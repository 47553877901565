import DirectApi from '../../../../directApi';
export const searchSuggestionQuery = (recentTaskId) => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return DirectApi.Fetch_Raw_With_Init('globalsearch/suggestions', {
        method: 'POST',
        credentials: 'include',
        headers,
        body: JSON.stringify({ recentTaskId }),
    })
        .then(response => response.json())
        .then(data => data);
};
export const searchQuery = (queryString, projectId, endpoint, abortSignal) => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return DirectApi.Fetch_Raw_With_Init('globalsearch', {
        method: 'POST',
        credentials: 'include',
        headers,
        body: JSON.stringify({ queryString, projectId, endpoint }),
        signal: abortSignal,
    })
        .then(response => response.json())
        .then(data => data)
        .catch(() => {
        console.log('Search query aborted');
    });
};
