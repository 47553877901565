/* @flow */

import {CharacterMetadata, ContentBlock, genKey, Entity} from 'draft-js';
import {Map, List, OrderedMap, OrderedSet} from 'immutable';
import getSafeBodyFromHTML from './getSafeBodyFromHTML';
import {
	createTextChunk,
	getSoftNewlineChunk,
	getEmptyChunk,
	getBlockDividerChunk,
	getFirstBlockChunk,
	getAtomicBlockChunk,
	joinChunks,
	getHorizontalRuleChunk,
} from './chunkBuilder';
import getBlockTypeForTag from './getBlockTypeForTag';
import processInlineTag from './processInlineTag';
import getBlockData from './getBlockData';
import getEntityId from './getEntityId';
import getJiraCustomBlockType from './getJiraCustomBlockType';

const SPACE = ' ';
const REGEX_NBSP = new RegExp('&nbsp;', 'g');

let firstBlock = true;

function hasOnlyOneAttribute(node) {
	return node.attributes.length === 1;
}

function isEmptyNoneditableDiv(node) {
	return hasOnlyOneAttribute(node) && node.attributes.contenteditable?.value === 'false';
}

function genFragment(node, inlineStyle, depth, lastList, inEntity, customChunkGenerator, isInList = false) {
	const nodeName = node.nodeName.toLowerCase();
	// Makes sure nodes marked as 'draftjs-ignore' are not rendered as individual blocks
	const draftJsIgnore = node.className && node.className.includes ? node.className?.includes('slate-draftjs-ignore') : false;

	if (customChunkGenerator) {
		const value = customChunkGenerator(nodeName, node);
		if (value) {
			const entityId = Entity.__create(value.type, value.mutability, value.data || {});
			return {chunk: getAtomicBlockChunk(entityId)};
		}
	}

	if (nodeName === '#text' && node.textContent !== '\n') {
		return createTextChunk(node, inlineStyle, inEntity);
	}

	if (nodeName === 'br') {
		return {chunk: getSoftNewlineChunk()};
	}

	if (nodeName === 'hr') {
		return {chunk: getHorizontalRuleChunk()};
	}

	if (nodeName === 'img' && node instanceof HTMLImageElement) {
		const entityConfig = {};
		entityConfig.src = node.getAttribute ? node.getAttribute('src') || node.src : node.src;
		entityConfig.alt = node.alt;
		entityConfig.height = node.style.height;
		entityConfig.width = node.style.width;
		if (node.style.float) {
			entityConfig.alignment = node.style.float;
		}
		const entityId = Entity.__create('IMAGE', 'MUTABLE', entityConfig);
		return {chunk: getAtomicBlockChunk(entityId)};
	}

	if (nodeName === 'video' && node instanceof HTMLVideoElement) {
		const entityConfig = {};
		entityConfig.src = node.getAttribute ? node.getAttribute('src') || node.src : node.src;
		entityConfig.alt = node.alt;
		entityConfig.height = node.style.height;
		entityConfig.width = node.style.width;
		if (node.style.float) {
			entityConfig.alignment = node.style.float;
		}
		const entityId = Entity.__create('VIDEO', 'MUTABLE', entityConfig);
		return {chunk: getAtomicBlockChunk(entityId)};
	}

	if (nodeName === 'iframe' && node instanceof HTMLIFrameElement) {
		const entityConfig = {};
		entityConfig.src = node.getAttribute ? node.getAttribute('src') || node.src : node.src;
		entityConfig.height = node.height;
		entityConfig.width = node.width;
		const entityId = Entity.__create('EMBEDDED_LINK', 'MUTABLE', entityConfig);
		return {chunk: getAtomicBlockChunk(entityId)};
	}

	// Look for the blocktype only if the node has not been marked as 'draftjs-ignore'
	let blockType = draftJsIgnore ? undefined : getBlockTypeForTag(nodeName, lastList);

	if (blockType === 'unstyled') {
		// Look for Jira custom block types. These are spans within a paragraph with the className 'slate-non-editable-content'
		// Check first chiled to see if it matches.
		const childNode = node.firstChild;
		if (childNode?.nodeName?.toLowerCase() === 'span' && childNode.className === 'slate-non-editable-content') {
			// Set blockType to undefined so this doesn't get rendered as a separate block. The Child span will get renderes as a custom jira block instead
			blockType = undefined;
		}

		if (isEmptyNoneditableDiv(node)) {
			// Set blockType to undefined so this doesn't get rendered as a separate block. This is a patch for some plate markup that we can safely ignore
			blockType = undefined;
		}
	}
	if (!blockType && !draftJsIgnore) {
		// If a blocktype wasn't found, check if it is a noneditable span and set the blockType from the data attribute
		if (nodeName === 'span' && node.className === 'slate-non-editable-content') {
			blockType = getJiraCustomBlockType(node);
		}
	}

	let chunk;
	if (blockType) {
		if (nodeName === 'ul' || nodeName === 'ol') {
			isInList = true;
			lastList = nodeName;
			depth += 1;
		} else {
			if (!isInList && blockType !== 'unordered-list-item' && blockType !== 'ordered-list-item') {
				lastList = '';
				depth = -1;
			}
			if (firstBlock) {
				chunk = getFirstBlockChunk(blockType, getBlockData(node));
				firstBlock = false;
			} else if (!isInList || blockType !== 'unstyled') {
				chunk = getBlockDividerChunk(blockType, depth, getBlockData(node));
			}
		}
	}
	if (!chunk) {
		chunk = getEmptyChunk();
	}

	inlineStyle = processInlineTag(nodeName, node, inlineStyle);

	let child = node.firstChild;
	while (child) {
		const entityId = getEntityId(child);
		const {chunk: generatedChunk} = genFragment(
			child,
			inlineStyle,
			depth,
			lastList,
			entityId || inEntity,
			customChunkGenerator,
			isInList
		);
		chunk = joinChunks(chunk, generatedChunk);
		const sibling = child.nextSibling;
		child = sibling;
	}
	return {chunk};
}

function getChunkForHTML(html, customChunkGenerator) {
	const sanitizedHtml = html.trim().replace(REGEX_NBSP, SPACE);
	const safeBody = getSafeBodyFromHTML(sanitizedHtml);
	if (!safeBody) {
		return null;
	}
	firstBlock = true;
	const {chunk} = genFragment(safeBody, new OrderedSet(), -1, '', undefined, customChunkGenerator);
	return {chunk};
}

export default function htmlToDraft(html, customChunkGenerator) {
	const chunkData = getChunkForHTML(html, customChunkGenerator);
	if (chunkData) {
		const {chunk} = chunkData;
		let entityMap = new OrderedMap({});
		chunk.entities &&
			chunk.entities.forEach(entity => {
				if (entity) {
					entityMap = entityMap.set(entity, Entity.__get(entity));
				}
			});
		let start = 0;
		return {
			contentBlocks: chunk.text.split('\r').map((textBlock, ii) => {
				const end = start + textBlock.length;
				const inlines = chunk && chunk.inlines.slice(start, end);
				const entities = chunk && chunk.entities.slice(start, end);
				const characterList = new List(
					inlines.map((style, index) => {
						const data = {style, entity: null};
						if (entities[index]) {
							data.entity = entities[index];
						}
						return CharacterMetadata.create(data);
					})
				);
				start = end;
				return new ContentBlock({
					key: genKey(),
					type: (chunk && chunk.blocks[ii] && chunk.blocks[ii].type) || 'unstyled',
					depth: chunk && chunk.blocks[ii] && chunk.blocks[ii].depth,
					data: (chunk && chunk.blocks[ii] && chunk.blocks[ii].data) || new Map({}),
					text: textBlock,
					characterList,
				});
			}),
			entityMap,
		};
	}
	return null;
}
