import React, {useEffect, useState} from 'react';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';

import ActionsMenu from '../../shared/components/action-menu/actions_menu';
import {MODAL_TYPE, showModal} from '../../shared/components/modals/generic_modal_conductor';
import {createToast} from '../../shared/components/toasts/another-toast/toaster';
import Util from '../../shared/util/util';
import UpdateExpenseCategoryMutation from '../../../mutations/update_expense_category_mutation';
import DeleteExpenseCategoryMutation from '../../../mutations/delete_expense_category_mutation';
import {BasicTextInput} from 'web-components';

export const ExpenseCategoryRow = injectIntl(
	withRouter(({intl: {formatMessage}, expenseCategory, company, isFirstRow}) => {
		const isDefault = company.defaultExpenseCategory ? company.defaultExpenseCategory.id === expenseCategory.id : false;
		const isUsedByBaseline = expenseCategory.baselineUses > 0;
		const disabled = expenseCategory.disabled;
		const {canDelete} = expenseCategory;

		const [name, setName] = useState(expenseCategory.name);

		const handleSetName = name => {
			if (name.length > 191) {
				name = name.substring(0, 191);
			}
			setName(name);
		};

		useEffect(() => {
			handleSetName(expenseCategory.name);
		}, [expenseCategory.name]);

		const updateExpenseCategoryName = expenseCategoryName => {
			if (
				expenseCategoryName !== undefined &&
				expenseCategoryName !== null &&
				expenseCategoryName !== '' &&
				expenseCategoryName !== expenseCategory.name
			) {
				Util.CommitMutation(
					UpdateExpenseCategoryMutation,
					{
						id: expenseCategory.id,
						name: expenseCategoryName,
					},
					null,
					true
				);
			}
		};

		const changeDisabled = expenseCategory => {
			Util.CommitMutation(UpdateExpenseCategoryMutation, {
				id: expenseCategory.id,
				disabled: !disabled,
			});
		};

		const deleteExpenseCategory = expenseCategory => {
			const onSuccess = () => {
				createToast({duration: 5000, message: formatMessage({id: 'expense_category.has-been-deleted'})});
			};

			const callbackPositive = () => {
				Util.CommitMutation(
					DeleteExpenseCategoryMutation,
					{
						id: expenseCategory.id,
						companyId: company.id,
					},
					onSuccess
				);
			};

			showModal({
				type: MODAL_TYPE.GENERIC_DELETION_WARNING,
				deleteCallback: callbackPositive,
			});
		};

		return (
			<>
				<td className="column-name" data-userpilot={isFirstRow ? 'expense-categories-name' : null}>
					<div className="name-input">
						<BasicTextInput
							value={name}
							onChange={handleSetName}
							callback={updateExpenseCategoryName}
							width="250px"
							disabled={disabled}
						/>
					</div>
				</td>

				<td className="column-actions" data-userpilot={isFirstRow ? 'expense-categories-actions-menu' : null}>
					<ActionsMenu
						whiteInner={true}
						options={[
							{
								text: formatMessage({id: disabled ? 'common.enable' : 'common.disable'}),
								onClick: changeDisabled.bind(null, expenseCategory),
								locked: isDefault,
							},
							{
								text: formatMessage({id: 'common.delete'}),
								onClick: deleteExpenseCategory.bind(null, expenseCategory),
								locked: !canDelete || isUsedByBaseline,
							},
						]}
					/>
				</td>
			</>
		);
	})
);
