import {useEffect, useState} from 'react';
import {graphql} from 'react-relay';
import {fetchQuery} from 'relay-runtime';
import environment from '../../../../RelayEnvironment';
import {CAP_SETTING, PROGRAM_BUDGET_TYPE} from '../../../../constants';
import {ExpenseProgramLogic} from '../../../../containers/modal/expense_item_modal/ExpenseProgramLogic';
import {canLoggedInPersonManageProgram, hasTopDownProgramBudgetFeature} from '../../../shared/util/ProgramFinancialLogic';

/**
 * useProjectProgramFinancials
 *
 * hook to get project related program information
 * example usage:
 *
   	const {getProgramBudgetInfo, getExpenseProgramLogic} = useProjectProgramFinancials(companyProjectId);
	  const {programBudget, programAllocation, isInFixedPriceProgram} = getProgramBudgetInfo(actualPersonId);
 *
 *  Provide expense specific information to instantiate an expenseProgramLogic class specific to current program
 		let expenseLogic = getExpenseProgramLogic(actualPersonId, approved, billable, isCreate, totalExpenseAmount);

* * */

const projectProgramFinancialsQuery = graphql`
	query useProjectProgramFinancialsQuery($projectId: String) {
		viewer {
			component(name: "useProjectProgramFinancials")
			psProject(companyProjectId: $projectId) {
				program
				programBudgetType
				isProgramRevenueLocked
				programRemainingBudget
				psProgram {
					id
					name
					prefix
					budgetType
					budgetValue
					totalAllocation
					isRevenueLocked
					revenueSetting
					members(first: 10000) @connection(key: "Program_members", filters: []) {
						edges {
							node {
								role
								person {
									id
								}
							}
						}
					}
				}
			}
		}
	}
`;

export const getIsInFixedPriceProgram = programBudgetType => {
	return hasTopDownProgramBudgetFeature() && programBudgetType === PROGRAM_BUDGET_TYPE.FIXED_PRICE;
};

export const getIsInCappedRevenueProgram = programBudgetType => {
	return hasTopDownProgramBudgetFeature() && programBudgetType === PROGRAM_BUDGET_TYPE.CAPPED;
};

export const canAmountFitInBudget = (amount, programRemainingBudget) => {
	const amountRounded = Math.round(amount * 100) / 100;
	const programRemainingBudgetRounded = Math.round(programRemainingBudget * 100) / 100;
	return amountRounded <= programRemainingBudgetRounded;
};

// prefer the use of useProjectProgramFinancials - this is for legacy class components and unit tests
export const getRestrictedProgramInformation = (isInFixedPriceProgram, isInCappedRevenueProgram, program, loggedInPersonId) => {
	let programBudget, programAllocation;

	const canManageProgram = canLoggedInPersonManageProgram(program?.members, loggedInPersonId);

	if ((isInFixedPriceProgram || isInCappedRevenueProgram) && program) {
		programBudget = program.budgetValue;
		programAllocation = program.totalAllocation;
	}
	const programName = program ? program.name : '';
	const programPrefix = program ? program.prefix : '';
	const revenueSetting = program ? program.revenueSetting : '';
	return {canManageProgram, programBudget, programAllocation, programName, programPrefix, revenueSetting};
};

// prefer the use of useProjectProgramFinancials - this is for legacy class components and unit tests
export const doGetProgramBudgetInfo = (
	loggedInPersonId,
	projectId,
	program,
	programBudgetType,
	isProgramRevenueLocked,
	programRemainingBudget
) => {
	const isInFixedPriceProgram = getIsInFixedPriceProgram(programBudgetType);
	const isInCappedRevenueProgram = getIsInCappedRevenueProgram(programBudgetType);

	const {canManageProgram, programBudget, programAllocation, programName, programPrefix, revenueSetting} =
		getRestrictedProgramInformation(isInFixedPriceProgram, isInCappedRevenueProgram, program, loggedInPersonId);

	return {
		projectId,
		loggedInPersonId,
		programBudget,
		programAllocation,
		canManageProgram,
		isInFixedPriceProgram,
		isInCappedRevenueProgram,
		programRemainingBudget,
		isProgramRevenueLocked,
		programName,
		programPrefix,
		program,
		revenueSetting,
	};
};

// prefer the use of useProjectProgramFinancials - this is for legacy class components and unit tests
export const doGetExpenseProgramLogic = (
	loggedInPersonId,
	projectId,
	program,
	programBudgetType,
	programRemainingBudget,
	isExpenseApproved,
	isExpenseBillable,
	isCreate,
	expenseTotalAmount,
	partOfFixedPrice,
	initialOnTopOfFixedPrice
) => {
	const {isInFixedPriceProgram, isInCappedRevenueProgram, canManageProgram, revenueSetting} = doGetProgramBudgetInfo(
		loggedInPersonId,
		projectId,
		program,
		programBudgetType,
		programRemainingBudget
	);
	return new ExpenseProgramLogic(
		isInFixedPriceProgram,
		isInCappedRevenueProgram,
		canManageProgram,
		programRemainingBudget,
		isExpenseApproved,
		isExpenseBillable,
		isCreate,
		expenseTotalAmount,
		revenueSetting,
		partOfFixedPrice,
		initialOnTopOfFixedPrice
	);
};
export const buildRetainerPeriodProgramBudgetInfo = (periodsValue, programBudgetInfo, project) => {
	const {canManageProgram, programAllocation} = programBudgetInfo;
	const validateAgainstProgramBudget = [PROGRAM_BUDGET_TYPE.CAPPED, PROGRAM_BUDGET_TYPE.FIXED_PRICE].includes(
		project.programBudgetType
	);
	const showProgramBudgetInfo = validateAgainstProgramBudget && canManageProgram;
	const totalProgramAllocationIncludingPeriodsValue = programAllocation + periodsValue;
	const showOverAllocatedError =
		programBudgetInfo.revenueSetting === CAP_SETTING.ALWAYS_PERMITTED
			? false
			: project.isProgramRevenueLocked ||
			  (validateAgainstProgramBudget && project.programRemainingBudget - periodsValue < 0);
	const showCannotIncreasedPeriodTarget = periodsValue > 0;
	const showRevenueDisabled = programBudgetInfo.revenueSetting === CAP_SETTING.ALWAYS_DISABLED;
	return {
		validateAgainstProgramBudget,
		showProgramBudgetInfo,
		totalProgramAllocationIncludingPeriodsValue,
		showOverAllocatedError,
		showCannotIncreasedPeriodTarget,
		showRevenueDisabled,
		...programBudgetInfo,
	};
};

export const useProjectProgramFinancials = (projectId, onDataReady = () => {}) => {
	const [projectProgramFinancialsData, setProjectProgramFinancialsData] = useState([]);

	const getProgramBudgetInfo = loggedInPersonId => {
		return doGetProgramBudgetInfo(
			loggedInPersonId,
			projectId,
			projectProgramFinancialsData?.psProgram,
			projectProgramFinancialsData?.programBudgetType,
			projectProgramFinancialsData?.isProgramRevenueLocked
		);
	};

	const getExpenseProgramLogic = (
		loggedInPersonId,
		isExpenseApproved,
		isExpenseBillable,
		isCreate,
		expenseTotalAmount,
		partOfFixedPrice,
		initialOnTopOfFixedPrice
	) => {
		return doGetExpenseProgramLogic(
			loggedInPersonId,
			projectId,
			projectProgramFinancialsData?.psProgram,
			projectProgramFinancialsData?.programBudgetType,
			projectProgramFinancialsData?.programRemainingBudget,
			isExpenseApproved,
			isExpenseBillable,
			isCreate,
			expenseTotalAmount,
			partOfFixedPrice,
			initialOnTopOfFixedPrice
		);
	};

	const getRetainerPeriodProgramBudgetInfo = (periodsValue, loggedInPersonId, project) => {
		return buildRetainerPeriodProgramBudgetInfo(periodsValue, getProgramBudgetInfo(loggedInPersonId), project);
	};

	const asyncFetch = async projectId => {
		if (projectId && hasTopDownProgramBudgetFeature()) {
			try {
				onDataReady(false);

				const data = await fetchQuery(environment.getInstance(), projectProgramFinancialsQuery, {
					projectId: projectId + '',
				});
				setProjectProgramFinancialsData(data.viewer.psProject);
			} finally {
				onDataReady(true);
			}
		}
	};

	const refetch = async projectId => {
		if (projectId) {
			asyncFetch(projectId);
		}
	};

	useEffect(() => {
		if (projectId) {
			asyncFetch(projectId);
		}
	}, [projectId]);

	return {
		getProgramBudgetInfo,
		getExpenseProgramLogic,
		getRetainerPeriodProgramBudgetInfo,
		refetch,
	};
};
