import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {INITIALS_SIZE} from '../../../../constants';

class PersonInitials extends Component {
	render() {
		return (
			<div className={'person-initials-hexagon-shape ' + this.props.size}>
				<div className="initials">{this.props.initials}</div>
			</div>
		);
	}
}
PersonInitials.propTypes = {
	initials: PropTypes.string.isRequired,
	size: PropTypes.oneOf([
		INITIALS_SIZE.EXTRA_SMALL,
		INITIALS_SIZE.SMALL,
		INITIALS_SIZE.MEDIUM,
		INITIALS_SIZE.LARGE,
		INITIALS_SIZE.EXTRA_LARGE,
		INITIALS_SIZE.HUNDRED,
	]),
	addBorder: PropTypes.bool,
};
export default PersonInitials;
