import React, {useState} from 'react';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {createRefetchContainer, graphql} from 'react-relay';
import BaseLevelWrapper from './BaselevelWrapper';
import {GLOBAL_FILTER_FIELD, GLOBAL_FILTER_OPERATOR} from '../../../../../constants';
import GroupRow from '../rows/GroupRow';
import {useGenericReportContext} from '../../../saved-report/GenericReportContext';

const ClientRelayWrapperV2 = ({
	tableHeader,
	relay,
	groupings,
	searchQuery,
	viewer,
	intl,
	rowIndex,
	enabledColumns,
	nextLevelProps,
	currency,
	customFieldColumnCount,
	customFieldKeys,
	pageSize,
}) => {
	const {reportId, sharedId} = useGenericReportContext();

	const clients = viewer.reportData.clients.edges?.filter(client => client.node.taskTotals?.entities > 0);
	const hasNextPage = viewer.reportData.clients.pageInfo.hasNextPage && clients?.length >= pageSize;
	const [modalTasksFetching, setModalTasksFetching] = useState(false);

	/**
	 * Grouping filter for task with a client.
	 *
	 * @param clientID The client ID
	 */
	const clientGroupingFilter = clientID => {
		return {
			field: GLOBAL_FILTER_FIELD.CLIENT,
			operator: GLOBAL_FILTER_OPERATOR.IS,
			value: [clientID],
		};
	};

	/**
	 * Grouping filter for task without a client.
	 */
	const noClientGroupingFilter = () => {
		return {
			field: GLOBAL_FILTER_FIELD.CLIENT,
			operator: GLOBAL_FILTER_OPERATOR.IS,
		};
	};

	const dataRows = clients.map(client => {
		return {
			...client.node,
			generatedName: client.node.noClient ? intl.formatMessage({id: 'common.no_client'}) : client.node.name,
			currency,
		};
	});

	const data = {
		rows: dataRows,
		groupingFilter: rowData => (rowData.noClient ? noClientGroupingFilter() : clientGroupingFilter(rowData.clientId)),
	};

	const fetchMore = () => {
		if (!modalTasksFetching) {
			const refetchObject = {};
			setModalTasksFetching(true);
			refetchObject.pageSize = clients.length + pageSize;
			relay.refetch(refetchObject, null, () => setModalTasksFetching(false));
		}
	};

	const clientRows = () => {
		return GroupRow(
			data,
			intl,
			rowIndex,
			searchQuery,
			groupings,
			enabledColumns,
			hasNextPage,
			fetchMore,
			nextLevelProps,
			currency,
			reportId,
			sharedId,
			customFieldColumnCount,
			customFieldKeys
		);
	};

	return rowIndex === 1 ? (
		<BaseLevelWrapper
			tableHeader={tableHeader}
			intl={intl}
			enabledColumns={enabledColumns}
			data={data}
			getRows={clientRows}
			currentLength={clients.length}
			hasNextPage={hasNextPage}
			refetch={relay.refetch}
			currency={currency}
			customFieldColumnCount={customFieldColumnCount}
			pageSize={pageSize}
		/>
	) : (
		clientRows()
	);
};

const ClientRelayWrapperQuery = graphql`
	query ClientRelayWrapperV2Query_Query(
		$searchQuery: TaskSearchQueryType
		$pageSize: Int
		$reportId: String
		$sharedId: String
	) {
		viewer {
			actualPersonId
			component(name: "client_relay_wrapper_v2")
			...ClientRelayWrapperV2_viewer
				@arguments(searchQuery: $searchQuery, pageSize: $pageSize, reportId: $reportId, sharedId: $sharedId)
		}
	}
`;
export {ClientRelayWrapperQuery};

export default injectIntl(
	withRouter(
		createRefetchContainer(
			ClientRelayWrapperV2,
			{
				viewer: graphql`
					fragment ClientRelayWrapperV2_viewer on Viewer
					@argumentDefinitions(
						searchQuery: {type: "TaskSearchQueryType"}
						pageSize: {type: "Int"}
						reportId: {type: "String"}
						sharedId: {type: "String"}
					) {
						reportData(id: $reportId, sharedId: $sharedId) {
							clients(first: $pageSize, searchQuery: $searchQuery)
								@connection(key: "TaskReport_clients", filters: ["searchQuery"]) {
								pageInfo {
									endCursor
									hasNextPage
								}
								edges {
									node {
										id
										clientId
										name
										taskTotals {
											work {
												estimate {
													minutes
													points
												}
												remaining {
													minutes
													points
												}
											}
											reported {
												time
												points
												projected
											}
											entities
										}
										noClient
									}
								}
							}
						}
					}
				`,
			},
			graphql`
				query ClientRelayWrapperV2RefetchQuery(
					$pageSize: Int
					$searchQuery: TaskSearchQueryType
					$reportId: String
					$sharedId: String
				) {
					viewer {
						...ClientRelayWrapperV2_viewer
							@arguments(pageSize: $pageSize, searchQuery: $searchQuery, reportId: $reportId, sharedId: $sharedId)
					}
				}
			`
		)
	)
);
