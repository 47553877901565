import React from 'react';
import {DeprecatedContextMenu as ContextMenu} from '@forecast-it/design-system';
import {DeprecatedContextMenuItem as ContextMenuItem} from '@forecast-it/design-system';
import {DeprecatedText as Text} from '@forecast-it/design-system';
import ReactDOM from 'react-dom';
import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';
import {PROGRAM_BUDGET_TYPE} from '../../../../constants';
import ProgramUtil from '../../../shared/util/ProgramUtil';

const ProgramBudgetTypeContextMenu = ({program, bounds, closeContextMenu}) => {
	const history = useHistory();
	const {formatMessage} = useIntl();

	return ReactDOM.createPortal(
		<ContextMenu verticalPos={'below'} horizontalOrientation={'left'} bounds={bounds} closeContextMenu={closeContextMenu}>
			<ContextMenuItem
				onClick={() => {
					trackEvent('Program Budget Settings Link', 'Clicked', {location: 'From Meatballs Menu'});
					history.push(`${ProgramUtil.programLink()}/${program.prefix}/settings/general`);
				}}
			>
				<Text>{formatMessage({id: 'common.go_to_program_budget_settings'})}</Text>
			</ContextMenuItem>
			{program.budgetType !== PROGRAM_BUDGET_TYPE.UNCAPPED && (
				<ContextMenuItem
					onClick={() => {
						trackEvent('Edit Program Budget Link', 'Clicked', {location: 'From Meatballs Menu'});
						history.push(`${ProgramUtil.programLink()}/${program.prefix}/budget/edit`);
					}}
				>
					<Text>{formatMessage({id: 'common.edit_program_budget'})}</Text>
				</ContextMenuItem>
			)}
		</ContextMenu>,
		document.querySelector('#root-portal-container')
	);
};

export default ProgramBudgetTypeContextMenu;
