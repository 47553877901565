import React from 'react';

const lockedIcon = props => (
	<div className={props.className} title={props.title}>
		<svg xmlns="http://www.w3.org/2000/svg" width={props.width || '9'} height={props.height || '10'} viewBox="0 0 9 10">
			<path
				fill={props.fill ? props.fill : '#757575'}
				fillRule="evenodd"
				d="M1.667 8.333h5V5h-5v3.333zm2.5-6.666c.919 0 1.666.747 1.666 1.666H2.5c0-.919.748-1.666 1.667-1.666zM7.5 3.333A3.337 3.337 0 0 0 4.167 0 3.337 3.337 0 0 0 .833 3.333.834.834 0 0 0 0 4.167v5c0 .46.373.833.833.833H7.5c.46 0 .833-.372.833-.833v-5a.834.834 0 0 0-.833-.834z"
			/>
		</svg>
	</div>
);
export default lockedIcon;
