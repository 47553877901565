import DataExportFormatter from './DataExportFormatter';
import Util from '../util';
import {BUDGET_TYPE} from '../../../../constants';
import {roundToNDecimals} from '../NumberUtil';

export default class ProjectFormatter extends DataExportFormatter {
	_columnFormatHook(name) {
		return name
			.split(/(?=[A-Z_])/)
			.map(word => word.replace('_', '').toLocaleLowerCase())
			.join('_');
	}

	_getFormattedValueHook(column, project) {
		switch (column) {
			case 'project_id':
				return project.customProjectId
					? this._formatString(project.customProjectId)
					: this._formatWithPrefix(project.companyProjectId, 'P', '');
			case 'project_name':
				return project.name;
			case 'client':
				return project.client ? project.client.name : '';
			case 'uninvoiced_time_registrations_time':
			case 'uninvoiced_locked_periods_time':
				return project.uninvoicedMinutes;
			case 'uninvoiced_time_registrations_amount':
			case 'uninvoiced_locked_periods_amount':
				return roundToNDecimals(project.uninvoicedPrice, 2);
			case 'uninvoiced_expenses':
				return roundToNDecimals(project.uninvoicedExpenses, 2);
			case 'uninvoiced_fixed_price':
				return roundToNDecimals(project.uninvoicedFixedPrice, 2);
			case 'total_uninvoiced':
				return roundToNDecimals(project.uninvoicedPrice + project.uninvoicedExpenses + project.uninvoicedFixedPrice, 2);
			case 'total_invoiced':
				return roundToNDecimals(project.invoicedAmount, 2);
			case 'currency':
				return project.currency;
			default:
				return null;
		}
	}

	_formatProjectOwner(projectPersons) {
		if (projectPersons && projectPersons.edges) {
			return projectPersons.edges
				.map(edge => edge.node)
				.filter(projectPerson => projectPerson.isContactPerson)
				.map(projectPerson => projectPerson.person)
				.map(person => person.fullName)
				.join(' - ');
		} else {
			return '';
		}
	}

	_formatLabels(labels) {
		if (labels) {
			return labels.edges
				.map(edge => edge.node)
				.map(projectLabel => projectLabel.label)
				.map(label => label.name)
				.join(' - ');
		} else {
			return '';
		}
	}

	_formatBudgetType(project) {
		let label = '';
		if (project) {
			const {budgetType, defaultPeriodBudgetType} = project;
			label = Util.getBudgetTypeTranslation(budgetType, this._intl);
			if (budgetType === BUDGET_TYPE.RETAINER && defaultPeriodBudgetType) {
				label += ` (${Util.getPeriodBudgetTypeTranslation(defaultPeriodBudgetType, this._intl)})`;
			}
		}
		return label;
	}
}
