/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ProgramNovaInsights_viewer$ref = any;
export type ProgramNovaInsights_QueryVariables = {|
  prefix?: ?string
|};
export type ProgramNovaInsights_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: ProgramNovaInsights_viewer$ref,
  |}
|};
export type ProgramNovaInsights_Query = {|
  variables: ProgramNovaInsights_QueryVariables,
  response: ProgramNovaInsights_QueryResponse,
|};
*/


/*
query ProgramNovaInsights_Query(
  $prefix: String
) {
  viewer {
    actualPersonId
    component(name: "program_nova_insights")
    ...ProgramNovaInsights_viewer_3WHZd4
    id
  }
}

fragment ProgramNovaInsights_viewer_3WHZd4 on Viewer {
  id
  program(prefix: $prefix) {
    id
    name
    color
    projectHealthRollup {
      suggestedStatusDisagreesRag
      suggestedStatusDisagreesMessage
      predictedOverrunRag
      predictedOverrunMessage
      budgetOverrunRag
      budgetOverrunMessage
      projectHealthResponses {
        projectId
        predictedEndDateRAGStatus
        predictedEndDateInfo
        predictedEndDateError
        budgetInsightRag
        budgetInsightInfoShort
        budgetInsightError
        suggestedStatusRag
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "prefix",
    "type": "String"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "program_nova_insights"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"program_nova_insights\")"
},
v3 = [
  {
    "kind": "Variable",
    "name": "prefix",
    "variableName": "prefix"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProgramNovaInsights_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "args": (v3/*: any*/),
            "kind": "FragmentSpread",
            "name": "ProgramNovaInsights_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProgramNovaInsights_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "ProgramType",
            "kind": "LinkedField",
            "name": "program",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "color",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectHealthRollup",
                "kind": "LinkedField",
                "name": "projectHealthRollup",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "suggestedStatusDisagreesRag",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "suggestedStatusDisagreesMessage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "predictedOverrunRag",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "predictedOverrunMessage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "budgetOverrunRag",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "budgetOverrunMessage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MultiProjectHealth",
                    "kind": "LinkedField",
                    "name": "projectHealthResponses",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "predictedEndDateRAGStatus",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "predictedEndDateInfo",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "predictedEndDateError",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "budgetInsightRag",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "budgetInsightInfoShort",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "budgetInsightError",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "suggestedStatusRag",
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "ProgramNovaInsights_Query",
    "operationKind": "query",
    "text": "query ProgramNovaInsights_Query(\n  $prefix: String\n) {\n  viewer {\n    actualPersonId\n    component(name: \"program_nova_insights\")\n    ...ProgramNovaInsights_viewer_3WHZd4\n    id\n  }\n}\n\nfragment ProgramNovaInsights_viewer_3WHZd4 on Viewer {\n  id\n  program(prefix: $prefix) {\n    id\n    name\n    color\n    projectHealthRollup {\n      suggestedStatusDisagreesRag\n      suggestedStatusDisagreesMessage\n      predictedOverrunRag\n      predictedOverrunMessage\n      budgetOverrunRag\n      budgetOverrunMessage\n      projectHealthResponses {\n        projectId\n        predictedEndDateRAGStatus\n        predictedEndDateInfo\n        predictedEndDateError\n        budgetInsightRag\n        budgetInsightInfoShort\n        budgetInsightError\n        suggestedStatusRag\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0e6c342850de1b9834345f44a4fad121';

module.exports = node;
