import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import {CSS_CONSTANTS} from '../../../../../../../css_variables';
import ArrowDown from '../../../../../../../images/v2/components/dropdown/arrow-down.svg';
import {FormattedHTMLMessage} from 'react-intl';
import ForecastTooltip from '../../../../tooltips/ForecastTooltip';
import {IconWrapper} from '../../../../../../../components/initial-plan/InitialPlan.styled';
import {Icon} from '@forecast-it/design-system';

const TileWrapper = Styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
`;

const UnfocusedDropdownWrapper = Styled.div`
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: space-between;
    padding-left: 8px;
    border: ${props => !props.disabled && 'solid 1px #e6e6e6'};
    border-radius: 4px;
    align-items: center;
    background-color: ${props => !props.disabled && '#fff'};
`;

const UnfocusedDropdownText = Styled.div`
    font-family: ${CSS_CONSTANTS.text_font_family_v2};
    font-size: 12px;
    color: ${props => (props.noSelection ? CSS_CONSTANTS.v2_text_light_gray : CSS_CONSTANTS.v2_text_gray)};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: ${props => !props.newDropdown && '8px'};
`;

const UnfocusedDropdownCaret = Styled.div`
    background: url(${ArrowDown}) no-repeat center;
    min-width: 16px;
    max-width: 16px;
    height: 30px;
    margin-right: 6px;
    margin-top: ${props => props.newDropdown && '1px'};
    outline: none;
`;

export const UnfocusedDropdownTile = ({value, noSelection, disabled, newDropdown, cy = '', isRoleDisabled}) => {
	return (
		<TileWrapper>
			<UnfocusedDropdownWrapper disabled={disabled} data-cy={cy}>
				<UnfocusedDropdownText title={value} disabled={disabled} noSelection={noSelection} newDropdown={newDropdown}>
					{value}
				</UnfocusedDropdownText>
				{!disabled && <UnfocusedDropdownCaret newDropdown={newDropdown} />}
			</UnfocusedDropdownWrapper>
			{isRoleDisabled && (
				<ForecastTooltip content={<FormattedHTMLMessage id="project_person.deactivated_role" />}>
					<IconWrapper>
						<Icon icon="warning" size="m" color="error" />
					</IconWrapper>
				</ForecastTooltip>
			)}
		</TileWrapper>
	);
};

UnfocusedDropdownTile.propTypes = {
	value: PropTypes.string,
	noSelection: PropTypes.bool,
	lazyDataFetched: PropTypes.bool,
	cy: PropTypes.string,
	newDropdown: PropTypes.bool,
};
