import styled from 'styled-components/macro';
import {CSS_CONSTANTS} from '../../../../css_variables';

export const HeaderContainer = styled.div`
	background: white;
	width: 100%;
	position: sticky;
	top: 0;
	left: 0;
`;

export const PageTitle = styled.div`
	font-size: 16px;
	text-transform: capitalize;
	color: ${CSS_CONSTANTS.v2_text_gray};
	font-weight: 500;
	margin: 24px 66px 0;
	position: sticky;
	left: 66px;
`;

export const PageContent = styled.div`
	margin-left: 22px;
	margin-right: 22px;
`;
