/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type APPROVAL_STATUS = "APPROVED" | "NOT_SUBMITTED" | "SUBMITTED" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type TimeRegRelayWrapper_viewer$ref: FragmentReference;
declare export opaque type TimeRegRelayWrapper_viewer$fragmentType: TimeRegRelayWrapper_viewer$ref;
export type TimeRegRelayWrapper_viewer = {|
  +reportData: ?{|
    +timeRegistrations: ?{|
      +pageInfo: {|
        +endCursor: ?string,
        +hasNextPage: boolean,
      |},
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +date: ?string,
          +notes: ?string,
          +billable: ?boolean,
          +invoiced: ?boolean,
          +approvalStatus: ?APPROVAL_STATUS,
          +cost: ?number,
          +price: ?number,
          +person: ?{|
            +firstName: ?string,
            +lastName: ?string,
            +profilePictureId: ?string,
            +role: ?{|
              +name: ?string
            |},
            +department: ?{|
              +name: ?string
            |},
          |},
          +timeRegistered: ?number,
          +billableRegistered: ?number,
          +nonBillableRegistered: ?number,
          +task: ?{|
            +name: ?string,
            +project: ?{|
              +name: ?string,
              +projectColor: ?string,
              +companyProjectId: ?number,
              +customProjectId: ?string,
              +client: ?{|
                +name: ?string
              |},
              +fullAccessToProject: ?boolean,
              +readOnlyAccess: ?boolean,
            |},
            +phase: ?{|
              +name: ?string
            |},
            +sprint: ?{|
              +name: ?string
            |},
          |},
          +project: ?{|
            +name: ?string,
            +projectColor: ?string,
            +companyProjectId: ?number,
            +customProjectId: ?string,
            +client: ?{|
              +name: ?string
            |},
            +fullAccessToProject: ?boolean,
            +readOnlyAccess: ?boolean,
          |},
          +idleTime: ?{|
            +name: ?string,
            +isInternalTime: ?boolean,
          |},
          +reportCustomFieldValues: ?$ReadOnlyArray<?{|
            +id: string,
            +key: ?string,
            +value: ?string,
            +displayName: ?string,
            +readOnly: ?boolean,
          |}>,
          +role: ?{|
            +name: ?string
          |},
        |}
      |}>,
    |}
  |},
  +$refType: TimeRegRelayWrapper_viewer$ref,
|};
export type TimeRegRelayWrapper_viewer$data = TimeRegRelayWrapper_viewer;
export type TimeRegRelayWrapper_viewer$key = {
  +$data?: TimeRegRelayWrapper_viewer$data,
  +$fragmentRefs: TimeRegRelayWrapper_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "ReportRole",
  "kind": "LinkedField",
  "name": "role",
  "plural": false,
  "selections": (v1/*: any*/),
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ReportProject",
  "kind": "LinkedField",
  "name": "project",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "companyProjectId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customProjectId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ReportClient",
      "kind": "LinkedField",
      "name": "client",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullAccessToProject",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "readOnlyAccess",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchQuery",
      "type": "TaskSearchQueryType"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "pageSize",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "reportId",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sharedId",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "customFieldKeys",
      "type": "[String]"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "pageSize",
        "cursor": null,
        "direction": "forward",
        "path": [
          "reportData",
          "timeRegistrations"
        ]
      }
    ]
  },
  "name": "TimeRegRelayWrapper_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "reportId"
        },
        {
          "kind": "Variable",
          "name": "sharedId",
          "variableName": "sharedId"
        }
      ],
      "concreteType": "ReportData",
      "kind": "LinkedField",
      "name": "reportData",
      "plural": false,
      "selections": [
        {
          "alias": "timeRegistrations",
          "args": [
            {
              "kind": "Variable",
              "name": "searchQuery",
              "variableName": "searchQuery"
            }
          ],
          "concreteType": "ReportTimeRegistrationTypeConnection",
          "kind": "LinkedField",
          "name": "__TimeRegReport_timeRegistrations_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ReportTimeRegistrationTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ReportTimeReg",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "date",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "notes",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "billable",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "invoiced",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "approvalStatus",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cost",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "price",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ReportPerson",
                      "kind": "LinkedField",
                      "name": "person",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "firstName",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "lastName",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "profilePictureId",
                          "storageKey": null
                        },
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ReportDepartment",
                          "kind": "LinkedField",
                          "name": "department",
                          "plural": false,
                          "selections": (v1/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "timeRegistered",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "billableRegistered",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "nonBillableRegistered",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ReportTask",
                      "kind": "LinkedField",
                      "name": "task",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v3/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ReportPhase",
                          "kind": "LinkedField",
                          "name": "phase",
                          "plural": false,
                          "selections": (v1/*: any*/),
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ReportSprint",
                          "kind": "LinkedField",
                          "name": "sprint",
                          "plural": false,
                          "selections": (v1/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ReportIdleTime",
                      "kind": "LinkedField",
                      "name": "idleTime",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isInternalTime",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": [
                        {
                          "kind": "Variable",
                          "name": "fieldNodeKeys",
                          "variableName": "customFieldKeys"
                        }
                      ],
                      "concreteType": "ReportCustomFieldValue",
                      "kind": "LinkedField",
                      "name": "reportCustomFieldValues",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "id",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "key",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "value",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "displayName",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "readOnly",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '1c0d0dfab13c1571d50faa4c8ec3367d';

module.exports = node;
