/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type DeprecatedProjectIndicatorJS_project$ref = any;
type LabelDropdown_labels$ref = any;
type TeamDropdown_teams$ref = any;
type genericTaskContextMenu_company$ref = any;
export type BUDGET_TYPE = "FIXED_PRICE" | "FIXED_PRICE_V2" | "NON_BILLABLE" | "RETAINER" | "TIME_AND_MATERIALS" | "%future added value";
export type CANT_DELETE_TASK_REASON = "BLOCKED_BY_SUBTASK" | "GITHUB" | "HAS_INVOICED_TIME" | "HAS_LOCKED_TIME" | "HAS_OTHER_PEOPLES_TIME_REGS" | "HAS_TIME_REGS" | "JIRA" | "NO_USER_TYPE" | "UNIT4" | "%future added value";
export type DEPENDENCY_TYPE = "CANNOT_BE_COMPLETED" | "CANNOT_START" | "%future added value";
export type LANGUAGE = "DANISH" | "ENGLISH_EU" | "ENGLISH_UK" | "ENGLISH_US" | "FRENCH" | "POLISH" | "SPANISH" | "%future added value";
export type PROJECT_STATUS = "DONE" | "HALTED" | "OPPORTUNITY" | "PLANNING" | "RUNNING" | "%future added value";
export type STATUS_CATEGORY = "DONE" | "INPROGRESS" | "TODO" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectSprintBacklogWrapper_viewer$ref: FragmentReference;
declare export opaque type ProjectSprintBacklogWrapper_viewer$fragmentType: ProjectSprintBacklogWrapper_viewer$ref;
export type ProjectSprintBacklogWrapper_viewer = {|
  +id: string,
  +language: ?LANGUAGE,
  +actualPersonId: ?string,
  +availableFeatureFlags: ?$ReadOnlyArray<?{|
    +key: ?string
  |}>,
  +projectGroup: ?{|
    +id: string,
    +companyProjectGroupId: ?number,
    +name: ?string,
    +color: ?string,
    +projects: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +demo: ?boolean,
          +status: ?PROJECT_STATUS,
          +companyProjectId: ?number,
          +taskLevels: ?number,
          +sprintTimeBox: ?boolean,
          +sprintLengthInDays: ?number,
          +useManualAllocations: ?boolean,
          +remainingAutoCalculated: ?boolean,
          +projectColor: ?string,
          +estimationUnit: ?string,
          +minutesPerEstimationPoint: ?number,
          +manualProgressOnProjectEnabled: ?boolean,
          +manualProgressOnPhasesEnabled: ?boolean,
          +manualProgressOnTasksEnabled: ?boolean,
          +budgetWork: ?number,
          +budgetType: ?BUDGET_TYPE,
          +tasks: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +readOnly: ?{|
                  +isReadOnly: ?boolean
                |},
                +userCanDeleteTask: ?boolean,
                +userCantDeleteTaskReason: ?CANT_DELETE_TASK_REASON,
                +name: ?string,
                +description: ?string,
                +highPriority: ?boolean,
                +companyTaskId: ?number,
                +estimateForecast: ?number,
                +timeLeft?: ?number,
                +currentPrice?: ?number,
                +timeRegistrations?: ?{|
                  +edges: ?$ReadOnlyArray<?{|
                    +node: ?{|
                      +id: string,
                      +minutesRegistered: ?number,
                      +price: ?number,
                      +invoiced: ?boolean,
                      +person: ?{|
                        +id: string,
                        +role: ?{|
                          +id: string
                        |},
                      |},
                    |}
                  |}>
                |},
                +progress?: ?number,
                +approved: ?boolean,
                +sortOrder: ?number,
                +blocked: ?boolean,
                +bug: ?boolean,
                +estimateForecastPrice: ?number,
                +deadlineYear: ?number,
                +deadlineMonth: ?number,
                +deadlineDay: ?number,
                +startYear: ?number,
                +startMonth: ?number,
                +startDay: ?number,
                +startFrom: ?string,
                +deadlineFrom: ?string,
                +hasDependency: ?boolean,
                +canStart: ?boolean,
                +canBeSetToDone: ?boolean,
                +subTaskCount: ?number,
                +latestUiUpdateAt: ?string,
                +hasLockedTime: ?boolean,
                +hasInvoicedTime: ?boolean,
                +favoured: ?boolean,
                +billable: ?boolean,
                +parentTaskId: ?string,
                +project: ?{|
                  +id: string,
                  +demo: ?boolean,
                  +estimationUnit: ?string,
                  +minutesPerEstimationPoint: ?number,
                  +companyProjectId: ?number,
                  +projectEndYear: ?number,
                  +projectEndMonth: ?number,
                  +projectEndDay: ?number,
                  +projectColor: ?string,
                  +remainingAutoCalculated: ?boolean,
                  +name: ?string,
                  +status: ?PROJECT_STATUS,
                  +projectGroupId: ?string,
                  +projectPerson: ?{|
                    +role: ?{|
                      +id: string,
                      +name: ?string,
                    |}
                  |},
                  +harvestProject: ?{|
                    +id: string
                  |},
                  +client: ?{|
                    +id: string
                  |},
                  +rateCard: ?{|
                    +id: string,
                    +currency: ?string,
                    +disabledRoles: ?$ReadOnlyArray<?{|
                      +id: string
                    |}>,
                  |},
                  +useTaskHierarchy: ?boolean,
                  +$fragmentRefs: DeprecatedProjectIndicatorJS_project$ref,
                |},
                +taskLabels: ?$ReadOnlyArray<?{|
                  +id: string,
                  +label: ?{|
                    +id: string,
                    +name: ?string,
                    +color: ?string,
                  |},
                |}>,
                +sprint: ?{|
                  +id: string,
                  +endYear: ?number,
                  +endMonth: ?number,
                  +endDay: ?number,
                  +savedPerformance: ?number,
                  +name: ?string,
                  +projectGroupSprintId: ?string,
                  +isProjectGroupSprint: ?boolean,
                |},
                +statusColumnV2: ?{|
                  +id: string,
                  +name: ?string,
                  +category: ?STATUS_CATEGORY,
                  +projectGroupStatusColumnId: ?number,
                |},
                +phase: ?{|
                  +id: string,
                  +name: ?string,
                  +deadlineYear: ?number,
                  +deadlineMonth: ?number,
                  +deadlineDay: ?number,
                |},
                +role: ?{|
                  +id: string,
                  +name: ?string,
                |},
                +assignedPersons: ?$ReadOnlyArray<?{|
                  +id: string,
                  +active: ?boolean,
                  +firstName: ?string,
                  +lastName: ?string,
                  +profilePictureId: ?string,
                  +profilePictureDefaultId: ?number,
                  +permissions: ?$ReadOnlyArray<?string>,
                  +client: ?{|
                    +id: string
                  |},
                |}>,
                +taskDismissedNotifications: ?{|
                  +taskOverrunPredictionDismissed: ?boolean
                |},
                +thisTaskDependsOn: ?{|
                  +edges: ?$ReadOnlyArray<?{|
                    +node: ?{|
                      +id: string,
                      +type: ?DEPENDENCY_TYPE,
                      +thisDependsOnTask: ?{|
                        +id: string,
                        +name: ?string,
                        +deadlineYear: ?number,
                        +deadlineMonth: ?number,
                        +deadlineDay: ?number,
                        +startYear: ?number,
                        +startMonth: ?number,
                        +startDay: ?number,
                      |},
                    |}
                  |}>
                |},
                +owner: ?{|
                  +id: string,
                  +permissions: ?$ReadOnlyArray<?string>,
                |},
                +followers: ?$ReadOnlyArray<?{|
                  +id: string,
                  +permissions: ?$ReadOnlyArray<?string>,
                |}>,
                +parentTask: ?{|
                  +id: string,
                  +name: ?string,
                |},
                +progressDetails: ?{|
                  +progress: ?number
                |},
              |}
            |}>
          |},
          +projectPersons: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +person: ?{|
                  +id: string,
                  +active: ?boolean,
                  +permissions: ?$ReadOnlyArray<?string>,
                  +firstName: ?string,
                  +lastName: ?string,
                  +fullName: ?string,
                  +initials: ?string,
                  +profilePictureId: ?string,
                  +profilePictureDefaultId: ?number,
                  +role: ?{|
                    +id: string,
                    +name: ?string,
                  |},
                  +client: ?{|
                    +id: string
                  |},
                |},
                +role: ?{|
                  +id: string,
                  +name: ?string,
                |},
              |}
            |}>
          |},
          +sprints: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +name: ?string,
                +projectGroupSprintId: ?string,
                +isProjectGroupSprint: ?boolean,
                +startDay: ?number,
                +startMonth: ?number,
                +startYear: ?number,
                +endDay: ?number,
                +endMonth: ?number,
                +endYear: ?number,
                +description?: ?string,
                +savedPerformance: ?number,
                +hasUnfinishedTask: ?boolean,
                +integrations: ?{|
                  +id: string,
                  +jiraId: ?string,
                  +adoId: ?string,
                |},
              |}
            |}>
          |},
          +phases: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +name: ?string,
              |}
            |}>
          |},
          +statusColumnsV2: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +order: ?number,
                +name: ?string,
                +projectGroupStatusColumnId: ?number,
                +jiraStatusId: ?string,
                +category: ?STATUS_CATEGORY,
              |}
            |}>
          |},
          +$fragmentRefs: DeprecatedProjectIndicatorJS_project$ref,
        |}
      |}>
    |},
  |},
  +project: ?{|
    +id: string,
    +name: ?string,
    +remainingAutoCalculated: ?boolean,
    +tasks: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +readOnly: ?{|
            +isReadOnly: ?boolean
          |},
          +userCanDeleteTask: ?boolean,
          +userCantDeleteTaskReason: ?CANT_DELETE_TASK_REASON,
          +name: ?string,
          +description: ?string,
          +highPriority: ?boolean,
          +companyTaskId: ?number,
          +estimateForecast: ?number,
          +timeLeft?: ?number,
          +currentPrice?: ?number,
          +timeRegistrations?: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +minutesRegistered: ?number,
                +price: ?number,
                +invoiced: ?boolean,
                +person: ?{|
                  +id: string,
                  +role: ?{|
                    +id: string
                  |},
                |},
              |}
            |}>
          |},
          +progress?: ?number,
          +approved: ?boolean,
          +sortOrder: ?number,
          +estimateForecastPrice: ?number,
          +blocked: ?boolean,
          +bug: ?boolean,
          +deadlineYear: ?number,
          +deadlineMonth: ?number,
          +deadlineDay: ?number,
          +startYear: ?number,
          +startMonth: ?number,
          +startDay: ?number,
          +startFrom: ?string,
          +deadlineFrom: ?string,
          +hasDependency: ?boolean,
          +canStart: ?boolean,
          +canBeSetToDone: ?boolean,
          +subTaskCount: ?number,
          +latestUiUpdateAt: ?string,
          +hasLockedTime: ?boolean,
          +hasInvoicedTime: ?boolean,
          +favoured: ?boolean,
          +billable: ?boolean,
          +parentTaskId: ?string,
          +taskLabels: ?$ReadOnlyArray<?{|
            +id: string,
            +label: ?{|
              +id: string,
              +name: ?string,
              +color: ?string,
            |},
          |}>,
          +sprint: ?{|
            +id: string,
            +endYear: ?number,
            +endMonth: ?number,
            +endDay: ?number,
            +name: ?string,
          |},
          +statusColumnV2: ?{|
            +id: string,
            +name: ?string,
            +category: ?STATUS_CATEGORY,
          |},
          +phase: ?{|
            +id: string,
            +name: ?string,
            +deadlineYear: ?number,
            +deadlineMonth: ?number,
            +deadlineDay: ?number,
          |},
          +role: ?{|
            +id: string,
            +name: ?string,
          |},
          +assignedPersons: ?$ReadOnlyArray<?{|
            +id: string,
            +active: ?boolean,
            +firstName: ?string,
            +lastName: ?string,
            +email: ?string,
            +profilePictureId: ?string,
            +profilePictureDefaultId: ?number,
            +permissions: ?$ReadOnlyArray<?string>,
            +client: ?{|
              +id: string
            |},
          |}>,
          +taskDismissedNotifications: ?{|
            +taskOverrunPredictionDismissed: ?boolean
          |},
          +project: ?{|
            +id: string,
            +name: ?string,
            +demo: ?boolean,
            +client: ?{|
              +id: string,
              +name: ?string,
            |},
            +harvestProject: ?{|
              +id: string
            |},
            +projectPerson: ?{|
              +role: ?{|
                +id: string,
                +name: ?string,
              |}
            |},
            +remainingAutoCalculated: ?boolean,
            +companyProjectId: ?number,
            +estimationUnit: ?string,
            +minutesPerEstimationPoint: ?number,
            +projectStartYear: ?number,
            +projectStartMonth: ?number,
            +projectStartDay: ?number,
            +projectEndYear: ?number,
            +projectEndMonth: ?number,
            +projectEndDay: ?number,
            +projectColor: ?string,
            +status: ?PROJECT_STATUS,
            +useTaskHierarchy: ?boolean,
          |},
          +thisTaskDependsOn: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +type: ?DEPENDENCY_TYPE,
                +thisDependsOnTask: ?{|
                  +id: string,
                  +name: ?string,
                  +deadlineYear: ?number,
                  +deadlineMonth: ?number,
                  +deadlineDay: ?number,
                  +startYear: ?number,
                  +startMonth: ?number,
                  +startDay: ?number,
                |},
              |}
            |}>
          |},
          +owner: ?{|
            +id: string,
            +permissions: ?$ReadOnlyArray<?string>,
          |},
          +followers: ?$ReadOnlyArray<?{|
            +id: string,
            +permissions: ?$ReadOnlyArray<?string>,
          |}>,
          +parentTask: ?{|
            +id: string,
            +name: ?string,
          |},
          +progressDetails: ?{|
            +progress: ?number
          |},
        |}
      |}>
    |},
    +projectPersons: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +person: ?{|
            +id: string,
            +active: ?boolean,
            +permissions: ?$ReadOnlyArray<?string>,
            +firstName: ?string,
            +lastName: ?string,
            +fullName: ?string,
            +initials: ?string,
            +profilePictureId: ?string,
            +profilePictureDefaultId: ?number,
            +role: ?{|
              +id: string,
              +name: ?string,
            |},
            +client: ?{|
              +id: string
            |},
          |},
          +role: ?{|
            +id: string,
            +name: ?string,
          |},
        |}
      |}>
    |},
    +sprints: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +timeLeft: ?number,
          +forecast: ?number,
          +startDay: ?number,
          +startMonth: ?number,
          +startYear: ?number,
          +endDay: ?number,
          +endMonth: ?number,
          +endYear: ?number,
          +description?: ?string,
          +savedPerformance: ?number,
          +hasUnfinishedTask: ?boolean,
          +sprintPersons: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +availableMinutes?: ?number,
                +scheduledMinutes?: ?number,
                +taskAvailableMinutes?: ?number,
                +person: ?{|
                  +id: string,
                  +firstName: ?string,
                  +lastName: ?string,
                  +profilePictureId: ?string,
                  +profilePictureDefaultId: ?number,
                  +role: ?{|
                    +id: string,
                    +name: ?string,
                  |},
                |},
              |}
            |}>
          |},
          +integrations: ?{|
            +id: string,
            +jiraId: ?string,
            +adoId: ?string,
          |},
        |}
      |}>
    |},
    +phases: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |}
      |}>
    |},
    +statusColumnsV2: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +order: ?number,
          +name: ?string,
          +projectGroupStatusColumnId: ?number,
          +jiraStatusId: ?string,
          +category: ?STATUS_CATEGORY,
        |}
      |}>
    |},
  |},
  +company: ?{|
    +id: string,
    +backendId: ?number,
    +name: ?string,
    +currency: ?string,
    +harvestEnabled: ?boolean,
    +lockedPeriodYear: ?number,
    +lockedPeriodMonth: ?number,
    +lockedPeriodDay: ?number,
    +isUsingProjectAllocation: ?boolean,
    +modules: ?$ReadOnlyArray<?{|
      +moduleType: ?string
    |}>,
    +roles: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |}
      |}>
    |},
    +labels: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +color: ?string,
          +category: ?{|
            +id: string,
            +name: ?string,
          |},
          +$fragmentRefs: LabelDropdown_labels$ref,
        |}
      |}>
    |},
    +clients: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +logoId: ?string,
          +logoDefaultId: ?number,
        |}
      |}>
    |},
    +teams: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +teamPersons: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +person: ?{|
                  +id: string
                |},
              |}
            |}>
          |},
        |},
        +$fragmentRefs: TeamDropdown_teams$ref,
      |}>
    |},
    +monday: ?number,
    +tuesday: ?number,
    +wednesday: ?number,
    +thursday: ?number,
    +friday: ?number,
    +saturday: ?number,
    +sunday: ?number,
    +$fragmentRefs: genericTaskContextMenu_company$ref,
  |},
  +$refType: ProjectSprintBacklogWrapper_viewer$ref,
|};
export type ProjectSprintBacklogWrapper_viewer$data = ProjectSprintBacklogWrapper_viewer;
export type ProjectSprintBacklogWrapper_viewer$key = {
  +$data?: ProjectSprintBacklogWrapper_viewer$data,
  +$fragmentRefs: ProjectSprintBacklogWrapper_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "count": null,
  "cursor": null,
  "direction": "forward",
  "path": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color",
  "storageKey": null
},
v4 = {
  "kind": "Literal",
  "name": "first",
  "value": 1000000
},
v5 = [
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "demo",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyProjectId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "remainingAutoCalculated",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectColor",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "estimationUnit",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minutesPerEstimationPoint",
  "storageKey": null
},
v13 = [
  {
    "kind": "Literal",
    "name": "backlog",
    "value": true
  }
],
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "TaskReadOnly",
  "kind": "LinkedField",
  "name": "readOnly",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isReadOnly",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userCanDeleteTask",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userCantDeleteTaskReason",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "highPriority",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyTaskId",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "estimateForecast",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "approved",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sortOrder",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "blocked",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bug",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "estimateForecastPrice",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineYear",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineMonth",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineDay",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startYear",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startMonth",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDay",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startFrom",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineFrom",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasDependency",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canStart",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canBeSetToDone",
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subTaskCount",
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latestUiUpdateAt",
  "storageKey": null
},
v39 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasLockedTime",
  "storageKey": null
},
v40 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasInvoicedTime",
  "storageKey": null
},
v41 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "favoured",
  "storageKey": null
},
v42 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billable",
  "storageKey": null
},
v43 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "parentTaskId",
  "storageKey": null
},
v44 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectEndYear",
  "storageKey": null
},
v45 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectEndMonth",
  "storageKey": null
},
v46 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectEndDay",
  "storageKey": null
},
v47 = [
  (v1/*: any*/),
  (v2/*: any*/)
],
v48 = {
  "alias": null,
  "args": null,
  "concreteType": "Role",
  "kind": "LinkedField",
  "name": "role",
  "plural": false,
  "selections": (v47/*: any*/),
  "storageKey": null
},
v49 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "personId",
      "variableName": "personId"
    }
  ],
  "concreteType": "ProjectPerson",
  "kind": "LinkedField",
  "name": "projectPerson",
  "plural": false,
  "selections": [
    (v48/*: any*/)
  ],
  "storageKey": null
},
v50 = [
  (v1/*: any*/)
],
v51 = {
  "alias": null,
  "args": null,
  "concreteType": "HarvestProjectType",
  "kind": "LinkedField",
  "name": "harvestProject",
  "plural": false,
  "selections": (v50/*: any*/),
  "storageKey": null
},
v52 = {
  "alias": null,
  "args": null,
  "concreteType": "Client",
  "kind": "LinkedField",
  "name": "client",
  "plural": false,
  "selections": (v50/*: any*/),
  "storageKey": null
},
v53 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v54 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "useTaskHierarchy",
  "storageKey": null
},
v55 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "DeprecatedProjectIndicatorJS_project"
},
v56 = {
  "alias": null,
  "args": null,
  "concreteType": "TaskLabel",
  "kind": "LinkedField",
  "name": "taskLabels",
  "plural": true,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Label",
      "kind": "LinkedField",
      "name": "label",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v57 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endYear",
  "storageKey": null
},
v58 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endMonth",
  "storageKey": null
},
v59 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDay",
  "storageKey": null
},
v60 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "savedPerformance",
  "storageKey": null
},
v61 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectGroupSprintId",
  "storageKey": null
},
v62 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isProjectGroupSprint",
  "storageKey": null
},
v63 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "category",
  "storageKey": null
},
v64 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectGroupStatusColumnId",
  "storageKey": null
},
v65 = {
  "alias": null,
  "args": null,
  "concreteType": "PhaseType",
  "kind": "LinkedField",
  "name": "phase",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    (v2/*: any*/),
    (v26/*: any*/),
    (v27/*: any*/),
    (v28/*: any*/)
  ],
  "storageKey": null
},
v66 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v67 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v68 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v69 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePictureId",
  "storageKey": null
},
v70 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePictureDefaultId",
  "storageKey": null
},
v71 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "permissions",
  "storageKey": null
},
v72 = {
  "alias": null,
  "args": null,
  "concreteType": "TaskDismissedNotifications",
  "kind": "LinkedField",
  "name": "taskDismissedNotifications",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "taskOverrunPredictionDismissed",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v73 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v74 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v75 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v76 = {
  "alias": "thisTaskDependsOn",
  "args": null,
  "concreteType": "DependencyTypeConnection",
  "kind": "LinkedField",
  "name": "__Task_thisTaskDependsOn_connection",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DependencyTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DependencyType",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Task",
              "kind": "LinkedField",
              "name": "thisDependsOnTask",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/),
                (v26/*: any*/),
                (v27/*: any*/),
                (v28/*: any*/),
                (v29/*: any*/),
                (v30/*: any*/),
                (v31/*: any*/)
              ],
              "storageKey": null
            },
            (v73/*: any*/)
          ],
          "storageKey": null
        },
        (v74/*: any*/)
      ],
      "storageKey": null
    },
    (v75/*: any*/)
  ],
  "storageKey": null
},
v77 = [
  (v1/*: any*/),
  (v71/*: any*/)
],
v78 = {
  "alias": null,
  "args": null,
  "concreteType": "Person",
  "kind": "LinkedField",
  "name": "owner",
  "plural": false,
  "selections": (v77/*: any*/),
  "storageKey": null
},
v79 = {
  "alias": null,
  "args": null,
  "concreteType": "Person",
  "kind": "LinkedField",
  "name": "followers",
  "plural": true,
  "selections": (v77/*: any*/),
  "storageKey": null
},
v80 = {
  "alias": null,
  "args": null,
  "concreteType": "Task",
  "kind": "LinkedField",
  "name": "parentTask",
  "plural": false,
  "selections": (v47/*: any*/),
  "storageKey": null
},
v81 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "progress",
  "storageKey": null
},
v82 = {
  "alias": null,
  "args": null,
  "concreteType": "TaskProgress",
  "kind": "LinkedField",
  "name": "progressDetails",
  "plural": false,
  "selections": [
    (v81/*: any*/)
  ],
  "storageKey": null
},
v83 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeLeft",
  "storageKey": null
},
v84 = {
  "condition": "fetchLazyData",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    (v83/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentPrice",
      "storageKey": null
    },
    {
      "alias": "timeRegistrations",
      "args": null,
      "concreteType": "TimeRegTypeConnection",
      "kind": "LinkedField",
      "name": "__Task_timeRegistrations_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TimeRegTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TimeRegType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "minutesRegistered",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "price",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "invoiced",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Person",
                  "kind": "LinkedField",
                  "name": "person",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Role",
                      "kind": "LinkedField",
                      "name": "role",
                      "plural": false,
                      "selections": (v50/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v73/*: any*/)
              ],
              "storageKey": null
            },
            (v74/*: any*/)
          ],
          "storageKey": null
        },
        (v75/*: any*/)
      ],
      "storageKey": null
    },
    (v81/*: any*/)
  ]
},
v85 = {
  "alias": null,
  "args": (v5/*: any*/),
  "concreteType": "ProjectPersonTypeConnection",
  "kind": "LinkedField",
  "name": "projectPersons",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectPersonTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectPerson",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Person",
              "kind": "LinkedField",
              "name": "person",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v66/*: any*/),
                (v71/*: any*/),
                (v67/*: any*/),
                (v68/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fullName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "initials",
                  "storageKey": null
                },
                (v69/*: any*/),
                (v70/*: any*/),
                (v48/*: any*/),
                (v52/*: any*/)
              ],
              "storageKey": null
            },
            (v48/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "projectPersons(first:1000000)"
},
v86 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasUnfinishedTask",
  "storageKey": null
},
v87 = {
  "alias": null,
  "args": null,
  "concreteType": "SprintIntegrationsType",
  "kind": "LinkedField",
  "name": "integrations",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jiraId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adoId",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v88 = {
  "condition": "fetchLazyData",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    (v17/*: any*/)
  ]
},
v89 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 1000
    }
  ],
  "concreteType": "PhaseTypeConnection",
  "kind": "LinkedField",
  "name": "phases",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PhaseTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PhaseType",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v47/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "phases(first:1000)"
},
v90 = {
  "alias": null,
  "args": (v5/*: any*/),
  "concreteType": "StatusColumnV2TypeConnection",
  "kind": "LinkedField",
  "name": "statusColumnsV2",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StatusColumnV2TypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "StatusColumnV2",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "order",
              "storageKey": null
            },
            (v2/*: any*/),
            (v64/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "jiraStatusId",
              "storageKey": null
            },
            (v63/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "statusColumnsV2(first:1000000)"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectId",
      "type": "ID"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "groupId",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "fetchLazyData",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "personId",
      "type": "ID!"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      (v0/*: any*/),
      (v0/*: any*/),
      (v0/*: any*/),
      (v0/*: any*/),
      (v0/*: any*/),
      (v0/*: any*/),
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "project",
          "tasks"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "project",
          "sprints"
        ]
      }
    ]
  },
  "name": "ProjectSprintBacklogWrapper_viewer",
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "language",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actualPersonId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FeatureFlag",
      "kind": "LinkedField",
      "name": "availableFeatureFlags",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "groupId"
        }
      ],
      "concreteType": "ProjectGroupType",
      "kind": "LinkedField",
      "name": "projectGroup",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "companyProjectGroupId",
          "storageKey": null
        },
        (v2/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": (v5/*: any*/),
          "concreteType": "ProjectTypeConnection",
          "kind": "LinkedField",
          "name": "projects",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    (v6/*: any*/),
                    (v7/*: any*/),
                    (v8/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "taskLevels",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "sprintTimeBox",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "sprintLengthInDays",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "useManualAllocations",
                      "storageKey": null
                    },
                    (v9/*: any*/),
                    (v10/*: any*/),
                    (v11/*: any*/),
                    (v12/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "manualProgressOnProjectEnabled",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "manualProgressOnPhasesEnabled",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "manualProgressOnTasksEnabled",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "budgetWork",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "budgetType",
                      "storageKey": null
                    },
                    {
                      "alias": "tasks",
                      "args": (v13/*: any*/),
                      "concreteType": "TaskTypeConnection",
                      "kind": "LinkedField",
                      "name": "__Project_tasks_connection",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "TaskTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Task",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/),
                                (v14/*: any*/),
                                (v15/*: any*/),
                                (v16/*: any*/),
                                (v2/*: any*/),
                                (v17/*: any*/),
                                (v18/*: any*/),
                                (v19/*: any*/),
                                (v20/*: any*/),
                                (v21/*: any*/),
                                (v22/*: any*/),
                                (v23/*: any*/),
                                (v24/*: any*/),
                                (v25/*: any*/),
                                (v26/*: any*/),
                                (v27/*: any*/),
                                (v28/*: any*/),
                                (v29/*: any*/),
                                (v30/*: any*/),
                                (v31/*: any*/),
                                (v32/*: any*/),
                                (v33/*: any*/),
                                (v34/*: any*/),
                                (v35/*: any*/),
                                (v36/*: any*/),
                                (v37/*: any*/),
                                (v38/*: any*/),
                                (v39/*: any*/),
                                (v40/*: any*/),
                                (v41/*: any*/),
                                (v42/*: any*/),
                                (v43/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "ProjectType",
                                  "kind": "LinkedField",
                                  "name": "project",
                                  "plural": false,
                                  "selections": [
                                    (v1/*: any*/),
                                    (v6/*: any*/),
                                    (v11/*: any*/),
                                    (v12/*: any*/),
                                    (v8/*: any*/),
                                    (v44/*: any*/),
                                    (v45/*: any*/),
                                    (v46/*: any*/),
                                    (v10/*: any*/),
                                    (v9/*: any*/),
                                    (v2/*: any*/),
                                    (v7/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "projectGroupId",
                                      "storageKey": null
                                    },
                                    (v49/*: any*/),
                                    (v51/*: any*/),
                                    (v52/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "RateCard",
                                      "kind": "LinkedField",
                                      "name": "rateCard",
                                      "plural": false,
                                      "selections": [
                                        (v1/*: any*/),
                                        (v53/*: any*/),
                                        {
                                          "alias": null,
                                          "args": null,
                                          "concreteType": "Role",
                                          "kind": "LinkedField",
                                          "name": "disabledRoles",
                                          "plural": true,
                                          "selections": (v50/*: any*/),
                                          "storageKey": null
                                        }
                                      ],
                                      "storageKey": null
                                    },
                                    (v54/*: any*/),
                                    (v55/*: any*/)
                                  ],
                                  "storageKey": null
                                },
                                (v56/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Sprint",
                                  "kind": "LinkedField",
                                  "name": "sprint",
                                  "plural": false,
                                  "selections": [
                                    (v1/*: any*/),
                                    (v57/*: any*/),
                                    (v58/*: any*/),
                                    (v59/*: any*/),
                                    (v60/*: any*/),
                                    (v2/*: any*/),
                                    (v61/*: any*/),
                                    (v62/*: any*/)
                                  ],
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "StatusColumnV2",
                                  "kind": "LinkedField",
                                  "name": "statusColumnV2",
                                  "plural": false,
                                  "selections": [
                                    (v1/*: any*/),
                                    (v2/*: any*/),
                                    (v63/*: any*/),
                                    (v64/*: any*/)
                                  ],
                                  "storageKey": null
                                },
                                (v65/*: any*/),
                                (v48/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Person",
                                  "kind": "LinkedField",
                                  "name": "assignedPersons",
                                  "plural": true,
                                  "selections": [
                                    (v1/*: any*/),
                                    (v66/*: any*/),
                                    (v67/*: any*/),
                                    (v68/*: any*/),
                                    (v69/*: any*/),
                                    (v70/*: any*/),
                                    (v71/*: any*/),
                                    (v52/*: any*/)
                                  ],
                                  "storageKey": null
                                },
                                (v72/*: any*/),
                                (v76/*: any*/),
                                (v78/*: any*/),
                                (v79/*: any*/),
                                (v80/*: any*/),
                                (v82/*: any*/),
                                (v73/*: any*/),
                                (v84/*: any*/)
                              ],
                              "storageKey": null
                            },
                            (v74/*: any*/)
                          ],
                          "storageKey": null
                        },
                        (v75/*: any*/)
                      ],
                      "storageKey": "__Project_tasks_connection(backlog:true)"
                    },
                    (v85/*: any*/),
                    {
                      "alias": "sprints",
                      "args": null,
                      "concreteType": "SprintTypeConnection",
                      "kind": "LinkedField",
                      "name": "__Project_sprints_connection",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "SprintTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Sprint",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/),
                                (v2/*: any*/),
                                (v61/*: any*/),
                                (v62/*: any*/),
                                (v31/*: any*/),
                                (v30/*: any*/),
                                (v29/*: any*/),
                                (v59/*: any*/),
                                (v58/*: any*/),
                                (v57/*: any*/),
                                (v60/*: any*/),
                                (v86/*: any*/),
                                (v87/*: any*/),
                                (v73/*: any*/),
                                (v88/*: any*/)
                              ],
                              "storageKey": null
                            },
                            (v74/*: any*/)
                          ],
                          "storageKey": null
                        },
                        (v75/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v89/*: any*/),
                    (v90/*: any*/),
                    (v55/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "projects(first:1000000)"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "internalId",
          "variableName": "projectId"
        }
      ],
      "concreteType": "ProjectType",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/),
        (v9/*: any*/),
        {
          "alias": "tasks",
          "args": (v13/*: any*/),
          "concreteType": "TaskTypeConnection",
          "kind": "LinkedField",
          "name": "__Project_tasks_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TaskTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Task",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v14/*: any*/),
                    (v15/*: any*/),
                    (v16/*: any*/),
                    (v2/*: any*/),
                    (v17/*: any*/),
                    (v18/*: any*/),
                    (v19/*: any*/),
                    (v20/*: any*/),
                    (v21/*: any*/),
                    (v22/*: any*/),
                    (v25/*: any*/),
                    (v23/*: any*/),
                    (v24/*: any*/),
                    (v26/*: any*/),
                    (v27/*: any*/),
                    (v28/*: any*/),
                    (v29/*: any*/),
                    (v30/*: any*/),
                    (v31/*: any*/),
                    (v32/*: any*/),
                    (v33/*: any*/),
                    (v34/*: any*/),
                    (v35/*: any*/),
                    (v36/*: any*/),
                    (v37/*: any*/),
                    (v38/*: any*/),
                    (v39/*: any*/),
                    (v40/*: any*/),
                    (v41/*: any*/),
                    (v42/*: any*/),
                    (v43/*: any*/),
                    (v56/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Sprint",
                      "kind": "LinkedField",
                      "name": "sprint",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v57/*: any*/),
                        (v58/*: any*/),
                        (v59/*: any*/),
                        (v2/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "StatusColumnV2",
                      "kind": "LinkedField",
                      "name": "statusColumnV2",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v2/*: any*/),
                        (v63/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v65/*: any*/),
                    (v48/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Person",
                      "kind": "LinkedField",
                      "name": "assignedPersons",
                      "plural": true,
                      "selections": [
                        (v1/*: any*/),
                        (v66/*: any*/),
                        (v67/*: any*/),
                        (v68/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "email",
                          "storageKey": null
                        },
                        (v69/*: any*/),
                        (v70/*: any*/),
                        (v71/*: any*/),
                        (v52/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v72/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProjectType",
                      "kind": "LinkedField",
                      "name": "project",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v2/*: any*/),
                        (v6/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Client",
                          "kind": "LinkedField",
                          "name": "client",
                          "plural": false,
                          "selections": (v47/*: any*/),
                          "storageKey": null
                        },
                        (v51/*: any*/),
                        (v49/*: any*/),
                        (v9/*: any*/),
                        (v8/*: any*/),
                        (v11/*: any*/),
                        (v12/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "projectStartYear",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "projectStartMonth",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "projectStartDay",
                          "storageKey": null
                        },
                        (v44/*: any*/),
                        (v45/*: any*/),
                        (v46/*: any*/),
                        (v10/*: any*/),
                        (v7/*: any*/),
                        (v54/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v76/*: any*/),
                    (v78/*: any*/),
                    (v79/*: any*/),
                    (v80/*: any*/),
                    (v82/*: any*/),
                    (v73/*: any*/),
                    (v84/*: any*/)
                  ],
                  "storageKey": null
                },
                (v74/*: any*/)
              ],
              "storageKey": null
            },
            (v75/*: any*/)
          ],
          "storageKey": "__Project_tasks_connection(backlog:true)"
        },
        (v85/*: any*/),
        {
          "alias": "sprints",
          "args": null,
          "concreteType": "SprintTypeConnection",
          "kind": "LinkedField",
          "name": "__Project_sprints_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SprintTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Sprint",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    (v83/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "forecast",
                      "storageKey": null
                    },
                    (v31/*: any*/),
                    (v30/*: any*/),
                    (v29/*: any*/),
                    (v59/*: any*/),
                    (v58/*: any*/),
                    (v57/*: any*/),
                    (v60/*: any*/),
                    (v86/*: any*/),
                    {
                      "alias": null,
                      "args": (v5/*: any*/),
                      "concreteType": "SprintPersonTypeConnection",
                      "kind": "LinkedField",
                      "name": "sprintPersons",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "SprintPersonTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "SprintPerson",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Person",
                                  "kind": "LinkedField",
                                  "name": "person",
                                  "plural": false,
                                  "selections": [
                                    (v1/*: any*/),
                                    (v67/*: any*/),
                                    (v68/*: any*/),
                                    (v69/*: any*/),
                                    (v70/*: any*/),
                                    (v48/*: any*/)
                                  ],
                                  "storageKey": null
                                },
                                {
                                  "condition": "fetchLazyData",
                                  "kind": "Condition",
                                  "passingValue": true,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "availableMinutes",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "scheduledMinutes",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "taskAvailableMinutes",
                                      "storageKey": null
                                    }
                                  ]
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": "sprintPersons(first:1000000)"
                    },
                    (v87/*: any*/),
                    (v73/*: any*/),
                    (v88/*: any*/)
                  ],
                  "storageKey": null
                },
                (v74/*: any*/)
              ],
              "storageKey": null
            },
            (v75/*: any*/)
          ],
          "storageKey": null
        },
        (v89/*: any*/),
        (v90/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "backendId",
          "storageKey": null
        },
        (v2/*: any*/),
        (v53/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "harvestEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lockedPeriodYear",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lockedPeriodMonth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lockedPeriodDay",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isUsingProjectAllocation",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Module",
          "kind": "LinkedField",
          "name": "modules",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "moduleType",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v5/*: any*/),
          "concreteType": "RoleTypeConnection",
          "kind": "LinkedField",
          "name": "roles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RoleTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Role",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v47/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "roles(first:1000000)"
        },
        {
          "alias": null,
          "args": [
            (v4/*: any*/),
            {
              "kind": "Literal",
              "name": "labelType",
              "value": "TASK"
            }
          ],
          "concreteType": "LabelTypeConnection",
          "kind": "LinkedField",
          "name": "labels",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LabelTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Label",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "LabelCategory",
                      "kind": "LinkedField",
                      "name": "category",
                      "plural": false,
                      "selections": (v47/*: any*/),
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "LabelDropdown_labels"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "labels(first:1000000,labelType:\"TASK\")"
        },
        {
          "alias": null,
          "args": (v5/*: any*/),
          "concreteType": "ClientTypeConnection",
          "kind": "LinkedField",
          "name": "clients",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ClientTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Client",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "logoId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "logoDefaultId",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "clients(first:1000000)"
        },
        {
          "alias": null,
          "args": (v5/*: any*/),
          "concreteType": "TeamTypeConnection",
          "kind": "LinkedField",
          "name": "teams",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TeamTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Team",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": (v5/*: any*/),
                      "concreteType": "TeamPersonTypeConnection",
                      "kind": "LinkedField",
                      "name": "teamPersons",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "TeamPersonTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "TeamPerson",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Person",
                                  "kind": "LinkedField",
                                  "name": "person",
                                  "plural": false,
                                  "selections": (v50/*: any*/),
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": "teamPersons(first:1000000)"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "TeamDropdown_teams"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "teams(first:1000000)"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "monday",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tuesday",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "wednesday",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thursday",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "friday",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "saturday",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sunday",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "genericTaskContextMenu_company"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f90d59b09dc38f8d24c8c8fbc2f49e09';

module.exports = node;
