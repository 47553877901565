/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TrackingComponent_viewer$ref = any;
export type TrackingComponent_QueryVariables = {||};
export type TrackingComponent_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: TrackingComponent_viewer$ref,
  |}
|};
export type TrackingComponent_Query = {|
  variables: TrackingComponent_QueryVariables,
  response: TrackingComponent_QueryResponse,
|};
*/


/*
query TrackingComponent_Query {
  viewer {
    actualPersonId
    component(name: "tracking_component")
    ...TrackingComponent_viewer
    id
  }
}

fragment TrackingComponent_viewer on Viewer {
  id
  firstName
  lastName
  backendId
  actualPersonId
  email
  csrfToken
  socketFullUpdate
  createdAt
  isFirstPersonInCompany
  language
  setToRunningNotificationLastDismissalDate
  role {
    name
    id
  }
  company {
    id
    backendId
    createdAt
    name
    socketEnabled
    tier
    trialEndTime
    accessBlocked
    isUsingSchedulingPlanMode
    isUsingProjectAllocation
    isUsingMixedAllocation
    userSeats
    virtualSeats
    businessType
    betaOptIn
    slackEnabled
    teamsEnabled
    vstsEnabled
    githubEnabled
    gitlabEnabled
    salesforceEnabled
    unit4Enabled
    jiraCloudEnabled
    xeroEnabled
    newXeroEnabled
    quickbooksEnabled
    economicEnabled
    oktaEnabled
    oneloginEnabled
    AADEnabled
    gdriveEnabled
    harvestEnabled
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "tracking_component"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"tracking_component\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "backendId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TrackingComponent_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TrackingComponent_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "TrackingComponent_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "csrfToken",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "socketFullUpdate",
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isFirstPersonInCompany",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "language",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "setToRunningNotificationLastDismissalDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Role",
            "kind": "LinkedField",
            "name": "role",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "socketEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tier",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "trialEndTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accessBlocked",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isUsingSchedulingPlanMode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isUsingProjectAllocation",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isUsingMixedAllocation",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "userSeats",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "virtualSeats",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "businessType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "betaOptIn",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slackEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "teamsEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "vstsEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "githubEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "gitlabEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "salesforceEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "unit4Enabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "jiraCloudEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "xeroEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "newXeroEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "quickbooksEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "economicEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "oktaEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "oneloginEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "AADEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "gdriveEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "harvestEnabled",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "TrackingComponent_Query",
    "operationKind": "query",
    "text": "query TrackingComponent_Query {\n  viewer {\n    actualPersonId\n    component(name: \"tracking_component\")\n    ...TrackingComponent_viewer\n    id\n  }\n}\n\nfragment TrackingComponent_viewer on Viewer {\n  id\n  firstName\n  lastName\n  backendId\n  actualPersonId\n  email\n  csrfToken\n  socketFullUpdate\n  createdAt\n  isFirstPersonInCompany\n  language\n  setToRunningNotificationLastDismissalDate\n  role {\n    name\n    id\n  }\n  company {\n    id\n    backendId\n    createdAt\n    name\n    socketEnabled\n    tier\n    trialEndTime\n    accessBlocked\n    isUsingSchedulingPlanMode\n    isUsingProjectAllocation\n    isUsingMixedAllocation\n    userSeats\n    virtualSeats\n    businessType\n    betaOptIn\n    slackEnabled\n    teamsEnabled\n    vstsEnabled\n    githubEnabled\n    gitlabEnabled\n    salesforceEnabled\n    unit4Enabled\n    jiraCloudEnabled\n    xeroEnabled\n    newXeroEnabled\n    quickbooksEnabled\n    economicEnabled\n    oktaEnabled\n    oneloginEnabled\n    AADEnabled\n    gdriveEnabled\n    harvestEnabled\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c5b6495f8fde465dcdfd21e95d07767b';

module.exports = node;
