import React from 'react';
import Styled from 'styled-components/macro';

export const ProgressSuggestionWrapper = Styled.div`
    display: flex;
    margin-left: 8px;
    height: 30px;
`;

const ProgressSuggestionTableCell = Styled.div`
  
    width: 79px;
    border: 1px solid  ${props => props.theme.border.secondary};
    padding: 8px 0;
     border-right: 0;
    
    color: ${props => props.theme.colors.textGray};
    font-weight: 700;
    font-size: 12px;
    cursor: pointer;
    
    display: flex;
    align-items: center;
    justify-content: center;
    
    background-color: ${props => (props.selected ? props.theme.colors.forecastPurpleLight : 'none')};
    
    &:hover {       
        background-color: ${props => props.theme.colors.forecastPurpleLight};
    }
    
    &:first-child{
        border-right: 0;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    
    &:last-child {
 				border-right: 1px solid  ${props => props.theme.border.secondary};
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
    
    &.disabled {
        pointer-events: none;
        color: ${props => props.theme.colors.veryLightGray};
    }
`;

export const ProgressSuggestion = ({option, onClick, selected}) => {
	return (
		<ProgressSuggestionTableCell selected={selected} onMouseDown={() => onClick(option)}>
			<>{option + '%'}</>
		</ProgressSuggestionTableCell>
	);
};
