import React from 'react';

const repeatingTaskIcon = props => (
	<svg className={'repeating-task-icon ' + (props.className ? props.className : '')} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
		<g fill="none" fillRule="evenodd">
			<path d="M0 0h16v16H0z" />
			<path
				fill="#A1A1A1"
				d="M4.8 4.566a3.203 3.203 0 0 0-3.2 3.2H0c0-2.648 2.153-4.8 4.8-4.8h6.069L9.034 1.13 10.166 0l3.2 3.2a.8.8 0 0 1 0 1.131l-3.2 3.2L9.034 6.4l1.835-1.834H4.8zm9.6 3.2H16c0 2.647-2.153 4.8-4.8 4.8H5.131L6.966 14.4l-1.132 1.131-3.2-3.2a.8.8 0 0 1 0-1.131l3.2-3.2 1.132 1.131-1.835 1.835H11.2c1.765 0 3.2-1.436 3.2-3.2z"
			/>
		</g>
	</svg>
);

export default repeatingTaskIcon;
