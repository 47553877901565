import React, { useMemo, useState } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Modal, Table, Text, Tooltip } from '@forecast-it/design-system';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import DeleteClientBillTosMutation from '../../../../mutations/invoicing/DeleteClientBillTosMutation';
import Util from '../../../shared/util/util';
const UnassociateBillToModal = ({ billToId, closeModal, viewer }) => {
    var _a;
    const { formatMessage } = useIntl();
    const [selectedRows, setSelectedRows] = useState([]);
    // Get clients that have this bill to associated
    const clients = useMemo(() => {
        var _a, _b, _c, _d;
        return ((_d = (_c = (_b = (_a = viewer.company) === null || _a === void 0 ? void 0 : _a.clients) === null || _b === void 0 ? void 0 : _b.edges) === null || _c === void 0 ? void 0 : _c.map(edge => edge === null || edge === void 0 ? void 0 : edge.node)) === null || _d === void 0 ? void 0 : _d.filter(client => { var _a, _b; return (_b = (_a = client === null || client === void 0 ? void 0 : client.clientBillTos) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.some(billTo => { var _a, _b; return ((_b = (_a = billTo === null || billTo === void 0 ? void 0 : billTo.node) === null || _a === void 0 ? void 0 : _a.billTo) === null || _b === void 0 ? void 0 : _b.id) === billToId; }); })) || [];
    }, [viewer]);
    const invoicedClient = useMemo(() => new Set(clients
        .filter(client => {
        var _a, _b;
        return (_b = (_a = client === null || client === void 0 ? void 0 : client.clientBillTos) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.some(clientBillTo => { var _a, _b, _c; return ((_b = (_a = clientBillTo === null || clientBillTo === void 0 ? void 0 : clientBillTo.node) === null || _a === void 0 ? void 0 : _a.billTo) === null || _b === void 0 ? void 0 : _b.id) === billToId && ((_c = clientBillTo === null || clientBillTo === void 0 ? void 0 : clientBillTo.node) === null || _c === void 0 ? void 0 : _c.invoiced); });
    })
        .map(client => client.id)), [clients]);
    const unAssociateBillTo = () => {
        if (clients.length === 1) {
            // This is in case there is only one client to un-associate.
            // We do no show a list from which the user can select, but just
            // a confirmation message with the name of the client.
            selectedRows.push(clients[0]);
        }
        // Get the client bill to ids to delete
        const clientBillTosToDelete = selectedRows
            .flatMap(client => client.clientBillTos.edges)
            .filter(clientBillTo => clientBillTo.node.billTo.id === billToId)
            .map(clientBillTo => clientBillTo.node.id);
        Util.CommitMutation(DeleteClientBillTosMutation, {
            clientBillToIds: clientBillTosToDelete,
        }, closeModal);
    };
    const areThereClients = clients.length > 0;
    return (React.createElement(Modal, { onCloseModal: closeModal },
        React.createElement(Modal.Title, null, formatMessage({ id: 'settings_finance.bill_to.unassociate_client' })),
        React.createElement(Modal.Content, null, areThereClients ? (clients.length > 1 ? (React.createElement(React.Fragment, null,
            React.createElement(Text, { size: '1' },
                React.createElement("b", null, formatMessage({ id: 'settings_finance.select_client' }))),
            React.createElement(Text, null, formatMessage({ id: 'settings_finance.unassociate_single_bill_to_entity' })),
            React.createElement(Table, { data: clients, onSelectionChange: setSelectedRows, width: '100%', enableRowSelection: row => !invoicedClient.has(row.original.id) },
                React.createElement(Table.TextColumn, { header: formatMessage({ id: 'common.select_all' }), accessorKey: 'name', disabled: row => invoicedClient.has(row.id), tooltip: row => invoicedClient.has(row.id) ? (React.createElement(Tooltip.Item, { title: formatMessage({
                            id: 'settings_finance.bill_to.unassociate_client.disabled_tooltip',
                        }), details: React.createElement(FormattedHTMLMessage, { id: 'settings_finance.bill_to.unassociate_client.disabled_tooltip.desc' }) })) : undefined })))) : (React.createElement(Text, null,
            React.createElement(FormattedMessage, { id: 'settings_finance.bill_to.unassociate_single_client_message', values: { clientName: (_a = clients[0]) === null || _a === void 0 ? void 0 : _a.name } })))) : (React.createElement(Text, { size: '1' }, formatMessage({ id: 'settings_finance.all_clients_un_associated_from_bill_to_message' })))),
        areThereClients ? (React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { onPress: unAssociateBillTo, disabled: clients.length > 1 && !selectedRows.length }, formatMessage({ id: 'common.un_associate' })),
            React.createElement(Modal.SecondaryFooterButton, { onPress: closeModal }, formatMessage({ id: 'common.close' })))) : (React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { onPress: closeModal }, formatMessage({ id: 'common.close' }))))));
};
export const UnassociateBillToModalQuery = graphql `
	query UnassociateBillToModalQuery {
		viewer {
			actualPersonId
			component(name: "UnsassociateBillToModal")
			...UnassociateBillToModal_viewer
		}
	}
`;
export default createFragmentContainer(UnassociateBillToModal, {
    viewer: graphql `
		fragment UnassociateBillToModal_viewer on Viewer {
			id
			company {
				id
				clients(first: 100000) {
					edges {
						node {
							id
							name
							clientBillTos(first: 10000) @connection(key: "Client_clientBillTos", filters: []) {
								edges {
									node {
										id
										billFrom {
											id
										}
										billTo {
											id
										}
										clientId
										invoiced
									}
								}
							}
						}
					}
				}
			}
		}
	`,
});
