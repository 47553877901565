import React from 'react';
import {AffixedInput} from 'web-components';

export const AffixedInputWrapper = ({
	innerRef,
	label,
	modalView,
	taskModalStyle,
	onClick,
	onKeyDown,
	value,
	disabled,
	callback,
	affix,
	customClassName,
	placeholder,
}) => {
	const handleOnClick = e => {
		if (onClick) {
			onClick(e.target);
		}
	};

	const handleOnKeyDown = e => {
		if (onKeyDown) {
			onKeyDown(e);
		}
	};

	return (
		<div onClick={e => handleOnClick(e)} onKeyDown={e => handleOnKeyDown(e)} className={customClassName}>
			<AffixedInput
				innerRef={innerRef}
				label={label}
				modalView={modalView}
				taskModalStyle={taskModalStyle}
				value={value}
				disabled={disabled}
				callback={val => callback(val)}
				affix={affix}
				placeholder={placeholder}
			/>
		</div>
	);
};
