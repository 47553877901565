import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {cloneDeep} from 'lodash';
import UpdateHolidayCalendarMutation from '../../../mutations/update_holiday_calendar_mutation';
import DeleteHolidayCalendarMutation from '../../../mutations/delete_holiday_calendar_mutation';
import CreateHolidayCalendarEntryMutation from '../../../mutations/create_holiday_calendar_entry_mutation';
import InputField from '../../../components/inputs/input_field';
import {createToast} from '../../shared/components/toasts/another-toast/toaster';
import {MODAL_TYPE, showModal} from '../../shared/components/modals/generic_modal_conductor';
import Util from '../../shared/util/util';
import DatePicker from './date_picker';
import Moment from 'moment';
import HolidayCalendarEntryRow from './settings_holiday_calendars_calendar_entry_row';

class SettingsHolidayCalendarsSingleCalendar extends Component {
	constructor(props) {
		super(props);

		this.state = {
			holidayCalendarName: this.props.holidayCalendar.name,
			gearMenuExpanded: false,
			holidayCalendarEntryName: '',
			holidayCalendarEntryDate: Moment(),
		};
	}

	onNameChange(value) {
		this.setState({holidayCalendarName: value || ''});
	}

	onNewEntryNameChange(e) {
		this.setState({holidayCalendarEntryName: e.target.value || ''});
	}

	onNewEntryDateChange(value) {
		this.setState({holidayCalendarEntryDate: value});
	}

	onNewEntryAddClick() {
		const {holidayCalendarEntryName, holidayCalendarEntryDate} = this.state;
		Util.CommitMutation(
			CreateHolidayCalendarEntryMutation,
			{
				holidayCalendarId: this.props.holidayCalendar.id,
				name: holidayCalendarEntryName,
				year: holidayCalendarEntryDate.year(),
				month: holidayCalendarEntryDate.month() + 1,
				day: holidayCalendarEntryDate.date(),
			},
			null,
			true
		);
		this.setState({holidayCalendarEntryName: ''});
	}

	handleNameBlur() {
		Util.CommitMutation(
			UpdateHolidayCalendarMutation,
			{
				id: this.props.holidayCalendar.id,
				name: this.state.holidayCalendarName,
			},
			null,
			true
		);
	}

	deleteHolidayCalendar() {
		const callbackPositive = params => {
			const onSuccess = result => {
				createToast({duration: 5000, message: this.props.intl.formatMessage({id: 'Holiday calendar deleted'})});
				this.props.history.push('/admin/holidays/');
			};

			Util.CommitMutation(
				DeleteHolidayCalendarMutation,
				{
					id: this.props.holidayCalendar.id,
					companyId: this.props.companyId,
				},
				onSuccess,
				true
			);
		};

		showModal({
			type: MODAL_TYPE.GENERIC_DELETION_WARNING,
			deleteCallback: callbackPositive,
		});
	}

	closeGearMenu() {
		this.setState({gearMenuExpanded: false});
	}

	toggleGearMenu() {
		this.setState({gearMenuExpanded: !this.state.gearMenuExpanded});
	}

	showImportHolidayCalendarEntriesModal() {
		showModal({type: MODAL_TYPE.IMPORT_HOLIDAY_CALENDAR_ENTRIES, holidayCalendarId: this.props.holidayCalendar.id});
	}

	render() {
		const {formatMessage} = this.props.intl;
		const {holidayCalendarEntryName, holidayCalendarEntryDate, gearMenuExpanded, holidayCalendarName} = this.state;
		const {holidayCalendarEntries} = cloneDeep(this.props.holidayCalendar);

		if (holidayCalendarEntries)
			holidayCalendarEntries.edges.sort((a, b) => {
				const aDate = Util.CreateNonUtcMomentDate(a.node.year, a.node.month, a.node.day);
				const bDate = Util.CreateNonUtcMomentDate(b.node.year, b.node.month, b.node.day);
				if (aDate.isBefore(bDate)) return -1;
				if (bDate.isBefore(aDate)) return 1;
				return 0;
			});
		return (
			<div className="settings-holiday-calendar">
				<fieldset>
					<div className="flex-container">
						<div>
							<div className="top-controls">
								<InputField
									cy={'holiday-calendar-name-input'}
									label={formatMessage({id: 'settings_holiday_calendars.holiday_calendar_name'})}
									type="text"
									placeholder={formatMessage({id: 'settings_holiday_calendars.holiday_calendar_name'})}
									value={holidayCalendarName}
									onChange={this.onNameChange.bind(this)}
									onBlur={this.handleNameBlur.bind(this)}
									autosize={true}
								/>
								<button
									data-cy="import-public-holidays"
									className="import-calendar-button"
									onClick={this.showImportHolidayCalendarEntriesModal.bind(this)}
								>
									{formatMessage({id: 'settings_holiday_calendars.import'})}
								</button>
								<button
									data-cy={'add-calendar-button'}
									onClick={this.props.createHolidayCalendar}
									className="add-holiday-calendar-button"
								>
									{formatMessage({id: 'settings_holiday_calendars.add_another_holiday_calendar'})}
								</button>
								<div
									className="gear-menu"
									tabIndex="0"
									onBlur={this.closeGearMenu.bind(this)}
									onClick={this.toggleGearMenu.bind(this)}
								>
									<span className="gear-icon" />
									<div className={'gear-menu-drop-down ' + (gearMenuExpanded ? 'expanded' : 'collapsed')}>
										<ul className="settings-drop-down">
											<li className="option" onClick={this.deleteHolidayCalendar.bind(this)}>
												<span className="delete">
													<FormattedMessage id="common.delete" />
												</span>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<table>
								<thead>
									<tr>
										<th>
											<FormattedMessage id="common.name" />
										</th>
										<th>
											<FormattedMessage id="common.date" />
										</th>
										<th />
									</tr>
								</thead>
								<tbody>
									{holidayCalendarEntries
										? holidayCalendarEntries.edges.map(holidayCalendarEntryEdge => (
												<HolidayCalendarEntryRow
													key={holidayCalendarEntryEdge.node.id}
													holidayCalendarId={this.props.holidayCalendar.id}
													holidayCalendarEntry={holidayCalendarEntryEdge.node}
												/>
										  ))
										: ''}
									<tr>
										<td>
											<input
												data-cy={'new-holiday-input'}
												type="text"
												placeholder={formatMessage({id: 'common.name'})}
												value={holidayCalendarEntryName}
												onChange={this.onNewEntryNameChange.bind(this)}
											/>
										</td>
										<td className="date-cell">
											<DatePicker
												value={holidayCalendarEntryDate}
												onDatePick={this.onNewEntryDateChange.bind(this)}
											/>
										</td>
										<td>
											<button
												data-cy={'add-holiday-button'}
												className="button-add"
												onClick={this.onNewEntryAddClick.bind(this)}
											>
												{formatMessage({id: 'common.add'})}
											</button>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</fieldset>
			</div>
		);
	}
}

SettingsHolidayCalendarsSingleCalendar.propTypes = {
	holidayCalendar: PropTypes.object.isRequired,
	companyId: PropTypes.string.isRequired,
};

export default injectIntl(withRouter(SettingsHolidayCalendarsSingleCalendar));
