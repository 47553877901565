import {hasNoAccessToTimeRegProject} from '../../../../../../components/timesheets/team-timesheet/TimesheetsUtil';
import Util from '../../../../../shared/util/util';
import {idleTimeRegistrationAllowed, taskTimeRegistrationAllowed} from './InformationUtil';
import {cloneDeep} from 'lodash';
import {TIME_REG_TYPES} from '../../time_row_utils';

export function formatDayRegs(dayRegs) {
	return dayRegs.map(reg => {
		return {
			id: reg.node.id,
			minutesRegistered: reg.node.minutesRegistered,
			billableMinutesRegistered: reg.node.billableMinutesRegistered,
			billable: reg.node.task?.billable || reg.node.billable || reg.node.project?.billable,
			notes: reg.node.notes ? reg.node.notes : undefined,
			role: reg.node.role,
			canHaveRole: !reg.node.idleTime,
			taskEstimateForecastMinutes: reg.node.task ? reg.node.task.estimateForecastMinutes : null,
			taskTotalMinutesRegistered: reg.node.task ? reg.node.task.totalMinutesRegistered : null,
			taskId: reg.node.task ? reg.node.task.id : undefined,
			fullAccessToProject: !hasNoAccessToTimeRegProject(reg.node),
		};
	});
}

export const getTimeRegType = timeReg => {
	if (timeReg.task && timeReg.task.project) {
		return TIME_REG_TYPES.TASK_TIME;
	} else if (timeReg.project) {
		return TIME_REG_TYPES.PROJECT_TIME;
	} else if (timeReg.idleTime) {
		return TIME_REG_TYPES.IDLE_TIME;
	} else {
		return null;
	}
};
export const addTimeRegTypes = timeRegs => {
	if (timeRegs.length === 0) {
		return [];
	}
	const timeRegsClone = cloneDeep(timeRegs);
	timeRegsClone.forEach(timeReg => {
		timeReg.node.type = getTimeRegType(timeReg.node);
	});
	return timeRegsClone;
};

export const groupRegs = timeRegistrations => {
	// Entity ID -> Entity Object
	const seenEntities = new Map();
	// Entity ID -> List of time Regs per day
	const regsMap = new Map();

	timeRegistrations.forEach(timeReg => {
		let keyEntity;
		if (timeReg.node.type === TIME_REG_TYPES.NO_ACCESS) {
			keyEntity = {id: -1};
		} else if (timeReg.node.type === TIME_REG_TYPES.TASK_TIME) {
			keyEntity = timeReg.node.task;
		} else if (timeReg.node.type === TIME_REG_TYPES.PROJECT_TIME) {
			keyEntity = timeReg.node.project;
		} else {
			keyEntity = timeReg.node.idleTime;
		}
		if (keyEntity) {
			// Copy type down to child for use later
			keyEntity.type = timeReg.node.type;
			keyEntity.timeReg = timeReg;

			const dayOfWeek = Util.CreateMomentDate(timeReg.node.year, timeReg.node.month, timeReg.node.day).weekday();

			if (seenEntities.has(keyEntity.id)) {
				const dayRegsForEntitiy = regsMap.get(keyEntity.id);
				dayRegsForEntitiy[dayOfWeek].push(timeReg);
				regsMap.set(keyEntity.id, dayRegsForEntitiy);
			} else {
				const dayRegsForEntitiy = [[], [], [], [], [], [], []];
				dayRegsForEntitiy[dayOfWeek].push(timeReg);
				regsMap.set(keyEntity.id, dayRegsForEntitiy);
				// Set so we know we've seen it, and so we can swap id for object later
				seenEntities.set(keyEntity.id, keyEntity);
			}
		}
	});
	// Zip the above maps into
	// Entity Object -> List of time Regs per day
	const outPutMap = new Map();
	seenEntities.forEach((entity, id) => {
		outPutMap.set(entity, regsMap.get(id));
	});
	return outPutMap;
};
export const filterTimeregistrations = (searchValue, t) => {
	const timeRegistration = t.node ? t.node : t;
	const projectName = timeRegistration.project
		? timeRegistration.project.name + 'P' + timeRegistration.project.companyProjectId
		: timeRegistration.task && timeRegistration.task.project
		? timeRegistration.task.project.name + 'P' + timeRegistration.task.project.companyProjectId
		: '';
	const idleName = timeRegistration.idleTime ? timeRegistration.idleTime.name : '';
	const taskName = timeRegistration.task ? timeRegistration.task.name : timeRegistration.name ? timeRegistration.name : '';
	const phaseName =
		timeRegistration.task && timeRegistration.task.phase
			? timeRegistration.task.phase.name
			: timeRegistration.phase
			? timeRegistration.phase.name
			: '';
	const clientName =
		timeRegistration.project && timeRegistration.project.client
			? timeRegistration.project.client.name
			: timeRegistration.task && timeRegistration.task.project && timeRegistration.task.project.client
			? timeRegistration.task.project.client.name
			: '';
	return Util.normalizedIncludes(projectName + idleName + taskName + phaseName + clientName, searchValue);
};
const secondarySortList = (a, b, direction) => {
	let result;
	const idleTimeA = a.switchIdleTime ? a : a.idleTime;
	const idleTimeB = b.switchIdleTime ? b : b.idleTime;
	if (idleTimeA && idleTimeB && idleTimeA.isInternalTime !== idleTimeB.isInternalTime) {
		// 1.a - If both are idleTimes, sort internal time first.
		result = idleTimeA.isInternalTime ? direction * -1 : direction;
	} else if (!!idleTimeA !== !!idleTimeB) {
		// 1.b - Iff one is idleTime - sort below the other
		result = !!idleTimeA ? direction : direction * -1;
	} else {
		const aName = (a.project && a.project.name) || (idleTimeA && idleTimeA.name) || null;
		const bName = (b.project && b.project.name) || (idleTimeB && idleTimeB.name) || null;
		if (aName && bName) result = aName > bName ? 1 : -1;
	}
	return result;
};
export const sortList = (list, sortValue, sortAscending) => {
	let sortingList = list;
	const direction = sortAscending ? 1 : -1;

	sortingList.sort((ak, bk) => {
		// If map entries, sort by key
		ak = Array.isArray(ak) ? ak[0] : ak;
		bk = Array.isArray(bk) ? bk[0] : bk;
		// list items are not always stored the same way, have to unwrap
		const a = ak.node ? ak.node : ak;
		const b = bk.node ? bk.node : bk;

		if (sortValue === 'project-indicator') {
			const aId =
				a.task && a.task.project ? a.task.project.companyProjectId : a.project ? a.project.companyProjectId : -1;
			const bId =
				b.task && b.task.project ? b.task.project.companyProjectId : b.project ? b.project.companyProjectId : -1;

			/*
				Handle odd cases first
				1 - no pId exist - defaults to using project name or idleTime name
				2 - one of the project-indicator names does not exist or for some reason project and idleTime names are none existing. we place them last
				3 - indicators has same names - sort by project ids
			*/

			// Option 1
			if (aId === -1 && bId === -1) {
				const result = secondarySortList(a, b, direction);
				if (result) {
					return result;
				}
			}

			// Option 2
			if (aId === -1 || aId === null) return direction;
			if (bId === -1 || bId === null) return direction * -1;

			//Same project ?
			if (aId === bId) {
				// Same task ?
				const aTaskName = a.task && a.task.name ? a.task.name : a.name ? a.name : a.idleTime ? a.idleTime.name : '';
				const bTaskName = b.task && b.task.name ? b.task.name : b.name ? b.name : b.idleTime ? b.idleTime.name : '';
				if (aTaskName === bTaskName) {
					const aTaskId = a.task ? a.task.id : a.idleTime ? a.idleTime.id : a.id ? a.id : '';
					const bTaskId = b.task ? b.task.id : b.idleTime ? b.idleTime.id : b.id ? b.id : '';
					return aTaskId > bTaskId ? direction : -1 * direction;
				}
				return aTaskName > bTaskName ? direction : direction * -1;
			}

			return aId > bId ? direction : direction * -1;
		} else if (sortValue === 'project-name') {
			const aName =
				a.task && a.task.project && a.task.project.name
					? a.task.project.name.toLowerCase()
					: a.project && a.project.name
					? a.project.name.toLowerCase()
					: '';
			const bName =
				b.task && b.task.project && b.task.project.name
					? b.task.project.name.toLowerCase()
					: b.project && b.project.name
					? b.project.name.toLowerCase()
					: '';

			/*
				Handle odd cases first
				1 - no project names exist - defaults to using idleTime name if applicable
				2 - one of the project names does not exist or for some reason project and idleTime names are none existing. we place them last
				3 - project has same names - sort by project ids
			*/

			// Option 1
			if (!aName && !bName) {
				const result = secondarySortList(a, b, direction);
				if (result) {
					return result;
				}
			}

			// Option 2
			if (aName === '' || aName === null) return direction;
			if (bName === '' || bName === null) return direction * -1;

			// Option 3
			if (aName === bName) {
				const aId =
					a.task && a.task.project ? a.task.project.companyProjectId : a.project ? a.project.companyProjectId : -1;
				const bId =
					b.task && b.task.project ? b.task.project.companyProjectId : b.project ? b.project.companyProjectId : -1;
				if (aId === bId) {
					const aTaskName =
						a.task && a.task.name
							? a.task.name.toLowerCase()
							: a.idleTime && a.idleTime.name
							? a.idleTime.name.toLowerCase()
							: a.name
							? a.name.toLowerCase()
							: '';
					const bTaskName =
						b.task && b.task.name
							? b.task.name.toLowerCase()
							: b.idleTime && b.idleTime.name
							? b.idleTime.name.toLowerCase()
							: b.name
							? b.name.toLowerCase()
							: '';
					if (aTaskName === bTaskName) {
						const aTaskId = a.task ? a.task.id : a.idleTime ? a.idleTime.id : a.id ? a.id : '';
						const bTaskId = b.task ? b.task.id : b.idleTime ? b.idleTime.id : b.id ? b.id : '';
						return aTaskId > bTaskId ? direction : -1 * direction;
					}
					return aTaskName > bTaskName ? direction : -1 * direction;
				}
				return aId > bId ? direction : direction * -1;
			}

			return aName > bName ? direction : direction * -1;
		} else if (sortValue === 'task-name') {
			const aTaskName =
				a.task && a.task.name
					? a.task.name.toLowerCase()
					: a.idleTime && a.idleTime.name
					? a.idleTime.name.toLowerCase()
					: a.name
					? a.name.toLowerCase()
					: '';
			const bTaskName =
				b.task && b.task.name
					? b.task.name.toLowerCase()
					: b.idleTime && b.idleTime.name
					? b.idleTime.name.toLowerCase()
					: b.name
					? b.name.toLowerCase()
					: '';
			/*
				Handle odd cases first
				1 - no taks names exist - defaults to using project name or idleTime name
				2 - one of the phase names does not exist or for some reason project and idleTime names are none existing. we place them last
				3 - task has same names - sort by project ids
			*/

			// Option 1
			if (aTaskName === '' && bTaskName === '') {
				const result = secondarySortList(a, b, direction);
				if (result) {
					return result;
				}
			}

			// Option 2
			if (aTaskName === '' || aTaskName === null) return direction;
			if (bTaskName === '' || bTaskName === null) return direction * -1;

			// Option 3
			if (aTaskName === bTaskName) {
				const aProjectName =
					a.task && a.task.project
						? a.task.project.name.toLowerCase()
						: a.project && a.project.name
						? a.project.name.toLowerCase()
						: a.idleTime && a.idleTime.name
						? a.idleTime.name
						: '';
				const bProjectName =
					b.task && b.task.project
						? b.task.project.name.toLowerCase()
						: b.project && b.project.name
						? b.project.name.toLowerCase()
						: b.idleTime && b.idleTime.name
						? b.idleTime.name
						: '';
				return aProjectName > bProjectName ? direction : direction * -1;
			}
			return aTaskName > bTaskName ? direction : direction * -1;
		} else if (sortValue === 'phase') {
			const aPhaseName =
				a.task && a.task.phase && a.task.phase.name
					? a.task.phase.name.toLowerCase()
					: a.phase && a.phase.name
					? a.phase.name.toLowerCase()
					: '';
			const bPhaseName =
				b.task && b.task.phase && b.task.phase.name
					? b.task.phase.name.toLowerCase()
					: b.phase && b.phase.name
					? b.phase.name.toLowerCase()
					: '';

			/*
				Handle odd cases first
				1 - no phase names exist - defaults to using project name or idleTime name
				2 - one of the phase names does not exist or for some reason project and idleTime names are none existing. we place them last
				3 - phase has same names - sort by project ids
			*/

			// Option 1
			if (!aPhaseName && !bPhaseName) {
				const result = secondarySortList(a, b, direction);
				if (result) {
					return result;
				}
			}

			// Option 2
			if (aPhaseName === '' || aPhaseName === null) return direction;
			if (bPhaseName === '' || bPhaseName === null) return direction * -1;

			// Option 3
			if (aPhaseName === bPhaseName) {
				const aId =
					a.task && a.task.project ? a.task.project.companyProjectId : a.project ? a.project.companyProjectId : -1;
				const bId =
					b.task && b.task.project ? b.task.project.companyProjectId : b.project ? b.project.companyProjectId : -1;
				// Same project?
				if (aId === bId) {
					// Same task?
					const aTaskName =
						a.task && a.task.name
							? a.task.name.toLowerCase()
							: a.idleTime && a.idleTime.name
							? a.idleTime.name.toLowerCase()
							: a.name
							? a.name.toLowerCase()
							: '';
					const bTaskName =
						b.task && b.task.name
							? b.task.name.toLowerCase()
							: b.idleTime && b.idleTime.name
							? b.idleTime.name.toLowerCase()
							: b.name
							? b.name.toLowerCase()
							: '';
					return aTaskName > bTaskName ? direction : -1 * direction;
				}
				return aId > bId ? direction : direction * -1;
			}

			return aPhaseName > bPhaseName ? direction : direction * -1;
		} else if (sortValue === 'client') {
			const aClientName =
				a.task && a.task.project && a.task.project.client && a.task.project.client.name
					? a.task.project.client.name.toLowerCase()
					: a.project && a.project.client && a.project.client.name
					? a.project.client.name.toLowerCase()
					: '';
			const bClientName =
				b.task && b.task.project && b.task.project.client && b.task.project.client.name
					? b.task.project.client.name.toLowerCase()
					: b.project && b.project.client && b.project.client.name
					? b.project.client.name.toLowerCase()
					: '';

			/*
				Handle odd cases first
				1 - no client names exist - defaults to using project name or idleTime name
				2 - one of the client names does not exist or for some reason project and idleTime names are none existing. we place them last
				3 - client has same names - sort by project ids
			*/

			// Option 1
			if (!aClientName && !bClientName) {
				const result = secondarySortList(a, b, direction);
				if (result) {
					return result;
				}
			}

			// Option 2
			if (aClientName === '' || aClientName === null) return direction;
			if (bClientName === '' || bClientName === null) return direction * -1;

			// Option 3
			if (aClientName === bClientName) {
				const aId =
					a.task && a.task.project ? a.task.project.companyProjectId : a.project ? a.project.companyProjectId : -1;
				const bId =
					b.task && b.task.project ? b.task.project.companyProjectId : b.project ? b.project.companyProjectId : -1;
				// Same project?
				if (aId === bId) {
					// Same task?
					const aTaskName =
						a.task && a.task.name
							? a.task.name.toLowerCase()
							: a.idleTime && a.idleTime.name
							? a.idleTime.name.toLowerCase()
							: a.name
							? a.name.toLowerCase()
							: '';
					const bTaskName =
						b.task && b.task.name
							? b.task.name.toLowerCase()
							: b.idleTime && b.idleTime.name
							? b.idleTime.name.toLowerCase()
							: b.name
							? b.name.toLowerCase()
							: '';
					return aTaskName > bTaskName ? direction : -1 * direction;
				}
				return aId > bId ? direction : direction * -1;
			}

			return aClientName > bClientName ? direction : direction * -1;
		} else if (sortValue === 'remaining') {
			let aRem = a.task && a.task.timeLeft != null ? a.task.timeLeft : a.timeLeft != null ? a.timeLeft : -1;
			let bRem = b.task && b.task.timeLeft != null ? b.task.timeLeft : b.timeLeft != null ? b.timeLeft : -1;

			// sort project name / idleTime name if no timeleft exists - always ascending
			if (aRem === -1 && bRem === -1) {
				const aName = (a.project && a.project.name) || (a.idleTime && a.idleTime.name) || null;
				const bName = (b.project && b.project.name) || (b.idleTime && b.idleTime.name) || null;
				if (aName != null && bName != null) return aName > bName ? 1 : -1;
			}
			return aRem > bRem ? direction : direction * -1;
		}
		return 0;
	});
	return sortingList;
};

export function getFilteredTimeRegs(timeRegsForPeriod, movedTasks, movedIdleTimes, searchValue) {
	return timeRegsForPeriod
		.filter(tReg =>
			tReg.node.task
				? !movedTasks.some(taskId => taskId === tReg.node.task.id)
				: tReg.node.idleTime
				? !movedIdleTimes.some(idleTimeId => idleTimeId === tReg.node.idleTime.id)
				: true
		)
		.filter(filterTimeregistrations.bind(this, searchValue));
}

export function getFilteredSwitchTasks(
	selectedSwitch,
	suggestedSwitchTasks,
	selectedPerson,
	timeRegsForPeriod,
	searchValue,
	switchTasks
) {
	return selectedSwitch === 'suggested'
		? suggestedSwitchTasks
				.map(suggestion => suggestion.task)
				.filter(
					task =>
						task &&
						taskTimeRegistrationAllowed(task, selectedPerson.id) &&
						!timeRegsForPeriod.some(tReg => tReg.node.task && tReg.node.task.id === task.id)
				)
				.filter(filterTimeregistrations.bind(this, searchValue))
		: selectedSwitch != null
		? switchTasks
				.filter(
					task =>
						task &&
						taskTimeRegistrationAllowed(task.node, selectedPerson.id) &&
						!timeRegsForPeriod.some(tReg => tReg.node.task && tReg.node.task.id === task.node.id)
				)
				.filter(filterTimeregistrations.bind(this, searchValue))
				.map(task => task.node)
		: [];
}

export function getFilteredSwitchIdleTimes(
	selectedSwitch,
	suggestedSwitchTasks,
	timeRegsForPeriod,
	searchValue,
	switchIdleTimes
) {
	return selectedSwitch === 'suggested'
		? suggestedSwitchTasks
				.map(suggestion => suggestion.idleTime)
				.filter(
					idleTime =>
						idleTime &&
						idleTimeRegistrationAllowed(idleTime) &&
						!timeRegsForPeriod.some(tReg => tReg.node.idleTime && tReg.node.idleTime.id === idleTime.id)
				)
				.filter(filterTimeregistrations.bind(this, searchValue))
				.map(idleTime => ({...idleTime, switchIdleTime: true}))
		: selectedSwitch === 'starred'
		? switchIdleTimes
				.filter(
					idleTime =>
						idleTime &&
						idleTimeRegistrationAllowed(idleTime.node) &&
						!timeRegsForPeriod.some(tReg => tReg.node.idleTime && tReg.node.idleTime.id === idleTime.node.id)
				)
				.filter(filterTimeregistrations.bind(this, searchValue))
				.map(idleTime => ({...idleTime.node, switchIdleTime: true}))
		: [];
}

export function getDayRegsForSwitchEntity(isWeekView, groupedMovedTasks, entity) {
	let dayRegsForEntity =
		isWeekView && groupedMovedTasks.get(entity) ? groupedMovedTasks.get(entity) : [[], [], [], [], [], [], []];
	if (entity.minutesRegistered) {
		if (isWeekView) {
			// Important to use weekday instead of day, since weekday returns day index relative to locale
			const dayOfWeek = Util.CreateNonUtcMomentDate(entity.year, entity.month, entity.day).weekday();

			dayRegsForEntity[dayOfWeek] = [entity];
		} else {
			dayRegsForEntity = null;
		}
	}
	return dayRegsForEntity;
}
