import React from 'react';
import { Text, IconTooltip, FlexColumn, FlexRow } from '@forecast-it/design-system';
export function TimeExceedsEstimateInfo() {
    return (React.createElement("div", null,
        React.createElement(Text, { size: '2' }, "Based on your company settings, time entries on a task cannot exceed the estimate."),
        React.createElement(Text, { size: '2', color: 'medium' }, "This is a company-wide setting configured by your admin.")));
}
export function TimeExceedsEstimateIcon() {
    return (React.createElement(IconTooltip, { icon: 'info', color: 'disabled', iconSize: 's' },
        React.createElement(TimeExceedsEstimateInfo, null)));
}
export function RemainingTimeAndError({ remainingInfoMessage, timeError, textSize, gap, marginTop, marginBottom, }) {
    return remainingInfoMessage || timeError ? (React.createElement("div", { style: { marginTop: marginTop || '4px', marginBottom: marginBottom || '12px' } },
        React.createElement(FlexColumn, { gap: gap || 's' },
            remainingInfoMessage ? (React.createElement(FlexRow, { gap: 's' },
                React.createElement(Text, { size: textSize || '2', color: 'medium' }, remainingInfoMessage),
                React.createElement(TimeExceedsEstimateIcon, null))) : null,
            timeError ? (React.createElement(Text, { size: textSize || '2', color: 'error' }, timeError)) : null))) : null;
}
