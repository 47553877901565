import { useMemo } from 'react';
import { isSageAccount } from '../../util/sage/SageUtil';
function canSpin() {
    return isSageAccount();
}
export const useSpinner = ({ waiting }) => {
    const enableSpinning = useMemo(() => {
        return waiting && canSpin();
    }, [waiting]);
    return { spinning: enableSpinning };
};
