import { hasModule } from '../ModuleUtil';
import { MODULE_TYPES } from '../../../../constants';
/**
 * These functions act as intermediate solutions for handling view configurations demanded by Sage until we can find a
 * good approach to unifying the view restrictions originating from Sage with the view restrictions originating from CompanySetup.
 *
 * Certain Sage restrictions are already handled by CompanySetup:
 * SAGE_INTACCT_RESTRICTED_REVENUE_ACCESS handled by company.revenueEnabled
 */
export function sageFinancialsRestricted() {
    return (hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED) &&
        !hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED_PROJECT_FINANCIALS_ACCESS));
}
export function sageCostRestricted() {
    return hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED);
}
export function sageRevenueRecognitionRestricted() {
    return hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED);
}
export function sageCreateExpenseRestricted() {
    return hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED);
}
export function sageCreateInvoiceRestricted() {
    return (hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED) &&
        !hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED_PROJECT_FINANCIALS_ACCESS) &&
        !hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED_REVENUE_ACCESS));
}
export function sageReportingRestricted() {
    return hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED) && !hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED_REPORTING_ACCESS);
}
export function sageFinanceMenuRestricted() {
    return (hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED) &&
        (sageFinancialsRestricted() || !hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED_FINANCE_MENU_ACCESS)));
}
export function isSagePSAWithFinancialsAccess() {
    return (hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED) &&
        (hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED_PROJECT_FINANCIALS_ACCESS) ||
            hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED_REVENUE_ACCESS)));
}
