import React from 'react';

export const ArrowRightOutline = ({color, width, height}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 14">
		<path
			fill={color}
			d="M8.25 14c-.06 0-.122-.004-.184-.01-.437-.052-.831-.283-1.106-.652-.234-.314-.362-.706-.362-1.106v-1.697H1.65C.74 10.535 0 9.742 0 8.768V5.232c0-.974.74-1.767 1.65-1.767h4.948V1.768c0-.473.172-.917.484-1.25C7.393.183 7.807 0 8.248 0c.374 0 .74.138 1.031.388l6.103 5.23c.344.297.56.719.608 1.188.048.469-.077.932-.353 1.3-.075.1-.16.193-.256.274l-6.103 5.231c-.294.255-.65.389-1.028.389zm-6.6-5.232h5.773c.455 0 .825.396.825.884v2.58L14.35 7 8.248 1.769v2.58c0 .487-.37.883-.825.883H1.65v3.536z"
		/>
	</svg>
);
