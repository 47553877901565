/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type DependencySectionQueryContent_viewer$ref = any;
export type DependencySectionQueryContent_QueryVariables = {|
  id?: ?string
|};
export type DependencySectionQueryContent_QueryResponse = {|
  +viewer: ?{|
    +component: ?string,
    +actualPersonId: ?string,
    +id: string,
    +$fragmentRefs: DependencySectionQueryContent_viewer$ref,
  |}
|};
export type DependencySectionQueryContent_Query = {|
  variables: DependencySectionQueryContent_QueryVariables,
  response: DependencySectionQueryContent_QueryResponse,
|};
*/


/*
query DependencySectionQueryContent_Query(
  $id: String
) {
  viewer {
    component(name: "dependency_section")
    actualPersonId
    id
    ...DependencySectionQueryContent_viewer_1Bmzm5
  }
}

fragment DependencySectionQueryContent_viewer_1Bmzm5 on Viewer {
  dependencyTask: task(companyTaskId: $id) {
    project {
      tasks(first: 100000) {
        edges {
          node {
            id
            name
            companyTaskId
            approved
            statusColumnV2 {
              id
              name
              category
              projectGroupStatusColumnId
            }
          }
        }
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id",
    "type": "String"
  }
],
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "dependency_section"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"dependency_section\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DependencySectionQueryContent_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "id"
              }
            ],
            "kind": "FragmentSpread",
            "name": "DependencySectionQueryContent_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DependencySectionQueryContent_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": "dependencyTask",
            "args": [
              {
                "kind": "Variable",
                "name": "companyTaskId",
                "variableName": "id"
              }
            ],
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "task",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectType",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "first",
                        "value": 100000
                      }
                    ],
                    "concreteType": "TaskTypeConnection",
                    "kind": "LinkedField",
                    "name": "tasks",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TaskTypeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Task",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "companyTaskId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "approved",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "StatusColumnV2",
                                "kind": "LinkedField",
                                "name": "statusColumnV2",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v4/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "category",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "projectGroupStatusColumnId",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "tasks(first:100000)"
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "DependencySectionQueryContent_Query",
    "operationKind": "query",
    "text": "query DependencySectionQueryContent_Query(\n  $id: String\n) {\n  viewer {\n    component(name: \"dependency_section\")\n    actualPersonId\n    id\n    ...DependencySectionQueryContent_viewer_1Bmzm5\n  }\n}\n\nfragment DependencySectionQueryContent_viewer_1Bmzm5 on Viewer {\n  dependencyTask: task(companyTaskId: $id) {\n    project {\n      tasks(first: 100000) {\n        edges {\n          node {\n            id\n            name\n            companyTaskId\n            approved\n            statusColumnV2 {\n              id\n              name\n              category\n              projectGroupStatusColumnId\n            }\n          }\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '298b942940613ae8b9d4be294d9b1595';

module.exports = node;
