import { addDays, closestTo, differenceInCalendarDays } from 'date-fns';
import { getLockDate } from '../../../../../util/time-registration/time-locking/TimeLockingDate';
import { TimeRegistrationEntity } from '../types/TimeRegistrationEntityId';
import { isTimeRegistrationDateInvalid, isTimeRegistrationDateInvalidWithReason, } from '../../../../../util/time-registration/time-registration-settings/TimeRegistrationDateFilter';
import { createDateFromNullableYMD } from '../../../../../util/DateUtil';
export function getLockDateFromViewer(viewer) {
    if (viewer.company && viewer.company.person) {
        const { lockedPeriodYear, lockedPeriodMonth, lockedPeriodDay, person } = viewer.company;
        const { submitLockedDateYear, submitLockedDateMonth, submitLockedDateDay, excludeFromCompanyLockedPeriod } = person;
        return getLockDate({
            companyLockYear: lockedPeriodYear,
            companyLockMonth: lockedPeriodMonth,
            companyLockDay: lockedPeriodDay,
            personLockYear: submitLockedDateYear,
            personLockMonth: submitLockedDateMonth,
            personLockDay: submitLockedDateDay,
            personExcludedFromCompanyLock: excludeFromCompanyLockedPeriod,
        });
    }
    return null;
}
export function getIsDateDisabledFromTargetEntity({ date, targetEntity, ignoredDates }, withReason = false) {
    if (targetEntity) {
        const timeRegistrationDateValidator = withReason
            ? isTimeRegistrationDateInvalidWithReason
            : isTimeRegistrationDateInvalid;
        switch (targetEntity.entity) {
            case TimeRegistrationEntity.Task: {
                const taskStartDate = createDateFromNullableYMD({
                    year: targetEntity.startYear,
                    month: targetEntity.startMonth,
                    day: targetEntity.startDay,
                });
                const taskEndDate = createDateFromNullableYMD({
                    year: targetEntity.deadlineYear,
                    month: targetEntity.deadlineMonth,
                    day: targetEntity.deadlineDay,
                });
                const taskProject = targetEntity.project;
                const projectStartDate = taskProject
                    ? createDateFromNullableYMD({
                        year: taskProject.projectStartYear,
                        month: taskProject.projectStartMonth,
                        day: taskProject.projectStartDay,
                    })
                    : null;
                const projectEndDate = taskProject
                    ? createDateFromNullableYMD({
                        year: taskProject.projectEndYear,
                        month: taskProject.projectEndMonth,
                        day: taskProject.projectEndDay,
                    })
                    : null;
                return timeRegistrationDateValidator({
                    date,
                    taskStartDate,
                    taskEndDate,
                    projectStartDate,
                    projectEndDate,
                    ignoredDates,
                });
            }
            case TimeRegistrationEntity.Project: {
                const projectStartDate = createDateFromNullableYMD({
                    year: targetEntity.projectStartYear,
                    month: targetEntity.projectStartMonth,
                    day: targetEntity.projectStartDay,
                });
                const projectEndDate = createDateFromNullableYMD({
                    year: targetEntity.projectEndYear,
                    month: targetEntity.projectEndMonth,
                    day: targetEntity.projectEndDay,
                });
                return timeRegistrationDateValidator({ date, projectStartDate, projectEndDate, ignoredDates });
            }
        }
    }
    return withReason ? null : false;
}
function noNulls(array) {
    return array.filter((item) => item !== null);
}
export function getClosestValidTimeRegDateForTargetEntity(targetEntity) {
    if (targetEntity) {
        switch (targetEntity.entity) {
            case TimeRegistrationEntity.Task: {
                const taskStartDate = createDateFromNullableYMD({
                    year: targetEntity.startYear,
                    month: targetEntity.startMonth,
                    day: targetEntity.startDay,
                });
                const taskEndDate = createDateFromNullableYMD({
                    year: targetEntity.deadlineYear,
                    month: targetEntity.deadlineMonth,
                    day: targetEntity.deadlineDay,
                });
                return closestTo(new Date(), noNulls([taskStartDate, taskEndDate])) || null;
            }
            case TimeRegistrationEntity.Project: {
                const projectStartDate = createDateFromNullableYMD({
                    year: targetEntity.projectStartYear,
                    month: targetEntity.projectStartMonth,
                    day: targetEntity.projectStartDay,
                });
                const projectEndDate = createDateFromNullableYMD({
                    year: targetEntity.projectEndYear,
                    month: targetEntity.projectEndMonth,
                    day: targetEntity.projectEndDay,
                });
                return closestTo(new Date(), noNulls([projectStartDate, projectEndDate])) || null;
            }
        }
    }
    return null;
}
export function getInitialOrFirstNonLockValidDateForTargetEntity(initialDate, lockDate, targetEntity) {
    const initialOrNonLockedDate = lockDate && differenceInCalendarDays(lockDate, initialDate) >= 0 ? addDays(lockDate, 1) : initialDate;
    if (getIsDateDisabledFromTargetEntity({
        date: initialDate,
        targetEntity,
    })) {
        const endDate = getClosestValidTimeRegDateForTargetEntity(targetEntity);
        if (endDate) {
            return endDate;
        }
    }
    return initialOrNonLockedDate;
}
