/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type SettingsPeoplePage_weekdays$ref: FragmentReference;
declare export opaque type SettingsPeoplePage_weekdays$fragmentType: SettingsPeoplePage_weekdays$ref;
export type SettingsPeoplePage_weekdays = {|
  +monday: ?number,
  +tuesday: ?number,
  +wednesday: ?number,
  +thursday: ?number,
  +friday: ?number,
  +saturday: ?number,
  +sunday: ?number,
  +$refType: SettingsPeoplePage_weekdays$ref,
|};
export type SettingsPeoplePage_weekdays$data = SettingsPeoplePage_weekdays;
export type SettingsPeoplePage_weekdays$key = {
  +$data?: SettingsPeoplePage_weekdays$data,
  +$fragmentRefs: SettingsPeoplePage_weekdays$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingsPeoplePage_weekdays",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "monday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tuesday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wednesday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thursday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "friday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "saturday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sunday",
      "storageKey": null
    }
  ],
  "type": "Person"
};
// prettier-ignore
(node/*: any*/).hash = '941000434e4166cd12b3d535842436b8';

module.exports = node;
