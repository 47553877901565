import React from 'react';
import styled from 'styled-components';
import {Text} from '@forecast-it/design-system';

const EmptyStateWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	height: 320px;
`;

const Header = styled(Text)`
	max-width: 320px;
`;

const SearchResultEmptyState = ({searchString}) => {
	return (
		<EmptyStateWrapper data-cy={'search-modal-empty-state'}>
			<Header ellipsis>{`Sorry, we could not find anything matching: "${searchString}"`}</Header>
		</EmptyStateWrapper>
	);
};

export default SearchResultEmptyState;
