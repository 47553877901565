import React from 'react';

const checkMarkIcon = props => (
	<div className={props.className} title={props.title ? props.title : ''}>
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
			<g fill="none" fillRule="nonzero">
				<path
					fill="#F89449"
					d="M1.726 19.934a3.031 3.031 0 0 1-.469-.184c-.351-.168-.84-.656-1.007-1.003-.266-.543-.246.109-.246-8.747s-.02-8.204.246-8.747C.418.906.906.418 1.253.25 1.796-.016 1.144.004 10 .004s8.204-.02 8.747.246c.347.168.835.656 1.003 1.003.266.543.246-.109.246 8.747s.02 8.204-.246 8.747c-.168.347-.656.835-1.003 1.003-.543.266.113.246-8.767.242-6.923 0-8.074-.008-8.254-.058z"
				/>
				<path fill="#FFF" d="M6.095 10V4.026H8.4V8.79H11.6V4.026H13.906v11.948H11.6V10.82H8.4v5.154H6.094z" />
			</g>
		</svg>
	</div>
);

export default checkMarkIcon;
