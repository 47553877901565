import React, {useContext, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import ProjectBudgetBreakdownHeader from './ProjectBudgetBreakdownHeader';
import ProjectBudgetTable from './ProjectBudgetTable';
import {ProgramContext} from '../program-overview-page/ProgramContextProvider';
import ProjectBudgetTotals from './ProgramBudgetTotals';
import {DeprecatedMainContainer as MainContainer} from '@forecast-it/design-system';
import {useIntl} from 'react-intl';
import {useTrackPage} from '../../../../tracking/amplitude/hooks/useTrackPage';
import CustomScrollDiv from '../../../shared/components/scroll-bars/custom_scroll_div';
import {hasSomePermission} from '../../../shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../Permissions';
import CompanySetupUtil from '../../../shared/util/CompanySetupUtil';

const ProgramBudgetOverview = () => {
	const {program, projects, company, actualPersonId, retry} = useContext(ProgramContext);
	const {formatMessage} = useIntl();
	const history = useHistory();

	useEffect(() => {
		if (
			!CompanySetupUtil.hasFinance() ||
			!hasSomePermission([PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION, PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE])
		) {
			// if user doesnt have access rights to view this page redirect to no access page
			history.push('/not-authorized');
		}
		document.title = `${formatMessage({id: 'common.budget'})} - ${program.prefix}`;
	});
	useTrackPage('Program Budget');

	return (
		<MainContainer>
			<CustomScrollDiv>
				{/*<ProgramSectionHeader prefix={program.prefix} name={program.name} programId={program.id} color={program.color} />*/}
				<ProjectBudgetTotals program={program} company={company} actualPersonId={actualPersonId} />
				<ProjectBudgetBreakdownHeader />
				<ProjectBudgetTable program={program} projects={projects} company={company} retry={retry} />
			</CustomScrollDiv>
		</MainContainer>
	);
};

export default ProgramBudgetOverview;
