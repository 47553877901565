import React, {useEffect, useState} from 'react';
import {CalendarCellStyle, CalendarTableStyles, CalenderStyle} from '../../timesheets_styled';
import Moment from 'moment';
import Util from '../../../../shared/util/util';
import {getHolidaysOfTheDay, getWeekTimeIcon, workingHourForTheDay} from '../../timesheets_person_data';
import {getTimeRegistrationsOfTheDay, separateWorkingTimeAndTimeOff} from '../../timesheets_time_registrations';
import CheckmarkIcon from '../../../../../images/checkmark_icon';
import {TIME_PERIOD} from '../../timesheets_change_date_view';
import OverlayLoader from '../../../../../components/loaders/overlay_loader';
import TooltipContainer from '../../../../shared/components/tooltips/tooltip_container';
import {trackEvent} from '../../../../../tracking/amplitude/TrackingV2';
import {isRolesAllowed} from '../../../../shared/util/cache/TimeRegistrationSettingsUtil';
import GoogleCalendarOAuth from '../../../../../containers/settings/settingsCalendar/GoogleCalendarOAuth';
import {Alert} from '@forecast-it/design-system';
import {AlertType} from '@forecast-it/design-system/dist/forecast-v2/components/data-display/alert/types';
import {LOCALSTORAGE_DISMISS_KEY} from '../TimesheetsCalendarUtil';
import OutlookCalendarOAuth from '../../../../../containers/settings/settingsCalendar/OutlookCalendarOAuth';

const timesheetsCalendar = ({
	intl,
	viewer,
	currentViewingDate,
	currentViewingMonth,
	currentPersonViewed,
	selectDay,
	timePeriod,
	cellWidth,
	loading,
	lockedDate,
}) => {
	const rolesAllowed = isRolesAllowed();
	const [selectedDate, setSelectedDate] = useState(
		timePeriod === TIME_PERIOD.WEEK_VIEW
			? 'week-' + currentViewingDate.week()
			: 'day-' + currentViewingDate.date() + '-' + currentViewingDate.month()
	);

	const selectDateFromCalendar = (day, isWeek) => {
		const selected = isWeek ? 'week-' + day.week() : 'day-' + day.date() + '-' + day.month();
		const readableSelection = isWeek ? 'week-' + day.week() : `day-${day.date()}/${day.month() + 1}`;
		trackEvent('Timesheet Calendar', 'Clicked', {selected: readableSelection});
		setSelectedDate(selected);
		selectDay(day, isWeek);
	};

	useEffect(() => {
		// the date has changed (from somewhere outside this, probably the header)
		const isWeek = timePeriod === TIME_PERIOD.WEEK_VIEW;
		const selected = isWeek
			? 'week-' + currentViewingDate.week()
			: 'day-' + currentViewingDate.date() + '-' + currentViewingDate.month();
		setSelectedDate(selected);
	}, [currentViewingDate, timePeriod]);

	const onKeyPressHandler = (e, day, isWeek) => {
		switch (e.key) {
			case 'Enter':
				selectDateFromCalendar(day, isWeek);
				break;
			default:
				break;
		}
	};

	const WeekNumberCell = ({week, timeRegistration, firstWeekOfMonth, holidaysInTheWeek, fullweekLocked}) => {
		const totalTime = timeRegistration.reduce(
			(totalTimeRegistered, timeReg) => totalTimeRegistered + timeReg.node.minutesRegistered,
			0
		);
		const totalHolidayTime = week.days.reduce((total, day, index) => {
			return holidaysInTheWeek[index].length > 0 ? total + workingHourForTheDay(currentPersonViewed, day) : total;
		}, 0);

		const icon = getWeekTimeIcon(week.days[6], currentPersonViewed, totalTime + totalHolidayTime, intl, fullweekLocked);

		const weekInfoCell = (
			<CalendarCellStyle
				className={
					'week-cell' +
					(Moment().isSame(week.days[1], 'week') ? ' week-current' : '') +
					(selectedDate === 'week-' + week.days[0].week() ? ' week-selected' : '') +
					(fullweekLocked ? ' locked' : '')
				}
				tabIndex={0}
			>
				<div className={'week-number'}>{week.days[0].week()}</div>
				<div className={'week-total'}>
					<div className={'week-total-title'}>{intl.formatMessage({id: 'common.total'})}</div>
					<div className={'week-total-value'}>
						{icon}
						{Util.convertMinutesToFullHour(totalTime, intl, true)}
					</div>
				</div>
			</CalendarCellStyle>
		);

		return fullweekLocked ? (
			<td
				style={{width: cellWidth ? cellWidth : ''}}
				className={'week-cell'}
				onClick={() => selectDateFromCalendar(week.days[firstWeekOfMonth ? 6 : 0], true)}
				onKeyPress={e => onKeyPressHandler(e, week.days[0], true)}
			>
				<TooltipContainer
					showDelay={300}
					infoText={intl.formatMessage({id: 'time-lock.tooltip-date-locked'})}
					tooltipPosition={'right'}
				>
					{weekInfoCell}
				</TooltipContainer>
			</td>
		) : (
			<td
				style={{width: cellWidth ? cellWidth : ''}}
				className={'week-cell'}
				onClick={() => selectDateFromCalendar(week.days[firstWeekOfMonth ? 6 : 0], true)}
				onKeyPress={e => onKeyPressHandler(e, week.days[0], true)}
			>
				{weekInfoCell}
			</td>
		);
	};

	const DayCell = ({day, timeRegistration, isOutsideThisMonth, holidaysOfTheDay}) => {
		const workingHours = workingHourForTheDay(currentPersonViewed, day);
		const timeWorked = separateWorkingTimeAndTimeOff(timeRegistration);
		const totalTime = timeWorked.timeOff + timeWorked.workingTime;
		const isLocked = lockedDate && lockedDate.isSameOrAfter(day, 'day');
		const isNonWorkingDay = workingHours === 0 || holidaysOfTheDay.length > 0;

		//the max is used for the extra time.
		const percentageTimeOff = isNonWorkingDay ? 0 : (timeWorked.timeOff * 100) / Math.max(workingHours, totalTime);
		const roundedPercentageTimeOff = Math.round(10 * percentageTimeOff) / 10;
		const percentageWorked = isNonWorkingDay ? 0 : (timeWorked.workingTime * 100) / Math.max(workingHours, totalTime);

		const totalTimeInHours = Util.convertMinutesToFullHour(totalTime, intl, true);
		const isDaySelected = selectedDate === 'day-' + day.date() + '-' + day.month();

		const dayCell = (
			<CalendarCellStyle
				className={
					'day-cell' +
					(day.isSame(Moment(), 'day') ? ' today' : '') +
					(isDaySelected ? ' selected' : selectedDate === 'week-' + day.week() ? ' week-selected' : '') +
					(isLocked ? ' locked' : '')
				}
				timeWorkedHeight={percentageWorked}
				timeOffHeight={roundedPercentageTimeOff}
				whiteSpaceHeight={100 - (percentageWorked + roundedPercentageTimeOff)}
				tabIndex={0}
			>
				<div className={'background' + (percentageWorked > 0 || roundedPercentageTimeOff > 0 ? ' has-colored-bg' : '')}>
					<div className={'white-space-background' + (isNonWorkingDay ? ' non-working-day' : '')}></div>
					<div className={'time-worked-background'}></div>
					<div className={'time-off-background'}></div>
				</div>

				<div
					className={
						'day-number' +
						(isOutsideThisMonth ? ' outside-month' : '') +
						(isNonWorkingDay ? ' non-working-day' : '') +
						(day.isSame(Moment(), 'day') ? ' today' : '')
					}
				>
					{day.date()}
				</div>
				{holidaysOfTheDay.length > 0 ? (
					<div className={'holiday-name'} title={holidaysOfTheDay.map(h => h.node.name).join(',')}>
						{holidaysOfTheDay[0].node.name +
							(holidaysOfTheDay.length > 1 ? ' + ' + (holidaysOfTheDay.length - 1) : '')}
					</div>
				) : null}
				<div className={'time-worked'}>
					{totalTime >= workingHours && !isNonWorkingDay ? <CheckmarkIcon locked={isLocked} /> : ''}
					<div className={'total'}>{timeRegistration.length > 0 ? totalTimeInHours : ''}</div>
				</div>
			</CalendarCellStyle>
		);

		return (
			<td
				style={{width: cellWidth ? cellWidth : ''}}
				title={holidaysOfTheDay ? holidaysOfTheDay.map(h => h.node.name).join(', ') : ''}
				onClick={() => selectDateFromCalendar(day, false)}
				onKeyPress={e => onKeyPressHandler(e, day, false)}
				data-userpilot={isDaySelected ? 'timesheets-day' : ''}
			>
				{isLocked ? (
					<TooltipContainer showDelay={300} infoText={intl.formatMessage({id: 'time-lock.tooltip-date-locked'})}>
						{dayCell}
					</TooltipContainer>
				) : (
					dayCell
				)}
			</td>
		);
	};

	const getDayInfo = (day, holidaysOfTheDay) => {
		const timeRegistration = getTimeRegistrationsOfTheDay(day, viewer.timeRegistrations.edges);
		const isOutsideThisMonth = day.month() !== currentViewingMonth.month();
		return (
			<DayCell
				key={day}
				intl={intl}
				day={day}
				timeRegistration={timeRegistration}
				isOutsideThisMonth={isOutsideThisMonth}
				holidaysOfTheDay={holidaysOfTheDay}
			></DayCell>
		);
	};

	const getWeeksOfMonth = () => {
		const startDate = currentViewingMonth.clone().startOf('month').startOf('week');

		const endDate = currentViewingMonth.clone().endOf('month').endOf('week');

		const calendar = [];
		while (startDate.isBefore(endDate)) {
			calendar.push({
				days: Array(7)
					.fill(0)
					.map((n, i) => startDate.clone().add(i, 'day')),
			});
			startDate.add(7, 'day');
		}

		return calendar;
	};

	//need to be stored to know if it's a 5 or 6 weeks long month
	const weekArray = getWeeksOfMonth();

	const getWeekInfo = (week, nbOfWeeks, index) => {
		const timeRegistrations = viewer.timeRegistrations.edges.filter(t => {
			const date = Util.CreateNonUtcMomentDate(t.node.year, t.node.month, t.node.day);
			//(..., null, '[]') is the momentjs way to make the dates inclusives...
			return date.isBetween(week.days[0], week.days[6], null, '[]');
		});

		const holidays = week.days.map(day => getHolidaysOfTheDay(currentPersonViewed, day));
		const showUserPilotOnWeek = index === 2;
		const fullweekLocked = lockedDate && lockedDate.isSameOrAfter(week.days[6], 'day');

		return (
			<tr
				key={week.days[0].week()}
				className={nbOfWeeks === 6 ? ' six-weeks-month' : ''}
				data-userpilot={showUserPilotOnWeek ? 'timesheets-week' : ''}
			>
				<WeekNumberCell
					intl={intl}
					timeRegistration={timeRegistrations}
					week={week}
					firstWeekOfMonth={index === 0}
					holidaysInTheWeek={holidays}
					fullweekLocked={fullweekLocked}
				></WeekNumberCell>
				{week.days.map((day, index) => getDayInfo(day, holidays[index]))}
			</tr>
		);
	};

	const getWeekDays = () => {
		const weekDaysShort = Moment.weekdays(true);
		return weekDaysShort.map((weekDay, index) => {
			return (
				<th style={{width: cellWidth ? cellWidth : ''}} key={weekDay}>
					{weekDay}
				</th>
			);
		});
	};

	/**
	 * Make the cells a bit  bigger if week view is active and if selection of roles is allowed
	 * @returns {boolean}
	 */
	const useBigCells = () => {
		return timePeriod === TIME_PERIOD.WEEK_VIEW && rolesAllowed;
	};

	const [calendarInfoDismissCount, setCalendarInfoDismissCount] = useState(localStorage.getItem(LOCALSTORAGE_DISMISS_KEY));
	const [calendarInfoDismissed, setCalendarInfoDismissed] = useState(calendarInfoDismissCount > 2);

	const dismissCalendarInfo = () => {
		const newDismissCount = calendarInfoDismissCount + 1;
		setCalendarInfoDismissCount(newDismissCount);
		setCalendarInfoDismissed(true);
		localStorage.setItem(LOCALSTORAGE_DISMISS_KEY, newDismissCount.toString());
	};

	const showGoogleCalendarInfo =
		viewer.calendarIntegrations.googleCalendar.calendarFetchEnabled != null &&
		!viewer.calendarIntegrations.googleCalendar.calendarFetchEnabled &&
		!calendarInfoDismissed &&
		!viewer.calendarIntegrations.googleCalendar.calendarTokenError;

	const showOutlookCalendarInfo =
		viewer.calendarIntegrations.outlookCalendar.calendarFetchEnabled != null &&
		!viewer.calendarIntegrations.outlookCalendar.calendarFetchEnabled &&
		!calendarInfoDismissed &&
		!viewer.calendarIntegrations.outlookCalendar.calendarTokenError;

	return (
		<CalenderStyle data-userpilot={'timesheets-calendar'}>
			{loading && <OverlayLoader />}
			{showGoogleCalendarInfo ? (
				<Alert
					text={'Create time registrations from your Google Calendar'}
					type={AlertType.INFORMATION}
					onDismiss={() => dismissCalendarInfo()}
				>
					<GoogleCalendarOAuth personId={currentPersonViewed.id} />
				</Alert>
			) : null}{' '}
			{showOutlookCalendarInfo ? (
				<Alert
					text={'Create time registrations from your Outlook Calendar'}
					type={AlertType.INFORMATION}
					onDismiss={() => dismissCalendarInfo()}
				>
					<OutlookCalendarOAuth page="timesheets" />
				</Alert>
			) : null}
			<CalendarTableStyles bigCells={useBigCells()}>
				<thead>
					<tr>
						<th style={{width: cellWidth ? cellWidth : ''}}>{intl.formatMessage({id: 'overview_time.week'})}</th>
						{getWeekDays()}
					</tr>
				</thead>
				<tbody data-cy="timesheets-calendar-body">
					{weekArray.map((week, index) => {
						return getWeekInfo(week, weekArray.length, index);
					})}
				</tbody>
			</CalendarTableStyles>
		</CalenderStyle>
	);
};

export default timesheetsCalendar;
