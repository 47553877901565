// @ts-check
import React, {useEffect, useState} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import styled from 'styled-components';
import {CaretIcon} from 'web-components';

import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';
import Util from '../../util/util';
import StatusWithDescription from './StatusWithDescription';
import {sortProgramStatuses, sortProjectStatuses} from './StatusUtil';
import {hasPermission} from '../../util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../Permissions';
import StatusSidebarUpdateSection from './StatusSidebarUpdateSection';
import GenericColorIndicatorSlideout from '../slideout/GenericColorIndicatorSlideout';
import {DeprecatedText as Text} from '@forecast-it/design-system';

const StatusSection = styled.div`
	margin-bottom: 48px;
	margin-right: 16px;
`;

const SubHeader = styled.div`
	display: flex;
	justify-content: space-between;
	cursor: pointer;
	margin-right: 16px;
`;

/**
 * @typedef {object} StatusSidebarProps
 * @property {object} viewer
 */

/**
 * @type {React.FC<StatusSidebarProps>}
 */
const StatusSidebar = ({viewer}) => {
	const {program, project} = viewer;

	const projectEditingDisabled = program
		? !hasPermission(PERMISSION_TYPE.PROGRAMS_UPDATE)
		: project
		? !hasPermission(PERMISSION_TYPE.PROJECTS_UPDATE) || Util.projectEditingDisabled(project)
		: null;

	const localStatusHistoryExpanded = localStorage.getItem('sidebar_status_history_expanded');

	const [statusHistoryExpanded, setStatusHistoryExpanded] = useState(false);

	useEffect(() => {
		// delay rendering of status history until after mount since the RichTextFields are heavy on performance
		const historyRenderTimer = setTimeout(() => {
			setStatusHistoryExpanded(localStatusHistoryExpanded !== null ? JSON.parse(localStatusHistoryExpanded) : true);
		}, 500);
		return () => clearTimeout(historyRenderTimer);
	}, []);

	const toggleStatusHistoryExpanded = () => {
		const newStatusState = !statusHistoryExpanded;
		trackEvent('Status History', 'Toggled', {expanded: newStatusState});
		localStorage.setItem('sidebar_status_history_expanded', JSON.stringify(newStatusState));
		setStatusHistoryExpanded(newStatusState);
	};

	const sortedProgramStatuses = program
		? program.programStatuses.edges
				.filter(programStatus => programStatus.node.id !== program.currentProgramStatus.id)
				.sort(sortProgramStatuses)
		: null;

	const sortedProjectStatuses = project
		? project.projectStatuses.edges
				.filter(projectStatus => projectStatus.node.id !== project.currentProjectStatus.id)
				.sort(sortProjectStatuses)
		: null;
	return (
		<GenericColorIndicatorSlideout
			name={program ? program.name : project.name}
			color={program ? program.color : project.projectColor}
			isMultiIndicator={!!program}
		>
			<StatusSection>
				<Text type={'heading'} variant={'m'}>
					Current Status
				</Text>
				{program
					? program.currentProgramStatus && (
							// @ts-ignore
							<StatusWithDescription programStatus={program.currentProgramStatus} projectStatus={null} />
					  )
					: project
					? project.currentProjectStatus && (
							// @ts-ignore
							<StatusWithDescription projectStatus={project.currentProjectStatus} programStatus={null} />
					  )
					: null}
			</StatusSection>
			{projectEditingDisabled ? null : (
				<StatusSection>
					<StatusSidebarUpdateSection project={project} program={program} firstName={viewer.firstName} />
				</StatusSection>
			)}
			{sortedProgramStatuses?.length > 0 || sortedProjectStatuses?.length > 0 ? (
				<StatusSection>
					<SubHeader onClick={() => toggleStatusHistoryExpanded()}>
						<Text type={'heading'} variant={'m'}>
							Previous Statuses
						</Text>
						<CaretIcon direction={statusHistoryExpanded ? CaretIcon.DIRECTION.UP : CaretIcon.DIRECTION.DOWN} />
					</SubHeader>
					{statusHistoryExpanded && sortedProgramStatuses
						? sortedProgramStatuses.map(programStatus => (
								// @ts-ignore
								<StatusWithDescription
									key={programStatus.node.id}
									programStatus={programStatus.node}
									projectStatus={null}
								/>
						  ))
						: statusHistoryExpanded && sortedProjectStatuses
						? sortedProjectStatuses.map(projectStatus => (
								// @ts-ignore
								<StatusWithDescription
									key={projectStatus.node.id}
									projectStatus={projectStatus.node}
									programStatus={null}
								/>
						  ))
						: null}
				</StatusSection>
			) : null}
		</GenericColorIndicatorSlideout>
	);
};

const StatusSidebarQuery = graphql`
	query StatusSidebar_Query($projectId: ID, $programPrefix: String) {
		viewer {
			actualPersonId
			component(name: "status_sidebar")
			...StatusSidebar_viewer @arguments(projectId: $projectId, programPrefix: $programPrefix)
		}
	}
`;

export {StatusSidebarQuery};

export default createFragmentContainer(StatusSidebar, {
	viewer: graphql`
		fragment StatusSidebar_viewer on Viewer @argumentDefinitions(projectId: {type: "ID"}, programPrefix: {type: "String"}) {
			firstName
			project(internalId: $projectId) {
				id
				name
				projectColor
				isInProjectGroup
				companyProjectId
				currentProjectStatus {
					id
					color
					...StatusWithDescription_projectStatus
				}
				projectStatuses(first: 10000) @connection(key: "Project_projectStatuses") {
					edges {
						node {
							id
							year
							month
							day
							hour
							minute
							second
							...StatusWithDescription_projectStatus
						}
					}
				}
			}
			program(prefix: $programPrefix) {
				id
				prefix
				name
				color
				currentProgramStatus {
					id
					ragStatus
					...StatusWithDescription_programStatus
				}
				programStatuses(first: 10000) @connection(key: "Program_programStatuses") {
					edges {
						node {
							id
							createdAt
							...StatusWithDescription_programStatus
						}
					}
				}
			}
		}
	`,
});
