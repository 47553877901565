import React from 'react';
import ForecastQueryRenderer from '../../../../ForecastQueryRenderer';
import {REPORT_GROUPINGS, HIDDEN_FEATURES} from '../../../../constants';
import ProjectRelayWrapper, {ProjectRelayWrapperV2Query} from './relay-wrappers/ProjectRelayWrapperV2';
import PhaseRelayWrapper, {PhaseRelayWrapperV2Query} from './relay-wrappers/PhaseRelayWrapperV2';
import ClientRelayWrapper, {ClientRelayWrapperQuery} from './relay-wrappers/ClientRelayWrapperV2';
import TaskReportRelayWrapper, {TaskRelayWrapperV2Query} from './relay-wrappers/TaskRelayWrapperV2';
import RoleRelayWrapper, {RoleRelayWrapperV2Query} from './relay-wrappers/RoleRelayWrapperV2';
import {parseTaskFilters} from '../../../shared/components/filters/filter_logic';
import SprintRelayWrapper, {SprintRelayWrapperV2Query} from './relay-wrappers/SprintRelayWrapperV2';
import Util from '../../../shared/util/util';

export const getSecondDropdownOptionsByGroupings = (firstDropdownValue, intl) => {
	const secondDropdownOptions = [];
	switch (firstDropdownValue) {
		case REPORT_GROUPINGS.PHASE:
			secondDropdownOptions.push(
				{value: REPORT_GROUPINGS.PROJECT, label: intl.formatMessage({id: 'common.group_by_project'})},
				...(!Util.isFeatureHidden(HIDDEN_FEATURES.CLIENT)
					? [{value: REPORT_GROUPINGS.CLIENT, label: intl.formatMessage({id: 'common.group_by_client'})}]
					: []),
				{value: REPORT_GROUPINGS.ROLE, label: intl.formatMessage({id: 'common.group_by_role'})},
				{value: null, label: 'No grouping'}
			);
			break;
		case REPORT_GROUPINGS.PROJECT:
			secondDropdownOptions.push(
				{value: REPORT_GROUPINGS.PHASE, label: intl.formatMessage({id: 'common.group_by_phase'})},
				{value: REPORT_GROUPINGS.ROLE, label: intl.formatMessage({id: 'common.group_by_role'})},
				{value: REPORT_GROUPINGS.SPRINT, label: intl.formatMessage({id: 'common.group_by_sprint'})},
				{value: null, label: 'No grouping'}
			);
			break;
		case REPORT_GROUPINGS.CLIENT:
			secondDropdownOptions.push(
				{value: REPORT_GROUPINGS.PROJECT, label: intl.formatMessage({id: 'common.group_by_project'})},
				{value: REPORT_GROUPINGS.ROLE, label: intl.formatMessage({id: 'common.group_by_role'})},
				{value: REPORT_GROUPINGS.PHASE, label: intl.formatMessage({id: 'common.group_by_phase'})},
				{value: REPORT_GROUPINGS.SPRINT, label: intl.formatMessage({id: 'common.group_by_sprint'})},
				{value: null, label: 'No grouping'}
			);
			break;
		case REPORT_GROUPINGS.ROLE:
			secondDropdownOptions.push(
				{value: REPORT_GROUPINGS.PROJECT, label: intl.formatMessage({id: 'common.group_by_project'})},
				...(!Util.isFeatureHidden(HIDDEN_FEATURES.CLIENT)
					? [{value: REPORT_GROUPINGS.CLIENT, label: intl.formatMessage({id: 'common.group_by_client'})}]
					: []),
				{value: REPORT_GROUPINGS.PHASE, label: intl.formatMessage({id: 'common.group_by_phase'})},
				{value: REPORT_GROUPINGS.SPRINT, label: intl.formatMessage({id: 'common.group_by_sprint'})},
				{value: null, label: 'No grouping'}
			);
			break;
		case null:
			return null;
		default:
			return 'No second dropdown options provided!';
	}
	return secondDropdownOptions;
};

const pageSize = 30;

/**
 * This will convert the selected task filters into Global Task filters
 * used in the search query filters.
 *
 * @param defaultSearchQueryFilters The default search query filters.
 * @param filters The task filters
 * @returns {*[]} An array of Global Task filters
 */
export const getSearchQueryFiltersValue = (defaultSearchQueryFilters, filters) => {
	const parsedFilters = parseTaskFilters(filters, false, false);
	return [...defaultSearchQueryFilters, ...parsedFilters];
};

export const getRelayWrapper = (
	tableHeader,
	groupings,
	searchQuery,
	rowIndex,
	enabledColumns,
	relayProps,
	object,
	nextLevelProps,
	currency,
	customFieldColumnCount,
	customFieldKeys
) => {
	switch (groupings[0]) {
		case REPORT_GROUPINGS.PROJECT:
			return (
				<ProjectRelayWrapper
					{...relayProps}
					tableHeader={tableHeader}
					groupings={groupings.slice(1)}
					searchQuery={searchQuery}
					rowIndex={rowIndex + 1}
					enabledColumns={enabledColumns}
					nextLevelProps={nextLevelProps}
					currency={currency}
					customFieldColumnCount={customFieldColumnCount}
					customFieldKeys={customFieldKeys}
					pageSize={pageSize}
				/>
			);

		case REPORT_GROUPINGS.TASK:
			return (
				<TaskReportRelayWrapper
					{...relayProps}
					tableHeader={tableHeader}
					searchQuery={searchQuery}
					rowIndex={rowIndex + 1}
					enabledColumns={enabledColumns}
					nextLevelProps={nextLevelProps}
					currency={currency}
					customFieldColumnCount={customFieldColumnCount}
					customFieldKeys={customFieldKeys}
					pageSize={pageSize}
				/>
			);

		case REPORT_GROUPINGS.PHASE:
			return (
				<PhaseRelayWrapper
					{...relayProps}
					tableHeader={tableHeader}
					groupings={groupings.slice(1)}
					searchQuery={searchQuery}
					rowIndex={rowIndex + 1}
					enabledColumns={enabledColumns}
					nextLevelProps={nextLevelProps}
					currency={currency}
					customFieldColumnCount={customFieldColumnCount}
					customFieldKeys={customFieldKeys}
					pageSize={pageSize}
				/>
			);

		case REPORT_GROUPINGS.CLIENT:
			return (
				<ClientRelayWrapper
					{...relayProps}
					tableHeader={tableHeader}
					groupings={groupings.slice(1)}
					searchQuery={searchQuery}
					rowIndex={rowIndex + 1}
					enabledColumns={enabledColumns}
					nextLevelProps={nextLevelProps}
					currency={currency}
					customFieldColumnCount={customFieldColumnCount}
					customFieldKeys={customFieldKeys}
					pageSize={pageSize}
				/>
			);

		case REPORT_GROUPINGS.ROLE:
			return (
				<RoleRelayWrapper
					{...relayProps}
					tableHeader={tableHeader}
					groupings={groupings.slice(1)}
					searchQuery={searchQuery}
					rowIndex={rowIndex + 1}
					enabledColumns={enabledColumns}
					nextLevelProps={nextLevelProps}
					currency={currency}
					customFieldColumnCount={customFieldColumnCount}
					customFieldKeys={customFieldKeys}
					pageSize={pageSize}
				/>
			);

		case REPORT_GROUPINGS.SPRINT:
			return (
				<SprintRelayWrapper
					{...relayProps}
					tableHeader={tableHeader}
					groupings={groupings.slice(1)}
					searchQuery={searchQuery}
					rowIndex={rowIndex + 1}
					enabledColumns={enabledColumns}
					nextLevelProps={nextLevelProps}
					currency={currency}
					customFieldColumnCount={customFieldColumnCount}
					customFieldKeys={customFieldKeys}
					pageSize={pageSize}
				/>
			);

		default:
			return '(TaskReportUtil)No relay wrapper provided!';
	}
};

const getComponentName = groupings => {
	switch (groupings[0]) {
		case REPORT_GROUPINGS.PROJECT:
			return 'project_relay_wrapper';

		case REPORT_GROUPINGS.TASK:
			return 'task_relay_wrapper';

		case REPORT_GROUPINGS.ROLE:
			return 'role_relay_wrapper';

		case REPORT_GROUPINGS.PHASE:
			return 'phase_relay_wrapper';

		case REPORT_GROUPINGS.CLIENT:
			return 'client_relay_wrapper';

		case REPORT_GROUPINGS.SPRINT:
			return 'sprint_relay_wrapper';

		default:
			return 'No component name provided!';
	}
};

const getQuery = groupings => {
	switch (groupings[0]) {
		case REPORT_GROUPINGS.PROJECT:
			return ProjectRelayWrapperV2Query;

		case REPORT_GROUPINGS.TASK:
			return TaskRelayWrapperV2Query;

		case REPORT_GROUPINGS.CLIENT:
			return ClientRelayWrapperQuery;

		case REPORT_GROUPINGS.ROLE:
			return RoleRelayWrapperV2Query;

		case REPORT_GROUPINGS.PHASE:
			return PhaseRelayWrapperV2Query;

		case REPORT_GROUPINGS.SPRINT:
			return SprintRelayWrapperV2Query;

		default:
			return 'No query provided!';
	}
};

const keyReducer = (accumulator, currentValue) =>
	accumulator + (currentValue && currentValue.length > 0 ? currentValue.value[0] : '');

const getQueryInfo = (groupings, searchQuery, object) => {
	const queryInfo = {};
	const keyEnding = searchQuery.filters.length > 0 && searchQuery.filters.reduce(keyReducer);
	const keyEnding2 = object && object.field + object.value;

	queryInfo.key = `query-render-relayWrapper-${keyEnding}-${keyEnding2}`;
	queryInfo.componentName = getComponentName(groupings, object);
	queryInfo.query = getQuery(groupings, object);
	return queryInfo;
};

export const getQueryRenderer = (
	tableHeader,
	groupings,
	searchQuery,
	rowIndex,
	enabledColumns,
	groupingFilter,
	nextLevelProps,
	currency,
	reportId,
	sharedId,
	customFieldColumnCount,
	customFieldKeys
) => {
	let searchQueryFiltersCopy = [...searchQuery.filters];
	if (groupingFilter !== null) {
		searchQueryFiltersCopy = searchQueryFiltersCopy.filter(filter => filter.field !== groupingFilter.field);
		searchQueryFiltersCopy.push(groupingFilter);
	}
	const newSearchQuery = {filters: searchQueryFiltersCopy};
	const query = getQueryInfo(groupings, searchQuery, groupingFilter);
	return (
		<ForecastQueryRenderer
			key={query.key}
			query={query.query}
			renderIfNotLoggedIn={true}
			variables={{
				pageSize,
				searchQuery: newSearchQuery,
				reportId,
				sharedId,
				customFieldKeys: customFieldKeys,
			}}
			render={relayProps => {
				return getRelayWrapper(
					tableHeader,
					groupings,
					newSearchQuery,
					rowIndex,
					enabledColumns,
					relayProps,
					groupingFilter,
					nextLevelProps,
					currency,
					customFieldColumnCount,
					customFieldKeys
				);
			}}
		/>
	);
};

/**
 *  Return the options for the first grouping.
 *
 * @param intl The I18n object
 * @returns Array of available groupings
 */
export const noGroupingFirstDropdownOptions = intl => {
	return [
		{value: REPORT_GROUPINGS.PROJECT, label: intl.formatMessage({id: 'common.group_by_project'})},
		{value: REPORT_GROUPINGS.PHASE, label: intl.formatMessage({id: 'common.group_by_phase'})},
		...(!Util.isFeatureHidden(HIDDEN_FEATURES.CLIENT)
			? [{value: REPORT_GROUPINGS.CLIENT, label: intl.formatMessage({id: 'common.group_by_client'})}]
			: []),
		{value: REPORT_GROUPINGS.ROLE, label: intl.formatMessage({id: 'common.group_by_role'})},
		{value: null, label: intl.formatMessage({id: 'project_sprints.group_by_people_disable'})},
	];
};

export const getTotalTimeEntries = timeRegistrations => {
	if (timeRegistrations !== undefined) {
		return timeRegistrations.edges.reduce((acc, elem) => acc + elem.node.minutesRegistered, 0);
	}
	return 0;
};
