import React from 'react';
import { FlexRow, Button } from '@forecast-it/design-system';
import Logo, { LOGO_SIZE } from '../../shared/components/logo/Logo';
import { FLEX_ALIGN_ITEMS } from '@forecast-it/design-system';
import { useIntl } from 'react-intl';
import { LogoWrapper } from '../primary/PrimaryNavigationMain';
const ActionBar = ({ onClickSave, onClickCancel }) => {
    const { formatMessage } = useIntl();
    return (React.createElement(FlexRow, null,
        React.createElement(LogoWrapper, null,
            React.createElement(Logo, { size: LOGO_SIZE.SMALL })),
        React.createElement(FlexRow, { flex: "1 1 auto", alignSelf: FLEX_ALIGN_ITEMS.END, justifyContent: FLEX_ALIGN_ITEMS.END },
            React.createElement(Button, { onClick: onClickSave }, formatMessage({ id: 'common.save_changes' })),
            React.createElement(Button, { onClick: onClickCancel, emphasis: "medium" }, formatMessage({ id: 'common.cancel' })))));
};
export default ActionBar;
