import React from 'react';
import { useTheme } from 'styled-components';
import { ForecastTheme } from '@forecast-it/design-system';
import { SageLogo } from '../../../../images/SageLogo';
import { ForecastLogo } from '../../../../images/ForecastLogo';
export var LOGO_SIZE;
(function (LOGO_SIZE) {
    LOGO_SIZE[LOGO_SIZE["SMALL"] = 0] = "SMALL";
    LOGO_SIZE[LOGO_SIZE["MEDIUM"] = 1] = "MEDIUM";
})(LOGO_SIZE || (LOGO_SIZE = {}));
const getLogoDimensions = (size, theme = ForecastTheme.Sky) => {
    const logoDimensions = {
        [ForecastTheme.Sky]: {
            [LOGO_SIZE.SMALL]: {
                width: '81',
                height: '14',
            },
            [LOGO_SIZE.MEDIUM]: {
                width: '120',
                height: '20',
            },
        },
        [ForecastTheme.Sage]: {
            [LOGO_SIZE.SMALL]: {
                width: '50',
                height: '28',
            },
            [LOGO_SIZE.MEDIUM]: {
                width: '142',
                height: '80',
            },
        },
    };
    return logoDimensions[theme][size];
};
const Logo = ({ size = LOGO_SIZE.SMALL }) => {
    const theme = useTheme();
    const logoDimensions = getLogoDimensions(size, theme.themeName);
    switch (theme.themeName) {
        case ForecastTheme.Sage:
            return React.createElement(SageLogo, Object.assign({}, logoDimensions));
        default:
            return React.createElement(ForecastLogo, Object.assign({}, logoDimensions));
    }
};
export default Logo;
