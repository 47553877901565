/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type settingsRoles_role$ref: FragmentReference;
declare export opaque type settingsRoles_role$fragmentType: settingsRoles_role$ref;
export type settingsRoles_role = {|
  +id: string,
  +name: ?string,
  +notifyProjectOverview: ?boolean,
  +timeRegCount: ?number,
  +$refType: settingsRoles_role$ref,
|};
export type settingsRoles_role$data = settingsRoles_role;
export type settingsRoles_role$key = {
  +$data?: settingsRoles_role$data,
  +$fragmentRefs: settingsRoles_role$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "settingsRoles_role",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyProjectOverview",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeRegCount",
      "storageKey": null
    }
  ],
  "type": "Role"
};
// prettier-ignore
(node/*: any*/).hash = '107abfa3d0af713306ef6e970ddb5332';

module.exports = node;
