import React, {useMemo} from 'react';
import {RowWrapper, BaselineRowWrapper, GrowableColumn} from './Elements.styled';
import {keyBy} from 'lodash';
import {Column} from './Elements.styled';
import {getColumnWidth} from './utils';
import {useIntl} from 'react-intl';
import Util from '../../../../util/util';
import {NumericTileV2, UNIT} from './task-row-elements/NumericTile';

export const BaselineRow = ({
	node: {id, baselineTargetMinutes, baselineTargetPrice, baselineCost, availableColumns, currencySymbol},
}) => {
	const intl = useIntl();
	const columnsObject = useMemo(() => keyBy(availableColumns, 'name'), [availableColumns]);

	const nameColumnWidth = useMemo(() => {
		let width = getColumnWidth('task-name', columnsObject);
		const idColumnWidth = getColumnWidth('task-id', columnsObject);
		width += idColumnWidth || 0;
		return width;
	}, [availableColumns]);

	return (
		<RowWrapper>
			<BaselineRowWrapper>
				{/* task-id and task-name used for the same column*/}
				<GrowableColumn width={nameColumnWidth}>{intl.formatMessage({id: 'baseline.baseline'})}</GrowableColumn>
				{getColumnWidth('date', columnsObject) && <Column width={getColumnWidth('date', columnsObject)} />}
				{getColumnWidth('assigned-role', columnsObject) && (
					<Column width={getColumnWidth('assigned-role', columnsObject)} />
				)}
				{getColumnWidth('assigned-person', columnsObject) && (
					<Column width={getColumnWidth('assigned-person', columnsObject)} />
				)}
				{getColumnWidth('status', columnsObject) && <Column width={getColumnWidth('status', columnsObject)} />}
				{getColumnWidth('phase', columnsObject) && <Column width={getColumnWidth('phase', columnsObject)} />}
				{getColumnWidth('sprint', columnsObject) && <Column width={getColumnWidth('sprint', columnsObject)} />}
				{getColumnWidth('done-percentage', columnsObject) && (
					<Column width={getColumnWidth('done-percentage', columnsObject)} />
				)}
				{getColumnWidth('period-target', columnsObject) && (
					<Column width={getColumnWidth('period-target', columnsObject)} />
				)}
				{getColumnWidth('forecast', columnsObject) && (
					<BaselineEstimateColumn
						baselineTargetMinutes={baselineTargetMinutes}
						width={getColumnWidth('forecast', columnsObject)}
					/>
				)}
				{getColumnWidth('time-entries', columnsObject) && (
					<Column width={getColumnWidth('time-entries', columnsObject)} />
				)}
				{getColumnWidth('billable-time-entries', columnsObject) && (
					<Column width={getColumnWidth('billable-time-entries', columnsObject)} />
				)}
				{getColumnWidth('non-billable-time-entries', columnsObject) && (
					<Column width={getColumnWidth('non-billable-time-entries', columnsObject)} />
				)}
				{getColumnWidth('remaining', columnsObject) && <Column width={getColumnWidth('remaining', columnsObject)} />}
				{getColumnWidth('over-forecast', columnsObject) && (
					<Column width={getColumnWidth('over-forecast', columnsObject)} />
				)}
				{getColumnWidth('price', columnsObject) && (
					<BaselinePlanRevenueColumn
						baselineTargetPrice={baselineTargetPrice}
						currencySymbol={currencySymbol}
						width={getColumnWidth('price', columnsObject)}
						formatNumber={intl.formatNumber}
					/>
				)}
				{getColumnWidth('actual-price', columnsObject) && (
					<Column width={getColumnWidth('actual-price', columnsObject)} />
				)}
				{getColumnWidth('planned-cost', columnsObject) && (
					<BaselinePlannedCostColumn
						baselineCost={baselineCost}
						currencySymbol={currencySymbol}
						width={getColumnWidth('planned-cost', columnsObject)}
						formatNumber={intl.formatNumber}
					/>
				)}
				{getColumnWidth('actual-cost', columnsObject) && (
					<Column width={getColumnWidth('actual-cost', columnsObject)} />
				)}

				{getColumnWidth('labels', columnsObject) && <Column width={getColumnWidth('labels', columnsObject)} />}
				{getColumnWidth('approved', columnsObject) && <Column width={getColumnWidth('approved', columnsObject)} />}
				<Column width={60} />
				<Column width={20} />
			</BaselineRowWrapper>
		</RowWrapper>
	);
};

const BaselineEstimateColumn = React.memo(({baselineTargetMinutes, width}) => {
	let content = null;
	content = (
		<NumericTileV2
			key={'baseline-estimate'}
			value={baselineTargetMinutes}
			unit={UNIT.HOURS}
			cy={'baseline-estimate-tile'}
			inputIndent
		/>
	);
	return <Column width={width}>{content}</Column>;
});

const BaselinePlanRevenueColumn = React.memo(({baselineTargetPrice, currencySymbol, width, formatNumber}) => {
	const placeUnitBeforeValue = Util.CurrencyIsPrefixed(currencySymbol);

	const price = formatNumber(baselineTargetPrice, {format: 'always_two_decimal'});
	let content = null;
	content = (
		<NumericTileV2
			key={'baseline-price'}
			placeUnitBeforeValue={placeUnitBeforeValue}
			value={price}
			unit={currencySymbol}
			cy={'baseline-price-tile'}
		/>
	);
	return <Column width={width}>{content}</Column>;
});

const BaselinePlannedCostColumn = React.memo(({baselineCost, currencySymbol, width, formatNumber}) => {
	const placeUnitBeforeValue = Util.CurrencyIsPrefixed(currencySymbol);

	const costNumber = formatNumber(baselineCost, {format: 'always_two_decimal'});
	let content = null;
	content = (
		<NumericTileV2
			key={'baseline-price'}
			placeUnitBeforeValue={placeUnitBeforeValue}
			value={costNumber}
			unit={currencySymbol}
			cy={'baseline-price-tile'}
		/>
	);
	return <Column width={width}>{content}</Column>;
});
