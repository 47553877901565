import ChangeViewButton from '../../../forecast-app/shared/components/changeview/ChangeViewButton';
import ChangeViewGroupings from '../../../forecast-app/shared/components/changeview/ChangeViewGroupings';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { REPORT_COLUMNS } from './Constants';
const REPORTS_GROUPING = [REPORT_COLUMNS.TYPE, REPORT_COLUMNS.CREATED_BY, REPORT_COLUMNS.ACCESS, REPORT_COLUMNS.FAVORITES];
const ReportsPageChangeViewButton = ({ groupings, onGroupingsChange }) => {
    return (React.createElement(ChangeViewButton, null,
        React.createElement(ChangeViewGroupings, { maxLevel: 3, groupings: groupings, onChange: onGroupingsChange }, REPORTS_GROUPING.map(grouping => (React.createElement(ChangeViewGroupings.Grouping, { key: grouping, value: grouping },
            React.createElement(FormattedMessage, { id: 'report.column.' + grouping })))))));
};
export default ReportsPageChangeViewButton;
