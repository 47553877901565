import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';
class Calendar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			viewedMonth: props.viewedMonth ? props.viewedMonth : props.value ? Moment(props.value).month() : Moment().month(),
			viewedYear: props.viewedYear ? props.viewedYear : props.value ? Moment(props.value).year() : Moment().year(),
			hoveredDate: null,
			keyCodeUsed: null,
			previouslyFocusedDate: null,
		};
	}

	componentDidMount() {
		//if date range picker refs need to be passed to parent component so other calendar can access and set focus on day buttons
		if (this.props.isDateRangePicker || this.props.isFixedRangePicker) {
			this.setRefs();
		}
		let dateToFocus;
		if ((this.props.isDateRangePicker || this.props.isFixedRangePicker) && this.props.first) {
			if (this.props.startDate) {
				dateToFocus = this.props.startDate.clone();
			} else {
				dateToFocus = Moment();
			}
		} else if (!this.props.isDateRangePicker && !this.props.isFixedRangePicker) {
			if (this.props.value) {
				dateToFocus = this.props.value.clone();
			} else if (this.props.startDate) {
				dateToFocus = this.props.startDate.clone();
			} else {
				dateToFocus = Moment();
			}
		}
		if (dateToFocus !== undefined) {
			//delay the focus because the element is not available yet
			requestAnimationFrame(() => {
				const key = 'day_button_' + dateToFocus.date() + '_' + dateToFocus.month() + '_' + dateToFocus.year();
				if (this[key] !== undefined && this[key] !== null) {
					this[key].focus();
				}
			});
		}
	}

	UNSAFE_componentWillUpdate(nextProps, nextState) {
		if (this.props.isSinglePicker) return;
		if (this.state.viewedMonth !== nextProps.viewedMonth || this.state.viewedYear !== nextProps.viewedYear) {
			this.setState({
				viewedMonth: nextProps.viewedMonth,
				viewedYear: nextProps.viewedYear,
			});
		}
	}

	markSelected(day) {
		if (this.props.isFixedRangePicker && this.props.rangeType) {
			if (!this.props.hoveredDate) {
				return day.isSame(this.props.startDate, 'd') || day.isSame(this.props.endDate, 'd');
			} else {
				const range = this.props.rangeType;
				return (
					day.isSame(this.props.hoveredDate.clone().startOf(range), 'd') ||
					day.isSame(this.props.hoveredDate.clone().endOf(range), 'd')
				);
			}
		}

		if (this.props.isDateRangePicker) {
			if (this.props.startDate && this.props.startDate.isSame(day, 'd')) {
				return (
					!this.props.hoveredDate ||
					(!this.props.selectingStartDate && !this.props.hoveredDate.isBefore(this.props.startDate))
				);
			}
			if (this.props.endDate && this.props.endDate.isSame(day, 'd')) {
				return (
					!this.props.hoveredDate ||
					(this.props.selectingStartDate && !this.props.hoveredDate.isAfter(this.props.endDate)) ||
					(!this.props.selectingStartDate &&
						(!this.props.startDate || !this.props.hoveredDate.isAfter(this.props.startDate)))
				);
			}
		} else {
			return !this.props.hoveredDate && this.props.startDate && this.props.startDate.isSame(day, 'd');
		}
	}

	markBeingChanged(day) {
		// this is only executed if there is hovered date
		if (this.props.isFixedRangePicker) {
			return (
				(this.props.hoveredDate.isBefore(this.props.startDate) || this.props.hoveredDate.isAfter(this.props.endDate)) &&
				(day.isSame(this.props.startDate, 'd') || day.isSame(this.props.endDate, 'd'))
			);
		}

		let marked = false;
		if (this.props.startDate && day.isSame(this.props.startDate, 'd')) {
			marked = this.props.selectingStartDate || this.props.hoveredDate.isBefore(this.props.startDate);
		}
		if (this.props.endDate && day.isSame(this.props.endDate, 'd')) {
			marked =
				marked ||
				(this.props.selectingStartDate && this.props.hoveredDate.isAfter(this.props.endDate)) ||
				(!this.props.selectingStartDate &&
					this.props.startDate &&
					!this.props.hoveredDate.isBefore(this.props.startDate));
		}

		return marked;
	}

	markInRange(day) {
		let markInRange = false;
		if (this.props.isDateRangePicker) {
			if (this.props.startDate && this.props.endDate) {
				markInRange = day.isAfter(this.props.startDate) && day.isBefore(this.props.endDate);
			}
			if (!markInRange && this.props.hoveredDate) {
				if (this.props.startDate) {
					if (this.props.hoveredDate.isAfter(this.props.startDate) && !this.props.selectingStartDate) {
						markInRange = day.isAfter(this.props.startDate) && day.isBefore(this.props.hoveredDate);
					} else if (this.props.hoveredDate.isBefore(this.props.startDate)) {
						markInRange = day.isAfter(this.props.hoveredDate) && day.isBefore(this.props.startDate);
					}
				} else if (this.props.endDate) {
					if (this.props.hoveredDate.isAfter(this.props.endDate)) {
						markInRange = day.isAfter(this.props.endDate) && day.isBefore(this.props.hoveredDate);
					} else if (this.props.hoveredDate.isBefore(this.props.endDate)) {
						markInRange = day.isAfter(this.props.hoveredDate) && day.isBefore(this.props.endDate);
					}
				}
			}
		} else if (this.props.isFixedRangePicker && this.props.rangeType) {
			if (this.props.startDate && this.props.endDate) {
				markInRange = day.isAfter(this.props.startDate) && day.isBefore(this.props.endDate);
			}
			if (!markInRange && this.props.hoveredDate) {
				const range = this.props.rangeType;
				markInRange =
					day.isAfter(this.props.hoveredDate.clone().startOf(range)) &&
					day.isBefore(this.props.hoveredDate.clone().endOf(range));
			}
		}
		return markInRange;
	}

	onDayMouseEnter(day) {
		if (this.props.onDayMouseEnter) {
			this.props.onDayMouseEnter(day);
		}
	}

	onTableBodyMouseLeave() {
		if (this.props.onTableBodyMouseLeave) {
			this.props.onTableBodyMouseLeave();
		}
	}

	getTableBodyWeekRow(week, baseDate, index) {
		const {disabledDates} = this.props;
		const disabledDatesIsCallback = typeof disabledDates === 'function';
		return (
			<tr className="table-body-week-row" key={index}>
				{week.days.map((day, index) => {
					const disabled =
						(disabledDatesIsCallback && disabledDates(day)) ||
						(!disabledDatesIsCallback &&
							disabledDates &&
							disabledDates.find(disabledDate => day.isSame(disabledDate))) ||
						(this.props.startDateLimite && day.isBefore(this.props.startDateLimite)) ||
						(this.props.endDateLimite && day.isSameOrAfter(this.props.endDateLimite, 'd'));
					return (
						<td key={index}>
							<button
								data-cy={
									!day.isSame(baseDate, 'month')
										? (this.props.first ? 'first-' : '') + 'calendar-day-' + day.format('DD') + '-disabled'
										: (this.props.first ? 'first-' : '') + 'calendar-day-' + day.format('DD')
								}
								onMouseEnter={day.isSame(baseDate, 'month') ? this.onDayMouseEnter.bind(this, day) : null}
								onClick={this.handleDateClick.bind(this, day, true)}
								className={
									'calendar-day' +
									(!day.isSame(baseDate, 'month')
										? ' outside'
										: this.props.hoveredDate && this.markBeingChanged(day)
										? ' being-changed'
										: this.markSelected(day)
										? ' selected'
										: this.markInRange(day)
										? ' in-range'
										: '') +
									(this.props.disableHoverColor ? ' no-hover' : '') +
									(disabled ? ' disabled' : '')
								}
								onKeyDown={this.handleKeyPress.bind(this, day)}
								ref={button =>
									(this['day_button_' + day.date() + '_' + day.month() + '_' + day.year()] = button)
								}
								aria-label={day.format('dddd DD MMMM YYYY')}
								disabled={!day.isSame(baseDate, 'month') || disabled}
								data-day-number={day.format('D') + (!day.isSame(baseDate, 'month') ? '-disabled' : '-enabled')}
							>
								<span>{day.format('D')}</span>
							</button>
						</td>
					);
				})}
			</tr>
		);
	}
	weeks(moment) {
		const startDate = moment.clone().startOf('month').startOf('week');
		const endDate = moment.clone().endOf('month').endOf('week');
		const calendar = [];
		while (startDate.isBefore(endDate)) {
			calendar.push({
				days: Array(7)
					.fill(0)
					.map((n, i) => startDate.clone().add(i, 'day')),
			});
			startDate.add(7, 'day');
		}
		return calendar;
	}

	previousMonth(keyCodeUsed, previouslyFocusedDate, e) {
		if (e && this.props.noPropagation) {
			e.stopPropagation();
			e.preventDefault();
		}

		if (this.props.viewPreviousMonth) {
			this.props.viewPreviousMonth(keyCodeUsed, previouslyFocusedDate);
		} else {
			if (this.state.viewedMonth === 0) {
				this.setState({viewedMonth: 11, viewedYear: this.state.viewedYear - 1, keyCodeUsed, previouslyFocusedDate});
			} else {
				this.setState({viewedMonth: this.state.viewedMonth - 1, keyCodeUsed, previouslyFocusedDate});
			}
			trackEvent('Calender Previous Month Button', 'Clicked');
		}
	}
	nextMonth(keyCodeUsed, previouslyFocusedDate, e) {
		if (e && this.props.noPropagation) {
			e.stopPropagation();
			e.preventDefault();
		}

		if (this.props.viewNextMonth) {
			this.props.viewNextMonth(keyCodeUsed, previouslyFocusedDate);
		} else {
			if (this.state.viewedMonth === 11) {
				this.setState({viewedMonth: 0, viewedYear: this.state.viewedYear + 1, keyCodeUsed}, previouslyFocusedDate);
			} else {
				this.setState({viewedMonth: this.state.viewedMonth + 1, keyCodeUsed, previouslyFocusedDate});
			}
			trackEvent('Calender Next Month Button', 'Clicked');
		}
	}

	previousYear(keyCodeUsed, previouslyFocusedDate) {
		const keyCode = keyCodeUsed + '_ctrl';
		if (this.props.viewPreviousYear) {
			this.props.viewPreviousYear(keyCode, previouslyFocusedDate);
		} else {
			this.setState({viewedYear: this.state.viewedYear - 1, keyCodeUsed: keyCode, previouslyFocusedDate});
		}
	}
	nextYear(keyCodeUsed, previouslyFocusedDate) {
		const keyCode = keyCodeUsed + '_ctrl';
		if (this.props.viewNextYear) {
			this.props.viewNextYear(keyCode, previouslyFocusedDate);
		} else {
			this.setState({viewedYear: this.state.viewedYear + 1, keyCodeUsed: keyCode, previouslyFocusedDate});
		}
	}

	handleDateClick(moment, clickedFromButton, e) {
		trackEvent('Calender Date', 'Clicked', {date: moment.format('YYYY-MM-DD')});
		if (e && this.props.noPropagation) {
			e.stopPropagation();
			e.preventDefault();
		}

		if (this.props.value && moment === this.props.value) return;
		//if date range picker and ctrl + enter/space was cicked add clicked date to date range if exists if not default behaviour
		if (clickedFromButton && this.props.isDateRangePicker && e.ctrlKey && this.props.startDate && this.props.endDate) {
			if (moment.isBefore(this.props.startDate)) {
				this.props.addContiguousDate(moment, true);
			} else if (moment.isAfter(this.props.endDate)) {
				this.props.addContiguousDate(moment, false);
			}
		} else {
			this.props.onDateClick(moment);
		}
	}

	handleKeyPress(day, e) {
		//if day is null it means that key was pressed on previous/next month arrow
		let requestedDay = null;
		// if day is not null it means handle key press was called while calendar's day button was focused
		if (day) {
			if (e.keyCode === 40) {
				// down arrow, move to same day next week
				e.preventDefault();
				requestedDay = day.clone().add(7, 'day');
				if (
					requestedDay.month() !== this.state.viewedMonth &&
					((this.props.isDateRangePicker && !this.props.first) || !this.props.isDateRangePicker)
				) {
					//if requested day is out of currently viewed scope
					this.nextMonth(40, day);
				}
			} else if (e.keyCode === 38) {
				//up arrow, move to same day past week
				e.preventDefault();
				requestedDay = day.clone().add(-7, 'day');
				if (
					requestedDay.month() !== this.state.viewedMonth &&
					((this.props.isDateRangePicker && this.props.first) || !this.props.isDateRangePicker)
				) {
					//if requested day is out of currently viewed scope
					this.previousMonth(38, day);
				}
			} else if (e.keyCode === 39) {
				//right arrow
				requestedDay = day.clone().add(1, 'day');
				if (e.shiftKey) {
					//if contiguous date and pressed with shift add to existing date-range
					if (this.props.startDate && !this.props.endDate) {
						if (day.isSame(this.props.startDate, 'd')) {
							this.handleDateClick(requestedDay, false);
						}
					} else if (this.props.startDate && this.props.endDate && day.isSame(this.props.startDate, 'd')) {
						this.props.addContiguousDate(requestedDay, true);
					} else if (this.props.endDate && day.isSame(this.props.endDate, 'd')) {
						this.props.addContiguousDate(requestedDay, false);
					}
				}
				//if requested day is out of currently viewed scope
				if (
					requestedDay.month() !== this.state.viewedMonth &&
					((this.props.isDateRangePicker && !this.props.first) || !this.props.isDateRangePicker)
				) {
					this.nextMonth(39, day);
				}
			} else if (e.keyCode === 37) {
				//left arrow, move to previous day
				requestedDay = day.clone().add(-1, 'day');
				if (e.shiftKey) {
					//if focus on end date and pressed with shift remove from existing date-range
					if (this.props.endDate && day.isSame(this.props.endDate, 'd')) {
						this.props.addContiguousDate(requestedDay, false);
					} else if (this.props.startDate && day.isSame(this.props.startDate, 'd')) {
						this.props.addContiguousDate(requestedDay, true);
					}
				}
				if (
					requestedDay.month() !== this.state.viewedMonth &&
					((this.props.isDateRangePicker && this.props.first) || !this.props.isDateRangePicker)
				) {
					//if requested day is out of currently viewed scope
					this.previousMonth(37, day);
				}
			} else if (e.keyCode === 36) {
				//home button, move to first day of currently viewed month
				requestedDay = day.clone().startOf('month');
			} else if (e.keyCode === 35) {
				//end button, move to last day of currently viewed month
				requestedDay = day.clone().endOf('month');
			} else if (e.keyCode === 33) {
				//page up button, move to same day in previous month;
				requestedDay = day.clone();
				if (e.ctrlKey) {
					requestedDay.add(-1, 'year');
					this.previousYear(33, day);
				} else {
					requestedDay.add(-1, 'month');
					if (
						requestedDay.month() !== this.state.viewedMonth &&
						((this.props.isDateRangePicker && this.props.first) || !this.props.isDateRangePicker)
					) {
						//if requested day is out of currently viewed scope
						this.previousMonth(33, day);
					}
				}
			} else if (e.keyCode === 34) {
				//page down button,
				requestedDay = day.clone();
				if (e.ctrlKey) {
					requestedDay.add(1, 'year');
					this.nextYear(34, day);
				} else {
					requestedDay.add(1, 'month');
					//if requested day is out of currently viewed scope
					if (
						requestedDay.month() !== this.state.viewedMonth &&
						((this.props.isDateRangePicker && !this.props.first) || !this.props.isDateRangePicker)
					) {
						this.nextMonth(34, day);
					}
				}
			}
			if (requestedDay) {
				const key = 'day_button_' + requestedDay.date() + '_' + requestedDay.month() + '_' + requestedDay.year();
				const requestedElement = this[key];
				if (requestedElement) {
					requestedElement.focus();
				} else if (
					this.props.isDateRangePicker &&
					this.props.refsMap &&
					this.props.refsMap.has(key) &&
					this.props.refsMap.get(key) !== undefined
				) {
					// if date range picker day buttons refs from second calendar are passed as a map
					this.props.refsMap.get(key).focus();
				}
			}
		}
	}

	setRefs() {
		//this function can only be called by calendars in date range picker
		//need to set refs for each of the day buttons in order to pass them as props to other calendar in date range picker. Each of calendars must be able to focus other calendar's buttons
		const refsMap = new Map();
		let date = Moment().set('month', this.state.viewedMonth).set('year', this.state.viewedYear).startOf('month');
		const finalDate = date.clone().endOf('month');
		while (!date.isAfter(finalDate)) {
			const key = 'day_button_' + date.date() + '_' + date.month() + '_' + date.year();
			refsMap.set(key, this[key]);
			date.add(1, 'day');
		}
		this.props.setRefs(refsMap, this.props.first);
	}

	render() {
		const baseDate = Moment();
		baseDate.set('date', 1);
		baseDate.set('month', this.state.viewedMonth);
		baseDate.set('year', this.state.viewedYear);

		let baseDateMonth = baseDate.format('MMMM');
		const baseDateFormatted = baseDateMonth.charAt(0).toUpperCase() + baseDateMonth.substring(1);
		const calendar = this.weeks(baseDate);
		const weekDaysShort = Moment.weekdaysMin(true);
		const previousMonthDisabled =
			this.props.startDateLimite && baseDate.clone().date(1).add(-1, 'day').isBefore(this.props.startDateLimite);
		const nextMonthDisabled = this.props.endDateLimite && baseDate.clone().endOf('month').isAfter(this.props.endDateLimite);
		return (
			<table className="calendar-table">
				<thead>
					<tr className="month-controls">
						{this.props.isDateRangePicker && !this.props.first ? (
							<th className="viewed-year">{this.state.viewedYear}</th>
						) : (
							<th>
								<button
									ref={button => (this.previous_month_button = button)}
									className="previous-control"
									data-cy="previous_month_button"
									onClick={this.previousMonth.bind(this, null, null)}
									onKeyDown={this.handleKeyPress.bind(this, null)}
									disabled={previousMonthDisabled}
								/>
							</th>
						)}
						{!this.props.isDateRangePicker ? (
							<th colSpan="5">
								<div className="viewed-month">{baseDateFormatted}</div>
								<div className="viewed-year">{this.state.viewedYear}</div>
							</th>
						) : (
							<th className="viewed-month" colSpan="5">
								{baseDateFormatted}
							</th>
						)}

						{this.props.isDateRangePicker && this.props.first ? (
							<th className="viewed-year">{this.state.viewedYear}</th>
						) : (
							<th>
								<button
									ref={button => (this.next_month_button = button)}
									className="next-control"
									data-cy="next_month_button"
									onClick={this.nextMonth.bind(this, null, null)}
									onKeyDown={this.handleKeyPress.bind(this, null)}
									disabled={nextMonthDisabled}
								/>
							</th>
						)}
					</tr>
					<tr className="weekdays">
						{weekDaysShort.map((weekDay, index) => (
							<th key={index}>{weekDay}</th>
						))}
					</tr>
				</thead>
				<tbody onMouseLeave={this.onTableBodyMouseLeave.bind(this)}>
					{calendar.map((week, index) => this.getTableBodyWeekRow(week, baseDate, index))}
				</tbody>
			</table>
		);
	}
}
Calendar.propTypes = {
	value: PropTypes.object,
	onDateClick: PropTypes.func.isRequired,
	refsMap: PropTypes.object,
	setRefs: PropTypes.func,
	isDateRangePicker: PropTypes.bool,
	isFixedRangePicker: PropTypes.bool,
	addContiguousDate: PropTypes.func,
	previouslyFocusedDate: PropTypes.object,
	resetPreviouslyFocusedDate: PropTypes.func,
	viewNextYear: PropTypes.func,
	viewPreviousYear: PropTypes.func,
	rangeType: PropTypes.oneOf(['day', 'week', 'month']),
	disabledDates: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
};
export default Calendar;
