/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type settingsSubscription_subscription$ref: FragmentReference;
declare export opaque type settingsSubscription_subscription$fragmentType: settingsSubscription_subscription$ref;
export type settingsSubscription_subscription = {|
  +planId: ?string,
  +currency: ?string,
  +email: ?string,
  +seats: ?number,
  +virtualSeats: ?number,
  +collaboratorSeats: ?number,
  +seatPrice: ?number,
  +applyTieredDiscount: ?boolean,
  +inRenewal: ?boolean,
  +contractStartDate: ?string,
  +contractEndDate: ?string,
  +contractTerm: ?number,
  +csmName: ?string,
  +csmEmail: ?string,
  +customerSupportTier: ?string,
  +$refType: settingsSubscription_subscription$ref,
|};
export type settingsSubscription_subscription$data = settingsSubscription_subscription;
export type settingsSubscription_subscription$key = {
  +$data?: settingsSubscription_subscription$data,
  +$fragmentRefs: settingsSubscription_subscription$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "settingsSubscription_subscription",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "planId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "seats",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "virtualSeats",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "collaboratorSeats",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "seatPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "applyTieredDiscount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "inRenewal",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contractStartDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contractEndDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contractTerm",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "csmName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "csmEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerSupportTier",
      "storageKey": null
    }
  ],
  "type": "Subscription"
};
// prettier-ignore
(node/*: any*/).hash = '66837043d6ed0245bfec637407dce2c5';

module.exports = node;
