import React from 'react';
import PropTypes from 'prop-types';
import {createFragmentContainer, graphql} from 'react-relay';
import {Dropdown, HarvestIcon, IconText, IconTextIcon} from 'web-components';
import _ from 'lodash';
import {useIntl} from 'react-intl';

import DeprecatedProjectIndicatorJS from '../../project-indicator/js/DeprecatedProjectIndicatorJS';

export const ProjectDropdown = ({
	projects,
	emphasizeEmptyState,
	maxHeight,
	dropdownAlignment,
	width,
	name,
	selectedItems,
	showRole,
	onRemove,
	onSelect,
	optionsName,
	selectedGroupName,
	userpilot,
	isMultiSelect,
	isClearable,
	groupByStatus,
	hideEmptyGroups,
	showRunning,
	showPlanning,
	showOpportunity,
	showHalted,
	showDone,
	showSecondaryIcon,
	isHarvestUser,
	cy,
	disabled,
	label,
	required,
	size,
	usePortal,
	headerLines,
}) => {
	const intl = useIntl();

	const sortedProjects = [...projects].sort((a, b) => {
		const aName = a.node.name || 'Untitled';
		const bName = b.node.name || 'Untitled';
		if (aName.toLowerCase() < bName.toLowerCase()) return -1;
		if (aName.toLowerCase() > bName.toLowerCase()) return 1;
		return 0;
	});

	const groupProjectsByStatus = () => {
		const groupedProjects = _.groupBy(sortedProjects, 'node.status');
		return groupedProjects;
	};

	const getLine = project => {
		const {id, name, customProjectId, companyProjectId, harvestProject} = project;
		return showSecondaryIcon ? (
			<IconTextIcon
				cy={`option-${name}`}
				key={id}
				value={id}
				text={name}
				searchString={`P${companyProjectId} ${customProjectId} ${name}`}
			>
				<DeprecatedProjectIndicatorJS project={project} disableLink noWidthLimit={false} />
				{harvestProject && isHarvestUser ? <HarvestIcon /> : <div />}
			</IconTextIcon>
		) : (
			<IconText
				cy={`option-${name}`}
				key={id}
				value={id}
				text={name}
				searchString={`P${companyProjectId} ${customProjectId} ${name}`}
			>
				<DeprecatedProjectIndicatorJS project={project} disableLink noWidthLimit={false} />
			</IconText>
		);
	};

	const getGroups = () => {
		let groups = [];
		if (groupByStatus) {
			const groupedByStatus = groupProjectsByStatus();
			if (!groupedByStatus.PLANNING) groupedByStatus.PLANNING = [];
			if (!groupedByStatus.RUNNING) groupedByStatus.RUNNING = [];
			if (!groupedByStatus.OPPORTUNITY) groupedByStatus.OPPORTUNITY = [];
			if (!groupedByStatus.HALTED) groupedByStatus.HALTED = [];
			if (!groupedByStatus.DONE) groupedByStatus.DONE = [];

			const planningGroup = (
				<Dropdown.Group key={'PLANNING'} name={intl.formatMessage({id: 'project_status.planning'})}>
					{groupedByStatus.PLANNING
						? groupedByStatus.PLANNING.map(edge => {
								return getLine(edge.node);
						  })
						: []}
				</Dropdown.Group>
			);
			const runningGroup = (
				<Dropdown.Group key={'RUNNING'} name={intl.formatMessage({id: 'project_status.running'})}>
					{groupedByStatus.RUNNING
						? groupedByStatus.RUNNING.map(edge => {
								return getLine(edge.node);
						  })
						: []}
				</Dropdown.Group>
			);
			const opportunityGroup = (
				<Dropdown.Group key={'OPPORTUNITY'} name={intl.formatMessage({id: 'project_status.opportunity'})}>
					{groupedByStatus.OPPORTUNITY
						? groupedByStatus.OPPORTUNITY.map(edge => {
								return getLine(edge.node);
						  })
						: []}
				</Dropdown.Group>
			);
			const haltedGroup = (
				<Dropdown.Group key={'HALTED'} name={intl.formatMessage({id: 'project_status.halted'})}>
					{groupedByStatus.HALTED
						? groupedByStatus.HALTED.map(edge => {
								return getLine(edge.node);
						  })
						: []}
				</Dropdown.Group>
			);
			const doneGroup = (
				<Dropdown.Group key={'DONE'} name={intl.formatMessage({id: 'project_status.done'})}>
					{groupedByStatus.DONE
						? groupedByStatus.DONE.map(edge => {
								return getLine(edge.node);
						  })
						: []}
				</Dropdown.Group>
			);

			if (showPlanning) groups.push(planningGroup);
			if (showRunning) groups.push(runningGroup);
			if (showOpportunity) groups.push(opportunityGroup);
			if (showHalted) groups.push(haltedGroup);
			if (showDone) groups.push(doneGroup);
		} else {
			groups.push(
				<Dropdown.Group name={optionsName} key={'dropdown-options'}>
					{sortedProjects.map(edge => {
						return getLine(edge.node);
					})}
				</Dropdown.Group>
			);
		}

		return groups;
	};

	return (
		<Dropdown
			cy={cy}
			emphasizeEmptyState={emphasizeEmptyState}
			headerLines={headerLines}
			isNested
			isMultiSelect={isMultiSelect}
			isClearable={isClearable}
			onRemove={onRemove}
			onSelect={onSelect}
			selectedGroupName={selectedGroupName}
			selectedItems={selectedItems}
			width={width}
			maxHeight={maxHeight}
			dropdownAlignment={dropdownAlignment}
			name={name}
			userpilot={userpilot}
			disabled={disabled}
			label={label}
			required={required}
			size={size}
			usePortal={usePortal}
		>
			{getGroups()}
		</Dropdown>
	);
};

ProjectDropdown.propTypes = {
	projects: PropTypes.arrayOf(
		PropTypes.shape({
			node: PropTypes.shape({
				id: PropTypes.string.isRequired,
				name: PropTypes.string.isRequired,
				projectColor: PropTypes.string.isRequired,
			}),
		})
	).isRequired,
	dropDownAlignment: PropTypes.oneOf(['left', 'center', 'right']),
	width: PropTypes.number,
	name: PropTypes.string,
	optionsName: PropTypes.string,
	selectedItems: PropTypes.array,
	onSelect: PropTypes.func,
	onRemove: PropTypes.func,
	showRole: PropTypes.bool,
	selectedGroupName: PropTypes.string,
	showPlanning: PropTypes.bool,
	showRunning: PropTypes.bool,
	showOpportunity: PropTypes.bool,
	showDone: PropTypes.bool,
	showHalted: PropTypes.bool,
	headerLines: PropTypes.element,
};

ProjectDropdown.defaultProps = {
	showPlanning: true,
	showRunning: true,
	showOpportunity: true,
	showHalted: true,
	showDone: true,
	optionsName: 'Project',
	name: 'Project',
	selectedGroupName: 'Selected',
	showRole: false,
	onSelect: () => false,
	onRemove: () => false,
};

export default createFragmentContainer(ProjectDropdown, {
	projects: graphql`
		fragment ProjectDropdown_projects on ProjectTypeEdge @relay(plural: true) {
			node {
				id
				name
				projectColor
				companyProjectId
				customProjectId
				status
				...DeprecatedProjectIndicatorJS_project
				manualProgressOnProjectEnabled
				manualProgressOnPhasesEnabled
				manualProgressOnTasksEnabled
			}
		}
	`,
});
