import { DeprecatedCreativeModal as CreativeModal, FlexColumn, Text } from '@forecast-it/design-system';
import { useHistory } from 'react-router-dom';
import React from 'react';
export function ActionCompleteGoToLinkModal({ title, content, linkButtonText, link, closeButtonText, onStay, closeModal, dataCy, }) {
    const history = useHistory();
    const closeAndStay = () => {
        closeModal();
        if (onStay) {
            onStay();
        }
    };
    const closeAndGoToLink = () => {
        closeModal();
        history.push(link);
    };
    return (React.createElement(CreativeModal, { size: 'm', closeModal: closeAndStay, creativeButtonText: linkButtonText, cancelButtonText: closeButtonText, onCreateHandler: closeAndGoToLink, title: title, disabled: false, "data-cy": dataCy },
        React.createElement(FlexColumn, null,
            React.createElement(Text, null, content))));
}
