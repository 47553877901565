import React from 'react';

const smallCloseIcon = props => (
	<svg onClick={props.onClick} className={`small-close-icon ${props.class}`} width="12" height="12" xmlns="http://www.w3.org/2000/svg">
		<g stroke="#535353" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="square">
			<path className={`small-close-icon-path ${props.class}-path`} d="M2.308 9.692l7.384-7.384M2.308 2.308l7.384 7.384" />
		</g>
	</svg>
);

export default smallCloseIcon;
