import React, {useEffect, useRef, useState} from 'react';
import Styled from 'styled-components/macro';
import {useIntl} from 'react-intl';
import {CaretIcon} from 'web-components';

const ShowMoreWrapper = Styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;    
    max-height: ${props => (props.maxHeight ? props.maxHeight + 'px' : 'none')};
    overflow: hidden;
    position: relative;
`;

const ShowMoreFiller = Styled.div`
	width: ${props => props.width}px;
	height: ${props => props.height}px;
`;

const ShowMoreToggle = Styled.div`
	display: ${props => (props.show ? 'flex' : 'none')};
	position: absolute;
	right: 0;
	bottom: 0;
	color: #8E8F9F;
	font-size: 9px;
	font-weight: 500;
	text-transform: lowercase;
	cursor: ${props => (props.onClick ? 'pointer' : 'inherit')};
	height: ${props => props.height}px;
    line-height: ${props => props.height}px;
    span {
    	margin-right: 5px;
    }
`;

function elementsOverlap(domRect1, domRect2) {
	return !(
		domRect1.top >= domRect2.bottom ||
		domRect1.right <= domRect2.left ||
		domRect1.bottom <= domRect2.top ||
		domRect1.left >= domRect2.right
	);
}

export const ShowMore = ({minLines = 2, noExpand = false, children}) => {
	const {formatMessage} = useIntl();
	const wrapperRef = useRef();
	const itemRefs = useRef([]);
	const showMoreRef = useRef(null);
	const [showMore, setShowMore] = useState(false);
	const [expanded, setExpanded] = useState(false);
	const [hiddenElements, setHiddenElements] = useState(0);

	const [itemHeight, setItemHeight] = useState(0);
	const [showMoreWidth, setShowMoreWidth] = useState(0);

	useEffect(() => {
		setItemHeight(itemRefs.current?.[0]?.clientHeight);
	}, [itemRefs.current?.[0]?.clientHeight]);

	useEffect(() => {
		setShowMoreWidth(showMoreRef.current?.clientWidth);
	}, [showMoreRef.current?.clientWidth, expanded]);

	const maxCollapsedHeight = itemHeight * minLines;
	useEffect(() => {
		const showMoreRect = showMoreRef.current.getBoundingClientRect();
		let tempHiddenElements = 0;
		let hiddenByOverlap = 0;
		for (const itemElement of itemRefs.current) {
			if (!itemElement) {
				// Removed item
				continue;
			}
			const overlaps = showMore && elementsOverlap(showMoreRect, itemElement.getBoundingClientRect());
			itemElement.style.visibility = overlaps ? 'hidden' : '';
			if (overlaps || itemElement.offsetTop >= maxCollapsedHeight) {
				tempHiddenElements++;
				if (overlaps) {
					hiddenByOverlap++;
				}
			}
		}
		// Show ShowMore of elements are hidden because of overflow (and not just by overlapping ShowMore itself)
		setShowMore(tempHiddenElements > hiddenByOverlap);
		setHiddenElements(tempHiddenElements);
	}, [showMoreWidth, children, itemRefs, wrapperRef, showMore]);

	return (
		<ShowMoreWrapper ref={wrapperRef} maxHeight={expanded ? undefined : maxCollapsedHeight}>
			{React.Children.map(children, (child, index) => (
				<div ref={element => (itemRefs.current[index] = element)}>{child}</div>
			))}
			<ShowMoreFiller width={showMoreWidth} height={itemHeight} />

			<ShowMoreToggle
				data-cy="show-more-toggle"
				ref={showMoreRef}
				height={itemHeight}
				show={showMore}
				onClick={noExpand ? undefined : () => setExpanded(!expanded)}
			>
				{noExpand && <div>{formatMessage({id: 'common.plus-more'}, {count: hiddenElements})}</div>}
				{!noExpand && (
					<>
						<CaretIcon
							color="#8E8F9F"
							size={CaretIcon.SIZE.STANDARD}
							direction={expanded ? CaretIcon.DIRECTION.UP : CaretIcon.DIRECTION.DOWN}
						/>
						{formatMessage({id: expanded ? 'common.show-less' : 'common.show-more'})}
					</>
				)}
			</ShowMoreToggle>
		</ShowMoreWrapper>
	);
};
