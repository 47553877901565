import React, {useMemo} from 'react';
import {getQueryRenderer} from './TaskReportUtil';
import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import EmptyState from '../../../shared/components/empty-states/empty_state';
import {useGenericReportContext} from '../../saved-report/GenericReportContext';

const TaskReportTableV2 = ({enabledColumns, groupings, searchQuery, currency, viewer, customFieldDefinitions}) => {
	const totals = viewer.reportData.totals;
	const {reportId, sharedId} = useGenericReportContext();

	// Empty columns to be added to rows
	const customFieldColumnCount = useMemo(
		() => (customFieldDefinitions ? customFieldDefinitions.length : 0),
		[customFieldDefinitions]
	);

	const customFieldKeys = useMemo(
		() =>
			customFieldDefinitions
				? customFieldDefinitions
						.map(def => {
							const keyRxist = enabledColumns['company.reportCustomFieldDefinitions.' + def.key];
							return keyRxist !== undefined && keyRxist !== null ? def.key : '';
						})
						.filter(key => key !== '')
				: [],
		[enabledColumns, customFieldDefinitions]
	);

	const tableHeader = {
		totals,
		customFieldDefinitions,
	};

	/**
	 * Returns the empty state.
	 *
	 * if any filters are applied and no task are found,
	 * return that no result is found. If no filters
	 * are used, state that there aren't any tasks.
	 *
	 * @returns EmptyState element
	 */
	const getEmptyState = () => {
		if (searchQuery.filters.length > 0) {
			return <EmptyState pageName={EmptyState.EMPTY_STATE.NO_RESULT_FOUND} />;
		} else {
			return <EmptyState pageName={EmptyState.EMPTY_STATE.TASK_REPORT} />;
		}
	};

	// If no tasks are found - use empty state
	if (totals?.taskTotals?.entities === 0) {
		return getEmptyState();
	} else {
		return getQueryRenderer(
			tableHeader,
			groupings,
			searchQuery,
			0,
			enabledColumns,
			null,
			{},
			currency,
			reportId,
			sharedId,
			customFieldColumnCount,
			customFieldKeys
		);
	}
};

const TaskReportTableV2Query = graphql`
	query TaskReportTableV2_Query($searchQuery: TaskSearchQueryType, $reportId: String, $sharedId: String) {
		viewer {
			actualPersonId
			component(name: "task_report_table_v2")
			...TaskReportTableV2_viewer @arguments(searchQuery: $searchQuery, reportId: $reportId, sharedId: $sharedId)
		}
	}
`;
export {TaskReportTableV2Query};

export default injectIntl(
	withRouter(
		createFragmentContainer(TaskReportTableV2, {
			viewer: graphql`
				fragment TaskReportTableV2_viewer on Viewer
				@argumentDefinitions(
					searchQuery: {type: "TaskSearchQueryType"}
					reportId: {type: "String"}
					sharedId: {type: "String"}
				) {
					reportData(id: $reportId, sharedId: $sharedId) {
						totals(searchQuery: $searchQuery) {
							taskTotals {
								entities
								work {
									estimate {
										minutes
										points
									}
									remaining {
										minutes
										points
									}
								}
								reported {
									time
									points
									projected
								}
								financial {
									revenue {
										planned
										actual
									}
								}
							}
						}
					}
				}
			`,
		})
	)
);
