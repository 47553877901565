import React from 'react';
import {StandardMargin} from '../../../components/insights/invoicing/InvoicingPage.styled';
import {SubNavigation} from 'web-components';
import {useIntl} from 'react-intl';
import {useHistory, useLocation} from 'react-router-dom';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../shared/components/headers/top-header-bar/TopHeaderBar';
import Util from '../../shared/util/util';
import {createFragmentContainer, graphql} from 'react-relay';

export const getResourcesSubPages = () =>
	[
		Util.hasDepartments()
			? {
					value: '/admin/departments',
					label: 'common.departments',
			  }
			: null,
		{
			value: '/admin/teams',
			label: 'settings.teams',
		},
		{
			value: '/admin/people',
			label: 'common.people',
		},
		{
			value: '/admin/roles',
			label: 'settings.roles',
		},
		Util.hasSkills()
			? {
					value: '/admin/skills',
					label: 'settings.skills',
					feedback: 'https://experience.forecast.it/feedback-skills',
			  }
			: null,
	].filter(Boolean);

export const findResourceSubPage = pathname => {
	return getResourcesSubPages().find(subPage => pathname?.startsWith?.(subPage.value));
};

const SettingsResourcesSubNav = ({viewer}) => {
	const {formatMessage} = useIntl();
	const {pathname} = useLocation();
	const history = useHistory();
	const activeResourceSubPage = findResourceSubPage(pathname);
	const headerContent = [];

	const handleSetActivePage = value => {
		const newPage = getResourcesSubPages().some(option => option.value === value);
		if (activeResourceSubPage?.value !== value && newPage) {
			history.push(value);
		}
	};

	if (activeResourceSubPage?.feedback) {
		headerContent.push({
			type: TopHeaderBar.TYPE.FEEDBACK,
			link: activeResourceSubPage.feedback + (viewer.email ? '?email=' + encodeURIComponent(viewer.email) : ''),
		});
	}

	return (
		<>
			<TopHeaderBarWrapper>
				<TopHeaderBar title={formatMessage({id: 'settings.resources'})} content={headerContent} />
			</TopHeaderBarWrapper>
			<div style={{marginTop: '20px'}}>
				<StandardMargin data-cy={'resources-tabs'}>
					{getResourcesSubPages().map(option => (
						<SubNavigation.NavigationItem
							active={activeResourceSubPage?.value === option.value}
							onClick={() => handleSetActivePage(option.value)}
							key={option.value}
							cy={'settings-resources-tabs' + option.value.toLowerCase().replaceAll('/', '-')}
							userpilot={'settings-resources-tabs' + option.value.toLowerCase().replaceAll('/', '-')}
						>
							{formatMessage({id: option.label})}
						</SubNavigation.NavigationItem>
					))}
				</StandardMargin>
			</div>
		</>
	);
};

export default createFragmentContainer(SettingsResourcesSubNav, {
	viewer: graphql`
		fragment SettingsResourcesSubNav_viewer on Viewer {
			email
		}
	`,
});
