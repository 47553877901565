import React from 'react';

const ChevronRight = ({color}) => {
	return (
		<svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0.336879 7.66331C0.154634 7.48107 0.154634 7.18559 0.336879 7.00335L3.19881 4.14142C3.27691 4.06331 3.27691 3.93668 3.19881 3.85858L0.336879 0.996647C0.154634 0.814403 0.154634 0.518925 0.336879 0.33668C0.519123 0.154436 0.8146 0.154436 0.996845 0.33668L3.85877 3.19861C4.30137 3.6412 4.30137 4.35879 3.85877 4.80138L0.996845 7.66331C0.8146 7.84556 0.519123 7.84556 0.336879 7.66331Z"
				fill={color}
			/>
		</svg>
	);
};

export default ChevronRight;
