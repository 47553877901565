// @ts-check
import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {CrossIcon} from 'web-components';

const SlideoutContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	height: 100%;
`;

const CrossIconWrapper = styled.button`
	position: absolute;
	right: 0;
	border: none;
	border-radius: 4px;
	padding: 0;
	&:hover,
	&:focus {
		background-color: #e7e7f3;
	}
`;

const SlideoutContentWrapper = ({closeSlideout, children}) => {
	/** @type {React.MutableRefObject<HTMLButtonElement | null>} */
	const crossIconRef = useRef(null);

	useEffect(() => {
		if (crossIconRef.current) {
			crossIconRef.current.focus();
		}
	}, []);

	return (
		<SlideoutContainer>
			<CrossIconWrapper ref={crossIconRef} tabIndex={0} onClick={() => closeSlideout(true)}>
				<CrossIcon size={CrossIcon.SIZE.STANDARD} />
			</CrossIconWrapper>
			{children}
		</SlideoutContainer>
	);
};

export default SlideoutContentWrapper;
