import {MODAL_TYPE, showModal} from '../../shared/components/modals/generic_modal_conductor';
import React, {useEffect} from 'react';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';

const KeyboardShortcuts = ({actualPersonId}) => {
	const inEditMode = () =>
		(document.activeElement && document.activeElement.nodeName === 'INPUT') ||
		(document.activeElement &&
			document.activeElement.className &&
			document.activeElement.className.includes('DraftEditor'));

	const handleEventTracking = value => {
		trackEvent('Shortcut', 'Pressed', {value});
	};

	const handleShortcutApp = event => {
		if (!inEditMode()) {
			if (event.key === '=') {
				const modifierKeys = event.metaKey || event.ctrlKey || event.altKey;
				if (!modifierKeys) {
					handleEventTracking('time-reg');
					showModal({
						type: MODAL_TYPE.CREATE_TIME_REGISTRATION,
						personId: actualPersonId,
					});
				}
			}
		}
	};

	useEffect(() => {
		document.addEventListener('keydown', handleShortcutApp);

		return () => {
			document.removeEventListener('keydown', handleShortcutApp);
		};
	}, []);

	return <></>;
};

export default KeyboardShortcuts;
