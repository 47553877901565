import { MODULE_TYPES } from '../../../constants';
import Util from './util';
import CompanySetupUtil from './CompanySetupUtil';
const CACHE = new Set();
const SESSION_STORAGE_KEY = 'enabledModules';
export const setEnabledModules = (enabledModules) => {
    if (Array.isArray(enabledModules)) {
        sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(enabledModules));
        CACHE.clear();
        enabledModules.forEach(module => {
            CACHE.add(module.moduleType);
        });
    }
};
export const hasModule = (moduleType) => {
    if (!MODULE_TYPES[moduleType]) {
        throw Error(`Unknown module of type '${moduleType}'`);
    }
    if (CACHE.size === 0) {
        const enabledModulesJson = sessionStorage.getItem(SESSION_STORAGE_KEY);
        const enabledModules = enabledModulesJson ? JSON.parse(enabledModulesJson) : [];
        setEnabledModules(enabledModules);
    }
    return CACHE.has(moduleType);
};
export const getModuleAuthFunc = (moduleType) => {
    switch (moduleType) {
        case MODULE_TYPES.FINANCE:
            return () => CompanySetupUtil.hasFinance();
        case MODULE_TYPES.RETAINERS:
            return () => Util.shouldUseRetainers();
        case MODULE_TYPES.TIME_LOCKING:
            return () => Util.hasTimeLocking();
        case MODULE_TYPES.DEPARTMENTS:
            return () => Util.hasDepartments();
        case MODULE_TYPES.HUBSPOT:
            return () => Util.hasHubspot();
        default:
            return null;
    }
};
export const getModuleEndDate = (moduleType) => {
    if (!MODULE_TYPES[moduleType]) {
        throw Error(`Unknown module of type '${moduleType}'`);
    }
    const enabledModulesJson = sessionStorage.getItem(SESSION_STORAGE_KEY);
    const enabledModules = enabledModulesJson ? JSON.parse(enabledModulesJson) : [];
    if (!Array.isArray(enabledModules))
        return null;
    const module = enabledModules.find(enabledModule => enabledModule.moduleType === moduleType);
    if (!module)
        return null;
    return { endYear: module.endYear, endMonth: module.endMonth, endDay: module.endDay };
};
