import React from 'react';
import ReactDOM from 'react-dom';
import Root from './containers/root';
import * as Sentry from '@sentry/browser';
import 'draft-js/dist/Draft.css';
import {versionManager} from './version_manager';
import DirectApi from './directApi';

// eslint-disable-next-line no-console
console.log('using graphql server: ', DirectApi.graphqlServerEndpoint('graphql'));

if (process.env.SENTRY_CONFIG && process.env.SENTRY_CONFIG !== '') {
	Sentry.init({
		dsn: process.env.SENTRY_CONFIG,
		// https://docs.sentry.io/platforms/javascript/troubleshooting/
		tunnel: `${DirectApi.graphqlServerEndpoint('tunnel')}`,
		release: `web-front@${versionManager.getClientMajorVersion()}.${versionManager.getClientMinorVersion()}`,
		ignoreErrors: ["can't access dead object", 'Method webWidget:on.open does not exist'],
	});
}

/*if (config.environment === 'master' || config.environment === 'production') {
	datadogRum.init({
		applicationId: 'f3cbd69b-9b58-45ef-bb28-bace5233fcd5',
		clientToken: 'puba6e897fd8dbcf4afb5678f7c9e5afc66',
		site: 'datadoghq.eu',
		service: 'forecast-application',
		env: config.environment === 'master' ? 'test' : 'production',
		// Specify a version number to identify the deployed version of your application in Datadog
		version: `${versionManager.getClientMajorVersion()}.${versionManager.getClientMinorVersion()}`,
		sampleRate: 100,
		trackInteractions: true,
		allowedTracingOrigins: [config.environment === 'master' ? /https:\/\/.*\.wolwa\.com/ : /https:\/\/.*\.forecast\.it/],
	});
}*/

ReactDOM.render(<Root />, document.getElementById('app'));

if (process.env.NODE_ENV !== 'production') {
	module.hot.accept();
}
