import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import { DeprecatedText as Text } from '@forecast-it/design-system';
import { DeprecatedProjectIndicatorComponent } from '../../../project-indicator/DeprecatedProjectIndicator';
const ProjectRowWrapper = styled.div `
	display: flex;
	padding: 8px;
	border-bottom: 1px solid #a1a1a1;
	gap: 8px;
	align-items: center;
	width: 350px;
	max-width: 350px;
	overflow: hidden;
	&:last-child {
		border-bottom: none;
	}
`;
const OwnedProjectRow = (props) => {
    const { project } = props;
    return (React.createElement(ProjectRowWrapper, null,
        React.createElement(DeprecatedProjectIndicatorComponent, { projectColor: project.projectColor, companyProjectId: project.companyProjectId, customProjectId: project.customProjectId, projectName: project.name, noWrapper: true }),
        React.createElement(Text, null, project.name)));
};
export default createFragmentContainer(OwnedProjectRow, {
    project: graphql `
		fragment OwnedProjectRow_project on ProjectType {
			id
			name
			companyProjectId
			customProjectId
			projectColor
		}
	`,
});
