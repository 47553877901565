import React, { useCallback } from 'react';
import ContextMenu from '../../../../shared/components/context-menus/context_menu';
import { openCreateTaskModal, openTimeRegModal } from './CalendarEventModalLogic';
import { trackEvent } from '../../../../../tracking/amplitude/TrackingV2';
const EventContextMenu = ({ contextMenuItem, bounds, closeContextMenu, person, markEventAsConverted, }) => {
    const onRegisterTime = () => {
        trackEvent('Register Time Menu Item', 'Clicked');
        openTimeRegModal(person, contextMenuItem.calendarEvent, markEventAsConverted);
    };
    const onConvertToTask = () => {
        trackEvent('Convert To Task Menu Item', 'Clicked');
        openCreateTaskModal(person, contextMenuItem.calendarEvent, markEventAsConverted);
    };
    const closeContextMenuCallback = useCallback(() => {
        closeContextMenu();
    }, [contextMenuItem]);
    const options = [
        { text: 'Register time', onClick: onRegisterTime },
        { text: 'Convert to task', onClick: onConvertToTask },
    ];
    return (React.createElement(ContextMenu, { options: options, contextMenuPosition: { x: bounds.x, y: bounds.y }, closeContextMenu: () => closeContextMenuCallback(), newStyle: true }));
};
export default EventContextMenu;
