import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {createFragmentContainer, graphql} from 'react-relay';
import {Dropdown} from 'web-components';
import * as tracking from '../../../../tracking';
import {useForecastFetchQuery} from '../../hooks/useForecastFetchQuery';
import {useIntl, FormattedHTMLMessage} from 'react-intl';
import Util from '../../util/util';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';
import ForecastTooltip from '../tooltips/ForecastTooltip';
import {Icon, FlexRow} from '@forecast-it/design-system';

const suggestionsQuery = graphql`
	query RoleDropdownQuery($taskId: ID, $taskName: String, $projectId: ID) {
		viewer {
			component(name: "suggested_role_dropdown")
			company {
				availableMLModels {
					roleModel
				}
			}
			suggestedRoles(first: 100000, taskId: $taskId, taskName: $taskName, projectId: $projectId) {
				edges {
					node {
						id
						name
					}
				}
			}
		}
	}
`;

export const RoleDropdown = ({
	roles,
	activeDisabledRole,
	isClearable,
	dropdownAlignment,
	width,
	name,
	optionsName,
	selectedItems,
	onSelect,
	onRemove,
	selectedGroupName,
	userpilot,
	cy,
	emphasizeEmptyState,
	isMultiSelect,
	customPlaceholder,
	disabled,
	taskId,
	taskName,
	projectId,
	useSuggestions,
	usePortal,
	focusOnMount,
	headerLines,
}) => {
	const intl = useIntl();
	const {fetch, data} = useForecastFetchQuery(suggestionsQuery);

	const isFetching = useRef(false);
	const handleFetch = variables => {
		const onFetchSuccess = () => {
			isFetching.current = false;
		};

		if (!isFetching.current) {
			isFetching.current = true;
			fetch(variables, onFetchSuccess);
		}
	};

	const handleExpand = () => {
		if (taskId && useSuggestions) {
			handleFetch({taskId});
		} else if (taskName && projectId && useSuggestions) {
			const encodeTaskName = encodeURI(taskName);
			handleFetch({taskName: encodeTaskName, projectId});
		}
	};

	const sendMLFeedbackRole = roleIds => {
		const roleId = roleIds.length > 0 && roleIds[0];
		const suggestedRolesList = data?.viewer.suggestedRoles.edges;
		if (!roleId || !suggestedRolesList || suggestedRolesList.length === 0) return;

		const wasInTop5SuggestedRolesList = suggestedRolesList.slice(0, 5).some(sp => sp.node.id === roleId);
		const wasInTop3SuggestedRolesList = suggestedRolesList.slice(0, 3).some(sp => sp.node.id === roleId);
		const wasInTop1SuggestedRolesList = suggestedRolesList.length > 0 && suggestedRolesList[0].node.id === roleId;

		// Check if that role was among the suggested roles list
		const wasInSuggestedRolesList = suggestedRolesList.some(sp => sp.node.id === roleId);

		tracking.trackEvent('[ML - Suggested Roles]', {
			isSuggestedRole: wasInTop1SuggestedRolesList,
			wasInSuggestedRoleList: wasInSuggestedRolesList,
			wasInTop5SuggestedRoleList: wasInTop5SuggestedRolesList,
			wasInTop3SuggestedRoleList: wasInTop3SuggestedRolesList,
			wasInTop1SuggestedRoleList: wasInTop1SuggestedRolesList,
			taskId: taskId ? Util.getIdFromBase64String(taskId) : null,
			projectId: projectId ? Util.getIdFromBase64String(projectId) : null,
		});

		trackEvent('Suggested Role', 'Feedback Given', {
			isSuggestedRole: wasInTop1SuggestedRolesList,
			wasInSuggestedRoleList: wasInSuggestedRolesList,
			wasInTop5SuggestedRoleList: wasInTop5SuggestedRolesList,
			wasInTop3SuggestedRoleList: wasInTop3SuggestedRolesList,
			wasInTop1SuggestedRoleList: wasInTop1SuggestedRolesList,
			taskId: taskId ? Util.getIdFromBase64String(taskId) : null,
			projectId: projectId ? Util.getIdFromBase64String(projectId) : null,
		});
	};

	const handleSelect = roleIds => {
		if (useSuggestions) {
			sendMLFeedbackRole(roleIds);
		}

		onSelect(roleIds);
	};

	const sortedRoles = [...roles].sort((a, b) => {
		if (a.node.id === null) return -1;
		if (b.node.id === null) return 1;
		if (a.node.name.toLowerCase() < b.node.name.toLowerCase()) return -1;
		if (a.node.name.toLowerCase() > b.node.name.toLowerCase()) return 1;
		return 0;
	});
	if (activeDisabledRole) {
		sortedRoles.push(activeDisabledRole);
	}

	return (
		<FlexRow gap={'s'}>
			<Dropdown
				isClearable={isClearable}
				onExpand={handleExpand}
				isMultiSelect={isMultiSelect}
				headerLines={headerLines}
				isNested
				selectedGroupName={selectedGroupName}
				selectedItems={selectedItems}
				width={width}
				dropdownAlignment={dropdownAlignment}
				name={name}
				onSelect={handleSelect}
				onRemove={onRemove}
				userpilot={userpilot}
				cy={cy}
				emphasizeEmptyState={emphasizeEmptyState}
				customPlaceholder={customPlaceholder}
				disabled={disabled}
				usePortal={usePortal}
				focusOnMount={focusOnMount}
			>
				{!!data &&
					data.viewer &&
					data.viewer.company.availableMLModels.roleModel &&
					data.viewer.suggestedRoles.edges.length > 0 && (
						<Dropdown.Group name={intl.formatMessage({id: 'common.suggestions'})}>
							{data.viewer.suggestedRoles.edges.map(edge => (
								<Dropdown.SingleText key={edge.node.id} value={edge.node.id} searchString={edge.node.name}>
									{edge.node.name}
								</Dropdown.SingleText>
							))}
						</Dropdown.Group>
					)}
				<Dropdown.Group name={optionsName} key={'dropdown-options'}>
					{sortedRoles.map(edge => {
						return (
							<Dropdown.SingleText
								key={edge.node.id}
								value={edge.node.id}
								searchString={edge.node.name}
								disabled={activeDisabledRole && edge.node.id === activeDisabledRole.node.id}
							>
								{edge.node.name}
							</Dropdown.SingleText>
						);
					})}
				</Dropdown.Group>
			</Dropdown>
			{!!activeDisabledRole && (
				<ForecastTooltip content={<FormattedHTMLMessage id="project_person.deactivated_role" />}>
					<Icon icon="warning" size="m" color="error" />
				</ForecastTooltip>
			)}
		</FlexRow>
	);
};

RoleDropdown.propTypes = {
	roles: PropTypes.arrayOf(
		PropTypes.shape({
			node: PropTypes.shape({
				name: PropTypes.string.isRequired,
				id: PropTypes.string.isRequired,
			}),
		})
	).isRequired,
	dropDownAlignment: PropTypes.oneOf(['left', 'center', 'right']),
	width: PropTypes.number,
	name: PropTypes.string,
	optionsName: PropTypes.string,
	selectedItems: PropTypes.array,
	onSelect: PropTypes.func,
	onRemove: PropTypes.func,
	selectedGroupName: PropTypes.string,
	taskId: PropTypes.string,
	cy: PropTypes.string,
	useSuggestions: PropTypes.bool,
	headerLines: PropTypes.element,
};

RoleDropdown.defaultProps = {
	optionsName: 'Roles',
	name: 'Roles',
	selectedGroupName: 'Selected',
	onSelect: () => false,
	onRemove: () => false,
};

export default createFragmentContainer(RoleDropdown, {
	roles: graphql`
		fragment RoleDropdown_roles on RoleTypeEdge @relay(plural: true) {
			node {
				name
				id
			}
		}
	`,
});
