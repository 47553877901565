import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useSharedReportLoginContext} from './SharedReportLoginContext';
import {Avatar, ImagesWrapper} from '../../../shared/components/empty-states/empty_state_styled';
import Button from '../../../shared/components/buttons/button/button';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../../constants';
import enter_email_animation from '../../../../images/empty-states/empty02_retainer_anim.svg';
import enter_email_background from '../../../../images/empty-states/empty02_retainer_bg.svg';
import confirmation_animation from '../../../../images/empty-states/empty05_scoping2_anim.svg';
import confirmation_background from '../../../../images/empty-states/empty05_scoping2_bg.svg';
import Util from '../../../shared/util/util';
import SharedReportResetPasswordMutation from '../../../../mutations/shared_report_reset_password_mutation';
import Styled from 'styled-components/macro';
import {isSageSpecificUrl} from '../../../shared/util/UrlUtil';
import {WideButton} from '../../../../containers/sage-signup-flow/Sage.styled';
import {EMPHASIS, FlexColumn, Heading, Text} from '@forecast-it/design-system';

const TextContainer = Styled.div`
	padding-bottom: 30px;	
`;

function SharedReportResetPasswordForecast({sent, onClick, onSubmit}) {
	const {formatMessage} = useIntl();
	return (
		<>
			{sent ? (
				<>
					<h1 className="confirmation-text">{formatMessage({id: 'thank_you.header_top'})}</h1>
					<span className="confirmation-text">
						<FormattedMessage id="login.reset-password-instructions_no_email" />
					</span>
					<a onClick={onClick} data-cy="login-page-link" style={{cursor: 'pointer'}}>
						<FormattedMessage id="login.sign-in" />
					</a>
				</>
			) : (
				<form onSubmit={onSubmit}>
					<h1>{formatMessage({id: 'login.reset-password'})}</h1>
					<TextContainer>
						<FormattedMessage id="login.reset-password-shared-report" />
					</TextContainer>
					<div className="buttons">
						<a onClick={onClick} data-cy="back-to-login-page-link" style={{cursor: 'pointer'}}>
							<FormattedMessage id="login.back-to-sign-in" />
						</a>
						<Button
							id="reset-password-submit-button"
							text={formatMessage({id: 'login.reset-password'})}
							cy="button-reset-password-submit"
							buttonStyle={BUTTON_STYLE.FILLED}
							colorTheme={BUTTON_COLOR.PINK}
							onClick={onSubmit}
							uppercase={false}
							roundedBorders={true}
						/>
					</div>
				</form>
			)}

			<div className="animation-container forgot-password-animation">
				<ImagesWrapper
					size="small"
					position="absolute"
					right={sent ? 'initial' : '25%'}
					left={sent ? '7.5%' : 'initial'}
				>
					<Avatar size="small" image={sent ? confirmation_background : enter_email_background} />
					<Avatar size="small" image={sent ? confirmation_animation : enter_email_animation} />
				</ImagesWrapper>
			</div>
		</>
	);
}

function SharedReportResetPasswordSage({sent, onClick, onSubmit}) {
	const {formatMessage} = useIntl();
	return (
		<>
			{sent ? (
				<FlexColumn gap={'l'}>
					<Heading size="4">{formatMessage({id: 'login.forgot_password.check_your_email'})}</Heading>
					<Text>
						{formatMessage(
							{
								id: 'login.forgot_password.instructions_no_email',
							},
							{br: <br />}
						)}
					</Text>
					<WideButton emphasis={EMPHASIS.LOW} onClick={onClick} data-cy="login-page-link">
						<FormattedMessage id="login.forgot_password.return_to_sign_in" />
					</WideButton>
				</FlexColumn>
			) : (
				<FlexColumn gap={'l'}>
					<Heading size="4">{formatMessage({id: 'login.forgot_password.reset_your_password'})}</Heading>
					<Text>
						<FormattedMessage id="login.reset-password-shared-report" />
					</Text>
					<FlexColumn gap={'s'}>
						<WideButton onClick={onSubmit} data-cy="button-reset-password-submit">
							{formatMessage({id: 'login.reset-password'})}
						</WideButton>
						<WideButton emphasis={EMPHASIS.LOW} onClick={onClick} data-cy="back-to-login-page-link">
							{formatMessage({id: 'login.forgot_password.return_to_sign_in'})}
						</WideButton>
					</FlexColumn>
				</FlexColumn>
			)}
		</>
	);
}

const SharedReportResetPassword = () => {
	const [isSent, setIsSent] = useState(false);

	const {showLoginForm, sharedId} = useSharedReportLoginContext();

	const resetPassword = event => {
		if (event) {
			event.preventDefault();
		}

		const onSuccess = response => {
			if (response.sharedReportResetPassword.success) {
				setIsSent(true);
			}
		};

		Util.CommitMutation(
			SharedReportResetPasswordMutation,
			{
				sharedId,
			},
			onSuccess
		);
	};

	return isSageSpecificUrl() ? (
		<SharedReportResetPasswordSage sent={isSent} onClick={showLoginForm} onSubmit={resetPassword} />
	) : (
		<SharedReportResetPasswordForecast sent={isSent} onClick={showLoginForm} onSubmit={resetPassword} />
	);
};

export default SharedReportResetPassword;
