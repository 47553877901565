import {getBlockTag, getBlockStyle, getBlockInnerMarkup} from './block';

/**
 * Function to check if a block is of type list.
 */
export function isList(blockType) {
	return blockType === 'unordered-list-item' || blockType === 'ordered-list-item';
}

/**
 * Function will return html markup for a list block.
 */
export function getListMarkup(listBlocks, entityMap, hashtagConfig, directional, customEntityTransform) {
	return getListMarkupInner(listBlocks, entityMap, hashtagConfig, directional, customEntityTransform, 0).html;
}

function getListMarkupInner(listBlocks, entityMap, hashtagConfig, directional, customEntityTransform, unclosedLiTags) {
	const listHtml = [];
	let nestedListBlock = [];

	let previousBlock;
	listBlocks.forEach(block => {
		let nestedBlock = false;
		if (!previousBlock) {
			listHtml.push(`<${getBlockTag(block.type)}>\n`);
		} else if (previousBlock.type !== block.type && previousBlock.depth === block.depth) {
			if (unclosedLiTags > 0) {
				listHtml.push(`</li>`);
				unclosedLiTags -= 1;
			}
			listHtml.push(`</${getBlockTag(previousBlock.type)}>\n`);
			listHtml.push(`<${getBlockTag(block.type)}>\n`);
		} else if (previousBlock.depth === block.depth) {
			if (nestedListBlock && nestedListBlock.length > 0) {
				const subList = getListMarkupInner(
					nestedListBlock,
					entityMap,
					hashtagConfig,
					directional,
					customEntityTransform,
					unclosedLiTags
				);
				listHtml.push(subList.html);
				unclosedLiTags += subList.unclosedLiTags;
				nestedListBlock = [];
			}
		} else {
			nestedBlock = true;
			nestedListBlock.push(block);
		}
		if (!nestedBlock) {
			if (previousBlock?.type === block.type && previousBlock?.depth === block.depth && unclosedLiTags > 0) {
				listHtml.push(`</li>\n`);
				unclosedLiTags -= 1;
			}
			listHtml.push('<li');
			const blockStyle = getBlockStyle(block.data);
			if (blockStyle) {
				listHtml.push(` style="${blockStyle}"`);
			}
			if (directional) {
				listHtml.push(' dir = "auto"');
			}
			listHtml.push('>');
			listHtml.push(getBlockInnerMarkup(block, entityMap, hashtagConfig, customEntityTransform));
			/* We do not close li tag here.
			 * The next draft-js block might be a nested list so we defer the closing tag until:
			 * a) before starting a new li tag
			 * b) before closing a ul or ol tag */
			unclosedLiTags += 1;
			previousBlock = block;
		}
	});
	if (nestedListBlock && nestedListBlock.length > 0) {
		const subList = getListMarkupInner(
			nestedListBlock,
			entityMap,
			hashtagConfig,
			directional,
			customEntityTransform,
			unclosedLiTags
		);
		listHtml.push(subList.html);
		unclosedLiTags += subList.unclosedLiTags;
	}

	if (unclosedLiTags > 0) {
		listHtml.push(`</li>\n`);
		unclosedLiTags -= 1;
	}

	listHtml.push(`</${getBlockTag(previousBlock.type)}>\n`);

	return {html: listHtml.join(''), unclosedLiTags: unclosedLiTags};
}
