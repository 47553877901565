import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import {buildHeaderBar} from '../../shared/components/headers/header-bar/header_bar';
import Button from '../../shared/components/buttons/button/button';
import {BUTTON_COLOR, BUTTON_STYLE, ELEMENT_TYPE} from '../../../constants';
import CustomScrollDiv from '../../shared/components/scroll-bars/custom_scroll_div';
import Team from './settings_teams_team';
import {MODAL_TYPE, showModal} from '../../shared/components/modals/generic_modal_conductor';
import GenericEmptyState from '../../../components/empty-state/generic_empty_state_v2';
import InsightsEmptyState from '../../shared/components/empty-states/insights_empty_state';
import Util from '../../shared/util/util';
import SettingsResourcesSubNav from './SettingsResourcesSubNav';

class settingsTeams extends Component {
	constructor(props) {
		super(props);

		this.state = {
			searchFilterValue: '',
			preventClickInteraction: false,
		};
		this.onMouseDown = this.onMouseDown.bind(this);
		this.onMouseUp = this.onMouseUp.bind(this);
	}

	componentDidMount() {
		window.addEventListener('mousedown', this.onMouseDown);
		window.addEventListener('mouseup', this.onMouseUp);
	}

	componentWillUnmount() {
		window.removeEventListener('mousedown', this.onMouseDown);
		window.removeEventListener('mouseup', this.onMouseUp);
	}

	onMouseDown() {
		this.isMouseDown = true;
	}

	onMouseUp() {
		this.isMouseDown = false;
		//Delay execution so that we do not set it to false before click events from the same mouse up are executed
		requestAnimationFrame(() => {
			this.setState({preventClickInteraction: false});
		});
	}

	preventClickInteraction() {
		if (this.isMouseDown) {
			this.setState({preventClickInteraction: true});
		}
	}

	onSearchChange(searchFilterValue) {
		this.setState({searchFilterValue});
	}

	openCreateTeamModal() {
		showModal({
			type: MODAL_TYPE.ADD_TEAM,
			companyId: this.props.viewer.company.id,
			persons: this.props.viewer.company.allPersons.edges.filter(person => person.node.active),
			creatingTeam: true,
		});
	}

	getHeader() {
		const rightContent = [];

		rightContent.push({
			type: ELEMENT_TYPE.SEARCH,
			value: this.state.searchFilterValue || '',
			onChange: this.onSearchChange.bind(this),
		});

		rightContent.push(
			<Button
				key="create-team-button"
				text={this.props.intl.formatMessage({id: 'settings_team.add-team'})}
				buttonStyle={BUTTON_STYLE.OUTLINE}
				colorTheme={BUTTON_COLOR.PURPLE}
				onClick={this.openCreateTeamModal.bind(this)}
			/>
		);

		return buildHeaderBar([], rightContent);
	}

	getFilteredTeamData() {
		const {searchFilterValue} = this.state;
		const filteredTeamData = [];
		const teamEdges = this.props.viewer.company.teams.edges;
		for (const teamEdge of teamEdges) {
			const team = teamEdge.node;
			const teamPersons = team.teamPersons.edges.map(teamPersonEdge => teamPersonEdge.node.person);
			const filteredTeamPersons = teamPersons.filter(person => {
				const personSearchString = `${team.name}${person.email}${person.fullName}${
					person.role ? person.role.name : ''
				}`;
				return !searchFilterValue || Util.normalizedIncludes(personSearchString, searchFilterValue);
			});
			if (filteredTeamPersons.length || !searchFilterValue) {
				filteredTeamData.push({
					id: team.id,
					name: team.name,
					teamPersons: filteredTeamPersons,
					allTeamPersonIds: teamPersons.map(person => person.id),
				});
			}
		}
		return filteredTeamData;
	}

	render() {
		const {formatMessage} = this.props.intl;
		const {viewer} = this.props;
		const {preventClickInteraction, searchFilterValue} = this.state;
		const showEmptyState = !viewer.company.teams.edges.length;
		const filteredTeamData = this.getFilteredTeamData().sort((a, b) => Util.sortAlphabetically(a.name, b.name));
		return (
			<div className="settings-app settings-teams" data-cy={'settings-teams-page'}>
				<SettingsResourcesSubNav viewer={this.props.viewer} />
				{this.getHeader()}
				<div className={'settings-teams-content' + (showEmptyState || !filteredTeamData.length ? ' empty-state' : '')}>
					{showEmptyState ? (
						<GenericEmptyState
							header={formatMessage({id: 'settings_teams.empty_state_header'})}
							text={formatMessage({id: 'settings_teams.empty_state_text'})}
							callback={this.openCreateTeamModal.bind(this)}
							actionText={formatMessage({id: 'settings_team.add-team'})}
						/>
					) : filteredTeamData.length ? (
						<CustomScrollDiv>
							{filteredTeamData.map(team => (
								<Team
									key={team.id}
									companyId={viewer.company.id}
									team={team}
									allPersons={viewer.company.allPersons.edges.map(personEdge => personEdge.node)}
									preventClickInteraction={this.preventClickInteraction.bind(this)}
									isPreventClickInteractionSet={preventClickInteraction}
								/>
							))}
						</CustomScrollDiv>
					) : (
						<InsightsEmptyState infoMessageId={'scheduling.no-match'} searchText={searchFilterValue} />
					)}
				</div>
			</div>
		);
	}
}

settingsTeams.propTypes = {};

const settingsTeamsQuery = graphql`
	query settingsTeams_Query {
		viewer {
			actualPersonId
			component(name: "settings_teams")
			...settingsTeams_viewer
		}
	}
`;

graphql`
	fragment settingsTeams_team on Team {
		id
		name
		teamPersons(first: 10000) @connection(key: "Team_teamPersons") {
			edges {
				node {
					person {
						id
						...settingsTeams_person @relay(mask: false)
					}
				}
			}
		}
	}
`;

graphql`
	fragment settingsTeams_person on Person {
		id
		fullName
		firstName
		lastName
		email
		profilePictureId
		profilePictureDefaultId
		role {
			name
		}
		isViewer
	}
`;

export {settingsTeamsQuery};

export default injectIntl(
	createFragmentContainer(settingsTeams, {
		viewer: graphql`
			fragment settingsTeams_viewer on Viewer {
				...SettingsResourcesSubNav_viewer
				availableFeatureFlags {
					key
				}
				company {
					id
					teams(first: 10000) @connection(key: "Company_teams") {
						edges {
							node {
								...settingsTeams_team @relay(mask: false)
							}
						}
					}
					allPersons(first: 10000, excludeClientUsers: true) {
						edges {
							node {
								...settingsTeams_person @relay(mask: false)
								active
							}
						}
					}
				}
			}
		`,
	})
);
