import React from 'react';
import {DeprecatedDestructiveModal as DestructiveModal, DeprecatedText as Text} from '@forecast-it/design-system';
import Util from '../../../util/util';
import {createToast} from '../../toasts/toast';
import {useIntl} from 'react-intl';
import {trackEvent} from '../../../../../tracking/amplitude/TrackingV2';
import RemoveMemberFromProgramMutation from '../../../../../mutations/project-service/remove_member_from_program_mutation';
import ProgramUtil from '../../../util/ProgramUtil';

const RemoveMemberFromProgramModal = ({closeModal, programId, memberId}) => {
	const {formatMessage} = useIntl();

	const content = (
		<>
			<Text>
				{formatMessage(
					{id: 'modal.description.delete.remove_entity'},
					{entity: formatMessage({id: 'common.member_lowercase'})}
				)}
			</Text>
			<Text>
				{Util.upperCaseFirst(
					formatMessage(
						{id: 'modal.description.delete.remove_program_member'},
						{program: ProgramUtil.programText(formatMessage)}
					)
				)}
			</Text>
		</>
	);

	const removeMemberFromProgram = () =>
		Util.CommitMutation(RemoveMemberFromProgramMutation, {programId, memberIds: [memberId]}, () => {
			trackEvent('Member', 'Removed From Program');
			createToast({
				duration: 5000,
				message: Util.upperCaseFirst(
					formatMessage({id: 'program.member.remove'}, {program: ProgramUtil.programText(formatMessage)})
				),
			});
			closeModal();
		});

	return (
		<DestructiveModal
			closeModal={closeModal}
			onDeleteHandler={removeMemberFromProgram}
			destructiveButtonText={formatMessage({id: 'common.remove'})}
			title={formatMessage({id: 'modal.title.delete'})}
		>
			{content}
		</DestructiveModal>
	);
};

export default RemoveMemberFromProgramModal;
