import { isAfter } from 'date-fns';
import { hasTimeLocking, hasTimeApproval } from '../../FeatureUtil';
function getCompanyLockDate({ companyLockYear, companyLockMonth, companyLockDay, personExcludedFromCompanyLock = false, }) {
    if (hasTimeLocking() && !personExcludedFromCompanyLock && companyLockYear && companyLockMonth && companyLockDay) {
        return new Date(companyLockYear, companyLockMonth - 1, companyLockDay);
    }
    return null;
}
function getPersonLockDate({ personLockYear, personLockMonth, personLockDay }) {
    if (personLockYear && personLockMonth && personLockDay) {
        return new Date(personLockYear, personLockMonth - 1, personLockDay);
    }
}
export function getLockDate({ companyLockYear, companyLockMonth, companyLockDay, personLockYear, personLockMonth, personLockDay, personExcludedFromCompanyLock, }) {
    if (hasTimeLocking()) {
        const companyLockDate = getCompanyLockDate({
            companyLockYear,
            companyLockMonth,
            companyLockDay,
            personExcludedFromCompanyLock,
        });
        if (hasTimeApproval()) {
            const personLockDate = getPersonLockDate({ personLockYear, personLockMonth, personLockDay });
            if (!personLockDate && !companyLockDate)
                return null;
            if (!personLockDate || !companyLockDate)
                return personLockDate || companyLockDate;
            return isAfter(personLockDate, companyLockDate) ? personLockDate : companyLockDate;
        }
        return companyLockDate;
    }
    return null;
}
