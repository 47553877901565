import styled from 'styled-components';
import {CSS_CONSTANTS} from '../../../../../../css_variables';
import plusAddWhite from '../../../../../../images/v2/components/overview_time/plus-add-white.svg';

export const NewTaskWrapper = styled.div`
	margin-left: 8px;
	display: flex;
	align-items: center;
	padding-right: 8px;
	font-size: 13px;
	color: ${CSS_CONSTANTS.v2_branding_purple};
	cursor: pointer;
	&:hover {
		color: ${CSS_CONSTANTS.color_purple_hover_active};
	}
`;

export const QuickAddTaskWrapper = styled.div`
	display: flex;
	margin-left: ${props => (props.subtaskStyle ? '32px' : '8px')};
	flex-direction: row;
	height: 30px;
	align-self: center;
	width: ${props => (props.subtaskStyle ? '50%' : '60%')};
	font-size: 13px;
	color: ${props => props.disabled && CSS_CONSTANTS.v2_text_gray};
	&.white {
		background-color: white;
	}
	.task-name-input-wrapper {
		height: 100%;
		max-width: 500px;
		min-width: 250px;
		flex-grow: 1;
		margin-right: 8px;
		.task-name-input {
			font-size: 13px;
			width: 100%;
			height: 100%;
			border: 1px solid #e6e6e6;
			background-color: ${props => (props.disabled ? CSS_CONSTANTS.v2_input_disabled_grey : '#fff')};
			border-radius: 4px;
			padding-left: 16px;
			&:hover {
				border-color: ${props =>
					props.disabled ? CSS_CONSTANTS.app_border_color : CSS_CONSTANTS.v2_button_very_light_grey_hovered};
			}
			&:focus {
				border-color: ${CSS_CONSTANTS.v2_branding_purple};
				outline: none;
			}
			&::placeholder {
				font-size: 13px;
			}
		}
	}
	.assigned-dropdown-wrapper {
		flex-grow: 0;
		min-width: 180px;
		max-width: 180px;
		margin-right: 8px;
	}
	.hours-input {
		margin-right: 8px;
		max-width: 90px;
		min-width: 90px;
	}
	.hours {
		margin-right: 8px;
		max-width: 60px;
		min-width: 60px;
		.input-hours,
		.input-hours-DAN,
		.input-points {
			&:before {
				right: 10px;
				top: 0px;
			}

			input {
				height: 100%;
				border-radius: 4px;
				padding-right: 18px;
			}
		}
	}
	.input-add-button {
		height: 100%; //28px
		width: 28px;
		background-size: 14px;
		background: ${CSS_CONSTANTS.v2_create_button_green} url(${plusAddWhite}) no-repeat center;
		&.lightgrey {
			background-color: ${CSS_CONSTANTS.v2_grey};
			&:hover {
				opacity: 1;
				cursor: default;
			}
		}
		&.green {
			background-color: ${CSS_CONSTANTS.v2_create_button_green};
			&:hover {
				background-color: ${CSS_CONSTANTS.v2_create_button_green_active};
			}
		}
	}
	.forecast.right {
		text-align: right;
	}
	.approved {
		text-align: center;
		padding-right: 0;
	}
	.wrong {
		input {
			border-radius: 3px;
			border: 1px solid red;
		}
	}
	.project-dropdown-container {
		.project-dropdown-button {
			height: 28px;
		}
	}
`;
