import React from 'react';

const CapacityModalEmptyStateGraphic = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="207" height="101">
		<g fill="none" fillRule="evenodd">
			<path
				d="M27.508 1.527v78.912M48.244 1.527v78.912M68.98 1.527v78.912M89.716 1.527v78.912m20.736-78.912v78.912m20.737-78.912v78.912m20.736-78.912v78.912"
				stroke="#A1A1A1"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				opacity=".2"
			/>
			<g fillRule="nonzero">
				<path fill="#62D87B" d="M22.626 20.539V7.866L11.655 1.527.678 7.866v12.673l10.977 6.332z" opacity=".5" />
				<path
					fill="#3A1499"
					d="M9.722 6.013c-3.317 1.608-5.784 4.176-6.34 10.262-.555 6.088 1.58 8.295 1.58 13.271 0 4.977 5.431 6.794 9.413 4.429 3.974-2.366-.238-12.37-.238-12.37 2.524-.202 5.445-3.267 5.445-9.614 0-6.347-9.809-10.004-9.86-5.98z"
				/>
				<path fill="#A7C4E6" d="M15.665 20.69c1.054-.836 2.179-.62 3.102 0s4.241 3.123 5.301 3.405c-.454 1.594-1.608 3.151-3.923 2.798-2.316-.346-4.48-6.203-4.48-6.203z" />
				<path
					fill="#D1E6F4"
					d="M10.855 21.332c-1.774 1.277-4.299 3.455-4.155 6.023.152 2.567 3.022 7.695 3.318 9.361.303 1.66-.707 1.861-.151 2.67.555.807 3.418 2.365 7.645.655 4.227-1.709 2.265-3.772 2.063-5.084-.202-1.306 1.01-2.316 1.71-3.022.706-.707 1.427-3.217-.354-5.186-2.402-2.669-3.686-10.018-10.076-5.417z"
				/>
				<path
					fill="#EBFBFF"
					d="M15.154 23.475c-.217 1.225-.715 2.329-1.017 2.632-1.681-1.039-4.725-2.618-4.364-4.263.216-1.002 1.039-1.781 1.479-1.998.432-.209 3.902 3.628 3.902 3.628zm.295-.138c.794.527 1.255.786 1.789 1.262.007-2.077-.541-4.392-2.77-5.3-2.214-.902.981 4.038.981 4.038z"
				/>
				<path fill="#FFAB81" d="M11.028 18.043c.1 1.356.05 2.82-.353 3.873 1.204 1.609 3.173 1.76 4.479 1.558 1.305-.202-.253-3.707-.657-5.236-.396-1.522-3.469-.195-3.469-.195z" />
				<path
					fill="#DB8765"
					d="M15.11 20.135c-.23-.721-.483-1.407-.606-1.897-.396-1.522-3.469-.202-3.469-.202.036.476.043.96.029 1.435 1.19.938 2.784 1.68 4.046.664zm2.135-9.34c1.082.252 1.68.908 1.68 1.608 0 .707-.98 1.818-1.355 1.86-.375.044-.325-3.468-.325-3.468z"
				/>
				<path fill="#FFAB81" d="M8.287 16.038c.902 1.515 2.287 2.755 5.107 2.755 2.012 0 5.503-1.875 4.695-6.578-.808-4.695-4.19-4.442-6.174-4.089-1.652.296-4.291 2.351-4.291 4.493-2.15.404-2.229 4.09.663 3.42z" />
				<path fill="#FFF" d="M11.028 14.567l6.188-1.118c.455 3.058-4.875 4.998-6.188 1.118z" />
			</g>
			<g fillRule="nonzero">
				<path fill="#62D87B" d="M22.626 47.319V34.646l-10.971-6.333L.678 34.646v12.673l10.977 6.332z" opacity=".5" />
				<path
					fill="#EDF7FF"
					d="M7.408 69.713c-.512-2.034 2.308-4.55 1.27-6.022-5.079-7.199-3.615-13.942-1.32-15.125 2.286-1.19 9.505-1.767 11.107-1.19 7.5 2.712 2.51 22.33 2.51 22.33-1.825 1.868-5.936 3.303-8.857 2.842-2.611-.411-4.71-2.835-4.71-2.835z"
				/>
				<path
					fill="#E1EAF2"
					d="M8.684 63.698c1.039 1.471-1.781 3.988-1.269 6.022 0 0 1.349 1.558 3.231 2.388-1.954-3.03 2.791-8.172.224-13.495-.433-.894-1.955-7.248-.289-8.489 0 0-1.175-1.11-2.142-1.97a7.25 7.25 0 00-1.067.412c-2.301 1.19-3.765 7.934 1.312 15.132z"
				/>
				<path fill="#72F03E" d="M14.75 50.946c-.627 1.032 1.233 2.734 2.092 2.633.858-.101.216-2.077-.202-3.354-.325-.995-1.421.159-1.66.728" />
				<path fill="#000" d="M8.216 48.062c-.101.338-.05.886.266 1.391.318.505 1.753 2.618 7.062 3.671-.166-1.031.26-2.178.26-2.178l-4.732-1.01-2.856-1.875z" opacity=".2" />
				<path fill="#000" d="M15.803 50.946s1.09.52 1.572 1.169c.13-1.169.094-4.075-1.831-5.208-1.926-1.125.26 4.04.26 4.04z" opacity=".2" />
				<path
					fill="#E1EAF2"
					d="M9.434 47.188c-1.247.354-1.255.989-1.348 1.356.685 1.861 3.83 3.47 6.895 4.025.195-.555.822-1.623.822-1.623l-4.63-3.383-1.739-.375zm6.369 3.758a5.376 5.376 0 011.356.419c.029-1.356-.1-4.818-2.43-4.754-2.316.073 1.074 4.335 1.074 4.335z"
				/>
				<path fill="#302A25" d="M17.166 37.899c-2.315.05-5.33 7.717-6.383 8.244-.937.468-4.37-.087-5.77-3.888-1.125-3.072-.39-4.847 1.068-5.388 3.822-9.051 12.117-3.721 11.085 1.032z" />
				<path fill="#FFAB81" d="M14.736 44.707c0 2.481 1.341 3.708 1.067 6.24-2.885-.608-6.375-1.747-6.375-3.76 0-1.536.736-3.151.736-3.151l4.573.67z" />
				<path fill="#DB8765" d="M15.551 48.386c-.325-1.147-.815-2.186-.815-3.679l-4.566-.67s-.433.966-.635 2.106c.275.822 3.73 2.445 6.016 2.243zm1.861-7.371c1.557-.758 1.91-3.621-.253-3.124-2.156.513.253 3.124.253 3.124z" />
				<path fill="#FFAB81" d="M7.653 43.734c.96 1.255 4.01 3.793 7.898 1.457 2.971-1.782 2.567-5.431 1.608-7.3-.96-1.86-4.176-3.923-7.248-2.517-3.13 1.436-3.224 3.967-3.318 5.05-2.013.006-.851 4.016 1.06 3.31z" />
				<path fill="#FFF" d="M16.272 41.015c.858 2.35-3.78 4.717-4.731 1.32 1.615-.65 3.317-1.14 4.731-1.32z" />
				<path fill="#302A25" d="M6.6 40.43c.23.916.433 1.169.685 1.27.253.1 1.01-1.789.505-3.866 1.608-.058 7.515.642 8.064-2.027.548-2.669-7.307-2.517-8.915 1.659-1.55 2.07-.339 2.964-.339 2.964z" />
				<path fill="#61564F" d="M15.176 34.912c.425.671-.05 1.883-1.962 1.883-2.02 0-2.676-1.659-4.76-1.103.98-1.66 4.919-3.628 6.722-.78z" />
				<path
					fill="#72F03E"
					d="M16.265 53.124c.678.96-.044 6.304-3.102 6.773-3.05.469-3.23-2.38-4.334-2.82-1.104-.433-3.291 1.275-3.657 2.415 1.276.736 3.253.505 3.253.505.836 1.176 2.95 2.647 5.265 1.875 2.315-.772 5.027-4.782 3.476-8.756-1.262-3.224-.901.007-.901.007z"
				/>
			</g>
			<g fillRule="nonzero">
				<path fill="#62D87B" d="M22.626 74.099V61.434l-10.971-6.34-10.977 6.34v12.665l10.977 6.34z" opacity=".5" />
				<path fill="#EDF7FF" d="M17.686 73.27c-2.741-1.248-8.482 2.712-11.1 4.176-1.558.873-2.028 3.412-2.028 6.708 0 3.765 4.349 9.809 4.349 13.408 0 3.195 12.009 2.82 12.276 0 .41-4.342 1.781-21.897-3.498-24.292z" />
				<path fill="#FF64AB" d="M16.921 79.415c.693 1.205 3.03 10.062 3.13 14.642l-.858 1.86c-.714-.67-1.673-1.76-1.673-1.76s-.498-10.724-1.702-14.05c-.008-.966 1.103-.692 1.103-.692z" />
				<path fill="#FF64AB" d="M17.152 77.886c.447.945.187 2.82-.527 2.972-.714.151-1.673-.758-1.976-1.717-.296-.952 2.503-1.255 2.503-1.255z" />
				<path fill="#000" d="M15.81 73.479c1.724 2.265 3.109 5.237 2.424 6.614-.303-.923-1.154-1.594-2.013-1.789-.353.505-1.24 1.37-1.254 3.448-1.429-.909-7.076-4.277-5.713-6.318.505-2.532 6.556-1.955 6.556-1.955z" opacity=".2" />
				<path
					fill="#E1EAF2"
					d="M16.171 78.124s-1.515.894-1.63 2.907c-1.99-1.212-5.359-3.542-5.482-4.984-.13-1.443.902-3.332 1.731-3.094.815.238 5.381 5.171 5.381 5.171zm0 0c1.356 0 2.445 1.4 2.445 1.4.05-2.273-1.24-5.987-3.484-6.643-1.536-.448-1.283 2.46-1.283 2.46s1.392 1.564 2.322 2.783z"
				/>
				<path fill="#70401F" d="M17.527 65.069c-2.315.05-6.405 6.917-7.227 7.761-.822.844-4.097-.555-5.301-3.88-1.205-3.325-.447-6.225 3.224-8.158 3.808-2.012 8.871.087 9.304 4.277z" />
				<path fill="#FFAB81" d="M15.104 71.878c0 2.481 1.341 3.707 1.067 6.239-2.885-.606-6.376-1.746-6.376-3.758 0-1.536.736-3.152.736-3.152l4.573.671z" />
				<path
					fill="#DB8765"
					d="M11.108 71.294l3.988.584c0 .678.101 1.262.246 1.803-1.205.447-3.044 1.594-3.715 2.936-1.089-.592-1.832-1.335-1.832-2.265 0-1.536.736-3.152.736-3.152h.007c.267.123.483.18.57.094zm6.671-3.102c1.558-.757 1.912-3.62-.252-3.123-2.164.505.252 3.123.252 3.123z"
				/>
				<path fill="#FFAB81" d="M8.021 70.912c.96 1.255 4.01 3.793 7.898 1.457 2.971-1.782 2.567-5.432 1.608-7.3-.96-1.86-4.176-3.923-7.249-2.517-3.13 1.436-3.224 3.967-3.317 5.05-2.013.007-.859 4.01 1.06 3.31z" />
				<path fill="#70401F" d="M15.104 62.783c-.945 2.229-6.023.678-6.427 3.145-.252 1.55.354 3.23-.483 3.772-.36-.144-1.01-1.262-1.226-2.092-.188-.714-1.306-3.996.606-4.825 1.904-.83 5.698-2.56 7.53 0z" />
				<path fill="#FFF" d="M16.474 68.301c.793 2.185-3.513 4.385-4.4 1.226 1.5-.606 3.087-1.068 4.4-1.226z" />
				<path fill="#A8673B" d="M14.101 62.307c-.21.67-3.224 1.457-5.157.317 1.731-2.372 5.655-1.918 5.157-.317z" />
			</g>
			<path fill="#FFE794" fillRule="nonzero" d="M74.75 21.714H33.64a4.34 4.34 0 01-4.328-4.327v-6.491a4.34 4.34 0 014.327-4.328h41.112a4.34 4.34 0 014.327 4.328v6.49a4.34 4.34 0 01-4.327 4.328z" />
			<path fill="#FFF" fillRule="nonzero" d="M34.613 11.992h11.865v4.299H34.613z" opacity=".5" />
			<path fill="#9270FE" fillRule="nonzero" d="M145.751 48.56h-91.6a4.34 4.34 0 01-4.327-4.328v-6.491a4.341 4.341 0 014.328-4.328h91.6a4.34 4.34 0 014.327 4.328v6.491a4.341 4.341 0 01-4.328 4.328z" />
			<path fill="#62D87B" fillRule="nonzero" d="M62.865 75.34H33.639a4.34 4.34 0 01-4.327-4.328v-6.491a4.34 4.34 0 014.327-4.328h29.226a4.34 4.34 0 014.327 4.328v6.491a4.34 4.34 0 01-4.327 4.328z" />
			<path fill="#FFC8F6" fillRule="nonzero" d="M96.23 75.34h49.21a4.34 4.34 0 004.328-4.328v-6.491a4.34 4.34 0 00-4.327-4.328H96.23a4.341 4.341 0 00-4.328 4.328v6.491a4.341 4.341 0 004.328 4.328z" />
			<path fill="#18BDBC" fillRule="nonzero" d="M125.239 21.772H95.667a4.34 4.34 0 01-4.327-4.327v-6.492a4.34 4.34 0 014.327-4.327h29.572a4.34 4.34 0 014.327 4.327v6.492a4.34 4.34 0 01-4.327 4.327z" />
			<path fill="#C8F0E7" fillRule="nonzero" d="M145.434 21.772h-3.066a4.34 4.34 0 01-4.327-4.327v-6.492a4.34 4.34 0 014.327-4.327h3.066a4.34 4.34 0 014.327 4.327v6.492a4.34 4.34 0 01-4.327 4.327z" />
			<g fillRule="nonzero">
				<path fill="#003299" d="M191.082 8.624c3.317 1.608 7.097 4.782 6.34 10.263-.866 6.318-6.672 5.792-9.795 12.052-2.222 4.458-11.62 4.559-12.42 0-1.161-6.62 6.008-8.914 6.008-16.329.007-6.333 5.085-10.372 9.867-5.986z" />
				<g fill="#FFAB81">
					<path d="M204.504 51.091c-.714.36-1.464 2.467-1.464 2.467.44 2.56-1.688 5.193-.592 5.575.794.281 2.489-.404 3.376-1.738.649-.981.591-4.465.043-6.037-.548-1.573-1.363-.267-1.363-.267z" />
					<path d="M204.237 51.315c-.844.49-3.05 1.275-3.527 1.124-.476-.152-1.046.995.18 1.269 1.234.274 2.972.014 2.972.014l.375-2.407z" />
				</g>
				<path fill="#FFAB81" d="M169.112 90.544c.109.764.188 2.495-.714 3.267-.901.772-3.375 1.103-3.743-.267-.368-1.37.938-2.777 1.738-4.479.8-1.71 2.72 1.479 2.72 1.479z" />
				<path fill="#DB8765" d="M166.386 89.058c.043-.087.087-.152.13-.21l.772-.23c.85.317 1.817 1.926 1.817 1.926.094.656.159 2.02-.39 2.885.08-2.69-1.983-1.45-2.856-3.383.188-.317.368-.642.527-.988z" />
				<path
					fill="#B488BC"
					d="M169.112 90.544c.253 1.089.18 4.068 0 4.738-.18.671-1.017.837-2.048 2.575-1.039 1.738-3.21 3.044-5.049 2.943-1.839-.101-2.676-.736-2.979-1.068-.303-.331-.173-1.464.433-1.875 2.907-1.976 4.082-6.657 5.41-6 1.269.62.88 1.485 2.033 1.557 1.147.065 2.51-.685 2.2-2.87z"
				/>
				<path
					fill="#D0DBEE"
					d="M169.112 95.282c-.18.671-1.017.837-2.048 2.575-1.039 1.738-3.21 3.044-5.049 2.943-1.839-.101-2.676-.736-2.979-1.068-.18-.201-.202-.685-.065-1.132.16.354.62.065 1.169.267.685.26.966 1.529 3.18 1.132 1.768-.324 3.412-2.409 4.083-3.476.505-.808 1.262-1.32 1.825-2.077-.036.375-.072.67-.116.836z"
				/>
				<path fill="#FFAB81" d="M171.002 70.298c.57.873 1.608 3.296.303 5.25-1.306 1.955-3.859 1.032-3.138-2.444.729-3.477 2.835-2.806 2.835-2.806z" />
				<path fill="#DB8765" d="M171.853 72.512c-1.06.484-2.416.671-3.613.325.779-3.152 2.762-2.532 2.762-2.532.289.426.685 1.248.851 2.207z" />
				<path fill="#B488BC" d="M171.471 70.868c.772.67 2.142 3.108 1.204 5.489-.937 2.373-.656 6.419-2.69 7.522-2.027 1.104-4.14.108-3.498-1.774 1.774-5.186-1.168-7.422 2.207-7.559 2.041-.08 2.993-2.264 2.777-3.678z" />
				<path
					fill="#B95741"
					d="M177.392 45.35c-.533-.137-.577.534-1.204.938-.628.403-9.99 5.488-11.504 7.313-1.515 1.825-1.832 2.539-1.161 5.265.67 2.72 3.966 11.504 4.233 14.36 1.428-.31 3.21-1.558 3.794-2.675-1.29-4.191.714-8.252-1.962-12.442 2.943-.49 11.324-3.303 13.51-4.724 2.185-1.428 3.223-3.506 1.925-5.078-1.298-1.58-7.63-2.957-7.63-2.957z"
				/>
				<path fill="#A23012" d="M178.554 50.586c.98 4.638 1.832 1.854 4.147.426l-.166 2.697c-2.676 1.428-9.571 3.73-12.557 4.328 5.734-2.222 8.215-4.559 8.576-7.45z" />
				<path
					fill="#00BBD8"
					d="M184.114 22.154c-2.127-.447-4.81-.122-7.19 1.515-1.522 1.046-7.646 4.104-7.646 4.37-2.099-.757-5.842-2.048-7.09-1.694.267.577-.137 1.514-.937 1.695 1.651 1.471 4.86 4.06 6.513 4.147 1.651.087 1.875-.714 4.37-1.781 2.496-1.068 8.114-3.124 9.853-3.275 1.738-.159 4.349-4.508 2.127-4.977z"
				/>
				<path
					fill="#009FC9"
					d="M162.174 27.225c1.868 1.536 5.315 4.003 6.967 1.666 0 0-.31.894 0 2.048.916-1.248 8.785-4.68 9.946-5.214 1.024-.476 1.645.302 1.674 1.644-2.38.599-6.564 2.157-8.627 3.037-2.495 1.067-2.719 1.875-4.37 1.781-1.652-.087-4.862-2.676-6.513-4.147.447-.094.771-.433.923-.815z"
				/>
				<path
					fill="#B95741"
					d="M187.692 47.398c2.2 0 3.476.18 3.476 3.347 0 3.166-1.695 6.643-6.022 11.64-3.78 4.365-7.703 7.264-8.439 12.666-.793 5.799-4.674 11.194-7.299 15.428-1.471.353-3.433.404-3.477-.938-.043-1.334 5.576-15.565 5.756-17.26.18-1.694 1.385-5.084 2.813-7.623 1.421-2.546 7.177-17.26 13.192-17.26z"
				/>
				<path fill="#A23012" d="M187.692 47.398c2.2 0 3.476.18 3.476 3.347 0 1.219-.252 2.488-.808 3.873-1.017-2.892-3.606-4.436-6.246-5.64 1.162-.981 2.366-1.58 3.578-1.58z" />
				<path
					fill="#00BBD8"
					d="M191.442 47.932c.03.844-.743 1.919-2.986 1.919-2.243 0-12.441-2.943-11.46-4.775.98-1.832 2.589-4.19 2.813-4.905-.267-1.385-.534-2.942-.49-3.88-1.119-.404-1.703-2.063-1.342-3.166.577-1.739 2.762-3.21 2.856-5.93.086-2.718.937-4.947 2.365-5.308 1.428-.353 6.78.844 8.518 2.23 1.739 1.384 3.39 6.065 1.652 9.808-1.738 3.744-2.813 6.023-2.813 8.208-.007 2.186.837 4.37.887 5.799z"
				/>
				<path
					fill="#009FC9"
					d="M186.487 40.929c-2.272-2.72-5.532-3.758-7.176-4.075-.007-.202-.015-.39-.007-.563.533 0 1.255-.483 2.538 0 2.265.844 4.328 3.476 6.643 2.185 2.323-1.29 1.291-3.476 1.962-5.799.454-1.565 2.286-1.868 3.62-1.694a8.036 8.036 0 01-.72 2.942c-1.739 3.744-2.813 6.023-2.813 8.208 0 2.186.843 4.37.894 5.799.029.844-.743 1.919-2.986 1.919-.245 0-.584-.037-.988-.101 2.055-.765 1.572-5.785-.967-8.821z"
				/>
				<path
					fill="#00BBD8"
					d="M192.438 24.902c1.81 1.06 3.78 3.369 5.74 6.802 1.963 3.433 4.105 5.618 5.266 7.804 1.161 2.185 2.409 9.679 2.856 11.864-.534.354-2.048.087-2.495 0-1.068-2.856-3.657-8.828-3.614-10.393-1.875-1.962-6.087-6.686-7.4-7.847-3.044-2.683-4.046-10.393-.353-8.23z"
				/>
				<path fill="#009FC9" d="M201.958 41.895c-.274-3.859-2.798-3.065-5.676-5.135 1.45 1.572 2.964 3.238 3.902 4.22-.043 1.557 2.539 7.536 3.613 10.392.116.022.289.058.505.087-.959-2.943-2.077-5.698-2.344-9.564z" />
				<path
					fill="#A23012"
					d="M186.199 61.138a98.58 98.58 0 01-1.046 1.248c-3.78 4.364-7.703 7.263-8.439 12.665-.793 5.799-4.673 11.194-7.299 15.428-.324.08-.67.137-1.017.18 9.19-10.624-1.147-15.687 17.8-29.52zm-16.611-3.036c2.676 4.19.671 8.25 1.962 12.441-.216.419-.613.859-1.089 1.263.13-2.453-3.577-9.802-1.875-13.776l1.002.072z"
				/>
				<path fill="#FFAB81" d="M189.776 20.654c-.1 1.356-.05 2.82.354 3.873-1.205 1.609-3.174 1.76-4.48 1.558-1.305-.202.253-3.707.657-5.236.397-1.522 3.47-.195 3.47-.195z" />
				<path
					fill="#DB8765"
					d="M185.694 22.753c.23-.721.483-1.406.606-1.897.396-1.522 3.469-.202 3.469-.202-.036.476-.043.96-.029 1.435-1.19.93-2.784 1.674-4.046.664zm-2.135-9.347c-1.082.252-1.68.908-1.68 1.608 0 .707.98 1.817 1.355 1.86.376.044.325-3.468.325-3.468z"
				/>
				<path fill="#FFAB81" d="M192.51 18.65c-.902 1.514-2.287 2.754-5.107 2.754-2.012 0-5.503-1.875-4.695-6.578.808-4.695 4.19-4.443 6.174-4.09 1.652.297 4.291 2.352 4.291 4.494 2.15.404 2.229 4.09-.663 3.42z" />
				<path fill="#003299" d="M192.229 12.915c-.88.765-1.66 1.226-3.253 1.169 1.082-1.082.793-1.623.793-1.623-1.789 1.63-7.898 1.63-6.6-1.248 1.241-2.734 8.67-1.767 9.052-.642.39 1.125.008 2.344.008 2.344z" />
				<path fill="#FFF" d="M188.976 17.315l-4.61-.837c-.33 2.28 3.636 3.722 4.61.837z" />
				<path
					fill="#FFAB81"
					d="M162.383 28.177c.837-.693.7-3.224.591-4.508-.108-1.284-.375-2.726-.995-2.77-.62-.043-.151 1.775-.584 3.268-.952-.686-2.654-2.784-3.022-3.369-.368-.591-1.637-.187-.96.801.491.837 1.018 1.724 1.018 1.724-.642.793-.686 2.849-.152 3.368.534.52 2.438 2.856 4.104 1.486z"
				/>
			</g>
			<path fill="#FFF" fillRule="nonzero" d="M54.238 38.837H87.74v4.299H54.238zm41.674-26.845h7.768v4.299h-7.768zm-62.02 53.625h19.762v4.299H33.892zm62.02 0h27.379v4.299H95.912z" opacity=".5" />
		</g>
	</svg>
);

export default CapacityModalEmptyStateGraphic;
