import {PERMISSION_TYPE} from '../../../Permissions';
import * as PERMISSION_GROUPS from '../../admin-tab/permissions-page/PermissionGroups';
import {HIDDEN_FEATURES, MODULE_TYPES, SEAT} from '../../../constants';
import {isFeatureHidden} from './CompanySetupUtil';
import {hasModule} from './ModuleUtil';

const PERMISSION_CACHE = new Set();
export const PERMISSIONS_SESSION_STORAGE_KEY = 'permissions';

const validatePermission = permission => {
	if (!PERMISSION_TYPE[permission]) {
		throw Error(`Unknown permission of type '${permission}'`);
	}
};

const validatePermissionsType = permissions => {
	if (!Array.isArray(permissions)) {
		throw Error(`Permissions should be an array of Permissions. Permissions was '${permissions}'`);
	}
};

const checkPermission = (permission, permissions) => {
	validatePermission(permission);
	validatePermissionsType(permissions);
	return permissions.some(perm => perm === permission);
};

export const setPermissions = permissions => {
	validatePermissionsType(permissions);
	sessionStorage.setItem(PERMISSIONS_SESSION_STORAGE_KEY, JSON.stringify(permissions));
	PERMISSION_CACHE.clear();
	permissions.forEach(permission => PERMISSION_CACHE.add(permission));
};

export const hasPermission = permission => {
	validatePermission(permission);

	if (PERMISSION_CACHE.size === 0) {
		const sessionPermissions = sessionStorage.getItem(PERMISSIONS_SESSION_STORAGE_KEY);
		const permissions = sessionPermissions ? JSON.parse(sessionPermissions) : [];
		setPermissions(permissions);
	}

	return PERMISSION_CACHE.has(permission);
};

export const hasSomePermission = permissions => {
	for (let i = 0; i < permissions.length; i++) {
		if (hasPermission(permissions[i])) {
			return true;
		}
	}
	return false;
};

export const reportPermissions = [
	PERMISSION_TYPE.INSIGHTS_CREATE,
	PERMISSION_TYPE.INSIGHTS_READ,
	PERMISSION_TYPE.INSIGHTS_UPDATE,
	PERMISSION_TYPE.INSIGHTS_DELETE,
];

export const showCost = () => {
	return hasModule(MODULE_TYPES.FINANCE) && hasPermission(PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION);
};

export const showRevenue = () => {
	return (
		!isFeatureHidden(HIDDEN_FEATURES.REVENUE) &&
		hasModule(MODULE_TYPES.FINANCE) &&
		hasSomePermission([PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION, PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE])
	);
};
export const canApproveAllocation = () =>
	!hasModule(MODULE_TYPES.PERMISSION_MANAGEMENT) ||
	!hasModule(MODULE_TYPES.ALLOCATION_APPROVE) ||
	hasPermission(PERMISSION_TYPE.ALLOCATION_APPROVE);

// Can be used to check permissions of both persons and profiles
export const personHasPermission = (permission, person) => {
	return checkPermission(permission, person.permissions);
};

export const isClientUser = () => {
	return hasPermission(PERMISSION_TYPE.IS_CLIENT_USER);
};

export const personIsClientUser = person => {
	return personHasPermission(PERMISSION_TYPE.IS_CLIENT_USER, person);
};

export const personIsVirtualUser = person => {
	return personHasPermission(PERMISSION_TYPE.IS_VIRTUAL_USER, person);
};

export const personIsCollaboratorUser = person => {
	return person.seat === SEAT.COLLABORATOR;
};

export const personHasContributerProfile = person => {
	if (!person.profiles?.edges?.length) {
		return false;
	}
	return person.profiles.edges.some(edge => atob(edge.node.id).includes('-4'));
};

export const personIsAdmin = person => {
	return personHasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS, person);
};

const sortAlphabetically = (a, b, exceptions) => {
	if (exceptions) {
		if (exceptions[a]) return 1;
		else if (exceptions[b]) return -1;
	}
	if (!a) return 1;
	if (!b) return -1;
	return a.localeCompare(b, undefined, {sensitivity: 'base'});
};

const filterBySeat = (profile, seat) => {
	if (!seat) {
		return true;
	}
	return profile.seat === seat;
};

export const getSelectableProfiles = (profiles, seat) => {
	return profiles
		.map(profile => profile.node)
		.filter(profile => !personIsClientUser(profile) && !profile.disabled)
		.filter(profile => filterBySeat(profile, seat))
		.sort((a, b) => sortAlphabetically(a.name, b.name));
};

export const getBasicUserTypeFromPermissions = () => {
	if (hasPermission(PERMISSION_TYPE.IS_CLIENT_USER)) return 'CLIENT';
	if (hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS)) return 'ADMIN';
	if (hasPermission(PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION)) return 'FULL';
	if (hasPermission(PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE)) return 'FULL_NO_COST';
	if (hasPermission(PERMISSION_TYPE.PROJECTS_CREATE)) return 'NO_FINANCIAL';

	return 'RESTRICTED';
};

export const getProfile = (profiles, profileId) => {
	return profiles.find(profile => profile.id === profileId);
};

export const getProfileName = (profiles, profileId) => {
	const profile = getProfile(profiles, profileId);
	return profile ? profile.name : '';
};

export const hasPermissionGroup = (permissionGroup, permissions) => {
	return permissionGroup.permissions.every(permission => permissions.includes(permission));
};

export const getDependingPermissions = permissionGroup => {
	return (permissionGroup.dependingPermissionGroups || []).flatMap(group => group.permissions);
};

export const getDefaultPermissions = permissionGroup => {
	return getDependingPermissions(permissionGroup).concat(
		PERMISSION_GROUPS.PERMISSION_GROUPS()
			.filter(group => group.dependingPermissionGroups?.includes(permissionGroup))
			.flatMap(group => group.permissions)
	);
};

export const dependencyChecked = (permissionGroup, permissions) => {
	switch (permissionGroup) {
		case PERMISSION_GROUPS.CREATE_PERSON:
			return false;
		case PERMISSION_GROUPS.ACCESS_ALL_PROJECTS():
			return false;
		case PERMISSION_GROUPS.CREATE_UPDATE_CLIENT:
			return hasPermissionGroup(PERMISSION_GROUPS.MANAGE_PROJECTS, permissions);
		case PERMISSION_GROUPS.SCHEDULING_ACCESS:
			return hasPermissionGroup(PERMISSION_GROUPS.MANAGE_ALLOCATION, permissions);
		case PERMISSION_GROUPS.SEE_MANAGE_INSIGHTS:
			return hasPermissionGroup(PERMISSION_GROUPS.MANAGE_ALLOCATION, permissions);
		case PERMISSION_GROUPS.SEE_MANAGE_INVOICES:
			return false;
		case PERMISSION_GROUPS.SEE_MANAGE_RETAINER_PERIOD:
			return false;
		case PERMISSION_GROUPS.MANAGE_ALLOCATION:
			return hasPermissionGroup(PERMISSION_GROUPS.MANAGE_PROJECTS, permissions);
		case PERMISSION_GROUPS.MANAGE_COMMENTS_ALL:
			return false;
		case PERMISSION_GROUPS.MANAGE_PHASES:
			return hasPermissionGroup(PERMISSION_GROUPS.MANAGE_PROJECTS, permissions);
		case PERMISSION_GROUPS.MANAGE_PROJECTS:
			return (
				hasPermissionGroup(PERMISSION_GROUPS.SEE_MANAGE_RETAINER_PERIOD, permissions) ||
				hasPermissionGroup(PERMISSION_GROUPS.MANAGE_PROGRAMS(), permissions) ||
				hasPermissionGroup(PERMISSION_GROUPS.PROJECT_SCHEDULING_SHARE_CREATE, permissions)
			);
		case PERMISSION_GROUPS.MANAGE_SPRINTS:
			return hasPermissionGroup(PERMISSION_GROUPS.MANAGE_PROJECTS, permissions);
		case PERMISSION_GROUPS.MANAGE_WORKFLOW_COLUMN:
			return hasPermissionGroup(PERMISSION_GROUPS.MANAGE_PROJECTS, permissions);
		case PERMISSION_GROUPS.MANAGE_TIME_OFF:
			return hasPermissionGroup(PERMISSION_GROUPS.MANAGE_TIME_REGISTRATIONS_ALL, permissions);
		case PERMISSION_GROUPS.MANAGE_TIME_REGISTRATIONS_ALL:
			return false;
		case PERMISSION_GROUPS.MANAGE_PROJECT_ASSIGNMENT:
			return hasPermissionGroup(PERMISSION_GROUPS.MANAGE_PROJECTS, permissions);
		case PERMISSION_GROUPS.VIEW_FINANCIAL_INFORMATION:
			return false;
		case PERMISSION_GROUPS.VIEW_FINANCIAL_INFORMATION_REVENUE:
			return (
				hasPermissionGroup(PERMISSION_GROUPS.SEE_MANAGE_RETAINER_PERIOD, permissions) ||
				hasPermissionGroup(PERMISSION_GROUPS.VIEW_FINANCIAL_INFORMATION, permissions)
			);
		case PERMISSION_GROUPS.VIEW_SISENSE_DASHBOARDS:
			return hasPermissionGroup(PERMISSION_GROUPS.DESIGN_SISENSE_DASHBOARDS, permissions);
		case PERMISSION_GROUPS.READ_ALL_PROJECTS:
			return hasPermissionGroup(PERMISSION_GROUPS.ACCESS_ALL_PROJECTS(), permissions);
		default:
			return false;
	}
};
