import {MODULE_TYPES, PROGRAM_BUDGET_TYPE, PROGRAM_MEMBER_ROLES} from '../../../constants';
import {hasModule} from './ModuleUtil';
import {hasPermission} from './PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';

export const hasTopDownProgramBudgetFeature = () => {
	return hasModule(MODULE_TYPES.PROGRAMS_PLUS);
};
/* programMembers need this structure:
				members {
					edges {
						node {
							role
							person {
								id
							}
						}
					}
				}	*/
export const canLoggedInPersonManageProgram = (programMembers, loggedInPersonId) => {
	if (!hasTopDownProgramBudgetFeature()) {
		return false;
	}
	if (hasPermission(PERMISSION_TYPE.PROGRAMS_MANAGE_ALL)) {
		return true;
	}
	if (!programMembers) {
		return false;
	}
	const programOwnersIds = programMembers.edges
		.filter(owner => owner.node && owner.node.role === PROGRAM_MEMBER_ROLES.OWNER)
		.map(owner => owner.node.person.id);

	return !!programOwnersIds && programOwnersIds.includes(loggedInPersonId);
};

/*
* Use this fragment on Project to get all required data
*
  	  isProgramRevenueLocked
  		programBudgetType
      programRemainingBudget
			program {
				name
				prefix
				members {
					edges {
						node {
							role
							person {
								id
							}
						}
					}
				}
			}
*
* We could expand this class if we need to add some general logic that processes this information - otherwise we should delete it*/
export class ProgramFinancialLogic {
	constructor(
		loggedInPersonId,
		programBudgetType,
		programRemainingBudget,
		isProgramRevenueLocked,
		programName,
		programPrefix,
		programMembers
	) {
		this._isInFixedPriceProgram = programBudgetType === PROGRAM_BUDGET_TYPE.FIXED_PRICE;
		this._isInCappedRevenueProgram = programBudgetType === PROGRAM_BUDGET_TYPE.CAPPED;
		this._canManageProgram = canLoggedInPersonManageProgram(programMembers, loggedInPersonId);
		this._programRemainingBudget = programRemainingBudget;
		this._programPrefix = programPrefix;
	}
}
