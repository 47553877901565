import { getTimeRegistrationSettings } from '../../../util/cache/TimeRegistrationSettingsUtil';
const prefix = 'You can not register time ';
const allowExceedingEstimate = "exceeding a task's estimate";
const allowOutsideTaskDates = "outside a task's dates";
const allowOutsideProjectDates = "outside a project's dates";
const allowOutsideTaskOrProjectDates = "outside a task or project's dates";
const requireNote = 'without adding a note';
function getMessageComponents(settings) {
    const messageComponents = [];
    if (!settings.allowExceedingEstimate) {
        messageComponents.push(allowExceedingEstimate);
    }
    if (!settings.allowOutsideTaskDates && !settings.allowOutsideProjectDates) {
        messageComponents.push(allowOutsideTaskOrProjectDates);
    }
    else if (!settings.allowOutsideTaskDates) {
        messageComponents.push(allowOutsideTaskDates);
    }
    else if (!settings.allowOutsideProjectDates) {
        messageComponents.push(allowOutsideProjectDates);
    }
    if (settings.requireNote) {
        messageComponents.push(requireNote);
    }
    return messageComponents;
}
function getFinalMessage(messageComponents) {
    if (messageComponents.length === 0) {
        return undefined;
    }
    else if (messageComponents.length === 1) {
        return prefix + messageComponents[0];
    }
    else {
        let finalMessage = prefix;
        const firstComponents = messageComponents.slice(0, -2);
        const lastTwoComponents = messageComponents.slice(-2);
        if (firstComponents.length > 0) {
            finalMessage += firstComponents.join(', ') + ', ';
        }
        finalMessage += lastTwoComponents.join(' or ');
        return finalMessage;
    }
}
export function getTimeRegistrationSettingsInfoMessage(settings) {
    const messageComponents = getMessageComponents(settings);
    return getFinalMessage(messageComponents);
}
export function showTimeRegistrationSettingsInfoMessage() {
    return getMessageComponents(getTimeRegistrationSettings()).length > 0;
}
