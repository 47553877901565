import {useEffect, useState} from 'react';
import {graphql} from 'react-relay';
import {fetchQuery} from 'relay-runtime';
import environment from '../../../../RelayEnvironment';
import {cloneDeep} from 'lodash';

export const useRecentPrograms = (fetch = false) => {
	const localStorageName = 'recent_programs';

	const maxRecent = 10;

	const sortByDate = (a, b) => new Date(b.date) - new Date(a.date);

	const readFromLocalStorage = () => {
		return localStorage.getItem(localStorageName) ? JSON.parse(localStorage.getItem(localStorageName)) : [];
	};

	const [recentProgramData, setRecentProgramData] = useState(readFromLocalStorage);
	const [recentPrograms, setRecentPrograms] = useState([]);

	const _storeToLocalStorage = (data = recentProgramData) => {
		localStorage.setItem(localStorageName, JSON.stringify(data));
	};

	const storeProgramData = data => {
		_storeToLocalStorage(data);
		setRecentProgramData(data);
		setTimeout(() => window.dispatchEvent(new Event('recent-program-storage')), 300);
	};

	const readAndSetData = () => {
		const data = readFromLocalStorage();
		setRecentProgramData(data);
	};

	useEffect(() => {
		window.addEventListener('recent-program-storage', readAndSetData);
		return () => window.removeEventListener('recent-program-storage', readAndSetData);
	}, []);

	const findProgramById = programId => {
		return recentProgramData.find(pd => pd.id === programId);
	};

	const programDeleted = programId => {
		const newArray = recentProgramData.filter(pd => pd.id !== programId);
		storeProgramData(newArray);
	};

	const programVisited = programId => {
		if (!findProgramById(programId)) {
			const arrLength = recentProgramData.length;
			let newArray = recentProgramData;
			newArray.unshift({
				date: new Date(),
				id: programId,
			});
			if (arrLength > maxRecent) {
				newArray = recentProgramData.slice(0, maxRecent);
			}
			storeProgramData(newArray);
		} else {
			const clonedData = cloneDeep(recentProgramData);
			clonedData.find(pd => pd.id === programId).date = new Date().toISOString();
			clonedData.sort(sortByDate);
			storeProgramData(clonedData);
		}
	};

	const refresh = () => {
		setTimeout(() => window.dispatchEvent(new Event('recent-program-storage')), 300);
	};

	const useRecentProgramsQuery = graphql`
		query useRecentProgramsQuery($programIds: [ID]) {
			viewer {
				component(name: "useRecentProgramsQuery")
				id
				backendId
				actualPersonId
				company {
					id
					programs(first: 10000, programIds: $programIds) @connection(key: "Recent_programs", filters: []) {
						edges {
							node {
								id
								name
								prefix
								color
							}
						}
					}
				}
			}
		}
	`;

	useEffect(() => {
		try {
			if (fetch && recentProgramData.length > 0) {
				fetchQuery(environment.getInstance(), useRecentProgramsQuery, {
					programIds: recentProgramData.map(p => p.id),
				})
					.then(data => {
						const programs = data.viewer.company.programs.edges.map(program => program.node);
						const sortedData = recentProgramData.slice().sort(sortByDate);
						const sortedPrograms = sortedData
							.map(pd => ({...programs.find(p => p.id === pd.id), date: pd.date}))
							.filter(p => p);
						setRecentPrograms(sortedPrograms);

						//Clean up not found programs
						if (sortedPrograms.length !== recentProgramData.length) {
							const newArray = recentProgramData.filter(pd => sortedPrograms.find(p => p.id === pd.id));
							storeProgramData(newArray);
						}
					})
					.catch(error => {
						console.error('Could not retrieve recent programs - clean recent programs in local storage', error);
						_storeToLocalStorage([]);
					});
			} else if (recentProgramData.length === 0) {
				setRecentPrograms([]);
			}
		} catch (error) {
			console.error('Could not retrieve recent programs - clean recent programs in local storage', error);
			_storeToLocalStorage([]);
		}
	}, [recentProgramData]);

	return {
		programs: recentPrograms,
		programVisited,
		programDeleted,
		refresh,
	};
};
