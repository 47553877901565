import React, {createContext, useContext, useState} from 'react';
import SharedReportLoginForm from './SharedReportLoginForm';
import SharedReportResetPassword from './SharedReportResetPassword';
import SharedReportChangePassword from './SharedReportChangePassword';

const SharedReportLoginContext = createContext();

export const useSharedReportLoginContext = () => {
	const context = useContext(SharedReportLoginContext);
	if (!context) throw new Error('useSharedReportLoginContext must be used within SharedReportLoginPageProvider!');
	return context;
};

export const SharedReportLoginContextProvider = ({sharedId, refetch}) => {
	const [loginComponent, setLoginComponent] = useState(<SharedReportLoginForm />);
	const [password, setPassword] = useState(null);

	const showForgotPassword = () => {
		setLoginComponent(<SharedReportResetPassword />);
	};

	const showLoginForm = () => {
		setLoginComponent(<SharedReportLoginForm />);
	};

	const showChangePassword = () => {
		setLoginComponent(<SharedReportChangePassword />);
	};

	const value = {
		showForgotPassword,
		showLoginForm,
		showChangePassword,
		sharedId,
		refetch,
		password,
		setPassword,
	};

	return (
		<SharedReportLoginContext.Provider value={value}>
			<div className="content-container">
				<div className="content">{loginComponent}</div>
			</div>
		</SharedReportLoginContext.Provider>
	);
};
