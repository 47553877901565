/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ProjectRoutes_viewer$ref = any;
export type ProjectRoutes_QueryVariables = {|
  projectId?: ?string,
  projectGroupId?: ?string,
|};
export type ProjectRoutes_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +project: ?{|
      +id: string,
      +fullAccessToProject: ?boolean,
      +readOnlyAccess: ?boolean,
    |},
    +projectGroup: ?{|
      +id: string,
      +readOnlyAccess: ?boolean,
    |},
    +$fragmentRefs: ProjectRoutes_viewer$ref,
  |}
|};
export type ProjectRoutes_Query = {|
  variables: ProjectRoutes_QueryVariables,
  response: ProjectRoutes_QueryResponse,
|};
*/


/*
query ProjectRoutes_Query(
  $projectId: String
  $projectGroupId: String
) {
  viewer {
    actualPersonId
    component(name: "project")
    project(id: $projectId) {
      id
      fullAccessToProject
      readOnlyAccess
    }
    projectGroup(id: $projectGroupId) {
      id
      readOnlyAccess
    }
    ...ProjectRoutes_viewer_28AH7q
    id
  }
}

fragment ProjectRoutes_viewer_28AH7q on Viewer {
  actualPersonId
  project(id: $projectId) {
    ...SecondaryNavigation_project
    id
  }
  projectGroup(id: $projectGroupId) {
    ...SecondaryNavigation_projectGroup
    id
  }
}

fragment SecondaryNavigation_project on ProjectType {
  companyProjectId
  customProjectId
  sprintTimeBox
  budgetType
  projectGroupId
  useBaseline
  hasInvoices
  financialSourceSettings {
    plannedCost
    plannedRevenue
    actualCost
    actualRevenue
    forecastCost
    forecastRevenue
    id
  }
}

fragment SecondaryNavigation_projectGroup on ProjectGroupType {
  companyProjectGroupId
  projectHeaderProjects: projects(first: 1) {
    edges {
      node {
        id
        sprintTimeBox
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectGroupId",
    "type": "String"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "project"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"project\")"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullAccessToProject",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "readOnlyAccess",
  "storageKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectGroupId"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sprintTimeBox",
  "storageKey": null
},
v9 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectRoutes_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "ProjectGroupType",
            "kind": "LinkedField",
            "name": "projectGroup",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": [
              {
                "kind": "Variable",
                "name": "projectGroupId",
                "variableName": "projectGroupId"
              },
              {
                "kind": "Variable",
                "name": "projectId",
                "variableName": "projectId"
              }
            ],
            "kind": "FragmentSpread",
            "name": "ProjectRoutes_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectRoutes_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "companyProjectId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "customProjectId",
                "storageKey": null
              },
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "budgetType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectGroupId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "useBaseline",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasInvoices",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "FinancialSourceSettings",
                "kind": "LinkedField",
                "name": "financialSourceSettings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "plannedCost",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "plannedRevenue",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "actualCost",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "actualRevenue",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "forecastCost",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "forecastRevenue",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "ProjectGroupType",
            "kind": "LinkedField",
            "name": "projectGroup",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "companyProjectGroupId",
                "storageKey": null
              },
              {
                "alias": "projectHeaderProjects",
                "args": (v9/*: any*/),
                "concreteType": "ProjectTypeConnection",
                "kind": "LinkedField",
                "name": "projects",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "projects(first:1)"
              },
              {
                "alias": "projectHeaderProjects",
                "args": (v9/*: any*/),
                "filters": [],
                "handle": "connection",
                "key": "ProjectGroup_projectHeaderProjects",
                "kind": "LinkedHandle",
                "name": "projects"
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "ProjectRoutes_Query",
    "operationKind": "query",
    "text": "query ProjectRoutes_Query(\n  $projectId: String\n  $projectGroupId: String\n) {\n  viewer {\n    actualPersonId\n    component(name: \"project\")\n    project(id: $projectId) {\n      id\n      fullAccessToProject\n      readOnlyAccess\n    }\n    projectGroup(id: $projectGroupId) {\n      id\n      readOnlyAccess\n    }\n    ...ProjectRoutes_viewer_28AH7q\n    id\n  }\n}\n\nfragment ProjectRoutes_viewer_28AH7q on Viewer {\n  actualPersonId\n  project(id: $projectId) {\n    ...SecondaryNavigation_project\n    id\n  }\n  projectGroup(id: $projectGroupId) {\n    ...SecondaryNavigation_projectGroup\n    id\n  }\n}\n\nfragment SecondaryNavigation_project on ProjectType {\n  companyProjectId\n  customProjectId\n  sprintTimeBox\n  budgetType\n  projectGroupId\n  useBaseline\n  hasInvoices\n  financialSourceSettings {\n    plannedCost\n    plannedRevenue\n    actualCost\n    actualRevenue\n    forecastCost\n    forecastRevenue\n    id\n  }\n}\n\nfragment SecondaryNavigation_projectGroup on ProjectGroupType {\n  companyProjectGroupId\n  projectHeaderProjects: projects(first: 1) {\n    edges {\n      node {\n        id\n        sprintTimeBox\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7ff391e072bff367088e72185b4078e1';

module.exports = node;
