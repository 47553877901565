import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';
import {NoteRequiredError} from '../add-time-entry/validation-info/NoteRequiredInfo';
import {isTimeRegistrationNoteInvalid} from '../../util/time-registration/time-registration-settings/TimeRegistrationNoteFilter';

class TextAreaFoldout extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isFocused: false,
			noteError: false,
		};
	}

	onFocus() {
		trackEvent('Note Text Field', 'Focused');
		if (!this.props.disabled) this.setState({isFocused: true});
	}

	onBlur(e) {
		const validateNote = !!this.props.timeRegId;
		let noteError = validateNote && isTimeRegistrationNoteInvalid(this.foldoutarea.value);
		const firstTimeErrorShown = this.state.noteError === false;
		if (!noteError) {
			if (this.props.onBlur) {
				this.props.onBlur(this.foldoutarea.value);
			}
			this.setState({isFocused: false});
		} else if (firstTimeErrorShown) {
			this.foldoutarea.focus();
		} else {
			// If the note is invalid, but the user has already seen the error, we just unfocus and keep the old note
			noteError = false;
			this.setState({isFocused: false, noteError: noteError});
		}

		this.setState({noteError: noteError});
	}

	onChange(e) {
		if (this.props.onChange) {
			this.props.onChange(e.target.value);
		}
	}

	render() {
		return (
			<div className="textarea-foldout">
				{this.props.hideLabel ? null : (
					<label id={'text-area-label'} className={'label'}>
						{this.props.label}
					</label>
				)}
				<input
					readOnly={this.props.disabled}
					onFocus={this.onFocus.bind(this)}
					value={this.props.value ? this.props.value : ''}
					ref={input => (this.textarea_input = input)}
					onChange={() => {}}
					placeholder={this.props.placeholder}
				/>
				{this.state.isFocused && (
					<div className="foldout">
						<textarea
							disabled={this.props.disabled}
							maxLength={this.props.maxChars || 999}
							defaultValue={this.props.value ? this.props.value : ''}
							rows={10}
							cols={this.props.numCols ? this.props.numCols : 40}
							onBlur={this.onBlur.bind(this)}
							autoFocus={true}
							onChange={this.onChange.bind(this)}
							ref={textarea => (this.foldoutarea = textarea)}
							placeholder={this.props.placeholder}
							onKeyDown={event => {
								if (this.props.saveOnEnter && !event.shiftKey && event.key === 'Enter') {
									this.onBlur();
								}
							}}
							onFocus={e => {
								if (this.props.selectOnClick) {
									e.target.select();
								}
							}}
						/>
						{this.state.noteError && <NoteRequiredError />}
					</div>
				)}
			</div>
		);
	}
}

TextAreaFoldout.propTypes = {
	value: PropTypes.string,
	maxChars: PropTypes.number,
	selectOnClick: PropTypes.bool,
	saveOnEnter: PropTypes.bool,
};
export default TextAreaFoldout;
