import {Table} from 'web-components';
import {
	totalsActual,
	totalsDifference,
	totalsEstimate,
	totalsPlanned,
	totalsProjected,
	totalsRemaining,
	totalsReported,
} from '../renders/TaskTotalsHeaders';
import React from 'react';
import {ESTIMATION_UNIT} from '../../../../../constants';

const GroupColumns = (row, intl, customFieldColumnCount) => {
	const columns = [
		<Table.Column>
			<span>{row && (row.generatedName || row.name)}</span>
		</Table.Column>,
		<Table.Column />,
		<Table.Column />,
		<Table.Column />,
		<Table.Column />,
		<Table.Column />,
		<Table.Column />,
		<Table.Column />,
		<Table.Column />,
		<Table.Column />,
		<Table.Column />,
		<Table.Column />,
		<Table.Column />,
		<Table.Column />,
		<Table.Column />,
		<Table.Column />,
		<Table.Column />,
		<Table.Column />,
		<Table.Column />,
		<Table.Column />,
		<Table.Column>
			{row.taskTotals?.work?.estimate && totalsEstimate(row.taskTotals, intl, ESTIMATION_UNIT.HOURS)}
		</Table.Column>,
		<Table.Column>
			{row.taskTotals?.work?.estimate && totalsEstimate(row.taskTotals, intl, ESTIMATION_UNIT.POINTS)}
		</Table.Column>,
		<Table.Column>{row.taskTotals?.reported?.projected && totalsProjected(row.taskTotals, intl)}</Table.Column>,
		<Table.Column>{row.taskTotals?.reported && totalsReported(row.taskTotals, intl)}</Table.Column>,
		<Table.Column>
			{row.taskTotals?.work?.remaining && totalsRemaining(row.taskTotals, intl, ESTIMATION_UNIT.HOURS)}
		</Table.Column>,
		<Table.Column>
			{row.taskTotals?.work?.remaining && totalsRemaining(row.taskTotals, intl, ESTIMATION_UNIT.POINTS)}
		</Table.Column>,
		<Table.Column>
			{row.taskTotals?.work?.estimate &&
				row.taskTotals?.reported &&
				totalsDifference(row.taskTotals, intl, ESTIMATION_UNIT.HOURS)}
		</Table.Column>,
		<Table.Column>
			{row.taskTotals?.work?.estimate &&
				row.taskTotals?.reported &&
				totalsDifference(row.taskTotals, intl, ESTIMATION_UNIT.POINTS)}
		</Table.Column>,
		<Table.Column>{row.taskTotals?.financial?.revenue && totalsPlanned(row.taskTotals, row.currency, intl)}</Table.Column>,
		<Table.Column>{row.taskTotals?.financial?.revenue && totalsActual(row.taskTotals, row.currency, intl)}</Table.Column>,
		<Table.Column />,
		<Table.Column />,
	];

	for (let i = 0; i < customFieldColumnCount; i++) {
		columns.push(<Table.Column />);
	}

	return columns;
};

export default GroupColumns;
