import React from 'react';
import {CalculationLoader, Table} from 'web-components';
import {GLOBAL_FILTER_FIELD, GLOBAL_FILTER_OPERATOR} from '../../../../../constants';
import Util from '../../../../shared/util/util';
import {getQueryRenderer} from '../TimeRegistrationReportPageUtil';
import {DeprecatedProjectIndicatorNoFragment} from '../../../../shared/components/project-indicator/DeprecatedProjectIndicator';

export const getProjectRow = (
	data,
	intl,
	groupIndex,
	searchQuery,
	groupings,
	enabledColumns,
	hasNextPage,
	fetchMore,
	nextLevelProps,
	currency,
	reportId,
	sharedId,
	isSharedView,
	customFieldColumnCount,
	customFieldKeys
) => {
	const getColumns = project => {
		const hasAccessToProject = project.noProject ? true : project.fullAccessToProject;

		const formattedPrice =
			project.totalPrice !== null
				? intl.formatNumber(project.timeRegTotals.totalPrice, {format: 'always_two_decimal'})
				: null;
		const formattedCost =
			project.totalCost !== null
				? intl.formatNumber(project.timeRegTotals.totalCost, {format: 'always_two_decimal'})
				: null;

		const projectName =
			project.noProject === true ? (
				<Table.Column>{intl.formatMessage({id: 'time_reg_report.no_project'})}</Table.Column>
			) : hasAccessToProject ? (
				<Table.Column>
					<DeprecatedProjectIndicatorNoFragment
						project={project}
						disableLink={isSharedView || project.readOnlyAccess}
						clearSubPath
						showProjectName
					/>
				</Table.Column>
			) : (
				<Table.Column>{intl.formatMessage({id: 'timesheets.no_access'})}</Table.Column>
			);

		const columns = [
			projectName,
			<Table.Column />,
			<Table.Column />,
			<Table.Column />,
			<Table.Column>{Util.convertMinutesToFullHour(project.timeRegTotals.totalTimeRegistered, intl, true)}</Table.Column>,
			<Table.Column>
				{Util.convertMinutesToFullHour(project.timeRegTotals.totalBillableRegistered, intl, true)}
			</Table.Column>,
			<Table.Column>
				{Util.convertMinutesToFullHour(project.timeRegTotals?.totalNonBillableRegistered, intl, true)}
			</Table.Column>,
			<Table.Column />,
			<Table.Column />,
			<Table.Column />,
			<Table.Column />,
			<Table.Column />,
			<Table.Column />,
			<Table.Column />,
			<Table.Column>
				<span>
					{hasAccessToProject ? (
						formattedPrice !== null ? (
							Util.GetFormattedCurrencySymbol(currency, formattedPrice)
						) : (
							<CalculationLoader />
						)
					) : (
						'-'
					)}
				</span>
			</Table.Column>,
			<Table.Column>
				<span>
					{hasAccessToProject ? (
						formattedCost !== null ? (
							Util.GetFormattedCurrencySymbol(currency, formattedCost)
						) : (
							<CalculationLoader />
						)
					) : (
						'-'
					)}
				</span>
			</Table.Column>,
			<Table.Column />,
			<Table.Column />,
		];

		for (let i = 0; i < customFieldColumnCount; i++) {
			columns.push(<Table.Column />);
		}

		return columns;
	};

	return (
		<Table.Rows
			data={data}
			canExpand={true}
			autoload={hasNextPage && groupIndex === 1}
			loadMore={hasNextPage ? fetchMore : false}
			{...nextLevelProps}
		>
			{({rowData, tableColumnsProps, nextLevelProps, expanded}) => {
				const filterField =
					rowData.noProject === true
						? GLOBAL_FILTER_FIELD.NON_PROJECT_TIME_REG
						: GLOBAL_FILTER_FIELD.PROJECT_TIME_REG;

				return (
					<>
						<Table.Row canExpandRow={rowData.canExpand} {...tableColumnsProps}>
							{getColumns(rowData)}
						</Table.Row>
						{expanded &&
							getQueryRenderer(
								null,
								groupings,
								searchQuery,
								groupIndex,
								enabledColumns,
								{field: filterField, operator: GLOBAL_FILTER_OPERATOR.IS, value: [rowData.projectId]},
								nextLevelProps,
								currency,
								reportId,
								sharedId,
								customFieldColumnCount,
								customFieldKeys
							)}
					</>
				);
			}}
		</Table.Rows>
	);
};
