/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
query TimesheetTimeTableWrapper_Query {
  viewer {
    actualPersonId
    component(name: "TimesheetTimeTableWrapper")
    ...TimesheetTimeTableWrapper_viewer
    id
  }
}

fragment TimesheetTimeTableWrapper_viewer on Viewer {
  id
  actualPersonId
  harvestUser
  unit4User
  company {
    id
    bambooHREnabled
    harvestEnabled
    unit4Enabled
    useTimeOffApproval
    roles(includeDisabled: false) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
}
*/
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actualPersonId",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": [
            {
                "kind": "Literal",
                "name": "name",
                "value": "TimesheetTimeTableWrapper"
            }
        ],
        "kind": "ScalarField",
        "name": "component",
        "storageKey": "component(name:\"TimesheetTimeTableWrapper\")"
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "TimesheetTimeTableWrapper_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        (v1 /*: any*/),
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "TimesheetTimeTableWrapper_viewer"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "TimesheetTimeTableWrapper_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "harvestUser",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "unit4User",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Company",
                            "kind": "LinkedField",
                            "name": "company",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "bambooHREnabled",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "harvestEnabled",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "unit4Enabled",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "useTimeOffApproval",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": [
                                        {
                                            "kind": "Literal",
                                            "name": "includeDisabled",
                                            "value": false
                                        }
                                    ],
                                    "concreteType": "RoleTypeConnection",
                                    "kind": "LinkedField",
                                    "name": "roles",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "RoleTypeEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Role",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v2 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "name",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": "roles(includeDisabled:false)"
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "TimesheetTimeTableWrapper_Query",
            "operationKind": "query",
            "text": "query TimesheetTimeTableWrapper_Query {\n  viewer {\n    actualPersonId\n    component(name: \"TimesheetTimeTableWrapper\")\n    ...TimesheetTimeTableWrapper_viewer\n    id\n  }\n}\n\nfragment TimesheetTimeTableWrapper_viewer on Viewer {\n  id\n  actualPersonId\n  harvestUser\n  unit4User\n  company {\n    id\n    bambooHREnabled\n    harvestEnabled\n    unit4Enabled\n    useTimeOffApproval\n    roles(includeDisabled: false) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = 'be2b056a06c15237ed7388af70cd09cd';
export default node;
