import {useIntl} from 'react-intl';
import {Dropdown} from 'web-components';
import React, {useEffect, useState} from 'react';
import Styled from 'styled-components';
import moment from 'moment';

const Wrapper = Styled.div`
	width: 200px;
`;

export const PRESET_DATE_RANGE_PICKER_OPTIONS = {
	ALL_TIME: 'AllTime',
	PREVIOUS_MONTH: 'PreviousMonth',
	CURRENT_MONTH: 'CurrentMonth',
	NEXT_MONTH: 'NextMonth',
	PREVIOUS_YEAR: 'PreviousYear',
	CURRENT_YEAR: 'CurrentYear',
	YEAR_TO_DATE: 'YearToDate',
	CUSTOM_RANGE: 'CustomRange',
};

const PresetDateRangePicker = ({handleDateRangeChange, initialPresetDateRange, options, userpilot, presetDateRange}) => {
	const intl = useIntl();

	const [selectedItems, setSelectedItems] = useState([PRESET_DATE_RANGE_PICKER_OPTIONS.CUSTOM_RANGE]);

	const dropdownOptions = [];

	const addOption = option => {
		const dropdownOption = {id: option, value: option};
		switch (option) {
			case PRESET_DATE_RANGE_PICKER_OPTIONS.ALL_TIME:
				dropdownOptions.push({...dropdownOption, name: intl.formatMessage({id: 'common.all_time'})});
				break;
			case PRESET_DATE_RANGE_PICKER_OPTIONS.PREVIOUS_MONTH:
				dropdownOptions.push({...dropdownOption, name: intl.formatMessage({id: 'common.previous_month'})});
				break;
			case PRESET_DATE_RANGE_PICKER_OPTIONS.CURRENT_MONTH:
				dropdownOptions.push({...dropdownOption, name: intl.formatMessage({id: 'common.current_month'})});
				break;
			case PRESET_DATE_RANGE_PICKER_OPTIONS.NEXT_MONTH:
				dropdownOptions.push({
					...dropdownOption,
					name: intl.formatMessage({id: 'date_span_picker.next_month'}),
				});
				break;
			case PRESET_DATE_RANGE_PICKER_OPTIONS.PREVIOUS_YEAR:
				dropdownOptions.push({...dropdownOption, name: intl.formatMessage({id: 'common.previous_year'})});
				break;
			case PRESET_DATE_RANGE_PICKER_OPTIONS.CURRENT_YEAR:
				dropdownOptions.push({...dropdownOption, name: intl.formatMessage({id: 'common.current_year'})});
				break;
			case PRESET_DATE_RANGE_PICKER_OPTIONS.YEAR_TO_DATE:
				dropdownOptions.push({...dropdownOption, name: intl.formatMessage({id: 'common.year_to_date'})});
				break;
			case PRESET_DATE_RANGE_PICKER_OPTIONS.CUSTOM_RANGE:
				dropdownOptions.push({...dropdownOption, name: intl.formatMessage({id: 'common.custom_range'})});
				break;
			default:
				break;
		}
	};

	if (options) {
		for (const option of options) {
			addOption(option);
		}
	}

	const calculateDateRange = value => {
		const selectedValue = value ? value[0] : null;
		let selectedDateRange = {option: selectedValue, startDate: null, endDate: null};
		switch (selectedValue) {
			case PRESET_DATE_RANGE_PICKER_OPTIONS.ALL_TIME:
				// No date restriction
				break;
			case PRESET_DATE_RANGE_PICKER_OPTIONS.PREVIOUS_MONTH:
				selectedDateRange.startDate = moment().local().subtract(1, 'month').startOf('month');
				selectedDateRange.endDate = moment().local().subtract(1, 'month').endOf('month');
				break;
			case PRESET_DATE_RANGE_PICKER_OPTIONS.CURRENT_MONTH:
				selectedDateRange.startDate = moment().local().startOf('month');
				selectedDateRange.endDate = moment().local().endOf('month');
				break;
			case PRESET_DATE_RANGE_PICKER_OPTIONS.NEXT_MONTH:
				selectedDateRange.startDate = moment().local().add(1, 'month').startOf('month');
				selectedDateRange.endDate = moment().local().add(1, 'month').endOf('month');
				break;
			case PRESET_DATE_RANGE_PICKER_OPTIONS.PREVIOUS_YEAR:
				selectedDateRange.startDate = moment().local().subtract(1, 'year').startOf('year');
				selectedDateRange.endDate = moment().local().subtract(1, 'year').endOf('year');
				break;
			case PRESET_DATE_RANGE_PICKER_OPTIONS.CURRENT_YEAR:
				selectedDateRange.startDate = moment().local().startOf('year');
				selectedDateRange.endDate = moment().local().endOf('year');
				break;
			case PRESET_DATE_RANGE_PICKER_OPTIONS.YEAR_TO_DATE:
				selectedDateRange.startDate = moment().local().startOf('year');
				selectedDateRange.endDate = moment().local().endOf('day');
				break;
			case PRESET_DATE_RANGE_PICKER_OPTIONS.CUSTOM_RANGE:
				selectedDateRange.startDate = moment().local().startOf('month');
				selectedDateRange.endDate = moment().local().endOf('month');
				break;
			default:
				selectedDateRange = null;
				break;
		}

		return selectedDateRange;
	};

	const handleSelect = value => {
		setSelectedItems(value);
		const selectedDateRange = calculateDateRange(value);
		handleDateRangeChange(selectedDateRange);
	};

	useEffect(() => {
		if (initialPresetDateRange) {
			handleSelect([initialPresetDateRange]);
		}
	}, []);

	useEffect(() => {
		if (presetDateRange) {
			handleSelect([presetDateRange]);
		}
	}, [presetDateRange]);

	return (
		<Wrapper data-cy={'date-range-dropdown'}>
			<Dropdown
				onSelect={handleSelect}
				selectedItems={selectedItems}
				width={200}
				dropdownAlignment={'left'}
				name={''}
				userpilot={userpilot}
			>
				{dropdownOptions.map(option => {
					return (
						<Dropdown.SingleText
							key={option.id}
							value={option.value}
							searchString={option.name}
							cy={'preset_date_range_picker-' + option.id}
						>
							{option.name}
						</Dropdown.SingleText>
					);
				})}
			</Dropdown>
		</Wrapper>
	);
};

export default PresetDateRangePicker;
