import React from 'react';

export const SageLogo = ({width = "60", height = "60"}) => {
	return (

	<svg width={width} height={height} style={{verticalAlign: "middle", marginTop: "2px"}} viewBox="0 0 115 64" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M13.4082 0C20.5156 0 26.2947 4.91529 26.4278 11.7564C26.4944 13.6823 25.0995 14.7458 23.5715 14.7458C22.1101 14.7458 20.8484 13.6838 20.7818 11.8896C20.7153 8.03629 17.4611 5.18002 13.3416 5.18002C9.48836 5.18002 6.56703 7.9032 6.56703 11.6233C6.56703 15.6747 9.2902 17.469 14.9362 19.528C20.98 21.7201 26.4944 24.3101 26.4944 32.0151C26.4944 38.7246 21.2463 44.1043 13.9407 44.1043C6.76674 44.1043 0.854492 39.0559 0.854492 32.2148C0.854492 27.9636 2.91349 24.9742 4.90593 24.9742C6.6336 24.9742 7.69562 26.1029 7.69562 27.6308C7.69562 28.9591 6.56703 29.5567 6.56703 31.9485C6.56703 36.1996 10.0209 38.9228 13.9407 38.9228C18.0587 38.9228 20.7818 36.0665 20.7818 32.6127C20.7818 28.3616 18.0587 26.5688 12.4127 24.5749C6.10258 22.3191 0.854492 19.5945 0.854492 12.0892C0.854492 5.31466 6.30079 0 13.4082 0Z" fill="#00D639"/>
		<path d="M42.3973 44.1048C35.2234 44.1048 29.2461 38.3257 29.2461 31.0186C29.2461 23.4467 35.29 17.8008 42.597 17.8008C50.435 17.8008 55.9478 23.6465 55.9478 31.6177V41.3151C55.9478 42.9762 54.6196 44.1048 53.0919 44.1048C51.4974 44.1048 50.1687 42.9762 50.1687 41.3151V31.8159C50.1687 26.5678 47.1797 22.9808 42.4639 22.9808C38.2793 22.9808 34.9586 26.5012 34.9586 31.0186C34.9586 35.2032 38.2128 38.7902 42.3323 38.7902C43.8602 38.7902 44.5244 38.3922 45.4548 38.3922C46.7166 38.3922 48.0448 39.4543 48.0448 40.9823C48.0435 43.0412 45.585 44.1048 42.3973 44.1048Z" fill="#00D639"/>
		<path d="M72.3143 63.8991C64.8744 63.8991 58.6973 58.5844 58.6973 51.6766C58.6973 49.3515 60.0255 48.2895 61.5536 48.2895C63.0813 48.2895 64.3432 49.2865 64.41 51.2122C64.4763 55.5299 67.9302 58.7175 72.2479 58.7175C76.8319 58.7175 79.8196 55.7948 79.8196 52.0079C79.8196 47.6902 76.4988 45.8976 70.8529 44.1033C64.2768 41.9777 58.6973 39.0549 58.6973 30.952C58.6973 23.5118 64.41 17.8008 71.8485 17.8008C79.3538 17.8008 85.5323 23.247 85.5323 30.421C85.5323 34.8052 83.7397 38.1926 81.2809 38.1926C79.6201 38.1926 78.4914 36.9974 78.4914 35.536C78.4914 34.008 79.8196 33.3439 79.8196 30.6207C79.8196 26.0368 76.2326 22.9823 71.9149 22.9823C67.7303 22.9823 64.41 26.1714 64.41 30.3559C64.41 35.0715 67.7971 37.0654 73.3767 38.9247C79.8196 41.0503 85.5323 43.7069 85.5323 51.4785C85.5323 58.4526 80.0195 63.8991 72.3143 63.8991Z" fill="#00D639"/>
		<path d="M101.835 44.1053C94.3945 44.1053 88.2842 38.2596 88.2842 30.954C88.2842 23.6469 94.1964 17.8027 101.502 17.8027C108.876 17.8027 114.455 22.9177 114.455 29.4275C114.455 31.951 112.662 33.4789 109.74 33.4789H102.101C100.573 33.4789 99.4442 32.4169 99.4442 31.0206C99.4442 29.6922 100.573 28.6288 102.101 28.6288H107.813C108.345 28.6288 108.744 28.3625 108.744 27.8315C108.744 25.9056 106.286 22.9162 101.703 22.9162C97.4521 22.9162 93.9983 26.5697 93.9983 30.954C93.9983 35.4048 97.5185 38.9252 101.903 38.9252C106.951 38.9252 108.612 35.9358 110.671 35.9358C112.399 35.9358 113.328 37.0644 113.328 38.3277C113.328 39.5228 112.531 40.5863 110.538 41.8481C108.543 43.1083 105.555 44.1053 101.835 44.1053Z" fill="#00D639"/>
	</svg>
	);
};