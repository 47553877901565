import React from 'react';
import Styled from 'styled-components';
import Util from '../../util/util';
import {CSS_CONSTANTS} from '../../../../css_variables';
import overAllocatedWarningIcon from '../../../../images/components/UtilizationGauge/OverAllocated.svg';
import RoleIcon from '../../../../images/components/UtilizationGauge/Role.svg';

/**
 * @param imageSource image id for the specific person
 * @param {String} fullName Name of person
 * @param {boolean} isRole Is utilization for a role
 * @param {minutesAvailable: number, minutesAllocated: number} utilization Utilization of person
 */
export default ({isRole, fullName, imageSource, utilization}) => {
	const {minutesAvailable, minutesRemaining} = utilization;
	const minutesAllocated = minutesAvailable - minutesRemaining;
	const initials = Util.getInitials(fullName);
	const isOverAllocated = minutesRemaining < 0;
	const displayPersonInitials = !imageSource && !isRole && !isOverAllocated;
	const utilizationPercentage = (minutesAllocated / minutesAvailable) * 100;
	const gaugeUtilization = isOverAllocated ? Math.min(utilizationPercentage - 100, 100) : utilizationPercentage;

	return (
		<Wrapper>
			<Background isOverAllocated={isOverAllocated} />
			{gaugeUtilization > 0 && (
				<Gauge
					utilization={gaugeUtilization}
					isOverAllocated={isOverAllocated}
					guageColor={isOverAllocated ? CSS_CONSTANTS.color_red_500 : CSS_CONSTANTS.color_green_300}
				/>
			)}
			<Avatar
				isRole={isRole}
				isOverAllocated={isOverAllocated}
				imageSource={isOverAllocated ? overAllocatedWarningIcon : isRole ? RoleIcon : imageSource}
			>
				{displayPersonInitials && <PersonGaugeInitials>{initials}</PersonGaugeInitials>}
			</Avatar>
		</Wrapper>
	);
};

const GAUGE_WIDTH = 4;
const AVATAR_SIZE = 32;
const GAUGE_AVATAR_TOTAL_SIZE = AVATAR_SIZE + GAUGE_WIDTH;

const Wrapper = Styled.div`
	position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Background = Styled.div`
	z-index: 1;
	background-color: ${props =>
		props.isOverAllocated ? CSS_CONSTANTS.schedule_v2_red : CSS_CONSTANTS.schedule_border_color_primary};
	border-radius: 50%;
	height: ${AVATAR_SIZE}px;
	width: ${AVATAR_SIZE}px; 
`;

const Avatar = Styled.div`
	z-index: 3;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	border-radius: 50%;
	height: ${AVATAR_SIZE - GAUGE_WIDTH * 2}px;
	width: ${AVATAR_SIZE - GAUGE_WIDTH * 2}px;
	
	background: ${props => (props.imageSource ? `white url(${props.imageSource}) no-repeat center` : CSS_CONSTANTS.brand500)};
	background-size: ${props => (props.isOverAllocated ? 16 : props.isRole ? 16 : GAUGE_AVATAR_TOTAL_SIZE)}px;
`;

const PersonGaugeInitials = Styled.span`
	z-index: 4;
	font-size: 9px;
	font-weight: 600;
	color: white;
	text-transform: uppercase;
`;

const Gauge = Styled.div`
	z-index: 2;
	position: absolute;
	width: ${AVATAR_SIZE}px;
	aspect-ratio: 1;
	display: inline-grid;
	place-content: center;
	
	&:before {
		content: "";
		position: absolute; 
		border-radius: 50%;
		inset: 0;

		background: conic-gradient(${props => props.guageColor} calc(${props => props.utilization} * 1%), #0000 0);
	}
	
	&:before {
		background:
			radial-gradient(farthest-side, ${props => props.guageColor} 98%, #0000) top/${GAUGE_WIDTH}px ${GAUGE_WIDTH}px no-repeat,
			conic-gradient(${props => props.guageColor} calc(${props => props.utilization} * 1%), #0000 0);
	}
	
	&:after {
		content: "";
		position: absolute;
		border-radius: 50%;
		inset: calc(50% - ${GAUGE_WIDTH}px / 2);
		background: ${props => props.guageColor};
		transform: rotate(calc(${props => props.utilization} * 3.6deg)) translateY(calc(50% - ${AVATAR_SIZE}px / 2));
	}
`;
