import UpdateAdvancedTimeRegistrationContent from '../../../my-work-tab/my-timesheets-page/bottom-section/timesheets-time-registration-table/advanced-time-registration-input/UpdateAdvancedTimeRegistrationContent';
import ForecastTooltip from '../tooltips/ForecastTooltip';
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { Icon, PopoverHandler } from '@forecast-it/design-system';
import { hasNoAccessToTimeRegProject } from '../../../../components/timesheets/team-timesheet/TimesheetsUtil';
import { useIntl } from 'react-intl';
function formatDayRegs(dayRegs) {
    return dayRegs.map(reg => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        return {
            id: reg.node.id,
            minutesRegistered: reg.node.minutesRegistered,
            billableMinutesRegistered: reg.node.billableMinutesRegistered,
            notes: reg.node.notes ? reg.node.notes : '',
            role: reg.node.role,
            noteInvalid: false,
            shouldUpdate: true,
            idleTimeId: ((_a = reg.node) === null || _a === void 0 ? void 0 : _a.idleTimeId) || ((_c = (_b = reg.node) === null || _b === void 0 ? void 0 : _b.idleTime) === null || _c === void 0 ? void 0 : _c.id),
            canHaveRole: !((_d = reg.node) === null || _d === void 0 ? void 0 : _d.idleTimeId) && !((_f = (_e = reg.node) === null || _e === void 0 ? void 0 : _e.idleTime) === null || _f === void 0 ? void 0 : _f.id),
            taskEstimateForecastMinutes: ((_g = reg === null || reg === void 0 ? void 0 : reg.node) === null || _g === void 0 ? void 0 : _g.task) ? reg.node.task.estimateForecastMinutes : null,
            taskTotalMinutesRegistered: ((_h = reg === null || reg === void 0 ? void 0 : reg.node) === null || _h === void 0 ? void 0 : _h.task) ? reg.node.task.totalMinutesRegistered : null,
            taskId: reg.node.task ? reg.node.task.id : undefined,
            billable: reg.node.billable,
            fullAccessToProject: !hasNoAccessToTimeRegProject(reg.node),
        };
    });
}
function getProjectIdFromTimeRegs(dayRegs) {
    var _a, _b, _c, _d, _e, _f, _g;
    return ((_c = (_b = (_a = dayRegs[0]) === null || _a === void 0 ? void 0 : _a.node) === null || _b === void 0 ? void 0 : _b.project) === null || _c === void 0 ? void 0 : _c.id) || ((_g = (_f = (_e = (_d = dayRegs[0]) === null || _d === void 0 ? void 0 : _d.node) === null || _e === void 0 ? void 0 : _e.task) === null || _f === void 0 ? void 0 : _f.project) === null || _g === void 0 ? void 0 : _g.id);
}
const EditTimeEntriesStyle = styled.div `
	margin: ${props => (props.showBillableTime ? '8px' : '19px')} 4px;
	z-index: 2;
`;
const ToggleEditButton = forwardRef(({ children, disabled }, ref) => {
    const { open, setOpen } = PopoverHandler.usePopoverState();
    const toggle = () => setOpen(!open);
    return (React.createElement("div", { onClick: disabled ? undefined : toggle, className: 'edit-button' + (open ? '-visible' : ''), ref: ref }, children));
});
const EditTimeEntries = ({ dayRegNodes, dayOfInput, infoText, disabled, mutation, showBillableTime, }) => {
    var _a, _b;
    const selectedPerson = (_b = (_a = dayRegNodes[0]) === null || _a === void 0 ? void 0 : _a.node) === null || _b === void 0 ? void 0 : _b.person;
    const intl = useIntl();
    if (!selectedPerson) {
        return null;
    }
    function mutationWrapper(hours, billableHours, notes, timeRegId, roleId, onSuccess) {
        mutation({
            hours,
            billableHours,
            dayRegs: dayRegNodes,
            selectedPerson,
            notes,
            timeRegId,
            roleId,
            currentViewingDate: dayOfInput,
            intl,
            onSuccess,
        });
    }
    return (React.createElement(EditTimeEntriesStyle, { showBillableTime: showBillableTime, onClick: e => e.stopPropagation() },
        React.createElement(PopoverHandler, null,
            React.createElement(PopoverHandler.Trigger, { asChild: true },
                React.createElement(ToggleEditButton, { disabled: disabled },
                    React.createElement(ForecastTooltip, { content: infoText, maxWidth: 250 },
                        React.createElement(Icon, { "data-cy": 'edit-time-reg-button', icon: 'edit', color: disabled ? 'disabled' : 'default' })))),
            React.createElement(PopoverHandler.Content, { showArrow: true },
                React.createElement(UpdateAdvancedTimeRegistrationContent, { timeRegistrationsOnEntityOnDay: formatDayRegs(dayRegNodes), mutation: mutationWrapper, selectedPerson: selectedPerson, dayOfInput: dayOfInput, projectId: getProjectIdFromTimeRegs(dayRegNodes), isCompanyTimesheet: true })))));
};
export default EditTimeEntries;
