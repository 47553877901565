import moment from 'moment';

export function getCantDeleteTaskMessage(intl, cantDeleteTaskReason) {
	switch (cantDeleteTaskReason) {
		case 'HAS_LOCKED_TIME':
			return intl.formatMessage({id: 'common.task_delete_locked'});
		case 'HAS_INVOICED_TIME':
			return intl.formatMessage({id: 'task.cant_delete_reason.invoice_time_regs'});
		case 'HAS_TIME_REGS':
			return intl.formatMessage({id: 'task.cant_delete_reason.time_regs'});
		case 'HAS_OTHER_PEOPLES_TIME_REGS':
			return intl.formatMessage({id: 'task.cant_delete_reason.other_peoples_time_regs'});
		case 'BLOCKED_BY_SUBTASK':
			return intl.formatMessage({id: 'task.cant_delete_reason.blocked_by_subtask'});
		case 'JIRA':
			return intl.formatMessage({id: 'task.cant_delete_reason.jira'});
		case 'GITHUB':
			return intl.formatMessage({id: 'task.cant_delete_reason.github'});
		case 'UNIT4':
			return intl.formatMessage({id: 'task.cant_delete_reason.unit4'});
		case 'NO_USER_TYPE':
		default:
			return intl.formatMessage({id: 'common.no_delete_rights'});
	}
}

export function getTaskRoleId(task) {
	return task.roleId ? task.roleId : task.role ? task.role.id : undefined;
}

export function getTaskPlannedDate(task) {
	let plannedDate;
	if (task.deadlineYear && task.deadlineMonth && task.deadlineDay) {
		plannedDate = moment.utc({y: task.deadlineYear, M: task.deadlineMonth - 1, d: task.deadlineDay});
	} else if (task.startYear && task.startMonth && task.startDay) {
		plannedDate = moment.utc({y: task.startYear, M: task.startMonth - 1, d: task.startDay});
	}
	return plannedDate;
}
