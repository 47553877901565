import Styled from 'styled-components/macro';
import {BasicTextInput, Button} from 'web-components';
import React from 'react';
import {useIntl} from 'react-intl';
import * as PropTypes from 'prop-types';

export const AddNewPriorityLevelStyle = Styled.div`
		margin-top: 20px;
    display: flex;
    justify-content: left;
	`;

const Spacer = Styled.div`
    width: 8px;
	`;

export function AddNewPriorityLevel(props) {
	const {formatMessage} = useIntl();

	return (
		<AddNewPriorityLevelStyle>
			<BasicTextInput
				type="text"
				value={props.value}
				cy={'skill-name'}
				id="skillName"
				label={''}
				width={'300px'}
				placeholder={formatMessage({id: 'settings.new-priority-level'})}
				onChange={props.onChange}
				callbackOnEnter={props.onClick}
				disabled={!props.canEnterNewPriorityLevel}
				maxLength={100}
			/>
			<Spacer />
			<Button
				cy={'add-new-priority-name-button'}
				onClick={props.onClick}
				size={Button.SIZE.STANDARD}
				disabled={!props.canAddPriorityLevel}
				variant={props.canAddPriorityLevel ? Button.VARIANT.GREEN_FILLED : Button.VARIANT.VERY_LIGHT_GRAY_FILLED}
				iconPosition={Button.ICON_POSITION.FIRST}
				icon={props.icon}
				isSquare={true}
			/>
		</AddNewPriorityLevelStyle>
	);
}

AddNewPriorityLevel.propTypes = {
	value: PropTypes.string,
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	canEnterNewPriorityLevel: PropTypes.bool,
	onClick: PropTypes.func,
	canAddPriorityLevel: PropTypes.bool,
	icon: PropTypes.func,
};
