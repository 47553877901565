import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import SuccessIcon from '../../../../images/v2/task-modal/harvest-check.svg';
import FailureIcon from '../../../../images/v2/task-modal/harvest-x.svg';
import LoadIcon from '../../../../images/v2/load.svg';
import {injectIntl} from 'react-intl';
import Clipboard from 'react-clipboard.js';
import {createToast} from '../../../shared/components/toasts/another-toast/toaster';
import {dispatch, EVENT_ID} from '../../../../containers/event_manager';
import {projectUrl} from '../../../../directApi';

class StatusTable extends Component {
	redirectToProject(companyProjectId, customProjectId) {
		dispatch(EVENT_ID.MULTIPLE_PROJECTS_ADDED);
		this.props.history.push(projectUrl(companyProjectId, customProjectId));
	}

	buildRow(data, key) {
		let statusIcon;

		switch (data.status) {
			case 'SUCCESS':
				let text = this.props.intl.formatMessage({id: 'settings.app_catalog.status_table.migration_successful'});
				statusIcon = <img src={SuccessIcon} alt={text} title={text} className="migration-success-icon" />;
				break;
			case 'MIGRATING':
				text = this.props.intl.formatMessage({id: 'settings.app_catalog.status_table.migration_in_progress'});
				statusIcon = <img src={LoadIcon} alt={text} title={text} className="loading-icon" />;
				break;
			default:
				text = this.props.intl.formatMessage({id: 'settings.app_catalog.status_table.migration_failed'}) + data.status;

				statusIcon = (
					<Clipboard
						data-clipboard-text={data.status}
						className="migration-failed-clipboard"
						onClick={() =>
							createToast({
								duration: 2000,
								message: this.props.intl.formatMessage({id: 'settings.app_catalog.status_table.copied_id'}),
							})
						}
					>
						<img src={FailureIcon} alt={text} title={text} />
					</Clipboard>
				);
				break;
		}

		return (
			<tr key={key}>
				<td
					className="project-column"
					onClick={() => this.redirectToProject(data.companyProjectId, data.customProjectId)}
				>
					{data.title}
				</td>
				<td>{data.startedAt.format('YYYY MMM D, HH:mm')}</td>
				<td>{data.status !== 'MIGRATING' ? data.finishedAt.format('YYYY MMM D, HH:mm') : null}</td>
				<td className="status-cell">{statusIcon}</td>
			</tr>
		);
	}

	render() {
		return (
			<table className="asana-import-status-table">
				<thead>
					<tr>
						<th>{this.props.intl.formatMessage({id: 'common.title'})}</th>
						<th>{this.props.intl.formatMessage({id: 'settings.app_catalog.asana.started_at'})}</th>
						<th>{this.props.intl.formatMessage({id: 'settings.app_catalog.asana.finished_at'})}</th>
						<th>{this.props.intl.formatMessage({id: 'common.status'})}</th>
					</tr>
				</thead>
				<tbody>
					{this.props.data
						.sort((d1, d2) => (d1.startedAt < d2.startedAt ? 1 : -1))
						.map((d, idx) => this.buildRow(d, idx))}
				</tbody>
			</table>
		);
	}
}

StatusTable.propTypes = {
	data: PropTypes.arrayOf(
		PropTypes.shape({
			status: PropTypes.string,
			startedAt: PropTypes.object,
			finishedAt: PropTypes.object,
			title: PropTypes.string,
		})
	),
};

export default injectIntl(withRouter(StatusTable));
