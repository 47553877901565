/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type appGoogleDrive_viewer$ref = any;
export type appGoogleDrive_QueryVariables = {||};
export type appGoogleDrive_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: appGoogleDrive_viewer$ref,
  |}
|};
export type appGoogleDrive_Query = {|
  variables: appGoogleDrive_QueryVariables,
  response: appGoogleDrive_QueryResponse,
|};
*/


/*
query appGoogleDrive_Query {
  viewer {
    actualPersonId
    component(name: "app_gdrive")
    ...appGoogleDrive_viewer
    id
  }
}

fragment appGoogleDrive_viewer on Viewer {
  id
  company {
    id
    gdriveEnabled
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "app_gdrive"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"app_gdrive\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "appGoogleDrive_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "appGoogleDrive_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "appGoogleDrive_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "gdriveEnabled",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "appGoogleDrive_Query",
    "operationKind": "query",
    "text": "query appGoogleDrive_Query {\n  viewer {\n    actualPersonId\n    component(name: \"app_gdrive\")\n    ...appGoogleDrive_viewer\n    id\n  }\n}\n\nfragment appGoogleDrive_viewer on Viewer {\n  id\n  company {\n    id\n    gdriveEnabled\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4cdba619c3dc5ae27b99f61c4d021f81';

module.exports = node;
