import React, {useCallback} from 'react';
import ContextMenu from '../../../shared/components/context-menus/context_menu';
import {createToast} from '../../../shared/components/toasts/toast';

const SearchResultContextMenu = ({contextMenuItem, bounds, closeContextMenu}) => {
	const openInNewTab = () => {
		if (contextMenuItem.onRowSelect) {
			contextMenuItem.onRowSelect();
		}

		window.open(contextMenuItem.link, '_blank');
	};

	const onCopyLink = () => {
		navigator.clipboard
			.writeText(window.location.origin + contextMenuItem.link)
			.then(() => createToast({duration: 3000, message: 'Link copied to clipboard'}));
	};

	const closeContextMenuCallback = useCallback(() => {
		closeContextMenu(contextMenuItem.index);
	}, [contextMenuItem.index]);

	const options = [
		{text: 'Open link in new tab', onClick: openInNewTab},
		{text: 'Copy link address', onClick: onCopyLink},
	];

	return (
		<ContextMenu
			options={options}
			contextMenuPosition={{x: bounds.x, y: bounds.y}}
			closeContextMenu={() => closeContextMenuCallback(contextMenuItem.index)}
			newStyle
		/>
	);
};

export default SearchResultContextMenu;
