import React from 'react';

const DependencyIcon = props => (
	<span title={props.title} data-cy={props.cy}>
		<svg className={props.class ? props.class : 'dependency-icon'} xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20">
			<path
				fill={props.color ? props.color : '#4A5968'}
				fillRule="evenodd"
				d="M14.385 1.414L12.97 2.828l-1.414-1.414L12.97 0l1.414 1.414zM7.313 5.656L3.778 9.192l1.414 1.414 3.536-3.535-1.415-1.415zm5.413 7.587l2.708 2.708-1.414 1.414-2.708-2.708L7.07 18.9a1.998 1.998 0 0 1-2.828 0L.584 15.244a2.003 2.003 0 0 1 0-2.828l1.794-1.795-.014-.015a1.986 1.986 0 0 1-.586-1.414c0-.534.208-1.036.586-1.414L5.9 4.242c.502-.5 1.247-.662 1.913-.499l1.622-1.622a1 1 0 0 1 1.414 0l1.415 1.414a.996.996 0 0 1 0 1.414l-1.61 1.612a1.994 1.994 0 0 1-.51 1.923L6.606 12.02c-.378.378-.88.586-1.414.586a1.987 1.987 0 0 1-1.398-.572L2 13.83l3.656 3.656 4.242-4.243a2 2 0 0 1 2.83 0z"
			/>
		</svg>
	</span>
);

export default DependencyIcon;
