import {MODAL_TYPE, showModal} from '../../../shared/components/modals/generic_modal_conductor';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';

export const generateAgileDemo = () => {
	trackEvent('Agile Demo Data', 'Loaded');
	showModal({
		type: MODAL_TYPE.LOAD_DEMO_ENVIRONMENT,
		demoEndpoint: 'agile',
	});
};

export const generateAgencyDemo = () => {
	trackEvent('Agency Demo Data', 'Loaded');
	showModal({
		type: MODAL_TYPE.LOAD_DEMO_ENVIRONMENT,
		demoEndpoint: 'agency',
	});
};
