import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import styled from 'styled-components';
import {NewTaskWrapper, QuickAddTaskWrapper} from './QuickAddTask.styled';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../../../../constants';
import Util from '../../../../util/util';
import {AffixedInputWrapper} from '../../../inputs/AffixedInputWrapper';
import HoursInput from '../../../inputs/hours-input/hours_input_view';
import Button from '../../../buttons/button/button';
import {RoleDropdown} from '../../../dropdowns/Role_dropdown';
import {PersonDropdown} from '../../../dropdowns/Person_dropdown';
import {withRouter} from 'react-router-dom/cjs/react-router-dom';
import {profilePicSrc} from '../../../../../../directApi';

const DropdownWrapper = styled.div`
	min-width: 158px;
	max-width: 158px;
	margin-right: 8px;
`;

class QuickAddTask extends Component {
	constructor(props) {
		super(props);

		this.state = {
			inputTitle: '',
			inputApprove: true,
			isNameInvalid: false,
			estimateForecastValue: 0,
			persons: [],
			role: null,
			expanded: false,
		};

		this.estimate_forecast_value = 0;
	}

	componentDidMount() {
		if (this.props.parentTaskId) {
			this._input && this._input.focus({preventScroll: true});
		}
	}

	handleInputTitleChange(e) {
		this.setState({
			inputTitle: e.target.value,
		});
	}

	handleTitleBlur() {
		if (this.state.inputTitle === '') {
			this.setState({expanded: false});
		}
	}

	addTask() {
		const estForecast = this.props.isEstimatedInHours
			? Util.convertTimeInputToMinutes(this.estimate_forecast_value)
			: this.estimate_forecast_value;

		const invalidTaskName = this.state.inputTitle === '';
		if (invalidTaskName) {
			this._input.focus();
			this.setState({
				isNameInvalid: invalidTaskName,
			});
		} else {
			this.props.addTask(
				this.props.projectId,
				this.state.inputTitle,
				this.state.role,
				estForecast,
				this.props.phaseId === 'no-phase' ? null : this.props.phaseId,
				this.props.parentTaskId,
				this.state.persons,
				this.props.intl,
				this.props.history
			);
			// reset state
			this.setState({
				inputTitle: '',
				inputRole: null,
				inputApprove: true,
				isNameInvalid: false,
				persons: [],
				role: null,
				estimateForecastValue: 0,
			});
			this.estimate_forecast_value = 0;
			this._input.focus();
		}
	}

	handleEnterKeyPress(e) {
		if (e.keyCode === 13) {
			this.addTask();
		}
	}

	assignRole(id) {
		const roles = this.props.roles;
		const role = roles.find(r => r.node.id === id);

		this.setState({role: role ? role.node : null});
	}

	assignRoleV2(ids) {
		const roles = this.props.roles;
		const role = roles.find(r => ids.includes(r.node.id));

		this.setState({role: role ? role.node : null});
	}

	unassignRole() {
		this.setState({role: null});
	}

	assignPerson(ids) {
		const projectPersons = this.props.projectPersons;
		let personsToAdd = projectPersons
			.map(person => person.node.person)
			.filter(projectPerson => ids.includes(projectPerson.id));

		if (this.state.role === null) {
			const roleToAdd = projectPersons.find(projectPerson => ids.includes(projectPerson.node.person.id));
			if (roleToAdd.node.role) this.assignRole(roleToAdd.node.role.id);
		}

		this.setState({
			persons: personsToAdd,
		});
	}

	unassignPerson(id) {
		const newPersons = this.state.persons.filter(person => person.id !== id);

		this.setState({persons: newPersons});
	}

	assignPersonV2(ids) {
		const projectPersons = this.props.projectPersons;
		const personToAdd = projectPersons.map(person => person.node.person).find(person => ids.includes(person.id));
		if (personToAdd) {
			const newPersons = [...this.state.persons, personToAdd];
			this.setState({
				persons: newPersons,
			});
		}
	}

	unassignPersonV2(ids) {
		const newPersons = this.state.persons.filter(person => !ids.includes(person.id));
		this.setState({persons: newPersons});
	}

	handleEstimateChange(value) {
		this.estimate_forecast_value = value;
		this.setState({estimateForecastValue: value});
	}

	expandAddTask() {
		this.setState({expanded: true}, () => this._input && this._input.focus());
	}

	render() {
		const {formatMessage} = this.props.intl;

		return this.state.expanded || this.props.parentTaskId ? (
			<QuickAddTaskWrapper disabled={this.props.disabled} subtaskStyle={this.props.parentTaskId} data-cy={'new-task-row'}>
				<div className={'task-name-input-wrapper' + (this.state.isNameInvalid ? ' wrong' : '')}>
					<input
						title={this.state.isNameInvalid ? formatMessage({id: 'common.field-cannot-be-empty'}) : ''}
						ref={el => (this._input = el)}
						type="text"
						placeholder={formatMessage({
							id: this.props.parentTaskId ? 'task_modal.new_subtask' : 'common.new-task',
						})}
						value={this.state.inputTitle}
						onChange={this.handleInputTitleChange.bind(this)}
						onBlur={this.handleTitleBlur.bind(this)}
						onKeyDown={this.handleEnterKeyPress.bind(this)}
						autoComplete="off"
						id={(this.props.phaseId ? this.props.phaseId : 'no-phase') + '-add-control'}
						className={'text-input task-name-input'}
						maxLength={191}
						disabled={this.props.disabled}
						data-cy="task-name"
					/>
				</div>
				<DropdownWrapper className="dropdown-container" tabIndex="-1">
					<RoleDropdown
						selectedItems={this.state.role ? [this.state.role.id] : []}
						onSelect={id => this.assignRoleV2(id)}
						onRemove={() => this.unassignRole()}
						dropdownAlignment={'left'}
						key={'assigned-role'}
						width={255}
						name={formatMessage({id: 'common.assign_role'})}
						isMultiSelect={false}
						isClearable={true}
						roles={this.props.roles}
						optionsName={formatMessage({id: 'settings.roles'})}
						selectedGroupName={formatMessage({id: 'common.applied'})}
						disabled={this.props.disabled || this.state.inputTitle === ''}
						taskName={this.state.inputTitle}
						projectId={this.props.projectId}
						useSuggestions
						usePortal
					/>
				</DropdownWrapper>
				<DropdownWrapper className="dropdown-container" tabIndex="-1">
					<PersonDropdown
						persons={this.props.projectPersons.map(projectPerson => {
							return {node: projectPerson.node.person};
						})}
						name={formatMessage({id: 'common.assign_people'})}
						optionsName={formatMessage({id: 'common.persons'})}
						selectedGroupName={formatMessage({id: 'common.applied'})}
						selectedItems={this.state.persons.map(person => person.id)}
						selectedItemsPlaceholder={this.state.persons.map(person => {
							return {
								fullName: person.fullName,
								imageSource: profilePicSrc(person.profilePictureId),
							};
						})}
						disabled={this.props.disabled || this.state.inputTitle === ''}
						onSelect={ids => this.assignPersonV2(ids)}
						onRemove={ids => this.unassignPersonV2(ids)}
						dropdownAlignment={'left'}
						width={255}
						taskName={this.state.inputTitle}
						projectId={this.props.isConnectedParent ? this.state.selectedProject.node.id : this.props.projectId}
						useSuggestions
						showRole
						usePortal
					/>
				</DropdownWrapper>
				{this.props.isEstimatedInHours ? (
					<div style={{minWidth: '90px', marginRight: '4px'}}>
						<HoursInput
							value={this.state.estimateForecastValue}
							placeholder={formatMessage({id: 'common.estimate'})}
							mutation={value => this.handleEstimateChange(value)}
							disabled={this.props.disabled || this.state.inputTitle === ''}
							parentKeyDown={this.handleEnterKeyPress.bind(this)}
						></HoursInput>
					</div>
				) : (
					<AffixedInputWrapper
						onKeyDown={this.handleEnterKeyPress.bind(this)}
						onClick={e => e.focus()}
						disabled={this.props.disabled || this.state.inputTitle === ''}
						customClassName={'hours-input'}
						value={this.state.estimateForecastValue}
						callback={value => this.handleEstimateChange(value)}
						affix={formatMessage({id: 'common.points.short'})}
					/>
				)}
				<div className="add" data-cy="task-add-button">
					<Button
						disabled={this.props.disabled || this.state.inputTitle === ''}
						colorTheme={this.state.inputTitle ? BUTTON_COLOR.GREEN : BUTTON_COLOR.LIGHTGREY}
						buttonStyle={BUTTON_STYLE.FILLED}
						className="input-add-button"
						onClick={this.addTask.bind(this)}
					/>
				</div>
			</QuickAddTaskWrapper>
		) : (
			<NewTaskWrapper
				onClick={() => this.expandAddTask()}
				data-cy={'new-task-action'}
				data-userpilot={this.props.isTopAddTask ? 'task-name-input-first' : ''}
			>
				+ {formatMessage({id: 'common.new-task'})}
			</NewTaskWrapper>
		);
	}
}

QuickAddTask.propTypes = {
	projectId: PropTypes.string.isRequired,
	companyId: PropTypes.string.isRequired,
	phase: PropTypes.object,
	addTask: PropTypes.func.isRequired,
	roles: PropTypes.array.isRequired,
	projectPersons: PropTypes.array.isRequired,
	availableFeatureFlags: PropTypes.array.isRequired,
	isTopAddTask: PropTypes.bool,
};

export default injectIntl(withRouter(QuickAddTask));
