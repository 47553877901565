/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SprintGoalSlideout_viewer$ref = any;
export type SprintGoalSlideout_QueryVariables = {|
  sprintId?: ?string,
  projectGroupSprintId?: ?string,
|};
export type SprintGoalSlideout_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: SprintGoalSlideout_viewer$ref,
  |}
|};
export type SprintGoalSlideout_Query = {|
  variables: SprintGoalSlideout_QueryVariables,
  response: SprintGoalSlideout_QueryResponse,
|};
*/


/*
query SprintGoalSlideout_Query(
  $sprintId: ID
  $projectGroupSprintId: ID
) {
  viewer {
    actualPersonId
    component(name: "sprint_goal")
    ...SprintGoalSlideout_viewer_1y2Aqp
    id
  }
}

fragment SprintGoalSlideout_viewer_1y2Aqp on Viewer {
  sprint(id: $sprintId) {
    id
    name
    sprintGoals(first: 1000000) {
      edges {
        node {
          id
          description
          statusColor
          stage
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  projectGroupSprint(id: $projectGroupSprintId) {
    id
    name
    sprintGoals(first: 1000000) {
      edges {
        node {
          id
          description
          statusColor
          stage
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sprintId",
    "type": "ID"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectGroupSprintId",
    "type": "ID"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "sprint_goal"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"sprint_goal\")"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000000
  }
],
v6 = {
  "alias": null,
  "args": (v5/*: any*/),
  "concreteType": "SprintGoalTypeConnection",
  "kind": "LinkedField",
  "name": "sprintGoals",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SprintGoalTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SprintGoal",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "statusColor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "stage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cursor",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PageInfo",
      "kind": "LinkedField",
      "name": "pageInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endCursor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasNextPage",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "sprintGoals(first:1000000)"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SprintGoalSlideout_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "args": [
              {
                "kind": "Variable",
                "name": "projectGroupSprintId",
                "variableName": "projectGroupSprintId"
              },
              {
                "kind": "Variable",
                "name": "sprintId",
                "variableName": "sprintId"
              }
            ],
            "kind": "FragmentSpread",
            "name": "SprintGoalSlideout_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SprintGoalSlideout_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "sprintId"
              }
            ],
            "concreteType": "Sprint",
            "kind": "LinkedField",
            "name": "sprint",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": (v5/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "Sprint_sprintGoals",
                "kind": "LinkedHandle",
                "name": "sprintGoals"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "projectGroupSprintId"
              }
            ],
            "concreteType": "ProjectGroupSprintType",
            "kind": "LinkedField",
            "name": "projectGroupSprint",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": (v5/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "ProjectGroupSprint_sprintGoals",
                "kind": "LinkedHandle",
                "name": "sprintGoals"
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "SprintGoalSlideout_Query",
    "operationKind": "query",
    "text": "query SprintGoalSlideout_Query(\n  $sprintId: ID\n  $projectGroupSprintId: ID\n) {\n  viewer {\n    actualPersonId\n    component(name: \"sprint_goal\")\n    ...SprintGoalSlideout_viewer_1y2Aqp\n    id\n  }\n}\n\nfragment SprintGoalSlideout_viewer_1y2Aqp on Viewer {\n  sprint(id: $sprintId) {\n    id\n    name\n    sprintGoals(first: 1000000) {\n      edges {\n        node {\n          id\n          description\n          statusColor\n          stage\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n  projectGroupSprint(id: $projectGroupSprintId) {\n    id\n    name\n    sprintGoals(first: 1000000) {\n      edges {\n        node {\n          id\n          description\n          statusColor\n          stage\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9b83507d595c510b2c8a8e79d4575f42';

module.exports = node;
