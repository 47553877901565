import Styled, {css} from 'styled-components';
import {CSS_CONSTANTS} from '../../../../../css_variables';

export const Element = Styled.div`
    display: flex;
	align-items: center;
	justify-content: ${props => props.split && 'space-between'};
	justify-content: ${props => props.alignRight && 'flex-end'};
    overflow: ${props => props.fixedWidth && 'hidden'};
	width: ${props => !props.fixedWidth && '100%'};
	flex-direction: ${props => props.column && 'column'};
`;

export const StickySection = Styled.div`
	margin-bottom: ${props => props.bottomSpacing && '24px'};
	padding: 0 32px;
	position: sticky;
	left: 0;
`;

export const FillBar = Styled.div`
    height: 100%;
    background-color: ${({percent}) => (percent > 1 ? '#E4253B' : '#05e298')};
    width: ${props => `${props.percent * 100}%`};
`;

export const HorizontalBar = Styled.hr`
	border: none;
	height: 1px;
	width: 100%;
	color: ${({transparent}) => (transparent ? '#fff' : '#E6E6ED')};
	background-color: ${({transparent}) => (transparent ? '#fff' : '#E6E6ED')};
`;

export const GridContainer = Styled.div`
    display: flex;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
`;

export const Data = Styled.div`
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    font-size: ${({large, small}) => (large ? '24px' : small ? '13px' : '16px')};
    font-weight: ${({large, bold}) => (large || bold ? '500' : '400')};
    color: ${({warning}) => (warning ? '#E4253B' : '#4C4C58')};
`;

export const Row = Styled.div`
    display: flex;
    flex-direction: row;
	justify-content: ${props => props.split && 'space-between'};
	margin-bottom: ${props => props.bottomMargin && `${props.bottomMargin}px`};
`;

export const Title = Styled.div`
	margin-right: ${props => props.rightMargin && `${props.rightMargin}px`};
    font-size: 13px;
    color: #a1a1a1;
	display: flex;
	flex-direction: row;
	text-align: center;
    ${({singleLine}) =>
		singleLine &&
		css`
			text-align: left;
			white-space: nowrap;
			margin-right: 4px;
		`}
`;

export const Header = Styled.div`	
    font-size: 16x;
    color: #4c4c58;
	display: flex;
	flex-direction: row;
	text-transform: capitalize;
`;

const TotalsContainer = Styled.div`
	overflow:hidden;
    height: 100%;
    border: 1px solid #e6e6e6;
    display: flex;
    flex-direction: column;
    padding: 16px 24px 24px 24px;
    align-items: ${({center}) => center && 'center'};
    justify-content: ${({center}) => center && 'center'};
    flex-grow: 1;
    border-radius: 4px;
`;

export const IconWrapper = Styled.div`
`;

export const TotalsBox = Styled(TotalsContainer)`    
    ${Row} {
    	margin-left: -48px;
		margin-right: -48px; 	
    }
	${Element} {
	    padding-left: 48px;
	    padding-right: 48px;
	}    
	${IconWrapper} {
    	margin-top: -8px;
    	margin-right: -16px;
    }
`;

export const TotalsLine = Styled(TotalsContainer)`
    padding: 8px 8px 8px 16px;
`;

export const TooltipTitle = Styled.div`
    whiteSpace: nowrap;
`;

export const TooltipValue = Styled.div`
    whiteSpace: nowrap;
`;

export const TooltipContainer = Styled.div`
    width: 180px;
`;

export const ValueContainer = Styled.div`
    display: flex;
`;

export const BracketsValue = Styled.div`
    display: flex;
    margin-left: 5px;
	color: ${({negative}) => negative && CSS_CONSTANTS.negative_value};
`;
