import {genKey} from 'draft-js';
import moment from 'moment';
import {WarningIcon} from 'web-components/icons';

export const sortProgramStatuses = (a, b) => {
	const aCreatedDate = moment(a.node.createdAt);
	const bCreatedDate = moment(b.node.createdAt);
	return aCreatedDate.isBefore(bCreatedDate) ? 1 : -1;
};

export const sortProjectStatuses = (a, b) => {
	const aCreatedDate = moment.utc({
		y: a.node.year,
		M: a.node.month - 1,
		d: a.node.day,
		h: a.node.hour,
		m: a.node.minute,
		s: a.node.second,
	});
	const bCreatedDate = moment.utc({
		y: b.node.year,
		M: b.node.month - 1,
		d: b.node.day,
		h: b.node.hour,
		m: b.node.minute,
		s: b.node.second,
	});
	return aCreatedDate.isBefore(bCreatedDate) ? 1 : -1;
};

export const parseSuggestedProgramStatusText = suggestedStatusText => {
	if (!suggestedStatusText) return '';
	return suggestedStatusText.map(text => `<p>${text}</p>`).join('');
};

export const convertWarningVariantToRagStatus = warningVariant => {
	switch (warningVariant) {
		case WarningIcon.VARIANT.DANGER:
			return 'RED';
		case WarningIcon.VARIANT.WARNING:
			return 'AMBER';
		default:
			return 'GREEN';
	}
};

export const convertWarningVariantToStatusColor = warningVariant => {
	switch (warningVariant) {
		case WarningIcon.VARIANT.DANGER:
			return '#ff3300';
		case WarningIcon.VARIANT.WARNING:
			return '#ffc107';
		default:
			return '#33cc33';
	}
};

const mapInfoToDraftJSBlocks = infoText => {
	return [
		{key: genKey(), text: infoText + '.', type: 'unstyled', depth: 0, inlineStyleRanges: [], entityRanges: [], data: {}},
		{key: genKey(), text: '', type: 'unstyled', depth: 0, inlineStyleRanges: [], entityRanges: [], data: {}},
	];
};

export const convertInfoTextToStatusDescription = activeInsightsInfoText => {
	if (!activeInsightsInfoText || activeInsightsInfoText.length === 0) return '';

	const activeInsightBlocks = activeInsightsInfoText.reduce((acc, info) => acc.concat(mapInfoToDraftJSBlocks(info)), []);

	return JSON.stringify({
		blocks: activeInsightBlocks,
		entityMap: {},
	});
};
