import React from 'react';
import SelectionBadge from '../dropdowns/selection_badge';

const SkillBadge = ({skill, skillLevel, unknownLevel = false, onSkillRemove}) => {
	let skillName = skill.name;

	if (skillLevel) {
		skillName += ` - ${skillLevel}`;
	} else if (unknownLevel) {
		skillName += ` - ?`;
	}

	return <SelectionBadge name={skillName} disabled={unknownLevel} onRemove={onSkillRemove} />;
};

export default SkillBadge;
