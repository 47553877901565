import ReportTable from './ReportsTable';
import React, { useState } from 'react';
import { Text } from '@forecast-it/design-system';
const ReportsTabContent = ({ enableSelection, company, reports, search, groupings, scrollRef, groupingColumnHeader, }) => {
    const [filteredReports, setFilteredReports] = useState([]);
    const showFilterText = filteredReports.length !== reports.length;
    const filterText = `Showing (${filteredReports.length} of ${reports.length})`;
    return (React.createElement(React.Fragment, null,
        React.createElement(Text, { size: '2' },
            " ",
            showFilterText ? filterText : ''),
        React.createElement(ReportTable, { enableSelection: enableSelection, company: company, reports: reports, search: search, groupings: groupings, scrollRef: scrollRef, setFilteredRows: setFilteredReports, groupingColumnHeader: groupingColumnHeader })));
};
export default ReportsTabContent;
