import React from 'react';
import { Card, DeprecatedCreativeModal as Modal, Icon } from '@forecast-it/design-system';
import styled from 'styled-components';
import { REPORT_TYPE } from '../../../../../constants';
import { useHistory } from 'react-router-dom';
import { createFragmentContainer, graphql } from 'react-relay';
import { useIntl } from 'react-intl';
import { hasSomePermission } from '../../../../shared/util/PermissionsUtil';
import { PERMISSION_TYPE } from '../../../../../Permissions';
const GridContainer = styled.div `
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 24px;
`;
const CreateReportModal = ({ createReport, viewer, closeModal }) => {
    const { company } = viewer;
    const history = useHistory();
    const { formatMessage } = useIntl();
    const hasFinancialAccess = hasSomePermission([
        PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION,
        PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE,
    ]);
    function handleCreateReport(type) {
        return createReport(type, company, history, closeModal);
    }
    return (React.createElement(Modal, { title: "Create a New Report", size: "xl", withoutButtons: true, closeModal: closeModal, replicateDesignSystem: true },
        React.createElement(GridContainer, null,
            React.createElement(Card, { "data-cy": 'create-report-card-time-reg', onClick: () => handleCreateReport(REPORT_TYPE.TIME_REG) },
                React.createElement(Card.Title, null,
                    React.createElement(Icon, { icon: 'clock' }),
                    formatMessage({ id: 'report.time_registration.title' })),
                React.createElement(Card.Body, null, formatMessage({ id: 'report.time_registration.description' }))),
            hasFinancialAccess ? (React.createElement(Card, { "data-cy": 'create-report-card-ppr', onClick: () => handleCreateReport(REPORT_TYPE.PROJECT_PORTFOLIO_REPORT) },
                React.createElement(Card.Title, null,
                    React.createElement(Icon, { icon: 'briefcase' }),
                    formatMessage({ id: 'report.project_portfolio.title' })),
                React.createElement(Card.Body, null, formatMessage({ id: 'report.project_portfolio.description' })))) : null,
            React.createElement(Card, { "data-cy": 'create-report-card-ut', onClick: () => handleCreateReport(REPORT_TYPE.UTILIZATION_REPORT) },
                React.createElement(Card.Title, null,
                    React.createElement(Icon, { icon: 'gauge' }),
                    formatMessage({ id: 'report.utilization.title' })),
                React.createElement(Card.Body, null, formatMessage({ id: 'report.utilization.description' }))),
            React.createElement(Card, { "data-cy": 'create-report-card-task', onClick: () => handleCreateReport(REPORT_TYPE.TASK_REPORT) },
                React.createElement(Card.Title, null,
                    React.createElement(Icon, { icon: 'task' }),
                    formatMessage({ id: 'report.task.title' })),
                React.createElement(Card.Body, null, formatMessage({ id: 'report.task.description' }))))));
};
export const CreateReportModalQuery = graphql `
	query CreateReportModalQuery {
		viewer {
			actualPersonId
			component(name: "create_report_modal")
			...CreateReportModal_viewer
		}
	}
`;
export default createFragmentContainer(CreateReportModal, {
    viewer: graphql `
		fragment CreateReportModal_viewer on Viewer {
			company {
				isUsingProjectAllocation
				isUsingSchedulingPlanMode
				isUsingMixedAllocation
			}
		}
	`,
});
