import React from 'react';

import {hideLoader, showLoader} from '../../../containers/global_loader';
import CheckmarkIcon from '../../../images/checkmark_icon';
import WarningIcon from '../../../images/warning_icon';
import {EVENT_ID, dispatch} from '../../../containers/event_manager';
import Moment from 'moment';
import ForecastTooltip from '../../shared/components/tooltips/ForecastTooltip';

export const getHolidaysOfTheDay = (person, day) => {
	return person.holidayCalendar
		? person.holidayCalendar.holidayCalendarEntries.edges.filter(
				d => d.node.day === day.date() && d.node.month === day.month() + 1 && d.node.year === day.year()
		  )
		: [];
};

export const workingHourForTheDay = (person, day) => {
	const personStartDate = Moment(person.startDate || person.createdAt);
	const personEndDate = person.endDate && Moment(person.endDate);

	if (day.isBefore(personStartDate, 'day') || (personEndDate && day.isSameOrAfter(personEndDate, 'day'))) return 0;
	const workingHours = [
		person.sunday,
		person.monday,
		person.tuesday,
		person.wednesday,
		person.thursday,
		person.friday,
		person.saturday,
	];
	return workingHours[day.day()];
};

const workingHourForTheWeek = (person, lastDayOfWeek) => {
	const p = person.node ? person.node : person;
	const personStartDate = Moment(person.startDate || person.createdAt);
	const personEndDate = person.endDate && Moment(person.endDate);

	let workingHours;
	if (
		lastDayOfWeek.isBefore(personStartDate) ||
		(personEndDate && lastDayOfWeek.clone().startOf('w').isSameOrAfter(personEndDate))
	) {
		return 0; //the person didn't start yet or isn't working there anymore
	} else if (personStartDate.isBetween(lastDayOfWeek.clone().startOf('w'), lastDayOfWeek, null, '[]')) {
		workingHours = [p.sunday, p.monday, p.tuesday, p.wednesday, p.thursday, p.friday, p.saturday];
		const startDay = personStartDate.day();
		workingHours = workingHours.map((hours, index) => (index < startDay ? 0 : hours));
	} else if (personEndDate && personEndDate.isBetween(lastDayOfWeek.clone().startOf('w'), lastDayOfWeek)) {
		workingHours = [p.sunday, p.monday, p.tuesday, p.wednesday, p.thursday, p.friday, p.saturday];
		const endDay = personEndDate.day();
		workingHours = workingHours.map((hours, index) => (index >= endDay ? 0 : hours));
	} else {
		workingHours = [p.sunday, p.monday, p.tuesday, p.wednesday, p.thursday, p.friday, p.saturday];
	}

	return workingHours.reduce((total, sum) => total + sum);
};

export const getEmailIcon = (lastDayOfWeek, lockedDate, currentPersonViewed, totalTime, callback, intl) => {
	const workingHours = workingHourForTheWeek(currentPersonViewed, lastDayOfWeek);
	let res = null;
	const today = Moment();
	const locked = lastDayOfWeek.isSameOrBefore(today);
	const isCurrentWeek = lastDayOfWeek.isSame(today, 'week');

	res =
		workingHours === 0 ? (
			'' //the person wasn't created yet, so no warning
		) : totalTime >= workingHours && workingHours > 0 ? (
			<CheckmarkIcon locked={lastDayOfWeek.isSameOrBefore(today)} />
		) : lockedDate && lockedDate.isSameOrAfter(lastDayOfWeek, 'd') ? (
			<WarningIcon
				className="warning-icon"
				title={intl.formatMessage({id: 'overview_time.not_enough_time_warning'})}
				danger={true}
				customWidth={15}
				customHeight={15}
			/>
		) : locked || isCurrentWeek ? (
			<ForecastTooltip
				content={intl.formatMessage({id: 'timesheet.reminder_tooltip'}, {firstName: currentPersonViewed.firstName})}
			>
				<div
					data-userpilot="timesheets-team-email-warning"
					className={'warning-email-icon' + (isCurrentWeek ? ' hidden' : '')}
					onClick={e => callback(e, currentPersonViewed)}
				></div>
			</ForecastTooltip>
		) : (
			''
		);
	return res;
};

export const getWeekTimeIcon = (lastDayOfWeek, currentPersonViewed, totalTime, intl, fullweekLocked) => {
	const workingHours = workingHourForTheWeek(currentPersonViewed, lastDayOfWeek);
	let res = null;
	const today = Moment();
	res =
		workingHours === 0 ? (
			'' //the person wasn't created yet, so no warning
		) : totalTime >= workingHours && workingHours > 0 ? (
			<CheckmarkIcon locked={fullweekLocked} />
		) : lastDayOfWeek.isBefore(today) && !fullweekLocked ? (
			<WarningIcon
				className="warning-icon"
				title={intl.formatMessage({id: 'overview_time.not_enough_time_warning'})}
				danger={true}
				customWidth={15}
				customHeight={15}
			/>
		) : (
			''
		);
	return res;
};

export const handlePersonSelected = (person, date, refetch) => {
	const onSuccess = error => {
		hideLoader();
		dispatch(EVENT_ID.RESET_SWITCH);
	};
	showLoader();
	const startDate = date.clone().startOf('month').startOf('week').format('YYYY-MM-DD');
	const endDate = date.clone().endOf('month').endOf('week').format('YYYY-MM-DD');
	refetch(
		{
			personId: person.value,
			startDateString: startDate,
			endDateString: endDate,
		},
		null,
		onSuccess
	);
	return person.value;
};
