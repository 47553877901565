import {BUDGET_TYPE, MODULE_TYPES, PROGRAM_BUDGET_TYPE} from '../../../constants';
import ProjectUtil from './project_util';
import {hasPermission} from './PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import {hasModule} from './ModuleUtil';
import Util from './util';

export default class ProgramUtil {
	static isProjectAvailableForFixedPriceProgram(project) {
		return ProjectUtil.isFixedPriceProject(project) || project.budgetType === BUDGET_TYPE.NON_BILLABLE;
	}

	static getAvailableAndUnavailableProjects = (projects, program, formatMessage) => {
		const availableProjects = [];
		const unavailableProjects = [];

		projects.forEach(project => {
			const isInProjectGroup = project.settings?.edges
				.map(setting => setting.node)
				.find(setting => setting.name === 'connectedProjectId');

			if (!project.program && !isInProjectGroup) {
				switch (program.budgetType) {
					case PROGRAM_BUDGET_TYPE.FIXED_PRICE:
						if (ProgramUtil.isProjectAvailableForFixedPriceProgram(project)) {
							availableProjects.push({data: project});
						} else {
							unavailableProjects.push({
								data: project,
								tooltipMessage: formatMessage({
									id: 'import_project.unavailable_project_fixed_price_budget_type_tooltip',
								}),
							});
						}
						break;
					case PROGRAM_BUDGET_TYPE.CAPPED:
						if (!program.isRevenueLocked || ProjectUtil.getProjectConfirmedRevenue(project) <= 0) {
							availableProjects.push({data: project});
						} else {
							unavailableProjects.push({
								data: project,
								tooltipMessage: formatMessage({
									id: 'import_project.unavailable_project_capped_confirmed_revenue',
								}),
							});
						}
						break;
					case PROGRAM_BUDGET_TYPE.UNCAPPED:
						availableProjects.push({data: project});
						break;
				}
			} else {
				unavailableProjects.push({
					data: project,
					tooltipMessage: isInProjectGroup
						? formatMessage({id: 'import_project.project_in_project_group_tooltip'})
						: Util.upperCaseFirst(
								formatMessage(
									{id: 'import_project.project_in_another_program_tooltip'},
									{program: ProgramUtil.programText(formatMessage)}
								)
						  ),
				});
			}
		});

		return {availableProjects, unavailableProjects};
	};

	static getTotalConfirmedRevenue(program) {
		return program.totalAllocation;
	}

	static canPersonManageProgram(members, actualPersonId) {
		if (hasPermission(PERMISSION_TYPE.PROGRAMS_MANAGE_ALL)) {
			return true;
		}
		if (!members || !members.edges) {
			return false;
		}
		const member = members.edges.find(member => member.node?.person?.id === actualPersonId);
		if (member && member.node.role === 'OWNER') {
			return true;
		}
		return false;
	}

	static programText = (formatMessage, multiple = false) => {
		return hasModule(MODULE_TYPES.PROGRAMS_PLUS)
			? multiple
				? formatMessage({id: 'common.programs'})
				: formatMessage({id: 'common.program'})
			: multiple
			? formatMessage({id: 'common.project-groups'})
			: formatMessage({id: 'common.project-group'});
	};

	static programLink = (multiple = false) =>
		hasModule(MODULE_TYPES.PROGRAMS_PLUS)
			? multiple
				? '/programs'
				: '/program'
			: multiple
			? '/project-groups'
			: '/project-group';
}
