import React from 'react';
import {FormattedHTMLMessage, FormattedMessage, injectIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import {withRouter} from 'react-router-dom';
import * as tracking from '../../../tracking';
import {SlackIcon} from 'web-components';
import CreateSlackVerificationKeyMutation from '../../../mutations/slack_integration/create_slack_verification_key_mutation';
import CustomScrollDiv from '../../shared/components/scroll-bars/custom_scroll_div';
import Util from '../../shared/util/util';
import {ELEMENT_TYPE} from '../../../constants';
import {buildHeaderBar} from '../../shared/components/headers/header-bar/header_bar';
import DeactivateSlackIntegrationMutation from '../../../mutations/slack_integration/DeactivateSlackIntegrationMutation';
import {trackEvent, trackPage, unregisterPageInfo} from '../../../tracking/amplitude/TrackingV2';

class appSlack extends React.Component {
	constructor(props) {
		super(props);

		this.superPropertyChecksum = trackPage('App Catalog - Slack');
	}

	componentDidMount() {
		document.title = 'Slack - Apps & Integrations - Forecast';
		// Segment
		tracking.trackPage('settings-app-catalog-slack');
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	sendToSlack() {
		const redirectUri = `${process.env.API_URL}/slack/oauth`;
		const onSuccess = ({createSlackVerificationKey}) => {
			tracking.trackEvent('Slack integration enabled');
			trackEvent('Slack Integration', 'Enabled');
			const scopes = [
				'app_mentions:read',
				'links:read',
				'links:write',
				'chat:write',
				'commands',
				'channels:read',
				'channels:join',
				'users:read',
			];
			window.location.href = `https://slack.com/oauth/v2/authorize?client_id=${
				process.env.SLACK_APP_CLIENT_ID
			}&redirect_uri=${redirectUri}&scope=${scopes.join(',')}&user_scope=&state=${createSlackVerificationKey.key}`;
		};

		Util.CommitMutation(CreateSlackVerificationKeyMutation, null, onSuccess);
	}

	disableSlack() {
		Util.CommitMutation(DeactivateSlackIntegrationMutation);
	}

	openHelpCenter() {
		window.open('https://support.forecast.app/hc/en-us/articles/6324664929809');
	}

	getHeader() {
		const leftContent = [],
			rightContent = [];

		leftContent.push({type: ELEMENT_TYPE.BACK_BUTTON, onClick: this.props.history.goBack});

		return buildHeaderBar(leftContent, rightContent);
	}

	render() {
		return (
			<div className="section-content settings-app settings-app-page settings-app-slack">
				{this.props.children}
				{this.getHeader()}
				<CustomScrollDiv>
					<div className="section-body">
						<div className="inner">
							<fieldset className="general">
								<div className="header-wrapper">
									<SlackIcon size={SlackIcon.SIZE.EXTRA_BIG} />
									<div className="title-description-wrapper">
										<div className="description">
											<FormattedHTMLMessage id={'settings.app_catalog.slack.new_description'} />
										</div>
									</div>
								</div>
								<div className="content-wrapper">
									<div className="input-fields">
										{this.props.viewer.company.newSlackEnabled ? (
											<button onClick={() => this.disableSlack()}>
												<FormattedMessage id="settings.app_catalog.slack.deactivate" />
											</button>
										) : (
											<a onClick={() => this.sendToSlack()}>
												<img
													alt="Add to Slack"
													height="40"
													width="139"
													src="https://platform.slack-edge.com/img/add_to_slack.png"
													srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
												/>
											</a>
										)}
									</div>
									<button className="help-button" onClick={this.openHelpCenter.bind(this)}>
										<FormattedMessage id="settings.app_catalog.help_button" />
									</button>
								</div>
							</fieldset>
						</div>
					</div>
				</CustomScrollDiv>
			</div>
		);
	}
}

const appSlackQuery = graphql`
	query appSlack_Query {
		viewer {
			actualPersonId
			component(name: "app_slack")
			...appSlack_viewer
		}
	}
`;

export {appSlackQuery};

export default createFragmentContainer(injectIntl(withRouter(appSlack)), {
	viewer: graphql`
		fragment appSlack_viewer on Viewer {
			id
			company {
				id
				slackEnabled
				newSlackEnabled
			}
		}
	`,
});
