/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type timeRegWSugestionsInputQueryVariables = {|
  personId?: ?string,
  dateString?: ?string,
|};
export type timeRegWSugestionsInputQueryResponse = {|
  +viewer: ?{|
    +component: ?string,
    +timeSuggestionsRecommender: ?{|
      +recommendationInstanceId: ?number,
      +recommendationId: ?number,
      +generation: ?number,
      +timeStep: ?number,
    |},
    +timeSuggestionsRecommenderTwo: ?{|
      +recommendationInstanceId: ?number,
      +recommendationId: ?number,
      +generation: ?number,
      +timeStep: ?number,
    |},
    +monday: ?number,
    +tuesday: ?number,
    +wednesday: ?number,
    +thursday: ?number,
    +friday: ?number,
    +saturday: ?number,
    +sunday: ?number,
    +actualPersonId: ?string,
    +timeRegistrations: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +day: ?number,
          +month: ?number,
          +year: ?number,
          +minutesRegistered: ?number,
          +person: ?{|
            +id: string
          |},
        |}
      |}>
    |},
  |}
|};
export type timeRegWSugestionsInputQuery = {|
  variables: timeRegWSugestionsInputQueryVariables,
  response: timeRegWSugestionsInputQueryResponse,
|};
*/


/*
query timeRegWSugestionsInputQuery(
  $personId: ID
  $dateString: String
) {
  viewer {
    component(name: "suggested_time")
    timeSuggestionsRecommender {
      recommendationInstanceId
      recommendationId
      generation
      timeStep
      id
    }
    timeSuggestionsRecommenderTwo {
      recommendationInstanceId
      recommendationId
      generation
      timeStep
      id
    }
    monday
    tuesday
    wednesday
    thursday
    friday
    saturday
    sunday
    actualPersonId
    timeRegistrations(first: 100000, personId: $personId, date: $dateString) {
      edges {
        node {
          id
          day
          month
          year
          minutesRegistered
          person {
            id
          }
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "personId",
    "type": "ID"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dateString",
    "type": "String"
  }
],
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "suggested_time"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"suggested_time\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "recommendationInstanceId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "recommendationId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "generation",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeStep",
  "storageKey": null
},
v6 = [
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "monday",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tuesday",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "wednesday",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thursday",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "friday",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "saturday",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sunday",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "date",
      "variableName": "dateString"
    },
    {
      "kind": "Literal",
      "name": "first",
      "value": 100000
    },
    {
      "kind": "Variable",
      "name": "personId",
      "variableName": "personId"
    }
  ],
  "concreteType": "TimeRegTypeConnection",
  "kind": "LinkedField",
  "name": "timeRegistrations",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TimeRegTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TimeRegType",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v15/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "day",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "month",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "year",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "minutesRegistered",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Person",
              "kind": "LinkedField",
              "name": "person",
              "plural": false,
              "selections": [
                (v15/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v17 = [
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v15/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "timeRegWSugestionsInputQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Recommender",
            "kind": "LinkedField",
            "name": "timeSuggestionsRecommender",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Recommender",
            "kind": "LinkedField",
            "name": "timeSuggestionsRecommenderTwo",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v16/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "timeRegWSugestionsInputQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Recommender",
            "kind": "LinkedField",
            "name": "timeSuggestionsRecommender",
            "plural": false,
            "selections": (v17/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Recommender",
            "kind": "LinkedField",
            "name": "timeSuggestionsRecommenderTwo",
            "plural": false,
            "selections": (v17/*: any*/),
            "storageKey": null
          },
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v16/*: any*/),
          (v15/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "timeRegWSugestionsInputQuery",
    "operationKind": "query",
    "text": "query timeRegWSugestionsInputQuery(\n  $personId: ID\n  $dateString: String\n) {\n  viewer {\n    component(name: \"suggested_time\")\n    timeSuggestionsRecommender {\n      recommendationInstanceId\n      recommendationId\n      generation\n      timeStep\n      id\n    }\n    timeSuggestionsRecommenderTwo {\n      recommendationInstanceId\n      recommendationId\n      generation\n      timeStep\n      id\n    }\n    monday\n    tuesday\n    wednesday\n    thursday\n    friday\n    saturday\n    sunday\n    actualPersonId\n    timeRegistrations(first: 100000, personId: $personId, date: $dateString) {\n      edges {\n        node {\n          id\n          day\n          month\n          year\n          minutesRegistered\n          person {\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ce23e0bc8685cf20657389ffe398aef1';

module.exports = node;
