import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Dropdown, Switch, Table, TableHeaderGrouping, WarningIcon} from 'web-components';
import InlineLoader from '../../../shared/components/inline-loader/inline_loader';
import TooltipContainer from '../../../shared/components/tooltips/tooltip_container';
import Util from '../../../shared/util/util';
import {createToast} from '../../../shared/components/toasts/toast';
import {ForecastProbability, ForecastProbabilityWarningWrapper} from '../salesforce/app_salesforce_style';
import {SelectPipelineDescription, SelectPipelineWrapper} from './HubspotStagesTableStyled';
import DirectApi from '../../../../directApi';

const hubspotStagesTable = () => {
	const intl = useIntl();

	//all hubspot pipelines -> contains both dynamodb and hubspot data
	const [pipelines, setPipelines] = useState(null);
	//the currently selected pipeline -> contains both dynamodb and hubspot data
	const [selectedPipeline, setSelectedPipeline] = useState(null);
	//the stages that correspond to the currently selected pipeline -> contains both dynamodb and hubspot data
	const [stagesToShow, setStagesToShow] = useState(null);
	//the stages settings of the currently selected pipeline from dynamodb -> contains only dynamodb data
	const [localStages, setLocalStages] = useState(null);
	//all pipelines settings from dynamodb -> contains only dyna,odb data
	const [localPipelines, setLocalPipelines] = useState(null);

	const handleSelectPipeline = pipeline => {
		const pipelineId = pipeline[0];
		const selectedPipeline = pipelines.find(p => p.id === pipelineId);

		//find the index of the selected pipeline in local pipelines
		const pipelineIndex = localPipelines.findIndex(localPipeline => localPipeline.id === pipelineId);
		let newLocalStages;
		//if the pipeline was found in the localPipelines, the localStages will take the values of the pipeline's stages,
		//otherwise the localStages will be just an empty array (this happens when all the "create project" dropdowns are off)
		if (pipelineIndex >= 0) {
			newLocalStages = localPipelines[pipelineIndex].stages;
		} else {
			newLocalStages = [];
		}
		setSelectedPipeline(pipeline[0]);
		setStagesToShow(selectedPipeline.stages);
		setLocalStages(newLocalStages);
	};

	const saveStages = async stagesSettings => {
		const headers = new Headers();
		headers.append('Content-Type', 'application/json');

		const init = {
			headers: headers,
			credentials: 'include',
			method: 'PUT',
			body: JSON.stringify({
				stagesSettings: stagesSettings,
			}),
		};

		fetch(DirectApi.graphqlServerEndpoint('hubspot/deal_stages'), init).then(() => {
			createToast({
				duration: 2000,
				message: intl.formatMessage({id: 'common.changes_saved'}),
			});
		});
	};

	const setStageState = async stage => {
		//add the stage in the local stages list and sort it
		const localStageAsc = [...localStages.filter(s => s.id !== stage.id), stage].sort(
			(a, b) => a.displayOrder - b.displayOrder
		);

		//find the pipeline and its index in the list of pipelines
		const foundPipeline = pipelines.find(pipeline => {
			return pipeline.stages.some(s => s.id === stage.id);
		});
		const foundPipelineIndex = pipelines.findIndex(pipeline => {
			return pipeline.stages.some(s => s.id === stage.id);
		});

		//update the localPipelines list.
		//if the pipeline of the selected stage does not exist in the list
		//(which happens when all the "create project" dropdowns were off),
		//then add it to the list and set its stages to localStagesAsc,
		// otherwise update the existing pipeline's stages to localStagesAsc
		const localPipelinesListUpdated = localPipelines;

		const isPipelineInLocalPipelines = localPipelinesListUpdated.some(pipeline => pipeline.id === foundPipeline.id);

		if (isPipelineInLocalPipelines) {
			const index = localPipelinesListUpdated.findIndex(pipeline => pipeline.id === foundPipeline.id);
			localPipelinesListUpdated[index].stages = localStageAsc;
		} else {
			localPipelinesListUpdated.push({...foundPipeline, stages: localStageAsc});
		}

		//update the pipelines list.
		//when we find the pipeline of the selected stage,
		//we want to update its stages to contain
		//both stages from Hubspot which aren't in the saved settings
		//and take the settings for the stages that still exist in Hubspot
		const pipelineListUpdated = pipelines.map(pipeline => {
			if (pipeline.id === foundPipeline.id) {
				const updatedStages = pipeline.stages.map(s => {
					const index = localStageAsc.findIndex(localStage => localStage.id === s.id);
					if (index >= 0) {
						return localStageAsc[index];
					} else {
						return s;
					}
				});
				return {...pipeline, stages: updatedStages};
			} else return pipeline;
		});

		//get the updated stages to show
		const stagesToShowUpdated = pipelineListUpdated[foundPipelineIndex].stages;

		//save the changes to dynamodb
		await saveStages({pipelines: localPipelinesListUpdated});

		//updat the state of pipelines, localPipelines, localStages, stagesToShow
		setPipelines(pipelineListUpdated);
		setLocalPipelines(localPipelinesListUpdated);
		setLocalStages(localStageAsc);
		setStagesToShow(stagesToShowUpdated);
	};

	const buildDealStageRow = stage => {
		//get the hubspot probability
		const hubspotProbability = Number(stage.metadata.probability) * 100;

		// Sort descending, set closest stage to be last non-null projectStatus setting
		const closestWithStatus = localStages
			.filter(s => s.displayOrder < stage.displayOrder)
			.sort((a, b) => b.displayOrder - a.displayOrder)
			.find(s => !!s.ProjectStatus);

		let forecastStageValue;
		// If stage is selected for this stage, choose that
		if (stage.ProjectStatus) {
			forecastStageValue = stage.ProjectStatus;
		}
		// If stage with a lower order has a stage, choose that
		else if (closestWithStatus) {
			forecastStageValue = closestWithStatus.ProjectStatus;
		}
		// If no stage, but project is set to be created by this point, set to opportunity status
		else {
			forecastStageValue = 'OPPORTUNITY';
		}

		//Find forecast probability
		let forecastProbability;
		if (forecastStageValue === 'OPPORTUNITY') {
			//If status at this stage is opportunity, choose Hubspot probability
			forecastProbability = hubspotProbability;
		} else {
			forecastProbability = '100';
		}

		//Check if probability out of sync
		const probabilityOutOfSync = `${forecastProbability}` !== `${hubspotProbability}`;

		const result = {
			id: stage.displayOrder,
			hubspotStage: stage.label,
			forecastProjectStage: (
				<Dropdown
					width={188}
					usePortal
					dropdownAlignment="none"
					isClearable={stage.ProjectStatus}
					selectedItems={[forecastStageValue]}
					name={'None'}
					onSelect={s => {
						setStageState({...stage, ProjectStatus: s[0]});
					}}
					onRemove={() => setStageState({...stage, ProjectStatus: null})}
				>
					{Util.getProjectStageForDropdown(intl, true).map(status => (
						<Dropdown.SingleText key={status.label} value={status.value} searchString={status.value}>
							{status.label}
						</Dropdown.SingleText>
					))}
				</Dropdown>
			),
			hubspotProbability: `${hubspotProbability}%`,
			forecastProbability: probabilityOutOfSync ? (
				<ForecastProbabilityWarningWrapper>
					<TooltipContainer
						infoText={intl.formatMessage({id: 'settings.app_catalog.salesforce.probability_out_of_sync'})}
					>
						<WarningIcon color={'red'} />
					</TooltipContainer>
					<ForecastProbability>{forecastProbability}%</ForecastProbability>
				</ForecastProbabilityWarningWrapper>
			) : (
				`${forecastProbability}%`
			),
			createProject: (
				<Switch
					checked={stage.CreateProject}
					onChange={() => setStageState({...stage, CreateProject: !stage.CreateProject})}
				/>
			),
		};

		return result;
	};

	useEffect(() => {
		DirectApi.Fetch('hubspot/deal_stages').then(json => {
			const pipelines = json.list;
			let localPipelinesState = [];
			//construct the localPipelines state, so that it only contains
			//the dynamodb data
			pipelines.forEach(pipeline => {
				let pipelineAdded = false;
				pipeline.stages.forEach(stage => {
					if (!!stage.ProjectStatus || !!stage.CreateProject) {
						if (!pipelineAdded) {
							localPipelinesState.push({...pipeline, stages: [stage]});
							pipelineAdded = true;
						} else {
							const index = localPipelinesState.findIndex(p => p.id === pipeline.id);
							localPipelinesState[index].stages.push(stage);
						}
					}
				});
			});

			setPipelines(json.list);
			setSelectedPipeline(json.list[0].id);
			setStagesToShow(json.list[0].stages);
			setLocalStages(json.list[0].stages.filter(stage => !!stage.ProjectStatus || !!stage.CreateProject));
			setLocalPipelines(localPipelinesState);
		});
	}, []);

	const dataRows =
		stagesToShow &&
		localStages &&
		stagesToShow.sort((a, b) => a.displayOrder - b.displayOrder).map(stage => buildDealStageRow(stage));

	const data = dataRows && {rows: dataRows};

	return !pipelines || !stagesToShow || !selectedPipeline || !localStages ? (
		<InlineLoader />
	) : (
		<>
			<SelectPipelineWrapper>
				<SelectPipelineDescription>
					<span>{intl.formatMessage({id: 'hubspot_configuration.select_pipeline'})}:</span>
				</SelectPipelineDescription>

				<Dropdown
					usePortal
					selectedItems={[selectedPipeline]}
					onSelect={handleSelectPipeline}
					name={intl.formatMessage({id: 'hubspot_configuration.select_pipeline'})}
				>
					{pipelines.map(pipeline => {
						return (
							<Dropdown.SingleText key={pipeline.id} value={pipeline.id}>
								{pipeline.label}
							</Dropdown.SingleText>
						);
					})}
				</Dropdown>
			</SelectPipelineWrapper>

			<Table>
				<Table.Header>
					<Table.HeaderColumn key={'header-column-1'} usePadding visible width="EXTRA_SMALL" align="left">
						<Table.HeaderColumn.Title>#</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn key={'header-column-2'} flex={1} usePadding visible width="LARGE" align="left">
						<Table.HeaderColumn.Title>Hubspot</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn key={'header-column-3'} usePadding visible width="LARGE" align="left">
						<Table.HeaderColumn.Title>Forecast</Table.HeaderColumn.Title>
					</Table.HeaderColumn>

					<TableHeaderGrouping key={'header-column-4'} name={intl.formatMessage({id: 'project.win_chance'})}>
						<Table.HeaderColumn key={'header-column-5'} usePadding visible width="MEDIUM" align="right">
							<Table.HeaderColumn.Title>Hubspot</Table.HeaderColumn.Title>
						</Table.HeaderColumn>
						<Table.HeaderColumn key={'header-column-6'} usePadding visible width="MEDIUM" align="right">
							<Table.HeaderColumn.Title>Forecast</Table.HeaderColumn.Title>
						</Table.HeaderColumn>
					</TableHeaderGrouping>

					<Table.HeaderColumn key={'header-column-7'} usePadding visible width="MEDIUM" align="center">
						<Table.HeaderColumn.Title>{intl.formatMessage({id: 'common.create_project'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
				</Table.Header>
				<Table.Rows data={data} canExpand={false}>
					{({rowData, tableColumnsProps}) => {
						return (
							<Table.Row key={rowData.id} {...tableColumnsProps}>
								<Table.Column>{rowData.id}</Table.Column>
								<Table.Column>{rowData.hubspotStage}</Table.Column>
								<Table.Column>{rowData.forecastProjectStage}</Table.Column>
								<Table.Column>{rowData.hubspotProbability}</Table.Column>
								<Table.Column>{rowData.forecastProbability}</Table.Column>
								<Table.Column>{rowData.createProject}</Table.Column>
							</Table.Row>
						);
					}}
				</Table.Rows>
			</Table>
		</>
	);
};

export default hubspotStagesTable;
