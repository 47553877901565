import { hasBillableMinutesSplitFeature } from './../../../admin-tab/time-management/time-registration-settings/TimeRegistrationSettingsSection';
import { Granularity } from '../../../admin-tab/time-management/constants/Granularity';
import { hasPermission } from '../PermissionsUtil';
import { PERMISSION_TYPE } from '../../../../Permissions';
const CACHE = new Map();
const TimeRegistrationSettingsStorageKey = 'timeRegistrationSettings';
const DefaultSetup = {
    granularity: Granularity.Minutes,
    allowDoneTasks: true,
    allowProjects: true,
    allowInternalTime: true,
    allowExceedingEstimate: true,
    allowOutsideTaskDates: true,
    allowOutsideProjectDates: true,
    allowBillableSplit: false,
    allowRoles: false,
    requireTaskAssignment: false,
    requireNote: false,
};
export const TimeRegistrationSettingsDefaultSetup = DefaultSetup;
export const setTimeRegistrationSettings = (timeRegistrationSettings) => {
    if (timeRegistrationSettings) {
        CACHE.clear();
        sessionStorage.setItem(TimeRegistrationSettingsStorageKey, JSON.stringify(timeRegistrationSettings));
        // populate cache
        CACHE.set(TimeRegistrationSettingsStorageKey, timeRegistrationSettings);
    }
};
const setTimeRegistrationSettingsCache = () => {
    if (CACHE.size === 0) {
        const sessionTimeRegistrationSettings = sessionStorage.getItem(TimeRegistrationSettingsStorageKey);
        const timeRegistrationSettings = sessionTimeRegistrationSettings
            ? JSON.parse(sessionTimeRegistrationSettings)
            : DefaultSetup;
        setTimeRegistrationSettings(timeRegistrationSettings);
    }
};
export const getTimeRegistrationSettings = () => {
    setTimeRegistrationSettingsCache();
    const cachedTimeRegistrationSettings = CACHE.get(TimeRegistrationSettingsStorageKey);
    if (cachedTimeRegistrationSettings) {
        return cachedTimeRegistrationSettings;
    }
    return DefaultSetup;
};
export function isTimeRegistrationNoteRequired() {
    const timeRegistrationSettings = getTimeRegistrationSettings();
    return timeRegistrationSettings.requireNote;
}
export function isTimeRegistrationTaskAssignmentRequired() {
    const timeRegistrationSettings = getTimeRegistrationSettings();
    return timeRegistrationSettings.requireTaskAssignment;
}
export function isDoneTaskTimeRegistrationAllowed() {
    const timeRegistrationSettings = getTimeRegistrationSettings();
    return timeRegistrationSettings.allowDoneTasks;
}
export function isProjectTimeRegistrationAllowed() {
    const timeRegistrationSettings = getTimeRegistrationSettings();
    return timeRegistrationSettings.allowProjects;
}
export function isInternalTimeRegistrationAllowed() {
    const timeRegistrationSettings = getTimeRegistrationSettings();
    return timeRegistrationSettings.allowInternalTime;
}
export function isBillableSplitAllowed() {
    if (!hasBillableMinutesSplitFeature())
        return DefaultSetup.allowBillableSplit;
    const timeRegistrationSettings = getTimeRegistrationSettings();
    return timeRegistrationSettings.allowBillableSplit;
}
export function isRolesAllowed(showRolesForTimeAdmins = false) {
    const timeRegistrationSettings = getTimeRegistrationSettings();
    if (showRolesForTimeAdmins) {
        return timeRegistrationSettings.allowRoles || hasPermission(PERMISSION_TYPE.TIME_REGISTRATION_UPDATE_ALL);
    }
    else {
        return timeRegistrationSettings.allowRoles;
    }
}
export function isExceedingEstimateAllowed() {
    const timeRegistrationSettings = getTimeRegistrationSettings();
    return timeRegistrationSettings.allowExceedingEstimate;
}
export function isOutsideTaskDatesAllowed() {
    const timeRegistrationSettings = getTimeRegistrationSettings();
    return timeRegistrationSettings.allowOutsideTaskDates;
}
export function isOutsideProjectDatesAllowed() {
    const timeRegistrationSettings = getTimeRegistrationSettings();
    return timeRegistrationSettings.allowOutsideProjectDates;
}
export const getGranularity = () => {
    return getTimeRegistrationSettings().granularity;
};
