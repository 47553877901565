/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type SecondaryNavigation_project$ref = any;
type SecondaryNavigation_projectGroup$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectRoutes_viewer$ref: FragmentReference;
declare export opaque type ProjectRoutes_viewer$fragmentType: ProjectRoutes_viewer$ref;
export type ProjectRoutes_viewer = {|
  +actualPersonId: ?string,
  +project: ?{|
    +$fragmentRefs: SecondaryNavigation_project$ref
  |},
  +projectGroup: ?{|
    +$fragmentRefs: SecondaryNavigation_projectGroup$ref
  |},
  +$refType: ProjectRoutes_viewer$ref,
|};
export type ProjectRoutes_viewer$data = ProjectRoutes_viewer;
export type ProjectRoutes_viewer$key = {
  +$data?: ProjectRoutes_viewer$data,
  +$fragmentRefs: ProjectRoutes_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectId",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectGroupId",
      "type": "String"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectRoutes_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actualPersonId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "projectId"
        }
      ],
      "concreteType": "ProjectType",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SecondaryNavigation_project"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "projectGroupId"
        }
      ],
      "concreteType": "ProjectGroupType",
      "kind": "LinkedField",
      "name": "projectGroup",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SecondaryNavigation_projectGroup"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
// prettier-ignore
(node/*: any*/).hash = 'd1097cf0492017019ed119ae0ebd4801';

module.exports = node;
