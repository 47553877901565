import React from 'react';
import Util from '../../../../../util/util';
import UpdatePhaseMutation from '../../../../../../../mutations/update_phase_mutation';
import DatePicker from '../../../../date-picker/date_picker_v3';
import {DATE_PICKER_STYLE} from '../../../../../../../constants';
import {Column} from '../Elements.styled';
import {isDateDisabled} from '../../../../../util/DateUtil';

export const PhaseDatePickerColumn = ({phase, project, align, width, lazyDataFetched, disabled, empty, cy}) => {
	let startDate = Util.CreateNonUtcMomentDate(phase.startYear, phase.startMonth, phase.startDay);
	let endDate = Util.CreateNonUtcMomentDate(phase.deadlineYear, phase.deadlineMonth, phase.deadlineDay);
	let startFrom = phase.startFrom ? project.name : '';
	let endFrom = phase.deadlineFrom ? project.name : '';
	let isCalculated = false;

	if (project.useBaseline) {
		if (!phase.startYear) {
			startDate = Util.CreateNonUtcMomentDate(
				project.projectStartYear,
				project.projectStartMonth,
				project.projectStartDay
			);
			isCalculated = true;
		}
		if (!phase.deadlineYear) {
			endDate = Util.CreateNonUtcMomentDate(project.projectEndYear, project.projectEndMonth, project.projectEndDay);
			isCalculated = true;
		}
	}

	const clearDates = () => {
		const mutationObject = {
			id: phase.id,
			projectId: project.id,
			startYear: null,
			startMonth: null,
			startDay: null,
			deadlineYear: null,
			deadlineMonth: null,
			deadlineDay: null,
		};
		if (project.synchBaselineAndScopingDates) {
			mutationObject.baselineStartYear = null;
			mutationObject.baselineStartMonth = null;
			mutationObject.baselineStartDay = null;
			mutationObject.baselineDeadlineYear = null;
			mutationObject.baselineDeadlineMonth = null;
			mutationObject.baselineDeadlineDay = null;
		}
		Util.CommitMutation(UpdatePhaseMutation, mutationObject);
	};

	const handleDateRangeChange = (startDate, endDate) => {
		const mutationObject = {
			id: phase.id,
			projectId: project.id,
			startYear: startDate.year(),
			startMonth: startDate.month() + 1,
			startDay: startDate.date(),
			deadlineYear: endDate.year(),
			deadlineMonth: endDate.month() + 1,
			deadlineDay: endDate.date(),
		};
		if (project.synchBaselineAndScopingDates) {
			mutationObject.baselineStartYear = startDate.year();
			mutationObject.baselineStartMonth = startDate.month() + 1;
			mutationObject.baselineStartDay = startDate.date();
			mutationObject.baselineDeadlineYear = endDate.year();
			mutationObject.baselineDeadlineMonth = endDate.month() + 1;
			mutationObject.baselineDeadlineDay = endDate.date();
		}
		Util.CommitMutation(UpdatePhaseMutation, mutationObject);
	};

	if (empty) {
		return <div />;
	} else {
		return (
			<Column align={align} width={width} data-userpilot="date-picker-first">
				<DatePicker
					buttonCy={cy + '-date-button'}
					disabled={lazyDataFetched || disabled}
					id={phase.id}
					isNewDateRange={true}
					startDate={startDate}
					endDate={endDate}
					handleDateRangeChange={handleDateRangeChange}
					clearable={true}
					clearBothDates={clearDates}
					datePickerStyle={DATE_PICKER_STYLE.SCOPING_PHASE_DATE}
					colorInherited={true}
					compactShowYear={true}
					isCalculated={isCalculated}
					noPropagation={true}
					startFrom={startFrom}
					endFrom={endFrom}
					disabledDates={isDateDisabled}
				/>
			</Column>
		);
	}
};
