/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
query TimeRegistrationModalUpdate_Query(
  $timeRegistrationId: ID
) {
  viewer {
    actualPersonId
    component(name: "time_registration_modal_update")
    ...TimeRegistrationModalUpdate_viewer_1jzIbK
    id
  }
}

fragment TimeRegistrationModalUpdate_viewer_1jzIbK on Viewer {
  actualPersonId
  timeRegistration(id: $timeRegistrationId) {
    id
    taskId
    projectId
    idleTimeId
    minutesRegistered
    billableMinutesRegistered
    notes
    year
    month
    day
    person {
      id
    }
    harvestTask {
      id
    }
    role {
      id
      name
    }
    task {
      id
      jiraId
      project {
        id
      }
    }
  }
}
*/
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "timeRegistrationId",
            "type": "ID"
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actualPersonId",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": [
            {
                "kind": "Literal",
                "name": "name",
                "value": "time_registration_modal_update"
            }
        ],
        "kind": "ScalarField",
        "name": "component",
        "storageKey": "component(name:\"time_registration_modal_update\")"
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v4 = [
        (v3 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "TimeRegistrationModalUpdate_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "timeRegistrationId",
                                    "variableName": "timeRegistrationId"
                                }
                            ],
                            "kind": "FragmentSpread",
                            "name": "TimeRegistrationModalUpdate_viewer"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "TimeRegistrationModalUpdate_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "id",
                                    "variableName": "timeRegistrationId"
                                }
                            ],
                            "concreteType": "TimeRegType",
                            "kind": "LinkedField",
                            "name": "timeRegistration",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "taskId",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "projectId",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "idleTimeId",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "minutesRegistered",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "billableMinutesRegistered",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "notes",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "year",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "month",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "day",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Person",
                                    "kind": "LinkedField",
                                    "name": "person",
                                    "plural": false,
                                    "selections": (v4 /*: any*/),
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "HarvestTaskType",
                                    "kind": "LinkedField",
                                    "name": "harvestTask",
                                    "plural": false,
                                    "selections": (v4 /*: any*/),
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Role",
                                    "kind": "LinkedField",
                                    "name": "role",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "name",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Task",
                                    "kind": "LinkedField",
                                    "name": "task",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "jiraId",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ProjectType",
                                            "kind": "LinkedField",
                                            "name": "project",
                                            "plural": false,
                                            "selections": (v4 /*: any*/),
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v3 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "TimeRegistrationModalUpdate_Query",
            "operationKind": "query",
            "text": "query TimeRegistrationModalUpdate_Query(\n  $timeRegistrationId: ID\n) {\n  viewer {\n    actualPersonId\n    component(name: \"time_registration_modal_update\")\n    ...TimeRegistrationModalUpdate_viewer_1jzIbK\n    id\n  }\n}\n\nfragment TimeRegistrationModalUpdate_viewer_1jzIbK on Viewer {\n  actualPersonId\n  timeRegistration(id: $timeRegistrationId) {\n    id\n    taskId\n    projectId\n    idleTimeId\n    minutesRegistered\n    billableMinutesRegistered\n    notes\n    year\n    month\n    day\n    person {\n      id\n    }\n    harvestTask {\n      id\n    }\n    role {\n      id\n      name\n    }\n    task {\n      id\n      jiraId\n      project {\n        id\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = '7a722181e71f45afb69a062c2ac5d74b';
export default node;
