// @ts-check
import React, {useState} from 'react';
import styled from 'styled-components';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';
import Util from '../../util/util';
import {createToast} from '../toasts/toast';
import {convertWarningVariantToRagStatus, convertWarningVariantToStatusColor} from './StatusUtil';
import {WarningIcon} from 'web-components/icons';
import RichTextItem from '../../../../components/richtext_item';
import {
	convertRagStatusToWarningVariant,
	convertStatusColorToWarningVariant,
	StatusIndicator,
} from '../../../../containers/project/project_health/project_health_status/StatusIndicator';
import CreateProjectStatusMutation from '../../../../mutations/create_project_status_mutation';
import CreateProgramStatusMutation from '../../../../mutations/project-service/CreateProgramStatusMutation';
import {
	DeprecatedButton as Button,
	DeprecatedRichTextField as RichTextField,
	DeprecatedText as Text,
} from '@forecast-it/design-system';
import {SuggestedStatusRenderer} from './suggested_status/SuggestedStatusRenderer';

const TitleSuggestionWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const ProjectStatusModalRow = styled.div`
	display: flex;
	margin: 16px 0 8px 3px;
`;

// hack, apparently the editor decided to remove the option to position the toolbar below the input field in V5...
const EditorStyleOverride = styled.div`
	.ck-editor {
		display: flex;
		flex-direction: column-reverse;
		max-width: 456px;
		.ck-editor__editable {
			border-top-left-radius: 4px !important;
			border-top-right-radius: 4px !important;
			border-bottom-left-radius: 0 !important;
			border-bottom-right-radius: 0 !important;
		}
		.ck-toolbar {
			border-top-left-radius: 0 !important;
			border-top-right-radius: 0 !important;
			border-bottom-left-radius: 4px !important;
			border-bottom-right-radius: 4px !important;
		}
	}
	.ck-balloon-panel {
		z-index: 99999;
	}
`;

/**
 * @callback clearCallback
 * @returns {void}
 */

/**
 * @param {Object | null} selectedWarning
 * @param {string | undefined} description
 * @param {number} programId
 * @param {any} suggestedStatusVariant
 * @param {clearCallback} clearCallback
 */
const saveProgramStatus = (selectedWarning, description, programId, suggestedStatusVariant, clearCallback) => {
	const ragStatus = convertWarningVariantToRagStatus(selectedWarning);
	const onSuccess = () => {
		if (suggestedStatusVariant) {
			const suggestedRagStatus = convertWarningVariantToRagStatus(suggestedStatusVariant);
			trackEvent('Suggested Program Status', 'Feedback Given', {
				selectedColor: ragStatus,
				suggestedColor: suggestedRagStatus,
				suggestionSelected: ragStatus === suggestedRagStatus,
			});
		}
		createToast({message: 'Program status saved', duration: 3000});

		if (clearCallback) {
			clearCallback();
		}
	};
	Util.CommitMutation(
		CreateProgramStatusMutation,
		{
			programId: programId,
			ragStatus: ragStatus,
			description: description,
		},
		onSuccess
	);
};

/**
 * @param {Object | null} selectedWarning
 * @param {string | undefined} description
 * @param {number} projectId
 * @param {any} suggestedStatusVariant
 * @param {clearCallback} clearCallback
 */
const saveProjectStatus = (selectedWarning, description, projectId, suggestedStatusVariant, clearCallback) => {
	const color = convertWarningVariantToStatusColor(selectedWarning);
	const onSuccess = () => {
		if (suggestedStatusVariant) {
			const suggestedColor = convertWarningVariantToStatusColor(suggestedStatusVariant);
			trackEvent('Suggested Status', 'Feedback Given', {
				selectedColor: Util.getProjectStatusColorName(color),
				suggestedColor: Util.getProjectStatusColorName(suggestedColor),
				suggestionSelected: color === suggestedColor,
			});
		}
		createToast({message: 'Project status saved', duration: 3000});

		if (clearCallback) {
			clearCallback();
		}
	};
	Util.CommitSchedulingModalUpdate(
		CreateProjectStatusMutation,
		{
			projectId: projectId,
			color: color,
			description: description,
		},
		// @ts-ignore
		onSuccess
	);
};

const StatusSidebarUpdateSection = ({program, project, firstName}) => {
	const [suggestedStatusVariant, setSuggestedStatusVariant] = useState(null);
	const [selectedWarning, setSelectedWarning] = useState(WarningIcon.VARIANT.NO_WARNING);
	const [description, setDescription] = useState('');

	const suggestedStatusCallback = (suggestedStatusVariant, suggestedStatusText) => {
		setSuggestedStatusVariant(suggestedStatusVariant);
		setSelectedWarning(suggestedStatusVariant);
		setDescription(suggestedStatusText);
	};

	/**
	 * @type {clearCallback}
	 */
	const clearStatus = () => {
		setSelectedWarning(WarningIcon.VARIANT.NO_WARNING);
		setDescription('');
	};

	const handleProgramStatusSave = () => {
		saveProgramStatus(selectedWarning, description, program.id, suggestedStatusVariant, clearStatus);
	};

	const handleProjectStatusSave = () => {
		saveProjectStatus(selectedWarning, description, project.id, suggestedStatusVariant, clearStatus);
	};

	const currentStatusVariant = program
		? convertRagStatusToWarningVariant(program.currentProgramStatus?.ragStatus)
		: convertStatusColorToWarningVariant(project.currentProjectStatus?.color);

	return (
		<>
			<TitleSuggestionWrapper>
				<Text type={'heading'} variant={'m'}>
					Update Status
				</Text>
				{project?.isInProjectGroup ? null : (
					<SuggestedStatusRenderer
						projectId={project?.id}
						programPrefix={program?.prefix}
						currentStatusVariant={currentStatusVariant}
						callback={suggestedStatusCallback}
					/>
				)}
			</TitleSuggestionWrapper>

			<>
				<ProjectStatusModalRow>
					<StatusIndicator
						warning={WarningIcon.VARIANT.NO_WARNING}
						active={selectedWarning === WarningIcon.VARIANT.NO_WARNING}
						onSelect={() => setSelectedWarning(WarningIcon.VARIANT.NO_WARNING)}
					/>
					<StatusIndicator
						warning={WarningIcon.VARIANT.WARNING}
						active={selectedWarning === WarningIcon.VARIANT.WARNING}
						onSelect={() => setSelectedWarning(WarningIcon.VARIANT.WARNING)}
					/>
					<StatusIndicator
						warning={WarningIcon.VARIANT.DANGER}
						active={selectedWarning === WarningIcon.VARIANT.DANGER}
						onSelect={() => setSelectedWarning(WarningIcon.VARIANT.DANGER)}
					/>
				</ProjectStatusModalRow>
				{program ? (
					<EditorStyleOverride>
						<RichTextField
							limitations={[
								'BlockQuote',
								'Code',
								'CodeBlock',
								'Table',
								'TableToolbar',
								'Indent',
								'MediaEmbed',
								'ImageUpload',
							]}
							editor={'ClassicEditor'}
							onSaveHandler={desc => setDescription(desc)}
							data={description}
							toolbarLocation="bottom"
							placeholder={`How is the program going, ${firstName}?`}
						/>
					</EditorStyleOverride>
				) : project ? (
					// @ts-ignore
					<RichTextItem
						cy={'status-description'}
						projectLocked={false}
						alwaysShowControls={true}
						text={description}
						handleTextChange={desc => setDescription(desc)}
						placeholder={`How is the project going, ${firstName}?`}
						hideEmojiPicker
					/>
				) : null}
				<div style={{marginTop: '16px'}}>
					<Button
						type={'creative'}
						onClick={() => (program ? handleProgramStatusSave() : project ? handleProjectStatusSave() : null)}
					>
						Update Status
					</Button>
				</div>
			</>
		</>
	);
};

export default StatusSidebarUpdateSection;
