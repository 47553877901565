import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TooltipContainer from '../../tooltips/tooltip_container';
import {injectIntl} from 'react-intl';
class CSVButton extends Component {
	render() {
		return (
			<div className={'csv-wrapper'} key={`csv-export`}>
				<TooltipContainer
					infoText={this.props.disabled ? null : this.props.intl.formatMessage({id: 'common.export-csv'})}
				>
					<button
						className={'csv-button'}
						onClick={this.props.onClick}
						data-userpilot={'csv-button'}
						data-cy={this.props.cy}
						id={'csv-button'}
						disabled={this.props.disabled}
					/>
				</TooltipContainer>
			</div>
		);
	}
}
CSVButton.propTypes = {
	text: PropTypes.string,
	cy: PropTypes.string,
	onClick: PropTypes.func,
};
export default injectIntl(CSVButton);
