import React, {useMemo, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';
import {RadioButton, Switch} from 'web-components';
import UpdateCompanyMutation from '../../../mutations/update_company_mutation.modern';
import Util from '../../shared/util/util';
import {createToast} from '../../shared/components/toasts/another-toast/toaster';
import {CSS_CONSTANTS} from '../../../css_variables';
import {hasFeatureFlag} from '../../shared/util/FeatureUtil';
import {Button} from '@forecast-it/design-system';
import {useHistory} from 'react-router-dom';

export const InvoicingStyle = styled.div`
	padding-bottom: 33px;
	width: 550px;
`;
export const SwitchLabel = styled.div`
	font-size: 13px;
	margin: 0 0 0 8px;
`;
export const SwitchWrapper = styled.div`
	display: flex;
	flex-direction: row;
	margin: 16px 0 0 0;
	align-items: center;
`;

const Title = styled.div`
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 20px;
`;

const Section = styled.div`
	font-size: 13px;
	color: ${CSS_CONSTANTS.v2_text_gray};

	padding: 16px 24px;
	border: 1px solid ${CSS_CONSTANTS.v2_border_gray};
	border-radius: 4px;
	:not(:last-child) {
		margin-bottom: 40px;
	}
`;

const Description = styled.div`
	margin-bottom: 25px;
`;

const RadioButtonContainer = styled.div`
	:not(:last-child) {
		margin-bottom: 20px;
	}

	display: flex;
`;

const RadioButtonLabel = styled.div`
	margin-left: 10px;
`;

export const Invoicing = ({company, invoicingIntegrationEnabled}) => {
	const {formatMessage} = useIntl();
	const [invoiceQuantityDecimals, setInvoiceQuantityDecimals] = useState(company.invoiceQuantityDecimals);
	const billToCount = useMemo(
		() => company.billFroms?.reduce((count, current) => count + (current.billTos?.edges?.length || 0), 0),
		[company]
	);
	const history = useHistory();

	const changeCanUnlinkInvoice = arg => {
		const onSuccess = () => {
			createToast({
				duration: 5000,
				message: formatMessage({id: 'settings_finance.invoicing.toast_text'}),
			});
		};

		Util.CommitMutation(
			UpdateCompanyMutation,
			{
				userCanUnlinkInvoice: !arg,
			},
			onSuccess
		);
	};

	const handleInvoiceQuantityDecimalsChange = decimals => {
		const onSuccess = () => {
			createToast({
				duration: 3000,
				message: formatMessage({id: 'common.changes_saved'}),
			});
			setInvoiceQuantityDecimals(decimals);
		};

		Util.CommitMutation(
			UpdateCompanyMutation,
			{
				invoiceQuantityDecimals: decimals,
			},
			onSuccess
		);
	};

	const goToBillTo = () => {
		history.push('/admin/finance/bill-to');
	};

	return (
		<InvoicingStyle data-userpilot={'invoicing-section'}>
			{hasFeatureFlag('invoicing_pathways') && invoicingIntegrationEnabled && (
				<Section>
					<Title>{formatMessage({id: 'settings_finance.bill_to_entities_title'})}</Title>
					<Description>
						{formatMessage({id: 'settings_finance.bill_to_entities_description'}, {count: billToCount})}
					</Description>
					<Button emphasis={'medium'} onClick={goToBillTo}>
						<FormattedMessage id={'settings_finance.manage_bill_to_entities'} />
					</Button>
				</Section>
			)}
			<Section>
				<Title>{formatMessage({id: 'settings_finance.decimal_rounding_title'})}</Title>
				<Description>{formatMessage({id: 'settings_finance.decimal_rounding_description'})}</Description>
				<RadioButtonContainer>
					<RadioButton
						checked={invoiceQuantityDecimals === 2}
						onClick={() => handleInvoiceQuantityDecimalsChange(2)}
					/>
					<RadioButtonLabel>{formatMessage({id: 'settings_finance.2_decimals_label'})}</RadioButtonLabel>
				</RadioButtonContainer>
				<RadioButtonContainer>
					<RadioButton
						checked={invoiceQuantityDecimals === 4}
						onClick={() => handleInvoiceQuantityDecimalsChange(4)}
					/>
					<RadioButtonLabel>{formatMessage({id: 'settings_finance.4_decimals_label'})}</RadioButtonLabel>
				</RadioButtonContainer>
			</Section>
			{invoicingIntegrationEnabled && (
				<Section>
					<Title>{formatMessage({id: 'settings_finance.invoice_unlinking'})}</Title>
					<Description>{formatMessage({id: 'settings_finance.invoicing_description'})}</Description>
					<SwitchWrapper>
						<Switch checked={company.integrations.userCanUnlinkInvoice} onChange={changeCanUnlinkInvoice} />
						<SwitchLabel>
							<FormattedMessage id="common.enable" />
						</SwitchLabel>
					</SwitchWrapper>
				</Section>
			)}
		</InvoicingStyle>
	);
};
