import Util from '../../../util/util';
import {getGranularity} from '../../../util/cache/TimeRegistrationSettingsUtil';
import {Granularity} from '../../../../admin-tab/time-management/constants/Granularity';

export const isValidValue = (formattedValue, minuteLimit) => {
	/*
	 * A valid value is an empty string or after formatting a number (int/float) which is below the minuteLimit for the input
	 */
	return typeof formattedValue === 'number' && (minuteLimit ? formattedValue <= minuteLimit : true);
};

function validateAndFormatInput(minutes, minuteLimit, intl, showAsterisk, val) {
	const res = {
		float: null,
		full: null,
		error: false,
	};

	if (isValidValue(minutes, minuteLimit)) {
		res.float = minutes / 60;
		res.full = Util.convertMinutesToFullHour(minutes, intl, true);
		res.full += showAsterisk ? ' *' : '';
	} else if (val === null || val === undefined || val === '') {
		res.float = null;
		res.full = '';
	} else {
		res.float = val;
		res.full = val;
		res.error = true;
	}

	return res;
}

/*
 * Float Hour Format --> 1,5h / 1.5h
 * Clock Hour Format --> 1:30
 * Common Hour Format --> 1h 30m
 * Long Common Hour Format --> 1hour 30min
 */

/*
 * This takes a string as entry (val) and return a float (1.5) of the value and the formatted value (1.5h):
 * val		--> {float, full}
 * "abc"	--> {null, "abc"} 	--> result as an error
 * " " 		--> {0, ''} 		--> result as en empty input
 * null		--> {0, ''} 		--> result as en empty input
 * "0"		--> {0, ''} 		--> result as en empty input
 * "1h30m" 	--> {1.5, "1.5h"}
 */
export const convertIntoFloatHoursFormat = (val, intl, minuteLimit, showAsterisk, isTimeRegInput = false) => {
	if (typeof val === 'string') {
		val = val.trim();
	}

	const minutes = Util.convertTimeInputToMinutes(val, isTimeRegInput);

	return validateAndFormatInput(minutes, minuteLimit, intl, showAsterisk, val);
};

const roundTimeInputToCompanyGranularity = (timeInput, timeGranularity, workingMinutesToday) => {
	if (timeInput !== null) {
		switch (timeGranularity) {
			case Granularity.Minutes:
				return timeInput;
			case Granularity.Quarters:
				return Math.ceil(timeInput / 15) * 15;
			case Granularity.Hours:
				return Math.ceil(timeInput / 60) * 60;
			case Granularity.Days:
				// If the user is entering time on a day without working hours we return the input as is otherwise we use actual work hours
				const minutesToday = workingMinutesToday ? workingMinutesToday : 1;
				return Math.ceil(timeInput / minutesToday) * minutesToday;
			default:
				return timeInput;
		}
	}
};

function convertDaysInputToMinutes(val, workingMinutesToday) {
	const days = parseFloat(val.replace('d', '').replace(',', '.'));
	return Math.ceil(days) * workingMinutesToday;
}

export const convertIntoMinutesWithGranularity = (val, workingMinutesToday, isTimeRegInput = false) => {
	if (typeof val === 'string') {
		val = val.trim();
	}

	const timeGranularity = getGranularity();

	if (timeGranularity === Granularity.Days && typeof val === 'string' && val.includes('d')) {
		return convertDaysInputToMinutes(val, workingMinutesToday);
	} else {
		const minutes = Util.convertTimeInputToMinutes(val, isTimeRegInput);
		return roundTimeInputToCompanyGranularity(minutes, timeGranularity, workingMinutesToday);
	}
};

export const convertIntoFloatHoursFormatWithGranularity = (val, intl, minuteLimit, showAsterisk, workingMinutesToday) => {
	const minutes = convertIntoMinutesWithGranularity(val, workingMinutesToday, true);

	return validateAndFormatInput(minutes, minuteLimit, intl, showAsterisk, val);
};

// No change, do nothing
export const noChange = (a, b, intl) => {
	const aVal = convertIntoFloatHoursFormat(a, intl).float;
	const bVal = convertIntoFloatHoursFormat(b, intl).float;

	// We always want mutation to be fired if user only inputs 0 and nothing else
	return aVal === bVal;
};
