import React, {createContext, useEffect} from 'react';
import {withSimpleSocketHandling} from '../../../../socket/withSimpleSocketHandling';
import {createFragmentContainer, graphql} from 'react-relay';
import {useRecentPrograms} from '../hooks/useRecentPrograms';
import {SOCKET_ACTION, SOCKET_EVENT_TYPE} from '../../../../constants';
import Util from '../../../shared/util/util';

export const ProgramContext = createContext();

const ProgramContextProvider = ({children, viewer, setSocketConfig, retry}) => {
	const company = viewer.company;
	const program = viewer.program;
	const allPrograms = viewer.company.programs.edges.map(program => program.node);
	const actualPersonId = viewer.actualPersonId;
	let projects = [];

	if (program) {
		projects = viewer.program.projects.edges.map(project => project.node).filter(project => project !== null);

		// Add program to recent list
		let recentPrograms = useRecentPrograms();
		useEffect(() => {
			recentPrograms.programVisited(program.id);
		}, [program.id]);

		const programId = Util.getUUIdFromBase64String(program.id);
		const projectsIds = projects.map(project => Util.getUUIdFromBase64String(project.id));
		const socketEvents = [
			{
				type: SOCKET_EVENT_TYPE.PROGRAM,
				action: SOCKET_ACTION.UPDATE,
				entityIds: programId,
			},
			{
				type: SOCKET_EVENT_TYPE.PROGRAM,
				action: SOCKET_ACTION.DELETE,
				entityIds: programId,
			},
			{
				type: SOCKET_EVENT_TYPE.V2_PROJECT,
				action: SOCKET_ACTION.UPDATE,
				entityIds: projectsIds,
			},
			{
				type: SOCKET_EVENT_TYPE.V2_PROJECT,
				action: SOCKET_ACTION.DELETE,
				entityIds: projectsIds,
			},
		];
		setSocketConfig(socketEvents);
	}

	return (
		<ProgramContext.Provider value={{program, projects, allPrograms, company, actualPersonId, retry}}>
			{children}
		</ProgramContext.Provider>
	);
};

export const getProjectIdentifier = projectServiceProject => {
	return projectServiceProject?.legacyProjectAsSingleProject?.customProjectId || projectServiceProject?.prefix;
};

const ProgramContextProviderQuery = graphql`
	query ProgramContextProvider_Query($prefix: String) {
		viewer {
			actualPersonId
			component(name: "program_context_provider")
			...ProgramContextProvider_viewer @arguments(prefix: $prefix)
		}
	}
`;

export {ProgramContextProviderQuery};

export default withSimpleSocketHandling(
	createFragmentContainer(ProgramContextProvider, {
		viewer: graphql`
			fragment ProgramContextProvider_viewer on Viewer @argumentDefinitions(prefix: {type: "String"}) {
				id
				backendId
				actualPersonId
				program(prefix: $prefix) {
					id
					name
					prefix
					description
					color
					startDate
					endDate
					budgetType
					budgetValue
					totalAllocation
					isRevenueLocked
					revenueSetting
					clients(first: 10000) {
						edges {
							node {
								id
								name
								logoId
							}
						}
					}
					currentProgramStatus {
						ragStatus
						...StatusWithDescription_programStatus
					}
					stage {
						name
					}
					createdAt
					createdBy {
						person {
							id
							fullName
						}
					}
					projects(first: 10000) @connection(key: "Program_projects", filters: []) {
						edges {
							node {
								id
								serviceCompanyId
								companyProjectId
								legacyProjectAsSingleProject {
									id
									customProjectId
								}
								name
								prefix
								color
								startDate
								endDate
								stage
								budgetType
								budget
								defaultPeriodBudgetType
								clients {
									edges {
										node {
											id
											name
											logoId
										}
									}
								}
								status {
									edges {
										node {
											id
											description
											color
											addedAt
										}
									}
								}
								currentProjectStatus {
									id
									color
									description
									person {
										id
										firstName
										lastName
										profilePictureId
									}
								}
								financialNumbers {
									allActualTimeAndExpenses
									recognitionProfitPercentage
									allTotalTimeAndExpensesAtCompletion
									forecastCostToComplete
									totalCostAtCompletion
								}
								financialNumbersToDate {
									recognitionActualProfitPercentage
									allActualTimeAndExpenses
								}
								totalRevenueRecognition
								totalRevenueRecognitionToDate
								totalTimeRevenueRecognitionToDate
								totalBillableExpensesAtCompletion
								settings {
									edges {
										node {
											id
											name
											value
										}
									}
								}
								userRelationTypes
							}
						}
					}
					projectsFinancialsTotals {
						recognitionForecastRevenue # Revenue - Recognition at completion
						allActualTimeAndExpenses # Spend - Actual to date
						allTotalTimeAndExpensesAtCompletion # Spend - Total at completion
						recognitionProfitPercentage # Profit margin - total at completion
						recognitionActualProfitPercentage # Profit margin - total at completion
					}
					projectsFinancialsToDate {
						totalActualRevenueRecognition # Revenue - recognised to date
						recognitionAmount # Revenue - recognised to date
						recognitionActualProfitPercentage # Profit margin - actual to date
						allActualTimeAndExpenses # Spend - actual to date
					}
					userRelationTypes
					members(first: 10000) @connection(key: "Program_members", filters: []) {
						edges {
							node {
								id
								role
								person {
									id
									profilePictureId
									fullName
									email
									initials
								}
							}
						}
					}
				}
				company {
					id
					currency
					programs(first: 10000) @connection(key: "Company_programs", filters: []) {
						edges {
							node {
								id
								name
								description
								prefix
								color
								startDate
								endDate
								budgetType
								members(first: 10000) @connection(key: "Program_members", filters: []) {
									edges {
										node {
											id
											role
											person {
												id
											}
										}
									}
								}
							}
						}
					}
					allPersons(first: 10000, excludeClientUsers: true) {
						edges {
							node {
								id
								supressProgramBudgetTypeTipMessage
							}
						}
					}
					clients(first: 10000) {
						edges {
							node {
								id
								name
								logoId
								logoDefaultId
							}
						}
					}
					programStages(first: 10000) @connection(key: "Company_programStages", filters: []) {
						edges {
							node {
								id
								name
								type
							}
						}
					}
				}
			}
		`,
	})
);
