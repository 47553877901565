import React, {memo, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {formatSearchResult} from './formatters/SearchResultFormatter';
import SearchResultRow, {SEARCH_RESULT_VARIANT} from './SearchResultRow';
import SearchResultContextMenu from './SearchResultRowContextMenu';
import styled from 'styled-components';

const SearchResultContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${({reducedGap}) => (reducedGap ? '8px' : '16px')};
`;

const SearchResults = memo(
	({
		searchResults,
		allowLinks,
		focus,
		setFocus,
		indexOffset,
		handleRecentSelected,
		trackAndHideSearch,
		searchResultSection,
		showTaskIndicator,
	}) => {
		const [contextMenuItem, setContextMenuItem] = useState(null);
		const [bounds, setBounds] = useState({});

		const topRef = useRef(null);

		const formattedSearchResults = useMemo(
			() => searchResults.map(result => formatSearchResult(result, handleRecentSelected, trackAndHideSearch)),
			[searchResults]
		);

		const showContextMenu = useCallback(
			(e, index, link, onRowSelect) => {
				setContextMenuItem({index, link, onRowSelect});
				setBounds({x: e.pageX, y: e.pageY});
			},
			[searchResults]
		);

		const closeContextMenu = useCallback(
			index => {
				if (contextMenuItem) {
					setContextMenuItem(null);
					setBounds({});
					setFocus(index || 0);
				}
			},
			[contextMenuItem, searchResults]
		);

		useEffect(() => {
			topRef.current?.scrollIntoView();
		}, [searchResults]);
		return (
			<>
				<div ref={topRef}></div>
				<SearchResultContainer reducedGap={searchResultSection === SEARCH_RESULT_VARIANT.RECENT}>
					{formattedSearchResults.map((searchResult, index) => {
						const roveIndex = index + indexOffset + 1; // incremented by 1 because searchInput is part of rove focus
						const key = index + searchResult.name;
						return (
							<React.Fragment key={key}>
								<SearchResultRow
									result={searchResult}
									allowLinks={allowLinks}
									index={roveIndex}
									focus={focus === roveIndex}
									setFocus={setFocus}
									isContextMenuItem={roveIndex === contextMenuItem?.index}
									showContextMenu={showContextMenu}
									showTaskIndicator={showTaskIndicator}
								/>
							</React.Fragment>
						);
					})}
				</SearchResultContainer>
				{contextMenuItem ? (
					<SearchResultContextMenu
						closeContextMenu={closeContextMenu}
						contextMenuItem={contextMenuItem}
						bounds={bounds}
					/>
				) : null}
			</>
		);
	}
);

export default SearchResults;
