/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type settingsTeams_viewer$ref = any;
export type settingsTeams_QueryVariables = {||};
export type settingsTeams_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: settingsTeams_viewer$ref,
  |}
|};
export type settingsTeams_Query = {|
  variables: settingsTeams_QueryVariables,
  response: settingsTeams_QueryResponse,
|};
*/


/*
query settingsTeams_Query {
  viewer {
    actualPersonId
    component(name: "settings_teams")
    ...settingsTeams_viewer
    id
  }
}

fragment SettingsResourcesSubNav_viewer on Viewer {
  email
}

fragment settingsTeams_viewer on Viewer {
  ...SettingsResourcesSubNav_viewer
  availableFeatureFlags {
    key
    id
  }
  company {
    id
    teams(first: 10000) {
      edges {
        node {
          id
          name
          teamPersons(first: 10000) {
            edges {
              node {
                person {
                  id
                  fullName
                  firstName
                  lastName
                  email
                  profilePictureId
                  profilePictureDefaultId
                  role {
                    name
                    id
                  }
                  isViewer
                }
                id
                __typename
              }
              cursor
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          }
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    allPersons(first: 10000, excludeClientUsers: true) {
      edges {
        node {
          id
          fullName
          firstName
          lastName
          email
          profilePictureId
          profilePictureDefaultId
          role {
            name
            id
          }
          isViewer
          active
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "settings_teams"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"settings_teams\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "kind": "Literal",
  "name": "first",
  "value": 10000
},
v5 = [
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePictureId",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePictureDefaultId",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Role",
  "kind": "LinkedField",
  "name": "role",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isViewer",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "settingsTeams_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "settingsTeams_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "settingsTeams_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FeatureFlag",
            "kind": "LinkedField",
            "name": "availableFeatureFlags",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "TeamTypeConnection",
                "kind": "LinkedField",
                "name": "teams",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TeamTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Team",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": (v5/*: any*/),
                            "concreteType": "TeamPersonTypeConnection",
                            "kind": "LinkedField",
                            "name": "teamPersons",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "TeamPersonTypeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "TeamPerson",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Person",
                                        "kind": "LinkedField",
                                        "name": "person",
                                        "plural": false,
                                        "selections": [
                                          (v3/*: any*/),
                                          (v7/*: any*/),
                                          (v8/*: any*/),
                                          (v9/*: any*/),
                                          (v2/*: any*/),
                                          (v10/*: any*/),
                                          (v11/*: any*/),
                                          (v12/*: any*/),
                                          (v13/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      (v3/*: any*/),
                                      (v14/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v15/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v16/*: any*/)
                            ],
                            "storageKey": "teamPersons(first:10000)"
                          },
                          {
                            "alias": null,
                            "args": (v5/*: any*/),
                            "filters": null,
                            "handle": "connection",
                            "key": "Team_teamPersons",
                            "kind": "LinkedHandle",
                            "name": "teamPersons"
                          },
                          (v14/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v16/*: any*/)
                ],
                "storageKey": "teams(first:10000)"
              },
              {
                "alias": null,
                "args": (v5/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "Company_teams",
                "kind": "LinkedHandle",
                "name": "teams"
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "excludeClientUsers",
                    "value": true
                  },
                  (v4/*: any*/)
                ],
                "concreteType": "PersonTypeConnection",
                "kind": "LinkedField",
                "name": "allPersons",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PersonTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Person",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v2/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "active",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "allPersons(excludeClientUsers:true,first:10000)"
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "settingsTeams_Query",
    "operationKind": "query",
    "text": "query settingsTeams_Query {\n  viewer {\n    actualPersonId\n    component(name: \"settings_teams\")\n    ...settingsTeams_viewer\n    id\n  }\n}\n\nfragment SettingsResourcesSubNav_viewer on Viewer {\n  email\n}\n\nfragment settingsTeams_viewer on Viewer {\n  ...SettingsResourcesSubNav_viewer\n  availableFeatureFlags {\n    key\n    id\n  }\n  company {\n    id\n    teams(first: 10000) {\n      edges {\n        node {\n          id\n          name\n          teamPersons(first: 10000) {\n            edges {\n              node {\n                person {\n                  id\n                  fullName\n                  firstName\n                  lastName\n                  email\n                  profilePictureId\n                  profilePictureDefaultId\n                  role {\n                    name\n                    id\n                  }\n                  isViewer\n                }\n                id\n                __typename\n              }\n              cursor\n            }\n            pageInfo {\n              endCursor\n              hasNextPage\n            }\n          }\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    allPersons(first: 10000, excludeClientUsers: true) {\n      edges {\n        node {\n          id\n          fullName\n          firstName\n          lastName\n          email\n          profilePictureId\n          profilePictureDefaultId\n          role {\n            name\n            id\n          }\n          isViewer\n          active\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ac6d00d8f6626d068e4af4eb95bf4a0c';

module.exports = node;
