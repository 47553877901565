var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Dropdown as DropdownWebComponent } from 'web-components';
import Dropdown from '../../shared/components/dropdowns/dropdown';
import { BUTTON_COLOR, BUTTON_STYLE } from '../../../constants';
import { MODAL_TYPE, showModal } from '../../shared/components/modals/generic_modal_conductor';
import DirectApi from '../../../directApi';
import { getSalesforceInstanceUrl } from './client-js/settings_clients_util.js';
import Util from '../../shared/util/util';
import UpdateClientMutation from '../../../mutations/update_client_mutation';
import Warning from '../../../components/warning';
import InlineLoader from '../../shared/components/inline-loader/inline_loader';
const SettingsClientIntegrations = ({ viewer, isProjectClientPage }) => {
    var _a, _b, _c, _d;
    const intl = useIntl();
    const { formatMessage } = intl;
    const { client } = viewer.company || {};
    let fetchTimeout = null;
    const [integrationLoading, setIntegrationLoading] = useState(false);
    const [salesforceCustomerOptions, setSalesforceCustomerOptions] = useState([]);
    const [hubspotCompanyOptions, setHubspotCompanyOptions] = useState([]);
    const [pipedriveOrganizationOptions, setPipedriveOrganizationOptions] = useState([]);
    const [sageIntacctContactOptions, setSageIntacctContactOptions] = useState([]);
    const [salesforceInstanceUrl, setSalesforceInstanceUrl] = useState('');
    const [searchingCustomers, setSearchingCustomers] = useState(false);
    const fetchSageIntacctCustomers = () => {
        var _a;
        if ((_a = viewer.company) === null || _a === void 0 ? void 0 : _a.sageIntacctConnectionEnabled) {
            setIntegrationLoading(true);
            DirectApi.Fetch('sage_intacct/unimported_customers').then(res => {
                const options = !(res === null || res === void 0 ? void 0 : res.customers)
                    ? []
                    : res.customers
                        .sort((a, b) => {
                        if (a.NAME.toUpperCase().trim() > b.NAME.toUpperCase().trim()) {
                            return 1;
                        }
                        else if (a.NAME.toUpperCase().trim() < b.NAME.toUpperCase().trim()) {
                            return -1;
                        }
                        return 0;
                    })
                        .map(customer => ({ value: parseInt(customer.CUSTOMERID), label: `${customer.NAME}` }));
                setSageIntacctContactOptions(options);
                setIntegrationLoading(false);
            });
        }
    };
    const fetchPipedriveOrganizations = () => {
        var _a;
        if (!((_a = viewer.company) === null || _a === void 0 ? void 0 : _a.pipedriveCompanyDomain))
            return;
        else {
            setIntegrationLoading(true);
            DirectApi.Fetch(`pipedrive/unimportedOrganizations`).then(organizations => {
                const options = !Array.isArray(organizations)
                    ? []
                    : organizations
                        .sort((a, b) => (a.name.toUpperCase().trim() > b.name.toUpperCase().trim() ? 1 : 0))
                        .map(company => ({
                        value: company.id.toString(),
                        label: company.name,
                    }));
                setPipedriveOrganizationOptions(options);
                setIntegrationLoading(false);
            });
        }
    };
    const fetchSalesforceCustomers = () => {
        var _a;
        if ((_a = viewer.company) === null || _a === void 0 ? void 0 : _a.newSalesforceEnabled) {
            setIntegrationLoading(true);
            DirectApi.Fetch('salesforce/unimported_accounts').then(accounts => {
                if (accounts === null || accounts === void 0 ? void 0 : accounts.length) {
                    const options = accounts.list
                        .sort((a, b) => (a.Name.toUpperCase().trim() > b.Name.toUpperCase().trim() ? 1 : -1))
                        .map(account => ({
                        value: account.Id,
                        label: account.Name,
                    }));
                    setSalesforceCustomerOptions(options);
                    setIntegrationLoading(false);
                }
            });
        }
    };
    const fetchSalesforceInstanceUrl = () => __awaiter(void 0, void 0, void 0, function* () {
        var _e;
        if ((_e = viewer.company) === null || _e === void 0 ? void 0 : _e.newSalesforceEnabled) {
            setIntegrationLoading(true);
            const instanceUrl = yield getSalesforceInstanceUrl();
            setSalesforceInstanceUrl(instanceUrl);
            setIntegrationLoading(false);
        }
    });
    const linkHubspotCompany = option => {
        const callback = () => {
            if (option) {
                Util.CommitMutation(UpdateClientMutation, {
                    id: client === null || client === void 0 ? void 0 : client.id,
                    //option is an array with one element
                    hubspotCompanyId: option[0],
                });
            }
        };
        const foundOption = hubspotCompanyOptions.find(hubspotOption => hubspotOption.value === option[0]);
        const optionlabel = foundOption && foundOption.label;
        showModal({
            type: MODAL_TYPE.WARNING,
            warningMessageId: 'settings.client.link_client',
            warningInformation: [
                React.createElement("div", { className: "link-client-modal-content" },
                    React.createElement(FormattedMessage, { id: "settings.client.confirm_link_customer", values: { linkName: optionlabel, b: chunks => React.createElement("strong", null, chunks) } })),
            ],
            useInfoIcon: true,
            buttons: [
                {
                    text: React.createElement(FormattedMessage, { id: "common.cancel" }),
                    style: BUTTON_STYLE.FILLED,
                    color: BUTTON_COLOR.WHITE,
                },
                {
                    text: React.createElement(FormattedMessage, { id: "common.link" }),
                    style: BUTTON_STYLE.FILLED,
                    color: BUTTON_COLOR.GREEN,
                    callback: callback,
                },
            ],
        });
    };
    const unlinkHubspotCustomer = () => {
        const { formatMessage } = intl;
        showModal({
            type: MODAL_TYPE.GENERIC,
            className: 'default-warning-modal',
            content: React.createElement(Warning, { messageId: "settings.client.hubspot_unlink" }),
            buttons: [
                {
                    text: formatMessage({ id: 'common.cancel' }),
                    style: BUTTON_STYLE.FILLED,
                    color: BUTTON_COLOR.WHITE,
                },
                {
                    text: formatMessage({ id: 'common.unlink' }),
                    style: BUTTON_STYLE.FILLED,
                    color: BUTTON_COLOR.RED,
                    callback: () => {
                        Util.CommitMutation(UpdateClientMutation, {
                            id: client === null || client === void 0 ? void 0 : client.id,
                            hubspotCompanyId: null,
                        });
                    },
                },
            ],
        });
    };
    const linkPipedriveOrganization = option => {
        const callback = () => {
            if (option) {
                Util.CommitMutation(UpdateClientMutation, {
                    id: client === null || client === void 0 ? void 0 : client.id,
                    pipedriveOrganizationId: option.value,
                });
            }
        };
        showModal({
            type: MODAL_TYPE.WARNING,
            warningMessageId: 'settings.client.link_client',
            warningInformation: [
                React.createElement("div", { className: "link-client-modal-content" },
                    React.createElement(FormattedMessage, { id: "settings.client.confirm_link_customer", values: { linkName: option.label, b: chunks => React.createElement("strong", null, chunks) } })),
            ],
            useInfoIcon: true,
            buttons: [
                {
                    text: React.createElement(FormattedMessage, { id: "common.cancel" }),
                    style: BUTTON_STYLE.FILLED,
                    color: BUTTON_COLOR.WHITE,
                },
                {
                    text: React.createElement(FormattedMessage, { id: "common.link" }),
                    style: BUTTON_STYLE.FILLED,
                    color: BUTTON_COLOR.GREEN,
                    callback: callback,
                },
            ],
        });
    };
    const unlinkPipedriveOrganization = () => {
        const { formatMessage } = intl;
        showModal({
            type: MODAL_TYPE.GENERIC,
            className: 'default-warning-modal',
            content: React.createElement(Warning, { messageId: "settings.client.pipedrive_unlink" }),
            buttons: [
                {
                    text: formatMessage({ id: 'common.cancel' }),
                    style: BUTTON_STYLE.FILLED,
                    color: BUTTON_COLOR.WHITE,
                },
                {
                    text: formatMessage({ id: 'common.unlink' }),
                    style: BUTTON_STYLE.FILLED,
                    color: BUTTON_COLOR.RED,
                    callback: () => {
                        Util.CommitMutation(UpdateClientMutation, {
                            id: client === null || client === void 0 ? void 0 : client.id,
                            pipedriveOrganizationId: null,
                        });
                    },
                },
            ],
        });
    };
    const unlinkSageIntacctCustomer = () => {
        const { formatMessage } = intl;
        showModal({
            type: MODAL_TYPE.GENERIC,
            className: 'default-warning-modal',
            content: React.createElement(Warning, { messageId: "settings.client.sage_intacct_unlink" }),
            buttons: [
                {
                    text: formatMessage({ id: 'common.cancel' }),
                    style: BUTTON_STYLE.FILLED,
                    color: BUTTON_COLOR.WHITE,
                },
                {
                    text: formatMessage({ id: 'common.unlink' }),
                    style: BUTTON_STYLE.FILLED,
                    color: BUTTON_COLOR.RED,
                    callback: () => {
                        Util.CommitMutation(UpdateClientMutation, {
                            id: client === null || client === void 0 ? void 0 : client.id,
                            sageIntacctCustomerId: null,
                        });
                    },
                },
            ],
        });
    };
    const linkSageIntacctCustomer = option => {
        const callback = () => {
            if (option) {
                Util.CommitMutation(UpdateClientMutation, {
                    id: client === null || client === void 0 ? void 0 : client.id,
                    sageIntacctCustomerId: option.value.toString(),
                });
            }
        };
        showModal({
            type: MODAL_TYPE.WARNING,
            warningMessageId: 'settings.client.link_client',
            warningInformation: [
                React.createElement("div", { className: "link-client-modal-content" },
                    React.createElement(FormattedMessage, { id: "settings.client.confirm_link_customer", values: { linkName: option.label, b: chunks => React.createElement("strong", null, chunks) } })),
            ],
            useInfoIcon: true,
            buttons: [
                {
                    text: React.createElement(FormattedMessage, { id: "common.cancel" }),
                    style: BUTTON_STYLE.FILLED,
                    color: BUTTON_COLOR.WHITE,
                },
                {
                    text: React.createElement(FormattedMessage, { id: "common.link" }),
                    style: BUTTON_STYLE.FILLED,
                    color: BUTTON_COLOR.GREEN,
                    callback: callback,
                },
            ],
        });
    };
    const linkSalesforceCustomer = option => {
        const callback = () => {
            if (option) {
                Util.CommitMutation(UpdateClientMutation, {
                    id: client === null || client === void 0 ? void 0 : client.id,
                    salesforceAccountId: option.value,
                });
            }
        };
        showModal({
            type: MODAL_TYPE.WARNING,
            warningMessageId: 'settings.client.link_client',
            warningInformation: [
                React.createElement("div", { className: "link-client-modal-content" },
                    React.createElement(FormattedMessage, { id: "settings.client.confirm_link_customer", values: { linkName: option.label, b: chunks => React.createElement("strong", null, chunks) } })),
            ],
            useInfoIcon: true,
            buttons: [
                {
                    text: React.createElement(FormattedMessage, { id: "common.cancel" }),
                    style: BUTTON_STYLE.FILLED,
                    color: BUTTON_COLOR.WHITE,
                },
                {
                    text: React.createElement(FormattedMessage, { id: "common.link" }),
                    style: BUTTON_STYLE.FILLED,
                    color: BUTTON_COLOR.GREEN,
                    callback: callback,
                },
            ],
        });
    };
    const unlinkSalesforceCustomer = () => {
        const { formatMessage } = intl;
        showModal({
            type: MODAL_TYPE.GENERIC,
            className: 'default-warning-modal',
            content: React.createElement(Warning, { messageId: "settings.client.salesforce_unlink" }),
            buttons: [
                {
                    text: formatMessage({ id: 'common.cancel' }),
                    style: BUTTON_STYLE.FILLED,
                    color: BUTTON_COLOR.WHITE,
                },
                {
                    text: formatMessage({ id: 'common.unlink' }),
                    style: BUTTON_STYLE.FILLED,
                    color: BUTTON_COLOR.RED,
                    callback: () => {
                        Util.CommitMutation(UpdateClientMutation, {
                            id: client === null || client === void 0 ? void 0 : client.id,
                            salesforceAccountId: null,
                        });
                    },
                },
            ],
        });
    };
    const getHubspotCompanies = searchInput => {
        var _a;
        if (!((_a = viewer.company) === null || _a === void 0 ? void 0 : _a.hubspotAccountId) || !searchInput) {
            setHubspotCompanyOptions([]);
            setIntegrationLoading(false);
            setSearchingCustomers(false);
            return;
        }
        else {
            setIntegrationLoading(true);
            DirectApi.Fetch(`hubspot/unimportedCompanies${searchInput ? `?search=${searchInput}` : ''}`).then(companies => {
                const options = !companies.list
                    ? []
                    : companies.list
                        .sort((a, b) => a.properties.name.toUpperCase().trim() > b.properties.name.toUpperCase().trim() ? 1 : 0)
                        .map(company => ({ value: company.id, label: company.properties.name }));
                setHubspotCompanyOptions(options);
                setIntegrationLoading(false);
                setSearchingCustomers(false);
            });
        }
    };
    const getHubspotCompaniesWithTimeout = newSearchString => {
        //send the request to hubspot service 1 second and a half after the user is done typing
        if (fetchTimeout) {
            clearTimeout(fetchTimeout);
        }
        setSearchingCustomers(true);
        fetchTimeout = setTimeout(() => {
            getHubspotCompanies(newSearchString);
        }, 1000);
    };
    useEffect(() => {
        fetchSageIntacctCustomers();
        // getHubspotCompanies('');
        fetchSalesforceCustomers();
        fetchSalesforceInstanceUrl();
        fetchPipedriveOrganizations();
    }, []);
    return (React.createElement("div", { className: "client-page settings-app" },
        !isProjectClientPage && ((_a = viewer.company) === null || _a === void 0 ? void 0 : _a.sageIntacctConnectionEnabled) ? (React.createElement("fieldset", { className: "client-integrations" },
            React.createElement("div", { className: "top-section" },
                React.createElement("h2", null, "Sage Intacct")),
            React.createElement("p", { className: "description" }, (client === null || client === void 0 ? void 0 : client.sageIntacctCustomerId) ? (React.createElement(React.Fragment, null,
                React.createElement(FormattedMessage, { id: "settings.client.sage_intacct_customer_linked", values: {
                        a: chunks => (React.createElement("a", null, chunks)),
                    } }),
                React.createElement("div", null,
                    React.createElement(Button, { onClick: unlinkSageIntacctCustomer.bind(this), size: Button.SIZE.STANDARD, variant: Button.VARIANT.RED_FILLED },
                        React.createElement(FormattedMessage, { id: "common.unlink" }))))) : (React.createElement(React.Fragment, null,
                React.createElement(FormattedMessage, { id: "settings.client.sage_intacct_customer" }),
                React.createElement("div", { className: "integration-customer-dropdown" },
                    React.createElement(Dropdown, { value: null, options: sageIntacctContactOptions, onChange: val => linkSageIntacctCustomer(val), label: formatMessage({ id: 'settings.client.sage_intacct_customer' }), placeholder: formatMessage({
                            id: 'settings.client.sage_intacct_customer',
                        }), customHeight: 30, integrationDropdown: true, integrationLoading: integrationLoading, integrationRetryToFetch: fetchSageIntacctCustomers.bind(this) }))))))) : null,
        !isProjectClientPage && ((_b = viewer.company) === null || _b === void 0 ? void 0 : _b.hubspotAccountId) ? (React.createElement("fieldset", { className: "client-integrations" },
            React.createElement("div", { className: "top-section" },
                React.createElement("h2", null, "Hubspot")),
            React.createElement("p", { className: "description" }, (client === null || client === void 0 ? void 0 : client.hubspotCompanyId) ? (React.createElement(React.Fragment, null,
                React.createElement(FormattedMessage, { id: "settings.client.hubspot_company_linked", values: {
                        a: chunks => {
                            var _a;
                            return (React.createElement("a", { href: `https://app.hubspot.com/contacts/${(_a = viewer.company) === null || _a === void 0 ? void 0 : _a.hubspotAccountId}/company/${client === null || client === void 0 ? void 0 : client.hubspotCompanyId}/`, target: "_blank" }, chunks));
                        },
                    } }),
                React.createElement("div", null,
                    React.createElement(Button, { onClick: unlinkHubspotCustomer.bind(this), size: Button.SIZE.STANDARD, variant: Button.VARIANT.RED_FILLED },
                        React.createElement(FormattedMessage, { id: "common.unlink" }))))) : (React.createElement(React.Fragment, null,
                React.createElement(FormattedMessage, { id: "settings.client.link_hubspot_company" }),
                React.createElement("div", { className: "integration-customer-dropdown" },
                    React.createElement(DropdownWebComponent, { isClearable: false, onSelect: v => {
                            linkHubspotCompany(v);
                        }, name: formatMessage({ id: 'settings.client.hubspot_company' }), searchStringChangedCallback: searchString => getHubspotCompaniesWithTimeout(searchString) }, searchingCustomers ? (React.createElement(InlineLoader, null)) : (hubspotCompanyOptions
                        .sort((o1, o2) => (o1.label < o2.label ? -1 : 1))
                        .map(option => (React.createElement(DropdownWebComponent.SingleText, { key: option.label, value: option.value, searchString: option.label }, option.label))))))))))) : null,
        !isProjectClientPage && ((_c = viewer.company) === null || _c === void 0 ? void 0 : _c.pipedriveCompanyDomain) ? (React.createElement("fieldset", { className: "client-integrations" },
            React.createElement("div", { className: "top-section" },
                React.createElement("h2", null, "Pipedrive")),
            React.createElement("p", { className: "description" }, (client === null || client === void 0 ? void 0 : client.pipedriveOrganizationId) ? (React.createElement(React.Fragment, null,
                React.createElement(FormattedMessage, { id: "settings.client.pipedrive_organization_linked", values: {
                        a: chunks => {
                            var _a;
                            return (React.createElement("a", { href: `${(_a = viewer.company) === null || _a === void 0 ? void 0 : _a.pipedriveCompanyDomain}/organization/${client === null || client === void 0 ? void 0 : client.pipedriveOrganizationId}`, target: "_blank" }, chunks));
                        },
                    } }),
                React.createElement("div", null,
                    React.createElement(Button, { onClick: unlinkPipedriveOrganization.bind(this), size: Button.SIZE.STANDARD, variant: Button.VARIANT.RED_FILLED },
                        React.createElement(FormattedMessage, { id: "common.unlink" }))))) : (React.createElement(React.Fragment, null,
                React.createElement(FormattedMessage, { id: "settings.client.link_pipedrive_organization" }),
                React.createElement("div", { className: "integration-customer-dropdown" },
                    React.createElement(Dropdown, { value: null, options: pipedriveOrganizationOptions, onChange: val => linkPipedriveOrganization(val), label: formatMessage({ id: 'settings.client.pipedrive_organization' }), placeholder: formatMessage({
                            id: 'settings.client.pipedrive_organization',
                        }), customHeight: 30, integrationDropdown: true, integrationLoading: integrationLoading, integrationRetryToFetch: fetchPipedriveOrganizations.bind(this) }))))))) : null,
        !isProjectClientPage && ((_d = viewer.company) === null || _d === void 0 ? void 0 : _d.newSalesforceEnabled) ? (React.createElement("fieldset", { className: "client-integrations" },
            React.createElement("div", { className: "top-section" },
                React.createElement("h2", null, "Salesforce")),
            React.createElement("p", { className: "description" }, (client === null || client === void 0 ? void 0 : client.salesforceAccountId) ? (React.createElement(React.Fragment, null,
                React.createElement(FormattedMessage, { id: "settings.client.salesforce_account_linked", values: {
                        a: chunks => (React.createElement("a", { target: "_blank", href: `${salesforceInstanceUrl}/lightning/r/Account/${client === null || client === void 0 ? void 0 : client.salesforceAccountId}/view` }, chunks)),
                    } }),
                React.createElement("div", null,
                    React.createElement(Button, { onClick: unlinkSalesforceCustomer.bind(this), size: Button.SIZE.STANDARD, variant: Button.VARIANT.RED_FILLED },
                        React.createElement(FormattedMessage, { id: "common.unlink" }))))) : (React.createElement(React.Fragment, null,
                React.createElement(FormattedMessage, { id: "settings.client.link_salesforce_account" }),
                React.createElement("div", { className: "integration-customer-dropdown" },
                    React.createElement(Dropdown, { value: null, options: salesforceCustomerOptions, onChange: val => linkSalesforceCustomer(val), label: formatMessage({ id: 'settings.client.salesforce_account' }), placeholder: formatMessage({ id: 'settings.client.salesforce_account' }), customHeight: 30, integrationDropdown: true, integrationLoading: integrationLoading, integrationRetryToFetch: fetchSalesforceCustomers.bind(this) }))))))) : null));
};
export default SettingsClientIntegrations;
