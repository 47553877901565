/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ProjectIndicatorJS_project$ref = any;
export type useRecentProjectsQueryVariables = {|
  projectIds?: ?$ReadOnlyArray<?string>
|};
export type useRecentProjectsQueryResponse = {|
  +viewer: ?{|
    +id: string,
    +component: ?string,
    +backendId: ?number,
    +actualPersonId: ?string,
    +psProjects: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +serviceCompanyId: ?number,
          +name: ?string,
          +prefix: ?string,
          +color: ?string,
          +legacyProjectAsSingleProject: ?{|
            +id: string,
            +customProjectId: ?string,
            +$fragmentRefs: ProjectIndicatorJS_project$ref,
          |},
        |}
      |}>
    |},
  |}
|};
export type useRecentProjectsQuery = {|
  variables: useRecentProjectsQueryVariables,
  response: useRecentProjectsQueryResponse,
|};
*/


/*
query useRecentProjectsQuery(
  $projectIds: [ID]
) {
  viewer {
    id
    component(name: "useRecentProjectsQuery")
    backendId
    actualPersonId
    psProjects(first: 10, projectIds: $projectIds) {
      edges {
        node {
          id
          serviceCompanyId
          name
          prefix
          color
          legacyProjectAsSingleProject {
            id
            customProjectId
            ...ProjectIndicatorJS_project
          }
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
}

fragment ProjectIndicatorJS_project on ProjectType {
  name
  companyProjectId
  customProjectId
  projectColor
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectIds",
    "type": "[ID]"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "useRecentProjectsQuery"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"useRecentProjectsQuery\")"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "backendId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceCompanyId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "prefix",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "customProjectId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  },
  {
    "kind": "Variable",
    "name": "projectIds",
    "variableName": "projectIds"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useRecentProjectsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": "psProjects",
            "args": null,
            "concreteType": "ProjectServiceProjectTypeConnection",
            "kind": "LinkedField",
            "name": "__Recent_psProjects_connection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectServiceProjectTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectServiceProjectType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectType",
                        "kind": "LinkedField",
                        "name": "legacyProjectAsSingleProject",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v9/*: any*/),
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ProjectIndicatorJS_project"
                          }
                        ],
                        "storageKey": null
                      },
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              (v12/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useRecentProjectsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": (v13/*: any*/),
            "concreteType": "ProjectServiceProjectTypeConnection",
            "kind": "LinkedField",
            "name": "psProjects",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectServiceProjectTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectServiceProjectType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectType",
                        "kind": "LinkedField",
                        "name": "legacyProjectAsSingleProject",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v9/*: any*/),
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "companyProjectId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "projectColor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              (v12/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v13/*: any*/),
            "filters": [],
            "handle": "connection",
            "key": "Recent_psProjects",
            "kind": "LinkedHandle",
            "name": "psProjects"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "viewer",
            "psProjects"
          ]
        }
      ]
    },
    "name": "useRecentProjectsQuery",
    "operationKind": "query",
    "text": "query useRecentProjectsQuery(\n  $projectIds: [ID]\n) {\n  viewer {\n    id\n    component(name: \"useRecentProjectsQuery\")\n    backendId\n    actualPersonId\n    psProjects(first: 10, projectIds: $projectIds) {\n      edges {\n        node {\n          id\n          serviceCompanyId\n          name\n          prefix\n          color\n          legacyProjectAsSingleProject {\n            id\n            customProjectId\n            ...ProjectIndicatorJS_project\n          }\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n}\n\nfragment ProjectIndicatorJS_project on ProjectType {\n  name\n  companyProjectId\n  customProjectId\n  projectColor\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5031eb7ad5c64ebae97c18eb06cddc2a';

module.exports = node;
