import {useIntl} from 'react-intl';
import {optionTraverser} from '../../../the_eye_util';
import {hasFeatureFlag} from './FeatureUtil';

const translationMap = {
	'project_budget.revenue': 'project_budget.billable_time_and_expenses',
	'project_budget.actual_revenue': 'project_budget.actual_billable_time_and_expenses',
	'project_budget.actual_revenue_no_expenses': 'project_budget.actual_billable_time',
	'project_budget.total_actual_revenue_all_projects': 'project_budget.actual_billable_time_and_expenses_all_projects',
	'project_budget.planned_revenue': 'project_budget.planned_billable_time',
	'project_budget.total_planned_revenue_all_projects': 'project_budget.total_billable_time_all_projects',
	'project_budget.remaining_revenue': 'project_budget.forecast_billable_time_and_expenses',
	'project_budget.remaining': 'project_budget.forecast_to_complete',
	'project_budget.remaining_revenue_all_projects': 'project_budget.forecast_billable_time_all_projects',
	'project_budget.total_remaining_revenue_all_projects': 'project_budget.forecast_billable_time_all_projects',
	'project_budget.remaining_cost': 'project_budget.forecast_cost_to_complete',
	'project_budget.remaining_profit': 'project_budget.forecast_profit_to_complete',
	'project_budget.remaining_margin': 'project_budget.forecast_margin_to_complete',
	'project_budget.total_remaining_cost_all_projects': 'project_budget.forecast_cost_to_complete_all_projects',
	'project_budget.remaining_cost_all_projects': 'project_budget.forecast_cost_to_complete_all_projects',
	'project_budget.forecast': 'project_budget.total_at_completion',
	'project_budget.forecast_revenue': 'project_budget.total_billable_time',
	'project_budget.forecast_cost': 'project_budget.total_cost_at_completion',
	'project_budget.forecast_profit': 'project_budget.total_profit_at_completion',
	'project_budget.forecast_margin': 'project_budget.total_margin_at_completion',
	'project_budget.plan_revenue': 'project_budget.planned_billable_time',
	'project_budget.rate_based_forecast_revenue': 'project_budget.total_billable_time_and_expenses',
	'project_budget.forecast_vs_plan': 'project_budget.total_at_completion_vs_planned',
	'project_budget.plan_vs_forecast': 'project_budget.planned_vs_total_at_completion',
	'project_budget.baseline_vs_forecast': 'project_budget.baseline_vs_total_at_completion',
};

const newFinancialsCategoriesMap = {
	'project_budget.planned_cost': 'common.estimated_cost',
	'project_budget.planned_profit': 'common.estimated_profit',
	'project_budget.planned_time': 'common.estimated_hours',
	'project_budget.forecast_cost': 'common.projected_total_cost',
	'project_budget.forecast_profit': 'common.projected_total_profit',
	'project_budget.baseline_time': 'baseline.hours',
	'project_budget.planned_billable_time_and_expenses': 'common.estimated_billable_value_of_service',
	'project_budget.rate_based_forecast_revenue': 'common.projected_total_billable_value_of_service',
	'project_budget.recognition_amount': 'common.projected_total_revenue',
	'project_budget.plan': 'common.estimated',
	'common.planned': 'common.estimated',
	'project_budget.actuals': 'project_budget.actual',
	'project_budget.actual_to_date': 'project_budget.actual',
	'project_budget.forecast_to_complete': 'common.remaining_work',
	'project_budget.total_at_completion': 'common.projected_total',
	'project_budget.baseline_vs_forecast': 'project_budget.baseline_vs_projected_total',
	'project_budget.plan_vs_forecast': 'project_budget.estimated_vs_projected_total',
	'project_budget.planned_vs_total_at_completion': 'project_budget.estimated_vs_projected_total',
	'project_budget.plan_vs_actual': 'project_budget.estimated_vs_actual',
	'project_budget.billable_time_and_expenses': 'common.billable_value_of_service',
	'project_budget.suggested_time_recognition': 'project_budget.revenue',
	'project_budget.time': 'common.hours',
	'project_budget.billable_time_spent_accumulated': 'project_budget.billable_value_of_service_accumulated_time',
	'project_budget.billable_time_spent': 'project_budget.billable_value_of_service_time',
	'project_budget.time_material_vs_recognition': 'project_budget.billable_value_of_service_vs_recognition_time',
	'project_budget.revenue': 'project_budget.revenue',
	'project_budget.actual_billable_time_and_expenses': 'common.actual_billable_value_of_service',
	'project_budget.forecast_billable_time': 'common.remaining_work_billable_value_of_service',
	'project_budget.actual_revenue': 'project_budget.actual_revenue',

	'project_settings.financials.value_calculations.planned_values':
		'project_settings.financials.value_calculations.estimated_values',
	'project_settings.financials.value_calculations.planned_values_description':
		'project_settings.financials.value_calculations.estimated_values_description',
	'project_settings.financials.value_calculations.planned_label':
		'project_settings.financials.value_calculations.estimated_label',
	'project_settings.financials.value_calculations.planned_separately':
		'project_settings.financials.value_calculations.estimated_separately',
	'project_settings.financials.value_calculations.planned_tooltip_header':
		'project_settings.financials.value_calculations.estimated_tooltip_header',
	'project_settings.financials.value_calculations.planned_tooltip':
		'project_settings.financials.value_calculations.estimated_tooltip',
	'project_settings.financials.value_calculations.planned_hours':
		'project_settings.financials.value_calculations.estimated_hours',
	'project_settings.financials.value_calculations.planned_revenue':
		'project_settings.financials.value_calculations.estimated_revenue',
	'project_settings.financials.value_calculations.planned_revenue_tooltip_description':
		'project_settings.financials.value_calculations.estimated_revenue_tooltip_description',
	'project_settings.financials.value_calculations.planned_cost':
		'project_settings.financials.value_calculations.estimated_cost',

	'project_settings.financials.value_calculations.forecast_values':
		'project_settings.financials.value_calculations.remaining_values',
	'project_settings.financials.value_calculations.forecast_values_description':
		'project_settings.financials.value_calculations.remaining_values_description',
	'project_settings.financials.value_calculations.forecast_label':
		'project_settings.financials.value_calculations.remaining_label',
	'project_settings.financials.value_calculations.forecast_separately':
		'project_settings.financials.value_calculations.remaining_separately',
	'project_settings.financials.value_calculations.forecast_tooltip_header':
		'project_settings.financials.value_calculations.remaining_tooltip_header',
	'project_settings.financials.value_calculations.forecast_tooltip':
		'project_settings.financials.value_calculations.remaining_tooltip',
	'project_settings.financials.value_calculations.forecast_hours':
		'project_settings.financials.value_calculations.remaining_hours',
	'project_settings.financials.value_calculations.forecast_revenue':
		'project_settings.financials.value_calculations.remaining_revenue',
	'project_settings.financials.value_calculations.forecast_revenue_tooltip_description':
		'project_settings.financials.value_calculations.remaining_revenue_tooltip_description',
	'project_settings.financials.value_calculations.forecast_cost':
		'project_settings.financials.value_calculations.remaining_cost',
};

export const remapId = id => {
	if (hasFeatureFlag('financial_categories_update') && newFinancialsCategoriesMap[id]) {
		return newFinancialsCategoriesMap[id];
	} else if (translationMap[id]) {
		return translationMap[id];
	} else {
		return id;
	}
};

export const remapAndFormatFinancialMessage = (descriptor, values) => {
	return useIntl().formatMessage({...descriptor, id: remapId(descriptor.id)}, values);
};

export const useRemappingFormatMessage = (intl = useIntl()) => {
	return (descriptor, values) => {
		return intl.formatMessage({...descriptor, id: remapId(descriptor.id)}, values);
	};
};

export const remapOptionTranslationIds = options => {
	optionTraverser(options, option => {
		option.translationId = remapId(option.translationId);
	});
};
