import React from 'react';
import {ContentBox, ContentBoxHeader, ContentBoxInfo, DropdownWrapper, TimeSubtitle, TimeTitle} from '../TimeManagement_styled';
import {Dropdown} from 'web-components';
import {createFragmentContainer, graphql} from 'react-relay';
import {getLabelFromGranularity, Granularity} from '../constants/Granularity';
import Util from '../../../shared/util/util';
import UpdateTimeRegistrationGranularityMutation from '../../../../mutations/time-registration-settings/UpdateTimeRegistrationGranularityMutation';
import {setTimeRegistrationSettings} from '../../../shared/util/cache/TimeRegistrationSettingsUtil';
import {FlexColumn, FlexItem, FlexRow, Icon} from '@forecast-it/design-system';

const updateTimeRegistrationGranularity = (id, granularity) => {
	const updateCache = res => {
		setTimeRegistrationSettings(res?.updateTimeRegistrationGranularity?.timeRegistrationSettings);
	};
	Util.CommitMutation(UpdateTimeRegistrationGranularityMutation, {id, granularity}, updateCache);
};

const TimeIncrementsSection = ({company}) => {
	const {timeRegistrationSettings} = company;
	const {id, granularity} = timeRegistrationSettings;
	return (
		<ContentBox>
			<ContentBoxInfo>
				<ContentBoxHeader>
					<TimeTitle>Time Increments</TimeTitle>
					<DropdownWrapper>
						<Dropdown
							name={'Select increment'}
							cy={'increment-settings'}
							selectedItems={[granularity]}
							onSelect={item => updateTimeRegistrationGranularity(id, item[0])}
							customPlaceholder={granularity}
						>
							{Object.values(Granularity).map(granularity => (
								<Dropdown.SingleText key={granularity} value={granularity}>
									{getLabelFromGranularity(granularity)}
								</Dropdown.SingleText>
							))}
						</Dropdown>
					</DropdownWrapper>
				</ContentBoxHeader>
				<TimeSubtitle>
					<FlexColumn gap={'s'}>
						<FlexItem>Select the time increment that each time registration will be rounded up to.</FlexItem>
						<FlexRow gap={'s'}>
							<Icon icon={'info'} />
							<FlexItem>
								This will only affect new time registrations and will not impact old time registrations or the
								rest of the platform.{' '}
								<a href={'https://support.forecast.app/hc/en-us/articles/4611544983569'} target={'_blank'}>
									Learn more
								</a>
							</FlexItem>
						</FlexRow>
					</FlexColumn>
				</TimeSubtitle>
			</ContentBoxInfo>
		</ContentBox>
	);
};
export default createFragmentContainer(TimeIncrementsSection, {
	company: graphql`
		fragment TimeIncrementsSection_company on Company {
			timeRegistrationSettings {
				id
				granularity
			}
		}
	`,
});
