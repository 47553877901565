import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import Moment from 'moment';
import TaskTable from '../../../../components/new-ui/task-table-v3/task_table';
import {injectIntl} from 'react-intl';
import Util from '../../../shared/util/util';
import CreateTaskMutation from '../../../../mutations/create_task_mutation';
import SprintsAddTask from './sprints_add_task';
import {createToast} from '../../../shared/components/toasts/another-toast/toaster';
import InlineLoader from '../../../shared/components/inline-loader/inline_loader';
import {hasFeatureFlag} from '../../../shared/util/FeatureUtil';
import {filterRolesByRatecardDisabledRoles} from '../../../shared/util/RateCardUtil';

class SprintsSprint extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.scrollBarRef = null;
	}

	componentDidMount() {
		if (this.props.focusAddControls && this.nameInput) {
			this.nameInput.select();
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (
			nextProps.isDraggingSomething &&
			((nextProps.tasksBeingDragged.length !== 0 &&
				nextProps.tasksBeingDragged[0].node.sprint &&
				nextProps.tasksBeingDragged[0].node.sprint.id !== this.props.sprint.id) ||
				(nextProps.tasksBeingDragged.length !== 0 &&
					!nextProps.tasksBeingDragged[0].node.sprint &&
					this.props.sprint.id) ||
				(nextProps.tasksBeingDragged.length !== 0 &&
					nextProps.tasksBeingDragged[0].node.sprint &&
					!this.props.sprint.id)) &&
			!this.props.sprint.forceRender
		) {
			return false;
		}
		return true;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.createdTaskId && this.state.createdTaskId && prevState.createdTaskId === this.state.createdTaskId) {
			this.setState({createdTaskId: null});
		}
	}

	handleAddTask(projectId, name, role, estimateForecast, approved, sprintId, assignedPersons) {
		if (this.props.projectLocked) {
			return;
		}
		const onSuccess = res => {
			if (res.createTask.errors && res.createTask.errors.length === 1) {
				Util.checkForSageErrorAndShowModal(res.createTask.errors, false);
			} else {
				createToast({
					duration: 5000,
					message: this.props.intl.formatMessage({
						id: 'task_activity_log.CARD_CREATE',
					}),
					actionText: this.props.intl.formatMessage({id: 'common.see_task'}),
					actionCallback: () => Util.showTaskModal(res.createTask.task.node.companyTaskId, this.props.history),
				});
				this.setState({createdTaskId: res.createTask.task.node.companyTaskId});
				if (this.scrollBarRef) {
					setTimeout(() => this.scrollBarRef.scrollTop(this.scrollBarRef.getScrollHeight() + 50), 100);
				}
			}
		};
		if (projectId && name) {
			const assignedPersonsIds = assignedPersons.map(person => person.id);
			const inSprint = !!sprintId;
			const mutationObject = {
				projectId: projectId,
				name: name,
				forecast: estimateForecast ? estimateForecast : undefined,
				approved: approved,
				sprintId: sprintId,
				assignedPersons: assignedPersonsIds,
				placementOfTask: 'BOTTOM',
				inSprint,
				...(inSprint ? {} : {placementOfBacklog: 'BOTTOM', inBacklog: true}),
			};
			if (role) {
				mutationObject.roleId = role.id;
			}
			Util.CommitMutation(CreateTaskMutation, mutationObject, onSuccess);
		}
	}

	registerScrollBar(scrollBarRef) {
		this.scrollBarRef = scrollBarRef;
	}

	isCurrentSprint(sprint) {
		const today = Moment();
		const startDate = Moment({
			y: sprint.startYear,
			M: sprint.startMonth - 1,
			d: sprint.startDay,
		});
		const endDate = Moment({
			y: sprint.endYear,
			M: sprint.endMonth - 1,
			d: sprint.endDay,
		});
		return !today.isBefore(startDate) && !today.isAfter(endDate);
	}

	render() {
		const project = this.props.isConnectedParent
			? this.props.viewer.projectGroup.projects.edges[0].node
			: this.props.viewer.project;
		const projectGroupSprintId = this.props.isConnectedParent ? this.props.sprint.projectGroupSprintId : null;

		const sprintFetched = this.props.lazyDataFetched || (!this.props.collapsed && this.isCurrentSprint(this.props.sprint));

		const assignableRoles = project
			? filterRolesByRatecardDisabledRoles(this.props.viewer.company.roles.edges, project.rateCard)
			: this.props.viewer.company.roles.edges;

		return (
			<div
				className={
					'phase' + (!this.props.isBacklog ? ' has-phase' : '') + (this.props.sideBacklog ? ' side-space' : '')
				}
				style={{minWidth: this.props.tableMinWidth}}
				data-userpilot={this.props.sprintIndex === 0 ? 'sprint-section-latest' : null}
			>
				<div
					className={`body`}
					id={this.props.sprint.id !== undefined ? 'phase-' + this.props.sprint.id : ''}
					style={{minWidth: this.props.tableMinWidth - (this.props.sideBacklog ? 78 : 66)}}
				>
					<TaskTable
						onSelectAllTasks={ids => this.props.onSelectAllTasks && this.props.onSelectAllTasks(ids)}
						onDeselectAllTasks={ids => this.props.onDeselectAllTasks && this.props.onDeselectAllTasks(ids)}
						cy="sprint"
						dragActive={this.props.draggingTask}
						collapsed={this.props.collapsed || !sprintFetched}
						registerScrollBar={this.registerScrollBar.bind(this)}
						tasks={this.props.sprint.tasks}
						disabled={!this.props.lazyDataFetched || this.props.projectLocked}
						isConnectedParent={this.props.isConnectedParent}
						dragAndDropGroup={this.props.dragAndDropGroup}
						taskCellMaxWidth={this.props.taskCellMaxWidth}
						minutesPerEstimationPoint={this.props.minutesPerEstimationPoint}
						showSprintNumbers={true}
						sprint={this.props.sprint}
						sortedSprints={this.props.sortedSprints}
						sprintPersons={this.props.sprintPersons}
						emptyPhase={this.props.emptyPhase}
						forceHidden={this.props.forceHidden}
						handleRowSelected={this.props.handleRowSelected}
						projectPersons={this.props.projectPersons}
						selectedRows={this.props.selectedRows}
						groupByPerson={this.props.groupByPerson}
						groupByRole={this.props.groupByRole}
						showEmptyPersons={this.props.showEmptyPersons}
						selectionMode={this.props.selectionMode}
						containerWidth={this.props.containerWidth}
						phaseHeader={this.props.phaseHeader}
						isEmpty={this.props.isBacklog}
						rowHeight={40}
						listHeaderHeight={40 + (this.props.showCloseSprintButton ? 46 + (this.props.collapsed ? 10 : 0) : 0)} //46 for the height of the close Sprint button and 10 for the margin when its closed
						showTaskModal={this.props.showTaskModal}
						projectLocked={this.props.projectLocked}
						isJiraProject={this.props.isJiraProject}
						phase={this.props.sprint}
						projectId={this.props.projectId}
						roles={this.props.roles}
						isEstimatedInHours={this.props.isEstimatedInHours}
						hasRateCard={this.props.hasRateCard}
						currencySymbol={this.props.currencySymbol}
						placeUnitBeforeValue={this.props.placeUnitBeforeValue}
						hasFinancialAccess={this.props.hasFinancialAccess}
						useManualAllocations={this.props.useManualAllocations}
						language={this.props.language}
						addTaskToBulkUpdate={this.props.addTaskToBulkUpdate}
						removeTaskFromBulkUpdate={this.props.removeTaskFromBulkUpdate}
						bulkUpdateTasks={this.props.bulkUpdateTasks}
						projectTasks={this.props.projectTasks}
						viewer={this.props.viewer}
						availableColumns={this.props.availableColumns}
						columnWidths={this.props.columnWidths}
						createdTaskId={this.state.createdTaskId}
						allowedPersonsForGroupBy={this.persons}
						parentType={'sprintv4'}
						unselectTasks={this.props.unselectTasks}
						lazyDataFetched={this.props.lazyDataFetched}
						lowHighModelScore={this.props.lowHighModelScore}
					/>

					{this.props.collapsed ? null : (
						<div
							className={
								'phase-bottom-section' + (this.props.groupByPerson || this.props.groupByRole ? ' grouped' : '')
							}
						>
							{!sprintFetched && <InlineLoader />}
							<SprintsAddTask
								isEmpty={this.props.isBacklog}
								disabled={!this.props.lazyDataFetched || this.props.projectLocked}
								isEstimatedInHours={this.props.isEstimatedInHours}
								useDropdownReplacement={hasFeatureFlag(
									'dropdown_replacement',
									this.props.viewer.availableFeatureFlags
								)}
								availableColumns={this.props.availableColumns}
								containerWidth={this.props.containerWidth}
								contextHeight={this.props.contextHeight}
								isWhite={this.props.isBacklog}
								projectId={this.props.projectId}
								sprint={this.props.sprint}
								addTask={this.handleAddTask.bind(this)}
								viewer={this.props.viewer}
								sprintIndex={this.props.sprintIndex}
								projectPersons={project.projectPersons.edges}
								assignablePersons={project.projectPersons.edges}
								assignableRoles={assignableRoles}
								isConnectedParent={this.props.isConnectedParent}
								projectGroupSprintId={projectGroupSprintId}
							/>
						</div>
					)}
				</div>
			</div>
		);
	}
}

SprintsSprint.propTypes = {
	sprint: PropTypes.object.isRequired,
	roles: PropTypes.array.isRequired,
	isBacklog: PropTypes.bool.isRequired,
	locale: PropTypes.string,
	hasRateCard: PropTypes.bool.isRequired,
	focusAddControls: PropTypes.bool,
	projectLocked: PropTypes.bool.isRequired,
	currencySymbol: PropTypes.string.isRequired,
	placeUnitBeforeValue: PropTypes.bool.isRequired,
	hasFinancialAccess: PropTypes.bool.isRequired,
	isJiraProject: PropTypes.bool.isRequired,
	//showPrice: PropTypes.bool.isRequired,
	//showCurrentPrice: PropTypes.bool.isRequired,
};

export default withRouter(injectIntl(SprintsSprint, {forwardRef: true}));
