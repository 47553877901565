import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import uuid from 'uuid';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import CustomScrollDiv from '../scroll-bars/custom_scroll_div';
import Util from '../../util/util';
import Person from '../person/person';
import CloseIcon from '../../../../images/small_close_icon';
import {EVENT_ID, subscribe, unsubscribe} from '../../../../containers/event_manager';
import _ from 'lodash';
import InlineLoader from '../inline-loader/inline_loader';
import ForecastTooltip from '../../../shared/components/tooltips/ForecastTooltip';
import {Icon, Scrollbar} from '@forecast-it/design-system';
import {profilePicSrc} from '../../../../directApi';
import {DeprecatedProjectIndicatorNoFragment} from '../project-indicator/DeprecatedProjectIndicator';
import {DeprecatedProjectGroupIndicatorNoFragment} from '../project-indicator/DeprecatedProjectGroupIndicator';
import styled from 'styled-components';

export const MarginRightWrapper = styled.div`
	margin-right: 8px;
`;

class NestedDropdown extends Component {
	constructor(props) {
		super(props);
		let outerOptionExpandedMap;
		if (this.props.outerOptionExpandedMap) {
			outerOptionExpandedMap = this.props.outerOptionExpandedMap;
		} else {
			outerOptionExpandedMap = new Map();
			this.props.options.forEach(option =>
				outerOptionExpandedMap.set(
					option.value,
					option.forceExpand || (option.nestedOptions.length !== 0 && !option.collapseByDefault)
				)
			);
		}
		this.state = {
			expanded: this.props.actionMenu || false,
			selected: null,
			selectedNested: false,
			searchText: '',
			searchInputValue: '',
			outerOptionExpandedMap,
			preselectedOption: this.props.preselectedOption || null,
		};
		this.uuid = props.uuid || uuid.v4();
		this.handleEscKeyPress = this.handleEscKeyPress.bind(this);
		this.handleScroll = this.handleScroll.bind(this);
	}

	// add event listeners for handleing the esc key press without propagating to the parent components
	componentDidMount() {
		const {attachKeyDownHandler} = this.props;
		subscribe(EVENT_ID.SCROLLED, this.handleScroll);

		if (this.inputRef) {
			this.inputRef.addEventListener('keydown', this.handleEscKeyPress);
			this.offset = this.inputRef.getBoundingClientRect();
			// this.inputRef.focus();
		}
		if (this.nestedDropdown && this.props.setDropdownWidth) {
			const width = this.nestedDropdown.getBoundingClientRect().width;
			this.props.setDropdownWidth(width);
		}
		attachKeyDownHandler && attachKeyDownHandler(this.onParentKeyDown.bind(this));
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.externalSearch && prevState.searchText !== this.state.searchText) {
			this.props.externalSearch(this.state.searchText);
		}
		if (prevState.expanded !== this.state.expanded) {
			if (this.props.onToggle) {
				this.props.onToggle();
			}
		}
		if (prevProps.preselectedOption !== this.props.preselectedOption) {
			this.setState({
				preselectedOption: this.props.preselectedOption,
			});
		}
	}

	componentWillUnmount() {
		unsubscribe(EVENT_ID.SCROLLED, this.handleScroll);

		if (this.inputRef) {
			this.inputRef.removeEventListener('keydown', this.handleEscKeyPress);
		}
	}

	handleEscKeyPress(e) {
		if (e.key === 'Escape') {
			e.stopPropagation();
			e.preventDefault();
			this.handleInputBlur(e);
		}
	}

	handleScroll(scrollParent) {
		// Don't collapse if scrolled by self
		if (this.props.closeOnScroll && scrollParent !== 'nested-dropdown' && this.state.expanded) {
			this.setState({expanded: false, searchText: '', searchInputValue: ''});
			if (this.inputRef) this.inputRef.blur();
		}
	}

	onParentKeyDown(e) {
		this.onKeyDown(e);
	}

	toggleDropdown(e) {
		if (this.props.disabled) return;
		e.stopPropagation();
		if (!this.state.expanded && this.inputRef) {
			//if expanding focus input field
			this.inputRef.focus();
		}
		if (this.inputRef) {
			this.offset = this.inputRef.getBoundingClientRect();
		}
		if (this.props.lazyLoadDropdown) {
			this.props.refetch();
		}
		this.setState(
			{expanded: !this.state.expanded, searchText: '', searchInputValue: ''},
			this.scrollToPreselectedElement.bind(this)
		);
	}

	scrollToPreselectedElement() {
		// get the element with option-pre-selected class and scroll to it
		requestAnimationFrame(() => {
			// if the preselected option is nested in a collapsed option expand the parent it and scroll to option
			const outerOptionExpandedMap = this.state.outerOptionExpandedMap;
			if (
				this.props.options.find(option =>
					option.nestedOptions.some(nOption => _.isEqual(nOption, this.state.preselectedOption))
				)
			) {
				const outerOptionValue = this.props.options.find(option =>
					option.nestedOptions.some(nOption => _.isEqual(nOption, this.state.preselectedOption))
				).value;
				outerOptionExpandedMap.set(outerOptionValue, true);
			}
			this.setState({outerOptionExpandedMap}, () => {
				// did not use ref because nested options already had ref
				let selectedElement = document.getElementsByClassName('option-pre-selected')[0];
				if (selectedElement && typeof selectedElement.scrollIntoView === 'function') {
					selectedElement.scrollIntoView({
						block: 'center',
					});
				}
			});
		});
	}

	handleInputFocus() {
		if (this.state.expanded) return;
		this.setState({expanded: true});
	}

	handleInputBlur(e) {
		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11
		if (
			newTarget &&
			(newTarget.id === 'input-field-arrow' ||
				newTarget.id === 'input-field-arrow-wrapper-' + this.uuid ||
				newTarget.id === 'clear-option' ||
				(newTarget.id !== undefined &&
					(newTarget.id.includes('outer_select_option_') || newTarget.id.includes('nested_select_option_'))))
		)
			return;
		this.setState({expanded: false, selected: null, selectedNested: null, searchText: '', searchInputValue: ''});
		if (this.inputRef) this.inputRef.blur();
	}

	onOptionsHeaderBlur(e) {
		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement;
		if (!newTarget.className.includes('outer-option-header')) {
			this.setState({expanded: false, selected: null, selectedNested: null});
			// toggle the action menu as well when bluring the nested dropdown
			if (this.props.toggleActionsDropdown) this.props.toggleActionsDropdown(e);
		}
	}

	onOuterOptionSelect(option) {
		if (this.props.onOuterOptionSelect) {
			if (option.nestedOptions.length) {
				this.props.onOuterOptionSelect(option);
			}
			// if the option is not a category that can be expanded/collapssed and it has a onclick event
			if (option.onClick && !option.nestedOptions.length) {
				option.onClick();
				this.setState({selected: null});
			}
		} else {
			const outerOptionExpandedMap = this.state.outerOptionExpandedMap;
			outerOptionExpandedMap.set(option.value, !outerOptionExpandedMap.get(option.value));
			this.setState({outerOptionExpandedMap});
		}
	}

	onNestedOptionSelect(option, e) {
		if (e && typeof e.target.className !== 'string') return; // dont continue if the pressed element is an SVG

		option.onClick ? option.onClick() : this.props.onChange(option);
		this.setState({preselectedOption: option});
		if (this.props.stayOpenOnSelect) {
			this.setState({selected: null, searchText: '', searchInputValue: ''});
		} else {
			this.setState({expanded: false, selected: null, searchText: '', searchInputValue: ''});
		}
	}

	deselectPreselectedOption() {
		this.props.onChange(null);
		this.setState({preselectedOption: null, expanded: false, selected: null, searchText: '', searchInputValue: ''});
	}

	clearDropdownStateOnClose() {
		this.setState({expanded: false, selected: null, selectedNested: null, searchText: '', searchInputValue: ''});
	}

	onKeyDown(e) {
		if (!this.state.expanded) return;
		if (e.keyCode === 27) {
			this.clearDropdownStateOnClose();
		} else if (e.key === 'Enter') {
			if (this.state.selected === null) return;
			if (this.state.selectedNested === null) {
				const selectedOption = this.props.options[this.state.selected];
				if (this.state.selected === 0 && this.props.options[0].clearOption) {
					//select clear option
					this.onNestedOptionSelect(this.props.options[0]);
				} else if (selectedOption.clickable) {
					this.onNestedOptionSelect(selectedOption);
				} else {
					//toggle outer option
					this.onOuterOptionSelect(selectedOption);
				}
			} else {
				const selectedOuterOption = this.props.options[this.state.selected];
				const selectedNestedOption = selectedOuterOption.nestedOptions.filter(
					o => this.state.searchText === '' || o.label.toLowerCase().includes(this.state.searchText.toLowerCase())
				)[this.state.selectedNested];
				this.onNestedOptionSelect(selectedNestedOption);
			}
		} else if (e.keyCode === 40) {
			//down arrow
			const options =
				this.state.searchText === ''
					? this.props.options
					: this.props.options.map(option => {
							const mappedOption = option;
							mappedOption.nestedOptions = option.nestedOptions.filter(
								nestedOption =>
									this.state.searchText === '' ||
									nestedOption.label.toLowerCase().includes(this.state.searchText.toLowerCase())
							);
							return mappedOption;
					  });
			let selected = null,
				selectedNested = null;
			if (this.state.selected === null) {
				selected = 0;
			} else if (this.state.selected !== null && this.state.selectedNested === null) {
				//currently selected outer option. Check if that outer option is expanded. If it is select its first nested otpion if not selected next outer option
				let selectedOption = options[this.state.selected];
				if (this.state.outerOptionExpandedMap.get(selectedOption.value) && selectedOption.nestedOptions.length !== 0) {
					//outer option expanded and has nested options
					selected = this.state.selected;
					selectedNested = 0;
				} else if (this.state.selected !== options.length - 1) {
					selected = this.state.selected + 1;
					selectedNested = null;
				}
			} else if (this.state.selected !== null && this.state.selectedNested !== null) {
				//currently selected nested option. Check if there is next nested option. If not selected next outer option
				let selectedOption = options[this.state.selected];
				if (this.state.selectedNested !== selectedOption.nestedOptions.length - 1) {
					selected = this.state.selected;
					selectedNested = this.state.selectedNested + 1;
				} else if (this.state.selected !== options.length - 1) {
					selected = this.state.selected + 1;
					selectedNested = null;
				}
			}
			if (selected !== this.state.selected || selectedNested !== this.state.selectedNested) {
				this.setState({selected, selectedNested});
				if (this.customScrollRef) {
					const domNode =
						selectedNested === null
							? ReactDOM.findDOMNode(this['outer_select_option_' + selected])
							: ReactDOM.findDOMNode(
									this['nested_select_option_' + selected + '_nested_index_' + selectedNested]
							  );
					if (domNode) this.customScrollRef.setScrollTop(domNode.offsetTop - 50);
				}
			}
		} else if (e.keyCode === 38) {
			if (e.altKey) {
				this.clearDropdownStateOnClose();
				if (this.inputRef) this.inputRef.blur();
				return;
			}
			//up arrow
			const options =
				this.state.searchText === ''
					? this.props.options
					: this.props.options.map(option => {
							const mappedOption = option;
							mappedOption.nestedOptions = option.nestedOptions.filter(
								nestedOption =>
									this.state.searchText === '' ||
									nestedOption.label.toLowerCase().includes(this.state.searchText.toLowerCase())
							);
							return mappedOption;
					  });
			let selected = null,
				selectedNested = null;
			if (this.state.selected === null) {
				// if nothing selected adn up arrow pressed select last visible option
				let lastOption = options[options.length - 1];
				selected = options.length - 1;
				if (lastOption.nestedOptions.length !== 0 && this.state.outerOptionExpandedMap.get(lastOption.value)) {
					selectedNested = lastOption.nestedOptions.length - 1;
				}
			} else if (this.state.selected !== null && this.state.selected !== 0 && this.state.selectedNested === null) {
				//currently selected outer option. Select last nested option of above outer option if expanded, if not expanded select outer option.
				let previousOption = options[this.state.selected - 1];
				if (previousOption.nestedOptions.length !== 0 && this.state.outerOptionExpandedMap.get(previousOption.value)) {
					//previous outer option is expanded and has nested options. Select last nested option
					selected = this.state.selected - 1;
					selectedNested = previousOption.nestedOptions.length - 1;
				} else {
					// select previous outer option
					selected = this.state.selected - 1;
					selectedNested = null;
				}
			} else if (this.state.selected !== null && this.state.selectedNested !== null) {
				//currently selected nested option. Select previous nested option if any or last nested option of previous outer option if expanded. If collapsed selected outer option
				if (this.state.selectedNested === 0) {
					selected = this.state.selected;
					selectedNested = null;
				} else {
					selected = this.state.selected;
					selectedNested = this.state.selectedNested - 1;
				}
			}
			if (selected !== this.state.selected || selectedNested !== this.state.selectedNested) {
				this.setState({selected, selectedNested});
				if (this.customScrollRef) {
					const domNode =
						selectedNested === null
							? ReactDOM.findDOMNode(this['outer_select_option_' + selected])
							: ReactDOM.findDOMNode(
									this['nested_select_option_' + selected + '_nested_index_' + selectedNested]
							  );
					if (domNode) this.customScrollRef.setScrollTop(domNode.offsetTop - 200);
				}
			}
		}
	}
	clearCountdown() {
		if (this.typingTimer !== null) {
			clearTimeout(this.typingTimer);
		}
	}
	startCountdown() {
		this.clearCountdown();
		this.typingTimer = setTimeout(this.doneTyping.bind(this), 500);
	}
	doneTyping() {
		this.setState({searchText: this.state.searchInputValue, selected: null, selectedNested: null});
	}
	onSearch(e) {
		// Don't search if pressed key was arrow keys or enter
		if (e.keyCode === 27 || e.keyCode === 38 || e.keyCode === 40 || e.key === 'Enter') return;
		if (this.props.lazyLoadDropdown) {
			this.startCountdown();
		} else {
			this.doneTyping();
		}
	}
	onSearchChange(e) {
		this.setState({searchInputValue: e.target.value});
	}
	onOptionMouseEnter(index, selectedNestedIndex) {
		this.setState({selected: index, selectedNested: selectedNestedIndex});
	}
	onOptionMouseLeave() {
		this.setState({selected: null, selectedNested: false});
	}
	filterUsingLabel(nestedOption) {
		return typeof nestedOption.label === 'string' && !nestedOption.searchString;
	}

	render() {
		if (this.state.expanded && this.inputRef && !this.props.actionMenu) {
			this.inputRef.focus();
		}

		let dropdownStyle = {};
		if (this.offset && this.props.portalDropdown) {
			if (this.state.renderOnTop) {
				dropdownStyle = {
					position: 'absolute',
					zIndex: 999,
					bottom: window.innerHeight - this.offset.top,
					left: this.offset.left - 1,
					width: this.offset.width + 37,
					marginBottom: 0,
				};
			} else {
				dropdownStyle = {
					position: 'absolute',
					zIndex: 999,
					top: this.offset.top + this.offset.height,
					left: this.offset.left - 1,
					width: this.offset.width + 37,
				};
			}
		}

		const getDropdownContent = () => {
			return this.props.options.map((option, index) => {
				// clear option means option that is not in a group
				return option.clearOption ? (
					<div key={index}>
						{this.state.searchText === '' || Util.normalizedIncludes(option.label, this.state.searchText) ? (
							<div
								className={
									'clear-option' +
									(this.state.selected === index ? ' selected' : '') +
									(_.isEqual(option, this.state.preselectedOption) && !this.props.disablePreselectedOption
										? ' option-pre-selected'
										: '')
								}
								onMouseDown={this.onNestedOptionSelect.bind(this, option)}
								onMouseEnter={this.onOptionMouseEnter.bind(this, index, null)}
								onMouseLeave={this.onOptionMouseLeave.bind(this)}
								id="clear-option"
								tabIndex={-1}
								data-cy={`clear-option-${option.cy ? option.cy : index}`}
							>
								{option.logo ? <span className={option.logo}></span> : null}
								<span className="clear-option-name">{option.label}</span>
							</div>
						) : null}
					</div>
				) : (
					<div
						key={index}
						className={
							'outer-option' +
							(this.state.outerOptionExpandedMap.get(option.value) &&
							option.nestedOptions.filter(nestedOption => {
								if (this.filterUsingLabel(nestedOption)) {
									return (
										this.state.searchText === '' ||
										Util.normalizedIncludes(nestedOption.label, this.state.searchText)
									);
								} else {
									return (
										this.state.searchText === '' ||
										Util.normalizedIncludes(nestedOption.searchString, this.state.searchText)
									);
								}
							}).length !== 0
								? ' expanded'
								: '')
						}
					>
						<div
							tabIndex={-1}
							onBlur={this.props.actionMenu ? this.onOptionsHeaderBlur.bind(this) : null}
							className={
								'outer-option-header' +
								(this.state.selectedNested === null && this.state.selected === index ? ' selected' : '')
							}
							onMouseDown={this.onOuterOptionSelect.bind(this, option)}
							onMouseEnter={this.onOptionMouseEnter.bind(this, index, null)}
							onMouseLeave={this.onOptionMouseLeave.bind(this)}
							id={'outer_select_option_' + index}
							ref={div => (this['outer_select_option_' + index] = div)}
							data-cy={option.cy}
						>
							<div>
								<span className="outer-option-name">
									{option.label}{' '}
									{option.label === option?.unavailableItemLabel && (
										<ForecastTooltip
											placement={'bottom'}
											content={option?.unavailableItemLabelTooltipContent}
										>
											<Icon icon={'help'} size={'s'} />
										</ForecastTooltip>
									)}
								</span>
								{this.props.hideOptionsCounter ? null : (
									<span className="outer-option-nested-options-counter">
										{` (${
											option.nestedOptions.filter(nestedOption => {
												if (this.filterUsingLabel(nestedOption)) {
													return (
														!nestedOption.excludeFromCount &&
														(this.state.searchText === '' ||
															Util.normalizedIncludes(nestedOption.label, this.state.searchText))
													);
												} else {
													return (
														!nestedOption.excludeFromCount &&
														(this.state.searchText === '' ||
															Util.normalizedIncludes(
																nestedOption.searchString,
																this.state.searchText
															))
													);
												}
											}).length
										}) `}
									</span>
								)}
							</div>
							<div
								className={
									'outer-option-arrow' +
									(this.state.outerOptionExpandedMap.get(option.value) ? ' expanded' : '')
								}
							/>
						</div>
						{this.state.outerOptionExpandedMap.get(option.value)
							? option.nestedOptions
									.filter(nestedOption => {
										if (this.filterUsingLabel(nestedOption)) {
											return (
												this.state.searchText === '' ||
												Util.normalizedIncludes(nestedOption.label, this.state.searchText)
											);
										} else {
											return (
												this.state.searchText === '' ||
												Util.normalizedIncludes(nestedOption.searchString, this.state.searchText)
											);
										}
									})
									.map((nestedOption, nestedIndex) => {
										return (
											<div
												key={nestedIndex}
												className={
													'nested-option' +
													(nestedIndex === 0 ? ' first' : '') +
													(nestedIndex === option.nestedOptions.length - 1 ? ' last' : '') +
													(this.state.selectedNested === nestedIndex && this.state.selected === index
														? ' selected'
														: '') +
													(nestedOption.disabled ? ' disabled' : '') +
													(_.isEqual(nestedOption, this.state.preselectedOption) &&
													!this.props.disablePreselectedOption
														? ' option-pre-selected'
														: '')
												}
												ref={div =>
													(this['nested_select_option_' + index + '_nested_index_' + nestedIndex] =
														div)
												}
												onMouseDown={this.onNestedOptionSelect.bind(this, nestedOption)}
												tabIndex={-1}
												onMouseEnter={this.onOptionMouseEnter.bind(this, index, nestedIndex)}
												onMouseLeave={this.onOptionMouseLeave.bind(this)}
												id={'nested_select_option_' + index + '_nested_index_' + nestedIndex}
												data-cy={`nested-option${nestedOption.cy ? '-' + nestedOption.cy : ''}`}
												style={{
													opacity: option.label === option?.unavailableItemLabel ? '0.4' : '',
													pointerEvents: option.label === option?.unavailableItemLabel ? 'none' : '',
												}}
											>
												{nestedOption.icon}

												{nestedOption.person ? (
													<div className={`project-option-wrapper person-details-wrapper`}>
														<Person
															name={nestedOption.person.fullName}
															showName={true}
															showRole={true}
															imageSrc={profilePicSrc(nestedOption.person.profilePictureId)}
															imageSize="new-ui-dropdown"
														/>
													</div>
												) : (nestedOption.projectColor !== undefined ||
														nestedOption.color !== undefined) &&
												  (nestedOption.companyProjectId !== undefined ||
														nestedOption.companyProjectGroupId !== undefined) ? (
													<div className={`project-option-wrapper `}>
														{nestedOption.isInProjectGroup ? (
															<div className="sub-project-group-indicator" />
														) : null}
														<MarginRightWrapper>
															{nestedOption.isProjectGroup ? (
																<DeprecatedProjectGroupIndicatorNoFragment
																	projectGroup={nestedOption}
																	disableLink={true}
																	noWidthLimit={false}
																	noWrapper={true}
																/>
															) : (
																<DeprecatedProjectIndicatorNoFragment
																	project={nestedOption}
																	disableLink={true}
																	noWidthLimit={false}
																	noWrapper={true}
																/>
															)}
														</MarginRightWrapper>

														{nestedOption.isHalted ? <div className="blocked-icon" /> : null}
														<span
															className={
																'nested-option-name' +
																(nestedOption.isHalted
																	? ' isBlocked'
																	: nestedOption.isDone
																	? ' isDone'
																	: '')
															}
															title={nestedOption.label}
														>
															{nestedOption.label}
														</span>
														{nestedOption.harvestProject && this.props.isHarvestUser ? (
															<div
																className={'harvest-icon'}
																title={nestedOption.harvestProject.name}
															/>
														) : nestedOption.project &&
														  nestedOption.project.harvestProject &&
														  this.props.isHarvestUser ? (
															<div
																className={'harvest-icon'}
																title={nestedOption.project.harvestProject.name}
															/>
														) : null}
													</div>
												) : (
													<span className={`nested-option-name `}>{nestedOption.label}</span>
												)}

												{_.isEqual(nestedOption, this.state.preselectedOption) &&
												!this.props.disablePreselectedOptionRemove ? (
													<div
														className="deselect-icon"
														onClick={this.deselectPreselectedOption.bind(this)}
													>
														<CloseIcon />
													</div>
												) : null}
											</div>
										);
									})
							: null}
					</div>
				);
			});
		};

		const scrollBarMaxHeight = this.props.actionMenu ? undefined : this.props.maxDropdownHeight ?? 402;

		const expandedDropdownContent = (
			<div className="nested-dropdown-options-wrapper" style={dropdownStyle}>
				{this.props.contentLoading ? (
					<div className="content-loading" style={{height: this.props.maxDropdownHeight || 352, width: '100%'}}>
						<InlineLoader />
					</div>
				) : this.props.nativeScrollbar ? (
					<Scrollbar maxHeight={scrollBarMaxHeight} handleScroll={this.props.handleScroll} hasFocusableContent>
						{getDropdownContent()}
					</Scrollbar>
				) : (
					<CustomScrollDiv
						scrollParent={'nested-dropdown'}
						handleScroll={this.props.handleScroll}
						autoHeight={true}
						autoHeightMin={1}
						autoHeightMax={
							this.props.actionMenu
								? 'max-content'
								: this.props.maxDropdownHeight
								? this.props.maxDropdownHeight
								: 402
						}
						ref={div => (this.customScrollRef = div)}
					>
						{getDropdownContent()}
					</CustomScrollDiv>
				)}
			</div>
		);

		return (
			<div
				id={this.props.id}
				data-userpilot={this.props.userpilot}
				data-cy={this.props.cy + '-dropdown'}
				className={
					this.props.customClass
						? `nested-dropdown-v2 ${this.props.customClass}`
						: 'nested-dropdown-v2' +
						  (this.state.expanded ? ' expanded' : '') +
						  (this.props.disabled ? ' locked' : '') +
						  (this.props.small ? ' small' : '')
				}
				title={this.props.value}
				ref={div => (this.nestedDropdown = div)}
				style={this.props.dropdownStyle ? this.props.dropdownStyle : {}}
			>
				{!this.state.expanded && this.props.label ? (
					<span onClick={this.toggleDropdown.bind(this)} className="nested-dropdown-v2-label">
						{this.props.label}
					</span>
				) : null}

				<div
					className={
						'input-arrow-wrapper' +
						(this.state.expanded ? ' expanded' : ' collapsed') +
						(this.props.actionMenu ? ' hidden' : '') +
						(this.props.showBorder ? ' show-border' : '') +
						(this.props.disabled ? ' locked disabled' : '')
					}
				>
					{!this.state.searchInputValue ? (
						<div className="placeholder" onClick={!this.state.expanded ? this.toggleDropdown.bind(this) : null}>
							{(this.state.expanded && this.props.showPlaceHolderWhenExpanded) || !this.state.expanded ? (
								this.props.projectIdLabel &&
								this.props.projectIdLabel.companyProjectId &&
								this.props.projectIdLabel.projectColor ? (
									<div className={'id-label-wrapper'}>
										<DeprecatedProjectIndicatorNoFragment
											project={this.props.projectIdLabel}
											disableLink={true}
										/>
									</div>
								) : null
							) : null}
							<div className={`placeholder-label ${!this.props.value ? ' faded' : ''}`}>
								{this.state.expanded
									? this.props.showPlaceHolderWhenExpanded && this.props.expandedPlaceholder
										? this.props.expandedPlaceholder
										: this.props.showPlaceHolderWhenExpanded && this.props.placeholder
										? this.props.placeholder
										: this.props.value
										? this.props.value
										: ''
									: this.props.value
									? this.props.value
									: this.props.placeholder
									? this.props.placeholder
									: ''}
							</div>
						</div>
					) : null}
					<input
						className={
							'nested-dropdown-input' +
							(this.state.expanded ? ' expanded' : '') +
							(this.props.useWhiteColor ? ' white-color' : '') +
							(this.props.useBlackColor ? ' black-color' : '') +
							(this.props.projectIdLabel ? ' with-id-label' : '') +
							(this.props.disabled ? ' locked' : '')
						}
						value={this.state.searchInputValue ? this.state.searchInputValue : ''}
						title={this.props.value}
						onFocus={this.handleInputFocus.bind(this)}
						onBlur={this.handleInputBlur.bind(this)}
						onKeyDown={this.onKeyDown.bind(this)}
						onKeyUp={this.onSearch.bind(this)}
						onChange={this.onSearchChange.bind(this)}
						id={this.uuid}
						ref={input => {
							this.inputRef = input;
						}}
						autoComplete="off"
						disabled={this.props.disabled}
					/>
					{this.props.clearOnInput && this.props.value ? (
						<div className="deselect-icon" onClick={this.deselectPreselectedOption.bind(this)}>
							<CloseIcon class="input-clear-icon" />
						</div>
					) : null}
					{this.state.expanded || this.props.showArrowWhenCollapsed ? (
						<div
							className="arrow-icon-wrapper"
							id={'input-field-arrow-wrapper-' + this.uuid}
							onClick={this.props.disabled ? null : this.toggleDropdown.bind(this)}
							tabIndex={-1}
							data-cy={this.props.cy + '-arrow-wrapper'}
						>
							<div
								className={
									'arrow' +
									(this.state.expanded
										? this.props.useWhiteColor
											? ' white-expanded'
											: ' expanded'
										: this.props.useWhiteColor
										? ' white-collapsed'
										: ' collapsed') +
									(this.props.disabled ? ' disabled' : '')
								}
								id="input-field-arrow"
							/>
						</div>
					) : null}
				</div>

				{this.state.expanded
					? this.props.portalDropdown
						? ReactDOM.createPortal(expandedDropdownContent, document.querySelector('#root-portal-container'))
						: expandedDropdownContent
					: null}
			</div>
		);
	}
}
NestedDropdown.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.any,
			clickable: PropTypes.bool,
			clearOption: PropTypes.bool,
			nestedOptions: PropTypes.array,
		})
	),
	preselectedOption: PropTypes.object,
	disablePreselectedOption: PropTypes.bool, // disable highlighting of the selected option when opening the dropdown
	disablePreselectedOptionRemove: PropTypes.bool, // disable remove the preselected option
	label: PropTypes.string,
	cy: PropTypes.string,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	uuid: PropTypes.string,
	showArrowWhenCollapsed: PropTypes.bool,
	useWhiteColor: PropTypes.bool,
	toggleActionsDropdown: PropTypes.func,
	actionMenu: PropTypes.bool,
	dropdownStyle: PropTypes.object,
	showPlaceHolderWhenExpanded: PropTypes.bool,
	placeholder: PropTypes.string,
	showBorder: PropTypes.bool,
	small: PropTypes.bool,
};
export default injectIntl(NestedDropdown);
