import React from 'react';
import styled from 'styled-components';
import {NewTabIcon} from 'web-components';
import {WarningIcon} from 'web-components/icons';
import {getWarningVariantFromRAGStatus} from '../../../../../../../containers/project/project_health/ProjectHealthLogic';

const NewTabIconWrapper = styled.div`
	display: none;
	align-items: center;
	margin-left: 8px;
`;

const CellWrapper = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	padding: 6px 8px;
	border-radius: 4px;
	&:hover,
	&:focus {
		background-color: #e7e7f3;
		${NewTabIconWrapper} {
			display: flex;
		}
	}
`;

const InsightText = styled.span`
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const InteractiveCell = ({text, ragStatus = null, onSelect}) => {
	const handleCellClick = () => {
		if (onSelect) {
			onSelect();
		}
	};

	const handleCellKeydown = e => {
		if (e.key === 'Enter' || e.key === ' ') {
			if (onSelect) {
				onSelect();
			}
		}
	};

	return (
		<CellWrapper
			title={ragStatus ? `${ragStatus} - ${text}` : text}
			tabIndex={0}
			onClick={handleCellClick}
			onKeyDown={handleCellKeydown}
		>
			{ragStatus ? <WarningIcon variant={getWarningVariantFromRAGStatus(ragStatus)} /> : null}
			<InsightText>{text}</InsightText>
			<NewTabIconWrapper>
				<NewTabIcon size={NewTabIcon.SIZE.MEDIUM} />
			</NewTabIconWrapper>
		</CellWrapper>
	);
};

export default InteractiveCell;
