import React from 'react';
import {Button, Badge} from 'web-components';
import {
	AppliedFiltersContainer,
	AppliedFiltersTop,
	SmallTitle,
	Buttons,
	BadgeWrapper,
	AppliedFiltersGroup,
	AppliedFiltersGroupBadges,
	AppliedFiltersGroupHeader,
	AppliedFiltersGroupSectionName,
	OperatorBadge,
	AppliedFiltersTypeLabel,
	AppliedFiltersGroups,
} from './filter.styled';
import {findElementFromId, getTypeLabel, getGroupLabel} from './filter_util';
import tracking from '../../../../tracking';
import CustomScrollDiv from '../scroll-bars/custom_scroll_div';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';
import {groupBy} from 'lodash';
import {FILTER_OPERATOR, isFilterOperatorEnabled} from './filter_logic';

const filterIdToKey = filterId => {
	if (typeof filterId === 'string') {
		return filterId;
	} else {
		return JSON.stringify(filterId);
	}
};

export const AppliedFiltersSection = ({
	viewer,
	intl,
	advancedFilter,
	appliedFilters,
	appliedFilterOperators,
	onRemoveFilter,
	onClearFilters,
	clickOnSave,
	selectedSection,
}) => {
	const {formatMessage} = intl;
	const handleSaveFilter = () => {
		tracking.trackPageAction('Filter saved');
		trackEvent('Filter', 'Saved');
		clickOnSave();
	};

	const getFilter = appliedFilters => {
		let filters = [];
		appliedFilters.forEach(filter => {
			const element = findElementFromId(filter.filterType, filter.id, viewer, formatMessage, intl);
			filters.push(
				<BadgeWrapper key={filterIdToKey(filter.id)} data-cy={`applied-filter-${filter.filterType}`}>
					<Badge
						title={element.title ? element.title : ''}
						text={element.value || formatMessage({id: 'common.not_found'})}
						onRemoveClick={() => onRemoveFilter(filter.section, filter.filterType, [filter.id])}
						disabled={element.value === null}
					/>
				</BadgeWrapper>
			);
		});

		return filters;
	};

	const getGroupedFilters = () => {
		let elements = [];
		const groups = groupBy(appliedFilters, f => f.section + '-' + f.filterType);
		for (const filters of Object.values(groups)) {
			const section = filters[0].section;
			const filterType = filters[0].filterType;
			const isRequireAll = isFilterOperatorEnabled(
				appliedFilterOperators,
				section,
				filterType,
				FILTER_OPERATOR.REQUIRE_ALL
			);
			const isExclude = isFilterOperatorEnabled(appliedFilterOperators, section, filterType, FILTER_OPERATOR.EXCLUDE);
			elements.push(
				<AppliedFiltersGroup key={section + filterType}>
					<AppliedFiltersGroupHeader>
						<AppliedFiltersTypeLabel>
							{getTypeLabel(filterType, formatMessage, selectedSection)}
						</AppliedFiltersTypeLabel>
						{!!advancedFilter && (
							<AppliedFiltersGroupSectionName>
								({getGroupLabel(section, formatMessage)})
							</AppliedFiltersGroupSectionName>
						)}
						{isExclude && (
							<OperatorBadge color={'#E5F4FF'}>{formatMessage({id: 'common.filter.exclude'})}</OperatorBadge>
						)}
						{isRequireAll && (
							<OperatorBadge color={'#F1F1FD'}>{formatMessage({id: 'common.filter.require_all'})}</OperatorBadge>
						)}
					</AppliedFiltersGroupHeader>
					<AppliedFiltersGroupBadges>{getFilter(filters)}</AppliedFiltersGroupBadges>
				</AppliedFiltersGroup>
			);
		}
		return elements;
	};

	return (
		<AppliedFiltersContainer>
			<AppliedFiltersTop>
				<SmallTitle>{formatMessage({id: 'filter_content.applied_filters'})}</SmallTitle>
				<Buttons>
					<Button
						size={Button.SIZE.SMALL}
						variant={Button.VARIANT.VERY_LIGHT_GRAY_OUTLINE}
						onClick={onClearFilters}
						cy={'clear-filters'}
					>
						{formatMessage({id: 'common.clear'})}
					</Button>
					<Button
						size={Button.SIZE.SMALL}
						variant={Button.VARIANT.GREEN_FILLED}
						disabled={appliedFilters.length === 0}
						onClick={handleSaveFilter}
						userpilot={'filter-save-button'}
						cy={'filter-save-button'}
					>
						{formatMessage({id: 'filter_content.save_filters'})}
					</Button>
				</Buttons>
			</AppliedFiltersTop>
			<CustomScrollDiv style={{height: 'calc(100% - 31px)'}}>
				<AppliedFiltersGroups data-cy={'applied-filters'}>{getGroupedFilters()}</AppliedFiltersGroups>
			</CustomScrollDiv>
		</AppliedFiltersContainer>
	);
};
