/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = [
        (v0 /*: any*/),
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "concreteType": "Role",
        "kind": "LinkedField",
        "name": "role",
        "plural": false,
        "selections": (v1 /*: any*/),
        "storageKey": null
    }, v3 = [
        (v2 /*: any*/)
    ];
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "projectId",
                "type": "ID"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "taskId",
                "type": "ID"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "personId",
                "type": "ID"
            }
        ],
        "kind": "Fragment",
        "metadata": null,
        "name": "RoleDropdown_viewer",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": [
                            {
                                "kind": "Literal",
                                "name": "includeDisabled",
                                "value": false
                            },
                            {
                                "kind": "Variable",
                                "name": "projectId",
                                "variableName": "projectId"
                            }
                        ],
                        "concreteType": "RoleTypeConnection",
                        "kind": "LinkedField",
                        "name": "roles",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "RoleTypeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Role",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": (v1 /*: any*/),
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": [
                            {
                                "kind": "Variable",
                                "name": "id",
                                "variableName": "personId"
                            }
                        ],
                        "concreteType": "Person",
                        "kind": "LinkedField",
                        "name": "person",
                        "plural": false,
                        "selections": [
                            (v0 /*: any*/),
                            (v2 /*: any*/)
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Variable",
                        "name": "internalId",
                        "variableName": "projectId"
                    }
                ],
                "concreteType": "ProjectType",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": [
                            {
                                "kind": "Variable",
                                "name": "personId",
                                "variableName": "personId"
                            }
                        ],
                        "concreteType": "ProjectPerson",
                        "kind": "LinkedField",
                        "name": "projectPerson",
                        "plural": false,
                        "selections": (v3 /*: any*/),
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Variable",
                        "name": "id",
                        "variableName": "taskId"
                    }
                ],
                "concreteType": "Task",
                "kind": "LinkedField",
                "name": "task",
                "plural": false,
                "selections": (v3 /*: any*/),
                "storageKey": null
            }
        ],
        "type": "Viewer"
    };
})();
node.hash = '096091c91878b6503727afe53fa819a0';
export default node;
