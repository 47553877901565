/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = [
        (v0 /*: any*/)
    ];
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "InvoicedBillToWarningModal_viewer",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Literal",
                        "name": "first",
                        "value": 100000
                    }
                ],
                "concreteType": "InvoiceTypeConnection",
                "kind": "LinkedField",
                "name": "invoicesV2",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "InvoiceTypeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "InvoiceType",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    (v0 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "BillTo",
                                        "kind": "LinkedField",
                                        "name": "billTo",
                                        "plural": false,
                                        "selections": (v1 /*: any*/),
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Client",
                                        "kind": "LinkedField",
                                        "name": "client",
                                        "plural": false,
                                        "selections": (v1 /*: any*/),
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ProjectType",
                                        "kind": "LinkedField",
                                        "name": "projects",
                                        "plural": true,
                                        "selections": [
                                            (v0 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "name",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "projectColor",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "companyProjectId",
                                                "storageKey": null
                                            },
                                            {
                                                "args": null,
                                                "kind": "FragmentSpread",
                                                "name": "ProjectIndicator_project"
                                            }
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": "invoicesV2(first:100000)"
            }
        ],
        "type": "Viewer"
    };
})();
node.hash = '330dff84d05be5ba0ed541e0f61c2809';
export default node;
