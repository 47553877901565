import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import Button from '../../../shared/components/buttons/button/button';
import { BUTTON_COLOR, BUTTON_STYLE } from '../../../../constants';
import { trackEvent } from './../../../../tracking/amplitude/TrackingV2';
import { MODAL_TYPE, showModal } from '../../../shared/components/modals/generic_modal_conductor';
import Moment from 'moment';
import { hasFeatureFlag } from '../../../shared/util/FeatureUtil';
const submitTimeOff = ({ nonViewerPersonSelected, selectedPerson }) => {
    trackEvent('Submit Time Off Bottom', 'Clicked', {
        timeRegPerson: nonViewerPersonSelected ? selectedPerson.email : 'myself',
    });
    showModal({
        type: MODAL_TYPE.SUBMIT_TIME_OFF_APPROVAL,
        selectedPersonName: nonViewerPersonSelected ? selectedPerson.firstName : undefined,
        selectedPersonId: selectedPerson.id,
    });
};
const submitTimesheets = ({ nonViewerPersonSelected, selectedPerson, isWeekView, currentViewingDate, lockedDate, lastWorkingDayInWeek, usingTimeOffApproval, }) => {
    trackEvent('Submit Timesheets Bottom', 'Clicked', {
        timeRegPerson: nonViewerPersonSelected ? selectedPerson.email : 'myself',
    });
    const today = Moment();
    const submitDate = !isWeekView
        ? currentViewingDate
        : today.isSame(currentViewingDate, 'week')
            ? today
            : lastWorkingDayInWeek;
    showModal({
        type: MODAL_TYPE.SUBMIT_TIME_APPROVAL,
        selectedPersonName: nonViewerPersonSelected ? selectedPerson.firstName : undefined,
        selectedPersonId: selectedPerson.id,
        currentViewingDate: submitDate,
        lockedDate,
        usingTimeOffApproval,
    });
};
const unsubmitTimesheets = ({ nonViewerPersonSelected, selectedPerson, isWeekView, currentViewingDate, lockedDate, companyLockedDate, }) => {
    trackEvent('Unsubmit Timesheets Bottom', 'Clicked', {
        timeRegPerson: nonViewerPersonSelected ? selectedPerson.email : 'myself',
    });
    const unsubmitDate = !isWeekView ? currentViewingDate : currentViewingDate.clone().startOf('week');
    showModal({
        type: MODAL_TYPE.UNSUBMIT_TIME_APPROVAL,
        selectedPersonName: nonViewerPersonSelected ? selectedPerson.firstName : undefined,
        selectedPersonId: selectedPerson.id,
        currentViewingDate: unsubmitDate,
        companyLockedDate,
        lockedDate,
    });
};
const TimesheetsApprovalButtons = ({ selectedPerson, nonViewerPersonSelected, usingTimeApproval, usingTimeOffApproval, isWeekView, currentViewingDate, lockedDate, companyLockedDate, isFullyLocked, lastWorkingDayInWeek, }) => {
    const { formatMessage } = useIntl();
    const isCompanyLocked = useMemo(() => {
        if (companyLockedDate) {
            return isWeekView
                ? companyLockedDate.isSameOrAfter(currentViewingDate.clone().endOf('week'), 'd')
                : companyLockedDate.isSameOrAfter(currentViewingDate, 'd');
        }
        return false;
    }, [isWeekView, companyLockedDate, currentViewingDate]);
    const isWorkdaysLocked = useMemo(() => {
        if (lockedDate && lastWorkingDayInWeek) {
            return lockedDate.isSameOrAfter(lastWorkingDayInWeek, 'd');
        }
        return false;
    }, [isWeekView, lockedDate, lastWorkingDayInWeek]);
    const messageIdSuffix = (usingTimeOffApproval ? '_and_time_off' : '') + '_info';
    if (!usingTimeApproval && !usingTimeOffApproval)
        return null;
    return (React.createElement(React.Fragment, null,
        usingTimeApproval && isWorkdaysLocked ? (React.createElement(Button, { cy: 'unsubmit-timesheets-button', isDisabled: isCompanyLocked, text: nonViewerPersonSelected
                ? formatMessage({ id: 'time_approval.unsubmit_names_timesheets' }, { name: selectedPerson.firstName })
                : formatMessage({ id: 'time_approval.unsubmit_timesheets' }), buttonStyle: BUTTON_STYLE.OUTLINE_THICK, colorTheme: BUTTON_COLOR.VERYLIGHTGREY, onClick: () => unsubmitTimesheets({
                nonViewerPersonSelected,
                selectedPerson,
                isWeekView,
                currentViewingDate,
                lockedDate,
                companyLockedDate,
            }) })) : usingTimeApproval ? (React.createElement(Button, { cy: 'submit-timesheets-button', isDisabled: isFullyLocked, text: nonViewerPersonSelected
                ? formatMessage({ id: 'time_approval.submit_names_timesheets' }, { name: selectedPerson.firstName })
                : formatMessage({ id: 'time_approval.submit_timesheets' }), buttonStyle: BUTTON_STYLE.OUTLINE_THICK, colorTheme: BUTTON_COLOR.VERYLIGHTGREY, tooltipProps: hasFeatureFlag('pto_timesheet_allocation_linking')
                ? {
                    tooltipDuration: 30000,
                    canBeMiddle: true,
                    tooltipPosition: 'bottom',
                    infoText: nonViewerPersonSelected
                        ? formatMessage({ id: 'time_approval.submit_names_timesheets' + messageIdSuffix }, { name: selectedPerson.firstName })
                        : formatMessage({ id: 'time_approval.submit_timesheets' + messageIdSuffix }),
                }
                : undefined, onClick: () => submitTimesheets({
                nonViewerPersonSelected,
                selectedPerson,
                isWeekView,
                currentViewingDate,
                lockedDate,
                lastWorkingDayInWeek,
                usingTimeOffApproval,
            }) })) : null,
        usingTimeOffApproval ? (React.createElement(Button, { cy: 'submit-time-off-button', isDisabled: isFullyLocked, text: nonViewerPersonSelected
                ? formatMessage({ id: 'time_approval.submit_names_time_off' }, { name: selectedPerson.firstName })
                : formatMessage({ id: 'time_approval.submit_time_off' }), buttonStyle: BUTTON_STYLE.OUTLINE_THICK, colorTheme: BUTTON_COLOR.VERYLIGHTGREY, tooltipProps: {
                tooltipDuration: 30000,
                canBeMiddle: true,
                tooltipPosition: 'bottom',
                infoText: nonViewerPersonSelected
                    ? formatMessage({ id: 'time_off_approval.submit_names_timesheets_info' }, { name: selectedPerson.firstName })
                    : formatMessage({ id: 'time_off_approval.submit_timesheets_info' }),
            }, onClick: () => submitTimeOff({ nonViewerPersonSelected, selectedPerson }) })) : null));
};
export default TimesheetsApprovalButtons;
