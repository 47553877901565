/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type suggestedPersonAndRole_viewer$ref = any;
export type suggestedPersonAndRole_QueryVariables = {|
  projectId?: ?string,
  taskId?: ?string,
  taskName?: ?string,
|};
export type suggestedPersonAndRole_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: suggestedPersonAndRole_viewer$ref,
  |}
|};
export type suggestedPersonAndRole_Query = {|
  variables: suggestedPersonAndRole_QueryVariables,
  response: suggestedPersonAndRole_QueryResponse,
|};
*/


/*
query suggestedPersonAndRole_Query(
  $projectId: ID
  $taskId: ID
  $taskName: String
) {
  viewer {
    actualPersonId
    component(name: "suggested_person_and_role")
    ...suggestedPersonAndRole_viewer_2cw3KX
    id
  }
}

fragment suggestedPersonAndRole_viewer_2cw3KX on Viewer {
  company {
    availableMLModels {
      roleModel
      assigneeModel
    }
    id
  }
  suggestedPersons(first: 100000, taskId: $taskId, taskName: $taskName, projectId: $projectId) {
    edges {
      node {
        id
        active
        profilePictureId
        profilePictureDefaultId
        fullName
        role {
          id
          name
        }
      }
    }
  }
  suggestedRoles(first: 100000, taskId: $taskId, taskName: $taskName, projectId: $projectId) {
    edges {
      node {
        id
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId",
    "type": "ID"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "taskId",
    "type": "ID"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "taskName",
    "type": "String"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "suggested_person_and_role"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"suggested_person_and_role\")"
},
v3 = {
  "kind": "Variable",
  "name": "projectId",
  "variableName": "projectId"
},
v4 = {
  "kind": "Variable",
  "name": "taskId",
  "variableName": "taskId"
},
v5 = {
  "kind": "Variable",
  "name": "taskName",
  "variableName": "taskName"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100000
  },
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/)
],
v8 = [
  (v6/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "suggestedPersonAndRole_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "args": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "suggestedPersonAndRole_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "suggestedPersonAndRole_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AvailableMLModels",
                "kind": "LinkedField",
                "name": "availableMLModels",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "roleModel",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "assigneeModel",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "PersonTypeConnection",
            "kind": "LinkedField",
            "name": "suggestedPersons",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PersonTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Person",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "active",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "profilePictureId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "profilePictureDefaultId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "fullName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Role",
                        "kind": "LinkedField",
                        "name": "role",
                        "plural": false,
                        "selections": (v8/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "RoleTypeConnection",
            "kind": "LinkedField",
            "name": "suggestedRoles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RoleTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Role",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "suggestedPersonAndRole_Query",
    "operationKind": "query",
    "text": "query suggestedPersonAndRole_Query(\n  $projectId: ID\n  $taskId: ID\n  $taskName: String\n) {\n  viewer {\n    actualPersonId\n    component(name: \"suggested_person_and_role\")\n    ...suggestedPersonAndRole_viewer_2cw3KX\n    id\n  }\n}\n\nfragment suggestedPersonAndRole_viewer_2cw3KX on Viewer {\n  company {\n    availableMLModels {\n      roleModel\n      assigneeModel\n    }\n    id\n  }\n  suggestedPersons(first: 100000, taskId: $taskId, taskName: $taskName, projectId: $projectId) {\n    edges {\n      node {\n        id\n        active\n        profilePictureId\n        profilePictureDefaultId\n        fullName\n        role {\n          id\n          name\n        }\n      }\n    }\n  }\n  suggestedRoles(first: 100000, taskId: $taskId, taskName: $taskName, projectId: $projectId) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7c563262a8bf0970015abb4842de5ed8';

module.exports = node;
