import React from 'react';

const AiIcon = props => {
	if (props.useNewIcon) {
		return (
			<svg className={'ai-icon ' + (props.className ? props.className : '')} width={props.customSize ? props.customSize : '14'} height={props.customSize ? props.customSize : '14'} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M10.588 3.95c.78 0 1.412.654 1.412 1.463v5.123c0 .809-.632 1.464-1.412 1.464H1.412C.632 12 0 11.345 0 10.536V5.413C0 4.604.632 3.95 1.412 3.95zM8.824 6.62c-.748 0-1.355.63-1.355 1.405 0 .36.134.683.348.931H9.83a1.42 1.42 0 00.347-.93c0-.777-.606-1.406-1.354-1.406zm-5.647 0c-.748 0-1.355.63-1.355 1.405 0 .36.133.683.347.931h2.015a1.42 1.42 0 00.347-.93c0-.777-.606-1.406-1.354-1.406zM7.462 0c.755 0 1.403.502 1.613 1.21a.896.896 0 00-.522.515.225.225 0 00.42.16.445.445 0 01.46-.28c.643.118 1.141.616 1.262 1.245h-9.39a1.57 1.57 0 011.258-1.244.446.446 0 01.463.28.225.225 0 00.421-.16.896.896 0 00-.474-.501c-.015-.007-.032-.01-.047-.016A1.685 1.685 0 014.538 0c.377 0 .743.128 1.039.363a.225.225 0 01.085.176v.57a.337.337 0 00.676 0v-.57c0-.069.031-.134.085-.176A1.674 1.674 0 017.463 0z"
					fill={props.white ? '#FFF' : '#49bbf5'}
					opacity={props.opacity ? props.opacity : 1}
					fillRule="evenodd"
					className={'ai-icon-path'}
				/>
			</svg>
		);
	} else {
		return (
			<svg className={'ai-icon ' + (props.className ? props.className : '')} xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15">
				<path
					fill="#A1A1A1"
					fillRule="evenodd"
					d="M2.31 7.5h2.94a.75.75 0 0 1 .75.75v3.862L9.69 7.5H6.75A.75.75 0 0 1 6 6.75V2.888L2.31 7.5zM5.25 15a.75.75 0 0 1-.75-.75V9H.75A.751.751 0 0 1 .165 7.78l6-7.5A.75.75 0 0 1 7.5.75V6h3.75a.751.751 0 0 1 .585 1.218l-6 7.5A.749.749 0 0 1 5.25 15z"
				/>
			</svg>
		);
	}
};

export default AiIcon;
