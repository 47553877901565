import {hasFeatureFlag} from './FeatureUtil';

export function isJiraToForecastOneWaySync(company) {
	if (!hasFeatureFlag('jira_to_forecast_one_way_sync')) {
		return false;
	}

	if (company.jiraCloudEnabled) {
		return company.integrations.jiraCloud.syncSettings.isJiraToForecastOneWaySync;
	} else if (company.jiraServerEnabled) {
		return company.integrations.jiraServer.syncSettings.isJiraToForecastOneWaySync;
	} else {
		return false;
	}
}
