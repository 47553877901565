import React from 'react';
import { getFormattedName, getLinkFromVariant, processTextToHTML } from './FormatterUtil';
const getFormattedProjectHighlightField = (highlightField) => {
    switch (highlightField) {
        case 'name': // Name will be shown in the Title field, instead show Project ID in description
        case 'name.shingled_bigram':
        case 'company_project_id':
        case 'company_project_id_fallback':
        case 'custom_project_id':
            return 'Project ID: ';
        case 'client_name_fallback':
        case 'client_name':
            return 'Project for client: ';
        default:
            return `Project with ${highlightField}: `;
    }
};
const getFormattedProjectHighlightValue = (highlightField, highlightValue, customProjectId, companyProjectId, clientName) => {
    switch (highlightField) {
        case 'name':
        case 'name.shingled_bigram':
            return customProjectId || `P${companyProjectId}`;
        case 'company_project_id':
            return React.createElement("em", null, `P${companyProjectId}`);
        case 'company_project_id_fallback':
            return customProjectId || `P${companyProjectId}`;
        case 'client_name_fallback':
            return `${clientName}`;
        // HTML without quotes case
        case 'custom_project_id':
            return React.createElement("span", null, processTextToHTML(highlightValue));
        default:
            return React.createElement("span", null,
                "\"",
                processTextToHTML(highlightValue),
                "\"");
    }
};
const getFormattedProjectHighlight = (highlight, customProjectId, companyProjectId, clientName) => {
    // Default to company_project_id_fallback if highlight is undefined
    const highlightField = highlight
        ? highlight.highlightField
        : clientName && clientName.length > 0
            ? 'client_name_fallback'
            : 'company_project_id_fallback';
    const highlightValue = highlight ? highlight.highlightValue : '';
    const formattedHighlightField = getFormattedProjectHighlightField(highlightField);
    const formattedHighlightValue = getFormattedProjectHighlightValue(highlightField, highlightValue, customProjectId, companyProjectId, clientName);
    return {
        highlightField: formattedHighlightField,
        highlightValue: formattedHighlightValue,
    };
};
export const projectFormatter = ({ searchResult, trackAndHideSearch }) => {
    const onSelect = (rank, openedInNewTab) => {
        trackAndHideSearch({ id: searchResult.id, variant: searchResult.variant, rank, searchResult }, !openedInNewTab);
    };
    const formattedName = getFormattedName(searchResult.name, searchResult.highlight);
    const formattedHighlight = getFormattedProjectHighlight(searchResult.noNameHighlight, searchResult.customProjectId, searchResult.companyProjectId, searchResult.clientName);
    const link = getLinkFromVariant(searchResult.companyProjectId, searchResult.customProjectId, searchResult.variant);
    return {
        title: searchResult.name,
        name: formattedName,
        highlight: formattedHighlight,
        variant: searchResult.variant,
        companyProjectId: searchResult.companyProjectId,
        customProjectId: searchResult.customProjectId || null,
        projectColor: searchResult.projectColor,
        projectIndicatorName: searchResult.name,
        ariaLabel: `Search result. Type: Project. Title: ${searchResult.name}. Highlight: ${formattedHighlight.highlightField}: ${formattedHighlight.highlightValue}`,
        link,
        onSelect,
    };
};
