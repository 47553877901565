import React from 'react';

const priorityIcon = props => {

	const fill = props.fill ?
		props.fill :
		props.active ? '#E4253B' : '#CECECE';

	return (
		<span tabIndex='0' onKeyDown={props.onKeyDown} title={props.title} data-cy={props.cy}>
			{props.active ? (
				<svg onClick={props.onClick ? props.onClick : null}
					 className={'priority-icon ' + (props.className ? props.className : '') + (props.active ? ' active' : '')}
					 width='14' height='16' xmlns='http://www.w3.org/2000/svg'>
					<path data-cy={props.cy ? props.cy + '-path' : ''} d='M0 0v16h1.647V9.6H14V0z' fill={fill}
						  fillRule='evenodd' />
				</svg>
			) : (
				<svg onClick={props.onClick ? props.onClick : null}
					 className={'priority-icon ' + (props.className ? props.className : '') + (props.active ? ' active' : '')}
					 xmlns='http://www.w3.org/2000/svg' width='14' height='16' viewBox='0 0 14 16'>
					<path data-cy={props.cy ? props.cy + '-path' : ''} fill={fill} fillRule='evenodd'
						  d='M0 0v16h1.647V9.6H14V0H0zm1.647 8h10.707V1.6H1.647V8z' />
				</svg>
			)}
		</span>
	);
};

export default priorityIcon;
