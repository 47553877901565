import React from 'react';
import { ButtonDropdown, DropdownList } from '@forecast-it/design-system';
import { FormattedMessage } from 'react-intl';
const ChangeViewButton = ({ children }) => {
    return (React.createElement(DropdownList, null,
        React.createElement(DropdownList.Trigger, null,
            React.createElement(ButtonDropdown, { emphasis: 'medium', "data-cy": 'change-view' },
                React.createElement(FormattedMessage, { id: 'change_view.button' }))),
        React.createElement(DropdownList.Content, { offset: 4 }, children)));
};
export default ChangeViewButton;
