import React from 'react';
import {useIntl} from 'react-intl';
import {SharedReportLoginContextProvider} from './SharedReportLoginContext';
import {isSageSpecificUrl} from '../../../shared/util/UrlUtil';
import Logo, {LOGO_SIZE} from '../../../shared/components/logo/Logo';
import {Content, Page, PageContent} from '../../../../containers/sage-signup-flow/Sage.styled';
import {FlexColumn, FlexContainer} from '@forecast-it/design-system';

const SharedReportLoginWrapper = ({sharedId, refetch}) => {
	const {formatMessage} = useIntl();

	return (
		<>
			{isSageSpecificUrl() ? (
				<Page>
					<Content alignItems="center" justifyContent="center">
						<Logo size={LOGO_SIZE.MEDIUM} />
						<FlexContainer>
							<PageContent gap="xl">
								<FlexColumn gap="l">
									<SharedReportLoginContextProvider
										sharedId={sharedId}
										refetch={refetch}
									></SharedReportLoginContextProvider>
								</FlexColumn>
							</PageContent>
						</FlexContainer>
					</Content>
				</Page>
			) : (
				<div className="login-wrapper">
					<div className="description-panel">
						<div className="description">
							<a href="https://www.forecast.app/">
								<div className="logo" />
								<span>Forecast</span>
							</a>
							<h2>{formatMessage({id: 'running_reminder_modal.greeting'})},</h2>
							<h2>{formatMessage({id: 'login.welcome-greeting'})}</h2>
						</div>
					</div>
					<SharedReportLoginContextProvider sharedId={sharedId} refetch={refetch}></SharedReportLoginContextProvider>
				</div>
			)}
		</>
	);
};

export default SharedReportLoginWrapper;
