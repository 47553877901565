import React, {useRef, useState, useEffect} from 'react';
import {SliderWrapper, FilterContainerWrapper, CarretWrapper} from './filter.styled';
import {matchSaved} from './filter_util';
import {CaretIcon, SavedFilter} from 'web-components';
import Util from '../../util/util';

export const FilterSlider = ({
	intl,
	favouredFilters,
	appliedFiltersList,
	appliedFilterOperators,
	renamingFilter,
	renameFilter,
	changeAppliedFilters,
	filterExpandedKey,
}) => {
	const [sliderExpanding, setSliderExpanding] = useState(false);
	const favouredFiltersRef = useRef([]);
	const [favouredFiltersWidth, setFavouredFiltersWidth] = useState();
	const [sliderExpanded, setSliderExpanded] = useState(true);

	const calculateSliderWidth = () => {
		let width = 0;
		favouredFiltersRef.current.forEach(filter => {
			width += filter ? filter.offsetWidth : 0;
		});
		return width;
	};

	const handleExpandeSlider = () => {
		if (!sliderExpanded) {
			setSliderExpanding(true);
			setTimeout(() => {
				setSliderExpanding(false);
			}, 500);
		}
		Util.localStorageSetItem('favourite-slider-expanded' + filterExpandedKey, !sliderExpanded);
		setSliderExpanded(!sliderExpanded);
	};

	useEffect(() => {
		const isExpanded =
			Util.localStorageGetItemWithDefault('favourite-slider-expanded' + filterExpandedKey, 'true') === 'true';
		setSliderExpanded(isExpanded);
		setFavouredFiltersWidth(calculateSliderWidth());
	});

	useEffect(() => {
		setFavouredFiltersWidth(calculateSliderWidth());
	}, [favouredFilters]);

	return (
		<SliderWrapper
			expanded={sliderExpanded}
			moving={sliderExpanding}
			filtersWidth={favouredFiltersWidth}
			data-userpilot={'filter-favorite-foldout'}
			data-cy={'filter-favorite-foldout'}
		>
			<CarretWrapper onClick={() => handleExpandeSlider()} data-cy={'filter-slider-toggle-expand'}>
				<CaretIcon direction={sliderExpanded ? CaretIcon.DIRECTION.RIGHT : CaretIcon.DIRECTION.LEFT} />
			</CarretWrapper>

			{favouredFilters.map((filter, index) => {
				const isActive = matchSaved(filter, appliedFiltersList, appliedFilterOperators);
				const isRenaming = renamingFilter === filter.id + '-slider';
				return (
					<FilterContainerWrapper
						key={filter.id}
						ref={f => (favouredFiltersRef.current[index] = f)}
						data-active={isActive}
						data-cy={`saved-filter:${filter.name}`}
					>
						<SavedFilter
							inSlider
							edit={isRenaming}
							toggleActivate={() => {
								const args = isActive ? [[], {}] : [filter.value, filter.operators];
								return !isRenaming && changeAppliedFilters(...args);
							}}
							active={isActive}
							text={filter.name}
							onSetChange={name => renameFilter(filter.id, name)}
						/>
					</FilterContainerWrapper>
				);
			})}
		</SliderWrapper>
	);
};
