/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type DeprecatedProjectIndicatorJS_project$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type GenericProjectTemplatesTable_viewer$ref: FragmentReference;
declare export opaque type GenericProjectTemplatesTable_viewer$fragmentType: GenericProjectTemplatesTable_viewer$ref;
export type GenericProjectTemplatesTable_viewer = {|
  +id: string,
  +projects: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +companyProjectId: ?number,
        +useManualAllocations: ?boolean,
        +useBaseline: ?boolean,
        +projectColor: ?string,
        +$fragmentRefs: DeprecatedProjectIndicatorJS_project$ref,
      |}
    |}>
  |},
  +company: ?{|
    +pipedriveCompanyDomain: ?string,
    +hubspotAccountId: ?string,
  |},
  +$refType: GenericProjectTemplatesTable_viewer$ref,
|};
export type GenericProjectTemplatesTable_viewer$data = GenericProjectTemplatesTable_viewer;
export type GenericProjectTemplatesTable_viewer$key = {
  +$data?: GenericProjectTemplatesTable_viewer$data,
  +$fragmentRefs: GenericProjectTemplatesTable_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GenericProjectTemplatesTable_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 10000
        }
      ],
      "concreteType": "ProjectTypeConnection",
      "kind": "LinkedField",
      "name": "projects",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "companyProjectId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "useManualAllocations",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "useBaseline",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "projectColor",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "DeprecatedProjectIndicatorJS_project"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "projects(first:10000)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pipedriveCompanyDomain",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hubspotAccountId",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '0faf329d958929ee0ece0df9a73a5b9f';

module.exports = node;
