import {useCallback, useState, useEffect} from 'react';

function useRoveFocus(size) {
	const [currentFocus, setCurrentFocus] = useState(-1);

	const handleKeyDown = useCallback(
		e => {
			if (e.defaultPrevented) return;

			switch (e.key) {
				case 'ArrowUp':
				case 'Up':
					e.preventDefault();
					setCurrentFocus(currentFocus <= 0 ? size - 1 : currentFocus - 1);
					break;
				case 'ArrowDown':
				case 'Down':
					e.preventDefault();
					setCurrentFocus(currentFocus === size - 1 ? 0 : currentFocus + 1);
					break;
				default:
					return;
			}
		},
		[size, currentFocus, setCurrentFocus]
	);

	useEffect(() => {
		document.addEventListener('keydown', handleKeyDown, false);
		return () => {
			document.removeEventListener('keydown', handleKeyDown, false);
		};
	}, [handleKeyDown]);

	return [currentFocus, setCurrentFocus];
}

export default useRoveFocus;
