import React from 'react';
import {FILTER_TYPE, GLOBAL_FILTER_FIELD, HIDDEN_FEATURES, REPORT_GROUPINGS} from '../../../../constants';
import {convertToGlobalFilter, getUnifiedFilterOperator, isOperator} from '../../../shared/components/filters/filter_logic';
import TimeRegRelayWrapper, {TimeRegRelayWrapperQuery} from './relay-wrappers/TimeRegRelayWrapper';
import TimeRegProjectRelayWrapper, {TimeRegProjectRelayWrapperQuery} from './relay-wrappers/TimeRegProjectRelayWrapper';
import TimeRegPersonRelayWrapper, {TimeRegPersonRelayWrapperQuery} from './relay-wrappers/TimeRegPersonRelayWrapper';
import TimeRegTaskRelayWrapper, {TimeRegTaskRelayWrapperQuery} from './relay-wrappers/TimeRegTaskRelayWrapper';
import TimeRegClientRelayWrapper, {TimeRegClientRelayWrapperQuery} from './relay-wrappers/TimeRegClientRelayWrapper';
import TimeRegSprintRelayWrapper, {TimeRegSprintRelayWrapperQuery} from './relay-wrappers/TimeRegSprintRelayWrapper';
import TimeRegPhaseRelayWrapper, {TimeRegPhaseRelayWrapperQuery} from './relay-wrappers/TimeRegPhaseRelayWrapper';
import ForecastQueryRenderer from '../../../../ForecastQueryRenderer';
import TimeRegRoleRelayWrapper, {TimeRegRoleRelayWrapperQuery} from './relay-wrappers/TimeRegRoleRelayWrapper';
import TimeRegDepartmentRelayWrapper, {
	TimeRegDepartmentRelayWrapperQuery,
} from './relay-wrappers/TimeRegDepartmentRelayWrapper';
import Util from '../../../shared/util/util';

export const getSecondDropdownOptionsByGroupings = (firstDropdownValue, intl) => {
	const secondDropdownOptions = [];
	switch (firstDropdownValue) {
		case REPORT_GROUPINGS.PERSON:
			secondDropdownOptions.push(
				{value: REPORT_GROUPINGS.PROJECT, label: intl.formatMessage({id: 'common.group_by_project'})},
				{value: REPORT_GROUPINGS.TASK, label: intl.formatMessage({id: 'common.group_by_task'})},
				...(!Util.isFeatureHidden(HIDDEN_FEATURES.CLIENT)
					? [{value: REPORT_GROUPINGS.CLIENT, label: intl.formatMessage({id: 'common.group_by_client'})}]
					: []),
				{value: null, label: 'No grouping'}
			);
			break;
		case REPORT_GROUPINGS.PROJECT:
			secondDropdownOptions.push(
				{value: REPORT_GROUPINGS.PERSON, label: intl.formatMessage({id: 'common.group_by_person'})},
				{value: REPORT_GROUPINGS.PHASE, label: intl.formatMessage({id: 'common.group_by_phase'})},
				{value: REPORT_GROUPINGS.SPRINT, label: intl.formatMessage({id: 'common.group_by_sprint'})},
				{value: REPORT_GROUPINGS.TASK, label: intl.formatMessage({id: 'common.group_by_task'})},
				{value: null, label: 'No grouping'}
			);
			break;

		case REPORT_GROUPINGS.TASK:
			secondDropdownOptions.push(
				{value: REPORT_GROUPINGS.PERSON, label: intl.formatMessage({id: 'common.group_by_person'})},
				{value: null, label: 'No grouping'}
			);
			break;
		case REPORT_GROUPINGS.CLIENT:
			secondDropdownOptions.push(
				{value: REPORT_GROUPINGS.PERSON, label: intl.formatMessage({id: 'common.group_by_person'})},
				{value: REPORT_GROUPINGS.PROJECT, label: intl.formatMessage({id: 'common.group_by_project'})},
				{value: null, label: 'No grouping'}
			);
			break;
		case REPORT_GROUPINGS.DEPARTMENT:
			secondDropdownOptions.push(
				{value: REPORT_GROUPINGS.PERSON, label: intl.formatMessage({id: 'common.group_by_person'})},
				{value: REPORT_GROUPINGS.PROJECT, label: intl.formatMessage({id: 'common.group_by_project'})},
				//{value: REPORT_GROUPINGS.ROLE, label: intl.formatMessage({id: 'common.group_by_role'})},
				{value: null, label: 'No grouping'}
			);
			break;
		case REPORT_GROUPINGS.ROLE:
			secondDropdownOptions.push(
				{value: REPORT_GROUPINGS.PERSON, label: intl.formatMessage({id: 'common.group_by_person'})},
				{value: REPORT_GROUPINGS.PROJECT, label: intl.formatMessage({id: 'common.group_by_project'})},
				{value: null, label: 'No grouping'}
			);
			break;
		case null:
			return null;
		default:
			return 'No second dropdown options provided!';
	}
	return secondDropdownOptions;
};

export const getSearchQueryFiltersValue = (defaultSearchQueryFilters, filters) => {
	const updatedFilters = [...defaultSearchQueryFilters];
	if (filters !== null && filters.timeRegs) {
		const timeRegFilters = filters.timeRegs;
		for (let key of Object.keys(timeRegFilters)) {
			if (isOperator(key)) {
				continue;
			}

			const value = timeRegFilters[key];
			const unifiedFilterOperator = getUnifiedFilterOperator(key, timeRegFilters);

			if (key === FILTER_TYPE.APPROUVED) {
				updatedFilters.push({
					field: GLOBAL_FILTER_FIELD.TASK_APPROVED,
					operator: unifiedFilterOperator,
					value: value.map(val => (val === 'approved' ? true.toString() : false.toString())),
				});
			}
			if (key === FILTER_TYPE.PERSON) {
				updatedFilters.push({
					field: GLOBAL_FILTER_FIELD.PERSON_TIME_REG,
					operator: unifiedFilterOperator,
					value: value,
				});
			}
			if (key === FILTER_TYPE.PROJECT) {
				updatedFilters.push({
					field: GLOBAL_FILTER_FIELD.PROJECT_TIME_REG,
					operator: unifiedFilterOperator,
					value: value,
				});
			}
			if (key === FILTER_TYPE.ROLE) {
				updatedFilters.push({
					field: GLOBAL_FILTER_FIELD.ROLE_TIME_REG,
					operator: unifiedFilterOperator,
					value: value,
				});
			}
			if (key === FILTER_TYPE.TEAM) {
				updatedFilters.push({
					field: GLOBAL_FILTER_FIELD.TEAM_TIME_REG,
					operator: unifiedFilterOperator,
					value: value,
				});
			}
			if (key === FILTER_TYPE.DEPARTMENT) {
				updatedFilters.push({
					field: GLOBAL_FILTER_FIELD.DEPARTMENT_TIME_REG,
					operator: unifiedFilterOperator,
					value: value,
				});
			}
			if (key === FILTER_TYPE.CLIENT) {
				updatedFilters.push({
					field: GLOBAL_FILTER_FIELD.CLIENT_TIME_REG,
					operator: unifiedFilterOperator,
					value: value,
				});
			}
			if (key === FILTER_TYPE.BILLABLE) {
				updatedFilters.push({
					field: GLOBAL_FILTER_FIELD.BILLABLE_TIME_REG,
					operator: unifiedFilterOperator,
					value: value,
				});
			}
			if (key === FILTER_TYPE.INTERNAL_TIME) {
				updatedFilters.push({
					field: GLOBAL_FILTER_FIELD.INTERNAL_TIME_REG,
					operator: unifiedFilterOperator,
					value: value,
				});
			}
			if (key === FILTER_TYPE.TIME_OFF) {
				updatedFilters.push({
					field: GLOBAL_FILTER_FIELD.TIME_OFF_TIME_REG,
					operator: unifiedFilterOperator,
					value: value,
				});
			}
			if (key === FILTER_TYPE.PROJECT_TYPE) {
				updatedFilters.push(convertToGlobalFilter(key, value, filters.timeRegs));
			}
			if (key === FILTER_TYPE.INVOICED) {
				updatedFilters.push({
					field: GLOBAL_FILTER_FIELD.INVOICED,
					operator: unifiedFilterOperator,
					value: value,
				});
			}
			if (key === FILTER_TYPE.LABEL_TIME_REG) {
				updatedFilters.push({
					field: GLOBAL_FILTER_FIELD.LABEL_TIME_REG,
					operator: unifiedFilterOperator,
					value: value,
				});
			}
			if (key === FILTER_TYPE.PROJECT_OWNER_TIME_REG_REPORT || key === FILTER_TYPE.OWNER) {
				const existing = updatedFilters.find(filter => filter.field === GLOBAL_FILTER_FIELD.OWNER);
				if (existing && existing.value) {
					existing.value = [...value, ...existing.value];
				} else {
					updatedFilters.push({
						field: GLOBAL_FILTER_FIELD.OWNER,
						operator: unifiedFilterOperator,
						value: value,
					});
				}
			}
		}
	}
	return updatedFilters;
};

const pageSize = 30;

const addTimeRegRow = object => {
	if (object !== null) {
		return object.field === GLOBAL_FILTER_FIELD.NON_PROJECT_TIME_REG;
	}
};

export const getRelayWrapper = (
	tableHeader,
	groupings,
	searchQuery,
	groupIndex,
	enabledColumns,
	relayProps,
	object,
	nextLevelProps,
	currency,
	customFieldColumnCount,
	customFieldKeys
) => {
	switch (groupings[0]) {
		case REPORT_GROUPINGS.PROJECT:
			return (
				<TimeRegProjectRelayWrapper
					{...relayProps}
					tableHeader={tableHeader}
					groupings={groupings.slice(1)}
					searchQuery={searchQuery}
					groupIndex={groupIndex + 1}
					enabledColumns={enabledColumns}
					nextLevelProps={nextLevelProps}
					currency={currency}
					customFieldColumnCount={customFieldColumnCount}
					customFieldKeys={customFieldKeys}
					pageSize={pageSize}
				/>
			);
		case REPORT_GROUPINGS.PERSON:
			return (
				<TimeRegPersonRelayWrapper
					{...relayProps}
					tableHeader={tableHeader}
					groupings={groupings.slice(1)}
					searchQuery={searchQuery}
					groupIndex={groupIndex + 1}
					enabledColumns={enabledColumns}
					nextLevelProps={nextLevelProps}
					currency={currency}
					customFieldColumnCount={customFieldColumnCount}
					customFieldKeys={customFieldKeys}
					pageSize={pageSize}
				/>
			);
		case 'timeReg':
			return (
				<TimeRegRelayWrapper
					{...relayProps}
					tableHeader={tableHeader}
					searchQuery={searchQuery}
					groupIndex={groupIndex + 1}
					enabledColumns={enabledColumns}
					nextLevelProps={nextLevelProps}
					currency={currency}
					customFieldColumnCount={customFieldColumnCount}
					customFieldKeys={customFieldKeys}
					pageSize={pageSize}
				/>
			);
		case REPORT_GROUPINGS.TASK:
			if (addTimeRegRow(object) === true) {
				return (
					<TimeRegRelayWrapper
						{...relayProps}
						tableHeader={tableHeader}
						searchQuery={searchQuery}
						groupIndex={groupIndex + 2}
						enabledColumns={enabledColumns}
						nextLevelProps={nextLevelProps}
						currency={currency}
						customFieldColumnCount={customFieldColumnCount}
						customFieldKeys={customFieldKeys}
						pageSize={pageSize}
					/>
				);
			}
			return (
				<TimeRegTaskRelayWrapper
					{...relayProps}
					tableHeader={tableHeader}
					groupings={groupings.slice(1)}
					searchQuery={searchQuery}
					groupIndex={groupIndex + 1}
					enabledColumns={enabledColumns}
					nextLevelProps={nextLevelProps}
					currency={currency}
					customFieldColumnCount={customFieldColumnCount}
					customFieldKeys={customFieldKeys}
					pageSize={pageSize}
				/>
			);
		case REPORT_GROUPINGS.SPRINT:
			if (addTimeRegRow(object) === true) {
				return (
					<TimeRegRelayWrapper
						{...relayProps}
						tableHeader={tableHeader}
						searchQuery={searchQuery}
						groupIndex={groupIndex + 2}
						enabledColumns={enabledColumns}
						nextLevelProps={nextLevelProps}
						currency={currency}
						customFieldColumnCount={customFieldColumnCount}
						customFieldKeys={customFieldKeys}
						pageSize={pageSize}
					/>
				);
			}
			return (
				<TimeRegSprintRelayWrapper
					{...relayProps}
					tableHeader={tableHeader}
					groupings={groupings.slice(1)}
					searchQuery={searchQuery}
					groupIndex={groupIndex + 1}
					enabledColumns={enabledColumns}
					nextLevelProps={nextLevelProps}
					currency={currency}
					customFieldColumnCount={customFieldColumnCount}
					customFieldKeys={customFieldKeys}
					pageSize={pageSize}
				/>
			);
		case REPORT_GROUPINGS.PHASE:
			if (addTimeRegRow(object) === true) {
				return (
					<TimeRegRelayWrapper
						{...relayProps}
						tableHeader={tableHeader}
						searchQuery={searchQuery}
						groupIndex={groupIndex + 2}
						enabledColumns={enabledColumns}
						nextLevelProps={nextLevelProps}
						currency={currency}
						customFieldColumnCount={customFieldColumnCount}
						customFieldKeys={customFieldKeys}
						pageSize={pageSize}
					/>
				);
			} else
				return (
					<TimeRegPhaseRelayWrapper
						{...relayProps}
						tableHeader={tableHeader}
						groupings={groupings.slice(1)}
						searchQuery={searchQuery}
						groupIndex={groupIndex + 1}
						enabledColumns={enabledColumns}
						nextLevelProps={nextLevelProps}
						currency={currency}
						customFieldColumnCount={customFieldColumnCount}
						customFieldKeys={customFieldKeys}
						pageSize={pageSize}
					/>
				);
		case REPORT_GROUPINGS.CLIENT:
			return (
				<TimeRegClientRelayWrapper
					{...relayProps}
					tableHeader={tableHeader}
					groupings={groupings.slice(1)}
					searchQuery={searchQuery}
					groupIndex={groupIndex + 1}
					enabledColumns={enabledColumns}
					nextLevelProps={nextLevelProps}
					currency={currency}
					customFieldColumnCount={customFieldColumnCount}
					customFieldKeys={customFieldKeys}
					pageSize={pageSize}
				/>
			);
		case REPORT_GROUPINGS.ROLE:
			return (
				<TimeRegRoleRelayWrapper
					{...relayProps}
					tableHeader={tableHeader}
					groupings={groupings.slice(1)}
					searchQuery={searchQuery}
					groupIndex={groupIndex + 1}
					enabledColumns={enabledColumns}
					nextLevelProps={nextLevelProps}
					currency={currency}
					customFieldColumnCount={customFieldColumnCount}
					customFieldKeys={customFieldKeys}
					pageSize={pageSize}
				/>
			);
		case REPORT_GROUPINGS.DEPARTMENT:
			return (
				<TimeRegDepartmentRelayWrapper
					{...relayProps}
					tableHeader={tableHeader}
					groupings={groupings.slice(1)}
					searchQuery={searchQuery}
					groupIndex={groupIndex + 1}
					enabledColumns={enabledColumns}
					nextLevelProps={nextLevelProps}
					currency={currency}
					customFieldColumnCount={customFieldColumnCount}
					customFieldKeys={customFieldKeys}
					pageSize={pageSize}
				/>
			);
		default:
			return '(reported_time_page_util)No relay wrapper provided!';
	}
};

const getComponentName = (groupings, object) => {
	switch (groupings[0]) {
		case REPORT_GROUPINGS.PROJECT:
			return 'time_reg_project_relay_wrapper';
		case REPORT_GROUPINGS.PERSON:
			return 'time_reg_person_relay_wrapper';
		case 'timeReg':
			return 'time_reg_relay_wrapper';
		case REPORT_GROUPINGS.TASK:
			if (addTimeRegRow(object) === true) {
				return 'time_reg_relay_wrapper';
			}
			return 'time_reg_task_relay_wrapper';
		case REPORT_GROUPINGS.SPRINT:
			if (addTimeRegRow(object) === true) {
				return 'time_reg_sprint_relay_wrapper';
			}
			return 'sprint_relay_wrapper';
		case REPORT_GROUPINGS.PHASE:
			if (addTimeRegRow(object) === true) {
				return 'time_reg_relay_wrapper';
			} else return 'time_reg_phase_relay_wrapper';
		case REPORT_GROUPINGS.CLIENT:
			return 'time_reg_client_relay_wrapper';
		case REPORT_GROUPINGS.ROLE:
			if (addTimeRegRow(object) === true) {
				return 'time_reg_relay_wrapper';
			} else return 'time_reg_role_relay_wrapper';
		case REPORT_GROUPINGS.DEPARTMENT:
			if (addTimeRegRow(object) === true) {
				return 'time_reg_relay_wrapper';
			} else return 'time_reg_department_relay_wrapper';
		default:
			return 'No component name provided!';
	}
};

const getQuery = (groupings, object) => {
	switch (groupings[0]) {
		case REPORT_GROUPINGS.PROJECT:
			return TimeRegProjectRelayWrapperQuery;
		case REPORT_GROUPINGS.PERSON:
			return TimeRegPersonRelayWrapperQuery;
		case 'timeReg':
			return TimeRegRelayWrapperQuery;
		case REPORT_GROUPINGS.TASK:
			if (addTimeRegRow(object) === true) {
				return TimeRegRelayWrapperQuery;
			}
			return TimeRegTaskRelayWrapperQuery;
		case REPORT_GROUPINGS.CLIENT:
			return TimeRegClientRelayWrapperQuery;
		case REPORT_GROUPINGS.SPRINT:
			if (addTimeRegRow(object) === true) {
				return TimeRegRelayWrapperQuery;
			}
			return TimeRegSprintRelayWrapperQuery;
		case REPORT_GROUPINGS.PHASE:
			if (addTimeRegRow(object) === true) {
				return TimeRegRelayWrapperQuery;
			} else return TimeRegPhaseRelayWrapperQuery;
		case REPORT_GROUPINGS.ROLE:
			if (addTimeRegRow(object) === true) {
				return TimeRegRelayWrapperQuery;
			} else return TimeRegRoleRelayWrapperQuery;
		case REPORT_GROUPINGS.DEPARTMENT:
			if (addTimeRegRow(object) === true) {
				return TimeRegRelayWrapperQuery;
			} else return TimeRegDepartmentRelayWrapperQuery;
		default:
			return 'No query provided!';
	}
};
const keyReducer = (accumulator, currentValue) => accumulator + currentValue.value[0];

const getQueryInfo = (groupings, searchQuery, object) => {
	const queryInfo = {};
	const keyEnding = searchQuery.filters.length > 0 && searchQuery.filters.reduce(keyReducer);
	queryInfo.key = 'query-render-relayWrapper' + keyEnding;
	queryInfo.componentName = getComponentName(groupings, object);
	queryInfo.query = getQuery(groupings, object);
	return queryInfo;
};

export const getQueryRenderer = (
	tableHeader,
	groupings,
	searchQuery,
	groupIndex,
	enabledColumns,
	object,
	nextLevelProps,
	currency,
	reportId,
	sharedId,
	customFieldColumnCount,
	customFieldKeys
) => {
	const searchQueryFiltersCopy = [...searchQuery.filters];
	if (object !== null) {
		searchQueryFiltersCopy.push(object);
	}
	const newSearchQuery = {filters: searchQueryFiltersCopy};
	const query = getQueryInfo(groupings, searchQuery, object);
	return (
		<ForecastQueryRenderer
			key={query.key}
			query={query.query}
			renderIfNotLoggedIn={true}
			variables={{
				pageSize,
				searchQuery: newSearchQuery,
				reportId,
				sharedId,
				customFieldKeys: customFieldKeys,
			}}
			render={relayProps => {
				return getRelayWrapper(
					tableHeader,
					groupings,
					newSearchQuery,
					groupIndex,
					enabledColumns,
					relayProps,
					object,
					nextLevelProps,
					currency,
					customFieldColumnCount,
					customFieldKeys
				);
			}}
		/>
	);
};
