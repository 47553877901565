import React from 'react';
import { ValueCell } from '../../../../../../components/initial-plan/ValueCell';
import { Data, HorizontalBar, Title, ValueContainer } from '../ProjectScopingTotals_styled';
import { Tile, TileRow } from './Tile_styled';
export const FixedPriceMiddleTile = ({ totalsData, settings, intl }) => {
    return (React.createElement(Tile, null,
        React.createElement(TileRow, null,
            React.createElement(Title, { singleLine: true }, intl.formatMessage({ id: 'common.actual_hours' })),
            React.createElement(Data, { small: true },
                React.createElement(ValueContainer, null,
                    React.createElement(ValueCell, { noJustify: true, cy: 'actual_hours', type: ValueCell.VALUE_TYPE.TIME, value: totalsData.actualHours, currencySymbol: settings.currencySymbol })))),
        React.createElement(TileRow, null,
            React.createElement(Title, { singleLine: true }, intl.formatMessage({ id: 'common.projected_total_hours' })),
            React.createElement(Data, { small: true },
                React.createElement(ValueContainer, null,
                    React.createElement(ValueCell, { noJustify: true, cy: 'projected_total_hours', type: ValueCell.VALUE_TYPE.TIME, value: totalsData.projectedTotalHours, currencySymbol: settings.currencySymbol })))),
        React.createElement(HorizontalBar, null),
        React.createElement(TileRow, null,
            React.createElement(Title, { singleLine: true }, intl.formatMessage({ id: 'common.actual_billable_hours' })),
            React.createElement(Data, { small: true },
                React.createElement(ValueContainer, null,
                    React.createElement(ValueCell, { noJustify: true, cy: 'actual_billable_hours', type: ValueCell.VALUE_TYPE.TIME, value: totalsData.actualBillableHours, currencySymbol: settings.currencySymbol })))),
        React.createElement(TileRow, null,
            React.createElement(Title, { singleLine: true }, intl.formatMessage({ id: 'common.actual_non_billable_hours' })),
            React.createElement(Data, { small: true },
                React.createElement(ValueContainer, null,
                    React.createElement(ValueCell, { noJustify: true, cy: 'actual_non_billable_hours', type: ValueCell.VALUE_TYPE.TIME, value: totalsData.actualNonBillableHours }))))));
};
