import {
	getProjectDates,
	getTaskDates,
} from '../../../util/time-registration/time-registration-settings/TimeRegistrationDateUtil_BackwardsCompatibility';
import {isBillableSplitAllowed} from '../../../util/cache/TimeRegistrationSettingsUtil';

export const formatProjectOption = project => {
	const projectNode = project.node ? project.node : project;
	return {
		value: projectNode.id,
		label: projectNode.name,
		name: projectNode.name, // Needed for projectIndicator
		projectId: projectNode.id,
		projectColor: projectNode.projectColor,
		harvestProject: projectNode.harvestProject,
		unit4Project: projectNode.unit4Project,
		companyProjectId: projectNode.companyProjectId,
		customProjectId: projectNode.customProjectId,
		projectPerson: projectNode.projectPerson,
		billable: projectNode.billable,
		cy: 'project-option-' + projectNode.name,
		...getProjectDates(projectNode),
	};
};

export const formatTaskOption = task => {
	const taskNode = task.node ? task.node : task;
	return {
		value: taskNode.id,
		label: 'T' + taskNode.companyTaskId + ' ' + taskNode.name,
		name: taskNode.project.name, // Needed for projectIndicator
		projectColor: taskNode.project.projectColor,
		companyProjectId: taskNode.project.companyProjectId,
		customProjectId: taskNode.project.customProjectId,
		companyTaskId: taskNode.companyTaskId,
		project: formatProjectOption(taskNode.project),
		timeLeft: taskNode.timeLeft,
		estimateForecastMinutes: taskNode.estimateForecastMinutes,
		totalMinutesRegistered: taskNode.totalMinutesRegistered,
		parentTaskId: taskNode.parentTaskId,
		billable: taskNode.billable,
		role: taskNode.role,
		cy: 'task-option-' + taskNode.name?.replace(' ', ''),
		jiraId: taskNode.jiraId,
		...getTaskDates(taskNode),
	};
};

export const formatIdleTimeOption = idleTime => {
	const idleTimeNode = idleTime.node ? idleTime.node : idleTime;
	return {
		value: idleTimeNode.id,
		label: idleTimeNode.name,
		idleTimeId: idleTimeNode.id,
		isInternalTime: idleTimeNode.isInternalTime,
	};
};

export const formatTaskOptions = (tasks, formatMessage, overBudgetProgramsByCompanyProjectId) => {
	const taskOptions = [];
	let taskObjects = [];
	let unavailableTaskObjects = [];
	if (tasks && tasks.edges.length > 0) {
		for (const task of tasks.edges) {
			const taskObj = formatTaskOption(task);
			if (
				overBudgetProgramsByCompanyProjectId &&
				!isBillableSplitAllowed() &&
				overBudgetProgramsByCompanyProjectId.find(project => project.companyProjectId === taskObj.companyProjectId) &&
				taskObj.billable
			) {
				unavailableTaskObjects.push(taskObj);
				unavailableTaskObjects.sort((a, b) => a.companyProjectId - b.companyProjectId);
			} else {
				taskObjects.push(taskObj);
				taskObjects.sort((a, b) => a.companyProjectId - b.companyProjectId);
			}
		}
	}

	taskOptions.push({
		value: 'tasks',
		label: formatMessage({id: 'common.available_tasks'}),
		nestedOptions: taskObjects,
		forceExpand: true,
	});
	if (unavailableTaskObjects.length !== 0) {
		taskOptions.push({
			value: 'tasks',
			label: formatMessage({id: 'common.unavailable_tasks'}),
			nestedOptions: unavailableTaskObjects,
			forceExpand: true,
			unavailableItemLabel: formatMessage({id: 'common.unavailable_tasks'}),
			unavailableItemLabelTooltipContent: formatMessage({id: 'timesheets.unavailable_billable_tasks_message'}),
		});
	}

	return taskOptions;
};
