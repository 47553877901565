import React, { useEffect } from 'react';
import { Button } from '@forecast-it/design-system';
import { CommitMutation } from '../../../../../mutations/ts/CommitMutation';
import OptOutOfFeatureFlagMutation from '../../../../../mutations/ts/OptOutOfFeatureFlagMutation';
import { triggerUserPilotOnceIfScheduled } from '../../../util/UserPilotUtil';
const optOutOfFeatureFlag = (featureFlag, onSuccess) => {
    CommitMutation(OptOutOfFeatureFlagMutation, { featureFlag: featureFlag }, onSuccess);
};
const OptOutOfFeatureFlagButton = ({ featureFlag, userPilotContentId, onSuccess, }) => {
    useEffect(() => {
        triggerUserPilotOnceIfScheduled(userPilotContentId);
    }, [userPilotContentId]);
    return (React.createElement(Button, { emphasis: 'low', onClick: () => optOutOfFeatureFlag(featureFlag, onSuccess) }, "Switch to classic look"));
};
export default OptOutOfFeatureFlagButton;
