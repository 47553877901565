import {SOCKET_ACTION, SOCKET_EVENT_TYPE} from '../../../constants';

export const getSocketConfig = (personIds, taskIds) => {
	return [
		{
			type: SOCKET_EVENT_TYPE.TIME_REG,
			action: SOCKET_ACTION.CREATE,
			taskIds: taskIds,
		},
		{
			type: SOCKET_EVENT_TYPE.TIME_REG,
			action: SOCKET_ACTION.UPDATE,
			taskIds: taskIds,
		},
		{
			type: SOCKET_EVENT_TYPE.TIME_REG,
			action: SOCKET_ACTION.DELETE,
			taskIds: taskIds,
		},
		{
			type: SOCKET_EVENT_TYPE.TIME_LOCK,
			action: SOCKET_ACTION.UPDATE,
			personIds: personIds,
		},
		{
			type: SOCKET_EVENT_TYPE.TASK,
			action: SOCKET_ACTION.DELETE,
			personIds: personIds,
		},
		{
			type: SOCKET_EVENT_TYPE.TASK,
			action: SOCKET_ACTION.UPDATE,
			personIds: personIds,
		},
		{
			type: SOCKET_EVENT_TYPE.PROJECT_PERSON,
			action: SOCKET_ACTION.DELETE,
			personIds: personIds,
		},
	];
};

// Stats should only update from stuff that the selectedPerson is assigned to or doing
export const getStatsSocketConfig = personIds => {
	return [
		{
			type: SOCKET_EVENT_TYPE.TIME_REG,
			action: SOCKET_ACTION.CREATE,
			personIds: personIds,
		},
		{
			type: SOCKET_EVENT_TYPE.TIME_REG,
			action: SOCKET_ACTION.UPDATE,
			personIds: personIds,
		},
		{
			type: SOCKET_EVENT_TYPE.TIME_REG,
			action: SOCKET_ACTION.DELETE,
			personIds: personIds,
		},
		{
			type: SOCKET_EVENT_TYPE.TASK,
			action: SOCKET_ACTION.DELETE,
			personIds: personIds,
		},
		{
			type: SOCKET_EVENT_TYPE.TASK,
			action: SOCKET_ACTION.UPDATE,
			personIds: personIds,
		},
		{
			type: SOCKET_EVENT_TYPE.PROJECT_PERSON,
			action: SOCKET_ACTION.DELETE,
			personIds: personIds,
		},
		{
			type: SOCKET_EVENT_TYPE.PROJECT,
			action: SOCKET_ACTION.DELETE,
			personIds: personIds,
		},
	];
};

export const getStarredSocketConfig = personIds => {
	return [
		{
			type: SOCKET_EVENT_TYPE.TASK,
			action: SOCKET_ACTION.DELETE,
			personIds: personIds,
		},
		{
			type: SOCKET_EVENT_TYPE.TASK,
			action: SOCKET_ACTION.UPDATE,
			personIds: personIds,
		},
	];
};
