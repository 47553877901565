import { TimeRegistrationNotAllowedReason } from './TimeRegistrationNotAllowedReason';
import ProjectUtil from '../../../util/project_util';
import { isDoneTaskTimeRegistrationAllowed, isTimeRegistrationTaskAssignmentRequired, } from '../../cache/TimeRegistrationSettingsUtil';
function notDoneClause(statusColumnCategory) {
    if (!isDoneTaskTimeRegistrationAllowed()) {
        return statusColumnCategory !== 'DONE';
    }
    return true;
}
function assignmentClause(personId, assignedPersonIds) {
    if (isTimeRegistrationTaskAssignmentRequired()) {
        return assignedPersonIds === null || assignedPersonIds === void 0 ? void 0 : assignedPersonIds.includes(personId);
    }
    return true;
}
function noManualProgressClause(project) {
    return !ProjectUtil.projectUsesManualProgress(project);
}
export function isTaskTimeRegistrationAllowed(statusColumnCategory, personId, assignedPersonIds, project) {
    return (notDoneClause(statusColumnCategory) && assignmentClause(personId, assignedPersonIds) && noManualProgressClause(project));
}
export function getTaskTimeRegistrationNotAllowedWithReason(statusColumnCategory, personId, assignedPersonIds) {
    if (!notDoneClause(statusColumnCategory)) {
        return TimeRegistrationNotAllowedReason.NoDoneTask;
    }
    if (!assignmentClause(personId, assignedPersonIds)) {
        return TimeRegistrationNotAllowedReason.NoUnassignedTask;
    }
    return null;
}
