/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type SalesforceConfigurationConductor_viewer$ref: FragmentReference;
declare export opaque type SalesforceConfigurationConductor_viewer$fragmentType: SalesforceConfigurationConductor_viewer$ref;
export type SalesforceConfigurationConductor_viewer = {|
  +company: ?{|
    +newSalesforceEnabled: ?boolean
  |},
  +$refType: SalesforceConfigurationConductor_viewer$ref,
|};
export type SalesforceConfigurationConductor_viewer$data = SalesforceConfigurationConductor_viewer;
export type SalesforceConfigurationConductor_viewer$key = {
  +$data?: SalesforceConfigurationConductor_viewer$data,
  +$fragmentRefs: SalesforceConfigurationConductor_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SalesforceConfigurationConductor_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "newSalesforceEnabled",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
// prettier-ignore
(node/*: any*/).hash = '34be013738256a443867af8e4d376ae1';

module.exports = node;
