import React, {useState} from 'react';
import {createRefetchContainer, graphql} from 'react-relay';
import SettingsProjectSubNav from './SettingsProjectSubNav';
import {FormattedHTMLMessage, useIntl} from 'react-intl';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import * as tracking from '../../../tracking';
import Styled from 'styled-components/macro';
import {PlusIcon} from 'web-components';
import PriorityLevelRow from './PriorityLevelRow';
import {createToast} from '../../shared/components/toasts/toast';
import CreateOrUpdatePriorityLevelsBulkMutationModern from '../../../mutations/create_or_update_priority_levels_bulk_mutation.modern';
import DeletePriorityLevelMutation from '../../../mutations/delete_priority_level_mutation.modern';
import Util from '../../shared/util/util';
import {AddNewPriorityLevel} from './AddNewPriorityLevel';
import {Background} from './Background';
import {ForecastThemeProvider} from '../../shared/ForecastThemeProvider';

const Page = Styled.div`
	max-width: 1000px;
    margin-left: 24px;
    margin-top: 6px;
    font-size: 13px;
	`;

const PrioritiesList = Styled.div`
		margin-top: 24px;
		max-width: 500px;
	`;

const DraggableContainer = Styled.div`
		width: 500px;
		height: 1000px;
		position: relative;
    top: -1000px;
    left: 64px;
    z-index: 2;	   
	`;

const persistPriorityLevelList = (priorityLevelsList, formatMessage) => {
	const priorityLevelsInputTypeList = priorityLevelsList.map(e => ({id: e.id, name: e.name, disabled: e.disabled}));
	Util.CommitMutation(
		CreateOrUpdatePriorityLevelsBulkMutationModern,
		{
			priorityLevels: priorityLevelsInputTypeList,
		},
		data => {
			if (data) {
				createToast({
					duration: 5000,
					message: formatMessage({id: 'common.changes_saved'}),
				});
			}
		}
	);
};

const deletePriorityLevel = (idToDelete, replacementId, priorityLevels, formatMessage) => {
	if (idToDelete) {
		tracking.trackEvent(`Settings - Priority Levels - Deleted`, {replacementIdProvided: replacementId !== null});
		Util.CommitMutation(
			DeletePriorityLevelMutation,
			{
				priorityLevelIdToDelete: idToDelete,
				replacementPriorityLevelId: replacementId,
			},
			data => {
				if (data) {
					createToast({
						duration: 5000,
						message: formatMessage({id: 'common.changes_saved'}),
					});
				}
			}
		);
	}
};

const onDragEnd = (data, priorityLevels, formatMessage) => {
	if (!data.source) {
		return;
	}
	const sourceIndex = data.source.index;
	const destinationIndex = data.destination ? data.destination.index : sourceIndex;

	const arrayCopy = Array.from(priorityLevels);
	const element = arrayCopy[sourceIndex];
	arrayCopy.splice(sourceIndex, 1);
	arrayCopy.splice(destinationIndex, 0, element);

	persistPriorityLevelList(arrayCopy, formatMessage);
	tracking.trackEvent('Settings - Priority Levels - Changed order');
};

const SettingsPriorityLevels = React.memo(({viewer}) => {
	const {formatMessage} = useIntl();
	const priorityLevels = viewer.company.priorityLevels.edges
		.map(edge => edge.node)
		.sort((a, b) => {
			return b.weight - a.weight;
		});
	const [newPriorityName, setNewPriorityName] = useState('');

	const addNewPriorityLevel = () => {
		const newPriorityLevels = [...priorityLevels, {name: newPriorityName}];
		setNewPriorityName('');
		persistPriorityLevelList(newPriorityLevels, formatMessage);
		tracking.trackEvent('Settings - Priority Levels - Add new');
	};

	const updatePriorityLevelName = (id, newName) => {
		const newPriorityLevels = [];
		priorityLevels.forEach(priorityLevel => {
			const updatedPriorityLevel = {...priorityLevel};
			if (updatedPriorityLevel.id === id) {
				updatedPriorityLevel.name = newName;
			}
			newPriorityLevels.push(updatedPriorityLevel);
		});
		persistPriorityLevelList(newPriorityLevels, formatMessage);
		tracking.trackEvent('Settings - Priority Levels - Change Name', {id, newName});
	};

	const disablePriorityLevel = (id, disabledState) => {
		const newPriorityLevels = [];
		priorityLevels.forEach(priorityLevel => {
			const updatedPriorityLevel = {...priorityLevel};
			if (updatedPriorityLevel.id === id) {
				updatedPriorityLevel.disabled = disabledState;
			}
			newPriorityLevels.push(updatedPriorityLevel);
		});
		persistPriorityLevelList(newPriorityLevels, formatMessage);
		tracking.trackEvent('Settings - Priority Levels - Toggle Disabled', {id, disabledState});
	};

	const canEnterNewPriorityLevel = priorityLevels.length < 10;
	const canAddPriorityLevel = newPriorityName !== '' && canEnterNewPriorityLevel;

	return (
		<div className="section-content settings-app" data-cy={'settings-priority-levels-page'}>
			<ForecastThemeProvider>
				<SettingsProjectSubNav viewer={viewer} />
				<Page>
					<FormattedHTMLMessage id="settings.priority-levels-description" />

					<PrioritiesList>
						<Background rows={priorityLevels.length} />

						<DraggableContainer>
							<DragDropContext onDragEnd={data => onDragEnd(data, priorityLevels, formatMessage)}>
								<Droppable droppableId="priorities">
									{provided => (
										<div ref={provided.innerRef} style={{height: priorityLevels.length * 44}}>
											{priorityLevels.map((priorityLevel, index) => (
												<Draggable key={priorityLevel.id} draggableId={priorityLevel.id} index={index}>
													{(provided, snapshot) => (
														<div
															ref={provided.innerRef}
															style={provided.draggableProps.style}
															key={priorityLevel.id}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
														>
															<PriorityLevelRow
																company={viewer.company}
																priorityLevel={priorityLevel}
																index={index}
																isDragging={snapshot.isDragging}
																deleteCallback={replacementId =>
																	deletePriorityLevel(
																		priorityLevel.id,
																		replacementId,
																		priorityLevels,
																		formatMessage
																	)
																}
																disableCallback={disablePriorityLevel}
																updateNameCallback={updatePriorityLevelName}
															/>
														</div>
													)}
												</Draggable>
											))}

											{provided.placeholder}
										</div>
									)}
								</Droppable>
							</DragDropContext>
							<AddNewPriorityLevel
								value={newPriorityName}
								onChange={setNewPriorityName}
								canEnterNewPriorityLevel={canEnterNewPriorityLevel}
								onClick={addNewPriorityLevel}
								canAddPriorityLevel={canAddPriorityLevel}
								icon={color => <PlusIcon color={color} />}
							/>
						</DraggableContainer>
					</PrioritiesList>
				</Page>
			</ForecastThemeProvider>
		</div>
	);
});

export const SettingsPriorityLevelsQuery = graphql`
	query SettingsPriorityLevelsQuery {
		viewer {
			actualPersonId
			component(name: "settings_priority_levels")
			...SettingsPriorityLevels_viewer
		}
	}
`;

export default createRefetchContainer(
	SettingsPriorityLevels,
	{
		viewer: graphql`
			fragment SettingsPriorityLevels_viewer on Viewer {
				actualPersonId
				id
				company {
					id
					name
					priorityLevels(first: 1000) @connection(key: "Company_priorityLevels", filters: []) {
						edges {
							node {
								id
								name
								weight
								useCount
								disabled
							}
						}
					}
				}
			}
		`,
	},
	SettingsPriorityLevelsQuery
);
