import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';
import {getDefaultMediumClientLogo} from '../../../../components/default_avatars';
import RichTextItem from '../../../../components/richtext_item';
import {HubspotIcon, PipedriveIcon, QuickbooksIcon, SalesforceIcon} from 'web-components';
import TdLink from '../../../../components/td_link';
import TooltipContainer from '../../../shared/components/tooltips/tooltip_container';
import {clientLogoSrc} from '../../../../directApi';

class SettingsClientsClient extends Component {
	render() {
		const {formatMessage} = this.props.intl;
		const logoSrc = this.props.logoId
			? clientLogoSrc(this.props.logoId)
			: getDefaultMediumClientLogo(this.props.logoDefaultId);
		//const path = window.location.pathname + "view-client/" + this.props.id;
		let path = window.location.pathname;
		if (path.endsWith('/')) {
			path = path.substr(0, path.length - 1);
		}
		path += '/view-client/' + this.props.id;

		return (
			<tr className="row-client">
				<TdLink
					className="client"
					path={path}
					content={
						<div className="wrapper">
							<div className="image-container">
								<div className="client-picture">
									<img crossOrigin="use-credentials" src={logoSrc} width="120" height="120" />
								</div>
							</div>
							<div className="name-tile" data-cy="client-name">
								<h3 className="name">{this.props.name}</h3>
							</div>
						</div>
					}
				/>
				<TdLink
					path={path}
					content={
						<RichTextItem
							hideEmojiPicker={true}
							text={this.props.notes}
							readOnly={true}
							alwaysShowControls={false}
						/>
					}
				/>
				<TdLink path={path} content={this.props.projectCount} />
				<td className="controls">
					{this.props.quickbooksId ? (
						<span className="integration-logo">
							<QuickbooksIcon size={QuickbooksIcon.SIZE.BIG} />
						</span>
					) : null}
					{this.props.hubspotCompanyId ? (
						<span className="integration-logo">
							<HubspotIcon size={HubspotIcon.SIZE.BIG} />
						</span>
					) : null}
					{this.props.pipedriveOrganizationId ? (
						<span className="integration-logo">
							<PipedriveIcon size={PipedriveIcon.SIZE.EXTRA_BIG} />
						</span>
					) : null}
					{this.props.economicCustomerId ? <span className="integration-logo economic" /> : null}
					{this.props.xeroContactId ? <span className="integration-logo xero" /> : null}
					{this.props.salesforceAccountId ? (
						<span className="integration-logo">
							<SalesforceIcon size={SalesforceIcon.SIZE.BIG} />
						</span>
					) : null}
					{this.props.sageIntacctCustomerId ? <span className="integration-logo sageIntacct" /> : null}

					<span
						data-cy="delete-client"
						className={'control remove ' + (this.props.invoiced ? 'disabled' : '')}
						onClick={!this.props.invoiced ? this.props.deleteClient : null}
					>
						{this.props.invoiced ? (
							<TooltipContainer
								tooltipInfinteDuration
								infoText={formatMessage({id: 'settings.client_delete_invoiced'})}
							>
								<FormattedMessage id="common.delete" />
							</TooltipContainer>
						) : (
							<FormattedMessage id="common.delete" />
						)}
					</span>
					<span data-cy="edit-client" className="control edit" onClick={this.props.displayClientPage}>
						<FormattedMessage id="common.edit" />
					</span>
					{this.props.economicCustomerId ? (
						<span className="control integration-link">
							<a href={'https://secure.e-conomic.com/sales/customers'} target="_blank" rel="noopener noreferrer">
								<FormattedMessage id="settings_clients.open_in" values={{systemName: 'E-conomic'}} />
							</a>
						</span>
					) : null}
					{this.props.xeroContactId ? (
						<span className="control integration-link">
							<a
								href={`https://go.xero.com/Contacts/View/${this.props.xeroContactId}`}
								target="_blank"
								rel="noopener noreferrer"
							>
								<FormattedMessage id="settings_clients.open_in" values={{systemName: 'Xero'}} />
							</a>
						</span>
					) : null}
					{this.props.hubspotCompanyId ? (
						<span className="control integration-link">
							<a
								href={`https://app.hubspot.com/contacts/${this.props.hubspotAccountId}/company/${this.props.hubspotCompanyId}/`}
								target="_blank"
								rel="noopener noreferrer"
							>
								<FormattedMessage id="settings_clients.open_in" values={{systemName: 'Hubspot'}} />
							</a>
						</span>
					) : null}
					{this.props.pipedriveOrganizationId ? (
						<span className="control integration-link">
							<a
								href={`${this.props.pipedriveCompanyDomain}/organization/${this.props.pipedriveOrganizationId}`}
								target="_blank"
								rel="noopener noreferrer"
							>
								<FormattedMessage id="settings_clients.open_in" values={{systemName: 'Pipedrive'}} />
							</a>
						</span>
					) : null}
					{this.props.quickbooksId ? (
						<span className="control integration-link">
							<a
								href={`${process.env.QUICKBOOKS_BASE_URL}/app/customerdetail?nameId=${this.props.quickbooksId}`}
								target="_blank"
								rel="noopener noreferrer"
							>
								<FormattedMessage id="settings_clients.open_in" values={{systemName: 'Quickbooks'}} />
							</a>
						</span>
					) : null}
					{this.props.salesforceAccountId ? (
						<span className="control integration-link">
							<a
								href={`${this.props.salesforceInstanceUrl}/lightning/r/Account/${this.props.salesforceAccountId}/view`}
								target="_blank"
								rel="noopener noreferrer"
							>
								<FormattedMessage id="settings_clients.open_in" values={{systemName: 'Salesforce'}} />
							</a>
						</span>
					) : null}
					{/* {this.props.sageIntacctCustomerId ? (
						<span className="control integration-link">
							<a href={`#`} target="_blank" rel="noopener noreferrer">
								<FormattedMessage id="settings_clients.open_in" values={{systemName: 'Sage Intacct'}} />
							</a>
						</span>
					) : null} Seems like ther is not simple way to construct the link... need to figure this out. For now it's disabled and it just shows the logo. */}
				</td>
			</tr>
		);
	}
}

SettingsClientsClient.propTypes = {
	name: PropTypes.string,
	logoId: PropTypes.string,
	notes: PropTypes.string,
	displayClientPage: PropTypes.func.isRequired,
	deleteClient: PropTypes.func.isRequired,
	economicCustomerId: PropTypes.string,
	xeroContactId: PropTypes.string,
	quickbooksId: PropTypes.string,
	sageIntacctCustomerId: PropTypes.string,
};

export default injectIntl(SettingsClientsClient);
