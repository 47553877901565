import React, { forwardRef, memo, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { trackEvent } from '../../../../tracking/amplitude/TrackingV2';
import { escapeRegExp } from 'lodash';
import { TRACKING_OBJECTS } from '../../../../tracking/amplitude/constants/TrackingObjects';
import { DeprecatedButtonIconOnly, theme } from '@forecast-it/design-system';
const AutocompleteWrapper = styled.div `
	opacity: 0;
	grid-column: 1;
	grid-row: 1;
	display: flex;
	align-items: center;
	pointer-events: none;
	overflow: hidden;
`;
const SearchInputAutocomplete = styled.div `
	padding: 0;
	border: none;
	outline: none;
	${theme.fonts.body['1']};

	white-space: pre;
	color: silver;
`;
const SearchInput = styled.input `
	padding: 0;
	border: none;
	outline: none;
	${theme.fonts.body['1']};

	grid-column: 1;
	grid-row: 1;

	&::placeholder {
		${theme.fonts.body['1']};
		color: ${theme.colors.text.content.auxiliary};
	}
`;
const SearchInputLayer = styled.div `
	display: grid;
	height: 40px;
	width: 100%;

	&:focus-within ${AutocompleteWrapper} {
		opacity: 1;
	}
`;
const TabIndicator = styled.div `
	margin-left: 2px;
	padding: 4px 6px 4px 5px;
	border: 1px solid #e7e7f3;
	border-radius: 4px;
`;
const updateRecentSearches = (currentSearchString, recentSearches, recentSearchKey) => {
    // Check if search string is already in recent list. Move to top if it is
    const searchStringIndex = recentSearches.indexOf(currentSearchString);
    if (searchStringIndex > -1) {
        recentSearches.splice(searchStringIndex, 1);
    }
    else if (recentSearches.length > 200) {
        recentSearches.pop();
    }
    recentSearches.unshift(currentSearchString);
    localStorage.setItem(recentSearchKey, JSON.stringify(recentSearches));
};
/**
 * @param {string} text input string
 * @returns individual words from the input string split by all non-alphanumeric characters.
 */
const wordsInText = (text) => {
    if (text) {
        const regex = /[\p{L}\d-]+/gu; // Witch-craft blatantly stolen from https://stackoverflow.com/a/48902765. "{L}" matches any letter from any language, "\d" matches any number, "-"" matches hypens
        return text.match(regex);
    }
};
const getRecentSearchWordList = (recentSearches) => {
    if (!recentSearches) {
        return [];
    }
    return recentSearches.reduce((acc, recentSearch) => {
        const wordsInRecentSearch = wordsInText(recentSearch);
        if (wordsInRecentSearch) {
            wordsInRecentSearch.forEach(recentSearch => {
                // Only add term to wordList if it is longer than 2 characters
                if (recentSearch.length > 2) {
                    acc.push(recentSearch.toLowerCase());
                }
            });
        }
        return acc;
    }, []);
};
const getPrefixMatchFromWordList = (searchTerm, wordList) => {
    // Lookup pattern prefix-matches the searchTerm but ignores exact matches in order to ensure that longer, worse matches are not dropped.
    // Example: If searchTerm is "night" and wordlist contains both "night" and "nightlife" (in that order), "nightlife" will still be returned even though "night" is an exact match.
    const lookupPattern = new RegExp(`^${escapeRegExp(searchTerm.toLowerCase())}(.)+`);
    const prefixMatch = wordList.find(word => lookupPattern.test(word));
    // replacerPattern isolates the part of the identified match that is already present in the searchTerm in order to only return the part necessary for autocompletion.
    const replacerPattern = new RegExp(`^${escapeRegExp(searchTerm.toLowerCase())}`);
    return prefixMatch ? prefixMatch.replace(replacerPattern, '') : '';
};
const getAutocompleteString = (searchString, wordList) => {
    if (searchString) {
        const lastSearchTerm = searchString.split(' ').pop();
        if (lastSearchTerm && lastSearchTerm !== ' ') {
            const prefixMatch = getPrefixMatchFromWordList(lastSearchTerm, wordList);
            return searchString + prefixMatch;
        }
        return searchString;
    }
    return '';
};
const GlobalSearchInput = memo(forwardRef(({ searchString, recentSearchKey, recentSearches, inputPlaceholder, updateSearchString, updateSearchStringDebounced, focusSearchInput, focusFirstResult, }, searchInputRef) => {
    const [autocompleteString, setAutocompleteString] = useState('');
    const wordList = useMemo(() => {
        return getRecentSearchWordList(recentSearches);
    }, [recentSearches]);
    const hasNewAutocompleteSuggestion = (inputValue, autocompleteValue) => {
        return inputValue !== autocompleteValue;
    };
    const handleSearchAutocomplete = (e) => {
        if (hasNewAutocompleteSuggestion(searchString, autocompleteString)) {
            e.stopPropagation();
            e.preventDefault();
            trackEvent(`${TRACKING_OBJECTS.GLOBAL_SEARCH_MODAL} Autocomplete Suggestion`, 'Accepted');
            updateSearchString(autocompleteString);
        }
    };
    const handleSearchInputEscape = (e) => {
        if (hasNewAutocompleteSuggestion(searchString, autocompleteString)) {
            e.stopPropagation();
            e.preventDefault();
            trackEvent(`${TRACKING_OBJECTS.GLOBAL_SEARCH_MODAL} Autocomplete Suggestion`, 'Rejected');
            setAutocompleteString(searchString);
        }
    };
    const handleInputKeyDown = (e) => {
        if (e.key === 'Enter') {
            focusFirstResult();
        }
        else if (e.key === 'Tab') {
            handleSearchAutocomplete(e);
        }
        else if (e.key === 'Escape') {
            handleSearchInputEscape(e);
        }
    };
    const handleInputChange = (e) => {
        const searchString = e.target.value;
        updateSearchStringDebounced(searchString);
    };
    const clearInput = () => {
        updateSearchString('');
        focusSearchInput();
    };
    useEffect(() => {
        setAutocompleteString(getAutocompleteString(searchString, wordList));
    }, [searchString]);
    useEffect(() => {
        return () => {
            var _a;
            const currentSearchString = (_a = searchInputRef.current) === null || _a === void 0 ? void 0 : _a.value;
            // Set recent
            if (currentSearchString) {
                updateRecentSearches(currentSearchString, recentSearches, recentSearchKey);
            }
        };
    }, []);
    const showTabIndicator = hasNewAutocompleteSuggestion(searchString, autocompleteString);
    return (React.createElement("div", { style: { display: 'flex', flexGrow: 1, alignItems: 'center' } },
        React.createElement(SearchInputLayer, null,
            (autocompleteString === null || autocompleteString === void 0 ? void 0 : autocompleteString.length) < 60 ? (React.createElement(AutocompleteWrapper, null,
                React.createElement(SearchInputAutocomplete, null, autocompleteString),
                showTabIndicator ? React.createElement(TabIndicator, null, "Tab") : null)) : null,
            React.createElement(SearchInput, { ref: searchInputRef, type: "search", value: searchString, maxLength: 200, onChange: handleInputChange, onKeyDown: handleInputKeyDown, onFocus: focusSearchInput, "data-cy": 'search-modal-input', placeholder: inputPlaceholder, autoFocus: true })),
        searchString.length > 0 ? (React.createElement(DeprecatedButtonIconOnly, { size: 'l', type: 'ghost', icon: 'close', onClick: () => clearInput() })) : null));
}));
export default GlobalSearchInput;
