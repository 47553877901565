import React from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {ProjectIndicatorNoFragment} from '../ProjectIndicator';

/**
 * JS Wrapper to allow using fragment from JS parents since fragments are not compatible across TS/JS.
 */
const ProjectIndicatorJS = props => {
	return <ProjectIndicatorNoFragment {...props} />;
};

export default createFragmentContainer(ProjectIndicatorJS, {
	project: graphql`
		fragment ProjectIndicatorJS_project on ProjectType {
			name
			companyProjectId
			customProjectId
			projectColor
		}
	`,
});
