/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type settingsDepartments_person$ref: FragmentReference;
declare export opaque type settingsDepartments_person$fragmentType: settingsDepartments_person$ref;
export type settingsDepartments_person = {|
  +id: string,
  +fullName: ?string,
  +firstName: ?string,
  +lastName: ?string,
  +email: ?string,
  +costCenter: ?string,
  +profilePictureId: ?string,
  +profilePictureDefaultId: ?number,
  +department: ?{|
    +id: string,
    +name: ?string,
  |},
  +role: ?{|
    +name: ?string
  |},
  +isViewer: ?boolean,
  +$refType: settingsDepartments_person$ref,
|};
export type settingsDepartments_person$data = settingsDepartments_person;
export type settingsDepartments_person$key = {
  +$data?: settingsDepartments_person$data,
  +$fragmentRefs: settingsDepartments_person$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "settingsDepartments_person",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "costCenter",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profilePictureId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profilePictureDefaultId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DepartmentType",
      "kind": "LinkedField",
      "name": "department",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Role",
      "kind": "LinkedField",
      "name": "role",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isViewer",
      "storageKey": null
    }
  ],
  "type": "Person"
};
})();
// prettier-ignore
(node/*: any*/).hash = '1a7060be883fe62d874784d85af8c51b';

module.exports = node;
