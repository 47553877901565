import React, {useImperativeHandle} from 'react';
import PropTypes from 'prop-types';
import useDrivePicker from 'react-google-drive-picker';
import useScript from 'react-script-hook';

const clientId = '142486744568-qnkgblh2k4t13g5skjjvq1k8c3otncpa.apps.googleusercontent.com';
const developerKey = 'AIzaSyB41w5h2L-MhKlkduTJb7r1AVhe60dVmD0';
const scope = 'https://www.googleapis.com/auth/drive.file';

// Store Google Drive access token across pages and components (but not too persistent)
let token;

const GooglePicker = React.forwardRef(({children, className, newUI, onChange, mimeTypes, multiselect, disabled}, ref) => {
	const [openPicker] = useDrivePicker();
	const [loading, error] = useScript({src: 'https://accounts.google.com/gsi/client'});

	const getToken = callback => {
		if (token?.accessToken && (token?.expiry || 0) > new Date().getTime()) {
			callback(token.accessToken);
		} else {
			const client = window.google.accounts.oauth2.initTokenClient({
				client_id: clientId,
				scope,
				callback: tokenResponse => {
					token = {
						accessToken: tokenResponse.access_token,
						expiry: new Date().getTime() + (tokenResponse.expires_in || 0) * 1000,
					};
					callback(token.accessToken);
				},
			});
			client.requestAccessToken();
		}
	};
	const handleOpenPicker = accessToken => {
		const ownDriveView = new window.google.picker.DocsView().setIncludeFolders(true);
		const sharedDriveView = new window.google.picker.DocsView().setIncludeFolders(true).setEnableDrives(true);
		const customViewsArray = [ownDriveView, sharedDriveView];

		if (mimeTypes) {
			const mimeTypesString = mimeTypes.join(',');
			ownDriveView.setMimeTypes(mimeTypesString);
			sharedDriveView.setMimeTypes(mimeTypesString);
		}

		openPicker({
			clientId: clientId,
			developerKey: developerKey,
			token: accessToken,
			supportDrives: true,
			disableDefaultView: true,
			multiselect: !!multiselect,
			customViews: customViewsArray,
			callbackFunction: onChange,
		});
	};

	const onChoose = () => {
		if (loading || error) {
			return null;
		}
		getToken(accessToken => handleOpenPicker(accessToken));
	};

	useImperativeHandle(ref, () => ({
		onChoose,
	}));

	const handleKeyDown = e => {
		if (e.key === 'Enter') {
			e.nativeEvent.stopImmediatePropagation();
			onChoose();
		}
	};

	return (
		<div
			className={className}
			style={newUI ? {display: 'flex'} : {}}
			onClick={onChoose}
			tabIndex={0}
			onKeyDown={handleKeyDown}
		>
			{children ? children : <button>Open google chooser</button>}
		</div>
	);
});

GooglePicker.propTypes = {
	children: PropTypes.node,
	onChange: PropTypes.func,
	multiselect: PropTypes.bool,
};

GooglePicker.defaultProps = {
	onChange: () => {},
	multiselect: false,
};

export default GooglePicker;
