import React, {createContext, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {withRouter} from 'react-router-dom';
import {DragDropContext} from '@forecasthq/react-virtualized-dnd';
import {createFragmentContainer, graphql} from 'react-relay';
import {useIntl} from 'react-intl';
import {cloneDeep, groupBy} from 'lodash';
import Moment from 'moment';
import ProjectWorkflowColumn from './ProjectWorkflowColumn';
import {
	BUTTON_COLOR,
	BUTTON_STYLE,
	BUTTON_VARIANT,
	ELEMENT_TYPE,
	FILTER_SECTION,
	FILTER_TYPE,
	PROJECT_STATUS,
	WorkflowCategories as WORKFLOW_CATEGORIES,
} from '../../../../constants';
import {ContentContainer, WorkflowPageContainer, WorkflowWrapper} from './ProjectWorkflow.styled';
import Util from '../../../shared/util/util';
import {buildHeaderBar} from '../../../shared/components/headers/header-bar/header_bar';
import {getFiltersAlphabetically} from '../../../shared/components/filters/filter_util';
import {withSocketHandling} from '../../../../socket/withSocketHandling';
import {FILTER_SECTIONS} from '../../../shared/components/filters/FilterWrapper';
import Warning from '../../../../components/warning';
import {
	getSearchQueryForStatusCol,
	mapDraggableIdToTask,
	moveTaskToColumn,
	promptTimeRegistration,
} from './ProjectWorkflowLogic';
import {
	assignViewerOnSpacePress,
	getCardParentFromElement,
	getTaskFromTaskStatusId,
	theEyeOptions,
} from './ProjectWorkflowUtil';
import {getInitialOptions, handleChangedOptions, theEyeToColumns} from '../../../../the_eye_util';
import CreateStatusColumnMutation from '../../../../mutations/create_status_column_mutation';
import DeleteTaskMutation from '../../../../mutations/delete_task_mutation';
import * as tracking from '../../../../tracking';
import {EVENT_ID, subscribe, unsubscribe} from '../../../../containers/event_manager';
import {MODAL_TYPE, showModal} from '../../../shared/components/modals/generic_modal_conductor';
import {createToast} from '../../../shared/components/toasts/another-toast/toaster';
import UpdateTaskMutation from '../../../../mutations/update_task_mutation.modern';
import ReorderProjectBoardTaskMutation from '../../../../mutations/ReorderProjectBoardTaskMutation';
import {TopHeaderBar} from '../../../shared/components/headers/top-header-bar/TopHeaderBar';
import {useWindowSize} from '../../../shared/hooks/useWindowSize';
import {getColumnSocketConfig} from './ProjectWorkflowSocket';
import UpdateStatusColumnMutation from '../../../../mutations/update_status_column_mutation';
import {useForecastFetchQuery} from '../../../shared/hooks/useForecastFetchQuery';
import {StatusColumnTasksCountQuery} from './StatusColumnTasksCountQuery';
import {hasPermission, isClientUser, personIsClientUser} from '../../../shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../Permissions';
import {hasFeatureFlag} from '../../../shared/util/FeatureUtil';
import {BulkSelectPopup} from 'web-components';
import {getComplexBulkOptions} from '../../../shared/util/BulkUtil';
import {ArrowRightOutline, BinOutline, MoreActionsOutline} from '../../../../images/svg-collection';
import {useRecentProjects} from '../hooks/useRecentProjects';
import {useTrackPage} from '../../../../tracking/amplitude/hooks/useTrackPage';
import ProjectHeader from '../shared/ProjectHeader';
import {getProjectIndicatorString} from '../../../shared/components/project-indicator/support/ProjectIndicatorLogic';
import * as JiraUtil from '../../../shared/util/JiraUtil';

export const DragAndDropGroup = 'WORKFLOW_V2';
export const WorkflowContext = createContext();
export const ProjectWorkflowPage = React.memo(
	({viewer, relay, setSocketVariables, setSocketConfig, matchProjectId, matchGroupId, retry, buyNowTime}) => {
		const intl = useIntl();

		// Set as recent project
		let recentProjects = useRecentProjects();
		useEffect(() => {
			if (viewer.project) {
				recentProjects.projectVisited(viewer.project.id);
			}
		}, [viewer.project?.id]);

		let localStorageFilters = null;
		if (viewer.project || viewer.projectGroup) {
			localStorageFilters = Util.localStorageGetItem(
				`project-board-filters-v4-${
					viewer.project ? `p-${viewer.project.companyProjectId}` : `x-${viewer.projectGroup.companyProjectGroupId}`
				}`
			);
		}

		const localStorageTheEyeName = 'the-eye-workflow';
		const [filters, setFilters] = useState(localStorageFilters ? JSON.parse(localStorageFilters) : {person: {}, task: {}});
		const [searchQueryValue, setSearchQueryValue] = useState(''); // State for what is sent to the queryrenderers
		const [theEyeOpts, setTheEyeOptions] = useState(getInitialOptions(theEyeOptions, localStorageTheEyeName));
		const enabledColumns = useMemo(() => theEyeToColumns(theEyeOpts), [theEyeOpts]);
		//const searchTimerRef = useRef(false);
		const [isNewColumnButtonDisabled, setIsNewColumnButtonDisabled] = useState(false);
		const [newColumnId, setNewColumnId] = useState(null);
		const [selectedTasks, setSelectedTasks] = useState([]);
		const [dependencies, setDependencies] = useState({cantStart: null, cantFinish: null, cantUnfinish: null});
		const [integrationRestriction, setIntegrationRestriction] = useState(null);
		const [cardReorderInProgress, setCardReorderInProgress] = useState(false);
		const [isDraggingColumn, setIsDraggingColumn] = useState(false);
		const [columnBeingDragged, setColumnBeingDragged] = useState(null);
		const [statusColumnsOrderMap, setStatusColumnsOrderMap] = useState(new Map());
		const canEditWorkflow = hasPermission(PERMISSION_TYPE.STATUS_COLUMN_UPDATE);
		const isClientActionsRestricted = useMemo(() => Util.isClientTaskActionsRestricted(viewer), [viewer]);

		const projectIsDoneOrHalted = project =>
			project.status === PROJECT_STATUS.DONE || project.status === PROJECT_STATUS.HALTED;

		const isProjectLocked = useMemo(
			() =>
				viewer.project
					? projectIsDoneOrHalted(viewer.project)
					: !viewer.projectGroup.projects?.edges.some(project => !projectIsDoneOrHalted(project.node)),
			[viewer.project]
		);
		const isInGroup = useMemo(() => viewer.project && viewer.project.isInProjectGroup, [viewer.project]);
		// Ref storing a local state of the fetched tasks to allow identifying the above task from index when dragging ends
		// Key = statusColumnId, val = tasks. Ref is updated by ColumnContent when tasks change.
		const taskMapRef = useRef(new Map());
		const taskSizeMapRef = useRef(new Map());
		const imgSizeMapRef = useRef(new Map());
		const mousedOverCard = useRef(null);

		const didMount = useRef(false);

		const size = useWindowSize();

		const {fetch, data} = useForecastFetchQuery(StatusColumnTasksCountQuery);

		const isConnectedParent = viewer.projectGroup !== null;

		const refetch = variables => {
			fetch(variables);
			setSocketVariables(variables);
		};

		const socketFetch = variables => {
			fetch(variables);
			retry();
		};

		const getFreshData = useCallback(
			result => {
				if (
					result.updateTask ||
					result.createTask ||
					result.deleteTask ||
					result.duplicateTask ||
					result.deleteStatusColumn
				) {
					refetch({
						projectId: matchProjectId,
						groupId: matchGroupId,
						searchQuery: getSearchQueryForStatusCol(null, searchQueryValue, filters, isConnectedParent, true),
					});
				}
			},
			[matchProjectId, matchGroupId, filters, searchQueryValue]
		);

		const deselectTasksByIds = taskIds => {
			const newSelectedTasks = selectedTasks.filter(task => !taskIds.includes(task.node.id));
			setSelectedTasks([...newSelectedTasks]);
		};

		const validateSelectedTasks = result => {
			if (result.deleteTask) {
				deselectTasksByIds(result.deleteTask.deletedTasksIds);
			}
		};

		const onMouseMove = useCallback(e => {
			if (Util.getPathIsTaskModal()) {
				if (mousedOverCard.current) mousedOverCard.current = null;
				return;
			}
			const cardElem = getCardParentFromElement(e.target);
			if (cardElem) {
				if (!mousedOverCard.current || (mousedOverCard.current && mousedOverCard.current !== cardElem.id)) {
					mousedOverCard.current = cardElem.id;
				}
			} else if (mousedOverCard.current) {
				mousedOverCard.current = null;
			}
		}, []);

		useEffect(() => {
			window.addEventListener('mousemove', onMouseMove);

			return () => {
				window.removeEventListener('mousemove', onMouseMove);
			};
		}, []);

		const projectId = viewer.projectGroup ? viewer.projectGroup.id : viewer.project.id;

		const projectIds = useMemo(() => {
			return isConnectedParent
				? viewer.projectGroup.projects.edges.map(edge => parseInt(atob(edge.node.id).replace('ProjectType:', '')))
				: parseInt(atob(viewer.project.id).replace('ProjectType:', ''));
		}, [isConnectedParent, projectId]);

		const handleShortcutProjectBoard = useCallback(
			e => {
				const pathIsTaskModal = Util.getPathIsTaskModal();
				const activeElement = document.activeElement;
				if (
					(activeElement &&
						(activeElement.tagName === 'INPUT' ||
							activeElement.isContentEditable ||
							(activeElement.className && activeElement.className.toLowerCase().includes('drafteditor')) ||
							(activeElement.className && activeElement.className.toLowerCase().includes('switch')))) ||
					pathIsTaskModal
				) {
					return;
				}
				if (e.repeat) {
					e.preventDefault();
					return;
				}
				if (e.key === 'Escape') {
					setSelectedTasks([]);
				}
				if (!mousedOverCard.current) {
					return;
				}
				const task = getTaskFromTaskStatusId(mousedOverCard.current, taskMapRef.current, isConnectedParent);
				if (!task || isClientUser() || isProjectLocked) {
					return;
				}
				const isDoneOrHalted =
					task.project.status === PROJECT_STATUS.DONE || task.project.status === PROJECT_STATUS.HALTED;
				if (!isDoneOrHalted) {
					if (e.key === ' ') {
						assignViewerOnSpacePress(task, viewer.actualPersonId, viewer.project && viewer.project.isJiraProject);
					}
				}
			},
			[isConnectedParent, projectId]
		);

		useTrackPage('Project Workflow');

		useEffect(() => {
			const name = viewer.project
				? viewer.project.name !== null && viewer.project.name !== ''
					? viewer.project.name
					: getProjectIndicatorString(viewer.project.companyProjectId, viewer.project.customProjectId)
				: viewer.projectGroup.name !== ''
				? viewer.projectGroup.name
				: 'X-' + viewer.projectGroup.companyProjectGroupId;
			document.title = 'Workflow - ' + name + ' - Forecast';

			tracking.trackPage('Faster-workflow');

			setSocketConfig(getColumnSocketConfig(projectIds), socketFetch);

			const localStorageFilters = matchGroupId
				? localStorage.getItem(`project-board-filters-v4-x-${matchGroupId}`)
					? JSON.parse(localStorage.getItem(`project-board-filters-v4-x-${matchGroupId}`))
					: {person: {}, task: {}}
				: localStorage.getItem(`project-board-filters-v4-p-${matchProjectId}`)
				? JSON.parse(localStorage.getItem(`project-board-filters-v4-p-${matchProjectId}`))
				: {person: {}, task: {}};

			const searchQuery = getSearchQueryForStatusCol(null, null, localStorageFilters, isConnectedParent, true);

			fetch({projectId: matchProjectId, groupId: matchGroupId, searchQuery: searchQuery});

			window.addEventListener('keydown', handleShortcutProjectBoard);
			return () => {
				window.removeEventListener('keydown', handleShortcutProjectBoard);
			};
		}, [isConnectedParent, projectId]);

		useEffect(() => {
			let statusColumnsOrderMapInit = new Map();
			if (viewer.project) {
				viewer.project.statusColumnsV2.edges.forEach(col => {
					statusColumnsOrderMapInit.set(col.node.id, col.node.order);
				});
			} else if (viewer.projectGroup && viewer.projectGroup.projects.edges.length > 0) {
				viewer.projectGroup.projects.edges[0].node.statusColumnsV2.edges.forEach(col => {
					statusColumnsOrderMapInit.set(col.node.projectGroupStatusColumnId, col.node.order);
				});
			}
			setStatusColumnsOrderMap(statusColumnsOrderMapInit);
		}, [viewer]);

		useEffect(() => {
			subscribe(EVENT_ID.SCHEDULING_MODAL_MUTATION_SUCCESS, validateSelectedTasks);
			return () => {
				unsubscribe(EVENT_ID.SCHEDULING_MODAL_MUTATION_SUCCESS, validateSelectedTasks);
			};
		}, [selectedTasks]);

		useEffect(() => {
			subscribe(EVENT_ID.SCHEDULING_MODAL_MUTATION_SUCCESS, getFreshData);
			return () => {
				unsubscribe(EVENT_ID.SCHEDULING_MODAL_MUTATION_SUCCESS, getFreshData);
			};
		}, [filters, searchQueryValue]);

		useEffect(() => {
			// Don't refetch on mount
			if (!didMount.current) {
				didMount.current = true;
				return;
			}
			refetch({
				projectId: matchProjectId,
				groupId: matchGroupId,
				searchQuery: getSearchQueryForStatusCol(null, searchQueryValue, filters, isConnectedParent, true),
			});
		}, [matchProjectId, matchGroupId, filters, searchQueryValue]);

		const selectTasks = tasks => {
			const newSelectedTasks = selectedTasks;
			tasks.forEach(task => {
				const existIndex = selectedTasks.findIndex(sTask => sTask.node.id === task.node.id);
				if (existIndex < 0) {
					newSelectedTasks.push(task);
				} else {
					newSelectedTasks[existIndex] = task;
				}
			});
			setSelectedTasks([...newSelectedTasks]);
		};

		const onSearchQueryValueChange = value => {
			setSearchQueryValue(value);
		};

		const getCannotStart = task =>
			task.thisTaskDependsOn.edges.some(
				edge => edge.node.type === 'CANNOT_START' && edge.node.thisDependsOnTask.statusColumnV2.category !== 'DONE'
			);

		const getCannotFinish = task =>
			task.thisTaskDependsOn.edges.some(
				edge =>
					edge.node.type === 'CANNOT_BE_COMPLETED' && edge.node.thisDependsOnTask.statusColumnV2.category !== 'DONE'
			);

		const getDependencies = task => {
			const taskString = `T${task.companyTaskId} ${task.name}`;

			const cantStart = getCannotStart(task) ? taskString : null;
			const cantFinish = getCannotFinish(task) ? taskString : null;
			const cantUnfinish =
				task.statusColumnV2.category === 'DONE' &&
				task.dependsOnThisTask.edges.some(dependency => {
					if (dependency.node.type === 'CANNOT_START') {
						return dependency.node.taskDependsOnThis.statusColumnV2.category !== 'TODO';
					}
					if (dependency.node.type === 'CANNOT_BE_COMPLETED') {
						return dependency.node.taskDependsOnThis.statusColumnV2.category === 'DONE';
					}
					return false;
				})
					? taskString
					: null;

			return {cantStart, cantFinish, cantUnfinish};
		};

		const getTaskDependencies = tasks => {
			const cannotStart = [];
			const cannotFinish = [];
			const cannotUnfinish = [];

			tasks.forEach(task => {
				const {cantStart, cantFinish, cantUnfinish} = getDependencies(task);
				if (cantStart) {
					cannotStart.push(cantStart);
				}
				if (cantFinish) {
					cannotFinish.push(cantFinish);
				}
				if (cantUnfinish) {
					cannotUnfinish.push(cantUnfinish);
				}
			});

			return {cannotStart, cannotFinish, cannotUnfinish};
		};

		const setDragDependencies = draggedTask => {
			let tasks;
			const isDraggingSelectedTask =
				selectedTasks.length > 0 && selectedTasks.some(task => task.node.id === draggedTask?.id);
			if (isDraggingSelectedTask) {
				tasks = selectedTasks.map(task => task.node);
			} else {
				tasks = [draggedTask];
			}
			const {cannotStart, cannotFinish, cannotUnfinish} = getTaskDependencies(tasks);
			// just to not rerender if unneccesary
			if (cannotStart.length > 0 || cannotFinish.length > 0 || cannotUnfinish.length > 0) {
				setDependencies({
					cantStart: cannotStart.length > 0 ? cannotStart : null,
					cantFinish: cannotFinish.length > 0 ? cannotFinish : null,
					cantUnfinish: cannotUnfinish.length > 0 ? cannotUnfinish : null,
				});
			}
		};

		const showAutomateModal = variant => {
			const people_options = viewer.project
				? viewer.project.projectPersons.edges.map(pp => pp.node.person)
				: viewer.projectGroup.projectGroupPersons.edges
						.filter(pp => !personIsClientUser(pp.node.person))
						.map(pp => pp.node.person);
			showModal({
				type: MODAL_TYPE.PROJECT_AUTOMATE,
				variant: variant,
				tasks: selectedTasks.map(task => task.node),
				intl: intl,
				roles: viewer.company.roles,
				labels: viewer.company.labels,
				persons: people_options,
			});
		};

		const editSelection = () => {
			const onSuccess = errors => {
				if (errors && errors.length !== 0) {
					Util.resolveTaskStatusErrors(errors, intl);
				}
			};
			const role_options = viewer.company.roles.edges.map(role => ({
				value: role.node.id,
				label: role.node.name,
			}));
			const people_options = viewer.project
				? viewer.project.projectPersons.edges.map(pp => ({
						value: pp.node.person.id,
						label: pp.node.person.firstName + ' ' + pp.node.person.lastName,
						profilePictureId: pp.node.person.profilePictureId,
						profilePictureDefaultId: pp.node.person.profilePictureDefaultId,
				  }))
				: viewer.projectGroup.projectGroupPersons.edges
						.filter(pp => !personIsClientUser(pp.node.person))
						.map(pp => ({
							value: pp.node.person.id,
							label: pp.node.person.firstName + ' ' + pp.node.person.lastName,
							profilePictureId: pp.node.person.profilePictureId,
							profilePictureDefaultId: pp.node.person.profilePictureDefaultId,
						}));
			const status_options = viewer.project
				? viewer.project.statusColumnsV2.edges.map(col => ({
						value: col.node.id,
						label: col.node.name,
						logo: col.node.jiraStatusId ? 'jira-logo' : undefined,
				  }))
				: viewer.projectGroup.projects.edges[0].node.statusColumnsV2.edges.map(col => ({
						value: col.node.projectGroupStatusColumnId,
						label: col.node.name,
						logo: col.node.jiraStatusId ? 'jira-logo' : undefined,
				  }));

			showModal({
				type: MODAL_TYPE.BULK_TASK_UPDATE,
				useTaskFollowers: viewer.project ? viewer.project.useTaskFollowers : false,
				language: viewer.language,
				role_options: role_options,
				people_options: people_options,
				status_options: status_options,
				labels: viewer.company.labels.edges,
				getOptions: viewer.company.labels.edges.map(label => ({
					value: label.node.id,
					label: label.node.name,
					color: label.node.color,
				})),
				companyId: viewer.company.id,
				tasks: selectedTasks.map(t => t.node.id),
				projectGroupId: viewer.projectGroup ? viewer.projectGroup.id : null,
				estimationUnit: viewer.project
					? viewer.project.estimationUnit
					: viewer.projectGroup.projects.edges[0].node.estimationUnit,
				saveCallback: onSuccess,
				isJiraProject: viewer.project && viewer.project.isJiraProject,
				isClientUser: isClientUser(),
				availableFeatureFlags: viewer.availableFeatureFlags,
			});
		};

		const stopTimer = task => {
			if (hasFeatureFlag('new_time_registration_modal')) {
				showModal({
					type: MODAL_TYPE.TIMER_TIME_REGISTRATION,
					timerActionTaskId: task.id,
					preventedDelete: true,
				});
			} else {
				showModal({
					type: MODAL_TYPE.TIMER_V3,
					isPreviousTimerReminder: false,
					timerProject: task.project,
					timerTask: task,
					preventedDelete: true,
				});
			}
		};

		const deleteSelected = () => {
			const {formatMessage} = intl;
			if (viewer.timerStartDate) {
				for (let task of selectedTasks) {
					if (viewer.timerTask && task.node.id === viewer.timerTask.id) {
						stopTimer(task.node);
						return;
					}
				}
			}

			const callbackPositive = () => {
				const onSuccess = () => {
					createToast({
						duration: 5000,
						message: formatMessage({id: 'bulk_delete.toast'}),
					});
					setSelectedTasks([]);
				};
				const filters = selectedTasks.map(task => {
					return viewer.project ? task.node.statusColumnV2.id : task.node.statusColumnV2.projectGroupStatusColumnId;
				});
				Util.CommitSchedulingModalUpdate(
					DeleteTaskMutation,
					{
						filters,
						viewer,
						ids: selectedTasks.map(t => t.node.id),
					},
					onSuccess
				);
			};

			showModal({
				type: MODAL_TYPE.GENERIC_DELETION_WARNING,
				deleteCallback: callbackPositive,
				deletedItems: selectedTasks.map(t => t.node),
			});
		};

		const bulkUpdateStatus = () => {
			const callbackPositive = selected => {
				const onSuccess = res => {
					createToast({duration: 5000, message: intl.formatMessage({id: 'bulk_update.toast'})});

					// Update statusColumnId of selectedTasks
					if (res?.updateTask?.taskEdges) {
						const updatedSelectedTasks = cloneDeep(res.updateTask.taskEdges);
						setSelectedTasks(updatedSelectedTasks);
					}
				};

				const selectedTaskIds = selectedTasks.map(task => task.node.id);

				const prevStatusColumnIdMap = selectedTasks.reduce((map, task) => {
					map.set(task.node.id, {
						statusColumnId: task.node.statusColumnV2.id,
						projectGroupStatusColumnId: task.node.statusColumnV2.projectGroupStatusColumnId,
					});
					return map;
				}, new Map());

				const mutationObject = {
					ids: selectedTaskIds,
					companyId: viewer.company.id,
					prevStatusColumnIdMap: prevStatusColumnIdMap,
					aboveTaskId: null,
					statusColumnCategory: selected ? selected.category : null,
				};
				if (viewer.project || !selected) {
					mutationObject.statusColumnId = selected ? selected.value : null;
				} else {
					mutationObject.projectGroupStatusColumnId = selected.value;
				}
				Util.CommitSchedulingModalUpdate(ReorderProjectBoardTaskMutation, mutationObject, onSuccess);
			};

			const {cannotStart, cannotFinish, cannotUnfinish} = getTaskDependencies(selectedTasks.map(task => task.node));
			let status_select_options = viewer.project
				? cloneDeep(viewer.project.statusColumnsV2.edges)
						.sort((a, b) => a.node.order - b.node.order)
						.map(col => ({value: col.node.id, label: col.node.name, category: col.node.category}))
				: cloneDeep(viewer.projectGroup.projects.edges[0].node.statusColumnsV2.edges)
						.sort((a, b) => a.node.order - b.node.order)
						.map(col => ({
							value: col.node.projectGroupStatusColumnId,
							label: col.node.name,
							category: col.node.category,
						}));
			status_select_options = status_select_options.filter(option => {
				if (option.category === 'TODO' && cannotUnfinish.length > 0) {
					return false;
				}
				if (option.category === 'INPROGRESS' && (cannotUnfinish.length > 0 || cannotStart.length > 0)) {
					return false;
				}
				if (option.category === 'DONE' && (cannotFinish.length > 0 || cannotStart.length > 0)) {
					return false;
				}

				return true;
			});
			showModal({
				type: MODAL_TYPE.SELECT_V2,
				title: intl.formatMessage({id: 'bulk_update_option.move_to_status'}),
				defaultCallback: callbackPositive,
				options: status_select_options,
				label: intl.formatMessage({id: 'common.status'}),
				multi: false,
				initialValue: null,
			});
		};

		const compareSprintDates = (a, b) => {
			let aStartDate = Moment({
				y: a.node.startYear,
				M: a.node.startMonth - 1,
				d: a.node.startDay,
			});
			let aEndDate = Moment({
				M: a.node.endMonth - 1,
				y: a.node.endYear,
				d: a.node.endDay,
			});
			let bStartDate = Moment({
				y: b.node.startYear,
				M: b.node.startMonth - 1,
				d: b.node.startDay,
			});
			let bEndDate = Moment({
				y: b.node.endYear,
				M: b.node.endMonth - 1,
				d: b.node.endDay,
			});
			//Date used for sorting sprints with not selected dates
			const dummyDate = Moment({
				y: -10000,
				M: 1,
				d: 1,
			});

			if (!aStartDate.isValid()) aStartDate = dummyDate.clone();
			if (!aEndDate.isValid()) aEndDate = dummyDate.clone();
			if (!bStartDate.isValid()) bStartDate = dummyDate.clone();
			if (!bEndDate.isValid()) bEndDate = dummyDate.clone();

			if (aStartDate.isBefore(bStartDate)) return 1;
			if (bStartDate.isBefore(aStartDate)) return -1;
			//If same date, sort by end date ascending
			if (aEndDate.isBefore(bEndDate)) return 1;
			if (bEndDate.isBefore(aEndDate)) return -1;

			const aId = parseInt(atob(a.node.id).replace('Sprint:', ''), 10);
			const bId = parseInt(atob(b.node.id).replace('Sprint:', ''), 10);
			if (aId < bId) return 1;
			return -1;
		};

		const bulkUpdateSprint = () => {
			const callbackPositive = selected => {
				const onSuccess = result => {
					createToast({duration: 5000, message: intl.formatMessage({id: 'bulk_update.toast'})});
				};
				const mutationObject = {
					ids: selectedTasks.map(t => t.node.id),
				};
				if (viewer.project || !selected) {
					mutationObject.sprintId = selected ? selected.value : null;
				} else {
					mutationObject.projectGroupSprintId = selected.value;
				}
				Util.CommitMutation(UpdateTaskMutation, mutationObject, onSuccess);
			};
			const sprint_select_options = viewer.project
				? cloneDeep(viewer.project.sprints.edges)
						.sort(compareSprintDates)
						.map(sprint => ({value: sprint.node.id, label: sprint.node.name}))
				: cloneDeep(viewer.projectGroup.projects.edges[0].node.sprints.edges)
						.sort(compareSprintDates)
						.filter(sprint => sprint.node.isProjectGroupSprint)
						.map(sprint => ({value: sprint.node.projectGroupSprintId, label: sprint.node.name}));
			sprint_select_options.push({value: null, label: intl.formatMessage({id: 'project_sprints.backlog'})});
			showModal({
				type: MODAL_TYPE.SELECT_V2,
				title: intl.formatMessage({id: 'project_section.bulk_move_cards_sprint'}),
				defaultCallback: callbackPositive,
				options: sprint_select_options,
				label: intl.formatMessage({id: 'common.sprint'}),
				multi: false,
				initialValue: null,
			});
		};

		const bulkUpdatePhase = () => {
			const callbackPositive = selected => {
				const onSuccess = result => {
					createToast({duration: 5000, message: intl.formatMessage({id: 'bulk_update.toast'})});
				};
				Util.CommitMutation(
					UpdateTaskMutation,
					{
						ids: selectedTasks.map(t => t.node.id),
						phaseId: selected ? selected.value : null,
					},
					onSuccess
				);
			};
			const phase_select_options = viewer.project.phases.edges.map(phase => ({
				value: phase.node.id,
				label: phase.node.name,
			}));
			showModal({
				type: MODAL_TYPE.SELECT_V2,
				title: intl.formatMessage({id: 'project_section.bulk_move_cards_milesone'}),
				defaultCallback: callbackPositive,
				options: phase_select_options,
				label: intl.formatMessage({id: 'common.scope-group'}),
				multi: false,
				initialValue: null,
			});
		};

		const bulkUpdateProject = () => {
			const onSuccess = result => {
				setSelectedTasks([]);
			};
			let currentProjectId;
			let currentProjectGroupId;
			if (viewer.project) {
				currentProjectId = viewer.project.id;
			} else {
				currentProjectId = selectedTasks[0].node.project.id;
				if (
					selectedTasks.filter(t => {
						return t.node.project.id !== currentProjectId;
					}).length > 0
				) {
					currentProjectId = null; //multiple projects selected
					currentProjectGroupId = viewer.projectGroup.id;
				}
			}
			showModal({
				type: MODAL_TYPE.TASK_LOCATION,
				onSuccess: onSuccess,
				currentProjectId: currentProjectId,
				projectGroupId: currentProjectGroupId,
				taskId: selectedTasks.map(task => task.node.id),
				statusColumns: selectedTasks.map(task => task.node.statusColumnV2),
			});
		};

		const getBulkUpdateOptions = () => {
			const selectionHasBlockingTask = selectedTasks.some(task => !task.node.userCanDeleteTask);

			const taskSubtreeSelected =
				selectedTasks &&
				selectedTasks.some(
					t =>
						t.node.parentTaskId != null &&
						selectedTasks.filter(t2 => t2.node.id === t.node.parentTaskId).length === 0
				);

			if (viewer.project?.isJiraProject || viewer.project?.vstsProject) {
				return [
					{
						id: 'bulk update',
						label: intl.formatMessage({id: 'project_section.bulk_card_update'}),
						icon: color => <MoreActionsOutline color={color} width={16} height={16} />,
						callback: editSelection, // TODO
						userpilot: 'bulk-update-button',
						cy: 'bulk-update-button',
						variant: BUTTON_VARIANT.VERY_LIGHT_GRAY_OUTLINE,
					},
				];
			} else if (!viewer.project) {
				const list = [
					{
						id: 'bulk update',
						label: intl.formatMessage({id: 'project_section.bulk_card_update'}),
						icon: color => <MoreActionsOutline color={color} width={16} height={16} />,
						callback: editSelection, // TODO
						userpilot: 'bulk-update-button',
						cy: 'bulk-update-button',
						variant: BUTTON_VARIANT.VERY_LIGHT_GRAY_OUTLINE,
					},
				];

				if (!isClientUser()) {
					list.push({
						id: 'move_tasks',
						label: intl.formatMessage({id: 'bulk_edit.move_tasks'}),
						icon: color => <ArrowRightOutline color={color} width={16} height={16} />,
						options: [
							{label: intl.formatMessage({id: 'bulk_update_option.move_to_status'}), callback: bulkUpdateStatus},
							{label: intl.formatMessage({id: 'bulk_update_option.move_to_sprint'}), callback: bulkUpdateSprint},
							{
								label: intl.formatMessage({id: 'task_location_modal.title'}),
								callback: bulkUpdateProject,
								disabled: selectionHasBlockingTask,
							},
						],
						userpilot: 'bulk-move-button',
						cy: 'bulk-move-button',
						variant: BUTTON_VARIANT.VERY_LIGHT_GRAY_OUTLINE,
					});
				}

				list.push({
					id: 'delete',
					icon: color => <BinOutline color={color} width={16} height={16} />,
					label: intl.formatMessage({id: 'common.delete'}),
					callback: deleteSelected,
					disabled: selectionHasBlockingTask,
					userpilot: 'bulk-delete-button',
					cy: 'bulk-delete-button',
					variant: BUTTON_VARIANT.VERY_LIGHT_GRAY_OUTLINE,
				});

				return list;
			} else {
				const list = [
					{
						id: 'bulk update',
						label: intl.formatMessage({id: 'project_section.bulk_card_update'}),
						icon: color => <MoreActionsOutline color={color} width={16} height={16} />,
						callback: editSelection,
						userpilot: 'bulk-update-button',
						cy: 'bulk-update-button',
						variant: BUTTON_VARIANT.VERY_LIGHT_GRAY_OUTLINE,
					},
				];

				if (!isClientUser()) {
					list.push({
						id: 'move_tasks',
						label: intl.formatMessage({id: 'bulk_edit.move_tasks'}),
						icon: color => <ArrowRightOutline color={color} width={16} height={16} />,
						options: [
							{label: intl.formatMessage({id: 'bulk_update_option.move_to_status'}), callback: bulkUpdateStatus},
							{label: intl.formatMessage({id: 'bulk_update_option.move_to_sprint'}), callback: bulkUpdateSprint},
							{
								label: intl.formatMessage({id: 'project_section.bulk_move_cards_milesone'}),
								callback: bulkUpdatePhase,
								disabled: taskSubtreeSelected,
							},
							{
								label: intl.formatMessage({id: 'task_location_modal.title'}),
								callback: bulkUpdateProject,
								disabled: selectionHasBlockingTask || viewer.project.harvestProjectId,
							},
						],
						userpilot: 'bulk-move-button',
						cy: 'bulk-move-button',
						variant: BUTTON_VARIANT.VERY_LIGHT_GRAY_OUTLINE,
					});
				}

				list.push({
					id: 'delete',
					icon: color => <BinOutline color={color} width={16} height={16} />,
					label: intl.formatMessage({id: 'common.delete'}),
					callback: deleteSelected,
					disabled: selectionHasBlockingTask,
					userpilot: 'bulk-delete-button',
					cy: 'bulk-delete-button',
					variant: BUTTON_VARIANT.VERY_LIGHT_GRAY_OUTLINE,
				});

				return list;
			}
		};

		const getColumnsExpandedMap = () => {
			let isColExpandedMap;
			const project = viewer.project ? viewer.project : viewer.projectGroup ? viewer.projectGroup : null;
			const statusColumnsV2 = project
				? project.statusColumnsV2
					? project.statusColumnsV2.edges
					: project.projects && project.projects.edges.length > 0
					? project.projects.edges[0].node.statusColumnsV2.edges
					: []
				: [];
			try {
				isColExpandedMap = project
					? new Map(JSON.parse(localStorage.getItem('project-board' + project.id)))
					: new Map();
			} catch (e) {
				isColExpandedMap = new Map();
				statusColumnsV2.forEach(col => {
					isColExpandedMap.set(col.node.id, col.node.category !== WORKFLOW_CATEGORIES.DONE);
				});
			}

			//No local storage on this project yet
			if (isColExpandedMap.size === 0 && project) {
				statusColumnsV2.forEach(col => {
					isColExpandedMap.set(col.node.id, col.node.category !== WORKFLOW_CATEGORIES.DONE);
				});
				Util.localStorageSetItem('project-board' + project.id, JSON.stringify(isColExpandedMap));
			}
			return isColExpandedMap;
		};
		const [isColumnsExpandedMap, setIsColumnsExpandedMap] = useState(getColumnsExpandedMap());

		const handleToggleColumn = useCallback(
			(colId, expand) => {
				const colExpandedMap = isColumnsExpandedMap;
				colExpandedMap.set(colId, expand);
				if (viewer.project) {
					Util.localStorageSetItem('project-board' + viewer.project.id, JSON.stringify(colExpandedMap));
				} else {
					Util.localStorageSetItem('project-board' + viewer.projectGroup.id, JSON.stringify(colExpandedMap));
				}

				setIsColumnsExpandedMap(colExpandedMap);
			},
			[isColumnsExpandedMap]
		);

		const handleAddNewColumn = () => {
			setIsNewColumnButtonDisabled(true);
			const onSuccess = res => {
				const newColumn =
					res.createStatusColumn &&
					res.createStatusColumn.statusColumnV2 &&
					res.createStatusColumn.statusColumnV2.node
						? res.createStatusColumn.statusColumnV2.node
						: null;
				const newColumnId = viewer.project ? newColumn.id : newColumn.projectGroupStatusColumnId;
				setIsNewColumnButtonDisabled(false);
				setNewColumnId(newColumnId);
			};
			Util.CommitMutation(
				CreateStatusColumnMutation,
				{
					projectId: viewer.project ? viewer.project.id : viewer.projectGroup.projects.edges[0].node.id,
					projectGroupId: viewer.projectGroup ? viewer.projectGroup.id : undefined,
					name: intl.formatMessage({id: 'project_board.new_column'}),
					order: 1,
					category: WORKFLOW_CATEGORIES.INPROGRESS,
				},
				onSuccess
			);
		};

		const onFilterChange = filters => {
			setFilters(filters);
		};

		const handleTheEyeOptionSelect = (_, __, ___, newOptions) => {
			setTheEyeOptions(handleChangedOptions(newOptions, localStorageTheEyeName));
		};

		const getHeaderTitleContent = () => {
			const content = [];

			const onboardingFlows = [
				{
					id: 'workflow-introduction',
					title: 'Introduction to page',
					description: null,
					contentId: '1681901236xPxk4416',
				},
			];

			const onboardingComponent = {
				id: 'onboarding-component',
				type: TopHeaderBar.TYPE.ONBOARDING,
				title: intl.formatMessage({id: 'onboarding.task_board_onboarding_title'}),
				options: onboardingFlows,
				helpCenterLink: 'https://support.forecast.app/hc/en-us/sections/4419344459665-Projects',
				subLink:
					'https://support.forecast.app/hc/en-us/articles/5622730714769-Viewing-your-Project-s-Workflow-Kanban-Board-',
			};
			content.push(onboardingComponent);

			return content;
		};

		const getHeader = () => {
			const leftContent = [],
				rightContent = [];

			leftContent.push({
				type: ELEMENT_TYPE.THE_EYE,
				options: theEyeOpts,
				onSelect: handleTheEyeOptionSelect,
				openRight: true,
				userpilot: 'eye-selector',
			});

			rightContent.push({
				type: ELEMENT_TYPE.SEARCH_LAZY,
				onChange: onSearchQueryValueChange,
				placeholder: intl.formatMessage({id: 'global_search.placeholder'}),
			});

			const taskFilters = [
				FILTER_TYPE.CLIENT_GUEST_USERS,
				FILTER_TYPE.DEADLINE,
				FILTER_TYPE.DEPENDENCIES,
				FILTER_TYPE.INDICATOR_FILTERED,
				FILTER_TYPE.LABEL,
				FILTER_TYPE.RECENT_ACTIVITY,
				FILTER_TYPE.PROJECT_PHASE,
			];

			if (!isClientActionsRestricted) {
				taskFilters.push(FILTER_TYPE.PROJECT_PERSON);
				taskFilters.push(FILTER_TYPE.ROLE);
			}

			if (viewer.projectGroup) {
				taskFilters.push(FILTER_TYPE.PROJECT);
				taskFilters.push(FILTER_TYPE.PROJECT_STAGE);
			}

			if (
				(viewer.project && viewer.project.useTaskFollowers) ||
				(viewer.projectGroup && viewer.projectGroup.projects.edges[0].node.useTaskFollowers)
			) {
				taskFilters.push(FILTER_TYPE.PROJECT_FOLLOWER);
			}

			if (
				(viewer.project && viewer.project.useTaskOwner) ||
				(viewer.projectGroup && viewer.projectGroup.projects.edges[0].node.useTaskOwner)
			) {
				taskFilters.push(FILTER_TYPE.PROJECT_OWNER);
			}

			if (
				(viewer.project && viewer.project.sprintTimeBox) ||
				(viewer.projectGroup && viewer.projectGroup.projects.edges[0].node.sprintTimeBox)
			) {
				taskFilters.push(FILTER_TYPE.PROJECT_SPRINT);
				taskFilters.push(FILTER_TYPE.SPRINT_CATEGORY);
			}

			if (
				(viewer.project && viewer.project.taskLevels === 2) ||
				(viewer.projectGroup && viewer.projectGroup.projects.edges[0].node.taskLevels === 2)
			) {
				taskFilters.push(FILTER_TYPE.SUB_TASKS);
			}

			if (viewer.company.teams && viewer.company.teams.edges.length > 0 && !isClientUser()) {
				taskFilters.push(FILTER_TYPE.TEAM);
			}

			const useTaskHierarchy =
				viewer.project?.useTaskHierarchy ||
				viewer.projectGroup?.projects.edges.filter(project => project.node.useTaskHierarchy).length > 0;
			if (useTaskHierarchy) {
				taskFilters.push(FILTER_TYPE.TASK_LEVEL);
			}

			const projectFilters = [];
			const peopleFilters = [];
			rightContent.push({
				type: ELEMENT_TYPE.FILTER_V4,
				operatorOptions: {allowExclude: true, allowRequireAll: true},
				defaultSection: FILTER_SECTIONS.TASKS,
				projectFilters,
				peopleFilters,
				taskFilters: getFiltersAlphabetically(taskFilters, intl.formatMessage),
				primaryFilters: {
					[FILTER_SECTIONS.TASKS]: [
						FILTER_TYPE.PROJECT_PERSON,
						FILTER_TYPE.PROJECT_PHASE,
						FILTER_TYPE.DEADLINE,
						FILTER_TYPE.LABEL,
						FILTER_TYPE.ROLE,
						FILTER_TYPE.PROJECT_SPRINT,
					],
				},
				viewer: viewer,
				appliedFiltersName: `project-board-filters-v4-${
					viewer.project ? 'p-' + viewer.project.companyProjectId : 'x-' + viewer.projectGroup.companyProjectGroupId
				}`,
				filterSection: FILTER_SECTION.WORKFLOW,
				onFiltersChange: onFilterChange,
				projectId: viewer.project ? viewer.project.id : null,
				projectGroupId: viewer.projectGroup ? viewer.projectGroup.id : null,
				companyProjectGroupId: viewer.projectGroup ? viewer.projectGroup.companyProjectGroupId : null,
				companyProjectId: viewer.project ? viewer.project.companyProjectId : null,
			});
			if (!isInGroup) {
				rightContent.push({
					type: ELEMENT_TYPE.BUTTON,
					dataCy: 'new-column',
					text: intl.formatMessage({id: 'project_board.new_column'}),
					callback: handleAddNewColumn,
					style: BUTTON_STYLE.OUTLINE,
					color: BUTTON_COLOR.PURPLE,
					disabled: isProjectLocked || !canEditWorkflow || isNewColumnButtonDisabled,
				});
			}

			if (isProjectLocked && viewer.project) {
				const indicator = {
					type: ELEMENT_TYPE.INDICATOR,
					status: viewer.project.status,
				};
				leftContent.push(indicator);
			}

			return buildHeaderBar(leftContent, rightContent);
		};

		const shouldShowDisabledOverlay = column => {
			const result =
				(column.node.category !== WORKFLOW_CATEGORIES.TODO && dependencies.cantStart) ||
				(column.node.category === WORKFLOW_CATEGORIES.DONE && dependencies.cantFinish) ||
				(column.node.category !== WORKFLOW_CATEGORIES.DONE && dependencies.cantUnfinish);
			return result;
		};

		const canEditTODO = viewer.project
			? viewer.project.statusColumnsV2.edges.filter(col => col.node.category === WORKFLOW_CATEGORIES.TODO).length > 1
			: viewer.projectGroup.projects.edges[0].node.statusColumnsV2.edges.filter(
					col => col.node.category === WORKFLOW_CATEGORIES.TODO
			  ).length > 1;

		const canEditDONE = viewer.project
			? viewer.project.statusColumnsV2.edges.filter(col => col.node.category === WORKFLOW_CATEGORIES.DONE).length > 1
			: viewer.projectGroup.projects.edges[0].node.statusColumnsV2.edges.filter(
					col => col.node.category === WORKFLOW_CATEGORIES.DONE
			  ).length > 1;

		const canEditStatus = column => {
			switch (column.node.category) {
				case WORKFLOW_CATEGORIES.TODO:
					return canEditTODO;
				case WORKFLOW_CATEGORIES.DONE:
					return canEditDONE;
				default:
					return true;
			}
		};

		const startDragColumn = useCallback(status => {
			setColumnBeingDragged(status);
			setIsDraggingColumn(true);
		}, []);

		const updateDraggedColumn = useCallback(
			(sourceOrder, newOrder, draggedLeft, targetCol) => {
				const {project} = viewer;
				if (
					isDraggingColumn &&
					newOrder !==
						statusColumnsOrderMap.get(
							project ? columnBeingDragged.node.id : columnBeingDragged.node.projectGroupStatusColumnId
						)
				) {
					const statusColumnsOrderMapCpy = cloneDeep(statusColumnsOrderMap);
					const previousOrder = statusColumnsOrderMapCpy.get(
						project ? columnBeingDragged.node.id : columnBeingDragged.node.projectGroupStatusColumnId
					);
					const orderIncreased = newOrder > previousOrder;
					const columns = project
						? project.statusColumnsV2.edges
						: viewer.projectGroup.projects.edges[0].node.statusColumnsV2.edges;
					columns.forEach(col => {
						const key = project ? col.node.id : col.node.projectGroupStatusColumnId;
						if (
							(project && key === columnBeingDragged.node.id) ||
							(!project && key === columnBeingDragged.node.projectGroupStatusColumnId)
						) {
							statusColumnsOrderMapCpy.set(key, newOrder);
						} else {
							const colOrder = statusColumnsOrderMapCpy.get(
								project ? col.node.id : col.node.projectGroupStatusColumnId
							);
							if (orderIncreased && colOrder > previousOrder && colOrder <= newOrder) {
								//we need to lower order by 1
								statusColumnsOrderMapCpy.set(key, colOrder - 1);
							} else if (!orderIncreased && colOrder < previousOrder && colOrder >= newOrder) {
								//we need to increase order by 1
								statusColumnsOrderMapCpy.set(key, colOrder + 1);
							}
						}
					});
					setStatusColumnsOrderMap(statusColumnsOrderMapCpy);
				}
			},
			[viewer, isDraggingColumn, statusColumnsOrderMap, columnBeingDragged]
		);

		const saveStatusesReorder = useCallback(() => {
			if (
				columnBeingDragged &&
				statusColumnsOrderMap.has(
					viewer.project ? columnBeingDragged.node.id : columnBeingDragged.node.projectGroupStatusColumnId
				)
			) {
				const newOrder = Math.round(
					statusColumnsOrderMap.get(
						viewer.project ? columnBeingDragged.node.id : columnBeingDragged.node.projectGroupStatusColumnId
					)
				);
				const mutationObject = {
					id: columnBeingDragged.node.id,
					order: newOrder,
					isIncrementingOrder: newOrder > columnBeingDragged.node.order,
				};
				const onSuccess = _ => {
					setColumnBeingDragged(null);
					setIsDraggingColumn(false);
				};
				Util.CommitMutation(UpdateStatusColumnMutation, mutationObject, onSuccess);
			}
		}, [columnBeingDragged, statusColumnsOrderMap]);

		const statusColumnIdsMap = useMemo(() => {
			if (isConnectedParent) {
				// reduce => acc.concat is a sad alternative to flatMap since IE and Edge do not support flatMap. :(
				const statusColumns = viewer.projectGroup.projects.edges.reduce(
					(acc, project) =>
						acc.concat(project.node.statusColumnsV2 ? project.node.statusColumnsV2.edges.map(col => col.node) : []),
					[]
				);
				return statusColumns.reduce((acc, col) => {
					if (!acc[col.projectGroupStatusColumnId]) {
						acc[col.projectGroupStatusColumnId] = [];
					}
					acc[col.projectGroupStatusColumnId].push(col.id);
					return acc;
				}, {});
			} else {
				const statusColumns = viewer.project.statusColumnsV2.edges.map(col => col.node.id);
				return groupBy(statusColumns, id => id);
			}
		}, [viewer, isConnectedParent]);

		const unselectTasks = useCallback(() => {
			setSelectedTasks([]);
		}, []);

		const getColumns = containerHeight => {
			let statusColumns = isConnectedParent
				? [...viewer.projectGroup.projects.edges[0].node.statusColumnsV2.edges].filter(
						col => col.node.projectGroupStatusColumnId
				  )
				: [...viewer.project.statusColumnsV2.edges];

			const compareOrder = (a, b) => {
				const aOrder = statusColumnsOrderMap.has(viewer.project ? a.node.id : a.node.projectGroupStatusColumnId)
					? statusColumnsOrderMap.get(viewer.project ? a.node.id : a.node.projectGroupStatusColumnId)
					: a.node.order;
				const bOrder = statusColumnsOrderMap.has(viewer.project ? b.node.id : b.node.projectGroupStatusColumnId)
					? statusColumnsOrderMap.get(viewer.project ? b.node.id : b.node.projectGroupStatusColumnId)
					: b.node.order;
				if (aOrder < bOrder) return -1;
				if (aOrder > bOrder) return 1;
				else return 0;
			};

			statusColumns.sort(compareOrder);
			return statusColumns.map((column, index) => {
				let statusColumnIds = isConnectedParent
					? statusColumnIdsMap[column.node.projectGroupStatusColumnId]
					: statusColumnIdsMap[column.node.id];
				return (
					<ProjectWorkflowColumn
						selectedTasks={selectedTasks}
						unselectTasks={unselectTasks}
						key={column.node.id}
						isProjectLocked={isProjectLocked}
						projectIds={projectIds}
						statusColumnIds={statusColumnIds}
						taskMapRef={taskMapRef}
						taskSizeMapRef={taskSizeMapRef}
						imgSizeMapRef={imgSizeMapRef}
						tasksCountData={data}
						isConnectedParent={isConnectedParent}
						intl={intl}
						canEditStatus={canEditStatus(column)}
						canEditWorkflow={canEditWorkflow}
						isNewColumn={
							viewer.project
								? newColumnId === column.node.id
								: newColumnId === column.node.projectGroupStatusColumnId
						}
						searchQueryValue={searchQueryValue}
						filters={filters}
						viewer={viewer}
						column={column}
						relay={relay}
						index={index}
						onToggleColumn={handleToggleColumn}
						shouldShowDisabledOverlay={shouldShowDisabledOverlay(column)}
						integrationRestriction={integrationRestriction}
						expanded={
							isColumnsExpandedMap.get(column.node.id) === undefined
								? true
								: isColumnsExpandedMap.get(column.node.id)
						}
						theEyeOptions={enabledColumns}
						containerHeight={containerHeight}
						startDragColumn={startDragColumn}
						updateDraggedColumn={updateDraggedColumn}
						saveStatusesReorder={saveStatusesReorder}
						draggingColumn={isDraggingColumn}
						statusOrder={statusColumnsOrderMap.get(
							viewer.project ? column.node.id : column.node.projectGroupStatusColumnId
						)}
					/>
				);
			});
		};

		const getIntegrationRestrictions = task => {
			if (task.jiraId) {
				return setIntegrationRestriction('jira');
			} else if (task.vstsId) {
				return setIntegrationRestriction('ado');
			} else if (JiraUtil.isJiraToForecastOneWaySync(viewer.company)) {
				return setIntegrationRestriction('jira_to_forecast_one_way_sync');
			}
		};
		const onDragStart = e => {
			const draggedTask = mapDraggableIdToTask(e.draggableId, taskMapRef.current);
			if (draggedTask) {
				setDragDependencies(draggedTask);
				getIntegrationRestrictions(draggedTask);
			}
		};

		const onDragCancel = e => {
			setDependencies({cantStart: null, cantFinish: null, cantUnfinish: null});
		};

		const onDragEnd = (draggable, droppableId, placeholderId) => {
			const sourceDroppableId = draggable.droppableId;
			const destinationDroppableId = droppableId;
			const draggableId = draggable.draggableId;
			const {project, projectGroup, company} = viewer;

			const onDragEndSuccess = (result, isDraggingSelectedTask) => {
				setCardReorderInProgress(false);
				const errors = result && result.updateTask ? result.updateTask.errors : null;
				if (errors && errors.includes('jira_transition_failed')) {
					showModal({
						type: MODAL_TYPE.GENERIC,
						content: (
							<div>
								<Warning messageId="common.invalid_action_modal_title" />
								<div className="warning-part-2">
									{intl.formatMessage({id: 'modal.jira.transition_failed.warning_1'})}
								</div>
								<div className="warning-part-2">
									{intl.formatMessage({id: 'modal.jira.transition_failed.warning_2'})}
								</div>
							</div>
						),
						className: 'default-warning-modal',
						buttons: [
							{
								text: 'OK',
								style: BUTTON_STYLE.FILLED,
								color: BUTTON_COLOR.WHITE,
							},
						],
					});
				}
				if (result?.updateTask?.taskEdges && isDraggingSelectedTask) {
					const updatedSelectedTasks = cloneDeep(result.updateTask.taskEdges);
					setSelectedTasks(updatedSelectedTasks);
				}
			};

			promptTimeRegistration(
				sourceDroppableId,
				destinationDroppableId,
				draggableId,
				taskMapRef,
				viewer.project ? [viewer.project] : viewer.projectGroup.projects.edges.map(project => project.node),
				isConnectedParent,
				viewer.actualPersonId
			);

			moveTaskToColumn(
				sourceDroppableId,
				destinationDroppableId,
				draggableId,
				placeholderId,
				taskMapRef,
				project,
				projectGroup,
				company.id,
				onDragEndSuccess,
				selectedTasks,
				setCardReorderInProgress
			);
		};

		// app-header: 36, app-subheader: 35, page-header-title: 46, page-header: 70,
		const containerHeight = (size ? size.height : window.innerHeight) - (70 + 46 + 35 + 36 + 10 + (buyNowTime ? 40 : 0));

		const taskCount = selectedTasks.length;

		return (
			<WorkflowPageContainer data-cy={'workflow-page'}>
				{taskCount > 0 && (
					<BulkSelectPopup
						itemCount={taskCount}
						counterText={intl.formatMessage({id: 'bulk_edit.tasks_selected'})}
						actionOptions={getBulkUpdateOptions()}
						onClose={() => setSelectedTasks([])}
						complexOptions={getComplexBulkOptions(showAutomateModal, null, intl)}
					/>
				)}
				<ProjectHeader
					title="Task Board"
					projectGroup={viewer.projectGroup}
					project={viewer.project}
					psProject={viewer.psProject}
					titleContent={getHeaderTitleContent()}
					buttons={getHeader()}
				/>
				<ContentContainer>
					<DragDropContext
						onDragStart={e => {
							onDragStart(e);
						}}
						onDragCancel={e => {
							onDragCancel(e);
						}}
						onDragEnd={(draggable, droppableId, placeholderId) => {
							onDragEnd(draggable, droppableId, placeholderId);
							if (dependencies.cantStart || dependencies.cantFinish || dependencies.cantUnfinish) {
								setDependencies({cantStart: null, cantFinish: null, cantUnfinish: null});
							}
							if (integrationRestriction) {
								setIntegrationRestriction(null);
							}
						}}
						scrollContainerHeight={containerHeight}
						scrollContainerMinHeight={containerHeight}
						outerScrollBar={true}
						dragAndDropGroup={DragAndDropGroup}
						scrollProps={{
							renderTrackHorizontal: ({style, ...props}) => (
								<div
									{...props}
									style={{
										...style,
										zIndex: 999,
										right: '2px',
										bottom: '2px',
										left: '2px',
										borderRadius: '3px',
									}}
								/>
							),
						}}
					>
						<WorkflowContext.Provider
							value={{
								refetchData: getFreshData,
								selectedTasks,
								deselectTasksByIds,
								selectTasks,
								cardReorderInProgress,
							}}
						>
							<WorkflowWrapper>{getColumns(containerHeight)}</WorkflowWrapper>
						</WorkflowContext.Provider>
					</DragDropContext>
				</ContentContainer>
			</WorkflowPageContainer>
		);
	}
);

// RELAY SECTION

const ProjectWorkflowPageQuery = graphql`
	query ProjectWorkflowPage_Query($projectId: String, $groupId: String) {
		viewer {
			actualPersonId
			component(name: "project_faster_workflow")
			project(id: $projectId) {
				id
			}
			projectGroup(id: $groupId) {
				projects(first: 1000000) {
					edges {
						node {
							id
							name
							companyProjectId
							status
						}
					}
				}
			}
			...ProjectWorkflowPage_viewer @arguments(projectId: $projectId, groupId: $groupId)
		}
	}
`;

export {ProjectWorkflowPageQuery};

export default withRouter(
	withSocketHandling(
		createFragmentContainer(ProjectWorkflowPage, {
			viewer: graphql`
				fragment ProjectWorkflowPage_viewer on Viewer
				@argumentDefinitions(projectId: {type: "String"}, groupId: {type: "String"}) {
					id
					backendId
					actualPersonId
					timerStartDate
					timerTask {
						id
					}
					availableFeatureFlags {
						key
					}
					company {
						id
						jiraCloudEnabled
						jiraServerEnabled
						integrations {
							jiraCloud {
								syncSettings {
									isJiraToForecastOneWaySync
								}
							}
							jiraServer {
								syncSettings {
									isJiraToForecastOneWaySync
								}
							}
						}
						# FILTER_TYPE.TEAM
						teams(first: 1000000) {
							edges {
								node {
									id
								}
							}
						}
						# FILTER_TYPE.LABEL, FILTER_TYPE.TASK_PROJECT_LABEL
						labels(first: 1000000, labelType: TASK) {
							edges {
								node {
									id
									name
									color
									category {
										id
										name
									}
									...LabelDropdown_labels
								}
							}
						}
						# FILTER_TYPE.ROLE
						roles(first: 1000000) {
							edges {
								node {
									id
									name
								}
							}
						}
					}
					project(id: $projectId) {
						...ProjectHeader_project
						...SecondaryNavigation_project
						id
						taskLevels
						status
						companyProjectId
						customProjectId
						sprintTimeBox
						useTaskFollowers
						useTaskOwner
						projectColor
						name
						projectStartYear
						projectStartMonth
						projectStartDay
						projectEndYear
						projectEndMonth
						projectEndDay
						billable
						estimationUnit
						isJiraProject
						jiraCloudEpicIds
						jiraCloudProject {
							id
						}
						harvestProjectId
						vstsProject
						vstsAccount
						vstsArea
						isInProjectGroup
						useTaskHierarchy
						manualProgressOnProjectEnabled
						manualProgressOnPhasesEnabled
						manualProgressOnTasksEnabled
						sprints(first: 100000) {
							edges {
								node {
									id
									name
									startDay
									startMonth
									startYear
									endDay
									endMonth
									endYear
								}
							}
						}
						projectPersons(first: 1000000) {
							edges {
								node {
									person {
										id
										initials
										firstName
										lastName
										profilePictureId
										profilePictureDefaultId
									}
								}
							}
						}
						phases(first: 100000) {
							edges {
								node {
									id
									name
								}
							}
						}
						statusColumnsV2(first: 1000000) @connection(key: "Project_statusColumnsV2", filters: []) {
							edges {
								node {
									id
									name
									order
									category
									encourageTimeRegistration
									jiraStatusId
									adoState
									userActions {
										canDelete
										canRename
										canCreateTask
									}
								}
							}
						}
					}
					projectGroup(id: $groupId) {
						...ProjectHeader_projectGroup
						...SecondaryNavigation_projectGroup
						id
						companyProjectGroupId
						name
						projectGroupPersons(first: 1000000) {
							edges {
								node {
									person {
										id
										permissions
										initials
										firstName
										lastName
										profilePictureId
										profilePictureDefaultId
									}
								}
							}
						}
						...ProjectWorkflowAddTask_projectGroup
						projects(first: 1000000) @connection(key: "ProjectGroup_projects", filters: []) {
							edges {
								node {
									estimationUnit
									id
									name
									status
									billable
									projectColor
									companyProjectId
									customProjectId
									taskLevels
									projectStartYear
									projectStartMonth
									projectStartDay
									projectEndYear
									projectEndMonth
									projectEndDay
									sprintTimeBox
									useTaskOwner
									useTaskFollowers
									useTaskHierarchy
									sprints(first: 100000) {
										edges {
											node {
												id
												name
												projectGroupSprintId
												isProjectGroupSprint
												startDay
												startMonth
												startYear
												endDay
												endMonth
												endYear
											}
										}
									}
									statusColumnsV2(first: 1000000) @connection(key: "Project_statusColumnsV2", filters: []) {
										edges {
											node {
												id
												name
												order
												category
												projectGroupStatusColumnId
												encourageTimeRegistration
												jiraStatusId
												adoState
												userActions {
													canDelete
													canRename
													canCreateTask
												}
											}
										}
									}
								}
							}
						}
					}
					psProject(companyProjectId: $projectId) {
						...ProjectHeader_psProject
					}
					filters(first: 1000000, projectId: $projectId, projectGroupId: $groupId)
						@connection(key: "Viewer_filters", filters: []) {
						edges {
							node {
								id
								name
								section
								value
							}
						}
					}
				}
			`,
		})
	)
);
