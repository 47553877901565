/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type appGitlab_viewer$ref: FragmentReference;
declare export opaque type appGitlab_viewer$fragmentType: appGitlab_viewer$ref;
export type appGitlab_viewer = {|
  +id: string,
  +company: ?{|
    +id: string,
    +gitlabEnabled: ?boolean,
    +gitlabServerEnabled: ?boolean,
    +gitlabServerUrl: ?string,
    +gitlabServerToken: ?string,
    +gitlabDeleteIssues: ?boolean,
  |},
  +$refType: appGitlab_viewer$ref,
|};
export type appGitlab_viewer$data = appGitlab_viewer;
export type appGitlab_viewer$key = {
  +$data?: appGitlab_viewer$data,
  +$fragmentRefs: appGitlab_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "appGitlab_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gitlabEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gitlabServerEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gitlabServerUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gitlabServerToken",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gitlabDeleteIssues",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '63afd992d0c6b7a6c8a659e3a09db8bc';

module.exports = node;
