/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type TimeIncrementsSection_company$ref: FragmentReference;
declare export opaque type TimeIncrementsSection_company$fragmentType: TimeIncrementsSection_company$ref;
export type TimeIncrementsSection_company = {|
  +timeRegistrationSettings: ?{|
    +id: string,
    +granularity: ?string,
  |},
  +$refType: TimeIncrementsSection_company$ref,
|};
export type TimeIncrementsSection_company$data = TimeIncrementsSection_company;
export type TimeIncrementsSection_company$key = {
  +$data?: TimeIncrementsSection_company$data,
  +$fragmentRefs: TimeIncrementsSection_company$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TimeIncrementsSection_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TimeRegistrationSettingsType",
      "kind": "LinkedField",
      "name": "timeRegistrationSettings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "granularity",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Company"
};
// prettier-ignore
(node/*: any*/).hash = 'a55f4f18f596c13ba7496de78a2f40c8';

module.exports = node;
