import React, {useState} from 'react';
import styled from 'styled-components';
import {VARIANT} from '../../../../../../../../containers/modal/project-automate/project_automate_modal';
import ForecastTooltip, {TooltipColor} from '../../../../../tooltips/ForecastTooltip';
import {PredictionPopup} from './PredictionPopup';
import * as tracking from '../../../../../../../../tracking';
import {WarningIcon} from 'web-components/icons';
import {trackEvent} from '../../../../../../../../tracking/amplitude/TrackingV2';

const PredictionPopupWrapper = styled.div`
	cursor: pointer;
	min-width: 21px;
	height: 30px;
	padding: 2px;
	border: 1px solid #e6e6e6;
	border-right-width: 0;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	transition: all 0.2s ease-in;
	background-color: #fff;
	outline: none;
	&:hover,
	&:focus {
		background-color: #f7f7fe;
	}
`;

const IconWrapper = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
`;

export const PredictionPopupInputExtension = React.memo(({task, modelScore, showWarning, intl}) => {
	const [popupVisible, setPopupVisible] = useState(false);

	const predictedEstimate = task.predictedEstimate?.estimate;

	const onPopupShow = () => {
		tracking.trackAIEvent('Popover shown', VARIANT.ESTIMATES, null, {taskId: task.id, taskName: task.name});
		trackEvent('Task Overrun Prediction Popover', 'Shown', {taskId: task.id, taskName: task.name});
		localStorage.setItem('latest-overrun-prediction-shown', task.id);
		setPopupVisible(true);
	};

	const onPopupHidden = () => {
		setPopupVisible(false);
	};

	return showWarning ? (
		<PredictionPopupWrapper>
			<ForecastTooltip
				content={
					<PredictionPopup
						intl={intl}
						predictedEstimate={predictedEstimate}
						modelScore={modelScore}
						task={task}
						visible={popupVisible}
					/>
				}
				trigger={'click'}
				maxWidth={550}
				interactive={true}
				delay={[0, 100]}
				duration={[600, 250]}
				onMount={onPopupShow}
				onHidden={onPopupHidden}
				appendTo={document.body}
				spanStyle={{height: '100%', width: '100%'}}
				color={TooltipColor.PURPLE}
			>
				<IconWrapper>
					<WarningIcon size="small" variant={WarningIcon.VARIANT.WARNING} />
				</IconWrapper>
			</ForecastTooltip>
		</PredictionPopupWrapper>
	) : null;
});
