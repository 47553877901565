import React from 'react';

import moment from 'moment';
import {HexagonText, Table} from 'web-components';
import {APPROVAL_STATUS, MODULE_TYPES} from '../../../../../constants';
import {getClientName, getRoleName, getTimeRegTypeAndDetailDom} from '../TimeRegistrationReportTableUtil';
import Util from '../../../../shared/util/util';
import {hasModule} from '../../../../shared/util/ModuleUtil';
import {profilePicSrc} from '../../../../../directApi';

export const getTimeRegRow = (currency, intl, timeReg, enabledColumns, fetchMore, isSharedView) => {
	const enabledColumnsCopy = {...enabledColumns};

	const customFields =
		(hasModule(MODULE_TYPES.CUSTOM_FIELDS) || isSharedView) && timeReg.reportCustomFieldValues
			? [...timeReg.reportCustomFieldValues].sort((a, b) => (a.displayName > b.displayName ? 1 : -1))
			: [];

	const hasAccessToProject = timeReg.project
		? timeReg.project.fullAccessToProject
		: timeReg.task
		? timeReg.task.project.fullAccessToProject
		: true;

	const [project, task] = getTimeRegTypeAndDetailDom(timeReg, intl, isSharedView);

	const date = moment(timeReg.date).format('DD MMM YYYY');

	const phaseName = timeReg.task && timeReg.task.phase && timeReg.task.phase.name;

	const sprintName = timeReg.task?.sprint?.name;

	const roleName = hasAccessToProject ? getRoleName(timeReg) : null;

	const departmentName = timeReg.person?.department?.name;

	const clientName = hasAccessToProject ? getClientName(timeReg) : null;

	const billable = hasAccessToProject
		? timeReg.billable
			? intl.formatMessage({id: 'common.yes'})
			: intl.formatMessage({id: 'common.no'})
		: null;

	const invoiced = hasAccessToProject
		? timeReg.invoiced
			? intl.formatMessage({id: 'common.yes'})
			: intl.formatMessage({id: 'common.no'})
		: null;

	const approvalStatus = hasAccessToProject
		? timeReg.approvalStatus === APPROVAL_STATUS.APPROVED
			? intl.formatMessage({id: 'common.yes'})
			: intl.formatMessage({id: 'common.no'})
		: null;

	const reportedTime = Util.convertMinutesToFullHour(timeReg.timeRegistered, intl, true);
	const billableTime = Util.convertMinutesToFullHour(timeReg.billableRegistered, intl, true);
	const nonBillableTime = Util.convertMinutesToFullHour(timeReg.nonBillableRegistered, intl, true);
	const formattedPrice = intl.formatNumber(timeReg.price, {format: 'always_two_decimal'});
	const formattedCost = intl.formatNumber(timeReg.cost, {format: 'always_two_decimal'});
	if (
		!(
			enabledColumns.hasOwnProperty('timeRegType') ||
			enabledColumns.hasOwnProperty('timeRegDetail') ||
			enabledColumns.hasOwnProperty('taskName')
		)
	) {
		enabledColumnsCopy.needingSpace = true;
	}

	const fullName = timeReg.person.firstName
		? timeReg.person.firstName + (timeReg.person.lastName ? ` ${timeReg.person.lastName}` : '')
		: '';

	return [
		<Table.Column>
			<Table.Column.Cell title={timeReg.idleTime ? project : null}>{project}</Table.Column.Cell>
		</Table.Column>,
		<Table.Column>
			<Table.Column.Cell title={task}>{task}</Table.Column.Cell>
		</Table.Column>,
		<Table.Column>
			<Table.Column.Cell title={phaseName}>{phaseName}</Table.Column.Cell>
		</Table.Column>,
		<Table.Column>
			<Table.Column.Cell title={sprintName}>{sprintName}</Table.Column.Cell>
		</Table.Column>,
		<Table.Column>{reportedTime}</Table.Column>,
		<Table.Column>{billableTime}</Table.Column>,
		<Table.Column>{nonBillableTime}</Table.Column>,
		<Table.Column>
			<Table.Column.Cell title={timeReg.notes}>{timeReg.notes}</Table.Column.Cell>
		</Table.Column>,
		<Table.Column>{date}</Table.Column>,
		<Table.Column>{<HexagonText text={fullName} imgUrl={profilePicSrc(timeReg.person.profilePictureId)} />}</Table.Column>,
		<Table.Column>
			<Table.Column.Cell title={roleName}>{roleName}</Table.Column.Cell>
		</Table.Column>,
		<Table.Column>
			<Table.Column.Cell title={departmentName}>{departmentName}</Table.Column.Cell>
		</Table.Column>,
		<Table.Column>
			<Table.Column.Cell title={clientName}>{clientName}</Table.Column.Cell>
		</Table.Column>,
		<Table.Column>{billable}</Table.Column>,
		<Table.Column>{Util.GetFormattedCurrencySymbol(currency, formattedPrice)}</Table.Column>,
		<Table.Column>{Util.GetFormattedCurrencySymbol(currency, formattedCost)}</Table.Column>,
		<Table.Column>{invoiced}</Table.Column>,
		<Table.Column>{approvalStatus}</Table.Column>,
		...customFields.map(customField => (
			<Table.Column key={timeReg.id + '_' + customField.key}>
				<Table.Column.Cell>{customField.value}</Table.Column.Cell>
			</Table.Column>
		)),
	];
};
