import { MODAL_TYPE, showModal } from '../../../../shared/components/modals/generic_modal_conductor';
export const shareReport = (report) => {
    var _a, _b;
    showModal({
        type: MODAL_TYPE.SHARE_REPORT_MODAL,
        reportId: report.id,
        reportSharesInput: (_b = (_a = report.shares) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.map(share => {
            var _a, _b, _c;
            return {
                email: (_b = (_a = share === null || share === void 0 ? void 0 : share.node) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.email,
                sharedId: (_c = share === null || share === void 0 ? void 0 : share.node) === null || _c === void 0 ? void 0 : _c.id,
            };
        }),
        privateAccessInput: report.privateAccess,
        reportOwner: report.person,
        reportType: report.type,
    });
};
