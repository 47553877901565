/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type AppBambooHR_viewer$ref: FragmentReference;
declare export opaque type AppBambooHR_viewer$fragmentType: AppBambooHR_viewer$ref;
export type AppBambooHR_viewer = {|
  +id: string,
  +availableFeatureFlags: ?$ReadOnlyArray<?{|
    +key: ?string
  |}>,
  +company: ?{|
    +id: string,
    +useTimeOffApproval: ?boolean,
    +bambooHREnabled: ?boolean,
  |},
  +$refType: AppBambooHR_viewer$ref,
|};
export type AppBambooHR_viewer$data = AppBambooHR_viewer;
export type AppBambooHR_viewer$key = {
  +$data?: AppBambooHR_viewer$data,
  +$fragmentRefs: AppBambooHR_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppBambooHR_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "FeatureFlag",
      "kind": "LinkedField",
      "name": "availableFeatureFlags",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "useTimeOffApproval",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bambooHREnabled",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '40a43f598975d0ff0026a4c3ecea98d0';

module.exports = node;
