/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type labelRow_company$ref: FragmentReference;
declare export opaque type labelRow_company$fragmentType: labelRow_company$ref;
export type labelRow_company = {|
  +id: string,
  +createdAt: ?string,
  +$refType: labelRow_company$ref,
|};
export type labelRow_company$data = labelRow_company;
export type labelRow_company$key = {
  +$data?: labelRow_company$data,
  +$fragmentRefs: labelRow_company$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "labelRow_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    }
  ],
  "type": "Company"
};
// prettier-ignore
(node/*: any*/).hash = '51e5e00ed9d215ea109f170f003bf0a5';

module.exports = node;
