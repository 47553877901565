import Util from '../../../shared/util/util';
import DirectApi from '../../../../directApi';
export const INVOICE_INTEGRATIONS = {
    XERO: 'XERO',
    ECONOMIC: 'ECONOMIC',
    QUICKBOOKS: 'QUICKBOOKS',
};
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const defaultContactsMapper = (customers, integrationName) => !Array.isArray(customers)
    ? []
    : customers
        .sort((a, b) => Util.sortAlphabetically(a.name, b.name))
        .map(customer => ({
        id: String(customer.id),
        name: customer.name,
        taxId: String(customer.taxId),
        address: customer.address,
        integrationInstanceId: customer.integrationInstanceId,
        integration: integrationName,
    }));
export const fetchXeroContacts = () => DirectApi.Fetch('xero/allContacts').then(customers => defaultContactsMapper(customers, INVOICE_INTEGRATIONS.XERO));
export const fetchQuickbooksContacts = () => DirectApi.Fetch('quickbooks/all_customers').then(customers => defaultContactsMapper(customers, INVOICE_INTEGRATIONS.QUICKBOOKS));
export const fetchEconomicContacts = () => DirectApi.Fetch('economic/all_customers').then(customers => defaultContactsMapper(customers, INVOICE_INTEGRATIONS.ECONOMIC));
export const filterContactsByIntegrationInstanceId = (contacts, billFrom) => contacts.filter(contact => (!contact.integrationInstanceId || contact.integrationInstanceId === (billFrom === null || billFrom === void 0 ? void 0 : billFrom.externalId)) &&
    contact.integration === (billFrom === null || billFrom === void 0 ? void 0 : billFrom.integration));
