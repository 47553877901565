import { addDays, endOfMonth, endOfWeek, getWeek, isAfter, isBefore, isSameDay, isWeekend, startOfMonth, startOfWeek, } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import Util from '../../../shared/util/util';
import * as tracking from '../../../../tracking';
import RevokeGoogleCalendarTokenMutation from '../../../../mutations/revoke_google_calendar_token_mutation';
export const DAY_PREFIX = 'day-';
export const WEEK_PREFIX = 'week-';
export const LOCALSTORAGE_DISMISS_KEY = 'calendar-info-dismiss-count';
export const LOCALSTORAGE_TIMESHEETS_SELECTED_TAB = 'timesheets_selected_tab';
export const onKeyboardEventHandler = (event, day, isWeek, selectDateFromCalendar) => {
    switch (event.key) {
        case 'Enter':
            selectDateFromCalendar(day, isWeek);
            break;
        default:
            break;
    }
};
export const getHolidaysOfTheDay = (person, day) => {
    return person.holidayCalendar
        ? person.holidayCalendar.holidayCalendarEntries.edges.filter(d => d.node.day === day.getDate() && d.node.month === day.getMonth() + 1 && d.node.year === day.getFullYear())
        : [];
};
export const workingHourForTheDay = (person, day) => {
    const personStartDate = new Date(person.startDate || person.createdAt);
    const personEndDate = person.endDate ? new Date(person.endDate) : null;
    if (isBefore(day, personStartDate) || (personEndDate && (isSameDay(day, personEndDate) || isAfter(day, personEndDate)))) {
        return 0;
    }
    const workingHours = [
        person.sunday,
        person.monday,
        person.tuesday,
        person.wednesday,
        person.thursday,
        person.friday,
        person.saturday,
    ];
    return workingHours[day.getDay()];
};
export const isBetween = (day, first, last) => {
    return (isBefore(day, last) || isSameDay(day, last)) && (isAfter(day, first) || isSameDay(day, first));
};
export const workingHourForTheWeek = (person, lastDayOfWeek) => {
    const personStartDate = new Date(person.startDate || person.createdAt);
    const personEndDate = person.endDate && new Date(person.endDate);
    let workingHoursArray;
    const startOfWeekDay = startOfWeek(lastDayOfWeek);
    if (isBefore(lastDayOfWeek, personStartDate) ||
        (personEndDate && (isSameDay(startOfWeekDay, personEndDate) || isAfter(startOfWeekDay, personEndDate)))) {
        return 0; //the person didn't start yet or isn't working there anymore
    }
    else if (isBetween(personStartDate, startOfWeekDay, lastDayOfWeek)) {
        workingHoursArray = [
            person.sunday,
            person.monday,
            person.tuesday,
            person.wednesday,
            person.thursday,
            person.friday,
            person.saturday,
        ];
        const startDay = personStartDate.getDate();
        workingHoursArray = workingHoursArray.map((hours, index) => (index < startDay ? 0 : hours));
    }
    else if (personEndDate && isBetween(personEndDate, startOfWeekDay, lastDayOfWeek)) {
        workingHoursArray = [
            person.sunday,
            person.monday,
            person.tuesday,
            person.wednesday,
            person.thursday,
            person.friday,
            person.saturday,
        ];
        const endDay = personEndDate.getDate();
        workingHoursArray = workingHoursArray.map((hours, index) => (index >= endDay ? 0 : hours));
    }
    else {
        workingHoursArray = [
            person.sunday,
            person.monday,
            person.tuesday,
            person.wednesday,
            person.thursday,
            person.friday,
            person.saturday,
        ];
    }
    return workingHoursArray.reduce((total, sum) => total + sum);
};
const getWeekDateArray = (weekStart, hideWeekend) => {
    const includedDays = hideWeekend ? [1, 2, 3, 4, 5] : [0, 1, 2, 3, 4, 5, 6];
    const week = [];
    for (let n = 0; n < 7; n++) {
        const day = addDays(new Date(weekStart.getTime()), n);
        if (includedDays.includes(day.getDay())) {
            week.push(day);
        }
    }
    return week;
};
export const getWeeksOfMonth = (currentViewingMonth, hideWeekend) => {
    let startDate = startOfWeek(startOfMonth(currentViewingMonth));
    const endDate = endOfWeek(endOfMonth(currentViewingMonth));
    const calendar = [];
    while (isBefore(startDate, endDate)) {
        const weekStart = startDate;
        calendar.push({
            days: getWeekDateArray(weekStart, hideWeekend),
        });
        startDate = addDays(startDate, 7);
    }
    return calendar;
};
export const isDaySelected = (selectedDate, day) => {
    return selectedDate === DAY_PREFIX + day.getDate() + '-' + day.getMonth();
};
export const isWeekSelected = (selectedDate, week) => {
    const day = week.days[0];
    return selectedDate === WEEK_PREFIX + getWeek(day);
};
export const getSelectedDate = (isWeek, date) => {
    return isWeek ? WEEK_PREFIX + getWeek(date) : DAY_PREFIX + date.getDate() + '-' + date.getMonth();
};
export const getReadableSelectedDate = (isWeek, date) => {
    return isWeek ? WEEK_PREFIX + getWeek(date) : `${DAY_PREFIX}${date.getDate()}/${date.getMonth() + 1}`;
};
export const getFirstDayOfWeek = () => {
    return startOfWeek(new Date()).getDay();
};
export const getWeekdaysShort = (hideWeekend) => {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const result = [];
    let day = startOfWeek(new Date());
    for (let n = 0; n < 7; n++) {
        if (!(isWeekend(day) && hideWeekend)) {
            result.push(days[day.getDay()]);
        }
        day = addDays(day, 1);
    }
    return result;
};
export const getStartDate = (event) => {
    return (event === null || event === void 0 ? void 0 : event.start) && (event === null || event === void 0 ? void 0 : event.calendarTimeZone) ? utcToZonedTime(event.start, event.calendarTimeZone) : null;
};
export const getEndDate = (event) => {
    return (event === null || event === void 0 ? void 0 : event.end) && (event === null || event === void 0 ? void 0 : event.calendarTimeZone) ? utcToZonedTime(event.end, event.calendarTimeZone) : null;
};
export const revokeTokens = (personId) => {
    tracking.trackEvent('Google Calendar Revoke Tokens');
    localStorage.removeItem(LOCALSTORAGE_TIMESHEETS_SELECTED_TAB);
    localStorage.setItem(LOCALSTORAGE_DISMISS_KEY, '0');
    Util.CommitMutation(RevokeGoogleCalendarTokenMutation, {
        personId: personId,
    });
};
