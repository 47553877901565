import React from 'react';

const priorityIcon = props => {
	const fill = props.fill ?
		props.fill :
		props.active ? '#e4253b' : '#cecece';

	return (
		<span tabIndex="0" onKeyDown={props.onKeyDown} title={props.title} data-cy={props.cy} className="blocked-icon-wrapper">
			<svg onClick={props.onClick ? props.onClick : null} className={'bloked-icon ' + (props.className ? props.className : '') + (props.active ? ' active' : '')} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
				<path
					data-cy={props.cy ? props.cy + '-path' : ''}
					fill={fill}
					fillRule="evenodd"
					d="M8 1.6A6.407 6.407 0 0 0 1.6 8c0 3.529 2.871 6.4 6.4 6.4 3.529 0 6.4-2.871 6.4-6.4 0-3.529-2.871-6.4-6.4-6.4zM8 16c-4.411 0-8-3.589-8-8s3.589-8 8-8c4.412 0 8 3.589 8 8s-3.588 8-8 8zm3.766-10.635L9.13 8l2.635 2.634-1.132 1.131L8 9.132l-2.634 2.633-1.132-1.131L6.87 8 4.234 5.365l1.132-1.131L8 6.868l2.634-2.634 1.132 1.131z"
				/>
			</svg>
		</span>
	);
}

export default priorityIcon;
