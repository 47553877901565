/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
query TimeRegistrationModalTimeOnDay_Query(
  $personId: ID
  $date: String
) {
  viewer {
    actualPersonId
    component(name: "time_registration_modal_time_on_day")
    ...TimeRegistrationModalTimeOnDay_viewer_3lmu9T
    id
  }
}

fragment TimeRegistrationModalTimeOnDay_viewer_3lmu9T on Viewer {
  company {
    person(id: $personId) {
      startDate
      endDate
      createdAt
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
      timeRegistrations(startDate: $date, endDate: $date) {
        edges {
          node {
            minutesRegistered
            id
          }
        }
      }
      id
    }
    id
  }
}
*/
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "personId",
            "type": "ID"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "date",
            "type": "String"
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actualPersonId",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": [
            {
                "kind": "Literal",
                "name": "name",
                "value": "time_registration_modal_time_on_day"
            }
        ],
        "kind": "ScalarField",
        "name": "component",
        "storageKey": "component(name:\"time_registration_modal_time_on_day\")"
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "TimeRegistrationModalTimeOnDay_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "date",
                                    "variableName": "date"
                                },
                                {
                                    "kind": "Variable",
                                    "name": "personId",
                                    "variableName": "personId"
                                }
                            ],
                            "kind": "FragmentSpread",
                            "name": "TimeRegistrationModalTimeOnDay_viewer"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "TimeRegistrationModalTimeOnDay_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Company",
                            "kind": "LinkedField",
                            "name": "company",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": [
                                        {
                                            "kind": "Variable",
                                            "name": "id",
                                            "variableName": "personId"
                                        }
                                    ],
                                    "concreteType": "Person",
                                    "kind": "LinkedField",
                                    "name": "person",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "startDate",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "endDate",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "createdAt",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "monday",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "tuesday",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "wednesday",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "thursday",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "friday",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "saturday",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "sunday",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": [
                                                {
                                                    "kind": "Variable",
                                                    "name": "endDate",
                                                    "variableName": "date"
                                                },
                                                {
                                                    "kind": "Variable",
                                                    "name": "startDate",
                                                    "variableName": "date"
                                                }
                                            ],
                                            "concreteType": "TimeRegTypeConnection",
                                            "kind": "LinkedField",
                                            "name": "timeRegistrations",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "TimeRegTypeEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "TimeRegType",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "minutesRegistered",
                                                                    "storageKey": null
                                                                },
                                                                (v3 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        (v3 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v3 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v3 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "TimeRegistrationModalTimeOnDay_Query",
            "operationKind": "query",
            "text": "query TimeRegistrationModalTimeOnDay_Query(\n  $personId: ID\n  $date: String\n) {\n  viewer {\n    actualPersonId\n    component(name: \"time_registration_modal_time_on_day\")\n    ...TimeRegistrationModalTimeOnDay_viewer_3lmu9T\n    id\n  }\n}\n\nfragment TimeRegistrationModalTimeOnDay_viewer_3lmu9T on Viewer {\n  company {\n    person(id: $personId) {\n      startDate\n      endDate\n      createdAt\n      monday\n      tuesday\n      wednesday\n      thursday\n      friday\n      saturday\n      sunday\n      timeRegistrations(startDate: $date, endDate: $date) {\n        edges {\n          node {\n            minutesRegistered\n            id\n          }\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
        }
    };
})();
node.hash = '418aae58fc133ffcd62da42fc85a087d';
export default node;
