/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type useProjectCalculatedFieldsHookQueryVariables = {|
  projectIds?: ?$ReadOnlyArray<?string>
|};
export type useProjectCalculatedFieldsHookQueryResponse = {|
  +viewer: ?{|
    +component: ?string,
    +actualPersonId: ?string,
    +company: ?{|
      +id: string,
      +name: ?string,
      +projectCalculatedFields: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +projectId: string,
            +progress: ?{|
              +progressType: ?string,
              +value: ?number,
            |},
          |}
        |}>
      |},
    |},
  |}
|};
export type useProjectCalculatedFieldsHookQuery = {|
  variables: useProjectCalculatedFieldsHookQueryVariables,
  response: useProjectCalculatedFieldsHookQueryResponse,
|};
*/


/*
query useProjectCalculatedFieldsHookQuery(
  $projectIds: [ID]
) {
  viewer {
    component(name: "useProjectCalculatedFields")
    actualPersonId
    company {
      id
      name
      projectCalculatedFields(first: 1000, projectIds: $projectIds) {
        edges {
          node {
            projectId
            progress {
              progressType
              value
              id
            }
            id
          }
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectIds",
    "type": "[ID]"
  }
],
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "useProjectCalculatedFields"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"useProjectCalculatedFields\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  },
  {
    "kind": "Variable",
    "name": "projectIds",
    "variableName": "projectIds"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "progressType",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useProjectCalculatedFieldsHookQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "ProjectServiceProjectCalculatedFieldsTypeConnection",
                "kind": "LinkedField",
                "name": "projectCalculatedFields",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectServiceProjectCalculatedFieldsTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectServiceProjectCalculatedFieldsType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProjectServiceProgressType",
                            "kind": "LinkedField",
                            "name": "progress",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              (v8/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useProjectCalculatedFieldsHookQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "ProjectServiceProjectCalculatedFieldsTypeConnection",
                "kind": "LinkedField",
                "name": "projectCalculatedFields",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectServiceProjectCalculatedFieldsTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectServiceProjectCalculatedFieldsType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProjectServiceProgressType",
                            "kind": "LinkedField",
                            "name": "progress",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              (v8/*: any*/),
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "useProjectCalculatedFieldsHookQuery",
    "operationKind": "query",
    "text": "query useProjectCalculatedFieldsHookQuery(\n  $projectIds: [ID]\n) {\n  viewer {\n    component(name: \"useProjectCalculatedFields\")\n    actualPersonId\n    company {\n      id\n      name\n      projectCalculatedFields(first: 1000, projectIds: $projectIds) {\n        edges {\n          node {\n            projectId\n            progress {\n              progressType\n              value\n              id\n            }\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e9f6c5f3e9015ac328450ac1e3a71685';

module.exports = node;
