import {Table} from 'web-components';
import React from 'react';
import Styled from 'styled-components/macro';
import BugTaskInputIconPath from '../../../../../../images/ico-bug';
import NonBillableTaskInputIconPath from '../../../../../../images/ico-nonbillable';
import PriorityTaskInputIconPath from '../../../../../../images/ico-priority';
import BlockedTaskInputIconPath from '../../../../../../images/ico-blocked';
import {injectIntl} from 'react-intl';
import * as CompanySetupUtils from '../../../../../shared/util/CompanySetupUtil';
import {HIDDEN_FEATURES} from '../../../../../../constants';

const TaskIndicatorsColumn = props => {
	const {formatMessage} = props.intl;
	const task = props.task;
	const iconColor = '#535353';

	return (
		<Table.Column>
			<IndicatorsContainer>
				{task.indicators.priority && (
					<IndicatorIcon>
						<PriorityTaskInputIconPath
							cy="icon-priority"
							title={formatMessage({id: 'common.high_priority'})}
							active={false}
							fill={iconColor}
						/>
					</IndicatorIcon>
				)}
				{task.indicators.bug && (
					<IndicatorIcon>
						<BugTaskInputIconPath
							cy="icon-bug"
							title={formatMessage({id: 'common.bug'})}
							active={false}
							className="active-state"
							fill={iconColor}
						/>
					</IndicatorIcon>
				)}
				{!task.indicators.billable && !CompanySetupUtils.isFeatureHidden(HIDDEN_FEATURES.REVENUE) && (
					<IndicatorIcon>
						<NonBillableTaskInputIconPath
							cy="icon-nonbillable"
							title={formatMessage({id: 'common.non-billable'})}
							active={true}
							className="active-state"
							fill={iconColor}
						/>
					</IndicatorIcon>
				)}
				{task.indicators.blocked && (
					<IndicatorIcon>
						<BlockedTaskInputIconPath
							cy="icon-blocked"
							title={formatMessage({id: 'common.blocked'})}
							active={false}
							fill={iconColor}
						/>
					</IndicatorIcon>
				)}
			</IndicatorsContainer>
		</Table.Column>
	);
};

export default injectIntl(TaskIndicatorsColumn);

export const IndicatorsContainer = Styled.div`
	display: inline;
    display:flex;
    padding-left: 16px;
    padding-right: 16px;
`;

export const IndicatorIcon = Styled.span`
	display:inline;
	margin: 0 4px;
	display:flex;
`;
