import React from 'react';
import styled from 'styled-components';
import {
	DeprecatedCreativeModal as CreativeModal,
	DeprecatedText as Text,
	deprecatedTheme as theme,
	FlexColumn,
} from '@forecast-it/design-system';
import {useIntl} from 'react-intl';

export const SelectionBody = styled.div`
	padding: ${theme.spacing.m};
`;

export const LeaveAllocationsPageModal = ({closeModal, leavePageCallback}) => {
	const intl = useIntl();
	const {formatMessage} = intl;

	const updateProgramBudgetType = () => {
		closeModal();
		leavePageCallback();
	};

	return (
		<CreativeModal
			title={formatMessage({id: 'common.leave_page_question'})}
			closeModal={closeModal}
			onCreateHandler={updateProgramBudgetType}
			creativeButtonText={formatMessage({id: 'common.leave_now'})}
			size={'m'}
			alignItems={'left'}
		>
			<FlexColumn>
				<Text>{formatMessage({id: 'common.leave_allocations_page_warning'})}</Text>
			</FlexColumn>
		</CreativeModal>
	);
};
