/* eslint-disable no-unused-vars */
import React, {Component, Children, cloneElement} from 'react';
import PropTypes from 'prop-types';
import {TYPES} from './NodeContentRenderer';
const styles = {};
export class TreeNodeRenderer extends Component {
	render() {
		const {
			children,
			listIndex,
			swapFrom,
			swapLength,
			swapDepth,
			scaffoldBlockPxWidth,
			lowerSiblingCounts,
			connectDropTarget,
			isOver,
			draggedNode,
			canDrop,
			treeIndex,
			treeId, // Delete from otherProps
			getPrevRow, // Delete from otherProps
			node, // Delete from otherProps
			path, // Delete from otherProps
			rowDirection,
			...otherProps
		} = this.props;

		return connectDropTarget(
			<div key={node.id} {...otherProps} className={styles.node} data-cy={'table-row-' + node.type}>
				<div
					style={{
						width: '100%',
						height: '100%',
						borderBottom:
							(node.type === TYPES.ADD_TASK && node.parentTaskId) ||
							node.type === TYPES.TASK ||
							node.type === TYPES.ROLE_GROUPING ||
							node.type === TYPES.PERSON_GROUPING ||
							node.type === TYPES.BASELINE
								? '1px solid #e6e6e6'
								: null,
						backgroundColor: node.type === TYPES.BASELINE ? '#F7F7FE' : null,
					}}
				>
					{Children.map(children, child =>
						cloneElement(child, {
							isOver,
							canDrop,
							draggedNode,
							lowerSiblingCounts,
							listIndex,
							swapFrom,
							swapLength,
							swapDepth,
						})
					)}
				</div>
			</div>
		);
	}
}

TreeNodeRenderer.defaultProps = {
	swapFrom: null,
	swapDepth: null,
	swapLength: null,
	canDrop: false,
	draggedNode: null,
};

TreeNodeRenderer.propTypes = {
	treeIndex: PropTypes.number.isRequired,
	treeId: PropTypes.string.isRequired,
	swapFrom: PropTypes.number,
	swapDepth: PropTypes.number,
	swapLength: PropTypes.number,
	scaffoldBlockPxWidth: PropTypes.number.isRequired,
	lowerSiblingCounts: PropTypes.arrayOf(PropTypes.number).isRequired,

	listIndex: PropTypes.number.isRequired,
	children: PropTypes.node.isRequired,

	// Drop target
	connectDropTarget: PropTypes.func.isRequired,
	isOver: PropTypes.bool.isRequired,
	canDrop: PropTypes.bool,
	draggedNode: PropTypes.shape({}),

	// used in dndManager
	getPrevRow: PropTypes.func.isRequired,
	node: PropTypes.shape({}).isRequired,
	path: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
	rowDirection: PropTypes.string.isRequired,
};

export default TreeNodeRenderer;
