import styled from 'styled-components';
export const DesignSystemDropdownOldLookAdapter = styled.div `
	width: 100%;

	> button {
		height: 32px;
		border-radius: 3px;
		border: solid 1px ${props => props.theme.colors.veryLightGray};
		&:active {
			border: solid 1px ${props => props.theme.colors.veryLightGray};
			padding: ${props => props.theme.sys.spacing['200']} ${props => props.theme.sys.spacing['400']};
		}
	}

	div {
		font-size: 14px;
	}
`;
