/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
const node = (function () {
    var v0 = {
        "kind": "Variable",
        "name": "endDay",
        "variableName": "endDay"
    }, v1 = {
        "kind": "Variable",
        "name": "endMonth",
        "variableName": "endMonth"
    }, v2 = {
        "kind": "Variable",
        "name": "endYear",
        "variableName": "endYear"
    }, v3 = {
        "kind": "Variable",
        "name": "startDay",
        "variableName": "startDay"
    }, v4 = {
        "kind": "Variable",
        "name": "startMonth",
        "variableName": "startMonth"
    }, v5 = {
        "kind": "Variable",
        "name": "startYear",
        "variableName": "startYear"
    }, v6 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "TimeRegistrationRequest",
            "kind": "LinkedField",
            "name": "timeRegRequest",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "minutesRegistered",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "billableMinutesRegistered",
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "valid",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "validationReasons",
            "storageKey": null
        }
    ];
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "personId",
                "type": "ID!"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "startYear",
                "type": "Int!"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "startMonth",
                "type": "Int!"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "startDay",
                "type": "Int!"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "endYear",
                "type": "Int!"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "endMonth",
                "type": "Int!"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "endDay",
                "type": "Int!"
            }
        ],
        "kind": "Fragment",
        "metadata": null,
        "name": "CopyLastWeekValidationSection_viewer",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": [
                            {
                                "kind": "Variable",
                                "name": "id",
                                "variableName": "personId"
                            }
                        ],
                        "concreteType": "Person",
                        "kind": "LinkedField",
                        "name": "person",
                        "plural": false,
                        "selections": [
                            {
                                "alias": "doneTasksExcluded",
                                "args": [
                                    (v0 /*: any*/),
                                    (v1 /*: any*/),
                                    (v2 /*: any*/),
                                    {
                                        "kind": "Literal",
                                        "name": "excludeDoneTasks",
                                        "value": true
                                    },
                                    (v3 /*: any*/),
                                    (v4 /*: any*/),
                                    (v5 /*: any*/)
                                ],
                                "concreteType": "ValidatedTimeRegistration",
                                "kind": "LinkedField",
                                "name": "validatedTimeRegsInDateRange",
                                "plural": true,
                                "selections": (v6 /*: any*/),
                                "storageKey": null
                            },
                            {
                                "alias": "doneTasksIncluded",
                                "args": [
                                    (v0 /*: any*/),
                                    (v1 /*: any*/),
                                    (v2 /*: any*/),
                                    {
                                        "kind": "Literal",
                                        "name": "excludeDoneTasks",
                                        "value": false
                                    },
                                    (v3 /*: any*/),
                                    (v4 /*: any*/),
                                    (v5 /*: any*/)
                                ],
                                "concreteType": "ValidatedTimeRegistration",
                                "kind": "LinkedField",
                                "name": "validatedTimeRegsInDateRange",
                                "plural": true,
                                "selections": (v6 /*: any*/),
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Viewer"
    };
})();
node.hash = '4e5fc0c80b94e8f96a60e1f37405ea08';
export default node;
