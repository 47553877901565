import React, {Component} from 'react';
import {cloneDeep} from 'lodash';

import {createFragmentContainer, graphql} from 'react-relay';
import CreateRoleMutation from '../../../mutations/create_role_mutation';
import UpdateRoleMutation from '../../../mutations/update_role_mutation';
import DeleteRoleMutation from '../../../mutations/delete_role_mutation';
import {FormattedMessage, injectIntl} from 'react-intl';
import * as tracking from '../../../tracking';
import {createToast} from '../../shared/components/toasts/another-toast/toaster';
import CustomScrollDiv from '../../shared/components/scroll-bars/custom_scroll_div';
import Util from '../../shared/util/util';
import {MODAL_TYPE, showModal} from '../../shared/components/modals/generic_modal_conductor';
import SettingsResourcesSubNav from './SettingsResourcesSubNav';
import {trackPage, unregisterPageInfo} from '../../../tracking/amplitude/TrackingV2';

class settingsRoles extends Component {
	constructor(props) {
		super(props);

		this.state = {newRoleName: ''};
		this.roleInputs = [];

		this.superPropertyChecksum = trackPage('Settings Roles');
	}

	componentDidMount() {
		document.title = 'Roles - Account - Forecast';
		// Segment
		tracking.trackPage('settings-roles');
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	showChangesSavedToast() {
		createToast({duration: 2000, message: this.props.intl.formatMessage({id: 'common.changes_saved'})});
	}

	handleNewRoleNameChange(e) {
		this.setState({newRoleName: e.target.value});
	}

	handleRoleNameBlur(roleId, e) {
		const input = {
			id: roleId,
			name: e.target.value,
		};
		Util.CommitMutation(UpdateRoleMutation, input, () => this.showChangesSavedToast(), true);
	}

	handleKeyPressRole(index, e) {
		if (e.key === 'Enter') {
			this.roleInputs[index].blur();
		}
	}

	handleAddRole() {
		if (!this.state.newRoleName) return;
		const input = {
			companyId: this.props.viewer.company.id,
			name: this.state.newRoleName,
		};
		Util.CommitMutation(CreateRoleMutation, input, null, true);
		this.setState({newRoleName: ''});
	}

	handleDeleteRole(roleId) {
		// confirmation window
		const onSuccess = result => {
			if (
				result.deleteRole.errors &&
				result.deleteRole.errors.length === 1 &&
				result.deleteRole.errors[0] === 'TIME_REG_USED_WITH_ROLE'
			) {
				showModal({
					type: MODAL_TYPE.ROLE_USED_IN_TIME_REG,
				});
			} else {
				createToast({duration: 5000, message: this.props.intl.formatMessage({id: 'role.has-been-deleted'})});
			}
		};
		const callbackPositive = params => {
			const input = {
				companyId: this.props.viewer.company.id,
				id: roleId,
			};
			Util.CommitMutation(DeleteRoleMutation, input, onSuccess, true);
		};
		showModal({
			type: MODAL_TYPE.GENERIC_DELETION_WARNING,
			deleteCallback: callbackPositive,
			customMessageId: 'settings.delete_role_warning',
		});
	}

	render() {
		const {formatMessage} = this.props.intl;

		const roles = cloneDeep(this.props.viewer.company.roles.edges).sort((a, b) =>
			Util.sortAlphabetically(a.node.name, b.node.name)
		);
		for (const role of roles) {
			role.node.assigned = this.props.viewer.company.allPersons.edges.filter(person => {
				return person.node.active && person.node.role && person.node.role.id === role.node.id;
			}).length;
		}
		return (
			<div className="section-content settings-app settings-roles" data-cy={'settings-roles-page'}>
				{this.props.children}
				<SettingsResourcesSubNav viewer={this.props.viewer} />
				<CustomScrollDiv>
					<div className={'section-body'}>
						<div className="inner">
							<fieldset className="general">
								<p className="description">
									<FormattedMessage id="settings.roles-description" />
								</p>
								<table>
									<thead>
										<tr>
											<th>
												<FormattedMessage id="settings.role" />
											</th>
											<th>
												<FormattedMessage id="settings.role-assigned" />
											</th>
											<th>
												<FormattedMessage id="settings.role-assigned-timeregs" />
											</th>
											<th>&nbsp;</th>
										</tr>
									</thead>
									<tbody>
										{roles.map((role, index) => (
											<tr data-cy="role-row" key={role.node.id}>
												<td>
													<input
														data-cy="role-name"
														ref={input => (this.roleInputs[index] = input)}
														type="text"
														defaultValue={role.node.name}
														placeholder={formatMessage({id: 'settings.role-name'})}
														onBlur={this.handleRoleNameBlur.bind(this, role.node.id)}
														onKeyPress={e => this.handleKeyPressRole(index, e)}
														maxLength={190}
													/>
												</td>
												<td className="assigned">{role.node.assigned}</td>
												<td className="assigned-timeregs">{role.node.timeRegCount || 0}</td>
												<td className="controls" style={{color: '#6f49c5'}}>
													<span
														onClick={this.handleDeleteRole.bind(this, role.node.id)}
														data-cy="delete-role-button"
														className="remove"
													>
														{formatMessage({id: 'common.delete'})}
													</span>
												</td>
											</tr>
										))}
										<tr>
											<td>
												<input
													type="text"
													maxLength={190}
													data-cy="new-role-name"
													value={this.state.newRoleName}
													onChange={this.handleNewRoleNameChange.bind(this)}
													placeholder={formatMessage({id: 'settings.role-name'})}
												/>
											</td>
											<td>&nbsp;</td>
											<td>
												<button
													data-cy="add-role-button"
													data-userpilot={'add-role-button'}
													className="submit-button"
													onClick={this.handleAddRole.bind(this)}
												>
													{formatMessage({id: 'settings.role-add'})}
												</button>
											</td>
										</tr>
									</tbody>
								</table>
							</fieldset>
						</div>
					</div>
				</CustomScrollDiv>
			</div>
		);
	}
}

settingsRoles.propTypes = {};

const settingsRolesQuery = graphql`
	query settingsRoles_Query {
		viewer {
			actualPersonId
			component(name: "settings_roles")
			...settingsRoles_viewer
		}
	}
`;

graphql`
	fragment settingsRoles_role on Role {
		id
		name
		notifyProjectOverview
		timeRegCount
	}
`;

export {settingsRolesQuery};

export default injectIntl(
	createFragmentContainer(settingsRoles, {
		viewer: graphql`
			fragment settingsRoles_viewer on Viewer {
				...SettingsResourcesSubNav_viewer
				availableFeatureFlags {
					key
				}
				company {
					id
					allPersons(first: 10000) {
						edges {
							node {
								role {
									id
								}
								active
							}
						}
					}
					roles(first: 1000) @connection(key: "Company_roles", filters: []) {
						edges {
							node {
								name
								...settingsRoles_role @relay(mask: false)
							}
						}
					}
				}
			}
		`,
	})
);
