import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import GenericModal from '../../../../../../containers/modal/generic_modal';
import { asFragment } from '../../../../../../relay-helpers/TSRelayConverter';
import { notNullishAndPredicate } from '../../../../util/NotNullPredicate';
import OwnedProjectRow from './OwnedProjectRow';
const OwnedProjectsModal = ({ viewer, closeModal }) => {
    var _a, _b;
    const content = (_b = (_a = viewer.projects) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.filter(notNullishAndPredicate(project => { var _a; return !!((_a = project.node) === null || _a === void 0 ? void 0 : _a.userIsProjectOwner); })).map(project => {
        var _a;
        return React.createElement(OwnedProjectRow, { key: (_a = project.node) === null || _a === void 0 ? void 0 : _a.id, project: asFragment(project.node) });
    });
    return React.createElement(GenericModal, { closeModal: closeModal, headerText: 'Owned Projects', content: content });
};
const OwnedProjectsModalQuery = graphql `
	query OwnedProjectsModal_Query {
		viewer {
			actualPersonId
			component(name: "create_project_modal")
			...OwnedProjectsModal_viewer
		}
	}
`;
export { OwnedProjectsModalQuery };
export default createFragmentContainer(OwnedProjectsModal, {
    viewer: graphql `
		fragment OwnedProjectsModal_viewer on Viewer {
			projects(first: 1000000) @connection(key: "Viewer_projects", filters: []) {
				edges {
					node {
						id
						userIsProjectOwner
						...OwnedProjectRow_project
					}
				}
			}
		}
	`,
});
