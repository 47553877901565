import React from 'react';
import moment from 'moment';
import {FormattedMessage, injectIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import {withRouter} from 'react-router-dom';
import * as tracking from '../../../tracking';
import unit4Logo from '../../../images/integrations/unit4-logo.svg';
import UpdateCompanyMutation from '../../../mutations/update_company_mutation.modern';
import VerifyUnit4IntegrationMutation from '../../../mutations/verify_unit4_integration_mutation';
import CustomScrollDiv from '../../shared/components/scroll-bars/custom_scroll_div';
import Util from '../../shared/util/util';
import {MODAL_TYPE, showModal} from '../../shared/components/modals/generic_modal_conductor';
import {BUTTON_COLOR, BUTTON_STYLE, DATE_PICKER_STYLE, ELEMENT_TYPE} from '../../../constants';
import {buildHeaderBar} from '../../shared/components/headers/header-bar/header_bar';
import InlineLoader from '../../shared/components/inline-loader/inline_loader';
import {Button, Checkbox} from 'web-components';
import {TimeApprovalIncompatibleContent} from './shared/TimeApprovalIncompatibleContent';
import {usingTimeApproval} from '../../shared/util/FeatureUtil';
import DatePicker from '../../shared/components/date-picker/date_picker_v3';
import {trackPage, unregisterPageInfo} from '../../../tracking/amplitude/TrackingV2';

class appUnit4 extends React.Component {
	constructor(props) {
		super(props);
		// Set state
		const {company} = props.viewer;
		this.state = {
			unit4Domain: company.unit4Domain || '',
			unit4DomainSoap: company.unit4DomainSoap || '',
			unit4Client: company.unit4Client || '',
			unit4Username: company.unit4Username || '',
			verifying: false,
			twoWay: company.unit4TwoWay,
			unit4SyncDateLimit: company.unit4SyncDateLimit ? moment(company.unit4SyncDateLimit) : undefined,
		};

		this.superPropertyChecksum = trackPage('App Catalog - Unit4');
	}

	componentDidMount() {
		document.title = 'Unit4 - Apps & Integrations - Forecast';
		// Segment
		tracking.trackPage('settings-app-catalog-unit4');
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	toggleIntegration(arg) {
		if (arg) {
			// Enable
			Util.CommitMutation(UpdateCompanyMutation, {
				id: this.props.viewer.company.id,
				unit4Enabled: arg,
			});
		} else {
			const deactivateForReal = () => {
				Util.CommitMutation(UpdateCompanyMutation, {
					id: this.props.viewer.company.id,
					unit4Enabled: arg,
				});
			};

			showModal({
				type: MODAL_TYPE.WARNING,
				warningMessageId: 'settings.unlink_jira_warning_title',
				warningInformation: [<FormattedMessage id="integration.disable_unit4_integration" />],
				buttons: [
					{
						text: <FormattedMessage id="common.cancel" />,
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: <FormattedMessage id="integration.disable" />,
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.RED,
						callback: deactivateForReal,
					},
				],
			});
		}
	}

	handleSyncDateLimitChange(date) {
		Util.CommitMutation(UpdateCompanyMutation, {
			id: this.props.viewer.company.id,
			unit4SyncDateLimit: date?.format('YYYY-MM-DD') || '',
		});
		this.setState({unit4SyncDateLimit: date});
	}
	handleClearSyncDateLimit() {
		this.handleSyncDateLimitChange(null);
	}

	toggleTwoWay() {
		Util.CommitMutation(UpdateCompanyMutation, {
			id: this.props.viewer.company.id,
			unit4TwoWay: !this.state.twoWay,
		});
		this.setState({twoWay: !this.state.twoWay});
	}

	handleChange(property, e) {
		this.setState({[property]: e.target.value});
	}

	authenticate() {
		const {formatMessage} = this.props.intl;
		this.setState({verifying: true});

		const onSuccess = response => {
			if (response.verifyUnit4Integration.success) {
				// If success show success message to user
				showModal({
					type: MODAL_TYPE.WARNING,
					warningMessageId: 'integration.verification_success',
					warningInformation: [formatMessage({id: 'integration.verification_succeeded'})],
					useInfoIcon: true,
					buttons: [
						{
							text: formatMessage({id: 'common.ok'}),
							style: BUTTON_STYLE.FILLED,
							color: BUTTON_COLOR.WHITE,
						},
					],
				});
			} else {
				// If failure, show error message to user
				showModal({
					type: MODAL_TYPE.WARNING,
					warningMessageId: 'integration.verification_failed',
					warningInformation: [response.verifyUnit4Integration.message],
					useInfoIcon: true,
					buttons: [
						{
							text: formatMessage({id: 'common.ok'}),
							style: BUTTON_STYLE.FILLED,
							color: BUTTON_COLOR.WHITE,
						},
					],
				});
			}
			this.setState({verifying: false});
		};

		// Contact Unit4 to validate credentials
		Util.CommitMutation(
			VerifyUnit4IntegrationMutation,
			{
				unit4Domain: this.state.unit4Domain,
				unit4DomainSoap: this.state.unit4DomainSoap,
				unit4Client: this.state.unit4Client,
				unit4Username: this.state.unit4Username,
				unit4Password: this._unit4_password_ref.value,
			},
			onSuccess
		);
	}

	getHeader() {
		const leftContent = [],
			rightContent = [];

		leftContent.push({type: ELEMENT_TYPE.BACK_BUTTON, onClick: this.props.history.goBack});

		return buildHeaderBar(leftContent, rightContent);
	}

	render() {
		const {formatMessage} = this.props.intl;

		const useTimeApproval = usingTimeApproval(this.props.viewer.company.useTimeApproval);

		return (
			<div className="section-content settings-app settings-app-page settings-app-unit4">
				{this.props.children}
				{this.getHeader()}
				<CustomScrollDiv>
					<div className="section-body">
						<div className="inner">
							<fieldset className="general">
								<div className="header-wrapper">
									<img width={100} height={100} alt="unit4 logo" src={unit4Logo} />
									<div className="title-description-wrapper">
										<div className="description">
											<FormattedMessage id="settings.app_catalog.unit4.description" />
										</div>
									</div>
								</div>
								<div className="content-wrapper"></div>
							</fieldset>
							<fieldset className="general">
								{this.props.viewer.availableFeatureFlags.find(flag => flag.key === 'use_unit4') ? (
									useTimeApproval ? (
										<TimeApprovalIncompatibleContent integrationName={'Unit4'} />
									) : (
										<div className="input-fields">
											{this.props.viewer.company.unit4Enabled ? (
												<div>
													<div className="authentication-description">
														<FormattedMessage id="settings.app_catalog.unit4.authentication.description" />
													</div>
													<form autoComplete="off">
														<label htmlFor="unit4_domain">
															<FormattedMessage id="settings.app_catalog.unit4.domain" />
															<input
																autoComplete="off"
																id="unit4_domain"
																type="text"
																placeholder={formatMessage({
																	id: 'settings.app_catalog.unit4.domain.placeholder',
																})}
																value={this.state.unit4Domain}
																onChange={this.handleChange.bind(this, 'unit4Domain')}
															/>
														</label>
														<label htmlFor="unit4_domain_soap">
															<FormattedMessage id="settings.app_catalog.unit4.domain.soap" />
															<input
																autoComplete="off"
																id="unit4_domain_soap"
																type="text"
																placeholder={formatMessage({
																	id: 'settings.app_catalog.unit4.domain.placeholder',
																})}
																value={this.state.unit4DomainSoap}
																onChange={this.handleChange.bind(this, 'unit4DomainSoap')}
															/>
														</label>
														<label htmlFor="unit4_client">
															<FormattedMessage id="settings.app_catalog.unit4.client" />
															<input
																autoComplete="off"
																id="unit4_client"
																type="text"
																placeholder={formatMessage({
																	id: 'settings.app_catalog.unit4.client.placeholder',
																})}
																value={this.state.unit4Client}
																onChange={this.handleChange.bind(this, 'unit4Client')}
															/>
														</label>
														<label htmlFor="unit4_username">
															<FormattedMessage id="settings.app_catalog.unit4.username" />
															<input
																autoComplete="off"
																id="unit4_username"
																type="text"
																placeholder={formatMessage({
																	id: 'settings.app_catalog.unit4.username.placeholder',
																})}
																value={this.state.unit4Username}
																onChange={this.handleChange.bind(this, 'unit4Username')}
															/>
														</label>
														<label htmlFor="unit4_password">
															<FormattedMessage id="settings.app_catalog.unit4.password" />
															<input
																autoComplete="new-password"
																id="unit4_password"
																type="password"
																placeholder={formatMessage({
																	id: 'settings.app_catalog.unit4.password.placeholder',
																})}
																ref={input => (this._unit4_password_ref = input)}
															/>
														</label>
														<label htmlFor="unit4-two-way">
															<FormattedMessage id="settings.app_catalog.unit4.twoway" />
															<Checkbox
																id="unit4-two-way"
																checked={this.state.twoWay}
																onClick={this.toggleTwoWay.bind(this)}
															/>
														</label>
														<label htmlFor="unit4-sync-date-limit">
															<FormattedMessage id="settings.app_catalog.unit4.sync_limit" />
															<DatePicker
																startDate={this.state.unit4SyncDateLimit}
																handleDateRangeChange={this.handleSyncDateLimitChange.bind(
																	this
																)}
																clearBothDates={this.handleClearSyncDateLimit.bind(this)}
																datePickerStyle={DATE_PICKER_STYLE.SLIM}
																isSingleDatePicker
																clearable
																hideLimitMessage
																startDateEmptyText="Not set"
															/>
														</label>
													</form>
													<div className="authenticate-button-container">
														<Button
															onClick={this.authenticate.bind(this, true)}
															disabled={this.state.verifying}
															variant={Button.VARIANT.PURPLE_FILLED}
														>
															<FormattedMessage id="common.authenticate" />
														</Button>
														{this.state.verifying ? <InlineLoader /> : null}
													</div>
													<div className="button-container">
														<Button
															onClick={this.toggleIntegration.bind(this, false)}
															variant={Button.VARIANT.RED_FILLED}
														>
															<FormattedMessage id="integration.disable" />
														</Button>
													</div>
												</div>
											) : (
												<Button
													onClick={this.toggleIntegration.bind(this, true)}
													variant={Button.VARIANT.PURPLE_FILLED}
												>
													<FormattedMessage id="integration.enable" />
												</Button>
											)}
										</div>
									)
								) : (
									<div>
										The Unit4 integration is still under development. If you are interested in using the
										Unit4 integration, please contact{' '}
										<a href="mailto:success@forecast.it">success@forecast.it</a>
									</div>
								)}
							</fieldset>
						</div>
					</div>
				</CustomScrollDiv>
			</div>
		);
	}
}

const appUnit4Query = graphql`
	query appUnit4_Query {
		viewer {
			actualPersonId
			component(name: "app_unit4")
			...appUnit4_viewer
		}
	}
`;

export {appUnit4Query};

export default createFragmentContainer(injectIntl(withRouter(appUnit4)), {
	viewer: graphql`
		fragment appUnit4_viewer on Viewer {
			id
			availableFeatureFlags {
				key
			}
			company {
				id
				useTimeApproval
				modules {
					moduleType
				}
				unit4Enabled
				unit4Username
				unit4Domain
				unit4DomainSoap
				unit4Client
				unit4TwoWay
				unit4SyncDateLimit
			}
		}
	`,
});
