import React, {useEffect, useRef, useState} from 'react';
import {createRefetchContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import {GLOBAL_FILTER_FIELD, GLOBAL_FILTER_OPERATOR} from '../../../../../constants';
import {formatTaskOptions} from './add_time_entry_data_format';
import {withSocketHandling} from '../../../../../socket/withSocketHandling';
import {
	isDoneTaskTimeRegistrationAllowed,
	isTimeRegistrationTaskAssignmentRequired,
} from '../../../util/cache/TimeRegistrationSettingsUtil';
import {getSocketConfig} from './task_dropdown_socket_config';

import NestedDropdown from '../nested_dropdown';

const taskDropdownRelayWrapper = ({
	intl,
	relay,
	viewer,
	setSocketConfig,
	setSocketVariables,
	pageSize,
	overBudgetProgramsByCompanyProjectId,
	selectedProject,
	searchValue,
	closeOnScroll,
	placeholder,
	expandedPlaceholder,
	externalSearch,
	value,
	onChange,
	isHarvestUser,
	projectIdLabel,
	userpilot,
	disabled,
	personId,
}) => {
	const [modalTasksFetching, setModalTasksFetching] = useState(false);
	const [initialModalTasksFetching, setInitialModalTasksFetching] = useState(true);

	const [_searchQuery, setSearchQuery] = useState({filters: []});

	const didMount = useRef(false);

	useEffect(() => {
		setSocketConfig(getSocketConfig(), relay.refetch);
	}, []);

	const refetch = (variables, renderVariables, callback, options) => {
		relay.refetch(variables, renderVariables, callback, options);
		setSocketVariables(variables);
	};

	const taskOptions = formatTaskOptions(viewer.company.modalTasks, intl.formatMessage, overBudgetProgramsByCompanyProjectId);

	const fetchTasks = (searchValue, project) => {
		const searchQuery = {filters: []};
		const filterValues = [];
		setInitialModalTasksFetching(true);
		const onSuccess = () => {
			setInitialModalTasksFetching(false);
		};

		if (project || searchValue) {
			if (project) {
				const formattedProjectFilter = {
					field: GLOBAL_FILTER_FIELD.PROJECT,
					operator: GLOBAL_FILTER_OPERATOR.IS,
					value: [project.value || project.id],
				};
				filterValues.push(formattedProjectFilter);
			}

			if (searchValue && searchValue !== '') {
				const queryNameSearch = {
					operator: GLOBAL_FILTER_OPERATOR.CONTAINS,
					field: GLOBAL_FILTER_FIELD.NAME,
					value: [searchValue],
				};
				filterValues.push(queryNameSearch);
			}
		}

		if (!isDoneTaskTimeRegistrationAllowed()) {
			const statusFilter = {
				field: GLOBAL_FILTER_FIELD.STATUS,
				operator: GLOBAL_FILTER_OPERATOR.IS,
				value: ['TODO', 'INPROGRESS'],
			};
			filterValues.push(statusFilter);
		}

		if (isTimeRegistrationTaskAssignmentRequired()) {
			const personFilter = {
				field: GLOBAL_FILTER_FIELD.ASSIGNED,
				operator: GLOBAL_FILTER_OPERATOR.IN,
				value: [personId],
			};
			filterValues.push(personFilter);
		}

		filterValues.push({
			field: GLOBAL_FILTER_FIELD.PROJECT_MANUAL_PROGRESS,
			operator: GLOBAL_FILTER_OPERATOR.IN,
			value: ['false'],
		});

		searchQuery.filters = filterValues;

		setSearchQuery(searchQuery);

		refetch(
			{
				pageSize: pageSize,
				modalSearchQuery: searchQuery,
				personId: personId,
			},
			null,
			onSuccess
		);
	};

	const fetchMore = () => {
		const modalTasks = viewer.company.modalTasks;

		if (modalTasks && modalTasks.pageInfo.hasNextPage) {
			const newLength = modalTasks.edges.length + pageSize;
			setModalTasksFetching(true);
			refetch(
				{
					pageSize: newLength,
					modalSearchQuery: _searchQuery,
					personId: personId,
				},
				null,
				error => setModalTasksFetching(false)
			);
		}
	};

	const handleScroll = e => {
		if (!modalTasksFetching && e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 100) {
			fetchMore();
		}
	};

	// Fetch tasks if either searchValue or project id changes, but not on mount
	useEffect(() => {
		if (didMount.current) {
			fetchTasks(searchValue, selectedProject);
		} else {
			didMount.current = true;
		}
	}, [searchValue, selectedProject?.id]);

	return (
		<NestedDropdown
			id={'time-task-select-dropdown'}
			useWhiteColor={false}
			cy={'task-add-time'}
			placeholder={placeholder}
			name={placeholder}
			expandedPlaceholder={expandedPlaceholder}
			showPlaceHolderWhenExpanded={true}
			externalSearch={externalSearch}
			label={''}
			options={taskOptions}
			value={value}
			onChange={onChange}
			showArrowWhenCollapsed={true}
			hideOptionsCounter={true}
			lazyLoadDropdown={true}
			handleScroll={handleScroll}
			isHarvestUser={isHarvestUser}
			disablePreselectedOptionRemove={true}
			clearOnInput={!disabled}
			portalDropdown={true}
			closeOnScroll={closeOnScroll}
			projectIdLabel={projectIdLabel}
			userpilot={userpilot}
			disabled={disabled}
			refetch={() => fetchTasks(searchValue, selectedProject)}
			contentLoading={initialModalTasksFetching}
		/>
	);
};

const taskDropdownRelayWrapperQuery = graphql`
	query taskDropdownRelayWrapper_Query(
		$pageSize: Int
		$cursor: String
		$modalSearchQuery: TaskSearchQueryType
		$sortValue: String
		$personId: ID
	) {
		viewer {
			actualPersonId
			component(name: "task_dropdown")
			...taskDropdownRelayWrapper_viewer
				@arguments(
					pageSize: $pageSize
					cursor: $cursor
					modalSearchQuery: $modalSearchQuery
					sortValue: $sortValue
					personId: $personId
				)
		}
	}
`;

export {taskDropdownRelayWrapperQuery};

export default withSocketHandling(
	injectIntl(
		createRefetchContainer(
			taskDropdownRelayWrapper,
			{
				viewer: graphql`
					fragment taskDropdownRelayWrapper_viewer on Viewer
					@argumentDefinitions(
						pageSize: {type: "Int"}
						cursor: {type: "String"}
						modalSearchQuery: {type: "TaskSearchQueryType"}
						sortValue: {type: "String"}
						personId: {type: "ID"}
					) {
						id
						actualPersonId
						company {
							id
							modalTasks: allTasks(
								first: $pageSize
								after: $cursor
								searchQuery: $modalSearchQuery
								sortValue: $sortValue
								simpleResponse: true
								approvedOnly: true
								runningProjectOnly: true
								excludeReadOnly: true
							) @connection(key: "Company_modalTasks", filters: []) {
								pageInfo {
									endCursor
									hasNextPage
								}
								edges {
									node {
										id
										name
										companyTaskId
										timeLeft
										estimateForecastMinutes
										totalMinutesRegistered
										parentTaskId
										billable
										startYear
										startMonth
										startDay
										deadlineDay
										deadlineMonth
										deadlineYear
										role {
											id
										}
										project {
											id
											name
											companyProjectId
											customProjectId
											status
											projectColor
											billable
											projectPerson(personId: $personId) {
												role {
													id
													name
												}
											}
											harvestProject {
												id
											}
											unit4Project {
												id
												activitiesEnabled
											}
											projectStartYear
											projectStartMonth
											projectStartDay
											projectEndYear
											projectEndMonth
											projectEndDay
										}
									}
									cursor
								}
							}
						}
					}
				`,
			},
			graphql`
				query taskDropdownRelayWrapperRefetchQuery(
					$pageSize: Int
					$cursor: String
					$modalSearchQuery: TaskSearchQueryType
					$sortValue: String
				) {
					viewer {
						component(name: "task_dropdown")
						...taskDropdownRelayWrapper_viewer
							@arguments(
								pageSize: $pageSize
								cursor: $cursor
								modalSearchQuery: $modalSearchQuery
								sortValue: $sortValue
							)
					}
				}
			`
		)
	)
);
