/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TimeRegistrationReportPage_viewer$ref = any;
export type TimeRegistrationReportPage_QueryVariables = {|
  savedReportId?: ?string,
  sharedId?: ?string,
|};
export type TimeRegistrationReportPage_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: TimeRegistrationReportPage_viewer$ref,
  |}
|};
export type TimeRegistrationReportPage_Query = {|
  variables: TimeRegistrationReportPage_QueryVariables,
  response: TimeRegistrationReportPage_QueryResponse,
|};
*/


/*
query TimeRegistrationReportPage_Query(
  $savedReportId: String
  $sharedId: String
) {
  viewer {
    actualPersonId
    component(name: "reported_time_page_v2")
    ...TimeRegistrationReportPage_viewer_1Xkajk
    id
  }
}

fragment TimeRegistrationReportPage_viewer_1Xkajk on Viewer {
  actualPersonId
  id
  email
  company {
    timeRegistrationSettings {
      allowBillableSplit
      id
    }
    id
  }
  filters(first: 10000, filterSection: REPORTED_TIME_TABLE) {
    edges {
      node {
        id
        name
        section
        value
        updatedAt
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  reportData(id: $savedReportId, sharedId: $sharedId) {
    company {
      currency
      reportCustomFieldDefinitions {
        id
        key
        entityType
        displayName
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "savedReportId",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sharedId",
    "type": "String"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "reported_time_page_v2"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"reported_time_page_v2\")"
},
v3 = {
  "kind": "Variable",
  "name": "sharedId",
  "variableName": "sharedId"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "filterSection",
    "value": "REPORTED_TIME_TABLE"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TimeRegistrationReportPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "args": [
              {
                "kind": "Variable",
                "name": "savedReportId",
                "variableName": "savedReportId"
              },
              (v3/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "TimeRegistrationReportPage_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TimeRegistrationReportPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TimeRegistrationSettingsType",
                "kind": "LinkedField",
                "name": "timeRegistrationSettings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "allowBillableSplit",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "FilterTypeConnection",
            "kind": "LinkedField",
            "name": "filters",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FilterTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Filter",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "section",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "updatedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "filters(filterSection:\"REPORTED_TIME_TABLE\",first:10000)"
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "filters": [],
            "handle": "connection",
            "key": "Viewer_filters",
            "kind": "LinkedHandle",
            "name": "filters"
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "savedReportId"
              },
              (v3/*: any*/)
            ],
            "concreteType": "ReportData",
            "kind": "LinkedField",
            "name": "reportData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ReportCompany",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currency",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReportCustomFieldDefinition",
                    "kind": "LinkedField",
                    "name": "reportCustomFieldDefinitions",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "key",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "entityType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "displayName",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "TimeRegistrationReportPage_Query",
    "operationKind": "query",
    "text": "query TimeRegistrationReportPage_Query(\n  $savedReportId: String\n  $sharedId: String\n) {\n  viewer {\n    actualPersonId\n    component(name: \"reported_time_page_v2\")\n    ...TimeRegistrationReportPage_viewer_1Xkajk\n    id\n  }\n}\n\nfragment TimeRegistrationReportPage_viewer_1Xkajk on Viewer {\n  actualPersonId\n  id\n  email\n  company {\n    timeRegistrationSettings {\n      allowBillableSplit\n      id\n    }\n    id\n  }\n  filters(first: 10000, filterSection: REPORTED_TIME_TABLE) {\n    edges {\n      node {\n        id\n        name\n        section\n        value\n        updatedAt\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  reportData(id: $savedReportId, sharedId: $sharedId) {\n    company {\n      currency\n      reportCustomFieldDefinitions {\n        id\n        key\n        entityType\n        displayName\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '13397a904bea1b672a99578009d7dbd8';

module.exports = node;
