export const TIME_REG_TYPES = {
	TASK_TIME: 'taskTime',
	PROJECT_TIME: 'projectTime',
	IDLE_TIME: 'idleTime',
	NO_ACCESS: 'noAccess',
};

export const COLUMN_NAME = {
	PROJECT_INDICATOR: 'project-indicator',
	TASK_NAME: 'task-name',
	PROJECT_NAME: 'project-name',
	PHASE: 'phase',
	CLIENT: 'client',
	REMAINING: 'remaining',
	HOURS_INPUT: 'hour-inputs',
	NOTES: 'notes',
	STAR: 'star',
};

export const buildWeekArr = (currentDate, limit) => {
	const weekArray = [];
	const rec = (date, acc, limit) => {
		if (limit > 0) {
			acc.push(date);
			rec(date.clone().subtract(1, 'days'), acc, limit - 1);
		}
		return acc;
	};
	return rec(currentDate, weekArray, limit).reverse();
};
