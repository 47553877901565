import React, {useState} from 'react';
import styled from 'styled-components';
import {Icon} from '@forecast-it/design-system';

const ChatButton = styled.button`
	z-index: 1000000;
	position: absolute;
	bottom: 24px;
	right: 24px;

	width: 44px;
	height: 44px;

	padding: 0;
	border: none;

	display: flex;
	align-items: center;
	justify-content: center;
`;

const ChatIconCircle = styled.div`
	border-radius: 50%;
	width: 40px;
	height: 40px;

	border: 2px solid #650ed8;
	background: #ffffff;

	display: flex;
	align-items: center;
	justify-content: center;
`;

const ChatIcon = () => {
	// state for opened
	const [opened, setOpened] = useState(false);

	function toggleChatWindow() {
		if (process.env.CIRCLE_BRANCH !== 'production') return;

		if (opened) {
			window.zE('messenger', 'close');
			setOpened(false);
		} else {
			window.zE('messenger', 'open');
			setOpened(true);
		}
	}

	return (
		<ChatButton onClick={toggleChatWindow}>
			<ChatIconCircle>
				<Icon icon="feedback" color="primary" size="l" />
			</ChatIconCircle>
		</ChatButton>
	);
};

export default ChatIcon;
