import {cloneDeep} from 'lodash';
import Util from '../../shared/util/util';
import {CUSTOM_FIELD_PREFIX} from '../../project-tab/projects/scoping-page/ProjectScopingUtil';

const theEyeOptions = customFieldDefinitions => {
	let options = [
		{
			name: 'project-indicator',
			checked: true,
			translationId: 'overview_time.card_details_selector_id',
			nestedOptions: null,
		},
		{name: 'project-name', checked: true, translationId: 'project_section.project_name', nestedOptions: null},
		{name: 'phase', checked: true, translationId: 'common.phase', nestedOptions: null},
		{name: 'client', checked: false, translationId: 'common.client', nestedOptions: null},
		{name: 'remaining', checked: false, translationId: 'common.remaining', nestedOptions: null},
	];
	if (customFieldDefinitions) {
		customFieldDefinitions
			.filter(customFieldDefinition => customFieldDefinition.entityType === 'TIME_REG')
			.forEach(customFieldDefinition => {
				options.push({
					name: CUSTOM_FIELD_PREFIX + customFieldDefinition.key,
					checked: true,
					displayName: customFieldDefinition.displayName,
					customFieldKey: customFieldDefinition.key,
					readOnly: customFieldDefinition.readOnly,
				});
			});
	}
	return options;
};

const optionVisitor = (option, callback, parentPath) => {
	const path = parentPath ? `${parentPath}.${option.name}` : option.name;

	callback(option, path);
	if (option.nestedOptions) {
		option.nestedOptions.forEach(nestedOption => {
			optionVisitor(nestedOption, callback, path);
		});
	}
};

const optionTraverser = (options, callback) => {
	options.forEach(option => {
		optionVisitor(option, callback, '');
	});
};

export const getInitialOptions = customFieldDefinitions => {
	const options = cloneDeep(theEyeOptions(customFieldDefinitions));

	const locallySavedOptionsString = localStorage.getItem('timesheet-options');
	if (locallySavedOptionsString) {
		const savedOptionsValues = JSON.parse(locallySavedOptionsString);
		if (typeof savedOptionsValues === 'object' && !Array.isArray(savedOptionsValues)) {
			optionTraverser(options, (option, path) => {
				if (savedOptionsValues[path] !== undefined) {
					option.checked = savedOptionsValues[path];
				}
			});
		}
	}

	return options;
};

export const handleChangedOptions = changedOptions => {
	const savedOptionValues = {};
	optionTraverser(changedOptions, (option, path) => {
		savedOptionValues[path] = option.checked;
	});

	Util.localStorageSetItem('timesheet-options', JSON.stringify(savedOptionValues));
	return changedOptions;
};

export const enabledColumns = () => {};
