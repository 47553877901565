import React from 'react';
import {useIntl} from 'react-intl';

export const TaskModalRemainingPlaceholder = () => {
	const intl = useIntl();

	return (
		<div className={'header-tile remaining-tile'}>
			<div className="tile-wrapper">
				<div className="tile-container">
					<div className="tile-label">{intl.formatMessage({id: 'common.remaining'})}</div>
					<div className="text-container">
						<div className={`number-text`}>-</div>
					</div>
				</div>
			</div>
		</div>
	);
};
