/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "TimeRegistrationModalIntegrations_project",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "HarvestProjectType",
                "kind": "LinkedField",
                "name": "harvestProject",
                "plural": false,
                "selections": [
                    (v0 /*: any*/)
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "Unit4ProjectType",
                "kind": "LinkedField",
                "name": "unit4Project",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "activitiesEnabled",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "ProjectType"
    };
})();
node.hash = 'bf9af33d9c8e250adece647bc14c639d';
export default node;
