import DataExportFormatter from './DataExportFormatter';
import WorkFormatter from './WorkFormatter';
import ExpenseFormatter from './ExpenseFormatter';
import {idFromGlobalId} from '../GlobalIdUtil';

export default class PhaseFormatter extends DataExportFormatter {
	#workFormatter;
	#expenseFormatter;
	#phases;

	constructor(intl) {
		super(intl, ['phase_name', 'item_type', 'item_name'], [], false);

		this.#workFormatter = new WorkFormatter(intl);
		this.#expenseFormatter = new ExpenseFormatter(intl);
	}

	_storeGeneralData(generalData) {
		this.#workFormatter._storeGeneralData(generalData);
		this.#expenseFormatter._storeGeneralData(generalData);
		this.#phases = new Map();
		generalData.phases.forEach(phase => {
			this.#phases.set(idFromGlobalId(phase.node.id), phase.node);
		});
	}

	_columnFormatHook(name) {
		const workColumn = this.#workFormatter._columnFormatHook(name);
		if (workColumn) {
			return workColumn;
		}

		const expenseColumn = this.#expenseFormatter._columnFormatHook(name);
		if (expenseColumn) {
			return expenseColumn;
		}

		switch (name) {
			case 'dates':
				return ['phase_start_date', 'phase_deadline_date'];
			default:
				return null;
		}
	}

	_formatRow(columns, rowData) {
		const commonFieldsObject = this._getCommonFieldsObject(columns, rowData);

		const columnsWithoutCommon = columns.filter(
			name => !['phase_name', 'phase_start_date', 'phase_deadline_date'].includes(name)
		);

		const workItems = Array.from(rowData.roles.values()).map(work => {
			const formattedWorkItem = this.#workFormatter._formatRow(columnsWithoutCommon, work);

			return {...commonFieldsObject, ...formattedWorkItem};
		});

		const expenseItems = Array.from(rowData.expenses.values()).map(expense => {
			const formattedExpenseItem = this.#expenseFormatter._formatRow(columnsWithoutCommon, expense);

			return {...commonFieldsObject, ...formattedExpenseItem};
		});

		return workItems.concat(expenseItems);
	}

	_getCommonFieldsObject(columns, rowData) {
		const obj = {};

		const phase = this.#phases.get(rowData.phaseId);

		if (columns.includes('phase_name')) {
			obj['phase_name'] = this._formatNamedObject(phase, '');
		}

		if (columns.includes('phase_start_date')) {
			obj['phase_start_date'] = this._formatDate(
				phase.baselineStartDay,
				phase.baselineStartMonth,
				phase.baselineStartYear
			);
		}

		if (columns.includes('phase_deadline_date')) {
			obj['phase_deadline_date'] = this._formatDate(
				phase.baselineDeadlineDay,
				phase.baselineDeadlineMonth,
				phase.baselineDeadlineYear
			);
		}

		return obj;
	}
}
