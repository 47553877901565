import React, { useMemo, useState } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { FlexColumn, FlexRow, Label, Modal, Text } from '@forecast-it/design-system';
import { useIntl } from 'react-intl';
import CreateProjectBillToMutation from '../../../../mutations/invoicing/CreateProjectBillToMutation';
import Util from '../../../shared/util/util';
import ConditionalDropdown from '../../../../components/dropdowns/conditional-dropdown/ConditionalDropdown';
import InvoicingIntegrationIcon from '../ts/InvocingIntegrationIcon';
const getKeyFromIds = (billFromId, billToId) => {
    return billFromId + '-' + billToId;
};
const AddProjectBillToModal = ({ projectId, closeModal, viewer }) => {
    var _a;
    const { formatMessage } = useIntl();
    const clientBillTos = useMemo(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        const projectBillToKeys = new Set();
        for (const projectBillTo of ((_b = (_a = viewer === null || viewer === void 0 ? void 0 : viewer.project) === null || _a === void 0 ? void 0 : _a.projectBillTos) === null || _b === void 0 ? void 0 : _b.edges) || []) {
            projectBillToKeys.add(getKeyFromIds(((_d = (_c = projectBillTo === null || projectBillTo === void 0 ? void 0 : projectBillTo.node) === null || _c === void 0 ? void 0 : _c.billFrom) === null || _d === void 0 ? void 0 : _d.id) || '', ((_f = (_e = projectBillTo === null || projectBillTo === void 0 ? void 0 : projectBillTo.node) === null || _e === void 0 ? void 0 : _e.billTo) === null || _f === void 0 ? void 0 : _f.id) || ''));
        }
        return (((_k = (_j = (_h = (_g = viewer === null || viewer === void 0 ? void 0 : viewer.project) === null || _g === void 0 ? void 0 : _g.client) === null || _h === void 0 ? void 0 : _h.clientBillTos) === null || _j === void 0 ? void 0 : _j.edges) === null || _k === void 0 ? void 0 : _k.map(clientBillTo => clientBillTo === null || clientBillTo === void 0 ? void 0 : clientBillTo.node).filter(clientBillTo => { var _a, _b; return !projectBillToKeys.has(getKeyFromIds(((_a = clientBillTo === null || clientBillTo === void 0 ? void 0 : clientBillTo.billFrom) === null || _a === void 0 ? void 0 : _a.id) || '', ((_b = clientBillTo === null || clientBillTo === void 0 ? void 0 : clientBillTo.billTo) === null || _b === void 0 ? void 0 : _b.id) || '')); })) || []);
    }, [viewer]);
    const billFromOptions = useMemo(() => {
        var _a, _b, _c, _d, _e;
        const billFromKeys = new Set();
        for (const clientBillTo of clientBillTos) {
            billFromKeys.add((_a = clientBillTo === null || clientBillTo === void 0 ? void 0 : clientBillTo.billFrom) === null || _a === void 0 ? void 0 : _a.id);
        }
        const billFroms = (((_e = (_d = (_c = (_b = viewer === null || viewer === void 0 ? void 0 : viewer.project) === null || _b === void 0 ? void 0 : _b.client) === null || _c === void 0 ? void 0 : _c.clientBillTos) === null || _d === void 0 ? void 0 : _d.edges) === null || _e === void 0 ? void 0 : _e.map(clientBillTo => { var _a; return (_a = clientBillTo === null || clientBillTo === void 0 ? void 0 : clientBillTo.node) === null || _a === void 0 ? void 0 : _a.billFrom; }).filter(billFrom => billFrom && billFromKeys.has(billFrom.id))) || []);
        const billFromOptions = [];
        const billFromIds = new Set();
        for (const billFrom of billFroms) {
            if (!billFrom)
                continue;
            if (!billFromIds.has(billFrom === null || billFrom === void 0 ? void 0 : billFrom.id)) {
                billFromOptions.push(billFrom);
                billFromIds.add(billFrom.id);
            }
        }
        return billFromOptions;
    }, [viewer, clientBillTos]);
    const [selectedBillFrom, setSelectedBillFrom] = useState();
    const [selectedClientBillTo, setSelectedClientBillTo] = useState();
    const clientBillToOptions = useMemo(() => selectedBillFrom ? clientBillTos.filter(clientBillTo => { var _a; return ((_a = clientBillTo === null || clientBillTo === void 0 ? void 0 : clientBillTo.billFrom) === null || _a === void 0 ? void 0 : _a.id) === (selectedBillFrom === null || selectedBillFrom === void 0 ? void 0 : selectedBillFrom.id); }) : [], [selectedBillFrom, clientBillTos]);
    const addProjectBillTo = () => {
        const onSuccess = () => {
            closeModal();
        };
        if (selectedClientBillTo) {
            Util.CommitMutation(CreateProjectBillToMutation, {
                billToId: selectedClientBillTo.billTo.id,
                clientBillToId: selectedClientBillTo.id,
                clientId: selectedClientBillTo.clientId,
                projectId: projectId,
                billFromId: selectedClientBillTo.billFrom.id,
            }, onSuccess);
        }
    };
    const BillFromItem = ({ billFrom }) => (React.createElement(FlexRow, { gap: 's' },
        React.createElement(InvoicingIntegrationIcon, { integration: billFrom === null || billFrom === void 0 ? void 0 : billFrom.integration }),
        React.createElement(Text, null, billFrom === null || billFrom === void 0 ? void 0 : billFrom.name)));
    return (React.createElement(Modal, { onCloseModal: closeModal },
        React.createElement(Modal.Title, null, formatMessage({ id: 'project_settings.add_billing_details' })),
        React.createElement(Modal.Content, null,
            React.createElement(FlexColumn, { gap: "xl" },
                React.createElement(FlexColumn, { gap: "xs" },
                    React.createElement(Label, null, formatMessage({ id: 'common.bill_from' })),
                    React.createElement(ConditionalDropdown, { options: billFromOptions, renderOption: (billFrom) => (React.createElement(FlexRow, null,
                            React.createElement(BillFromItem, { billFrom: billFrom }))), onSelect: setSelectedBillFrom },
                        React.createElement(FlexRow, null,
                            React.createElement(BillFromItem, { billFrom: selectedBillFrom })))),
                React.createElement(FlexColumn, { gap: "xs" },
                    React.createElement(Label, null, formatMessage({ id: 'common.bill_to' })),
                    React.createElement(ConditionalDropdown, { options: clientBillToOptions, renderOption: (item) => (React.createElement(FlexRow, null,
                            React.createElement(Text, null, item.billTo.name))), onSelect: setSelectedClientBillTo },
                        React.createElement(FlexRow, null,
                            React.createElement(Text, null, ((_a = selectedClientBillTo === null || selectedClientBillTo === void 0 ? void 0 : selectedClientBillTo.billTo) === null || _a === void 0 ? void 0 : _a.name) || '')))))),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { onPress: addProjectBillTo }, formatMessage({ id: 'common.confirm' })),
            React.createElement(Modal.SecondaryFooterButton, { onPress: closeModal }, formatMessage({ id: 'common.close' })))));
};
export const AddProjectBillToModalQuery = graphql `
	query AddProjectBillToModalQuery($projectId: ID) {
		viewer {
			actualPersonId
			component(name: "AddProjectBillToModal")
			...AddProjectBillToModal_viewer @arguments(id: $projectId)
		}
	}
`;
export default createFragmentContainer(AddProjectBillToModal, {
    viewer: graphql `
		fragment AddProjectBillToModal_viewer on Viewer @argumentDefinitions(id: {type: "ID!"}) {
			id
			project(internalId: $id) {
				id
				projectBillTos(first: 10000) {
					edges {
						node {
							id
							billTo {
								id
							}
							billFrom {
								id
							}
						}
					}
				}
				client {
					clientBillTos(first: 10000) {
						edges {
							node {
								id
								clientId
								billTo {
									id
									name
									billFromId
								}
								billFrom {
									id
									name
									integration
								}
							}
						}
					}
				}
			}
			company {
				billFroms {
					id
					name
				}
			}
		}
	`,
});
