import {sanitize} from 'dompurify';
import parse from 'html-react-parser';
import {SEARCH_RESULT_VARIANT} from '../SearchResultRow';
import {getProjectGroupLink, getProjectLinkAppendLastViewed, getTaskLink} from '../../../../shared/util/LinkUtil';
import {escapeRegExp} from 'lodash';

export const processTextToHTML = (text, shortenInput = true) => {
	const sanitizedInput = sanitize(text);
	if (shortenInput) {
		const shortenedInput = sanitizedInput.substring(sanitizedInput.indexOf('<em>') - 20);
		if (shortenedInput.length < sanitizedInput.length) {
			return parse('...' + shortenedInput);
		}
	}

	return parse(sanitizedInput);
};

const getReplacerRegex = removePiece => {
	return new RegExp(`\\b${escapeRegExp(removePiece)}\\b`, 'g');
};

// Returns the name string with individual words wrapped in <em></em> tags if they were wrapped in the highlight.
const mergeNameAndHighlight = (name, highlight) => {
	const highlightTerms = highlight.matchAll(/<em>(.*?)<\/em>/g); // matches anything wrapped between <em></em> tags to get highlighted terms from highlight string
	const highlightList = Array.from(highlightTerms, x => x[1]);
	return highlightList.reduce((acc, curr) => acc.replaceAll(getReplacerRegex(curr), `<em>${curr}</em>`), name);
};

export const getLinkFromVariant = (id, customProjectId, variant) => {
	switch (variant) {
		case SEARCH_RESULT_VARIANT.PROJECT_GROUP:
			return getProjectGroupLink(id);
		case SEARCH_RESULT_VARIANT.PROJECT:
			return getProjectLinkAppendLastViewed(id, customProjectId);
		case SEARCH_RESULT_VARIANT.TASK:
			return getTaskLink(id);
		default:
			return null;
	}
};

export const getFormattedName = (name, highlight) => {
	const highlightField = highlight?.highlightField;
	const highlightIsName = highlightField === 'name' || highlightField === 'name.shingled_bigram';
	return highlightIsName ? processTextToHTML(mergeNameAndHighlight(name, highlight?.highlightValue), false) : name;
};

export const getFormattedVariantName = variant => {
	switch (variant) {
		case SEARCH_RESULT_VARIANT.PROJECT_GROUP:
			return 'Connected project';
		case SEARCH_RESULT_VARIANT.PROJECT:
			return 'Project';
		case SEARCH_RESULT_VARIANT.TASK:
			return 'Task';
		case SEARCH_RESULT_VARIANT.INTERNAL_TIME:
			return 'Internal time';
		default:
			return '';
	}
};

export const getAllowedEntitiesText = (allowedEntities, intl) => {
	return intl.formatList(allowedEntities, {
		style: 'short',
		type: 'disjunction',
	});
};
