/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TimeRegClientRelayWrapper_viewer$ref = any;
export type GLOBAL_FILTER_CONTEXT = "INVOICES" | "LABELS" | "PEOPLE" | "PLACEHOLDERS" | "PROJECTS" | "RESOURCES" | "SKILLS" | "TASKS" | "TIME_REGS" | "%future added value";
export type GLOBAL_FILTER_FIELD = "APPROVAL_STATUS" | "ASSIGNED" | "ASSIGNED_SELF" | "BILLABLE" | "BILLABLE_TIME_REG" | "BILL_FROM" | "BILL_TO" | "BLOCKED" | "BUG" | "CLIENT" | "CLIENT_GUEST_USERS" | "CLIENT_TIME_REG" | "COMPANY_TASK_ID" | "CONTACT" | "CREATED_AT" | "DEADLINE" | "DEPARTMENT" | "DEPARTMENT_TIME_REG" | "DEPENDENCIES" | "DESCRIPTION" | "END_DATE" | "END_DATE_PROJECT" | "END_DATE_TIME_REG" | "FILTER_BY_TIME_REG_SETTINGS" | "FOLLOWER" | "FORECAST" | "HIGH_PRIORITY" | "INTERNAL_TIME" | "INTERNAL_TIME_REG" | "INVOICED" | "LABELS" | "LABEL_TIME_REG" | "NAME" | "NON_CLIENT_TIME_REG" | "NON_DEPARTMENT_TIME_REG" | "NON_PHASE_TIME_REG" | "NON_PROJECT_TIME_REG" | "NON_ROLE_TIME_REG" | "NON_SPRINT_TIME_REG" | "NON_TASK_TIME_REG" | "NO_CONTACT" | "OWNER" | "PERMISSION_LEVEL" | "PERSON" | "PERSON_TIME_REG" | "PHASE_CATEGORY" | "PHASE_TIME_REG" | "PRIORITY_LEVEL" | "PROGRAM" | "PROJECT" | "PROJECT_BUDGET_TYPE" | "PROJECT_DEFAULT_PERIOD_BUDGET_TYPE" | "PROJECT_FOLLOWER" | "PROJECT_GROUP" | "PROJECT_GROUP_SPRINT" | "PROJECT_GROUP_STATUS" | "PROJECT_LABELS" | "PROJECT_MANUAL_PROGRESS" | "PROJECT_OWNER" | "PROJECT_PERSON" | "PROJECT_PHASE" | "PROJECT_SPRINT" | "PROJECT_STAGE" | "PROJECT_STATUS" | "PROJECT_TIME_REG" | "RATE_CARD" | "RECENT_ACTIVITY" | "RECENT_TIME" | "REMAINING" | "RETAINER_PERIOD" | "ROLE" | "ROLE_TIME_REG" | "SET_DONE_AT" | "SKILL" | "SPRINT_CATEGORY" | "SPRINT_TIME_REG" | "STARRED" | "START_DATE" | "START_DATE_PROJECT" | "START_DATE_TIME_REG" | "STATUS" | "SUB_TASKS" | "TASK" | "TASK_APPROVED" | "TASK_HIERARCHY" | "TASK_LEVEL" | "TASK_TIME_REG" | "TEAM" | "TEAM_MEMBER" | "TEAM_TIME_REG" | "TIME_OFF" | "TIME_OFF_TIME_REG" | "TIME_REGS" | "UPDATED_AT" | "WITH_TASKS" | "WITH_TIME_REG" | "%future added value";
export type GLOBAL_FILTER_LOGICAL_OPERATOR = "AND" | "OR" | "%future added value";
export type GLOBAL_FILTER_OPERATOR = "ALL_IN" | "CONTAINS" | "CONTAINS_NOT" | "GREATER" | "GREATER_OR_EQUAL" | "IN" | "IS" | "IS_ALL" | "IS_NOT" | "IS_NOT_ALL" | "LESS" | "LESS_OR_EQUAL" | "NOT_ALL_IN" | "NOT_IN" | "%future added value";
export type TaskSearchQueryType = {|
  filters?: ?$ReadOnlyArray<?GlobalSearchFilterType>
|};
export type GlobalSearchFilterType = {|
  field?: ?GLOBAL_FILTER_FIELD,
  operator?: ?GLOBAL_FILTER_OPERATOR,
  value?: ?$ReadOnlyArray<?string>,
  context?: ?GLOBAL_FILTER_CONTEXT,
  filters?: ?$ReadOnlyArray<?GlobalSearchFilterType>,
  logicalOperator?: ?GLOBAL_FILTER_LOGICAL_OPERATOR,
|};
export type TimeRegClientRelayWrapperRefetchQueryVariables = {|
  pageSize?: ?number,
  searchQuery?: ?TaskSearchQueryType,
  reportId?: ?string,
  sharedId?: ?string,
|};
export type TimeRegClientRelayWrapperRefetchQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: TimeRegClientRelayWrapper_viewer$ref
  |}
|};
export type TimeRegClientRelayWrapperRefetchQuery = {|
  variables: TimeRegClientRelayWrapperRefetchQueryVariables,
  response: TimeRegClientRelayWrapperRefetchQueryResponse,
|};
*/


/*
query TimeRegClientRelayWrapperRefetchQuery(
  $pageSize: Int
  $searchQuery: TaskSearchQueryType
  $reportId: String
  $sharedId: String
) {
  viewer {
    ...TimeRegClientRelayWrapper_viewer_3tSDRy
    id
  }
}

fragment TimeRegClientRelayWrapper_viewer_3tSDRy on Viewer {
  reportData(id: $reportId, sharedId: $sharedId) {
    clients(first: $pageSize, searchQuery: $searchQuery) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          clientId
          name
          noClient
          timeRegTotals {
            totalCost
            totalPrice
            totalTimeRegistered
            totalBillableRegistered
            totalNonBillableRegistered
            id
          }
          id
          __typename
        }
        cursor
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pageSize",
    "type": "Int"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchQuery",
    "type": "TaskSearchQueryType"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "reportId",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sharedId",
    "type": "String"
  }
],
v1 = {
  "kind": "Variable",
  "name": "searchQuery",
  "variableName": "searchQuery"
},
v2 = {
  "kind": "Variable",
  "name": "sharedId",
  "variableName": "sharedId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "pageSize"
  },
  (v1/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TimeRegClientRelayWrapperRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "pageSize",
                "variableName": "pageSize"
              },
              {
                "kind": "Variable",
                "name": "reportId",
                "variableName": "reportId"
              },
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "TimeRegClientRelayWrapper_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TimeRegClientRelayWrapperRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "reportId"
              },
              (v2/*: any*/)
            ],
            "concreteType": "ReportData",
            "kind": "LinkedField",
            "name": "reportData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "ReportClientTypeConnection",
                "kind": "LinkedField",
                "name": "clients",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReportClientTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ReportClient",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "clientId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "noClient",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ReportTimeRegTotals",
                            "kind": "LinkedField",
                            "name": "timeRegTotals",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "totalCost",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "totalPrice",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "totalTimeRegistered",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "totalBillableRegistered",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "totalNonBillableRegistered",
                                "storageKey": null
                              },
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "filters": [
                  "searchQuery"
                ],
                "handle": "connection",
                "key": "TimeRegReport_clients",
                "kind": "LinkedHandle",
                "name": "clients"
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "TimeRegClientRelayWrapperRefetchQuery",
    "operationKind": "query",
    "text": "query TimeRegClientRelayWrapperRefetchQuery(\n  $pageSize: Int\n  $searchQuery: TaskSearchQueryType\n  $reportId: String\n  $sharedId: String\n) {\n  viewer {\n    ...TimeRegClientRelayWrapper_viewer_3tSDRy\n    id\n  }\n}\n\nfragment TimeRegClientRelayWrapper_viewer_3tSDRy on Viewer {\n  reportData(id: $reportId, sharedId: $sharedId) {\n    clients(first: $pageSize, searchQuery: $searchQuery) {\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n      edges {\n        node {\n          clientId\n          name\n          noClient\n          timeRegTotals {\n            totalCost\n            totalPrice\n            totalTimeRegistered\n            totalBillableRegistered\n            totalNonBillableRegistered\n            id\n          }\n          id\n          __typename\n        }\n        cursor\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9afc0e747e10a017b67e3e4d4e252b84';

module.exports = node;
