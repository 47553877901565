import React, { useEffect, useMemo, useState } from 'react';
import { Dropdown as WCDropdown } from 'web-components';
import { trackEvent } from '../../../../../tracking/amplitude/TrackingV2';
import { createFragmentContainer, graphql } from 'react-relay';
import { getCompanyRoles, isRoleDisabled, } from '../../modals/time-registration/time-registration-modal/util/TimeRegistrationModalRoleUtil';
import { getDefaultRole } from '../../../util/time-registration/time-role/TimeRegistrationRoleUtil';
import { LabelAndWarning } from './LabelAndWarning';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { ComboBox, Text } from '@forecast-it/design-system';
import { DesignSystemComboBoxOldLookAdapter } from '../DesignSystemComboBoxOldLookAdapter';
function getWarningText(showDisabledRoleWarning, showMovedToProjectRoleWarning) {
    if (showMovedToProjectRoleWarning) {
        return React.createElement(FormattedMessage, { id: "time_reg.changed_project_role_changed" });
    }
    if (showDisabledRoleWarning) {
        return React.createElement(FormattedHTMLMessage, { id: "project_person.deactivated_role" });
    }
    return undefined;
}
export var RoleDropdownLabelFormat;
(function (RoleDropdownLabelFormat) {
    RoleDropdownLabelFormat["NO_MARGIN"] = "narrow";
    RoleDropdownLabelFormat["DEFAULT"] = "default";
})(RoleDropdownLabelFormat || (RoleDropdownLabelFormat = {}));
export function onRoleOrRolesChanged(roles, selectedRole) {
    const roleOptions = roles.map(role => ({
        value: role.id,
        label: role.name,
        disabled: role.disabled,
        cy: 'role-dropdown-item-' + role.name,
    }));
    return { roleOptions, isRoleDisabled: isRoleDisabled(selectedRole === null || selectedRole === void 0 ? void 0 : selectedRole.id, roles) };
}
function RoleDropdown({ viewer, role, showMovedToProjectRoleWarning, onSelect, isDisabled, projectId, showLabel, labelFormat, }) {
    var _a;
    const [selectedRole, setSelectedRole] = useState(role);
    const [showDisabledRoleWarning, setShowDisabledRoleWarning] = useState(false);
    const roles = useMemo(() => { var _a; return getCompanyRoles((_a = viewer.company) === null || _a === void 0 ? void 0 : _a.roles, projectId, selectedRole); }, [(_a = viewer.company) === null || _a === void 0 ? void 0 : _a.roles, projectId, selectedRole]);
    useEffect(() => {
        var _a, _b, _c, _d, _e;
        setSelectedRole(getDefaultRole(role, (_b = (_a = viewer === null || viewer === void 0 ? void 0 : viewer.project) === null || _a === void 0 ? void 0 : _a.projectPerson) === null || _b === void 0 ? void 0 : _b.role, (_c = viewer === null || viewer === void 0 ? void 0 : viewer.task) === null || _c === void 0 ? void 0 : _c.role, (_e = (_d = viewer.company) === null || _d === void 0 ? void 0 : _d.person) === null || _e === void 0 ? void 0 : _e.role) ||
            undefined);
    }, [role, projectId, viewer === null || viewer === void 0 ? void 0 : viewer.task, viewer === null || viewer === void 0 ? void 0 : viewer.project]);
    const roleOptions = useMemo(() => {
        const { roleOptions, isRoleDisabled } = onRoleOrRolesChanged(roles, selectedRole);
        setShowDisabledRoleWarning(isRoleDisabled);
        return roleOptions;
    }, [role, roles]);
    const handleTimeRegRoleChange = (id) => {
        const newRole = roles.find(role => role.id === id);
        onSelect(newRole);
        setSelectedRole(newRole);
        trackEvent('Time Registration', 'Role Changed', {
            timeRegistrationRoleName: newRole === null || newRole === void 0 ? void 0 : newRole.name,
        });
    };
    return (React.createElement(LabelAndWarning, { showLabel: showLabel, labelFormat: labelFormat, warningText: getWarningText(showDisabledRoleWarning, showMovedToProjectRoleWarning || false) },
        React.createElement(DesignSystemComboBoxOldLookAdapter, null,
            React.createElement(ComboBox, { "data-cy": 'role-dropdown', defaultItems: roleOptions, value: selectedRole === null || selectedRole === void 0 ? void 0 : selectedRole.id, placeholder: 'Select a role', onChange: handleTimeRegRoleChange, width: ComboBox.WIDTH.FLEX, size: 'small', menuTrigger: "focus", disabled: isDisabled }, option => (React.createElement(ComboBox.Item, { key: option.value, textValue: option.label || '' },
                React.createElement(Text, { "data-cy": option.cy, noWrap: true, size: '2' }, option.label),
                ' '))))));
}
function PlaceholderDropdown({ showLabel, labelFormat }) {
    return (React.createElement(LabelAndWarning, { showLabel: showLabel, labelFormat: labelFormat },
        React.createElement(WCDropdown, { name: '', inputClassName: 'role-drop-down' })));
}
const RoleDropdownQuery = graphql `
	query RoleDropdown_Query($projectId: ID, $taskId: ID, $personId: ID) {
		viewer {
			component(name: "role_dropdown")
			actualPersonId
			id
			...RoleDropdown_viewer @arguments(projectId: $projectId, taskId: $taskId, personId: $personId)
		}
	}
`;
export { RoleDropdownQuery, PlaceholderDropdown };
export default createFragmentContainer(RoleDropdown, {
    viewer: graphql `
		fragment RoleDropdown_viewer on Viewer
		@argumentDefinitions(projectId: {type: "ID"}, taskId: {type: "ID"}, personId: {type: "ID"}) {
			company {
				roles(includeDisabled: false, projectId: $projectId) {
					edges {
						node {
							id
							name
						}
					}
				}
				person(id: $personId) {
					id
					role {
						id
						name
					}
				}
			}
			project(internalId: $projectId) {
				projectPerson(personId: $personId) {
					role {
						id
						name
					}
				}
			}
			task(id: $taskId) {
				role {
					id
					name
				}
			}
		}
	`,
});
