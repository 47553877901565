import Moment from 'moment';
import {GLOBAL_FILTER_FIELD, GLOBAL_FILTER_OPERATOR} from '../../../../../constants';
import {taskTypeDefaultOptions} from '../../timesheets_page';

const filterSelf = {field: GLOBAL_FILTER_FIELD.ASSIGNED_SELF, operator: 'IN', value: ['true']};

const keyToFilterField = key => {
	switch (key) {
		case 'recent':
			return GLOBAL_FILTER_FIELD.RECENT_TIME;
		//return GLOBAL_FILTER_FIELD.UPDATED_AT;
		case 'starred':
			return GLOBAL_FILTER_FIELD.STARRED;
		case 'planned':
		case 'inProgress':
			return GLOBAL_FILTER_FIELD.STATUS;
		default:
			return key;
	}
};

export const constructSearchQuery = (selectedSwitch, currentViewingDate, isWeekView) => {
	const searchQuery = {};
	const filterValues = [];

	// Suggested time is queried in a completely different way, and will therefore need to be handled separately
	if (selectedSwitch === 'suggested') {
		searchQuery.filters = filterValues;

		return searchQuery;
	} else if (selectedSwitch && selectedSwitch !== 'none') {
		let formattedFilter = {};
		if (selectedSwitch === 'recent') {
			// This value determines how far back in time the query should look.
			const timeVal = Moment().subtract(1, 'week').startOf('day').format('YYYY-MM-DD');

			formattedFilter = {
				field: keyToFilterField(selectedSwitch),
				operator: GLOBAL_FILTER_OPERATOR.IS,
				//operator: GLOBAL_FILTER_OPERATOR.GREATER_OR_EQUAL,
				value: [timeVal],
			};
			filterValues.push(formattedFilter);
		} else if (selectedSwitch === 'assignedToMe') {
			// Do nothing?
			const aMonthAgo = Moment().subtract(30, 'days').startOf('day').format('YYYY-MM-DD');
			filterValues.push({field: GLOBAL_FILTER_FIELD.UPDATED_AT, operator: 'GREATER_OR_EQUAL', value: [aMonthAgo]});

			const aMonthAhead = Moment().add(30, 'days').startOf('day').format('YYYY-MM-DD');
			filterValues.push({field: GLOBAL_FILTER_FIELD.START_DATE, operator: 'LESS_OR_EQUAL', value: [aMonthAhead]});
		} else if (selectedSwitch !== 'planned') {
			const value = taskTypeDefaultOptions[selectedSwitch].val;
			formattedFilter = {
				field: keyToFilterField(selectedSwitch),
				operator: GLOBAL_FILTER_OPERATOR.IS,
				value: [value.toString()],
			};
			filterValues.push(formattedFilter);
		}

		// Only return tasks assigned to the person - this is very unintuitive for starred tasks
		if (selectedSwitch !== 'starred' && selectedSwitch !== 'recent') {
			filterValues.push(filterSelf);
		}
		if (selectedSwitch === 'planned') {
			const startDate = isWeekView
				? currentViewingDate.clone().endOf('week').format('YYYY-MM-DD')
				: currentViewingDate.clone().format('YYYY-MM-DD');
			const endDate = isWeekView
				? currentViewingDate.clone().startOf('week').format('YYYY-MM-DD')
				: currentViewingDate.clone().format('YYYY-MM-DD');
			filterValues.push({field: GLOBAL_FILTER_FIELD.START_DATE, operator: 'LESS_OR_EQUAL', value: [startDate]});
			filterValues.push({field: GLOBAL_FILTER_FIELD.END_DATE, operator: 'GREATER_OR_EQUAL', value: [endDate]});
		}
	}

	searchQuery.filters = filterValues;
	return searchQuery;
};
