import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {
	DeprecatedButtonIconOnly as ButtonIconOnly,
	DeprecatedColorIndicator as ColorIndicator,
	DeprecatedContextMenuItem as ContextMenuItem,
	DeprecatedCreativeModal as CreativeModal,
	DeprecatedText as Text,
	deprecatedTheme as theme,
	DeprecatedDropdownMenu as DropdownMenu,
	FlexColumn,
	FlexItem,
	FlexRow,
	Icon,
	DeprecatedInput as Input,
	DeprecatedLink as Link,
	DeprecatedListCell as ListCell,
	DeprecatedListRow as ListRow,
	DeprecatedNoticeBanner as NoticeBanner,
	DeprecatedPlaceholderItem as PlaceholderItem,
	DeprecatedScrollbar as Scrollbar,
	DeprecatedSearchWithDropdown as SearchWithDropdown,
	DeprecatedTable as Table,
	DeprecatedTableCell as TableCell,
	DeprecatedTableRow as TableRow,
} from '@forecast-it/design-system';
import {createFragmentContainer, graphql} from 'react-relay';
import {useIntl} from 'react-intl';
import Util from '../../../util/util';
import AttachProjectToProgramMutation from '../../../../../mutations/project-service/attach_project_to_program_mutation';
import {createToast} from '../../toasts/toast';
import useSearch, {searchMultiplePaths} from '../../../hooks/useSearch';
import {BUDGET_TYPE, PROGRAM_BUDGET_TYPE, PROJECT_STATUS, SOCKET_ACTION, SOCKET_EVENT_TYPE} from '../../../../../constants';
import {trackEvent} from '../../../../../tracking/amplitude/TrackingV2';
import Styled from 'styled-components/macro';
import ForecastTooltip from '../../tooltips/ForecastTooltip';
import {withSocketHandling} from '../../../../../socket/withSocketHandling';
import ProgramUtil from '../../../util/ProgramUtil';
import ProjectUtil from '../../../util/project_util';
import Checkbox from '../../../../../components/inputs/checkbox';
import {useTrackModal} from '../../../../../tracking/amplitude/hooks/useTrackModal';
import useUpdateEffect from '../../../hooks/useUpdateEffect';

const ProjectDropdownSection = Styled.div`
	&:not(:first-child) {
		padding-top: 12px;
	}
	&:first-child {
		padding-top: 16px;
	}
`;
const ProjectDropdownSectionHeader = Styled.div`
	margin-bottom: 16px;
	padding-left: 16px;
	padding-right: 16px;
	display: flex;
`;
const ProjectDropdownSectionHeaderTitle = Styled.div`
	margin-right: 13px;
`;

const UnavailableIconContainer = Styled.div`
	margin-right: 20px;
`;

const getIsProgramOverBudget = (program, projectBudgetTotal) => {
	return (
		[PROGRAM_BUDGET_TYPE.FIXED_PRICE, PROGRAM_BUDGET_TYPE.CAPPED].includes(program.budgetType) &&
		program.budgetValue < projectBudgetTotal
	);
};

const getSearchedProjects = (projects, searchValue, selectedProjects, showAllProjects) => {
	const SEARCH_PATHS = ['name', 'prefix', 'legacyProjectAsSingleProject.customProjectId', 'clients.edges.node.name'];

	const availableProjects = projects
		.map(availableProject => availableProject.node)
		.filter(
			availableProject =>
				!selectedProjects.some(
					selectedProject => availableProject.serviceCompanyId === selectedProject.serviceCompanyId
				)
		)
		.filter(
			project => showAllProjects || (project.stage !== PROJECT_STATUS.DONE && project.stage !== PROJECT_STATUS.HALTED)
		);
	return searchMultiplePaths(availableProjects, SEARCH_PATHS, searchValue);
};

function getProjectIdentifier(project) {
	return project?.legacyProjectAsSingleProject?.customProjectId || project.prefix;
}

const ImportProjectToProgramModal = ({viewer, program, closeModal, setSocketConfig}) => {
	// Default search paths
	const _search = useSearch();
	const history = useHistory();
	const isFixedPriceProgram = program.budgetType === PROGRAM_BUDGET_TYPE.FIXED_PRICE;

	const currentProjectBudgetTotal = ProjectUtil.getProjectConfirmedRevenueTotal(
		program.projects.edges.map(project => project.node)
	);

	const {formatMessage} = useIntl();
	const [selectedProjects, setSelectedProjects] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [showAllProjects, setShowAllProjects] = useState(false);
	const [searchedProjects, setSearchedProjects] = useState(
		getSearchedProjects(viewer.psProjects.edges, searchValue, selectedProjects, showAllProjects)
	);
	const [projectBudgetTotal, setProjectBudgetTotal] = useState(currentProjectBudgetTotal);
	const [showProgramBudgetError, setShowProgramBudgetError] = useState(false);
	const [showProjectDropdown, setShowProjectDropdown] = useState(false);
	const projectsIds = searchedProjects.map(project => Util.getUUIdFromBase64String(project.id));
	const isProgramOverBudget = getIsProgramOverBudget(program, projectBudgetTotal);
	const [importStarted, setImportStarted] = useState(false);

	const {availableProjects, unavailableProjects} = ProgramUtil.getAvailableAndUnavailableProjects(
		searchedProjects,
		program,
		formatMessage
	);

	const socketEvents = [
		{
			type: SOCKET_EVENT_TYPE.V2_PROJECT,
			action: SOCKET_ACTION.UPDATE,
			entityIds: projectsIds,
		},
		{
			type: SOCKET_EVENT_TYPE.V2_PROJECT,
			action: SOCKET_ACTION.DELETE,
			entityIds: projectsIds,
		},
		{
			type: SOCKET_EVENT_TYPE.V2_PROJECT,
			action: SOCKET_ACTION.CREATE,
		},
	];
	setSocketConfig(socketEvents);

	const attachProjectsToProgram = () => {
		setImportStarted(true);
		if (selectedProjects) {
			const billableProjectsSelected = selectedProjects.some(project => project.budgetType !== BUDGET_TYPE.NON_BILLABLE);
			if (!isProgramOverBudget || !billableProjectsSelected) {
				Util.CommitSchedulingModalUpdate(
					AttachProjectToProgramMutation,
					{
						programId: program.id,
						projectIds: selectedProjects.map(project => project.id),
					},
					() => {
						trackEvent('Project', 'Added To Program', {numberOfProjects: selectedProjects.length});
						createToast({
							duration: 5000,
							message: Util.upperCaseFirst(
								selectedProjects.length > 1
									? formatMessage(
											{id: 'program.project.add.success_multiple'},
											{program: ProgramUtil.programText(formatMessage)}
									  )
									: formatMessage(
											{id: 'program.project.add.success_single'},
											{program: ProgramUtil.programText(formatMessage)}
									  )
							),
						});
						closeModal();
					}
				);
			} else {
				setShowProgramBudgetError(true);
				trackEvent('Program', 'Error Shown', {error: 'Project Budget Total over Program budget'});
			}
		} else {
			createToast({
				duration: 5000,
				message: formatMessage({id: 'program.project.import.empty'}),
			});
		}
	};

	const handleSelectedProjectsChanged = newSelectedProjects => {
		setSelectedProjects(newSelectedProjects);
		const selectedProjectBudgetTotal = ProjectUtil.getProjectConfirmedRevenueTotal(newSelectedProjects);
		const newProjectBudgetTotal = currentProjectBudgetTotal + selectedProjectBudgetTotal;
		if (showProgramBudgetError && !getIsProgramOverBudget(program, newProjectBudgetTotal)) {
			setShowProgramBudgetError(false);
		}
		setProjectBudgetTotal(newProjectBudgetTotal);
	};

	const stageProject = project => {
		handleSelectedProjectsChanged([project, ...selectedProjects]);
		setShowProjectDropdown(false);
	};

	const unstageProject = index => {
		const temp = [...selectedProjects];
		temp.splice(index, 1);
		handleSelectedProjectsChanged(temp);
	};

	const goToProgramBudget = () => {
		closeModal();
		history.replace(`${ProgramUtil.programLink()}/${program.prefix}/budget`);
	};

	useTrackModal('Add Project To Program Modal', {
		unavailableProjects: unavailableProjects.length,
		availableProjects: availableProjects.length,
		programRevenueLocked: program.isRevenueLocked,
	});

	useUpdateEffect(() => {
		setSearchedProjects(getSearchedProjects(viewer.psProjects.edges, searchValue, selectedProjects, showAllProjects));
	}, [searchValue, selectedProjects, showAllProjects, viewer]);

	const ProjectDropdownMenu = () => {
		const getProjectList = (projects, isDisabled) => {
			return projects.map(project => (
				<FlexRow gap={'s'}>
					<ContextMenuItem
						disabled={isDisabled}
						onClick={() => stageProject(project.data)}
						key={project.data.id}
						cy={`project_row_${project.data.prefix}`}
					>
						<ListRow>
							<ListCell size={'20px'}>
								<ColorIndicator color={isDisabled ? theme.colors.background.disabled : project.data.color} />
							</ListCell>
							<ListCell fixed={'50px'}>
								<Text variant={'bold'} color={isDisabled ? 'secondary' : 'primary'} noWrap>
									{getProjectIdentifier(project.data)}
								</Text>
							</ListCell>
							<ListCell>{_search.highlightMatch(project.data.name, searchValue, isDisabled)}</ListCell>
						</ListRow>
					</ContextMenuItem>
					{isDisabled && (
						<UnavailableIconContainer>
							<ForecastTooltip
								maxWidth={'320px'}
								placement={'bottom'}
								content={
									<FlexColumn gap={'s'}>
										<Text type={'heading'} variant={'s'}>
											{formatMessage({id: 'import_project.unavailable_projects'})}
										</Text>
										<Text>{project.tooltipMessage}</Text>
									</FlexColumn>
								}
							>
								<ListCell>
									<Icon icon={'help'} size={'m'} />
								</ListCell>
							</ForecastTooltip>
						</UnavailableIconContainer>
					)}
				</FlexRow>
			));
		};

		return (
			<DropdownMenu data-cy={'project_list'}>
				{searchedProjects.length > 0 ? (
					<>
						<ProjectDropdownSection>
							<ProjectDropdownSectionHeader>
								<ProjectDropdownSectionHeaderTitle>
									<Text type={'heading'} variant={'m'}>
										{`${formatMessage({id: 'import_project.available_projects'})} (${
											availableProjects.length
										})`}
									</Text>
								</ProjectDropdownSectionHeaderTitle>
							</ProjectDropdownSectionHeader>
							{getProjectList(availableProjects, false)}
						</ProjectDropdownSection>
						{unavailableProjects.length !== 0 && (
							<ProjectDropdownSection>
								<ProjectDropdownSectionHeader>
									<ProjectDropdownSectionHeaderTitle>
										<Text type={'heading'} variant={'m'}>
											{`${formatMessage({id: 'import_project.unavailable_projects'})} (${
												unavailableProjects.length
											})`}
										</Text>
									</ProjectDropdownSectionHeaderTitle>
								</ProjectDropdownSectionHeader>
								{getProjectList(unavailableProjects, true)}
							</ProjectDropdownSection>
						)}
					</>
				) : (
					<PlaceholderItem>
						<Text color={'disabled'}>{formatMessage({id: 'search.no_match'}, {searchText: searchValue})}</Text>
					</PlaceholderItem>
				)}
			</DropdownMenu>
		);
	};

	return (
		<CreativeModal
			title={formatMessage({id: 'common.action.add_entity'}, {entity: formatMessage({id: 'common.project'})})}
			creativeButtonText={formatMessage(
				{id: 'program.project.add_amount'},
				{optionalAmount: selectedProjects.length > 0 ? `(${selectedProjects.length})` : ''}
			)}
			closeModal={closeModal}
			onCreateHandler={attachProjectsToProgram}
			size={'l'}
			cy={'add_project_modal'}
			disabled={!selectedProjects.length || importStarted}
		>
			<FlexColumn gap={'xl'}>
				{showProgramBudgetError ? (
					<NoticeBanner type={'warning'} title={formatMessage({id: 'import_project.program_over_budget_headline'})}>
						<>
							<Text>
								{formatMessage({
									id: isFixedPriceProgram
										? 'import_project.program_over_budget_text.fixed_price'
										: 'import_project.program_over_budget_text.capped',
								})}
							</Text>{' '}
							<Link onClick={goToProgramBudget}>
								{formatMessage({
									id: isFixedPriceProgram
										? 'program.go_to_fixed_price_allocation'
										: 'common.edit_program_budget',
								})}
							</Link>
						</>
					</NoticeBanner>
				) : null}
				{isFixedPriceProgram ? (
					<NoticeBanner title={formatMessage({id: 'import_project.unavailable_projects'})}>
						<Text>{formatMessage({id: 'import_project.unavailable_project_fixed_price_budget_type_tooltip'})}</Text>
					</NoticeBanner>
				) : null}
				<FlexColumn gap={'s'}>
					<Text type={'heading'} variant={'m'}>
						{formatMessage({id: 'common.project'})}
					</Text>
					<FlexItem>
						<FlexRow gap={'s'}>
							<Checkbox
								checked={showAllProjects}
								onChange={() =>
									setShowAllProjects(prevState => {
										trackEvent('Import Project To Program Modal', 'Toggle Show Done/Halted Programs', {
											state: !prevState ? 'With done/halted' : 'Without done/halted',
										});
										return !prevState;
									})
								}
							></Checkbox>
							<Text>
								{formatMessage(
									{id: 'common.show.done-halted'},
									{entity: formatMessage({id: 'common.projects'})}
								)}
							</Text>
						</FlexRow>
					</FlexItem>
					<Text color={'secondary'}>{formatMessage({id: 'import_project.project_dropdown_description'})}</Text>
					<SearchWithDropdown DropdownMenu={showProjectDropdown ? ProjectDropdownMenu : () => <></>}>
						<Input
							icon={'search'}
							placeholder={formatMessage(
								{id: 'common.action.add_entity'},
								{entity: formatMessage({id: 'common.project'}).toLowerCase()}
							)}
							onChange={e => {
								setSearchValue(e.target.value);
							}}
							onClick={() => setShowProjectDropdown(true)}
							error={showProgramBudgetError}
							data-cy={'project_search'}
						/>
					</SearchWithDropdown>
					{selectedProjects.length > 0 && (
						<Scrollbar maxHeight={'310px'}>
							<Table>
								{selectedProjects.map((selectedProject, index) => (
									<TableRow key={selectedProject.id} error={showProgramBudgetError}>
										<TableCell fixed={'20px'}>
											<ColorIndicator color={selectedProject.color} />
										</TableCell>
										<TableCell fixed={'50px'}>
											<Text variant={'bold'} noWrap>
												{getProjectIdentifier(selectedProject)}
											</Text>
										</TableCell>
										<TableCell size={'l'}>{selectedProject.name}</TableCell>
										<TableCell align={'right'}>
											<ButtonIconOnly
												icon={'closeS'}
												size={'s'}
												type={'ghost'}
												onClick={() => unstageProject(index)}
											/>
										</TableCell>
									</TableRow>
								))}
							</Table>
						</Scrollbar>
					)}
				</FlexColumn>
			</FlexColumn>
		</CreativeModal>
	);
};

const ImportProjectToProgramModalQuery = graphql`
	query ImportProjectToProgramModal_Query {
		viewer {
			actualPersonId
			component(name: "import_project_to_program_modal")
			...ImportProjectToProgramModal_viewer
		}
	}
`;

export {ImportProjectToProgramModalQuery};

export default withSocketHandling(
	createFragmentContainer(ImportProjectToProgramModal, {
		viewer: graphql`
			fragment ImportProjectToProgramModal_viewer on Viewer {
				id
				backendId
				actualPersonId
				psProjects(first: 100000) {
					edges {
						node {
							id
							serviceCompanyId
							companyProjectId
							name
							description
							prefix
							legacyProjectAsSingleProject {
								customProjectId
							}
							color
							startDate
							endDate
							stage
							budgetType
							defaultPeriodBudgetType
							userRelationTypes
							clients {
								edges {
									node {
										id
										name
									}
								}
							}
							status {
								edges {
									node {
										id
										description
										color
										addedAt
									}
								}
							}
							currentProjectStatus {
								id
								color
								description
								person {
									id
									firstName
									lastName
									profilePictureId
								}
							}
							settings(names: ["connectedProjectId"]) {
								edges {
									node {
										id
										name
										value
										type
										group
										synced
									}
								}
							}
							totalRevenueRecognition
							totalRevenueRecognitionToDate
							totalTimeRevenueRecognitionToDate
							totalBillableExpensesAtCompletion
							program
						}
					}
				}
			}
		`,
	})
);
