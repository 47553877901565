import React, {createContext, useContext, useState} from 'react';
import {useIntl} from 'react-intl';
import Util from '../../../../shared/util/util';
import {ESTIMATION_UNIT} from '../../../../../constants';
import * as tracking from '../../../../../tracking';
import {trackEvent} from '../../../../../tracking/amplitude/TrackingV2';
import {hasFeatureFlag} from '../../../../shared/util/FeatureUtil';
import {idFromGlobalId} from '../../../../shared/util/GlobalIdUtil';

const TotalsContext = createContext(null);

/**
 * This is the Context Provider for the Scoping page header totals.
 *
 * It is used for calculating the totals values, and for handling the expanding state of the box.
 *
 * @param children The
 * @param projectData The project data used for calculating.
 * @param projectSettings The settings used for showing the totals
 * @returns {JSX.Element} The Context Provider element.
 */
export const ProjectScopingTotalsProvider = ({
	children,
	projectData,
	settings,
	baselineFinancialNumbers,
	totalFinancialNumbers,
	phases,
}) => {
	const intl = useIntl();

	const TOTALS_EXPANDED_KEY = 'project-scoping-totals-expanded';

	const [totalsExpanded, setTotalsExpanded] = useState(JSON.parse(Util.localStorageGetItem(TOTALS_EXPANDED_KEY) || true));

	const startDate = Util.CreateNonUtcMomentDate(
		projectData.projectStartYear,
		projectData.projectStartMonth,
		projectData.projectStartDay
	);
	const endDate = Util.CreateNonUtcMomentDate(
		projectData.projectEndYear,
		projectData.projectEndMonth,
		projectData.projectEndDay
	);

	const startDateString = startDate
		? `${intl.formatDate(startDate, {day: 'numeric'})} ${intl.formatDate(startDate, {month: 'short'})}, ${intl.formatDate(
				startDate,
				{year: 'numeric'}
		  )}`
		: intl.formatMessage({id: 'project_overview.no_start_date'});
	const endDateString = endDate
		? `${intl.formatDate(endDate, {day: 'numeric'})} ${intl.formatDate(endDate, {month: 'short'})}, ${intl.formatDate(
				endDate,
				{year: 'numeric'}
		  )}`
		: intl.formatMessage({id: 'project_overview.no_end_date'});

	const progress = projectData.manualProgressEnabled
		? projectData.progress / 100.0
		: projectData.rolledUpData.projectProgress
		? projectData.rolledUpData.projectProgress / 100.0
		: 0;
	const plannedRevenue = projectData.rolledUpData.price;

	const actualRevenue = projectData.rolledUpData.actualPrice;
	const actualRevenueForTasks = projectData.rolledUpData.actualPrice
		? Util.getFormattedCurrencyValue(
				settings.currencySymbol,
				intl.formatNumber(projectData.rolledUpData.actualPrice, {format: 'always_two_decimal'})
		  )
		: '-';
	const actualRevenueForProject = projectData.projectRevenueNoTask
		? Util.getFormattedCurrencyValue(
				settings.currencySymbol,
				intl.formatNumber(projectData.projectRevenueNoTask, {format: 'always_two_decimal'})
		  )
		: '-';

	const totalPriceAtCompletion = projectData.rolledUpData.totalPriceAtCompletion;
	const totalPriceAtCompletionForTasks = projectData.rolledUpData.totalPriceAtCompletion
		? Util.getFormattedCurrencyValue(
				settings.currencySymbol,
				intl.formatNumber(projectData.rolledUpData.totalPriceAtCompletion, {format: 'always_two_decimal'})
		  )
		: '-';
	const totalPriceAtCompletionForProject = projectData.projectTotalPriceAtCompletionNoTask
		? Util.getFormattedCurrencyValue(
				settings.currencySymbol,
				intl.formatNumber(projectData.projectTotalPriceAtCompletionNoTask, {format: 'always_two_decimal'})
		  )
		: '-';

	const isEstimatedInHours = projectData.rolledUpData.estimationUnit === ESTIMATION_UNIT.HOURS;

	const estimate = projectData.rolledUpData.estimate
		? isEstimatedInHours
			? projectData.rolledUpData.estimate / 60
			: (projectData.rolledUpData.estimate / 60) * projectData.minutesPerEstimationPoint
		: 0;

	const estimateOnTasksFormatted = projectData.rolledUpData.estimate
		? isEstimatedInHours
			? Util.convertMinutesToFullHour(projectData.rolledUpData.estimate, intl)
			: Util.convertMinutesToFullHour(projectData.rolledUpData.estimate * projectData.minutesPerEstimationPoint, intl)
		: '-';

	const timeEntries = projectData.projectTimeEntries ? projectData.projectTimeEntries / 60 : 0;

	const billableTimeEntries = projectData.projectBillableTimeEntries ? projectData.projectBillableTimeEntries / 60 : 0;

	const timeEntriesOnTasks = projectData.rolledUpData.timeRegistered
		? Util.convertMinutesToFullHour(projectData.rolledUpData.timeRegistered, intl)
		: '-';

	const timeEntriesOnProject = projectData.projectTimeRegisteredNoTask
		? Util.convertMinutesToFullHour(projectData.projectTimeRegisteredNoTask, intl)
		: '-';

	const remaining =
		projectData.manualProgressOnProjectEnabled || projectData.manualProgressOnPhasesEnabled
			? estimate - estimate * progress
			: projectData.rolledUpData.remaining
			? isEstimatedInHours
				? projectData.rolledUpData.remaining / 60
				: (projectData.rolledUpData.remaining / 60) * projectData.minutesPerEstimationPoint
			: 0;

	const remainingOnTasksFormatted =
		projectData.manualProgressOnProjectEnabled || projectData.manualProgressOnPhasesEnabled
			? isEstimatedInHours
				? Util.convertMinutesToFullHour(estimate - estimate * progress, intl)
				: Util.convertMinutesToFullHour((estimate - estimate * progress) * 60, intl)
			: projectData.rolledUpData.remaining
			? isEstimatedInHours
				? Util.convertMinutesToFullHour(projectData.rolledUpData.remaining, intl)
				: Util.convertMinutesToFullHour(
						projectData.rolledUpData.remaining * projectData.minutesPerEstimationPoint,
						intl
				  )
			: '-';

	const difference = projectData.projectDifferenceEstimate ? projectData.projectDifferenceEstimate / 60 : 0;

	const varianceOnTasksFormatted = projectData.projectDifferenceEstimate
		? Util.convertMinutesToFullHour(projectData.projectDifferenceEstimate, intl)
		: '-';

	const totalAvailability = projectData.rolledUpData.totalAvailability ? projectData.rolledUpData.totalAvailability / 60 : 0;
	const remainingAvailability = projectData.rolledUpData.remainingAvailability
		? projectData.rolledUpData.remainingAvailability / 60
		: 0;

	const phaseIds = new Set(phases.map(phase => idFromGlobalId(phase.id)));
	const baselineFinancialNumbersPhaseFiltered = baselineFinancialNumbers.filter(financialNumbers =>
		phaseIds.has(financialNumbers.phaseId)
	);
	const baselineTargetMinutes = hasFeatureFlag('baseline_financial_service')
		? baselineFinancialNumbersPhaseFiltered.reduce((total, financialNumbers) => total + financialNumbers.baselineMinutes, 0)
		: projectData.rolledUpData.baselineTargetMinutes;
	const baselineTargetHours = baselineTargetMinutes ? baselineTargetMinutes / 60 : 0;
	const baselineTargetPrice = hasFeatureFlag('baseline_financial_service')
		? baselineFinancialNumbersPhaseFiltered.reduce(
				(total, financialNumbers) => total + financialNumbers.baselineTimeAndExpenses,
				0
		  )
		: projectData.rolledUpData.baselineTargetPrice;
	const allTotalTimeAndExpensesAtCompletion = projectData.projectAllTotalTimeAndExpensesAtCompletion;
	const projectedTotalCost = totalFinancialNumbers.totalCostAtCompletion;
	const projectedTotalRevenue = totalFinancialNumbers.totalRevenueRecognition;
	const projectedTotalProfit = totalFinancialNumbers.totalRevenueProfitAtCompletion;
	const projectedTotalMargin = totalFinancialNumbers.totalRevenueMarginAtCompletion;
	const projectedTotalHours = totalFinancialNumbers.forecastMinutes / 60;
	const actualHours = totalFinancialNumbers.registeredMinutes / 60;
	const actualBillableHours = totalFinancialNumbers.billableActualMinutes / 60;
	const actualNonBillableHours = totalFinancialNumbers.nonBillableActualMinutes / 60;
	const projectedBillableValueOfService = totalFinancialNumbers.billableTotalTimeAndExpensesAtCompletion;
	const baselineHours = totalFinancialNumbers.baselineMinutes / 60;

	const toggleTotalsExpanded = () => {
		setTotalsExpanded(value => {
			const newValue = !value;
			Util.localStorageSetItem(TOTALS_EXPANDED_KEY, newValue);
			tracking.trackElementClicked('Expand scoping totals', {enabled: newValue});
			trackEvent('Scoping Totals', newValue ? 'Expanded' : 'Collapsed');

			return newValue;
		});
	};

	return (
		<TotalsContext.Provider
			value={{
				totalsExpanded,
				toggleTotalsExpanded,
				projectData, // The original project data
				settings, // The settings
				totalsData: {
					startDate,
					startDateString,
					endDate,
					endDateString,
					progress,
					plannedRevenue,
					actualRevenue,
					actualRevenueForTasks,
					actualRevenueForProject,
					totalPriceAtCompletion,
					totalPriceAtCompletionForTasks,
					totalPriceAtCompletionForProject,
					estimate,
					estimateOnTasks: estimateOnTasksFormatted,
					timeEntries,
					billableTimeEntries,
					timeEntriesOnTasks,
					timeEntriesOnProject,
					remaining,
					remainingOnTasks: remainingOnTasksFormatted,
					difference,
					varianceOnTasks: varianceOnTasksFormatted,
					totalAvailability,
					remainingAvailability,
					baselineTargetHours,
					baselineTargetPrice,
					allTotalTimeAndExpensesAtCompletion,
					projectedTotalCost,
					projectedTotalRevenue,
					projectedTotalProfit,
					projectedTotalMargin,
					projectedTotalHours,
					actualHours,
					actualBillableHours,
					actualNonBillableHours,
					projectedBillableValueOfService,
					baselineHours,
					isEstimatedInHours,
				},
			}}
		>
			{children}
		</TotalsContext.Provider>
	);
};

export const useProjectTotals = () => useContext(TotalsContext);
