import React, {useEffect} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {withSocketHandling} from '../../../../../../socket/withSocketHandling';
import {SOCKET_ACTION, SOCKET_EVENT_TYPE} from '../../../../../../constants';
import DependencySection from './dependency_section';

const DependencySectionQueryContent = React.forwardRef(
	(
		{
			viewer,
			task,
			project,
			isTaskReadOnly,
			isClientUser,
			projectColor,
			expanded,
			addedByUser,
			toggleDependencySection,
			setSocketConfig,
		},
		ref
	) => {
		useEffect(() => {
			if (task && project) {
				const projectIds = parseInt(atob(project.id).replace('ProjectType:', ''));
				const taskId = parseInt(atob(task.id).replace('Task:', ''));
				const socketEvents = [
					{
						type: SOCKET_EVENT_TYPE.PROJECT,
						action: SOCKET_ACTION.DELETE,
						projectIds: projectIds,
					},
					{
						type: SOCKET_EVENT_TYPE.PROJECT,
						action: SOCKET_ACTION.UPDATE,
						projectIds: projectIds,
					},
					{
						type: SOCKET_EVENT_TYPE.TASK,
						action: SOCKET_ACTION.UPDATE,
						taskIds: taskId,
					},
					{
						type: SOCKET_EVENT_TYPE.TASK,
						action: SOCKET_ACTION.DELETE,
						taskIds: taskId,
					},
				];
				setSocketConfig(socketEvents);
			}
		}, []);

		return (
			<DependencySection
				ref={ref}
				task={task}
				project={project}
				projectTasks={viewer.dependencyTask.project.tasks}
				isTaskReadOnly={isTaskReadOnly}
				isClientUser={isClientUser}
				projectColor={projectColor}
				expanded={expanded}
				addedByUser={addedByUser}
				toggleDependencySection={toggleDependencySection}
			/>
		);
	}
);

const DependencySectionQueryContentQuery = graphql`
	query DependencySectionQueryContent_Query($id: String) {
		viewer {
			component(name: "dependency_section")
			actualPersonId
			id
			...DependencySectionQueryContent_viewer @arguments(id: $id)
		}
	}
`;

export {DependencySectionQueryContentQuery};

export default withSocketHandling(
	createFragmentContainer(DependencySectionQueryContent, {
		viewer: graphql`
			fragment DependencySectionQueryContent_viewer on Viewer @argumentDefinitions(id: {type: "String"}) {
				dependencyTask: task(companyTaskId: $id) {
					project {
						tasks(first: 100000) {
							edges {
								node {
									id
									name
									companyTaskId
									approved
									statusColumnV2 {
										id
										name
										category
										projectGroupStatusColumnId
									}
								}
							}
						}
					}
				}
			}
		`,
	})
);
