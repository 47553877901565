/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ResourceManagement_company$ref: FragmentReference;
declare export opaque type ResourceManagement_company$fragmentType: ResourceManagement_company$ref;
export type ResourceManagement_company = {|
  +isUsingSchedulingPlanMode: ?boolean,
  +isUsingProjectAllocation: ?boolean,
  +isUsingMixedAllocation: ?boolean,
  +$refType: ResourceManagement_company$ref,
|};
export type ResourceManagement_company$data = ResourceManagement_company;
export type ResourceManagement_company$key = {
  +$data?: ResourceManagement_company$data,
  +$fragmentRefs: ResourceManagement_company$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ResourceManagement_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isUsingSchedulingPlanMode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isUsingProjectAllocation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isUsingMixedAllocation",
      "storageKey": null
    }
  ],
  "type": "Company"
};
// prettier-ignore
(node/*: any*/).hash = 'e99e2ebe68d047b17c2502dc86370e9b';

module.exports = node;
