import React from 'react';
import { useIntl } from 'react-intl';
import Util from '../../../../shared/util/util.js';
import { StyledTime } from './checkmark_time_styled.js';
function TimeRegistrationDayTotals({ minutesRegistered, billableMinutesRegistered, }) {
    const intl = useIntl();
    const registeredTimeInHours = Util.convertMinutesToFullHour(minutesRegistered, intl, true);
    const billableRegisteredTimeInHours = Util.convertMinutesToFullHour(billableMinutesRegistered, intl, true);
    return (React.createElement(StyledTime, null, minutesRegistered ? (React.createElement("div", { style: { display: 'flex', flexDirection: 'column', gap: '4px', flexGrow: 1, justifyContent: 'center' } },
        React.createElement("p", { style: { width: '100%', lineHeight: '14px' } }, registeredTimeInHours),
        React.createElement("p", { style: { width: '100%', lineHeight: '14px', color: '#727483' } }, billableMinutesRegistered !== minutesRegistered ? `${billableRegisteredTimeInHours} bil.` : ''))) : ('')));
}
export default TimeRegistrationDayTotals;
