import { format } from 'date-fns';
import React from 'react';
import ForecastQueryRenderer from '../../../../../../../ForecastQueryRenderer';
import TimeRegistrationModalTimeOnDay, { TimeRegistrationModalTimeOnDayPlaceholder, TimeRegistrationModalTimeOnDayQuery, } from '../modal-content/TimeRegistrationModalTimeOnDay';
function TimeRegistrationModalTimeOnDayQueryWrapper({ personId, date, }) {
    return (React.createElement(ForecastQueryRenderer, { key: "query-render-time_reg_modal_timer", query: TimeRegistrationModalTimeOnDayQuery, variables: { personId, date: format(date, 'yyyy-MM-dd') }, showLoader: true, customLoader: () => React.createElement(TimeRegistrationModalTimeOnDayPlaceholder, null), render: (relayProps) => {
            return React.createElement(TimeRegistrationModalTimeOnDay, Object.assign({}, relayProps, { date: date }));
        } }));
}
export default TimeRegistrationModalTimeOnDayQueryWrapper;
