/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type labelRow_company$ref = any;
type newLabelRow_company$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type labelCategoryRow_company$ref: FragmentReference;
declare export opaque type labelCategoryRow_company$fragmentType: labelCategoryRow_company$ref;
export type labelCategoryRow_company = {|
  +id: string,
  +$fragmentRefs: labelRow_company$ref & newLabelRow_company$ref,
  +$refType: labelCategoryRow_company$ref,
|};
export type labelCategoryRow_company$data = labelCategoryRow_company;
export type labelCategoryRow_company$key = {
  +$data?: labelCategoryRow_company$data,
  +$fragmentRefs: labelCategoryRow_company$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "labelCategoryRow_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "labelRow_company"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "newLabelRow_company"
    }
  ],
  "type": "Company"
};
// prettier-ignore
(node/*: any*/).hash = '119da035b0d473b907b0149f236e0faa';

module.exports = node;
