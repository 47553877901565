import Styled from 'styled-components/macro';
import React from 'react';

const BackgroundStyle = Styled.div`
		margin-top: 24px;
		width: 600px;
		height: 1000px;
		display: flex;    
		flex-direction: column;
		position: relative;
    top: 0px;
    left: 0px;
    z-index: 1;	    
	`;

const RowContainer = Styled.div`
   display:flex; 
`;
const Divider = Styled.div`
		width: 500px;
  	margin-left: 20px;
  	padding-left: 20px;
  	height: 44px;
    border-top: ${props => (props.index === 0 ? '1px solid #e6e6e6' : 'none')};
    border-bottom: 1px solid #e6e6e6;
   
`;
const IndexColumn = Styled.div`
		width: 44px;
	`;

const PrioritiesColumn = Styled.div`
	width: 500px;
	`;

const PriorityIndex = Styled.div`
   background-color: #f5f5f5;       
	 text-align: center;
	 line-height: 42px;
   width: 44px;
   height: 42px;  	    	    
`;

export const Background = ({rows}) => {
	return (
		<BackgroundStyle>
			{[...Array(rows).keys()].map(index => (
				<RowContainer key={index}>
					<IndexColumn>
						<PriorityIndex>{index + 1}</PriorityIndex>
					</IndexColumn>
					<PrioritiesColumn>
						<Divider index={index} />
					</PrioritiesColumn>
				</RowContainer>
			))}
		</BackgroundStyle>
	);
};
