export const getDefaultRole = (existingRole, projectPersonRole, taskRole, companyRole) => {
    if (existingRole) {
        return existingRole;
    }
    if (projectPersonRole) {
        return projectPersonRole;
    }
    if (taskRole) {
        return taskRole;
    }
    if (companyRole) {
        return companyRole;
    }
    return null;
};
