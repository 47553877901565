import { FlexColumn, Link, Placeholder } from '@forecast-it/design-system';
import { CalendarEvent } from './CalendarEvent';
import React, { useEffect, useState } from 'react';
import { trackEvent } from '../../../../../tracking/amplitude/TrackingV2';
import { getCalendarEventSize } from './CalendarEventUtil';
import { dispatch, EVENT_ID, subscribe, unsubscribe } from '../../../../../containers/event_manager';
import { LinkWrapper } from '../TimesheetsCalendar_styled';
export const CalendarEvents = ({ calendarEvents, isEventConverted, renderPlaceholders, isLocked, openModal, showContextMenu, }) => {
    const defaultVisibleEvents = 4;
    const maxEventSizeTotal = 228;
    const [visibleEvents, setVisibleEvents] = useState(defaultVisibleEvents);
    const visibleCalendarEvents = calendarEvents === null || calendarEvents === void 0 ? void 0 : calendarEvents.slice(0, visibleEvents);
    const numberOfHiddenEvents = (calendarEvents === null || calendarEvents === void 0 ? void 0 : calendarEvents.length) - visibleEvents;
    const [totalEventSizeLargerThanMax, setTotalEventSizeLargerThanMax] = useState(false);
    const showShowLessButton = (calendarEvents === null || calendarEvents === void 0 ? void 0 : calendarEvents.length) > 0 && totalEventSizeLargerThanMax && numberOfHiddenEvents === 0;
    const hideEvents = () => {
        if (calendarEvents) {
            let eventSizeTotal = 0;
            let numOfVisibleItems = 0;
            for (let i = 0; i < calendarEvents.length; i++) {
                const event = calendarEvents[i];
                eventSizeTotal += getCalendarEventSize(event);
                if (eventSizeTotal > maxEventSizeTotal) {
                    setTotalEventSizeLargerThanMax(true);
                    break;
                }
                numOfVisibleItems++;
            }
            setVisibleEvents(numOfVisibleItems);
        }
        else {
            setVisibleEvents(defaultVisibleEvents);
        }
    };
    const handleShowMore = (args) => {
        setVisibleEvents(visibleEvents + args.number);
    };
    const handleShowLess = () => {
        hideEvents();
    };
    useEffect(() => {
        subscribe(EVENT_ID.CALENDAR_EVENTS_SHOW_MORE, handleShowMore);
        return () => {
            unsubscribe(EVENT_ID.CALENDAR_EVENTS_SHOW_MORE, handleShowMore);
        };
    }, [handleShowMore]);
    useEffect(() => {
        subscribe(EVENT_ID.CALENDAR_EVENTS_SHOW_LESS, handleShowLess);
        return () => {
            unsubscribe(EVENT_ID.CALENDAR_EVENTS_SHOW_LESS, handleShowLess);
        };
    }, [handleShowLess]);
    const showLess = (e) => {
        e.stopPropagation();
        trackEvent('Show Less Events Link', 'Clicked');
        dispatch(EVENT_ID.CALENDAR_EVENTS_SHOW_LESS);
    };
    const showMoreEvents = (e, number) => {
        e.stopPropagation();
        trackEvent('Show More Events Link', 'Clicked', { totalEvents: calendarEvents === null || calendarEvents === void 0 ? void 0 : calendarEvents.length });
        dispatch(EVENT_ID.CALENDAR_EVENTS_SHOW_MORE, { number: number });
    };
    useEffect(() => {
        hideEvents();
    }, [calendarEvents === null || calendarEvents === void 0 ? void 0 : calendarEvents.length]);
    return (React.createElement(React.Fragment, null,
        React.createElement(FlexColumn, { gap: "none" }, (visibleCalendarEvents === null || visibleCalendarEvents === void 0 ? void 0 : visibleCalendarEvents.length) ? (visibleCalendarEvents.map(event => (React.createElement(CalendarEvent, { key: 'calendar-event-key' + (event === null || event === void 0 ? void 0 : event.id), event: event, isLocked: isLocked, isConverted: isEventConverted(event === null || event === void 0 ? void 0 : event.id), openModal: openModal, showContextMenu: showContextMenu })))) : renderPlaceholders ? (React.createElement(React.Fragment, null, [...Array(4)].map((value, index) => (React.createElement(Placeholder, { key: 'placeholder-key-' + index, height: '52px' }))))) : null),
        numberOfHiddenEvents > 0 && (React.createElement(LinkWrapper, null,
            React.createElement(Link, { size: '2', onClick: e => showMoreEvents(e, numberOfHiddenEvents) },
                "Show ",
                numberOfHiddenEvents,
                " more"))),
        !!showShowLessButton && (React.createElement(LinkWrapper, null,
            React.createElement(Link, { size: '2', onClick: e => showLess(e) }, "Show less")))));
};
