import React, {Component} from 'react';
import PropTypes from 'prop-types';

class Switch extends Component {
	onChange(e) {
		e.stopPropagation();
		if (this.props.onChange) {
			this.props.onChange(!this.props.checked);
		}
	}

	keyPressHandler(e) {
		e.preventDefault();
		if (e.key === 'Enter' || e.key === ' ') this.onChange(e);
	}

	render() {
		const width = this.props.sliderWidth ? this.props.sliderWidth : 50;
		return (
			<div className={'switch-wrapper' + (this.props.white ? ' white' : '')}>
				{this.props.header ? <div className={'switch-label'}> {this.props.header} </div> : null}
				<label
					style={{height: this.props.sliderHeight, width: width}}
					className={
						(this.props.checked ? 'switch-container toggled-on' : 'switch-container') +
						(this.props.locked ? ' locked-switch' : '')
					}
					tabIndex={0}
					onKeyPress={this.keyPressHandler.bind(this)}
					onBlur={this.props.onBlur}
					data-cy={'switch-slider-label' + (this.props.cy ? '-' + this.props.cy : '')}
				>
					<input
						data-cy={'switch-slider' + (this.props.cy ? '-' + this.props.cy : '')}
						style={{height: this.props.sliderHeight}}
						type="checkbox"
						className="switch-box"
						disabled={this.props.locked}
						onChange={this.onChange.bind(this)}
						checked={this.props.checked}
						id={this.props.id}
					/>
					<span
						style={{height: this.props.sliderHeight * (3 / 5), width: this.props.sliderHeight * (3 / 5)}}
						className="switch-slider"
					>
						{' '}
					</span>
				</label>
			</div>
		);
	}
}
Switch.propTypes = {
	onChange: PropTypes.func,
	checked: PropTypes.bool.isRequired,
	locked: PropTypes.bool,
	id: PropTypes.string,
	sliderHeight: PropTypes.number,
	sliderWidth: PropTypes.number,
};
export default Switch;
