export const getPopupPosition = (origin, popup) => {
	let arrowPositionValues = {
		left: false,
		right: false,
		bottom: false,
		top: false,
	};

	let popupPositionValues = {};
	//check if the popup is going out of the screen update positions
	//check if there is not enough space on the right
	if (origin.left + origin.width / 2 + popup.width / 2 > window.innerWidth) {
		arrowPositionValues.right = true;
		popupPositionValues.left = origin.left - popup.width - 15;
		//check if popup fits vertically
		if (window.innerHeight - origin.bottom > popup.height / 2) {
			popupPositionValues.bottom = window.innerHeight - origin.bottom + origin.height / 2 - popup.height / 2;
		} else {
			arrowPositionValues.bottom = true;
			popupPositionValues.bottom = window.innerHeight - origin.bottom - 5;
		}
	}
	//check if there is not enough room on the left
	else if (origin.left - popup.width / 2 < 0) {
		arrowPositionValues.left = true;
		popupPositionValues.left = origin.left + origin.width + 15;
		//check if popup fits vertically
		if (window.innerHeight - origin.bottom > popup.height / 2) {
			popupPositionValues.bottom = window.innerHeight - origin.bottom + origin.height / 2 - popup.height / 2;
		} else {
			arrowPositionValues.bottom = true;
			popupPositionValues.bottom = window.innerHeight - origin.bottom;
		}
	}
	//check if there is room on the left and on the right
	else {
		popupPositionValues.left = origin.left + origin.width / 2 - popup.width / 2;
		//check if popup fits vertically
		if (window.innerHeight - origin.bottom > popup.height + 15) {
			popupPositionValues.bottom = window.innerHeight - origin.bottom - popup.height - 15;
		} else {
			arrowPositionValues.bottom = true;
			popupPositionValues.bottom = window.innerHeight - origin.bottom + origin.height + 15;
		}
	}
	if (popupPositionValues.bottom > window.innerHeight - popup.height - 5) {
		popupPositionValues.bottom = window.innerHeight - popup.height - 5;
		arrowPositionValues.bottom = false;

		if (origin.bottom > popup.height + 15) {
			arrowPositionValues.topPixels = origin.top + 5;
		} else {
			arrowPositionValues.hidden = true;
		}
	}
	return {popupPositionValues: popupPositionValues, arrowPositionValues: arrowPositionValues};
};
