import React, {useEffect} from 'react';
import {createPaginationContainer, graphql} from 'react-relay';
import * as tracking from '../../../../../../tracking';
import {useIntl} from 'react-intl';
import TimeRegFormatter from '../../../../util/export-formatters/TimeRegFormatter';
import {useDataExport} from '../../../../hooks/useDataExport';
import {trackCSVExport} from '../../../../../../tracking/amplitude/TrackingV2';

const ReportedTimeCSVModal = ({viewer, relay, enabledColumns, closeModal}) => {
	const intl = useIntl();
	let visibleColumns = Object.keys(enabledColumns).filter(columnName => enabledColumns[columnName]);
	visibleColumns.unshift('projectId');

	const exportData = useDataExport(
		new TimeRegFormatter(intl, ['project_id'], ['chip-right', 'selector', 'customField_']),
		null,
		data => data,
		_ => 'time_reg'
	);

	const handleCsvDownloadPress = () => {
		tracking.trackEvent('download reported time');
		trackCSVExport('Reported Time');

		const availableColumns = Object.keys(enabledColumns).map(column => {
			return {name: column, checked: enabledColumns[column]};
		});

		exportData(
			availableColumns,
			viewer.company.timeRegistrations.edges.map(timeReg => timeReg.node),
			null,
			null,
			'CSV'
		);
	};

	useEffect(() => {
		if (viewer) {
			if (viewer.company.timeRegistrations.pageInfo.hasNextPage) {
				relay.loadMore(50000);
			} else {
				handleCsvDownloadPress();
				closeModal();
			}
		}
	}, []);

	return (
		<div>
			{intl.formatMessage(
				{id: 'common.time_regs_fetched_so_far'},
				{count: viewer.company.timeRegistrations?.edges.length}
			)}
		</div>
	);
};

const ReportedTimeCSVModalQuery = graphql`
	query ReportedTimeCSVModal_Query(
		$searchQuery: TaskSearchQueryType
		$pageSize: Int
		$cursor: String
		$validateAccess: Boolean
	) {
		viewer {
			actualPersonId
			component(name: "reported_time_CSV")
			...ReportedTimeCSVModal_viewer
				@arguments(searchQuery: $searchQuery, pageSize: $pageSize, cursor: $cursor, validateAccess: $validateAccess)
		}
	}
`;

export {ReportedTimeCSVModalQuery};
export default createPaginationContainer(
	ReportedTimeCSVModal,
	{
		viewer: graphql`
			fragment ReportedTimeCSVModal_viewer on Viewer
			@argumentDefinitions(
				searchQuery: {type: "TaskSearchQueryType"}
				pageSize: {type: "Int"}
				cursor: {type: "String"}
				validateAccess: {type: "Boolean"}
			) {
				id
				company {
					currency
					timeRegistrations(
						first: $pageSize
						after: $cursor
						searchQuery: $searchQuery
						sortValue: ""
						validateAccess: $validateAccess
					) @connection(key: "Company_timeRegistrations", filters: []) {
						pageInfo {
							endCursor
							hasNextPage
						}
						edges {
							node {
								day
								month
								year
								notes
								billable
								invoiced
								approvalStatus
								person {
									id
									fullName
									role {
										name
									}
								}
								minutesRegistered
								billableMinutesRegistered
								task {
									name
									role {
										name
									}
									project {
										name
										fullAccessToProject
										companyProjectId
										customProjectId
										client {
											name
										}
									}
									phase {
										name
									}
								}
								project {
									name
									fullAccessToProject
									companyProjectId
									customProjectId
									client {
										name
									}
								}
								idleTime {
									isInternalTime
									name
								}
								priceBaseCurrency
								cost
								customFieldValues {
									edges {
										node {
											id
											key
											value
											displayName
										}
									}
								}
							}
						}
					}
				}
			}
		`,
	},
	{
		direction: 'forward',
		getConnectionFromProps({viewer}) {
			return viewer.company.timeRegistrations;
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				pageSize: totalCount,
			};
		},
		getVariables(_, {count, cursor}, fragmentVariables) {
			return {
				...fragmentVariables,
				pageSize: count,
				cursor,
			};
		},
		query: graphql`
			query ReportedTimeCSVModalRefetchQuery(
				$searchQuery: TaskSearchQueryType
				$pageSize: Int
				$cursor: String
				$validateAccess: Boolean
			) {
				viewer {
					component(name: "reported_time_CSV")
					...ReportedTimeCSVModal_viewer
						@arguments(
							searchQuery: $searchQuery
							pageSize: $pageSize
							cursor: $cursor
							validateAccess: $validateAccess
						)
				}
			}
		`,
	}
);
