import DirectApi from '../../../../../../directApi';

export function fetchHarvestTasks(
	project,
	harvestLoading,
	harvestTasks,
	harvestProjectLoaded,
	setHarvestLoading,
	setHarvestProjectLoaded,
	setHarvestTasks
) {
	//If project dropdown is not empty
	if (project) {
		//If a project is selected, show only tasks that belong to that project
		if (project.id && project.harvestProject) {
			if (harvestLoading === true) return;
			if (harvestTasks.length === 0 || harvestProjectLoaded !== project.id) setHarvestLoading(true);
			setHarvestProjectLoaded(project.id);
			DirectApi.Fetch('harvest_tasks/' + project.id).then(tasks => {
				setHarvestTasks(tasks);
				setHarvestLoading(false);
			});
		} else {
			setHarvestTasks([]);
			setHarvestLoading(false);
		}
	} else {
		setHarvestTasks([]);
		setHarvestLoading(false);
	}
}

export function fetchUnit4Activities(
	project,
	unit4Loading,
	unit4Activities,
	unit4ProjectLoaded,
	setUnit4Loading,
	setUnit4ProjectLoaded,
	setUnit4Activities
) {
	//If project dropdown is not empty
	if (project) {
		//If a project is selected, show only tasks that belong to that project
		if (project.id && project.unit4Project) {
			if (unit4Loading === true) return;
			if (unit4Activities.length === 0 || unit4ProjectLoaded !== project.id) {
				setUnit4Loading(true);
			}
			setUnit4ProjectLoaded(project.id);
			DirectApi.Fetch('unit4_activities/' + project.id).then(activities => {
				setUnit4Activities(activities);
				setUnit4Loading(false);
			});
		} else {
			setUnit4Activities([]);
			setUnit4Loading(false);
		}
	} else {
		setUnit4Activities([]);
		setUnit4Loading(false);
	}
}

export function getIsHarvestLineItem(item) {
	return item.task
		? item.task.project.harvestProject != null
		: item.project
		? item.project.harvestProject != null
		: item.harvestProject;
}

export function getIsUnit4LineItem(item) {
	return item.task
		? item.task.project.unit4Project != null && item.task.project.unit4Project.activitiesEnabled
		: item.project
		? item.project.unit4Project != null && item.project.unit4Project.activitiesEnabled
		: item.unit4Project && item.unit4Project.activitiesEnabled;
}

export function shouldDisableHarvestPopup(isWeekView, lineItem, isDoneOrHalted) {
	const isHarvestReg = isWeekView
		? lineItem.harvestProject || (lineItem.project && lineItem.project.harvestProject)
		: lineItem.harvestTaskIdInt;
	return (
		!isHarvestReg ||
		(lineItem.type === 'task' && !lineItem.task.fullAccessToProject) ||
		isDoneOrHalted(lineItem.task ? lineItem.task.project : lineItem.project ? lineItem.project : null)
	);
}

export function shouldDisableUnit4Popup(isWeekView, lineItem, isDoneOrHalted) {
	const isUnit4Reg = isWeekView
		? lineItem.unit4Project || (lineItem.project && lineItem.project.unit4Project)
		: lineItem.unit4ActivityId ||
		  (lineItem.task &&
				lineItem.task.project &&
				lineItem.task.project.unit4Project &&
				lineItem.task.project.unit4Project.activitiesEnabled);

	return (
		!isUnit4Reg ||
		(lineItem.type === 'task' && !lineItem.task.fullAccessToProject) ||
		isDoneOrHalted(lineItem.task ? lineItem.task.project : lineItem.project ? lineItem.project : null)
	);
}
