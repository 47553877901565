import Styled from 'styled-components';
import {CSS_CONSTANTS} from '../../../../../../css_variables';

export const AvailabilityTooltipContent = Styled.div`
  display: grid;
  grid-template-columns: auto auto;
   
  font-weight: 400;
  font-size: 13px;
  color: ${CSS_CONSTANTS.v2_text_gray};
  padding: 5px;
`;

export const AvailabilityTooltipItemTitle = Styled.div`
	width: 100%;
	margin-right: 15px;
`;

export const AvailabilityTooltipItemValue = Styled.div`
	font-weight: ${props => (props.bold ? 700 : 400)};
	color: ${props => (props.isOverAllocated ? CSS_CONSTANTS.color_red_500 : CSS_CONSTANTS.v2_text_gray)};
	margin-left: 5px;
`;
