import { graphql } from 'react-relay';
export const CalendarEventsQuery = graphql `
	query CalendarEventsFetch_Query($start: String, $end: String, $timezone: String) {
		viewer {
			id
			component(name: "google_calendar_events")
			calendarIntegrations {
				calendarEvents(start: $start, end: $end, timezone: $timezone) {
					edges {
						node {
							id
							name
							description
							start
							end
							calendarTimeZone
							allDay
							calendarId
							foregroundColor
							backgroundColor
							attendees
						}
					}
				}
			}
		}
	}
`;
