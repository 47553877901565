/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
query TimeRegistrationModalTimer_Query {
  viewer {
    actualPersonId
    component(name: "time_registration_modal_timer")
    ...TimeRegistrationModalTimer_viewer
    id
  }
}

fragment TimeRegistrationModalTimer_viewer on Viewer {
  actualPersonId
  timerStartDate
  timerEndDate
  timerTask {
    id
  }
}
*/
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actualPersonId",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": [
            {
                "kind": "Literal",
                "name": "name",
                "value": "time_registration_modal_timer"
            }
        ],
        "kind": "ScalarField",
        "name": "component",
        "storageKey": "component(name:\"time_registration_modal_timer\")"
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "TimeRegistrationModalTimer_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        (v1 /*: any*/),
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "TimeRegistrationModalTimer_viewer"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "TimeRegistrationModalTimer_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        (v1 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "timerStartDate",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "timerEndDate",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Task",
                            "kind": "LinkedField",
                            "name": "timerTask",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "TimeRegistrationModalTimer_Query",
            "operationKind": "query",
            "text": "query TimeRegistrationModalTimer_Query {\n  viewer {\n    actualPersonId\n    component(name: \"time_registration_modal_timer\")\n    ...TimeRegistrationModalTimer_viewer\n    id\n  }\n}\n\nfragment TimeRegistrationModalTimer_viewer on Viewer {\n  actualPersonId\n  timerStartDate\n  timerEndDate\n  timerTask {\n    id\n  }\n}\n"
        }
    };
})();
node.hash = '8076ce8bdc86f57f3d991c50289c4981';
export default node;
