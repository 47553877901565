// @ts-check
import {DeprecatedText as Text} from '@forecast-it/design-system';
import React from 'react';
import GenericColorIndicatorSlideout from '../../../../../shared/components/slideout/GenericColorIndicatorSlideout';
import {toggleSlideout} from '../../../../../shared/components/slideout/SlideoutConductor';
import {SLIDEOUT_TYPE} from '../../../../../shared/components/slideout/SLIDEOUT_TYPE';
import {convertStatusColorToRagStatus, RAG_STATUS} from '../../../../../shared/components/status/StatusWrapper';

import ProjectInsightTable from './table/ProjectInsightTable';

const getLatestStatusColor = project => {
	const statusList = project.status?.edges?.length > 0 ? project.status.edges.map(status => status.node) : [];
	statusList.sort((a, b) => new Date(b.addedAt).getTime() - new Date(a.addedAt).getTime());
	return statusList.length > 0 && statusList[0].color;
};

const SuggestedStatusSlideout = ({program, projects}) => {
	const {projectHealthResponses} = program.projectHealthRollup;

	const tableProjects = projects.map(project => {
		const projectHealth = projectHealthResponses.find(
			projectHealth => projectHealth.projectId === project.serviceCompanyId
		);
		const ragStatus = projectHealth?.suggestedStatusRag;
		return {
			id: project.id,
			name: project.name,
			prefix: project.prefix,
			color: project.color,
			insight: {
				modalHash: 'suggested-status-program',
				ragStatusIndex: Object.keys(RAG_STATUS).indexOf(ragStatus),
				ragStatus: ragStatus,
				latestProjectStatus: convertStatusColorToRagStatus(getLatestStatusColor(project)),
			},
		};
	});

	const filteredTableProjects = tableProjects.filter(
		project => project.insight.ragStatus && project.insight.latestProjectStatus !== project.insight.ragStatus
	);

	return (
		<GenericColorIndicatorSlideout name={program.name} color={program.color} isMultiIndicator>
			<Text type={'heading'} variant={'m'}>
				Statuses
			</Text>
			{filteredTableProjects.length > 0 ? (
				<ProjectInsightTable tableProjects={filteredTableProjects} isStatusTable></ProjectInsightTable>
			) : (
				<div style={{marginTop: '16px'}}>
					<Text>All projects are up to date!</Text>
				</div>
			)}
		</GenericColorIndicatorSlideout>
	);
};

export const toggleSuggestedStatusSlideout = ({program, projects, invokerRef}) => {
	toggleSlideout(SLIDEOUT_TYPE.SUGGESTED_STATUS_INSIGHT, {
		content: <SuggestedStatusSlideout program={program} projects={projects} />,
		width: 750,
		trackingName: 'Suggested Status Slideout',
		invokerRef,
	});
};
