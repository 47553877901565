import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';
import {WorkflowCategories} from '../../../../constants';
import ForecastTooltip from '../../../shared/components/tooltips/ForecastTooltip';

class StatusColumnCategorySelector extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: props.expandByDefault,
			value: this.props.value,
			preselect: '',
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.value !== this.props.value) {
			this.setState({value: nextProps.value});
		}
		if (nextProps.expandByDefault && !this.props.expandByDefault) {
			this.toggleSelectMenu();
		}
	}

	toggleSelectMenu() {
		if (this.props.locked) {
			this.button.blur();
		} else {
			this.button.focus();
			this.setState({expanded: !this.state.expanded});
		}
	}

	onChange(selected, key) {
		const letter = /^[a-zA-Z0-9]/.test(key) ? key : '';
		this.setState({value: selected});
		this.props.onChange(selected);

		/* If this is a new column, focus the name field after the category has
		 * been chosen. A small timeout is added to make sure that the focus
		 * is not removed by rerendering after the mutation returns
		 */
		if (this.props.expandByDefault) {
			setTimeout(() => {
				this.props.focusNameInput(letter);
			}, 200);
		}
	}

	onPreselect(option) {
		this.setState({
			preselect: option,
		});
	}

	onKeyDown(option, e) {
		if (this.state.expanded) {
			const states = [WorkflowCategories.TODO, WorkflowCategories.INPROGRESS, WorkflowCategories.DONE];
			if (e.keyCode === 32) {
				// space
			} else if (e.keyCode === 27) {
				// Escape
				this.setState({expanded: false});
			} else if (e.keyCode === 13 && option) {
				//Enter
				this.onChange(option);
				this.button.blur();
			} else if (e.keyCode === 38) {
				//up
				let next;
				switch (option) {
					case states[0]:
						next = states[2];
						break;
					case states[2]:
						next = states[1];
						break;
					case states[1]:
						next = states[0];
						break;
					default:
						next = this.state.value;
						break;
				}
				this.onPreselect(next);
			} else if (e.keyCode === 40) {
				//down
				let next;
				switch (option) {
					case states[0]:
						next = states[1];
						break;
					case states[2]:
						next = states[0];
						break;
					case states[1]:
						next = states[2];
						break;
					default:
						next = this.state.value;
						break;
				}
				this.onPreselect(next);
			} else {
				//not elseif to keep shift, capslock, ....
				if (
					/^[a-zA-Z0-9]/.test(e.key) &&
					((e.keyCode >= 48 && e.keyCode <= 57) ||
						(e.keyCode >= 65 && e.keyCode <= 90) ||
						(e.keyCode >= 96 && e.keyCode <= 105))
				) {
					this.onChange(this.props.value, e.key);
				}
			}
		}
	}

	cleanPreselect() {
		if (this.state.preselect !== '') {
			this.setState({preselect: ''});
		}
	}

	onBlur() {
		this.setState({expanded: false, preselect: ''});
	}

	workflowCategories() {
		const {formatMessage} = this.props.intl;
		switch (this.state.value) {
			case WorkflowCategories.TODO:
				return formatMessage({id: 'common.to_do'});
			case WorkflowCategories.INPROGRESS:
				return formatMessage({id: 'common.in_progress'});
			case WorkflowCategories.DONE:
				return formatMessage({id: 'project_settings.workflow-done'});
		}
	}

	render() {
		const {formatMessage} = this.props.intl;
		return (
			<div
				className={
					'status-column-category-selector-wrapper-v2' +
					(this.props.useNewStyling ? ' new ' : '') +
					(this.props.locked ? ' disabled' : '')
				}
				onBlur={this.onBlur.bind(this)}
				data-userpilot={this.props.userpilot}
			>
				<ForecastTooltip
					content={
						!this.state.expanded &&
						!this.props.projectLocked &&
						(!this.props.canEditWorkflow
							? this.workflowCategories()
							: this.props.isInProjectGroup
							? formatMessage({id: 'common.connected_project_no_change'}, {functionality: 'change column status'})
							: this.props.locked
							? formatMessage(
									{id: 'status_column_category_selector.disabled_tooltip'},
									{category: this.state.value}
							  )
							: formatMessage({id: 'status_column_category_selector.tooltip'}))
					}
					spanStyle={{display: 'flex'}}
				>
					<button
						ref={btn => (this.button = btn)}
						className={
							'category-icon ' +
							(this.props.useNewStyling ? 'new ' : '') +
							this.state.value +
							(this.props.useNewStyling ? '-NEW' : '') +
							(this.props.locked ? ' locked' : '')
						}
						onClick={this.toggleSelectMenu.bind(this)}
						autoFocus={this.props.expandByDefault}
						onKeyDown={this.onKeyDown.bind(this, this.state.preselect)}
						onBlur={this.onBlur.bind(this)}
						disabled={this.props.locked}
					/>
				</ForecastTooltip>
				{this.state.expanded ? (
					<ul
						className={'category-options-list' + (this.props.useNewStyling ? '-new' : '')}
						onMouseEnter={this.cleanPreselect.bind(this)}
					>
						<li
							role="option"
							tabIndex="0"
							className={
								'category-option' +
								(this.state.value === WorkflowCategories.TODO ? ' selected' : '') +
								(this.state.preselect === WorkflowCategories.TODO ? ' preselected' : '')
							}
							onMouseDown={this.onChange.bind(this, WorkflowCategories.TODO)}
						>
							<div className={'category-icon TODO' + (this.props.useNewStyling ? '-NEW' : '')} />
							<FormattedMessage id="common.to_do" />
						</li>
						<li
							role="option"
							tabIndex="1"
							className={
								'category-option' +
								(this.state.value === WorkflowCategories.INPROGRESS ? ' selected' : '') +
								(this.state.preselect === WorkflowCategories.INPROGRESS ? ' preselected' : '')
							}
							onMouseDown={this.onChange.bind(this, WorkflowCategories.INPROGRESS)}
						>
							<div className={'category-icon INPROGRESS' + (this.props.useNewStyling ? '-NEW' : '')} />
							<FormattedMessage id="common.in_progress" />
						</li>
						<li
							role="option"
							tabIndex="2"
							className={
								'category-option' +
								(this.state.value === WorkflowCategories.DONE ? ' selected' : '') +
								(this.state.preselect === WorkflowCategories.DONE ? ' preselected' : '')
							}
							onMouseDown={this.onChange.bind(this, WorkflowCategories.DONE)}
						>
							<div className={'category-icon DONE' + (this.props.useNewStyling ? '-NEW' : '')} />
							<FormattedMessage id="project_settings.workflow-done" />
						</li>
					</ul>
				) : null}
			</div>
		);
	}
}

StatusColumnCategorySelector.propTypes = {
	expandByDefault: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired,
	locked: PropTypes.bool,
};

export default injectIntl(StatusColumnCategorySelector);
