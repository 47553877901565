import React from 'react';
import { ForecastTooltipFormulaRenderer } from '../../../../../../components/ForecastTooltipFormulaRenderer';
import { ValueCell } from '../../../../../../components/initial-plan/ValueCell';
import ForecastTooltip from '../../../../../shared/components/tooltips/ForecastTooltip';
import { remapId } from '../../../../../shared/util/FinancialInternationalisationUtil';
import { Data, HorizontalBar, Title, ValueContainer } from '../ProjectScopingTotals_styled';
import { ProjectScopingTotalsTooltips } from '../ProjectScopingTotalsTooltips';
import { AvailabilityTile } from './AvailabilityTile';
import { Tile, TileRow } from './Tile_styled';
import { TileVariant } from './TotalsTypes';
const DatesRow = ({ totalsData, intl }) => {
    return (React.createElement(TileRow, null,
        React.createElement(Title, null, intl.formatMessage({ id: 'project_overview.project_dates' })),
        React.createElement(Data, { small: true }, `${totalsData.startDateString} - ${totalsData.endDateString}`)));
};
const ProgressRow = ({ projectData, totalsData, intl, }) => {
    return (React.createElement(TileRow, null,
        React.createElement(Title, null, intl.formatMessage({
            id: (projectData === null || projectData === void 0 ? void 0 : projectData.manualProgressOnProjectEnabled)
                ? 'common.progress'
                : 'project_scoping.estimated_project_progress',
        })),
        React.createElement(Data, { small: true },
            React.createElement(ValueCell, { noJustify: true, type: ValueCell.VALUE_TYPE.PERCENTAGE, value: totalsData.progress }))));
};
const FixedPriceRow = ({ totalsData, projectData, isFixedPriceV2, settings, intl, }) => {
    var _a;
    if (isFixedPriceV2) {
        return (React.createElement(TileRow, null,
            React.createElement(Title, { singleLine: true }, intl.formatMessage({ id: remapId('project_budget.fixed_price') })),
            React.createElement(Data, { small: true },
                React.createElement(ValueCell, { noJustify: true, type: ValueCell.VALUE_TYPE.PRICE, value: (projectData === null || projectData === void 0 ? void 0 : projectData.budget) || 0, currencySymbol: settings.currencySymbol }))));
    }
    else if (!settings.isPointsProject && ((_a = settings.financialSourceSettings) === null || _a === void 0 ? void 0 : _a.plannedRevenue) === 'TASK') {
        return (React.createElement(TileRow, null,
            React.createElement(Title, { singleLine: true }, intl.formatMessage({
                id: remapId('project_scoping.total_planned_billable_time'),
            })),
            React.createElement(Data, { small: true },
                React.createElement(ValueCell, { noJustify: true, type: ValueCell.VALUE_TYPE.PRICE, value: totalsData.plannedRevenue, currencySymbol: settings.currencySymbol }))));
    }
    return null;
};
const ActualPriceRow = ({ totalsData, hasFinancialServiceInScoping, settings, intl, }) => {
    var _a, _b;
    if (hasFinancialServiceInScoping) {
        if (((_a = settings.financialSourceSettings) === null || _a === void 0 ? void 0 : _a.actualRevenue) === 'TASK' &&
            ((_b = settings.financialSourceSettings) === null || _b === void 0 ? void 0 : _b.forecastRevenue) === 'TASK') {
            return (React.createElement(TileRow, null,
                React.createElement(Title, { singleLine: true }, intl.formatMessage({
                    id: 'project_budget.total_value_of_service_at_completion',
                })),
                React.createElement(Data, { small: true },
                    React.createElement(ForecastTooltip, { placement: 'bottom', maxWidth: '350px', content: React.createElement(ForecastTooltipFormulaRenderer, { items: [
                                {
                                    title: intl.formatMessage({
                                        id: 'project_budget.total_value_of_service_at_completion',
                                    }),
                                    details: [
                                        intl.formatMessage({ id: 'common.time' }),
                                        'x',
                                        intl.formatMessage({ id: 'common.rate' }),
                                        '+',
                                        intl.formatMessage({ id: 'common.expenses' }),
                                    ],
                                    footer: intl.formatMessage({
                                        id: 'project_budget.total_value_of_service_at_completion_tooltip',
                                    }),
                                },
                            ], translatedMessage: true }) },
                        React.createElement(ValueContainer, null,
                            React.createElement(ValueCell, { noJustify: true, type: ValueCell.VALUE_TYPE.PRICE, value: totalsData.allTotalTimeAndExpensesAtCompletion, currencySymbol: settings.currencySymbol }))))));
        }
        return null;
    }
    return (React.createElement(TileRow, null,
        React.createElement(Title, { singleLine: true }, intl.formatMessage({ id: remapId('project_budget.actual_revenue_no_expenses') })),
        React.createElement(Data, { small: true },
            React.createElement(ForecastTooltip, { placement: 'bottom', content: ProjectScopingTotalsTooltips() },
                React.createElement(ValueContainer, null,
                    React.createElement(ValueCell, { noJustify: true, type: ValueCell.VALUE_TYPE.PRICE, value: totalsData.actualRevenue, currencySymbol: settings.currencySymbol }))))));
};
export const DatesAndRevenueTile = ({ totalsData, projectData, showRevenue, isFixedPriceV2, settings, hasFinancialServiceInScoping, availabilityTile, intl, }) => {
    const showAvailabilityTile = availabilityTile === TileVariant.DatesAndRevenue;
    return (React.createElement(Tile, null,
        React.createElement(DatesRow, { totalsData: totalsData, intl: intl }),
        React.createElement(ProgressRow, { totalsData: totalsData, projectData: projectData, intl: intl }),
        showRevenue ? (React.createElement(React.Fragment, null,
            React.createElement(HorizontalBar, null),
            React.createElement(FixedPriceRow, { totalsData: totalsData, projectData: projectData, isFixedPriceV2: isFixedPriceV2, settings: settings, intl: intl }),
            React.createElement(ActualPriceRow, { totalsData: totalsData, settings: settings, hasFinancialServiceInScoping: hasFinancialServiceInScoping, intl: intl }))) : showAvailabilityTile ? (React.createElement(React.Fragment, null,
            React.createElement(HorizontalBar, null),
            React.createElement(AvailabilityTile, { totalsData: totalsData, intl: intl }))) : null));
};
