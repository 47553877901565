/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DeprecatedProjectIndicator_project",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "companyProjectId",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "customProjectId",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "projectColor",
            "storageKey": null
        }
    ],
    "type": "ProjectType"
};
node.hash = '86976a2c02c34c8e1e6236be7edd7f23';
export default node;
