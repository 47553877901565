/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
const node = (function () {
    var v0 = {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": null
    }, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "invoiced",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v7 = [
        (v1 /*: any*/)
    ];
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": {
            "connection": [
                (v0 /*: any*/),
                (v0 /*: any*/)
            ]
        },
        "name": "SettingsFinanceBillTo_viewer",
        "selections": [
            (v1 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": [
                    (v1 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "BillFrom",
                        "kind": "LinkedField",
                        "name": "billFroms",
                        "plural": true,
                        "selections": [
                            (v1 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "integration",
                                "storageKey": null
                            },
                            (v2 /*: any*/),
                            {
                                "alias": "billTos",
                                "args": null,
                                "concreteType": "BillToTypeConnection",
                                "kind": "LinkedField",
                                "name": "__BillFrom_billTos_connection",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "BillToTypeEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "BillTo",
                                                "kind": "LinkedField",
                                                "name": "node",
                                                "plural": false,
                                                "selections": [
                                                    (v1 /*: any*/),
                                                    (v2 /*: any*/),
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "address",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "taxId",
                                                        "storageKey": null
                                                    },
                                                    (v3 /*: any*/),
                                                    (v4 /*: any*/)
                                                ],
                                                "storageKey": null
                                            },
                                            (v5 /*: any*/)
                                        ],
                                        "storageKey": null
                                    },
                                    (v6 /*: any*/)
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": [
                            {
                                "kind": "Literal",
                                "name": "first",
                                "value": 100000
                            }
                        ],
                        "concreteType": "ClientTypeConnection",
                        "kind": "LinkedField",
                        "name": "clients",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "ClientTypeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Client",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                            (v1 /*: any*/),
                                            (v2 /*: any*/),
                                            {
                                                "alias": "clientBillTos",
                                                "args": null,
                                                "concreteType": "ClientBillToTypeConnection",
                                                "kind": "LinkedField",
                                                "name": "__Client_clientBillTos_connection",
                                                "plural": false,
                                                "selections": [
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "ClientBillToTypeEdge",
                                                        "kind": "LinkedField",
                                                        "name": "edges",
                                                        "plural": true,
                                                        "selections": [
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "concreteType": "ClientBillTo",
                                                                "kind": "LinkedField",
                                                                "name": "node",
                                                                "plural": false,
                                                                "selections": [
                                                                    (v1 /*: any*/),
                                                                    {
                                                                        "alias": null,
                                                                        "args": null,
                                                                        "concreteType": "BillFrom",
                                                                        "kind": "LinkedField",
                                                                        "name": "billFrom",
                                                                        "plural": false,
                                                                        "selections": (v7 /*: any*/),
                                                                        "storageKey": null
                                                                    },
                                                                    {
                                                                        "alias": null,
                                                                        "args": null,
                                                                        "concreteType": "BillTo",
                                                                        "kind": "LinkedField",
                                                                        "name": "billTo",
                                                                        "plural": false,
                                                                        "selections": (v7 /*: any*/),
                                                                        "storageKey": null
                                                                    },
                                                                    {
                                                                        "alias": null,
                                                                        "args": null,
                                                                        "kind": "ScalarField",
                                                                        "name": "clientId",
                                                                        "storageKey": null
                                                                    },
                                                                    (v3 /*: any*/),
                                                                    (v4 /*: any*/)
                                                                ],
                                                                "storageKey": null
                                                            },
                                                            (v5 /*: any*/)
                                                        ],
                                                        "storageKey": null
                                                    },
                                                    (v6 /*: any*/)
                                                ],
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": "clients(first:100000)"
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Viewer"
    };
})();
node.hash = '614467a83ebcf550f5991c53ba7e66fb';
export default node;
