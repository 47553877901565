// @ts-check
import {DeprecatedText as Text} from '@forecast-it/design-system';
import React from 'react';
import GenericColorIndicatorSlideout from '../../../../../shared/components/slideout/GenericColorIndicatorSlideout';
import {toggleSlideout} from '../../../../../shared/components/slideout/SlideoutConductor';
import {SLIDEOUT_TYPE} from '../../../../../shared/components/slideout/SLIDEOUT_TYPE';
import {RAG_STATUS} from '../../../../../shared/components/status/StatusWrapper';

import ProjectInsightTable from './table/ProjectInsightTable';

const PredictedEndSlideout = ({program, projects}) => {
	const {projectHealthResponses} = program.projectHealthRollup;

	const tableProjects = projects.map(project => {
		const projectHealth = projectHealthResponses.find(
			projectHealth => projectHealth.projectId === project.serviceCompanyId
		);
		const ragStatus = projectHealth?.predictedEndDateRAGStatus;
		return {
			id: project.id,
			name: project.name,
			prefix: project.prefix,
			color: project.color,
			insight: {
				modalHash: 'predicted-end-program',
				ragStatusIndex: ragStatus ? Object.keys(RAG_STATUS).indexOf(ragStatus) : 99,
				ragStatus: ragStatus,
				info: projectHealth?.predictedEndDateInfo,
				lockText: projectHealth?.predictedEndDateError,
			},
		};
	});

	return (
		<GenericColorIndicatorSlideout name={program.name} color={program.color} isMultiIndicator>
			<Text type={'heading'} variant={'m'}>
				End Dates
			</Text>
			<ProjectInsightTable tableProjects={tableProjects}></ProjectInsightTable>
		</GenericColorIndicatorSlideout>
	);
};

export const togglePredictedEndSlideout = ({program, projects, invokerRef}) => {
	toggleSlideout(SLIDEOUT_TYPE.PREDICTED_END_INSIGHT, {
		content: <PredictedEndSlideout program={program} projects={projects} />,
		width: 750,
		trackingName: 'Predicted End Slideout',
		invokerRef,
	});
};
