import React, {useEffect, useState} from 'react';
import Util from '../../../shared/util/util';
import UpdateCustomFieldValueMutation from '../../../../mutations/update_custom_field_value_mutation';
import {BasicTextInput} from 'web-components';

export const CustomFieldValueInput = ({customFieldKey, value, entityType, entityId}) => {
	const [currentValue, setCurrentValue] = useState(value);

	useEffect(() => {
		setCurrentValue(value);
	}, [value]);

	const handleSave = () => {
		if (value !== currentValue) {
			Util.CommitMutation(UpdateCustomFieldValueMutation, {
				entityId,
				entityType,
				key: customFieldKey,
				value: currentValue,
			});
		}
	};

	return <BasicTextInput value={currentValue} onChange={setCurrentValue} callback={handleSave} />;
};
