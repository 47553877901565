import {DeprecatedText as Text} from '@forecast-it/design-system';
import React from 'react';

const _getValues = (item, pathToValue) => {
	const path = pathToValue ? (Array.isArray(pathToValue) ? pathToValue : pathToValue.split('.')) : [];
	let dir = item;
	while (path.length > 0) {
		const next = path.shift();
		dir = dir ? dir[next] : dir;
		if (Array.isArray(dir)) {
			let tempDir = [];
			dir.forEach(i => {
				tempDir.push(_getValues(i, path));
			});
			dir = tempDir;
		}
	}
	return dir ? (Array.isArray(dir) ? dir : String(dir)) : '';
};

export const searchMultiplePaths = (entities, pathsToFields, searchValue) => {
	let results = [];
	pathsToFields.forEach(path => {
		results.push(
			entities.filter(entity => {
				const value = _getValues(entity, path);
				if (Array.isArray(value)) {
					return value.some(val => val.toLowerCase().includes(searchValue.toLowerCase()));
				} else {
					return value.toLowerCase().includes(searchValue.toLowerCase());
				}
			})
		);
	});
	const uniqueResults = [];
	results.forEach(res => res.forEach(r => !uniqueResults.includes(r) && uniqueResults.push(r)));
	return uniqueResults;
};

const useSearch = () => {
	const _getValue = (item, pathToValue) => {
		const path = pathToValue ? pathToValue.split('.') : [];
		let dir = item;
		while (path.length > 0) {
			const next = path.shift();
			dir = dir ? dir[next] : dir;
		}
		return dir || '';
	};

	const search = (entities, pathToField, searchValue) =>
		entities.filter(entity => _getValue(entity, pathToField).toLowerCase().includes(searchValue.toLowerCase()));

	const highlightMatch = (searchString, searchValue, isDisabled) => {
		const nameLowercase = searchString.toLowerCase();
		const startIndex = nameLowercase.indexOf(searchValue);
		return (
			<Text color={isDisabled ? 'secondary' : 'primary'}>
				{startIndex < 0 ? (
					searchString
				) : (
					<>
						{searchString.substring(0, startIndex)}
						<Text variant={'bold'}>{searchString.substring(startIndex, startIndex + searchValue.length)}</Text>
						{searchString.substring(startIndex + searchValue.length)}
					</>
				)}
			</Text>
		);
	};

	return {
		search,
		searchMultiplePaths,
		highlightMatch,
	};
};

export default useSearch;
