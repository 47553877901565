import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';

// searchString and value is used by nested Dropdown
// eslint-disable-next-line no-unused-vars
export const SkillLine = ({name, category, searchString, value, cy}) => {
	return (
		<Container data-cy={cy}>
			<Skill>{name}</Skill>
			{category && <Category>{category.name}</Category>}
		</Container>
	);
};

export const Category = Styled.div`
	color: #A5A5AE;
`;

export const Skill = Styled.div`
`;

export const Container = Styled.div`
	padding: 5px 0;
`;

SkillLine.propTypes = {
	name: PropTypes.string,
	category: PropTypes.object,
	searchString: PropTypes.string,
	cy: PropTypes.string,
	value: PropTypes.any,
};
