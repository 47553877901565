import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TooltipContainer from '../../tooltips/tooltip_container';
import {injectIntl} from 'react-intl';
import {PDFDownloadLink} from '@react-pdf/renderer';
import {trackPDFExport} from '../../../../../tracking/amplitude/TrackingV2';
class PDFButton extends Component {
	render() {
		return (
			<PDFDownloadLink document={this.props.document} fileName={this.props.fileName}>
				{({loading, error}) => (
					<div className={'pdf-wrapper'} key={`pdf-export`}>
						<TooltipContainer infoText={this.props.disabled || loading || error ? null : 'Export PDF'}>
							<button
								className={'pdf-button'}
								data-userpilot={'pdf-button'}
								data-cy={this.props.cy}
								id={'pdf-button'}
								disabled={this.props.disabled || loading || error}
								onClick={() => trackPDFExport(this.props.pageName)}
							/>
						</TooltipContainer>
					</div>
				)}
			</PDFDownloadLink>
		);
	}
}
PDFButton.propTypes = {
	text: PropTypes.string,
	cy: PropTypes.string,
	onClick: PropTypes.func,
};
export default injectIntl(PDFButton);
