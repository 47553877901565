import React, {useState} from 'react';
import styled from 'styled-components';
import {
	DeprecatedContentContainer as ContentContainer,
	DeprecatedCreativeModal as CreativeModal,
	DeprecatedText as Text,
	deprecatedTheme as theme,
	FlexColumn,
} from '@forecast-it/design-system';
import Util from '../../../util/util';
import UpdateProgramFinancialMetadataMutation from '../../../../../mutations/financial-service/update_program_financial_metadata_mutation';
import {useIntl} from 'react-intl';
import {CurrencyInput} from 'web-components';
import {trackEvent} from '../../../../../tracking/amplitude/TrackingV2';

export const SelectionBody = styled.div`
	padding: ${theme.spacing.m};
`;

export const ChangeProgramBudgetValueModal = ({company, program, closeModal}) => {
	const intl = useIntl();
	const {formatMessage} = intl;
	const {budgetType, budgetValue, projectsFinancialsTotals, totalAllocation} = program;
	const currencySymbol = Util.GetCurrencySymbol(company.currency);

	const totalProgramRevenueAtCompletion = projectsFinancialsTotals.recognitionForecastRevenue; // Revenue - Recognition at completion

	const [isCreating, setCreating] = useState(false);
	const [selectedBudgetValue, setSelectedBudgetValue] = useState(budgetValue);
	const [validBudgetValue, setValidBudgetValue] = useState(true);
	const showBudgetValueError = !validBudgetValue;

	const validate = () => {
		let valid = true;
		if (budgetType === 'FIXED_PRICE') {
			valid = selectedBudgetValue >= Util.roundFloatToTwoDecimals(totalProgramRevenueAtCompletion);
		}
		if (budgetType === 'CAPPED') {
			valid = selectedBudgetValue >= Util.roundFloatToTwoDecimals(totalAllocation);
		}
		setValidBudgetValue(valid);
		return valid;
	};

	const updateProgramBudgetType = () => {
		if (validate()) {
			setCreating(true);
			Util.CommitMutation(
				UpdateProgramFinancialMetadataMutation,
				{
					companyId: company.id,
					id: program.id,
					budgetType: budgetType,
					budgetValue: parseInt(selectedBudgetValue, 10),
				},
				() => {
					setCreating(false);
					closeModal();
				}
			);
			trackEvent('Program Budget Change Modal', 'Change Confirmed');
		}
	};

	function getBudgetInfo(budgetType) {
		switch (budgetType) {
			case 'CAPPED':
				return (
					<FlexColumn alignItems={'flex-start'}>
						<FlexColumn gap={'m'}>
							<Text type={'label'}>{formatMessage({id: 'project_budget.confirmed_revenue'})}</Text>
							<Text>{Util.getFormattedNumberWithCurrency(currencySymbol, totalAllocation, intl)}</Text>
							<Text type={'label'}>{formatMessage({id: 'project_budget.total_revenue_at_completion'})}</Text>
							<Text>
								{Util.getFormattedNumberWithCurrency(currencySymbol, totalProgramRevenueAtCompletion, intl)}
							</Text>
						</FlexColumn>
					</FlexColumn>
				);
			case 'FIXED_PRICE':
				return (
					<FlexColumn alignItems={'flex-start'}>
						<FlexColumn gap={'m'}>
							<Text type={'label'}>{formatMessage({id: 'project_budget.total_revenue_at_completion'})}</Text>
							<Text>
								{Util.getFormattedNumberWithCurrency(currencySymbol, totalProgramRevenueAtCompletion, intl)}
							</Text>
						</FlexColumn>
					</FlexColumn>
				);
			default:
				return null;
		}
	}

	function getBudgetValuePanel(budgetType) {
		const title =
			budgetType === 'CAPPED'
				? formatMessage({id: 'program.program_cap_amount.title'})
				: formatMessage({id: 'program.program_fixed_price_amount.title'});
		const subTitle =
			budgetType === 'CAPPED'
				? formatMessage({id: 'program.program_cap_amount.description'})
				: formatMessage({id: 'program.program_fixed_price_amount.description'});
		const errorMessage =
			budgetType === 'CAPPED'
				? formatMessage({id: 'program.program_cap_amount.error_min_amount'})
				: formatMessage({id: 'program.program_fixed_price_amount.error_min_amount'});
		return (
			<FlexColumn gap={'m'}>
				<Text type={'label'}>{title}</Text>
				<Text>{subTitle}</Text>
				<CurrencyInput
					cy={'program-budget-value'}
					currencySymbole={currencySymbol}
					value={selectedBudgetValue}
					callback={setSelectedBudgetValue}
					hasError={showBudgetValueError}
					intl={intl}
				/>
				{showBudgetValueError && <Text color={'destructive'}>{errorMessage}</Text>}
			</FlexColumn>
		);
	}

	const budgetInfo = getBudgetInfo(budgetType);

	return (
		<CreativeModal
			title={formatMessage({id: 'program_budget.edit_revenue_cap'})}
			closeModal={closeModal}
			onCreateHandler={updateProgramBudgetType}
			disabled={isCreating}
			creativeButtonText={formatMessage({id: 'common.confirm_changes'})}
			size={'s'}
			alignItems={'left'}
		>
			<ContentContainer padding={'none'} width={'100%'}>
				<FlexColumn>
					<SelectionBody>{budgetInfo}</SelectionBody>
				</FlexColumn>
			</ContentContainer>
			{getBudgetValuePanel(budgetType)}
		</CreativeModal>
	);
};
