import {css} from 'styled-components';
import styled from 'styled-components/macro';
import {CSS_CONSTANTS} from '../../../css_variables';

export const MyWorkPageWrapper = styled.div`
	display: flex;
	position: relative;
	flex-direction: column;
	flex-grow: 1;
	flex: 1;
	height: initial;
	${props =>
		props.overflowHidden &&
		css`
			overflow: hidden;
		`}
`;

export const HeaderBarStyled = styled.div`
	.header-bar {
		padding-left: 24px;
	}
`;

export const MyWorkContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100%;
`;
export const MyWorkWrapper = styled.div`
	height: 100%;
	display: flex;
	flex-direction: row;
	align-content: stretch;
	border-top: solid 1px ${CSS_CONSTANTS.app_border_color};
	${props =>
		props.overflowHidden &&
		css`
			overflow: hidden;
		`}
`;

export const MyWorkStatsSectionWrapper = styled.div`
	width: 235px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const MyWorkStatsWrapper = styled.div`
	margin: 0 28px 29px 29px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const MyWorkStatWrapper = styled.div`
	width: 100%;
	margin-top: 20px;
	padding-bottom: 20px;
	border-bottom: ${props => (props.hideBorder ? 'none' : '1px solid #e6e6e6')};
`;

export const MyWorkStatHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin-bottom: 16px;
	h5 {
		font-size: 13px;
		font-weight: 550;
		color: #535353;
		margin: 0 8px 0 0;
		padding: 0;
	}
`;

export const ProfileWrapper = styled.div``;

export const ProfilePictureWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 10px;
	// Interesting conditional rendering syntax
	${({selfOrAdmin}) =>
		selfOrAdmin &&
		`
		img,
		.person-initials-hexagon-shape {
			cursor: pointer;
			filter: drop-shadow(0 4px 10px rgba(0, 0, 0, 0.15));
			transition: 0.2s filter ease-in-out;
			&:hover {
				filter: drop-shadow(0 4px 10px rgba(0, 0, 0, 0.15)) brightness(0.7);
			}
		}
	`}
`;

export const NameWrapper = styled.div`
	font-size: 20px;
	//line-height: 22px;
	font-weight: 550;
	text-align: center;
	color: ${CSS_CONSTANTS.v2_text_gray};
	margin-bottom: 3px;
`;
export const RoleWrapper = styled.div`
	font-size: 12px;
	text-align: center;
	color: ${CSS_CONSTANTS.v2_text_light_gray};
	font-weight: 500;
`;

export const MyWorkTasksSectionWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	flex: 1;
	height: initial;
	background-color: ${CSS_CONSTANTS.v2_fixed_element_grey};
	border-left: solid 1px ${CSS_CONSTANTS.app_border_color};
	border-right: solid 1px ${CSS_CONSTANTS.app_border_color};
	${props =>
		props.overflowHidden
			? css`
					overflow: hidden;
			  `
			: css`
					padding-bottom: 40px;
			  `}
`;

export const MyWorkTasksListWrapper = styled.div`
	display: inline-block;
	min-width: 100%;
	margin-bottom: 32px;
`;

export const TaskListWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	align-items: stretch;
	min-height: 100%;
	margin-bottom: 16px;
	padding: 0px 24px 0px 24px;
`;

export const HeaderPageContainer = styled.div`
	.header-bar {
		flex-grow: 1;
		padding: 24px;
	}
`;

export const ListHeaderWrapper = styled.div`
	display: flex;
	font-size: 16px;
	font-weight: 500;
	background: ${CSS_CONSTANTS.v2_fixed_element_grey};
	position: sticky;
	top: 0;
	left: 0;
	overflow-x: hidden;
	z-index: 1;
	padding: 0px 24px 16px 24px;
`;

export const ListHeaderText = styled.div`
	display: flex;
	color: ${props =>
		props.overdue
			? props.disabled
				? CSS_CONSTANTS.v2_text_light_gray
				: CSS_CONSTANTS.negative_value
			: CSS_CONSTANTS.v2_text_gray};
	cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`;

export const ListHeaderTaskAmount = styled.div`
	margin-left: 4px;
	margin-right: 8px;
	font-weight: 400;
`;

export const HoursContainer = styled.div`
	display: flex;
	font-size: 13px;
	margin: 12px 0;
	justify-content: center;
`;

export const HoursActual = styled.div`
	font-weight: 500;
	color: #535353;
`;

export const HoursPlanned = styled.div`
	color: #a1a1a1;
`;
