import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as ReactDOM from 'react-dom';
import {injectIntl} from 'react-intl';
import NestedDropdown from '../dropdowns/nested_dropdown';
import Util from '../../util/util';
import Switch from '../switch/switch';
import InformationIcon from '../../../../images/information-icon';
import TooltipContainer from '../tooltips/tooltip_container';
import FinancialLockIcon from '../../../../components/new-ui/FinancialLockIcon';

class ActionsMenu extends Component {
	constructor(props) {
		super(props);

		let outerOptionExpandedMap;

		if (this.props.nestedDropdown) {
			outerOptionExpandedMap = new Map();
			this.props.options.forEach(option =>
				outerOptionExpandedMap.set(option.value, option.nestedOptions.length !== 0 && !option.collapseByDefault)
			);
		}

		this.state = {
			expanded: false,
			buttonHovered: false,
			focusFirstAction: false,
			dropdownHeight: 0,
			outerOptionExpandedMap,
			dropdownWidth: 200, //Default value, used for initial offset to avoid scrollbars appearing when width exits the page
		};
		this.collapseActionsDropdown = this.collapseActionsDropdown.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
	}

	componentDidMount() {
		this.offset = this.wrapperDivRef.getBoundingClientRect();

		//document.addEventListener('scroll', this.collapseActionsDropdown, true);
		// document.addEventListener('keyup', () => (this.preventExpansionOnFocus = false));

		if (this.actionsButtonRef) {
			this.actionsButtonRef.addEventListener('keydown', this.onKeyDown);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.focusFirstAction && this.actionsDropdownRef) {
			this.actionsDropdownRef.children[0].focus();
			this.setState({focusFirstAction: false});
		}
		if (!prevState.expanded && this.state.expanded && this.actionsDropdownRef) {
			const dropdownNode = this.actionsDropdownRef.getBoundingClientRect();
			this.setState({
				dropdownHeight: dropdownNode.height,
			});
		}
		this.offset = this.wrapperDivRef.getBoundingClientRect();
	}

	componentWillUnmount() {
		document.removeEventListener('scroll', this.collapseActionsDropdown, true);
		// document.removeEventListener('keyup', () => (this.preventExpansionOnFocus = false));

		if (this.actionsButtonRef) {
			this.actionsButtonRef.removeEventListener('keydown', this.onKeyDown);
		}
	}

	onFocus() {
		if (this.props.nestedDropdown && !this.props.disabled) {
			this.hasMenuJustBeenFocused = true;
			this.setState({expanded: true});
			// only Jacek and God know what's happening here
			setTimeout(() => {
				this.hasMenuJustBeenFocused = false;
			}, 200);
		}
	}

	attachKeyDownHandler(keyDownHandler) {
		this.attachedKeyDownHandler = keyDownHandler;
	}

	onKeyDown(e) {
		e.stopPropagation();
		this.attachedKeyDownHandler && this.attachedKeyDownHandler(e);
		if (e.key === 'Escape') {
			document.activeElement && document.activeElement.blur();
		}
		this.props.passedKeyDownHandler && this.props.passedKeyDownHandler(e);
	}

	collapseActionsDropdown(target) {
		if (this.state.expanded) {
			this.setState({expanded: false});
		}
	}

	toggleActionsDropdown(e) {
		e.preventDefault();
		e.stopPropagation();
		if (this.props.disabled) {
			return;
		}
		if (this.hasMenuJustBeenFocused) {
			this.hasMenuJustBeenFocused = false;
			return;
		}
		if (!this.state.expanded && this.actionsButtonRef) {
			this.actionsButtonRef.focus();
		}
		this.offset = this.wrapperDivRef.getBoundingClientRect();
		this.setState({expanded: !this.state.expanded});

		if (this.props.onActionMenuToggle) {
			this.props.onActionMenuToggle(!this.state.expanded);
		}
	}

	handleActionsBlur(e) {
		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11
		if (newTarget.id.includes('outer_select_option_') || newTarget.id.includes('nested_select_option_')) return;
		if (newTarget && this.actionsDropdownRef && this.actionsDropdownRef.contains(newTarget)) return;
		this.setState({expanded: false});
	}

	handleActionsKeyPress(e) {
		if (this.props.disabled) {
			return;
		}
		if (e.key === 'ArrowDown') {
			// Down arrow
			e.preventDefault();
			if (!this.state.expanded) {
				this.offset = this.wrapperDivRef.getBoundingClientRect();
				this.setState({expanded: true, focusFirstAction: true});
			} else {
				this.focusNextChild(this.actionsDropdownRef.children);
			}
		} else if (e.key === 'ArrowUp' && this.state.expanded) {
			// Up arrow
			e.preventDefault();
			this.focusPreviousChild(this.actionsDropdownRef.children);
		} else if (e.key === 'Enter' || e.key === ' ') {
			// Enter / Space
			if (!this.state.expanded) {
				this.offset = this.wrapperDivRef.getBoundingClientRect();
				this.setState({expanded: true, focusFirstAction: true});
			} else {
				const focusedElement = document.activeElement;
				if (focusedElement !== this.buttonRef) {
					e.preventDefault();
					focusedElement.click();
				}
			}
		}
	}

	focusNextChild(children) {
		const focusedElement = document.activeElement;
		for (let i = 0; i < children.length; i++) {
			if (focusedElement === children[i]) {
				if (i + 1 >= children.length) {
					children[0].focus();
				} else {
					children[i + 1].focus();
				}
				return;
			}
		}
		children[0].focus();
	}

	focusPreviousChild(children) {
		const focusedElement = document.activeElement;
		for (let i = 0; i < children.length; i++) {
			if (focusedElement === children[i]) {
				if (i === 0) {
					children[children.length - 1].focus();
				} else {
					children[i - 1].focus();
				}
				return;
			}
		}
		children[children.length - 1].focus();
	}

	handleOptionClick(e, option) {
		e.stopPropagation();
		e.preventDefault();
		option.onClick();
		if (!option.slider) {
			this.setState({expanded: false});
		}
	}

	onOuterOptionSelect(option) {
		const outerOptionExpandedMap = this.state.outerOptionExpandedMap;
		outerOptionExpandedMap.set(option.value, !outerOptionExpandedMap.get(option.value));
		this.setState({outerOptionExpandedMap});
	}

	onFocusMenu(e) {
		this.toggleActionsDropdown(e);
	}

	sendToPath(path) {
		window.open(path, '_self');
	}

	setDropdownWidth(width) {
		this.setState({dropdownWidth: width});
	}

	render() {
		let style = {};
		let showOnTop = false;
		if (this.offset) {
			const dropdownWidth = this.actionsDropdownRef
				? this.actionsDropdownRef.getBoundingClientRect().width
				: this.state.dropdownWidth;
			const left =
				this.offset.left -
				(this.props.showOnRight ? 0 : dropdownWidth === undefined ? 0 : dropdownWidth - this.offset.width) +
				(this.props.title ? (this.props.showOnRight ? this.offset.width : -this.offset.width) : 0) +
				this.props.leftOffset;

			showOnTop =
				this.props.alwaysShowOnTop ||
				this.offset.top + this.offset.height + this.state.dropdownHeight > window.innerHeight - this.props.bottomOffset;

			if (showOnTop) {
				style = {
					bottom: window.innerHeight - this.offset.top,
					left,
					position: 'absolute',
				};
			} else {
				style = {
					top: this.offset.top + (this.props.title ? 0 : this.offset.height + 3),
					left,
					position: 'absolute',
				};
			}
		}
		if (this.props.customColor) {
			style.borderColor = this.props.customColor;
		}
		let buttonStyle = {width: this.props.customWidth ? this.props.customWidth : ''};
		if (this.props.customColor && (this.state.buttonHovered || this.state.expanded)) {
			buttonStyle.backgroundColor = 'rgba(0, 0, 0, 0.4)';
		}

		return (
			<div
				className={
					'new-ui-actions-menu ' +
					(this.props.customClass ? this.props.customClass : '') +
					(this.state.expanded ? 'expanded' : '') +
					(this.props.disabled ? 'disabled' : '')
				}
				ref={div => (this.wrapperDivRef = div)}
			>
				<div
					tabIndex={0}
					style={buttonStyle}
					id={'actions-btn'}
					data-cy={this.props.cy ? this.props.cy + '-actions-menu-btn' : 'actions-menu-btn'}
					data-userpilot={this.props.userpilot}
					className={
						'actions' +
						(this.props.noBorderRadiusStyling ? ' no-border-radius-styling' : '') +
						(this.props.className ? ` ${this.props.className}` : '') +
						(showOnTop ? ' on-top' : '') +
						(this.state.expanded ? ' expanded' : '') +
						(this.props.isWhite ? ' white' : '') +
						(this.props.isGreen ? ' green' : '') +
						(!this.state.expanded && this.props.useBlackIcon && !this.props.isWhite && !this.props.isGreen
							? ' black-icon'
							: '') +
						(this.props.whiteInner ? ' white-inner' : '') +
						(this.props.blackIcon ? ' black-icon' : '') +
						(this.props.horizontalIcon ? ' horizontal-icon' : '') +
						(this.props.useGreyIcon ? ' grey-icon' : '') +
						(this.props.disabled ? ' disabled-icon' : '')
					}
					aria-haspopup="menu"
					aria-expanded={this.state.expanded}
					onClick={this.toggleActionsDropdown.bind(this)}
					onBlur={this.handleActionsBlur.bind(this)}
					onKeyPress={this.handleActionsKeyPress.bind(this)}
					ref={button => (this.actionsButtonRef = button)}
					disabled={this.props.disabled}
					onMouseEnter={
						this.props.customColor
							? () => {
									this.setState({buttonHovered: true});
							  }
							: null
					}
					onMouseLeave={
						this.props.customColor
							? () => {
									this.setState({buttonHovered: false});
							  }
							: null
					}
				>
					{this.props.label ? this.props.label : null}
				</div>
				{this.state.expanded && !this.props.nestedDropdown
					? ReactDOM.createPortal(
							<ul
								style={style}
								className={
									'actions-dropdown expanded' +
									(showOnTop ? ' on-top' : '') +
									(this.props.whiteInner ? ' white' : '') +
									(this.props.noBorderRadiusStyling ? ' no-border-radius-styling' : '') +
									(this.props.title ? ' with-title' : '')
								}
								aria-hidden={false}
								aria-labelledby="actions-btn"
								role="menu"
								ref={ul => (this.actionsDropdownRef = ul)}
								data-cy={this.props.cy}
								data-userpilot={'action-menu-content'}
							>
								{this.props.title ? (
									<li className="title-description-wrapper">
										<div className="title">{this.props.title}</div>
										<div className="description">{this.props.description}</div>
									</li>
								) : null}

								{this.props.options.map((option, index) => (
									<li
										id={'option_' + index}
										data-cy={this.props.cy ? this.props.cy + '-' + option.cy : option.cy}
										key={index}
										title={
											option.locked && option.disabledTitle
												? option.disabledTitle
												: option.locked && option.invoiced
												? this.props.intl.formatMessage({id: 'invoicing.object_invoiced'})
												: option.locked
												? this.props.intl.formatMessage({id: 'common.locked'})
												: null
										}
										className={
											'actions-option' +
											(option.locked ? ' locked-option' : '') +
											(option.slider ? ' with-slider' : '')
										}
										role="menuitem"
										tabIndex="-1"
										onClick={e =>
											option.linkPath
												? this.sendToPath(option.linkPath)
												: option.locked
												? e.stopPropagation()
												: this.handleOptionClick(e, option)
										}
										onBlur={this.handleActionsBlur.bind(this)}
										onKeyDown={this.handleActionsKeyPress.bind(this)}
										onMouseEnter={
											this.props.customColor
												? () => {
														this.setState({hoveredOptionId: 'option_' + index});
												  }
												: null
										}
										onMouseLeave={
											this.props.customColor
												? () => {
														this.setState({hoveredOptionId: null});
												  }
												: null
										}
										style={
											this.props.customColor && this.state.hoveredOptionId === 'option_' + index
												? {
														backgroundColor: this.props.customColor,
														color: Util.getTextColorV2(this.props.customColor),
												  }
												: null
										}
									>
										{/* PointerEvents: none in order to avoid ambiguous className for collapse checks (it would return actions-options, text-tooltip-wrapper, or simply <span> depending on where in the menu was clicked). There might be a better solution? */}
										<div className={'text-tooltip-wrapper'} style={{pointerEvents: 'none'}}>
											<span>
												{option.hotkey ? (
													<div className={'option-group'}>
														<div className={'opt-text'}>{option.text}</div>
														<div className={'opt-hotkey'}>{' (' + option.hotkey + ')'}</div>
													</div>
												) : (
													option.text
												)}
											</span>
											{option.tooltip ? (
												<TooltipContainer
													triangleLocation={'right'}
													infoText={option.tooltip}
													tooltipInfinteDuration={true}
													noHidden={true}
												>
													<InformationIcon />
												</TooltipContainer>
											) : null}
										</div>
										{option.slider ? (
											<Switch
												checked={option.checked}
												sliderWidth={35}
												sliderHeight={18}
												locked={option.locked}
											/>
										) : null}
										{option.noFinanceModule ? (
											<FinancialLockIcon
												fill={
													this.props.customColor && this.state.hoveredOptionId === 'option_' + index
														? Util.getTextColorV2(this.props.customColor)
														: '#000000'
												}
											/>
										) : null}
									</li>
								))}
							</ul>,
							document.querySelector('#root-portal-container')
					  )
					: this.state.expanded && this.props.nestedDropdown
					? ReactDOM.createPortal(
							<NestedDropdown
								toggleActionsDropdown={this.toggleActionsDropdown.bind(this)} // pass this function in order to toggle the action menu when the nested dropdown is blurred
								customClass="action-menu-nested-dropdown"
								actionMenu={true}
								dropdownStyle={style}
								options={this.props.options}
								outerOptionExpandedMap={this.state.outerOptionExpandedMap}
								onOuterOptionSelect={this.onOuterOptionSelect.bind(this)}
								attachKeyDownHandler={this.attachKeyDownHandler.bind(this)}
								setDropdownWidth={this.setDropdownWidth.bind(this)}
							/>,
							document.querySelector('#root-portal-container')
					  )
					: null}
			</div>
		);
	}
}

ActionsMenu.propTypes = {
	disabled: PropTypes.bool,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string,
			disabledTitle: PropTypes.string,
			onClick: PropTypes.func,
			locked: PropTypes.bool,
		})
	).isRequired,
	isWhite: PropTypes.bool,
	isGreen: PropTypes.bool,
	useBlackIcon: PropTypes.bool,
	bottomOffset: PropTypes.number,
	leftOffset: PropTypes.number,
	className: PropTypes.string,
	blackIcon: PropTypes.bool,
	linkPath: PropTypes.string,
	title: PropTypes.string,
	description: PropTypes.string,
	alwaysShowOnTop: PropTypes.bool,
	label: PropTypes.string,
	onActionMenuToggle: PropTypes.func,
};

ActionsMenu.defaultProps = {
	bottomOffset: 0,
	leftOffset: 0,
};

export default injectIntl(ActionsMenu);
