/* Page uses deprecated components - copy over the Text component ellipsis styles and add a min width to
 * get the rendering we want - this is a temporary fix until we can update the page to use the new components */
import styled from 'styled-components';

export const TruncatedName = styled.div`
	display: inline-block;
	white-space: pre-wrap;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	word-break: break-all;
	min-width: 150px;
`;
