import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import Util from '../../../util/util';
import * as tracking from '../../../../../tracking';
import ForecastTooltip from '../../tooltips/ForecastTooltip';
import {trackEvent} from '../../../../../tracking/amplitude/TrackingV2';
import {EVENT_ID, subscribe, unsubscribe} from '../../../../../containers/event_manager';

class SearchLazy extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: props.value ? props.value : '',
			isSearchExpanded: this.props.alwaysExpanded ? this.props.alwaysExpanded : false,
		};
		this.handleShortcutSearch = this.handleShortcutSearch.bind(this);
		this.searchTimer = null;
		this.clearSearch = this.clearSearch.bind(this);
	}

	componentDidMount() {
		if (this.props.useShortcut) {
			document.addEventListener('keydown', this.handleShortcutSearch);
		}
		if (this.props.startFocused && this.searchInput) {
			this.searchInput.focus();
		}
		subscribe(EVENT_ID.CLEAR_SEARCH, this.clearSearch);
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.isSearchExpanded && !prevState.isSearchExpanded && this.searchInput) {
			this.searchInput.focus();
		}
	}

	componentWillUnmount() {
		if (this.props.useShortcut) {
			document.removeEventListener('keydown', this.handleShortcutSearch);
		}
		if (this.searchTimer) {
			clearTimeout(this.searchTimer);
		}
		unsubscribe(EVENT_ID.CLEAR_SEARCH, this.clearSearch);
	}

	handleShortcutSearch(e) {
		const taskModalOpen = Util.getPathIsTaskModal();
		const activeElement = document.activeElement;
		const focusIsInput =
			activeElement &&
			(activeElement.tagName === 'INPUT' ||
				activeElement.tagName === 'TEXTAREA' ||
				activeElement.isContentEditable ||
				(activeElement.className && activeElement.className.toLowerCase().includes('drafteditor')) ||
				(activeElement.className && activeElement.className.toLowerCase().includes('switch')));
		const focusIsSearch = activeElement && activeElement.id === 'search';
		if (focusIsSearch && !taskModalOpen && e.key === 'Escape') {
			this.clearSearch();
			this.toggleSearchBar();
		}
		if (taskModalOpen || focusIsInput || e.repeat) {
			return;
		}
		if (e.shiftKey && e.keyCode === 70) {
			e.preventDefault();
			e.stopPropagation();
			this.toggleSearchBar();
		}
	}

	handleBlur(e) {
		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11

		if (this.search_container && this.search_container.contains(newTarget)) {
			return;
		} else {
			if (this.state.value === '') {
				if (this.props.setSearchState) {
					this.props.setSearchState(false);
				}
				this.setState({isSearchExpanded: false});
			}
		}
		tracking.trackEvent('Searched', {searchString: e.target && e.target.value ? e.target.value : ''});
		trackEvent('Items', 'Searched', {searchString: e.target && e.target.value ? e.target.value : ''});
	}

	toggleSearchBar() {
		this.setState({isSearchExpanded: !this.state.isSearchExpanded});
		if (this.props.setSearchState) {
			this.props.setSearchState(!this.state.isSearchExpanded);
		}
		if (this.state.isSearchExpanded && this.props.onClose) {
			this.props.onClose();
		}
	}

	clearSearch() {
		this.setState({value: ''});
		this.props.onChange('');
		this.searchInput?.focus();
	}

	onSearchChange(value) {
		this.setState({value});
		if (this.searchTimer) {
			clearTimeout(this.searchTimer);
		}
		this.searchTimer = setTimeout(() => {
			this.props.onChange(value);
		}, 500);
	}

	render() {
		const isSearchExpanded = this.state.isSearchExpanded || this.props.alwaysExpanded;
		const isDisabled = this.props.disabled;
		const isSearchEmpty = this.state.value === '';
		return (
			<ForecastTooltip
				content={
					this.props.disableTooltip || isSearchExpanded ? null : this.props.intl.formatMessage({id: 'common.search'})
				}
				delay={[500, null]}
			>
				<div
					tabIndex={this.props.customTabIndex ? this.props.customTabIndex : '0'}
					className={
						'action search-V2' +
						(isSearchExpanded ? ' expanded' : '') +
						(isSearchEmpty ? ' empty' : ' filled') +
						(isDisabled ? ' disabled' : '')
					}
					onClick={!isSearchEmpty && isSearchExpanded ? this.clearSearch.bind(this) : this.toggleSearchBar.bind(this)}
					ref={div => (this.search_container = div)}
					data-cy={this.props.dataCy ? this.props.dataCy : ''}
				>
					{isSearchExpanded ? (
						<input
							id="search"
							type="text"
							ref={input => {
								this.searchInput = input;
							}}
							onClick={e => {
								e.stopPropagation();
							}}
							onChange={e => this.onSearchChange(e.target.value)}
							placeholder={this.props.placeholder ? this.props.placeholder : ''}
							onBlur={e => this.handleBlur(e)}
							value={this.state.value}
							disabled={isDisabled}
							data-cy={this.props.dataCy ? this.props.dataCy + '-text-input' : ''}
							autoComplete="off"
						/>
					) : null}
				</div>
			</ForecastTooltip>
		);
	}
}

SearchLazy.propTypes = {
	placeholder: PropTypes.string.isRequired,
	value: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	onKeyUp: PropTypes.func,
	alwaysExpanded: PropTypes.bool,
	disableTooltip: PropTypes.bool,
};

export default injectIntl(SearchLazy);
