import {SOCKET_ACTION, SOCKET_EVENT_TYPE} from '../../../../constants';
import {PERMISSION_TYPE} from '../../../../Permissions';
import {hasPermission} from '../../../shared/util/PermissionsUtil';

export const getSocketConfig = (projectId, actualPersonId) => {
	const projectIds = parseInt(atob(projectId).replace('ProjectType:', ''));
	const actualPersonIdInt = parseInt(atob(actualPersonId).replace('Person:', ''));
	const socketEvents = [
		{
			type: SOCKET_EVENT_TYPE.PROJECT,
			action: SOCKET_ACTION.DELETE,
			projectIds: projectIds,
		},
		{
			type: SOCKET_EVENT_TYPE.PROJECT,
			action: SOCKET_ACTION.UPDATE,
			projectIds: projectIds,
		},
		{
			type: SOCKET_EVENT_TYPE.PROJECT_FINANCIALS,
			action: SOCKET_ACTION.UPDATE,
			projectIds: projectIds,
		},
		{
			type: SOCKET_EVENT_TYPE.TIME_LOCK,
			action: SOCKET_ACTION.UPDATE,
			personIds: actualPersonIdInt,
		},
		{
			type: SOCKET_EVENT_TYPE.TASK,
			action: SOCKET_ACTION.UPDATE,
			projectIds: projectIds,
		},
		{
			type: SOCKET_EVENT_TYPE.TASK,
			action: SOCKET_ACTION.CREATE,
			projectIds: projectIds,
		},
		{
			type: SOCKET_EVENT_TYPE.TASK,
			action: SOCKET_ACTION.DELETE,
			projectIds: projectIds,
		},
		{
			type: SOCKET_EVENT_TYPE.PHASE,
			action: SOCKET_ACTION.UPDATE,
			projectIds: projectIds,
		},
		{
			type: SOCKET_EVENT_TYPE.PHASE,
			action: SOCKET_ACTION.CREATE,
			projectIds: projectIds,
		},
		{
			type: SOCKET_EVENT_TYPE.PHASE,
			action: SOCKET_ACTION.DELETE,
			projectIds: projectIds,
		},
	];
	if (!hasPermission(PERMISSION_TYPE.PROJECTS_READ_ALL)) {
		socketEvents.push({
			type: SOCKET_EVENT_TYPE.PROJECT_PERSON,
			action: SOCKET_ACTION.DELETE,
			projectIds: projectIds,
			personIds: actualPersonIdInt,
		});
	}

	return socketEvents;
};
