import React, {useState} from 'react';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {createRefetchContainer, graphql} from 'react-relay';
import TimeRegBaseLevelWrapper from './TimeRegBaseLevelWrapper';
import {getRoleRow} from '../rows/RoleRow';
import {useGenericReportContext} from '../../../saved-report/GenericReportContext';

const TimeRegRoleRelayWrapper = ({
	relay,
	tableHeader,
	groupings,
	searchQuery,
	viewer,
	intl,
	groupIndex,
	enabledColumns,
	nextLevelProps,
	currency,
	customFieldColumnCount,
	customFieldKeys,
	pageSize,
}) => {
	const {reportId, sharedId} = useGenericReportContext();

	const roles = viewer.reportData.roles.edges?.filter(role => role.node.timeRegTotals.totalTimeRegistered > 0);
	const hasNextPage = viewer.reportData.roles.pageInfo.hasNextPage && roles?.length >= pageSize;
	const [modalTasksFetching, setModalTasksFetching] = useState(false);

	const dataRows = roles.map(role => {
		return {
			...role.node,
		};
	});

	const data = {rows: dataRows};

	const fetchMore = () => {
		if (!modalTasksFetching) {
			const refetchObject = {};
			setModalTasksFetching(true);
			refetchObject.pageSize = roles.length + pageSize;
			relay.refetch(refetchObject, null, () => setModalTasksFetching(false));
		}
	};

	const roleRows = () => {
		return getRoleRow(
			data,
			intl,
			groupIndex,
			searchQuery,
			groupings,
			enabledColumns,
			hasNextPage,
			fetchMore,
			nextLevelProps,
			currency,
			reportId,
			sharedId,
			customFieldColumnCount,
			customFieldKeys
		);
	};

	return groupIndex === 1 ? (
		<TimeRegBaseLevelWrapper
			tableHeader={tableHeader}
			intl={intl}
			enabledColumns={enabledColumns}
			getRows={roleRows}
			currentLength={roles.length}
			hasNextPage={viewer.reportData.roles.pageInfo.hasNextPage}
			refetch={relay.refetch}
			customFieldColumnCount={customFieldColumnCount}
			pageSize={pageSize}
		/>
	) : (
		roleRows()
	);
};

const TimeRegRoleRelayWrapperQuery = graphql`
	query TimeRegRoleRelayWrapper_Query(
		$searchQuery: TaskSearchQueryType
		$pageSize: Int
		$reportId: String
		$sharedId: String
	) {
		viewer {
			actualPersonId
			component(name: "time_reg_role_relay_wrapper_v2")
			...TimeRegRoleRelayWrapper_viewer
				@arguments(searchQuery: $searchQuery, pageSize: $pageSize, reportId: $reportId, sharedId: $sharedId)
		}
	}
`;
export {TimeRegRoleRelayWrapperQuery};

export default injectIntl(
	withRouter(
		createRefetchContainer(
			TimeRegRoleRelayWrapper,
			{
				viewer: graphql`
					fragment TimeRegRoleRelayWrapper_viewer on Viewer
					@argumentDefinitions(
						searchQuery: {type: "TaskSearchQueryType"}
						pageSize: {type: "Int"}
						reportId: {type: "String"}
						sharedId: {type: "String"}
					) {
						reportData(id: $reportId, sharedId: $sharedId) {
							roles(first: $pageSize, searchQuery: $searchQuery)
								@connection(key: "TimeRegReport_roles", filters: ["searchQuery"]) {
								pageInfo {
									endCursor
									hasNextPage
								}
								edges {
									node {
										roleId
										name
										noRole
										timeRegTotals {
											totalCost
											totalPrice
											totalTimeRegistered
											totalBillableRegistered
											totalNonBillableRegistered
										}
									}
								}
							}
						}
					}
				`,
			},
			graphql`
				query TimeRegRoleRelayWrapperRefetchQuery(
					$pageSize: Int
					$searchQuery: TaskSearchQueryType
					$reportId: String
					$sharedId: String
				) {
					viewer {
						...TimeRegRoleRelayWrapper_viewer
							@arguments(pageSize: $pageSize, searchQuery: $searchQuery, reportId: $reportId, sharedId: $sharedId)
					}
				}
			`
		)
	)
);
