import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import {BUTTON_COLOR, BUTTON_STYLE, DATE_PICKER_STYLE} from '../../../../constants';
import Button from '../buttons/button/button';
import Moment from 'moment';
import Util from '../../util/util';
import Dropdown from '../dropdowns/dropdown';
import CreateTimeRegistration from '../../../../mutations/create_time_registration_mutation.modern';
import uuid from 'uuid';
import TimeRegWSuggestionsInput from '../inputs/time-registration-input/time_reg_w_sugestions_input';
import DatePicker from '../date-picker/date_picker_v3';
import {TimeRegistrationIcon} from 'web-components/icons';
import PopupStyle, {TertiaryButtonStyleOverride} from '../../../../styles/components/time_reg_popup_styled';
import {injectIntl} from 'react-intl';
import {createToast} from '../toasts/another-toast/toaster';
import {dispatch, EVENT_ID, subscribe, unsubscribe} from '../../../../containers/event_manager';
import {ClickAwayListener} from '@material-ui/core';
import {MODAL_TYPE, showModal} from '../modals/generic_modal_conductor';
import {getLockedDate} from '../../../../components/timesheets/time-lock/TimeLockUtil';
import {isClientUser} from '../../util/PermissionsUtil';
import {getPopupPosition} from '../../util/PopupUtil';
import {CSS_CONSTANTS} from '../../../../css_variables';
import DirectApi from '../../../../directApi';
import {updateTaskForScoping} from '../../../project-tab/projects/scoping-page/ProjectScopingUtil';
import {canLoggedInPersonManageProgram, hasTopDownProgramBudgetFeature} from '../../util/ProgramFinancialLogic';
import {ACTION} from '../modals/program-management/ProgramBudgetErrorMessage';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';
import {isTaskTimeRegistrationAllowed} from '../../util/time-registration/time-registration-settings/TimeRegistrationTaskFilter';
import {
	isBillableSplitAllowed,
	isRolesAllowed,
	isTimeRegistrationNoteRequired,
} from '../../util/cache/TimeRegistrationSettingsUtil';
import {isTimeRegistrationNoteInvalid} from '../../util/time-registration/time-registration-settings/TimeRegistrationNoteFilter';
import {NoteRequiredError, NoteRequiredInfoIcon} from '../add-time-entry/validation-info/NoteRequiredInfo';
import LabelWithTooltip from '../add-time-entry/validation-info/LabelWithTooltip';
import {hasFeatureFlag} from '../../util/FeatureUtil';
import {toEntityId} from '../modals/time-registration/time-registration-modal/util/TimeRegistrationModalUtil';
import HoursInput from '../inputs/hours-input/hours_input_view';
import {TimeRegGranularityInfoIcon} from '../add-time-entry/validation-info/TimeRegGranularityInfo';
import {convertIntoFloatHoursFormatWithGranularity} from '../inputs/hours-input/hours_input_logic';
import {workingHourForTheDay} from '../../../my-work-tab/my-timesheets-page/timesheets_person_data';
import {createDateFromMoment} from '../../util/DateUtil';
import {
	getDateRestrictionInfo,
	getIsDateDisabledForTaskAndProject,
	getProjectDates,
	getTaskDates,
	validateDateReturnErrorMessage,
} from '../../util/time-registration/time-registration-settings/TimeRegistrationDateUtil_BackwardsCompatibility';

import {
	getTotalMinutesRegisteredFromTimeRegistrationEdges,
	useTaskEstimateValidation,
} from '../../util/time-registration/time-registration-settings/useTaskEstimateValidation';
import {RemainingTimeAndError} from '../add-time-entry/validation-info/TimeExceedsEstimateInfo';
import {DateRestrictionInfoIcon} from '../add-time-entry/validation-info/DateRestrictionInfo';
import {DateInvalidError} from '../add-time-entry/validation-info/DateInvalidError';
import RoleDropdownRelayWrapper from '../dropdowns/role-dropdown/RoleDropdownRelayWrapper';
import {RoleDropdownLabelFormat} from '../dropdowns/role-dropdown/RoleDropdown';
import OptIntoFeatureFlagMutation from '../../../../mutations/ts/OptIntoFeatureFlagMutation';

const optIntoFeatureFlag = (viewer, modalState, closeModal) => {
	const onSuccess = () => {
		trackEvent('Time Registration Popup', 'Opted Into New Time Registration Modal');
		closeModal();
		showModal({
			type: MODAL_TYPE.CREATE_TIME_REGISTRATION,
			personId: viewer.actualPersonId,
			entityId: toEntityId({taskId: modalState.taskId}),
			initialMinutes: modalState.timeInputValue,
			initialBillableTime: modalState.billableTime,
			initialDate: modalState.selectedDate ? createDateFromMoment(modalState.selectedDate) : undefined,
			initialNote: modalState.notesInputValue,
			initialRole: modalState.selectedRole,
		});
	};

	Util.CommitMutation(OptIntoFeatureFlagMutation, {featureFlag: 'new_time_registration_modal'}, onSuccess);
};

function getBillableTimeInputValue_timeRegInputConversion(targetEntityBillable, billableTime, inputValue) {
	if (!targetEntityBillable) {
		return 0;
	}
	if (billableTime != null) {
		return `${billableTime}m`;
	}
	if (inputValue && inputValue !== '') {
		return `${inputValue}m`;
	}
	return 0;
}

const TimeRegPopup = ({viewer, projectId, task, intl, refetch}) => {
	const formatMessage = intl.formatMessage;
	const timeIconRef = useRef(null);
	const popupRef = useRef(null);
	const billableMinutesUpdated = useRef(false);

	const today = Moment();
	const actualPersonId = viewer.actualPersonId;
	const lockedDate = getLockedDate(viewer.company, viewer);
	const todayOrEarliestSelectableDate =
		lockedDate && lockedDate.isSameOrAfter(today, 'day') ? lockedDate.clone().add(1, 'd') : today;

	const noteRequired = isTimeRegistrationNoteRequired();

	const [id] = useState(uuid.v4());
	const [isPopupVisible, setIsPopupVisible] = useState(false);
	const [popupPosition, setPopupPosition] = useState({});
	const [arrowPosition, setArrowPosition] = useState({});
	const [selectedDate, setSelectedDate] = useState(todayOrEarliestSelectableDate);
	const [inputValue, setInputValue] = useState('');
	const [billableTime, setBillableTime] = useState(0);
	const [notes, setNotes] = useState('');
	const [noteError, setNoteError] = useState(false);
	const [harvestTasks, setHarvestTasks] = useState([]);
	const [selectedHarvestTask, setSelectedHarvestTask] = useState(null);
	const [selectedUnit4Activity, setSelectedUnit4Activity] = useState(null);
	const [unit4Loading, setUnit4Loading] = useState(false);
	const [unit4Activities, setUnit4Activities] = useState([]);
	const [selectedRole, setSelectedRole] = useState();
	const [timeError, setTimeError] = useState('');
	const [dateError, setDateError] = useState('');

	const targetEntityBillable = task.billable;
	const billableSplitAllowed = isBillableSplitAllowed();

	const billableTimeInputValue = getBillableTimeInputValue_timeRegInputConversion(
		targetEntityBillable,
		billableTime,
		inputValue
	);

	const workingHoursForDay = workingHourForTheDay({...viewer, id: viewer.actualPersonId}, selectedDate || Moment());

	const rolesAllowed = isRolesAllowed();

	const minutesRegistered =
		task?.totalMinutesRegistered || getTotalMinutesRegisteredFromTimeRegistrationEdges(task?.timeRegistrations?.edges);
	const {remainingInfoMessage, validateTimeInputValue} = useTaskEstimateValidation(
		task?.estimateForecastMinutes,
		minutesRegistered,
		0,
		intl
	);

	let inputRef; // ref for input focus
	const handleIconClick = e => {
		trackEvent('Time Reg Icon', 'Clicked', {
			openingPopup: !isPopupVisible,
			projectId: task.project.companyProjectId,
			taskId: task.companyTaskId,
		});

		e.stopPropagation();

		if (hasFeatureFlag('new_time_registration_modal')) {
			showModal({
				type: MODAL_TYPE.CREATE_TIME_REGISTRATION,
				personId: viewer.actualPersonId,
				entityId: toEntityId({taskId: task.id}),
			});
		} else {
			if (hasTopDownProgramBudgetFeature() && refetch) {
				// Refetch task info to ensure accurate program revenue validation when adding
				// time registrations, as program's cap could have been changed by actions
				// performed in a different tab or by other program users.
				refetch();
			}

			if (timeIconRef.current) {
				const iconPosition = timeIconRef.current.getBoundingClientRect();

				requestAnimationFrame(() => {
					if (popupRef.current) {
						const popupPosition = popupRef.current.getBoundingClientRect();

						const result = getPopupPosition(iconPosition, popupPosition);

						setPopupPosition(result.popupPositionValues);
						setArrowPosition(result.arrowPositionValues);
					}
				});
			}

			dispatch(EVENT_ID.TIME_POPUP_OPENED, task.id);
			setIsPopupVisible(!isPopupVisible);
		}
	};

	const resetFields = () => {
		setSelectedDate(todayOrEarliestSelectableDate);
		setInputValue('');
		setBillableTime(null);
		setNotes('');
		setNoteError(false);
		setTimeError('');
		setDateError('');
	};

	const closePopup = () => {
		resetFields();
		setIsPopupVisible(false);
	};

	const onScroll = useCallback(() => {
		if (isPopupVisible === true) {
			closePopup();
		}
	}, [isPopupVisible]);

	const timePopupOpened = openedTaskId => {
		if (task.id !== openedTaskId) {
			closePopup();
		}
	};

	const fetchHarvestTasks = project => {
		if (project) {
			if (project.id && project.harvestProject) {
				DirectApi.Fetch('harvest_tasks/' + project.id).then(tasks => {
					setHarvestTasks(tasks);
				});
			} else {
				setHarvestTasks([]);
			}
		} else {
			setHarvestTasks([]);
		}
	};

	const fetchUnit4Activities = () => {
		//If project dropdown is not empty
		if (task.project) {
			//If a project is selected, show only tasks that belong to that project
			if (task.project.id && task.project.unit4Project) {
				if (unit4Loading === true) return;
				if (unit4Activities.length === 0) {
					setUnit4Loading(true);
				}
				DirectApi.Fetch('unit4_activities/' + task.project.id).then(activities => {
					setUnit4Activities(activities.map(activity => ({label: activity.name, value: activity.id})));
					setUnit4Loading(false);
				});
			} else {
				setUnit4Activities([]);
				setUnit4Loading(false);
			}
		} else {
			setUnit4Activities([]);
			setUnit4Loading(false);
		}
	};

	const dateInfo = getDateRestrictionInfo(task, task?.project);
	const taskStatusColumn = task.statusColumnV2?.category;
	const assignedPersonIds = useMemo(() => task.assignedPersons.map(person => person.id), [task]);

	const shouldShowPopup = useMemo(() => {
		return (
			!isClientUser() && isTaskTimeRegistrationAllowed(taskStatusColumn, actualPersonId, assignedPersonIds, task?.project)
		);
	}, [actualPersonId, taskStatusColumn, assignedPersonIds]);

	useEffect(() => {
		subscribe(EVENT_ID.SCROLLED, onScroll);
		subscribe(EVENT_ID.TIME_POPUP_OPENED, timePopupOpened);
		return () => {
			unsubscribe(EVENT_ID.SCROLLED, onScroll);
			unsubscribe(EVENT_ID.TIME_POPUP_OPENED, timePopupOpened);
		};
	}, []);

	useEffect(() => {
		if (isPopupVisible) {
			fetchHarvestTasks(task.project);
			document.addEventListener('wheel', onScroll);
			return () => {
				document.removeEventListener('wheel', onScroll);
			};
		}
	}, [isPopupVisible]);

	useEffect(() => {
		if (lockedDate && lockedDate.isSameOrAfter(selectedDate, 'day')) {
			setSelectedDate(lockedDate.clone().add(1, 'd'));
		}
	}, [lockedDate]);

	useEffect(() => {
		if (!isPopupVisible) return;
		const timeValidationError = validateTimeInputValue(inputValue);
		if (timeValidationError) {
			setTimeError(timeValidationError);
		} else {
			setTimeError(null);
		}
	}, [inputValue]);

	const onPopupClick = e => {
		e.target.focus();
		e.stopPropagation();
	};

	const handleDateSelect = date => {
		setSelectedDate(date);
	};

	useEffect(() => {
		const dateInvalidReason = validateDateReturnErrorMessage(selectedDate, task, task?.project);
		if (!dateInvalidReason) {
			setDateError('');
		}
	}, [selectedDate]);

	const handleHarvestTaskChange = task => {
		setSelectedHarvestTask(task);
	};

	const handleUnit4ActivityChange = activity => {
		setSelectedUnit4Activity(activity);
	};

	const handleNotesChange = e => {
		const note = e.target ? e.target.value : '';
		setNotes(note);
		setNoteError(false);
	};

	const handleCancelButtonPress = () => {
		trackEvent('Time Registration Popup Cancel Button', 'Clicked');
		closePopup();
	};

	const showProgramRevenueLockedMessage = () => {
		const program = task.project.program;
		const loggedInPersonId = actualPersonId;
		const canManageProgram = canLoggedInPersonManageProgram(program?.members, loggedInPersonId);

		showModal({
			type: MODAL_TYPE.PROGRAM_BUDGET_ERROR_MESSAGE,
			action: ACTION.ADD_TIME_ENTRY,
			programName: program?.name,
			canManageProgram,
			programPrefix: program?.prefix,
		});

		trackEvent('Program Budget Error Message Modal', 'Shown');
	};

	const getTrackingProperties = () => {
		let properties = {
			projectId: task.project.companyProjectId,
			taskId: task.companyTaskId,
			taskType: !!task.parentTaskId ? 'subTask' : 'task',
			date: selectedDate.format('YYYY-MM-DD'),
			timeRegPerson: viewer.email,
			notes: notes.length ? notes : '',
		};

		return properties;
	};

	const handleAddButtonPress = e => {
		const dateInvalidReason = validateDateReturnErrorMessage(selectedDate, task, task?.project);
		if (dateInvalidReason) {
			setDateError(dateInvalidReason);
		} else {
			setDateError('');
		}

		const noteInvalid = isTimeRegistrationNoteInvalid(notes);
		if (noteInvalid) {
			setNoteError(true);
		}

		if (dateInvalidReason || noteInvalid) {
			return;
		}

		trackEvent(`Time Registration Add Button`, 'Clicked', getTrackingProperties());

		const onSuccess = () => {
			createToast({
				duration: 5000,
				message: formatMessage({id: 'common.time_entry_added'}),
			});
			closePopup();
		};

		const handleResult = result => {
			if (result.createTimeReg.errors && result.createTimeReg.errors.length === 1) {
				if (result.createTimeReg.errors[0] === 'TIME_REGISTRATION_IN_LOCKED_PERIOD') {
					showModal({
						type: MODAL_TYPE.WARNING_DATE_LOCKED,
					});
				} else if (Util.checkForSageErrorAndShowModal(result.createTimeReg.errors, false)) {
				} else if (result.createTimeReg.errors[0] === 'PROGRAM_REVENUE_LOCKED') {
					showProgramRevenueLockedMessage();
				}
			} else {
				updateTaskForScoping(task?.id, onSuccess);
			}
		};

		if (!isNaN(inputValue) && inputValue !== null && inputValue !== '') {
			const billableMinutesRegistered = targetEntityBillable ? billableTime : undefined;

			Util.CommitMutation(
				CreateTimeRegistration,
				{
					viewer: viewer,
					projectId: projectId,
					taskId: task.id,
					subTask: !!task.parentTaskId,
					source: 'Time-reg-popup',
					idleTimeId: null,
					minutesRegistered: inputValue,
					billableMinutesRegistered: billableMinutesRegistered,
					day: selectedDate.date(),
					month: selectedDate.month() + 1,
					year: selectedDate.year(),
					personId: actualPersonId,
					notes: notes.length ? notes : null,
					harvestTaskId: selectedHarvestTask ? selectedHarvestTask.value : null,
					unit4ActivityId: selectedUnit4Activity ? selectedUnit4Activity.value : null,
					unit4ActivityName: selectedUnit4Activity ? selectedUnit4Activity.label : null,
					roleId: selectedRole?.id,
				},
				handleResult
			);
		} else {
			inputRef && inputRef.focus();
		}
	};

	const onChildComponentBlur = () => {
		if (isPopupVisible) {
			closePopup();
		}
	};

	const setSuggestedValueToInput = value => {
		setInputValue(Util.convertTimeInputToMinutes(value, true) || null);

		if (!billableMinutesUpdated.current) {
			setBillableTime(null);
		}
	};

	const onBillableHoursInputChange = value => {
		const formattedValue = Util.convertTimeInputToMinutes(value, true);

		trackEvent('Time Entry Modal', 'Billable Time Input Changed', {
			from: billableTimeInputValue,
			to: formattedValue / 60,
		});

		setBillableTime(formattedValue);

		billableMinutesUpdated.current = true;
	};

	// Dirty hack to prevent drag events from bubbling out from portal
	const onMouseDown = e => {
		e.stopPropagation();
	};

	const harvestTaskOptions = harvestTasks
		? harvestTasks.map(harvestTask => {
				return {value: harvestTask.id, label: harvestTask.name};
		  })
		: [];

	const isDateInvalid = date => {
		const datesObject = {
			date: createDateFromMoment(date),
			...getTaskDates(task),
			...getProjectDates(task.project),
		};
		return getIsDateDisabledForTaskAndProject(datesObject);
	};

	function getPersonFromViewer() {
		return {
			id: viewer.actualPersonId,
			startDate: viewer.startDate,
			endDate: viewer.endDate,
			createdAt: viewer.createdAt,
			monday: viewer.monday,
			tuesday: viewer.tuesday,
			wednesday: viewer.wednesday,
			thursday: viewer.thursday,
			friday: viewer.friday,
			saturday: viewer.saturday,
			sunday: viewer.sunday,
		};
	}

	const modalState = {
		taskId: task.id,
		timeInputValue: inputValue,
		billableTime,
		notesInputValue: notes,
		selectedDate,
	};

	return !shouldShowPopup ? null : (
		<React.Fragment>
			<div
				className={'time-registration-icon' + (isPopupVisible ? ' active' : '')}
				style={{cursor: 'pointer'}}
				data-cy={'time-registration-popup'}
				ref={timeIconRef}
				onClick={handleIconClick}
			>
				<TimeRegistrationIcon hoverColor={CSS_CONSTANTS.v2_branding_purple} />
			</div>
			{isPopupVisible
				? ReactDOM.createPortal(
						<ClickAwayListener onClickAway={onChildComponentBlur}>
							<PopupStyle
								onMouseDown={onMouseDown}
								onClick={onPopupClick}
								onContextMenu={onPopupClick}
								popupPosition={popupPosition}
								arrowPosition={arrowPosition}
								ref={popupRef}
							>
								<div className="popup-wrapper" data-cy={'time-entry-popup-content'}>
									<div className="entry-wrapper">
										<div className="title">{formatMessage({id: 'common.new_time_entry_popup_title'})}</div>
									</div>
									<div style={{display: 'flex', gap: '24px'}}>
										<div className="entry-wrapper">
											<TimeRegWSuggestionsInput
												id={id}
												clearable
												initialValue={inputValue}
												onInputChange={value => {
													setSuggestedValueToInput(value);
												}}
												date={selectedDate}
												task={task}
												personId={actualPersonId}
												person={getPersonFromViewer()}
												innerRef={div => (inputRef = div)}
												cy={'time-entry-pop-up'}
											/>
										</div>
										{billableSplitAllowed && (
											<div className="entry-wrapper">
												<div className="icon-title-wrapper">
													<div className="input-title">
														<LabelWithTooltip label={'Billable time'}>
															<TimeRegGranularityInfoIcon />
														</LabelWithTooltip>
													</div>
												</div>
												<div style={{width: '90px'}}>
													<HoursInput
														customClassName={'hour-input'}
														cy={'new-billable-time-entry-input'}
														value={billableTimeInputValue}
														mutation={onBillableHoursInputChange}
														disabled={!targetEntityBillable}
														replicateDesignSystem={false}
														granularityFormatter={(val, intl, minuteLimit, showAsterisk) =>
															convertIntoFloatHoursFormatWithGranularity(
																val,
																intl,
																minuteLimit,
																showAsterisk,
																workingHoursForDay
															)
														}
														isTimeregInput
													/>
												</div>
											</div>
										)}
									</div>
									<RemainingTimeAndError
										remainingInfoMessage={remainingInfoMessage}
										timeError={timeError}
										marginTop={'0'}
										marginBottom={'16px'}
										textSize={'3'}
									/>
									{task.project &&
									task.project.harvestProject &&
									viewer.harvestUser &&
									viewer.company.harvestEnabled ? (
										<div className="entry-wrapper">
											<Dropdown
												inputClassName="harvest-dropdown"
												focusOnClick={true}
												options={harvestTaskOptions}
												onChange={handleHarvestTaskChange}
												value={selectedHarvestTask ? selectedHarvestTask.value : ''}
												label={formatMessage({id: 'card_modal.harvest_task'})}
												multi={false}
												clearable={true}
												customHeight={30}
												placeholder={formatMessage({id: 'card_modal.harvest_task_placeholder'})}
											/>
										</div>
									) : null}
									{task.project &&
									task.project.unit4Project &&
									task.project.unit4Project.activitiesEnabled &&
									viewer.unit4User &&
									viewer.company.unit4Enabled ? (
										<div className="entry-wrapper">
											<Dropdown
												value={selectedUnit4Activity ? selectedUnit4Activity.value : null}
												options={unit4Activities}
												onChange={handleUnit4ActivityChange}
												label={formatMessage({id: 'integrations.unit4_activity'})}
												placeholder={formatMessage({id: 'integrations.unit4_activity_placeholder'})}
												customHeight={30}
												onClick={fetchUnit4Activities}
												integrationDropdown={true}
												integrationLoading={unit4Loading}
												integrationRetryToFetch={fetchUnit4Activities}
											/>
										</div>
									) : null}
									<div className="entry-wrapper">
										<div className="datepicker">
											<div className="input-title">
												<LabelWithTooltip
													label={formatMessage({id: 'common.date'})}
													alwaysShowTooltip={!!dateInfo}
												>
													<DateRestrictionInfoIcon message={dateInfo} />
												</LabelWithTooltip>
											</div>

											<DatePicker
												startDate={selectedDate}
												handleDateRangeChange={handleDateSelect}
												datePickerStyle={DATE_PICKER_STYLE.STANDARD}
												isSingleDatePicker={true}
												disabled={false}
												startDateLimite={lockedDate && lockedDate.clone().add(1, 'd')}
												disabledDates={isDateInvalid}
											/>
										</div>
										<DateInvalidError dateError={dateError} />
									</div>
									<div className="entry-wrapper">
										<div className="notes">
											<div className="input-title">
												{noteRequired ? (
													<LabelWithTooltip label={'notes'} isRequired>
														<NoteRequiredInfoIcon />
													</LabelWithTooltip>
												) : (
													formatMessage({id: 'common.notes'})
												)}
											</div>
											<textarea
												value={notes}
												maxLength={
													viewer.company.characterLimit > -1 ? viewer.company.characterLimit : 999
												}
												rows={5}
												cols={40}
												placeholder={formatMessage({id: 'overview_time.write_your_notes'})}
												onChange={handleNotesChange.bind(this)}
											/>
											{noteError ? <NoteRequiredError textSize={'3'} /> : null}
										</div>
									</div>
									{rolesAllowed ? (
										<div className="entry-wrapper">
											<RoleDropdownRelayWrapper
												onSelect={role => setSelectedRole(role)}
												projectId={projectId}
												taskId={task.id}
												personId={viewer.actualPersonId}
												showLabel={true}
												labelFormat={RoleDropdownLabelFormat.DEFAULT}
											/>
										</div>
									) : null}
									<div className="button-wrapper">
										{hasFeatureFlag('new_time_registration_modal_opt_in') ? (
											<TertiaryButtonStyleOverride>
												<Button
													text={'Try the new look'}
													buttonStyle={BUTTON_STYLE.TEXT}
													colorTheme={BUTTON_COLOR.PURPLE}
													onClick={() => optIntoFeatureFlag(viewer, modalState, closePopup)}
												/>
											</TertiaryButtonStyleOverride>
										) : null}
										<div className="buttons">
											<Button
												cy="cancel-button"
												text={formatMessage({id: 'common.cancel'})}
												buttonStyle={BUTTON_STYLE.FILLED}
												colorTheme={BUTTON_COLOR.WHITE}
												onClick={handleCancelButtonPress}
												focusOnMouseDown={true} // need to focus on the button when clicking down to trigger the blur effect on the input to tranform string to hours amount
												focusOnClick={true}
											/>
											<Button
												cy="add-new-time-entry-button"
												text={formatMessage({id: 'common.add'})}
												buttonStyle={BUTTON_STYLE.FILLED}
												colorTheme={BUTTON_COLOR.GREEN}
												onClick={handleAddButtonPress}
												focusOnMouseDown={true}
												focusOnClick={true}
												isDisabled={
													(task.project &&
														task.project.harvestProject &&
														viewer.harvestUser &&
														viewer.company.harvestEnabled &&
														!selectedHarvestTask) ||
													inputValue == null ||
													!!timeError
												}
											/>
										</div>
									</div>
								</div>
							</PopupStyle>
						</ClickAwayListener>,
						document.querySelector('#root-portal-container')
				  )
				: null}
		</React.Fragment>
	);
};

export default injectIntl(TimeRegPopup);
