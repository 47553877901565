import styled from 'styled-components';

export const ButtonWrapper = styled.div`
	display: flex;
	margin-top: 12px;
	flex-direction: row;
`;

export const AddTenantButtonWrapper = styled.div``;

export const HelpButtonWrapper = styled.div`
	margin-left: 8px;
`;
