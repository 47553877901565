import React from 'react';
import Styled from 'styled-components';
import {GrowableColumn} from '../Elements.styled';
import UtilizationGauge from '../../../../person/UtilizationGauge';
import {getAvailabilityTooltipContent} from '../utils';
import {useIntl, FormattedHTMLMessage} from 'react-intl';
import ForecastTooltip from '../../../../tooltips/ForecastTooltip';
import {IconWrapper} from '../../../../../../../components/initial-plan/InitialPlan.styled';
import {Icon} from '@forecast-it/design-system';

/***
 *
 * @param {String} name Name of role
 * @param {bool} noRole Is the role unassigened
 * @param {number} taskAmount the number of tasks with a given role
 * @param displayUtilizationGauge
 * @param availability
 * @param rollUpValues
 * @returns {JSX.Element}
 * @constructor
 */
export const RoleNameColumn = ({
	name,
	noRole,
	isSelectedRoleDisabled,
	taskAmount,
	displayUtilizationGauge,
	availability,
	rollUpValues,
}) => {
	const intl = useIntl();
	const availabilityInformation = {
		totalAvailability: rollUpValues.minutesAvailable,
		availabilityUntilPhaseEnds: rollUpValues.remainingMinutesAvailable,
		taskRemaining: rollUpValues.rollupRemaining,
		remainingAvailabilityInPhase: rollUpValues.minutesRemaining,
	};
	const utilizationGaugeTooltipContent = getAvailabilityTooltipContent(intl, availabilityInformation);

	return (
		<GrowableColumn>
			<Wrapper>
				{displayUtilizationGauge && (
					<UtilizationGaugeContainer>
						<ForecastTooltip content={utilizationGaugeTooltipContent} placement={'top'} maxWidth={600}>
							<UtilizationGauge utilization={availability} isRole={true} />
						</ForecastTooltip>
					</UtilizationGaugeContainer>
				)}
				<RoleName>{name}</RoleName>
				{noRole && <NoRoleTaskAmount>{'(' + taskAmount + ')'}</NoRoleTaskAmount>}
				{isSelectedRoleDisabled && (
					<ForecastTooltip content={<FormattedHTMLMessage id="project_person.deactivated_role" />}>
						<IconWrapper>
							<Icon icon="warning" size="m" color="error" />
						</IconWrapper>
					</ForecastTooltip>
				)}
			</Wrapper>
		</GrowableColumn>
	);
};

export const Wrapper = Styled.div`
    display: flex;
    align-items: center;
`;

export const NoRoleTaskAmount = Styled.div`
	margin-left: 4px; 
	font-weight: 300;
`;

const RoleName = Styled.div`
	font-size: 13px;
	font-weight: 500;
`;

const UtilizationGaugeContainer = Styled.div`
	margin-right: 8px;
`;
