import React from 'react';
import {FormattedHTMLMessage, FormattedMessage, injectIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import {withRouter} from 'react-router-dom';
import * as tracking from '../../../tracking';
import gitlabLogo from '../../../images/integrations/gitlab.png';
import CreateGitlabVerificationKeyMutation from '../../../mutations/create_gitlab_verification_key_mutation';
import UpdateCompanyMutation from '../../../mutations/update_company_mutation.modern';
import CustomScrollDiv from '../../shared/components/scroll-bars/custom_scroll_div';
import VerifyGitlabServerIntegrationMutation from '../../../mutations/verify_gitlab_server_integration_mutation';
import Util from '../../shared/util/util';
import Checkbox from '../../../components/inputs/checkbox';
import {MODAL_TYPE, showModal} from '../../shared/components/modals/generic_modal_conductor';
import {BUTTON_COLOR, BUTTON_STYLE, ELEMENT_TYPE} from '../../../constants';
import {buildHeaderBar} from '../../shared/components/headers/header-bar/header_bar';
import {trackEvent, trackPage, unregisterPageInfo} from '../../../tracking/amplitude/TrackingV2';

class appGitlab extends React.Component {
	constructor(props) {
		super(props);

		const company = props.viewer.company;

		this.state = {
			accountUrl: company.gitlabServerUrl ? company.gitlabServerUrl : '',
			accessToken: company.gitlabServerToken ? company.gitlabServerToken : '',
			verifying: false,
			verifyingSave: false,
		};

		this.superPropertyChecksum = trackPage('App Catalog - Gitlab');
	}

	componentDidMount() {
		document.title = 'Gitlab - Apps & Integrations - Forecast';
		// Segment
		tracking.trackPage('settings-app-catalog-gitlab');
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	sendToGitlab() {
		const onSuccess = ({createGitlabVerificationKey}) => {
			tracking.trackEvent('Gitlab integration enabled');
			trackEvent('Gitlab Integration', 'Enabled');
			const redirectUri = process.env.GITLAB_REDIRECT_URI;
			const clientId = process.env.GITLAB_CLIENT_ID;
			window.location.href = `https://gitlab.com/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&state=${createGitlabVerificationKey.key}`;
		};

		Util.CommitMutation(CreateGitlabVerificationKeyMutation, null, onSuccess);
	}

	disableGitlab() {
		const {formatMessage} = this.props.intl;
		const deactivateForReal = () => {
			Util.CommitMutation(UpdateCompanyMutation, {
				id: this.props.viewer.company.id,
				gitlabDeactivate: true,
			});
		};

		showModal({
			type: MODAL_TYPE.WARNING,
			warningMessageId: 'warning-modal.edit-warning-title',
			warningInformation: [formatMessage({id: 'settings.app_catalog.gitlab.deactivate_warning'})],
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.deactivate'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
					callback: deactivateForReal,
				},
			],
		});
	}

	handleChange(property, e) {
		this.setState({[property]: e.target.value});
	}

	enableDisable() {
		const {formatMessage} = this.props.intl;
		if (this.props.viewer.company.gitlabServerEnabled) {
			const deactivateForReal = () => {
				Util.CommitMutation(UpdateCompanyMutation, {
					id: this.props.viewer.company.id,
					gitlabServerDeactivate: true,
				});
				this.setState({
					accountUrl: '',
					accessToken: '',
				});
			};

			showModal({
				type: MODAL_TYPE.WARNING,
				warningMessageId: 'warning-modal.edit-warning-title',
				warningInformation: [formatMessage({id: 'settings.app_catalog.gitlab.deactivate_warning'})],
				buttons: [
					{
						text: formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: formatMessage({id: 'common.deactivate'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.RED,
						callback: deactivateForReal,
					},
				],
			});
			this.setState({verifying: false});
		} else {
			if (this.state.accountUrl === '') {
				this._account_url.focus();
				return;
			}
			if (this.state.accessToken === '') {
				this._access_token.focus();
				return;
			}

			this.setState({verifying: true});
			const onSuccess = response => {
				this.setState({verifying: false});
				if (response.verifyGitlabServerIntegration.success) {
					tracking.trackEvent('Gitlab integration enabled');
					trackEvent('Gitlab Integration', 'Enabled');
					showModal({
						type: MODAL_TYPE.WARNING,
						warningMessageId: 'integration.verification_success',
						warningInformation: [formatMessage({id: 'integration.verification_succeeded'})],
						useInfoIcon: true,
						buttons: [
							{
								text: formatMessage({id: 'common.ok'}),
								style: BUTTON_STYLE.FILLED,
								color: BUTTON_COLOR.WHITE,
							},
						],
					});
				} else {
					showModal({
						type: MODAL_TYPE.WARNING,
						warningMessageId: 'integration.verification_fail',
						warningInformation: [response.verifyGitlabServerIntegration.message],
						buttons: [
							{
								text: formatMessage({id: 'common.ok'}),
								style: BUTTON_STYLE.FILLED,
								color: BUTTON_COLOR.WHITE,
							},
						],
					});
				}
			};

			Util.CommitMutation(
				VerifyGitlabServerIntegrationMutation,
				{
					url: this.state.accountUrl,
					token: this.state.accessToken,
				},
				onSuccess
			);
		}
	}

	verifyAndSave() {
		const {formatMessage} = this.props.intl;
		if (this.state.accountUrl === '') {
			this._account_url.focus();
			return;
		}
		if (this.state.accessToken === '') {
			this._access_token.focus();
			return;
		}

		this.setState({verifyingSave: true});
		const onSuccess = response => {
			this.setState({verifyingSave: false});
			if (response.verifyGitlabServerIntegration.success) {
				showModal({
					type: MODAL_TYPE.WARNING,
					warningMessageId: 'integration.verification_success',
					warningInformation: [formatMessage({id: 'integration.verification_succeeded'})],
					useInfoIcon: true,
					buttons: [
						{
							text: formatMessage({id: 'common.ok'}),
							style: BUTTON_STYLE.FILLED,
							color: BUTTON_COLOR.WHITE,
						},
					],
				});
			} else {
				showModal({
					type: MODAL_TYPE.WARNING,
					warningMessageId: 'integration.verification_fail',
					warningInformation: [response.verifyGitlabServerIntegration.message],
					buttons: [
						{
							text: formatMessage({id: 'common.ok'}),
							style: BUTTON_STYLE.FILLED,
							color: BUTTON_COLOR.WHITE,
						},
					],
				});
			}
		};

		let url = this.state.accountUrl.trim();
		if (url.endsWith('/')) {
			url = url.slice(0, -1);
		}
		Util.CommitMutation(
			VerifyGitlabServerIntegrationMutation,
			{
				url,
				token: this.state.accessToken,
			},
			onSuccess
		);
	}

	openHelpCenter() {
		window.open('https://support.forecast.app/hc/en-us/articles/5400674862097');
	}

	changeDeleteIssuesSetting(e) {
		Util.CommitMutation(UpdateCompanyMutation, {
			id: this.props.viewer.company.id,
			gitlabDeleteIssues: !this.props.viewer.company.gitlabDeleteIssues,
		});
	}

	getHeader() {
		const leftContent = [],
			rightContent = [];

		leftContent.push({type: ELEMENT_TYPE.BACK_BUTTON, onClick: this.props.history.goBack});

		return buildHeaderBar(leftContent, rightContent);
	}

	render() {
		const {formatMessage} = this.props.intl;

		let enableButtonText = formatMessage({id: 'common.activate'});
		if (this.state.verifying) {
			enableButtonText = formatMessage({id: 'settings.app_catalog.verifying_button'});
		} else if (this.props.viewer.company.gitlabServerEnabled) {
			enableButtonText = formatMessage({id: 'common.deactivate'});
		}
		return (
			<div className="section-content settings-app settings-app-page settings-app-gitlab">
				{this.props.children}
				{this.getHeader()}
				<CustomScrollDiv>
					<div className="section-body">
						<div className="inner">
							<fieldset className="general">
								<div className="header-wrapper">
									<img width={100} height={100} alt="gitlab logo" src={gitlabLogo} />
									<div className="title-description-wrapper">
										<div className="description">
											<FormattedHTMLMessage id="settings.app_catalog.gitlab.description" />
										</div>
									</div>
								</div>
								<button className="help-button" onClick={this.openHelpCenter}>
									<FormattedMessage id="settings.app_catalog.help_button" />
								</button>
							</fieldset>
							<fieldset>
								<h3>GitLab.com</h3>
								<div className="content-wrapper">
									<div className="input-fields">
										{this.props.viewer.company.gitlabEnabled ? (
											<>
												<button
													className="gitlab-reauthorize-button"
													onClick={this.sendToGitlab.bind(this)}
												>
													<FormattedMessage id="settings.app_catalog.gitlab.reauthorize" />
												</button>
												<button onClick={this.disableGitlab.bind(this)}>
													<FormattedMessage id="settings.app_catalog.gitlab.deactivate" />
												</button>
											</>
										) : (
											<button onClick={this.sendToGitlab.bind(this)}>
												<FormattedMessage id="settings.app_catalog.gitlab.authorize" />
											</button>
										)}
									</div>
								</div>
							</fieldset>
							<fieldset>
								<h3>Local GitLab server</h3>
								<p>
									<FormattedHTMLMessage id="settings.app_catalog.description-gitlab-server" />
								</p>
								<div className="input-fields">
									<label htmlFor="account_url">
										<FormattedMessage id="settings.app_catalog.gitlab.url" />
										<input
											id="account_url"
											type="text"
											ref={input => (this._account_url = input)}
											placeholder="https://gitlab.example.com"
											value={this.state.accountUrl}
											onChange={this.handleChange.bind(this, 'accountUrl')}
										/>
									</label>
									<label htmlFor="access_token">
										<FormattedMessage id="settings.app_catalog.gitlab.token" />
										<input
											id="access_token"
											type="password"
											ref={input => (this._access_token = input)}
											value={this.state.accessToken}
											onChange={this.handleChange.bind(this, 'accessToken')}
										/>
									</label>
									{this.props.viewer.company.gitlabServerEnabled ? (
										<button
											disabled={this.state.verifyingSave}
											className="submit-button save-button"
											onClick={this.verifyAndSave.bind(this)}
										>
											{this.state.verifyingSave
												? formatMessage({id: 'settings.app_catalog.verifying_button'})
												: formatMessage({id: 'common.save'})}
										</button>
									) : null}
									<button
										disabled={this.state.verifying}
										className="submit-button"
										onClick={this.enableDisable.bind(this)}
									>
										{enableButtonText}
									</button>
								</div>
							</fieldset>
							<fieldset>
								<h3>Settings</h3>
								<div className="input-fields">
									<div className="checkbox-wrapper">
										<Checkbox
											id="gitlab_delete_issues"
											onChange={this.changeDeleteIssuesSetting.bind(this)}
											checked={this.props.viewer.company.gitlabDeleteIssues}
										/>
										<label htmlFor="gitlab_delete_issues">
											{formatMessage({id: 'settings.app_catalog.gitlab.delete_description'})}
										</label>
									</div>
								</div>
							</fieldset>
						</div>
					</div>
				</CustomScrollDiv>
			</div>
		);
	}
}

const appGitlabQuery = graphql`
	query appGitlab_Query {
		viewer {
			actualPersonId
			component(name: "app_gitlab")
			...appGitlab_viewer
		}
	}
`;

export {appGitlabQuery};

export default createFragmentContainer(injectIntl(withRouter(appGitlab)), {
	viewer: graphql`
		fragment appGitlab_viewer on Viewer {
			id
			company {
				id
				gitlabEnabled
				gitlabServerEnabled
				gitlabServerUrl
				gitlabServerToken
				gitlabDeleteIssues
			}
		}
	`,
});
