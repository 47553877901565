import styled from 'styled-components';
export const FlexContainer = styled.div `
	width: 100%;
`;
export const AlreadySharedContainer = styled.div `
	overflow-y: auto;
	overflow-x: hidden;
`;
export const SharedPersonInfoWrapper = styled.div `
	padding: 8px;
	display: flex;
	align-items: center;
`;
export const CrossIconWrapper = styled.div `
	margin-right: 4px;
	visibility: hidden;
	${SharedPersonInfoWrapper}:hover & {
		visibility: visible;
	}
`;
