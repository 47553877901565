import React from 'react';
import {withRouter} from 'react-router-dom';
import {Icon, DropdownHandler, NavBarIconButton} from '@forecast-it/design-system';
import * as tracking from '../../../tracking';
import Util from '../../shared/util/util';
import LogoutMutation from '../../../mutations/logout_mutation';
import LogoutSisenseUserMutation from '../../../mutations/LogoutSisenseUserMutation';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import styled from 'styled-components';
import {isClientUser} from '../../shared/util/PermissionsUtil';
import {HIDDEN_FEATURES} from '../../../constants';
import {setAvailableFeatureFlags} from '../../shared/util/FeatureUtil';

const StyledA = styled.a`
	text-decoration: none;
	&:hover {
		color: inherit;
	}
`;

const ProfileButton = ({onToggleTimer, timerHidden, ...props}) => {
	const handleLogout = () => {
		trackEvent('Navigation Bar Profile Menu Sign Out', 'Clicked');
		const onSuccess = ({login}) => {
			setAvailableFeatureFlags([]);
			tracking.logout();
			window.location.href = window.location.origin;
		};
		if (props.viewer.sisenseUserId) {
			// Log user out of Sisense
			Util.CommitMutation(LogoutSisenseUserMutation, {sisenseUserId: props.viewer.sisenseUserId}, () => {
				Util.CommitMutation(LogoutMutation, null, onSuccess);
			});
		} else {
			Util.CommitMutation(LogoutMutation, null, onSuccess);
		}
	};

	const toggleTimer = () => {
		onToggleTimer && onToggleTimer();
		trackEvent('Navigation Bar Profile Menu Timer', 'Clicked');
	};

	return (
		<DropdownHandler>
			<DropdownHandler.Trigger
				onClick={() => trackEvent('Navigation Bar Profile Menu Button', 'Clicked')}
				data-cy={'profile-dropdown-button'}
			>
				<NavBarIconButton aria-label="my-profile" icon="profile" />
			</DropdownHandler.Trigger>
			<DropdownHandler.Content>
				<DropdownHandler.Item
					onClick={() => {
						trackEvent('Navigation Bar Profile Menu Profile', 'Clicked');
						props.history.push('/my-profile');
					}}
					data-cy={'profile-dropdown-my-profile'}
				>
					<Icon icon="profile" size="m" />
					Profile
				</DropdownHandler.Item>
				{!isClientUser() && !Util.isFeatureHidden(HIDDEN_FEATURES.TIME_REGISTRATIONS) && (
					<DropdownHandler.Item onClick={toggleTimer} data-cy={'profile-dropdown-toggle-timer'}>
						<Icon icon="timer" size="m" />
						{timerHidden ? 'Show Timer' : 'Hide Timer'}
					</DropdownHandler.Item>
				)}
				<DropdownHandler.Item asChild data-cy={'profile-dropdown-refer-and-earn'}>
					<StyledA
						href="https://www.forecast.app/refer-a-friend-to-forecast"
						target="_blank"
						rel="noopener noreferrer"
						onClick={() => trackEvent('Navigation Bar Profile Menu Refer And Earn', 'Clicked')}
					>
						<Icon icon="announce" size="m" />
						Refer & Earn
					</StyledA>
				</DropdownHandler.Item>
				<DropdownHandler.Item onClick={handleLogout} data-cy={'profile-dropdown-log-out'}>
					<Icon icon="logoff" size="m" />
					Sign Out
				</DropdownHandler.Item>
			</DropdownHandler.Content>
		</DropdownHandler>
	);
};

export default withRouter(ProfileButton);
