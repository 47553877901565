const MS_PER_SECOND = 1e3;
const SECS_PER_MIN = 60;
const SECS_PER_HOUR = SECS_PER_MIN * 60;
const SECS_PER_DAY = SECS_PER_HOUR * 24;
const SECS_PER_WEEK = SECS_PER_DAY * 7;

export const DEFAULT_THRESHOLDS = {
	second: 45, // seconds to minute
	minute: 45, // minutes to hour
	hour: 22, // hour to day
	day: 5, // day to week
	week: 4, // week to month
	month: 12, // month to a year
};

/**
 *
 * @param {Date | number} from
 * @param {Date | number} to
 * @param {object} thresholds
 */
export const getTimeDifferenceAndUnit = (from, to = Date.now(), thresholds) => {
	const resolvedThresholds = {
		...DEFAULT_THRESHOLDS,
		...(thresholds || {}),
	};

	const seconds = (+from - +to) / MS_PER_SECOND;
	if (Math.abs(seconds) < resolvedThresholds.second) {
		return {
			value: Math.round(seconds),
			unit: 'second',
		};
	}

	const mins = seconds / SECS_PER_MIN;
	if (Math.abs(mins) < resolvedThresholds.minute) {
		return {
			value: Math.round(mins),
			unit: 'minute',
		};
	}

	const hours = seconds / SECS_PER_HOUR;
	if (Math.abs(hours) < resolvedThresholds.hour) {
		return {
			value: Math.round(hours),
			unit: 'hour',
		};
	}

	const days = seconds / SECS_PER_DAY;
	if (Math.abs(days) < resolvedThresholds.day) {
		return {
			value: Math.round(days),
			unit: 'day',
		};
	}

	const weeks = seconds / SECS_PER_WEEK;
	if (Math.abs(weeks) < resolvedThresholds.week) {
		return {
			value: Math.round(weeks),
			unit: 'week',
		};
	}

	const fromDate = new Date(from);
	const toDate = new Date(to);

	const years = fromDate.getFullYear() - toDate.getFullYear();
	const months = years * 12 + fromDate.getMonth() - toDate.getMonth();
	if (months < resolvedThresholds.month) {
		return {
			value: months,
			unit: 'month',
		};
	}

	return {
		value: years,
		unit: 'year',
	};
};
