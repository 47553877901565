import Util from '../../../../shared/util/util';
import SaveReportAs from '../../../../../mutations/ts/reporting/SaveReportAsMutation';
import { createToast } from '../../../../shared/components/toasts/another-toast/toaster';
import { MODAL_TYPE, showModal } from '../../../../shared/components/modals/generic_modal_conductor';
const saveReportAsMutation = (intl, closeModal, callback, report) => {
    const onSuccess = () => {
        createToast({
            duration: 5000,
            message: intl.formatMessage({ id: 'common.action.saved' }, { entity: report.name }),
        });
        closeModal();
        if (callback && callback instanceof Function) {
            callback();
        }
    };
    Util.CommitMutation(SaveReportAs, report, onSuccess);
};
export const saveReportAs = (intl, report, callback) => {
    showModal({
        report: report,
        type: MODAL_TYPE.SAVE_REPORT_AS_MODAL,
        saveReportAs: (closeModal, saveReportAs) => saveReportAsMutation(intl, closeModal, callback, saveReportAs),
    });
};
