/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProjectScopingPredictionsQueryVariables = {|
  projectId?: ?string
|};
export type ProjectScopingPredictionsQueryResponse = {|
  +viewer: ?{|
    +id: string,
    +component: ?string,
    +company: ?{|
      +availableMLModels: ?{|
        +lowHighModel: ?boolean,
        +lowHighModelScore: ?number,
      |}
    |},
    +predictionProject: ?{|
      +id: string,
      +predictedOverrunningTasks: ?$ReadOnlyArray<?{|
        +globalTaskId: ?string,
        +estimate: ?number,
        +overrunProbability: ?number,
      |}>,
    |},
  |}
|};
export type ProjectScopingPredictionsQuery = {|
  variables: ProjectScopingPredictionsQueryVariables,
  response: ProjectScopingPredictionsQueryResponse,
|};
*/


/*
query ProjectScopingPredictionsQuery(
  $projectId: ID
) {
  viewer {
    id
    component(name: "scoping_predictions")
    company {
      availableMLModels {
        lowHighModel
        lowHighModelScore
      }
      id
    }
    predictionProject: project(internalId: $projectId) {
      id
      predictedOverrunningTasks {
        globalTaskId
        estimate
        overrunProbability
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId",
    "type": "ID"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "scoping_predictions"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"scoping_predictions\")"
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "AvailableMLModels",
  "kind": "LinkedField",
  "name": "availableMLModels",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lowHighModel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lowHighModelScore",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": "predictionProject",
  "args": [
    {
      "kind": "Variable",
      "name": "internalId",
      "variableName": "projectId"
    }
  ],
  "concreteType": "ProjectType",
  "kind": "LinkedField",
  "name": "project",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "PredictedEstimate",
      "kind": "LinkedField",
      "name": "predictedOverrunningTasks",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "globalTaskId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "estimate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "overrunProbability",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectScopingPredictionsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectScopingPredictionsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "ProjectScopingPredictionsQuery",
    "operationKind": "query",
    "text": "query ProjectScopingPredictionsQuery(\n  $projectId: ID\n) {\n  viewer {\n    id\n    component(name: \"scoping_predictions\")\n    company {\n      availableMLModels {\n        lowHighModel\n        lowHighModelScore\n      }\n      id\n    }\n    predictionProject: project(internalId: $projectId) {\n      id\n      predictedOverrunningTasks {\n        globalTaskId\n        estimate\n        overrunProbability\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '94318d9e4b22d444f8e94e105174962a';

module.exports = node;
