import React, { useState } from 'react';
import { FlexColumn, Label, Modal } from '@forecast-it/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import Util from '../../../forecast-app/shared/util/util';
import RichTextItem from '../../../components/richtext_item';
import UpdateClientMutation from '../../../mutations/update_client_mutation';
import styled from 'styled-components';
export const RichTextWrapper = styled.div `
	.editor {
		border-color: #6b6d7b;
		border-radius: 4px;
		&:hover {
			border-color: #6b6d7b;
		}
		&.client-notes-editor {
			margin: 0;
			.public-DraftEditor-content {
				min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : '0px')};
			}
		}
	}
`;
const EditNotesModal = ({ clientId, currentNotes, closeModal }) => {
    const { formatMessage } = useIntl();
    const [notes, setNotes] = useState(currentNotes !== null && currentNotes !== void 0 ? currentNotes : '');
    const handleClientNotesUpdate = editorState => setNotes(editorState);
    const saveChanges = () => Util.CommitMutation(UpdateClientMutation, {
        id: clientId,
        notes: notes,
    }, closeModal);
    return (React.createElement(Modal, { onCloseModal: closeModal },
        React.createElement(Modal.Title, null,
            React.createElement(FormattedMessage, { id: "settings_clients.edit_notes" })),
        React.createElement(Modal.Content, null,
            React.createElement(FlexColumn, null,
                React.createElement(Label, null, formatMessage({ id: 'settings_clients.client_notes' })),
                React.createElement(RichTextWrapper, { minHeight: 60 },
                    React.createElement(RichTextItem, { readOnly: false, text: notes, placeholder: formatMessage({ id: 'settings_clients.enter_client_note' }), handleTextChange: handleClientNotesUpdate.bind(this), hideEmojiPicker: true, alwaysShowControls: true, alwaysShowBorder: true, clientNotesEditor: true })))),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { onPress: saveChanges },
                React.createElement(FormattedMessage, { id: 'common.save_changes' })),
            React.createElement(Modal.SecondaryFooterButton, { onPress: closeModal },
                React.createElement(FormattedMessage, { id: 'common.close' })))));
};
export default EditNotesModal;
