import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import {DATE_PICKER_STYLE} from '../../../../constants';
import WarningIcon from '../../../../images/warning_icon';

class DatePickerButton extends Component {
	handleOnClick(e) {
		e.stopPropagation();
		this.props.onClick(e);
	}

	handleKeyDown(e) {
		if (e.key === 'Enter') {
			e.stopPropagation();
			this.props.onClick(e);
		}
	}

	getButtonContent() {
		const {date, disabled, onBlur, isDateRangeEndDateButton, warning, dateInherited, leftAlign} = this.props;
		const {formatDate, formatMessage} = this.props.intl;
		switch (this.props.datePickerStyle) {
			case DATE_PICKER_STYLE.TASK_MODAL_TASK_DATE:
				return (
					<button
						data-cy={this.props.cy}
						className={
							'date-picker-button task-modal-task-date' +
							(disabled ? ' locked' : '') +
							(isDateRangeEndDateButton ? ' end-date-picker-button' : '') +
							(this.props.active ? ' active' : '')
						}
						onClick={e => this.handleOnClick(e)}
						disabled={disabled}
						onBlur={onBlur}
						ref={this.props.reference ? this.props.reference : null}
						data-userpilot={this.props.userpilot}
					>
						{this.props.label ? (
							<div className="date-picker-button-label">{this.props.label.toUpperCase()}</div>
						) : null}
						<div className="date-text-container">
							{warning && warning.showWarning ? (
								<span className="warning" title={warning.message}>
									<WarningIcon danger />
								</span>
							) : null}

							{date ? (
								<>
									<span className="date-text-bold">
										{formatDate(date, {
											day: 'numeric',
										})}
									</span>{' '}
									<span className="date-text-bold">
										{formatDate(date, {
											month: 'short',
										}).toUpperCase()}
									</span>{' '}
									<span>
										{formatDate(date, {
											year: warning && warning.showWarning ? '2-digit' : 'numeric',
										})}
									</span>
									<span>{dateInherited ? '*' : ''}</span>
								</>
							) : (
								<span>-</span>
							)}
						</div>
					</button>
				);
			case DATE_PICKER_STYLE.COMPACT:
				return (
					<button
						data-cy={this.props.cy}
						className={
							'date-picker-button date-picker-button-compact' +
							(disabled ? ' locked' : '') +
							(isDateRangeEndDateButton ? ' end-date-picker-button' : '')
						}
						onClick={e => this.handleOnClick(e)}
						disabled={disabled}
						onBlur={onBlur}
						ref={this.props.reference ? this.props.reference : null}
					>
						{date ? (
							<div className="date-text-container">
								<div className="date-text-bold">
									<div>
										{formatDate(date, {
											day: 'numeric',
										})}{' '}
									</div>
									<div>
										{formatDate(date, {
											month: 'short',
										}).toUpperCase()}
									</div>
								</div>
							</div>
						) : (
							<div className="date-picker-icon" />
						)}
					</button>
				);
			case DATE_PICKER_STYLE.STANDARD:
			case DATE_PICKER_STYLE.INVOICE:
			case DATE_PICKER_STYLE.MODAL:
			case DATE_PICKER_STYLE.SLIM:
				return (
					<button
						data-cy={this.props.cy}
						className={
							'date-picker-button date-picker-button-standard' +
							(disabled ? ' locked' : '') +
							(isDateRangeEndDateButton ? ' end-date-picker-button' : '') +
							(this.props.active ? ' active' : '') +
							(this.props.highlighted ? ' highlighted' : '') +
							(this.props.fullWidth ? ' fullWidth' : '') +
							(this.props.datePickerStyle === DATE_PICKER_STYLE.SLIM ? ' slim' : '')
						}
						onClick={e => this.handleOnClick(e)}
						onKeyPress={this.handleKeyDown.bind(this)}
						disabled={disabled}
						onBlur={onBlur}
						ref={this.props.reference ? this.props.reference : null}
						autoFocus={this.props.focus}
					>
						{date ? (
							<div className="date-text-container">
								<>
									<span className="date-text-bold">
										{formatDate(date, {
											day: 'numeric',
										})}
									</span>{' '}
									<span className="date-text-bold">
										{formatDate(date, {
											month: 'short',
										}).toUpperCase()}
									</span>{' '}
									<span>
										{formatDate(date, {
											year: 'numeric',
										})}
									</span>
								</>
							</div>
						) : isDateRangeEndDateButton ? (
							this.props.endDateEmptyText ? (
								<div className="empty-text">{this.props.endDateEmptyText}</div>
							) : (
								<div className="date-picker-icon" />
							)
						) : this.props.startDateEmptyText ? (
							<div className="empty-text">{this.props.startDateEmptyText}</div>
						) : (
							<div className="date-picker-icon" />
						)}
					</button>
				);
			case DATE_PICKER_STYLE.RATE_CARD_MODAL:
				return (
					<button
						data-cy={this.props.cy}
						className={
							'date-picker-button date-picker-button-standard' +
							(disabled ? ' locked' : '') +
							(this.props.active ? ' active' : '') +
							(this.props.fullWidth ? ' fullWidth' : '') +
							(this.props.highlighted ? ' highlighted' : '')
						}
						onClick={e => this.handleOnClick(e)}
						onKeyPress={this.handleKeyDown.bind(this)}
						disabled={disabled}
						onBlur={onBlur}
						ref={this.props.reference ? this.props.reference : null}
						autoFocus={this.props.focus}
					>
						{date ? (
							<div className={'date-text-container' + (leftAlign ? ' leftAlign' : '')}>
								<span>{date.format('Do MMM YYYY')}</span>
							</div>
						) : this.props.startDateEmptyText ? (
							<div className={'empty-text' + (leftAlign ? ' leftAlign' : '')}>
								{this.props.startDateEmptyText}
							</div>
						) : (
							<div className="date-picker-icon" />
						)}
					</button>
				);
			case DATE_PICKER_STYLE.SCOPING_PHASE_DATE:
				return (
					<button
						data-cy={this.props.cy}
						className={
							'date-picker-button scoping-phase-date' +
							(disabled ? ' locked' : '') +
							(isDateRangeEndDateButton ? ' end-date-picker-button' : '') +
							(this.props.active ? ' active' : '') +
							(!date && !isDateRangeEndDateButton && !this.props.blank ? ' double' : '') +
							(this.props.highlighted ? ' highlighted' : '') +
							(this.props.compactShowYear ? ' show-year' : '')
						}
						id={this.props.id}
						onClick={e => this.handleOnClick(e)}
						disabled={disabled}
						onBlur={onBlur}
						ref={this.props.reference ? this.props.reference : null}
					>
						{this.props.label ? (
							<div className="date-picker-button-label">{this.props.label.toUpperCase()}</div>
						) : null}

						<div className="date-text-container" data-cy={this.props.cy + '-container'}>
							{date ? (
								<>
									<span
										className={
											this.props.fontWeight === 'normal' ? 'date-text-normal' : 'date-text-bold large'
										}
										data-cy={this.props.cy + '-day-number-text'}
									>
										{formatDate(date, {
											day: 'numeric',
										})}
									</span>{' '}
									<span
										className={
											this.props.fontWeight === 'normal' ? 'date-text-normal' : 'date-text-bold large'
										}
									>
										{formatDate(date, {
											month: 'short',
										}).toUpperCase()}
									</span>
									{this.props.compactShowYear ? (
										<span className={'date-text-normal year'}>
											{formatDate(date, {
												year: 'numeric',
											})}
										</span>
									) : null}
									{dateInherited ? <span>*</span> : null}
								</>
							) : isDateRangeEndDateButton || this.props.blank ? (
								<>
									<span
										className={
											this.props.fontWeight === 'normal' ? 'date-text-normal' : 'date-text-bold large'
										}
										data-cy={this.props.cy + '-day-number-text'}
									></span>{' '}
									<span
										className={
											this.props.fontWeight === 'normal' ? 'date-text-normal' : 'date-text-bold large'
										}
									></span>
								</>
							) : (
								<span className="italic double">
									{formatMessage({id: disabled ? 'filter_type.deadline.no_dates' : 'common.select_dates'})}
								</span>
							)}
						</div>
					</button>
				);
			case DATE_PICKER_STYLE.BASELINE_PHASE_DATE:
				return (
					<button
						data-cy={this.props.cy}
						className={
							'date-picker-button baseline-phase-date' +
							(disabled ? ' locked' : '') +
							(isDateRangeEndDateButton ? ' end-date-picker-button' : '') +
							(this.props.active ? ' active' : '') +
							(!date && !isDateRangeEndDateButton ? ' double' : '') +
							(this.props.highlighted ? ' highlighted' : '') +
							(this.props.compactShowYear ? ' show-year' : '')
						}
						id={this.props.id}
						onClick={e => this.handleOnClick(e)}
						disabled={disabled}
						onBlur={onBlur}
						ref={this.props.reference ? this.props.reference : null}
					>
						{this.props.label ? (
							<div className="date-picker-button-label">{this.props.label.toUpperCase()}</div>
						) : null}

						<div className="date-text-container baseline" data-cy={this.props.cy + '-container'}>
							{date ? (
								<>
									<span className={'date-text-normal'} data-cy={this.props.cy + '-day-number-text'}>
										{formatDate(date, {
											day: 'numeric',
											month: 'short',
										})}
									</span>
									<span>{', '}</span>
									{this.props.compactShowYear ? (
										<span className={'date-text-normal'}>
											{formatDate(date, {
												year: 'numeric',
											})}
										</span>
									) : null}
									{dateInherited ? '*' : ''}
								</>
							) : isDateRangeEndDateButton ? (
								<>
									<span
										className={
											this.props.fontWeight === 'normal' ? 'date-text-normal' : 'date-text-bold large'
										}
										data-cy={this.props.cy + '-day-number-text'}
									></span>{' '}
									<span
										className={
											this.props.fontWeight === 'normal' ? 'date-text-normal' : 'date-text-bold large'
										}
									></span>
								</>
							) : (
								<span className="italic double">
									{formatMessage({id: disabled ? 'filter_type.deadline.no_dates' : 'common.select_dates'})}
								</span>
							)}
						</div>
					</button>
				);
			default:
				return null;
		}
	}

	render() {
		return (
			<div
				className={
					'date-picker-button-container-v3' +
					(this.props.colorInherited ? ' inherit' : '') +
					(this.props.fullWidth ? ' fullWidth' : '') +
					(!this.props.date && !this.props.isDateRangeEndDateButton ? ' double' : '')
				}
			>
				{this.getButtonContent()}
			</div>
		);
	}
}
DatePickerButton.propTypes = {
	date: PropTypes.object,
	onClick: PropTypes.func.isRequired,
	locked: PropTypes.bool,
	colorInherited: PropTypes.bool,
	onBlur: PropTypes.func,
	label: PropTypes.string,
	cy: PropTypes.string,
	startDateEmptyText: PropTypes.string,
	endDateEmptyText: PropTypes.string,
	focus: PropTypes.bool,
	fullWidth: PropTypes.bool,
	leftAlign: PropTypes.bool,
};

export default injectIntl(DatePickerButton);
