import React from 'react';
import { ValueCell } from '../../../../../../components/initial-plan/ValueCell';
import { Data, Title } from '../ProjectScopingTotals_styled';
import { Tile, TileRow } from './Tile_styled';
export const AvailabilityTile = ({ totalsData, intl }) => {
    return (React.createElement(Tile, null,
        React.createElement(TileRow, null,
            React.createElement(Title, null, intl.formatMessage({ id: 'common.total_availability' })),
            React.createElement(Data, { small: true },
                React.createElement(ValueCell, { noJustify: true, type: ValueCell.VALUE_TYPE.TIME, value: totalsData.totalAvailability }))),
        React.createElement(TileRow, null,
            React.createElement(Title, null, intl.formatMessage({ id: 'common.remaining_availability' })),
            React.createElement(Data, { small: true },
                React.createElement(ValueCell, { noJustify: true, type: ValueCell.VALUE_TYPE.TIME, value: totalsData.remainingAvailability })))));
};
