import React from 'react';
import {FlexRow} from '@forecast-it/design-system';
import {DeprecatedText as Text} from '@forecast-it/design-system';
import styled from 'styled-components';

const WrapperDiv = styled.div`
	margin-top: 25px;
	margin-bottom: 10px;
`;

const ProjectBudgetBreakdownHeader = ({prefix, color, name}) => {
	return (
		<WrapperDiv>
			<FlexRow alignItems={'flex-start'} gap={'l'}>
				<Text type={'heading'} variant={'l'}>
					Project budget breakdown
				</Text>
			</FlexRow>
		</WrapperDiv>
	);
};

export default ProjectBudgetBreakdownHeader;
