import React from 'react';
import Util from '../../../../../forecast-app/shared/util/util';
import CreatePhaseProgressMutation from '../../../../../mutations/create_phase_progress_mutation';
import {ProgressRegistrationPopup} from './ProgressRegistrationPopup';
import {ForecastThemeProvider} from '../../../ForecastThemeProvider';
import {createToast} from '../../toasts/toast';
import {useIntl} from 'react-intl';

export const PhaseProgressRegistrationPopup = ({phase, projectId}) => {
	const intl = useIntl();

	const savePhaseProgress = (phase, newProgressValue) => {
		const onSuccess = () => {
			createToast({
				duration: 5000,
				message: intl.formatMessage({id: 'common.progress-updated'}),
			});
		};
		if (!isNaN(newProgressValue)) {
			Util.CommitMutation(
				CreatePhaseProgressMutation,
				{
					projectId: projectId,
					phaseId: phase.id,
					progress: newProgressValue,
				},
				onSuccess
			);
		}
	};

	return (
		<ForecastThemeProvider>
			<ProgressRegistrationPopup
				currentProgressValue={phase.progress}
				onClose={newProgressValue => savePhaseProgress(phase, newProgressValue)}
			/>
		</ForecastThemeProvider>
	);
};
