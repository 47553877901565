/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
query RoleDropdown_Query(
  $projectId: ID
  $taskId: ID
  $personId: ID
) {
  viewer {
    component(name: "role_dropdown")
    actualPersonId
    id
    ...RoleDropdown_viewer_vtVxH
  }
}

fragment RoleDropdown_viewer_vtVxH on Viewer {
  company {
    roles(includeDisabled: false, projectId: $projectId) {
      edges {
        node {
          id
          name
        }
      }
    }
    person(id: $personId) {
      id
      role {
        id
        name
      }
    }
    id
  }
  project(internalId: $projectId) {
    projectPerson(personId: $personId) {
      role {
        id
        name
      }
      id
    }
    id
  }
  task(id: $taskId) {
    role {
      id
      name
    }
    id
  }
}
*/
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "projectId",
            "type": "ID"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "taskId",
            "type": "ID"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "personId",
            "type": "ID"
        }
    ], v1 = {
        "alias": null,
        "args": [
            {
                "kind": "Literal",
                "name": "name",
                "value": "role_dropdown"
            }
        ],
        "kind": "ScalarField",
        "name": "component",
        "storageKey": "component(name:\"role_dropdown\")"
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actualPersonId",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v4 = {
        "kind": "Variable",
        "name": "personId",
        "variableName": "personId"
    }, v5 = {
        "kind": "Variable",
        "name": "projectId",
        "variableName": "projectId"
    }, v6 = [
        (v3 /*: any*/),
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
        }
    ], v7 = {
        "alias": null,
        "args": null,
        "concreteType": "Role",
        "kind": "LinkedField",
        "name": "role",
        "plural": false,
        "selections": (v6 /*: any*/),
        "storageKey": null
    }, v8 = [
        (v7 /*: any*/),
        (v3 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "RoleDropdown_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "args": [
                                (v4 /*: any*/),
                                (v5 /*: any*/),
                                {
                                    "kind": "Variable",
                                    "name": "taskId",
                                    "variableName": "taskId"
                                }
                            ],
                            "kind": "FragmentSpread",
                            "name": "RoleDropdown_viewer"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "RoleDropdown_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Company",
                            "kind": "LinkedField",
                            "name": "company",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": [
                                        {
                                            "kind": "Literal",
                                            "name": "includeDisabled",
                                            "value": false
                                        },
                                        (v5 /*: any*/)
                                    ],
                                    "concreteType": "RoleTypeConnection",
                                    "kind": "LinkedField",
                                    "name": "roles",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "RoleTypeEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Role",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": (v6 /*: any*/),
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": [
                                        {
                                            "kind": "Variable",
                                            "name": "id",
                                            "variableName": "personId"
                                        }
                                    ],
                                    "concreteType": "Person",
                                    "kind": "LinkedField",
                                    "name": "person",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        (v7 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v3 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "internalId",
                                    "variableName": "projectId"
                                }
                            ],
                            "concreteType": "ProjectType",
                            "kind": "LinkedField",
                            "name": "project",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": [
                                        (v4 /*: any*/)
                                    ],
                                    "concreteType": "ProjectPerson",
                                    "kind": "LinkedField",
                                    "name": "projectPerson",
                                    "plural": false,
                                    "selections": (v8 /*: any*/),
                                    "storageKey": null
                                },
                                (v3 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "id",
                                    "variableName": "taskId"
                                }
                            ],
                            "concreteType": "Task",
                            "kind": "LinkedField",
                            "name": "task",
                            "plural": false,
                            "selections": (v8 /*: any*/),
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "RoleDropdown_Query",
            "operationKind": "query",
            "text": "query RoleDropdown_Query(\n  $projectId: ID\n  $taskId: ID\n  $personId: ID\n) {\n  viewer {\n    component(name: \"role_dropdown\")\n    actualPersonId\n    id\n    ...RoleDropdown_viewer_vtVxH\n  }\n}\n\nfragment RoleDropdown_viewer_vtVxH on Viewer {\n  company {\n    roles(includeDisabled: false, projectId: $projectId) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n    person(id: $personId) {\n      id\n      role {\n        id\n        name\n      }\n    }\n    id\n  }\n  project(internalId: $projectId) {\n    projectPerson(personId: $personId) {\n      role {\n        id\n        name\n      }\n      id\n    }\n    id\n  }\n  task(id: $taskId) {\n    role {\n      id\n      name\n    }\n    id\n  }\n}\n"
        }
    };
})();
node.hash = 'e2e7914ed43335f9af7404b0d3ba819d';
export default node;
