import React from 'react';
import styled from 'styled-components';
import Asterisk from '../../inputs/Asterisk';
const RequiredLabelWrapper = styled.div `
	display: flex;
	align-items: center;
	margin-bottom: ${p => (p.isTimePage ? null : '2px')};
	height: 11px;

	line-height: ${p => (p.isTimePage ? null : '0')};
`;
function LabelWithTooltip({ label, isRequired, alwaysShowTooltip, children, isTimePage }) {
    return (React.createElement(RequiredLabelWrapper, { isTimePage: isTimePage },
        React.createElement("div", { style: { marginTop: isTimePage ? '0' : '2px' } }, label),
        isRequired ? React.createElement(Asterisk, null) : null,
        isRequired || alwaysShowTooltip ? React.createElement("div", { style: { marginLeft: '4px' } }, children) : null));
}
export default LabelWithTooltip;
