import { idFromGlobalId } from '../../../../../util/GlobalIdUtil';
import { TimeRegistrationEntity } from '../types/TimeRegistrationEntityId';
export function toEntityId({ taskId, projectId, idleTimeId }) {
    if (taskId) {
        return { entity: TimeRegistrationEntity.Task, id: idFromGlobalId(taskId) };
    }
    if (projectId) {
        return { entity: TimeRegistrationEntity.Project, id: idFromGlobalId(projectId) };
    }
    if (idleTimeId) {
        return { entity: TimeRegistrationEntity.IdleTime, id: idFromGlobalId(idleTimeId) };
    }
    return null;
}
export function toBackendEntityId(entityId, projectId) {
    switch (entityId.entity) {
        case TimeRegistrationEntity.Task:
            return { taskId: entityId.id, projectId: projectId ? idFromGlobalId(projectId) : null, idleTimeId: null };
        case TimeRegistrationEntity.Project:
            return { taskId: null, projectId: entityId.id, idleTimeId: null };
        case TimeRegistrationEntity.IdleTime:
            return { taskId: null, projectId: null, idleTimeId: entityId.id };
        default:
            return {};
    }
}
export function searchResultToBackendEntityId({ id, projectId, variant, }) {
    switch (variant) {
        case 'TASK':
            return { taskId: id, projectId: projectId, idleTimeId: null };
        case 'PROJECT':
            return { taskId: null, projectId: id, idleTimeId: null };
        case 'INTERNAL_TIME':
            return { taskId: null, projectId: null, idleTimeId: id };
        default:
            return {};
    }
}
export function isTaskEntity(entity) {
    return entity === TimeRegistrationEntity.Task;
}
export function isProjectEntity(entity) {
    return entity === TimeRegistrationEntity.Project;
}
export function getEntityProject(targetEntity) {
    switch (targetEntity === null || targetEntity === void 0 ? void 0 : targetEntity.entity) {
        case TimeRegistrationEntity.Task:
            return targetEntity.project;
        case TimeRegistrationEntity.Project:
            return targetEntity;
        default:
            return null;
    }
}
export function isTargetEntityBillable(targetEntity) {
    var _a;
    // billable === true is the default state
    if (!targetEntity)
        return true;
    switch (targetEntity.entity) {
        case TimeRegistrationEntity.Task:
        case TimeRegistrationEntity.Project:
            return (_a = targetEntity.billable) !== null && _a !== void 0 ? _a : true;
        case TimeRegistrationEntity.IdleTime:
            return false;
    }
}
export function doesTargetEntityAllowRoles(targetEntity) {
    // roles === true is the default state
    if (!targetEntity)
        return true;
    switch (targetEntity.entity) {
        case TimeRegistrationEntity.Task:
        case TimeRegistrationEntity.Project:
            return true;
        case TimeRegistrationEntity.IdleTime:
            return false;
    }
}
