/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type appJiraServer_viewer$ref = any;
export type appJiraServer_QueryVariables = {||};
export type appJiraServer_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: appJiraServer_viewer$ref,
  |}
|};
export type appJiraServer_Query = {|
  variables: appJiraServer_QueryVariables,
  response: appJiraServer_QueryResponse,
|};
*/


/*
query appJiraServer_Query {
  viewer {
    actualPersonId
    component(name: "app_jira_server")
    ...appJiraServer_viewer
    id
  }
}

fragment appJiraServer_viewer on Viewer {
  id
  availableFeatureFlags {
    key
    id
  }
  company {
    id
    useTimeApproval
    integrations {
      jiraServer {
        syncSettings {
          isJiraToForecastOneWaySync
        }
        id
      }
      id
    }
    jiraServerPublicRsaKey
    jiraServerConsumerKey
    jiraServerAccessToken
    jiraServerUrl
    jiraServerPrefixType
    jiraServerPrefixLoading
    jiraServerState
    jiraServerEnabled
    unSynchedJiraServerProjects {
      id
      name
      key
    }
  }
  projects(first: 10000) {
    edges {
      node {
        id
        projectColor
        companyProjectId
        customProjectId
        name
        isJiraProject
        jiraServerProject {
          name
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "app_jira_server"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"app_jira_server\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "appJiraServer_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "appJiraServer_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "appJiraServer_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FeatureFlag",
            "kind": "LinkedField",
            "name": "availableFeatureFlags",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "useTimeApproval",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CompanyIntegrationsType",
                "kind": "LinkedField",
                "name": "integrations",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CompanyJiraSettingsType",
                    "kind": "LinkedField",
                    "name": "jiraServer",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "JiraSyncSettingsType",
                        "kind": "LinkedField",
                        "name": "syncSettings",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isJiraToForecastOneWaySync",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "jiraServerPublicRsaKey",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "jiraServerConsumerKey",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "jiraServerAccessToken",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "jiraServerUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "jiraServerPrefixType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "jiraServerPrefixLoading",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "jiraServerState",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "jiraServerEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "JiraServerProjectType",
                "kind": "LinkedField",
                "name": "unSynchedJiraServerProjects",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 10000
              }
            ],
            "concreteType": "ProjectTypeConnection",
            "kind": "LinkedField",
            "name": "projects",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectColor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "companyProjectId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "customProjectId",
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isJiraProject",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "JiraServerProjectType",
                        "kind": "LinkedField",
                        "name": "jiraServerProject",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "projects(first:10000)"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "appJiraServer_Query",
    "operationKind": "query",
    "text": "query appJiraServer_Query {\n  viewer {\n    actualPersonId\n    component(name: \"app_jira_server\")\n    ...appJiraServer_viewer\n    id\n  }\n}\n\nfragment appJiraServer_viewer on Viewer {\n  id\n  availableFeatureFlags {\n    key\n    id\n  }\n  company {\n    id\n    useTimeApproval\n    integrations {\n      jiraServer {\n        syncSettings {\n          isJiraToForecastOneWaySync\n        }\n        id\n      }\n      id\n    }\n    jiraServerPublicRsaKey\n    jiraServerConsumerKey\n    jiraServerAccessToken\n    jiraServerUrl\n    jiraServerPrefixType\n    jiraServerPrefixLoading\n    jiraServerState\n    jiraServerEnabled\n    unSynchedJiraServerProjects {\n      id\n      name\n      key\n    }\n  }\n  projects(first: 10000) {\n    edges {\n      node {\n        id\n        projectColor\n        companyProjectId\n        customProjectId\n        name\n        isJiraProject\n        jiraServerProject {\n          name\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c56b4bb3eea2269c90d7deab4aeb6830';

module.exports = node;
