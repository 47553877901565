/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "externalId",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": {
            "connection": [
                {
                    "count": null,
                    "cursor": null,
                    "direction": "forward",
                    "path": null
                }
            ]
        },
        "name": "AddBillToEntityModal_viewer",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "BillFrom",
                        "kind": "LinkedField",
                        "name": "billFroms",
                        "plural": true,
                        "selections": [
                            (v0 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "integration",
                                "storageKey": null
                            },
                            (v1 /*: any*/),
                            (v2 /*: any*/),
                            {
                                "alias": "billTos",
                                "args": null,
                                "concreteType": "BillToTypeConnection",
                                "kind": "LinkedField",
                                "name": "__BillFrom_billTos_connection",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "BillToTypeEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "BillTo",
                                                "kind": "LinkedField",
                                                "name": "node",
                                                "plural": false,
                                                "selections": [
                                                    (v0 /*: any*/),
                                                    (v2 /*: any*/),
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "address",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "taxId",
                                                        "storageKey": null
                                                    },
                                                    (v1 /*: any*/),
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "__typename",
                                                        "storageKey": null
                                                    }
                                                ],
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "cursor",
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "PageInfo",
                                        "kind": "LinkedField",
                                        "name": "pageInfo",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "endCursor",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "hasNextPage",
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Viewer"
    };
})();
node.hash = '6726d6bd7d6439232bc1517836591b65';
export default node;
