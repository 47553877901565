import React, {useEffect} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {FormattedMessage, injectIntl} from 'react-intl';
import {useHistory, withRouter} from 'react-router-dom';
import CustomScrollDiv from '../../shared/components/scroll-bars/custom_scroll_div';
import {ELEMENT_TYPE} from '../../../constants';
import {buildHeaderBar} from '../../shared/components/headers/header-bar/header_bar';
import {useTrackPage} from '../../../tracking/amplitude/hooks/useTrackPage';
import googleSSOLogo from '../../../images/integrations/google-logo.svg';
import ToggleGoogleSSOMutation from '../../../mutations/ToggleGoogleSSOMutation';
import Util from '../../shared/util/util';

const AppGoogleSSO = ({viewer}) => {
	const history = useHistory();
	useTrackPage('settings-app-catalog-google-sso');
	useEffect(() => {
		document.title = 'Google SSO - Apps & Integrations - Forecast';
	}, []);

	const toggleGoogleSSO = enabled => {
		Util.CommitMutation(ToggleGoogleSSOMutation, {
			enableGoogleSSO: enabled,
		});
	};

	const getHeader = () => {
		const leftContent = [];

		leftContent.push({type: ELEMENT_TYPE.BACK_BUTTON, onClick: history.goBack});

		return buildHeaderBar(leftContent, []);
	};

	return (
		<div className="section-content settings-app settings-app-page">
			{getHeader()}
			<CustomScrollDiv>
				<div className="section-body">
					<div className="inner">
						<fieldset className="general">
							<div className="header-wrapper">
								<img width={100} height={100} alt="Google" src={googleSSOLogo} />
								<div className="title-description-wrapper">
									<div className="description">
										<FormattedMessage id="settings.app_catalog.google_sso_description" />
									</div>
								</div>
							</div>

							{viewer.company.googleSSOEnabled ? (
								<button className="submit-button" onClick={() => toggleGoogleSSO(false)}>
									<FormattedMessage id="common.deactivate" />
								</button>
							) : (
								<button className="submit-button" onClick={() => toggleGoogleSSO(true)}>
									<FormattedMessage id="common.activate" />
								</button>
							)}
						</fieldset>
					</div>
				</div>
			</CustomScrollDiv>
		</div>
	);
};

const AppGoogleSSOQuery = graphql`
	query AppGoogleSSO_Query {
		viewer {
			actualPersonId
			component(name: "app_google_sso")
			...AppGoogleSSO_viewer
		}
	}
`;

export {AppGoogleSSOQuery};

export default createFragmentContainer(injectIntl(withRouter(AppGoogleSSO)), {
	viewer: graphql`
		fragment AppGoogleSSO_viewer on Viewer {
			id
			company {
				id
				googleSSOEnabled
			}
		}
	`,
});
