import styled from 'styled-components';
import { calendarTokens } from '@forecast-it/design-system';
import { CellTextBold } from '../TimesheetsCalendar_styled';
const tokens = calendarTokens.calendar;
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const TodayText = styled(CellTextBold) `
	color: ${tokens.color.text.content.ondark};
	background: ${tokens.color.border.midemphasis};
	text-align: center;
	border-radius: 12px;
	height: 24px;
	width: 24px;
	padding-top: 4px;
	position: relative;
	right: 2px;
`;
