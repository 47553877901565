import React from 'react';
import {useIntl} from 'react-intl';
import GenericModal, {MODAL_WIDTH} from '../../../../../../containers/modal/generic_modal';
import {BUTTON_COLOR, DATE_FORMAT_DAY, DATE_PICKER_STYLE, GLOBAL_FILTER_FIELD} from '../../../../../../constants';
import DatePicker from '../../../date-picker/date_picker_v3';
import moment from 'moment';
import {MODAL_TYPE, showModal} from '../../generic_modal_conductor';
import {trackEvent} from '../../../../../../tracking/amplitude/TrackingV2';

/**
 * This component is used to export time registration using a modal
 * with a date range picker. When the dates are confirmed it will
 * call the DOWNLOADING_QUERY_DATA Modal, which will execute the
 * export of the data.
 *
 * @param searchQuery The Search Query to extract the time registrations. If START_DATE_TIME_REG / END_DATE_TIME_REG are provided, they will be set in the date picker.
 * @param enabledColumns The columns to export
 * @param closeModal The close modal function
 * @param legacyDownload Use the old CSV download function
 * @returns {JSX.Element} The component
 * @constructor
 */
const ReportedTimeDateModal = ({searchQuery, enabledColumns, closeModal, legacyDownload = false}) => {
	const {formatMessage} = useIntl();

	const onTaskDateChange = (d1, d2) => {
		searchQuery.filters.find(filter => filter.field === GLOBAL_FILTER_FIELD.START_DATE_TIME_REG).value =
			d1.format(DATE_FORMAT_DAY);
		searchQuery.filters.find(filter => filter.field === GLOBAL_FILTER_FIELD.END_DATE_TIME_REG).value =
			d2.format(DATE_FORMAT_DAY);
	};

	const searchStartDate = searchQuery.filters.find(filter => filter.field === GLOBAL_FILTER_FIELD.START_DATE_TIME_REG);
	const startDate = searchStartDate ? moment.utc(searchStartDate.value, DATE_FORMAT_DAY) : null;

	const searchEndDate = searchQuery.filters.find(filter => filter.field === GLOBAL_FILTER_FIELD.END_DATE_TIME_REG);
	const endDate = searchEndDate ? moment.utc(searchEndDate.value, DATE_FORMAT_DAY) : null;

	const saveCSV = () => {
		trackEvent('Download CSV Modal Confirm Button', 'Clicked');
		closeModal().then(() => {
			showModal({
				type: MODAL_TYPE.DOWNLOADING_QUERY_DATA,
				searchQuery: searchQuery,
				enabledColumns,
				validateAccess: true,
				legacyDownload,
			});
		});
	};

	const cancelCSV = () => {
		trackEvent('Download CSV Modal Cancel Button', 'Clicked');
	};

	const content = () => {
		return (
			<DatePicker
				isNewDateRange={true}
				startDate={startDate}
				startDateLabel={formatMessage({id: 'common.start_date'})}
				endDate={endDate}
				endDateLabel={formatMessage({id: 'common.end_date'})}
				handleDateRangeChange={onTaskDateChange}
				clearable={false}
				datePickerStyle={DATE_PICKER_STYLE.TASK_MODAL_TASK_DATE}
				disabled={false}
				startDateWarnings={false}
				deadlineDateWarnings={false}
				buttonCy={'date-picker-button'}
				userpilot={'task-modal-date-picker'}
			/>
		);
	};

	return (
		<GenericModal
			closeModal={closeModal}
			buttons={[
				{text: formatMessage({id: 'common.cancel'}), callback: cancelCSV, color: BUTTON_COLOR.WHITE},
				{text: formatMessage({id: 'common.confirm'}), callback: saveCSV},
			]}
			content={content()}
			headerText={formatMessage({id: 'reported_time_date_modal.header'})}
			modalWidth={MODAL_WIDTH.SMALL}
		/>
	);
};

export default ReportedTimeDateModal;
