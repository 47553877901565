import React from 'react';
import FinancialLockIcon from '../../../components/new-ui/FinancialLockIcon';
import {FeatureTitleToggle, NoStylingNeeded, SwitchWrapper, TimeSubtitle, TimeTitle} from './TimeManagement_styled';
import {Switch} from 'web-components';

export const FeatureToggle = ({
	formatMessage,
	checked,
	toggleFeature,
	locked = false,
	featureTitleId,
	featureSubtitleId,
	helpCenterLink,
	cy,
	disabled = false,
}) => {
	return (
		<NoStylingNeeded>
			<FeatureTitleToggle>
				<SwitchWrapper>
					<Switch locked={locked || disabled} checked={checked} onChange={toggleFeature} cy={cy} />
				</SwitchWrapper>
				<TimeTitle>
					{formatMessage({id: featureTitleId})}
					{locked ? <FinancialLockIcon></FinancialLockIcon> : null}
				</TimeTitle>
			</FeatureTitleToggle>
			<TimeSubtitle>
				{formatMessage({id: featureSubtitleId})}{' '}
				{helpCenterLink && (
					<a href={helpCenterLink} target="_blank" rel="noopener noreferrer">
						{formatMessage({id: 'common.learn_more_here'})}
					</a>
				)}
			</TimeSubtitle>
		</NoStylingNeeded>
	);
};
