import styled from 'styled-components';
export const Tile = styled.div `
	display: flex;
	flex-direction: column;
`;
export const TileRow = styled.div `
	display: flex;
	justify-content: space-between;
	margin-bottom: 4px;
	${({ indent }) => indent && 'margin-left: 24px;'}
`;
