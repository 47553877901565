import React from 'react';
import {ArrowRightOutline, BinOutline} from '../../../images/svg-collection';
import {BUTTON_COLOR, BUTTON_STYLE, BUTTON_VARIANT} from '../../../constants';
import * as tracking from '../../../tracking';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import Util from '../../../forecast-app/shared/util/util';
import DeleteMultipleLabelsMutation from '../../../mutations/delete_multiple_labels_mutation';
import MoveLabelsToSkillsMutation from '../../../mutations/move_labels_to_skills_mutation';
import {createToast} from '../../../forecast-app/shared/components/toasts/toast';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';

const labelsBulkMoveToSkills = (selectedLabels, company, formatMessage, unselectLabels) => {
	const {skillLevelsEnabled} = company;
	tracking.trackEvent('labels - opened bulk option (move labels to skills)');
	trackEvent('Convert Labels To Skills Modal', 'Opened');
	const count = selectedLabels.size;
	showModal({
		type: MODAL_TYPE.WARNING,
		message: formatMessage({id: 'settings_labels.move_to_skills_title'}, {count}),
		warningInformation: [
			formatMessage({id: 'settings_labels.move_to_skills_info'}, {b: b => <b>{b}</b>}),
			skillLevelsEnabled && formatMessage({id: 'settings_labels.move_to_skills_info_level'}),
		],
		buttons: [
			{
				text: formatMessage({id: 'common.cancel'}),
				style: BUTTON_STYLE.FILLED,
				color: BUTTON_COLOR.WHITE,
			},
			{
				text: formatMessage({id: 'settings_labels.move_to_skills_convert'}),
				style: BUTTON_STYLE.FILLED,
				color: BUTTON_COLOR.GREEN,
				cy: 'move-to-skills-convert',
				callback: () => {
					Util.CommitMutation(
						MoveLabelsToSkillsMutation,
						{
							labelIds: Array.from(selectedLabels),
							companyId: company.id,
						},
						() => {
							unselectLabels();
							createToast({
								duration: 5000,
								message: formatMessage({id: 'settings_labels.move_to_skills_toast'}, {count}),
							});
						}
					);
				},
			},
		],
	});
};

const labelsBulkMoveCategory = selectedLabels => {
	tracking.trackEvent('labels - opened bulk option (move labels to category)');
	trackEvent('Bulk Move Label Category Modal', 'Opened');
	showModal({
		type: MODAL_TYPE.BULK_MOVE_LABELS_TO_CATEGORY,
		labelIds: selectedLabels,
	});
};

const labelsMerge = selectedLabels => {
	tracking.trackEvent('labels - opened bulk option (merge labels)');
	trackEvent('Bulk Merge Labels Modal', 'Opened');

	showModal({
		type: MODAL_TYPE.MERGE_LABELS,
		labelIds: selectedLabels,
	});
};

const labelsBulkDelete = (selectedLabels, company, formatMessage, unselectLabels) => {
	tracking.trackEvent('labels - opened bulk option (delete)');
	trackEvent('Bulk Delete Labels Modal', 'Opened');
	const labelCount = selectedLabels.size;
	showModal({
		type: MODAL_TYPE.CONFIRM_LABEL_DELETION,
		title: formatMessage({id: 'settings_labels.bulk_delete_labels_title'}, {labelCount}),
		warningMessage: formatMessage({id: 'settings_labels.bulk_delete_labels_warning'}),
		confirmText: formatMessage({id: 'settings.confirm'}),
		onDelete: () => {
			Util.CommitMutation(
				DeleteMultipleLabelsMutation,
				{
					ids: selectedLabels,
					companyId: company.id,
				},
				() => {
					unselectLabels();
					createToast({
						duration: 5000,
						message: formatMessage({id: 'settings_labels.labels_deleted'}, {labelCount}),
					});
				}
			);
		},
	});
};

export const getLabelsBulkOptions = (selectedLabels, company, formatMessage, unselectLabels) => {
	return [
		{
			id: 'bulk-move-category',
			label: formatMessage({id: 'bulk_edit.labels.move_to_category'}),
			icon: color => <ArrowRightOutline color={color} width={16} height={16} />,
			callback: () => labelsBulkMoveCategory(selectedLabels),
			userpilot: 'bulk-move-button-category',
			cy: 'bulk-move-button-category',
			variant: BUTTON_VARIANT.VERY_LIGHT_GRAY_OUTLINE,
		},
		Util.hasSkills()
			? {
					id: 'bulk-move-to-skills',
					label: formatMessage({id: 'bulk_edit.labels.move_to_skills'}),
					callback: () => labelsBulkMoveToSkills(selectedLabels, company, formatMessage, unselectLabels),
					userpilot: 'bulk-move-button-skills',
					cy: 'bulk-move-button-skills',
					variant: BUTTON_VARIANT.VERY_LIGHT_GRAY_OUTLINE,
			  }
			: null,
		{
			id: 'bulk-merge-labels',
			label: formatMessage({id: 'bulk_edit.labels.merge'}),
			callback: () => labelsMerge(selectedLabels),
			userpilot: 'bulk-merge-labels',
			cy: 'bulk-merge-labels',
			variant: BUTTON_VARIANT.VERY_LIGHT_GRAY_OUTLINE,
			disabled: selectedLabels.size < 2,
		},
		{
			id: 'delete',
			icon: color => <BinOutline color={color} width={16} height={16} />,
			label: formatMessage({id: 'common.delete'}),
			callback: () => labelsBulkDelete(selectedLabels, company, formatMessage, unselectLabels),
			userpilot: 'bulk-delete-button',
			cy: 'bulk-delete-button',
			variant: BUTTON_VARIANT.VERY_LIGHT_GRAY_OUTLINE,
		},
	].filter(Boolean);
};
