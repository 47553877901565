import { partition } from 'lodash';
const actualsColumnNames = ['actual-price', 'actual-cost', 'billable-time-entries', 'non-billable-time-entries'];
export const groupEyeOptions = (availableColumns) => {
    const [actualsOptions, restColumns] = partition(availableColumns, column => {
        return actualsColumnNames.includes(column.name);
    });
    const actualsOption = {
        name: 'actuals',
        disabled: false,
        checked: actualsOptions.some(option => option.checked),
        translationId: 'project_budget.actuals',
        nestedOptions: actualsOptions,
    };
    return actualsOptions && actualsOptions.length > 0 ? [actualsOption, ...restColumns] : restColumns;
};
const getColumnNameWithEyeOptionPrefix = (column) => {
    if (actualsColumnNames.includes(column.name)) {
        return `actuals.${column.name}`;
    }
    return column.name;
};
export const eyeOptionsToCols = (eyeOptions, availableColumns) => {
    return availableColumns.map(column => {
        return Object.assign(Object.assign({}, column), { checked: eyeOptions[getColumnNameWithEyeOptionPrefix(column)] });
    });
};
