import React from 'react';

const playIcon = props => (
	<svg className="play-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
		<path
			fill="#A1A1A1"
			fillRule="evenodd"
			d="M8 14.4c3.529 0 6.4-2.871 6.4-6.4 0-3.529-2.871-6.4-6.4-6.4A6.407 6.407 0 0 0 1.6 8c0 3.529 2.871 6.4 6.4 6.4zM8 0c4.411 0 8 3.589 8 8 0 4.412-3.589 8-8 8s-8-3.588-8-8c0-4.411 3.589-8 8-8zM6.4 11.2V4.8L11.2 8l-4.8 3.2z"
		/>
	</svg>
);

export default playIcon;
