import {MODAL_TYPE, showModal} from '../../shared/components/modals/generic_modal_conductor';
import {useIntl} from 'react-intl';
import {Section} from './Styles';
import {DeprecatedText as Text, FlexRow} from '@forecast-it/design-system';
import CheckBox from '../../../components/new-ui/check_box';
import React from 'react';

const showTrackTimeConfirmationModal = company => {
	showModal({
		type: MODAL_TYPE.SETTINGS_SETUP_CONFIRM_TOGGLE_TIMESHEETS,
		company: company,
	});
};

export const ToggleTimeSheets = ({company}) => {
	const {revenueEnabled, timesheetsEnabled} = company;
	const {formatMessage} = useIntl();

	return (
		<>
			<Section>
				<Text type={'heading'} variant={'m'}>
					{formatMessage({id: 'header.time'})}
				</Text>
				<FlexRow gap={'l'}>
					<CheckBox
						isDisabled={revenueEnabled}
						isChecked={timesheetsEnabled}
						onChange={() => showTrackTimeConfirmationModal(company)}
					/>
					<Text type={'label'}>{formatMessage({id: 'settings.setup.time_tracking.selector_label'})}</Text>
				</FlexRow>
				<Text>{formatMessage({id: 'settings.setup.time_tracking.description'})}</Text>
			</Section>
		</>
	);
};
