/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type TimeRegistrationReportTable_viewer$ref: FragmentReference;
declare export opaque type TimeRegistrationReportTable_viewer$fragmentType: TimeRegistrationReportTable_viewer$ref;
export type TimeRegistrationReportTable_viewer = {|
  +reportData: ?{|
    +totals: ?{|
      +timeRegTotals: ?{|
        +totalCost: ?number,
        +totalPrice: ?number,
        +totalTimeRegistered: ?number,
        +totalBillableRegistered: ?number,
        +totalNonBillableRegistered: ?number,
      |}
    |}
  |},
  +$refType: TimeRegistrationReportTable_viewer$ref,
|};
export type TimeRegistrationReportTable_viewer$data = TimeRegistrationReportTable_viewer;
export type TimeRegistrationReportTable_viewer$key = {
  +$data?: TimeRegistrationReportTable_viewer$data,
  +$fragmentRefs: TimeRegistrationReportTable_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchQuery",
      "type": "TaskSearchQueryType"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "reportId",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sharedId",
      "type": "String"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "TimeRegistrationReportTable_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "reportId"
        },
        {
          "kind": "Variable",
          "name": "sharedId",
          "variableName": "sharedId"
        }
      ],
      "concreteType": "ReportData",
      "kind": "LinkedField",
      "name": "reportData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "searchQuery",
              "variableName": "searchQuery"
            }
          ],
          "concreteType": "ReportTotals",
          "kind": "LinkedField",
          "name": "totals",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ReportTimeRegTotals",
              "kind": "LinkedField",
              "name": "timeRegTotals",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "totalCost",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "totalPrice",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "totalTimeRegistered",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "totalBillableRegistered",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "totalNonBillableRegistered",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
// prettier-ignore
(node/*: any*/).hash = '22d49853eb5e1253f43e4ca3a6e90662';

module.exports = node;
