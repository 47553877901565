import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { createTimeRegistration } from './logic/TimeRegistrationModalMutation';
import TimeRegistrationModalQueryWrapper from './query-wrappers/TimeRegistrationModalQueryWrapper';
import { toEntityId } from './util/TimeRegistrationModalUtil';
const infoText = {
    headerText: 'Create time entry',
    creativeButtonText: 'Save',
    secondaryButtonText: 'Save and Create New',
    cancelButtonText: 'Cancel',
};
function createModalTimeRegistration({ timeRegistrationMutationInput }) {
    createTimeRegistration(timeRegistrationMutationInput);
}
function TimeRegistrationModalCreate({ viewer, personId, entityId, closeModal, initialMinutes, initialBillableTime, initialDate, initialNote, initialRole, onCreateSuccessCallbacks, }) {
    return (React.createElement(TimeRegistrationModalQueryWrapper, { personId: personId, entityId: entityId ||
            toEntityId({
                taskId: viewer.task ? viewer.task.id : null,
                projectId: viewer.project ? viewer.project.id : null,
                idleTimeId: null,
            }), projectId: undefined, initialMinutes: initialMinutes, initialBillableTime: initialBillableTime, initialDate: initialDate, initialNote: initialNote, initialRole: initialRole, infoText: infoText, shouldValidateEntity: true, callbackPositive: timeRegistrationMutationInput => {
            const input = Object.assign({}, timeRegistrationMutationInput);
            if (onCreateSuccessCallbacks) {
                // Merge the two callback lists
                input.onCreateSuccessCallbacks = onCreateSuccessCallbacks.concat(input.onCreateSuccessCallbacks ? input.onCreateSuccessCallbacks : []);
            }
            createModalTimeRegistration({
                timeRegistrationMutationInput: input,
            });
        }, closeModal: closeModal }));
}
export const TimeRegistrationModalCreateQuery = graphql `
	query TimeRegistrationModalCreate_Query($companyTaskId: String, $companyProjectId: String) {
		viewer {
			actualPersonId
			component(name: "time_registration_modal_create")
			...TimeRegistrationModalCreate_viewer @arguments(companyTaskId: $companyTaskId, companyProjectId: $companyProjectId)
		}
	}
`;
export default createFragmentContainer(TimeRegistrationModalCreate, {
    viewer: graphql `
		fragment TimeRegistrationModalCreate_viewer on Viewer
		@argumentDefinitions(companyTaskId: {type: "String!"}, companyProjectId: {type: "String!"}) {
			actualPersonId
			task(companyTaskId: $companyTaskId) {
				id
				project {
					id
				}
			}
			project(id: $companyProjectId) {
				id
			}
		}
	`,
});
