import React from 'react';
import {Table} from 'web-components';
import {getCustomFieldColumns} from '../../../shared/util/ReportServiceUtil';
import {useIntl} from 'react-intl';

export const getBaseLevelTableHeader = (columns, customFieldDefinitions) => {
	const {formatMessage} = useIntl();
	const customFields = getCustomFieldColumns(customFieldDefinitions, columns);

	return (
		<Table.Header>
			<Table.HeaderColumn usePadding flex={1} align="left" visible={true} width="MEDIUM">
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.project'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn usePadding align="left" visible={true} width="MEDIUM">
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.task'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns['timeReg.task.phase.info']} width="MEDIUM">
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.phase'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns['timeReg.task.sprint.info']} width="MEDIUM">
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.sprint'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="right" usePadding visible={true} width="SMALL">
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.reported'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="right" usePadding visible={columns['actuals.timeReg.billableRegistered']} width="MEDIUM">
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.billable_time'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn
				align="right"
				usePadding
				visible={columns['actuals.timeReg.nonBillableRegistered']}
				width="MEDIUM"
			>
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.non_billable_time'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns['timeReg.notes']} width="LARGE">
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.notes'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns['timeReg.date']} width="SMALL">
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.dates'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={true} width="MEDIUM">
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.person'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn
				align="left"
				usePadding
				visible={columns['timeReg.role.info'] || columns['timeReg.person.role.info']}
				width="MEDIUM"
			>
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.role'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns['timeReg.person.department.info']} width="MEDIUM">
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.department'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns['timeReg.client.info']} width="MEDIUM">
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.client'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns['actuals.timeReg.billable']} width="MEDIUM">
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.is_billable'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="right" usePadding visible={columns['actuals.timeReg.price']} width="MEDIUM">
				<Table.HeaderColumn.Title>
					{formatMessage({id: 'insights.component.list.column.reportedMoney.newBudget'})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="right" usePadding visible={columns['actuals.timeReg.cost']} width="MEDIUM">
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.actual_cost'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns['timeReg.invoiced']} width="SMALL">
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.invoiced'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns['timeReg.approvalStatus']} width="SMALL">
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.approved'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			{customFields.map(customField => (
				<Table.HeaderColumn align="center" usePadding visible={customField.visible} width="MEDIUM">
					<Table.HeaderColumn.Title>{customField.displayName}</Table.HeaderColumn.Title>
				</Table.HeaderColumn>
			))}
		</Table.Header>
	);
};
