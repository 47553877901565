import {addCustomFieldsEntity, filterAndHandleEyeOptions, filterEyeOptions} from '../../../shared/util/ReportServiceUtil';
import {showCost, showRevenue} from '../../../shared/util/PermissionsUtil';
import {HIDDEN_FEATURES, REPORT_GROUPINGS} from '../../../../constants';
import Util from '../../../shared/util/util';
import CompanySetupUtil, {isFeatureHidden} from '../../../shared/util/CompanySetupUtil';
import {isBillableSplitAllowed} from '../../../shared/util/cache/TimeRegistrationSettingsUtil';

const groupTotalsOptions = [
	{
		name: 'timeRegTotals.totalCost',
		checked: true,
		hide: true,
	},
	{
		name: 'timeRegTotals.totalPrice',
		checked: true,
		hide: true,
	},
	{
		name: 'timeRegTotals.totalTimeRegistered',
		checked: true,
		hide: true,
	},
	{
		name: 'timeRegTotals.totalBillableRegistered',
		checked: true,
		hide: true,
	},
	{
		name: 'timeRegTotals.totalNonBillableRegistered',
		checked: true,
		hide: true,
	},
];

const projectOptions = [
	{
		name: 'name',
		checked: true,
		hide: true,
	},
	{
		name: 'projectColor',
		checked: true,
		hide: true,
	},
	{
		name: 'companyProjectId',
		checked: true,
		hide: true,
	},
	{
		name: 'customProjectId',
		checked: true,
		hide: true,
	},
	{
		name: 'fullAccessToProject',
		checked: true,
		hide: true,
	},
	...(!Util.isFeatureHidden(HIDDEN_FEATURES.CLIENT)
		? [
				{
					name: 'client',
					checked: true,
					hide: true,
					nestedOptions: [
						{
							name: 'name',
							checked: true,
							hide: true,
						},
					],
				},
		  ]
		: []),
	...groupTotalsOptions,
];

const phaseOptions = [
	{
		name: 'name',
		checked: true,
		hide: true,
	},
];

export const theEyeOptionsNoGrouping = (sharedView = false, timeRegSettings = {}) => [
	// Company Data
	{
		name: 'company.currency',
		checked: true,
		hide: true,
	},

	// Totals
	{
		name: 'totals',
		checked: true,
		hide: true,
		nestedOptions: [...groupTotalsOptions],
	},

	// Actual fields
	{
		name: 'timeReg.price',
		checked: true,
		hide: true,
	},
	{
		name: 'timeReg.cost',
		checked: true,
		hide: true,
	},
	{
		name: 'timeReg.billableRegistered',
		checked: true,
		hide: true,
	},
	{
		name: 'timeReg.nonBillableRegistered',
		checked: true,
		hide: true,
	},
	{
		name: 'timeReg.billable',
		checked: true,
		hide: true,
	},
	// Actual eye options
	{
		name: 'actuals',
		translationId: 'common.actuals',
		checked: false,
		nestedOptions: [
			...(sharedView || (CompanySetupUtil.hasFinance() && isBillableSplitAllowed())
				? [
						{
							name: 'timeReg.billableRegistered',
							checked: false,
							translationId: 'common.billable_time',
							nestedOptions: null,
						},
						{
							name: 'timeReg.nonBillableRegistered',
							checked: false,
							translationId: 'common.non_billable_time',
							nestedOptions: null,
						},
				  ]
				: []),
			{
				name: 'timeReg.price',
				checked: false,
				translationId: 'project_budget.actual_revenue',
				nestedOptions: null,
			},
			{
				name: 'timeReg.cost',
				checked: false,
				translationId: 'insights.component.list.column.actualCost',
				nestedOptions: null,
			},
			...(isFeatureHidden(HIDDEN_FEATURES.REVENUE)
				? []
				: [
						{
							name: 'timeReg.billable',
							checked: false,
							translationId: 'common.is_billable',
							nestedOptions: null,
						},
				  ]),
		],
	},

	//Groups
	{
		name: 'timeReg.phase',
		checked: true,
		hide: true,
		nestedOptions: [
			{
				name: 'name',
				checked: true,
				hide: true,
			},
			...groupTotalsOptions,
		],
	},
	{
		name: 'timeReg.sprint',
		checked: true,
		hide: true,
		nestedOptions: [
			{
				name: 'name',
				checked: true,
				hide: true,
			},
			...groupTotalsOptions,
		],
	},
	...(!Util.isFeatureHidden(HIDDEN_FEATURES.CLIENT)
		? [
				{
					name: 'timeReg.client',
					checked: true,
					hide: true,
					nestedOptions: [
						{
							name: 'name',
							checked: true,
							hide: true,
						},
						...groupTotalsOptions,
					],
				},
		  ]
		: []),

	{
		name: 'timeReg.role',
		checked: true,
		hide: true,
		nestedOptions: [
			{
				name: 'name',
				checked: true,
				hide: true,
			},
			...groupTotalsOptions,
		],
	},

	{
		name: 'timeReg.person.role',
		checked: true,
		hide: true,
		nestedOptions: [
			{
				name: 'name',
				checked: true,
				hide: true,
			},
			...groupTotalsOptions,
		],
	},
	{
		name: 'timeReg.person.department',
		checked: true,
		hide: true,
		nestedOptions: [
			{
				name: 'name',
				checked: true,
				hide: true,
			},
			...groupTotalsOptions,
		],
	},
	{
		name: 'timeReg.person',
		checked: true,
		hide: true,
		nestedOptions: [
			{
				name: 'firstName',
				checked: true,
				hide: true,
			},
			{
				name: 'lastName',
				checked: true,
				hide: true,
			},
			...groupTotalsOptions,
		],
	},
	{
		name: 'timeReg.task',
		checked: true,
		hide: true,
		nestedOptions: [
			{
				name: 'name',
				checked: true,
				hide: true,
			},
			...groupTotalsOptions,
		],
	},

	// Time Reg Options
	{
		name: 'timeReg.idleTime',
		checked: true,
		hide: true,
		nestedOptions: [
			{
				name: 'name',
				checked: true,
				hide: true,
			},
			{
				name: 'isInternalTime',
				checked: true,
				hide: true,
			},
		],
	},
	{
		name: 'timeReg.project',
		checked: true,
		translationId: null,
		hide: true,
		nestedOptions: [...projectOptions],
	},
	{
		name: 'timeReg.task',
		checked: true,
		translationId: null,
		hide: true,
		nestedOptions: [
			{
				name: 'project',
				checked: true,
				translationId: null,
				hide: true,
				nestedOptions: [...projectOptions],
			},
			{
				name: 'name',
				checked: true,
				translationId: null,
				hide: true,
			},
			{
				name: 'phase',
				checked: true,
				translationId: null,
				hide: true,
				nestedOptions: [...phaseOptions],
			},
		],
	},
	{
		name: 'timeReg.timeRegistered',
		checked: true,
		translationId: 'common.reported',
		nestedOptions: null,
		hide: true,
	},
	{
		name: 'timeReg.notes',
		checked: false,
		translationId: 'common.notes',
		nestedOptions: null,
	},
	{
		name: 'timeReg.date',
		checked: true,
		translationId: 'common.date',
		nestedOptions: null,
	},
	{
		name: 'timeReg.role.info',
		checked: false,
		translationId: 'common.role',
		nestedOptions: null,
	},
	{
		name: 'timeReg.person.department.info',
		checked: false,
		translationId: 'common.department',
		nestedOptions: null,
	},
	{
		name: 'timeReg.task.phase.info',
		checked: true,
		translationId: 'common.phase',
		nestedOptions: null,
	},
	{
		name: 'timeReg.task.sprint.info',
		checked: false,
		translationId: 'common.sprint',
		nestedOptions: null,
	},
	...(!Util.isFeatureHidden(HIDDEN_FEATURES.CLIENT)
		? [
				{
					name: 'timeReg.client.info',
					checked: true,
					translationId: 'common.client',
					nestedOptions: null,
				},
		  ]
		: []),

	...(isFeatureHidden(HIDDEN_FEATURES.REVENUE) || !CompanySetupUtil.hasFinance()
		? []
		: [
				{
					name: 'timeReg.invoiced',
					checked: false,
					translationId: 'project_budget.invoiced',
					nestedOptions: null,
				},
		  ]),
	{
		name: 'timeReg.approvalStatus',
		checked: false,
		translationId: 'common.approved',
		nestedOptions: null,
	},
];

const customFieldsOtions = [
	{
		entityTypePrefix: 'company',
		entityType: 'reportCustomFieldDefinitions',
		hide: false,
		checked: false,
	},
	{
		entityTypePrefix: 'timeReg',
		entityType: 'reportCustomFieldValues',
		hide: true,
		checked: true,
	},
];

/**
 * This will create the default exclude list based on the selected options
 * and grouping. It make sure that the hidden options are excluded
 * if not required by a group.
 *
 * @param initialEyeOptions The options
 * @param groupings The groupings
 * @param sharedView Is shared view
 * @returns {*[]} The excluded list
 */
const getExcludeList = (initialEyeOptions, groupings, sharedView) => {
	const excludeList = [];

	// Hide actual fields based on actual eye options
	if (!initialEyeOptions['actuals.timeReg.billableRegistered']) {
		excludeList.push(...['timeReg.billableRegistered']);
	}
	if (!initialEyeOptions['actuals.timeReg.nonBillableRegistered']) {
		excludeList.push(...['timeReg.nonBillableRegistered']);
	}
	if (!initialEyeOptions['actuals.timeReg.price']) {
		excludeList.push(...['timeReg.price']);
	}
	if (!initialEyeOptions['actuals.timeReg.cost']) {
		excludeList.push(...['timeReg.cost']);
	}
	if (!initialEyeOptions['actuals.timeReg.billable']) {
		excludeList.push(...['timeReg.billable']);
	}

	if (!groupings.includes(REPORT_GROUPINGS.CLIENT) && !initialEyeOptions['timeReg.client.info']) {
		excludeList.push(...['.project.client.name']);
	}

	if (!groupings.includes(REPORT_GROUPINGS.ROLE) && !initialEyeOptions['timeReg.role.info']) {
		excludeList.push(...['.role.name']);
	}

	if (!groupings.includes(REPORT_GROUPINGS.ROLE) && !initialEyeOptions['timeReg.person.role.info']) {
		excludeList.push(...['.project.person.role.name']);
	}

	if (!groupings.includes(REPORT_GROUPINGS.SPRINT) && !initialEyeOptions['timeReg.project.sprint.info']) {
		excludeList.push(...['.project.sprint.name']);
	}

	if (!groupings.includes(REPORT_GROUPINGS.PHASE) && !initialEyeOptions['timeReg.project.phase.info']) {
		excludeList.push(...['.project.phase.name']);
	}

	if (!groupings.includes(REPORT_GROUPINGS.DEPARTMENT) && !initialEyeOptions['timeReg.person.department.info']) {
		excludeList.push(...['.person.department.name']);
	}

	new Map([
		['timeReg.cost', '.timeRegTotals.totalCost'],
		['timeReg.price', '.timeRegTotals.totalPrice'],
		['timeReg.timeRegistered', '.timeRegTotals.totalTimeRegistered'],
		['timeReg.billableRegistered', '.timeRegTotals.totalBillableRegistered'],
		['timeReg.nonBillableRegistered', '.timeRegTotals.totalNonBillableRegistered'],
	]).forEach((value, key) => {
		if (!initialEyeOptions[key]) {
			excludeList.push(value);
		}
	});

	const REVENUE_FIELDS = ['.price', '.totalPrice'];
	const COST_FIELDS = ['.cost', '.totalCost'];
	if (!sharedView && !showRevenue()) {
		excludeList.push(...REVENUE_FIELDS);
	}

	if (!sharedView && !showCost()) {
		excludeList.push(...COST_FIELDS);
	}

	return excludeList;
};

export const filterObjectEyeOptionsWithCustomFields = (
	initialEyeOptions,
	groupings,
	sharedView,
	customFieldDefinitions,
	timeRegistrationSettings
) => {
	const excludeList = getExcludeList(initialEyeOptions, groupings, sharedView);
	return filterAndHandleEyeOptions(
		initialEyeOptions,
		addCustomFieldsEntity(
			theEyeOptionsNoGrouping(sharedView, timeRegistrationSettings),
			customFieldDefinitions,
			customFieldsOtions
		),
		excludeList
	);
};

export const theEyeOptionsFilteredWithCustomFields = (
	initialEyeOptions,
	groupings,
	sharedView,
	customFieldDefinitions,
	timeRegistrationSettings
) => {
	const excludeList = getExcludeList(initialEyeOptions, groupings, sharedView);
	return filterEyeOptions(
		addCustomFieldsEntity(
			theEyeOptionsNoGrouping(sharedView, timeRegistrationSettings),
			customFieldDefinitions,
			customFieldsOtions
		),
		excludeList,
		''
	);
};
