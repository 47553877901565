import React, {useState} from 'react';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {createRefetchContainer, graphql} from 'react-relay';
import BaseLevelWrapper from './BaselevelWrapper';
import {GLOBAL_FILTER_FIELD, GLOBAL_FILTER_OPERATOR} from '../../../../../constants';
import GroupRow from '../rows/GroupRow';
import {useGenericReportContext} from '../../../saved-report/GenericReportContext';

const RoleRelayWrapperV2 = ({
	relay,
	tableHeader,
	groupings,
	searchQuery,
	viewer,
	intl,
	rowIndex,
	enabledColumns,
	nextLevelProps,
	currency,
	customFieldColumnCount,
	customFieldKeys,
	pageSize,
}) => {
	const {reportId, sharedId} = useGenericReportContext();

	const roles = viewer.reportData.roles.edges?.filter(role => role.node.taskTotals.entities > 0);
	const hasNextPage = viewer.reportData.roles.pageInfo.hasNextPage && roles.length >= pageSize;
	const [modalTasksFetching, setModalTasksFetching] = useState(false);

	/**
	 * Grouping filter for task with specific role.
	 *
	 * @param roleID The role ID
	 */
	const roleGroupingFilter = roleID => {
		return {
			field: GLOBAL_FILTER_FIELD.ROLE,
			operator: GLOBAL_FILTER_OPERATOR.IS,
			value: [roleID],
		};
	};

	/**
	 * Grouping filter for task without a role.
	 */
	const noRoleGroupingFilter = () => {
		return {
			field: GLOBAL_FILTER_FIELD.ROLE,
			operator: GLOBAL_FILTER_OPERATOR.IS,
		};
	};
	const dataRows = roles.map(role => {
		return {
			...role.node,
			generatedName: role.node.noRole ? intl.formatMessage({id: 'common.no_role'}) : role.node.name,
			currency,
		};
	});

	const data = {
		rows: dataRows,
		groupingFilter: rowData => (rowData.noRole ? noRoleGroupingFilter() : roleGroupingFilter(rowData.roleId)),
	};

	const fetchMore = () => {
		if (!modalTasksFetching) {
			const refetchObject = {};
			setModalTasksFetching(true);
			refetchObject.pageSize = roles.length + pageSize;
			relay.refetch(refetchObject, null, () => setModalTasksFetching(false));
		}
	};

	const roleRows = () => {
		return GroupRow(
			data,
			intl,
			rowIndex,
			searchQuery,
			groupings,
			enabledColumns,
			hasNextPage,
			fetchMore,
			nextLevelProps,
			currency,
			reportId,
			sharedId,
			customFieldColumnCount,
			customFieldKeys
		);
	};

	return rowIndex === 1 ? (
		<BaseLevelWrapper
			tableHeader={tableHeader}
			intl={intl}
			enabledColumns={enabledColumns}
			getRows={roleRows}
			currentLength={roles.length}
			hasNextPage={hasNextPage}
			refetch={relay.refetch}
			currency={currency}
			customFieldColumnCount={customFieldColumnCount}
			pageSize={pageSize}
		/>
	) : (
		roleRows()
	);
};

const RoleRelayWrapperV2Query = graphql`
	query RoleRelayWrapperV2_Query($searchQuery: TaskSearchQueryType, $pageSize: Int, $reportId: String, $sharedId: String) {
		viewer {
			actualPersonId
			component(name: "role_relay_wrapper_v2")
			...RoleRelayWrapperV2_viewer
				@arguments(searchQuery: $searchQuery, pageSize: $pageSize, reportId: $reportId, sharedId: $sharedId)
		}
	}
`;
export {RoleRelayWrapperV2Query};

export default injectIntl(
	withRouter(
		createRefetchContainer(
			RoleRelayWrapperV2,
			{
				viewer: graphql`
					fragment RoleRelayWrapperV2_viewer on Viewer
					@argumentDefinitions(
						searchQuery: {type: "TaskSearchQueryType"}
						pageSize: {type: "Int"}
						reportId: {type: "String"}
						sharedId: {type: "String"}
					) {
						reportData(id: $reportId, sharedId: $sharedId) {
							roles(first: $pageSize, searchQuery: $searchQuery)
								@connection(key: "TaskReport_roles", filters: ["searchQuery"]) {
								pageInfo {
									endCursor
									hasNextPage
								}
								edges {
									node {
										id
										roleId
										name
										taskTotals {
											work {
												estimate {
													minutes
													points
												}
												remaining {
													minutes
													points
												}
											}
											reported {
												time
												points
												projected
											}
											financial {
												revenue {
													planned
													actual
												}
											}
											entities
										}
										noRole
									}
								}
							}
						}
					}
				`,
			},
			graphql`
				query RoleRelayWrapperV2RefetchQuery(
					$pageSize: Int
					$searchQuery: TaskSearchQueryType
					$reportId: String
					$sharedId: String
				) {
					viewer {
						...RoleRelayWrapperV2_viewer
							@arguments(pageSize: $pageSize, searchQuery: $searchQuery, reportId: $reportId, sharedId: $sharedId)
					}
				}
			`
		)
	)
);
