/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type TimeIncrementsSection_company$ref = any;
type TimeRegistrationSettingsSection_company$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type settingsIdleTimes_viewer$ref: FragmentReference;
declare export opaque type settingsIdleTimes_viewer$fragmentType: settingsIdleTimes_viewer$ref;
export type settingsIdleTimes_viewer = {|
  +id: string,
  +company: ?{|
    +id: string,
    +useTimeApproval: ?boolean,
    +useInternalTimeApproval: ?boolean,
    +useTimeOffApproval: ?boolean,
    +allUsersModifyTimeOff: ?boolean,
    +useTimeLocking: ?boolean,
    +lockedPeriodYear: ?number,
    +lockedPeriodMonth: ?number,
    +lockedPeriodDay: ?number,
    +harvestEnabled: ?boolean,
    +jiraCloudEnabled: ?boolean,
    +jiraServerEnabled: ?boolean,
    +unit4Enabled: ?boolean,
    +bambooHREnabled: ?boolean,
    +allocations: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +idleTime: ?{|
            +id: string
          |},
        |}
      |}>
    |},
    +idleTimes: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +isInternalTime: ?boolean,
          +disabled: ?boolean,
          +timeRegistrations: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string
              |}
            |}>
          |},
        |}
      |}>
    |},
    +$fragmentRefs: TimeRegistrationSettingsSection_company$ref & TimeIncrementsSection_company$ref,
  |},
  +$refType: settingsIdleTimes_viewer$ref,
|};
export type settingsIdleTimes_viewer$data = settingsIdleTimes_viewer;
export type settingsIdleTimes_viewer$key = {
  +$data?: settingsIdleTimes_viewer$data,
  +$fragmentRefs: settingsIdleTimes_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "company",
          "idleTimes"
        ]
      }
    ]
  },
  "name": "settingsIdleTimes_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "useTimeApproval",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "useInternalTimeApproval",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "useTimeOffApproval",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allUsersModifyTimeOff",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "useTimeLocking",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lockedPeriodYear",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lockedPeriodMonth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lockedPeriodDay",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "harvestEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jiraCloudEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jiraServerEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unit4Enabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bambooHREnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 100000
            },
            {
              "kind": "Literal",
              "name": "idleTimeOnly",
              "value": true
            }
          ],
          "concreteType": "AllocationTypeConnection",
          "kind": "LinkedField",
          "name": "allocations",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AllocationTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Allocation",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "IdleTime",
                      "kind": "LinkedField",
                      "name": "idleTime",
                      "plural": false,
                      "selections": (v1/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "allocations(first:100000,idleTimeOnly:true)"
        },
        {
          "alias": "idleTimes",
          "args": null,
          "concreteType": "IdleTimeTypeConnection",
          "kind": "LinkedField",
          "name": "__Company_idleTimes_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "IdleTimeTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "IdleTime",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isInternalTime",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "disabled",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "first",
                          "value": 1
                        }
                      ],
                      "concreteType": "TimeRegTypeConnection",
                      "kind": "LinkedField",
                      "name": "timeRegistrations",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "TimeRegTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "TimeRegType",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": (v1/*: any*/),
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": "timeRegistrations(first:1)"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TimeRegistrationSettingsSection_company"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TimeIncrementsSection_company"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b6721daf30dce5ae2ce3c99af6a4f09d';

module.exports = node;
