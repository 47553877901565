import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import UploadingOverlay from '../../uploading-overlay/uploading_overlay';
import Person from '../../person/person';
import {profilePicSrc} from '../../../../../directApi';

class SuggestedPersonAndRole extends Component {
	constructor(props) {
		super(props);

		this.MAX_SUGGESTED_PERSONS = 5;
	}

	componentDidMount() {
		this.setSuggestedPersons();
		this.setSuggestedRoles();
	}

	assignPerson(person, e) {
		if (this.props.assignPerson) {
			this.props.assignPerson(person, true, e);
		}
	}

	assignRole(id, e) {
		if (this.props.assignRole) {
			this.props.assignRole(id, true, e);
		}
	}

	setSuggestedPersons() {
		if (this.props.setSuggestedPersons) {
			this.props.setSuggestedPersons(this.props.viewer.suggestedPersons);
		}
	}

	setSuggestedRoles() {
		if (this.props.setSuggestedRoles) {
			this.props.setSuggestedRoles(this.props.viewer.suggestedRoles);
		}
	}

	render() {
		const {formatMessage} = this.props.intl;
		const {isPersonsOnlyDropdown, isRolesOnlyDropdown} = this.props;
		let {suggestedPersons, suggestedRoles} = this.props.viewer;
		const showAll = !isPersonsOnlyDropdown && !isRolesOnlyDropdown;

		if (this.props.loading) {
			return <UploadingOverlay />;
		}

		// Don't show suggestions if company does not have required model
		if (
			isPersonsOnlyDropdown &&
			this.props.viewer.company.availableMLModels &&
			!this.props.viewer.company.availableMLModels.assigneeModel
		) {
			return null;
		}

		if (
			isRolesOnlyDropdown &&
			this.props.viewer.company.availableMLModels &&
			!this.props.viewer.company.availableMLModels.roleModel
		) {
			return null;
		}

		// For persons dropdown, if there are people already assigned, don't show the suggestions
		if (isPersonsOnlyDropdown && this.props.assignedPersons && this.props.assignedPersons.length !== 0) {
			return null;
		}

		if (this.props.searchCriteria !== '') {
			return null;
		}

		let filteredSuggestedPersons = [];
		let filteredSuggestedRoles = [];

		if (suggestedPersons) {
			filteredSuggestedPersons = suggestedPersons.edges.filter(
				edge => !this.props.assignedPersons || !this.props.assignedPersons.find(person => person.id === edge.node.id)
			);

			filteredSuggestedPersons = filteredSuggestedPersons.filter(edge => {
				if (!this.props.suggestedPersonRoleFilter) {
					return true;
				}

				if (!edge.node.role) {
					return false;
				}

				return this.props.suggestedPersonRoleFilter.id === edge.node.role.id;
			});
		}

		if (suggestedRoles) {
			filteredSuggestedRoles = suggestedRoles.edges.filter(edge => {
				return !this.props.assignedRole;
			});
		}

		if (isPersonsOnlyDropdown && filteredSuggestedPersons.length === 0) {
			return null;
		}

		if (isRolesOnlyDropdown && filteredSuggestedRoles.length === 0) {
			return null;
		}

		if (!showAll) {
			// IMPORTANT: We had split the roles and persons dropdowns into their own
			// This means that if we are dealing with a persons dropdown, we don't want to show roles suggestions, and vice versa
			isPersonsOnlyDropdown ? (filteredSuggestedRoles = []) : (filteredSuggestedPersons = []);
		}

		// if statement if both persons only and roles only are false
		return (
			// eslint-disable-next-line jsx-a11y/role-supports-aria-props
			<li
				id="suggested-persons-list"
				role="menuitem"
				className={'no-drag'}
				onClick={this.props.toggleSuggestedPersons}
				ref={this.props.forwardedRef}
				aria-expanded={this.props.suggestedPersonsExpanded}
				aria-haspopup="menu"
				tabIndex="-1"
			>
				<div
					className={
						'list-header no-drag' +
						(this.props.selected === this.props.role_options.length + this.props.assignedLength ? ' selected' : '')
					}
				>
					<div className="title">
						{formatMessage({id: 'common.assignee_suggestions'}) +
							' (' +
							(filteredSuggestedPersons.length + filteredSuggestedRoles.length) +
							')'}
					</div>
					<div className={this.props.suggestedPersonsExpanded ? ' expanded' : ' not-expanded'} />
				</div>
				{this.props.suggestedPersonsExpanded ? (
					<ul
						className={'no-drag'}
						aria-hidden={!this.props.suggestedPersonsExpanded}
						aria-labelledby="suggested-persons-list"
						role="menu"
					>
						{filteredSuggestedRoles.map((role, index) => {
							return (
								<li
									key={role.node.id}
									role="menuitem"
									tabIndex="-1"
									className={
										'role-option no-drag' +
										(this.props.selected === index + this.props.assignedLength + 1 ? ' selected' : '')
									}
									onClick={this.assignRole.bind(this, role.node.id)}
									data-cy="assigned-dropdown-role-option"
								>
									<div className="role-name no-drag">{role.node.name}</div>
								</li>
							);
						})}
						{filteredSuggestedPersons.map((person, index) => {
							return (
								<li
									key={person.node.id}
									role="menuitem"
									tabIndex="-1"
									className={
										'person-option no-drag' +
										(this.props.selected ===
										this.props.role_options.length + this.props.assignedLength + 1 + index
											? ' selected'
											: '')
									}
									onClick={this.assignPerson.bind(this, person.node)}
									data-cy="assigned-dropdown-suggested-person-option"
								>
									<Person
										name={person.node.fullName}
										role={person.node.role ? person.node.role.name : null}
										showName={true}
										showRole={!!person.node.role}
										imageSize="new-ui-dropdown"
										imageSrc={profilePicSrc(person.node.profilePictureId)}
									/>
								</li>
							);
						})}
					</ul>
				) : null}
			</li>
		);
	}
}

const suggestedPersonAndRoleQuery = graphql`
	query suggestedPersonAndRole_Query($projectId: ID, $taskId: ID, $taskName: String) {
		viewer {
			actualPersonId
			component(name: "suggested_person_and_role")
			...suggestedPersonAndRole_viewer @arguments(projectId: $projectId, taskId: $taskId, taskName: $taskName)
		}
	}
`;

export {suggestedPersonAndRoleQuery};

export default injectIntl(
	createFragmentContainer(
		React.forwardRef((props, ref) => <SuggestedPersonAndRole forwardedRef={ref} {...props} />),
		{
			viewer: graphql`
				fragment suggestedPersonAndRole_viewer on Viewer
				@argumentDefinitions(projectId: {type: "ID"}, taskId: {type: "ID"}, taskName: {type: "String"}) {
					company {
						availableMLModels {
							roleModel
							assigneeModel
						}
					}
					suggestedPersons(first: 100000, taskId: $taskId, taskName: $taskName, projectId: $projectId) {
						edges {
							node {
								id
								active
								profilePictureId
								profilePictureDefaultId
								fullName
								role {
									id
									name
								}
							}
						}
					}
					suggestedRoles(first: 100000, taskId: $taskId, taskName: $taskName, projectId: $projectId) {
						edges {
							node {
								id
								name
							}
						}
					}
				}
			`,
		}
	)
);
