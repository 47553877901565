export function setLocalStorageSilent(key, val) {
	try {
		localStorage.setItem(key, val);
	} catch (e) {}
}

export function getLocalStorageSilent(key) {
	try {
		return localStorage.getItem(key);
	} catch (e) {}
	return null;
}

export function parseLocalStorageJSONOrDefault(key, fallback) {
	const value = localStorage.getItem(key);
	if (value) {
		return JSON.parse(value);
	} else {
		return fallback;
	}
}
