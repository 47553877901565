import {
	DeprecatedColorIndicator as ColorIndicator,
	DeprecatedMultiColorIndicator as MultiColorIndicator,
	DeprecatedScrollbar as Scrollbar,
	DeprecatedText as Text,
} from '@forecast-it/design-system';
import React from 'react';
import styled from 'styled-components';

const SidebarHeader = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 32px;
`;

const ColorTitleWrapper = styled.div`
	display: flex;
`;

const ColorIndicatorWrapper = styled.div`
	margin-right: 8px;
`;

const GenericColorIndicatorSlideout = ({name, color, isMultiIndicator = false, children}) => {
	return (
		<>
			<SidebarHeader>
				<ColorTitleWrapper>
					<ColorIndicatorWrapper>
						{isMultiIndicator ? (
							<MultiColorIndicator color={color} size={'s'} />
						) : (
							<ColorIndicator size={'s'} color={color} />
						)}
					</ColorIndicatorWrapper>
					<Text type={'heading'} variant={'m'}>
						{name}
					</Text>
				</ColorTitleWrapper>
			</SidebarHeader>
			<Scrollbar maxHeight={'calc(100% - 54px)'}>{children}</Scrollbar>
		</>
	);
};

export default GenericColorIndicatorSlideout;
