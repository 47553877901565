import React, {useState} from 'react';
import Styled from 'styled-components';
import {CaretIcon, Checkbox} from 'web-components';

// searchString and value is used by nested Dropdown
// eslint-disable-next-line no-unused-vars
export const SkillLevelItem = ({
	name,
	skillLevels,
	selectedLevels,
	onSelect,
	onRemove,
	multiSelect,
	searchString,
	value,
	cy,
}) => {
	const [expanded, setExpanded] = useState(false);
	const allSelected = selectedLevels.some(level => !level) || skillLevels.every(level => selectedLevels.includes(level.id));

	const onSkillClick = e => {
		e.stopPropagation();
		setExpanded(!expanded);
	};
	const onSkillCheckboxClick = e => {
		e.stopPropagation();
	};
	const onSkillLevelClick = skillLevelId => e => {
		e.stopPropagation();
		const selected = allSelected || selectedLevels.includes(skillLevelId);
		if (selected) {
			onRemove(skillLevelId);
		} else {
			onSelect(skillLevelId);
		}
	};

	return (
		<Container data-cy={cy}>
			<Header onClick={onSkillClick}>
				<Skill>
					{multiSelect && (
						<Checkbox
							onClick={onSkillCheckboxClick}
							hasEntitiesChecked={!!selectedLevels.length && !allSelected}
							checked={!!selectedLevels.length}
							onChange={onSkillLevelClick(null)}
						/>
					)}
					<Name>{name}</Name>
				</Skill>
				<CaretIcon color="#D3D3DF" direction={expanded ? CaretIcon.DIRECTION.UP : CaretIcon.DIRECTION.DOWN} />
			</Header>
			{expanded &&
				skillLevels.map(skillLevel => (
					<SkillLevel key={name + '-' + skillLevel.id} onClick={onSkillLevelClick(skillLevel.id)}>
						{multiSelect && <Checkbox checked={allSelected || selectedLevels.includes(skillLevel.id)} />}
						<Name>
							{skillLevel.level} - {skillLevel.description}
						</Name>
					</SkillLevel>
				))}
		</Container>
	);
};

export const Name = Styled.span`
`;

export const ItemRow = Styled.div`
    display: flex;
    align-items: center;
    > *:not(:first-child) {
		margin-left: 5px;
	}
`;

export const Header = Styled.div`
	padding: 5px 20px 5px 16px;
	display: flex;
	justify-content: space-between;
	&:hover {
		background-color: #f0e7fc;
	}
`;

export const Skill = Styled(ItemRow)`
`;

export const Category = Styled.div`
	color: #A5A5AE;
`;

export const SkillLevel = Styled(ItemRow)`
	padding: 5px 0 5px 32px;
	&:hover {
		background-color: #f0e7fc;
	}
`;

export const Container = Styled.div`
	background-color: white;
	user-select: none;
`;
