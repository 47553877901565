import React from 'react';
import {SavedFilter} from 'web-components';
import {SavedFiltersContainer, SmallTitle, SavedFilters, FilterContainerWrapper, FavoriteFilters} from './filter.styled';
import {matchSaved, contextMenuOptions} from './filter_util';

export const SavedFiltersSection = ({
	intl,
	savedFilters,
	appliedFilters,
	appliedFilterOperators,
	changeAppliedFilters,
	showSaving,
	saveFilter,
	deleteFilter,
	renamingFilter,
	setRenamingFilter,
	validateRenameFilter,
}) => {
	const constructSavedFilters = favouredSection => {
		const filters = favouredSection ? savedFilters.slice(0, 3) : savedFilters.slice(3, savedFilters.length);

		return (
			filters &&
			filters.map(filter => {
				const isActive = matchSaved(filter, appliedFilters, appliedFilterOperators);
				const isRenaming = renamingFilter === filter.id;
				return (
					<FilterContainerWrapper
						key={filter.id}
						data-userpilot={'filter-saved-filter'}
						data-active={isActive}
						data-cy={`saved-filter:${filter.name}`}
					>
						<SavedFilter
							edit={isRenaming}
							toggleActivate={() => {
								const args = isActive ? [[], {}] : [filter.value, filter.operators];
								return !isRenaming && changeAppliedFilters(...args);
							}}
							active={isActive}
							maxWidth={112}
							text={filter.name}
							dropdownOptions={contextMenuOptions(intl, filter.id, setRenamingFilter, deleteFilter)}
							onSetChange={name => validateRenameFilter(filter.id, name)}
						/>
					</FilterContainerWrapper>
				);
			})
		);
	};

	return (
		<SavedFiltersContainer data-cy={'saved-filters'}>
			<SmallTitle>{intl.formatMessage({id: 'filter_content.saved_filters'})}</SmallTitle>
			<FavoriteFilters data-userpilot={'filter-favorite-area'}>
				{showSaving ? (
					<FilterContainerWrapper>
						<SavedFilter active edit maxWidth={112} onSetChange={saveFilter.bind(this)} />
					</FilterContainerWrapper>
				) : null}
				{constructSavedFilters(true)}
			</FavoriteFilters>
			<SavedFilters>{constructSavedFilters(false)}</SavedFilters>
		</SavedFiltersContainer>
	);
};
