import { graphql } from 'react-relay';
export const ReportsPageQuery = graphql `
	query ReportsPageQuery {
		viewer {
			actualPersonId
			component(name: "reports_page")
			...ReportsPage_viewer
		}
	}
`;
