import React from 'react';
import {DeprecatedContextMenu as ContextMenu} from '@forecast-it/design-system';
import {DeprecatedContextMenuItem as ContextMenuItem} from '@forecast-it/design-system';
import {DeprecatedText as Text} from '@forecast-it/design-system';
import {MODAL_TYPE, showModal} from '../../../shared/components/modals/generic_modal_conductor';
import ReactDOM from 'react-dom';
import {useIntl} from 'react-intl';
import {hasPermission} from '../../../shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../Permissions';
import {useHistory} from 'react-router-dom';
import ProgramUtil from '../../../shared/util/ProgramUtil';

const ProgramContextMenu = ({program, companyId, bounds, closeContextMenu}) => {
	const history = useHistory();
	const {formatMessage} = useIntl();

	const openDeleteProgramModal = () => {
		showModal({
			type: MODAL_TYPE.DELETE_PROGRAM,
			programId: program.id,
			companyId: companyId,
		});
	};

	return ReactDOM.createPortal(
		<ContextMenu verticalPos={'below'} horizontalOrientation={'left'} bounds={bounds} closeContextMenu={closeContextMenu}>
			{hasPermission(PERMISSION_TYPE.PROGRAMS_UPDATE) && (
				<ContextMenuItem onClick={() => history.push(`program/${program.prefix}/settings`)}>
					<Text>{formatMessage({id: 'settings.text.entity'}, {entity: ProgramUtil.programText(formatMessage)})}</Text>
				</ContextMenuItem>
			)}
			{hasPermission(PERMISSION_TYPE.PROGRAMS_DELETE) && (
				<ContextMenuItem onClick={openDeleteProgramModal}>
					<Text color="destructive">
						{formatMessage({id: 'common.action.delete_entity'}, {entity: ProgramUtil.programText(formatMessage)})}
					</Text>
				</ContextMenuItem>
			)}
		</ContextMenu>,
		document.querySelector('#root-portal-container')
	);
};

export default ProgramContextMenu;
