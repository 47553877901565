import React, {Component} from 'react';
import {FormattedHTMLMessage, injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import InputField from '../../../components/inputs/input_field';
import Util from '../../shared/util/util';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import UpdateCompanyMutation from '../../../mutations/update_company_mutation.modern';
import GenericModal from '../../../containers/modal/generic_modal';
import Warning from '../../../components/warning';
import TooltipContainer from '../../shared/components/tooltips/tooltip_container';
import InformationIcon from '../../../images/information-icon';
import Checkbox from '../../../components/new-ui/check_box';
import OverlayLoader from '../../../components/loaders/overlay_loader';

class ChangeAllocationTypeModalContent extends Component {
	constructor(props) {
		super(props);

		this.changingAllocationType = false;
		this.confirmText = 'confirm';

		this.state = {
			input_field_value: '',
			loading: false,
			usingProjectAllocationCreateAllocations: true,
		};
	}

	handleInputChange(e) {
		this.setState({input_field_value: e});
	}

	changeAllocationType() {
		if (!this.changingAllocationType) {
			this.changingAllocationType = true;

			this.setState({loading: true});

			const onSuccess = () => {
				this.props.changeAllocationOption(this.props.isUsingProjectAllocation);
				this.props.closeModal();
			};

			Util.CommitMutation(
				UpdateCompanyMutation,
				{
					id: this.props.companyId,
					isUsingProjectAllocation: this.props.isUsingProjectAllocation,
					usingProjectAllocationCreateAllocations: this.state.usingProjectAllocationCreateAllocations,
				},
				onSuccess,
				true
			);
		}
	}

	render() {
		const {formatMessage} = this.props.intl;
		const content = (
			<div className="default-warning-modal change-allocation-type-modal">
				{this.state.loading && <OverlayLoader />}
				<Warning messageId="settings.change-confirmation-title" />
				<div className="allocation-type">
					<FormattedHTMLMessage
						id={
							this.props.isUsingProjectAllocation
								? 'settings_details.project_allocation'
								: 'settings_details.task_allocation'
						}
					/>
					<TooltipContainer
						infoText={
							this.props.isUsingProjectAllocation
								? [
										{
											title: ['settings_details.project_allocation'],
											details: ['settings_details.project_allocation_tooltip_text'],
											noBox: true,
										},
								  ]
								: [
										{
											title: ['settings_details.task_allocation'],
											details: ['settings_details.task_allocation_tooltip_text'],
											noBox: true,
										},
								  ]
						}
						tooltipInfinteDuration={true}
						edgeOffset={350}
					>
						<InformationIcon />
					</TooltipContainer>
				</div>
				<div className="description">
					{this.props.isUsingProjectAllocation ? (
						<div className="checkbox-row checkbox-row-first">
							<Checkbox
								isChecked={this.state.usingProjectAllocationCreateAllocations}
								onChange={() => {
									this.setState({
										usingProjectAllocationCreateAllocations:
											!this.state.usingProjectAllocationCreateAllocations,
									});
								}}
							/>
							<div className="checkbox-label">
								<FormattedHTMLMessage id="settings.transform_all_info" />
							</div>
						</div>
					) : null}
					<div className={'description-list ' + (!this.props.isUsingProjectAllocation ? '' : 'pushed-right')}>
						<div className="list-line">
							<div className="circle"></div>
							<FormattedHTMLMessage
								id={
									this.props.isUsingProjectAllocation
										? 'settings.description_list_projects_1'
										: 'settings.description_list_tasks_1'
								}
							/>
						</div>
						<div className="list-line">
							<div className="circle"></div>
							<FormattedHTMLMessage
								id={
									this.props.isUsingProjectAllocation
										? 'settings.description_list_projects_2'
										: 'settings.description_list_tasks_2'
								}
							/>
						</div>
					</div>
				</div>
				{this.props.isUsingProjectAllocation ? (
					<div className="transform-disclaimer">
						<FormattedHTMLMessage id="settings.confirm_transform_disclaimer" />
					</div>
				) : null}
				<div className="confirmation-info">
					<FormattedHTMLMessage id="settings.confirm_text" />
				</div>
				<InputField
					value={this.state.input_field_value}
					onChange={this.handleInputChange.bind(this)}
					placeholder={formatMessage(
						{id: 'settings_team.delete_person_modal.type_confirm_text'},
						{confirmText: this.confirmText}
					)}
					type="text"
					autoFocus={true}
					hideLabel={true}
					className="confirmation-input"
				/>
				<div className="confirmation-info">
					<FormattedHTMLMessage id="common.warning.this_action_can_not_be_undone" />
				</div>
			</div>
		);

		return (
			<GenericModal
				closeModal={this.props.closeModal}
				className="change-allocation-type-modal"
				buttons={[
					{
						text: formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: formatMessage({id: 'common.confirm'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.RED,
						callback: this.changeAllocationType.bind(this),
						preventDefaultClose: true,
						disabled:
							this.state.loading ||
							this.confirmText.toLowerCase() !== this.state.input_field_value.toLowerCase().trim(),
					},
				]}
				content={content}
			/>
		);
	}
}

ChangeAllocationTypeModalContent.propTypes = {
	companyId: PropTypes.string.isRequired,
	isUsingProjectAllocation: PropTypes.string,
};

export default injectIntl(ChangeAllocationTypeModalContent);
