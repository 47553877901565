import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';
import {Table} from 'web-components';
import {RateCardRowV2} from './settings_finance_rate_card_row_v2';
import {BUTTON_COLOR, BUTTON_STYLE, ELEMENT_TYPE} from '../../../constants';
import HeaderBar from '../../shared/components/headers/header-bar/header_bar';
import Util from '../../shared/util/util';
import {MODAL_TYPE, showModal} from '../../shared/components/modals/generic_modal_conductor';

export const HeaderBarStyled = styled.div`
	.header-bar {
		padding-left: 0px;
		padding-right: 0px;
	}
`;

export const RateCardsStyle = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	padding-bottom: 10px;
`;

export const RateCardsV2 = ({company, projects, rateCards}) => {
	const intl = useIntl();
	let rateCardsClone = [...rateCards];
	const [searchFilterValue, setSearchFilterValue] = useState('');
	const [sortObject, setSortObject] = useState(null);

	const sortDisabled = () => {
		const disabledRateCards = rateCardsClone.filter(rateCard => rateCard.disabled);
		const enabledRateCards = rateCardsClone.filter(rateCard => !rateCard.disabled);

		rateCardsClone = enabledRateCards.concat(disabledRateCards);
	};

	const filterRateCards = () => {
		const filterValue = searchFilterValue.trim();
		if (filterValue) {
			rateCardsClone = rateCardsClone.filter(rateCard => {
				const name = rateCard.name;
				const currency = rateCard.currency;
				return Util.normalizedIncludes(name, filterValue) || Util.normalizedIncludes(currency, filterValue);
			});
		}
	};

	const filterAdjustedRateCards = () => {
		rateCardsClone = rateCardsClone.filter(card => card.parentRateCardId === null);
	};

	const sort = () => {
		if (sortObject && sortObject.direction !== null && sortObject.id) {
			rateCardsClone.sort((a, b) => {
				const column = sortObject.id;
				const res = a[column].localeCompare(b[column], 'en', {sensitivity: 'base'});
				return sortObject.direction === 'DESC' ? res * -1 : res;
			});
		}
	};

	filterRateCards();

	filterAdjustedRateCards();

	sort();

	sortDisabled();

	const handleSearchChange = value => {
		setSearchFilterValue(value);
	};

	const onSort = sort => {
		setSortObject(sort);
	};

	const openRateCardModal = rateCard => {
		let showWarning = false;
		if (rateCard && projects && projects.length > 0) {
			const projectsWithRateCard = projects.filter(project =>
				project.rateCard ? project.rateCard.id === rateCard.id : null
			);
			const projectWithRateCardHasLockedPeriod = !!projectsWithRateCard.find(project => project.hasLockedPeriod);
			showWarning = projectsWithRateCard.length > 0 && !projectWithRateCardHasLockedPeriod;
		}
		if (showWarning) {
			const callbackPositive = params => {
				setTimeout(() => {
					showModal({
						type: MODAL_TYPE.RATE_CARD,
						rateCard,
						rateCardId: rateCard ? rateCard.id : null,
					});
				}, 0);
			};
			showModal({
				type: MODAL_TYPE.WARNING,
				warningInformation: [intl.formatMessage({id: 'settings_rate_card.modal_warning.affect_data'})],
				buttons: [
					{
						text: intl.formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: intl.formatMessage({id: 'common.ok'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.RED,
						callback: callbackPositive,
					},
				],
			});
		} else {
			showModal({
				type: MODAL_TYPE.RATE_CARD,
				rateCard,
				rateCardId: rateCard ? rateCard.id : null,
			});
		}
	};
	const getHeader = () => {
		const leftContent = [];
		const rightContent = [];

		rightContent.push({
			type: ELEMENT_TYPE.SEARCH,
			value: searchFilterValue,
			onChange: handleSearchChange.bind(null),
		});

		rightContent.push({
			type: ELEMENT_TYPE.BUTTON,
			text: intl.formatMessage({id: 'settings_finance.new_rate_card'}),
			style: BUTTON_STYLE.FILLED,
			color: BUTTON_COLOR.PURPLE,
			callback: () => openRateCardModal(),
			userpilot: 'new_rate_card',
			dataCy: 'new_rate_card',
			disabled: false,
		});

		return <HeaderBar leftContent={leftContent} rightContent={rightContent} />;
	};

	return (
		<RateCardsStyle data-userpilot={'rate-cards-section'}>
			<HeaderBarStyled>{getHeader()}</HeaderBarStyled>
			<p className="description">
				<FormattedMessage id="settings_finance.rateCard_description" />
			</p>
			<Table paddedCells onSort={onSort} initialSort={{id: 'name', direction: 'ASC'}}>
				<Table.Header>
					<Table.HeaderColumn sortId={'name'} flex={2} usePadding visible width="MEDIUM">
						<Table.HeaderColumn.Title>{intl.formatMessage({id: 'common.name'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn flex={1} usePadding visible width="MEDIUM">
						<Table.HeaderColumn.Title>
							{intl.formatMessage({id: 'settings.rate_cards_number_of_projects'})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn flex={1} usePadding visible width="MEDIUM">
						<Table.HeaderColumn.Title>
							{intl.formatMessage({id: 'settings_details.currency_section'})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn flex={1} usePadding visible width="MEDIUM" align="right" />
				</Table.Header>
				<Table.Rows
					data={{
						rows: rateCardsClone,
					}}
				>
					{({rowData, tableColumnsProps}) => (
						<RateCardRowV2
							card={rowData}
							company={company}
							projects={projects}
							rateCards={rateCardsClone}
							tableColumnsProps={tableColumnsProps}
							openRateCardModal={openRateCardModal}
						/>
					)}
				</Table.Rows>
			</Table>
		</RateCardsStyle>
	);
};
