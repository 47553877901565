import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Modal, Text } from '@forecast-it/design-system';
import { useIntl } from 'react-intl';
import Util from '../../../shared/util/util';
import DeleteBillToMutation from '../../../../mutations/invoicing/DeleteBillToMutation';
const RemoveBillToModal = ({ billToId, billFromId, closeModal }) => {
    const { formatMessage } = useIntl();
    const removeBillTo = (billToId, billFromId) => {
        // This mutation also deletes the client bill to entities linked to
        // the bill to entity being deleted.
        Util.CommitMutation(DeleteBillToMutation, {
            billToId,
            billFromId,
        }, closeModal);
    };
    return (React.createElement(Modal, { onCloseModal: closeModal },
        React.createElement(Modal.Title, null, formatMessage({ id: 'settings_finance.bill_to.remove' })),
        React.createElement(Modal.Content, null,
            React.createElement(Text, null, formatMessage({ id: 'settings_finance.remove_bill_to_entity_confirmation_description' })),
            React.createElement(Text, null, formatMessage({ id: 'settings_finance.remove_bill_to_entity_confirmation_question' }))),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { onPress: () => removeBillTo(billToId, billFromId) }, formatMessage({ id: 'common.remove' })),
            React.createElement(Modal.SecondaryFooterButton, { onPress: closeModal }, formatMessage({ id: 'common.close' })))));
};
export const RemoveBillToModalQuery = graphql `
	query RemoveBillToModalQuery {
		viewer {
			actualPersonId
			component(name: "RemoveBillToModal")
			...RemoveBillToModal_viewer
		}
	}
`;
export default createFragmentContainer(RemoveBillToModal, {
    viewer: graphql `
		fragment RemoveBillToModal_viewer on Viewer {
			id
			company {
				id
				clients(first: 100000) {
					edges {
						node {
							id
							name
							clientBillTos(first: 10000) @connection(key: "Client_clientBillTos", filters: []) {
								edges {
									node {
										id
										billFrom {
											id
										}
										billTo {
											id
										}
										clientId
									}
								}
							}
						}
					}
				}
			}
		}
	`,
});
