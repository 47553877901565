import {graphql} from 'react-relay';

export const sprintsPredictionsQuery = graphql`
	query ProjectSprintsPredictionsQuery($projectId: ID, $groupId: String) {
		viewer {
			id
			component(name: "sprint_predictions")
			company {
				availableMLModels {
					lowHighModel
					lowHighModelScore
				}
			}
			predictionProject: project(internalId: $projectId) {
				id
				predictedOverrunningTasks(approvedOnly: true) {
					globalTaskId
					estimate
					overrunProbability
				}
			}
			predictionProjectGroup: projectGroup(id: $groupId) {
				predictedOverrunningTasks(approvedOnly: true) {
					globalTaskId
					estimate
					overrunProbability
				}
			}
		}
	}
`;
