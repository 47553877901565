import {FILTER_SECTIONS} from '../components/filters/dropdown_section';
import {FILTER_TYPE} from '../../../constants';

export const filterTaskLabels = label => !label.category || label.category.allowOnTasks;
export const filterProjectLabels = label => !label.category || label.category.allowOnProjects;
export const filterPeopleLabels = label => !label.category || label.category.allowOnPeople;

export const filterTaskLabelEdges = edge => filterTaskLabels(edge.node);
export const filterProjectLabelEdges = edge => filterProjectLabels(edge.node);
export const filterPeopleLabelEdges = edge => filterPeopleLabels(edge.node);

export const getLabelFilterByFilterSectionAndType = (filterSection, filterType) => {
	if (filterSection === FILTER_SECTIONS.PROJECTS || filterType === FILTER_TYPE.TASK_PROJECT_LABEL) {
		return filterProjectLabels;
	} else if (filterSection === FILTER_SECTIONS.TASKS) {
		return filterTaskLabels;
	} else if (filterSection === FILTER_SECTIONS.PEOPLE) {
		return filterPeopleLabels;
	} else {
		return () => true;
	}
};
