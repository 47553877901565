import {Entity} from 'draft-js';

const isJsonObject = jsonString => {
	try {
		var o = JSON.parse(jsonString);

		if (o && typeof o === 'object') {
			return true;
		}
	} catch (e) {}

	return false;
};

const getEntityId = node => {
	let entityId = undefined;
	const className = node.className;
	const isMention = className === 'slate-mention';
	const isActionItem = className === 'slate-action_item';
	if (node instanceof HTMLAnchorElement) {
		const entityConfig = {};
		if (node.dataset && node.dataset.mention !== undefined) {
			entityConfig.url = node.href;
			entityConfig.text = node.innerHTML;
			entityConfig.value = node.dataset.value;
			entityId = Entity.__create('MENTION', 'IMMUTABLE', entityConfig);
		} else {
			entityConfig.url = node.getAttribute ? node.getAttribute('href') || node.href : node.href;
			entityConfig.title = node.innerHTML;
			entityConfig.targetOption = node.target;
			entityId = Entity.__create('LINK', 'MUTABLE', entityConfig);
		}
	} else if (isMention) {
		const mentionData = node.getAttribute('data-slate-value');
		const hasDataObject = isJsonObject(mentionData);
		const mentionDataObject = hasDataObject
			? JSON.parse(mentionData)
			: {
					personId: node.getAttribute('data-slate-value'),
					name: node.innerHTML,
			  };

		entityId = Entity.__create('MENTION', hasDataObject ? 'IMMUTABLE' : 'SEGMENTED', {
			mention: mentionDataObject,
		});
	} else if (isActionItem) {
		entityId = Entity.__create('ACTION_ITEM', 'SEGMENTED', {
			checked: node.querySelector('[role=checkbox]').getAttribute('data-state') === 'checked',
		});
	}
	return entityId;
};

export default getEntityId;
