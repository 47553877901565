import React, { useState } from 'react';
import { Checkbox, FlexRow, Label, Modal, Text } from '@forecast-it/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { createToast } from '../../../../shared/components/toasts/another-toast/toaster';
const BulkShareReportModal = ({ closeModal, reports, changePrivateAccess }) => {
    const intl = useIntl();
    const ids = reports.filter(report => report.reportService).map(report => report.id);
    const allHavePrivateAccess = reports.every(rep => rep.privateAccess);
    const [privateAccess, setPrivateAccess] = useState(allHavePrivateAccess);
    const togglePrivateAccess = () => {
        setPrivateAccess(!privateAccess);
    };
    const saveChanges = () => {
        const onSuccess = () => {
            if (privateAccess) {
                createToast({
                    duration: 5000,
                    message: intl.formatMessage({ id: 'shared_report.bulk_report_made_private' }, { reportCount: reports.length }),
                });
            }
            else {
                createToast({
                    duration: 5000,
                    message: intl.formatMessage({ id: 'shared_report.bulk_report_shared_with_organisation' }, { reportCount: reports.length }),
                });
            }
        };
        changePrivateAccess(onSuccess, ids, privateAccess);
        closeModal();
    };
    return (React.createElement(Modal, { size: Modal.SIZE.MEDIUM, onCloseModal: closeModal },
        React.createElement(Modal.Title, null,
            React.createElement(FormattedMessage, { id: 'report.share' })),
        React.createElement(Modal.Content, null,
            React.createElement(Label, null, intl.formatMessage({ id: 'report.share-company-label' })),
            React.createElement(FlexRow, null,
                React.createElement(Checkbox, { checked: !privateAccess, onChange: togglePrivateAccess }),
                React.createElement(Text, null, intl.formatMessage({ id: 'report.private_access.description' })))),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { onPress: saveChanges, dataCy: 'modal-btn-done' },
                React.createElement(FormattedMessage, { id: "common.save" })))));
};
export default BulkShareReportModal;
