import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import { FlexColumn, Label } from '@forecast-it/design-system';
import DirectApi from '../../../../../../../directApi';
import Dropdown from '../../../../dropdowns/dropdown';
function TimeRegistrationModalIntegrations({ project, person, company, selectedHarvestTask, handleHarvestTaskChange, selectedUnit4Activity, handleUnit4ActivityChange, }) {
    const { formatMessage } = useIntl();
    const [harvestTasks, setHarvestTasks] = useState([]);
    const [harvestLoading, setHarvestLoading] = useState(false);
    const [unit4Activities, setUnit4Activities] = useState([]);
    const [unit4Loading, setUnit4Loading] = useState(false);
    const prevProjectId = useRef(project ? project.id : null);
    function fetchHarvestTasks(project) {
        //If a project is selected, show only tasks that belong to that project
        if (project && project.harvestProject) {
            if (harvestLoading === true || harvestTasks.length > 0)
                return;
            setHarvestLoading(true);
            DirectApi.Fetch('harvest_tasks/' + project.id).then(tasks => {
                setHarvestTasks(tasks);
                setHarvestLoading(false);
            });
        }
        else {
            setHarvestTasks([]);
            setHarvestLoading(false);
        }
    }
    function fetchUnit4Activities(project) {
        //If a project is selected, show only tasks that belong to that project
        if (project && project.unit4Project) {
            if (unit4Loading === true || unit4Activities.length > 0)
                return;
            setUnit4Loading(true);
            DirectApi.Fetch('unit4_activities/' + project.id).then(activities => {
                setUnit4Activities(activities);
                setUnit4Loading(false);
            });
        }
        else {
            setUnit4Activities([]);
            setUnit4Loading(false);
        }
    }
    useEffect(() => {
        if (project && prevProjectId.current !== project.id) {
            setUnit4Activities([]);
            setHarvestTasks([]);
            prevProjectId.current = project.id;
        }
    }, [project]);
    useEffect(() => {
        if (selectedHarvestTask && project) {
            fetchHarvestTasks(project);
        }
    }, []);
    const harvestTaskOptions = harvestTasks
        ? harvestTasks.map(harvestTask => {
            return { value: harvestTask.id, label: harvestTask.name };
        })
        : [];
    const unit4ActivityOptions = unit4Activities
        ? unit4Activities.map(unit4Activity => {
            return { value: unit4Activity.id, label: unit4Activity.name };
        })
        : [];
    const showHarvestDropdown = project && project.harvestProject && person.harvestUser && company.harvestEnabled;
    const showUnit4Dropdown = project && project.unit4Project && project.unit4Project.activitiesEnabled && person.unit4User && company.unit4Enabled;
    return (React.createElement(React.Fragment, null,
        showHarvestDropdown ? (React.createElement(FlexColumn, { gap: 's' },
            React.createElement(Label, null, "Harvest task"),
            React.createElement(Dropdown, { id: 'time-harvest-dropdown', options: harvestTaskOptions, onChange: handleHarvestTaskChange, value: selectedHarvestTask ? selectedHarvestTask.value : '', multi: false, clearable: true, focusOnClick: true, customHeight: 30, placeholder: harvestLoading
                    ? formatMessage({ id: 'common.loading' })
                    : formatMessage({ id: 'card_modal.harvest_task_placeholder' }), maxHeight: 245, onClick: () => fetchHarvestTasks(project), integrationDropdown: true, integrationLoading: harvestLoading, integrationRetryToFetch: () => fetchHarvestTasks(project), hideLabel: true }))) : null,
        showUnit4Dropdown ? (React.createElement(FlexColumn, { gap: 's' },
            React.createElement(Label, null, "Unit4 activity"),
            React.createElement(Dropdown, { id: 'time-unit4-dropdown', options: unit4ActivityOptions, onChange: handleUnit4ActivityChange, value: selectedUnit4Activity ? selectedUnit4Activity.value : '', multi: false, clearable: true, focusOnClick: true, customHeight: 30, placeholder: formatMessage({ id: 'integrations.unit4_activity_placeholder' }), maxHeight: 245, onClick: () => fetchUnit4Activities(project), integrationDropdown: true, integrationLoading: unit4Loading, integrationRetryToFetch: () => fetchUnit4Activities(project), hideLabel: true }))) : null));
}
export default createFragmentContainer(TimeRegistrationModalIntegrations, {
    project: graphql `
		fragment TimeRegistrationModalIntegrations_project on ProjectType {
			id
			harvestProject {
				id
			}
			unit4Project {
				activitiesEnabled
			}
		}
	`,
    person: graphql `
		fragment TimeRegistrationModalIntegrations_person on Person {
			harvestUser
			unit4User
		}
	`,
    company: graphql `
		fragment TimeRegistrationModalIntegrations_company on Company {
			harvestEnabled
			unit4Enabled
		}
	`,
});
