import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {APPROVAL_STATUS, BUTTON_COLOR, BUTTON_STYLE} from '../../../../../../constants';
import {injectIntl} from 'react-intl';
import Person from '../../../person/person';
import {MODAL_TYPE, showModal} from '../../../modals/generic_modal_conductor';
import DeleteTimeRegMutation from '../../../../../../mutations/delete_time_registration_mutation.modern';
import UpdateTimeRegistration from '../../../../../../mutations/update_time_registration_mutation.modern';
import Util from '../../../../util/util';
import ActionsMenu from '../../../action-menu/actions_menu';
import Warning from '../../../../../../components/warning';
import LockedIcon from '../../../../../../images/integrations/locked_icon';
import Moment from 'moment';
import {hasPermission} from '../../../../util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../../../Permissions';
import {updateTaskForScoping} from '../../../../../project-tab/projects/scoping-page/ProjectScopingUtil';
import {trackEvent} from '../../../../../../tracking/amplitude/TrackingV2';
import {hasFeatureFlag} from '../../../../util/FeatureUtil';
import {profilePicSrc} from '../../../../../../directApi';

class TimeEntry extends Component {
	showEditModal() {
		const timeReg = this.props.timeReg;
		trackEvent('Time Entries Time Registration Edit Action', 'Clicked', {
			selectedPerson: timeReg.person.id === this.props.viewer.actualPersonId ? 'myself' : timeReg.person.id,
			selectedTimeReg: timeReg.id,
			taskId: this.props.task.id,
			projectId: this.props.task.project.id,
		});
		if (hasFeatureFlag('new_time_registration_modal')) {
			showModal({
				type: MODAL_TYPE.UPDATE_TIME_REGISTRATION,
				timeRegistrationId: timeReg.id,
			});
		} else {
			showModal({
				type: MODAL_TYPE.TIMER_V3,
				updateTimeRegId: timeReg.id,
				personId: timeReg.person.id,
				isPreviousTimerReminder: false,
				taskId: this.props.task.id,
				useTimer: false,
				passedTime: timeReg.minutesRegistered,
				passedBillableTime: timeReg.billableMinutesRegistered,
				passedNotes: timeReg.notes,
				passedDate: Moment({y: timeReg.year, M: timeReg.month - 1, d: timeReg.day}),
				lockedDate: this.props.lockedDate,
				selectedPerson: timeReg.person,
				harvestTaskSelected: timeReg.harvestTask ? timeReg.harvestTask.id : null,
				timeRegRole: timeReg.role,
			});
		}
	}

	showDeleteModal() {
		const {formatMessage} = this.props.intl;
		trackEvent('Time Entries Time Registration Delete Action', 'Clicked', {
			selectedPerson:
				this.props.timeReg.person.id === this.props.viewer.actualPersonId ? 'myself' : this.props.timeReg.person.id,
			selectedTimeReg: this.props.timeReg.id,
			taskId: this.props.task.id,
			projectId: this.props.task.project.id,
		});
		showModal({
			type: MODAL_TYPE.GENERIC,
			content: (
				<div>
					<Warning messageId="warning_modal.delete_time_reg" />
					<div className="warning-part-2">{formatMessage({id: 'common.warning.this_action_can_not_be_undone'})}</div>
				</div>
			),
			defaultCallback: this.props.focusTimeEntriesTile,
			className: 'default-warning-modal',
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.delete'}),
					callback: this.deleteTimeReg.bind(this),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
				},
			],
			modalRef: this.props.modalRef,
		});
	}

	deleteTimeReg() {
		const onSuccess = result => {
			const hasOneError = result.deleteTimeReg && result.deleteTimeReg.errors && result.deleteTimeReg.errors.length === 1;

			if (hasOneError && result.deleteTimeReg.errors[0] === 'TIME_REGISTRATION_IN_LOCKED_PERIOD') {
				showModal({
					type: MODAL_TYPE.WARNING_DATE_LOCKED,
				});
			} else if (hasOneError && Util.checkForSageErrorAndShowModal(result.deleteTimeReg.errors, false)) {
			} else {
				updateTaskForScoping(this.props.task?.id);
			}
		};

		Util.CommitSchedulingModalUpdate(
			DeleteTimeRegMutation,
			{
				id: this.props.timeReg.id,
				taskId: this.props.task.id,
				projectId: this.props.task.project.id,
				companyId: this.props.viewer.company.id,
			},
			onSuccess
		);

		this.props.focusTimeEntriesTile();
	}

	getPerson(timeRegistration) {
		const personName = timeRegistration.person.fullName;
		const personRole = timeRegistration.role?.name;

		return (
			<Person
				name={personName}
				role={personRole}
				showName={true}
				showRole={true}
				imageSrc={profilePicSrc(timeRegistration.person.profilePictureId)}
				imageSize="large"
			/>
		);
	}

	getIntegrationSymbol() {
		const timeReg = this.props.timeReg;
		if (timeReg.economicTimeId || timeReg.xeroInvoiceId) {
			return ' padlock';
		}
		return '';
	}

	harvestRetry(timeRegistration) {
		Util.CommitMutation(UpdateTimeRegistration, {
			id: timeRegistration.id,
			retryHarvest: true,
		});
	}

	render() {
		const {formatMessage, formatDate} = this.props.intl;
		const {viewer, timeReg, disabled, lockedDate} = this.props;
		const integrationSymbolClass = this.getIntegrationSymbol();
		const {company} = viewer;
		const {harvestEnabled, economicEnabled, unit4Enabled} = company;
		const date = Util.CreateNonUtcMomentDate(timeReg.year, timeReg.month, timeReg.day);
		const lockedInTime = lockedDate && lockedDate.isSameOrAfter(date, 'day');
		const approvalStatus = this.props.useTimeApproval && timeReg.approvalStatus;
		return (
			<li className="time-registration-line">
				{this.getPerson(timeReg)}
				<div
					className={
						'time-registration-line-content ' +
						(timeReg.invoiced || timeReg.xeroInvoiceId || timeReg.economicTimeId ? 'invoiced' : '')
					}
				>
					<div className={'time-registration-line-values' + integrationSymbolClass}>
						<div className="value">
							{Util.convertMinutesToFullHour(timeReg.minutesRegistered, this.props.intl, true)}
						</div>
						<div className="date">
							<span>
								{formatDate(date, {
									day: 'numeric',
								})}
							</span>{' '}
							<span>
								{formatDate(date, {
									month: 'short',
								}).toUpperCase()}
							</span>{' '}
							<span>
								{formatDate(date, {
									year: 'numeric',
								})}
							</span>
						</div>
					</div>
					<div className="integration-info">
						{timeReg.invoiced || timeReg.xeroInvoiceId || timeReg.economicTimeId ? null : harvestEnabled ? (
							<>
								{Util.getHarvestStatusIcon(timeReg, this.props.intl)}
								{timeReg.harvestError ? (
									<div
										className="harvest-retry"
										onClick={this.harvestRetry.bind(this, timeReg)}
										title={formatMessage({id: 'integrations.harvest.retry'})}
									>
										{formatMessage({id: 'common.retry'})}
									</div>
								) : (
									<div className="integration-task-name" title={timeReg.harvestTask?.name}>
										{timeReg.harvestTask?.name}
									</div>
								)}
							</>
						) : null}

						{economicEnabled && timeReg.economicActivity ? (
							<div className="integration-task-name" title={timeReg.economicActivity.name}>
								{timeReg.economicActivity.name}
							</div>
						) : null}

						{unit4Enabled && (timeReg.unit4TimeEntryId || timeReg.unit4ActivityName) ? (
							<>
								{timeReg.unit4TimeEntryId ? <div className="unit4-small-logo"></div> : null}
								{timeReg.unit4ActivityName ? (
									<div className="integration-task-name" title={timeReg.unit4ActivityName}>
										{timeReg.unit4ActivityName}
									</div>
								) : null}
							</>
						) : null}
					</div>
				</div>
				{timeReg.person.isViewer ||
				hasPermission(PERMISSION_TYPE.TIME_REGISTRATION_UPDATE_ALL) ||
				hasPermission(PERMISSION_TYPE.TIME_REGISTRATION_DELETE_ALL) ? (
					timeReg.invoiced ||
					timeReg.xeroInvoiceId ||
					timeReg.economicTimeId ||
					timeReg.lockedInPeriod ||
					lockedInTime ? (
						<LockedIcon
							width={14}
							height={13}
							fill="#a1a1a1"
							className="status-icon"
							title={
								timeReg.xeroInvoiceId
									? formatMessage({id: 'xero.cannot_edit_time_reg'})
									: timeReg.invoiced || timeReg.economicTimeId
									? formatMessage({id: 'invoice.cannot_edit_time_reg'})
									: approvalStatus === APPROVAL_STATUS.APPROVED
									? formatMessage({id: 'approval.approved_time'})
									: approvalStatus === APPROVAL_STATUS.SUBMITTED
									? formatMessage({id: 'approval.submitted_time'})
									: lockedInTime
									? formatMessage({id: 'time_lock.time_reg_locked'})
									: timeReg.lockedInPeriod
									? formatMessage({id: 'retainer.cannot_edit_time_reg'})
									: ''
							}
						/>
					) : disabled ? null : (
						<ActionsMenu
							className="time-entry-actions"
							options={[
								{
									text: formatMessage({id: 'common.edit'}),
									onClick: this.showEditModal.bind(this),
								},
								{
									text: formatMessage({id: 'common.delete'}),
									onClick: this.showDeleteModal.bind(this),
								},
							]}
							whiteInner={true}
						/>
					)
				) : null}
			</li>
		);
	}
}

TimeEntry.propTypes = {
	timeReg: PropTypes.object.isRequired,
	personProjectRoles: PropTypes.object.isRequired,
	disabled: PropTypes.bool,
	viewer: PropTypes.object.isRequired,
};

export default injectIntl(TimeEntry);
