import { Avatar, ComboBox, DeprecatedCreativeModal as Modal, FlexRow, Label } from '@forecast-it/design-system';
import { useIntl } from 'react-intl';
import React, { useMemo } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { getNotNullishNodes } from '../../../../shared/util/NotNullPredicate';
import { profilePicRectSrc } from '../../../../../directApi';
function isSameOwner(newOwnerId, report) {
    var _a;
    return newOwnerId === ((_a = report === null || report === void 0 ? void 0 : report.person) === null || _a === void 0 ? void 0 : _a.id);
}
const TransferOwnershipModal = ({ closeModal, transferOwnership, report, viewer, }) => {
    var _a, _b, _c;
    const { formatMessage } = useIntl();
    const [newOwnerId, setNewOwnerId] = React.useState((_a = report === null || report === void 0 ? void 0 : report.person) === null || _a === void 0 ? void 0 : _a.id);
    const persons = getNotNullishNodes((_c = (_b = viewer === null || viewer === void 0 ? void 0 : viewer.company) === null || _b === void 0 ? void 0 : _b.allPersons) === null || _c === void 0 ? void 0 : _c.edges);
    const defaultItems = useMemo(() => {
        return persons.map(person => ({
            avatar: person.profilePictureId ? profilePicRectSrc(person.profilePictureId) : undefined,
            id: person.id,
            name: person.fullName,
        }));
    }, [persons]);
    const handleTransferOwnership = () => {
        const newOwner = persons.find(person => newOwnerId === person.id);
        if (newOwner) {
            transferOwnership(newOwner);
            closeModal();
        }
    };
    return (React.createElement(Modal, { title: formatMessage({ id: 'common.action.transfer_ownership' }), creativeButtonText: formatMessage({ id: 'common.action.transfer_ownership' }), onCreateHandler: handleTransferOwnership, disabled: !newOwnerId || isSameOwner(newOwnerId, report), closeModal: closeModal, replicateDesignSystem: true, "data-cy": "transfer-ownership-modal" },
        React.createElement(ComboBox, { defaultItems: defaultItems, defaultSelectedKey: newOwnerId, label: "Select new owner", onChange: item => setNewOwnerId(item), "data-cy": "transfer-ownership-combobox" }, item => (React.createElement(ComboBox.Item, { textValue: item.name || '' },
            React.createElement(FlexRow, null,
                React.createElement(Avatar, { src: item.avatar, size: 's', name: item.name || '' }),
                React.createElement(Label, null, item.name)))))));
};
export const TransferOwnershipModalQuery = graphql `
	query TransferOwnershipModalQuery($permissions: [String!]!) {
		viewer {
			actualPersonId
			component(name: "transfer_ownership_modal")
			...TransferOwnershipModal_viewer @arguments(permissions: $permissions)
		}
	}
`;
export default createFragmentContainer(TransferOwnershipModal, {
    viewer: graphql `
		fragment TransferOwnershipModal_viewer on Viewer @argumentDefinitions(permissions: {type: "[String!]!"}) {
			company {
				allPersons(first: 10000, permissions: $permissions) @connection(key: "Company_allPersons") {
					edges {
						node {
							id
							fullName
							profilePictureId
						}
					}
				}
			}
		}
	`,
});
