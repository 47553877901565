import {VARIANT} from '../../../containers/modal/project-automate/project_automate_modal';
import {PROJECT_STATUS, WorkflowCategories as WORKFLOW_CATEGORIES} from '../../../constants';
import {hasFeatureFlag} from './FeatureUtil';
import {hasMLModel} from './AvailableMLModelUtil';
import * as tracking from '../../../tracking';
import {hasPermission} from './PermissionsUtil';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';

export const shouldShowTaskOverrun = (isEstimatedInHours, requiredPermission, isDemoProject) => {
	return (
		isEstimatedInHours &&
		hasPermission(requiredPermission) &&
		(hasMLModel('overrunModel') ||
			hasFeatureFlag('skip_overrunning_model_check') ||
			hasFeatureFlag('skip_overrunning_check') ||
			hasFeatureFlag('task_overrun_demo') ||
			isDemoProject)
	);
};

export const isTaskOverrun = task => {
	if (!task.timeRegistrations) return false;
	return task.timeRegistrations.edges.reduce((acc, tReg) => acc + tReg.node.minutesRegistered, 0) > task.estimateForecast;
};

const showTaskPredictionWarning = task => {
	const projectStageCriteria =
		task.project && ![PROJECT_STATUS.OPPORTUNITY, PROJECT_STATUS.HALTED, PROJECT_STATUS.DONE].includes(task.project.status);
	const taskStatusCriteria = task.statusColumnV2.category !== WORKFLOW_CATEGORIES.DONE;
	return projectStageCriteria && taskStatusCriteria;
};

export const showOverrunPredictionWarning = task => {
	const predictedEstimate = task.predictedEstimate?.estimate;
	const overrunProbability = task.predictedEstimate?.overrunProbability;
	const predictionDismissed = task.taskDismissedNotifications?.taskOverrunPredictionDismissed;
	const predictionOverEstimate = task.estimateForecast < predictedEstimate;

	const taskInDemoProject = task.project?.demo;

	if (taskInDemoProject) {
		const taskName = task.name.toLowerCase();
		return !predictionDismissed && showTaskPredictionWarning(task) && !isTaskOverrun(task) && taskName.includes('client');
	}

	if (hasFeatureFlag('skip_overrunning_check')) {
		return !predictionDismissed && showTaskPredictionWarning(task);
	}

	if (hasFeatureFlag('task_overrun_demo')) {
		const taskName = task.name.toLowerCase();
		return (
			!predictionDismissed &&
			showTaskPredictionWarning(task) &&
			!isTaskOverrun(task) &&
			(taskName.includes('session') || taskName.includes('iteration'))
		);
	}

	return (
		predictedEstimate &&
		overrunProbability &&
		predictionOverEstimate &&
		!predictionDismissed &&
		!isTaskOverrun(task) &&
		showTaskPredictionWarning(task)
	);
};

export const trackPredictionsShown = predictionDataTasks => {
	const tasksCount = predictionDataTasks.length;
	const tasksOverrunPredictedCount = predictionDataTasks.filter(task => showOverrunPredictionWarning(task)).length;
	tracking.trackAIEvent('Predictions loaded', VARIANT.ESTIMATES, null, {tasksCount, tasksOverrunPredictedCount});
	trackEvent('Task Overrun Predictions', 'Loaded', {tasksCount, tasksOverrunPredictedCount});
};
