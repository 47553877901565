import Styled from 'styled-components/macro';
import {CSS_CONSTANTS} from '../../../../css_variables';

export const QueryRenderContainer = Styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	width: 100%;
`;

export const Container = Styled.div`
	z-index: 2000;
	position: fixed;
	width: ${props => props.width + 'px'};
	display: flex;
	flex-direction: column;
	top: 0;
	bottom: 0;
	right: 0;
	margin-top: ${props => (props.noMenu ? '36px' : '70px')};
	padding: 34px 30px 0;
	background-color: white;
	transform: translateX(100%);
	box-shadow: 0 3px 5px 0 rgba(74, 89, 105, 0.14);
	transition: transform 0.1s ease-in;
	&.slide-in {
		transform: translateX(0%);
		transition: transform 0.1s ease-out;
		z-index: 2000;
		
	}
`;

export const TitleAndAdvancedContainer = Styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 11px;
`;

export const CloseContainer = Styled.div`
	width: 100%;
	height: 16px;
	margin-bottom: ${props => (props.showSaved ? '8px' : '39px')};
	display: flex;
	justify-content: flex-end;
`;

export const FilterContainerWrapper = Styled.div`
	padding:3px 2px;
	display:flex;
	align-items:center;
`;
export const SavedFiltersContainer = Styled.div`
	border-bottom: 1px solid #ebebeb;
	padding-bottom: 20px;
	margin-bottom: 23px;
	z-index: 200;
`;

export const FavoriteFilters = Styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-top: 10px;
	width: 100%;
	min-height: 32px;
	padding: 0 4px;
	border: 1px dashed #cecece;
`;
export const FilterTypeContainer = Styled.div`
	width: 100%;
	margin-bottom: 28px;
`;

export const SavedFilters = Styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding: 6px;
`;
export const TitleWithMargin = Styled.div`
	display: block;
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 16px;
	color: #535353;
`;

export const Title = Styled.div`
	flex-grow: 1;
	font-size: 16px;
	font-weight: 500;
	color: ${props => {
		return '#535353';
	}};

	> span.highlight {
		color: #6e0fea;
	}
`;

export const DropdownContainer = Styled.div`
	width: 100%;

`;

export const SmallTitle = Styled.div`
	display: inline-block;
	letter-spacing: 0.5px;
	color: #a1a1a1;
	font-weight: 700;
	font-size: 9px;
	text-transform :uppercase;
`;

export const AdvancedText = Styled(SmallTitle)`

	margin-right: 8px;

`;

export const ShowAdvancedClickable = Styled.div`
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
`;

export const ShowAdvancedContainer = Styled.div`
	height: 16px;

`;

export const DropdownGrid = Styled.div`
	display: grid;
	width: 100%;
	grid-template-columns: repeat(3, 155px);
	grid-template-rows: auto;
	gap: 6px 6px;
	margin-bottom: 14px;
`;

export const AppliedFiltersFlexFlow = Styled.div`
	display: flex;
	flex-wrap: wrap;
	margin:0 0 22px 0;
`;

export const AppliedFiltersGroups = Styled.div`
`;

export const AppliedFiltersGroup = Styled.div`
	margin-top: 6px;
	margin-bottom: 15px;
`;
export const AppliedFiltersGroupHeader = Styled.div`
	font-size: 12px;
	height: 16px;
	color: ${CSS_CONSTANTS.schedule_text_grey};
	margin-bottom: 11px;
	display: flex;
	align-items: center;
`;

export const AppliedFiltersTypeLabel = Styled.span`
	font-weight: 600;
`;
export const AppliedFiltersGroupSectionName = Styled.span`
	font-weight: 400;
	margin-left: 4px;
`;

export const OperatorBadge = Styled.div`
	font-family: Inter, sans-serif;
	padding: 3px 12px;
	background: ${props => props.color};
	color: #000000;
	border-radius: 12px;
	font-size: 10px;
	line-height: 10px;
	font-weight: 400;
	margin-left: 8px;
`;

export const AppliedFiltersGroupBadges = Styled.div`
	display: flex;
	flex-wrap: wrap;
`;

export const Item = Styled.div`
	border: 1px solid #a1a1a1;
	border-radius: 4px;
	width: 100%;
	height: 30px;
	
`;

export const BadgeWrapper = Styled.div`
	margin: 0 5px 5px 0;
`;

export const AppliedFiltersContainer = Styled.div`
	border-top: 1px solid #ebebeb;
	padding-top: 19px;
	flex:1;
`;

export const AppliedFiltersTop = Styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 9px;
`;

export const Buttons = Styled.div`
	display:flex;
	justify-content: flex-end;
	flex-grow: 1;

	 > * {
		 margin-left: 5px;
	 }
`;

export const CarretWrapper = Styled.div`
	height:30px;
	width:22px;
	display: flex;
	padding: 0 6px;
	justify-content: center;
`;

export const FilterButtonWrapper = Styled.div`
	display:flex;
	button{
		z-index:1;
	}
`;

export const SliderWrapper = Styled.div`
	width: ${props => (props.expanded ? props.filtersWidth + 26 + 'px' : '22px')};
	height:30px;
	border: solid 1px #ebebee;
	background-color: #ffffff;
	display:flex;
	cursor:pointer;
	transition:width 0.5s ease-in;
	padding-right: 6px;
	justify-content: space-between;
	overflow: ${props => (props.moving || !props.expanded ? 'hidden' : '')} ;
`;

export const ShowMoreToggle = Styled.div`
	display: flex;
	color: #727483;
	font-size: 11px;
	font-weight: 400;
	cursor: pointer;
    span {
    	margin-right: 5px;
    }
    margin-bottom: 20px;
`;
