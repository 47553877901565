import React, {useCallback, useEffect, useRef, useState} from 'react';
import {PageHeaderInput} from 'web-components';
import {limitString} from '../../../util/StringUtil';

const inputTitle = ({value, onChange, placeholder, userpilot, defaultTitle}) => {
	const inputRef = useRef();

	const [_value, setValue] = useState(value);

	const calculateWidth = useCallback((text, font) => {
		const element = document.createElement('canvas');
		const context = element.getContext('2d');
		context.font = font;
		return context.measureText(text).width + 0 + 'px;';
	});

	const containsOnlyWhiteSpaces = string => {
		return !string.replace(/\s/g, '').length > 0;
	};

	const initialWidth = () => {
		calculateWidth(value, '500 20px neue-haas-grotesk-text');
	};

	const [width, setWidth] = useState(null);

	const handleOnChange = e => {
		setValue(limitString(e, 255));
	};

	useEffect(() => {
		setWidth(initialWidth);
	}, []);

	const handleBlur = () => {
		if (_value && containsOnlyWhiteSpaces(_value)) {
			setValue(defaultTitle);
			onChange(defaultTitle);
		} else {
			onChange(_value);
		}
	};

	return (
		<div data-userpilot={userpilot} data-cy={userpilot}>
			<PageHeaderInput
				placeholder={placeholder}
				innerRef={inputRef}
				transition={true}
				width={width}
				value={_value}
				onBlur={handleBlur}
				onChange={handleOnChange}
			/>
		</div>
	);
};

export default inputTitle;
