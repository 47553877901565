import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import ActionMenu from '../../shared/components/action-menu/actions_menu';
import UpdateTeamMutation from '../../../mutations/update_team_mutation';
import DeleteTeamMutation from '../../../mutations/delete_team_mutation';
import {MODAL_TYPE, showModal} from '../../shared/components/modals/generic_modal_conductor';
import Util from '../../shared/util/util';
import {createToast} from '../../shared/components/toasts/another-toast/toaster';
import Person from '../../shared/components/person/person';
import AutoSchedulingDropdown from '../../../components/canvas-scheduling/projects-scheduling/autoscheduling/auto_scheduling_dropdown';
import InputField from '../../../components/new-ui/input_field';
import {profilePicSrc} from '../../../directApi';

class Team extends Component {
	constructor(props) {
		super(props);

		const {team} = this.props;

		this.state = {
			expanded: true,
			dropdownTeamPersons: team.teamPersons,
			showTeamNameInput: false,
			teamName: team.name,
		};
	}

	toggleExpansion(ignoreClickInteractionPrevention) {
		if (!ignoreClickInteractionPrevention && this.props.isPreventClickInteractionSet) return;
		this.setState({expanded: !this.state.expanded});
	}

	deleteTeam() {
		const {team, companyId} = this.props;
		const {formatMessage} = this.props.intl;

		const onSuccess = () => {
			createToast({duration: 5000, message: formatMessage({id: 'settings_team.team_deleted'})});
		};
		const callbackPositive = params => {
			Util.CommitMutation(
				DeleteTeamMutation,
				{
					companyId,
					id: team.id,
				},
				onSuccess,
				true
			);
		};
		showModal({
			type: MODAL_TYPE.GENERIC_DELETION_WARNING,
			deleteCallback: callbackPositive,
		});
	}

	removePersonFromTeam(personId) {
		if (this.props.isPreventClickInteractionSet) return;
		const {team} = this.props;
		const personIds = team.allTeamPersonIds;
		personIds.splice(personIds.indexOf(personId), 1);

		const {dropdownTeamPersons} = this.state;
		const index = dropdownTeamPersons.findIndex(person => person.id === personId);
		dropdownTeamPersons.splice(index, 1);
		this.setState(dropdownTeamPersons);

		Util.CommitMutation(
			UpdateTeamMutation,
			{
				id: this.props.team.id,
				persons: personIds,
			},
			null,
			true
		);
	}

	selectPerson(person) {
		const {dropdownTeamPersons} = this.state;
		dropdownTeamPersons.push(person);
		this.setState(dropdownTeamPersons);
	}

	deselectPerson(person) {
		const {dropdownTeamPersons} = this.state;
		const index = dropdownTeamPersons.findIndex(dropdownTeamPerson => dropdownTeamPerson.id === person.id);
		dropdownTeamPersons.splice(index, 1);
		this.setState(dropdownTeamPersons);
	}

	onPersonDropdownCollapse() {
		this.props.preventClickInteraction();
		const {dropdownTeamPersons} = this.state;
		const newPersonIds = dropdownTeamPersons.map(person => person.id);
		const oldPersonIds = this.props.team.teamPersons.map(teamPerson => teamPerson.id);
		if (newPersonIds.length === oldPersonIds.length && newPersonIds.every(personId => oldPersonIds.includes(personId)))
			return;
		Util.CommitMutation(
			UpdateTeamMutation,
			{
				id: this.props.team.id,
				persons: newPersonIds,
			},
			null,
			true
		);
	}

	onTeamNameChange(teamName) {
		this.setState({teamName});
	}

	onTeamNameBlur() {
		this.setState({showTeamNameInput: false});
		this.props.preventClickInteraction();
		if (this.state.teamName === this.props.team.name) return;

		Util.CommitMutation(
			UpdateTeamMutation,
			{
				id: this.props.team.id,
				name: this.state.teamName,
			},
			null,
			true
		);
	}

	showTeamNameInput(e) {
		e.stopPropagation();
		this.setState({showTeamNameInput: true});
	}

	getLinkPath(person) {
		if (person.isViewer) {
			return '/my-profile/account-configuration';
		} else {
			return '/admin/team/view-user/' + person.id;
		}
	}

	render() {
		const {formatMessage} = this.props.intl;
		const {team, allPersons} = this.props;
		const teamPersons = team.teamPersons.sort((a, b) =>
			Util.sortAlphabetically(a.firstName + ' ' + a.lastName, b.firstName + ' ' + b.lastName)
		);
		const {expanded, dropdownTeamPersons, showTeamNameInput, teamName} = this.state;
		return (
			<div className="settings-team">
				<div className="settings-team-head-row" onClick={this.toggleExpansion.bind(this, false)}>
					{showTeamNameInput ? (
						<InputField
							id={team.id + 'team-name'}
							value={teamName}
							onChange={this.onTeamNameChange.bind(this)}
							onBlur={this.onTeamNameBlur.bind(this)}
							type="text"
							placeholder=""
							autoFocus={true}
							hideLabel={true}
							blurOnEnter={true}
						/>
					) : (
						<div className="team-name-container" onClick={this.showTeamNameInput.bind(this)}>
							<div className="team-name">
								{team.name} <span>({teamPersons.length})</span>{' '}
							</div>
						</div>
					)}

					<button
						className={'expansion-button' + (expanded ? ' expanded' : '')}
						onClick={e => {
							e.stopPropagation();
							this.toggleExpansion(true);
						}}
					/>
					<ActionMenu
						options={[{text: formatMessage({id: 'common.delete'}), onClick: this.deleteTeam.bind(this)}]}
						whiteInner={true}
					/>
				</div>
				{expanded ? (
					<div className="settings-team-team-members">
						<table className="settings-team-table">
							<thead>
								<tr>
									<th className="name-column">{formatMessage({id: 'common.name'})}</th>
									<th className="role-column">{formatMessage({id: 'common.role'})}</th>
									<th className="email-column">{formatMessage({id: 'common.email'})}</th>
									<th />
								</tr>
							</thead>
							<tbody>
								{teamPersons.map(person => (
									<tr key={person.id} className="settings-team-team-member">
										<td
											className="name-column person-link-cell"
											onClick={e => {
												e.stopPropagation();
												window.open(this.getLinkPath(person), '_self');
											}}
										>
											<Person
												key={person.id}
												name={person.fullName}
												showName={true}
												showRole={false}
												imageSrc={profilePicSrc(person.profilePictureId)}
												imageSize="medium"
											/>
										</td>
										<td className="role-column">{person.role ? person.role.name : ''}</td>
										<td className="email-column">{person.email || ''}</td>
										<td>
											<button
												className="delete-person-button"
												onClick={this.removePersonFromTeam.bind(this, person.id)}
											/>
										</td>
									</tr>
								))}
							</tbody>
						</table>

						<div className="settings-team-add-team-member-row">
							<AutoSchedulingDropdown
								placeholder={formatMessage({id: 'settings_teams.add_people'})}
								useSmallerStyling={true}
								assignablePersons={allPersons}
								assignedPersons={dropdownTeamPersons}
								assignableRoles={[]}
								assignPerson={this.selectPerson.bind(this)}
								unassignPerson={this.deselectPerson.bind(this)}
								isMultiSelect={true}
								onCollapse={this.onPersonDropdownCollapse.bind(this)}
							/>
						</div>
					</div>
				) : null}
			</div>
		);
	}
}

Team.propTypes = {
	team: PropTypes.object.isRequired,
	companyId: PropTypes.string.isRequired,
	isPreventClickInteractionSet: PropTypes.bool.isRequired,
	preventClickInteraction: PropTypes.func.isRequired,
};

export default injectIntl(Team);
