import { isDateDisabled } from '../../DateUtil';
import { getTimeRegistrationDateRestrictionInfoMessage, isTimeRegistrationDateInvalid, isTimeRegistrationDateInvalidWithReason, } from './TimeRegistrationDateFilter';
import { createDateFromNullableYMD } from '../../DateUtil';
export function getIsDateDisabledForTaskAndProject({ date, taskStartDate, taskEndDate, projectStartDate, projectEndDate, ignoredDates, }) {
    return (isDateDisabled(date) ||
        isTimeRegistrationDateInvalid({
            date,
            taskStartDate,
            taskEndDate,
            projectStartDate,
            projectEndDate,
            ignoredDates,
        }));
}
export function getIsDateDisabledForTaskAndProjectWithReason({ date, taskStartDate, taskEndDate, projectStartDate, projectEndDate, ignoredDates, }) {
    return isDateDisabled(date)
        ? 'The company does not allow time registrations on this date'
        : isTimeRegistrationDateInvalidWithReason({
            date,
            taskStartDate,
            taskEndDate,
            projectStartDate,
            projectEndDate,
            ignoredDates,
        });
}
export function getTaskDates(task) {
    return {
        taskStartDate: createDateFromNullableYMD({ year: task === null || task === void 0 ? void 0 : task.startYear, month: task === null || task === void 0 ? void 0 : task.startMonth, day: task === null || task === void 0 ? void 0 : task.startDay }),
        taskEndDate: createDateFromNullableYMD({
            year: task === null || task === void 0 ? void 0 : task.deadlineYear,
            month: task === null || task === void 0 ? void 0 : task.deadlineMonth,
            day: task === null || task === void 0 ? void 0 : task.deadlineDay,
        }),
    };
}
export function getProjectDates(project) {
    return {
        projectStartDate: createDateFromNullableYMD({
            year: project === null || project === void 0 ? void 0 : project.projectStartYear,
            month: project === null || project === void 0 ? void 0 : project.projectStartMonth,
            day: project === null || project === void 0 ? void 0 : project.projectStartDay,
        }),
        projectEndDate: createDateFromNullableYMD({
            year: project === null || project === void 0 ? void 0 : project.projectEndYear,
            month: project === null || project === void 0 ? void 0 : project.projectEndMonth,
            day: project === null || project === void 0 ? void 0 : project.projectEndDay,
        }),
    };
}
export function validateDateReturnErrorMessage(selectedDate, task, project) {
    return getIsDateDisabledForTaskAndProjectWithReason(Object.assign(Object.assign({ date: selectedDate.toDate() }, getTaskDates(task)), getProjectDates(project)));
}
export function getDateRestrictionInfo(task, project) {
    if (!task || !project) {
        return null;
    }
    return getTimeRegistrationDateRestrictionInfoMessage(Object.assign(Object.assign({}, getTaskDates(task)), getProjectDates(project)));
}
