/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type customFieldsSection_viewer$ref: FragmentReference;
declare export opaque type customFieldsSection_viewer$fragmentType: customFieldsSection_viewer$ref;
export type customFieldsSection_viewer = {|
  +task: ?{|
    +id: string,
    +customFieldValues: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +key: ?string,
          +value: ?string,
          +displayName: ?string,
          +readOnly: ?boolean,
        |}
      |}>
    |},
  |},
  +$refType: customFieldsSection_viewer$ref,
|};
export type customFieldsSection_viewer$data = customFieldsSection_viewer;
export type customFieldsSection_viewer$key = {
  +$data?: customFieldsSection_viewer$data,
  +$fragmentRefs: customFieldsSection_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "taskId",
      "type": "ID"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "customFieldsSection_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "taskId"
        }
      ],
      "concreteType": "Task",
      "kind": "LinkedField",
      "name": "task",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomFieldValueTypeConnection",
          "kind": "LinkedField",
          "name": "customFieldValues",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CustomFieldValueTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CustomFieldValue",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "key",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "value",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "displayName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "readOnly",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '44fd451bf8fd7167742c450645de4891';

module.exports = node;
