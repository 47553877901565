import { useRef } from 'react';
/* An improved usePrevious that does not rely on useEffect and only updates when the value changes */
export const usePrevious = (value) => {
    const ref = useRef({
        value: value,
        prev: null,
    });
    const current = ref.current.value;
    if (value !== current) {
        ref.current = {
            value: value,
            prev: current,
        };
    }
    return ref.current.prev;
};
