import React from 'react';
import styled from 'styled-components';
import { DeprecatedText as Text } from '@forecast-it/design-system';
import { Link } from 'react-router-dom';
import { getNotAllowedReasonEntityLabel, } from '../../util/time-registration/time-registration-settings/TimeRegistrationNotAllowedReason';
import { getTaskLink } from '../../util/LinkUtil';
const InvalidatedTimerTaskWrapper = styled.div `
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-bottom: 8px;
	padding: 8px 12px;
	background-color: #f7f7fe;
	border-radius: 8px;
`;
function TimerTaskInvalidInfo({ invalidatedTimerTask }) {
    const notAllowedReasonEntityLabel = getNotAllowedReasonEntityLabel(invalidatedTimerTask.reason);
    const taskLink = invalidatedTimerTask.taskId ? getTaskLink(invalidatedTimerTask.taskId) : null;
    const isCurrentLink = window.location.pathname === taskLink;
    const isNonLinkText = isCurrentLink || !taskLink;
    const nonLinkText = isCurrentLink ? 'This task' : 'A task';
    return (React.createElement(InvalidatedTimerTaskWrapper, null,
        React.createElement(Text, null,
            isNonLinkText ? nonLinkText : React.createElement(Link, { to: taskLink }, "The task your timer was started on"),
            ` is no longer a valid time registration target because your company does not allow time registrations ${notAllowedReasonEntityLabel}.`),
        React.createElement(Text, null, "Please select a different task.")));
}
export default TimerTaskInvalidInfo;
