import React, {useState} from 'react';
import Styled from 'styled-components';
import PropTypes from 'prop-types';
import {Switch} from 'web-components';

const SwitchLine = ({name, onToggle, initToggleState}) => {
	const [checked, setChecked] = useState(initToggleState);

	const onChange = () => {
		const newCheckState = !checked;
		setChecked(newCheckState);
		onToggle(newCheckState);
	};

	return (
		<Container>
			{name}
			<Switch onChange={onChange} checked={checked} />
		</Container>
	);
};

const Container = Styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: transparent;
	margin: 5px 0px 5px 0px;
	padding: 0px 12px 0px 12px;
	box-sizing: border-box;
	width: 100%;
	:last-child { 
		border-bottom: 1px solid #e6e6e6;
		padding-bottom: 5px;
	}
`;

SwitchLine.propTypes = {
	name: PropTypes.string,
	onToggle: PropTypes.func,
	initToggleState: PropTypes.bool,
	cy: PropTypes.string,
};

export default SwitchLine;
