/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type settingsCompanyNotifications_viewer$ref = any;
export type settingsCompanyNotifications_QueryVariables = {||};
export type settingsCompanyNotifications_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: settingsCompanyNotifications_viewer$ref,
  |}
|};
export type settingsCompanyNotifications_Query = {|
  variables: settingsCompanyNotifications_QueryVariables,
  response: settingsCompanyNotifications_QueryResponse,
|};
*/


/*
query settingsCompanyNotifications_Query {
  viewer {
    actualPersonId
    component(name: "settings_notifications")
    ...settingsCompanyNotifications_viewer
    id
  }
}

fragment settingsCompanyNotifications_viewer on Viewer {
  id
  company {
    id
    useTimeApproval
    timesheetReminderEnabled
    timesheetReminderDay
    timesheetReminderHour
    timesheetReminderIncludeToday
    projectDigestNotificationEnabled
    projectDigestNotificationDay
    projectDigestNotificationHour
    roles(first: 10000) {
      edges {
        node {
          id
          name
          notifyProjectOverview
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "settings_notifications"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"settings_notifications\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "settingsCompanyNotifications_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "settingsCompanyNotifications_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "settingsCompanyNotifications_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "useTimeApproval",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timesheetReminderEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timesheetReminderDay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timesheetReminderHour",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timesheetReminderIncludeToday",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectDigestNotificationEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectDigestNotificationDay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectDigestNotificationHour",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 10000
                  }
                ],
                "concreteType": "RoleTypeConnection",
                "kind": "LinkedField",
                "name": "roles",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RoleTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Role",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "notifyProjectOverview",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "roles(first:10000)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "settingsCompanyNotifications_Query",
    "operationKind": "query",
    "text": "query settingsCompanyNotifications_Query {\n  viewer {\n    actualPersonId\n    component(name: \"settings_notifications\")\n    ...settingsCompanyNotifications_viewer\n    id\n  }\n}\n\nfragment settingsCompanyNotifications_viewer on Viewer {\n  id\n  company {\n    id\n    useTimeApproval\n    timesheetReminderEnabled\n    timesheetReminderDay\n    timesheetReminderHour\n    timesheetReminderIncludeToday\n    projectDigestNotificationEnabled\n    projectDigestNotificationDay\n    projectDigestNotificationHour\n    roles(first: 10000) {\n      edges {\n        node {\n          id\n          name\n          notifyProjectOverview\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0241bf767ee33a7d6071d7ed6a358a38';

module.exports = node;
