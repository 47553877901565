/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type settingsAppCatalog_viewer$ref = any;
export type settingsAppCatalog_QueryVariables = {||};
export type settingsAppCatalog_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: settingsAppCatalog_viewer$ref,
  |}
|};
export type settingsAppCatalog_Query = {|
  variables: settingsAppCatalog_QueryVariables,
  response: settingsAppCatalog_QueryResponse,
|};
*/


/*
query settingsAppCatalog_Query {
  viewer {
    actualPersonId
    component(name: "settings_catalog")
    ...settingsAppCatalog_viewer
    id
  }
}

fragment PersonDropdown_persons on PersonTypeEdge {
  node {
    id
    fullName
    firstName
    lastName
    profilePictureId
    active
    client {
      id
    }
    role {
      name
      id
    }
  }
}

fragment ToggleIsSSOMandatory_company on Company {
  oktaEnabled
  oneloginEnabled
  AADEnabled
  googleSSOEnabled
  isSSOMandatory
  allPersons(first: 10000, excludeClientUsers: true, onlyActive: true) {
    edges {
      ...PersonDropdown_persons
      node {
        id
        fullName
        firstName
        lastName
        email
        profilePictureId
        profilePictureDefaultId
        active
        hasSSOExemption
      }
    }
  }
}

fragment settingsAppCatalog_viewer on Viewer {
  company {
    ...ToggleIsSSOMandatory_company
    tier
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "settings_catalog"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"settings_catalog\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "settingsAppCatalog_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "settingsAppCatalog_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "settingsAppCatalog_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "oktaEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "oneloginEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "AADEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "googleSSOEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isSSOMandatory",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "excludeClientUsers",
                    "value": true
                  },
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 10000
                  },
                  {
                    "kind": "Literal",
                    "name": "onlyActive",
                    "value": true
                  }
                ],
                "concreteType": "PersonTypeConnection",
                "kind": "LinkedField",
                "name": "allPersons",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PersonTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Person",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fullName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "profilePictureId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "active",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Client",
                            "kind": "LinkedField",
                            "name": "client",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Role",
                            "kind": "LinkedField",
                            "name": "role",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "profilePictureDefaultId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasSSOExemption",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "allPersons(excludeClientUsers:true,first:10000,onlyActive:true)"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tier",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "settingsAppCatalog_Query",
    "operationKind": "query",
    "text": "query settingsAppCatalog_Query {\n  viewer {\n    actualPersonId\n    component(name: \"settings_catalog\")\n    ...settingsAppCatalog_viewer\n    id\n  }\n}\n\nfragment PersonDropdown_persons on PersonTypeEdge {\n  node {\n    id\n    fullName\n    firstName\n    lastName\n    profilePictureId\n    active\n    client {\n      id\n    }\n    role {\n      name\n      id\n    }\n  }\n}\n\nfragment ToggleIsSSOMandatory_company on Company {\n  oktaEnabled\n  oneloginEnabled\n  AADEnabled\n  googleSSOEnabled\n  isSSOMandatory\n  allPersons(first: 10000, excludeClientUsers: true, onlyActive: true) {\n    edges {\n      ...PersonDropdown_persons\n      node {\n        id\n        fullName\n        firstName\n        lastName\n        email\n        profilePictureId\n        profilePictureDefaultId\n        active\n        hasSSOExemption\n      }\n    }\n  }\n}\n\nfragment settingsAppCatalog_viewer on Viewer {\n  company {\n    ...ToggleIsSSOMandatory_company\n    tier\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c998f2ca0c557d3a42da2c7e647e5ade';

module.exports = node;
