/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ResourceManagement_company$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type SettingsSetup_viewer$ref: FragmentReference;
declare export opaque type SettingsSetup_viewer$fragmentType: SettingsSetup_viewer$ref;
export type SettingsSetup_viewer = {|
  +id: string,
  +company: ?{|
    +id: string,
    +companyTarget: ?number,
    +revenueEnabled: ?boolean,
    +timesheetsEnabled: ?boolean,
    +clientsEnabled: ?boolean,
    +sageIntacctTimeRegsFromForecast: ?boolean,
    +$fragmentRefs: ResourceManagement_company$ref,
  |},
  +$refType: SettingsSetup_viewer$ref,
|};
export type SettingsSetup_viewer$data = SettingsSetup_viewer;
export type SettingsSetup_viewer$key = {
  +$data?: SettingsSetup_viewer$data,
  +$fragmentRefs: SettingsSetup_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingsSetup_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "companyTarget",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "revenueEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "timesheetsEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "clientsEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sageIntacctTimeRegsFromForecast",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ResourceManagement_company"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '05eacecbc178ac602441e93a7694a87a';

module.exports = node;
