import {
	isInternalTimeRegistrationAllowed,
	isProjectTimeRegistrationAllowed,
} from '../../../../../shared/util/cache/TimeRegistrationSettingsUtil';
import {
	getTaskTimeRegistrationNotAllowedWithReason,
	isTaskTimeRegistrationAllowed,
} from '../../../../../shared/util/time-registration/time-registration-settings/TimeRegistrationTaskFilter';
import {TIME_REG_TYPES} from '../../time_row_utils';
import {TimeRegistrationNotAllowedReason} from '../../../../../shared/util/time-registration/time-registration-settings/TimeRegistrationNotAllowedReason';
import {createDateFromNullableYMD} from '../../../../../shared/util/DateUtil';
import {isTimeRegistrationDateInvalidWithReason} from '../../../../../shared/util/time-registration/time-registration-settings/TimeRegistrationDateFilter';
import {hasFeatureFlag} from '../../../../../shared/util/FeatureUtil';
import {APPROVAL_STATUS} from '../../../../../../constants';
import {cloneDeep} from 'lodash';
import {getTimeRegistrationNotAllowedTooltip} from '../../../../../shared/components/add-time-entry/TimeRegistrationNotAllowedTooltip';
import ProjectUtil from '../../../../../shared/util/project_util';

export const idleTimeRegistrationAllowed = idleTime => {
	if (idleTime?.isInternalTime) {
		return isInternalTimeRegistrationAllowed();
	}

	return true;
};

export const taskTimeRegistrationAllowed = (task, selectedPersonId) => {
	const statusCategory = task.statusColumnV2?.category;
	const assignedPersonIds = task.assignedPersons ? task.assignedPersons.map(person => person.id) : [];
	return isTaskTimeRegistrationAllowed(statusCategory, selectedPersonId, assignedPersonIds, task.project);
}; // This function is sort-of exploiting the fact that project dates and task dates are not named the same in graphql.

export const getLineItemRegistrationNotAllowedReason = (lineItem, selectedPersonId) => {
	switch (lineItem.type) {
		case TIME_REG_TYPES.PROJECT_TIME: {
			if (!isProjectTimeRegistrationAllowed()) {
				return TimeRegistrationNotAllowedReason.NoProject;
			}
			return null;
		}
		case TIME_REG_TYPES.IDLE_TIME: {
			if (!idleTimeRegistrationAllowed(lineItem)) {
				return TimeRegistrationNotAllowedReason.NoInternalTime;
			}
			return null;
		}
		case TIME_REG_TYPES.TASK_TIME: {
			const lineItemTaskEntity = lineItem.task || lineItem; // If the table is in day view, the time registration is the primary line item and the task a property, whereas if the table is in week view, the task is the primary line item.
			const statusCategory = lineItemTaskEntity.statusColumnV2?.category;
			const assignedPersonIds = lineItemTaskEntity.assignedPersons
				? lineItemTaskEntity.assignedPersons.map(person => person.id)
				: [];
			return getTaskTimeRegistrationNotAllowedWithReason(statusCategory, selectedPersonId, assignedPersonIds);
		}
	}
};

// The startDate and endDate fields are always assumed to be task dates.
export const getInputRegistrationNotAllowedReason = (momentDate, lineItem) => {
	const date = momentDate.toDate();
	if (lineItem) {
		const lineItemProject = lineItem.type === TIME_REG_TYPES.PROJECT_TIME ? lineItem : lineItem.project;
		const projectStartDate = createDateFromNullableYMD({
			year: lineItemProject?.projectStartYear,
			month: lineItemProject?.projectStartMonth,
			day: lineItemProject?.projectStartDay,
		});
		const projectEndDate = createDateFromNullableYMD({
			year: lineItemProject?.projectEndYear,
			month: lineItemProject?.projectEndMonth,
			day: lineItemProject?.projectEndDay,
		});
		const taskStartDate = createDateFromNullableYMD({
			year: lineItem.startYear,
			month: lineItem.startMonth,
			day: lineItem.startDay,
		});
		const taskEndDate = createDateFromNullableYMD({
			year: lineItem.deadlineYear,
			month: lineItem.deadlineMonth,
			day: lineItem.deadlineDay,
		});
		return isTimeRegistrationDateInvalidWithReason({
			date,
			projectStartDate,
			projectEndDate,
			taskStartDate,
			taskEndDate,
		});
	}

	return null;
};
export const getRemainingMinutes = lineItemNode => {
	const task = lineItemNode.task || lineItemNode;
	if (!task || task.estimateForecastMinutes === undefined || task.totalMinutesRegistered === undefined) {
		return undefined;
	}
	return task.estimateForecastMinutes - task.totalMinutesRegistered;
};

export function isDoneOrHalted(project) {
	return project ? project.status === 'DONE' || project.status === 'HALTED' : false;
}

export const getInfoTextForHoursInput = (
	useTimeOffApproval,
	noAccess,
	lockedInternalTime,
	lockedTimeOff,
	inLockedPeriod,
	approvalStatus,
	invoiced,
	projectDone,
	projectHalted,
	containsNotes,
	hasMultipleHarvstTasks,
	hasAllocation,
	isWeekView,
	isInternalTimeDisabled,
	lockedInTime,
	periodTimeApprovalStatus,
	lineItemRegistrationNotAllowedReason,
	inputRegistrationNotAllowedReason,
	intl,
	notPermittedHourInputs,
	timeOffLockedByBambooHR,
	manualProgress
) => {
	const {formatMessage} = intl;
	const switchToDayText = formatMessage({id: 'timesheets.switch_to_day_view_for_details'});
	if (lockedInTime || lockedInternalTime) {
		if (approvalStatus) {
			if (approvalStatus === APPROVAL_STATUS.APPROVED) return formatMessage({id: 'approval.approved_time'});
			else if (approvalStatus === APPROVAL_STATUS.SUBMITTED) return formatMessage({id: 'approval.submitted_time'});
		} else if (periodTimeApprovalStatus) {
			if (periodTimeApprovalStatus === APPROVAL_STATUS.APPROVED)
				return formatMessage({id: 'approval.approved_time_input'});
			else if (periodTimeApprovalStatus === APPROVAL_STATUS.SUBMITTED)
				return formatMessage({id: 'approval.submitted_time_input'});
		}
		return formatMessage({id: 'time_lock.time_reg_locked'});
	}
	if (noAccess) {
		return formatMessage({id: 'overview_time.inaccessible'});
	}
	if (lockedTimeOff) {
		if (useTimeOffApproval) {
			return formatMessage({id: 'approval.submitted_time'});
		}
		return formatMessage({id: 'timesheets.edit_time_off_as_allocation'});
	}
	if (isInternalTimeDisabled) {
		return formatMessage({id: 'timesheets.hours_input.disabled_idle_time'});
	}
	if (hasAllocation) {
		return formatMessage({id: 'timesheets.hours_input.allocation'});
	}
	if (isWeekView && hasMultipleHarvstTasks) {
		return formatMessage({id: 'timesheets.week_view.hours_input.harvest_task'}) + '. ' + switchToDayText;
	}
	if (inLockedPeriod) {
		if (approvalStatus) {
			if (approvalStatus === APPROVAL_STATUS.APPROVED) return formatMessage({id: 'approval.approved_time_unsubmitted'});
			else if (approvalStatus === APPROVAL_STATUS.SUBMITTED) return formatMessage({id: 'approval.submitted_time'});
		}
		return isWeekView
			? formatMessage({id: 'timesheets.week_view.hours_input.time_entries_in_locked_period'}) + '. ' + switchToDayText
			: formatMessage({id: 'timesheets.day_view.hours_input.time_entries_in_locked_period'});
	}
	if (invoiced) {
		return isWeekView
			? formatMessage({id: 'timesheets.week_view.hours_input.time_entries_invoiced'}) + '. ' + switchToDayText
			: formatMessage({id: 'timesheets.day_view.hours_input.time_entries_invoiced'});
	}
	if (projectDone || projectHalted) {
		return formatMessage(
			{id: 'project_section.locked-info'},
			{status: formatMessage({id: projectDone ? 'project_status.done' : 'project_status.halted'})}
		);
	}
	if (isWeekView && containsNotes) {
		return formatMessage({id: 'timesheets.week_view.hours_input.time_entries_contains_notes'}) + '. ' + switchToDayText;
	}
	if (lineItemRegistrationNotAllowedReason) {
		return getTimeRegistrationNotAllowedTooltip(lineItemRegistrationNotAllowedReason);
	}
	if (inputRegistrationNotAllowedReason) {
		return inputRegistrationNotAllowedReason;
	}
	if (notPermittedHourInputs) {
		return formatMessage({id: 'timesheets.unavailable_time_entries_edit_message'});
	}
	if (timeOffLockedByBambooHR) {
		return formatMessage({id: 'integrations.bamboohr.time_off_tooltip'});
	}
	if (manualProgress) {
		return formatMessage({id: 'timesheets.project_manual_progress'});
	}
};
export const timeEntryContainsHarvestTask = dayRegs => {
	return dayRegs && dayRegs.length > 1 && dayRegs.some(timeReg => timeReg.node && timeReg.node.harvestTask);
};
export const timeEntryInLockedPeriod = (lineItem, dayRegs, isWeekView) => {
	const timeReg = lineItem.timeReg ? (lineItem.timeReg.node ? lineItem.timeReg.node : lineItem.timeReg) : lineItem;
	return isWeekView
		? dayRegs && dayRegs.length > 0 && dayRegs.some(tReg => tReg.node.lockedInPeriod)
		: timeReg.lockedInPeriod;
};
export const getTimeEntryApprovalStatus = (lineItem, dayRegs, isWeekView) => {
	const timeReg = lineItem.timeReg ? (lineItem.timeReg.node ? lineItem.timeReg.node : lineItem.timeReg) : lineItem;
	return isWeekView
		? dayRegs &&
				dayRegs.length > 0 &&
				dayRegs.find(tReg => tReg.node.approvalStatus !== APPROVAL_STATUS.NOT_SUBMITTED)?.node.approvalStatus
		: timeReg.approvalStatus;
};
// hasInvoicedTime, invoiced, locked period etc.
export const timeEntryInvoiced = (lineItem, dayRegs, isWeekView) => {
	// This is kind of gross, but the structures from week/day view are different connections, so we kind of have to
	const timeReg = lineItem.timeReg ? (lineItem.timeReg.node ? lineItem.timeReg.node : lineItem.timeReg) : lineItem;
	const tRegInvoiced = timeReg => timeReg.invoiced || timeReg.xeroInvoiceId || timeReg.economicTimeId;
	return isWeekView ? dayRegs && dayRegs.length > 0 && dayRegs.some(tReg => tRegInvoiced(tReg.node)) : tRegInvoiced(timeReg);
};
export const hasLinkedAllocation = dayRegs => {
	return dayRegs.some(timeReg => !!timeReg.node?.allocationId);
};
export const timeEntryProjectHalted = lineItem => {
	const project = lineItem.project ? lineItem.project : lineItem.task ? lineItem.task.project : lineItem;
	return project.status && project.status === 'HALTED';
};
export const projectIsManualProgress = lineItem => {
	const project = lineItem.project ? lineItem.project : lineItem.task ? lineItem.task.project : lineItem;
	return ProjectUtil.projectUsesManualProgress(project);
};
export const timeEntryProjectDone = lineItem => {
	const project = lineItem.project ? lineItem.project : lineItem.task ? lineItem.task.project : lineItem;
	return project.status && project.status === 'DONE';
};
// Project done/halted, multiple time entries on same day, with notes
export const timeEntryContainsMultipleNotes = (lineItem, dayRegs) => {
	return dayRegs && dayRegs.length > 1 && dayRegs.some(timeReg => timeReg.node.notes && timeReg.node.notes.length > 0);
};
export const timeEntryContainsNotes = (lineItem, dayRegs) => {
	return dayRegs && dayRegs.some(timeReg => timeReg.node.notes && timeReg.node.notes.length > 0);
};
export const timeEntryNoAccess = lineItem => {
	// idle times don't have this field so we nee to check if false
	return (
		lineItem.fullAccessToProject === false ||
		(lineItem.task && !lineItem.task.fullAccessToProject) ||
		(lineItem.project && !lineItem.project.fullAccessToProject)
	);
};

export const timeEntryLockedTimeOff = (lineItem, dayRegs, isWeekView, useTimeOffApproval) => {
	if (hasFeatureFlag('pto_timesheet_allocation_linking')) {
		const timeReg = lineItem.timeReg ? (lineItem.timeReg.node ? lineItem.timeReg.node : lineItem.timeReg) : lineItem;

		const hasLockedIdleTime = timeReg => {
			const idleTime = timeReg.idleTime;
			const isInternalTime = idleTime?.isInternalTime;
			const approvalStatus = timeReg?.approvalStatus;

			const isSubmittedOrApproved = [APPROVAL_STATUS.SUBMITTED, APPROVAL_STATUS.APPROVED].includes(approvalStatus);
			const isDisabledStatus = useTimeOffApproval ? isSubmittedOrApproved : timeReg.allocationId && isSubmittedOrApproved;

			return !!idleTime && !isInternalTime && isDisabledStatus;
		};

		return isWeekView
			? dayRegs && dayRegs.length > 0 && dayRegs.some(tReg => hasLockedIdleTime(tReg.node))
			: hasLockedIdleTime(timeReg);
	} else {
		return false;
	}
};

export const lineItemIsTimeOff = lineItem => {
	if (hasFeatureFlag('pto_timesheet_allocation_linking')) {
		const timeReg = lineItem.timeReg?.node || lineItem.node;
		const isIdleTime = timeReg ? !!timeReg.idleTime : lineItem.switchIdleTime;
		const isInternalTime = lineItem.isInternalTime;

		return isIdleTime && !isInternalTime;
	} else {
		return false;
	}
};
export const lineItemWithNotes = (lineItem, dayRegsForEntitiy) => {
	const notes = [];
	const toMap = dayRegsForEntitiy ? dayRegsForEntitiy : [[], [], [], [], [], [], []];
	toMap.forEach(day => {
		day.forEach(timeReg => {
			const note = {...timeReg.node};
			notes.push(note);
		});
	});

	const lineItemClone = cloneDeep(lineItem);
	lineItemClone.timeRegistrationsWithNotes = notes;
	return lineItemClone;
};

const isIdleTimeRoleDifferent = timeReg => {
	if (!timeReg.person.role) return false;
	return timeReg.role.id !== timeReg.person.role.id;
};

const isTaskRoleDifferent = timeReg => {
	// Get role of person
	let personRoleId = timeReg.person?.role?.id;
	// Use project person role if it exists
	if (timeReg.task.project.projectPerson?.role) {
		personRoleId = timeReg.task.project.projectPerson.role.id;
	}
	return timeReg?.role.id !== personRoleId;
};

const isProjectRoleDifferent = timeReg => {
	// If the time reg has no role, then we can't check the match
	if (!timeReg.person.role) return false;
	// Get role of person
	let personRoleId = timeReg.person.role.id;
	// Use project person role if it exists
	if (timeReg.project.projectPerson?.role) {
		personRoleId = timeReg.project.projectPerson.role.id;
	}
	return timeReg.role.id !== personRoleId;
};
/**
 * Checks if the role associated with a time registration is different from
 * the default role for that time registration's type.
 *
 * @param {Object} timeReg The time registration object to check
 * @returns {Boolean} True if the roles are different, false otherwise
 */
export const checkIfRoleIsDifferent = timeReg => {
	// If the time reg has no role or type, then we can't check the match
	if (!timeReg.role || !timeReg.type) return false;

	switch (timeReg.type) {
		case TIME_REG_TYPES.IDLE_TIME:
			return isIdleTimeRoleDifferent(timeReg);
		case TIME_REG_TYPES.TASK_TIME:
			return isTaskRoleDifferent(timeReg);
		case TIME_REG_TYPES.PROJECT_TIME:
			return isProjectRoleDifferent(timeReg);
		default:
			return false;
	}
};
