import {useEffect, useState} from 'react';
import Util from '../../../shared/util/util';
import UnmarkProgramAsFavoriteMutation from '../../../../mutations/project-service/unmark_program_as_favorite_mutation';
import {createToast} from '../../../shared/components/toasts/toast';
import MarkProgramAsFavoriteMutation from '../../../../mutations/project-service/mark_program_as_favorite_mutation';
import {useIntl} from 'react-intl';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';
import UpdateProgramBasicsMutation from '../../../../mutations/project-service/update_program_basics_mutation';
import {hasPermission} from '../../../shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../Permissions';
import ProgramUtil from '../../../shared/util/ProgramUtil';

export const useProgram = (program, companyId) => {
	const {formatMessage} = useIntl();

	const [_program, setProgram] = useState(program);

	const isFavorite = _program.userRelationTypes.includes('FAVORITE');

	useEffect(() => setProgram(program), [program]);

	const updateProgramDescription = nextDescription => {
		if (nextDescription !== program.description) {
			Util.CommitMutation(
				UpdateProgramBasicsMutation,
				{
					id: program.id,
					name: program.name,
					description: nextDescription,
					color: program.color,
				},
				() =>
					createToast({
						duration: 5000,
						message: formatMessage(
							{id: 'common.feedback.updated_entity_field'},
							{
								entity: ProgramUtil.programText(formatMessage).toLowerCase(),
								field: formatMessage({id: 'common.description'}).toLowerCase(),
							}
						),
					})
			);
		}
	};

	const unmarkProgramAsFavorite = () => {
		Util.CommitMutation(UnmarkProgramAsFavoriteMutation, {programId: _program.id, companyId}, () =>
			createToast({
				duration: 5000,
				message: formatMessage(
					{id: 'common.action.unfavorite_entity'},
					{entity: ProgramUtil.programText(formatMessage)}
				),
			})
		);
	};

	const markProgramAsFavorite = () => {
		Util.CommitMutation(MarkProgramAsFavoriteMutation, {programId: _program.id, companyId}, () =>
			createToast({
				duration: 5000,
				message: formatMessage({id: 'common.action.favorite_entity'}, {entity: ProgramUtil.programText(formatMessage)}),
			})
		);
	};

	const updateProgramFavorite = () => {
		if (isFavorite) {
			trackEvent('Program', 'Unfavorited');
			unmarkProgramAsFavorite();
		} else {
			trackEvent('Program', 'Favorited');
			markProgramAsFavorite();
		}
	};

	const memberTypes = {
		OWNER: 'OWNER',
		USER: 'MEMBER',
	};

	const isMember = (actualPersonId, memberType) => {
		return program.members.edges
			?.map(member => member.node)
			.find(member => member?.role === memberType && member?.person?.id === actualPersonId);
	};

	const canManage = actualPersonId => {
		return (
			hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS) ||
			hasPermission(PERMISSION_TYPE.PROGRAMS_MANAGE_ALL) ||
			isMember(actualPersonId, memberTypes.OWNER)
		);
	};

	return {
		isFavorite,
		updateProgramFavorite,
		updateProgramDescription,
		isMember,
		memberTypes,
		canManage,
		// status: 0,
	};
};
