import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import CreateHolidayCalendarMutation from '../../../mutations/create_holiday_calendar_mutation';
import Util from '../../shared/util/util';
import CustomScrollDiv from '../../shared/components/scroll-bars/custom_scroll_div';
import HolidayCalendarsRow from './settings_holiday_calendars_row';
import HolidayCalendar from './settings_holiday_calendars_single_calendar';
import * as tracking from '../../../tracking';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../shared/components/headers/top-header-bar/TopHeaderBar';
import {trackPage, unregisterPageInfo} from '../../../tracking/amplitude/TrackingV2';

class HolidayCalendars extends Component {
	constructor(props) {
		super(props);

		this.superPropertyChecksum = trackPage('Settings Holiday Calendars');
	}

	componentDidMount() {
		document.title = 'Holiday Calendars - Settings - Forecast';

		// Segment
		tracking.trackPage('settings-holiday-calendars');
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	createHolidayCalendar() {
		Util.CommitMutation(
			CreateHolidayCalendarMutation,
			{
				companyId: this.props.viewer.company.id,
				name: this.props.intl.formatMessage({id: 'settings_holiday_calendars.new_holiday_calendar'}),
			},
			null,
			true
		);
	}

	getHeader() {
		return (
			<TopHeaderBarWrapper>
				<TopHeaderBar title={this.props.intl.formatMessage({id: 'common.holidays'})} />
			</TopHeaderBarWrapper>
		);
	}

	render() {
		const {formatMessage} = this.props.intl;
		const {viewer} = this.props;
		const {company} = viewer;
		const {defaultHolidayCalendar, holidayCalendars} = company;
		return (
			<div
				className="section-content settings-app settings-holiday-calendars"
				data-cy={'settings-holiday-calendars-page'}
			>
				{this.props.children}
				{this.getHeader()}
				<CustomScrollDiv>
					<div className="section-body">
						<div className="inner">
							{holidayCalendars.edges.length ? (
								holidayCalendars.edges.length === 1 ? (
									<HolidayCalendar
										holidayCalendar={holidayCalendars.edges[0].node}
										createHolidayCalendar={this.createHolidayCalendar.bind(this)}
										companyId={company.id}
									/>
								) : (
									<fieldset className="holiday-calendars">
										<div className="top-container">
											<button
												data-cy={'add-calendar-button'}
												onClick={this.createHolidayCalendar.bind(this)}
												className="add-holiday-calendar-button"
											>
												{formatMessage({id: 'settings_holiday_calendars.add_another_holiday_calendar'})}
											</button>
										</div>
										<table>
											<thead>
												<tr>
													<th className="column-name">{formatMessage({id: 'common.name'})}</th>
													<th>{formatMessage({id: 'settings.rate_card_default'})}</th>
													<th className="column-controls">&nbsp;</th>
												</tr>
											</thead>

											<tbody>
												{holidayCalendars.edges.map((holidayCalendarEdge, index) => (
													<HolidayCalendarsRow
														key={'holiday-calendar-row' + index}
														companyId={company.id}
														id={holidayCalendarEdge.node.id}
														name={holidayCalendarEdge.node.name}
														isDefault={
															!!(
																defaultHolidayCalendar &&
																defaultHolidayCalendar.id === holidayCalendarEdge.node.id
															)
														}
													/>
												))}
											</tbody>
										</table>
									</fieldset>
								)
							) : (
								<fieldset>
									<div className="holiday-calendars-empty-state-container">
										<h3>{formatMessage({id: 'settings_holiday_calendars.empty_state_text'})}</h3>
										<button
											data-cy="add-calendar-button"
											onClick={this.createHolidayCalendar.bind(this)}
											className="add-holiday-calendar-button"
										>
											{formatMessage({id: 'settings_holiday_calendars.add_holiday_calendar'})}
										</button>
									</div>
								</fieldset>
							)}
						</div>
					</div>
				</CustomScrollDiv>
			</div>
		);
	}
}

HolidayCalendars.propTypes = {};

const settingsHolidayCalendarsQuery = graphql`
	query settingsHolidayCalendars_Query {
		viewer {
			actualPersonId
			component(name: "settings_holiday_calendars")
			...settingsHolidayCalendars_viewer
		}
	}
`;

// Temporary solution, should belong to child component - Needs to be defined since holidayCalendarEntry is not a child of this component
// eslint-disable-next-line no-unused-vars
const holidayCalendarEntry = graphql`
	fragment settingsHolidayCalendars_holidayCalendarEntry on HolidayCalendarEntry {
		id
		name
		year
		month
		day
	}
`;

// Temporary solution, should belong to child component
graphql`
	fragment settingsHolidayCalendars_HolidayCalendar on HolidayCalendar {
		id
		name
		holidayCalendarEntries(first: 100000) @connection(key: "HolidayCalendar_holidayCalendarEntries") {
			edges {
				node {
					...settingsHolidayCalendars_holidayCalendarEntry @relay(mask: false)
				}
			}
		}
	}
`;

export {settingsHolidayCalendarsQuery};

export default injectIntl(
	createFragmentContainer(HolidayCalendars, {
		viewer: graphql`
			fragment settingsHolidayCalendars_viewer on Viewer {
				id
				availableFeatureFlags {
					key
				}
				company {
					id
					defaultHolidayCalendar {
						id
					}
					holidayCalendars(first: 10000) @connection(key: "Company_holidayCalendars") {
						edges {
							node {
								...settingsHolidayCalendars_HolidayCalendar @relay(mask: false)
							}
						}
					}
				}
			}
		`,
	})
);
