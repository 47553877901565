import React from 'react';
import styled from 'styled-components';

const SegmentedControlContainer = styled.div`
	display: flex;
	flex-direction: row;
	color: #393946;

	& > label:hover {
		border-color: #605dec;
		& + input + label {
			border-left-color: #605dec;
		}
		& + input:checked + label {
			border-left-width: 1px;
		}
	}
	& > input:checked + label {
		background: #605dec;
		border-color: #605dec;
		color: #ffffff;
		& + input + label {
			border-left-color: #605dec;
		}
	}
	& > input:focus + label {
		border-color: #605dec;
		outline: 4px solid #e5f4ff;
		z-index: 9999;
		border-right-width: 1px;
		& + input + label {
			border-left-width: 0px;
		}
	}
`;

const HiddenRadio = styled.input.attrs({type: 'radio'})`
	opacity: 0;
	filter: alpha(opacity=0);
	position: absolute;
	top: 0;
	left: 0;
`;

const Label = styled.label`
	height: 40px;
	line-height: 38px;
	font-size: 13px;
	text-align: center;
	cursor: pointer;
	flex: 1 1 0px;
	border-width: 1px ${props => (props.isLast ? '1px' : '0px')} 1px 1px;
	border-style: solid;
	border-color: ${props => (props.error ? '#FEB6B3' : '#e7e7f3')};
	border-top-left-radius: ${props => (props.isFirst ? '24px' : '0px')};
	border-bottom-left-radius: ${props => (props.isFirst ? '24px' : '0px')};
	border-top-right-radius: ${props => (props.isLast ? '24px' : '0px')};
	border-bottom-right-radius: ${props => (props.isLast ? '24px' : '0px')};
`;

const SegmentedControl = ({options, name, onClick, error, cyPrefix = ''}) => {
	return (
		<SegmentedControlContainer>
			{options.map((option, index, array) => (
				<React.Fragment key={index}>
					<HiddenRadio
						name={name}
						value={option.value}
						id={option.value}
						onClick={() => {
							onClick(option.value);
						}}
					/>
					<Label
						data-cy={`${cyPrefix}${option.value}`}
						htmlFor={option.value}
						isFirst={index === 0}
						isLast={index === array.length - 1}
						error={error}
					>
						{option.label || option.value}
					</Label>
				</React.Fragment>
			))}
		</SegmentedControlContainer>
	);
};

export default SegmentedControl;
