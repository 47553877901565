import {Table} from 'web-components';
import React, {useMemo} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {useIntl} from 'react-intl';
import * as tracking from '../../../tracking';
import {ActionMenuWrapper} from './table_row.styled';
import LabelRow from './label_row';
import Util from '../../../forecast-app/shared/util/util';
import DeleteLabelCategoryMutation from '../../../mutations/delete_label_category_mutation';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import ForecastTooltip from '../../../forecast-app/shared/components/tooltips/ForecastTooltip';
import {LabelEntityCountsStyled} from './label_entity_counts.styled.';
import NewLabelRow from './new_label_row';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';

const LabelCategoryRow = ({
	company,
	tableColumnsProps,
	nextLevelProps,
	labels,
	labelCategory,
	expanded,
	isLabelSelected,
	toggleLabelSelected,
}) => {
	const {formatMessage} = useIntl();
	const categoryRowIndex = tableColumnsProps.index;

	const {taskCount, projectCount, peopleCount} = useMemo(() => {
		let taskCount = 0,
			projectCount = 0,
			peopleCount = 0;

		for (const label of labels) {
			taskCount += label.taskCount;
			projectCount += label.projectCount;
			peopleCount += label.peopleCount;
		}

		return {taskCount, projectCount, peopleCount};
	}, [labels]);

	if (!labelCategory) {
		labelCategory = {
			name: formatMessage({id: 'settings_labels.no_category'}),
		};
	}

	const deleteLabelCategory = () => {
		Util.CommitMutation(
			DeleteLabelCategoryMutation,
			{
				id: labelCategory.id,
				companyId: company.id,
			},
			() => {
				createToast({
					duration: 5000,
					message: formatMessage({id: 'settings_labels.delete_category_toast'}, {name: labelCategory.name}),
				});
			}
		);
	};

	const getActionsMenuOptions = () => {
		return [
			{
				text: formatMessage({id: 'settings_labels.edit_category'}),
				cy: 'edit',
				onClick: () => {
					tracking.trackEvent('labels - update category modal opened');
					trackEvent('Update Label Category Modal', 'Opened');
					showModal({
						type: MODAL_TYPE.UPDATE_LABEL_CATEGORY,
						labelCategoryId: labelCategory.id,
					});
				},
			},
			{
				text: formatMessage({id: 'settings_labels.delete_category'}),
				cy: 'delete',
				onClick: () => {
					if (labels.length) {
						tracking.trackEvent('labels - category delete confirm modal opened');
						trackEvent('Delete Label Category Modal', 'Opened');
						showModal({
							type: MODAL_TYPE.CONFIRM_LABEL_CATEGORY_DELETION,
							title: formatMessage({id: 'settings_labels.delete_category_title'}, {name: labelCategory.name}),
							warningMessage: formatMessage({id: 'settings_labels.delete_category_warning'}),
							confirmText: formatMessage({id: 'settings.confirm'}),
							onDelete: deleteLabelCategory,
						});
					} else {
						deleteLabelCategory();
					}
				},
			},
		];
	};

	return (
		<>
			<Table.Row
				hoverStyle={{visibility: 'visible'}}
				cy={`label-category-${categoryRowIndex}`}
				{...tableColumnsProps}
				canExpandRow={!!labels.length}
				isHeader
			>
				<Table.Column>
					{labelCategory.name} ({labels.length})
				</Table.Column>
				<Table.Column />
				<Table.Column>
					<Table.Column.Cell>
						{labelCategory.id && !labelCategory.allowOnTasks ? (
							<ForecastTooltip
								placement={'bottom'}
								content={formatMessage(
									{id: 'settings_labels.not-allowed-entity'},
									{entity: formatMessage({id: 'settings_labels.not_allowed_tasks'})}
								)}
							>
								<LabelEntityCountsStyled disabled={!labelCategory.allowOnTasks}>
									<span>N/A</span>
								</LabelEntityCountsStyled>
							</ForecastTooltip>
						) : (
							<LabelEntityCountsStyled header>
								<span>{taskCount}</span>
							</LabelEntityCountsStyled>
						)}
					</Table.Column.Cell>
				</Table.Column>
				<Table.Column>
					<Table.Column.Cell>
						{labelCategory.id && !labelCategory.allowOnProjects ? (
							<ForecastTooltip
								placement={'bottom'}
								content={formatMessage(
									{id: 'settings_labels.not-allowed-entity'},
									{entity: formatMessage({id: 'settings_labels.not_allowed_projects'})}
								)}
							>
								<LabelEntityCountsStyled disabled={!labelCategory.allowOnProjects}>
									<span>N/A</span>
								</LabelEntityCountsStyled>
							</ForecastTooltip>
						) : (
							<LabelEntityCountsStyled header>
								<span>{projectCount}</span>
							</LabelEntityCountsStyled>
						)}
					</Table.Column.Cell>
				</Table.Column>
				<Table.Column>
					<Table.Column.Cell>
						{labelCategory.id && !labelCategory.allowOnPeople ? (
							<ForecastTooltip
								placement={'bottom'}
								content={formatMessage(
									{id: 'settings_labels.not-allowed-entity'},
									{entity: formatMessage({id: 'settings_labels.not_allowed_people'})}
								)}
							>
								<LabelEntityCountsStyled disabled={!labelCategory.allowOnPeople}>
									<span>N/A</span>
								</LabelEntityCountsStyled>
							</ForecastTooltip>
						) : (
							<LabelEntityCountsStyled header>
								<span>{peopleCount}</span>
							</LabelEntityCountsStyled>
						)}
					</Table.Column.Cell>
				</Table.Column>
				<Table.Column>
					{!!labelCategory.id && (
						<ActionMenuWrapper
							cy={'label-category'}
							className={'row-hover'}
							whiteInner={true}
							options={getActionsMenuOptions()}
						/>
					)}
				</Table.Column>
			</Table.Row>
			{expanded && (
				<>
					<Table.Rows
						data={{
							rows: labels,
						}}
						{...nextLevelProps}
					>
						{({rowData: label, tableColumnsProps}) => (
							<LabelRow
								cy={`label-category-${Util.trimWhitespace(labelCategory?.name)}-label-${Util.trimWhitespace(
									label?.name
								)}`}
								company={company}
								tableColumnsProps={tableColumnsProps}
								checked={isLabelSelected(label.id)}
								toggleSelect={e => {
									tracking.trackEvent('labels - category selected');
									trackEvent('Label Category', 'Selected');
									e.stopPropagation();
									toggleLabelSelected(label.id);
								}}
								label={label}
								labelCategory={labelCategory.id ? labelCategory : null}
							/>
						)}
					</Table.Rows>
					<NewLabelRow company={company} category={labelCategory.id ? labelCategory : null} />
				</>
			)}
		</>
	);
};

export default createFragmentContainer(LabelCategoryRow, {
	company: graphql`
		fragment labelCategoryRow_company on Company {
			id
			...labelRow_company
			...newLabelRow_company
		}
	`,
	labels: graphql`
		fragment labelCategoryRow_labels on Label @relay(plural: true) {
			id
			name
			taskCount
			projectCount
			peopleCount
			...labelRow_label
		}
	`,
	labelCategory: graphql`
		fragment labelCategoryRow_labelCategory on LabelCategory {
			id
			name
			allowOnTasks
			allowOnProjects
			allowOnPeople
			...labelRow_labelCategory
		}
	`,
});
