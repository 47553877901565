/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PersonDropdownQueryVariables = {|
  taskId?: ?string,
  taskName?: ?string,
  projectId?: ?string,
|};
export type PersonDropdownQueryResponse = {|
  +viewer: ?{|
    +component: ?string,
    +company: ?{|
      +availableMLModels: ?{|
        +assigneeModel: ?boolean
      |}
    |},
    +suggestedPersons: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +active: ?boolean,
          +profilePictureId: ?string,
          +profilePictureDefaultId: ?number,
          +fullName: ?string,
          +role: ?{|
            +id: string,
            +name: ?string,
          |},
        |}
      |}>
    |},
  |}
|};
export type PersonDropdownQuery = {|
  variables: PersonDropdownQueryVariables,
  response: PersonDropdownQueryResponse,
|};
*/


/*
query PersonDropdownQuery(
  $taskId: ID
  $taskName: String
  $projectId: ID
) {
  viewer {
    component(name: "suggested_person_dropdown")
    company {
      availableMLModels {
        assigneeModel
      }
      id
    }
    suggestedPersons(first: 100000, taskId: $taskId, taskName: $taskName, projectId: $projectId) {
      edges {
        node {
          id
          active
          profilePictureId
          profilePictureDefaultId
          fullName
          role {
            id
            name
          }
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "taskId",
    "type": "ID"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "taskName",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId",
    "type": "ID"
  }
],
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "suggested_person_dropdown"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"suggested_person_dropdown\")"
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "AvailableMLModels",
  "kind": "LinkedField",
  "name": "availableMLModels",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "assigneeModel",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 100000
    },
    {
      "kind": "Variable",
      "name": "projectId",
      "variableName": "projectId"
    },
    {
      "kind": "Variable",
      "name": "taskId",
      "variableName": "taskId"
    },
    {
      "kind": "Variable",
      "name": "taskName",
      "variableName": "taskName"
    }
  ],
  "concreteType": "PersonTypeConnection",
  "kind": "LinkedField",
  "name": "suggestedPersons",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PersonTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Person",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "active",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "profilePictureId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "profilePictureDefaultId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fullName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Role",
              "kind": "LinkedField",
              "name": "role",
              "plural": false,
              "selections": [
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PersonDropdownQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PersonDropdownQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "PersonDropdownQuery",
    "operationKind": "query",
    "text": "query PersonDropdownQuery(\n  $taskId: ID\n  $taskName: String\n  $projectId: ID\n) {\n  viewer {\n    component(name: \"suggested_person_dropdown\")\n    company {\n      availableMLModels {\n        assigneeModel\n      }\n      id\n    }\n    suggestedPersons(first: 100000, taskId: $taskId, taskName: $taskName, projectId: $projectId) {\n      edges {\n        node {\n          id\n          active\n          profilePictureId\n          profilePictureDefaultId\n          fullName\n          role {\n            id\n            name\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3b5cf95db33b302277f3066d6ea6437e';

module.exports = node;
