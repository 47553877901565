/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type labelRow_label$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type labelCategoryRow_labels$ref: FragmentReference;
declare export opaque type labelCategoryRow_labels$fragmentType: labelCategoryRow_labels$ref;
export type labelCategoryRow_labels = $ReadOnlyArray<{|
  +id: string,
  +name: ?string,
  +taskCount: ?number,
  +projectCount: ?number,
  +peopleCount: ?number,
  +$fragmentRefs: labelRow_label$ref,
  +$refType: labelCategoryRow_labels$ref,
|}>;
export type labelCategoryRow_labels$data = labelCategoryRow_labels;
export type labelCategoryRow_labels$key = $ReadOnlyArray<{
  +$data?: labelCategoryRow_labels$data,
  +$fragmentRefs: labelCategoryRow_labels$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "labelCategoryRow_labels",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "taskCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "peopleCount",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "labelRow_label"
    }
  ],
  "type": "Label"
};
// prettier-ignore
(node/*: any*/).hash = '5fa515008c31079470fbe8e18f34c51a';

module.exports = node;
