import React, {useState} from 'react';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {createRefetchContainer, graphql} from 'react-relay';
import BaseLevelWrapper from './BaselevelWrapper';
import {GLOBAL_FILTER_FIELD, GLOBAL_FILTER_OPERATOR} from '../../../../../constants';
import GroupRow from '../rows/GroupRow';
import {useGenericReportContext} from '../../../saved-report/GenericReportContext';
import {DeprecatedProjectIndicatorNoFragment} from '../../../../shared/components/project-indicator/DeprecatedProjectIndicator';

const ProjectRelayWrapperV2 = ({
	relay,
	tableHeader,
	groupings,
	searchQuery,
	viewer,
	intl,
	rowIndex,
	enabledColumns,
	nextLevelProps,
	currency,
	customFieldColumnCount,
	customFieldKeys,
	pageSize,
}) => {
	const {reportId, sharedId, isSharedView} = useGenericReportContext();

	const projects = viewer.reportData.projects?.edges.filter(project => project.node.taskTotals.entities > 0);
	const hasNextPage = viewer.reportData.projects.pageInfo.hasNextPage && projects.length >= pageSize;
	const [modalTasksFetching, setModalTasksFetching] = useState(false);

	/**
	 * Grouping filter for tasks inside projects
	 *
	 * @param projectID The project ID
	 */
	const projectGroupingFilter = projectID => {
		return {
			field: GLOBAL_FILTER_FIELD.PROJECT,
			operator: GLOBAL_FILTER_OPERATOR.IS,
			value: [projectID],
		};
	};

	/**
	 * Return the project name
	 */
	const generatedProjectName = project => {
		return (
			<DeprecatedProjectIndicatorNoFragment
				project={project}
				disableLink={isSharedView || project.readOnlyAccess}
				showProjectName
				clearSubPath
			/>
		);
	};

	/**
	 * This will build the data rows, containing the project data, task totals and
	 * if the project is expandable.
	 */
	const dataRows = projects.map(project => {
		return {
			...project.node,
			generatedName: generatedProjectName(project.node),
			canExpand: true,
			currency,
		};
	});

	const data = {
		rows: dataRows,
		groupingFilter: rowData => projectGroupingFilter(rowData.projectId),
	};

	const fetchMore = () => {
		if (!modalTasksFetching) {
			const refetchObject = {};
			setModalTasksFetching(true);
			refetchObject.pageSize = projects.length + pageSize;
			relay.refetch(refetchObject, null, () => setModalTasksFetching(false));
		}
	};

	const projectRows = () =>
		GroupRow(
			data,
			intl,
			rowIndex,
			searchQuery,
			groupings,
			enabledColumns,
			hasNextPage,
			fetchMore,
			nextLevelProps,
			currency,
			reportId,
			sharedId,
			customFieldColumnCount,
			customFieldKeys
		);

	return rowIndex === 1 ? (
		<BaseLevelWrapper
			tableHeader={tableHeader}
			intl={intl}
			enabledColumns={enabledColumns}
			getRows={projectRows}
			currentLength={projects.length}
			hasNextPage={hasNextPage}
			refetch={relay.refetch}
			currency={currency}
			customFieldColumnCount={customFieldColumnCount}
			pageSize={pageSize}
		/>
	) : (
		projectRows()
	);
};

const ProjectRelayWrapperV2Query = graphql`
	query ProjectRelayWrapperV2_Query($searchQuery: TaskSearchQueryType, $pageSize: Int, $reportId: String, $sharedId: String) {
		viewer {
			actualPersonId
			component(name: "project_relay_wrapper_v2")
			...ProjectRelayWrapperV2_viewer
				@arguments(searchQuery: $searchQuery, pageSize: $pageSize, reportId: $reportId, sharedId: $sharedId)
		}
	}
`;
export {ProjectRelayWrapperV2Query};

export default injectIntl(
	withRouter(
		createRefetchContainer(
			ProjectRelayWrapperV2,
			{
				viewer: graphql`
					fragment ProjectRelayWrapperV2_viewer on Viewer
					@argumentDefinitions(
						searchQuery: {type: "TaskSearchQueryType"}
						pageSize: {type: "Int"}
						reportId: {type: "String"}
						sharedId: {type: "String"}
					) {
						reportData(id: $reportId, sharedId: $sharedId) {
							projects(first: $pageSize, searchQuery: $searchQuery)
								@connection(key: "TaskReport_projects", filters: ["searchQuery"]) {
								pageInfo {
									endCursor
									hasNextPage
								}
								edges {
									node {
										id
										projectId
										companyProjectId
										customProjectId
										name
										projectColor
										readOnlyAccess
										taskTotals {
											work {
												estimate {
													minutes
													points
												}
												remaining {
													minutes
													points
												}
											}
											reported {
												time
												points
												projected
											}
											financial {
												revenue {
													planned
													actual
												}
											}
											entities
										}
									}
								}
							}
						}
					}
				`,
			},
			graphql`
				query ProjectRelayWrapperV2RefetchQuery(
					$pageSize: Int
					$searchQuery: TaskSearchQueryType
					$reportId: String
					$sharedId: String
				) {
					viewer {
						component(name: "project_relay_wrapper_v2")
						...ProjectRelayWrapperV2_viewer
							@arguments(pageSize: $pageSize, searchQuery: $searchQuery, reportId: $reportId, sharedId: $sharedId)
					}
				}
			`
		)
	)
);
