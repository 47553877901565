import React, {Component} from 'react';
import PropTypes from 'prop-types';
import FavouriteIcon from '../../../../images/favourite_icon';
import {FormattedMessage} from 'react-intl';
import Util from '../../util/util';
import FavoriteTaskMutation from '../../../../mutations/favorite_task_mutation';
import FavoriteIdleTimeMutation from '../../../../mutations/favorite_idle_time_mutation';
import ForecastTooltip from '../tooltips/ForecastTooltip';

class StarTask extends Component {
	constructor(props) {
		super(props);
		this.state = {
			starHovered: false,
			timeLocked: false,
		};
	}

	setFavourite(e) {
		e.stopPropagation();
		e.preventDefault();

		if (this.state.timeLocked) {
			return;
		} else {
			this.setState({timeLocked: true});
		}

		const task = this.props.task;
		const taskId = task ? task.id : this.props.taskId;
		const favoured = task ? task.favoured : this.props.favoured;

		const onSuccess = result => {
			const timeout = 100;
			setTimeout(() => this.setState({timeLocked: false}), timeout);
		};

		this.props.isIdleTime
			? Util.CommitMutation(FavoriteIdleTimeMutation, {id: taskId, favoured: !favoured}, onSuccess)
			: Util.CommitMutation(
					FavoriteTaskMutation,
					{
						ids: [taskId],
						favoured: !favoured,
						viewerId: this.props.parentId,
						favoriteTaskList: this.props.favoriteTaskList,
					},
					onSuccess
			  );
	}

	toogleStarHovered(isHovered) {
		this.setState({
			starHovered: isHovered,
		});
	}

	render() {
		const {task, disabled} = this.props;
		const favoured = task ? task.favoured : this.props.favoured;

		const starStyle = {
			cursor: disabled ? 'inherit' : 'pointer',
			zIndex: 2,
		};

		return (
			<div className={'favourite-wrapper'} style={starStyle}>
				<ForecastTooltip
					content={
						this.props.disabled ? (
							favoured ? (
								<FormattedMessage id={'task.starred_task'} />
							) : null
						) : (
							<FormattedMessage id={favoured ? 'task.unstar_task' : 'task.star_task'} />
						)
					}
				>
					<div
						className={'favourite'}
						data-userpilot={this.props.userpilot}
						onMouseEnter={this.toogleStarHovered.bind(this, true)}
						onMouseLeave={this.toogleStarHovered.bind(this, false)}
					>
						<FavouriteIcon
							cy={'favourite-icon' + (this.props.cySource ? '-' + this.props.cySource : '')}
							className={'favourite-icon'}
							onClick={
								disabled || this.state.timeLocked || favoured === undefined
									? null
									: this.setFavourite.bind(this)
							}
							hovered={this.state.starHovered}
							active={favoured}
							disabled={disabled || favoured === undefined}
							customWidth={this.props.customSize ? this.props.customSize : null}
							customHeight={this.props.customSize ? this.props.customSize : null}
						/>
					</div>
				</ForecastTooltip>
			</div>
		);
	}
}

StarTask.propTypes = {
	task: PropTypes.shape({
		id: PropTypes.string.isRequired,
		favoured: PropTypes.bool,
	}),
	isIdleTime: PropTypes.bool,
	parentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), //viewerId if task
};

export default StarTask;
