import React from 'react';
import styled from 'styled-components';
import {createFragmentContainer, graphql} from 'react-relay';
import {DeprecatedText as Text} from '@forecast-it/design-system';
import {withSimpleSocketHandling} from '../../../../../../socket/withSimpleSocketHandling';
import {SOCKET_ACTION, SOCKET_EVENT_TYPE} from '../../../../../../constants';
import Util from '../../../../../shared/util/util';

const todoColor = '#DDDDE9';
const inProgressColor = '#CEAEFA';
const doneColor = '#6E0FEB';

const ProgressBarBackground = styled.div`
	display: flex;
	overflow: hidden;
	margin-top: 12px;
	height: 8px;
	width: 100%;
	border-radius: 4px;
	background-color: ${todoColor};
`;

const ProgressBarSegment = styled.div`
	width: ${props => props.width}%;
	background-color: ${props => props.color};
`;

const ProgressBarDetails = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
`;

const IndicatorTextWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
`;

const ProgressBarIndicator = styled.div`
	height: 8px;
	width: 8px;
	border-radius: 2px;
	background-color: ${props => props.color};
`;

const ProgramWorkBreakdown = ({viewer, projects, setSocketConfig}) => {
	const programId = Util.getUUIdFromBase64String(viewer.program.id);
	const projectsIds = projects.map(project => Util.getUUIdFromBase64String(project.id));
	const socketEvents = [
		{
			type: SOCKET_EVENT_TYPE.PROGRAM,
			action: SOCKET_ACTION.UPDATE,
			entityIds: programId,
		},
		{
			type: SOCKET_EVENT_TYPE.PROGRAM,
			action: SOCKET_ACTION.DELETE,
			entityIds: programId,
		},
		{
			type: SOCKET_EVENT_TYPE.V2_PROJECT,
			action: SOCKET_ACTION.UPDATE,
			entityIds: projectsIds,
		},
		{
			type: SOCKET_EVENT_TYPE.V2_PROJECT,
			action: SOCKET_ACTION.DELETE,
			entityIds: projectsIds,
		},
		{
			type: SOCKET_EVENT_TYPE.TASK,
			action: SOCKET_ACTION.UPDATE,
			projectIds: projectsIds,
		},
		{
			type: SOCKET_EVENT_TYPE.TASK,
			action: SOCKET_ACTION.CREATE,
			projectIds: projectsIds,
		},
		{
			type: SOCKET_EVENT_TYPE.TASK,
			action: SOCKET_ACTION.DELETE,
			projectIds: projectsIds,
		},
	];
	setSocketConfig(socketEvents);

	const {todoPercentage, inProgressPercentage, donePercentage} = viewer.program.projectTaskStatusBreakdown || {
		todoPercentage: 0,
		inProgressPercentage: 0,
		donePercentage: 0,
	};
	return (
		<>
			<ProgressBarBackground>
				<ProgressBarSegment width={donePercentage} color={doneColor} />
				<ProgressBarSegment width={inProgressPercentage} color={inProgressColor} />
			</ProgressBarBackground>
			<ProgressBarDetails data-cy={'program-work-breakdown'}>
				<IndicatorTextWrapper>
					<ProgressBarIndicator color={doneColor}></ProgressBarIndicator>
					<Text color={'secondary'}>{`Done ${donePercentage}%`}</Text>
				</IndicatorTextWrapper>
				<IndicatorTextWrapper>
					<ProgressBarIndicator color={inProgressColor}></ProgressBarIndicator>
					<Text color={'secondary'}>{`In Progress ${inProgressPercentage}%`}</Text>
				</IndicatorTextWrapper>
				<IndicatorTextWrapper>
					<ProgressBarIndicator color={todoColor}></ProgressBarIndicator>
					<Text color={'secondary'}>{`To-do ${todoPercentage}%`}</Text>
				</IndicatorTextWrapper>
			</ProgressBarDetails>
		</>
	);
};

const ProgramWorkBreakdownQuery = graphql`
	query ProgramWorkBreakdown_Query($prefix: String) {
		viewer {
			actualPersonId
			component(name: "program_work_breakdown")
			...ProgramWorkBreakdown_viewer @arguments(prefix: $prefix)
		}
	}
`;

export {ProgramWorkBreakdownQuery};

export default withSimpleSocketHandling(
	createFragmentContainer(ProgramWorkBreakdown, {
		viewer: graphql`
			fragment ProgramWorkBreakdown_viewer on Viewer @argumentDefinitions(prefix: {type: "String"}) {
				id
				program(prefix: $prefix) {
					id
					projectTaskStatusBreakdown {
						todoPercentage
						inProgressPercentage
						donePercentage
					}
				}
			}
		`,
	})
);
