import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import Util from '../../util/util';
import UpdateTimeRegistration from '../../../../mutations/update_time_registration_mutation.modern';
import Moment from 'moment';
import CustomScrollDiv from '../scroll-bars/custom_scroll_div';
import DropdownV2 from '../dropdowns/dropdown';

class Unit4PopOut extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: false,
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.loadOnExpand && this.state.expanded === true && this.state.expanded !== prevState.expanded) {
			this.props.updateUnit4Activities();
		}
	}

	handleBlur(e) {
		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11
		if (this._container && this._container.contains(newTarget)) {
			return;
		} else this.toggleExpanded(e, false);
	}

	toggleExpanded(e, btn) {
		const noteHeight = 70;
		const marginsAndHeader = 66;
		const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
		const y = e.clientY ? e.clientY : 0;
		const numRenderedItems = this.props.timeRegistrations ? Math.min(this.props.timeRegistrations.length, 4) : 0;
		const popOutSize = numRenderedItems * noteHeight + marginsAndHeader + (numRenderedItems === 2.5 ? 4 : 0);
		const isCloseToEdge = h - 60 - y <= popOutSize;
		const wouldFoldOverTop = y - popOutSize - 50 <= 0;
		if (isCloseToEdge && !wouldFoldOverTop) {
			this.setState({foldUp: true});
		} else {
			this.setState({foldUp: false});
		}

		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement;
		e.stopPropagation();

		if (btn) {
			//click on the button
			this.setState(prevState => {
				return {expanded: !prevState.expanded};
			});
		} else if (newTarget.className === 'notes-pop-out' || (this.notes_pop_out && this.notes_pop_out.contains(newTarget))) {
			//click inside the pop-out
			this.setState({
				expanded: true,
			});
		} else {
			//click somewhere else
			this.setState({
				expanded: false,
			});
		}
	}

	updateUnit4Activity(timeReg, selectedUnit4Activity) {
		if (!selectedUnit4Activity && timeReg.project.id) {
			this.props.updateUnit4Activities(timeReg.project.id);
		} else if (
			!timeReg.unit4Activity ||
			(timeReg.unit4Activity && timeReg.unit4Activity.id !== selectedUnit4Activity.value)
		) {
			Util.CommitMutation(UpdateTimeRegistration, {
				id: timeReg.id,
				unit4ActivityId: selectedUnit4Activity.value,
				unit4ActivityName: selectedUnit4Activity.label,
			});
		}
	}

	onDropdownBlur() {
		if (this._container) {
			this._container.focus();
		}
	}

	render() {
		const timeRegistrations = this.props.timeRegistrations;
		const {formatMessage, formatDate} = this.props.intl;
		const noteHeight = 70;
		const marginsAndHeader = 66;
		// Render 4 unit4 activities max, then we scroll
		const numRenderedItems = timeRegistrations ? Math.min(timeRegistrations.length, 4) : 0;
		const notesPopOutSize = numRenderedItems * noteHeight + marginsAndHeader + (numRenderedItems === 4 ? 4 : 0);

		if (timeRegistrations) {
			timeRegistrations.sort(function (a, b) {
				//sort notes by date
				if (a.year !== b.year) return a.year - b.year;
				if (a.month !== b.month) return a.month - b.month;
				return a.day - b.day;
			});
		}
		const unit4ActivityOptions = [];
		this.props.unit4Activities.forEach(option => {
			unit4ActivityOptions.push({
				value: option.id,
				label: option.name,
			});
		});

		return (
			<div
				className={'unit4-pop-out' + (this.state.foldUp ? ' fold-up' : '')}
				tabIndex={'0'}
				ref={div => (this._container = div)}
				onBlur={this.handleBlur.bind(this)}
			>
				<div
					ref={div => (this.notes_section = div)}
					onClick={e => (!this.props.disabled ? this.toggleExpanded(e, true) : {})}
					className={
						'unit4-button' + (!this.props.disabled ? ' has-notes' : '') + (this.state.expanded ? ' expanded' : '')
					}
				/>
				{this.state.expanded ? (
					timeRegistrations ? (
						<div
							className={'notes-pop-out' + (this.state.foldUp ? ' fold-up' : '')}
							style={this.state.foldUp ? {top: -1 * notesPopOutSize} : {}}
							ref={div => (this.notes_pop_out = div)}
						>
							{this.props.loadOnExpand && this.props.integrationLoading ? (
								<div className={'unit4-loading'}>
									<li className={'loading-box'}>
										<div className={'loading-text'}>{formatMessage({id: 'common.loading'})}</div>
										<div className={'loading-item'} />
									</li>
								</div>
							) : (
								<>
									<div className={'notes-header'}>
										<div className={'notes-header-title'}>
											{' '}
											{formatMessage({id: 'overview_time.unit4_activities_for'})}
										</div>
										<div className={'task-name'}> {this.props.taskName}</div>
									</div>
									<CustomScrollDiv autoHeight={true} autoHeightMin={1} autoHeightMax={320}>
										<div className="notes-container">
											{timeRegistrations.map((timeRegistration, index) => {
												return (
													<div key={'note-' + index} className="note">
														<div className="note-header">
															<div className="note-date">
																{formatDate(
																	Moment({
																		year: timeRegistration.year,
																		month: timeRegistration.month - 1,
																		date: timeRegistration.day,
																	}),
																	{
																		weekday: 'long',
																		month: 'numeric',
																		day: 'numeric',
																	}
																)}
															</div>
															<div
																className="note-hours"
																title={
																	timeRegistration.invoiced
																		? formatMessage({id: 'invoice.cannot_edit_time_reg'})
																		: ''
																}
															>
																{'(' +
																	(!timeRegistration.invoiced
																		? Util.convertMinutesToFullHour(
																				timeRegistration.minutesRegistered,
																				this.props.intl
																		  )
																		: formatMessage(
																				{id: 'invoice.invoiced_hours'},
																				{
																					amount: timeRegistration.minutesRegistered,
																					formatted: Util.convertMinutesToFullHour(
																						timeRegistration.minutesRegistered,
																						this.props.intl
																					),
																				}
																		  )) +
																	')'}
															</div>
														</div>
														<div tabIndex="0" className="note-content">
															<DropdownV2
																onBlur={this.onDropdownBlur.bind(this)}
																value={timeRegistration.unit4ActivityId}
																options={unit4ActivityOptions}
																onChange={val =>
																	this.updateUnit4Activity(timeRegistration, val)
																}
																hideLabel={true}
																placeholder={formatMessage({
																	id: 'integrations.unit4_activity_placeholder',
																})}
																disabled={this.props.disabled}
																customHeight={30}
																onClick={
																	this.props.loadOnExpand
																		? void 0
																		: this.props.updateUnit4Activities.bind(this)
																}
																integrationDropdown={true}
																integrationLoading={this.props.integrationLoading}
																integrationRetryToFetch={this.props.updateUnit4Activities.bind(
																	this
																)}
															/>
														</div>
													</div>
												);
											})}
										</div>
									</CustomScrollDiv>
								</>
							)}
						</div>
					) : null
				) : null}
			</div>
		);
	}
}
Unit4PopOut.propTypes = {
	timeRegItem: PropTypes.object.isRequired,
	timeRegistrations: PropTypes.array,
	unit4Activities: PropTypes.array,
	integrationLoading: PropTypes.bool,
	updateUnit4Activities: PropTypes.func.isRequired,
};
export default injectIntl(Unit4PopOut);
