/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type genericTaskContextMenu_company$ref: FragmentReference;
declare export opaque type genericTaskContextMenu_company$fragmentType: genericTaskContextMenu_company$ref;
export type genericTaskContextMenu_company = {|
  +id: string,
  +jiraCloudEnabled: ?boolean,
  +jiraServerEnabled: ?boolean,
  +integrations: ?{|
    +jiraCloud: ?{|
      +syncSettings: ?{|
        +isJiraToForecastOneWaySync: ?boolean
      |}
    |},
    +jiraServer: ?{|
      +syncSettings: ?{|
        +isJiraToForecastOneWaySync: ?boolean
      |}
    |},
  |},
  +$refType: genericTaskContextMenu_company$ref,
|};
export type genericTaskContextMenu_company$data = genericTaskContextMenu_company;
export type genericTaskContextMenu_company$key = {
  +$data?: genericTaskContextMenu_company$data,
  +$fragmentRefs: genericTaskContextMenu_company$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "JiraSyncSettingsType",
    "kind": "LinkedField",
    "name": "syncSettings",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isJiraToForecastOneWaySync",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "genericTaskContextMenu_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jiraCloudEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jiraServerEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CompanyIntegrationsType",
      "kind": "LinkedField",
      "name": "integrations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CompanyJiraSettingsType",
          "kind": "LinkedField",
          "name": "jiraCloud",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CompanyJiraSettingsType",
          "kind": "LinkedField",
          "name": "jiraServer",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Company"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e983c3b19f620d889db701d1169384f3';

module.exports = node;
