import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { ExpandableIcon } from 'web-components';
import { BUDGET_TYPE, PERIOD_BUDGET_TYPE } from '../../../../../constants';
import { isBillableSplitAllowed } from '../../../../shared/util/cache/TimeRegistrationSettingsUtil';
import { hasFeatureFlag } from '../../../../shared/util/FeatureUtil';
import { isClientUser, showRevenue as viewerHasRevenuePermission } from '../../../../shared/util/PermissionsUtil';
import ProjectUtil from '../../../../shared/util/project_util';
import { Element, GridContainer, Header, IconWrapper, Row, TotalsBox } from './ProjectScopingTotals_styled';
import { useProjectTotals } from './ProjectScopingTotalsContext';
import { AvailabilityTile } from './totals-header-box-tiles/AvailabilityTile';
import { DatesAndRevenueTile } from './totals-header-box-tiles/DatesAndRevenueTile';
import { EstimateAndTimeTile } from './totals-header-box-tiles/EstimateAndTimeTile';
import { RemainingAndBaselineTile } from './totals-header-box-tiles/RemainingAndBaselineTile';
import { TileVariant } from './totals-header-box-tiles/TotalsTypes';
import { FixedPriceLeftTile } from './totals-header-box-tiles/FixedPriceLeftTile';
import { FixedPriceMiddleTile } from './totals-header-box-tiles/FixedPriceMiddleTile';
import { FixedPriceRightTile } from './totals-header-box-tiles/FixedPriceRightTile';
const HeaderTotalsGrid = styled.div `
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));
	grid-gap: 24px 48px;
`;
// Attempt to fit the availability tile into the secondary row of one of the other tiles instead of showing it as its own standalone tile if there is space for it.
const getAvailabilityTile = (showRevenue, showBillableSplit, showBaseline, showAvailabilityOnScoping) => {
    if (!showAvailabilityOnScoping)
        return null;
    // If none of the other secondary rows are shown, show as standalone column
    if (!showRevenue && !showBillableSplit && !showBaseline)
        return TileVariant.Availability;
    if (!showRevenue)
        return TileVariant.DatesAndRevenue;
    if (!showBillableSplit)
        return TileVariant.EstimateAndTime;
    if (!showBaseline)
        return TileVariant.RemainingAndBaseline;
    return TileVariant.Availability;
};
/**
 * Create the project scoping header section in a big box (Expanded)
 *
 * @returns JSX Project Scoping Totals Box Component
 */
const ProjectScopingTotalsBox = () => {
    const intl = useIntl();
    const { totalsExpanded, toggleTotalsExpanded, totalsData, settings, projectData } = useProjectTotals();
    const hasFinancialServiceInScoping = hasFeatureFlag('use_financial_service_in_scoping');
    const projectTracksRevenue = ProjectUtil.projectTracksRevenue(projectData);
    const isNonBillable = !projectTracksRevenue;
    const showRevenue = projectTracksRevenue && viewerHasRevenuePermission();
    const isFixedPriceV2 = projectTracksRevenue &&
        (projectData.budgetType === BUDGET_TYPE.FIXED_PRICE_V2 ||
            (projectData.budgetType === BUDGET_TYPE.RETAINER &&
                projectData.defaultPeriodBudgetType === PERIOD_BUDGET_TYPE.FIXED_PRICE));
    const showBillableSplit = !isNonBillable && isBillableSplitAllowed();
    const showBaseline = settings.isBaselineProject;
    const showAvailabilityOnScoping = settings.isUsingProjectAllocation && !isClientUser();
    const availabilityTile = getAvailabilityTile(showRevenue, showBillableSplit, showBaseline, showAvailabilityOnScoping);
    return (React.createElement(GridContainer, { "data-userpilot": "project-totals-header" },
        React.createElement(TotalsBox, { key: "dates-revenue-box", "data-cy": "project-totals-header" },
            React.createElement(Row, { bottomMargin: 16 },
                React.createElement(Element, { split: true },
                    React.createElement(Header, null, "Project totals")),
                React.createElement(Element, { alignRight: true },
                    React.createElement(IconWrapper, null,
                        React.createElement(ExpandableIcon, { isOpen: totalsExpanded, onClick: toggleTotalsExpanded, cy: 'collapse-project-totals' })))),
            React.createElement(HeaderTotalsGrid, null, hasFeatureFlag('use_financial_service_in_scoping') &&
                hasFeatureFlag('financial_categories_update') &&
                isFixedPriceV2 ? (React.createElement(React.Fragment, null,
                React.createElement(FixedPriceLeftTile, { totalsData: totalsData, projectData: projectData, settings: settings, intl: intl }),
                React.createElement(FixedPriceMiddleTile, { totalsData: totalsData, settings: settings, intl: intl }),
                React.createElement(FixedPriceRightTile, { totalsData: totalsData, projectData: projectData, settings: settings, intl: intl }))) : (React.createElement(React.Fragment, null,
                React.createElement(DatesAndRevenueTile, { totalsData: totalsData, projectData: projectData, showRevenue: showRevenue, isFixedPriceV2: isFixedPriceV2, hasFinancialServiceInScoping: hasFinancialServiceInScoping, settings: settings, availabilityTile: availabilityTile, intl: intl }),
                React.createElement(EstimateAndTimeTile, { totalsData: totalsData, projectData: projectData, showBillableSplit: showBillableSplit, settings: settings, availabilityTile: availabilityTile, intl: intl }),
                React.createElement(RemainingAndBaselineTile, { totalsData: totalsData, projectData: projectData, showRevenue: showRevenue, showBaseline: showBaseline, settings: settings, availabilityTile: availabilityTile, intl: intl }),
                availabilityTile === TileVariant.Availability && (React.createElement(AvailabilityTile, { totalsData: totalsData, intl: intl }))))))));
};
export default ProjectScopingTotalsBox;
