import React, { useMemo, useState } from 'react';
import { Button, FlexColumn, Table, TableBar, Text } from '@forecast-it/design-system';
import Styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { createFragmentContainer, graphql } from 'react-relay';
import { MODAL_TYPE, showModal } from '../../shared/components/modals/generic_modal_conductor';
import { createToast } from '../../shared/components/toasts/another-toast/toaster';
import Util from '../../shared/util/util';
import CreateProjectPersonClientMutation from '../../../mutations/create_project_person_client_mutation';
import DeleteProjectPersonMutation from '../../../mutations/delete_project_person_mutation';
import DeletePersonMutation from '../../../mutations/delete_person_mutation';
import SettingsClientHeader from './SettingsClientHeader';
import CustomScrollDiv from '../../shared/components/scroll-bars/custom_scroll_div';
const PageWrapper = Styled.div `
	padding: 25px;
`;
const TableBarWrapper = Styled.div `
	margin: 32px 0 24px;
`;
const DescriptionWrapper = Styled(FlexColumn) `
	margin: 8px 0 24px;
`;
const SettingsClientGuestUsers = ({ viewer }) => {
    const history = useHistory();
    const { formatMessage } = useIntl();
    const { client } = viewer.company || {};
    const clientId = client === null || client === void 0 ? void 0 : client.id;
    const [disabledClickCheckboxes, setDisabledClickCheckboxes] = useState([]);
    const personIdToProjectsMap = useMemo(() => {
        var _a;
        const map = new Map();
        if ((_a = viewer.projects) === null || _a === void 0 ? void 0 : _a.edges) {
            viewer.projects.edges.forEach(project => {
                var _a;
                const { id: projectId, name: projectName, projectPersons } = (project === null || project === void 0 ? void 0 : project.node) || {};
                if (projectId && projectName && projectPersons) {
                    (_a = projectPersons.edges) === null || _a === void 0 ? void 0 : _a.forEach(pp => {
                        const { id: personProjectId, person } = (pp === null || pp === void 0 ? void 0 : pp.node) || {};
                        if (personProjectId && person) {
                            let personProjects = [];
                            if (map.has(person.id)) {
                                personProjects = map.get(person.id) || [];
                            }
                            personProjects.push({
                                personProjectId,
                                projectId,
                                projectName,
                            });
                            map.set(person.id, personProjects);
                        }
                    });
                }
            });
        }
        return map;
    }, [viewer.projects]);
    const clientUsers = useMemo(() => {
        var _a;
        return (((_a = client === null || client === void 0 ? void 0 : client.clientUsers) === null || _a === void 0 ? void 0 : _a.edges) || []).map(user => {
            if (!user)
                return null;
            const { node } = user;
            const fullName = (node === null || node === void 0 ? void 0 : node.firstName) ? `${node.firstName} ${(node === null || node === void 0 ? void 0 : node.lastName) || ''}` : '';
            let projects = [];
            let projectsDisplayLabel = '';
            if ((node === null || node === void 0 ? void 0 : node.id) && personIdToProjectsMap.has(node.id)) {
                const personProjects = personIdToProjectsMap.get(node.id) || [];
                projects = personProjects;
                projectsDisplayLabel = personProjects.map((p) => p.projectName).join(', ');
            }
            return Object.assign(Object.assign({}, node), { fullName,
                projects,
                projectsDisplayLabel });
        });
    }, [client, personIdToProjectsMap]);
    const onClickAddGuestUser = () => {
        var _a, _b, _c;
        const project_options = ((_b = (_a = viewer.projects) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.filter(p => { var _a, _b; return ((_b = (_a = p === null || p === void 0 ? void 0 : p.node) === null || _a === void 0 ? void 0 : _a.client) === null || _b === void 0 ? void 0 : _b.id) === clientId; }).map(p => {
            var _a, _b;
            return ({
                value: (_a = p === null || p === void 0 ? void 0 : p.node) === null || _a === void 0 ? void 0 : _a.id,
                label: (_b = p === null || p === void 0 ? void 0 : p.node) === null || _b === void 0 ? void 0 : _b.name,
            });
        })) || null;
        showModal({
            type: MODAL_TYPE.ADD_CLIENT_USER,
            addClientUser: true,
            project_options: project_options,
            companyId: (_c = viewer.company) === null || _c === void 0 ? void 0 : _c.id,
            clientId: clientId,
        });
    };
    const onClickEditGuestUser = row => {
        history.push('/admin/team/view-user/' + row.id);
    };
    const onClickDeleteGuestUser = row => {
        const onSuccess = () => {
            createToast({ duration: 5000, message: formatMessage({ id: 'settings.client-user_deleted' }) });
        };
        const callbackPositive = () => {
            Util.CommitMutation(DeletePersonMutation, {
                id: row.id,
                parentId: clientId,
            }, onSuccess);
        };
        showModal({
            type: MODAL_TYPE.GENERIC_DELETION_WARNING,
            deleteCallback: callbackPositive,
        });
    };
    const projectOptions = useMemo(() => {
        var _a, _b;
        return (((_b = (_a = viewer.projects) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.map(edge => {
            var _a, _b;
            return ({
                id: (_a = edge === null || edge === void 0 ? void 0 : edge.node) === null || _a === void 0 ? void 0 : _a.id,
                name: (_b = edge === null || edge === void 0 ? void 0 : edge.node) === null || _b === void 0 ? void 0 : _b.name,
            });
        })) || []);
    }, [viewer.projects]);
    const onChangeProjectPerson = (projectId, personId, projects) => {
        var _a;
        const onSuccess = (projectId, personId) => {
            const newDisabledClickCheckboxes = [...disabledClickCheckboxes].filter(c => c.personId !== personId && c.projectId !== projectId);
            setDisabledClickCheckboxes(newDisabledClickCheckboxes);
        };
        if (!projectId)
            return;
        setDisabledClickCheckboxes(old => [...old, { projectId, personId }]);
        if (disabledClickCheckboxes.find(c => c.projectId === projectId && c.personId === personId))
            return;
        const projectIds = projects.map(p => p.projectId);
        if (projectIds.includes(projectId)) {
            const personProjectId = (_a = projects.find(p => p.projectId === projectId)) === null || _a === void 0 ? void 0 : _a.personProjectId;
            if (personProjectId) {
                Util.CommitMutation(DeleteProjectPersonMutation, {
                    ids: [personProjectId],
                    projectId: projectId,
                }, () => onSuccess(projectId, personId));
            }
        }
        else {
            Util.CommitMutation(CreateProjectPersonClientMutation, {
                personId: personId,
                projectId: projectId,
            }, () => onSuccess(projectId, personId));
        }
    };
    return (React.createElement(CustomScrollDiv, null,
        React.createElement(PageWrapper, null,
            clientId ? (React.createElement(SettingsClientHeader, { clientId: clientId, clientName: client === null || client === void 0 ? void 0 : client.name, clientLogoDefaultId: client === null || client === void 0 ? void 0 : client.logoDefaultId, clientLogoId: client === null || client === void 0 ? void 0 : client.logoId })) : null,
            React.createElement(TableBarWrapper, null,
                React.createElement(TableBar, { title: formatMessage({ id: 'settings.client-user' }) },
                    React.createElement(Button, { emphasis: 'low', onClick: onClickAddGuestUser },
                        React.createElement(FormattedMessage, { id: "settings_clients.add-client-user" })))),
            React.createElement(DescriptionWrapper, null,
                React.createElement(Text, null, formatMessage({ id: 'settings.client-contacts-description' })),
                React.createElement(Text, null, formatMessage({ id: 'settings.client-contacts-sub-description' }))),
            React.createElement(Table, { data: clientUsers, width: '100%' },
                React.createElement(Table.TextColumn, { accessorKey: 'fullName', header: formatMessage({ id: 'common.name' }), allowSorting: true }),
                React.createElement(Table.TextColumn, { accessorKey: 'email', header: formatMessage({ id: 'common.email' }), allowSorting: true }),
                React.createElement(Table.TextColumn, { accessorKey: 'phone', header: formatMessage({ id: 'common.phone' }), allowSorting: true }),
                React.createElement(Table.DropdownColumn, { header: formatMessage({ id: 'common.projects' }), label: row => row.projectsDisplayLabel, defaultLabel: formatMessage({ id: 'settings.client-user-no-assigned-projects' }), width: '250px', keepOpenStateOnChange: true }, projectOptions.map(p => (React.createElement(Table.DropdownColumn.CheckboxItem, { key: p.id, checked: row => row.projects.map(p => p.projectId).includes(p.id), onChange: row => onChangeProjectPerson(p.id, row.id, row.projects) },
                    React.createElement(Text, { size: "2" }, p.name))))),
                React.createElement(Table.ActionColumn, { header: formatMessage({ id: 'common.actions' }), hideHeader: true },
                    React.createElement(Table.ActionColumn.Option, { onClick: onClickEditGuestUser },
                        React.createElement(FormattedMessage, { id: 'common.edit' })),
                    React.createElement(Table.ActionColumn.Option, { onClick: onClickDeleteGuestUser },
                        React.createElement(FormattedMessage, { id: 'common.delete' })))))));
};
export const SettingsClientGuestUsersQuery = graphql `
	query SettingsClientGuestUsers_Query($clientId: String) {
		viewer {
			actualPersonId
			component(name: "settings_client")
			...SettingsClientGuestUsers_viewer @arguments(clientId: $clientId)
		}
	}
`;
export default createFragmentContainer(SettingsClientGuestUsers, {
    viewer: graphql `
		fragment SettingsClientGuestUsers_viewer on Viewer @argumentDefinitions(clientId: {type: "String"}) {
			id
			availableFeatureFlags {
				key
			}
			company {
				id
				client(id: $clientId) {
					id
					name
					street
					zip
					city
					vat
					country
					notes
					logoId
					logoDefaultId
					clientUsers(first: 1000) @connection(key: "Client_clientUsers", filters: []) {
						edges {
							node {
								id
								firstName
								lastName
								email
								phone
							}
						}
					}
				}
			}
			projects(first: 100000) @connection(key: "Viewer_projects", filters: []) {
				edges {
					node {
						id
						name
						client {
							id
						}
						projectPersons(first: 1000, onlyClientUsers: true)
							@connection(key: "Project_projectPersons", filters: []) {
							edges {
								node {
									id
									person {
										id
									}
								}
							}
						}
					}
				}
			}
		}
	`,
});
