/**
 * A class used to store two different values for a task.
 * One called value which can be any value of a task (estimate, price, etc.)
 * And another called approvedValue which holds the same values, but disregards values on unapproved tasks.
 */
export class RollUpValue {
	constructor(task, value) {
		this.task = task;

		if (task.node.approved) {
			this.approvedValue = value;
		} else {
			this.approvedValue = 0;
		}

		this.value = value;
	}

	addValue(rollUpValue) {
		this.approvedValue += rollUpValue.approvedValue;
		this.value += rollUpValue.value;
	}

	getApprovedValue() {
		return this.approvedValue;
	}

	getValue() {
		return this.value;
	}
}
