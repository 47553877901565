import React, {Component} from 'react';

class warningIcon extends Component {
	render() {
		const width = this.props.customWidth ? this.props.customWidth : '16';
		const height = this.props.customHeight ? this.props.customHeight : '16';

		return (
			<span className={this.props.className ? this.props.className + '-title' : ''} title={this.props.title} data-cy={this.props.cy}>
				<svg
					className={this.props.className ? this.props.className : ''}
					onMouseEnter={this.props.handleHover ? this.props.handleHover : null}
					onMouseLeave={this.props.handleHover ? this.props.handleHover : null}
					xmlns="http://www.w3.org/2000/svg"
					width={width}
					height={height}
					viewBox="0 0 16 16"
				>
					<path fill={this.props.danger ? '#e01212' /*red*/ : '#e0c82f' /*yellow*/} fillRule="evenodd" d="M8.566.235a.8.8 0 0 0-1.131 0l-7.2 7.2a.8.8 0 0 0 0 1.13l7.2 7.2a.798.798 0 0 0 1.13 0l7.2-7.2a.8.8 0 0 0 0-1.13l-7.2-7.2z"></path>
					<path fill="#FFF" fillRule="nonzero" d="M8 12.2a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
					<path fill="#FFF" fillRule="nonzero" d="M7.2 9.188h1.6L9.6 4.8H6.4z" />
				</svg>
			</span>
		);
	}
}

export default warningIcon;
