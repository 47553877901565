/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type TimeRegistrationSettingsSection_company$ref: FragmentReference;
declare export opaque type TimeRegistrationSettingsSection_company$fragmentType: TimeRegistrationSettingsSection_company$ref;
export type TimeRegistrationSettingsSection_company = {|
  +timeRegistrationSettings: ?{|
    +id: string,
    +allowDoneTasks: ?boolean,
    +allowProjects: ?boolean,
    +allowInternalTime: ?boolean,
    +allowBillableSplit: ?boolean,
    +allowRoles: ?boolean,
    +allowExceedingEstimate: ?boolean,
    +allowOutsideProjectDates: ?boolean,
    +allowOutsideTaskDates: ?boolean,
    +requireTaskAssignment: ?boolean,
    +requireNote: ?boolean,
  |},
  +$refType: TimeRegistrationSettingsSection_company$ref,
|};
export type TimeRegistrationSettingsSection_company$data = TimeRegistrationSettingsSection_company;
export type TimeRegistrationSettingsSection_company$key = {
  +$data?: TimeRegistrationSettingsSection_company$data,
  +$fragmentRefs: TimeRegistrationSettingsSection_company$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TimeRegistrationSettingsSection_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TimeRegistrationSettingsType",
      "kind": "LinkedField",
      "name": "timeRegistrationSettings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allowDoneTasks",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allowProjects",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allowInternalTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allowBillableSplit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allowRoles",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allowExceedingEstimate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allowOutsideProjectDates",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allowOutsideTaskDates",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "requireTaskAssignment",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "requireNote",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Company"
};
// prettier-ignore
(node/*: any*/).hash = '8839e1fb3df730660b68c4d7c745e3ce';

module.exports = node;
