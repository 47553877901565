import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import InputField from '../../../components/new-ui/input_field';
import GenericModal, {MODAL_WIDTH} from '../../../containers/modal/generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import {createToast} from '../../shared/components/toasts/another-toast/toaster';
import CreateCustomFieldDefinitionMutation from '../../../mutations/create_custom_field_definition_mutation.js';
import UpdateCustomFieldDefinitionMutation from '../../../mutations/update_custom_field_definition_mutation.js';
import Util from '../../shared/util/util';
import {createFragmentContainer, graphql} from 'react-relay';
import {Field, ModalBody} from '../../shared/components/modals/Modal.styled';
import {Checkbox, InputLabel} from 'web-components';

const CustomFieldDefinitionModal = ({company, customFieldDefinitionId, entityType, closeModal}) => {
	const {formatMessage} = useIntl();
	const isUpdating = !!customFieldDefinitionId;
	const customFieldDefinitions = company.customFieldDefinitions.edges
		.map(edge => edge.node)
		.filter(cf => cf.entityType === entityType);
	const customFieldDefinition = isUpdating ? customFieldDefinitions.find(cf => cf.id === customFieldDefinitionId) : {};
	if (isUpdating && !customFieldDefinition) {
		return null;
	}
	const [key, setKey] = useState(customFieldDefinition.key);
	const [displayName, setDisplayName] = useState(customFieldDefinition.displayName || '');
	const [readOnly, setReadOnly] = useState(customFieldDefinition.readOnly);
	const existingKeys = new Set(
		customFieldDefinitions.filter(cf => !isUpdating || cf.id !== customFieldDefinitionId).map(cf => cf.key)
	);
	const [errorMessage, setErrorMessage] = useState('');

	const onKeyChange = newKey => {
		newKey = newKey.replace(/[^A-Za-z0-9-_]/g, '');
		setKey(newKey);
		const duplicateName = existingKeys.has(newKey);
		if (duplicateName) {
			setErrorMessage(formatMessage({id: 'settings.custom-fields.already_exists'}));
		} else {
			setErrorMessage(null);
		}
	};

	const create = () => {
		Util.CommitMutation(
			CreateCustomFieldDefinitionMutation,
			{
				key,
				displayName,
				readOnly,
				entityType,
				companyId: company.id,
			},
			() => {
				createToast({
					duration: 5000,
					message: formatMessage({id: 'settings.custom-fields.create_toast'}, {name: displayName}),
				});
			}
		);
	};

	const update = () => {
		Util.CommitMutation(
			UpdateCustomFieldDefinitionMutation,
			{
				id: customFieldDefinitionId,
				displayName,
				readOnly,
			},
			() => {
				createToast({
					duration: 5000,
					message: formatMessage({id: 'settings.custom-fields.update_toast'}, {name: displayName}),
				});
			}
		);
	};

	return (
		<GenericModal
			closeModal={closeModal}
			modalWidth={MODAL_WIDTH.SMALL}
			headerText={formatMessage({id: isUpdating ? 'settings.custom-fields.update' : 'settings.custom-fields.create'})}
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
					cy: 'button-cancel',
				},
				{
					text: formatMessage({id: isUpdating ? 'common.update' : 'common.create'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: isUpdating ? update : create,
					disabled: !key || !displayName || !!errorMessage,
					cy: 'button-save',
				},
			]}
			content={
				<ModalBody>
					<Field>
						<InputField
							value={formatMessage({id: 'settings.custom-fields.entity-type.' + entityType})}
							cy={'custom-field-entity-type'}
							label={formatMessage({id: 'settings.custom-fields.entityType'})}
							type="text"
							locked
						/>
					</Field>
					<Field>
						<InputField
							value={key}
							cy={'custom-field-key'}
							label={formatMessage({id: 'settings.custom-fields.key'})}
							type="text"
							onChange={onKeyChange}
							errorMessage={errorMessage}
							invalidInput={!!errorMessage}
							required={true}
							maxLength={191}
							autoFocus={true}
							locked={isUpdating}
						/>
					</Field>
					<Field>
						<InputField
							value={displayName}
							cy={'custom-field-display-name'}
							label={formatMessage({id: 'settings.custom-fields.displayName'})}
							type="text"
							onChange={setDisplayName}
							required={true}
							maxLength={191}
						/>
					</Field>
					<Field>
						<InputLabel
							text={formatMessage({id: 'settings.custom-fields.readOnly'})}
							child={
								<div>
									<Checkbox
										checked={readOnly}
										onChange={e => {
											setReadOnly(e.target.checked);
										}}
									/>
								</div>
							}
						/>
					</Field>
				</ModalBody>
			}
		/>
	);
};

const CustomFieldDefinitionModalQuery = graphql`
	query CustomFieldDefinitionModalQuery {
		viewer {
			actualPersonId
			component(name: "custom_field_definition_modal")
			company {
				...CustomFieldDefinitionModal_company
			}
		}
	}
`;

export {CustomFieldDefinitionModalQuery};

export default createFragmentContainer(CustomFieldDefinitionModal, {
	company: graphql`
		fragment CustomFieldDefinitionModal_company on Company {
			id
			customFieldDefinitions(first: 1000) @connection(key: "Company_customFieldDefinitions") {
				edges {
					node {
						id
						key
						displayName
						readOnly
						entityType
					}
				}
			}
		}
	`,
});
