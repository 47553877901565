import React, {useEffect} from 'react';
import {
	DeprecatedFavorite as Favorite,
	FlexColumn,
	FlexRow,
	DeprecatedHeaderContainer as HeaderContainer,
	DeprecatedMultiColorIndicator as MultiColorIndicator,
	DeprecatedProgressBar as ProgressBar,
	DeprecatedText as Text,
} from '@forecast-it/design-system';
import hash from 'object-hash';
import {Link} from 'react-router-dom';
import {useProgram} from '../hooks/useProgram';
import {useProgramCalculatedFields} from '../hooks/useProgramCalculatedFields';
import StatusWrapper from '../../../shared/components/status/StatusWrapper';
import {FeedbackButton} from '../../../shared/components/buttons/feedback-button';
import {hasModule} from '../../../shared/util/ModuleUtil';
import {MODULE_TYPES} from '../../../../constants';

const ProgramOverviewHeader = ({program, subMenu, companyId}) => {
	const _program = useProgram(program, companyId);

	const {programCalcData, reloadData} = useProgramCalculatedFields(program.id);

	useEffect(() => {
		reloadData();
	}, [hash(program)]);

	const getProgress = () => programCalcData(program.id)?.progress;

	return (
		<HeaderContainer data-cy={'program_header'}>
			<FlexRow alignItems={'space-between'} gap={'s'}>
				<FlexRow alignItems={'flex-start'} gap={'l'}>
					<Link to={'/programs'}>
						<MultiColorIndicator color={program.color} size={'l'} />
					</Link>
					<FlexRow>
						<FlexColumn gap={'xs'}>
							<FlexRow gap={'l'}>
								<Text type={'heading'} variant={'xl'} data-cy={'program_name'}>
									{program.name}
								</Text>
								{/*<StatusIndicator status={_program.status} />*/}
								{hasModule(MODULE_TYPES.PROGRAMS_PLUS) ? (
									<FeedbackButton
										key={'programs-feedback-button'}
										link={'https://www.forecast.app/programs'}
									/>
								) : (
									<FeedbackButton
										key={'project-group-feedback-button'}
										link={'https://www.forecast.app/project-groups'}
									/>
								)}
							</FlexRow>
							<FlexRow gap={'s'}>{subMenu}</FlexRow>
						</FlexColumn>
					</FlexRow>
				</FlexRow>
				<FlexRow alignItems={'flex-start'}>
					<FlexRow justifyContent={'flex-end'} data-cy={'program_status'} gap={'s'}>
						<StatusWrapper
							programPrefix={program.prefix}
							currentStatusRag={program.currentProgramStatus?.ragStatus}
						/>
						<Text data-cy={'program_progress'}>{getProgress()}%</Text>
						<ProgressBar progress={getProgress()} />
						<Favorite onClick={_program.updateProgramFavorite} favorite={_program.isFavorite} size={'m'} />
					</FlexRow>
				</FlexRow>
			</FlexRow>
		</HeaderContainer>
	);
};

export default ProgramOverviewHeader;
