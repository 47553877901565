import React from 'react';
import Util from '../../../../shared/util/util';
import {Table} from 'web-components';
import TaskIndicatorsColumn from './columns/TaskIndiatorsColumn';
import LabelGroup from '../../../../shared/components/labels/label_group';
import AssignedTableTile from '../../../../shared/components/table-tiles/assigned_table_tile';
import {ESTIMATION_UNIT, MODULE_TYPES} from '../../../../../constants';
import {hasModule} from '../../../../shared/util/ModuleUtil';
import {DeprecatedProjectIndicatorNoFragment} from '../../../../shared/components/project-indicator/DeprecatedProjectIndicator';

export const taskRow = (intl, task, showTaskModal, isSharedView, currency) => {
	const customFields =
		(hasModule(MODULE_TYPES.CUSTOM_FIELDS) || isSharedView) && task.reportCustomFieldValues
			? [...task.reportCustomFieldValues].sort((a, b) => (a.displayName > b.displayName ? 1 : -1))
			: [];

	const openTaskModal = task => {
		showTaskModal(task.companyTaskId);
	};

	const labels = task.labels?.map(l => {
		return {label: l};
	});

	const columns = [
		// *** Task name ***
		<Table.Column key={task.id + '_name'}>
			<Table.Column.Cell
				title={task.name}
				onClick={() => !isSharedView && !task.project.readOnlyAccess && openTaskModal(task)}
			>
				{`T${task.companyTaskId} ${task.name}`}
			</Table.Column.Cell>
		</Table.Column>,

		// *** Indicators ***
		<TaskIndicatorsColumn task={task} intl={intl} key={task.id + '_indicator'} />,

		// *** Project name ***
		<Table.Column key={task.id + '_project'}>
			<Table.Column.Cell title={task.project.name}>
				{task.project.companyProjectId && task.project.projectColor ? (
					<DeprecatedProjectIndicatorNoFragment
						project={task.project}
						disableLink={isSharedView || task.project.readOnlyAccess}
						showProjectName
						clearSubPath
					/>
				) : (
					task.project.name
				)}
			</Table.Column.Cell>
		</Table.Column>,

		// *** Project End date ***
		<Table.Column key={task.id + '_project_deadline'}>
			<Table.Column.Cell>
				{task.project.deadline &&
					intl.formatDate(task.project.deadline, {
						year: 'numeric',
						month: 'short',
						day: 'numeric',
						timeZone: 'UTC',
					})}
			</Table.Column.Cell>
		</Table.Column>,

		// *** Project stage ***
		<Table.Column key={task.id + '_project_stage'}>
			<Table.Column.Cell>{Util.getProjectStageTranslation(task.project.stage, intl)}</Table.Column.Cell>
		</Table.Column>,

		// *** Rate card ***
		<Table.Column key={task.id + '_rateCard'}>
			<Table.Column.Cell>{task.project.rateCard?.name}</Table.Column.Cell>
		</Table.Column>,

		// *** Client name ***
		<Table.Column key={task.id + '_client'}>
			<Table.Column.Cell title={task.project.client?.name}>{task.project.client?.name}</Table.Column.Cell>
		</Table.Column>,

		// *** Task Start date ***
		<Table.Column key={task.id + 'task_startDate'}>
			<Table.Column.Cell>
				{task.dates &&
					intl.formatDate(task.dates.start, {
						year: 'numeric',
						month: 'short',
						day: 'numeric',
						timeZone: 'UTC',
					})}
			</Table.Column.Cell>
		</Table.Column>,

		// *** Task End date ***
		<Table.Column key={task.id + '_task_endDate'}>
			<Table.Column.Cell>
				{task.dates &&
					intl.formatDate(task.dates.deadline, {
						year: 'numeric',
						month: 'short',
						day: 'numeric',
						timeZone: 'UTC',
					})}
			</Table.Column.Cell>
		</Table.Column>,

		// *** Task Created date ***
		<Table.Column key={task.id + '_task_createdAt'}>
			<Table.Column.Cell>
				{task.dates &&
					intl.formatDate(task.dates.createdAt, {
						year: 'numeric',
						month: 'short',
						day: 'numeric',
						timeZone: 'UTC',
					})}
			</Table.Column.Cell>
		</Table.Column>,

		// *** Role name ***
		<Table.Column key={task.id + '_role'}>
			<Table.Column.Cell>{task.role?.name}</Table.Column.Cell>
		</Table.Column>,

		// *** Assignees ***
		<Table.Column key={task.id + '_assignees'}>
			<Table.Column.Cell>
				<AssignedTableTile
					disabled={true}
					assignablePersons={[]}
					assignedPersons={task.assignees}
					assignPerson={() => {}}
					unassignPerson={() => {}}
					useTableStyling={true}
					isMultiSelect={false}
					id={task.id}
					key={'assigned-person'}
					task={task}
					maxDisplayed={5}
					cy={'assigned-person'}
					isClientUser={false}
					intl={intl}
				/>
			</Table.Column.Cell>
		</Table.Column>,

		// *** Status ***
		<Table.Column key={task.id + '_status'}>
			<Table.Column.Cell>{task.status?.name}</Table.Column.Cell>
		</Table.Column>,

		// *** Phase ***
		<Table.Column key={task.id + '_phase'}>
			<Table.Column.Cell>{task.phase?.name}</Table.Column.Cell>
		</Table.Column>,

		// *** Phase Start date ***
		<Table.Column key={task.id + '_phase_startDate'}>
			<Table.Column.Cell>
				{task.phase?.dates?.start &&
					intl.formatDate(task.phase.dates.start, {
						year: 'numeric',
						month: 'short',
						day: 'numeric',
						timeZone: 'UTC',
					})}
			</Table.Column.Cell>
		</Table.Column>,

		// *** Phase End date ***
		<Table.Column key={task.id + '_phase_endDate'}>
			<Table.Column.Cell>
				{task.phase?.dates?.deadline &&
					intl.formatDate(task.phase.dates.deadline, {
						year: 'numeric',
						month: 'short',
						day: 'numeric',
						timeZone: 'UTC',
					})}
			</Table.Column.Cell>
		</Table.Column>,

		// *** Sprint ***
		<Table.Column key={task.id + '_sprint'}>
			<Table.Column.Cell>{task.sprint?.name}</Table.Column.Cell>
		</Table.Column>,

		// *** Sprint Start date ***
		<Table.Column key={task.id + '_sprint_startDate'}>
			<Table.Column.Cell>
				{task.sprint &&
					task.sprint.dates &&
					intl.formatDate(task.sprint.dates.start, {
						year: 'numeric',
						month: 'short',
						day: 'numeric',
						timeZone: 'UTC',
					})}
			</Table.Column.Cell>
		</Table.Column>,

		// *** Sprint End date ***
		<Table.Column key={task.id + '_sprint_endDate'}>
			<Table.Column.Cell>
				{task.sprint &&
					task.sprint.dates &&
					intl.formatDate(task.sprint.dates.deadline, {
						year: 'numeric',
						month: 'short',
						day: 'numeric',
						timeZone: 'UTC',
					})}
			</Table.Column.Cell>
		</Table.Column>,

		// *** Progress ***
		<Table.Column key={task.id + '_progress'}>
			<Table.Column.Cell>{`${task.progress}%`}</Table.Column.Cell>
		</Table.Column>,

		// *** Estimate ***
		<Table.Column key={task.id + '_estimate_hours'}>
			<Table.Column.Cell>
				{task.project.estimationUnit === ESTIMATION_UNIT.HOURS &&
					Util.convertMinutesToFullHour(task.work.estimate, intl)}
			</Table.Column.Cell>
		</Table.Column>,
		<Table.Column key={task.id + '_estimate_points'}>
			<Table.Column.Cell>
				{task.project.estimationUnit === ESTIMATION_UNIT.POINTS &&
					`${intl.formatNumber(task.work.estimate, {format: 'always_two_decimal'})}p`}
			</Table.Column.Cell>
		</Table.Column>,

		// *** Projected time ***
		<Table.Column key={task.id + '_projected'}>
			<Table.Column.Cell>
				{task.reported && Util.convertMinutesToFullHour(task.reported.projected, intl)}
			</Table.Column.Cell>
		</Table.Column>,

		// *** Reported time ***
		<Table.Column key={task.id + '_reported'}>
			<Table.Column.Cell>{task.reported && Util.convertMinutesToFullHour(task.reported.time, intl)}</Table.Column.Cell>
		</Table.Column>,

		// *** Remaining time ***
		<Table.Column key={task.id + '_remaining_hours'}>
			<Table.Column.Cell>
				{task.work &&
					task.project.estimationUnit === ESTIMATION_UNIT.HOURS &&
					Util.convertMinutesToFullHour(task.work.remaining, intl)}
			</Table.Column.Cell>
		</Table.Column>,
		<Table.Column key={task.id + '_remaining_points'}>
			<Table.Column.Cell>
				{task.work &&
					task.project.estimationUnit === ESTIMATION_UNIT.POINTS &&
					`${intl.formatNumber(task.work.remaining, {format: 'always_two_decimal'})}p`}
			</Table.Column.Cell>
		</Table.Column>,

		// *** Difference ***
		<Table.Column key={task.id + '_difference_hours'}>
			<Table.Column.Cell>
				{task.work &&
					task.project.estimationUnit === ESTIMATION_UNIT.HOURS &&
					Util.convertMinutesToFullHour(task.work.estimate - task.reported.time, intl)}
			</Table.Column.Cell>
		</Table.Column>,
		<Table.Column key={task.id + '_difference_points'}>
			<Table.Column.Cell>
				{task.work &&
					task.project.estimationUnit === ESTIMATION_UNIT.POINTS &&
					`${intl.formatNumber(task.work.estimate - task.reported.time / task.project.minutesPerEstimationPoint, {
						format: 'always_two_decimal',
					})}p`}
			</Table.Column.Cell>
		</Table.Column>,

		// *** Planned revenue ***
		<Table.Column key={task.id + '_planned_revenue'}>
			<Table.Column.Cell>
				{task.financial?.revenue &&
					Util.GetFormattedCurrencySymbol(
						currency,
						intl.formatNumber(task.financial.revenue.planned, {format: 'always_two_decimal'})
					)}
			</Table.Column.Cell>
		</Table.Column>,

		// *** Actual revenue ***
		<Table.Column key={task.id + '_actual_revenue'}>
			<Table.Column.Cell>
				{task.financial?.revenue &&
					Util.GetFormattedCurrencySymbol(
						currency,
						intl.formatNumber(task.financial.revenue.actual, {format: 'always_two_decimal'})
					)}
			</Table.Column.Cell>
		</Table.Column>,

		// *** Approved ***
		<Table.Column key={task.id + '_approved'}>
			<Table.Column.Cell>
				{task.approved ? intl.formatMessage({id: 'common.yes'}) : intl.formatMessage({id: 'common.no'})}
			</Table.Column.Cell>
		</Table.Column>,

		// *** Labels ***
		<Table.Column key={task.id + '_labels'}>
			<Table.Column.Cell>
				<LabelGroup labels={labels} noWrap={true} intl={intl} />
			</Table.Column.Cell>
		</Table.Column>,

		// *** Custom Fields ***
		...customFields.map(customField => (
			<Table.Column key={task.id + '_' + customField.key}>
				<Table.Column.Cell>{customField.value}</Table.Column.Cell>
			</Table.Column>
		)),
	];

	return columns;
};
