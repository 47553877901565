import React from 'react';
import { ValueCell } from '../../../../../../components/initial-plan/ValueCell';
import { HIDDEN_FEATURES } from '../../../../../../constants';
import ForecastTooltip from '../../../../../shared/components/tooltips/ForecastTooltip';
import ProjectUtil from '../../../../../shared/util/project_util';
import { getNonBillableTime } from '../../../../../shared/util/time-registration/time-registration-settings/BillableTimeSplitUtil';
import Util from '../../../../../shared/util/util';
import { Data, HorizontalBar, Title } from '../ProjectScopingTotals_styled';
import { EstimateValueTooltip, TimeEntriesValueTooltip } from '../ProjectScopingTotalsTooltips';
import { AvailabilityTile } from './AvailabilityTile';
import { Tile, TileRow } from './Tile_styled';
import { TileVariant } from './TotalsTypes';
const EstimateRow = ({ totalsData, intl }) => {
    return (React.createElement(TileRow, null,
        React.createElement(Title, { singleLine: true }, totalsData.isEstimatedInHours
            ? intl.formatMessage({ id: 'project_scoping.total_estimate_in_plan' })
            : 'Total points estimated in current plan'),
        React.createElement(Data, { small: true },
            React.createElement(ForecastTooltip, { placement: 'left', content: EstimateValueTooltip() },
                React.createElement(ValueCell, { noJustify: true, type: totalsData.isEstimatedInHours ? ValueCell.VALUE_TYPE.TIME : ValueCell.VALUE_TYPE.POINTS, value: totalsData.estimate })))));
};
const TimeEntriesRow = ({ totalsData, projectData, intl, }) => {
    return (React.createElement(TileRow, null, !ProjectUtil.projectUsesManualProgress(projectData) &&
        !Util.isFeatureHidden(HIDDEN_FEATURES.TIME_REGISTRATIONS) && (React.createElement(React.Fragment, null,
        React.createElement(Title, { singleLine: true }, intl.formatMessage({ id: 'project_scoping.actual_hours_spent' })),
        React.createElement(Data, { small: true },
            React.createElement(ForecastTooltip, { placement: 'left', content: TimeEntriesValueTooltip() },
                React.createElement(ValueCell, { noJustify: true, type: ValueCell.VALUE_TYPE.TIME, value: totalsData.timeEntries })))))));
};
const BillableTimeEntriesRow = ({ totalsData }) => {
    return (React.createElement(TileRow, null,
        React.createElement(Title, { singleLine: true }, 'Billable hours spent to date'),
        React.createElement(Data, { small: true },
            React.createElement(ValueCell, { noJustify: true, type: ValueCell.VALUE_TYPE.TIME, value: totalsData.billableTimeEntries }))));
};
const NonBillableTimeEntriesRow = ({ totalsData }) => {
    return (React.createElement(TileRow, null,
        React.createElement(Title, { singleLine: true }, 'Non-billable hours spent to date'),
        React.createElement(Data, { small: true },
            React.createElement(ValueCell, { noJustify: true, type: ValueCell.VALUE_TYPE.TIME, value: getNonBillableTime(totalsData.timeEntries, totalsData.billableTimeEntries) }))));
};
export const EstimateAndTimeTile = ({ totalsData, projectData, showBillableSplit, availabilityTile, intl, }) => {
    const showAvailabilityTile = availabilityTile === TileVariant.EstimateAndTime;
    return (React.createElement(Tile, null,
        React.createElement(EstimateRow, { totalsData: totalsData, intl: intl }),
        React.createElement(TimeEntriesRow, { totalsData: totalsData, projectData: projectData, intl: intl }),
        showBillableSplit ? (React.createElement(React.Fragment, null,
            React.createElement(HorizontalBar, { transparent: true }),
            React.createElement(BillableTimeEntriesRow, { totalsData: totalsData }),
            React.createElement(NonBillableTimeEntriesRow, { totalsData: totalsData }))) : showAvailabilityTile ? (React.createElement(React.Fragment, null,
            React.createElement(HorizontalBar, null),
            React.createElement(AvailabilityTile, { totalsData: totalsData, intl: intl }))) : null));
};
