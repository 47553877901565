import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import Person from '../../person/person';
import SuggestedPersonAndRoleRenderer from './suggested_person_and_role_renderer';
import * as tracking from '../../../../../tracking';
import Util from '../../../util/util';
import HexAddIcon from '../../../../../images/v2/hexagon_add_button';
import * as uuid from 'uuid';
import {cloneDeep} from 'lodash';
import {trackEvent} from '../../../../../tracking/amplitude/TrackingV2';
import CustomScrollToNativeScroll from '../../scroll-bars/CustomScrollToNativeScroll';
import {profilePicSrc} from '../../../../../directApi';

class AssignedDropdown extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isManagingFocus: false,
			searchCriteria: '',
			selected: null,
			rolesExpanded: true,
			peopleExpanded: true,
			teamsExpanded: true,
			suggestedPersonsExpanded: true,
			assignedExpanded: true,
			dropdownExpanded: false,
			suggestedPersons: [],
			suggestedRoles: [],
			renderOnTop: false,
			dropdownHeight: 0,
			isClientViewRestricted: Util.isClientTaskViewRestricted(props.viewer),
		};
		this.collapseDropdown = this.collapseDropdown.bind(this);
	}

	componentDidMount() {
		document.addEventListener('scroll', this.collapseDropdown, true);
		if (this.customScrollRef) {
			this.customScrollRef.setScrollTop(0);
		}
	}

	componentWillUnmount() {
		document.removeEventListener('scroll', this.collapseDropdown, true);
	}
	collapseDropdown(e) {
		// prevent the scroll event to be triggered unintentionally
		if (
			e.target.nodeName !== '#document' &&
			e.target?.className !== 'contract-trigger' &&
			e.target?.className !== 'expand-trigger'
		) {
			if (!this.state.dropdownExpanded) {
				return;
			}
			if (e.target && e.target.firstChild && this.option_0 && e.target.firstChild.id === this.option_0.id) return;
			this.setState({dropdownExpanded: false});
			if (this.props.onCollapse) {
				this.props.onCollapse();
			}
		}
	}

	handleInputFocus() {
		if (!this.props.disabled) {
			if (this.wrapperDivRef) {
				this.offset = this.wrapperDivRef.getBoundingClientRect();
			} else {
				// When autoFocus prop is true, this method is fired before the component is fully mounted
				// Therefore wrapperDivRef is not available yet, hence the requestAnimationFrame
				requestAnimationFrame(() => {
					if (this.wrapperDivRef) {
						this.offset = this.wrapperDivRef.getBoundingClientRect();
						this.forceUpdate();
					}
				});
			}

			this.setState({dropdownExpanded: true});
		}
	}

	handleAddIconClick() {
		if (!this.state.dropdownExpanded && !this.inputRef) {
			this.setState({dropdownExpanded: true}, () => {
				this.focusInput();
			});
		} else {
			this.setState({dropdownExpanded: false});
		}
	}

	handleSearchCriteriaChange(e) {
		const selected = 2 + !!this.props.assignedRole + (this.props.assignedPersons ? this.props.assignedPersons.length : 0);
		this.setState({searchCriteria: e.target.value || '', selected});
	}

	clearInput() {
		this.setState({searchCriteria: ''}, () => {
			this.focusInput();
		});
	}

	focusInput(e) {
		if (this.inputRef) {
			this.inputRef.focus();
		}
	}

	toggleDropdown(e) {
		e.stopPropagation();

		if (this.state.dropdownExpanded && this.inputRef) {
			this.inputRef.blur();
		} else if (this.inputRef) {
			this.inputRef.focus();
		}
		this.setState({dropdownExpanded: !this.state.dropdownExpanded});
		if (this.props.giveParentFocus) {
			this.props.giveParentFocus();
		}
	}
	toggleRoles() {
		this.setState({rolesExpanded: !this.state.rolesExpanded});
	}
	togglePeople() {
		this.setState({peopleExpanded: !this.state.peopleExpanded});
	}
	toggleTeams() {
		this.setState({teamsExpanded: !this.state.teamsExpanded});
	}
	toggleSuggestedPersons() {
		this.setState({suggestedPersonsExpanded: !this.state.suggestedPersonsExpanded});
	}
	toggleAssigned() {
		this.setState({assignedExpanded: !this.state.assignedExpanded});
	}

	getPersonOptions() {
		const assignablePersons = this.props.assignablePersons.filter(
			edge => !this.props.assignedPersons || !this.props.assignedPersons.find(person => person.id === edge.node.person.id)
		);

		const searchCriteria = this.state.searchCriteria;
		return assignablePersons
			.filter(edge => {
				const person = edge.node.person;
				const role = edge.node.role;
				const firstName = person.firstName || '';
				const lastName = person.lastName || '';
				const roleName = role && role.name ? role.name : '';
				return (
					person.active &&
					(searchCriteria === '' ||
						Util.normalizedIncludes(firstName, searchCriteria) ||
						Util.normalizedIncludes(lastName, searchCriteria) ||
						Util.normalizedIncludes(roleName, searchCriteria))
				);
			})
			.sort((a, b) => {
				const aFirstName = a.node.person.firstName || a.node.person.name || '';
				const aLastName = a.node.person.lastName || '';
				const bFirstName = b.node.person.firstName || b.node.person.name || '';
				const bLastName = b.node.person.lastName || '';
				var textA = Util.normalizedWord(aFirstName + ' ' + aLastName).toUpperCase();
				var textB = Util.normalizedWord(bFirstName + ' ' + bLastName).toUpperCase();
				return textA < textB ? -1 : textA > textB ? 1 : 0;
			});
	}

	getTeamOptions() {
		const {assignableTeams} = this.props;

		const searchCriteria = this.state.searchCriteria;

		return assignableTeams
			.filter(team => searchCriteria === '' || Util.normalizedIncludes(team.name, searchCriteria))
			.sort((a, b) => {
				var textA = (a.name || '').toUpperCase();
				var textB = (b.name || '').toUpperCase();
				return textA < textB ? -1 : textA > textB ? 1 : 0;
			});
	}

	getRoleOptions() {
		const assignableRoles = this.props.assignableRoles.filter(
			edge => !this.props.assignedRole || edge.node.id !== this.props.assignedRole.id
		);
		const searchCriteria = this.state.searchCriteria;

		return assignableRoles
			.filter(edge => {
				const role = edge.node;
				return searchCriteria === '' || Util.normalizedIncludes(role.name, searchCriteria);
			})
			.sort((a, b) => {
				var textA = (a.node.name || '').toUpperCase();
				var textB = (b.node.name || '').toUpperCase();
				return textA < textB ? -1 : textA > textB ? 1 : 0;
			});
	}

	combinedLengthOfOptions() {
		const assignedLength =
			(this.props.assignedPersons ? this.props.assignedPersons.length : 0) + (this.props.assignedRole ? 1 : 0);
		return (
			(this.props.assignablePersons ? this.getPersonOptions().length : 0) +
			(this.state.searchCriteria !== '' ? 0 : this.getSuggestedLength()) +
			(this.props.assignableRoles ? this.getRoleOptions().length : 0) +
			(this.props.assignableTeams ? this.getTeamOptions().length : 0) +
			assignedLength +
			3
		);
	}

	handleKeyPress(e) {
		if (this.state.dropdownExpanded) {
			if (e.key === 'Enter' || e.key === ' ') {
				//Enter / Space
				e.preventDefault();
				const domNode = ReactDOM.findDOMNode(this['option_' + this.state.selected]);
				if (domNode) domNode.click();
			} else if (e.keyCode === 40) {
				// Down arrow
				const optionsLength = this.combinedLengthOfOptions();
				let selected = 0;
				if (this.state.selected !== null) {
					selected = this.state.selected + 1;
				}
				if (selected >= optionsLength) {
					// Back to start
					selected = 0;
				}
				this.setState({selected: selected});
				if (this.customScrollRef) {
					const domNode = ReactDOM.findDOMNode(this['option_' + selected]);
					if (domNode) {
						this.customScrollRef.setScrollTop(domNode.offsetTop - 50);
					}
				}
			} else if (e.key === 'Escape') {
				e.preventDefault();
				e.stopPropagation();
				this.collapseDropdown(e);
			} else if (e.keyCode === 38) {
				e.preventDefault();
				// Alt + Up arrow closes the dropdown
				if (e.altKey) {
					if (this.state.dropdownExpanded) {
						this.setState({dropdownExpanded: false, selected: null, searchCriteria: ''});
						return;
					}
				}
				// Up arrow
				const optionsLength = this.combinedLengthOfOptions();
				if (!this.state.renderOnTop && this.state.selected === null) return;

				let selected = 0;
				if (this.state.renderOnTop && this.state.selected === null) {
					selected = optionsLength - 1;
				} else if (this.state.selected > 0) {
					selected = this.state.selected - 1;
				} else {
					selected = optionsLength - 1;
				}
				this.setState({selected: selected});
				if (this.customScrollRef) {
					const domNode = ReactDOM.findDOMNode(this['option_' + selected]);
					if (domNode) {
						this.customScrollRef.setScrollTop(domNode.offsetTop - 200);
					}
				}
			} else if (e.key === 'Tab' && this.wrapperDivRef && this.props.withAddIcon) {
				e.preventDefault();
				this.wrapperDivRef.focus();
			}
		} else if (e.keyCode === 40 && e.altKey) {
			e.preventDefault();
			// Alt + Down arrow opens the dropdown
			if (!this.state.dropdownExpanded && !this.props.disabled) {
				this.setState({dropdownExpanded: true});
			}
		}
	}

	onFocus(e) {
		// Clear the timeout so we dont collapse the dropdown
		if (this.props.withAddIcon) this.handleInputFocus();

		clearTimeout(this._timeoutID);
		if (!this.state.isManagingFocus) {
			this.setState({
				isManagingFocus: true,
			});
		}
	}

	onBlur(e) {
		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement;
		if (
			newTarget.id === 'role-list' ||
			newTarget.id === 'people-list' ||
			newTarget.id === 'teams-list' ||
			newTarget.id === 'assigned-list' ||
			newTarget.id === 'suggested-persons-list' ||
			newTarget.id === 'assigned-input-add-icon' ||
			newTarget.className.includes('person-option') ||
			newTarget.className.includes('role-option') ||
			newTarget.className.includes('team-option') ||
			newTarget.className.includes('clear-input') ||
			newTarget.className.includes('arrow')
		)
			return;
		this.setState({dropdownExpanded: false, searchCriteria: ''});
		// Set a timeout to collapse the dropdown if we dont get focus within this tick
		this._timeoutID = setTimeout(() => {
			if (this.state.isManagingFocus) {
				this.setState({
					isManagingFocus: false,
				});
			}
		}, 0);
	}

	setSuggestedPersons(suggestedPersons) {
		this.setState({suggestedPersons: suggestedPersons});
	}

	sendMLFeedbackPerson(personId, isSuggestedPerson) {
		if (!this.props.viewer && (!this.props.projectId || !this.props.companyId)) return;
		if (!personId) return; // don't send event if roleId is undefined (removal of an assigned role)
		const suggestedPersonsList = this.state.suggestedPersons;
		let wasInSuggestedPersonsList = isSuggestedPerson;
		let wasInTop5SuggestedPersonsList =
			suggestedPersonsList && suggestedPersonsList.edges?.slice(0, 5).find(sp => sp.node.id === personId);
		let wasInTop3SuggestedPersonsList =
			suggestedPersonsList && suggestedPersonsList.edges?.slice(0, 3).find(sp => sp.node.id === personId);

		let wasInTop1SuggestedPersonsList =
			suggestedPersonsList &&
			suggestedPersonsList.edges?.length > 0 &&
			suggestedPersonsList.edges[0].node?.id === personId;

		if (!isSuggestedPerson) {
			// Check if that person was among the suggested persons list
			wasInSuggestedPersonsList =
				suggestedPersonsList && suggestedPersonsList.edges?.find(sp => sp.node?.id === personId);
		}

		const taskId = this.props.task ? this.props.task.id : null;
		const projectId = this.props.projectId
			? this.props.projectId
			: this.props.viewer.project
			? this.props.viewer.project.id
			: this.props.task
			? this.props.task.project
				? this.props.task.project.id
				: null
			: null;
		const companyId = this.props.companyId
			? this.props.companyId
			: this.props.viewer.company
			? this.props.viewer.company.id
			: null;

		tracking.trackEvent('[ML - Suggested Persons]', {
			isSuggestedPerson: isSuggestedPerson,
			wasInSuggestedPersonsList: !!wasInSuggestedPersonsList,
			wasInTop5SuggestedPersonsList: !!wasInTop5SuggestedPersonsList,
			wasInTop3SuggestedPersonsList: !!wasInTop3SuggestedPersonsList,
			wasInTop1SuggestedPersonsList: !!wasInTop1SuggestedPersonsList,
			taskId: Util.getIdFromBase64String(taskId),
			projectId: Util.getIdFromBase64String(projectId),
			companyId: Util.getIdFromBase64String(companyId),
		});

		trackEvent('Suggested Person', 'Feedback Given', {
			isSuggestedPerson: isSuggestedPerson,
			wasInSuggestedPersonsList: !!wasInSuggestedPersonsList,
			wasInTop5SuggestedPersonsList: !!wasInTop5SuggestedPersonsList,
			wasInTop3SuggestedPersonsList: !!wasInTop3SuggestedPersonsList,
			wasInTop1SuggestedPersonsList: !!wasInTop1SuggestedPersonsList,
			taskId: Util.getIdFromBase64String(taskId),
			projectId: Util.getIdFromBase64String(projectId),
			companyId: Util.getIdFromBase64String(companyId),
		});
	}

	assignPerson(person, isSuggestedPerson, e) {
		const id = person.id;
		if (this.props.assignPerson === undefined || this.props.assignPerson === null) {
			return;
		}

		if (this.props.showSuggestions) {
			this.sendMLFeedbackPerson(id, isSuggestedPerson);
		}

		e.stopPropagation();
		if (this.props.assignPersonOnlyDiff === true) {
			// In this case we will only send the id of the specific person that was added
			this.props.assignPerson(id, e, this.props.task);
		} else if (this.props.isMultiSelect) {
			let currentPersons = this.props.assignedPersons ? cloneDeep(this.props.assignedPersons) : [];
			currentPersons.push(person);
			currentPersons.sort((a, b) => {
				let aName = a.firstName || a.name;
				let bName = b.firstName || b.name;
				aName = Util.normalizedWord(aName);
				bName = Util.normalizedWord(bName);

				return aName < bName ? -1 : aName > bName ? 1 : 0;
			});
			const currentIds = currentPersons.map(person => person.id);
			this.props.assignPerson(currentIds, e, this.props.task);
		} else {
			this.props.assignPerson([id], e, this.props.task);
		}
		if (this.inputRef) {
			this.inputRef.focus();
		}
		this.setState({
			selected: 3 + !!this.props.assignedRole + (this.props.assignedPersons ? this.props.assignedPersons.length : 0),
		}); //select the first element from the unassigned roles/people

		if (this.props.closeOnChoice) {
			if (this.inputRef) {
				this.inputRef.blur();
			}
			this.setState({dropdownExpanded: false});
		}
	}

	unassignPerson(unassignId, e) {
		if (this.props.unassignPerson === undefined || this.props.unassignPerson === null) {
			return;
		}
		e.stopPropagation();
		this.props.unassignPerson(unassignId, e, this.props.task);
		if (this.inputRef) {
			this.inputRef.focus();
		}
		if (this.props.closeOnUnassign) this.toggleDropdown(e);
		this.setState({
			selected: 1 + !!this.props.assignedRole + (this.props.assignedPersons ? this.props.assignedPersons.length : 0),
		});
	}

	assignTeam(team, e) {
		e.stopPropagation();

		if (this.props.assignTeam === undefined || this.props.assignTeam === null) {
			return;
		}
		const {id} = team;

		this.props.assignTeam(id);

		if (this.props.closeOnChoice) {
			if (this.inputRef) {
				this.inputRef.blur();
			}
			this.setState({dropdownExpanded: false});
		}
	}

	setSuggestedRoles(suggestedRoles) {
		this.setState({suggestedRoles: suggestedRoles});
	}

	sendMLFeedbackRole(roleId, isSuggestedRole) {
		if (!this.props.viewer && (!this.props.projectId || !this.props.companyId)) return;
		if (!roleId) return; // don't send event if roleId is undefined (removal of an assigned role)
		const suggestedRolesList = this.state.suggestedRoles;
		let wasInSuggestedRolesList = isSuggestedRole;
		let wasInTop5SuggestedRolesList =
			suggestedRolesList && suggestedRolesList.edges?.slice(0, 5).find(sp => sp.node.id === roleId);
		let wasInTop3SuggestedRolesList =
			suggestedRolesList && suggestedRolesList.edges?.slice(0, 3).find(sp => sp.node.id === roleId);
		let wasInTop1SuggestedRolesList =
			suggestedRolesList && suggestedRolesList.edges?.length > 0 && suggestedRolesList.edges[0].node.id === roleId;

		if (!isSuggestedRole) {
			// Check if that role was among the suggested roles list
			wasInSuggestedRolesList = suggestedRolesList && suggestedRolesList.edges?.find(sp => sp.node.id === roleId);
		}

		const taskId = this.props.task ? this.props.task.id : null;
		const projectId = this.props.projectId
			? this.props.projectId
			: this.props.viewer.project
			? this.props.viewer.project.id
			: this.props.task
			? this.props.task.project
				? this.props.task.project.id
				: null
			: null;
		const companyId = this.props.companyId
			? this.props.companyId
			: this.props.viewer.company
			? this.props.viewer.company.id
			: null;

		tracking.trackEvent('[ML - Suggested Roles]', {
			isSuggestedRole: isSuggestedRole,
			wasInSuggestedRoleList: !!wasInSuggestedRolesList,
			wasInTop5SuggestedRoleList: !!wasInTop5SuggestedRolesList,
			wasInTop3SuggestedRoleList: !!wasInTop3SuggestedRolesList,
			wasInTop1SuggestedRoleList: !!wasInTop1SuggestedRolesList,
			taskId: Util.getIdFromBase64String(taskId),
			projectId: Util.getIdFromBase64String(projectId),
			companyId: Util.getIdFromBase64String(companyId),
		});

		trackEvent('Suggested Role', 'Feedback Given', {
			isSuggestedRole: isSuggestedRole,
			wasInSuggestedRoleList: !!wasInSuggestedRolesList,
			wasInTop5SuggestedRoleList: !!wasInTop5SuggestedRolesList,
			wasInTop3SuggestedRoleList: !!wasInTop3SuggestedRolesList,
			wasInTop1SuggestedRoleList: !!wasInTop1SuggestedRolesList,
			taskId: Util.getIdFromBase64String(taskId),
			projectId: Util.getIdFromBase64String(projectId),
			companyId: Util.getIdFromBase64String(companyId),
		});
	}

	assignRole(roleId, isSuggestedRole, e) {
		if (this.props.assignRole === undefined || this.props.assignRole === null) {
			return;
		}

		if (this.props.showSuggestions) {
			this.sendMLFeedbackRole(roleId, isSuggestedRole);
		}

		if (e) {
			e.stopPropagation();
		}
		this.props.assignRole(roleId, e, this.props.task);
		if (this.inputRef) {
			this.inputRef.focus();
		}
		if (roleId) {
			this.setState({selected: 3 + (this.props.assignedPersons ? this.props.assignedPersons.length : 0)}); //select the first element from the unassigned roles/people
		} else {
			this.setState({
				selected: 1 + !!this.props.assignedRole + (this.props.assignedPersons ? this.props.assignedPersons.length : 0),
			});
		}

		if (this.props.closeOnChoice) {
			if (this.inputRef) {
				this.inputRef.blur();
			}
			this.setState({dropdownExpanded: false});
		}
	}

	getProjectId() {
		if (this.props.projectId) {
			return this.props.projectId;
		}

		if (this.props.viewer && this.props.viewer.project) {
			return this.props.viewer.project.id;
		}

		if (this.props.task && this.props.task.project) {
			return this.props.task.project.id;
		}

		return null;
	}

	getSuggestedLength() {
		const isPersonsOnlyDropdown = this.props.assignableRoles === undefined;
		const isRolesOnlyDropdown = this.props.assignablePersons === undefined;
		const suggestionsLength =
			(isPersonsOnlyDropdown && this.state.suggestedPersons?.edges?.length) +
			(isRolesOnlyDropdown && this.state.suggestedRoles?.edges?.length) +
			1;
		return this.props.showSuggestions ? (this.state.suggestedPersonsExpanded ? suggestionsLength : 1) : 0;
	}

	render() {
		const {formatMessage} = this.props.intl;
		const {disabled} = this.props;
		const id = uuid.v4();
		// Check if it is a persons only dropdown, or roles dropdown
		// This is needed since now we have separated roles and people dropdowns
		const isPersonsOnlyDropdown = this.props.assignableRoles === undefined;
		const isRolesOnlyDropdown = this.props.assignablePersons === undefined;
		const role_options = this.props.assignableRoles
			? this.getRoleOptions().map(role => {
					return {id: role.node.id, name: role.node.name, shorthand: Util.getInitials(role.node.name)};
			  })
			: [];

		const person_options = this.props.assignablePersons
			? this.getPersonOptions().map(edge => {
					return {
						id: edge.node.person.id,
						name: edge.node.person.firstName + (edge.node.person.lastName ? ' ' + edge.node.person.lastName : ''),
						role: edge.node.role ? edge.node.role.name : '',
						profilePictureId: edge.node.person.profilePictureId,
						profilePictureDefaultId: edge.node.person.profilePictureDefaultId,
					};
			  })
			: [];

		const teams_options = this.props.assignableTeams ? this.getTeamOptions() : [];

		const assigned_options = this.props.assignedPersons
			? this.props.assignedPersons
					.map(assigned => {
						const assignable = this.props.assignablePersons.find(
							assignable => assignable.node.person.id === assigned.id
						);
						if (assignable)
							return {
								id: assignable.node.person.id,
								name:
									assignable.node.person.firstName +
									(assignable.node.person.lastName ? ' ' + assignable.node.person.lastName : ''),
								role: assignable.node.role ? assignable.node.role.name : '',
								profilePictureId: assignable.node.person.profilePictureId,
								profilePictureDefaultId: assignable.node.person.profilePictureDefaultId,
							};
						return {
							id: assigned.id,
							name: assigned.firstName + (assigned.lastName ? ' ' + assigned.lastName : ''),
							role: !assigned.active
								? formatMessage({id: 'team.deactivated-group-title'})
								: assigned.role
								? assigned.role.name
								: '',
							profilePictureId: assigned.profilePictureId,
							profilePictureDefaultId: assigned.profilePictureDefaultId,
						};
					})
					.filter(assigned => {
						return (
							Util.normalizedIncludes(assigned.name, this.state.searchCriteria) ||
							Util.normalizedIncludes(assigned.role, this.state.searchCriteria)
						);
					})
					.sort((a, b) => {
						let aName = Util.normalizedWord(a.name);
						let bName = Util.normalizedWord(b.name);

						return aName < bName ? -1 : aName > bName ? 1 : 0;
					})
			: [];

		const assignedLength = assigned_options.length + (this.props.assignedRole ? 1 : 0) + 1;

		const suggestionsLength = this.getSuggestedLength();

		let style = {};
		if (this.offset) {
			let width = this.props.wideExpanded
				? this.offset.width < 190
					? this.offset.width + 98
					: this.offset.width
				: this.offset.width < 190
				? this.offset.width + 48
				: this.offset.width;
			if (this.offset.top + 350 > window.innerHeight) {
				style = {
					bottom: window.innerHeight - this.offset.top - 2 + (this.props.withAddIcon ? 10 : 0),
					left: this.offset.left,
					width: width,
					marginBottom: 0,
					borderBottomRightRadius: 0,
					borderBottomLeftRadius: 0,
					borderTopRightRadius: 4,
					borderTopLeftRadius: 4,
				};
			} else {
				style = {
					top: this.offset.top + this.offset.height - 2,
					left: this.offset.left,
					width: width,
				};
			}
		}

		const maxPeopleIconsShown = this.props.maxPeopleIconsShown || 4;
		return (
			<div
				id={this.props.wrapperId}
				className={
					'assigned-dropdown-wrapper no-drag' +
					(this.props.useSmallerStyling ? ' small' : '') +
					(disabled ? ' disabled' : '') +
					(this.props.customClasses ? ` ${this.props.customClasses} ` : '') +
					(this.props.withAddIcon ? ' add-icon' : '') +
					(this.props.shortInput ? ' short-input' : '')
				}
				onFocus={this.onFocus.bind(this)}
				onBlur={this.onBlur.bind(this)}
				ref={div => (this.wrapperDivRef = div)}
				tabIndex={this.props.withAddIcon ? 0 : -1}
				data-cy={`${this.props.cy}-button`}
				data-userpilot={this.props.userpilot}
			>
				{this.props.label ? <label htmlFor={this.props.withAddIcon ? '' : id}>{this.props.label}</label> : null}

				{this.props.withAddIcon ? null : (
					<input
						id={id + (this.props.withAddIcon ? '-add-icon' : '') + (this.props.id ? `-${this.props.id}` : '')}
						autoComplete="off"
						className={
							'subtask-assigned-input' +
							(this.state.dropdownExpanded ? ' expanded' : '') +
							(disabled ? ' disabled' : '') +
							(this.props.withAddIcon ? ' add-icon' : '')
						}
						type="text"
						value={this.state.dropdownExpanded ? this.state.searchCriteria : ''}
						onChange={this.handleSearchCriteriaChange.bind(this)}
						ref={input => {
							this.inputRef = input;
						}}
						placeholder={
							!this.state.dropdownExpanded && assignedLength > 1
								? ''
								: this.props.assignedRole === null && this.state.isClientViewRestricted
								? '-'
								: this.props.customPlaceholder
								? this.props.customPlaceholder
								: this.props.assignableRoles
								? formatMessage({
										id: 'common.assign_role',
								  })
								: formatMessage({
										id: 'common.assign_people',
								  })
						}
						onFocus={this.handleInputFocus.bind(this)}
						onKeyDown={this.handleKeyPress.bind(this)}
						role="combobox"
						aria-autocomplete="list"
						aria-owns="assigned-menu"
						aria-expanded={this.state.dropdownExpanded}
						aria-activedescendant={this.state.selected != null ? 'select_option_' + this.state.selected : ''}
						autoFocus={this.props.autoFocus}
						disabled={disabled}
						data-cy={`${this.props.cy}-input`}
					/>
				)}
				{this.state.dropdownExpanded &&
				this.state.searchCriteria.length &&
				!this.props.noClear &&
				!this.props.withAddIcon ? (
					<div
						className={`clear-input ${this.props.useSmallerStyling ? 'small' : ''}`}
						onClick={this.clearInput.bind(this)}
						tabIndex={-1}
						onKeyDown={this.handleKeyPress.bind(this)}
					/>
				) : null}

				{!this.state.dropdownExpanded || this.props.withAddIcon ? (
					<div className={'assigned-icons ' + (this.props.withAddIcon ? 'add-icon' : '')}>
						{this.props.assignedRole ? (
							<>{assigned_options.length ? null : <span>{this.props.assignedRole.name}</span>}</>
						) : null}
						{assigned_options
							.filter(
								(person, index) =>
									index <
									(assigned_options.length <= maxPeopleIconsShown
										? maxPeopleIconsShown
										: maxPeopleIconsShown - 1)
							)
							.map((person, index) => (
								<div onClick={this.focusInput.bind(this)} key={person.id}>
									<Person
										name={person.name}
										showName={false}
										showRole={false}
										imageSize={!this.props.useSmallerStyling ? 'new-ui-person-dropdown' : 'medium'}
										imageSrc={person.profilePictureId ? profilePicSrc(person.profilePictureId) : null}
										cy={`${this.props.cy}-assigned-person-${index}`}
									/>
								</div>
							))}

						{assigned_options.length > maxPeopleIconsShown ? (
							<div onClick={this.focusInput.bind(this)} className="more-people-container">
								+{assigned_options.length - maxPeopleIconsShown + 1}
							</div>
						) : assigned_options.length === 1 && !this.props.withAddIcon ? (
							<span style={{paddingRight: '20px'}}>{assigned_options[0].name}</span>
						) : null}
						{this.props.withAddIcon ? (
							<HexAddIcon onClick={this.handleAddIconClick.bind(this)} cy={`${this.props.cy}-add-button`} />
						) : null}
					</div>
				) : null}
				{this.props.withAddIcon ? null : (
					<div
						className={
							'arrow no-drag' + (this.state.dropdownExpanded ? ' expanded' : '') + (disabled ? ' disabled' : '')
						}
						aria-hidden="true"
						onClick={!disabled ? this.toggleDropdown.bind(this) : null}
						tabIndex={-1}
						onKeyDown={this.handleKeyPress.bind(this)}
						data-cy={this.props.cy ? `${this.props.cy}-close-arrow` : 'dropdown-close-arrow'}
					/>
				)}
				{this.state.dropdownExpanded && !disabled
					? ReactDOM.createPortal(
							<ul
								data-cy={'assigned-dropdown-scrollbar'}
								style={style}
								aria-hidden={!this.state.dropdownExpanded}
								aria-labelledby="assigned-input"
								className={
									'subtask-assigned-dropdown no-drag' +
									(this.props.withAddIcon ? ' add-icon' : '') +
									' new-scrollbar'
								}
								role="menu"
								id="assigned-menu"
							>
								{this.props.withAddIcon ? (
									<div className="dropdown-search-input-add-icon">
										<input
											id={'assigned-input' + (this.props.withAddIcon ? '-add-icon' : '')}
											autoComplete="off"
											className={
												'subtask-assigned-input' +
												(this.state.dropdownExpanded ? ' expanded' : '') +
												(disabled ? ' disabled' : '') +
												(this.props.withAddIcon ? ' add-icon' : '')
											}
											type="text"
											value={this.state.dropdownExpanded ? this.state.searchCriteria : ''}
											onChange={this.handleSearchCriteriaChange.bind(this)}
											ref={input => {
												this.inputRef = input;
											}}
											placeholder={
												this.props.isMultiSelect
													? !this.state.dropdownExpanded && assignedLength > 1
														? ''
														: formatMessage({
																id: 'common.assign',
														  })
													: assigned_options.length + (this.props.assignedRole ? 1 : 0) > 0
													? ''
													: formatMessage({
															id: 'assigned_dropdown.assign_person_or_role',
													  })
											}
											onFocus={this.handleInputFocus.bind(this)}
											onKeyDown={this.handleKeyPress.bind(this)}
											role="combobox"
											aria-autocomplete="list"
											aria-owns="assigned-menu"
											aria-expanded={this.state.dropdownExpanded}
											aria-activedescendant={
												this.state.selected != null ? 'select_option_' + this.state.selected : ''
											}
											autoFocus={true}
											disabled={disabled}
											data-cy={`${this.props.cy}-input`}
										/>
										{this.state.dropdownExpanded &&
										this.state.searchCriteria.length &&
										!this.props.noClear &&
										this.props.withAddIcon ? (
											<div
												className="clear-input"
												onClick={this.clearInput.bind(this)}
												tabIndex={-1}
												onKeyDown={this.handleKeyPress.bind(this)}
											/>
										) : null}
									</div>
								) : null}
								<CustomScrollToNativeScroll
									maxHeight={300}
									hasFocusableContent
									// Old scroller props
									autoHeight={true}
									autoHeightMin={1}
									autoHeightMax={300}
									ref={div => (this.customScrollRef = div)}
								>
									<li
										id="assigned-list"
										className={'no-drag'}
										onClick={e => e.stopPropagation()}
										role="menuitem"
										aria-expanded={this.state.assignedExpanded}
										aria-haspopup="menu"
										tabIndex="-1"
										ref={li => (this.option_0 = li)}
									>
										<div
											onClick={this.toggleAssigned.bind(this)}
											data-cy="list-header"
											className={'list-header no-drag' + (this.state.selected === 0 ? ' selected' : '')}
										>
											<div data-cy="assigned-header" className="title">
												{this.props.label
													? this.props.label
													: formatMessage({id: 'common.assigned'}) +
													  ' (' +
													  (assignedLength - 1) +
													  ')'}
											</div>
											<div className={this.state.assignedExpanded ? 'expanded' : 'not-expanded'} />
										</div>

										{this.state.assignedExpanded ? (
											<ul
												className={'no-drag'}
												aria-hidden={!this.state.assignedExpanded}
												aria-labelledby="assigned-list"
												role="menu"
											>
												{this.props.assignedRole ? (
													<li
														key={this.props.assignedRole.id}
														role="menuitem"
														onClick={e => e.stopPropagation()}
														tabIndex="-1"
														className={
															'role-option no-drag' +
															(this.state.selected === 1 ? ' selected' : '')
														}
														data-cy={'assigned-role'}
													>
														<div className="role-name no-drag">{this.props.assignedRole.name}</div>
														{!this.props.hideDelete ? (
															<div
																className={'delete-icon no-drag'}
																onClick={this.assignRole.bind(this, null, false)}
																ref={li => (this.option_1 = li)}
																data-cy={'delete-role-button'}
															/>
														) : null}
													</li>
												) : null}
												{assigned_options.map((person, index) => {
													return (
														<li
															className={
																'person-option no-drag' +
																(this.state.selected ===
																index + (this.props.assignedRole ? 1 : 0) + 1
																	? ' selected'
																	: '')
															}
															onClick={e => e.stopPropagation()}
															key={person.id}
															role="menuitem"
															data-cy={'assigned-person-' + index}
															tabIndex="-1"
														>
															<Person
																onClick={e => e.stopPropagation()}
																name={person.name}
																role={person.role}
																showName={true}
																showRole={true}
																imageSize="new-ui-dropdown"
																imageSrc={profilePicSrc(person.profilePictureId)}
															/>
															{this.props.isClientUser &&
															this.props.viewerId !== person.id ? null : (
																<div
																	className={'delete-icon no-drag'}
																	onClick={this.unassignPerson.bind(this, person.id)}
																	ref={li =>
																		(this[
																			'option_' +
																				(index + (this.props.assignedRole ? 1 : 0) + 1)
																		] = li)
																	}
																	data-cy={'delete-person-button'}
																/>
															)}
														</li>
													);
												})}
											</ul>
										) : null}
									</li>
									{this.props.showSuggestions ? (
										<SuggestedPersonAndRoleRenderer
											ref={li => (this['option_' + (role_options.length + assignedLength)] = li)}
											task={this.props.task}
											projectId={this.getProjectId()}
											assignPerson={this.assignPerson.bind(this)}
											assignRole={this.assignRole.bind(this)}
											toggleSuggestedPersons={this.toggleSuggestedPersons.bind(this)}
											assignedLength={assignedLength}
											role_options={role_options}
											suggestedPersonsExpanded={this.state.suggestedPersonsExpanded}
											selected={this.state.selected}
											assignedPersons={this.props.assignedPersons}
											searchCriteria={this.state.searchCriteria}
											assignedRole={this.props.assignedRole}
											setSuggestedPersons={this.setSuggestedPersons.bind(this)}
											setSuggestedRoles={this.setSuggestedRoles.bind(this)}
											isPersonsOnlyDropdown={isPersonsOnlyDropdown}
											isRolesOnlyDropdown={isRolesOnlyDropdown}
											suggestedPersonRoleFilter={this.props.suggestedPersonRoleFilter}
										/>
									) : null}
									{this.props.assignableRoles ? (
										<li
											id="role-list"
											onClick={this.toggleRoles.bind(this)}
											role="menuitem"
											aria-expanded={this.state.rolesExpanded}
											aria-haspopup="menu"
											tabIndex="-1"
											className={'no-drag'}
											ref={li => (this['option_' + (assignedLength + suggestionsLength)] = li)}
										>
											<div
												className={
													'list-header' +
													(this.state.selected === assignedLength + suggestionsLength
														? ' selected'
														: '')
												}
											>
												<div className="title">
													{formatMessage({id: 'settings.roles'}) + ' (' + role_options.length + ')'}
												</div>
												<div className={this.state.rolesExpanded ? 'expanded' : 'not-expanded'} />
											</div>
											{this.state.rolesExpanded ? (
												<ul
													aria-hidden={!this.state.rolesExpanded}
													aria-labelledby="role-list"
													role="menu"
												>
													{role_options.map((role, index) => {
														return (
															<li
																key={role.id}
																role="menuitem"
																tabIndex="-1"
																className={
																	'role-option no-drag' +
																	(this.state.selected ===
																	index + assignedLength + suggestionsLength + 1
																		? ' selected'
																		: '')
																}
																onClick={this.assignRole.bind(this, role.id, false)}
																ref={li =>
																	(this[
																		'option_' +
																			(index + assignedLength + suggestionsLength + 1)
																	] = li)
																}
																data-cy={'assigned-dropdown-role-option'}
															>
																<div className="role-name no-drag">{role.name}</div>
															</li>
														);
													})}
												</ul>
											) : null}
										</li>
									) : null}
									{this.props.assignablePersons ? (
										<li
											id="people-list"
											onClick={this.togglePeople.bind(this)}
											role="menuitem"
											className={'no-drag'}
											aria-expanded={this.state.peopleExpanded}
											aria-haspopup="menu"
											tabIndex="-1"
											ref={li =>
												(this['option_' + (role_options.length + assignedLength + suggestionsLength)] =
													li)
											}
										>
											<div
												className={
													'list-header no-drag' +
													(this.state.selected ===
													role_options.length + assignedLength + suggestionsLength
														? ' selected'
														: '')
												}
											>
												<div className="title">
													{formatMessage({id: 'common.people'}) + ' (' + person_options.length + ')'}
												</div>
												<div className={this.state.peopleExpanded ? 'expanded' : 'not-expanded'} />
											</div>
											{this.state.peopleExpanded ? (
												<ul
													className={'no-drag'}
													aria-hidden={!this.state.peopleExpanded}
													aria-labelledby="people-list"
													role="menu"
												>
													{person_options.map((person, index) => {
														return (
															<li
																key={person.id}
																role="menuitem"
																tabIndex="-1"
																className={
																	'person-option no-drag' +
																	(this.state.selected ===
																	role_options.length +
																		assignedLength +
																		suggestionsLength +
																		index +
																		1
																		? ' selected'
																		: '')
																}
																onClick={this.assignPerson.bind(this, person, false)}
																ref={li =>
																	(this[
																		'option_' +
																			(role_options.length +
																				assignedLength +
																				suggestionsLength +
																				index +
																				1)
																	] = li)
																}
																data-cy={'assigned-dropdown-person-option-' + index}
															>
																<Person
																	name={person.name}
																	role={person.role}
																	showName={true}
																	showRole={true}
																	imageSize="new-ui-dropdown"
																	imageSrc={
																		person.profilePictureId
																			? profilePicSrc(person.profilePictureId)
																			: null
																	}
																/>
															</li>
														);
													})}
												</ul>
											) : null}
										</li>
									) : null}
									{this.props.assignableTeams && (
										<li
											id="teams-list"
											onClick={this.toggleTeams.bind(this)}
											role="menuitem"
											className={'no-drag'}
											aria-expanded={this.state.teamsExpanded}
											aria-haspopup="menu"
											tabIndex="-1"
											ref={li =>
												(this[
													'option_' +
														(person_options.length +
															role_options.length +
															assignedLength +
															suggestionsLength +
															1)
												] = li)
											}
										>
											<div
												className={
													'list-header no-drag' +
													(this.state.selected ===
													person_options.length +
														role_options.length +
														assignedLength +
														suggestionsLength +
														1
														? ' selected'
														: '')
												}
											>
												<div className="title">
													{formatMessage({id: 'settings.teams'}) + ' (' + teams_options.length + ')'}
												</div>
												<div className={this.state.teamsExpanded ? 'expanded' : 'not-expanded'} />
											</div>
											{this.state.teamsExpanded ? (
												<ul
													className={'no-drag'}
													aria-hidden={!this.state.teamsExpanded}
													aria-labelledby="teams-list"
													role="menu"
												>
													{teams_options.map((team, index) => {
														return (
															<li
																key={team.id}
																role="menuitem"
																tabIndex="-1"
																className={
																	'team-option no-drag' +
																	(this.state.selected ===
																	person_options.length +
																		role_options.length +
																		assignedLength +
																		suggestionsLength +
																		index +
																		2
																		? ' selected'
																		: '')
																}
																onClick={this.assignTeam.bind(this, team)}
																ref={li =>
																	(this[
																		'option_' +
																			(person_options.length +
																				role_options.length +
																				assignedLength +
																				suggestionsLength +
																				index +
																				2)
																	] = li)
																}
																data-cy={'assigned-dropdown-team-option-' + index}
															>
																{team.name}
															</li>
														);
													})}
												</ul>
											) : null}
										</li>
									)}
								</CustomScrollToNativeScroll>
							</ul>,
							document.querySelector('#root-portal-container')
					  )
					: null}
			</div>
		);
	}
}

AssignedDropdown.propTypes = {
	projectId: PropTypes.string, // override project id defined in viewer.
	disabled: PropTypes.bool,
	assignedPersons: PropTypes.arrayOf(PropTypes.object),
	assignedRole: PropTypes.object,
	viewer: PropTypes.object,
	assignablePersons: PropTypes.arrayOf(PropTypes.object),
	assignableRoles: PropTypes.arrayOf(PropTypes.object),
	assignableTeams: PropTypes.arrayOf(PropTypes.object),
	isMultiSelect: PropTypes.bool,
	assignRole: PropTypes.func,
	assignPerson: PropTypes.func,
	assignPersonOnlyDiff: PropTypes.bool,
	isWhite: PropTypes.bool,
	maxPeopleIconsShown: PropTypes.number,
	taskName: PropTypes.string,
	withAddIcon: PropTypes.bool, // show the plus button instead of the dropdown
	showSuggestions: PropTypes.bool,
	closeOnUnassign: PropTypes.bool,
	shortInput: PropTypes.bool, // this makes the dropdown 30px high
	shouldShowAiIcon: PropTypes.bool,
	useNewAiIcon: PropTypes.bool,
	id: PropTypes.string, // for custom ids
};
export default injectIntl(AssignedDropdown);
