import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import {FormattedDate, FormattedRelativeTime, injectIntl} from 'react-intl';
import {selectUnit} from '@formatjs/intl-utils';
import Person from '../../../person/person';
import Util from '../../../../util/util';
import {profilePicSrc} from '../../../../../../directApi';

class ActivityLogRow extends Component {
	getMessage() {
		const activity = this.props.activity;
		let eventType = activity.eventType;
		const params = activity.params ? JSON.parse(activity.params) : {};
		let messageParams = params;

		const taskLevelSuffix = this.props.taskLevels === 1 ? '.TODO' : '.SUBTASK';

		return Util.getTaskActivityMessage(eventType, messageParams, params, taskLevelSuffix, this.props.intl);
	}

	render() {
		const activity = this.props.activity;
		const {value, unit} = selectUnit(Moment.utc(activity.date));
		return (
			<div className="activity-log-row">
				{activity.person ? (
					<Person
						name={activity.person.firstName + (activity.person.lastName ? ' ' + activity.person.lastName : '')}
						showName={true}
						showRole={true}
						role={activity.person.role ? activity.person.role.name : ''}
						imageSize="large"
						imageSrc={profilePicSrc(activity.person.profilePictureId)}
					/>
				) : (
					<div className="person-container">
						<div className="name">{'Someone'}</div>
					</div>
				)}
				<div className="text">{this.getMessage()}</div>
				<div className="date">
					<span>
						<FormattedDate
							value={Moment.utc(activity.date).toDate()}
							year="numeric"
							month="numeric"
							day="numeric"
							hour="numeric"
							minute="numeric"
							second="numeric"
						/>
					</span>
					<span>{unit !== 'year' && <FormattedRelativeTime unit={unit} value={value} />}</span>
				</div>
			</div>
		);
	}
}

ActivityLogRow.propTypes = {
	activity: PropTypes.object.isRequired,
};

export default injectIntl(ActivityLogRow);
