/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TimeRegistrationModalIntegrations_company",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "harvestEnabled",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unit4Enabled",
            "storageKey": null
        }
    ],
    "type": "Company"
};
node.hash = '3e4a85aa04776d0de7ff2744e0473a3a';
export default node;
