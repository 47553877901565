/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type SprintGoalSlideout_viewer$ref: FragmentReference;
declare export opaque type SprintGoalSlideout_viewer$fragmentType: SprintGoalSlideout_viewer$ref;
export type SprintGoalSlideout_viewer = {|
  +sprint: ?{|
    +id: string,
    +name: ?string,
    +sprintGoals: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +description: ?string,
          +statusColor: ?string,
          +stage: ?string,
        |}
      |}>
    |},
  |},
  +projectGroupSprint: ?{|
    +id: string,
    +name: ?string,
    +sprintGoals: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +description: ?string,
          +statusColor: ?string,
          +stage: ?string,
        |}
      |}>
    |},
  |},
  +$refType: SprintGoalSlideout_viewer$ref,
|};
export type SprintGoalSlideout_viewer$data = SprintGoalSlideout_viewer;
export type SprintGoalSlideout_viewer$key = {
  +$data?: SprintGoalSlideout_viewer$data,
  +$fragmentRefs: SprintGoalSlideout_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SprintGoalTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SprintGoal",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "statusColor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "stage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sprintId",
      "type": "ID"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectGroupSprintId",
      "type": "ID"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "sprint",
          "sprintGoals"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "projectGroupSprint",
          "sprintGoals"
        ]
      }
    ]
  },
  "name": "SprintGoalSlideout_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "sprintId"
        }
      ],
      "concreteType": "Sprint",
      "kind": "LinkedField",
      "name": "sprint",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": "sprintGoals",
          "args": null,
          "concreteType": "SprintGoalTypeConnection",
          "kind": "LinkedField",
          "name": "__Sprint_sprintGoals_connection",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "projectGroupSprintId"
        }
      ],
      "concreteType": "ProjectGroupSprintType",
      "kind": "LinkedField",
      "name": "projectGroupSprint",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": "sprintGoals",
          "args": null,
          "concreteType": "SprintGoalTypeConnection",
          "kind": "LinkedField",
          "name": "__ProjectGroupSprint_sprintGoals_connection",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '1a32a14291275199e6db19cc8ff68d5a';

module.exports = node;
