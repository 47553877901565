import React from 'react';
import {TopNavigation} from '@forecast-it/design-system';
import ForecastQueryRenderer from '../../../ForecastQueryRenderer';
import PrimaryNavigationMain, {PrimaryNavigationMainQuery} from './PrimaryNavigationMain';
import PrimaryNavigationRight, {PrimaryNavigationRightQuery} from './PrimaryNavigationRight';
import {ZenModeable} from '../../shared/components/zen-mode/ZenModeable';

const PrimaryNavigation = ({actualPersonId}) => {
	const noHeaderPaths = ['baseline/edit'];

	const noHeader = noHeaderPaths.some(noHeaderPath => {
		return location.pathname.endsWith(noHeaderPath);
	});

	const customLoader = () => <div className={'header-loader'} style={{height: '56px'}}></div>;
	return (
		<ZenModeable>
			{noHeader ? null : (
				<TopNavigation>
					<ForecastQueryRenderer
						key="query-render-top_navigation_main"
						query={PrimaryNavigationMainQuery}
						variables={{}}
						redirectIfNotLoggedIn={true}
						customLoader={customLoader}
						render={(relayProps, retry) => <PrimaryNavigationMain retry={retry} {...relayProps} />}
					/>
					<ForecastQueryRenderer
						key="query-render-top_navigation_right"
						query={PrimaryNavigationRightQuery}
						variables={{}}
						redirectIfNotLoggedIn={true}
						customLoader={customLoader}
						render={(relayProps, retry) => (
							<PrimaryNavigationRight retry={retry} {...relayProps} actualPersonId={actualPersonId} />
						)}
					/>
				</TopNavigation>
			)}
		</ZenModeable>
	);
};

export default PrimaryNavigation;
