import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../../../components/new-ui/tooltip';
import {EVENT_ID, subscribe, unsubscribe} from '../../../../containers/event_manager';
import {ClickAwayListener} from '@material-ui/core';

export default class TooltipContainer extends React.Component {
	constructor(props) {
		super(props);
		this.tooltipComponent = React.createRef();
		this.showTimeout = null;
		this.durationTimeout = null;
		this.state = {
			showTooltip: false,
			defaultTriangleLocation: 'topMiddle',
			canCalculateTooltipPosition: false,
			focused: false,
			highlightFocus: this.props.highlightFocus,
			showOnClick: this.props.showOnClick,
			locked: false,
		};
		this.closeTooltip = this.closeTooltip.bind(this);
	}

	componentDidMount() {
		subscribe(EVENT_ID.SCROLLED, this.closeTooltip);
		if (this.props.showOnRender) {
			this.setState({showTooltip: true});
		}
	}

	componentWillUnmount() {
		unsubscribe(EVENT_ID.SCROLLED, this.closeTooltip);
		if (this.durationTimeout !== null) {
			clearTimeout(this.durationTimeout);
			this.durationTimeout = null;
		}
		if (this.showTimeout !== null) {
			clearTimeout(this.showTimeout);
			this.showTimeout = null;
		}
	}

	onMouseEnter(e) {
		if (this.state.highlightFocus) {
			this.setState({
				focused: true,
			});
		}
		const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
		const y = e.target.getBoundingClientRect().bottom;
		const isCloseToEdge = h - y <= (this.props.edgeOffset ? this.props.edgeOffset : 95);
		if (this.props.showDelay) {
			this.showTimeout = setTimeout(
				() => this.setState({showTooltip: true, defaultTriangleLocation: isCloseToEdge ? 'bottomMiddle' : 'topMiddle'}),
				this.props.showDelay
			);
		} else {
			this.setState({showTooltip: true, defaultTriangleLocation: isCloseToEdge ? 'bottomMiddle' : 'topMiddle'});
		}
		if (!this.props.tooltipInfinteDuration) {
			this.durationTimeout = setTimeout(
				() => this.setState({showTooltip: false}),
				this.props.tooltipDuration != null ? this.props.tooltipDuration : 3000
			);
		}
	}

	closeTooltip(e) {
		if (this.state.focused) {
			this.setState({
				focused: false,
			});
		}
		if (this.showTimeout !== null) {
			clearTimeout(this.showTimeout);
			this.showTimeout = null;
		}
		if (this.durationTimeout !== null) {
			this.durationTimeout = null;
		}
		if (this.state.showTooltip) {
			this.setState({showTooltip: false});
		}
		this.setState({locked: false});
	}

	lockTooltip() {
		this.setState({locked: true});
	}

	getTooltipPosition() {
		if (!this.state.canCalculateTooltipPosition || !this.tooltip_container_icon || !this.tooltipComponent) {
			return null;
		}
		const iconBoundingRect = this.tooltip_container_icon.getBoundingClientRect();
		const position = {top: null, bottom: null, left: null, right: null, oneRowTooltip: false};
		let componentWidth = 0,
			componentHeight = 0;
		if (this.tooltipComponent && this.tooltipComponent.current && this.tooltipComponent.current.component) {
			componentWidth = this.tooltipComponent.current.component.clientWidth;
			componentHeight = this.tooltipComponent.current.component.clientHeight;
		}

		//overuling everything else to fit the expected behavihour
		//used on the week cell on Timesheet when the full week is locked
		if (this.props.tooltipPosition === 'right') {
			position.top = iconBoundingRect.y + iconBoundingRect.height / 2 - 16;
			position.left = iconBoundingRect.x + iconBoundingRect.width + 13;
			return position;
		}

		if (this.props.tooltipPosition === 'bottom') {
			position.top = iconBoundingRect.y + iconBoundingRect.height + 10;
			position.left = iconBoundingRect.x - (componentWidth - iconBoundingRect.width) / 2; // - componentWidth / 2;
			return position;
		}

		//this is really bad and it does not make sens, but I can't change it without making sure to not messed up anything else
		const oneRowTooltip = componentHeight !== 0 && componentHeight < 40;
		position.oneRowTooltip = oneRowTooltip;

		if (oneRowTooltip) {
			//tooltip with one line of text. Display above or under and in the middle of element
			if (window.innerHeight <= iconBoundingRect.y + componentHeight) {
				position.bottom = window.innerHeight - iconBoundingRect.y + 45;
			} else {
				position.top = iconBoundingRect.y + iconBoundingRect.height + 10;
			}
			position.left = iconBoundingRect.x - (componentWidth - iconBoundingRect.width) / 2; // - componentWidth / 2;
		} else {
			const canbeMiddle = this.props.canBeMiddle ? this.props.canBeMiddle : false;
			if (window.innerHeight <= iconBoundingRect.y + componentHeight + 30) {
				//should be displayed on top
				if (canbeMiddle) {
					position.bottom = window.innerHeight - iconBoundingRect.y + 14;
				} else {
					position.bottom = window.innerHeight - iconBoundingRect.y - iconBoundingRect.height;
				}
			} else {
				//can be displayed on bottom
				if (canbeMiddle) {
					position.top = iconBoundingRect.y + iconBoundingRect.height + 14;
				} else {
					position.top = iconBoundingRect.y - 8;
				}
			}
			if ((position.bottom !== null || position.top !== null) && canbeMiddle) {
				position.left = iconBoundingRect.x - (componentWidth - iconBoundingRect.width) / 2;
			} else if (window.innerWidth <= iconBoundingRect.x + iconBoundingRect.width + componentWidth + 20) {
				//should be displayed on the left side
				position.right = window.innerWidth - iconBoundingRect.x + 13;
			} else {
				//can be displayed on the right side
				position.left = iconBoundingRect.x + iconBoundingRect.width + 13;
			}
		}
		return position;
	}

	calculateTooltipPosition() {
		this.setState({canCalculateTooltipPosition: true});
	}

	getTriangleLocation(position) {
		if (position === null) {
			return this.props.triangleLocation || this.state.defaultTriangleLocation;
		} else if (position.oneRowTooltip) {
			if (position.bottom) {
				return 'bottomMiddle';
			}
			return 'topMiddle';
		} else if (position.bottom !== null) {
			if (this.props.canBeMiddle) {
				return 'bottomMiddle';
			} else if (position.left !== null) {
				return 'lowerLeft';
			}
			return 'lowerRight';
		} else if (position.left !== null) {
			if (this.props.canBeMiddle) {
				return 'topMiddle';
			}
			return 'left';
		}
		return 'right';
	}

	render() {
		const {showTooltip, focused, locked} = this.state;
		const {disabled, infoText, onFocusText} = this.props;

		if (!infoText && !this.props.showOnFocus) {
			return this.props.children;
		}
		const position = this.getTooltipPosition();
		return (
			<ClickAwayListener onClickAway={this.closeTooltip.bind(this)}>
				<span
					ref={e => (this.tooltip_container_icon = e)}
					className={
						'testtest tooltip-container' +
						(this.props.className ? ' ' + this.props.className : '') +
						(focused ? ' focused' : '')
					}
					onFocus={this.onMouseEnter.bind(this)}
					onBlur={this.closeTooltip.bind(this)}
					onMouseEnter={this.props.showOnFocus ? null : this.onMouseEnter.bind(this)}
					onMouseLeave={this.props.showOnFocus || locked ? null : this.closeTooltip.bind(this)}
					onClick={this.props.showOnClick ? this.lockTooltip.bind(this) : null}
					style={this.props.growable ? {display: 'flex'} : {}}
				>
					{this.props.children}
					<Tooltip
						ref={this.tooltipComponent}
						autoPlace={true}
						shown={!disabled && showTooltip}
						grey={true}
						customMaxWidth={this.props.customMaxWidth}
						infoText={infoText}
						onFocusText={onFocusText}
						triangleLocation={this.getTriangleLocation(position)}
						timer={this.props.timer}
						width={this.props.width}
						autoPlaceOffset={this.props.autoPlaceOffset}
						duration={this.props.tooltipDuration}
						infinteDuration={this.props.tooltipInfinteDuration}
						translatedMessage={this.props.translatedMessage}
						top={position === null ? null : position.top}
						left={position === null ? null : position.left}
						bottom={position === null ? null : position.bottom}
						right={position === null ? null : position.right}
						calculateTooltipPosition={this.calculateTooltipPosition.bind(this)}
						noHidden={this.props.noHidden}
						dontShowAgain={this.props.dontShowAgain}
						onTooltipOnClick={this.props.onTooltipOnClick}
					/>
				</span>
			</ClickAwayListener>
		);
	}
}

TooltipContainer.propTypes = {
	infoText: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
	triangleLocation: PropTypes.oneOf(['bottomMiddle', 'topMiddle', 'topRight', 'right', 'left']),
	tooltipPosition: PropTypes.oneOf(['bottom', 'right']),
	trackingOptions: PropTypes.shape({
		helpIcon: PropTypes.string,
		placement: PropTypes.string,
	}),
	timer: PropTypes.number, // prop that sets how long should pass unitl the tooltip is shown
	tooltipDuration: PropTypes.number, // props that sets how long time should the tooltip be shown for after it has appeared,
	translatedMessage: PropTypes.bool, // should be set to true if detail messages you are passing are not language keys but already translated messages
};
