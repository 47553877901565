import { useForecastFetchQuery } from '../../../../shared/hooks/useForecastFetchQuery';
import { CalendarEventsQuery } from './CalendarEventsFetch';
import { useEffect, useMemo, useState } from 'react';
import { getWeek } from 'date-fns';
const createEventWeekMap = (events) => {
    if (events && (events === null || events === void 0 ? void 0 : events.length) > 0) {
        return events.reduce((resultMap, event) => {
            var _a, _b;
            const weekKey = ((_a = event === null || event === void 0 ? void 0 : event.node) === null || _a === void 0 ? void 0 : _a.start) ? getWeek(new Date((_b = event.node) === null || _b === void 0 ? void 0 : _b.start)) : null;
            // Add event to week arrays
            if (weekKey !== null && weekKey !== undefined) {
                (resultMap[weekKey] = resultMap[weekKey] || []).push(event === null || event === void 0 ? void 0 : event.node);
            }
            return resultMap;
        }, {});
    }
    return {};
};
export const useCalendarEvents = (currentViewingDate, currentViewingMonth, showCalendarEvents) => {
    var _a, _b;
    const { fetch: fetchCalendarEvents, data: calendarEventsData } = useForecastFetchQuery(CalendarEventsQuery, true);
    const eventWeekMap = useMemo(() => {
        var _a, _b, _c;
        return showCalendarEvents
            ? createEventWeekMap((_c = (_b = (_a = calendarEventsData === null || calendarEventsData === void 0 ? void 0 : calendarEventsData.viewer) === null || _a === void 0 ? void 0 : _a.calendarIntegrations) === null || _b === void 0 ? void 0 : _b.calendarEvents) === null || _c === void 0 ? void 0 : _c.edges)
            : {};
    }, [(_b = (_a = calendarEventsData === null || calendarEventsData === void 0 ? void 0 : calendarEventsData.viewer) === null || _a === void 0 ? void 0 : _a.calendarIntegrations) === null || _b === void 0 ? void 0 : _b.calendarEvents, showCalendarEvents]);
    const [isLoadingCalendarEvents, setLoadingCalendarEvents] = useState(false);
    const startDate = currentViewingMonth.clone().startOf('month').startOf('week').format('YYYY-MM-DD');
    const endDate = currentViewingMonth.clone().endOf('month').endOf('week').format('YYYY-MM-DD');
    useEffect(() => {
        if (showCalendarEvents) {
            setLoadingCalendarEvents(true);
            fetchCalendarEvents({
                start: startDate,
                end: endDate,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            }, () => setLoadingCalendarEvents(false));
        }
    }, [currentViewingMonth === null || currentViewingMonth === void 0 ? void 0 : currentViewingMonth.month(), showCalendarEvents]);
    return {
        calendarEvents: eventWeekMap ? eventWeekMap[currentViewingDate.week()] : [],
        isLoadingCalendarEvents,
    };
};
