import styled from 'styled-components';
import logo from '../../../../images/logo-v2.svg';
import arrow from '../../../../images/integrations/controls/arrows.svg';
import OkSign from '../../../../images/v2/components/multi_select_checked_green.svg';

export const PageWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
`;

export const ParentContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const ContentWrapper = styled(ParentContentWrapper)`
	width: 500px;
`;

export const Title = styled.div`
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 25px;
	text-align: center;
	color: #4c4c58;
	margin-top: 40px;
	margin-bottom: 16px;
`;
export const NormalText = styled.div`
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 16px;
`;

export const GoBackContainer = styled.div`
	display: flex;
	:hover {
		cursor: pointer;
	}
`;
export const GoBackText = styled(NormalText)`
	color: #a5a5ae;
	font-weight: 400;
	margin-left: 10px;
	:hover {
		cursor: pointer;
	}
`;

export const ModalContent = styled(NormalText)`
	color: #535353;
`;

export const ModalTitle = styled.div`
	display: flex;
`;

export const GreenCheckboxMark = styled.div`
	height: 30px;
	width: 30px;
	background: url(${OkSign});
	background-size: 30px;
	background-position: center;
	background-repeat: no-repeat;
	margin-right: 16px;
`;
/* } */
export const Description = styled(NormalText)`
	text-align: center;
	color: #4c4c58;
	a {
		color: #008eff;
		:hover {
			cursor: pointer;
		}
	}
`;

export const FakeHeaderContainer = styled.div`
	display: flex;
	margin-left: 24px;
	margin-top: 24px;
	margin-bottom: 10px;
`;

export const ForecastProbabilityWarningWrapper = styled.div`
	display: flex;
	align-items: center;
`;

export const ForecastProbability = styled.p`
	margin-left: 4px;
`;

export const DuplicateOptions = styled.div`
	margin-top: 16px;
`;

export const RadioButtonsWrapper = styled.div`
	padding: 0 0 24px 0;
`;

export const LogosWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background-size: 70px 61px;
	margin-top: 32px;
`;

export const ForecastLogo = styled.div`
	height: 70px;
	width: 61px;
	background-size: contain;
	background-repeat: no-repeat;
	background-image: url(${logo});
`;

export const ArrowsIcon = styled.div`
	margin: 0 16px;
	width: 45px;
	height: 25px;
	background: url(${arrow}) no-repeat center;
`;

export const ButtonWrapper = styled.div`
	margin-top: 50px;
	margin-bottom: 40px;
	button {
		margin-left: auto;
		margin-right: auto;
	}
`;

export const DropdownWrapper = styled.div`
	width: 250px;
`;

export const RefNumberWrapper = styled.div`
	margin-top: 16px;
	margin-left: 8px;
`;

export const DropdownAddButtonWrapper = styled.div`
	display: flex;
	align-items: center;

	.dropdown-component {
		margin-top: 16px;
	}
	.add-button-component {
		width: 30px;
		height: 30px;
		margin-top: 16px;
		margin-left: 8px;
	}
`;

export const HorizontalFlexWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const TableHeaderWrapper = styled(HorizontalFlexWrapper)`
	justify-content: space-between;
`;

export const SandboxWrapper = styled(HorizontalFlexWrapper)`
	margin-bottom: 8px;
	margin-top: 8px;
	label {
		margin-right: 8px;
	}
`;

export const TextAreaWrapper = styled.div`
	width: 100%;
	min-width: 100%;
	max-width: 100%;
	margin-top: 4px;
	textarea {
		width: 100%;
		min-width: 100%;
		max-width: 100%;
		min-height: 80px;
	}
`;

export const TestStatusWrapper = styled.div`
	margin-right: 4px;
	height: 16px;
	width: 16px;
	div {
		background-size: 32px 32px;
	}
`;
