import Styled from 'styled-components';
import {CSS_CONSTANTS} from '../../../../css_variables';

export const FlexGroup = Styled.div`
    display: flex;
	align-items: center;
	column-gap: 6px;
`;
export const HoverShow = Styled.div`
	visibility: hidden;
	width: 24px;
	height: 24px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	
	&:hover {
	    background-color: #E7E7F3;
	    cursor: pointer;
	}
`;
export const Item = Styled.div`	
	font-size: 11px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 6px 10px 6px 4px;
`;
export const HoverItem = Styled(Item)`	
	&:hover {
        background-color: ${CSS_CONSTANTS.background_ghost_white};
        ${HoverShow} {
			visibility: visible;
        }
    }
`;
export const ItemName = Styled.div`
	font-size: 13px;
	font-weight: 500;
`;
export const ItemDescription = Styled.div`
	color: #545454;
`;
export const ItemWarning = Styled.div`
	color: #FD3027;
`;
export const ItemNote = Styled.div``;
