/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ProjectDropdown_viewer$ref = any;
export type ProjectDropdown_QueryVariables = {|
  connectedProjectId?: ?string,
  programId?: ?string,
|};
export type ProjectDropdown_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: ProjectDropdown_viewer$ref,
  |}
|};
export type ProjectDropdown_Query = {|
  variables: ProjectDropdown_QueryVariables,
  response: ProjectDropdown_QueryResponse,
|};
*/


/*
query ProjectDropdown_Query(
  $connectedProjectId: String
  $programId: ID
) {
  viewer {
    actualPersonId
    component(name: "breadcrumb_project_dropdown")
    ...ProjectDropdown_viewer_GNWOX
    id
  }
}

fragment ProjectDropdown_viewer_GNWOX on Viewer {
  id
  projectGroup(id: $connectedProjectId) {
    companyProjectGroupId
    name
    color
    projects(first: 1000000) {
      edges {
        node {
          id
          name
          companyProjectId
          customProjectId
          ...ProjectIndicatorJS_project
        }
      }
    }
    id
  }
  program(internalId: $programId) {
    id
    projects(first: 1000000) {
      edges {
        node {
          id
          legacyProjectAsSingleProject {
            id
            name
            companyProjectId
            customProjectId
            ...ProjectIndicatorJS_project
          }
        }
      }
    }
  }
}

fragment ProjectIndicatorJS_project on ProjectType {
  name
  companyProjectId
  customProjectId
  projectColor
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connectedProjectId",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "programId",
    "type": "ID"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "breadcrumb_project_dropdown"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"breadcrumb_project_dropdown\")"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000000
  }
],
v6 = [
  (v3/*: any*/),
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "companyProjectId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "customProjectId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "projectColor",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectDropdown_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "args": [
              {
                "kind": "Variable",
                "name": "connectedProjectId",
                "variableName": "connectedProjectId"
              },
              {
                "kind": "Variable",
                "name": "programId",
                "variableName": "programId"
              }
            ],
            "kind": "FragmentSpread",
            "name": "ProjectDropdown_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectDropdown_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "connectedProjectId"
              }
            ],
            "concreteType": "ProjectGroupType",
            "kind": "LinkedField",
            "name": "projectGroup",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "companyProjectGroupId",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "color",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "ProjectTypeConnection",
                "kind": "LinkedField",
                "name": "projects",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "projects(first:1000000)"
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "internalId",
                "variableName": "programId"
              }
            ],
            "concreteType": "ProgramType",
            "kind": "LinkedField",
            "name": "program",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "ProjectServiceProjectTypeConnection",
                "kind": "LinkedField",
                "name": "projects",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectServiceProjectTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectServiceProjectType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProjectType",
                            "kind": "LinkedField",
                            "name": "legacyProjectAsSingleProject",
                            "plural": false,
                            "selections": (v6/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "projects(first:1000000)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "ProjectDropdown_Query",
    "operationKind": "query",
    "text": "query ProjectDropdown_Query(\n  $connectedProjectId: String\n  $programId: ID\n) {\n  viewer {\n    actualPersonId\n    component(name: \"breadcrumb_project_dropdown\")\n    ...ProjectDropdown_viewer_GNWOX\n    id\n  }\n}\n\nfragment ProjectDropdown_viewer_GNWOX on Viewer {\n  id\n  projectGroup(id: $connectedProjectId) {\n    companyProjectGroupId\n    name\n    color\n    projects(first: 1000000) {\n      edges {\n        node {\n          id\n          name\n          companyProjectId\n          customProjectId\n          ...ProjectIndicatorJS_project\n        }\n      }\n    }\n    id\n  }\n  program(internalId: $programId) {\n    id\n    projects(first: 1000000) {\n      edges {\n        node {\n          id\n          legacyProjectAsSingleProject {\n            id\n            name\n            companyProjectId\n            customProjectId\n            ...ProjectIndicatorJS_project\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment ProjectIndicatorJS_project on ProjectType {\n  name\n  companyProjectId\n  customProjectId\n  projectColor\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7b4e07097c87d2a5b60e5974435b06cd';

module.exports = node;
