import React from 'react';
import Styled from 'styled-components';
import {useIntl} from 'react-intl';
import {CSS_CONSTANTS} from '../../../css_variables';

const Label = Styled.div`
  font-size: 10px;
  font-weight: 400;
  text-transform: uppercase;
  color: ${CSS_CONSTANTS.v2_text_light_gray};
`;
const Value = Styled.div`
  margin-bottom: 5px;
`;

const ContactPerson = ({person}) => {
	const {formatMessage} = useIntl();
	const unknown = <i>?</i>;
	return (
		<div>
			<Label>{formatMessage({id: 'common.name'})}</Label>
			<Value>{person.fullName || unknown}</Value>
			<Label>{formatMessage({id: 'common.email'})}</Label>
			<Value>{person.email || unknown}</Value>
			<Label>{formatMessage({id: 'common.title'})}</Label>
			<Value>{person.jobTitle || unknown}</Value>
			<Label>{formatMessage({id: 'common.phone'})}</Label>
			<Value>{person.phone || unknown}</Value>
		</div>
	);
};

export default ContactPerson;
