import {
	DeprecatedColorIndicator as ColorIndicator,
	Icon,
	DeprecatedTable as Table,
	DeprecatedTableCell as TableCell,
	DeprecatedTableHeaderCell as TableHeaderCell,
	DeprecatedTableHeaderRow as TableHeaderRow,
	DeprecatedTableRow as TableRow,
	DeprecatedText as Text,
} from '@forecast-it/design-system';
import React, {useState} from 'react';
import styled from 'styled-components';
import ForecastTooltip, {TooltipColor} from '../../../../../../shared/components/tooltips/ForecastTooltip';
import useSort from '../../../../../../shared/hooks/useSort';
import InteractiveCell from './InteractiveCell';
import StatusCell from './StatusCell';
import {getProjectIdentifier} from '../../../ProgramContextProvider';

const HeaderButtonWrapper = styled.div`
	padding-left: 8px;
`;

const projectScopingUrl = project => `/project/${project.prefix}/scoping`;
const projectNovaInsightsUrl = project => `/project/${project.prefix}/nova-insights#${project.insight.modalHash}`;

const ProjectRowContent = ({project, isStatusTable}) => (
	<>
		<TableCell fixed={'20px'}>{<ColorIndicator color={project.color} />}</TableCell>
		<TableCell fixed={'60px'}>
			<Text variant={'bold'}>{getProjectIdentifier(project)}</Text>
		</TableCell>
		<TableCell size={'m'}>
			<InteractiveCell text={project.name} onSelect={() => window.open(projectScopingUrl(project), '_blank')} />
		</TableCell>
		<TableCell size={isStatusTable ? 'm' : 'l'}>
			{project.insight.ragStatus ? (
				isStatusTable ? (
					<StatusCell
						latestProjectStatus={project.insight.latestProjectStatus}
						ragStatus={project.insight.ragStatus}
						onSelect={() => window.open(projectNovaInsightsUrl(project), '_blank')}
					/>
				) : (
					<InteractiveCell
						text={project.insight.info}
						ragStatus={project.insight.ragStatus}
						onSelect={() => window.open(projectNovaInsightsUrl(project), '_blank')}
					/>
				)
			) : (
				<ForecastTooltip content={project.insight.lockText} color={TooltipColor.PURPLE}>
					<Icon icon={'lockClosed'} size={'l'} />
				</ForecastTooltip>
			)}
		</TableCell>
	</>
);

const ProjectRow = ({project, isStatusTable}) => (
	<TableRow key={project.id} data={project}>
		<ProjectRowContent project={project} isStatusTable={isStatusTable} />
	</TableRow>
);

const sortProjects = (projects, _sort, sortValue) => {
	return projects.sort((p1, p2) => _sort.sort(sortValue, p1, p2));
};

const createProjectTable = (projects, isStatusTable, _sort, sortValue) => {
	const sortedProjects = sortProjects(projects, _sort, sortValue);
	return sortedProjects.map((project, index) => (
		<ProjectRow project={project} key={project.id} isStatusTable={isStatusTable} />
	));
};

const ProjectInsightTable = ({tableProjects, isStatusTable = false}) => {
	const _sort = useSort();
	const [sortValue, setSortValue] = useState({key: 'insight.ragStatusIndex', direction: 1});

	return (
		<Table>
			<TableHeaderRow>
				<TableHeaderCell fixed={'20px'} />
				<TableHeaderCell
					isSorted={_sort.isSortedColumn('prefix', sortValue)}
					direction={_sort.getDirection(sortValue)}
					onClick={() => _sort.updateSort('prefix', sortValue, setSortValue)}
					fixed={'60px'}
				>
					ID
				</TableHeaderCell>
				<TableHeaderCell
					isSorted={_sort.isSortedColumn('name', sortValue)}
					direction={_sort.getDirection(sortValue)}
					onClick={() => _sort.updateSort('name', sortValue, setSortValue)}
					size={'m'}
				>
					<HeaderButtonWrapper>Name</HeaderButtonWrapper>
				</TableHeaderCell>
				<TableHeaderCell
					isSorted={_sort.isSortedColumn('insight.ragStatusIndex', sortValue)}
					direction={_sort.getDirection(sortValue)}
					onClick={() => _sort.updateSort('insight.ragStatusIndex', sortValue, setSortValue)}
					size={isStatusTable ? 'm' : 'l'}
				>
					<HeaderButtonWrapper>Insight</HeaderButtonWrapper>
				</TableHeaderCell>
			</TableHeaderRow>
			{createProjectTable(tableProjects, isStatusTable, _sort, sortValue)}
		</Table>
	);
};

export default ProjectInsightTable;
