/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProgramNovaInsights_viewer$ref: FragmentReference;
declare export opaque type ProgramNovaInsights_viewer$fragmentType: ProgramNovaInsights_viewer$ref;
export type ProgramNovaInsights_viewer = {|
  +id: string,
  +program: ?{|
    +id: string,
    +name: ?string,
    +color: ?string,
    +projectHealthRollup: ?{|
      +suggestedStatusDisagreesRag: ?string,
      +suggestedStatusDisagreesMessage: ?string,
      +predictedOverrunRag: ?string,
      +predictedOverrunMessage: ?string,
      +budgetOverrunRag: ?string,
      +budgetOverrunMessage: ?string,
      +projectHealthResponses: ?$ReadOnlyArray<?{|
        +projectId: ?number,
        +predictedEndDateRAGStatus: ?string,
        +predictedEndDateInfo: ?string,
        +predictedEndDateError: ?string,
        +budgetInsightRag: ?string,
        +budgetInsightInfoShort: ?string,
        +budgetInsightError: ?string,
        +suggestedStatusRag: ?string,
      |}>,
    |},
  |},
  +$refType: ProgramNovaInsights_viewer$ref,
|};
export type ProgramNovaInsights_viewer$data = ProgramNovaInsights_viewer;
export type ProgramNovaInsights_viewer$key = {
  +$data?: ProgramNovaInsights_viewer$data,
  +$fragmentRefs: ProgramNovaInsights_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "prefix",
      "type": "String"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProgramNovaInsights_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "prefix",
          "variableName": "prefix"
        }
      ],
      "concreteType": "ProgramType",
      "kind": "LinkedField",
      "name": "program",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "color",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectHealthRollup",
          "kind": "LinkedField",
          "name": "projectHealthRollup",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "suggestedStatusDisagreesRag",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "suggestedStatusDisagreesMessage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "predictedOverrunRag",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "predictedOverrunMessage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "budgetOverrunRag",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "budgetOverrunMessage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "MultiProjectHealth",
              "kind": "LinkedField",
              "name": "projectHealthResponses",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "projectId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "predictedEndDateRAGStatus",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "predictedEndDateInfo",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "predictedEndDateError",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "budgetInsightRag",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "budgetInsightInfoShort",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "budgetInsightError",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "suggestedStatusRag",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '7170c6f2b66254d6ee308669707110ba';

module.exports = node;
