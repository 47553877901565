import React from 'react';
import Styled from 'styled-components';
import {AssigneeGroup} from 'web-components';
import {GrowableColumn} from '../Elements.styled';
import UtilizationGauge from '../../../../person/UtilizationGauge';
import ForecastTooltip from '../../../../tooltips/ForecastTooltip';
import {useIntl} from 'react-intl';
import {getAvailabilityTooltipContent} from '../utils';
import {profilePicSrc} from '../../../../../../../directApi';

/***
 *
 * @param imageId image id for the specific person
 * @param {String} fullName Name of person
 * @param {bool} noPerson Is the person unassigened
 * @param {number} taskAmount the number of tasks with a given person
 * @param availability
 * @param displayUtilizationGauge
 * @param rollUpValues
 * @constructor
 */
export const ImageNameColumn = ({
	imageId,
	fullName,
	noPerson,
	taskAmount,
	availability,
	displayUtilizationGauge,
	rollUpValues,
}) => {
	const intl = useIntl();
	const availabilityInformation = {
		totalAvailability: rollUpValues.minutesAvailable,
		availabilityUntilPhaseEnds: rollUpValues.remainingMinutesAvailable,
		taskRemaining: rollUpValues.rollupRemaining,
		remainingAvailabilityInPhase: rollUpValues.minutesRemaining,
	};
	const utilizationGaugeTooltipContent = getAvailabilityTooltipContent(intl, availabilityInformation);

	return (
		<GrowableColumn>
			<Wrapper>
				{!noPerson && (
					<div style={{marginRight: 8}}>
						{displayUtilizationGauge ? (
							<ForecastTooltip content={utilizationGaugeTooltipContent} placement={'top'} maxWidth={600}>
								<UtilizationGauge
									fullName={fullName}
									imageSource={profilePicSrc(imageId)}
									utilization={availability}
								/>
							</ForecastTooltip>
						) : (
							<AssigneeGroup
								showCount={1}
								assignees={[
									{
										fullName,
										imageSource: profilePicSrc(imageId),
									},
								]}
							/>
						)}
					</div>
				)}
				<PersonName>{fullName}</PersonName>
				{noPerson && <NoPersonTaskAmount>{'(' + taskAmount + ')'}</NoPersonTaskAmount>}
			</Wrapper>
		</GrowableColumn>
	);
};

export const Wrapper = Styled.div`
    display: flex;
    align-items: center;
`;

export const NoPersonTaskAmount = Styled.div`
	margin-left: 4px; 
	font-weight: 300;
`;

const PersonName = Styled.div`
	font-size: 13px;
	font-weight: 500;
`;
