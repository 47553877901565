/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
query TimeRegistrationModalCreate_Query(
  $companyTaskId: String
  $companyProjectId: String
) {
  viewer {
    actualPersonId
    component(name: "time_registration_modal_create")
    ...TimeRegistrationModalCreate_viewer_Zg8xU
    id
  }
}

fragment TimeRegistrationModalCreate_viewer_Zg8xU on Viewer {
  actualPersonId
  task(companyTaskId: $companyTaskId) {
    id
    project {
      id
    }
  }
  project(id: $companyProjectId) {
    id
  }
}
*/
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "companyTaskId",
            "type": "String"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "companyProjectId",
            "type": "String"
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actualPersonId",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": [
            {
                "kind": "Literal",
                "name": "name",
                "value": "time_registration_modal_create"
            }
        ],
        "kind": "ScalarField",
        "name": "component",
        "storageKey": "component(name:\"time_registration_modal_create\")"
    }, v3 = {
        "kind": "Variable",
        "name": "companyTaskId",
        "variableName": "companyTaskId"
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v5 = [
        (v4 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "TimeRegistrationModalCreate_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "companyProjectId",
                                    "variableName": "companyProjectId"
                                },
                                (v3 /*: any*/)
                            ],
                            "kind": "FragmentSpread",
                            "name": "TimeRegistrationModalCreate_viewer"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "TimeRegistrationModalCreate_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": [
                                (v3 /*: any*/)
                            ],
                            "concreteType": "Task",
                            "kind": "LinkedField",
                            "name": "task",
                            "plural": false,
                            "selections": [
                                (v4 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ProjectType",
                                    "kind": "LinkedField",
                                    "name": "project",
                                    "plural": false,
                                    "selections": (v5 /*: any*/),
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "id",
                                    "variableName": "companyProjectId"
                                }
                            ],
                            "concreteType": "ProjectType",
                            "kind": "LinkedField",
                            "name": "project",
                            "plural": false,
                            "selections": (v5 /*: any*/),
                            "storageKey": null
                        },
                        (v4 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "TimeRegistrationModalCreate_Query",
            "operationKind": "query",
            "text": "query TimeRegistrationModalCreate_Query(\n  $companyTaskId: String\n  $companyProjectId: String\n) {\n  viewer {\n    actualPersonId\n    component(name: \"time_registration_modal_create\")\n    ...TimeRegistrationModalCreate_viewer_Zg8xU\n    id\n  }\n}\n\nfragment TimeRegistrationModalCreate_viewer_Zg8xU on Viewer {\n  actualPersonId\n  task(companyTaskId: $companyTaskId) {\n    id\n    project {\n      id\n    }\n  }\n  project(id: $companyProjectId) {\n    id\n  }\n}\n"
        }
    };
})();
node.hash = '85cfb2218e57faa1e63351269f90f967';
export default node;
