import React from 'react';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { theme as webComponentsTheme } from 'web-components';
import { tokensForThemeProvider, ForecastTheme } from '@forecast-it/design-system';
import { hasModule } from './util/ModuleUtil';
import { MODULE_TYPES } from '../../constants';
import { isSageSpecificUrl } from './util/UrlUtil';
const getThemeName = () => {
    if (hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED)) {
        return ForecastTheme.Sage;
    }
    if (isSageSpecificUrl()) {
        return ForecastTheme.Sage;
    }
    return ForecastTheme.Sky;
};
export const ForecastThemeProvider = ({ children }) => {
    const themeName = getThemeName();
    const combinedTheme = Object.assign(Object.assign({ themeName }, webComponentsTheme), tokensForThemeProvider[themeName]);
    return React.createElement(ThemeProvider, { theme: combinedTheme }, children);
};
ForecastThemeProvider.propTypes = {
    children: PropTypes.any,
};
