import React from 'react';
import { getFormattedName, getLinkFromVariant, processTextToHTML } from './FormatterUtil';
const getFormattedTaskHighlightField = highlightField => {
    switch (highlightField) {
        case 'name': // Name will be shown in the Title field, instead show Task ID in description
        case 'name.shingled_bigram':
        case 'company_task_id':
            return 'Task ID: ';
        case 'assignee_names':
            return 'Task assigned to: ';
        case 'role_name':
            return 'Task with role: ';
        case 'comments':
            return 'Task with comment: ';
        case 'task_fallback':
        case 'project_name':
        case 'company_project_id':
        case 'custom_project_id':
            return 'Task in project: ';
        default:
            return `Task with ${highlightField}: `;
    }
};
const getFormattedTaskHighlightValue = (highlightField, highlightValue, companyTaskId, companyProjectId, projectName) => {
    switch (highlightField) {
        case 'name':
        case 'name.shingled_bigram':
            return `T${companyTaskId}`;
        case 'company_task_id':
            return React.createElement("em", null, `T${companyTaskId}`);
        case 'company_project_id':
            return React.createElement("em", null, `P${companyProjectId}`);
        case 'task_fallback':
            return `${projectName}`;
        // HTML without quotes case
        case 'custom_project_id':
            return React.createElement("span", null, processTextToHTML(highlightValue));
        default:
            return React.createElement("span", null,
                "\"",
                processTextToHTML(highlightValue),
                "\"");
    }
};
const getFormattedTaskHighlight = (highlight, companyTaskId, companyProjectId, projectName) => {
    // Default to company_task_id_fallback if highlight is undefined
    const highlightField = highlight ? highlight.highlightField : 'task_fallback';
    const highlightValue = highlight ? highlight.highlightValue : '';
    const formattedHighlightField = getFormattedTaskHighlightField(highlightField);
    const formattedHighlightValue = getFormattedTaskHighlightValue(highlightField, highlightValue, companyTaskId, companyProjectId, projectName);
    return {
        highlightField: formattedHighlightField,
        highlightValue: formattedHighlightValue,
    };
};
export const taskFormatter = ({ searchResult, trackAndHideSearch }) => {
    const onSelect = (rank, openedInNewTab) => {
        trackAndHideSearch({ id: searchResult.id, variant: searchResult.variant, rank, searchResult }, !openedInNewTab);
    };
    const formattedName = getFormattedName(searchResult.name, searchResult.highlight);
    const formattedHighlight = getFormattedTaskHighlight(searchResult.noNameHighlight, searchResult.companyTaskId, searchResult.companyProjectId, searchResult.projectName);
    const link = getLinkFromVariant(searchResult.companyTaskId, searchResult.customProjectId, searchResult.variant);
    return {
        title: `${searchResult.name}; In project: ${searchResult.projectName}`,
        name: formattedName,
        highlight: formattedHighlight,
        variant: searchResult.variant,
        companyProjectId: searchResult.companyProjectId,
        customProjectId: searchResult.customProjectId,
        projectColor: searchResult.projectColor,
        projectIndicatorName: searchResult.projectName,
        companyTaskId: searchResult.companyTaskId,
        ariaLabel: `Search result. Type: Task. Title: ${searchResult.name}. Highlight: ${formattedHighlight.highlightField}: ${formattedHighlight.highlightValue}`,
        link,
        onSelect,
    };
};
