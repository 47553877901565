import React, {Component} from 'react';
import {ClickAwayListener} from '@material-ui/core';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';
import {TRACKING_OBJECTS} from '../../../../tracking/amplitude/constants/TrackingObjects';
import {toProperCase} from './../../../../tracking/amplitude/TrackingV2';

class ContextMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			focusAtIndex: 0,
			top: props.contextMenuPosition && props.contextMenuPosition.y,
			left: props.contextMenuPosition && props.contextMenuPosition.x,
		};
		this.menu = React.createRef();
		this.closeContextMenu = this.props.closeContextMenu;
	}
	componentDidMount() {
		trackEvent(TRACKING_OBJECTS.CONTEXT_MENU, 'Expanded');
		if (this.option_0) {
			this.option_0.focus();
		}
		const {width, height, left, top} = this.menu.current.getBoundingClientRect();
		const {innerHeight, innerWidth} = window;

		if (top + height > innerHeight) {
			const newTopValue = this.state.top - height;
			this.setState({top: newTopValue});
		}

		if (left + width > innerWidth) {
			const newLeftValue = this.state.left - width;
			this.setState({left: newLeftValue});
		}
		document.addEventListener('wheel', this.closeContextMenu);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.focusAtIndex !== this.state.focusAtIndex && this['option_' + this.state.focusAtIndex]) {
			this['option_' + this.state.focusAtIndex].focus();
		}
		if (
			this.props.contextMenuPosition?.x &&
			this.props.contextMenuPosition?.y &&
			(prevProps.contextMenuPosition?.x !== this.props.contextMenuPosition?.x ||
				prevProps.contextMenuPosition?.y !== this.props.contextMenuPosition?.y)
		) {
			this.setState({top: this.props.contextMenuPosition.y, left: this.props.contextMenuPosition.x});
			this.option_0.focus();
		}
	}

	componentWillUnmount() {
		document.removeEventListener('wheel', this.closeContextMenu);
	}
	handleKeyDown(option, e) {
		if (e.keyCode === 27) {
			//escape
			if (this.props.closeContextMenu) {
				e.stopPropagation();
				e.preventDefault();
				this.props.closeContextMenu();
			}
		} else if (e.keyCode === 32 || e.keyCode === 13) {
			//enter or space
			if (option.onClick) {
				e.stopPropagation();
				e.preventDefault();
				const trackingName = option.trackingName ?? option.text;
				trackEvent(`${TRACKING_OBJECTS.CONTEXT_MENU} - ${toProperCase(trackingName)}`, 'Selected With Keyboard');
				option.onClick();
			}
		} else if (e.keyCode === 38) {
			//arrow up
			if (this.state.focusAtIndex !== 0) {
				e.stopPropagation();
				e.preventDefault();
				this.setState({focusAtIndex: this.state.focusAtIndex - 1});
			} else {
				e.stopPropagation();
				e.preventDefault();
				this.setState({focusAtIndex: this.props.options.length - 1});
			}
		} else if (e.keyCode === 40) {
			//arrow down
			if (this.state.focusAtIndex !== this.props.options.length - 1) {
				e.stopPropagation();
				e.preventDefault();
				this.setState({focusAtIndex: this.state.focusAtIndex + 1});
			} else {
				e.stopPropagation();
				e.preventDefault();
				this.setState({focusAtIndex: 0});
			}
		}
	}

	onOptionClicked = option => {
		const trackingName = option.trackingName ?? option.text;
		trackEvent(`${TRACKING_OBJECTS.CONTEXT_MENU} - ${toProperCase(trackingName)}`, 'Clicked');
		option.onClick(this);
		if (this.props.closeContextMenu) {
			this.props.closeContextMenu();
		}
	};

	render() {
		return (
			<ClickAwayListener onClickAway={this.props.closeContextMenu || (() => undefined)}>
				<ul
					className={'context-menu-v2' + (this.props.newStyle ? ' new-style' : '')}
					ref={this.menu}
					data-cy={this.props.cy}
					style={{position: 'fixed', top: this.state.top, left: this.state.left}}
					role="menu"
				>
					{this.props.options.map((option, index) =>
						option.icon === 'download' || option.icon === 'open' ? (
							<li data-cy={option.cy} className="context-menu-option download-option" key={index} role="menuitem">
								<a
									className="file-download"
									//for some reason when '?123 is added after file id it downloads file with correct filename otherwise filename is ignored'
									href={option.link ? option.link : option.fileSrc}
									target={option.link ? '_blank' : ''}
									ref={el => {
										this['option_' + index] = el;
									}}
									onKeyDown={this.handleKeyDown.bind(this, option)}
									onClick={option.onClick ? option.onClick.bind(this) : null}
									onMouseEnter={() => (option.onMouseEnter ? option.onMouseEnter() : null)}
									onMouseLeave={() => (option.onMouseLeave ? option.onMouseLeave() : null)}
								>
									<div id="context-menu-option" className={'option-icon ' + option.icon} />
									<span id="context-menu-option">{option.text}</span>
								</a>
							</li>
						) : (
							<li
								data-cy={option.cy}
								title={option.disabled ? option.disabledDescription : option.description}
								className={
									'context-menu-option' +
									(option.disabled ? ' disabled' : '') +
									(option.bottomOption ? ' bottom-option' : '') +
									(option.selected ? ' selected' : '')
								}
								key={index}
								id="context-menu-option"
								onClick={option.disabled ? undefined : this.onOptionClicked.bind(this, option)}
								tabIndex={0}
								ref={el => {
									this['option_' + index] = el;
								}}
								onKeyDown={this.handleKeyDown.bind(this, option)}
								role="menuitem"
								onMouseEnter={() => (option.onMouseEnter ? option.onMouseEnter() : null)}
								onMouseLeave={() => (option.onMouseLeave ? option.onMouseLeave() : null)}
							>
								<div id="context-menu-option" className={'option-icon ' + option.icon} />
								<span
									id="context-menu-option"
									style={{
										color: option.textColor || undefined,
									}}
								>
									{option.text}
								</span>
							</li>
						)
					)}
				</ul>
			</ClickAwayListener>
		);
	}
}
export default ContextMenu;
