import { PROGRAM_BUDGET_TYPE } from '../../../../../../../constants';
import { TRACKING_OBJECTS } from '../../../../../../../tracking/amplitude/constants/TrackingObjects';
import { trackEvent } from '../../../../../../../tracking/amplitude/TrackingV2';
import { notNullishAndPredicate } from '../../../../../util/NotNullPredicate';
import { canLoggedInPersonManageProgram, hasTopDownProgramBudgetFeature } from '../../../../../util/ProgramFinancialLogic';
import { isTimeRegistrationNoteInvalid } from '../../../../../util/time-registration/time-registration-settings/TimeRegistrationNoteFilter';
import { getTaskTimeRegistrationNotAllowedWithReason } from '../../../../../util/time-registration/time-registration-settings/TimeRegistrationTaskFilter';
import { MODAL_TYPE, showModal } from '../../../generic_modal_conductor';
import { ACTION } from '../../../program-management/ProgramBudgetErrorMessage';
import { TimeRegistrationEntity } from '../types/TimeRegistrationEntityId';
import { getEntityProject, isTargetEntityBillable } from '../util/TimeRegistrationModalUtil';
function showProgramRevenueLockedMessage({ programName, programPrefix, programMembers, companyProjectId, actualPersonId, }) {
    const canManageProgram = canLoggedInPersonManageProgram(programMembers, actualPersonId);
    showModal({
        type: MODAL_TYPE.PROGRAM_BUDGET_ERROR_MESSAGE,
        action: ACTION.ADD_TIME_ENTRY,
        programName,
        canManageProgram,
        programPrefix,
        companyProjectId,
        actualPersonId,
    });
    trackEvent('Program Budget Error Message Modal', 'Shown');
}
function entityInProgramOverRevenueCap({ targetEntity, actualPersonId, time, billableTime, }) {
    const hasTopDownProgramBudgetFeatureFlag = hasTopDownProgramBudgetFeature();
    const entityProject = getEntityProject(targetEntity);
    const billableMinutesRegistered = isTargetEntityBillable(targetEntity) ? billableTime : undefined;
    const registrationIsNonBillable = billableMinutesRegistered === undefined ||
        billableMinutesRegistered === 0 ||
        (billableMinutesRegistered === null && time === 0);
    if (entityProject && entityProject.program && hasTopDownProgramBudgetFeatureFlag) {
        const projectIsInOverRevenueCapProgram = entityProject.isProgramRevenueLocked &&
            !registrationIsNonBillable &&
            entityProject.programBudgetType === PROGRAM_BUDGET_TYPE.CAPPED;
        if (projectIsInOverRevenueCapProgram) {
            showProgramRevenueLockedMessage({
                programName: entityProject.program.name,
                programPrefix: entityProject.program.prefix,
                programMembers: entityProject.program.members,
                companyProjectId: entityProject.companyProjectId,
                actualPersonId,
            });
            return true;
        }
    }
    return false;
}
export var ModalInvalidInputReason;
(function (ModalInvalidInputReason) {
    ModalInvalidInputReason["InvalidNote"] = "InvalidNote";
    ModalInvalidInputReason["InvalidTime"] = "InvalidTime";
    ModalInvalidInputReason["InvalidEntity"] = "InvalidEntity";
})(ModalInvalidInputReason || (ModalInvalidInputReason = {}));
export function isModalInputInvalid({ targetEntity, actualPersonId, time, billableTime, note, }) {
    const invalidReasons = [];
    if (time === null || isNaN(time)) {
        trackEvent(TRACKING_OBJECTS.TIME_REGISTRATION_MODAL, 'Submit Failed', { reason: 'Time Invalid' });
        invalidReasons.push(ModalInvalidInputReason.InvalidTime);
    }
    if (isTimeRegistrationNoteInvalid(note)) {
        trackEvent(TRACKING_OBJECTS.TIME_REGISTRATION_MODAL, 'Submit Failed', { reason: 'Note Invalid' });
        invalidReasons.push(ModalInvalidInputReason.InvalidNote);
    }
    if (entityInProgramOverRevenueCap({ targetEntity, actualPersonId, time, billableTime })) {
        trackEvent(TRACKING_OBJECTS.TIME_REGISTRATION_MODAL, 'Submit Failed', {
            reason: 'Program Revenue Cap Exceeded',
        });
        invalidReasons.push(ModalInvalidInputReason.InvalidEntity);
    }
    return invalidReasons;
}
export function validateTargetEntity({ targetEntity, personId, shouldValidateEntity, showError, }) {
    var _a;
    if (personId && shouldValidateEntity) {
        if ((targetEntity === null || targetEntity === void 0 ? void 0 : targetEntity.entity) === TimeRegistrationEntity.Task) {
            const statusCategory = targetEntity.statusColumnV2 ? targetEntity.statusColumnV2.category : null;
            const assignedPersonIds = targetEntity.assignedPersons
                ? targetEntity.assignedPersons.filter(notNullishAndPredicate()).map(person => person.id)
                : [];
            const timeRegistrationNotAllowedReason = getTaskTimeRegistrationNotAllowedWithReason(statusCategory, personId, assignedPersonIds);
            if (timeRegistrationNotAllowedReason) {
                return {
                    targetEntity: null,
                    targetEntityError: showError
                        ? { taskId: targetEntity.companyTaskId, reason: timeRegistrationNotAllowedReason }
                        : null,
                };
            }
            if (!((_a = targetEntity === null || targetEntity === void 0 ? void 0 : targetEntity.project) === null || _a === void 0 ? void 0 : _a.fullAccessToProject) || targetEntity.project.status === 'DONE') {
                // No access to task's project or project DONE
                return { targetEntity: null, targetEntityError: null };
            }
        }
        else if ((targetEntity === null || targetEntity === void 0 ? void 0 : targetEntity.entity) === TimeRegistrationEntity.Project) {
            if (!targetEntity.fullAccessToProject || targetEntity.status === 'DONE') {
                // No access to project or project DONE
                return { targetEntity: null, targetEntityError: null };
            }
        }
    }
    return { targetEntity, targetEntityError: null };
}
