import React from 'react';

const nonBillableIcon = props => {

	const fill = props.fill ?
		props.fill :
		props.active ? '#6e0fea' : '#cecece';

	return (
		<span tabIndex="0" onKeyDown={props.onKeyDown} title={props.title} data-cy={props.cy}>
			<svg onClick={props.onClick ? props.onClick : null} className={'nonBillable-icon ' + (props.className ? props.className : '')} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14 16">
				<g fill="none" fillRule="evenodd">
					<path
						data-cy={props.cy ? props.cy + '-path' : ''}
						fill={fill}
						fillRule="nonzero"
						d="M7.44 3.467c1.57 0 2.152.755 2.205 1.866h1.948c-.062-1.529-.987-2.933-2.83-3.386V0H6.118v1.92a5.199 5.199 0 0 0-1.304.48L6.11 3.707c.361-.151.802-.24 1.33-.24zM1.12.942L0 2.072l3.033 3.057c0 1.849 1.375 2.853 3.447 3.475l3.094 3.12c-.3.427-.925.81-2.133.81-1.816 0-2.53-.818-2.627-1.867h-1.94c.106 1.946 1.552 3.04 3.244 3.404V16h2.645v-1.911c.847-.16 1.605-.489 2.16-.996l1.957 1.974 1.12-1.13L1.12.943z"
					/>
				</g>
			</svg>
		</span>
	);
}

export default nonBillableIcon;
