import React from 'react';

const AddSubtaskIcon = props => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="24" height="24" rx="4" fill="white" />
		<rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#E6E6E6" />
		<g clipPath="url(#addsubtaskicon)">
			<path d="M5 17C5 17.2652 5.10536 17.5196 5.29289 17.7071C5.48043 17.8946 5.73478 18 6 18H7" stroke="#4C4C58" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M5 10H8" stroke="#4C4C58" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M15 6.5H19" stroke="#4C4C58" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M5 7.5V17" stroke="#4C4C58" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M17 4.5V8.5" stroke="#4C4C58" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M12 18H6.5" stroke="#4C4C58" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
			<circle cx="10.5" cy="10" r="1.9" stroke="#4C4C58" strokeWidth="1.2" />
			<circle cx="14.5" cy="18" r="1.9" stroke="#4C4C58" strokeWidth="1.2" />
		</g>
		<defs>
			<clipPath id="addsubtaskicon">
				<rect width="17" height="17" fill="white" transform="translate(3.5 3.5)" />
			</clipPath>
		</defs>
	</svg>
);

export default AddSubtaskIcon;
