import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import * as tracking from '../../../tracking';
import CustomScrollDiv from '../../shared/components/scroll-bars/custom_scroll_div';
import SettingsApiKeysLineItem from './settingsApiKeysLineItem';
import CreateApiKeyMutation from '../../../mutations/create_api_key_mutation';
import Util from '../../shared/util/util';
import {BUTTON_COLOR, BUTTON_STYLE, ELEMENT_TYPE} from '../../../constants';
import {buildHeaderBar} from '../../shared/components/headers/header-bar/header_bar';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../shared/components/headers/top-header-bar/TopHeaderBar';
import {trackPage, unregisterPageInfo} from '../../../tracking/amplitude/TrackingV2';

class settingsApiKeys extends Component {
	constructor(props) {
		super(props);

		this.superPropertyChecksum = trackPage('Settings API Keys');
	}

	componentDidMount() {
		document.title = 'Api Keys - Account - Forecast';
		// Segment
		tracking.trackPage('settings-apikeys');
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	createApiKey() {
		Util.CommitMutation(CreateApiKeyMutation, this.props.viewer.company.id, null, true);
	}

	getTopHeader() {
		return (
			<TopHeaderBarWrapper>
				<TopHeaderBar title={this.props.intl.formatMessage({id: 'settings.api_keys.title'})} />
			</TopHeaderBarWrapper>
		);
	}

	getHeader() {
		const rightContent = [
			{
				type: ELEMENT_TYPE.BUTTON,
				text: this.props.intl.formatMessage({id: 'settings.api_keys.new_button'}),
				callback: this.createApiKey.bind(this),
				style: BUTTON_STYLE.OUTLINE,
				color: BUTTON_COLOR.PURPLE,
			},
		];

		return buildHeaderBar([], rightContent);
	}

	render() {
		const {formatMessage} = this.props.intl;

		return (
			<div className="section-content settings-app settings-api-keys" data-cy={'settings-api-keys-page'}>
				{this.props.children}
				{this.getTopHeader()}
				{this.getHeader()}
				<CustomScrollDiv>
					<div className="section-body">
						<div className="inner">
							<fieldset className="api-keys">
								<div className="top-container">
									<p className="description">{formatMessage({id: 'settings.api_keys.description'})}</p>
								</div>
								<table className="api-key-table">
									<thead>
										<tr>
											<th>{formatMessage({id: 'settings.api_keys.table_header.name'})}</th>
											<th>{formatMessage({id: 'settings.api_keys.table_header.key'})}</th>
											<th>{formatMessage({id: 'settings.api_keys.table_header.created_by'})}</th>
											<th>{formatMessage({id: 'settings.api_keys.table_header.date_created'})}</th>
											<th>{formatMessage({id: 'settings.api_keys.table_header.active'})}</th>
											<th>&nbsp;</th>
										</tr>
									</thead>

									<tbody>
										{this.props.viewer.company.apiKeys.edges.map((edge, index) => (
											<SettingsApiKeysLineItem
												key={'apiKey-' + index}
												apiKey={edge.node}
												company={this.props.viewer.company}
											/>
										))}
									</tbody>
								</table>
							</fieldset>
						</div>
					</div>
				</CustomScrollDiv>
			</div>
		);
	}
}

const settingsApiKeysQuery = graphql`
	query settingsApiKeys_Query {
		viewer {
			actualPersonId
			component(name: "settings_api_keys")
			...settingsApiKeys_viewer
		}
	}
`;

// Temporary solution, should belong to child component
graphql`
	fragment settingsApiKeys_apiKey on ApiKey {
		id
		name
		key
		active
		createdAt
		createdBy {
			id
			firstName
			lastName
		}
	}
`;

export {settingsApiKeysQuery};

export default injectIntl(
	createFragmentContainer(settingsApiKeys, {
		viewer: graphql`
			fragment settingsApiKeys_viewer on Viewer {
				id
				availableFeatureFlags {
					key
				}
				company {
					id
					apiKeys(first: 1000) @connection(key: "Company_apiKeys") {
						edges {
							node {
								id
								...settingsApiKeys_apiKey @relay(mask: false)
							}
						}
					}
				}
			}
		`,
	})
);
