const useDate = () => {
	const formatDate = date => {
		const _date = new Date(date);
		const options = {year: '2-digit', month: 'short', day: '2-digit'};

		return new Intl.DateTimeFormat(undefined, options).format(
			new Date(_date.getUTCFullYear(), _date.getUTCMonth(), _date.getUTCDate())
		);
	};

	return {formatDate};
};

export default useDate;
