import React, {useMemo} from 'react';
import ProjectScopingTotalsBox from './ProjectScopingTotalsBox';
import {StickySection} from './ProjectScopingTotals_styled';
import ProjectScopingTotalsLine from './ProjectScopingTotalsLine';
import {useProjectTotals} from './ProjectScopingTotalsContext';

/**
 * This is a wrapper component used for showing the Scoping Header Totals
 * either as a box or as a line, depending on the state of the expansion.
 *
 * @returns {JSX.Element} The Totals Component
 */
const ProjectScopingTotals = () => {
	const {projectData, totalsExpanded} = useProjectTotals();
	const TotalsComponent = useMemo(
		() => (totalsExpanded ? ProjectScopingTotalsBox : ProjectScopingTotalsLine),
		[projectData, totalsExpanded]
	);

	return (
		<StickySection bottomSpacing>
			<TotalsComponent />
		</StickySection>
	);
};

export default ProjectScopingTotals;
