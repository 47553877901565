import React from 'react';
import { Animation } from '@forecast-it/design-system';
import styled from 'styled-components';
const SpinnerWrapper = styled.div `
	display: flex;
	justify-content: center;
	align-items: center;
`;
const AnimationWrapper = styled.div `
	width: 24px;
	height: 24px;
`;
export var SpinnerStyle;
(function (SpinnerStyle) {
    SpinnerStyle["onDark"] = "White";
    SpinnerStyle["onLight"] = "";
})(SpinnerStyle || (SpinnerStyle = {}));
const Spinner = ({ children, spinning, style = SpinnerStyle.onDark }) => {
    return (React.createElement(SpinnerWrapper, null, spinning ? (React.createElement(AnimationWrapper, null,
        React.createElement(Animation, { animation: 'loader' + style }))) : (children)));
};
export default Spinner;
