import React from 'react';
import { useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import { FlexRow, Text } from '@forecast-it/design-system';
import Util from '../../../../../util/util';
import { ESTIMATION_UNIT } from '../../../../../../../constants';
const formatTime = ({ time, estimationUnit, intl }) => {
    if (estimationUnit === ESTIMATION_UNIT.POINTS) {
        return Util.roundPoints(time, intl);
    }
    return Util.convertMinutesToFullHour(time, intl);
};
const convertStoryPointsToMinutes = ({ points, minutesPerEstimationPoint }) => {
    if (minutesPerEstimationPoint > 0) {
        return points / minutesPerEstimationPoint;
    }
    return 0;
};
const convertStoryPointsOrMinutesToMinutes = ({ pointsOrMinutes, minutesPerEstimationPoint, estimationUnit, }) => {
    if (estimationUnit === ESTIMATION_UNIT.POINTS && minutesPerEstimationPoint) {
        return convertStoryPointsToMinutes({ points: pointsOrMinutes, minutesPerEstimationPoint });
    }
    return pointsOrMinutes;
};
/* when editing a time registration, the time left on the task should include the time initially registered
 *  editing a 4 hour timereg on an 8 hour task should show 8 hours remaining, not 4.
 * */
function getTimeRemainingIncludingInitialTime(estimateForecastMinutes, totalMinutesRegistered, initialTime, addInitialTimeToRemaining) {
    if (!estimateForecastMinutes) {
        return 0;
    }
    const time = (!!addInitialTimeToRemaining && initialTime) || 0;
    return estimateForecastMinutes - (totalMinutesRegistered || 0) + time;
}
function TimeRegistrationModalTaskInfo({ task, initialTime, addInitialTimeToRemaining, }) {
    var _a, _b, _c, _d, _e, _f;
    const intl = useIntl();
    const { estimateForecastMinutes, totalMinutesRegistered } = task;
    const convertedRemaining = convertStoryPointsOrMinutesToMinutes({
        pointsOrMinutes: getTimeRemainingIncludingInitialTime(estimateForecastMinutes, totalMinutesRegistered, initialTime, addInitialTimeToRemaining),
        minutesPerEstimationPoint: (_a = task.project) === null || _a === void 0 ? void 0 : _a.minutesPerEstimationPoint,
        estimationUnit: (_b = task.project) === null || _b === void 0 ? void 0 : _b.estimationUnit,
    });
    const convertedEstimate = convertStoryPointsOrMinutesToMinutes({
        pointsOrMinutes: estimateForecastMinutes !== null && estimateForecastMinutes !== void 0 ? estimateForecastMinutes : 0,
        minutesPerEstimationPoint: (_c = task.project) === null || _c === void 0 ? void 0 : _c.minutesPerEstimationPoint,
        estimationUnit: (_d = task.project) === null || _d === void 0 ? void 0 : _d.estimationUnit,
    });
    const formattedTaskEstimate = formatTime({
        time: convertedEstimate,
        estimationUnit: (_e = task.project) === null || _e === void 0 ? void 0 : _e.estimationUnit,
        intl: intl,
    });
    const formattedTaskRemaining = formatTime({
        time: convertedRemaining,
        estimationUnit: (_f = task.project) === null || _f === void 0 ? void 0 : _f.estimationUnit,
        intl: intl,
    });
    return (React.createElement(FlexRow, null,
        React.createElement(Text, { color: "medium", noWrap: true }, `${formattedTaskRemaining} remaining on ${formattedTaskEstimate} task`)));
}
export default createFragmentContainer(TimeRegistrationModalTaskInfo, {
    task: graphql `
		fragment TimeRegistrationModalTaskInfo_task on Task {
			id
			name
			estimateForecastMinutes
			totalMinutesRegistered
			project {
				estimationUnit
				minutesPerEstimationPoint
			}
		}
	`,
});
