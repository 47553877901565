/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type settingsHolidayCalendars_HolidayCalendar$ref: FragmentReference;
declare export opaque type settingsHolidayCalendars_HolidayCalendar$fragmentType: settingsHolidayCalendars_HolidayCalendar$ref;
export type settingsHolidayCalendars_HolidayCalendar = {|
  +id: string,
  +name: ?string,
  +holidayCalendarEntries: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +year: ?number,
        +month: ?number,
        +day: ?number,
      |}
    |}>
  |},
  +$refType: settingsHolidayCalendars_HolidayCalendar$ref,
|};
export type settingsHolidayCalendars_HolidayCalendar$data = settingsHolidayCalendars_HolidayCalendar;
export type settingsHolidayCalendars_HolidayCalendar$key = {
  +$data?: settingsHolidayCalendars_HolidayCalendar$data,
  +$fragmentRefs: settingsHolidayCalendars_HolidayCalendar$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "holidayCalendarEntries"
        ]
      }
    ]
  },
  "name": "settingsHolidayCalendars_HolidayCalendar",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": "holidayCalendarEntries",
      "args": null,
      "concreteType": "HolidayCalendarEntryTypeConnection",
      "kind": "LinkedField",
      "name": "__HolidayCalendar_holidayCalendarEntries_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "HolidayCalendarEntryTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "HolidayCalendarEntry",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "year",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "month",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "day",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "HolidayCalendar"
};
})();
// prettier-ignore
(node/*: any*/).hash = '7d6c7d7341203ab4890e1c82dfde6f51';

module.exports = node;
