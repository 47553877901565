import React, {useCallback, useContext, useMemo} from 'react';
import ProgramContextProvider, {
	ProgramContext,
	ProgramContextProviderQuery,
} from './program-overview-page/ProgramContextProvider';
import SubNavigationContainer from '../../shared/components/navigation/sub-navigation/SubNavigationContainer';
import ForecastQueryRenderer from '../../../ForecastQueryRenderer';
import ProgramOverviewHeader from './shared/ProgramOverviewHeader';
import {DeprecatedPageContainer as PageContainer} from '@forecast-it/design-system';
import {useIntl} from 'react-intl';
import {hasPermission, hasSomePermission} from '../../shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import {useProgram} from './hooks/useProgram';
import {useHistory} from 'react-router-dom';
import ProgramUtil from '../../shared/util/ProgramUtil';
import CompanySetupUtil from '../../shared/util/CompanySetupUtil';

const Program = ({match, activeTab, children}) => {
	const {formatMessage} = useIntl();

	const createHeader = (program, companyId) => subMenu =>
		useMemo(() => <ProgramOverviewHeader program={program} companyId={companyId} subMenu={subMenu} />, [program]);

	const history = useHistory();

	const subTabs = canManage => {
		const tabs = [];

		// Always have the overview tab
		tabs.push({name: formatMessage({id: 'common.overview'}), isActive: path => path.includes('overview'), to: 'overview'});

		// Add the budget tab
		if (
			CompanySetupUtil.hasFinance() &&
			(hasPermission(PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION) ||
				hasPermission(PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE))
		) {
			tabs.push({
				name: 'Financials',
				isActive: path => path.includes('budget') || path.includes('financials'),
				to: 'financials',
			});
		}

		// add program timeline
		tabs.push({
			name: formatMessage({id: 'project_timeline.title'}),
			isActive: path => path.includes('schedule') || path.includes('timeline'),
			to: 'timeline',
		});

		// Add the settings tab
		if (
			hasSomePermission([
				PERMISSION_TYPE.PROGRAMS_CREATE,
				PERMISSION_TYPE.PROGRAMS_UPDATE,
				PERMISSION_TYPE.PROGRAMS_DELETE,
			])
		) {
			tabs.push({
				name: formatMessage({id: 'settings.title'}),
				isActive: path => path.includes('settings'),
				to: canManage ? 'settings/general' : 'settings/team',
			});
		}

		return tabs;
	};

	const ProgramSubComponent = useCallback(() => {
		const programContext = useContext(ProgramContext);

		const program = programContext.program;
		if (program) {
			const companyId = programContext.company.id;
			const actualPersonId = programContext.actualPersonId;

			const header = createHeader(program, companyId);

			const _program = useProgram(program, companyId);
			const canManage = _program.canManage(actualPersonId);

			return (
				<SubNavigationContainer
					headerElement={header}
					tabs={subTabs(canManage)}
					baseLink={`${ProgramUtil.programLink()}/${match.params.prefix}`}
				>
					{children}
				</SubNavigationContainer>
			);
		} else {
			history.replace('/programs');
			return null;
		}
	}, [activeTab]);

	return useMemo(
		() => (
			<PageContainer>
				<ForecastQueryRenderer
					fetchPolicy={'network-only'}
					key={'query-render-programOverview' + match.params.prefix}
					query={ProgramContextProviderQuery}
					variables={{
						prefix: match.params.prefix,
					}}
					authorizeAccessRoute={'program'}
					render={(relayProps, retry) => (
						<ProgramContextProvider {...relayProps} retry={retry}>
							<ProgramSubComponent />
						</ProgramContextProvider>
					)}
				/>
			</PageContainer>
		),
		[children, activeTab]
	);
};

Program.propTypes = {};

Program.defaultProps = {};

export default Program;
