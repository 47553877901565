import React from 'react';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import empty_starred_tasks from '../../../../images/empty-states/empty09_mywork2_anim.svg';
import empty_starred_tasks_bg from '../../../../images/empty-states/empty09_mywork2_bg.svg';
import empty_starred_tasks_bg_small from '../../../../images/empty-states/empty14_mywork_bg.svg';
import empty_starred_tasks_small from '../../../../images/empty-states/empty14_mywork.svg';
import empty_scoping from '../../../../images/empty-states/empty01_scoping1_anim.svg';
import empty_scoping_bg from '../../../../images/empty-states/empty01_scoping1_bg.svg';
import empty_retainer from '../../../../images/empty-states/empty02_retainer_anim.svg';
import empty_retainer_bg from '../../../../images/empty-states/empty02_retainer_bg.svg';
import empty_timesheets from '../../../../images/empty-states/empty03_timesheets_anim.svg';
import empty_timesheets_bg from '../../../../images/empty-states/empty03_timesheets_bg.svg';
import empty_sprints from '../../../../images/empty-states/empty04_sprints_anim.svg';
import empty_sprints_bg from '../../../../images/empty-states/empty04_sprints_bg.svg';
import empty_projects from '../../../../images/empty-states/empty06_projects_anim.svg';
import empty_projects_bg from '../../../../images/empty-states/empty06_projects_bg.svg';
import empty_notifications from '../../../../images/empty-states/empty07_notifications_anim.svg';
import empty_notifications_bg from '../../../../images/empty-states/empty07_notifications_bg.svg';
import empty_invoices from '../../../../images/empty-states/empty08_invoices_anim.svg';
import empty_invoices_bg from '../../../../images/empty-states/empty08_invoices_bg.svg';
import empty_all_tasks from '../../../../images/empty-states/empty13_mywork1_anim.svg';
import empty_all_tasks_bg from '../../../../images/empty-states/empty13_mywork1_bg.svg';
import empty_rate_cards from '../../../../images/empty-states/empty10_ratecards_anim.svg';
import empty_rate_cards_bg from '../../../../images/empty-states/empty10_ratecards_bg.svg';
import empty_files from '../../../../images/empty-states/empty11_files_anim.svg';
import empty_files_bg from '../../../../images/empty-states/empty11_files_bg.svg';
import empty_baseline from '../../../../images/empty-states/empty_baseline.svg';
import empty_phase from '../../../../images/empty-states/phase_spyglass.svg';
import empty_budget from '../../../../images/empty-states/budget_magician.svg';
import {EVENT_ID, dispatch} from '../../../../containers/event_manager';

import {MODAL_TYPE, showModal} from '../modals/generic_modal_conductor';
import {Link} from '@forecast-it/design-system';

const ClickableText = styled.span`
	cursor: pointer;
	text-decoration: underline;
	&:hover {
		color: #6e0feb;
	}
`;

export const getPageInfo = (page, EMPTY_STATE, showSuggestionsLink) => {
	let emptyStateInfo = {};

	switch (page) {
		case EMPTY_STATE.STARRED_TASKS:
			emptyStateInfo.avatar = empty_starred_tasks;
			emptyStateInfo.background = empty_starred_tasks_bg;
			emptyStateInfo.headline = <FormattedMessage id="empty_state.my_starred_tasks.headline" />;
			emptyStateInfo.noArrow = true;
			break;
		case EMPTY_STATE.STARRED_TASKS_SMALL:
			emptyStateInfo.avatar = empty_starred_tasks_small;
			emptyStateInfo.background = empty_starred_tasks_bg_small;
			emptyStateInfo.headline = <FormattedMessage id="empty_state.my_starred_tasks.headline" />;
			emptyStateInfo.noArrow = true;
			break;
		case EMPTY_STATE.SCOPING:
			emptyStateInfo.avatar = empty_scoping;
			emptyStateInfo.background = empty_scoping_bg;
			emptyStateInfo.headline = <FormattedMessage id="empty_state.scoping.headline" />;
			break;
		case EMPTY_STATE.PHASE:
			emptyStateInfo.avatar = empty_phase;
			break;
		case EMPTY_STATE.PROJECTS:
			emptyStateInfo.avatar = empty_projects;
			emptyStateInfo.background = empty_projects_bg;
			emptyStateInfo.headline = <FormattedMessage id="empty_state.projects.headline" />;
			break;
		case EMPTY_STATE.PROJECTS_COLLABORATOR:
			emptyStateInfo.avatar = empty_projects;
			emptyStateInfo.background = empty_projects_bg;
			emptyStateInfo.headline = <FormattedMessage id="empty_state.projects_collaborator.headline" />;
			emptyStateInfo.noArrow = true;
			break;
		case EMPTY_STATE.TIMESHEETS_NEW:
			emptyStateInfo.headline = <FormattedMessage id="empty_state.timesheets.headline" />;
			emptyStateInfo.info = showSuggestionsLink && (
				<>
					Get started with <Link onClick={e => dispatch(EVENT_ID.SHOW_TIME_REG_SUGGESTIONS)}>some suggestions</Link>
				</>
			);
			emptyStateInfo.noArrow = true;
			break;
		case EMPTY_STATE.TIMESHEETS:
			emptyStateInfo.avatar = empty_timesheets;
			emptyStateInfo.background = empty_timesheets_bg;
			emptyStateInfo.headline = <FormattedMessage id="empty_state.timesheets.headline" />;
			emptyStateInfo.info = <FormattedMessage id="empty_state.timesheets.info" />;
			emptyStateInfo.noArrow = true;
			break;
		case EMPTY_STATE.NOTIFICATIONS:
			emptyStateInfo.avatar = empty_notifications;
			emptyStateInfo.background = empty_notifications_bg;
			emptyStateInfo.headline = <FormattedMessage id="empty_state.notifications.headline" />;
			emptyStateInfo.info = <FormattedMessage id="empty_state.notifications.info" />;
			emptyStateInfo.noArrow = true;
			break;
		case EMPTY_STATE.RATE_CARDS_ADMIN:
			emptyStateInfo.avatar = empty_rate_cards;
			emptyStateInfo.background = empty_rate_cards_bg;
			emptyStateInfo.headline = <FormattedMessage id="empty_state.rate_cards_admin.headline" />;
			break;
		case EMPTY_STATE.RATE_CARDS_PROJECT:
			emptyStateInfo.avatar = empty_rate_cards;
			emptyStateInfo.background = empty_rate_cards_bg;
			emptyStateInfo.headline = <FormattedMessage id="empty_state.rate_cards_project.headline" />;
			break;
		case EMPTY_STATE.INVOICES:
			emptyStateInfo.avatar = empty_invoices;
			emptyStateInfo.background = empty_invoices_bg;
			emptyStateInfo.headline = <FormattedMessage id="empty_state.invoices.headline" />;
			break;
		case EMPTY_STATE.RETAINER:
			emptyStateInfo.avatar = empty_retainer;
			emptyStateInfo.background = empty_retainer_bg;
			emptyStateInfo.headline = <FormattedMessage id="retainer.no_periods_header" />;
			emptyStateInfo.actionText = <FormattedMessage id="retainer.set_project_dates" />;
			break;
		case EMPTY_STATE.FILES:
			emptyStateInfo.avatar = empty_files;
			emptyStateInfo.background = empty_files_bg;
			emptyStateInfo.headline = <FormattedMessage id="empty_state.files.headline" />;
			emptyStateInfo.info = <FormattedMessage id="empty_state.files.info" />;
			break;
		case EMPTY_STATE.MY_WORK_TASKS:
			emptyStateInfo.noArrow = true;
			emptyStateInfo.avatar = empty_files;
			emptyStateInfo.background = empty_files_bg;
			emptyStateInfo.headline = <FormattedMessage id="empty_state.all_tasks.headline" />;
			break;
		case EMPTY_STATE.MY_WORK_TASKS_PAST:
			emptyStateInfo.noArrow = true;
			emptyStateInfo.avatar = empty_files;
			emptyStateInfo.background = empty_files_bg;
			emptyStateInfo.headline = <FormattedMessage id="empty_state.all_tasks.headline_past" />;
			break;
		case EMPTY_STATE.REPORTED_TIME_TABLE:
			emptyStateInfo.noArrow = true;
			emptyStateInfo.avatar = empty_files;
			emptyStateInfo.background = empty_files_bg;
			emptyStateInfo.headline = <FormattedMessage id="empty_state.reported_time_table.headline" />;
			break;
		case EMPTY_STATE.TASK_REPORT:
			emptyStateInfo.noArrow = true;
			emptyStateInfo.avatar = empty_files;
			emptyStateInfo.background = empty_files_bg;
			emptyStateInfo.headline = <FormattedMessage id="empty_state.task_report.headline" />;
			break;
		case EMPTY_STATE.SPRINTS:
			emptyStateInfo.avatar = empty_sprints;
			emptyStateInfo.background = empty_sprints_bg;
			emptyStateInfo.headline = <FormattedMessage id="empty_state.sprints.headline" />;
			break;
		case EMPTY_STATE.SPRINTS_CHILDREN:
			emptyStateInfo.avatar = empty_sprints;
			emptyStateInfo.background = empty_sprints_bg;
			emptyStateInfo.headline = <FormattedMessage id="empty_state.sprints_children.headline" />;
			emptyStateInfo.info = <FormattedMessage id="empty_state.sprints_children.info" />;
			emptyStateInfo.noArrow = true;
			emptyStateInfo.actionText = <FormattedMessage id="empty_state.sprints_children.action_text" />;
			break;
		case EMPTY_STATE.BUDGET:
			emptyStateInfo.avatar = empty_budget;
			break;
		case EMPTY_STATE.ALL_TASKS:
			emptyStateInfo.avatar = empty_all_tasks;
			emptyStateInfo.background = empty_all_tasks_bg;
			emptyStateInfo.headline = <FormattedMessage id="empty_state.all_tasks.headline" />;
			emptyStateInfo.noArrow = true;
			break;
		case EMPTY_STATE.MY_WORK_SPRINTS:
			emptyStateInfo.avatar = empty_all_tasks;
			emptyStateInfo.background = empty_all_tasks_bg;
			emptyStateInfo.headline = <FormattedMessage id="empty_state.my_work.sprints" />;
			emptyStateInfo.noArrow = true;
			break;
		case EMPTY_STATE.MY_WORK_SPRINTS_NEXT_WEEK:
			emptyStateInfo.avatar = empty_all_tasks;
			emptyStateInfo.background = empty_all_tasks_bg;
			emptyStateInfo.headline = <FormattedMessage id="empty_state.my_work.sprints.next_week" />;
			emptyStateInfo.noArrow = true;
			break;
		case EMPTY_STATE.NO_ERRORS:
			emptyStateInfo.avatar = empty_all_tasks;
			emptyStateInfo.background = empty_all_tasks_bg;
			emptyStateInfo.headline = <FormattedMessage id="empty_state.all_errors_fixed" />;
			emptyStateInfo.noArrow = true;
			break;
		case EMPTY_STATE.NO_RESULT_FOUND:
			emptyStateInfo.headline = <FormattedMessage id="empty_state.no_result_found.headline" />;
			emptyStateInfo.info = <FormattedMessage id="empty_state.no_result_found.info" />;
			emptyStateInfo.noArrow = true;
			break;
		case EMPTY_STATE.BASELINE_NO_DATES:
			emptyStateInfo.avatar = empty_invoices;
			emptyStateInfo.background = empty_invoices_bg;
			emptyStateInfo.headline = <FormattedMessage id="project_budget.project_has_no_start_or_end_date" />;
			emptyStateInfo.noArrow = true;
			emptyStateInfo.actionText = <FormattedMessage id="project_budget.add_dates" />;
			emptyStateInfo.buttonFilled = true;
			break;
		case EMPTY_STATE.BASELINE:
			emptyStateInfo.avatar = empty_baseline;
			emptyStateInfo.headline = <FormattedMessage id="empty_state.baseline" />;
			emptyStateInfo.noArrow = true;
			break;
		case EMPTY_STATE.APPROVAL_NO_SUBMIT:
			emptyStateInfo.avatar = empty_files;
			emptyStateInfo.background = empty_files_bg;
			emptyStateInfo.headline = <FormattedMessage id="empty_state.time_approval.no_time_submitted" />;
			emptyStateInfo.noArrow = true;
			break;
		case EMPTY_STATE.APPROVAL_NO_SUBMIT_ON_OWNED:
			emptyStateInfo.avatar = empty_files;
			emptyStateInfo.background = empty_files_bg;
			emptyStateInfo.headline = (
				<div>
					No one from{' '}
					<ClickableText onClick={() => showModal({type: MODAL_TYPE.OWNED_PROJECTS})}>
						your project teams
					</ClickableText>{' '}
					have submitted their timesheets for this week yet
				</div>
			);
			emptyStateInfo.noArrow = true;
			break;
		case EMPTY_STATE.APPROVAL_NO_REGISTERED:
			emptyStateInfo.avatar = empty_files;
			emptyStateInfo.background = empty_files_bg;
			emptyStateInfo.headline = <FormattedMessage id="empty_state.time_approval.no_time_registered" />;
			emptyStateInfo.noArrow = true;
			break;
		case EMPTY_STATE.APPROVAL_NO_REGISTERED_ON_OWNED:
			emptyStateInfo.avatar = empty_files;
			emptyStateInfo.background = empty_files_bg;
			emptyStateInfo.headline = (
				<div>
					No one from{' '}
					<ClickableText onClick={() => showModal({type: MODAL_TYPE.OWNED_PROJECTS})}>
						your project teams
					</ClickableText>{' '}
					has registered time for this week yet
				</div>
			);
			emptyStateInfo.noArrow = true;
			break;
		case EMPTY_STATE.APPROVAL_MISSING_SUBMITION:
			emptyStateInfo.avatar = empty_scoping;
			emptyStateInfo.background = empty_scoping_bg;
			emptyStateInfo.headline = <FormattedMessage id="empty_state.time_approval.missing_submition" />;
			emptyStateInfo.noArrow = true;
			break;
		case EMPTY_STATE.APPROVAL_NOTHING_APPROVED:
			emptyStateInfo.avatar = empty_scoping;
			emptyStateInfo.background = empty_scoping_bg;
			emptyStateInfo.headline = <FormattedMessage id="empty_state.time_approval.nothing_approved" />;
			emptyStateInfo.noArrow = true;
			break;
		case EMPTY_STATE.APPROVAL_ALL_APPROVED:
			emptyStateInfo.avatar = empty_all_tasks;
			emptyStateInfo.background = empty_all_tasks_bg;
			emptyStateInfo.headline = <FormattedMessage id="empty_state.time_approval.all_approved" />;
			emptyStateInfo.noArrow = true;
			break;
		case EMPTY_STATE.APPROVAL_ALL_SUBMITTED:
			emptyStateInfo.avatar = empty_all_tasks;
			emptyStateInfo.background = empty_all_tasks_bg;
			emptyStateInfo.headline = <FormattedMessage id="empty_state.time_approval.all_submitted" />;
			emptyStateInfo.noArrow = true;
			break;
		case EMPTY_STATE.SISENSE_NO_USER:
			emptyStateInfo.avatar = empty_timesheets;
			emptyStateInfo.background = empty_timesheets_bg;
			emptyStateInfo.headline = 'Welcome to Advanced Analytics';
			emptyStateInfo.info = 'Click “Go to Analytics” to grab a seat and get started!';
			break;
		case EMPTY_STATE.PLACEHOLDERS_SCHEDULING:
			emptyStateInfo.avatar = empty_scoping;
			emptyStateInfo.info =
				'On this page, you will be able to manage all placeholders that have been created with any project';
			emptyStateInfo.noArrow = true;
			break;
		default:
			return null;
	}

	return emptyStateInfo;
};
