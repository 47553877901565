import React from 'react';
import Styled from 'styled-components';

const ImageSection = Styled.div`
	background-color: #fff;
	border-radius: 4px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	cursor: pointer;
	display: block;
	position: relative;
	text-decoration: none;
	z-index: 0;
	width:100%;
`;

const ImageWrapper = Styled.div`
    background-color: white;
	background-image: url("${props => props.url}");
	background-size: contain;
	background-position: 50%;
    background-repeat: no-repeat;
    backgroundSize: 'cover';
	user-select: none;
`;

export class ImageComponent extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			imageHeight: props.imgSizeMapRef.current.has(props.taskId) ? props.imgSizeMapRef.current.get(props.taskId) : 0,
			imageWidth: 229,
		};
	}

	getMeta(url) {
		var img = new Image();
		img.src = url;
		img.onload = image => {
			if (image.target.height !== this.state.imageHeight) {
				const minHeight = Math.min((image.target.height * 229) / image.target.width, image.target.height);
				if (this.props.imgSizeMapRef.current.get(this.props.taskId) !== minHeight) {
					this.props.updateCardHeight(minHeight - 20);
					this.props.imgSizeMapRef.current.set(this.props.taskId, minHeight);
				}
				this.setState({imageHeight: image.target.height, imageWidth: image.target.width});
			}
		};
		if (this.state.imageHeight > 260) {
			const calculateHeight = (this.state.imageHeight * 229) / this.state.imageWidth;
			return {height: calculateHeight, backgroundSize: 'contain'};
		} else {
			return {height: this.state.imageHeight, backgroundSize: 'cover'};
		}
	}

	render() {
		const imageSrc = this.props.imageSrc;
		if (!imageSrc) return null;
		const imgStyle = this.getMeta(imageSrc);

		return (
			<ImageSection>
				<ImageWrapper url={imageSrc} style={imgStyle} />
			</ImageSection>
		);
	}
}
