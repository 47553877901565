/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Timer_viewer$ref = any;
export type Timer_QueryVariables = {||};
export type Timer_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: Timer_viewer$ref,
  |}
|};
export type Timer_Query = {|
  variables: Timer_QueryVariables,
  response: Timer_QueryResponse,
|};
*/


/*
query Timer_Query {
  viewer {
    actualPersonId
    component(name: "top_navigation_timer_component")
    ...Timer_viewer
    id
  }
}

fragment Timer_viewer on Viewer {
  timerStartDate
  timerEndDate
  timerTask {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "top_navigation_timer_component"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"top_navigation_timer_component\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Timer_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Timer_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Timer_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timerStartDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timerEndDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "timerTask",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "Timer_Query",
    "operationKind": "query",
    "text": "query Timer_Query {\n  viewer {\n    actualPersonId\n    component(name: \"top_navigation_timer_component\")\n    ...Timer_viewer\n    id\n  }\n}\n\nfragment Timer_viewer on Viewer {\n  timerStartDate\n  timerEndDate\n  timerTask {\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '834aa3b6ec0cb1356460b5dcc1890399';

module.exports = node;
