import React, {createContext, useContext, useEffect, useState} from 'react';
import {createSavedOptions} from '../../../the_eye_util';
import Moment from 'moment';
import {BUTTON_COLOR, BUTTON_STYLE, ELEMENT_TYPE, PERIOD_TYPE} from '../../../constants';
import {TopHeaderBar} from '../../shared/components/headers/top-header-bar/TopHeaderBar';
import {useIntl} from 'react-intl';
import {hasPermission} from '../../shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import {processSavedReport} from './GenericSavedReportConductor';
import {hasFeatureFlag} from '../../shared/util/FeatureUtil';
import {ReportState} from '../reports-page/ReportTypes';
import {SaveReportPrompt} from '../report/shared/SaveReportPrompt';
import {saveReportAs} from '../reports-page/actions/save-report-as/SaveReportAs';
import {saveReport} from '../reports-page/actions/save-report/SaveReport';
import {saveReportMutation} from '../reports-page/actions/save-report/SaveReport';
import {shareReport} from '../reports-page/actions/share-report/ShareReport';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';

const GenericReportContext = createContext();

export const useGenericReportContext = () => {
	const context = useContext(GenericReportContext);
	if (!context) throw new Error('useGenericReportContext must be used within GenericReportPageProvider!');
	return context;
};

export const buildUpdatedEyeOptions = (updatedEyeOptions, currentEyeOptions) => {
	return {...currentEyeOptions, ...updatedEyeOptions};
};

export const getSavedEyeOptions = savedReport => {
	const object = JSON.parse(savedReport.eyeApplied);
	if (!object || typeof object !== 'object') {
		// If eyeOptions is not a proper object, use default eyeOptions (e.g. if stored as "No default eye options provided!")
		return {};
	}
	return object;
};

const getPeriodDate = (periodType, initialPeriodDate) => {
	switch (periodType) {
		case PERIOD_TYPE.YEAR:
			const periodDateOrDefault =
				isNaN(initialPeriodDate) || initialPeriodDate < 1972 ? Moment().format('YYYY') : initialPeriodDate;
			return parseInt(periodDateOrDefault);
		case null:
			return null;
		default:
			break;
	}
};

function isTemporaryReport(savedReport) {
	return savedReport.state === ReportState.TEMPORARY;
}

export const GenericReportContextProvider = ({children, savedReportRef, actualPersonId, sharedId, shareType}) => {
	const savedReport = processSavedReport(savedReportRef);
	const intl = useIntl();
	//owner id
	const reportOwner = savedReport.person;

	//state variables --- only related to save and share concept
	const [componentDidMount, setComponentDidMount] = useState(false);
	const [isStateModified, setIsStateModified] = useState(isTemporaryReport(savedReport));
	const [reportName, setReportName] = useState(savedReport.name);
	const [isNameDirty, setIsNameDirty] = useState(false);
	const [startDate, setStartDate] = useState(Moment(savedReport.startDate, 'YYYY-MM-DD'));
	const [endDate, setEndDate] = useState(Moment(savedReport.endDate, 'YYYY-MM-DD'));
	const [theEyeOptionsChecked, setTheEyeOptionsChecked] = useState(getSavedEyeOptions(savedReport));
	const [firstDropdownValue, setFirstDropdownValue] = useState(savedReport.groupingOne);
	const [secondDropdownValue, setSecondDropdownValue] = useState(savedReport.groupingTwo);
	const [thirdDropdownValue, setThirdDropdownValue] = useState(savedReport.groupingThree);
	const [fourthDropdownValue, setFourthDropdownValue] = useState(savedReport.groupingFour);
	// filterApplied / filterValue is for the old reports, filters is stored in reportService
	// conversion is done in graphQL (FilterOptionsMapper)
	const [filterValue, setFilterValue] = useState(JSON.parse(savedReport.filterApplied));
	const [filters, setFilters] = useState(savedReport.filters);

	//this getPeriodDate function is needed because some reports don't use the yyyy-mm-dd format
	//instead they expect to get just a "partial" date, as a number, like yyyy
	const [periodDate, setPeriodDate] = useState(getPeriodDate(savedReport.periodType, savedReport.periodDate));

	const [_sharedId] = useState(sharedId);
	const [_shareType] = useState(shareType);
	const [reportId] = useState(savedReport.id);

	/**
	 * Some reports (PPR) have controls that activate their action callback on load
	 * To guard against this, we set isStateModified to false on load
	 * And to be sure we set it again after a timeout
	 *
	 * We could fix the issues as they come, but this is a more robust solution albeit a bit hacky
	 * Only drawback is that changes withing the first 200ms will not be detected
	 * */
	useEffect(() => {
		setTimeout(() => {
			setComponentDidMount(true);
		}, 200);
	}, []);

	const setIsStateModifiedIfComponentDidMount = () => {
		if (componentDidMount) {
			setIsStateModified(true);
		}
	};

	const hasReportRedesignFeature = hasFeatureFlag('reports_redesign');
	const isShared = !!savedReport.shares.edges.length;
	const isAdmin = hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS);
	const isOwner = reportOwner?.id === actualPersonId;
	const hasUpdatePermission = hasPermission(PERMISSION_TYPE.INSIGHTS_UPDATE);
	// Only allow admins and owners to edit shared reports if on the Reports Redesign feature
	const canSave = hasReportRedesignFeature && isShared ? isOwner || isAdmin : isOwner || hasUpdatePermission;

	//handlers

	const handleSetReportName = changedName => {
		setReportName(changedName);
		setIsNameDirty(true);
		!isStateModified && setIsStateModifiedIfComponentDidMount(true);
	};

	const handleSetStartDate = changedStartDate => {
		setStartDate(changedStartDate);
		!isStateModified && setIsStateModifiedIfComponentDidMount(true);
	};

	const handleSetEndDate = changedEndDate => {
		setEndDate(changedEndDate);
		!isStateModified && setIsStateModifiedIfComponentDidMount(true);
	};

	const handleSetPeriodDate = changedPeriodDate => {
		setPeriodDate(changedPeriodDate);
		!isStateModified && setIsStateModifiedIfComponentDidMount(true);
	};

	const handleSetTheEyeOptions = (selectedOption, __, ___, newOptions) => {
		if (savedReport.reportService) {
			setTheEyeOptionsChecked(createSavedOptions(newOptions));
		} else {
			setTheEyeOptionsChecked(buildUpdatedEyeOptions(createSavedOptions(newOptions), getSavedEyeOptions(savedReport)));
		}

		!isStateModified && setIsStateModifiedIfComponentDidMount(true);
	};

	const handleSetFirstDropdownValue = changedValue => {
		setFirstDropdownValue(changedValue);
		!isStateModified && setIsStateModifiedIfComponentDidMount(true);
	};

	const handleSetSecondDropdownValue = changedValue => {
		setSecondDropdownValue(changedValue);
		!isStateModified && setIsStateModifiedIfComponentDidMount(true);
	};

	const handleSetThirdDropdownValue = changedValue => {
		setThirdDropdownValue(changedValue);
		!isStateModified && setIsStateModifiedIfComponentDidMount(true);
	};

	const handleSetFourthDropdownValue = changedValue => {
		setFourthDropdownValue(changedValue);
		!isStateModified && setIsStateModifiedIfComponentDidMount(true);
	};

	const handleSetFilterValue = changedFilters => {
		setFilterValue(changedFilters);
		!isStateModified && setIsStateModifiedIfComponentDidMount(true);
	};

	const handleSetFilters = filters => {
		setFilters(filters);
		!isStateModified && setIsStateModifiedIfComponentDidMount(true);
	};

	function getSaveAsReportFromContext() {
		return {
			type: savedReport.type,
			name: reportName,
			eyeApplied: JSON.stringify(theEyeOptionsChecked),
			startDate: startDate ? startDate.format('YYYY-MM-DD') : null,
			endDate: endDate ? endDate.format('YYYY-MM-DD') : null,
			privateAccess: savedReport.privateAccess,
			periodDate: JSON.stringify(periodDate),
			groupingOne: firstDropdownValue,
			groupingTwo: secondDropdownValue,
			groupingThree: thirdDropdownValue,
			groupingFour: fourthDropdownValue,
			filters: filters,
		};
	}

	function getSaveReportFromContext() {
		return {
			id: savedReport.id,
			eyeApplied: JSON.stringify(theEyeOptionsChecked),
			groupingOne: firstDropdownValue,
			groupingTwo: secondDropdownValue,
			groupingThree: thirdDropdownValue,
			groupingFour: fourthDropdownValue,
			startDate: startDate ? startDate.format('YYYY-MM-DD') : null,
			endDate: endDate ? endDate.format('YYYY-MM-DD') : null,
			privateAccess: savedReport.privateAccess,
			periodDate: JSON.stringify(periodDate),
			filterApplied: JSON.stringify(filterValue),
			filters: filters,
			name: reportName,
			reportService: savedReport.reportService,
			state: ReportState.SAVED,
		};
	}

	const saveTemporaryReport = callback => {
		trackEvent('Temporary Standard Report', 'Saved');
		const report = getSaveReportFromContext();

		const onSuccess = res => {
			setIsStateModified(false);
			setIsNameDirty(false);
			setReportName(res.updateSavedReport.savedReport.name);
			if (callback && callback instanceof Function) {
				callback();
			}
		};

		if (!isNameDirty) {
			saveReport(report, intl, onSuccess);
		} else {
			saveReportMutation(report, intl, onSuccess);
		}
	};

	const saveSavedReport = callback => {
		const onSuccess = () => {
			setIsStateModified(false);
			setIsNameDirty(false);
			if (callback && callback instanceof Function) {
				callback();
			}
		};
		saveReportMutation(getSaveReportFromContext(), intl, onSuccess);
	};

	const _saveReport = callback =>
		isTemporaryReport(savedReport) ? saveTemporaryReport(callback) : saveSavedReport(callback);

	const _saveReportAs = callback =>
		!isTemporaryReport(savedReport) && saveReportAs(intl, getSaveAsReportFromContext(), callback);

	const save = onSuccess => {
		_saveReport(onSuccess);
	};

	const saveAs = onSuccess => _saveReportAs(onSuccess);

	const _shareReport = () => !isTemporaryReport(savedReport) && shareReport(savedReport);

	const getTopReportHeader = reportNameProps => {
		const headerElements = [];

		if (isTemporaryReport(savedReport)) {
			headerElements.push({
				type: TopHeaderBar.TYPE.INPUT_REPORT_TITLE,
				intl: intl,
				placeholder: savedReport.name,
				userpilot: 'rename-input',
				onChange: handleSetReportName,
				key: 'temporary',
			});
		} else {
			headerElements.push({
				type: TopHeaderBar.TYPE.INPUT_REPORT_TITLE,
				intl: intl,
				value: savedReport.name,
				userpilot: 'rename-input',
				onChange: handleSetReportName,
				...reportNameProps,
				key: 'saved',
			});
		}

		return headerElements;
	};

	const getBottomReportHeader = additionalProps => {
		const headerElements = [];

		headerElements.push({
			type: ELEMENT_TYPE.LAST_EDIT_INFO,
			name: savedReport.updatedBy?.fullName,
			date: savedReport.updatedAt,
			byYou: savedReport.updatedBy?.id === actualPersonId,
		});

		if (canSave) {
			headerElements.push({
				type: ELEMENT_TYPE.BUTTON,
				color: BUTTON_COLOR.PURPLE,
				disabled: !isStateModified,
				text: intl.formatMessage({id: 'saved_report.save_report'}),
				userpilot: 'save-button',
				callback: _saveReport,
				dataCy: 'save-button',
			});

			if (hasFeatureFlag('reports_redesign') && !isTemporaryReport(savedReport) && savedReport.reportService) {
				headerElements.push({
					type: ELEMENT_TYPE.BUTTON,
					color: BUTTON_COLOR.PURPLE,
					text: intl.formatMessage({id: 'common.action.make_a_copy'}),
					userpilot: 'save-as-button',
					callback: _saveReportAs,
					dataCy: 'save-as-button',
				});
			}

			if (hasFeatureFlag('reports_redesign') && !isTemporaryReport(savedReport) && savedReport.reportService) {
				headerElements.push({
					type: ELEMENT_TYPE.BUTTON,
					text: 'Share',
					callback: _shareReport,
					style: BUTTON_STYLE.OUTLINE,
					color: BUTTON_COLOR.PURPLE,
				});
			}
		}

		additionalProps &&
			additionalProps.eyeProps &&
			headerElements.push({
				type: ELEMENT_TYPE.THE_EYE,
				defaultOptions: additionalProps.eyeProps.eyeOptions,
				checkedOptions: theEyeOptionsChecked,
				onSelect: handleSetTheEyeOptions,
			});

		//filters
		additionalProps &&
			additionalProps.filterProps &&
			headerElements.push({
				type: ELEMENT_TYPE.FILTER_V4,
				operatorOptions: {allowExclude: false, allowRequireAll: false},
				...additionalProps.filterProps,
				noMenu: true,
				useSavedReport: true,
				preAppliedFilters: filterValue,
				onFiltersChange: additionalProps.onFiltersChange || handleSetFilterValue,
			});

		return headerElements;
	};

	const getIsReportOwner = personId =>
		reportOwner && ((reportOwner.active && reportOwner.id === personId) || !reportOwner.active);

	const getHasOwnerPermission = personId => isAdmin || getIsReportOwner(personId);

	const canPersonShare = personId => {
		let reason;
		if (isTemporaryReport(savedReport)) {
			reason = intl.formatMessage({id: 'save_report.cant_share_temporary'});
		} else if (!getIsReportOwner(personId) && !isAdmin) {
			reason = intl.formatMessage({id: 'save_report.cant_share_permissions'});
		}

		return {
			isShareable: !reason,
			cantShareReason: reason,
		};
	};

	const value = {
		getTopReportHeader: getTopReportHeader,
		getBottomReportHeader: getBottomReportHeader,
		isStateModified,
		reportName: reportName,
		startDate: startDate,
		endDate: endDate,
		periodDate: periodDate,
		theEyeOptionsChecked: theEyeOptionsChecked,
		setTheEyeOptionsChecked: setTheEyeOptionsChecked,
		firstDropdownValue: firstDropdownValue,
		secondDropdownValue: secondDropdownValue,
		thirdDropdownValue: thirdDropdownValue,
		fourthDropdownValue: fourthDropdownValue,
		filterValue: filterValue,
		handleSetReportName: handleSetReportName,
		handleSetStartDate: handleSetStartDate,
		handleSetEndDate: handleSetEndDate,
		handleSetPeriodDate: handleSetPeriodDate,
		handleSetTheEyeOptions: handleSetTheEyeOptions,
		handleSetFirstDropdownValue: handleSetFirstDropdownValue,
		handleSetSecondDropdownValue: handleSetSecondDropdownValue,
		handleSetThirdDropdownValue: handleSetThirdDropdownValue,
		handleSetFourthDropdownValue: handleSetFourthDropdownValue,
		handleSetFilterValue: handleSetFilterValue,
		handleSetFilters: handleSetFilters,
		isSharedView: !!_sharedId,
		shareType: _shareType,
		sharedId,
		reportId,
		reportShares: savedReport.shares,
		isReportService: hasFeatureFlag('reports_redesign') ? savedReport.reportService : false,
		getHasOwnerPermission,
		getIsReportOwner,
		reportOwner,
		privateAccess: savedReport.privateAccess,
		canPersonShare,
	};

	return (
		<GenericReportContext.Provider value={value}>
			{hasFeatureFlag('reports_redesign') && !!canSave && (
				<SaveReportPrompt
					hasChanges={isStateModified}
					onSave={onSuccess => {
						trackEvent('Standard Report', 'SavePrompt Save');
						save(onSuccess);
					}}
					onSaveAs={
						isTemporaryReport(savedReport)
							? undefined
							: onSuccess => {
									trackEvent('Standard Report', 'SavePrompt SaveAs');
									saveAs(onSuccess);
							  }
					}
					onDiscard={() => {
						trackEvent('Standard Report', 'SavePrompt Discard');
						return Promise.resolve(true);
					}}
				/>
			)}
			{children}
		</GenericReportContext.Provider>
	);
};
