import {ClickAwayListener} from '@material-ui/core';
import React, {useRef, useState} from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import NotesIcon from '../../../../images/v2/components/notes/notes-no-time-dark.svg';
import NotesIconWhite from '../../../../images/v2/components/notes/notes-no-time-white.svg';
import NotesIconActive from '../../../../images/v2/components/notes/notes-empty-active.svg';
import {hasPermission} from '../../util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../Permissions';

const SprintNotesWrapper = styled.div`
	position: relative;
	color: #535353;
	height: 40px;
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: left;
`;

const PopoutWrapper = styled.div`
	position: absolute;
	z-index: 999;
	border: 1px solid #535353;
	border-radius: 4px;
	background-color: #fff;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
	display: flex;
	flex-direction: column;
`;

const PopoutHeader = styled.div`
	font-weight: 500;
	padding: 8px 0;
	margin: 0 10px;
	border-bottom: 1px solid #535353;
`;

const TextArea = styled.textarea`
	resize: none;
	padding: 8px 10px 0 10px;
	flex-grow: 1;
	width: 100%;
	outline: none;
	border: none;
`;

const NotesButton = styled.div`
	height: 30px;
	width: 24px;
	cursor: pointer;
	background-image: ${props =>
		props.isActive ? `url(${NotesIconActive})` : props.isWhite ? `url(${NotesIconWhite})` : `url(${NotesIcon})`};
	background-size: 16px;
	background-position: center;
	background-repeat: no-repeat;
	border: 1px solid transparent;
	&:hover {
		background-image: url(${NotesIconActive});
	}
	&.disabled {
		&:hover {
			cursor: default;
			background-image: url(${NotesIcon});
		}
	}
`;

export const SprintNotesFoldout = ({value, height = 325, width = 300, onSubmit, disabled, maxChars, isWhite}) => {
	const [expanded, setExpanded] = useState();
	const [popupPosition, setPopupPosition] = useState({top: 0, left: 0});

	const notesButtonRef = useRef(null);
	const textAreaRef = useRef(null);

	const onBlur = () => {
		if (textAreaRef.current) {
			onSubmit(textAreaRef.current.value || '');
		}
	};

	const closeNotes = () => {
		onBlur();
		setExpanded(false);
	};

	const toggleExpand = e => {
		e.stopPropagation();

		const screenHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
		const y = e.currentTarget.getBoundingClientRect().bottom;

		const isCloseToEdge = screenHeight - 60 - y <= height;
		const wouldFoldOverTop = y - height - 148 <= 0;

		if (notesButtonRef.current) {
			const popUpPosition = {top: 0, left: 0};
			popUpPosition.left = notesButtonRef.current.getBoundingClientRect().left - width + 24;

			if (isCloseToEdge && !wouldFoldOverTop) {
				popUpPosition.top = notesButtonRef.current.getBoundingClientRect().top - height;
				setPopupPosition(popUpPosition);
			} else {
				popUpPosition.top = notesButtonRef.current.getBoundingClientRect().top + 24;
				setPopupPosition(popUpPosition);
			}
		}

		if (expanded) {
			setExpanded(false);
		} else {
			setExpanded(true);
		}
	};

	return (
		<ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={() => closeNotes()}>
			<SprintNotesWrapper>
				<NotesButton
					ref={notesButtonRef}
					onClick={e => toggleExpand(e)}
					isWhite={isWhite}
					isActive={expanded}
				></NotesButton>
				{expanded
					? ReactDOM.createPortal(
							<PopoutWrapper
								style={{top: popupPosition.top, left: popupPosition.left, height: height, width: width}}
							>
								<PopoutHeader className={'foldout'} tabIndex={'-1'}>
									Sprint Description
								</PopoutHeader>
								<TextArea
									ref={textAreaRef}
									className={'foldout'}
									maxLength={maxChars}
									readOnly={disabled || !hasPermission(PERMISSION_TYPE.SPRINT_UPDATE)}
									placeholder={'Enter a description for your sprint'}
									defaultValue={value || ''}
									onBlur={onBlur}
								/>
							</PopoutWrapper>,
							document.querySelector('#root-portal-container')
					  )
					: null}
			</SprintNotesWrapper>
		</ClickAwayListener>
	);
};
