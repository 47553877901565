/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type taskDropdownRelayWrapper_viewer$ref = any;
export type GLOBAL_FILTER_CONTEXT = "INVOICES" | "LABELS" | "PEOPLE" | "PLACEHOLDERS" | "PROJECTS" | "RESOURCES" | "SKILLS" | "TASKS" | "TIME_REGS" | "%future added value";
export type GLOBAL_FILTER_FIELD = "APPROVAL_STATUS" | "ASSIGNED" | "ASSIGNED_SELF" | "BILLABLE" | "BILLABLE_TIME_REG" | "BILL_FROM" | "BILL_TO" | "BLOCKED" | "BUG" | "CLIENT" | "CLIENT_GUEST_USERS" | "CLIENT_TIME_REG" | "COMPANY_TASK_ID" | "CONTACT" | "CREATED_AT" | "DEADLINE" | "DEPARTMENT" | "DEPARTMENT_TIME_REG" | "DEPENDENCIES" | "DESCRIPTION" | "END_DATE" | "END_DATE_PROJECT" | "END_DATE_TIME_REG" | "FILTER_BY_TIME_REG_SETTINGS" | "FOLLOWER" | "FORECAST" | "HIGH_PRIORITY" | "INTERNAL_TIME" | "INTERNAL_TIME_REG" | "INVOICED" | "LABELS" | "LABEL_TIME_REG" | "NAME" | "NON_CLIENT_TIME_REG" | "NON_DEPARTMENT_TIME_REG" | "NON_PHASE_TIME_REG" | "NON_PROJECT_TIME_REG" | "NON_ROLE_TIME_REG" | "NON_SPRINT_TIME_REG" | "NON_TASK_TIME_REG" | "NO_CONTACT" | "OWNER" | "PERMISSION_LEVEL" | "PERSON" | "PERSON_TIME_REG" | "PHASE_CATEGORY" | "PHASE_TIME_REG" | "PRIORITY_LEVEL" | "PROGRAM" | "PROJECT" | "PROJECT_BUDGET_TYPE" | "PROJECT_DEFAULT_PERIOD_BUDGET_TYPE" | "PROJECT_FOLLOWER" | "PROJECT_GROUP" | "PROJECT_GROUP_SPRINT" | "PROJECT_GROUP_STATUS" | "PROJECT_LABELS" | "PROJECT_MANUAL_PROGRESS" | "PROJECT_OWNER" | "PROJECT_PERSON" | "PROJECT_PHASE" | "PROJECT_SPRINT" | "PROJECT_STAGE" | "PROJECT_STATUS" | "PROJECT_TIME_REG" | "RATE_CARD" | "RECENT_ACTIVITY" | "RECENT_TIME" | "REMAINING" | "RETAINER_PERIOD" | "ROLE" | "ROLE_TIME_REG" | "SET_DONE_AT" | "SKILL" | "SPRINT_CATEGORY" | "SPRINT_TIME_REG" | "STARRED" | "START_DATE" | "START_DATE_PROJECT" | "START_DATE_TIME_REG" | "STATUS" | "SUB_TASKS" | "TASK" | "TASK_APPROVED" | "TASK_HIERARCHY" | "TASK_LEVEL" | "TASK_TIME_REG" | "TEAM" | "TEAM_MEMBER" | "TEAM_TIME_REG" | "TIME_OFF" | "TIME_OFF_TIME_REG" | "TIME_REGS" | "UPDATED_AT" | "WITH_TASKS" | "WITH_TIME_REG" | "%future added value";
export type GLOBAL_FILTER_LOGICAL_OPERATOR = "AND" | "OR" | "%future added value";
export type GLOBAL_FILTER_OPERATOR = "ALL_IN" | "CONTAINS" | "CONTAINS_NOT" | "GREATER" | "GREATER_OR_EQUAL" | "IN" | "IS" | "IS_ALL" | "IS_NOT" | "IS_NOT_ALL" | "LESS" | "LESS_OR_EQUAL" | "NOT_ALL_IN" | "NOT_IN" | "%future added value";
export type TaskSearchQueryType = {|
  filters?: ?$ReadOnlyArray<?GlobalSearchFilterType>
|};
export type GlobalSearchFilterType = {|
  field?: ?GLOBAL_FILTER_FIELD,
  operator?: ?GLOBAL_FILTER_OPERATOR,
  value?: ?$ReadOnlyArray<?string>,
  context?: ?GLOBAL_FILTER_CONTEXT,
  filters?: ?$ReadOnlyArray<?GlobalSearchFilterType>,
  logicalOperator?: ?GLOBAL_FILTER_LOGICAL_OPERATOR,
|};
export type taskDropdownRelayWrapperRefetchQueryVariables = {|
  pageSize?: ?number,
  cursor?: ?string,
  modalSearchQuery?: ?TaskSearchQueryType,
  sortValue?: ?string,
|};
export type taskDropdownRelayWrapperRefetchQueryResponse = {|
  +viewer: ?{|
    +component: ?string,
    +$fragmentRefs: taskDropdownRelayWrapper_viewer$ref,
  |}
|};
export type taskDropdownRelayWrapperRefetchQuery = {|
  variables: taskDropdownRelayWrapperRefetchQueryVariables,
  response: taskDropdownRelayWrapperRefetchQueryResponse,
|};
*/


/*
query taskDropdownRelayWrapperRefetchQuery(
  $pageSize: Int
  $cursor: String
  $modalSearchQuery: TaskSearchQueryType
  $sortValue: String
) {
  viewer {
    component(name: "task_dropdown")
    ...taskDropdownRelayWrapper_viewer_1oArhO
    id
  }
}

fragment taskDropdownRelayWrapper_viewer_1oArhO on Viewer {
  id
  actualPersonId
  company {
    id
    modalTasks: allTasks(first: $pageSize, after: $cursor, searchQuery: $modalSearchQuery, sortValue: $sortValue, simpleResponse: true, approvedOnly: true, runningProjectOnly: true, excludeReadOnly: true) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          name
          companyTaskId
          timeLeft
          estimateForecastMinutes
          totalMinutesRegistered
          parentTaskId
          billable
          startYear
          startMonth
          startDay
          deadlineDay
          deadlineMonth
          deadlineYear
          role {
            id
          }
          project {
            id
            name
            companyProjectId
            customProjectId
            status
            projectColor
            billable
            projectPerson {
              role {
                id
                name
              }
              id
            }
            harvestProject {
              id
            }
            unit4Project {
              id
              activitiesEnabled
            }
            projectStartYear
            projectStartMonth
            projectStartDay
            projectEndYear
            projectEndMonth
            projectEndDay
          }
          __typename
        }
        cursor
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pageSize",
    "type": "Int"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "modalSearchQuery",
    "type": "TaskSearchQueryType"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortValue",
    "type": "String"
  }
],
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "task_dropdown"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"task_dropdown\")"
},
v2 = {
  "kind": "Variable",
  "name": "sortValue",
  "variableName": "sortValue"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Literal",
    "name": "approvedOnly",
    "value": true
  },
  {
    "kind": "Literal",
    "name": "excludeReadOnly",
    "value": true
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "pageSize"
  },
  {
    "kind": "Literal",
    "name": "runningProjectOnly",
    "value": true
  },
  {
    "kind": "Variable",
    "name": "searchQuery",
    "variableName": "modalSearchQuery"
  },
  {
    "kind": "Literal",
    "name": "simpleResponse",
    "value": true
  },
  (v2/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billable",
  "storageKey": null
},
v7 = [
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "taskDropdownRelayWrapperRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "args": [
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              {
                "kind": "Variable",
                "name": "modalSearchQuery",
                "variableName": "modalSearchQuery"
              },
              {
                "kind": "Variable",
                "name": "pageSize",
                "variableName": "pageSize"
              },
              (v2/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "taskDropdownRelayWrapper_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "taskDropdownRelayWrapperRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "actualPersonId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": "modalTasks",
                "args": (v4/*: any*/),
                "concreteType": "TaskTypeConnection",
                "kind": "LinkedField",
                "name": "allTasks",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TaskTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Task",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "companyTaskId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "timeLeft",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "estimateForecastMinutes",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "totalMinutesRegistered",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "parentTaskId",
                            "storageKey": null
                          },
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startYear",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startMonth",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startDay",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "deadlineDay",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "deadlineMonth",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "deadlineYear",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Role",
                            "kind": "LinkedField",
                            "name": "role",
                            "plural": false,
                            "selections": (v7/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProjectType",
                            "kind": "LinkedField",
                            "name": "project",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "companyProjectId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "customProjectId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "status",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "projectColor",
                                "storageKey": null
                              },
                              (v6/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProjectPerson",
                                "kind": "LinkedField",
                                "name": "projectPerson",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Role",
                                    "kind": "LinkedField",
                                    "name": "role",
                                    "plural": false,
                                    "selections": [
                                      (v3/*: any*/),
                                      (v5/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "HarvestProjectType",
                                "kind": "LinkedField",
                                "name": "harvestProject",
                                "plural": false,
                                "selections": (v7/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Unit4ProjectType",
                                "kind": "LinkedField",
                                "name": "unit4Project",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "activitiesEnabled",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "projectStartYear",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "projectStartMonth",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "projectStartDay",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "projectEndYear",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "projectEndMonth",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "projectEndDay",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": "modalTasks",
                "args": (v4/*: any*/),
                "filters": [],
                "handle": "connection",
                "key": "Company_modalTasks",
                "kind": "LinkedHandle",
                "name": "allTasks"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "taskDropdownRelayWrapperRefetchQuery",
    "operationKind": "query",
    "text": "query taskDropdownRelayWrapperRefetchQuery(\n  $pageSize: Int\n  $cursor: String\n  $modalSearchQuery: TaskSearchQueryType\n  $sortValue: String\n) {\n  viewer {\n    component(name: \"task_dropdown\")\n    ...taskDropdownRelayWrapper_viewer_1oArhO\n    id\n  }\n}\n\nfragment taskDropdownRelayWrapper_viewer_1oArhO on Viewer {\n  id\n  actualPersonId\n  company {\n    id\n    modalTasks: allTasks(first: $pageSize, after: $cursor, searchQuery: $modalSearchQuery, sortValue: $sortValue, simpleResponse: true, approvedOnly: true, runningProjectOnly: true, excludeReadOnly: true) {\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n      edges {\n        node {\n          id\n          name\n          companyTaskId\n          timeLeft\n          estimateForecastMinutes\n          totalMinutesRegistered\n          parentTaskId\n          billable\n          startYear\n          startMonth\n          startDay\n          deadlineDay\n          deadlineMonth\n          deadlineYear\n          role {\n            id\n          }\n          project {\n            id\n            name\n            companyProjectId\n            customProjectId\n            status\n            projectColor\n            billable\n            projectPerson {\n              role {\n                id\n                name\n              }\n              id\n            }\n            harvestProject {\n              id\n            }\n            unit4Project {\n              id\n              activitiesEnabled\n            }\n            projectStartYear\n            projectStartMonth\n            projectStartDay\n            projectEndYear\n            projectEndMonth\n            projectEndDay\n          }\n          __typename\n        }\n        cursor\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '750674ea0856898fbbcc6c2c10166b97';

module.exports = node;
