/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type TaskReportTableV2_viewer$ref: FragmentReference;
declare export opaque type TaskReportTableV2_viewer$fragmentType: TaskReportTableV2_viewer$ref;
export type TaskReportTableV2_viewer = {|
  +reportData: ?{|
    +totals: ?{|
      +taskTotals: ?{|
        +entities: ?number,
        +work: ?{|
          +estimate: ?{|
            +minutes: ?number,
            +points: ?number,
          |},
          +remaining: ?{|
            +minutes: ?number,
            +points: ?number,
          |},
        |},
        +reported: ?{|
          +time: ?number,
          +points: ?number,
          +projected: ?number,
        |},
        +financial: ?{|
          +revenue: ?{|
            +planned: ?number,
            +actual: ?number,
          |}
        |},
      |}
    |}
  |},
  +$refType: TaskReportTableV2_viewer$ref,
|};
export type TaskReportTableV2_viewer$data = TaskReportTableV2_viewer;
export type TaskReportTableV2_viewer$key = {
  +$data?: TaskReportTableV2_viewer$data,
  +$fragmentRefs: TaskReportTableV2_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "points",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "minutes",
    "storageKey": null
  },
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchQuery",
      "type": "TaskSearchQueryType"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "reportId",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sharedId",
      "type": "String"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaskReportTableV2_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "reportId"
        },
        {
          "kind": "Variable",
          "name": "sharedId",
          "variableName": "sharedId"
        }
      ],
      "concreteType": "ReportData",
      "kind": "LinkedField",
      "name": "reportData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "searchQuery",
              "variableName": "searchQuery"
            }
          ],
          "concreteType": "ReportTotals",
          "kind": "LinkedField",
          "name": "totals",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ReportTaskTotals",
              "kind": "LinkedField",
              "name": "taskTotals",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "entities",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TaskReportTableTask_GroupWork",
                  "kind": "LinkedField",
                  "name": "work",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "TaskReportTableTask_Unit",
                      "kind": "LinkedField",
                      "name": "estimate",
                      "plural": false,
                      "selections": (v1/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "TaskReportTableTask_Unit",
                      "kind": "LinkedField",
                      "name": "remaining",
                      "plural": false,
                      "selections": (v1/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TaskReportTableTask_Reported",
                  "kind": "LinkedField",
                  "name": "reported",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "time",
                      "storageKey": null
                    },
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "projected",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ReportTotalsFinancial",
                  "kind": "LinkedField",
                  "name": "financial",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "TaskReportTableTask_Revenue",
                      "kind": "LinkedField",
                      "name": "revenue",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "planned",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "actual",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '64315580147162926f5fb6136b67bb50';

module.exports = node;
