import React from 'react';

export const BinOutline = ({color, width, height}) => (
	<div className={'checkmark-icon'}>
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 18 16">
			<path
				fill={color}
				d="M10.895 0c1.128 0 2.052.88 2.052 1.978v.43h4.264c.436 0 .789.347.789.775 0 .392-.297.716-.682.767l-.107.007-1.738-.001v10.066c0 1.047-.841 1.897-1.9 1.973l-.152.005H4.58c-1.129 0-2.053-.88-2.053-1.978V3.956l-1.737.001C.353 3.957 0 3.61 0 3.183c0-.392.297-.716.682-.767l.107-.007 4.263-.001v-.43c0-1.047.842-1.897 1.9-1.973L7.106 0zm3 3.957h-9.79v10.065c0 .206.164.385.388.423l.086.007h8.842c.267 0 .474-.198.474-.43V3.957zm-6.79 2.065c.4 0 .73.29.783.669l.007.105v4.817c0 .427-.354.774-.79.774-.4 0-.73-.291-.782-.669l-.007-.105V6.796c0-.428.353-.774.79-.774zm3.79 0c.4 0 .73.29.782.669l.007.105v4.817c0 .427-.353.774-.79.774-.399 0-.73-.291-.782-.669l-.007-.105V6.796c0-.428.354-.774.79-.774zm0-4.474h-3.79c-.266 0-.473.198-.473.43l-.001.43h4.737v-.43c0-.206-.163-.385-.387-.423l-.086-.007z"
			/>
		</svg>
	</div>
);
