/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SettingsSetup_viewer$ref = any;
export type SettingsSetupQueryVariables = {||};
export type SettingsSetupQueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: SettingsSetup_viewer$ref,
  |}
|};
export type SettingsSetupQuery = {|
  variables: SettingsSetupQueryVariables,
  response: SettingsSetupQueryResponse,
|};
*/


/*
query SettingsSetupQuery {
  viewer {
    actualPersonId
    component(name: "settings_setup")
    ...SettingsSetup_viewer
    id
  }
}

fragment ResourceManagement_company on Company {
  isUsingSchedulingPlanMode
  isUsingProjectAllocation
  isUsingMixedAllocation
}

fragment SettingsSetup_viewer on Viewer {
  id
  company {
    id
    companyTarget
    revenueEnabled
    timesheetsEnabled
    clientsEnabled
    sageIntacctTimeRegsFromForecast
    ...ResourceManagement_company
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "settings_setup"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"settings_setup\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SettingsSetupQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SettingsSetup_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SettingsSetupQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "companyTarget",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "revenueEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timesheetsEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientsEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sageIntacctTimeRegsFromForecast",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isUsingSchedulingPlanMode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isUsingProjectAllocation",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isUsingMixedAllocation",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "SettingsSetupQuery",
    "operationKind": "query",
    "text": "query SettingsSetupQuery {\n  viewer {\n    actualPersonId\n    component(name: \"settings_setup\")\n    ...SettingsSetup_viewer\n    id\n  }\n}\n\nfragment ResourceManagement_company on Company {\n  isUsingSchedulingPlanMode\n  isUsingProjectAllocation\n  isUsingMixedAllocation\n}\n\nfragment SettingsSetup_viewer on Viewer {\n  id\n  company {\n    id\n    companyTarget\n    revenueEnabled\n    timesheetsEnabled\n    clientsEnabled\n    sageIntacctTimeRegsFromForecast\n    ...ResourceManagement_company\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c517ca9b57a43a53759b63214ea68a54';

module.exports = node;
