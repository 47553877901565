import {isEqual} from 'lodash';
import ReorderTaskMutation from '../../../../../../mutations/reorder_task_mutation.modern';
import Util from '../../../../util/util';
import {createToast} from '../../../toasts/toast';
import {TYPES} from '../Theme/NodeContentRenderer';
import {GROUP_TYPE} from '../TaskTable';
import CreateTaskMutation from '../../../../../../mutations/create_task_mutation';
import {isJiraToForecastOneWaySync} from '../../../../util/JiraUtil';

export const canDrag = ({node}) => {
	return node.type === TYPES.TASK && !isJiraToForecastOneWaySync(node.sharedOptions.company);
};

export const getCanDropFunc = (groupType, showBaselineInfo) => {
	if (showBaselineInfo) {
		if (groupType === GROUP_TYPE.NO_GROUPING) {
			return ({nextParent}) => {
				return nextParent?.type === TYPES.TASK || nextParent?.type === TYPES.BASELINE;
			};
		}
		return ({nextParent}) => {
			return (
				nextParent?.type === TYPES.BASELINE ||
				nextParent?.type === TYPES.PERSON_GROUPING ||
				nextParent?.type === TYPES.TASK ||
				nextParent?.type === TYPES.NO_ROLE_GROUPING ||
				nextParent?.type === TYPES.ROLE_GROUPING
			);
		};
	}

	if (groupType === GROUP_TYPE.NO_GROUPING) {
		return ({nextParent}) => nextParent?.type === TYPES.TASK || nextParent?.type === TYPES.HEADER;
	}

	return ({nextParent}) => {
		return (
			nextParent?.type === TYPES.ROLE_GROUPING ||
			nextParent?.type === TYPES.NO_ROLE_GROUPING ||
			nextParent?.type === TYPES.PERSON_GROUPING ||
			nextParent?.type === TYPES.TASK
		);
	};
};

export const canNodeHaveChildren = (node, showBaselineInfo, useTaskHierarchy) => {
	if (node && node.type === TYPES.TASK) {
		const maxSubTaskDepth = Util.getMaxSubtaskDepth(!!(node.project && node.project.isJiraProject));
		return useTaskHierarchy && node.taskDepth < maxSubTaskDepth;
	}
	let childrenAllowedTypes = [];
	if (showBaselineInfo) {
		childrenAllowedTypes = [TYPES.BASELINE, TYPES.PERSON_GROUPING, TYPES.NO_ROLE_GROUPING];
	} else {
		childrenAllowedTypes = [TYPES.HEADER, TYPES.ROLE_GROUPING, TYPES.NO_ROLE_GROUPING, TYPES.PERSON_GROUPING];
	}
	return node && childrenAllowedTypes.find(type => type === node.type);
};

const getNoGroupNameByGroupType = groupType => {
	switch (groupType) {
		case GROUP_TYPE.PERSON:
			return 'no_person';
		case GROUP_TYPE.ROLE:
			return 'no_role';
		default:
			return null;
	}
};

const getDropBranch = (treeData, path) => {
	const [currentNode, ...remainingPath] = path;
	const currentBranch = treeData.find(branch => branch.id === currentNode);
	// Terminate recursion once remaining path is only task
	if (remainingPath.length === 1) {
		return currentBranch;
	} else {
		return getDropBranch(currentBranch.children, remainingPath);
	}
};

export const onMoveNode = (
	{treeData, node, nextParentNode, prevPath, prevTreeIndex, nextPath, nextTreeIndex},
	selectedTasks,
	groupType,
	setSelectedTasks
) => {
	const onSuccess = result => {
		if (result?.updateTask?.errors?.length === 1) {
			if (result.updateTask.errors[0] === 'TASK_HIERARCHY_CIRCLE_DEPENDENCY') {
				console.error('TASK_HIERARCHY_CIRCLE_DEPENDENCY');
			}
			if (result.updateTask.errors[0] === 'TASK_HIERARCHY_MAX_LEVEL_REACHED') {
				createToast({duration: 5000, message: 'Too many subtask levels'});
				console.error('TASK_HIERARCHY_MAX_LEVEL_REACHED');
			}
		} else if (nextParentNode.task) {
			createToast({duration: 5000, message: 'Subtask moved'});
		}
		// A semi hacky way of making sure that you can't parents tasks selected without also having the children selected.
		setSelectedTasks([]);
	};

	// Find all task in the selectedTasks list that are NOT the nextParent
	let selectedTasksMinusNextParent = [...selectedTasks].filter(task =>
		nextParentNode.task ? task.id !== nextParentNode.task.id : true
	);
	// If you are moving tasks to the root / lvl1 then filter the selectedtasks so if only contains tasks which parent are not in the list
	if (!nextParentNode.task) {
		selectedTasksMinusNextParent = selectedTasksMinusNextParent.filter(
			task =>
				!task.parentTaskId || !selectedTasksMinusNextParent.find(selectedTask => task.parentTaskId === selectedTask.id)
		);
	}
	const sourceTask = node.task;

	const dropInBaseLine = nextParentNode?.type === TYPES.BASELINE;

	const mutationObject = {};

	// Check if there are any selected tasks
	if (selectedTasksMinusNextParent.length > 0) {
		// Check to see if the moved task wasn't among the selected tasks
		if (!selectedTasksMinusNextParent.find(task => task.id === sourceTask.id)) {
			// If the moved task wasn't among the selected add the moved task to the selected tasks
			// The extra dragged card goes in first, and ends up at the dragged position, everything else comes after in sort order
			selectedTasksMinusNextParent.splice(0, 0, sourceTask);
		}
		mutationObject.ids = selectedTasksMinusNextParent.map(task => task.id);
	} else {
		mutationObject.ids = [sourceTask.id];
	}

	mutationObject.parentTaskId = nextParentNode.task ? nextParentNode.task.id : null;

	// Find the parent phase
	const targetPhase = nextPath[0];
	const groupedMove = groupType !== GROUP_TYPE.NO_GROUPING;
	const targetPhaseId = targetPhase && targetPhase.split('#')[0];
	const targetGroup = groupedMove && nextPath[1];
	const targetGroupId = targetGroup && targetGroup.split('#')[0];
	const prevTargetGroup = groupedMove && prevPath[1];
	const prevTargetGroupId = prevTargetGroup && prevTargetGroup.split('#')[0];
	const noGroup = getNoGroupNameByGroupType(groupType);

	// Group movement
	if (!isEqual(prevPath, nextPath)) {
		mutationObject.phaseId = targetPhaseId === 'no-phase' ? null : targetPhaseId;
		if (targetGroup !== prevTargetGroup) {
			if (groupType === GROUP_TYPE.ROLE) {
				mutationObject.roleId = targetGroupId === noGroup ? null : targetGroupId;
			} else if (groupType === GROUP_TYPE.PERSON) {
				mutationObject.existingAssignedPersons = sourceTask.assignedPersons.map(p => p.id);

				// If targetGroupId is no_person, we want to unassign everyone
				if (targetGroupId === noGroup) {
					const unassignPersonsFromTask = {
						taskId: sourceTask.id,
						personIds: sourceTask.assignedPersons.map(p => p.id),
					};
					mutationObject.unassignPersonFromTask = [unassignPersonsFromTask];
				} else if (targetGroupId !== null) {
					// If a task in dropped in the baseline lvl1 then don't set the assignedPersonToAdd
					if (!dropInBaseLine) {
						// Person to add to all dragged tasks
						mutationObject.assignedPersonToAdd = targetGroupId;
					}
					// Person to remove from all dragged tasks
					if (prevTargetGroupId != null && prevTargetGroupId !== noGroup) {
						const unassignPersonsFromTask = {
							taskId: sourceTask.id,
							personIds: [prevTargetGroupId],
						};
						mutationObject.unassignPersonFromTask = [unassignPersonsFromTask];
					}
				}
			}
		}
	}

	const taskBranch = getDropBranch(treeData, nextPath);

	const branchIndex = taskBranch.children.findIndex(node => {
		return node.task?.id === sourceTask.id;
	});

	if (branchIndex && branchIndex > 0) {
		const aboveTask = taskBranch.children[branchIndex - 1];
		if (aboveTask?.task) {
			mutationObject.aboveTaskId = aboveTask.id;
			mutationObject.optimisticTaskOrder = aboveTask.task.sortOrder + 0.5;
		} else {
			mutationObject.aboveTaskId = null;
			mutationObject.optimisticTaskOrder = 0.5;
		}
	} else {
		mutationObject.aboveTaskId = null;
		mutationObject.optimisticTaskOrder = 0.5;
	}

	Util.CommitMutation(ReorderTaskMutation, mutationObject, onSuccess);
};

export const handleAddTask = (projectId, name, role, forecast, phaseId, parentTaskId, assignedPersons, intl, history) => {
	const onSuccess = res => {
		if (res.createTask.errors && res.createTask.errors.length === 1) {
			Util.checkForSageErrorAndShowModal(res.createTask.errors, false);
		} else {
			createToast({
				duration: 5000,
				message: intl.formatMessage({
					id: 'task_activity_log.CARD_CREATE',
				}),
				actionText: intl.formatMessage({id: 'common.see_task'}),
				actionCallback: () => Util.showTaskModal(res.createTask.task.node.companyTaskId, history),
			});
		}
	};
	if (projectId && name) {
		const assignedPersonsIds = assignedPersons.map(person => person.id);
		const mutationObject = {
			projectId: projectId,
			name: name,
			forecast: forecast ? forecast : undefined,
			approved: true,
			phaseId: phaseId,
			parentTaskId: parentTaskId,
			assignedPersons: assignedPersonsIds,
			placementOfTask: 'BOTTOM',
		};
		if (role) {
			mutationObject.roleId = role.id;
		}
		Util.localStorageDeleteFincancialMap();
		Util.CommitMutation(CreateTaskMutation, mutationObject, onSuccess);
	}
};
