import React from 'react';
import { Modal } from '@forecast-it/design-system';
import { dateRangeToYMD } from '../../../shared/util/DateUtil';
import { handleCopyAll, handleCopyWithoutDoneTasks } from './TimesheetsCopyLastWeekModalLogic';
export function TimesheetsCopyLastWeekModalFooter({ personId, copyDateRange, closeModal, onCopyCallback, copyDone, copyFullWeek, }) {
    const copyDateRangeYMD = dateRangeToYMD(copyDateRange);
    return (React.createElement(Modal.Footer, null,
        React.createElement(Modal.PrimaryFooterButton, { dataCy: "copy-last-week-primary-button", key: 'modal_primaryFooterButton_copy_wo_done_tasks_key', onPress: () => copyDone
                ? handleCopyAll(personId, copyDateRangeYMD, onCopyCallback, closeModal)
                : handleCopyWithoutDoneTasks(personId, copyDateRangeYMD, onCopyCallback, closeModal) }, `Copy from previous ${copyFullWeek ? 'week' : 'day'}`)));
}
