/**
 * Limit string to a certain length
 * @param str Input string
 * @param limit The maximum length of the string. If set to 0 or less, the original string will be returned.
 */
export function limitString(str, limit) {
    if (limit <= 0) {
        return str;
    }
    if (str.length > limit) {
        return str.substring(0, limit);
    }
    return str;
}
