import Styled from 'styled-components';

export const Container = Styled.div`
	width: 100%;
	height: 100%;
`;

export const EmptyStateContainer = Styled(Container)`
	padding-right: 10px;
`;
export const Spacer = Styled.div`
	height: 80px;
`;

export const CardWrapper = Styled.div`
	margin: 0 10px 10px 0;
`;
