import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { isBillableSplitAllowed, isTimeRegistrationNoteRequired, } from '../../../../../shared/util/cache/TimeRegistrationSettingsUtil';
import { isTimeRegistrationNoteInvalid } from '../../../../../shared/util/time-registration/time-registration-settings/TimeRegistrationNoteFilter';
import { workingHourForTheDay } from '../../../timesheets_person_data';
import AdvancedTimeRegistrationContent, { PopupFocusItem } from './AdvancedTimeRegistrationContent';
import { Popup, PrimaryButton as AddButton } from './AdvancedTimeRegistrationStyled';
import { trackEvent } from '../../../../../../tracking/amplitude/TrackingV2';
import { useTaskEstimateValidation } from '../../../../../shared/util/time-registration/time-registration-settings/useTaskEstimateValidation';
import { useIntl } from 'react-intl';
import { PopoverHandler } from '@forecast-it/design-system';
import Spinner from '../../../../../shared/components/spinner/Spinner';
import { useSpinner } from '../../../../../shared/components/spinner/useSpinner';
const Buttons = styled.div `
	display: flex;
	justify-content: flex-end;
	height: 30px;
`;
function CreateAdvancedTimeRegistrationContent({ billable, mutation, selectedPerson, dayOfInput, taskEstimateForecastMinutes, taskTotalMinutesRegistered, canHaveRole, projectId, taskId, isSuggestion, }) {
    useEffect(() => {
        trackEvent('Create Advanced Time Registration Popup', 'Opened');
    }, []);
    const intl = useIntl();
    const id = 'time-reg-popup';
    const { setOpen } = PopoverHandler.usePopoverState();
    const [waitingForMutation, setWaitingForMutation] = useState(false);
    const { spinning } = useSpinner({ waiting: waitingForMutation });
    const [timeInputMinutes, setTimeInputMinutes] = useState(0);
    const [billableTimeInputMinutes, setBillableTimeInputMinutes] = useState(null);
    const [notes, setNotes] = useState('');
    const [role, setRole] = useState(null);
    const [noteError, setNoteError] = useState(false);
    const [timeRegistrationFocus, setTimeRegistrationFocus] = useState({
        id: null,
        focusItem: null,
    }); // Used to propagate focus control down to children
    const [timeError, setTimeError] = useState(null);
    const addButtonRef = useRef(null);
    const isNoteRequired = isTimeRegistrationNoteRequired();
    const { remainingInfoMessage, validateTimeInputValue } = useTaskEstimateValidation(taskEstimateForecastMinutes, taskTotalMinutesRegistered, 0, intl);
    const onTimeChange = useCallback((minutes, isEnterBlur) => {
        var _a;
        setTimeInputMinutes(minutes);
        if (isEnterBlur) {
            (_a = addButtonRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [setTimeInputMinutes, addButtonRef.current]);
    const onBillableTimeChange = useCallback((minutes, isEnterBlur) => {
        var _a;
        setBillableTimeInputMinutes(minutes);
        if (isEnterBlur) {
            (_a = addButtonRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [setBillableTimeInputMinutes, addButtonRef.current]);
    const onNotesChange = useCallback(notes => {
        setNotes(notes);
        setNoteError(false);
    }, [setNotes, setNoteError]);
    const onRoleChange = useCallback(role => {
        setRole(role);
    }, [setRole]);
    const handleAddButtonClick = () => {
        setWaitingForMutation(true);
        const timeInputError = validateTimeInputValue(timeInputMinutes);
        if (timeInputError) {
            setTimeError(timeInputError);
            setTimeRegistrationFocus({ id: id, focusItem: PopupFocusItem.Hours });
            setWaitingForMutation(false);
            return;
        }
        const noteInvalid = isNoteRequired && isTimeRegistrationNoteInvalid(notes);
        if (noteInvalid) {
            setNoteError(true);
            setTimeRegistrationFocus({ id: id, focusItem: PopupFocusItem.Notes });
            setWaitingForMutation(false);
            return;
        }
        const hours = timeInputMinutes ? timeInputMinutes / 60 : 0;
        // If the popout is used for a non-billable time registration we don't want to send any value (not even null) to the mutation
        // since we want to preserve the existing billableMinutesRegistered in the db in case the item is made billable in the future.
        const billableHours = billable
            ? billableTimeInputMinutes != null
                ? billableTimeInputMinutes / 60
                : undefined
            : undefined;
        function onSuccess() {
            setWaitingForMutation(false);
            setOpen(false);
            trackEvent('Advanced Time Registration', 'Created', {
                billable: billable,
                minutesRegistered: timeInputMinutes,
                billableMinutesRegistered: billableTimeInputMinutes,
                role: role === null || role === void 0 ? void 0 : role.name,
                notes: notes,
                isSuggestion: !!isSuggestion,
            });
        }
        mutation(hours, billableHours, notes ? notes : undefined, role === null || role === void 0 ? void 0 : role.id, onSuccess);
    };
    const workingHours = useMemo(() => workingHourForTheDay(selectedPerson, dayOfInput), [selectedPerson, dayOfInput]);
    const makeWider = isBillableSplitAllowed() && billable && canHaveRole;
    return (React.createElement(Popup, { makeWider: makeWider },
        React.createElement(AdvancedTimeRegistrationContent, { id: id, timeInputMinutes: timeInputMinutes, onTimeChange: onTimeChange, billableTimeInputMinutes: billableTimeInputMinutes, onBillableTimeChange: onBillableTimeChange, notes: notes, onNotesChange: onNotesChange, billable: billable, noteError: noteError, timeError: timeError, remainingInfoMessage: remainingInfoMessage, workingHours: workingHours, timeRegistrationFocus: timeRegistrationFocus, onRoleChange: onRoleChange, firstTimeRegistration: true, canHaveRole: canHaveRole, projectId: projectId, taskId: taskId, personId: selectedPerson.id + '' }),
        React.createElement(Buttons, null,
            React.createElement(AddButton, { "z-index": 10, ref: addButtonRef, "data-cy": "add-new-time-entry-button", onClick: handleAddButtonClick, disabled: waitingForMutation || timeInputMinutes == null },
                React.createElement(Spinner, { children: 'Add', spinning: spinning })))));
}
export default CreateAdvancedTimeRegistrationContent;
