import { TimeRegistrationEntity } from '../types/TimeRegistrationEntityId';
function mapToRole(roleId, roleName, disabled = false) {
    if (roleId && roleName) {
        return { id: roleId, name: roleName, disabled: disabled };
    }
    return null;
}
export function getCompanyRoles(companyRoles, projectId = undefined, selectedRole = undefined) {
    if (companyRoles === null || companyRoles === void 0 ? void 0 : companyRoles.edges) {
        const mappedCompanyRoles = companyRoles.edges.reduce((acc, roleEdge) => {
            var _a, _b;
            const role = mapToRole((_a = roleEdge === null || roleEdge === void 0 ? void 0 : roleEdge.node) === null || _a === void 0 ? void 0 : _a.id, (_b = roleEdge === null || roleEdge === void 0 ? void 0 : roleEdge.node) === null || _b === void 0 ? void 0 : _b.name);
            if (role) {
                acc.push(role);
            }
            return acc;
        }, []);
        if (projectId && selectedRole && !mappedCompanyRoles.find(r => r.id === selectedRole.id)) {
            const mappedRole = mapToRole(selectedRole.id, selectedRole.name, true);
            if (mappedRole) {
                mappedCompanyRoles.push(mappedRole);
            }
        }
        return mappedCompanyRoles;
    }
    else {
        return [];
    }
}
export function isRoleDisabled(roleId, roles) {
    if (roleId === undefined) {
        return false;
    }
    return roles.some(role => role.id === roleId && role.disabled);
}
export function getDisabledRoleIds(roles) {
    return roles.filter(role => role.disabled).map(role => role.id);
}
export function getRoleWarningTagOrUndefined(roleId, roles) {
    return isRoleDisabled(roleId, roles) ? 'project_person.deactivated_role' : undefined;
}
function getProjectPersonRole(targetEntity) {
    var _a, _b, _c;
    if (!targetEntity)
        return null;
    switch (targetEntity.entity) {
        case TimeRegistrationEntity.Task:
            if (((_a = targetEntity.project) === null || _a === void 0 ? void 0 : _a.projectPerson) && ((_b = targetEntity.project) === null || _b === void 0 ? void 0 : _b.projectPerson.role)) {
                return targetEntity.project.projectPerson.role;
            }
            else {
                return null;
            }
        case TimeRegistrationEntity.Project:
            if ((_c = targetEntity === null || targetEntity === void 0 ? void 0 : targetEntity.projectPerson) === null || _c === void 0 ? void 0 : _c.role) {
                return targetEntity.projectPerson.role;
            }
            else {
                return null;
            }
        case TimeRegistrationEntity.IdleTime:
            return null;
    }
}
/**
 * Get the default role to be used, using this priority list:
 * ProjectPerson.roleId > Task.roleId > Person.roleId > null
 * @param targetEntity
 * @param personRole
 */
export function getDefaultRole(targetEntity, personRole) {
    const projectPersonRole = getProjectPersonRole(targetEntity);
    if (projectPersonRole) {
        return projectPersonRole;
    }
    if ((targetEntity === null || targetEntity === void 0 ? void 0 : targetEntity.entity) === TimeRegistrationEntity.Task && targetEntity.role) {
        return targetEntity.role;
    }
    return personRole;
}
export function updateRoleOnProjectChange({ newDefaultRole, initialRole, projectId, previousProjectId, selectedRole, setSelectedRole, setShowMovedToProjectRoleWarning, }) {
    if (!!initialRole && // if editing a time reg with a role (internal time is loaded without a role)
        !!projectId && // and we have a project now
        !!previousProjectId && // and we have changed from a different project
        projectId !== previousProjectId && // and we have a project now
        !!newDefaultRole && // and the new task / project combo has a role
        (newDefaultRole === null || newDefaultRole === void 0 ? void 0 : newDefaultRole.id) !== (selectedRole === null || selectedRole === void 0 ? void 0 : selectedRole.id) // and that role is different from the selected role
    ) {
        setSelectedRole(newDefaultRole);
        setShowMovedToProjectRoleWarning(true);
    }
    else if (initialRole) {
        setShowMovedToProjectRoleWarning(false);
    }
}
