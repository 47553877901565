import React, {useEffect} from 'react';
import styled from 'styled-components';
import {ColoredIdIcon, DropdownHandler, NavBarIconButton} from '@forecast-it/design-system';
import {createFragmentContainer, graphql} from 'react-relay';
import {SOCKET_ACTION, SOCKET_EVENT_TYPE} from '../../../constants';
import {useHistory} from 'react-router-dom';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import {useRecentProjects} from '../../project-tab/projects/hooks/useRecentProjects';
import {useRecentPrograms} from '../../project-tab/programs/hooks/useRecentPrograms';
import {withSocketHandling} from '../../../socket/withSocketHandling';
import ProgramUtil from '../../shared/util/ProgramUtil';
import {projectUrlWithLastPage} from '../../../directApi';
import ProjectIndicatorJS from '../../shared/components/project-indicator/js/ProjectIndicatorJS';

const StyledLink = styled.a`
	justify-content: space-between;
	text-decoration: none;
	:hover {
		text-decoration: none;
		color: unset;
	}
`;

const StyledEmptyState = styled.div`
	font-weight: 400;
`;

export const DropDownButton = <NavBarIconButton aria-label="recents" icon="clock" />;

const RecentButton = ({viewer, setSocketConfig}) => {
	const history = useHistory();

	const recentProjects = useRecentProjects(true);
	const recentPrograms = useRecentPrograms(true);

	useEffect(() => {
		const socketEvents = [
			{
				type: SOCKET_EVENT_TYPE.PERSON_NOTIFICATION,
				action: SOCKET_ACTION.UPDATE,
				personIds: [viewer.backendId],
			},
		];
		setSocketConfig(socketEvents);
	}, []);

	const recentList = [...recentProjects.projects, ...recentPrograms.programs]
		.sort((a, b) => new Date(b.date) - new Date(a.date))
		.filter(recentItem => recentItem.id)
		.slice(0, 10);

	return (
		<DropdownHandler>
			<DropdownHandler.Trigger onClick={() => trackEvent('Navigation Bar Favorites Menu Button', 'Clicked')}>
				{DropDownButton}
			</DropdownHandler.Trigger>
			<DropdownHandler.Content>
				<DropdownHandler.Label>
					{recentList.length > 0 ? 'Recent' : <StyledEmptyState>No recent.</StyledEmptyState>}
				</DropdownHandler.Label>
				{recentList.map(p => (
					<DropdownHandler.Item key={p.id} asChild={true}>
						<StyledLink
							href={
								p.__typename === 'ProgramType'
									? `${ProgramUtil.programLink()}/${p.prefix}/overview`
									: projectUrlWithLastPage(
											p.prefix?.slice(2),
											p.legacyProjectAsSingleProject?.customProjectId
									  )
							}
							onClick={e => {
								e.preventDefault();
								trackEvent('Navigation Bar Favorites Menu Item', 'Clicked');
								history.push(
									p.__typename === 'ProgramType'
										? `${ProgramUtil.programLink()}/${p.prefix}/overview`
										: projectUrlWithLastPage(
												p.prefix?.slice(2),
												p.legacyProjectAsSingleProject?.customProjectId
										  )
								);
							}}
						>
							{p.name}
							{p.__typename === 'ProgramType' ? (
								<ColoredIdIcon color={p.color}>{p.prefix}</ColoredIdIcon>
							) : (
								<ProjectIndicatorJS project={p.legacyProjectAsSingleProject} />
							)}
						</StyledLink>
					</DropdownHandler.Item>
				))}
			</DropdownHandler.Content>
		</DropdownHandler>
	);
};
const RecentButtonQuery = graphql`
	query RecentButton_Query {
		viewer {
			actualPersonId
			component(name: "top_navigation_recent")
			...RecentButton_viewer
		}
	}
`;

export {RecentButtonQuery};

export default withSocketHandling(
	createFragmentContainer(RecentButton, {
		viewer: graphql`
			fragment RecentButton_viewer on Viewer {
				id
				backendId
			}
		`,
	})
);
