export default function getJiraCustomBlockType(node) {
	if (node.hasAttribute('data-slate-value')) {
		const dataSlateValue = node.getAttribute('data-slate-value');
		const unescapedDataString = dataSlateValue
			.replace(/&amp;/g, '&')
			.replace(/&quot;/g, '"')
			.replace(/&lt;/g, '<');
		const data = JSON.parse(unescapedDataString);
		return data.type;
	}
	return 'unstyled';
}
