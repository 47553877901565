import React, { useEffect, useState } from 'react';
import { ComboBox, DeprecatedCreativeModal as CreativeModal, DatePicker, FlexColumn, FlexRow, IconTooltip, NumberField, SubHeading, Text, TextField, } from '@forecast-it/design-system';
import styled from 'styled-components';
import { createFragmentContainer, graphql } from 'react-relay';
import { createInvoices, getCurrencyItems, getInvoicePeriodData } from './BulkInvoiceCreateLogic';
import { MODAL_TYPE, showModal } from '../../../generic_modal_conductor';
import { getNotNullishNodes, notNullishAndPredicate } from '../../../../../util/NotNullPredicate';
import CustomScrollDiv from '../../../../../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import { useIntl } from 'react-intl';
import { ForecastTooltipFormulaRenderer } from '../../../../../../../components/ForecastTooltipFormulaRenderer.js';
import { sortByStartDate } from '../../../../../../../components/new-ui/retainer_period/RetainerPeriodUtil';
import { trackEvent } from './../../../../../../../tracking/amplitude/TrackingV2';
import { projectUrl } from '../../../../../../../directApi';
import { hasFeatureFlag } from '../../../../../util/FeatureUtil';
import { RoundingEntities } from '../../../../../util/RoundingUtil';
const GridRow = styled.div `
	display: grid;
	grid-template-columns: auto 7% 12% 16% 16% 16%;
	column-gap: ${props => props.theme.sys.spacing[200]};
	width: 100%;
	border-bottom: solid 1px ${props => props.theme.sys.color.border.decorative};
	padding: ${props => props.theme.sys.spacing[300]};
	&:first-child {
		padding-top: ${props => props.theme.sys.spacing[100]};
	}
`;
function getAmount(invoice, tax) {
    return invoice ? invoice.quantity * invoice.unitPrice + invoice.quantity * invoice.unitPrice * tax : 0;
}
function getInvoices(invoices, currency, tax) {
    return invoices === null || invoices === void 0 ? void 0 : invoices.map(invoice => {
        return Object.assign(Object.assign({}, invoice), { currency: currency, tax: tax });
    });
}
function getSelectedPeriods(project, projectPeriodIds) {
    var _a;
    return getNotNullishNodes((_a = project === null || project === void 0 ? void 0 : project.retainerPeriods) === null || _a === void 0 ? void 0 : _a.edges).filter(period => period && projectPeriodIds.includes(period.id));
}
function getCurrency(project, company) {
    var _a;
    return ((_a = project === null || project === void 0 ? void 0 : project.rateCard) === null || _a === void 0 ? void 0 : _a.currency) || (company === null || company === void 0 ? void 0 : company.currency) || '';
}
function BulkInvoiceCreateModal({ viewer, projectPeriodIds, closeModal, onStay }) {
    const { project, company } = viewer;
    const { formatMessage } = useIntl();
    const hasRateRounding = hasFeatureFlag('rate_rounding');
    const projectOrCompanyCurrency = getCurrency(project, company);
    const periods = getSelectedPeriods(project, projectPeriodIds).sort(sortByStartDate);
    const [tax, setTax] = useState(0);
    const [currency, setCurrency] = useState(projectOrCompanyCurrency);
    const invoicePeriodData = getInvoicePeriodData({ periods, currency, project, company });
    const invoices = (invoicePeriodData === null || invoicePeriodData === void 0 ? void 0 : invoicePeriodData.filter(notNullishAndPredicate()).map(dto => {
        const [name, setName] = useState(dto.name);
        const [quantity, setQuantity] = useState(dto.quantity);
        const [unitPrice, setUnitPrice] = useState(dto.unitPrice);
        const [invoiceDate, setInvoiceDate] = useState(dto.invoiceDate);
        const [dueDate, setDueDate] = useState(dto.dueDate);
        const [errorMessage, setErrorMessage] = useState();
        return {
            period: dto.period,
            name,
            setName,
            quantity,
            setQuantity,
            unitPrice,
            setUnitPrice,
            invoiceDate,
            setInvoiceDate,
            dueDate,
            setDueDate,
            errorMessage,
            setErrorMessage,
        };
    })) || [];
    useEffect(() => {
        invoices.forEach((invoice, index) => {
            invoice.setUnitPrice(invoicePeriodData[index].unitPrice);
        });
    }, [currency]);
    const onSuccess = () => {
        showModal({
            type: MODAL_TYPE.ACTION_COMPLETE_GO_SOMEWHERE,
            title: 'Invoices created',
            content: 'Invoices have been created successfully, in Draft status.',
            linkButtonText: 'Go to Invoicing',
            link: `${projectUrl(project === null || project === void 0 ? void 0 : project.companyProjectId, project === null || project === void 0 ? void 0 : project.customProjectId)}/financials#invoicing`,
            closeButtonText: 'Stay here',
            onStay,
            dataCy: 'period-invoice-confirmation-modal',
        }, true);
        trackEvent('Bulk Retainer Period Invoices', 'Created', { count: periods.length || 0 });
        periods.forEach(period => {
            trackEvent('Retainer Period Invoice', 'Created', { locked: period.periodLocked });
        });
    };
    const handleSetInvoiceName = (invoice, value) => {
        invoice.setName(value);
        if (!value) {
            invoice.setErrorMessage(formatMessage({ id: 'common.required_field' }));
        }
        else if (value.length > 191) {
            invoice.setErrorMessage(formatMessage({ id: 'errors.name-too-long' }));
        }
        else {
            invoice.setErrorMessage(undefined);
        }
    };
    return (React.createElement(CreativeModal, { size: 'xxl', closeModal: closeModal, creativeButtonText: formatMessage({ id: 'invoicing.create_invoices' }), cancelButtonText: formatMessage({ id: 'common.cancel' }), onCreateHandler: () => createInvoices({
            invoices: getInvoices(invoices, currency, tax * 100),
            project: project,
            invoiceQuantityDecimals: company === null || company === void 0 ? void 0 : company.invoiceQuantityDecimals,
            onSuccess,
        }), onCancelHandler: closeModal, title: formatMessage({ id: 'invoicing.create_invoices' }), disabled: invoices === null || invoices === void 0 ? void 0 : invoices.some(invoice => !!invoice.errorMessage) },
        React.createElement(FlexColumn, null,
            React.createElement(GridRow, null,
                React.createElement(FlexColumn, null,
                    React.createElement(SubHeading, null, formatMessage({ id: 'common.project' })),
                    React.createElement(Text, null, project === null || project === void 0 ? void 0 : project.name)),
                React.createElement("div", null),
                React.createElement("div", null),
                React.createElement("div", null),
                React.createElement(NumberField, { label: formatMessage({ id: 'common.tax' }), value: tax, format: NumberField.FORMAT.PERCENT, onChange: setTax }),
                React.createElement(ComboBox, { label: formatMessage({ id: 'common.currency' }), items: getCurrencyItems(company), value: currency, onChange: setCurrency, width: ComboBox.WIDTH.FLEX }, currencyItem => (React.createElement(ComboBox.Item, { textValue: currencyItem.currencyCode },
                    React.createElement(Text, null, currencyItem.currencyCode))))),
            React.createElement("br", null),
            React.createElement("br", null),
            React.createElement(GridRow, null,
                React.createElement(SubHeading, null, formatMessage({ id: 'project_section.invoice_name' })),
                React.createElement(SubHeading, null, formatMessage({ id: 'common.quantity' })),
                React.createElement(SubHeading, null, formatMessage({ id: 'common.unit_price' })),
                React.createElement(FlexRow, { gap: 's' },
                    React.createElement(SubHeading, null, formatMessage({ id: 'common.amount' })),
                    React.createElement(IconTooltip, { width: 'l' },
                        React.createElement(ForecastTooltipFormulaRenderer, { matchDesignSystemFonts: true, items: [
                                {
                                    title: formatMessage({ id: 'common.amount' }),
                                    details: [
                                        formatMessage({ id: 'common.quantity' }),
                                        'x',
                                        formatMessage({ id: 'common.unit_price' }),
                                        '+',
                                        formatMessage({ id: 'common.tax' }),
                                    ],
                                },
                            ], translatedMessage: true }))),
                React.createElement(SubHeading, null, formatMessage({ id: 'invoicing.invoice_date' })),
                React.createElement(SubHeading, null, formatMessage({ id: 'common.due_date' }))),
            React.createElement(CustomScrollDiv, { autoHeight: true, autoHeightMax: '60vh' }, invoices === null || invoices === void 0 ? void 0 : invoices.map((invoice, index) => {
                var _a, _b;
                return (React.createElement(GridRow, null,
                    React.createElement(TextField, { "data-cy": `invoice-name-${index}`, onChange: e => handleSetInvoiceName(invoice, e.target.value), errorText: invoice.errorMessage, value: invoice.name || '' }),
                    React.createElement(NumberField, { "data-cy": `quantity-${index}`, minValue: 0, onChange: invoice.setQuantity, value: invoice.quantity }),
                    React.createElement(NumberField, { "data-cy": `unit-price-${index}`, minimumFractionDigits: 2, maximumFractionDigits: hasRateRounding ? RoundingEntities.RATE.decimals : 2, onChange: invoice.setUnitPrice, value: invoice.unitPrice }),
                    React.createElement(NumberField, { "data-cy": `amount-${index}`, minimumFractionDigits: 2, maximumFractionDigits: 2, disabled: true, value: getAmount(invoice, tax) }),
                    React.createElement(DatePicker, { "data-cy": `invoice-date-${index}`, onChange: invoice.setInvoiceDate, value: (_a = invoice.invoiceDate) !== null && _a !== void 0 ? _a : undefined }),
                    React.createElement(DatePicker, { "data-cy": `due-date-${index}`, onChange: invoice.setDueDate, value: (_b = invoice.dueDate) !== null && _b !== void 0 ? _b : undefined })));
            })))));
}
export const BulkInvoiceCreateModalQuery = graphql `
	query BulkInvoiceCreateModal_Query($projectId: ID) {
		viewer {
			component(name: "bulk_invoice_create_modal_query")
			actualPersonId
			...BulkInvoiceCreateModal_viewer @arguments(projectId: $projectId)
		}
	}
`;
export default createFragmentContainer(BulkInvoiceCreateModal, {
    viewer: graphql `
		fragment BulkInvoiceCreateModal_viewer on Viewer @argumentDefinitions(projectId: {type: "ID"}) {
			company {
				currency
				invoiceQuantityDecimals
				exchangeRates {
					edges {
						node {
							id
							currency
							rate
						}
					}
				}
			}
			project(internalId: $projectId) {
				id
				companyProjectId
				customProjectId
				name
				client {
					id
				}
				rateCard {
					id
					name
					currency
					parentRateCardId
					defaultRate
					rates(first: 10000) {
						edges {
							node {
								id
								rate
								startDate
								defaultRate
								role {
									id
									name
								}
							}
						}
					}
				}
				retainerPeriods(first: 100000) @connection(key: "Project_retainerPeriods", filters: []) {
					edges {
						node {
							id
							name
							startYear
							startMonth
							startDay
							endYear
							endMonth
							endDay
							periodPriceAmount
							periodHoursAmount
							periodBudgetType
							periodLocked
							periodLockedTime
							invoiced
						}
					}
				}
				timeRegistrations(first: 10000000) @connection(key: "Project_timeRegistrations", filters: []) {
					edges {
						node {
							id
							day
							month
							year
							createdAt
							updatedAt
							minutesRegistered
							billableMinutesRegistered
							invoiced
							price
							retainerConflictHandled
							rate
						}
					}
				}
			}
		}
	`,
});
