import React, {useState} from 'react';
import {
	DeprecatedButtonIconOnly as ButtonIconOnly,
	DeprecatedContentContainer as ContentContainer,
	FlexColumn,
	FlexItem,
	FlexRow,
	DeprecatedLink as Link,
	DeprecatedText as Text,
	Icon,
} from '@forecast-it/design-system';
import styled from 'styled-components';
import ForecastTooltip from '../../../shared/components/tooltips/ForecastTooltip';
import ProgramBudgetTypeContextMenu from './ProgramBudgetTypeContextMenu';
import {FormattedHTMLMessage, useIntl} from 'react-intl';
import SupressProgramBudgetTypeTipMessageMutation from '../../../../mutations/SupressProgramBudgetTypeTipMessageMutation';
import Util from '../../../../forecast-app/shared/util/util';
import {CAP_SETTING, PROGRAM_BUDGET_TYPE} from '../../../../constants';
import {NUMBER_TYPE} from '../../../../components/new-ui/project/project-budget-v3/util/BudgetUtils';
import {trackComplexEvent, trackEvent} from '../../../../tracking/amplitude/TrackingV2';
import {useHistory} from 'react-router-dom';
import {MODAL_TYPE, showModal} from '../../../shared/components/modals/generic_modal_conductor';
import ProgramUtil from '../../../../forecast-app/shared/util/ProgramUtil';
import {isALargerOrEqualToB} from '../../../shared/util/NumberUtil';

const ProgramBudgetTypeInfo = ({program, company, actualPersonId, hideContextMenu, showEditBudgetLink}) => {
	const currencySymbol = Util.GetCurrencySymbol(company.currency);

	const {formatMessage} = useIntl();
	const intl = useIntl();
	const history = useHistory();

	const [showContextMenu, setShowContextMenu] = useState(false);
	const [showSettingsBox, setShowSettingsBox] = useState(true);
	const [selectedProgram, setSelectedProgram] = useState({});
	const [bounds, setBounds] = useState({});

	const isOwner = ProgramUtil.canPersonManageProgram(program.members, actualPersonId);

	const allPeople = company.allPersons.edges.map(person => person.node);
	const currentPerson = allPeople.find(person => person.id === actualPersonId);

	const programsRefs = currentPerson.supressProgramBudgetTypeTipMessage.map(program => program.split('-')[1]);
	const suppressTipMessage = programsRefs.includes(program.id) || !isOwner ? true : null;

	const totalConfirmedRevenue = ProgramUtil.getTotalConfirmedRevenue(program);
	const capReached = isALargerOrEqualToB(totalConfirmedRevenue, program.budgetValue, 2);

	// For fixed price programs
	const allocatedRevenue =
		program.budgetType === PROGRAM_BUDGET_TYPE.FIXED_PRICE
			? program.projects.edges.reduce((accum, project) => accum + project.node.budget, 0)
			: 0;

	let hideCapSettingLink = false;

	// Track if change budget type suggestion pop-up is viewed. The condition used is the same one used
	// below to either show or not show the pop-up.
	if (showSettingsBox && !suppressTipMessage && program.budgetType === PROGRAM_BUDGET_TYPE.UNCAPPED) {
		trackEvent('Change Program Budget Type Suggestion Box', 'Viewed');
	}

	if (!isOwner) {
		hideContextMenu = true;
		hideCapSettingLink = true;
	}

	const toggleContextMenu = (e, program) => {
		setShowContextMenu(!showContextMenu);
		setSelectedProgram(program);
		setBounds(document.elementFromPoint(e.pageX, e.pageY).getBoundingClientRect());
	};

	const closeSettingsBox = () => {
		trackEvent('Change Program Budget Type Suggestion Box', 'Closed');
		setShowSettingsBox(!showSettingsBox);
		Util.CommitMutation(SupressProgramBudgetTypeTipMessageMutation, {
			key: `supress_program_budget_type_tip_message-${program.id}`,
			personId: actualPersonId,
			value: true,
		});
	};

	const formatNumber = (value, numberType, currencySymbol, intl) => {
		switch (numberType) {
			case NUMBER_TYPE.MONEY:
				return Util.getFormattedNumberWithCurrency(currencySymbol, value, intl);
			case NUMBER_TYPE.PERCENTAGE:
				return Util.getFormattedPercentage(value, intl);
			default:
				break;
		}
		return value;
	};

	const getProjectTypeTooltipDesc = budgetType => {
		switch (budgetType) {
			case PROGRAM_BUDGET_TYPE.UNCAPPED:
				return {
					name: 'common.program_uncapped_budget_label',
					tooltip: formatMessage({id: 'common.program_uncapped_budget_type_description'}),
				};
			case PROGRAM_BUDGET_TYPE.CAPPED:
				return {
					name: 'common.program_capped_budget_label',
					tooltip: formatMessage({id: 'common.program_capped_budget_type_description'}),
				};
			case PROGRAM_BUDGET_TYPE.FIXED_PRICE:
				return {
					name: 'common.program_fixed_price_budget_label',
					tooltip: formatMessage({id: 'common.program_fixed_price_budget_type_description'}),
				};
			default:
				return {
					name: 'common.program_uncapped_budget_label',
					tooltipDesc: formatMessage({id: 'common.program_uncapped_budget_type_description'}),
				};
		}
	};

	const getCapSettingName = revenueSetting => {
		switch (revenueSetting) {
			case CAP_SETTING.PERMITTED_UNTIL_CAP:
				return formatMessage({id: 'program.cap_setting.permitted_until_cap'});
			case CAP_SETTING.ALWAYS_PERMITTED:
				return formatMessage({id: 'program.cap_setting.always_permitted'});
			case CAP_SETTING.ALWAYS_DISABLED:
				return formatMessage({id: 'program.cap_setting.always_disabled'});
		}
	};

	const openChangeBudgetModal = () => {
		trackEvent('Edit Program Budget Link', 'Clicked', {location: 'From Project Budget Type Info'});
		showModal({
			type: MODAL_TYPE.CHANGE_PROGRAM_BUDGET_VALUE,
			program: program,
			company: company,
		});
	};

	const openCapSettingModal = () => {
		trackComplexEvent('Change Cap Setting Link', 'Clicked', {location: 'From Project Budget Type Info'});
		showModal({
			type: MODAL_TYPE.CHANGE_CAP_SETTING,
			program: program,
			company: company,
		});
	};

	const SettingsBox = styled.div`
		border: 1px solid #e7e7f3;
		width: 610px;
		padding: 15px;
		border-radius: 5px;
		display: ${props => `${props.display}`};
		background: #f7f7fe;
	`;

	return (
		<FlexColumn gap={'xl'}>
			<FlexRow alignItems={'flex-end'} gap={'xl'}>
				<ContentContainer width={'auto'} height={program.budgetType === PROGRAM_BUDGET_TYPE.CAPPED ? '250px' : '170px'}>
					<FlexColumn gap={'xl'}>
						<FlexRow justifyContent="space-between" alignItems={'center'} gap={'xl'}>
							<FlexItem>
								<Text type={'label'} variant={'m'} color={'primary'}>
									{<FormattedHTMLMessage id={getProjectTypeTooltipDesc(program.budgetType).name} />}
								</Text>
								<ForecastTooltip
									placement={'bottom'}
									content={<Text>{getProjectTypeTooltipDesc(program.budgetType).tooltip}</Text>}
								>
									<Icon icon={'help'} size={'s'} />
								</ForecastTooltip>
							</FlexItem>
							{!hideContextMenu && (
								<FlexItem>
									<ButtonIconOnly
										type={'ghost'}
										icon={'more'}
										size={'s'}
										onClick={e => toggleContextMenu(e, program)}
									/>
								</FlexItem>
							)}
						</FlexRow>
						<SettingsBox
							display={
								showSettingsBox && !suppressTipMessage && program.budgetType === PROGRAM_BUDGET_TYPE.UNCAPPED
									? 'block'
									: 'none'
							}
						>
							<FlexRow alignItems={'center'} gap={'xl'}>
								<FlexItem>
									<Text type={'label'} variant={'m'} color={'primary'}>
										{formatMessage({id: 'common.program_budget_settings_box_message'})}
										<Link
											target="_blank"
											rel="noopener noreferrer"
											style={{cursor: 'pointer'}}
											onClick={() => {
												trackEvent('Program Budget Settings Link', 'Clicked', {
													location: 'From Change Program Budget Type Suggestion Box',
												});
												history.replace(
													`${ProgramUtil.programLink()}/${program.prefix}/settings/general`
												);
											}}
										>
											{formatMessage({id: 'common.got_to_settings'})}
										</Link>
									</Text>
								</FlexItem>
								<FlexItem>
									<ButtonIconOnly
										type={'ghost'}
										icon={'close'}
										size={'s'}
										onClick={e => closeSettingsBox(e)}
									/>
								</FlexItem>
							</FlexRow>
						</SettingsBox>
						{program.budgetType === PROGRAM_BUDGET_TYPE.CAPPED ||
						program.budgetType === PROGRAM_BUDGET_TYPE.FIXED_PRICE ? (
							<>
								<div style={{width: '600px'}}>
									<FlexRow justifyContent="space-between" alignItems={'center'} gap={'xl'}>
										<FlexColumn gap={'s'}>
											<FlexItem>
												<Text type={'label'} variant={'m'} color={'primary'}>
													{program.budgetType === PROGRAM_BUDGET_TYPE.CAPPED
														? formatMessage({id: 'program.revenue_cap'})
														: formatMessage({id: 'program.program_fixed_price_amount.title'})}
												</Text>
											</FlexItem>
											<FlexItem>
												<FlexRow gap={'s'}>
													<Text
														type={'heading'}
														variant={'xl'}
														color={'primary'}
														data-cy={'programBudgetValue'}
													>
														{formatNumber(
															program.budgetValue,
															NUMBER_TYPE.MONEY,
															currencySymbol,
															intl
														)}
													</Text>
													{showEditBudgetLink && (
														<Text type={'label'} variant={'m'} color={'primary'}>
															<Link onClick={openChangeBudgetModal}>
																{program.budgetType === PROGRAM_BUDGET_TYPE.CAPPED
																	? formatMessage({id: 'program_budget.edit_cap'})
																	: formatMessage({id: 'project_budget.edit_fixed_price'})}
															</Link>
														</Text>
													)}
												</FlexRow>
											</FlexItem>
											{program.budgetType === PROGRAM_BUDGET_TYPE.CAPPED && (
												<div style={{height: '16px'}}></div>
											)}
											{/* Dummy div to match the spacing of the column beside */}
										</FlexColumn>
										{program.budgetType === PROGRAM_BUDGET_TYPE.CAPPED && (
											<FlexColumn gap={'s'}>
												<FlexRow gap={'s'}>
													<Text type={'label'} variant={'m'} color={'primary'}>
														{formatMessage({id: 'common.confirmed_revenue'})}
													</Text>
													<ForecastTooltip
														placement={'bottom'}
														content={
															<Text>
																{formatMessage({id: 'common.confirmed_revenue_tooltip'})}
															</Text>
														}
													>
														<Icon icon={'help'} size={'s'} />
													</ForecastTooltip>
													{capReached && (
														<Text type={'label'} variant={'m'} color={'primary'}>
															({formatMessage({id: 'program.cap_reached'})})
														</Text>
													)}
												</FlexRow>
												<FlexItem>
													<FlexRow gap={'s'}>
														<Text
															type={'heading'}
															variant={'xl'}
															color={'primary'}
															data-cy={'programConfirmedRevenue'}
														>
															{formatNumber(
																totalConfirmedRevenue,
																NUMBER_TYPE.MONEY,
																currencySymbol,
																intl
															)}
														</Text>
														{program.isRevenueLocked && (
															<ForecastTooltip
																placement={'bottom'}
																content={
																	<FlexColumn gap={'s'}>
																		<Text type={'heading'} variant={'s'}>
																			{formatMessage({
																				id: 'program.additional_revenue_disabled',
																			})}
																		</Text>
																		<Text>
																			{formatMessage({
																				id: 'program.additional_revenue_disabled_short_info',
																			})}
																		</Text>
																	</FlexColumn>
																}
															>
																<Icon icon="lockClosed" size="s" />
															</ForecastTooltip>
														)}
													</FlexRow>
												</FlexItem>
												<FlexItem>
													<FlexRow>
														<Text
															type={'heading'}
															variant={'s'}
															color={'primary'}
															data-cy={'programRemainingBudget'}
														>
															(
															{formatNumber(
																program.budgetValue - totalConfirmedRevenue,
																NUMBER_TYPE.MONEY,
																currencySymbol,
																intl
															)}{' '}
															remaining)
														</Text>
													</FlexRow>
												</FlexItem>
											</FlexColumn>
										)}
										{program.budgetType === PROGRAM_BUDGET_TYPE.FIXED_PRICE ? (
											<FlexColumn>
												<FlexRow justifyContent="flex-end" gap={'s'}>
													<FlexItem>
														<FlexColumn gap={'s'}>
															<div style={{textAlign: 'right'}}>
																<Text
																	justifyContent={'end'}
																	type={'label'}
																	variant={'m'}
																	color={'primary'}
																>
																	{formatMessage({
																		id: 'program_budget.allocated_to_projects',
																	})}
																</Text>
															</div>
															<div style={{textAlign: 'right'}}>
																<Text type={'label'} variant={'m'} color={'primary'}>
																	{formatMessage({
																		id: 'program_budget.not_allocated_to_projects',
																	})}
																</Text>
															</div>
														</FlexColumn>
													</FlexItem>
													<FlexItem>
														<FlexColumn gap={'s'}>
															<Text
																type={'label'}
																variant={'m'}
																color={'primary'}
																data-cy={'programAllocation'}
															>
																{formatNumber(
																	allocatedRevenue,
																	NUMBER_TYPE.MONEY,
																	currencySymbol,
																	intl
																)}
															</Text>
															<Text
																type={'label'}
																variant={'m'}
																color={'primary'}
																data-cy={'programRemainingBudget'}
															>
																{formatNumber(
																	program.budgetValue - allocatedRevenue,
																	NUMBER_TYPE.MONEY,
																	currencySymbol,
																	intl
																)}
															</Text>
														</FlexColumn>
													</FlexItem>
												</FlexRow>
											</FlexColumn>
										) : null}
									</FlexRow>
								</div>
								{program.budgetType === PROGRAM_BUDGET_TYPE.CAPPED && (
									<FlexRow gap={'s'}>
										<Text>{formatMessage({id: 'common.additional_revenue'})}:</Text>
										<Text variant="bold">{getCapSettingName(program.revenueSetting)}</Text>
										<ForecastTooltip
											placement={'bottom'}
											content={
												program.revenueSetting === CAP_SETTING.PERMITTED_UNTIL_CAP ? (
													<FlexColumn gap={'s'}>
														<Text type={'heading'} variant={'s'}>
															{formatMessage({
																id: 'program.revenue_permitted_until_cap_tooltip_header',
															})}
														</Text>
														<Text>
															{formatMessage({
																id: 'program.revenue_permitted_until_cap_tooltip_info1',
															})}
														</Text>
														<Text>
															{formatMessage({
																id: 'program.revenue_permitted_until_cap_tooltip_info2',
															})}
														</Text>
													</FlexColumn>
												) : program.revenueSetting === CAP_SETTING.ALWAYS_PERMITTED ? (
													<FlexColumn gap={'s'}>
														<Text type={'heading'} variant={'s'}>
															{formatMessage({
																id: 'program.revenue_always_permitted_tooltip_header',
															})}
														</Text>
														<Text>
															{formatMessage({
																id: 'program.revenue_always_permitted_tooltip_info',
															})}
														</Text>
													</FlexColumn>
												) : program.revenueSetting === CAP_SETTING.ALWAYS_DISABLED ? (
													<FlexColumn gap={'s'}>
														<Text type={'heading'} variant={'s'}>
															{formatMessage({
																id: 'program.revenue_always_disabled_tooltip_header',
															})}
														</Text>
														<Text>
															{formatMessage({
																id: 'program.revenue_always_disabled_tooltip_info',
															})}
														</Text>
													</FlexColumn>
												) : null
											}
										>
											<Icon icon={'help'} size={'s'} />
										</ForecastTooltip>
										{!hideCapSettingLink && (
											<Link onClick={openCapSettingModal}>
												{formatMessage({id: 'common.change_settings'})}
											</Link>
										)}
									</FlexRow>
								)}
								{program.budgetType === PROGRAM_BUDGET_TYPE.CAPPED &&
									program.revenueSetting === CAP_SETTING.PERMITTED_UNTIL_CAP &&
									(capReached ? (
										<Text>
											<FormattedHTMLMessage id="program.revenue_disabled_info" />
										</Text>
									) : (
										<Text>
											<FormattedHTMLMessage id="program.revenue_permitted_info" />
										</Text>
									))}
							</>
						) : null}
					</FlexColumn>
				</ContentContainer>
			</FlexRow>
			{showContextMenu ? (
				<ProgramBudgetTypeContextMenu
					program={selectedProgram}
					closeContextMenu={() => setShowContextMenu(false)}
					bounds={bounds}
				/>
			) : null}
		</FlexColumn>
	);
};

export default ProgramBudgetTypeInfo;
