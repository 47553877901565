import React, {useState, useRef} from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import uuid from 'uuid';

const RadioDropdownContainer = styled.div`
	display: flex;
	flex-direction: row;
	color: #393946;
	position: relative;

	& > label:hover {
		border-color: #605dec;
	}
	& > input:checked + label {
		background: #605dec;
		border-color: #605dec;
		color: #ffffff;
	}
	& > input:focus + label {
		border-color: #605dec;
		outline: 4px solid #e5f4ff;
	}

	&::after {
		content: '';
		display: block;
		position: absolute;
		border-radius: 2px;
		border-bottom: 2px solid #393946;
		border-right: 2px solid #393946;
		width: 8px;
		height: 8px;
		top: 50%;
		right: 24px;
		transform: translate(0, -65%) rotate(45deg);
		pointer-events: none;
	}
`;

const HiddenRadio = styled.input.attrs({type: 'radio'})`
	opacity: 0;
	filter: alpha(opacity=0);
	position: absolute;
	top: 0;
	left: 0;
`;

const Dropdown = styled.label`
	height: 40px;
	line-height: 38px;
	font-size: 13px;
	text-align: center;
	cursor: pointer;
	flex: 1 1 0px;
	border-width: 1px;
	border-style: solid;
	border-color: ${props => (props.error ? '#FEB6B3' : '#e7e7f3')};
	border-radius: 24px;
	padding: 0px 43px 0px 24px;
`;

const DropdownOptions = styled.div`
	position: absolute;
	background: #ffffff;
	padding: 8px 0px;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
	border-radius: 4px;
`;

const DropdownOption = styled.div`
	height: 32px;
	padding: 8px 16px;
	font-size: 14px;
	line-height: 16px;
	cursor: pointer;
	background: ${props => (props.active ? '#F7F7FE' : '#ffffff')};
	&:hover {
		background: #f7f7fe;
	}
`;

const RadioDropdown = ({
	id,
	name,
	value = 'Default Value',
	options = [{value: 'default_option_value', label: 'Default Option'}],
	onOptionSelect = () => {},
	onClick = () => {},
	error,
}) => {
	const [expanded, setExpanded] = useState(false);
	const [selected, setSelected] = useState(options.findIndex(option => option.value === value) ?? null);
	const [active, setActive] = useState(null);
	const [dropdownStyle, setDropdownStyle] = useState({});
	const dropdownRef = useRef(null);
	const _uuid = useRef(uuid.v4());

	const handleKeyPress = e => {
		if (expanded) {
			if (e.keyCode !== 9 && e.key !== 'Tab') {
				// Prevent default for anything other than Tab
				e.preventDefault();
			}

			if (e.keyCode === 27 || e.key === 'Escape') {
				// Escape
				setExpanded(false);
			} else if (e.keyCode === 13 || e.keyCode === 32 || e.keyCode === '' || e.key === 'Enter') {
				//Enter / Space
				if (active !== null) {
					setSelected(active);
					onOptionSelect(options[active]);
				}
				setExpanded(false);
			} else if (e.keyCode === 40 || e.key === 'ArrowDown') {
				// Down arrow
				if (active !== null) {
					setActive((active + 1) % options.length);
				} else {
					setActive(0);
				}
			} else if (e.keyCode === 38 || e.key === 'ArrowUp') {
				// Alt + Up arrow closes the dropdown
				if (e.altKey) {
					setExpanded(false);
					return;
				}
				// Up arrow
				if (active) {
					setActive((active - 1) % options.length);
				} else {
					setActive(options.length - 1);
				}
			} else if (e.keyCode === 35 || e.key === 'End') {
				// End - Select last option
				setActive(options.length - 1);
			} else if (e.keyCode === 36 || e.key === 'Home') {
				// Home - Select first option
				setActive(0);
			}
		} else if ((e.keyCode === 40 || e.key === 'ArrowDown') && e.altKey) {
			// Alt + Down arrow opens the dropdown
			setExpanded(true);
		}
	};

	return (
		<RadioDropdownContainer>
			<HiddenRadio
				id={id || value}
				name={name}
				value={value}
				onClick={() => {
					onClick(value);
					setExpanded(!expanded);
				}}
				onFocus={() => {
					if (dropdownRef) {
						const offset = dropdownRef.current.getBoundingClientRect();
						if (offset) {
							setDropdownStyle({
								bottom: window.innerHeight - offset.top,
								left: offset.left,
								width: undefined,
								marginBottom: 0,
							});
						}
					}
				}}
				onBlur={e => {
					const newTarget = e.type.includes('key')
						? null
						: e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11
					if (newTarget === dropdownRef.current) return;
					setExpanded(false);
				}}
				onKeyDown={handleKeyPress}
			/>
			<Dropdown
				htmlFor={id || value}
				error={error}
				role="combobox"
				aria-controls={`options-${_uuid.current}`}
				aria-expanded={expanded}
				aria-activedescendant={active !== null ? 'select_option_' + active : undefined}
				ref={dropdownRef}
				tabIndex="-1"
			>
				{selected !== null ? options[selected].label || options[selected].value : value}
			</Dropdown>
			{expanded && options.length
				? ReactDOM.createPortal(
						<DropdownOptions role="listbox" id={`options-${_uuid.current}`} style={dropdownStyle}>
							{options.map((option, index) => (
								<DropdownOption
									title={option.description ? option.description : null}
									id={'select_option_' + index}
									role="option"
									tabIndex={-1}
									key={index}
									active={active === index}
									selected={selected === index}
									aria-selected={selected === index}
									onMouseDown={e => {
										setSelected(index);
										onOptionSelect(options[index]);
										setExpanded(false);
									}}
								>
									{selected === index ? '✓    ' : ''}
									{option.label || option.value}
								</DropdownOption>
							))}
						</DropdownOptions>,
						document.querySelector('#root-portal-container')
				  )
				: null}
		</RadioDropdownContainer>
	);
};

export default RadioDropdown;
