import React, {Component} from 'react';

class favouriteIcon extends Component {
    onKeyDown(e) {
        if (e.key === 'Enter') {
            this.props.onClick && this.props.onClick(e);
        }
    }

    render() {
        const width = this.props.customWidth ? this.props.customWidth : '14';
        const height = this.props.customHeight ? this.props.customHeight : '14';

        return (
            <span className={this.props.className ? this.props.className + '-title' : ''} title={this.props.title}
                  data-cy={this.props.cy} onClick={this.props.onClick} onKeyDown={this.onKeyDown.bind(this)}
                  tabIndex="0">
				<svg
                    className={this.props.className ? this.props.className : ''}
                    onMouseEnter={this.props.handleHover ? this.props.handleHover : null}
                    onMouseLeave={this.props.handleHover ? this.props.handleHover : null}
                    xmlns="http://www.w3.org/2000/svg"
                    width={width}
                    height={height}
                    viewBox={'0 0 14 14'}
                >
					{this.props.active ? (
                        <path
                            fill={this.props.disabled ? '#535353' : '#F6DA1A'}
                            fillRule="nonzero"
                            d="M3.46 13.798a1.391 1.391 0 0 1-1.9-.45 1.352 1.352 0 0 1-.141-1.12l1.183-3.753L.445 6.506a1.354 1.354 0 0 1-.079-1.93c.262-.28.63-.439 1.016-.439h2.926L5.73.832A1.388 1.388 0 0 1 8.272.83l1.424 3.306h2.922c.763 0 1.382.612 1.382 1.366 0 .38-.161.745-.445 1.003l-2.157 1.969 1.183 3.753c.227.72-.18 1.485-.908 1.71-.38.117-.793.066-1.133-.14L7 11.652l-3.54 2.146z"
                        />
                    ) : (
                        <path
                            fill={this.props.hovered && !this.props.disabled ? '#F6DA1A' : '#535353'}
                            fillRule="nonzero"
                            d="M3.46 13.798a1.391 1.391 0 0 1-1.9-.45 1.352 1.352 0 0 1-.141-1.12l1.183-3.753L.445 6.506a1.354 1.354 0 0 1-.079-1.93c.262-.28.63-.439 1.016-.439h2.926L5.73.832A1.388 1.388 0 0 1 8.272.83l1.424 3.306h2.922c.763 0 1.382.612 1.382 1.366 0 .38-.161.745-.445 1.003l-2.157 1.969 1.183 3.753c.227.72-.18 1.485-.908 1.71-.38.117-.793.066-1.133-.14L7 11.652l-3.54 2.146zm7.636-.895c.078.047.173.06.261.032a.314.314 0 0 0 .21-.395l-1.384-4.387 2.652-2.419a.313.313 0 0 0-.217-.546H8.994l-1.7-3.946a.32.32 0 0 0-.587 0L5.011 5.189H1.382a.32.32 0 0 0-.235.101.313.313 0 0 0 .018.445l2.652 2.42-1.384 4.386a.312.312 0 0 0 .033.26.321.321 0 0 0 .438.103L7 10.42l4.096 2.483z"
                        />
                    )}
				</svg>
			</span>
        );
    }
}

export default favouriteIcon;
