import React, { useMemo, useState } from 'react';
import { ComboBox, Text } from '@forecast-it/design-system';
import { getCompanyRoles, getDisabledRoleIds, getRoleWarningTagOrUndefined, } from '../../../modals/time-registration/time-registration-modal/util/TimeRegistrationModalRoleUtil';
import { TRACKING_OBJECTS } from '../../../../../../tracking/amplitude/constants/TrackingObjects';
import { trackEvent } from '../../../../../../tracking/amplitude/TrackingV2';
import { createFragmentContainer, graphql } from 'react-relay';
import { FormattedHTMLMessage } from 'react-intl';
function getErrorText(roleId, roles) {
    const roleWarningTag = getRoleWarningTagOrUndefined(roleId, roles);
    return roleWarningTag ? React.createElement(FormattedHTMLMessage, { id: "project_person.deactivated_role" }) : undefined;
}
const SkylineRoleDropdown = ({ viewer, role, controlled, onSelect, projectId }) => {
    var _a;
    const [internalRole, setInternalRole] = useState(role);
    const roles = useMemo(() => { var _a; return getCompanyRoles((_a = viewer.company) === null || _a === void 0 ? void 0 : _a.roles, projectId, role); }, [(_a = viewer.company) === null || _a === void 0 ? void 0 : _a.roles, projectId]);
    const roleErrorText = useMemo(() => getErrorText(controlled ? role === null || role === void 0 ? void 0 : role.id : internalRole === null || internalRole === void 0 ? void 0 : internalRole.id, roles), [controlled ? role === null || role === void 0 ? void 0 : role.id : internalRole === null || internalRole === void 0 ? void 0 : internalRole.id, roles]);
    function handleTimeRegRoleChange(roleId) {
        const newRole = roles.find(role => role.id === roleId);
        onSelect(newRole);
        if (!controlled) {
            setInternalRole(newRole);
        }
        trackEvent(TRACKING_OBJECTS.TIME_REGISTRATION_MODAL, 'Role Changed', {
            timeRegistrationRoleName: newRole === null || newRole === void 0 ? void 0 : newRole.name,
        });
    }
    return (React.createElement(ComboBox, { "data-cy": 'role-combobox', label: 'Role', defaultItems: roles, value: controlled ? role === null || role === void 0 ? void 0 : role.id : internalRole === null || internalRole === void 0 ? void 0 : internalRole.id, onChange: handleTimeRegRoleChange, width: 'flex', errorText: roleErrorText, showErrorAsIconTooltip: true, disabledKeys: getDisabledRoleIds(roles) }, item => (React.createElement(ComboBox.Item, { textValue: item.name || '' },
        React.createElement(Text, null, item.name)))));
};
const SkylineRoleDropdownQuery = graphql `
	query SkylineRoleDropdown_Query($projectId: ID) {
		viewer {
			component(name: "role_dropdown")
			actualPersonId
			id
			...SkylineRoleDropdown_viewer @arguments(projectId: $projectId)
		}
	}
`;
export { SkylineRoleDropdownQuery };
export default createFragmentContainer(SkylineRoleDropdown, {
    viewer: graphql `
		fragment SkylineRoleDropdown_viewer on Viewer @argumentDefinitions(projectId: {type: "ID"}) {
			company {
				roles(includeDisabled: false, projectId: $projectId) {
					edges {
						node {
							id
							name
						}
					}
				}
			}
		}
	`,
});
