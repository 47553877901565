import React from 'react';
import {useIntl} from 'react-intl';
import {Hexagon} from 'web-components';
import Util from '../../../util/util';
import Styled from 'styled-components';
import {profilePicSrc} from '../../../../../directApi';

const PersonDisplay = Styled.div`
	display: flex;
	align-items: center; 
 `;

const Name = Styled.div`
	padding-left: 10px;
 `;

const ProgressTable = Styled.table`

 
	font-size: 13px;	
	th, td {
  	padding-left: 10px;
  	padding-right: 10px;
  	border-bottom: 1px solid #DDD;  	
  	text-align: left;
  	white-space: nowrap;
	}

	th{
		padding-bottom: 10px;
		font-color: #BBB;
	}
	
	td:first-child{
		text-align: right;
	}
	
	td:last-child{
		white-space: normal;
		width: 99%;
	}
	
`;

function Person({person}) {
	const fullName = person.firstName + ' ' + person.lastName;
	return (
		<PersonDisplay>
			<Hexagon text={fullName} imgUrl={profilePicSrc(person.profilePictureId)} />
			<Name>{fullName}</Name>
		</PersonDisplay>
	);
}

const ProgressHistory = ({project}) => {
	const {formatMessage} = useIntl();

	return (
		<ProgressTable>
			<thead>
				<tr>
					<th>{formatMessage({id: 'common.progress'})}</th>
					<th>{formatMessage({id: 'common.date'})}</th>
					<th>{formatMessage({id: 'common.added_by'})}</th>
				</tr>
			</thead>

			<tbody>
				{project.progressHistory.map(historyItem => {
					const createdAt = historyItem.createdAt
						? Util.CreateNonUtcMomentDateFromString(historyItem.createdAt).format('ll')
						: '';

					return (
						<tr>
							<td>{historyItem.progress}%</td>
							<td>{createdAt}</td>
							<td>
								<Person person={historyItem.createdBy}></Person>
							</td>
						</tr>
					);
				})}
			</tbody>
		</ProgressTable>
	);
};

export default ProgressHistory;
