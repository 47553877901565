import {GLOBAL_FILTER_OPERATOR, GLOBAL_FILTER_FIELD} from '../../../constants';

export const getMyWorkOverdueSearchQuery = selectedPersonId => {
	return {
		filters: [
			{field: GLOBAL_FILTER_FIELD.ASSIGNED, operator: GLOBAL_FILTER_OPERATOR.IN, value: selectedPersonId},
			{field: GLOBAL_FILTER_FIELD.DEADLINE, operator: GLOBAL_FILTER_OPERATOR.IS, value: ['overdue']},
			{field: GLOBAL_FILTER_FIELD.STATUS, operator: GLOBAL_FILTER_OPERATOR.IS, value: ['TODO', 'INPROGRESS']},
		],
	};
};

export const getMyWorkTasksSearchQueryV2 = (selectedPersonId, startOfWeekDate, endOfWeekDate) => {
	return {
		filters: [
			{field: GLOBAL_FILTER_FIELD.ASSIGNED, operator: GLOBAL_FILTER_OPERATOR.IN, value: selectedPersonId},
			{field: GLOBAL_FILTER_FIELD.START_DATE, operator: GLOBAL_FILTER_OPERATOR.LESS_OR_EQUAL, value: [endOfWeekDate]},
			{field: GLOBAL_FILTER_FIELD.END_DATE, operator: GLOBAL_FILTER_OPERATOR.GREATER_OR_EQUAL, value: [startOfWeekDate]},
			{field: GLOBAL_FILTER_FIELD.STATUS, operator: GLOBAL_FILTER_OPERATOR.IS, value: ['TODO', 'INPROGRESS']},
		],
	};
};

export const getMyWorkNoDatesTasksSearchQueryV2 = (selectedPersonId, endOfWeekDate) => {
	return {
		filters: [
			{field: GLOBAL_FILTER_FIELD.ASSIGNED, operator: GLOBAL_FILTER_OPERATOR.IN, value: selectedPersonId},
			{
				field: GLOBAL_FILTER_FIELD.START_DATE,
				operator: GLOBAL_FILTER_OPERATOR.LESS_OR_EQUAL,
				value: [endOfWeekDate, 'NULL'],
			},
			{field: GLOBAL_FILTER_FIELD.END_DATE, operator: GLOBAL_FILTER_OPERATOR.IS, value: ['NULL']},
			{field: GLOBAL_FILTER_FIELD.STATUS, operator: GLOBAL_FILTER_OPERATOR.IS, value: ['TODO', 'INPROGRESS']},
		],
	};
};

export const getMyWorkDoneTasksSearchQuery = (selectedPersonId, startOfWeekDate, endOfWeekDate) => {
	return {
		filters: [
			{field: GLOBAL_FILTER_FIELD.ASSIGNED, operator: GLOBAL_FILTER_OPERATOR.IN, value: selectedPersonId},
			{field: GLOBAL_FILTER_FIELD.SET_DONE_AT, operator: GLOBAL_FILTER_OPERATOR.LESS_OR_EQUAL, value: [endOfWeekDate]},
			{
				field: GLOBAL_FILTER_FIELD.SET_DONE_AT,
				operator: GLOBAL_FILTER_OPERATOR.GREATER_OR_EQUAL,
				value: [startOfWeekDate],
			},
			{field: GLOBAL_FILTER_FIELD.STATUS, operator: GLOBAL_FILTER_OPERATOR.IS, value: ['DONE']},
		],
	};
};

export const getMyWorkStatsSearchQuery = (selectedPersonId, showThisWeek) => {
	return {
		filters: [
			{field: GLOBAL_FILTER_FIELD.ASSIGNED, operator: GLOBAL_FILTER_OPERATOR.IN, value: selectedPersonId},
			{
				field: GLOBAL_FILTER_FIELD.DEADLINE,
				operator: GLOBAL_FILTER_OPERATOR.IS,
				value: showThisWeek ? ['this_week'] : ['next_week'],
			},
		],
	};
};
