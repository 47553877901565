/*global google*/
import React from 'react';
import loadScript from 'load-script';
import PropTypes from 'prop-types';
import GooglePickerNew from './google_picker_new';

const GOOGLE_SDK_URL = 'https://apis.google.com/js/api.js';

let scriptLoadingStarted = false;

const clientId = '142486744568-qnkgblh2k4t13g5skjjvq1k8c3otncpa.apps.googleusercontent.com';
const developerKey = 'AIzaSyB41w5h2L-MhKlkduTJb7r1AVhe60dVmD0';
const scope = ['https://www.googleapis.com/auth/drive.file'];

class GooglePickerOld extends React.Component {
	static propTypes = {
		children: PropTypes.node,
		authImmediate: PropTypes.bool,
		origin: PropTypes.string,
		onChange: PropTypes.func,
		multiselect: PropTypes.bool,
		navHidden: PropTypes.bool,
		disabled: PropTypes.bool,
	};

	static defaultProps = {
		onChange: () => {},
		authImmediate: false,
		multiselect: false,
		navHidden: false,
		disabled: false,
	};

	constructor(props) {
		super(props);

		this.onApiLoad = this.onApiLoad.bind(this);
		this.onChoose = this.onChoose.bind(this);
	}

	componentDidMount() {
		if (this.isGoogleReady()) {
			// google api is already exists
			// init immediately
			this.onApiLoad();
		} else if (!scriptLoadingStarted) {
			// load google api and the init
			scriptLoadingStarted = true;
			loadScript(GOOGLE_SDK_URL, this.onApiLoad);
		} else {
			// is loading
		}
	}

	isGoogleReady() {
		return !!window.gapi;
	}

	isGoogleAuthReady() {
		return !!window.gapi.auth;
	}

	isGooglePickerReady() {
		return !!window.google.picker;
	}

	onApiLoad() {
		window.gapi.load('auth');
		window.gapi.load('picker');
	}

	doAuth(callback) {
		window.gapi.auth.authorize(
			{
				client_id: clientId,
				scope: scope,
				immediate: this.props.authImmediate,
				prompt: 'select_account',
			},
			callback
		);
	}

	onChoose() {
		if (!this.isGoogleReady() || !this.isGoogleAuthReady() || !this.isGooglePickerReady() || this.props.disabled) {
			return null;
		}

		const token = window.gapi.auth.getToken();
		const oauthToken = token && token.access_token;

		if (oauthToken) {
			this.createPicker(oauthToken);
		} else {
			this.doAuth(({access_token}) => {
				if (access_token) {
					this.createPicker(access_token);
				}
			});
		}
	}

	handleKeyDown(e) {
		if (e.key === 'Enter') {
			e.nativeEvent.stopImmediatePropagation();
			this.onChoose();
		}
	}

	createPicker(oauthToken) {
		const docsView = new google.picker.DocsView().setIncludeFolders(true);
		const driveView = new google.picker.DocsView().setIncludeFolders(true).setEnableDrives(true);

		if (this.props.mimeTypes) {
			docsView.setMimeTypes(this.props.mimeTypes.join(','));
			driveView.setMimeTypes(this.props.mimeTypes.join(','));
		}

		const picker = new window.google.picker.PickerBuilder()
			.enableFeature(google.picker.Feature.SUPPORT_TEAM_DRIVES)
			.addView(docsView)
			.addView(driveView)
			.setOAuthToken(oauthToken)
			.setDeveloperKey(developerKey)
			.setCallback(this.props.onChange);

		if (this.props.origin) {
			picker.setOrigin(this.props.origin);
		}

		if (this.props.multiselect) {
			picker.enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED);
		}

		picker.build().setVisible(true);
	}

	render() {
		const style = this.props.newUI ? {display: 'flex'} : {};
		return (
			<div
				className={this.props.className}
				style={style}
				onClick={this.onChoose.bind(this)}
				tabIndex={0}
				onKeyDown={this.handleKeyDown.bind(this)}
			>
				{this.props.children ? this.props.children : <button>Open google chooser</button>}
			</div>
		);
	}
}

const newGoogleGSI = localStorage.getItem('new_google_gsi') !== 'false';
export default newGoogleGSI ? GooglePickerNew : GooglePickerOld;
