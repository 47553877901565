import React, {useContext, useEffect, useState} from 'react';
import {
	DeprecatedAvatar as Avatar,
	DeprecatedButtonIconOnly as ButtonIconOnly,
	DeprecatedContextMenu as ContextMenu,
	DeprecatedContextMenuGroup as ContextMenuGroup,
	DeprecatedContextMenuItem as ContextMenuItem,
	DeprecatedDropdown as Dropdown,
	DeprecatedDropdownMenu as DropdownMenu,
	DeprecatedDropdownMenuText as DropdownMenuText,
	FlexColumn,
	FlexItem,
	FlexRow,
	DeprecatedInput as Input,
	DeprecatedMainContainer as MainContainer,
	DeprecatedPlaceholderItem as PlaceholderItem,
	DeprecatedSearchWithDropdown as SearchWithDropdown,
	DeprecatedTableCell as TableCell,
	DeprecatedTableHeaderCell as TableHeaderCell,
	DeprecatedTableHeaderRow as TableHeaderRow,
	DeprecatedTableRow as TableRow,
	DeprecatedText as Text,
	DeprecatedVirtualizedTable as VirtualizedTable,
} from '@forecast-it/design-system';
import {useIntl} from 'react-intl';
import ReactDOM from 'react-dom';
import {ProgramContext} from '../program-overview-page/ProgramContextProvider';
import {MODAL_TYPE, showModal} from '../../../shared/components/modals/generic_modal_conductor';
import {createFragmentContainer, graphql} from 'react-relay';
import useSearch from '../../../shared/hooks/useSearch';
import AddMemberFromProgramMutation from '../../../../mutations/project-service/add_member_to_program_mutation';
import UpdateProgramMemberRoleMutation from '../../../../mutations/project-service/update_program_member_role_mutation';
import Util from '../../../shared/util/util';
import {createToast} from '../../../shared/components/toasts/toast';
import useSort from '../../../shared/hooks/useSort';
import {useProgram} from '../hooks/useProgram';
import ProgramUtil from '../../../shared/util/ProgramUtil';
import {profilePicSrc} from '../../../../directApi';

const BASE_KEY = 'program-settings-team-page';
const LOCAL_SORT_KEY = `${BASE_KEY}-sort`;

const ProgramSettingsTeamPage = ({company}) => {
	const {program, actualPersonId} = useContext(ProgramContext);
	const _program = useProgram(program);
	const _search = useSearch();
	const _sort = useSort();

	const {formatMessage} = useIntl();
	const [showContextMenu, setShowContextMenu] = useState(false);
	const [selectedMember, setSelectedMember] = useState({});
	const [bounds, setBounds] = useState({});

	const [searchValue, setSearchValue] = useState('');

	const [searchedPersons, setSearchedPersons] = useState([]);
	const [addPersonSearchValue, setAddPersonSearchValue] = useState('');
	const [personsBeingAdded, setPersonsBeingAdded] = useState([]);

	const canManage = _program.canManage(actualPersonId);

	const [sortValue, setSortValue] = useState(
		JSON.parse(Util.localStorageGetItemWithDefault(LOCAL_SORT_KEY, '{"key": "", "direction": 0}'))
	);
	const updateSortValue = nextSortValue => {
		Util.localStorageSetItem(LOCAL_SORT_KEY, JSON.stringify(nextSortValue));
		setSortValue(nextSortValue);
	};

	const getUnassignedPersons = () => {
		const programPersonIds = program.members.edges?.map(member => member.node.person.id);
		return company.allPersons.edges?.filter(person => !programPersonIds.includes(person.node.id));
	};

	const toggleContextMenu = (e, member) => {
		setShowContextMenu(!showContextMenu);
		setSelectedMember(member);
		setBounds(document.elementFromPoint(e.pageX, e.pageY).getBoundingClientRect());
	};

	const openRemoveMemberModal = () =>
		showModal({
			type: MODAL_TYPE.REMOVE_PROGRAM_MEMBER_MODAL,
			programId: program.id,
			memberId: selectedMember.id,
		});

	const addPersonToProgram = person => {
		setPersonsBeingAdded([...personsBeingAdded, person.id]);
		Util.CommitMutation(AddMemberFromProgramMutation, {programId: program.id, personIds: [person.id], role: 'USER'}, () => {
			createToast({
				duration: 5000,
				message: formatMessage({id: 'program.member.added'}, {program: ProgramUtil.programText(formatMessage)}),
			});
		});
	};

	const updateMemberRole = (member, role) => {
		if (member.role !== role) {
			Util.CommitMutation(UpdateProgramMemberRoleMutation, {memberId: member.id, role: role}, () =>
				createToast({
					duration: 5000,
					message: formatMessage({id: 'program.member.role_updated'}),
				})
			);
		}
	};

	const canUpdateOrRemove = member =>
		canManage &&
		(member.role === 'USER' ||
			program.members.edges?.map(member => member.node).filter(member => member.role === 'OWNER').length > 1);

	useEffect(() => {
		const searchedPersons = _search.search(
			getUnassignedPersons().map(person => person.node),
			'fullName',
			addPersonSearchValue
		);
		setSearchedPersons(searchedPersons);
	}, [company, addPersonSearchValue, program.members]);

	const PersonDropdownMenu = () => (
		<DropdownMenu data-cy={'person_list'}>
			{searchedPersons.length > 0 ? (
				searchedPersons.map(person => (
					<ContextMenuItem
						disabled={personsBeingAdded.includes(person.id)}
						onClick={() => addPersonToProgram(person)}
						key={person.id}
						cy={`person_item`}
					>
						{_search.highlightMatch(person.fullName, addPersonSearchValue)}
					</ContextMenuItem>
				))
			) : (
				<PlaceholderItem>
					<Text color={'disabled'}>{formatMessage({id: 'search.no_match'}, {searchText: addPersonSearchValue})}</Text>
				</PlaceholderItem>
			)}
		</DropdownMenu>
	);

	return (
		<MainContainer>
			<FlexColumn gap={'l'}>
				<Text type={'heading'} variant={'m'}>
					{`${ProgramUtil.programText(formatMessage)} ${formatMessage({id: 'common.team'})}`}
				</Text>
				<FlexRow justifyContent={'space-between'} gap={'s'}>
					<FlexItem>
						<Input
							icon={'search'}
							size={'m'}
							placeholder={formatMessage(
								{id: 'search.search_entity'},
								{entity: formatMessage({id: 'common.people'}).toLowerCase()}
							)}
							onChange={e => setSearchValue(e.target.value)}
						/>
					</FlexItem>
					{canManage && (
						<FlexItem>
							<SearchWithDropdown DropdownMenu={PersonDropdownMenu}>
								<Input
									icon={'search'}
									placeholder={formatMessage(
										{id: 'common.action.add_entity'},
										{entity: formatMessage({id: 'common.member_lowercase'})}
									)}
									onChange={e => setAddPersonSearchValue(e.target.value)}
									data-cy={'add_program_member'}
								/>
							</SearchWithDropdown>
						</FlexItem>
					)}
				</FlexRow>
			</FlexColumn>

			<VirtualizedTable>
				<TableHeaderRow>
					<TableHeaderCell fixed={'32px'} />
					<TableHeaderCell
						fixed={'300px'}
						isSorted={_sort.isSortedColumn('person.fullName', sortValue)}
						direction={_sort.getDirection(sortValue)}
						onClick={() => _sort.updateSort('person.fullName', sortValue, updateSortValue)}
					>
						{formatMessage({id: 'common.people'})}
					</TableHeaderCell>
					<TableHeaderCell
						fixed={'200px'}
						isSorted={_sort.isSortedColumn('role', sortValue)}
						direction={_sort.getDirection(sortValue)}
						onClick={() => _sort.updateSort('role', sortValue, updateSortValue)}
					>
						{`${ProgramUtil.programText(formatMessage)} ${formatMessage({id: 'common.role'})}`}
					</TableHeaderCell>
					<TableHeaderCell fixed={'32px'} />
				</TableHeaderRow>
				{_search
					.search(program.members.edges, 'node.person.fullName', searchValue)
					?.map(member => member.node)
					.sort((m1, m2) => _sort.sort(sortValue, m1, m2))
					.map(member => (
						<TableRow key={member.id}>
							<TableCell fixed={'32px'}>
								<Avatar
									size={'l'}
									src={profilePicSrc(member.person.profilePictureId)}
									initials={member.person.initials}
								/>
							</TableCell>
							<TableCell fixed={'300px'}>
								<FlexColumn gap={'s'}>
									<FlexItem>{member.person.fullName}</FlexItem>
									<FlexItem>{member.person.email}</FlexItem>
								</FlexColumn>
							</TableCell>
							<TableCell fixed={'200px'}>
								<Dropdown
									initialValue={{
										value: member.role,
										text: formatMessage({id: 'common.program.role.' + member.role}),
									}}
									disabled={!canUpdateOrRemove(member)}
									onSelect={role => updateMemberRole(member, role)}
								>
									<DropdownMenuText
										values={[
											{value: 'OWNER', text: formatMessage({id: 'common.program.role.OWNER'})},
											{value: 'USER', text: formatMessage({id: 'common.program.role.USER'})},
										]}
									/>
								</Dropdown>
							</TableCell>
							<TableCell fixed={'32px'}>
								<ButtonIconOnly
									title={formatMessage({id: 'project_settings.more_settings'})}
									type={'ghost'}
									icon={'more'}
									size={'s'}
									onClick={e => toggleContextMenu(e, member)}
								/>
							</TableCell>
						</TableRow>
					))}
			</VirtualizedTable>
			{showContextMenu &&
				ReactDOM.createPortal(
					<ContextMenu
						horizontalOrientation={'left'}
						bounds={bounds}
						closeContextMenu={() => setShowContextMenu(false)}
					>
						<ContextMenuGroup>
							<ContextMenuItem disabled={!canUpdateOrRemove(selectedMember)} onClick={openRemoveMemberModal}>
								{formatMessage(
									{id: 'common.action.remove_from_entity'},
									{entity: ProgramUtil.programText(formatMessage).toLowerCase()}
								)}
							</ContextMenuItem>
						</ContextMenuGroup>
					</ContextMenu>,
					document.querySelector('#root-portal-container')
				)}
		</MainContainer>
	);
};

const ProgramSettingsTeamPageQuery = graphql`
	query ProgramSettingsTeamPage_Query {
		viewer {
			actualPersonId
			component(name: "program_settings_team_page")
			company {
				...ProgramSettingsTeamPage_company
			}
		}
	}
`;

export {ProgramSettingsTeamPageQuery};

export default createFragmentContainer(ProgramSettingsTeamPage, {
	company: graphql`
		fragment ProgramSettingsTeamPage_company on Company {
			allPersons(first: 10000, onlyActive: true, excludeClientUsers: true)
				@connection(key: "Company_allPersons", filters: []) {
				edges {
					node {
						id
						fullName
					}
				}
			}
		}
	`,
});
