import React from 'react';
import {Table} from 'web-components';

export const getBaseLevelTableHeaderTotals = (tableHeader, customFieldColumnCount) => {
	//change it to columns.length
	const totalNumbers = new Array(11);
	const columns = [
		<Table.Column></Table.Column>,
		<Table.Column></Table.Column>,
		<Table.Column></Table.Column>,
		<Table.Column></Table.Column>,
		<Table.Column>{tableHeader.wholeTableReportedTime}</Table.Column>,
		<Table.Column>{tableHeader.wholeTableBillableRegistered}</Table.Column>,
		<Table.Column>{tableHeader.wholeTableNonBillableRegistered}</Table.Column>,
		<Table.Column></Table.Column>,
		<Table.Column></Table.Column>,
		<Table.Column></Table.Column>,
		<Table.Column></Table.Column>,
		<Table.Column></Table.Column>,
		<Table.Column></Table.Column>,
		<Table.Column></Table.Column>,
		<Table.Column>{tableHeader.wholeTableTotalPrice}</Table.Column>,
		<Table.Column>{tableHeader.wholeTableTotalCost}</Table.Column>,
		<Table.Column></Table.Column>,
		<Table.Column></Table.Column>,
	];

	for (let i = 0; i < customFieldColumnCount; i++) {
		columns.push(<Table.Column />);
	}

	return (
		<Table.Rows data={{rows: [totalNumbers]}} canExpand={false} hasHeader>
			{({rowData, tableColumnsProps}) => {
				return <Table.Row {...tableColumnsProps}>{columns}</Table.Row>;
			}}
		</Table.Rows>
	);
};
