import {useIntl} from 'react-intl';
import {DeprecatedText as Text, FlexItem, FlexRow, IconTooltip} from '@forecast-it/design-system';
import {ForecastTooltipFormulaRenderer} from '../../../../components/ForecastTooltipFormulaRenderer';
import React from 'react';
import Util from '../../util/util';

const NUMBER_TYPE = {
	MONEY: 'MONEY',
	PERCENTAGE: 'PERCENTAGE',
};
const formatNumber = (value, numberType, currencySymbol, intl) => {
	switch (numberType) {
		case NUMBER_TYPE.MONEY:
			return currencySymbol ? Util.getFormattedNumberWithCurrency(currencySymbol, value, intl) : value;
		case NUMBER_TYPE.PERCENTAGE:
			return Util.getFormattedPercentage(value, intl);
		default:
			break;
	}
	return value;
};
const FinancialNumber = ({
	labelTranslationId,
	label,
	cy,
	tooltipItem,
	value,
	type = 'heading',
	variant = 'm',
	numberType,
	currencySymbol,
	currency,
	zeroValuesHidden = false,
}) => {
	if (zeroValuesHidden && value === 0) {
		return <></>;
	}
	const intl = useIntl();
	const theCurrencySymbol = currencySymbol || Util.GetCurrencySymbol(currency);
	const theLabel = label ? label : labelTranslationId ? intl.formatMessage({id: labelTranslationId}) : undefined;
	return (
		<FlexRow gap={'xl'} justifyContent={'space-between'}>
			<Text type={type} variant={variant} color={value < 0 ? 'destructive' : 'primary'} data-cy={cy}>
				{formatNumber(value, numberType, theCurrencySymbol, intl)}
			</Text>

			<FlexItem flex="1 1 auto">
				<FlexRow gap={'xs'} wrap={'false'}>
					{theLabel && (
						<Text type={type} variant={variant} color={'primary'}>
							{theLabel}
						</Text>
					)}
					{tooltipItem && tooltipItem.details.map ? (
						<IconTooltip>
							<ForecastTooltipFormulaRenderer items={[tooltipItem]} translatedMessage={true} />
						</IconTooltip>
					) : (
						tooltipItem && (
							<IconTooltip>
								<IconTooltip.Item content={tooltipItem} />
							</IconTooltip>
						)
					)}
				</FlexRow>
			</FlexItem>
		</FlexRow>
	);
};

FinancialNumber.NUMBER_TYPE = NUMBER_TYPE;

export default FinancialNumber;
