import React from 'react';
import ContactTypeBox from './ContactTypeBox';
import Styled from 'styled-components';

export const CONTACT_TYPES = {
	PRIMARY: 'PRIMARY',
	BILLING: 'BILLING',
	DECISION_MAKER: 'DECISION_MAKER',
	ONBOARDING: 'ONBOARDING',
	TECHNICAL: 'TECHNICAL',
};

const ContactDetailsContainer = Styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const ContactDetails = ({contactPersons, companyId}) => {
	const findContactPerson = contactType =>
		(contactPersons || []).find(contactPerson => contactPerson.contactType === contactType);

	return (
		<ContactDetailsContainer>
			{Object.keys(CONTACT_TYPES).map(contactType => (
				<ContactTypeBox
					contactType={contactType}
					person={findContactPerson(contactType)?.person}
					companyId={companyId}
				/>
			))}
		</ContactDetailsContainer>
	);
};

export default ContactDetails;
