import { MODAL_TYPE, showModal } from '../../../../shared/components/modals/generic_modal_conductor';
import { createToast } from '../../../../shared/components/toasts/another-toast/toaster';
import DeleteSavedReportMutation from '../../../../../mutations/delete_saved_report_mutation';
import Util from '../../../../shared/util/util';
export const deleteReport = (report, companyId, intl) => {
    showModal({
        type: MODAL_TYPE.DELETE_REPORT_MODAL,
        reportName: report.name,
        reportCount: 1,
        callback: () => {
            const onSuccess = () => {
                createToast({
                    duration: 5000,
                    message: intl.formatMessage({ id: 'report.has-been-deleted' }, { name: report.name }),
                });
            };
            Util.CommitMutation(DeleteSavedReportMutation, {
                id: report.id,
                reportService: report.reportService,
                companyId: companyId,
            }, onSuccess);
        },
    });
};
