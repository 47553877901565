import React from 'react';
import {useIntl} from 'react-intl';
import {Dropdown, Switch, Table, TableHeaderGrouping} from 'web-components';
import TooltipContainer from '../../../shared/components/tooltips/tooltip_container';
import WarningIcon from '../../../../images/warning_icon';
import Util from '../../../shared/util/util';
import {ForecastProbability, ForecastProbabilityWarningWrapper} from './app_salesforce_style';

const salesforceStagesTable = ({stages, localStages, setStageState}) => {
	const intl = useIntl();
	const buildOpportunityStageRow = stage => {
		const stageLocalState = localStages.find(s => s.Id === stage.Id);

		if (stageLocalState) {
			// If settings are saved for this stage, use those instead.
			stage = stageLocalState;
		}

		const projectCreateBeforeThisStage = localStages
			.filter(s => s.SortOrder < stage.SortOrder)
			.sort((a, b) => b.SortOrder - a.SortOrder)
			.find(s => !!s.CreateProject);

		// Sort descending, set closest stage to be last non-null projectStatus setting
		let closestWithStatus = localStages
			.filter(s => s.SortOrder < stage.SortOrder)
			.sort((a, b) => b.SortOrder - a.SortOrder)
			.find(s => !!s.ProjectStatus);

		// Find stage
		let statusVal;
		if (stage.ProjectStatus) {
			// If stage is selected for this stage, choose that
			statusVal = stage.ProjectStatus;
		} else if (closestWithStatus) {
			// If stage with a lower order has a stage, choose that
			statusVal = closestWithStatus.ProjectStatus;
		} else if (projectCreateBeforeThisStage || stage.CreateProject) {
			// If no stage, but project is set to be created by this point, set to opportunity status
			statusVal = 'OPPORTUNITY';
		}

		// Find Forecast probability
		let forecastProbability;
		if (statusVal && statusVal === 'OPPORTUNITY') {
			// If status at this stage is opportunity, choose SF probability
			forecastProbability = stage.DefaultProbability;
		} else if (!statusVal) {
			// If no status at this stage, it will not be synced.
			forecastProbability = '-';
		} else {
			// Stage is not opportunity or null. Always 100%
			forecastProbability = '100';
		}

		// Check if probability is out of sync
		const probabilityOutOfSync = `${forecastProbability}` !== `${stage.DefaultProbability}`;

		return {
			id: stage.SortOrder,
			salesforceOpportunity: stage.ApiName,
			forecastProjectStage: (
				<Dropdown
					width={188}
					usePortal
					dropdownAlignment="none"
					disabled={!(projectCreateBeforeThisStage || stage.CreateProject)}
					isClearable={stage.ProjectStatus && projectCreateBeforeThisStage}
					selectedItems={statusVal}
					name={'None'}
					onSelect={s => setStageState({...stage, ProjectStatus: s[0]})}
					onRemove={() => setStageState({...stage, ProjectStatus: null})}
				>
					{Util.getProjectStageForDropdown(intl, true).map(status => (
						<Dropdown.SingleText key={status.label} value={status.value} searchString={status.value}>
							{status.label}
						</Dropdown.SingleText>
					))}
				</Dropdown>
			),
			salesforce: `${stage.DefaultProbability}%`,
			forecast: probabilityOutOfSync ? (
				<ForecastProbabilityWarningWrapper>
					<TooltipContainer
						infoText={intl.formatMessage({id: 'settings.app_catalog.salesforce.probability_out_of_sync'})}
					>
						<WarningIcon danger />
					</TooltipContainer>
					<ForecastProbability>{forecastProbability}%</ForecastProbability>
				</ForecastProbabilityWarningWrapper>
			) : (
				`${forecastProbability}%`
			),
			createProject: (
				<Switch
					checked={stage.CreateProject}
					onChange={() => setStageState({...stage, CreateProject: !stage.CreateProject, ProjectStatus: null})}
				/>
			),
		};
	};

	const dataRows = stages.sort((a, b) => a.SortOrder - b.SortOrder).map(stage => buildOpportunityStageRow(stage));

	const data = {rows: dataRows};
	return (
		<>
			<Table>
				<Table.Header>
					<Table.HeaderColumn usePadding visible width="EXTRA_SMALL" align="left">
						<Table.HeaderColumn.Title>#</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn flex={1} usePadding visible width="LARGE" align="left">
						<Table.HeaderColumn.Title>Salesforce</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn usePadding visible width="LARGE" align="left">
						<Table.HeaderColumn.Title>Forecast</Table.HeaderColumn.Title>
					</Table.HeaderColumn>

					<TableHeaderGrouping name={intl.formatMessage({id: 'project.win_chance'})}>
						<Table.HeaderColumn usePadding visible width="MEDIUM" align="right">
							<Table.HeaderColumn.Title>Salesforce</Table.HeaderColumn.Title>
						</Table.HeaderColumn>
						<Table.HeaderColumn usePadding visible width="MEDIUM" align="right">
							<Table.HeaderColumn.Title>Forecast</Table.HeaderColumn.Title>
						</Table.HeaderColumn>
					</TableHeaderGrouping>

					<Table.HeaderColumn usePadding visible width="MEDIUM" align="center">
						<Table.HeaderColumn.Title>{intl.formatMessage({id: 'common.create_project'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
				</Table.Header>
				<Table.Rows data={data} canExpand={false}>
					{({rowData, tableColumnsProps}) => {
						return (
							<Table.Row {...tableColumnsProps}>
								<Table.Column>{rowData.id}</Table.Column>
								<Table.Column>{rowData.salesforceOpportunity}</Table.Column>
								<Table.Column>{rowData.forecastProjectStage}</Table.Column>
								<Table.Column>{rowData.salesforce}</Table.Column>
								<Table.Column>{rowData.forecast}</Table.Column>
								<Table.Column>{rowData.createProject}</Table.Column>
							</Table.Row>
						);
					}}
				</Table.Rows>
			</Table>
		</>
	);
};

export default salesforceStagesTable;
