import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { ProjectIndicator } from 'web-components';
import { ProjectIndicatorWrapper } from './support/DeprecatedProjectIndicator.styled';
import { getProjectGroupLink } from './support/ProjectIndicatorLogic';
export const DeprecatedProjectGroupIndicatorComponent = ({ companyProjectGroupId, projectGroupName, projectGroupColor, disableLink = false, clearSubPath = false, showProjectName = false, noWidthLimit = true, noWrapper = false, }) => {
    const link = getProjectGroupLink(companyProjectGroupId === null || companyProjectGroupId === void 0 ? void 0 : companyProjectGroupId.toString(), clearSubPath, disableLink);
    // Workaround for nested_dropdown that breaks with the 100% width added by the ProjectIndicatorWrapper
    const WrapperComponent = noWrapper ? React.Fragment : ProjectIndicatorWrapper;
    return (React.createElement(WrapperComponent, null,
        React.createElement(ProjectIndicator, { id: companyProjectGroupId, customId: undefined, noWidthLimit: noWidthLimit, name: projectGroupName, link: link, disableLink: disableLink, color: projectGroupColor, clearSubPath: clearSubPath, isConnectedProject: true }),
        showProjectName ? React.createElement("div", { title: projectGroupName !== null && projectGroupName !== void 0 ? projectGroupName : undefined }, projectGroupName) : null));
};
const DeprecatedProjectGroupIndicator = ({ projectGroup, disableLink = false, clearSubPath = false, showProjectName = false, noWidthLimit = true, noWrapper = false, }) => {
    return (React.createElement(DeprecatedProjectGroupIndicatorComponent, { companyProjectGroupId: projectGroup.companyProjectGroupId, projectGroupName: projectGroup.name, projectGroupColor: projectGroup.color, disableLink: disableLink, clearSubPath: clearSubPath, showProjectName: showProjectName, noWidthLimit: noWidthLimit, noWrapper: noWrapper }));
};
// Exported raw component to reuse it in DeprecatedProjectIndicatorJS that wraps it in a JS fragment (since JS and TS fragments are incompatible)
export { DeprecatedProjectGroupIndicator as DeprecatedProjectGroupIndicatorNoFragment };
export default createFragmentContainer(DeprecatedProjectGroupIndicator, {
    projectGroup: graphql `
		fragment DeprecatedProjectGroupIndicator_projectGroup on ProjectGroupType {
			name
			companyProjectGroupId
			color
		}
	`,
});
