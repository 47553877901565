import Util from '../../../../shared/util/util';
import SaveReport from '../../../../../mutations/ts/reporting/update_saved_report_mutation';
import { createToast } from '../../../../shared/components/toasts/another-toast/toaster';
import { MODAL_TYPE, showModal } from '../../../../shared/components/modals/generic_modal_conductor';
export const saveReportMutation = (report, intl, callback) => {
    const onSuccess = (res) => {
        createToast({
            duration: 5000,
            message: intl.formatMessage({ id: 'common.action.saved' }, { entity: report.name }),
        });
        callback && callback(res);
    };
    Util.CommitMutation(SaveReport, report, onSuccess);
};
export const saveReport = (report, intl, callback) => {
    showModal({
        report: report,
        type: MODAL_TYPE.SAVE_REPORT_MODAL,
        saveReport: (saveReport, closeModal) => {
            const _callback = (res) => {
                closeModal();
                callback && callback(res);
            };
            saveReportMutation(saveReport, intl, _callback);
        },
    });
};
