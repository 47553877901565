import React from 'react';

export const AvatarOutline = ({color, width, height}) => (
    <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            fillRule="evenodd" 
            clipRule="evenodd" 
            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM18.4529 17.9833C19.9095 16.4132 20.8 14.3106 20.8 12C20.8 7.13989 16.8601 3.2 12 3.2C7.13989 3.2 3.2 7.13989 3.2 12C3.2 14.3106 4.09052 16.4132 5.54708 17.9833C6.58025 16.327 8.23402 15.0966 10.1837 14.6184C8.60311 13.9203 7.5 12.339 7.5 10.5C7.5 8.01472 9.51472 6 12 6C14.4853 6 16.5 8.01472 16.5 10.5C16.5 12.339 15.3969 13.9203 13.8163 14.6184C15.766 15.0965 17.4198 16.3269 18.4529 17.9833ZM17.5574 18.8235C16.4536 16.8966 14.3777 15.6 12 15.6C9.6223 15.6 7.54639 16.8966 6.44257 18.8235C7.95782 20.0591 9.89238 20.8 12 20.8C14.1076 20.8 16.0422 20.0591 17.5574 18.8235ZM12 13.8C13.8225 13.8 15.3 12.3225 15.3 10.5C15.3 8.67746 13.8225 7.2 12 7.2C10.1775 7.2 8.7 8.67746 8.7 10.5C8.7 12.3225 10.1775 13.8 12 13.8Z" 
            fill={color}
        />
    </svg>
);


