import React from 'react';
import {Table} from 'web-components';
import {getQueryRenderer} from '../TaskReportUtil';
import GroupColumns from './GroupColumns';

const GroupRow = (
	data,
	intl,
	rowIndex,
	searchQuery,
	groupings,
	enabledColumns,
	hasNextPage,
	fetchMore,
	nextLevelProps,
	currency,
	reportId,
	sharedId,
	customFieldColumnCount,
	customFieldKeys
) => {
	return (
		<Table.Rows
			data={data}
			canExpand={true}
			autoload={hasNextPage && rowIndex === 1}
			loadMore={hasNextPage ? fetchMore : false}
			{...nextLevelProps}
		>
			{({rowData, tableColumnsProps, nextLevelProps, expanded}) => {
				return (
					<>
						<Table.Row {...tableColumnsProps}>{GroupColumns(rowData, intl, customFieldColumnCount)}</Table.Row>
						{expanded &&
							getQueryRenderer(
								null,
								groupings,
								searchQuery,
								rowIndex,
								enabledColumns,
								data.groupingFilter(rowData),
								nextLevelProps,
								currency,
								reportId,
								sharedId,
								customFieldColumnCount,
								customFieldKeys
							)}
					</>
				);
			}}
		</Table.Rows>
	);
};

export default GroupRow;
