/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type StatusWithDescription_programStatus$ref: FragmentReference;
declare export opaque type StatusWithDescription_programStatus$fragmentType: StatusWithDescription_programStatus$ref;
export type StatusWithDescription_programStatus = {|
  +id: string,
  +createdAt: ?string,
  +createdBy: ?{|
    +person: ?{|
      +id: string,
      +fullName: ?string,
      +profilePictureId: ?string,
    |}
  |},
  +description: ?string,
  +ragStatus: ?string,
  +$refType: StatusWithDescription_programStatus$ref,
|};
export type StatusWithDescription_programStatus$data = StatusWithDescription_programStatus;
export type StatusWithDescription_programStatus$key = {
  +$data?: StatusWithDescription_programStatus$data,
  +$fragmentRefs: StatusWithDescription_programStatus$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StatusWithDescription_programStatus",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserType",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Person",
          "kind": "LinkedField",
          "name": "person",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fullName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "profilePictureId",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ragStatus",
      "storageKey": null
    }
  ],
  "type": "ProjectServiceProgramStatusType"
};
})();
// prettier-ignore
(node/*: any*/).hash = '7277cb7102b9bc53d7b749b554be0c2f';

module.exports = node;
