import React from 'react';
import Styled from 'styled-components/macro';
import {ForecastThemeProvider} from '../../../ForecastThemeProvider';

const ProgressStyle = Styled.div`
	position: absolute;
	width: 100%;
	top: 0;
	.progress {
		width: 0px;
		background-color: ${props => props.theme.colors.forecastPurpleMedium};
		height: 6px;
		animation: fill-bar ${props => props.duration}ms linear;
		@keyframes fill-bar {
		  from {width: 0%;}
		  to {width: 100%;}
		}
	}
`;

const ToastProgress = ({duration, className}) => {
	return (
		<ForecastThemeProvider>
			<ProgressStyle className={className} duration={duration}>
				<div>
					<div className="progress-div">
						<div style={{width: `0px`}} className="progress" />
					</div>
				</div>
			</ProgressStyle>
		</ForecastThemeProvider>
	);
};

export default ToastProgress;
