import React, { useEffect, useMemo, useRef, useState } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import Styled from 'styled-components';
import { Button, FlexColumn, FlexRow, Heading, SearchField, Tabs } from '@forecast-it/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import CustomScrollDiv from '../../shared/components/scroll-bars/custom_scroll_div';
import { getNotNullishNodes } from '../../shared/util/NotNullPredicate';
import Util from '../../shared/util/util';
import { trackEvent } from '../../../tracking/amplitude/TrackingV2';
import ReportsPageChangeViewButton from './ReportsPageChangeViewButton';
import { mapReportToRow } from './ReportRowMapper';
import ReportsTabContent from './ReportsTabContent';
import { hasPermission } from '../../shared/util/PermissionsUtil';
import { PERMISSION_TYPE } from '../../../Permissions';
import { createReport } from './actions/create-report/CreateReport';
import { useTrackPage } from '../../../tracking/amplitude/hooks/useTrackPage';
import { useLocalStorage } from 'react-use';
const GROUP_BY_LOCALSTORAGE_KEY = 'report-page-group-by';
const Toolbar = ({ children }) => React.createElement(FlexRow, null, children);
Toolbar.Left = ({ children }) => React.createElement(FlexRow, { gap: 's' }, children);
Toolbar.Right = ({ children }) => (React.createElement(FlexRow, { gap: 's', justifyContent: 'end' }, children));
const PageWrapper = Styled.div `
  padding: 32px;
`;
const ReportsPageTabs = {
    private: 'private',
    shared: 'shared',
    all: 'all',
};
const ReportsPage = ({ viewer }) => {
    var _a;
    const intl = useIntl();
    const { formatMessage } = intl;
    const scrollRef = useRef(null);
    const [search, setSearch] = useState('');
    const [tab, setTab] = useLocalStorage('ReportsPage.SelectedTab', ReportsPageTabs.shared);
    const selectedTab = tab !== null && tab !== void 0 ? tab : ReportsPageTabs.shared;
    const [groupings, setGroupings] = useState(() => JSON.parse(Util.localStorageGetItem(GROUP_BY_LOCALSTORAGE_KEY) || JSON.stringify([])));
    useEffect(() => {
        document.title = `Reports - Forecast`;
    }, []);
    useTrackPage('Reports', { tabName: tab });
    const groupingColumnHeader = groupings.length
        ? formatMessage({ id: 'report.grouping' }) +
            ': ' +
            groupings.map(grouping => formatMessage({ id: 'report.column.' + grouping })).join(' / ')
        : 'Name';
    const onGroupingsChange = (groupings) => {
        Util.localStorageSetItem(GROUP_BY_LOCALSTORAGE_KEY, JSON.stringify(groupings));
        setGroupings(groupings);
        const groupingObj = groupings.reduce((result, item, index) => {
            result[`level_${index + 1}`] = item;
            return result;
        }, {});
        trackEvent('Standard Report', 'Grouping Changed', Object.assign({ groupBy: groupings }, groupingObj));
    };
    const allReports = useMemo(() => {
        var _a, _b;
        return getNotNullishNodes((_b = (_a = viewer === null || viewer === void 0 ? void 0 : viewer.company) === null || _a === void 0 ? void 0 : _a.allSavedReports) === null || _b === void 0 ? void 0 : _b.edges)
            .filter(report => !report.converted)
            .map(report => mapReportToRow(report, viewer.actualPersonId));
    }, [(_a = viewer === null || viewer === void 0 ? void 0 : viewer.company) === null || _a === void 0 ? void 0 : _a.allSavedReports]);
    const privateReports = useMemo(() => allReports.filter(report => report.isReportOwner && report.privateAccess), [allReports]);
    const companyReports = useMemo(() => allReports.filter(report => !report.privateAccess), [allReports]);
    const isAdmin = hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS);
    return (React.createElement(CustomScrollDiv, { setScrollbarsRef: node => {
            scrollRef.current = node;
        } },
        React.createElement(PageWrapper, null,
            React.createElement(FlexColumn, { gap: 'l' },
                React.createElement(Toolbar, null,
                    React.createElement(Toolbar.Left, null,
                        React.createElement(Heading, { size: '3' },
                            React.createElement(FormattedMessage, { id: "header.reports" }))),
                    React.createElement(Toolbar.Right, null,
                        React.createElement(SearchField, { value: search, onChange: setSearch, "data-cy": 'reports-search' }),
                        React.createElement(ReportsPageChangeViewButton, { groupings: groupings, onGroupingsChange: onGroupingsChange }),
                        React.createElement(Button, { "data-cy": 'create-reports-button', onClick: createReport },
                            React.createElement(FormattedMessage, { id: "reports.create-report" })))),
                React.createElement(Tabs, { value: selectedTab, onValueChange: setTab },
                    React.createElement(Tabs.List, null,
                        React.createElement(Tabs.Tab, { "data-cy": 'my-reports-tab', value: ReportsPageTabs.private }, `Private (${privateReports.length})`),
                        React.createElement(Tabs.Tab, { "data-cy": 'company-reports-tab', value: ReportsPageTabs.shared }, `Shared (${companyReports.length})`),
                        isAdmin && (React.createElement(Tabs.Tab, { "data-cy": 'all-reports-tab', value: ReportsPageTabs.all }, `All (${allReports.length})`))),
                    React.createElement(Tabs.Content, { value: ReportsPageTabs.private },
                        React.createElement(ReportsTabContent, { enableSelection: true, company: viewer.company, reports: privateReports, search: search, groupings: groupings, scrollRef: scrollRef, groupingColumnHeader: groupingColumnHeader })),
                    React.createElement(Tabs.Content, { value: ReportsPageTabs.shared },
                        React.createElement(ReportsTabContent, { enableSelection: isAdmin, company: viewer.company, reports: companyReports, search: search, groupings: groupings, scrollRef: scrollRef, groupingColumnHeader: groupingColumnHeader })),
                    isAdmin && (React.createElement(Tabs.Content, { value: ReportsPageTabs.all },
                        React.createElement(ReportsTabContent, { enableSelection: true, company: viewer.company, reports: allReports, search: search, groupings: groupings, scrollRef: scrollRef, groupingColumnHeader: groupingColumnHeader }))))))));
};
export default createFragmentContainer(ReportsPage, {
    viewer: graphql `
		fragment ReportsPage_viewer on Viewer {
			id
			actualPersonId
			availableFeatureFlags {
				key
			}
			company {
				...ReportsTable_company
				id
				modules {
					moduleType
				}
				allSavedReports(first: 100000, state: "SAVED") @connection(key: "Company_allSavedReports", filters: []) {
					edges {
						node {
							id
							name
							type
							person {
								id
								fullName
								email
								profilePictureId
							}
							converted
							reportService
							shares(first: 100) @connection(key: "Report_shares", filters: []) {
								edges {
									node {
										id
										user {
											id
											email
										}
									}
								}
							}
							updatedAt
							privateAccess
							state
							favorite
						}
					}
				}
			}
		}
	`,
});
