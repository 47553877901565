import {fetchQuery} from 'relay-runtime';
import environment from '../../../RelayEnvironment';

export function useDataExport(formatter, query = null, dataExtractFunction = data => data, filenameFunction = _ => 'export') {
	return (columns, data = [], generalData = {}, variables = {}, format = 'CSV') => {
		if (query) {
			fetchQuery(environment.getInstance(), query, variables).then(
				data => doExport(columns, data, generalData, format, formatter, dataExtractFunction, filenameFunction),
				error => handleError(error)
			);
		} else {
			doExport(columns, data, generalData, format, formatter, dataExtractFunction, filenameFunction);
		}
	};
}

function doExport(columns, data, generalData, format, formatter, dataExtractFunction, filenameFunction) {
	const extractedData = dataExtractFunction(data);
	const filename = filenameFunction(data);

	formatter.exportData(columns, extractedData, generalData, filename, format);
}

function handleError(error) {
	// Error handling here!!!
}
