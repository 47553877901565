/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProgramWorkBreakdown_viewer$ref: FragmentReference;
declare export opaque type ProgramWorkBreakdown_viewer$fragmentType: ProgramWorkBreakdown_viewer$ref;
export type ProgramWorkBreakdown_viewer = {|
  +id: string,
  +program: ?{|
    +id: string,
    +projectTaskStatusBreakdown: ?{|
      +todoPercentage: ?number,
      +inProgressPercentage: ?number,
      +donePercentage: ?number,
    |},
  |},
  +$refType: ProgramWorkBreakdown_viewer$ref,
|};
export type ProgramWorkBreakdown_viewer$data = ProgramWorkBreakdown_viewer;
export type ProgramWorkBreakdown_viewer$key = {
  +$data?: ProgramWorkBreakdown_viewer$data,
  +$fragmentRefs: ProgramWorkBreakdown_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "prefix",
      "type": "String"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProgramWorkBreakdown_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "prefix",
          "variableName": "prefix"
        }
      ],
      "concreteType": "ProgramType",
      "kind": "LinkedField",
      "name": "program",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectTaskStatusBreakdown",
          "kind": "LinkedField",
          "name": "projectTaskStatusBreakdown",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "todoPercentage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "inProgressPercentage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "donePercentage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e290b2ab4ab39dc12b572e24659bf8cd';

module.exports = node;
