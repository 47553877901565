import React from 'react';
import AppSalesforce, {appSalesforceQuery} from './app_salesforce';
import {injectIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import {withRouter} from 'react-router-dom';
import ForecastQueryRenderer from '../../../../ForecastQueryRenderer';
import SalesforceInstallView from './SalesforceInstallView';

const salesforceConfigurationConductor = ({viewer, intl}) => {
	const isSalesforceActivated = viewer.company.newSalesforceEnabled;
	return isSalesforceActivated ? (
		<ForecastQueryRenderer
			key="query-render-appSalesforce"
			query={appSalesforceQuery}
			authorizeAccessRoute={'settings'}
			render={relayProps => {
				return <AppSalesforce {...relayProps} />;
			}}
		/>
	) : (
		<SalesforceInstallView intl={intl} />
	);
};

const salesforceConfigurationConductorQuery = graphql`
	query SalesforceConfigurationConductor_Query {
		viewer {
			actualPersonId
			component(name: "salesforce_configuration_conductor")
			...SalesforceConfigurationConductor_viewer
		}
	}
`;
export {salesforceConfigurationConductorQuery};

export default injectIntl(
	withRouter(
		createFragmentContainer(salesforceConfigurationConductor, {
			viewer: graphql`
				fragment SalesforceConfigurationConductor_viewer on Viewer {
					company {
						newSalesforceEnabled
					}
				}
			`,
		})
	)
);
