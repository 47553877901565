import PropTypes from 'prop-types';
import React from 'react';
import Styled from 'styled-components';
import {AIOutline, ArrowRightOutline, BinOutline, MoreActionsOutline} from '../../../../../images/svg-collection';

export const BUTTON_COLOR = {
	VERYLIGHTGREY: 'veryLightGrey',
	PURPLE: 'purple',
	TEXT_PURPLE: 'textPurple',
};

export const BUTTON_STYLE = {
	FILLED: 'filled',
	OUTLINE: 'outline',
	TEXT: 'text',
};

export const BUTTON_SIZE = {
	STANDARD: 'standard',
};

export const BUTTON_ICONS = {
	MORE_ACTIONS_OUTLINE: 'moreActionsOutline',
	AI_OUTLINE: 'aiOutline',
	ARROW_RIGHT_OUTLINE: 'arrowRightOutline',
	BIN_OUTLINE: 'binOutline',
};

const BUTTON_HEIGHT = {
	standard: '30px',
};
const COLORS = {
	veryLightGrey: {
		text: '#535353',
		color: '#e6e6e6',
		hover: {
			color: '#a1a1a1',
		},
		active: {},
	},
	purple: {
		text: '#fff',
		color: '#6e0fea',
		hover: {},
		active: {},
	},
	textPurple: {
		text: '#6e0fea',
		color: '#fff',
		hover: {
			backgroundColor: 'rgba(0, 0, 0, 0.05)',
		},
		active: {
			backgroundColor: 'rgba(0, 0, 0, 0.1)',
		},
	},
};

const FONT_SIZE = {
	standard: '10px',
};

export const Button = ({content, disabled, onClick, userpilot, iconPosition, iconName, size, buttonColor, buttonStyle, cy}) => {
	/**
	 *
	 * @param {string} name should be taken from the BUTTON_ICON enyum
	 * @param {string} color hex representation of color
	 * returns an svg icon component
	 */
	const getIcon = (name, color) => {
		switch (name) {
			case BUTTON_ICONS.ARROW_RIGHT_OUTLINE:
				return <ArrowRightOutline width={16} height={16} color={color} />;
			case BUTTON_ICONS.BIN_OUTLINE:
				return <BinOutline width={16} height={16} color={color} />;
			case BUTTON_ICONS.AI_OUTLINE:
				return <AIOutline width={16} height={16} color={color} />;
			case BUTTON_ICONS.MORE_ACTIONS_OUTLINE:
				return <MoreActionsOutline width={16} height={16} color={color} />;
			default:
				return null;
		}
	};

	/**
	 * proxy for the onClick of the button
	 */
	const handleClick = e => {
		onClick(e);
	};

	return (
		<StyledButton
			data-cy={cy}
			disabled={disabled}
			buttonColor={buttonColor}
			buttonStyle={buttonStyle}
			size={size}
			onClick={handleClick}
			data-userpilot={userpilot}
		>
			{iconName && iconPosition === 'first' && (
				<IconWrapper iconPosition={iconPosition}>{getIcon(iconName, COLORS[buttonColor].text)}</IconWrapper>
			)}
			<span>{content}</span>
			{iconName && iconPosition === 'last' && <icon />}
		</StyledButton>
	);
};

Button.propTypes = {
	/*
		content of the button, should be less height than the size of the button (standard 30px)
	*/
	content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	/*
		disables the button, thereby making onClick not responding, and chaging to a visual disabled
	*/
	disabled: PropTypes.bool,
	/*
		should icon be first or last
	*/
	iconPosition: PropTypes.oneOf(['first', 'last']),
	/*
		should match one of variables in enum ICON_BUTTONS
	*/
	iconName: PropTypes.string,
	/*
		onClick handler
	*/
	onClick: PropTypes.func,
	/*
		userpilot tag
	*/
	userpilot: PropTypes.string,

	/*
		buttons style (outline thikness or fill color)
	*/
	buttonStyle: PropTypes.oneOf([BUTTON_STYLE.FILLED, BUTTON_STYLE.OUTLINE, BUTTON_STYLE.TEXT]).isRequired,

	/*
		Color of the button, is preconfigured in enum BUTTON_COLOR
	*/
	buttonColor: PropTypes.oneOf([BUTTON_COLOR.VERYLIGHTGREY, BUTTON_COLOR.PURPLE, BUTTON_COLOR.TEXT_PURPLE]).isRequired,
};

Button.defaultProps = {
	onClick: () => false,
	buttonStyle: BUTTON_STYLE.OUTLINE,
	iconPosition: 'first',
	size: BUTTON_SIZE.STANDARD,
	cy: '',
};

const IconWrapper = Styled.div`
	width:16px;
	height: 16px;
	margin-right: ${props => (props.iconPosition === 'first' ? '8px' : 0)};
	margin-left: ${props => (props.iconPosition === 'last' ? '8px' : 0)};
`;

const StyledButton = Styled.button`
	background-color: ${props => (props.buttonStyle === BUTTON_STYLE.FILLED ? COLORS[props.buttonColor].color : '')};
	padding: 1px;
	text-transform: uppercase;
	padding: 0 16px;
	font-weight: bold;
	letter-spacing: 1px;
	white-space: nowrap;
	height: ${props => BUTTON_HEIGHT[props.size]};
	font-size: ${props => FONT_SIZE[props.size]};
	color: ${props => COLORS[props.buttonColor].text};
	border-style: solid;
	border-width: ${props => {
		switch (props.buttonStyle) {
			case BUTTON_STYLE.FILLED:
			case BUTTON_STYLE.OUTLINE:
				return '1px';
			case BUTTON_STYLE.TEXT:
				return '0';
			default:
				return '0';
		}
	}};
	border-color: ${props => COLORS[props.buttonColor].color};
	display: flex;
	align-items: center;
	position: relative;
	box-sizing: border-box;
	cursor: pointer;
	&:focus {
		outline: none;
		background-color: ${props => COLORS[props.buttonColor].hover.backgroundColor}
	}
	&:hover {
		opacity: ${props => (props.buttonStyle === BUTTON_STYLE.FILLED ? '0.8' : '1')};
		border-color: ${props =>
			props.buttonStyle !== BUTTON_STYLE.FILLED
				? COLORS[props.buttonColor].hover.color
				: COLORS[props.buttonColor].color};
		background-color: ${props => COLORS[props.buttonColor].hover.backgroundColor}
	}
	&:active {
		background-color: ${props => COLORS[props.buttonColor].active.backgroundColor}
	}
	&:disabled {
		opacity: 0.5;
	}
`;
