import React from 'react';
import { Breadcrumbs, FlexColumn, FlexContainer, FlexItem, FlexRow, Heading, Text } from '@forecast-it/design-system';
import { getDefaultMediumClientLogo } from '../../../components/default_avatars';
import Styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import SettingsClientsSubNav from './SettingsClientsSubNav';
import { clientLogoSrc } from '../../../directApi';
const BreadcrumbsWrapper = Styled.div `
  	padding-bottom: 24px;
`;
const SettingsClientHeader = ({ clientId, clientName, clientLogoId, clientLogoDefaultId, }) => {
    const logoSrc = clientLogoId ? clientLogoSrc(clientLogoId) : getDefaultMediumClientLogo(clientLogoDefaultId);
    return (React.createElement(React.Fragment, null,
        React.createElement(BreadcrumbsWrapper, null,
            React.createElement(Breadcrumbs, null,
                React.createElement(Breadcrumbs.Item, null,
                    React.createElement(Link, { to: '/admin/clients' },
                        React.createElement(FormattedMessage, { id: "common.all_clients" }))),
                React.createElement(Breadcrumbs.Item, null,
                    React.createElement("span", null, clientName || '')))),
        React.createElement(FlexContainer, null,
            React.createElement(FlexRow, { gap: 'xl' },
                React.createElement(FlexItem, null,
                    React.createElement("img", { crossOrigin: "use-credentials", alt: "client-logo", src: logoSrc, width: "96", height: "96" })),
                React.createElement(FlexColumn, { alignItems: 'flex-start', alignSelf: 'center', gap: 's' },
                    React.createElement(Text, null,
                        React.createElement(FormattedMessage, { id: "common.client" })),
                    React.createElement(Heading, { size: 3 }, clientName || '-')))),
        React.createElement(SettingsClientsSubNav, { clientId: clientId })));
};
export default SettingsClientHeader;
