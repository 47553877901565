/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type labelRow_labelCategory$ref: FragmentReference;
declare export opaque type labelRow_labelCategory$fragmentType: labelRow_labelCategory$ref;
export type labelRow_labelCategory = {|
  +allowOnTasks: ?boolean,
  +allowOnProjects: ?boolean,
  +allowOnPeople: ?boolean,
  +$refType: labelRow_labelCategory$ref,
|};
export type labelRow_labelCategory$data = labelRow_labelCategory;
export type labelRow_labelCategory$key = {
  +$data?: labelRow_labelCategory$data,
  +$fragmentRefs: labelRow_labelCategory$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "labelRow_labelCategory",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowOnTasks",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowOnProjects",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowOnPeople",
      "storageKey": null
    }
  ],
  "type": "LabelCategory"
};
// prettier-ignore
(node/*: any*/).hash = '40b0356d0bbe4e399ce09f3f57cd8575';

module.exports = node;
