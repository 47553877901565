import styled from 'styled-components';

export const SelectPipelineDescription = styled.div`
	min-width: max-content;
	margin-right: 16px;
`;

export const SelectPipelineWrapper = styled.div`
	display: flex;
	width: 500px;
	flex-direction: row;
	align-items: center;
	margin-bottom: 16px;
`;
