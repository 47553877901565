import React, {useEffect} from 'react';
import {FormattedHTMLMessage, useIntl} from 'react-intl';
import GenericModal from '../../../../../containers/modal/generic_modal';
import {getCsvLabels} from '../../../../reports-tab/report/task-report-page/TaskReportCsvUtil';
import Util from '../../../util/util';
import DirectApi from '../../../../../directApi';

const TaskReportCSVModalWrapper = ({
	closeModal,
	searchQueryFilters,
	filterValue,
	enabledColumns,
	reportName,
	customFieldDefinitions,
}) => {
	const intl = useIntl();

	const handleCsvDownloadPress = () => {
		const dataInput = getCsvLabels(enabledColumns, customFieldDefinitions);

		const labels = dataInput.map(input => input.label);

		const headers = new Headers();
		headers.append('Content-Type', 'application/json');
		const init = {
			method: 'POST',
			headers: headers,
			credentials: 'include',
			body: JSON.stringify({
				labels: labels,
				dataProcessorInputs: dataInput,
				format: 'CSV',
				filter: searchQueryFilters,
				filterApplied: JSON.stringify(filterValue),
				exportFields: [],
			}),
		};

		fetch(DirectApi.graphqlServerEndpoint('report/owner_export_csv'), init).then(async result => {
			const blob = await result.blob();
			Util.exportToCSV(blob, `task_report_${reportName ?? ''}`);
			closeModal();
		});
	};

	const content = (
		<div className={'body-container'}>
			<div style={{marginBottom: '12px'}}>{intl.formatMessage({id: 'common.wait_gathering'})}</div>
		</div>
	);

	useEffect(() => {
		handleCsvDownloadPress();
	}, []);

	return (
		<GenericModal
			closeModal={closeModal}
			headerText={<FormattedHTMLMessage id="common.processing_loading" />}
			content={content}
			preventClickAwayClose={true}
			modalWidth={572}
		/>
	);
};

export default TaskReportCSVModalWrapper;
