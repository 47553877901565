import {DeprecatedButton as Button} from '@forecast-it/design-system';
import {partition} from 'lodash';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import {BUDGET_TYPE, PROJECT_STATUS} from '../../../../../constants';
import {getSuggestedStatusVariantFromRag} from '../../../../../containers/project/project_health/ProjectHealthLogic';
import {getNovaInsightsData} from '../../../../../containers/project/project_health/ProjectHealthPage';
import ForecastTooltip, {TooltipColor} from '../../tooltips/ForecastTooltip';
import {convertInfoTextToStatusDescription, parseSuggestedProgramStatusText} from '../StatusUtil';

const Dot = styled.div`
	width: 8px;
	height: 8px;
	border-radius: 16px;
	background-color: #008bfa;
`;

const getSuggestedStatusFromProgram = program => {
	const suggestedStatus = program.suggestedStatus;
	const suggestedStatusVariant = suggestedStatus ? getSuggestedStatusVariantFromRag(suggestedStatus.ragStatus) : null;
	const suggestedStatusText = parseSuggestedProgramStatusText(suggestedStatus?.statusMessages);

	return {
		suggestedStatus,
		suggestedStatusVariant,
		suggestedStatusText,
	};
};

const getSuggestedStatusFromProject = (project, company, history, intl) => {
	const insightsData = getNovaInsightsData(project, company.isUsingProjectAllocation, history, intl);

	const insightsPartition = partition(insightsData, insight => {
		return !insight.disabled;
	});
	const availableInsightsData = insightsPartition[0];
	const activeInsightsInfoText = availableInsightsData.map(insight => insight.info);

	const suggestedStatus = project.projectHealth.suggestedStatusRag;
	const suggestedStatusVariant = suggestedStatus ? getSuggestedStatusVariantFromRag(suggestedStatus) : null;
	const suggestedStatusText = convertInfoTextToStatusDescription(activeInsightsInfoText);

	return {
		suggestedStatus,
		suggestedStatusVariant,
		suggestedStatusText,
	};
};

const getSuggestedStatusDisabledReason = project => {
	if (!project) return null;
	if (project.status === PROJECT_STATUS.OPPORTUNITY) {
		return 'Status suggestions are not available for Opportunity projects.';
	}
	if (project.budgetType === BUDGET_TYPE.RETAINER) {
		return 'Status suggestions are not available for Retainer projects.';
	}

	return (
		<div>
			Unlock intelligent status suggestions by:
			<ul>
				<li>Allocating people to the project</li>
				<li>Setting tasks with time registrations to done</li>
			</ul>
		</div>
	);
};

const SuggestedStatus = ({viewer, currentStatusVariant, callback}) => {
	const intl = useIntl();
	const history = useHistory();

	const {program, project, company} = viewer;
	const {suggestedStatus, suggestedStatusVariant, suggestedStatusText} = useMemo(
		() =>
			program ? getSuggestedStatusFromProgram(program) : getSuggestedStatusFromProject(project, company, history, intl),
		[program, project]
	);

	const statusDisabledReason = getSuggestedStatusDisabledReason(project);
	const suggestionIsDifferent = suggestedStatus && currentStatusVariant !== suggestedStatusVariant;

	return (
		<ForecastTooltip content={!suggestedStatus ? statusDisabledReason : null} color={TooltipColor.PURPLE}>
			<Button
				type={'secondary'}
				disabled={!suggestedStatus}
				style={{marginLeft: '16px'}}
				onClick={() => callback(suggestedStatusVariant, suggestedStatusText)}
			>
				See Suggestion {suggestionIsDifferent ? <Dot /> : null}
			</Button>
		</ForecastTooltip>
	);
};

const SuggestedStatusQuery = graphql`
	query SuggestedStatus_Query($projectId: ID, $programPrefix: String) {
		viewer {
			actualPersonId
			component(name: "suggested_status")
			...SuggestedStatus_viewer @arguments(projectId: $projectId, programPrefix: $programPrefix)
		}
	}
`;

export {SuggestedStatusQuery};

export default createFragmentContainer(SuggestedStatus, {
	viewer: graphql`
		fragment SuggestedStatus_viewer on Viewer
		@argumentDefinitions(projectId: {type: "ID"}, programPrefix: {type: "String"}) {
			firstName
			company {
				isUsingProjectAllocation
			}
			project(internalId: $projectId) {
				id
				status
				companyProjectId
				projectStartYear
				projectStartMonth
				projectStartDay
				projectEndYear
				projectEndMonth
				projectEndDay
				budget
				budgetType
				useBaseline
				revenueMarginLow
				revenueMarginHigh
				rateCard {
					currency
				}
				projectHealth {
					performanceRolling
					optimisticPerformanceRolling
					conservativePerformanceRolling
					performanceRAGStatus
					performanceInfo
					performanceError
					focus
					focusRAGStatus
					focusInfo
					focusError
					predictedEndDate
					predictedEndDateRAGStatus
					predictedEndDateInfo
					predictedEndDateError
					budgetInsightInfo
					budgetInsightRAGStatus
					budgetInsightError
					optimisticPredictedEndDate
					forecastRevenue
					suggestedStatusRag
				}
			}
			program(prefix: $programPrefix) {
				id
				name
				color
				suggestedStatus {
					ragStatus
					statusMessages
				}
			}
		}
	`,
});
