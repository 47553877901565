import React from 'react';
import { Modal, Text } from '@forecast-it/design-system';
import { FormattedMessage } from 'react-intl';
const ConfirmCustomIdChangeModal = ({ title, description, autoClosePositive = true, autoCloseNegative = true, callbackPositive, callbackNegative, closeModal, }) => {
    const confirm = () => {
        callbackPositive();
        if (autoClosePositive) {
            closeModal();
        }
    };
    const cancel = () => {
        callbackNegative();
        if (autoCloseNegative) {
            closeModal();
        }
    };
    return (React.createElement(Modal, { size: Modal.SIZE.SMALL, onCloseModal: closeModal },
        React.createElement(Modal.Title, null, title),
        React.createElement(Modal.Content, null,
            React.createElement(Text, null, description),
            React.createElement(Text, null,
                React.createElement(FormattedMessage, { id: "common.are_you_sure_proceed" }))),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { onPress: confirm },
                React.createElement(FormattedMessage, { id: "common.confirm" })),
            React.createElement(Modal.SecondaryFooterButton, { onPress: cancel },
                React.createElement(FormattedMessage, { id: 'common.cancel' })))));
};
export default ConfirmCustomIdChangeModal;
