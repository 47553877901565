import Util from '../util';

export default class DataExportFormatter {
	_intl;
	#mandatoryColumns;
	#columnsToIgnore;
	#shouldSplitDate;

	constructor(intl, mandatoryColumns = [], columnsToIgnore = [], shouldSplitDate = false) {
		this._intl = intl;
		this.#mandatoryColumns = mandatoryColumns;
		this.#columnsToIgnore = columnsToIgnore;
		this.#shouldSplitDate = shouldSplitDate;
	}

	_withDefault = (obj, func, def) => (!!obj ? func(obj) : def);

	_formatArray(array, func) {
		return this._withDefault(
			array,
			ar =>
				// The array string is formatted as a string
				this._formatString(
					func(ar)
						.map(element => this._formatString(element)) // Each element is considered a string
						.toString()
				),
			''
		);
	}

	_formatBoolean(obj, ifTrue, ifFalse) {
		return obj ? ifTrue : ifFalse;
	}

	_formatCurrency(amount) {
		return this._intl.formatNumber(amount ? amount : 0, {format: 'always_two_decimal'});
	}

	_formatDate(day, month, year) {
		return day && month && year ? Util.CreateNonUtcMomentDate(year, month, day).format('YYYY-MM-DD') : '';
	}

	_formatTime = minutes => {
		return minutes || 0;
	};

	_formatIntlMessage(id) {
		return this._intl.formatMessage({id: id});
	}

	_formatIntlMessageByPredicate(predicate, idIfTrue, idIfFalse) {
		return this._intl.formatMessage({id: predicate ? idIfTrue : idIfFalse});
	}

	_formatNamedObject(obj, def) {
		return this._withDefault(obj, o => o.name, def);
	}

	_formatNumber(number) {
		return this._intl.formatNumber(number, {format: 'rounded_two_decimal'});
	}

	_formatWithPrefix(obj, prefix, def) {
		return this._withDefault(obj, o => `${prefix}${o}`, def);
	}

	_formatPercentage(obj) {
		return this._withDefault(obj, o => `${o}%`, '0%');
	}

	_formatPercentageFromFraction(fraction) {
		return this._formatPercentage(fraction * 100);
	}

	_formatString(obj) {
		return this._withDefault(
			obj,
			str => {
				let val = typeof str === 'string' ? str : str.toString();

				val = val.replace(/"/g, '""');

				// Ensure multi lined strings comes out as one line.
				val = val.replace(/\n/g, ' ');

				if (['+', '-', '=', '@'].some(e => val.trim().startsWith(e))) {
					val = `'${val}`;
				}

				return val;
			},
			''
		);
	}

	_fullname(person) {
		return person.fullName ?? `${person.firstName}${person.lastName !== null ? ` ${person.lastName}` : ''}`;
	}

	_formatColumnNames(columns) {
		const columnsNames = columns
			.filter(column => column.checked) // Filters away columns, which are NOT checked
			.filter(column => !this.#columnsToIgnore.includes(column.name)) // Filters away columns to ignore
			.filter(column => !this.#mandatoryColumns.includes(column.name)) // Filters away mandatory columns, as they are added later
			.map(column => column.name)
			.flatMap(name => {
				name = name.replace('-', '_');
				const hookColumnNames = this._columnFormatHook(name);

				if (hookColumnNames) {
					return hookColumnNames;
				}

				switch (name) {
					case 'date':
						if (this.#shouldSplitDate) {
							return ['start_date', 'deadline_date'];
						} else {
							return 'date';
						}
					case 'projectId':
						return 'project_id';
					default:
						return name;
				}
			});

		const keys = [...this.#mandatoryColumns];

		keys.push(...columnsNames);

		return keys;
	}

	_formatRow(columnNames, row = {}) {
		const output = {};

		columnNames.forEach(column => (output[column] = this._getFormattedValue(column, row)));

		return output;
	}

	_formatAllRows(columnNames, rowDataArray = []) {
		return rowDataArray.flatMap(row => this._formatRow(columnNames, row));
	}

	_storeGeneralData(generalData) {
		//Do nothing
	}

	exportData(columns, rowDataArray = [], generalData = {}, filename = 'export', format = 'CSV') {
		this._storeGeneralData(generalData);

		const formattedColumnNames = this._formatColumnNames(columns);
		const formattedData = this._formatAllRows(formattedColumnNames, rowDataArray);
		const formattedFilename = filename.toLowerCase().replace(/\s/g, '_');

		if (format === 'CSV') {
			const fullFileName = formattedFilename + '.csv';

			const exportData = this.mapObjectsToCSVByHeaders(formattedColumnNames, formattedData);

			Util.exportToCSV(exportData, fullFileName);
		} else {
			throw new Error(`Unknown data export format: ${format}`);
		}
	}

	_columnFormatHook(name) {
		return null;
	}

	_getFormattedValue(column, row) {
		const hookValue = this._getFormattedValueHook(column, row);

		if (hookValue != null) {
			return hookValue;
		}

		switch (column) {
			case 'date':
				return this._formatDate(row.day, row.month, row.year);
			case 'deadline_date':
				return this._formatDate(row.deadlineDay, row.deadlineMonth, row.deadlineYear);
			case 'project_id':
				return row.project.customProjectId
					? this._formatString(row.project.customProjectId)
					: this._formatWithPrefix(row.project.companyProjectId, 'P', '');
			case 'project_name':
				return this._formatNamedObject(row.project, '');
			case 'start_date':
				return this._formatDate(row.startDay, row.startMonth, row.startYear);
			case 'task_id':
				return this._formatWithPrefix(row.companyTaskId, 'T', '');
			case 'task_name':
				return this._formatString(row.name);
			default:
				return '';
		}
	}

	_getFormattedValueHook(column, row, generalData = {}) {
		return null;
	}

	_csvFormatter(field) {
		let val = typeof field === 'string' ? field : field.toString();

		if (val.includes(',')) {
			val = `"${val}"`;
		}

		return val;
	}

	mapObjectsToCSVByHeaders(headerArray, objectArray) {
		const mappedData = objectArray.map(row => headerArray.map(header => this._csvFormatter(row[header])));

		const fullArray = [headerArray].concat(mappedData);

		return fullArray.join('\r\n');
	}
}
