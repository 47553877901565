import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import Util from '../../../../../util/util';
import {GrowableColumn} from '../Elements.styled';
import {HeaderInput} from '../Header.Styled';
import UpdatePhaseMutation from '../../../../../../../mutations/update_phase_mutation';
import {useIntl} from 'react-intl';

const ColumnWrapper = styled.div`
	display: flex;
`;

export const PhaseNameColumn = ({
	name,
	canEdit = true,
	width,
	phaseId,
	projectId,
	disabled,
	integrationLogos = [],
	cy = '',
	backgroundColor,
}) => {
	const intl = useIntl();

	const [phaseName, setPhaseName] = useState(name || '');

	useEffect(() => {
		setPhaseName(name);
	}, [name]);
	const inputRef = useRef();

	const handleNameChange = e => {
		setPhaseName(e.target.value);
	};

	const handleKeyPress = e => {
		if (e.key === 'Enter') {
			inputRef?.current?.blur();
		}
	};

	const handleSave = () => {
		Util.CommitMutation(UpdatePhaseMutation, {
			id: phaseId,
			projectId,
			name: phaseName === '' ? intl.formatMessage({id: 'project_scoping.new-scope-group'}) : phaseName,
		});
	};

	return (
		<GrowableColumn width={width}>
			{!canEdit ? (
				<span className="phase-none">{intl.formatMessage({id: 'project_scopes.tasks-no-scope'})} </span>
			) : (
				<ColumnWrapper>
					{integrationLogos.length > 0
						? integrationLogos.map(integrationLogo => <div style={{'padding-top': '3px'}}>{integrationLogo}</div>)
						: null}
					<HeaderInput
						onClick={e => e.stopPropagation()}
						data-cy={'phase-input' + cy}
						//style={{color: Util.getTextColor(this.props.project.projectColor)}}
						type="text"
						ref={inputRef}
						value={phaseName}
						onChange={handleNameChange}
						onBlur={handleSave}
						disabled={disabled}
						spellCheck={false}
						maxLength={191}
						onKeyPress={handleKeyPress}
						backgroundColor={backgroundColor}
						data-userpilot="name-input-first"
					/>
				</ColumnWrapper>
			)}
		</GrowableColumn>
	);
};
