import React from 'react';
import {TaskRowHeaderV2 as TaskRowHeaderComponentV2} from 'web-components';
import * as tracking from '../../../../../tracking';

const TaskListHeader = ({intl, enabledColumns, sortBy, onSortByChange, stickyTopPosition, isInContainer}) => {
	return (
		<TaskRowHeaderComponentV2
			intl={intl}
			columns={enabledColumns}
			sortBy={sortBy}
			onSortByChange={onSortByChange}
			stickyTopPosition={stickyTopPosition}
			isInContainer={isInContainer}
			tracking={tracking}
		/>
	);
};

export default TaskListHeader;
