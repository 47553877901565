/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
query InvoicedBillToWarningModalQuery {
  viewer {
    actualPersonId
    component(name: "InvoicedBillToWarningModal")
    ...InvoicedBillToWarningModal_viewer
    id
  }
}

fragment InvoicedBillToWarningModal_viewer on Viewer {
  id
  invoicesV2(first: 100000) {
    edges {
      node {
        id
        billTo {
          id
        }
        client {
          id
        }
        projects {
          id
          name
          projectColor
          companyProjectId
          ...ProjectIndicator_project
        }
      }
    }
  }
}

fragment ProjectIndicator_project on ProjectType {
  name
  companyProjectId
  customProjectId
  projectColor
}
*/
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actualPersonId",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": [
            {
                "kind": "Literal",
                "name": "name",
                "value": "InvoicedBillToWarningModal"
            }
        ],
        "kind": "ScalarField",
        "name": "component",
        "storageKey": "component(name:\"InvoicedBillToWarningModal\")"
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = [
        (v2 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "InvoicedBillToWarningModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        (v1 /*: any*/),
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "InvoicedBillToWarningModal_viewer"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "InvoicedBillToWarningModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": [
                                {
                                    "kind": "Literal",
                                    "name": "first",
                                    "value": 100000
                                }
                            ],
                            "concreteType": "InvoiceTypeConnection",
                            "kind": "LinkedField",
                            "name": "invoicesV2",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "InvoiceTypeEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "InvoiceType",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                                (v2 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "BillTo",
                                                    "kind": "LinkedField",
                                                    "name": "billTo",
                                                    "plural": false,
                                                    "selections": (v3 /*: any*/),
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Client",
                                                    "kind": "LinkedField",
                                                    "name": "client",
                                                    "plural": false,
                                                    "selections": (v3 /*: any*/),
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ProjectType",
                                                    "kind": "LinkedField",
                                                    "name": "projects",
                                                    "plural": true,
                                                    "selections": [
                                                        (v2 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "name",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "projectColor",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "companyProjectId",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "customProjectId",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": "invoicesV2(first:100000)"
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "InvoicedBillToWarningModalQuery",
            "operationKind": "query",
            "text": "query InvoicedBillToWarningModalQuery {\n  viewer {\n    actualPersonId\n    component(name: \"InvoicedBillToWarningModal\")\n    ...InvoicedBillToWarningModal_viewer\n    id\n  }\n}\n\nfragment InvoicedBillToWarningModal_viewer on Viewer {\n  id\n  invoicesV2(first: 100000) {\n    edges {\n      node {\n        id\n        billTo {\n          id\n        }\n        client {\n          id\n        }\n        projects {\n          id\n          name\n          projectColor\n          companyProjectId\n          ...ProjectIndicator_project\n        }\n      }\n    }\n  }\n}\n\nfragment ProjectIndicator_project on ProjectType {\n  name\n  companyProjectId\n  customProjectId\n  projectColor\n}\n"
        }
    };
})();
node.hash = '9c2f20cfc57d1a442b20fd5c5a9b3826';
export default node;
