import React, {useEffect, useRef, useState} from 'react';
import Moment from 'moment';
import {Timer as TimerComponent} from '@forecast-it/design-system';
import {createFragmentContainer, graphql} from 'react-relay';
import UpdateViewerMutation from '../../../mutations/update_viewer_mutation.modern';
import Util from '../../shared/util/util';
import {MODAL_TYPE, showModal} from '../../shared/components/modals/generic_modal_conductor';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import {hasFeatureFlag} from '../../shared/util/FeatureUtil';

export const TimerLoader = (
	<TimerComponent>
		<TimerComponent.Button aria-label={'start-timer'} icon={'play'} onClick={() => {}} />
		<TimerComponent.Display>{'0:00:00'}</TimerComponent.Display>
		<TimerComponent.Button aria-label="create-time-entry" icon="checkCircle" disabled={true} onClick={() => {}} />
		<TimerComponent.Button aria-label="discard-time-entry" icon="closeCircle" disabled={true} onClick={() => {}} />
	</TimerComponent>
);

const Timer = ({viewer, personId}) => {
	const getEndDate = () => (viewer.timerEndDate ? Moment.utc(viewer.timerEndDate) : Moment.utc());
	const [running, setRunning] = useState(viewer.timerStartDate && !viewer.timerEndDate);
	const [duration, setDuration] = useState(viewer.timerStartDate ? getEndDate().diff(viewer.timerStartDate, 'seconds') : 0);
	const interval = useRef(null);

	const incrementTimer = () => {
		setDuration(viewer.timerStartDate ? getEndDate().diff(viewer.timerStartDate, 'seconds') : 0);
	};

	useEffect(() => {
		setRunning(!!viewer.timerStartDate && !viewer.timerEndDate);
		incrementTimer();
	}, [viewer.timerStartDate, viewer.timerEndDate]);

	useEffect(() => {
		if (running && interval.current == null) {
			interval.current = setInterval(incrementTimer, 500);
		}
		return () => {
			clearInterval(interval.current);
			interval.current = null;
			incrementTimer();
		};
	}, [running, viewer.timerStartDate, viewer.timerEndDate]);

	const onPlay = () => {
		trackEvent('Top Navigation Timer Start', 'Clicked');
		Util.CommitMutation(UpdateViewerMutation, {
			timerStartDate: Moment.utc().format(),
			timerEndDate: null,
		});
	};
	const onPause = () => {
		trackEvent('Top Navigation Timer Pause', 'Clicked');
		Util.CommitMutation(UpdateViewerMutation, {
			timerEndDate: Moment.utc().format(),
		});
	};
	const onApply = () => {
		trackEvent('Top Navigation Timer Apply', 'Clicked');
		if (hasFeatureFlag('new_time_registration_modal')) {
			showModal({
				type: MODAL_TYPE.TIMER_TIME_REGISTRATION,
				timerActionTaskId: viewer.timerTask?.id,
			});
		} else {
			showModal({
				type: MODAL_TYPE.TIMER_V3,
				isPreviousTimerReminder: viewer.timerStartDate !== null && viewer.timerStartDate !== undefined,
				timerTask: viewer.timerTask ? viewer.timerTask : null,
				projectId: null,
				taskId: null,
				personId: personId,
			});
		}
	};
	const onDiscard = () => {
		trackEvent('Top Navigation Timer Discard', 'Clicked');
		Util.CommitMutation(UpdateViewerMutation, {
			timerStartDate: null,
			timerEndDate: null,
		});
	};

	const formatTime = () => {
		const h = Math.floor(duration / 3600).toString(),
			m = Math.floor((duration % 3600) / 60)
				.toString()
				.padStart(2, '0'),
			s = Math.floor(duration % 60)
				.toString()
				.padStart(2, '0');

		return h + ':' + m + ':' + s;
	};

	return (
		<TimerComponent>
			<TimerComponent.Button
				aria-label={running ? 'pause-timer' : 'start-timer'}
				icon={running ? 'pause' : 'play'}
				onClick={running ? onPause : onPlay}
			/>
			<TimerComponent.Display>{formatTime()}</TimerComponent.Display>
			<TimerComponent.Button
				aria-label="create-time-entry"
				icon="checkCircle"
				disabled={running || duration === 0}
				onClick={onApply}
			/>
			<TimerComponent.Button
				aria-label="discard-time-entry"
				icon="closeCircle"
				disabled={running || duration === 0}
				onClick={onDiscard}
			/>
		</TimerComponent>
	);
};

const TimerQuery = graphql`
	query Timer_Query {
		viewer {
			actualPersonId
			component(name: "top_navigation_timer_component")
			...Timer_viewer
		}
	}
`;

export {TimerQuery};

export default createFragmentContainer(Timer, {
	viewer: graphql`
		fragment Timer_viewer on Viewer {
			timerStartDate
			timerEndDate
			timerTask {
				id
			}
		}
	`,
});
