import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import VisibilitySensor from 'react-visibility-sensor';
import Moment from 'moment';
import {withRouter} from 'react-router-dom';
import {cloneDeep} from 'lodash';
import {DragDropContext} from '@forecasthq/react-virtualized-dnd';
import SprintsSprint from './sprints_sprint';
import SprintsSprintHeader from './sprints_sprint_header';
import CreateSprintMutation from '../../../../mutations/create_sprint_mutation';
import UpdateSprintMutation from '../../../../mutations/update_sprint_mutation';
import Util from '../../../shared/util/util';
import ProjectUtil from '../../../shared/util/project_util';
import UpdateTaskMutation from '../../../../mutations/update_task_mutation.modern';
import DeleteTaskMutation from '../../../../mutations/delete_task_mutation';
import * as tracking from '../../../../tracking';
import {createToast} from '../../../shared/components/toasts/another-toast/toaster';
import {MODAL_TYPE, showModal} from '../../../shared/components/modals/generic_modal_conductor';
import InsightsEmptyState from '../../../shared/components/empty-states/insights_empty_state';
import HeaderBar from '../../../shared/components/headers/header-bar/header_bar';
import ProjectSprintBacklogWrapper, {ProjectSprintBacklogWrapperQuery} from './ProjectSprintBacklogWrapper';
import {
	BUDGET_TYPE,
	BUTTON_COLOR,
	BUTTON_STYLE,
	BUTTON_VARIANT,
	ELEMENT_TYPE,
	EMPTY_STATE,
	ESTIMATION_UNIT,
	FILTER_SECTION,
	FILTER_TYPE,
	HIDDEN_FEATURES,
	MAX_BULK_SELECTION,
	PROJECT_STATUS,
	SOCKET_ACTION,
	SOCKET_EVENT_TYPE,
	WorkflowCategories,
} from '../../../../constants';
import GenericTaskContextMenu from '../../../shared/components/context-menus/generic_task_context_menu';
import ReorderTaskMutation from '../../../../mutations/reorder_task_mutation.modern.sprint-page';
import MoveTasksToCurrentSprintMutation from '../../../../mutations/move_tasks_to_current_sprint_mutation';
import UpdateProjectMutation from '../../../../mutations/update_project_mutation.modern';
import CreateSprintAndMoveTasksMutation from '../../../../mutations/create_sprint_and_move_tasks_mutation';
import EmptyState from '../../../shared/components/empty-states/empty_state';
import {getFiltersAlphabetically} from '../../../shared/components/filters/filter_util';
import {FILTER_SECTIONS} from '../../../shared/components/filters/FilterWrapper';
import {getFilterFunctions} from '../../../shared/components/filters/filter_logic';
import {OPTIONS} from '../../../../containers/modal/UpdateSprintPerformanceModal';
import {TASKS_OPTIONS} from '../../../../containers/modal/CloseSprintModal';
import Warning from '../../../../components/warning';
import UpdateProjectGroupMutation from '../../../../mutations/update_project_group_mutation';
import {hasFeatureFlag} from '../../../shared/util/FeatureUtil';
import {TopHeaderBar} from '../../../shared/components/headers/top-header-bar/TopHeaderBar';
import {hasPermission, hasSomePermission, personIsClientUser} from '../../../shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../Permissions';
import TaskFormatter from '../../../shared/util/export-formatters/TaskFormatter';
import {BulkSelectPopup} from 'web-components';
import {getComplexBulkOptions} from '../../../shared/util/BulkUtil';
import {ArrowRightOutline, BinOutline, MoreActionsOutline} from '../../../../images/svg-collection';
import {getAdditionalBulkUpdateAutomateOptions} from '../scoping-page/ProjectScopingBulkLogic';
import CompanySetupUtil, {isFeatureHidden} from '../../../shared/util/CompanySetupUtil';
import {trackEvent, trackPage, unregisterPageInfo} from '../../../../tracking/amplitude/TrackingV2';
import {recentProjectsHOC} from '../hoc/recentProjectsHOC';
import ProjectHeader from '../shared/ProjectHeader';
import {filterRolesByRatecardDisabledRoles} from '../../../shared/util/RateCardUtil';
import ForecastQueryRenderer from '../../../../ForecastQueryRenderer';
import SideBacklog from '../../../../components/new-ui/side_backlog';
import {projectUrl} from '../../../../directApi';
import {getProjectIndicatorString} from '../../../shared/components/project-indicator/support/ProjectIndicatorLogic';
import {isBillableSplitAllowed} from '../../../shared/util/cache/TimeRegistrationSettingsUtil';
import {eyeOptionsToCols, groupEyeOptions} from './ProjectSprintEyeUtil';
import {optionTraverser} from '../../../../the_eye_util';
import {getTaskUrl, pathIncludesTask} from '../../../shared/util/UrlUtil';

const tabs = [
	{translationId: 'common.all_sprints', value: 'all'},
	{translationId: 'common.done_sprints', value: 'done'},
	{translationId: 'common.active_sprints', value: 'active'},
];

class projectSprintsV4 extends Component {
	constructor(props) {
		super(props);
		if (this.props.viewer.project) {
			Util.localStorageSetItem('project-section-last-viewed', 'sprints');
		} else {
			Util.localStorageSetItem('project-group-section-last-viewed', 'sprints');
		}
		this.state = this.getFreshStateObject(this.props);
		this.handleShortcutProjectSprints = this.handleShortcutProjectSprints.bind(this);
		this.scrollToNewPhase = this.scrollToNewPhase.bind(this);
		this.onWindowResize = this.onWindowResize.bind(this);
		this.closeContextMenu = this.closeContextMenu.bind(this);
		this.onContextMenu = this.onContextMenu.bind(this);
		this.updateContainerWidth = this.updateContainerWidth.bind(this);
		this.registerRowSelection = this.registerRowSelection.bind(this);
		this.handleDeselectMultipleTasks = this.handleDeselectMultipleTasks.bind(this);
		this.handleSelectMultipleTasks = this.handleSelectMultipleTasks.bind(this);
		this.parentGroup =
			this.props.viewer.project &&
			this.props.viewer.project.projectGroupId &&
			this.props.viewer.projectGroups.edges.find(group => group.node.id === this.props.viewer.project.projectGroupId);

		this.superPropertyChecksum = trackPage('Project Sprint');
	}

	UNSAFE_componentWillMount() {
		if (
			(this.props.viewer.project && this.props.viewer.project.sprintTimeBox) ||
			(this.props.viewer.projectGroup &&
				this.props.viewer.projectGroup.projects.edges.length > 0 &&
				this.props.viewer.projectGroup.projects.edges[0].node.sprintTimeBox)
		) {
			const path = this.props.history.location.pathname;
			if (!Util.AuthorizeViewerAccess('project-sprint')) {
				if (pathIncludesTask(path)) {
					const cardPath = getTaskUrl(path);
					if (this.props.viewer.project) {
						this.props.history.push(
							projectUrl(this.props.viewer.project.companyProjectId, this.props.viewer.project.customProjectId) +
								'/workflow' +
								cardPath
						);
					} else {
						this.props.history.push(
							'/connected/X-' + this.props.viewer.projectGroup.companyProjectGroupId + '/workflow' + cardPath
						);
					}
				} else {
					// if user doesnt have access rights to view this page redirect to no access page
					this.props.history.push('/not-authorized');
					if (this.props.viewer.project) {
						Util.localStorageSetItem('project-section-last-viewed', 'workflow');
					} else {
						Util.localStorageSetItem('project-group-section-last-viewed', 'workflow');
					}
				}
			}
		} else {
			if (this.props.viewer.project) {
				this.props.history.push(
					projectUrl(this.props.viewer.project.companyProjectId, this.props.viewer.project.customProjectId) +
						'/workflow'
				);
			} else {
				this.props.history.push('/connected/X-' + this.props.viewer.projectGroup.companyProjectGroupId + '/workflow');
			}
			Util.localStorageSetItem('project-section-last-viewed', 'workflow');
		}
	}

	componentDidMount() {
		tracking.trackPage('project-sprints');
		this.updateTaskCellMaxWidth();
		window.addEventListener('resize', this.onWindowResize);
		document.addEventListener('keydown', this.handleShortcutProjectSprints);
		document.addEventListener('wheel', this.closeMenus.bind(this));
		const cols = this.state.availableColumns.slice();
		const width = this.getColWidths();
		cols.map(col => (col.width = width));

		this.setState({availableColumns: cols});
		if (this._side_div) {
			this.setState({sideBacklogWidth: this._side_div.clientWidth});
		}

		if (this.section_body && this.dnd_context) {
			const screenHeight = window.innerHeight;
			// 10px margin below header
			const headerBarHeight = this.header_bar.getBoundingClientRect().bottom + 16 + (this.props.buyNowTime ? 40 : 8);
			const contextHeight = screenHeight - headerBarHeight;
			this.setState({contextHeight: contextHeight});
		}

		this.setState({containerWidth: this.getContainerWidth(), taskTableWidth: this.getTaskTableWidth()});

		const name = this.props.viewer.project
			? this.props.viewer.project.name !== null && this.props.viewer.project.name !== ''
				? this.props.viewer.project.name
				: getProjectIndicatorString(
						this.props.viewer.project.companyProjectId,
						this.props.viewer.project.customProjectId
				  )
			: this.props.viewer.projectGroup.name !== null && this.props.viewer.projectGroup.name !== ''
			? this.props.viewer.projectGroup.name
			: 'X-' + this.props.viewer.projectGroup.compantProjectGroupId;
		document.title = 'Sprints - ' + name + ' - Forecast';

		let projectIds;

		if (this.props.viewer.project) {
			projectIds = parseInt(atob(this.props.viewer.project.id).replace('ProjectType:', ''));
		} else if (this.props.viewer.projectGroup) {
			projectIds = this.props.viewer.projectGroup.projects.edges.map(edge =>
				parseInt(atob(edge.node.id).replace('ProjectType:', ''))
			);
		}
		const actualPersonIdInt = parseInt(atob(this.props.viewer.actualPersonId).replace('Person:', ''));

		if (projectIds) {
			const socketEvents = [
				{
					type: SOCKET_EVENT_TYPE.PROJECT,
					action: SOCKET_ACTION.DELETE,
					projectIds: projectIds,
				},
				{
					type: SOCKET_EVENT_TYPE.PROJECT,
					action: SOCKET_ACTION.UPDATE,
					projectIds: projectIds,
				},
				{
					type: SOCKET_EVENT_TYPE.TIME_LOCK,
					action: SOCKET_ACTION.UPDATE,
					personIds: actualPersonIdInt,
				},
				{
					type: SOCKET_EVENT_TYPE.TASK,
					action: SOCKET_ACTION.UPDATE,
					projectIds: projectIds,
				},
				{
					type: SOCKET_EVENT_TYPE.TASK,
					action: SOCKET_ACTION.CREATE,
					projectIds: projectIds,
				},
				{
					type: SOCKET_EVENT_TYPE.TASK,
					action: SOCKET_ACTION.DELETE,
					projectIds: projectIds,
				},
				{
					type: SOCKET_EVENT_TYPE.SPRINT,
					action: SOCKET_ACTION.UPDATE,
					projectIds: projectIds,
				},
				{
					type: SOCKET_EVENT_TYPE.SPRINT,
					action: SOCKET_ACTION.CREATE,
					projectIds: projectIds,
				},
				{
					type: SOCKET_EVENT_TYPE.SPRINT,
					action: SOCKET_ACTION.DELETE,
					projectIds: projectIds,
				},
			];
			if (!hasPermission(PERMISSION_TYPE.PROJECTS_READ_ALL)) {
				socketEvents.push({
					type: SOCKET_EVENT_TYPE.PROJECT_PERSON,
					action: SOCKET_ACTION.DELETE,
					projectIds: projectIds,
					personIds: this.props.viewer.backendId,
				});
			}
			if (this.props.relay.refetch) {
				this.props.setSocketConfig(socketEvents, () => {
					this.refetchSprintData(false);
				});
			} else {
				this.props.setSocketConfig(socketEvents);
			}

			this.setState({
				socketData: {
					socketEvents,
					projectIds,
					actualPersonIdInt,
				},
			});
		}

		this.refetchSprintData();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (
			(this.props.viewer.project && !nextProps.viewer.project) ||
			(nextProps.viewer.project && !this.props.viewer.project)
		) {
			//changing from project to group or from group to project
			if (nextProps.viewer.project) {
				if (nextProps.viewer.project.sprintTimeBox) {
					this.setState(this.getFreshStateObject(nextProps));
				} else {
					this.props.history.push(
						projectUrl(nextProps.viewer.project.companyProjectId, nextProps.viewer.project.customProjectId) +
							'/workflow'
					);
				}
			} else {
				if (nextProps.viewer.projectGroup.projects.edges[0].node.sprintTimeBox) {
					this.setState(this.getFreshStateObject(nextProps));
				} else {
					this.props.history.push(
						'/connected/X-' + nextProps.viewer.projectGroup.companyProjectGroupId + '/workflow'
					);
				}
			}
		} else if (
			this.props.viewer.project &&
			nextProps.viewer.project &&
			this.props.viewer.project.id !== nextProps.viewer.project.id
		) {
			//Check if nextProps project has sprints enabled if not navigate to workflow
			if (nextProps.viewer.project.sprintTimeBox) {
				this.setState(this.getFreshStateObject(nextProps));
			} else {
				this.props.history.push(
					projectUrl(nextProps.viewer.project.companyProjectId, nextProps.viewer.project.customProjectId) +
						'/workflow'
				);
			}
		} else if (
			this.props.viewer.projectGroup &&
			nextProps.viewer.projectGroup &&
			this.props.viewer.projectGroup.id !== nextProps.viewer.projectGroup.id
		) {
			if (nextProps.viewer.projectGroup.projects.edges[0].node.sprintTimeBox) {
				this.setState(this.getFreshStateObject(nextProps));
			} else {
				this.props.history.push('/connected/X-' + nextProps.viewer.projectGroup.companyProjectGroupId + '/workflow');
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const width = this.getTaskCellMaxWidth();
		// We never pass containerWidth as props, so should this be removed?
		if (this.props.containerWidth !== prevProps.containerWidth || width !== this.state.taskCellMaxWidth) {
			this.updateTaskCellMaxWidth();
		}

		// Reset selectedTasks if a task is deleted to exclude the deleted tasks.
		if (this.props.viewer.project) {
			if (
				this.state.selectedTasks.length > 0 &&
				this.props.viewer.project.tasks.edges.length < prevProps.viewer.project.tasks.edges.length
			) {
				const prevSelectedTaskIds = this.state.selectedTasks.map(task => task.node.id);
				const newSelectedTasks = this.props.viewer.project.tasks.edges.filter(task =>
					prevSelectedTaskIds.includes(task.node.id)
				);
				this.setState({selectedTasks: newSelectedTasks});
			}
		} else if (this.props.viewer.projectGroup) {
			if (this.state.selectedTasks.length > 0) {
				const prevAllTasksCount = prevProps.viewer.projectGroup.projects.edges.reduce(
					(acc, project) => acc + project.node.tasks.edges.length,
					0
				);
				const allTasksCount = this.props.viewer.projectGroup.projects.edges.reduce(
					(acc, project) => acc + project.node.tasks.edges.length,
					0
				);
				if (allTasksCount < prevAllTasksCount) {
					// Addition is far more efficient that concatenation, so only concatenate in the case when we KNOW that the list has changed length
					const allTasks = this.props.viewer.projectGroup.projects.edges.reduce(
						(acc, project) => acc.concat(project.node.tasks.edges),
						[]
					);
					const prevSelectedTaskIds = this.state.selectedTasks.map(task => task.node.id);
					const newSelectedTasks = allTasks.filter(task => prevSelectedTaskIds.includes(task.node.id));
					this.setState({selectedTasks: newSelectedTasks});
				}
			}
		}

		if (prevState.searchFilterValue !== this.state.searchFilterValue) {
			if (this.state.searchFilterValue.length > 0) {
				const searchFilter = task => {
					const taskValue = 'T' + task.node.companyTaskId + task.node.name;
					const searchValue = this.state.searchFilterValue.trim();
					return Util.normalizedIncludes(taskValue, searchValue);
				};
				const matchingTasks = this.tasks.filter(searchFilter).length;
				trackEvent('Search', 'Results', {searchString: this.state.searchFilterValue, matching: matchingTasks});
			}
		}

		if (prevState.selectedTab !== this.state.selectedTab) {
			this.refetchSprintData(false);
		}

		this.setShouldCollapseAll();
		this.updateContainerWidth();
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);

		document.removeEventListener('keydown', this.handleShortcutProjectSprints);
		document.removeEventListener('wheel', this.closeMenus);
		window.removeEventListener('resize', this.onWindowResize);
		cancelAnimationFrame(this.requestedFrame);
	}

	refetchSprintData(useLazyDataFetched = true) {
		let allSprints = this.isConnectedParent
			? cloneDeep(this.props.viewer.projectGroup.projects.edges)
					.reduce((acc, project) => acc.concat(project.node.sprints ? project.node.sprints.edges : []), [])
					.filter(sprint => sprint.node.isProjectGroupSprint)
			: cloneDeep(this.props.viewer.project.sprints.edges);

		switch (this.state.selectedTab.value) {
			case 'active':
				allSprints = allSprints = allSprints.filter(s1 => {
					const sprint = this.visibleSprints.find(
						s2 => s2.id === s1.node.id || s2.projectGroupSprintId === s1.node.projectGroupSprintId
					);
					return sprint && !sprint.isDone;
				});
				break;
			case 'all':
			case 'done': {
				allSprints = null;
				break;
			}
			default:
				break;
		}

		if (useLazyDataFetched) {
			this.setState({lazyDataFetched: false});
		}

		if (this.props.refetch) {
			this.props.refetch(
				{
					fetchLazyData: true,
					onlyActiveSprint: false,
					sprintIds: allSprints?.map(sprint => sprint.node.id),
				},
				null,
				() => this.setState({lazyDataFetched: true})
			);
		}
	}

	updateContainerWidth() {
		const containerWidth = this.getContainerWidth();
		const taskTableWidth = this.getTaskTableWidth();
		if (containerWidth !== this.state.containerWidth || taskTableWidth !== this.state.taskTableWidth) {
			this.setState({containerWidth: containerWidth, taskTableWidth: taskTableWidth});
		}
	}

	getContextHeight() {
		const screenHeight = window.innerHeight;
		// 10px margin below header
		let headerBarHeight = this.header_bar.getBoundingClientRect().bottom + 16 + (this.props.buyNowTime ? 40 : 8);
		return screenHeight - headerBarHeight;
	}

	isEmpty(obj) {
		for (const key in obj) {
			if (obj.hasOwnProperty(key)) return false;
		}
		return true;
	}

	goToParent() {
		this.props.history.push('/connected/X-' + this.parentGroup.node.companyProjectGroupId + '/sprints');
	}

	updateTaskCellMaxWidth() {
		const taskCellMaxWidth = this.getTaskCellMaxWidth();
		// const minWidth = this.state.availableColumns.reduce((acc, elem) => acc + (elem.checked ? (elem.name === 'task-name' ? taskCellMaxWidth : elem.minWidth) : 0), 0);
		this.setState({taskCellMaxWidth: taskCellMaxWidth});
	}

	onWindowResize() {
		if (this.section_body && this.dnd_context) {
			this.setState(
				{
					containerWidth: this.getContainerWidth(),
					taskTableWidth: this.getTaskTableWidth(),
					contextHeight: this.getContextHeight(),
				},
				() => this.updateTaskCellMaxWidth()
			);
		} else
			this.setState({containerWidth: this.getContainerWidth(), taskTableWidth: this.getTaskTableWidth()}, () =>
				this.updateTaskCellMaxWidth()
			);
	}

	compareSprintDates(a, b) {
		let aStartDate = Moment({
			y: a.node.startYear,
			M: a.node.startMonth - 1,
			d: a.node.startDay,
		});
		let aEndDate = Moment({
			M: a.node.endMonth - 1,
			y: a.node.endYear,
			d: a.node.endDay,
		});
		let bStartDate = Moment({
			y: b.node.startYear,
			M: b.node.startMonth - 1,
			d: b.node.startDay,
		});
		let bEndDate = Moment({
			y: b.node.endYear,
			M: b.node.endMonth - 1,
			d: b.node.endDay,
		});
		//Date used for sorting sprints with not selected dates
		const dummyDate = Moment({
			y: -10000,
			M: 1,
			d: 1,
		});

		if (!aStartDate.isValid()) aStartDate = dummyDate.clone();
		if (!aEndDate.isValid()) aEndDate = dummyDate.clone();
		if (!bStartDate.isValid()) bStartDate = dummyDate.clone();
		if (!bEndDate.isValid()) bEndDate = dummyDate.clone();

		if (aStartDate.isBefore(bStartDate)) return 1;
		if (bStartDate.isBefore(aStartDate)) return -1;
		//If same date, sort by end date ascending
		if (aEndDate.isBefore(bEndDate)) return 1;
		if (bEndDate.isBefore(aEndDate)) return -1;

		const aId = parseInt(atob(a.node.id).replace('PhaseType:', ''), 10);
		const bId = parseInt(atob(b.node.id).replace('PhaseType:', ''), 10);
		if (aId < bId) return 1;
		return -1;
	}

	getFreshStateObject(props) {
		const {formatMessage} = props.intl;

		const sprintsOrderMap = new Map();
		const sprints = this.props.viewer.project
			? cloneDeep(this.props.viewer.project.sprints.edges)
			: cloneDeep(this.props.viewer.projectGroup.projects.edges[0].node.sprints.edges);
		sprints.sort(this.compareSprintDates).forEach((sprint, index) => {
			sprintsOrderMap.set(sprint.node.id, index);
		});

		const sprintsMap = new Map();
		sprints.forEach(sprint => {
			sprintsMap.set(sprint.node.id, !this.shouldSprintStartClosedNew(sprint.node));
		});

		let burgerMenuOptions = [];

		burgerMenuOptions = [
			{
				id: 'group_by_people_enable',
				type: ELEMENT_TYPE.TOGGLE_SWITCH,
				name: formatMessage({id: 'common.group_by_people_enable'}),
				activated: localStorage.getItem('project-sprints-group-by-person')
					? JSON.parse(localStorage.getItem('project-sprints-group-by-person'))
					: Util.localStorageSetItem('project-sprints-group-by-person', false),
			},
			{
				id: 'persons_grouping_show_all',
				type: ELEMENT_TYPE.TOGGLE_SWITCH,
				name: formatMessage({id: 'common.persons_grouping_show_all'}),
				activated: localStorage.getItem('project-sprints-show-empty-persons')
					? JSON.parse(localStorage.getItem('project-sprints-show-empty-persons'))
					: Util.localStorageSetItem('project-sprints-show-empty-persons', false),
			},
		];
		this.isConnectedParent = !!(!props.viewer.project && props.viewer.projectGroup);
		this.isConnectedChild = !this.isConnectedParent && props.viewer.project.isInProjectGroup;
		const projectId = this.isConnectedParent ? props.viewer.projectGroup.id : props.viewer.project.id;
		let filters;
		let filtersKey = 'project-sprintV3-filters-v4-';
		if (localStorage.getItem(`${filtersKey}${projectId}`)) {
			filters = JSON.parse(localStorage.getItem(`${filtersKey}${projectId}`));
		} else {
			filters = {
				person: {},
				task: {},
			};
		}
		const filterFunctions = getFilterFunctions(filters);
		const sideBacklog =
			JSON.parse(localStorage.getItem('backlog-placement')) !== null
				? JSON.parse(localStorage.getItem('backlog-placement'))
				: true;

		let projectPersons;
		if (this.props.viewer.project) {
			projectPersons = cloneDeep(this.props.viewer.project.projectPersons.edges);
		} else {
			//connected project
			projectPersons = cloneDeep(this.props.viewer.projectGroup.projects.edges[0].node.projectPersons.edges);
			this.props.viewer.projectGroup.projects.edges.forEach(project =>
				project.node.projectPersons.edges.forEach(person => {
					if (
						personIsClientUser(person.node.person) &&
						!projectPersons.find(p => p.node.person.id === person.node.person.id)
					) {
						projectPersons.push(person);
					}
				})
			);
		}

		return {
			animationPosition: {},
			sideBacklogWidth: 0,
			showEmptyPersons: JSON.parse(localStorage.getItem('project-sprints-show-empty-persons')),
			groupByPerson: JSON.parse(localStorage.getItem('project-sprints-group-by-person')),
			groupByRole: JSON.parse(localStorage.getItem('project-sprints-group-by-role')),
			hasScroll: false,
			selectedTasks: [],
			selectionMode: false,
			burgerMenuOptions: burgerMenuOptions,
			dragAndDropGroup: 'project-sprints',
			focusSprintId: null,
			tasksBeingDragged: [],
			columnWidths: 0,
			taskAboveId: null,
			draggingTask: false,
			phaseFilterValue: (
				JSON.parse(localStorage.getItem('project-sprints-filter-value-' + projectId + '-phase')) || []
			).filter(val => val !== null),
			statusFilterValue: (
				JSON.parse(localStorage.getItem('project-sprints-filter-value-' + projectId + '-status')) || []
			).filter(val => val !== null),
			labelFilterValue: (
				JSON.parse(localStorage.getItem('project-sprints-filter-value-' + projectId + '-label')) || []
			).filter(val => val !== null),
			roleFilterValue: (
				JSON.parse(localStorage.getItem('project-sprints-filter-value-' + projectId + '-role')) || []
			).filter(val => val !== null),
			blocked_bugFilterValue: (
				JSON.parse(localStorage.getItem('project-sprints-filter-value-' + projectId + '-blocked_bug')) || []
			).filter(val => val !== null),
			sprintFilterValue: (
				JSON.parse(localStorage.getItem('project-sprints-filter-value-' + projectId + '-sprint')) || []
			).filter(val => val !== null),
			recentlyUpdatedFilterValue: (
				JSON.parse(localStorage.getItem('project-sprints-filter-value-' + projectId + '-recentlyUpdated')) || []
			).filter(val => val !== null),
			teamFilterValue: (
				JSON.parse(localStorage.getItem('project-sprints-filter-value-' + projectId + '-team')) || []
			).filter(val => val !== null),
			teammemberFilterValue: (
				JSON.parse(localStorage.getItem('project-sprints-filter-value-' + projectId + '-teammember')) || []
			).filter(val => val !== null),
			searchFilterValue: '',
			sprintsOrderMap: sprintsOrderMap,
			availableColumns: this.getInitialSelectedColumns(),
			selectedTab: this.getInitialSelectedTab(),
			sprintsMap: sprintsMap,
			collapseAll: false,
			containerWidth: this.getContainerWidth(),
			taskTableWidth: 0,
			showContextMenu: false,
			contextMenuElement: null,
			contextMenuPosition: null,
			contextMenuTask: null,
			filters,
			filterFunctions,
			sideBacklog: sideBacklog,
			projectPersons,
			lazyDataFetched: false,
			visibleSprints: [],
			reloadBacklog: null,
		};
	}

	getInitialSelectedColumns() {
		const isConnectedParent = !this.props.viewer.project;
		const storedCols = JSON.parse(
			localStorage.getItem((isConnectedParent ? 'connected-' : '') + 'project-sprints-selected-cols')
		);
		const hasFinancialAccess =
			CompanySetupUtil.hasFinance() &&
			hasSomePermission([PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION, PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE]);
		const sprintsEnabled =
			(this.props.viewer.project && this.props.viewer.project.sprintTimeBox) ||
			(this.props.viewer.projectGroup && this.props.viewer.projectGroup.projects.edges[0].node.sprintTimeBox);
		const isEstimatedInNewPoints =
			(this.isConnectedParent
				? this.props.viewer.projectGroup.projects.edges[0].node.estimationUnit
				: this.props.viewer.project.estimationUnit) === 'POINTS';
		const isDemoProject = this.isConnectedParent
			? this.props.viewer.projectGroup.projects.edges[0].node.demo
			: this.props.viewer.project.demo;
		const hideRevenue =
			isFeatureHidden(HIDDEN_FEATURES.REVENUE) ||
			(this.isConnectedParent ? false : this.props.viewer.project.budgetType === BUDGET_TYPE.NON_BILLABLE);
		const showBillableTimeSplit = isBillableSplitAllowed();
		const availableCols = Util.getSprintColumns(
			isConnectedParent,
			hasFinancialAccess,
			sprintsEnabled,
			isEstimatedInNewPoints,
			undefined,
			isDemoProject,
			!hideRevenue,
			this.props.viewer.project,
			showBillableTimeSplit
		);

		availableCols.forEach(availableColumn => {
			const storedColumn = storedCols?.find(storedColumn => storedColumn.id === availableColumn.name);

			if (!availableColumn.hide) {
				availableColumn.checked = storedColumn ? storedColumn.checked : availableColumn.sprintDefault || false;
			}
		});

		//If this is showing just one project and that project does not register time, remove the time columns
		//If we are showing project groups, we will show time info if at least one project is not using manual progress
		let hideTimeRegistrations = false;
		if (this.props.viewer.project) {
			hideTimeRegistrations = ProjectUtil.projectUsesManualProgress(this.props.viewer.project);
		} else {
			hideTimeRegistrations = ProjectUtil.allProjectEdgesUseManualProgress(this.props.viewer.projectGroup.projects.edges);
		}

		if (hideTimeRegistrations) {
			return Util.getNoTimeRegColumns(availableCols);
		}

		return availableCols;
	}

	getInitialSelectedTab() {
		// const storedTab = localStorage.getItem('project-sprints-selected-tab');
		// if (storedTab) {
		// 	return tabs.find(tab => tab.value === storedTab);
		// } else
		return tabs[2]; //active sprint by default
	}

	getColWidths(cols) {
		const numActiveCols = cols
			? cols.filter(col => col.checked).length
			: this.state.availableColumns.filter(col => col.checked).length;
		return 100 / numActiveCols;
	}

	shouldSprintStartClosed(sprint) {
		let shouldStartClosed = true;
		if (sprint.tasks && sprint.startDay && sprint.endDay) {
			const sprintStartDate = Util.CreateNonUtcMomentDate(sprint.startYear, sprint.startMonth, sprint.startDay);
			const sprintEndDate = Util.CreateNonUtcMomentDate(sprint.endYear, sprint.endMonth, sprint.endDay);
			if (
				Moment().isBetween(sprintStartDate, sprintEndDate) &&
				sprint.tasks.filter(task => task.statusColumnV2.category !== WorkflowCategories.DONE).length !== 0
			) {
				shouldStartClosed = false;
			}
		}
		return shouldStartClosed;
	}

	shouldSprintStartClosedNew(sprint) {
		let shouldStartClosed = true;
		if (sprint.startDay && sprint.endDay) {
			const sprintStartDate = Util.CreateNonUtcMomentDate(sprint.startYear, sprint.startMonth, sprint.startDay);
			const sprintEndDate = Util.CreateNonUtcMomentDate(sprint.endYear, sprint.endMonth, sprint.endDay);
			if (Moment().isBetween(sprintStartDate, sprintEndDate, 'day', '[]')) {
				shouldStartClosed = false;
			}
		}
		return shouldStartClosed;
	}

	doCoolStuff() {
		this.setState({showHingeAnimation: true});
	}

	undoCoolStuff() {
		this.setState({showHingeAnimation: false});
		this.keysPressed = [];
	}

	handleShortcutProjectSprints(e) {
		const taskModalOpen = Util.getPathIsTaskModal();
		const activeElement = document.activeElement;
		const focusIsInput =
			activeElement &&
			(activeElement.tagName === 'INPUT' ||
				activeElement.tagName === 'TEXTAREA' ||
				activeElement.isContentEditable ||
				(activeElement.className && activeElement.className.toLowerCase().includes('drafteditor')) ||
				(activeElement.className && activeElement.className.toLowerCase().includes('switch')));
		if (taskModalOpen || focusIsInput || e.repeat) {
			return;
		}
		if (this.keysPressed == null) {
			this.keysPressed = [];
		}
		// We don't care unless it's the correct start of the sequence
		if (this.keysPressed.length === 0 && e.key === 'ArrowUp') {
			this.keysPressed.push(e.key);
		} else if (this.keysPressed.length > 0) {
			this.keysPressed.push(e.key);
			if (e.key === 'Enter' && this.keysPressed && this.keysPressed.length >= 10) {
				const solution = [
					'ArrowUp',
					'ArrowUp',
					'ArrowDown',
					'ArrowDown',
					'ArrowLeft',
					'ArrowRight',
					'ArrowLeft',
					'ArrowRight',
					'b',
					'a',
					'Enter',
				]; // Konami!
				let solved = true;
				this.keysPressed.forEach((key, index) => {
					if (solution[index] !== key) {
						solved = false;
					}
				});
				if (solved) {
					this.doCoolStuff();
				}
			} else {
				if (this.keysPressed.length > 11) {
					this.keysPressed = [];
				}
			}
		}
		if (this.state.selectedTasks.length > 0 && e.key === 'Delete') {
			this.bulkUpdateDelete();
		}
		if (e.shiftKey && !e.altKey && e.key === 'G') {
			e.preventDefault();
			this.toggleGroupByPerson();
		}
		if (e.shiftKey && !e.altKey && e.key === 'E') {
			e.preventDefault();
			this.toggleShowEmptyPersons();
		}
		if (e.shiftKey && !e.altKey && e.key === 'N') {
			e.preventDefault();
			this.addSprint();
		}
		if (e.key === 'Enter' && e.ctrlKey) {
			this.toggleCollapseAll();
		}
		if (e.key === 'Escape') {
			if (this.state.selectedTasks.length > 0) {
				this.setState({selectedTasks: [], selectionMode: false});
			}
		}
		if (e.altKey && e.shiftKey && e.keyCode === 88) {
			this.clearFilters();
		}
	}

	handleFilterChange(filterType, value) {
		this.setState({[filterType + 'FilterValue']: value});
		if (typeof value === 'string') {
			Util.localStorageSetItem(
				'project-sprints-filter-value-' +
					(!this.isConnectedParent ? this.props.viewer.project.id : this.props.viewer.projectGroup.id) +
					'-' +
					filterType,
				JSON.stringify(value)
			);
		} else {
			Util.localStorageSetItem(
				'project-sprints-filter-value-' +
					(!this.isConnectedParent ? this.props.viewer.project.id : this.props.viewer.projectGroup.id) +
					'-' +
					filterType,
				JSON.stringify(value.map(v => ({...v, label: null})))
			);
		}
	}

	unselectTasks() {
		this.setState({selectedTasks: []});
		this.updateSelectionMode(false);
	}

	sortByEndDate(projectA, projectB) {
		const aEnd = Util.CreateMomentDate(projectA.node.endYear, projectA.node.endMonth, projectA.node.endDay);
		const bEnd = Util.CreateMomentDate(projectB.node.endYear, projectB.node.endMonth, projectB.node.endDay);
		return aEnd.isBefore(bEnd) ? 1 : -1;
	}

	addSprint() {
		if (this.isConnectedChild) {
			return;
		}
		const onSuccess = result => {
			this.isCreatingSprint = false;
			const sprintsOrderMap = new Map();
			const sprintsMap = this.state.sprintsMap;
			if (this.props.viewer.project) {
				sprintsOrderMap.set(result.createSprint.sprint.node.id, 0);
				sprintsMap.set(result.createSprint.sprint.node.id, true);
			} else {
				sprintsOrderMap.set(result.createSprint.sprint.node.projectGroupSprintId, 0);
				sprintsMap.set(result.createSprint.sprint.node.id, true);
			}
			this.state.sprintsOrderMap.forEach((value, key) => sprintsOrderMap.set(key, value + 1));
			this.setState({
				focusSprintId: result.createSprint.sprint.node.id,
				sprintsOrderMap: sprintsOrderMap,
				sprintsMap: sprintsMap,
			});

			createToast({
				duration: 5000,
				message: this.props.intl.formatMessage({id: 'project_sprints.sprint-created'}),
			});
		};

		let startDate = Moment();
		if (
			(this.props.viewer.project && this.props.viewer.project.sprints.edges.length) ||
			(this.props.viewer.projectGroup && this.props.viewer.projectGroup.projects.edges[0].node.sprints.edges.length)
		) {
			const latestSprint = this.props.viewer.project
				? cloneDeep(this.props.viewer.project.sprints.edges).sort(this.sortByEndDate)[0]
				: cloneDeep(this.props.viewer.projectGroup.projects.edges[0].node.sprints.edges).sort(this.sortByEndDate)[0];
			const afterLastSprint = Moment()
				.set('year', latestSprint.node.endYear)
				.set('month', latestSprint.node.endMonth - 1)
				.set('date', latestSprint.node.endDay)
				.add(1, 'day');
			const today = Moment().startOf('day');
			startDate = afterLastSprint.isBefore(today) ? today : afterLastSprint;
		}
		let sprintLength = 13;
		if (
			(this.props.viewer.project && this.props.viewer.project.sprintLengthInDays) ||
			(this.props.viewer.projectGroup && this.props.viewer.projectGroup.projects.edges[0].node.sprintLengthInDays)
		) {
			sprintLength = this.props.viewer.project
				? this.props.viewer.project.sprintLengthInDays - 1
				: this.props.viewer.projectGroup.projects.edges[0].node.sprintLengthInDays - 1;
		}
		if (sprintLength < 0) {
			sprintLength = 0;
		}
		const endDate = startDate.clone().add(sprintLength, 'day');
		let mutationObject = null;

		if (this.props.viewer.project) {
			mutationObject = {
				name:
					this.props.intl.formatMessage({id: 'sprints.sprint'}) +
					' ' +
					(this.props.viewer.project.sprints.edges.length + 1),
				projectId: this.props.viewer.project.id,
				startDay: startDate.date(),
				startMonth: startDate.month() + 1,
				startYear: startDate.year(),
				endDay: endDate.date(),
				endMonth: endDate.month() + 1,
				endYear: endDate.year(),
			};
			if (!this.isCreatingSprint) {
				this.isCreatingSprint = true;
				Util.CommitMutation(CreateSprintMutation, mutationObject, onSuccess);
			}
		} else if (this.props.viewer.projectGroup) {
			mutationObject = {
				name:
					this.props.intl.formatMessage({id: 'sprints.sprint'}) +
					' ' +
					(this.props.viewer.projectGroup.projects.edges[0].node.sprints.edges.length + 1),
				projectGroupId: this.props.viewer.projectGroup.id,
				projectId: this.props.viewer.projectGroup.projects.edges[0].node.id,
				startDay: startDate.date(),
				startMonth: startDate.month() + 1,
				startYear: startDate.year(),
				endDay: endDate.date(),
				endMonth: endDate.month() + 1,
				endYear: endDate.year(),
			};
			if (!this.isCreatingSprint) {
				this.isCreatingSprint = true;
				Util.CommitMutation(CreateSprintMutation, mutationObject, onSuccess);
			}
		}
		return mutationObject;
	}

	scrollToNewPhase(newPhaseId) {
		if (this.scrollbar) {
			if (newPhaseId) {
				requestAnimationFrame(() => {
					const el = document.getElementById('phase-wrapper-' + newPhaseId);
					if (el) {
						this.scrollbar.animateScrollTop(el.offsetTop - 20);
						this.scrollbar.scrollToLeft();
					}
				});
			} else {
				this.scrollbar.scrollTop(0);
			}
		}
	}

	clearFilters() {
		this.setState({
			phaseFilterValue: [],
			statusFilterValue: [],
			labelFilterValue: [],
			recentlyUpdatedFilterValue: [],
			teammemberFilterValue: [],
			roleFilterValue: [],
			sprintFilterValue: [],
			teamFilterValue: [],
			blocked_bugFilterValue: [],
			searchFilterValue: '',
			selectedTasks: [],
		});
		localStorage.removeItem('project-sprints-filter-value-' + this.props.viewer.project.id + '-status');
		localStorage.removeItem('project-sprints-filter-value-' + this.props.viewer.project.id + '-phase');
		localStorage.removeItem('project-sprints-filter-value-' + this.props.viewer.project.id + '-label');
		localStorage.removeItem('project-sprints-filter-value-' + this.props.viewer.project.id + '-role');
		localStorage.removeItem('project-sprints-filter-value-' + this.props.viewer.project.id + '-sprint');
		localStorage.removeItem('project-sprints-filter-value-' + this.props.viewer.project.id + '-recentlyUpdated');
		localStorage.removeItem('project-sprints-filter-value-' + this.props.viewer.project.id + '-blocked_bug');
		localStorage.removeItem('project-sprints-filter-value-' + this.props.viewer.project.id + '-team');
		localStorage.removeItem('project-sprints-filter-value-' + this.props.viewer.project.id + '-teammember');
	}

	bulkUpdateStatus() {
		const callbackPositive = selected => {
			const onSuccess = () => {
				createToast({duration: 5000, message: this.props.intl.formatMessage({id: 'bulk_update.toast'})});
			};
			const mutationObject = {
				ids: this.state.selectedTasks.map(t => t.node.id),
			};
			if (this.props.viewer.project || !selected) {
				mutationObject.statusColumnId = selected ? selected.value : null;
				mutationObject.statusColumnCategory = selected ? selected.category : null;
			} else {
				mutationObject.projectGroupStatusColumnId = selected.value;
				mutationObject.statusColumnCategory = selected ? selected.category : null;
			}
			Util.CommitMutation(UpdateTaskMutation, mutationObject, onSuccess);
		};
		const status_select_options = this.props.viewer.project
			? cloneDeep(this.props.viewer.project.statusColumnsV2.edges)
					.sort((a, b) => a.node.order - b.node.order)
					.map(col => ({value: col.node.id, label: col.node.name, category: col.node.category}))
			: cloneDeep(this.props.viewer.projectGroup.projects.edges[0].node.statusColumnsV2.edges)
					.sort((a, b) => a.node.order - b.node.order)
					.map(col => ({
						value: col.node.projectGroupStatusColumnId,
						label: col.node.name,
						category: col.node.category,
					}));
		showModal({
			type: MODAL_TYPE.SELECT_V2,
			title: this.props.intl.formatMessage({id: 'bulk_update_option.move_to_status'}),
			defaultCallback: callbackPositive,
			options: status_select_options,
			label: this.props.intl.formatMessage({id: 'common.status'}),
			multi: false,
			initialValue: null,
		});
	}

	bulkUpdateMoveToSprint() {
		const callbackPositive = selected => {
			const onSuccess = () => {
				createToast({duration: 5000, message: this.props.intl.formatMessage({id: 'bulk_update.toast'})});
			};
			const mutationObject = {
				ids: this.state.selectedTasks.map(t => t.node.id),
			};
			if (this.props.viewer.project || !selected) {
				mutationObject.sprintId = selected ? selected.value : null;
			} else {
				mutationObject.projectGroupSprintId = selected.value;
			}
			mutationObject.pageContext = 'sprintv4';
			mutationObject.prevStates = this.state.selectedTasks;
			Util.CommitMutation(UpdateTaskMutation, mutationObject, onSuccess);

			this.unselectTasks();
		};
		const sprint_select_options = this.props.viewer.project
			? cloneDeep(this.props.viewer.project.sprints.edges)
					.sort(this.compareSprintDates)
					.map(sprint => ({value: sprint.node.id, label: sprint.node.name}))
			: cloneDeep(this.props.viewer.projectGroup.projects.edges[0].node.sprints.edges)
					.sort(this.compareSprintDates)
					.filter(sprint => sprint.node.isProjectGroupSprint)
					.map(sprint => ({value: sprint.node.projectGroupSprintId, label: sprint.node.name}));
		sprint_select_options.push({
			value: null,
			label: this.props.intl.formatMessage({id: 'project_sprints.backlog'}),
		});
		showModal({
			type: MODAL_TYPE.SELECT_V2,
			title: this.props.intl.formatMessage({id: 'project_section.bulk_move_cards_sprint'}),
			defaultCallback: callbackPositive,
			options: sprint_select_options,
			label: this.props.intl.formatMessage({id: 'common.sprint'}),
			multi: false,
			initialValue: null,
		});
	}

	bulkUpdateMoveToPhase() {
		const callbackPositive = selected => {
			const onSuccess = () => {
				createToast({duration: 5000, message: this.props.intl.formatMessage({id: 'bulk_update.toast'})});
			};
			Util.CommitMutation(
				UpdateTaskMutation,
				{
					ids: this.state.selectedTasks.map(t => t.node.id),
					phaseId: selected ? selected.value : null,
				},
				onSuccess
			);
		};
		const phase_select_options = this.props.viewer.project.phases.edges.map(phase => ({
			value: phase.node.id,
			label: phase.node.name,
		}));

		showModal({
			type: MODAL_TYPE.SELECT_V2,
			title: this.props.intl.formatMessage({id: 'project_section.bulk_move_cards_milesone'}),
			defaultCallback: callbackPositive,
			options: phase_select_options,
			label: this.props.intl.formatMessage({id: 'common.scope-group'}),
			multi: false,
			initialValue: null,
		});
	}

	bulkUpdateMoveToProject() {
		let currentProjectId;
		if (this.props.viewer.project) {
			currentProjectId = this.props.viewer.project.id;
		} else {
			currentProjectId = this.state.selectedTasks[0].node.project.id;
			if (
				this.state.selectedTasks.filter(t => {
					return t.node.project.id !== currentProjectId;
				}).length > 0
			) {
				currentProjectId = null; //multiple projects selected
			}
		}
		showModal({
			type: MODAL_TYPE.TASK_LOCATION,
			currentProjectId: currentProjectId,
			taskId: this.state.selectedTasks.map(task => task.node.id),
		});
		this.unselectTasks();
	}

	bulkUpdateTask() {
		const onSuccess = errors => {
			if (errors && errors.length !== 0) {
				Util.resolveTaskStatusErrors(errors, this.props.intl);
			}
		};

		const availableRoles = this.props.viewer.project
			? filterRolesByRatecardDisabledRoles(this.props.viewer.company.roles.edges, this.props.viewer.project.rateCard)
			: this.props.viewer.company.roles.edges;
		const role_options = availableRoles.map(role => ({value: role.node.id, label: role.node.name}));

		const people_options = this.state.projectPersons
			.filter(person => {
				if (!personIsClientUser(person.node.person)) return true;

				// If client project person, only show if not a connected project.
				return !this.props.viewer.projectGroup;
			})
			.map(person => ({
				value: person.node.person.id,
				label: person.node.person.firstName + ' ' + person.node.person.lastName,
				profilePictureId: person.node.person.profilePictureId,
				profilePictureDefaultId: person.node.person.profilePictureDefaultId,
			}));

		const status_options = this.isConnectedParent
			? this.props.viewer.projectGroup.projects.edges[0].node.statusColumnsV2.edges.map(col => ({
					value: col.node.projectGroupStatusColumnId,
					label: col.node.name,
					logo: col.node.jiraStatusId ? 'jira-logo' : undefined,
			  }))
			: this.props.viewer.project.statusColumnsV2.edges.map(col => ({
					value: col.node.id,
					label: col.node.name,
					logo: col.node.jiraStatusId ? 'jira-logo' : undefined,
			  }));
		showModal({
			type: MODAL_TYPE.BULK_TASK_UPDATE,
			useTaskFollowers: !!this.props.viewer.project ? this.props.viewer.project.useTaskFollowers : false,
			language: this.props.viewer.language,
			role_options: role_options,
			people_options: people_options,
			status_options: status_options,
			labels: this.props.viewer.company.labels.edges,
			projectGroupId: this.props.viewer.projectGroup ? this.props.viewer.projectGroup.id : null,
			companyId: this.props.viewer.company.id,
			tasks: this.state.selectedTasks.map(t => t.node.id),
			estimationUnit: this.isConnectedParent
				? this.props.viewer.projectGroup.projects.edges[0].node.estimationUnit
				: this.props.viewer.project.estimationUnit,
			saveCallback: onSuccess,
			isJiraProject: this.isConnectedParent
				? this.props.viewer.projectGroup.projects.edges[0].node.isJiraProject
				: this.props.viewer.project.isJiraProject,
			availableFeatureFlags: this.props.viewer.availableFeatureFlags,
		});
	}

	bulkUpdateDelete() {
		const {formatMessage} = this.props.intl;
		const selectedTasks = this.state.selectedTasks;
		if (this.props.viewer.timerStartDate) {
			for (let task of selectedTasks) {
				if (this.props.viewer.timerTask && task.node.id === this.props.viewer.timerTask.id) {
					this.stopTimer(task.node, true);
					return;
				}
			}
		}
		const callbackPositive = () => {
			const onSuccess = () => {
				this.unselectTasks();
				createToast({duration: 5000, message: formatMessage({id: 'bulk_delete.toast'})});
			};
			Util.CommitMutation(
				DeleteTaskMutation,
				{
					ids: this.state.selectedTasks.map(t => t.node.id),
					projectId: this.props.viewer.project ? this.props.viewer.project.id : null,
					projectIds: this.props.viewer.projectGroup
						? this.props.viewer.projectGroup.projects.edges.map(project => project.node.id)
						: null,
				},
				onSuccess
			);
		};
		showModal({
			type: MODAL_TYPE.GENERIC_DELETION_WARNING,
			deletedItems: this.state.selectedTasks.map(task => task.node),
			deleteCallback: callbackPositive,
		});
	}

	stopTimer(task, preventedDelete) {
		if (hasFeatureFlag('new_time_registration_modal')) {
			showModal({
				type: MODAL_TYPE.TIMER_TIME_REGISTRATION,
				timerActionTaskId: task.id,
				preventedDelete: preventedDelete,
			});
		} else {
			const project = this.props.viewer.project
				? this.props.viewer.project
				: this.props.viewer.projectGroup.projects.edges.find(edge =>
						edge.node.tasks.edges.find(taskEdge => taskEdge.node.id === task.id)
				  ).node;
			showModal({
				type: MODAL_TYPE.TIMER_V3,
				isPreviousTimerReminder: false,
				timerProject: project,
				timerTask: task,
				preventedDelete: preventedDelete,
			});
		}
	}

	setShouldCollapseAll() {
		const map = this.state.sprintsMap;
		// If any phase is expanded: collapse all; otherwise expand all
		const shouldClose = !![...map.values()].find(bool => bool);
		if (this.state.collapseAll !== shouldClose) {
			this.setState({collapseAll: shouldClose});
		}
	}

	toggleSelectionMode(rowId) {
		const curSelection = this.state.selectionMode;
		if (!this.state.selectionMode) {
			tracking.trackPageAction(`Bulk-edit-mode-on`);
			trackEvent('Bulk Edit Mode', 'Activated');
		}
		this.setState({selectedTasks: [], selectionMode: rowId != null ? true : !curSelection}, () =>
			rowId != null ? this.handleRowSelected(rowId, false) : null
		);
	}

	toggleCollapseAll() {
		const map = this.state.sprintsMap;
		map.forEach((v, k) => map.set(k, !this.state.collapseAll));
		this.setState(() => {
			return {sprintsMap: map};
		});
	}

	getIsSprintExpanded(sprintId) {
		const map = this.state.sprintsMap;
		return map.get(sprintId);
	}

	toggleSprintExpanded(sprintId) {
		const map = this.state.sprintsMap;
		const curVal = map.get(sprintId);
		map.set(sprintId, !curVal);
		this.setState({sprintsMap: map});
	}

	toggleSprintDelete() {
		this.setState({reloadBacklog: Date.now()});
	}

	theEyeSelector(selectedElem, _, __, changedOptions) {
		const savedOptionValues = {};
		optionTraverser(changedOptions, (option, path) => {
			savedOptionValues[path] = option.checked;
		});

		// To avoid messing too much with the state management of Scoping, we will continue to store the columns themselves instead of just the eyeOptions object.
		// Eventually, it would likely make sense to align the approach to eye options with the newer pages by using the useEyeOptions hook.
		const cols = eyeOptionsToCols(savedOptionValues, this.state.availableColumns);

		const width = this.getColWidths(cols);
		cols.map(col => (col.width = width));
		this.setState({availableColumns: cols, columnWidths: width});

		const theEye = cols.map(c => {
			return {
				id: c.name,
				checked: c.checked,
			};
		});

		Util.localStorageSetItem(
			(this.isConnectedParent ? 'connected-' : '') + 'project-sprints-selected-cols',
			JSON.stringify(theEye)
		);
		this.updateTaskCellMaxWidth();
	}

	handleTabChange(tab) {
		this.setState({selectedTab: tab});
		// Util.localStorageSetItem('project-sprints-selected-tab', tab.value);
	}

	toggleShowEmptyPersons() {
		this.setState(
			prevState => {
				return {showEmptyPersons: !prevState.showEmptyPersons};
			},
			() => {
				Util.localStorageSetItem('project-sprints-show-empty-persons', this.state.showEmptyPersons);
				tracking.trackElementClicked('Show empty persons', {enabled: this.state.showEmptyPersons});
				trackEvent('Show Empty Persons', this.state.showEmptyPersons ? 'Enabled' : 'Disabled');
			}
		);
	}

	toggleGroupByPerson() {
		this.setState(
			prevState => {
				return {groupByPerson: !prevState.groupByPerson, groupByRole: false, selectedTasks: []};
			},
			() => {
				Util.localStorageSetItem('project-sprints-group-by-person', this.state.groupByPerson);
				Util.localStorageSetItem('project-sprints-group-by-role', false);
				tracking.trackElementClicked('Group by person', {enabled: this.state.groupByPerson});
				trackEvent('Group By Person', this.state.groupByPerson ? 'Enabled' : 'Disabled');
			}
		);
	}

	toggleGroupByRole() {
		this.setState(
			prevState => {
				return {groupByRole: !prevState.groupByRole, groupByPerson: false, selectedTasks: []};
			},
			() => {
				Util.localStorageSetItem('project-sprints-group-by-role', this.state.groupByRole);
				Util.localStorageSetItem('project-sprints-group-by-person', false);
				tracking.trackElementClicked('Group by role', {enabled: this.state.groupByRole});
				trackEvent('Group By Role', this.state.groupByRole ? 'Enabled' : 'Disabled');
			}
		);
	}

	onFilterChange(filters, filterFunctions) {
		this.setState({filters, filterFunctions});
	}

	getHeaderTitleContent() {
		const content = [];

		const onboardingFlows = [
			{
				id: 'sprints-introduction',
				title: 'Introduction to the page',
				description: null,
				contentId: '1681890283pHkk3731',
			},
		];

		const onboardingComponent = {
			id: 'onboarding-component',
			type: TopHeaderBar.TYPE.ONBOARDING,
			title: this.props.intl.formatMessage({id: 'onboarding.sprints_onboarding_title'}),
			options: onboardingFlows,
			helpCenterLink: 'https://support.forecast.app/hc/en-us/sections/4419344459665-Projects',
			subLink: 'https://support.forecast.app/hc/en-us/articles/5624767211409-Using-Sprints-in-Projects-Scrum-Board-',
		};

		content.push(onboardingComponent);

		return content;
	}

	constructHeaderBar() {
		const parentGroup =
			this.props.viewer.project &&
			this.props.viewer.project.projectGroupId &&
			this.props.viewer.projectGroups.edges.find(group => group.node.id === this.props.viewer.project.projectGroupId);

		const projectLocked =
			!this.isConnectedParent &&
			(this.props.viewer.project.status === 'DONE' || this.props.viewer.project.status === 'HALTED');
		const {formatMessage} = this.props.intl;
		const leftContent = [];
		const rightContent = [];

		tabs.forEach(tab => {
			tab.label = formatMessage({id: tab.translationId});
		});
		const pastOrActivePhase = {
			type: ELEMENT_TYPE.LEFT_DROPDOWN,
			dropdownOptions: tabs,
			value: this.state.selectedTab.value,
			dataCy: 'new-ui-scoping-phase-selector-tab',
			listDataCy: 'new-ui-scoping-phase-selector-options',
			userpilot: 'sprint-dropdown',
			callback: this.handleTabChange.bind(this),
		};
		leftContent.push(pastOrActivePhase);

		const eyeAvailableColumns = groupEyeOptions(this.state.availableColumns);

		const theEye = {
			type: ELEMENT_TYPE.THE_EYE,
			options: this.state.availableColumns.length > 0 ? eyeAvailableColumns : null,
			onSelect: this.theEyeSelector.bind(this),
			userpilot: 'eye-selector',
			openRight: true,
		};

		leftContent.push(theEye);

		const csv = {
			type: ELEMENT_TYPE.CSV,
			callback: this.exportCSVNew.bind(this),
			style: BUTTON_STYLE.OUTLINE,
			color: BUTTON_COLOR.LIGHTGREY,
			text: formatMessage({id: 'common.export-csv'}),
			tooltipEnabled: true,
			userpilot: 'csv-button',
			disabled: !this.state.lazyDataFetched,
		};
		leftContent.push(csv);

		if (projectLocked) {
			const indicator = {
				type: ELEMENT_TYPE.INDICATOR,
				status: this.props.viewer.project.status,
			};
			leftContent.push(indicator);
		}

		const search = {
			type: ELEMENT_TYPE.SEARCH_LAZY,
			value: this.state.searchFilterValue,
			onChange: this.handleFilterChange.bind(this, 'search'),
		};

		rightContent.push(search);

		const taskFilters = [
			FILTER_TYPE.PROJECT_PERSON,
			FILTER_TYPE.ROLE,
			FILTER_TYPE.PROJECT_STATUS_COLUMN,
			FILTER_TYPE.CLIENT_GUEST_USERS,
			FILTER_TYPE.DEPENDENCIES,
			FILTER_TYPE.INDICATOR,
			FILTER_TYPE.LABEL,
			FILTER_TYPE.RECENT_ACTIVITY,
			FILTER_TYPE.PROJECT_PHASE,
			FILTER_TYPE.PROJECT_SPRINT,
		];

		if (this.props.viewer.projectGroup) {
			taskFilters.push(FILTER_TYPE.PROJECT);
			taskFilters.push(FILTER_TYPE.PROJECT_STAGE);
		}

		if (this.props.viewer.project && this.props.viewer.project.useTaskFollowers) {
			taskFilters.push(FILTER_TYPE.PROJECT_FOLLOWER);
		}

		if (this.props.viewer.project && this.props.viewer.project.useTaskOwner) {
			taskFilters.push(FILTER_TYPE.PROJECT_OWNER);
		}
		if (this.props.viewer.project && this.props.viewer.project.taskLevels === 2) {
			taskFilters.push(FILTER_TYPE.SUB_TASKS);
		}

		if (this.props.viewer.company.teams.edges.length > 0) {
			taskFilters.push(FILTER_TYPE.TEAM);
		}

		if (
			this.props.viewer.projectGroup
				? this.props.viewer.projectGroup.projects.edges[0].node.estimationUnit === 'HOURS'
				: this.props.viewer.project.estimationUnit === 'HOURS'
		) {
			taskFilters.push(FILTER_TYPE.PREDICTED_OVERRUN);
		}

		const projectFilters = [];
		const peopleFilters = [];
		rightContent.push({
			type: ELEMENT_TYPE.FILTER_V4,
			defaultSection: FILTER_SECTIONS.TASKS,
			projectFilters,
			peopleFilters,
			taskFilters: getFiltersAlphabetically(taskFilters, this.props.intl.formatMessage),
			primaryFilters: {
				[FILTER_SECTIONS.TASKS]: [
					FILTER_TYPE.PROJECT_STATUS_COLUMN,
					FILTER_TYPE.PROJECT_PHASE,
					FILTER_TYPE.PROJECT_PERSON,
				],
			},
			viewer: this.props.viewer,
			appliedFiltersName: `project-sprintV3-filters-v4-${
				this.props.viewer.project ? this.props.viewer.project.id : this.props.viewer.projectGroup.id
			}`,
			filterSection: FILTER_SECTION.SPRINT,
			onFiltersChange: this.onFilterChange.bind(this),
			projectId: this.props.viewer.project ? this.props.viewer.project.id : null,
			projectGroupId: this.props.viewer.projectGroup ? this.props.viewer.projectGroup.id : null,
			companyProjectGroupId: this.props.viewer.projectGroup ? this.props.viewer.projectGroup.companyProjectGroupId : null,
			companyProjectId: this.props.viewer.project ? this.props.viewer.project.companyProjectId : null,
		});

		const newSprintButton = {
			type: ELEMENT_TYPE.BUTTON,
			text: formatMessage({id: 'project_sprints.new-sprint'}),
			callback: this.addSprint.bind(this),
			disabled:
				!this.state.lazyDataFetched ||
				projectLocked ||
				this.isConnectedChild ||
				!hasPermission(PERMISSION_TYPE.SPRINT_CREATE),
			style: BUTTON_STYLE.OUTLINE,
			color: BUTTON_COLOR.PURPLE,
			dataCy: 'new-ui-scoping-custom-button-container',
			userpilot: 'new-sprint-button',
		};
		rightContent.push(newSprintButton);

		if (hasFeatureFlag('zen_mode')) {
			rightContent.push({
				type: ELEMENT_TYPE.ZEN_MODE,
			});
		}

		const collapse = {
			type: ELEMENT_TYPE.COLLAPSE,
			collapsed: !this.state.collapseAll,
			toggleCollapse: this.toggleCollapseAll.bind(this),
		};

		rightContent.push(collapse);

		const actionsMenu = {
			type: ELEMENT_TYPE.ACTIONS_MENU,
			options: [
				{
					key: 'GROUP_BY_PERSONS',
					text: formatMessage({id: 'common.group_by_person'}),
					hotkey: 'Shift + G',
					onClick: this.toggleGroupByPerson.bind(this),
					slider: true,
					checked: this.state.groupByPerson,
				},
				{
					key: 'SHOW_EMPTY_PERSONS',
					text: formatMessage({id: 'common.show_empty_persons'}),
					hotkey: 'Shift + E',
					onClick: this.toggleShowEmptyPersons.bind(this),
					locked: !this.state.groupByPerson,
					slider: true,
					checked: this.state.groupByPerson && this.state.showEmptyPersons,
				},
				{
					key: 'MOVE_BACKLOG',
					text: 'Keep Backlog on Side',
					onClick: this.moveBacklog.bind(this),
					slider: true,
					checked: this.state.sideBacklog,
				},
			],
			whiteInner: true,
			isWhite: true,
			blackIcon: true,
			passedKeyDownHandler: this.handleShortcutProjectSprints.bind(this),
			userpilot: 'actions-menu-top',
		};

		actionsMenu.options.splice(2, 0, {
			key: 'GROUP_BY_ROLE',
			text: formatMessage({id: 'common.group_by_role'}),
			onClick: this.toggleGroupByRole.bind(this),
			slider: true,
			checked: this.state.groupByRole,
		});

		rightContent.push(actionsMenu);

		return (
			<HeaderBar
				innerRef={div => (this.header_bar = div)}
				parentGroup={parentGroup}
				leftContent={leftContent}
				rightContent={rightContent}
			/>
		);
	}

	getTaskObjectFromId(id) {
		let task = null;
		this.allTasks.forEach(t => {
			if (t.node.id === id) {
				task = t;
			}
		});
		return task;
	}

	getTaskAboveInList(placeholderId, sourceList, targetPerson) {
		if (placeholderId.includes('#')) {
			const destinationArr = placeholderId.split('#');
			placeholderId = destinationArr[1];
		}
		let taskId = placeholderId;
		let list = sourceList.sort(this.sortBySortOrder);

		if (this.state.groupByPerson) {
			if (targetPerson === 'UNASSIGNED' || targetPerson === null) {
				list = list.filter(task => task.assignedPersons.length === 0);
			} else {
				list = list.filter(task => task.assignedPersons.find(p => p.id === targetPerson));
			}
		} else if (this.state.groupByRole) {
			if (targetPerson === 'UNASSIGNED' || targetPerson === null) {
				list = list.filter(task => !task.role);
			} else {
				list = list.filter(task => task.role && task.role.id === targetPerson);
			}
		}

		let index = -1;
		if (placeholderId === 'END_OF_LIST') {
			index = list.length - 1;
		} else {
			index = list.findIndex(task => task.id === taskId) - 1;
		}

		if (index >= 0) {
			return list[index];
		} else return null;
	}

	getTasksForSprint(sprintId) {
		if (sprintId === 'no-id') {
			return this.allTasks.filter(task => task.node.sprint === null).map(t => t.node);
		} else {
			if (this.isConnectedParent) {
				return this.allTasks
					.filter(task => task.node.sprint != null && task.node.sprint.projectGroupSprintId === sprintId)
					.map(t => t.node);
			}
			return this.sprintData.find(sprint => sprint.node.id === sprintId).node.tasks;
		}
	}

	registerRowSelection(rowId, shiftKey, tasks) {
		// disable shift select when grouping is active
		// because we sort the task in a way that does not supporl list select when grouped
		if (this.state.groupByPerson || this.state.groupByRole) {
			shiftKey = null;
		}
		this.handleRowSelected(rowId, shiftKey, tasks);
	}

	handleSelectMultipleTasks(ids) {
		const tasks = ids.map(id => {
			return this.getTaskObjectFromId(id);
		});
		const newSelectedTasks = this.state.selectedTasks;
		tasks.forEach(task => {
			const exist = this.state.selectedTasks.some(sTask => sTask.node.id === task.node.id);
			if (!exist) newSelectedTasks.push(task);
		});
		this.setState({selectedTasks: newSelectedTasks});
		this.updateSelectionMode(newSelectedTasks.length);
	}

	handleDeselectMultipleTasks(ids) {
		const newSelectedTasks = this.state.selectedTasks.filter(task => !ids.includes(task.node.id));
		this.setState({selectedTasks: newSelectedTasks});
		this.updateSelectionMode(newSelectedTasks.length);
	}

	updateSelectionMode(selectedTaskCount) {
		this.setState({
			selectionMode: selectedTaskCount > 0,
		});
	}

	handleRowSelected(rowId, shiftKey, tasks) {
		let selectedTasks = [...this.state.selectedTasks];
		let taskId = rowId;
		let targetPerson;

		if (rowId.includes('#')) {
			const rowArr = rowId.split('#');
			targetPerson = rowArr[0];
			taskId = rowArr[1];
		}
		if (rowId.includes('-')) {
			const rowArr = rowId.split('-');
			targetPerson = rowArr[1];
			taskId = rowArr[0];
		}
		const taskToSelect = Object.assign({}, this.getTaskObjectFromId(taskId));
		const isEmptyPhase = taskToSelect.node.sprint == null;
		const lockedTask = taskToSelect.node.project.status === 'HALTED' || taskToSelect.node.project.status === 'DONE';
		if (lockedTask) {
			return;
		}
		let alreadySelected;
		if (this.state.groupByPerson) {
			alreadySelected = !!selectedTasks.find(elem => elem.node.id === taskId && elem.sourcePerson === targetPerson);
		} else {
			alreadySelected = !!selectedTasks.find(elem => elem.node.id === taskId);
		}
		// Always prioritize unselect if already selected
		if (alreadySelected) {
			// Remove selected element
			if (this.state.groupByPerson) {
				selectedTasks.splice(
					selectedTasks.findIndex(elem => elem.node.id === taskId && elem.sourcePerson === targetPerson),
					1
				);
			} else {
				selectedTasks.splice(
					selectedTasks.findIndex(elem => elem.node.id === taskId),
					1
				);
			}
		} else {
			if (this.state.groupByPerson && !isEmptyPhase) {
				if (shiftKey && this.state.selectedTasks.length > 0) {
					const sprintTasks = (tasks ? tasks : this.tasks).filter(
						task =>
							(task.node.sprint &&
								taskToSelect.node.sprint &&
								(this.isConnectedParent
									? task.node.sprint.projectGroupSprintId === taskToSelect.node.sprint.projectGroupSprintId
									: task.node.sprint.id === taskToSelect.node.sprint.id)) ||
							(!task.node.sprint && !taskToSelect.node.sprint)
					);
					const personTasks = sprintTasks
						.filter(task =>
							targetPerson === 'UNASSIGNED'
								? task.node.assignedPersons.length === 0
								: task.node.assignedPersons.find(p => p.id === targetPerson)
						)
						.sort(this.sortBySortOrder)
						.map(t => Object.assign({}, t)); // More deep copies :(
					let tasksToAdd = [];
					const start = selectedTasks[selectedTasks.length - 1];
					const end = taskToSelect;
					// Only multi-select within same person
					if (start && end && start.sourcePerson === targetPerson) {
						const startIndex = personTasks.findIndex(task => task.node.id === start.node.id) || 0;
						const endIndex = personTasks.findIndex(task => task.node.id === end.node.id);
						if (startIndex < endIndex) {
							// +1 to start to avoid adding the already added task
							tasksToAdd = personTasks.slice(startIndex, endIndex + 1);
						} else {
							// Slice always goes from 0..n, so flip direction of going from end to start
							tasksToAdd = personTasks.slice(endIndex, startIndex + 1);
						}
						// Avoid adding elements that were previously added
						tasksToAdd = tasksToAdd.filter(
							task => !selectedTasks.find(t => t.node.id === task.node.id && t.sourcePerson === targetPerson)
						);
						tasksToAdd.forEach(t => (t.sourcePerson = targetPerson));
						selectedTasks = selectedTasks.concat(tasksToAdd);
					}
				}
				// Else add as normally
				else {
					taskToSelect.sourcePerson = targetPerson;
					selectedTasks.push(taskToSelect);
				}
			} else {
				// Do multi select on shift, if a previous task (starting point) was selected
				if (shiftKey && this.state.selectedTasks.length > 0) {
					const isFromDoneProject = task =>
						task.node.project && (task.node.project.status === 'DONE' || task.node.project.status === 'HALTED');
					const tasksFromPHASE = (tasks ? tasks : this.tasks)
						.filter(
							task =>
								(!isFromDoneProject(task) &&
									task.node.sprint &&
									taskToSelect.node.sprint &&
									(this.isConnectedParent
										? task.node.sprint.projectGroupSprintId ===
										  taskToSelect.node.sprint.projectGroupSprintId
										: task.node.sprint.id === taskToSelect.node.sprint.id)) ||
								(!task.node.sprint && !taskToSelect.node.sprint)
						)
						.sort(this.sortBySortOrder);
					let tasksToAdd = [];
					const start = selectedTasks[selectedTasks.length - 1];
					const end = taskToSelect;
					if (start && end) {
						const startIndex = tasksFromPHASE.findIndex(task => task.node.id === start.node.id) || 0;
						const endIndex = tasksFromPHASE.findIndex(task => task.node.id === end.node.id);
						if (startIndex < endIndex) {
							// +1 to start to avoid adding the already added task
							tasksToAdd = tasksFromPHASE.slice(startIndex, endIndex + 1);
						} else {
							// Slice always goes from 0..n, so flip direction of going from end to start
							tasksToAdd = tasksFromPHASE.slice(endIndex, startIndex + 1);
						}
						// Avoid adding elements that were previously added
						tasksToAdd = tasksToAdd.filter(task => !selectedTasks.find(t => t.node.id === task.node.id));
						selectedTasks = selectedTasks.concat(tasksToAdd);
					}
				}
				// Else add as normally
				else selectedTasks.push(taskToSelect);
			}
		}

		if (selectedTasks.length > MAX_BULK_SELECTION) {
			createToast({
				duration: 5000,
				message: this.props.intl.formatMessage({id: 'common.max_bulk_selected'}, {tasks: MAX_BULK_SELECTION}),
			});
		}

		this.setState({
			selectedTasks:
				selectedTasks.length > MAX_BULK_SELECTION ? selectedTasks.slice(0, MAX_BULK_SELECTION) : selectedTasks,
		});
		this.updateSelectionMode(selectedTasks.length);
	}

	getSprintIdFromConnectedSprint(projectGroupSprintId) {
		const targetSprint = this.sprintData.find(sprint => sprint.node.projectGroupSprintId === projectGroupSprintId);
		return targetSprint ? targetSprint.node.id : null;
	}

	handleTaskDrag(draggable, destinationId, placeholderId) {
		let sourcePerson;
		let targetPersonId;
		let targetPerson;
		let targetSprint = destinationId;
		let draggableId = draggable.draggableId;

		if (destinationId && destinationId.includes('#')) {
			const destinationArr = destinationId.split('#');
			targetPersonId = destinationArr[0] === 'UNASSIGNED' ? null : destinationArr[0];
			if (targetPersonId && this.state.groupByPerson) {
				targetPerson = this.state.projectPersons.find(p => p.node.person.id === targetPersonId);
				if (targetPerson === undefined) {
					createToast({
						duration: 5000,
						message: this.props.intl.formatMessage({id: 'common.warning.deactivated_user'}),
					});
					return;
				}
			}
			targetSprint = destinationArr[1];
		}
		if (draggableId && draggableId.includes('#')) {
			const draggableArr = draggable.draggableId.split('#');
			sourcePerson = draggableArr[0] === 'UNASSIGNED' ? null : draggableArr[0];
			draggableId = draggableArr[1];
		}

		const sourceTask = this.getTaskObjectFromId(draggableId);

		if (targetPerson && this.props.viewer.projectGroup && personIsClientUser(targetPerson.node.person)) {
			let projects = [];
			let wrongClient = false;
			if (this.state.selectedTasks.length > 0) {
				this.state.selectedTasks.forEach(task => {
					const taskProject = task.node.project;

					if (!projects.find(p => p.companyProjectId === taskProject.companyProjectId)) {
						projects.push(taskProject);
					}
				});
				projects.forEach(project => {
					const fullProject = this.props.viewer.projectGroup.projects.edges.find(
						p => p.node.companyProjectId === project.companyProjectId
					);
					if (fullProject.node.projectPersons.edges.find(p => p.node.person.id === targetPersonId) === undefined) {
						wrongClient = true;
						return;
					}
				});
			} else {
				const project = this.props.viewer.projectGroup.projects.edges.find(
					p => p.node.companyProjectId === sourceTask.node.project.companyProjectId
				);
				if (
					project &&
					targetPerson &&
					project.node.projectPersons.edges.find(p => p.node.person.id === targetPersonId) === undefined
				) {
					wrongClient = true;
				}
			}

			if (wrongClient) {
				createToast({
					duration: 5000,
					message: this.props.intl.formatMessage({id: 'common.warning.wrong_project_client'}),
				});
				return;
			}
		}
		// Get task above placeholder
		const aboveTask = placeholderId
			? this.getTaskAboveInList(placeholderId, this.getTasksForSprint(targetSprint), targetPersonId)
			: undefined;

		const mutationObject = {};
		let tasksToMove = [];

		if (sourceTask && targetSprint) {
			let ids = [];
			// If we have selected tasks, send these as well
			if (this.state.selectedTasks.length > 0) {
				tasksToMove = this.state.selectedTasks;
				// Purposefully sort before additional moved element may be added
				tasksToMove.sort(this.sortBySortOrder);
				// Was the moved task was not among the selected tasks?
				if (!tasksToMove.find(task => task.node.id === sourceTask.node.id)) {
					// The extra dragged card goes in first, and ends up at the dragged position, everything else comes after in sort order
					tasksToMove.splice(0, 0, sourceTask);
				}
				ids = tasksToMove.map(task => task.node.id);
				if (this.state.groupByPerson) {
					// Since multiselect can select multiple different person-tasks, that are in fact the same tasks, we use this to make a distinct selection
					ids = [...new Set(ids)];
				}
			} else {
				ids = [sourceTask.node.id];
				// For optimistic response
			}

			mutationObject.companyId = this.props.viewer.company.id;
			mutationObject.existingAssignedPersons = sourceTask.node.assignedPersons.map(p => p.id);
			mutationObject.prevStates = this.state.selectedTasks.length > 0 ? this.state.selectedTasks : [sourceTask];
			mutationObject.ids = ids;
			if (this.isConnectedParent) {
				const optimisticSprintId = this.getSprintIdFromConnectedSprint(targetSprint);
				mutationObject.projectGroupSprintId = targetSprint === 'no-id' ? null : targetSprint;
				if (optimisticSprintId != null) {
					mutationObject.optimisticSprintId = optimisticSprintId;
				}
			} else {
				mutationObject.sprintId = targetSprint === 'no-id' ? null : targetSprint;
			}
			if (aboveTask) {
				mutationObject.aboveTaskId = aboveTask.node ? aboveTask.node.id : aboveTask.id;
				mutationObject.optimisticTaskOrder = aboveTask.node
					? aboveTask.node.sortOrder + 0.5
					: aboveTask.sortOrder + 0.5;
			} else {
				mutationObject.aboveTaskId = null;
				mutationObject.optimisticTaskOrder = 0.5;
			}
			// Change assignments if new person
			if (sourcePerson !== targetPersonId) {
				if (this.state.groupByRole) {
					mutationObject.roleId = targetPersonId;
				} else {
					if (targetPersonId != null) {
						// Person to add to all dragged tasks
						mutationObject.assignedPersonToAdd = targetPersonId;
						// Person to remove from all dragged tasks
						if (sourcePerson != null) {
							const unassignPersonsFromTask = ids.map(id => {
								return {
									taskId: id,
									personIds: [sourcePerson],
								};
							});
							mutationObject.unassignPersonFromTask = unassignPersonsFromTask;
						}
					} else if (sourcePerson !== null) {
						let unassignPersonsFromTask;
						// If targetPersonId is null, we want to unassign everyone
						if (tasksToMove.length > 1) {
							unassignPersonsFromTask = tasksToMove.map(task => {
								return {
									taskId: task.node.id,
									personIds: task.node.assignedPersons.map(p => p.id),
								};
							});
						} else {
							unassignPersonsFromTask = ids.map(id => {
								return {
									taskId: id,
									personIds: sourceTask.node.assignedPersons.map(p => p.id),
								};
							});
						}
						mutationObject.unassignPersonFromTask = unassignPersonsFromTask;
					}
				}
			}

			const onSuccess = response => {
				if (
					response &&
					response.updateTask &&
					response.updateTask.errors &&
					response.updateTask.errors.includes('jira_sprint_change_failed')
				) {
					// Jira rejected the sprint change. Show message to user.
					Util.showJiraSprintChangeRejected(this.props.intl);
				}
			};
			Util.CommitMutation(ReorderTaskMutation, mutationObject, onSuccess);
			this.setState({selectedTasks: [], draggingTask: false});
			this.updateSelectionMode(false);
		}
	}

	sortBySortOrder(a, b) {
		if (a.node && b.node) {
			if (a.node.sortOrder < b.node.sortOrder) return -1;
			if (a.node.sortOrder > b.node.sortOrder) return 1;
		} else {
			if (a.sortOrder < b.sortOrder) return -1;
			if (a.sortOrder > b.sortOrder) return 1;
		}

		return 0;
	}

	getContainerWidth() {
		const margin = 66 * 2;
		const sideBacklog = this._side_div ? this._side_div.clientWidth + 12 : 0; // 12px margin on backlog
		const secondaryNavWidth = this.props.sideNavExpanded ? 256 : 44;

		return Math.max(document.documentElement.clientWidth, window.innerWidth) - margin - sideBacklog - secondaryNavWidth;
	}

	getTaskCellMaxWidth() {
		// Sprint header cannot grow smaller than 1150 px
		const w = Math.max(this.getContainerWidth(), 1150);
		// Calculate space for other cols only
		const ignoreCol = col => col.name === 'task-name' || col.name === 'task-id' || col.name === 'selector';
		// Space used by other headers than task name + id
		const usedSpace =
			this.state.availableColumns.reduce(
				(acc, col) => ((col.checked || col.maintainSpace) && !ignoreCol(col) ? col.maxWidth + acc : acc),
				0
			) + 72;
		if (usedSpace > w) {
			// base minwidth for overflow
			return 200;
		}

		return Math.max(200, w - usedSpace);
	}

	getTaskTableWidth() {
		if (this.state.availableColumns) {
			const taskCellWidth = this.getTaskCellMaxWidth();
			const ignoreCol = col => col.name === 'task-name' || col.name === 'task-id' || col.name === 'selector';
			// Space used by other headers than task name + id
			const usedSpace =
				this.state.availableColumns.reduce(
					(acc, col) => ((col.checked || col.maintainSpace) && !ignoreCol(col) ? col.maxWidth + acc : acc),
					0
				) + 52;
			return usedSpace + taskCellWidth;
		} else {
			return 0;
		}
	}

	dataCSV() {
		let csvTasks = this.tasks.map(task => task.node);
		csvTasks = csvTasks.sort((a, b) => {
			const aSprint = a.sprint ? a.sprint : null;
			const bSprint = b.sprint ? b.sprint : null;
			const samePhase = aSprint && bSprint && aSprint.id === bSprint.id;

			// Both are in same phase, compare sort order
			if ((aSprint === null && bSprint === null) || samePhase) {
				return a.sortOrder - b.sortOrder;
			} else if (aSprint == null && bSprint) {
				return 1;
			} else if (aSprint && bSprint == null) {
				return -1;
			}
			// Else sort by phase
			const aStartDate = aSprint.startYear
				? Util.CreateMomentDate(aSprint.startYear, aSprint.startMonth, aSprint.startDay)
				: null;
			const bStartDate = bSprint.startYear
				? Util.CreateMomentDate(bSprint.startYear, bSprint.startMonth, bSprint.startDay)
				: null;

			const sameDate = aStartDate && bStartDate && aStartDate.isSame(bStartDate);
			// Both phases have the same start date, sort by phase id
			if ((aStartDate == null && bStartDate == null) || sameDate) {
				return aSprint.id < bSprint.id ? 1 : -1;
			} else if (aStartDate == null && bStartDate) {
				return 1;
			} else if (aStartDate && bStartDate == null) {
				return -1;
			} else return aStartDate.isBefore(bStartDate) ? -1 : 1;
		});
		return csvTasks;
	}

	exportCSVNew() {
		const taskFormatter = new TaskFormatter(this.props.intl, ['sprint_name'], ['chip-right', 'selector', 'labels'], true);

		const projects = this.props.viewer.project
			? [this.props.viewer.project]
			: this.props.viewer.projectGroup.projects.edges.map(p => p.node);
		const projectName = this.isConnectedParent ? this.props.viewer.projectGroup.name : this.props.viewer.project.name;

		taskFormatter.exportData(
			this.state.availableColumns,
			this.dataCSV(),
			{projects: projects},
			`${projectName}_sprint_data`,
			'CSV'
		);
	}

	closeContextMenu() {
		this.setState({
			showContextMenu: false,
			contextMenuElement: null,
			contextMenuPosition: null,
			contextMenuTask: null,
		});
	}

	onContextMenu(task, e) {
		const el = e.target;
		const projectStatus = this.isConnectedParent ? '' : this.props.viewer.project.status;
		if (projectStatus === 'DONE' || projectStatus === 'HALTED' || !this.state.lazyDataFetched) {
			return;
		}
		if (el.tagName === 'INPUT' || el.tagName === 'BUTTON' || el.tagName === 'A') {
			this.closeContextMenu();
			return;
		}
		e.preventDefault();
		const contextMenuPosition = {};
		contextMenuPosition.x = e.pageX;
		//check if there is place for menu underneath cursor
		if (window.innerHeight - e.pageY < 250) {
			contextMenuPosition.y = e.pageY - 250;
		} else {
			contextMenuPosition.y = e.pageY;
		}
		this.setState({
			showContextMenu: true,
			contextMenuElement: el,
			contextMenuPosition,
			contextMenuTask: task,
		});
	}

	handleDragStart(draggable) {
		this.draggedOrigin = draggable.droppableId;
		this.closeMenus();
		this.setState({draggingTask: true});
	}

	getContextMenu() {
		const {contextMenuTask, contextMenuPosition} = this.state;
		return (
			<GenericTaskContextMenu
				cy="sprint-task-context-menu"
				task={contextMenuTask}
				viewer={this.props.viewer}
				contextMenuPosition={contextMenuPosition}
				closeContextMenu={this.closeContextMenu}
			/>
		);
	}

	closeMenus() {
		if (this.state.showContextMenu) {
			this.setState({showContextMenu: false});
		}
	}

	moveBacklog() {
		this.setState(
			prevState => {
				return {sideBacklog: !prevState.sideBacklog};
			},
			() => {
				Util.localStorageSetItem('backlog-placement', this.state.sideBacklog);
				const action = this.state.sideBacklog ? 'Enabled' : 'Disabled';
				trackEvent('Keep Backlog On Side', action, {});
			}
		);
	}

	moveTasksToNewSprint(currentSprintId) {
		let startDate = Moment().startOf('day');
		let sprintLength = 13;
		if (this.props.viewer.project && this.props.viewer.project.sprintLengthInDays) {
			sprintLength = this.props.viewer.project.sprintLengthInDays - 1;
		} else if (this.props.viewer.projectGroup && this.props.viewer.projectGroup.projects.edges[0].node.sprintLengthInDays) {
			sprintLength = this.props.viewer.projectGroup.projects.edges[0].node.sprintLengthInDays - 1;
		}
		if (sprintLength < 0) {
			sprintLength = 0;
		}
		const endDate = startDate.clone().add(sprintLength, 'day');
		const onSuccess = result => {
			const sprintsOrderMap = new Map();
			const sprintsMap = this.state.sprintsMap;
			const sprint = result.createSprintAndMoveTasks.sprint;
			if (this.props.viewer.project) {
				sprintsOrderMap.set(sprint.node.id, 0);
				sprintsMap.set(sprint.node.id, true);
			} else {
				sprintsOrderMap.set(sprint.node.projectGroupSprintId, 0);
				sprintsMap.set(sprint.node.id, true);
			}
			this.state.sprintsOrderMap.forEach((value, key) => sprintsOrderMap.set(key, value + 1));
			this.setState({focusSprintId: sprint.node.id, sprintsOrderMap: sprintsOrderMap, sprintsMap: sprintsMap});

			createToast({
				duration: 5000,
				message: this.props.intl.formatMessage({id: 'project_sprints.sprint-created'}),
			});
		};
		Util.CommitMutation(
			CreateSprintAndMoveTasksMutation,
			{
				projectId: this.props.viewer.project
					? this.props.viewer.project.id
					: this.props.viewer.projectGroup.projects.edges[0].node.id,
				projectGroupId: this.props.viewer.projectGroup ? this.props.viewer.projectGroup.id : null,
				name:
					this.props.intl.formatMessage({id: 'sprints.sprint'}) +
					' ' +
					((this.props.viewer.project
						? this.props.viewer.project.sprints.edges.length
						: this.props.viewer.projectGroup.projects.edges[0].node.sprints.edges.length) +
						1),
				startDay: startDate.date(),
				startMonth: startDate.month() + 1,
				startYear: startDate.year(),
				endDay: endDate.date(),
				endMonth: endDate.month() + 1,
				endYear: endDate.year(),
				currentSprintId: currentSprintId,
			},
			onSuccess
		);
	}

	moveTasksToSelectedSprint(currentSprintId, selectedSprintId) {
		const targetSprint = this.sprintData.find(
			sprint => sprint.node.id === selectedSprintId || sprint.node.projectGroupSprintId === Number(selectedSprintId)
		);
		const sprintName =
			targetSprint && targetSprint.name ? targetSprint.name : this.props.intl.formatMessage({id: 'common.sprint'});
		const onSuccess = () => {
			createToast({
				duration: 5000,
				message: this.props.intl.formatMessage(
					{id: 'project_sprints.moved_unfinished_tasks_to'},
					{sprintName: sprintName}
				),
			});
			this.calculatePerformance(currentSprintId, false);
		};
		Util.CommitMutation(
			MoveTasksToCurrentSprintMutation,
			{
				currentSprintId: currentSprintId,
				selectedSprintId: selectedSprintId,
				projectId: this.props.viewer.project ? this.props.viewer.project.id : null,
				projectGroupId: this.props.viewer.projectGroup ? this.props.viewer.projectGroup.id : null,
			},
			onSuccess
		);
	}

	setTasksDone(sprintId, columnId) {
		let unfinishedTaskIds = [];
		const sprint = this.sprintData.find(s => s.node.id === sprintId);
		sprint.node.allTasks.forEach(task => {
			if (task.statusColumnV2.category !== WorkflowCategories.DONE) {
				unfinishedTaskIds.push(task.id);
			}
		});

		const mutationObject = {
			ids: unfinishedTaskIds,
			statusColumnCategory: 'DONE',
		};

		if (this.isConnectedParent) {
			mutationObject.projectGroupStatusColumnId = columnId;
		} else {
			mutationObject.statusColumnId = columnId;
		}

		const onSuccess = res => {
			if (
				res.updateTask.errors &&
				res.updateTask.errors.some(error => error.includes('THIS_ACTION_WOULD_RESULT_IN_DEPENDENCY_VIOLATION_FOR'))
			) {
				return showModal({
					type: MODAL_TYPE.GENERIC,
					content: (
						<div>
							<Warning messageId="common.invalid_action_modal_title" />
							<div className="warning-part-2">
								{this.props.intl.formatMessage(
									{
										id: 'dependency.move_task_done_column_warning',
									},
									{sprintName: sprint.node.name}
								)}
							</div>
							<div className="warning-part-2">
								{this.props.intl.formatMessage({
									id: 'dependency.status_change_proceed_warning',
								})}
							</div>
						</div>
					),
					className: 'default-warning-modal',
					buttons: [
						{
							text: this.props.intl.formatMessage({
								id: 'common.filter-close',
							}),
							style: BUTTON_STYLE.FILLED,
							color: BUTTON_COLOR.WHITE,
						},
					],
				});
			} else {
				const targetSprint = this.sprintData.find(
					sprint => sprint.node.id === sprintId || sprint.node.projectGroupSprintId === Number(sprintId)
				);
				const sprintName =
					targetSprint && targetSprint.name
						? targetSprint.name
						: this.props.intl.formatMessage({id: 'common.sprint'});
				createToast({
					duration: 5000,
					message: this.props.intl.formatMessage(
						{id: 'project_sprints.set_sprint_tasks_to_done'},
						{sprintName: sprintName}
					),
				});
				this.calculatePerformance(sprintId, false);
			}
		};

		Util.CommitMutation(UpdateTaskMutation, mutationObject, onSuccess);
	}

	moveTasksToBacklog(sprintId) {
		let unfinishedTaskIds = [];
		const sprints = this.sprintData;
		sprints.forEach(sprint => {
			if (sprint.node.id === sprintId) {
				sprint.node.allTasks.forEach(task => {
					if (
						task.statusColumnV2.category !== WorkflowCategories.DONE &&
						task.project.status !== 'DONE' &&
						task.project.status !== 'HALTED'
					) {
						unfinishedTaskIds.push(task.id);
					}
				});
			}
		});
		const onSuccess = () => {
			createToast({
				duration: 5000,
				message: this.props.intl.formatMessage(
					{id: 'project_sprints.moved_num_tasks'},
					{
						num: unfinishedTaskIds.length,
						target: this.props.intl.formatMessage({id: 'project_sprints.backlog'}),
					}
				),
			});
			this.calculatePerformance(sprintId, false);
		};

		Util.CommitMutation(ReorderTaskMutation, {ids: unfinishedTaskIds, sprintId: null}, onSuccess);
	}

	handleUnfinishedWork(sprintId, option, columnId) {
		let selectedSprintId;
		const today = Moment().startOf('day');
		const sprints = this.props.viewer.project
			? this.props.viewer.project.sprints.edges
			: this.props.viewer.projectGroup.projects.edges[0].node.sprints.edges;
		const currentSprints = sprints.filter(sprint => {
			const startDate = Moment({
				y: sprint.node.startYear,
				M: sprint.node.startMonth - 1,
				d: sprint.node.startDay,
			});
			const endDate = Moment({
				y: sprint.node.endYear,
				M: sprint.node.endMonth - 1,
				d: sprint.node.endDay,
			});
			return !today.isBefore(startDate) && !today.isAfter(endDate);
		});
		if (option === TASKS_OPTIONS.NEXT_SPRINT) {
			if (currentSprints.length !== 0) {
				//has at least one current sprint
				selectedSprintId = currentSprints[0].node.id;

				if (sprintId === selectedSprintId && currentSprints.length > 1) {
					selectedSprintId = currentSprints[1].node.id;
				}

				if (sprintId !== selectedSprintId) {
					this.moveTasksToSelectedSprint(sprintId, selectedSprintId);
				}
			}

			if (sprintId === selectedSprintId || selectedSprintId == null) {
				//check if has any future sprints
				let potentialClosestFutureSprintStart = null;
				let closestSprintId = null;
				//let sprintName = null;
				const sprints = this.props.viewer.project
					? this.props.viewer.project.sprints.edges
					: this.props.viewer.projectGroup.projects.edges[0].node.sprints.edges;
				sprints.forEach(sprint => {
					const startDate = Moment({
						y: sprint.node.startYear,
						M: sprint.node.startMonth - 1,
						d: sprint.node.startDay,
					});
					if (
						startDate.isAfter(today) &&
						(potentialClosestFutureSprintStart === null || potentialClosestFutureSprintStart.isAfter(startDate))
					) {
						closestSprintId = sprint.node.id;
						potentialClosestFutureSprintStart = startDate;
					}
				});

				if (closestSprintId !== null) {
					//move to closest future sprint
					this.moveTasksToSelectedSprint(sprintId, closestSprintId);
				} else {
					//has no future sprints display option to create new one and move tasks to it
					this.moveTasksToNewSprint(sprintId);
				}
			}
		} else if (option === TASKS_OPTIONS.DONE) {
			this.setTasksDone(sprintId, columnId);
		} else if (option === TASKS_OPTIONS.BACKLOG) {
			this.moveTasksToBacklog(sprintId);
		}
		tracking.trackElementClicked('Handle unfinished work', {
			option:
				option === TASKS_OPTIONS.BACKLOG
					? 'Move to backlog'
					: option === TASKS_OPTIONS.DONE
					? 'Set to done'
					: 'Move to next sprint',
		});
		trackEvent('Handle Unfinished Work', 'Clicked');
	}

	updateProject(currentSprintId, performance, newPerformance, option, updatePerformanceClicked) {
		const project = this.props.viewer.project
			? this.props.viewer.project
			: this.props.viewer.projectGroup.projects.edges[0].node;

		let onSuccess;
		if (this.props.viewer.project) {
			Util.CommitMutation(UpdateProjectMutation, {
				project: project,
				minutesPerEstimationPoint: Math.round(newPerformance * 60),
			});
		} else {
			Util.CommitMutation(UpdateProjectGroupMutation, {
				id: this.props.viewer.projectGroup.id,
				minutesPerEstimationPoint: Math.round(newPerformance * 60),
			});
		}

		let mutationObject = {
			id: currentSprintId,
			savedPerformance: Math.round(performance * 60),
		};

		if (this.isConnectedParent) {
			mutationObject.projectGroupId = this.props.viewer.projectGroup.id;
		} else {
			mutationObject.projectId = project.id;
		}
		tracking.trackElementClicked('Update Performance', {
			option:
				newPerformance === OPTIONS.DO_NOT_UPDAT
					? 'Do not update'
					: option === OPTIONS.MANUAL
					? 'manual update'
					: option === OPTIONS.CALCULATED
					? 'calculated update'
					: 'N/A',
			origin: updatePerformanceClicked ? 'update performance button' : 'close sprint modal',
		});

		trackEvent('Update Performance', 'Clicked');

		Util.CommitMutation(UpdateSprintMutation, mutationObject, newPerformance != null && onSuccess);
	}

	/**
	 *
	 * @param {*} sprintId
	 * @param {*} updatePerformanceClicked this is only used for tracking to know if the modal is opened from the update performance button (true) or from the close sprint modal (false)
	 */
	calculatePerformance(sprintId, updatePerformanceClicked) {
		const project = this.props.viewer.project
			? this.props.viewer.project
			: this.props.viewer.projectGroup.projects.edges[0].node;
		if (project.estimationUnit !== 'HOURS') {
			let fourLastDoneSprints = [];
			let currentSprint;
			this.sprintData.forEach(sprint => {
				if (sprint.node.id === sprintId) {
					currentSprint = sprint;
				} else if (sprint.node.isDone && fourLastDoneSprints.length < 4) {
					fourLastDoneSprints.push(sprint);
				}
			});

			showModal({
				type: MODAL_TYPE.UPDATE_SPRINT_PERFORMANCE,
				currentSprint,
				fourLastDoneSprints,
				currentPerformance: project.minutesPerEstimationPoint,
				callback: (currentSprintId, performance, newPerformance, option) =>
					this.updateProject(currentSprintId, performance, newPerformance, option, updatePerformanceClicked),
			});
		}
	}

	getTaskContentMinWidth() {
		const activeCols = this.state.availableColumns.filter(
			col => (col.checked || col.maintainSpace) && col.name !== 'task-name' && col.name !== 'task-id'
		);
		return activeCols.reduce((acc, col) => acc + col.minWidth, 0) + this.state.taskCellMaxWidth + 26 * 2 + 16; // 26 padding on each side, 16 margin
	}

	getIsSprintDragOrigin(id) {
		if (!this.draggedOrigin) {
			return false;
		} else {
			if (this.draggedOrigin.includes('#')) {
				const draggedOriginparts = this.draggedOrigin.split('#');
				if (draggedOriginparts.length > 0) {
					const draggedOrigin = draggedOriginparts[1];
					return draggedOrigin.includes(id);
				}
				return false;
			} else {
				return this.draggedOrigin.includes(id);
			}
		}
	}

	showAutomateModal(variant) {
		const persons = this.state.projectPersons.map(edge => edge.node.person);
		showModal({
			type: MODAL_TYPE.PROJECT_AUTOMATE,
			variant: variant,
			tasks: this.state.selectedTasks.map(task => task.node),
			intl: this.props.intl,
			roles: this.props.viewer.company.roles,
			labels: this.props.viewer.company.labels,
			persons,
		});
	}

	getBulkUpdateOptions() {
		const invoiced = this.state.selectedTasks && this.state.selectedTasks.some(t => t.node.hasInvoicedTime);
		const lockedTimeReg = this.state.selectedTasks && this.state.selectedTasks.some(t => t.node.hasLockedTime);
		const userCantDeleteTask = this.state.selectedTasks && this.state.selectedTasks.some(t => !t.node.userCanDeleteTask);

		const taskSubtreeSelected =
			this.state.selectedTasks &&
			this.state.selectedTasks.some(
				t =>
					t.node.parentTaskId != null &&
					this.state.selectedTasks.filter(t2 => t2.node.id === t.node.parentTaskId).length === 0
			);

		if (!this.isConnectedParent && this.props.viewer.project.isJiraProject) {
			return [
				{
					id: 'bulk update',
					icon: color => <MoreActionsOutline color={color} width={16} height={16} />,
					label: this.props.intl.formatMessage({id: 'project_section.bulk_card_update'}),
					callback: this.bulkUpdateTask.bind(this),
					userpilot: 'bulk-update-button',
					disabled: !this.state.lazyDataFetched,
					cy: 'bulk-update-button',
					variant: BUTTON_VARIANT.VERY_LIGHT_GRAY_OUTLINE,
				},
				{
					id: 'move_tasks',
					label: this.props.intl.formatMessage({id: 'bulk_edit.move_tasks'}),
					icon: color => <ArrowRightOutline color={color} width={16} height={16} />,
					options: [
						{
							label: this.props.intl.formatMessage({id: 'project_section.bulk_move_cards_milesone'}),
							callback: this.bulkUpdateMoveToSprint.bind(this),
						},
					],
					disabled: !this.state.lazyDataFetched,
					userpilot: 'bulk-move-button',
					variant: BUTTON_VARIANT.VERY_LIGHT_GRAY_OUTLINE,
				},
			];
		} else if (!this.isConnectedParent) {
			return [
				{
					id: 'bulk update',
					icon: color => <MoreActionsOutline color={color} width={16} height={16} />,
					label: this.props.intl.formatMessage({id: 'project_section.bulk_card_update'}),
					callback: this.bulkUpdateTask.bind(this),
					userpilot: 'bulk-update-button',
					disabled: !this.state.lazyDataFetched,
					cy: 'bulk-update-button',
					variant: BUTTON_VARIANT.VERY_LIGHT_GRAY_OUTLINE,
				},
				{
					id: 'move_tasks',
					label: this.props.intl.formatMessage({id: 'bulk_edit.move_tasks'}),
					icon: color => <ArrowRightOutline color={color} width={16} height={16} />,
					options: [
						{
							label: this.props.intl.formatMessage({id: 'bulk_update_option.move_to_status'}),
							callback: this.bulkUpdateStatus.bind(this),
						},
						{
							label: this.props.intl.formatMessage({id: 'project_section.bulk_move_cards_sprint'}),
							callback: this.bulkUpdateMoveToSprint.bind(this),
						},
						{
							label: this.props.intl.formatMessage({id: 'project_section.bulk_move_cards_milesone'}),
							callback: this.bulkUpdateMoveToPhase.bind(this),
							disabled: taskSubtreeSelected,
						},
						{
							label: this.props.intl.formatMessage({id: 'task_location_modal.title'}),
							callback: this.bulkUpdateMoveToProject.bind(this),
							disabled: lockedTimeReg || this.props.viewer.project.harvestProjectId,
						},
					],
					userpilot: 'bulk-move-button',
					disabled: !this.state.lazyDataFetched,
					cy: 'bulk-move-button',
					variant: BUTTON_VARIANT.VERY_LIGHT_GRAY_OUTLINE,
				},
				{
					id: 'delete',
					label: this.props.intl.formatMessage({id: 'common.delete'}),
					icon: color => <BinOutline color={color} width={16} height={16} />,
					callback: this.bulkUpdateDelete.bind(this),
					disabled: !this.state.lazyDataFetched || invoiced || lockedTimeReg || userCantDeleteTask,
					userpilot: 'bulk-delete-button',
					cy: 'bulk-delete-button',
					variant: BUTTON_VARIANT.VERY_LIGHT_GRAY_OUTLINE,
				},
			];
		} else {
			return [
				{
					id: 'bulk update',
					icon: color => <MoreActionsOutline color={color} width={16} height={16} />,
					label: this.props.intl.formatMessage({id: 'project_section.bulk_card_update'}),
					callback: this.bulkUpdateTask.bind(this),
					userpilot: 'bulk-update-button',
					disabled: !this.state.lazyDataFetched,
					cy: 'bulk-update-button',
					variant: BUTTON_VARIANT.VERY_LIGHT_GRAY_OUTLINE,
				},
				{
					id: 'move_tasks',
					label: this.props.intl.formatMessage({id: 'bulk_edit.move_tasks'}),
					icon: color => <ArrowRightOutline color={color} width={16} height={16} />,
					options: [
						{
							label: this.props.intl.formatMessage({id: 'bulk_update_option.move_to_status'}),
							callback: this.bulkUpdateStatus.bind(this),
						},
						{
							label: this.props.intl.formatMessage({id: 'project_section.bulk_move_cards_sprint'}),
							callback: this.bulkUpdateMoveToSprint.bind(this),
						},
						{
							label: this.props.intl.formatMessage({id: 'task_location_modal.title'}),
							callback: this.bulkUpdateMoveToProject.bind(this),
							disabled: lockedTimeReg,
						},
					],
					userpilot: 'bulk-move-button',
					disabled: !this.state.lazyDataFetched,
					cy: 'bulk-move-button',
					variant: BUTTON_VARIANT.VERY_LIGHT_GRAY_OUTLINE,
				},
				{
					id: 'delete',
					label: this.props.intl.formatMessage({id: 'common.delete'}),
					icon: color => <BinOutline color={color} width={16} height={16} />,
					callback: this.bulkUpdateDelete.bind(this),
					disabled: !this.state.lazyDataFetched || invoiced || lockedTimeReg || userCantDeleteTask,
					userpilot: 'bulk-delete-button',
					cy: 'bulk-delete-button',
					variant: BUTTON_VARIANT.VERY_LIGHT_GRAY_OUTLINE,
				},
			];
		}
	}

	render() {
		this.isConnectedParent = !!(!this.props.viewer.project && this.props.viewer.projectGroup);
		this.isConnectedChild = !this.isConnectedParent && this.props.viewer.project.isInProjectGroup;
		const projectLocked =
			!this.isConnectedParent &&
			(this.props.viewer.project.status === 'DONE' || this.props.viewer.project.status === 'HALTED');
		const hasFinancialAccess = hasSomePermission([
			PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION,
			PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE,
		]);
		const hasSprintUpdateAccess = hasPermission(PERMISSION_TYPE.SPRINT_UPDATE);
		const currencySymbol = Util.GetCurrencySymbol(
			!this.isConnectedParent && this.props.viewer.project.rateCard
				? this.props.viewer.project.rateCard.currency
				: this.props.viewer.company.currency
		);
		const placeUnitBeforeValue = Util.CurrencyIsPrefixed(currencySymbol);
		const project = this.props.viewer.project
			? this.props.viewer.project
			: this.props.viewer.projectGroup.projects.edges[0].node;
		const hasRateCard = project.rateCard !== null;
		const isEstimatedInNewPoints =
			(this.isConnectedParent
				? this.props.viewer.projectGroup.projects.edges[0].node.estimationUnit
				: this.props.viewer.project.estimationUnit) === 'POINTS';
		const isProjectAllocationOrCombinedMode =
			this.props.viewer.company.isUsingProjectAllocation || this.props.viewer.company.isUsingMixedAllocation;

		// reduce => acc.concat is a sad alternative to flatMap since IE and Edge do not support flatMap. :(
		this.tasks = (
			this.isConnectedParent
				? cloneDeep(this.props.viewer.projectGroup.projects.edges).reduce(
						(acc, project) => acc.concat(project.node.tasks ? project.node.tasks.edges : []),
						[]
				  )
				: cloneDeep(project.tasks.edges)
		).sort(this.sortBySortOrder);

		this.tasks = !this.props.predictionDataMap
			? this.tasks
			: this.tasks.map(t => {
					return {
						node: {
							...t.node,
							predictedEstimate: this.props.predictionDataMap[t.node.id]?.predictedEstimate,
						},
					};
			  });
		const isEstimatedInHours = project.estimationUnit === ESTIMATION_UNIT.HOURS;

		if (this.state.filterFunctions && this.state.filterFunctions.taskFilter) {
			const options = {
				isProjectGroup: this.props.viewer.projectGroup !== null && this.props.viewer.projectGroup !== undefined,
				teams: this.props.viewer.company.teams.edges,
			};
			this.tasks = this.tasks.filter(task => this.state.filterFunctions.taskFilter(task.node, options));
		}

		const locale = Util.GetLocaleFromPersonLanguage(this.props.viewer.language);
		if (this.state.searchFilterValue.length > 0) {
			const searchFilter = task => {
				const taskValue = 'T' + task.node.companyTaskId + task.node.name;
				const searchValue = this.state.searchFilterValue.trim();
				return Util.normalizedIncludes(taskValue, searchValue);
			};
			this.tasks = this.tasks.filter(searchFilter);
		}

		let sprints = [];
		const dateInPast = (y, m, d) => {
			const today = Moment();
			const dateAsMoment = Util.CreateNonUtcMomentDate(y, m, d);
			return dateAsMoment && today.isSameOrAfter(dateAsMoment, 'd');
		};
		const sprintData = this.isConnectedParent
			? cloneDeep(this.props.viewer.projectGroup.projects?.edges[0].node.sprints.edges).filter(
					sprint => sprint.node.isProjectGroupSprint
			  )
			: cloneDeep(this.props.viewer.project.sprints.edges);

		let allTasks = this.isConnectedParent
			? this.props.viewer.projectGroup.projects?.edges.reduce((acc, project) => acc.concat(project.node.tasks.edges), [])
			: project.tasks.edges;

		this.sprintData = sprintData;
		this.allTasks = allTasks;

		sprints = sprintData
			.sort((a, b) => this.compareSprintDates(a, b))
			.map(sprint => {
				const group_sprint = sprint.node;
				if (this.isConnectedParent) {
					group_sprint.tasks = this.tasks
						.filter(
							task =>
								task.node.sprint && task.node.sprint.projectGroupSprintId === group_sprint.projectGroupSprintId
						)
						.filter((v, i, a) => a.findIndex(v2 => v2.node.id === v.node.id) === i)
						.map(task => task.node);
				} else {
					group_sprint.tasks = this.tasks
						.filter(task => task.node.sprint && task.node.sprint.id === group_sprint.id)
						.filter((v, i, a) => a.findIndex(v2 => v2.node.id === v.node.id) === i)
						.map(task => task.node);
				}
				if (this.isConnectedParent) {
					group_sprint.allTasks = allTasks
						.filter(
							task =>
								task.node.sprint && task.node.sprint.projectGroupSprintId === group_sprint.projectGroupSprintId
						)
						.map(task => task.node);
				} else {
					group_sprint.allTasks = allTasks
						.filter(task => task.node.sprint && task.node.sprint.id === group_sprint.id)
						.map(task => task.node);
				}
				//group_sprint.allTasks = allTasks.filter(task => task.node.sprint && task.node.sprint.id === group_sprint.id).map(task => task.node);
				group_sprint.tasks = group_sprint.tasks.sort((a, b) => a.sortOrder - b.sortOrder);

				// Sprint is done if we can find no tasks where the task isn't done, AND the sprint is in the past
				group_sprint.isDone =
					group_sprint.tasks.length > 0
						? dateInPast(group_sprint.endYear, group_sprint.endMonth, group_sprint.endDay) &&
						  group_sprint.tasks.filter(task => task.statusColumnV2.category !== WorkflowCategories.DONE).length ===
								0
						: !group_sprint.hasUnfinishedTask &&
						  dateInPast(group_sprint.endYear, group_sprint.endMonth, group_sprint.endDay);

				//Sprint totals
				let forecastUnfiltered = 0;
				let doneForecastUnfiltered = 0;
				let priceUnfiltered = 0;
				let currentPriceUnfiltered = 0;
				let timeEntriesUnfiltered = 0;
				let totalRemainingUnfiltered = 0;
				const currentSprintValues = {
					doneTasksEstimation: 0,
					doneTasksTimeRegistered: 0,
					performance: 0,
				};
				group_sprint.allTasks.forEach(tmpTask => {
					forecastUnfiltered += tmpTask.estimateForecast != null ? tmpTask.estimateForecast : 0;
					if (tmpTask.statusColumnV2.category === WorkflowCategories.DONE) {
						doneForecastUnfiltered += tmpTask.estimateForecast != null ? tmpTask.estimateForecast : 0;
					}
					priceUnfiltered += tmpTask.estimateForecastPrice != null ? tmpTask.estimateForecastPrice : 0;
					currentPriceUnfiltered += tmpTask.currentPrice != null ? tmpTask.currentPrice : 0;
					timeEntriesUnfiltered +=
						tmpTask.timeRegistrations != null
							? tmpTask.timeRegistrations.edges.reduce(
									(totalTime, tmpTimeReg) => totalTime + tmpTimeReg.node.minutesRegistered,
									0
							  )
							: 0;
					totalRemainingUnfiltered += tmpTask.timeLeft != null ? tmpTask.timeLeft : 0;

					if (isEstimatedInNewPoints && tmpTask.statusColumnV2.category === 'DONE') {
						currentSprintValues.doneTasksEstimation += tmpTask.estimateForecast;
						currentSprintValues.doneTasksTimeRegistered += tmpTask.timeRegistrations
							? tmpTask.timeRegistrations.edges.reduce((acc, timeReg) => acc + timeReg.node.minutesRegistered, 0)
							: 0;
						//duplicated task does not return taskregistrations
					}
				});
				group_sprint.forecastUnfiltered = forecastUnfiltered;
				group_sprint.doneForecastUnfiltered = doneForecastUnfiltered;
				group_sprint.priceUnfiltered = priceUnfiltered;
				group_sprint.currentPriceUnfiltered = currentPriceUnfiltered;
				group_sprint.timeEntriesUnfiltered = timeEntriesUnfiltered;
				group_sprint.totalRemainingUnfiltered = totalRemainingUnfiltered;

				if (isEstimatedInNewPoints) {
					currentSprintValues.doneTasksEstimation *= 60;
					group_sprint.performance =
						currentSprintValues.doneTasksEstimation > 0
							? Math.round(
									(currentSprintValues.doneTasksTimeRegistered / currentSprintValues.doneTasksEstimation) * 60
							  )
							: 0;
				}

				// Hide sprint if sprint filter is applied and sprint does not match
				group_sprint.hide =
					this.state.filters &&
					this.state.filters.task &&
					this.state.filters.task.projectSprint &&
					this.state.filters.task.projectSprint.length !== 0 &&
					!this.state.filters.task.projectSprint.includes(
						!this.props.viewer.projectGroup ? group_sprint.id : group_sprint.projectGroupSprintId
					);

				//phase done calculation
				const calcTasks = group_sprint.allTasks;

				const approvedPhaseForecast = calcTasks.reduce((total, tmpTask) => total + tmpTask.estimateForecast, 0);
				const approvedPhaseRemaining = calcTasks.reduce((total, tmpTask) => total + tmpTask.timeLeft, 0);
				const numberOfDoneTasks = calcTasks.filter(
					task => task.statusColumnV2.category === WorkflowCategories.DONE
				).length;

				group_sprint.reportedNotDone = calcTasks
					.filter(task => task.statusColumnV2.category !== WorkflowCategories.DONE)
					.reduce(
						(total, tmpTask) =>
							total +
							(tmpTask.timeRegistrations != null
								? tmpTask.timeRegistrations.edges.reduce(
										(totalTime, tmpTimeReg) => totalTime + tmpTimeReg.node.minutesRegistered,
										0
								  )
								: 0),
						0
					);
				if (!isEstimatedInHours) {
					group_sprint.reportedNotDone /= project.minutesPerEstimationPoint;
				}

				let forecast = 0;
				let price = 0;
				let currentPrice = 0;
				let timeEntries = 0;
				let totalRemaining = 0;
				group_sprint.tasks.forEach(tmpTask => {
					forecast += tmpTask.estimateForecast != null ? tmpTask.estimateForecast : 0;
					price += tmpTask.estimateForecastPrice != null ? tmpTask.estimateForecastPrice : 0;
					currentPrice += tmpTask.currentPrice != null ? tmpTask.currentPrice : 0;
					timeEntries +=
						tmpTask.timeRegistrations != null
							? tmpTask.timeRegistrations.edges.reduce(
									(totalTime, tmpTimeReg) => totalTime + tmpTimeReg.node.minutesRegistered,
									0
							  )
							: 0;
					totalRemaining += tmpTask.timeLeft != null ? tmpTask.timeLeft : 0;
				});
				group_sprint.forecast = forecast;
				group_sprint.price = price;
				group_sprint.currentPrice = currentPrice;
				group_sprint.timeEntries = timeEntries;
				group_sprint.totalRemaining = totalRemaining;
				group_sprint.totalForecastDiff = isEstimatedInHours
					? group_sprint.forecast - group_sprint.timeEntries
					: group_sprint.forecast * project.minutesPerEstimationPoint - group_sprint.timeEntries;

				group_sprint.progress = Util.calculateElementProgress(
					approvedPhaseForecast,
					calcTasks.reduce(
						(total, tmpTask) =>
							total +
							(tmpTask.timeRegistrations != null
								? tmpTask.timeRegistrations.edges.reduce(
										(totalTime, tmpTimeReg) => totalTime + tmpTimeReg.node.minutesRegistered,
										0
								  )
								: 0),
						0
					),
					approvedPhaseRemaining,
					calcTasks.length,
					numberOfDoneTasks,
					isEstimatedInHours,
					project.minutesPerEstimationPoint
				);

				return group_sprint;
			});
		const backlog = {
			id: null,
		};
		backlog.tasks = this.tasks
			.filter(task => {
				return task.node.sprint === null;
			})
			.map(task => task.node);

		backlog.allTasks = project.tasks.edges
			.filter(task => {
				return task.node.sprint === null;
			})
			.map(task => task.node);

		//empty phase totals
		backlog.tasks = backlog.tasks.sort((a, b) => a.sortOrder - b.sortOrder);

		// Hide backlog if sprint filter is applied and backlog (null) does not match
		backlog.hide =
			this.state.filters &&
			this.state.filters.task &&
			this.state.filters.task.projectSprint &&
			this.state.filters.task.projectSprint.length !== 0 &&
			!this.state.filters.task.projectSprint.includes(null);

		const isEmpty = allTasks.length === 0 && sprintData.length === 0;
		const anySelectFiltersUsed =
			this.state.phaseFilterValue.length ||
			this.state.recentlyUpdatedFilterValue.length ||
			this.state.teamFilterValue.length ||
			this.state.teammemberFilterValue.length ||
			this.state.statusFilterValue.length ||
			this.state.labelFilterValue.length ||
			this.state.sprintFilterValue.length ||
			this.state.roleFilterValue.length ||
			this.state.blocked_bugFilterValue.length;
		const searchUsed = this.state.searchFilterValue.length;

		switch (this.state.selectedTab.value) {
			case 'done':
				sprints = sprints.filter(sprint => sprint.isDone);
				break;
			case 'active':
				sprints = sprints.filter(sprint => !sprint.isDone);
				break;
			case 'all':
			default:
				break;
		}
		const totalTableMinWidth = this.state.taskTableWidth; //this.state.sideBacklog ? this.state.containerWidth - (66 * 2 + sideDivWidth + 12) : this.state.containerWidth - 66 * 2;
		const sprintWrapperWidth = this.state.taskTableWidth + (this.state.sideBacklog ? 66 + 16 + 12 : 66 * 2 + 16);

		const roles = this.props.viewer.project
			? filterRolesByRatecardDisabledRoles(this.props.viewer.company.roles.edges, this.props.viewer.project.rateCard)
			: this.props.viewer.company.roles.edges;

		const taskRowMinWidth = this.getTaskContentMinWidth();

		const contextHeight = this.dnd_context ? this.dnd_context.outerScrollBar.getClientHeight() : 0;
		const sortedSprints = this.isConnectedParent
			? sprints
					.filter(sprint => sprint.isProjectGroupSprint)
					.map(sprint => ({value: sprint.projectGroupSprintId, label: sprint.name}))
			: sprints.map(sprint => ({value: sprint.id, label: sprint.name}));

		this.visibleSprints = sprints;

		const emptyPhaseHeader = (
			<SprintsSprintHeader
				minWidth={taskRowMinWidth}
				cy={'phase-header'}
				tableMinWidth={totalTableMinWidth}
				handleUnfinishedWork={this.handleUnfinishedWork.bind(this)}
				minutesPerEstimationPoint={project.minutesPerEstimationPoint}
				projectLocked={projectLocked}
				isConnectedParent={this.isConnectedParent}
				isConnectedChild={this.isConnectedChild}
				taskCellMaxWidth={this.state.taskCellMaxWidth}
				sprint={backlog}
				projectId={project.id}
				projectColor={
					this.isConnectedParent ? this.props.viewer.projectGroup.color : this.props.viewer.project.projectColor
				}
				emptyPhase={true}
				isEstimatedInHours={isEstimatedInHours}
				hasRateCard={hasRateCard}
				currencySymbol={currencySymbol}
				placeUnitBeforeValue={placeUnitBeforeValue}
				hasFinancialAccess={hasFinancialAccess}
				availableColumns={this.state.availableColumns}
				onExpansionChange={this.toggleSprintExpanded.bind(this)}
				expanded={this.getIsSprintExpanded(backlog.id)}
				viewer={this.props.viewer}
				isNewSprint={this.state.focusSprintId === backlog.id}
				scrollToNewPhase={this.scrollToNewPhase}
				moveBacklog={this.moveBacklog.bind(this)}
				useManualAllocations={isProjectAllocationOrCombinedMode}
				lazyDataFetched={this.state.lazyDataFetched}
				onSprintDelete={this.toggleSprintDelete.bind(this)}
			/>
		);

		const backlogWrapperVars = this.props.viewer.project
			? {
					projectId: '' + this.props.viewer.project?.id,
					fetchLazyData: true,
					personId: this.props.viewer.actualPersonId,
			  }
			: {
					groupId: '' + this.props.viewer.projectGroup?.companyProjectGroupId,
					fetchLazyData: true,
					personId: this.props.viewer.actualPersonId,
			  };

		const LoadingBacklogElem = () =>
			!this.state.sideBacklog ? (
				<SprintsSprint
					onSelectAllTasks={this.handleSelectMultipleTasks}
					onDeselectAllTasks={this.handleDeselectMultipleTasks}
					draggingTask={this.state.draggingTask}
					showSprintNumbers={true}
					minutesPerEstimationPoint={project.minutesPerEstimationPoint}
					projectPersons={this.state.projectPersons}
					sprintPersons={[]}
					showEmptyPersons={this.state.showEmptyPersons}
					groupByPerson={false}
					groupByRole={false}
					tableMinWidth={totalTableMinWidth}
					cy={''}
					isConnectedParent={this.isConnectedParent}
					isConnectedChild={this.isConnectedChild}
					phaseHeader={emptyPhaseHeader}
					selectionMode={this.state.selectionMode}
					containerWidth={this.state.containerWidth}
					contextHeight={contextHeight}
					dragAndDropGroup={this.state.dragAndDropGroup}
					showTaskModal={taskId => Util.showTaskModal(taskId, this.props.history)}
					projectLocked={projectLocked}
					isJiraProject={!this.isConnectedParent && this.props.viewer.project.isJiraProject}
					sprint={backlog}
					sortedSprints={sortedSprints}
					project={project}
					projectId={project.id}
					isBacklog={true}
					emptyPhase={true}
					roles={roles}
					isEstimatedInHours={isEstimatedInHours}
					isEstimatedInNewPoints={
						(this.isConnectedParent
							? this.props.viewer.projectGroup.projects.edges[0].node.estimationUnit
							: this.props.viewer.project.estimationUnit) === 'POINTS'
					}
					hasRateCard={hasRateCard}
					currencySymbol={currencySymbol}
					placeUnitBeforeValue={placeUnitBeforeValue}
					hasFinancialAccess={hasFinancialAccess}
					language={this.props.viewer.language}
					//calculateTablesSize={this.calculateTablesSize.bind(this)}
					projectTasks={allTasks}
					viewer={this.props.viewer}
					availableColumns={this.state.availableColumns}
					handleRowSelected={this.registerRowSelection}
					selectedRows={this.state.selectedTasks}
					taskCellMaxWidth={this.state.taskCellMaxWidth}
					columnWidths={this.state.columnWidths}
					useManualAllocations={isProjectAllocationOrCombinedMode}
					//showTaskLocationModal={this.props.route.showTaskLocationModal}
					unselectTasks={this.unselectTasks.bind(this)}
					lazyDataFetched={this.state.lazyDataFetched}
					lowHighModelScore={this.props.lowHighModelScore}
				/>
			) : (
				<SideBacklog
					innerRef={ref => (this._side_div = ref)}
					tableMinWidth={totalTableMinWidth}
					cy={''}
					isConnectedParent={this.isConnectedParent}
					isConnectedChild={this.isConnectedChild}
					onExpansionChange={this.updateContainerWidth}
					phase={backlog}
					phaseHeader={emptyPhaseHeader}
					selectionMode={this.state.selectionMode}
					containerWidth={this.state.containerWidth}
					dragAndDropGroup={this.state.dragAndDropGroup}
					showTaskModal={taskId => Util.showTaskModal(taskId, this.props.history)}
					projectLocked={projectLocked}
					isJiraProject={!this.isConnectedParent && this.props.viewer.project.isJiraProject}
					tasks={[]}
					project={project}
					projectId={project.id}
					emptyPhase={true}
					roles={roles}
					isEstimatedInHours={isEstimatedInHours}
					hasRateCard={hasRateCard}
					currencySymbol={currencySymbol}
					placeUnitBeforeValue={placeUnitBeforeValue}
					hasFinancialAccess={hasFinancialAccess}
					language={this.props.viewer.language}
					projectTasks={allTasks}
					viewer={this.props.viewer}
					availableColumns={this.state.availableColumns}
					handleRowSelected={this.registerRowSelection}
					selectedRows={this.state.selectedTasks}
					onContextMenu={this.onContextMenu}
					moveBacklog={this.moveBacklog.bind(this)}
					draggingTask={this.state.draggingTask}
					lazyDataFetched={this.state.lazyDataFetched}
					buyNowTime={this.props.buyNowTime}
				/>
			);

		const backlogWrapper = (
			<ForecastQueryRenderer
				key={'query-render-projectSprintBacklog'}
				query={ProjectSprintBacklogWrapperQuery}
				variables={backlogWrapperVars}
				customLoader={() => <LoadingBacklogElem />}
				render={(relayProps, retry) => (
					<ProjectSprintBacklogWrapper
						{...relayProps}
						retry={retry}
						innerRef={ref => (this._side_div = ref)}
						cy={''}
						onExpansionChange={this.updateContainerWidth}
						phase={backlog}
						onContextMenu={this.onContextMenu}
						moveBacklog={this.moveBacklog.bind(this)}
						buyNowTime={this.props.buyNowTime}
						onSelectAllTasks={this.handleSelectMultipleTasks}
						onDeselectAllTasks={this.handleDeselectMultipleTasks}
						draggingTask={this.state.draggingTask}
						minutesPerEstimationPoint={project.minutesPerEstimationPoint}
						projectPersons={this.state.projectPersons}
						showEmptyPersons={this.state.showEmptyPersons}
						tableMinWidth={totalTableMinWidth}
						isConnectedParent={this.isConnectedParent}
						isConnectedChild={this.isConnectedChild}
						phaseHeader={emptyPhaseHeader}
						selectionMode={this.state.selectionMode}
						containerWidth={this.state.containerWidth}
						contextHeight={contextHeight}
						dragAndDropGroup={this.state.dragAndDropGroup}
						showTaskModal={taskId => Util.showTaskModal(taskId, this.props.history)}
						projectLocked={projectLocked}
						isJiraProject={!this.isConnectedParent && this.props.viewer.project.isJiraProject}
						sprint={backlog}
						sortedSprints={sortedSprints}
						project={project}
						projectId={project.id}
						roles={roles}
						isEstimatedInHours={isEstimatedInHours}
						isEstimatedInNewPoints={
							(this.isConnectedParent
								? this.props.viewer.projectGroup.projects.edges[0].node.estimationUnit
								: this.props.viewer.project.estimationUnit) === 'POINTS'
						}
						hasRateCard={hasRateCard}
						currencySymbol={currencySymbol}
						placeUnitBeforeValue={placeUnitBeforeValue}
						hasFinancialAccess={hasFinancialAccess}
						language={this.props.viewer.language}
						projectTasks={allTasks}
						availableColumns={this.state.availableColumns}
						handleRowSelected={this.registerRowSelection}
						selectedRows={this.state.selectedTasks}
						taskCellMaxWidth={this.state.taskCellMaxWidth}
						columnWidths={this.state.columnWidths}
						useManualAllocations={isProjectAllocationOrCombinedMode}
						unselectTasks={this.unselectTasks.bind(this)}
						lazyDataFetched={this.state.lazyDataFetched}
						lowHighModelScore={this.props.lowHighModelScore}
						mainViewer={this.props.viewer}
						sideBacklog={this.state.sideBacklog}
						socketData={this.state.socketData}
						addTasks={tasks => (this.allTasks = [...this.allTasks, ...tasks])}
						updateContainerWidth={this.updateContainerWidth}
						filters={this.state.filters}
						filterFunctions={this.state.filterFunctions}
						reloadBacklog={this.state.reloadBacklog}
						backlogWrapperVars={backlogWrapperVars}
					/>
				)}
			/>
		);

		this.allTasks = this.allTasks.filter((v, i, a) => a.findIndex(v2 => v2.node.id === v.node.id) === i);

		const sideBackLogWidth = this._side_div ? this._side_div.clientWidth : 234;
		const windowWidth = window.innerWidth;
		const marginWithSideBacklog = 66;
		let contextWidth = this.state.sideBacklog ? windowWidth - sideBackLogWidth - marginWithSideBacklog : windowWidth;
		if (this.props.sideNavLocked) {
			contextWidth -= 256;
		} else {
			contextWidth -= 44;
		}

		const taskCount = this.state.selectedTasks.length;
		return (
			<div
				className={
					'project-sprints-v2' +
					(!projectLocked ? ' active' : '') +
					(isEmpty ? ' empty' : '') +
					(this.state.showHingeAnimation ? ' animated hinge' : '')
				}
				onAnimationEnd={this.undoCoolStuff.bind(this)}
				data-cy={'sprints-page'}
			>
				{taskCount > 0 && (
					<BulkSelectPopup
						itemCount={taskCount}
						counterText={this.props.intl.formatMessage({id: 'bulk_edit.tasks_selected'})}
						actionOptions={this.getBulkUpdateOptions()}
						onClose={this.unselectTasks.bind(this)}
						complexOptions={getComplexBulkOptions(
							this.showAutomateModal.bind(this),
							getAdditionalBulkUpdateAutomateOptions(
								this.state.selectedTasks.map(t => t.node),
								selected => this.setState({selectedTasks: selected}),
								this.props.predictionDataMap,
								project,
								PERMISSION_TYPE.SPRINT_UPDATE,
								this.props.viewer.availableFeatureFlags,
								this.props.intl
							),
							this.props.intl
						)}
					/>
				)}
				{this.state.showContextMenu ? this.getContextMenu() : null}
				{this.props.children}
				<ProjectHeader
					title={this.props.intl.formatMessage({id: 'project_section.sprints'})}
					titleContent={this.getHeaderTitleContent()}
					buttons={this.constructHeaderBar()}
					project={this.props.viewer.project}
					psProject={this.props.viewer.psProject}
					projectGroup={this.props.viewer.projectGroup}
				/>
				{this.tasks.length === 0 && (searchUsed || anySelectFiltersUsed) && !isEmpty ? (
					<InsightsEmptyState
						infoMessageId={
							searchUsed && !anySelectFiltersUsed ? 'scheduling.no-match' : 'scheduling.no-match-generic'
						}
						searchText={this.state.searchFilterValue}
					/>
				) : isEmpty ? (
					<EmptyState
						pageName={this.isConnectedChild ? EMPTY_STATE.SPRINTS_CHILDREN : EMPTY_STATE.SPRINTS}
						callback={this.isConnectedChild ? this.goToParent.bind(this) : null}
					/>
				) : (
					<div
						ref={div => (this.section_body = div)}
						className={'section-body flow-sample-scoping-phase' + (this.state.sideBacklog ? ' flex-row' : '')}
					>
						{this.state.sideBacklog ? <div className="backlog-wrapper">{backlogWrapper}</div> : null}
						<div className={'context-sizer'} style={{minWidth: contextWidth, maxWidth: contextWidth}}>
							{this.state.sideBacklog && sprints.length === 0 ? (
								<EmptyState
									pageName={this.isConnectedChild ? EMPTY_STATE.SPRINTS_CHILDREN : EMPTY_STATE.SPRINTS}
									callback={this.isConnectedChild ? this.goToParent.bind(this) : null}
								/>
							) : (
								<DragDropContext
									ref={div => (this.dnd_context = div)}
									scrollYSpeed={25}
									scrollContainerHeight={
										this.state.contextHeight || window.innerHeight - 134 - (this.props.buyNowTime ? 40 : 8)
									}
									scrollContainerMinHeight={
										this.state.contextHeight || window.innerHeight - 134 - (this.props.buyNowTime ? 40 : 8)
									}
									dragAndDropGroup={this.state.dragAndDropGroup}
									onDragEnd={this.handleTaskDrag.bind(this)}
									onDragStart={this.handleDragStart.bind(this)}
									outerScrollBar={true}
								>
									{sprints.map((sprint, index) => {
										const projectGroupSprint = this.props.viewer.projectGroup
											? this.props.viewer.projectGroup.projectGroupSprints.edges.find(
													el => el.node.id === sprint.projectGroupSprintId
											  )
											: null;
										let hasDifferentPerformance;
										this.notDoneTasksUnfiltered = sprint.allTasks.filter(
											task =>
												task.project &&
												task.project.status !== PROJECT_STATUS.HALTED &&
												task.project.status !== PROJECT_STATUS.DONE &&
												task.statusColumnV2 &&
												task.statusColumnV2.category !== WorkflowCategories.DONE
										);
										const notDoneTasksUnfilteredNumber = this.notDoneTasksUnfiltered.length;
										const empty = sprint.id == null;
										const sprintEndDate = empty
											? null
											: Util.CreateNonUtcMomentDate(sprint.endYear, sprint.endMonth, sprint.endDay);
										const showUnfinishedWorkInfo =
											!empty &&
											sprintEndDate.isSameOrBefore(Moment(), 'd') &&
											notDoneTasksUnfilteredNumber !== 0 &&
											hasSprintUpdateAccess &&
											!this.props.projectLocked;
										hasDifferentPerformance =
											!isEstimatedInHours && sprint.performance !== (sprint.savedPerformance || 0);
										const showCloseSprintButton =
											!this.isConnectedChild &&
											((hasDifferentPerformance && sprint.isDone) || showUnfinishedWorkInfo) &&
											hasSprintUpdateAccess;

										const phaseHeader = (
											<SprintsSprintHeader
												cy={'phase-header'}
												minWidth={taskRowMinWidth}
												handleUnfinishedWork={this.handleUnfinishedWork.bind(this)}
												minutesPerEstimationPoint={project.minutesPerEstimationPoint}
												projectId={project.id}
												taskCellMaxWidth={this.state.taskCellMaxWidth}
												isConnectedParent={this.isConnectedParent}
												isConnectedChild={this.isConnectedChild}
												projectColor={
													this.isConnectedParent
														? this.props.viewer.projectGroup.color
														: this.props.viewer.project.projectColor
												}
												projectLocked={projectLocked}
												sprintIndex={index}
												sprint={sprint}
												projectGroupSprint={projectGroupSprint}
												project={project}
												company={this.props.viewer.company}
												emptyPhase={false}
												isEstimatedInHours={isEstimatedInHours}
												hasRateCard={hasRateCard}
												currencySymbol={currencySymbol}
												placeUnitBeforeValue={placeUnitBeforeValue}
												hasFinancialAccess={hasFinancialAccess}
												availableColumns={this.state.availableColumns}
												onExpansionChange={this.toggleSprintExpanded.bind(this)}
												expanded={this.getIsSprintExpanded(sprint.id)}
												isNewSprint={this.state.focusSprintId === sprint.id}
												scrollToNewPhase={this.scrollToNewPhase}
												useManualAllocations={isProjectAllocationOrCombinedMode}
												viewer={this.props.viewer}
												calculatePerformance={() => this.calculatePerformance(sprint.id, true)}
												differentPerformance={hasDifferentPerformance}
												isEstimatedInNewPoints={isEstimatedInNewPoints}
												showCloseSprintButton={showCloseSprintButton}
												lazyDataFetched={this.state.lazyDataFetched}
												onSprintDelete={this.toggleSprintDelete.bind(this)}
											/>
										);
										return (
											<div
												key={sprint.id}
												id={'sprint-wrapper-' + sprint.id}
												data-cy="new-ui-scoping-sprint-wrapper"
												className={'sprint-wrapper' + (sprint.hide ? ' no-margin' : '')}
												style={{
													minWidth: sprintWrapperWidth,
												}}
											>
												{sprint.hide ? null : (
													<VisibilitySensor
														scrollCheck={true}
														partialVisibility
														offset={{top: -100, bottom: -300}}
													>
														{({isVisible}) => {
															const isCollapsed = !this.getIsSprintExpanded(sprint.id);
															return (
																<SprintsSprint
																	onSelectAllTasks={this.handleSelectMultipleTasks}
																	onDeselectAllTasks={this.handleDeselectMultipleTasks}
																	draggingTask={this.state.draggingTask}
																	minutesPerEstimationPoint={
																		project.minutesPerEstimationPoint
																	}
																	sprintPersons={
																		projectGroupSprint?.node?.sprintPersons
																			? projectGroupSprint.node.sprintPersons.edges
																			: sprint.sprintPersons
																			? sprint.sprintPersons.edges
																			: []
																	}
																	showSprintNumbers={true}
																	projectPersons={this.state.projectPersons}
																	showEmptyPersons={this.state.showEmptyPersons}
																	groupByPerson={this.state.groupByPerson}
																	groupByRole={this.state.groupByRole}
																	tableMinWidth={totalTableMinWidth}
																	isConnectedParent={this.isConnectedParent}
																	isConnectedChild={this.isConnectedChild}
																	cy={'phase'}
																	collapsed={isCollapsed}
																	forceHidden={
																		!isCollapsed &&
																		!isVisible &&
																		!this.getIsSprintDragOrigin(
																			this.isConnectedParent
																				? sprint.projectGroupSprintId
																				: sprint.id
																		)
																	}
																	phaseHeader={phaseHeader}
																	selectionMode={this.state.selectionMode}
																	containerWidth={this.state.containerWidth}
																	contextHeight={contextHeight}
																	sprintIndex={index}
																	showTaskModal={taskId =>
																		Util.showTaskModal(taskId, this.props.history)
																	}
																	projectLocked={projectLocked}
																	isJiraProject={
																		!this.isConnectedParent &&
																		this.props.viewer.project.isJiraProject
																	}
																	sprint={sprint}
																	sortedSprints={sortedSprints}
																	project={project}
																	projectId={project.id}
																	isBacklog={false}
																	focusAddControls={this.state.focusSprintId === sprint.id}
																	roles={roles}
																	isEstimatedInHours={isEstimatedInHours}
																	isEstimatedInNewPoints={isEstimatedInNewPoints}
																	locale={locale}
																	hasRateCard={hasRateCard}
																	currencySymbol={currencySymbol}
																	placeUnitBeforeValue={placeUnitBeforeValue}
																	hasFinancialAccess={hasFinancialAccess}
																	language={this.props.viewer.language}
																	projectTasks={allTasks}
																	viewer={this.props.viewer}
																	availableColumns={this.state.availableColumns}
																	dragAndDropGroup={this.state.dragAndDropGroup}
																	handleRowSelected={this.registerRowSelection.bind(this)}
																	selectedRows={this.state.selectedTasks}
																	taskCellMaxWidth={this.state.taskCellMaxWidth}
																	columnWidths={this.state.columnWidths}
																	sideBacklog={this.state.sideBacklog}
																	useManualAllocations={isProjectAllocationOrCombinedMode}
																	filters={this.state.filters}
																	unselectTasks={this.unselectTasks.bind(this)}
																	showCloseSprintButton={showCloseSprintButton}
																	lazyDataFetched={this.state.lazyDataFetched}
																	lowHighModelScore={this.props.lowHighModelScore}
																/>
															);
														}}
													</VisibilitySensor>
												)}
											</div>
										);
									})}
									{!this.state.sideBacklog && !backlog.hide ? (
										<div className="sprint-wrapper" style={{minWidth: totalTableMinWidth}}>
											{backlogWrapper}
										</div>
									) : null}
								</DragDropContext>
							)}
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default recentProjectsHOC(injectIntl(withRouter(projectSprintsV4)));
