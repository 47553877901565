import React from 'react';
import { Text, IconTooltip } from '@forecast-it/design-system';
import { getGranularity } from '../../../util/cache/TimeRegistrationSettingsUtil';
import { getGranularityInfoLabel, Granularity } from '../../../../admin-tab/time-management/constants/Granularity';
function TimeRegGranularityInfo({ granularity }) {
    const granularityLabel = getGranularityInfoLabel(granularity);
    return (React.createElement("div", null,
        React.createElement(Text, { size: '2' }, granularityLabel),
        React.createElement(Text, { size: '2', color: 'medium' }, "This is a company-wide setting configured by your admin")));
}
export function TimeRegGranularityInfoIcon() {
    const granularity = getGranularity();
    if (granularity === Granularity.Minutes)
        return null;
    return (React.createElement(IconTooltip, { icon: 'info', color: 'disabled', iconSize: 's', width: 'l' },
        React.createElement(TimeRegGranularityInfo, { granularity: granularity })));
}
