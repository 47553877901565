import React, {useEffect, useState} from 'react';
import {Button, CaretIcon, PipedriveIcon} from 'web-components';
import {FakeHeaderContainer, GoBackContainer, GoBackText} from '../salesforce/app_salesforce_style';
import CustomScrollDiv from '../../../shared/components/scroll-bars/custom_scroll_div';
import {FormattedMessage, useIntl} from 'react-intl';
import {graphql} from 'graphql';
import {withRouter} from 'react-router-dom';
import {createFragmentContainer} from 'react-relay';
import {ButtonsWrapper, DescriptionWrapper, IconAndDescriptionWrapper} from '../hubspot/app_hubspot_style';
import {
	MainSectionWrapper,
	PageWrapper,
	SubSection,
} from '../../../../containers/project/project_settings/ProjectSettings_styled';
import Util from '../../../shared/util/util';
import CreatePipedriveVerificationKeyMutation from '../../../../mutations/CreatePipedriveVerificationKeyMutation';
import PipedriveStagesTable from './PipedriveStagesTable';
import ForecastQueryRenderer from '../../../../ForecastQueryRenderer';
import GenericProjectTemplatesTable, {genericProjectTemplatesTableQuery} from '../shared/GenericProjectTemplatesTable';
import {MODAL_TYPE, showModal} from '../../../shared/components/modals/generic_modal_conductor';
import Warning from '../../../../components/warning';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../../constants';
import DisablePipedriveMutation from '../../../../mutations/DisablePipedriveMutation';
import InlineLoader from '../../../shared/components/inline-loader/inline_loader';
import PipedriveActivityTypesSection from './PipedriveActivityTypesSection';

const AppPipedrive = ({history, viewer}) => {
	const intl = useIntl();

	const [deactivating, setDeactivating] = useState(false);

	const openHelpCenter = () => {
		window.open('https://support.forecast.app/hc/en-us/articles/5236867751953');
	};

	const goBackToIntegrationsPage = () => {
		history.push('/admin/integrations');
	};

	const activatePipedrive = () => {
		const clientId = process.env.PIPEDRIVE_APP_CLIENT_ID;
		const redirectUri = `${process.env.API_URL}/pipedrive/oauth`;

		Util.CommitMutation(CreatePipedriveVerificationKeyMutation, null, ({createPipedriveVerificationKey}) =>
			window.open(
				`https://oauth.pipedrive.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&state=${createPipedriveVerificationKey.key}`,
				'_self'
			)
		);
	};

	useEffect(() => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const installPipedrive = urlSearchParams.get('install');
		if (installPipedrive) {
			activatePipedrive();
		}
	}, []);

	const deactivateIntegration = () => {
		const {formatMessage} = intl;

		const callbackPositive = params => {
			setDeactivating(true);
			Util.CommitMutation(DisablePipedriveMutation, null, () => setDeactivating(false));
		};

		let info = 'settings.app_catalog.pipedrive.disable_message_warning';

		showModal({
			type: MODAL_TYPE.GENERIC,
			content: (
				<div>
					<Warning messageId={info} />
					<div className="warning-part-2">{formatMessage({id: 'common.warning.this_action_can_not_be_undone'})}</div>
				</div>
			),
			className: 'default-warning-modal',
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.delete'}),
					callback: callbackPositive,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
				},
			],
		});
	};

	const pipedriveEnabled = viewer.company.pipedriveCompanyDomain;

	const actionButton = pipedriveEnabled ? (
		<Button variant={Button.VARIANT.RED_OUTLINE} onClick={deactivateIntegration}>
			<FormattedMessage id="common.deactivate" />
		</Button>
	) : (
		<Button variant={Button.VARIANT.PURPLE_OUTLINE} onClick={activatePipedrive}>
			<FormattedMessage id="common.activate" />
		</Button>
	);
	return (
		<PageWrapper>
			<MainSectionWrapper width={1250}>
				<FakeHeaderContainer onClick={goBackToIntegrationsPage}>
					<GoBackContainer>
						<CaretIcon size={CaretIcon.SIZE.STANDARD} color={'#535353'} direction={CaretIcon.DIRECTION.LEFT} />
						<GoBackText>
							{intl.formatMessage({id: 'app_salesforce.salesforce_configuration.go_back_to_integrations'})}
						</GoBackText>
					</GoBackContainer>
				</FakeHeaderContainer>

				<CustomScrollDiv>
					<SubSection>
						<IconAndDescriptionWrapper>
							<PipedriveIcon size={PipedriveIcon.SIZE.EXTRA_BIG} />
							<DescriptionWrapper>
								<FormattedMessage id="settings.app_catalog.pipedrive.description" />
							</DescriptionWrapper>
						</IconAndDescriptionWrapper>

						<ButtonsWrapper>
							{actionButton}
							<Button variant={Button.VARIANT.STANDARD} onClick={openHelpCenter}>
								<FormattedMessage id="settings.app_catalog.help_button" />
							</Button>
						</ButtonsWrapper>
					</SubSection>

					{pipedriveEnabled && !deactivating && (
						<>
							<SubSection
								title={intl.formatMessage({id: 'settings.app_catalog.common.deal_stages'})}
								description={
									<FormattedMessage
										id="settings.app_catalog.pipedrive.deal_description"
										values={{
											a: msg => (
												<a
													href="https://support.forecast.app/hc/en-us/articles/5236867751953"
													target="_blank"
													rel="noopener noreferrer"
												>
													{msg}
												</a>
											),
										}}
									/>
								}
							>
								<PipedriveStagesTable />
							</SubSection>

							<SubSection
								title={'Activity types'}
								description={<FormattedMessage id="settings.app_catalog.pipedrive.activity_type_description" />}
							>
								<PipedriveActivityTypesSection />
							</SubSection>

							<SubSection
								title={intl.formatMessage({id: 'settings.app_catalog.common.project_template'})}
								description={<FormattedMessage id="settings.app_catalog.common.project_template_description" />}
							>
								<ForecastQueryRenderer
									key="query-render-GenericProjectTemplatesTable"
									query={genericProjectTemplatesTableQuery}
									render={relayProps => {
										return (
											<GenericProjectTemplatesTable
												{...relayProps}
												integrationName="pipedrive"
												refNumberTooltipDescription="settings.app_catalog.pipedrive.ref_no_tooltip"
											/>
										);
									}}
								/>
							</SubSection>
						</>
					)}

					{deactivating && (
						<InlineLoader
							loaderText={intl.formatMessage({id: 'settings.app_catalog.common.deactivating_integration'})}
						/>
					)}
				</CustomScrollDiv>
			</MainSectionWrapper>
		</PageWrapper>
	);
};

const appPipedriveQuery = graphql`
	query appPipedrive_Query {
		viewer {
			actualPersonId
			component(name: "app_pipedrive")
			...appPipedrive_viewer
		}
	}
`;

export {appPipedriveQuery};

export default withRouter(
	createFragmentContainer(AppPipedrive, {
		viewer: graphql`
			fragment appPipedrive_viewer on Viewer {
				id
				availableFeatureFlags {
					key
				}
				company {
					id
					pipedriveCompanyDomain
				}
			}
		`,
	})
);
