import Util from '../util/util';
import ValidatePrefixMutation from '../../../mutations/project-service/validate_prefix';
import GenerateUniquePrefixMutation from '../../../mutations/project-service/generate_unique_prefix';

const usePrefix = () => {
	const validateProgramPrefix = (prefix, onSuccess) => {
		Util.CommitMutation(ValidatePrefixMutation, {prefix: prefix, domain: 'PROGRAM'}, res =>
			onSuccess(res.validatePrefix.valid === 'true')
		);
	};

	const generateProgramPrefix = (name, onSuccess) => {
		if (name) {
			Util.CommitMutation(
				GenerateUniquePrefixMutation,
				{
					name: name,
					domain: 'PROGRAM',
				},
				res => onSuccess(res.generateUniquePrefix.prefix)
			);
		}
	};

	const validateProjectPrefix = (prefix, onSuccess) => {
		Util.CommitMutation(ValidatePrefixMutation, {prefix: prefix, domain: 'PROJECT'}, res =>
			onSuccess(res.validatePrefix.valid === 'true')
		);
	};

	const generateProjectPrefix = (name, onSuccess) => {
		if (name) {
			Util.CommitMutation(
				GenerateUniquePrefixMutation,
				{
					name: name,
					domain: 'PROJECT',
				},
				res => onSuccess(res.generateUniquePrefix.prefix)
			);
		}
	};

	const formatter = value => value.replace(/[^a-zA-Z0-9]/, '').toUpperCase();

	return {
		validateProgramPrefix,
		generateProgramPrefix,
		validateProjectPrefix,
		generateProjectPrefix,
		formatter,
	};
};

export default usePrefix;
