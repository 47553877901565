import {PERMISSION_TYPE} from '../../../Permissions';

const SHOW_TIME_ENTRIES = {
	name: 'Hide time entries',
	description: 'Guest users will not be able to see time registered on tasks',
	permissions: [PERMISSION_TYPE.CLIENT_HIDE_TIME_ENTRIES],
};

const SHOW_ESTIMATES = {
	name: 'Hide estimates',
	description: 'Guest users will not be able to see or edit task estimates',
	permissions: [PERMISSION_TYPE.CLIENT_HIDE_ESTIMATES],
};

const SHOW_ALL_TASKS = {
	name: 'Show all tasks in assigned projects',
	description: 'Guest users will be able to see all tasks for projects they are assigned to',
	permissions: [PERMISSION_TYPE.CLIENT_SEE_ALL_TASKS],
};

const RESTRICTED_TASK_ACTIONS = {
	name: 'Limit guest user actions',
	description:
		'Guest users can only create tasks, edit task name, edit task description, add comments and attach files to tasks',
	permissions: [PERMISSION_TYPE.CLIENT_RESTRICTED_TASK_ACTIONS],
};

const RESTRICTED_TASK_VIEW = {
	name: 'Hide assignee and role',
	description: 'Assignee and role fields will show up empty for the guest user unless they are the ones assigned to the task',
	permissions: [PERMISSION_TYPE.CLIENT_RESTRICTED_TASK_VIEW],
};

export const CLIENT_PERMISSION_GROUPS = [SHOW_TIME_ENTRIES, SHOW_ESTIMATES, SHOW_ALL_TASKS];

export const CLIENT_PERMISSIONS_WITH_GUEST_USER = [
	SHOW_ALL_TASKS,
	SHOW_TIME_ENTRIES,
	SHOW_ESTIMATES,
	RESTRICTED_TASK_VIEW,
	RESTRICTED_TASK_ACTIONS,
];
