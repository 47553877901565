import {useEffect, useState} from 'react';
import {graphql} from 'react-relay';
import {fetchQuery} from 'relay-runtime';
import environment from '../../../../RelayEnvironment';
import hash from 'object-hash';

export const useProjectCalculatedFields = projectIds => {
	const chunkSize = 50;

	const [calculatedFieldsData, setCalculatedFieldsData] = useState([]);

	const addCalculatedFieldsData = data => {
		setCalculatedFieldsData(prevState => [...prevState, ...data]);
	};

	const calculateProjectFieldsQuery = graphql`
		query useProjectCalculatedFieldsHookQuery($projectIds: [ID]) {
			viewer {
				component(name: "useProjectCalculatedFields")
				actualPersonId
				company {
					id
					name
					projectCalculatedFields(first: 1000, projectIds: $projectIds) {
						edges {
							node {
								projectId
								progress {
									progressType
									value
								}
							}
						}
					}
				}
			}
		}
	`;

	const asyncFetch = async () => {
		for (let i = 0; i < projectIds.length; i += chunkSize) {
			const projectIdsChunk = projectIds.slice(i, i + chunkSize);
			await fetchQuery(environment.getInstance(), calculateProjectFieldsQuery, {projectIds: projectIdsChunk}).then(
				data => {
					return addCalculatedFieldsData(
						data.viewer.company.projectCalculatedFields.edges.map(projectData => projectData.node)
					);
				}
			);
		}
	};

	useEffect(() => {
		// noinspection JSIgnoredPromiseFromCall
		asyncFetch();
	}, [hash(projectIds)]);

	/**
	 * This will return the calculated fields for a project
	 *
	 * @param projectId The project ID
	 * @returns {{progress: *}} Calculated Fields Available
	 */
	const projectCalcData = projectId => {
		let projectData = calculatedFieldsData?.find(project => project.projectId === projectId);
		return {
			progress: projectData ? Math.round(projectData.progress.value * 100) : 0, // In percent
		};
	};

	const isConnectedProject = project => project.__typename === 'ProjectGroupType';
	const getProgress = project => {
		if (isConnectedProject(project)) {
			// If connected project use avg of all project that belongs to it.
			const progress =
				project.children
					.map(project => projectCalcData(project.id))
					.map(calcData => calcData.progress)
					.reduce((a, b) => a + b, 0) / project.children.length;

			return isNaN(progress) ? 0 : Math.ceil(progress);
		} else {
			return projectCalcData(project.id)?.progress;
		}
	};

	return {
		projectCalcData,
		getProgress,
	};
};
