import React from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import GenericSavedReportConductor from './GenericSavedReportConductor';
import {Redirect} from 'react-router-dom/cjs/react-router-dom';

const CompanySavedReportWrapper = ({viewer}) => {
	const savedReport = viewer.company.savedReport;

	if (!savedReport || !savedReport.type) return <Redirect to="/not-found" />;

	return <GenericSavedReportConductor savedReport={savedReport} />;
};

const CompanySavedReportWrapperQuery = graphql`
	query CompanySavedReportWrapperQuery_Query($savedReportId: String) {
		viewer {
			actualPersonId
			component(name: "company_saved_report_wrapper")
			...CompanySavedReportWrapper_viewer @arguments(savedReportId: $savedReportId)
		}
	}
`;

export {CompanySavedReportWrapperQuery};

export default injectIntl(
	withRouter(
		createFragmentContainer(CompanySavedReportWrapper, {
			viewer: graphql`
				fragment CompanySavedReportWrapper_viewer on Viewer @argumentDefinitions(savedReportId: {type: "String"}) {
					actualPersonId
					company {
						savedReport(id: $savedReportId) {
							...GenericSavedReportConductor_savedReport
							id
							type
							name
							reportService
						}
					}
				}
			`,
		})
	)
);
