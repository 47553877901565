import { differenceInCalendarDays, format, max, min } from 'date-fns';
import { isOutsideProjectDatesAllowed, isOutsideTaskDatesAllowed } from '../../cache/TimeRegistrationSettingsUtil';
function isOutsideDateRange({ date, startDate, endDate, ignoredDates = [] }) {
    if (ignoredDates.length > 0) {
        if (ignoredDates.some(ignoredDate => differenceInCalendarDays(date, ignoredDate) === 0)) {
            return false;
        }
    }
    if (startDate && endDate) {
        return differenceInCalendarDays(date, startDate) < 0 || differenceInCalendarDays(date, endDate) > 0;
    }
    if (startDate) {
        return differenceInCalendarDays(date, startDate) < 0;
    }
    if (endDate) {
        return differenceInCalendarDays(date, endDate) > 0;
    }
    return false;
}
function isTaskDateInvalid(dateInvalidInput) {
    if (isOutsideTaskDatesAllowed()) {
        return false;
    }
    return isOutsideDateRange(dateInvalidInput);
}
function isProjectDateInvalid(dateInvalidInput) {
    if (isOutsideProjectDatesAllowed()) {
        return false;
    }
    return isOutsideDateRange(dateInvalidInput);
}
export function isTimeRegistrationDateInvalid({ date, projectStartDate, projectEndDate, taskStartDate, taskEndDate, ignoredDates, }) {
    return (isTaskDateInvalid({ date, startDate: taskStartDate, endDate: taskEndDate, ignoredDates }) ||
        isProjectDateInvalid({ date, startDate: projectStartDate, endDate: projectEndDate, ignoredDates }));
}
function getDateInvalidReasonString({ entityType, startDate, endDate }) {
    const startDateString = startDate ? format(startDate, 'P') : '';
    const endDateString = endDate ? format(endDate, 'P') : '';
    return `Time registration must be within ${entityType} dates: ${startDateString} - ${endDateString}`;
}
export function isTimeRegistrationDateInvalidWithReason({ date, projectStartDate, projectEndDate, taskStartDate, taskEndDate, ignoredDates, }) {
    if (isTaskDateInvalid({ date, startDate: taskStartDate, endDate: taskEndDate, ignoredDates })) {
        return getDateInvalidReasonString({ entityType: 'task', startDate: taskStartDate, endDate: taskEndDate });
    }
    if (isProjectDateInvalid({ date, startDate: projectStartDate, endDate: projectEndDate, ignoredDates })) {
        return getDateInvalidReasonString({ entityType: 'project', startDate: projectStartDate, endDate: projectEndDate });
    }
    return null;
}
export function getTimeRegistrationDateRestrictionInfoMessage({ projectStartDate, projectEndDate, taskStartDate, taskEndDate, }) {
    if (!isOutsideTaskDatesAllowed() &&
        !isOutsideProjectDatesAllowed() &&
        (taskStartDate || taskEndDate || projectStartDate || projectEndDate)) {
        const startDate = taskStartDate && projectStartDate ? max([taskStartDate, projectStartDate]) : taskStartDate || projectStartDate;
        const endDate = taskEndDate && projectEndDate ? min([taskEndDate, projectEndDate]) : taskEndDate || projectEndDate;
        return getDateInvalidReasonString({ entityType: 'task and project', startDate: startDate, endDate: endDate });
    }
    if (!isOutsideTaskDatesAllowed() && (taskStartDate || taskEndDate)) {
        return getDateInvalidReasonString({ entityType: 'task', startDate: taskStartDate, endDate: taskEndDate });
    }
    if (!isOutsideProjectDatesAllowed() && (projectStartDate || projectEndDate)) {
        return getDateInvalidReasonString({ entityType: 'project', startDate: projectStartDate, endDate: projectEndDate });
    }
    return null;
}
