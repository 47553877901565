export const RoundingEntities = {
    RATE: { name: 'RATE', decimals: 4 },
};
export default class RoundingUtility {
    static roundToNDecimals(value, decimalAmount) {
        return Math.round((value + Number.EPSILON) * Math.pow(10, decimalAmount)) / Math.pow(10, decimalAmount);
    }
    static roundBasedOnEntityType(value, entityType) {
        const entity = RoundingEntities[entityType];
        const decimalAmount = entity ? entity.decimals : 2;
        return this.roundToNDecimals(value, decimalAmount);
    }
}
