import React from 'react';
import {CalculationLoader, HexagonText, Table} from 'web-components';
import Util from '../../../../shared/util/util';
import {getQueryRenderer} from '../TimeRegistrationReportPageUtil';
import {GLOBAL_FILTER_FIELD, GLOBAL_FILTER_OPERATOR} from '../../../../../constants';
import {profilePicSrc} from '../../../../../directApi';

export const getPersonRow = (
	data,
	intl,
	groupIndex,
	searchQuery,
	groupings,
	enabledColumns,
	hasNextPage,
	fetchMore,
	nextLevelProps,
	currency,
	reportId,
	sharedId,
	customFieldColumnCount,
	customFieldKeys
) => {
	const getColumns = person => {
		const formattedPrice = intl.formatNumber(person.timeRegTotals?.totalPrice, {format: 'always_two_decimal'});
		const formattedCost = intl.formatNumber(person.timeRegTotals?.totalCost, {format: 'always_two_decimal'});
		const fullName = person.firstName ? person.firstName + (person.lastName ? ` ${person.lastName}` : '') : '';
		const columns = [
			<Table.Column>
				<HexagonText
					intl={intl}
					active={person.active}
					text={fullName}
					imgUrl={profilePicSrc(person.profilePictureId)}
				/>
			</Table.Column>,
			<Table.Column />,
			<Table.Column />,
			<Table.Column />,
			<Table.Column>{Util.convertMinutesToFullHour(person.timeRegTotals?.totalTimeRegistered, intl, true)}</Table.Column>,
			<Table.Column>
				{Util.convertMinutesToFullHour(person.timeRegTotals?.totalBillableRegistered, intl, true)}
			</Table.Column>,
			<Table.Column>
				{Util.convertMinutesToFullHour(person.timeRegTotals?.totalNonBillableRegistered, intl, true)}
			</Table.Column>,
			<Table.Column />,
			<Table.Column />,
			<Table.Column />,
			<Table.Column />,
			<Table.Column />,
			<Table.Column />,
			<Table.Column />,
			<Table.Column>
				<span>
					{formattedPrice !== null ? (
						Util.GetFormattedCurrencySymbol(currency, formattedPrice)
					) : (
						<CalculationLoader />
					)}
				</span>
			</Table.Column>,
			<Table.Column>
				<span>
					{formattedCost !== null ? Util.GetFormattedCurrencySymbol(currency, formattedCost) : <CalculationLoader />}
				</span>
			</Table.Column>,
			<Table.Column />,
			<Table.Column />,
		];

		for (let i = 0; i < customFieldColumnCount; i++) {
			columns.push(<Table.Column />);
		}

		return columns;
	};

	return (
		<Table.Rows
			data={data}
			canExpand={true}
			autoload={hasNextPage && groupIndex === 1}
			loadMore={hasNextPage ? fetchMore : false}
			{...nextLevelProps}
		>
			{({rowData, tableColumnsProps, nextLevelProps, expanded}) => {
				return (
					<>
						<Table.Row {...tableColumnsProps}>{getColumns(rowData, enabledColumns)}</Table.Row>
						{expanded &&
							getQueryRenderer(
								null,
								groupings,
								searchQuery,
								groupIndex,
								enabledColumns,
								{
									field: GLOBAL_FILTER_FIELD.PERSON_TIME_REG,
									operator: GLOBAL_FILTER_OPERATOR.IS,
									value: [rowData.personId],
								},
								nextLevelProps,
								currency,
								reportId,
								sharedId,
								customFieldColumnCount,
								customFieldKeys
							)}
					</>
				);
			}}
		</Table.Rows>
	);
};
