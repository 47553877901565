/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectGroupIndicator_projectGroup",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "companyProjectGroupId",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "color",
            "storageKey": null
        }
    ],
    "type": "ProjectGroupType"
};
node.hash = 'ce28c265c139a23c9a4727cea25732ab';
export default node;
