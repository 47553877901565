import React, {useState} from 'react';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {createRefetchContainer, graphql} from 'react-relay';
import TimeRegBaseLevelWrapper from './TimeRegBaseLevelWrapper';
import {getTaskRow} from '../rows/TaskRow';
import {useGenericReportContext} from '../../../saved-report/GenericReportContext';
import {idFromGlobalId} from '../../../../shared/util/GlobalIdUtil';

const TimeRegTaskRelayWrapper = ({
	relay,
	tableHeader,
	groupings,
	searchQuery,
	viewer,
	intl,
	groupIndex,
	enabledColumns,
	nextLevelProps,
	currency,
	customFieldColumnCount,
	customFieldKeys,
	pageSize,
}) => {
	const {reportId, sharedId} = useGenericReportContext();

	const tasks = viewer.reportData.tasks.edges?.filter(task => task.node.timeRegTotals.totalTimeRegistered > 0);
	const hasNextPage = viewer.reportData.tasks.pageInfo.hasNextPage && tasks?.length >= pageSize;
	const [modalTasksFetching, setModalTasksFetching] = useState(false);

	const dataRows = tasks.map(task => {
		const noAccessTask = idFromGlobalId(task.node.taskId) === -1;
		return {
			...task.node,
			hasAccessToTask: true,
			canExpand: !noAccessTask,
		};
	});

	const data = {rows: dataRows};

	const fetchMore = () => {
		if (!modalTasksFetching) {
			const refetchObject = {};
			setModalTasksFetching(true);
			refetchObject.pageSize = tasks.length + pageSize;
			relay.refetch(refetchObject, null, () => setModalTasksFetching(false));
		}
	};

	const taskRows = () => {
		return getTaskRow(
			data,
			intl,
			groupIndex,
			searchQuery,
			groupings,
			enabledColumns,
			hasNextPage,
			fetchMore,
			nextLevelProps,
			currency,
			reportId,
			sharedId,
			customFieldColumnCount,
			customFieldKeys
		);
	};

	return groupIndex === 1 ? (
		<TimeRegBaseLevelWrapper
			intl={intl}
			enabledColumns={enabledColumns}
			tableHeader={tableHeader}
			data={data}
			getRows={taskRows}
			currentLength={tasks.length}
			hasNextPage={viewer.reportData.tasks.pageInfo.hasNextPage}
			refetch={relay.refetch}
			customFieldColumnCount={customFieldColumnCount}
			pageSize={pageSize}
		/>
	) : (
		taskRows()
	);
};

const TimeRegTaskRelayWrapperQuery = graphql`
	query TimeRegTaskRelayWrapper_Query(
		$searchQuery: TaskSearchQueryType
		$pageSize: Int
		$reportId: String
		$sharedId: String
	) {
		viewer {
			actualPersonId
			component(name: "time_reg_task_relay_wrapper_v2")
			...TimeRegTaskRelayWrapper_viewer
				@arguments(searchQuery: $searchQuery, pageSize: $pageSize, reportId: $reportId, sharedId: $sharedId)
		}
	}
`;
export {TimeRegTaskRelayWrapperQuery};

export default injectIntl(
	withRouter(
		createRefetchContainer(
			TimeRegTaskRelayWrapper,
			{
				viewer: graphql`
					fragment TimeRegTaskRelayWrapper_viewer on Viewer
					@argumentDefinitions(
						searchQuery: {type: "TaskSearchQueryType"}
						pageSize: {type: "Int"}
						reportId: {type: "String"}
						sharedId: {type: "String"}
					) {
						reportData(id: $reportId, sharedId: $sharedId) {
							tasks(first: $pageSize, searchQuery: $searchQuery)
								@connection(key: "TimeRegReport_tasks", filters: ["searchQuery"]) {
								pageInfo {
									endCursor
									hasNextPage
								}
								edges {
									node {
										taskId
										name
										noTask
										timeRegTotals {
											totalCost
											totalPrice
											totalTimeRegistered
											totalBillableRegistered
											totalNonBillableRegistered
										}
										phase {
											name
										}
									}
								}
							}
						}
					}
				`,
			},
			graphql`
				query TimeRegTaskRelayWrapperRefetchQuery(
					$pageSize: Int
					$searchQuery: TaskSearchQueryType
					$reportId: String
					$sharedId: String
				) {
					viewer {
						...TimeRegTaskRelayWrapper_viewer
							@arguments(pageSize: $pageSize, searchQuery: $searchQuery, reportId: $reportId, sharedId: $sharedId)
					}
				}
			`
		)
	)
);
