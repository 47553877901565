import { Text } from '@forecast-it/design-system';
import React from 'react';
import { getTimeRegistrationSettingsInfoMessage } from './TimeRegistrationSettingsInfoMessageContent';
import { getTimeRegistrationSettings } from '../../../util/cache/TimeRegistrationSettingsUtil';
export function TimeRegistrationSettingsInfoMessage() {
    const infoText = getTimeRegistrationSettingsInfoMessage(getTimeRegistrationSettings());
    return (React.createElement(React.Fragment, null,
        React.createElement(Text, { size: '2' }, infoText),
        React.createElement(Text, { size: '2', color: 'medium' }, "This is a company-wide setting configured by your admin.")));
}
