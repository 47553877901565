import draftToHtml from './draftjsToHtml/js';
export const convertFromDraftJsToHtml = (draftJs) => {
    if (!draftJs) {
        return '';
    }
    const json = JSON.parse(draftJs);
    const html = draftToHtml(json);
    console.log('editor content conversion html', html);
    return html;
};
