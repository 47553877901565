import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import TdLink from '../../../components/td_link';
import {withRouter} from 'react-router-dom';
import Toggle from '../../../components/inputs/toggle';
import UpdateCompanyMutation from '../../../mutations/update_company_mutation.modern';
import DeleteHolidayCalendarMutation from '../../../mutations/delete_holiday_calendar_mutation';
import Util from '../../shared/util/util';
import {MODAL_TYPE, showModal} from '../../shared/components/modals/generic_modal_conductor';
import {createToast} from '../../shared/components/toasts/another-toast/toaster';

class HolidayCalendarsRow extends Component {
	constructor(props) {
		super(props);
		this.state = {
			gearMenuExpanded: false,
		};
	}

	closeGearMenu() {
		this.setState({gearMenuExpanded: false});
	}

	toggleGearMenu(e) {
		this.setState({gearMenuExpanded: !this.state.gearMenuExpanded});
	}

	openHolidayCalendarPage() {
		this.props.history.push('/admin/holidays/view-holiday-calendar/' + this.props.id);
	}

	deleteHolidayCalendar() {
		const callbackPositive = params => {
			const onSuccess = result => {
				createToast({
					duration: 5000,
					message: <FormattedMessage id="settings_holiday_calendars.delete_notification" />,
				});
				this.props.history.push('/admin/holidays/');
			};

			Util.CommitMutation(
				DeleteHolidayCalendarMutation,
				{
					id: this.props.id,
					companyId: this.props.companyId,
				},
				onSuccess,
				true
			);
		};

		showModal({
			type: MODAL_TYPE.GENERIC_DELETION_WARNING,
			deleteCallback: callbackPositive,
		});
	}

	setDefaultHolidayCalendar() {
		Util.CommitMutation(
			UpdateCompanyMutation,
			{
				id: this.props.companyId,
				defaultHolidayCalendarId: this.props.isDefault ? null : this.props.id,
			},
			null,
			true
		);
	}

	render() {
		let path = window.location.pathname;
		if (path.endsWith('/')) {
			path = path.substr(0, path.length - 1);
		}
		path += '/view-holiday-calendar/' + this.props.id;
		return (
			<tr className="row-holiday-calendar">
				<TdLink dataCy={'calendar-name'} className="client" path={path} content={this.props.name} />
				<td className="default">
					<Toggle checked={this.props.isDefault} onChange={this.setDefaultHolidayCalendar.bind(this)} />
				</td>
				<td
					className="gear-menu"
					tabIndex="0"
					onBlur={this.closeGearMenu.bind(this)}
					onClick={this.toggleGearMenu.bind(this)}
				>
					<span className="gear-icon" />
					<div className={`gear-menu-drop-down ${this.state.gearMenuExpanded ? 'expanded' : 'collapsed'}`}>
						<ul className="settings-drop-down">
							<li className="option" onClick={this.openHolidayCalendarPage.bind(this)}>
								<FormattedMessage id="common.edit" />
							</li>
							<li className="option" onClick={this.deleteHolidayCalendar.bind(this)}>
								<FormattedMessage id="common.delete" />
							</li>
						</ul>
					</div>
				</td>
			</tr>
		);
	}
}

HolidayCalendarsRow.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	isDefault: PropTypes.bool.isRequired,
};

export default withRouter(HolidayCalendarsRow);
