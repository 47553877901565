/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type DepartmentDropdown_departments$ref = any;
type PersonDropdown_persons$ref = any;
type SettingsResourcesSubNav_viewer$ref = any;
type SkillCategoryRow_company$ref = any;
type SkillCategoryRow_skillCategory$ref = any;
type SkillCategoryRow_skills$ref = any;
export type FILTER_SECTION = "FINANCIAL_PORTFOLIO_REPORT" | "INVOICING_INVOICES" | "INVOICING_OVERVIEW" | "LABELS" | "MY_LIST" | "MY_TASKS" | "MY_TIMESHEETS" | "MY_WORK" | "PROJECT" | "PROJECTS_OVERVIEW" | "PROJECT_TIMELINE" | "REPORTED_TIME_TABLE" | "SCHEDULING_CAPACITY_OVERVIEW" | "SCHEDULING_PEOPLE" | "SCHEDULING_PLACEHOLDERS" | "SCHEDULING_PROJECTS" | "SCOPING" | "SKILLS" | "SPRINT" | "SPRINT_BACKLOG" | "TASK_REPORT" | "TEAM_TIMESHEET" | "TIME_APPROVAL" | "UTILIZATION_REPORT" | "WORKFLOW" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type settingsSkills_viewer$ref: FragmentReference;
declare export opaque type settingsSkills_viewer$fragmentType: settingsSkills_viewer$ref;
export type settingsSkills_viewer = {|
  +id: string,
  +company: ?{|
    +id: string,
    +skillLevelsEnabled: ?boolean,
    +departments: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +persons: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string
              |}
            |}>
          |}
        |},
        +$fragmentRefs: DepartmentDropdown_departments$ref,
      |}>
    |},
    +teams: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +teamPersons: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +person: ?{|
                  +id: string
                |},
              |}
            |}>
          |},
        |}
      |}>
    |},
    +allPersons: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +role: ?{|
            +id: string
          |},
          +department: ?{|
            +id: string
          |},
        |},
        +$fragmentRefs: PersonDropdown_persons$ref,
      |}>
    |},
    +skills: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +category: ?{|
            +id: string
          |},
          +skillPersons: ?$ReadOnlyArray<?{|
            +person: ?{|
              +id: string,
              +role: ?{|
                +id: string
              |},
              +department: ?{|
                +id: string
              |},
              +active: ?boolean,
              +permissions: ?$ReadOnlyArray<?string>,
            |},
            +level: ?{|
              +id: string
            |},
          |}>,
          +$fragmentRefs: SkillCategoryRow_skills$ref,
        |}
      |}>
    |},
    +skillCategories: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +$fragmentRefs: SkillCategoryRow_skillCategory$ref,
        |}
      |}>
    |},
    +$fragmentRefs: SkillCategoryRow_company$ref,
  |},
  +filters: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +section: ?FILTER_SECTION,
        +value: ?string,
        +updatedAt: ?string,
      |}
    |}>
  |},
  +$fragmentRefs: SettingsResourcesSubNav_viewer$ref,
  +$refType: settingsSkills_viewer$ref,
|};
export type settingsSkills_viewer$data = settingsSkills_viewer;
export type settingsSkills_viewer$key = {
  +$data?: settingsSkills_viewer$data,
  +$fragmentRefs: settingsSkills_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Role",
  "kind": "LinkedField",
  "name": "role",
  "plural": false,
  "selections": (v1/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "DepartmentType",
  "kind": "LinkedField",
  "name": "department",
  "plural": false,
  "selections": (v1/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "company",
          "departments"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "company",
          "skills"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "company",
          "skillCategories"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "filters"
        ]
      }
    ]
  },
  "name": "settingsSkills_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "skillLevelsEnabled",
          "storageKey": null
        },
        {
          "alias": "departments",
          "args": null,
          "concreteType": "DepartmentTypeConnection",
          "kind": "LinkedField",
          "name": "__Company_departments_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DepartmentTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "DepartmentType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PersonTypeConnection",
                      "kind": "LinkedField",
                      "name": "persons",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "PersonTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Person",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": (v1/*: any*/),
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                },
                (v3/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "DepartmentDropdown_departments"
                }
              ],
              "storageKey": null
            },
            (v4/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 1000000
            }
          ],
          "concreteType": "TeamTypeConnection",
          "kind": "LinkedField",
          "name": "teams",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TeamTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Team",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "first",
                          "value": 1000
                        }
                      ],
                      "concreteType": "TeamPersonTypeConnection",
                      "kind": "LinkedField",
                      "name": "teamPersons",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "TeamPersonTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "TeamPerson",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Person",
                                  "kind": "LinkedField",
                                  "name": "person",
                                  "plural": false,
                                  "selections": (v1/*: any*/),
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": "teamPersons(first:1000)"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "teams(first:1000000)"
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "excludeClientUsers",
              "value": true
            },
            {
              "kind": "Literal",
              "name": "first",
              "value": 10000
            }
          ],
          "concreteType": "PersonTypeConnection",
          "kind": "LinkedField",
          "name": "allPersons",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PersonTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Person",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v5/*: any*/),
                    (v6/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "PersonDropdown_persons"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "allPersons(excludeClientUsers:true,first:10000)"
        },
        {
          "alias": "skills",
          "args": null,
          "concreteType": "SkillTypeConnection",
          "kind": "LinkedField",
          "name": "__Company_skills_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SkillTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Skill",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v7/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SkillCategory",
                      "kind": "LinkedField",
                      "name": "category",
                      "plural": false,
                      "selections": (v1/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SkillPerson",
                      "kind": "LinkedField",
                      "name": "skillPersons",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Person",
                          "kind": "LinkedField",
                          "name": "person",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v5/*: any*/),
                            (v6/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "active",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "permissions",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "SkillLevel",
                          "kind": "LinkedField",
                          "name": "level",
                          "plural": false,
                          "selections": (v1/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v2/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "SkillCategoryRow_skills"
                    }
                  ],
                  "storageKey": null
                },
                (v3/*: any*/)
              ],
              "storageKey": null
            },
            (v4/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": "skillCategories",
          "args": null,
          "concreteType": "SkillCategoryTypeConnection",
          "kind": "LinkedField",
          "name": "__Company_skillCategories_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SkillCategoryTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SkillCategory",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v7/*: any*/),
                    (v2/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "SkillCategoryRow_skillCategory"
                    }
                  ],
                  "storageKey": null
                },
                (v3/*: any*/)
              ],
              "storageKey": null
            },
            (v4/*: any*/)
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SkillCategoryRow_company"
        }
      ],
      "storageKey": null
    },
    {
      "alias": "filters",
      "args": null,
      "concreteType": "FilterTypeConnection",
      "kind": "LinkedField",
      "name": "__Viewer_filters_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FilterTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Filter",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v7/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "section",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "value",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "updatedAt",
                  "storageKey": null
                },
                (v2/*: any*/)
              ],
              "storageKey": null
            },
            (v3/*: any*/)
          ],
          "storageKey": null
        },
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SettingsResourcesSubNav_viewer"
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fc935aec5ecea943b3a2be318495847e';

module.exports = node;
