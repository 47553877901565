import React, {useState} from 'react';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {createRefetchContainer, graphql} from 'react-relay';
import TimeRegBaseLevelWrapper from './TimeRegBaseLevelWrapper';
import {getProjectRow} from '../rows/ProjectRow';
import {useGenericReportContext} from '../../../saved-report/GenericReportContext';

const TimeRegProjectRelayWrapper = ({
	relay,
	tableHeader,
	groupings,
	searchQuery,
	viewer,
	intl,
	groupIndex,
	enabledColumns,
	nextLevelProps,
	currency,
	customFieldColumnCount,
	customFieldKeys,
	pageSize,
}) => {
	const {reportId, sharedId, isSharedView} = useGenericReportContext();

	const projects = viewer.reportData.projects.edges.filter(project => project.node.timeRegTotals?.totalTimeRegistered > 0);
	const hasNextPage = viewer.reportData.projects.pageInfo.hasNextPage && projects?.length >= pageSize;
	const [modalTasksFetching, setModalTasksFetching] = useState(false);

	const dataRows = projects.map(project => {
		const hasAccessToProject = project.node.fullAccessToProject;
		return {
			...project.node,
			canExpand: hasAccessToProject || project.node.noProject,
		};
	});

	const data = {rows: dataRows};

	const fetchMore = () => {
		if (!modalTasksFetching) {
			const refetchObject = {};
			setModalTasksFetching(true);
			refetchObject.pageSize = projects.length + pageSize;
			relay.refetch(refetchObject, null, () => setModalTasksFetching(false));
		}
	};

	const projectRows = () => {
		return getProjectRow(
			data,
			intl,
			groupIndex,
			searchQuery,
			groupings,
			enabledColumns,
			hasNextPage,
			fetchMore,
			nextLevelProps,
			currency,
			reportId,
			sharedId,
			isSharedView,
			customFieldColumnCount,
			customFieldKeys
		);
	};

	return groupIndex === 1 ? (
		<TimeRegBaseLevelWrapper
			tableHeader={tableHeader}
			intl={intl}
			enabledColumns={enabledColumns}
			getRows={projectRows}
			currentLength={projects.length}
			hasNextPage={viewer.reportData.projects.pageInfo.hasNextPage}
			refetch={relay.refetch}
			customFieldColumnCount={customFieldColumnCount}
			pageSize={pageSize}
		/>
	) : (
		projectRows()
	);
};

const TimeRegProjectRelayWrapperQuery = graphql`
	query TimeRegProjectRelayWrapper_Query(
		$searchQuery: TaskSearchQueryType
		$pageSize: Int
		$reportId: String
		$sharedId: String
	) {
		viewer {
			actualPersonId
			component(name: "time_reg_project_relay_wrapper_v2")
			...TimeRegProjectRelayWrapper_viewer
				@arguments(searchQuery: $searchQuery, pageSize: $pageSize, reportId: $reportId, sharedId: $sharedId)
		}
	}
`;

export {TimeRegProjectRelayWrapperQuery};

export default injectIntl(
	withRouter(
		createRefetchContainer(
			TimeRegProjectRelayWrapper,
			{
				viewer: graphql`
					fragment TimeRegProjectRelayWrapper_viewer on Viewer
					@argumentDefinitions(
						searchQuery: {type: "TaskSearchQueryType"}
						pageSize: {type: "Int"}
						reportId: {type: "String"}
						sharedId: {type: "String"}
					) {
						reportData(id: $reportId, sharedId: $sharedId) {
							projects(first: $pageSize, searchQuery: $searchQuery)
								@connection(key: "TimeRegReport_projects", filters: ["searchQuery"]) {
								pageInfo {
									endCursor
									hasNextPage
								}
								edges {
									node {
										projectId
										companyProjectId
										customProjectId
										name
										projectColor
										fullAccessToProject
										readOnlyAccess
										noProject
										timeRegTotals {
											totalCost
											totalPrice
											totalTimeRegistered
											totalBillableRegistered
											totalNonBillableRegistered
										}
									}
								}
							}
						}
					}
				`,
			},
			graphql`
				query TimeRegProjectRelayWrapperRefetchQuery(
					$pageSize: Int
					$searchQuery: TaskSearchQueryType
					$reportId: String
					$sharedId: String
				) {
					viewer {
						...TimeRegProjectRelayWrapper_viewer
							@arguments(pageSize: $pageSize, searchQuery: $searchQuery, reportId: $reportId, sharedId: $sharedId)
					}
				}
			`
		)
	)
);
