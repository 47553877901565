import Util from '../../../shared/util/util';
import ToggleReportFavoriteMutation from '../../../../mutations/toggle_report_favorite_mutation';
export const toggleFavoriteReport = (row) => {
    if (row.reportService) {
        Util.CommitMutation(ToggleReportFavoriteMutation, {
            reportId: row.id,
            isFavorite: row.favorite,
        });
    }
};
