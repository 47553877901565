import { Link, Text } from '@forecast-it/design-system';
import React from 'react';
import { hasFeatureFlag } from '../../../../../shared/util/FeatureUtil';
import { dispatch, EVENT_ID } from '../../../../../../containers/event_manager';
import { trackEvent } from '../../../../../../tracking/amplitude/TrackingV2';
import styled from 'styled-components';
// FIXME: hack until font-weight is added to DS
const TextStyleOverride = styled.div `
	div {
		font-weight: 600;
	}
`;
export const EmptyStateTaskName = ({ filterActive, searchActive, additionalClasses, usingCurrentUserAssigneeFilter, }) => {
    const usingCurrentUserAssigneeFilterMessage = 'It looks like there’s no work assigned to you';
    const genericNotFoundMessage = 'We cannot find what you are looking for';
    if (hasFeatureFlag('timesheet_remaster') && searchActive) {
        return (React.createElement("div", { className: 'elem-wrapper empty-state' + additionalClasses, style: { overflow: 'visible' } },
            React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
                React.createElement(TextStyleOverride, null,
                    React.createElement(Text, { noWrap: true }, genericNotFoundMessage)),
                React.createElement(Text, { noWrap: true, color: 'medium' },
                    React.createElement(Link, { onClick: () => {
                            trackEvent('Clear Search Query Link', 'Clicked');
                            dispatch(EVENT_ID.CLEAR_SEARCH);
                        } }, "Try clearing the search query")))));
    }
    if (hasFeatureFlag('timesheet_remaster') && filterActive) {
        return (React.createElement("div", { className: 'elem-wrapper empty-state' + additionalClasses, style: { overflow: 'visible' } },
            React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
                React.createElement(TextStyleOverride, null,
                    React.createElement(Text, { noWrap: true }, usingCurrentUserAssigneeFilter ? usingCurrentUserAssigneeFilterMessage : genericNotFoundMessage)),
                React.createElement(Text, { noWrap: true, color: 'medium' },
                    React.createElement(Link, { onClick: () => {
                            trackEvent('Clear Filters Link', 'Clicked');
                            dispatch(EVENT_ID.CLEAR_FILTERS);
                        } }, "Try clearing the filters")))));
    }
    return (React.createElement("div", { className: 'elem-wrapper empty-state' + additionalClasses, style: { overflow: 'visible' } },
        React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
            React.createElement(TextStyleOverride, null,
                React.createElement(Text, { noWrap: true }, genericNotFoundMessage)),
            React.createElement(Text, { noWrap: true, color: 'medium' }, "Try to adjust your search terms or filters."))));
};
