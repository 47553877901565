import React, {useState} from 'react';
import {AddTaskSection, BasicTextInputWrapper} from './ProjectWorkflow.styled';
import {createFragmentContainer, graphql} from 'react-relay';
import ProjectDropdown from '../../../shared/components/dropdowns/project-dropdown-a/project_dropdown';
import {BasicTextInput, Button, PlusIcon} from 'web-components';
import {createTaskInColumn} from './ProjectWorkflowLogic';
import {PROJECT_STATUS} from '../../../../constants';
const ProjectWorkflowAddTask = ({intl, columnId, disabled, projectGroup, project, companyId}) => {
	const [selectedConnectedProject, setSelectedConnectedProject] = useState(
		projectGroup
			? projectGroup.projects.edges.find(
					project => project.node.status !== PROJECT_STATUS.DONE && project.node.status !== PROJECT_STATUS.HALTED
			  ) || projectGroup.projects.edges[0]
			: project
	);
	const [newTaskInputValue, setNewTaskInputValue] = useState('');

	const createTask = () => {
		const projectId = projectGroup ? selectedConnectedProject.node.id : selectedConnectedProject.id;
		const selectedColumn = projectGroup
			? selectedConnectedProject.node.statusColumnsV2.edges.find(col => col.node.projectGroupStatusColumnId === columnId)
			: null;
		const selectedColumnId = projectGroup ? selectedColumn && selectedColumn.node.id : columnId;
		createTaskInColumn(selectedColumnId, projectId, newTaskInputValue, companyId);
		setNewTaskInputValue('');
	};

	const handleSelectProject = project => {
		setSelectedConnectedProject(project);
	};

	return (
		<AddTaskSection>
			{projectGroup ? (
				<ProjectDropdown
					selectedProject={selectedConnectedProject}
					projectOptions={projectGroup.projects.edges}
					onChange={p => handleSelectProject(p)}
				/>
			) : null}
			<BasicTextInputWrapper>
				<BasicTextInput
					cy="new-task-input"
					disabled={disabled}
					value={newTaskInputValue}
					placeholder={intl.formatMessage({id: 'common.new-task'})}
					onChange={e => setNewTaskInputValue(e)}
					callbackOnEnter={() => createTask()}
					maxLength={191}
				></BasicTextInput>
			</BasicTextInputWrapper>
			{newTaskInputValue && (
				<Button
					iconPosition={Button.ICON_POSITION.FIRST}
					icon={color => <PlusIcon color={color} />}
					showText={false}
					onClick={() => createTask()}
					variant={Button.VARIANT.GREEN_FILLED}
					isSquare={true}
				/>
			)}
		</AddTaskSection>
	);
};

export default createFragmentContainer(ProjectWorkflowAddTask, {
	projectGroup: graphql`
		fragment ProjectWorkflowAddTask_projectGroup on ProjectGroupType {
			id
			name
			projects(first: 1000000) {
				edges {
					node {
						id
						name
						companyProjectId
						...DeprecatedProjectIndicatorJS_project
						projectColor
						status
						statusColumnsV2(first: 1000000) @connection(key: "Project_statusColumnsV2", filters: []) {
							edges {
								node {
									id
									category
									projectGroupStatusColumnId
									order
								}
							}
						}
					}
				}
			}
		}
	`,
});
