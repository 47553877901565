export var TimeRegistrationNotAllowedReason;
(function (TimeRegistrationNotAllowedReason) {
    TimeRegistrationNotAllowedReason["NoProject"] = "NO_PROJECT";
    TimeRegistrationNotAllowedReason["NoInternalTime"] = "NO_INTERNAL_TIME";
    TimeRegistrationNotAllowedReason["NoDoneTask"] = "NO_DONE_TASK";
    TimeRegistrationNotAllowedReason["NoUnassignedTask"] = "NO_UNASSIGNED_TASK";
})(TimeRegistrationNotAllowedReason || (TimeRegistrationNotAllowedReason = {}));
export function getNotAllowedReasonEntityLabel(notAllowedReason) {
    switch (notAllowedReason) {
        case TimeRegistrationNotAllowedReason.NoProject:
            return 'directly on projects';
        case TimeRegistrationNotAllowedReason.NoInternalTime:
            return 'on internal time';
        case TimeRegistrationNotAllowedReason.NoDoneTask:
            return 'on done tasks';
        case TimeRegistrationNotAllowedReason.NoUnassignedTask:
            return 'on tasks that are not assigned to you';
    }
}
