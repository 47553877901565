import React, {Component} from 'react';
import TimeEntriesTile, {timeEntriesTileQuery} from './time_entries_tile';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import ForecastQueryRenderer from '../../../../../../ForecastQueryRenderer';

class TimeEntriesTileRenderer extends Component {
	shouldComponentUpdate(nextProps) {
		return nextProps.taskId !== this.props.taskId;
	}

	render() {
		return (
			<ForecastQueryRenderer
				key="query-render-timeEntriesTileQuery"
				query={timeEntriesTileQuery}
				variables={{taskId: this.props.taskId, personId: this.props.personId}}
				customLoader={() => (
					<div className="header-tile header-tile-time-entries" data-cy={this.props.cy}>
						<div className="tile-wrapper">
							<div className="tile-container">
								<div className="tile-label">
									<FormattedMessage id="common.time_entries" />
								</div>
								<div className="text-container">
									<div className="number-text">...</div>
									<div className="unit-text">
										<FormattedMessage id="common.hours.short" />
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
				render={(relayProps, retry) => (
					<TimeEntriesTile
						warning={this.props.warning}
						retry={retry}
						viewer={relayProps.viewer}
						rollupTimeEntries={this.props.rollupTimeEntries}
						disabled={this.props.disabled}
						taskId={this.props.taskId}
						cy={this.props.cy}
						isClientUser={this.props.isClientUser}
						isTwoLevelSubTask={this.props.isTwoLevelSubTask}
					/>
				)}
			/>
		);
	}
}

TimeEntriesTileRenderer.propTypes = {
	taskId: PropTypes.string.isRequired,
	personId: PropTypes.string.isRequired,
};

export default TimeEntriesTileRenderer;
