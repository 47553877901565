/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v2 = [
        (v0 /*: any*/),
        (v1 /*: any*/)
    ], v3 = {
        "alias": null,
        "args": null,
        "concreteType": "Role",
        "kind": "LinkedField",
        "name": "role",
        "plural": false,
        "selections": (v2 /*: any*/),
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "billable",
        "storageKey": null
    }, v5 = [
        (v0 /*: any*/)
    ], v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fullAccessToProject",
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "companyProjectId",
        "storageKey": null
    }, v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "customProjectId",
        "storageKey": null
    }, v10 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "projectColor",
        "storageKey": null
    }, v11 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "projectStartYear",
        "storageKey": null
    }, v12 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "projectStartMonth",
        "storageKey": null
    }, v13 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "projectStartDay",
        "storageKey": null
    }, v14 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "projectEndYear",
        "storageKey": null
    }, v15 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "projectEndMonth",
        "storageKey": null
    }, v16 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "projectEndDay",
        "storageKey": null
    }, v17 = {
        "alias": null,
        "args": null,
        "concreteType": "Role",
        "kind": "LinkedField",
        "name": "projectRoles",
        "plural": true,
        "selections": (v2 /*: any*/),
        "storageKey": null
    }, v18 = {
        "alias": null,
        "args": [
            {
                "kind": "Variable",
                "name": "personId",
                "variableName": "personId"
            }
        ],
        "concreteType": "ProjectPerson",
        "kind": "LinkedField",
        "name": "projectPerson",
        "plural": false,
        "selections": [
            (v3 /*: any*/)
        ],
        "storageKey": null
    }, v19 = {
        "condition": "fetchProgramInfo",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "programBudgetType",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isProgramRevenueLocked",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "ProgramType",
                "kind": "LinkedField",
                "name": "program",
                "plural": false,
                "selections": [
                    (v1 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "prefix",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectServiceProgramMemberTypeConnection",
                        "kind": "LinkedField",
                        "name": "members",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProjectServiceProgramMemberTypeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ProjectServiceProgramMemberType",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "role",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "Person",
                                                "kind": "LinkedField",
                                                "name": "person",
                                                "plural": false,
                                                "selections": (v5 /*: any*/),
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ]
    }, v20 = {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ProjectIndicator_project"
    }, v21 = {
        "args": null,
        "kind": "FragmentSpread",
        "name": "TimeRegistrationModalIntegrations_project"
    };
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "personId",
                "type": "ID!"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "taskId",
                "type": "Int"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "projectId",
                "type": "Int"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "idleTimeId",
                "type": "Int"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "date",
                "type": "String"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "fetchProgramInfo",
                "type": "Boolean!"
            }
        ],
        "kind": "Fragment",
        "metadata": null,
        "name": "TimeRegistrationModal_viewer",
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "backendId",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "actualPersonId",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sageIntacctEnabled",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lockedPeriodYear",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lockedPeriodMonth",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lockedPeriodDay",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": [
                            {
                                "kind": "Variable",
                                "name": "backendProjectId",
                                "variableName": "projectId"
                            },
                            {
                                "kind": "Literal",
                                "name": "includeDisabled",
                                "value": false
                            }
                        ],
                        "concreteType": "RoleTypeConnection",
                        "kind": "LinkedField",
                        "name": "roles",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "RoleTypeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Role",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": (v2 /*: any*/),
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": [
                            {
                                "kind": "Variable",
                                "name": "id",
                                "variableName": "personId"
                            }
                        ],
                        "concreteType": "Person",
                        "kind": "LinkedField",
                        "name": "person",
                        "plural": false,
                        "selections": [
                            (v0 /*: any*/),
                            (v3 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "email",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "submitLockedDateYear",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "submitLockedDateMonth",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "submitLockedDateDay",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "excludeFromCompanyLockedPeriod",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "startDate",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endDate",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "createdAt",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "monday",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "tuesday",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "wednesday",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "thursday",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "friday",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "saturday",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "sunday",
                                "storageKey": null
                            },
                            {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "TimeRegistrationModalIntegrations_person"
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "TimeRegistrationModalIntegrations_company"
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Variable",
                        "name": "backendId",
                        "variableName": "taskId"
                    }
                ],
                "concreteType": "Task",
                "kind": "LinkedField",
                "name": "task",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    (v1 /*: any*/),
                    (v3 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "companyTaskId",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "estimateForecastMinutes",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalMinutesRegistered",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "timeLeft",
                        "storageKey": null
                    },
                    (v4 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startYear",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startMonth",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startDay",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "deadlineYear",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "deadlineMonth",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "deadlineDay",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "StatusColumnV2",
                        "kind": "LinkedField",
                        "name": "statusColumnV2",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "category",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "Person",
                        "kind": "LinkedField",
                        "name": "assignedPersons",
                        "plural": true,
                        "selections": (v5 /*: any*/),
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectType",
                        "kind": "LinkedField",
                        "name": "project",
                        "plural": false,
                        "selections": [
                            (v0 /*: any*/),
                            (v1 /*: any*/),
                            (v6 /*: any*/),
                            (v7 /*: any*/),
                            (v8 /*: any*/),
                            (v9 /*: any*/),
                            (v10 /*: any*/),
                            (v4 /*: any*/),
                            (v11 /*: any*/),
                            (v12 /*: any*/),
                            (v13 /*: any*/),
                            (v14 /*: any*/),
                            (v15 /*: any*/),
                            (v16 /*: any*/),
                            (v17 /*: any*/),
                            (v18 /*: any*/),
                            (v19 /*: any*/),
                            (v20 /*: any*/),
                            (v21 /*: any*/)
                        ],
                        "storageKey": null
                    },
                    {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "TimeRegistrationModalTaskInfo_task"
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Variable",
                        "name": "backendId",
                        "variableName": "projectId"
                    }
                ],
                "concreteType": "ProjectType",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    (v1 /*: any*/),
                    (v6 /*: any*/),
                    (v7 /*: any*/),
                    (v8 /*: any*/),
                    (v9 /*: any*/),
                    (v10 /*: any*/),
                    (v4 /*: any*/),
                    (v11 /*: any*/),
                    (v12 /*: any*/),
                    (v13 /*: any*/),
                    (v14 /*: any*/),
                    (v15 /*: any*/),
                    (v16 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "Client",
                        "kind": "LinkedField",
                        "name": "client",
                        "plural": false,
                        "selections": [
                            (v1 /*: any*/)
                        ],
                        "storageKey": null
                    },
                    (v17 /*: any*/),
                    (v18 /*: any*/),
                    (v19 /*: any*/),
                    (v20 /*: any*/),
                    (v21 /*: any*/)
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Variable",
                        "name": "backendId",
                        "variableName": "idleTimeId"
                    }
                ],
                "concreteType": "IdleTime",
                "kind": "LinkedField",
                "name": "idleTime",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isInternalTime",
                        "storageKey": null
                    },
                    (v1 /*: any*/)
                ],
                "storageKey": null
            }
        ],
        "type": "Viewer"
    };
})();
node.hash = 'eff1bed13fb012f59d46368c95e214da';
export default node;
