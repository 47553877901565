// @ts-check
import {useRef} from 'react';

/**
 * @callback singletonCallback
 */

/**
 * Hook allowing a functional component to execute a function once and only once per version of the deps.
 * This can for instance be used to emulate the behavior of the constructor of a Class-based React component, or to emulate the behavior of useEffect executed before the render cycle.
 * @param {singletonCallback} callback - Function to execute
 * @param {Array} [deps=[]] - Array of items that when changed will cause the callback to trigger again, works similar to the deps array of native React hooks
 */
export const useSingleton = (callback = () => {}, deps = []) => {
	const hasBeenExecuted = useRef(false);
	const prevDeps = useRef(deps);

	const hasChanged = deps.some((d, index) => !Object.is(d, prevDeps.current[index])); // Same shallow equality implementation as useEffect
	if (hasChanged) {
		prevDeps.current = deps;
		hasBeenExecuted.current = false;
	}

	if (hasBeenExecuted.current) return;

	callback();

	hasBeenExecuted.current = true;
};
