import {
	AvailabilityTooltipContent,
	AvailabilityTooltipItemTitle,
	AvailabilityTooltipItemValue,
} from './AvailabilityTooltipContent.styled';
import React from 'react';
import Util from '../../../../util/util';

/**
 *
 * @param {string} columnKey
 * @param {object} columnsObject, objectInfo keyed by column name
 * @returns {number | false} returns width if its visible, else null,
 */

export const getColumnWidth = (columnKey, columns, level = 0) => {
	if (columns[columnKey]?.checked) {
		const subtractAmount = columnKey === 'task-name' ? 24 * level : 0;
		return columns[columnKey]?.width - subtractAmount;
	}
	return false;
};

export const alignToFlex = align => {
	switch (align) {
		case 'left':
			return 'flex-start';
		case 'center':
			return 'center';
		case 'right':
			return 'flex-end';
	}
};

export const getAvailabilityTooltipContent = (intl, availabilityInformation) => {
	const {formatMessage} = intl;
	const {totalAvailability, availabilityUntilPhaseEnds, taskRemaining, remainingAvailabilityInPhase} =
		availabilityInformation;

	return (
		<AvailabilityTooltipContent>
			{!!totalAvailability && (
				<>
					<AvailabilityTooltipItemTitle>{`${formatMessage({
						id: 'project_scoping.total_availability_in_phase',
					})}:`}</AvailabilityTooltipItemTitle>
					<AvailabilityTooltipItemValue>
						{Util.convertMinutesToFullHour(totalAvailability, intl)}
					</AvailabilityTooltipItemValue>
				</>
			)}
			{!!availabilityUntilPhaseEnds && (
				<>
					<AvailabilityTooltipItemTitle>{`${formatMessage({
						id: 'project_scoping.availability_from_today_until_phase_ends',
					})}:`}</AvailabilityTooltipItemTitle>
					<AvailabilityTooltipItemValue>
						{Util.convertMinutesToFullHour(availabilityUntilPhaseEnds, intl)}
					</AvailabilityTooltipItemValue>
				</>
			)}
			{!!taskRemaining && (
				<>
					<AvailabilityTooltipItemTitle>{`${formatMessage({
						id: 'project_scoping.remaining_time_on_tasks_in_phase',
					})}:`}</AvailabilityTooltipItemTitle>
					<AvailabilityTooltipItemValue>
						{Util.convertMinutesToFullHour(taskRemaining, intl)}
					</AvailabilityTooltipItemValue>
				</>
			)}
			{!!remainingAvailabilityInPhase && (
				<>
					<AvailabilityTooltipItemTitle>{`${formatMessage({
						id: 'project_scoping.remaining_availability_in_phase',
					})} = `}</AvailabilityTooltipItemTitle>
					<AvailabilityTooltipItemValue bold={true} isOverAllocated={remainingAvailabilityInPhase < 0}>
						{Util.convertMinutesToFullHour(remainingAvailabilityInPhase, intl)}
					</AvailabilityTooltipItemValue>
				</>
			)}
		</AvailabilityTooltipContent>
	);
};
