import React, {useRef, useState} from 'react';
import {ClickAwayListener} from '@material-ui/core';
import PopupStyle from '../../../../../styles/components/time_reg_popup_styled';
import ReactDOM from 'react-dom';
import {getPopupPosition} from '../../../util/PopupUtil';
import {ProgressRegistrationIcon} from 'web-components/icons';
import {CSS_CONSTANTS} from '../../../../../css_variables';
import {ProgressRegistration} from './ProgressRegistration';

export const ProgressRegistrationPopup = ({currentProgressValue, onClose, onOpen}) => {
	const progressIconRef = useRef(null);
	const popupRef = useRef(null);

	const [isPopupVisible, setIsPopupVisible] = useState(false);
	const [popupPosition, setPopupPosition] = useState({});
	const [arrowPosition, setArrowPosition] = useState({});

	const handleIconClick = event => {
		if (progressIconRef.current) {
			event.stopPropagation();
			const iconPosition = progressIconRef.current.getBoundingClientRect();

			requestAnimationFrame(() => {
				if (popupRef.current) {
					const popupPosition = popupRef.current.getBoundingClientRect();

					const result = getPopupPosition(iconPosition, popupPosition);

					setPopupPosition(result.popupPositionValues);
					setArrowPosition(result.arrowPositionValues);
				}
			});
		}
		if (onOpen) {
			onOpen();
		}

		setIsPopupVisible(!isPopupVisible);
	};

	const closePopup = value => {
		if (isPopupVisible) {
			setIsPopupVisible(false);
		}
		onClose(value);
	};

	const onPopupClick = e => {
		e.target.focus();
	};

	return (
		<React.Fragment>
			<div className={'time-registration-icon'} ref={progressIconRef} onClick={handleIconClick}>
				<ProgressRegistrationIcon hoverColor={CSS_CONSTANTS.v2_branding_purple} />
			</div>

			{isPopupVisible
				? ReactDOM.createPortal(
						<ClickAwayListener onClickAway={closePopup}>
							<PopupStyle
								onClick={onPopupClick}
								popupPosition={popupPosition}
								arrowPosition={arrowPosition}
								ref={popupRef}
							>
								<ProgressRegistration
									isPopup={true}
									showButtons={true}
									showTitle={true}
									currentProgressValue={currentProgressValue}
									close={closePopup}
									closeOnValueClicked={true}
								/>
							</PopupStyle>
						</ClickAwayListener>,
						document.querySelector('#root-portal-container')
				  )
				: null}
		</React.Fragment>
	);
};
