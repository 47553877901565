import ForecastTooltip from '../tooltips/ForecastTooltip';
import React from 'react';
import styled from 'styled-components';
import { Icon } from '@forecast-it/design-system';
const TimeregActionButtonStyle = styled.button `
	border: none;
	background: none;
	padding: 0;
`;
const TimeregActionButton = ({ disabled = false, onClick, text, icon, 'data-cy': dataCy, }) => {
    return (React.createElement(TimeregActionButtonStyle, { onClick: e => {
            e.stopPropagation();
            onClick(e);
        }, disabled: disabled, "aria-label": text, "data-cy": dataCy },
        React.createElement(ForecastTooltip, { content: text, maxWidth: 250 },
            React.createElement(Icon, { icon: icon, color: disabled ? 'disabled' : 'default' }))));
};
export default TimeregActionButton;
