import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import Styled from 'styled-components';
import {CSS_CONSTANTS} from '../../../../../../../css_variables';
import Util from '../../../../../util/util';

const NumericTileContainerStyle = Styled.div`
	display: flex;
	margin-left: auto;
	width: 100%;
`;

const NumericTileStyle = Styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	height: 30px;
	font-size: 13px;
	text-align: right;
	color: ${props => (props.isNegative ? CSS_CONSTANTS.negative_value : props.darkText ? 'black' : CSS_CONSTANTS.v2_text_gray)};	
	width: 100%;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	
	border: ${props => props.inputStyle && '1px solid #e6e6e6'};
	border-radius: ${props => props.inputStyle && '4px'};
	padding: ${props => props.inputStyle && '6px 15px 6px 10px'};
	padding-right: ${props => props.inputIndent && !props.inputStyle && '16px'};
	background-color: ${props => props.inputStyle && '#fff'};
	border-top-left-radius: ${props => props.withInputExtension && 0};
	border-bottom-left-radius: ${props => props.withInputExtension && 0};
`;

const NumericTileText = Styled.div`
	overflow: hidden;
	text-overflow: ellipsis;
`;

const PopupContainer = Styled.div`
	margin-left: auto;
	margin-right: 4px;
`;

export const UNIT = {
	HOURS: 'hours',
	POINTS: 'points',
	PERCENTAGE: 'percentage',
};

const formatWithUnit = (value, unit, placeUnitBeforeValue, intl) => {
	switch (unit) {
		case UNIT.POINTS:
			return placeUnitBeforeValue
				? intl.formatMessage({id: 'common.points.short'}) + value
				: value + intl.formatMessage({id: 'common.points.short'});
		case UNIT.PERCENTAGE:
			return placeUnitBeforeValue ? '%' + value : value + '%';
		default:
			return placeUnitBeforeValue ? unit + ' ' + value : value + unit;
	}
};

export const NumericTileV2 = React.memo(
	({
		id,
		value,
		unit,
		placeUnitBeforeValue,
		customClassName,
		asterisk,
		popup,
		darkText,
		inputStyle,
		inputIndent,
		inputExtension,
		cy,
		highlightNegative = false,
	}) => {
		const intl = useIntl();

		const formattedValue = useMemo(() => {
			return unit === UNIT.HOURS
				? Util.convertMinutesToFullHour(value, intl)
				: formatWithUnit(value, unit, placeUnitBeforeValue, intl);
		}, [value, unit, placeUnitBeforeValue, intl]);

		const isNegative = highlightNegative && value < 0;

		return (
			<NumericTileContainerStyle className={customClassName}>
				{inputExtension}
				<NumericTileStyle
					id={id}
					data-cy={cy + '-value'}
					darkText={darkText}
					inputStyle={inputStyle}
					inputIndent={inputIndent}
					withInputExtension={!!inputExtension}
					isNegative={isNegative}
				>
					{popup ? <PopupContainer>{popup}</PopupContainer> : null}
					<NumericTileText>
						{formattedValue}
						{asterisk ? '*' : ''}
					</NumericTileText>
				</NumericTileStyle>
			</NumericTileContainerStyle>
		);
	}
);
