import Styled from 'styled-components';
import Util from '../../../../util/util';

export const HeaderInput = Styled.input`
    background-color: ${props => props.backgroundColor};
    color: ${({backgroundColor}) => Util.getTextColorV2(backgroundColor)};
    border-radius: 4px;
    border: 0px;
    height: 24px;
    width: 100%;
    &:hover {
        background-color: ${props => (props.disabled ? props.backgroundColor : 'rgba(0, 0, 0, 0.1)')};
    }
    &:focus {
        background-color: rgba(0, 0, 0, 0.2);
        outline: none;
    }
`;
