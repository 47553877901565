/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type StatusSidebar_viewer$ref = any;
export type StatusSidebar_QueryVariables = {|
  projectId?: ?string,
  programPrefix?: ?string,
|};
export type StatusSidebar_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: StatusSidebar_viewer$ref,
  |}
|};
export type StatusSidebar_Query = {|
  variables: StatusSidebar_QueryVariables,
  response: StatusSidebar_QueryResponse,
|};
*/


/*
query StatusSidebar_Query(
  $projectId: ID
  $programPrefix: String
) {
  viewer {
    actualPersonId
    component(name: "status_sidebar")
    ...StatusSidebar_viewer_4bGfr2
    id
  }
}

fragment StatusSidebar_viewer_4bGfr2 on Viewer {
  firstName
  project(internalId: $projectId) {
    id
    name
    projectColor
    isInProjectGroup
    companyProjectId
    currentProjectStatus {
      id
      color
      ...StatusWithDescription_projectStatus
    }
    projectStatuses(first: 10000) {
      edges {
        node {
          id
          year
          month
          day
          hour
          minute
          second
          ...StatusWithDescription_projectStatus
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  program(prefix: $programPrefix) {
    id
    prefix
    name
    color
    currentProgramStatus {
      id
      ragStatus
      ...StatusWithDescription_programStatus
    }
    programStatuses(first: 10000) {
      edges {
        node {
          id
          createdAt
          ...StatusWithDescription_programStatus
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
}

fragment StatusWithDescription_programStatus on ProjectServiceProgramStatusType {
  id
  createdAt
  createdBy {
    person {
      id
      fullName
      profilePictureId
    }
    id
  }
  description
  ragStatus
}

fragment StatusWithDescription_projectStatus on ProjectStatus {
  id
  person {
    id
    fullName
    profilePictureId
    profilePictureDefaultId
  }
  description
  color
  day
  month
  year
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId",
    "type": "ID"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "programPrefix",
    "type": "String"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "status_sidebar"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"status_sidebar\")"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePictureId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Person",
  "kind": "LinkedField",
  "name": "person",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profilePictureDefaultId",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "day",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "month",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "year",
  "storageKey": null
},
v13 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  }
],
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ragStatus",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "UserType",
  "kind": "LinkedField",
  "name": "createdBy",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Person",
      "kind": "LinkedField",
      "name": "person",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        (v6/*: any*/),
        (v7/*: any*/)
      ],
      "storageKey": null
    },
    (v3/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StatusSidebar_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "args": [
              {
                "kind": "Variable",
                "name": "programPrefix",
                "variableName": "programPrefix"
              },
              {
                "kind": "Variable",
                "name": "projectId",
                "variableName": "projectId"
              }
            ],
            "kind": "FragmentSpread",
            "name": "StatusSidebar_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StatusSidebar_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "internalId",
                "variableName": "projectId"
              }
            ],
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectColor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isInProjectGroup",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "companyProjectId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectStatus",
                "kind": "LinkedField",
                "name": "currentProjectStatus",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v5/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v13/*: any*/),
                "concreteType": "ProjectStatusTypeConnection",
                "kind": "LinkedField",
                "name": "projectStatuses",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectStatusTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectStatus",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v12/*: any*/),
                          (v11/*: any*/),
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hour",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "minute",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "second",
                            "storageKey": null
                          },
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v5/*: any*/),
                          (v14/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v16/*: any*/)
                ],
                "storageKey": "projectStatuses(first:10000)"
              },
              {
                "alias": null,
                "args": (v13/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "Project_projectStatuses",
                "kind": "LinkedHandle",
                "name": "projectStatuses"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "prefix",
                "variableName": "programPrefix"
              }
            ],
            "concreteType": "ProgramType",
            "kind": "LinkedField",
            "name": "program",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "prefix",
                "storageKey": null
              },
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectServiceProgramStatusType",
                "kind": "LinkedField",
                "name": "currentProgramStatus",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v13/*: any*/),
                "concreteType": "ProjectServiceProgramStatusTypeConnection",
                "kind": "LinkedField",
                "name": "programStatuses",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectServiceProgramStatusTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectServiceProgramStatusType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v18/*: any*/),
                          (v19/*: any*/),
                          (v9/*: any*/),
                          (v17/*: any*/),
                          (v14/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v16/*: any*/)
                ],
                "storageKey": "programStatuses(first:10000)"
              },
              {
                "alias": null,
                "args": (v13/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "Program_programStatuses",
                "kind": "LinkedHandle",
                "name": "programStatuses"
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "StatusSidebar_Query",
    "operationKind": "query",
    "text": "query StatusSidebar_Query(\n  $projectId: ID\n  $programPrefix: String\n) {\n  viewer {\n    actualPersonId\n    component(name: \"status_sidebar\")\n    ...StatusSidebar_viewer_4bGfr2\n    id\n  }\n}\n\nfragment StatusSidebar_viewer_4bGfr2 on Viewer {\n  firstName\n  project(internalId: $projectId) {\n    id\n    name\n    projectColor\n    isInProjectGroup\n    companyProjectId\n    currentProjectStatus {\n      id\n      color\n      ...StatusWithDescription_projectStatus\n    }\n    projectStatuses(first: 10000) {\n      edges {\n        node {\n          id\n          year\n          month\n          day\n          hour\n          minute\n          second\n          ...StatusWithDescription_projectStatus\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n  program(prefix: $programPrefix) {\n    id\n    prefix\n    name\n    color\n    currentProgramStatus {\n      id\n      ragStatus\n      ...StatusWithDescription_programStatus\n    }\n    programStatuses(first: 10000) {\n      edges {\n        node {\n          id\n          createdAt\n          ...StatusWithDescription_programStatus\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n}\n\nfragment StatusWithDescription_programStatus on ProjectServiceProgramStatusType {\n  id\n  createdAt\n  createdBy {\n    person {\n      id\n      fullName\n      profilePictureId\n    }\n    id\n  }\n  description\n  ragStatus\n}\n\nfragment StatusWithDescription_projectStatus on ProjectStatus {\n  id\n  person {\n    id\n    fullName\n    profilePictureId\n    profilePictureDefaultId\n  }\n  description\n  color\n  day\n  month\n  year\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a67d4946e23c674c3de020219ca002a5';

module.exports = node;
