import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import ReactDOM from 'react-dom';
// import CustomScrollDiv from '../../../custom_scroll_div';
import ForecastTooltip from '../../tooltips/ForecastTooltip';
import {trackEvent} from '../../../../../tracking/amplitude/TrackingV2';
import {isEqual} from 'lodash';
import {Button as DsButton} from '@forecast-it/design-system';

const optionVisitor = (option, callback, parentPath) => {
	const path = parentPath ? `${parentPath}.${option.name}` : option.name;

	callback(option, path);
	if (option.nestedOptions) {
		option.nestedOptions.forEach(nestedOption => {
			if (nestedOption !== undefined && nestedOption !== null) {
				optionVisitor(nestedOption, callback, path);
			}
		});
	}
};

const optionTraverser = (options, callback) => {
	options &&
		options.forEach(option => {
			if (option !== undefined && option !== null) {
				optionVisitor(option, callback);
			}
		});
};

// Need to compare with previous options to prevent tracking same updates multiple times due to JS equality fuckery
const optionsChanged = (eyeOptions, prevEyeOptions) => {
	const options = [];
	optionTraverser(eyeOptions, (option, path) => {
		if (!option.hide) {
			options.push(path);
		}
	});
	options.sort();

	const prevOptions = [];
	optionTraverser(prevEyeOptions, (option, path) => {
		if (!option.hide) {
			prevOptions.push(path);
		}
	});
	prevOptions.sort();

	return !isEqual(options, prevOptions);
};

export const trackEyeSelector = (selectedOption, eyeOptions, prevEyeOptions) => {
	if (eyeOptions) {
		try {
			const shown = [];
			optionTraverser(eyeOptions, (option, path) => {
				if (option.checked && !option.hide) {
					shown.push(path);
				}
			});

			const didChange = optionsChanged(eyeOptions, prevEyeOptions);

			if (didChange) {
				const trackingProperties = {
					selectedOptions: shown,
					count: shown.length,
				};

				if (selectedOption) {
					trackingProperties.selectedOptionName = selectedOption.name;
					trackingProperties.selectedOptionChecked = !selectedOption?.checked;
				}

				// if no selectedOption is passed along, assume this is a Load event
				trackEvent('Eye Options', !selectedOption ? 'Loaded' : 'Selected', trackingProperties);
			}
		} catch (e) {
			// eslint-disable-next-line no-console
			console.warn('Suppress error, to not break the page because of tracking', e);
		}
	}
};

class TheEye extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: false,
			expandedTopLevelOption: null,
			expandedSecondLevelOption: null,
			offset: null,
			eyeOptions: null,
			nonSortedEyeOptions: null,
		};

		this.collapseEye = this.collapseEye.bind(this);
		this.onWindowResize = this.onWindowResize.bind(this);
	}

	componentDidMount() {
		this.updateEyeOptions(this.props.options);

		if (!this.props.ignoreScroll) {
			document.addEventListener('scroll', this.collapseEye, true);
		}

		window.addEventListener('resize', this.onWindowResize);
		if (this.expand_btn) {
			this.setState({
				offset: this.expand_btn.getBoundingClientRect(),
			});
		}
	}

	componentDidUpdate() {
		this.updateEyeOptions(this.props.options);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.onWindowResize);
		if (!this.props.ignoreScroll) {
			document.removeEventListener('scroll', this.collapseEye, true);
		}
	}

	toggleExpand() {
		this.setState({expanded: !this.state.expanded});
		if (this.expand_btn) {
			this.setState({
				offset: this.expand_btn.getBoundingClientRect(),
			});
		}
	}

	collapseEye() {
		if (this.state.expanded) {
			this.setState({expanded: false});
		}
	}

	onWindowResize() {
		if (this.state.expanded && this.expand_btn) {
			this.setState({
				offset: this.expand_btn.getBoundingClientRect(),
			});
		}
	}

	hasVisibleNestedOptions(option) {
		return option.nestedOptions && option.nestedOptions.find(nestedOption => !nestedOption.hide);
	}

	handleOptionClick(option, parentOption, grandParentOption, e) {
		e.stopPropagation();
		if (!option.disabled) {
			// added functionality to prevent duplicate logic in every page that uses this component
			// create a deep copy of the options before changing it to keep the component compatible with older implementations
			let newOptions = JSON.parse(JSON.stringify(this.state.eyeOptions));
			newOptions.map(currentOption => {
				// if the options have 3 layers
				if (grandParentOption) {
					// if clicking on a grandchild - lowest level in dropdown
					if (currentOption.name === grandParentOption.name) {
						currentOption.nestedOptions.forEach(parentOpt => {
							if (parentOpt && parentOpt.name === parentOption.name) {
								parentOpt.nestedOptions.forEach(childOption => {
									if (childOption && childOption.name === option.name) {
										childOption.checked = !childOption.checked;
									}
								});
								// if all child options are not checked, uncheck the parent, but filters the options that are hidden
								parentOpt.checked = !parentOpt.nestedOptions
									.filter(op => op !== null)
									.every(childOption => childOption && !childOption.checked);
							}
						});
						// if all child options are not checked, uncheck the parent, but filters the options that are hidden
						currentOption.checked = !currentOption.nestedOptions
							.filter(op => op !== null)
							.every(parentOption => parentOption && !parentOption.checked);
					}
				} else if (parentOption) {
					// if clicking on a child
					if (currentOption.name === parentOption.name) {
						currentOption.nestedOptions.forEach(childOption => {
							if (childOption && childOption.name === option.name) {
								childOption.checked = !childOption.checked;
								if (childOption.nestedOptions) {
									childOption.nestedOptions.forEach(grandCOption => {
										grandCOption.checked = childOption.checked;
									});
								}
							}
						});
						// if all child options are not checked, uncheck the parent, but filters the options that are hidden
						currentOption.checked = !currentOption.nestedOptions
							.filter(op => op !== null)
							.every(childOption => childOption && !childOption.checked);
					}
				} else if (option) {
					// if clicking on a parent
					if (currentOption.name === option.name) {
						currentOption.checked = !currentOption.checked;
						// propagate change to all children and grandchildren
						if (currentOption.nestedOptions) {
							currentOption.nestedOptions.forEach(childOption => {
								// options can be null
								if (childOption) {
									childOption.checked = currentOption.checked;
									if (childOption.nestedOptions) {
										childOption.nestedOptions.forEach(grandCOption => {
											if (grandCOption) {
												grandCOption.checked = currentOption.checked;
											}
										});
									}
								}
							});
						}
					}
				}

				return currentOption;
			});

			// had to keep sending the option, parentOption and grandParentOption because of backwards compatibility.
			// 			some places have their own logic which should be removed
			this.props.onSelect(option, parentOption, grandParentOption, newOptions);

			trackEyeSelector(option, newOptions);
		}
		this.expand_btn.focus();
	}

	showNestedOptions(option, isTopLevel, e) {
		e.stopPropagation();
		const optionName = option.name;
		const stateObject = {};
		if (isTopLevel) {
			stateObject.expandedTopLevelOption = optionName;
		} else {
			stateObject.expandedSecondLevelOption = optionName;
		}
		this.setState(stateObject);
	}

	hideNestedOptions(option, isTopLevel, e) {
		e.stopPropagation();
		const stateObject = {};
		if (isTopLevel) {
			stateObject.expandedTopLevelOption = null;
			stateObject.expandedSecondLevelOption = null;
		} else {
			stateObject.expandedSecondLevelOption = null;
		}
		this.setState(stateObject);
	}

	handleBlur(e) {
		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11
		if (
			(newTarget &&
				((newTarget.id && newTarget.id.includes('card-layout-list-option')) ||
					newTarget.id === 'card-layout-list-option-collapse-button')) ||
			newTarget.id === 'expand-button'
		)
			return;
		this.setState({expanded: false});
	}

	handleKeyDown(option, parentOption, index, nestedIndex, e) {
		if (this.state.expanded) {
			if (e.keyCode === 27) {
				// Escape
				this.setState({expanded: false});
				return;
			} else if ((e.keyCode === 32 || e.keyCode === 13) && option) {
				// Enter /Space
				if (!option.disabled) {
					this.handleOptionClick(option, parentOption);
				}
			} else if (e.keyCode === 40) {
				//Down arrow
				let elementToFocus = null;
				if (!option) {
					elementToFocus = 'card-layout-list-option0';
				} else if (this.hasVisibleNestedOptions(option)) {
					//first nested option of currently focused element
					elementToFocus = 'card-layout-list-option-nested' + index;
				} else if (parentOption && nestedIndex !== parentOption.nestedOptions.length - 1) {
					// not last nested option is currently focused
					elementToFocus = 'card-layout-list-option-nested' + (index + nestedIndex + 1);
				} else {
					//top level option or last nested option
					elementToFocus = 'card-layout-list-option' + (index + 1);
				}
				if (elementToFocus) {
					const domNode = ReactDOM.findDOMNode(this[elementToFocus]);
					if (domNode) {
						domNode.focus();
					}
				}
				return;
			} else if (e.keyCode === 38) {
				//Up arrow
				let elementToFocus = null;
				if (e.altKey) {
					this.setState({expanded: false});
					return;
				} else if (index === 0 && !parentOption) {
					return;
				} else if (parentOption && nestedIndex !== 0) {
					//not first nested option. Focus previous one
					elementToFocus = 'card-layout-list-option-nested' + (index + nestedIndex - 1);
				} else if (parentOption && nestedIndex === 0) {
					//focus parent if first nested option
					elementToFocus = 'card-layout-list-option' + index;
				} else if (!parentOption) {
					//parent element focused. Check if previous option has nested options. If it does focus last one if not focus previous top option
					let previousOption = null;
					this.state.eyeOptions.forEach((o, i) => {
						if (i === index - 1) {
							previousOption = o;
						}
					});
					if (!previousOption) {
						return;
					}
					if (previousOption.nestedOptions) {
						elementToFocus =
							'card-layout-list-option-nested' + (index - 1 + (previousOption.nestedOptions.length - 1));
					} else {
						elementToFocus = 'card-layout-list-option' + (index - 1);
					}
				}
				if (elementToFocus) {
					const domNode = ReactDOM.findDOMNode(this[elementToFocus]);
					if (domNode) {
						domNode.focus();
					}
				}
			}
		} else if ((e.keyCode === 40 && e.altKey) || e.keyCode === 32 || e.keyCode === 13) {
			this.setState({expanded: true});
			return;
		}
	}

	getListHTML() {
		const html = [];
		this.state.eyeOptions.forEach((option, index) => {
			if (option && !option.hide && (option.translationId || option.displayName)) {
				html.push(
					<li
						data-cy-class="option"
						data-cy={'eye-option-' + option.name}
						id={`card-layout-list-option${index}`}
						ref={li => (this['card-layout-list-option' + index] = li)}
						key={option.name}
						className={
							(option.disabled ? 'disabled ' : '') +
							(this.hasVisibleNestedOptions(option) ? 'nested-li ' : '') +
							(index === 0 ? 'first' : option.name === 'progress-bar' ? 'last' : '')
						}
						onClick={this.handleOptionClick.bind(this, option, null, null)}
						onBlur={this.handleBlur.bind(this)}
						tabIndex={0}
						autoFocus={index === 1}
						onKeyDown={this.handleKeyDown.bind(this, option, null, index, null)}
						onMouseEnter={this.showNestedOptions.bind(this, option, true)}
						onMouseLeave={this.hideNestedOptions.bind(this, option, true)}
					>
						<div className="name-expand-wrapper">
							<div className="name">
								{!!option.translationId &&
									this.props.intl.formatMessage({id: option.translationId}, {suffix: option.suffix}) +
										(option.name && option.name.includes('xero') ? ' (Xero)' : '')}
								{!!option.displayName && option.displayName}
							</div>
							{option.checked === undefined ? null : (
								<div
									className={'visible-state-icon' + (option.checked ? ' selected' : '')}
									data-cy={'eye-option-state-icon-' + option.name}
								/>
							)}
							{this.hasVisibleNestedOptions(option) ? (
								<button
									className={
										'expand-nested-options-button' +
										(this.state[option.name + 'expanded'] ? ' expanded' : ' collapsed')
									}
									id="card-layout-list-option-collapse-button"
									onBlur={this.handleBlur.bind(this)}
								/>
							) : null}
						</div>

						{this.hasVisibleNestedOptions(option) && this.state.expandedTopLevelOption === option.name ? (
							<ul
								className={`board-card-details-selector-nested-list ${
									option.nestedOptions?.some(o => o && o.nestedOptions) ? ' has-nested-elements' : ''
								} `}
							>
								{option.nestedOptions &&
									option.nestedOptions
										.filter(nestedOption => nestedOption !== null && !nestedOption.hide)
										.map((nestedOption, nestedIndex) => (
											<li
												id={`card-layout-list-option-nested${nestedIndex}`}
												data-cy-class="option"
												data-cy={'eye-option-' + nestedOption.name}
												ref={li =>
													(this['card-layout-list-option-nested' + (index + nestedIndex)] = li)
												}
												key={nestedOption.name}
												className={
													'nested-option' +
													(nestedOption.disabled ? ' disabled' : '') +
													(nestedOption.nestedOptions ? ' nested-li ' : '')
												}
												onClick={this.handleOptionClick.bind(this, nestedOption, option, null)}
												onBlur={this.handleBlur.bind(this)}
												tabIndex={0}
												onKeyDown={this.handleKeyDown.bind(
													this,
													nestedOption,
													option,
													index,
													nestedIndex
												)}
												onMouseEnter={this.showNestedOptions.bind(this, nestedOption, false)}
												onMouseLeave={this.hideNestedOptions.bind(this, nestedOption, false)}
											>
												<div className="name-expand-wrapper">
													<div className="name">
														{!!nestedOption.translationId &&
															this.props.intl.formatMessage(
																{id: nestedOption.translationId},
																{suffix: nestedOption.suffix}
															)}
														{!!nestedOption.displayName && nestedOption.displayName}
													</div>
													{nestedOption.checked === undefined ? null : (
														<div
															className={
																'visible-state-icon' + (nestedOption.checked ? ' selected' : '')
															}
															data-cy={'eye-option-state-icon-' + option.name}
														/>
													)}
													{this.hasVisibleNestedOptions(nestedOption) ? (
														<button
															className={
																'expand-nested-options-button' +
																(this.state[nestedOption.name + 'expanded']
																	? ' expanded'
																	: ' collapsed')
															}
															id="card-layout-list-option-collapse-button"
															onBlur={this.handleBlur.bind(this)}
														/>
													) : null}
												</div>

												{this.hasVisibleNestedOptions(nestedOption) &&
												this.state.expandedSecondLevelOption === nestedOption.name ? (
													<ul className="board-card-details-selector-nested-list">
														{nestedOption.nestedOptions &&
															nestedOption.nestedOptions
																.filter(nestedOption2 => nestedOption2 !== null)
																.map((nestedOption2, nestedIndex) => (
																	<li
																		id={`card-layout-list-option-nested${nestedIndex}`}
																		ref={li =>
																			(this[
																				'card-layout-list-option-nested' +
																					(index + nestedIndex)
																			] = li)
																		}
																		key={nestedOption2.name}
																		className={
																			'second-nested-option' +
																			(nestedOption2.disabled ? ' disabled' : '')
																		}
																		onClick={this.handleOptionClick.bind(
																			this,
																			nestedOption2,
																			nestedOption,
																			option
																		)}
																		onBlur={this.handleBlur.bind(this)}
																		tabIndex={0}
																		onKeyDown={this.handleKeyDown.bind(
																			this,
																			nestedOption2,
																			option,
																			index,
																			nestedIndex
																		)}
																	>
																		<div className="name-expand-wrapper">
																			<div className="name">
																				{this.props.intl.formatMessage(
																					{id: nestedOption2.translationId},
																					{suffix: nestedOption2.suffix}
																				)}
																			</div>
																			<div
																				className={
																					'visible-state-icon' +
																					(nestedOption2.checked ? ' selected' : '')
																				}
																				data-cy={'eye-option-state-icon-' + option.name}
																			/>
																		</div>
																	</li>
																))}
													</ul>
												) : null}
											</li>
										))}
							</ul>
						) : null}
					</li>
				);
			}
		});
		return html;
	}

	/**
	 * Update eye options
	 * @param options
	 */
	updateEyeOptions(options) {
		if (!options) return;

		if (!isEqual(this.state.nonSortedEyeOptions, options)) {
			trackEyeSelector(null, options, this.state.nonSortedEyeOptions);

			this.setState({nonSortedEyeOptions: options});
			const sortedOptions = options ? [...options] : [];

			this.sortOptionsByName(sortedOptions);
			this.setState({eyeOptions: sortedOptions});
		}
	}

	/**
	 * Nested Sort of options
	 * @param options
	 */
	sortOptionsByName(options) {
		options.forEach(o => {
			if (o && o.nestedOptions) {
				this.sortOptionsByName(o.nestedOptions);
			}
		});

		options.sort((a, b) => {
			if (!a || !b) return 0;
			var nameA = (
				a.translationId
					? this.props.intl.formatMessage({id: a.translationId}, {suffix: a.suffix})
					: a.displayName || a.name
			).toUpperCase(); // ignore upper and lowercase
			var nameB = (
				b.translationId
					? this.props.intl.formatMessage({id: b.translationId}, {suffix: b.suffix})
					: b.displayName || b.name
			).toUpperCase(); // ignore upper and lowercase
			nameA = a.sortOrder ? a.sortOrder : nameA;
			nameB = b.sortOrder ? b.sortOrder : nameB;
			if (nameA < nameB) {
				return -1;
			} else if (nameA > nameB) {
				return 1;
			} else {
				// names must be equal
				return 0;
			}
		});
	}

	render() {
		const hasNested = this.props.options?.some(o => o.nestedOptions);
		const style = {};
		if (this.state.offset) {
			//if (this.offset.top + 520 > window.innerHeight) {
			//	style.bottom = window.innerHeight - this.offset.top + this.offset.height;
			//} else {
			style.top = this.state.offset.top + this.state.offset.height + 5;
			//}
			style.left = this.state.offset.left - (this.props.expandLeft ? (hasNested ? 213 : 183) : 0);
			style.position = 'absolute';
		}

		return (
			<div className={this.props.useDesignSystemButton ? '' : 'board-card-details-selector'}>
				<ForecastTooltip
					delay={[200, 0]}
					disabled={this.state.expanded || this.props.disableTooltip}
					content={this.props.intl.formatMessage({id: 'icon_hover_text.eye'})}
				>
					{this.props.useDesignSystemButton ? (
						<DsButton
							emphasis={'low'}
							icon={'view'}
							tabIndex="0"
							data-cy={'eye-selector'}
							data-userpilot={'eye-selector'}
							onClick={this.toggleExpand.bind(this)}
							onBlur={this.handleBlur.bind(this)}
							onKeyDown={this.handleKeyDown.bind(this, null, null, null, null)}
							ref={btn => (this.expand_btn = btn)}
						/>
					) : (
						<div
							tabIndex="0"
							data-cy={'eye-selector'}
							data-userpilot={'eye-selector'}
							onClick={this.toggleExpand.bind(this)}
							className={'expand-button' + (this.state.expanded ? ' expanded' : ' collapsed')}
							onBlur={this.handleBlur.bind(this)}
							onKeyDown={this.handleKeyDown.bind(this, null, null, null, null)}
							id={'expand-button'}
							ref={btn => (this.expand_btn = btn)}
						/>
					)}
				</ForecastTooltip>
				{this.state.expanded
					? ReactDOM.createPortal(
							<ul
								data-userpilot={'eye-selector-list'}
								data-cy={'eye-selector-list'}
								style={style}
								className={
									'board-card-details-selector-list' +
									(this.props.expandLeft ? ' expand-left' : '') +
									(this.props.openRight ? ' open-right' : '') +
									(hasNested ? ' has-nested-elements' : '')
								}
							>
								{this.getListHTML()}
							</ul>,
							document.querySelector('#root-portal-container')
					  )
					: null}
			</div>
		);
	}
}

export default injectIntl(TheEye);
