import React, {Component} from 'react';
import Util from '../../util/util';
import {injectIntl} from 'react-intl';
import GooglePicker from '../google-picker/google_picker';
import CreateGoogleDriveFileAttachmentMutation from '../../../../mutations/create_google_drive_file_attachment';

class DraftJSUploadButtonWithGdrive extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showPlusButton: this.props.showPlusButton ? this.props.showPlusButton : false,
		};
	}

	uploadFilesGoogleDrive(data) {
		if (data && data.docs) {
			data.docs.forEach(doc => {
				const input = {
					taskId: this.props.taskId,
					personId: this.props.personId,
					googleDriveId: doc.id,
					name: doc.name,
					link: doc.url,
					thumb: doc.iconUrl.replace('/16/', '/64/'),
				};
				Util.CommitMutation(CreateGoogleDriveFileAttachmentMutation, input);
			});
		}
	}

	render() {
		const {formatMessage} = this.props.intl;

		return (
			<div className={this.state.showPlusButton ? 'with-plus' : ''}>
				{this.state.showPlusButton ? <div className="add-file" /> : null}
				<div className="add-file-gdrive">
					<div
						tabIndex="-1"
						onClick={() => (this.props.fileInput ? this.props.fileInput.click() : null)}
						className="upload-file-button upload-from-computer"
					>
						<label>
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14">
								<path
									fill="#292929"
									d="M1.333 10h13.332v-.667H1.333V10zm13.334-8.667L14.665 8H1.333V1.333h13.334zm0-1.333H1.333C.598 0 0 .598 0 1.333V10c0 .735.598 1.333 1.333 1.333h6v1.334H4.667V14h6.666v-1.333H8.667v-1.334h6c.735 0 1.333-.598 1.333-1.333V1.333C16 .598 15.402 0 14.667 0z"
								/>
							</svg>
							{formatMessage({id: 'common.computer'})}
						</label>
					</div>
					<div className="upload-file-button">
						<label>
							<GooglePicker
								className="upload-options-gdrive"
								onChange={this.uploadFilesGoogleDrive.bind(this)}
								multiselect={true}
							>
								<svg xmlns="http://www.w3.org/2000/svg" width="15" height="13" viewBox="0 0 15 13">
									<path
										fill="#292929"
										d="M11.563 11.934H3.561l1.511-2.77H13.2l-1.636 2.77zm-6.36-10.35l1.421 2.51-2.33 4.27c0 .004-.003.005-.005.008l-1.62 2.915-1.528-3.056 4.063-6.648zM8.89 8.097H5.654l1.588-2.912L8.89 8.098zm.286-7.032l4.072 7.032h-3.134l-3.98-7.032h3.042zm5.457 7.298L9.945.266A.533.533 0 0 0 9.483 0H5.221c-.012 0-.024.004-.036.005a.515.515 0 0 0-.336.148c-.01.01-.018.023-.028.034-.019.022-.039.043-.054.068L.078 7.927a.533.533 0 0 0-.022.516L2.1 12.53a.534.534 0 0 0 .066.208c.096.163.27.263.459.263h9.244c.188 0 .362-.1.458-.262l2.185-3.702a.52.52 0 0 0 .123-.139.532.532 0 0 0 0-.533z"
									/>
								</svg>
								G Drive
							</GooglePicker>
						</label>
					</div>
				</div>
			</div>
		);
	}
}

export default injectIntl(DraftJSUploadButtonWithGdrive);
