import React from 'react';
import {DeprecatedCreativeModal as CreativeModal, DeprecatedText as Text} from '@forecast-it/design-system';
import {FormattedHTMLMessage, useIntl} from 'react-intl';
import Util from '../../shared/util/util';
import UpdateCompanyMutation from '../../../mutations/update_company_mutation.modern';
import {COMPANY_SETUP, setCompanySetting} from '../../shared/util/CompanySetupUtil';

const ToggleRevenueModal = ({closeModal, company}) => {
	const {formatMessage} = useIntl();

	const wantToEnable = !company.revenueEnabled;

	const persistRevenueEnabledSetting = () => {
		Util.CommitMutation(
			UpdateCompanyMutation,
			{
				id: company.id,
				revenueEnabled: wantToEnable,
			},
			() => {
				setCompanySetting(COMPANY_SETUP.REVENUE_ENABLED, wantToEnable);
				closeModal();
			}
		);
	};

	return (
		<CreativeModal
			closeModal={closeModal}
			creativeButtonText={formatMessage({id: wantToEnable ? 'common.turn_on_feature' : 'common.turn_off_feature'})}
			onCreateHandler={() => persistRevenueEnabledSetting(false)}
			title={formatMessage({id: wantToEnable ? 'common.turn_on_this_feature' : 'common.turn_off_this_feature'})}
		>
			<Text>
				<FormattedHTMLMessage
					id={
						wantToEnable
							? 'settings.setup.revenue_tracking.enable_confirmation_text'
							: 'settings.setup.revenue_tracking.disable_confirmation_text'
					}
				/>
			</Text>
		</CreativeModal>
	);
};

export default ToggleRevenueModal;
