import {Dropdown} from 'web-components';
import React from 'react';
import _ from 'lodash';
import {useIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import SkillBadge from '../../skills/skill_badge';
import {SkillsPreview} from './SkillDropdown.styled';
import {ShowMore} from './ShowMore';
import {SkillLevelItem} from './SkillLevelItem';
import {SkillLine} from './SkillLine';

const SkillDropdown = ({
	name,
	company,
	selectedItems,
	onSelect,
	onRemove,
	headerLines,
	emphasizeEmptyState,
	disabled,
	usePortal,
	width,
	dropdownAlignment,
	selectedGroupName,
	isClearable = false,
	showSkillsBelow,
	selectLevels = false,
	multiSelect = false,
}) => {
	const {formatMessage} = useIntl();
	const skills = company.skills.edges.map(edge => edge.node);
	const skillById = _.keyBy(skills, skill => skill.id);
	const {skillLevelsEnabled, skillLevels} = company;
	const skillLevelById = _.keyBy(skillLevels, skillLevel => skillLevel.id);
	const noCategory = formatMessage({id: 'settings_skills.no_category'});
	const skillCategories = [
		...company.skillCategories.edges.map(edge => edge.node),
		{
			id: undefined,
			name: noCategory,
		},
	];
	const skillsByCategoryId = _.groupBy(skills, skill => skill.category?.id);

	const getSearchString = skill => [skill.name, skill.category?.name].map(string => string?.toLowerCase()).join(' ');

	const addSkillLevel = (skillId, skillLevelId) => {
		const selectedLevels = selectedItems.filter(sp => sp.skillId === skillId).map(sp => sp.skillLevelId);
		if (multiSelect || selectedLevels.length === 0) {
			let updatedSelections;
			const allSelected =
				skillLevelId === null ||
				skillLevels.every(level => level.id === skillLevelId || selectedLevels.includes(level.id));

			if (allSelected) {
				// Combine all separate skill level selections to one combined skill selection (level = null)
				updatedSelections = [...selectedItems.filter(sp => sp.skillId !== skillId), {skillId}];
			} else {
				updatedSelections = [...selectedItems, {skillId, skillLevelId}];
			}
			onSelect([{skillId, skillLevelId}], updatedSelections);
		}
	};

	const removeSkillLevel = (skillId, skillLevelId) => {
		let updatedSelections;
		if (skillLevelId) {
			const allLevelsSelected = selectedItems.some(sp => sp.skillId === skillId && !sp.skillLevelId);
			if (allLevelsSelected) {
				// Split combined skill selection (level = null), to separate skill level selections without the removed level.
				updatedSelections = [
					...selectedItems.filter(sp => sp.skillId !== skillId),
					...skillLevels.filter(sl => sl.id !== skillLevelId).map(sl => ({skillId, skillLevelId: sl.id})),
				];
			} else {
				updatedSelections = selectedItems.filter(sp => sp.skillId !== skillId || sp.skillLevelId !== skillLevelId);
			}
		} else {
			updatedSelections = selectedItems.filter(sp => sp.skillId !== skillId);
		}
		onRemove([{skillId, skillLevelId}], updatedSelections);
	};

	const onSkillRemove = removedSkillIds => {
		const updatedSelections = selectedItems.filter(sp => sp.skillId !== removedSkillIds[0]);
		onRemove(removedSkillIds, updatedSelections);
	};
	const onSkillSelect = addedSkillIds => {
		addSkillLevel(addedSkillIds[0], null);
	};

	const skillsPreview = showSkillsBelow && !!selectedItems?.length && (
		<SkillsPreview>
			<ShowMore>
				{selectedItems.map(sp => {
					const skill = skillById[sp.skillId];
					const level = skillLevelById[sp.skillLevelId];
					return (
						<SkillBadge
							key={skill.id + level?.level}
							skill={skill}
							skillLevel={level?.level}
							onSkillRemove={() => removeSkillLevel(skill.id, level?.id)}
						/>
					);
				})}
			</ShowMore>
		</SkillsPreview>
	);

	const showSelectionCount = skillLevelsEnabled && multiSelect;

	return (
		<>
			<Dropdown
				isClearable={isClearable}
				isNested
				isMultiSelect
				headerLines={headerLines}
				hideEmptyGroups
				usePortal={usePortal}
				selectedGroupName={multiSelect ? null : selectedGroupName}
				name={name}
				onSelect={onSkillSelect}
				onRemove={onSkillRemove}
				selectedItems={multiSelect ? [] : selectedItems.map(item => item.skillId || item)}
				cy={'skill-category-skills'}
				emphasizeEmptyState={emphasizeEmptyState}
				disabled={disabled}
				width={width}
				dropdownAlignment={dropdownAlignment}
			>
				{skillCategories.map(skillCategory => (
					<Dropdown.Group
						name={skillCategory.name}
						key={`skill-category-${skillCategory.id}`}
						initiallyCollapsed
						hideCount={showSelectionCount}
						infoText={
							showSelectionCount &&
							(skillsByCategoryId[skillCategory.id]
								?.filter(skill => selectedItems.map(item => item.skillId).includes(skill.id))
								.length.toString() ||
								'0')
						}
					>
						{skillsByCategoryId[skillCategory.id]?.map(skill =>
							selectLevels ? (
								<SkillLevelItem
									key={skill.id}
									value={skill.id}
									searchString={getSearchString(skill)}
									cy={`skill:${skill.name}`}
									name={skill.name}
									skillLevels={skillLevels}
									selectedLevels={selectedItems
										.filter(sl => sl.skillId === skill.id)
										.map(sl => sl.skillLevelId)}
									onSelect={skillLevelId => addSkillLevel(skill.id, skillLevelId)}
									onRemove={skillLevelId => removeSkillLevel(skill.id, skillLevelId)}
									multiSelect={multiSelect}
									noPadding
								/>
							) : (
								<SkillLine
									key={skill.id}
									value={skill.id}
									searchString={getSearchString(skill)}
									cy={`skill:${skill.name}`}
									name={skill.name}
									category={skill.category}
								/>
							)
						)}
					</Dropdown.Group>
				))}
			</Dropdown>
			{skillsPreview}
		</>
	);
};

export default createFragmentContainer(SkillDropdown, {
	company: graphql`
		fragment SkillDropdown_company on Company {
			skillLevelsEnabled
			skillLevels {
				id
				level
				description
			}
			skills(first: 10000) @connection(key: "Company_skills") {
				edges {
					node {
						id
						name
						category {
							id
							name
						}
					}
				}
			}
			skillCategories(first: 10000) @connection(key: "Company_skillCategories") {
				edges {
					node {
						id
						name
					}
				}
			}
		}
	`,
});
