import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FlexColumn, Modal, Text } from '@forecast-it/design-system';
import { useRouterPrompt } from '../../../shared/hooks/useRouterPrompt';
export const SaveReportPrompt = ({ hasChanges, onSave, onSaveAs, onDiscard, }) => {
    const { showPrompt, handleSave, handleSaveAs, handleDiscard, closePrompt } = useRouterPrompt({
        hasChanges,
        onSave,
        onSaveAs,
        onDiscard,
        ignoreChildPaths: true,
    });
    return showPrompt ? (React.createElement(Modal, { onCloseModal: handleDiscard },
        React.createElement(Modal.Title, null,
            React.createElement(FormattedMessage, { id: 'common.save_changes' })),
        React.createElement(Modal.Content, null,
            React.createElement(FlexColumn, null,
                React.createElement(Text, null,
                    React.createElement(FormattedMessage, { id: 'common.save_changes_text' })))),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { onPress: () => {
                    closePrompt();
                    handleSave();
                } },
                React.createElement(FormattedMessage, { id: 'common.save' })),
            onSaveAs && (React.createElement(Modal.SecondaryFooterButton, { onPress: () => {
                    closePrompt();
                    handleSaveAs();
                } },
                React.createElement(FormattedMessage, { id: 'saved_report.save_as' }))),
            React.createElement(Modal.TertiaryFooterButton, { onPress: handleDiscard },
                React.createElement(FormattedMessage, { id: 'common.discard' }))))) : null;
};
