/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type github_type = "branch" | "issue" | "pullrequest" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type githubAttachModal_viewer$ref: FragmentReference;
declare export opaque type githubAttachModal_viewer$fragmentType: githubAttachModal_viewer$ref;
export type githubAttachModal_viewer = {|
  +id: string,
  +task: ?{|
    +id: string,
    +githubAttachedObjects: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: ?string,
          +number: ?string,
          +title: ?string,
          +repo: ?string,
          +url: ?string,
          +createdBy: ?string,
          +type: ?github_type,
          +status: ?string,
          +state: ?string,
          +base: ?string,
          +head: ?string,
          +name: ?string,
        |}
      |}>
    |},
    +project: ?{|
      +id: string
    |},
  |},
  +$refType: githubAttachModal_viewer$ref,
|};
export type githubAttachModal_viewer$data = githubAttachModal_viewer;
export type githubAttachModal_viewer$key = {
  +$data?: githubAttachModal_viewer$data,
  +$fragmentRefs: githubAttachModal_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "id",
      "type": "String"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "githubAttachModal_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "companyTaskId",
          "variableName": "id"
        }
      ],
      "concreteType": "Task",
      "kind": "LinkedField",
      "name": "task",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 1000000
            }
          ],
          "concreteType": "GithubAttachedObjectTypeConnection",
          "kind": "LinkedField",
          "name": "githubAttachedObjects",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "GithubAttachedObjectTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "GithubAttachedObject",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "number",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "title",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "repo",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "url",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "createdBy",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "type",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "status",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "state",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "base",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "head",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "githubAttachedObjects(first:1000000)"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectType",
          "kind": "LinkedField",
          "name": "project",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '50fa24c197a737ed32b39fbd0f136aad';

module.exports = node;
