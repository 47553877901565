/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type PersonsPreviewSelector_persons$ref: FragmentReference;
declare export opaque type PersonsPreviewSelector_persons$fragmentType: PersonsPreviewSelector_persons$ref;
export type PersonsPreviewSelector_persons = $ReadOnlyArray<{|
  +id: string,
  +fullName: ?string,
  +profilePictureId: ?string,
  +active: ?boolean,
  +permissions: ?$ReadOnlyArray<?string>,
  +role: ?{|
    +name: ?string
  |},
  +$refType: PersonsPreviewSelector_persons$ref,
|}>;
export type PersonsPreviewSelector_persons$data = PersonsPreviewSelector_persons;
export type PersonsPreviewSelector_persons$key = $ReadOnlyArray<{
  +$data?: PersonsPreviewSelector_persons$data,
  +$fragmentRefs: PersonsPreviewSelector_persons$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "PersonsPreviewSelector_persons",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profilePictureId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "active",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "permissions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Role",
      "kind": "LinkedField",
      "name": "role",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Person"
};
// prettier-ignore
(node/*: any*/).hash = 'ea8da9fae671533e2cab39916f27da77';

module.exports = node;
