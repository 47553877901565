import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { WorkSignifier } from '@forecast-it/design-system';
function withPrefix(companyProjectGroupId) {
    if (!companyProjectGroupId)
        return '';
    return `${'X'}${companyProjectGroupId}`;
}
const ProjectGroupIndicator = ({ projectGroup, outline }) => {
    if (!projectGroup) {
        return React.createElement(React.Fragment, null); // temp fix until we fix the recent project dropdown that shows empty projects
    }
    const workId = withPrefix(projectGroup.companyProjectGroupId);
    return (React.createElement(WorkSignifier, { workId: workId, name: projectGroup.name || undefined, color: projectGroup.color || '', outline: outline }));
};
// Exported raw component to reuse it in ProjectIndicatorJS that wraps it in a JS fragment (since JS and TS fragments are incompatible)
export { ProjectGroupIndicator as ProjectGroupIndicatorNoFragment };
export default createFragmentContainer(ProjectGroupIndicator, {
    projectGroup: graphql `
		fragment ProjectGroupIndicator_projectGroup on ProjectGroupType {
			name
			companyProjectGroupId
			color
		}
	`,
});
