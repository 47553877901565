import {Icon} from '@forecast-it/design-system';
import React from 'react';
import styled from 'styled-components';
import {NewTabIcon} from 'web-components';
import {StatusIndicator} from '../../../../../../../containers/project/project_health/project_health_status/StatusIndicator';

const NewTabIconWrapper = styled.div`
	display: none;
	align-items: center;
	margin-left: 8px;
`;

const CellWrapper = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	padding: 6px 8px;
	border-radius: 4px;
	&:hover,
	&:focus {
		background-color: #e7e7f3;
		${NewTabIconWrapper} {
			display: flex;
		}
	}
`;

const StatusCell = ({latestProjectStatus, ragStatus = null, onSelect}) => {
	const handleCellClick = () => {
		if (onSelect) {
			onSelect();
		}
	};

	const handleCellKeydown = e => {
		if (e.key === 'Enter' || e.key === ' ') {
			if (onSelect) {
				onSelect();
			}
		}
	};

	return (
		<CellWrapper
			title={`${latestProjectStatus} -> ${ragStatus}`}
			tabIndex={0}
			onClick={handleCellClick}
			onKeyDown={handleCellKeydown}
		>
			<StatusIndicator projectStatusColor={latestProjectStatus} fromRagStatus />
			<div style={{marginRight: '8px'}}>
				<Icon size={'m'} icon={'arrowRight'} />
			</div>
			<StatusIndicator projectStatusColor={ragStatus} fromRagStatus />
			<NewTabIconWrapper>
				<NewTabIcon size={NewTabIcon.SIZE.MEDIUM} />
			</NewTabIconWrapper>
		</CellWrapper>
	);
};

export default StatusCell;
