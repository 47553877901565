import React from 'react';

export const MoreActionsOutline = ({color, width, height}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 17 17">
		<g fill="none" fillRule="evenodd" transform="translate(0 1)">
			<rect width="14.625" height="14.625" x=".813" y=".559" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" rx="1.5" />
			<path
				fill={color}
				fillRule="nonzero"
				d="M8.1 3.9c.38 0 .693.274.743.629l.007.098V7.15h2.523c.401 0 .727.336.727.75 0 .38-.274.693-.629.743l-.098.007H8.85v2.523c0 .401-.336.727-.75.727-.38 0-.693-.274-.743-.629l-.007-.098V8.65H4.827c-.401 0-.727-.336-.727-.75 0-.38.274-.693.629-.743l.098-.007H7.35V4.627c0-.401.336-.727.75-.727z"
			/>
		</g>
	</svg>
);
