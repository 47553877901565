import {FormattedMessage, useIntl} from 'react-intl';
import DemoLineItem from './demo_line_item';
import React, {useState} from 'react';
import {MODAL_TYPE, showModal} from '../../../shared/components/modals/generic_modal_conductor';
import Warning from '../../../../components/warning';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../../constants';
import Util from '../../../shared/util/util';
import DeleteDemoDataMutation from '../../../../mutations/delete_demo_data_mutation';
import {createToast} from '../../../shared/components/toasts/toast';
import DeleteDemoMutation from '../../../../mutations/delete_demo_mutation';
import {createFragmentContainer, graphql} from 'react-relay';
import Styled from 'styled-components';
import {Text} from '../../../../Text.styled';
import DirectApi from '../../../../directApi';

export const Container = Styled(Text)`
	padding-top: 20px;
`;

const SettingsDemoData = ({viewer}) => {
	const {formatMessage} = useIntl();
	const [deletingDemoData, setDeletingDemoData] = useState(false);
	const demos = viewer.company.demos.edges;
	let demoProgressCheckTimeout = 0;

	const intervalProgressCheck = numberOfProjects => {
		DirectApi.Fetch('demo_projects_progress').then(res => {
			if (res.projects === 0) {
				clearInterval(demoProgressCheckTimeout);
				createToast({
					duration: 5000,
					message: formatMessage({id: 'settings_details.demo_generated_toast'}),
				});
			} else if (res.projects === -1) {
				clearInterval(demoProgressCheckTimeout);
				showModal({
					type: MODAL_TYPE.GENERIC,
					content: (
						<div className="default-warning-modal">
							<div className="warning-part-2">
								{formatMessage({id: 'settings_details.demo_generated_error_toast'}) +
									'. CorrelationId: ' +
									res.correlationId}
							</div>
						</div>
					),
					buttons: [
						{
							text: 'OK',
							style: BUTTON_STYLE.FILLED,
							color: BUTTON_COLOR.WHITE,
						},
					],
				});
			} else {
				if (res.projects === 1) {
					createToast({
						duration: 5000,
						message: `Restoring generic data`,
					});
				} else {
					createToast({
						duration: 5000,
						message: `Progress (${res.projects} project(s) remaining out of ${numberOfProjects})`,
					});
				}
			}
		});
	};

	const createDemoData = () => {
		showModal({
			type: MODAL_TYPE.SAVE_DEMO_ENVIRONMENT,
			companyId: viewer.company.id,
		});
	};

	const deleteDemoData = () => {
		const onSuccess = () => {
			setDeletingDemoData(false);
			createToast({
				duration: 5000,
				message: formatMessage({id: 'settings_details.demo_data_deleted'}),
			});
		};

		Util.CommitMutation(
			DeleteDemoDataMutation,
			{
				companyId: viewer.company.id,
			},
			onSuccess
		);
		setDeletingDemoData(true);
	};

	const generateDemoEnvironment = demo => {
		const callbackPositive = () => {
			const demoId = demo.id;

			DirectApi.Fetch_Raw(`activate_demo?demoId=${demoId}`);
			demoProgressCheckTimeout = setInterval(() => {
				intervalProgressCheck(demo.numberOfProjects);
			}, 3000);
		};

		showModal({
			type: MODAL_TYPE.GENERIC,
			content: (
				<div className="default-warning-modal">
					<Warning messageId="settings_details.generate_demo_environment" />
					<div className="warning-part-2">{formatMessage({id: 'settings_details.generate_demo_info'})}</div>
					<div className="warning-part-2">{formatMessage({id: 'settings_details.generate_demo_info_2'})}</div>
				</div>
			),
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.generate'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: callbackPositive,
				},
			],
		});
	};

	const deleteDemoEnvironment = demo => {
		const onSuccess = () => {
			createToast({
				duration: 5000,
				message: formatMessage({id: 'settings_details.demo_deleted_toast'}),
			});
		};
		const callbackPositive = () => {
			Util.CommitMutation(
				DeleteDemoMutation,
				{
					companyId: viewer.company.id,
					id: demo.id,
				},
				onSuccess
			);
		};

		showModal({
			type: MODAL_TYPE.GENERIC,
			content: (
				<div className="default-warning-modal">
					<Warning messageId="settings_details.delete_demo_environment" />
					<div className="warning-part-2">{formatMessage({id: 'settings_details.delete_demo_info'})}</div>
				</div>
			),
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.delete'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: callbackPositive,
				},
			],
		});
	};

	return (
		<Container>
			{!viewer.company.forecastDemo && viewer.company.hasDemoData ? (
				<fieldset className="demo-data">
					<div>
						<button className="delete-project-button" onClick={deleteDemoData} disabled={deletingDemoData}>
							<FormattedMessage id="settings_details.delete_all_demo_data" />
						</button>
					</div>
				</fieldset>
			) : viewer.company.forecastDemo ? (
				<fieldset className="demo-data">
					<div>
						<div>
							<button onClick={createDemoData}>
								{formatMessage({id: 'settings_details.save_demo_environment'})}
							</button>
						</div>

						{viewer.company.hasDemoData ? (
							<div>
								<button className="delete-project-button" onClick={deleteDemoData}>
									<FormattedMessage id="settings_details.delete_all_demo_data" />
								</button>
							</div>
						) : null}

						<div className="demo-table-wrapper">
							<table>
								<thead>
									<tr>
										<th className="column-name">
											{formatMessage({id: 'settings_details.demo_environment'})}
										</th>
										<th className="column-name">
											{formatMessage({id: 'settings_details.demo_created_by'})}
										</th>
										<th className="column-name">Projects</th>
									</tr>
								</thead>

								<tbody>
									{demos.map(demo => {
										return (
											<DemoLineItem
												key={demo.node.id}
												demo={demo.node}
												viewerEmail={viewer.email}
												onSelect={generateDemoEnvironment}
												onDelete={deleteDemoEnvironment}
											/>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				</fieldset>
			) : null}
		</Container>
	);
};

export const settingsDemoDataQuery = graphql`
	query settingsDemoData_Query {
		viewer {
			actualPersonId
			component(name: "settings_demo_data")
			...settingsDemoData_viewer
		}
	}
`;

export default createFragmentContainer(SettingsDemoData, {
	viewer: graphql`
		fragment settingsDemoData_viewer on Viewer {
			id
			email
			company {
				id
				forecastDemo
				hasDemoData
				demos(first: 10000) @connection(key: "Company_demos") {
					edges {
						node {
							id
							name
							trialDemo
							sharedInCompany
							createdByEmail
							numberOfProjects
						}
					}
				}
			}
		}
	`,
});
