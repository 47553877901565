import React, {Component} from 'react';
import RichTextField from '../../../../../components/new-ui/rich_text_field';
import {injectIntl} from 'react-intl';
import UpdateTaskMutation from '../../../../../mutations/update_task_mutation.modern';
import UpdateSuggestedTaskLabelsMutation from '../../../../../mutations/update_suggested_task_labels_mutation';
import Util from '../../../util/util';
import ExpandIcon from '../../../../../images/v2/components/dropdown/expand_icon';
import {dispatch, EVENT_ID} from '../../../../../containers/event_manager';
import {profilePicSrc} from '../../../../../directApi';

class DescriptionSection extends Component {
	constructor(props) {
		super(props);

		this.state = {
			expanded: localStorage.getItem('task-modal-v3-description-expanded') || false,
			isExpandable: false,
			showUploadOptions: false,
			taskHasDependencies:
				props.task.dependsOnThisTask &&
				props.task.thisTaskDependsOn &&
				props.task.dependsOnThisTask.edges.length > 0 &&
				props.task.thisTaskDependsOn.edges.length > 0,
		};
		this.onKeyPress = this.onKeyPress.bind(this);
		this.mentions =
			props.project.projectPersons &&
			props.project.projectPersons.edges
				.filter(projectPerson => {
					return projectPerson.node.person.active;
				})
				.map(projectPerson => {
					return {
						personId: projectPerson.node.person.id,
						name: projectPerson.node.person.firstName + ' ' + projectPerson.node.person.lastName,
						avatar: profilePicSrc(projectPerson.node.person.profilePictureId),
					};
				});
	}

	UNSAFE_componentWillMount() {
		document.addEventListener('mousedown', this.onMouseDown);
		/*
		 * Listener added on body because of the order in which components
		 * receive events. If it is set on document, the listener in root.js
		 * will get the event first, and close the modal before the event is
		 * heard in this component.
		 */
		document.body.addEventListener('keydown', this.onKeyPress);
	}

	componentDidMount() {
		//this.richTextField will not exist if the section is not rendered due to viewer being a client user
		if (this.richTextField) {
			const collapsedDescriptionHeight = this.isHighResolution() ? 180 : 102;
			const textHeight = this.richTextField
				.getDecoratedComponentInstance()
				.richTextFieldEditorWrapper.getBoundingClientRect().height;
			this.setState({isExpandable: textHeight > collapsedDescriptionHeight});
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.project !== this.props.project) {
			this.mentions =
				this.props.project.projectPersons &&
				this.props.project.projectPersons.edges
					.filter(projectPerson => {
						return projectPerson.node.person.active;
					})
					.map(projectPerson => {
						return {
							personId: projectPerson.node.person.id,
							name: projectPerson.node.person.firstName + ' ' + projectPerson.node.person.lastName,
							avatar: profilePicSrc(projectPerson.node.person.profilePictureId),
						};
					});
		}
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.onMouseDown);
		document.body.removeEventListener('keydown', this.onKeyPress);
	}

	onMouseDown = e => {
		if (this.uploadOptions && !this.uploadOptions.contains(e.target) && !this.fileUploadButton.contains(e.target)) {
			this.setState({showUploadOptions: false});
		}
	};

	onKeyPress(e) {
		if (e.key === 'Escape') {
			if (this.richTextField) {
				this.richTextField.getDecoratedComponentInstance().handleEditorBlur(null);
			}
		}
	}

	isHighResolution() {
		return window.innerHeight > 800;
	}

	handleReadMoreChange() {
		if (this.state.expanded) {
			localStorage.removeItem('task-modal-v3-description-expanded');
		} else {
			Util.localStorageSetItem('task-modal-v3-description-expanded', true);
		}

		this.setState({expanded: !this.state.expanded});
	}

	handleClickOnText() {
		if (!this.state.expanded) {
			Util.localStorageSetItem('task-modal-v3-description-expanded', true);

			this.setState({expanded: true});
		}
	}

	isEmptyEditorState(editorState) {
		try {
			const editorJSON = JSON.parse(editorState);
			if (editorJSON.blocks && editorJSON.blocks.length === 1) {
				const text = editorJSON.blocks[0].text;
				return !text;
			}
			return false;
		} catch {
			return false;
		}
	}

	onTaskDescriptionUpdateSuccess(result, editorState) {
		if (result.updateTask.errors && result.updateTask.errors.length === 1) {
			Util.checkForSageErrorAndShowModal(result.updateTask.errors, false);
		} else {
			dispatch(EVENT_ID.SHOW_CHANGES_SAVED);
			Util.CommitMutation(UpdateSuggestedTaskLabelsMutation, {
				taskId: this.props.task.id,
				description: editorState,
			});
		}
	}

	editTaskDescription(editorState) {
		const emptyDescriptionChange = !this.props.task.description && this.isEmptyEditorState(editorState);
		if (!emptyDescriptionChange && this.props.task.description !== editorState) {
			Util.CommitMutation(
				UpdateTaskMutation,
				{
					ids: [this.props.task.id],
					description: editorState,
					//refetchSuggestedLabels: true
				},
				result => this.onTaskDescriptionUpdateSuccess(result, editorState)
			);
		}
		const collapsedDescriptionHeight = this.isHighResolution() ? 180 : 102;
		const textHeight = this.richTextField
			.getDecoratedComponentInstance()
			.richTextFieldEditorWrapper.getBoundingClientRect().height;
		this.setState({isExpandable: textHeight > collapsedDescriptionHeight});
	}

	uploadFile() {
		let file = this._fileInput.files.item(0);

		const upload = [];

		upload.push({
			taskId: this.props.task.id,
			mimeType: file.type,
			name: file.name,
			file: file,
		});

		let coverFile = null;
		if (!this.props.task.coverFile) {
			coverFile = upload.find(f => {
				const ext = f.name.split('.').pop();
				return ext === 'png' || ext === 'jpg' || ext === 'gif';
			});
		}

		const onSuccess = result => {
			if (!this.props.task.coverFile && coverFile && result) {
				Util.CommitMutation(UpdateTaskMutation, {
					ids: [this.props.task.id],
					coverFileId: result.createFile.file.node.id,
				});
			}
		};
		Util.uploadFiles(upload, onSuccess, coverFile);
	}

	onFileButtonClicked() {
		this._fileInput.click();
	}

	render() {
		const {formatMessage} = this.props.intl;
		const {viewer, project, task, isTaskReadOnly} = this.props;
		const {gdriveEnabled} = viewer.company;

		const mentionsLabels = viewer.company.labels.edges.map(label => {
			return {id: label.node.id, name: label.node.name};
		});

		return (
			<div
				className={
					'task-modal-v3-description-section' +
					(this.state.expanded ? ' expanded' : '') +
					(this.state.isExpandable ? ' is-expandable' : '') +
					(this.isHighResolution() ? ' high-resolution' : '')
				}
				data-cy="task-modal-description-section"
				data-userpilot={'task-modal-description'}
			>
				<div className="description-text">
					<RichTextField
						mentions={this.mentions}
						mentionsLabels={mentionsLabels}
						text={task.description || ''}
						placeholder={formatMessage({id: 'settings.project-edit-description'})}
						alwaysShowControls={false}
						ref={element => (this.richTextField = element)}
						handleTextChange={this.editTaskDescription.bind(this)}
						onClick={this.handleClickOnText.bind(this)}
						characterLimit={viewer.company.characterLimit}
						readOnly={isTaskReadOnly || (!!task.jiraId && !!project.jiraServerProject)}
						//ignoreComponentWillReceiveProps={true}
						showUploadFileIcon={true}
						onFileButtonClick={this.onFileButtonClicked.bind(this)}
						gdriveEnabled={gdriveEnabled}
						taskId={task.id}
						actualPersonId={viewer.actualPersonId}
						coverFile={task.coverFile}
						fileInput={this._fileInput}
					/>
					{this.state.isExpandable ? (
						<div className="expand-description-text-toggle">
							<div className="text-icon" onClick={this.handleReadMoreChange.bind(this)}>
								{this.state.expanded
									? formatMessage({id: 'common.read_less'})
									: formatMessage({id: 'common.read_more'})}
								<ExpandIcon orientation={this.state.expanded ? 'up' : 'down'} />
							</div>
						</div>
					) : null}
					<input
						type="file"
						data-cy="task-modal-file-upload"
						onClick={e => {
							e.target.value = null;
						}}
						onChange={this.uploadFile.bind(this)}
						ref={input => (this._fileInput = input)}
						disabled={isTaskReadOnly}
						className="hidden-file-input"
					/>
				</div>
			</div>
		);
	}
}

export default injectIntl(DescriptionSection);
