import React, {Component} from 'react';

class AddButton extends Component {
	render() {
		const {customClass, disabled, cy} = this.props;
		return (
			<button
				className={`add-button-component ${disabled ? ' disabled ' : ''} ${customClass || ''}`}
				onClick={disabled ? undefined : this.props.onClick}
				data-cy={cy}
			>
				+
			</button>
		);
	}
}

export default AddButton;
