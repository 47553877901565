import React from 'react';
import {DeprecatedText as Text} from '@forecast-it/design-system';
import {DeprecatedDestructiveModal as DestructiveModal} from '@forecast-it/design-system';
import Util from '../../../util/util';
import DetachProjectFromProgramMutation from '../../../../../mutations/project-service/detach_project_from_program_mutation';
import {createToast} from '../../toasts/toast';
import {useIntl} from 'react-intl';
import {trackEvent} from '../../../../../tracking/amplitude/TrackingV2';
import ProgramUtil from '../../../util/ProgramUtil';

const RemoveProjectFromProgramModal = ({closeModal, programId, projectId}) => {
	const {formatMessage} = useIntl();

	const content = (
		<>
			<Text>
				{formatMessage(
					{id: 'modal.description.delete.remove_entity'},
					{entity: formatMessage({id: 'common.project_lowercase'})}
				)}
			</Text>
			<Text>
				{Util.upperCaseFirst(
					formatMessage(
						{id: 'modal.description.delete.remove_project'},
						{program: ProgramUtil.programText(formatMessage)}
					)
				)}
			</Text>
		</>
	);

	const detachProjectFromProgram = () =>
		Util.CommitSchedulingModalUpdate(
			DetachProjectFromProgramMutation,
			{programId: programId, projectIds: projectId},
			() => {
				trackEvent('Project', 'Removed From Program');
				createToast({
					duration: 5000,
					message: Util.upperCaseFirst(
						formatMessage({id: 'program.project.remove'}, {program: ProgramUtil.programText(formatMessage)})
					),
				});
				closeModal();
			}
		);

	return (
		<DestructiveModal
			closeModal={closeModal}
			onDeleteHandler={detachProjectFromProgram}
			destructiveButtonText={formatMessage({id: 'common.remove'})}
			title={formatMessage({id: 'modal.title.delete'})}
		>
			{content}
		</DestructiveModal>
	);
};

export default RemoveProjectFromProgramModal;
