/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type DeletePriorityLevelModal_company$ref: FragmentReference;
declare export opaque type DeletePriorityLevelModal_company$fragmentType: DeletePriorityLevelModal_company$ref;
export type DeletePriorityLevelModal_company = {|
  +id: string,
  +priorityLevels: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +weight: ?number,
      |}
    |}>
  |},
  +$refType: DeletePriorityLevelModal_company$ref,
|};
export type DeletePriorityLevelModal_company$data = DeletePriorityLevelModal_company;
export type DeletePriorityLevelModal_company$key = {
  +$data?: DeletePriorityLevelModal_company$data,
  +$fragmentRefs: DeletePriorityLevelModal_company$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeletePriorityLevelModal_company",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1000
        }
      ],
      "concreteType": "PriorityLevelTypeConnection",
      "kind": "LinkedField",
      "name": "priorityLevels",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PriorityLevelTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PriorityLevel",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "weight",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "priorityLevels(first:1000)"
    }
  ],
  "type": "Company"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f92d19934295a4c4f31030c447a4e8ec';

module.exports = node;
