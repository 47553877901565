import React, {useState} from 'react';
import {Table} from 'web-components';
import {getBaseLevelTableHeader} from '../BaseLevelTableHeader';
import {getBaseLevelTableHeaderTotals} from '../BaseLevelTableHeaderTotals';
import CustomScrollDiv from '../../../../shared/components/scroll-bars/custom_scroll_div';
import {PageContent} from '../styling/TimeRegistrationReportTable.styled';
import {EXTERNAL_SHARE_TYPE} from '../../../../shared/util/ReportServiceUtil';
import {useGenericReportContext} from '../../../saved-report/GenericReportContext';

const TimeRegBaseLevelWrapper = ({
	tableHeader,
	getRows,
	enabledColumns,
	currentLength,
	hasNextPage,
	refetch,
	customFieldColumnCount,
	pageSize,
}) => {
	const {isSharedView, shareType} = useGenericReportContext();

	const [modalTasksFetching, setModalTasksFetching] = useState(false);

	const fetchMore = () => {
		const refetchObject = {};
		if (hasNextPage) {
			setModalTasksFetching(true);
			refetchObject.showLoader = true;
			refetchObject.pageSize = currentLength + pageSize;
			refetch(refetchObject, null, () => setModalTasksFetching(false));
		} else if (modalTasksFetching) {
			setModalTasksFetching(false);
		}
	};

	const handleScroll = e => {
		if (!modalTasksFetching && e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 300) {
			fetchMore();
		}
	};

	const tableContent = () => (
		<PageContent>
			<Table>
				{/* <BaseLevelTableHeader intl {...tableHeader} /> */}
				{getBaseLevelTableHeader(enabledColumns, tableHeader.customFieldDefinitions)}
				{getBaseLevelTableHeaderTotals(tableHeader, customFieldColumnCount)}
				{getRows()}
			</Table>
		</PageContent>
	);

	const scrollWrapper = () => {
		return (
			<CustomScrollDiv className="custom-scrollbar-div" handleScroll={handleScroll}>
				{tableContent()}
			</CustomScrollDiv>
		);
	};

	return isSharedView && shareType === EXTERNAL_SHARE_TYPE ? tableContent() : scrollWrapper();
};

export default TimeRegBaseLevelWrapper;
