import React from 'react';
import { useIntl } from 'react-intl';
import Util from '../../../../shared/util/util.js';
function TimeRegistrationWeekTotals({ minutesRegistered, billableMinutesRegistered, minutesInTheWorkWeek, dataCy, }) {
    const intl = useIntl();
    const registeredTimeInHours = Util.convertMinutesToFullHour(minutesRegistered, intl, true);
    const billableRegisteredTimeInHours = Util.convertMinutesToFullHour(billableMinutesRegistered, intl, true);
    const hoursInTheWorkWeek = Util.convertMinutesToFullHour(minutesInTheWorkWeek, intl, true);
    return (React.createElement("div", { style: { display: 'flex', flexDirection: 'column', gap: '4px', flexGrow: 1, justifyContent: 'center' } },
        React.createElement("p", { style: { width: '100%', lineHeight: '14px' }, "data-cy": dataCy }, `Timesheet Total: ${registeredTimeInHours}/${hoursInTheWorkWeek}`),
        React.createElement("p", { style: { width: '100%', lineHeight: '14px', color: '#727483' } }, `Billable Total: ${billableRegisteredTimeInHours}`)));
}
export default TimeRegistrationWeekTotals;
