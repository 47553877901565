/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TimeRegistrationModalIntegrations_person",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "harvestUser",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unit4User",
            "storageKey": null
        }
    ],
    "type": "Person"
};
node.hash = 'd86a4a2e47e87916081608b32d729f5c';
export default node;
