import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Switch} from 'web-components';
import InlineLoader from '../../../shared/components/inline-loader/inline_loader';
import {HorizontalFlexWrapper} from '../salesforce/app_salesforce_style';
import {SelectionElementLabel} from '../../../../containers/project/project_settings/ProjectSettings_styled';
import {createToast} from '../../../shared/components/toasts/toast';
import {useIntl} from 'react-intl';
import DirectApi from '../../../../directApi';

const genericIntegrationLinkedFields = ({integrationName}) => {
	const intl = useIntl();
	const [linkedFields, setLinkedFields] = useState(null);
	useEffect(() => {
		DirectApi.Fetch(`${integrationName}/linked_fields`).then(json => {
			setLinkedFields(json.linkedFields);
		});
	}, []);

	const handleSwitchChange = (value, field) => {
		const newLinkedFields = {...linkedFields};
		newLinkedFields[field].linked = !value;

		let body = {};

		Object.keys(newLinkedFields).forEach(field => {
			if (newLinkedFields[field].linked) {
				body[field] = newLinkedFields[field].text;
			}
		});

		const headers = new Headers();
		headers.append('Content-Type', 'application/json');

		const init = {
			headers: headers,
			credentials: 'include',
			method: 'PUT',
			body: JSON.stringify(body),
		};

		return fetch(DirectApi.graphqlServerEndpoint(`hubspot/linked_fields`), init).then(() => {
			setLinkedFields(newLinkedFields);
			createToast({
				duration: 2000,
				message: intl.formatMessage({id: 'common.changes_saved'}),
			});
		});
	};

	return linkedFields ? (
		<>
			{Object.keys(linkedFields).map(field => {
				return (
					<LinkedFieldsWrapper>
						<HorizontalFlexWrapper>
							<Switch checked={linkedFields[field].linked} onChange={value => handleSwitchChange(value, field)} />
							<SelectionElementLabel>{linkedFields[field].text}</SelectionElementLabel>
						</HorizontalFlexWrapper>
					</LinkedFieldsWrapper>
				);
			})}
		</>
	) : (
		<InlineLoader />
	);
};

const LinkedFieldsWrapper = styled.div`
	margin: 8px 0;
`;

export default genericIntegrationLinkedFields;
