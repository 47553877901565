import React from 'react';

const attentionIcon = props => (
	<div className={props.className} title={props.title}>
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
			<g fill="none" fillRule="nonzero">
				<path
					fill="#ADADAD"
					d="M1.723 19.903a3.027 3.027 0 0 1-.468-.184c-.35-.167-.838-.655-1.005-1.002-.266-.542-.246.11-.246-8.733 0-8.842-.02-8.19.246-8.733C.417.904.904.417 1.25.25c.542-.266-.109-.246 8.733-.246 8.843 0 8.191-.02 8.733.246.347.167.835.654 1.002 1.001.265.542.246-.109.246 8.733 0 8.843.02 8.191-.246 8.733-.167.347-.655.835-1.002 1.002-.542.265.113.246-8.752.242-6.912 0-8.062-.008-8.242-.058z"
				/>
				<path fill="#FFF" d="M6.086 9.984V4.02h2.3v4.757h3.197V4.019h2.3v11.93h-2.3v-5.146H8.386v5.146h-2.3z" />
			</g>
		</svg>
	</div>
);

export default attentionIcon;
