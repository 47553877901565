import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import Person from '../../../person/person';
import UpdateTaskMutation from '../../../../../../mutations/update_task_mutation.modern';
import UpdateSubTaskMutation from '../../../../../../mutations/update_sub_task_mutation';
import Util from '../../../../util/util';
import AssignedDropdown from '../../../dropdowns/assigned-dropdown/assigned_dropdown';
import {profilePicSrc} from '../../../../../../directApi';

class TaskModalSubtaskDropdown extends Component {
	constructor(props) {
		super(props);

		this.state = {isManagingFocus: false};
	}

	toggleContent(e) {
		e.preventDefault();
		this.setState({isManagingFocus: !this.state.isManagingFocus});
	}

	getButtonInnerHTML() {
		const subtask = this.props.subtask;
		return subtask.person ? (
			<Person
				name={subtask.person.firstName + (subtask.person.lastName ? ' ' + subtask.person.lastName : '')}
				showName={false}
				showRole={false}
				imageSize="new-ui-dropdown"
				imageSrc={profilePicSrc(subtask.person.profilePictureId)}
				ownerBorderColor="#6e0fea"
				isOwner={this.state.isManagingFocus}
			/>
		) : (
			<svg width="32px" height="30px" viewBox="0 0 140 140">
				<polyline
					fill="transparent"
					stroke="#05e298"
					strokeWidth="8"
					points={'70,1 130.62,35 130.62,105 70,139 9.38,105 9.38,35 70,1'}
				/>
				<line x1="43.75" y1="70" x2="96.25" y2="70" stroke="#05e298" strokeWidth="8" />
				<line y1="43.75" x1="70" y2="96.25" x2="70" stroke="#05e298" strokeWidth="8" />
			</svg>
		);
	}

	assignRole(id) {
		Util.CommitMutation(UpdateSubTaskMutation, {
			id: this.props.subtask.id,
			roleId: id,
		});
		this.setState({isManagingFocus: false, searchCriteria: '', selected: null});
	}

	assignPerson(id) {
		Util.CommitMutation(UpdateSubTaskMutation, {
			id: this.props.subtask.id,
			personId: id,
		});
		// Check if person is already assigned to the task, if not assign them
		const assignedIds = this.props.task.assignedPersons.map(person => person.id);
		if (!assignedIds.includes(id)) {
			Util.CommitMutation(UpdateTaskMutation, {
				ids: [this.props.task.id],
				assignedPersons: assignedIds.concat(id),
			});
		}
		this.setState({isManagingFocus: false, searchCriteria: '', selected: null});
	}

	clearAssigned() {
		Util.CommitMutation(UpdateSubTaskMutation, {
			id: this.props.subtask.id,
			personId: null,
			roleId: null,
		});
		this.setState({isManagingFocus: false, searchCriteria: '', selected: null});
	}

	onFocus(e) {
		// Clear the timeout so we dont collapse the dropdown
		clearTimeout(this._timeoutID);
	}

	onBlur(e) {
		// Set a timeout to collapse the dropdown if we dont get focus within this tick
		this._timeoutID = setTimeout(() => {
			if (this.state.isManagingFocus) {
				this.setState({
					isManagingFocus: false,
				});
			}
		}, 0);
	}

	render() {
		const subtask = this.props.subtask;
		return (
			<div className="subtask-assigned-wrapper" onFocus={this.onFocus.bind(this)} onBlur={this.onBlur.bind(this)}>
				<button
					onClick={this.toggleContent.bind(this)}
					ref={button => (this.buttonRef = button)}
					aria-haspopup="menu"
					disabled={this.props.disabled}
				>
					{this.getButtonInnerHTML()}
				</button>
				{this.state.isManagingFocus ? (
					<div className={'subtask-assigned-content' + (subtask.person || subtask.role ? ' clearable' : '')}>
						<AssignedDropdown
							assignablePersons={this.props.project.projectPersons.edges}
							assignableRoles={this.props.viewer.company.roles.edges}
							assignedPersons={subtask.person ? [subtask.person] : []}
							assignPerson={this.assignPerson.bind(this)}
							assignRole={this.assignRole.bind(this)}
							autoFocus={true}
							viewer={this.props.viewer}
							unassignPerson={this.clearAssigned.bind(this)}
						/>
					</div>
				) : null}
			</div>
		);
	}
}

TaskModalSubtaskDropdown.propTypes = {
	project: PropTypes.object.isRequired,
	subtask: PropTypes.object.isRequired,
	viewer: PropTypes.object.isRequired,
	disabled: PropTypes.bool,
};

export default injectIntl(TaskModalSubtaskDropdown);
