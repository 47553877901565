import React from 'react';
import PropTypes from 'prop-types';
import {createFragmentContainer, graphql} from 'react-relay';
import ForecastTile from '../forecast_tile';
import RemainingTile from '../remaining_tile';
import TimeEntriesTileRenderer from '../time_entries_tile_renderer';
import {ProgressTile} from '../progress-tile/ProgressTile';
import ProjectUtil from '../../../../../util/project_util';
import Util from '../../../../../util/util';
import {HIDDEN_FEATURES} from '../../../../../../../constants';
import {TaskModalTimeEntriesPlaceholder} from '../placeholders/TaskModalTimeEntriesPlaceholder';

const TaskRollupSection = ({viewer, task, project, isClientUser, disabled, intl, personId, showTimeEntries}) => {
	const {rollupEstimate, rollupTimeEntries, rollupRemaining} = viewer.task;
	return (
		<>
			<ForecastTile
				rollupEstimate={rollupEstimate}
				task={task}
				project={project}
				disabled={disabled}
				cy="task-modal-forecast-button"
			/>
			{ProjectUtil.projectHasManualProgressSettingEnabled(viewer.task.project) ? (
				<ProgressTile task={task} intl={intl} />
			) : showTimeEntries ? (
				<TimeEntriesTileRenderer
					rollupTimeEntries={rollupTimeEntries}
					taskId={task.id}
					isTwoLevelSubTask={project.taskLevels === 2}
					disabled={disabled || Util.isFeatureHidden(HIDDEN_FEATURES.TIME_REGISTRATIONS)}
					cy="task-modal-entries-button"
					isClientUser={isClientUser}
					personId={personId}
				/>
			) : (
				<TaskModalTimeEntriesPlaceholder />
			)}
			<RemainingTile
				rollupRemaining={rollupRemaining}
				task={task}
				project={project}
				disabled={disabled}
				cy="task-modal-remaining-time-button"
			/>
		</>
	);
};

TaskRollupSection.propTypes = {
	viewer: PropTypes.object,
	task: PropTypes.object.isRequired,
	project: PropTypes.object.isRequired,
	disabled: PropTypes.bool.isRequired,
	isClientUser: PropTypes.bool.isRequired,
	personId: PropTypes.string.isRequired,
};

const TaskRollupSectionQuery = graphql`
	query TaskRollupSection_Query($id: String) {
		viewer {
			actualPersonId
			component(name: "task_rollup_section")
			...TaskRollupSection_viewer @arguments(id: $id)
		}
	}
`;

export {TaskRollupSectionQuery};

export default createFragmentContainer(TaskRollupSection, {
	viewer: graphql`
		fragment TaskRollupSection_viewer on Viewer @argumentDefinitions(id: {type: "String"}) {
			id
			task(companyTaskId: $id) {
				id
				rollupEstimate
				rollupTimeEntries
				rollupRemaining
				project {
					manualProgressOnProjectEnabled
					manualProgressOnPhasesEnabled
					manualProgressOnTasksEnabled
				}
			}
		}
	`,
});
