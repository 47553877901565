import { endOfWeek, formatISO, getDate, getMonth, getYear, isBefore, isWeekend, parseISO, startOfToday, startOfWeek, startOfMonth, endOfMonth, } from 'date-fns';
import moment from 'moment';
import CompanySetupUtil from './CompanySetupUtil';
/**
 * We want to migrate away from Moment.js, so dates will start transitioning
 * to standard js dates using date-fns.
 * Keep all date functions accepting Moment arguments compatible with moment.js as well as standard js dates
 * use isMoment to check if a date is a moment date, if not use date-fns
 * */
export function isMoment(dateObject) {
    return !!dateObject && moment.isMoment(dateObject);
}
export function isDateDisabled(dateObject) {
    if (dateObject && CompanySetupUtil.weekendsAlwaysHidden()) {
        if (isMoment(dateObject)) {
            const momentDate = dateObject;
            // Is sunday or saturday
            return [0, 6].includes(momentDate.day());
        }
        else {
            return isWeekend(dateObject);
        }
    }
    return false;
}
export function CreateMomentDate(year, month, day) {
    if (!!year && !!month && !!day) {
        return moment.utc({ y: year, M: month - 1, d: day });
    }
    return null;
}
export function isDateDisabledYMD(year, month, day) {
    return isDateDisabled(CreateMomentDate(year, month, day));
}
export function dateFragmentPropsFromIsoDateString(isoDateString) {
    if (!isoDateString) {
        return {
            year: null,
            month: null,
            day: null,
        };
    }
    const date = parseISO(isoDateString);
    return {
        year: getYear(date),
        month: getMonth(date) + 1,
        day: getDate(date),
    };
}
export function createDateFromYMD({ year, month, day }) {
    return new Date(year, month - 1, day);
}
export function createDateFromNullableYMD({ year, month, day }) {
    if (year && month && day) {
        return createDateFromYMD({ year, month, day });
    }
    return null;
}
export function createDateFromMoment(moment) {
    if (moment instanceof Date) {
        return moment;
    }
    return createDateFromYMD({
        year: moment.year(),
        month: moment.month() + 1,
        day: moment.date(),
    });
}
export function isYearMonthDayBeforeToday(year, month, day) {
    if (!!year && !!month && !!day) {
        const date = createDateFromYMD({
            year,
            month,
            day,
        });
        if (date) {
            return isBefore(date, startOfToday());
        }
    }
    return false;
}
export function getWeekDateRangeFromDate(date) {
    const startDate = startOfWeek(date);
    const endDate = endOfWeek(date);
    return { startDate, endDate };
}
export function dateRangeToYMD(dateRange) {
    return {
        startYear: getYear(dateRange.startDate),
        startMonth: getMonth(dateRange.startDate) + 1,
        startDay: getDate(dateRange.startDate),
        endYear: getYear(dateRange.endDate),
        endMonth: getMonth(dateRange.endDate) + 1,
        endDay: getDate(dateRange.endDate),
    };
}
export function dateFragmentPropsFromIntervalInIsoFormat(startDateIsoString, endDateIsoString) {
    const startDate = parseISO(startDateIsoString);
    const endDate = parseISO(endDateIsoString);
    return dateRangeToYMD({ startDate, endDate });
}
export function isoDateToMomentNonUtc(isoDate) {
    if (isoDate)
        return moment(isoDate);
    return null;
}
export function dateObjectToIsoDate(dateObject) {
    if (!dateObject) {
        return null;
    }
    if (isMoment(dateObject)) {
        const momentDate = dateObject;
        return momentDate.format('YYYY-MM-DD');
    }
    else {
        return formatISO(dateObject, { representation: 'date' });
    }
}
export function getMonthRange(dateObject) {
    const startDate = startOfMonth(dateObject);
    const endDate = endOfMonth(dateObject);
    return { startDate, endDate };
}
export function getPreviousMonthRange(dateObject) {
    const date = new Date(dateObject);
    date.setMonth(date.getMonth() - 1);
    return getMonthRange(date);
}
export function getNextMonthRange(dateObject) {
    const date = new Date(dateObject);
    date.setMonth(date.getMonth() + 1);
    return getMonthRange(date);
}
