import { RichTextEditor } from '@forecast-it/design-system';
import React from 'react';
import { useDraftJsHtmlConversion } from './useDraftJsConversion';
export const RichTextEditorDraftJsWrapper = ({ peopleMentions, initialDraftJsContent, onDraftJsChanged, 'data-cy': dataCy, }) => {
    const { initialHtml, convertHtmlToDraftJs } = useDraftJsHtmlConversion({
        initialDraftJsContent,
        onDraftJsChanged,
    });
    return (React.createElement(RichTextEditor, { "data-cy": dataCy, peopleMentions: peopleMentions, onBlur: convertHtmlToDraftJs, initialHtml: initialHtml }));
};
