import {BUDGET_TYPE, HIDDEN_FEATURES, MODULE_TYPES, PERIOD_BUDGET_TYPE} from '../../../constants';
import {hasFeatureFlag} from './FeatureUtil';
import CompanySetupUtil, {isFeatureHidden} from './CompanySetupUtil';
import {hasModule} from './ModuleUtil';

export default class ProjectUtil {
	static handleSprintLengthChange(value) {
		// Clamp sprint length between 0 and 365
		const clampedValue = Math.max(0, Math.min(365, parseInt(value, 10)));
		return isNaN(clampedValue) ? value : clampedValue;
	}
	static getDefaultRateCard(formatMessage, defaultRateCard, companyCurrency) {
		if (defaultRateCard) {
			return {
				value: defaultRateCard.id,
				label: defaultRateCard.name,
				currency: defaultRateCard ? defaultRateCard.currency : companyCurrency,
			};
		} else {
			return {value: null, label: formatMessage({id: 'rate_card_selector.no_rate_card_text'}), currency: companyCurrency};
		}
	}

	static projectHasManualProgressSettingEnabled(project) {
		return (
			project &&
			(project.manualProgressOnProjectEnabled ||
				project.manualProgressOnPhasesEnabled ||
				project.manualProgressOnTasksEnabled)
		);
	}

	static projectUsesManualProgress(project) {
		const projectEntity = project?.node || project;
		return (
			(isFeatureHidden(HIDDEN_FEATURES.TIME_REGISTRATIONS) && !hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED)) ||
			this.projectHasManualProgressSettingEnabled(projectEntity)
		);
	}

	static projectTracksTime(project) {
		return !isFeatureHidden(HIDDEN_FEATURES.TIME_REGISTRATIONS) && !ProjectUtil.projectUsesManualProgress(project);
	}

	static projectTracksRevenue(project) {
		return !isFeatureHidden(HIDDEN_FEATURES.REVENUE) && project?.budgetType !== BUDGET_TYPE.NON_BILLABLE;
	}

	static projectTracksCost() {
		return CompanySetupUtil.hasCost();
	}

	static projectHasRevenueRecognition(budgetType) {
		return (
			CompanySetupUtil.hasRevenueRecognition() &&
			ProjectUtil.projectTracksRevenue({budgetType}) &&
			(budgetType === BUDGET_TYPE.FIXED_PRICE_V2 || hasFeatureFlag('revenue_recognition_with_all_budget_types'))
		);
	}

	static projectHasExpenses(budgetType) {
		return ProjectUtil.projectTracksRevenue({budgetType}) && CompanySetupUtil.hasExpenses();
	}

	static projectHasInvoicing(budgetType) {
		return ProjectUtil.projectTracksRevenue({budgetType}) && CompanySetupUtil.hasFinance();
	}

	static isFixedPriceV1(project) {
		return ProjectUtil.projectTracksRevenue(project) && project.budgetType === BUDGET_TYPE.FIXED_PRICE;
	}

	static isFixedPriceV2(project) {
		return ProjectUtil.projectTracksRevenue(project) && project.budgetType === BUDGET_TYPE.FIXED_PRICE_V2;
	}

	static isAnyKindOfFixedPrice(project) {
		return (
			ProjectUtil.projectTracksRevenue(project) &&
			(project.budgetType === BUDGET_TYPE.FIXED_PRICE ||
				project.budgetType === BUDGET_TYPE.FIXED_PRICE_V2 ||
				(project.budgetType === BUDGET_TYPE.RETAINER &&
					project.defaultPeriodBudgetType === PERIOD_BUDGET_TYPE.FIXED_PRICE))
		);
	}

	static allProjectsUseManualProgress = projects => {
		if (isFeatureHidden(HIDDEN_FEATURES.TIME_REGISTRATIONS)) {
			return true;
		}
		if (!projects || !projects.filter) {
			return false;
		}
		const listOfProjectsNotUsingManualProgress = projects.filter(
			project => !ProjectUtil.projectUsesManualProgress(project)
		);
		return listOfProjectsNotUsingManualProgress.length === 0;
	};

	static allProjectEdgesUseManualProgress = edges => {
		if (isFeatureHidden(HIDDEN_FEATURES.TIME_REGISTRATIONS)) {
			return true;
		}
		return edges ? ProjectUtil.allProjectsUseManualProgress(edges.map(edge => edge.node)) : false;
	};

	/**
	 * returns correct actual revenue based on billable
	 * @param {object} task task
	 * - taskCount: amount of tasks in project
	 * - billableScope: combined scope of all billable + approved tasks in minutes
	 * - newFixedPrice: project fixed revenue if has featureflag and fixed revenue project, undefined otherwise
	 * @returns {number} actual revenue for task
	 */
	static getActualPriceForTask(task) {
		if (hasFeatureFlag('use_financial_service_in_scoping')) {
			return task.currentPrice;
		}
		return task.billable && task.approved && task.timeRegistrations?.edges?.length > 0
			? task.timeRegistrations.edges.reduce((acc, timeReg) => acc + timeReg.node.price, 0)
			: 0;
	}

	static getPlannedCostForTask(task) {
		return hasFeatureFlag('use_financial_service_in_scoping') ? task.plannedCost || 0 : 0;
	}

	static getActualCostForTask(task) {
		return hasFeatureFlag('use_financial_service_in_scoping') ? task.actualCost || 0 : 0;
	}

	static getTotalPriceAtCompletionForTask(task) {
		return task.totalPriceAtCompletion || 0;
	}

	static getTotalAllTotalTimeAndExpensesAtCompletionForTask(task) {
		return task.allTotalTimeAndExpensesAtCompletion || 0;
	}

	/**
	 * returns correct Planned Revenue based on billable
	 * @param {object} task task
	 * - taskCount: amount of tasks in project
	 * - billableScope: combined scope of all billable + approved tasks in minutes
	 * - newFixedPrice: project fixed revenue if has featureflag and fixed revenue project, undefined otherwise
	 * @returns {number} Planned Revenue for task
	 */
	static getEstimatedPrice(task) {
		if (hasFeatureFlag('use_financial_service_in_scoping')) {
			return task.estimateForecastPrice;
		}
		return task.billable && task.approved && task.estimateForecastPrice !== null ? task.estimateForecastPrice : 0;
	}

	static isFixedPriceProject(project) {
		return (
			project.budgetType === BUDGET_TYPE.FIXED_PRICE ||
			project.budgetType === BUDGET_TYPE.FIXED_PRICE_V2 ||
			(project.budgetType === BUDGET_TYPE.RETAINER && project.defaultPeriodBudgetType === PERIOD_BUDGET_TYPE.FIXED_PRICE)
		);
	}

	static getProjectConfirmedRevenue(project) {
		return ProjectUtil.isFixedPriceProject(project)
			? project.totalRevenueRecognition || 0
			: (project.totalTimeRevenueRecognitionToDate || 0) + (project.totalBillableExpensesAtCompletion || 0);
	}

	static getProjectConfirmedRevenueTotal = projects => {
		if (!projects || !projects.reduce) {
			return 0;
		}
		return projects.reduce((total, project) => {
			const projectConfirmedRevenue = ProjectUtil.getProjectConfirmedRevenue(project);
			return total + projectConfirmedRevenue;
		}, 0);
	};

	static getProjectedBillableValueOfServiceForTask(task) {
		if (hasFeatureFlag('use_financial_service_in_scoping')) {
			return task.totalPriceAtCompletion;
		}
		return task.billable && task.approved && task.totalPriceAtCompletion !== null ? task.totalPriceAtCompletion : 0;
	}

	static getProjectedCostForTask(task) {
		if (hasFeatureFlag('use_financial_service_in_scoping')) {
			return task.totalCostAtCompletion;
		}
		return task.billable && task.approved && task.totalCostAtCompletion !== null ? task.totalCostAtCompletion : 0;
	}
}
