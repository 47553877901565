/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type GenericProjectTemplatesTable_viewer$ref = any;
export type GenericProjectTemplatesTable_QueryVariables = {||};
export type GenericProjectTemplatesTable_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: GenericProjectTemplatesTable_viewer$ref,
  |}
|};
export type GenericProjectTemplatesTable_Query = {|
  variables: GenericProjectTemplatesTable_QueryVariables,
  response: GenericProjectTemplatesTable_QueryResponse,
|};
*/


/*
query GenericProjectTemplatesTable_Query {
  viewer {
    actualPersonId
    component(name: "genericProjectTemplatesTable")
    ...GenericProjectTemplatesTable_viewer
    id
  }
}

fragment DeprecatedProjectIndicatorJS_project on ProjectType {
  name
  companyProjectId
  customProjectId
  projectColor
}

fragment GenericProjectTemplatesTable_viewer on Viewer {
  id
  projects(first: 10000) {
    edges {
      node {
        id
        name
        companyProjectId
        useManualAllocations
        useBaseline
        projectColor
        ...DeprecatedProjectIndicatorJS_project
      }
    }
  }
  company {
    pipedriveCompanyDomain
    hubspotAccountId
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "genericProjectTemplatesTable"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"genericProjectTemplatesTable\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GenericProjectTemplatesTable_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GenericProjectTemplatesTable_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GenericProjectTemplatesTable_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 10000
              }
            ],
            "concreteType": "ProjectTypeConnection",
            "kind": "LinkedField",
            "name": "projects",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "companyProjectId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "useManualAllocations",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "useBaseline",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectColor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "customProjectId",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "projects(first:10000)"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pipedriveCompanyDomain",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hubspotAccountId",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "GenericProjectTemplatesTable_Query",
    "operationKind": "query",
    "text": "query GenericProjectTemplatesTable_Query {\n  viewer {\n    actualPersonId\n    component(name: \"genericProjectTemplatesTable\")\n    ...GenericProjectTemplatesTable_viewer\n    id\n  }\n}\n\nfragment DeprecatedProjectIndicatorJS_project on ProjectType {\n  name\n  companyProjectId\n  customProjectId\n  projectColor\n}\n\nfragment GenericProjectTemplatesTable_viewer on Viewer {\n  id\n  projects(first: 10000) {\n    edges {\n      node {\n        id\n        name\n        companyProjectId\n        useManualAllocations\n        useBaseline\n        projectColor\n        ...DeprecatedProjectIndicatorJS_project\n      }\n    }\n  }\n  company {\n    pipedriveCompanyDomain\n    hubspotAccountId\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '46f7cac86d47a90f3b6d82f71ce2a6be';

module.exports = node;
