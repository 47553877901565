/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
query CopyLastWeekValidationSection_Query(
  $personId: ID!
  $startYear: Int!
  $startMonth: Int!
  $startDay: Int!
  $endYear: Int!
  $endMonth: Int!
  $endDay: Int!
) {
  viewer {
    actualPersonId
    component(name: "copy_last_week_validation_section")
    ...CopyLastWeekValidationSection_viewer_3q0Fis
    id
  }
}

fragment CopyLastWeekValidationSection_viewer_3q0Fis on Viewer {
  company {
    person(id: $personId) {
      doneTasksExcluded: validatedTimeRegsInDateRange(startYear: $startYear, startMonth: $startMonth, startDay: $startDay, endYear: $endYear, endMonth: $endMonth, endDay: $endDay, excludeDoneTasks: true) {
        timeRegRequest {
          minutesRegistered
          billableMinutesRegistered
        }
        valid
        validationReasons
      }
      doneTasksIncluded: validatedTimeRegsInDateRange(startYear: $startYear, startMonth: $startMonth, startDay: $startDay, endYear: $endYear, endMonth: $endMonth, endDay: $endDay, excludeDoneTasks: false) {
        timeRegRequest {
          minutesRegistered
          billableMinutesRegistered
        }
        valid
        validationReasons
      }
      id
    }
    id
  }
}
*/
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "personId",
            "type": "ID!"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "startYear",
            "type": "Int!"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "startMonth",
            "type": "Int!"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "startDay",
            "type": "Int!"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "endYear",
            "type": "Int!"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "endMonth",
            "type": "Int!"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "endDay",
            "type": "Int!"
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actualPersonId",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": [
            {
                "kind": "Literal",
                "name": "name",
                "value": "copy_last_week_validation_section"
            }
        ],
        "kind": "ScalarField",
        "name": "component",
        "storageKey": "component(name:\"copy_last_week_validation_section\")"
    }, v3 = {
        "kind": "Variable",
        "name": "endDay",
        "variableName": "endDay"
    }, v4 = {
        "kind": "Variable",
        "name": "endMonth",
        "variableName": "endMonth"
    }, v5 = {
        "kind": "Variable",
        "name": "endYear",
        "variableName": "endYear"
    }, v6 = {
        "kind": "Variable",
        "name": "startDay",
        "variableName": "startDay"
    }, v7 = {
        "kind": "Variable",
        "name": "startMonth",
        "variableName": "startMonth"
    }, v8 = {
        "kind": "Variable",
        "name": "startYear",
        "variableName": "startYear"
    }, v9 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "TimeRegistrationRequest",
            "kind": "LinkedField",
            "name": "timeRegRequest",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "minutesRegistered",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "billableMinutesRegistered",
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "valid",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "validationReasons",
            "storageKey": null
        }
    ], v10 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "CopyLastWeekValidationSection_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "args": [
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                (v5 /*: any*/),
                                {
                                    "kind": "Variable",
                                    "name": "personId",
                                    "variableName": "personId"
                                },
                                (v6 /*: any*/),
                                (v7 /*: any*/),
                                (v8 /*: any*/)
                            ],
                            "kind": "FragmentSpread",
                            "name": "CopyLastWeekValidationSection_viewer"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "CopyLastWeekValidationSection_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Company",
                            "kind": "LinkedField",
                            "name": "company",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": [
                                        {
                                            "kind": "Variable",
                                            "name": "id",
                                            "variableName": "personId"
                                        }
                                    ],
                                    "concreteType": "Person",
                                    "kind": "LinkedField",
                                    "name": "person",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": "doneTasksExcluded",
                                            "args": [
                                                (v3 /*: any*/),
                                                (v4 /*: any*/),
                                                (v5 /*: any*/),
                                                {
                                                    "kind": "Literal",
                                                    "name": "excludeDoneTasks",
                                                    "value": true
                                                },
                                                (v6 /*: any*/),
                                                (v7 /*: any*/),
                                                (v8 /*: any*/)
                                            ],
                                            "concreteType": "ValidatedTimeRegistration",
                                            "kind": "LinkedField",
                                            "name": "validatedTimeRegsInDateRange",
                                            "plural": true,
                                            "selections": (v9 /*: any*/),
                                            "storageKey": null
                                        },
                                        {
                                            "alias": "doneTasksIncluded",
                                            "args": [
                                                (v3 /*: any*/),
                                                (v4 /*: any*/),
                                                (v5 /*: any*/),
                                                {
                                                    "kind": "Literal",
                                                    "name": "excludeDoneTasks",
                                                    "value": false
                                                },
                                                (v6 /*: any*/),
                                                (v7 /*: any*/),
                                                (v8 /*: any*/)
                                            ],
                                            "concreteType": "ValidatedTimeRegistration",
                                            "kind": "LinkedField",
                                            "name": "validatedTimeRegsInDateRange",
                                            "plural": true,
                                            "selections": (v9 /*: any*/),
                                            "storageKey": null
                                        },
                                        (v10 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v10 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v10 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "CopyLastWeekValidationSection_Query",
            "operationKind": "query",
            "text": "query CopyLastWeekValidationSection_Query(\n  $personId: ID!\n  $startYear: Int!\n  $startMonth: Int!\n  $startDay: Int!\n  $endYear: Int!\n  $endMonth: Int!\n  $endDay: Int!\n) {\n  viewer {\n    actualPersonId\n    component(name: \"copy_last_week_validation_section\")\n    ...CopyLastWeekValidationSection_viewer_3q0Fis\n    id\n  }\n}\n\nfragment CopyLastWeekValidationSection_viewer_3q0Fis on Viewer {\n  company {\n    person(id: $personId) {\n      doneTasksExcluded: validatedTimeRegsInDateRange(startYear: $startYear, startMonth: $startMonth, startDay: $startDay, endYear: $endYear, endMonth: $endMonth, endDay: $endDay, excludeDoneTasks: true) {\n        timeRegRequest {\n          minutesRegistered\n          billableMinutesRegistered\n        }\n        valid\n        validationReasons\n      }\n      doneTasksIncluded: validatedTimeRegsInDateRange(startYear: $startYear, startMonth: $startMonth, startDay: $startDay, endYear: $endYear, endMonth: $endMonth, endDay: $endDay, excludeDoneTasks: false) {\n        timeRegRequest {\n          minutesRegistered\n          billableMinutesRegistered\n        }\n        valid\n        validationReasons\n      }\n      id\n    }\n    id\n  }\n}\n"
        }
    };
})();
node.hash = 'f25d82316e28371fe46eb09cc559e7e3';
export default node;
