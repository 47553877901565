import React from 'react';

const PremiumIcon = props => (
	<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width={props.width || '14px'} height={props.height || '10px'} viewBox="0 0 14 10" version="1.1">
		<g id="Premium" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<g id="Icon" transform="translate(-117.000000, -144.000000)" fill="#E49F34" fill-rule="nonzero">
				<path
					d="M123.889655,144.005633 C123.636667,144.031787 123.400625,144.148076 123.22958,144.334873 L120.884067,147.590441 L118.578016,145.920601 L118.483672,145.864617 C118.144423,145.702862 117.740319,145.73403 117.431797,145.946413 C117.146047,146.143119 116.984819,146.466235 117.001129,146.802272 L117.758855,153.476758 C117.792749,153.774645 118.050463,154 118.35723,154 L129.640723,154 C129.947489,154 130.205203,153.774645 130.239097,153.476758 L130.989662,146.880209 C131.044134,146.532122 130.88048,146.16279 130.566156,145.946413 C130.257634,145.73403 129.853529,145.702862 129.514281,145.864617 L129.419794,145.920704 L127.113133,147.590441 L124.812415,144.389078 C124.572892,144.121391 124.292518,144 123.998976,144 L123.889655,144.005633 Z M121.768051,148.409093 L124.000481,145.300735 L126.229306,148.408265 C126.392702,148.635829 126.646537,148.786532 126.928874,148.825571 L127.034347,148.834839 C127.28034,148.844163 127.523393,148.76792 127.717908,148.6174 L129.747147,147.147059 L129.101438,152.823529 L118.895763,152.823529 L118.250053,147.147059 L120.295454,148.627852 C120.5013,148.78806 120.786774,148.863491 121.067781,148.825124 C121.350078,148.786581 121.604103,148.636413 121.768051,148.409093 Z"
					id="Crown-Icon"
				/>
			</g>
		</g>
	</svg>
);

export default PremiumIcon;
