import { format } from 'date-fns';
import React from 'react';
import { useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import { FlexRow, Icon, Text } from '@forecast-it/design-system';
import { getWorkingHoursForPersonForDate } from '../../../../../util/time-registration/working-hours/WorkingHoursUtil';
import Util from '../../../../../util/util';
function workingHoursForDayFromViewer({ viewer, date }) {
    var _a;
    const person = (_a = viewer.company) === null || _a === void 0 ? void 0 : _a.person;
    if (!person)
        return 0;
    const personStartDate = person.startDate
        ? new Date(person.startDate)
        : person.createdAt
            ? new Date(person.createdAt)
            : null;
    if (!personStartDate)
        return 0;
    const personEndDate = person.endDate ? new Date(person.endDate) : null;
    const workingHoursByDay = {
        sunday: person.sunday || 0,
        monday: person.monday || 0,
        tuesday: person.tuesday || 0,
        wednesday: person.wednesday || 0,
        thursday: person.thursday || 0,
        friday: person.friday || 0,
        saturday: person.saturday || 0,
    };
    return getWorkingHoursForPersonForDate({ date, workingHoursByDay, personStartDate, personEndDate });
}
export function TimeRegistrationModalTimeOnDayPlaceholder() {
    return (React.createElement(FlexRow, { gap: "xl" },
        React.createElement(Text, null, `-- out of -- registered on --/--/----`)));
}
function TimeRegistrationModalTimeOnDay({ viewer, date }) {
    var _a, _b, _c, _d;
    const workingHoursForTheDay = workingHoursForDayFromViewer({ viewer, date });
    const intl = useIntl();
    const timeOnDay = (_d = (_c = (_b = (_a = viewer.company) === null || _a === void 0 ? void 0 : _a.person) === null || _b === void 0 ? void 0 : _b.timeRegistrations) === null || _c === void 0 ? void 0 : _c.edges) === null || _d === void 0 ? void 0 : _d.reduce((acc, edge) => { var _a; return acc + (((_a = edge === null || edge === void 0 ? void 0 : edge.node) === null || _a === void 0 ? void 0 : _a.minutesRegistered) || 0); }, 0);
    const formattedTimeOnDay = Util.convertMinutesToFullHour(timeOnDay, intl, true);
    const formattedWorkingHoursForTheDay = Util.convertMinutesToFullHour(workingHoursForTheDay, intl, true);
    const formattedDate = format(date, 'P');
    return (React.createElement(FlexRow, { gap: "xl" },
        React.createElement(Text, { color: "medium", noWrap: true }, `${formattedTimeOnDay} out of ${formattedWorkingHoursForTheDay} registered on ${formattedDate}`),
        timeOnDay === workingHoursForTheDay && React.createElement(Icon, { icon: "tick", size: "l" })));
}
export const TimeRegistrationModalTimeOnDayQuery = graphql `
	query TimeRegistrationModalTimeOnDay_Query($personId: ID, $date: String) {
		viewer {
			actualPersonId
			component(name: "time_registration_modal_time_on_day")
			...TimeRegistrationModalTimeOnDay_viewer @arguments(personId: $personId, date: $date)
		}
	}
`;
export default createFragmentContainer(TimeRegistrationModalTimeOnDay, {
    viewer: graphql `
		fragment TimeRegistrationModalTimeOnDay_viewer on Viewer
		@argumentDefinitions(personId: {type: "ID!"}, date: {type: "String"}) {
			company {
				person(id: $personId) {
					startDate
					endDate
					createdAt
					monday
					tuesday
					wednesday
					thursday
					friday
					saturday
					sunday
					timeRegistrations(startDate: $date, endDate: $date) {
						edges {
							node {
								minutesRegistered
							}
						}
					}
				}
			}
		}
	`,
});
