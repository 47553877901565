import React, { useMemo, useState } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Breadcrumbs, Button, Link, SearchField, Table, TableBar, Tabs, Text } from '@forecast-it/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import Styled from 'styled-components';
import { SIZE } from 'web-components';
import { MODAL_TYPE, showModal } from '../../../shared/components/modals/generic_modal_conductor';
import Util from '../../../shared/util/util';
import CustomScrollDiv from '../../../shared/components/scroll-bars/custom_scroll_div';
import { INVOICE_INTEGRATIONS } from './InvoiceIntegrationsUtils';
import InvoicingIntegrationIcon from './InvocingIntegrationIcon';
const PageWrapper = Styled.div `
  padding: 32px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const BreadcrumbsWrapper = Styled.div `
  padding-bottom: 24px;
 `;
const sortedBillFromArray = (viewer) => {
    var _a;
    const org = (_a = viewer.company) === null || _a === void 0 ? void 0 : _a.billFroms;
    const array = Array.isArray(org) ? [...org] : [];
    const integrationSortOrder = Object.keys(INVOICE_INTEGRATIONS);
    array.sort((a, b) => {
        const intIndexA = integrationSortOrder.indexOf(a.integration);
        const intIndexB = integrationSortOrder.indexOf(b.integration);
        if (intIndexA === intIndexB) {
            return a.name.localeCompare(b.name);
        }
        else {
            return intIndexA > intIndexB ? 1 : -1;
        }
    });
    return array;
};
const getAssociatedClients = (viewer, billToId) => {
    var _a, _b, _c;
    return (_c = (_b = (_a = viewer.company) === null || _a === void 0 ? void 0 : _a.clients) === null || _b === void 0 ? void 0 : _b.edges) === null || _c === void 0 ? void 0 : _c.filter(edge => {
        var _a, _b, _c;
        return (_c = (_b = (_a = edge === null || edge === void 0 ? void 0 : edge.node) === null || _a === void 0 ? void 0 : _a.clientBillTos) === null || _b === void 0 ? void 0 : _b.edges) === null || _c === void 0 ? void 0 : _c.some(clientBillToEdge => {
            var _a, _b;
            return ((_b = (_a = clientBillToEdge === null || clientBillToEdge === void 0 ? void 0 : clientBillToEdge.node) === null || _a === void 0 ? void 0 : _a.billTo) === null || _b === void 0 ? void 0 : _b.id) === billToId;
        });
    }).map(edge => {
        var _a, _b;
        const client = edge === null || edge === void 0 ? void 0 : edge.node;
        const invoiced = ((_b = (_a = client === null || client === void 0 ? void 0 : client.clientBillTos) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.some(clientBillToEdge => {
            var _a;
            const clientBillTo = clientBillToEdge === null || clientBillToEdge === void 0 ? void 0 : clientBillToEdge.node;
            return ((_a = clientBillTo === null || clientBillTo === void 0 ? void 0 : clientBillTo.billTo) === null || _a === void 0 ? void 0 : _a.id) === billToId && (clientBillTo === null || clientBillTo === void 0 ? void 0 : clientBillTo.invoiced);
        })) || false;
        return {
            id: client === null || client === void 0 ? void 0 : client.id,
            name: client === null || client === void 0 ? void 0 : client.name,
            invoiced,
        };
    });
};
const mappedBillTos = (viewer, billFromId) => {
    var _a, _b, _c, _d, _e;
    return (_e = (_d = (_c = (_b = (_a = viewer.company) === null || _a === void 0 ? void 0 : _a.billFroms) === null || _b === void 0 ? void 0 : _b.find(billFrom => (billFrom === null || billFrom === void 0 ? void 0 : billFrom.id) === billFromId)) === null || _c === void 0 ? void 0 : _c.billTos) === null || _d === void 0 ? void 0 : _d.edges) === null || _e === void 0 ? void 0 : _e.map(edge => { var _a; return (Object.assign({ clients: getAssociatedClients(viewer, ((_a = edge === null || edge === void 0 ? void 0 : edge.node) === null || _a === void 0 ? void 0 : _a.id) || '') }, edge === null || edge === void 0 ? void 0 : edge.node)); });
};
const SettingsFinanceBillTo = ({ viewer }) => {
    var _a, _b;
    const { formatMessage } = useIntl();
    const billFroms = useMemo(() => sortedBillFromArray(viewer), [viewer]);
    const [billFromId, setBillFromId] = useState(((_a = billFroms[0]) === null || _a === void 0 ? void 0 : _a.id) || '');
    const billFromIntegrationName = (_b = billFroms.find(billFrom => billFrom.id === billFromId)) === null || _b === void 0 ? void 0 : _b.integration;
    const billTos = useMemo(() => mappedBillTos(viewer, billFromId), [viewer, billFromId]);
    const [searchFilterValue, setSearchFilterValue] = useState('');
    const openModal = () => {
        showModal({
            type: MODAL_TYPE.ADD_BILL_TO_ENTITY,
            billFromId,
        });
    };
    const removeBillTo = row => {
        showModal({
            type: row.invoiced ? MODAL_TYPE.INVOICED_BILL_TO_WARNING : MODAL_TYPE.REMOVE_BILL_TO,
            billToId: row.id,
            billFromId,
        });
    };
    const associateBillTo = row => {
        showModal({
            type: MODAL_TYPE.ASSOCIATE_BILL_TO,
            billToId: row.id,
            billFromId,
        });
    };
    const disassociateBillTo = row => {
        if (row.clients.length === 1 && row.clients[0].invoiced) {
            showModal({
                type: MODAL_TYPE.INVOICED_BILL_TO_WARNING,
                billToId: row.id,
                clientId: row.clients[0].id,
            });
        }
        else {
            showModal({
                type: MODAL_TYPE.UNASSOCIATE_BILL_TO,
                billToId: row.id,
                billFromId,
            });
        }
    };
    return (React.createElement(PageWrapper, null,
        React.createElement(BreadcrumbsWrapper, null,
            React.createElement(Breadcrumbs, null,
                React.createElement(Breadcrumbs.Item, null,
                    React.createElement(Link, { href: '/admin/finance#invoicing' },
                        React.createElement(FormattedMessage, { id: "settings_finance.menuTitle" }))),
                React.createElement(Breadcrumbs.Item, null,
                    React.createElement(Text, null, formatMessage({ id: 'settings_finance.manage_bill_to_entities' }))))),
        React.createElement(TableBar, { title: formatMessage({ id: 'settings_finance.manage_bill_to_entities' }) },
            React.createElement(SearchField, { id: TableBar.ELEMENTS.SEARCH, value: searchFilterValue, onChange: setSearchFilterValue }),
            React.createElement(Button, { onClick: openModal },
                React.createElement(FormattedMessage, { id: "settings_finance.add_bill_to_entity" })),
            React.createElement(Tabs, { id: TableBar.ELEMENTS.TABS, value: billFromId, onValueChange: setBillFromId },
                React.createElement(Tabs.List, null, billFroms.map(billFrom => (React.createElement(Tabs.Tab, { key: billFrom.id, value: billFrom.id },
                    React.createElement(InvoicingIntegrationIcon, { integration: billFrom.integration, size: SIZE.TAB }),
                    billFrom.name,
                    " (",
                    billFrom.billTos.edges.length,
                    ")")))))),
        !!billTos && (React.createElement(CustomScrollDiv, { autoHide: false, style: { flexGrow: 1 } },
            React.createElement(Table, { data: billTos, searchValue: searchFilterValue, width: '100%' },
                React.createElement(Table.TextColumn, { accessorKey: 'name', header: formatMessage({ id: 'settings_finance.bill_to_entity_name' }), allowSorting: true }),
                React.createElement(Table.TextColumn, { accessorKey: 'address', header: formatMessage({ id: 'settings_finance.address' }), allowSorting: true }),
                Util.shouldDisplayTaxIdColumn(billFromIntegrationName) && (React.createElement(Table.TextColumn, { accessorKey: 'taxId', header: formatMessage({ id: 'settings.client.vat_number' }), allowSorting: true })),
                React.createElement(Table.ArrayColumn, { accessorKey: 'clients', tooltipRender: values => values.map(value => value.name).join('\n'), render: values => (values.length ? values[0].name : 'None'), renderLimit: 1, renderLimited: count => `Multiple clients (${count})`, header: formatMessage({ id: 'settings_finance.associated_clients' }), allowSorting: true }),
                React.createElement(Table.ActionColumn, { header: 'Actions', hideHeader: true },
                    React.createElement(Table.ActionColumn.Option, { onClick: associateBillTo },
                        React.createElement(FormattedMessage, { id: 'settings_finance.bill_to.associate_client' })),
                    React.createElement(Table.ActionColumn.Option, { onClick: disassociateBillTo },
                        React.createElement(FormattedMessage, { id: 'settings_finance.bill_to.disassociate_client' })),
                    React.createElement(Table.ActionColumn.Option, { onClick: removeBillTo },
                        React.createElement(FormattedMessage, { id: 'settings_finance.bill_to.remove' }))))))));
};
export const SettingsFinanceBillToQuery = graphql `
	query SettingsFinanceBillTo_Query {
		viewer {
			actualPersonId
			component(name: "settings_finance")
			...SettingsFinanceBillTo_viewer
		}
	}
`;
export default createFragmentContainer(SettingsFinanceBillTo, {
    viewer: graphql `
		fragment SettingsFinanceBillTo_viewer on Viewer {
			id
			company {
				id
				billFroms {
					id
					integration
					name
					billTos(first: 10000) @connection(key: "BillFrom_billTos", filters: []) {
						edges {
							node {
								id
								name
								address
								taxId
								invoiced
							}
						}
					}
				}
				clients(first: 100000) {
					edges {
						node {
							id
							name
							clientBillTos(first: 10000) @connection(key: "Client_clientBillTos", filters: []) {
								edges {
									node {
										id
										billFrom {
											id
										}
										billTo {
											id
										}
										clientId
										invoiced
									}
								}
							}
						}
					}
				}
			}
		}
	`,
});
