/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ProgramWorkBreakdown_viewer$ref = any;
export type ProgramWorkBreakdown_QueryVariables = {|
  prefix?: ?string
|};
export type ProgramWorkBreakdown_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: ProgramWorkBreakdown_viewer$ref,
  |}
|};
export type ProgramWorkBreakdown_Query = {|
  variables: ProgramWorkBreakdown_QueryVariables,
  response: ProgramWorkBreakdown_QueryResponse,
|};
*/


/*
query ProgramWorkBreakdown_Query(
  $prefix: String
) {
  viewer {
    actualPersonId
    component(name: "program_work_breakdown")
    ...ProgramWorkBreakdown_viewer_3WHZd4
    id
  }
}

fragment ProgramWorkBreakdown_viewer_3WHZd4 on Viewer {
  id
  program(prefix: $prefix) {
    id
    projectTaskStatusBreakdown {
      todoPercentage
      inProgressPercentage
      donePercentage
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "prefix",
    "type": "String"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "program_work_breakdown"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"program_work_breakdown\")"
},
v3 = [
  {
    "kind": "Variable",
    "name": "prefix",
    "variableName": "prefix"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProgramWorkBreakdown_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "args": (v3/*: any*/),
            "kind": "FragmentSpread",
            "name": "ProgramWorkBreakdown_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProgramWorkBreakdown_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "ProgramType",
            "kind": "LinkedField",
            "name": "program",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectTaskStatusBreakdown",
                "kind": "LinkedField",
                "name": "projectTaskStatusBreakdown",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "todoPercentage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "inProgressPercentage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "donePercentage",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "ProgramWorkBreakdown_Query",
    "operationKind": "query",
    "text": "query ProgramWorkBreakdown_Query(\n  $prefix: String\n) {\n  viewer {\n    actualPersonId\n    component(name: \"program_work_breakdown\")\n    ...ProgramWorkBreakdown_viewer_3WHZd4\n    id\n  }\n}\n\nfragment ProgramWorkBreakdown_viewer_3WHZd4 on Viewer {\n  id\n  program(prefix: $prefix) {\n    id\n    projectTaskStatusBreakdown {\n      todoPercentage\n      inProgressPercentage\n      donePercentage\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4dd08b53fa8d2c312d5c4046bf2d3f6d';

module.exports = node;
