import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import Calendar from '../../shared/components/date-picker/calendar';
import DatePickerButton from '../../../components/new-ui/date-controls/date_picker_button';

class DatePicker extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showCalendar: false,
		};
	}

	toggleCalendar(e) {
		e.preventDefault();
		this.setState({showCalendar: !this.state.showCalendar});
	}
	handleDateClick(moment) {
		this.props.onDatePick(moment);
		this.setState({showCalendar: false});
	}
	onBlur(e) {
		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11
		// return if element being focused is part of calendar
		if (
			newTarget &&
			(newTarget.className === 'previous-control' ||
				newTarget.className === 'next-control' ||
				newTarget.className === 'clear-button' ||
				newTarget.className.includes('calendar-wrapper') ||
				newTarget.className.includes('date-picker-button') ||
				newTarget.className.includes('calendar-day'))
		)
			return;
		this.setState({showCalendar: false});
	}
	handleKeyDown(e) {
		//close calendar on escape click
		if (e && e.keyCode === 27) {
			this.setState({showCalendar: false});
		}
	}
	render() {
		const {formatMessage} = this.props.intl;
		const {locked, value, label, clearable, disabledDates} = this.props;
		return (
			<div className="date-picker-wrapper-new">
				<DatePickerButton
					hideLabel={this.props.hideLabel}
					isNewDateRange={this.props.isNewDateRange}
					onClick={this.toggleCalendar.bind(this)}
					startDate={this.props.value}
					isDateRangePicker={false}
					locked={locked}
					label={label}
					boldDayMonth={this.props.boldDayMonth}
					useTextFormat={this.props.useTextFormat}
					hideButtonIcon={this.props.hideButtonIcon}
					customPlaceholder={this.props.customPlaceholder}
					customDateFormat={this.props.customDateFormat}
					customClasses={this.state.showCalendar ? 'expanded' : ''}
				/>
				{this.state.showCalendar ? (
					<ReactCSSTransitionGroup transitionName="appear" transitionEnterTimeout={500} transitionLeaveTimeout={300}>
						<div
							className={'calendar-wrapper' + (clearable ? ' clearable' : '')}
							tabIndex="0"
							onBlur={this.onBlur.bind(this)}
							ref={div => (this.calendar_wrapper = div)}
							onKeyDown={this.handleKeyDown.bind(this)}
						>
							<div className="triangle-up">
								<svg width="24" height="10">
									<polygon
										style={{fill: 'white', stroke: '#e6e6e6', strokeWidth: 1, fillRule: 'evenodd'}}
										points="0,10 12,0 24,10"
									/>
								</svg>
								<div className="border-cover" />
							</div>
							<Calendar
								disabledDates={disabledDates}
								value={value}
								onDateClick={this.handleDateClick.bind(this)}
								clearable={clearable}
								isSinglePicker={true}
							/>
							{clearable ? (
								<button className="clear-button" onClick={this.handleDateClick.bind(this, null)}>
									{formatMessage({id: 'common.clear_date'})}
								</button>
							) : null}
						</div>
					</ReactCSSTransitionGroup>
				) : null}
			</div>
		);
	}
}
DatePicker.propTypes = {
	value: PropTypes.object,
	onDatePick: PropTypes.func,
	label: PropTypes.string,
	locked: PropTypes.bool,
	clearable: PropTypes.bool,
	dateFormat: PropTypes.string,
	useTextFormat: PropTypes.bool,
};
export default injectIntl(DatePicker);
