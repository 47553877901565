import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {CrossIcon, Dropdown, HexagonText, InputLabel, Tag, SelectDropdown} from 'web-components';
import _ from 'lodash';
import {DropdownOption, Selections, SelectionsHeader, SelectionsHeaderActions} from './SkillsSelections.styled';
import {Scrollbars} from 'react-custom-scrollbars';
import Search from '../../../../../components/new-ui/search';
import {createFragmentContainer, graphql} from 'react-relay';
import {personIsVirtualUser} from '../../../util/PermissionsUtil';
import {PreviewSelector} from './PreviewSelector.styled';
import {FlexGroup, HoverItem, HoverShow} from '../../modals/ItemList.styled';
import ContextMenu from '../../context-menus/context_menu';
import DotsHorizontal from '../../../../../images/cross-section-icons/dots_horizontal.svg';
import {profilePicSrc} from '../../../../../directApi';

const PersonFilterType = {
	ACTIVE_PEOPLE: 'active_people',
	USERS: 'users',
	VIRTUAL_RESOURCES: 'virtual_resources',
	DEACTIVATED: 'deactivated',
};

const SkillLevel = {
	NO_LEVEL: 'no_level',
	ALL: 'all',
};

const PersonsPreviewSelector = ({
	persons,
	selectedPersons,
	onChange,
	renderTag,
	selectionHeaderLabel,
	hideSelectionHeaderActions = false,
	required = false,
	noAdd = false,
	skillLevelsEnabled,
	skillLevels,
	onSkillLevelChange,
	skill,
	selectedSkillLevel,
}) => {
	const {formatMessage} = useIntl();
	const personById = _.keyBy(persons, person => person.id);
	const [searchValue, setSearchValue] = useState('');
	const [personFilterType, setPersonFilterType] = useState(PersonFilterType.ACTIVE_PEOPLE);
	const [skillLevelFilterValue, setSkillLevelFilterValue] = useState(selectedSkillLevel || SkillLevel.ALL);
	const [showSkillContextMenu, setShowSkillContextMenu] = useState(null);
	const [skillContextMenuX, setSkillContextMenuX] = useState(null);
	const [skillContextMenuY, setSkillContextMenuY] = useState(null);

	const skillLevelFilterValues = [
		{level: '', id: SkillLevel.ALL, description: 'All Levels'},
		...(skillLevels || []),
		{
			level: '',
			id: SkillLevel.NO_LEVEL,
			description: 'Level not set',
		},
	];

	const inputLabelText = required
		? `${formatMessage({id: 'common.people'})}`
		: `${formatMessage({id: 'common.people'})} ${formatMessage({id: 'common.field_optional_label'})}`;

	if (!selectionHeaderLabel) {
		selectionHeaderLabel = formatMessage({id: `common.selected`});
	}

	const displayContextMenu = (event, person) => {
		const target = event.target;

		if (target) {
			const {bottom, right} = target.getBoundingClientRect();
			setSkillContextMenuX(right);
			setSkillContextMenuY(bottom);
			setShowSkillContextMenu(person.id === showSkillContextMenu ? null : person.id);
		}
	};

	const getUserType = person => {
		if (person.active) {
			if (!personIsVirtualUser(person)) {
				return formatMessage({id: 'filter_type.resource_type.user'});
			} else {
				return formatMessage({id: 'filter_type.resource_type.virtual_resource'});
			}
		} else {
			return formatMessage({id: 'filter_type.resource_type.deactivated'});
		}
	};

	const personTypeFilter = person => {
		// If we are hiding the person filter selector we should not do any
		// person type filtering
		if (hideSelectionHeaderActions) {
			return true;
		}
		if (personFilterType === PersonFilterType.ACTIVE_PEOPLE) {
			return person.active;
		} else if (personFilterType === PersonFilterType.USERS) {
			return person.active && !personIsVirtualUser(person);
		} else if (personFilterType === PersonFilterType.VIRTUAL_RESOURCES) {
			return person.active && personIsVirtualUser(person);
		} else if (personFilterType === PersonFilterType.DEACTIVATED) {
			return !person.active;
		}
	};

	const skillLevelFilter = person => {
		// If we are hiding the person filter selector we should not do any
		// person type filtering
		if (hideSelectionHeaderActions) {
			return true;
		}
		if (skillLevelFilterValue === SkillLevel.ALL) {
			return true;
		} else if (skillLevelFilterValue === SkillLevel.NO_LEVEL) {
			return person.skillLevelId === undefined;
		} else {
			return person.skillLevelId === skillLevelFilterValue;
		}
	};

	const getSearchString = person =>
		[person.fullName, person.role?.name, getUserType(person)].map(string => string?.toLowerCase()).join(' ');

	const searchFilter = person => {
		return getSearchString(person).includes(searchValue.toLowerCase());
	};

	const selected = selectedPersons
		.filter(skillLevelFilter)
		.map(selectedPerson => personById[selectedPerson.personId])
		.filter(Boolean)
		.filter(searchFilter)
		.filter(personTypeFilter)
		.reverse();

	const addPerson = (addedPeopleIds, _) => {
		onChange(
			selectedPersons.concat(
				addedPeopleIds.map(personId => ({
					personId,
					skillId: skill?.id,
					skillLevelId: Object.values(SkillLevel).includes(selectedSkillLevel) ? undefined : selectedSkillLevel,
				}))
			)
		);
	};

	const onRemove = person => {
		onChange(selectedPersons.filter(sp => sp.personId !== person.id));
	};

	const shouldDisplayContextMenu = skill =>
		showSkillContextMenu && showSkillContextMenu === skill.id && skillContextMenuX && skillContextMenuY;

	const onHoverShowClick = skillLevelsEnabled
		? (event, person) => displayContextMenu(event, person)
		: (event, person) => onRemove(person);

	const getContextMenuOptions = person => {
		const options = [];

		const selectedSkillLevel = selectedPersons.find(sp => sp.personId === person.id);

		skillLevels.forEach(skillLevel => {
			const isSelected = selectedSkillLevel?.skillLevelId === skillLevel.id;

			options.push({
				text: `${skillLevel.level} ${skillLevel.description}`,
				onClick: () => (isSelected ? undefined : onSkillLevelChange(person.id, skillLevel.id)),
				disabled: isSelected,
				selected: isSelected,
			});
		});

		options.push({
			text: formatMessage({id: 'settings_people.remove_skill'}),
			textColor: '#DB0900',
			onClick: () => onRemove(person),
			bottomOption: true,
		});

		return options;
	};

	return (
		<PreviewSelector>
			{!noAdd && (
				<InputLabel
					text={inputLabelText}
					child={
						<Dropdown
							isMultiSelect
							usePortal
							hideSelected
							onSelect={addPerson}
							selectedItems={selectedPersons.map(sp => sp.personId)}
							name={formatMessage({id: 'settings_skills.assign_people'})}
							size={'large'}
							cy={'skill-persons'}
						>
							{persons.map((person, index) => (
								<DropdownOption
									data-cy={`person-option-${index}`}
									key={person.id}
									value={person.id}
									searchString={getSearchString(person)}
									cy={`person:${person.fullName}`}
								>
									<HexagonText
										text={person.fullName}
										subText={person.role?.name || ''}
										imgUrl={profilePicSrc(person.profilePictureId)}
									/>
									<Tag color={'#a5a5ae'} name={getUserType(person)} />
								</DropdownOption>
							))}
						</Dropdown>
					}
				/>
			)}
			<Selections data-cy={'selected-persons'}>
				<SelectionsHeader>
					<InputLabel text={`${selectionHeaderLabel} (${selected.length})`} />
					{!hideSelectionHeaderActions && (
						<SelectionsHeaderActions>
							<Search
								dataCy={'selected-search'}
								key={`selected-search`}
								value={searchValue}
								onChange={setSearchValue}
							/>
							<SelectDropdown
								cy={'selected-filter'}
								selectedValue={personFilterType}
								onClick={setPersonFilterType}
							>
								{Object.values(PersonFilterType).map(personFilterType => (
									<SelectDropdown.Option value={personFilterType} cy={`filter-${personFilterType}`}>
										{formatMessage({id: `settings_skills.filter_person.${personFilterType}`})}
									</SelectDropdown.Option>
								))}
							</SelectDropdown>
							{skillLevelsEnabled && (
								<SelectDropdown
									cy={'selected-filter-level'}
									selectedValue={skillLevelFilterValue}
									onClick={setSkillLevelFilterValue}
								>
									{skillLevelFilterValues.map(skillLevel => (
										<SelectDropdown.Option value={skillLevel.id} cy={`filter-${skillLevel.level}`}>
											{skillLevel.level + ' ' + skillLevel.description}
										</SelectDropdown.Option>
									))}
								</SelectDropdown>
							)}
						</SelectionsHeaderActions>
					)}
				</SelectionsHeader>
				<Scrollbars style={{height: '200px'}}>
					{selected.map((person, index) => (
						<HoverItem data-cy={`selected-person-${index}`} key={person.id}>
							<HexagonText
								value={person.id}
								searchString={person.fullName}
								text={person.fullName}
								subText={person.role?.name || ''}
								imgUrl={profilePicSrc(person.profilePictureId)}
							/>
							<FlexGroup>
								<Tag color={'#a5a5ae'} name={getUserType(person)} />
								{renderTag && renderTag(person)}
								<HoverShow onClick={event => onHoverShowClick(event, person)}>
									{skillLevelsEnabled ? (
										<img src={DotsHorizontal} alt="Skill options menu" />
									) : (
										<CrossIcon size={CrossIcon.SIZE.STANDARD} color={'#000'} cy={'clear-icon'} />
									)}
								</HoverShow>
							</FlexGroup>

							{shouldDisplayContextMenu(person) && (
								<ContextMenu
									key={person.id}
									cy="skill-options-context-menu"
									options={getContextMenuOptions(person)}
									closeContextMenu={() => setShowSkillContextMenu(null)}
									contextMenuPosition={{
										x: skillContextMenuX,
										y: skillContextMenuY,
									}}
								/>
							)}
						</HoverItem>
					))}
				</Scrollbars>
			</Selections>
		</PreviewSelector>
	);
};
export default createFragmentContainer(PersonsPreviewSelector, {
	persons: graphql`
		fragment PersonsPreviewSelector_persons on Person @relay(plural: true) {
			id
			fullName
			profilePictureId
			active
			permissions
			role {
				name
			}
		}
	`,
});
