import Styled, {css} from 'styled-components';
import {CSS_CONSTANTS} from '../../../../../../css_variables';
import dragHandle from '../../../../../../images/cross-section-icons/table/drag-handle.svg';
import chevronDown from '../../../../../../images/v2/components/dropdown/arrow-down.svg';
import chevronUp from '../../../../../../images/v2/components/dropdown/arrow-up.svg';
import chevronDownPurple from '../../../../../../images/v2/components/dropdown/arrow-down-purple.svg';
import chevronUpPurple from '../../../../../../images/v2/components/dropdown/arrow-up-purple.svg';

import showMore from '../../../../../../images/components/overlay/navigation-show-more-vertical.svg';
import iconPriority from '../../../../../../images/v2/components/input-field/ico-priority.svg';
import iconBlocked from '../../../../../../images/v2/components/input-field/ico-blocked.svg';
import iconBug from '../../../../../../images/v2/task-modal/ico-bug-grey.svg';
import checkmark from '../../../../../../images/v2/ok-green.svg';
import whiteHexagon from '../../../../../../images/v2/components/white-hexagon.svg';
import {TaskAddSubtask} from './task-row-elements';
import {alignToFlex} from './utils';
import Util from '../../../../util/util';

export const RowWrapper = Styled.div`
  font-family: 'neue-haas-grotesk-text', sans-serif;
  color: #535353;
  font-size: 13px;
  display: flex;
  align-items: center;
  height: 100%;
  flex-grow: 1;
  box-sizing: border-box;
  background-color: #fff;
  &:hover:not(.no-hover):not(.selected) {
		background-color: #F0E6FD;
		.time-registration-icon {
			opacity: 1;
		}
		.dot-options {
			opacity: 1;
		}
	}
	.time-registration-icon {
		&:not(.active) {
			opacity: 0;
		}
		cursor: pointer;
	}
`;

export const ExpansionWrapper = Styled.div`
	width: 24px;
	min-width: 24px;
	height: 100%;
`;

export const ExpansionToggle = Styled(ExpansionWrapper)`
	width: 16px;
	z-index: 1;
	
	${props =>
		!props.preventExpansion &&
		css`
			background: url(${props => (props.expanded ? chevronUp : chevronDown)});
			background-position: center;
			background-repeat: no-repeat;
			cursor: pointer;

			&:hover {
				background-image: url(${props => (props.expanded ? chevronUpPurple : chevronDownPurple)});
			}
		`}
`;

export const DragHandle = Styled.div`
	opacity: 0;
	width: 14px;
	min-width: 14px;
	height: 14px;
	background: url(${dragHandle});
	background-size: 14px;
	background-position: center;
	background-repeat: no-repeat;
	z-index: 1;
`;

export const DotOptions = Styled.div`
	cursor: pointer;
	opacity: 0;
	width: 16px;
	height: 30px;
	position: absolute;
	right: 10px;
	background: url(${showMore});
	background-size: 25px;
	background-position: center;
	background-repeat: no-repeat;
	border-radius: 4px;
	&:hover,
	&:focus,
	&.clicked {
		opacity: 1;
		background-color: rgba(0, 0, 0, 0.2);
	}
`;

const HeaderRowWrapper = Styled.div`
	background-color: ${({color}) => color};
	color: ${({color}) => Util.getTextColorV2(color)};
	height: 40px;
	width: 100%;
	border-radius: 4px;
	display: flex;
`;

export const HeaderPhase = Styled(HeaderRowWrapper)`
	&:hover {
		filter: brightness(0.9);
		cursor: pointer;
	}
`;

export const HeaderNoPhase = Styled(HeaderRowWrapper)`
	color:#4C4C58;
	background-color:#F7F7FE;
	border-radius: 0px;
	border-top: solid 1px #E6E6E6;
	border-bottom: solid 1px #E6E6E6;
`;

export const BaselineRowWrapper = Styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	font-weight: 500;
	padding-left: 8px;
`;

export const GroupingRowWrapper = Styled(RowWrapper)`
	background-color: #fff;
	width: 100%;
	height: 100%;
`;

export const TaskRowWrapper = Styled(RowWrapper)`
	cursor: grab;
	opacity: ${props => props.draggingParent && !props.draggingThis && '0.5'};
    &:hover ${DragHandle} {
      opacity: ${props => (props.isDragging && !props.draggingThis ? 0 : 1)};
    }
	&:hover ${TaskAddSubtask} {
		opacity: ${props => (props.isDragging && !props.draggingThis ? 0 : 1)};
	}
	&:hover:not(.no-hover):not(.selected) {
		background-color: #F0E6FD;
		.time-registration-icon {
			opacity: 1;
		}
		.dot-options {
			opacity: 1;
		}
	}

	.elem-wrapper {
		font-size: 12px;
		color: ${CSS_CONSTANTS.v2_text_gray};
		box-sizing: border-box;
		position: relative;
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		white-space: nowrap;
		overflow: hidden;
	}

	.time-registration-icon {
		&:not(.active) {
			opacity: 0;
		}
		cursor: pointer;
	}

	.done-check-mark {
		width: 100%;
		height: 100%;
		background: url(${checkmark});
		background-size: 20px;
		background-position: right center;
		background-repeat: no-repeat;
	}

	.dot-options {
		&:not(.clicked) {
			opacity: 0;
		}
		cursor: pointer;
	}

	.drop-down-v2 {
		//margin-left: 4px;
		width: 100%;
		border-radius: 5px;
		height: 30px;
		background-color: #fff;
		&:hover {
			input {
				border-color: ${CSS_CONSTANTS.v2_button_very_light_grey_hovered};
			}
		}
		&.disabled {
			input {
				border-color: transparent;
			}
		}
		input {
			height: 100%;
			background-color: transparent;
			border: solid 1px #e6e6e6;
			//border-top-width: 2px !important;
			//border-bottom-width: 2px !important;
			//padding: 16px 24px 16px 8px;
			&:focus {
				border-color: ${CSS_CONSTANTS.v2_branding_purple};
			}
		}
		.arrow {
			margin-right: 7px;
		}
	}

	.focused-assigned-container {
		display: flex;
		align-items: center;
		padding-right: 0;
		padding-bottom: 0;
		.assigned-dropdown-wrapper {
			height: 30px;
			padding-top: 0;
			input.expanded {
				border-color: ${CSS_CONSTANTS.v2_branding_purple};
				border-bottom-left-radius: 4px;
				border-bottom-right-radius: 4px;
			}
			.arrow,
			.clear-input {
				bottom: 1px;
			}
			.assigned-icons {
				top: 3px;
			}
		}
	}
	.unfocused-assigned-container {
		width: 100%;
		display: flex;
		align-items: center;
		padding: 0;
		cursor: pointer;
		.empty-assigned-container {
			width: 100%;
			font-size: 13px;
			letter-spacing: 0px;
			.arrow {
				width: 16px;
				height: 100%;
				background: url(${chevronDown}) no-repeat center;
				position: absolute;
				right: 12px;
				outline: none;
			}
			&.enabled {
				color: ${CSS_CONSTANTS.v2_text_gray};
				border-color: ${CSS_CONSTANTS.app_border_color};
				background-color: #fff;
				&:hover {
					color: ${CSS_CONSTANTS.v2_text_gray};
				}
			}
		}
		.assigned-list {
			&.disabled {
				background-color: transparent;
				.name {
					color: ${CSS_CONSTANTS.text_base_color} !important;
				}
			}
			padding: 2px 8px;
			height: auto;
			margin: 4px 0 3px 0;
			border: solid 1px #e6e6e6;
			justify-content: left;
			background-color: #fff;
			.assigned-role-unfocused-wrapper {
				.assigned-role-text {
					padding-left: 0;
				}
			}
			&:hover {
				border-color: ${CSS_CONSTANTS.v2_button_very_light_grey_hovered};
			}
			&.disabled {
				.assigned-role-text {
					color: ${CSS_CONSTANTS.v2_text_light_gray};
				}
				.name {
					color: ${CSS_CONSTANTS.v2_text_light_gray};
				}
				&:hover {
					border-color: transparent;
				}
			}
			.arrow {
				right: 12px;
			}
		}
		.additional-assigned-persons {
			height: 26px;
		}
		.more-people-container {
			color: #535353;
			height: 26px;
			width: 26px;
			background-size: 22px;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url(${whiteHexagon});
			font-size: 11px;
			font-weight: 500;
			line-height: 26px;
			text-align: center;
			z-index: 10;
		}
	}

    .task-group,
	.project-group {
		align-items: center;
		width: 100%;
		max-width: 100%;
		display: flex;
		flex-direction: row;
		.blocked-icon,
		.warning-icon,
		.bug-icon,
		.high-priority-flag {
			margin-right: 2px;
			width: 15px;
			min-width: 15px;
			max-width: 15px;
			height: 15px;
			background-size: 12px;
			background-position: center;
			background-repeat: no-repeat;
		}
		.high-priority-flag {
			background-image: url(${iconPriority});
		}
		.blocked-icon {
			background-image: url(${iconBlocked});
		}
		.bug-icon {
			background-image: url(${iconBug});
		}
		.task-id {
			cursor: pointer;
			&:hover {
				color: ${CSS_CONSTANTS.color_purple_hover_active};
			}
		}
		.task-name,
		.project-name {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			padding-left: 0;
			.td-text {
				text-decoration: none;
				cursor: pointer;
				margin: 0;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				&:hover {
					color: ${CSS_CONSTANTS.color_purple_hover_active};
				}
				&.no-click {
					&.project-name {
						color: ${CSS_CONSTANTS.v2_text_light_gray};
						font-style: italic;
					}
					&:hover {
						&.project-name {
							color: ${CSS_CONSTANTS.v2_text_light_gray};
						}
						color: ${CSS_CONSTANTS.v2_text_gray};
						cursor: default;
					}
				}
				&:disabled {
					color: ${CSS_CONSTANTS.v2_input_disabled_grey};
					&:hover {
						color: ${CSS_CONSTANTS.v2_input_disabled_grey};
					}
				}
			}
		}
	}
`;

export const columnStyle = css`
	padding-left: 8px;
	height: 100%;
	display: flex;
	align-items: center;
`;
export const GrowableColumn = Styled.div`
	${columnStyle};
	flex: 1;
	display: grid;
	min-width: ${({width}) => `${width}px`};
`;

export const Column = Styled.div`
	${columnStyle};
	width: ${({width}) => `${width}px`};
	text-align: ${({align}) => `${align}`};
	justify-content: ${props => alignToFlex(props.align)};
	padding-right: ${props => props.paddingRight && `${props.paddingRight}px`};

	outline: none;
	white-space:nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const TaskRowDropdownStyle = Styled.div`
	width: 100%;
	.drop-down-v2 {
		input {
			padding: 10px 30px 10px 8px;
		}
	}
`;

export const PeriodRowWrapper = Styled.div`
	color: #4C4C58;
	background-color: #F7F7FE;
	height: 48px;
	width: 100%;
	border-top: solid 1px #E6E6E6;
	display: flex;
`;

export const PeriodNameWrapper = Styled.span`
	display: flex;
`;

export const PeriodLockedWrapper = Styled.div`
	padding-right: 10px;
`;
