import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import styled from 'styled-components';
import {Switch} from 'web-components';
import InlineLoader from '../../../shared/components/inline-loader/inline_loader';
import {SelectionElementLabel} from '../../../../containers/project/project_settings/ProjectSettings_styled';
import {createToast} from '../../../shared/components/toasts/toast';
import {HorizontalFlexWrapper} from '../salesforce/app_salesforce_style';
import DirectApi from '../../../../directApi';

const pipedriveActivityTypesSection = () => {
	const intl = useIntl();
	const [activityTypes, setActivityTypes] = useState(null);

	useEffect(() => {
		DirectApi.Fetch('pipedrive/activity_types').then(response => {
			const types = response.map(type => {
				return {
					name: type.name,
					key_string: type.key_string,
					create: type.create,
				};
			});
			setActivityTypes(types);
		});
	}, []);

	const handleSwitchChange = (value, selectedActivityType) => {
		const headers = new Headers();
		headers.append('Content-Type', 'application/json');

		const init = {
			headers: headers,
			credentials: 'include',
			method: 'PUT',
			body: JSON.stringify({
				key_string: selectedActivityType.key_string,
				create: !value,
			}),
		};

		return fetch(DirectApi.graphqlServerEndpoint(`pipedrive/activity_types`), init).then(() => {
			const newActivityTypes = activityTypes.map(activityType => {
				if (activityType.key_string === selectedActivityType.key_string) {
					return {
						...activityType,
						create: !value,
					};
				} else return activityType;
			});
			setActivityTypes(newActivityTypes);
			createToast({
				duration: 2000,
				message: intl.formatMessage({id: 'common.changes_saved'}),
			});
		});
	};

	return activityTypes ? (
		<>
			{activityTypes.map(activityType => {
				return (
					<ActivityWrapper>
						<HorizontalFlexWrapper>
							<Switch
								checked={activityTypes.find(type => type.key_string === activityType.key_string).create}
								onChange={value => handleSwitchChange(value, activityType)}
							/>
							<SelectionElementLabel>{activityType.name}</SelectionElementLabel>
						</HorizontalFlexWrapper>
					</ActivityWrapper>
				);
			})}
		</>
	) : (
		<InlineLoader />
	);
};

const ActivityWrapper = styled.div`
	margin: 8px 0;
`;

export default pipedriveActivityTypesSection;
