import React, { useState } from 'react';
import { Avatar, Button, Checkbox, Detail, FlexColumn, FlexRow, Label, Link, Modal, Text, TextField, } from '@forecast-it/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { isInValidEmailFormat } from '../../../../shared/util/EmailUtil';
import { createToast } from '../../../../shared/components/toasts/another-toast/toaster';
import Util from '../../../../shared/util/util';
import CreateReportShareMutation from '../../../../../mutations/create_report_share_mutation';
import DeleteReportShareMutation from '../../../../../mutations/delete_report_share_mutation';
import ChangeAccessForSavedReportMutation from '../../../../../mutations/change_access_for_saved_report_mutation';
import { AlreadySharedContainer, FlexContainer, SharedPersonInfoWrapper } from './ShareReportModal_style';
import { profilePicRectSrc } from '../../../../../directApi';
import { REPORT_TYPE } from '../../../../../constants';
const ShareReportModal = ({ closeModal, reportSharesInput, reportId, privateAccessInput, reportOwner, reportType, }) => {
    const intl = useIntl();
    const [disableAddButton, setDisableAddButton] = useState(true);
    const [inputErrorMessage, setInputErrorMessage] = useState(null);
    const [reportShares, setReportShares] = useState(reportSharesInput);
    const [newEmail, setNewEmail] = useState('');
    const [privateAccess, setPrivateAccess] = useState(privateAccessInput);
    const nonShareableReportTypes = [REPORT_TYPE.UTILIZATION_REPORT, REPORT_TYPE.PROJECT_PORTFOLIO_REPORT];
    const canShareExternally = !nonShareableReportTypes.includes(reportType);
    const emailExistsInAlreadySharedPersonsList = (email) => {
        return reportShares.some(reportShare => reportShare.email === email);
    };
    const validateInput = (input) => {
        if (emailExistsInAlreadySharedPersonsList(input)) {
            setInputErrorMessage('The report has been already shared with this email.');
            setDisableAddButton(true);
        }
        else if (isInValidEmailFormat(input)) {
            setInputErrorMessage(null);
            setDisableAddButton(false);
        }
        else {
            setInputErrorMessage('You have entered an invalid email address.');
            setDisableAddButton(true);
        }
        setNewEmail(input);
    };
    const togglePrivateAccess = () => {
        const onSuccess = () => {
            if (privateAccess) {
                createToast({
                    duration: 5000,
                    message: intl.formatMessage({ id: 'shared_report.report_shared_with_organisation' }),
                });
            }
            else {
                createToast({
                    duration: 5000,
                    message: intl.formatMessage({ id: 'shared_report.report_made_private' }),
                });
            }
            setPrivateAccess(!privateAccess);
        };
        Util.CommitMutation(ChangeAccessForSavedReportMutation, {
            id: reportId,
            privateAccess: !privateAccess,
        }, onSuccess);
    };
    const addReportShares = createSharesResponse => {
        const newReportShares = createSharesResponse.map(response => {
            return {
                email: response.node.user.email,
                sharedId: response.node.id,
            };
        });
        setReportShares([...reportShares, ...newReportShares]);
    };
    const removeReportShare = (deletedShareId) => {
        const filteredReportShares = reportShares.filter(reportShare => reportShare.sharedId !== deletedShareId);
        setReportShares([...filteredReportShares]);
    };
    const handleCreateShare = () => {
        Util.CommitMutation(CreateReportShareMutation, {
            reportId: reportId,
            emails: [newEmail],
        }, data => {
            var _a;
            if ((_a = data.createReportShare.warnings) === null || _a === void 0 ? void 0 : _a.length) {
                const emails = data.createReportShare.warnings
                    .map(warning => warning.substring(warning.indexOf(':') + 1))
                    .join(',');
                createToast({
                    duration: 5000,
                    message: intl.formatMessage({ id: 'shared_report.person_added.error' }) + ' ' + emails,
                });
            }
            else {
                createToast({
                    duration: 5000,
                    message: intl.formatMessage({ id: 'shared_report.report_shared_with_person' }, { email: newEmail }),
                });
            }
            setNewEmail('');
            setDisableAddButton(true);
            addReportShares(data.createReportShare.shareEdges);
        });
    };
    const handleDeleteReportShare = (sharedId) => {
        Util.CommitMutation(DeleteReportShareMutation, {
            id: sharedId,
            reportId: reportId,
        }, data => {
            removeReportShare(data.deleteReportShare.deletedShareId);
            createToast({
                duration: 5000,
                message: intl.formatMessage({ id: 'shared_report.person_removed' }),
            });
        });
    };
    return (React.createElement(Modal, { size: Modal.SIZE.MEDIUM, onCloseModal: closeModal },
        React.createElement(Modal.Title, null,
            React.createElement(FormattedMessage, { id: 'report.share' })),
        React.createElement(Modal.Content, null,
            canShareExternally ? (React.createElement(React.Fragment, null,
                React.createElement(Label, null, intl.formatMessage({ id: 'report.share-input-label' })),
                inputErrorMessage && React.createElement(Text, { color: Text.Color.error }, inputErrorMessage),
                React.createElement(FlexContainer, null,
                    React.createElement(FlexRow, null,
                        React.createElement(TextField, { value: newEmail, onChange: e => {
                                validateInput(e.target.value);
                            }, "data-cy": "share-email" }),
                        React.createElement(Button, { emphasis: 'medium', onClick: handleCreateShare, disabled: disableAddButton }, intl.formatMessage({ id: 'common.add' })))),
                React.createElement(AlreadySharedContainer, null,
                    React.createElement(SharedPersonInfoWrapper, null,
                        React.createElement(FlexRow, null,
                            React.createElement(Avatar, { src: reportOwner.profilePictureId
                                    ? profilePicRectSrc(reportOwner.profilePictureId)
                                    : undefined, size: 'm', name: reportOwner.fullName }),
                            React.createElement(FlexColumn, null,
                                React.createElement(Text, { size: '2' }, reportOwner.fullName),
                                React.createElement(Detail, { size: '1' }, reportOwner.email))),
                        React.createElement(Text, null, "Owner")),
                    reportShares
                        .sort((rs1, rs2) => rs1.email.localeCompare(rs2.email))
                        .map(reportShare => (React.createElement(SharedPersonInfoWrapper, null,
                        React.createElement(FlexContainer, null,
                            React.createElement(Detail, { size: '1' }, reportShare.email)),
                        React.createElement(Link, { size: '2', onClick: () => handleDeleteReportShare(reportShare.sharedId) }, intl.formatMessage({ id: 'common.remove' })))))))) : null,
            React.createElement(Label, null, intl.formatMessage({ id: 'report.share-company-label' })),
            React.createElement(FlexRow, null,
                React.createElement(Checkbox, { "data-cy": 'access-checkbox', checked: !privateAccess, onChange: togglePrivateAccess }),
                React.createElement(Text, null, intl.formatMessage({ id: 'report.private_access.description' })))),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { onPress: closeModal, dataCy: 'modal-btn-done' },
                React.createElement(FormattedMessage, { id: "common.done" })))));
};
export default ShareReportModal;
