import {MODAL_TYPE, showModal} from '../../shared/components/modals/generic_modal_conductor';
import {usingInternalTimeApproval, usingTimeApproval} from '../../shared/util/FeatureUtil';
import Util from '../../shared/util/util';
import UpdateCompanyMutation from '../../../mutations/update_company_mutation.modern';

const toggleInternalTimeApprovalMutation = (companyId, useInternalTimeApproval) => {
	Util.CommitMutation(UpdateCompanyMutation, {
		id: companyId,
		useInternalTimeApproval: !useInternalTimeApproval,
	});
};

export const showToggleInternalTimeApprovalModal = (companyId, companyTimeApprovalSettings, formatMessage) => {
	const useTimeApproval = usingTimeApproval(companyTimeApprovalSettings.useTimeApproval);
	if (!useTimeApproval) {
		return;
	}

	const useInternalTimeApproval = usingInternalTimeApproval(companyTimeApprovalSettings.useInternalTimeApproval);

	showModal({
		type: MODAL_TYPE.CONFIRM_GENERIC,
		title: formatMessage({
			id: useInternalTimeApproval ? 'approval.disable_internal_time_approval' : 'approval.enable_internal_time_approval',
		}),
		warningMessage: formatMessage({
			id: useInternalTimeApproval
				? 'approval.disable_internal_time_approval_desc'
				: 'approval.enable_internal_time_approval_desc',
		}),
		confirmText: formatMessage({id: 'settings.confirm'}),
		onConfirm: () => toggleInternalTimeApprovalMutation(companyId, useInternalTimeApproval),
	});
};
