import React, {useState} from 'react';
import {StarIcon} from 'web-components';
import Util from '../util/util';
import FavoriteTaskMutation from '../../../mutations/favorite_task_mutation';

export const FavouredActionWrapper = ({isFavoured, taskId}) => {
	const [lockedWhileLoading, setLockedWhileLoading] = useState(false);
	const toggleFavourite = () => {
		if (lockedWhileLoading) return;
		setLockedWhileLoading(true);

		const onResponse = () => {
			const timeout = 100;
			setTimeout(() => setLockedWhileLoading(false), timeout);
		};

		Util.CommitMutation(FavoriteTaskMutation, {ids: [taskId], favoured: !isFavoured, favoriteTaskList: true}, onResponse);
	};
	return <StarIcon active={isFavoured} onClick={toggleFavourite} />;
};
