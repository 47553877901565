import React from 'react';

export const AIOutline = ({color, width, height}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16">
		<g fill="none" fillRule="evenodd">
			<path
				stroke={color}
				strokeWidth="1.5"
				d="M14.118 6.016H1.882c-.31 0-.588.133-.79.343-.213.22-.342.524-.342.86v6.829c0 .335.129.64.341.86.203.21.482.342.791.342h12.236c.31 0 .588-.132.79-.342.213-.22.342-.525.342-.86v-6.83c0-.335-.129-.64-.34-.86-.203-.21-.482-.342-.792-.342z"
			/>
			<path
				fill={color}
				d="M11.422 8.829c-.997 0-1.806.838-1.806 1.874 0 .478.178.91.463 1.24h2.686c.285-.332.464-.762.464-1.241 0-1.035-.81-1.875-1.806-1.875l-.001.002zM4.583 8.829c-.998 0-1.806.838-1.806 1.874 0 .478.177.91.463 1.24h2.686c.285-.332.464-.762.464-1.241 0-1.035-.81-1.875-1.806-1.875l-.001.002zM9.95 0c1.005 0 1.87.669 2.15 1.611-.31.12-.57.358-.695.69-.045.15.034.31.18.366.147.056.313-.01.38-.153.097-.248.348-.402.613-.375.857.158 1.52.823 1.683 1.66H1.74c.165-.845.83-1.502 1.676-1.657.266-.03.519.123.617.372.038.1.127.173.233.19.106.017.213-.024.28-.107.068-.083.087-.196.048-.296-.114-.3-.338-.538-.632-.668-.02-.009-.04-.012-.062-.02C4.183.669 5.045 0 6.05 0c.502 0 .991.171 1.384.483.074.058.115.144.115.236v.76c0 .249.201.45.45.45s.45-.201.45-.45v-.76c0-.092.043-.178.115-.236C8.959.171 9.447 0 9.95 0z"
			/>
		</g>
	</svg>
);
