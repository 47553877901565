import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import ActionMenu from '../../../action-menu/actions_menu';
import {createToast} from '../../../toasts/another-toast/toaster';
import Util from '../../../../util/util';
import DeleteDependencyMutation from '../../../../../../mutations/delete_dependency_mutation';
import Warning from '../../../../../../components/warning';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../../../../constants';
import {MODAL_TYPE, showModal} from '../../../modals/generic_modal_conductor';
import DependencyFrom from './dependency_form';
import WarningIcon from '../../../../../../images/warning_icon';
import TooltipContainer from '../../../tooltips/tooltip_container';

class DependencyContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isEditing: false,
		};
	}

	getActionMenuOptions() {
		const {
			dependency,
			intl: {formatMessage},
		} = this.props;
		const actionMenuOptions = [];

		actionMenuOptions.push({
			text: formatMessage({id: 'dependency_modal.edit_dependency'}),
			onClick: this.toggleEditDependency.bind(this),
			cy: 'edit-dependency-button',
		});

		if (dependency && dependency.node && dependency.node.taskDependsOnThis && dependency.node.taskDependsOnThis.id)
			actionMenuOptions.push({
				text: formatMessage({id: 'task_modal.delete_dependency'}),
				onClick: this.deleteDependency.bind(this, dependency, dependency.node.taskDependsOnThis.id),
				cy: 'delete-dependency-button',
			});

		return actionMenuOptions;
	}

	toggleEditDependency() {
		this.setState({isEditing: !this.state.isEditing});
	}

	deleteDependency(dependency, connectedTaskId, e) {
		const {formatMessage} = this.props.intl;

		if (e) {
			e.stopPropagation();
		}
		const callbackPositive = () => {
			const onSuccess = () => {
				createToast({
					duration: 5000,
					message: this.props.intl.formatMessage({id: 'task_modal.dependency_has_been_deleted'}),
				});
			};
			Util.CommitSchedulingModalUpdate(
				DeleteDependencyMutation,
				{
					id: dependency.node.id,
					taskIdDependsOnThis: dependency.node.taskDependsOnThis.id,
					thisDependsOnTaskId: dependency.node.thisDependsOnTask.id,
				},
				onSuccess
			);
		};

		showModal({
			type: MODAL_TYPE.GENERIC,
			content: (
				<div className="default-warning-modal">
					<Warning messageId="common.delete-confirmation-title" />
					<div className="warning-part-2">{formatMessage({id: 'common.warning.this_action_can_not_be_undone'})}</div>
				</div>
			),
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
					cy: 'cancel-dependency-delete',
				},
				{
					text: formatMessage({id: 'common.delete'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
					callback: callbackPositive,
					cy: 'confirm-dependency-delete',
				},
			],
		});
	}

	getStatusTranslation(status) {
		const {
			intl: {formatMessage},
		} = this.props;
		switch (status) {
			case 'TODO':
				return formatMessage({id: `common.to_do`});
			case 'INPROGRESS':
				return formatMessage({id: `common.in_progress`});
			case 'DONE':
				return formatMessage({id: `common.done`});
		}
	}

	// checks if dependencies are violated based on the dates
	isDependencyViolated() {
		if (this.props.dependencyBefore) {
			const dependencyType = this.props.dependency.node.type;

			const dependency = this.props.dependency.node.thisDependsOnTask;
			const currentTask = this.props.dependency.node.taskDependsOnThis;

			if (!dependency || !currentTask) return false;

			const {
				startDay,
				startMonth,
				startYear,
				deadlineDay: taskEndDay,
				deadlineMonth: taskEndMonth,
				deadlineYear: taskEndYear,
			} = currentTask;
			const {deadlineDay, deadlineMonth, deadlineYear} = dependency;

			const dependencyEndDate = Util.CreateNonUtcMomentDate(deadlineYear, deadlineMonth, deadlineDay);

			if (dependencyType === 'CANNOT_START') {
				const currentTaskStartDate = Util.CreateNonUtcMomentDate(startYear, startMonth, startDay);
				if (!dependencyEndDate || !currentTaskStartDate) {
					return false;
				}
				return dependencyEndDate.isAfter(currentTaskStartDate);
			} else if (dependencyType === 'CANNOT_BE_COMPLETED') {
				const currentTaskEndDate = Util.CreateNonUtcMomentDate(taskEndYear, taskEndMonth, taskEndDay);
				if (!dependencyEndDate || !currentTaskEndDate) {
					return false;
				}
				return dependencyEndDate.isAfter(currentTaskEndDate);
			}
		} else if (!this.props.dependencyBefore) {
			const dependencyType = this.props.dependency.node.type;

			const dependency = this.props.dependency.node.taskDependsOnThis;

			if (!dependency) return false;

			const {deadlineDay: taskEndDay, deadlineMonth: taskEndMonth, deadlineYear: taskEndYear} = this.props.task;
			const {startDay, startMonth, startYear, deadlineDay, deadlineMonth, deadlineYear} = dependency;

			const currentTaskEndDate = Util.CreateNonUtcMomentDate(taskEndYear, taskEndMonth, taskEndDay);

			if (dependencyType === 'CANNOT_START') {
				const dependencyStartDate = Util.CreateNonUtcMomentDate(startYear, startMonth, startDay);
				if (!currentTaskEndDate || !dependencyStartDate) {
					return false;
				}
				return currentTaskEndDate.isAfter(dependencyStartDate);
			} else if (dependencyType === 'CANNOT_BE_COMPLETED') {
				const dependencyEndDate = Util.CreateNonUtcMomentDate(deadlineYear, deadlineMonth, deadlineDay);
				if (!currentTaskEndDate || !dependencyEndDate) {
					return false;
				}
				return currentTaskEndDate.isAfter(dependencyEndDate);
			}
		}

		return false;
	}

	showTaskModal(taskId) {
		Util.showTaskModal(taskId, this.props.history);
	}

	render() {
		const {
			dependencyBefore,
			dependency,
			project,
			task,
			projectTasks,
			locked,
			openDependencyModal,
			intl: {formatMessage},
			cy,
		} = this.props;

		if (dependencyBefore && !dependency.node.thisDependsOnTask) return null;
		if (!dependencyBefore && !dependency.node.taskDependsOnThis) return null;

		const status =
			dependencyBefore === true
				? dependency.node.thisDependsOnTask.statusColumnV2
					? dependency.node.thisDependsOnTask.statusColumnV2.category
					: dependency.node.taskDependsOnThis.statusColumnV2
					? dependency.node.taskDependsOnThis.statusColumnV2.category
					: ''
				: dependency.node.taskDependsOnThis.statusColumnV2
				? dependency.node.taskDependsOnThis.statusColumnV2.category
				: '';

		const isDependencyViolated = this.isDependencyViolated();

		return this.state.isEditing ? (
			<div className="task-bond-container">
				{dependencyBefore === false ? (
					<div className="bond-left" style={{backgroundColor: `${project.projectColor}`}} />
				) : null}
				<DependencyFrom
					isEditing={true}
					toggleForm={this.toggleEditDependency.bind(this)}
					dependency={dependency}
					task={task}
					project={project}
					projectTasks={projectTasks}
					before={dependencyBefore}
				/>
				{dependencyBefore === true ? (
					<div className="bond-right" style={{backgroundColor: `${project.projectColor}`}} />
				) : null}
			</div>
		) : (
			<div className="task-bond-container" key={dependency.node.id} data-cy={cy}>
				{dependencyBefore === false ? (
					<div className="bond-left" style={{backgroundColor: `${project.projectColor}`}} />
				) : null}
				<div
					className={`task-container-wrapper ${status === 'DONE' ? 'faded' : ''}`}
					onClick={!locked ? openDependencyModal : null}
				>
					<div
						className={
							'corner ' +
							((dependencyBefore &&
								dependency.node.thisDependsOnTask.statusColumn &&
								dependency.node.thisDependsOnTask.statusColumn.category === 'DONE') ||
							(dependencyBefore === false &&
								dependency.node.taskDependsOnThis.statusColumn &&
								dependency.node.taskDependsOnThis.statusColumn.category === 'DONE')
								? 'done'
								: '')
						}
					/>
					<div
						className={
							'task-container ' +
							((dependencyBefore &&
								dependency.node.thisDependsOnTask.statusColumn &&
								dependency.node.thisDependsOnTask.statusColumn.category === 'DONE') ||
							(dependencyBefore === false &&
								dependency.node.taskDependsOnThis.statusColumn &&
								dependency.node.taskDependsOnThis.statusColumn.category === 'DONE')
								? 'done'
								: '')
						}
					>
						{!locked ? (
							<ActionMenu options={this.getActionMenuOptions()} whiteInner={true} cy="dependency-action-menu" />
						) : null}
						<div
							className={'top-line' + (dependencyBefore ? ' clickable' : '')}
							onClick={
								dependencyBefore
									? this.showTaskModal.bind(this, dependency.node.thisDependsOnTask.companyTaskId)
									: null
							}
						>
							{isDependencyViolated ? (
								<TooltipContainer
									infoText={formatMessage({id: 'task_modal.dependency_dates_warning'})}
									triangleLocation={'bottomMiddle'}
								>
									<WarningIcon ref={node => (this.warningIcon = node)} danger={true} />
								</TooltipContainer>
							) : null}
							{formatMessage({id: 'common.finish'})}
							{dependencyBefore === true
								? ` T${dependency.node.thisDependsOnTask.companyTaskId}`
								: ` T${task.companyTaskId}`}
							{dependencyBefore === true ? (
								<div className="dependency-status">{this.getStatusTranslation(status)}</div>
							) : null}
						</div>

						<div className="mid-line">
							{dependencyBefore === true ? `${dependency.node.thisDependsOnTask.name}` : ` ${task.name}`}
						</div>

						{dependencyBefore ? (
							<div className="bottom-line">
								{dependency.node.type === 'CANNOT_START'
									? formatMessage({id: 'dependency_modal.to_start'})
									: formatMessage({id: 'task_modal.to_finish'})}{' '}
								T{task.companyTaskId}
							</div>
						) : (
							<div
								className="bottom-line clickable"
								onClick={this.showTaskModal.bind(this, dependency.node.taskDependsOnThis.companyTaskId)}
							>
								{dependency.node.type === 'CANNOT_START'
									? formatMessage({id: 'dependency_modal.to_start'})
									: formatMessage({id: 'task_modal.to_finish'})}
								{` T${dependency.node.taskDependsOnThis.companyTaskId}`}
								{!dependencyBefore === true ? (
									<div className="dependency-status">{this.getStatusTranslation(status)}</div>
								) : null}
							</div>
						)}
					</div>
				</div>
				{dependencyBefore === true ? (
					<div className="bond-right" style={{backgroundColor: `${project.projectColor}`}} />
				) : null}
			</div>
		);
	}
}

export default injectIntl(withRouter(DependencyContainer));
