import React, {useState} from 'react';
import {FormattedHTMLMessage, useIntl} from 'react-intl';

import {useHistory} from 'react-router-dom';
import GenericModal from '../../../../../containers/modal/generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../../../constants';
import {useProjectProgramFinancials} from '../../../../project-tab/programs/hooks/useProjectProgramFinancials';
import ProgramUtil from '../../../util/ProgramUtil';

export const ACTION = {
	INITIATE_TIMER: 'ititiateTimer',
	ADD_TIME_ENTRY: 'addTimeEntry',
	EDIT_TIME_ENTRY: 'editTimeEntry',
	MARK_TASK_BILLABLE: 'markTaskBillable',
	APPROVE_SINGLE_TIME_REG: 'approveSingleTimeReg',
	APPROVE_MULTIPLE_TIME_REGS: 'approveMultipleTimeRegs',
};

function getHeaderTranslationId(action) {
	switch (action) {
		case ACTION.INITIATE_TIMER:
			return 'task_modal.suppress_timer_initiation_title';
		case ACTION.EDIT_TIME_ENTRY:
			return 'timesheets.can_not_edit_time_entry_modal_header';
		case ACTION.ADD_TIME_ENTRY:
			return 'timesheets.can_not_add_time_entry_modal_header';
		case ACTION.MARK_TASK_BILLABLE:
			return 'program.error.cannot_mark_task_as_billable';
		case ACTION.APPROVE_SINGLE_TIME_REG:
			return 'time_approval.can_not_approve_time_reg_header';
		case ACTION.APPROVE_MULTIPLE_TIME_REGS:
			return 'time_approval.can_not_approve_time_regs_header';
		default:
			return 'common.error_colon';
	}
}

function getActionText(action) {
	switch (action) {
		case ACTION.EDIT_TIME_ENTRY:
			return 'edited';
		case ACTION.ADD_TIME_ENTRY:
			return 'added';
		default:
			return '';
	}
}

function getProgramInfoHelper(companyProjectId, actualPersonId, setIsDataReady) {
	if (!actualPersonId || !companyProjectId) {
		return {canManageProgram: false, programName: undefined, programPrefix: undefined};
	}
	const {getProgramBudgetInfo} = useProjectProgramFinancials(companyProjectId, setIsDataReady);

	const {canManageProgram, programName, programPrefix} = getProgramBudgetInfo(actualPersonId);

	return {canManageProgram: canManageProgram, programName: programName, programPrefix: programPrefix};
}

const ProgramBudgetErrorMessage = ({
	closeModal,
	action,
	programName,
	canManageProgram,
	programPrefix,
	companyProjectId,
	actualPersonId,
}) => {
	const {formatMessage} = useIntl();
	const history = useHistory();
	const [isDataReady, setIsDataReady] = useState(false);
	let dontUseHook = true;

	if (programName === undefined && !!actualPersonId && !!companyProjectId) {
		dontUseHook = false;
		const prgInfo = getProgramInfoHelper(companyProjectId, actualPersonId, setIsDataReady);
		canManageProgram = prgInfo.canManageProgram;
		programName = prgInfo.programName;
		programPrefix = prgInfo.programPrefix;
	}

	const handleConfirmButton = () => {
		history.push(`${ProgramUtil.programLink()}/${programPrefix}/budget/edit`);
		closeModal();
	};

	const getContent = (action, canManageProgram, programName) => {
		const values = {action: getActionText(action)};
		if (programName) {
			values.programName = programName;
		}

		switch (action) {
			case ACTION.INITIATE_TIMER:
				return (
					<FormattedHTMLMessage
						id={
							canManageProgram
								? 'task_modal.suppress_timer_initiation_message_with_program_info'
								: 'task_modal.suppress_timer_initiation_message_without_program_info'
						}
						values={values}
					/>
				);
			case ACTION.EDIT_TIME_ENTRY:
			case ACTION.ADD_TIME_ENTRY:
				return (
					<FormattedHTMLMessage
						id={
							canManageProgram
								? 'timesheets.can_not_add_or_edit_time_entry_message'
								: 'timesheets.can_not_add_or_edit_time_entry_message_without_program_info'
						}
						values={values}
					/>
				);
			case ACTION.MARK_TASK_BILLABLE:
				return (
					<FormattedHTMLMessage
						id={
							canManageProgram
								? 'program.error.capped_program.owner.revenue_exceeded_or_prevented'
								: 'program.error.capped_program.non_owner.revenue_exceeded_or_prevented'
						}
						values={values}
					/>
				);
			case ACTION.APPROVE_SINGLE_TIME_REG:
				return (
					<FormattedHTMLMessage
						id={
							canManageProgram
								? 'time_approval.can_not_approve_time_reg_message_with_program_info'
								: 'time_approval.can_not_approve_time_reg_message_without_program_info'
						}
						values={values}
					/>
				);
			case ACTION.APPROVE_MULTIPLE_TIME_REGS:
				return (
					<FormattedHTMLMessage
						id={
							canManageProgram
								? 'time_approval.can_not_approve_time_regs_message_with_program_info'
								: 'time_approval.can_not_approve_time_regs_message_without_program_info'
						}
						values={values}
					/>
				);
		}
	};

	return canManageProgram && (isDataReady || dontUseHook) ? (
		<GenericModal
			closeModal={closeModal}
			buttons={[
				{
					text: 'Edit program budget',
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					cy: 'user-input-confirm-btn',
					preventDefaultClose: true,
					callback: handleConfirmButton,
				},
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
			]}
			headerText={formatMessage({
				id: getHeaderTranslationId(action),
			})}
			content={<div style={{width: '600px'}}>{getContent(action, true, programName)}</div>}
		/>
	) : isDataReady || dontUseHook ? (
		<GenericModal
			closeModal={closeModal}
			buttons={[
				{
					text: formatMessage({id: 'common.close'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
				},
			]}
			headerText={formatMessage({
				id: getHeaderTranslationId(action),
			})}
			content={<div style={{width: '600px'}}>{getContent(action, false, null)}</div>}
		/>
	) : null;
};

export default ProgramBudgetErrorMessage;
