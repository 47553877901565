/* eslint no-useless-concat:0 */

import {hasFeatureFlag} from '../../../shared/util/FeatureUtil';

export const getCsvLabels = (columns, customFieldDefinitions) => {
	const hasFinancialCategoriesUpdate = hasFeatureFlag('financial_categories_update');
	const dataInput = [
		{
			fieldNames: ['task.companyTaskId'],
			label: 'task_id',
			format: 'STRING',
			processor: 'SCRIPT',
			processorData: {
				script: 'return "T" + data["task.companyTaskId"]',
			},
		},
		{
			fieldNames: ['task.name'],
			label: 'task_name',
			format: 'STRING',
		},
	];
	columns['task.project.info'] &&
		dataInput.push({
			fieldNames: ['task.project.companyProjectId', 'task.project.customProjectId'],
			label: 'project_id',
			format: 'STRING',
			processor: 'SCRIPT',
			processorData: {
				script: 'return data["task.project.customProjectId"]',
			},
		});
	columns['task.project.info'] && dataInput.push({fieldNames: ['task.project.name'], label: 'project_name'});

	columns['task.project.deadline'] && dataInput.push({fieldNames: ['task.project.deadline'], label: 'project_deadline_date'});
	columns['task.project.stage'] && dataInput.push({fieldNames: ['task.project.stage'], label: 'project_stage'});
	columns['task.project.rateCard.name'] && dataInput.push({fieldNames: ['task.project.rateCard.name'], label: 'rate_card'});
	columns['task.project.client.info'] && dataInput.push({fieldNames: ['task.project.client.name'], label: 'client_name'});
	columns['task.dates.start'] && dataInput.push({fieldNames: ['task.dates.start'], label: 'task_start_date'});
	columns['task.dates.deadline'] && dataInput.push({fieldNames: ['task.dates.deadline'], label: 'task_end_date'});
	columns['task.dates.createdAt'] && dataInput.push({fieldNames: ['task.dates.createdAt'], label: 'task_created_at'});
	columns['task.role.info'] && dataInput.push({fieldNames: ['task.role.name'], label: 'assigned_role'});
	//HERE
	//columns['task.assignees'] && dataInput.push({fieldNames: ['task.assignees'], label: 'Assigneess'});
	columns['task.assignees'] &&
		dataInput.push({
			fieldNames: ['task.assignees.firstName', 'task.assignees.lastName'],
			label: 'Assignees',
			format: 'STRING',
			processor: 'LIST',
			processorData: {
				arrayPath: 'task.assignees',
				subProcessor: {
					format: 'STRING',
					fieldNames: ['firstName', 'lastName'],
					delimiter: ' ',
				},
			},
		});
	columns['task.labels'] &&
		dataInput.push({
			fieldNames: ['task.labels.name'],
			label: 'labels',
			processor: 'LIST',
			processorData: {
				arrayPath: 'task.labels',
				subProcessor: {
					format: 'STRING',
					fieldNames: ['name'],
					delimiter: ' ',
				},
			},
		});
	columns['task.status.name'] && dataInput.push({fieldNames: ['task.status.name'], label: 'task_status'});
	columns['task.phase.info'] && dataInput.push({fieldNames: ['task.phase.name'], label: 'phase_name'});
	columns['task.phase.dates.start'] && dataInput.push({fieldNames: ['task.phase.dates.start'], label: 'phase_start_date'});
	columns['task.phase.dates.deadline'] &&
		dataInput.push({fieldNames: ['task.phase.dates.deadline'], label: 'phase_end_date'});
	columns['task.sprint.info'] && dataInput.push({fieldNames: ['task.sprint.name'], label: 'sprint_name'});
	columns['task.sprint.dates.start'] && dataInput.push({fieldNames: ['task.sprint.dates.start'], label: 'sprint_start_date'});
	columns['task.sprint.dates.deadline'] &&
		dataInput.push({fieldNames: ['task.sprint.dates.deadline'], label: 'sprint_end_date'});
	columns['task.progress'] &&
		dataInput.push({
			fieldNames: ['task.progress'],
			label: 'progress',
			format: 'STRING',
			processor: 'SCRIPT',
			processorData: {
				script: 'return data["task.progress"] + \'%\' ;',
			},
		});

	columns['task.work.estimate.minutes'] &&
		dataInput.push({
			fieldNames: ['task.work.estimate'],
			label: 'estimate',
			format: 'INTEGER',
		});
	columns['task.work.estimate.points'] &&
		dataInput.push({
			fieldNames: ['task.work.estimate'],
			label: 'estimate_points',
		});
	columns['task.reported.projected'] &&
		dataInput.push({
			fieldNames: ['task.reported.projected'],
			label: 'projected',
			format: 'INTEGER',
		});
	columns['task.reported.time'] &&
		dataInput.push({
			fieldNames: ['task.reported.time'],
			label: 'time_entries',
			format: 'INTEGER',
		});
	columns['task.work.remaining.minutes'] &&
		dataInput.push({
			fieldNames: ['task.work.remaining'],
			label: 'remaining_hours',
			format: 'INTEGER',
		});
	columns['task.work.remaining.points'] &&
		dataInput.push({
			fieldNames: ['task.work.remaining'],
			label: 'remaining_points',
		});
	columns['task.work.difference.minutes'] &&
		dataInput.push({
			fieldNames: ['task.work.estimate', 'task.work.remaining'],
			label: 'difference_estimate_hours',
			format: 'INTEGER',
			processor: 'SCRIPT',
			processorData: {
				script: 'return data["task.work.estimate"]-data["task.work.remaining"];',
			},
		});
	columns['task.work.difference.points'] &&
		dataInput.push({
			fieldNames: ['task.work.estimate', 'task.work.remaining'],
			label: 'difference_estimate_points',
			format: 'INTEGER',
			processor: 'SCRIPT',
			processorData: {
				script: 'return data["task.work.estimate"]-data["task.work.remaining"]',
			},
		});
	columns['task.financial.revenue.planned'] &&
		dataInput.push({
			fieldNames: ['task.financial.revenue.planned'],
			label: hasFinancialCategoriesUpdate ? 'estimated_billable_value_of_service' : 'plan_revenue',
			format: 'FLOAT',
			processor: 'SCRIPT',
			processorData: {
				script: 'return parseFloat(data["task.financial.revenue.planned"]).toFixed(2) === "NaN" ? 0 : parseFloat(data["task.financial.revenue.planned"]).toFixed(2);',
			},
		});
	columns['task.financial.revenue.actual'] &&
		dataInput.push({
			fieldNames: ['task.financial.revenue.actual'],
			label: hasFinancialCategoriesUpdate ? 'actual_billable_value_of_service' : 'actual_revenue',
			format: 'FLOAT',
			processor: 'SCRIPT',
			processorData: {
				script: 'return parseFloat(data["task.financial.revenue.actual"]).toFixed(2) === "NaN" ? 0 : parseFloat(data["task.financial.revenue.actual"]).toFixed(2);',
			},
		});
	columns['task.approved'] &&
		dataInput.push({
			fieldNames: ['task.approved'],
			label: 'approved',
			format: 'STRING',
			processor: 'SCRIPT',
			processorData: {
				script: 'return data["task.approved"] === "true" ? "Yes" : "No";',
			},
		});

	// Loop all customfield
	const customFieldsKeys = customFieldDefinitions.map(c => c.key);
	customFieldsKeys.forEach(key => {
		const label = customFieldDefinitions
			.find(def => def.key === key)
			.displayName.replace(' ', '_')
			.toLowerCase();
		const col = 'company.reportCustomFieldDefinitions.' + key;
		columns[col] &&
			dataInput.push({
				fieldNames: ['task.reportCustomFieldValues.' + key],
				label: label,
				format: 'STRING',
				processor: 'CUSTOM_FIELD',
				processorData: {
					key: key,
					fields: 'task.reportCustomFieldValues',
					script: 'return data["value"] ? data["value"] : "";',
				},
			});
	});

	return dataInput;
};
