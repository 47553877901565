/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AllPhasesDropdown_phases$ref = any;
type AllPhasesDropdown_projects$ref = any;
type ClientDropdown_clients$ref = any;
type DepartmentDropdown_departments$ref = any;
type IdleTimeDropdown_idleTimes$ref = any;
type LabelDropdown_labels$ref = any;
type PersonDropdown_persons$ref = any;
type PhasesDropdown_phases$ref = any;
type ProjectContactDropdown_projects$ref = any;
type ProjectDropdown_projects$ref = any;
type ProjectPersonDropdown_persons$ref = any;
type ProjectStatusColumnDropdown_columns$ref = any;
type RateCardDropdown_rateCards$ref = any;
type RoleDropdown_roles$ref = any;
type SkillDropdown_company$ref = any;
type SkillLevelDropdown_company$ref = any;
type SprintDropdown_sprints$ref = any;
type TeamDropdown_teams$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type FilterQueryContent_viewer$ref: FragmentReference;
declare export opaque type FilterQueryContent_viewer$fragmentType: FilterQueryContent_viewer$ref;
export type FilterQueryContent_viewer = {|
  +client: ?{|
    +id: string
  |},
  +availableFeatureFlags: ?$ReadOnlyArray<?{|
    +key: ?string
  |}>,
  +projects: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
      |},
      +$fragmentRefs: ProjectDropdown_projects$ref & ProjectContactDropdown_projects$ref & AllPhasesDropdown_projects$ref,
    |}>
  |},
  +phases?: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +projectId: ?string,
      |},
      +$fragmentRefs: AllPhasesDropdown_phases$ref,
    |}>
  |},
  +project: ?{|
    +demo: ?boolean,
    +statusColumnsV2: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |},
        +$fragmentRefs: ProjectStatusColumnDropdown_columns$ref,
      |}>
    |},
    +projectPersons: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +person: ?{|
            +id: string,
            +initials: ?string,
            +firstName: ?string,
            +lastName: ?string,
            +profilePictureId: ?string,
            +profilePictureDefaultId: ?number,
          |}
        |},
        +$fragmentRefs: ProjectPersonDropdown_persons$ref,
      |}>
    |},
    +phases: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |},
        +$fragmentRefs: PhasesDropdown_phases$ref,
      |}>
    |},
    +sprints: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |},
        +$fragmentRefs: SprintDropdown_sprints$ref,
      |}>
    |},
  |},
  +projectGroup: ?{|
    +projects: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +companyProjectId: ?number,
          +statusColumnsV2: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +name: ?string,
                +projectGroupStatusColumnId: ?number,
              |},
              +$fragmentRefs: ProjectStatusColumnDropdown_columns$ref,
            |}>
          |},
          +projectPersons: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +person: ?{|
                  +id: string,
                  +firstName: ?string,
                  +lastName: ?string,
                |}
              |},
              +$fragmentRefs: ProjectPersonDropdown_persons$ref,
            |}>
          |},
          +phases: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +name: ?string,
              |},
              +$fragmentRefs: PhasesDropdown_phases$ref,
            |}>
          |},
          +sprints: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +name: ?string,
                +projectGroupSprintId: ?string,
              |},
              +$fragmentRefs: SprintDropdown_sprints$ref,
            |}>
          |},
        |},
        +$fragmentRefs: ProjectDropdown_projects$ref,
      |}>
    |}
  |},
  +program: ?{|
    +projects: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +serviceCompanyId: ?number,
          +legacyProject: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +name: ?string,
                +companyProjectId: ?number,
                +statusColumnsV2: ?{|
                  +edges: ?$ReadOnlyArray<?{|
                    +node: ?{|
                      +id: string,
                      +name: ?string,
                      +projectGroupStatusColumnId: ?number,
                    |},
                    +$fragmentRefs: ProjectStatusColumnDropdown_columns$ref,
                  |}>
                |},
                +projectPersons: ?{|
                  +edges: ?$ReadOnlyArray<?{|
                    +node: ?{|
                      +person: ?{|
                        +id: string,
                        +firstName: ?string,
                        +lastName: ?string,
                      |}
                    |},
                    +$fragmentRefs: ProjectPersonDropdown_persons$ref,
                  |}>
                |},
                +phases: ?{|
                  +edges: ?$ReadOnlyArray<?{|
                    +node: ?{|
                      +id: string,
                      +name: ?string,
                    |},
                    +$fragmentRefs: PhasesDropdown_phases$ref,
                  |}>
                |},
                +sprints: ?{|
                  +edges: ?$ReadOnlyArray<?{|
                    +node: ?{|
                      +id: string,
                      +name: ?string,
                      +projectGroupSprintId: ?string,
                    |},
                    +$fragmentRefs: SprintDropdown_sprints$ref,
                  |}>
                |},
              |},
              +$fragmentRefs: ProjectDropdown_projects$ref,
            |}>
          |},
        |}
      |}>
    |}
  |},
  +company: ?{|
    +skillLevelsEnabled: ?boolean,
    +skillLevels: ?$ReadOnlyArray<?{|
      +id: string,
      +level: ?number,
      +description: ?string,
    |}>,
    +skills: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |}
      |}>
    |},
    +modules: ?$ReadOnlyArray<?{|
      +moduleType: ?string
    |}>,
    +rateCards: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |},
        +$fragmentRefs: RateCardDropdown_rateCards$ref,
      |}>
    |},
    +allPersons: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +firstName: ?string,
          +lastName: ?string,
        |},
        +$fragmentRefs: PersonDropdown_persons$ref,
      |}>
    |},
    +teams: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +teamPersons: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +person: ?{|
                  +id: string
                |},
              |}
            |}>
          |},
        |},
        +$fragmentRefs: TeamDropdown_teams$ref,
      |}>
    |},
    +clients: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |},
        +$fragmentRefs: ClientDropdown_clients$ref,
      |}>
    |},
    +labels: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +category: ?{|
            +id: string,
            +name: ?string,
            +allowOnPeople: ?boolean,
            +allowOnProjects: ?boolean,
            +allowOnTasks: ?boolean,
          |},
          +$fragmentRefs: LabelDropdown_labels$ref,
        |}
      |}>
    |},
    +roles: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |},
        +$fragmentRefs: RoleDropdown_roles$ref,
      |}>
    |},
    +idleTimes: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |},
        +$fragmentRefs: IdleTimeDropdown_idleTimes$ref,
      |}>
    |},
    +departments: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |},
        +$fragmentRefs: DepartmentDropdown_departments$ref,
      |}>
    |},
    +priorityLevels: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +weight: ?number,
        |}
      |}>
    |},
    +programs: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |}
      |}>
    |},
    +billFroms: ?$ReadOnlyArray<?{|
      +id: string,
      +name: string,
      +integration: string,
      +billTos: {|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: string,
          |}
        |}>
      |},
    |}>,
    +expenseCategories: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |}
      |}>
    |},
    +$fragmentRefs: SkillDropdown_company$ref & SkillLevelDropdown_company$ref,
  |},
  +$refType: FilterQueryContent_viewer$ref,
|};
export type FilterQueryContent_viewer$data = FilterQueryContent_viewer;
export type FilterQueryContent_viewer$key = {
  +$data?: FilterQueryContent_viewer$data,
  +$fragmentRefs: FilterQueryContent_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "count": null,
  "cursor": null,
  "direction": "forward",
  "path": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = [
  (v1/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v7 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "ProjectDropdown_projects"
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "ProjectStatusColumnDropdown_columns"
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v12 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "ProjectPersonDropdown_persons"
},
v13 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "PhaseTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PhaseType",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": (v5/*: any*/),
        "storageKey": null
      },
      (v6/*: any*/),
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "PhasesDropdown_phases"
      }
    ],
    "storageKey": null
  },
  (v8/*: any*/)
],
v14 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "SprintDropdown_sprints"
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyProjectId",
  "storageKey": null
},
v16 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "StatusColumnV2TypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "StatusColumnV2",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "projectGroupStatusColumnId",
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      (v6/*: any*/),
      (v9/*: any*/)
    ],
    "storageKey": null
  },
  (v8/*: any*/)
],
v17 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ProjectPersonTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectPerson",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      (v6/*: any*/),
      (v12/*: any*/)
    ],
    "storageKey": null
  },
  (v8/*: any*/)
],
v18 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SprintTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Sprint",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "projectGroupSprintId",
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      (v6/*: any*/),
      (v14/*: any*/)
    ],
    "storageKey": null
  },
  (v8/*: any*/)
],
v19 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100000
  }
],
v20 = [
  (v1/*: any*/),
  (v3/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectId",
      "type": "ID"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "groupId",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "programPrefix",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "fetchAllProjectPhases",
      "type": "Boolean!"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "projects"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "project",
          "statusColumnsV2"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "project",
          "projectPersons"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "project",
          "phases"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "project",
          "sprints"
        ]
      },
      (v0/*: any*/),
      (v0/*: any*/),
      (v0/*: any*/),
      (v0/*: any*/),
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "projectGroup",
          "projects"
        ]
      },
      (v0/*: any*/),
      (v0/*: any*/),
      (v0/*: any*/),
      (v0/*: any*/),
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "program",
          "projects"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "company",
          "skills"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "company",
          "rateCards"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "company",
          "allPersons"
        ]
      },
      (v0/*: any*/),
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "company",
          "teams"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "company",
          "clients"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "company",
          "labels"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "company",
          "roles"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "company",
          "idleTimes"
        ]
      },
      (v0/*: any*/),
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "company",
          "expenseCategories"
        ]
      }
    ]
  },
  "name": "FilterQueryContent_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Client",
      "kind": "LinkedField",
      "name": "client",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FeatureFlag",
      "kind": "LinkedField",
      "name": "availableFeatureFlags",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "projects",
      "args": null,
      "concreteType": "ProjectTypeConnection",
      "kind": "LinkedField",
      "name": "__FilterViewer_filters_projects_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v5/*: any*/),
              "storageKey": null
            },
            (v6/*: any*/),
            (v7/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ProjectContactDropdown_projects"
            },
            {
              "condition": "fetchAllProjectPhases",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AllPhasesDropdown_projects"
                }
              ]
            }
          ],
          "storageKey": null
        },
        (v8/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "internalId",
          "variableName": "projectId"
        }
      ],
      "concreteType": "ProjectType",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "demo",
          "storageKey": null
        },
        {
          "alias": "statusColumnsV2",
          "args": null,
          "concreteType": "StatusColumnV2TypeConnection",
          "kind": "LinkedField",
          "name": "__FilterViewer_filters_statusColumnsV2_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "StatusColumnV2TypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "StatusColumnV2",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v5/*: any*/),
                  "storageKey": null
                },
                (v6/*: any*/),
                (v9/*: any*/)
              ],
              "storageKey": null
            },
            (v8/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": "projectPersons",
          "args": null,
          "concreteType": "ProjectPersonTypeConnection",
          "kind": "LinkedField",
          "name": "__FilterViewer_filters_projectPersons_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectPersonTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectPerson",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Person",
                      "kind": "LinkedField",
                      "name": "person",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "initials",
                          "storageKey": null
                        },
                        (v10/*: any*/),
                        (v11/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "profilePictureId",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "profilePictureDefaultId",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v4/*: any*/)
                  ],
                  "storageKey": null
                },
                (v6/*: any*/),
                (v12/*: any*/)
              ],
              "storageKey": null
            },
            (v8/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": "phases",
          "args": null,
          "concreteType": "PhaseTypeConnection",
          "kind": "LinkedField",
          "name": "__FilterViewer_filters_phases_connection",
          "plural": false,
          "selections": (v13/*: any*/),
          "storageKey": null
        },
        {
          "alias": "sprints",
          "args": null,
          "concreteType": "SprintTypeConnection",
          "kind": "LinkedField",
          "name": "__FilterViewer_filters_sprints_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SprintTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Sprint",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v5/*: any*/),
                  "storageKey": null
                },
                (v6/*: any*/),
                (v14/*: any*/)
              ],
              "storageKey": null
            },
            (v8/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "groupId"
        }
      ],
      "concreteType": "ProjectGroupType",
      "kind": "LinkedField",
      "name": "projectGroup",
      "plural": false,
      "selections": [
        {
          "alias": "projects",
          "args": null,
          "concreteType": "ProjectTypeConnection",
          "kind": "LinkedField",
          "name": "__FilterViewer_filters_project_group_projects_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v3/*: any*/),
                    (v15/*: any*/),
                    {
                      "alias": "statusColumnsV2",
                      "args": null,
                      "concreteType": "StatusColumnV2TypeConnection",
                      "kind": "LinkedField",
                      "name": "__FilterViewer_filters__project_group_projects_statusColumnsV2_connection",
                      "plural": false,
                      "selections": (v16/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": "projectPersons",
                      "args": null,
                      "concreteType": "ProjectPersonTypeConnection",
                      "kind": "LinkedField",
                      "name": "__FilterViewer_filters_project_group_projects_projectPersons_connection",
                      "plural": false,
                      "selections": (v17/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": "phases",
                      "args": null,
                      "concreteType": "PhaseTypeConnection",
                      "kind": "LinkedField",
                      "name": "__FilterViewer_filters_project_group_phases_connection",
                      "plural": false,
                      "selections": (v13/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": "sprints",
                      "args": null,
                      "concreteType": "SprintTypeConnection",
                      "kind": "LinkedField",
                      "name": "__FilterViewer_filters_project_group_sprints_connection",
                      "plural": false,
                      "selections": (v18/*: any*/),
                      "storageKey": null
                    },
                    (v4/*: any*/)
                  ],
                  "storageKey": null
                },
                (v6/*: any*/),
                (v7/*: any*/)
              ],
              "storageKey": null
            },
            (v8/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "prefix",
          "variableName": "programPrefix"
        }
      ],
      "concreteType": "ProgramType",
      "kind": "LinkedField",
      "name": "program",
      "plural": false,
      "selections": [
        {
          "alias": "projects",
          "args": null,
          "concreteType": "ProjectServiceProjectTypeConnection",
          "kind": "LinkedField",
          "name": "__FilterViewer_filters_program_projects_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectServiceProjectTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectServiceProjectType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "serviceCompanyId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProjectTypeConnection",
                      "kind": "LinkedField",
                      "name": "legacyProject",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ProjectTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ProjectType",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/),
                                (v3/*: any*/),
                                (v15/*: any*/),
                                {
                                  "alias": "statusColumnsV2",
                                  "args": null,
                                  "concreteType": "StatusColumnV2TypeConnection",
                                  "kind": "LinkedField",
                                  "name": "__FilterViewer_filters_program_projects_statusColumnsV2_connection",
                                  "plural": false,
                                  "selections": (v16/*: any*/),
                                  "storageKey": null
                                },
                                {
                                  "alias": "projectPersons",
                                  "args": null,
                                  "concreteType": "ProjectPersonTypeConnection",
                                  "kind": "LinkedField",
                                  "name": "__FilterViewer_filters_program_projects_projectPersons_connection",
                                  "plural": false,
                                  "selections": (v17/*: any*/),
                                  "storageKey": null
                                },
                                {
                                  "alias": "phases",
                                  "args": null,
                                  "concreteType": "PhaseTypeConnection",
                                  "kind": "LinkedField",
                                  "name": "__FilterViewer_filters_program_phases_connection",
                                  "plural": false,
                                  "selections": (v13/*: any*/),
                                  "storageKey": null
                                },
                                {
                                  "alias": "sprints",
                                  "args": null,
                                  "concreteType": "SprintTypeConnection",
                                  "kind": "LinkedField",
                                  "name": "__FilterViewer_filters_program_sprints_connection",
                                  "plural": false,
                                  "selections": (v18/*: any*/),
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            (v7/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v4/*: any*/)
                  ],
                  "storageKey": null
                },
                (v6/*: any*/)
              ],
              "storageKey": null
            },
            (v8/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "skillLevelsEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SkillLevel",
          "kind": "LinkedField",
          "name": "skillLevels",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "level",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": "skills",
          "args": null,
          "concreteType": "SkillTypeConnection",
          "kind": "LinkedField",
          "name": "__Company_skills_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SkillTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Skill",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v5/*: any*/),
                  "storageKey": null
                },
                (v6/*: any*/)
              ],
              "storageKey": null
            },
            (v8/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Module",
          "kind": "LinkedField",
          "name": "modules",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "moduleType",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": "rateCards",
          "args": null,
          "concreteType": "RateCardTypeConnection",
          "kind": "LinkedField",
          "name": "__FilterViewer_filters_company_rateCards_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RateCardTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "RateCard",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v5/*: any*/),
                  "storageKey": null
                },
                (v6/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "RateCardDropdown_rateCards"
                }
              ],
              "storageKey": null
            },
            (v8/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": "allPersons",
          "args": null,
          "concreteType": "PersonTypeConnection",
          "kind": "LinkedField",
          "name": "__FilterViewer_filters_company_allPersons_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PersonTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Person",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v10/*: any*/),
                    (v11/*: any*/),
                    (v4/*: any*/)
                  ],
                  "storageKey": null
                },
                (v6/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "PersonDropdown_persons"
                }
              ],
              "storageKey": null
            },
            (v8/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": "teams",
          "args": null,
          "concreteType": "TeamTypeConnection",
          "kind": "LinkedField",
          "name": "__FilterViewer_filters_company_teams_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TeamTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Team",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v3/*: any*/),
                    {
                      "alias": "teamPersons",
                      "args": null,
                      "concreteType": "TeamPersonTypeConnection",
                      "kind": "LinkedField",
                      "name": "__FilterViewer_filters_company_teams_teamPersons_connection",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "TeamPersonTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "TeamPerson",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Person",
                                  "kind": "LinkedField",
                                  "name": "person",
                                  "plural": false,
                                  "selections": (v2/*: any*/),
                                  "storageKey": null
                                },
                                (v4/*: any*/)
                              ],
                              "storageKey": null
                            },
                            (v6/*: any*/)
                          ],
                          "storageKey": null
                        },
                        (v8/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v4/*: any*/)
                  ],
                  "storageKey": null
                },
                (v6/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "TeamDropdown_teams"
                }
              ],
              "storageKey": null
            },
            (v8/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": "clients",
          "args": null,
          "concreteType": "ClientTypeConnection",
          "kind": "LinkedField",
          "name": "__FilterViewer_filters_company_clients_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ClientTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Client",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v5/*: any*/),
                  "storageKey": null
                },
                (v6/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ClientDropdown_clients"
                }
              ],
              "storageKey": null
            },
            (v8/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": "labels",
          "args": null,
          "concreteType": "LabelTypeConnection",
          "kind": "LinkedField",
          "name": "__FilterViewer_filters_company_labels_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LabelTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Label",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "LabelCategory",
                      "kind": "LinkedField",
                      "name": "category",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v3/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "allowOnPeople",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "allowOnProjects",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "allowOnTasks",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v4/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "LabelDropdown_labels"
                    }
                  ],
                  "storageKey": null
                },
                (v6/*: any*/)
              ],
              "storageKey": null
            },
            (v8/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": "roles",
          "args": null,
          "concreteType": "RoleTypeConnection",
          "kind": "LinkedField",
          "name": "__FilterViewer_filters_company_roles_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RoleTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Role",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v5/*: any*/),
                  "storageKey": null
                },
                (v6/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "RoleDropdown_roles"
                }
              ],
              "storageKey": null
            },
            (v8/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": "idleTimes",
          "args": null,
          "concreteType": "IdleTimeTypeConnection",
          "kind": "LinkedField",
          "name": "__FilterViewer_filters_company_idleTimes_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "IdleTimeTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "IdleTime",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v5/*: any*/),
                  "storageKey": null
                },
                (v6/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "IdleTimeDropdown_idleTimes"
                }
              ],
              "storageKey": null
            },
            (v8/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v19/*: any*/),
          "concreteType": "DepartmentTypeConnection",
          "kind": "LinkedField",
          "name": "departments",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DepartmentTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "DepartmentType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v20/*: any*/),
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "DepartmentDropdown_departments"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "departments(first:100000)"
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 1000
            }
          ],
          "concreteType": "PriorityLevelTypeConnection",
          "kind": "LinkedField",
          "name": "priorityLevels",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PriorityLevelTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PriorityLevel",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "weight",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "priorityLevels(first:1000)"
        },
        {
          "alias": null,
          "args": (v19/*: any*/),
          "concreteType": "ProjectServiceProgramTypeConnection",
          "kind": "LinkedField",
          "name": "programs",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectServiceProgramTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProgramType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v20/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "programs(first:100000)"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BillFrom",
          "kind": "LinkedField",
          "name": "billFroms",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "integration",
              "storageKey": null
            },
            {
              "alias": "billTos",
              "args": null,
              "concreteType": "BillToTypeConnection",
              "kind": "LinkedField",
              "name": "__BillFrom_billTos_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BillToTypeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "BillTo",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": (v5/*: any*/),
                      "storageKey": null
                    },
                    (v6/*: any*/)
                  ],
                  "storageKey": null
                },
                (v8/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": "expenseCategories",
          "args": null,
          "concreteType": "ExpenseCategoryTypeConnection",
          "kind": "LinkedField",
          "name": "__Company_expenseCategories_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ExpenseCategoryTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ExpenseCategory",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v5/*: any*/),
                  "storageKey": null
                },
                (v6/*: any*/)
              ],
              "storageKey": null
            },
            (v8/*: any*/)
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SkillDropdown_company"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SkillLevelDropdown_company"
        }
      ],
      "storageKey": null
    },
    {
      "condition": "fetchAllProjectPhases",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PhaseTypeConnection",
          "kind": "LinkedField",
          "name": "phases",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PhaseTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PhaseType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "projectId",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "condition": "fetchAllProjectPhases",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "AllPhasesDropdown_phases"
                    }
                  ]
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '6f3ce2e77f4972316a68eefd85fd13a5';

module.exports = node;
