import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import AssignedDropdown from '../dropdowns/assigned-dropdown/assigned_dropdown';
import Person from '../person/person';
import {profilePicSrc} from '../../../../directApi';

class AssignedTableTile extends Component {
	constructor() {
		super();
		this.state = {
			hasFocus: false,
		};
	}

	toggleFocus(e) {
		if (this.props.disabled) {
			return;
		}
		if (e) {
			e.stopPropagation();
		}
		this.setState(prevState => {
			return {hasFocus: !prevState.hasFocus};
		});
	}

	onFocus(e) {
		// Clear the timeout so we dont collapse the dropdown
		clearTimeout(this._timeoutID);
	}

	onBlur(e) {
		// Set a timeout to collapse the dropdown if we dont get focus within this tick
		this._timeoutID = setTimeout(() => {
			if (this.state.hasFocus) {
				this.setState({
					hasFocus: false,
				});
			}
		}, 0);
	}

	takeFocus() {
		this.setState({hasFocus: false});
	}

	render() {
		let isOverMax;
		let assignedPersons;
		if (this.props.assignedPersons) {
			assignedPersons = this.props.assignedPersons.filter(p => p !== null);
			isOverMax = assignedPersons.length > this.props.maxDisplayed;
		}

		const {formatMessage} = this.props.intl;
		return this.state.hasFocus ? (
			<div onBlur={e => this.onBlur(e)} onFocus={e => this.onFocus(e)} className="focused-assigned-container">
				<AssignedDropdown
					{...this.props}
					giveParentFocus={this.takeFocus.bind(this)}
					onCollapse={this.onBlur.bind(this)}
					autoFocus={true}
					wideExpanded={this.props.wideExpanded}
					noClear={this.props.noClear}
					suggestedPersonRoleFilter={this.props.suggestedPersonRoleFilter}
					showSuggestions={true}
					isClientUser={this.props.isClientUser}
					viewerId={this.props.viewer?.actualPersonId}
				/>
			</div>
		) : (
			<div
				onClick={e => this.toggleFocus(e)}
				className="unfocused-assigned-container"
				onFocus={() => this.toggleFocus()}
				tabIndex={0}
				data-cy={this.props.cy + '-button'}
			>
				{(assignedPersons ? assignedPersons.length > 0 : false) || this.props.assignedRole ? (
					<div className={'assigned-list' + (this.props.disabled ? ' disabled' : '')} data-cy={'assigned-list'}>
						{this.props.assignedRole && this.props.assignedRole.name ? (
							<div className="assigned-role-unfocused-wrapper" data-cy={'assigned-role-unfocused'}>
								<div className="assigned-role-text" data-cy="assigned-role-text">
									{this.props.assignedRole.name}
								</div>
							</div>
						) : (
							''
						)}
						{assignedPersons
							? assignedPersons
									.slice(
										0,
										isOverMax
											? this.props.maxDisplayed - 1 - !!this.props.assignedRole
											: this.props.maxDisplayed
									)
									.map(p => (
										<Person
											key={p.id}
											name={p.firstName + (p.lastName ? ' ' + p.lastName : '')}
											showName={!this.props.hideName && assignedPersons.length === 1}
											showRole={false}
											imageSize="new-ui-person-dropdown"
											imageSrc={profilePicSrc(p.profilePictureId)}
											cy={'assigned-person-unfocused'}
											isOwner={this.props.isOwner}
										/>
									))
							: null}

						{isOverMax ? (
							<div className="additional-assigned-persons">
								<div className="more-people-container">
									{'+' + (assignedPersons.length + 1 + !!this.props.assignedRole - this.props.maxDisplayed)}
								</div>
							</div>
						) : null}
						{this.props.noArrow ? null : (
							<div className={'arrow' + (this.props.disabled ? ' disabled' : '')} data-cy="arrow" />
						)}
					</div>
				) : (
					<div
						className={'empty-assigned-container' + (this.props.disabled ? ' disabled' : ' enabled')}
						data-cy="empty-assigned-container"
					>
						<span>
							{formatMessage({id: this.props.assignableRoles ? 'common.assign_role' : 'common.assign_people'})}
						</span>
						<div className={'arrow' + (this.props.disabled ? ' disabled' : '')} />
					</div>
				)}
			</div>
		);
	}
}

AssignedTableTile.propTypes = {
	disabled: PropTypes.bool,
	assignedPersons: PropTypes.arrayOf(PropTypes.object),
	assignedRole: PropTypes.object,
	assignablePersons: PropTypes.arrayOf(PropTypes.object),
	assignableRoles: PropTypes.arrayOf(PropTypes.object),
	isMultiSelect: PropTypes.bool,
	assignRole: PropTypes.func,
	assignPerson: PropTypes.func,
	assignPersonOnlyDiff: PropTypes.bool,
	maxDisplayed: PropTypes.number,
	noArrow: PropTypes.bool,
	taskName: PropTypes.string,
};

export default injectIntl(AssignedTableTile);
