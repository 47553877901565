import React, {Component} from 'react';
import WarningIcon from '../../../../../images/warning_icon';
import {dispatch, EVENT_ID, subscribe, unsubscribe} from '../../../../../containers/event_manager';
import ToastProgress from './toast_progress';

export const createToast = message => dispatch(EVENT_ID.SHOW_TOAST, message);

export class Toaster extends Component {
	constructor(props) {
		super(props);
		this.state = {
			toastQueue: [],
			isDisplayingToast: false,
			toastState: 0,
			toastMessage: '',
			toastActionText: '',
			toastActionCallback: null,
			toastWarning: null,
			isDisplayingDuration: null,
			duration: null,
			callback: null,
		};

		this.addToastToQueue = this.addToastToQueue.bind(this);
	}

	componentDidMount() {
		subscribe(EVENT_ID.SHOW_TOAST, this.addToastToQueue);
	}

	componentWillUnmount() {
		unsubscribe(EVENT_ID.SHOW_TOAST, this.addToastToQueue);
		if (this.hideToastTimeout) {
			clearTimeout(this.hideToastTimeout);
		}
	}

	addToastToQueue(toast) {
		const {toastQueue} = this.state;
		toastQueue.push(toast);
		this.setState({toastQueue});
		if (toastQueue.length === 1) {
			this.dispatchToast();
		} else {
			if (this.hideToastTimeout) {
				clearTimeout(this.hideToastTimeout);
				this.hideToast();
			}
		}
	}

	dispatchToast() {
		const toast = this.state.toastQueue[0];
		this.setState({
			isDisplayingToast: true,
			toastMessage: toast.message,
			toastActionText: toast.actionText,
			toastActionCallback: toast.actionCallback,
			toastWarning: toast.warning,
			isDisplayingDuration: toast.isDisplayingDuration,
			duration: toast.duration,
			callback: toast.callback,
		});
		setTimeout(() => {
			this.setState({toastState: 1});
		}, 100);
		this.hideToastTimeout = setTimeout(() => {
			this.hideToast();
			if (this.state.callback) {
				this.state.callback();
			}
		}, toast.duration);
	}

	hideToast() {
		this.hideToastTimeout = null;
		this.setState({toastState: 0});
		setTimeout(() => {
			this.moveToastQueue();
		}, 200);
	}

	moveToastQueue() {
		const toastQueue = this.state.toastQueue;
		toastQueue.shift();
		this.setState({isDisplayingToast: false, toastQueue});
		if (toastQueue.length) {
			this.dispatchToast();
		}
	}

	handleActionClick() {
		if (this.state.toastActionCallback) {
			this.state.toastActionCallback();
			if (this.hideToastTimeout) {
				clearTimeout(this.hideToastTimeout);
				this.hideToast();
			}
		}
	}

	render() {
		return (
			<div className="toaster">
				{this.state.isDisplayingToast ? (
					<div className={'toast ' + (this.state.toastState === 1 ? 'displaying' : '')} data-cy="toast">
						{this.state.isDisplayingDuration && <ToastProgress duration={this.state.duration} />}
						<div className="message" data-cy="toast-message">
							{this.state.toastWarning ? <WarningIcon danger={true} /> : null}
							{this.state.toastMessage}
						</div>
						{this.state.toastActionText && this.state.toastActionCallback ? (
							<div className="action" role="alert" onClick={this.handleActionClick.bind(this)}>
								{this.state.toastActionText}
							</div>
						) : null}
					</div>
				) : null}
			</div>
		);
	}
}
