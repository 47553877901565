/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
query AssociateBillToEntityModalQuery(
  $clientId: String
) {
  viewer {
    actualPersonId
    component(name: "AssociateBillToEntityModal")
    ...AssociateBillToEntityModal_viewer_3sjgMp
    id
  }
}

fragment AssociateBillToEntityModal_viewer_3sjgMp on Viewer {
  id
  company {
    id
    billFroms {
      id
      integration
      externalId
      name
      billTos(first: 10000) {
        edges {
          node {
            id
            name
            address
            taxId
            externalId
            __typename
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
    client(id: $clientId) {
      id
      clientBillTos(first: 10000) {
        edges {
          node {
            id
            billFrom {
              id
            }
            billTo {
              id
            }
            clientId
            __typename
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
}
*/
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "clientId",
            "type": "String"
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actualPersonId",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": [
            {
                "kind": "Literal",
                "name": "name",
                "value": "AssociateBillToEntityModal"
            }
        ],
        "kind": "ScalarField",
        "name": "component",
        "storageKey": "component(name:\"AssociateBillToEntityModal\")"
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "externalId",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v6 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 10000
        }
    ], v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
    }, v9 = {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v10 = [
        (v3 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AssociateBillToEntityModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "clientId",
                                    "variableName": "clientId"
                                }
                            ],
                            "kind": "FragmentSpread",
                            "name": "AssociateBillToEntityModal_viewer"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AssociateBillToEntityModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Company",
                            "kind": "LinkedField",
                            "name": "company",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "BillFrom",
                                    "kind": "LinkedField",
                                    "name": "billFroms",
                                    "plural": true,
                                    "selections": [
                                        (v3 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "integration",
                                            "storageKey": null
                                        },
                                        (v4 /*: any*/),
                                        (v5 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": (v6 /*: any*/),
                                            "concreteType": "BillToTypeConnection",
                                            "kind": "LinkedField",
                                            "name": "billTos",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "BillToTypeEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "BillTo",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                                (v3 /*: any*/),
                                                                (v5 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "address",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "taxId",
                                                                    "storageKey": null
                                                                },
                                                                (v4 /*: any*/),
                                                                (v7 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        (v8 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v9 /*: any*/)
                                            ],
                                            "storageKey": "billTos(first:10000)"
                                        },
                                        {
                                            "alias": null,
                                            "args": (v6 /*: any*/),
                                            "filters": [],
                                            "handle": "connection",
                                            "key": "BillFrom_billTos",
                                            "kind": "LinkedHandle",
                                            "name": "billTos"
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": [
                                        {
                                            "kind": "Variable",
                                            "name": "id",
                                            "variableName": "clientId"
                                        }
                                    ],
                                    "concreteType": "Client",
                                    "kind": "LinkedField",
                                    "name": "client",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": (v6 /*: any*/),
                                            "concreteType": "ClientBillToTypeConnection",
                                            "kind": "LinkedField",
                                            "name": "clientBillTos",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ClientBillToTypeEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "ClientBillTo",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                                (v3 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "BillFrom",
                                                                    "kind": "LinkedField",
                                                                    "name": "billFrom",
                                                                    "plural": false,
                                                                    "selections": (v10 /*: any*/),
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "BillTo",
                                                                    "kind": "LinkedField",
                                                                    "name": "billTo",
                                                                    "plural": false,
                                                                    "selections": (v10 /*: any*/),
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "clientId",
                                                                    "storageKey": null
                                                                },
                                                                (v7 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        (v8 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v9 /*: any*/)
                                            ],
                                            "storageKey": "clientBillTos(first:10000)"
                                        },
                                        {
                                            "alias": null,
                                            "args": (v6 /*: any*/),
                                            "filters": [],
                                            "handle": "connection",
                                            "key": "Client_clientBillTos",
                                            "kind": "LinkedHandle",
                                            "name": "clientBillTos"
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "AssociateBillToEntityModalQuery",
            "operationKind": "query",
            "text": "query AssociateBillToEntityModalQuery(\n  $clientId: String\n) {\n  viewer {\n    actualPersonId\n    component(name: \"AssociateBillToEntityModal\")\n    ...AssociateBillToEntityModal_viewer_3sjgMp\n    id\n  }\n}\n\nfragment AssociateBillToEntityModal_viewer_3sjgMp on Viewer {\n  id\n  company {\n    id\n    billFroms {\n      id\n      integration\n      externalId\n      name\n      billTos(first: 10000) {\n        edges {\n          node {\n            id\n            name\n            address\n            taxId\n            externalId\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n    client(id: $clientId) {\n      id\n      clientBillTos(first: 10000) {\n        edges {\n          node {\n            id\n            billFrom {\n              id\n            }\n            billTo {\n              id\n            }\n            clientId\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = '90e74ff29e84f1f0c00678e186d3ec9d';
export default node;
