/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PrimaryNavigationMain_viewer$ref = any;
export type PrimaryNavigationMain_QueryVariables = {||};
export type PrimaryNavigationMain_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: PrimaryNavigationMain_viewer$ref,
  |}
|};
export type PrimaryNavigationMain_Query = {|
  variables: PrimaryNavigationMain_QueryVariables,
  response: PrimaryNavigationMain_QueryResponse,
|};
*/


/*
query PrimaryNavigationMain_Query {
  viewer {
    actualPersonId
    component(name: "top_navigation_main")
    ...PrimaryNavigationMain_viewer
    id
  }
}

fragment PrimaryNavigationMain_viewer on Viewer {
  id
  projectOwner
  company {
    id
    useTimeApproval
    useTimeOffApproval
    unit4Enabled
    isUsingProjectAllocation
    isUsingMixedAllocation
    tier
    isCoreTier
    sageIntacctTimeRegsFromForecast
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "top_navigation_main"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"top_navigation_main\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PrimaryNavigationMain_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PrimaryNavigationMain_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PrimaryNavigationMain_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "projectOwner",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "useTimeApproval",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "useTimeOffApproval",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "unit4Enabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isUsingProjectAllocation",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isUsingMixedAllocation",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tier",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isCoreTier",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sageIntacctTimeRegsFromForecast",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "PrimaryNavigationMain_Query",
    "operationKind": "query",
    "text": "query PrimaryNavigationMain_Query {\n  viewer {\n    actualPersonId\n    component(name: \"top_navigation_main\")\n    ...PrimaryNavigationMain_viewer\n    id\n  }\n}\n\nfragment PrimaryNavigationMain_viewer on Viewer {\n  id\n  projectOwner\n  company {\n    id\n    useTimeApproval\n    useTimeOffApproval\n    unit4Enabled\n    isUsingProjectAllocation\n    isUsingMixedAllocation\n    tier\n    isCoreTier\n    sageIntacctTimeRegsFromForecast\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f6969a5fdbd152b2b5519e428215af2b';

module.exports = node;
