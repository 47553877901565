/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
query AddProjectBillToModalQuery(
  $projectId: ID
) {
  viewer {
    actualPersonId
    component(name: "AddProjectBillToModal")
    ...AddProjectBillToModal_viewer_3VHfkf
    id
  }
}

fragment AddProjectBillToModal_viewer_3VHfkf on Viewer {
  id
  project(internalId: $projectId) {
    id
    projectBillTos(first: 10000) {
      edges {
        node {
          id
          billTo {
            id
          }
          billFrom {
            id
          }
        }
      }
    }
    client {
      clientBillTos(first: 10000) {
        edges {
          node {
            id
            clientId
            billTo {
              id
              name
              billFromId
            }
            billFrom {
              id
              name
              integration
            }
          }
        }
      }
      id
    }
  }
  company {
    billFroms {
      id
      name
    }
    id
  }
}
*/
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "projectId",
            "type": "ID"
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actualPersonId",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": [
            {
                "kind": "Literal",
                "name": "name",
                "value": "AddProjectBillToModal"
            }
        ],
        "kind": "ScalarField",
        "name": "component",
        "storageKey": "component(name:\"AddProjectBillToModal\")"
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v4 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 10000
        }
    ], v5 = [
        (v3 /*: any*/)
    ], v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AddProjectBillToModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "id",
                                    "variableName": "projectId"
                                }
                            ],
                            "kind": "FragmentSpread",
                            "name": "AddProjectBillToModal_viewer"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AddProjectBillToModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "alias": null,
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "internalId",
                                    "variableName": "projectId"
                                }
                            ],
                            "concreteType": "ProjectType",
                            "kind": "LinkedField",
                            "name": "project",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                {
                                    "alias": null,
                                    "args": (v4 /*: any*/),
                                    "concreteType": "ProjectBillToTypeConnection",
                                    "kind": "LinkedField",
                                    "name": "projectBillTos",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ProjectBillToTypeEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ProjectBillTo",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v3 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "BillTo",
                                                            "kind": "LinkedField",
                                                            "name": "billTo",
                                                            "plural": false,
                                                            "selections": (v5 /*: any*/),
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "BillFrom",
                                                            "kind": "LinkedField",
                                                            "name": "billFrom",
                                                            "plural": false,
                                                            "selections": (v5 /*: any*/),
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": "projectBillTos(first:10000)"
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Client",
                                    "kind": "LinkedField",
                                    "name": "client",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": (v4 /*: any*/),
                                            "concreteType": "ClientBillToTypeConnection",
                                            "kind": "LinkedField",
                                            "name": "clientBillTos",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ClientBillToTypeEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "ClientBillTo",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                                (v3 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "clientId",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "BillTo",
                                                                    "kind": "LinkedField",
                                                                    "name": "billTo",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        (v3 /*: any*/),
                                                                        (v6 /*: any*/),
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "billFromId",
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "BillFrom",
                                                                    "kind": "LinkedField",
                                                                    "name": "billFrom",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        (v3 /*: any*/),
                                                                        (v6 /*: any*/),
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "integration",
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": "clientBillTos(first:10000)"
                                        },
                                        (v3 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Company",
                            "kind": "LinkedField",
                            "name": "company",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "BillFrom",
                                    "kind": "LinkedField",
                                    "name": "billFroms",
                                    "plural": true,
                                    "selections": [
                                        (v3 /*: any*/),
                                        (v6 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v3 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "AddProjectBillToModalQuery",
            "operationKind": "query",
            "text": "query AddProjectBillToModalQuery(\n  $projectId: ID\n) {\n  viewer {\n    actualPersonId\n    component(name: \"AddProjectBillToModal\")\n    ...AddProjectBillToModal_viewer_3VHfkf\n    id\n  }\n}\n\nfragment AddProjectBillToModal_viewer_3VHfkf on Viewer {\n  id\n  project(internalId: $projectId) {\n    id\n    projectBillTos(first: 10000) {\n      edges {\n        node {\n          id\n          billTo {\n            id\n          }\n          billFrom {\n            id\n          }\n        }\n      }\n    }\n    client {\n      clientBillTos(first: 10000) {\n        edges {\n          node {\n            id\n            clientId\n            billTo {\n              id\n              name\n              billFromId\n            }\n            billFrom {\n              id\n              name\n              integration\n            }\n          }\n        }\n      }\n      id\n    }\n  }\n  company {\n    billFroms {\n      id\n      name\n    }\n    id\n  }\n}\n"
        }
    };
})();
node.hash = 'a3a64f6d755e2a262b2a112e8c71b768';
export default node;
