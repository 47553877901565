/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type DraggableCard_task$ref = any;
type genericTaskContextMenu_company$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type MyWorkFavouredList_viewer$ref: FragmentReference;
declare export opaque type MyWorkFavouredList_viewer$fragmentType: MyWorkFavouredList_viewer$ref;
export type MyWorkFavouredList_viewer = {|
  +id: string,
  +actualPersonId: ?string,
  +availableFeatureFlags: ?$ReadOnlyArray<?{|
    +key: ?string
  |}>,
  +startDate: ?string,
  +endDate: ?string,
  +createdAt: ?string,
  +monday: ?number,
  +tuesday: ?number,
  +wednesday: ?number,
  +thursday: ?number,
  +friday: ?number,
  +saturday: ?number,
  +sunday: ?number,
  +company: ?{|
    +$fragmentRefs: genericTaskContextMenu_company$ref
  |},
  +favouredTasks: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +favouredSortOrder: ?string,
        +$fragmentRefs: DraggableCard_task$ref,
      |}
    |}>
  |},
  +$refType: MyWorkFavouredList_viewer$ref,
|};
export type MyWorkFavouredList_viewer$data = MyWorkFavouredList_viewer;
export type MyWorkFavouredList_viewer$key = {
  +$data?: MyWorkFavouredList_viewer$data,
  +$fragmentRefs: MyWorkFavouredList_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "favouredTasks"
        ]
      }
    ]
  },
  "name": "MyWorkFavouredList_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actualPersonId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FeatureFlag",
      "kind": "LinkedField",
      "name": "availableFeatureFlags",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "monday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tuesday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wednesday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thursday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "friday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "saturday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sunday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "genericTaskContextMenu_company"
        }
      ],
      "storageKey": null
    },
    {
      "alias": "favouredTasks",
      "args": null,
      "concreteType": "TaskTypeConnection",
      "kind": "LinkedField",
      "name": "__Viewer_favouredTasks_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TaskTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Task",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "favouredSortOrder",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "DraggableCard_task"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '39c33be337cf167a44aadba5fc18241b';

module.exports = node;
