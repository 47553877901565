import React from 'react';
import {
	DeprecatedContentContainer as ContentContainer,
	DeprecatedText as Text,
	deprecatedTheme as theme,
	FlexColumn,
	FlexItem,
	FlexRow,
	Icon,
} from '@forecast-it/design-system';
import {colors, spacing} from '@forecast-it/design-system/src/deprecated/shared/styles';
import {useIntl} from 'react-intl';
import styled from 'styled-components';
import {NUMBER_TYPE} from '../../../../components/new-ui/project/project-budget-v3/util/BudgetUtils';
import Util from '../../../shared/util/util';
import ForecastTooltip from '../../../shared/components/tooltips/ForecastTooltip';
import {ForecastTooltipFormulaRenderer} from '../../../../components/ForecastTooltipFormulaRenderer';
import ProgramBudgetTypeInfo from './ProgramBudgetTypeInfo';
import {BUDGET_TYPE, PROGRAM_BUDGET_TYPE} from '../../../../constants';
import ProgramBudgetTypeProfitBox from './ProgramBudgetTypeProfitBox';
import {hasTopDownProgramBudgetFeature} from '../../../shared/util/ProgramFinancialLogic';
import ProgramUtil from '../../../shared/util/ProgramUtil';

const HorizontalDivider = styled.div`
	border: solid 1px ${colors.border.primary.default};
	height: 1px;
	width: ${spacing.xl};
`;

const VerticalDivider = styled.div`
	border-left: 1px solid ${theme.colors.border.primary.default};
	height: 100%;
`;

const formatNumber = (value, numberType, currencySymbol, intl) => {
	switch (numberType) {
		case NUMBER_TYPE.MONEY:
			return Util.getFormattedNumberWithCurrency(currencySymbol, value, intl);
		case NUMBER_TYPE.PERCENTAGE:
			return Util.getFormattedPercentage(value, intl);
		default:
			break;
	}
	return value;
};

const tooltips = () => {
	const {formatMessage} = useIntl();
	return {
		RevenueRecognitionAtCompletion: [
			{
				title: formatMessage({id: 'project_budget.recognition_at_completion'}),
				details: [
					formatMessage({id: 'common.revenue_recognized_to_date'}),
					'+',
					formatMessage({id: 'common.forecast_revenue_recognition_to_complete'}),
				],
			},
			!hasTopDownProgramBudgetFeature()
				? {
						description: formatMessage(
							{id: 'project_budget.recognition_at_completion_tooltip'},
							{program: ProgramUtil.programText(formatMessage).toLowerCase()}
						),
				  }
				: {},
		],
		RevenueRecognizedToDate: [
			!hasTopDownProgramBudgetFeature()
				? {
						title: formatMessage({id: 'project_budget.recognised_to_date'}),
						description: formatMessage(
							{id: 'project_budget.recognised_to_date_tooltip'},
							{program: ProgramUtil.programText(formatMessage).toLowerCase()}
						),
				  }
				: {},
		],
		SpendTotalAtCompletion: [
			{
				title: formatMessage({id: 'project_budget.total_value_of_service_at_completion'}),
				details: [
					formatMessage({id: 'common.time'}),
					'x',
					formatMessage({id: 'common.rate'}),
					'+',
					formatMessage({id: 'common.expenses'}),
				],
			},
			!hasTopDownProgramBudgetFeature()
				? {
						description: formatMessage({id: 'project_budget.total_value_of_service_at_completion_tooltip'}),
				  }
				: {},
		],
		SpendActualToDate: [
			{
				title: formatMessage({id: 'project_budget.actual_value_of_service_to_date'}),
				details: [
					formatMessage({id: 'common.time'}),
					'x',
					formatMessage({id: 'common.rate'}),
					'+',
					formatMessage({id: 'common.expenses'}) + ' (to date)',
				],
			},
			!hasTopDownProgramBudgetFeature()
				? {
						description: formatMessage({id: 'project_budget.actual_value_of_service_to_date_tooltip'}),
				  }
				: {},
		],
		ProfitMarginAtCompletion: [
			{
				title: formatMessage({id: 'project_budget.profit_margin_at_completion'}),
				details: [
					`( ${formatMessage({id: 'common.revenue_recognition_at_completion'})}`,
					'-',
					`${formatMessage({id: 'common.cost_at_completion'})})`,
					'/',
					formatMessage({id: 'common.revenue_recognition_at_completion'}),
					'X',
					'100',
				],
			},
			!hasTopDownProgramBudgetFeature()
				? {
						description: formatMessage(
							{id: 'project_budget.aggregated_projects_in_program_tooltip'},
							{program: ProgramUtil.programText(formatMessage).toLowerCase()}
						),
				  }
				: {},
		],
		ProfitMarginActualToDate: [
			{
				title: formatMessage({id: 'project_budget.profit_margin_to_date'}),
				details: [
					`( ${formatMessage({id: 'common.revenue_recognized_to_date'})}`,
					'-',
					`${formatMessage({id: 'common.cost_to_date'})})`,
					'/',
					formatMessage({id: 'common.revenue_recognized_to_date'}),
					'X',
					'100',
				],
			},
			!hasTopDownProgramBudgetFeature()
				? {
						description: formatMessage(
							{id: 'project_budget.aggregated_projects_in_program_tooltip'},
							{program: ProgramUtil.programText(formatMessage).toLowerCase()}
						),
				  }
				: {},
		],
		ProfitMarginActualAtCompletion: [
			{
				title: formatMessage({id: 'project_budget.profit_margin_at_completion'}),
				details: [
					`( ${formatMessage({id: 'common.revenue_recognition_at_completion'})}`,
					'-',
					`${formatMessage({id: 'common.cost_at_completion'})})`,
					'/',
					formatMessage({id: 'common.revenue_recognition_at_completion'}),
					'X',
					'100',
				],
			},
			!hasTopDownProgramBudgetFeature()
				? {
						description: formatMessage({id: 'project_budget.aggregated_projects_in_program_tooltip'}),
				  }
				: {},
		],
		RevenueRecognitionAtCompletionCapVariance: [
			{
				title: formatMessage({id: 'program_budget.variance_with_cap'}),
				details: [
					formatMessage({id: 'program.program_cap_amount'}),
					'-',
					formatMessage({id: 'project_budget.recognition_at_completion'}),
				],
			},
		],
		RevenueRecognizedToDateCapVariance: [
			{
				title: formatMessage({id: 'program_budget.variance_with_cap'}),
				details: [
					formatMessage({id: 'program.program_cap_amount'}),
					'-',
					formatMessage({id: 'common.recognition_to_date'}),
				],
			},
		],
		RemainingRevenue: [
			{
				title: formatMessage({id: 'project_budget.remaining_revenue'}),
				details: [
					formatMessage({id: 'common.fixed_price'}),
					'-',
					formatMessage({id: 'common.revenue_recognition_to_date'}),
				],
			},
		],
		SpendActualToDateFixedPriceVariance: [
			{
				title: formatMessage({id: 'program_budget.variance_with_fixed_price'}),
				details: [
					formatMessage({id: 'common.fixed_price'}),
					'-',
					formatMessage({id: 'project_budget.value_of_service_to_date'}),
				],
			},
		],
		SpendTotalAtCompletionFixedPriceVariance: [
			{
				title: formatMessage({id: 'program_budget.variance_with_fixed_price'}),
				details: [
					formatMessage({id: 'common.fixed_price'}),
					'-',
					formatMessage({id: 'project_budget.value_of_service_at_completion'}),
				],
			},
		],
		SpendActualToDateCapVariance: [
			{
				title: formatMessage({id: 'program_budget.variance_with_cap'}),
				details: [
					formatMessage({id: 'program.program_cap_amount'}),
					'-',
					formatMessage({id: 'project_budget.value_of_service_to_date'}),
				],
			},
		],
		SpendTotalAtCompletionCapVariance: [
			{
				title: formatMessage({id: 'program_budget.variance_with_cap'}),
				details: [
					formatMessage({id: 'program.program_cap_amount'}),
					'-',
					formatMessage({id: 'project_budget.value_of_service_at_completion'}),
				],
			},
		],
	};
};

const getTooltipForAggregate = (tooltips, aggregate) => {
	const tooltipItems = tooltips ? tooltips[aggregate] : null;
	return tooltipItems ? tooltipItems : null;
};

const BudgetHeaderCardWrapper = styled.div`
	margin-right: ${spacing.l};
`;

const BudgetHeaderCard = ({children}) => {
	return (
		<BudgetHeaderCardWrapper>
			<FlexItem>
				<FlexColumn gap={'xl'}>
					{children.map(child => {
						return <FlexItem>{child}</FlexItem>;
					})}
				</FlexColumn>
			</FlexItem>
		</BudgetHeaderCardWrapper>
	);
};

const FinancialNumber = ({
	translationId,
	cy,
	tooltipTranslationId,
	tooltip,
	tooltipMaxWidth,
	value,
	size = 'm',
	numberType,
	currencySymbol,
}) => {
	const tooltipItem = getTooltipForAggregate(tooltips(), tooltip);
	const intl = useIntl();
	return (
		<FlexColumn gap={'m'}>
			{hasTopDownProgramBudgetFeature() ? (
				<Text type={'label'} variant={'l'} color={'primary'}>
					{intl.formatMessage({id: translationId})}
				</Text>
			) : (
				<Text type={'heading'} variant={'s'} color={'secondary'}>
					{intl.formatMessage({id: translationId})}
				</Text>
			)}
			<FlexRow gap={'m'}>
				<Text
					type={'heading'}
					variant={size}
					color={value < 0 ? 'destructive' : size !== 'm' ? 'primary' : 'secondary'}
					data-cy={cy}
				>
					{formatNumber(value, numberType, currencySymbol, intl)}
				</Text>
				{tooltipTranslationId && !hasTopDownProgramBudgetFeature() ? (
					<ForecastTooltip
						maxWidth={tooltipMaxWidth}
						placement={'bottom'}
						content={<ForecastTooltipFormulaRenderer items={tooltipItem} translatedMessage={true} />}
					>
						<FlexColumn>
							<Icon icon={'help'} size={size} />
						</FlexColumn>
					</ForecastTooltip>
				) : tooltipTranslationId && tooltip !== 'RevenueRecognizedToDate' ? (
					<ForecastTooltip
						maxWidth={tooltipMaxWidth}
						placement={'bottom'}
						content={<ForecastTooltipFormulaRenderer items={tooltipItem} translatedMessage={true} />}
					>
						<FlexColumn>
							<Icon icon={'help'} size={size} />
						</FlexColumn>
					</ForecastTooltip>
				) : null}
			</FlexRow>
		</FlexColumn>
	);
};

const ProgramBudgetTotals = ({program, company, actualPersonId}) => {
	const currencySymbol = Util.GetCurrencySymbol(company.currency);
	const {formatMessage} = useIntl();

	return hasTopDownProgramBudgetFeature() ? (
		<FlexColumn gap={'xl'}>
			<Text type={'heading'} variant={'l'}>
				{formatMessage({id: 'program.budget.overview'}, {Program: ProgramUtil.programText(formatMessage)})}
			</Text>

			{program.budgetType === PROGRAM_BUDGET_TYPE.UNCAPPED ? (
				<ProgramBudgetTypeInfo program={program} company={company} actualPersonId={actualPersonId} />
			) : null}

			{program.budgetType === PROGRAM_BUDGET_TYPE.FIXED_PRICE || program.budgetType === PROGRAM_BUDGET_TYPE.CAPPED ? (
				<FlexRow justifyContent={'left'} gap={'l'}>
					<FlexItem>
						<ProgramBudgetTypeInfo program={program} company={company} actualPersonId={actualPersonId} />
					</FlexItem>
					<FlexItem>
						<ProgramBudgetTypeProfitBox program={program} company={company} actualPersonId={actualPersonId} />
					</FlexItem>
				</FlexRow>
			) : null}

			{program.budgetType === PROGRAM_BUDGET_TYPE.UNCAPPED ? (
				<FlexRow justifyContent="left" alignItems={'flex-start'} gap={'xl'}>
					<ContentContainer width={'auto'}>
						<BudgetHeaderCard>
							<Text type={'heading'} variant={'m'} color={'primary'}>
								{formatMessage({id: 'common.profit'})}
							</Text>
							<FinancialNumber
								translationId={'project_budget.profit_margin_to_date'}
								cy={'profitMargin.toDate'}
								tooltipTranslationId={'project_budget.profit_margin_to_date'}
								tooltip={'ProfitMarginActualToDate'}
								tooltipMaxWidth={1000}
								value={program.projectsFinancialsToDate.recognitionActualProfitPercentage}
								numberType={NUMBER_TYPE.PERCENTAGE}
								size={'s'}
							/>
							<FinancialNumber
								translationId={'project_budget.profit_margin_at_completion'}
								cy={'profitMargin.atCompletion'}
								tooltipTranslationId={'project_budget.profit_margin_at_completion'}
								tooltip={'ProfitMarginAtCompletion'}
								tooltipMaxWidth={1000}
								value={program.projectsFinancialsTotals.recognitionProfitPercentage}
								numberType={NUMBER_TYPE.PERCENTAGE}
								size={'s'}
							/>
						</BudgetHeaderCard>
					</ContentContainer>
					<ContentContainer width={'auto'}>
						<FlexRow justifyContent="left" alignItems={'flex-start'} gap={'xl'}>
							<BudgetHeaderCard>
								<Text type={'heading'} variant={'m'} color={'primary'}>
									{formatMessage({id: 'project_budget.revenue'})}
								</Text>
								<FinancialNumber
									translationId={'common.revenue_recognition_to_date'}
									cy={'revenueRecognition.toDate'}
									tooltipTranslationId={'common.revenue_recognition_to_date'}
									tooltip={'RevenueRecognizedToDate'}
									tooltipMaxWidth={350}
									value={program.projectsFinancialsToDate.totalActualRevenueRecognition}
									numberType={NUMBER_TYPE.MONEY}
									currencySymbol={currencySymbol}
									size={'s'}
								/>
								<FinancialNumber
									translationId={'common.revenue_recognition_at_completion'}
									cy={'revenueRecognition.atCompletion'}
									tooltipTranslationId={'common.revenue_recognition_at_completion'}
									tooltip={'RevenueRecognitionAtCompletion'}
									tooltipMaxWidth={450}
									value={program.projectsFinancialsTotals.recognitionForecastRevenue}
									numberType={NUMBER_TYPE.MONEY}
									currencySymbol={currencySymbol}
									size={'s'}
								/>
							</BudgetHeaderCard>
							<VerticalDivider />
							<BudgetHeaderCard>
								<Text type={'heading'} variant={'m'} color={'primary'}>
									{formatMessage({id: 'common.value_of_service'})}
								</Text>
								<FinancialNumber
									translationId={'project_budget.value_of_service_to_date'}
									cy={'valueOfService.toDate'}
									tooltipTranslationId={'project_budget.value_of_service_to_date'}
									tooltip={'SpendActualToDate'}
									tooltipMaxWidth={350}
									value={program.projectsFinancialsTotals.allActualTimeAndExpenses}
									numberType={NUMBER_TYPE.MONEY}
									currencySymbol={currencySymbol}
									size={'s'}
								/>
								<FinancialNumber
									translationId={'project_budget.value_of_service_at_completion'}
									cy={'valueOfService.atCompletion'}
									tooltipTranslationId={'project_budget.total_value_of_service_at_completion_tooltip'}
									tooltip={'SpendTotalAtCompletion'}
									tooltipMaxWidth={350}
									value={program.projectsFinancialsTotals.allTotalTimeAndExpensesAtCompletion}
									numberType={NUMBER_TYPE.MONEY}
									currencySymbol={currencySymbol}
									size={'s'}
								/>
							</BudgetHeaderCard>
						</FlexRow>
					</ContentContainer>
				</FlexRow>
			) : null}

			{program.budgetType === PROGRAM_BUDGET_TYPE.FIXED_PRICE || program.budgetType === PROGRAM_BUDGET_TYPE.CAPPED ? (
				<FlexRow justifyContent="left" alignItems={'flex-start'} gap={'xl'}>
					<ContentContainer width={'auto'}>
						<FlexRow justifyContent="left" alignItems={'flex-start'} gap={'xl'}>
							{program.budgetType === PROGRAM_BUDGET_TYPE.CAPPED ? (
								<BudgetHeaderCard>
									<Text type={'heading'} variant={'m'} color={'primary'}>
										{formatMessage({id: 'project_budget.revenue_to_date'})}
									</Text>
									<FinancialNumber
										translationId={'common.recognition_to_date'}
										cy={'revenueRecognition.toDate'}
										tooltipTranslationId={'common.recognition_to_date'}
										tooltip={'RevenueRecognizedToDate'}
										tooltipMaxWidth={350}
										value={program.projectsFinancialsToDate.totalActualRevenueRecognition}
										numberType={NUMBER_TYPE.MONEY}
										currencySymbol={currencySymbol}
										size={'s'}
									/>
									<FinancialNumber
										translationId={'program_budget.variance_with_cap'}
										cy={'revenueRecognition.toDate.variance'}
										tooltipTranslationId={'program_budget.variance_with_cap'}
										tooltip={'RevenueRecognizedToDateCapVariance'}
										tooltipMaxWidth={450}
										value={
											program.budgetValue - program.projectsFinancialsToDate.totalActualRevenueRecognition
										}
										numberType={NUMBER_TYPE.MONEY}
										currencySymbol={currencySymbol}
										size={'s'}
									/>
								</BudgetHeaderCard>
							) : null}
							{program.budgetType === PROGRAM_BUDGET_TYPE.CAPPED ? <VerticalDivider /> : null}
							{program.budgetType === PROGRAM_BUDGET_TYPE.CAPPED ? (
								<BudgetHeaderCard>
									<Text type={'heading'} variant={'m'} color={'primary'}>
										{formatMessage({id: 'project_budget.revenue_at_completion'})}
									</Text>
									<FinancialNumber
										translationId={'project_budget.recognition_at_completion'}
										cy={'revenueRecognition.atCompletion'}
										tooltipTranslationId={'project_budget.recognition_at_completion'}
										tooltip={'RevenueRecognitionAtCompletion'}
										tooltipMaxWidth={600}
										value={program.projectsFinancialsTotals.recognitionForecastRevenue}
										numberType={NUMBER_TYPE.MONEY}
										currencySymbol={currencySymbol}
										size={'s'}
									/>
									<FinancialNumber
										translationId={'program_budget.variance_with_cap'}
										cy={'revenueRecognition.atCompletion.variance'}
										tooltipTranslationId={'program_budget.variance_with_cap'}
										tooltip={'RevenueRecognitionAtCompletionCapVariance'}
										tooltipMaxWidth={450}
										value={
											program.budgetValue - program.projectsFinancialsTotals.recognitionForecastRevenue
										}
										numberType={NUMBER_TYPE.MONEY}
										currencySymbol={currencySymbol}
										size={'s'}
									/>
								</BudgetHeaderCard>
							) : null}
							{program.budgetType === PROGRAM_BUDGET_TYPE.FIXED_PRICE ? (
								<BudgetHeaderCard>
									<Text type={'heading'} variant={'m'} color={'primary'}>
										{formatMessage({id: 'project_budget.revenue'})}
									</Text>
									<FinancialNumber
										translationId={'common.revenue_recognition_to_date'}
										cy={'revenueRecognition.toDate'}
										tooltipTranslationId={'common.revenue_recognition_to_date'}
										tooltip={'RevenueRecognizedToDate'}
										tooltipMaxWidth={350}
										value={program.projectsFinancialsToDate.totalActualRevenueRecognition}
										numberType={NUMBER_TYPE.MONEY}
										currencySymbol={currencySymbol}
										size={'s'}
									/>
									<FinancialNumber
										translationId={'project_budget.remaining_revenue'}
										cy={'revenueRecognition.remaining'}
										tooltipTranslationId={'project_budget.remaining_revenu'}
										tooltip={'RemainingRevenue'}
										tooltipMaxWidth={350}
										value={
											program.budgetValue - program.projectsFinancialsToDate.totalActualRevenueRecognition
										}
										numberType={NUMBER_TYPE.MONEY}
										currencySymbol={currencySymbol}
										size={'s'}
									/>
								</BudgetHeaderCard>
							) : null}
							<VerticalDivider />
							<BudgetHeaderCard>
								<Text type={'heading'} variant={'m'} color={'primary'}>
									{formatMessage({id: 'project_budget.value_of_service_to_date'})}
								</Text>
								<FinancialNumber
									translationId={'project_budget.value_of_service_to_date'}
									cy={'valueOfService.toDate'}
									tooltipTranslationId={'project_budget.value_of_service_to_date'}
									tooltip={'SpendActualToDate'}
									tooltipMaxWidth={350}
									value={program.projectsFinancialsTotals.allActualTimeAndExpenses}
									numberType={NUMBER_TYPE.MONEY}
									currencySymbol={currencySymbol}
									size={'s'}
								/>
								<FinancialNumber
									translationId={
										program.budgetType === PROGRAM_BUDGET_TYPE.FIXED_PRICE
											? 'program_budget.variance_with_fixed_price'
											: 'program_budget.variance_with_cap'
									}
									cy={'valueOfService.toDate.variance'}
									tooltipTranslationId={'project_budget.recognition_at_completion_tooltip'}
									tooltip={
										program.budgetType === BUDGET_TYPE.FIXED_PRICE
											? 'SpendActualToDateFixedPriceVariance'
											: 'SpendActualToDateCapVariance'
									}
									tooltipMaxWidth={450}
									value={program.budgetValue - program.projectsFinancialsTotals.allActualTimeAndExpenses}
									numberType={NUMBER_TYPE.MONEY}
									currencySymbol={currencySymbol}
									size={'s'}
								/>
							</BudgetHeaderCard>
							<VerticalDivider />
							<BudgetHeaderCard>
								<Text type={'heading'} variant={'m'} color={'primary'}>
									{formatMessage({id: 'project_budget.value_of_service_at_completion'})}
								</Text>
								<FinancialNumber
									translationId={'project_budget.value_of_service_at_completion'}
									cy={'valueOfService.atCompletion'}
									tooltipTranslationId={'project_budget.value_of_service_at_completion'}
									tooltip={'SpendTotalAtCompletion'}
									tooltipMaxWidth={350}
									value={program.projectsFinancialsTotals.allTotalTimeAndExpensesAtCompletion}
									numberType={NUMBER_TYPE.MONEY}
									currencySymbol={currencySymbol}
									size={'s'}
								/>
								<FinancialNumber
									translationId={
										program.budgetType === PROGRAM_BUDGET_TYPE.FIXED_PRICE
											? 'program_budget.variance_with_fixed_price'
											: 'program_budget.variance_with_cap'
									}
									cy={'valueOfService.atCompletion.variance'}
									tooltipTranslationId={'program_budget.variance_with_fixed_price'}
									tooltip={
										program.budgetType === BUDGET_TYPE.FIXED_PRICE
											? 'SpendTotalAtCompletionFixedPriceVariance'
											: 'SpendTotalAtCompletionCapVariance'
									}
									tooltipMaxWidth={350}
									value={
										program.budgetValue -
										program.projectsFinancialsTotals.allTotalTimeAndExpensesAtCompletion
									}
									numberType={NUMBER_TYPE.MONEY}
									currencySymbol={currencySymbol}
									size={'s'}
								/>
							</BudgetHeaderCard>
						</FlexRow>
					</ContentContainer>
				</FlexRow>
			) : null}
		</FlexColumn>
	) : (
		<FlexColumn gap={'xl'}>
			<Text type={'heading'} variant={'l'}>
				{formatMessage({id: 'program.budget.overview'}, {Program: ProgramUtil.programText(formatMessage)})}
			</Text>

			<FlexRow justifyContent="left" alignItems={'flex-start'} gap={'xl'}>
				<ContentContainer width={'auto'}>
					<FlexRow justifyContent="left" alignItems={'flex-start'} gap={'xl'}>
						<BudgetHeaderCard>
							<Text type={'heading'} variant={'m'} color={'primary'}>
								{formatMessage({id: 'project_budget.revenue'})}
							</Text>
							<FinancialNumber
								translationId={'project_budget.recognition_at_completion'}
								tooltipTranslationId={'project_budget.recognition_at_completion_tooltip'}
								tooltip={'RevenueRecognitionAtCompletion'}
								tooltipMaxWidth={450}
								value={program.projectsFinancialsTotals.recognitionForecastRevenue}
								numberType={NUMBER_TYPE.MONEY}
								currencySymbol={currencySymbol}
								size={'l'}
							/>
							<HorizontalDivider />
							<FinancialNumber
								translationId={'project_budget.recognised_to_date'}
								tooltipTranslationId={'project_budget.recognised_to_date_tooltip'}
								tooltip={'RevenueRecognizedToDate'}
								tooltipMaxWidth={350}
								value={program.projectsFinancialsToDate.recognitionAmount}
								numberType={NUMBER_TYPE.MONEY}
								currencySymbol={currencySymbol}
							/>
						</BudgetHeaderCard>
						<BudgetHeaderCard>
							<Text type={'heading'} variant={'m'} color={'primary'}>
								{formatMessage({id: 'project_budget.value_of_service_to_date'})}
							</Text>
							<FinancialNumber
								translationId={'project_budget.total_value_of_service_at_completion'}
								tooltipTranslationId={'project_budget.total_value_of_service_at_completion_tooltip'}
								tooltip={'SpendTotalAtCompletion'}
								tooltipMaxWidth={350}
								value={program.projectsFinancialsTotals.allTotalTimeAndExpensesAtCompletion}
								numberType={NUMBER_TYPE.MONEY}
								currencySymbol={currencySymbol}
								size={'l'}
							/>
							<HorizontalDivider />
							<FinancialNumber
								translationId={'project_budget.actual_value_of_service_to_date'}
								tooltipTranslationId={'project_budget.actual_value_of_service_to_date_tooltip'}
								tooltip={'SpendActualToDate'}
								tooltipMaxWidth={350}
								value={program.projectsFinancialsTotals.allActualTimeAndExpenses}
								numberType={NUMBER_TYPE.MONEY}
								currencySymbol={currencySymbol}
							/>
						</BudgetHeaderCard>
					</FlexRow>
				</ContentContainer>
				<ContentContainer width={'auto'}>
					<BudgetHeaderCard>
						<Text type={'heading'} variant={'m'} color={'primary'}>
							{formatMessage({id: 'project_budget.profit_margin'})}
						</Text>
						<FinancialNumber
							translationId={'project_budget.profit_margin_at_completion'}
							tooltipTranslationId={'project_budget.aggregated_projects_in_program_tooltip'}
							tooltip={'ProfitMarginAtCompletion'}
							tooltipMaxWidth={1000}
							value={program.projectsFinancialsTotals.recognitionProfitPercentage}
							numberType={NUMBER_TYPE.PERCENTAGE}
							size={'l'}
						/>
						<HorizontalDivider />
						<FinancialNumber
							translationId={'project_budget.profit_margin_to_date'}
							tooltipTranslationId={'project_budget.aggregated_projects_in_program_tooltip'}
							tooltip={'ProfitMarginActualToDate'}
							tooltipMaxWidth={1000}
							value={program.projectsFinancialsToDate.recognitionActualProfitPercentage}
							numberType={NUMBER_TYPE.PERCENTAGE}
						/>
					</BudgetHeaderCard>
				</ContentContainer>
			</FlexRow>
		</FlexColumn>
	);
};

export default ProgramBudgetTotals;
