import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';

class InsightsEmptyState extends Component {
	render() {
		const {formatMessage} = this.props.intl;
		return (
			<div className="insights-empty-state-container">
				<span className={'info' + (this.props.small ? ' small-text' : '')}>
					{this.props.searchText
						? formatMessage({id: this.props.infoMessageId}, {searchText: this.props.searchText})
						: formatMessage({id: this.props.infoMessageId})}
				</span>
				<img src={require('../../../../images/empty-states/empty-state-background-2.png')} alt="insights" />
				{this.props.altMessageId ? <span className="info">{formatMessage({id: this.props.altMessageId})}</span> : null}
			</div>
		);
	}
}
InsightsEmptyState.propTypes = {
	infoMessageId: PropTypes.string.isRequired,
	searchText: PropTypes.string,
};
export default injectIntl(InsightsEmptyState);
