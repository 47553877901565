import React from 'react';
import {getPageInfo} from './empty_state_options';
import {
	PageWrapper,
	ImagesWrapper,
	Avatar,
	LeftArrow,
	RightArrow,
	HeadlineMessage,
	InfoMessage,
	ButtonWrapper,
} from './empty_state_styled';
import Button from '../buttons/button/button';
import {BUTTON_STYLE, BUTTON_COLOR} from '../../../../constants';

export const EMPTY_STATE = {
	SCOPING: 0,
	PHASE: 1,
	PROJECTS: 2,
	STARRED_TASKS: 3,
	NOTIFICATIONS: 4,
	RATE_CARDS_ADMIN: 5,
	INVOICES: 6,
	RETAINER: 7,
	FILES: 8,
	TIMESHEETS: 9,
	SPRINTS: 10,
	SPRINTS_CHILDREN: 11,
	BUDGET: 12,
	ALL_TASKS: 13,
	RATE_CARDS_PROJECT: 14,
	PROJECTS_COLLABORATOR: 15,
	NO_RESULT_FOUND: 16,
	STARRED_TASKS_SMALL: 17,
	MY_WORK_TASKS: 18,
	MY_WORK_TASKS_PAST: 19,
	MY_WORK_SPRINTS: 20,
	MY_WORK_SPRINTS_NEXT_WEEK: 21,
	BASELINE_NO_DATES: 22,
	BASELINE: 23,
	APPROVAL_NO_SUBMIT: 24,
	APPROVAL_NO_REGISTERED: 25,
	APPROVAL_MISSING_SUBMITION: 26,
	APPROVAL_NOTHING_APPROVED: 27,
	APPROVAL_ALL_APPROVED: 28,
	APPROVAL_ALL_SUBMITTED: 29,
	TASK_REPORT: 30,
	SISENSE_NO_USER: 31,
	NO_ERRORS: 32,
	PLACEHOLDERS_SCHEDULING: 33,
	APPROVAL_NO_SUBMIT_ON_OWNED: 34,
	APPROVAL_NO_REGISTERED_ON_OWNED: 35,
	REPORTED_TIME_TABLE: 36,
	TIMESHEETS_NEW: 37,
};

const EmptyState = ({pageName, callback, filterOrSearchActive, size, showSuggestionsLink}) => {
	if (filterOrSearchActive) {
		const page = getPageInfo(EMPTY_STATE.NO_RESULT_FOUND, EMPTY_STATE);
		return (
			<PageWrapper>
				<HeadlineMessage>{page.headline}</HeadlineMessage>
				<InfoMessage>{page.info}</InfoMessage>
				{page.avatar ? (
					<ImagesWrapper size={size}>
						<Avatar size={size} image={page.avatar}></Avatar>
					</ImagesWrapper>
				) : null}
			</PageWrapper>
		);
	} else {
		const page = getPageInfo(pageName, EMPTY_STATE, showSuggestionsLink);

		return (
			<PageWrapper data-cy="empty-state-page">
				{page.background || page.avatar ? (
					<ImagesWrapper size={size}>
						{page.noArrow ? null : page.leftArrow ? <LeftArrow size={size} /> : <RightArrow size={size} />}
						<Avatar size={size} image={page.background}></Avatar>
						<Avatar size={size} image={page.avatar}></Avatar>
					</ImagesWrapper>
				) : null}
				<HeadlineMessage size={size}>{page.headline}</HeadlineMessage>
				{page.info ? <InfoMessage>{page.info}</InfoMessage> : null}
				{callback ? (
					<ButtonWrapper>
						<Button
							data-cy="empty-state-button"
							buttonStyle={page.buttonFilled ? BUTTON_STYLE.FILLED : BUTTON_STYLE.OUTLINE}
							colorTheme={BUTTON_COLOR.PURPLE}
							text={page.actionText}
							onClick={callback}
						/>
					</ButtonWrapper>
				) : null}
			</PageWrapper>
		);
	}
};

EmptyState.EMPTY_STATE = EMPTY_STATE;

EmptyState.SIZE = {
	SMALL: 'small',
	MEDIUM: 'medium',
};

EmptyState.defaultProps = {
	size: EmptyState.SIZE.MEDIUM,
};

export default EmptyState;
