import Styled from 'styled-components';

export const PreviewSelector = Styled.div`
	font-size: 9px;
	.dropdown-container {
	    height: 40px;
	    > div {
	        padding: 0;
	    }
	}
	input {
        padding: 10px 30px 10px 16px;
	)
`;
