import {cloneDeep} from 'lodash';
import Util from '../util';
import {HIDDEN_FEATURES} from '../../../../constants';

const theEyeOptions = () => {
	return [
		{checked: true, name: 'star'},
		{checked: true, name: 'projectId', translationId: 'overview_time.card_details_selector_id'},
		{checked: false, name: 'projectName', translationId: 'common.project-name'},
		{checked: false, name: 'taskId', translationId: 'project_board.card_details_selector_task_id'},
		{checked: true, name: 'task-name'},
		{checked: false, name: 'assignedPerson', translationId: 'common.assignees'},
		{checked: false, name: 'owner', translationId: 'common.owner'},
		{checked: false, name: 'client', translationId: 'common.client'},
		{checked: true, name: 'status', translationId: 'common.status'},
		{checked: false, name: 'startDate', translationId: 'common.start_date'},
		{checked: false, name: 'deadline', translationId: 'common.deadline'},
		{checked: false, name: 'phase', translationId: 'common.phase'},
		{checked: true, name: 'progress', translationId: 'common.progress'},
		{checked: true, name: 'estimate', translationId: 'common.estimate'},
		{checked: true, name: 'timeEntries', translationId: 'common.time_entries'},
		{checked: false, name: 'remaining', translationId: 'common.remaining'},
		{checked: false, name: 'labels', translationId: 'common.labels'},
	];
};

export const theEyeToColumns = actualEye => {
	const val = {};
	actualEye.forEach(e => {
		val[e.name] = e.checked;
	});
	return val;
};

const optionVisitor = (option, callback, parentPath) => {
	const path = parentPath ? `${parentPath}.${option.name}` : option.name;

	callback(option, path);
	if (option.nestedOptions) {
		option.nestedOptions.forEach(nestedOption => {
			optionVisitor(nestedOption, callback, path);
		});
	}
};

const optionTraverser = (options, callback) => {
	options.forEach(option => {
		optionVisitor(option, callback, '');
	});
};

export const getInitialOptions = () => {
	const noTimeReg = Util.isFeatureHidden(HIDDEN_FEATURES.TIME_REGISTRATIONS);
	const options = noTimeReg
		? Util.getNoTimeRegColumns(cloneDeep(theEyeOptions()), ['timeEntries'])
		: cloneDeep(theEyeOptions());

	const locallySavedOptionsString = localStorage.getItem('my-work-options');
	if (locallySavedOptionsString) {
		const savedOptionsValues = JSON.parse(locallySavedOptionsString);
		if (typeof savedOptionsValues === 'object' && !Array.isArray(savedOptionsValues)) {
			optionTraverser(options, (option, path) => {
				if (savedOptionsValues[path] !== undefined) {
					option.checked = savedOptionsValues[path];
				} else {
					savedOptionsValues[path] = {...option};
				}
			});
		}
	}

	return options;
};

export const handleChangedOptions = changedOptions => {
	const savedOptionValues = {};
	optionTraverser(changedOptions, (option, path) => {
		savedOptionValues[path] = option.checked;
	});

	Util.localStorageSetItem('my-work-options', JSON.stringify(savedOptionValues));
	return changedOptions;
};
