import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import Comment from './comment_section_comment';
import CommentsIcon from '../../../../../../images/v2/components/workflow-task/v3/ico-comments.svg';
import ExpandIcon from '../../../../../../images/v2/components/dropdown/expand_icon';

class CommentSection extends Component {
	state = {
		showAllComments: this.props.task.comments.edges.length <= 3,
	};

	handleCommentsTogglePress(e) {
		if (e.key === 'Enter') this.toggleAllComments();
	}

	toggleAllComments() {
		this.setState({showAllComments: !this.state.showAllComments});
	}

	render() {
		const {viewer, task, isTaskReadOnly, showComments, project} = this.props;

		// Task modal has been throwing errors in production , due to missing data on the task. We're looking into it.
		if (task.comments == null) {
			return <div className={'error-comments-empty'} />;
		}
		const comments = this.state.showAllComments
			? task.comments.edges
			: task.comments.edges.slice(task.comments.edges.length - 3, task.comments.edges.length);
		const {formatMessage} = this.props.intl;

		if (task.comments.edges.length === 0) return null;

		return (
			<div className={'comment-section-container'}>
				<button
					className="comment-section-header"
					onClick={comments.length ? this.props.toggleComments.bind(this) : null}
				>
					<div className="comments-icon">
						<img src={CommentsIcon} alt={formatMessage({id: 'cards.comments'})} />
					</div>
					<div className="comments-text">{formatMessage({id: 'cards.comments'})}</div>
					<div className="comments-count">{`(${task.comments.edges.length})`}</div>
					{comments.length ? <ExpandIcon orientation={showComments ? 'up' : 'down'} /> : null}
				</button>

				{showComments && task.commentCount ? (
					<>
						{!this.state.showAllComments && task.comments.edges.length > 3 ? (
							<div
								className="comments-toggle"
								tabIndex="0"
								onClick={this.toggleAllComments.bind(this)}
								onKeyPress={this.handleCommentsTogglePress.bind(this)}
							>
								{formatMessage({id: 'task_modal.view_previous_comments'})}
							</div>
						) : null}
						<div className={'task-modal-v3-comments'}>
							{comments.map(comment => (
								<Comment
									viewer={viewer}
									project={project}
									key={comment.node.id}
									comment={comment}
									isViewerComment={comment.node.person.id === viewer.actualPersonId}
									isTaskReadOnly={isTaskReadOnly}
									taskId={task.id}
								/>
							))}
						</div>
					</>
				) : null}
			</div>
		);
	}
}

CommentSection.propTypes = {
	task: PropTypes.object.isRequired,
};

export default injectIntl(CommentSection, {forwardRef: true});
