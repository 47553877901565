import {
	DeprecatedButtonIconOnly as ButtonIconOnly,
	DeprecatedButton as Button,
	DeprecatedText as Text,
	deprecatedTheme as theme,
	FlexRow,
} from '@forecast-it/design-system';
import React from 'react';
import {useIntl} from 'react-intl';
import styled from 'styled-components';

/* We need these exported from design system or we need a more general modal type that is "neutral" and not either red or green */
export const ModalContainer = styled.div`
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 900;
	background: ${theme.colors.background.layover}4D;
	display: flex;
	justify-content: center;
	align-items: center;
`;

/* We need these exported from design system or we need a more general modal type that is "neutral" and not either red or green */
export const ModalBox = styled.div`
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	width: 440px;
	flex-direction: column;
	gap: ${theme.spacing.xl};
	border-radius: ${theme.borderRadius.l};
	background-color: ${theme.colors.background.primary};
	${theme.shadow.xl}
	padding: ${theme.spacing.xl} ${theme.spacing.xl} ${theme.spacing.l} ${theme.spacing.xl};
`;

/* We need a more general modal type that is "neutral" and not either red or green - this will work for now */
export const ProgramBudgetTypeChangedModal = ({closeModal, goToBudgetCallback}) => {
	const {formatMessage} = useIntl();
	const closeAndGoToBudget = () => {
		closeModal();
		goToBudgetCallback();
	};
	return (
		<ModalContainer onClick={closeModal}>
			<ModalBox onClick={e => e.stopPropagation()}>
				<FlexRow justifyContent={'space-between'} gap={'s'}>
					<Text type={'heading'} variant={'xl'}>
						{formatMessage({id: 'program.budget_modal.program_budget_type_changed'})}
					</Text>
					<ButtonIconOnly size={'s'} type={'ghost'} icon={'close'} onClick={closeModal} />
				</FlexRow>
				<Text>{formatMessage({id: 'program.budget_modal.program_budget_type_changed.description'})}</Text>
				<FlexRow alignItems={'flex-end'} justifyContent={'flex-end'} gap={'s'}>
					<Button data-cy={'closeAndGoToBudget'} size={'l'} type={'secondary'} onClick={closeAndGoToBudget}>
						{formatMessage({id: 'program.budget_modal.go_to_program_budget_page'})}
					</Button>
					<Button size={'l'} type={'ghost'} onClick={closeModal}>
						{formatMessage({id: 'program.budget_modal.stay_in_settings'})}
					</Button>
				</FlexRow>
			</ModalBox>
		</ModalContainer>
	);
};
