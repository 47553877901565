import React, {memo, useEffect, useRef, useState} from 'react';
import ForecastQueryRenderer from '../../../../ForecastQueryRenderer';
import ProjectWorkflowColumnContent, {ProjectWorkflowColumnContentQuery} from './ProjectWorkflowColumnContent';
import ProjectWorkflowAddTask from './ProjectWorkflowAddTask';
import {
	CantDropCollapsed,
	CollapsedColumnHeader,
	CollapsedColumnWrapper,
	ColumnContentWrapper,
	ColumnHeader,
	ColumnWrapper,
	DependencyOverlay,
	HeaderLogo,
	HeaderSettingsMenu,
	HoverControl,
	RotatedDiv,
	TextInput,
	TitleAndCount,
} from './ProjectWorkflow.styled';
import {getSearchQueryForStatusCol} from './ProjectWorkflowLogic';
import StatusColumnCategorySelector from './status_column_category_selector';
import {CollapseIcon, ContextMenu, DragHandleIcon, JiraIcon, VerticalDotsIcon} from 'web-components';
import {ClickAwayListener} from '@material-ui/core';
import Switch from '../../../shared/components/switch/switch';
import {Droppable} from '@forecasthq/react-virtualized-dnd';
import Util from '../../../shared/util/util';
import UpdateStatusColumnMutation from '../../../../mutations/update_status_column_mutation';
import DeleteStatusColumnMutation from '../../../../mutations/delete_status_column_mutation';
import {MODAL_TYPE, showModal} from '../../../shared/components/modals/generic_modal_conductor';
import Warning from '../../../../components/warning';
import adoLogo from '../../../../images/integrations/azure-dev-ops.svg';
import {BUTTON_COLOR, BUTTON_STYLE, HIDDEN_FEATURES, ItemTypes} from '../../../../constants';
import BlockedIcon from '../../../../images/ico-blocked';
import {FormattedMessage} from 'react-intl';
import {has} from 'lodash';
import CardLoaderWrapper from '../../../../components/CardLoader';
import {DragAndDropGroup} from './ProjectWorkflowPage';
import {DragSource, DropTarget} from 'react-dnd';
import flow from 'lodash/flow';
import ProjectUtil from '../../../shared/util/project_util';

const columnSource = {
	canDrag(props, monitor) {
		return !(props.isProjectLocked || !props.canEditWorkflow || (props.viewer.projectGroup && props.viewer.project));
	},
	beginDrag(props) {
		props.startDragColumn(props.column);
		return {
			isColumn: true,
			id: props.column.node.id,
			order: props.statusOrder,
			expanded: props.expanded,
			name: props.column.node.name,
		};
	},
	endDrag(props, monitor) {
		const didDrop = monitor.didDrop();

		if (!didDrop) {
			props.saveStatusesReorder();
		}
	},
};

function collectSource(connect, monitor) {
	return {
		connectDragSource: connect.dragSource(),
		connectDragPreview: connect.dragPreview(),
		isDragging: monitor.isDragging(),
	};
}

const columnTarget = {
	drop(props, monitor, component) {
		if (!monitor.isOver({shallow: true})) return;
		if (monitor.getItem().isColumn) {
			props.saveStatusesReorder();
		}
	},
	hover(props, monitor, component) {
		if (monitor.getItem().isColumn) {
			if (monitor.getItem().id === props.column.node.id || monitor.getItem().order === props.statusOrder) return;
			const draggedLeft = monitor.getItem().order > props.statusOrder;
			props.updateDraggedColumn(monitor.getItem().order, props.statusOrder, draggedLeft, props.column);
			monitor.getItem().order = props.statusOrder;
			/*

			need to find how to get component as not null

			let hoverClientX;
			const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();
			const clientOffset = monitor.getClientOffset();
			if (draggedLeft) {
				hoverClientX = clientOffset.x - hoverBoundingRect.left;
			} else {
				hoverClientX = clientOffset.x - hoverBoundingRect.right;
			}
			if (monitor.getItem().expanded || (!draggedLeft && hoverClientX > -60) || (draggedLeft && hoverClientX < 60)) {
				props.updateDraggedColumn(monitor.getItem().order, props.statusOrder, draggedLeft, props.status);
				monitor.getItem().order = props.statusOrder;
			}*/
		}
	},
};

function collectTarget(connect, monitor) {
	return {
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver() && monitor.getItem().isColumn,
	};
}
const ProjectWorkflowColumn = memo(
	({
		viewer,
		projectIds,
		statusColumnIds,
		column,
		containerHeight,
		taskMapRef,
		taskSizeMapRef,
		imgSizeMapRef,
		tasksCountData,
		canEditStatus,
		canEditWorkflow,
		isConnectedParent,
		searchQueryValue,
		filters,
		intl,
		isProjectLocked,
		index,
		expanded,
		relay,
		onToggleColumn,
		isNewColumn,
		theEyeOptions,
		shouldShowDisabledOverlay,
		integrationRestriction,
		isOver,
		connectDropTarget,
		connectDragSource,
		connectDragPreview,
		startDragColumn,
		updateDraggedColumn,
		saveStatusesReorder,
		draggingColumn,
		statusOrder,
		unselectTasks,
	}) => {
		const [collapsed, setCollapsed] = useState(!expanded);
		const [editName, setEditName] = useState(false);
		const [columnName, setColumnName] = useState(column.node.name);
		const [settingsExpanded, setSettingsExpanded] = useState(false);
		const [encourageTimeRegs, setEncourageTimeRegs] = useState(column.node.encourageTimeRegistration);
		const inputRef = useRef();

		const columnId = isConnectedParent ? column.node.projectGroupStatusColumnId : column.node.id;
		const tasksCountColumns = tasksCountData
			? isConnectedParent
				? tasksCountData.viewer.projectGroup?.projects.edges.reduce(
						(acc, project) =>
							acc.concat(
								project.node
									? project.node.statusColumnsV2.edges.filter(
											column => column.node.projectGroupStatusColumnId === columnId
									  )
									: []
							),
						[]
				  )
				: tasksCountData.viewer.project?.statusColumnsV2.edges.find(column => column.node.id === columnId)
			: null;

		const tasksCount = tasksCountColumns
			? isConnectedParent
				? tasksCountColumns.reduce((acc, column) => acc + column.node.tasksCount, 0)
				: tasksCountColumns.node.tasksCount
			: null;
		const searchQuery = getSearchQueryForStatusCol(columnId, searchQueryValue, filters, isConnectedParent);

		const isConnectedChild = !isConnectedParent && viewer.project.isInProjectGroup;

		const isJiraColumn = (viewer.company.jiraCloudEnabled || viewer.company.jiraServerEnabled) && column.node.jiraStatusId;

		const opacity = isOver && draggingColumn ? 0.5 : 1;

		useEffect(() => {
			onToggleColumn(column.node.id, !collapsed);
		}, [collapsed, onToggleColumn, column.node.id]);

		const canEditName = () => {
			return !isProjectLocked && canEditWorkflow && column.node.userActions.canRename;
		};

		useEffect(() => {
			if (inputRef.current) {
				inputRef.current.focus();
			}
		}, [editName]);

		const handleSetColumnName = () => {
			setEditName(false);
			const name = columnName.trim();
			const onSuccess = data => {
				// TODO
				// if (this.props.isNewColumn) {
				// 	this.props.resetIsNewColumn();
				// }
				setColumnName(name);
			};
			if (name !== '') {
				Util.CommitMutation(
					UpdateStatusColumnMutation,
					{
						id: column.node.id,
						name: name,
					},
					onSuccess
				);
			} else {
				setColumnName(column.node.name);
			}
		};

		const handleInputKeyDown = event => {
			switch (event.key) {
				case 'Enter':
					handleSetColumnName();
					break;
				default:
					return;
			}
		};

		const handleDeleteColumn = () => {
			const onSuccess = result => {
				if (result.deleteStatusColumn.errors && result.deleteStatusColumn.errors.length !== 0) {
					showModal({
						type: MODAL_TYPE.GENERIC,
						content: (
							<div>
								<Warning messageId="common.invalid_action_modal_title" />
								<div className="warning-part-2">
									{intl.formatMessage({
										id: 'dependency.delete_status_column_warning',
									})}
								</div>
								<div className="warning-part-2">
									{intl.formatMessage({
										id: 'dependency.status_column_change_proceed_warning',
									})}
								</div>
							</div>
						),
						className: 'default-warning-modal',
						buttons: [
							{
								text: intl.formatMessage({
									id: 'common.filter-close',
								}),
								style: BUTTON_STYLE.FILLED,
								color: BUTTON_COLOR.WHITE,
							},
						],
					});
				} else {
					//relay.refetch(); is it needed?
				}
			};

			const deleteColumn = () => {
				Util.CommitSchedulingModalUpdate(
					DeleteStatusColumnMutation,
					{
						id: column.node.id,
						projectId: viewer.project ? viewer.project.id : viewer.projectGroup.projects.edges[0].node.id,
						projectGroupId: viewer.projectGroup ? viewer.projectGroup.id : undefined,
					},
					onSuccess
				);
			};
			showModal({
				type: MODAL_TYPE.WARNING,
				warningMessageId: 'common.delete-confirmation-title',
				warningInformation: [intl.formatMessage({id: 'project_board.delete_column_prompt_info'})],
				buttons: [
					{
						text: intl.formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: intl.formatMessage({id: 'common.delete'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.RED,
						callback: deleteColumn,
						cy: 'modal-delete-button',
					},
				],
			});
		};

		const handleChangeEncourageTimeEntries = e => {
			if (e && e.type && e.type === 'click') {
				e.stopPropagation();
				e.preventDefault();
			}
			const encourageTimeRegistration = !encourageTimeRegs;

			const onSuccess = () => {
				setEncourageTimeRegs(encourageTimeRegistration);
			};
			Util.CommitMutation(
				UpdateStatusColumnMutation,
				{
					id: column.node.id,
					encourageTimeRegistration,
				},
				onSuccess
			);
		};

		const handleColumnCategoryUpdate = category => {
			const onError = (transaction, defaultFailure) => {
				if (
					has(transaction, 'res.data.updateStatusColumn.errors') &&
					transaction.res.data.updateStatusColumn.errors &&
					transaction.res.data.updateStatusColumn.errors !== 0
				) {
					if (
						transaction.res.data.updateStatusColumn.errors.some(error =>
							error.includes('THIS_ACTION_WOULD_RESULT_IN_DEPENDENCY_VIOLATION_FOR')
						)
					) {
						return showModal({
							type: MODAL_TYPE.GENERIC,
							content: (
								<div>
									<Warning messageId="common.invalid_action_modal_title" />
									<div className="warning-part-2">
										{intl.formatMessage({
											id: 'dependency.update_status_column_warning',
										})}
									</div>
									<div className="warning-part-2">
										{intl.formatMessage({
											id: 'dependency.status_column_change_proceed_warning',
										})}
									</div>
								</div>
							),
							className: 'default-warning-modal',
							buttons: [
								{
									text: intl.formatMessage({
										id: 'common.filter-close',
									}),
									style: BUTTON_STYLE.FILLED,
									color: BUTTON_COLOR.WHITE,
								},
							],
						});
					}
				}
				defaultFailure(transaction);
			};

			Util.CommitMutation(
				UpdateStatusColumnMutation,
				{
					id: column.node.id,
					category,
				},
				null,
				true,
				{},
				onError
			);
		};

		const onBlurColumn = e => {
			/**
			 * this is ha hack to fix an issue on the columns.
			 * Beautiful DnD prevent all click on the column to avoid focus on it
			 * We need it to for the context menu/inputs to get closed on blur.
			 */
			if (e.defaultPrevented) {
				return;
			}
			e.target.focus();
		};

		const getDisabledOverlay = () => {
			let headerTextId = null;
			let details = null;

			if (integrationRestriction === 'jira_to_forecast_one_way_sync' && column.node.jiraStatusId) {
				headerTextId = 'project_board.cannot_move_into_jira_column';
			} else if (integrationRestriction === 'jira' && !column.node.jiraStatusId) {
				headerTextId = 'project_board.cannot_move_out_of_jira_collumn';
			}
			if (integrationRestriction === 'ado' && !column.node.adoState) {
				headerTextId = 'project_board.cannot_move_out_of_ado_column';
			}
			if (shouldShowDisabledOverlay && shouldShowDisabledOverlay.length > 0) {
				headerTextId = 'project_board.dependencies_not_fulfilled_warning';
				details = shouldShowDisabledOverlay.map((dependency, i) => (
					<div key={i} className={'dependency-item'}>
						<div className={'dependency-bullet'} />
						<span className={'dependency-text'}>{dependency}</span>
					</div>
				));
			}
			if (headerTextId !== null) {
				return (
					<DependencyOverlay>
						<div className="text-container">
							<BlockedIcon active />
							<div className="dependencies-not-fulfilled">
								<div className={'dependency-header'}>
									<FormattedMessage id={headerTextId} />
								</div>
								{details}
							</div>
							<div className="not-allowed-overlay" />
						</div>
					</DependencyOverlay>
				);
			}
			return null;
		};

		const columnHeight = containerHeight - 95; // Height of column is size of container minus size of header and size of add task

		const getExpanded = connectDragPreview(
			<div id={'statusColumn' + column.node.id} style={{opacity: opacity}}>
				<ColumnWrapper onClick={e => onBlurColumn(e)} data-cy="status-column">
					{getDisabledOverlay()}
					<ColumnHeader>
						{isProjectLocked || !canEditWorkflow || (viewer.projectGroup && viewer.project)
							? null
							: connectDragSource(
									<div>
										<HoverControl settingsExpanded={settingsExpanded}>
											<DragHandleIcon size={DragHandleIcon.SIZE.STANDARD} color="#4a4a4a" />
										</HoverControl>
									</div>
							  )}
						<StatusColumnCategorySelector
							useNewStyling
							isInProjectGroup={isConnectedChild}
							locked={!canEditWorkflow || !canEditStatus || isProjectLocked || isConnectedChild || isJiraColumn}
							value={column.node.category}
							expandByDefault={isNewColumn}
							onChange={handleColumnCategoryUpdate}
							focusNameInput={() => setEditName(true)}
							userpilot={'category selector'}
							canEditWorkflow={canEditWorkflow}
							projectLocked={isProjectLocked}
						/>
						{editName ? (
							<TitleAndCount>
								<TextInput
									ref={inputRef}
									type="text"
									value={columnName}
									onChange={e => setColumnName(e.currentTarget.value)}
									onBlur={handleSetColumnName}
									onKeyDown={handleInputKeyDown}
								/>
							</TitleAndCount>
						) : (
							<TitleAndCount onClick={() => canEditName() && setEditName(true)}>
								<p>
									<b style={{maxWidth: '160px', overflow: 'hidden', textOverflow: 'ellipsis'}}>
										{column.node.name}
									</b>
									<span>{`(${tasksCount || 0})`}</span>
								</p>
								{isJiraColumn && <JiraIcon />}
								{column.node.adoState && <HeaderLogo src={adoLogo} />}
							</TitleAndCount>
						)}

						<HoverControl
							data-cy="collapse-expand"
							onClick={() => setCollapsed(!collapsed)}
							settingsExpanded={settingsExpanded}
						>
							<CollapseIcon size={CollapseIcon.SIZE.SMALL} color="#747474" />
						</HoverControl>
						{isProjectLocked || !canEditWorkflow ? null : (
							<HoverControl
								data-cy="column-menu"
								settingsExpanded={settingsExpanded}
								onClick={() => setSettingsExpanded(true)}
							>
								<VerticalDotsIcon size={VerticalDotsIcon.SIZE.MEDIUM} color={'#4a4a4a'} />
								{settingsExpanded && (
									<ClickAwayListener onClickAway={() => setSettingsExpanded(false)}>
										<HeaderSettingsMenu>
											{column.node.jiraStatusId || column.node.adoState ? (
												<ContextMenu onClick={e => e && e === 'delete' && handleDeleteColumn()}>
													<>
														{!ProjectUtil.projectUsesManualProgress(viewer.project) &&
															!Util.isFeatureHidden(HIDDEN_FEATURES.TIME_REGISTRATIONS) && (
																<div
																	style={{
																		display: 'flex',
																		flexDirection: 'row',
																		padding: '5px 0',
																		fontSize: '13px',
																	}}
																>
																	<span style={{marginRight: '8px'}}>
																		{intl.formatMessage({
																			id: 'workflow.encourage_time_entries',
																		})}
																	</span>
																	<Switch
																		onChange={handleChangeEncourageTimeEntries}
																		checked={encourageTimeRegs}
																		sliderWidth={35}
																		sliderHeight={18}
																	/>
																</div>
															)}
													</>
												</ContextMenu>
											) : (
												<ContextMenu onClick={e => e && e === 'delete' && handleDeleteColumn()}>
													<>
														{!ProjectUtil.projectUsesManualProgress(viewer.project) &&
															!Util.isFeatureHidden(HIDDEN_FEATURES.TIME_REGISTRATIONS) && (
																<div
																	style={{
																		display: 'flex',
																		flexDirection: 'row',
																		padding: '5px 0',
																		fontSize: '13px',
																	}}
																>
																	<span style={{marginRight: '8px'}}>
																		{intl.formatMessage({
																			id: 'workflow.encourage_time_entries',
																		})}
																	</span>
																	<Switch
																		onChange={handleChangeEncourageTimeEntries}
																		checked={encourageTimeRegs}
																		sliderWidth={35}
																		sliderHeight={18}
																	/>
																</div>
															)}
													</>
													<ContextMenu.SingleText
														cy="delete-column"
														disabled={!column.node.userActions.canDelete}
														value="delete"
													>
														{intl.formatMessage({id: 'common.delete'})}
													</ContextMenu.SingleText>
												</ContextMenu>
											)}
										</HeaderSettingsMenu>
									</ClickAwayListener>
								)}
							</HoverControl>
						)}
					</ColumnHeader>
					<ProjectWorkflowAddTask
						disabled={isProjectLocked || !column.node.userActions?.canCreateTask}
						intl={intl}
						columnId={viewer.projectGroup ? column.node.projectGroupStatusColumnId : column.node.id}
						columnOrder={column.node.order}
						projectGroup={viewer.projectGroup}
						project={viewer.project}
						companyId={viewer.company.id}
					/>
					<ColumnContentWrapper height={columnHeight}>
						<ForecastQueryRenderer
							key={'query-render-projectWorkflowColumnQuery-' + column.node.id}
							query={ProjectWorkflowColumnContentQuery}
							customLoader={() => <CardLoaderWrapper />}
							variables={{
								pageSize: 30,
								filterColumnId: columnId.toString(),
								searchQuery: searchQuery,
								personId: viewer.actualPersonId,
							}}
							render={(relayProps, retry) => {
								return (
									<ProjectWorkflowColumnContent
										{...relayProps}
										projectIds={projectIds}
										statusColumnIds={statusColumnIds}
										taskMapRef={taskMapRef}
										taskSizeMapRef={taskSizeMapRef}
										imgSizeMapRef={imgSizeMapRef}
										retry={retry}
										intl={intl}
										key={column.node.id}
										statusColumnId={columnId}
										isConnectedParent={isConnectedParent}
										shouldShowDisabledOverlay={shouldShowDisabledOverlay}
										theEyeOptions={theEyeOptions}
										columnHeight={columnHeight}
										unselectTasks={unselectTasks}
									/>
								);
							}}
						/>
					</ColumnContentWrapper>
				</ColumnWrapper>
			</div>
		);

		const getCollapsed = connectDragPreview(
			<div id={'statusColumn' + column.node.id} style={{opacity: opacity}}>
				<CollapsedColumnWrapper
					data-cy="status-column"
					onClick={() => {
						setSettingsExpanded(false);
						setCollapsed(!collapsed);
					}}
				>
					<Droppable
						droppableId={columnId.toString()}
						isDropDisabled={!!shouldShowDisabledOverlay}
						dragAndDropGroup={DragAndDropGroup}
						placeholderStyle={{marginRight: 5, marginLeft: 5}}
						listHeader={
							<div className={'header-droppable'}>
								<CollapsedColumnHeader showDisabledOverlay={!!shouldShowDisabledOverlay}>
									{!!shouldShowDisabledOverlay ? (
										<CantDropCollapsed>
											<div className="text-container">
												<BlockedIcon active />

												<div className="not-allowed-overlay" />
											</div>
										</CantDropCollapsed>
									) : null}
									<HoverControl invert settingsExpanded={settingsExpanded}>
										<CollapseIcon size={CollapseIcon.SIZE.SMALL} color="#747474" />
									</HoverControl>
									<TitleAndCount collapsed>
										<p>
											<b>{column.node.name}</b>
											<span>{`(${tasksCount || 0})`}</span>
										</p>
										{isJiraColumn && (
											<RotatedDiv>
												<JiraIcon />
											</RotatedDiv>
										)}
										{column.node.adoState && <HeaderLogo collapsed src={adoLogo} />}
									</TitleAndCount>
								</CollapsedColumnHeader>
							</div>
						}
						listHeaderHeight={44}
						activeHeaderClass={'drop-active'}
						hideList={true}
						containerHeight={containerHeight}
						containerMinHeight={containerHeight}
						dynamicElemHeight={true}
						minElemHeight={78}
					>
						<div></div>
					</Droppable>
				</CollapsedColumnWrapper>
			</div>
		);

		return connectDropTarget(collapsed ? getCollapsed : getExpanded);
	}
);
export default flow(
	DragSource(ItemTypes.PROJECT_BOARD, columnSource, collectSource),
	DropTarget(ItemTypes.PROJECT_BOARD, columnTarget, collectTarget)
)(ProjectWorkflowColumn);

ProjectWorkflowColumn.defaultProps = {
	expanded: true,
};
