import React, { useRef, useState } from 'react';
import { Button, ButtonDropdownField, DropdownList, FlexColumn, FlexContainer, FlexRow, Icon, Label, Modal, Text, TextField, } from '@forecast-it/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import Util from '../../../forecast-app/shared/util/util';
import UpdateClientMutation from '../../../mutations/update_client_mutation';
import { getDefaultMediumClientLogo } from '../../../components/default_avatars';
import { BUTTON_COLOR, BUTTON_STYLE, isoCountries } from '../../../constants';
import { createFragmentContainer, graphql } from 'react-relay';
import Warning from '../../../components/warning';
import { MODAL_TYPE, showModal } from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import { clientLogoSrc } from '../../../directApi';
var LogoState;
(function (LogoState) {
    LogoState["NO_CHANGE"] = "NO_CHANGE";
    LogoState["UPDATE"] = "UPDATE";
    LogoState["DELETE"] = "DELETE";
})(LogoState || (LogoState = {}));
const EditClientDetailsModal = ({ clientId, viewer, closeModal }) => {
    var _a;
    const { formatMessage } = useIntl();
    const { client } = (viewer === null || viewer === void 0 ? void 0 : viewer.company) || {};
    const changeAvatarButtonRef = useRef(null);
    const [name, setName] = useState((client === null || client === void 0 ? void 0 : client.name) || '');
    const [street, setStreet] = useState((client === null || client === void 0 ? void 0 : client.street) || '');
    const [postcode, setPostcode] = useState((client === null || client === void 0 ? void 0 : client.zip) || '');
    const [city, setCity] = useState((client === null || client === void 0 ? void 0 : client.city) || '');
    const [vat, setVat] = useState((client === null || client === void 0 ? void 0 : client.vat) || '');
    const [country, setCountry] = useState(isoCountries.find((_country) => _country.value === (client === null || client === void 0 ? void 0 : client.country)) || null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [logoState, setLogoState] = useState(LogoState.NO_CHANGE);
    const [logo, setLogo] = useState((client === null || client === void 0 ? void 0 : client.logoId) ? clientLogoSrc(client === null || client === void 0 ? void 0 : client.logoId) : getDefaultMediumClientLogo(client === null || client === void 0 ? void 0 : client.logoDefaultId));
    const onClickChangeAvatarButton = () => {
        var _a;
        (_a = changeAvatarButtonRef.current) === null || _a === void 0 ? void 0 : _a.click();
    };
    const onChangeAvatar = (event) => {
        var _a;
        const file = (_a = event.target.files) === null || _a === void 0 ? void 0 : _a[0];
        if (!file)
            return;
        Util.confirmFilePreviewType(file, res => {
            if (res) {
                setLogoState(LogoState.UPDATE);
                setSelectedFile(file);
                setLogo(URL.createObjectURL(file));
            }
            else {
                closeModal();
                showModal({
                    type: MODAL_TYPE.GENERIC,
                    content: (React.createElement("div", { className: "default-warning-modal" },
                        React.createElement(Warning, { messageId: "warning_modal.profile_picture_wrong_format_title" }),
                        React.createElement("div", { className: "warning-part-2" }, formatMessage({ id: 'warning_modal.profile_picture_wrong_format_info' })))),
                    buttons: [
                        {
                            text: formatMessage({ id: 'common.cancel' }),
                            style: BUTTON_STYLE.FILLED,
                            color: BUTTON_COLOR.WHITE,
                        },
                    ],
                });
            }
        });
    };
    const onClickRemoveAvatar = () => {
        setLogoState(LogoState.DELETE);
        setSelectedFile(null);
        setLogo(getDefaultMediumClientLogo(client === null || client === void 0 ? void 0 : client.logoDefaultId));
    };
    const onClickConfirmChanges = () => {
        const mutationBody = Object.assign({ id: clientId, name,
            street, zip: postcode, city,
            vat, country: (country === null || country === void 0 ? void 0 : country.value) || '' }, (logoState === LogoState.DELETE ? { logoId: 'delete' } : {}));
        if (logoState === LogoState.UPDATE) {
            Util.CommitMutation(UpdateClientMutation, mutationBody, closeModal, true, {
                id: clientId,
                file: selectedFile,
            });
        }
        else {
            Util.CommitMutation(UpdateClientMutation, mutationBody, closeModal);
        }
    };
    const onClickSelectCountry = (value) => {
        setCountry(value);
    };
    const isRemoveAvatarButtonDisabled = (!(client === null || client === void 0 ? void 0 : client.logoId) && logoState !== LogoState.UPDATE) || logoState === LogoState.DELETE;
    return (React.createElement(Modal, { size: Modal.SIZE.SMALL, onCloseModal: closeModal },
        React.createElement(Modal.Title, null,
            React.createElement(FormattedMessage, { id: "common.edit_details" })),
        React.createElement(Modal.Content, null,
            React.createElement(FlexColumn, { gap: 'l' },
                React.createElement(FlexRow, { gap: 'l' },
                    React.createElement(FlexContainer, null,
                        React.createElement("img", { crossOrigin: "use-credentials", alt: "client-logo", src: logo, width: "96", height: "96" })),
                    React.createElement(FlexColumn, null,
                        React.createElement("input", { type: "file", style: { display: 'none' }, onChange: onChangeAvatar.bind(this), ref: changeAvatarButtonRef }),
                        React.createElement(Button, { emphasis: "medium", onClick: onClickChangeAvatarButton },
                            React.createElement(FormattedMessage, { id: "common.change_avatar" })),
                        React.createElement(Button, { emphasis: "medium", onClick: onClickRemoveAvatar, disabled: isRemoveAvatarButtonDisabled }, isRemoveAvatarButtonDisabled ? (React.createElement(FormattedMessage, { id: "common.remove" })) : (React.createElement(FormattedMessage, { id: "common.remove_avatar" }))))),
                React.createElement(TextField, { label: formatMessage({ id: 'settings.client-contact-name' }), value: name, onValueChange: setName, size: "large", width: "flex" }),
                React.createElement(TextField, { label: formatMessage({ id: 'settings.client-company-street' }), value: street, onValueChange: setStreet, size: "large", width: "flex" }),
                React.createElement(TextField, { label: formatMessage({ id: 'settings.client-company-postcode-zip' }), value: postcode, onValueChange: setPostcode, size: "large", width: "flex" }),
                React.createElement(TextField, { label: formatMessage({ id: 'settings.client-company-city' }), value: city, onValueChange: setCity, size: "large", width: "flex" }),
                React.createElement(TextField, { label: formatMessage({ id: 'settings.client-company-vat' }), value: vat, onValueChange: setVat, size: "large", width: "flex" }),
                React.createElement(FlexColumn, { gap: 's' },
                    React.createElement(Label, null, "Country"),
                    React.createElement(DropdownList, null,
                        React.createElement(DropdownList.Trigger, null,
                            React.createElement(ButtonDropdownField, null,
                                React.createElement(Text, null, (_a = country === null || country === void 0 ? void 0 : country.label) !== null && _a !== void 0 ? _a : ''))),
                        React.createElement(DropdownList.Content, { align: 'start' }, isoCountries.map(current => (React.createElement(DropdownList.Item, { key: current.value, onClick: () => onClickSelectCountry(current) },
                            React.createElement(FlexRow, null,
                                React.createElement(Text, null, current.label),
                                (current === null || current === void 0 ? void 0 : current.value) === (country === null || country === void 0 ? void 0 : country.value) && React.createElement(Icon, { icon: 'iconTick' })))))))))),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { onPress: onClickConfirmChanges },
                React.createElement(FormattedMessage, { id: 'common.confirm_changes' })),
            React.createElement(Modal.SecondaryFooterButton, { onPress: closeModal },
                React.createElement(FormattedMessage, { id: 'common.cancel' })))));
};
export const EditClientDetailsModalQuery = graphql `
	query EditClientDetailsModal_Query($clientId: String) {
		viewer {
			actualPersonId
			component(name: "edit_client_details_modal")
			...EditClientDetailsModal_viewer @arguments(clientId: $clientId)
		}
	}
`;
export default createFragmentContainer(EditClientDetailsModal, {
    viewer: graphql `
		fragment EditClientDetailsModal_viewer on Viewer @argumentDefinitions(clientId: {type: "String"}) {
			id
			availableFeatureFlags {
				key
			}
			company {
				id
				tier
				client(id: $clientId) {
					id
					name
					street
					zip
					city
					vat
					country
					notes
					logoId
					logoDefaultId
				}
			}
		}
	`,
});
