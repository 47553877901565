import React, {Component} from 'react';
import SuggestedPersonAndRole, {suggestedPersonAndRoleQuery} from './suggested_person_and_role';
import ForecastQueryRenderer from '../../../../../ForecastQueryRenderer';

class SuggestedPersonAndRoleRenderer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			forceFetch: true,
		};
	}

	componentDidMount() {
		this.setState({forceFetch: false});
	}

	render() {
		const {task, projectId} = this.props;
		if (!task) {
			return null;
		}
		const taskId = task.id || '';
		const taskName = task ? encodeURI(task.name) : '';

		return (
			<ForecastQueryRenderer
				key="query-render-suggestedPersonAndRole"
				showLoader={false}
				query={suggestedPersonAndRoleQuery}
				variables={{
					taskId: taskId || null,
					taskName: taskName || '',
					projectId: projectId || '',
					searchCriteria: this.props.searchCriteria,
				}}
				render={(relayProps, retry) => {
					return <SuggestedPersonAndRole {...this.props} {...relayProps} retry={retry} />;
				}}
			/>
		);
	}
}

SuggestedPersonAndRoleRenderer.propTypes = {};

export default SuggestedPersonAndRoleRenderer;
