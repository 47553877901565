import { calendarTokens } from '@forecast-it/design-system';
import styled from 'styled-components';
import { CalendarEventSize } from './CalendarEventUtil';
const tokens = calendarTokens.calendar;
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const EventName = styled.div `
	${props => (props.isLocked || props.isConverted ? tokens.font.event.regular : tokens.font.event.bold)};
	padding: 2px 0px 2px 0px;
	color: ${props => props.isLocked || props.isConverted ? tokens.color.text.content.auxiliary : tokens.color.text.content.main};
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: ${props => props.size === CalendarEventSize.SMALL ? '2' : props.size === CalendarEventSize.MEDIUM ? '3' : '5'};
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	max-height: ${props => props.size === CalendarEventSize.SMALL ? '44px' : props.size === CalendarEventSize.MEDIUM ? '64px' : '88px'};
	flex: 1 1 auto;
`;
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const TimeInfo = styled.div `
	${tokens.font.event.regular};
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	padding: 2px 0px 2px 0px;
	color: ${props => props.isLocked || props.isConverted ? tokens.color.text.content.auxiliary : tokens.color.text.content.main};
	flex: 0 0 auto;
`;
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const EventDuration = styled.div `
	color: ${tokens.color.text.content.auxiliary};
`;
export const CalendarEventStyled = styled.div `
	background-color: ${tokens.color.event.background.default};
	padding: ${tokens.size.event.padding};
	border-style: solid;
	border-width: 1px;
	border-radius: ${tokens.size.event.borderradius};
	border-color: ${tokens.color.border.decorative};
	cursor: pointer;
	&:hover {
		background-color: ${tokens.color.event.background.hover};
		box-shadow: ${tokens.elevation.shadow};
		z-index: 2;
	}
	margin-bottom: -1px;
`;
export const CalendarEventColorBar = styled.div `
	background-color: ${({ color }) => color};
	height: ${({ size }) => (size === CalendarEventSize.SMALL ? '44px' : size === CalendarEventSize.MEDIUM ? '64px' : '88px')};
	min-width: 4px;
	border-radius: 2px;
`;
