/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateReportModal_viewer",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isUsingProjectAllocation",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isUsingSchedulingPlanMode",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isUsingMixedAllocation",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Viewer"
};
node.hash = 'ac575a5b8863ff50e3f2776b24f3328c';
export default node;
