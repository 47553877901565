/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ReportsTable_company",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        }
    ],
    "type": "Company"
};
node.hash = 'a6dfa5576852c904ad5d3434ddbe3e9c';
export default node;
