import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import * as tracking from '../../../tracking';
import CustomScrollDiv from '../../shared/components/scroll-bars/custom_scroll_div';
import CreateVSTSVerificationKeyMutation from '../../../mutations/create_vsts_verification_key_mutation';
import DeleteVSTSAccountMutation from '../../../mutations/delete_vsts_account_mutation';
import UpdateCompanyMutation from '../../../mutations/update_company_mutation.modern';
import AutosizeInput from 'react-input-autosize';
import Util from '../../shared/util/util';
import {withRouter} from 'react-router-dom';
import {buildHeaderBar} from '../../shared/components/headers/header-bar/header_bar';
import Button from '../../shared/components/buttons/button/button';
import DropdownV2 from '../../shared/components/dropdowns/dropdown';
import {BUTTON_COLOR, BUTTON_STYLE, ELEMENT_TYPE} from '../../../constants';
import {MODAL_TYPE, showModal} from '../../shared/components/modals/generic_modal_conductor';
import Warning from '../../../components/warning';
import {AdoIcon, SIZE} from 'web-components';
import {createToast} from '../../shared/components/toasts/toast';
import {trackEvent, trackPage, unregisterPageInfo} from '../../../tracking/amplitude/TrackingV2';

class appVsts extends React.Component {
	constructor(props) {
		super(props);

		const company = props.viewer.company;

		this.state = {
			vstsAccounts: company.vstsAccounts ? company.vstsAccounts : [],
			accountInput: '',
		};

		this.superPropertyChecksum = trackPage('App Catalog - VSTS');
	}

	componentDidMount() {
		document.title = 'VSTS - Apps & Integrations - Forecast';
		// Segment
		tracking.trackPage('settings-app-catalog-vsts');

		// If Company doesn't have a vstsEstimateField yet, create it
		if (this.props.viewer.company.id && this.props.viewer.company.vstsEstimateField === '') {
			Util.CommitMutation(UpdateCompanyMutation, {
				id: this.props.viewer.company.id,
				vstsEstimateField: 'Microsoft.VSTS.Scheduling.OriginalEstimate',
			});
		}
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	sendToVSTS(vstsAccount) {
		if (vstsAccount && vstsAccount !== '') {
			vstsAccount = vstsAccount.replace('http://', '').replace('https://', '');
			if (vstsAccount.includes('.visualstudio.com')) {
				vstsAccount = vstsAccount.split('.visualstudio.com')[0];
			}

			if (!this.validAccountName(vstsAccount)) {
				this.setState({error: 'Invalid account name'});
				createToast({duration: 5000, message: 'Invalid account name'});
				return;
			}

			const onSuccess = ({createVSTSVerificationKey}) => {
				tracking.trackEvent('VSTS integration enabled');
				trackEvent('VSTS Integration', 'Enabled');
				let redirectUri = process.env.VSTS_REDIRECT_URI;
				let appId = process.env.VSTS_CLIENT_ID;

				let auth_uri = `https://app.vssps.visualstudio.com/oauth2/authorize
                ?client_id=${appId}
                &response_type=Assertion
                &prompt=login
                &state=${createVSTSVerificationKey.key}
                &scope=${'vso.project%20vso.serviceendpoint_manage%20vso.work_full'}
                &redirect_uri=${redirectUri}`;
				window.location.href = auth_uri;
			};

			Util.CommitMutation(
				CreateVSTSVerificationKeyMutation,
				{
					currentUrl: window.location.href,
					vstsAccount,
				},
				onSuccess
			);
		} else {
			createToast({duration: 5000, message: 'Account name is required'});
			this.setState({error: 'Account name is required'});
		}
	}

	openHelpCenter() {
		window.open('https://support.forecast.app/hc/en-us/articles/5400568475025');
	}

	setAccountName(e) {
		this.setState({accountInput: e.target.value});
	}

	// https://docs.microsoft.com/en-us/azure/devops/organizations/settings/naming-restrictions?view=azure-devops#user-account-names
	validAccountName(accountName) {
		if (!accountName || accountName.length < 1 || accountName.length > 256) {
			return false;
		}
		var pattern = new RegExp('["\\/\\[\\]:|<>+=;?*\\\\]|\\.$|,', 'g');
		return !pattern.test(accountName);
	}

	deleteAttachedAccount(account) {
		const {formatMessage} = this.props.intl;

		showModal({
			type: MODAL_TYPE.GENERIC,
			className: 'default-warning-modal',
			content: <Warning messageId="settings.app_catalog.ado_unlink_account" />,
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.continue'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: () => Util.CommitMutation(DeleteVSTSAccountMutation, {vstsAccount: account}),
				},
			],
		});
	}

	handleEstimateFieldChange(e) {
		Util.CommitMutation(UpdateCompanyMutation, {
			id: this.props.viewer.company.id,
			vstsEstimateField: e.value,
		});
	}

	getErrorMessage() {
		if (!this.props.history.location.search) return '';

		if (/error=\w*/g.exec(this.props.history.location.search)[0].substring(6) === 'wrong_account') {
			return (
				<FormattedMessage
					id="settings.app_catalog.wrong_account"
					values={{
						a: (...c) => (
							<a
								href="https://aka.ms/vssignout"
								target="_blank"
								rel="noopener noreferrer"
								onClick={() => this.props.history.push()}
							>
								{c}
							</a>
						),
					}}
				/>
			);
		}
	}

	getHeader() {
		const leftContent = [],
			rightContent = [];

		leftContent.push({type: ELEMENT_TYPE.BACK_BUTTON, onClick: this.props.history.goBack});

		return buildHeaderBar(leftContent, rightContent);
	}

	render() {
		const {formatMessage} = this.props.intl;

		const estimationFieldOptions = [
			{
				label: 'Original Estimate (Microsoft.VSTS.Scheduling.OriginalEstimate)',
				value: 'Microsoft.VSTS.Scheduling.OriginalEstimate',
			},
			{
				label: 'Story Points (Microsoft.VSTS.Scheduling.StoryPoints)',
				value: 'Microsoft.VSTS.Scheduling.StoryPoints',
			},
			{
				label: 'Effort (Microsoft.VSTS.Scheduling.Effort)',
				value: 'Microsoft.VSTS.Scheduling.Effort',
			},
		];

		return (
			<div className="section-content settings-app settings-app-page">
				{this.props.children}
				{this.getHeader()}
				<CustomScrollDiv>
					<div className="section-body">
						<div className="inner">
							<fieldset className="general">
								<div className="header-wrapper">
									<AdoIcon size={SIZE.EXTRA_BIG} />
									<div className="title-description-wrapper">
										<div className="description">
											{formatMessage({id: 'settings.app_catalog.description-visual-studio-team'})}
										</div>
									</div>
								</div>
								<div className="ado-field">
									<DropdownV2
										label={formatMessage({id: 'ado.ado_estimate_field'})}
										value={
											this.props.viewer.company.vstsEstimateField ||
											'Microsoft.VSTS.Scheduling.OriginalEstimate'
										}
										onChange={e => this.handleEstimateFieldChange(e)}
										options={estimationFieldOptions}
									/>
								</div>
								<table>
									<thead>
										<tr>
											<td>{formatMessage({id: 'settings.app_catalog.ado_linked_accounts'})}</td>
										</tr>
									</thead>
									<tbody>
										{this.props.viewer.company.vstsAccount.map(s => (
											<tr>
												<td>{s}</td>
												<td>
													<Button
														className="ado-button"
														onClick={() => this.sendToVSTS(s)}
														buttonStyle={BUTTON_STYLE.FILLED}
														colorTheme={BUTTON_COLOR.MEDIUMGREY}
														text={formatMessage({id: 'settings.app_catalog.gitlab.reauthorize'})}
													/>
												</td>
												<td>
													<Button
														className="ado-button"
														onClick={() => this.deleteAttachedAccount(s)}
														buttonStyle={BUTTON_STYLE.FILLED}
														colorTheme={BUTTON_COLOR.RED}
														text={formatMessage({id: 'common.unlink'})}
													/>
												</td>
											</tr>
										))}
									</tbody>
									<tfoot>
										<tr>
											<td>
												https://dev.azure.com/
												<AutosizeInput
													id="vsts_account_domain"
													type="text"
													placeholder={'Account'}
													value={this.state.accountInput}
													onChange={e => {
														this.setAccountName(e);
													}}
												/>
											</td>
											<td>
												<Button
													className="ado-button"
													onClick={() => this.sendToVSTS(this.state.accountInput)}
													buttonStyle={BUTTON_STYLE.FILLED}
													colorTheme={BUTTON_COLOR.GREEN}
													text={formatMessage({id: 'common.link'})}
												/>
											</td>
										</tr>
									</tfoot>
								</table>
								<span className="ado-error">{this.getErrorMessage()}</span>
							</fieldset>
						</div>
					</div>
				</CustomScrollDiv>
			</div>
		);
	}
}

const appVstsQuery = graphql`
	query appVsts_Query {
		viewer {
			actualPersonId
			component(name: "app_vsts")
			...appVsts_viewer
		}
	}
`;

export {appVstsQuery};

export default createFragmentContainer(injectIntl(withRouter(appVsts)), {
	viewer: graphql`
		fragment appVsts_viewer on Viewer {
			id
			company {
				id
				vstsEnabled
				vstsAccount
				vstsEstimateField
			}
		}
	`,
});
