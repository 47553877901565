import React from 'react';

const LockIcon = props => (
	<svg xmlns="http://www.w3.org/2000/svg" width="8" height="11" viewBox="0 0 8 11">
		<path
			fill={props.fill ? props.fill : '#FFF'}
			fillRule="evenodd"
			d="M5.714 5.714H2.286V2.857c0-.945.769-1.714 1.714-1.714s1.714.769 1.714 1.714v2.857zm1.143 0V2.857A2.86 2.86 0 0 0 4 0a2.86 2.86 0 0 0-2.857 2.857v2.857C.512 5.714 0 6.226 0 6.857V9.43c0 .63.512 1.142 1.143 1.142h5.714C7.487 10.571 8 10.06 8 9.43V6.857c0-.63-.513-1.143-1.143-1.143z"
		/>
	</svg>
);

export default LockIcon;
