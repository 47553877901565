import styled from 'styled-components';
import {CSS_CONSTANTS} from '../../../../css_variables';

export const BottomSectionWrapper = styled.div`
	flex-grow: 1;
	max-width: 2089px;
	padding-top: 0;
	border: solid ${CSS_CONSTANTS.app_border_color};
	border-width: 0 1px 1px 1px;
	.header-bar {
		min-height: 0;
	}
	> div {
		padding: 0 24px;
	}

	.header-bar-center {
		flex-grow: 1;
	}
	@media screen and (min-width: 2137px) {
		width: 2089px;
	}
`;

export const BottomSectionWrapperOld = styled(BottomSectionWrapper)`
	border-width: 1px;
`;
