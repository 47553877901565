/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type MyWorkPageV2_viewer$ref = any;
export type MyWorkPageV2RefetchQueryVariables = {|
  personId?: ?string
|};
export type MyWorkPageV2RefetchQueryResponse = {|
  +viewer: ?{|
    +component: ?string,
    +$fragmentRefs: MyWorkPageV2_viewer$ref,
  |}
|};
export type MyWorkPageV2RefetchQuery = {|
  variables: MyWorkPageV2RefetchQueryVariables,
  response: MyWorkPageV2RefetchQueryResponse,
|};
*/


/*
query MyWorkPageV2RefetchQuery {
  viewer {
    component(name: "my_work")
    ...MyWorkPageV2_viewer_3WrWRM
    id
  }
}

fragment MyWorkPageV2_viewer_3WrWRM on Viewer {
  id
  actualPersonId
  favouredTasksCount
  email
  availableFeatureFlags {
    key
    id
  }
  company {
    harvestEnabled
    id
  }
  filters(first: 1000000, filterSection: MY_WORK) {
    edges {
      node {
        id
        name
        section
        value
        updatedAt
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "personId",
    "type": "ID"
  }
],
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "my_work"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"my_work\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "filterSection",
    "value": "MY_WORK"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000000
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MyWorkPageV2RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "args": [
              {
                "kind": "Variable",
                "name": "personId",
                "variableName": "personId"
              }
            ],
            "kind": "FragmentSpread",
            "name": "MyWorkPageV2_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MyWorkPageV2RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "actualPersonId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "favouredTasksCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "FeatureFlag",
            "kind": "LinkedField",
            "name": "availableFeatureFlags",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "harvestEnabled",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "FilterTypeConnection",
            "kind": "LinkedField",
            "name": "filters",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FilterTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Filter",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "section",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "updatedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "filters(filterSection:\"MY_WORK\",first:1000000)"
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "filters": [],
            "handle": "connection",
            "key": "Viewer_filters",
            "kind": "LinkedHandle",
            "name": "filters"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "MyWorkPageV2RefetchQuery",
    "operationKind": "query",
    "text": "query MyWorkPageV2RefetchQuery {\n  viewer {\n    component(name: \"my_work\")\n    ...MyWorkPageV2_viewer_3WrWRM\n    id\n  }\n}\n\nfragment MyWorkPageV2_viewer_3WrWRM on Viewer {\n  id\n  actualPersonId\n  favouredTasksCount\n  email\n  availableFeatureFlags {\n    key\n    id\n  }\n  company {\n    harvestEnabled\n    id\n  }\n  filters(first: 1000000, filterSection: MY_WORK) {\n    edges {\n      node {\n        id\n        name\n        section\n        value\n        updatedAt\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '53412a003319ef1e35c5d8b62d32d920';

module.exports = node;
