import React from 'react';
import {createFragmentContainer, graphql} from 'react-relay';

import {DeprecatedProjectIndicatorNoFragment} from '../DeprecatedProjectIndicator';

/**
 * JS Wrapper to allow using fragment from JS parents since fragments are not compatible across TS/JS.
 */
const DeprecatedProjectIndicatorJS = props => {
	return <DeprecatedProjectIndicatorNoFragment {...props} />;
};

export default createFragmentContainer(DeprecatedProjectIndicatorJS, {
	project: graphql`
		fragment DeprecatedProjectIndicatorJS_project on ProjectType {
			name
			companyProjectId
			customProjectId
			projectColor
		}
	`,
});
