import {cloneDeep} from 'lodash';
import Util from '../util/util';
import {ELEMENT_TYPE} from '../../../constants';
import {useEffect, useState} from 'react';
import {optionTraverser} from '../../../the_eye_util';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';

/*
 * Parameters:
 * 	   optionDefinitionOrKeys: a list of eye option definitions or a simple list of strings that will be converted to eye options
 *     translationIdPrefix: if passing a list of keys, they will get a translation key based on this prefix and key
 * 		 localStorageKey to use for persisting
 *     setEyeOptionMapCallback - a callback that will receive the eye option state as a map of booleans where the keys are "optionKey.nestedOptionKey"
 * returns [optionHeaderElement, isEyeOptionChecked]
 * optionHeaderElement can be inserted in a header (rightContent / leftContent)
 * isEyeOptionChecked is called with a key and returns the checked state
 * */
export const useEyeOptions = (optionDefinitionOrKeys, localStorageKey, translationIdPrefix, setEyeOptionMapCallback) => {
	const defaultOptions = allKeys => {
		return allKeys.map(key => {
			return {name: key, checked: true, translationId: translationIdPrefix + key};
		});
	};

	const getInitialOptions = optionDefinitionOrKeys => {
		const isOptionDefinition = optionDefinitionOrKeys && optionDefinitionOrKeys.length && optionDefinitionOrKeys[0].name;
		const options = isOptionDefinition ? optionDefinitionOrKeys : cloneDeep(defaultOptions(optionDefinitionOrKeys));
		const locallySavedOptionsString = localStorage.getItem(localStorageKey);
		if (locallySavedOptionsString) {
			const savedOptionsValues = JSON.parse(locallySavedOptionsString);
			if (typeof savedOptionsValues === 'object' && !Array.isArray(savedOptionsValues)) {
				optionTraverser(options, (option, path) => {
					if (savedOptionsValues[path] !== undefined) {
						option.checked = savedOptionsValues[path];
					}
				});
			}
		}
		return options;
	};

	const [theEyeOptions, setTheEyeOptions] = useState(getInitialOptions(optionDefinitionOrKeys));

	useEffect(() => {
		trackEvent('Eye Options', 'Loaded', {theEyeOptions});
	}, []);

	if (setEyeOptionMapCallback) {
		useEffect(() => {
			const savedOptionValues = {};
			optionTraverser(theEyeOptions, (option, path) => {
				savedOptionValues[path] = option.checked;
			});
			setEyeOptionMapCallback(savedOptionValues);
		}, [theEyeOptions]);
	}

	const optionsChangedList = (prevOptions, newOptions) => {
		const optionChanges = [];
		for (let key in prevOptions) {
			if (newOptions.hasOwnProperty(key) && prevOptions[key] !== newOptions[key]) {
				optionChanges.push({
					option: key,
					value: newOptions[key] ? 'Selected' : 'Deselected',
				});
			}
		}
		return optionChanges;
	};

	const handleChangedOptions = changedOptions => {
		const savedOptionValues = {};
		optionTraverser(changedOptions, (option, path) => {
			savedOptionValues[path] = option.checked;
		});

		const prevSavedOptionValues = JSON.parse(Util.localStorageGetItem(localStorageKey));
		Util.localStorageSetItem(localStorageKey, JSON.stringify(savedOptionValues));
		setTheEyeOptions(changedOptions);

		const optionChanges = optionsChangedList(prevSavedOptionValues, savedOptionValues);
		optionChanges.forEach(change =>
			trackEvent('Eye Options', change.value, {
				option: change.option,
			})
		);

		return changedOptions;
	};

	const handleEyeOptionsSelect = (_, __, ___, newOptions) => {
		handleChangedOptions(newOptions);
	};

	const isEyeOptionChecked = key => {
		const option = theEyeOptions.find(option => option.name === key);
		return option ? option.checked : false;
	};

	const optionHeaderElement = {
		type: ELEMENT_TYPE.THE_EYE,
		options: theEyeOptions,
		onSelect: handleEyeOptionsSelect,
		openRight: false,
		userpilot: 'eye-selector',
		cy: 'eye-selector',
	};
	return [optionHeaderElement, isEyeOptionChecked, theEyeOptions, handleChangedOptions];
};
