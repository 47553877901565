import styled from 'styled-components';

export const StyledTime = styled.div`
	display: block;
	overflow: ${props => (props.showOverflow ? 'visible' : 'hidden')};
	text-overflow: ellipsis;
	z-index: 2;
	flex-shrink: 1;
	justify-content: flex-end;
	p {
		display: inline;
		text-align: right;
		margin: 0;
		font-weight: 450;
		overflow: visible;
	}
`;

export const StyledCheckmark = styled.span`
	display: inline;
	margin-left: 3px;
	margin-right: 3px;
`;
