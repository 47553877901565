import React from 'react';
import { ValueCell } from '../../../../../../components/initial-plan/ValueCell';
import { HIDDEN_FEATURES } from '../../../../../../constants';
import ForecastTooltip from '../../../../../shared/components/tooltips/ForecastTooltip';
import ProjectUtil from '../../../../../shared/util/project_util';
import Util from '../../../../../shared/util/util';
import { Data, HorizontalBar, Title, ValueContainer } from '../ProjectScopingTotals_styled';
import { RemainingValueTooltip, VarianceValueTooltip } from '../ProjectScopingTotalsTooltips';
import { AvailabilityTile } from './AvailabilityTile';
import { Tile, TileRow } from './Tile_styled';
import { TileVariant } from './TotalsTypes';
const RemainingRow = ({ totalsData, intl }) => {
    return (React.createElement(TileRow, null,
        React.createElement(Title, null, totalsData.isEstimatedInHours
            ? intl.formatMessage({ id: 'project_scoping.forecast_hours_remaining' })
            : 'Forecast points remaining'),
        React.createElement(Data, { small: true },
            React.createElement(ForecastTooltip, { placement: 'left', content: RemainingValueTooltip() },
                React.createElement(ValueCell, { noJustify: true, type: totalsData.isEstimatedInHours ? ValueCell.VALUE_TYPE.TIME : ValueCell.VALUE_TYPE.POINTS, value: totalsData.remaining })))));
};
const VarianceWithEstimateRow = ({ totalsData, projectData, intl, }) => {
    if (!ProjectUtil.projectUsesManualProgress(projectData) && !Util.isFeatureHidden(HIDDEN_FEATURES.TIME_REGISTRATIONS)) {
        return (React.createElement(TileRow, null,
            React.createElement(Title, null, intl.formatMessage({ id: 'project_scoping.variance_estimate' })),
            React.createElement(Data, { small: true },
                React.createElement(ForecastTooltip, { placement: 'left', content: VarianceValueTooltip() },
                    React.createElement(ValueContainer, null,
                        React.createElement(ValueCell, { noJustify: true, type: ValueCell.VALUE_TYPE.TIME, value: totalsData.difference }))))));
    }
    return null;
};
const BaselineEstimateRow = ({ totalsData, intl }) => {
    return (React.createElement(TileRow, null,
        React.createElement(Title, null, intl.formatMessage({ id: 'project_scoping.baseline_estimate' })),
        React.createElement(Data, { small: true },
            React.createElement(ValueCell, { noJustify: true, type: ValueCell.VALUE_TYPE.TIME, value: totalsData.baselineTargetHours }))));
};
const BaselineRevenueRow = ({ totalsData, showRevenue, settings, intl, }) => {
    if (showRevenue) {
        return (React.createElement(TileRow, null,
            React.createElement(Title, null, intl.formatMessage({ id: 'baseline.baseline_billable_time' })),
            React.createElement(Data, { small: true },
                React.createElement(ValueCell, { noJustify: true, type: ValueCell.VALUE_TYPE.PRICE, value: totalsData.baselineTargetPrice, currencySymbol: settings.currencySymbol }))));
    }
    return null;
};
export const RemainingAndBaselineTile = ({ totalsData, projectData, showRevenue, showBaseline, settings, availabilityTile, intl, }) => {
    const showAvailabilityTile = availabilityTile === TileVariant.RemainingAndBaseline;
    return (React.createElement(Tile, null,
        React.createElement(RemainingRow, { totalsData: totalsData, intl: intl }),
        React.createElement(VarianceWithEstimateRow, { totalsData: totalsData, projectData: projectData, intl: intl }),
        showBaseline ? (React.createElement(React.Fragment, null,
            React.createElement(HorizontalBar, null),
            React.createElement(BaselineEstimateRow, { totalsData: totalsData, intl: intl }),
            React.createElement(BaselineRevenueRow, { totalsData: totalsData, showRevenue: showRevenue, settings: settings, intl: intl }))) : showAvailabilityTile ? (React.createElement(React.Fragment, null,
            React.createElement(HorizontalBar, null),
            React.createElement(AvailabilityTile, { totalsData: totalsData, intl: intl }))) : null));
};
