import { isTimeRegistrationTimeInvalidWithReason } from './TimeRegistrationTimeFilter';
import { isExceedingEstimateAllowed } from '../../cache/TimeRegistrationSettingsUtil';
import Util from '../../util';
export function getRemainingTimeInfoMessage(remainingMinutes, estimateForecastMinutes, intl) {
    if (isExceedingEstimateAllowed() || estimateForecastMinutes === null || isNaN(estimateForecastMinutes)) {
        return null;
    }
    const remainingTime = remainingMinutes !== null ? Util.convertMinutesToFullHour(remainingMinutes, intl) : null;
    const estimatedTime = Util.convertMinutesToFullHour(estimateForecastMinutes, intl);
    return remainingTime && estimatedTime ? `${remainingTime} remaining of ${estimatedTime} estimate` : null;
}
export function useTaskEstimateValidation(estimateForecastMinutes, totalMinutesRegistered, initialMinutes, intl) {
    if (isExceedingEstimateAllowed() ||
        estimateForecastMinutes === null ||
        estimateForecastMinutes === undefined ||
        isNaN(estimateForecastMinutes)) {
        return {
            remainingInfoMessage: null,
            validateTimeInputValue: () => {
                return null;
            },
        };
    }
    const remainingMinutes = estimateForecastMinutes - (totalMinutesRegistered || 0) + (initialMinutes || 0);
    function validateTimeInputValue(timeInputValue) {
        return isTimeRegistrationTimeInvalidWithReason({
            inputMinutes: timeInputValue || 0,
            remainingMinutes: remainingMinutes === undefined ? null : remainingMinutes,
            subtractMinutes: 0,
        });
    }
    const remainingInfoMessage = getRemainingTimeInfoMessage(remainingMinutes, estimateForecastMinutes, intl);
    return { remainingInfoMessage, validateTimeInputValue };
}
export function getTotalMinutesRegisteredFromTimeRegistrationEdges(edges) {
    if (isExceedingEstimateAllowed() || !edges) {
        return null;
    }
    return edges.reduce((sum, timeRegistration) => {
        return sum + timeRegistration.node.minutesRegistered;
    }, 0);
}
