import React from 'react';
import Styled from 'styled-components/macro';
import Util from '../../../util/util';
import {CSS_CONSTANTS} from '../../../../../css_variables';
import {CrossIcon} from 'web-components';

const BreadcrumbsSectionWrapper = Styled.div`
	font-size: 11px;
	height:32px;
	display:flex;
	flex-direction:row;
	align-items:center;
	border-bottom:solid 1px ${() => CSS_CONSTANTS.app_border_color};
	padding: 0 8px 0 24px;
`;

const NamesSection = Styled.div`
	display:flex;
	flex-direction:row;
	flex:1;
`;

const SlashWrapper = Styled.div`
	margin:0 8px;
	color:#A5A5AE;
`;

const NameWrapper = Styled.div`
	color:#4C4C58;
	max-width:270px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`;

const ParentNameWrapper = Styled(NameWrapper)`
	color:#A5A5AE;
	cursor:pointer;
	&:hover{
		color:#6E0FEB;
	}
`;

const NoParentSectionWrapper = Styled.div`
	height: 24px;
	padding: 4px 8px 0 0;
	display:flex;
	justify-content:flex-end;
`;

const CrossWrapper = Styled.div`
	//this is to make the cross icon goes outside of the section 
`;

const BreadcrumbsSection = ({parentsList, taskName, history, closeModal}) => {
	if (parentsList.length === 0) {
		return (
			<NoParentSectionWrapper>
				<CrossWrapper>
					<CrossIcon cy="close-modal-button" size={CrossIcon.SIZE.STANDARD} color={'#4C4C58'} onClick={closeModal} />
				</CrossWrapper>
			</NoParentSectionWrapper>
		);
	}

	const parentsBreacrumbs = parentsList.map(parent => {
		return (
			<>
				<ParentNameWrapper onClick={() => Util.showTaskModal(parent.companyTaskId, history)}>
					{parent.name}
				</ParentNameWrapper>
				<SlashWrapper>/</SlashWrapper>
			</>
		);
	});

	return (
		<BreadcrumbsSectionWrapper>
			<NamesSection>
				{parentsBreacrumbs}
				<NameWrapper>{taskName}</NameWrapper>
			</NamesSection>
			{<CrossIcon cy="close-modal-button" size={CrossIcon.SIZE.STANDARD} color={'#4C4C58'} onClick={closeModal} />}
		</BreadcrumbsSectionWrapper>
	);
};

export default BreadcrumbsSection;
