import React from 'react';
import placeholderAvatarBackground from '../../../../images/components/scheduling/placeholder-avatar-background.svg';
import styled from 'styled-components';
import {CSS_CONSTANTS} from '../../../../css_variables';

const Avatar = styled.div`
	background: url(${placeholderAvatarBackground}) center no-repeat;
	justify-content: center;
	align-items: center;
	display: flex;
	width: 28px;
	height: 32px;
`;

const Initials = styled.div`
	color: ${CSS_CONSTANTS.brand500};
	font-weight: 700;
	text-transform: uppercase;
	font-size: ${props => (props.small ? '11px' : '14px')};
`;

const PlaceholderInitials = ({initials}) => {
	return (
		<Avatar>
			<Initials>{initials}</Initials>
		</Avatar>
	);
};

export default PlaceholderInitials;
