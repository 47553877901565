import {timeEntryLockedInternalTime} from '../../TimeApprovalLockLogic';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../../../../constants';
import {canLoggedInPersonManageProgram, hasTopDownProgramBudgetFeature} from '../../../../../shared/util/ProgramFinancialLogic';
import {MODAL_TYPE, showModal} from '../../../../../shared/components/modals/generic_modal_conductor';
import {ACTION} from '../../../../../shared/components/modals/program-management/ProgramBudgetErrorMessage';
import {trackEvent} from '../../../../../../tracking/amplitude/TrackingV2';
import Util from '../../../../../shared/util/util';
import {hasFeatureFlag} from '../../../../../shared/util/FeatureUtil';
import Warning from '../../../../../../components/warning';
import React from 'react';
import {
	hasLinkedAllocation,
	lineItemIsTimeOff,
	timeEntryContainsHarvestTask,
	timeEntryInLockedPeriod,
	timeEntryInvoiced,
	timeEntryLockedTimeOff,
	timeEntryNoAccess,
	timeEntryProjectDone,
	timeEntryProjectHalted,
} from './InformationUtil';

export function isContextVisible(lineItem, isWeekView, daysRegisteredForEntity, usingInternalTimeApproval, useTimeOffApproval) {
	if (lineItem.id === -1) return false;
	let isVisible = false;

	const isTimeReg = lineItem.type != null;
	if (isWeekView) {
		const dayRegList = daysRegisteredForEntity ? daysRegisteredForEntity : [[]];
		if (dayRegList.some(reg => reg.length > 0)) isVisible = true;
		const inLockedPeriodWeek =
			isTimeReg && dayRegList.some(dayRegs => timeEntryInLockedPeriod(lineItem, dayRegs, isWeekView));
		const lockedInternalTime =
			isTimeReg && dayRegList.some(dayRegs => timeEntryLockedInternalTime(lineItem, dayRegs, isWeekView));
		const lockedTimeOff =
			isTimeReg && dayRegList.some(dayRegs => timeEntryLockedTimeOff(lineItem, dayRegs, isWeekView, useTimeOffApproval));
		const invoicedWeek = isTimeReg && dayRegList.some(dayRegs => timeEntryInvoiced(lineItem, dayRegs, isWeekView));
		let hasAllocationWeek =
			lineItem.timeReg &&
			lineItem.timeReg.node.allocationId &&
			!(lineItem.timeReg.node.idleTime && lineItem.timeReg.node.idleTime.isInternalTime);
		if (lineItemIsTimeOff(lineItem)) {
			hasAllocationWeek = dayRegList.some(dayRegs => hasLinkedAllocation(dayRegs));
		}
		const hasMultipleHarvestTasks = isTimeReg && dayRegList.some(dayRegs => timeEntryContainsHarvestTask(dayRegs));

		isVisible =
			isVisible &&
			!hasAllocationWeek &&
			!hasMultipleHarvestTasks &&
			!inLockedPeriodWeek &&
			!invoicedWeek &&
			!lockedInternalTime &&
			!lockedTimeOff;
	} else {
		if (lineItem.timeRegistrationsWithNotes && lineItem.timeRegistrationsWithNotes.length > 0) isVisible = true;
		if (lineItem.minutesRegistered !== undefined && lineItem.minutesRegistered !== null) isVisible = true;
		const inLockedPeriod = isTimeReg && timeEntryInLockedPeriod(lineItem, [], isWeekView);
		const lockedInternalTime =
			isTimeReg && timeEntryLockedInternalTime(lineItem, [], isWeekView, usingInternalTimeApproval);
		const lockedTimeOff = isTimeReg && timeEntryLockedTimeOff(lineItem, [], isWeekView, useTimeOffApproval);
		const invoiced = isTimeReg && timeEntryInvoiced(lineItem, [], isWeekView);
		const hasAllocation = lineItem.allocationId && !(lineItem.idleTime && lineItem.idleTime.isInternalTime);
		isVisible = isVisible && !inLockedPeriod && !invoiced && !hasAllocation && !lockedInternalTime && !lockedTimeOff;
	}

	// general visibility checks for both week and day rows
	const noAccess = isTimeReg && timeEntryNoAccess(lineItem);
	const projectDone = isTimeReg && timeEntryProjectDone(lineItem);
	const projectHalted = isTimeReg && timeEntryProjectHalted(lineItem);

	isVisible = isVisible && !noAccess && !projectDone && !projectHalted;
	return isVisible;
}

// Can be deleted when "timesheet_remaster" is rolled out"
export function getActionMenuOptions(
	isWeekView,
	formatMessage,
	showDeleteConfirmationModal,
	dayRegsForEntitiy,
	lineItem,
	editTimeRegistration
) {
	let options;
	if (isWeekView) {
		options = [
			{
				name: 'delete',
				text: formatMessage({id: 'common.delete_row'}),
				onClick: showDeleteConfirmationModal.bind(this, dayRegsForEntitiy, isWeekView),
			},
		];
	} else {
		options = [
			{
				name: 'edit',
				text: formatMessage({id: 'time_registration.edit.header'}),
				onClick: editTimeRegistration.bind(this, lineItem),
			},
			{
				name: 'delete',
				text: formatMessage({id: 'common.delete'}),
				onClick: showDeleteConfirmationModal.bind(this, lineItem),
			},
		];
	}
	return options;
}

export function showProgramRevenueLockedMessage(viewer, program, companyProjectId) {
	const loggedInPersonId = viewer.actualPersonId;
	const canManageProgram = canLoggedInPersonManageProgram(program?.members, loggedInPersonId);

	showModal({
		type: MODAL_TYPE.PROGRAM_BUDGET_ERROR_MESSAGE,
		action: ACTION.EDIT_TIME_ENTRY,
		programName: program?.name,
		canManageProgram,
		programPrefix: program?.prefix,
		companyProjectId: companyProjectId,
		actualPersonId: loggedInPersonId,
	});

	trackEvent('Program Budget Error Message Modal', 'Shown');
}

export function editTimeRegistration(
	lineItem,
	overBudgetProgramsByCompanyProjectId,
	showProgramRevenueLockedMessage,
	selectedPerson
) {
	const lineItemProject = lineItem.task ? lineItem.task.project : lineItem.project;
	const overBudgetProgram = overBudgetProgramsByCompanyProjectId.find(
		project => project.companyProjectId === lineItemProject?.companyProjectId
	);

	if (hasTopDownProgramBudgetFeature() && overBudgetProgram && (!lineItem?.task || lineItem.task?.billable === true)) {
		showProgramRevenueLockedMessage(overBudgetProgram.programInfo, lineItemProject.companyProjectId);
		return;
	}

	const passedDate =
		lineItem.year && lineItem.month && lineItem.day
			? Util.CreateNonUtcMomentDate(lineItem.year, lineItem.month, lineItem.day)
			: null;

	if (hasFeatureFlag('new_time_registration_modal')) {
		showModal({
			type: MODAL_TYPE.UPDATE_TIME_REGISTRATION,
			personId: selectedPerson.id,
			timeRegistrationId: lineItem.id,
		});
	} else {
		showModal({
			type: MODAL_TYPE.TIMER_V3,
			updateTimeRegId: lineItem.id,
			isPreviousTimerReminder: false,
			timerProject: lineItemProject,
			timerTask: lineItem.task,
			timerIdleTime: lineItem.idleTime,
			passedNotes: lineItem.notes,
			passedTime: lineItem.minutesRegistered,
			passedBillableTime: lineItem.billableMinutesRegistered,
			passedDate: passedDate,
			timeRegRole: lineItem.role,
			useTimer: false,
			harvestTaskSelected: lineItem.harvestTaskIdInt ? btoa(`HarvestTaskType:${lineItem.harvestTaskIdInt}`) : null,
			selectedPerson: selectedPerson,
			personId: selectedPerson.id,
		});
	}
}

export function showDeleteConfirmationModal(
	formatMessage,
	isWeek,
	deleteTimeRegistration,
	lineItem,
	deleteTimeRegistrationsForWeek
) {
	showModal({
		type: MODAL_TYPE.GENERIC,
		content: (
			<div className="default-warning-modal">
				<Warning messageId="common.delete-confirmation-title" />
				<div className="warning-part-2">
					{formatMessage({
						id: isWeek
							? 'common.warning.week_delete_this_action_can_not_be_undone'
							: 'common.warning.this_action_can_not_be_undone',
					})}
				</div>
			</div>
		),
		buttons: [
			{
				text: formatMessage({id: 'common.cancel'}),
				style: BUTTON_STYLE.FILLED,
				color: BUTTON_COLOR.WHITE,
			},
			{
				text: formatMessage({id: 'common.delete'}),
				style: BUTTON_STYLE.FILLED,
				color: BUTTON_COLOR.RED,
				callback: !isWeek
					? deleteTimeRegistration.bind(this, lineItem)
					: deleteTimeRegistrationsForWeek.bind(this, lineItem),
				cy: 'new-ui-time-custom-button-container',
			},
		],
	});
}
