import {useEffect, useState} from 'react';
import {graphql} from 'react-relay';
import {fetchQuery} from 'relay-runtime';
import environment from '../../../../RelayEnvironment';
import Util from '../../../shared/util/util';
import {cloneDeep} from 'lodash';

export const useRecentProjects = (fetch = false) => {
	const localStorageName = 'recent_projects';

	const maxRecent = 10;

	const sortByDate = (a, b) => new Date(b.date) - new Date(a.date);

	const readFromLocalStorage = () => {
		return (localStorage.getItem(localStorageName) ? JSON.parse(localStorage.getItem(localStorageName)) : []).sort(
			sortByDate
		);
	};

	const [recentProjectData, setRecentProjectData] = useState(readFromLocalStorage);
	const [recentProjects, setRecentProjects] = useState([]);

	const _storeToLocalStorage = (data = recentProjectData) => {
		localStorage.setItem(localStorageName, JSON.stringify(data));
	};

	const storeProjectData = data => {
		_storeToLocalStorage(data);
		setRecentProjectData(data);
		setTimeout(() => window.dispatchEvent(new Event('recent-project-storage')), 300);
	};

	const readAndSetData = () => {
		const data = readFromLocalStorage();
		setRecentProjectData(readFromLocalStorage(data));
	};

	useEffect(() => {
		window.addEventListener('recent-project-storage', readAndSetData);
		return () => window.removeEventListener('recent-project-storage', readAndSetData);
	}, []);

	const findProjectById = projectId => {
		return recentProjectData.find(pd => pd.id === projectId);
	};

	const projectDeleted = projectId => {
		const newArray = recentProjectData.filter(pd => pd.id !== projectId);
		storeProjectData(newArray);
	};

	const projectById = (projects, id) => projects.find(p => p.serviceCompanyId === Util.getIdFromBase64String(id));

	const sortAndSetProjects = projects => {
		const sortedRecentProjects = recentProjectData
			.slice()
			.sort(sortByDate)
			.filter(pd => projectById(projects, pd.id))
			.map(pd => ({...projectById(projects, pd.id), date: pd.date}))
			.filter(p => p);

		setRecentProjects(sortedRecentProjects);

		return sortedRecentProjects;
	};

	const useRecentProjectsQuery = graphql`
		query useRecentProjectsQuery($projectIds: [ID]) {
			viewer {
				id
				component(name: "useRecentProjectsQuery")
				backendId
				actualPersonId
				psProjects(first: 10, projectIds: $projectIds) @connection(key: "Recent_psProjects", filters: []) {
					edges {
						node {
							id
							serviceCompanyId
							name
							prefix
							color
							legacyProjectAsSingleProject {
								id
								customProjectId
								...ProjectIndicatorJS_project
							}
						}
					}
				}
			}
		}
	`;

	useEffect(() => {
		if (fetch) {
			try {
				const projectDataIds = recentProjectData.map(p => Util.getIdFromBase64String(p.id));
				const projectIds = recentProjects.map(p => p.serviceCompanyId);
				const idChanges =
					projectDataIds.length !== projectIds.length ||
					!projectDataIds.filter(element => projectIds.includes(element));

				if (idChanges) {
					if (recentProjectData.length > 0) {
						fetchQuery(environment.getInstance(), useRecentProjectsQuery, {
							projectIds: recentProjectData.map(p => p.id),
						})
							.then(data => {
								const projects = data.viewer.psProjects.edges.map(project => project.node);
								const sortedProjects = sortAndSetProjects(projects);

								//Clean up not found projects
								if (projects.length !== recentProjectData.length) {
									const newArray = recentProjectData.filter(pd => projectById(sortedProjects, pd.id));
									storeProjectData(newArray);
								}
							})
							.catch(error => {
								console.error(
									'Could not retrieve recent projects - clean recent projects in local storage',
									error
								);
								_storeToLocalStorage([]);
							});
					} else if (recentProjectData.length === 0) {
						setRecentProjects([]);
					}
				} else {
					sortAndSetProjects(recentProjects);
				}
			} catch (error) {
				console.error('Could not retrieve recent projects - clean recent projects in local storage', error);
				_storeToLocalStorage([]);
			}
		}
	}, [recentProjectData]);

	const projectVisited = projectId => {
		if (!findProjectById(projectId)) {
			const arrLength = recentProjectData.length;
			let newArray = recentProjectData;
			newArray.unshift({
				date: new Date(),
				id: projectId,
			});
			if (arrLength > maxRecent) {
				newArray = recentProjectData.slice(0, maxRecent);
			}
			storeProjectData(newArray);
		} else {
			const clonedData = cloneDeep(recentProjectData);
			clonedData.find(pd => pd.id === projectId).date = new Date().toISOString();
			clonedData.sort(sortByDate);
			storeProjectData(clonedData);
		}
	};

	const refresh = () => {
		setRecentProjectData([]);
		setTimeout(() => window.dispatchEvent(new Event('recent-project-storage')), 300);
	};

	return {
		projects: recentProjects,
		projectVisited,
		projectDeleted,
		refresh,
	};
};
