import {useEffect, useState} from 'react';
import {graphql} from 'react-relay';
import {fetchQuery} from 'relay-runtime';
import environment from '../../../../RelayEnvironment';
import Util from '../../../shared/util/util';

export const useProgramCalculatedFields = programIds => {
	const [calculatedFieldsData, setCalculatedFieldsData] = useState();

	const calculateProgramFieldsQuery = graphql`
		query useProgramCalculatedFieldsHookQuery($programIds: [ID]) {
			viewer {
				component(name: "useProgramCalculatedFields")
				actualPersonId
				company {
					id
					name
					programCalculatedFields(first: 1000, programIds: $programIds) {
						edges {
							node {
								programId
								progress {
									progressType
									value
								}
							}
						}
					}
				}
			}
		}
	`;

	const loadData = () => {
		const minutesToWait = 5;
		const calculatedFieldsLocalStorageKey = 'useProgramCalculatedFieldsLastStart';
		const calculatedFieldsDataByStatusLocalStorageKey = 'useProgramCalculatedFieldsData';

		// Check if we have loaded the data in the last 5 minutes
		const lastStartTime = Util.localStorageGetItem(calculatedFieldsLocalStorageKey);
		if (lastStartTime && Date.now() - +lastStartTime < 1000 * 60 * minutesToWait) {
			const localData = Util.localStorageGetItem(calculatedFieldsDataByStatusLocalStorageKey);
			if (localData) {
				return setCalculatedFieldsData(JSON.parse(localData));
			}
			return;
		}
		// set last start to now
		Util.localStorageSetItem(calculatedFieldsLocalStorageKey, Date.now());

		return fetchQuery(environment.getInstance(), calculateProgramFieldsQuery, {programIds}).then(data => {
			const calculatedFieldsData = data.viewer.company.programCalculatedFields.edges.map(programData => programData.node);
			Util.localStorageSetItem(calculatedFieldsDataByStatusLocalStorageKey, JSON.stringify(calculatedFieldsData));
			return setCalculatedFieldsData(calculatedFieldsData);
		});
	};

	useEffect(() => {
		loadData();
	}, []);

	/**
	 * This will return the calculated fields for a program
	 *
	 * @param programId The program ID
	 * @returns {{progress: *}} Calculated Fields Available
	 */
	const programCalcData = programId => {
		let programData = calculatedFieldsData?.find(program => program.programId === programId);
		return {
			progress: programData ? Math.round(programData.progress.value * 100) : 0, // In percent
		};
	};

	return {
		programCalcData,
		reloadData: loadData,
	};
};
