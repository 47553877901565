import React from 'react';
import Util from '../../../../../util/util';
import WarningIcon from '../../../../../../../images/warning_icon';
import {CSS_CONSTANTS} from '../../../../../../../css_variables';
import {GrowableColumn} from '../Elements.styled';
import Styled from 'styled-components/macro';
import {PriorityIcon, BlockedIcon, BugIcon, CrossIcon} from 'web-components';
import AddSubtaskIcon from '../../../../../../../images/v2/AddSubtaskIcon';
import ForecastTooltip from '../../../../tooltips/ForecastTooltip';

export const TaskNameColumn = React.memo(
	({
		task,
		width,
		showTaskModal,
		onCreateSubtask,
		createSubtaskTaskExpanded,
		showCreateSubtask,
		rollupChildrenCount,
		rollupEstimate,
		rollupTimeRegistered,
		expanded,
		parent,
		intl,
	}) => {
		const openCardModal = (e, item, showTaskModal) => {
			e.stopPropagation();
			e.preventDefault();
			if (showTaskModal) {
				showTaskModal(item.companyTaskId);
			}
		};

		const onTaskClick = (e, task, showTaskModal) => {
			const newTarget = e.relatedTarget || e.explicitOriginalTarget;
			if (newTarget && (newTarget.tagName === 'INPUT' || newTarget.tagName === 'Button')) {
				return;
			}
			openCardModal(e, task, showTaskModal);
		};

		const rollupInfo = {rollupEstimate, rollupTimeRegistered};

		const warnings = Util.GetTaskWarnings(task, intl, rollupInfo);

		const parentName = parent && parent.node && parent.node.name;

		return (
			<GrowableColumn width={width}>
				<TaskNameWrapper>
					{task && warnings && warnings[0] ? (
						<TaskNameIcon>
							<WarningIcon
								title={warnings[0].message}
								danger={warnings[0].color === 'red'}
								customWidth={15}
								customHeight={15}
							/>
						</TaskNameIcon>
					) : null}
					{task && task.highPriority ? (
						<TaskNameIcon>
							<PriorityIcon shouldShow size={PriorityIcon.SIZE.SMALL} />
						</TaskNameIcon>
					) : null}
					{task && task.blocked ? (
						<TaskNameIcon>
							<BlockedIcon shouldShow size={BlockedIcon.SIZE.SMALL} />
						</TaskNameIcon>
					) : null}
					{task && task.bug ? (
						<TaskNameIcon>
							<BugIcon shouldShow size={BugIcon.SIZE.SMALL} />
						</TaskNameIcon>
					) : null}
					<TaskTitle
						data-cy="task-name"
						tabIndex={'0'}
						onKeyDown={
							task
								? e => (e.key === ' ' || e.key === 'Enter' ? openCardModal(e, task, showTaskModal) : null)
								: null
						}
						onClick={task ? e => onTaskClick(e, task, showTaskModal) : null}
						title={task.name}
					>
						{parentName && <TaskParentName>{parentName}</TaskParentName>}
						{task.name}
					</TaskTitle>
					{rollupChildrenCount > 0 && !expanded && <TaskChildrenCount>{rollupChildrenCount}</TaskChildrenCount>}
					{showCreateSubtask && (
						<ForecastTooltip content={createSubtaskTaskExpanded ? 'Close Subtask' : 'Create a new subtask'}>
							<TaskAddSubtask active={createSubtaskTaskExpanded} onClick={() => onCreateSubtask(task.id)}>
								{createSubtaskTaskExpanded ? (
									<CrossIcon size={CrossIcon.SIZE.SMALL} color={'#fff'}></CrossIcon>
								) : (
									<AddSubtaskIcon></AddSubtaskIcon>
								)}
							</TaskAddSubtask>
						</ForecastTooltip>
					)}
				</TaskNameWrapper>
			</GrowableColumn>
		);
	}
);

export const TaskParentName = Styled.div`
	color: #A5A5AE;
	font-size: 11px;
`;
export const TaskNameWrapper = Styled.div`
	display: flex;
	flex-grow: 1;
	overflow: hidden;
	align-items: center;
`;

export const TaskNameIcon = Styled.span`
	display:inline;
	margin: 0 4px;
	display:flex;
`;

export const TaskTitle = Styled.div`
	display: flex;
	flex-direction: column;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	min-width: 0;
	cursor: pointer;
	&:hover {
		color: ${CSS_CONSTANTS.color_purple_hover_active};
	}
`;

export const TaskAddSubtask = Styled.div`
	display: flex;
	margin-left: 8px;
	justify-content: center;
	align-items: center;
	height: 24px;
	min-width: 24px;
	cursor: pointer;
	border-radius: 4px;
	background-color: ${props => props.active && CSS_CONSTANTS.v2_branding_purple};
	opacity: ${props => (props.active ? 1 : 0)};
	&:hover {
		svg {
			rect {
				stroke: ${CSS_CONSTANTS.v2_button_very_light_grey_hovered};
			}
		}
	}
`;

const TaskChildrenCount = Styled.div`
	display: flex;
	margin-left: 8px;
	min-height: 20px;
	max-height: 20px;
	min-width: 20px;
	max-width: 20px;
	font-size: 11px;
	border: 1px solid #C4C4CC;
	background-color: #E6E6ED;
	border-radius: 50%;
	justify-content: center;
	align-items: center;
`;
