import React, { useMemo, useState } from 'react';
import { SelectionList, Modal, Icon, FlexRow, Text } from '@forecast-it/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { Size } from '@forecast-it/design-system/dist/forecast-v2/components/feedback/modal/Modal';
import styled from 'styled-components';
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const SelectionListContainer = styled(SelectionList.RowContainer) `
	height: 490px;
`;
const MAX_ALLOWED_LEVELS = 3;
const treeHeight = (options) => {
    var _a;
    return (_a = options === null || options === void 0 ? void 0 : options.reduce(function (maxHeight, option) {
        return Math.max(maxHeight, treeHeight(option.nestedOptions) + 1);
    }, 1)) !== null && _a !== void 0 ? _a : 0;
};
const ShowHideColumnsModal = ({ eyeOptions: originalEyeOptions, onEyeOptionsChange, closeModal, }) => {
    const { formatMessage } = useIntl();
    const maxLevel = useMemo(() => Math.min(treeHeight(originalEyeOptions), MAX_ALLOWED_LEVELS), originalEyeOptions);
    const [eyeOptions, setEyeOptions] = useState(originalEyeOptions);
    const optionLevels = [eyeOptions];
    const toggleChecked = (key) => {
        setEyeOptions(prevEyeOptions => {
            const eyeOption = prevEyeOptions.find(eyeOption => eyeOption.name === key);
            if (eyeOption) {
                eyeOption.checked = !eyeOption.checked;
            }
            return [...prevEyeOptions];
        });
    };
    const confirm = () => {
        onEyeOptionsChange(eyeOptions);
        closeModal();
    };
    const size = maxLevel === 1 ? Size.EXTRA_SMALL : maxLevel === 2 ? Size.MEDIUM : Size.EXTRA_LARGE;
    return (React.createElement(Modal, { size: size, onCloseModal: closeModal },
        React.createElement(Modal.Title, null,
            React.createElement(FormattedMessage, { id: "change_view.show_hide_columns" })),
        React.createElement(Modal.Content, null,
            React.createElement(FormattedMessage, { id: "change_view.show_hide_columns.description" }),
            React.createElement(SelectionListContainer, null, optionLevels.map((options, level) => (React.createElement(SelectionList, { key: level, onAction: toggleChecked, "aria-label": 'Eye Options - Level ' + level }, options.map(({ name, translationId, checked }) => {
                const label = formatMessage({ id: translationId });
                return (React.createElement(SelectionList.Item, { key: name, textValue: label, "data-cy": name },
                    React.createElement(FlexRow, { justifyContent: 'space-between' },
                        React.createElement(Text, { color: checked ? 'main' : 'auxiliary' }, label),
                        React.createElement(Icon, { icon: checked ? 'view' : 'hide' }))));
            })))))),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { onPress: confirm },
                React.createElement(FormattedMessage, { id: 'common.confirm' })),
            React.createElement(Modal.SecondaryFooterButton, { onPress: closeModal },
                React.createElement(FormattedMessage, { id: 'common.close' })))));
};
export default ShowHideColumnsModal;
