import React, {useState} from 'react';
import {
	DeprecatedColorIndicator as ColorIndicator,
	DeprecatedButton as Button,
	DeprecatedText as Text,
	deprecatedTheme as theme,
	FlexColumn,
	FlexItem,
	FlexRow,
} from '@forecast-it/design-system';
import {useIntl} from 'react-intl';
import Util from '../../../../forecast-app/shared/util/util';
import styled from 'styled-components';
import {NUMBER_TYPE} from '../../../../components/new-ui/project/project-budget-v3/util/BudgetUtils';
import {BUDGET_TYPE, PROGRAM_BUDGET_TYPE} from '../../../../constants';
import {useProjectCalculatedFields} from '../hooks/useProjectCalculatedFields';
import {Link, useHistory} from 'react-router-dom';
import {CurrencyInput, PercentageInput} from 'web-components';
import UpdateProjectMutation from '../../../../mutations/update_project_budget_page_mutation';
import updateProjectBudgetMutation from '../../../../mutations/financial-service/update_project_budget_mutation';
import {MODAL_TYPE, showModal} from '../../../shared/components/modals/generic_modal_conductor';
import ForecastTooltip from '../../../shared/components/tooltips/ForecastTooltip';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';
import ProgramUtil from '../../../../forecast-app/shared/util/ProgramUtil';
import ProjectUtil from '../../../../forecast-app/shared/util/project_util';
import {getProjectIdentifier} from '../program-overview-page/ProgramContextProvider';

const LightTable = styled.table`
	width: 100%;
	${theme.font.base.regular}
	color: ${theme.colors.text.primary};
	td,
	th {
		padding: ${theme.spacing.s} ${theme.spacing.m} ${theme.spacing.s} ${theme.spacing.m};
	}
	td.link {
		cursor: pointer;
	}
	th {
		${theme.font.base.bold}
		color: ${theme.colors.text.secondary};
	}
	tr {
		border-bottom: 1px solid ${theme.colors.border.primary.default};
		height: 70px;
	}
`;

const formatNumber = (value, numberType, currencySymbol, intl) => {
	switch (numberType) {
		case NUMBER_TYPE.MONEY:
			return Util.getFormattedNumberWithCurrency(currencySymbol, value ? value : 0, intl);
		case NUMBER_TYPE.PERCENTAGE:
			return Util.getFormattedPercentage(value ? value : 0, intl);
		default:
			break;
	}
	return value;
};

const ProjectBudgetAllocationTable = ({program, projects, company, setShowErrorBox, setErrorTitle, setErrorMessage, retry}) => {
	const {formatMessage} = useIntl();
	const intl = useIntl();
	const currency = Util.GetCurrencySymbol(company.currency);
	const isFixedPriceProgram = program.budgetType === PROGRAM_BUDGET_TYPE.FIXED_PRICE;
	const history = useHistory();
	const formattedProjects = projects.map(project => ({
		...project,
		stageAllocation: project.budget,
		stageAllocationPercentage: (project.budget * 100) / program.budgetValue,
		edited: false,
		amountWarning: false,
		budgetType: project.settings.edges[project.settings.edges.findIndex(obj => obj.node.name === 'budgetType')].node.value,
	}));
	const [projectList, setProjectList] = useState(formattedProjects);
	const [confirmButtonEnabled, setConfirmButtonEnabled] = useState(false);
	const [allocatedBudget, setAllocatedBudget] = useState(
		program.projects.edges.reduce((total, project) => total + project.node.budget, 0)
	);

	const totalConfirmedRevenue = ProgramUtil.getTotalConfirmedRevenue(program);

	// We need this var for navigation purposes
	let blockNavigationAway = true;

	const navigateToBudgetPage = () => {
		showModal({
			type: MODAL_TYPE.PROGRAM_ALLOCATIONS_CHANGED,
			program: program,
			company: company,
			goToBudgetCallback: () => {
				history.replace(`${ProgramUtil.programLink()}/${program.prefix}/budget`);
			},
		});
	};

	const cancelButtonHandler = () => {
		history.replace(`${ProgramUtil.programLink()}/${program.prefix}/budget`);
	};

	const onSuccess = () => {
		// Some logs here maybe?
	};

	const onError = transaction => {
		setErrorTitle('Error');
		setErrorMessage(transaction.error);
		setShowErrorBox(true);
	};

	const openLeavePageConfirmationModal = path => {
		const leavePageCallback = () => {
			trackEvent('Project Budget Allocation', 'Selected Project Period Page');
			blockNavigationAway = false;
			history.replace(path);
		};
		// If no project has been changed, allow navigation
		if (projectList.find(project => project.edited === true) === undefined) {
			leavePageCallback();
			return;
		} else {
			showModal({
				type: MODAL_TYPE.LEAVE_ALLOCATIONS_PAGE,
				leavePageCallback,
			});
		}

		return false;
	};

	// Block navigatiion to another page if changes have been made
	React.useEffect(() => {
		const unblock = history.block(location => {
			if (blockNavigationAway) {
				return openLeavePageConfirmationModal(location.pathname);
			}
			return true;
		});

		return () => {
			unblock();
		};
	}, [blockNavigationAway, projectList]);

	const applyChange = projectId => {
		let clonedProjects = JSON.parse(JSON.stringify(projectList));
		let stagedAllocatedBudget = 0;
		const projectIndex = clonedProjects.findIndex(project => project.id === projectId);
		const clonedProject = clonedProjects[projectIndex];
		const project = projects[projectIndex];

		// Calculate current allocated budget
		let currentAllocatedBudget = 0;
		for (var w = 0; w < clonedProjects.length; w++) {
			currentAllocatedBudget += clonedProjects[w].budget;
		}
		const unAllocatedBudget = program.budgetValue - currentAllocatedBudget;

		// If new allocation is the same as the current allocated budget, do nothing
		if (clonedProject.stageAllocation === project.budget && !clonedProject.edited) {
			return;
		}

		if (clonedProject.stageAllocation > unAllocatedBudget + clonedProject.budget) {
			clonedProject.amountWarning = true;
			trackEvent('Project Budget Allocation', 'Single Change Applied', {
				error: 'This amount exceeds the available budget',
			});
			setProjectList(clonedProjects);
			return;
		}

		if (clonedProject.stageAllocation === project.budget && clonedProject.edited) {
			// If user re-enters original allocation
			clonedProject.budget = project.budget;
			clonedProject.edited = false;
		} else {
			clonedProject.budget = clonedProject.stageAllocation;
			clonedProject.edited = true;
		}

		clonedProject.amountWarning = false;

		// Calculate new total staged budget
		for (var x = 0; x < clonedProjects.length; x++) {
			stagedAllocatedBudget += clonedProjects[x].budget;
		}

		const oldBudget = project.budget;
		const newBudget = clonedProject.budget;
		trackEvent('Project Budget Allocation', 'Single Change Applied', {
			isProjectInProgram: true,
			increased: oldBudget < newBudget,
		});

		setAllocatedBudget(stagedAllocatedBudget);
		setProjectList(clonedProjects);

		if (clonedProjects.find(project => project.edited === true)) {
			setConfirmButtonEnabled(true);
		} else {
			setConfirmButtonEnabled(false);
		}
	};

	const revertChange = projectId => {
		let clonedProjects = JSON.parse(JSON.stringify(projectList));
		const projectIndex = clonedProjects.findIndex(project => project.id === projectId);
		if (
			clonedProjects[projectIndex].edited ||
			clonedProjects[projectIndex].amountWarning ||
			clonedProjects[projectIndex].stageAllocation !== projects[projectIndex].budget
		) {
			setAllocatedBudget(allocatedBudget - clonedProjects[projectIndex].budget + projects[projectIndex].budget);

			clonedProjects[projectIndex].budget = projects[projectIndex].budget;

			// Clear input fields
			clonedProjects[projectIndex].stageAllocation = projects[projectIndex].budget;
			clonedProjects[projectIndex].stageAllocationPercentage =
				(projects[projectIndex].budget * 100) / program.budgetValue;
			clonedProjects[projectIndex].amountWarning = false;
			clonedProjects[projectIndex].edited = false;

			trackEvent('Project Budget Allocation', 'Single Change Reverted');
			setProjectList(clonedProjects);
		}
		if (clonedProjects.find(project => project.edited === true)) {
			setConfirmButtonEnabled(true);
		} else {
			setConfirmButtonEnabled(false);
		}
	};

	const revertAllChanges = () => {
		const formattedProjects = projects.map(project => ({
			...project,
			stageAllocation: project.budget,
			stageAllocationPercentage: (project.budget * 100) / program.budgetValue,
			edited: false,
			amountWarning: false,
			budgetType:
				project.settings.edges[project.settings.edges.findIndex(obj => obj.node.name === 'budgetType')].node.value,
		}));
		setAllocatedBudget(program.projects.edges.reduce((total, project) => total + project.node.budget, 0));
		setProjectList(formattedProjects);
		setConfirmButtonEnabled(false);
	};

	// Revert if program's fixed price changes
	React.useEffect(() => {
		revertAllChanges();
		// Refetch data (especially for financials) in case it's been updated
		retry();
	}, [program.budgetValue]);

	// If we are on the allocations page when we receive the updated values (after applying and confirming the changes)
	// the values on the table do not change (unless we do a hard refresh) as they are not states.
	// The code below essentially uses the revertAllChanges function to set the values of the table to the updated values
	// from the project state which is initially cloned to populate the values in the table.
	React.useEffect(() => {
		revertAllChanges();
	}, [projects]);

	const stageAllocation = (projectId, type, value) => {
		let clonedProjects = JSON.parse(JSON.stringify(projectList));
		const projectIndex = clonedProjects.findIndex(project => project.id === projectId);

		switch (type) {
			case 'amount':
				clonedProjects[projectIndex].stageAllocationPercentage = (value * 100) / program.budgetValue;
				clonedProjects[projectIndex].stageAllocation = value;
				break;
			case 'percentage':
				clonedProjects[projectIndex].stageAllocation = value * program.budgetValue;
				clonedProjects[projectIndex].stageAllocationPercentage =
					(clonedProjects[projectIndex].stageAllocation * 100) / program.budgetValue;
				break;
		}

		clonedProjects[projectIndex].amountWarning = false;

		trackEvent('Project Budget Allocation', 'Edited', {type});
		setProjectList(clonedProjects);
	};

	const commitAllocations = () => {
		let clonedProjects = JSON.parse(JSON.stringify(projectList));

		let projectsToUpdate = [];

		// Update financial metadata (budget value in this case) through financial service first so
		// that when we update the projects thorugh service company we can get the already updated
		// budget value in the mutation response (which is fetched from financial service metadata).
		// If we only update service company, we are not going to get the updated budget value as
		// the calculation in financial service (service company sends a notification that triggers this)
		// are not completed before the response is returned.
		clonedProjects.forEach(project => {
			if (project.edited) {
				const mutationObject = {
					id: project.serviceCompanyId,
					budgetBaseCurrency: project.budget,
					projectProgramBudgetType: program.budgetType,
				};
				projectsToUpdate.push(mutationObject);
			}
		});

		if (projectsToUpdate.length === 0) {
			return;
		}

		Util.CommitMutation(updateProjectBudgetMutation, projectsToUpdate, onSuccess, onError);

		// Update project through service company
		clonedProjects.forEach(project => {
			if (project.edited) {
				project.id = Util.toBase64String(`ProjectType:${project.serviceCompanyId}`);
				const mutationObject = {
					project: project,
					billable: true,
					budget: project.budget,
				};

				Util.CommitMutation(UpdateProjectMutation, mutationObject, onSuccess, onError);
				project.edited = false;
			}
		});

		trackEvent('Project Budget Allocation', 'Changes Confirmed');
		blockNavigationAway = false;
		navigateToBudgetPage();
	};

	const getProjectId = project => (
		<Link to={`/project/${project.prefix}/budget`} style={{textDecoration: 'none'}}>
			<FlexRow gap={'m'}>
				<ColorIndicator color={project.color} />
				<Text variant={'bold'}>
					<div style={{whiteSpace: 'nowrap'}}>{getProjectIdentifier(project)}</div>
				</Text>
			</FlexRow>
		</Link>
	);

	const getProfitMarginAtCompletion = project =>
		formatNumber(project.financialNumbers.recognitionProfitPercentage, NUMBER_TYPE.PERCENTAGE, currency, intl);

	const getTotalSpendAtCompletion = project =>
		formatNumber(project.financialNumbers.allTotalTimeAndExpensesAtCompletion, NUMBER_TYPE.MONEY, currency, intl);

	const getConfirmedRevenue = project =>
		formatNumber(ProjectUtil.getProjectConfirmedRevenue(project), NUMBER_TYPE.MONEY, currency, intl);

	const getConfirmedRevenueTotal = () => (
		<FlexColumn gap={'s'}>
			<Text type={'heading'} variant={'m'}>
				{formatNumber(totalConfirmedRevenue, NUMBER_TYPE.MONEY, currency, intl)}
			</Text>
			<Text variant={'m'}>
				{formatNumber(totalConfirmedRevenue / program.budgetValue, NUMBER_TYPE.PERCENTAGE, currency, intl)}
				{' ' +
					formatMessage({
						id: isFixedPriceProgram ? 'program_budget.of_fixed_price' : 'program_budget.of_cap',
					})}
			</Text>
		</FlexColumn>
	);

	const getTotalSpendAtCompletionTotal = program => (
		<FlexColumn gap={'s'}>
			<Text type={'heading'} variant={'m'}>
				{formatNumber(
					program.projectsFinancialsTotals.allTotalTimeAndExpensesAtCompletion,
					NUMBER_TYPE.MONEY,
					currency,
					intl
				)}
			</Text>
			<Text variant={'m'}>
				{formatNumber(
					program.projectsFinancialsTotals.allTotalTimeAndExpensesAtCompletion / program.budgetValue,
					NUMBER_TYPE.PERCENTAGE,
					currency,
					intl
				)}
				{' ' +
					formatMessage({
						id: isFixedPriceProgram ? 'program_budget.of_fixed_price' : 'program_budget.of_cap',
					})}
			</Text>
		</FlexColumn>
	);

	const getRevenueAtCompletion = project => formatNumber(project.budget, NUMBER_TYPE.MONEY, currency, intl);

	const getRevenueAtCompletionTotal = program => (
		<FlexColumn gap={'s'}>
			<Text type={'heading'} variant={'m'}>
				{formatNumber(allocatedBudget, NUMBER_TYPE.MONEY, currency, intl)}
			</Text>
			<Text variant={'m'}>
				{formatNumber(allocatedBudget / program.budgetValue, NUMBER_TYPE.PERCENTAGE, currency, intl)}
				{' ' +
					formatMessage({
						id: isFixedPriceProgram ? 'program_budget.of_fixed_price' : 'program_budget.of_cap',
					})}
			</Text>
		</FlexColumn>
	);

	const getAllocationAmountInputBox = project => (
		<FlexColumn alignItems="flex-start">
			<div style={{border: project.amountWarning ? '2px solid red' : '', borderRadius: '5px'}}>
				<FlexItem>
					<CurrencyInput
						cy={'program-cap-amount'}
						value={project.stageAllocation}
						currencySymbole={currency}
						intl={intl}
						callback={value => stageAllocation(project.id, 'amount', value)}
						alignLeft={true}
					/>
				</FlexItem>
			</div>
		</FlexColumn>
	);

	const getNonBillableProjectMessage = () => (
		<ForecastTooltip placement={'bottom'} content={intl.formatMessage({id: 'common.non_billable_broject'})}>
			<Text type={'label'} variant={'m'} color={'primary'}>
				--
			</Text>
		</ForecastTooltip>
	);

	const getPeriodProjectMessage = project => (
		<FlexColumn gap={'s'}>
			<Text type={'label'} variant={'m'} color={'primary'}>
				{intl.formatMessage({id: 'common.change_retaine_budget'})}
			</Text>

			<Text type={'label'} variant={'m'} color={'primary'}>
				<Link onClick={() => openLeavePageConfirmationModal(`/project/${project.prefix}/retainer-tracking`)}>
					{intl.formatMessage({id: 'common.go_to_period_page'})}
				</Link>
			</Text>
		</FlexColumn>
	);

	const getAllocationPercentage = project => (
		<FlexColumn alignItems="flex-start">
			<div style={{border: project.amountWarning ? '2px solid red' : '', borderRadius: '5px'}}>
				<PercentageInput
					additionalPercentageSignInfo={' of FP'}
					cy={'program-cap-amount'}
					value={project.stageAllocationPercentage}
					intl={intl}
					callback={value => stageAllocation(project.id, 'percentage', value)}
					noBorder={project.amountWarning}
					alignLeft={true}
				/>
			</div>
		</FlexColumn>
	);

	const getApplyButton = project => (
		<FlexColumn>
			<Button type={'secondary'} size={'m'} onClick={() => applyChange(project.id)}>
				{intl.formatMessage({id: 'common.apply'})}
			</Button>
		</FlexColumn>
	);
	const getRevertButton = project => (
		<FlexColumn>
			<Button type={'ghost'} size={'m'} onClick={() => revertChange(project.id)}>
				{intl.formatMessage({id: 'common.revert'})}
			</Button>
		</FlexColumn>
	);

	const getProjectBudget = project => (
		<FlexColumn alignItems="flex-end">
			<FlexRow gap={'s'}>
				<FlexItem>
					<Text type={'label'} variant={'m'}>
						{project.budgetType === BUDGET_TYPE.NON_BILLABLE
							? getNonBillableProjectMessage()
							: getRevenueAtCompletion(project)}
					</Text>
				</FlexItem>
				{project.budgetType !== BUDGET_TYPE.NON_BILLABLE && (
					<FlexItem>
						{`(${formatNumber(project.budget / program.budgetValue, NUMBER_TYPE.PERCENTAGE, currency, intl)})`}
					</FlexItem>
				)}
			</FlexRow>
		</FlexColumn>
	);

	const getEditBox = project => (
		<div
			style={{
				border: '1px solid grey',
				height: '20px',
				width: '50px',
				justifyContent: 'center',
				textAlign: 'center',
				borderRadius: '3px',
				fontFamily: 'cursive',
				display: project.edited ? '' : 'none',
			}}
		>
			<FlexItem>
				<Text>{intl.formatMessage({id: 'common.edited'})}</Text>
			</FlexItem>
		</div>
	);

	const getTotalBudget = program => (
		<FlexColumn gap={'s'}>
			<Text type={'heading'} variant={'m'}>
				{formatNumber(allocatedBudget, NUMBER_TYPE.MONEY, currency, intl)}
			</Text>
			<Text variant={'m'}>
				{formatNumber(allocatedBudget / program.budgetValue, NUMBER_TYPE.PERCENTAGE, currency, intl)}
				{' ' +
					formatMessage({
						id: isFixedPriceProgram ? 'program_budget.of_fixed_price' : 'program_budget.of_cap',
					})}
			</Text>
		</FlexColumn>
	);
	const {getProgress} = useProjectCalculatedFields(projectList?.map(project => project.id));
	const getFormattedProgress = project => formatNumber(getProgress(project) / 100, NUMBER_TYPE.PERCENTAGE, currency, intl);
	const getRevertText = () => (
		<Text type={'label'} variant={'m'} color={'primary'}>
			<Link
				onClick={() => {
					revertAllChanges();
					trackEvent('Project Budget Allocation', 'All Changes Reverted');
				}}
			>
				{intl.formatMessage({id: 'common.revert_allocations_changes'})}
			</Link>
		</Text>
	);

	const getAmountErrorMessage = (project, index) =>
		index === 7 ? (
			<td
				colspan={'2'}
				key={index}
				align={'left'}
				style={{
					display: project.amountWarning ? '' : 'none',
					paddingRight: '20px',
					paddingTop: '0px',
					width: '20px',
				}}
			>
				<Text type={'label'} variant={'l'} color={'destructive'}>
					This amount exceeds the available budget. Free amount from other projects or increase the overall program
					fixed price amount.
				</Text>
			</td>
		) : (
			<td></td>
		);

	const getContent = project => {
		switch (project.budgetType) {
			case BUDGET_TYPE.RETAINER:
				return getPeriodProjectMessage(project);
			case BUDGET_TYPE.FIXED_PRICE_V2:
				return getAllocationAmountInputBox(project);
			case BUDGET_TYPE.FIXED_PRICE:
				return getAllocationAmountInputBox(project);
			case BUDGET_TYPE.NON_BILLABLE:
				return getNonBillableProjectMessage();
		}
	};

	const columns = [
		{
			title: formatMessage({id: 'common.id'}),
			align: 'left',
			content: getProjectId,
			footer: () => (
				<Text type={'heading'} variant={'m'}>
					{formatMessage({id: 'common.total'})}
				</Text>
			),
		},
		{title: formatMessage({id: 'common.project'}), align: 'right', content: project => project.name},
		{title: formatMessage({id: 'common.progress'}), align: 'right', content: getFormattedProgress},
		...(!isFixedPriceProgram
			? [
					{
						title: formatMessage({id: 'common.confirmed_revenue'}),
						align: 'right',
						content: getConfirmedRevenue,
						footer: getConfirmedRevenueTotal,
					},
			  ]
			: []),
		{
			title: formatMessage({id: 'project_budget.profit_margin_at_completion'}),
			align: 'right',
			content: getProfitMarginAtCompletion,
		},
		{
			title: formatMessage({id: 'project_budget.value_of_service_at_completion'}),
			align: 'right',
			content: getTotalSpendAtCompletion,
			footer: getTotalSpendAtCompletionTotal,
		},
		...(isFixedPriceProgram
			? [
					{
						title: '',
						align: 'right',
						content: getEditBox,
					},
					{
						title: formatMessage({id: 'project_budget.allocated_budget'}),
						align: 'right',
						content: getProjectBudget,
						footer: getTotalBudget,
					},
					{
						title: 'New allocation (amount)',
						align: 'left',
						content: getContent,
					},
					{
						title: 'New allocation (%)',
						align: 'left',
						content: getAllocationPercentage,
						footer: getRevertText,
						footerColumnSpan: '3',
					},
					{
						title: '',
						align: 'left',
						content: getApplyButton,
						noFooterColum: true,
					},
					{
						title: '',
						align: 'left',
						content: getRevertButton,
						noFooterColum: true,
					},
			  ]
			: [
					{
						title: formatMessage({id: 'common.revenue_recognition_at_completion'}),
						align: 'right',
						content: getRevenueAtCompletion,
						footer: getRevenueAtCompletionTotal,
					},
			  ]),
	];

	return (
		<>
			<LightTable>
				<thead>
					<tr style={{height: '0px'}}>
						{columns.map(column => (
							<th align={column.align ? column.align : 'center'}>{column.title}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{projectList.map(project =>
						project.budgetType === BUDGET_TYPE.FIXED_PRICE_V2 || project.budgetType === BUDGET_TYPE.FIXED_PRICE ? (
							<>
								<tr key={project.id} style={{borderBottom: project.amountWarning ? `0px` : ''}}>
									{columns.map((column, index) => (
										<td key={index} align={column.align ? column.align : 'center'}>
											{column.content(project)}
										</td>
									))}
								</tr>
								<tr style={{height: '0px', display: project.amountWarning ? '' : 'none'}} key={project.id}>
									{columns.map((column, index) =>
										index < 8 ? getAmountErrorMessage(project, index, column) : null
									)}
								</tr>
							</>
						) : (
							<tr key={project.id}>
								{columns.map((column, index) =>
									index < 8 ? (
										<td
											colspan={index === 7 ? '3' : '0'}
											key={index}
											align={column.align ? column.align : 'center'}
										>
											{column.content(project)}
										</td>
									) : null
								)}
							</tr>
						)
					)}
				</tbody>
				<tfoot>
					<tr>
						{columns.map(column =>
							!column.noFooterColum ? (
								<td
									colspan={column.footerColumnSpan ? column.footerColumnSpan : '0'}
									align={column.footerColumnSpan ? 'right' : column.align ? column.align : 'center'}
								>
									{column.footer ? column.footer(program) : ''}
								</td>
							) : null
						)}
					</tr>
				</tfoot>
			</LightTable>
			{isFixedPriceProgram && (
				<FlexRow justifyContent="space-between">
					<FlexItem>
						<FlexRow gap={'s'}>
							<Button disabled={!confirmButtonEnabled} type={'creative'} size={'l'} onClick={commitAllocations}>
								{intl.formatMessage({id: 'common.confirm_changes'})}
							</Button>
							<Button type={'secondary'} size={'l'} onClick={cancelButtonHandler}>
								{intl.formatMessage({id: 'common.cancel'})}
							</Button>
						</FlexRow>
					</FlexItem>
				</FlexRow>
			)}
		</>
	);
};

export default ProjectBudgetAllocationTable;
