import {useRef} from 'react';
import {isEqual} from 'lodash';

export const useDidObjectChange = (currentValue, initialValue) => {
	const previousValue = useRef(initialValue);

	const objectChanged = !isEqual(previousValue.current, currentValue);
	if (objectChanged) {
		previousValue.current = currentValue;
	}

	return objectChanged;
};
