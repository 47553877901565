import { convertFromHtmlToDraftJs } from './HtmlToDraftJsConverter';
import { convertFromDraftJsToHtml } from './DraftJsToHtmlConverter';
export const useDraftJsHtmlConversion = ({ initialDraftJsContent, onDraftJsChanged, }) => {
    const initialHtml = convertFromDraftJsToHtml(initialDraftJsContent);
    const convertHtmlToDraftJs = (htmlContent) => {
        const draftJsContent = convertFromHtmlToDraftJs(htmlContent);
        onDraftJsChanged(draftJsContent);
    };
    return { initialHtml, convertHtmlToDraftJs };
};
