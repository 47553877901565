import React from 'react';
import {cloneDeep} from 'lodash';
import Util from '../../../util/util';
import DropdownV2 from '../../dropdowns/dropdown';
import AssignedTableTile from '../../table-tiles/assigned_table_tile';
import moment from 'moment';

export const statusElement = (task, intl, disabled) => {
	return (
		<DropdownV2
			focusOnClick={true}
			optionClickEvent={true}
			onChange={status => Util.changeTaskStatus(task, status, intl)}
			options={cloneDeep(task.project.statusColumnsV2.edges)
				.sort((a, b) => {
					const comparisonResult = a.node.order - b.node.order;
					if (comparisonResult > 0) {
						return 1;
					} else if (comparisonResult < 0) {
						return -1;
					} else {
						return 0;
					}
				})
				.map(statusColumn => {
					return {
						label: statusColumn.node.name,
						value: statusColumn.node.id,
						logo: statusColumn.node.jiraStatusId ? 'jira-logo' : undefined,
					};
				})}
			value={task.statusColumnV2.id}
			hideLabel={true}
			restrictWidth={true}
			customHeight={30}
			customWidth={166}
			inputCy={'status-list'}
			listDataCy={'status'}
			disabled={disabled}
		/>
	);
};

export const assigneesElement = (task, assignedPersons) => {
	return (
		<AssignedTableTile
			disabled={true}
			assignablePersons={task.project && task.project.projectPersons ? task.project.projectPersons.edges : []}
			assignedPersons={assignedPersons || task.assignedPersons}
			useTableStyling={true}
			isMultiSelect={true}
			id={task.id}
			key={'assigned-person'}
			task={task}
			maxDisplayed={4}
			cy={'assigned-person'}
			suggestedPersonRoleFilter={task.role}
			isClientUser={false}
		/>
	);
};

export const ownerElement = (task, disabled = true, assignablePersons) => {
	return (
		<AssignedTableTile
			disabled={disabled}
			assignablePersons={
				assignablePersons
					? assignablePersons
					: task.project && task.project.projectPersons
					? task.project.projectPersons.edges
					: []
			}
			assignedPersons={task.owner ? [task.owner] : []}
			useTableStyling={true}
			isMultiSelect={false}
			cy="task-modal-owner-dropdown"
			task={task}
			useSmallerStyling={true}
			withAddIcon={false}
			closeOnChoice={true}
			wideExpanded={true}
			isClientUser={false}
			isOwner={true}
		/>
	);
};

export const getDateText = (day, month, year, intl) => {
	const {formatMessage} = intl;
	const today = moment();
	if (day == null) {
		return '';
	}
	const date = Util.CreateNonUtcMomentDate(year, month, day);
	if (today.isSame(date, 'date')) {
		return <span className={'today date-text'}>{formatMessage({id: 'common.today'})}</span>;
	} else if (today.add(1, 'days').isSame(date, 'date')) {
		return <span className={'today date-text'}>{formatMessage({id: 'common.tomorrow'})}</span>;
	} else if (today.isAfter(date, 'date')) {
		return <span className={'overdue date-text'}>{date.format('DD MMM')}</span>;
	}
	return <span className={'date-text'}>{date.format('DD MMM')}</span>;
};

export const getEstimateHours = (task, intl) => {
	return Util.convertMinutesToFullHour(task.estimateForecast, intl);
};

export const getEstimatePoints = (task, intl) => {
	return task.estimateForecast + intl.formatMessage({id: 'common.points.short'});
};

export const getRemainingHours = (task, intl) => {
	return Util.convertMinutesToFullHour(task.timeLeft, intl);
};

export const getRemainingPoints = (task, intl) => {
	return task.timeLeft + intl.formatMessage({id: 'common.points.short'});
};

export const getProgressPercentage = (task, intl) => {
	return task.progress + '%';
};
