import React from 'react';
import {useIntl} from 'react-intl';
import {InputLabel} from 'web-components';
import {PreviewSelector} from './PreviewSelector.styled';
import {createFragmentContainer, graphql} from 'react-relay';
import {SkillsSelections} from './SkillsSelections';
import SkillDropdown from '../skill-dropdown/SkillDropdown';

const SkillsPreviewSelector = ({
	company,
	selectedSkillPersons,
	onChange,
	warningCallback = () => false,
	selectionHeaderLabel,
	hideSelectionHeaderActions = false,
	selectLevels = false,
}) => {
	const {formatMessage} = useIntl();
	const {skills} = company;

	if (!selectionHeaderLabel) {
		selectionHeaderLabel = formatMessage({id: `common.selected`});
	}

	const onSkillLevelUpdate = (_, skillPersons) => {
		onChange(skillPersons);
	};

	const onRemove = skill => {
		onChange(selectedSkillPersons.filter(sp => sp.skillId !== skill.id));
	};

	const onSkillLevelChange = (skillId, skillLevelId) => {
		onChange(selectedSkillPersons.map(sp => (sp.skillId === skillId ? {skillId, skillLevelId} : sp)));
	};

	return (
		<PreviewSelector>
			<InputLabel
				text={formatMessage({id: 'common.skills'})}
				child={
					<SkillDropdown
						usePortal
						name={formatMessage({id: 'settings_skills.add_skills'})}
						company={company}
						selectedItems={selectedSkillPersons}
						onSelect={onSkillLevelUpdate}
						onRemove={onSkillLevelUpdate}
						selectedGroupName={null}
						selectLevels={selectLevels}
					/>
				}
			/>
			<SkillsSelections
				header={`${selectionHeaderLabel} (${selectedSkillPersons.length})`}
				skills={skills.edges.map(edge => edge.node)}
				selectedSkillPersons={selectedSkillPersons}
				onRemove={onRemove}
				warningCallback={warningCallback}
				hideSelectionHeaderActions={hideSelectionHeaderActions}
				skillLevelsEnabled={selectLevels}
				skillLevels={company.skillLevels}
				onSkillLevelChange={onSkillLevelChange}
			/>
		</PreviewSelector>
	);
};
export default createFragmentContainer(SkillsPreviewSelector, {
	company: graphql`
		fragment SkillsPreviewSelector_company on Company {
			skillLevelsEnabled
			...SkillDropdown_company
			skills(first: 10000) @connection(key: "Company_skills") {
				edges {
					node {
						id
						name
						category {
							id
							name
						}
					}
				}
			}
			skillLevels {
				id
				level
				description
			}
		}
	`,
});
