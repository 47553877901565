/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type settingsHolidayCalendar_viewer$ref = any;
export type settingsHolidayCalendar_QueryVariables = {|
  holidayCalendarId?: ?string
|};
export type settingsHolidayCalendar_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: settingsHolidayCalendar_viewer$ref,
  |}
|};
export type settingsHolidayCalendar_Query = {|
  variables: settingsHolidayCalendar_QueryVariables,
  response: settingsHolidayCalendar_QueryResponse,
|};
*/


/*
query settingsHolidayCalendar_Query(
  $holidayCalendarId: String
) {
  viewer {
    actualPersonId
    component(name: "settings_holiday_calendar")
    ...settingsHolidayCalendar_viewer_3y4PKj
    id
  }
}

fragment settingsHolidayCalendar_viewer_3y4PKj on Viewer {
  availableFeatureFlags {
    key
    id
  }
  company {
    id
    holidayCalendar(id: $holidayCalendarId) {
      id
      name
      holidayCalendarEntries(first: 10000) {
        edges {
          node {
            id
            name
            year
            month
            day
            __typename
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "holidayCalendarId",
    "type": "String"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "settings_holiday_calendar"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"settings_holiday_calendar\")"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "settingsHolidayCalendar_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "args": [
              {
                "kind": "Variable",
                "name": "holidayCalendarId",
                "variableName": "holidayCalendarId"
              }
            ],
            "kind": "FragmentSpread",
            "name": "settingsHolidayCalendar_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "settingsHolidayCalendar_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FeatureFlag",
            "kind": "LinkedField",
            "name": "availableFeatureFlags",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "holidayCalendarId"
                  }
                ],
                "concreteType": "HolidayCalendar",
                "kind": "LinkedField",
                "name": "holidayCalendar",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": (v5/*: any*/),
                    "concreteType": "HolidayCalendarEntryTypeConnection",
                    "kind": "LinkedField",
                    "name": "holidayCalendarEntries",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "HolidayCalendarEntryTypeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "HolidayCalendarEntry",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "year",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "month",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "day",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__typename",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "holidayCalendarEntries(first:10000)"
                  },
                  {
                    "alias": null,
                    "args": (v5/*: any*/),
                    "filters": null,
                    "handle": "connection",
                    "key": "HolidayCalendar_holidayCalendarEntries",
                    "kind": "LinkedHandle",
                    "name": "holidayCalendarEntries"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "settingsHolidayCalendar_Query",
    "operationKind": "query",
    "text": "query settingsHolidayCalendar_Query(\n  $holidayCalendarId: String\n) {\n  viewer {\n    actualPersonId\n    component(name: \"settings_holiday_calendar\")\n    ...settingsHolidayCalendar_viewer_3y4PKj\n    id\n  }\n}\n\nfragment settingsHolidayCalendar_viewer_3y4PKj on Viewer {\n  availableFeatureFlags {\n    key\n    id\n  }\n  company {\n    id\n    holidayCalendar(id: $holidayCalendarId) {\n      id\n      name\n      holidayCalendarEntries(first: 10000) {\n        edges {\n          node {\n            id\n            name\n            year\n            month\n            day\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0e983b91943b653c2a86c0ba8ea72d8b';

module.exports = node;
