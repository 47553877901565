import React from 'react';
import { ValueCell } from '../../../../../../components/initial-plan/ValueCell';
import { Data, HorizontalBar, Title, ValueContainer } from '../ProjectScopingTotals_styled';
import { Tile, TileRow } from './Tile_styled';
import { BUDGET_TYPE } from '../../../../../../constants';
export const FixedPriceLeftTile = ({ totalsData, projectData, settings, intl }) => {
    const isFixedPriceWithNoExpensesOnTop = projectData.budgetType === BUDGET_TYPE.FIXED_PRICE_V2 &&
        Math.abs(totalsData.projectedTotalRevenue - (projectData.budget || 0)) < 0.1;
    return (React.createElement(Tile, null,
        React.createElement(TileRow, null,
            React.createElement(React.Fragment, null,
                React.createElement(Title, { singleLine: true }, intl.formatMessage({
                    id: isFixedPriceWithNoExpensesOnTop ? 'common.fixed_price' : 'common.projected_total_revenue',
                })),
                React.createElement(Data, { small: true },
                    React.createElement(ValueContainer, null,
                        React.createElement(ValueCell, { noJustify: true, cy: isFixedPriceWithNoExpensesOnTop ? 'fixed_price' : 'projected_total_revenue', type: ValueCell.VALUE_TYPE.PRICE, value: totalsData.projectedTotalRevenue, currencySymbol: settings.currencySymbol }))))),
        React.createElement(TileRow, null,
            React.createElement(Title, { singleLine: true }, intl.formatMessage({ id: 'common.projected_total_cost' })),
            React.createElement(Data, { small: true },
                React.createElement(ValueContainer, null,
                    React.createElement(ValueCell, { noJustify: true, cy: 'projected_total_cost', type: ValueCell.VALUE_TYPE.PRICE, value: totalsData.projectedTotalCost, currencySymbol: settings.currencySymbol })))),
        React.createElement(HorizontalBar, null),
        React.createElement(TileRow, null,
            React.createElement(Title, { singleLine: true }, intl.formatMessage({ id: 'common.projected_total_profit' })),
            React.createElement(Data, { small: true },
                React.createElement(ValueContainer, null,
                    React.createElement(ValueCell, { noJustify: true, cy: 'projected_total_profit', type: ValueCell.VALUE_TYPE.PRICE, value: totalsData.projectedTotalProfit, currencySymbol: settings.currencySymbol })))),
        React.createElement(TileRow, null,
            React.createElement(Title, { singleLine: true }, intl.formatMessage({ id: 'common.projected_total_margin' })),
            React.createElement(Data, { small: true },
                React.createElement(ValueContainer, null,
                    React.createElement(ValueCell, { noJustify: true, cy: 'projected_total_margin', type: ValueCell.VALUE_TYPE.PERCENTAGE, value: totalsData.projectedTotalMargin }))))));
};
