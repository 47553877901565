import React, {useContext, useEffect} from 'react';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import Util from '../../shared/util/util';
import {dispatch, EVENT_ID} from '../../../containers/event_manager';

const StateContext = React.createContext(null);

export const SecondaryNavStateProvider = ({children}) => {
	const [expanded, setExpanded] = React.useState(
		Util.localStorageGetItemWithDefault('secondary-navigation-expanded', 'true') === 'true'
	);
	const [locked, setLocked] = React.useState(
		Util.localStorageGetItemWithDefault('secondary-navigation-locked', 'true') === 'true'
	);
	const [openTimer, setOpenTimer] = React.useState(null);
	const [closeTimer, setCloseTimer] = React.useState(null);

	useEffect(() => {
		setTimeout(() => {
			dispatch(EVENT_ID.SIDENAV_STATE_CHANGED);
		}, 1);
	}, [expanded]);

	const open = () => {
		clearTimeout(closeTimer);
		trackEvent('Secondary Navigation', 'Opened');
		Util.localStorageSetItem('secondary-navigation-expanded', true);
		setExpanded(true);
	};

	const close = () => {
		clearTimeout(openTimer);
		trackEvent('Secondary Navigation', 'Closed');
		Util.localStorageSetItem('secondary-navigation-expanded', false);
		setExpanded(false);
	};

	const conditionalOpen = () => {
		clearTimeout(closeTimer);
		setOpenTimer(
			setTimeout(() => {
				if (!locked) {
					open();
				}
			}, 300)
		);
	};

	const conditionalClose = () => {
		clearTimeout(openTimer);
		setCloseTimer(
			setTimeout(() => {
				if (!locked) {
					close();
				}
			}, 300)
		);
	};

	const toggleLock = () => {
		if (locked) {
			close();
		} else {
			open();
		}

		trackEvent('Secondary Navigation', 'Locked');
		Util.localStorageSetItem('secondary-navigation-locked', !locked);
		setLocked(prevLocked => !prevLocked);
	};

	return (
		<StateContext.Provider value={{expanded, locked, toggleLock, open: conditionalOpen, close: conditionalClose}}>
			{children}
		</StateContext.Provider>
	);
};

export const useSecondaryNavState = () => useContext(StateContext);
