import styled, {css} from 'styled-components';
import {CSS_CONSTANTS} from '../../../../../css_variables';
import arrowAsc from '../../../../../images/v2/sort-ascending.svg';
import arrowDsc from '../../../../../images/v2/sort-descending.svg';

const stickyHeaderRules = css`
	position: sticky;
	top: 63px;
	z-index: 1;
	background-color: #fff;
	padding-top: 24px;
`;

const stickyFooterRules = css`
	margin-bottom: 24px;
	border-top: 1px solid ${CSS_CONSTANTS.app_border_color};
`;

const stickyFooterWrapperRules = css`
	position: sticky;
	bottom: 0;
	background-color: #fff;
	z-index: 1;
`;

export const StickyFooterWrapper = styled.div`
	${({hasTimesheetRemaster}) => hasTimesheetRemaster && stickyFooterWrapperRules};
`;

export const TimeRowTableWrapper = styled.div`
	.grid-wrapper {
		display: grid;
		// grid-column-gap: 16px;
		// grid-template-columns: 66px auto 1fr auto auto auto auto 30px;
		grid-template-columns: var(--gridCols);
		.loader {
			grid-column: 1 / -1;
			height: 100px;
			border: solid 1px ${CSS_CONSTANTS.app_border_color};
			padding-right: 8px;
			border-top-width: 0;
		}
		.header-elem-wrapper {
			&.sticky {
				${stickyHeaderRules};
			}
			padding-bottom: 8px;
			padding-right: 4px;
			text-overflow: ellipsis;
			white-space: nowrap;
			outline: none;
			display: flex;
			align-items: end;
			&:focus {
				outline: none;
			}
			font-size: 9px;
			text-transform: uppercase;
			font-weight: 500;
			color: ${CSS_CONSTANTS.v2_text_light_gray};
			border-bottom: solid 1px ${CSS_CONSTANTS.app_border_color};
			text-align: left;
			min-width: 30px;
			&.project-indicator {
				padding: 0 11px 8px 0;
			}
			&:first-child {
				padding-left: 16px;
			}
			&.task-name {
				flex-grow: 1;
			}

			&.hour-inputs {
				display: flex;
				flex-direction: row;
				.week-day-header {
					cursor: pointer;
					max-width: 96px;
					min-width: 96px;
					@media screen and (max-width: 1400px) {
						max-width: 81px;
						min-width: 81px;
					}
					padding-right: 6px;
					text-align: right;
					font-size: 9px;
					font-weight: 500;
					letter-spacing: 1px;
					color: ${CSS_CONSTANTS.v2_text_gray};
					.date-text {
						margin-top: 2px;
					}
					&.non-working-day {
						color: ${CSS_CONSTANTS.v2_text_light_gray};
					}
					&:hover,
					&.today {
						color: ${CSS_CONSTANTS.v2_branding_purple};
					}
				}
				.time-header {
					width: 100%;
					text-align: left;
					font-size: 9px;
					font-weight: 500;
					letter-spacing: 1px;
				}
			}
			&.sortable {
				cursor: pointer;
				overflow: hidden;
				&:hover {
					color: ${CSS_CONSTANTS.v2_branding_purple};
				}
				span {
					width: 18px;
					height: 13px;
					padding: 0.2em 0;
					background-size: 12px;
					color: ${CSS_CONSTANTS.v2_branding_purple};
				}
				&.ascending {
					span {
						background-image: url(${arrowAsc});
						background-size: 18px;
						background-position: center;
						color: ${CSS_CONSTANTS.v2_branding_purple};
						padding-bottom: 1px;
					}
					color: ${CSS_CONSTANTS.v2_branding_purple};
				}
				&.descending {
					span {
						background-image: url(${arrowDsc});
						background-size: 18px;
						background-position: center;
						color: ${CSS_CONSTANTS.v2_branding_purple};
					}
					color: ${CSS_CONSTANTS.v2_branding_purple};
				}
			}
			&:not(.sortable) {
				padding-right: 16px;
			}
			.header-text {
				min-width: 30px;
				overflow: hidden;
				text-overflow: ellipsis;
				letter-spacing: 1px;
			}
		}
		.totals-elem-wrapper {
			position: relative;
			min-width: 30px;
			padding-top: 16px;
			padding-right: 16px;
			font-size: 13px;
			text-overflow: ellipsis;
			color: ${CSS_CONSTANTS.v2_text_gray};
			flex-grow: 0;
			outline: none;
			overflow: hidden;
			border-bottom: solid 1px ${CSS_CONSTANTS.app_border_color};
			&.total-view {
				font-weight: 450;
				background-color: #f5f5f8;
				height: 52px;
				text-align: right;
				padding-top: 0;
				line-height: 52px;
				p {
					text-align: left;
					width: 40px;
					z-index: 2;
					margin: 0 4px 0 0;
				}
			}
			&.first {
				border-left: solid 1px ${CSS_CONSTANTS.app_border_color};
				padding-left: 11px;
			}
			&.hours-total-wrapper {
				${stickyFooterRules};
				display: flex;
				flex-direction: row;

				div {
					margin-right: 6px;
					font-size: 13px;
				}
			}
			&.overflow-visible {
				overflow: visible;
			}
		}
		.elem-wrapper {
			position: relative;
			min-width: 30px;
			padding-top: 16px;
			padding-right: 16px;
			font-size: 13px;
			white-space: nowrap;
			text-overflow: ellipsis;
			color: ${CSS_CONSTANTS.v2_text_gray};
			flex-grow: 0;
			outline: none;
			overflow: hidden;
			&.switch-task-indicator {
				padding-top: 12px;
				overflow: unset;
			}
			&.clickable:not([disabled]) {
				&:hover {
					color: ${CSS_CONSTANTS.v2_branding_purple};
				}
				text-decoration: none;
				cursor: pointer;
			}
			&.no-access {
				font-style: italic;
			}
			&:focus {
				outline: none;
			}
			&.empty-state {
				padding-top: 60px;
				height: 150px;
			}
			height: 48px;
			border-bottom: solid 1px ${CSS_CONSTANTS.app_border_color};
			text-overflow: ellipsis;
			&.first {
				border-left: solid 1px ${CSS_CONSTANTS.app_border_color};
				padding-left: 16px;
			}
			&.last {
				border-right: solid 1px ${CSS_CONSTANTS.app_border_color};
			}
			&.lastRow {
				border-bottom: none;
			}
			&.task-name {
				min-width: 180px;
				@media screen and (max-width: 1240px) {
					min-width: 120px;
				}
			}
			&.project-name,
			&.task-name {
				white-space: nowrap;
				text-overflow: ellipsis;
			}
			&.project-indicator {
				padding: 0 11px 0 0;
				display: flex;
			}
			&.unit4,
			&.harvest {
				overflow: visible;
				.unit4-pop-out,
				.unit4-button,
				.harvest-pop-out,
				.harvest-button {
					height: 48px;
				}
			}
			&.task-remaining-tile {
				text-align: right;
			}
			&.star,
			&.edit,
			&.delete {
				padding-right: 0;
				padding-left: 4px;
			}
			&.edit,
			&.delete {
				padding-top: 14px;
			}
			&.context-menu {
				padding: 8px 8px 0 0;
			}
			&.favorite {
				background: ${CSS_CONSTANTS.favoured_task_yellow};
			}
			&.focused {
				// background: red;
			}
			&.total-view {
				font-weight: 450;
				background-color: #f5f5f8;
				height: 52px;
				text-align: right;
				padding-top: 0;
				line-height: 52px;
				p.hours-total-week {
					text-align: left;
					width: 40px;
					z-index: 2;
					margin: 0 4px 0 0;
				}
			}
			&.overflow-visible {
				overflow: visible;
			}
			&.hours-input-wrapper {
				display: flex;
				flex-direction: row;
				padding-top: 8px;
				padding-left: 2px; //for the lock icon
				input,
				button {
					max-width: 90px;
					min-width: 90px;
					@media screen and (max-width: 1400px) {
						max-width: 75px;
						min-width: 75px;
					}
				}

				&.week-view {
					> div,
					span {
						margin-right: 6px;
					}
				}
				.input-title {
					input,
					button {
						max-width: unset;
						min-width: unset;
					}
				}
			}
			&.hours-total-wrapper {
				${stickyFooterRules};
				display: flex;
				flex-direction: row;

				div {
					display: flex;
					align-items: center;
					margin-right: 6px;
					font-size: 13px;
					max-width: 90px;
					min-width: 90px;
					@media screen and (max-width: 1400px) {
						max-width: 75px;
						min-width: 75px;
					}
				}
			}
			&.hours-total-wrapper-day {
				${stickyFooterRules};
				display: flex;
				flex-direction: row;
				div {
					width: fit-content;
				}
			}
			&.week-view-notes-wrapper {
				overflow: visible;
				padding: 2px 0;
				.notes-section {
					top: 3px;
					left: 2px;
				}
			}
			&.day-view-notes-wrapper {
				padding-right: 0;
				padding-top: 8px;
				overflow: visible;
				input {
					width: 100%;
					height: 30px;
					padding: 0 8px;
					background-color: white;
					border: solid 1px ${CSS_CONSTANTS.v2_border_gray};
					border-radius: 4px;
					&:hover {
						border-color: ${CSS_CONSTANTS.v2_date_picker_button_border_color};
					}
					&:focus {
						outline: none;
					}
				}
				&.disabled {
					input {
						cursor: default;
						width: 100%;
						background-color: ${CSS_CONSTANTS.v2_input_disabled_grey};
						&:hover {
							border-color: ${CSS_CONSTANTS.v2_border_gray};
						}
					}
				}
				textarea {
					width: 100%;
					height: 125px;
					box-shadow: none;
					border: solid 1px $v2-branding-purple;
					border-radius: 4px;
					&:focus {
						border-color: ${CSS_CONSTANTS.color_purple_hover_active};
					}
				}
				.textarea-foldout {
					padding-right: 16px;
					input:read-only {
						cursor: default;
						width: 100%;
						background-color: ${CSS_CONSTANTS.v2_input_disabled_grey};
						color: ${CSS_CONSTANTS.v2_text_light_gray};
						&:hover {
							border-color: ${CSS_CONSTANTS.v2_border_gray};
						}
					}
				}
			}
			&.custom-field-value-wrapper {
				padding-top: 8px;
				overflow: visible;
				input {
					width: 100%;
					height: 30px;
					padding: 0 8px;
					background-color: white;
					border: solid 1px ${CSS_CONSTANTS.v2_border_gray};
					border-radius: 4px;
					&:hover {
						border-color: ${CSS_CONSTANTS.v2_date_picker_button_border_color};
					}
					&:focus {
						outline: none;
					}
				}
			}
		}
	}

	.clickable:not([disabled]) {
		cursor: pointer;
		&:hover,
		&:focus {
			color: ${CSS_CONSTANTS.color_purple_hover_active};
		}
	}
`;

export const VerticallyCentered = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
`;

export const TimeRowTableWrapperOld = styled(TimeRowTableWrapper)`
	margin-top: 24px;

	.totals-elem-wrapper {
		&.total-view {
			height: 44px;
			line-height: 44px;
		}
	}

	.elem-wrapper {
		&.total-view {
			height: 44px;
			line-height: 44px;
		}
	}
`;
