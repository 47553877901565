import {useEffect, useRef, useState} from 'react';
import Util from '../../../shared/util/util';

const LOCAL_EXPANDABLE_KEY = baseKey => `${baseKey}-expandable-map`;

export const useExpandableGroups = (grouping, prefKey) => {
	const firstUpdate = useRef(true);

	const [groupExpandedMap, setGroupExpandedMap] = useState(
		new Map(JSON.parse(Util.localStorageGetItem(LOCAL_EXPANDABLE_KEY(prefKey))))
	);

	/**
	 * Returns if a group is expanded
	 *
	 * @param group The group name
	 * @returns {any} Is it expanded
	 */
	const groupExpanded = group => {
		return groupExpandedMap.get(group);
	};

	/**
	 * This method sets the state of a group expanded.
	 *
	 * It also scrolls the table to the group
	 *
	 * @param group The group name
	 * @param expanded Is the group expanded
	 */
	const setGroupExpanded = (group, expanded) => {
		setGroupExpandedMap(prevState => new Map(prevState.set(group, expanded)));
		Util.localStorageSetItem(LOCAL_EXPANDABLE_KEY(prefKey), JSON.stringify(groupExpandedMap.set(group, expanded)));
	};

	/**
	 * Effect for resetting the expanded group map when the grouping is changed. NOT run on initial render
	 */
	useEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;
			return;
		}

		setGroupExpandedMap(new Map());
		localStorage.removeItem(LOCAL_EXPANDABLE_KEY(prefKey));
	}, [grouping]);

	return {
		setGroupExpanded,
		groupExpanded,
	};
};
