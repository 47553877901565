import Moment from 'moment';
export function getPeriodTitle(isWeekView, currentViewingDate) {
    const today = Moment();
    let title = '';
    if (isWeekView) {
        const weekDiff = currentViewingDate.week() - today.week();
        if (weekDiff === 0) {
            title = "This week's work";
        }
        else if (weekDiff === -1) {
            title = "Last week's work";
        }
        else if (weekDiff === 1) {
            title = "Next week's work";
        }
        else {
            title = "Selected week's work";
        }
    }
    else {
        const dayDiff = currentViewingDate.startOf('day').diff(today.startOf('day'), 'days');
        if (dayDiff === 0) {
            title = "Today's work";
        }
        else if (dayDiff === -1) {
            title = "Yesterday's work";
        }
        else if (dayDiff === 1) {
            title = "Tomorrow's work";
        }
        else {
            title = "Selected day's work";
        }
    }
    return title;
}
export function suggestionsWithoutTimeRegs(timeRegsForPeriod, validTimeRegistrationSuggestions) {
    const taskIds = timeRegsForPeriod.filter(timeReg => { var _a; return (_a = timeReg.node.task) === null || _a === void 0 ? void 0 : _a.id; }).map(timeReg => { var _a; return (_a = timeReg.node.task) === null || _a === void 0 ? void 0 : _a.id; });
    const projectIds = timeRegsForPeriod
        .filter(timeReg => { var _a; return (_a = timeReg.node.project) === null || _a === void 0 ? void 0 : _a.id; })
        .map(timeReg => { var _a; return (_a = timeReg.node.project) === null || _a === void 0 ? void 0 : _a.id; });
    const idleTimeIds = timeRegsForPeriod
        .filter(timeReg => { var _a; return (_a = timeReg.node.idleTime) === null || _a === void 0 ? void 0 : _a.id; })
        .map(timeReg => { var _a; return (_a = timeReg.node.idleTime) === null || _a === void 0 ? void 0 : _a.id; });
    let hasSuggestionWithoutTimeReg = false;
    validTimeRegistrationSuggestions === null || validTimeRegistrationSuggestions === void 0 ? void 0 : validTimeRegistrationSuggestions.forEach(suggestion => {
        var _a, _b, _c, _d, _e, _f;
        if (suggestion) {
            const taskHasTimeReg = ((_a = suggestion.task) === null || _a === void 0 ? void 0 : _a.id) ? taskIds.includes((_b = suggestion.task) === null || _b === void 0 ? void 0 : _b.id) : false;
            const projectHasTimeReg = ((_c = suggestion.project) === null || _c === void 0 ? void 0 : _c.id) ? projectIds.includes((_d = suggestion.project) === null || _d === void 0 ? void 0 : _d.id) : false;
            const idleTimeHasTimeReg = ((_e = suggestion.idleTime) === null || _e === void 0 ? void 0 : _e.id) ? idleTimeIds.includes((_f = suggestion.idleTime) === null || _f === void 0 ? void 0 : _f.id) : false;
            // If we did not match any of the ids, then we have a suggestion without an existing time registration
            if (!(taskHasTimeReg || projectHasTimeReg || idleTimeHasTimeReg)) {
                hasSuggestionWithoutTimeReg = true;
            }
        }
    });
    return hasSuggestionWithoutTimeReg;
}
