import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import Util from '../../util/util';
//import CreateTimeRegistration from './../../mutations/create_time_registration_mutation.modern';
import UpdateTimeRegistration from '../../../../mutations/update_time_registration_mutation.modern';
import Moment from 'moment';
import CustomScrollDiv from '../scroll-bars/custom_scroll_div';
import ReactDOM from 'react-dom';
import {ClickAwayListener} from '@material-ui/core';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';
import {hasLockedInternalTime} from '../../../my-work-tab/my-timesheets-page/bottom-section/TimeApprovalLockLogic';
import {isTimeRegistrationNoteInvalid} from '../../util/time-registration/time-registration-settings/TimeRegistrationNoteFilter';
import {NoteRequiredError} from '../add-time-entry/validation-info/NoteRequiredInfo';

class NotesPopOut extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: false,
			popUpPosition: {top: 0, left: 0},
			noteError: new Array(props.timeRegItem?.timeRegistrationsWithNotes.length).fill(false),
		};
	}

	toggleExpanded(e, btn) {
		trackEvent('Note Icon Button', 'Clicked', {noteExpanded: !this.state.expanded});
		e.stopPropagation();
		if (!this.state.expanded) {
			const noteHeight = 110;
			const marginsAndHeader = 66;
			const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
			const y = e.currentTarget.getBoundingClientRect().bottom;
			const numRenderedItems = this.props.timeRegItem.timeRegistrationsWithNotes
				? Math.min(this.props.timeRegItem.timeRegistrationsWithNotes.length, 2.5)
				: 0;
			const popOutSize = numRenderedItems * noteHeight + marginsAndHeader + (numRenderedItems === 2.5 ? 4 : 0);

			const isCloseToEdge = h - 60 - y <= popOutSize;
			const wouldFoldOverTop = y - popOutSize - 148 <= 0;

			const popUpPosition = {top: 0, left: 0};
			popUpPosition.left = this.notes_section.getBoundingClientRect().left - 304 + 35;

			if (isCloseToEdge && !wouldFoldOverTop) {
				popUpPosition.top = this.notes_section.getBoundingClientRect().top - popOutSize;
				this.setState({popUpPosition, foldUp: true});
			} else {
				popUpPosition.top = this.notes_section.getBoundingClientRect().top + 35;
				this.setState({popUpPosition, foldUp: false});
			}
		}
		if (btn) {
			//click on the button
			this.setState(prevState => {
				return {expanded: !prevState.expanded};
			});
		}
	}

	updateNotes(timeRegistration, timeRegistrationLocked, index, e) {
		const noteError = isTimeRegistrationNoteInvalid(e.target.value);

		if (!noteError && !timeRegistrationLocked) {
			Util.CommitMutation(UpdateTimeRegistration, {
				id: timeRegistration.id,
				notes: e.target.value === '' ? null : e.target.value,
			});
		}

		this.setState(prevState => {
			const prevNoteErrors = [...prevState.noteError];
			prevNoteErrors[index] = noteError;
			return {noteError: prevNoteErrors};
		});
	}

	closeNote() {
		if (this.state.expanded)
			this.setState({
				expanded: false,
				noteError: new Array(this.props.timeRegItem?.timeRegistrationsWithNotes.length).fill(false),
			});
	}

	render() {
		const item = this.props.timeRegItem;
		const {formatMessage, formatDate} = this.props.intl;
		// Render 2.5 notes max, then we scroll

		let nbNotes = 0;
		if (item.timeRegistrationsWithNotes) {
			item.timeRegistrationsWithNotes.sort(function (a, b) {
				//sort notes by date
				if (a.year !== b.year) return a.year - b.year;
				if (a.month !== b.month) return a.month - b.month;
				return a.day - b.day;
			});
			nbNotes = item.timeRegistrationsWithNotes.filter(e => e.notes !== null).length;
		}
		const hasAccess =
			item.timeRegistrationsWithNotes && item.timeRegistrationsWithNotes.length > 0 && this.props.locked !== true;

		return (
			<ClickAwayListener onClickAway={this.closeNote.bind(this)}>
				<div
					className={'notes-section' + (this.state.foldUp ? ' fold-up' : '')}
					title={
						!item.timeRegistrationsWithNotes || item.timeRegistrationsWithNotes.length === 0
							? formatMessage({
									id: 'time_page.locked_notes',
							  })
							: null
					}
					tabIndex={'0'}
				>
					<div
						ref={div => (this.notes_section = div)}
						onClick={e =>
							item.timeRegistrationsWithNotes && this.props.locked !== true ? this.toggleExpanded(e, true) : {}
						}
						className={
							'notes-button' +
							(hasAccess ? (nbNotes > 0 ? ' has-notes' : ' has-time') : ' disabled') +
							(this.state.expanded ? ' expanded' : '')
						}
					>
						{hasAccess && nbNotes > 0 && !this.props.readOnly ? <div className="number">{nbNotes}</div> : null}
					</div>
					{this.state.expanded
						? item.timeRegistrationsWithNotes && item.timeRegistrationsWithNotes.length > 0
							? ReactDOM.createPortal(
									<div
										className={'notes-pop-out' + (this.state.foldUp ? ' fold-up' : '')}
										style={{top: this.state.popUpPosition.top, left: this.state.popUpPosition.left}}
										ref={div => (this.notes_pop_out = div)}
									>
										<div className={'notes-header'}>
											<div className={'notes-header-title'}>
												{formatMessage({id: 'overview_time.notes_for'})}
											</div>
											<div className={'task-name'} title={this.props.taskName}>
												{this.props.taskName}
											</div>
										</div>
										<CustomScrollDiv autoHeight={true} autoHeightMin={1} autoHeightMax={320}>
											<div className="notes-container">
												{item.timeRegistrationsWithNotes.map((timeRegistration, index) => {
													const day = Moment({
														year: timeRegistration.year,
														month: timeRegistration.month - 1,
														date: timeRegistration.day,
													});
													const isLockedInTime =
														this.props.lockedDate &&
														this.props.lockedDate.isSameOrAfter(day, 'day');
													const isLockedInternalTime = hasLockedInternalTime(
														timeRegistration,
														this.props.usingInternalTimeApproval
													);
													const timeRegistrationLocked =
														this.props.readOnly ||
														isLockedInTime ||
														isLockedInternalTime ||
														timeRegistration.economicTimeId ||
														this.props.disabled ||
														timeRegistration.invoiced ||
														timeRegistration.lockedInPeriod ||
														timeRegistration.allocationId;
													return (
														<div key={'note-' + index} className={'note'}>
															<div className="note-header">
																<div className="note-date">
																	{formatDate(day, {
																		weekday: 'long',
																		month: 'numeric',
																		day: 'numeric',
																	})}
																</div>
																<div
																	className="note-hours"
																	title={
																		timeRegistration.invoiced
																			? this.props.intl.formatMessage({
																					id: 'invoice.cannot_edit_time_reg',
																			  })
																			: ''
																	}
																>
																	{'(' +
																		(!timeRegistration.invoiced
																			? Util.convertMinutesToFullHour(
																					timeRegistration.minutesRegistered,
																					this.props.intl
																			  )
																			: formatMessage(
																					{id: 'invoice.invoiced_hours'},
																					{
																						amount: timeRegistration.minutesRegistered,
																						formatted:
																							Util.convertMinutesToFullHour(
																								timeRegistration.minutesRegistered,
																								this.props.intl
																							),
																					}
																			  )) +
																		')'}
																</div>
															</div>
															<div className="note-content">
																<textarea
																	ref={div => (this.notes_pop_out = div)}
																	className={
																		'note-area' +
																		(timeRegistrationLocked ? ' disabled' : '')
																	}
																	maxLength={
																		this.props.characterLimit > -1
																			? this.props.characterLimit
																			: 999
																	}
																	readOnly={timeRegistrationLocked}
																	placeholder={
																		timeRegistrationLocked
																			? ''
																			: formatMessage({id: 'common.enter_notes'})
																	}
																	defaultValue={timeRegistration.notes}
																	onBlur={this.updateNotes.bind(
																		this,
																		timeRegistration,
																		timeRegistrationLocked,
																		index
																	)}
																	title={
																		this.props.disabled
																			? formatMessage(
																					{id: 'project_section.locked-info'},
																					{
																						status: timeRegistration.task
																							? timeRegistration.task.project
																									.status
																							: timeRegistration.project
																							? timeRegistration.project.status
																							: '',
																					}
																			  )
																			: ''
																	}
																/>
																{this.state.noteError[index] && <NoteRequiredError />}
															</div>
														</div>
													);
												})}
											</div>
										</CustomScrollDiv>
									</div>,
									document.querySelector('#root-portal-container')
							  )
							: null
						: null}
				</div>
			</ClickAwayListener>
		);
	}
}
NotesPopOut.propTypes = {
	timeRegItem: PropTypes.object.isRequired,
};
export default injectIntl(NotesPopOut);
