import { trackEvent } from '../../../tracking/amplitude/TrackingV2';
import { hasFeatureFlag } from './FeatureUtil';
export const getLocalStorageKey = (userPilotContentId) => {
    return 'userPilotTrigger' + userPilotContentId;
};
export const scheduleUserPilotTriggerPageLoad = (userPilotContentId) => {
    if (hasFeatureFlag('user_pilot_programmatic_triggers')) {
        const localStorageKey = getLocalStorageKey(userPilotContentId);
        if (window.userpilot) {
            localStorage.setItem(localStorageKey, 'showOnLoad');
        }
    }
};
export const scheduleUserPilotTriggerOnReloadAndReload = (userPilotContentId) => {
    scheduleUserPilotTriggerPageLoad(userPilotContentId);
    window.location.reload();
};
export const triggerUserPilotOnceIfScheduled = (userPilotContentId) => {
    if (!!userPilotContentId && hasFeatureFlag('user_pilot_programmatic_triggers')) {
        const localStorageKey = getLocalStorageKey(userPilotContentId);
        const userPilotTriggerLocalStorage = localStorage.getItem(localStorageKey);
        if (window.userpilot && userPilotTriggerLocalStorage === 'showOnLoad') {
            trackEvent('Userpilot Flow', 'Triggered Once', { userPilotContentId: userPilotContentId });
            localStorage.setItem(localStorageKey, 'shown');
            window.userpilot.trigger(userPilotContentId);
        }
    }
};
export const triggerUserPilotNTimes = (userPilotContentId, numberOfTimes) => {
    if (!!userPilotContentId && hasFeatureFlag('user_pilot_programmatic_triggers')) {
        const localStorageKey = getLocalStorageKey(userPilotContentId);
        const userPilotTriggerLocalStorage = localStorage.getItem(localStorageKey);
        const shownTimes = userPilotTriggerLocalStorage ? parseInt(userPilotTriggerLocalStorage.split('_')[1]) : 0;
        if (window.userpilot && shownTimes < numberOfTimes) {
            trackEvent('Userpilot Flow', 'Triggered Programmatically', {
                userPilotContentId: userPilotContentId,
                shownTimes: (shownTimes + 1).toString(10),
            });
            localStorage.setItem(localStorageKey, 'shown_' + (shownTimes + 1));
            window.userpilot.trigger(userPilotContentId);
        }
    }
};
export const triggerUserPilotOnce = (userPilotContentId) => {
    triggerUserPilotNTimes(userPilotContentId, 1);
};
