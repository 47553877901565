import React from 'react';
import {DeprecatedDropdown as Dropdown, DeprecatedDropdownMenuText as DropdownMenuText} from '@forecast-it/design-system';
import {useIntl} from 'react-intl';

export const GROUPING = {
	CLIENT: 'client',
	STAGE: 'stage',
	STATUS: 'status',
	FAVORITE: 'favorite',
	NO_GROUPING: '',
};

const GroupingDropdown = ({onSelectGroup, initialGroup, disabled, omit = [], cy}) => {
	const {formatMessage} = useIntl();

	const getValueFromGroup = group => {
		switch (group) {
			case GROUPING.CLIENT:
				return {
					text: formatMessage({id: 'common.client'}),
					value: GROUPING.CLIENT,
				};
			case GROUPING.STAGE:
				return {
					text: formatMessage({id: 'common.stage'}),
					value: GROUPING.STAGE,
				};
			case GROUPING.STATUS:
				return {
					text: formatMessage({id: 'common.status'}),
					value: GROUPING.STATUS,
				};
			case GROUPING.FAVORITE:
				return {
					text: formatMessage({id: 'common.favorite'}),
					value: GROUPING.FAVORITE,
				};
			default:
				return {text: formatMessage({id: 'common.no_grouping'}), value: ''};
		}
	};

	const groupingOptions = Object.values(GROUPING)
		.filter(group => !omit.includes(group))
		.map(group => getValueFromGroup(group));

	return (
		<Dropdown onSelect={onSelectGroup} initialValue={getValueFromGroup(initialGroup)} disabled={disabled} data-cy={cy}>
			<DropdownMenuText values={groupingOptions} />
		</Dropdown>
	);
};

export default GroupingDropdown;
