import React, {useState} from 'react';
import {
	DeprecatedCreativeModal as CreativeModal,
	FlexColumn,
	DeprecatedText as Text,
	FlexRow,
	FlexItem,
	RadioGroupHandler,
} from '@forecast-it/design-system';
import {useIntl} from 'react-intl';
import Util from '../../../../../shared/util/util';
import UpdateProjectMutation from '../../../../../../mutations/update_project_mutation.modern';
import {SYNCH_BASELINE_SETTING} from '../../../../../../constants';

const RelinkBaselineScopingDatesModal = ({closeModal, project, synchDates}) => {
	const {formatMessage} = useIntl();
	const [selectedOption, setSelectedOption] = useState(null);
	const [isConfirmDisabled, setIsConfirmDisabled] = useState(true);

	const handleRadioSelection = option => {
		setSelectedOption(option);
		setIsConfirmDisabled(false);
	};

	const handleConfirmClick = () => {
		Util.CommitMutation(UpdateProjectMutation, {
			project: project,
			synchBaselineAndScopingDates: synchDates,
			synchDatesSetting: selectedOption,
		});

		closeModal();
	};

	return (
		<CreativeModal
			closeModal={closeModal}
			onCreateHandler={handleConfirmClick}
			creativeButtonText={formatMessage({id: 'common.confirm'})}
			title={formatMessage({id: 'baseline.phase_dates_setting.modal.title'})}
			size="m"
			disabled={isConfirmDisabled}
		>
			<FlexColumn gap="xxl">
				<Text color={'primary'}>{formatMessage({id: 'baseline.phase_dates_setting.modal.description_1'})}</Text>

				<FlexItem>
					<Text variant={'b'}>{formatMessage({id: 'baseline.phase_dates_setting.modal.sub_heading_1'})}</Text>
					<Text color={'secondary'}>{formatMessage({id: 'baseline.phase_dates_setting.modal.sub_heading_2'})}</Text>
				</FlexItem>

				<RadioGroupHandler onValueChange={handleRadioSelection}>
					<FlexRow gap={'s'}>
						<RadioGroupHandler.Item size={'s'} value={SYNCH_BASELINE_SETTING.BASELINE}>
							<RadioGroupHandler.Indicator size={'s'}></RadioGroupHandler.Indicator>
						</RadioGroupHandler.Item>
						<FlexColumn gap="xs">
							<Text>{formatMessage({id: 'baseline.phase_dates_setting.modal.option_1'})}</Text>
						</FlexColumn>
					</FlexRow>
					<FlexRow gap={'s'}>
						<RadioGroupHandler.Item size={'s'} value={SYNCH_BASELINE_SETTING.SCOPING}>
							<RadioGroupHandler.Indicator size={'s'}></RadioGroupHandler.Indicator>
						</RadioGroupHandler.Item>
						<FlexColumn gap="xs">
							<Text>{formatMessage({id: 'baseline.phase_dates_setting.modal.option_2'})}</Text>
						</FlexColumn>
					</FlexRow>
				</RadioGroupHandler>

				<Text>{formatMessage({id: 'baseline.phase_dates_setting.description_2'})}</Text>
			</FlexColumn>
		</CreativeModal>
	);
};

export default RelinkBaselineScopingDatesModal;
