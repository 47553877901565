import React from 'react';
import {createRefetchContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import {Redirect, withRouter} from 'react-router-dom';
import SharedReportLoginWrapper from './login/SharedReportLoginWrapper';
import GenericSavedReportConductor from '../saved-report/GenericSavedReportConductor';
import {hasPermission} from '../../shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';

const SharedReportPage = ({viewer, match, relay, shareType, setLocaleFromBrowser}) => {
	const sharedReport = viewer.sharedReport;
	const sharedId = match.params.sharedId;
	setLocaleFromBrowser();

	if (sharedReport) {
		if (viewer.actualPersonId && !window.location.href.includes('internal')) {
			// if a client user already received report share links as an external user, those will not have the internal prefix
			// They should still work though so we redirect if the client user is already logged in (has an actual person ID).
			return <Redirect to={'/internal/sharedreport/' + sharedId} />;
		}
		if (
			!viewer.actualPersonId ||
			hasPermission(PERMISSION_TYPE.INSIGHTS_READ) ||
			hasPermission(PERMISSION_TYPE.IS_CLIENT_USER)
		) {
			return (
				<GenericSavedReportConductor savedReport={sharedReport.savedReport} sharedId={sharedId} shareType={shareType} />
			);
		} else {
			return <Redirect to="/not-authorized" />;
		}
	} else if (viewer.sharedReportUserRole !== 'ROLE_USER_EXTERNAL') {
		return <Redirect to={'/internal/sharedreport/' + sharedId} />;
	} else {
		return <SharedReportLoginWrapper sharedId={sharedId} refetch={relay.refetch} />;
	}
};

const SharedReportQuery = graphql`
	query SharedReportPage_Query($sharedId: String) {
		viewer {
			actualPersonId
			component(name: "shared_report")
			...SharedReportPage_viewer @arguments(sharedId: $sharedId)
		}
	}
`;

export {SharedReportQuery};

export default injectIntl(
	withRouter(
		createRefetchContainer(
			SharedReportPage,
			{
				viewer: graphql`
					fragment SharedReportPage_viewer on Viewer @argumentDefinitions(sharedId: {type: "String"}) {
						id
						actualPersonId
						sharedReport(sharedId: $sharedId) {
							id
							savedReport {
								...GenericSavedReportConductor_savedReport
								id
								type
								name
							}
						}
						sharedReportUserRole(sharedId: $sharedId)
					}
				`,
			},
			graphql`
				query SharedReportPageRefetchQuery($sharedId: String) {
					viewer {
						...SharedReportPage_viewer @arguments(sharedId: $sharedId)
					}
				}
			`
		)
	)
);
