import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {withRouter} from 'react-router-dom';
import {cloneDeep} from 'lodash';
import * as tracking from '../../../../tracking';
import ClientPage from './client_page';
import NotFoundModal from '../../../../containers/modal/not_found_modal';
import {injectIntl} from 'react-intl';
import {buildHeaderBar} from '../../../shared/components/headers/header-bar/header_bar';
import {ELEMENT_TYPE, TIERS} from '../../../../constants';
import {hasPermission} from '../../../shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../Permissions';
import {trackPage, unregisterPageInfo} from '../../../../tracking/amplitude/TrackingV2';

class settingsClient extends Component {
	constructor(props) {
		super(props);

		this.superPropertyChecksum = trackPage('Settings Clients Client');
	}

	componentDidMount() {
		// Segment
		tracking.trackPage('settings-clients-client');
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	closeModal() {
		this.props.history.goBack();
	}

	getHeader() {
		const headerLeftContent = [{type: ELEMENT_TYPE.BACK_BUTTON, onClick: this.props.history.goBack}];

		return buildHeaderBar(headerLeftContent, []);
	}

	render() {
		if (!this.props.viewer.company.client) {
			const {formatMessage} = this.props.intl;
			return (
				<NotFoundModal
					title={formatMessage({id: 'client_not_found_modal.title'})}
					message={formatMessage({id: 'client_not_found_modal.info'})}
					closeModal={this.closeModal.bind(this)}
				/>
			);
		}
		const showBillingInfo = [TIERS.LITE, TIERS.PRO, TIERS.ENTERPRISE].includes(this.props.viewer.company.tier);
		const invoices = this.props.viewer.invoicesV2.edges.map(edge => edge.node);
		const invoiced = invoices.some(invoice => invoice.client && invoice.client.id === this.props.viewer.company.client.id);

		return (
			<div className="section-content settings-app settings-client">
				{this.props.children}
				{this.getHeader()}
				<ClientPage
					client={cloneDeep(this.props.viewer.company.client)}
					allPersons={this.props.viewer.company.allPersons}
					viewerId={this.props.viewer.id}
					isSettingsClient={true}
					characterLimit={this.props.viewer.company.characterLimit}
					companyId={this.props.viewer.company.id}
					projects={this.props.viewer.projects.edges}
					showBillingInfo={showBillingInfo}
					isFullAccess={true}
					hasClientUpdatePermission={hasPermission(PERMISSION_TYPE.CLIENT_UPDATE)}
					hasProjectUpdatePermission={hasPermission(PERMISSION_TYPE.PROJECTS_UPDATE)}
					viewer={this.props.viewer}
					invoiced={invoiced}
					sageIntacctConnectionEnabled={this.props.viewer.company.sageIntacctConnectionEnabled}
					quickbooksEnabled={this.props.viewer.company.quickbooksEnabled}
					economicEnabled={this.props.viewer.company.economicEnabled}
					newXeroEnabled={this.props.viewer.company.newXeroEnabled}
					newSalesforceEnabled={this.props.viewer.company.newSalesforceEnabled}
					hubspotAccountId={this.props.viewer.company.hubspotAccountId}
					pipedriveCompanyDomain={this.props.viewer.company.pipedriveCompanyDomain}
				/>
			</div>
		);
	}
}

const settingsClientQuery = graphql`
	query settingsClient_Query($clientId: String) {
		viewer {
			actualPersonId
			component(name: "settings_client")
			...settingsClient_viewer @arguments(clientId: $clientId)
		}
	}
`;

export {settingsClientQuery};

export default withRouter(
	injectIntl(
		createFragmentContainer(settingsClient, {
			viewer: graphql`
				fragment settingsClient_viewer on Viewer @argumentDefinitions(clientId: {type: "String"}) {
					id
					availableFeatureFlags {
						key
					}
					company {
						id
						tier
						characterLimit
						quickbooksEnabled
						economicEnabled
						newXeroEnabled
						hubspotAccountId
						newSalesforceEnabled
						pipedriveCompanyDomain
						sageIntacctConnectionEnabled
						allPersons(first: 10000) {
							edges {
								node {
									id
									firstName
									lastName
									profilePictureId
									profilePictureDefaultId
									active
								}
							}
						}
						client(id: $clientId) {
							id
							name
							street
							zip
							city
							vat
							country
							notes
							logoId
							logoDefaultId
							quickbooksCustomerId
							economicCustomerId
							xeroContactId
							sageIntacctCustomerId
							salesforceAccountId
							hubspotCompanyId
							pipedriveOrganizationId
							clientUsers(first: 1000) @connection(key: "Client_clientUsers", filters: []) {
								edges {
									node {
										id
										firstName
										lastName
										email
										phone
									}
								}
							}
						}
					}
					projects(first: 100000) @connection(key: "Viewer_projects", filters: []) {
						edges {
							node {
								id
								name
								client {
									id
								}
								projectPersons(first: 1000, onlyClientUsers: true)
									@connection(key: "Project_projectPersons", filters: []) {
									edges {
										node {
											id
											person {
												id
											}
										}
									}
								}
							}
						}
					}
					invoicesV2(first: 100000) {
						edges {
							node {
								id
								client {
									id
								}
							}
						}
					}
				}
			`,
		})
	)
);
