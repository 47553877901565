import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import useSubscription from '../../hooks/useSubscription';
import { EVENT_ID } from '../../../../containers/event_manager';
const ZenMode = styled.div `
	${({ hidden }) => hidden &&
    css `
			display: none;
		`}
`;
export const ZenModeable = ({ children }) => {
    const [zenModeActive, setZenModeActive] = useState(false);
    const onZenModeEvent = useCallback(() => {
        setZenModeActive(!zenModeActive);
    }, [zenModeActive]);
    useSubscription(EVENT_ID.ZEN_MODE_TOGGLED, onZenModeEvent);
    return React.createElement(ZenMode, { hidden: zenModeActive }, children);
};
