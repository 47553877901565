import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import ActionMenu from '../../shared/components/action-menu/actions_menu';
import {MODAL_TYPE, showModal} from '../../shared/components/modals/generic_modal_conductor';
import Util from '../../shared/util/util';
import {createToast} from '../../shared/components/toasts/another-toast/toaster';
import Person from '../../shared/components/person/person';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import AutoSchedulingDropdown from '../../../components/canvas-scheduling/projects-scheduling/autoscheduling/auto_scheduling_dropdown';
import InputField from '../../../components/new-ui/input_field';
import reset from '../../../images/v2/components/phase/reset.svg';
import UpdateDepartmentMutation from '../../../mutations/update_department_mutation';
import DeleteDepartmentMutation from '../../../mutations/delete_department_mutation';
import UpdatePersonMutation from '../../../mutations/update_person_mutation.modern';
import {SageIntacctIcon} from 'web-components';
import {profilePicSrc} from '../../../directApi';

class Department extends Component {
	constructor(props) {
		super(props);

		const {department} = this.props;

		this.state = {
			expanded: true,
			showNameInput: false,
			name: props.noDepartment ? '' : department.name,
			addToDepartment: [],
			removeFromDepartment: [],
		};
	}

	toggleExpansion() {
		this.setState({expanded: !this.state.expanded});
	}

	deleteDepartment() {
		const callbackPositive = params => {
			Util.CommitMutation(
				DeleteDepartmentMutation,
				{
					id: this.props.department.id,
					companyId: this.props.companyId,
				},
				() =>
					createToast({
						duration: 5000,
						message: this.props.intl.formatMessage({id: 'settings_departments.department_deleted'}),
					}),
				true
			);
		};
		showModal({
			type: MODAL_TYPE.GENERIC_DELETION_WARNING,
			deleteCallback: callbackPositive,
		});
	}

	removePersonFromDepartment(person) {
		Util.CommitMutation(
			UpdatePersonMutation,
			{
				id: person.id,
				departmentId: null,
			},
			null,
			true
		);
	}

	removePersonFromDepartmentState(person) {
		if (this.state.addToDepartment.some(per => per.id === person.id)) {
			this.setState({addToDepartment: this.state.addToDepartment.filter(per => per.id !== person.id)});
		} else {
			this.setState({removeFromDepartment: [...this.state.removeFromDepartment, person]});
		}
	}

	addPersonToDepartmentState(person) {
		if (this.state.removeFromDepartment.some(per => per.id === person.id)) {
			this.setState({removeFromDepartment: this.state.removeFromDepartment.filter(per => per.id !== person.id)});
		} else {
			this.setState({addToDepartment: [...this.state.addToDepartment, person]});
		}
	}

	onNameChange(name) {
		this.setState({name});
	}

	onNameBlur() {
		this.setState({showNameInput: false});
		if (this.state.name === this.props.department.name) return;

		Util.CommitMutation(
			UpdateDepartmentMutation,
			{
				id: this.props.department.id,
				name: this.state.name,
			},
			null,
			true
		);
	}

	showNameInput(e) {
		e.stopPropagation();
		this.setState({showNameInput: true});
	}

	getLinkPath(person) {
		if (person.isViewer) {
			return '/my-profile/account-configuration';
		} else {
			return '/admin/team/view-user/' + person.id;
		}
	}

	buildRow(person) {
		return (
			<tr className="department-person-row">
				<td
					className="person-cell"
					onClick={e => {
						e.stopPropagation();
						window.open(this.getLinkPath(person), '_self');
					}}
				>
					<Person
						key={person.id}
						name={person.fullName}
						showName={true}
						showRole={false}
						imageSrc={profilePicSrc(person.profilePictureId)}
						imageSize="medium"
					/>
				</td>
				<td>{person.role ? person.role.name : ''}</td>
				<td>{person.email}</td>
				<td className="remove-person-cell">
					{this.props.noDepartment ? (
						''
					) : (
						<img
							alt="Remove person"
							title="Remove person"
							className="remove-person-button"
							src={reset}
							onClick={() => this.removePersonFromDepartment(person)}
						/>
					)}
				</td>
				<td></td>
			</tr>
		);
	}

	onDropdownCollapse() {
		const callbackPositive = () => {
			this.state.addToDepartment.forEach(person =>
				Util.CommitMutation(
					UpdatePersonMutation,
					{
						id: person.id,
						departmentId: this.props.department.id,
					},
					null,
					true
				)
			);

			this.state.removeFromDepartment.forEach(person =>
				Util.CommitMutation(
					UpdatePersonMutation,
					{
						id: person.id,
						departmentId: null,
					},
					null,
					true
				)
			);

			this.setState({
				addToDepartment: [],
				removeFromDepartment: [],
			});
		};

		const {formatMessage} = this.props.intl;

		const reassignedPeople = this.state.addToDepartment.filter(person => {
			const p = this.props.allPersons.find(allPer => allPer.id === person.id);
			return p.department && p.department.id;
		});

		if (reassignedPeople.length > 0) {
			showModal({
				type: MODAL_TYPE.WARNING,
				warningMessageId: 'settings_department.reassigned',
				warningInformation: [
					formatMessage({id: 'settings_departments.people_reassigned_warning'}),
					...reassignedPeople.map(per => `${per.firstName} ${per.lastName || ''}`),
				],
				buttons: [
					{
						text: formatMessage({id: 'common.cancel'}),
						callback: () =>
							this.setState({
								addToDepartment: [],
								removeFromDepartment: [],
							}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: formatMessage({id: 'common.ok'}),
						callback: callbackPositive,
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.RED,
					},
				],
			});
		} else {
			callbackPositive();
		}
	}

	getDepartmentAssignedList(departmentPersons) {
		const persons = new Set([...departmentPersons, ...this.state.addToDepartment]);
		return [...persons].filter(person => !this.state.removeFromDepartment.some(removePer => removePer.id === person.id));
	}

	render() {
		const {formatMessage} = this.props.intl;
		const departmentPersons = (
			this.props.noDepartment ? this.props.peopleWithoutDepartments : this.props.departmentPersons || []
		).sort((p1, p2) => (p1.fullName.toLowerCase() < p2.fullName.toLowerCase() ? -1 : 1));

		return (
			<div className="department-container">
				{this.props.noDepartment ? (
					''
				) : (
					<div className="settings-department-head-row" onClick={() => this.toggleExpansion()}>
						{this.state.showNameInput && !this.props.noDepartment ? (
							<InputField
								id={this.props.department.id + 'department-name'}
								value={this.state.name}
								onChange={name => this.onNameChange(name)}
								onBlur={() => this.onNameBlur()}
								type="text"
								placeholder=""
								autoFocus={true}
								hideLabel={true}
								blurOnEnter={true}
							/>
						) : (
							<div className="department-name-container" onClick={e => this.showNameInput(e)}>
								<div className="department-name">
									{this.props.department.name} <span>({departmentPersons.length})</span>{' '}
								</div>
								<div className="department-integrations">
									{this.props.department.sageIntacctId && <SageIntacctIcon />}
								</div>
							</div>
						)}

						<button
							className={'expansion-button' + (this.state.expanded ? ' expanded' : '')}
							onClick={e => {
								e.stopPropagation();
								this.toggleExpansion(true);
							}}
						/>
						<ActionMenu
							options={[
								{
									text: formatMessage({id: 'common.delete'}),
									onClick: () => this.deleteDepartment(),
								},
							]}
							whiteInner={true}
						/>
					</div>
				)}
				{this.state.expanded ? (
					<table className="department-person-table">
						<thead>
							<td className="department-table-header">{formatMessage({id: 'common.name'})}</td>
							<td className="department-table-header">{formatMessage({id: 'common.role'})}</td>
							<td className="department-table-header">{formatMessage({id: 'common.email'})}</td>
							<td className="department-table-header"></td>
							<td className="department-table-header"></td>
						</thead>
						<tbody>{departmentPersons.map(person => this.buildRow(person))}</tbody>
						{this.props.noDepartment ? (
							''
						) : (
							<tfoot>
								<tr className="department-add-people-footer">
									<td>
										<AutoSchedulingDropdown
											placeholder={formatMessage({id: 'settings_departments.add_people'})}
											useSmallerStyling={true}
											assignablePersons={this.props.allPersons}
											assignedPersons={this.getDepartmentAssignedList(departmentPersons)}
											assignableRoles={[]}
											assignPerson={person => this.addPersonToDepartmentState(person)}
											unassignPerson={person => this.removePersonFromDepartmentState(person)}
											isMultiSelect={true}
											onCollapse={this.onDropdownCollapse.bind(this)}
										/>
									</td>
									<td />
									<td />
									<td />
									<td />
								</tr>
							</tfoot>
						)}
					</table>
				) : (
					<hr className="collapsed-splitter" />
				)}
			</div>
		);
	}
}

Department.propTypes = {
	department: PropTypes.object,
	noDepartment: PropTypes.bool,
	peopleWithoutDepartments: PropTypes.array,
	allPersons: PropTypes.array,
	companyId: PropTypes.number,
	departmentPersons: PropTypes.array,
};

export default injectIntl(Department);
