import React, {useContext} from 'react';
import {NavLink, Switch} from 'react-router-dom';
import {VersionReloadRoute} from '../../../../containers/version_reload_route';
import ForecastQueryRenderer from '../../../../ForecastQueryRenderer';
import ProgramSettingsTeamPage, {ProgramSettingsTeamPageQuery} from './ProgramSettingsTeamPage';
import ProgramSettingsGeneralPage from './ProgramSettingsGeneralPage';
import {programPrefix} from '../../../../containers/routerv2';

import styled from 'styled-components';
import {useIntl} from 'react-intl';
import {useProgram} from '../hooks/useProgram';
import {ProgramContext} from '../program-overview-page/ProgramContextProvider';

const WithSideNav = styled.div`
	display: flex;
	gap: 0px;
	height: 100%;
	width: 100%;
`;

const SideNav = styled.div`
	box-sizing: border-box;

	display: flex;
	flex-direction: column;
	width: 200px;
	padding: 32px 24px;

	background: #fafafe;
	border-right: 1px solid #e7e7f3;
`;

const StyledNavLink = styled(NavLink)`
	text-decoration: none;
	padding: 8px 12px;
	border-radius: 4px;
	width: 98px;

	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 16px;

	color: #727483;
`;

const Content = styled.div`
	padding-bottom: 32px;
	height: 100%;
	width: 100%;
	display: flex;
`;

const ProgramSettingsPage = ({match}) => {
	const {formatMessage} = useIntl();

	const programContext = useContext(ProgramContext);
	const {program, company, actualPersonId} = programContext;
	const _program = useProgram(program, company.id);
	const canManage = _program.canManage(actualPersonId);

	const defaultPath = canManage ? 'settings/general' : 'settings/team';

	return (
		<WithSideNav>
			<SideNav>
				{canManage && (
					<StyledNavLink
						to={`${match.url}/general`}
						replace
						activeStyle={{background: '#f1f1fd', color: '#6E0FEB'}}
						isActive={(match, location) => {
							if (!match) return false;
							return match.url === location.pathname;
						}}
					>
						{formatMessage({id: 'settings.general'})}
					</StyledNavLink>
				)}
				<StyledNavLink
					to={`${match.url}/team`}
					replace
					activeStyle={{background: '#f1f1fd', color: '#6E0FEB'}}
					isActive={(match, location) => {
						if (!match) return false;
						return match.url === location.pathname;
					}}
				>
					{formatMessage({id: 'common.team'})}
				</StyledNavLink>
			</SideNav>
			<Content>
				<Switch>
					<VersionReloadRoute path={programPrefix('settings/team')}>
						{({match}) => {
							return (
								<ForecastQueryRenderer
									key={'query-render-programSettingsTeamPage' + match.params.prefix}
									query={ProgramSettingsTeamPageQuery}
									authorizeAccessRoute={'program'}
									render={relayProps => <ProgramSettingsTeamPage company={relayProps.viewer.company} />}
								/>
							);
						}}
					</VersionReloadRoute>

					<VersionReloadRoute path={programPrefix(defaultPath)}>
						<ProgramSettingsGeneralPage />
					</VersionReloadRoute>
				</Switch>
			</Content>
		</WithSideNav>
	);
};

export default ProgramSettingsPage;
