import {cloneDeep} from 'lodash';
import {createSavedOptions, optionTraverser} from '../../../the_eye_util';

/**
 * Const for Internal share
 */
export const INTERNAL_SHARE_TYPE = 'INTERNAL';

/**
 * Const for External share
 */
export const EXTERNAL_SHARE_TYPE = 'EXTERNAL';

/**
 * Filter out keys if part of the exclude list
 *
 * @param excludeList The exclude list
 * @param key The key to check
 * @returns {boolean} Should key be excluded.
 */
const inExcludeList = (excludeList, key) => {
	return excludeList.find(excludeKey => {
		if (excludeKey.startsWith('.')) {
			return key.includes(excludeKey);
		} else {
			return key.startsWith(excludeKey);
		}
	});
};

/**
 * Function to filter out options that are not a part
 * of the available eye options for a report.
 *
 * Used for saved reports that are coming from the
 * report service to filter out fields not defined
 * as eye options.
 *
 * @param options The options
 * @param availableOptions The defined eye options
 * @param excludeList A list of keys that should be excluded.
 * @returns {{}}
 */
export const filterAndHandleEyeOptions = (options, availableOptions, excludeList) => {
	const availableSavedOptions = createSavedOptions(availableOptions);
	const availableSavedOptionsKeys = Object.keys(availableSavedOptions);

	// Create list of hidden values
	const availableSavedOptionHide = {};

	optionTraverser(availableOptions, (option, path) => {
		availableSavedOptionHide[path] = option.hide;
	});

	// Filter out options that not exist in the default eye options or is in the exclude list.
	const filteredOptions = Object.keys(options)
		.filter(key => availableSavedOptionsKeys.includes(key) && !inExcludeList(excludeList, key))
		.reduce((obj, key) => {
			if (availableSavedOptionHide[key]) {
				delete obj[key]; // This is a mandatory field - Let the code below add the correct value
			} else {
				obj[key] = options[key];
			}
			return obj;
		}, {});

	// Method to find the parent from a option name (key)
	const findParent = key => {
		let parentFound = availableOptions.find(option => option.name === key);
		if (parentFound) {
			return parentFound;
		} else {
			return key ? findParent(key.substring(0, key.lastIndexOf('.'))) : null;
		}
	};

	// Creates a list of the filtered options
	const filteredOptionKeys = Object.keys(filteredOptions);

	// Run through the filtered options - and add the options is missing (Including the mandatory fields)
	availableSavedOptionsKeys.forEach(key => {
		if (!filteredOptionKeys.includes(key)) {
			// New added hidden option.
			if (availableSavedOptionHide[key] && !inExcludeList(excludeList, key)) {
				const lonelyOption = availableOptions.find(option => option.name === key);
				const parent = findParent(key);
				if (lonelyOption) {
					// This is a hidden lonely option -> Use default value from eye options
					filteredOptions[key] = availableSavedOptions[key];
				} else if (parent?.hidden) {
					// This is a hidden option under a hidden parent - Set to true
					filteredOptions[key] = true;
				} else if (!parent?.hidden) {
					// THis is a hidden option under a none hidden parent - Use parent value
					filteredOptions[key] = filteredOptions[parent.name];
				} else {
					console.error('Could not locate hidden option', key);
				}
			} else {
				filteredOptions[key] = false;
			}
		}
	});

	return filteredOptions;
};

/**
 *  This will remove options from the eye based on an exclude list.
 *
 * @param options The eye options
 * @param excludeList The exclude list
 * @param prefix
 * @returns {*} The filtered options
 */
export const filterEyeOptions = (options, excludeList, prefix = '') => {
	options.forEach(option => {
		if (inExcludeList(excludeList, prefix + option.name)) {
			option.hide = true;
			// options.pop(option);
		}

		if (option.nestedOptions) {
			filterEyeOptions(option.nestedOptions, excludeList, prefix + option.name + '.');
		}
	});
	return options;
};

export const addCustomFieldsEntity = (options, customFields, entities) => {
	if (customFields && customFields.length > 0) {
		const optionsWithCustom = cloneDeep(options);
		entities.forEach(entity => {
			optionsWithCustom.push({
				name: entity.entityTypePrefix + '.' + entity.entityType,
				checked: entity.checked,
				hide: entity.hide,
				translationId: 'settings.custom_fields.title',
				nestedOptions: [
					...customFields.map(customFieldDefinition => ({
						name: customFieldDefinition.key,
						displayName: customFieldDefinition.displayName,
						checked: entity.checked,
						hide: entity.hide,
						nestedOptions: null,
					})),
				],
			});
		});

		return optionsWithCustom;
	} else {
		return options;
	}
};

export const getCustomFieldColumns = (customFieldDefinitions, columns) => {
	return customFieldDefinitions
		? customFieldDefinitions.map(cfd => {
				const key = Object.keys(columns).find(key => key === 'company.reportCustomFieldDefinitions.' + cfd.key);

				return {
					displayName: cfd.displayName,
					visible: columns[key] || false,
				};
		  })
		: [];
};
