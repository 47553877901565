import React, { useMemo } from 'react';
import { Button, FlexColumn, FlexContainer, FlexRow, Icon, Label, TableBar, Text } from '@forecast-it/design-system';
import Styled from 'styled-components';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import RichTextItem from '../../../components/richtext_item';
import SettingsClientHeader from './SettingsClientHeader';
import SettingsClientAssociatedBillTosSection from './SettingsClientAssociatedBillTosSection';
import { MODAL_TYPE, showModal } from '../../shared/components/modals/generic_modal_conductor';
import CustomScrollDiv from '../../shared/components/scroll-bars/custom_scroll_div';
import SettingsClientIntegrations from './SettingsClientIntegrations';
import { isoCountries } from '../../../constants';
const PageWrapper = Styled.div `
	padding: 25px;
`;
const TableBarWrapper = Styled.div `
	margin: 32px 0 24px;
`;
export const RichTextWrapper = styled.div `
	.editor {
		padding: 0;
	}
`;
export const LabelIconButton = styled.button `
	border: none;
	padding: 0;
`;
const SettingsClientInformation = ({ viewer, isProjectClientPage }) => {
    const { formatMessage } = useIntl();
    const { client } = viewer.company || {};
    const clientId = client === null || client === void 0 ? void 0 : client.id;
    const openEditClientDetailsModal = () => {
        showModal({
            type: MODAL_TYPE.EDIT_CLIENT_DETAILS,
            clientId: client === null || client === void 0 ? void 0 : client.id,
        });
    };
    const openEditNotesModal = () => {
        showModal({
            type: MODAL_TYPE.EDIT_CLIENT_NOTES,
            clientId: client === null || client === void 0 ? void 0 : client.id,
            currentNotes: client === null || client === void 0 ? void 0 : client.notes,
        });
    };
    const country = useMemo(() => { var _a; return (_a = isoCountries.find(_country => _country.value === (client === null || client === void 0 ? void 0 : client.country))) === null || _a === void 0 ? void 0 : _a.label; }, [client === null || client === void 0 ? void 0 : client.country]);
    return (React.createElement(CustomScrollDiv, null,
        React.createElement(PageWrapper, null,
            clientId ? (React.createElement(SettingsClientHeader, { clientId: clientId, clientName: client === null || client === void 0 ? void 0 : client.name, clientLogoDefaultId: client === null || client === void 0 ? void 0 : client.logoDefaultId, clientLogoId: client === null || client === void 0 ? void 0 : client.logoId })) : null,
            React.createElement(TableBarWrapper, null,
                React.createElement(TableBar, { title: formatMessage({ id: 'settings_clients.client_information.header_text' }) },
                    React.createElement(Button, { emphasis: 'low', onClick: openEditClientDetailsModal },
                        React.createElement(FormattedMessage, { id: "settings_clients.client_information.edit_button_text" })))),
            React.createElement(FlexContainer, null,
                React.createElement(FlexRow, null,
                    React.createElement(FlexColumn, null,
                        React.createElement(Label, null,
                            React.createElement(FormattedMessage, { id: "common.name" })),
                        React.createElement(Text, null, (client === null || client === void 0 ? void 0 : client.name) || '-')),
                    React.createElement(FlexColumn, null,
                        React.createElement(Label, null,
                            React.createElement(FormattedMessage, { id: "settings.client-company-street" })),
                        React.createElement(Text, null, (client === null || client === void 0 ? void 0 : client.street) || '-')),
                    React.createElement(FlexColumn, null,
                        React.createElement(Label, null,
                            React.createElement(FormattedMessage, { id: "settings.client-company-postcode-zip" })),
                        React.createElement(Text, null, (client === null || client === void 0 ? void 0 : client.zip) || '-'))),
                React.createElement(FlexRow, null,
                    React.createElement(FlexColumn, null,
                        React.createElement(Label, null,
                            React.createElement(FormattedMessage, { id: "settings.client.vat_number" })),
                        React.createElement(Text, null, (client === null || client === void 0 ? void 0 : client.vat) || '-')),
                    React.createElement(FlexColumn, null,
                        React.createElement(Label, null,
                            React.createElement(FormattedMessage, { id: "settings.client-company-city" })),
                        React.createElement(Text, null, (client === null || client === void 0 ? void 0 : client.city) || '-')),
                    React.createElement(FlexColumn, null,
                        React.createElement(Label, null,
                            React.createElement(FormattedMessage, { id: "settings.client-company-country" })),
                        React.createElement(Text, null, country || '-'))),
                React.createElement(FlexRow, null,
                    React.createElement(FlexColumn, null,
                        React.createElement(Label, null,
                            React.createElement(FlexRow, null,
                                React.createElement(FormattedMessage, { id: "settings.client-company-notes" }),
                                React.createElement(LabelIconButton, { onClick: openEditNotesModal },
                                    React.createElement(Icon, { icon: 'edit', size: 'l', color: 'primary' })))),
                        (client === null || client === void 0 ? void 0 : client.notes) ? (React.createElement(RichTextWrapper, null,
                            React.createElement(RichTextItem, { hideEmojiPicker: true, text: client === null || client === void 0 ? void 0 : client.notes, readOnly: true, alwaysShowControls: false }))) : (React.createElement(Text, null, "-")))),
                React.createElement(SettingsClientAssociatedBillTosSection, { viewer: viewer }),
                React.createElement(FlexRow, null,
                    React.createElement(SettingsClientIntegrations, { viewer: viewer, isProjectClientPage: isProjectClientPage }))))));
};
export const SettingsClientInformationQuery = graphql `
	query SettingsClientInformation_Query($clientId: String) {
		viewer {
			actualPersonId
			component(name: "settings_client")
			...SettingsClientInformation_viewer @arguments(clientId: $clientId)
		}
	}
`;
export default createFragmentContainer(SettingsClientInformation, {
    viewer: graphql `
		fragment SettingsClientInformation_viewer on Viewer @argumentDefinitions(clientId: {type: "String"}) {
			id
			availableFeatureFlags {
				key
			}
			company {
				id
				newXeroEnabled
				sageIntacctConnectionEnabled
				hubspotAccountId
				pipedriveCompanyDomain
				quickbooksEnabled
				newSalesforceEnabled
				economicEnabled
				billFroms {
					id
					name
					integration
					billTos(first: 10000) @connection(key: "BillFrom_billTos", filters: []) {
						edges {
							node {
								id
								name
								taxId
								address
								billFromId
							}
						}
					}
				}
				client(id: $clientId) {
					id
					name
					street
					zip
					city
					vat
					country
					notes
					logoId
					logoDefaultId
					clientBillTos(first: 10000) @connection(key: "Client_clientBillTos", filters: []) {
						edges {
							node {
								id
								billFrom {
									id
									name
									integration
								}
								billTo {
									id
									name
									address
									taxId
								}
								clientId
								invoiced
							}
						}
					}
					xeroContactId
					sageIntacctCustomerId
					hubspotCompanyId
					pipedriveOrganizationId
					quickbooksCustomerId
					salesforceAccountId
					economicCustomerId
					clientUsers(first: 1000) @connection(key: "Client_clientUsers", filters: []) {
						edges {
							node {
								id
								firstName
								lastName
								email
								phone
							}
						}
					}
				}
			}
		}
	`,
});
