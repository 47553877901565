import styled from 'styled-components';
import React from 'react';
import {CSS_CONSTANTS} from '../../../../../../../css_variables';
import {Column} from '../Elements.styled';

export const TaskIdColumn = React.memo(({col, task, showTaskModal, width}) => {
	const openCardModal = (e, item, showTaskModal) => {
		e.stopPropagation();
		e.preventDefault();
		if (showTaskModal) {
			showTaskModal(item.companyTaskId);
		}
	};

	const onTaskClick = (e, task, showTaskModal) => {
		const newTarget = e.relatedTarget || e.explicitOriginalTarget;
		if (newTarget && (newTarget.tagName === 'INPUT' || newTarget.tagName === 'Button')) {
			return;
		}
		openCardModal(e, task, showTaskModal);
	};
	return (
		<Column width={width}>
			<TaskIdWrapper
				className={col.name}
				data-cy={col.name}
				key={col.name}
				style={{
					maxWidth: col.maxWidth ? col.maxWidth : '',
					minWidth: col.minWidth,
				}}
				tabIndex={'0'}
				onKeyDown={
					task ? e => (e.key === ' ' || e.key === 'Enter' ? openCardModal(e, task, showTaskModal) : null) : null
				}
				onClick={task ? e => onTaskClick(e, task, showTaskModal) : null}
			>
				{'T' + task.companyTaskId}
			</TaskIdWrapper>
		</Column>
	);
});

const TaskIdWrapper = styled.div`
	cursor: pointer;
	&:hover {
		color: ${CSS_CONSTANTS.color_purple_hover_active};
	}
`;
