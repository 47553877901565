import React from 'react';
import { Text, IconTooltip } from '@forecast-it/design-system';
export function NoteRequiredInfo() {
    return (React.createElement("div", null,
        React.createElement(Text, { size: '2' }, "You need to add a note to create a time registration."),
        React.createElement(Text, { size: '2', color: 'medium' }, "This is a company-wide setting configured by your admin.")));
}
export function NoteRequiredInfoIcon() {
    return (React.createElement(IconTooltip, { icon: 'info', color: 'disabled', iconSize: 's' },
        React.createElement(NoteRequiredInfo, null)));
}
export function NoteRequiredError({ textSize }) {
    return (React.createElement(Text, { color: 'error', size: textSize || '2' }, "Adding a note is required for time entries. This is a company-wide setting."));
}
