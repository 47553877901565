import { projectFormatter } from './ProjectFormatter';
import { recentFormatter } from './RecentFormatter';
import { suggestionFormatter } from './SuggestionFormatter';
import { taskFormatter } from './TaskFormatter';
import { projectGroupFormatter } from './ProjectGroupFormatter';
import { internalTimeFormatter } from './InternalTimeFormatter';
import { SearchResultVariant } from '../types/SearchResultVariant';
export const formatSearchResult = (searchResult, handleRecentSelected, trackAndHideSearch // FIXME: selectedItem should be typed better
) => {
    switch (searchResult.variant) {
        case SearchResultVariant.Recent:
            return recentFormatter({ searchResult, handleRecentSelected });
        case SearchResultVariant.Suggestion:
            return suggestionFormatter({ searchResult, trackAndHideSearch });
        case SearchResultVariant.Task:
            return taskFormatter({ searchResult, trackAndHideSearch });
        case SearchResultVariant.Project:
            return projectFormatter({ searchResult, trackAndHideSearch });
        case SearchResultVariant.ProjectGroup:
            return projectGroupFormatter({ searchResult, trackAndHideSearch });
        case SearchResultVariant.InternalTime:
            return internalTimeFormatter({ searchResult, trackAndHideSearch });
    }
};
