import Util from '../../../../shared/util/util';
import { getSavedReportURLByReportType } from '../../../../../components/insights/saved_report_util';
import CreateTemporaryReport from '../../../../../mutations/reporting/CreateTemporaryReportMutation';
import { getEyeApplied, getGroupingOne, getPeriodDate, getPeriodType, } from '../../../../../containers/modal/create-new-report/create-new-report-util';
import { MODAL_TYPE, showModal } from '../../../../shared/components/modals/generic_modal_conductor';
import { endOfMonth, format, startOfMonth } from 'date-fns';
const createReportMutation = (type, company, history, closeModal) => {
    const onSuccess = response => {
        var _a;
        const report = (_a = response === null || response === void 0 ? void 0 : response.createTemporaryReport) === null || _a === void 0 ? void 0 : _a.temporaryReport;
        history.push(getSavedReportURLByReportType(report === null || report === void 0 ? void 0 : report.type, report === null || report === void 0 ? void 0 : report.id));
        closeModal();
    };
    const today = new Date();
    const startDate = format(startOfMonth(today), 'yyyy-MM-dd');
    const endDate = format(endOfMonth(today), 'yyyy-MM-dd');
    Util.CommitMutation(CreateTemporaryReport, {
        name: type,
        type: type,
        eyeApplied: JSON.stringify(getEyeApplied(type, company.isUsingProjectAllocation, company.isUsingSchedulingPlanMode, Util.isMixedAllocationModeEnabled(company))),
        startDate,
        endDate,
        filterApplied: JSON.stringify(null),
        filters: [],
        groupingOne: getGroupingOne(type),
        periodType: getPeriodType(type),
        periodDate: getPeriodDate(type),
    }, onSuccess);
};
export const createReport = () => {
    showModal({
        type: MODAL_TYPE.CREATE_REPORT_MODAL,
        createReport: (type, company, history, closeModal) => createReportMutation(type, company, history, closeModal),
    });
};
