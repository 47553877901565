import React from 'react';
import styled from 'styled-components';
import {CrossIcon} from 'web-components';

const Badge = styled.div`
	padding: 4px 12px;
	background: ${props => (props.disabled ? '#F1F1FD' : '#e5f4ff')};
	color: ${props => (props.disabled ? '#393946' : '#00589e')};
	border-radius: 12px;
	font-size: 10px;
	margin: 2px;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	white-space: nowrap;
`;

const SelectionBadge = ({name, disabled = false, onRemove}) => {
	return (
		<Badge disabled={disabled}>
			{name}
			{onRemove && <CrossIcon onClick={onRemove} size={CrossIcon.SIZE.SMALL} color="#00589e" />}
		</Badge>
	);
};

export default SelectionBadge;
