import React from 'react';
import {DeprecatedDestructiveModal as DestructiveModal} from '@forecast-it/design-system';
import {DeprecatedText as Text} from '@forecast-it/design-system';
import Util from '../../../util/util';
import DeleteProgramMutation from '../../../../../mutations/project-service/delete_program_mutation';
import {createToast} from '../../toasts/toast';
import {useIntl} from 'react-intl';
import {useRecentPrograms} from '../../../../project-tab/programs/hooks/useRecentPrograms';
import {trackEvent} from '../../../../../tracking/amplitude/TrackingV2';
import ProgramUtil from '../../../util/ProgramUtil';

const DeleteProgramModal = ({closeModal, programId, companyId, onDeleteCallback, origin}) => {
	const {formatMessage} = useIntl();
	const recentPrograms = useRecentPrograms();

	const deleteProgram = () => {
		Util.CommitMutation(
			DeleteProgramMutation,
			{
				programId: programId,
				companyId: companyId,
			},
			() => {
				trackEvent('Program', 'Deleted', {origin: origin});
				closeModal();
				createToast({
					duration: 5000,
					message: 'Program deleted',
				});
				recentPrograms.programDeleted(programId);
				if (onDeleteCallback) onDeleteCallback();
			}
		);
	};

	return (
		<DestructiveModal
			closeModal={closeModal}
			title={formatMessage({id: 'modal.title.delete'})}
			onDeleteHandler={deleteProgram}
		>
			<Text>
				{formatMessage(
					{id: 'modal.description.delete.delete_entity'},
					{entity: ProgramUtil.programText(formatMessage).toLowerCase()}
				)}
			</Text>
			<Text>
				{Util.upperCaseFirst(
					formatMessage(
						{id: 'modal.description.delete.delete_program'},
						{program: ProgramUtil.programText(formatMessage)}
					)
				)}
			</Text>
		</DestructiveModal>
	);
};

export default DeleteProgramModal;
