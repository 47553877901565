import {graphql} from 'react-relay';

export const StatusColumnTasksCountQuery = graphql`
	query StatusColumnTasksCountQuery($projectId: String, $groupId: String, $searchQuery: TaskSearchQueryType) {
		viewer {
			id
			component(name: "StatusColumnTasksCount")
			project(id: $projectId) {
				id
				statusColumnsV2(first: 1000000) @connection(key: "Project_taskscount_statusColumnsV2", filters: []) {
					edges {
						node {
							id
							tasksCount(searchQuery: $searchQuery)
						}
					}
				}
			}
			projectGroup(id: $groupId) {
				id
				projects(first: 1000000) @connection(key: "ProjectGroup_taskscount_projects", filters: []) {
					edges {
						node {
							id
							statusColumnsV2(first: 1000000) @connection(key: "Project_statusColumnsV2", filters: []) {
								edges {
									node {
										id
										projectGroupStatusColumnId
										tasksCount(searchQuery: $searchQuery)
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
