import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import CustomScrollDiv from '../../../scroll-bars/custom_scroll_div';
import {createFragmentContainer, graphql} from 'react-relay';
import ActivityLogRow from './activity_log_row';
import {cloneDeep} from 'lodash';

class activityLog extends Component {
	render() {
		const {footerBottomPosition, taskLevels, viewer} = this.props;
		const taskActivities = viewer.task && viewer.task.taskActivities ? cloneDeep(viewer.task.taskActivities) : [];
		return (
			<div className="activity-list-container" style={{height: footerBottomPosition}} data-cy="activity-list-container">
				<CustomScrollDiv autoHeight={false} className={'custom-scrollbar-div'}>
					<div className="activities">
						{taskActivities.length ? (
							taskActivities.reverse().map((activity, index) => {
								return <ActivityLogRow key={index} activity={activity} taskLevels={taskLevels} />;
							})
						) : (
							<div className="wait-text">
								{this.props.intl.formatMessage({id: 'task_modal.wait-loading-activity'})}
							</div>
						)}
					</div>
				</CustomScrollDiv>
			</div>
		);
	}
}

const activityLogQuery = graphql`
	query activityLog_Query($taskId: ID) {
		viewer {
			actualPersonId
			component(name: "activity_log")
			...activityLog_viewer @arguments(taskId: $taskId)
		}
	}
`;

export {activityLogQuery};

export default createFragmentContainer(injectIntl(activityLog), {
	viewer: graphql`
		fragment activityLog_viewer on Viewer @argumentDefinitions(taskId: {type: "ID"}) {
			availableFeatureFlags {
				key
			}
			task(id: $taskId) {
				taskActivities {
					id
					eventType
					date
					params
					person {
						id
						firstName
						lastName
						email
						profilePictureId
						profilePictureDefaultId
						role {
							name
						}
					}
				}
			}
		}
	`,
});
