import React, {Component} from 'react';
import {injectIntl, FormattedHTMLMessage, FormattedMessage} from 'react-intl';
import ReactDOM from 'react-dom';

class GenericTooltip extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showTooltip: false,
			stayOpen: false,
			toolTipClicked: false,
		};
	}

	toggleTooltip(e) {
		if (!this.state.stayOpen) {
			if (!this.props.doNotShowTooltip) {
				this.setState({showTooltip: !this.state.showTooltip});
			}
		}
	}

	toggleStayOpen() {
		this.setState({stayOpen: !this.state.stayOpen});
	}

	handleBlur(e) {
		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11
		if ((newTarget && this._container && this._container.contains(newTarget)) || newTarget.tagName === 'A') {
			return;
		}
		this.setState({showTooltip: false, stayOpen: false});
	}

	handleClick(e) {
		this.setState({toolTipClicked: true});
	}

	render() {
		const style = {};
		if (this.state.showTooltip && this._container) {
			const offset = this._container.getBoundingClientRect();
			if (this.props.showOnRight) {
				style.left = offset.right + 10;
				style.top = offset.top;
			} else if (this.props.showOnLeft) {
				//250 is tooltip width + 20 for margin
				style.left = offset.left - 270;
				style.top = offset.top;
			} else {
				if (offset.top + 400 > window.innerHeight) {
					style.bottom = window.innerHeight - offset.top + 15;
				} else {
					style.top = offset.top + offset.height + 12;
				}
				// tooltip width = 250 / 2
				style.left = offset.left + offset.width / 2 - 125; //- (offset.left > 150 ? 150 : offset.left - 20);
			}
		}
		return (
			<div
				tabIndex="0"
				className={
					'tooltip-label' +
					(this.props.type === 'beta' ? ' beta-tooltip-v2' : '') +
					(this.props.small ? ' small' : '')
				}
				onBlur={this.handleBlur.bind(this)}
			>
				<button
					ref={div => (this._container = div)}
					className={'tooltip-button' + (this.props.type === 'beta' ? ' beta-button' : '')}
					onMouseEnter={this.toggleTooltip.bind(this)}
					onMouseLeave={this.toggleTooltip.bind(this)}
					onClick={this.toggleStayOpen.bind(this)}
				>
					{this.props.type === 'beta' ? (
						<FormattedMessage id={'common.beta'} />
					) : this.props.type === 'deprecated' ? (
						<FormattedMessage id={'common.deprecated'} />
					) : (
						''
					)}
				</button>
				{this.state.showTooltip
					? ReactDOM.createPortal(
							<div
								ref={div => (this._tooltip = div)}
								tabIndex="0"
								className={
									'tooltip-portal' +
									(this.props.showOnRight ? ' showOnRight' : this.props.showOnLeft ? ' showOnLeft' : '')
								}
								style={style}
							>
								{this.props.type === 'beta' ? <FormattedHTMLMessage id={'common.beta_tooltip'} /> : ''}
							</div>,
							document.querySelector('#root-portal-container')
					  )
					: null}
			</div>
		);
	}
}
export default injectIntl(GenericTooltip);
