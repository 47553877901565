// @ts-check
import {DeprecatedText as Text} from '@forecast-it/design-system';
import React from 'react';
import {BUDGET_TYPE} from '../../../../../../constants';
import GenericColorIndicatorSlideout from '../../../../../shared/components/slideout/GenericColorIndicatorSlideout';
import {toggleSlideout} from '../../../../../shared/components/slideout/SlideoutConductor';
import {SLIDEOUT_TYPE} from '../../../../../shared/components/slideout/SLIDEOUT_TYPE';
import {RAG_STATUS} from '../../../../../shared/components/status/StatusWrapper';

import ProjectInsightTable from './table/ProjectInsightTable';

const BudgetSlideout = ({program, projects}) => {
	const {projectHealthResponses} = program.projectHealthRollup;

	const tableProjects = projects.map(project => {
		const projectHealth = projectHealthResponses.find(
			projectHealth => projectHealth.projectId === project.serviceCompanyId
		);
		const ragStatus = projectHealth?.budgetInsightRag;
		return {
			id: project.id,
			name: project.name,
			prefix: project.prefix,
			color: project.color,
			budgetType: project.settings.edges.find(obj => obj.node.name === 'budgetType')?.node.value,
			insight: {
				modalHash: 'budget-program',
				ragStatusIndex: ragStatus ? Object.keys(RAG_STATUS).indexOf(ragStatus) : 99,
				ragStatus: ragStatus,
				info: projectHealth?.budgetInsightInfoShort,
				lockText: projectHealth?.budgetInsightError,
			},
		};
	});

	const filteredTableProjects = tableProjects.filter(
		project => project.budgetType === BUDGET_TYPE.FIXED_PRICE || project.budgetType === BUDGET_TYPE.FIXED_PRICE_V2
	);

	return (
		<GenericColorIndicatorSlideout name={program.name} color={program.color} isMultiIndicator>
			<Text type={'heading'} variant={'m'}>
				Budgets
			</Text>
			<ProjectInsightTable tableProjects={filteredTableProjects}></ProjectInsightTable>
		</GenericColorIndicatorSlideout>
	);
};

export const toggleBudgetSlideout = ({program, projects, invokerRef}) => {
	toggleSlideout(SLIDEOUT_TYPE.BUDGET_INSIGHT, {
		content: <BudgetSlideout program={program} projects={projects} />,
		width: 750,
		trackingName: 'Budget Insight Slideout',
		invokerRef,
	});
};
