/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CreateButton_viewer$ref = any;
export type CreateButton_QueryVariables = {|
  projectId?: ?string
|};
export type CreateButton_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: CreateButton_viewer$ref,
  |}
|};
export type CreateButton_Query = {|
  variables: CreateButton_QueryVariables,
  response: CreateButton_QueryResponse,
|};
*/


/*
query CreateButton_Query(
  $projectId: String
) {
  viewer {
    actualPersonId
    component(name: "top_navigation_create_button")
    ...CreateButton_viewer_2CgS8h
    id
  }
}

fragment CreateButton_viewer_2CgS8h on Viewer {
  id
  actualPersonId
  availableFeatureFlags {
    key
    id
  }
  company {
    id
    bambooHREnabled
  }
  project(id: $projectId) {
    id
    status
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId",
    "type": "String"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "top_navigation_create_button"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"top_navigation_create_button\")"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateButton_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "args": [
              {
                "kind": "Variable",
                "name": "projectId",
                "variableName": "projectId"
              }
            ],
            "kind": "FragmentSpread",
            "name": "CreateButton_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateButton_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FeatureFlag",
            "kind": "LinkedField",
            "name": "availableFeatureFlags",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bambooHREnabled",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "projectId"
              }
            ],
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "CreateButton_Query",
    "operationKind": "query",
    "text": "query CreateButton_Query(\n  $projectId: String\n) {\n  viewer {\n    actualPersonId\n    component(name: \"top_navigation_create_button\")\n    ...CreateButton_viewer_2CgS8h\n    id\n  }\n}\n\nfragment CreateButton_viewer_2CgS8h on Viewer {\n  id\n  actualPersonId\n  availableFeatureFlags {\n    key\n    id\n  }\n  company {\n    id\n    bambooHREnabled\n  }\n  project(id: $projectId) {\n    id\n    status\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bf5dd99cc15a2431ce9215a9ed2a9e7c';

module.exports = node;
