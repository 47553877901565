import React, {Component} from 'react';
import GithubSection, {githubSectionQuery} from './github_section';
import ForecastQueryRenderer from '../../../../../../ForecastQueryRenderer';

class GithubSectionRenderer extends Component {
	shouldComponentUpdate(nextProps) {
		return nextProps.taskId !== this.props.taskId;
	}

	render() {
		return (
			<ForecastQueryRenderer
				key="query-render-githubSectionQuery"
				query={githubSectionQuery}
				variables={{taskId: this.props.task.id}}
				customLoader={() => <div className="loading-item" />}
				render={relayProps => {
					return (
						<GithubSection {...relayProps} taskId={this.props.task.id} isTaskReadOnly={this.props.isTaskReadOnly} />
					);
				}}
			/>
		);
	}
}

export default GithubSectionRenderer;
