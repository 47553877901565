/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TaskRollupSection_viewer$ref = any;
export type TaskRollupSection_QueryVariables = {|
  id?: ?string
|};
export type TaskRollupSection_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: TaskRollupSection_viewer$ref,
  |}
|};
export type TaskRollupSection_Query = {|
  variables: TaskRollupSection_QueryVariables,
  response: TaskRollupSection_QueryResponse,
|};
*/


/*
query TaskRollupSection_Query(
  $id: String
) {
  viewer {
    actualPersonId
    component(name: "task_rollup_section")
    ...TaskRollupSection_viewer_1Bmzm5
    id
  }
}

fragment TaskRollupSection_viewer_1Bmzm5 on Viewer {
  id
  task(companyTaskId: $id) {
    id
    rollupEstimate
    rollupTimeEntries
    rollupRemaining
    project {
      manualProgressOnProjectEnabled
      manualProgressOnPhasesEnabled
      manualProgressOnTasksEnabled
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id",
    "type": "String"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "task_rollup_section"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"task_rollup_section\")"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TaskRollupSection_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "id"
              }
            ],
            "kind": "FragmentSpread",
            "name": "TaskRollupSection_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TaskRollupSection_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "companyTaskId",
                "variableName": "id"
              }
            ],
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "task",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rollupEstimate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rollupTimeEntries",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rollupRemaining",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectType",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "manualProgressOnProjectEnabled",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "manualProgressOnPhasesEnabled",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "manualProgressOnTasksEnabled",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "TaskRollupSection_Query",
    "operationKind": "query",
    "text": "query TaskRollupSection_Query(\n  $id: String\n) {\n  viewer {\n    actualPersonId\n    component(name: \"task_rollup_section\")\n    ...TaskRollupSection_viewer_1Bmzm5\n    id\n  }\n}\n\nfragment TaskRollupSection_viewer_1Bmzm5 on Viewer {\n  id\n  task(companyTaskId: $id) {\n    id\n    rollupEstimate\n    rollupTimeEntries\n    rollupRemaining\n    project {\n      manualProgressOnProjectEnabled\n      manualProgressOnPhasesEnabled\n      manualProgressOnTasksEnabled\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9c58db7fffae6476ceecdf5f70e63c4b';

module.exports = node;
