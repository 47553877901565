/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SuggestedStatus_viewer$ref = any;
export type SuggestedStatus_QueryVariables = {|
  projectId?: ?string,
  programPrefix?: ?string,
|};
export type SuggestedStatus_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: SuggestedStatus_viewer$ref,
  |}
|};
export type SuggestedStatus_Query = {|
  variables: SuggestedStatus_QueryVariables,
  response: SuggestedStatus_QueryResponse,
|};
*/


/*
query SuggestedStatus_Query(
  $projectId: ID
  $programPrefix: String
) {
  viewer {
    actualPersonId
    component(name: "suggested_status")
    ...SuggestedStatus_viewer_4bGfr2
    id
  }
}

fragment SuggestedStatus_viewer_4bGfr2 on Viewer {
  firstName
  company {
    isUsingProjectAllocation
    id
  }
  project(internalId: $projectId) {
    id
    status
    companyProjectId
    projectStartYear
    projectStartMonth
    projectStartDay
    projectEndYear
    projectEndMonth
    projectEndDay
    budget
    budgetType
    useBaseline
    revenueMarginLow
    revenueMarginHigh
    rateCard {
      currency
      id
    }
    projectHealth {
      performanceRolling
      optimisticPerformanceRolling
      conservativePerformanceRolling
      performanceRAGStatus
      performanceInfo
      performanceError
      focus
      focusRAGStatus
      focusInfo
      focusError
      predictedEndDate
      predictedEndDateRAGStatus
      predictedEndDateInfo
      predictedEndDateError
      budgetInsightInfo
      budgetInsightRAGStatus
      budgetInsightError
      optimisticPredictedEndDate
      forecastRevenue
      suggestedStatusRag
      id
    }
  }
  program(prefix: $programPrefix) {
    id
    name
    color
    suggestedStatus {
      ragStatus
      statusMessages
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId",
    "type": "ID"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "programPrefix",
    "type": "String"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "suggested_status"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"suggested_status\")"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SuggestedStatus_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "args": [
              {
                "kind": "Variable",
                "name": "programPrefix",
                "variableName": "programPrefix"
              },
              {
                "kind": "Variable",
                "name": "projectId",
                "variableName": "projectId"
              }
            ],
            "kind": "FragmentSpread",
            "name": "SuggestedStatus_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SuggestedStatus_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isUsingProjectAllocation",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "internalId",
                "variableName": "projectId"
              }
            ],
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "companyProjectId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectStartYear",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectStartMonth",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectStartDay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectEndYear",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectEndMonth",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectEndDay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "budget",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "budgetType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "useBaseline",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "revenueMarginLow",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "revenueMarginHigh",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RateCard",
                "kind": "LinkedField",
                "name": "rateCard",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currency",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectHealth",
                "kind": "LinkedField",
                "name": "projectHealth",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "performanceRolling",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "optimisticPerformanceRolling",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "conservativePerformanceRolling",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "performanceRAGStatus",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "performanceInfo",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "performanceError",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "focus",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "focusRAGStatus",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "focusInfo",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "focusError",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "predictedEndDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "predictedEndDateRAGStatus",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "predictedEndDateInfo",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "predictedEndDateError",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "budgetInsightInfo",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "budgetInsightRAGStatus",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "budgetInsightError",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "optimisticPredictedEndDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "forecastRevenue",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "suggestedStatusRag",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "prefix",
                "variableName": "programPrefix"
              }
            ],
            "concreteType": "ProgramType",
            "kind": "LinkedField",
            "name": "program",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "color",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SuggestedStatus",
                "kind": "LinkedField",
                "name": "suggestedStatus",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ragStatus",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "statusMessages",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "SuggestedStatus_Query",
    "operationKind": "query",
    "text": "query SuggestedStatus_Query(\n  $projectId: ID\n  $programPrefix: String\n) {\n  viewer {\n    actualPersonId\n    component(name: \"suggested_status\")\n    ...SuggestedStatus_viewer_4bGfr2\n    id\n  }\n}\n\nfragment SuggestedStatus_viewer_4bGfr2 on Viewer {\n  firstName\n  company {\n    isUsingProjectAllocation\n    id\n  }\n  project(internalId: $projectId) {\n    id\n    status\n    companyProjectId\n    projectStartYear\n    projectStartMonth\n    projectStartDay\n    projectEndYear\n    projectEndMonth\n    projectEndDay\n    budget\n    budgetType\n    useBaseline\n    revenueMarginLow\n    revenueMarginHigh\n    rateCard {\n      currency\n      id\n    }\n    projectHealth {\n      performanceRolling\n      optimisticPerformanceRolling\n      conservativePerformanceRolling\n      performanceRAGStatus\n      performanceInfo\n      performanceError\n      focus\n      focusRAGStatus\n      focusInfo\n      focusError\n      predictedEndDate\n      predictedEndDateRAGStatus\n      predictedEndDateInfo\n      predictedEndDateError\n      budgetInsightInfo\n      budgetInsightRAGStatus\n      budgetInsightError\n      optimisticPredictedEndDate\n      forecastRevenue\n      suggestedStatusRag\n      id\n    }\n  }\n  program(prefix: $programPrefix) {\n    id\n    name\n    color\n    suggestedStatus {\n      ragStatus\n      statusMessages\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '891e9996b2c7a72251226653b7cb4d19';

module.exports = node;
