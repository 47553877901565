import React from 'react';
import styled from 'styled-components';
import {CSS_CONSTANTS} from '../../../../css_variables';
import loader from '../../../../images/loaders/loader-animation.svg';

const Overlayed = styled.div`
	display: flex;
	position: absolute;
	top: 70px;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 9999;

	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.4);
`;

const Box = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: white;
	padding: 20px 75px;
	border-radius: 5px;
`;

const OverlayedLoader = styled.div`
	width: 80px;
	height: 80px;
	background-size: 80px 80px;
	background-image: url(${loader});
	background-repeat: no-repeat;
	background-position: center;
`;

const Loader = styled.div`
	display: flex;
	height: 100%;
	width: 100%;
	background-size: 80px 80px;
	background-image: url(${loader});
	background-repeat: no-repeat;
	background-position: center;
	justify-content: center;
	align-items: center;
`;

const LoaderText = styled.div`
	font-size: 11px;
	padding-top: ${props => (props.overlayed ? '10px' : '45px')};
	color: ${props => (props.overlayed ? 'black' : CSS_CONSTANTS.v2_text_light_gray)};
	font-style: italic;
`;

/**
 * @typedef {object} InlineLoaderProps
 * @property {string} [loaderText]
 * @property {boolean} [overlayed]
 */

/**
 * @type {React.FC<InlineLoaderProps>}
 */
const InlineLoader = ({loaderText, overlayed}) => {
	if (overlayed) {
		return (
			<Overlayed data-cy={'loader'}>
				<Box>
					<OverlayedLoader />
					<LoaderText overlayed={overlayed}>{loaderText}</LoaderText>
				</Box>
			</Overlayed>
		);
	}
	return (
		<Loader data-cy={'loader'}>
			<LoaderText>{loaderText}</LoaderText>
		</Loader>
	);
};

export default InlineLoader;
