import moment from 'moment';
import Moment from 'moment';
import React from 'react';
import {cloneDeep} from 'lodash';
import zipcelx from 'zipcelx';
import {FormattedHTMLMessage, FormattedMessage} from 'react-intl';
import {ContentState, convertFromHTML, convertFromRaw, EditorState} from 'draft-js';
import {closeModal, MODAL_TYPE, showModal} from '../components/modals/generic_modal_conductor';
import CompanySetupUtil, {isFeatureHidden} from './CompanySetupUtil';
import {
	BUDGET_TYPE,
	BUTTON_COLOR,
	BUTTON_STYLE,
	DeadlineFrom,
	DependencyType,
	ECONOMIC_STATUS,
	EXPENSE_BILLING_OPTIONS,
	FILE_BLACKLIST,
	FILE_DETAILS,
	FILTER_FIELD_VALUES,
	GroupProgressCategories,
	HIDDEN_FEATURES,
	INVOICE_STATUS,
	LONGEST_MAGIC_NUMBER,
	MAX_PREVIEW_FILE_SIZE,
	MAX_SUBTASK_DEPTH,
	MAX_TOTAL_PREVIEW_SIZE,
	MODULE_TYPES,
	PASSWORD_VALIDATION_ERROR,
	PAYMENT_STATUS,
	PERIOD_BUDGET_TYPE,
	PersonAllocations,
	PROJECT_STATUS,
	ProjectAllocations,
	ProjectWinChance,
	QBO_STATUS,
	SAGE_INTACCT_STATUS,
	STRING_COMPARE,
	TIERS,
	WorkflowCategories,
	XERO_STATUS,
} from '../../../constants';
import {dispatch, EVENT_ID} from '../../../containers/event_manager';
import * as tracking from '../../../tracking';
import CreateFileMutation from '../../../mutations/create_file_mutation';
import {createToast} from '../components/toasts/another-toast/toaster';
import ical from 'ical-generator';
import UpdateTaskMutationModern from '../../../mutations/update_task_mutation.modern';
import Warning from '../../../components/warning';
import CheckMarkIcon from '../../../images/integrations/done_checkmark_icon';
import AttentionIcon from '../../../images/integrations/attention_icon';
import DeclinedIcon from '../../../images/integrations/declined_icon';
import socket_handling from '../../../socket/socket_handling';
import RelayEnvironment from '../../../RelayEnvironment';
import 'unorm'; //IE polyfill for normalize
import {
	areItemDatesValid,
	createCanvasTimelineDate,
	DAY_NAMES,
	getCompanyDefaultWorkingDays,
} from '../../../components/canvas-scheduling/canvas-timeline/canvas_timeline_util';
import {BlockedIcon, BugIcon, NotBillableIcon, PriorityIcon, StarIcon, WarningIcon} from 'web-components';
import {hasModule} from './ModuleUtil';
import {hasPermission, hasSomePermission, isClientUser} from './PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import {shouldShowTaskOverrun} from './PredictionUtil';
import {hasFeatureFlag} from './FeatureUtil';
import {remapOptionTranslationIds} from './FinancialInternationalisationUtil';
import DirectApi from '../../../directApi';
import {getCustomFieldColumnName} from '../../project-tab/projects/scoping-page/ProjectScopingUtil';
import {trackCSVExport, trackEvent} from '../../../tracking/amplitude/TrackingV2';
import {getCachedMessage} from '../../../translations/TranslationCache';
import {
	getRemainingAvailabilityTooltip,
	getTotalAvailabilityTooltip,
} from '../../project-tab/projects/scoping-page/ResourceUtil';
import {ForecastTooltipFormulaRenderer} from '../../../components/ForecastTooltipFormulaRenderer';
import ProgramUtil from './ProgramUtil';
import {ValueSource} from '../../../containers/project/project_settings/financials/ValueCalculationsComponent';
import StoreCustomMetricMutation from '../../../mutations/ts/StoreCustomMetricMutation';
import ProjectUtil from './project_util';
import {getProjectIndicatorString} from '../components/project-indicator/support/ProjectIndicatorLogic';
import {pathIncludesTask, removeTaskLinkFromUrl} from './UrlUtil';
import RoundingUtility, {RoundingEntities} from './RoundingUtil';

export default class Util {
	static getTiersMessage(tier) {
		switch (tier) {
			case TIERS.TRIAL:
				return 'TRIAL';
			case TIERS.LITE:
				return 'LITE';
			case TIERS.PRO:
				return 'PRO';
			case TIERS.CORE:
				return 'CORE';
			case TIERS.ENTERPRISE:
				return 'PLUS';
			case TIERS.FREE_FOREVER:
				return 'FREE FOREVER';
			default:
				break;
		}
	}

	// FIXME: move all date functions to DateUtil.ts - some already exist there as duplicates
	static CreateMomentDate(year, month, day) {
		if (year && month && day) {
			return moment.utc({y: year, M: month - 1, d: day});
		}
		return null;
	}

	static CreateDate(year, month, day) {
		if (year && month && day) {
			return new Date(year, month - 1, day);
		}
		return null;
	}

	static CreateNonUtcMomentDate(year, month, day) {
		if (year && month && day) return moment({y: year, M: month - 1, d: day});
		return null;
	}

	static CreateNonUtcMomentDateFromString(date) {
		if (date) return moment(date);
		return null;
	}

	static CreateUtcMomentDateFromString(date) {
		if (date) return moment.utc(date);
		return null;
	}

	static GetYearMonthDateFromMomentDate(date) {
		return {
			year: date ? date.year() : null,
			month: date ? date.month() + 1 : null,
			day: date ? date.date() : null,
		};
	}

	static GetShortYearMomentFormat(format) {
		const formatLocale = moment.localeData().longDateFormat(format);
		return formatLocale.replace(/YYYY/g, "'YY");
	}

	static getPathIsTaskModal() {
		const pathArr = window.location.href.split('/');
		const regex = new RegExp('^T\\d+$');
		const pathIsTaskModal = regex.test(pathArr[pathArr.length - 1]);
		return pathIsTaskModal;
	}

	static getHarvestStatusIcon(timeReg, intl) {
		if (timeReg.harvestTask || timeReg.harvestTaskIdInt) {
			if (timeReg.harvestTimeId) {
				return (
					<CheckMarkIcon
						className="harvest-sync-icon"
						title={intl.formatMessage({id: 'task_modal.harvest_registration_synced'})}
					/>
				);
			} else if (timeReg.harvestError) {
				return <DeclinedIcon className="harvest-sync-icon" title={timeReg.harvestError} />;
			} else {
				return (
					<AttentionIcon
						className="harvest-sync-icon"
						title={intl.formatMessage({id: 'task_modal.harvest_registration_not_synced'})}
					/>
				);
			}
		} else {
			return (
				<DeclinedIcon
					className="harvest-sync-icon"
					title={intl.formatMessage({id: 'task_modal.harvest_registration_unable_sync'})}
				/>
			);
		}
	}

	static weekdaysBetween(d1, d2, isoWeekday) {
		// ensure we have valid moment instances
		// DL: Removed for performance reasons.
		//d1 = Moment(d1).utc();
		//d2 = Moment(d2).utc();
		// figure out how many days to advance to get to the next
		// specified weekday (might be 0 if d1 is already the
		// specified weekday).
		const daysToAdd = (7 + isoWeekday - d1.isoWeekday()) % 7;
		const nextTuesday = d1.clone().add(daysToAdd, 'days');
		// if we are already passed the end date, there must not
		// be any of that day in the given period.
		if (nextTuesday.isAfter(d2)) {
			return 0;
		}
		// otherwise, just return the whole number of weeks
		// difference plus one for the day we already advanced to
		const weeksBetween = d2.diff(nextTuesday, 'weeks');
		return weeksBetween + 1;
	}

	// function that returns an array with all the days between two dates (including the start and end) in a moment format
	static getMomentDaysBetweenDates = (startDate, endDate) => {
		let dates = [];
		let currentDate = startDate.clone();

		while (!currentDate.isSameOrAfter(endDate, 'day')) {
			dates.push(moment(currentDate));
			currentDate = currentDate.add(1, 'day');
		}
		// add the last date to the array
		dates.push(moment(endDate));

		return dates;
	};

	static isoDayToDayString(isoDay) {
		switch (isoDay) {
			case 1:
				return 'monday';
			case 2:
				return 'tuesday';
			case 3:
				return 'wednesday';
			case 4:
				return 'thursday';
			case 5:
				return 'friday';
			case 6:
				return 'saturday';
			case 7:
				return 'sunday';
			default:
				return '';
		}
	}

	static upperCaseFirst(string) {
		if (string && string[0]) {
			return string[0].toUpperCase() + string.slice(1).toLowerCase();
		}
	}

	static prettifyNotificationType(type, intl) {
		const fmsg = id => intl.formatMessage({id: id});
		return fmsg('notification.' + type.toLowerCase() + '_text');
	}

	static getNotificationFlagFromType(type) {
		let flag = 'notifyInAppOn';
		switch (type) {
			case 'PROJECT_ASSIGN_PERSON':
				flag += 'AssignedProject';
				break;
			case 'TASK_PERSON_ASSIGNED':
			case 'TASK_PERSON_UNASSIGNED':
				flag += 'AssignedTask';
				break;
			case 'TASK_DESCRIPTION_MENTION':
			case 'SUB_TASK_DESCRIPTION_MENTION':
			case 'PROJECT_DESCRIPTION_MENTION':
			case 'TASK_COMMENT_MENTION':
			case 'MENTION':
				flag += 'Mention';
				break;
			case 'PROJECT_UPCOMING_DEADLINE':
				flag += 'ProjectDeadline';
				break;
			case 'PROJECT_UPDATE_STATUS':
				flag += 'ProjectStatusChange';
				break;
			case 'TASK_BLOCKED_UNMARK':
			case 'TASK_BLOCKED_MARK':
				flag += 'TaskBlockedChange';
				break;
			case 'TASK_BUG_UNMARK':
			case 'TASK_BUG_MARK':
				flag += 'TaskBugChange';
				break;
			case 'TASK_COMMENT_UPDATE':
			case 'TASK_COMMENT_ADD':
				flag += 'TaskCommentChange';
				break;
			case 'TASK_UPCOMING_DEADLINE':
				flag += 'TaskDeadline';
				break;
			case 'TASK_UPDATE_DESCRIPTION':
				flag += 'TaskDescriptionChange';
				break;
			case 'TASK_DEADLINE_REMOVE':
			case 'TASK_DEADLINE_UPDATE':
			case 'TASK_DEADLINE_ADD':
				flag += 'TaskEndDateChange';
				break;
			case 'TASK_ESTIMATE_UPDATE':
				flag += 'TaskEstimateChange';
				break;
			case 'TASK_FILE_ADD':
				flag += 'TaskFileChange';
				break;
			case 'TASK_CHANGE_SCOPE_GROUP':
				flag += 'TaskPhaseChange';
				break;
			case 'PROJECT_CHANGE':
				flag += 'TaskProjectChange';
				break;
			case 'TASK_REPEAT_REMOVE':
			case 'TASK_REPEAT_ADD':
				flag += 'TaskRepeatingChange';
				break;
			case 'TASK_CHANGE_SPRINT':
				flag += 'TaskSprintChange';
				break;
			case 'TASK_START_DATE_UPDATE':
			case 'TASK_START_DATE_REMOVE':
			case 'TASK_START_DATE_ADD':
				flag += 'TaskStartDateChange';
				break;
			case 'TASK_CHANGE_STATUS_COLUMN':
				flag += 'TaskStatusColumnChange';
				break;
			case 'TASK_TODO_OR_SUB_TASK_RENAME':
			case 'TASK_TODO_OR_SUB_TASK_UPDATE_RENAME':
			case 'TASK_TODO_OR_SUB_TASK_UPDATE_STATE':
			case 'TASK_TODO_OR_SUB_TASK_ADD':
				flag += 'TaskSubtaskChange';
				break;
			case 'TASK_TODO_OR_SUB_TASK_UPDATE_ESTIMATE':
				flag += 'TaskSubtaskEstimateChange';
				break;
			case 'TASK_UPDATE_TITLE':
				flag += 'TaskTitleChange';
				break;
			case 'NEW_PERSON_JOINED':
				flag += 'PersonJoin';
				break;
			case 'TIME_OFF_REGISTER':
			case 'TIME_OFF_SUBMIT':
				flag += 'TimeOffManager';
				break;
			case 'TIME_OFF_APPROVE':
			case 'TIME_OFF_REJECT':
			case 'TIME_OFF_ALLOCATION_CREATE':
			case 'TIME_OFF_ALLOCATION_UPDATE_DATE':
			case 'TIME_OFF_ALLOCATION_UPDATE_TYPE':
			case 'TIME_OFF_ALLOCATION_UPDATE_HOURS':
			case 'TIME_OFF_ALLOCATION_DELETE':
				flag += 'TimeOffOwner';
				break;
			case 'INVOICE_CREATED':
			case 'INVOICE_DELETED':
				flag += 'InvoiceCreatedOrDeleted';
				break;
			case 'INVOICE_STATUS_UPDATED':
				flag += 'InvoiceStatusChange';
				break;
			case 'INVOICE_DATE_UPDATED':
				flag += 'InvoiceDateChanged';
				break;
			case 'INVOICE_DUE_DATE_UPDATED':
				flag += 'InvoiceDueDateChanged';
				break;
			case 'INVOICE_PAYMENT_CREATED':
				flag += 'InvoicePayment';
				break;
			case 'INVOICE_DUE':
				flag += 'InvoiceDue';
				break;
			case 'INVOICE_DATE_REACHED':
				flag += 'InvoiceDateReached';
				break;
			case 'INVOICE_OVERDUE':
				flag += 'InvoiceOverdue';
				break;
			case 'INVOICE_DAYS_OVERDUE':
				flag += 'InvoiceDaysOverdue';
				break;
			default:
				break;
		}
		return flag;
	}

	//notification
	static getNotificationContent(notification, suffix, intl) {
		const params = JSON.parse(notification.params);
		const task = 'T' + params.companyTaskId + ' ' + params.taskName;
		const project = getProjectIndicatorString(params.companyProjectId, params.customProjectId) + ' ' + params.projectName;
		const action = notification.publisherAction;
		const publisher =
			notification.publisher && notification.publisher.node
				? notification.publisher.node.fullName
				: params.publisherName
				? params.publisherName
				: intl.formatMessage({id: 'notifications.a_deleted_user'});
		const oldValue = params.oldValue;
		const newValue = params.newValue;
		const value = params.value;
		const valueAsDate = value => {
			if (value && value.length > 0) {
				const valueArr = value.split('-');
				if (valueArr.length === 3) {
					const yyyy = valueArr[0];
					const mm = valueArr[1];
					const dd = valueArr[2];
					if (yyyy && mm && dd) {
						return Util.CreateNonUtcMomentDate(yyyy, mm, dd).format('LL');
					}
				}
			}
			return value;
		};
		switch (action) {
			// TASK UPDATES
			case 'TASK_ESTIMATE_UPDATE':
				const oldVal =
					suffix !== 'p'
						? Util.convertMinutesToFullHour(oldValue, intl)
						: Math.round((oldValue / 60) * 10) / 10 + suffix;
				const newVal =
					suffix !== 'p'
						? Util.convertMinutesToFullHour(newValue, intl)
						: Math.round((newValue / 60) * 10) / 10 + suffix;

				return {
					id: 'notification.update_task_estimate',
					values: {publisher: publisher, task: task, oldVal, newVal, project: project},
				};
			case 'TASK_UPDATE_TITLE':
				// We need the old value of the title for the task here
				return {
					id: 'notification.update_task_title',
					values: {
						publisher: publisher,
						task: 'T' + params.companyTaskId + ' ' + oldValue,
						oldVal: oldValue,
						newVal: newValue,
					},
					head: 'Title Updated',
				};
			case 'TASK_UPDATE_DESCRIPTION':
				return {
					id: 'notification.update_task_description',
					values: {publisher: publisher, task: task},
					head: 'Description Updated',
				};
			case 'TASK_DESCRIPTION_MENTION':
				return {
					id: 'notification.task_description_mention',
					values: {publisher: publisher, task: task},
					head: 'Mentioned in:',
				};
			case 'SUB_TASK_DESCRIPTION_MENTION':
				return {
					id: 'notification.sub_task_description_mention',
					values: {publisher: publisher, task: task, subTask: params.subTaskName},
					head: 'Mentioned in:',
				};
			case 'PROJECT_DESCRIPTION_MENTION':
				return {
					id: 'notification.project_description_mention',
					values: {publisher: publisher, project: project},
					head: 'Mentioned in:',
				};
			case 'TASK_COMMENT_MENTION':
				return {
					id: 'notification.task_comment_mention',
					values: {publisher: publisher, task: task},
					head: 'Mentioned in:',
				};
			case 'TASK_PERSON_ASSIGNED':
				return {
					id: 'notification.task_person_assigned',
					values: {publisher: publisher, task: task},
					head: 'Assigned to:',
				};
			case 'TASK_PERSON_UNASSIGNED':
				return {
					id: 'notification.task_person_unassigned',
					values: {publisher: publisher, task: task},
					head: 'Unassigned from:',
				};
			case 'TASK_CREATE':
				return {
					id: 'notification.task_create',
					values: {publisher: publisher, task: task},
					head: 'Created:',
				};
			case 'TASK_CHANGE_SCOPE_GROUP':
				return {
					id: 'notification.task_phase_update',
					values: {
						publisher: publisher,
						task: task,
						value: params.newPhaseName ? params.newPhaseName : 'no-phase',
					},
					head: `Status: ${params.newPhaseName ? params.newPhaseName : 'no-phase'}`,
				};
			case 'TASK_CHANGE_STATUS_COLUMN':
				return {
					id: 'notification.task_status_update',
					values: {publisher: publisher, task: task, value: params.newStatusColumnName},
					head: `Status: ${params.newStatusColumnName}`,
				};
			case 'TASK_CHANGE_SPRINT':
				return {
					id: 'notification.task_sprint_update',
					values: {
						publisher: publisher,
						task: task,
						value: params.newSprintName ? params.newSprintName : 'backlog',
					},
					head: `Added to: ${params.newSprintName ? params.newSprintName : 'backlog'}`,
				};
			case 'TASK_REPEAT_REMOVE':
				return {
					id: 'notification.task_repeat_remove',
					values: {publisher: publisher, task: task},
					head: 'Repeat Task: Stop',
				};
			case 'TASK_REPEAT_ADD':
				return {
					id: 'notification.task_repeat_add',
					values: {publisher: publisher, task: task},
					head: 'Repeat Task: Start',
				};
			case 'TASK_TODO_OR_SUB_TASK_RENAME':
			case 'TASK_TODO_OR_SUB_TASK_UPDATE_RENAME':
			case 'TASK_TODO_OR_SUB_TASK_UPDATE_STATE': {
				if (Number(params.projectTaskLevels) === 2) {
					return {
						id: 'notification.task_sub_task_update_state',
						values: {publisher: publisher, task: task, value: value},
					};
				} else
					return {
						id: 'notification.task_todo_update_state',
						values: {publisher: publisher, task: task, value: value},
					};
			}
			case 'TASK_TODO_OR_SUB_TASK_UPDATE_ESTIMATE':
				if (Number(params.projectTaskLevels) === 2) {
					return {
						id: 'notification.task_sub_task_update_estimate',
						values: {publisher: publisher, task: task, value: value},
					};
				} else
					return {
						id: 'notification.task_todo_update_estimate',
						values: {publisher: publisher, task: task, value: value},
					};
			case 'TASK_TODO_OR_SUB_TASK_ADD':
				if (Number(params.projectTaskLevels) === 2) {
					return {
						id: 'notification.task_subtask_add',
						values: {publisher: publisher, task: task, value: value},
						head: 'Subtask: Added',
					};
				} else
					return {
						id: 'notification.task_todo_add',
						values: {publisher: publisher, task: task, value: value},
						head: 'Todo: Added',
					};
			case 'PROJECT_CHANGE':
				return {
					id: 'notification.task_project_change',
					values: {
						publisher: publisher,
						task: task,
						oldProject: 'P' + params.oldCompanyProjectId + ' ' + params.oldProjectName,
						newProject: 'P' + params.newCompanyProjectId + ' ' + params.newProjectName,
					},
					head: 'Moved to:',
				};
			case 'TASK_DEADLINE_REMOVE':
			case 'TASK_DEADLINE_UPDATE':
			case 'TASK_DEADLINE_ADD':
				return {
					id: 'notification.task_deadline_add',
					values: {publisher: publisher, task: task, value: valueAsDate(newValue)},
				};
			case 'TASK_START_DATE_UPDATE':
			case 'TASK_START_DATE_REMOVE':
			case 'TASK_START_DATE_ADD':
				return {
					id: 'notification.task_start_date_add',
					values: {publisher: publisher, task: task, value: valueAsDate(newValue)},
					head: 'Start date: Updated',
				};
			case 'TASK_FILE_ADD':
				return {
					id: 'notification.task_file_add',
					values: {publisher: publisher, task: task, fileName: params.fileName},
					head: 'File: Added',
				};
			case 'TASK_COMMENT_UPDATE':
				return {
					id: 'notification.task_comment_update',
					values: {publisher: publisher, task: task},
					head: 'Comment: Updated',
				};
			case 'TASK_COMMENT_ADD':
				return {
					id: 'notification.task_comment_add',
					values: {publisher: publisher, task: task},
					head: 'Comment: Added',
				};
			case 'TASK_BLOCKED_MARK':
				return {
					id: 'notification.task_blocked_mark',
					values: {publisher: publisher, task: task},
					head: 'Marked as: Blocked',
				};
			case 'TASK_BLOCKED_UNMARK':
				return {
					id: 'notification.task_blocked_unmark',
					values: {publisher: publisher, task: task},
					head: 'Marked as: Unblocked',
				};
			case 'TASK_BUG_MARK':
				return {
					id: 'notification.task_bug_mark',
					values: {publisher: publisher, task: task},
					head: 'Marked as: Bug',
				};
			case 'TASK_BUG_UNMARK':
				return {
					id: 'notification.task_bug_unmark',
					values: {publisher: publisher, task: task},
					head: 'Marked as: None',
				};
			case 'TASK_REPORTED_TIME_ADD': // In the future, this should never be used
				const val = Util.convertMinutesToFullHour(params.minutesRegistered, intl);
				return {
					id: 'notification.task_reported_time_add',
					values: {publisher: publisher, task: task, value: val},
					head: `Time registered: ${publisher}`,
				};
			// PROJECT UPDATES
			case 'PROJECT_UPDATE_STATUS':
				return {
					id: 'notification.project_status_update',
					values: {
						publisher: publisher,
						project: project,
						oldVal: Util.upperCaseFirst(oldValue),
						newVal: Util.upperCaseFirst(newValue),
					},
					head: `Status: ${newValue}`,
				};
			case 'PROJECT_GROUP_ASSIGN_PERSON':
			case 'PROJECT_ASSIGN_PERSON':
				return {
					id: 'notification.project_assign_person',
					values: {publisher: publisher, project: project},
					head: 'Assigned to:',
				};
			// DEADLINES
			case 'TASK_UPCOMING_DEADLINE':
				return {
					id: 'notification.task_upcoming_deadline',
					values: {publisher: publisher, task: task, project: project, numDays: params.numDays},
					head: `Due in ${params.numDays} days`,
				};
			case 'PROJECT_UPCOMING_DEADLINE':
				return {
					id: 'notification.project_upcoming_deadline',
					values: {publisher: publisher, project: project, numDays: params.numDays},
					head: `Due in ${params.numDays} days`,
				};
			// PERSON
			case 'NEW_PERSON_JOINED':
				return {
					id: 'notification.person_join',
					values: {publisher: publisher},
					head: 'New starter',
				};
			case 'INTEGRATION_WARNING':
				return {
					id: 'notification.time_reg_sync_warning',
					values: {date: params.date, publisher: publisher, system: params.system, task: task},
				};
			case 'TIME_OFF_REGISTER':
				return {
					id: 'notification.time_off_registered',
					values: {date: params.date, publisher: publisher, dateRange: params.dateRange},
					head: 'Time off: Registered',
				};
			case 'TIME_OFF_SUBMIT':
				return {
					id: 'notification.time_off_submitted',
					values: {date: params.date, publisher: publisher, dateRange: params.dateRange},
					head: 'Time off: Submitted',
				};
			case 'TIME_OFF_APPROVE':
				return {
					id: 'notification.time_off_approved',
					values: {date: params.date, publisher: publisher, dateRange: params.dateRange},
					head: 'Time off: Approved',
				};
			case 'TIME_OFF_REJECT':
				return {
					id: 'notification.time_off_rejected',
					values: {date: params.date, publisher: publisher, dateRange: params.dateRange},
					head: 'Time off: Rejected',
				};
			case 'TIME_OFF_ALLOCATION_CREATE':
				return {
					id: 'notification.time_off_allocation_created',
					values: {date: params.date, publisher: publisher, dateRange: params.dateRange},
				};
			case 'TIME_OFF_ALLOCATION_UPDATE_DATE':
				return {
					id: 'notification.time_off_allocation_date_updated',
					values: {
						date: params.date,
						publisher: publisher,
						dateRange: params.dateRange,
						oldDateRange: params.oldDateRange,
					},
					head: 'Time off: Updated',
				};
			case 'TIME_OFF_ALLOCATION_UPDATE_TYPE':
				return {
					id: 'notification.time_off_allocation_type_updated',
					values: {
						date: params.date,
						publisher: publisher,
						dateRange: params.dateRange,
						oldType: params.oldType,
						newType: params.newType,
					},
					head: 'Time off: Updated',
				};
			case 'TIME_OFF_ALLOCATION_UPDATE_HOURS':
				return {
					id: 'notification.time_off_allocation_hours_updated',
					values: {
						date: params.date,
						publisher: publisher,
						dateRange: params.dateRange,
						oldHours: params.oldHours,
						newHours: params.newHours,
					},
					head: 'Time off: Updated',
				};
			case 'TIME_OFF_ALLOCATION_DELETE':
				return {
					id: 'notification.time_off_allocation_deleted',
					values: {date: params.date, publisher: publisher, dateRange: params.dateRange},
					head: 'Time off: Deleted',
				};
			case 'INVOICE_CREATED':
				return {
					id: 'notification.invoice_created',
					values: {project: params.project, invoiceName: params.invoiceName, publisher: publisher},
					head: 'Invoice: Created',
				};
			case 'INVOICE_STATUS_UPDATED':
				return {
					id: 'notification.invoice_status_updated',
					values: {
						status: params.status,
						project: params.project,
						invoiceName: params.invoiceName,
					},
					head: 'Invoice: Updated',
				};
			case 'INVOICE_DATE_UPDATED':
				return {
					id: 'notification.invoice_date_updated',
					values: {
						invoiceDate: params.invoiceDate,
						oldInvoiceDate: params.oldInvoiceDate,
						invoiceName: params.invoiceName,
						publisher: publisher,
					},
					head: 'Invoice: Updated',
				};
			case 'INVOICE_DUE_DATE_UPDATED':
				return {
					id: 'notification.invoice_due_date_updated',
					values: {
						dueDate: params.dueDate,
						oldDueDate: params.oldDueDate,
						invoiceName: params.invoiceName,
						publisher: publisher,
					},
					head: 'Invoice: Updated',
				};
			case 'INVOICE_DELETED':
				return {
					id: 'notification.invoice_deleted',
					values: {project: params.project, invoiceName: params.invoiceName, publisher: publisher},
					head: 'Invoice: Deleted',
				};
			case 'INVOICE_PAYMENT_CREATED':
				return {
					id: 'notification.invoice_payment_created',
					values: {project: params.project, invoiceName: params.invoiceName, amount: params.amount},
					head: 'Invoice: Payment',
				};
			case 'INVOICE_DUE':
				return {
					id: 'notification.invoice_due',
					values: {project: params.project, invoiceName: params.invoiceName},
					head: 'Invoice: Due',
				};
			case 'INVOICE_DATE_REACHED':
				return {
					id: 'notification.invoice_date_reached',
					values: {project: params.project, invoiceName: params.invoiceName},
					head: 'Invoice: Date Reached',
				};
			case 'INVOICE_DAYS_OVERDUE':
				return {
					id: 'notification.invoice_days_overdue',
					values: {project: params.project, invoiceName: params.invoiceName, days: params.dueDays},
					head: 'Invoice: Days Overdue',
				};
			case 'INVOICE_OVERDUE':
				return {
					id: 'notification.invoice_overdue',
					values: {project: params.project, invoiceName: params.invoiceName},
					head: 'Invoice: Overdue',
				};
			default:
				return {
					id: 'notification.default_update_message',
					values: {
						publisher: publisher,
						action: action,
						targetObject: params.companyTaskId != null ? task : project,
					},
				};
		}
	}

	static getAllNotificationOptions() {
		return [
			'PROJECT_ASSIGN_PERSON',
			'TASK_PERSON_ASSIGNED',
			'MENTION',
			'PROJECT_UPCOMING_DEADLINE',
			'PROJECT_UPDATE_STATUS',
			'TASK_BLOCKED_UNMARK',
			'TASK_BUG_UNMARK',
			'TASK_COMMENT_ADD',
			'TASK_UPCOMING_DEADLINE',
			'TASK_UPDATE_DESCRIPTION',
			'TASK_DEADLINE_ADD',
			'TASK_ESTIMATE_UPDATE',
			'TASK_FILE_ADD',
			'TASK_CHANGE_SCOPE_GROUP',
			'PROJECT_CHANGE',
			'TASK_REPEAT_ADD',
			'TASK_CHANGE_SPRINT',
			'TASK_START_DATE_ADD',
			'TASK_CHANGE_STATUS_COLUMN',
			'TASK_TODO_OR_SUB_TASK_ADD',
			'TASK_TODO_OR_SUB_TASK_UPDATE_ESTIMATE',
			'TASK_UPDATE_TITLE',
			'NEW_PERSON_JOINED',
			'TIME_OFF_REGISTER',
			'TIME_OFF_APPROVE',
		];
	}

	//Works for getting total minutes of an allocation within a period and person's total working minutes during a period
	static getMinutesInPeriod = (entity, startDate, endDate, holidayCalendarEntryDates = null) => {
		const weekdayCountArray = [1, 2, 3, 4, 5, 6, 7].map(dayIndex => Util.weekdaysBetween(startDate, endDate, dayIndex));
		const dateEntries = holidayCalendarEntryDates.map(entry =>
			Util.CreateNonUtcMomentDate(entry.node.year, entry.node.month, entry.node.day)
		);
		let uniqueEntries = [];
		dateEntries.forEach(date => {
			if (!uniqueEntries.some(d => date.isSame(d))) {
				uniqueEntries.push(date);
			}
		});
		if (uniqueEntries) {
			for (const holidayCalendarEntryDate of uniqueEntries.filter(date => {
				return date.isSameOrAfter(startDate) && date.isSameOrBefore(endDate);
			})) {
				weekdayCountArray[holidayCalendarEntryDate.isoWeekday() - 1]--;
			}
		}
		return weekdayCountArray.reduce((total, dayCount, dayIndex) => (total += dayCount * entity[DAY_NAMES[dayIndex]]), 0);
	};

	static GetLocaleFromPersonLanguage(language) {
		if (language === 'ENGLISH_US') {
			return 'en-US';
		} else if (language === 'ENGLISH_UK') {
			return 'en-GB';
		} else if (language === 'ENGLISH_EU') {
			return 'en-150';
		} else if (language === 'FRENCH') {
			return 'fr';
		} else if (language === 'SPANISH') {
			return 'es';
		} else if (language === 'CHINESE') {
			return 'zh';
		} else if (language === 'DANISH') {
			return 'da';
		} else if (language === 'POLISH') {
			return 'pl';
		}
	}

	static AuthorizeViewerAccess(route, isProjectOwner) {
		switch (route) {
			case 'program':
				return (
					hasModule(MODULE_TYPES.PROGRAMS) &&
					(hasSomePermission([
						PERMISSION_TYPE.PROGRAMS_CREATE,
						PERMISSION_TYPE.PROGRAMS_UPDATE,
						PERMISSION_TYPE.PROGRAMS_DELETE,
					]) ||
						hasPermission(PERMISSION_TYPE.PROJECTS_READ_ALL))
				);
			case 'project-faster-workflow':
			case 'projects':
			case 'my-profile':
			case 'project-workflow':
				return true;
			case 'project-sprint':
			case 'project-files':
			case 'project-client':
			case 'scheduling':
			case 'project-team':
			case 'timesheets':
			case 'old-time':
			case 'project-phases': {
				return !isClientUser();
			}
			case 'project-timeline': {
				return isClientUser() || hasPermission(PERMISSION_TYPE.PROJECTS_CREATE);
			}
			case 'team-time':
				return hasSomePermission([PERMISSION_TYPE.TIME_REGISTRATION_UPDATE_ALL, PERMISSION_TYPE.MANAGE_TIME_OFF]);
			case 'insights':
			case 'time-report':
			case 'utilization-report':
			case 'task-report':
			case 'new-report':
				return hasPermission(PERMISSION_TYPE.INSIGHTS_READ);
			case 'project-health':
				return hasPermission(PERMISSION_TYPE.INSIGHTS_READ) && hasPermission(PERMISSION_TYPE.PROJECTS_UPDATE);
			case 'connected-projects':
			case 'project-settings':
			case 'group-settings':
				return hasPermission(PERMISSION_TYPE.PROJECTS_UPDATE);
			case 'project-budget':
			case 'project-initial-plan':
				return hasSomePermission([
					PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION,
					PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE,
				]);
			case 'unit4-log':
				return (
					hasSomePermission([
						PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION,
						PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE,
					]) && hasFeatureFlag('unit4_error_log')
				);
			case 'reports':
				return hasPermission(PERMISSION_TYPE.INSIGHTS_READ);
			case 'project-portfolio-report':
				return (
					hasSomePermission([
						PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION,
						PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE,
					]) && hasPermission(PERMISSION_TYPE.INSIGHTS_READ)
				);
			case 'project-invoicing':
			case 'invoicing':
				return hasPermission(PERMISSION_TYPE.INVOICE_READ);
			case 'project-periods':
				return hasPermission(PERMISSION_TYPE.RETAINER_PERIOD_READ);
			case 'time-approval': {
				return (
					hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS) ||
					isProjectOwner ||
					hasPermission(PERMISSION_TYPE.MANAGE_TIME_OFF)
				);
			}
			case 'settings': {
				return hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS);
			}
			case 'sisense': {
				return hasPermission(PERMISSION_TYPE.SISENSE_READ);
			}
			case 'expense-management':
				return (
					CompanySetupUtil.hasFinance() &&
					hasFeatureFlag('expense_management_page') &&
					hasPermission(PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE)
				);
			case 'baseline': {
				return this.hasBaselineModule();
			}

			default: {
				return false;
			}
		}
	}

	static getFormattedPercentage(percentage, intl, numberDecimals = 0) {
		if (percentage === undefined) {
			percentage = 0;
		}
		return intl.formatNumber(percentage, {
			style: 'percent',
			minimumFractionDigits: numberDecimals,
			maximumFractionDigits: numberDecimals,
		});
	}

	static getInvoiceTotals(expenses) {
		return expenses.reduce(
			(acc, expense) => {
				const standardPrice = Util.roundToNDecimals(expense.unitPrice * expense.quantity, 2);
				const discount = Util.roundToNDecimals((expense.discount / 100) * standardPrice, 2);
				const grossAmount = standardPrice - discount;
				const tax = Util.roundToNDecimals((expense.tax / 100) * grossAmount, 2);
				const netAmount = grossAmount + tax;

				return {
					subtotal: acc.subtotal + standardPrice,
					tax: acc.tax + tax,
					discount: acc.discount + discount,
					total: acc.total + netAmount,
				};
			},
			{subtotal: 0, tax: 0, discount: 0, total: 0}
		);
	}

	/**
	 *Get a simple progress value from 0% to 100% based om estimate and timeLeft
	 */
	static getSimpleProgressPercentageFromValues(estimate, remaining) {
		//return 0% if estimate isn't set
		if (estimate === 0) return 0;
		//return 0% if remaining is bigger then estimate
		if (remaining > estimate) return 0;
		let diff = estimate - remaining;
		let progress = Math.round((diff / estimate) * 100);
		// Clamp value to between 0 and 100%
		if (progress < 0) return 0;
		if (progress > 100) return 100;
		return progress;
	}

	//compare the value with 0.005 to prevent math.round to return -0
	static getFormattedCurrencyNumber(number, intl) {
		return intl.formatNumber(Math.round(number > -0.005 && number < 0 ? 0 : number * 100) / 100, {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		});
	}

	static roundFloatToTwoDecimals(number) {
		return Math.round(number * 100) / 100;
	}

	static formatLocalDecimals(number, language, decimalPlaces) {
		let locale;

		switch (language) {
			case 'ENGLISH_EU':
				locale = 'da-DK';
				break;
			case 'DANISH':
				locale = 'da-DK';
				break;
			case 'FRENCH':
				locale = 'fr-FR';
				break;
			case 'SPANISH':
				locale = 'es-ES';
				break;
			default:
				locale = 'en-GB';
		}

		return number.toLocaleString(locale, {
			minimumFractionDigits: decimalPlaces,
			maximumFractionDigits: decimalPlaces,
		});
	}

	static getFormattedNumberWithCurrency(currencySymbol, number, intl, noSpace) {
		return this.getFormattedCurrencyValue(currencySymbol, this.getFormattedCurrencyNumber(number, intl), null, noSpace);
	}

	static getFormattedNumberWithCurrencyFromEntity(currencySymbol, entity, number, intl, noSpace) {
		return this.getFormattedCurrencyValue(
			currencySymbol,
			RoundingUtility.roundBasedOnEntityType(number, entity),
			null,
			noSpace
		);
	}

	static formatHours(hours) {
		return (
			<FormattedMessage id="common.x_hours" values={{hours: hours}}>
				{
					// Replace spaces by &nbsp
					text => <span>{text.replace(/ /g, '\u00a0')}</span>
				}
			</FormattedMessage>
		);
	}

	static getCookieFromDocument(cookieName) {
		return this.getCookie(document.cookie, cookieName);
	}

	static getCookie(cookies, cookieName) {
		let name = cookieName + '=';
		let decodedCookie = decodeURIComponent(cookies);
		let ca = decodedCookie.split(';');
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) === ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length);
			}
		}
		return '';
	}

	static GetCurrencySymbol(currency) {
		const map = new Map([
			['ARS', '$'],
			['AUD', '$'],
			['BRL', 'R$'],
			['CAD', '$'],
			['CNY', '¥'],
			['CZK', 'Kč'],
			['DKK', 'kr.'],
			['EUR', '€'],
			['HKD', '$'],
			['INR', '₹'],
			['JPY', '¥'],
			['MXN', '$'],
			['NZD', '$'],
			['NOK', 'kr.'],
			['PHP', '₱'],
			['PLN', 'zł'],
			['GBP', '£'],
			['RUB', '₽'],
			['SGD', '$'],
			['ZAR', 'R'],
			['KRW', '₩'],
			['SEK', 'kr.'],
			['CHF', 'Fr.'],
			['TRY', '₺'],
			['USD', '$'],
			['THB', '฿'],
			['TTD', '$'],
			['TZS', 'TSh'],
			['HUF', 'Ft'],
			['AED', 'د.إ'],
			['UAH', '₴'],
		]);
		return map.get(currency);
	}

	static getFormattedCurrencyValue(currencySymbol, value, showAsterisk, noSpace = false) {
		let result;
		const spacing = noSpace ? '' : ' ';
		if (this.CurrencyIsPrefixed(currencySymbol)) {
			result = currencySymbol + spacing + value;
		} else {
			result = value + spacing + currencySymbol;
		}
		if (showAsterisk) {
			result += ' *';
		}

		return result;
	}

	static GetFormattedCurrencySymbolOrEmptyValueIndicator(currency, value) {
		return value ? this.getFormattedCurrencyValue(this.GetCurrencySymbol(currency), value) : '-';
	}

	static GetFormattedCurrencySymbol(currency, value) {
		return this.getFormattedCurrencyValue(this.GetCurrencySymbol(currency), value);
	}

	static CurrencyIsPrefixed(currency) {
		return currency === '$' || currency === '€' || currency === '฿' || currency === '£';
	}

	static projectEditingDisabled(project) {
		return [PROJECT_STATUS.DONE, PROJECT_STATUS.HALTED].includes(project.status);
	}

	static getWorkFlowCategoryFilters(intl) {
		const getOptionFormattedMessage = (option, intl) => {
			switch (option) {
				case WorkflowCategories.TODO:
					return intl.formatMessage({id: 'common.to_do'});
				case WorkflowCategories.INPROGRESS:
					return intl.formatMessage({id: 'common.in_progress'});
				case WorkflowCategories.DONE:
					return intl.formatMessage({id: 'project_settings.workflow-done'});
				default:
					return '';
			}
		};

		return Object.keys(WorkflowCategories).map(key => {
			return {value: key, label: getOptionFormattedMessage(key, intl)};
		});
	}

	static getgetPermissionLevelFilters(intl) {
		const {formatMessage} = intl;
		return [
			{value: 'NO_LOGIN', label: formatMessage({id: 'permissions_modal.user_type_virtual'})},
			{value: 'RESTRICTED', label: formatMessage({id: 'permissions_modal.user_type_collaborator'})},
			{value: 'NO_FINANCIAL', label: formatMessage({id: 'permissions_modal.user_type_owner'})},
			{value: 'FULL', label: formatMessage({id: 'permissions_modal.user_type_controller'})},
			{value: 'ADMIN', label: formatMessage({id: 'permissions_modal.user_type_admin'})},
		];
	}

	static getUserResourcesFilterOptions(intl) {
		const {formatMessage} = intl;
		return [
			{value: 'VIRTUAL_RESOURCE', label: formatMessage({id: 'filter_type.resource_type.virtual_resource'})},
			{value: 'ACTIVE_USER', label: formatMessage({id: 'filter_type.resource_type.active'})},
			{value: 'DEACTIVATED_USER', label: formatMessage({id: 'filter_type.resource_type.deactivated'})},
		];
	}

	static getLabelAppliedFilterOptions(intl) {
		const {formatMessage} = intl;
		return [
			{value: 'APPLIED_TO_PEOPLE', label: formatMessage({id: 'filter_type.label.applied_to_people'})},
			{value: 'APPLIED_TO_TASKS', label: formatMessage({id: 'filter_type.label.applied_to_tasks'})},
			{value: 'APPLIED_TO_PROJECTS', label: formatMessage({id: 'filter_type.label.applied_to_projects'})},
		];
	}

	static getLabelAllowedFilterOptions(intl) {
		const {formatMessage} = intl;
		return [
			{value: 'ALLOWED_ON_PEOPLE', label: formatMessage({id: 'filter_type.label.allowed_on_people'})},
			{value: 'ALLOWED_ON_TASKS', label: formatMessage({id: 'filter_type.label.allowed_on_tasks'})},
			{value: 'ALLOWED_ON_PROJECTS', label: formatMessage({id: 'filter_type.label.allowed_on_projects'})},
		];
	}

	static getGroupProgressCategoriesFilters(intl) {
		const getOptionFormattedMessage = (option, intl) => {
			switch (option) {
				case GroupProgressCategories.PAST:
					return intl.formatMessage({id: 'common.past_phases'});
				case GroupProgressCategories.ACTIVE:
					return intl.formatMessage({id: 'common.active_phases'});
				case GroupProgressCategories.FUTURE:
					return intl.formatMessage({id: 'common.future_phases'});
				case GroupProgressCategories.NONE:
					return intl.formatMessage({id: 'common.without-phase'});
				default:
					return '';
			}
		};

		return Object.keys(GroupProgressCategories).map(key => {
			return {value: key, label: getOptionFormattedMessage(key, intl)};
		});
	}

	static getSprintStatusFilters(intl) {
		const getOptionFormattedMessage = (option, intl) => {
			switch (option) {
				case GroupProgressCategories.PAST:
					return intl.formatMessage({id: 'common.past_sprints'});
				case GroupProgressCategories.ACTIVE:
					return intl.formatMessage({id: 'common.active_sprints'});
				case GroupProgressCategories.FUTURE:
					return intl.formatMessage({id: 'common.future_sprints'});
				case GroupProgressCategories.NONE:
					return intl.formatMessage({id: 'project_sprints.backlog'});
				default:
					return '';
			}
		};

		return Object.keys(GroupProgressCategories).map(key => {
			return {value: key, label: getOptionFormattedMessage(key, intl)};
		});
	}

	static getRecentUpdateFilters(intl) {
		return [
			{value: 'today', label: intl.formatMessage({id: 'common.today'})},
			{value: 'week', label: intl.formatMessage({id: 'filter_type.past_7_days'})},
			{value: 'month', label: intl.formatMessage({id: 'filter_type.past_month'})},
		];
	}

	static getClientGuestUsersFilters(intl) {
		return [
			{
				value: 'assigned_client_user',
				label: intl.formatMessage({id: 'filter_type.client_guest_users.assigned_option'}),
			},
			{
				value: 'follower_client_user',
				label: intl.formatMessage({id: 'filter_type.client_guest_users.follower_option'}),
			},
			{
				value: 'owner_client_user',
				label: intl.formatMessage({id: 'filter_type.client_guest_users.owner_option'}),
			},
		];
	}

	static isClientTaskViewRestricted(viewer) {
		return (
			hasFeatureFlag('guest_user_limited_task_access') &&
			isClientUser(viewer) &&
			hasPermission(PERMISSION_TYPE.CLIENT_RESTRICTED_TASK_VIEW)
		);
	}

	static isClientTaskActionsRestricted(viewer) {
		return (
			hasFeatureFlag('guest_user_limited_task_access') &&
			isClientUser(viewer) &&
			hasPermission(PERMISSION_TYPE.CLIENT_RESTRICTED_TASK_ACTIONS)
		);
	}

	static isMixedAllocationModeEnabled(company) {
		return hasFeatureFlag('tp_heatmap_spike') && !!company?.isUsingMixedAllocation;
	}

	static getDependenciesFilters(intl) {
		return [
			{value: 'has_dependencies', label: intl.formatMessage({id: 'common.has-dependencies'})},
			{value: 'no_dependencies', label: intl.formatMessage({id: 'common.no-dependencies'})},
		];
	}

	static getInvoiceStatusTranslation(value, intl) {
		switch (value) {
			case INVOICE_STATUS.DRAFT:
				return intl.formatMessage({id: 'filter_type.invoicing_draft'});
			case INVOICE_STATUS.APPROVED:
				return intl.formatMessage({id: 'filter_type.invoicing_approved'});
			case INVOICE_STATUS.SENT:
				return intl.formatMessage({id: 'filter_type.invoicing_sent'});
			case INVOICE_STATUS.EXPORTED:
				return intl.formatMessage({id: 'filter_type.exported'});
			case QBO_STATUS.OPEN:
				return intl.formatMessage({id: 'filter_type.open'});
			case QBO_STATUS.PAID:
				return intl.formatMessage({id: 'filter_type.paid'});
			case QBO_STATUS.VOIDED:
				return intl.formatMessage({id: 'filter_type.voided'});
			case QBO_STATUS.PARTIALLY_PAID:
				return intl.formatMessage({id: 'filter_type.partially_paid'});
			case QBO_STATUS.OVERDUE:
				return intl.formatMessage({id: 'filter_type.overdue'});
			case QBO_STATUS.DEPOSITED:
				return intl.formatMessage({id: 'filter_type.deposited'});
			case XERO_STATUS.PAID:
				return intl.formatMessage({id: 'filter_type.paid'});
			case XERO_STATUS.VOIDED:
				return intl.formatMessage({id: 'filter_type.voided'});
			case XERO_STATUS.DRAFT:
				return intl.formatMessage({id: 'filter_type.invoicing_draft'});
			case XERO_STATUS.SUBMITTED:
				return intl.formatMessage({id: 'filter_type.submitted'});
			case XERO_STATUS.DELETED:
				return intl.formatMessage({id: 'filter_type.deleted'});
			case XERO_STATUS.AUTHORISED:
				return intl.formatMessage({id: 'filter_type.authorised'});
			case ECONOMIC_STATUS.BOOKED:
				return intl.formatMessage({id: 'filter_type.booked'});
			case ECONOMIC_STATUS.DRAFT:
				return intl.formatMessage({id: 'filter_type.invoicing_draft'});
			case ECONOMIC_STATUS.NOT_DUE:
				return intl.formatMessage({id: 'filter_type.not_due'});
			case ECONOMIC_STATUS.PAID:
				return intl.formatMessage({id: 'filter_type.paid'});
			case ECONOMIC_STATUS.SENT:
				return intl.formatMessage({id: 'filter_type.invoicing_sent'});
			case ECONOMIC_STATUS.UNPAID:
				return intl.formatMessage({id: 'filter_type.unpaid'});
			case PAYMENT_STATUS.UNPAID:
				return intl.formatMessage({id: 'filter_type.unpaid'});
			case PAYMENT_STATUS.PARTIALLY_PAID:
				return intl.formatMessage({id: 'filter_type.partially_paid'});
			case PAYMENT_STATUS.PAID:
				return intl.formatMessage({id: 'filter_type.paid'});
			case PAYMENT_STATUS.OVERDUE:
				return intl.formatMessage({id: 'filter_type.overdue'});
			case SAGE_INTACCT_STATUS.POSTED:
				return intl.formatMessage({id: 'filter_type.posted'});
			case SAGE_INTACCT_STATUS.DRAFT:
				return intl.formatMessage({id: 'filter_type.invoicing_draft'});
			case SAGE_INTACCT_STATUS.NOT_DUE:
				return intl.formatMessage({id: 'filter_type.not_due'});
			case SAGE_INTACCT_STATUS.PAID:
				return intl.formatMessage({id: 'filter_type.paid'});
			case SAGE_INTACCT_STATUS.SENT:
				return intl.formatMessage({id: 'filter_type.invoicing_sent'});
			case SAGE_INTACCT_STATUS.UNPAID:
				return intl.formatMessage({id: 'filter_type.unpaid'});
			case SAGE_INTACCT_STATUS.VOIDED:
				return intl.formatMessage({id: 'filter_type.voided'});
			case SAGE_INTACCT_STATUS.PARTIALLY_PAID:
				return intl.formatMessage({id: 'filter_type.partially_paid'});
			case SAGE_INTACCT_STATUS.OVERDUE:
				return intl.formatMessage({id: 'filter_type.overdue'});
			case SAGE_INTACCT_STATUS.SUBMITTED:
				return intl.formatMessage({id: 'filter_type.submitted'});
			case SAGE_INTACCT_STATUS.DELETED:
				return intl.formatMessage({id: 'filter_type.deleted'});
			case SAGE_INTACCT_STATUS.AUTHORISED:
				return intl.formatMessage({id: 'filter_type.authorised'});
		}
	}

	static getInvoicesStatusFilters(intl) {
		const filters = [
			{value: 'DRAFT', label: Util.getInvoiceStatusTranslation(INVOICE_STATUS.DRAFT, intl)},
			{value: 'APPROVED', label: Util.getInvoiceStatusTranslation(INVOICE_STATUS.APPROVED, intl)},
			{value: 'SENT', label: Util.getInvoiceStatusTranslation(INVOICE_STATUS.SENT, intl)},
		];
		if (hasFeatureFlag('invoice_external_status')) {
			filters.push({value: 'EXPORTED', label: Util.getInvoiceStatusTranslation(INVOICE_STATUS.EXPORTED, intl)});
		}
		return filters;
	}

	static getPaymentStatusFilters(intl) {
		const filters = [
			{value: 'UNPAID', label: Util.getInvoiceStatusTranslation(PAYMENT_STATUS.UNPAID, intl)},
			{value: 'PARTIALLY_PAID', label: Util.getInvoiceStatusTranslation(PAYMENT_STATUS.PARTIALLY_PAID, intl)},
			{value: 'PAID', label: Util.getInvoiceStatusTranslation(PAYMENT_STATUS.PAID, intl)},
			{value: 'OVERDUE', label: Util.getInvoiceStatusTranslation(PAYMENT_STATUS.OVERDUE, intl)},
		];
		return filters;
	}

	static getQBOStatusFilters(intl) {
		const filters = [
			{value: QBO_STATUS.OPEN, label: Util.getInvoiceStatusTranslation(QBO_STATUS.OPEN, intl)},
			{value: QBO_STATUS.PAID, label: Util.getInvoiceStatusTranslation(QBO_STATUS.PAID, intl)},
			{value: QBO_STATUS.VOIDED, label: Util.getInvoiceStatusTranslation(QBO_STATUS.VOIDED, intl)},
			{
				value: QBO_STATUS.PARTIALLY_PAID,
				label: Util.getInvoiceStatusTranslation(QBO_STATUS.PARTIALLY_PAID, intl),
			},
			{value: QBO_STATUS.OVERDUE, label: Util.getInvoiceStatusTranslation(QBO_STATUS.OVERDUE, intl)},
			{value: QBO_STATUS.DEPOSITED, label: Util.getInvoiceStatusTranslation(QBO_STATUS.DEPOSITED, intl)},
		];
		console.log(filters);
		return filters;
	}

	static getXeroStatusFilters(intl) {
		const filters = [
			{value: XERO_STATUS.PAID, label: Util.getInvoiceStatusTranslation(XERO_STATUS.PAID, intl)},
			{value: XERO_STATUS.VOIDED, label: Util.getInvoiceStatusTranslation(XERO_STATUS.VOIDED, intl)},
			{value: XERO_STATUS.DRAFT, label: Util.getInvoiceStatusTranslation(XERO_STATUS.DRAFT, intl)},
			{value: XERO_STATUS.SUBMITTED, label: Util.getInvoiceStatusTranslation(XERO_STATUS.SUBMITTED, intl)},
			{value: XERO_STATUS.DELETED, label: Util.getInvoiceStatusTranslation(XERO_STATUS.DELETED, intl)},
			{value: XERO_STATUS.AUTHORISED, label: Util.getInvoiceStatusTranslation(XERO_STATUS.AUTHORISED, intl)},
		];
		return filters;
	}

	static getEconomicsStatusFilters(intl) {
		const filters = [
			{value: ECONOMIC_STATUS.BOOKED, label: Util.getInvoiceStatusTranslation(ECONOMIC_STATUS.BOOKED, intl)},
			{value: ECONOMIC_STATUS.DRAFT, label: Util.getInvoiceStatusTranslation(ECONOMIC_STATUS.DRAFT, intl)},
			{value: ECONOMIC_STATUS.NOT_DUE, label: Util.getInvoiceStatusTranslation(ECONOMIC_STATUS.NOT_DUE, intl)},
			{value: ECONOMIC_STATUS.PAID, label: Util.getInvoiceStatusTranslation(ECONOMIC_STATUS.PAID, intl)},
			{value: ECONOMIC_STATUS.SENT, label: Util.getInvoiceStatusTranslation(ECONOMIC_STATUS.SENT, intl)},
			{value: ECONOMIC_STATUS.UNPAID, label: Util.getInvoiceStatusTranslation(ECONOMIC_STATUS.UNPAID, intl)},
		];
		return filters;
	}

	static getSageIntacctStatusFilters(intl) {
		const filters = [
			{
				value: SAGE_INTACCT_STATUS.POSTED,
				label: Util.getInvoiceStatusTranslation(SAGE_INTACCT_STATUS.POSTED, intl),
			},
			{
				value: SAGE_INTACCT_STATUS.DRAFT,
				label: Util.getInvoiceStatusTranslation(SAGE_INTACCT_STATUS.DRAFT, intl),
			},
			{
				value: SAGE_INTACCT_STATUS.NOT_DUE,
				label: Util.getInvoiceStatusTranslation(SAGE_INTACCT_STATUS.NOT_DUE, intl),
			},
			{value: SAGE_INTACCT_STATUS.PAID, label: Util.getInvoiceStatusTranslation(SAGE_INTACCT_STATUS.PAID, intl)},
			{value: SAGE_INTACCT_STATUS.SENT, label: Util.getInvoiceStatusTranslation(SAGE_INTACCT_STATUS.SENT, intl)},
			{
				value: SAGE_INTACCT_STATUS.UNPAID,
				label: Util.getInvoiceStatusTranslation(SAGE_INTACCT_STATUS.UNPAID, intl),
			},
			{
				value: SAGE_INTACCT_STATUS.VOIDED,
				label: Util.getInvoiceStatusTranslation(SAGE_INTACCT_STATUS.VOIDED, intl),
			},
			{
				value: SAGE_INTACCT_STATUS.PARTIALLY_PAID,
				label: Util.getInvoiceStatusTranslation(SAGE_INTACCT_STATUS.PARTIALLY_PAID, intl),
			},
			{
				value: SAGE_INTACCT_STATUS.OVERDUE,
				label: Util.getInvoiceStatusTranslation(SAGE_INTACCT_STATUS.OVERDUE, intl),
			},
			{
				value: SAGE_INTACCT_STATUS.SUBMITTED,
				label: Util.getInvoiceStatusTranslation(SAGE_INTACCT_STATUS.SUBMITTED, intl),
			},
			{
				value: SAGE_INTACCT_STATUS.DELETED,
				label: Util.getInvoiceStatusTranslation(SAGE_INTACCT_STATUS.DELETED, intl),
			},
			{
				value: SAGE_INTACCT_STATUS.AUTHORISED,
				label: Util.getInvoiceStatusTranslation(SAGE_INTACCT_STATUS.AUTHORISED, intl),
			},
		];
		return filters;
	}

	static getExpenseBillingOptionsFilters(intl) {
		return [
			{
				value: EXPENSE_BILLING_OPTIONS.NON_BILLABLE,
				label: intl.formatMessage({id: 'expense_item_modal.non_billable'}),
			},
			{value: EXPENSE_BILLING_OPTIONS.BILLABLE, label: intl.formatMessage({id: 'expense_item_modal.billable'})},
			{
				value: EXPENSE_BILLING_OPTIONS.BILLABLE_AS_PART_OF_FIXED,
				label: intl.formatMessage({id: 'expense_item_modal.billable_part_of_fixed_price'}),
			},
			{
				value: EXPENSE_BILLING_OPTIONS.BILLABLE_ON_TOP_OF_FIXED,
				label: intl.formatMessage({id: 'expense_item_modal.billable_fixed_price'}),
			},
		];
	}

	static getPriorityLevelOptions(intl, priorityLevels) {
		const {formatMessage} = intl;
		const options = priorityLevels.map(priorityLevel => {
			return {value: priorityLevel.id, label: priorityLevel.name};
		});
		options.push({value: null, label: formatMessage({id: 'common.no_priority'})});
		return options;
	}

	static getPrograms(intl, programs) {
		const {formatMessage} = intl;
		const options = programs.map(program => {
			return {value: program.id, label: program.name};
		});
		options.push({
			value: null,
			label: formatMessage({id: 'common.no_program'}, {program: ProgramUtil.programText(formatMessage)}),
		});
		return options;
	}

	static getSkillAssignedFilters(intl) {
		const {formatMessage} = intl;
		return [
			{value: 'SKILLS_WITH_USERS', label: formatMessage({id: 'filter_type.assigned.skills_with_users'})},
			{value: 'SKILLS_WITHOUT_USERS', label: formatMessage({id: 'filter_type.assigned.skills_without_users'})},
		];
	}

	static getCapacityWorkloadFilters(intl) {
		return [
			{value: 'overbooked', label: intl.formatMessage({id: 'filter_option.overbooked'})},
			{value: 'underbooked', label: intl.formatMessage({id: 'filter_option.underbooked'})},
			{value: 'fullybooked', label: intl.formatMessage({id: 'filter_option.fully_booked'})},
			{value: 'booked', label: intl.formatMessage({id: 'filter_option.booked'})},
			{value: 'noworkload', label: intl.formatMessage({id: 'filter_option.no_workload'})},
		];
	}

	static getApprovedFilters(intl) {
		return [
			{value: 'approved', label: intl.formatMessage({id: 'common.approved'})},
			{value: 'not-approved', label: intl.formatMessage({id: 'common.not-approved'})},
		];
	}

	static getApprovalStatusFilters(intl) {
		return [
			{value: 'true', label: intl.formatMessage({id: 'common.approved'})},
			{value: 'false', label: intl.formatMessage({id: 'common.not-approved'})},
		];
	}

	static getOverrunFilters(isDemoProject) {
		if (shouldShowTaskOverrun(true, PERMISSION_TYPE.PHASE_UPDATE, isDemoProject)) {
			return [
				{value: 'predicted-overrun', label: 'Predicted overrun'},
				{value: 'currently-overrun', label: 'Currently overrun'},
			];
		}
		return [{value: 'currently-overrun', label: 'Currently overrun'}];
	}

	static getBillableFilters() {
		return [
			{value: '1', label: 'Billable'},
			{value: '0', label: 'Non Billable'},
		];
	}

	static getInvoicedFilters(intl) {
		return [
			{value: '1', label: intl.formatMessage({id: 'project_budget.invoiced'})},
			{value: '0', label: intl.formatMessage({id: 'project_budget.uninvoiced'})},
		];
	}

	static getSubTasksFilters(intl) {
		return [
			{value: 'has_sub_tasks', label: intl.formatMessage({id: 'filter_type.subtasks.has_subtasks_option'})},
			{value: 'no_sub_tasks', label: intl.formatMessage({id: 'filter_type.subtasks.no_subtasks_option'})},
		];
	}

	static getTaskHierarchyFilters(intl) {
		return [
			{value: 'is_sub_task', label: intl.formatMessage({id: 'filter_type.subtasks.is_subtask_option'})},
			{value: 'is_not_sub_task', label: intl.formatMessage({id: 'filter_type.subtasks.is_not_subtask_option'})},
			{value: 'has_sub_tasks', label: intl.formatMessage({id: 'filter_type.subtasks.has_subtasks_option'})},
			{value: 'no_sub_tasks', label: intl.formatMessage({id: 'filter_type.subtasks.no_subtasks_option'})},
		];
	}

	static getTaskLevelFilters(intl) {
		return [
			{
				value: FILTER_FIELD_VALUES.PARENT_TASK,
				label: intl.formatMessage({id: 'filter_type.task_level.parent_task'}),
			},
			{
				value: FILTER_FIELD_VALUES.SUB_TASK,
				label: intl.formatMessage({id: 'filter_type.task_level.subtask_task'}),
			},
		];
	}

	static getDeadlineFilters(intl) {
		return [
			{value: 'overdue', label: intl.formatMessage({id: 'common.overdue'})},
			{value: 'today', label: intl.formatMessage({id: 'common.today'})},
			{value: 'this_week', label: intl.formatMessage({id: 'filter_type.deadline.this_week_option'})},
			{value: 'next_week', label: intl.formatMessage({id: 'filter_type.deadline.next_week_option'})},
			{value: 'this_month', label: intl.formatMessage({id: 'filter_type.deadline.this_month'})},
			{value: 'next_month', label: intl.formatMessage({id: 'filter_type.deadline.next_month'})},
			{value: 'no_dates', label: intl.formatMessage({id: 'common.without-deadline'})},
		];
	}

	static getDueDateFilters(intl) {
		return [
			{value: 'this_month', label: intl.formatMessage({id: 'filter_type.due_date.this_month'})},
			{value: 'next_month', label: intl.formatMessage({id: 'filter_type.due_date.next_month'})},
			{value: 'past_month', label: intl.formatMessage({id: 'filter_type.due_date.past_month'})},
			{value: 'next_7_days', label: intl.formatMessage({id: 'filter_type.due_date.next_7_days'})},
			{value: 'next_14_days', label: intl.formatMessage({id: 'filter_type.due_date.next_14_days'})},
			{value: 'next_30_days', label: intl.formatMessage({id: 'filter_type.due_date.next_30_days'})},
			{value: 'next_60_days', label: intl.formatMessage({id: 'filter_type.due_date.next_60_days'})},
			{value: 'past_7_days', label: intl.formatMessage({id: 'filter_type.due_date.past_7_days'})},
			{value: 'past_14_days', label: intl.formatMessage({id: 'filter_type.due_date.past_14_days'})},
			{value: 'past_30_days', label: intl.formatMessage({id: 'filter_type.due_date.past_30_days'})},
			{value: 'past_60_days', label: intl.formatMessage({id: 'filter_type.due_date.past_60_days'})},
		];
	}

	static getProjectTypeFilterOptions(intl, viewer) {
		const projectTypeOptions = [
			{
				value: 'time_and_material',
				label: intl.formatMessage({id: 'new_project_modal.budget_type_time_materials'}),
			},
			{value: 'retainers', label: intl.formatMessage({id: 'common.retainers'})},
			{value: 'fixed_price', label: intl.formatMessage({id: 'common.fixed_price'})},
			{value: 'non-billable', label: intl.formatMessage({id: 'common.non-billable'})},
		];
		if (viewer && viewer.availableFeatureFlags && viewer.company && viewer.company.modules) {
			if (!hasModule(MODULE_TYPES.RETAINERS)) {
				projectTypeOptions.splice(
					projectTypeOptions.indexOf(
						projectTypeOptions.find(projectTypeOption => projectTypeOption.value === 'retainers')
					),
					1
				);
			}
		}
		return projectTypeOptions;
	}

	static getProjectWinChanceFilters(intl) {
		const getOptionFormattedMessage = (option, intl) => {
			switch (option) {
				case ProjectWinChance.ZERO:
					return intl.formatMessage({id: 'filter_type.project.win_chance.0'});
				case ProjectWinChance.OVER_10:
					return intl.formatMessage({id: 'filter_type.project.win_chance.over_10'});
				case ProjectWinChance.OVER_30:
					return intl.formatMessage({id: 'filter_type.project.win_chance.over_30'});
				case ProjectWinChance.OVER_50:
					return intl.formatMessage({id: 'filter_type.project.win_chance.over_50'});
				case ProjectWinChance.OVER_70:
					return intl.formatMessage({id: 'filter_type.project.win_chance.over_70'});
				case ProjectWinChance.OVER_90:
					return intl.formatMessage({id: 'filter_type.project.win_chance.over_90'});
				case ProjectWinChance.HUNDRED:
					return intl.formatMessage({id: 'filter_type.project.win_chance.100'});
				default:
					return '';
			}
		};

		return Object.keys(ProjectWinChance).map(key => {
			return {value: key, label: getOptionFormattedMessage(key, intl)};
		});
	}

	static getProjectAllocationsFilters(intl) {
		const getOptionFormattedMessage = (option, intl) => {
			switch (option) {
				case ProjectAllocations.CONTAINS_SOFT:
					return intl.formatMessage({id: 'filter_type.project.allocations.contains_soft'});
				case ProjectAllocations.CONTAINS_PLACEHOLDER:
					return intl.formatMessage({id: 'filter_type.project.allocations.contains_placeholder'});
				default:
					return '';
			}
		};

		return Object.keys(ProjectAllocations).map(key => {
			return {value: key, label: getOptionFormattedMessage(key, intl)};
		});
	}

	static getPersonAllocationsFilters(intl) {
		const getOptionFormattedMessage = (option, intl) => {
			switch (option) {
				case PersonAllocations.CONTAINS_SOFT:
					return intl.formatMessage({id: 'filter_type.person.allocations.contains_soft'});
				default:
					return '';
			}
		};

		return Object.keys(PersonAllocations).map(key => {
			return {value: key, label: getOptionFormattedMessage(key, intl)};
		});
	}

	static hasBaselineModule() {
		return hasModule(MODULE_TYPES.BASELINE) && this.AuthorizeViewerAccess('project-initial-plan');
	}

	static isBaselineProject(_, project) {
		return project && project.useBaseline && project.budgetType !== BUDGET_TYPE.RETAINER && this.hasBaselineModule();
	}

	static hasOpportunityAccess() {
		return hasModule(MODULE_TYPES.BASELINE);
	}

	static hasNovaInsights(company) {
		return true;
	}

	static hasStatusAugmentation(company, disableNotifications) {
		return (
			hasModule(MODULE_TYPES.NOVA_INSIGHTS) &&
			hasPermission(PERMISSION_TYPE.INSIGHTS_READ) &&
			hasPermission(PERMISSION_TYPE.PROJECTS_UPDATE) &&
			!disableNotifications &&
			(company.forecastDemo || company.tier !== TIERS.TRIAL)
		);
	}

	static getSprintDeadlineFilterOptions(intl) {
		return [
			{value: 'today', label: intl.formatMessage({id: 'common.today'})},
			{value: 'this_week', label: intl.formatMessage({id: 'filter_type.deadline.this_week_option'})},
			{
				value: 'within_five_days',
				label: intl.formatMessage({id: 'filter_type.deadline.within_days_option'}, {days: 5}),
			},
			{
				value: 'within_seven_days',
				label: intl.formatMessage({id: 'filter_type.deadline.within_days_option'}, {days: 7}),
			},
			{value: 'next_week', label: intl.formatMessage({id: 'filter_type.deadline.next_week_option'})},
			{value: 'this_month', label: intl.formatMessage({id: 'filter_type.deadline.this_month'})},
			{
				value: 'within_thirty_days',
				label: intl.formatMessage({id: 'filter_type.deadline.within_days_option'}, {days: 30}),
			},
			{value: 'next_month', label: intl.formatMessage({id: 'filter_type.deadline.next_month'})},
			{value: 'no_sprints', label: intl.formatMessage({id: 'common.no_sprints'})},
		];
	}

	static getProgressFilterOptions(intl) {
		const {formatMessage} = intl;
		return [
			{value: 0, label: '0%'},
			{value: 20, label: formatMessage({id: 'progress_filter_content_options.label_text'}, {value: 20})},
			{value: 50, label: formatMessage({id: 'progress_filter_content_options.label_text'}, {value: 50})},
			{value: 80, label: formatMessage({id: 'progress_filter_content_options.label_text'}, {value: 80})},
			{value: 90, label: formatMessage({id: 'progress_filter_content_options.label_text'}, {value: 90})},
			{value: 100, label: '100%'},
		];
	}

	static GetWarningsForTaskStartDate(task, intl) {
		if (task.startYear == null || task.deadlineYear == null || task.deadlineMonth == null || task.deadlineDay == null)
			return {showWarning: false, message: ''};
		const deadline = Util.CreateMomentDate(task.deadlineYear, task.deadlineMonth, task.deadlineDay).add(1, 'days');
		const start = Util.CreateMomentDate(task.startYear, task.startMonth, task.startDay);
		if (start.isSameOrAfter(deadline)) {
			return {
				showWarning: true,
				message: intl ? intl.formatMessage({id: 'card.start_date_after_deadline.message'}) : '',
			};
		}

		if (task.thisTaskDependsOn && task.thisTaskDependsOn.edges.length !== 0) {
			let message = '';
			task.thisTaskDependsOn.edges.forEach(dependency => {
				const dependant = dependency.node.thisDependsOnTask;
				if (
					dependant &&
					!dependant.done &&
					dependency.node.type === DependencyType.CANNOT_START &&
					dependant.startYear
				) {
					const dependantDeadline = Util.CreateMomentDate(
						dependant.deadlineYear,
						dependant.deadlineMonth,
						dependant.deadlineDay
					);
					if (start.isBefore(dependantDeadline)) {
						const prefix = message !== '' ? ', ' : '';
						message += intl
							? prefix +
							  intl.formatMessage(
									{id: 'card.dependency_card_start_date_validation_error.message'},
									{card: dependant.name}
							  )
							: '';
					}
				}
			});
			if (message !== '') {
				return {
					showWarning: true,
					message: message,
				};
			}
		}

		return {
			showWarning: false,
			message: '',
		};
	}

	static getScopingColumns(
		formatMessage,
		canViewFinancial,
		sprintsEnabled,
		viewer,
		isEstimatedInNewPoints,
		periodEnabled,
		showRevenue,
		showCost,
		showTime,
		showBillableTimeSplit
	) {
		const {company, project} = viewer;
		const {customFieldDefinitions, isUsingProjectAllocation, isUsingMixedAllocation} = company;
		const isDemoProject = project?.demo;
		const hasFinancialCategoriesUpdate = hasFeatureFlag('financial_categories_update');

		const shouldShowOverrunPrediction = shouldShowTaskOverrun(
			!isEstimatedInNewPoints,
			PERMISSION_TYPE.PHASE_UPDATE,
			isDemoProject
		);
		const availableColumns = [];
		availableColumns.push({
			checked: true,
			width: 30,
			name: 'selector',
			translationId: null,
			hide: true,
			align: 'left',
		});
		availableColumns.push({
			checked: true,
			width: 24,
			name: 'expand',
			translationId: null,
			hide: true,
			align: 'left',
		});
		availableColumns.push({
			checked: true,
			width: 65,
			name: 'task-id',
			translationId: 'project_board.card_details_selector_task_id',
			hide: false,
			align: 'left',
		});
		availableColumns.push({
			checked: true,
			width: 200,
			name: 'task-name',
			translationId: 'common.task_project',
			hide: true,
			align: 'left',
		});
		if (isUsingProjectAllocation || isUsingMixedAllocation) {
			availableColumns.push({
				checked: true,
				width: 100,
				name: 'total_availability',
				translationId: 'common.total_availability',
				hide: false,
				align: 'right',
				tooltipFunc: getTotalAvailabilityTooltip,
			});
			availableColumns.push({
				checked: true,
				width: 100,
				name: 'remaining_availability',
				translationId: 'common.remaining_availability',
				hide: false,
				align: 'right',
				tooltipFunc: getRemainingAvailabilityTooltip,
			});
		}
		availableColumns.push({
			checked: true,
			width: 211,
			name: 'date',
			translationId: 'common.dates',
			hide: false,
			align: 'center',
		});
		if (customFieldDefinitions) {
			customFieldDefinitions.edges
				.map(edge => edge.node)
				.filter(definition => ['TASK', 'PHASE'].includes(definition.entityType))
				.forEach(definition => {
					availableColumns.push({
						checked: true,
						width: 120,
						name: getCustomFieldColumnName(definition.entityType, definition.key),
						displayName:
							definition.displayName +
							' (' +
							formatMessage({id: 'settings.custom-fields.entity-type.' + definition.entityType}) +
							')',
						customFieldKey: definition.key,
						entityType: definition.entityType,
						readOnly: !!definition.readOnly,
						hide: false,
						align: 'left',
					});
				});
		}
		availableColumns.push({
			checked: true,
			width: 166,
			name: 'assigned-role',
			translationId: 'common.role',
			hide: false,
			align: 'left',
		});
		availableColumns.push({
			checked: true,
			width: 166,
			name: 'assigned-person',
			translationId: 'common.assignees',
			hide: false,
			align: 'left',
		});
		availableColumns.push({
			checked: true,
			width: 150,
			name: 'status',
			translationId: 'common.status',
			hide: false,
			align: 'left',
		});
		availableColumns.push({
			checked: true,
			width: 150,
			name: 'phase',
			translationId: 'common.phase',
			hide: false,
			align: 'left',
		});

		sprintsEnabled &&
			availableColumns.push({
				checked: true,
				width: 150,
				name: 'sprint',
				translationId: 'common.sprint',
				hide: false,
				align: 'left',
			});
		availableColumns.push({
			checked: true,
			width: 70,
			name: 'done-percentage',
			translationId: 'common.progress.short',
			hide: false,
			align: 'right',
			tooltipFunc: formatMessage => (
				<ForecastTooltipFormulaRenderer
					items={[
						{
							title: formatMessage({id: 'common.progress.short'}),
							details: [
								formatMessage({id: 'common.estimate'}),
								'-',
								formatMessage({id: 'common.remaining'}),
								'/',
								formatMessage({id: 'common.estimate'}),
								'*',
								'100',
							],
						},
					]}
					translatedMessage={true}
				/>
			),
		});

		// Show period column if period is enabled
		periodEnabled &&
			availableColumns.push({
				checked: true,
				width: 110,
				name: 'period-target',
				translationId: 'common.period-target',
				hide: true,
				align: 'right',
			});

		availableColumns.push({
			checked: true,
			width: shouldShowOverrunPrediction ? 112 : 90,
			name: 'forecast',
			translationId: 'common.estimate',
			hide: false,
			align: 'right',
			tooltipFunc: formatMessage => formatMessage({id: 'project_section.tooltip_task_estimates'}),
		});
		availableColumns.push({
			checked: true,
			width: 90,
			name: 'time-entries',
			translationId: 'common.time_entries',
			hide: false,
			align: 'right',
			tooltipFunc: formatMessage => formatMessage({id: 'project_section.tooltip_task_time_entries'}),
		});
		if (showBillableTimeSplit && showRevenue) {
			availableColumns.push({
				checked: true,
				width: 90,
				name: 'billable-time-entries',
				translationId: 'utilization.billable_time',
				hide: false,
				align: 'right',
			});
			availableColumns.push({
				checked: true,
				width: 90,
				name: 'non-billable-time-entries',
				translationId: 'utilization.non_billable_time',
				hide: false,
				align: 'right',
			});
		}
		showTime &&
			availableColumns.push({
				checked: true,
				width: 90,
				name: 'remaining',
				translationId: 'common.remaining',
				hide: false,
				align: 'right',
				tooltipFunc: formatMessage => formatMessage({id: 'project_section.tooltip_task_time_remaining'}),
			});

		availableColumns.push({
			checked: true,
			width: 90,
			name: 'over-forecast',
			translationId: 'project_scoping.difference_forecast',
			hide: false,
			align: 'right',
			tooltipFunc: formatMessage => (
				<ForecastTooltipFormulaRenderer
					items={[
						{
							title: formatMessage({id: 'project_scoping.difference_forecast'}),
							details: [formatMessage({id: 'common.estimate'}), '-', formatMessage({id: 'common.time_entries'})],
						},
					]}
					translatedMessage={true}
				/>
			),
		});

		showRevenue &&
			canViewFinancial &&
			project.financialSourceSettings.plannedRevenue !== ValueSource.ALLOCATION &&
			!hasFeatureFlag('financial_categories_update') &&
			availableColumns.push({
				checked: true,
				width: 110,
				name: 'price',
				translationId: 'project_budget.plan_revenue',
				hide: false,
				align: 'right',
				tooltipFunc: formatMessage => (
					<ForecastTooltipFormulaRenderer
						items={[
							{
								title: formatMessage({id: 'project_budget.planned_billable_time'}),
								details: [formatMessage({id: 'common.estimate'}), '*', formatMessage({id: 'common.rate'})],
							},
						]}
						translatedMessage={true}
					/>
				),
			});

		showRevenue &&
			canViewFinancial &&
			project.financialSourceSettings.forecastRevenue !== ValueSource.ALLOCATION &&
			hasFinancialCategoriesUpdate &&
			availableColumns.push({
				checked: true,
				width: 110,
				name: 'projected-billable-value-of-service',
				translationId: 'common.projected_total_billable_value_of_service',
				hide: false,
				align: 'right',
			});

		canViewFinancial &&
			showRevenue &&
			project.financialSourceSettings.actualRevenue !== ValueSource.ALLOCATION &&
			availableColumns.push({
				checked: true,
				width: 110,
				name: 'actual-price',
				translationId: hasFinancialCategoriesUpdate
					? 'common.actual_billable_value_of_service'
					: 'project_budget.actual_billable_time',
				hide: false,
				align: 'right',
				tooltipFunc: formatMessage => (
					<ForecastTooltipFormulaRenderer
						items={[
							{
								title: formatMessage({id: 'project_budget.actual_billable_time'}),
								details: [formatMessage({id: 'common.time_entries'}), '*', formatMessage({id: 'common.rate'})],
							},
						]}
						translatedMessage={true}
					/>
				),
			});

		canViewFinancial &&
			project.financialSourceSettings.plannedCost !== ValueSource.ALLOCATION &&
			hasFeatureFlag('use_financial_service_in_scoping') &&
			!hasFeatureFlag('financial_categories_update') &&
			availableColumns.push({
				checked: false,
				width: 110,
				name: 'planned-cost',
				translationId: 'project_budget.planned_cost',
				hide: false,
				align: 'right',
			});

		canViewFinancial &&
			project.financialSourceSettings.plannedCost !== ValueSource.ALLOCATION &&
			hasFeatureFlag('use_financial_service_in_scoping') &&
			hasFinancialCategoriesUpdate &&
			availableColumns.push({
				checked: false,
				width: 110,
				name: 'projected-cost',
				translationId: 'common.projected_total_cost',
				hide: false,
				align: 'right',
			});

		canViewFinancial &&
			project.financialSourceSettings.actualCost !== ValueSource.ALLOCATION &&
			hasFeatureFlag('use_financial_service_in_scoping') &&
			availableColumns.push({
				checked: false,
				width: 110,
				name: 'actual-cost',
				translationId: hasFinancialCategoriesUpdate ? 'common.actual_cost' : 'project_budget.actual_cost_to_date',
				hide: false,
				align: 'right',
			});

		availableColumns.push({
			checked: true,
			width: 300,
			name: 'labels',
			translationId: 'common.labels',
			hide: false,
			align: 'left',
		});
		availableColumns.push({
			checked: true,
			width: 90,
			name: 'approved',
			translationId: 'common.approved',
			hide: false,
			align: 'center',
		});
		availableColumns.push({
			checked: true,
			width: 60,
			name: 'starred',
			translationId: null,
			hide: true,
			align: 'left',
		});
		availableColumns.push({
			checked: true,
			width: 20,
			name: 'context-menu',
			translationId: null,
			hide: true,
			align: 'left',
		});

		remapOptionTranslationIds(availableColumns);
		return availableColumns;
	}

	static getRetainerColumns() {
		return [
			{
				checked: true,
				minWidth: 80,
				maxWidth: 80,
				name: 'task-id',
				translationId: 'project_board.card_details_selector_task_id',
				width: null,
				retainerDefault: true,
				independant: true,
			},
			{
				checked: true,
				minWidth: 200,
				name: 'task-name',
				translationId: 'common.task_project',
				width: null,
				align: 'left',
				hide: true,
				retainerDefault: true,
			},
			{
				checked: true,
				minWidth: 150,
				maxWidth: 150,
				name: 'invoiced',
				translationId: 'common.locked_entry',
				width: null,
				align: 'center',
				retainerDefault: true,
			},
			{
				checked: true,
				minWidth: 150,
				maxWidth: 150,
				name: 'date',
				translationId: 'common.date',
				width: null,
				align: 'center',
				retainerDefault: true,
			},
			{
				checked: false,
				minWidth: 166,
				maxWidth: 166,
				name: 'harvest',
				translationId: 'card_modal.harvest_task',
				width: null,
				retainerDefault: false,
			},
			{
				checked: true,
				minWidth: 90,
				maxWidth: 90,
				name: 'time-entries',
				translationId: 'common.time_entry',
				width: null,
				align: 'right',
				retainerDefault: true,
				paddingRight: 16,
			},
			{
				checked: true,
				minWidth: 110,
				maxWidth: 110,
				name: 'price',
				translationId: 'common.price',
				width: null,
				retainerDefault: true,
				align: 'right',
				paddingRight: 16,
			},
			{
				checked: true,
				minWidth: 166,
				maxWidth: 166,
				name: 'person',
				translationId: 'common.person',
				width: null,
				retainerDefault: true,
			},
			{
				checked: true,
				minWidth: 18,
				maxWidth: 18,
				name: 'chip-right',
				translationId: null,
				hide: true,
				width: null,
				retainerDefault: true,
				align: 'center',
			},
		];
	}

	static getSprintColumns(
		isConnected,
		canViewFinancial,
		sprintsEnabled,
		isEstimatedInNewPoints,
		isFixedPrice,
		isDemoProject,
		showRevenue,
		project,
		showBillableTimeSplit
	) {
		const shouldShowOverrunPrediction = shouldShowTaskOverrun(
			!isEstimatedInNewPoints,
			PERMISSION_TYPE.SPRINT_UPDATE,
			isDemoProject
		);

		const availableColumns = [
			{
				checked: true,
				minWidth: 50,
				maxWidth: 50,
				name: 'task-id',
				translationId: 'project_board.card_details_selector_task_id',
				width: null,
				notAnElem: true,
			},
			{
				checked: true,
				minWidth: 200,
				name: 'task-name',
				translationId: 'common.task',
				width: null,
				align: 'left',
				hide: true,
				sprintDefault: true,
			},
			{
				checked: true,
				minWidth: 166,
				maxWidth: 166,
				name: 'assigned-role',
				translationId: 'common.role',
				width: null,
				sprintDefault: true,
			},
			{
				checked: true,
				minWidth: 166,
				maxWidth: 166,
				name: 'assigned-person',
				translationId: 'common.assignees',
				width: null,
				sprintDefault: true,
			},
			{
				checked: true,
				minWidth: 150,
				maxWidth: 150,
				name: 'status',
				translationId: 'common.status',
				width: null,
				sprintDefault: true,
			},
			{
				checked: true,
				minWidth: 150,
				maxWidth: 150,
				name: 'phase',
				translationId: 'common.phase',
				width: null,
				align: 'left',
			},
			...(!project || (!project.manualProgressOnPhasesEnabled && !project.manualProgressOnProjectEnabled)
				? [
						{
							checked: true,
							minWidth: isFixedPrice ? 110 : 70,
							maxWidth: isFixedPrice ? 110 : 70,
							name: 'done-percentage',
							translationId: isFixedPrice ? 'common.hour-progress.short' : 'common.progress.short',
							title: isFixedPrice ? 'common.hour-progress' : 'common.progress',
							width: null,
							align: 'right',
						},
				  ]
				: []),
			{
				checked: true,
				minWidth: shouldShowOverrunPrediction ? 112 : 90,
				maxWidth: shouldShowOverrunPrediction ? 112 : 90,
				name: 'forecast',
				translationId: 'common.estimate',
				width: null,
				align: 'right',
				sprintDefault: true,
			},
			{
				checked: true,
				minWidth: 90,
				maxWidth: 90,
				name: 'time-entries',
				translationId: 'common.time_entries',
				width: null,
				align: 'right',
				sprintDefault: false,
			},
			...(showBillableTimeSplit && showRevenue
				? [
						{
							checked: true,
							minWidth: 90,
							maxWidth: 90,
							name: 'billable-time-entries',
							translationId: 'utilization.billable_time',
							width: null,
							align: 'right',
							sprintDefault: false,
						},
						{
							checked: true,
							minWidth: 90,
							maxWidth: 90,
							name: 'non-billable-time-entries',
							translationId: 'utilization.non_billable_time',
							width: null,
							align: 'right',
							sprintDefault: false,
						},
				  ]
				: []),
			{
				checked: true,
				minWidth: 90,
				maxWidth: 90,
				marginRight: 8,
				name: 'remaining',
				translationId: 'common.remaining',
				width: null,
				align: 'right',
				sprintDefault: true,
			},
			{
				checked: true,
				minWidth: 90,
				maxWidth: 90,
				name: 'over-forecast',
				translationId: 'project_scoping.difference_forecast',
				width: null,
				align: 'right',
				multiLine: true,
			},
		];

		if (canViewFinancial && showRevenue) {
			availableColumns.push({
				checked: true,
				minWidth: 110,
				maxWidth: 110,
				name: 'price',
				translationId: 'project_budget.plan_revenue',
				width: null,
				align: 'right',
			});
			availableColumns.push({
				checked: true,
				minWidth: 110,
				maxWidth: 110,
				name: 'actual-price',
				translationId: 'project_budget.actual_billable_time',
				width: null,
				align: 'right',
			});
		}
		if (canViewFinancial && hasFeatureFlag('use_financial_service_in_scoping')) {
			availableColumns.push({
				checked: false,
				minWidth: 110,
				maxWidth: 110,
				name: 'planned-cost',
				translationId: 'project_budget.planned_cost',
				width: null,
				align: 'right',
			});
			availableColumns.push({
				checked: false,
				minWidth: 110,
				maxWidth: 110,
				name: 'actual-cost',
				translationId: 'project_budget.actual_cost_to_date',
				width: null,
				align: 'right',
			});
		}
		availableColumns.push({
			checked: true,
			minWidth: 300,
			maxWidth: 300,
			name: 'labels',
			translationId: 'common.labels',
			width: null,
			align: 'left',
			multiLine: false,
		});
		if (sprintsEnabled) {
			availableColumns.splice(
				6,
				0,
				...[
					{
						checked: true,
						minWidth: 150,
						maxWidth: 150,
						name: 'sprint',
						translationId: 'common.sprint',
						width: null,
						align: 'left',
					},
				]
			);
		}

		availableColumns.splice(
			2,
			0,
			...[
				{
					checked: true,
					minWidth: 150,
					maxWidth: 150,
					name: 'date',
					translationId: 'common.dates',
					width: null,
					align: 'center',
					sprintDefault: true,
				},
			]
		);

		availableColumns.push({
			checked: false,
			hide: true,
			minWidth: 94,
			maxWidth: 94,
			name: 'empty',
			translationId: 'common.approved',
			width: null,
			align: 'center',
			maintainSpace: true,
		});
		if (isConnected) {
			availableColumns.splice(
				2,
				0,
				...[
					{
						checked: true,
						minWidth: 90,
						maxWidth: 90,
						name: 'project-id',
						translationId: 'overview_time.card_details_selector_id',
						width: null,
					},
					{
						checked: true,
						maxWidth: 150,
						minWidth: 150,
						name: 'project-name',
						translationId: 'common.project',
						width: null,
						align: 'left',
					},
				]
			);
		}

		availableColumns.push({
			checked: true,
			minWidth: 18,
			maxWidth: 18,
			name: 'chip-right',
			translationId: null,
			hide: true,
			width: null,
			align: 'center',
			sprintDefault: true,
		});
		availableColumns.splice(
			0,
			0,
			...[
				{
					checked: true,
					minWidth: 0,
					maxWidth: 0,
					name: 'selector',
					translationId: '',
					width: null,
					align: 'left',
					hide: true,
					sprintDefault: true,
					notAnElem: true,
				},
			]
		);

		return availableColumns;
	}

	static GetWarningsForTaskDeadline(task, project, intl) {
		if (task.deadlineYear == null) return {showWarning: false, message: ''};
		const deadline = Util.CreateMomentDate(task.deadlineYear, task.deadlineMonth, task.deadlineDay).add(1, 'days');
		const sprintEnd =
			task.sprint && task.sprint.endYear
				? Util.CreateMomentDate(task.sprint.endYear, task.sprint.endMonth, task.sprint.endDay).add(1, 'days')
				: null;

		if (project && project.sprintTimeBox && sprintEnd && deadline.isAfter(sprintEnd)) {
			return {
				showWarning: true,
				message: intl ? intl.formatMessage({id: 'card.deadline_after_sprint.message'}) : '',
			};
		}
		const phaseDeadline =
			task.phase && task.phase.deadlineYear
				? Util.CreateMomentDate(task.phase.deadlineYear, task.phase.deadlineMonth, task.phase.deadlineDay).add(
						1,
						'days'
				  )
				: null;
		if (phaseDeadline && deadline.isAfter(phaseDeadline)) {
			return {
				showWarning: true,
				message: intl ? intl.formatMessage({id: 'card.deadline_after_phase.message'}) : '',
			};
		}
		const projectDeadline =
			project == null
				? null
				: project.projectEndYear
				? Util.CreateMomentDate(project.projectEndYear, project.projectEndMonth, project.projectEndDay).add(1, 'days')
				: null;
		if (projectDeadline && deadline.isAfter(projectDeadline)) {
			return {
				showWarning: true,
				message: intl ? intl.formatMessage({id: 'card.deadline_after_project.message'}) : '',
			};
		}
		if (task.thisTaskDependsOn && task.thisTaskDependsOn.edges.length !== 0) {
			let message = '';
			task.thisTaskDependsOn.edges.forEach(dependency => {
				const dependant = dependency.node.thisDependsOnTask;
				if (
					dependant &&
					!dependant.done &&
					dependency.node.type === DependencyType.CANNOT_BE_COMPLETED &&
					dependant.deadlineYear
				) {
					const dependantDeadline = Util.CreateMomentDate(
						dependant.deadlineYear,
						dependant.deadlineMonth,
						dependant.deadlineDay
					).add(1, 'days');
					if (dependantDeadline.isAfter(deadline)) {
						const prefix = message !== '' ? ', ' : '';
						message += intl
							? prefix +
							  intl.formatMessage(
									{id: 'card.dependency_card_deadline_date_validation_error.message'},
									{card: dependant.name}
							  )
							: '';
					}
				}
			});

			if (message !== '') {
				return {
					showWarning: true,
					message: message,
				};
			}
		}

		return {
			showWarning: false,
			message: '',
		};
	}

	static GetWarningsForTaskDependencies(task, intl) {
		if (
			!task.canStart &&
			(task.statusColumn || task.statusColumnV2) &&
			((task.statusColumnV2 && task.statusColumnV2.category !== WorkflowCategories.TODO) ||
				(task.statusColumn && task.statusColumn.category !== WorkflowCategories.TODO))
		) {
			return {
				message: intl ? intl.formatMessage({id: 'card.dependency_can_start_validation_error.message'}) : '',
				showWarning: true,
				color: 'red',
			};
		} else if (
			!task.canBeSetToDone &&
			((task.statusColumnV2 && task.statusColumnV2.category === WorkflowCategories.DONE) ||
				(task.statusColumn && task.statusColumn.category === WorkflowCategories.DONE))
		) {
			return {
				message: intl ? intl.formatMessage({id: 'card.dependency_can_be_set_to_validation_error.message'}) : '',
				showWarning: true,
				color: 'red',
			};
		}
		return {
			showWarning: false,
			message: '',
		};
	}

	static JSONToCSV(data) {
		const objArray = data;
		let array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
		let str = '';
		for (var i = 0; i < array.length; i++) {
			var line = '';
			let firstLoop = true;
			for (var index in array[i]) {
				if (index !== 'key' && index !== 'id') {
					if (!firstLoop) line += ',';
					if (
						typeof array[i][index] === 'string' &&
						(array[i][index].includes(',') || array[i][index].includes('\n') || array[i][index].includes('"'))
					) {
						let val = array[i][index].replace(/"/g, '""');
						// Escape chars that are used for injection
						if (['+', '-', '=', '@'].some(e => val.trim().startsWith(e))) {
							val = `'${val}`;
						}
						line += '"' + val + '"';
					} else {
						let val = array[i][index];
						// Escape chars that are used for injection
						if (val && typeof val === 'string' && ['+', '-', '=', '@'].some(e => val.trim().startsWith(e))) {
							val = `'${val}`;
						} else if (val && typeof val === 'number') {
							if (hasFeatureFlag('rate_rounding') && index === 'unitPrice') {
								val = RoundingUtility.roundBasedOnEntityType(val, RoundingEntities.RATE.name);
							} else {
								// Round numbers to two decimals
								val = Math.round(val * 100) / 100;
							}
						}
						line += val;
					}
					firstLoop = false;
				}
			}
			str += line + '\r\n';
		}
		return str;
	}

	static exportToCSV(exportData, fileName) {
		var blob = new Blob([exportData], {type: 'text/csv'});

		// Determine which approach to take for the download
		if (navigator.msSaveOrOpenBlob) {
			// Works for Internet Explorer and Microsoft Edge
			navigator.msSaveOrOpenBlob(blob, fileName);
		} else {
			// Attempt to use an alternative method
			var anchor = document.body.appendChild(document.createElement('a'));
			// If the [download] attribute is supported, try to use it
			if ('download' in anchor) {
				anchor.download = fileName;
				anchor.href = URL.createObjectURL(blob);
				anchor.click();
			}
		}
	}

	static exportTable(columns, data, name, colNames) {
		tracking.trackEvent('CSV Exported');
		trackCSVExport('Component Insight', {insightComponent: name});
		const columnNames = colNames
			? colNames
			: columns.map(col => (col.endsWith('_raw') ? col.substring(0, col.length - 4) : col));
		let exportData = columnNames + '\r\n';
		exportData += this.JSONToCSV(data);
		// Create a CSV Blob
		var blob = new Blob(['\ufeff' + exportData], {type: 'text/csv'});
		const today = moment(new Date()).format('DD/MM/YYYY');
		const fileName = name + '-' + today + '.csv';
		// Determine which approach to take for the download
		if (navigator.msSaveOrOpenBlob) {
			// Works for Internet Explorer and Microsoft Edge
			navigator.msSaveOrOpenBlob(blob, fileName);
		} else {
			// Attempt to use an alternative method
			var anchor = document.body.appendChild(document.createElement('a'));
			// If the [download] attribute is supported, try to use it
			if ('download' in anchor) {
				anchor.download = fileName;
				anchor.href = URL.createObjectURL(blob);
				anchor.click();
			}
		}
	}

	static exportTableXlsx(columns, data, name, colNames) {
		tracking.trackEvent('XLSX Exported');
		trackEvent('Component Insight', 'XLSX Downloaded', {insightComponent: name});
		const today = moment(new Date()).format('DD/MM/YYYY');
		const fileName = name + '-' + today;
		const columnNames = colNames
			? colNames
			: columns.map(col => (col.endsWith('_raw') ? col.substring(0, col.length - 4) : col));
		const sheetData = [];
		// Header
		sheetData.push(
			columnNames.map(columnName => {
				return {
					value: columnName,
					type: 'string',
				};
			})
		);
		// Data
		let array = typeof data !== 'object' ? JSON.parse(data) : data;
		for (var i = 0; i < array.length; i++) {
			const lineData = [];
			for (var index in array[i]) {
				if (index !== 'key' && index !== 'id') {
					let val = array[i][index];
					lineData.push({
						value: val,
						type: typeof val,
					});
				}
			}
			sheetData.push(lineData);
		}

		const config = {
			filename: fileName,
			sheet: {
				data: sheetData,
			},
		};

		zipcelx(config);
	}

	static getHourEstimate(estimate) {
		return Math.ceil(parseFloat(estimate) * 20) / 20;
	}

	//Just multiplying getHourEstimate by 60 like we did in multiple places seems to break the number sometimes
	//4.1 * 60 becomes 245.99999999999997 instead of 246
	static getMinuteEstimate(estimate) {
		return Math.round(Math.ceil(parseFloat(estimate) * 20) * 3);
	}

	static getWorkingDaysDuringAllocation(startDate, endDate, monday, tuesday, wednesday, thursday, friday, saturday, sunday) {
		const days_between = [1, 2, 3, 4, 5, 6, 7].map(e => Util.weekdaysBetween(startDate, endDate, e));
		const workingDaysDuringAllocation =
			(monday !== 0 ? days_between[0] : 0) +
			(tuesday !== 0 ? days_between[1] : 0) +
			(wednesday !== 0 ? days_between[2] : 0) +
			(thursday !== 0 ? days_between[3] : 0) +
			(friday !== 0 ? days_between[4] : 0) +
			(saturday !== 0 ? days_between[5] : 0) +
			(sunday !== 0 ? days_between[6] : 0);
		return workingDaysDuringAllocation;
	}

	static calculateAvilableMinutes(startDate, endDate, monday, tuesday, wednesday, thursday, friday, saturday, sunday) {
		const days_between = [1, 2, 3, 4, 5, 6, 7].map(e => Util.weekdaysBetween(startDate, endDate, e));
		let available = 0;
		available += (days_between[0] * Math.ceil((monday / 60) * 20) * 60) / 20;
		available += (days_between[1] * Math.ceil((tuesday / 60) * 20) * 60) / 20;
		available += (days_between[2] * Math.ceil((wednesday / 60) * 20) * 60) / 20;
		available += (days_between[3] * Math.ceil((thursday / 60) * 20) * 60) / 20;
		available += (days_between[4] * Math.ceil((friday / 60) * 20) * 60) / 20;
		available += (days_between[5] * Math.ceil((saturday / 60) * 20) * 60) / 20;
		available += (days_between[6] * Math.ceil((sunday / 60) * 20) * 60) / 20;
		return available;
	}

	static hexToRgb(hex) {
		if (!hex) {
			// Return random color if no color is provided
			return {
				r: 37,
				g: 150,
				b: 190,
			};
		}
		var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result
			? {
					r: parseInt(result[1], 16),
					g: parseInt(result[2], 16),
					b: parseInt(result[3], 16),
			  }
			: null;
	}

	static getProjectStatusV2ColorHex(colorName) {
		if (colorName === 'GREEN') {
			return '#00DA80';
		} else if (colorName === 'YELLOW') {
			return '#ffc92d';
		} else if (colorName === 'RED') {
			return '#f84746';
		}
		return '';
	}

	static getProjectStatusColorHex(colorName) {
		if (colorName === 'GREEN') {
			return '#33cc33';
		} else if (colorName === 'YELLOW') {
			return '#ffc107';
		} else if (colorName === 'RED') {
			return '#ff3300';
		}
		return '';
	}

	static getProjectStatusColorName(status) {
		if (status === '#33cc33') {
			return 'GREEN';
		} else if (status === '#ffc107') {
			return 'YELLOW';
		} else if (status === '#ff3300') {
			return 'RED';
		}
		return '';
	}

	static parseStatusDescription(description) {
		let text = '';
		if (description) {
			try {
				const parsedDescription = JSON.parse(description);
				text = parsedDescription.blocks ? parsedDescription.blocks.map(block => block.text).join('\n') : text;
			} catch (e) {
				text = description;
			}
		}
		return text;
	}

	static getPersonFullName(person) {
		if (person) {
			const {firstName, lastName} = person;
			return `${firstName || ''} ${lastName || ''}`;
		}

		return '';
	}

	static calculateTimelineScale(start, end) {
		const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
		const diffDays = Math.round((end.getTime() - start.getTime()) / oneDay);
		let scale = 'month';
		if (diffDays < 30) {
			scale = 'day';
		} else if (diffDays < 90) {
			scale = 'week';
		}
		return scale;
	}

	static getTimelineStartDate(startDate) {
		return new Date(startDate) || moment.utc().startOf('isoweek').toDate();
	}

	static getTimelineEndDate(endDate) {
		return new Date(endDate) || moment.utc().startOf('isoweek').add(8, 'days').startOf('day').toDate();
	}

	static recentlyUpdatedFilterValue(task, filterValues) {
		if (task.latestUiUpdateAt === null || task.latestUiUpdateAt === undefined) {
			return false;
		}
		const latestUpdate = moment(task.latestUiUpdateAt);
		if (filterValues.includes('month')) {
			const monthAgo = moment().subtract(1, 'month').startOf('day');
			return latestUpdate.startOf('day').isSameOrAfter(monthAgo);
		} else if (filterValues.includes('week')) {
			const weekAgo = moment().subtract(1, 'week').startOf('day');
			return latestUpdate.startOf('day').isSameOrAfter(weekAgo);
		} else if (filterValues.includes('today')) {
			const today = moment().startOf('day');
			return latestUpdate.startOf('day').isSameOrAfter(today);
		}
		return false;
	}

	static teammemberNotiFilterValue(notification, filterValues) {
		const publisher = notification.publisherPersonId;
		return filterValues.includes(publisher);
	}

	static actionNotiFilterValue(notification, filterValues) {
		const actionFlag = Util.getNotificationFlagFromType(notification.publisherAction);
		return filterValues.includes(actionFlag);
	}

	static projectNotiFilterValue(notification, filterValues) {
		const projectId = JSON.parse(notification.params).companyProjectId;
		return filterValues.indexOf(projectId) > -1;
	}

	static ConvertDraftJsToPlainText(draftJsContent, delimiter) {
		//Used when exporting draftJs fields to csv file
		let content = null;
		if (draftJsContent !== undefined && draftJsContent !== null && draftJsContent !== '') {
			try {
				if (draftJsContent.startsWith('<div>') || draftJsContent.startsWith('<p>')) {
					const blocksFromHTML = convertFromHTML(draftJsContent);
					content = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
				} else {
					content = convertFromRaw(JSON.parse(draftJsContent));
				}
			} catch (err) {
				content = ContentState.createFromText(draftJsContent);
			}
		}
		const editorState = EditorState.createWithContent(content);
		return editorState.getCurrentContent().getPlainText(delimiter);
	}

	static CommitSchedulingModalUpdatePromise(mutationClass, input, retry = true) {
		return new Promise(resolve => {
			Util.CommitSchedulingModalUpdate(mutationClass, input, resolve, retry);
		});
	}

	static CommitSchedulingModalUpdate(mutationClass, input, onSuccess = null, retry = true) {
		Util.localStorageDeleteFincancialMap();
		const wrappedOnSuccess = response => {
			const inputResponse = {...response, input};
			Util.dispatchScheduleEvent(inputResponse);
			if (onSuccess) {
				onSuccess(response);
			}
		};
		this.CommitMutation(mutationClass, input, wrappedOnSuccess, retry);
	}

	static CommitMutation(mutationClass, input, onSuccess, retry = true, uploadables = null, onError) {
		const onFailure = transaction => {
			const error = transaction.toString();
			const mutationNameSplit = error.split('`');
			const mutationName = mutationNameSplit[1];
			if (error.includes('No Viewer:')) {
				const correlationId = error.split('No Viewer: ')[1];
				showModal({
					type: MODAL_TYPE.MUTATION_ERROR_NON_CRITICAL,
					correlationId: correlationId,
					endpoint: mutationName,
					errorType: 'No Viewer',
				});
			} else if (error.includes('404:')) {
				const correlationId = error.split('404: ')[1];
				showModal({
					type: MODAL_TYPE.MUTATION_ERROR_NON_CRITICAL,
					correlationId: correlationId,
					endpoint: mutationName,
					errorType: '404',
				});
			} else if (
				(error.includes('is not allowed by Access-Control-Allow-Origin') ||
					error.includes('TypeError: Origin') ||
					error.includes('Failed to fetch') ||
					error.includes('TypeError: Load failed') || // Mac version of "Failed to fetch"
					error.includes('TypeError: The Internet connection appears to be offline') ||
					error.includes('TypeError: NetworkError when attempting to fetch resource') ||
					error.includes('TypeError: Nätverksanslutningen förlorades') ||
					error.includes('TypeError: The network connection was lost')) &&
				retry
			) {
				setTimeout(() => {
					this.CommitMutation(mutationClass, input, onSuccess, false);
				}, 500);
			} else {
				let correlationId;
				if (error.includes('Internal Error: ')) {
					const split = error.split('Internal Error: ');
					correlationId = split[1];
				}
				const isNetworkError =
					error.includes('Failed to fetch') ||
					error.includes('TypeError: Load failed') || // Mac version of "Failed to fetch"
					error.includes('TypeError: The Internet connection appears to be offline') ||
					error.includes('TypeError: NetworkError when attempting to fetch resource') ||
					error.includes('TypeError: Nätverksanslutningen förlorades') ||
					error.includes('TypeError: The network connection was lost');
				showModal({
					type: MODAL_TYPE.MUTATION_ERROR,
					correlationId: correlationId,
					endpoint: mutationName,
					isNetworkError,
				});
			}
		};
		dispatch(EVENT_ID.MUTATION_SENT, mutationClass?.mutationId);

		mutationClass.commit(
			RelayEnvironment.getInstance(),
			input,
			onSuccess,
			onError ? transaction => onError(transaction, onFailure) : onFailure,
			uploadables
		);
	}

	static getCsrfValue() {
		return localStorage.getItem('CSRF-TOKEN') || Util.csrf;
	}

	static setCsrfValue(csrfToken) {
		Util.localStorageSetItem('CSRF-TOKEN', csrfToken);
		Util.csrf = csrfToken; // Fallback
	}

	static getClientId() {
		return socket_handling.getClientId();
	}

	static GetTaskWarnings(task, intl, rollupInfo) {
		let warnings = [];
		const minutesRegistered = task.timeRegistrations
			? task.timeRegistrations.edges.reduce((total, timeReg) => total + timeReg.node.minutesRegistered, 0)
			: 0;
		if (task.project && task.project.estimationUnit === 'HOURS') {
			if (minutesRegistered > task.estimateForecast) {
				warnings.push({
					message: intl ? intl.formatMessage({id: 'card.over_forecast.message'}) : '',
					color: 'red',
				});
			} else if (rollupInfo && rollupInfo.rollupTimeRegistered > rollupInfo.rollupEstimate) {
				warnings.push({
					message: intl ? intl.formatMessage({id: 'card.children_over_forecast'}) : '',
					color: 'red',
				});
			}
		} else {
			if (task.project && minutesRegistered > task.estimateForecast * task.project.minutesPerEstimationPoint) {
				warnings.push({
					message: intl ? intl.formatMessage({id: 'card.over_forecast.message'}) : '',
					color: 'red',
				});
			}
		}

		const warningsDeadline = Util.GetWarningsForTaskDeadline(task, task.project, intl);
		const warningsStartdate = Util.GetWarningsForTaskStartDate(task, intl);
		if (warningsDeadline.showWarning || warningsStartdate.showWarning) {
			if (warningsStartdate.showWarning) {
				warnings.push({
					message: warningsStartdate.message,
					color: 'red',
				});
			}
			if (warningsDeadline.showWarning) {
				warnings.push({
					message: warningsDeadline.message,
					color: 'red',
				});
			}
		}
		const warningsDependencies = Util.GetWarningsForTaskDependencies(task, intl);
		if (warningsDependencies.showWarning) {
			warnings.push({
				message: warningsDependencies.message,
				color: 'red',
			});
		}

		return warnings;
	}

	static GetProjectStatusFilters() {
		return [
			{
				color: Util.getProjectStatusV2ColorHex('RED'),
				value: 'RED',
				label: 'Off track',
			},
			{
				color: Util.getProjectStatusV2ColorHex('YELLOW'),
				value: 'YELLOW',
				label: 'At risk',
			},
			{
				color: Util.getProjectStatusV2ColorHex('GREEN'),
				value: 'GREEN',
				label: 'On track',
			},
		];
	}

	static GetIndicatorFiltersV2(intl, viewer, filtered) {
		const indicators = [
			{
				value: 'blocked',
				icon: <BlockedIcon shouldShow color="#E4253B" />,
				label: intl.formatMessage({id: 'common.blocked'}),
			},
			{value: 'bug', icon: <BugIcon shouldShow color="#535353" />, label: intl.formatMessage({id: 'common.bug'})},
			{
				value: 'high-priority',
				icon: <PriorityIcon shouldShow color="#E4253B" />,
				label: intl.formatMessage({id: 'common.high_priority'}),
			},
			{value: 'starred', icon: <StarIcon active />, label: intl.formatMessage({id: 'my_tasks.starred'})},
			...(CompanySetupUtil.isFeatureHidden(HIDDEN_FEATURES.REVENUE)
				? []
				: [
						{
							value: 'non-billable',
							icon: <NotBillableIcon color="#535353" />,
							label: intl.formatMessage({id: 'common.non-billable'}),
						},
				  ]),
			{value: 'error', icon: <WarningIcon color="#E4253B" />, label: intl.formatMessage({id: 'common.critical'})},
			{
				value: 'warning',
				icon: <WarningIcon color="#FFD81E" />,
				label: intl.formatMessage({id: 'common.warning'}),
			},
		];
		if (viewer && viewer.company && viewer.company.modules) {
			if (!CompanySetupUtil.hasFinance()) {
				indicators.splice(
					indicators.findIndex(indicator => indicator.value === 'non-billable'),
					1
				);
			}
		}
		if (filtered) {
			indicators.splice(
				indicators.findIndex(indicator => indicator.value === 'dependency'),
				1
			);
			indicators.splice(
				indicators.findIndex(indicator => indicator.value === 'error'),
				2
			);
		}
		return indicators;
	}

	static validateMin8Chars(password) {
		return password && password.length >= 8;
	}

	static validateMax64Chars(password) {
		return password === null || password === undefined || password.length <= 64;
	}

	static validate1Digit(password) {
		return /[0-9]/g.test(password);
	}

	static validate1Letter(password) {
		return /[a-zA-Z]/g.test(password);
	}

	static validate3Identical(password) {
		return password && !/([\S])\1\1/g.test(password);
	}

	static getPasswordHelpText(password, confirmPassword) {
		return (
			<div className="password-help-text">
				<div className="title">
					<FormattedMessage id="user-settings.security.password_help.password_must_contain" />
				</div>
				<div className={'line' + (Util.validateMin8Chars(password) ? ' valid' : '')}>
					<div className={Util.validateMin8Chars(password) ? 'check' : 'cross'} />
					<FormattedMessage id="user-settings.security.password_help.at_least_8" />
				</div>
				<div className={'line' + (Util.validate1Digit(password) ? ' valid' : '')}>
					<div className={Util.validate1Digit(password) ? 'check' : 'cross'} />
					<FormattedMessage id="user-settings.security.password_help.one_number" />
				</div>
				<div className={'line' + (Util.validate1Letter(password) ? ' valid' : '')}>
					<div className={Util.validate1Letter(password) ? 'check' : 'cross'} />
					<FormattedMessage id="user-settings.security.password_help.one_letter" />
				</div>
				<div className={'line' + (Util.validate3Identical(password) ? ' valid' : '')}>
					<div className={Util.validate3Identical(password) ? 'check' : 'cross'} />
					<FormattedMessage id="user-settings.security.password_help.max_2_identical" />
				</div>
			</div>
		);
	}

	static getTextColor(backgroundColor) {
		if (!backgroundColor) return '#000000';
		const c = parseInt(backgroundColor.slice(1), 16);
		const r = c >> 16;
		const g = (c >> 8) & 0x00ff;
		const b = c & 0x0000ff;
		const max = Math.max(r, g, b);
		const min = Math.min(r, g, b);
		const l = (max + min) / 510;
		return l > 0.6 ? '#000000' : '#ffffff';
	}

	static getTextColorV2(backgroundColor) {
		let brightness = 255;
		const brightnessThreshold = 165;
		try {
			const c = parseInt(backgroundColor.slice(1), 16);
			const r = c >> 16;
			const g = (c >> 8) & 0x00ff;
			const b = c & 0x0000ff;

			brightness = 0.2126 * r + 0.7152 * g + 0.0722 * b;
		} catch (e) {
			// TODO: handle exception
		}
		return brightness < brightnessThreshold ? '#ffffff' : '#000000';
	}

	static getStartFrom(task, intl) {
		const {formatMessage} = intl;
		let startFrom = '';
		switch (task.startFrom) {
			case DeadlineFrom.PROJECT:
				startFrom = task.project && task.project.name ? task.project.name : formatMessage({id: 'common.untitled'});
				break;
			case DeadlineFrom.PHASE:
				startFrom = task.phase && task.phase.name ? task.phase.name : formatMessage({id: 'common.untitled'});
				break;
			case DeadlineFrom.SPRINT:
				startFrom = task.sprint && task.sprint.name ? task.sprint.name : formatMessage({id: 'common.untitled'});
				break;
			case DeadlineFrom.TASK:
				startFrom = 'parent task';
				break;
			default:
				break;
		}
		return startFrom;
	}

	static getEndFrom(task, intl) {
		const {formatMessage} = intl;
		let endFrom = '';
		switch (task.deadlineFrom) {
			case DeadlineFrom.PROJECT:
				endFrom = task.project && task.project.name ? task.project.name : formatMessage({id: 'common.untitled'});
				break;
			case DeadlineFrom.PHASE:
				endFrom = task.phase && task.phase.name ? task.phase.name : formatMessage({id: 'common.untitled'});
				break;
			case DeadlineFrom.SPRINT:
				endFrom = task.sprint && task.sprint.name ? task.sprint.name : formatMessage({id: 'common.untitled'});
				break;
			case DeadlineFrom.TASK:
				endFrom = 'parent task';
				break;
			default:
				break;
		}
		return endFrom;
	}

	// phases with no dates inherit from project start and end dates
	static setPhaseDatesFromProject(phase, project) {
		if (!phase.startYear) {
			phase.startYear = project.projectStartYear;
			phase.startMonth = project.projectStartMonth;
			phase.startDay = project.projectStartDay;
		}

		if (!phase.deadlineYear) {
			phase.deadlineYear = project.projectEndYear;
			phase.deadlineMonth = project.projectEndMonth;
			phase.deadlineDay = project.projectEndDay;
		}
	}

	static getScrollableParent(node) {
		// this function finds and returns closest DOM element with scrollbars
		const isElement = node instanceof HTMLElement;
		const overflowY = isElement && window.getComputedStyle(node).overflowY;
		const isScrollable = overflowY !== 'visible' && overflowY !== 'hidden';
		if (!node) {
			return null;
		} else if (isScrollable && node.scrollHeight >= node.clientHeight) {
			return node;
		}

		return this.getScrollableParent(node.parentNode) || document.body;
	}

	static getFilesPreviewableMap(files) {
		//The total size of the allowed previews
		let totalPreviewedSize = 0;
		if (files) {
			return files.edges
				.filter(f => f.node)
				.map(file => {
					const hasPreview = this.FileHasPreview(file.node);
					const tooBig = this.FileIsToBigForPreview(file.node);
					let canPreview = false;

					if (hasPreview && !tooBig) {
						totalPreviewedSize += file.node.size;
						canPreview = totalPreviewedSize < MAX_TOTAL_PREVIEW_SIZE;
					}

					return {
						file: file.node,
						showPreview: canPreview,
					};
				});
		} else {
			return null;
		}
	}

	static FileHasPreview(fileNode) {
		const extension = fileNode.name ? fileNode.name.toLowerCase().split('.').pop() : '';
		return (
			extension === 'gif' ||
			extension === 'png' ||
			extension === 'jpg' ||
			extension === 'jpeg' ||
			extension === 'svg' ||
			extension === 'jfif'
		);
	}

	static FileIsToBigForPreview(fileNode) {
		return fileNode && fileNode.size ? fileNode.size > MAX_PREVIEW_FILE_SIZE : true;
	}

	static FileIsImageOrVideo(fileNode) {
		const extension = fileNode.name ? fileNode.name.toLowerCase().split('.').pop() : '';
		return ['gif', 'png', 'jpg', 'jpeg', 'svg', 'jfif', 'mov', 'mp4', 'webm', 'ogg'].includes(extension);
	}

	static FileIsVideo(fileNode) {
		const extension = fileNode.name ? fileNode.name.toLowerCase().split('.').pop() : '';
		return ['mov', 'mp4', 'webm', 'ogg'].includes(extension);
	}

	static confirmFilePreviewType(file, callback) {
		return Util.confirmFileType(
			file,
			[
				FILE_DETAILS.PNG,
				FILE_DETAILS.GIF87a,
				FILE_DETAILS.GIF89a,
				FILE_DETAILS.JPG,
				FILE_DETAILS.JPEG,
				FILE_DETAILS.JFIF,
				FILE_DETAILS.SVG,
			],
			callback
		);
	}

	static confirmFileType(file, matchedTypes, callback) {
		// Get file header
		const reader = new FileReader();
		reader.onloadend = function (e) {
			let fileHeader = new Uint8Array(e.target.result).subarray(0, LONGEST_MAGIC_NUMBER);

			// For each matchedType
			for (let i = 0; i < matchedTypes.length; i++) {
				const type = matchedTypes[i];
				// Check extension & mime-type
				if (type.extension === file.name.split('.').pop().toLowerCase()) {
					if (type.mimeType === file.type) {
						// Check magic numbers

						for (let j = 0; j < type.magicNumber.length; j++) {
							if (type.magicNumber.length > fileHeader.length || type.magicNumber[j] !== fileHeader[j]) {
								// Magic number not identical, break
								break;
							} else if (j + 1 === type.magicNumber.length) {
								// Magic number identical, return true
								callback(true);
								return;
							}
						}
					}
				}
			}
			callback(false);
		};
		reader.readAsArrayBuffer(file);
	}

	static uploadFiles(files, onSuccess = () => {}, coverFile) {
		const blacklist = FILE_BLACKLIST;

		let mightSucceed = [];
		const willFail = [];

		let dbCoverFile;
		let singleSuccess = res => {
			if (res && res.createFile.error) {
				// Failed
				mightSucceed = mightSucceed.filter(file => file !== res.createFile.filename);
				willFail.push({name: res.createFile.filename});
			}
			if (coverFile && res.createFile.file.node.name === coverFile.name) dbCoverFile = res;
		};

		dispatch(EVENT_ID.SHOW_LOADER);

		let lastSuccess = res => {
			dispatch(EVENT_ID.HIDE_LOADER);

			if (res && res.createFile.error) {
				// Failed
				mightSucceed = mightSucceed.filter(file => file !== res.createFile.filename);
				willFail.push({name: res.createFile.filename});
			}

			if (coverFile) {
				singleSuccess(res);

				onSuccess(dbCoverFile);
			} else {
				onSuccess();
			}

			// Create toast
			createToast({
				duration: 5000,
				message: (
					<div>
						{mightSucceed.length > 0 ? <FormattedHTMLMessage id="file_upload.uploaded-file" /> : null}
						{mightSucceed.length > 0 ? <br /> : null}
						{mightSucceed.length > 0 ? mightSucceed.reduce((acc, curr) => acc + ', ' + curr) : null}
						{mightSucceed.length > 0 ? <br /> : null}
						{willFail.length > 0 ? <FormattedHTMLMessage id="file_upload.rejected-files" /> : null}
						{willFail.length > 0 ? <br /> : null}
						{willFail.length > 0
							? willFail.reduce(
									(acc, curr) =>
										`${curr.reason ? `${curr.name} due to: ${curr.reason}` : curr.name}${
											acc ? ', ' + acc : ''
										}`,
									''
							  )
							: null}
					</div>
				),
			});
		};

		// For each file
		for (let i = 0; i < files.length; i++) {
			const f = files[i];
			const extension = f.name.includes('.') ? f.name.split('.').pop() : '';
			let allowed = true;

			if (!f.file) {
				willFail.push({name: f.name, reason: 'Could not read file'});
				continue;
			}

			if (f.file && !f.file.size) {
				willFail.push({name: f.name, reason: 'File is empty'});
				continue;
			}

			if (f.file && f.file.size > 2147483648) {
				// >2GB
				willFail.push({name: f.name, reason: 'Files larger than 2GB are not allowed'});
				continue;
			}

			if (f.file && f.name.length > 191) {
				willFail.push({name: f.name, reason: 'File name is to long'});
				continue;
			}

			if (extension.length === 0) {
				willFail.push({name: f.name, reason: 'Folders and files without types not allowed'});
				continue;
			}

			//	Check file against blacklist
			for (let j = 0; j < blacklist.length; j++) {
				const b = blacklist[j];
				if (b.extension === extension || b.mimeType === f.mimeType) {
					allowed = false;
					willFail.push({name: f.name, reason: `Illegal file type: ${b.extension}`});
				}
			}

			if (!allowed) continue;
			mightSucceed.push(f.name);

			console.log('Uploading file: ', {
				name: f.file.name,
				webkitRelativePath: f.file.webkitRelativePath,
				lastModified: f.file.lastModified,
				size: f.file.size,
				type: f.file.type,
			});

			Util.CommitMutation(
				CreateFileMutation,
				{
					...f,
					file: undefined,
				},
				i === files.length - 1 ? lastSuccess : singleSuccess,
				true,
				{file: f.file}
			);
		}

		if (mightSucceed.length === 0) lastSuccess();
	}

	static iCalExport(options) {
		const cal = ical();
		const {startTime, endTime, title, description, organizerName, organizerEmail, attendees} = options;

		const ev = cal.createEvent({
			start: startTime,
			end: endTime,
			summary: title,
			description: description,
			organizer: {
				name: organizerName || '-',
				email: organizerEmail,
			},
		});

		for (let i = 0; i < attendees.length; i++)
			ev.createAttendee({
				type: 'individual',
				status: 'needs-action',
				rsvp: 'true',
				mailto: attendees[i],
				email: attendees[i],
				name: attendees[i].split('@')[0],
			});

		const blob = new Blob([ev._calendar.toString()], {type: 'text/ics'});

		const fileName = 'project-team-meeting.ics';

		// Determine which approach to take for the download
		if (navigator.msSaveOrOpenBlob) {
			// Works for Internet Explorer and Microsoft Edge
			navigator.msSaveOrOpenBlob(blob, fileName);
		} else {
			// Attempt to use an alternative method
			const anchor = document.body.appendChild(document.createElement('a'));
			// If the [download] attribute is supported, try to use it
			if ('download' in anchor) {
				anchor.download = fileName;
				anchor.href = URL.createObjectURL(blob);
				anchor.click();
			}
		}
		// Log with SNS
		Util.postSns('calendar', 'CREATE', 'MEETING');
	}

	static googleCalendarExport(options) {
		const {startTime, endTime, title, description, attendees} = options;
		const format = 'YYYYMMDD[T]HHmmss';

		let url = `https://calendar.google.com/calendar/r/eventedit?dates=${startTime.format(format)}/${endTime.format(
			format
		)}`;

		if (title) url += `&details=${title}`;

		if (attendees.length > 0) url += attendees.reduce((acc, curr) => acc.replace(/\+/g, '%2B') + '&add=' + curr, '');

		if (description) url += `&details=${description}`;

		url = url.slice(0, 2083);

		window.open(url);

		// Log with SNS
		Util.postSns('calendar', 'CREATE', 'MEETING');
	}

	static getFontFamily() {
		return 'neue-haas-grotesk-text';
	}

	static getIdFromBase64String(str) {
		// atob() will decode the string
		// Split will cast the string to an array with 2 elements. E.g. ['Company Id', '1']
		// We parse the second element of the array into an integer
		return parseInt(atob(str).split(':')[1]);
	}

	static getUUIdFromBase64String(str) {
		// atob() will decode the string
		// Split will cast the string to an array with 2 elements. E.g. ['Company Id', '1']
		// We parse the second element of the array into an integer
		return atob(str).split(':')[1];
	}

	static getTypeFromGlobalID(str) {
		// atob() will decode the string
		// Split will cast the string to an array with 2 elements. E.g. ['Company Id', '1']
		// We return the first part as the type
		return atob(str).split(':')[0];
	}

	static toBase64String(str) {
		// btoa() will encode the string
		return btoa(str);
	}

	static getProfilePictureWithBorder(profilePicture, borderColor, size) {
		const getSvg = () => {
			const c = Math.sqrt(3) / 2;
			const half = size / 2;
			const strokeWidth = size / 12;
			const top = strokeWidth / 2;
			const bottom = size - strokeWidth / 2;
			const xMax = half + half * c;
			const xMin = half - half * c;
			const yMax = half * 1.5;
			const yMin = half * 0.5;
			return (
				<svg version="1.1" xmlns="http://www.w3.org/2000/svg" height={size} width={size}>
					<path
						stroke={borderColor}
						strokeWidth={strokeWidth}
						fill="none"
						d={`M${half} ${top} L${xMax} ${yMin} L${xMax} ${yMax} L${half} ${bottom} L${xMin} ${yMax} L${xMin} ${yMin} Z`}
					/>
				</svg>
			);
		};
		return (
			<div className="img-wrapper">
				<div className="image-container" style={{height: size, width: size}}>
					<img src={profilePicture} height={size} alt="owner" />
				</div>
				<div className="svg-container" style={{height: size, width: size}}>
					{getSvg()}
				</div>
			</div>
		);
	}

	static getPersonsForDropdown = allPersons => {
		let personDropdownOptions = [];

		if (!allPersons) return personDropdownOptions;

		allPersons.edges.forEach(p => {
			const optionsIds = [];
			if (!p.node.systemUser && !optionsIds.includes(p.node.id) && p.node.active) {
				optionsIds.push(p.node.id);
				personDropdownOptions.push({
					value: p.node.id,
					label: p.node.firstName + ' ' + p.node.lastName,
					profilePictureId: p.node.profilePictureId,
					profilePictureDefaultId: p.node.profilePictureDefaultId,
					initials: p.node.initials,
					email: p.node.email,
				});
			}
		});
		// Sort people in the user dropdown
		personDropdownOptions.sort((a, b) => {
			const nameA = a.label.toLowerCase();
			const nameB = b.label.toLowerCase();
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}
			return 0;
		});
		return personDropdownOptions;
	};

	static clamp(number, min, max) {
		return Math.min(Math.max(number, min), max);
	}

	// Allows supplying strings that should not be sorted as an object. Exceptions will be placed last in the list in default order
	static sortAlphabetically(a, b, exceptions) {
		if (exceptions) {
			if (exceptions[a]) return 1;
			else if (exceptions[b]) return -1;
		}
		if (!a) return 1;
		if (!b) return -1;
		return a.localeCompare(b, undefined, {sensitivity: 'base'});
	}

	static calculateElementProgress(
		forecastSum,
		timeEntriesSum,
		remainingSum,
		totalTasksCount,
		doneTasksCount,
		isEstimatedInHours,
		factor,
		isSingleTaskCalculation,
		isDone
	) {
		let result = 0;
		if (isSingleTaskCalculation) {
			//this should be used only by insights otherwise use progress field on TaskType
			if (isDone) {
				result = 100;
			} else if (forecastSum <= 0 && remainingSum <= 0) {
				result = 0;
			} else if (forecastSum > 0 && remainingSum <= 0) {
				result = 100;
			} else {
				if (timeEntriesSum > 0) {
					const timeEntriesSumFormatted = isEstimatedInHours ? timeEntriesSum : timeEntriesSum / factor;
					result = (100 * timeEntriesSumFormatted) / (timeEntriesSumFormatted + remainingSum);
				} else {
					result = ((forecastSum - remainingSum) * 100) / forecastSum;
				}
			}
			if (!isDone && result > 99) {
				result = 99;
			}
		} else {
			if (forecastSum > 0) {
				if (remainingSum > 0) {
					if (timeEntriesSum > 0) {
						const timeEntriesSumFormatted = isEstimatedInHours ? timeEntriesSum : timeEntriesSum / factor;
						result = (timeEntriesSumFormatted / (timeEntriesSumFormatted + remainingSum)) * 100;
					} else {
						result = ((forecastSum - remainingSum) * 100) / forecastSum;
					}
				} else {
					result = 100;
				}
			} else {
				//calc based on total and done tasks number
				result =
					totalTasksCount !== null && totalTasksCount !== undefined && totalTasksCount > 0
						? parseInt((doneTasksCount / totalTasksCount) * 100, 10)
						: 0;
			}
			if (result > 99 && doneTasksCount !== totalTasksCount) {
				result = 99;
			}
		}
		if (result > 100) {
			result = 100;
		} else if (result < 0) {
			result = 0;
		}
		return Math.round(result);
	}

	static changeTaskStatus(task, newStatus, intl, companyId, successCallback = () => {}) {
		const value = newStatus.value;
		const category = newStatus.category;
		const newProjectGroupStatusColumnId = newStatus.projectGroupStatusColumnId;
		const currentStatusId = task.statusColumnV2 ? task.statusColumnV2.id : null;
		const currentProjectGroupStatusColumnId = task.statusColumnV2 ? task.statusColumnV2.projectGroupStatusColumnId : null;
		if (currentStatusId !== value) {
			const onSuccess = result => {
				const errors = result && result.updateTask ? result.updateTask.errors : null;
				if (errors && errors.length !== 0) {
					this.resolveTaskStatusErrors(errors, intl);
				} else {
					successCallback(result);
				}
			};
			const mutationObject = {
				ids: [task.id],
				statusColumnId: value,
				statusColumnCategory: category,
			};
			if (companyId) {
				mutationObject.companyId = companyId;
			}
			if (currentStatusId) {
				mutationObject.prevStatusColumnId = currentStatusId;
			}
			if (newProjectGroupStatusColumnId && currentProjectGroupStatusColumnId) {
				mutationObject.prevProjectGroupStatusColumnId = currentProjectGroupStatusColumnId;
				mutationObject.projectGroupStatusColumnId = newProjectGroupStatusColumnId;
			}
			Util.CommitSchedulingModalUpdate(UpdateTaskMutationModern, mutationObject, onSuccess);
		}
	}

	static showJiraSprintChangeRejected(intl) {
		showModal({
			type: MODAL_TYPE.GENERIC,
			content: (
				<div>
					<Warning messageId="common.invalid_action_modal_title" />
					<div className="warning-part-2">
						{intl.formatMessage({id: 'modal.jira.sprint_change_failed.warning_1'})}
					</div>
					<div className="warning-part-2">
						{intl.formatMessage({id: 'modal.jira.sprint_change_failed.warning_2'})}
					</div>
				</div>
			),
			className: 'default-warning-modal',
			buttons: [
				{
					text: 'OK',
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
			],
		});
	}

	static resolveTaskStatusErrors(errors, intl) {
		if (errors.includes('jira_transition_failed')) {
			showModal({
				type: MODAL_TYPE.GENERIC,
				content: (
					<div>
						<Warning messageId="common.invalid_action_modal_title" />
						<div className="warning-part-2">
							{intl.formatMessage({id: 'modal.jira.transition_failed.warning_1'})}
						</div>
						<div className="warning-part-2">
							{intl.formatMessage({id: 'modal.jira.transition_failed.warning_2'})}
						</div>
					</div>
				),
				className: 'default-warning-modal',
				buttons: [
					{
						text: 'OK',
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
				],
			});
		} else {
			showModal({
				type: MODAL_TYPE.GENERIC,
				content: (
					<div>
						<Warning messageId="common.invalid_action_modal_title" />
						<div className="warning-part-2">{intl.formatMessage({id: 'dependency.status_change_warning'})}</div>
						<div className="warning-part-2">
							{intl.formatMessage({id: 'dependency.status_change_proceed_warning'})}
						</div>
					</div>
				),
				className: 'default-warning-modal',
				buttons: [
					{
						text: intl.formatMessage({id: 'common.filter-close'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
				],
			});
		}
	}

	static calculateColorsDistance(hex1, hex2) {
		const rgb1 = this.hexToRgb(hex1),
			rgb2 = this.hexToRgb(hex2);
		if (rgb1 === null || rgb2 === null) {
			return 150;
		}
		const diffR = rgb1.r - rgb2.r;
		const diffG = rgb1.g - rgb2.g;
		const diffB = rgb1.b - rgb2.b;
		return Math.sqrt(diffR * diffR + diffG * diffG + diffB * diffB);
	}

	static getCurrentCostPeriod(costPeriods) {
		return cloneDeep(costPeriods)
			.sort((a, b) => {
				const aStartDate = Util.CreateNonUtcMomentDate(a.node.startYear, a.node.startMonth, a.node.startDay);
				const bStartDate = Util.CreateNonUtcMomentDate(b.node.startYear, b.node.startMonth, b.node.startDay);

				return aStartDate - bStartDate;
			})
			.find((periodEdge, index) => {
				const startDate = Util.CreateNonUtcMomentDate(
					periodEdge.node.startYear,
					periodEdge.node.startMonth,
					periodEdge.node.startDay
				);
				let endDate = null;
				const now = moment();

				if (costPeriods[index + 1]) {
					endDate = Util.CreateNonUtcMomentDate(
						costPeriods[index + 1].node.startYear,
						costPeriods[index + 1].node.startMonth,
						costPeriods[index + 1].node.startDay
					);
				}

				// First cost period
				// Check if it's before the end date, which means from the beginning of time until the end date
				if (index === 0) {
					if (!endDate || now.isSameOrBefore(endDate)) {
						return true;
					}
				}

				if (now.isSameOrAfter(startDate) && now.isSameOrBefore(endDate)) {
					return true;
				}

				// Last cost period
				if (!endDate) {
					if (now.isSameOrAfter(startDate)) {
						return true;
					}
				}

				return false;
			});
	}

	// Explained here: https://codepen.io/grok/pen/LvOQbW?editors=0010
	static isNumber(value) {
		return typeof value === 'number' && value === Number(value) && Number.isFinite(value);
	}

	//Task Activity
	static getTaskActivityMessage(eventType, messageParams, params, taskLevelSuffix, intl) {
		const {formatMessage, formatDate} = intl;
		if (eventType.startsWith('TODO_OR_SUB_TASK')) {
			eventType += taskLevelSuffix;
		}
		switch (eventType) {
			case 'CARD_UPDATE_TITLE':
				messageParams.oldValue = params.oldValue ? params.oldValue : formatMessage({id: 'task_modal.untitled'});
				break;
			case 'ESTIMATE_UPDATE':
				if (params.estimationUnit === 'HOURS') {
					messageParams.oldValue = Util.convertMinutesToFullHour(params.oldValue, intl);
					messageParams.newValue = Util.convertMinutesToFullHour(params.newValue, intl);
					return <div>{formatMessage({id: 'task_activity_log.ESTIMATE_UPDATE_MINUTES'}, messageParams)}</div>;
				} else {
					return <div>{formatMessage({id: 'task_activity_log.ESTIMATE_UPDATE_POINTS'}, messageParams)}</div>;
				}
			case 'START_DATE_ADD':
				return (
					<div>
						{formatMessage(
							{id: 'task_activity_log.' + eventType},
							{date: formatDate(moment(params.value, 'YYYY-MM-DD'))}
						)}
					</div>
				);
			case 'START_DATE_REMOVE':
				return (
					<div>
						{formatMessage(
							{id: 'task_activity_log.' + eventType},
							{date: formatDate(moment(params.value, 'YYYY-MM-DD'))}
						)}
					</div>
				);
			case 'START_DATE_UPDATE':
				return (
					<div>
						{formatMessage(
							{id: 'task_activity_log.' + eventType},
							{
								oldDate: formatDate(moment(params.oldValue, 'YYYY-MM-DD')),
								newDate: formatDate(moment(params.newValue, 'YYYY-MM-DD')),
							}
						)}
					</div>
				);
			case 'DEADLINE_ADD':
				return (
					<div>
						{formatMessage(
							{id: 'task_activity_log.' + eventType},
							{date: formatDate(moment(params.value, 'YYYY-MM-DD'))}
						)}
					</div>
				);
			case 'DEADLINE_REMOVE':
				return (
					<div>
						{formatMessage(
							{id: 'task_activity_log.' + eventType},
							{date: formatDate(moment(params.value, 'YYYY-MM-DD'))}
						)}
					</div>
				);
			case 'DEADLINE_UPDATE':
				return (
					<div>
						{formatMessage(
							{id: 'task_activity_log.' + eventType},
							{
								oldDate: formatDate(moment(params.oldValue, 'YYYY-MM-DD')),
								newDate: formatDate(moment(params.newValue, 'YYYY-MM-DD')),
							}
						)}
					</div>
				);
			case 'CHANGE_SPRINT':
				messageParams = {
					oldValue: params.oldSprintName
						? params.oldSprintName === 'RECORD_DELETED'
							? formatMessage({id: 'task_activity_log.record-deleted'})
							: params.oldSprintName
						: formatMessage({id: 'project_sprints.backlog'}),
					newValue: params.newSprintName
						? params.newSprintName === 'RECORD_DELETED'
							? formatMessage({id: 'task_activity_log.record-deleted'})
							: params.newSprintName
						: formatMessage({id: 'project_sprints.backlog'}),
				};
				break;
			case 'CHANGE_SCOPE_GROUP':
				messageParams = {
					oldValue: params.oldPhaseName
						? params.oldPhaseName === 'RECORD_DELETED'
							? formatMessage({id: 'task_activity_log.record-deleted'})
							: params.oldPhaseName
						: formatMessage({id: 'project_scopes.no-scope'}),
					newValue: params.newPhaseName
						? params.newPhaseName === 'RECORD_DELETED'
							? formatMessage({id: 'task_activity_log.record-deleted'})
							: params.newPhaseName
						: formatMessage({id: 'project_scopes.no-scope'}),
				};
				break;
			case 'CHANGE_STATUS_COLUMN':
				messageParams = {
					oldValue:
						params.oldStatusColumnName === 'RECORD_DELETED'
							? formatMessage({id: 'task_activity_log.record-deleted'})
							: params.oldStatusColumnName,
					newValue:
						params.newStatusColumnName === 'RECORD_DELETED'
							? formatMessage({id: 'task_activity_log.record-deleted'})
							: params.newStatusColumnName,
				};
				break;
			case 'PROJECT_CHANGE':
				messageParams = {
					oldValue:
						params.oldProjectName === 'RECORD_DELETED'
							? formatMessage({id: 'task_activity_log.record-deleted'})
							: params.oldProjectName,
					newValue:
						params.newProjectName === 'RECORD_DELETED'
							? formatMessage({id: 'task_activity_log.record-deleted'})
							: params.newProjectName,
				};
				break;
			case 'REPORTED_TIME_ADD':
				const personName =
					params.timeRegPersonName === 'RECORD_DELETED'
						? formatMessage({id: 'task_activity_log.record-deleted'})
						: params.timeRegPersonName;
				return (
					<div>
						{formatMessage(
							{id: 'task_activity_log.REPORTED_TIME_ADD' + (params.timeRegPersonName ? '_WITH_PERSON' : '')},
							{
								value: Util.convertMinutesToFullHour(params.minutesRegistered, intl),
								date: formatDate(moment(params.date, 'YYYY-MM-DD')),
								personName,
							}
						)}
					</div>
				);
			case 'REPORTED_TIME_UPDATE_DATE':
				return (
					<div>
						{formatMessage(
							{id: 'task_activity_log.REPORTED_TIME_UPDATE_DATE'},
							{
								oldDate: formatDate(moment(params.oldDate, 'YYYY-MM-DD')),
								newDate: formatDate(moment(params.newDate, 'YYYY-MM-DD')),
							}
						)}
					</div>
				);
			case 'REPORTED_TIME_UPDATE_TIME':
				const oldHours = Util.convertMinutesToFullHour(params.oldMinutesRegistered, intl);
				const newHours = Util.convertMinutesToFullHour(params.newMinutesRegistered, intl);

				return (
					<div>
						{formatMessage(
							{id: 'task_activity_log.REPORTED_TIME_UPDATE_TIME'},
							{oldHours, newHours, date: formatDate(moment(params.date, 'YYYY-MM-DD'))}
						)}
					</div>
				);
			case 'REPORTED_TIME_DELETE':
				return (
					<div>
						{formatMessage(
							{id: 'task_activity_log.REPORTED_TIME_DELETE'},
							{
								hours: Util.convertMinutesToFullHour(params.minutesRegistered, intl),
								date: formatDate(moment(params.date, 'YYYY-MM-DD')),
							}
						)}
					</div>
				);
			case 'TODO_OR_SUB_TASK_UPDATE_STATE.SUBTASK':
			case 'TODO_OR_SUB_TASK_UPDATE_STATE.TODO':
				const completionSuffix = params.done ? '.COMPLETE' : '.OPEN';
				return <div>{formatMessage({id: 'task_activity_log.' + eventType + completionSuffix}, messageParams)}</div>;
			case 'LABEL_ADD':
			case 'LABEL_REMOVE':
				messageParams = {
					labelName:
						params.labelName === 'RECORD_DELETED'
							? formatMessage({id: 'task_activity_log.record-deleted'})
							: params.labelName,
				};
				break;
			case 'PERSON_ASSIGNED':
			case 'PERSON_UNASSIGNED':
				messageParams = {
					personName:
						params.personName === 'RECORD_DELETED'
							? formatMessage({id: 'task_activity_log.record-deleted'})
							: params.personName,
				};
				break;
			default:
				break;
		}
		return <div>{formatMessage({id: 'task_activity_log.' + eventType}, messageParams)}</div>;
	}

	static calculateVarianceToForecast(project, isHourEstimated) {
		let varianceToForecast = '';
		if (isHourEstimated) {
			varianceToForecast = (project.forecast - (project.reported + project.remaining)) / 60.0;
		} else {
			const reportedPoints = project.reported / project.minutesPerEstimationPoint;
			varianceToForecast = project.forecast - (reportedPoints + project.remaining);
		}
		return Math.round(varianceToForecast * 100.0) / 100.0;
	}

	//used in overview_upcoming, global_search_results_page, my_work
	static sortTasks(tasks, sortBy) {
		if (sortBy.column) {
			const direction = sortBy.ascending ? 1 : -1;
			// Break ties by sorting by companyProjectId, or companyTaskId if this is also a tie.
			const tieBreakSort = (a, b) => {
				if (a.node.project.companyProjectId === b.node.project.companyProjectId) {
					return a.node.companyTaskId > b.node.companyTaskId ? direction : direction * -1;
				}
				return a.node.project.companyProjectId > b.node.project.companyProjectId ? direction : direction * -1;
			};
			if (sortBy.column === 'project') {
				tasks.sort((a, b) => tieBreakSort(a, b));
			} else if (sortBy.column === 'id') {
				tasks.sort((a, b) => (a.node.companyTaskId > b.node.companyTaskId ? direction : direction * -1));
			} else if (sortBy.column === 'name') {
				tasks.sort((a, b) => {
					return a.node.name.toLowerCase() > b.node.name.toLowerCase()
						? direction
						: a.node.name.toLowerCase() === b.node.name.toLowerCase()
						? tieBreakSort(a, b)
						: direction * -1;
				});
			} else if (sortBy.column === 'project name' || sortBy.column === 'project-name') {
				tasks.sort((a, b) => {
					return a.node.project.name.toLowerCase() > b.node.project.name.toLowerCase()
						? direction
						: a.node.project.name.toLowerCase() === b.node.project.name.toLowerCase()
						? tieBreakSort(a, b)
						: direction * -1;
				});
			} else if (sortBy.column === 'status') {
				tasks.sort((a, b) => {
					if (
						(a.node.statusColumnV2 ? a.node.statusColumnV2.name : '') ===
						(b.node.statusColumnV2 ? b.node.statusColumnV2.name : '')
					) {
						return tieBreakSort(a, b);
					}
					return (a.node.statusColumnV2 ? a.node.statusColumnV2.name : '') >
						(b.node.statusColumnV2 ? b.node.statusColumnV2.name : '')
						? direction
						: direction * -1;
				});
			} else if (sortBy.column === 'deadline') {
				tasks.sort((a, b) => {
					if (!a.node.deadlineYear) return direction;
					if (!b.node.deadlineYear) return direction * -1;
					const aDate = Util.CreateNonUtcMomentDate(a.node.deadlineYear, a.node.deadlineMonth, a.node.deadlineDay);
					const bDate = Util.CreateNonUtcMomentDate(b.node.deadlineYear, b.node.deadlineMonth, b.node.deadlineDay);

					return aDate.isBefore(bDate) ? direction : aDate.isSame(bDate) ? tieBreakSort(a, b) : direction * -1;
				});
			} else if (sortBy.column === 'start_date' || sortBy.column === 'start date') {
				tasks.sort((a, b) => {
					if (!a.node.startYear) return direction;
					if (!b.node.startYear) return direction * -1;
					const aDate = Util.CreateNonUtcMomentDate(a.node.startYear, a.node.startMonth, a.node.startDay);
					const bDate = Util.CreateNonUtcMomentDate(b.node.startYear, b.node.startMonth, b.node.startDay);

					return aDate.isBefore(bDate) ? direction : aDate.isSame(bDate) ? tieBreakSort(a, b) : direction * -1;
				});
			} else if (sortBy.column === 'remaining') {
				tasks.sort((a, b) =>
					a.node.timeLeft > b.node.timeLeft
						? direction
						: a.node.timeLeft === b.node.timeLeft
						? tieBreakSort(a, b)
						: direction * -1
				);
			} else if (sortBy.column === 'client') {
				tasks.sort((a, b) => {
					const aName = a.node.project.client ? a.node.project.client.name : '';
					const bName = b.node.project.client ? b.node.project.client.name : '';

					return aName === bName ? tieBreakSort(a, b) : aName < bName ? direction : direction * -1;
				});
			} else if (sortBy.column === 'sprint') {
				tasks.sort((a, b) =>
					(a.node.sprint ? a.node.sprint.name : '') > (b.node.sprint ? b.node.sprint.name : '')
						? direction
						: direction * -1
				);
			} else if (sortBy.column === 'forecast') {
				tasks.sort((a, b) =>
					a.node.estimateForecast > b.node.estimateForecast
						? direction
						: a.node.estimateForecast === b.node.estimateForecast
						? tieBreakSort(a, b)
						: direction * -1
				);
			} else if (sortBy.column === 'time entries') {
				tasks.sort((a, b) => {
					const aTotal =
						a.node.timeRegistrations.edges.length !== 0
							? a.node.timeRegistrations.edges.reduce((total, timeReg) => {
									return total + timeReg.node.minutesRegistered / 60;
							  }, 0)
							: 0;
					const bTotal =
						b.node.timeRegistrations.edges.length !== 0
							? b.node.timeRegistrations.edges.reduce((total, timeReg) => {
									return total + timeReg.node.minutesRegistered / 60;
							  }, 0)
							: 0;
					return aTotal > bTotal ? direction : aTotal === bTotal ? tieBreakSort(a, b) : direction * -1;
				});
			} else if (sortBy.column === 'over forecast') {
				tasks.sort((a, b) => {
					let aTotal;
					if (a.node.project.estimationUnit === 'HOURS') {
						aTotal =
							(a.node.estimateForecast -
								a.node.timeRegistrations.edges.reduce(
									(total, timeReg) => total + timeReg.node.minutesRegistered,
									0
								)) *
							-1;
					} else {
						const time = a.node.timeRegistrations.edges.reduce(
							(total, timeReg) => total + timeReg.node.minutesRegistered,
							0
						);
						const estimate = a.node.estimateForecast * a.node.project.minutesPerEstimationPoint;
						aTotal = (estimate - time) * -1;
					}
					let bTotal;
					if (b.node.project.estimationUnit === 'HOURS') {
						bTotal =
							(b.node.estimateForecast -
								b.node.timeRegistrations.edges.reduce(
									(total, timeReg) => total + timeReg.node.minutesRegistered,
									0
								)) *
							-1;
					} else {
						const time = b.node.timeRegistrations.edges.reduce(
							(total, timeReg) => total + timeReg.node.minutesRegistered,
							0
						);
						const estimate = b.node.estimateForecast * b.node.project.minutesPerEstimationPoint;
						bTotal = (estimate - time) * -1;
					}
					return aTotal > bTotal ? direction : direction * -1;
				});
			} else if (sortBy.column === 'assigned') {
				tasks.sort((a, b) => {
					if (a.node.assignedPersons.length !== b.node.assignedPersons.length) {
						return a.node.assignedPersons.length > b.node.assignedPersons.length ? direction : direction * -1;
					} else if (a.node.assignedPersons.length === 1 && a.node.assignedPersons.length === 1) {
						const aName = (a.node.assignedPersons[0].firstName || '') + (a.node.assignedPersons[0].lastName || '');
						const bName = (b.node.assignedPersons[0].firstName || '') + (b.node.assignedPersons[0].lastName || '');
						return aName.localeCompare(bName);
					} else {
						return 0;
					}
				});
			} else if (sortBy.column === 'labels') {
				tasks.sort((a, b) => {
					let aValue = '';
					let bValue = '';
					a.node.taskLabels.forEach(tasklabel => (aValue += tasklabel.label.name.toLowerCase()));
					b.node.taskLabels.forEach(tasklabel => (bValue += tasklabel.label.name.toLowerCase()));
					if (aValue > bValue) return sortBy.ascending ? 1 : -1;
					if (aValue === bValue) return tieBreakSort(a, b);
					if (aValue < bValue) return sortBy.ascending ? -1 : 1;
					return 0;
				});
			} else if (sortBy.column === 'phase') {
				tasks.sort((a, b) => {
					const aVal = a.node.phase ? a.node.phase.name : '';
					const bVal = b.node.phase ? b.node.phase.name : '';
					return aVal > bVal ? direction : aVal === bVal ? tieBreakSort(a, b) : direction * -1;
				});
			}
		} else {
			tasks.sort((a, b) => (a.node.sortOrder > b.node.sortOrder ? 1 : -1));
		}
	}

	static getOverviewUpcomingOrTaskGlobalSearchColumns(columnData, availableFeatureFlags) {
		let columns = [
			{
				name: 'project',
				translationId: 'common.project',
				checked: columnData.find(col => col.name === 'project')
					? columnData.find(col => col.name === 'project').checked
					: true,
			},
			{
				name: 'project name',
				translationId: 'common.project-name',
				checked: columnData.find(col => col.name === 'project name')
					? columnData.find(col => col.name === 'project name').checked
					: false,
			},
			{
				name: 'client',
				translationId: 'common.client',
				checked: columnData.find(col => col.name === 'client')
					? columnData.find(col => col.name === 'client').checked
					: false,
			},
			{
				name: 'id',
				translationId: 'common.id',
				checked: columnData.find(col => col.name === 'id') ? columnData.find(col => col.name === 'id').checked : true,
			},
			{
				name: 'name',
				translationId: 'common.name',
				checked: columnData.find(col => col.name === 'name')
					? columnData.find(col => col.name === 'name').checked
					: true,
			},
			{
				name: 'status',
				translationId: 'common.status',
				checked: columnData.find(col => col.name === 'status')
					? columnData.find(col => col.name === 'status').checked
					: true,
			},
			{
				name: 'owner',
				translationId: 'common.owner',
				checked: columnData.find(col => col.name === 'owner')
					? columnData.find(col => col.name === 'owner').checked
					: false,
			},
			{
				name: 'start date',
				translationId: 'common.start_date',
				checked: columnData.find(col => col.name === 'start date')
					? columnData.find(col => col.name === 'start date').checked
					: true,
			},
			{
				name: 'deadline',
				translationId: 'common.deadline',
				checked: columnData.find(col => col.name === 'deadline')
					? columnData.find(col => col.name === 'deadline').checked
					: true,
			},
			{
				name: 'phase',
				translationId: 'common.scope-group',
				checked: columnData.find(col => col.name === 'phase')
					? columnData.find(col => col.name === 'phase').checked
					: false,
			},
			{
				name: 'sprint',
				translationId: 'common.sprint',
				checked: columnData.find(col => col.name === 'sprint')
					? columnData.find(col => col.name === 'sprint').checked
					: false,
			},
			{
				name: 'forecast',
				translationId: 'common.forecast',
				checked: columnData.find(col => col.name === 'forecast')
					? columnData.find(col => col.name === 'forecast').checked
					: false,
			},
			{
				name: 'time entries',
				translationId: 'common.time_entries',
				checked: columnData.find(col => col.name === 'time entries')
					? columnData.find(col => col.name === 'time entries').checked
					: false,
			},
			{
				name: 'remaining',
				translationId: 'common.remaining',
				checked: columnData.find(col => col.name === 'remaining')
					? columnData.find(col => col.name === 'remaining').checked
					: true,
			},
			{
				name: 'over forecast',
				translationId: 'card.over_forecast',
				checked: columnData.find(col => col.name === 'over forecast')
					? columnData.find(col => col.name === 'over forecast').checked
					: false,
			},
			{
				name: 'assigned',
				translationId: 'common.assigned',
				checked: columnData.find(col => col.name === 'assigned')
					? columnData.find(col => col.name === 'assigned').checked
					: false,
			},
			{
				name: 'comments/files',
				translationId: 'common.files-comments',
				checked: columnData.find(col => col.name === 'comments/files')
					? columnData.find(col => col.name === 'comments/files').checked
					: true,
			},
			{
				name: 'labels',
				translationId: 'common.labels',
				checked: columnData.find(col => col.name === 'labels')
					? columnData.find(col => col.name === 'labels').checked
					: false,
			},
		];

		if (Util.isFeatureHidden(HIDDEN_FEATURES.TIME_REGISTRATIONS)) {
			columns = this.getNoTimeRegColumns(columns, ['time entries']);
		}

		return columns;
	}

	static roundToDecimals(value, decimals) {
		const m = Math.pow(10, decimals ? decimals : 2);
		return Math.round(value * m) / m;
	}

	static getInitials(name) {
		if (!name || name.length === 0) {
			return '';
		}
		const splitName = name
			// eslint-disable-next-line
			.replace(/[\x00-\x1F\x7F-\x9F]/g, ' ') // matches all UNICODE control characters
			.split(' ')
			.filter(token => token !== '');

		if (splitName.length === 0) {
			return '';
		}

		return `${splitName[0][0]}${splitName.length >= 2 ? splitName[splitName.length - 1][0] : ''}`;
	}

	static getBrowserSpecs() {
		var ua = navigator.userAgent,
			tem,
			M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
		if (/trident/i.test(M[1])) {
			tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
			return {name: 'IE', version: tem[1] || ''};
		}
		if (M[1] === 'Chrome') {
			tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
			if (tem != null) return {name: tem[1].replace('OPR', 'Opera'), version: tem[2]};
		}
		M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
		tem = ua.match(/version\/(\d+)/i);
		if (tem != null) M.splice(1, 1, tem[1]);
		return {name: M[0], version: M[1]};
	}

	static trimWhitespace(input) {
		return input?.replace(/ /g, '');
	}

	static convertTimeInputToMinutes(timeInput, isTimeRegInput = false) {
		let resultMinutes = null;
		if (timeInput !== undefined) {
			let hours = null;
			let minutes = null;

			if (typeof timeInput === 'number') {
				// If we are converting an input for a time registration, any number >= 10 should be converted to minutes instead of hours.
				if (isTimeRegInput && timeInput >= 10) {
					minutes = timeInput;
				} else {
					hours = timeInput;
				}
			} else if (typeof timeInput === 'string') {
				// Trim input for spaces
				timeInput = this.trimWhitespace(timeInput);
				// Replace comma with dot
				timeInput = timeInput.replace(',', '.');
				// Replace * with empty string
				timeInput = timeInput.replace('*', '');

				// If it's only a number
				if (timeInput.length > 0) {
					if (!isNaN(Number(timeInput))) {
						const numberInput = Number(timeInput);
						if (isTimeRegInput && numberInput >= 10) {
							minutes = numberInput;
						} else {
							hours = numberInput;
						}
					} else {
						const timeFormat = new RegExp(/(^(\d+):(\d+)$)|(^(\d+):$)|(^:(\d+)$)/);
						const timeFormatMatches = timeFormat.exec(timeInput);
						if (timeFormatMatches) {
							if (timeFormatMatches[2] !== undefined && timeFormatMatches[3] !== undefined) {
								hours = Number(timeFormatMatches[2]);
								minutes = Number(timeFormatMatches[3]);
							} else if (timeFormatMatches[5] !== undefined) {
								hours = Number(timeFormatMatches[5]);
							} else if (timeFormatMatches[7] !== undefined) {
								minutes = Number(timeFormatMatches[7]);
							}
						} else {
							// The regex consist of three outer groups:
							// 1) hour-minute format (1h30m)
							// 2) hour format (1h)
							// 3) minute format (30m)
							// To see what the regex matches check the unit test.
							const hourMinuteFormat = new RegExp(
								/(^((-?(\d*\.)?\d+)[ht])(\d+)m?$)|(^(-?(\d*\.)?\d+)[ht]$)|(^(-?\d+)m$)/
							);
							const hourMinuteFormatMatches = hourMinuteFormat.exec(timeInput);
							if (hourMinuteFormatMatches) {
								if (hourMinuteFormatMatches[3] !== undefined && hourMinuteFormatMatches[5] !== undefined) {
									hours = Number(hourMinuteFormatMatches[3]);
									minutes = Number(hourMinuteFormatMatches[5]);
								} else if (hourMinuteFormatMatches[7] !== undefined) {
									hours = Number(hourMinuteFormatMatches[7]);
								} else if (hourMinuteFormatMatches[10] !== undefined) {
									minutes = Number(hourMinuteFormatMatches[10]);
								}
							}
						}
					}
				}
			}

			if (hours !== null || minutes !== null) {
				const negativeHours = isNaN(hours) ? 1 : hours < 0 ? -1 : 1;
				resultMinutes = Math.round(
					(hours !== null ? hours * 60 : 0) + negativeHours * (minutes !== null ? minutes : 0)
				);
			}
		}
		return resultMinutes;
	}

	static roundPoints(points, intl) {
		return Math.round((points + Number.EPSILON) * 100) / 100 + intl.formatMessage({id: 'common.points.short'});
	}

	static roundToNDecimals(value, decimalAmount) {
		return Math.round((value + Number.EPSILON) * Math.pow(10, decimalAmount)) / Math.pow(10, decimalAmount);
	}

	static convertMinutesToFullHourTwo(data) {
		const negative = data['task.work.estimate.minutes'] < 0 ? -1 : 1;
		const absoluteMinutes = Math.round(data['task.work.estimate.minutes']) * negative;
		const fullHours = absoluteMinutes / 60;
		let justHours = Math.trunc(fullHours);
		const justMinutes = absoluteMinutes - justHours * 60;
		const hideMinutes = justMinutes > 0 && justHours > 99;

		if (hideMinutes && justMinutes > 29) {
			justHours++;
		}

		let formatted = negative === -1 ? '-' : '';

		if (justHours > 0 && justMinutes > 0 && !hideMinutes) {
			formatted += justHours + 'h' + justMinutes + 'm';
		} else if (justMinutes > 0 && !hideMinutes) {
			formatted += justMinutes + 'm';
		} else {
			formatted += justHours + 'h';
		}

		return formatted;
	}

	static convertMinutesToFullHour(minutes = 0, intl, ignoreMinuteTruncation, appendHoursUnit = true) {
		const negative = minutes < 0 ? -1 : 1;
		const absoluteMinutes = Math.round(minutes) * negative;
		const fullHours = absoluteMinutes / 60;
		let justHours = Math.trunc(fullHours);
		const justMinutes = absoluteMinutes - justHours * 60;
		const hideMinutes = justMinutes > 0 && justHours > 99 && !ignoreMinuteTruncation;

		if (hideMinutes && justMinutes > 29) {
			justHours++;
		}

		if (!appendHoursUnit) {
			return justHours;
		}

		let formatted = negative === -1 ? '-' : '';

		if (justHours > 0 && justMinutes > 0 && !hideMinutes) {
			formatted +=
				justHours +
				getCachedMessage(intl, 'common.hours.short') +
				' ' +
				justMinutes +
				getCachedMessage(intl, 'common.minutes.short');
		} else if (justMinutes > 0 && !hideMinutes) {
			formatted += justMinutes + getCachedMessage(intl, 'common.minutes.short');
		} else {
			formatted += justHours + getCachedMessage(intl, 'common.hours.short');
		}

		return formatted;
	}

	static convertMinutesToDays(minutes = 0, minutesPerDay, intl) {
		const {formatMessage} = intl;

		const days = intl.formatNumber(minutes / minutesPerDay, {maximumFractionDigits: 2});

		return `${days} ${formatMessage({
			id: Math.abs(days) <= 1 && +days !== 0 ? 'overview_time.day' : 'common.days',
		}).toLowerCase()}`;
	}

	static isWeekdayDisabled(weekdayIndex, company, language) {
		const {weekendDisplayShowAlways} = company;

		if (weekendDisplayShowAlways === false) {
			const isSaturday = language === 'ENGLISH_US' ? weekdayIndex === 6 : weekdayIndex === 5;
			const isSunday = language === 'ENGLISH_US' ? weekdayIndex === 0 : weekdayIndex === 6;
			if (isSaturday || isSunday) return true;
		}

		return false;
	}

	static getAllocationTimeText(totalTime, allocation, company, intl, winPercentageToUse) {
		let potentialText = '';
		const defaultWorkingDays = getCompanyDefaultWorkingDays(company);
		let showPerDayText = true;
		let minutesPerDay = null;
		for (const dayName of DAY_NAMES) {
			if (allocation[dayName]) {
				if (minutesPerDay) {
					if (allocation[dayName] !== minutesPerDay) {
						showPerDayText = false;
						break;
					}
				} else {
					minutesPerDay = allocation[dayName];
				}
			} else if (defaultWorkingDays.includes(dayName)) {
				showPerDayText = false;
				break;
			}
		}

		potentialText += totalTime;

		// time per day
		const timePerDay =
			' | ' +
			Util.convertMinutesToFullHour(minutesPerDay * winPercentageToUse, intl) +
			getCachedMessage(intl, 'scheduling.heatmap_time_per_day');
		if (showPerDayText) {
			potentialText += timePerDay;
		}

		return {potentialText, timePerDay, showPerDayText};
	}

	static getRetainerTypeTranslation(retainerType, intl) {
		const {formatMessage} = intl;
		switch (retainerType) {
			case PERIOD_BUDGET_TYPE.FIXED_HOURS:
				return formatMessage({id: 'retainer.retainer_fixed_hours'});
			case PERIOD_BUDGET_TYPE.FIXED_PRICE:
				return formatMessage({id: 'retainer.retainer_fixed_price'});
			case PERIOD_BUDGET_TYPE.TIME_AND_MATERIALS:
				return formatMessage({id: 'retainer.retainer_time_materials'});
			default:
				return formatMessage({id: 'new_project_modal.retainer'});
		}
	}

	static getBudgetTypeTranslation(budgetType, intl, retainerType) {
		const {formatMessage} = intl;
		switch (budgetType) {
			case BUDGET_TYPE.FIXED_PRICE:
			case BUDGET_TYPE.FIXED_PRICE_V2:
				return formatMessage({id: 'new_project_modal.budget_type_fixed_price'});
			case BUDGET_TYPE.TIME_AND_MATERIALS:
				return formatMessage({id: 'new_project_modal.budget_type_time_materials'});
			case BUDGET_TYPE.NON_BILLABLE:
				return formatMessage({id: 'common.non-billable'});
			case BUDGET_TYPE.RETAINER:
				return this.getRetainerTypeTranslation(retainerType, intl);
			default:
				return null;
		}
	}

	static showTaskModal(taskId, history) {
		let newLocation = window.location.pathname;
		if (pathIncludesTask(newLocation)) {
			// Task modal is open. Redirect.
			closeModal();
			newLocation = removeTaskLinkFromUrl(newLocation);
		}

		newLocation = (newLocation.endsWith('/') ? newLocation : newLocation + '/') + 'T' + taskId;

		newLocation = this.appendURLTag(newLocation);

		history.push(newLocation);
	}

	static appendURLTag(location) {
		if (this.isScheduling()) {
			const hash = window.location.hash.slice(1);

			if (hash) {
				return `${location}#${hash}`;
			}
		}

		return location;
	}

	static getPhasesForPeriod(period, phases) {
		if (period && period.startYear && period.endYear) {
			const periodStartDate = this.CreateMomentDate(period.startYear, period.startMonth, period.startDay);
			const periodEndDate = this.CreateMomentDate(period.endYear, period.endMonth, period.endDay);
			return phases.filter(phase => {
				if (!phase.startYear) return false;
				const phaseStartDate = this.CreateMomentDate(phase.startYear, phase.startMonth, phase.startDay);
				return phaseStartDate.isBetween(periodStartDate, periodEndDate, 'days', []);
			});
		}
		return [];
	}

	static getPeriodBudgetTypeTranslation(periodBudgetType, intl) {
		const {formatMessage} = intl;
		switch (periodBudgetType) {
			case PERIOD_BUDGET_TYPE.FIXED_HOURS:
				return formatMessage({id: 'new_project_modal.budget_type_fixed_hours'});
			case PERIOD_BUDGET_TYPE.FIXED_PRICE:
				return formatMessage({id: 'common.fixed_price'});
			case PERIOD_BUDGET_TYPE.TIME_AND_MATERIALS:
				return formatMessage({id: 'new_project_modal.budget_type_time_materials'});
			default:
				return null;
		}
	}

	static getProjectStageForDropdown(intl, hasOpportunityAccess = false) {
		const {formatMessage} = intl;
		const stages = [
			{
				value: PROJECT_STATUS.PLANNING,
				label: formatMessage({
					id: 'project_status.planning',
				}),
			},
			{
				value: PROJECT_STATUS.RUNNING,
				label: formatMessage({
					id: 'project_status.running',
				}),
			},
			{
				value: PROJECT_STATUS.HALTED,
				label: formatMessage({
					id: 'project_status.halted',
				}),
			},
			{
				value: PROJECT_STATUS.DONE,
				label: formatMessage({
					id: 'project_status.done',
				}),
			},
		];

		if (hasOpportunityAccess) {
			stages.unshift({
				value: PROJECT_STATUS.OPPORTUNITY,
				label: formatMessage({
					id: 'project_status.opportunity',
				}),
			});
		}
		return stages;
	}

	static getProjectStageTranslation(stage, intl) {
		const {formatMessage} = intl;
		switch (stage) {
			case PROJECT_STATUS.OPPORTUNITY:
				return formatMessage({
					id: 'project_status.opportunity',
				});
			case PROJECT_STATUS.PLANNING:
				return formatMessage({
					id: 'project_status.planning',
				});
			case PROJECT_STATUS.RUNNING:
				return formatMessage({
					id: 'project_status.running',
				});
			case PROJECT_STATUS.HALTED:
				return formatMessage({
					id: 'project_status.halted',
				});
			case PROJECT_STATUS.DONE:
				return formatMessage({
					id: 'project_status.done',
				});
			default:
				return null;
		}
	}

	static replaceAt(origin, index, replacement) {
		const replace = replacement + ''; //needs to be a String
		return origin.substr(0, index) + replace + origin.substr(index + replace.length);
	}

	static normalizedIncludes(origin, searched, searchType) {
		if (origin == null || searched == null) return false;

		const normalizedOrigin = origin
			.toLowerCase()
			.normalize('NFD')
			.replace(/[\u0300-\u036f]/g, '');
		const normalizedSearched = searched
			.toLowerCase()
			.normalize('NFD')
			.replace(/[\u0300-\u036f]/g, '');

		switch (searchType) {
			case STRING_COMPARE.STARTS_WITH:
				return normalizedOrigin.startsWith(normalizedSearched);
			case STRING_COMPARE.ENDS_WITH:
				return normalizedOrigin.endsWith(normalizedSearched);
			case STRING_COMPARE.INCLUDES:
			default:
				return normalizedOrigin.includes(normalizedSearched);
		}
	}

	static normalizedIndexOf(origin, searched) {
		if (origin == null || searched == null) return -1;

		return origin
			.toLowerCase()
			.normalize('NFD')
			.replace(/[\u0300-\u036f]/g, '')
			.indexOf(
				searched
					.toLowerCase()
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, '')
			);
	}

	static normalizedWord(word) {
		if (word == null) return null;

		return word.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
	}

	static stringToProjectType(s) {
		switch (s) {
			case 'time_and_material':
				return BUDGET_TYPE.TIME_AND_MATERIALS;
			case 'retainers':
				return BUDGET_TYPE.RETAINER;
			case 'fixed_price':
				return BUDGET_TYPE.FIXED_PRICE;
			case 'fixed_price_v2':
				return BUDGET_TYPE.FIXED_PRICE_V2;
			case 'non-billable':
			case 'non_billable':
				return BUDGET_TYPE.NON_BILLABLE;
			default:
				return null;
		}
	}

	static validatePasswordChange(oldPassword, newPassword) {
		if (oldPassword === newPassword) {
			return {
				valid: false,
				error: PASSWORD_VALIDATION_ERROR.SAME_AS_OLD_PASSWORD,
			};
		} else {
			return this.passwordValidator(newPassword);
		}
	}

	static validatePassword(password) {
		// if (process.env.CIRCLE_BRANCH !== 'production' && process.env.CIRCLE_BRANCH !== 'master') {
		// 	return {valid: true};
		// }
		return this.passwordValidator(password);
	}

	static passwordValidator(password) {
		let passwordTrimmed = password.trim();

		// At least 8 characters
		if (!this.validateMin8Chars(passwordTrimmed))
			return {
				valid: false,
				error: PASSWORD_VALIDATION_ERROR.AT_LEAST_8_CHARS,
			};
		// At most 64 characters
		if (!this.validateMax64Chars(passwordTrimmed))
			return {
				valid: false,
				error: PASSWORD_VALIDATION_ERROR.AT_MOST_64_CHARS,
			};
		// At least 1 digit
		if (!this.validate1Digit(passwordTrimmed))
			return {
				valid: false,
				error: PASSWORD_VALIDATION_ERROR.AT_LEAST_1_DIGIT,
			};
		// At least 1 letter
		if (!this.validate1Letter(passwordTrimmed))
			return {
				valid: false,
				error: PASSWORD_VALIDATION_ERROR.AT_LEAST_1_LETTER,
			};
		// No 3 identical characters
		if (!this.validate3Identical(passwordTrimmed))
			return {
				valid: false,
				error: PASSWORD_VALIDATION_ERROR.NO_IDENTICAL_3,
			};
		// Hooray! Password passed all checks
		return {valid: true};
	}

	static shouldUseRetainers() {
		return hasModule(MODULE_TYPES.RETAINERS);
	}

	static hasCustomFields() {
		return hasModule(MODULE_TYPES.CUSTOM_FIELDS);
	}

	static hasTimeLocking() {
		return hasModule(MODULE_TYPES.TIME_LOCKING);
	}

	static hasDepartments() {
		return hasModule(MODULE_TYPES.DEPARTMENTS);
	}

	static hasSkills() {
		return hasModule(MODULE_TYPES.SKILLS);
	}

	static hasHubspot() {
		return hasModule(MODULE_TYPES.HUBSPOT);
	}

	static hasSalesforce() {
		return hasModule(MODULE_TYPES.SALESFORCE);
	}

	static hasPipedrive() {
		return hasModule(MODULE_TYPES.PIPEDRIVE);
	}

	static hasSisense() {
		return hasModule(MODULE_TYPES.SISENSE);
	}

	static validateProjectUrl(history) {
		const pathname = history.location.pathname;
		if (pathname.startsWith('/project/P')) {
			history.replace('/not-found');
		}
		if (pathname.startsWith('/connected/X')) {
			history.replace('/not-found');
		}
	}

	static getUrlQueryParameter(name) {
		name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
		const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
		const results = regex.exec(window.location.href);
		return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
	}

	static localStorageRemoveItem(key) {
		try {
			localStorage.removeItem(key);
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log('LocalStorage Remove failed: ' + key);
		}
	}

	static localStorageSetItem(key, val) {
		try {
			localStorage.setItem(key, val);
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log('LocalStorage failed: ' + key + ' ' + val);
		}
	}

	static localStorageGetItem(key) {
		try {
			return localStorage.getItem(key);
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log('LocalStorage failed: ' + key);
		}
	}

	static localStorageGetItemWithDefault(key, defaultValue) {
		try {
			const item = localStorage.getItem(key);
			return item ? item : defaultValue;
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log('LocalStorage failed: ' + key);
		}
	}

	static sessionStorageSetItem(key, val) {
		try {
			sessionStorage.setItem(key, val);
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log('Session Storage failed: ' + key + ' ' + val);
		}
	}

	static sortTimeRegs(timeRegs) {
		return timeRegs.sort((a, b) => {
			const aDate = moment({
				y: a.node.year,
				M: a.node.month - 1,
				d: a.node.day,
			});
			const bDate = moment({
				y: b.node.year,
				M: b.node.month - 1,
				d: b.node.day,
			});
			return aDate > bDate ? 1 : -1;
		});
	}

	static sortBaselinePhase(phases) {
		return phases.sort((a, b) => {
			let aStartDate = moment({
				y: a.node.startYear,
				M: a.node.startMonth - 1,
				d: a.node.startDay,
			});
			let aEndDate = moment({
				y: a.node.deadlineYear,
				M: a.node.deadlineMonth - 1,
				d: a.node.deadlineDay,
			});
			let bStartDate = moment({
				y: b.node.startYear,
				M: b.node.startMonth - 1,
				d: b.node.startDay,
			});
			let bEndDate = moment({
				y: b.node.deadlineYear,
				M: b.node.deadlineMonth - 1,
				d: b.node.deadlineDay,
			});
			//Date used for sorting phases with not selected dates
			const dummyDate = moment({
				y: -10000,
				M: 1,
				d: 1,
			});

			if (!aStartDate.isValid()) aStartDate = dummyDate.clone();
			if (!aEndDate.isValid()) aEndDate = dummyDate.clone();
			if (!bStartDate.isValid()) bStartDate = dummyDate.clone();
			if (!bEndDate.isValid()) bEndDate = dummyDate.clone();

			if (aStartDate < bStartDate) return -1;
			if (bStartDate < aStartDate) return 1;
			//If same date, sort by end date ascending
			if (aEndDate < bEndDate) return -1;
			if (bEndDate < aEndDate) return 1;

			// sort same end dates sort by id
			const aId = parseInt(atob(a.node.id).replace('PhaseType:', ''), 10);
			const bId = parseInt(atob(b.node.id).replace('PhaseType:', ''), 10);
			if (aId < bId) return 1;

			return -1;
		});
	}

	static sortPhases(phases, isCanvasTimeline = false) {
		return phases.sort((a, b) => {
			let aPhase = a;
			let bPhase = b;

			let aEndDate;
			let bEndDate;
			let aStartDate;
			let bStartDate;

			if (isCanvasTimeline) {
				// to be used in the canvas timeline
				aPhase = a.data.phase;
				bPhase = b.data.phase;

				if (!aPhase && bPhase) return 1;
				if (!bPhase && aPhase) return -1;

				aEndDate = createCanvasTimelineDate(aPhase.deadlineYear, aPhase.deadlineMonth, aPhase.deadlineDay);
				bEndDate = createCanvasTimelineDate(bPhase.deadlineYear, bPhase.deadlineMonth, bPhase.deadlineDay);
				aStartDate = createCanvasTimelineDate(aPhase.startYear, aPhase.startMonth, aPhase.startDay);
				bStartDate = createCanvasTimelineDate(bPhase.startYear, bPhase.startMonth, bPhase.startDay);
				const aHasDates = aStartDate && aEndDate && areItemDatesValid(aStartDate, aEndDate);
				const bHasDates = bStartDate && bEndDate && areItemDatesValid(bStartDate, bEndDate);

				// if phases do not have dates set the dates at the beginning of the timeline
				if (!aHasDates) {
					aStartDate = 0;
					aEndDate = 0;
				}
				if (!bHasDates) {
					bStartDate = 0;
					bEndDate = 0;
				}
			} else {
				aStartDate = moment({
					y: aPhase.startYear,
					M: aPhase.startMonth - 1,
					d: aPhase.startDay,
				});
				aEndDate = moment({
					y: aPhase.deadlineYear,
					M: aPhase.deadlineMonth - 1,
					d: aPhase.deadlineDay,
				});
				bStartDate = moment({
					y: bPhase.startYear,
					M: bPhase.startMonth - 1,
					d: bPhase.startDay,
				});
				bEndDate = moment({
					y: bPhase.deadlineYear,
					M: bPhase.deadlineMonth - 1,
					d: bPhase.deadlineDay,
				});
				//Date used for sorting phases with not selected dates
				const dummyDate = moment({
					y: -10000,
					M: 1,
					d: 1,
				});

				if (!aStartDate.isValid()) aStartDate = dummyDate.clone();
				if (!aEndDate.isValid()) aEndDate = dummyDate.clone();
				if (!bStartDate.isValid()) bStartDate = dummyDate.clone();
				if (!bEndDate.isValid()) bEndDate = dummyDate.clone();
			}

			// do the actual sorting
			if (aStartDate < bStartDate) return -1;
			if (bStartDate < aStartDate) return 1;
			//If same date, sort by end date ascending
			if (aEndDate < bEndDate) return -1;
			if (bEndDate < aEndDate) return 1;

			// sort same end dates sort by id
			const aId = parseInt(atob(aPhase.id).replace('PhaseType:', ''), 10);
			const bId = parseInt(atob(bPhase.id).replace('PhaseType:', ''), 10);
			if (aId < bId) return 1;

			return -1;
		});
	}

	static localStorageDeleteFincancialMap() {
		var keys = [];
		for (let i = 0; i < localStorage.length; i++) {
			if (localStorage.key(i).startsWith('projects-page-financial-data-map')) {
				keys.push(localStorage.key(i));
			}
		}
		for (let i = 0; i < keys.length; i++) {
			localStorage.removeItem(keys[i]);
		}
	}

	static getPercentageTime(val1, val2) {
		return val2 === 0 ? (val1 === 0 ? 0 : 1) : val1 / val2;
	}

	static minutesToHours(minutes) {
		return Math.round((minutes / 60.0) * 100) / 100;
	}

	static getInvoiceEntryAmount(unitPrice, quantity, exchangeRate, discount, tax) {
		return (unitPrice / exchangeRate) * quantity * ((100 - discount) / 100) * (tax ? (100 + tax) / 100 : 1);
	}

	static postSns(personOverride, method, type) {
		// Log with SNS
		const headers = new Headers();
		headers.append('Content-Type', 'application/json');
		const data = {
			method: 'POST',
			headers,
			credentials: 'include',
			body: JSON.stringify({
				personOverride,
				method,
				type,
			}),
		};

		fetch(DirectApi.graphqlServerEndpoint('sns'), data);
	}

	static shouldDisplayTaxIdColumn(integration) {
		return integration !== 'ECONOMIC' && integration !== 'QUICKBOOKS';
	}

	static getIntegrationsEnabled(companyViewer) {
		let integrationList = [];

		if (companyViewer.slackEnabled) {
			integrationList.push('Slack');
		}
		if (companyViewer.teamsEnabled) {
			integrationList.push('Teams');
		}
		if (companyViewer.vstsEnabled) {
			integrationList.push('Vsts');
		}
		if (companyViewer.githubEnabled) {
			integrationList.push('Github');
		}
		if (companyViewer.gitlabEnabled) {
			integrationList.push('Gitlab');
		}
		if (companyViewer.salesforceEnabled) {
			integrationList.push('Salesforce');
		}
		if (companyViewer.unit4Enabled) {
			integrationList.push('Unit4');
		}
		if (companyViewer.jiraCloudEnabled) {
			integrationList.push('JiraCloud');
		}
		if (companyViewer.xeroEnabled || companyViewer.newXeroEnabled) {
			integrationList.push('Xero');
		}
		if (companyViewer.quickbooksEnabled) {
			integrationList.push('Quickbooks');
		}
		if (companyViewer.economicEnabled) {
			integrationList.push('Economic');
		}
		if (companyViewer.oktaEnabled) {
			integrationList.push('Okta');
		}
		if (companyViewer.oneloginEnabled) {
			integrationList.push('OneLogin');
		}
		if (companyViewer.AADEnabled) {
			integrationList.push('AAD');
		}
		if (companyViewer.gdriveEnabled) {
			integrationList.push('GoogleDrive');
		}
		if (companyViewer.harvestEnabled) {
			integrationList.push('Harvest');
		}

		return integrationList;
	}

	static getCompanyExchangeRateMap(company) {
		return company.exchangeRates.edges
			.map(edge => edge.node)
			.reduce((map, exchangeRate) => {
				map[exchangeRate.currency] = exchangeRate.rate;
				return map;
			}, {});
	}

	static getQueryParams(queryString) {
		// Ex '?test=true&supertest=1&supertest=2'
		const splits = queryString.substring(1, queryString.length).split('&');

		const queryParams = {};

		for (let i = 0; i < splits.length; i++) {
			const queryParam = splits[i].split('=');

			if (!queryParams[queryParam[0]]) {
				queryParams[queryParam[0]] = [queryParam[1]];
			} else {
				queryParams[queryParam[0]] = [...queryParams[queryParam[0]], queryParam[1]];
			}
		}

		return queryParams;
	}

	/**
	 * Get the columns to be removed/hidden when No time registration is used
	 * @param columns
	 * @param specialToRemove
	 * @returns columns without the time registration related columns
	 */
	static getNoTimeRegColumns(columns, specialToRemove) {
		const columnsToRemove = ['time-entries', 'over-forecast', 'actual-price', 'actual-cost'].concat(specialToRemove);
		const cleanColumns = columns.filter(item => {
			return !columnsToRemove.includes(item.name);
		});

		return cleanColumns;
	}

	static isObjectLiteral(object) {
		return !!object && object.constructor === Object;
	}

	static hasRevenueWithoutCostAccess() {
		if (!ProjectUtil.projectTracksCost()) return true;
		return (
			!hasPermission(PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION) &&
			hasPermission(PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE)
		);
	}

	static isFeatureHidden(featureName) {
		return isFeatureHidden(featureName);
	}

	static getTranslationByFinancialNumberType(selectedSingleValue) {
		let reportName;
		switch (selectedSingleValue) {
			case 'actualRevenue':
			case 'billableActualTimeAndExpenses':
				reportName = 'project_budget.actual_billable_time_and_expenses';
				break;
			case 'actualCost':
				reportName = 'common.actual_cost';
				break;
			case 'actualProfit':
				reportName = 'project_budget.actual_profit';
				break;
			case 'plannedRevenue':
			case 'billablePlannedTimeAndExpenses':
				reportName = 'project_budget.planned_billable_time';
				break;
			case 'plannedCost':
				reportName = 'project_budget.planned_cost';
				break;
			case 'plannedProfit':
				reportName = 'project_budget.plan_profit';
				break;
			case 'remainingRevenue':
			case 'billableForecastTimeAndExpensesToComplete':
				reportName = 'project_budget.forecast_billable_time_and_expenses';
				break;
			case 'remainingCost':
			case 'forecastCostToComplete':
				reportName = 'project_budget.forecast_cost_to_complete';
				break;
			case 'remainingProfit':
			case 'forecastProfitToComplete':
				reportName = 'project_budget.forecast_profit_to_complete';
				break;
			case 'forecastRevenue':
			case 'billableTotalTimeAndExpensesAtCompletion':
				reportName = 'project_budget.revenue_recognition.total_billable_tm_time_completion';
				break;
			case 'forecastCost':
			case 'totalCostAtCompletion':
				reportName = 'project_budget.total_cost_at_completion';
				break;
			case 'forecastProfit':
			case 'totalProfitAtCompletion':
				reportName = 'project_budget.total_profit_at_completion';
				break;
			case 'invoiced':
				reportName = 'project_portfolio_report.invoiced';
				break;
			case 'paid':
				reportName = 'project_budget.invoice_paid';
				break;
			case 'recognitionAmount':
				reportName = 'project_budget.recognition_amount';
				break;
			default:
				throw new Error(`Unsupported selectedSingleValue ${selectedSingleValue}`);
		}

		return reportName;
	}

	static isScheduling() {
		return window.location.pathname.includes('schedul') || window.location.pathname.includes('/timeline');
	}

	static isScoping() {
		return window.location.pathname.includes('/scoping');
	}

	static dispatchScheduleEvent(response) {
		dispatch(EVENT_ID.SCHEDULING_MODAL_MUTATION_SUCCESS, response, Util.isScheduling() && {EVENT_FROM_SCHEDULING: true});
	}

	static createDate(startYear, startMonth, startDay) {
		return new Date(startYear, startMonth - 1, startDay);
	}

	static getMaxSubtaskDepth() {
		return MAX_SUBTASK_DEPTH;
	}

	static getMonthStringWithMomentMonth(momentMonth, format) {
		return Moment(`${momentMonth + 1}`, 'MM').format(format);
	}

	static chunkArray(arr, size) {
		return arr.length > size ? [arr.slice(0, size), ...this.chunkArray(arr.slice(size), size)] : [arr];
	}

	static titleCase(str) {
		str = str.toLowerCase().split(' ');
		for (let i = 0; i < str.length; i++) {
			str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
		}
		return str.join(' ');
	}

	static bambooHrTimeoffTooltipProps(formatMessage) {
		return {
			infoText: formatMessage({id: 'integrations.bamboohr.time_off_tooltip'}),
			showDelay: 300,
			tooltipPosition: 'bottom',
			canBeMiddle: true,
			customMaxWidth: 1000,
			triangleLocation: 'topMiddle',
			tooltipInfinteDuration: true,
			noHidden: true,
		};
	}

	static storeCustomMetric(componentName, metricName, value) {
		if (process.env.CIRCLE_BRANCH !== 'production') {
			console.log('METRIC:', componentName, metricName, value);
		}
		Util.CommitMutation(
			StoreCustomMetricMutation,
			{
				componentName,
				metricName,
				value,
			},
			() => undefined
		);
	}

	static checkForSageErrorAndShowModal(errors, closeOtherModals = false) {
		if (errors && errors.length > 0 && (errors[0].includes('SAGE ERROR') || errors[0].includes('Sage Intacct ERROR'))) {
			showModal(
				{
					type: MODAL_TYPE.WARNING,
					warningMessageId: 'common.invalid_action_modal_title',
					warningInformation: [errors[0]],
				},
				closeOtherModals
			);
			return true;
		}
		return false;
	}
}

Util.csrf = '';
