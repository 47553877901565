import DataExportFormatter from './DataExportFormatter';
import {idFromGlobalId} from '../GlobalIdUtil';

export default class ExpenseFormatter extends DataExportFormatter {
	#expenses;
	_storeGeneralData(generalData) {
		this.#expenses = new Map();
		generalData.phases.forEach(phase => {
			phase.node.phaseBaselineExpenses.edges.forEach(phaseBaselineExpense => {
				this.#expenses.set(idFromGlobalId(phaseBaselineExpense.node.id), phaseBaselineExpense.node);
			});
		});
	}

	_getFormattedValueHook(column, expense) {
		switch (column) {
			case 'cost':
				return this._formatCurrency(expense.baselineCost);
			case 'item_name':
				return this._formatString(this.#expenses.get(expense.phaseBaselineExpenseId).expenseCategory.name);
			case 'item_type':
				return this._formatIntlMessage('common.expense');
			case 'markup':
				return this._formatPercentage(this.#expenses.get(expense.phaseBaselineExpenseId).expenseMarkup * 100);
			case 'profit':
				return this._formatCurrency(expense.baselineProfit);
			case 'revenue':
				return this._formatCurrency(expense.baselineRevenue);
			case 'margin':
				return this._formatPercentage(Math.round(expense.baselineMargin * 100));
			default:
				return null;
		}
	}
}
