import React, {useMemo} from 'react';
import {Column, HeaderNoPhase, HeaderPhase, RowWrapper} from './Elements.styled';
import {keyBy} from 'lodash';
import Unit4Icon from '../../../../../../images/integrations/unit4-small-logo.svg';
import {PhaseContextMenu, PhaseDatePickerColumn, PhaseNameColumn} from './header-row-elements';
import {getAvailabilityTooltipContent, getColumnWidth} from './utils';
import {useIntl} from 'react-intl';
import Util from '../../../../util/util';
import {CaretIcon, JiraIcon} from 'web-components';
import ForecastTooltip from '../../../tooltips/ForecastTooltip';
import {hasPermission} from '../../../../util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../../../Permissions';
import {ESTIMATION_UNIT, PROJECT_STATUS} from '../../../../../../constants';
import {NumericTileV2, UNIT} from './task-row-elements/NumericTile';
import {PhaseProgressRegistrationPopup} from '../../../popups/progress-registration/PhaseProgressRegistrationPopup';
import {
	CUSTOM_FIELD_PREFIX,
	getCustomFieldColumnName,
} from '../../../../../project-tab/projects/scoping-page/ProjectScopingUtil';
import {CustomFieldColumn} from './header-row-elements/CustomFieldColumn';
import PhaseHeaderRowWarning from '../../../../../../images/components/scoping/PhaseHeaderRowWarning.svg';
import Styled from 'styled-components';
import {GROUP_TYPE} from '../TaskTable';
import {getNonBillableTime} from '../../../../util/time-registration/time-registration-settings/BillableTimeSplitUtil';

const WarningWrapper = Styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 13px;
`;

export const PhaseHeaderRow = ({node, onClick = () => null, cy = ''}, topHeader) => {
	const {id, phase, project, availableColumns, rolledUpValues, currencySymbol, expanded, sharedOptions} = node;
	const {isUsingProjectAllocation, company} = sharedOptions;
	const isMixedAllocationModeEnabled = Util.isMixedAllocationModeEnabled(company);

	//getFormattedCurrencyValue
	const columnsObject = useMemo(() => keyBy(availableColumns, 'name'), [availableColumns]);
	const intl = useIntl();

	const nameColumnWidth = useMemo(() => {
		let width = getColumnWidth('task-name', columnsObject);
		const idColumnWidth = getColumnWidth('task-id', columnsObject);
		width += idColumnWidth || 0;
		return width;
	}, [availableColumns]);

	const getIntegrationLogos = () => {
		const integrationLogos = [];
		if (phase.unit4Id) {
			// Temp untill Unit4 icon is added to web components
			integrationLogos.push(
				<div className="phases-header-integration-logo">
					<img width={18} height={18} src={Unit4Icon} alt={'Unit4'} title={'Unit4'} />
				</div>
			);
		}
		if (phase.jiraId) {
			integrationLogos.push(<JiraIcon size={JiraIcon.SIZE.SMALL} />);
		}
		return integrationLogos;
	};

	if (!phase) return null;

	const {projectColor} = project;
	const {name} = phase;

	const projectLocked = project.status === PROJECT_STATUS.HALTED || project.status === PROJECT_STATUS.DONE;

	const {
		totalAvailability,
		remainingAvailability,
		estimate,
		actualPrice,
		differenceEstimate,
		price,
		plannedCost,
		actualCost,
		progress,
		remaining,
		timeRegistered,
		billableTimeRegistered,
		totalPriceAtCompletion,
		projectedCost,
	} = rolledUpValues;

	const availabilityInformation = {
		totalAvailability: totalAvailability,
		availabilityUntilPhaseEnds: remainingAvailability + rolledUpValues.remaining,
		taskRemaining: rolledUpValues.remaining,
		remainingAvailabilityInPhase: remainingAvailability,
	};

	const displayUtilizationGauge =
		sharedOptions.visibility.groupSetting !== GROUP_TYPE.NO_GROUPING && sharedOptions.visibility.displayScopingAvailability;
	const totalAvailabilityWidth = getColumnWidth('total_availability', columnsObject);
	const remainingAvailabilityWidth = getColumnWidth('remaining_availability', columnsObject);
	const displayAvailability = isUsingProjectAllocation || isMixedAllocationModeEnabled;
	const anyAvailabilityInfoVisible =
		displayAvailability && (displayUtilizationGauge || totalAvailabilityWidth || remainingAvailabilityWidth);

	const getTooltipMessage = () => {
		if (anyAvailabilityInfoVisible) {
			if (remainingAvailability < 0) {
				return getAvailabilityTooltipContent(intl, availabilityInformation);
			} else if (rolledUpValues.roleGroupWarning) {
				return intl.formatMessage({id: 'project_scoping.role_group_warning'});
			} else if (rolledUpValues.personGroupWarning) {
				return intl.formatMessage({id: 'project_scoping.person_group_warning'});
			}
		}
		return null;
	};

	const tooltipMessage = getTooltipMessage();

	const disabled = !hasPermission(PERMISSION_TYPE.PHASE_UPDATE) || projectLocked;

	const cyPhaseName = name ? name.toLowerCase().replace(/\s/g, '') + (cy ? '-' + cy : '') : '';
	if (id === 'no-phase') {
		return (
			<RowWrapper onClick={onClick} data-cy="no-phase-header">
				<HeaderNoPhase>
					{/* task-id and task-name used for the same column*/}
					<PhaseNameColumn
						name={name}
						width={nameColumnWidth}
						phaseId={phase.id}
						projectId={project.id}
						disabled={disabled}
						integrationLogos={[]}
						canEdit={false}
					/>
					{displayAvailability && totalAvailabilityWidth && (
						<Column width={totalAvailabilityWidth} align={columnsObject['total_availability'].align}>
							{Util.convertMinutesToFullHour(totalAvailability, intl)}
						</Column>
					)}{' '}
					{displayAvailability && remainingAvailabilityWidth && (
						<Column width={remainingAvailabilityWidth} align={columnsObject['remaining_availability'].align}>
							{Util.convertMinutesToFullHour(remainingAvailability, intl)}
						</Column>
					)}
					{getColumnWidth('date', columnsObject) && <Column width={getColumnWidth('date', columnsObject)}></Column>}
					{availableColumns
						.filter(
							column =>
								column.checked &&
								column.name?.startsWith(CUSTOM_FIELD_PREFIX) &&
								getColumnWidth(column.name, columnsObject)
						)
						.map(column => (
							<Column width={getColumnWidth(column.name, columnsObject)} />
						))}
					{getColumnWidth('assigned-role', columnsObject) && (
						<Column width={getColumnWidth('assigned-role', columnsObject)} />
					)}
					{getColumnWidth('assigned-person', columnsObject) && (
						<Column width={getColumnWidth('assigned-person', columnsObject)} />
					)}
					{getColumnWidth('status', columnsObject) && <Column width={getColumnWidth('status', columnsObject)} />}
					{getColumnWidth('phase', columnsObject) && <Column width={getColumnWidth('phase', columnsObject)} />}
					{getColumnWidth('sprint', columnsObject) && <Column width={getColumnWidth('sprint', columnsObject)} />}
					{getColumnWidth('done-percentage', columnsObject) && (
						<Column width={getColumnWidth('done-percentage', columnsObject)}></Column>
					)}
					{getColumnWidth('forecast', columnsObject) && (
						<Column
							width={getColumnWidth('forecast', columnsObject)}
							align={columnsObject['forecast'].align}
							paddingRight={16}
						>
							{project.estimationUnit === ESTIMATION_UNIT.POINTS
								? Util.roundPoints(estimate, intl)
								: Util.convertMinutesToFullHour(estimate, intl)}
						</Column>
					)}
					{getColumnWidth('time-entries', columnsObject) && (
						<Column
							width={getColumnWidth('time-entries', columnsObject)}
							align={columnsObject['time-entries'].align}
						>
							{Util.convertMinutesToFullHour(timeRegistered, intl)}
						</Column>
					)}
					{getColumnWidth('billable-time-entries', columnsObject) && (
						<Column
							width={getColumnWidth('billable-time-entries', columnsObject)}
							align={columnsObject['billable-time-entries'].align}
						>
							{Util.convertMinutesToFullHour(billableTimeRegistered, intl)}
						</Column>
					)}
					{getColumnWidth('non-billable-time-entries', columnsObject) && (
						<Column
							width={getColumnWidth('non-billable-time-entries', columnsObject)}
							align={columnsObject['non-billable-time-entries'].align}
						>
							{Util.convertMinutesToFullHour(getNonBillableTime(timeRegistered, billableTimeRegistered), intl)}
						</Column>
					)}
					{getColumnWidth('remaining', columnsObject) && (
						<Column width={getColumnWidth('remaining', columnsObject)} align={columnsObject['remaining'].align}>
							{project.estimationUnit === ESTIMATION_UNIT.POINTS
								? Util.roundPoints(remaining, intl)
								: Util.convertMinutesToFullHour(remaining, intl)}
						</Column>
					)}
					{getColumnWidth('over-forecast', columnsObject) && (
						<Column
							width={getColumnWidth('over-forecast', columnsObject)}
							align={columnsObject['over-forecast'].align}
						>
							{Util.convertMinutesToFullHour(differenceEstimate, intl)}
						</Column>
					)}
					{getColumnWidth('price', columnsObject) && (
						<Column width={getColumnWidth('price', columnsObject)} align={columnsObject['price'].align}>
							{Util.getFormattedCurrencyValue(
								currencySymbol,
								intl.formatNumber(price, {format: 'always_two_decimal'})
							)}
						</Column>
					)}
					{getColumnWidth('projected-billable-value-of-service', columnsObject) && (
						<Column
							width={getColumnWidth('projected-billable-value-of-service', columnsObject)}
							align={columnsObject['projected-billable-value-of-service'].align}
						>
							{Util.getFormattedCurrencyValue(
								currencySymbol,
								intl.formatNumber(totalPriceAtCompletion, {format: 'always_two_decimal'})
							)}
						</Column>
					)}
					{getColumnWidth('actual-price', columnsObject) && (
						<Column
							width={getColumnWidth('actual-price', columnsObject)}
							align={columnsObject['actual-price'].align}
						>
							{Util.getFormattedCurrencyValue(
								currencySymbol,
								intl.formatNumber(actualPrice, {format: 'always_two_decimal'})
							)}
						</Column>
					)}
					{getColumnWidth('planned-cost', columnsObject) && (
						<Column
							width={getColumnWidth('planned-cost', columnsObject)}
							align={columnsObject['planned-cost'].align}
						>
							{Util.getFormattedCurrencyValue(
								currencySymbol,
								intl.formatNumber(plannedCost, {format: 'always_two_decimal'})
							)}
						</Column>
					)}
					{getColumnWidth('projected-cost', columnsObject) && (
						<Column
							width={getColumnWidth('projected-cost', columnsObject)}
							align={columnsObject['projected-cost'].align}
						>
							{Util.getFormattedCurrencyValue(
								currencySymbol,
								intl.formatNumber(projectedCost, {format: 'always_two_decimal'})
							)}
						</Column>
					)}
					{getColumnWidth('actual-cost', columnsObject) && (
						<Column width={getColumnWidth('actual-cost', columnsObject)} align={columnsObject['actual-cost'].align}>
							{Util.getFormattedCurrencyValue(
								currencySymbol,
								intl.formatNumber(actualCost, {format: 'always_two_decimal'})
							)}
						</Column>
					)}
					{getColumnWidth('labels', columnsObject) && (
						<Column width={getColumnWidth('labels', columnsObject)}></Column>
					)}
					{getColumnWidth('approved', columnsObject) && (
						<Column width={getColumnWidth('approved', columnsObject)}></Column>
					)}
					<Column width={60} />
					<Column width={20} />
				</HeaderNoPhase>
			</RowWrapper>
		);
	} else {
		return (
			<RowWrapper
				data-userpilot={topHeader ? 'milestone-header-first' : ''}
				onClick={onClick}
				data-cy={'phase-header-' + cyPhaseName}
			>
				<HeaderPhase color={projectColor}>
					{tooltipMessage && (
						<WarningWrapper>
							<ForecastTooltip content={tooltipMessage} placement={'top'} maxWidth={600}>
								<img src={PhaseHeaderRowWarning} alt={'phase row warning'} height={20} width={20} />
							</ForecastTooltip>
						</WarningWrapper>
					)}
					{/* task-id and task-name used for the same column*/}
					<PhaseNameColumn
						name={name}
						width={nameColumnWidth}
						phaseId={phase.id}
						projectId={project.id}
						disabled={disabled}
						integrationLogos={getIntegrationLogos()}
						backgroundColor={projectColor}
					/>
					{displayAvailability && totalAvailabilityWidth && (
						<Column width={totalAvailabilityWidth} align={columnsObject['total_availability'].align}>
							{Util.convertMinutesToFullHour(totalAvailability, intl)}
						</Column>
					)}{' '}
					{displayAvailability && remainingAvailabilityWidth && (
						<Column width={remainingAvailabilityWidth} align={columnsObject['remaining_availability'].align}>
							{Util.convertMinutesToFullHour(remainingAvailability, intl)}
						</Column>
					)}
					{getColumnWidth('date', columnsObject) && (
						<PhaseDatePickerColumn
							width={getColumnWidth('date', columnsObject)}
							phase={phase}
							project={project}
							disabled={disabled}
							cy={cy}
							align={columnsObject['date'].align}
						/>
					)}
					{availableColumns
						.filter(
							column =>
								column.checked &&
								column.name?.startsWith(CUSTOM_FIELD_PREFIX) &&
								getColumnWidth(column.name, columnsObject)
						)
						.map(column => {
							const entityType = 'PHASE';
							const customFieldValueDefinition = phase.customFieldValues?.edges
								.map(edge => edge.node)
								.find(cfv => getCustomFieldColumnName(entityType, cfv.key) === column.name);
							return (
								<CustomFieldColumn
									key={phase.id + ':' + column.name}
									width={getColumnWidth(column.name, columnsObject)}
									customFieldKey={column.customFieldKey}
									value={customFieldValueDefinition?.value}
									entityId={phase.id}
									entityType={entityType}
									readOnly={column.entityType !== entityType || column.readOnly}
									backgroundColor={projectColor}
									showBox={false}
								/>
							);
						})}
					{getColumnWidth('assigned-role', columnsObject) && (
						<Column width={getColumnWidth('assigned-role', columnsObject)} />
					)}
					{getColumnWidth('assigned-person', columnsObject) && (
						<Column width={getColumnWidth('assigned-person', columnsObject)} />
					)}
					{getColumnWidth('status', columnsObject) && <Column width={getColumnWidth('status', columnsObject)} />}
					{getColumnWidth('phase', columnsObject) && <Column width={getColumnWidth('phase', columnsObject)} />}
					{getColumnWidth('sprint', columnsObject) && <Column width={getColumnWidth('sprint', columnsObject)} />}
					{getColumnWidth('done-percentage', columnsObject) && (
						<Column
							width={getColumnWidth('done-percentage', columnsObject)}
							align={columnsObject['done-percentage'].align}
						>
							{project.manualProgressOnPhasesEnabled ? (
								<NumericTileV2
									id={phase.id}
									key={'progress'}
									darkText={true}
									popup={<PhaseProgressRegistrationPopup phase={phase} projectId={project.id} />}
									value={phase.progress ? phase.progress : 0}
									unit={UNIT.PERCENTAGE}
									cy={'done-percentage-tile'}
								/>
							) : (
								<>
									{Math.round(
										project.manualProgressOnTasksEnabled ? (phase.progress ? phase.progress : 0) : progress
									) + '%'}
								</>
							)}
						</Column>
					)}
					{getColumnWidth('period-target', columnsObject) && (
						<Column width={getColumnWidth('period-target', columnsObject)} />
					)}
					{getColumnWidth('forecast', columnsObject) && (
						<Column
							width={getColumnWidth('forecast', columnsObject)}
							align={columnsObject['forecast'].align}
							paddingRight={16}
							data-cy="phase-header-forecast"
						>
							{project.estimationUnit === ESTIMATION_UNIT.POINTS
								? Util.roundPoints(estimate, intl)
								: Util.convertMinutesToFullHour(estimate, intl)}
						</Column>
					)}
					{getColumnWidth('time-entries', columnsObject) && (
						<Column
							width={getColumnWidth('time-entries', columnsObject)}
							align={columnsObject['time-entries'].align}
						>
							{Util.convertMinutesToFullHour(timeRegistered, intl)}
						</Column>
					)}
					{getColumnWidth('billable-time-entries', columnsObject) && (
						<Column
							width={getColumnWidth('billable-time-entries', columnsObject)}
							align={columnsObject['billable-time-entries'].align}
						>
							{Util.convertMinutesToFullHour(billableTimeRegistered, intl)}
						</Column>
					)}
					{getColumnWidth('non-billable-time-entries', columnsObject) && (
						<Column
							width={getColumnWidth('non-billable-time-entries', columnsObject)}
							align={columnsObject['non-billable-time-entries'].align}
						>
							{Util.convertMinutesToFullHour(getNonBillableTime(timeRegistered, billableTimeRegistered), intl)}
						</Column>
					)}
					{getColumnWidth('remaining', columnsObject) && (
						<Column width={getColumnWidth('remaining', columnsObject)} align={columnsObject['remaining'].align}>
							{project.estimationUnit === ESTIMATION_UNIT.POINTS
								? Util.roundPoints(remaining, intl)
								: Util.convertMinutesToFullHour(remaining, intl)}
						</Column>
					)}
					{getColumnWidth('over-forecast', columnsObject) && (
						<Column
							width={getColumnWidth('over-forecast', columnsObject)}
							align={columnsObject['over-forecast'].align}
						>
							{Util.convertMinutesToFullHour(differenceEstimate, intl)}
						</Column>
					)}
					{getColumnWidth('price', columnsObject) && (
						<Column width={getColumnWidth('price', columnsObject)} align={columnsObject['price'].align}>
							{Util.getFormattedCurrencyValue(
								currencySymbol,
								intl.formatNumber(price, {format: 'always_two_decimal'})
							)}
						</Column>
					)}
					{getColumnWidth('projected-billable-value-of-service', columnsObject) && (
						<Column
							width={getColumnWidth('projected-billable-value-of-service', columnsObject)}
							align={columnsObject['projected-billable-value-of-service'].align}
						>
							{Util.getFormattedCurrencyValue(
								currencySymbol,
								intl.formatNumber(totalPriceAtCompletion, {format: 'always_two_decimal'})
							)}
						</Column>
					)}
					{getColumnWidth('actual-price', columnsObject) && (
						<Column
							width={getColumnWidth('actual-price', columnsObject)}
							align={columnsObject['actual-price'].align}
						>
							{Util.getFormattedCurrencyValue(
								currencySymbol,
								intl.formatNumber(actualPrice, {format: 'always_two_decimal'})
							)}
						</Column>
					)}
					{getColumnWidth('planned-cost', columnsObject) && (
						<Column
							width={getColumnWidth('planned-cost', columnsObject)}
							align={columnsObject['planned-cost'].align}
						>
							{Util.getFormattedCurrencyValue(
								currencySymbol,
								intl.formatNumber(plannedCost, {format: 'always_two_decimal'})
							)}
						</Column>
					)}
					{getColumnWidth('projected-cost', columnsObject) && (
						<Column
							width={getColumnWidth('projected-cost', columnsObject)}
							align={columnsObject['projected-cost'].align}
						>
							{Util.getFormattedCurrencyValue(
								currencySymbol,
								intl.formatNumber(projectedCost, {format: 'always_two_decimal'})
							)}
						</Column>
					)}
					{getColumnWidth('actual-cost', columnsObject) && (
						<Column width={getColumnWidth('actual-cost', columnsObject)} align={columnsObject['actual-cost'].align}>
							{Util.getFormattedCurrencyValue(
								currencySymbol,
								intl.formatNumber(actualCost, {format: 'always_two_decimal'})
							)}
						</Column>
					)}
					{getColumnWidth('labels', columnsObject) && (
						<Column width={getColumnWidth('labels', columnsObject)}></Column>
					)}
					{getColumnWidth('approved', columnsObject) && (
						<Column width={getColumnWidth('approved', columnsObject)}></Column>
					)}
					<Column width={60} align={'right'}>
						<ForecastTooltip content={intl.formatMessage({id: expanded ? 'workflow.collapse' : 'workflow.expand'})}>
							<div data-cy={cy + '-collapse-' + !expanded}>
								<CaretIcon
									color={Util.getTextColor(projectColor)}
									direction={expanded ? CaretIcon.DIRECTION.UP : CaretIcon.DIRECTION.DOWN}
									onClick={onClick}
								/>
							</div>
						</ForecastTooltip>
						<PhaseContextMenu
							company={sharedOptions.company}
							project={project}
							phase={phase}
							disabled={disabled}
							backgroundColor={projectColor}
							cy="phase-context-menu"
						/>
					</Column>
					<Column width={20} />
				</HeaderPhase>
			</RowWrapper>
		);
	}
};
