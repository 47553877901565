import {DeprecatedInput as Input} from '@forecast-it/design-system';
import React, {useState} from 'react';

export const TYPE = {
	PERCENTAGE_DECIMAL: 'percentageDecimal',
	PERCENTAGE_INTEGER: 'percentageInteger',
};

const convertIncoming = (type, value) => {
	let modifiedValue = value;
	if (type === TYPE.PERCENTAGE_DECIMAL) {
		modifiedValue *= 100;
	}
	if (type === TYPE.PERCENTAGE_DECIMAL || type === TYPE.PERCENTAGE_INTEGER) {
		modifiedValue = Math.round(modifiedValue) + '%';
	}
	return modifiedValue;
};

const convertOutgoing = (type, value) => {
	let modifiedValue = value.replace(/\D/g, '');
	if (type === TYPE.PERCENTAGE_DECIMAL || type === TYPE.PERCENTAGE_INTEGER) {
		if (modifiedValue === '') {
			return null;
		} else if (modifiedValue > 100) {
			modifiedValue = 100;
		} else if (modifiedValue < 0) {
			modifiedValue = 0;
		}
		if (modifiedValue !== null) {
			modifiedValue = Math.round(modifiedValue);
		}
	}

	if (type === TYPE.PERCENTAGE_DECIMAL) {
		return modifiedValue / 100;
	} else {
		return modifiedValue;
	}
};

const NumericInput = ({value, type, disabled, onNumberChanged}) => {
	const [valueState, setValueState] = useState(convertIncoming(type, value));

	const inputValueChanged = newValue => {
		const convertedValue = convertOutgoing(type, newValue);
		setValueState(convertIncoming(type, convertedValue));
		onNumberChanged(convertedValue);
	};

	return (
		<Input
			onChange={e => {
				setValueState(e.target.value);
			}}
			onBlur={e => inputValueChanged(e.target.value)}
			disabled={disabled}
			value={valueState}
		/>
	);
};

export default NumericInput;
