/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type appTrello_viewer$ref: FragmentReference;
declare export opaque type appTrello_viewer$fragmentType: appTrello_viewer$ref;
export type appTrello_viewer = {|
  +id: string,
  +$refType: appTrello_viewer$ref,
|};
export type appTrello_viewer$data = appTrello_viewer;
export type appTrello_viewer$key = {
  +$data?: appTrello_viewer$data,
  +$fragmentRefs: appTrello_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "appTrello_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
// prettier-ignore
(node/*: any*/).hash = '6d1242dca180403736a61074ba2f90c4';

module.exports = node;
