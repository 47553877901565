/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Timer_viewer$ref: FragmentReference;
declare export opaque type Timer_viewer$fragmentType: Timer_viewer$ref;
export type Timer_viewer = {|
  +timerStartDate: ?string,
  +timerEndDate: ?string,
  +timerTask: ?{|
    +id: string
  |},
  +$refType: Timer_viewer$ref,
|};
export type Timer_viewer$data = Timer_viewer;
export type Timer_viewer$key = {
  +$data?: Timer_viewer$data,
  +$fragmentRefs: Timer_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Timer_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timerStartDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timerEndDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Task",
      "kind": "LinkedField",
      "name": "timerTask",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
// prettier-ignore
(node/*: any*/).hash = '78b1b516352c9a99a45b8e0b76b62369';

module.exports = node;
