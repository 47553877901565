import DataExportFormatter from './DataExportFormatter';

export default class NovaInsightsCompatibilityFormatter extends DataExportFormatter {
	_getFormattedValueHook(column, company) {
		switch (column) {
			case 'company_id':
				return this._formatNumber(company.companyId);
			case 'company_name':
				return this._formatString(company.companyName);
			case 'running_projects':
				return this._formatNumber(company.runningProjectsCount);
			case 'projects_in_connected_project':
				return this._formatNumber(company.projectsInConnectedProjectCount);
			case 'performance_compatibility':
				return this._formatNumber(company.performanceAvailability);
			case 'focus_compatibility':
				return this._formatNumber(company.focusAvailability);
			case 'predicted_end_compatibility':
				return this._formatNumber(company.predictedEndAvailability);
			case 'nova_insights_compatibility':
				return this._formatNumber(company.novaInsightCompatibilityScore);
			case 'projects_with_time_regs':
				return this._formatNumber(company.timeRegProjectsCount);
			case 'projects_with_done_tasks':
				return this._formatNumber(company.doneTaskProjectsCount);
			case 'projects_with_allocations':
				return this._formatNumber(company.allocationProjectsCount);
			case 'projects_with_estimates':
				return this._formatNumber(company.estimatedProjectsCount);
			case 'predicted_end_compatibility_no_time_regs':
				return this._formatNumber(company.predictedEndWithoutTimeRegAvailability);
			case 'predicted_end_compatibility_no_done_tasks':
				return this._formatNumber(company.predictedEndWithoutDoneTaskAvailability);
			case 'predicted_end_compatibility_no_allocations':
				return this._formatNumber(company.predictedEndWithoutAllocationAvailability);
			case 'predicted_end_compatibility_no_time_regs_no_done_tasks':
				return this._formatNumber(company.predictedEndWithoutTimeRegDoneTaskAvailability);
			case 'predicted_end_compatibility_no_time_regs_no_done_tasks_no_allocations':
				return this._formatNumber(company.predictedEndWithoutTimeRegDoneTaskAllocationAvailability);
			default:
				return null;
		}
	}
}
