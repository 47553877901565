import Util from '../../../../shared/util/util';
import {ESTIMATION_UNIT} from '../../../../../constants';

const getHeaderValue = (totals, estimationUnit, hourString, pointString) => {
	if (totals) {
		if (estimationUnit === ESTIMATION_UNIT.HOURS) {
			return hourString;
		} else if (estimationUnit === ESTIMATION_UNIT.POINTS) {
			return pointString;
		}
	} else {
		return '-';
	}
};

export const totalsEstimate = (totals, intl, estimationUnit) => {
	const hoursString = `${Util.convertMinutesToFullHour(totals.work.estimate.minutes, intl)}`;
	const pointsString = `${intl.formatNumber(totals.work.estimate.points, {format: 'always_two_decimal'})}p`;
	return getHeaderValue(totals, estimationUnit, hoursString, pointsString);
};

export const totalsRemaining = (totals, intl, estimationUnit) => {
	const hoursString = `${Util.convertMinutesToFullHour(totals.work.remaining.minutes, intl)}`;
	const pointsString = `${intl.formatNumber(totals.work.remaining.points, {format: 'always_two_decimal'})}p`;
	return getHeaderValue(totals, estimationUnit, hoursString, pointsString);
};

export const totalsDifference = (totals, intl, estimationUnit) => {
	const hoursString = `${Util.convertMinutesToFullHour(totals.work.estimate.minutes - totals.reported.time, intl)}`;
	const pointsString = `${intl.formatNumber(totals.work.estimate.points - totals.reported.points, {
		format: 'always_two_decimal',
	})}p`;
	return getHeaderValue(totals, estimationUnit, hoursString, pointsString);
};

export const totalsReported = (totals, intl) => {
	return totals ? Util.convertMinutesToFullHour(totals.reported.time, intl) : '-';
};

export const totalsProjected = (totals, intl) => {
	return totals ? Util.convertMinutesToFullHour(totals.reported.projected, intl) : '-';
};

export const totalsPlanned = (totals, currency, intl) =>
	totals && totals.financial.revenue.planned
		? Util.GetFormattedCurrencySymbol(
				currency,
				intl.formatNumber(totals.financial.revenue.planned, {format: 'always_two_decimal'})
		  )
		: '-';

export const totalsActual = (totals, currency, intl) =>
	totals && totals.financial.revenue.actual
		? Util.GetFormattedCurrencySymbol(
				currency,
				intl.formatNumber(totals.financial.revenue.actual, {format: 'always_two_decimal'})
		  )
		: '-';
