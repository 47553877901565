var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import ForecastQueryRenderer from '../../../../../../../ForecastQueryRenderer';
import TimeRegistrationModalTimer, { TimeRegistrationModalTimerQuery } from '../TimeRegistrationModalTimer';
function TimeRegistrationModalTimerQueryWrapper(_a) {
    var modalProps = __rest(_a, []);
    return (React.createElement(ForecastQueryRenderer, { key: "query-render-time_reg_modal_timer", query: TimeRegistrationModalTimerQuery, fetchPolicy: 'network-only', modal: true, render: (relayProps) => {
            return React.createElement(TimeRegistrationModalTimer, Object.assign({}, relayProps, modalProps));
        } }));
}
export default TimeRegistrationModalTimerQueryWrapper;
