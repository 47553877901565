export const getProjectIdFromPath = currentPath => {
	if (!currentPath.startsWith('/project/')) {
		return null;
	}
	const match = /\/project\/([^/]*)\//g.exec(currentPath);
	return match ? match[1] : null;
};

export const getConnectProjectIDFromPath = currentPath =>
	currentPath.startsWith('/connected/X') ? currentPath.match(/\d+/g)[0] : null;
