import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Util from '../../../util/util';
import {fileExtensions} from '../../../../../constants';
import CoverImageIcon from '../../../../../images/components/overlay/cover.svg';
import ActionMenu from '../../action-menu/actions_menu';
import {injectIntl} from 'react-intl';
import {MODAL_TYPE, showModal} from '../../modals/generic_modal_conductor';
import {createToast} from '../../toasts/another-toast/toaster';
import DeleteFileMutation from '../../../../../mutations/delete_file_mutation.modern.js';
import UpdateTaskMutation from '../../../../../mutations/update_task_mutation.modern';
import UpdateFileMutation from '../../../../../mutations/update_file_mutation.modern';
import DeleteGoogleDriveFileLinkMutation from '../../../../../mutations/delete_google_drive_file_link';
import Moment from 'moment';
import DirectApi from '../../../../../directApi';

class TaskModalFile extends Component {
	constructor(props) {
		super(props);

		this.nameInput = React.createRef();
		const {
			file: {id, name},
		} = this.props;

		this.state = {
			fileName: name.split('.').shift(),
			fileExtension: name.split('.').pop(),
			fileId: id,
			isRenaming: false,
		};
	}

	isSafari() {
		const userAgent = navigator.userAgent.toLowerCase();
		return userAgent.indexOf('safari') !== -1 && userAgent.indexOf('chrome') === -1;
	}

	getFileActionMenuOptions() {
		const {
			intl: {formatMessage},
			task: {coverFile},
			file: {mimeType},
		} = this.props;

		const {fileId} = this.state;

		const coverFileId = coverFile ? coverFile.id : null;

		const options = [];

		// user should be able to set only images as cover
		if (mimeType.includes('image')) {
			// change option if the image is the cover image or not
			if (coverFileId === fileId) {
				options.push({
					text: formatMessage({id: 'file_preview.delete_cover'}),
					onClick: this.addCoverFile.bind(this, null),
				});
			} else {
				options.push({
					text: formatMessage({id: 'file_preview.make_cover'}),
					onClick: this.addCoverFile.bind(this, fileId),
				});
			}
		}

		options.push({
			text: formatMessage({id: 'common.rename'}),
			onClick: this.showRenameFileInput.bind(this),
		});

		options.push({
			text: formatMessage({id: 'common.delete'}),
			onClick: this.deleteFile.bind(this),
			cy: 'delete-file-menu-point',
		});

		return options;
	}

	handleInputChange(e) {
		this.setState({fileName: e.target.value});
	}

	handleKeyPress(e) {
		if (e && e.key === 'Enter') {
			this.renameFile();
		}
	}

	showRenameFileInput() {
		this.setState({isRenaming: true}, () => this.nameInput.current.select());
	}

	renameFile() {
		if (this.state.fileName.length) {
			const fileName = `${this.state.fileName}.${this.state.fileExtension}`;
			const {fileId} = this.state;
			Util.CommitMutation(UpdateFileMutation, {
				ids: [fileId],
				name: fileName,
			});
		} else {
			this.setState({fileName: this.props.file.name.split('.').shift()});
		}

		this.setState({isRenaming: false});
	}

	deleteFile() {
		const {fileId} = this.state;
		if (fileId) {
			const onSuccess = () => {
				createToast({duration: 5000, message: this.props.intl.formatMessage({id: 'file.has-been-deleted'})});
			};
			const obj = {
				files: [fileId],
			};
			obj.taskId = this.props.task.id;
			Util.CommitMutation(DeleteFileMutation, obj, onSuccess);
		}
	}

	addCoverFile(id) {
		Util.CommitMutation(UpdateTaskMutation, {
			ids: [this.props.task.id],
			coverFileId: id,
		});
	}

	showFile(fileSource) {
		const isFileVideoSet = new Set();
		const files = [];
		for (const fileEdge of this.props.task.files.edges) {
			if (!Util.FileIsImageOrVideo(fileEdge.node) || (this.isSafari() && fileEdge.node.mimeType.includes('video')))
				continue;
			const fileSource = DirectApi.fileSrc(fileEdge.node.id);
			files.push(fileSource);
			if (Util.FileIsVideo(fileEdge.node)) {
				isFileVideoSet.add(fileSource);
			}
		}
		showModal({
			type: MODAL_TYPE.EXPAND_FILE,
			file: fileSource,
			files,
			isFileVideoSet,
		});
	}

	unlinkGoogleDriveFile() {
		const {fileId} = this.state;
		const input = {
			id: fileId,
			taskId: this.props.task.id,
		};
		Util.CommitMutation(DeleteGoogleDriveFileLinkMutation, input);
	}

	render() {
		const {file, task, isTaskReadOnly, isBrowsingOnSafari, gDriveFile} = this.props;
		const {formatMessage, formatDate} = this.props.intl;
		const {yearCreated, monthCreated, dayCreated, hourCreated, minuteCreated, secondCreated, person} = file;
		const {fileName, fileId, fileExtension, isRenaming} = this.state;
		const fileIsImageOrVideo = Util.FileIsImageOrVideo(file);

		return !gDriveFile ? (
			<div
				className="file"
				title={`${fileName} (${formatDate(
					Moment.utc({
						year: yearCreated,
						month: monthCreated - 1,
						date: dayCreated,
						hour: hourCreated,
						minute: minuteCreated,
						second: secondCreated,
					}),
					{
						year: 'numeric',
						month: 'numeric',
						day: 'numeric',
						hour: 'numeric',
						minute: 'numeric',
						second: 'numeric',
					}
				)}) ${person ? `- ${formatMessage({id: 'common.uploaded_by'})} ` + person.fullName : ''}`}
			>
				<div className="file-image">
					{this.props.showPreview ? (
						<img crossOrigin="use-credentials" src={fileId ? DirectApi.fileSrc(fileId) : file.src} alt="file" />
					) : (
						<div
							className={
								fileExtensions.includes(file.name.toLowerCase().split('.').pop())
									? 'file-format ' + file.name.toLowerCase().split('.').pop()
									: 'file-format file'
							}
						/>
					)}

					{task.coverFile && task.coverFile.id === fileId ? (
						<img
							crossOrigin="use-credentials"
							data-cy="file-cover-indicator"
							className="image-is-cover-icon"
							src={CoverImageIcon}
							title={formatMessage({id: 'task-modal.image-is-cover'})}
						/>
					) : null}

					{fileIsImageOrVideo ? (
						<div className="file-options">
							{isBrowsingOnSafari && file.mimeType.includes('video') ? null : (
								<div
									className="file-option-container"
									onClick={this.showFile.bind(this, fileId ? DirectApi.fileSrc(fileId) : file.src)}
								>
									<div className="full-screen-icon" />
								</div>
							)}
						</div>
					) : null}
				</div>

				<div className="file-name">
					{isRenaming ? (
						<input
							ref={this.nameInput}
							onKeyPress={this.handleKeyPress.bind(this)}
							onChange={this.handleInputChange.bind(this)}
							onBlur={this.renameFile.bind(this)}
							type="text"
							value={fileName}
						/>
					) : (
						<>
							<a className="download" href={DirectApi.fileSrc(fileId) + '?1111'}>
								<div className="download-icon">
									<svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 15 18">
										<path
											fill="#292929"
											fillRule="evenodd"
											d="M6.3 0h1.8v9.527l2.964-2.963 1.273 1.272L7.2 12.972 2.064 7.836l1.273-1.272L6.3 9.527V0zm6.3 14.4h1.8v1.8c0 .993-.807 1.8-1.8 1.8H1.8C.807 18 0 17.193 0 16.2v-1.8h1.8v1.8h10.8v-1.8z"
										/>
									</svg>
								</div>
							</a>
							<p data-id={fileId} data-cy="file-name">{`${fileName}.${fileExtension}`}</p>
						</>
					)}

					{!isTaskReadOnly && !isRenaming ? (
						<ActionMenu disabled={false} options={this.getFileActionMenuOptions()} whiteInner={true} />
					) : null}
				</div>
			</div>
		) : (
			<div key={fileId} className="file">
				<div className="file-image">
					<img src={file.thumb} alt="file" />
					<div className="file-options">
						<a
							className="file-option-container download"
							href={file.link}
							target="_blank"
							rel="noopener noreferrer"
						>
							<div className="full-screen-icon" />
						</a>
					</div>
				</div>
				<div className="file-name">
					<p>{file.name}</p>
					<div className="delete-icon" onClick={this.unlinkGoogleDriveFile.bind(this)} />
				</div>
			</div>
		);
	}
}

TaskModalFile.propTypes = {
	file: PropTypes.shape({
		id: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		mimeType: PropTypes.string,
	}).isRequired,
	isTaskReadOnly: PropTypes.bool,
	gDriveFile: PropTypes.bool,
};

export default injectIntl(TaskModalFile);
