import React from 'react';
import styled from 'styled-components';
const Circle = styled.div `
	width: 8px;
	height: 8px;
	border: 1px solid #75c2ff;
	border-radius: 50%;
	background-color: #75c2ff;
`;
const Center = styled.div `
	display: flex;
	justify-content: center;
	align-items: center;

	height: 30px;
	width: 30px;

	cursor: pointer;
`;
function AdditionalPropertyDifferenceIndicator() {
    return (React.createElement(Center, null,
        React.createElement(Circle, { "data-cy": 'additional-prop-diff-indicator' })));
}
export default AdditionalPropertyDifferenceIndicator;
