/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type RecentButton_viewer$ref = any;
export type RecentButton_QueryVariables = {||};
export type RecentButton_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: RecentButton_viewer$ref,
  |}
|};
export type RecentButton_Query = {|
  variables: RecentButton_QueryVariables,
  response: RecentButton_QueryResponse,
|};
*/


/*
query RecentButton_Query {
  viewer {
    actualPersonId
    component(name: "top_navigation_recent")
    ...RecentButton_viewer
    id
  }
}

fragment RecentButton_viewer on Viewer {
  id
  backendId
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "top_navigation_recent"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"top_navigation_recent\")"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RecentButton_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RecentButton_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RecentButton_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "backendId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "RecentButton_Query",
    "operationKind": "query",
    "text": "query RecentButton_Query {\n  viewer {\n    actualPersonId\n    component(name: \"top_navigation_recent\")\n    ...RecentButton_viewer\n    id\n  }\n}\n\nfragment RecentButton_viewer on Viewer {\n  id\n  backendId\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c57d907dd727a0924d211df2d1e9a0f2';

module.exports = node;
