import React from 'react';
import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';

const DemoLineItem = props => {
	let itemText = '';
	let itemClass = '';
	let itemDeleteAction;
	if (props.demo.trialDemo) {
		itemText = <FormattedMessage id="settings_details.demo_is_trial" />;
		itemClass = 'trial';
	} else if (props.demo.sharedInCompany && props.viewerEmail === props.demo.createdByEmail) {
		itemText = <FormattedMessage id="common.delete_shared" />;
		itemClass = 'delete';
		itemDeleteAction = () => props.onDelete(props.demo);
	} else if (props.demo.sharedInCompany && props.viewerEmail !== props.demo.createdByEmail) {
		itemText = <FormattedMessage id="common.shared" />;
		itemClass = 'shared';
	} else {
		itemText = <FormattedMessage id="common.delete" />;
		itemClass = 'delete';
		itemDeleteAction = () => props.onDelete(props.demo);
	}

	return (
		<tr key={props.demo.id}>
			<td className="name" onClick={() => props.onSelect(props.demo)}>
				{props.demo.name}
			</td>
			<td className="creator">{props.demo.createdByEmail}</td>
			<td className="creator">{props.demo.numberOfProjects}</td>
			{
				<td onClick={itemDeleteAction} className={'option ' + itemClass}>
					{itemText}
				</td>
			}
		</tr>
	);
};

DemoLineItem.propTypes = {
	demo: PropTypes.object.isRequired,
	onSelect: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	viewerEmail: PropTypes.string.isRequired,
};

export default DemoLineItem;
