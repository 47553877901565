import { useEffect } from 'react';
import Util from '../util/util';
import afterFrame from 'afterframe';
const useMeasureRender = (componentName, metricName, deps = []) => {
    useEffect(() => {
        const startTime = performance.now();
        afterFrame(() => {
            const duration = performance.now() - startTime;
            Util.storeCustomMetric(componentName, metricName, duration);
        });
    }, deps);
};
export default useMeasureRender;
