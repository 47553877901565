import React from 'react';
import styled from 'styled-components';
import {CSS_CONSTANTS} from '../../../../../../../../css_variables';
import InlineLoader from '../../../../../inline-loader/inline_loader';

const LoaderWrapper = styled.div`
	width: 516px;
	height: 130px;
	border: 1px solid ${CSS_CONSTANTS.v2_border_gray};
	border-radius: 4px;
	padding: 8px;
	font-size: 13px;
	font-weight: 400;
`;

export const SimilarTaskSectionLoader = () => {
	return (
		<LoaderWrapper>
			<InlineLoader loaderText={'Loading similar tasks...'}></InlineLoader>
		</LoaderWrapper>
	);
};
