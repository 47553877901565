import React, { forwardRef, memo, useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Button, EMPHASIS, FlexRow, Icon, Text, WorkSignifier } from '@forecast-it/design-system';
import GlobalSearchModal from '../../../../../../navigation/header/global-search/GlobalSearchModal';
import useEventListener from '../../../../../../shared/hooks/useEventListener';
import { TimeRegistrationEntity } from '../types/TimeRegistrationEntityId';
import { isTaskTimeRegistrationAllowed } from '../../../../../util/time-registration/time-registration-settings/TimeRegistrationTaskFilter';
import { getAllowedEntitiesText } from '../../../../../../navigation/header/global-search/formatters/FormatterUtil';
import ProjectIndicator from '../../../../project-indicator/ProjectIndicator';
import { asFragment } from '../../../../../../../relay-helpers/TSRelayConverter';
import { trackEvent } from '../../../../../../../tracking/amplitude/TrackingV2';
const SearchSelection = styled.button `
	height: 70px;
	padding: 1px 16px;
	border: 1px solid #727483;
	border-radius: 4px;

	background-color: ${({ disabled }) => (disabled ? '#e7e7f3' : '#ffffff')};

	&:hover {
		background-color: #e7e7f3;
	}

	&:focus-visible {
		border-width: 2px;
		border-color: #3b94ffff;
		outline-style: none;
		padding: 0 15px;
	}
`;
const IconTitleContainer = styled.div `
	display: flex;
	flex: 1 1;
	align-items: center;
	gap: 12px;
	min-width: 0;
`;
const TypeContainer = styled.div `
	display: flex;
	align-items: center;
	gap: 12px;
	min-width: 0;
`;
const TitleContainer = styled.div `
	display: flex;
	flex-direction: column;
	gap: 4px;
	min-width: 0;
	width: 100%;
	text-align: left;
	align-items: flex-start;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;
const ClearButtonStyle = styled.div `
	div > button {
		border: none;

		&:hover {
			border: none;
		}
	}
`;
function getProjectFromOptimisticSearchResult(optimisticSearchResult) {
    return asFragment({
        companyProjectId: optimisticSearchResult.companyProjectId,
        customProjectId: optimisticSearchResult.customProjectId,
        projectColor: optimisticSearchResult.projectColor,
        name: optimisticSearchResult.projectName,
    });
}
// Home-brew optimistic formatter from raw search result to provide instant result feedback while modal does fetching and validation of selecting in the background
function formatOptimisticEntity(optimisticEntity) {
    const optimisticSearchResult = optimisticEntity.searchResult;
    if (optimisticSearchResult) {
        switch (optimisticEntity.variant) {
            case 'TASK':
                return {
                    title: optimisticSearchResult.name || optimisticSearchResult.entityName,
                    subtitle: `Task in ${optimisticSearchResult.projectName}`,
                    project: getProjectFromOptimisticSearchResult(optimisticSearchResult),
                    companyTaskId: optimisticSearchResult.companyTaskId,
                    type: 'Task',
                };
            case 'PROJECT':
                return {
                    title: optimisticSearchResult.name,
                    subtitle: optimisticSearchResult.clientName
                        ? `Project for client ${optimisticSearchResult.clientName}`
                        : 'Project',
                    project: getProjectFromOptimisticSearchResult(optimisticSearchResult),
                    type: 'Project',
                };
            case 'INTERNAL_TIME':
                return {
                    isInternalTime: true,
                    title: optimisticSearchResult.entityName,
                    type: 'Internal time',
                };
            default:
                return null;
        }
    }
}
function formatTargetEntity(targetEntity) {
    var _a, _b;
    switch (targetEntity.entity) {
        case TimeRegistrationEntity.Task:
            return {
                title: targetEntity.name,
                subtitle: `Task in ${(_a = targetEntity.project) === null || _a === void 0 ? void 0 : _a.name}`,
                project: targetEntity.project,
                companyTaskId: targetEntity.companyTaskId,
                type: 'Task',
            };
        case TimeRegistrationEntity.Project:
            return {
                title: targetEntity.name,
                subtitle: ((_b = targetEntity.client) === null || _b === void 0 ? void 0 : _b.name) ? `Project for client ${targetEntity.client.name}` : 'Project',
                project: targetEntity,
                type: 'Project',
            };
        case TimeRegistrationEntity.IdleTime:
            return {
                isInternalTime: targetEntity.isInternalTime,
                title: targetEntity.name,
                type: targetEntity.isInternalTime ? 'Internal time' : 'Time off',
            };
    }
}
function getFormattedTargetEntity({ targetEntity, optimisticSearchResult, targetEntityFetching, }) {
    if (targetEntityFetching && optimisticSearchResult) {
        const formattedOptimisticEntity = formatOptimisticEntity(optimisticSearchResult);
        if (formattedOptimisticEntity) {
            return formattedOptimisticEntity;
        }
    }
    if (targetEntity) {
        return formatTargetEntity(targetEntity);
    }
    return null;
}
const suggestionsFilter = (searchSuggestion, personId) => {
    if (searchSuggestion.suggestionVariant === 'TASK') {
        const personIdString = personId ? personId.toString() : '';
        const assignedPersonIds = searchSuggestion.assignedPersonIds
            ? searchSuggestion.assignedPersonIds.map(personId => personId.toString())
            : [];
        return isTaskTimeRegistrationAllowed(searchSuggestion.statusCategory, personIdString, assignedPersonIds, searchSuggestion);
    }
    return true;
};
const TimeRegistrationModalSearch = memo(forwardRef(({ targetEntity, targetEntityFetching, showTaskIndicator = false, companyId, personId, onResultSelected, clearSelected, allowedEntities, projectId, baseSearch, dataCy, trackingObject, disabled, }, ref) => {
    var _a, _b, _c, _d, _e;
    const intl = useIntl();
    const fallbackRef = useRef(null);
    const searchButtonRef = ref !== null && typeof ref !== 'function' ? ref : fallbackRef;
    const [searchShown, setSearchShown] = useState(false);
    const [optimisticSearchResult, setOptimisticSearchResult] = useState(null);
    const [, rerender] = useState({});
    const handleSearchSelection = useCallback(searchResult => {
        setOptimisticSearchResult(searchResult);
        onResultSelected(searchResult);
    }, []);
    const hideSearch = useCallback(() => {
        var _a;
        setSearchShown(false);
        (_a = searchButtonRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, []);
    const handleResize = useCallback(() => {
        rerender({}); // Causes modalYOffset to recalculate. Not sure if this is the most performant solution, but it works ¯\_(ツ)_/¯
    }, []);
    useEventListener('resize', handleResize, window, { throttleInterval: 20 });
    const modalYOffset = (_b = (_a = searchButtonRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect()) === null || _b === void 0 ? void 0 : _b.top;
    const formattedTargetEntity = getFormattedTargetEntity({
        targetEntity,
        optimisticSearchResult,
        targetEntityFetching,
    });
    const allowedEntitiesText = getAllowedEntitiesText(allowedEntities, intl);
    const clearSelection = () => {
        trackEvent(trackingObject, 'Cleared');
        clearSelected(formattedTargetEntity);
    };
    const handleBackspace = (event) => {
        if (event.key === 'Backspace') {
            clearSelection();
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(SearchSelection, { title: formattedTargetEntity
                ? `${formattedTargetEntity.title} - ${formattedTargetEntity.subtitle}`
                : `Search`, ref: searchButtonRef, type: "button", "aria-label": "Search", "aria-details": "Use this search field to look up a task, project, or track internal time.", "data-cy": dataCy, onClick: () => setSearchShown(true), onKeyDown: handleBackspace, disabled: disabled }, formattedTargetEntity ? (React.createElement(FlexRow, { alignItems: "center", justifyContent: "space-between", gap: 's' },
            React.createElement(IconTitleContainer, null,
                showTaskIndicator ? (React.createElement(WorkSignifier, { workId: `T${formattedTargetEntity === null || formattedTargetEntity === void 0 ? void 0 : formattedTargetEntity.companyTaskId}`, name: ((_c = formattedTargetEntity === null || formattedTargetEntity === void 0 ? void 0 : formattedTargetEntity.project) === null || _c === void 0 ? void 0 : _c.name) || undefined, color: ((_d = formattedTargetEntity === null || formattedTargetEntity === void 0 ? void 0 : formattedTargetEntity.project) === null || _d === void 0 ? void 0 : _d.projectColor) || '', outline: true })) : (((_e = formattedTargetEntity === null || formattedTargetEntity === void 0 ? void 0 : formattedTargetEntity.project) === null || _e === void 0 ? void 0 : _e.companyProjectId) && (React.createElement(ProjectIndicator, { outline: true, project: formattedTargetEntity.project }))),
                formattedTargetEntity.isInternalTime && React.createElement(Icon, { icon: "internalTime", size: "l" }),
                React.createElement(TitleContainer, null,
                    React.createElement(Text, { ellipsis: true }, formattedTargetEntity.title),
                    !formattedTargetEntity.isInternalTime && (React.createElement(Text, { ellipsis: true, size: 2, color: "medium" }, formattedTargetEntity.subtitle)))),
            React.createElement(TypeContainer, null,
                React.createElement(Text, { color: "medium" }, formattedTargetEntity.type),
                !disabled ? (React.createElement(ClearButtonStyle, null,
                    React.createElement(Button, { type: 'button', icon: 'close', emphasis: EMPHASIS.MONO, onPress: clearSelection }))) : null))) : (React.createElement(FlexRow, { alignItems: "center", gap: 's' },
            React.createElement(Icon, { icon: 'search', size: "m" }),
            React.createElement(Text, { color: "medium" }, "Search")))),
        searchShown ? (React.createElement(GlobalSearchModal, { companyId: companyId, personId: personId, isEmbedded: true, modalYOffset: modalYOffset, searchModalConfig: {
                inputPlaceholder: 'Search for a ' + allowedEntitiesText,
                allowLinks: false,
                showGiveFeedback: false,
                searchEndpoint: 'globalsearch/time-registration',
                recentSearchKey: 'recently-searched-time-registration',
                trackingObject: trackingObject,
                allowedEntities: allowedEntities,
                baseSearch: baseSearch,
                suggestionsFilter: searchSuggestion => suggestionsFilter(searchSuggestion, personId),
                projectId,
            }, hideSearch: hideSearch, onResultSelected: handleSearchSelection })) : null));
}));
export default TimeRegistrationModalSearch;
