import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PlaceholderInitials from './placeholder_initials';
import {CSS_CONSTANTS} from '../../../../css_variables';
import {getFirstLetter} from '../../../../components/canvas-scheduling/canvas-timeline/canvas_timeline_util';

const ForecastPlaceholder = styled.div`
	display: flex;
	align-items: center;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	.placeholder-info {
		overflow: hidden;
		text-overflow: ellipsis;
		width: calc(100% - 24px);
		padding: 0 3px 0 0px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		span {
			white-space: nowrap;
			text-overflow: ellipsis;
			display: block;
			overflow: hidden;
		}
		.name {
			font-size: 13px;
			padding-left: 7px;
		}
		.role {
			font-weight: ${() => CSS_CONSTANTS.base_font_medium_weight};
			font-size: 10px;
			padding-left: 7px;
			color: ${() => CSS_CONSTANTS.v2_text_light_gray};
		}
		.role,
		.name-inner {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
`;

const Placeholder = ({placeholder, className}) => {
	return (
		<ForecastPlaceholder className={className} title={placeholder.name}>
			<div className="placeholder-component-initials-container">
				<PlaceholderInitials initials={getFirstLetter(placeholder.name)} />
			</div>
			<div className={'no-drag placeholder-info'}>
				<span className={'name no-drag'}>
					<div className={'name-inner'}>{placeholder.name}</div>
				</span>
				{placeholder.role && <span className={'role no-drag'}>{placeholder.role.name}</span>}
			</div>
		</ForecastPlaceholder>
	);
};

Placeholder.propTypes = {
	placeholder: PropTypes.shape({
		name: PropTypes.string,
		role: PropTypes.shape({
			name: PropTypes.string,
		}),
	}),
	className: PropTypes.string,
};

export default Placeholder;
