import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import Util from '../../../shared/util/util';
import ActionMenu from '../../../../components/action_menu';
import DeletePersonMutation from '../../../../mutations/delete_person_mutation';
import TdLink from '../../../../components/td_link';
import {createToast} from '../../../shared/components/toasts/another-toast/toaster';
import {MODAL_TYPE, showModal} from '../../../shared/components/modals/generic_modal_conductor';
import SelectInput from '../../../../components/inputs/select_input';
import DeleteProjectPersonMutation from '../../../../mutations/delete_project_person_mutation';
import CreateProjectPersonClientMutation from '../../../../mutations/create_project_person_client_mutation';
import {hasPermission} from '../../../shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../Permissions';
import UpdatePersonMutation from '../../../../mutations/update_person_mutation.modern';
import UpdatePersonInvitedMutation from '../../../../mutations/update_client_person_mutation';

class SettingsClientContact extends Component {
	constructor(props) {
		super(props);
		const firstName = this.props.user.firstName ? this.props.user.firstName : '';
		const lastName = this.props.user.lastName ? ' ' + this.props.user.lastName : '';
		this.state = {
			name: firstName + lastName,
			email: this.props.user.email ? this.props.user.email : '',
			phone: this.props.user.phone ? this.props.user.phone : '',
			showDeletePrompt: false,
			assignClickDisabled: false,
		};
	}

	deleteClientUser() {
		const onSuccess = () => {
			createToast({duration: 5000, message: this.props.intl.formatMessage({id: 'settings.client-user_deleted'})});
		};

		const callbackPositive = () => {
			Util.CommitMutation(
				DeletePersonMutation,
				{
					id: this.props.user.id,
					parentId: this.props.clientId,
				},
				onSuccess
			);
		};

		showModal({
			type: MODAL_TYPE.GENERIC_DELETION_WARNING,
			deleteCallback: callbackPositive,
		});
	}

	editClientUser() {
		this.props.history.push('/admin/team/view-user/' + this.props.user.id);
	}

	createProjectPerson(projectId, onSuccess) {
		Util.CommitMutation(
			CreateProjectPersonClientMutation,
			{
				personId: this.props.user.id,
				projectId: projectId,
			},
			onSuccess
		);
	}

	updatePerson(onSuccess) {
		Util.CommitMutation(
			UpdatePersonMutation,
			{
				id: this.props.user.id,
				invited: true,
				profileIds: [btoa('Profile:-2')],
			},
			onSuccess
		);
	}

	updatePersonInvited(invited, onSuccess) {
		Util.CommitMutation(
			UpdatePersonInvitedMutation,
			{
				id: this.props.user.id,
				clientId: this.props.clientId,
				invited,
			},
			onSuccess
		);
	}

	handleAccessToProjectChange() {
		if (!this.state.assignClickDisabled) {
			this.setState({assignClickDisabled: true});
			//Delete project person mutation
			if (this.props.clientProjectPerson) {
				Util.CommitMutation(
					DeleteProjectPersonMutation,
					{
						ids: [this.props.clientProjectPerson.node.id],
						projectId: this.props.projectId,
					},
					() => this.setState({assignClickDisabled: false})
				);
			} else {
				const onSuccess = () => this.setState({assignClickDisabled: false});

				//Create project person
				//validate if has email, if not must be entered to give access
				if (!this.props.user.email || this.props.user.email === '') {
					showModal({
						type: MODAL_TYPE.ADD_CLIENT_USER,
						editClientUser: true,
						clientUser: this.props.user,
						clientId: this.props.clientId,
						callbackPositive: this.createProjectPerson.bind(this, this.props.projectId, onSuccess),
					});
				} else {
					this.createProjectPerson(this.props.projectId, this.updatePersonInvited.bind(this, true, onSuccess));
				}
			}
		}
	}

	addToProjects(selected) {
		let selectedValues = selected.map(s => s.value);

		let currentProjectIds = [];
		if (this.props.personIdToProjectIdsMap.has(this.props.user.id)) {
			currentProjectIds = this.props.personIdToProjectIdsMap.get(this.props.user.id).map(project => project.value);
		}

		const idToDelete = currentProjectIds.find(currentId => {
			return !selectedValues.includes(currentId);
		});
		const idToAdd = selectedValues.find(selectedId => {
			return !currentProjectIds.includes(selectedId);
		});
		if (idToAdd) {
			this.createProjectPerson(idToAdd, this.updatePerson.bind(this));
		}
		if (idToDelete) {
			Util.CommitMutation(DeleteProjectPersonMutation, {
				ids: [
					this.props.viewer.projects.edges
						.find(projectEdge => projectEdge.node.id === idToDelete)
						.node.projectPersons.edges.find(
							projectPersonEdge => projectPersonEdge.node.person.id === this.props.user.id
						).node.id,
				],
				projectId: idToDelete,
			});
		}
	}

	clearAllProjects() {
		const projectIds = [];
		this.props.personIdToProjectIdsMap.forEach(element => {
			element.forEach(e => {
				projectIds.push(e.value);
			});
		});

		const ids = [];
		for (const id of projectIds) {
			const project = this.props.viewer.projects.edges.find(projectEdge => projectEdge.node.id === id);
			if (project) {
				const projectPerson = project.node.projectPersons.edges.find(
					projectPersonEdge => projectPersonEdge.node.person.id === this.props.user.id
				);
				if (projectPerson) {
					ids.push(projectPerson.node.id);
				}
			}
		}

		Util.CommitMutation(DeleteProjectPersonMutation, {
			ids,
		});
	}

	handleAccessProjectsSelect(selected, clearAll = false) {
		if (!this.props.user.email || this.props.user.email === '') {
			showModal({
				type: MODAL_TYPE.ADD_CLIENT_USER,
				editClientUser: true,
				clientUser: this.props.user,
				callbackPositive: this.addToProjects.bind(this, selected),
			});
		} else if (clearAll) {
			this.clearAllProjects();
		} else {
			this.addToProjects(selected, clearAll);
		}
	}

	render() {
		const path = '/admin/team/view-user/' + this.props.user.id;
		const {formatMessage} = this.props.intl;
		const hasManageAcountSettingsPermission = hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS);

		return (
			<tr className={'client-row ' + (!hasManageAcountSettingsPermission ? 'disabled' : '')}>
				<TdLink
					locked={!hasManageAcountSettingsPermission}
					content={`${this.props.user.firstName} ${this.props.user.lastName}`}
					path={path}
				/>
				<TdLink locked={!hasManageAcountSettingsPermission} content={this.props.user.email} path={path} />
				<TdLink locked={!hasManageAcountSettingsPermission} content={this.props.user.phone} path={path} />
				<td>
					{this.props.isProjectClient ? (
						<div className="div-center">
							<input
								type="checkbox"
								disabled={!this.props.hasProjectUpdatePermission || this.state.assignClickDisabled}
								onChange={this.handleAccessToProjectChange.bind(this)}
								checked={
									this.props.clientProjectPerson !== null && this.props.clientProjectPerson !== undefined
								}
							/>
						</div>
					) : this.props.projectOptions.length ? (
						<SelectInput
							tableCellSelect={true}
							clearable={true}
							hideLabel={true}
							options={this.props.projectOptions}
							onChange={this.handleAccessProjectsSelect.bind(this)}
							value={
								this.props.personIdToProjectIdsMap && this.props.personIdToProjectIdsMap.has(this.props.user.id)
									? this.props.personIdToProjectIdsMap.get(this.props.user.id)
									: []
							}
							multi={true}
							showValuesInline={true}
							placeholder={formatMessage({id: 'client_page.project_access_placeholder'})}
						/>
					) : null}
				</td>
				<td>
					{!hasManageAcountSettingsPermission ? null : (
						<ActionMenu
							options={[
								{
									label: formatMessage({id: 'common.delete'}),
									onClick: this.deleteClientUser.bind(this),
								},
								{label: formatMessage({id: 'common.edit'}), onClick: this.editClientUser.bind(this)},
							]}
							horizontalIcon={true}
						/>
					)}
				</td>
			</tr>
		);
	}
}

export default injectIntl(withRouter(SettingsClientContact));
