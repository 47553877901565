/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type jiraTempoOauthTokenSection_company$ref: FragmentReference;
declare export opaque type jiraTempoOauthTokenSection_company$fragmentType: jiraTempoOauthTokenSection_company$ref;
export type jiraTempoOauthTokenSection_company = {|
  +tempoOauthTokenApplied: ?boolean,
  +jiraBaseUrl: ?string,
  +tempoOauthClientId: ?string,
  +tempoOauthClientSecret: ?string,
  +$refType: jiraTempoOauthTokenSection_company$ref,
|};
export type jiraTempoOauthTokenSection_company$data = jiraTempoOauthTokenSection_company;
export type jiraTempoOauthTokenSection_company$key = {
  +$data?: jiraTempoOauthTokenSection_company$data,
  +$fragmentRefs: jiraTempoOauthTokenSection_company$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "jiraTempoOauthTokenSection_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tempoOauthTokenApplied",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jiraBaseUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tempoOauthClientId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tempoOauthClientSecret",
      "storageKey": null
    }
  ],
  "type": "Company"
};
// prettier-ignore
(node/*: any*/).hash = '9cf00816a64bfb2cce5c70ea646db180';

module.exports = node;
