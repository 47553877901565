import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import styled from 'styled-components';
import AssignedDropdown from '../../../shared/components/dropdowns/assigned-dropdown/assigned_dropdown';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../../constants';
import Button from '../../../shared/components/buttons/button/button';
import ProjectDropdown from '../../../shared/components/dropdowns/project-dropdown-a/project_dropdown';
import HoursInput from '../../../shared/components/inputs/hours-input/hours_input_view';
import Util from '../../../shared/util/util';
import {AffixedInputWrapper} from '../../../shared/components/inputs/AffixedInputWrapper';
import {PersonDropdown} from '../../../shared/components/dropdowns/Person_dropdown';
import {RoleDropdown} from '../../../shared/components/dropdowns/Role_dropdown';
import {profilePicSrc} from '../../../../directApi';

const DropdownWrapper = styled.div`
	min-width: 180px;
	max-width: 180px;
	margin-right: 8px;
`;

class SprintsAddTask extends Component {
	constructor(props) {
		super(props);

		this.state = {
			inputTitle: '',
			inputApprove: true,
			isNameInvalid: false,
			estimateForecastValue: 0,
			persons: [],
			role: null,
			selectedProject: this.props.isConnectedParent
				? this.props.viewer.projectGroup.projects.edges.find(
						project => project.node.status !== 'HALTED' && project.node.status !== 'DONE'
				  ) || this.props.viewer.projectGroup.projects.edges[0]
				: null,
		};

		this.estimate_forecast_value = 0;
	}

	handleInputTitleChange(e) {
		this.setState({
			inputTitle: e.target.value,
		});
	}

	handleTitleBlur() {
		this.setState({isNameInvalid: this.state.inputTitle === ''});
	}

	addTask() {
		if (this.props.isEstimatedInHours) {
			this.addTaskNewFormat();
		} else {
			let estForecast = this.estimate_forecast_value;

			const invalidTaskName = this.state.inputTitle === '';

			if (invalidTaskName) {
				this._input.focus();
				this.setState({
					isNameInvalid: invalidTaskName,
				});
			} else {
				const sprint = this.props.isConnectedParent
					? this.state.selectedProject.node.sprints.edges.find(
							sprint => sprint.node.projectGroupSprintId === this.props.projectGroupSprintId
					  )
					: this.props.sprint;

				this.props.addTask(
					this.props.isConnectedParent ? this.state.selectedProject.node.id : this.props.projectId,
					this.state.inputTitle,
					this.state.role,
					estForecast,
					this.state.inputApprove,
					this.props.isConnectedParent ? (sprint ? sprint.node.id : null) : sprint.id,
					this.state.persons
				);
				// reset state
				this.setState({
					inputTitle: '',
					inputRole: null,
					inputApprove: true,
					isNameInvalid: false,
					estimateForecastValue: 0,
					persons: [],
					role: null,
				});
				this.estimate_forecast_value = 0;
				this._input.focus();
			}
		}
	}

	addTaskNewFormat() {
		const estForecast = Util.convertTimeInputToMinutes(this.estimate_forecast_value);

		const invalidTaskName = this.state.inputTitle === '';

		if (invalidTaskName) {
			this._input.focus();
			this.setState({
				isNameInvalid: invalidTaskName,
			});
		} else {
			const sprint = this.props.isConnectedParent
				? this.state.selectedProject.node.sprints.edges.find(
						sprint => sprint.node.projectGroupSprintId === this.props.projectGroupSprintId
				  )
				: this.props.sprint;
			this.props.addTask(
				this.props.isConnectedParent ? this.state.selectedProject.node.id : this.props.projectId,
				this.state.inputTitle,
				this.state.role,
				estForecast,
				this.state.inputApprove,
				this.props.isConnectedParent ? (sprint ? sprint.node.id : null) : sprint.id,
				this.state.persons
			); // reset state
			this.setState({
				inputTitle: '',
				inputRole: null,
				inputApprove: true,
				isNameInvalid: false,
				estimateForecastValue: 0,
				persons: [],
				role: null,
			});
			this.estimate_forecast_value = 0;
			this._input.focus();
		}
	}

	handleEnterKeyPress(e) {
		if (e.keyCode === 13) {
			this.addTask();
		}
	}

	handleProjectSelect(id) {
		this.setState({selectedProject: id});
	}

	assignRole(id) {
		const roles = this.props.viewer.company.roles.edges;
		const role = roles.find(r => r.node.id === id);

		this.setState({role: role ? role.node : null});
	}

	assignRoleV2(ids) {
		const roles = this.props.viewer.company.roles.edges;
		const role = roles.find(r => ids.includes(r.node.id));

		this.setState({role: role ? role.node : null});
	}

	unassignRole() {
		this.setState({role: null});
	}

	assignPerson(ids) {
		const projectPersons = this.props.projectPersons;
		let personsToAdd = projectPersons
			.map(person => person.node.person)
			.filter(projectPerson => ids.includes(projectPerson.id));
		if (this.state.role === null) {
			const roleToAdd = projectPersons.find(projectPerson => ids.includes(projectPerson.node.person.id));
			if (roleToAdd.node.role) this.assignRole(roleToAdd.node.role.id);
		}
		this.setState({
			persons: personsToAdd,
		});
	}

	unassignPerson(id) {
		const newPersons = this.state.persons.filter(person => person.id !== id);

		this.setState({persons: newPersons});
	}

	assignPersonV2(ids) {
		const projectPersons = this.props.projectPersons;
		const personToAdd = projectPersons.map(person => person.node.person).find(person => ids.includes(person.id));
		if (personToAdd) {
			const newPersons = [...this.state.persons, personToAdd];
			this.setState({
				persons: newPersons,
			});
		}
	}

	unassignPersonV2(ids) {
		const newPersons = this.state.persons.filter(person => !ids.includes(person.id));
		this.setState({persons: newPersons});
	}

	handleEstimateChange(value) {
		this.estimate_forecast_value = value;
		this.setState({estimateForecastValue: value});
	}
	render() {
		const {formatMessage} = this.props.intl;
		const projectOptions = this.props.viewer.project ? [] : this.props.viewer.projectGroup.projects.edges;
		const noProject = this.props.viewer.project
			? this.props.viewer.project.status === 'HALTED' || this.props.viewer.project.status === 'DONE'
			: !projectOptions.find(p => p.node.status === 'RUNNING' || p.node.status === 'PLANNING');

		return (
			<div
				className={
					'scoping-add-task-row' +
					(this.props.isWhite ? ' white' : '') +
					(this.props.groupByPerson ? ' grouped' : '') +
					(this.props.disabled || noProject ? ' disabled' : '')
				}
			>
				{this.props.isConnectedParent ? (
					<ProjectDropdown
						selectedProject={this.state.selectedProject}
						projectOptions={projectOptions}
						onChange={this.handleProjectSelect.bind(this)}
						customWidth={this.bottomAddTaskSection ? this.bottomAddTaskSection.clientWidth : null}
						contextHeight={this.props.contextHeight}
					/>
				) : null}
				<div className={'task-name-input-wrapper' + (this.state.isNameInvalid ? ' wrong' : '')}>
					<input
						title={this.state.isNameInvalid ? formatMessage({id: 'common.field-cannot-be-empty'}) : ''}
						ref={el => (this._input = el)}
						type="text"
						placeholder={formatMessage({id: 'common.new-task'})}
						value={this.state.inputTitle}
						onChange={this.handleInputTitleChange.bind(this)}
						onBlur={this.handleTitleBlur.bind(this)}
						onKeyDown={this.handleEnterKeyPress.bind(this)}
						autoComplete="off"
						id="no-phase-add-control"
						className={'text-input task-name-input'}
						maxLength={191}
						disabled={this.props.disabled || noProject}
						data-cy={'new-ui-scoping-task-name-input' + (this.props.isEmpty ? '-no-phase' : '')}
						data-userpilot={this.props.sprintIndex === 0 ? 'sprint-task-input-first' : null}
					/>
				</div>
				{this.props.useDropdownReplacement ? (
					<DropdownWrapper>
						<RoleDropdown
							selectedItems={this.state.role ? [this.state.role.id] : []}
							onSelect={id => this.assignRoleV2(id)}
							onRemove={() => this.unassignRole()}
							dropdownAlignment={'left'}
							key={'assigned-role'}
							width={255}
							name={formatMessage({id: 'common.assign_role'})}
							isMultiSelect={false}
							isClearable={true}
							roles={this.props.assignableRoles}
							optionsName={formatMessage({id: 'settings.roles'})}
							selectedGroupName={formatMessage({id: 'common.applied'})}
							disabled={this.props.disabled || this.state.inputTitle === ''}
							taskName={this.state.inputTitle}
							projectId={this.props.isConnectedParent ? this.state.selectedProject.node.id : this.props.projectId}
							useSuggestions
							usePortal
						/>
					</DropdownWrapper>
				) : (
					<AssignedDropdown
						useSmallerStyling
						assignedRole={this.state.role}
						assignableRoles={this.props.assignableRoles}
						assignRole={this.assignRole.bind(this)}
						autoFocus
						disabled={this.props.disabled || this.state.inputTitle === ''}
						isMultiSelect
						viewer={this.props.viewer}
						task={{id: null, name: this.state.inputTitle, description: ''}}
						showSuggestions
						projectId={this.state.selectedProject?.node?.id}
					/>
				)}
				{this.props.useDropdownReplacement ? (
					<DropdownWrapper>
						<PersonDropdown
							persons={this.props.assignablePersons.map(projectPerson => {
								return {node: projectPerson.node.person};
							})}
							name={formatMessage({id: 'common.assign_people'})}
							optionsName={formatMessage({id: 'common.persons'})}
							selectedGroupName={formatMessage({id: 'common.applied'})}
							selectedItems={this.state.persons.map(person => person.id)}
							selectedItemsPlaceholder={this.state.persons.map(person => {
								return {
									fullName: person.fullName,
									imageSource: profilePicSrc(person.profilePictureId),
								};
							})}
							disabled={this.props.disabled || this.state.inputTitle === ''}
							onSelect={ids => this.assignPersonV2(ids)}
							onRemove={ids => this.unassignPersonV2(ids)}
							dropdownAlignment={'left'}
							width={255}
							taskName={this.state.inputTitle}
							projectId={this.props.isConnectedParent ? this.state.selectedProject.node.id : this.props.projectId}
							useSuggestions
							showRole
							usePortal
						/>
					</DropdownWrapper>
				) : (
					<AssignedDropdown
						useSmallerStyling
						assignablePersons={this.props.assignablePersons}
						assignedPersons={this.state.persons}
						assignPerson={this.assignPerson.bind(this)}
						autoFocus
						unassignPerson={this.unassignPerson.bind(this)}
						disabled={this.props.disabled || this.state.inputTitle === ''}
						isMultiSelect
						viewer={this.props.viewer}
						task={{id: null, name: this.state.inputTitle, description: ''}}
						showSuggestions
						projectId={this.state.selectedProject?.node?.id}
					/>
				)}
				{this.props.isEstimatedInHours ? (
					<div style={{minWidth: '90px', marginRight: '4px'}}>
						<HoursInput
							value={this.state.estimateForecastValue}
							placeholder={formatMessage({id: 'common.estimate'})}
							mutation={value => this.handleEstimateChange(value)}
							disabled={this.props.disabled || this.state.inputTitle === ''}
							userpilot={this.props.phaseIndex === 0 ? 'task-estimate-input-first' : ''}
							parentKeyDown={this.handleEnterKeyPress.bind(this)}
						></HoursInput>
					</div>
				) : (
					<AffixedInputWrapper
						onKeyDown={this.handleEnterKeyPress.bind(this)}
						onClick={e => e.focus()}
						disabled={this.props.disabled || this.state.inputTitle === ''}
						customClassName={'hours-input'}
						value={this.state.estimateForecastValue}
						callback={value => this.handleEstimateChange(value)}
						affix={formatMessage({id: 'common.points.short'})}
					/>
				)}
				<div className="add">
					<Button
						disabled={this.props.disabled || this.state.inputTitle === ''}
						colorTheme={this.state.inputTitle ? BUTTON_COLOR.GREEN : BUTTON_COLOR.LIGHTGREY}
						buttonStyle={BUTTON_STYLE.FILLED}
						className="input-add-button"
						onClick={this.addTask.bind(this)}
					/>
				</div>
			</div>
		);
	}
}

SprintsAddTask.propTypes = {
	projectId: PropTypes.string.isRequired,
	sprint: PropTypes.object.isRequired,
	addTask: PropTypes.func.isRequired,
	viewer: PropTypes.object.isRequired,
};

export default injectIntl(SprintsAddTask);
