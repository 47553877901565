import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import Util from '../../../shared/util/util';
import SharedReportLoginMutation from '../../../../mutations/shared_report_login_mutation';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../../constants';
import Button from '../../../shared/components/buttons/button/button';
import {useSharedReportLoginContext} from './SharedReportLoginContext';
import {isSageSpecificUrl} from '../../../shared/util/UrlUtil';
import {FlexColumn, Heading, TextField, Link} from '@forecast-it/design-system';
import {WideButton} from '../../../../containers/sage-signup-flow/Sage.styled';

function SharedReportLoginFormSage(props) {
	const {formatMessage} = useIntl();
	return (
		<FlexColumn gap={'l'}>
			<Heading size="4">{formatMessage({id: 'shared_report.login'})}</Heading>
			<TextField
				isPasswordField
				label={formatMessage({id: 'common.password'})}
				autoComplete="password"
				maxLength={64}
				value={props.password}
				errorText={props.errorText}
				onValueChange={props.onValueChange}
			/>
			<Link onClick={props.onShowForgotPassword}>{formatMessage({id: 'login.forgot-password'})}</Link>
			<WideButton onClick={props.onLogin} data-cy="login-button">
				{formatMessage({id: 'login.sign-in'})}
			</WideButton>
		</FlexColumn>
	);
}

const SharedReportLoginForm = () => {
	const {formatMessage} = useIntl();

	const [error, setError] = useState(null);

	const {showForgotPassword, showChangePassword, sharedId, refetch, password, setPassword} = useSharedReportLoginContext();

	const handleSubmit = event => {
		if (event) {
			event.preventDefault();
		}

		if (!password || !password.length) {
			setError(formatMessage({id: 'shared_report.login.missing_password'}));
			return;
		}

		const onSuccess = ({sharedReportLogin}) => {
			if (sharedReportLogin && sharedReportLogin.success && sharedReportLogin.errors === null) {
				refetch();
			} else {
				if (sharedReportLogin.errors?.length > 0) {
					if (sharedReportLogin.errors.find(errorCode => errorCode.includes('-901-005'))) {
						// ABOUT TO EXPIRE
						showChangePassword();
					} else if (sharedReportLogin.errors.find(errorCode => errorCode.includes('-901-004'))) {
						// EXPIRED
						setError(formatMessage({id: 'shared_report.login.expired'}));
					} else {
						setError(formatMessage({id: 'shared_report.login.failed'}));
					}
				}
			}
		};

		Util.CommitMutation(
			SharedReportLoginMutation,
			{
				sharedId,
				password,
			},
			onSuccess,
			true
		);
	};

	return (
		<>
			<form name="login-form">
				{isSageSpecificUrl() ? (
					<SharedReportLoginFormSage
						password={password}
						errorText={error}
						onValueChange={setPassword}
						onShowForgotPassword={showForgotPassword}
						onLogin={handleSubmit}
					/>
				) : (
					<>
						<h1 style={{marginBottom: '5px'}}>{formatMessage({id: 'shared_report.login'})}</h1>

						<>
							<label htmlFor="password">{formatMessage({id: 'common.password'})}</label>
							<input
								className="password"
								type="password"
								name="password"
								onChange={e => setPassword(e.target.value)}
								defaultValue=""
								placeholder={formatMessage({id: 'login.enter-password'})}
							/>
						</>

						{error ? <div className="error">{error}</div> : ''}

						<div className="buttons">
							<div></div>
							<a onClick={showForgotPassword} style={{cursor: 'pointer'}}>
								<FormattedMessage id="login.forgot-password" />
							</a>
						</div>

						<div className="buttons signin-button">
							<Button
								id="login-button"
								text={formatMessage({id: 'login.sign-in'})}
								cy="login-button"
								buttonStyle={BUTTON_STYLE.FILLED}
								colorTheme={BUTTON_COLOR.PINK}
								onClick={handleSubmit}
								uppercase={false}
								roundedBorders={true}
								symbolClass="arrow-right"
								symbolSize={18}
								placeSymbolLast
							/>
						</div>
					</>
				)}
			</form>
		</>
	);
};

export default SharedReportLoginForm;
