import { differenceInSeconds } from 'date-fns';
import Moment from 'moment';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import UpdateViewerMutation from '../../../../../../mutations/update_viewer_mutation.modern.js';
import { trackEvent } from '../../../../../../tracking/amplitude/TrackingV2';
import Util from '../../../../../shared/util/util';
import { createTimeRegistration } from './logic/TimeRegistrationModalMutation';
import TimeRegistrationModalQueryWrapper from './query-wrappers/TimeRegistrationModalQueryWrapper';
import { toEntityId } from './util/TimeRegistrationModalUtil';
function getHeaderText({ preventedDelete, shouldRelaunchTimer }) {
    if (preventedDelete) {
        return 'Timer prevented delete';
    }
    if (shouldRelaunchTimer) {
        return 'Save timer data';
    }
    return 'Timer entry';
}
function getSearchFieldText({ shouldRelaunchTimer }) {
    if (shouldRelaunchTimer) {
        return 'Would you like to save the timer you started on the task below?';
    }
    return undefined;
}
function getInfoText({ preventedDelete, shouldRelaunchTimer }) {
    return {
        headerText: getHeaderText({ preventedDelete, shouldRelaunchTimer }),
        searchFieldText: getSearchFieldText({ shouldRelaunchTimer }),
        creativeButtonText: 'Save',
        cancelButtonText: 'Discard',
    };
}
function resetOrRelaunchTimer({ shouldRelaunchTimer, timerActionTaskId }) {
    trackEvent('Timer', `${shouldRelaunchTimer ? 'Restarted' : 'Reset'}`);
    Util.CommitMutation(UpdateViewerMutation, {
        timerStartDate: shouldRelaunchTimer ? Moment.utc().format() : null,
        timerEndDate: null,
        timerTaskId: shouldRelaunchTimer ? timerActionTaskId : null,
    });
}
function createTimerTimeRegistration({ timeRegistrationMutationInput, shouldRelaunchTimer, timerActionTaskId, closeModal, }) {
    createTimeRegistration(timeRegistrationMutationInput);
    resetOrRelaunchTimer({ shouldRelaunchTimer, timerActionTaskId });
    closeModal();
}
function resetTimer({ shouldRelaunchTimer, timerActionTaskId, closeModal }) {
    resetOrRelaunchTimer({ shouldRelaunchTimer, timerActionTaskId });
    closeModal();
}
// Determine what entity the time from the timer should be added to.
// If the timer was originally started on a task (the task from timerTaskId), then that task should always be the target, even if the timer was started/stopped on a different task (the task from timerActionTaskId).
// If the timer start action is on a different task than the one that ends up as the time registration, the timer should be started on that task after the time registration is created.
function getEntityIdFromTimer({ timerActionTaskId, timerTaskId }) {
    const taskToRegisterOn = timerTaskId || timerActionTaskId;
    return taskToRegisterOn ? toEntityId({ taskId: taskToRegisterOn, projectId: null, idleTimeId: null }) : null;
}
function getTimerMinutesFromTimerDates({ timerStartDateString, timerEndDateString }) {
    if (!timerStartDateString)
        return 0;
    const timerStartDate = new Date(timerStartDateString);
    const timerEndDate = timerEndDateString ? new Date(timerEndDateString) : new Date();
    const diffInSeconds = differenceInSeconds(timerEndDate, timerStartDate);
    // convert to minutes and round up to next minute
    return Math.ceil(diffInSeconds / 60);
}
function TimeRegistrationModalTimer({ viewer, timerActionTaskId, preventedDelete, closeModal }) {
    const { timerStartDate, timerEndDate, actualPersonId } = viewer;
    const timerTaskId = viewer.timerTask ? viewer.timerTask.id : null;
    const entityId = getEntityIdFromTimer({ timerActionTaskId, timerTaskId: timerTaskId });
    // Timer action was performed on a different task than the existing timer task.
    const shouldRelaunchTimer = timerTaskId && timerActionTaskId ? timerActionTaskId !== timerTaskId : false;
    const timerMinutes = getTimerMinutesFromTimerDates({
        timerStartDateString: timerStartDate,
        timerEndDateString: timerEndDate,
    });
    return (React.createElement(TimeRegistrationModalQueryWrapper, { personId: actualPersonId, entityId: entityId, projectId: undefined, infoText: getInfoText({ preventedDelete, shouldRelaunchTimer }), initialMinutes: timerMinutes, shouldValidateEntity: true, showValidationError: true, callbackPositive: timeRegistrationMutationInput => createTimerTimeRegistration({ timeRegistrationMutationInput, shouldRelaunchTimer, timerActionTaskId, closeModal }), callbackNegative: () => resetTimer({ shouldRelaunchTimer: shouldRelaunchTimer, timerActionTaskId, closeModal }), closeModal: closeModal }));
}
export const TimeRegistrationModalTimerQuery = graphql `
	query TimeRegistrationModalTimer_Query {
		viewer {
			actualPersonId
			component(name: "time_registration_modal_timer")
			...TimeRegistrationModalTimer_viewer
		}
	}
`;
export default createFragmentContainer(TimeRegistrationModalTimer, {
    viewer: graphql `
		fragment TimeRegistrationModalTimer_viewer on Viewer {
			actualPersonId
			timerStartDate
			timerEndDate
			timerTask {
				id
			}
		}
	`,
});
