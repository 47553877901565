/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type DeprecatedProjectIndicatorJS_project$ref = any;
export type CANT_DELETE_TASK_REASON = "BLOCKED_BY_SUBTASK" | "GITHUB" | "HAS_INVOICED_TIME" | "HAS_LOCKED_TIME" | "HAS_OTHER_PEOPLES_TIME_REGS" | "HAS_TIME_REGS" | "JIRA" | "NO_USER_TYPE" | "UNIT4" | "%future added value";
export type PROJECT_STATUS = "DONE" | "HALTED" | "OPPORTUNITY" | "PLANNING" | "RUNNING" | "%future added value";
export type STATUS_CATEGORY = "DONE" | "INPROGRESS" | "TODO" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type TaskRow_task$ref: FragmentReference;
declare export opaque type TaskRow_task$fragmentType: TaskRow_task$ref;
export type TaskRow_task = {|
  +id: string,
  +name: ?string,
  +companyTaskId: ?number,
  +startYear: ?number,
  +startMonth: ?number,
  +startDay: ?number,
  +deadlineDay: ?number,
  +deadlineMonth: ?number,
  +deadlineYear: ?number,
  +favoured: ?boolean,
  +estimateForecast: ?number,
  +estimateForecastMinutes: ?number,
  +timeLeft: ?number,
  +progress: ?number,
  +blocked: ?boolean,
  +highPriority: ?boolean,
  +billable: ?boolean,
  +readOnly: ?{|
    +isReadOnly: ?boolean
  |},
  +userCanDeleteTask: ?boolean,
  +userCantDeleteTaskReason: ?CANT_DELETE_TASK_REASON,
  +bug: ?boolean,
  +parentTaskId: ?string,
  +totalMinutesRegistered: ?number,
  +hasInvoicedTime: ?boolean,
  +assignedPersons: ?$ReadOnlyArray<?{|
    +id: string,
    +firstName: ?string,
    +lastName: ?string,
    +fullName: ?string,
    +profilePictureId: ?string,
    +profilePictureDefaultId: ?number,
    +active: ?boolean,
    +permissions: ?$ReadOnlyArray<?string>,
    +role: ?{|
      +id: string,
      +name: ?string,
    |},
  |}>,
  +role: ?{|
    +id: string,
    +name: ?string,
  |},
  +owner: ?{|
    +id: string,
    +firstName: ?string,
    +lastName: ?string,
    +fullName: ?string,
    +profilePictureId: ?string,
    +profilePictureDefaultId: ?number,
    +role: ?{|
      +id: string,
      +name: ?string,
    |},
  |},
  +phase: ?{|
    +id: string,
    +name: ?string,
  |},
  +statusColumnV2: ?{|
    +id: string,
    +name: ?string,
    +category: ?STATUS_CATEGORY,
  |},
  +project: ?{|
    +id: string,
    +name: ?string,
    +estimationUnit: ?string,
    +projectStartYear: ?number,
    +projectStartMonth: ?number,
    +projectStartDay: ?number,
    +projectEndYear: ?number,
    +projectEndMonth: ?number,
    +projectEndDay: ?number,
    +projectColor: ?string,
    +companyProjectId: ?number,
    +fullAccessToProject: ?boolean,
    +status: ?PROJECT_STATUS,
    +useTaskHierarchy: ?boolean,
    +manualProgressOnTasksEnabled: ?boolean,
    +harvestProject: ?{|
      +id: string
    |},
    +unit4Project: ?{|
      +id: string,
      +activitiesEnabled: ?boolean,
    |},
    +client: ?{|
      +id: string,
      +name: ?string,
    |},
    +projectPerson: ?{|
      +role: ?{|
        +id: string,
        +name: ?string,
      |}
    |},
    +statusColumnsV2: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +category: ?STATUS_CATEGORY,
          +order: ?number,
        |}
      |}>
    |},
    +$fragmentRefs: DeprecatedProjectIndicatorJS_project$ref,
  |},
  +taskLabels: ?$ReadOnlyArray<?{|
    +id: string,
    +label: ?{|
      +id: string,
      +name: ?string,
      +color: ?string,
    |},
  |}>,
  +parentTask: ?{|
    +id: string,
    +name: ?string,
  |},
  +progressDetails: ?{|
    +progress: ?number
  |},
  +$refType: TaskRow_task$ref,
|};
export type TaskRow_task$data = TaskRow_task;
export type TaskRow_task$key = {
  +$data?: TaskRow_task$data,
  +$fragmentRefs: TaskRow_task$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "progress",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePictureId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePictureDefaultId",
  "storageKey": null
},
v8 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Role",
  "kind": "LinkedField",
  "name": "role",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "category",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "personId",
      "type": "ID"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaskRow_task",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "companyTaskId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startMonth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deadlineDay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deadlineMonth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deadlineYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "favoured",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "estimateForecast",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "estimateForecastMinutes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeLeft",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "blocked",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "highPriority",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskReadOnly",
      "kind": "LinkedField",
      "name": "readOnly",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isReadOnly",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userCanDeleteTask",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userCantDeleteTaskReason",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parentTaskId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalMinutesRegistered",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasInvoicedTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Person",
      "kind": "LinkedField",
      "name": "assignedPersons",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "active",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "permissions",
          "storageKey": null
        },
        (v9/*: any*/)
      ],
      "storageKey": null
    },
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Person",
      "kind": "LinkedField",
      "name": "owner",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        (v7/*: any*/),
        (v9/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PhaseType",
      "kind": "LinkedField",
      "name": "phase",
      "plural": false,
      "selections": (v8/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StatusColumnV2",
      "kind": "LinkedField",
      "name": "statusColumnV2",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v10/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectType",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "estimationUnit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectStartYear",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectStartMonth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectStartDay",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectEndYear",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectEndMonth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectEndDay",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectColor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "companyProjectId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullAccessToProject",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "useTaskHierarchy",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "manualProgressOnTasksEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HarvestProjectType",
          "kind": "LinkedField",
          "name": "harvestProject",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Unit4ProjectType",
          "kind": "LinkedField",
          "name": "unit4Project",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "activitiesEnabled",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Client",
          "kind": "LinkedField",
          "name": "client",
          "plural": false,
          "selections": (v8/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "personId",
              "variableName": "personId"
            }
          ],
          "concreteType": "ProjectPerson",
          "kind": "LinkedField",
          "name": "projectPerson",
          "plural": false,
          "selections": [
            (v9/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 1000000
            }
          ],
          "concreteType": "StatusColumnV2TypeConnection",
          "kind": "LinkedField",
          "name": "statusColumnsV2",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "StatusColumnV2TypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "StatusColumnV2",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v10/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "order",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "statusColumnsV2(first:1000000)"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DeprecatedProjectIndicatorJS_project"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskLabel",
      "kind": "LinkedField",
      "name": "taskLabels",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Label",
          "kind": "LinkedField",
          "name": "label",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "color",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Task",
      "kind": "LinkedField",
      "name": "parentTask",
      "plural": false,
      "selections": (v8/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskProgress",
      "kind": "LinkedField",
      "name": "progressDetails",
      "plural": false,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Task"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a82d56ff6b97d112ba89cc04cf6309c9';

module.exports = node;
