import React, {useState} from 'react';
import {
	DeprecatedCreativeModal as CreativeModal,
	FlexColumn,
	DeprecatedText as Text,
	FlexRow,
} from '@forecast-it/design-system';
import Util from '../../../util/util';
import {createToast} from '../../toasts/toast';
import {useIntl} from 'react-intl';
import UpdateProgramFinancialMetadataMutation from '../../../../../mutations/financial-service/update_program_financial_metadata_mutation';
import {RadioButton} from 'web-components';
import {CAP_SETTING} from '../../../../../constants';
import {trackEvent} from '../../../../../tracking/amplitude/TrackingV2';

const ChangeCapSettingModal = ({closeModal, program, company}) => {
	const {formatMessage} = useIntl();
	const [selectedOption, setSelectedOption] = useState(program.revenueSetting);

	const handleChangeCapSetting = option => {
		setSelectedOption(option);
	};

	const handleChangeClick = () =>
		Util.CommitMutation(
			UpdateProgramFinancialMetadataMutation,
			{
				companyId: company.id,
				id: program.id,
				revenueSetting: selectedOption,
			},
			() => {
				if (program.revenueSetting !== selectedOption) {
					trackEvent('Cap Setting', 'Changed', {oldSetting: program.revenueSetting, newSetting: selectedOption});
				}
				createToast({
					duration: 5000,
					message: formatMessage({id: 'common.setting_changed'}),
				});
				closeModal();
			}
		);

	return (
		<CreativeModal
			closeModal={closeModal}
			onCreateHandler={handleChangeClick}
			creativeButtonText={formatMessage({id: 'common.change'})}
			title={formatMessage({id: 'program.cap_setting_title'})}
			size="m"
		>
			<FlexColumn gap="xxl">
				<Text>{formatMessage({id: 'program.cap_setting_text'})}</Text>
				<FlexRow gap={'s'}>
					<RadioButton
						checked={selectedOption === CAP_SETTING.PERMITTED_UNTIL_CAP}
						onClick={() => handleChangeCapSetting(CAP_SETTING.PERMITTED_UNTIL_CAP)}
					/>
					<FlexColumn gap="xs">
						<Text>{formatMessage({id: 'program.cap_setting.permitted_until_cap_default'})}</Text>
						<Text type="small">{formatMessage({id: 'program.cap_setting.permitted_until_cap_info'})}</Text>
					</FlexColumn>
				</FlexRow>
				<FlexRow gap={'s'}>
					<RadioButton
						checked={selectedOption === CAP_SETTING.ALWAYS_PERMITTED}
						onClick={() => handleChangeCapSetting(CAP_SETTING.ALWAYS_PERMITTED)}
					/>
					<FlexColumn gap="xs">
						<Text>{formatMessage({id: 'program.cap_setting.always_permitted'})}</Text>
						<Text type="small">{formatMessage({id: 'program.cap_setting.always_permitted_info'})}</Text>
					</FlexColumn>
				</FlexRow>
				<FlexRow gap={'s'}>
					<RadioButton
						checked={selectedOption === CAP_SETTING.ALWAYS_DISABLED}
						onClick={() => handleChangeCapSetting(CAP_SETTING.ALWAYS_DISABLED)}
					/>
					<FlexColumn gap="xs">
						<Text>{formatMessage({id: 'program.cap_setting.always_disabled'})}</Text>
						<Text type="small">{formatMessage({id: 'program.cap_setting.always_disabled_info'})}</Text>
					</FlexColumn>
				</FlexRow>
			</FlexColumn>
		</CreativeModal>
	);
};

export default ChangeCapSettingModal;
