/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type appHubspot_viewer$ref = any;
export type appHubspot_QueryVariables = {||};
export type appHubspot_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: appHubspot_viewer$ref,
  |}
|};
export type appHubspot_Query = {|
  variables: appHubspot_QueryVariables,
  response: appHubspot_QueryResponse,
|};
*/


/*
query appHubspot_Query {
  viewer {
    actualPersonId
    component(name: "app_hubspot")
    ...appHubspot_viewer
    id
  }
}

fragment appHubspot_viewer on Viewer {
  id
  availableFeatureFlags {
    key
    id
  }
  company {
    id
    hubspotAccountId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "app_hubspot"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"app_hubspot\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "appHubspot_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "appHubspot_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "appHubspot_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FeatureFlag",
            "kind": "LinkedField",
            "name": "availableFeatureFlags",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hubspotAccountId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "appHubspot_Query",
    "operationKind": "query",
    "text": "query appHubspot_Query {\n  viewer {\n    actualPersonId\n    component(name: \"app_hubspot\")\n    ...appHubspot_viewer\n    id\n  }\n}\n\nfragment appHubspot_viewer on Viewer {\n  id\n  availableFeatureFlags {\n    key\n    id\n  }\n  company {\n    id\n    hubspotAccountId\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '451e731d563538cd68b7b01ba205cb15';

module.exports = node;
