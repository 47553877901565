import DataExportFormatter from './DataExportFormatter';
import {idFromGlobalId} from '../GlobalIdUtil';

export default class WorkFormatter extends DataExportFormatter {
	#project;
	#roles;

	constructor(intl, mandatoryColumns = [], columnsToIgnore = [], shouldSplitDate = false) {
		super(intl, mandatoryColumns, columnsToIgnore, shouldSplitDate);
	}

	_storeGeneralData(generalData) {
		this.#project = generalData.project;
		this.#roles = new Map();
		generalData.phases.forEach(phase => {
			phase.node.phaseBaselineRoles.edges.forEach(phaseBaselineRole => {
				this.#roles.set(idFromGlobalId(phaseBaselineRole.node.id), phaseBaselineRole.node);
			});
		});
	}

	_columnFormatHook(name) {
		switch (name) {
			case 'costPH':
				return 'cost_per_hour';
			case 'ratePH':
				return 'rate_per_hour';
			default:
				return null;
		}
	}

	_getFormattedValueHook(column, work) {
		switch (column) {
			case 'cost':
				return this._formatCurrency(work.baselineCost);
			case 'cost_per_hour':
				return this._formatCurrency(work.baselineCostPerHour);
			case 'estimate':
				return this._formatNumber(this.#roles.get(work.phaseBaselineRoleId).baselineMinutes);
			case 'item_name':
				return this._formatString(this.#roles.get(work.phaseBaselineRoleId).role.name);
			case 'item_type':
				return this._formatIntlMessage('baseline.work');
			case 'profit':
				return this._formatCurrency(work.baselineProfit);
			case 'rate_per_hour':
				return this._formatCurrency(work.baselineRatePerHour);
			case 'revenue':
				return this._formatCurrency(work.baselineRevenue);
			case 'margin':
				return this._formatPercentage(Math.round(work.baselineMargin * 100));
			default:
				return null;
		}
	}
}
