import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import {injectIntl, FormattedMessage} from 'react-intl';
import Moment from 'moment';
import Calendar from './calendar';
import DatePickerButton from './date_picker_button_v3';
import {DATE_PICKER_STYLE} from '../../../../constants';

class DateRangePicker extends Component {
	constructor(props) {
		super(props);
		const viewedDates = this.getViewedDates(props.startDate, props.endDate);
		this.state = {
			selectingStartDate: true,
			showCalendar: false,
			viewedMonthFirst: viewedDates.viewedMonthFirst,
			viewedMonthSecond: viewedDates.viewedMonthSecond,
			viewedYearFirst: viewedDates.viewedYearFirst,
			viewedYearSecond: viewedDates.viewedYearSecond,
			startDate: props.startDate ? props.startDate.clone() : null,
			endDate: props.endDate ? props.endDate.clone() : null,
			hoveredDate: null,
			firstCalendarRefsMap: null,
			secondCalendarRefsMap: null,
			cancelUpdate: false,
			keyCodeUsed: null,
			previouslyFocusedDate: null,
			selectedDates: [],
		};
		this.collapseCalendar = this.collapseCalendar.bind(this);
		this.handleEscPress = this.handleEscPress.bind(this);
	}

	componentDidMount() {
		this.offset = this.date_range_picker_v2.getBoundingClientRect();
		window.addEventListener('wheel', this.collapseCalendar);
		document.body.addEventListener('keydown', this.handleEscPress);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		// if component receives new dates from props update state
		if (
			(nextProps.startDate && !this.props.startDate) ||
			(!nextProps.startDate && this.props.startDate) ||
			(nextProps.startDate && this.props.startDate && !nextProps.startDate.isSame(this.props.startDate)) ||
			(nextProps.endDate && !this.props.endDate) ||
			(!nextProps.endDate && this.props.endDate) ||
			(nextProps.endDate && this.props.endDate && !nextProps.endDate.isSame(this.props.endDate))
		) {
			this.setState({startDate: nextProps.startDate, endDate: nextProps.endDate});
		}

		// if component receives props that are not the same as what is in state
		if (
			this.props.overwriteDates &&
			((nextProps.startDate && !nextProps.startDate.isSame(this.state.startDate)) ||
				(nextProps.endDate && !nextProps.endDate.isSame(this.state.endDate)))
		) {
			this.setState({startDate: nextProps.startDate, endDate: nextProps.endDate});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		//this.offset = this.date_range_picker_v2.getBoundingClientRect();
		if (this.props.isSingleDatePicker) {
			if (this.state.selectedDates.length === 1) {
				this.props.handleDateRangeChange(this.state.selectedDates[0]);
				this.setState({
					selectedDates: [],
					showCalendar: false,
					startDate: this.state.selectedDates[0],
					hoveredDate: null,
				});
			}
		} else {
			if (this.state.selectedDates.length === 2) {
				const d1 = this.state.selectedDates[0];
				const d2 = this.state.selectedDates[1];
				const start = this.dateMin(d1, d2);
				const end = this.dateMax(d1, d2);
				this.props.handleDateRangeChange(start, end);
				this.setState({selectedDates: [], showCalendar: false, startDate: start, endDate: end, hoveredDate: null});
			}
		}
		if (!prevState.showCalendar && this.state.showCalendar) {
			const viewedDates = this.getViewedDates(this.props.startDate, this.props.endDate);
			this.setState({
				viewedMonthFirst: viewedDates.viewedMonthFirst,
				viewedMonthSecond: viewedDates.viewedMonthSecond,
				viewedYearFirst: viewedDates.viewedYearFirst,
				viewedYearSecond: viewedDates.viewedYearSecond,
			});
		}
		// Calendar was closed with 1 selection in a multi-selection setting
		if (this.didClose(prevState, this.state) && !this.props.isSingleDatePicker) {
			if (this.state.selectedDates.length === 1) {
				const d1 = this.state.selectedDates[0];
				let start;
				let end;
				// Need to handle the cases where startDate or endDate are null.
				if (!this.state.startDate && !this.state.endDate) {
					start = d1;
					end = d1;
				} else if (!this.state.startDate) {
					if (d1.isBefore(this.state.endDate)) {
						start = d1;
						end = this.state.endDate;
					} else {
						start = this.state.endDate;
						end = d1;
					}
				} else if (!this.state.endDate && d1.isBefore(this.state.startDate)) {
					start = d1;
					end = this.state.startDate;
				} else if (!this.state.endDate || d1.isSameOrAfter(this.state.endDate)) {
					start = this.state.startDate;
					end = d1;
				} else {
					// If a date was picked between start & end, we don't know whether to move end back, or start up, so it depends on the button pressed
					if (d1.isSameOrBefore(this.state.endDate) && d1.isSameOrAfter(this.state.startDate)) {
						if (this.state.openedOn === 'END') {
							start = this.state.startDate;
							end = d1;
						} else {
							start = d1;
							end = this.state.endDate;
						}
					} else {
						start = d1.isBefore(this.state.startDate) ? d1 : this.state.startDate;
						end = d1.isSameOrAfter(this.state.endDate) ? d1 : this.state.endDate;
					}
				}
				this.props.handleDateRangeChange(start, end);
				this.setState({selectedDates: [], startDate: start, endDate: end, hoveredDate: null});
			}
		}
	}

	componentWillUnmount() {
		window.removeEventListener('wheel', this.collapseCalendar);
		document.body.removeEventListener('keydown', this.handleEscPress);
	}

	didClose(prevState, state) {
		return prevState.showCalendar && !state.showCalendar;
	}

	dateMin(d1, d2) {
		if (d1.isSameOrBefore(d2)) {
			return d1;
		} else return d2;
	}

	dateMax(d1, d2) {
		if (d1.isSameOrAfter(d2)) {
			return d1;
		} else return d2;
	}

	collapseCalendar(e) {
		if (this.state.showCalendar && this.props.collapseOnWheel) {
			let target = e.target;
			let isInsideCalendarScroll = false;

			do {
				if (target.className && target.className.includes('calendars-dropdown-wrapper')) {
					isInsideCalendarScroll = true;
				}

				target = target.parentNode;
			} while (target);

			if (!isInsideCalendarScroll) {
				this.setState({showCalendar: false, selectingStartDate: true});
			}
		}
	}

	resetPreviouslyFocusedDate() {
		this.setState({previouslyFocusedDate: null, keyCodeUsed: null});
	}

	getViewedDates(startDate, endDate) {
		let viewedMonthFirst, viewedMonthSecond, viewedYearFirst, viewedYearSecond;
		if (startDate) {
			viewedMonthFirst = startDate.clone().month();
			viewedYearFirst = startDate.clone().year();
			const nextMonthDate = startDate.clone().startOf('month').add(1, 'month');
			viewedMonthSecond = nextMonthDate.month();
			viewedYearSecond = nextMonthDate.year();
		} else if (endDate) {
			const previousMonthDate = endDate.clone().startOf('month').add(-1, 'month');
			viewedMonthFirst = previousMonthDate.month();
			viewedYearFirst = previousMonthDate.year();
			viewedMonthSecond = endDate.clone().month();
			viewedYearSecond = endDate.clone().year();
		} else {
			//no dates select current month and current month + 1
			viewedMonthFirst = Moment().month();
			viewedYearFirst = Moment().year();
			const date = Moment().startOf('month').add(1, 'month');
			viewedMonthSecond = date.month();
			viewedYearSecond = date.year();
		}
		const viewedDates = {
			viewedMonthFirst,
			viewedYearFirst,
			viewedMonthSecond,
			viewedYearSecond,
		};
		return viewedDates;
	}

	toggleCalendar(e, openedOn) {
		e.preventDefault();
		e.stopPropagation();

		this.offset = this.date_range_picker_v2.getBoundingClientRect();
		const newState = {
			showCalendar: !this.state.showCalendar,
			openedOn: openedOn,
		};

		this.setState(newState);
	}

	handleDateClick(moment) {
		// Clear hovered date on click
		const newState = {hoveredDate: null};
		if (this.props.isSingleDatePicker) {
			// Close if single, we're done
			newState.showCalendar = false;
		}
		const selectedDates = [...this.state.selectedDates];
		selectedDates.push(moment);
		newState.selectedDates = selectedDates;
		this.setState(newState, () => {
			if (this.props.refToFocusAfterSelect) {
				this.props.refToFocusAfterSelect.current.focus();
			}
		});
	}

	handleTodayClick() {
		const today = Moment();
		const todayMonth = today.month();
		const todayYear = today.year();
		let nextMonth = todayMonth + 1;
		let nextYear = todayYear;
		if (todayMonth === 11) {
			nextMonth = 0;
			nextYear = todayYear + 1;
		}
		this.setState({
			viewedMonthFirst: todayMonth,
			viewedYearFirst: todayYear,
			viewedMonthSecond: nextMonth,
			viewedYearSecond: nextYear,
		});
	}

	addContiguousDate(moment, addAsStartDate) {
		if (addAsStartDate) {
			this.setState({startDate: moment});
			if (this.state.endDate) {
			}
		} else {
			this.setState({endDate: moment});
		}
	}

	viewPreviousMonth(keyCodeUsed, previouslyFocusedDate) {
		let viewedMonthFirst = this.state.viewedMonthFirst,
			viewedMonthSecond = this.state.viewedMonthSecond,
			viewedYearFirst = this.state.viewedYearFirst,
			viewedYearSecond = this.state.viewedYearSecond;
		if (viewedMonthFirst === 0) {
			viewedMonthFirst = 11;
			viewedYearFirst--;
		} else {
			viewedMonthFirst--;
		}
		if (viewedMonthSecond === 0) {
			viewedMonthSecond = 11;
			viewedYearSecond--;
		} else {
			viewedMonthSecond--;
		}
		this.setState({
			viewedMonthFirst,
			viewedMonthSecond,
			viewedYearFirst,
			viewedYearSecond,
			previouslyFocusedDate,
			keyCodeUsed,
		});
	}

	viewNextMonth(keyCodeUsed, previouslyFocusedDate) {
		let viewedMonthFirst = this.state.viewedMonthFirst,
			viewedMonthSecond = this.state.viewedMonthSecond,
			viewedYearFirst = this.state.viewedYearFirst,
			viewedYearSecond = this.state.viewedYearSecond;
		if (viewedMonthFirst === 11) {
			viewedMonthFirst = 0;
			viewedYearFirst++;
		} else {
			viewedMonthFirst++;
		}
		if (viewedMonthSecond === 11) {
			viewedMonthSecond = 0;
			viewedYearSecond++;
		} else {
			viewedMonthSecond++;
		}
		this.setState({
			viewedMonthFirst,
			viewedMonthSecond,
			viewedYearFirst,
			viewedYearSecond,
			previouslyFocusedDate,
			keyCodeUsed,
		});
	}

	viewNextYear(keyCodeUsed, previouslyFocusedDate) {
		this.setState({
			viewedYearFirst: this.state.viewedYearFirst + 1,
			viewedYearSecond: this.state.viewedYearSecond + 1,
			keyCodeUsed,
			previouslyFocusedDate,
		});
	}
	viewPreviousYear(keyCodeUsed, previouslyFocusedDate) {
		this.setState({
			viewedYearFirst: this.state.viewedYearFirst - 1,
			viewedYearSecond: this.state.viewedYearSecond - 1,
			keyCodeUsed,
			previouslyFocusedDate,
		});
	}

	onDayMouseEnter(day) {
		this.setState({hoveredDate: day});
	}

	onTableBodyMouseLeave() {
		this.setState({hoveredDate: null});
	}

	setRefs(refsMap, isFirstCalendar) {
		if (isFirstCalendar) {
			this.setState({firstCalendarRefsMap: refsMap});
		} else {
			this.setState({secondCalendarRefsMap: refsMap});
		}
	}

	handleClick(e) {
		if (this.props.noPropagation) {
			e.stopPropagation();
			e.preventDefault();
		}
	}

	handleBlur(e) {
		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11
		const shouldNotBlur =
			(newTarget && this.date_range_picker_v2 && this.date_range_picker_v2.contains(newTarget)) ||
			(this.calendar_pop_out && this.calendar_pop_out.contains(newTarget));
		if (shouldNotBlur) return;

		const newState = {showCalendar: false};
		this.setState(newState);
	}

	handleKeyDown(e) {
		if (
			e &&
			e.keyCode === 13 &&
			(this.state.selectingStartDate || this.props.isSingleDatePicker) &&
			((this.state.startDate && !this.props.startDate) ||
				(!this.state.startDate && this.props.startDate) ||
				(this.state.startDate && this.props.startDate && !this.props.startDate.isSame(this.state.startDate)))
		) {
			// if both start and end date selected and different from initial value enter works as confirm button. Save changes and close calendar
			this.setState({showCalendar: false});
		} else if (e && e.keyCode === 13 && !this.state.selectingStartDate) {
			// focus back on the button if both dates have been selected
			if (this.endDateRef) {
				this.endDateRef.focus();
			}
		}
	}

	handleEscPress(e) {
		if (e && e.keyCode === 27 && this.state.showCalendar) {
			const newState = {showCalendar: false, cancelUpdate: true, hoveredDate: null};
			this.setState(newState);
			e.stopPropagation();
			// focus on the end date button if when closing the datepicker
			if (this.endDateRef) {
				this.endDateRef.focus();
			}
		}
	}

	clearBothDates(e) {
		if (this.props.noPropagation) {
			e.stopPropagation();
			e.preventDefault();
		}

		if (this.clear_dates_btn) {
			this.clear_dates_btn.focus();
		}
		if (this.props.clearBothDates) {
			this.props.clearBothDates();
			this.setState({hoveredDate: null, showCalendar: false, cancelUpdate: true});
		}
	}

	getButtonWidthFromDatePickerStyle(datePickerStyle) {
		switch (datePickerStyle) {
			case DATE_PICKER_STYLE.TASK_MODAL_TASK_DATE:
				return 102;
			case DATE_PICKER_STYLE.COMPACT:
				return 45;
			case DATE_PICKER_STYLE.STANDARD:
				return 110;
			case DATE_PICKER_STYLE.SCOPING_PHASE_DATE:
				return this.props.compactShowYear ? 95 : 65;
			case DATE_PICKER_STYLE.BASELINE_PHASE_DATE:
				return this.props.compactShowYear ? 95 : 65;
			case DATE_PICKER_STYLE.INVOICE:
				return 379;
			case DATE_PICKER_STYLE.MODAL:
				return 186;
			default:
				return 110;
		}
	}

	render() {
		const {formatMessage} = this.props.intl;
		const style = {};
		const leftOffset = this.props.calendarOffsetX ? this.props.calendarOffsetX : 0;
		let calendarPlacement = '';
		let isCalendarPushedToTheLeft = false;

		if (this.offset) {
			if (this.offset.top + 450 > window.innerHeight && this.offset.top - 400 > 0) {
				style.bottom = window.innerHeight - this.offset.top + 12;
				calendarPlacement = 'top';
			} else {
				style.top = this.offset.top + this.offset.height + 12;
				calendarPlacement = 'bottom';
			}
			style.left = this.offset.left + leftOffset;
			//259 px is smallest width calendar can have (single date picker)
			const clientWidth = this.calendars_wrapper ? this.calendars_wrapper.clientWidth : 259;
			if (clientWidth !== null && style.left + clientWidth > window.innerWidth - 80) {
				//-80 because chrome is not smart
				style.left -= 150;
				isCalendarPushedToTheLeft = true;
			}
		}
		const allDatesNull = this.state.startDate == null && this.state.endDate == null;
		// Splitter to determine if we're setting start or end date when hovering (hovering above splitter sets end date, hovering below sets start date)
		let splitter =
			this.state.selectedDates.length > 0
				? this.state.selectedDates[0]
				: this.state.endDate
				? this.state.endDate
				: this.state.startDate;

		// If we've selected a date, that date is now the splitter, and we will either move from start to splitter, or from splitter to end
		let startDate =
			this.state.selectedDates.length > 0
				? this.state.openedOn === 'START' && splitter.isBefore(this.state.endDate)
					? splitter
					: this.state.startDate
				: this.state.startDate;
		let endDate =
			this.state.selectedDates.length > 0
				? this.state.openedOn === 'END' && splitter.isAfter(this.state.startDate)
					? splitter
					: this.state.endDate
				: this.state.endDate;

		// Check if Initial hover (before selecting anything) is between start & end. Used for detecting that we need to move either start or end below
		const isBetweenStartEnd =
			this.state.selectedDates.length === 0 &&
			this.state.hoveredDate &&
			this.state.hoveredDate.isSameOrBefore(this.state.endDate) &&
			this.state.hoveredDate.isSameOrAfter(this.state.startDate);
		// These two are just used for button active styling further down
		let hoverDateIsStart;
		let hoverDateIsEnd;

		// Work out where to place the hovered date
		if (this.props.isSingleDatePicker && this.state.showCalendar) {
			startDate = this.state.hoveredDate || this.state.startDate;
		} else if (!allDatesNull && this.state.showCalendar) {
			if (this.state.hoveredDate && isBetweenStartEnd) {
				// If a date was picked between start & end, we don't know whether to move end back, or start up, so it depends on the button pressed
				if (this.state.openedOn === 'START') {
					startDate = this.state.hoveredDate;
					hoverDateIsStart = true;
				} else {
					endDate = this.state.hoveredDate;
					hoverDateIsEnd = true;
				}
			} else if (this.state.hoveredDate) {
				if (this.state.hoveredDate.isSameOrBefore(splitter)) {
					startDate = this.state.hoveredDate;
					// If we've selected a date, that date must now be the other endpoint
					if (this.state.selectedDates.length > 0 || !this.state.endDate) {
						endDate = splitter;
					}
					hoverDateIsStart = true;
				} else if (this.state.hoveredDate.isSameOrAfter(splitter)) {
					endDate = this.state.hoveredDate;
					// If we've selected a date, that date must now be the other endpoint
					if (this.state.selectedDates.length > 0 || this.state.startDate === null) {
						startDate = splitter;
					}
					hoverDateIsEnd = true;
				}
			} else if (this.state.selectedDates.length > 0) {
				// Handle the case where we have just selected a date and have cleared the hoveredDate
				const selectedDate = this.state.selectedDates[0];
				const existingDate = this.state.endDate ? this.state.endDate : this.state.startDate;
				if (selectedDate.isSameOrBefore(this.state.endDate) && selectedDate.isSameOrAfter(this.state.startDate)) {
					if (this.state.openedOn === 'START') {
						startDate = selectedDate;
					} else {
						endDate = selectedDate;
					}
				} else {
					if (this.state.selectedDates[0].isSameOrBefore(existingDate)) {
						startDate = selectedDate;
						endDate = existingDate;
					} else if (this.state.selectedDates[0].isSameOrAfter(existingDate)) {
						endDate = selectedDate;
						// Handle edge case where start date is null
						if (this.state.startDate === null) {
							startDate = existingDate;
						}
					}
				}
			}
		} else if (allDatesNull && this.state.showCalendar) {
			if (this.state.hoveredDate && this.state.selectedDates.length > 0) {
				if (this.state.hoveredDate.isSameOrBefore(this.state.selectedDates[0])) {
					startDate = this.state.hoveredDate;
					endDate = this.state.selectedDates[0];
					hoverDateIsStart = true;
				} else {
					startDate = this.state.selectedDates[0];
					endDate = this.state.hoveredDate;
					hoverDateIsEnd = true;
				}
			} else if (this.state.hoveredDate) {
				startDate = this.state.hoveredDate;
				hoverDateIsStart = true;
			} else if (this.state.selectedDates.length > 0) {
				startDate = this.state.selectedDates[0];
				endDate = this.state.selectedDates[0];
			}
		}

		const buttonWidth = this.getButtonWidthFromDatePickerStyle(this.props.datePickerStyle);
		const scopingOrBaselinStyle =
			this.props.datePickerStyle === DATE_PICKER_STYLE.SCOPING_PHASE_DATE ||
			this.props.datePickerStyle === DATE_PICKER_STYLE.BASELINE_PHASE_DATE;
		return (
			<div
				className={'date-range-picker-wrapper-new' + (this.props.useCompactStyle ? ' compact' : '')}
				ref={div => (this.date_range_picker_v2 = div)}
				id="date_range_picker_v2"
				data-cy="date-range-picker"
			>
				{this.props.label ? <span className={'date-picker-label'}>{this.props.label}</span> : ''}

				{scopingOrBaselinStyle && startDate === null && endDate === null ? (
					<div className="buttons-wrapper">
						<DatePickerButton
							cy={this.props.buttonCy}
							userpilot={this.props.userpilot}
							onClick={e => this.toggleCalendar(e)}
							id={this.props.id}
							active={hoverDateIsStart && this.state.showCalendar}
							disabled={this.props.disabled}
							onBlur={this.props.handleBlur}
							colorInherited={this.props.colorInherited}
							dateInherited={this.props.startFrom != null || this.props.isCalculated}
							datePickerStyle={this.props.datePickerStyle}
							warning={this.props.startDateWarnings}
							fontWeight={this.props.fontWeight}
							startDateEmptyText={this.props.startDateEmptyText}
							endDateEmptyText={this.props.endDateEmptyText}
							compactShowYear={this.props.compactShowYear}
						/>
					</div>
				) : (
					<div className="buttons-wrapper" data-userpilot={this.props.userpilot}>
						<DatePickerButton
							cy={this.props.buttonCy}
							userpilot={this.props.userpilot + '-start'}
							onClick={e => this.toggleCalendar(e, 'START')}
							id={this.props.id}
							label={this.props.startDateLabel || null}
							date={startDate}
							active={hoverDateIsStart && this.state.showCalendar}
							disabled={this.props.disabled}
							onBlur={this.props.handleBlur}
							colorInherited={this.props.colorInherited}
							dateInherited={this.props.startFrom || this.props.endFrom || this.props.isCalculated}
							datePickerStyle={this.props.datePickerStyle}
							warning={this.props.startDateWarnings}
							fontWeight={this.props.fontWeight}
							startDateEmptyText={this.props.startDateEmptyText}
							endDateEmptyText={this.props.endDateEmptyText}
							highlighted={this.props.highlighted}
							focus={this.props.focusStart}
							compactShowYear={this.props.compactShowYear}
							fullWidth={this.props.fullWidth}
							leftAlign={this.props.leftAlign}
							blank={!startDate}
						/>
						{this.props.datePickerStyle === DATE_PICKER_STYLE.SCOPING_PHASE_DATE ? (
							<div className="button-dash-separator">{' - '}</div>
						) : null}
						{this.props.isSingleDatePicker ? null : (
							<DatePickerButton
								cy={this.props.buttonCy + '-2'}
								userpilot={this.props.userpilot + '-end'}
								onClick={e => this.toggleCalendar(e, 'END')}
								id={this.props.id}
								label={this.props.endDateLabel || null}
								date={endDate}
								isDateRangeEndDateButton={true}
								active={hoverDateIsEnd && this.state.showCalendar}
								disabled={this.props.disabled}
								onBlur={this.props.handleBlur}
								dateInherited={this.props.startFrom || this.props.endFrom || this.props.isCalculated}
								colorInherited={this.props.colorInherited}
								datePickerStyle={this.props.datePickerStyle}
								warning={this.props.deadlineDateWarnings}
								fontWeight={this.props.fontWeight}
								reference={node => (this.endDateRef = node)}
								startDateEmptyText={this.props.startDateEmptyText}
								endDateEmptyText={this.props.endDateEmptyText}
								highlighted={this.props.highlighted}
								focus={this.props.focusEnd}
								compactShowYear={this.props.compactShowYear}
								blank={!endDate}
							/>
						)}
					</div>
				)}

				{this.state.showCalendar
					? ReactDOM.createPortal(
							<div
								ref={div => (this.calendar_pop_out = div)}
								className={'calendars-dropdown-wrapper v3' + (this.props.clearable ? ' v3-clearable' : '')}
								style={style}
								onBlur={this.handleBlur.bind(this)}
							>
								<div
									className={'calendars-wrapper' + (this.props.clearable ? ' v3-clearable' : '')}
									tabIndex="0"
									onClick={this.handleClick.bind(this)}
									ref={div => (this.calendars_wrapper = div)}
									onKeyDown={this.handleKeyDown.bind(this)}
								>
									{this.props.hidePointerArrow ? null : calendarPlacement === 'bottom' ? (
										<div
											className="triangle-up"
											style={{
												left:
													(isCalendarPushedToTheLeft ? 150 : 0) +
													buttonWidth / 2 -
													12 -
													(this.props.calendarOffsetX || 0) +
													(!this.props.isSingleDatePicker &&
													((!hoverDateIsStart && !hoverDateIsEnd && this.state.openedOn === 'END') ||
														hoverDateIsEnd)
														? buttonWidth
														: 0) +
													'px',
												top: '-13px',
											}}
										>
											<svg width="24" height="10">
												<polygon
													style={{
														fill: 'white',
														stroke: '#6e0fea',
														strokeWidth: 1,
														fillRule: 'evenodd',
													}}
													points="0,10 12,0 24,10"
												/>
											</svg>
											<div className="border-cover" />
										</div>
									) : (
										<div
											className="triangle-down"
											style={{
												left:
													(isCalendarPushedToTheLeft ? 150 : 0) +
													buttonWidth / 2 -
													12 -
													(this.props.calendarOffsetX || 0) +
													(!this.props.isSingleDatePicker &&
													((!hoverDateIsStart && !hoverDateIsEnd && this.state.openedOn === 'END') ||
														hoverDateIsEnd)
														? buttonWidth
														: 0) +
													'px',
											}}
										>
											<svg width="24" height="10">
												<polygon
													style={{
														fill: 'white',
														stroke: '#6e0fea',
														strokeWidth: 1,
														fillRule: 'evenodd',
													}}
													points="0,10 12,0 24,10"
												/>
											</svg>
											<div className="border-cover" />
										</div>
									)}
									<Calendar
										isDateRangePicker={!this.props.isSingleDatePicker}
										first={true}
										startDate={startDate}
										endDate={endDate}
										onDateClick={this.handleDateClick.bind(this)}
										viewedMonth={this.state.viewedMonthFirst}
										viewedYear={this.state.viewedYearFirst}
										viewPreviousMonth={this.viewPreviousMonth.bind(this)}
										viewNextMonth={this.viewNextMonth.bind(this)}
										onDayMouseEnter={this.onDayMouseEnter.bind(this)}
										onTableBodyMouseLeave={this.onTableBodyMouseLeave.bind(this)}
										hoveredDate={this.state.hoveredDate}
										refsMap={this.state.secondCalendarRefsMap}
										setRefs={this.setRefs.bind(this)}
										addContiguousDate={this.addContiguousDate.bind(this)}
										previouslyFocusedDate={this.state.previouslyFocusedDate}
										resetPreviouslyFocusedDate={this.resetPreviouslyFocusedDate.bind(this)}
										keyCodeUsed={this.state.keyCodeUsed}
										viewNextYear={this.viewNextYear.bind(this)}
										viewPreviousYear={this.viewPreviousYear.bind(this)}
										startDateLimite={this.props.startDateLimite}
										endDateLimite={this.props.endDateLimite}
										noPropagation={this.props.noPropagation}
										disabledDates={this.props.disabledDates}
									/>
									{this.props.isSingleDatePicker ? null : <div className="spacer" />}
									{this.props.isSingleDatePicker ? null : (
										<Calendar
											isDateRangePicker={true}
											first={false}
											startDate={startDate}
											endDate={endDate}
											onDateClick={this.handleDateClick.bind(this)}
											viewedMonth={this.state.viewedMonthSecond}
											viewedYear={this.state.viewedYearSecond}
											viewNextMonth={this.viewNextMonth.bind(this)}
											onDayMouseEnter={this.onDayMouseEnter.bind(this)}
											onTableBodyMouseLeave={this.onTableBodyMouseLeave.bind(this)}
											hoveredDate={this.state.hoveredDate}
											refsMap={this.state.firstCalendarRefsMap}
											setRefs={this.setRefs.bind(this)}
											addContiguousDate={this.addContiguousDate.bind(this)}
											previouslyFocusedDate={this.state.previouslyFocusedDate}
											resetPreviouslyFocusedDate={this.resetPreviouslyFocusedDate.bind(this)}
											keyCodeUsed={this.state.keyCodeUsed}
											viewNextYear={this.viewNextYear.bind(this)}
											viewPreviousYear={this.viewPreviousYear.bind(this)}
											noPropagation={this.props.noPropagation}
											disabledDates={this.props.disabledDates}
										/>
									)}
								</div>
								{this.props.startFrom || this.props.endFrom || this.props.isCalculated ? (
									<div className="dates-from">
										<span className="star">*</span>
										<span>
											{this.props.isCalculated ? (
												<FormattedMessage id={'baseline_role.claculated_dates_reason'} />
											) : (
												<FormattedMessage
													id={
														this.props.datesInheritedFrom
															? 'card_modal.dates_from_detailed'
															: 'card_modal.dates_from'
													}
													values={{
														name: this.props.startFrom || this.props.endFrom,
														predecessor: this.props.datesInheritedFrom
															? this.props.datesInheritedFrom === 'PROJECT'
																? formatMessage({id: 'common.project'})
																: this.props.datesInheritedFrom === 'PHASE'
																? formatMessage({id: 'common.phase'})
																: this.props.datesInheritedFrom === 'SPRINT'
																? formatMessage({id: 'common.sprint'})
																: ''
															: '',
													}}
												/>
											)}
										</span>
									</div>
								) : this.props.startDateLimite && !this.props.hideLimitMessage ? (
									<div className="limite-date">
										<span>
											<FormattedMessage
												id={'time_lock.date_picker_info'}
												values={{date: this.props.startDateLimite.format('LL')}}
											/>
										</span>
									</div>
								) : null}
								<div
									className={
										'footer-section-v3' +
										(!this.props.startFrom && !this.props.endFrom ? ' extra-margin' : '') +
										(this.props.clearable ? ' clearable' : '')
									}
									tabIndex={0}
								>
									<button
										className="footer-button"
										onClick={this.handleTodayClick.bind(this)}
										data-cy={'today-button'}
									>
										<div className={'calender-icon'}></div>
										{'See Today'}
									</button>
									{this.props.clearable ? (
										<button
											ref={btn => (this.clear_dates_btn = btn)}
											className="footer-button"
											onClick={this.clearBothDates.bind(this)}
											data-cy={'clear-dates-button'}
										>
											<div className={'trash-icon'}></div>
											{formatMessage({id: 'common.clear_dates'})}
										</button>
									) : null}
								</div>
							</div>,
							document.querySelector('#root-portal-container')
					  )
					: null}
			</div>
		);
	}
}
DateRangePicker.propTypes = {
	startDate: PropTypes.object,
	endDate: PropTypes.object,
	startFrom: PropTypes.string,
	endFrom: PropTypes.string,
	handleDateRangeChange: PropTypes.func.isRequired,
	calendarOffsetX: PropTypes.number,
	label: PropTypes.string,
	customBackgroundColor: PropTypes.string,
	whiteText: PropTypes.bool,
	showEmptyDate: PropTypes.bool,
	handleBlur: PropTypes.func,
	clearable: PropTypes.bool,
	useCompactStyle: PropTypes.bool,
	hidePointerArrow: PropTypes.bool,
	colorInherited: PropTypes.bool,
	clearBothDates: PropTypes.func,
	datePickerStyle: PropTypes.number.isRequired,
	collapseOnWheel: PropTypes.bool,
	refToFocusAfterSelect: PropTypes.object,
	startDateEmptyText: PropTypes.string,
	endDateEmptyText: PropTypes.string,
	isSingleDatePicker: PropTypes.bool,
	highlighted: PropTypes.bool,
	focusStart: PropTypes.bool,
	focusEnd: PropTypes.bool,
	startDateLimite: PropTypes.object,
	hideLimitMessage: PropTypes.bool,
	mediumHeight: PropTypes.bool,
	fullWidth: PropTypes.bool,
	leftAlign: PropTypes.bool,
	disabledDates: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
};
export default injectIntl(DateRangePicker);
