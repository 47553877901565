import React from 'react';
import { DeprecatedText as Text } from '@forecast-it/design-system';
import { getNotAllowedReasonEntityLabel, } from '../../util/time-registration/time-registration-settings/TimeRegistrationNotAllowedReason';
import styled from 'styled-components';
const TimeRegistrationNotAllowedTooltip = styled.div `
	display: flex;
	flex-direction: column;
	gap: 8px;
`;
export function getTimeRegistrationNotAllowedTooltip(notAllowedReason) {
    const notAllowedReasonEntityLabel = getNotAllowedReasonEntityLabel(notAllowedReason);
    return (React.createElement(TimeRegistrationNotAllowedTooltip, null,
        React.createElement(Text, null, `Your company does not allow registering time ${notAllowedReasonEntityLabel}.`),
        React.createElement(Text, { type: 'small', color: 'secondary' }, "Contact your admin for more information.")));
}
