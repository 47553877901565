/* eslint-disable no-unused-vars */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components/macro';
import {TaskRow} from '../elements/TaskRow';
import {PhaseHeaderRow} from '../elements/PhaseHeaderRow';
import {PhasePersonGroupingRow} from '../elements/PhasePersonGroupingRow';
import {PhaseRoleGroupingRow} from '../elements/PhaseRoleGroupingRow';
import QuickAddTask from '../elements/QuickAddTask';
import {BaselineRow} from '../elements/BaselineRow';
import {PeriodRow} from '../elements/PeriodRow';
import Util from '../../../../util/util';

const styles = {};
export const TYPES = {
	HEADER: 'header',
	TASK: 'task',
	BASELINE: 'baseline',
	PERIOD: 'period',
	ROLE_GROUPING: 'role_grouping',
	NO_ROLE_GROUPING: 'no_role_grouping',
	PERSON_GROUPING: 'person_grouping',
	ADD_TASK: 'add_task',
	EMPTY_FILL: 'empty_fill',
};

function isDescendant(older, younger) {
	return (
		!!older.children &&
		typeof older.children !== 'function' &&
		older.children.some(child => child === younger || isDescendant(child, younger))
	);
}

const LineBlock = Styled.div`
height: 100%;
  position: relative;
  display: inline-block;
  flex: 0 0 auto;
`;

const AbsoluteLineBlock = Styled(LineBlock)`
	position: absolute;
 	top: 0;
`;

const Row = Styled.div`
  height: 100%;
  white-space: nowrap;
  display: flex;
  position: relative;
  width: 100%;
  box-sizing: border-box;
`;

const Grouping = Styled(Row)`
    border-bottom: 1px solid #545454;
    height: 20px;
    padding: 2px;
`;

const Header = Styled(Row)`
    background-color: ${({color}) => color};
    padding: 4px;
`;

const HeaderComponent = ({node, toggleChildrenVisibility, path, treeIndex}) => {
	return (
		<Header
			onClick={() =>
				toggleChildrenVisibility({
					node,
					path,
					treeIndex,
				})
			}
			color={node.color}
		>
			{node.title}
		</Header>
	);
};

const GroupingComponent = ({node}) => {
	return <Grouping>{node.title}</Grouping>;
};

// eslint-disable-next-line react/prefer-stateless-function
export class NodeContentRenderer extends Component {
	constructor(props) {
		super(props);
		this.handleToggleVisibility = this.handleToggleVisibility.bind(this);
	}

	isTopAddTask(node) {
		if (node.project && node.project.useBaseline && this.props.treeIndex === 2) {
			return true;
		} else {
			return this.props.treeIndex === 1;
		}
	}

	handleToggleVisibility() {
		const {toggleChildrenVisibility, node, path, treeIndex} = this.props;
		toggleChildrenVisibility({
			node,
			path,
			treeIndex,
		});
	}

	shouldIndent(nodeType) {
		switch (nodeType) {
			case TYPES.HEADER:
			case TYPES.PERIOD:
			case TYPES.ADD_TASK:
				return false;
			default:
				return true;
		}
	}

	childrenDepth(children) {
		return Array.isArray(children) && !!children.length
			? 1 + Math.max(0, ...children.map(child => this.childrenDepth(child.children)))
			: 0;
	}

	render() {
		const {
			scaffoldBlockPxWidth,
			toggleChildrenVisibility,
			connectDragPreview,
			connectDragSource,
			isDragging,
			canDrop,
			canDrag,
			node,
			parent,
			title,
			draggedNode,
			path,
			treeIndex,
			isSearchMatch,
			isSearchFocus,
			icons,
			buttons,
			className,
			style,
			didDrop,
			lowerSiblingCounts,
			listIndex,
			swapFrom,
			swapLength,
			swapDepth,
			treeId, // Not needed, but preserved for other renderers
			isOver, // Not needed, but preserved for other renderers
			parentNode, // Needed for dndManager
			rowDirection,
			...otherProps
		} = this.props;

		// Construct the scaffold representing the structure of the tree
		const scaffold = [];
		const maxSubTaskDepth = Util.getMaxSubtaskDepth(!!(node.taskProject && node.taskProject.isJiraProject));
		const childDepth = this.childrenDepth(draggedNode?.children);
		const maxDepthExceeded = swapDepth ? childDepth + swapDepth > maxSubTaskDepth : false;

		// Creating indent
		if (this.shouldIndent(node.type) && node.level) {
			const indentLevel = isDragging ? swapDepth : node.level;
			for (let i = 0; i < indentLevel; i++) {
				scaffold.push(<LineBlock key={`pre_${1 + i}`} style={{width: scaffoldBlockPxWidth}} />);

				//still dont know what this is used for exactly
				if (treeIndex !== listIndex && i === swapDepth) {
					scaffold.push(
						<AbsoluteLineBlock
							key={`highlight_${1 + i}`}
							style={{
								width: scaffoldBlockPxWidth,
								left: scaffoldBlockPxWidth * (i - 1),
							}}
						/>
					);
				}
			}
		}

		const getElement = node => {
			switch (node.type) {
				case TYPES.HEADER:
					return <PhaseHeaderRow node={node} onClick={this.handleToggleVisibility} cy="phase" topHeader={false} />;
				case TYPES.TASK:
					return <TaskRow node={node} draggedNode={draggedNode} toggleExpansion={this.handleToggleVisibility} />;
				case TYPES.BASELINE:
					return <BaselineRow node={node} />;
				case TYPES.PERIOD:
					return <PeriodRow node={node} />;
				case TYPES.GROUPING:
					return <GroupingComponent node={node} />;
				case TYPES.ROLE_GROUPING:
				case TYPES.NO_ROLE_GROUPING:
					return <PhaseRoleGroupingRow node={node} onClick={this.handleToggleVisibility} />;
				case TYPES.PERSON_GROUPING:
					return <PhasePersonGroupingRow node={node} onClick={this.handleToggleVisibility} />;
				case TYPES.ADD_TASK:
					return (
						<QuickAddTask
							disabled={node.projectStatus === 'DONE'}
							isEstimatedInHours={node.project.estimationUnit === 'HOURS'}
							projectId={node.project.id}
							companyId={node.companyId}
							phaseId={node.phaseId}
							addTask={node.handleAddTask}
							roles={node.roles}
							projectPersons={node.projectPersons}
							availableFeatureFlags={node.availableFeatureFlags}
							parentTaskId={node.parentTaskId}
							isTopAddTask={this.isTopAddTask(node)}
						/>
					);
				default:
					return <div></div>;
			}
		};

		const nodeContent = (
			<div
				style={{
					height: '100%',
					width: '100%',
					display: 'flex',
				}}
				{...otherProps}
			>
				{/* Set the row preview to be used during drag and drop */}
				{scaffold}
				{connectDragPreview(
					<div style={{display: 'flex', width: '100%'}}>
						{!didDrop && isDragging ? (
							<div
								style={{
									width: '100%',
									background: canDrop && !maxDepthExceeded ? '#f7f7fe' : '#ffbcb8',
									padding: '4px',
								}}
							>
								<div style={{width: '100%', height: '100%', border: '0px'}} />
							</div>
						) : (
							getElement(node)
						)}
					</div>
				)}
			</div>
		);

		return canDrag ? connectDragSource(nodeContent, {dropEffect: 'copy'}) : nodeContent;
	}
}

NodeContentRenderer.defaultProps = {
	buttons: [],
	canDrag: false,
	canDrop: false,
	className: '',
	draggedNode: null,
	icons: [],
	isSearchFocus: false,
	isSearchMatch: false,
	parentNode: null,
	style: {},
	swapDepth: null,
	swapFrom: null,
	swapLength: null,
	title: null,
	toggleChildrenVisibility: null,
};

NodeContentRenderer.propTypes = {
	buttons: PropTypes.arrayOf(PropTypes.node),
	canDrag: PropTypes.bool,
	className: PropTypes.string,
	icons: PropTypes.arrayOf(PropTypes.node),
	isSearchFocus: PropTypes.bool,
	isSearchMatch: PropTypes.bool,
	listIndex: PropTypes.number.isRequired,
	lowerSiblingCounts: PropTypes.arrayOf(PropTypes.number).isRequired,
	node: PropTypes.shape({}).isRequired,
	path: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
	scaffoldBlockPxWidth: PropTypes.number.isRequired,
	style: PropTypes.shape({}),
	swapDepth: PropTypes.number,
	swapFrom: PropTypes.number,
	swapLength: PropTypes.number,
	title: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
	toggleChildrenVisibility: PropTypes.func,
	treeIndex: PropTypes.number.isRequired,
	treeId: PropTypes.string.isRequired,
	rowDirection: PropTypes.string.isRequired,

	// Drag and drop API functions
	// Drag source
	connectDragPreview: PropTypes.func.isRequired,
	connectDragSource: PropTypes.func.isRequired,
	didDrop: PropTypes.bool.isRequired,
	draggedNode: PropTypes.shape({}),
	isDragging: PropTypes.bool.isRequired,
	parentNode: PropTypes.shape({}), // Needed for dndManager
	// Drop target
	canDrop: PropTypes.bool,
	isOver: PropTypes.bool.isRequired,
};

export default NodeContentRenderer;
