import React, {useEffect, useMemo, useState} from 'react';
import {Checkbox, TaskRowV2 as TaskRowComponentV2} from 'web-components';
import {createFragmentContainer, graphql} from 'react-relay';
import {FavouredActionWrapper} from '../../../hooks/favoured_action_wrapper';
import {
	assigneesElement,
	getDateText,
	getEstimateHours,
	getEstimatePoints,
	getProgressPercentage,
	getRemainingHours,
	getRemainingPoints,
	ownerElement,
	statusElement,
} from './task_row_elements_logic';
import GenericTaskContextMenu from '../../context-menus/generic_task_context_menu';
import TimeRegPopup from '../../popups/time_reg_popup';
import {ESTIMATION_UNIT} from '../../../../../constants';
import {hasPermission} from '../../../util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../../Permissions';
import {ProgressRegistrationPopup} from '../../popups/ProgressRegistrationPopup';
import Util from '../../../util/util';
import DeprecatedProjectIndicatorJS from '../../project-indicator/js/DeprecatedProjectIndicatorJS';

const TaskRow = ({intl, task, enabledColumns, showTaskModal, viewer, cy}) => {
	const [selected, setSelected] = useState(false);
	const [contextMenuPosition, setContextMenuPosition] = useState();
	const isClientViewRestricted = useMemo(() => Util.isClientTaskViewRestricted(viewer), [viewer]);
	const isClientActionsRestricted = useMemo(() => Util.isClientTaskActionsRestricted(viewer), [viewer]);
	const [taskAssignedPersons, setTaskAssignedPersons] = useState(task.assignedPersons || []);

	useEffect(() => {
		if (isClientViewRestricted) {
			const assignedPersons = task.assignedPersons || [];
			setTaskAssignedPersons(assignedPersons.filter(person => person.id === viewer.actualPersonId));
		}
	}, [task, isClientViewRestricted]);

	const onCheckHandler = c => {
		setSelected(!selected);
	};

	const openCardModal = (e, companyTaskId) => {
		e.stopPropagation();
		e.preventDefault();
		if (showTaskModal) {
			showTaskModal(companyTaskId);
		}
	};

	const openContextMenu = e => {
		e.preventDefault();
		const contextMenuPosition = {};
		const btn = document.elementFromPoint(e.pageX, e.pageY);
		if (btn != null && e.type !== 'contextmenu') {
			const btnBounds = btn.getBoundingClientRect();
			contextMenuPosition.x = btnBounds.right - btn.clientWidth - 2;
			if (window.innerHeight - btnBounds.bottom < 250) {
				contextMenuPosition.y = btnBounds.bottom - 282;
			} else {
				contextMenuPosition.y = btnBounds.bottom + 2;
			}
		} else {
			contextMenuPosition.x = e.pageX;
			//check if there is place for menu underneath cursor
			if (window.innerHeight - e.pageY < 250) {
				contextMenuPosition.y = e.pageY - 250;
			} else {
				contextMenuPosition.y = e.pageY;
			}
		}
		setContextMenuPosition(contextMenuPosition);
	};

	const closeContextMenu = () => {
		setContextMenuPosition(null);
	};

	const isEstimatedInPoints = task.project.estimationUnit === ESTIMATION_UNIT.POINTS;

	// Parent task name
	const parentTaskName = task.project.useTaskHierarchy && task.parentTask?.name;

	const noTimeReg = task.project.manualProgressOnTasksEnabled;

	const isTaskReadOnly = task.readOnly?.isReadOnly;

	const disabled = isTaskReadOnly || isClientActionsRestricted;

	return (
		<TaskRowComponentV2
			disabled={disabled}
			cy={cy}
			checkboxIcon={() => <Checkbox checked={selected} onClick={c => onCheckHandler(c)} />}
			taskId={task.companyTaskId}
			taskName={task.name}
			isFavoured={task.favoured}
			starIcon={({isFavoured}) => <FavouredActionWrapper isFavoured={isFavoured} taskId={task.id} />}
			projectIndicatorIcon={<DeprecatedProjectIndicatorJS project={task.project} />}
			projectName={task.project.name}
			clientName={task.project.client && task.project.client.name}
			columns={enabledColumns}
			isSelected={selected}
			openCardModal={e => openCardModal(e, task.companyTaskId)}
			assigneesElement={assigneesElement(task, taskAssignedPersons)}
			ownerElement={ownerElement(task)}
			statusElement={statusElement(task, intl, disabled)}
			startDate={getDateText(task.startDay, task.startMonth, task.startYear, intl)}
			deadline={getDateText(task.deadlineDay, task.deadlineMonth, task.deadlineYear, intl)}
			phase={task.phase ? task.phase.name : null}
			estimate={
				hasPermission(PERMISSION_TYPE.CLIENT_HIDE_ESTIMATES)
					? '-'
					: isEstimatedInPoints
					? getEstimatePoints(task, intl)
					: getEstimateHours(task, intl)
			}
			timeEntries={
				hasPermission(PERMISSION_TYPE.CLIENT_HIDE_TIME_ENTRIES)
					? '-'
					: Util.convertMinutesToFullHour(task.totalMinutesRegistered, intl)
			}
			remaining={
				hasPermission(PERMISSION_TYPE.CLIENT_HIDE_ESTIMATES) || hasPermission(PERMISSION_TYPE.CLIENT_HIDE_TIME_ENTRIES)
					? '-'
					: isEstimatedInPoints
					? getRemainingPoints(task, intl)
					: getRemainingHours(task, intl)
			}
			labels={task.taskLabels.map(entry => entry.label)} //The actual label is stored in the label field under taskLabels
			progress={
				task.project.manualProgressOnTasksEnabled
					? (task.progressDetails ? task.progressDetails.progress : 0) + '%'
					: getProgressPercentage(task, intl)
			}
			onContextMenu={e => openContextMenu(e)}
			contextMenuElement={() =>
				contextMenuPosition && (
					<GenericTaskContextMenu
						cy="my-work-task-context-menu"
						task={task}
						viewer={viewer}
						contextMenuPosition={contextMenuPosition}
						closeContextMenu={() => closeContextMenu()}
					/>
				)
			}
			isBug={task.bug}
			isHighPriority={task.highPriority}
			isBlocked={task.blocked}
			timeRegPopup={
				!isTaskReadOnly && <TimeRegPopup viewer={viewer} projectId={task.project.id} task={task} harvestTask={false} />
			}
			parentTaskName={parentTaskName}
			intl={intl}
			noTimeReg={noTimeReg}
			progressRegistrationPopup={!isTaskReadOnly && <ProgressRegistrationPopup task={task} />}
		/>
	);
};

export default createFragmentContainer(TaskRow, {
	task: graphql`
		fragment TaskRow_task on Task @argumentDefinitions(personId: {type: "ID"}) {
			id
			name
			companyTaskId
			startYear
			startMonth
			startDay
			deadlineDay
			deadlineMonth
			deadlineYear
			favoured
			estimateForecast
			estimateForecastMinutes
			timeLeft
			progress
			blocked
			highPriority
			billable
			readOnly {
				isReadOnly
			}
			userCanDeleteTask
			userCantDeleteTaskReason
			bug
			parentTaskId
			totalMinutesRegistered
			hasInvoicedTime
			assignedPersons {
				id
				firstName
				lastName
				fullName
				profilePictureId
				profilePictureDefaultId
				active
				permissions
				role {
					id
					name
				}
			}
			role {
				id
				name
			}
			owner {
				id
				firstName
				lastName
				fullName
				profilePictureId
				profilePictureDefaultId
				role {
					id
					name
				}
			}
			phase {
				id
				name
			}
			statusColumnV2 {
				id
				name
				category
			}
			project {
				id
				name
				estimationUnit
				projectStartYear
				projectStartMonth
				projectStartDay
				projectEndYear
				projectEndMonth
				projectEndDay
				projectColor
				companyProjectId
				fullAccessToProject
				status
				useTaskHierarchy
				manualProgressOnTasksEnabled
				harvestProject {
					id
				}
				unit4Project {
					id
					activitiesEnabled
				}
				client {
					id
					name
				}
				projectPerson(personId: $personId) {
					role {
						id
						name
					}
				}
				statusColumnsV2(first: 1000000) {
					edges {
						node {
							id
							name
							category
							order
						}
					}
				}
				...DeprecatedProjectIndicatorJS_project
			}
			assignedPersons {
				id
				firstName
				lastName
				fullName
				profilePictureId
				profilePictureDefaultId
				active
				permissions
				role {
					id
					name
				}
			}
			taskLabels {
				id
				label {
					id
					name
					color
				}
			}
			parentTask {
				id
				name
			}
			progressDetails {
				progress
			}
		}
	`,
});
