import React from 'react';
import { EconomicIcon, QuickbooksIcon, SIZE, XeroIcon } from 'web-components';
import { INVOICE_INTEGRATIONS } from './InvoiceIntegrationsUtils';
const InvoicingIntegrationIcon = ({ integration, size }) => {
    switch (integration) {
        case INVOICE_INTEGRATIONS.XERO:
            return React.createElement(XeroIcon, { size: size || SIZE.DROPDOWN });
        case INVOICE_INTEGRATIONS.ECONOMIC:
            return React.createElement(EconomicIcon, { size: size || SIZE.DROPDOWN });
        case INVOICE_INTEGRATIONS.QUICKBOOKS:
            return React.createElement(QuickbooksIcon, { size: size || SIZE.DROPDOWN });
        default:
            return null;
    }
};
export default InvoicingIntegrationIcon;
