import React from 'react';
import {StandardMargin} from '../../../components/insights/invoicing/InvoicingPage.styled';
import {SubNavigation} from 'web-components';
import {useIntl} from 'react-intl';
import {useHistory, useLocation} from 'react-router-dom';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../shared/components/headers/top-header-bar/TopHeaderBar';

export const getProjectSubPages = () => [
	{
		value: '/settings/priority-levels',
		label: 'settings.priority-levels',
	},
];

export const findProjectSubPage = pathname => {
	return getProjectSubPages().find(subPage => pathname?.startsWith?.(subPage.value));
};

const SettingsProjectSubNav = () => {
	const {formatMessage} = useIntl();
	const {pathname} = useLocation();
	const history = useHistory();
	const activeProjectSubPage = findProjectSubPage(pathname);

	const handleSetActivePage = value => {
		const newPage = getProjectSubPages().some(option => option.value === value);
		if (activeProjectSubPage?.value !== value && newPage) {
			history.push(value);
		}
	};

	return (
		<>
			<TopHeaderBarWrapper>
				<TopHeaderBar title="Project Priority" />
			</TopHeaderBarWrapper>
			<div style={{marginTop: '20px'}}>
				<StandardMargin data-cy={'Projects-tabs'}>
					{getProjectSubPages().map(option => (
						<SubNavigation.NavigationItem
							active={activeProjectSubPage?.value === option.value}
							onClick={() => handleSetActivePage(option.value)}
							key={option.value}
							cy={'settings-projects-tabs' + option.value.toLowerCase().replaceAll('/', '-')}
							userpilot={'settings-projects-tabs' + option.value.toLowerCase().replaceAll('/', '-')}
						>
							{formatMessage({id: option.label})}
						</SubNavigation.NavigationItem>
					))}
				</StandardMargin>
			</div>
		</>
	);
};

export default SettingsProjectSubNav;
