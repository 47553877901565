import React from 'react';
import { Text, IconTooltip } from '@forecast-it/design-system';
function TaskRequiredInfo() {
    return (React.createElement(React.Fragment, null,
        React.createElement(Text, { size: '2' }, "You need to select a task to create a time registration."),
        React.createElement(Text, { size: '2', color: 'medium' }, "This is a company-wide setting configured by your admin.")));
}
export function TaskRequiredInfoIcon() {
    return (React.createElement(IconTooltip, { icon: 'info', color: 'disabled', iconSize: 's' },
        React.createElement(TaskRequiredInfo, null)));
}
export function TaskRequiredError() {
    return (React.createElement(Text, { color: 'error', size: '2' }, "Selecting a task is required for time entries. This is a company-wide setting."));
}
