import DataExportFormatter from './DataExportFormatter';
import ProjectUtil from '../project_util';
import {ESTIMATION_UNIT} from '../../../../constants';
import {CUSTOM_FIELD_PREFIX, getCustomFieldColumnName} from '../../../project-tab/projects/scoping-page/ProjectScopingUtil';
import {getNonBillableTime} from '../time-registration/time-registration-settings/BillableTimeSplitUtil';

export default class TaskFormatter extends DataExportFormatter {
	#estimatedInHoursMap;
	#minsPerPointMap;

	constructor(intl, mandatoryColumns = [], columnsToIgnore = [], shouldSplitDate = false) {
		super(intl, mandatoryColumns, columnsToIgnore, shouldSplitDate);

		this.#estimatedInHoursMap = new Map();
		this.#minsPerPointMap = new Map();
	}

	_storeGeneralData(generalData) {
		generalData.projects.forEach(project => {
			const id = project.id;

			this.#estimatedInHoursMap.set(id, project.estimationUnit === ESTIMATION_UNIT.HOURS);
			this.#minsPerPointMap.set(id, project.minutesPerEstimationPoint);
		});
	}

	_pointsToMinutes(points, task) {
		return points * this._minsPerPoint(task);
	}

	_estimateInMinutes(estimate, task) {
		return this._isEstimatedInTime(task) ? estimate : this._pointsToMinutes(estimate, task);
	}

	_differenceInMinutes(difference, task) {
		return this._isEstimatedInTime(task) ? difference : this._pointsToMinutes(difference, task);
	}

	_isEstimatedInTime(task) {
		return this.#estimatedInHoursMap.get(task.project.id);
	}

	_minsPerPoint(task) {
		return this.#minsPerPointMap.get(task.project.id);
	}

	_formatEstimate(estimate, task) {
		return estimate ? this._formatNumber(this._estimateInMinutes(estimate, task)) : '0';
	}

	_formatDifference(difference, task) {
		return difference ? this._formatNumber(this._differenceInMinutes(difference, task)) : '0';
	}

	_formatEstimateMinusTTR(estimate, task) {
		return estimate
			? this._formatNumber(
					this._estimateInMinutes(estimate, task) - this._getTimeEntriesInMinutes(task.timeRegistrations)
			  )
			: '0';
	}

	_formatIndicators(task) {
		let indicators = '';
		indicators += task.highPriority ? this._formatIntlMessage('common.high_priority') + ', ' : '';
		indicators += task.bug ? this._formatIntlMessage('common.bug') + ', ' : '';
		indicators += !task.billable ? this._formatIntlMessage('common.non-billable') + ', ' : '';
		indicators += task.blocked ? this._formatIntlMessage('common.blocked') + ', ' : '';
		return indicators;
	}

	_getTimeEntriesInMinutes(task) {
		if (task.timeRegistrations !== undefined) {
			return task.timeRegistrations.edges.reduce((acc, elem) => acc + elem.node.minutesRegistered, 0);
		}
		return 0;
	}

	_getBillableTimeEntriesInMinutes(task) {
		if (task.timeRegistrations !== undefined) {
			return task.timeRegistrations.edges.reduce((acc, elem) => acc + elem.node.billableMinutesRegistered, 0);
		}
		return 0;
	}

	_getNonBillableTimeEntriesInMinutes(task) {
		return getNonBillableTime(this._getTimeEntriesInMinutes(task), this._getBillableTimeEntriesInMinutes(task));
	}

	_columnFormatHook(name) {
		switch (name) {
			case 'task':
				return ['task_id', 'task_name'];
			case 'actual_price':
			case 'actualRevenue':
				return 'actual_revenue';
			case 'done_percentage':
				return 'progress';
			case 'forecast':
				return 'estimate';
			case 'over_forecast':
				return 'difference_estimate';
			case 'price':
			case 'plannedRevenue':
				return 'plan_revenue';
			case 'assignees':
				return 'assigned_person';
			case 'phase':
				return 'phase_name';
			case 'sprint':
				return 'sprint_name';
			case 'project':
				return 'project_name';
			case 'taskDates.startDate':
				return 'task_start_date';
			case 'phaseDates.startDate':
				return 'phase_start_date';
			case 'sprintDates.startDate':
				return 'sprint_start_date';
			case 'taskDates.deadline':
				return 'task_deadline_date';
			case 'projectDeadline':
				return 'project_deadline_date';
			case 'phaseDates.deadline':
				return 'phase_deadline_date';
			case 'sprintDates.deadline':
				return 'sprint_deadline_date';
			case 'projectStage':
				return 'project_stage';
			case 'clientName':
				return 'client_name';
			case 'roleName':
				return 'assigned_role';
			case 'work.estimate':
				return 'estimate';
			case 'work.remaining':
				return 'remaining';
			case 'work.difference':
				return 'difference';
			case 'reported.reportedTime':
				return 'time_entries';
			case 'non_billable_time-entries':
				// Added as a bandaid fix because DataExportFormatter._formatColumnNames uses replace instead of replaceAll, so only the first occurence of a dash is changed. Unfortunately, fixing DataExportFormatter could alter the export format of other columns, thereby rendering some customer's export setups incompatible with external setups.
				return 'non_billable_time_entries';
			case 'reported.projected':
				return 'projected';
			case 'rateCard':
				return 'rate_card';
			case 'planned-cost':
				return 'planned_cost';
			case 'actual-cost':
				return 'actual_cost';
			case 'financials.revenueRecognition.recognitionLockedRevenue':
				return 'revenue_recognition_from_locked_months';
			case 'financials.revenueRecognition.recognitionOpenRevenue':
				return 'revenue_recognition_from_open_months';
			case 'financials.revenueRecognition.recognitionForecastRevenue':
				return 'total_revenue_recognition_at_completion';
			case 'financials.revenueRecognition.recognitionSurplus':
				return 'variance';
			case 'financials.timeMaterial.plannedRevenue':
				return 'planned_billable_time';
			case 'financials.timeMaterial.actualRevenue':
				return 'actual_billable_time_spent_to_date';
			case 'financials.timeMaterial.remainingRevenue':
				return 'forecast_billable_time_and_expenses_to_complete';
			case 'financials.timeMaterial.forecastRevenue':
				return 'total_billable_time_at_completion';
			default:
				return null;
		}
	}

	_getFormattedValueHook(column, task) {
		switch (column) {
			case 'task_start_date':
				return this._formatDate(task.startDay, task.startMonth, task.startYear);
			case 'task_deadline_date':
				return this._formatDate(task.deadlineDay, task.deadlineMonth, task.deadlineYear);
			case 'project_deadline_date':
				return this._formatDate(
					task.project?.projectEndDay,
					task.project?.projectEndMonth,
					task.project?.projectEndYear
				);
			case 'project_stage':
				return this._formatIntlMessage('project_status.' + task.project.status.toLowerCase());
			case 'client_name':
				return this._formatNamedObject(task.project.client, '');
			case 'status':
				return this._formatNamedObject(task.statusColumnV2, '');
			case 'assigned_person':
				return this._formatArray(task.assignedPersons, persons => persons.map(person => this._fullname(person)));
			case 'phase_name':
				return this._formatNamedObject(task.phase, 'No phase');
			case 'phase_start_date':
				return this._formatDate(task.phase?.startDay, task.phase?.startMonth, task.phase?.startYear);
			case 'phase_deadline_date':
				return this._formatDate(task.phase?.deadlineDay, task.phase?.deadlineMonth, task.phase?.deadlineYear);
			case 'sprint_name':
				return this._formatNamedObject(task.sprint, 'Backlog');
			case 'sprint_start_date':
				return this._formatDate(task.sprint?.startDay, task.sprint?.startMonth, task.sprint?.startYear);
			case 'sprint_deadline_date':
				return this._formatDate(task.sprint?.endDay, task.sprint?.endMonth, task.sprint?.endYear);
			case 'remaining':
				return this._formatEstimate(task.timeLeft, task);
			case 'difference':
				return this._formatDifference(task.estimateForecast - task.timeLeft, task);
			case 'time_entries':
				return this._formatNumber(this._getTimeEntriesInMinutes(task));
			case 'billable_time_entries':
				return this._formatNumber(this._getBillableTimeEntriesInMinutes(task));
			case 'non_billable_time_entries':
				return this._formatNumber(this._getNonBillableTimeEntriesInMinutes(task));
			case 'projected':
				return this._formatNumber(task.projectedTime / 60);
			case 'progress':
				return this._formatPercentage(task.progress);
			case 'rate_card':
				return this._formatNamedObject(task.project.rateCard, '');
			case 'plan_revenue':
				return this._formatCurrency(ProjectUtil.getEstimatedPrice(task));
			case 'actual_revenue':
				return this._formatCurrency(ProjectUtil.getActualPriceForTask(task));
			case 'planned_cost':
				return this._formatCurrency(ProjectUtil.getPlannedCostForTask(task));
			case 'actual_cost':
				return this._formatCurrency(ProjectUtil.getActualCostForTask(task));
			case 'approved':
				return this._formatBoolean(task.approved, 'approved', 'unapproved');
			case 'indicators':
				return this._formatIndicators(task);
			case 'assigned_role':
				return this._formatNamedObject(task.role, '');
			case 'difference_estimate':
				return this._formatEstimateMinusTTR(task.estimateForecast - this._getTimeEntriesInMinutes(task), task);
			case 'estimate':
				return this._formatEstimate(task.estimateForecast, task);
			case 'labels':
				return this._formatArray(task.taskLabels, labels => labels.map(label => label.label.name));
			case 'actual_revenue_recognised_to_date':
				return this._formatCurrency(task.financialNumbers.recognitionLockedRevenue);
			case 'forecast_revenue_to_be_recognised':
				return this._formatCurrency(task.financialNumbers.recognitionOpenRevenue);
			case 'total_revenue_recognition_at_completion':
				return this._formatCurrency(task.financialNumbers.recognitionForecastRevenue);
			case 'variance':
				return this._formatCurrency(task.financialNumbers.surplus);
			case 'planned_billable_time':
				return this._formatCurrency(task.financialNumbers.plannedRevenue);
			case 'actual_billable_time_spent_to_date':
				return this._formatCurrency(task.financialNumbers.actualRevenue);
			case 'forecast_billable_time_and_expenses_to_complete':
				return this._formatCurrency(task.financialNumbers.remainingRevenue);
			case 'total_billable_time_at_completion':
				return this._formatCurrency(task.financialNumbers.forecastRevenue);
			default:
				if (column.startsWith(CUSTOM_FIELD_PREFIX)) {
					const customFieldColumn = column.startsWith(CUSTOM_FIELD_PREFIX + '.')
						? column.slice(CUSTOM_FIELD_PREFIX.length + 1)
						: column;
					return task.customFieldValues?.edges
						.map(edge => edge.node)
						.find(cfv => getCustomFieldColumnName('TASK', cfv.key) === customFieldColumn)?.value;
				}
				return null;
		}
	}
}
