import React, {useState} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {withRouter} from 'react-router-dom';
import {TopNavigation} from '@forecast-it/design-system';
import CreateButton, {CreateButtonQuery, DropDownButton as CreateDropDownButton} from '../header/CreateButton';
import ProfileButton from '../header/ProfileButton';
import HelpButton from '../header/HelpButton';
import GlobalSearchV2 from '../header/global-search/GlobalSearchButton';
import Util from '../../shared/util/util';
import Timer, {TimerLoader, TimerQuery} from '../header/Timer';
import {withSocketHandling} from '../../../socket/withSocketHandling';
import ForecastQueryRenderer from '../../../ForecastQueryRenderer';
import {getProjectIdFromPath} from '../../shared/util/UrlContextUtil';
import RecentButton, {DropDownButton as RecentDropdownButton, RecentButtonQuery} from '../header/RecentButton';
import KeyboardShortcuts from '../header/KeyboardShortcuts';
import {useIntl} from 'react-intl';
import {isClientUser} from '../../shared/util/PermissionsUtil';
import NotificationMenu from '../../../components/new-ui/notifications/notification_menu';
import {HIDDEN_FEATURES} from '../../../constants';

const PrimaryNavigationRight = props => {
	const {viewer} = props;
	const {actualPersonId} = props;
	const [timerHidden, setTimerHidden] = useState(
		localStorage.getItem('header-hide-timer') ? JSON.parse(localStorage.getItem('header-hide-timer')) : false
	);
	const currentPath = window.location.pathname + window.location.hash;
	const {formatMessage} = useIntl();

	return (
		<TopNavigation.Right>
			<KeyboardShortcuts key={'KeyboardShortcuts'} actualPersonId={actualPersonId} />

			{!timerHidden && !isClientUser() && !Util.isFeatureHidden(HIDDEN_FEATURES.TIME_REGISTRATIONS) ? (
				<ForecastQueryRenderer
					key="query-render-timer_component"
					query={TimerQuery}
					customLoader={() => TimerLoader}
					render={relayProps => <Timer {...relayProps} personId={actualPersonId} />}
				/>
			) : null}

			<ForecastQueryRenderer
				key="query-render-create_button"
				query={CreateButtonQuery}
				variables={{
					projectId: getProjectIdFromPath(currentPath),
					companyId: viewer.company.id,
				}}
				customLoader={() => CreateDropDownButton(formatMessage)}
				render={relayProps => <CreateButton {...relayProps} />}
			/>

			<TopNavigation.Controls>
				<GlobalSearchV2 companyId={viewer.company.id} personId={viewer.backendId} />

				<ForecastQueryRenderer
					key="query-render-top_navigation-favorites"
					query={RecentButtonQuery}
					variables={{}}
					redirectIfNotLoggedIn={true}
					customLoader={() => RecentDropdownButton}
					render={(relayProps, retry) => <RecentButton retry={retry} {...relayProps} />}
				/>
				<NotificationMenu viewer={viewer} />
				<HelpButton />
				<ProfileButton
					viewer={viewer}
					timerHidden={timerHidden}
					onToggleTimer={() => {
						Util.localStorageSetItem('header-hide-timer', !timerHidden);
						setTimerHidden(!timerHidden);
					}}
				/>
			</TopNavigation.Controls>
		</TopNavigation.Right>
	);
};

const PrimaryNavigationRightQuery = graphql`
	query PrimaryNavigationRight_Query {
		viewer {
			actualPersonId
			component(name: "top_navigation_right")
			...PrimaryNavigationRight_viewer
		}
	}
`;

export {PrimaryNavigationRightQuery};

export default withRouter(
	withSocketHandling(
		createFragmentContainer(PrimaryNavigationRight, {
			viewer: graphql`
				fragment PrimaryNavigationRight_viewer on Viewer {
					id
					sisenseUserId
					backendId
					unseenNotifications
					projectOwner
					company {
						id
						useTimeApproval
						useTimeOffApproval
						unit4Enabled
						isUsingProjectAllocation
						tier
					}
					...notificationMenu_viewer
				}
			`,
		})
	)
);
