import React, {useEffect} from 'react';
import {useRecentProjects} from '../hooks/useRecentProjects';

export const recentProjectsHOC = Component => {
	return props => {
		let recentProjects = useRecentProjects();
		if (props.viewer.project?.id) {
			useEffect(() => {
				recentProjects.projectVisited(props.viewer.project.id);
			}, [props.viewer.project?.id]);
		}
		return <Component {...props} />;
	};
};
