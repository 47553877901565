import {useEffect, useState} from 'react';
import {graphql} from 'react-relay';
import {fetchQuery} from 'relay-runtime';
import environment from '../../../../RelayEnvironment';

/**
 *
 *
 * * */
export const useFinancialServiceUpdateTriggerQuery = graphql`
	query useFinancialServiceUpdateTriggerQuery($projectId: ID!) {
		viewer {
			actualPersonId
			component(name: "financial_calculation_trigger")
			project(internalId: $projectId) {
				id
				financialCalculationTrigger(allowLongerRuntime: true) {
					timestamp
					duration
				}
			}
		}
	}
`;

export const triggerFinancialServiceCalculationAndWait = async (
	projectId,
	setFinancialCalculationTriggerData = () => {},
	onDataReady = () => {}
) => {
	let data;
	if (projectId && onDataReady) {
		try {
			onDataReady(false);
			data = await fetchQuery(environment.getInstance(), useFinancialServiceUpdateTriggerQuery, {
				projectId: projectId + '',
			});
			setFinancialCalculationTriggerData(data.viewer.project.financialCalculationTrigger);
		} finally {
			onDataReady(true);
		}
		return data ? data.viewer.project.financialCalculationTrigger : null;
	} else {
		onDataReady(true);
		return null;
	}
};

export const useFinancialServiceUpdateTrigger = (projectId, onDataReady) => {
	const [financialCalculationTriggerData, setFinancialCalculationTriggerData] = useState([]);

	useEffect(() => {
		if (projectId && onDataReady) {
			triggerFinancialServiceCalculationAndWait(projectId, setFinancialCalculationTriggerData, onDataReady);
		}
	}, [projectId]);

	return {
		financialCalculationTriggerData,
		triggerFinancialServiceCalculationAndWait,
	};
};
