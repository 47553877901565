import React from 'react';
import styled from 'styled-components';
import {CSS_CONSTANTS} from '../../../../../css_variables';
import {Button as NewButton} from '@forecast-it/design-system';
import {trackComplexEvent} from '../../../../../tracking/amplitude/TrackingV2';
import {startCase} from 'lodash';

const OnboardingItem = styled.div`
	display: flex;
	border: 0 solid ${CSS_CONSTANTS.v2_button_very_light_grey};
	border-top-width: ${props => (props.firstItem ? '1px' : '0px')};
	border-bottom-width: 1px;
	padding: 16px 0;
	justify-content: space-between;

	button {
		align-self: center;
		min-width: 69px;
		height: 30px;
	}
`;

const TextContent = styled.div`
	margin-right: 16px;
	display: flex;
	flex-direction: column;
	align-self: center;
`;

const ItemTitle = styled.div`
	//font-weight: 600;
	font-size: 13px;
	color: ${CSS_CONSTANTS.v2_text_gray};
`;

const ItemDescription = styled.div`
	margin-top: 10px;
`;

const onboardingItem = ({index, title, description, contentId, closePopup}) => {
	const toggleOnboardingExperience = contentId => {
		trackComplexEvent('Onboarding Flow', 'Triggered', {onboardingFlow: `- ${startCase(title)}`});
		window.userpilot.trigger(contentId);
		if (closePopup) {
			closePopup();
		}
	};

	return (
		<OnboardingItem firstItem={index === 0}>
			<TextContent>
				<ItemTitle>{title}</ItemTitle>
				{description ? <ItemDescription>{description}</ItemDescription> : null}
			</TextContent>
			<NewButton id={'onboarding-button'} emphasis={'medium'} onClick={() => toggleOnboardingExperience(contentId)}>
				Start
			</NewButton>
		</OnboardingItem>
	);
};

export default onboardingItem;
