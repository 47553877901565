import { MODULE_TYPES } from '../../../constants';
import { hasModule } from './ModuleUtil';
const CACHE = new Set();
const SESSION_STORAGE_KEY = 'availableFeatureFlags';
const isSessionStorageAvailable = () => typeof window !== 'undefined' && window.sessionStorage;
export const setAvailableFeatureFlags = (availableFeatureFlags) => {
    if (Array.isArray(availableFeatureFlags)) {
        if (isSessionStorageAvailable()) {
            sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(availableFeatureFlags));
        }
        CACHE.clear();
        availableFeatureFlags.forEach(featureFlag => {
            CACHE.add(featureFlag.key);
        });
    }
};
export const hasFeatureFlag = (featureFlag) => {
    if (CACHE.size === 0) {
        const sessionFeatureFlags = isSessionStorageAvailable() ? sessionStorage.getItem(SESSION_STORAGE_KEY) : null;
        const availableFeatureFlags = sessionFeatureFlags ? JSON.parse(sessionFeatureFlags) : [];
        setAvailableFeatureFlags(availableFeatureFlags);
    }
    return CACHE.has(featureFlag);
};
export const hasTimeLocking = () => {
    return hasModule(MODULE_TYPES.TIME_LOCKING);
};
export const hasTimeApproval = () => {
    return hasModule(MODULE_TYPES.TIME_LOCKING);
};
export const usesSso = (company) => {
    const { oktaEnabled, oneloginEnabled, AADEnabled } = company || {};
    return !!oktaEnabled || !!oneloginEnabled || !!AADEnabled;
};
export const usingTimeApproval = (companyUseTimeApproval) => {
    return companyUseTimeApproval && hasTimeApproval();
};
export const usingInternalTimeApproval = (companyUseInternalTimeApproval) => {
    return hasTimeApproval() && companyUseInternalTimeApproval;
};
export const usingTimeOffApproval = (companyUseTimeOffApproval) => {
    return companyUseTimeOffApproval && hasTimeApproval() && hasFeatureFlag('pto_timesheet_allocation_linking');
};
