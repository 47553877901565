import React from 'react';
import {
	DeprecatedNavigationItem as NavigationItem,
	DeprecatedNavigationMenu as NavigationMenu,
} from '@forecast-it/design-system';
import {useHistory} from 'react-router-dom';

const SubNavigationContainer = ({headerElement, tabs, baseLink, children}) => {
	const history = useHistory();

	const isActive = tab => tab.isActive(history.location.pathname);

	const handleNavigationItemClick = tab => {
		if (!tab.disabled) {
			history.replace(`${baseLink}/${tab.to}`);
		}
	};

	const subMenu = (
		<NavigationMenu>
			{tabs.map(tab => (
				<NavigationItem key={tab.name} onClick={() => handleNavigationItemClick(tab)} active={isActive(tab)}>
					{tab.name}
				</NavigationItem>
			))}
		</NavigationMenu>
	);

	return (
		<>
			{headerElement(subMenu)}
			{children}
		</>
	);
};

export default SubNavigationContainer;
