import { useEffect, useMemo, useRef, useState } from 'react';
import { addTimeRegsToMovedSuggestions, getEntityFromSuggestion, getHasTimeRegistrationFromSuggestion, getTimeRegsNotMatchedToSuggestionsInDayView, getTimeRegsWithoutMovedSuggestions, } from '../timesheet-table-util/TimeRegSuggestionUtil';
function getSuggestionEntititesWithAndWithoutTimeRegistrations(suggestionsAtLoad, timeRegistrationsAtLoad) {
    const suggestionEntities = new Map();
    const suggestionsWithoutTimeRegs = [];
    suggestionsAtLoad === null || suggestionsAtLoad === void 0 ? void 0 : suggestionsAtLoad.forEach(suggestion => {
        const suggestionEntity = getEntityFromSuggestion(suggestion);
        const suggestionHasTimeRegistered = getHasTimeRegistrationFromSuggestion(suggestionEntity, timeRegistrationsAtLoad);
        if (!suggestionHasTimeRegistered) {
            suggestionEntities.set(suggestionEntity, [[], [], [], [], [], [], []]);
            suggestionsWithoutTimeRegs.push(suggestionEntity.id);
        }
    });
    return { suggestionEntities, suggestionsWithoutTimeRegs };
}
export const useSuggestions = ({ suggestions, timeRegistrations, currentViewingDate, isWeekView, }) => {
    const suggestionsNotHavingTimeRegsAtLoad = useRef([]);
    const timeRegistrationsAtLoad = useRef(undefined);
    const suggestionsAtLoad = useRef([]);
    const timeRegCount = useRef(0);
    const [suggestionEntities, setSuggestionEntities] = useState(new Map());
    const isDayView = !isWeekView;
    function refreshSuggestionEntitiesWhenSuggestionsChange() {
        const { suggestionEntities, suggestionsWithoutTimeRegs } = getSuggestionEntititesWithAndWithoutTimeRegistrations(suggestionsAtLoad.current, timeRegistrationsAtLoad.current);
        suggestionsNotHavingTimeRegsAtLoad.current = suggestionsWithoutTimeRegs;
        setSuggestionEntities(suggestionEntities);
    }
    /* Clear refs when currentViewingDate or isWeekView changes */
    useEffect(() => {
        suggestionsNotHavingTimeRegsAtLoad.current = [];
        timeRegistrationsAtLoad.current = undefined;
        suggestionsAtLoad.current = [];
        timeRegCount.current = 0;
    }, [currentViewingDate, isWeekView]);
    /* Handle changes to suggestions */
    useEffect(() => {
        /* When suggestions are ready, refresh the suggestion entities */
        refreshSuggestionEntitiesWhenSuggestionsChange();
    }, [suggestionsAtLoad.current]);
    useEffect(() => {
        var _a;
        /* When suggestions are loaded for the first time in the session, persist them */
        if (((_a = suggestionsAtLoad.current) === null || _a === void 0 ? void 0 : _a.length) === 0 && suggestions) {
            suggestionsAtLoad.current = [...suggestions];
        }
    }, [suggestions]);
    /* Handle changes to time registrations */
    useEffect(() => {
        var _a;
        // maintain a list of time regs at load - these are the ones we need to show as timeRegs not suggestions
        if (!timeRegistrationsAtLoad.current) {
            timeRegistrationsAtLoad.current = timeRegistrations;
        }
        // make sure we update the timeRegsAtLoad when a time reg is deleted
        timeRegistrationsAtLoad.current = (_a = timeRegistrationsAtLoad === null || timeRegistrationsAtLoad === void 0 ? void 0 : timeRegistrationsAtLoad.current) === null || _a === void 0 ? void 0 : _a.filter(timeReg => timeRegistrations.includes(timeReg));
        /*
         * If a time reg has been deleted we need to refresh the suggestions
         * */
        if (timeRegCount.current > timeRegistrations.length) {
            timeRegCount.current = timeRegistrations.length;
            refreshSuggestionEntitiesWhenSuggestionsChange();
        }
        timeRegCount.current = timeRegistrations.length;
    }, [timeRegistrations]);
    const suggestionEntitiesWithTimeRegs = useMemo(() => {
        return addTimeRegsToMovedSuggestions(suggestionEntities, timeRegistrations, suggestionsNotHavingTimeRegsAtLoad.current, isDayView);
    }, [suggestionEntities, timeRegistrations, suggestionsNotHavingTimeRegsAtLoad]);
    /* based on the available suggestions and time registrations,
     * calculate the return values:
     * 	- time regs excluding any suggestions that "moved" to become time regs
     *  - suggestions with time regs added when applicable
     * */
    const timeRegsNotMatchedToSuggestions = useMemo(() => isDayView
        ? getTimeRegsNotMatchedToSuggestionsInDayView(suggestionEntities, timeRegistrations, suggestionsNotHavingTimeRegsAtLoad.current)
        : [], [isDayView, suggestionEntities, timeRegistrations, suggestionsNotHavingTimeRegsAtLoad]);
    const timeRegistrationsWithoutMovedSuggestions = useMemo(() => getTimeRegsWithoutMovedSuggestions(timeRegistrations, suggestionsNotHavingTimeRegsAtLoad.current, timeRegsNotMatchedToSuggestions), [timeRegistrations, suggestionsNotHavingTimeRegsAtLoad.current, timeRegsNotMatchedToSuggestions]);
    return {
        timeRegistrationsWithoutMovedSuggestions: timeRegistrationsWithoutMovedSuggestions,
        suggestionEntitiesWithTimeRegs: suggestionEntitiesWithTimeRegs,
    };
};
