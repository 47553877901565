import React from 'react';
import PropTypes from 'prop-types';
import {TopHeaderBar} from '../../../shared/components/headers/top-header-bar/TopHeaderBar';
import Styled from 'styled-components/macro';
import {Breadcrumbs, Link} from '@forecast-it/design-system';
import {createFragmentContainer, graphql} from 'react-relay';
import {HeaderBar} from '../../../../components/insights/utilization-report/HeaderBar';
import ProgramUtil from '../../../shared/util/ProgramUtil';
import {FormattedMessage} from 'react-intl';
import ProjectDropdown, {ProjectDropdownQuery} from './ProjectDropdown';
import ForecastQueryRenderer from '../../../../ForecastQueryRenderer';
import {hasPermission, hasSomePermission, isClientUser} from '../../../shared/util/PermissionsUtil';
import {hasModule} from '../../../shared/util/ModuleUtil';
import {PERMISSION_TYPE} from '../../../../Permissions';
import {MODULE_TYPES} from '../../../../constants';
import {ZenModeable} from '../../../shared/components/zen-mode/ZenModeable';
import ProjectIndicatorJS from '../../../shared/components/project-indicator/js/ProjectIndicatorJS';
import {WorkSignifier} from '@forecast-it/design-system';

const HeaderWrapper = Styled.div`
	padding-left: 32px;
	padding-bottom: 16px;
	.header-bar {
		padding: 0px 32px 0px 0px !important;
	}
	background: #FFFFFF;
`;

const BreadcrumbsWrapper = Styled.div`
  padding-top: 24px;
  padding-bottom: 12px;
 `;

const ProjectDropdownContainer = Styled.div`	
	display: flex;
	align-items: center;
	float: right;
`;

const MainContainer = Styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

const findPage = () => {
	const path = window.location.href;
	const pathSections = path.split('/');
	return pathSections[pathSections.length - 1];
};

const getConnectedProjectPage = () => {
	const page = findPage();

	if (page.includes('sprints')) {
		return 'sprints';
	}

	if (page.includes('settings')) {
		if (page.includes('#tasks')) {
			return 'group-settings#tasks';
		}

		if (page.includes('#team')) {
			return 'group-settings#team';
		}

		return 'group-settings#general';
	}

	return 'task-board';
};

const getProgramPage = () => {
	const page = findPage();

	if (page.includes('financial')) {
		return 'financials';
	}

	if (page.includes('sprints')) {
		return 'sprints';
	}

	if (page.includes('settings')) {
		if (page.includes('#team')) {
			return 'settings/team';
		}

		return 'settings/general';
	}

	return 'overview';
};

const ProjectGroupIndicator = Styled(WorkSignifier)`	
	text-decoration: none!important;
`;

function ProgramLink(props) {
	return (
		<Link
			href={`${ProgramUtil.programLink()}/${props.psProgram.prefix}/${getProgramPage()}`}
			data-cy="breadcrumbs-parent-link"
		>
			{props.children}
		</Link>
	);
}

ProgramLink.propTypes = {psProgram: PropTypes.any};

function ConnectedProjectLink(props) {
	return (
		<Link
			href={`/connected/X-${props.projectGroupCompanyId}/${getConnectedProjectPage()}`}
			data-cy="breadcrumbs-parent-link"
		>
			{props.children}
		</Link>
	);
}

ConnectedProjectLink.propTypes = {
	projectGroupCompanyId: PropTypes.any,
	projectGroupName: PropTypes.any,
};
const ProjectHeader = ({title, titleContent, buttons, project, projectGroup, psProject}) => {
	const connectedProjectCompanyId = project?.projectGroupCompanyId?.toString();
	const programId = psProject?.psProgram?.id;

	const isProgramMember = psProject?.psProgram?.isViewerProgramMember;

	const hasAccessToPrograms =
		hasModule(MODULE_TYPES.PROGRAMS) &&
		(hasPermission(PERMISSION_TYPE.PROJECTS_READ_ALL) || isProgramMember) &&
		hasSomePermission([PERMISSION_TYPE.PROGRAMS_CREATE, PERMISSION_TYPE.PROGRAMS_UPDATE, PERMISSION_TYPE.PROGRAMS_DELETE]);

	return (
		<HeaderWrapper>
			{!isClientUser() && (
				<ZenModeable>
					<MainContainer>
						<BreadcrumbsWrapper>
							<Breadcrumbs>
								{(project || projectGroup) && !psProject?.psProgram && (
									<Breadcrumbs.Item>
										<Link href={`/projects`} data-cy="breadcrumbs-projects-link">
											<FormattedMessage id="common.all_projects" />
										</Link>
									</Breadcrumbs.Item>
								)}

								{project && project.isInProjectGroup && (
									<Breadcrumbs.Item>
										<div>
											<ConnectedProjectLink projectGroupCompanyId={project.projectGroupCompanyId}>
												{project.projectGroupName}
											</ConnectedProjectLink>

											<ConnectedProjectLink projectGroupCompanyId={project.projectGroupCompanyId}>
												<ProjectGroupIndicator
													workId={'X' + project.projectGroupCompanyId}
													name={project.projectGroupName}
													color={project.projectGroupColor}
													flexWidth={true}
												/>
											</ConnectedProjectLink>
										</div>
									</Breadcrumbs.Item>
								)}

								{psProject && psProject.psProgram && (
									<Breadcrumbs.Item isDisabled={!hasAccessToPrograms}>
										{hasAccessToPrograms ? (
											<Link href={`${ProgramUtil.programLink(true)}`} data-cy="breadcrumbs-programs-link">
												<FormattedMessage
													id={
														hasModule(MODULE_TYPES.PROGRAMS_PLUS)
															? 'common.programs'
															: 'common.project-groups'
													}
												/>
											</Link>
										) : (
											<FormattedMessage
												id={
													hasModule(MODULE_TYPES.PROGRAMS_PLUS)
														? 'common.programs'
														: 'common.project-groups'
												}
											/>
										)}
									</Breadcrumbs.Item>
								)}

								{psProject && psProject.psProgram && (
									<Breadcrumbs.Item isDisabled={!hasAccessToPrograms}>
										{hasAccessToPrograms ? (
											<div>
												<ProgramLink psProgram={psProject.psProgram}>
													{psProject.psProgram.name}
												</ProgramLink>
												<ProgramLink psProgram={psProject.psProgram}>
													<ProjectGroupIndicator
														workId={psProject.psProgram.prefix}
														name={psProject.psProgram.name}
														color={psProject.psProgram.color}
														flexWidth={true}
													/>
												</ProgramLink>
											</div>
										) : (
											<div>
												{psProject.psProgram.name}
												<ProjectGroupIndicator
													workId={psProject.psProgram.prefix}
													name={psProject.psProgram.name}
													color={psProject.psProgram.color}
													flexWidth={true}
												/>
											</div>
										)}
									</Breadcrumbs.Item>
								)}

								{project && (
									<Breadcrumbs.Item>
										<div>
											{project.name} <ProjectIndicatorJS project={project} />
										</div>
									</Breadcrumbs.Item>
								)}

								{projectGroup && (
									<Breadcrumbs.Item>
										<div>
											{projectGroup.name}{' '}
											<ProjectGroupIndicator
												workId={'X' + projectGroup.companyProjectGroupId}
												name={projectGroup.name}
												color={projectGroup.color}
												flexWidth={true}
											/>
										</div>
									</Breadcrumbs.Item>
								)}
							</Breadcrumbs>
						</BreadcrumbsWrapper>
						{(connectedProjectCompanyId || programId) && (
							<ProjectDropdownContainer>
								<ForecastQueryRenderer
									key="query-render-breadcrumb-project-dropdown"
									query={ProjectDropdownQuery}
									variables={{
										connectedProjectId: connectedProjectCompanyId,
										programId: programId,
									}}
									render={(relayProps, retry) => (
										<ProjectDropdown projectId={project?.id} {...relayProps} retry={retry} />
									)}
								/>
							</ProjectDropdownContainer>
						)}
					</MainContainer>
				</ZenModeable>
			)}

			<div style={{display: 'flex', alignItems: 'center', height: '44px'}}>
				{/* <SecondaryNavigationController /> */}
				<TopHeaderBar title={title} content={titleContent} />
				<div style={{flexGrow: 1}}>{buttons}</div>
			</div>
		</HeaderWrapper>
	);
};

ProjectHeader.propTypes = {
	title: PropTypes.string.isRequired,
	titleContent: PropTypes.array,
	buttons: PropTypes.instanceOf(HeaderBar),
	project: PropTypes.object,
	projectGroup: PropTypes.object,
};

export default createFragmentContainer(ProjectHeader, {
	psProject: graphql`
		fragment ProjectHeader_psProject on ProjectServiceProjectType {
			name
			psProgram {
				id
				name
				color
				prefix
				isViewerProgramMember
			}
		}
	`,
	project: graphql`
		fragment ProjectHeader_project on ProjectType {
			id
			companyProjectId
			...ProjectIndicatorJS_project
			name
			projectColor
			isInProjectGroup
			projectGroupCompanyId
			projectGroupName
			projectGroupColor
			useBaseline
			budgetType
			sprintTimeBox
		}
	`,
	projectGroup: graphql`
		fragment ProjectHeader_projectGroup on ProjectGroupType {
			companyProjectGroupId
			name
			color
			projectHeaderProjects: projects(first: 1) @connection(key: "ProjectGroup_projectHeaderProjects", filters: []) {
				edges {
					node {
						id
						sprintTimeBox
					}
				}
			}
		}
	`,
});
