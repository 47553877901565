import React, {useEffect} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl, useIntl} from 'react-intl';
import {Button, Table} from 'web-components';
import CustomScrollDiv from '../../shared/components/scroll-bars/custom_scroll_div';
import styled from 'styled-components';
import {MODAL_TYPE, showModal} from '../../shared/components/modals/generic_modal_conductor';
import Util from '../../shared/util/util';
import * as tracking from '../../../tracking';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../shared/components/headers/top-header-bar/TopHeaderBar';
import {NewProfile} from './SettingsProfilesNewProfile';
import ActionsMenu from '../../shared/components/action-menu/actions_menu';
import {createToast} from '../../shared/components/toasts/toast';
import DeleteProfileMutation from '../../../mutations/DeleteProfileMutation';
import UpdateProfileMutation from '../../../mutations/UpdateProfileMutation';
import {PERMISSION_GROUPS} from './PermissionGroups';
import {useTrackPage} from '../../../tracking/amplitude/hooks/useTrackPage';

const SettingsPermissionsStyles = styled.div`
	display: flex;
	flex: auto;
	flex-direction: column;
	align-items: center;
`;

const TableWrapper = styled.div`
	width: 566px;
	margin-left: 24px;
	padding-bottom: 24px;
`;

const TableText = styled.div`
	color: ${props => props.disabled && '#a1a1a1'};
`;

const settingsPermissions = props => {
	const intl = useIntl();

	useTrackPage('Permissions');

	useEffect(() => {
		document.title = 'Permissions - Account - Forecast';
		// Segment
		tracking.trackPage('permissions');
	}, []);

	const handleEditClick = profile => {
		showModal({
			type: MODAL_TYPE.PROFILE,
			profile: profile,
			permissions: PERMISSION_GROUPS(),
			profileNameCanBeEdited: profile.canBeEdited,
			profileCanBeEdited: profile.canBeEdited,
			headerText: 'Edit Profile',
		});
	};

	const getHeaderTitle = () => {
		const content = [];

		return (
			<TopHeaderBarWrapper sidePadding={24}>
				<TopHeaderBar title={intl.formatMessage({id: 'settings.permission_profiles'})} content={content} />
			</TopHeaderBarWrapper>
		);
	};

	const changeProfileDisabled = profile => {
		const onSuccess = () => {
			createToast({
				duration: 5000,
				message: profile.disabled
					? intl.formatMessage({id: 'settings.profile_enabled'})
					: intl.formatMessage({id: 'settings.profile_disabled'}),
			});
		};

		Util.CommitMutation(
			UpdateProfileMutation,
			{
				id: profile.id,
				disabled: !profile.disabled,
			},
			onSuccess
		);
	};

	const deleteProfile = profile => {
		const onSuccess = () => {
			createToast({
				duration: 5000,
				message: intl.formatMessage({id: 'settings.profile_deleted'}),
			});
		};

		Util.CommitMutation(
			DeleteProfileMutation,
			{
				id: profile.id,
				companyId: props.viewer.company.id,
			},
			onSuccess
		);
	};

	const profiles = props.viewer.company.profiles.edges
		.map(p => p.node)
		.filter(p => p.canBeEdited || p.name === 'Admin' || p.name === 'Contributor')
		.sort((a, b) => Util.sortAlphabetically(a.name, b.name));

	return (
		<SettingsPermissionsStyles>
			{getHeaderTitle()}
			<CustomScrollDiv cy={'permission-profiles-page'}>
				<TableWrapper>
					<Table>
						<Table.Header>
							<Table.HeaderColumn flex={1} usePadding visible width="MEDIUM">
								<Table.HeaderColumn.Title>Name</Table.HeaderColumn.Title>
							</Table.HeaderColumn>
							<Table.HeaderColumn usePadding visible width="MEDIUM">
								<Table.HeaderColumn.Title>Users with profile</Table.HeaderColumn.Title>
							</Table.HeaderColumn>
							<Table.HeaderColumn usePadding visible width="SMALL" />
							<Table.HeaderColumn usePadding visible width="SMALL" />
						</Table.Header>
						<Table.Rows
							data={{
								rows: profiles,
							}}
						>
							{({rowData, tableColumnsProps}) => (
								<Table.Row
									hoverStyle={{
										color: 'blue',
									}}
									{...tableColumnsProps}
								>
									<Table.Column>
										<TableText disabled={rowData.disabled}>{rowData.name}</TableText>
									</Table.Column>
									<Table.Column>
										<TableText disabled={rowData.disabled}>{rowData.personsCount}</TableText>
									</Table.Column>
									<Table.Column>
										<Button
											size={Button.SIZE.STANDARD}
											variant={Button.VARIANT.PURPLE_FILLED}
											onClick={() => handleEditClick(rowData)}
										>
											{rowData.canBeEdited ? 'EDIT' : 'VIEW'}
										</Button>
									</Table.Column>
									<Table.Column>
										<ActionsMenu
											whiteInner={true}
											options={[
												{
													text: intl.formatMessage({
														id: rowData.disabled ? 'common.enable' : 'common.disable',
													}),
													onClick: () => changeProfileDisabled(rowData),
													locked: !rowData.canBeEdited,
													disabledTitle: "You can't disable static profiles",
												},
												{
													text: intl.formatMessage({id: 'common.delete'}),
													onClick: () => deleteProfile(rowData),
													locked: !rowData.canBeEdited || rowData.personsCount > 0,
													disabledTitle: "You can't delete profiles with persons assigned",
												},
											]}
										/>
									</Table.Column>
								</Table.Row>
							)}
						</Table.Rows>
					</Table>
					<NewProfile companyId={props.viewer.company.id}></NewProfile>
				</TableWrapper>
			</CustomScrollDiv>
		</SettingsPermissionsStyles>
	);
};

export const settingsPermissionsQuery = graphql`
	query settingsPermissions_Query {
		viewer {
			actualPersonId
			component(name: "settings_permissions")
			...settingsPermissions_viewer
		}
	}
`;

export default injectIntl(
	createFragmentContainer(settingsPermissions, {
		viewer: graphql`
			fragment settingsPermissions_viewer on Viewer {
				id
				company {
					id
					profiles(first: 10000) @connection(key: "Company_profiles", filters: []) {
						edges {
							node {
								id
								name
								canBeEdited
								personsCount
								permissions
								disabled
							}
						}
					}
				}
			}
		`,
	})
);
