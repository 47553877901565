import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import * as amplitude from '@amplitude/analytics-browser';
import Util from '../util/util';
import {TIERS} from '../../../constants';
import {versionManager} from '../../../version_manager';
import moment from 'moment';
import socket_handling from '../../../socket/socket_handling';
import {getBasicUserTypeFromPermissions, hasPermission} from '../util/PermissionsUtil';
import * as Sentry from '@sentry/browser';
import {PERMISSION_TYPE} from '../../../Permissions';
import loadScript from 'load-script';
import LogRocket from 'logrocket';
import {MODAL_TYPE, showModal} from './modals/generic_modal_conductor';
import {withRouter} from 'react-router-dom';

class TrackingComponent extends Component {
	constructor(props) {
		super(props);

		this.handleResize = this.handleResize.bind(this);
		this.resizeTimeout = null;

		if (
			props.viewer.email &&
			(props.viewer.email.endsWith('forecastit.dk') ||
				props.viewer.email.endsWith('forecast.it') ||
				props.viewer.email.endsWith('forecast.app') ||
				props.viewer.socketFullUpdate)
		) {
			Util.localStorageSetItem('forecast_user', true);
		} else {
			localStorage.removeItem('forecast_user');
		}

		this.unlisten = this.props.history.listen((location, action) => {
			if (window.userpilot) {
				const URLcompanyProjectPath = location.pathname.match(/[PX]-\d+/);
				const userId =
					process.env.CIRCLE_BRANCH !== 'production' ? `TEST_${props.viewer.backendId}` : props.viewer.backendId;
				window.userpilot.identify(userId, {
					currentProjectId: URLcompanyProjectPath ? URLcompanyProjectPath[0] : null,
				});
				window.userpilot.reload();
			}
		});
	}

	componentDidMount() {
		const webFrontVersion = versionManager.getClientMajorVersion() + '.' + versionManager.getClientMinorVersion();
		const webFrontVersionTime = moment(versionManager.getClientVersionTime());
		// eslint-disable-next-line no-console
		console.log({webFrontVersion, webFrontVersionTime: webFrontVersionTime.format('DD/MM/YY HH:mm')}); //needed to show this in log rocket

		if (this.props.viewer && this.props.viewer.actualPersonId !== null) {
			window.addEventListener('resize', this.handleResize);

			// Check the trial end
			const securityCheck = () => {
				if (
					this.props.viewer &&
					this.props.viewer.company &&
					this.props.viewer.company.tier === TIERS.TRIAL &&
					this.props.history.location.pathname !== '/trial-end'
				) {
					const trialEndTime = moment.utc(this.props.viewer.company.trialEndTime);
					const now = moment.utc();
					if (trialEndTime.isBefore(now)) {
						this.props.history.push('/trial-end');
					}
				} else if (
					this.props.viewer &&
					this.props.viewer.company &&
					this.props.viewer.company.accessBlocked &&
					!['/trial-end', '/access-blocked', '/settings/subscription'].includes(this.props.history.location.pathname)
				) {
					this.props.history.push('/access-blocked');
				}

				// Update version automatically every hour
				this.updateWebFrontVersion();
			};
			securityCheck();
			this.intervalID = setInterval(securityCheck, 60 * 60 * 1000); // every hour

			if (
				this.props.viewer.actualPersonId !== null &&
				(Util.getCsrfValue() === null || Util.getCsrfValue() !== this.props.viewer.csrfToken)
			) {
				Util.setCsrfValue(this.props.viewer.csrfToken);
			}

			if (this.props.viewer.company.socketEnabled) {
				socket_handling.connect(this.props.viewer.socketFullUpdate, this.props.viewer.backendId);
			}

			const basicUserType = getBasicUserTypeFromPermissions();

			Sentry.configureScope(scope => {
				scope.setTag({company_id: atob(this.props.viewer.company.id).replace('Company:', '')});
				scope.setUser({
					email: this.props.viewer.email,
					id: atob(this.props.viewer.actualPersonId).replace('Person:', ''),
					company_id: atob(this.props.viewer.company.id).replace('Company:', ''),
					name: this.props.viewer.firstName + ' ' + this.props.viewer.lastName,
					userType: basicUserType,
					company_name: this.props.viewer.company.name,
				});
			});

			try {
				const forecast_user = localStorage.getItem('forecast_user');
				const viewer = this.props.viewer;
				amplitude.setUserId(viewer.backendId);

				const today = moment();
				const accountCreationDate = moment(this.props.viewer.company.createdAt, 'x');
				const monthsSinceCreation = today.diff(accountCreationDate, 'months');

				const companyAllocationType = viewer.company.isUsingMixedAllocation
					? 'Combined Mode'
					: viewer.company.isUsingProjectAllocation
					? 'Project Allocation'
					: viewer.company.isUsingSchedulingPlanMode
					? 'Task Estimates'
					: 'Time Registrations & Remaining Task Estimates';

				const identify = new amplitude.Identify()
					.set('app_version', `${versionManager.getClientMajorVersion()}.${versionManager.getClientMinorVersion()}`) // deprecated
					.set('appVersion', `${versionManager.getClientMajorVersion()}.${versionManager.getClientMinorVersion()}`)
					.set('email', viewer.email)
					.set('first_name', viewer.firstName) // deprecated
					.set('firstName', viewer.firstName)
					.set('last_name', viewer.lastName) // deprecated
					.set('lastName', viewer.lastName)
					.set('created', viewer.createdAt)
					.set('tier', viewer.company.tier)
					.set('role', viewer.role && viewer.role.name ? viewer.role.name : 'No role')
					.set('userSeats', viewer.company.userSeats)
					.set('virtualSeats', viewer.company.virtualSeats)
					.set('accountAge', monthsSinceCreation)
					.set('windowDimensions', `${window.innerWidth} x ${window.innerHeight}`)
					.set('windowHeight', window.innerHeight)
					.set('windowWidth', window.innerWidth)
					.set('first_person', viewer.isFirstPersonInCompany) // deprecated
					.set('firstPerson', viewer.isFirstPersonInCompany)
					.set('user_type', basicUserType) // deprecated
					.set('userType', basicUserType)
					.set('company_id', viewer.company.backendId) // deprecated
					.set('companyId', viewer.company.backendId)
					.set('company_name', viewer.company.name) // deprecated
					.set('companyName', viewer.company.name)
					.set('companyBusinessType', viewer.company.businessType)
					.set('company_allocation_type', companyAllocationType) // deprecated
					.set('companyAllocationType', companyAllocationType)
					.set('integration_enabled', Util.getIntegrationsEnabled(viewer.company)) // deprecated
					.set('integrationEnabled', Util.getIntegrationsEnabled(viewer.company))
					.set('forecast_user', forecast_user === 'true') // deprecated
					.set('forecastUser', forecast_user === 'true');
				amplitude.identify(identify);

				const groupType = 'companyId';
				const groupId = `${viewer.company.backendId}`;

				amplitude.setGroup(groupType, groupId);

				const groupIdentify = new amplitude.Identify();
				groupIdentify.set('companyName', viewer.company.name);
				groupIdentify.set('tier', viewer.company.tier);
				groupIdentify.set('companyBusinessType', viewer.company.businessType);
				groupIdentify.set('accountAgeInMonths', monthsSinceCreation);
				amplitude.groupIdentify(groupType, groupId, groupIdentify);

				if (process.env.CIRCLE_BRANCH !== 'production') {
					// eslint-disable-next-line no-console
					console.log('TRACKING IDENTIFY: ', identify, groupIdentify);
				}

				if (
					hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS) &&
					this.props.viewer.company.tier !== TIERS.TRIAL &&
					process.env.CIRCLE_BRANCH === 'production'
				) {
					const opts = {};
					opts.attrs = {id: 'ze-snippet'};
					loadScript(
						'https://static.zdassets.com/ekr/snippet.js?key=8ae6b90a-bd93-452b-bbfe-d522a93378e5',
						opts,
						() => {
							window.zE('messenger', 'loginUser', function (callback) {
								callback(this.props.viewer.zendeskToken);
							});
						}
					);
				}

				if (process.env.CIRCLE_BRANCH === 'production' && forecast_user !== 'true') {
					LogRocket.init('forecast/forecast-nod93');
					Sentry.configureScope(scope => {
						scope.addEventProcessor(async event => {
							event.extra = {sessionURL: LogRocket.sessionURL};
							return event;
						});
					});

					LogRocket.identify(atob(this.props.viewer.actualPersonId).replace('Person:', ''), {
						name: this.props.viewer.firstName + ' ' + this.props.viewer.lastName,
						email: this.props.viewer.email,

						// Add your own custom user variables here, ie:
						tier: this.props.viewer.company.tier,
						userType: basicUserType,
						company_name: this.props.viewer.company.name,
						company_id: atob(this.props.viewer.company.id).replace('Company:', ''),
					});
				}

				if (window.userpilot && !this.props.viewer?.email?.endsWith('forecastit.dk')) {
					const viewer = this.props.viewer;
					const userId = process.env.CIRCLE_BRANCH !== 'production' ? `TEST_${viewer.backendId}` : viewer.backendId;
					//const URLcompanyProjectPath = window.location.pathname.match(/[PX]-\d+/);
					//const projectInfo = this.getProjectFromUrl() || this.getProjectGroupFromUrl();
					//const isStoryPointProject =
					//	projectInfo &&
					//	(projectInfo.estimationUnit === ESTIMATION_UNIT.POINTS ||
					//		projectInfo.projects?.edges[0]?.node.estimationUnit === ESTIMATION_UNIT.POINTS);
					window.userpilot.identify(
						userId, // Used to identify users
						{
							created_at: new Date(viewer.createdAt).getTime() / 1000, // Signup date as a Unix timestamp
							tier: viewer.company.tier,
							seats: viewer.company.userSeats,
							firstPerson: viewer.isFirstPersonInCompany,
							userType: basicUserType,
							companyCreatedAt: viewer.company.createdAt / 1000,
							companyName: viewer.company.name,
							companyId: viewer.company.backendId,
							isBetaOptIn: viewer.company.betaOptIn,
							hasNewNavigation: true,
							hasUtilizationReport: true,
							companyAllocationType,
							//currentProjectId: URLcompanyProjectPath ? URLcompanyProjectPath[0] : null,
							//isStoryPointProject: isStoryPointProject,
						}
					);
				}
			} catch (e) {
				console.error(e);
			}

			const language = this.props.viewer.language;
			this.props.setLocale(language);

			if (
				!this.props.viewer?.email?.endsWith('forecastit.dk') &&
				this.props.viewer.onboardingInitialStep !== 0 &&
				!moment().isSame(moment(this.props.viewer.setToRunningNotificationLastDismissalDate), 'day') &&
				hasPermission(PERMISSION_TYPE.PROJECTS_UPDATE)
			) {
				showModal({
					type: MODAL_TYPE.SET_TO_RUNNING_REMINDER,
				});
			}
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);

		this.unlisten();

		clearInterval(this.intervalID);
		socket_handling.disconnect();
	}

	updateWebFrontVersion() {
		if (this.lastVersionCheck === undefined || this.lastVersionCheck.isBefore(moment().subtract(10, 'minutes'))) {
			this.lastVersionCheck = moment();
			// SPECIFICALLY USING THE EU ENDPOINT BECAUSE THAT IS THE MASTER FOR THE WEB FRONT VERSION
			fetch(process.env.GRAPHQL_ENDPOINT.slice(0, -7) + 'web_front_version')
				.then(response => {
					response.json().then(webFrontVersion => {
						versionManager.updateWithWebFrontVersionResponse(webFrontVersion);
					});
				})
				.catch(error => {
					console.log(error);
				});
		}
	}

	handleResize() {
		clearTimeout(this.resizeTimeout);
		this.resizeTimeout = setTimeout(this.trackResize, 350);
	}

	trackResize() {
		trackEvent('Window', 'Resized', {height: window.innerHeight, width: window.innerWidth});
		try {
			const identify = new amplitude.Identify()
				.set('windowDimensions', `${window.innerWidth} x ${window.innerHeight}`)
				.set('windowHeight', window.innerHeight)
				.set('windowWidth', window.innerWidth);
			amplitude.identify(identify);
		} catch (e) {
			console.error('windowDimensions identify failed');
		}
	}

	render() {
		return <></>;
	}
}

const TrackingComponentQuery = graphql`
	query TrackingComponent_Query {
		viewer {
			actualPersonId
			component(name: "tracking_component")
			...TrackingComponent_viewer
		}
	}
`;

export {TrackingComponentQuery};

export default withRouter(
	createFragmentContainer(TrackingComponent, {
		viewer: graphql`
			fragment TrackingComponent_viewer on Viewer {
				id
				firstName
				lastName
				backendId
				actualPersonId
				email
				csrfToken
				socketFullUpdate
				createdAt
				isFirstPersonInCompany
				language
				setToRunningNotificationLastDismissalDate
				role {
					name
				}
				company {
					id
					backendId
					createdAt
					name
					socketEnabled
					tier
					trialEndTime
					accessBlocked
					isUsingSchedulingPlanMode
					isUsingProjectAllocation
					isUsingMixedAllocation
					userSeats
					virtualSeats
					businessType
					betaOptIn

					slackEnabled
					teamsEnabled
					vstsEnabled
					githubEnabled
					gitlabEnabled
					salesforceEnabled
					unit4Enabled
					jiraCloudEnabled
					xeroEnabled
					newXeroEnabled
					quickbooksEnabled
					economicEnabled
					oktaEnabled
					oneloginEnabled
					AADEnabled
					gdriveEnabled
					harvestEnabled
				}
			}
		`,
	})
);
