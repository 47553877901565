// @ts-check

import {
	Icon,
	DeprecatedListCell as ListCell,
	DeprecatedListRow as ListRow,
	DeprecatedText as Text,
} from '@forecast-it/design-system';
import React, {useRef} from 'react';
import styled from 'styled-components';
import {WarningIcon} from 'web-components/icons';
import {getWarningVariantFromRAGStatus} from '../../../../../containers/project/project_health/ProjectHealthLogic';
import ForecastTooltip, {TooltipColor} from '../../../../shared/components/tooltips/ForecastTooltip';

const ChevronWrapper = styled.div`
	opacity: 0;
	display: flex;
	align-items: center;
`;

/**
 * @typedef {{disabled: boolean;}} InsightProps
 */
const Insight =
	/** @type {import('styled-components').ThemedStyledFunction<'div', InsightProps>} */
	(styled.button)`
		border: none;
		display: inline-flex;
		max-width: 100%;
		overflow: hidden;
		border-radius: 4px;
		padding: 8px;
		align-items: center;
		cursor: ${props => !props.disabled && 'pointer'};
		&:hover,
		&:focus {
			background-color: ${props => !props.disabled && '#f7f7fe'};
			${ChevronWrapper} {
				opacity: 1;
			}
		}
	`;

const InsightText = styled.div`
	margin: 0 4px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const InsightRow = ({name, ragStatus, insightMessage, lockedInsightText, cy, onSelect}) => {
	const insightRef = useRef();
	const rowDisabled = !ragStatus;

	const handleInsightClick = keyboardEvent => {
		if (!rowDisabled && onSelect) {
			onSelect(keyboardEvent, insightRef);
		}
	};

	return (
		<ListRow gap={'8px'}>
			<ListCell fixed={'64px'}>
				<Text color={'secondary'}>{name}</Text>
			</ListCell>
			<ListCell>
				<Insight
					ref={insightRef}
					title={rowDisabled ? null : insightMessage}
					disabled={rowDisabled}
					tabIndex={rowDisabled ? -1 : 0}
					onClick={handleInsightClick}
					data-cy={cy}
				>
					{rowDisabled ? (
						<ForecastTooltip content={lockedInsightText} color={TooltipColor.PURPLE}>
							<Icon size={'l'} icon={'lockClosed'}></Icon>
						</ForecastTooltip>
					) : (
						<>
							<WarningIcon variant={getWarningVariantFromRAGStatus(ragStatus)} />
							<InsightText>{insightMessage}</InsightText>
							<ChevronWrapper>
								<Icon icon={'chevronRight'} size={'m'}></Icon>
							</ChevronWrapper>
						</>
					)}
				</Insight>
			</ListCell>
		</ListRow>
	);
};

export default InsightRow;
