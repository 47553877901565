import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import { Icon, Text } from '@forecast-it/design-system';
import Util from '../../../shared/util/util';
import { getBillableMinutesRegistered, getInvalidTimeRegistrations, getMinutesRegistered, getTimeRegistrationsByReason, ValidationReason, } from './TimesheetsCopyLastWeekModalLogic';
const ValidationContainer = styled.div `
	display: flex;
	gap: 16px;
	align-items: center;
	padding: 8px 16px 8px 12px;
	border-radius: 4px;
	background-color: #e5f4ff;
	border-left: 12px solid #0004db;
`;
const InformationContainer = styled.div `
	display: flex;
	flex-direction: column;
	width: 100%;
`;
const ValidationList = styled.ul `
	margin: 0;
	padding: 0 0 0 24px;
`;
const ValidationItem = styled.li ``;
function getTimeEntriesString(timeRegistrations, intl) {
    const minutesRegistered = getMinutesRegistered(timeRegistrations);
    const billableMinutesRegistered = getBillableMinutesRegistered(timeRegistrations);
    return (`(${Util.convertMinutesToFullHour(minutesRegistered, intl, true)}` +
        (billableMinutesRegistered > 0
            ? `, ${Util.convertMinutesToFullHour(billableMinutesRegistered, intl, true)} billable)`
            : ')'));
}
const CopyLastWeekValidationSection = ({ viewer, copyDoneTasks, timeRegsCount, }) => {
    var _a, _b, _c, _d;
    const intl = useIntl();
    const timeRegistrationValidations = copyDoneTasks
        ? (_b = (_a = viewer.company) === null || _a === void 0 ? void 0 : _a.person) === null || _b === void 0 ? void 0 : _b.doneTasksIncluded
        : (_d = (_c = viewer.company) === null || _c === void 0 ? void 0 : _c.person) === null || _d === void 0 ? void 0 : _d.doneTasksExcluded;
    if (!timeRegistrationValidations)
        return null;
    useEffect(() => {
        timeRegsCount.current = (timeRegistrationValidations === null || timeRegistrationValidations === void 0 ? void 0 : timeRegistrationValidations.length) || 0;
    }, []);
    const invalidTimeRegistrations = getInvalidTimeRegistrations(timeRegistrationValidations);
    return invalidTimeRegistrations.length > 0 ? (React.createElement(ValidationContainer, { "data-cy": "copy-last-week-validation-section-container" },
        React.createElement(Icon, { icon: 'info', size: "l" }),
        React.createElement(InformationContainer, null,
            React.createElement(Text, null, `${invalidTimeRegistrations.length} time registrations ${getTimeEntriesString(invalidTimeRegistrations, intl)} will not be copied due to company settings`),
            React.createElement(ValidationList, null, Object.entries(ValidationReason).map(([reason, descriptions]) => {
                const numberOfTimeRegs = getTimeRegistrationsByReason(timeRegistrationValidations, reason).length;
                if (numberOfTimeRegs <= 0)
                    return null;
                const description = numberOfTimeRegs > 1 ? descriptions.plural : descriptions.singular;
                return (React.createElement(ValidationItem, { key: reason },
                    React.createElement(Text, null, `${numberOfTimeRegs} ${numberOfTimeRegs > 1 ? 'time registrations' : 'time registration'} ${getTimeEntriesString(getTimeRegistrationsByReason(timeRegistrationValidations, reason), intl)} ${numberOfTimeRegs > 1 ? 'are' : 'is'} ${description}`)));
            }))))) : null;
};
export const CopyLastWeekValidationSectionQuery = graphql `
	query CopyLastWeekValidationSection_Query(
		$personId: ID!
		$startYear: Int!
		$startMonth: Int!
		$startDay: Int!
		$endYear: Int!
		$endMonth: Int!
		$endDay: Int!
	) {
		viewer {
			actualPersonId
			component(name: "copy_last_week_validation_section")
			...CopyLastWeekValidationSection_viewer
				@arguments(
					personId: $personId
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
				)
		}
	}
`;
export default createFragmentContainer(CopyLastWeekValidationSection, {
    viewer: graphql `
		fragment CopyLastWeekValidationSection_viewer on Viewer
		@argumentDefinitions(
			personId: {type: "ID!"}
			startYear: {type: "Int!"}
			startMonth: {type: "Int!"}
			startDay: {type: "Int!"}
			endYear: {type: "Int!"}
			endMonth: {type: "Int!"}
			endDay: {type: "Int!"}
		) {
			company {
				person(id: $personId) {
					doneTasksExcluded: validatedTimeRegsInDateRange(
						startYear: $startYear
						startMonth: $startMonth
						startDay: $startDay
						endYear: $endYear
						endMonth: $endMonth
						endDay: $endDay
						excludeDoneTasks: true
					) {
						timeRegRequest {
							minutesRegistered
							billableMinutesRegistered
						}
						valid
						validationReasons
					}
					doneTasksIncluded: validatedTimeRegsInDateRange(
						startYear: $startYear
						startMonth: $startMonth
						startDay: $startDay
						endYear: $endYear
						endMonth: $endMonth
						endDay: $endDay
						excludeDoneTasks: false
					) {
						timeRegRequest {
							minutesRegistered
							billableMinutesRegistered
						}
						valid
						validationReasons
					}
				}
			}
		}
	`,
});
