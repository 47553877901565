import { projectUrl } from '../../../directApi';
import { getIndexOfTaskLink } from './UrlUtil';
const cleanTaskModalLinkFromPathName = (pathname) => {
    const indexOfTaskLink = getIndexOfTaskLink(pathname);
    const noTaskModalPathname = indexOfTaskLink && indexOfTaskLink > -1 ? pathname.replace(pathname.substring(indexOfTaskLink - 1), '') : pathname;
    return noTaskModalPathname.endsWith('/') ? noTaskModalPathname : noTaskModalPathname + '/';
};
export const getTaskLink = (companyTaskId) => {
    const pathname = window.location.pathname;
    const cleanPathName = cleanTaskModalLinkFromPathName(pathname);
    return `${cleanPathName}T${companyTaskId}`;
};
export const getProjectLinkAppendLastViewed = (companyProjectId, customProjectId) => {
    const lastViewedPage = localStorage.getItem('project-section-last-viewed') || 'workflow';
    return `${projectUrl(companyProjectId, customProjectId)}/${lastViewedPage}`;
};
export const getProjectGroupLink = (companyProjectId) => {
    const lastViewedPage = localStorage.getItem('project-group-section-last-viewed') || 'workflow';
    return `/connected/X-${companyProjectId}/${lastViewedPage}`;
};
