import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { updateTimeRegistration } from './logic/TimeRegistrationModalMutation';
import TimeRegistrationModalQueryWrapper from './query-wrappers/TimeRegistrationModalQueryWrapper';
import { toEntityId } from './util/TimeRegistrationModalUtil';
const infoText = {
    headerText: 'Update time entry',
    creativeButtonText: 'Save',
    cancelButtonText: 'Cancel',
};
function updateModalTimeRegistration({ timeRegistrationMutationInput, timeRegistrationId, closeModal, }) {
    if (timeRegistrationId) {
        updateTimeRegistration(Object.assign({ timeRegistrationId }, timeRegistrationMutationInput));
        closeModal();
    }
}
function getEntityIdFromTimeRegistration(timeRegistration) {
    if (!timeRegistration)
        return null;
    const { taskId, projectId, idleTimeId } = timeRegistration;
    return toEntityId({ taskId, projectId, idleTimeId });
}
function getInitialDateFromTimeRegistration(timeRegistration) {
    if (timeRegistration && timeRegistration.year && timeRegistration.month && timeRegistration.day) {
        return new Date(timeRegistration.year, timeRegistration.month - 1, timeRegistration.day);
    }
}
function TimeRegistrationModalUpdate({ viewer, closeModal }) {
    var _a, _b, _c, _d, _e, _f;
    const timeRegistration = viewer.timeRegistration;
    const timeRegistrationId = timeRegistration ? timeRegistration.id : null;
    const entityId = getEntityIdFromTimeRegistration(timeRegistration);
    const projectId = (_b = (_a = timeRegistration === null || timeRegistration === void 0 ? void 0 : timeRegistration.task) === null || _a === void 0 ? void 0 : _a.project) === null || _b === void 0 ? void 0 : _b.id;
    const timeRegBillableTime = (_c = timeRegistration === null || timeRegistration === void 0 ? void 0 : timeRegistration.billableMinutesRegistered) !== null && _c !== void 0 ? _c : undefined;
    const personId = ((_d = timeRegistration === null || timeRegistration === void 0 ? void 0 : timeRegistration.person) === null || _d === void 0 ? void 0 : _d.id) || viewer.actualPersonId;
    const initialTime = (timeRegistration === null || timeRegistration === void 0 ? void 0 : timeRegistration.minutesRegistered) || undefined;
    // We only pass along a value in initialBillableTime if minutesRegistered and billableMinutesRegistered are not the same.
    // If they are the same, we consider them to be linked and will let the default handling take over.
    const initialBillableTime = initialTime !== timeRegBillableTime ? timeRegBillableTime : undefined;
    const initialDate = getInitialDateFromTimeRegistration(timeRegistration);
    const initialNote = (timeRegistration === null || timeRegistration === void 0 ? void 0 : timeRegistration.notes) || undefined;
    const initialHarvestTaskId = ((_e = timeRegistration === null || timeRegistration === void 0 ? void 0 : timeRegistration.harvestTask) === null || _e === void 0 ? void 0 : _e.id) || undefined;
    const initialRole = (timeRegistration === null || timeRegistration === void 0 ? void 0 : timeRegistration.role) || undefined;
    const jiraTaskId = ((_f = timeRegistration === null || timeRegistration === void 0 ? void 0 : timeRegistration.task) === null || _f === void 0 ? void 0 : _f.jiraId) || undefined;
    return (React.createElement(TimeRegistrationModalQueryWrapper, { personId: personId, entityId: entityId, projectId: projectId, infoText: infoText, initialDate: initialDate, addInitialTimeToRemaining: true, initialMinutes: initialTime, initialBillableTime: initialBillableTime, initialNote: initialNote, initialHarvestTaskId: initialHarvestTaskId, initialRole: initialRole, jiraTaskId: jiraTaskId, onlyValidateFormElementsOnChange: true, callbackPositive: timeRegistrationMutationInput => updateModalTimeRegistration({ timeRegistrationMutationInput, timeRegistrationId, closeModal }), closeModal: closeModal }));
}
export const TimeRegistrationModalUpdateQuery = graphql `
	query TimeRegistrationModalUpdate_Query($timeRegistrationId: ID) {
		viewer {
			actualPersonId
			component(name: "time_registration_modal_update")
			...TimeRegistrationModalUpdate_viewer @arguments(timeRegistrationId: $timeRegistrationId)
		}
	}
`;
export default createFragmentContainer(TimeRegistrationModalUpdate, {
    viewer: graphql `
		fragment TimeRegistrationModalUpdate_viewer on Viewer @argumentDefinitions(timeRegistrationId: {type: "ID!"}) {
			actualPersonId
			timeRegistration(id: $timeRegistrationId) {
				id
				taskId
				projectId
				idleTimeId
				minutesRegistered
				billableMinutesRegistered
				notes
				year
				month
				day
				person {
					id
				}
				harvestTask {
					id
				}
				role {
					id
					name
				}
				task {
					id
					jiraId
					project {
						id
					}
				}
			}
		}
	`,
});
