/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
query TransferOwnershipModalQuery(
  $permissions: [String!]!
) {
  viewer {
    actualPersonId
    component(name: "transfer_ownership_modal")
    ...TransferOwnershipModal_viewer_21G6Dd
    id
  }
}

fragment TransferOwnershipModal_viewer_21G6Dd on Viewer {
  company {
    allPersons(first: 10000, permissions: $permissions) {
      edges {
        node {
          id
          fullName
          profilePictureId
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    id
  }
}
*/
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "permissions",
            "type": "[String!]!"
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actualPersonId",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": [
            {
                "kind": "Literal",
                "name": "name",
                "value": "transfer_ownership_modal"
            }
        ],
        "kind": "ScalarField",
        "name": "component",
        "storageKey": "component(name:\"transfer_ownership_modal\")"
    }, v3 = {
        "kind": "Variable",
        "name": "permissions",
        "variableName": "permissions"
    }, v4 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 10000
        },
        (v3 /*: any*/)
    ], v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "TransferOwnershipModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "args": [
                                (v3 /*: any*/)
                            ],
                            "kind": "FragmentSpread",
                            "name": "TransferOwnershipModal_viewer"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "TransferOwnershipModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Company",
                            "kind": "LinkedField",
                            "name": "company",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": (v4 /*: any*/),
                                    "concreteType": "PersonTypeConnection",
                                    "kind": "LinkedField",
                                    "name": "allPersons",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PersonTypeEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Person",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v5 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "fullName",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "profilePictureId",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "__typename",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "cursor",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PageInfo",
                                            "kind": "LinkedField",
                                            "name": "pageInfo",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "endCursor",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "hasNextPage",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": (v4 /*: any*/),
                                    "filters": [
                                        "permissions"
                                    ],
                                    "handle": "connection",
                                    "key": "Company_allPersons",
                                    "kind": "LinkedHandle",
                                    "name": "allPersons"
                                },
                                (v5 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v5 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "TransferOwnershipModalQuery",
            "operationKind": "query",
            "text": "query TransferOwnershipModalQuery(\n  $permissions: [String!]!\n) {\n  viewer {\n    actualPersonId\n    component(name: \"transfer_ownership_modal\")\n    ...TransferOwnershipModal_viewer_21G6Dd\n    id\n  }\n}\n\nfragment TransferOwnershipModal_viewer_21G6Dd on Viewer {\n  company {\n    allPersons(first: 10000, permissions: $permissions) {\n      edges {\n        node {\n          id\n          fullName\n          profilePictureId\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    id\n  }\n}\n"
        }
    };
})();
node.hash = 'be8adbbe3db3984c68cbfbd82fd7ddce';
export default node;
