import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {withRouter} from 'react-router-dom';
import {FormattedMessage, injectIntl} from 'react-intl';
import CustomScrollDiv from '../../shared/components/scroll-bars/custom_scroll_div';
import quickbooksLogo from '../../../images/integrations/quickbooks/quickbooks-logo.svg';
import CreateQuickbooksVerificationKeyMutation from '../../../mutations/create_quickbooks_verification_key_mutation';
import DisableQuickbooksIntegrationMutation from '../../../mutations/disable_quickbooks_integration_mutation';
import * as tracking from '../../../tracking';
import {BUTTON_COLOR, BUTTON_STYLE, ELEMENT_TYPE} from '../../../constants';
import {MODAL_TYPE, showModal} from '../../shared/components/modals/generic_modal_conductor';
import Util from '../../shared/util/util';
import Warning from '../../../components/warning';
import {buildHeaderBar} from '../../shared/components/headers/header-bar/header_bar';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import {hasFeatureFlag} from '../../shared/util/FeatureUtil';
import {INVOICE_INTEGRATIONS} from '../finance-page/ts/InvoiceIntegrationsUtils';

class appQuickbooks extends Component {
	disableQuickbooks() {
		const {formatMessage} = this.props.intl;

		const callbackPositive = () => {
			Util.CommitMutation(DisableQuickbooksIntegrationMutation, undefined, () => {
				window.location.reload(false);
			});
		};

		if (hasFeatureFlag('invoicing_pathways')) {
			showModal({
				type: MODAL_TYPE.DISABLE_INTEGRATION_MODAL,
				integration: INVOICE_INTEGRATIONS.QUICKBOOKS,
				removeCallback: callbackPositive,
			});
		} else {
			showModal({
				type: MODAL_TYPE.GENERIC,
				content: (
					<div>
						<Warning messageId={'invoicing.disable_quickbooks'} />
						<div className="warning-part-2">
							{formatMessage({id: 'common.warning.this_action_can_not_be_undone'})}
						</div>
					</div>
				),
				className: 'default-warning-modal',
				buttons: [
					{
						text: formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: formatMessage({id: 'integration.disable'}),
						callback: callbackPositive,
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.RED,
					},
				],
			});
		}
	}

	enableQuickbooks() {
		const onSuccess = ({createQuickbooksVerificationKey}) => {
			tracking.trackEvent('Quickbooks integration enabled');
			trackEvent('Quickbooks Integration', 'Enabled');
			window.location.href = `https://appcenter.intuit.com/connect/oauth2?client_id=${process.env.QUICKBOOKS_CLIENT_ID}&scope=com.intuit.quickbooks.accounting&redirect_uri=${process.env.QUICKBOOKS_REDIRECT_URI}&response_type=code&state=${createQuickbooksVerificationKey.key}`;
		};

		Util.CommitMutation(CreateQuickbooksVerificationKeyMutation, null, onSuccess);
	}

	openHelpCenter() {
		window.open('https://support.forecast.app/hc/en-us/articles/7306977969809');
	}

	getHeader() {
		const leftContent = [],
			rightContent = [];

		leftContent.push({type: ELEMENT_TYPE.BACK_BUTTON, onClick: this.props.history.goBack});

		return buildHeaderBar(leftContent, rightContent);
	}

	render() {
		const {formatMessage} = this.props.intl;
		return (
			<div className="section-content settings-app settings-app-page">
				{this.props.children}
				{this.getHeader()}
				<CustomScrollDiv>
					<div className="section-body">
						<div className="inner">
							<fieldset className="general">
								<div className="header-wrapper">
									<img height={100} alt="quickbooks logo" src={quickbooksLogo} />
									<div className="title-description-wrapper">
										<div className="description">
											{formatMessage({id: 'settings.app_catalog.quickbooks.description'})}
										</div>
									</div>
								</div>
								<div className="qb-buttons">
									{this.props.viewer.company.quickbooksEnabled ? (
										<button className="submit-button" onClick={this.disableQuickbooks.bind(this)}>
											{formatMessage({id: 'integration.disable'})}
										</button>
									) : (
										[
											<button
												className="connect-quickbooks"
												onClick={this.enableQuickbooks.bind(this)}
												key={'connectqb'}
											/>,
											<button
												className="help-button"
												onClick={this.openHelpCenter.bind(this)}
												key={'helpqb'}
											>
												<span>
													<FormattedMessage id="settings.app_catalog.help_button" />
												</span>
											</button>,
										]
									)}
								</div>
							</fieldset>
						</div>
					</div>
				</CustomScrollDiv>
			</div>
		);
	}
}

const appQuickbooksQuery = graphql`
	query appQuickbooks_Query {
		viewer {
			actualPersonId
			component(name: "app_quickbooks")
			...appQuickbooks_viewer
		}
	}
`;

export {appQuickbooksQuery};

export default createFragmentContainer(injectIntl(withRouter(appQuickbooks)), {
	viewer: graphql`
		fragment appQuickbooks_viewer on Viewer {
			id
			company {
				id
				quickbooksEnabled
			}
		}
	`,
});
