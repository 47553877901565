import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DatePicker from './date_picker';
import Util from '../../shared/util/util';
import DeleteHolidayCalendarEntryMutation from '../../../mutations/delete_holiday_calendar_entry_mutation';
import UpdateHolidayCalendarEntryMutation from '../../../mutations/update_holiday_calendar_entry_mutation';
import {FormattedMessage} from 'react-intl';

class HolidayCalendarEntryRow extends Component {
	constructor(props) {
		super(props);
		this.state = {
			gearMenuExpanded: false,
			name: this.props.holidayCalendarEntry.name || '',
		};
	}

	closeGearMenu() {
		this.setState({gearMenuExpanded: false});
	}

	toggleGearMenu(e) {
		this.setState({gearMenuExpanded: !this.state.gearMenuExpanded});
	}

	deleteHolidayCalendarEntry() {
		Util.CommitMutation(
			DeleteHolidayCalendarEntryMutation,
			{
				id: this.props.holidayCalendarEntry.id,
				holidayCalendarId: this.props.holidayCalendarId,
			},
			null,
			true
		);
	}

	onNameChange(e) {
		this.setState({name: e.target.value || ''});
	}

	onNameBlur() {
		Util.CommitMutation(
			UpdateHolidayCalendarEntryMutation,
			{
				id: this.props.holidayCalendarEntry.id,
				name: this.state.name,
			},
			null,
			true
		);
	}

	onDateChange(value) {
		Util.CommitMutation(
			UpdateHolidayCalendarEntryMutation,
			{
				id: this.props.holidayCalendarEntry.id,
				year: value.year(),
				month: value.month() + 1,
				day: value.date(),
			},
			null,
			true
		);
	}

	render() {
		const {holidayCalendarEntry} = this.props;
		const {name} = this.state;
		return (
			<tr>
				<td>
					<input
						data-cy="holiday-name"
						type="text"
						value={name}
						onBlur={this.onNameBlur.bind(this)}
						onChange={this.onNameChange.bind(this)}
					/>
				</td>
				<td>
					<DatePicker
						value={Util.CreateNonUtcMomentDate(
							holidayCalendarEntry.year,
							holidayCalendarEntry.month,
							holidayCalendarEntry.day
						)}
						onDatePick={this.onDateChange.bind(this)}
					/>
				</td>
				<td
					className="gear-menu"
					tabIndex="0"
					onBlur={this.closeGearMenu.bind(this)}
					onClick={this.toggleGearMenu.bind(this)}
				>
					<span className="gear-icon" data-cy="gear-menu-dropdown" />
					<div className={`gear-menu-drop-down ${this.state.gearMenuExpanded ? 'expanded' : 'collapsed'}`}>
						<ul className="settings-drop-down">
							<li
								data-cy="delete-holiday"
								className="option"
								onClick={this.deleteHolidayCalendarEntry.bind(this)}
							>
								<FormattedMessage id="common.delete" />
							</li>
						</ul>
					</div>
				</td>
			</tr>
		);
	}
}

HolidayCalendarEntryRow.propTypes = {
	holidayCalendarEntry: PropTypes.object.isRequired,
	holidayCalendarId: PropTypes.string.isRequired,
};

export default HolidayCalendarEntryRow;
