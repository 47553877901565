import {hasFeatureFlag} from '../../../shared/util/FeatureUtil';
import Util from '../../../shared/util/util';
import {ForecastTooltipFormulaRenderer} from '../../../../components/ForecastTooltipFormulaRenderer';
import React from 'react';

export const getHolidaysMap = holidayCalendars => {
	const calendars = holidayCalendars.edges.map(edge => edge.node);
	const holidaysMap = new Map();
	const holidayCalendarMap = new Map(calendars.map(calendar => [calendar.id, calendar.holidayCalendarEntries]));

	for (let key of holidayCalendarMap.keys()) {
		const holidays = [];

		const entries = holidayCalendarMap.get(key);
		const holidayCalendarEntries = entries.edges.map(edge => edge.node);
		for (const holidayCalendarEntry of holidayCalendarEntries) {
			const holidayDate = Util.createDate(
				holidayCalendarEntry.year,
				holidayCalendarEntry.month,
				holidayCalendarEntry.day
			);
			if (holidays.includes(holidayDate)) continue;
			holidays.push(holidayDate.getTime());
		}
		holidaysMap.set(key, holidays);
	}
	return holidaysMap;
};

export const getValue = (key, map) => {
	if (!map.has(key)) {
		map.set(key, {minutesAvailable: 0, remainingMinutesAvailable: 0});
	}
	return map.get(key);
};

export const addDays = (date, days) => {
	date.setDate(date.getDate() + days);
};

export const isDateInRange = (date, startDate, endDate) => {
	return startDate.getTime() <= date.getTime() && date.getTime() <= endDate.getTime();
};

export const getMinutesForDay = (date, allocation) => {
	const weekDay = date.getDay();
	switch (weekDay) {
		case 0:
			return allocation.sunday;
		case 1:
			return allocation.monday;
		case 2:
			return allocation.tuesday;
		case 3:
			return allocation.wednesday;
		case 4:
			return allocation.thursday;
		case 5:
			return allocation.friday;
		case 6:
			return allocation.saturday;
	}
};

const getPhasesOnDay = (date, phases) => {
	const phaseIds = [];

	for (let n = 0; n < phases.length; n++) {
		const phase = phases[n];
		const phaseStartDate = Util.createDate(phase.startYear, phase.startMonth, phase.startDay);
		const phaseEndDate = Util.createDate(phase.deadlineYear, phase.deadlineMonth, phase.deadlineDay);
		if (isDateInRange(date, phaseStartDate, phaseEndDate)) {
			phaseIds.push(phase.id);
		}
	}

	if (phaseIds.length === 0) {
		phaseIds.push('no-phase');
	}

	return phaseIds;
};

export const getNow = () => {
	const now = new Date();
	now.setHours(0, 0, 0, 0);
	return now;
};

const NO_ROLE_ID = 'no_role';
export const createAvailabilityMap = (phases, allocations, projectPersons, holidaysMap, sharedOptions) => {
	const startPerformance = performance.now();
	const {isUsingProjectAllocation, isUsingMixedAllocation} = sharedOptions;
	if (!isUsingProjectAllocation && !isUsingMixedAllocation) return new Map();

	const now = getNow();

	const personProjectRoleMap = projectPersons.reduce((personRoleMap, projectPerson) => {
		personRoleMap[projectPerson.person.id] = projectPerson.role;

		return personRoleMap;
	}, {});

	const hasInvertedPtoNonWorkingDays = hasFeatureFlag('inverted_pto_non_working_days');

	const personDaysOffMap = new Map();
	projectPersons.forEach(projectPerson => {
		const daysOff = new Set();
		const holidays = holidaysMap.get(projectPerson.person.holidayCalendar?.id);
		if (holidays) {
			holidays.forEach(holiday => daysOff.add(holiday));
		}

		if (!hasInvertedPtoNonWorkingDays) {
			const timeOffAllocations = projectPerson.person.allocations.edges
				.map(allocation => allocation.node)
				.filter(allocation => !allocation.idleTime.isInternalTime);
			const timeOffByDay = new Map();
			timeOffAllocations.forEach(allocation => {
				const allocationStartDate = Util.createDate(allocation.startYear, allocation.startMonth, allocation.startDay);
				const allocationEndDate = Util.createDate(allocation.endYear, allocation.endMonth, allocation.endDay);

				let date = allocationStartDate;
				while (date <= allocationEndDate) {
					const allocationAmount = getMinutesForDay(date, allocation);
					timeOffByDay.set(date.getTime(), (timeOffByDay.get(date.getTime()) || 0) + allocationAmount);
					addDays(date, 1);
				}
			});

			for (let [key, value] of timeOffByDay) {
				const date = new Date(key);
				const personWorkingTime = getMinutesForDay(date, projectPerson.person);
				if (personWorkingTime <= value) {
					daysOff.add(key);
				}
			}
		}

		personDaysOffMap.set(projectPerson.person.id, daysOff);
	});

	const availabilityMap = new Map();
	for (let i = 0; i < allocations.length; i++) {
		const allocation = allocations[i];
		if (!allocation.isSoft) {
			const allocationStartDate = Util.createDate(allocation.startYear, allocation.startMonth, allocation.startDay);
			const allocationEndDate = Util.createDate(allocation.endYear, allocation.endMonth, allocation.endDay);
			const {person} = allocation;
			const personId = person.id;
			const projectRole = personProjectRoleMap[person.id];
			const role = projectRole ? projectRole : person.role;
			const roleId = role?.id || NO_ROLE_ID;

			const date = allocationStartDate;
			while (!(date.getTime() > allocationEndDate.getTime())) {
				if (personDaysOffMap.get(allocation.person.id)?.has(date.getTime())) {
					addDays(date, 1);
					continue;
				}

				const phaseIds = getPhasesOnDay(date, phases);
				const minutes = getMinutesForDay(date, allocation) / phaseIds.length;

				for (let n = 0; n < phaseIds.length; n++) {
					const phaseAvailability = getValue(phaseIds[n] + '', availabilityMap);
					const personAvailability = getValue(phaseIds[n] + '-' + personId, availabilityMap);
					const roleAvailability = getValue(phaseIds[n] + '-' + roleId, availabilityMap);

					phaseAvailability.minutesAvailable += minutes;
					personAvailability.minutesAvailable += minutes;
					roleAvailability.minutesAvailable += minutes;

					if (!(date.getTime() < now.getTime())) {
						phaseAvailability.remainingMinutesAvailable += minutes;
						personAvailability.remainingMinutesAvailable += minutes;
						roleAvailability.remainingMinutesAvailable += minutes;
					}
				}

				addDays(date, 1);
			}
		}
	}

	console.log('createAvailabilityMap took ' + (performance.now() - startPerformance) + ' ms');

	return availabilityMap;
};

export const getTotalAvailabilityTooltip = formatMessage => {
	return formatMessage({id: 'project_scoping.total_availability_tooltip'});
};

export const getRemainingAvailabilityTooltip = formatMessage => {
	return (
		<ForecastTooltipFormulaRenderer
			items={[
				{
					title: formatMessage({id: 'common.remaining_availability'}),
					details: [
						formatMessage({id: 'common.total_availability'}),
						'-',
						formatMessage({id: 'project_scoping.allocated_hours_in_past'}),
						'-',
						formatMessage({id: 'project_scoping.remaining_time_on_tasks'}),
					],
				},
			]}
			translatedMessage={true}
		/>
	);
};
