/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
query SettingsFinanceBillTo_Query {
  viewer {
    actualPersonId
    component(name: "settings_finance")
    ...SettingsFinanceBillTo_viewer
    id
  }
}

fragment SettingsFinanceBillTo_viewer on Viewer {
  id
  company {
    id
    billFroms {
      id
      integration
      name
      billTos(first: 10000) {
        edges {
          node {
            id
            name
            address
            taxId
            invoiced
            __typename
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
    clients(first: 100000) {
      edges {
        node {
          id
          name
          clientBillTos(first: 10000) {
            edges {
              node {
                id
                billFrom {
                  id
                }
                billTo {
                  id
                }
                clientId
                invoiced
                __typename
              }
              cursor
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          }
        }
      }
    }
  }
}
*/
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actualPersonId",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": [
            {
                "kind": "Literal",
                "name": "name",
                "value": "settings_finance"
            }
        ],
        "kind": "ScalarField",
        "name": "component",
        "storageKey": "component(name:\"settings_finance\")"
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v4 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 10000
        }
    ], v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "invoiced",
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v9 = [
        (v2 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "SettingsFinanceBillTo_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        (v1 /*: any*/),
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "SettingsFinanceBillTo_viewer"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "SettingsFinanceBillTo_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Company",
                            "kind": "LinkedField",
                            "name": "company",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "BillFrom",
                                    "kind": "LinkedField",
                                    "name": "billFroms",
                                    "plural": true,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "integration",
                                            "storageKey": null
                                        },
                                        (v3 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": (v4 /*: any*/),
                                            "concreteType": "BillToTypeConnection",
                                            "kind": "LinkedField",
                                            "name": "billTos",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "BillToTypeEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "BillTo",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                                (v2 /*: any*/),
                                                                (v3 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "address",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "taxId",
                                                                    "storageKey": null
                                                                },
                                                                (v5 /*: any*/),
                                                                (v6 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        (v7 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v8 /*: any*/)
                                            ],
                                            "storageKey": "billTos(first:10000)"
                                        },
                                        {
                                            "alias": null,
                                            "args": (v4 /*: any*/),
                                            "filters": [],
                                            "handle": "connection",
                                            "key": "BillFrom_billTos",
                                            "kind": "LinkedHandle",
                                            "name": "billTos"
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": [
                                        {
                                            "kind": "Literal",
                                            "name": "first",
                                            "value": 100000
                                        }
                                    ],
                                    "concreteType": "ClientTypeConnection",
                                    "kind": "LinkedField",
                                    "name": "clients",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ClientTypeEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Client",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v2 /*: any*/),
                                                        (v3 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": (v4 /*: any*/),
                                                            "concreteType": "ClientBillToTypeConnection",
                                                            "kind": "LinkedField",
                                                            "name": "clientBillTos",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "ClientBillToTypeEdge",
                                                                    "kind": "LinkedField",
                                                                    "name": "edges",
                                                                    "plural": true,
                                                                    "selections": [
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "concreteType": "ClientBillTo",
                                                                            "kind": "LinkedField",
                                                                            "name": "node",
                                                                            "plural": false,
                                                                            "selections": [
                                                                                (v2 /*: any*/),
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "concreteType": "BillFrom",
                                                                                    "kind": "LinkedField",
                                                                                    "name": "billFrom",
                                                                                    "plural": false,
                                                                                    "selections": (v9 /*: any*/),
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "concreteType": "BillTo",
                                                                                    "kind": "LinkedField",
                                                                                    "name": "billTo",
                                                                                    "plural": false,
                                                                                    "selections": (v9 /*: any*/),
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "clientId",
                                                                                    "storageKey": null
                                                                                },
                                                                                (v5 /*: any*/),
                                                                                (v6 /*: any*/)
                                                                            ],
                                                                            "storageKey": null
                                                                        },
                                                                        (v7 /*: any*/)
                                                                    ],
                                                                    "storageKey": null
                                                                },
                                                                (v8 /*: any*/)
                                                            ],
                                                            "storageKey": "clientBillTos(first:10000)"
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": (v4 /*: any*/),
                                                            "filters": [],
                                                            "handle": "connection",
                                                            "key": "Client_clientBillTos",
                                                            "kind": "LinkedHandle",
                                                            "name": "clientBillTos"
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": "clients(first:100000)"
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "SettingsFinanceBillTo_Query",
            "operationKind": "query",
            "text": "query SettingsFinanceBillTo_Query {\n  viewer {\n    actualPersonId\n    component(name: \"settings_finance\")\n    ...SettingsFinanceBillTo_viewer\n    id\n  }\n}\n\nfragment SettingsFinanceBillTo_viewer on Viewer {\n  id\n  company {\n    id\n    billFroms {\n      id\n      integration\n      name\n      billTos(first: 10000) {\n        edges {\n          node {\n            id\n            name\n            address\n            taxId\n            invoiced\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n    clients(first: 100000) {\n      edges {\n        node {\n          id\n          name\n          clientBillTos(first: 10000) {\n            edges {\n              node {\n                id\n                billFrom {\n                  id\n                }\n                billTo {\n                  id\n                }\n                clientId\n                invoiced\n                __typename\n              }\n              cursor\n            }\n            pageInfo {\n              endCursor\n              hasNextPage\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = '8e9d606fe1999421698cb8d9c21990d2';
export default node;
