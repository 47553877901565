/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type DEPENDENCY_TYPE = "CANNOT_BE_COMPLETED" | "CANNOT_START" | "%future added value";
export type STATUS_CATEGORY = "DONE" | "INPROGRESS" | "TODO" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectWorkflowColumnContent_task$ref: FragmentReference;
declare export opaque type ProjectWorkflowColumnContent_task$fragmentType: ProjectWorkflowColumnContent_task$ref;
export type ProjectWorkflowColumnContent_task = {|
  +id: string,
  +thisTaskDependsOn: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +type: ?DEPENDENCY_TYPE,
        +thisDependsOnTask: ?{|
          +statusColumnV2: ?{|
            +category: ?STATUS_CATEGORY
          |}
        |},
      |}
    |}>
  |},
  +dependsOnThisTask: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +type: ?DEPENDENCY_TYPE,
        +taskDependsOnThis: ?{|
          +id: string,
          +statusColumnV2: ?{|
            +category: ?STATUS_CATEGORY
          |},
        |},
      |}
    |}>
  |},
  +$refType: ProjectWorkflowColumnContent_task$ref,
|};
export type ProjectWorkflowColumnContent_task$data = ProjectWorkflowColumnContent_task;
export type ProjectWorkflowColumnContent_task$key = {
  +$data?: ProjectWorkflowColumnContent_task$data,
  +$fragmentRefs: ProjectWorkflowColumnContent_task$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "StatusColumnV2",
  "kind": "LinkedField",
  "name": "statusColumnV2",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "category",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "thisTaskDependsOn"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "dependsOnThisTask"
        ]
      }
    ]
  },
  "name": "ProjectWorkflowColumnContent_task",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "thisTaskDependsOn",
      "args": null,
      "concreteType": "DependencyTypeConnection",
      "kind": "LinkedField",
      "name": "__Task_thisTaskDependsOn_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DependencyTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DependencyType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Task",
                  "kind": "LinkedField",
                  "name": "thisDependsOnTask",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                },
                (v3/*: any*/)
              ],
              "storageKey": null
            },
            (v4/*: any*/)
          ],
          "storageKey": null
        },
        (v5/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": "dependsOnThisTask",
      "args": null,
      "concreteType": "DependencyTypeConnection",
      "kind": "LinkedField",
      "name": "__Task_dependsOnThisTask_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DependencyTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DependencyType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Task",
                  "kind": "LinkedField",
                  "name": "taskDependsOnThis",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                },
                (v3/*: any*/)
              ],
              "storageKey": null
            },
            (v4/*: any*/)
          ],
          "storageKey": null
        },
        (v5/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Task"
};
})();
// prettier-ignore
(node/*: any*/).hash = '39efb64113f2b96a1985f1ecbd74abdb';

module.exports = node;
