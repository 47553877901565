import React from 'react';
import styled from 'styled-components';
import {FormattedMessage, injectIntl} from 'react-intl';
import TooltipContainer from '../../../shared/components/tooltips/tooltip_container';
import {WorkflowCategories} from '../../../../constants';
import Util from '../../../shared/util/util';

const StatWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 16px;
`;

const StatNumber = styled.div`
	font-weight: 600;
	margin-bottom: 4px;
	color: #535353;
`;

const StatTitle = styled.div`
	text-transform: uppercase;
	font-weight: 500;
	font-size: 9px;
	letter-spacing: 1px;
	color: #a1a1a1;
`;

const MyWorkWeekStats = ({intl, startDate, endDate, deadlineTasks, allocationData, timeRegistrations}) => {
	const isYMDDateThisWeek = (year, month, day) => {
		const taskDeadlineMoment = Util.CreateNonUtcMomentDate(year, month, day);
		return taskDeadlineMoment ? taskDeadlineMoment.isBetween(startDate, endDate, 'day', []) : false;
	};

	const getDeadlineEstimate = task => {
		const taskTimeRegsOutsideWeek = task.timeRegistrations.edges.filter(tReg => {
			return !isYMDDateThisWeek(tReg.node.year, tReg.node.month, tReg.node.day);
		});
		// Time from time regs not made during this week.
		const taskTimeOutsideWeek = taskTimeRegsOutsideWeek.reduce((total, tReg) => total + tReg.node.minutesRegistered, 0);
		return task.estimateForecast - taskTimeOutsideWeek;
	};

	const deadlineAmount = deadlineTasks.length;
	const doneDeadlineAmount = deadlineTasks.filter(
		task => task.node.statusColumnV2.category === WorkflowCategories.DONE
	).length;

	// PROGRESS CALCULATION START
	const weekGoal = allocationData ? allocationData.minutesAllocated : null;

	// Time registrations on projects or task without deadline this week
	const weekNonDeadlineTimeRegs = timeRegistrations.filter(tReg => {
		return (
			!tReg.node.task ||
			(tReg.node.task &&
				!isYMDDateThisWeek(tReg.node.task.deadlineYear, tReg.node.task.deadlineMonth, tReg.node.task.deadlineDay))
		);
	});
	const weekNonDeadlineTime = weekNonDeadlineTimeRegs.reduce((total, tReg) => total + tReg.node.minutesRegistered, 0);

	const weekDeadlineEstimate = deadlineTasks
		.map(task => task.node)
		.reduce((total, task) => total + getDeadlineEstimate(task), 0);
	const weekDeadlineRemaining = deadlineTasks.reduce((total, task) => total + task.node.timeLeft, 0);
	const weekDeadlineDone = weekDeadlineEstimate - weekDeadlineRemaining;

	// Time registrations on tasks with deadline NOT this week should eventually stop increasing progress. If weekDeadlineEstimate is equal or higher than weekGoal, only time regs on tasks with deadline this week will count.
	const weekNonDeadlineTimeCap = weekGoal - Math.min(weekGoal, weekDeadlineEstimate);
	const weekNonDeadlineTimeCapped = Math.min(weekNonDeadlineTime, weekNonDeadlineTimeCap);
	const weekWork = weekNonDeadlineTimeCapped + weekDeadlineDone;

	const weekProgress =
		weekGoal && weekGoal > 0 ? intl.formatNumber((weekWork / weekGoal) * 100, {format: 'rounded_zero_decimal'}) : 100;
	// PROGRESS CALCULATION END

	// TODO: Progress is currently just shown as 0% when weekProgress is under 0%, should probably add a warning
	return (
		<>
			<StatWrapper>
				<TooltipContainer
					infoText={intl.formatMessage({id: 'my-work.week_progress_info'})}
					tooltipDuration={20000}
					customMaxWidth={200}
				>
					<StatNumber>
						{weekGoal && weekProgress > 100 ? (
							<span>100%</span>
						) : weekGoal && weekProgress > 0 ? (
							<span>{weekProgress + '%'}</span>
						) : (
							<span>0%</span>
						)}
					</StatNumber>
				</TooltipContainer>
				<StatTitle>
					<FormattedMessage id="my-work.week_progress" />
				</StatTitle>
			</StatWrapper>
			<StatWrapper>
				<TooltipContainer
					infoText={intl.formatMessage({id: 'my-work.deadlines_met_info'})}
					tooltipDuration={20000}
					customMaxWidth={200}
				>
					<StatNumber>{doneDeadlineAmount + '/' + deadlineAmount}</StatNumber>
				</TooltipContainer>
				<StatTitle>
					<FormattedMessage id="my-work.deadlines_met" />
				</StatTitle>
			</StatWrapper>
		</>
	);
};

export default injectIntl(MyWorkWeekStats);
