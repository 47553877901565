import React from 'react';
import {useIntl} from 'react-intl';
import {Data, Element, GridContainer, IconWrapper, Row, Title, TotalsLine} from './ProjectScopingTotals_styled';
import ForecastTooltip from '../../../../shared/components/tooltips/ForecastTooltip';
import {ValueCell} from '../../../../../components/initial-plan/ValueCell';
import {useProjectTotals} from './ProjectScopingTotalsContext';
import {
	EstimateValueTooltip,
	ProjectScopingTotalsTooltips,
	RemainingValueTooltip,
	TimeEntriesValueTooltip,
	VarianceValueTooltip,
} from './ProjectScopingTotalsTooltips';
import {ExpandableIcon} from 'web-components';
import {remapId} from '../../../../shared/util/FinancialInternationalisationUtil';
import ProjectUtil from '../../../../shared/util/project_util';
import {BUDGET_TYPE, PERIOD_BUDGET_TYPE} from '../../../../../constants';
import {hasFeatureFlag} from '../../../../shared/util/FeatureUtil';

/**
 * Create the project scoping header section in a line (collapsed)
 *
 * @returns JSX Project Scoping Totals Line Component
 */
const ProjectScopingTotalsBox = () => {
	const intl = useIntl();

	const {totalsExpanded, toggleTotalsExpanded, totalsData, settings, projectData} = useProjectTotals();
	const projectTracksRevenue = ProjectUtil.projectTracksRevenue(projectData);
	const isFixedPriceV2 =
		projectTracksRevenue &&
		(projectData.budgetType === BUDGET_TYPE.FIXED_PRICE_V2 ||
			(projectData.budgetType === BUDGET_TYPE.RETAINER &&
				projectData.defaultPeriodBudgetType === PERIOD_BUDGET_TYPE.FIXED_PRICE));
	const isFixedPriceProject = projectData.budgetType === BUDGET_TYPE.FIXED_PRICE_V2;

	return (
		<GridContainer data-userpilot="project-totals-header">
			<TotalsLine key="totals-box" data-cy="totals-box">
				<Row>
					{hasFeatureFlag('use_financial_service_in_scoping') &&
					hasFeatureFlag('financial_categories_update') &&
					isFixedPriceV2 ? (
						<>
							<Element column>
								<Title>
									{isFixedPriceProject
										? intl.formatMessage({id: 'common.fixed_price'})
										: intl.formatMessage({id: 'common.projected_total_revenue'})}
								</Title>
								<Data small>
									<ValueCell
										noJustify
										cy={isFixedPriceProject ? 'fixed_price' : 'projected_total_revenue'}
										type={ValueCell.VALUE_TYPE.PRICE}
										currencySymbol={settings.currencySymbol}
										value={isFixedPriceProject ? projectData.budget : totalsData.projectedTotalRevenue}
									/>
								</Data>
							</Element>
							<Element column>
								<Title>{intl.formatMessage({id: 'common.projected_total_margin'})}</Title>
								<Data small>
									<ValueCell
										noJustify
										cy={'projected_total_margin'}
										type={ValueCell.VALUE_TYPE.PERCENTAGE}
										value={totalsData.projectedTotalMargin}
									/>
								</Data>
							</Element>
							<Element column>
								<Title>{intl.formatMessage({id: 'common.projected_total_billable_value_of_service'})}</Title>
								<Data small>
									<ValueCell
										noJustify
										cy={'projected_total_billable_value_of_service'}
										type={ValueCell.VALUE_TYPE.PRICE}
										currencySymbol={settings.currencySymbol}
										value={totalsData.projectedBillableValueOfService}
									/>
								</Data>
							</Element>
							<Element column>
								<Title>{intl.formatMessage({id: 'common.remaining_budget'})}</Title>
								<Data small>
									<ValueCell
										noJustify
										cy={'remaining_budget'}
										type={ValueCell.VALUE_TYPE.PRICE}
										currencySymbol={settings.currencySymbol}
										value={projectData?.budget || 0 - totalsData.projectedBillableValueOfService}
									/>
								</Data>
							</Element>
							<Element column>
								<Title>{intl.formatMessage({id: 'common.actual_hours'})}</Title>
								<Data small>
									<ValueCell
										noJustify
										cy={'actual_hours'}
										type={ValueCell.VALUE_TYPE.TIME}
										value={totalsData.actualHours}
									/>
								</Data>
							</Element>
							<Element column>
								<Title>{intl.formatMessage({id: 'common.projected_total_hours'})}</Title>
								<Data small>
									<ValueCell
										noJustify
										cy={'projected_total_hours'}
										type={ValueCell.VALUE_TYPE.TIME}
										value={totalsData.projectedTotalHours}
									/>
								</Data>
							</Element>
							{settings.isBaselineProject && totalsData.baselineHours > 0 ? (
								<>
									<Element column>
										<Title>{intl.formatMessage({id: 'baseline.hours'})}</Title>
										<Data small>
											<ValueCell
												noJustify
												cy={'baseline_hours'}
												type={ValueCell.VALUE_TYPE.TIME}
												value={totalsData.baselineHours}
											/>
										</Data>
									</Element>
									<Element column>
										<Title>{`${intl.formatMessage({id: 'baseline.baseline'})} - ${intl.formatMessage({
											id: 'common.projected_total_hours',
										})}`}</Title>
										<Data small>
											<ValueCell
												noJustify
												cy={'baseline_vs_projected_total_hours'}
												type={ValueCell.VALUE_TYPE.TIME}
												value={totalsData.baselineHours - totalsData.projectedTotalHours}
											/>
										</Data>
									</Element>
								</>
							) : (
								<>
									<Element column>
										<Title>
											{intl.formatMessage({
												id: 'common.actual_billable_hours',
											})}
										</Title>
										<Data small>
											<ValueCell
												noJustify
												cy={'actual_billable_hours'}
												type={ValueCell.VALUE_TYPE.TIME}
												value={totalsData.actualBillableHours}
											/>
										</Data>
									</Element>
									<Element column>
										<Title>
											{intl.formatMessage({
												id: 'common.actual_non_billable_hours',
											})}
										</Title>
										<Data small>
											<ValueCell
												noJustify
												cy={'actual_non_billable_hours'}
												type={ValueCell.VALUE_TYPE.TIME}
												value={totalsData.actualNonBillableHours}
											/>
										</Data>
									</Element>
								</>
							)}
						</>
					) : (
						<>
							<Element column>
								<Title>Start date</Title>
								<Data small>
									<ValueCell noJustify value={totalsData.startDateString} />
								</Data>
							</Element>

							<Element column>
								<Title>End date</Title>
								<Data small>
									<ValueCell noJustify value={totalsData.endDateString} />
								</Data>
							</Element>

							<Element column>
								<Title>{intl.formatMessage({id: 'common.completion'})}</Title>
								<Data small>
									<ValueCell noJustify type={ValueCell.VALUE_TYPE.PERCENTAGE} value={totalsData.progress} />
								</Data>
							</Element>

							{projectTracksRevenue ? (
								<Element column>
									<Title>{intl.formatMessage({id: remapId('project_budget.plan_revenue')})}</Title>
									<Data small>
										<ValueCell
											noJustify
											type={ValueCell.VALUE_TYPE.PRICE}
											value={totalsData.plannedRevenue}
											currencySymbol={settings.currencySymbol}
										/>
									</Data>
								</Element>
							) : null}

							{projectTracksRevenue ? (
								<Element column>
									<Title>
										{intl.formatMessage({id: remapId('project_budget.actual_revenue_no_expenses')})}
									</Title>
									<Data small>
										<ForecastTooltip placement={'bottom'} content={ProjectScopingTotalsTooltips()}>
											<ValueCell
												noJustify
												type={ValueCell.VALUE_TYPE.PRICE}
												value={totalsData.actualRevenue}
												currencySymbol={settings.currencySymbol}
											/>
										</ForecastTooltip>
									</Data>
								</Element>
							) : null}

							<Element column>
								<Title>{intl.formatMessage({id: 'common.estimate'})}</Title>
								<Data small>
									<ForecastTooltip placement={'bottom'} content={EstimateValueTooltip()}>
										<ValueCell
											noJustify
											type={
												totalsData.isEstimatedInHours
													? ValueCell.VALUE_TYPE.TIME
													: ValueCell.VALUE_TYPE.POINTS
											}
											value={totalsData.estimate}
											currencySymbol={settings.currencySymbol}
										/>
									</ForecastTooltip>
								</Data>
							</Element>

							{!ProjectUtil.projectUsesManualProgress(projectData) && (
								<Element column>
									<Title>{intl.formatMessage({id: 'common.time_entries'})}</Title>
									<Data small>
										<ForecastTooltip placement={'bottom'} content={TimeEntriesValueTooltip()}>
											<ValueCell
												noJustify
												type={
													totalsData.isEstimatedInHours
														? ValueCell.VALUE_TYPE.TIME
														: ValueCell.VALUE_TYPE.POINTS
												}
												value={totalsData.timeEntries}
												currencySymbol={settings.currencySymbol}
											/>
										</ForecastTooltip>
									</Data>
								</Element>
							)}

							<Element column>
								<Title>{intl.formatMessage({id: 'common.remaining'})}</Title>
								<Data small>
									<ForecastTooltip placement={'bottom'} content={RemainingValueTooltip()}>
										<ValueCell
											noJustify
											type={
												totalsData.isEstimatedInHours
													? ValueCell.VALUE_TYPE.TIME
													: ValueCell.VALUE_TYPE.POINTS
											}
											value={totalsData.remaining}
											currencySymbol={settings.currencySymbol}
										/>
									</ForecastTooltip>
								</Data>
							</Element>

							<Element column>
								<Title>{intl.formatMessage({id: 'project_scoping.difference_forecast'})}</Title>
								<Data small>
									<ForecastTooltip placement={'bottom'} content={VarianceValueTooltip()}>
										<ValueCell
											noJustify
											type={
												totalsData.isEstimatedInHours
													? ValueCell.VALUE_TYPE.TIME
													: ValueCell.VALUE_TYPE.POINTS
											}
											value={totalsData.difference}
										/>
									</ForecastTooltip>
								</Data>
							</Element>
						</>
					)}
					<IconWrapper>
						<ExpandableIcon isOpen={totalsExpanded} onClick={toggleTotalsExpanded} cy={'expand-project-totals'} />
					</IconWrapper>
				</Row>
			</TotalsLine>
		</GridContainer>
	);
};

export default ProjectScopingTotalsBox;
