import Util from '../../shared/util/util';
import moment from 'moment';
import {getHolidaysOfTheDay, workingHourForTheDay} from './timesheets_person_data';
import {isDateDisabled} from '../../shared/util/DateUtil';

/**
 *
 * @param {*} day
 * @param {*} timeRegistrationsViewer
 * @returns {any[]}
 */
export const getTimeRegistrationsOfTheDay = (day, timeRegistrationsViewer) => {
	return timeRegistrationsViewer.filter(t => {
		const date = Util.CreateNonUtcMomentDate(t.node.year, t.node.month, t.node.day);
		return date.isSame(day, 'day');
	});
};

export const separateWorkingTimeAndTimeOff = timeRegistrations => {
	const [timeOffRegistration, workingTimeRegistration] = timeRegistrations.reduce(
		([timeOff, workingTime], timeReg) =>
			timeReg.node.idleTime && !timeReg.node.idleTime.isInternalTime
				? [[...timeOff, timeReg], workingTime]
				: [timeOff, [...workingTime, timeReg]],
		[[], []]
	);

	const timeOffTotalTime = timeOffRegistration.reduce(
		(totalTimeRegistered, timeReg) => totalTimeRegistered + timeReg.node.minutesRegistered,
		0
	);

	const workingTimeTotalTime = workingTimeRegistration.reduce(
		(totalTimeRegistered, timeReg) => totalTimeRegistered + timeReg.node.minutesRegistered,
		0
	);

	const billableTimeTotalTime = workingTimeRegistration.reduce(
		(totalTimeRegistered, timeReg) => totalTimeRegistered + timeReg.node.billableMinutesRegistered,
		0
	);

	return {
		timeOff: timeOffTotalTime,
		workingTime: workingTimeTotalTime,
		billableTime: billableTimeTotalTime,
	};
};

/*
	Find the time registrations made on the same task/project/idle.
	The matching time registrations are then set to null to reduce the calculation
*/
export const findSameTimeRegistrations = (timeRegistrations, timeReg) => {
	return timeRegistrations.reduce((same, t, index) => {
		if (t && timeReg) {
			if (timeReg.node.task && t.node.task && timeReg.node.task.id === t.node.task.id) {
				timeRegistrations[index] = null;
				return [...same, t];
			} else if (timeReg.node.project && t.node.project && timeReg.node.project.id === t.node.project.id) {
				timeRegistrations[index] = null;
				return [...same, t];
			} else if (timeReg.node.idleTime && t.node.idleTime && timeReg.node.idleTime.id === t.node.idleTime.id) {
				timeRegistrations[index] = null;
				return [...same, t];
			} else {
				return same;
			}
		} else {
			return same;
		}
	}, []);
};

/**
 * Separates billable and non-billable time
 * @param timeRegistrations {Array} of time registrations
 * @returns {{billableMinutes: number, nonBillableMinutes: number}}
 */
function separateBillableAndNonBillableTime(timeRegistrations) {
	return timeRegistrations.reduce(
		(acc, time) => {
			if (time.node.idleTime) {
				acc.nonBillableMinutes += time.node.minutesRegistered;
			} else {
				acc.billableMinutes += time.node.billableMinutesRegistered;
			}
			return acc;
		},
		{billableMinutes: 0, nonBillableMinutes: 0}
	);
}

/**
 * We want to hide a weekend day if the company has "Exclude Weekends" enabled, and there is no time registered on that day
 *
 * @param {Moment} day
 * @param {number} time
 * @returns {boolean}
 */
const shouldHideWeekend = (day, time) => {
	return isDateDisabled(day) && time === 0;
};

/**
 *
 * @param {Moment} weekStart start date of week
 * @param {Moment} weekEnd end date of week
 * @param {object} timeRegistrations
 * @param {object} selectedPerson
 * @returns {object} object contaning members time {number} billableNonBillableSplit {number}, hoursToWork {number} (hours the selected person has to work), isHoliday {boolean}, noOfTimeRegistrations {number}, day {Moment}, hideWeekend {boolean}
 */
export const getTimeAndWorkingHoursForPeriod = (weekStart, weekEnd, timeRegistrations, selectedPerson) => {
	// clone weekstart because we are going to modify it
	const day = moment(weekStart);
	const timeAndWorkingHours = [];
	do {
		const timeRegistrationsForDay = getTimeRegistrationsOfTheDay(day, timeRegistrations);
		const timeWorked = separateWorkingTimeAndTimeOff(timeRegistrationsForDay);
		const billableNonBillableSplit = separateBillableAndNonBillableTime(timeRegistrationsForDay);
		const workingHours = workingHourForTheDay(selectedPerson, day, true);
		const isHoliday = getHolidaysOfTheDay(selectedPerson, day).length > 0;

		// if is holiday: hours to work should be 0, otherwise set it to working hours
		const hoursToWork = isHoliday ? 0 : workingHours;
		const time = timeWorked.workingTime + timeWorked.timeOff;
		timeAndWorkingHours.push({
			time,
			billableNonBillableSplit,
			hoursToWork,
			isHoliday,
			noOfTimeRegistrations: timeRegistrationsForDay.length,
			day: day.clone(),
			hideDay: shouldHideWeekend(day, time),
		});
	} while (!day.add(1, 'days').isAfter(weekEnd, 'day'));
	return timeAndWorkingHours;
};
