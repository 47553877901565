import React from 'react';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';
import {CurrencyRow} from './settings_finance_currency_row';
import {NewCurrency} from './settings_finance_new_currency';
import {HIDDEN_FEATURES} from '../../../constants';
import * as CompanySetupUtils from '../../shared/util/CompanySetupUtil';

export const CurrenciesStyle = styled.div`
	padding-bottom: 33px;
	min-width: 800px;
	table {
		tbody {
			tr {
				td {
					&.column-name,
					&.column-projects {
						cursor: pointer;
					}
				}
			}
		}

		.column-currency {
			width: 35%;
			padding-left: 16px;

			.currency-wrapper {
				display: flex;
				align-items: center;

				.currency-lock {
					margin-left: 8px;
				}
			}
		}

		.column-rate {
			width: 64%;

			.rate-wrapper {
				display: flex;
				align-items: center;

				.rate-input {
					margin-left: 8px;
				}
				.rate-text {
					width: 7rem;
				}
			}
		}

		.column-actions {
			width: 1%;
			text-align: right;
		}
	}
`;

export const Currencies = ({company, exchangeRates}) => {
	return (
		<CurrenciesStyle data-userpilot={'currencies-section'}>
			{!CompanySetupUtils.isFeatureHidden(HIDDEN_FEATURES.REVENUE) && (
				<p className="description">
					<FormattedMessage id="settings_finance.currencies_description" />
				</p>
			)}
			<table>
				<thead>
					<tr>
						<th className="column-currency">{<FormattedMessage id="settings_details.currency_section" />}</th>
						<th className="column-rate">{<FormattedMessage id="common.rate" />}</th>
						<th className="column-actions" />
					</tr>
				</thead>
				<tbody>
					{exchangeRates.map((exchangeRate, index) => (
						<tr key={index}>
							<CurrencyRow exchangeRate={exchangeRate} company={company} isFirstRow={index === 0} />
						</tr>
					))}
				</tbody>
			</table>
			<NewCurrency companyId={company.id} companyCurrency={company.currency} exchangeRates={exchangeRates} />
		</CurrenciesStyle>
	);
};
