import {FlexGroup, Item, ItemDescription, ItemName, ItemWarning} from '../../shared/components/modals/ItemList.styled';
import {WarningIcon} from 'web-components';
import React from 'react';

const LabelEntityWarning = ({
	key,
	cy,
	itemName,
	itemWarning,
	warningIconColor = '#E01212',
	warningIconSize = WarningIcon.SIZE.STANDARD,
	updated,
}) => {
	return (
		<Item key={key} data-cy={cy}>
			<FlexGroup>
				<WarningIcon size={warningIconSize} updated={updated} color={warningIconColor} />
				<ItemDescription>
					{itemName && <ItemName>{itemName}</ItemName>}
					{itemWarning && <ItemWarning>{itemWarning}</ItemWarning>}
				</ItemDescription>
			</FlexGroup>
		</Item>
	);
};

export default LabelEntityWarning;
