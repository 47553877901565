/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PROGRAM_BUDGET_TYPE = "CAPPED" | "FIXED_PRICE" | "UNCAPPED" | "%future added value";
export type useProjectProgramFinancialsQueryVariables = {|
  projectId?: ?string
|};
export type useProjectProgramFinancialsQueryResponse = {|
  +viewer: ?{|
    +component: ?string,
    +psProject: ?{|
      +program: ?string,
      +programBudgetType: ?PROGRAM_BUDGET_TYPE,
      +isProgramRevenueLocked: ?boolean,
      +programRemainingBudget: ?number,
      +psProgram: ?{|
        +id: string,
        +name: ?string,
        +prefix: ?string,
        +budgetType: ?PROGRAM_BUDGET_TYPE,
        +budgetValue: ?number,
        +totalAllocation: ?number,
        +isRevenueLocked: ?boolean,
        +revenueSetting: ?string,
        +members: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +role: ?string,
              +person: ?{|
                +id: string
              |},
            |}
          |}>
        |},
      |},
    |},
  |}
|};
export type useProjectProgramFinancialsQuery = {|
  variables: useProjectProgramFinancialsQueryVariables,
  response: useProjectProgramFinancialsQueryResponse,
|};
*/


/*
query useProjectProgramFinancialsQuery(
  $projectId: String
) {
  viewer {
    component(name: "useProjectProgramFinancials")
    psProject(companyProjectId: $projectId) {
      program
      programBudgetType
      isProgramRevenueLocked
      programRemainingBudget
      psProgram {
        id
        name
        prefix
        budgetType
        budgetValue
        totalAllocation
        isRevenueLocked
        revenueSetting
        members(first: 10000) {
          edges {
            node {
              role
              person {
                id
              }
              id
              __typename
            }
            cursor
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId",
    "type": "String"
  }
],
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "useProjectProgramFinancials"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"useProjectProgramFinancials\")"
},
v2 = [
  {
    "kind": "Variable",
    "name": "companyProjectId",
    "variableName": "projectId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "program",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "programBudgetType",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isProgramRevenueLocked",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "programRemainingBudget",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "prefix",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "budgetType",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "budgetValue",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalAllocation",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isRevenueLocked",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revenueSetting",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "Person",
  "kind": "LinkedField",
  "name": "person",
  "plural": false,
  "selections": [
    (v7/*: any*/)
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v20 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useProjectProgramFinancialsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "ProjectServiceProjectType",
            "kind": "LinkedField",
            "name": "psProject",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProgramType",
                "kind": "LinkedField",
                "name": "psProgram",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  {
                    "alias": "members",
                    "args": null,
                    "concreteType": "ProjectServiceProgramMemberTypeConnection",
                    "kind": "LinkedField",
                    "name": "__Program_members_connection",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectServiceProgramMemberTypeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProjectServiceProgramMemberType",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v15/*: any*/),
                              (v16/*: any*/),
                              (v17/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v18/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v19/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useProjectProgramFinancialsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "ProjectServiceProjectType",
            "kind": "LinkedField",
            "name": "psProject",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProgramType",
                "kind": "LinkedField",
                "name": "psProgram",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  {
                    "alias": null,
                    "args": (v20/*: any*/),
                    "concreteType": "ProjectServiceProgramMemberTypeConnection",
                    "kind": "LinkedField",
                    "name": "members",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectServiceProgramMemberTypeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProjectServiceProgramMemberType",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v15/*: any*/),
                              (v16/*: any*/),
                              (v7/*: any*/),
                              (v17/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v18/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v19/*: any*/)
                    ],
                    "storageKey": "members(first:10000)"
                  },
                  {
                    "alias": null,
                    "args": (v20/*: any*/),
                    "filters": [],
                    "handle": "connection",
                    "key": "Program_members",
                    "kind": "LinkedHandle",
                    "name": "members"
                  }
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "viewer",
            "psProject",
            "psProgram",
            "members"
          ]
        }
      ]
    },
    "name": "useProjectProgramFinancialsQuery",
    "operationKind": "query",
    "text": "query useProjectProgramFinancialsQuery(\n  $projectId: String\n) {\n  viewer {\n    component(name: \"useProjectProgramFinancials\")\n    psProject(companyProjectId: $projectId) {\n      program\n      programBudgetType\n      isProgramRevenueLocked\n      programRemainingBudget\n      psProgram {\n        id\n        name\n        prefix\n        budgetType\n        budgetValue\n        totalAllocation\n        isRevenueLocked\n        revenueSetting\n        members(first: 10000) {\n          edges {\n            node {\n              role\n              person {\n                id\n              }\n              id\n              __typename\n            }\n            cursor\n          }\n          pageInfo {\n            endCursor\n            hasNextPage\n          }\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7148b4d36c0f8278d6b586d6bd954efb';

module.exports = node;
