import React from 'react';
import {HIDDEN_FEATURES, REPORT_GROUPINGS} from '../../../../constants';
import Util from '../../../shared/util/util';
import {DeprecatedProjectIndicatorNoFragment} from '../../../shared/components/project-indicator/DeprecatedProjectIndicator';

export const getRoleName = timeReg => {
	if (timeReg.role) {
		return timeReg.role.name;
	} else {
		return timeReg.person.role ? timeReg.person.role.name : '';
	}
};

export const getClientName = timeReg => {
	if (timeReg.task) {
		if (timeReg.task.project.client) {
			return timeReg.task.project.client.name;
		}
	} else if (timeReg.project) {
		if (timeReg.project.client) {
			return timeReg.project.client.name;
		}
	} else return null;
};

export const getTimeRegTypeAndDetailDom = (timeReg, intl, isSharedView) => {
	if (timeReg.project) {
		const project = timeReg.project;
		return [
			project.fullAccessToProject ? (
				<DeprecatedProjectIndicatorNoFragment
					project={project}
					disableLink={isSharedView || project.readOnlyAccess}
					clearSubPath
					showProjectName
				/>
			) : (
				intl.formatMessage({id: 'timesheets.no_access'})
			),
			<span></span>,
		];
	} else if (timeReg.task) {
		const project = timeReg.task.project;
		return [
			project.fullAccessToProject ? (
				<DeprecatedProjectIndicatorNoFragment
					project={project}
					disableLink={isSharedView || project.readOnlyAccess}
					clearSubPath
					showProjectName
				/>
			) : (
				intl.formatMessage({id: 'timesheets.no_access'})
			),
			project.fullAccessToProject ? timeReg.task.name : ' ',
		];
	} else if (timeReg.idleTime && timeReg.idleTime.isInternalTime === true) {
		return [intl.formatMessage({id: 'common.internal_time'}), timeReg.idleTime.name];
	} else if (timeReg.idleTime && timeReg.idleTime.isInternalTime === false) {
		return [intl.formatMessage({id: 'common.time_off'}), timeReg.idleTime.name];
	}
};

export const getTimeRegTypeAndDetail = (timeReg, intl) => {
	if (timeReg.project) {
		return [
			timeReg.project.fullAccessToProject ? timeReg.project.name : intl.formatMessage({id: 'timesheets.no_access'}),
			'',
			timeReg.project.companyProjectId,
		];
	} else if (timeReg.task) {
		return [
			timeReg.task.project.fullAccessToProject
				? timeReg.task.project.name
				: intl.formatMessage({id: 'timesheets.no_access'}),
			timeReg.task.project.fullAccessToProject ? timeReg.task.name : ' ',
			timeReg.task.project.companyProjectId,
		];
	} else if (timeReg.idleTime && timeReg.idleTime.isInternalTime === true) {
		return [intl.formatMessage({id: 'common.internal_time'}), timeReg.idleTime.name, ''];
	} else if (timeReg.idleTime && timeReg.idleTime.isInternalTime === false) {
		return [intl.formatMessage({id: 'common.time_off'}), timeReg.idleTime.name, ''];
	}
};

export const noGroupingFirstDropdownOptions = intl => {
	return [
		{value: REPORT_GROUPINGS.PERSON, label: intl.formatMessage({id: 'common.group_by_person'})},
		{value: REPORT_GROUPINGS.PROJECT, label: intl.formatMessage({id: 'common.group_by_project'})},
		{value: REPORT_GROUPINGS.TASK, label: intl.formatMessage({id: 'common.group_by_task'})},
		...(!Util.isFeatureHidden(HIDDEN_FEATURES.CLIENT)
			? [{value: REPORT_GROUPINGS.CLIENT, label: intl.formatMessage({id: 'common.group_by_client'})}]
			: []),
		{value: REPORT_GROUPINGS.DEPARTMENT, label: intl.formatMessage({id: 'common.group_by_department'})},
		{value: REPORT_GROUPINGS.ROLE, label: intl.formatMessage({id: 'common.group_by_role'})},
		{value: null, label: intl.formatMessage({id: 'project_sprints.group_by_people_disable'})},
	];
};
