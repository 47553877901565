import React from 'react';
import Styled from 'styled-components';
import { SubNavigation } from 'web-components';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
const NavigatorWrapper = Styled.div `
	margin: 25px 0 -10px;
`;
export const getClientsSubPages = (clientId) => [
    {
        value: `/admin/clients/view-client/${clientId}`,
        label: 'common.client_information',
    },
    {
        value: `/admin/clients/view-client/${clientId}/guest-users`,
        label: 'common.guest_users',
    },
].filter(Boolean);
export const findClientsSubPage = (pathname, clientId) => {
    return getClientsSubPages(clientId).find(subPage => pathname === (subPage === null || subPage === void 0 ? void 0 : subPage.value));
};
const SettingsClientsSubNav = ({ clientId }) => {
    const { formatMessage } = useIntl();
    const { pathname } = useLocation();
    const history = useHistory();
    const activeClientsSubPage = findClientsSubPage(pathname, clientId);
    const handleSetActivePage = (value) => {
        const newPage = getClientsSubPages(clientId).some(option => (option === null || option === void 0 ? void 0 : option.value) === value);
        if ((activeClientsSubPage === null || activeClientsSubPage === void 0 ? void 0 : activeClientsSubPage.value) !== value && newPage) {
            history.push(value);
        }
    };
    return (React.createElement(NavigatorWrapper, null, getClientsSubPages(clientId).map(option => (React.createElement(SubNavigation.NavigationItem, { active: (activeClientsSubPage === null || activeClientsSubPage === void 0 ? void 0 : activeClientsSubPage.value) === (option === null || option === void 0 ? void 0 : option.value), onClick: () => handleSetActivePage(option === null || option === void 0 ? void 0 : option.value), key: option === null || option === void 0 ? void 0 : option.value, cy: 'settings-clients-tabs' + (option === null || option === void 0 ? void 0 : option.value.toLowerCase().replace('/', '-')), userpilot: 'settings-clients-tabs' + (option === null || option === void 0 ? void 0 : option.value.toLowerCase().replace('/', '-')) }, formatMessage({ id: option === null || option === void 0 ? void 0 : option.label }))))));
};
export default SettingsClientsSubNav;
