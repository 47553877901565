import React, {useEffect, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import {graphql} from 'react-relay';
import {hideAll} from 'tippy.js';
import {CSS_CONSTANTS} from '../../../../../css_variables';
import {injectIntl} from 'react-intl';
import HoursInput from '../hours-input/hours_input_view';
import {EVENT_ID, subscribe, unsubscribe} from '../../../../../containers/event_manager';
import {useForecastFetchQuery} from '../../../hooks/useForecastFetchQuery';
import ForecastTooltip, {TooltipColor} from '../../tooltips/ForecastTooltip';
import SuggestedTimeTooltipContent from './SuggestedTimePopupContent';
import {trackEvent} from '../../../../../tracking/amplitude/TrackingV2';
import {convertIntoFloatHoursFormatWithGranularity} from '../hours-input/hours_input_logic';
import {workingHourForTheDay} from '../../../../my-work-tab/my-timesheets-page/timesheets_person_data';
import moment from 'moment';
import LabelWithTooltip from '../../add-time-entry/validation-info/LabelWithTooltip';
import {TimeRegGranularityInfoIcon} from '../../add-time-entry/validation-info/TimeRegGranularityInfo';
import chevronDown from '../../../../../images/v2/components/dropdown/arrow-down.svg';
import chevronUp from '../../../../../images/v2/components/dropdown/arrow-up.svg';
import {isExceedingEstimateAllowed} from '../../../util/cache/TimeRegistrationSettingsUtil';

function getBackgroundImageOrNull(shouldShowSuggestions, popupVisible) {
	if (!shouldShowSuggestions) {
		return null;
	}
	return `background-image: url(${popupVisible ? chevronUp : chevronDown});`;
}

export const InputStyle = styled.div`
	color: ${CSS_CONSTANTS.v2_text_gray};
	font-size: 13px;

	.input-suggestions {
		display: flex;
		align-items: flex-end;

		.title-input-wrapper {
			display: flex;
			flex-direction: column;

			.ai-icon {
				margin-right: 8px;
				opacity: 0.39;
			}

			&.hour-input {
				width: ${props => (props.replicateDesignSystem ? '100%' : '90px')};
			}

			#time-add-hours-input {
				${props => getBackgroundImageOrNull(props.shouldShowSuggestions, props.popupVisible)}
				background-position: right 10px center;
				background-repeat: no-repeat;
				cursor: pointer;
				${props => (props.shouldShowSuggestions ? 'padding-right: 28px;' : '')}
			}

			.icon-title-wrapper {
				display: flex;
				flex-direction: row;
				min-height: 16px;
				font-weight: 500;

				.information-icon {
					height: 11px;
					width: 11px;
					margin-left: 4px;
					margin-bottom: 4px;
					cursor: help;
				}
			}

			.input-title {
				color: ${CSS_CONSTANTS.v2_text_light_gray};
				font-size: 9px;
				text-transform: uppercase;
				margin-bottom: 5px;
				letter-spacing: 1px;
			}

			.tippy-box {
				margin-top: ${props => (props.replicateDesignSystem ? '-7px' : '-12px')};
				margin-left: ${props => (props.replicateDesignSystem ? '0' : '10px')};
				border-top-left-radius: ${props => !props.replicateDesignSystem && '0'};
				border-top-right-radius: ${props => !props.replicateDesignSystem && '0'};
				${props => (props.replicateDesignSystem ? 'width: 160px;' : '')}
				.tippy-content {
					padding: 0;
				}
			}
		}
	}
`;

const suggestionsQuery = graphql`
	query timeRegWSugestionsInputQuery($personId: ID, $dateString: String) {
		viewer {
			component(name: "suggested_time")
			timeSuggestionsRecommender {
				recommendationInstanceId
				recommendationId
				generation
				timeStep
			}
			timeSuggestionsRecommenderTwo {
				recommendationInstanceId
				recommendationId
				generation
				timeStep
			}
			monday
			tuesday
			wednesday
			thursday
			friday
			saturday
			sunday
			actualPersonId
			timeRegistrations(first: 100000, personId: $personId, date: $dateString) {
				edges {
					node {
						id
						day
						month
						year
						minutesRegistered
						person {
							id
						}
					}
				}
			}
		}
	}
`;
const TimeRegWSuggestionsInput = ({
	id,
	onInputChange,
	onChildComponentBlur,
	initialValue,
	date,
	task,
	idleTime,
	innerRef,
	parentKeyDown,
	focusOnMount,
	clearable,
	personId,
	infoText,
	intl,
	userpilot,
	disabled,
	changeOnlyOnValid,
	person,
	onHoursInputFormatted,
	onlyFormatGranularityOnBlur,
	replicateDesignSystem,
	isTimePage,
	width,
}) => {
	const [inputValue, setInputValue] = useState(initialValue);
	const [popupVisible, setPopupVisible] = useState(false);

	const estimateExceeded = useMemo(() => {
		return !isExceedingEstimateAllowed() && !!task && task.totalMinutesRegistered >= task.estimateForecastMinutes;
	}, [task]);

	const {fetch, data} = useForecastFetchQuery(suggestionsQuery);

	const isFetching = useRef(false);
	const shouldShowSuggestions = date && (task || idleTime) && !disabled && !estimateExceeded;

	const handleFetch = variables => {
		const onFetchSuccess = () => {
			isFetching.current = false;
		};

		if (!isFetching.current) {
			isFetching.current = true;
			fetch(variables, onFetchSuccess);
		}
	};

	const fetchSuggestions = e => {
		if (shouldShowSuggestions) {
			handleFetch({personId: personId, dateString: date.format('YYYY-MM-DD')});
		}
		if (replicateDesignSystem) {
			e.select();
		}
	};
	const handleInputChange = value => {
		if (inputValue !== value) {
			setInputValue(value);
			if (onInputChange) onInputChange(value);
		}
	};

	const handleSuggestionClick = (x, value, y) => {
		trackEvent('New Time Entry Suggestion', 'Selected', {selectedSuggestion: value});
		handleInputChange(value);
		hideAll({duration: 0});
		setPopupVisible(false);
	};

	const onInputBlur = (value, e) => {
		setInputValue(value);
		if (onInputChange) onInputChange(value);
		if (onChildComponentBlur) onChildComponentBlur(e);
	};

	const handleParentKeyDown = (e, val) => {
		if (e.key === 'ArrowDown' && !popupVisible) {
			setPopupVisible(true);
			e.preventDefault();
		}
		if (e.key === 'Escape' && popupVisible) {
			setPopupVisible(false);
			e.preventDefault();
			e.stopPropagation();
		}
		if (e.key === 'Tab' && popupVisible) {
			setPopupVisible(false);
		}
		if (popupVisible) return;
		if (parentKeyDown) {
			parentKeyDown(e, val);
		}
	};

	const workingHoursForDay = workingHourForTheDay(person, date || moment());

	useEffect(() => {
		const handleInputClear = () => {
			handleInputChange('');
		};
		if (clearable) {
			subscribe(EVENT_ID.RESET_INPUT, handleInputClear);
		}
		return () => {
			if (clearable) {
				unsubscribe(EVENT_ID.RESET_INPUT, handleInputClear);
			}
		};
	}, [inputValue]);

	return (
		<InputStyle
			replicateDesignSystem={replicateDesignSystem}
			shouldShowSuggestions={shouldShowSuggestions}
			popupVisible={popupVisible}
			width={width}
		>
			<div className="input-suggestions">
				<div className="title-input-wrapper hour-input">
					{replicateDesignSystem ? null : (
						<div className="icon-title-wrapper">
							<div className="input-title">
								<LabelWithTooltip label={'Time entry'} alwaysShowTooltip={true} isTimePage={isTimePage}>
									<TimeRegGranularityInfoIcon />
								</LabelWithTooltip>
							</div>
						</div>
					)}
					<ForecastTooltip
						content={
							<SuggestedTimeTooltipContent
								addTimeSuggestion={handleSuggestionClick}
								popupVisible={popupVisible}
								inputParent={task ? {task} : idleTime ? {idleTime} : null}
								viewer={data?.viewer}
								workingHoursForDay={workingHoursForDay}
								date={date}
								intl={intl}
								replicateDesignSystem={replicateDesignSystem}
							/>
						}
						visible={popupVisible}
						onClickOutside={() => setPopupVisible(false)}
						disabled={!shouldShowSuggestions}
						maxWidth={replicateDesignSystem ? 160 : 90}
						interactive={true}
						delay={[0, 0]}
						duration={[300, 0]}
						spanStyle={{height: '100%', width: '100%'}}
						color={replicateDesignSystem ? TooltipColor.WHITE : TooltipColor.PURPLE}
						arrow={false}
						popperOptions={{
							modifiers: [
								{
									name: 'computeStyles', // the popper will align to the input field itself, giving a 5 px offset that we counter here
									options: {
										roundOffsets: ({x, y}) => ({
											x: Math.round(x - 5),
											y: Math.round(y + 0),
										}),
									},
								},
							],
						}}
					>
						<HoursInput
							customClassName={'hour-input'}
							placeholder={intl.formatMessage({id: 'hours_input.placeholder'})}
							cy={'new-time-entry-input'}
							id={id}
							onClick={() => setPopupVisible(!!shouldShowSuggestions)}
							value={inputValue}
							onFocus={fetchSuggestions}
							mutation={onInputBlur}
							focusOnMount={focusOnMount}
							innerRef={innerRef}
							mutationValidChange={changeOnlyOnValid}
							parentKeyDown={handleParentKeyDown}
							userpilot={userpilot}
							disabled={disabled}
							infoText={infoText}
							replicateDesignSystem={replicateDesignSystem}
							width={width}
							onInputFormatted={onHoursInputFormatted}
							onlyFormatGranularityOnBlur={onlyFormatGranularityOnBlur}
							granularityFormatter={(val, intl, minuteLimit, showAsterisk) =>
								convertIntoFloatHoursFormatWithGranularity(
									val,
									intl,
									minuteLimit,
									showAsterisk,
									workingHoursForDay
								)
							}
							isTimeregInput
						/>
					</ForecastTooltip>
				</div>
			</div>
		</InputStyle>
	);
};

TimeRegWSuggestionsInput.defaultProps = {
	changeOnlyOnValid: true,
};

export default injectIntl(TimeRegWSuggestionsInput);
