/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type settingsDepartments_viewer$ref = any;
export type settingsDepartments_QueryVariables = {||};
export type settingsDepartments_QueryResponse = {|
  +viewer: ?{|
    +availableFeatureFlags: ?$ReadOnlyArray<?{|
      +key: ?string,
      +name: ?string,
    |}>,
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: settingsDepartments_viewer$ref,
  |}
|};
export type settingsDepartments_Query = {|
  variables: settingsDepartments_QueryVariables,
  response: settingsDepartments_QueryResponse,
|};
*/


/*
query settingsDepartments_Query {
  viewer {
    availableFeatureFlags {
      key
      name
      id
    }
    actualPersonId
    component(name: "settings_departments")
    ...settingsDepartments_viewer
    id
  }
}

fragment SettingsResourcesSubNav_viewer on Viewer {
  email
}

fragment settingsDepartments_viewer on Viewer {
  ...SettingsResourcesSubNav_viewer
  availableFeatureFlags {
    key
    name
    id
  }
  company {
    id
    sageIntacctConnectionEnabled
    modules {
      moduleType
      id
    }
    departments(first: 10000) {
      edges {
        node {
          id
          name
          sageIntacctId
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    allPersons(first: 10000, excludeClientUsers: true) {
      edges {
        node {
          id
          fullName
          firstName
          lastName
          email
          costCenter
          profilePictureId
          profilePictureDefaultId
          department {
            id
            name
          }
          role {
            name
            id
          }
          isViewer
          active
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "settings_departments"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"settings_departments\")"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v6 = {
  "kind": "Literal",
  "name": "first",
  "value": 10000
},
v7 = [
  (v6/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "settingsDepartments_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FeatureFlag",
            "kind": "LinkedField",
            "name": "availableFeatureFlags",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "settingsDepartments_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "settingsDepartments_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FeatureFlag",
            "kind": "LinkedField",
            "name": "availableFeatureFlags",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sageIntacctConnectionEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Module",
                "kind": "LinkedField",
                "name": "modules",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "moduleType",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "DepartmentTypeConnection",
                "kind": "LinkedField",
                "name": "departments",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DepartmentTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DepartmentType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "sageIntacctId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "departments(first:10000)"
              },
              {
                "alias": null,
                "args": (v7/*: any*/),
                "filters": [],
                "handle": "connection",
                "key": "Company_departments",
                "kind": "LinkedHandle",
                "name": "departments"
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "excludeClientUsers",
                    "value": true
                  },
                  (v6/*: any*/)
                ],
                "concreteType": "PersonTypeConnection",
                "kind": "LinkedField",
                "name": "allPersons",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PersonTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Person",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fullName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastName",
                            "storageKey": null
                          },
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "costCenter",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "profilePictureId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "profilePictureDefaultId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "DepartmentType",
                            "kind": "LinkedField",
                            "name": "department",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Role",
                            "kind": "LinkedField",
                            "name": "role",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isViewer",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "active",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "allPersons(excludeClientUsers:true,first:10000)"
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "settingsDepartments_Query",
    "operationKind": "query",
    "text": "query settingsDepartments_Query {\n  viewer {\n    availableFeatureFlags {\n      key\n      name\n      id\n    }\n    actualPersonId\n    component(name: \"settings_departments\")\n    ...settingsDepartments_viewer\n    id\n  }\n}\n\nfragment SettingsResourcesSubNav_viewer on Viewer {\n  email\n}\n\nfragment settingsDepartments_viewer on Viewer {\n  ...SettingsResourcesSubNav_viewer\n  availableFeatureFlags {\n    key\n    name\n    id\n  }\n  company {\n    id\n    sageIntacctConnectionEnabled\n    modules {\n      moduleType\n      id\n    }\n    departments(first: 10000) {\n      edges {\n        node {\n          id\n          name\n          sageIntacctId\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    allPersons(first: 10000, excludeClientUsers: true) {\n      edges {\n        node {\n          id\n          fullName\n          firstName\n          lastName\n          email\n          costCenter\n          profilePictureId\n          profilePictureDefaultId\n          department {\n            id\n            name\n          }\n          role {\n            name\n            id\n          }\n          isViewer\n          active\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7854c15cf7bc875616e80e809227c93f';

module.exports = node;
