import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import Util from '../../../../util/util';
import tracking from '../../../../../../tracking';
import GitHubIcon from '../../../../../../images/v2/task-modal/github.svg';
import GitHubObject from './github_object';
import GithubAttachModalRenderer from './github_attach_modal_renderer';
import CreateGithubVerificationKeyMutation from '../../../../../../mutations/create_github_verification_key_mutation';
import ExpandIcon from '../../../../../../images/v2/components/dropdown/expand_icon';
import {MODAL_TYPE, showModal} from '../../../modals/generic_modal_conductor';
import {trackEvent} from '../../../../../../tracking/amplitude/TrackingV2';

class githubSection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: localStorage.getItem('task-modal-v3-github-section-expanded') || false,
		};
	}

	toggleExpansion(e) {
		if (this.state.expanded) {
			localStorage.removeItem('task-modal-v3-github-section-expanded');
		} else {
			Util.localStorageSetItem('task-modal-v3-github-section-expanded', true);
		}
		this.setState({expanded: !this.state.expanded});
	}

	handlePRGitHubKeyPress(e) {
		if (e.key === 'Enter') this.attachPullRequest();
	}

	attachPullRequest() {
		if (this.props.isTaskReadOnly) return;

		showModal({
			type: MODAL_TYPE.GENERIC,
			headerText: this.props.intl.formatMessage({id: 'github.attach_pull_request'}),
			content: <GithubAttachModalRenderer taskId={this.props.viewer.task.companyTaskId.toString()} type="pullrequest" />,
		});
	}

	handleIssueGitHubKeyPress(e) {
		if (e.key === 'Enter') this.attachIssue();
	}

	attachIssue() {
		if (this.props.isTaskReadOnly) return;

		showModal({
			type: MODAL_TYPE.GENERIC,
			headerText: this.props.intl.formatMessage({id: 'github.attach_issue'}),
			content: <GithubAttachModalRenderer taskId={this.props.viewer.task.companyTaskId.toString()} type="issue" />,
		});
	}

	handleGitHubKeyPress(e) {
		if (e.key === 'Enter') this.sendToGithub();
	}

	sendToGithub() {
		const onSuccess = ({createGithubVerificationKey}) => {
			tracking.trackEvent('Github integration enabled');
			trackEvent('Github Integration', 'Enabled');
			const redirectUri = process.env.GITHUB_REDIRECT_URI;
			const clientId = process.env.GITHUB_CLIENT_ID;
			window.location.href = `https://github.com/login/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&state=${createGithubVerificationKey.key}&scope=repo,read:org`;
		};
		Util.CommitMutation(CreateGithubVerificationKeyMutation, {currentUrl: window.location.href}, onSuccess);
	}

	handleKeyDown(e) {
		if (e.key === 'Enter') {
			this.setState({expanded: !this.state.expanded});
		}
	}

	render() {
		const {formatMessage} = this.props.intl;
		const {isClientUser, isTaskReadOnly} = this.props;
		const {githubConnected, company, task} = this.props.viewer;
		const hasGitHubSyncTarget = task?.project.gitHubSyncTarget;

		if (!company.githubEnabled || isClientUser) return null;
		const githubObjects = task?.githubAttachedObjects.edges.map(githubObjectEdge => githubObjectEdge.node);
		const eventCount = githubObjects ? githubObjects.length : 0;

		return (
			<div
				onKeyDown={this.handleKeyDown.bind(this)}
				className="task-modal-v3-github-section"
				data-cy="task-modal-github-section"
				tabIndex={eventCount ? 0 : undefined}
			>
				<div className="github-header-container">
					<div className="github-icon">
						<img src={GitHubIcon} alt={'Github'} />
					</div>
					<div className="github-text dark-grey">
						<span className="bold">GitHub</span> ({eventCount})
					</div>
					<div className={'github-header-right-section' + (isTaskReadOnly ? ' disabled' : '')}>
						{githubConnected ? (
							<>
								<div
									tabIndex="0"
									className={'pull-request dark-grey bold'}
									onClick={this.attachPullRequest.bind(this)}
									onKeyPress={this.handlePRGitHubKeyPress.bind(this)}
								>
									Pull Request
								</div>
								{!hasGitHubSyncTarget ? (
									<div
										tabIndex="0"
										className={'issues dark-grey bold'}
										onClick={!hasGitHubSyncTarget && this.attachIssue.bind(this)}
										onKeyPress={this.handleIssueGitHubKeyPress.bind(this)}
									>
										Issues
									</div>
								) : (
									''
								)}
							</>
						) : (
							<div
								tabIndex="0"
								className="issues dark-grey bold"
								onClick={this.sendToGithub.bind(this)}
								onKeyPress={this.handleGitHubKeyPress.bind(this)}
							>
								{formatMessage({id: 'github.link_github_account'})}
							</div>
						)}
						{githubConnected && eventCount ? (
							<ExpandIcon expanded={this.state.expanded} onClick={this.toggleExpansion.bind(this)} />
						) : null}
					</div>
				</div>
				{githubConnected && eventCount && this.state.expanded ? (
					<div className="github-expanded">
						{this.state.expanded
							? githubObjects.map(githubObject => (
									<GitHubObject
										key={githubObject.id}
										githubObject={githubObject}
										taskId={task && task.id}
										disableActionMenu={hasGitHubSyncTarget}
									/>
							  ))
							: null}
					</div>
				) : null}
			</div>
		);
	}
}

githubSection.propTypes = {
	viewer: PropTypes.object.isRequired,
	isTaskReadOnly: PropTypes.bool.isRequired,
};

const githubSectionQuery = graphql`
	query githubSection_Query($taskId: ID) {
		viewer {
			actualPersonId
			component(name: "task_modal_github_section")
			...githubSection_viewer @arguments(taskId: $taskId)
		}
	}
`;

export {githubSectionQuery};

export default createFragmentContainer(injectIntl(githubSection), {
	viewer: graphql`
		fragment githubSection_viewer on Viewer @argumentDefinitions(taskId: {type: "ID"}) {
			id
			firstName
			githubConnected
			task(id: $taskId) {
				id
				companyTaskId
				githubAttachedObjects(first: 1000001) @connection(key: "Task_githubAttachedObjects") {
					edges {
						node {
							id
							number
							title
							repo
							url
							createdBy
							type
							status
							state
							base
							head
							name
						}
					}
				}
				project {
					gitHubSyncTarget
				}
			}
			company {
				id
				githubEnabled
			}
		}
	`,
});
