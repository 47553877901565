import {FormattedHTMLMessage, useIntl} from 'react-intl';
import TooltipContainer from '../../shared/components/tooltips/tooltip_container';
import InformationIcon from '../../../images/information-icon';
import React from 'react';
import {DeprecatedText as Text, FlexRow} from '@forecast-it/design-system';
import {createFragmentContainer, graphql} from 'react-relay';
import {RadioButton} from 'web-components';
import {Section, SubSection} from '../setup-page/Styles';
import {MODAL_TYPE, showModal} from '../../shared/components/modals/generic_modal_conductor';
import Util from '../../shared/util/util';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import ValidateWeekendVisibilityChangeMutation from '../../../mutations/ValidateWeekendVisibilityChangeMutation';
import moment from 'moment';

const transitionDay = moment('2023-01-23'); // Companies created after this date cannot have per-user weekend visibility

const WeekendVisibility = React.memo(({company, showLoading, hideLoading}) => {
	const {formatMessage} = useIntl();
	const {weekendDisplayPerUser, weekendDisplayShowAlways} = company;
	const createdAt = moment(company.createdAt, 'x');
	const isOldCompany = createdAt.isBefore(transitionDay);

	const displayChangeWeekendSettingModal = (
		weekendDisplayPerUserValue,
		weekendDisplayShowAlwaysValue,
		isCompanyPrevented = null,
		isActiveUserPrevented = null,
		isInactiveUserPrevented = null
	) => {
		showModal({
			type: MODAL_TYPE.CHANGE_COMPANY_WEEKEND_VISIBILITY,
			companyId: company.id,
			weekendDisplayPerUser: weekendDisplayPerUserValue,
			weekendDisplayShowAlways: weekendDisplayShowAlwaysValue,
			previousWeekendDisplayPerUser: weekendDisplayPerUser,
			previousWeekendDisplayShowAlways: weekendDisplayShowAlways,
			isCompanyPrevented,
			isActiveUserPrevented,
			isInactiveUserPrevented,
		});
	};

	const handleWeekendVisibilityChange = (weekendDisplayPerUserValue, weekendDisplayShowAlwaysValue) => {
		const perUserSettingChanged = weekendDisplayPerUser !== weekendDisplayPerUserValue;
		const companySettingChanged = weekendDisplayShowAlways !== weekendDisplayShowAlwaysValue;

		if (perUserSettingChanged || companySettingChanged) {
			const shouldValidateCompany = weekendDisplayShowAlwaysValue === false;

			const onValidationSuccess = response => {
				const {isCompanyPrevented, isActiveUserPrevented, isInactiveUserPrevented} =
					response?.validateWeekendVisibilityChange;

				const isValid = !(isCompanyPrevented || isActiveUserPrevented || isInactiveUserPrevented);

				hideLoading();

				if (!isValid) {
					trackEvent('Weekend Visibility', 'Validation Failed');
				}

				displayChangeWeekendSettingModal(
					weekendDisplayPerUserValue,
					weekendDisplayShowAlwaysValue,
					isCompanyPrevented,
					isActiveUserPrevented,
					isInactiveUserPrevented
				);
			};

			if (shouldValidateCompany) {
				showLoading();
				Util.CommitMutation(
					ValidateWeekendVisibilityChangeMutation,
					{
						weekendDisplayPerUser: weekendDisplayPerUserValue,
						weekendDisplayShowAlways: weekendDisplayShowAlwaysValue,
					},
					response => onValidationSuccess(response),
					true
				);
			} else {
				displayChangeWeekendSettingModal(weekendDisplayPerUserValue, weekendDisplayShowAlwaysValue);
			}
		}
	};

	return (
		<Section>
			<Text type={'heading'} variant={'m'}>
				{formatMessage({id: 'settings_details.weekend_settings'})}
			</Text>
			<Text>{formatMessage({id: 'settings_details.weekend_settings_description'})}</Text>
			<SubSection>
				<FlexRow>
					<RadioButton
						checked={weekendDisplayShowAlways === true}
						onClick={() => handleWeekendVisibilityChange(false, true)}
					/>
					<Text>
						<FormattedHTMLMessage id="settings_details.company_control_show_weekends" />
					</Text>
					<TooltipContainer
						infoText={[
							{
								unstyledList: [
									formatMessage(
										{
											id: 'settings_details.company_control_show_weekends_weekends_not_counted_tooltip',
										},
										{b: b => <b>{b}</b>}
									),
								],
								noBox: true,
							},
						]}
						tooltipInfinteDuration={true}
						edgeOffset={350}
					>
						<InformationIcon />
					</TooltipContainer>
				</FlexRow>
				<FlexRow>
					<RadioButton
						checked={weekendDisplayShowAlways === false}
						onClick={() => handleWeekendVisibilityChange(false, false)}
					/>
					<Text>
						<FormattedHTMLMessage id="settings_details.company_control_hide_weekends" />
					</Text>
					<TooltipContainer
						infoText={[
							{
								unstyledList: [
									formatMessage({
										id: 'settings_details.company_control_hide_weekends_not_possible_to_select_weekends_tooltip',
									}),
								],
								noBox: true,
							},
						]}
						tooltipInfinteDuration={true}
						edgeOffset={350}
					>
						<InformationIcon />
					</TooltipContainer>
				</FlexRow>
				{(isOldCompany || weekendDisplayPerUser) && (
					<FlexRow>
						<RadioButton
							checked={weekendDisplayPerUser}
							onClick={() => handleWeekendVisibilityChange(true, null)}
						/>
						<Text>
							<FormattedHTMLMessage id="settings_details.company_weekends_as_eye_option" />
						</Text>

						<TooltipContainer
							infoText={[
								{
									unstyledList: [
										formatMessage(
											{
												id: 'settings_details.weekend_visibility_individual_user_visibility_tooltip',
											},
											{b: b => <b>{b}</b>}
										),
									],
									noBox: true,
								},
							]}
							tooltipInfinteDuration={true}
							edgeOffset={350}
						>
							<InformationIcon />
						</TooltipContainer>
					</FlexRow>
				)}
			</SubSection>
		</Section>
	);
});

export default createFragmentContainer(WeekendVisibility, {
	company: graphql`
		fragment WeekendVisibility_company on Company {
			weekendDisplayPerUser
			weekendDisplayShowAlways
			createdAt
		}
	`,
});
