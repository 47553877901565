function withPrefix(companyProjectId) {
    if (!companyProjectId)
        return '';
    return `P${companyProjectId}`;
}
export const useProjectIndicatorViewController = (project) => {
    if (!project) {
        return null;
    }
    const workId = project.customProjectId ? project.customProjectId : withPrefix(project.companyProjectId);
    return { workId, name: project.name || undefined, color: project.projectColor || '' };
};
