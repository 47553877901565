import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import Util from '../../shared/util/util';
import {Link} from 'react-router-dom';
import {Checkbox} from 'web-components';
import PeoplePerson from './PeoplePerson';
import {useSettingsPeopleContext} from './SettingsPeopleContext';

const PeoplePersonGroup = ({
	hasFinancial,
	viewer,
	integrationsEnabled,
	theEyeOptions,
	initialShowMembers,
	groupMembers,
	sortByColumn,
	sortBy,
	company,
	changeSortBy,
	roles,
	departments,
	profiles,
	groupName,
	seatsInfo,
	manageSubscriptions,
}) => {
	const {formatMessage} = useIntl();

	const [showMembers, setShowMembers] = useState(initialShowMembers);

	const {isChargebeeCustomer, modules, isCoreTier} = company;

	const {setPersonsInGroup, isSelectedGroup, toggleSelectedGroup} = useSettingsPeopleContext();

	useEffect(() => {
		setPersonsInGroup(groupName, groupMembers);
	}, [groupMembers]);

	return (
		<fieldset className="people group-container">
			<div className={'top-container'}>
				<div data-userpilot={'people-users-header'} className={'group-header'}>
					<h2>
						{`${groupName}`}
						<span data-cy="number-of-users" className={'seats-info'}>
							{seatsInfo}
						</span>
					</h2>
				</div>
				<div className="controls controls-new">
					<div
						className={'expand-control' + (showMembers ? ' expand' : ' collapse')}
						onClick={() => setShowMembers(prev => !prev)}
					/>
				</div>
				{isChargebeeCustomer && manageSubscriptions && !isCoreTier ? (
					<Link className={'manage-subscription-link subscription-link'} to={'/settings/subscription'}>
						{formatMessage({id: 'team_modal.manage_subscription'})}
					</Link>
				) : null}
			</div>

			{showMembers ? (
				<table>
					<colgroup>
						<col className="column-person" />
						<col className="column-role" />
						{Util.hasDepartments(modules) ? <col className="column-department" /> : null}
						<col className="column-permissions" />
						<col className="column-cost" />
						<col className="column-date" />
						<col className="column-date" />
						{theEyeOptions.map(option => (option.checked ? <col className={'column-' + option.name} /> : null))}
						{integrationsEnabled ? <col className="column-controls" /> : null}
						<col className="column-controls" />
					</colgroup>
					<thead>
						<tr>
							<th className={'selection'}>
								<Checkbox
									disabled={false}
									checked={isSelectedGroup(groupName)}
									onClick={() => toggleSelectedGroup(groupName)}
									cy={'selector-checkbox'}
									userpilot={'bulk-update-checkbox'}
								/>
							</th>
							<th
								className={`column-person person-width sortable ${
									sortByColumn === 'name' ? (sortBy.ascending ? 'ascending' : 'descending') : null
								}`}
								onClick={() => changeSortBy('name')}
							>
								{formatMessage({id: 'common.name'})}
								<span>&nbsp;&nbsp;</span>
							</th>
							<th
								className={`column-role role-width role-width-header sortable ${
									sortByColumn === 'role' ? (sortBy.ascending ? 'ascending' : 'descending') : null
								}`}
								onClick={() => changeSortBy('role')}
							>
								{formatMessage({id: 'common.role'})}
								<span>&nbsp;&nbsp;</span>
							</th>
							{Util.hasSkills() && <th>{formatMessage({id: 'common.skills'})}</th>}
							{Util.hasDepartments(modules) ? (
								<th
									className={`column-department department-width department-width-header sortable ${
										sortByColumn === 'department' ? (sortBy.ascending ? 'ascending' : 'descending') : null
									}`}
									onClick={() => changeSortBy('department')}
								>
									{formatMessage({id: 'common.department'})}
									<span>&nbsp;&nbsp;</span>
								</th>
							) : null}
							<th
								className={`column-permissions permissions-width permissions-width-header sortable ${
									sortByColumn === 'permission' ? (sortBy.ascending ? 'ascending' : 'descending') : null
								}`}
								onClick={() => changeSortBy('permission')}
							>
								{formatMessage({id: 'settings.permission_profile'})}
								<span>&nbsp;&nbsp;</span>
							</th>
							{hasFinancial ? (
								<th className="column-cost cost-width cost-width-header">
									{formatMessage({id: 'settings_people.internal_hourly_cost'})}
								</th>
							) : null}
							<th
								className={`column-date date-width date-width-header sortable ${
									sortByColumn === 'startDate' ? (sortBy.ascending ? 'ascending' : 'descending') : null
								}`}
								onClick={() => changeSortBy('startDate')}
								data-userpilot={'people-users-start-date'}
							>
								{formatMessage({id: 'common.start_date'})}
								<span>&nbsp;&nbsp;</span>
							</th>
							<th
								className={`column-date date-width date-width-header sortable ${
									sortByColumn === 'endDate' ? (sortBy.ascending ? 'ascending' : 'descending') : null
								}`}
								onClick={() => changeSortBy('endDate')}
							>
								{formatMessage({id: 'common.end_date'})}
								<span>&nbsp;&nbsp;</span>
							</th>
							{theEyeOptions.map(option =>
								option.checked ? (
									<th
										className={
											'column-' +
											option.name +
											(option.sortable &&
												` sortable ${
													sortByColumn === option.name
														? sortBy.ascending
															? 'ascending'
															: 'descending'
														: null
												}`)
										}
										onClick={() => changeSortBy(option.name)}
									>
										{formatMessage({id: option.translationId})}
										{option.sortable && <span>&nbsp;&nbsp;</span>}
									</th>
								) : null
							)}
							{integrationsEnabled ? (
								<th className="column-controls">
									{formatMessage({id: 'settings_account.section-integrations'})}
								</th>
							) : null}
							<th
								className={`column-seat seat-width seat-width-header sortable ${
									sortByColumn === 'seat' ? (sortBy.ascending ? 'ascending' : 'descending') : null
								}`}
								onClick={() => changeSortBy('seat')}
							>
								{formatMessage({id: 'common.seat_type'})}
								<span>&nbsp;&nbsp;</span>
							</th>
							<th
								className={`column-status status-width status-width-header sortable ${
									sortByColumn === 'status' ? (sortBy.ascending ? 'ascending' : 'descending') : null
								}`}
								onClick={() => changeSortBy('status')}
							>
								{formatMessage({id: 'common.status'})}
								<span>&nbsp;&nbsp;</span>
							</th>
							<th className={`column-actions actions-width actions-width-header`}></th>
						</tr>
					</thead>

					<tbody>
						{groupMembers.map((person, index) => (
							<PeoplePerson
								firstUser={index === 0}
								hasFinancial={hasFinancial}
								person={person}
								viewer={viewer}
								integrationsEnabled={integrationsEnabled}
								theEyeOptions={theEyeOptions}
								roles={roles}
								departments={departments}
								profiles={profiles}
								key={`${groupName}_${person.id}`}
							/>
						))}
					</tbody>
				</table>
			) : null}
		</fieldset>
	);
};

PeoplePersonGroup.propTypes = {};

export default PeoplePersonGroup;
