import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import gcalendarLogo from '../../../../images/integrations/gcalendar-logo.png';
import outlookLogo from '../../../../images/integrations/outlook-logo.jpg';
import appleLogo from '../../../../images/integrations/apple-calendar-logo.png';
import {TIERS} from '../../../../constants';
import {IconWrapper} from './app_catalog_application_styles';
import {hasModule} from '../../../shared/util/ModuleUtil';
import {hasFeatureFlag} from '../../../shared/util/FeatureUtil';

class AppCatalogApplication extends React.Component {
	getTiersMessage(tiers) {
		switch (tiers) {
			case TIERS.TRIAL:
				return <FormattedMessage id={'common.trial'} />;
			case TIERS.LITE:
				return <FormattedMessage id={'select_plan.lite_plan_name'} />;
			case TIERS.PRO:
				return <FormattedMessage id={'select_plan.pro_plan_name'} />;
			case TIERS.CORE:
				return <FormattedMessage id={'select_plan.core_plan_name'} />;
			case TIERS.ENTERPRISE:
				return hasFeatureFlag('core_as_required_tier') ? (
					<FormattedMessage id={'select_plan.core_plan_name'} />
				) : (
					<FormattedMessage id={'select_plan.enterprise_plan_name'} />
				);
			default:
				break;
		}
	}

	render() {
		const path = this.props.path.replace('//', '/');

		const availableInTiers = [];
		switch (this.props.requiredTier) {
			case TIERS.LITE:
				// Available in all tiers
				availableInTiers.push(TIERS.TRIAL, TIERS.LITE, TIERS.PRO, TIERS.ENTERPRISE, TIERS.CORE, TIERS.FREE_FOREVER);
				break;
			case TIERS.PRO:
				// Available in Pro and above
				availableInTiers.push(TIERS.TRIAL, TIERS.PRO, TIERS.ENTERPRISE, TIERS.CORE, TIERS.FREE_FOREVER);
				break;
			case TIERS.ENTERPRISE:
				// Only available in Enterprise and core
				availableInTiers.push(TIERS.TRIAL, TIERS.ENTERPRISE, TIERS.CORE, TIERS.FREE_FOREVER);
				break;
			default:
				// Not available in any tier
				// eslint-disable-next-line no-console
				console.warn(`${this.props.title} integration hasn't been set up with requiredTier`);
				break;
		}
		const hasAccessThroughTier = availableInTiers.indexOf(this.props.tier) > -1;

		let hasAccessThroughModule = false;
		if (this.props.requiredModuleType) {
			hasAccessThroughModule = hasModule(this.props.requiredModuleType);
		}

		const hasAccessToApp = hasAccessThroughTier || hasAccessThroughModule;

		if (this.props.available && hasAccessToApp) {
			if (this.props.title === 'Calendar') {
				return (
					<Link to={path}>
						<li className="integration available">
							<div>
								<img width={47} height={47} alt="gcalendar logo" src={gcalendarLogo} />
								<img width={47} height={47} alt="outlook logo" src={outlookLogo} />
							</div>
							<img width={47} height={47} alt="apple logo" src={appleLogo} />
							<h3 className="title">{this.props.title}</h3>
							<div className="description">
								<FormattedMessage id={this.props.descriptionId} />
							</div>
						</li>
					</Link>
				);
			} else {
				return (
					<Link to={path}>
						<li className="integration available">
							{this.props.webCompLogo ? (
								<IconWrapper>{this.props.webCompLogo}</IconWrapper>
							) : (
								<img width={100} height={100} alt={this.props.alt} src={this.props.logoSrc} />
							)}
							<h3 className="title">{this.props.title}</h3>
							<div className="description">
								<FormattedMessage id={this.props.descriptionId} />
							</div>
						</li>
					</Link>
				);
			}
		} else {
			if (this.props.title === 'Calendar') {
				return (
					<li className="integration">
						<div>
							<img width={47} height={47} alt="gcalendar logo" src={gcalendarLogo} />
							<img width={47} height={47} alt="outlook logo" src={outlookLogo} />
						</div>
						<img width={47} height={47} alt="apple logo" src={appleLogo} />
						{this.props.available ? null : (
							<div className="under-construction">
								<FormattedMessage id={'common.coming_soon'} />
							</div>
						)}
						<h3 className="title">{this.props.title}</h3>
						<div className="description">
							<FormattedMessage id={this.props.descriptionId} />
						</div>
					</li>
				);
			} else {
				return (
					<li className="integration">
						{this.props.webCompLogo ? (
							<IconWrapper>{this.props.webCompLogo}</IconWrapper>
						) : (
							<img width={100} height={100} alt={this.props.alt} src={this.props.logoSrc} />
						)}
						{this.props.available ? (
							hasAccessToApp ? null : (
								<div className="under-construction">
									Requires {this.getTiersMessage(this.props.requiredTier)}
								</div>
							)
						) : (
							<div className="under-construction">
								<FormattedMessage id={'common.coming_soon'} />
							</div>
						)}
						<h3 className="title">{this.props.title}</h3>
						<div className="description">
							<FormattedMessage id={this.props.descriptionId} />
						</div>
					</li>
				);
			}
		}
	}
}
export default AppCatalogApplication;
