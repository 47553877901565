import React from 'react';
import ForecastQueryRenderer from '../../../../../../../../ForecastQueryRenderer';
import SimilarTaskSection, {SimilarTaskSectionQuery} from './SimilarTaskSection';
import {SimilarTaskSectionLoader} from './SimilarTaskSectionLoader';

export const SimilarTaskSectionQueryWrapper = ({taskId}) => {
	return (
		<ForecastQueryRenderer
			key="query-render-reportedTimeTable"
			query={SimilarTaskSectionQuery}
			variables={{
				taskId: taskId,
			}}
			customLoader={() => <SimilarTaskSectionLoader />}
			render={relayProps => {
				return <SimilarTaskSection {...relayProps} relayProps={relayProps} />;
			}}
		/>
	);
};
