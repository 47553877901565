import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {convertToPercentageFormat} from '../../../../../components/inputs/PercentageInputLogic';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../../../constants';
import {PopupContainer} from './PopupStyle';
import {InputStyle} from '../../inputs/time-registration-input/time_reg_w_sugestions_input';
import {PercentageInput} from '../../../../../components/inputs/PercentageInput';
import {ProgressSuggestion, ProgressSuggestionWrapper} from './ProgressSuggestion';
import Button from '../../buttons/button/button';

export const ProgressRegistration = ({
	currentProgressValue,
	progressChangedCallback,
	close,
	isTaskModal,
	isPopup,
	showTitle,
	showButtons,
	closeOnValueClicked,
}) => {
	const intl = useIntl();
	const [inputValue, setInputValue] = useState(currentProgressValue);
	const [selectedIndex, setSelectedIndex] = useState();
	let inputRef;

	// Focus input on mount
	useEffect(() => {
		if (inputRef) {
			inputRef.focus();
		}
	}, []);

	const handleCancelButtonEnterPress = e => {
		if (e.key === 'Enter') close();
	};

	const handleCancelButtonPress = () => {
		close();
	};

	function saveProgress(inputValue, selectedIndex) {
		setSelectedIndex(selectedIndex);
		const validValue = convertToPercentageFormat(inputValue, intl);
		if (!validValue.error) {
			setInputValue(validValue.float * 100);
			if (progressChangedCallback) {
				progressChangedCallback(validValue.float);
			}
			if (closeOnValueClicked) {
				close(inputValue);
			}
		}
	}

	const handleSaveButtonPress = () => {
		saveProgress(inputValue);
	};

	const handleSaveButtonEnterPress = e => {
		if (e.key === 'Enter') {
			saveProgress(inputValue);
		}
	};

	const handleInputChange = value => {
		const sanitizedValue = value ? parseInt(value.replace(/\D/g, '')) : 0;
		if (inputValue !== sanitizedValue) {
			setInputValue(sanitizedValue);
			if (!closeOnValueClicked) {
				saveProgress(sanitizedValue);
			}
		}
	};

	const options = [25, 50, 75, 100];

	return (
		<PopupContainer isTaskModal={isTaskModal}>
			<div className={isPopup ? 'popup-wrapper' : ''}>
				{showTitle ? (
					<div className="entry-wrapper">
						<div className="title">{intl.formatMessage({id: 'common.update-progress'})}</div>
					</div>
				) : (
					<></>
				)}

				<div className="entry-wrapper">
					<InputStyle>
						<div className="input-suggestions">
							<div className="title-input-wrapper hour-input">
								<div className="icon-title-wrapper">
									<div className="input-title">{intl.formatMessage({id: 'common.progress'})}</div>
								</div>
								<PercentageInput
									intl={intl}
									value={inputValue}
									mutation={handleInputChange}
									innerRef={div => (inputRef = div)}
									customClassName={'hour-input'}
								/>
							</div>
							<div className="title-input-wrapper">
								<ProgressSuggestionWrapper>
									{options.map((option, index) => {
										return (
											<ProgressSuggestion
												option={option}
												selected={index === selectedIndex || option === inputValue}
												onClick={() => saveProgress(option, index)}
												key={index}
											/>
										);
									})}
								</ProgressSuggestionWrapper>
							</div>
						</div>
					</InputStyle>
				</div>

				{showButtons ? (
					<div className="buttons">
						<Button
							text={intl.formatMessage({id: 'common.cancel'})}
							buttonStyle={BUTTON_STYLE.FILLED}
							colorTheme={BUTTON_COLOR.WHITE}
							onKeyDown={handleCancelButtonEnterPress}
							onClick={handleCancelButtonPress}
							focusOnMouseDown={true} // need to focus on the button when clicking down to trigger the blur effect on the input to tranform string to hours amount
							focusOnClick={true}
						/>
						<Button
							text={intl.formatMessage({id: 'common.save'})}
							buttonStyle={BUTTON_STYLE.FILLED}
							colorTheme={BUTTON_COLOR.GREEN}
							onClick={handleSaveButtonPress}
							onKeyDown={handleSaveButtonEnterPress}
							focusOnMouseDown={true}
							focusOnClick={true}
							cy="progress-modal-save-button"
							isDisabled={convertToPercentageFormat(inputValue, intl).error}
						/>
					</div>
				) : (
					<></>
				)}
			</div>
		</PopupContainer>
	);
};
