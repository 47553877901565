/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
query ProjectDropdown_Query {
  viewer {
    actualPersonId
    component(name: "project_dropdown")
    ...ProjectDropdown_viewer_1Bmzm5
    id
  }
}

fragment ProjectDropdown_viewer_1Bmzm5 on Viewer {
  projects(first: 10000, excludeGroupProjects: true, excludeRestricted: true) {
    edges {
      node {
        id
        projectColor
        status
        companyProjectId
        customProjectId
        projectGroupId
        name
      }
    }
  }
  projectGroups(first: 10000) {
    edges {
      node {
        id
        companyProjectGroupId
        name
        color
        projects(first: 10000) {
          edges {
            node {
              id
              projectColor
              status
              companyProjectId
              customProjectId
              projectGroupId
              name
            }
          }
        }
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "id",
            "type": "String"
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actualPersonId",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": [
            {
                "kind": "Literal",
                "name": "name",
                "value": "project_dropdown"
            }
        ],
        "kind": "ScalarField",
        "name": "component",
        "storageKey": "component(name:\"project_dropdown\")"
    }, v3 = {
        "kind": "Literal",
        "name": "first",
        "value": 10000
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v6 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "ProjectTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v4 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "projectColor",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "companyProjectId",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "customProjectId",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "projectGroupId",
                            "storageKey": null
                        },
                        (v5 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ], v7 = [
        (v3 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ProjectDropdown_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "id",
                                    "variableName": "id"
                                }
                            ],
                            "kind": "FragmentSpread",
                            "name": "ProjectDropdown_viewer"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ProjectDropdown_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": [
                                {
                                    "kind": "Literal",
                                    "name": "excludeGroupProjects",
                                    "value": true
                                },
                                {
                                    "kind": "Literal",
                                    "name": "excludeRestricted",
                                    "value": true
                                },
                                (v3 /*: any*/)
                            ],
                            "concreteType": "ProjectTypeConnection",
                            "kind": "LinkedField",
                            "name": "projects",
                            "plural": false,
                            "selections": (v6 /*: any*/),
                            "storageKey": "projects(excludeGroupProjects:true,excludeRestricted:true,first:10000)"
                        },
                        {
                            "alias": null,
                            "args": (v7 /*: any*/),
                            "concreteType": "ProjectGroupTypeConnection",
                            "kind": "LinkedField",
                            "name": "projectGroups",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ProjectGroupTypeEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ProjectGroupType",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                                (v4 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "companyProjectGroupId",
                                                    "storageKey": null
                                                },
                                                (v5 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "color",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": (v7 /*: any*/),
                                                    "concreteType": "ProjectTypeConnection",
                                                    "kind": "LinkedField",
                                                    "name": "projects",
                                                    "plural": false,
                                                    "selections": (v6 /*: any*/),
                                                    "storageKey": "projects(first:10000)"
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "__typename",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "cursor",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PageInfo",
                                    "kind": "LinkedField",
                                    "name": "pageInfo",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "endCursor",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "hasNextPage",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": "projectGroups(first:10000)"
                        },
                        {
                            "alias": null,
                            "args": (v7 /*: any*/),
                            "filters": [],
                            "handle": "connection",
                            "key": "Viewer_projectGroups",
                            "kind": "LinkedHandle",
                            "name": "projectGroups"
                        },
                        (v4 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "ProjectDropdown_Query",
            "operationKind": "query",
            "text": "query ProjectDropdown_Query {\n  viewer {\n    actualPersonId\n    component(name: \"project_dropdown\")\n    ...ProjectDropdown_viewer_1Bmzm5\n    id\n  }\n}\n\nfragment ProjectDropdown_viewer_1Bmzm5 on Viewer {\n  projects(first: 10000, excludeGroupProjects: true, excludeRestricted: true) {\n    edges {\n      node {\n        id\n        projectColor\n        status\n        companyProjectId\n        customProjectId\n        projectGroupId\n        name\n      }\n    }\n  }\n  projectGroups(first: 10000) {\n    edges {\n      node {\n        id\n        companyProjectGroupId\n        name\n        color\n        projects(first: 10000) {\n          edges {\n            node {\n              id\n              projectColor\n              status\n              companyProjectId\n              customProjectId\n              projectGroupId\n              name\n            }\n          }\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
        }
    };
})();
node.hash = '9f68ea1e6e77d1098c77e3868ec0facb';
export default node;
