import React from 'react';

const HexagonAddIcon = props => (
	<svg onClick={props.onClick ? props.onClick : null} className={'hex-add-icon ' + (props.className ? props.className : '')} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 19 22" data-cy={props.cy}>
		<g fill="none" fillRule="evenodd" stroke="#05E298" strokeWidth="2">
			<path d="M9.308 1.162L1 6.07v9.858l8.308 4.91 8.307-4.91V6.071L9.308 1.16z" />
			<path fillRule="nonzero" d="M9.31 6.813v8.374M13.62 11H5h8.62z" />
		</g>
	</svg>
);

export default HexagonAddIcon;
