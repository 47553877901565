export const hasMLModel = mlModel => {
	const availableMLModels = sessionStorage.getItem('availableMLModels')
		? JSON.parse(sessionStorage.getItem('availableMLModels'))
		: {};

	return availableMLModels[mlModel];
};

export const setAvailableMLModels = availableMLModels => {
	sessionStorage.setItem('availableMLModels', JSON.stringify(availableMLModels));
};
