import React from 'react';
import styled from 'styled-components';
import { Modal } from '@forecast-it/design-system';
const LoaderStyle = styled.div `
	&:after {
		content: '...';
		overflow: hidden;
		display: inline-block;
		vertical-align: bottom;
		animation: ellipsis steps(4, end) 900ms infinite;
		width: 0px;
	}

	@keyframes ellipsis {
		to {
			width: 1.25em;
		}
	}
`;
export const TimesheetsCopyLastWeekModalLoader = () => {
    return (React.createElement(Modal.Content, null,
        React.createElement(LoaderStyle, null, "Copying your previous time registrations")));
};
