import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {CSS_CONSTANTS} from '../../../css_variables';
import {createToast} from '../../shared/components/toasts/toast';
import Util from '../../shared/util/util';
import UpdateCompanyMutation from '../../../mutations/update_company_mutation.modern';
import {BudgetThresholdsUpdateForm} from '../../shared/components/BudgetThresholdsUpdateForm';
import {ForecastSurplusModalChartMock} from '../../../containers/project/project_health/modals/forecast_surplus_modal/ForecastSurplusModalChartMock';

const SettingsWrapper = styled.div`
	border: 1px solid ${CSS_CONSTANTS.v2_border_gray};
	border-radius: 4px;
	padding: 16px 24px;
	width: 566px;
`;

const Title = styled.div`
	font-size: 16px;
	color: ${CSS_CONSTANTS.v2_text_gray};
	font-weight: 500;
	margin-bottom: 16px;
`;

const SubTitle = styled.div`
	font-size: 13px;
	color: ${CSS_CONSTANTS.v2_text_gray};
	margin-bottom: 16px;
`;

const ChartTitle = styled.div`
	font-size: 13px;
	color: ${CSS_CONSTANTS.v2_text_gray};
	margin: 16px 0 8px;
`;

const ChartWrapper = styled.div`
	height: 380px;
	border: 1px solid ${CSS_CONSTANTS.v2_border_gray};
	border-radius: 4px;
	padding: 16px;
`;

export const BudgetTolerance = ({company}) => {
	const updateProjectRevenueMargin = (revenueMarginLow, revenueMarginHigh) => {
		Util.CommitMutation(
			UpdateCompanyMutation,
			{id: company.id, defaultRevenueMarginLow: revenueMarginLow, defaultRevenueMarginHigh: revenueMarginHigh},
			() => createToast({duration: 3000, message: 'Default revenue thresholds updated'})
		);
	};

	const today = moment();
	const oneMonthAgo = today.clone().subtract(1, 'months');
	const oneMonthFromToday = today.clone().add(1, 'months');

	const currencySymbol = Util.GetCurrencySymbol(company.currency);

	return (
		<SettingsWrapper>
			<Title>Set Budget Tolerance</Title>
			<SubTitle>
				Use this page to set a default company-wide budget tolerance. Projects that already have a budget tolerance set
				up will not be affected.
			</SubTitle>
			<SubTitle>
				This is an experimental setting that only affects Nova Insights. It will not impact your other financial
				reporting, e.g. revenue or profit.
			</SubTitle>
			<BudgetThresholdsUpdateForm
				revenueMarginLow={company.defaultRevenueMarginLow}
				revenueMarginHigh={company.defaultRevenueMarginHigh}
				callback={updateProjectRevenueMargin}
			/>
			<ChartTitle>
				{`As an example, this is how a project with a Fixed Price Budget of ${Util.getFormattedCurrencyValue(
					currencySymbol,
					100000
				)} and your set tolerance would look`}
			</ChartTitle>
			<ChartWrapper>
				<ForecastSurplusModalChartMock
					fixedPrice={100000}
					startDate={oneMonthAgo}
					endDate={oneMonthFromToday}
					currencySymbol={currencySymbol}
					revenueMarginLow={company.defaultRevenueMarginLow}
					revenueMarginHigh={company.defaultRevenueMarginHigh}
				/>
			</ChartWrapper>
		</SettingsWrapper>
	);
};
