/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type STATUS_CATEGORY = "DONE" | "INPROGRESS" | "TODO" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type DependencySectionQueryContent_viewer$ref: FragmentReference;
declare export opaque type DependencySectionQueryContent_viewer$fragmentType: DependencySectionQueryContent_viewer$ref;
export type DependencySectionQueryContent_viewer = {|
  +dependencyTask: ?{|
    +project: ?{|
      +tasks: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
            +companyTaskId: ?number,
            +approved: ?boolean,
            +statusColumnV2: ?{|
              +id: string,
              +name: ?string,
              +category: ?STATUS_CATEGORY,
              +projectGroupStatusColumnId: ?number,
            |},
          |}
        |}>
      |}
    |}
  |},
  +$refType: DependencySectionQueryContent_viewer$ref,
|};
export type DependencySectionQueryContent_viewer$data = DependencySectionQueryContent_viewer;
export type DependencySectionQueryContent_viewer$key = {
  +$data?: DependencySectionQueryContent_viewer$data,
  +$fragmentRefs: DependencySectionQueryContent_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "id",
      "type": "String"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "DependencySectionQueryContent_viewer",
  "selections": [
    {
      "alias": "dependencyTask",
      "args": [
        {
          "kind": "Variable",
          "name": "companyTaskId",
          "variableName": "id"
        }
      ],
      "concreteType": "Task",
      "kind": "LinkedField",
      "name": "task",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectType",
          "kind": "LinkedField",
          "name": "project",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "first",
                  "value": 100000
                }
              ],
              "concreteType": "TaskTypeConnection",
              "kind": "LinkedField",
              "name": "tasks",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TaskTypeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Task",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "companyTaskId",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "approved",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "StatusColumnV2",
                          "kind": "LinkedField",
                          "name": "statusColumnV2",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "category",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "projectGroupStatusColumnId",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "tasks(first:100000)"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '80972084353f3a683d0a2d5f32e515f9';

module.exports = node;
