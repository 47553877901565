import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {useSharedReportLoginContext} from './SharedReportLoginContext';
import Button from '../../../shared/components/buttons/button/button';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../../constants';
import Util from '../../../shared/util/util';
import SharedReportChangePasswordMutation from '../../../../mutations/shared_report_change_password_mutation';
import SharedReportLoginMutation from '../../../../mutations/shared_report_login_mutation';
import {isSageSpecificUrl} from '../../../shared/util/UrlUtil';
import {FlexColumn, Heading, TextField, Text} from '@forecast-it/design-system';
import {WideButton} from '../../../../containers/sage-signup-flow/Sage.styled';

function SharedReportChangePasswordForecast({
	onSubmit,
	newPassword,
	onSetNewPassword,
	newPasswordRepeat,
	onSetNewPasswordRepeat,
	error,
}) {
	const {formatMessage} = useIntl();
	return (
		<form onSubmit={onSubmit}>
			<h1>{formatMessage({id: 'shared_report.change_password.title'})}</h1>
			<label htmlFor="newPassword">{formatMessage({id: 'user-settings.security.field.title.new-password'})}</label>
			<div className="input-bubble-wrapper">
				<input
					className="password"
					type="password"
					name="newPassword"
					id="newPassword"
					onChange={onSetNewPassword}
					defaultValue=""
					placeholder={formatMessage({id: 'user-settings.security.field.placeholder.new-password'})}
				/>
				<div
					className={'bubble left show' + (Util.validatePassword(newPassword).valid ? ' valid' : '')}
					style={{top: '-60px', left: '400px'}}
				>
					{Util.getPasswordHelpText(newPassword, newPasswordRepeat)}
				</div>
			</div>

			<label htmlFor="newPasswordRepeat">
				{formatMessage({id: 'user-settings.security.field.title.confirm-password'})}
			</label>
			<input
				className="password"
				type="password"
				name="password"
				id="newPasswordRepeat"
				onChange={onSetNewPasswordRepeat}
				defaultValue=""
				placeholder={formatMessage({id: 'user-settings.security.field.placeholder.confirm-password'})}
			/>

			{error ? <div className="error">{error}</div> : ''}

			<div className="buttons">
				<Button
					id="change-password-submit-button"
					text={formatMessage({id: 'shared_report.change_password.submit'})}
					cy="button-change-password-submit"
					buttonStyle={BUTTON_STYLE.FILLED}
					colorTheme={BUTTON_COLOR.PINK}
					onClick={onSubmit}
					uppercase={false}
					roundedBorders={true}
				/>
			</div>
		</form>
	);
}

function SharedReportChangePasswordSage({
	onSubmit,
	newPassword,
	onSetNewPassword,
	newPasswordRepeat,
	onSetNewPasswordRepeat,
	error,
}) {
	const {formatMessage} = useIntl();
	return (
		<FlexColumn gap={'l'}>
			<Heading size={4}>{formatMessage({id: 'login.set-new-password-title'})}</Heading>
			<TextField
				isPasswordField
				label={formatMessage({id: 'user-settings.security.field.title.new-password'})}
				autoComplete="password"
				maxLength={64}
				value={newPassword}
				hasError={!!error}
				onValueChange={onSetNewPassword}
			/>

			<div className={'password-help-text-inline'}>{Util.getPasswordHelpText(newPassword, newPasswordRepeat)}</div>

			<TextField
				isPasswordField
				label={formatMessage({id: 'user-settings.security.field.title.confirm-password'})}
				autoComplete="password"
				maxLength={64}
				value={newPasswordRepeat}
				hasError={!!error}
				onValueChange={onSetNewPasswordRepeat}
			/>

			{error ? <Text color={'error'}>{error}</Text> : ''}

			<WideButton onClick={onSubmit} data-cy="button-change-password-submit">
				{formatMessage({id: 'shared_report.change_password.submit'})}
			</WideButton>
		</FlexColumn>
	);
}

const SharedReportResetPassword = () => {
	const {formatMessage} = useIntl();

	const [error, setError] = useState(null);
	const [newPassword, setNewPassword] = useState('');
	const [newPasswordRepeat, setNewPasswordRepeat] = useState('');

	const {refetch, sharedId, password} = useSharedReportLoginContext();

	const changePassword = event => {
		if (event) {
			event.preventDefault();
		}

		if (newPassword !== newPasswordRepeat) {
			setError(formatMessage({id: 'shared_report.login.change_password.dont_match'}));
			return;
		}

		const passwordValidation = Util.validatePasswordChange(password, newPassword);
		if (!passwordValidation.valid) {
			setError(formatMessage({id: 'user-settings.security.password_validation_error.' + passwordValidation.error}));
			return;
		}

		const onSuccess = response => {
			if (response.sharedReportChangePassword.success) {
				Util.CommitMutation(
					SharedReportLoginMutation,
					{
						sharedId,
						password: newPassword,
					},
					refetch,
					true
				);
			} else {
				setError(formatMessage({id: 'shared_report.login.change_password.error'}));
			}
		};

		Util.CommitMutation(
			SharedReportChangePasswordMutation,
			{
				sharedId,
				oldPassword: password,
				newPassword,
			},
			onSuccess
		);
	};

	return isSageSpecificUrl() ? (
		<SharedReportChangePasswordSage
			onSubmit={changePassword}
			onSetNewPassword={setNewPassword}
			newPassword={newPassword}
			newPasswordRepeat={newPasswordRepeat}
			onSetNewPasswordRepeat={setNewPasswordRepeat}
			error={error}
		/>
	) : (
		<SharedReportChangePasswordForecast
			onSubmit={changePassword}
			onSetNewPassword={e => setNewPassword(e.target.value)}
			newPassword={newPassword}
			newPasswordRepeat={newPasswordRepeat}
			onSetNewPasswordRepeat={e => setNewPasswordRepeat(e.target.value)}
			error={error}
		/>
	);
};

export default SharedReportResetPassword;
