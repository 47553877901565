import {SOCKET_ACTION, SOCKET_EVENT_TYPE} from '../../../../../constants';

export const getSocketConfig = () => {
	return [
		{
			type: SOCKET_EVENT_TYPE.TASK,
			action: SOCKET_ACTION.DELETE,
		},
		{
			type: SOCKET_EVENT_TYPE.PROJECT,
			action: SOCKET_ACTION.DELETE,
		},
		{
			type: SOCKET_EVENT_TYPE.IDLE_TIME,
			action: SOCKET_ACTION.DELETE,
		},
	];
};
