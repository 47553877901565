export function encodeGlobalId(type, id) {
    return Buffer.from(type + ':' + id).toString('base64');
}
function parseAndSplitGlobalId(globalId) {
    return Buffer.from(globalId, 'base64').toString('utf8').split(':');
}
export function typeFromGlobalId(globalId) {
    return parseAndSplitGlobalId(globalId)[0];
}
export function idFromGlobalId(globalId) {
    return Number(parseAndSplitGlobalId(globalId)[1]);
}
