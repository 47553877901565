import React, {Component} from 'react';
import Moment from 'moment';
import {FormattedHTMLMessage, FormattedMessage, injectIntl} from 'react-intl';
import {createRefetchContainer, graphql} from 'react-relay';
import * as tracking from '../../../../tracking';
import asanaLogo from '../../../../images/integrations/asana-logo.png';
import {withRouter} from 'react-router-dom';
import CustomScrollDiv from '../../../shared/components/scroll-bars/custom_scroll_div';
import Util from '../../../shared/util/util';
import DisableAsanaMutation from '../../../../mutations/disable_asana_mutation';
import CreateAsanaVerificationKeyMutation from '../../../../mutations/create_asana_verification_key_mutation';
import MigrateAsanaProjectsMutation from '../../../../mutations/migrate_asana_projects_mutation';
import CheckBox from '../../../../components/inputs/checkbox';
import StatusTable from './status_table';
import UploadingOverlay from '../../../shared/components/uploading-overlay/uploading_overlay';
import {createToast} from '../../../shared/components/toasts/another-toast/toaster';
import {ELEMENT_TYPE} from '../../../../constants';
import {buildHeaderBar} from '../../../shared/components/headers/header-bar/header_bar';
import DirectApi from '../../../../directApi';
import 'whatwg-fetch'; //IE 11 fetch polyfill
import {trackEvent, trackPage, unregisterPageInfo} from '../../../../tracking/amplitude/TrackingV2';

class appAsana extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedProjects: [],
			asanaProjects: [],
			fetchingAsanaProjects: false,
			stoppedAutoRefresh: false,
		};

		this.refreshFetcher = null;

		this.superPropertyChecksum = trackPage('Settings Asana Integration');
	}

	componentDidMount() {
		document.title = 'Asana - Apps & Integrations - Forecast';
		// Segment
		tracking.trackPage('settings-app-catalog-asana');

		if (this.props.viewer.asanaEnabled) {
			this.setState({fetchingAsanaProjects: true});

			DirectApi.Fetch('asana_projects').then(json => {
				this.setState({
					asanaProjects: json,
					fetchingAsanaProjects: false,
				});
			});
		}
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);

		if (this.refreshFetcher) {
			clearTimeout(this.refreshFetcher);
		}
	}

	sendToAsana() {
		const onSuccess = ({createAsanaVerificationKey}) => {
			tracking.trackEvent('Asana migrations enabled');
			trackEvent('Asana Integration', 'Enabled');
			window.location.href = `https://app.asana.com/-/oauth_authorize?client_id=${process.env.ASANA_CLIENT_ID}&redirect_uri=${process.env.ASANA_REDIRECT_URI}&response_type=code&state=${createAsanaVerificationKey.key}`;
		};

		Util.CommitMutation(CreateAsanaVerificationKeyMutation, null, onSuccess);
	}

	deactivate() {
		Util.CommitMutation(DisableAsanaMutation);
	}

	openHelpCenter() {
		window.open('https://support.forecast.app/hc/en-us/articles/5300273822609');
	}

	updateProps(fetchTimestamp, projCount, iteration) {
		this.props.relay.refetch();
		if (iteration === 10) {
			this.setState({stoppedAutoRefresh: true});
			return;
		}

		const iterationWaitTime = 3000;

		// Filter and order projects according to migration status
		const filteredProjects = this.props.viewer.projects.edges
			.filter(p => p.node.asanaMigrationStatus)
			.sort((p1, p2) => (p1.node.asanaMigrationStatus.startedAt < p2.node.asanaMigrationStatus.startedAt ? 1 : -1));

		if (filteredProjects.length < projCount) {
			this.refreshFetcher = setTimeout(
				() => this.updateProps(fetchTimestamp, projCount, iteration + 1),
				iteration * iterationWaitTime
			);
			return;
		}

		// Check the n most recent migrations. If all of them were migrated after migration started, stop refetching
		for (let i = 0; i < projCount; i++) {
			const latest = filteredProjects[i].node.asanaMigrationStatus;
			const latestFinishedAt = new Moment(latest.finishedAt).add(new Moment().utcOffset(), 'minutes');
			if (latest.status === 'MIGRATING' || fetchTimestamp.isAfter(latestFinishedAt)) {
				this.refreshFetcher = setTimeout(
					() => this.updateProps(fetchTimestamp, projCount, iteration + 1),
					iteration * iterationWaitTime
				);
				return;
			}
		}
	}

	migrateSelectedProjects() {
		const projIds = this.state.selectedProjects.map(projs => projs.gid);
		// Clear selection to prevent multiple clicks
		this.setState({selectedProjects: []});

		let toastMessage = this.props.intl.formatMessage({id: 'settings.app_catalog.asana.migrated'}) + ': ';

		for (var i = 0; i < this.state.selectedProjects.length && i < 5; i++) {
			toastMessage += this.state.selectedProjects[i].name;
			if (i !== this.state.selectedProjects.length - 1) toastMessage += ', ';
		}

		if (this.state.selectedProjects.length > 5) {
			toastMessage += `${this.props.intl.formatMessage({id: 'common.and'})} ${
				this.state.selectedProjects.length - 5
			} ${this.props.intl.formatMessage({id: 'common.more'})}...`;
		}

		createToast({duration: 10000, message: toastMessage});

		Util.CommitMutation(
			MigrateAsanaProjectsMutation,
			{
				projectIds: projIds,
			},
			null,
			false
		);

		this.updateProps(new Moment(), projIds.length, 1);
	}

	toggleAllSelection() {
		this.setState({
			selectedProjects:
				this.state.selectedProjects.length > 0
					? []
					: this.state.asanaProjects.map(ap => ({name: ap.name, gid: ap.gid})),
		});
	}

	toggleProjectSelection(name, gid) {
		if (this.state.selectedProjects.find(p => p.gid === gid)) {
			this.setState({selectedProjects: this.state.selectedProjects.filter(p => p.gid !== gid)});
		} else {
			let selected = this.state.selectedProjects;
			selected.push({name: name, gid: gid});
			this.setState({
				selectedProjects: selected,
			});
		}
	}

	getHeader() {
		const leftContent = [],
			rightContent = [];

		leftContent.push({type: ELEMENT_TYPE.BACK_BUTTON, onClick: this.props.history.goBack});

		return buildHeaderBar(leftContent, rightContent);
	}

	render() {
		return (
			<div className="section-content settings-app settings-app-page">
				{this.props.children}
				{this.getHeader()}
				<CustomScrollDiv>
					<div className="section-body">
						<div className="inner">
							<fieldset className="general asana-fieldset">
								<div className="header-wrapper">
									<img width={100} height={100} alt="asana logo" src={asanaLogo} />
									<div className="title-description-wrapper">
										<div className="description">
											<FormattedHTMLMessage id="settings.app_catalog.asana.description" />
										</div>
									</div>
								</div>
								{this.props.viewer.asanaEnabled ? (
									<h3>
										<FormattedMessage id="common.projects" />
									</h3>
								) : null}
								{this.props.viewer.asanaEnabled ? (
									<div className="asana-projects-list-container">
										<CustomScrollDiv>
											{this.state.fetchingAsanaProjects ? (
												<UploadingOverlay />
											) : (
												this.state.asanaProjects
													.sort((p1, p2) => (p1.created_at < p2.created_at ? 1 : -1))
													.map((ap, idx) => (
														<div className="asana-project-row" key={idx}>
															<CheckBox
																id={ap.gid + '-' + ap.name}
																onChange={() => this.toggleProjectSelection(ap.name, ap.gid)}
																checked={this.state.selectedProjects.some(
																	p => p.gid === ap.gid
																)}
															/>
															<label htmlFor={ap.gid + '-' + ap.name}>{`(${new Moment(
																ap.created_at
															).format('YYYY MMM D, HH:mm')}) - ${ap.name}`}</label>
														</div>
													))
											)}
										</CustomScrollDiv>
									</div>
								) : null}
								<div className="input-fields asana-buttons">
									{this.props.viewer.asanaEnabled ? (
										[
											<button
												className="submit-button"
												onClick={() => this.migrateSelectedProjects()}
												disabled={this.state.selectedProjects.length === 0}
											>
												<span>
													<FormattedMessage id="common.import" />
												</span>
											</button>,
											<button className="submit-button" onClick={() => this.toggleAllSelection()}>
												<span>
													<FormattedMessage id="common.select_all" />
												</span>
											</button>,
											<button className="submit-button" onClick={() => this.deactivate()}>
												<span>
													<FormattedMessage id="integration.disable" />
												</span>
											</button>,
											this.state.stoppedAutoRefresh ? (
												<div className="stopped-refresh-text">
													<FormattedMessage id="settings.app_catalog.asana.auto_refresh_stopped" />
												</div>
											) : (
												''
											),
										]
									) : (
										<button className="submit-button" onClick={() => this.sendToAsana()}>
											<FormattedMessage id="settings.app_catalog.jira.activate" />
										</button>
									)}
									<button className="help-button" onClick={this.openHelpCenter.bind(this)}>
										<span>
											<FormattedMessage id="settings.app_catalog.help_button" />
										</span>
									</button>
								</div>
							</fieldset>
							<fieldset className="general">
								<h3>{this.props.intl.formatMessage({id: 'settings.app_catalog.asana.migration_log'})}</h3>
								<StatusTable
									data={this.props.viewer.projects.edges
										.filter(p => p.node.asanaMigrationStatus)
										.map(p => ({
											status: p.node.asanaMigrationStatus.status,
											startedAt: new Moment(p.node.asanaMigrationStatus.startedAt).add(
												new Moment().utcOffset(),
												'minutes'
											),
											finishedAt: new Moment(p.node.asanaMigrationStatus.finishedAt).add(
												new Moment().utcOffset(),
												'minutes'
											),
											title: p.node.name,
											companyProjectId: p.node.companyProjectId,
										}))}
								/>
							</fieldset>
						</div>
					</div>
				</CustomScrollDiv>
			</div>
		);
	}
}

const appAsanaQuery = graphql`
	query appAsana_Query {
		viewer {
			actualPersonId
			component(name: "app_asana")
			...appAsana_viewer
		}
	}
`;

export {appAsanaQuery};

export default createRefetchContainer(
	injectIntl(withRouter(appAsana)),
	{
		viewer: graphql`
			fragment appAsana_viewer on Viewer {
				id
				asanaEnabled
				projects(first: 100000) {
					edges {
						node {
							id
							companyProjectId
							customProjectId
							name
							asanaMigrationStatus {
								status
								startedAt
								finishedAt
							}
						}
					}
				}
			}
		`,
	},
	graphql`
		query appAsanaRefetchQuery {
			viewer {
				...appAsana_viewer
			}
		}
	`
);
