import React, {useState} from 'react';
import {TaskListWrapper} from '../../../../my-work-tab/my-tasks-page/my_work_styled';
import TaskRow from './Task_row';
import TaskListHeader from './Task_row_header';
import Util from '../../../util/util';

export const TaskList = ({
	intl,
	enabledColumns,
	tasks,
	history,
	viewer,
	enableSort,
	stickyTopPosition,
	isInContainer,
	localSortKey,
	cy,
}) => {
	const [sortBy, setSortBy] = useState(
		localSortKey && Util.localStorageGetItem(localSortKey)
			? JSON.parse(Util.localStorageGetItem(localSortKey))
			: {column: null, ascending: false}
	);
	Util.sortTasks(tasks, sortBy);

	const setSorting = sortBy => {
		if (enableSort) {
			setSortBy(sortBy);
			if (localSortKey) {
				Util.localStorageSetItem(localSortKey, JSON.stringify(sortBy));
			}
		}
	};

	return (
		<TaskListWrapper>
			<TaskListHeader
				intl={intl}
				enabledColumns={enabledColumns}
				sortBy={sortBy}
				onSortByChange={enableSort ? sortBy => setSorting(sortBy) : null}
				stickyTopPosition={stickyTopPosition}
				isInContainer={isInContainer}
			/>
			{tasks.map(task => (
				<TaskRow
					cy={cy}
					key={task.node.id}
					intl={intl}
					task={task.node}
					enabledColumns={enabledColumns}
					showTaskModal={taskId => Util.showTaskModal(taskId, history)}
					viewer={viewer} //TODO to removed, only used for Generic task context menu
				/>
			))}
		</TaskListWrapper>
	);
};
