import { addDays, differenceInCalendarDays, format, parse } from 'date-fns';
import React, { useEffect, useMemo } from 'react';
import { DatePicker, FlexColumn, FlexItem, Text } from '@forecast-it/design-system';
import { TRACKING_OBJECTS } from '../../../../../../../tracking/amplitude/constants/TrackingObjects';
import { trackEvent } from '../../../../../../../tracking/amplitude/TrackingV2';
import { createDateFromYMD, isDateDisabledYMD } from '../../../../../util/DateUtil';
import TimeRegistrationModalTimeOnDayQueryWrapper from '../query-wrappers/TimeRegistrationModalTimeOnDayQueryWrapper';
import { getIsDateDisabledFromTargetEntity } from '../util/TimeRegistrationModalDateUtil';
function isDateUnavailable(date, targetEntity) {
    const currentDate = createDateFromYMD({ year: date.year, month: date.month, day: date.day });
    return (isDateDisabledYMD(date.year, date.month, date.day) ||
        getIsDateDisabledFromTargetEntity({
            date: currentDate,
            targetEntity,
        }, false));
}
function TimeRegistrationModalDates({ personId, targetEntity, lockDate, selectedDate, setSelectedDate, setDateError, formValidationFailed, width, }) {
    const [userChangedDate, setUserChangedDate] = React.useState(false);
    function onDateSelection(dateString) {
        if (dateString) {
            // parse instead of new Date() to avoid time-zone shift when changing from string to date
            const parsedDate = parse(dateString, 'yyyy-MM-dd', new Date());
            trackEvent(TRACKING_OBJECTS.TIME_REGISTRATION_MODAL, 'Date Changed', {
                from: selectedDate,
                to: parsedDate,
            });
            setSelectedDate(parsedDate);
            setUserChangedDate(true);
        }
    }
    const selectedDateBeforeLockDateError = useMemo(() => {
        return lockDate ? differenceInCalendarDays(lockDate, selectedDate) >= 0 : false;
    }, [lockDate, selectedDate]);
    const dateDisabledByTargetEntityError = useMemo(() => {
        return getIsDateDisabledFromTargetEntity({ date: selectedDate, targetEntity }, true);
    }, [selectedDate, targetEntity]);
    const dateError = selectedDateBeforeLockDateError ? 'This date is locked' : dateDisabledByTargetEntityError;
    useEffect(() => {
        if (selectedDateBeforeLockDateError || dateDisabledByTargetEntityError) {
            setDateError(true);
        }
        else {
            setDateError(false);
        }
    }, [selectedDateBeforeLockDateError, dateDisabledByTargetEntityError]);
    const showError = dateError && (userChangedDate || formValidationFailed);
    return (React.createElement(FlexColumn, { gap: 's' },
        React.createElement(FlexItem, null,
            React.createElement("div", { style: { width: width || 'auto' } },
                React.createElement(DatePicker, { label: "Date", value: selectedDate && format(selectedDate, 'yyyy-MM-dd'), minValue: lockDate ? format(addDays(lockDate, 1), 'yyyy-MM-dd') : undefined, onChange: onDateSelection, suppressError: !showError, "data-cy": 'time-registration-modal-date-picker', isDateUnavailable: date => isDateUnavailable(date, targetEntity) }))),
        personId ? React.createElement(TimeRegistrationModalTimeOnDayQueryWrapper, { personId: personId, date: selectedDate }) : null,
        dateError && showError ? React.createElement(Text, { color: 'error' }, dateError) : null));
}
export default TimeRegistrationModalDates;
