import {throttle} from 'lodash';
import {useRef, useEffect} from 'react';
/*
 * Generic eventListener add and cleanup for hooks
 * handler passed to useEventListener should generally be created using useCallback so reference never changes.
 */
function useEventListener(
	eventName,
	handler,
	element = window,
	options = {isCapturingPhaseListener: false, throttleInterval: null}
) {
	// Create a ref that stores handler
	const savedHandler = useRef();

	// Update ref.current value if handler changes.
	// This allows our effect below to always get latest handler ...
	// ... without us needing to pass it in effect deps array ...
	// ... and potentially cause effect to re-run every render.
	useEffect(() => {
		savedHandler.current = handler;
	}, [handler]);

	useEffect(
		() => {
			const {isCapturingPhaseListener, throttleInterval} = options;
			// Make sure element supports addEventListener
			// On
			const isSupported = element && element.addEventListener;
			if (!isSupported) return;

			// Create event listener that calls handler function stored in ref
			const listenerFunc = event => savedHandler.current(event);
			const eventListener = throttleInterval ? throttle(listenerFunc, throttleInterval) : listenerFunc;

			// Add event listener
			element.addEventListener(eventName, eventListener, isCapturingPhaseListener);

			// Remove event listener on cleanup
			return () => {
				element.removeEventListener(eventName, eventListener, isCapturingPhaseListener);
			};
		},
		[eventName, element] // Re-run if eventName or element changes
	);
}

export default useEventListener;
