import Button from './button/button';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../../constants';
import React from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import Styled from 'styled-components';

const FeedbackButtonStyle = Styled.div`
	.feedback-button {
		height: 30px;
		padding: 0 16px;
		div.symbol {
			margin: 4px 8px 0 0;
		}
	}
`;

export const FeedbackButton = props => {
	const {formatMessage} = useIntl();
	const {link, ...rest} = props;
	return (
		<FeedbackButtonStyle>
			<Button
				className={'feedback-button'}
				text={formatMessage({id: 'common.give_feedback'})}
				buttonStyle={BUTTON_STYLE.OUTLINE_THICK}
				colorTheme={BUTTON_COLOR.VERYLIGHTGREY}
				symbolClass={'email'}
				symbolSize={15}
				onClick={() => window.open(link, '_blank')}
				{...rest}
			/>
		</FeedbackButtonStyle>
	);
};

FeedbackButton.propTypes = {
	link: PropTypes.string.isRequired,
};
