import { graphql } from 'react-relay';
export const TimesheetsTaskCountQuery = graphql `
	query TimesheetsTaskCountQuery($taskTypeSearchQuery: TaskSearchQueryType) {
		viewer {
			component(name: "TimesheetsTaskCountQuery")
			company {
				allTasksCount(searchQuery: $taskTypeSearchQuery, approvedOnly: true, runningProjectOnly: true)
			}
		}
	}
`;
