import React from 'react';
import {Bar} from 'react-chartjs-2';
import {injectIntl} from 'react-intl';
import {HoursContainer, HoursPlanned, HoursActual} from '../my_work_styled';
import {getTimeRegistrationsOfTheDay} from '../../my-timesheets-page/timesheets_time_registrations';
import {getHolidaysOfTheDay, workingHourForTheDay} from '../../my-timesheets-page/timesheets_person_data';
import Util from '../../../shared/util/util';
import {Link} from 'react-router-dom';

import {Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement);

const MyWorkTimeEntries = ({intl, person, timeRegistrations, weekArray}) => {
	const getAllocationForDay = momentDay => {
		const isHoliday = getHolidaysOfTheDay(person, momentDay).length > 0;
		return isHoliday ? 0 : workingHourForTheDay(person, momentDay); // Don't increase workingHourForTheDay if the day is a holiday
	};

	const getLabelForDay = dayString => {
		const translationId = 'common.' + dayString + '.short';
		return intl.formatMessage({id: translationId});
	};

	let weekTimeRegistered = 0;
	let weekAllocation = 0;

	// BEGIN CHART STUFF
	let data;
	const barWidth = 14;
	data = {
		labels: [],
		datasets: [
			// Target points
			{
				label: intl.formatMessage({id: 'retainer.target'}),
				type: 'line',
				borderColor: '#528fe9',
				pointRadius: 16,
				pointHoverRadius: 16,
				pointStyle: 'line',
				backgroundColor: '#528fe9',
				showLine: false,
				borderWidth: 1,
				data: [],
			},
			{
				label: intl.formatMessage({id: 'common.time_entries'}),
				backgroundColor: '#eaf8ff',
				borderWidth: 1,
				borderColor: '#ade3ff',
				lineTension: 0,
				barThickness: barWidth,
				fill: true,
				data: [],
			},
		],
	};

	weekArray.forEach(momentDay => {
		const timeRegs = getTimeRegistrationsOfTheDay(momentDay, timeRegistrations);
		const timeRegistered = timeRegs.reduce(
			(totalTimeRegistered, timeReg) => totalTimeRegistered + timeReg.node.minutesRegistered,
			0
		);
		weekTimeRegistered += timeRegistered;
		const isoDay = momentDay.isoWeekday();
		const dayString = Util.isoDayToDayString(isoDay);
		const allocation = getAllocationForDay(momentDay);
		weekAllocation += allocation;
		const label = getLabelForDay(dayString);
		if (allocation > 0 || timeRegs.length > 0) {
			data.datasets[0].data.push(allocation / 60);
			data.datasets[1].data.push(timeRegistered / 60);

			data.labels.push(label);
		}
	});

	const options = {
		maintainAspectRatio: false,
		font: {
			family: 'neue-haas-grotesk-text',
		},
		plugins: {
			title: {
				display: false,
			},
			legend: {
				display: false,
			},
			tooltip: {
				mode: 'index',
				intersect: true,
				backgroundColor: '#fff',
				borderColor: '#e6e6e6',
				borderWidth: 1,
				titleColor: '#535353',
				bodyColor: '#535353',
				padding: 12,
				callbacks: {
					label: ctx => ctx.dataset.label + ': ' + Util.convertMinutesToFullHour(ctx.parsed.y * 60, intl),
				},
			},
			datalabels: {
				display: false,
			},
		},
		scales: {
			x: {
				beginAtZero: true,
				ticks: {
					padding: 4,
					min: 0,
					font: {
						size: 11,
						color: '#a1a1a1',
					},
				},
				minBarLength: 5,
				maxRotation: 0,
				grid: {
					display: false,
					offsetGridLines: true,
					drawTicks: false,
				},
			},
			y: {
				beginAtZero: true,
				ticks: {
					font: {
						size: 11,
						color: '#a1a1a1',
					},
					padding: 4,
					autoSkip: true,
					autoSkipPadding: 4,

					//min: 0
					callback: function (value) {
						return value + intl.formatMessage({id: 'common.hours.short'});
					},
					maxTicksLimit: 8,
				},
				grid: {
					borderDash: [7, 4],
					drawTicks: false,
					color: '#e7e7e7',
				},
			},
		},
	};

	const minChartWidth = '190px';
	// END CHART STUFF

	return (
		<>
			<Link to="timesheets">
				<div
					className={'chart-sizer'}
					style={{width: minChartWidth, marginLeft: '-12px'}}
					data-userpilot={'timeregs-chart'}
				>
					<Bar data={data} options={options}></Bar>
				</div>
			</Link>
			<HoursContainer>
				<HoursActual>{Util.convertMinutesToFullHour(weekTimeRegistered, intl) + '/'}</HoursActual>
				<HoursPlanned>{Util.convertMinutesToFullHour(weekAllocation, intl)}</HoursPlanned>
			</HoursContainer>
		</>
	);
};

export default injectIntl(MyWorkTimeEntries);
