import styled from 'styled-components';
import {CSS_CONSTANTS} from '../../../../../css_variables';
import diagonals_background from '../../../../../images/diagonal-lines-pattern.svg';
import {genericTokens} from '@forecast-it/design-system';

export const SpinnerContainer = styled.div`
	width: 90px;
	height: 30px;
	border: solid 1px ${CSS_CONSTANTS.app_border_color};
	border-radius: 4px;
`;

export const HoursInputStyle = styled.input`
	text-overflow: ellipsis;
	width: 100%;
	border-radius: 4px;
	border-top-left-radius: ${props => props.withInputExtension && 0};
	border-bottom-left-radius: ${props => props.withInputExtension && 0};
	border: solid ${props => (props.taskModalStyle ? '0px' : '1px')} ${CSS_CONSTANTS.app_border_color};
	background-color: ${props => (props.taskModalStyle ? 'inherit' : CSS_CONSTANTS.color_white)};
	font-size: ${props => (props.modalView ? '12px' : props.taskModalStyle ? '15px' : '13px')};
	padding: ${props => (props.modalView ? '0 16px' : props.taskModalStyle ? '0' : '6px 15px 6px 10px')};
	line-height: ${props =>
		props.modalView ? 'normal' : '16px'}; /*if we don't set it the font height is 17px wich make the input too tall*/
	text-align: right;
	${props => (props.modalView ? 'flex: 0 1 auto;' : '')}
	color: ${props => (props.modalView || props.phaseHeaderStyle ? 'inherit' : CSS_CONSTANTS.v2_text_gray)};
	height: ${props => (props.modalView ? '40px' : props.taskModalStyle || props.phaseHeaderStyle ? '' : '30px')};
	${props => (props.modalView ? 'display: flex;' : '')}
	${props => (props.modalView ? 'flex-direction: row;' : '')}
    ${props => (props.taskModalStyle ? 'font-weight: 700;' : '')};

	${props => (props.invalid ? 'border-color:' + CSS_CONSTANTS.v2_border_invalid + ';' : '')}
	::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		font-size: 13px;
		color: ${CSS_CONSTANTS.v2_button_very_light_grey_hovered};
		opacity: 1;
	}

	::-moz-placeholder {
		/* Firefox 19+ */
		font-size: 13px;
		color: ${CSS_CONSTANTS.v2_button_very_light_grey_hovered};
		opacity: 1;
	}

	:-ms-input-placeholder {
		/* IE 10+ */
		font-size: 13px;
		color: ${CSS_CONSTANTS.v2_button_very_light_grey_hovered};
		opacity: 1;
	}

	:-moz-placeholder {
		/* Firefox 18- */
		font-size: 13px;
		color: ${CSS_CONSTANTS.v2_button_very_light_grey_hovered};
		opacity: 1;
	}

	&.non-working-day {
		background-image: url(${diagonals_background});
		background-position: center;
		background-size: auto auto;
	}

	&:hover {
		border-color: ${CSS_CONSTANTS.v2_button_very_light_grey_hovered};
	}

	&:disabled {
		color: ${CSS_CONSTANTS.v2_text_light_gray};
		background-color: ${CSS_CONSTANTS.v2_input_disabled_grey};
		border-color: ${CSS_CONSTANTS.app_border_color};
	}

	&.error {
		border-color: ${CSS_CONSTANTS.negative_value};
	}

	&:focus {
		border-color: ${CSS_CONSTANTS.v2_branding_purple};
		outline: none;
	}
`;

export const HoursInputReplicateDesignSystemStyle = styled(HoursInputStyle)`
	height: 44px;
	width: 100%;
	border: 1px solid #727483;
	border-radius: 4px;

	text-align: left;

	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;

	color: #393946;

	::placeholder {
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;

		color: #727483;
	}

	&:focus {
		outline: 2px solid ${genericTokens.color.border.focusring};
		outline-offset: -2px;
		border-color: transparent;
	}
`;

export const HoursInputContainerStyle = styled.div`
	position: relative;
	display: flex;
	${props => (props.noMaxWidth ? 'max-width: 90px;' : 'max-width: 100%;')}
	${props => (props.width ? `width: ${props.width}` : '')}
    ${props => (props.modalView || props.taskModalStyle || props.phaseHeaderStyle ? '' : 'height: 30px;')}
    flex-direction: column;

	.input-title {
		font-size: 9px;
		font-weight: 500;
		line-height: 1.22;
		letter-spacing: 1px;
		color: ${CSS_CONSTANTS.v2_text_light_gray};
		text-transform: uppercase;
		margin-bottom: 5px;
		white-space: nowrap;
	}
`;

export const HoursInputContainerReplicateDesignSystemStyle = styled(HoursInputContainerStyle)`
	width: ${props => (props.width ? props.width : '170px')};
	height: 44px;
`;

export const FakeHoursInputStyle = styled.button`
	text-overflow: ellipsis;
	width: 100%;
	border-radius: 4px;
	border: solid 1px ${CSS_CONSTANTS.app_border_color};
	background-color: ${CSS_CONSTANTS.color_white};
	font-size: 13px;
	padding: 6px 15px 6px 10px;
	line-height: 16px;
	text-align: right;
	color: ${CSS_CONSTANTS.v2_text_gray};
	height: 30px;

	&.non-working-day {
		background-image: linear-gradient(
			-45deg,
			rgba(0, 0, 0, 0),
			rgba(0, 0, 0, 0) 40%,
			rgba(0, 0, 0, 0.05) 40%,
			rgba(0, 0, 0, 0.05) 50%,
			rgba(0, 0, 0, 0) 50%,
			rgba(0, 0, 0, 0) 90%,
			rgba(0, 0, 0, 0.05) 90%,
			rgba(0, 0, 0, 0.05)
		);
		background-size: 10px 10px;
	}

	&:hover {
		border-color: ${CSS_CONSTANTS.v2_button_very_light_grey_hovered};
	}

	&:disabled {
		color: ${CSS_CONSTANTS.v2_text_light_gray};
		background-color: ${CSS_CONSTANTS.v2_input_disabled_grey};
		border-color: ${CSS_CONSTANTS.app_border_color};
	}

	&:focus {
		border-color: ${CSS_CONSTANTS.v2_branding_purple};
		outline: none;
	}
`;
