import React from 'react';
import styled from 'styled-components';
const AsteriskStyle = styled.span `
	font-size: 11px;
	font-weight: 600;
`;
function Asterisk() {
    return React.createElement(AsteriskStyle, null, "*");
}
export default Asterisk;
