import Styled from 'styled-components/macro';
import {deprecatedTheme as theme} from '@forecast-it/design-system';

export const Page = Styled.div`
  margin-left: ${theme.spacing.xl};  
  margin-top: ${theme.spacing.xxl};  
  display: flex;
  flex-direction: column;
	flex-wrap: wrap;
	gap: ${theme.spacing.l};
	`;

export const Section = Styled.div`
	display: flex;
	max-width: 500px;
  flex-direction: column;
	flex-wrap: wrap;
	gap: ${theme.spacing.xs};
`;

export const SubSection = Styled.div`
	display: flex;
  	flex-direction: column;
	flex-wrap: wrap;
	gap: ${theme.spacing.s};
	padding-left: ${({isInnerSubSection}) => (isInnerSubSection ? '39px' : theme.spacing.s)};	
	padding-top: ${theme.spacing.xs};	
`;

export const Divider = Styled.div`
	max-width: 500px;
  margin-top: ${theme.spacing.s};
  margin-bottom: ${theme.spacing.s};
	border-bottom: 1px solid ${theme.colors.border.primary.default};
`;
