var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import CustomScrollDiv from './custom_scroll_div';
import { Scrollbar } from '@forecast-it/design-system';
const CustomScrollToNativeScroll = (_a) => {
    var { forceCustomScrollbar, maxHeight, isPageScroller, hasFocusableContent, hideAxis, onNativeScroll, children } = _a, props = __rest(_a, ["forceCustomScrollbar", "maxHeight", "isPageScroller", "hasFocusableContent", "hideAxis", "onNativeScroll", "children"]);
    if (!forceCustomScrollbar) {
        return (React.createElement(Scrollbar, { maxHeight: maxHeight, onScroll: onNativeScroll, isPageScroller: isPageScroller, hasFocusableContent: hasFocusableContent, hideAxis: hideAxis }, children));
    }
    return React.createElement(CustomScrollDiv, Object.assign({}, props), children);
};
export default CustomScrollToNativeScroll;
