import { fetchQuery } from 'relay-runtime';
import environment from '../../../RelayEnvironment';
import { useState } from 'react';
const doNothing = () => {
    /* do nothing */
};
export const useForecastFetchQuery = (query, clearDataOnFetch = false) => {
    const [data, setData] = useState(null);
    const fetch = (variables = {}, onSuccess = doNothing, onError = doNothing) => {
        if (clearDataOnFetch) {
            setData(null);
        }
        fetchQuery(environment.getInstance(), query, variables).then(data => {
            setData(data);
            onSuccess();
        }, () => {
            onError();
        });
    };
    return { fetch, data };
};
