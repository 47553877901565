import React, {Component} from 'react';
import {Scrollbars} from 'react-custom-scrollbars';
import {SpringSystem} from 'rebound';
import {EVENT_ID, dispatch} from '../../../../containers/event_manager';

export default class CustomScrollDiv extends Component {
	componentDidMount() {
		if (this.props.setScrollbarsRef) {
			this.props.setScrollbarsRef(this.scrollbars.container.firstChild);
		}
		this.springSystem = new SpringSystem();
		this.spring = this.springSystem.createSpring();
		this.spring.setOvershootClampingEnabled(true);
		this.spring.addListener({onSpringUpdate: this.handleSpringUpdate.bind(this)});
	}

	componentWillUnmount() {
		this.springSystem.deregisterSpring(this.spring);
		this.springSystem.removeAllListeners();
		this.springSystem = undefined;
		this.spring.destroy();
		this.spring = undefined;
	}

	setScrollTop(top) {
		this.scrollbars.scrollTop(top);
	}

	animateScrollTop(top) {
		const scrollTop = this.scrollbars.getScrollTop();
		this.spring.setCurrentValue(scrollTop).setAtRest();
		this.spring.setEndValue(top);
	}

	scrollToLeft() {
		return this.scrollbars.scrollToLeft();
	}

	getScrollTop() {
		return this.scrollbars.getScrollTop();
	}

	getScrollHeight() {
		return this.scrollbars.getScrollHeight();
	}

	getHeight() {
		return this.scrollbars.getClientHeight();
	}

	handleSpringUpdate(spring) {
		const val = spring.getCurrentValue();
		this.scrollbars.scrollTop(val);
	}

	handleScrollStart() {
		dispatch(EVENT_ID.SCROLLED, this.props.scrollParent);
		if (this.props.onScrollStart) {
			this.props.onScrollStart();
		}
	}

	handleScroll(e) {
		if (this.props.handleScroll) {
			this.props.handleScroll(e);
		}
	}

	render() {
		// eslint-disable-next-line no-unused-vars
		const {setScrollbarsRef, horizontalContent, handleScroll, scrollParent, horizontalandverticalcontent, ...rest} =
			this.props;
		delete rest.marginBottom;
		return (
			<Scrollbars
				data-cy={this.props.cy}
				ref={div => {
					this.scrollbars = div;
				}}
				onScroll={this.handleScroll.bind(this)}
				onScrollStart={this.handleScrollStart.bind(this)}
				className={
					'custom-scrollbar-div ' +
					(horizontalandverticalcontent
						? 'view-horizontal-vertical'
						: horizontalContent
						? 'view-horizontal'
						: 'view-vertical')
				}
				hideTracksWhenNotNeeded={true}
				autoHide={this.props.autoHide !== undefined ? this.props.autoHide : true}
				style={{
					height: 'auto',
					width: this.props.width || '100%',
					marginBottom: this.props.marginBottom,
					marginRight: this.props.marginRight,
				}}
				autoHeight={this.props.autoHeight ? this.props.autoHeight : false}
				autoHeightMin={this.props.autoHeightMin ? this.props.autoHeightMin : null}
				autoHeightMax={this.props.autoHeightMax ? this.props.autoHeightMax : null}
				renderTrackHorizontal={props => <div {...props} className="track track-horizontal" />}
				renderTrackVertical={props => <div {...props} className="track track-vertical" />}
				renderThumbHorizontal={props => <div {...props} className="thumb thumb-horizontal" />}
				renderThumbVertical={props => <div {...props} className="thumb thumb-vertical" />}
				{...rest}
			/>
		);
	}
}
