import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import {buildHeaderBar} from '../../shared/components/headers/header-bar/header_bar';
import {BUTTON_COLOR, BUTTON_STYLE, ELEMENT_TYPE} from '../../../constants';
import CustomScrollDiv from '../../shared/components/scroll-bars/custom_scroll_div';
import {withRouter} from 'react-router-dom';
import Department from './department';
import {MODAL_TYPE, showModal} from '../../shared/components/modals/generic_modal_conductor';
import groupBy from 'lodash/groupBy';
import SettingsResourcesSubNav from './SettingsResourcesSubNav';
import Util from '../../shared/util/util';
import {hasFeatureFlag} from '../../shared/util/FeatureUtil';
import * as tracking from '../../../tracking';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';

class settingsDepartments extends Component {
	constructor(props) {
		super(props);

		this.state = {
			searchFilterValue: '',
			noDepartmentExpanded: true,
		};
	}

	UNSAFE_componentWillMount() {
		if (!Util.hasDepartments(this.props.viewer.company.modules)) {
			this.props.history.replace('/not-found');
		}
	}

	toggleNoDepartment() {
		this.setState({noDepartmentExpanded: !this.state.noDepartmentExpanded});
	}

	onSearchChange(searchFilterValue) {
		this.setState({searchFilterValue});
	}

	importSageIntacctUsers() {
		tracking.trackEvent('Import departments from Sage Intacct pressed');
		trackEvent('Import Departments From Sage Intacct', 'Clicked');
		showModal({
			type: MODAL_TYPE.SAGE_INTACCT_IMPORT_DEPARTMENTS,
			companyId: this.props.viewer.company.id,
		});
	}

	openCreateDepartmentModal() {
		showModal({
			type: MODAL_TYPE.ADD_DEPARTMENT,
			companyId: this.props.viewer.company.id,
			persons: this.props.viewer.company.allPersons.edges.filter(person => !person.node.department && person.node.active),
			creatingDepartment: true,
		});
	}

	getHeader() {
		const rightContent = [];

		if (hasFeatureFlag('sage_intacct_integration') && this.props.viewer.company.sageIntacctConnectionEnabled) {
			rightContent.push({
				type: ELEMENT_TYPE.BUTTON,
				text: this.props.intl.formatMessage({id: 'integrations.sage_intacct.import_departments'}),
				callback: this.importSageIntacctUsers.bind(this),
				style: BUTTON_STYLE.OUTLINE,
				color: BUTTON_COLOR.PURPLE,
			});
		}

		rightContent.push({
			type: ELEMENT_TYPE.SEARCH,
			value: this.state.searchFilterValue || '',
			onChange: this.onSearchChange.bind(this),
		});

		rightContent.push({
			type: ELEMENT_TYPE.BUTTON,
			text: this.props.intl.formatMessage({id: 'settings_department.add_department'}),
			callback: this.openCreateDepartmentModal.bind(this),
			style: BUTTON_STYLE.OUTLINE,
			color: BUTTON_COLOR.PURPLE,
		});

		return buildHeaderBar([], rightContent);
	}

	filterPerson(person) {
		return Util.normalizedIncludes(
			`${person.fullName} ${person.email} ${person.department ? person.department.name : ''} ${
				person.role ? person.role.name : ''
			} ${person.costCenter ? person.costCenter : ''}`,
			this.state.searchFilterValue
		);
	}

	render() {
		const {formatMessage} = this.props.intl;
		const allPersons = this.props.viewer.company.allPersons.edges.map(person => person.node).filter(per => per.active);

		// Filtering
		const filteredPersons =
			this.state.searchFilterValue.length > 0 ? allPersons.filter(person => this.filterPerson(person)) : allPersons;

		// Group persons by departments
		const groups = groupBy(filteredPersons, p => (p.department ? p.department.id : null));
		const departmentlessPeople = groups[null] || [];

		return (
			<div className="settings-app settings-departments" data-cy={'settings-departments-page'}>
				<SettingsResourcesSubNav viewer={this.props.viewer} />
				{this.getHeader()}
				<CustomScrollDiv>
					{departmentlessPeople.length > 0 ? (
						<div className="department-group-title">
							<span className="department-group-header">
								{formatMessage({id: 'settings_departments.no_department'})}
							</span>
							<span>({departmentlessPeople.length})</span>
							<button
								onClick={() => this.toggleNoDepartment()}
								className={
									'department-group-button ' + (this.state.noDepartmentExpanded ? 'expanded' : 'collapsed')
								}
							/>
						</div>
					) : (
						''
					)}

					{departmentlessPeople.length > 0 ? (
						this.state.noDepartmentExpanded ? (
							<div className="department-group">
								<Department
									noDepartment={true}
									allPersons={allPersons}
									peopleWithoutDepartments={departmentlessPeople}
								/>
							</div>
						) : (
							<div className="no-department-group-collapsed-spacer" />
						)
					) : (
						''
					)}

					{this.props.viewer.company.departments.edges.length > 0 && (
						<>
							<div className="department-group-title">
								<span className="department-group-header">{formatMessage({id: 'common.departments'})}</span>
							</div>

							<div className="department-group">
								{this.props.viewer.company.departments.edges.map(department => {
									return (
										<Department
											department={department.node}
											key={department.node.id + 'dep'}
											departmentPersons={groups[department.node.id]}
											allPersons={allPersons}
											companyId={this.props.viewer.company.id}
										/>
									);
								})}
							</div>
						</>
					)}
				</CustomScrollDiv>
			</div>
		);
	}
}

settingsDepartments.propTypes = {};

const settingsDepartmentsQuery = graphql`
	query settingsDepartments_Query {
		viewer {
			availableFeatureFlags {
				key
				name
			}
			actualPersonId
			component(name: "settings_departments")
			...settingsDepartments_viewer
		}
	}
`;

graphql`
	fragment settingsDepartments_department on DepartmentType {
		id
		name
		sageIntacctId
	}
`;

graphql`
	fragment settingsDepartments_person on Person {
		id
		fullName
		firstName
		lastName
		email
		costCenter
		profilePictureId
		profilePictureDefaultId
		department {
			id
			name
		}
		role {
			name
		}
		isViewer
	}
`;

export {settingsDepartmentsQuery};

export default injectIntl(
	createFragmentContainer(withRouter(settingsDepartments), {
		viewer: graphql`
			fragment settingsDepartments_viewer on Viewer {
				...SettingsResourcesSubNav_viewer
				availableFeatureFlags {
					key
					name
				}
				company {
					id
					sageIntacctConnectionEnabled
					modules {
						moduleType
					}
					departments(first: 10000) @connection(key: "Company_departments", filters: []) {
						edges {
							node {
								...settingsDepartments_department @relay(mask: false)
							}
						}
					}
					allPersons(first: 10000, excludeClientUsers: true) {
						edges {
							node {
								...settingsDepartments_person @relay(mask: false)
								active
							}
						}
					}
				}
			}
		`,
	})
);
