import React from 'react';
import { ValueCell } from '../../../../../../components/initial-plan/ValueCell';
import { Data, HorizontalBar, Title, ValueContainer } from '../ProjectScopingTotals_styled';
import { Tile, TileRow } from './Tile_styled';
export const FixedPriceRightTile = ({ totalsData, projectData, settings, intl }) => {
    return (React.createElement(Tile, null,
        settings.isBaselineProject && totalsData.baselineHours > 0 ? (React.createElement(React.Fragment, null,
            React.createElement(TileRow, null,
                React.createElement(Title, { singleLine: true }, intl.formatMessage({ id: 'baseline.hours' })),
                React.createElement(Data, { small: true },
                    React.createElement(ValueContainer, null,
                        React.createElement(ValueCell, { noJustify: true, cy: 'baseline_hours', type: ValueCell.VALUE_TYPE.TIME, value: totalsData.baselineHours, currencySymbol: settings.currencySymbol })))),
            React.createElement(TileRow, null,
                React.createElement(Title, { singleLine: true }, `${intl.formatMessage({ id: 'baseline.baseline' })} - ${intl.formatMessage({
                    id: 'common.projected_total_hours',
                })}`),
                React.createElement(Data, { small: true },
                    React.createElement(ValueContainer, null,
                        React.createElement(ValueCell, { noJustify: true, cy: 'baseline_vs_projected_total_hours', type: ValueCell.VALUE_TYPE.TIME, value: totalsData.baselineHours - totalsData.projectedTotalHours })))))) : (React.createElement(React.Fragment, null,
            React.createElement(TileRow, null,
                React.createElement(Title, null, intl.formatMessage({ id: 'project_overview.project_dates' })),
                React.createElement(Data, { small: true }, `${totalsData.startDateString} - ${totalsData.endDateString}`)),
            React.createElement(TileRow, null,
                React.createElement(Title, null, intl.formatMessage({
                    id: (projectData === null || projectData === void 0 ? void 0 : projectData.manualProgressOnProjectEnabled)
                        ? 'common.progress'
                        : 'project_scoping.estimated_project_progress',
                })),
                React.createElement(Data, { small: true },
                    React.createElement(ValueCell, { noJustify: true, cy: 'progress', type: ValueCell.VALUE_TYPE.PERCENTAGE, value: totalsData.progress }))))),
        React.createElement(HorizontalBar, null),
        React.createElement(TileRow, null,
            React.createElement(Title, { singleLine: true }, intl.formatMessage({ id: 'common.projected_total_billable_value_of_service' })),
            React.createElement(Data, { small: true },
                React.createElement(ValueContainer, null,
                    React.createElement(ValueCell, { noJustify: true, cy: 'projected_total_value_of_service', type: ValueCell.VALUE_TYPE.PRICE, value: totalsData.projectedBillableValueOfService, currencySymbol: settings.currencySymbol })))),
        React.createElement(TileRow, null,
            React.createElement(Title, { singleLine: true }, intl.formatMessage({ id: 'common.projected_remaining_budget' })),
            React.createElement(Data, { small: true },
                React.createElement(ValueContainer, null,
                    React.createElement(ValueCell, { noJustify: true, cy: 'projected_remaining_budget', type: ValueCell.VALUE_TYPE.PRICE, value: ((projectData === null || projectData === void 0 ? void 0 : projectData.budget) || 0) - totalsData.projectedBillableValueOfService, currencySymbol: settings.currencySymbol }))))));
};
