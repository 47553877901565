import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import DeleteClientMutation from '../../../../mutations/delete_client_mutation';
import {withRouter} from 'react-router-dom';
import SettingClientsClient from './settings_clients_client';
import {getSalesforceInstanceUrl} from './settings_clients_util';
import * as tracking from '../../../../tracking';
import {injectIntl} from 'react-intl';
import {createToast} from '../../../shared/components/toasts/another-toast/toaster';
import CustomScrollDiv from '../../../shared/components/scroll-bars/custom_scroll_div';
import Util from '../../../shared/util/util';
import {BUTTON_COLOR, BUTTON_STYLE, ELEMENT_TYPE, HIDDEN_FEATURES, MODULE_TYPES} from '../../../../constants';
import {buildHeaderBar} from '../../../shared/components/headers/header-bar/header_bar';
import {MODAL_TYPE, showModal} from '../../../shared/components/modals/generic_modal_conductor';
import {cloneDeep} from 'lodash';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../../shared/components/headers/top-header-bar/TopHeaderBar';
import {CLIENT_PERMISSION_GROUPS, CLIENT_PERMISSIONS_WITH_GUEST_USER} from '../ClientPermissionGroups';
import {PERMISSION_TYPE} from '../../../../Permissions';
import {trackPage, unregisterPageInfo} from '../../../../tracking/amplitude/TrackingV2';
import CompanySetupUtil, {isFeatureHidden} from '../../../shared/util/CompanySetupUtil';
import {hasFeatureFlag} from '../../../shared/util/FeatureUtil';
import {hasModule} from '../../../shared/util/ModuleUtil';

class settingsClients extends Component {
	constructor(props) {
		super(props);
		if (isFeatureHidden(HIDDEN_FEATURES.CLIENT)) {
			props.history.push('/admin/details');
		}
		this.state = {
			inputName: '',
			searchFilterValue: '',
			getSalesforceInstanceUrl: null,
		};

		this.superPropertyChecksum = trackPage('Settings Clients');
	}

	componentDidMount() {
		document.title = 'Clients - Account - Forecast';
		// Segment
		tracking.trackPage('settings-clients');

		if (this.props.viewer.company.newSalesforceEnabled) this.fetchSalesforceInstanceUrl();
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	async fetchSalesforceInstanceUrl() {
		const instanceUrl = await getSalesforceInstanceUrl();

		this.setState({
			salesforceInstanceUrl: instanceUrl,
		});
	}

	deleteClient(client, e) {
		e.stopPropagation();
		const onSuccess = response => {
			createToast({duration: 5000, message: this.props.intl.formatMessage({id: 'client.has-been-deleted'})});
		};

		const callbackPositive = params => {
			Util.CommitMutation(DeleteClientMutation, {id: client.id, companyId: this.props.viewer.company.id}, onSuccess);
		};
		showModal({
			type: MODAL_TYPE.GENERIC_DELETION_WARNING,
			deleteCallback: callbackPositive,
		});
	}

	openClientPage(id) {
		this.props.history.push('/admin/clients/view-client/' + id);
	}

	economicSync() {
		showModal({
			type: MODAL_TYPE.ECONOMIC_SYNC_CLIENTS,
			companyId: this.props.viewer.company.id,
		});
	}

	sageIntacctSync() {
		showModal({
			type: MODAL_TYPE.SAGE_INTACCT_SYNC_CLIENTS,
			companyId: this.props.viewer.company.id,
		});
	}

	xeroSync() {
		showModal({
			type: MODAL_TYPE.XERO_SYNC_CLIENTS,
			companyId: this.props.viewer.company.id,
		});
	}

	qboSync() {
		showModal({
			type: MODAL_TYPE.QUICKBOOKS_SYNC_CLIENTS,
			companyId: this.props.viewer.company.id,
		});
	}

	salesforceSync() {
		showModal({
			type: MODAL_TYPE.SALESFORCE_SYNC_CLIENTS,
			companyId: this.props.viewer.company.id,
			intl: this.props.intl,
			retry: this.props.retry,
		});
	}

	hubspotSync() {
		showModal({
			type: MODAL_TYPE.HUBSPOT_SYNC_CLIENTS,
			companyId: this.props.viewer.company.id,
			intl: this.props.intl,
			retry: this.props.retry,
		});
	}

	pipedriveSync() {
		showModal({
			type: MODAL_TYPE.PIPEDRIVE_SYNC_CLIENTS,
			companyId: this.props.viewer.company.id,
			intl: this.props.intl,
			retry: this.props.retry,
		});
	}

	handleSearchChange(value) {
		this.setState({searchFilterValue: value});
	}

	getTopHeader() {
		return (
			<TopHeaderBarWrapper>
				<TopHeaderBar title={this.props.intl.formatMessage({id: 'settings.clients'})} />
			</TopHeaderBarWrapper>
		);
	}

	getHeader() {
		const {formatMessage} = this.props.intl;
		const rightContent = [];

		if (this.props.viewer.company.economicEnabled && CompanySetupUtil.hasFinance()) {
			rightContent.push({
				type: ELEMENT_TYPE.BUTTON,
				text: formatMessage({id: 'settings_clients.import_from'}, {systemName: 'E-conomic'}),
				callback: this.economicSync.bind(this),
				style: BUTTON_STYLE.OUTLINE,
				color: BUTTON_COLOR.PURPLE,
			});
		}

		if (
			this.props.viewer.company.sageIntacctConnectionEnabled &&
			(CompanySetupUtil.hasFinance() || hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED))
		) {
			rightContent.push({
				type: ELEMENT_TYPE.BUTTON,
				text: formatMessage({id: 'settings_clients.import_from'}, {systemName: 'Sage Intacct'}),
				callback: this.sageIntacctSync.bind(this),
				style: BUTTON_STYLE.OUTLINE,
				color: BUTTON_COLOR.PURPLE,
			});
		}

		if (this.props.viewer.company.newXeroEnabled && CompanySetupUtil.hasFinance()) {
			rightContent.push({
				type: ELEMENT_TYPE.BUTTON,
				text: formatMessage({id: 'settings_clients.import_from'}, {systemName: 'Xero'}),
				callback: this.xeroSync.bind(this),
				style: BUTTON_STYLE.OUTLINE,
				color: BUTTON_COLOR.PURPLE,
			});
		}

		if (this.props.viewer.company.quickbooksEnabled && CompanySetupUtil.hasFinance()) {
			rightContent.push({
				type: ELEMENT_TYPE.BUTTON,
				text: formatMessage({id: 'settings_clients.import_from'}, {systemName: 'Quickbooks'}),
				callback: this.qboSync.bind(this),
				style: BUTTON_STYLE.OUTLINE,
				color: BUTTON_COLOR.PURPLE,
			});
		}

		if (this.props.viewer.company.newSalesforceEnabled && Util.hasSalesforce()) {
			rightContent.push({
				type: ELEMENT_TYPE.BUTTON,
				text: formatMessage({id: 'settings_clients.import_from'}, {systemName: 'Salesforce'}),
				callback: this.salesforceSync.bind(this),
				style: BUTTON_STYLE.OUTLINE,
				color: BUTTON_COLOR.PURPLE,
			});
		}

		if (this.props.viewer.company.hubspotAccountId && Util.hasHubspot()) {
			rightContent.push({
				type: ELEMENT_TYPE.BUTTON,
				text: formatMessage({id: 'settings_clients.import_from'}, {systemName: 'Hubspot'}),
				callback: this.hubspotSync.bind(this),
				style: BUTTON_STYLE.OUTLINE,
				color: BUTTON_COLOR.PURPLE,
			});
		}

		if (this.props.viewer.company.pipedriveCompanyDomain && Util.hasPipedrive()) {
			rightContent.push({
				type: ELEMENT_TYPE.BUTTON,
				text: formatMessage({id: 'settings_clients.import_from'}, {systemName: 'Pipedrive'}),
				callback: this.pipedriveSync.bind(this),
				style: BUTTON_STYLE.OUTLINE,
				color: BUTTON_COLOR.PURPLE,
			});
		}

		rightContent.push({
			type: ELEMENT_TYPE.BUTTON,
			text: 'client permissions',
			callback: () =>
				showModal({
					type: hasFeatureFlag('guest_user_limited_task_access') ? MODAL_TYPE.CLIENT_PERMISSIONS : MODAL_TYPE.PROFILE,
					profile: this.getClientPermissions(),
					permissions: hasFeatureFlag('guest_user_limited_task_access')
						? CLIENT_PERMISSIONS_WITH_GUEST_USER
						: CLIENT_PERMISSION_GROUPS,
					profileNameCanBeEdited: false,
					profileCanBeEdited: true,
					headerText: 'Edit client permissions',
					hideProfileName: true,
					description: formatMessage({id: 'client_page.client_description'}),
				}),
			style: BUTTON_STYLE.OUTLINE,
			color: BUTTON_COLOR.PURPLE,
			dataCy: 'edit-client-permissions-button',
		});

		if (!hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED)) {
			rightContent.push({
				type: ELEMENT_TYPE.BUTTON,
				text: formatMessage({id: 'settings_clients.add-client'}),
				callback: () => showModal({type: MODAL_TYPE.ADD_CLIENT}),
				style: BUTTON_STYLE.OUTLINE,
				color: BUTTON_COLOR.PURPLE,
				dataCy: 'add-client-button',
			});
		}

		return buildHeaderBar([], rightContent);
	}

	getClientPermissions() {
		return this.props.viewer.company.profiles.edges
			.map(profile => profile.node)
			.find(profile => profile.permissions.includes(PERMISSION_TYPE.IS_CLIENT_USER));
	}

	render() {
		const {formatMessage} = this.props.intl;
		let options = cloneDeep(this.props.viewer.company.clients.edges).sort((a, b) => {
			if (a.node.name.toLowerCase() > b.node.name.toLowerCase()) return 1;
			if (a.node.name.toLowerCase() < b.node.name.toLowerCase()) return -1;
			return 0;
		});
		const invoices = this.props.viewer.invoicesV2.edges.map(edge => edge.node);
		return (
			<div className="section-content settings-app settings-client" data-cy={'settings-clients-page'}>
				{this.props.children}
				{this.getTopHeader()}
				{this.getHeader()}
				<CustomScrollDiv>
					<div className="section-body">
						<div className="inner">
							<fieldset className="clients">
								<div className="top-container">
									<p className="description">{formatMessage({id: 'settings_clients.description'})}</p>
								</div>
								<table className="client-table">
									<thead>
										<tr>
											<th className="column-name">{formatMessage({id: 'common.name'})}</th>
											<th className="column-notes">{formatMessage({id: 'settings_clients.notes'})}</th>
											<th className="column-projects">{formatMessage({id: 'common.projects'})}</th>
											<th className="column-controls">&nbsp;</th>
										</tr>
									</thead>

									<tbody>
										{options.map(client => {
											const invoiced = invoices.some(
												invoice => invoice.client && invoice.client.id === client.node.id
											);

											return (
												<SettingClientsClient
													key={client.node.id}
													name={client.node.name}
													id={client.node.id}
													logoId={client.node.logoId}
													logoDefaultId={client.node.logoDefaultId}
													notes={client.node.notes}
													projectCount={client.node.projectCount ? client.node.projectCount : 0}
													displayClientPage={this.openClientPage.bind(this, client.node.id)}
													deleteClient={this.deleteClient.bind(this, client.node)}
													economicCustomerId={
														this.props.viewer.company.economicEnabled
															? client.node.economicCustomerId
															: undefined
													}
													sageIntacctCustomerId={
														this.props.viewer.company.sageIntacctConnectionEnabled
															? client.node.sageIntacctCustomerId
															: undefined
													}
													xeroContactId={
														this.props.viewer.company.xeroEnabled ||
														this.props.viewer.company.newXeroEnabled
															? client.node.xeroContactId
															: null
													}
													hubspotCompanyId={
														this.props.viewer.company.hubspotAccountId
															? client.node.hubspotCompanyId
															: null
													}
													pipedriveCompanyDomain={this.props.viewer.company.pipedriveCompanyDomain}
													pipedriveOrganizationId={
														this.props.viewer.company.pipedriveCompanyDomain
															? client.node.pipedriveOrganizationId
															: null
													}
													hubspotAccountId={this.props.viewer.company.hubspotAccountId}
													quickbooksId={client.node.quickbooksCustomerId}
													salesforceAccountId={
														this.props.viewer.company.newSalesforceEnabled
															? client.node.salesforceAccountId
															: undefined
													}
													invoiced={invoiced}
													salesforceInstanceUrl={this.state.salesforceInstanceUrl}
												/>
											);
										})}
									</tbody>
								</table>
							</fieldset>
						</div>
					</div>
				</CustomScrollDiv>
			</div>
		);
	}
}

const settingsClientsQuery = graphql`
	query settingsClients_Query {
		viewer {
			actualPersonId
			component(name: "settings_clients")
			...settingsClients_viewer
		}
	}
`;

export {settingsClientsQuery};

export default injectIntl(
	withRouter(
		createFragmentContainer(settingsClients, {
			viewer: graphql`
				fragment settingsClients_viewer on Viewer {
					component(name: "settings_clients")
					id
					availableFeatureFlags {
						key
					}
					company {
						id
						economicEnabled
						sageIntacctConnectionEnabled
						xeroEnabled
						newXeroEnabled
						quickbooksEnabled
						newSalesforceEnabled
						hubspotAccountId
						pipedriveCompanyDomain
						clients(first: 10000) @connection(key: "Company_clients", filters: []) {
							edges {
								node {
									id
									name
									notes
									logoId
									logoDefaultId
									projectCount
									economicCustomerId
									quickbooksCustomerId
									xeroContactId
									salesforceAccountId
									hubspotCompanyId
									pipedriveOrganizationId
									sageIntacctCustomerId
								}
							}
						}
						profiles(first: 10000) @connection(key: "Company_profiles", filters: []) {
							edges {
								node {
									id
									name
									permissions
								}
							}
						}
					}
					invoicesV2(first: 100000) {
						edges {
							node {
								id
								client {
									id
								}
							}
						}
					}
				}
			`,
		})
	)
);
