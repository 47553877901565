import Styled from 'styled-components/macro';
import {CSS_CONSTANTS} from '../../../../css_variables';

export const MyWorkFavouredTasksSectionWrapper = Styled.div`
	padding: 27px 14px 16px 24px;
	position: relative;
	width: ${props => (props.isCollapsed ? '60px' : '353px')};
	background-color: ${CSS_CONSTANTS.v2_fixed_element_grey};
	cursor: default;
`;

export const ArrowSection = Styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	margin-bottom: ${props => !props.isCollapsed && '30px'};
`;

export const StarredTitle = Styled.p`
	font-size: 16px;
	padding: 0;
	margin: 0;
	margin-left: 14px;
	font-weight: 500;
	color: #535353;
	white-space: nowrap;

`;

export const CollapsedStarredText = Styled(StarredTitle)`
	transform-origin: 0 0;
	transform: rotate(90deg);
	margin-top: 14px;
`;
