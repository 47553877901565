import { differenceInMinutes } from 'date-fns';
export var CalendarEventSize;
(function (CalendarEventSize) {
    CalendarEventSize[CalendarEventSize["SMALL"] = 52] = "SMALL";
    CalendarEventSize[CalendarEventSize["MEDIUM"] = 72] = "MEDIUM";
    CalendarEventSize[CalendarEventSize["LARGE"] = 96] = "LARGE";
})(CalendarEventSize || (CalendarEventSize = {}));
export function getCalendarEventSize(event) {
    if (!(event === null || event === void 0 ? void 0 : event.start) || !(event === null || event === void 0 ? void 0 : event.end)) {
        return CalendarEventSize.SMALL;
    }
    const start = new Date(event.start);
    const end = new Date(event.end);
    const diffInMinutes = differenceInMinutes(end, start);
    if (diffInMinutes <= 30) {
        return CalendarEventSize.SMALL;
    }
    else if (diffInMinutes <= 120) {
        return CalendarEventSize.MEDIUM;
    }
    else {
        return CalendarEventSize.LARGE;
    }
}
