import React, {useState, useEffect} from 'react';
import {useIntl} from 'react-intl';
import InputFieldV2 from '../../../components/new-ui/input_field';
import GenericModal, {MODAL_WIDTH} from '../../../containers/modal/generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE, colors} from '../../../constants';
import {createToast} from '../../shared/components/toasts/another-toast/toaster';
import CreateLabelMutation from '../../../mutations/create_label_mutation';
import Util from '../../shared/util/util';
import {createFragmentContainer, graphql} from 'react-relay';
import DropdownV2 from '../../shared/components/dropdowns/dropdown';
import {Field, ModalBody} from '../../shared/components/modals/Modal.styled';
import ColorsPicker from '../../../components/colors_picker';
import {InputLabel} from 'web-components';

const CreateLabelModal = ({company, closeModal}) => {
	const {formatMessage} = useIntl();
	const NO_CATEGORY = formatMessage({id: 'settings_labels.no_category'});
	const [name, setName] = useState('');
	const [color, setColor] = useState(colors[0]);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const categories = company.labelCategories.edges.map(labelCategory => labelCategory.node);
	const categoryOptions = categories.map(label => ({value: label.id, label: label.name}));
	const [errorMessage, setErrorMessage] = useState();
	categoryOptions.push({value: null, label: NO_CATEGORY});

	useEffect(() => {
		const existingNames = new Set(
			company.labels.edges
				.map(edge => edge.node)
				.filter(label => (label.category ? label.category.id === selectedCategory : selectedCategory === null))
				.map(label => label.name)
		);
		const duplicateName = existingNames.has(name);
		if (duplicateName) {
			setErrorMessage(formatMessage({id: 'settings_labels.already_exists'}));
		} else {
			setErrorMessage(null);
		}
	}, [selectedCategory, name]);

	const onNameChange = newName => {
		setName(newName);
	};

	const createLabel = () => {
		Util.CommitMutation(
			CreateLabelMutation,
			{
				name,
				color,
				categoryId: selectedCategory,
				companyId: company.id,
			},
			() => {
				createToast({
					duration: 5000,
					message: formatMessage({id: 'settings_labels.label_created_toast'}),
				});
			}
		);
	};

	const onSelectCategory = option => {
		setSelectedCategory(option.value);
	};

	return (
		<GenericModal
			closeModal={closeModal}
			modalWidth={MODAL_WIDTH.SMALL}
			headerText={formatMessage({id: 'settings_labels.create-label'})}
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
					cy: 'button-cancel',
				},
				{
					text: formatMessage({id: 'common.create'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: createLabel,
					disabled: !name || !!errorMessage,
					cy: 'button-save',
				},
			]}
			content={
				<ModalBody>
					<Field>
						<InputFieldV2
							value={name}
							cy={'label-name'}
							id="labelName"
							label={formatMessage({id: 'common.name'})}
							placeholder={formatMessage({id: 'common.name_placeholder'})}
							type="text"
							onChange={onNameChange}
							errorMessage={errorMessage}
							invalidInput={!!errorMessage}
							required={true}
							maxLength={191}
						/>
					</Field>
					<Field>
						<InputLabel
							text={formatMessage({id: 'common.color'})}
							child={<ColorsPicker value={color} onColorSelect={setColor} size={20} usePortal />}
						/>
					</Field>
					<Field>
						<DropdownV2
							options={categoryOptions}
							onChange={onSelectCategory}
							value={selectedCategory}
							label={`${formatMessage({id: 'settings_labels.category'})} ${formatMessage({
								id: 'common.field_optional_label',
							})}`}
							placeholder={NO_CATEGORY}
							inputName={'category-input'}
							inputCy={'category'}
							listDataCy={'category'}
						/>
					</Field>
				</ModalBody>
			}
		/>
	);
};

const createLabelModalQuery = graphql`
	query CreateLabelModal_Query {
		viewer {
			actualPersonId
			component(name: "create_label_modal")
			company {
				...CreateLabelModal_company
			}
		}
	}
`;

export {createLabelModalQuery};

export default createFragmentContainer(CreateLabelModal, {
	company: graphql`
		fragment CreateLabelModal_company on Company {
			id
			createdAt
			labelCategories(first: 10000) @connection(key: "Company_labelCategories") {
				edges {
					node {
						id
						name
					}
				}
			}
			labels(first: 1000) @connection(key: "Company_labels") {
				edges {
					node {
						id
						name
						category {
							id
						}
					}
				}
			}
		}
	`,
});
