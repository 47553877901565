/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type appSlack_viewer$ref = any;
export type appSlack_QueryVariables = {||};
export type appSlack_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: appSlack_viewer$ref,
  |}
|};
export type appSlack_Query = {|
  variables: appSlack_QueryVariables,
  response: appSlack_QueryResponse,
|};
*/


/*
query appSlack_Query {
  viewer {
    actualPersonId
    component(name: "app_slack")
    ...appSlack_viewer
    id
  }
}

fragment appSlack_viewer on Viewer {
  id
  company {
    id
    slackEnabled
    newSlackEnabled
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "app_slack"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"app_slack\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "appSlack_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "appSlack_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "appSlack_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slackEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "newSlackEnabled",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "appSlack_Query",
    "operationKind": "query",
    "text": "query appSlack_Query {\n  viewer {\n    actualPersonId\n    component(name: \"app_slack\")\n    ...appSlack_viewer\n    id\n  }\n}\n\nfragment appSlack_viewer on Viewer {\n  id\n  company {\n    id\n    slackEnabled\n    newSlackEnabled\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b87ec953e942339bac85d94dfe2a9ca9';

module.exports = node;
