import {addTimeRegTypes, groupRegs, sortList} from './DataUtil';
import {cloneDeep} from 'lodash';

export function getEntityList(
	entityList,
	filteredTimeRegs,
	sortValue,
	sortAscending,
	isWeekView,
	weekDays,
	topSection,
	getDayRegsForSwitchEntity
) {
	entityList = addTimeRegTypes(filteredTimeRegs);
	sortList(entityList, sortValue, sortAscending);
	if (isWeekView) {
		entityList = groupRegs(entityList, weekDays);
		for (let entity of topSection) {
			entityList.set(entity, getDayRegsForSwitchEntity(entity));
		}
		const mapEntries = [...entityList.entries()];
		sortList(mapEntries, sortValue, sortAscending);
		entityList = new Map(mapEntries);
	} else {
		entityList = entityList.concat(topSection);
		sortList(entityList, sortValue, sortAscending);
	}
	return entityList;
}

export function getEntityListWithoutPtoLinking(
	entityList,
	filteredTimeRegs,
	sortValue,
	sortAscending,
	isWeekView,
	weekDays,
	topSection
) {
	entityList = addTimeRegTypes(filteredTimeRegs);
	sortList(entityList, sortValue, sortAscending);
	if (isWeekView) {
		entityList = groupRegs(entityList, weekDays);
	}

	sortList(topSection, sortValue, sortAscending);

	return entityList;
}

export function getTopSection(isFullyLocked, combinedSwitchList) {
	return isFullyLocked ? [] : combinedSwitchList;
}

export function getCombinedSwitchList(
	isWeekView,
	groupedMovedTasks,
	filteredSwitchTasks,
	filteredSwitchIdleTimes,
	timeRegsMoved
) {
	return isWeekView
		? Array.from(groupedMovedTasks.keys()).concat(filteredSwitchTasks).concat(filteredSwitchIdleTimes)
		: addTimeRegTypes(timeRegsMoved).concat(filteredSwitchTasks).concat(filteredSwitchIdleTimes);
}

export function getGroupedMovedTasks(isWeekView, timeRegsMoved) {
	return isWeekView ? groupRegs(addTimeRegTypes(timeRegsMoved)) : [];
}

export function getTimeRegsMoved(timeRegsForPeriod, movedTasks, movedIdleTimes) {
	// These tasks are tasks that have been moved from tasks to time regs while the page was open
	const timeRegsMovedFromTasks = timeRegsForPeriod.filter(
		tReg => tReg.node.task && movedTasks.some(taskId => taskId === tReg.node.task.id)
	);
	const timeRegsMovedFromIdleTimes = timeRegsForPeriod.filter(
		tReg => tReg.node.idleTime && movedIdleTimes.some(idleTimeId => idleTimeId === tReg.node.idleTime.id)
	);
	return timeRegsMovedFromTasks.concat(timeRegsMovedFromIdleTimes);
}

export function getSwitchIdleTimes(viewer, selectedSwitch) {
	return viewer.company.taskTypeQueryIdleTimes && (selectedSwitch === 'suggested' || selectedSwitch === 'starred')
		? viewer.company.taskTypeQueryIdleTimes.edges.filter(idleTime => !idleTime.node.disabled)
		: [];
}

export function getSuggestedSwitchTasks(viewer) {
	return viewer.timeRegistrationSuggestedTasks
		? cloneDeep(viewer.timeRegistrationSuggestedTasks)
				.sort(function (a, b) {
					return Number(b.total) - Number(a.total);
				})
				.slice(0, 5)
		: [];
}

export function getSwitchTasks(viewer) {
	return viewer.company.taskTypeQueryTasks ? viewer.company.taskTypeQueryTasks.edges : [];
}
