import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export const TabWrapperStyle = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

const TabsStyle = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(10px, max-content));
	padding-bottom: 24px;
`;

const TabStyle = styled.div`
	padding: 8px 20px 0px 0px;
	font-size: 13px;
	color: #a1a1a1;
	position: relative;
	cursor: pointer;
	user-select: none;

	&:hover {
		color: #6e0fea;
	}

	&.selected {
		a {
			color: #6e0fea;
			font-weight: 500;
			border-bottom: 2px solid #6e0fea;
			padding-bottom: 2px;
		}
		&::after {
			content: '';
			display: block;
			width: 100%;
			height: 1px;
			background: white;
			position: absolute;
			left: 0;
			bottom: -1px;
		}
	}
`;

export const Tabs = ({children, onTabSelect}) => {
	const [selectedTab, setSelectedTab] = useState(0);
	const [selectedTabContent, setSelectedTabContent] = useState(null);

	const setTabContent = () => {
		let foundTab = null;
		React.Children.forEach(children, (child, index) => {
			if (index === selectedTab) {
				foundTab = child;
			}
		});
		setSelectedTabContent(foundTab);
	};

	useEffect(() => {
		let defaultSelectedTab = 0;
		React.Children.forEach(children, (child, index) => {
			if (child.props.defaultSelected) {
				defaultSelectedTab = index;
			}
		});
		setSelectedTab(defaultSelectedTab);
	}, []);

	useEffect(() => {
		React.Children.forEach(children, (child, index) => {
			if (child.props.isSelected) {
				setSelectedTab(index);
			}
		});
		setTabContent();
	}, [children]);

	useEffect(() => {
		setTabContent();

		if (onTabSelect) {
			onTabSelect(selectedTab);
		}
	}, [selectedTab]);

	return (
		<TabWrapperStyle>
			<TabsStyle>
				{React.Children.map(children, (child, index) => {
					return child.props.title ? (
						<TabStyle
							key={index}
							className={selectedTab === index ? ' selected' : ''}
							onClick={() => setSelectedTab(index)}
							data-cy={child.props.cy ? child.props.cy : undefined}
						>
							<a>{child.props.title}</a>
						</TabStyle>
					) : null;
				})}
			</TabsStyle>
			{selectedTabContent}
		</TabWrapperStyle>
	);
};

Tabs.propTypes = {
	/**
	 * Tabs can only contain Tab elements
	 */
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
