import React from 'react';
import {FormattedHTMLMessage, FormattedMessage, injectIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import {withRouter} from 'react-router-dom';
import * as tracking from '../../../tracking';
import UpdateCompanyMutation from '../../../mutations/update_company_mutation.modern';
import CustomScrollDiv from '../../shared/components/scroll-bars/custom_scroll_div';
import CreateGithubVerificationKeyMutation from '../../../mutations/create_github_verification_key_mutation';
import {MODAL_TYPE, showModal} from '../../shared/components/modals/generic_modal_conductor';
import Util from '../../shared/util/util';
import {BUTTON_COLOR, BUTTON_STYLE, ELEMENT_TYPE} from '../../../constants';
import {buildHeaderBar} from '../../shared/components/headers/header-bar/header_bar';
import {GithubIcon, SIZE} from 'web-components';
import {trackEvent, trackPage, unregisterPageInfo} from '../../../tracking/amplitude/TrackingV2';

class appGithub extends React.Component {
	constructor(props) {
		super(props);

		this.superPropertyChecksum = trackPage('App Catalog - Github');
	}

	componentDidMount() {
		document.title = 'GitHub - Apps & Integrations - Forecast';
		// Segment
		tracking.trackPage('settings-app-catalog-github');
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	startOauth() {
		const onSuccess = ({createGithubVerificationKey}) => {
			tracking.trackEvent('Github integration enabled');
			trackEvent('Github Integration', 'Enabled');
			const redirectUri = process.env.GITHUB_REDIRECT_URI;
			const clientId = process.env.GITHUB_CLIENT_ID;
			window.location.href = `https://github.com/login/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&state=${createGithubVerificationKey.key}&scope=repo,read:org`;
		};

		Util.CommitMutation(CreateGithubVerificationKeyMutation, {currentUrl: window.location.href.split('?')[0]}, onSuccess);
	}

	redirectToInstall() {
		window.open(`https://github.com/apps/${process.env.GITHUB_APP_NAME}/installations/new`);
	}

	redirectToUninstall() {
		window.open(
			`https://github.com/apps/${process.env.GITHUB_APP_NAME}/installations/${this.props.viewer.company.githubInstallationId}`
		);
	}

	disable() {
		const {formatMessage} = this.props.intl;
		const deactivateForReal = () => {
			Util.CommitMutation(UpdateCompanyMutation, {
				id: this.props.viewer.company.id,
				githubDeactivate: true,
			});
			this.redirectToUninstall();
		};

		showModal({
			type: MODAL_TYPE.WARNING,
			warningMessageId: 'common.delete-confirmation-title',
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.deactivate'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
					callback: deactivateForReal,
				},
			],
		});
	}

	openHelpCenter() {
		window.open('https://support.forecast.app/hc/en-us/articles/5574680650897');
	}

	storeUnauthorizedInstallation(inst) {
		Util.CommitMutation(UpdateCompanyMutation, {id: this.props.viewer.company.id, githubUnauthorizedInstallation: inst});
	}

	getHeader() {
		const leftContent = [],
			rightContent = [];

		leftContent.push({type: ELEMENT_TYPE.BACK_BUTTON, onClick: this.props.history.goBack});

		return buildHeaderBar(leftContent, rightContent);
	}

	render() {
		const unauthed = this.props.viewer.company.githubUnauthorizedInstallation;
		const authed = this.props.viewer.company.githubInstallationId;

		let inst = null;
		if (window.location.search) {
			const instSplit = window.location.search.split('installation_id=');
			if (instSplit.length > 1) {
				inst = instSplit[1].split('&')[0];
			}
		}

		if (inst) {
			// We have allegedly installed. Store unauthorized id
			this.storeUnauthorizedInstallation(Number.parseInt(inst));
			// Redirect to this page without query string
			this.props.history.push('/admin/integrations/github/');
		}

		let actionButton = null;
		if (!unauthed && !authed) {
			// Enabled but no inst -> install button
			// Nothing at all -> install button
			// Regardless of enabled
			actionButton = (
				<button onClick={() => this.redirectToInstall()}>
					<FormattedMessage id="common.install" />
				</button>
			);
		} else if (unauthed && !authed) {
			// Installed & not enabled -> auth button
			actionButton = (
				<button onClick={() => this.startOauth()}>
					<FormattedMessage id="common.activate" />
				</button>
			);
		} else {
			actionButton = (
				<button onClick={() => this.disable()}>
					<FormattedMessage id="common.deactivate" />
				</button>
			);
		}
		return (
			<div className="section-content settings-app settings-app-page settings-app-slack">
				{this.props.children}
				{this.getHeader()}
				<CustomScrollDiv>
					<div className="section-body">
						<div className="inner">
							<fieldset className="general">
								<div className="header-wrapper">
									<GithubIcon size={SIZE.EXTRA_BIG} />
									<div className="title-description-wrapper">
										<div className="description">
											<FormattedHTMLMessage id="settings.app_catalog.github.description" />
										</div>
									</div>
								</div>
								<div className="content-wrapper">
									<div className="input-fields">{actionButton}</div>
									<button className="help-button" onClick={this.openHelpCenter.bind(this)}>
										<FormattedMessage id="settings.app_catalog.help_button" />
									</button>
								</div>
							</fieldset>
						</div>
					</div>
				</CustomScrollDiv>
			</div>
		);
	}
}

const appGithubQuery = graphql`
	query appGithub_Query {
		viewer {
			actualPersonId
			component(name: "app_github")
			...appGithub_viewer
		}
	}
`;

export {appGithubQuery};

export default createFragmentContainer(injectIntl(withRouter(appGithub)), {
	viewer: graphql`
		fragment appGithub_viewer on Viewer {
			id
			company {
				id
				githubEnabled
				githubInstallationId
				githubUnauthorizedInstallation
			}
		}
	`,
});
