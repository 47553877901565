import styled from 'styled-components';
import {CSS_CONSTANTS} from '../../../../../css_variables';

export const PopupContainer = styled.div`
	min-width: 400px;
	width: auto;
	height: auto;
	z-index: ${props => (props.isTaskModal ? 'auto' : 100)};

	.popup-wrapper {
		padding: 16px;

		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.title {
			font-size: 13px;
			color: ${CSS_CONSTANTS.v2_text_gray};
			font-weight: 500;
		}

		.input-title {
			color: ${CSS_CONSTANTS.v2_text_light_gray};
			font-size: 9px;
			text-transform: uppercase;
			margin-bottom: 8px;
			letter-spacing: 1px;
		}
		.entry-wrapper {
			margin-bottom: 16px;

			.date-picker-button {
				height: 30px;
				border-radius: 4px !important;
			}
		}

		.buttons {
			display: flex;
			justify-content: flex-end;
			height: 30px;

			.custom-button-container .input-container .text {
				padding: 0 8.5px 0 8.5px;
			}
		}
	}
`;
