import React, {useState, useEffect} from 'react';
import {useIntl} from 'react-intl';
import GenericModal, {MODAL_WIDTH} from '../../../containers/modal/generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import {createToast} from '../../shared/components/toasts/another-toast/toaster';
import Util from '../../../forecast-app/shared/util/util';
import {createFragmentContainer, graphql} from 'react-relay';
import AssignMultipleLabelsToSkillCategoryMutation from '../../../mutations/assign_multiple_labels_to_category_mutation';
import DropdownV2 from '../../../forecast-app/shared/components/dropdowns/dropdown';
import {Field, ModalBody} from '../../shared/components/modals/Modal.styled';
import LabelEntityWarning from './label_entity_warning';
import MergeLabelsMutation from '../../../mutations/merge_labels_mutation';

const MoveMultipleLabelsToCategoryModal = ({labelIds, company, closeModal}) => {
	const {formatMessage} = useIntl();
	const [selectedLabelCategory, setSelectedLabelCategory] = useState(null);
	const [errorMessage, setErrorMessage] = useState();
	const [duplicateNames, setDuplicateNames] = useState([]);
	const [existingLabels, setExistingLabels] = useState([]);

	const labelCategories = company.labelCategories.edges.map(labelCategory => labelCategory.node);
	const labelCategoryOptions = labelCategories.map(labelCategory => ({
		value: labelCategory.id,
		label: labelCategory.name,
		allowOnTasks: labelCategory.allowOnTasks,
		allowOnProjects: labelCategory.allowOnProjects,
		allowOnPeople: labelCategory.allowOnPeople,
	}));
	labelCategoryOptions.push({value: null, label: formatMessage({id: 'settings_labels.no_category'})});

	// check if there are any labels assigned to tasks, projects or people
	const labels = company.labels.edges.filter(label => labelIds.has(label.node.id));
	useEffect(() => {
		const selectedCategory = selectedLabelCategory ? selectedLabelCategory.value : null;
		const newExistingLabels = company.labels.edges
			.map(edge => edge.node)
			.filter(label => !labelIds.has(label.id))
			.filter(label => (label.category ? label.category.id === selectedCategory : selectedCategory === null));
		setExistingLabels(newExistingLabels);
		const existingNames = new Set(newExistingLabels.map(label => label.name));
		const newDuplicateNames = labels.map(edge => edge.node).filter(node => (node ? existingNames.has(node.name) : false));
		setDuplicateNames(labels.map(edge => edge.node).filter(node => (node ? existingNames.has(node.name) : false)));
		const categoryString = selectedLabelCategory
			? selectedLabelCategory.label
			: formatMessage({id: 'settings_labels.no_category'});
		if (newDuplicateNames.length >= 1) {
			setErrorMessage(
				formatMessage(
					{id: 'settings_labels.labels_already_exist'},
					{
						amount: newDuplicateNames.length,
						category: categoryString,
					}
				)
			);
		} else {
			setErrorMessage(null);
		}
	}, [selectedLabelCategory]);

	const labelsAssignedTo = {tasks: 0, projects: 0, people: 0};
	labels.forEach(labelNode => {
		const label = labelNode.node;

		if (label.taskCount > 0) {
			labelsAssignedTo.tasks++;
		}

		if (label.projectCount > 0) {
			labelsAssignedTo.projects++;
		}

		if (label.peopleCount > 0) {
			labelsAssignedTo.people++;
		}
	});

	const bulkMoveLabelsToCategory = labelIds => {
		Util.CommitMutation(
			AssignMultipleLabelsToSkillCategoryMutation,
			{
				labelIds,
				categoryId: selectedLabelCategory.value,
			},
			() => {
				createToast({
					duration: 5000,
					message: formatMessage(
						{id: 'settings_labels.labels_moved_to_category'},
						{labelCount: labelIds.length, labelCategory: selectedLabelCategory.label}
					),
				});
			}
		);
	};

	const mergeLabels = (duplicateId, existingLabel) => {
		const idsToBeMerged = [duplicateId, existingLabel.id];
		Util.CommitMutation(
			MergeLabelsMutation,
			{
				labelIds: idsToBeMerged,
				name: existingLabel.name,
				color: existingLabel.color,
				categoryId: selectedLabelCategory.value, // ? selectedLabelCategory.value : null,
				companyId: company.id,
			},
			() => {
				createToast({
					duration: 5000,
					message: formatMessage(
						{id: 'settings_labels.merge_labels_toast'},
						{count: idsToBeMerged.length, name: existingLabel.name}
					),
				});
			}
		);
	};

	const bulkMoveAndMergeLabels = () => {
		const duplicateIds = duplicateNames.map(node => node.id);
		const uniqueIds = Array.from(labelIds).filter(id => !duplicateIds.includes(id));
		for (const duplicateName of duplicateNames) {
			for (const existingLabel of existingLabels) {
				if (duplicateName.name === existingLabel.name) {
					mergeLabels(duplicateName.id, existingLabel);
				}
			}
		}
		uniqueIds.length > 0 && bulkMoveLabelsToCategory(uniqueIds);
	};

	const onSelectCategory = option => {
		setSelectedLabelCategory(option);
	};

	return (
		<GenericModal
			closeModal={closeModal}
			modalWidth={MODAL_WIDTH.SMALL}
			headerText={formatMessage({id: 'settings_labels.move_labels_to_category_title'}, {labelCount: labelIds.size})}
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
					cy: 'button-cancel',
				},
				{
					text: formatMessage({id: 'common.move'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: duplicateNames.length >= 1 ? bulkMoveAndMergeLabels : () => bulkMoveLabelsToCategory(labelIds),
					disabled: !selectedLabelCategory,
					cy: 'button-move',
				},
			]}
			content={
				<ModalBody>
					<Field>
						<DropdownV2
							options={labelCategoryOptions}
							onChange={onSelectCategory}
							value={selectedLabelCategory?.value}
							label={formatMessage({id: 'settings_labels.category'})}
							placeholder={formatMessage({id: 'settings_labels.labels_category_placeholder'})}
							inputName={'label-category-input'}
							inputCy={'label-category'}
							listDataCy={'label-category'}
						/>
					</Field>

					{errorMessage && selectedLabelCategory && (
						<LabelEntityWarning
							updated
							itemWarning={errorMessage}
							key={'warning-duplicate-name'}
							cy={`warning-duplicate-name`}
						/>
					)}

					{selectedLabelCategory &&
						selectedLabelCategory.value !== null &&
						!selectedLabelCategory.allowOnTasks &&
						labelsAssignedTo.tasks > 0 && (
							<LabelEntityWarning
								updated
								itemName={formatMessage(
									{id: 'settings_labels.warning_deletion'},
									{entity: formatMessage({id: 'settings_labels.not_allowed_tasks'})}
								)}
								itemWarning={formatMessage(
									{id: 'settings_labels.warning_multiple_deletions'},
									{
										labelCount: labelsAssignedTo.tasks,
										entity: formatMessage({id: 'settings_labels.not_allowed_tasks'}),
									}
								)}
								key={'warning-labels-assigned-to-tasks'}
								cy={`warning-labels-assigned-to-tasks`}
							/>
						)}

					{selectedLabelCategory &&
						selectedLabelCategory.value !== null &&
						!selectedLabelCategory.allowOnProjects &&
						labelsAssignedTo.projects > 0 && (
							<LabelEntityWarning
								updated
								itemName={formatMessage(
									{id: 'settings_labels.warning_deletion'},
									{entity: formatMessage({id: 'settings_labels.not_allowed_projects'})}
								)}
								itemWarning={formatMessage(
									{id: 'settings_labels.warning_multiple_deletions'},
									{
										labelCount: labelsAssignedTo.projects,
										entity: formatMessage({id: 'settings_labels.not_allowed_projects'}),
									}
								)}
								key={'warning-labels-assigned-to-projects'}
								cy={`warning-labels-assigned-to-projects`}
							/>
						)}

					{selectedLabelCategory &&
						selectedLabelCategory.value !== null &&
						!selectedLabelCategory.allowOnPeople &&
						labelsAssignedTo.people > 0 && (
							<LabelEntityWarning
								updated
								itemName={formatMessage(
									{id: 'settings_labels.warning_deletion'},
									{entity: formatMessage({id: 'settings_labels.not_allowed_people'})}
								)}
								itemWarning={formatMessage(
									{id: 'settings_labels.warning_multiple_deletions'},
									{
										labelCount: labelsAssignedTo.people,
										entity: formatMessage({id: 'settings_labels.not_allowed_people'}),
									}
								)}
								key={'warning-labels-assigned-to-people'}
								cy={`warning-labels-assigned-to-people`}
							/>
						)}
				</ModalBody>
			}
		/>
	);
};

const moveMultipleLabelsToCategoryModalQuery = graphql`
	query MoveMultipleLabelsToCategoryModal_Query {
		viewer {
			actualPersonId
			component(name: "move_multiple_labels_to_category_modal")
			company {
				...MoveMultipleLabelsToCategoryModal_company
			}
		}
	}
`;

export {moveMultipleLabelsToCategoryModalQuery};

export default createFragmentContainer(MoveMultipleLabelsToCategoryModal, {
	company: graphql`
		fragment MoveMultipleLabelsToCategoryModal_company on Company {
			id
			labelCategories(first: 10000) @connection(key: "Company_labelCategories") {
				edges {
					node {
						id
						name
						allowOnTasks
						allowOnProjects
						allowOnPeople
					}
				}
			}
			labels(first: 10000) {
				edges {
					node {
						id
						name
						taskCount
						projectCount
						peopleCount
						category {
							id
						}
						color
					}
				}
			}
		}
	`,
});
