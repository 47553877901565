import React from 'react';
import { CheckboxInfo, CheckBoxRow, CheckBoxSizer, CheckboxSubtitle, CheckboxTitle } from '../TimeManagement_styled';
import { BetaTag, Checkbox } from 'web-components';
import { FlexRow } from '@forecast-it/design-system';
function TimeRegistrationSetting({ title, subtitle, checked, cy, callback, isBeta = false, }) {
    return (React.createElement(CheckBoxRow, null,
        React.createElement(CheckBoxSizer, null,
            React.createElement(Checkbox, { checked: checked, cy: cy, onClick: callback })),
        React.createElement(CheckboxInfo, null,
            React.createElement(FlexRow, null,
                React.createElement(CheckboxTitle, null, title),
                isBeta && React.createElement(BetaTag, null)),
            React.createElement(CheckboxSubtitle, null, subtitle))));
}
export default TimeRegistrationSetting;
