/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type labelRow_labelCategory$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type labelCategoryRow_labelCategory$ref: FragmentReference;
declare export opaque type labelCategoryRow_labelCategory$fragmentType: labelCategoryRow_labelCategory$ref;
export type labelCategoryRow_labelCategory = {|
  +id: string,
  +name: ?string,
  +allowOnTasks: ?boolean,
  +allowOnProjects: ?boolean,
  +allowOnPeople: ?boolean,
  +$fragmentRefs: labelRow_labelCategory$ref,
  +$refType: labelCategoryRow_labelCategory$ref,
|};
export type labelCategoryRow_labelCategory$data = labelCategoryRow_labelCategory;
export type labelCategoryRow_labelCategory$key = {
  +$data?: labelCategoryRow_labelCategory$data,
  +$fragmentRefs: labelCategoryRow_labelCategory$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "labelCategoryRow_labelCategory",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowOnTasks",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowOnProjects",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowOnPeople",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "labelRow_labelCategory"
    }
  ],
  "type": "LabelCategory"
};
// prettier-ignore
(node/*: any*/).hash = '9e653a23185d072f7a5e3a6fd25e3002';

module.exports = node;
