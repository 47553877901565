import React, {useState, useRef} from 'react';
import {EmailLabel, Button, Hexagon, CrossIcon} from 'web-components';
import {
	ButtonsWrapper,
	CancelShareWrapper,
	EmailsWrapper,
	AccessHeader,
	UsersGrantedAccess,
	AlreadySharedContainer,
	EmailWrapper,
	SharedPersonInfoWrapper,
	HexagonWrapper,
	DeactivatedIconWrapper,
	CrossIconWrapper,
	SharedWithWrapper,
	InputHolder,
	ActualInput,
	InputErrorMessage,
} from '../../../containers/modal/ExternalReportModal_style';
import GenericModal, {MODAL_WIDTH} from '../../../containers/modal/generic_modal';
import Util from '../../shared/util/util';
import CreateReportShareMutation from '../../../mutations/create_report_share_mutation';
import DeleteReportShareMutation from '../../../mutations/delete_report_share_mutation';
import {createToast} from '../../shared/components/toasts/another-toast/toaster';
import {useIntl} from 'react-intl';
import {isInValidEmailFormat} from '../../shared/util/EmailUtil';

const externalShareReportModal = props => {
	const [newEmails, setNewEmails] = useState([]);
	const [reportShares, setReportShares] = useState(props.reportShares);
	const [inputErrorMessage, setInputErrorMessage] = useState(null);
	const [disableShare, setDisableShare] = useState(false);
	const inputRef = useRef(null);
	const newEmailRefsArray = useRef([]);
	const intl = useIntl();

	const emailExistsInNewEmailList = email => {
		return newEmails.some(newEmail => newEmail === email);
	};

	const emailExistsInAlreadySharedPersonsList = email => {
		return reportShares.some(reportShare => reportShare.email === email);
	};

	/**
	 * Adds a email to the newEmails list.
	 * Only it is verified to eligible.
	 * @param e
	 */
	const addNewEmail = e => {
		if (e.target.value) {
			const email = e.target.value.trim();
			if (emailExistsInNewEmailList(email)) {
				setInputErrorMessage('Email already exists in this list.');
				e.target.focus();
			} else if (emailExistsInAlreadySharedPersonsList(email)) {
				setInputErrorMessage('The report has been already shared with this email.');
				e.target.focus();
			} else if (isInValidEmailFormat(email)) {
				inputRef.current.value = '';
				setInputErrorMessage(null);
				setNewEmails([...newEmails, email]);
			} else {
				setInputErrorMessage('You have entered an invalid email address.');
				e.target.focus();
			}
		}
	};

	const removeNewEmail = index => {
		const temp = [...newEmails];
		temp.splice(index, 1);
		setNewEmails(temp);
	};

	const handleKeyDown = e => {
		if (e.key === 'Enter' || e.key === 'Tab' || e.key === ',') {
			e.preventDefault();
			addNewEmail(e);
		} else {
			setInputErrorMessage(null);
		}
	};

	const handleBackspace = e => {
		if (e.key === 'Backspace') {
			// If the EmailLabel element is marked, delete it
			if (newEmailRefsArray.current.includes(document.activeElement)) {
				// Get the index of the marked element
				const index = newEmailRefsArray.current.indexOf(document.activeElement);

				// Remove the ref
				newEmailRefsArray.current.splice(index, 1);

				// Remove the email
				removeNewEmail(index);

				inputRef.current.focus();
			}
			// else if the input field is empty, mark the EmailLabel
			else if (!e.target.value) {
				newEmailRefsArray.current[newEmailRefsArray.current.length - 1]?.focus();
			}
		} else {
			// Focus the text field on other input
			inputRef.current.focus();
		}
	};

	const handleOnBlur = e => {
		addNewEmail(e);
	};

	/**
	 * Updates the reportShares list by adding the new reportShare(s) to the list.
	 * The createShareResponse is returned from the graphQL call.
	 * @param createSharesResponse
	 */
	const addReportShares = createSharesResponse => {
		const newReportShares = createSharesResponse.map(response => {
			return {
				email: response.node.user.email,
				sharedId: response.node.id,
			};
		});
		setReportShares([...reportShares, ...newReportShares]);
		setNewEmails([]);
	};

	/**
	 * Updates the reportShares list by removing the deleted report.
	 * The deletedShareId is returned from the GraphQL call.
	 * @param deletedShareId
	 */
	const removeReportShare = deletedShareId => {
		const filteredReportShares = reportShares.filter(reportShare => reportShare.sharedId !== deletedShareId);
		setReportShares([...filteredReportShares]);
	};

	/**
	 * Shares the report with a list of emails.
	 * OnSuccess the created report shares are added to the reportShares list.
	 */
	const handleCreateReportShare = () => {
		if (newEmails.length > 0) {
			setDisableShare(true);
			Util.CommitMutation(
				CreateReportShareMutation,
				{
					reportId: props.reportId,
					emails: newEmails,
				},
				data => {
					if (data.createReportShare.warnings?.length) {
						let emails = data.createReportShare.warnings
							.map(warning => warning.substring(warning.indexOf(':') + 1))
							.join(',');

						createToast({
							duration: 5000,
							message: intl.formatMessage({id: 'shared_report.person_added.error'}) + ' ' + emails,
						});
					} else {
						createToast({
							duration: 5000,
							message: intl.formatMessage({id: 'shared_report.person_added'}),
						});
					}

					addReportShares(data.createReportShare.shareEdges);
					setDisableShare(false);
				}
			);
		}
	};

	/**
	 * Deletes a specific report share.
	 * This removes the users access to the shared report.
	 * OnSuccess the deleted report share is removed from the reportShares list.
	 * @param reportShare the specific reportShare to delete
	 */
	const handleDeleteReportShare = reportShare => {
		Util.CommitMutation(
			DeleteReportShareMutation,
			{
				id: reportShare.sharedId,
				reportId: props.reportId,
			},
			data => {
				removeReportShare(data.deleteReportShare.deletedShareId);
				createToast({
					duration: 5000,
					message: intl.formatMessage({id: 'shared_report.person_removed'}),
				});
			}
		);
	};

	const content = (
		<>
			{inputErrorMessage && <InputErrorMessage>{inputErrorMessage}</InputErrorMessage>}
			<InputHolder>
				<EmailsWrapper onKeyDown={handleBackspace}>
					{newEmails.map((email, index) => (
						<EmailLabel
							key={index}
							text={email}
							onCrossClick={() => removeNewEmail(index)}
							ref={ref => {
								if (ref) newEmailRefsArray.current[index] = ref;
							}}
							tabIndex={index}
						/>
					))}
					<ActualInput
						autoFocus={true}
						placeholder={newEmails.length > 0 ? '' : 'Enter emails to share report with here.'}
						onBlur={handleOnBlur}
						onKeyDown={handleKeyDown}
						ref={inputRef}
						data-cy="share-email"
					/>
				</EmailsWrapper>
			</InputHolder>

			<ButtonsWrapper>
				{/* <CopyLink>Copy Link</CopyLink> */}
				<CancelShareWrapper>
					{/* <Button variant={Button.VARIANT.VERY_LIGHT_GRAY_OUTLINE} isSquare={false}>
						Cancel
					</Button> */}
					<Button
						disabled={disableShare}
						variant={Button.VARIANT.GREEN_FILLED}
						isSquare={false}
						onClick={() => handleCreateReportShare()}
						cy="share-button"
					>
						Share
					</Button>
				</CancelShareWrapper>
			</ButtonsWrapper>

			<AccessHeader>
				<UsersGrantedAccess>Users granted access</UsersGrantedAccess>
			</AccessHeader>

			<AlreadySharedContainer>
				{reportShares
					.sort((rs1, rs2) => rs1.email.localeCompare(rs2.email))
					.map(reportShare => (
						<SharedPersonInfoWrapper>
							<HexagonWrapper>
								<Hexagon text={reportShare.email} />
							</HexagonWrapper>
							<EmailWrapper>{reportShare.email}</EmailWrapper>
							<DeactivatedIconWrapper>
								{/* 	{person.external ? <DeactivatedIcon text={'External user'} /> : <div></div>} */}
							</DeactivatedIconWrapper>
							<CrossIconWrapper>
								<CrossIcon size={CrossIcon.SIZE.SMALL} onClick={() => handleDeleteReportShare(reportShare)} />
							</CrossIconWrapper>
						</SharedPersonInfoWrapper>
					))}
			</AlreadySharedContainer>

			<SharedWithWrapper>This report was shared with {reportShares.length} people</SharedWithWrapper>
		</>
	);
	return (
		<GenericModal
			headerText={'Share Report'}
			content={content}
			closeModal={props.closeModal}
			modalWidth={MODAL_WIDTH.MEDIUM}
		/>
	);
};

export default externalShareReportModal;
