import React from 'react';
import {Button, BUTTON_COLOR, BUTTON_ICONS, BUTTON_SIZE, BUTTON_STYLE} from '../components/buttons/buttonV2/button';
import AiBulkButton, {AIBulkButtonQuery} from '../../shared/components/popups/bulk-select-popup/AIBulkButton';
import ForecastQueryRenderer from '../../../ForecastQueryRenderer';
import {Item, ListSection} from '../../shared/components/popups/bulk-select-popup/bulk_select_popup.styled';

/**
 * takes a function and calls it, if its not undefined
 * @param {Function} callback
 */
const handleClick = callback => {
	if (callback) {
		callback();
	}
};

/**
 * Recursive function calling itself with options until options do not exist
 * @param {object} options
 * @param {number} count default 1
 */
export const getListOptions = (options, count = 1) => {
	let result = [];

	result = options.map((option, i) =>
		option.section ? (
			<ListSection key={i + count}>
				<Item
					disabled={option.disabled}
					onClick={() => !option.disabled && handleClick(option.callback)}
					indent={count}
					isTitle={option.options}
				>
					{option.label}
				</Item>
				{option.options && getListOptions(option.options, count + 1)}
			</ListSection>
		) : (
			<React.Fragment key={i + count}>
				<Item
					disabled={option.disabled}
					onClick={() => !option.disabled && handleClick(option.callback)}
					indent={count}
					isTitle={option.options}
				>
					{option.label}
				</Item>
				{option.options && getListOptions(option.options, count + 1)}
			</React.Fragment>
		)
	);

	return result;
};

export const getComplexBulkOptions = (showAutomateModal, additionalAutomateOptions, intl) => {
	return [
		{
			id: 'automate',
			renderButton: isSelected => (
				<ForecastQueryRenderer
					key="query-render-ai-button"
					query={AIBulkButtonQuery}
					customLoader={() => {
						return (
							<Button
								disabled={true}
								buttonColor={BUTTON_COLOR.VERYLIGHTGREY}
								buttonStyle={BUTTON_STYLE.OUTLINE}
								size={BUTTON_SIZE.STANDARD}
								content={intl.formatMessage({id: 'common.automate'})}
								iconPosition="first"
								iconName={BUTTON_ICONS.AI_OUTLINE}
							/>
						);
					}}
					render={(relayProps, retry) => {
						return (
							<AiBulkButton
								retry={retry}
								{...relayProps}
								showAutomateModal={showAutomateModal}
								listOptions={getListOptions}
								additionalAutomateOptions={additionalAutomateOptions}
								isButtonSelected={isSelected}
							/>
						);
					}}
				/>
			),
		},
	];
};
