import { useIntl } from 'react-intl';
import { notNullishAndPredicate } from '../../../util/NotNullPredicate';
import { PROJECT_STATUS } from '../../../../../constants';
// In order to ensure that the connected project parent is always shown when a connected project child is shown, the searchstring for a connected project contains the concatenation of all searchstrings from its children.
function getSearchStringForProjectGroup(projectGroupName, companyProjectGroupId, projectGroupProjects) {
    const projectGroupProjectsSearchStrings = projectGroupProjects.map(project => project.searchString).join(' ');
    return `${projectGroupName !== null && projectGroupName !== void 0 ? projectGroupName : ''} X${companyProjectGroupId !== null && companyProjectGroupId !== void 0 ? companyProjectGroupId : ''} ${projectGroupProjectsSearchStrings}`;
}
function projectNodeToProjectDropdownItem(project, sortValue) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    return {
        id: project.id,
        projectColor: (_a = project.projectColor) !== null && _a !== void 0 ? _a : '',
        name: (_b = project.name) !== null && _b !== void 0 ? _b : '',
        label: (_c = project.name) !== null && _c !== void 0 ? _c : '',
        searchString: `${(_d = project.name) !== null && _d !== void 0 ? _d : ''} ${(_e = project.customProjectId) !== null && _e !== void 0 ? _e : ''}`,
        companyProjectId: (_f = project.companyProjectId) !== null && _f !== void 0 ? _f : -1,
        customProjectId: (_g = project.customProjectId) !== null && _g !== void 0 ? _g : '',
        projectGroupId: (_h = project.projectGroupId) !== null && _h !== void 0 ? _h : undefined,
        isInProjectGroup: !!project.projectGroupId,
        sortValue: (_j = sortValue !== null && sortValue !== void 0 ? sortValue : project.companyProjectId) !== null && _j !== void 0 ? _j : -1,
    };
}
function projectGroupNodeToProjectDropdownItem(projectGroup, searchString) {
    var _a, _b, _c, _d;
    return {
        color: (_a = projectGroup.color) !== null && _a !== void 0 ? _a : '',
        name: (_b = projectGroup.name) !== null && _b !== void 0 ? _b : '',
        label: (_c = projectGroup.name) !== null && _c !== void 0 ? _c : '',
        searchString,
        companyProjectGroupId: (_d = projectGroup.companyProjectGroupId) !== null && _d !== void 0 ? _d : -1,
        isProjectGroup: true,
        sortValue: projectGroup.companyProjectGroupId + '_group',
    };
}
function getProjectItemsForStatuses(projects, statuses, externalSortValue) {
    return projects
        .map(project => project === null || project === void 0 ? void 0 : project.node)
        .filter(notNullishAndPredicate(project => { var _a; return statuses.includes((_a = project === null || project === void 0 ? void 0 : project.status) !== null && _a !== void 0 ? _a : ''); }))
        .map(project => projectNodeToProjectDropdownItem(project, externalSortValue));
}
function getProjectGroupNodeItemsForStatuses(projectGroupNode, statuses) {
    var _a;
    const projectGroupProjectItemsForStatus = ((_a = projectGroupNode.projects) === null || _a === void 0 ? void 0 : _a.edges)
        ? getProjectItemsForStatuses(projectGroupNode.projects.edges, statuses, projectGroupNode.companyProjectGroupId + '_group')
        : [];
    if (projectGroupProjectItemsForStatus.length === 0)
        return [];
    const searchString = getSearchStringForProjectGroup(projectGroupNode.name, projectGroupNode.companyProjectGroupId, projectGroupProjectItemsForStatus);
    const projectGroupItem = projectGroupNodeToProjectDropdownItem(projectGroupNode, searchString);
    return [projectGroupItem, ...projectGroupProjectItemsForStatus];
}
function getProjectGroupItemsForStatuses(projectGroups, statuses) {
    return projectGroups
        .map(projectGroup => projectGroup === null || projectGroup === void 0 ? void 0 : projectGroup.node)
        .filter(notNullishAndPredicate())
        .flatMap(projectGroup => getProjectGroupNodeItemsForStatuses(projectGroup, statuses));
}
function getDropdownItemsForStatuses(projects, projectGroups, statuses) {
    const projectItemsForStatus = getProjectItemsForStatuses(projects, statuses);
    const projectGroupItemsForStatus = getProjectGroupItemsForStatuses(projectGroups, statuses);
    return [...projectItemsForStatus, ...projectGroupItemsForStatus];
}
function getProjectOptions(viewer, hasOpportunityAccess, intl) {
    var _a, _b, _c, _d;
    const projects = (_b = (_a = viewer.projects) === null || _a === void 0 ? void 0 : _a.edges) !== null && _b !== void 0 ? _b : [];
    const projectGroups = (_d = (_c = viewer.projectGroups) === null || _c === void 0 ? void 0 : _c.edges) !== null && _d !== void 0 ? _d : [];
    const runningProjects = getDropdownItemsForStatuses(projects, projectGroups, [PROJECT_STATUS.RUNNING]);
    const planningProjects = hasOpportunityAccess
        ? getDropdownItemsForStatuses(projects, projectGroups, [PROJECT_STATUS.PLANNING])
        : getDropdownItemsForStatuses(projects, projectGroups, [PROJECT_STATUS.PLANNING, PROJECT_STATUS.OPPORTUNITY]);
    const opportunityProjects = hasOpportunityAccess
        ? getDropdownItemsForStatuses(projects, projectGroups, PROJECT_STATUS.OPPORTUNITY)
        : [];
    const sortProjects = (a, b) => {
        if (a.sortValue < b.sortValue)
            return -1;
        if (a.sortValue > b.sortValue)
            return 1;
        if (a.sortValue === b.sortValue) {
            if (a.isInProjectGroup && !b.isInProjectGroup) {
                return 1;
            }
        }
        return 0;
    };
    runningProjects.sort(sortProjects);
    planningProjects.sort(sortProjects);
    opportunityProjects.sort(sortProjects);
    const projectOptions = [
        {
            value: 'running_projects',
            label: intl.formatMessage({ id: 'project_status.running' }),
            nestedOptions: runningProjects,
        },
        {
            value: 'planning_projects',
            label: intl.formatMessage({ id: 'project_status.planning' }),
            nestedOptions: hasOpportunityAccess ? planningProjects : [...planningProjects, ...opportunityProjects],
        },
    ];
    if (hasOpportunityAccess) {
        projectOptions.push({
            value: 'opportunity_projects',
            label: intl.formatMessage({ id: 'project_status.opportunity' }),
            nestedOptions: hasOpportunityAccess ? opportunityProjects : [],
        });
    }
    return projectOptions;
}
export const useProjectDropdownController = (viewer, hasOpportunityAccess, // Supplied as a prop since it's loaded from session storage, meaning it is hard to mock for testing
selectedProject) => {
    var _a, _b;
    const intl = useIntl();
    const projectOptions = getProjectOptions(viewer, hasOpportunityAccess, intl);
    const selectedProjectLabel = (_b = (_a = selectedProject === null || selectedProject === void 0 ? void 0 : selectedProject.client) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : intl.formatMessage({ id: 'common.project' });
    return { selectedProjectLabel, projectOptions };
};
