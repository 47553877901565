/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type WeekendVisibility_company$ref: FragmentReference;
declare export opaque type WeekendVisibility_company$fragmentType: WeekendVisibility_company$ref;
export type WeekendVisibility_company = {|
  +weekendDisplayPerUser: ?boolean,
  +weekendDisplayShowAlways: ?boolean,
  +createdAt: ?string,
  +$refType: WeekendVisibility_company$ref,
|};
export type WeekendVisibility_company$data = WeekendVisibility_company;
export type WeekendVisibility_company$key = {
  +$data?: WeekendVisibility_company$data,
  +$fragmentRefs: WeekendVisibility_company$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WeekendVisibility_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weekendDisplayPerUser",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weekendDisplayShowAlways",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    }
  ],
  "type": "Company"
};
// prettier-ignore
(node/*: any*/).hash = '58cbddeadcc93ca828e3924985d6f159';

module.exports = node;
