/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
query BulkInvoiceCreateModal_Query(
  $projectId: ID
) {
  viewer {
    component(name: "bulk_invoice_create_modal_query")
    actualPersonId
    ...BulkInvoiceCreateModal_viewer_2CgS8h
    id
  }
}

fragment BulkInvoiceCreateModal_viewer_2CgS8h on Viewer {
  company {
    currency
    invoiceQuantityDecimals
    exchangeRates {
      edges {
        node {
          id
          currency
          rate
        }
      }
    }
    id
  }
  project(internalId: $projectId) {
    id
    companyProjectId
    customProjectId
    name
    client {
      id
    }
    rateCard {
      id
      name
      currency
      parentRateCardId
      defaultRate
      rates(first: 10000) {
        edges {
          node {
            id
            rate
            startDate
            defaultRate
            role {
              id
              name
            }
          }
        }
      }
    }
    retainerPeriods(first: 100000) {
      edges {
        node {
          id
          name
          startYear
          startMonth
          startDay
          endYear
          endMonth
          endDay
          periodPriceAmount
          periodHoursAmount
          periodBudgetType
          periodLocked
          periodLockedTime
          invoiced
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    timeRegistrations(first: 10000000) {
      edges {
        node {
          id
          day
          month
          year
          createdAt
          updatedAt
          minutesRegistered
          billableMinutesRegistered
          invoiced
          price
          retainerConflictHandled
          rate
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
}
*/
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "projectId",
            "type": "ID"
        }
    ], v1 = {
        "alias": null,
        "args": [
            {
                "kind": "Literal",
                "name": "name",
                "value": "bulk_invoice_create_modal_query"
            }
        ],
        "kind": "ScalarField",
        "name": "component",
        "storageKey": "component(name:\"bulk_invoice_create_modal_query\")"
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actualPersonId",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currency",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rate",
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "defaultRate",
        "storageKey": null
    }, v8 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 100000
        }
    ], v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "invoiced",
        "storageKey": null
    }, v10 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    }, v11 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
    }, v12 = {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v13 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 10000000
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "BulkInvoiceCreateModal_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "projectId",
                                    "variableName": "projectId"
                                }
                            ],
                            "kind": "FragmentSpread",
                            "name": "BulkInvoiceCreateModal_viewer"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "BulkInvoiceCreateModal_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Company",
                            "kind": "LinkedField",
                            "name": "company",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "invoiceQuantityDecimals",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ExchangeRateTypeConnection",
                                    "kind": "LinkedField",
                                    "name": "exchangeRates",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ExchangeRateTypeEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ExchangeRate",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v4 /*: any*/),
                                                        (v3 /*: any*/),
                                                        (v5 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                (v4 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "internalId",
                                    "variableName": "projectId"
                                }
                            ],
                            "concreteType": "ProjectType",
                            "kind": "LinkedField",
                            "name": "project",
                            "plural": false,
                            "selections": [
                                (v4 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "companyProjectId",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "customProjectId",
                                    "storageKey": null
                                },
                                (v6 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Client",
                                    "kind": "LinkedField",
                                    "name": "client",
                                    "plural": false,
                                    "selections": [
                                        (v4 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "RateCard",
                                    "kind": "LinkedField",
                                    "name": "rateCard",
                                    "plural": false,
                                    "selections": [
                                        (v4 /*: any*/),
                                        (v6 /*: any*/),
                                        (v3 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "parentRateCardId",
                                            "storageKey": null
                                        },
                                        (v7 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": [
                                                {
                                                    "kind": "Literal",
                                                    "name": "first",
                                                    "value": 10000
                                                }
                                            ],
                                            "concreteType": "RateTypeConnection",
                                            "kind": "LinkedField",
                                            "name": "rates",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "RateTypeEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "Rate",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                                (v4 /*: any*/),
                                                                (v5 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "startDate",
                                                                    "storageKey": null
                                                                },
                                                                (v7 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "Role",
                                                                    "kind": "LinkedField",
                                                                    "name": "role",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        (v4 /*: any*/),
                                                                        (v6 /*: any*/)
                                                                    ],
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": "rates(first:10000)"
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": (v8 /*: any*/),
                                    "concreteType": "RetainerPeriodTypeConnection",
                                    "kind": "LinkedField",
                                    "name": "retainerPeriods",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "RetainerPeriodTypeEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "RetainerPeriodType",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v4 /*: any*/),
                                                        (v6 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "startYear",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "startMonth",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "startDay",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "endYear",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "endMonth",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "endDay",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "periodPriceAmount",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "periodHoursAmount",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "periodBudgetType",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "periodLocked",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "periodLockedTime",
                                                            "storageKey": null
                                                        },
                                                        (v9 /*: any*/),
                                                        (v10 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v11 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v12 /*: any*/)
                                    ],
                                    "storageKey": "retainerPeriods(first:100000)"
                                },
                                {
                                    "alias": null,
                                    "args": (v8 /*: any*/),
                                    "filters": [],
                                    "handle": "connection",
                                    "key": "Project_retainerPeriods",
                                    "kind": "LinkedHandle",
                                    "name": "retainerPeriods"
                                },
                                {
                                    "alias": null,
                                    "args": (v13 /*: any*/),
                                    "concreteType": "TimeRegTypeConnection",
                                    "kind": "LinkedField",
                                    "name": "timeRegistrations",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "TimeRegTypeEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "TimeRegType",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v4 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "day",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "month",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "year",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "createdAt",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "updatedAt",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "minutesRegistered",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "billableMinutesRegistered",
                                                            "storageKey": null
                                                        },
                                                        (v9 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "price",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "retainerConflictHandled",
                                                            "storageKey": null
                                                        },
                                                        (v5 /*: any*/),
                                                        (v10 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v11 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v12 /*: any*/)
                                    ],
                                    "storageKey": "timeRegistrations(first:10000000)"
                                },
                                {
                                    "alias": null,
                                    "args": (v13 /*: any*/),
                                    "filters": [],
                                    "handle": "connection",
                                    "key": "Project_timeRegistrations",
                                    "kind": "LinkedHandle",
                                    "name": "timeRegistrations"
                                }
                            ],
                            "storageKey": null
                        },
                        (v4 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "BulkInvoiceCreateModal_Query",
            "operationKind": "query",
            "text": "query BulkInvoiceCreateModal_Query(\n  $projectId: ID\n) {\n  viewer {\n    component(name: \"bulk_invoice_create_modal_query\")\n    actualPersonId\n    ...BulkInvoiceCreateModal_viewer_2CgS8h\n    id\n  }\n}\n\nfragment BulkInvoiceCreateModal_viewer_2CgS8h on Viewer {\n  company {\n    currency\n    invoiceQuantityDecimals\n    exchangeRates {\n      edges {\n        node {\n          id\n          currency\n          rate\n        }\n      }\n    }\n    id\n  }\n  project(internalId: $projectId) {\n    id\n    companyProjectId\n    customProjectId\n    name\n    client {\n      id\n    }\n    rateCard {\n      id\n      name\n      currency\n      parentRateCardId\n      defaultRate\n      rates(first: 10000) {\n        edges {\n          node {\n            id\n            rate\n            startDate\n            defaultRate\n            role {\n              id\n              name\n            }\n          }\n        }\n      }\n    }\n    retainerPeriods(first: 100000) {\n      edges {\n        node {\n          id\n          name\n          startYear\n          startMonth\n          startDay\n          endYear\n          endMonth\n          endDay\n          periodPriceAmount\n          periodHoursAmount\n          periodBudgetType\n          periodLocked\n          periodLockedTime\n          invoiced\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    timeRegistrations(first: 10000000) {\n      edges {\n        node {\n          id\n          day\n          month\n          year\n          createdAt\n          updatedAt\n          minutesRegistered\n          billableMinutesRegistered\n          invoiced\n          price\n          retainerConflictHandled\n          rate\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = '4379a29cccb95df2e6eab1d22116d08f';
export default node;
