import React from 'react';
import * as PropTypes from 'prop-types';

import Util from '../../util/util';
import styled from 'styled-components';
import projectGroupIcon from '../../../../images/sections/scheduling-canvas/project-group-icon.svg';
import projectGroupIconWhite from '../../../../images/sections/scheduling-canvas/project-group-icon-white.svg';
import {projectUrl} from '../../../../directApi';

const ProjectIndicatorStyle = styled.a`
	text-decoration: none;
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: ${props => (props.group ? '60px' : '42px')};
	max-width: ${props => (props.group ? '60px' : '42px')};
	overflow: hidden;
	height: 20px;
	border-radius: 6px;
	font-size: 10px;
	font-weight: 700;
	float: left;
	padding-left: 10px;
	padding-right: 10px;
	.project-group-content {
		height: 14px;
		display: flex;
		flex-direction: row;
		align-items: center;

		.project-group-icon {
			height: 14px;
			img {
				width: 14px;
				height: 14px;
			}
		}

		.project-group-text {
			margin-left: ${props => (props.group ? '8px' : '0')};
		}
	}
`;

export const getNavigationString = (project, clearSubPath) => {
	const curLocArr = window.location.href.split('/');
	const pageName = curLocArr[curLocArr.length - 1];
	if (project.companyProjectGroupId) {
		return '/connected/X-' + project.companyProjectGroupId + '/' + (clearSubPath || pageName === 'my-list' ? '' : pageName);
	}
	return (
		projectUrl(project.companyProjectId, project.customProjectId) +
		'/' +
		(clearSubPath || pageName === 'my-list' ? '' : pageName)
	);
};

export const ProjectIndicator = ({project, clearSubPath, noTitle, disableLink}) => {
	if (!project) return null;

	const textColor = Util.getTextColorV2(project.color || project.projectColor);
	const title =
		(project.companyProjectGroupId ? 'X' + project.companyProjectGroupId : 'P' + project.companyProjectId) +
		' - ' +
		project.name;
	const icon = textColor === '#ffffff' ? projectGroupIconWhite : projectGroupIcon;

	return project.companyProjectGroupId ? (
		<ProjectIndicatorStyle
			className={'project-indicator-component1'}
			group={true}
			href={disableLink ? null : getNavigationString(project, clearSubPath)}
			tabIndex="0"
			title={noTitle ? '' : title}
			style={{backgroundColor: project.color, color: textColor}}
		>
			<div className="project-group-content">
				<div className="project-group-icon">
					<img src={icon} alt={title} />
				</div>
				<div className="project-group-text">X{project.companyProjectGroupId}</div>
			</div>
		</ProjectIndicatorStyle>
	) : (
		<ProjectIndicatorStyle
			className={'project-indicator-component2'}
			group={false}
			href={disableLink ? null : getNavigationString(project, clearSubPath)}
			tabIndex="0"
			title={noTitle ? '' : title}
			style={{backgroundColor: project.projectColor, color: textColor}}
		>
			<div className="project-group-content">
				<div className="project-group-text" data-cy="project-id">
					P{project.companyProjectId}
				</div>
			</div>
		</ProjectIndicatorStyle>
	);
};

ProjectIndicator.propTypes = {
	project: PropTypes.object.isRequired,
	clearSubPath: PropTypes.bool,
	noTitle: PropTypes.bool,
	disableLink: PropTypes.bool,
};

ProjectIndicator.defaultProps = {
	clearSubPath: false,
};
