/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RoleDropdownQueryVariables = {|
  taskId?: ?string,
  taskName?: ?string,
  projectId?: ?string,
|};
export type RoleDropdownQueryResponse = {|
  +viewer: ?{|
    +component: ?string,
    +company: ?{|
      +availableMLModels: ?{|
        +roleModel: ?boolean
      |}
    |},
    +suggestedRoles: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |}
      |}>
    |},
  |}
|};
export type RoleDropdownQuery = {|
  variables: RoleDropdownQueryVariables,
  response: RoleDropdownQueryResponse,
|};
*/


/*
query RoleDropdownQuery(
  $taskId: ID
  $taskName: String
  $projectId: ID
) {
  viewer {
    component(name: "suggested_role_dropdown")
    company {
      availableMLModels {
        roleModel
      }
      id
    }
    suggestedRoles(first: 100000, taskId: $taskId, taskName: $taskName, projectId: $projectId) {
      edges {
        node {
          id
          name
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "taskId",
    "type": "ID"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "taskName",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId",
    "type": "ID"
  }
],
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "suggested_role_dropdown"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"suggested_role_dropdown\")"
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "AvailableMLModels",
  "kind": "LinkedField",
  "name": "availableMLModels",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "roleModel",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 100000
    },
    {
      "kind": "Variable",
      "name": "projectId",
      "variableName": "projectId"
    },
    {
      "kind": "Variable",
      "name": "taskId",
      "variableName": "taskId"
    },
    {
      "kind": "Variable",
      "name": "taskName",
      "variableName": "taskName"
    }
  ],
  "concreteType": "RoleTypeConnection",
  "kind": "LinkedField",
  "name": "suggestedRoles",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RoleTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Role",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RoleDropdownQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RoleDropdownQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "RoleDropdownQuery",
    "operationKind": "query",
    "text": "query RoleDropdownQuery(\n  $taskId: ID\n  $taskName: String\n  $projectId: ID\n) {\n  viewer {\n    component(name: \"suggested_role_dropdown\")\n    company {\n      availableMLModels {\n        roleModel\n      }\n      id\n    }\n    suggestedRoles(first: 100000, taskId: $taskId, taskName: $taskName, projectId: $projectId) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '63f26dca647c6b1a28424a319f43ac4e';

module.exports = node;
