import Styled from 'styled-components';

export const LabelCheckboxStyled = Styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: 12px;

	label {
		margin-left: 10px;
		font-weight: 400;
		font-size: 13px;
	}
`;

export const LabelCheckboxheaderStyled = Styled.div`
	margin: 20px 0;

	span {
		color: #8E8F9F;
		font-size: 13px;	
		font-weight: 400;
	}
`;
