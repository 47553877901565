import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import Styled from 'styled-components';
import Util from '../../../../../util/util';
import {Column} from '../Elements.styled';

import UpdateCustomFieldValueMutation from '../../../../../../../mutations/update_custom_field_value_mutation';

const ColumnWrapper = styled.div`
	display: flex;
`;

export const CustomFieldInput = Styled.input`
    background-color: ${props => props.backgroundColor};
    color: ${({backgroundColor}) => Util.getTextColorV2(backgroundColor)};
    border-radius: 4px;
    border: ${props => (props.showBox ? '1px solid #e6e6e6' : '0px')};
    padding: ${props => (props.showBox ? '6px 15px 6px 10px' : '0px')};
    text-overflow: ellipsis;
    height: 24px;
    width: 100%;
    &:hover {
        background-color: ${props => (props.showBox ? props.backgroundColor : 'rgba(0, 0, 0, 0.1)')};
    }
    &:focus {
   		background-color: ${props => (props.showBox ? props.backgroundColor : 'rgba(0, 0, 0, 0.2)')};
        outline: none;
    }
`;

export const CustomFieldColumn = ({
	readOnly,
	customFieldKey,
	value,
	entityType,
	entityId,
	width,
	cy = '',
	backgroundColor,
	showBox,
}) => {
	const [currentValue, setCurrentValue] = useState(value || '');

	useEffect(() => {
		setCurrentValue(value);
	}, [value]);

	useEffect(() => {
		setCurrentValue(currentValue);
	}, [currentValue]);
	const inputRef = useRef();

	const handleValueChange = e => {
		setCurrentValue(e.target.value);
	};

	const handleKeyPress = e => {
		if (e.key === 'Enter') {
			inputRef?.current?.blur();
		}
	};

	const handleSave = () => {
		if (value !== currentValue) {
			Util.CommitMutation(UpdateCustomFieldValueMutation, {
				entityId,
				entityType,
				key: customFieldKey,
				value: currentValue,
			});
		}
	};

	return (
		<Column width={width}>
			{readOnly ? (
				<span>{currentValue}</span>
			) : (
				<ColumnWrapper>
					<CustomFieldInput
						onClick={e => e.stopPropagation()}
						data-cy={'custom-field-value-input' + cy}
						type="text"
						ref={inputRef}
						value={currentValue}
						onChange={handleValueChange}
						onBlur={handleSave}
						spellCheck={false}
						maxLength={191}
						onKeyPress={handleKeyPress}
						backgroundColor={backgroundColor}
						showBox={showBox}
					/>
				</ColumnWrapper>
			)}
		</Column>
	);
};
