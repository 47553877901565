import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import {CustomScrollDiv} from 'web-components';
import DeprecatedProjectIndicatorJS from '../../project-indicator/js/DeprecatedProjectIndicatorJS';

class ProjectDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: false,
			selectedProject: props.selectedProject,
		};
	}

	handleProjectSelection(e, option) {
		e.stopPropagation();
		e.preventDefault();
		if (option.node.status.includes('HALTED') || option.node.status.includes('DONE')) {
			return;
		}
		this.props.onChange(option);
		this.setState({expanded: false, selectedProject: option});
	}

	toggleExpand(e) {
		e.stopPropagation();
		e.preventDefault();

		const h = this.props.contextHeight
			? this.props.contextHeight
			: Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
		const y = e.currentTarget.getBoundingClientRect().bottom;
		//const popOutSize = Math.min(this.props.timeRegItem.timeRegistrationsWithNotes.length, 2.5) * noteHeight + marginsAndHeader + minSpace;
		const numRenderedItems = this.projectOptions.length;
		// Each row is 36px
		const popOutSize = numRenderedItems * 36;

		const isCloseToEdge = h - 20 - y <= popOutSize;
		const wouldFoldOverTop = y - popOutSize - 148 <= 0;

		if (isCloseToEdge && !wouldFoldOverTop) {
			this.setState({foldUp: true});
		} else {
			this.setState({foldUp: false});
		}
		this.setState(prevState => {
			return {expanded: !prevState.expanded};
		});
	}

	onPreselect(project) {
		this.setState({
			preselect: project,
		});
	}

	handleKeyDown(e) {
		if (this.props.selectedProject === undefined) return;
		let next = this.projectOptions.findIndex(project => project.node.id === this.state.selectedProject.node.id);
		const mod = this.projectOptions.length;
		let projectToSelect = this.state.selectedProject;

		if (e.keyCode === 27) {
			// Escape
			this.setState({expanded: false});
		} else if (e.keyCode === 13) {
			//Enter
			this.handleProjectSelection(e, projectToSelect);
		} else if (e.keyCode === 38) {
			//up
			next--;
			next = ((next % mod) + mod) % mod;
			projectToSelect = this.projectOptions[next];
			if (projectToSelect) {
				this.setState({selectedProject: projectToSelect});
			}
			this.onPreselect(projectToSelect.node.id);
		} else if (e.keyCode === 40) {
			//down
			next++;
			next = ((next % mod) + mod) % mod;
			projectToSelect = this.projectOptions[next];
			if (projectToSelect) {
				this.setState({selectedProject: projectToSelect});
			}
			this.onPreselect(projectToSelect.node.id);
		}
	}

	handleBlur(e) {
		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11
		if (this.container && this.container.contains(newTarget)) {
			return;
		}
		//if (newTarget && (newTarget.id === 'client-selector-input' || newTarget.id === 'client-selector-container')) return;
		this.setState({expanded: false});
	}

	render() {
		const projectIsDoneOrHalted = project => project.node.status === 'DONE' || project.node.status === 'HALTED';
		this.projectOptions = this.props.projectOptions.filter(project => !projectIsDoneOrHalted(project));
		let popOutSize = this.projectOptions.length * 30;
		popOutSize = popOutSize > 238 ? 238 : popOutSize;
		return (
			<div
				ref={div => (this.container = div)}
				className={'project-dropdown-container'}
				tabIndex="0"
				onBlur={e => this.handleBlur(e)}
			>
				<button
					className={'project-dropdown-button' + (this.state.expanded ? ' expanded' : '')}
					onClick={e => this.toggleExpand(e)}
					onKeyDown={this.handleKeyDown.bind(this)}
				>
					{this.props.selectedProject ? (
						<DeprecatedProjectIndicatorJS project={this.props.selectedProject.node} disableLink noWrapper />
					) : null}
				</button>
				<div className={'line-container'}>
					<div className={'line'} />
				</div>
				{this.state.expanded ? (
					<div
						className="project-options-container"
						style={
							this.state.foldUp
								? {
										top: -1 * popOutSize - 8,
										width: this.props.customWidth ? this.props.customWidth + 'px' : 'auto',
										height: `${popOutSize}px`,
										maxHeight: '240px',
								  }
								: {
										width: this.props.customWidth ? this.props.customWidth + 'px' : 'auto',
										height: `${popOutSize}px`,
										maxHeight: '240px',
								  }
						}
					>
						<CustomScrollDiv autoHeight autoHeightMax={popOutSize} autoHeightMin={popOutSize}>
							<ul className={'project-options'}>
								{this.projectOptions.map((project, idx) => (
									<li
										ref={li => (this['list-items' + idx] = li)}
										tabIndex="0"
										onKeyDown={e => this.handleKeyDown(e)}
										key={project.node.companyProjectId}
										className={
											'project-option ' +
											project.node.status.toLowerCase() +
											(this.state.preselect === project.node.id ? ' preselected' : '')
										}
										onClick={e => this.handleProjectSelection(e, project)}
										title={project.node.name}
									>
										<DeprecatedProjectIndicatorJS project={project.node} disableLink noWrapper />
										<div
											className={
												'project-name' +
												(this.state.selectedProject &&
												this.state.selectedProject.node.id === project.node.id
													? ' selected'
													: '')
											}
										>
											{project.node.name}
										</div>
									</li>
								))}
							</ul>
						</CustomScrollDiv>
					</div>
				) : null}
			</div>
		);
	}
}
ProjectDropdown.propTypes = {
	projectOptions: PropTypes.array.isRequired,
};
export default injectIntl(ProjectDropdown);
