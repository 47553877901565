import React from 'react';

const informationIcon = props => (
	<svg
		className={'information-icon ' + (props.className ? props.className : '')}
		xmlns="http://www.w3.org/2000/svg"
		width={props.customWidth ? props.customWidth : '15'}
		height={props.customHeight ? props.customHeight : '15'}
		viewBox={'0 0' + (props.customWidth ? ' ' + props.customWidth : ' 15') + (props.customHeight ? ' ' + props.customHeight : ' 15')}
	>
		<g fill={props.fill ? props.fill : '#A1A1A1'} fillRule="evenodd">
			<path d="M7.5 1.5c-3.308 0-6 2.692-6 6s2.692 6 6 6 6-2.692 6-6-2.692-6-6-6m0 13.5C3.364 15 0 11.636 0 7.5S3.364 0 7.5 0 15 3.365 15 7.5c0 4.136-3.364 7.5-7.5 7.5" />
			<path d="M8.25 9.75v-3A.75.75 0 0 0 7.5 6H6v1.5h.75v2.25h-1.5v1.5h4.5v-1.5h-1.5zM8.438 4.5a.938.938 0 1 1-1.876 0 .938.938 0 0 1 1.875 0" />
		</g>
	</svg>
);

export default informationIcon;
