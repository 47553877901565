import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {createFragmentContainer, graphql} from 'react-relay';
import {AssigneeGroup, Dropdown, HexagonText} from 'web-components';
import * as tracking from '../../../../tracking';
import {useForecastFetchQuery} from '../../hooks/useForecastFetchQuery';
import {useIntl} from 'react-intl';
import Util from '../../util/util';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';
import {profilePicSrc} from '../../../../directApi';

const suggestionsQuery = graphql`
	query PersonDropdownQuery($taskId: ID, $taskName: String, $projectId: ID) {
		viewer {
			component(name: "suggested_person_dropdown")
			company {
				availableMLModels {
					assigneeModel
				}
			}
			suggestedPersons(first: 100000, taskId: $taskId, taskName: $taskName, projectId: $projectId) {
				edges {
					node {
						id
						active
						profilePictureId
						profilePictureDefaultId
						fullName
						role {
							id
							name
						}
					}
				}
			}
		}
	}
`;

export const PersonDropdown = ({
	persons,
	dropdownAlignment,
	width,
	name,
	selectedItems,
	showRole,
	onRemove,
	onSelect,
	optionsName,
	selectedGroupName,
	disabled,
	userpilot,
	emphasizeEmptyState,
	usePortal,
	taskId,
	filterClients,
	selectedItemsPlaceholder,
	taskName,
	projectId,
	useSuggestions,
	focusOnMount,
	includeDeactivatedPersons,
	cy,
	headerLines,
}) => {
	const intl = useIntl();
	const {fetch, data} = useForecastFetchQuery(suggestionsQuery);

	const isFetching = useRef(false);
	const handleFetch = variables => {
		const onFetchSuccess = () => {
			isFetching.current = false;
		};

		if (!isFetching.current) {
			isFetching.current = true;
			fetch(variables, onFetchSuccess);
		}
	};

	const handleExpand = () => {
		if (taskId && useSuggestions) {
			handleFetch({taskId});
		} else if (taskName && projectId && useSuggestions) {
			const encodeTaskName = encodeURI(taskName);
			handleFetch({taskName: encodeTaskName, projectId});
		}
	};

	const sortedPersons = [...persons]
		.filter(person => (includeDeactivatedPersons || person.node.active) && (!filterClients || !person.node.client))
		.sort((a, b) => {
			if (a.node.id === null) return -1;
			if (b.node.id === null) return 1;
			if (
				`${a.node.firstName ? a.node.firstName.toLowerCase() : ''} ${
					a.node.lastName ? a.node.lastName.toLowerCase() : ''
				}` <
				`${b.node.firstName ? b.node.firstName.toLowerCase() : ''} ${
					b.node.lastName ? b.node.lastName.toLowerCase() : ''
				}`
			)
				return -1;
			if (
				`${a.node.firstName ? a.node.firstName.toLowerCase() : ''} ${
					a.node.lastName ? a.node.lastName.toLowerCase() : ''
				}` >
				`${b.node.firstName ? b.node.firstName.toLowerCase() : ''} ${
					b.node.lastName ? b.node.lastName.toLowerCase() : ''
				}`
			)
				return 1;
			return 0;
		});

	const sendMLFeedbackPerson = personIds => {
		const personId = personIds.length > 0 && personIds[0];
		const suggestedPersonsList = data?.viewer.suggestedPersons.edges;
		if (!personId || !suggestedPersonsList || suggestedPersonsList.length === 0) return;

		const wasInTop5SuggestedPersonsList = suggestedPersonsList.slice(0, 5).some(sp => sp.node.id === personId);
		const wasInTop3SuggestedPersonsList = suggestedPersonsList.slice(0, 3).some(sp => sp.node.id === personId);
		const wasInTop1SuggestedPersonsList = suggestedPersonsList.length > 0 && suggestedPersonsList[0].node.id === personId;

		// Check if that person was among the suggested persons list
		const wasInSuggestedPersonsList = suggestedPersonsList.some(sp => sp.node.id === personId);

		tracking.trackEvent('[ML - Suggested Persons]', {
			isSuggestedPerson: wasInTop3SuggestedPersonsList,
			wasInSuggestedPersonsList: wasInSuggestedPersonsList,
			wasInTop5SuggestedPersonsList: wasInTop5SuggestedPersonsList,
			wasInTop3SuggestedPersonsList: wasInTop3SuggestedPersonsList,
			wasInTop1SuggestedPersonsList: wasInTop1SuggestedPersonsList,
			taskId: taskId ? Util.getIdFromBase64String(taskId) : null,
			projectId: projectId ? Util.getIdFromBase64String(projectId) : null,
		});

		trackEvent('Suggested Person', 'Feedback Given', {
			isSuggestedPerson: wasInTop3SuggestedPersonsList,
			wasInSuggestedPersonsList: wasInSuggestedPersonsList,
			wasInTop5SuggestedPersonsList: wasInTop5SuggestedPersonsList,
			wasInTop3SuggestedPersonsList: wasInTop3SuggestedPersonsList,
			wasInTop1SuggestedPersonsList: wasInTop1SuggestedPersonsList,
			taskId: taskId ? Util.getIdFromBase64String(taskId) : null,
			projectId: projectId ? Util.getIdFromBase64String(projectId) : null,
		});
	};

	const handleSelect = personIds => {
		if (useSuggestions) {
			sendMLFeedbackPerson(personIds);
		}

		onSelect(personIds);
	};

	return (
		<Dropdown
			onExpand={handleExpand}
			isNested
			isMultiSelect
			headerLines={headerLines}
			onSelect={handleSelect}
			onRemove={onRemove}
			selectedGroupName={selectedGroupName}
			disabled={disabled}
			selectedItems={selectedItems}
			width={width}
			dropdownAlignment={dropdownAlignment}
			name={name}
			userpilot={userpilot}
			cy={cy}
			emphasizeEmptyState={emphasizeEmptyState}
			usePortal={usePortal}
			focusOnMount={focusOnMount}
			customPlaceholder={
				selectedItemsPlaceholder?.length > 0 ? (
					<AssigneeGroup alignStart showSingleName showCount={5} assignees={selectedItemsPlaceholder} />
				) : null
			}
		>
			{!!data &&
				data.viewer &&
				data.viewer.company.availableMLModels.assigneeModel &&
				data.viewer.suggestedPersons.edges.length > 0 &&
				selectedItems.length === 0 && (
					<Dropdown.Group name={intl.formatMessage({id: 'common.suggestions'})}>
						{data.viewer.suggestedPersons.edges.map(edge => (
							<HexagonText
								key={edge.node.id}
								value={edge.node.id}
								searchString={edge.node.fullName}
								text={edge.node.fullName}
								subText={showRole ? (edge.node.role ? edge.node.role.name : ' ') : null}
								imgUrl={profilePicSrc(edge.node.profilePictureId)}
							/>
						))}
					</Dropdown.Group>
				)}
			<Dropdown.Group name={optionsName} key={'dropdown-options'}>
				{sortedPersons.map(edge => {
					const subText = !edge.node.active
						? intl.formatMessage({id: 'team.deactivated-group-title'})
						: showRole
						? edge.node.role
							? edge.node.role.name
							: ' '
						: null;

					return (
						<HexagonText
							key={edge.node.id}
							value={edge.node.id}
							searchString={edge.node.fullName}
							text={edge.node.fullName}
							subText={subText}
							imgUrl={profilePicSrc(edge.node.profilePictureId)}
						/>
					);
				})}
			</Dropdown.Group>
		</Dropdown>
	);
};

PersonDropdown.propTypes = {
	persons: PropTypes.arrayOf(
		PropTypes.shape({
			node: PropTypes.shape({
				id: PropTypes.string.isRequired,
				firstName: PropTypes.string.isRequired,
				lastName: PropTypes.string.isRequired,
				profilePictureId: PropTypes.string,
				active: PropTypes.bool.isRequired,
				role: PropTypes.shape({
					name: PropTypes.string,
				}),
			}),
		})
	).isRequired,
	dropDownAlignment: PropTypes.oneOf(['left', 'center', 'right']),
	width: PropTypes.number,
	name: PropTypes.string,
	optionsName: PropTypes.string,
	cy: PropTypes.string,
	selectedItems: PropTypes.array,
	onSelect: PropTypes.func,
	onRemove: PropTypes.func,
	showRole: PropTypes.bool,
	selectedGroupName: PropTypes.string,
	disabled: PropTypes.bool,
	taskId: PropTypes.string,
	selectedItemsPlaceholder: PropTypes.arrayOf(
		PropTypes.shape({
			fullName: PropTypes.string,
			imageSource: PropTypes.string,
		})
	),
	useSuggestions: PropTypes.bool,
	headerLines: PropTypes.element,
};

PersonDropdown.defaultProps = {
	optionsName: 'Persons',
	name: 'Persons',
	selectedGroupName: 'Selected',
	showRole: false,
	onSelect: () => false,
	onRemove: () => false,
};

export default createFragmentContainer(PersonDropdown, {
	persons: graphql`
		fragment PersonDropdown_persons on PersonTypeEdge @relay(plural: true) {
			node {
				id
				fullName
				firstName
				lastName
				profilePictureId
				active
				client {
					id
				}
				role {
					name
				}
			}
		}
	`,
});
