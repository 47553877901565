import React, {useState} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {BasicTextInput} from 'web-components';
import Util from '../../../util/util';
import UpdateCustomFieldValueMutation from '../../../../../mutations/update_custom_field_value_mutation';

const CustomFieldsSection = ({viewer}) => {
	let {id, customFieldValues} = viewer.task;

	customFieldValues = customFieldValues?.edges
		.map(edge => {
			return {...edge.node};
		})
		.sort((a, b) => (a.displayName > b.displayName ? 1 : -1));

	const [customFields, setCustomFields] = useState(customFieldValues || []);

	const changeCustomFieldValue = (customField, value) => {
		customFields.find(cf => cf.id === customField.id).value = value;
		setCustomFields([...customFields]);
	};

	const handleCustomFieldChange = (customField, value) => {
		Util.CommitMutation(UpdateCustomFieldValueMutation, {
			id: customField.id,
			entityId: id,
			entityType: 'TASK',
			key: customField.key,
			value,
		});
	};

	return (
		<div className="task-modal-v3-custom-fields-section-container">
			{customFields.map(customField => {
				return (
					<div key={customField.key} className="task-modal-v3-custom-fields-section">
						<div>{customField.displayName}</div>
						<BasicTextInput
							value={customField.value}
							onChange={changeCustomFieldValue.bind(null, customField)}
							callback={handleCustomFieldChange.bind(null, customField)}
							disabled={customField.readOnly}
							maxLength={191}
							cy={'custom-field-name-input-' + customField.key}
						/>
					</div>
				);
			})}
		</div>
	);
};

export const customFieldsSectionQuery = graphql`
	query customFieldsSection_Query($taskId: ID) {
		viewer {
			actualPersonId
			component(name: "task_custom_field_section")
			...customFieldsSection_viewer @arguments(taskId: $taskId)
		}
	}
`;

export default createFragmentContainer(CustomFieldsSection, {
	viewer: graphql`
		fragment customFieldsSection_viewer on Viewer @argumentDefinitions(taskId: {type: "ID"}) {
			task(id: $taskId) {
				id
				customFieldValues {
					edges {
						node {
							id
							key
							value
							displayName
							readOnly
						}
					}
				}
			}
		}
	`,
});
