import Styled from 'styled-components';
import {CSS_CONSTANTS} from '../../../../../../css_variables';

export const TileExpansionWrapper = Styled.div`
    margin: 16px;
    width: 266px;
`;

export const TileExpansionRow = Styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: ${props => props.withPadding && '8px'};
`;

export const TileExpansionText = Styled.span`
    display: flex;
    color: ${CSS_CONSTANTS.v2_text_gray};
    font-size: 13px;
    font-weight: 500;
    align-items: center;
`;

export const TileExpansionTextLight = Styled.span`
    display: flex;
    color: ${CSS_CONSTANTS.v2_text_light_gray};
    font-size: 12px;
    align-items: center;
`;

export const TileExpansionInputWrapper = Styled.div`
    max-width: 90px;
`;

export const TileExpansionInputText = Styled.div`
    padding-right: ${props => (props.autoRemaining ? undefined : '16px')};
`;

export const TileExpansionBackgroundWrapper = Styled.div`
    background-color: ${CSS_CONSTANTS.background_ghost_white};
    padding: 16px;
`;

export const TileExpansionSeparator = Styled.div`
    margin: 8px 0 16px;
    border-top: 1px solid ${CSS_CONSTANTS.app_border_color};
`;
