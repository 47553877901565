/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type labelRow_label$ref: FragmentReference;
declare export opaque type labelRow_label$fragmentType: labelRow_label$ref;
export type labelRow_label = {|
  +id: string,
  +name: ?string,
  +color: ?string,
  +taskCount: ?number,
  +projectCount: ?number,
  +peopleCount: ?number,
  +$refType: labelRow_label$ref,
|};
export type labelRow_label$data = labelRow_label;
export type labelRow_label$key = {
  +$data?: labelRow_label$data,
  +$fragmentRefs: labelRow_label$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "labelRow_label",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "taskCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "peopleCount",
      "storageKey": null
    }
  ],
  "type": "Label"
};
// prettier-ignore
(node/*: any*/).hash = '3b4b143d1e3cc2f8d4e35d4c295ead22';

module.exports = node;
