/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type customFieldsSection_viewer$ref = any;
export type customFieldsSection_QueryVariables = {|
  taskId?: ?string
|};
export type customFieldsSection_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: customFieldsSection_viewer$ref,
  |}
|};
export type customFieldsSection_Query = {|
  variables: customFieldsSection_QueryVariables,
  response: customFieldsSection_QueryResponse,
|};
*/


/*
query customFieldsSection_Query(
  $taskId: ID
) {
  viewer {
    actualPersonId
    component(name: "task_custom_field_section")
    ...customFieldsSection_viewer_4v7Zcs
    id
  }
}

fragment customFieldsSection_viewer_4v7Zcs on Viewer {
  task(id: $taskId) {
    id
    customFieldValues {
      edges {
        node {
          id
          key
          value
          displayName
          readOnly
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "taskId",
    "type": "ID"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "task_custom_field_section"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"task_custom_field_section\")"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "customFieldsSection_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "args": [
              {
                "kind": "Variable",
                "name": "taskId",
                "variableName": "taskId"
              }
            ],
            "kind": "FragmentSpread",
            "name": "customFieldsSection_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "customFieldsSection_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "taskId"
              }
            ],
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "task",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomFieldValueTypeConnection",
                "kind": "LinkedField",
                "name": "customFieldValues",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CustomFieldValueTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CustomFieldValue",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "key",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "value",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "displayName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "readOnly",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "customFieldsSection_Query",
    "operationKind": "query",
    "text": "query customFieldsSection_Query(\n  $taskId: ID\n) {\n  viewer {\n    actualPersonId\n    component(name: \"task_custom_field_section\")\n    ...customFieldsSection_viewer_4v7Zcs\n    id\n  }\n}\n\nfragment customFieldsSection_viewer_4v7Zcs on Viewer {\n  task(id: $taskId) {\n    id\n    customFieldValues {\n      edges {\n        node {\n          id\n          key\n          value\n          displayName\n          readOnly\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '94a73400ab638941031c9a8f4358257b';

module.exports = node;
