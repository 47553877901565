import React from 'react';
import {injectIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import {DropdownSection} from './dropdown_section';
import {AppliedFiltersSection} from './applied_filters_section';

export const FilterQueryContent = ({
	viewer,
	intl,
	operatorOptions,
	appliedFilterOperators,
	primaryFilters,
	onAddFilter,
	onRemoveFilter,
	appliedFiltersList,
	selectedSection,
	setSelectedSection,
	filterSection,
	taskFilters,
	projectFilters,
	timeRegFilters,
	invoiceFilters,
	expenseFilters,
	skillFilters,
	labelFilters,
	peopleFilters,
	placeholderFilters,
	resourceFilters,
	onClearFilters,
	handleFilterModeChange,
	clickOnSave,
	enableFilterMode,
}) => {
	const advancedFilter =
		(taskFilters?.length ? 1 : 0) +
			(projectFilters?.length ? 1 : 0) +
			(peopleFilters?.length ? 1 : 0) +
			(timeRegFilters?.length ? 1 : 0) +
			(skillFilters?.length ? 1 : 0) +
			(labelFilters?.length ? 1 : 0) +
			(placeholderFilters?.length ? 1 : 0) +
			(resourceFilters?.length ? 1 : 0) +
			(invoiceFilters?.length ? 1 : 0) +
			(expenseFilters?.length ? 1 : 0) >
		1;
	return (
		<>
			<DropdownSection
				operatorOptions={operatorOptions}
				appliedFilterOperators={appliedFilterOperators}
				primaryFilters={primaryFilters}
				onChange={() => false}
				onAddFilter={onAddFilter}
				onRemoveFilter={onRemoveFilter}
				appliedFilters={appliedFiltersList}
				selectedSection={selectedSection}
				setSelectedSection={setSelectedSection}
				filterSection={filterSection}
				viewer={viewer}
				intl={intl}
				taskFilters={taskFilters}
				projectFilters={projectFilters}
				peopleFilters={peopleFilters}
				timeRegFilters={timeRegFilters}
				invoiceFilters={invoiceFilters}
				expenseFilters={expenseFilters}
				skillFilters={skillFilters}
				labelFilters={labelFilters}
				placeholderFilters={placeholderFilters}
				resourceFilters={resourceFilters}
				handleFilterModeChange={handleFilterModeChange}
				enableFilterMode={enableFilterMode}
			/>
			{appliedFiltersList.length > 0 && (
				<AppliedFiltersSection
					viewer={viewer}
					intl={intl}
					advancedFilter={advancedFilter}
					appliedFilterOperators={appliedFilterOperators}
					appliedFilters={appliedFiltersList}
					onClearFilters={onClearFilters}
					onRemoveFilter={onRemoveFilter}
					clickOnSave={clickOnSave}
					selectedSection={selectedSection}
				/>
			)}
		</>
	);
};

const FilterQueryContentQuery = graphql`
	query FilterQueryContent_Query($projectId: ID, $groupId: String, $programPrefix: String, $fetchAllProjectPhases: Boolean!) {
		viewer {
			actualPersonId
			component(name: "new_filter")
			...FilterQueryContent_viewer
				@arguments(
					projectId: $projectId
					groupId: $groupId
					programPrefix: $programPrefix
					fetchAllProjectPhases: $fetchAllProjectPhases
				)
		}
	}
`;

export {FilterQueryContentQuery};

export default injectIntl(
	createFragmentContainer(FilterQueryContent, {
		viewer: graphql`
			fragment FilterQueryContent_viewer on Viewer
			@argumentDefinitions(
				projectId: {type: "ID"}
				groupId: {type: "String"}
				programPrefix: {type: "String"}
				fetchAllProjectPhases: {type: "Boolean!"}
			) {
				client {
					id
				}
				availableFeatureFlags {
					key
				}
				# FILTER_TYPE.PROJECT, FILTER_TYPE.CONTACT, FILTER_TYPE.ALL_PROJECT_PHASES
				projects(first: 1000000, excludeRestricted: true)
					@connection(key: "FilterViewer_filters_projects", filters: []) {
					edges {
						...ProjectDropdown_projects
						...ProjectContactDropdown_projects
						...AllPhasesDropdown_projects @include(if: $fetchAllProjectPhases)
						node {
							id
							name
						}
					}
				}
				# phases are also used in applied filter section
				phases @include(if: $fetchAllProjectPhases) {
					edges {
						...AllPhasesDropdown_phases @include(if: $fetchAllProjectPhases)
						node {
							id
							name
							projectId
						}
					}
				}
				project(internalId: $projectId) {
					demo
					# PROJECT_STATUS_COLUMN
					statusColumnsV2(first: 1000000) @connection(key: "FilterViewer_filters_statusColumnsV2", filters: []) {
						edges {
							...ProjectStatusColumnDropdown_columns
							node {
								id
								name
							}
						}
					}
					# FILTER_TYPE.PROJECT_PERSON
					projectPersons(first: 1000000) @connection(key: "FilterViewer_filters_projectPersons", filters: []) {
						edges {
							...ProjectPersonDropdown_persons
							node {
								person {
									id
									initials
									firstName
									lastName
									profilePictureId
									profilePictureDefaultId
								}
							}
						}
					}
					# FILTER_TYPE.PROJECT_PHASE
					phases(first: 1000000) @connection(key: "FilterViewer_filters_phases", filters: []) {
						edges {
							...PhasesDropdown_phases
							node {
								id
								name
							}
						}
					}
					# FILTER_TYPE.PROJECT_SPRINT
					sprints(first: 1000000) @connection(key: "FilterViewer_filters_sprints", filters: []) {
						edges {
							...SprintDropdown_sprints
							node {
								id
								name
							}
						}
					}
				}
				projectGroup(id: $groupId) {
					projects(first: 1000000) @connection(key: "FilterViewer_filters_project_group_projects", filters: []) {
						edges {
							# FILTER_TYPE.PROJECT
							...ProjectDropdown_projects
							node {
								id
								name
								companyProjectId
								# PROJECT_STATUS_COLUMN
								statusColumnsV2(first: 1000000)
									@connection(
										key: "FilterViewer_filters__project_group_projects_statusColumnsV2"
										filters: []
									) {
									edges {
										...ProjectStatusColumnDropdown_columns
										node {
											id
											name
											projectGroupStatusColumnId
										}
									}
								}
								# FILTER_TYPE.PROJECT_PERSON
								projectPersons(first: 1000000)
									@connection(
										key: "FilterViewer_filters_project_group_projects_projectPersons"
										filters: []
									) {
									edges {
										...ProjectPersonDropdown_persons
										node {
											person {
												id
												firstName
												lastName
											}
										}
									}
								}
								phases(first: 1000000) @connection(key: "FilterViewer_filters_project_group_phases") {
									edges {
										...PhasesDropdown_phases
										node {
											id
											name
										}
									}
								}
								sprints(first: 1000000) @connection(key: "FilterViewer_filters_project_group_sprints") {
									edges {
										...SprintDropdown_sprints
										node {
											id
											name
											projectGroupSprintId
										}
									}
								}
							}
						}
					}
				}
				program(prefix: $programPrefix) {
					projects(first: 1000000) @connection(key: "FilterViewer_filters_program_projects", filters: []) {
						edges {
							node {
								serviceCompanyId
								legacyProject {
									edges {
										# FILTER_TYPE.PROJECT
										...ProjectDropdown_projects
										node {
											id
											name
											companyProjectId
											# PROJECT_STATUS_COLUMN
											statusColumnsV2(first: 1000000)
												@connection(
													key: "FilterViewer_filters_program_projects_statusColumnsV2"
													filters: []
												) {
												edges {
													...ProjectStatusColumnDropdown_columns
													node {
														id
														name
														projectGroupStatusColumnId
													}
												}
											}
											# FILTER_TYPE.PROJECT_PERSON
											projectPersons(first: 1000000)
												@connection(
													key: "FilterViewer_filters_program_projects_projectPersons"
													filters: []
												) {
												edges {
													...ProjectPersonDropdown_persons
													node {
														person {
															id
															firstName
															lastName
														}
													}
												}
											}
											phases(first: 1000000) @connection(key: "FilterViewer_filters_program_phases") {
												edges {
													...PhasesDropdown_phases
													node {
														id
														name
													}
												}
											}
											sprints(first: 1000000) @connection(key: "FilterViewer_filters_program_sprints") {
												edges {
													...SprintDropdown_sprints
													node {
														id
														name
														projectGroupSprintId
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
				company {
					# FILTER_TYPE.SKILL
					skillLevelsEnabled
					skillLevels {
						id
						level
					}
					...SkillDropdown_company
					skills(first: 10000) @connection(key: "Company_skills") {
						edges {
							node {
								id
								name
							}
						}
					}
					# FILTER_TYPE.SKILL_LEVEL
					...SkillLevelDropdown_company
					skillLevels {
						id
						level
						description
					}
					# FILTER_TYPE.PROJECT_TYPE
					modules {
						moduleType
					}
					# FILTER_TYPE.RATE_CARD
					rateCards(first: 1000000) @connection(key: "FilterViewer_filters_company_rateCards", filters: []) {
						edges {
							...RateCardDropdown_rateCards
							node {
								id
								name
							}
						}
					}
					# FILTER_TYPE.PERSON, FILTER_TYPE.TEAM_MEMBER, FILTER_TYPE.OWNER, FILTER_TYPE.FOLLOWER,
					allPersons(first: 1000000) @connection(key: "FilterViewer_filters_company_allPersons", filters: []) {
						edges {
							...PersonDropdown_persons
							node {
								id
								firstName
								lastName
							}
						}
					}
					# FILTER_TYPE.TEAM
					teams(first: 1000000) @connection(key: "FilterViewer_filters_company_teams", filters: []) {
						edges {
							...TeamDropdown_teams
							node {
								id
								name
								teamPersons(first: 1000)
									@connection(key: "FilterViewer_filters_company_teams_teamPersons", filters: []) {
									edges {
										node {
											id
											person {
												id
											}
										}
									}
								}
							}
						}
					}
					# FILTER_TYPE.CLIENT
					clients(first: 1000000) @connection(key: "FilterViewer_filters_company_clients", filters: []) {
						edges {
							...ClientDropdown_clients
							node {
								id
								name
							}
						}
					}
					# FILTER_TYPE.LABEL, FILTER_TYPE.TASK_PROJECT_LABEL
					labels(first: 1000000) @connection(key: "FilterViewer_filters_company_labels", filters: []) {
						edges {
							node {
								id
								name
								category {
									id
									name
									allowOnPeople
									allowOnProjects
									allowOnTasks
								}
								...LabelDropdown_labels
							}
						}
					}
					# FILTER_TYPE.ROLE
					roles(first: 1000000) @connection(key: "FilterViewer_filters_company_roles", filters: []) {
						edges {
							...RoleDropdown_roles
							node {
								id
								name
							}
						}
					}
					# FILTER_TYPE.INTERNAL_TIME
					# FILTER_TYPE.TIME_OFF
					idleTimes(first: 1000000) @connection(key: "FilterViewer_filters_company_idleTimes", filters: []) {
						edges {
							...IdleTimeDropdown_idleTimes
							node {
								id
								name
							}
						}
					}
					# FILTER_TYPE.DEPARTMENTS
					departments(first: 100000) {
						edges {
							...DepartmentDropdown_departments
							node {
								id
								name
							}
						}
					}
					# FILTER_TYPE.PRIORITY_LEVEL
					priorityLevels(first: 1000) {
						edges {
							node {
								id
								name
								weight
							}
						}
					}
					# FILTER_TYPE.PROGRAM
					programs(first: 100000) {
						edges {
							node {
								id
								name
							}
						}
					}

					# FILTER_TYPE.BILL_FROM and FILTER_TYPE.BILL_TO
					billFroms {
						id
						name
						integration
						billTos(first: 10000) @connection(key: "BillFrom_billTos", filters: []) {
							edges {
								node {
									id
									name
								}
							}
						}
					}
					# FILTER_TYPE.EXPENSE_CATEGORY
					expenseCategories(first: 10000) @connection(key: "Company_expenseCategories") {
						edges {
							node {
								id
								name
							}
						}
					}
				}
			}
		`,
	})
);
