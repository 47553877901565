import { useLocalStorage } from 'react-use';
export const useConvertedEventTracking = () => {
    const [value, setValue] = useLocalStorage('CalendarEvents.ConvertedKeys', [], {
        raw: false,
        serializer: JSON.stringify,
        deserializer: JSON.parse,
    });
    const markEventAsConverted = (id) => {
        if (!id) {
            return;
        }
        const newValue = [...value];
        if (newValue.length > 100) {
            // store a maximum of 100 event ids in local storage
            newValue.shift();
        }
        setValue([...newValue, id]);
    };
    const isEventConverted = (id) => {
        if (!id) {
            return false;
        }
        return value.includes(id);
    };
    return { markEventAsConverted, isEventConverted };
};
