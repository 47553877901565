import React, {Component} from 'react';
import Moment from 'moment';
import {FormattedDate, injectIntl} from 'react-intl';
import UpdateApiKeyMutation from '../../../mutations/update_api_key_mutation';
import {createToast} from '../../shared/components/toasts/another-toast/toaster';
import DeleteApiKeyMutation from '../../../mutations/delete_api_key_mutation';
import Util from '../../shared/util/util';
import {MODAL_TYPE, showModal} from '../../shared/components/modals/generic_modal_conductor';

class settingsApiKeysLineItem extends Component {
	constructor(props) {
		super(props);

		this.state = {
			name: this.props.apiKey.name ? this.props.apiKey.name : '',
		};
	}

	handleNameChange(e) {
		const value = e.target.value;
		this.setState({name: value});
	}

	handleNameBlur() {
		Util.CommitMutation(
			UpdateApiKeyMutation,
			{
				id: this.props.apiKey.id,
				name: this.state.name,
			},
			null,
			true
		);
	}

	handleKeyPressApiKey(e) {
		if (e.key === 'Enter') {
			this.apiKeyInput.blur();
		}
	}

	handleLabelDelete() {
		const {formatMessage} = this.props.intl;
		const onSuccess = () => {
			createToast({duration: 5000, message: formatMessage({id: 'settings.api_keys.deleted_message'})});
		};
		const callbackPositive = params => {
			Util.CommitMutation(
				DeleteApiKeyMutation,
				{
					id: this.props.apiKey.id,
					companyId: this.props.company.id,
				},
				onSuccess,
				true
			);
		};
		showModal({
			type: MODAL_TYPE.GENERIC_DELETION_WARNING,
			deleteCallback: callbackPositive,
		});
	}

	handleActiveChange() {
		Util.CommitMutation(
			UpdateApiKeyMutation,
			{
				id: this.props.apiKey.id,
				active: this._active_input.checked,
			},
			null,
			true
		);
	}

	render() {
		const {formatMessage} = this.props.intl;

		return (
			<tr className="api-key-row">
				<td>
					<input
						ref={input => (this.apiKeyInput = input)}
						type="text"
						className="name-input"
						value={this.state.name}
						onChange={this.handleNameChange.bind(this)}
						onBlur={this.handleNameBlur.bind(this)}
						onKeyPress={this.handleKeyPressApiKey.bind(this)}
					/>
				</td>
				<td>{this.props.apiKey.key}</td>
				<td>
					{this.props.apiKey.createdBy
						? `${this.props.apiKey.createdBy.firstName} ${this.props.apiKey.createdBy.lastName}`
						: ''}
				</td>
				<td>
					<FormattedDate
						value={Moment.utc(this.props.apiKey.createdAt).toDate()}
						year="numeric"
						month="numeric"
						day="numeric"
						hour="numeric"
						minute="numeric"
						second="numeric"
					/>
				</td>
				<td>
					<input
						checked={this.props.apiKey.active}
						type="checkbox"
						ref={input => (this._active_input = input)}
						onChange={this.handleActiveChange.bind(this)}
					/>
				</td>
				<td className="controls">
					<span onClick={this.handleLabelDelete.bind(this)} className="remove">
						{formatMessage({id: 'common.delete'})}
					</span>
				</td>
			</tr>
		);
	}
}

export default injectIntl(settingsApiKeysLineItem);
