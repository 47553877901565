import React, {useMemo} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {CalculationLoader} from 'web-components';
import EmptyState from '../../../shared/components/empty-states/empty_state';
import Util from '../../../shared/util/util';
import {getQueryRenderer} from './TimeRegistrationReportPageUtil';
import {useGenericReportContext} from '../../saved-report/GenericReportContext';

const TimeRegistrationReportTable = ({
	enabledColumns,
	groupings,
	searchQuery,
	viewer,
	intl,
	currency,
	customFieldDefinitions,
}) => {
	const timeRegTotals = viewer.reportData?.totals?.timeRegTotals;

	const wholeTableReportedTime = timeRegTotals && timeRegTotals.totalTimeRegistered;
	const wholeTableBillableRegistered = timeRegTotals && timeRegTotals.totalBillableRegistered;
	const wholeTableNonBillableRegistered = timeRegTotals && timeRegTotals.totalNonBillableRegistered;

	const formattedPrice = timeRegTotals && intl.formatNumber(timeRegTotals.totalPrice, {format: 'always_two_decimal'});
	const formattedCost = timeRegTotals && intl.formatNumber(timeRegTotals.totalCost, {format: 'always_two_decimal'});

	const {reportId, sharedId} = useGenericReportContext();

	// Empty columns to be added to rows
	const customFieldColumnCount = useMemo(
		() => (customFieldDefinitions ? customFieldDefinitions.length : 0),
		[customFieldDefinitions]
	);

	const customFieldKeys = useMemo(
		() =>
			customFieldDefinitions
				? customFieldDefinitions
						.map(def => {
							const keyRxist = enabledColumns['company.reportCustomFieldDefinitions.' + def.key];
							return keyRxist !== undefined && keyRxist !== null ? def.key : '';
						})
						.filter(key => key !== '')
				: [],
		[enabledColumns, customFieldDefinitions]
	);

	const getEmptyState = () => {
		if (searchQuery.filters.length > 3) return <EmptyState pageName={EmptyState.EMPTY_STATE.NO_RESULT_FOUND} />;
		else return <EmptyState pageName={EmptyState.EMPTY_STATE.REPORTED_TIME_TABLE} />;
	};

	const tableHeader = {
		wholeTableReportedTime: Util.convertMinutesToFullHour(wholeTableReportedTime, intl, true),
		wholeTableBillableRegistered: Util.convertMinutesToFullHour(wholeTableBillableRegistered, intl, true),
		wholeTableNonBillableRegistered: Util.convertMinutesToFullHour(wholeTableNonBillableRegistered, intl, true),
		wholeTableTotalCost:
			formattedCost !== null ? Util.GetFormattedCurrencySymbol(currency, formattedCost) : <CalculationLoader />,
		wholeTableTotalPrice:
			formattedPrice !== null ? Util.GetFormattedCurrencySymbol(currency, formattedPrice) : <CalculationLoader />,
		customFieldDefinitions,
	};

	//the nextLevelProps are just an empty object because the first TableRows in the table component do not have nextLevelProps
	return wholeTableReportedTime === 0
		? getEmptyState()
		: getQueryRenderer(
				tableHeader,
				groupings,
				searchQuery,
				0,
				enabledColumns,
				null,
				{},
				currency,
				reportId,
				sharedId,
				customFieldColumnCount,
				customFieldKeys
		  );
};

const TimeRegistrationReportTableQuery = graphql`
	query TimeRegistrationReportTable_Query($searchQuery: TaskSearchQueryType, $reportId: String, $sharedId: String) {
		viewer {
			actualPersonId
			component(name: "time_registration_report_table_v2")
			...TimeRegistrationReportTable_viewer
				@arguments(searchQuery: $searchQuery, reportId: $reportId, sharedId: $sharedId)
		}
	}
`;
export {TimeRegistrationReportTableQuery};

export default injectIntl(
	withRouter(
		createFragmentContainer(TimeRegistrationReportTable, {
			viewer: graphql`
				fragment TimeRegistrationReportTable_viewer on Viewer
				@argumentDefinitions(
					searchQuery: {type: "TaskSearchQueryType"}
					reportId: {type: "String"}
					sharedId: {type: "String"}
				) {
					reportData(id: $reportId, sharedId: $sharedId) {
						totals(searchQuery: $searchQuery) {
							timeRegTotals {
								totalCost
								totalPrice
								totalTimeRegistered
								totalBillableRegistered
								totalNonBillableRegistered
							}
						}
					}
				}
			`,
		})
	)
);
