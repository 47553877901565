import React, {useEffect} from 'react';
import {FormattedHTMLMessage, useIntl} from 'react-intl';
import GenericModal from '../../../../../../containers/modal/generic_modal';
import ForecastQueryRenderer from '../../../../../../ForecastQueryRenderer';
import ReportedTimeCSVModal, {ReportedTimeCSVModalQuery} from './ReportedTimeCSVModal';
import {getCsvLabels} from '../../../../../reports-tab/report/time-registration-report-page/TimeRegCsvUtil';
import Util from '../../../../util/util';
import DirectApi from '../../../../../../directApi';

const ReportedTimeCSVModalWrapper = ({
	closeModal,
	searchQuery,
	searchQueryFilters,
	filterValue,
	enabledColumns,
	groupings,
	validateAccess = false,
	reportName,
	legacyDownload = false,
	customFieldDefinitions,
}) => {
	const intl = useIntl();

	const handleCsvDownloadPress = () => {
		const dataInput = getCsvLabels(enabledColumns, groupings, customFieldDefinitions);

		const labels = dataInput.map(input => input.label);

		const headers = new Headers();
		headers.append('Content-Type', 'application/json');
		const init = {
			method: 'POST',
			headers: headers,
			credentials: 'include',
			body: JSON.stringify({
				labels: labels,
				dataProcessorInputs: dataInput,
				format: 'CSV',
				filter: searchQueryFilters,
				filterApplied: JSON.stringify(filterValue),
				exportFields: [],
			}),
		};

		fetch(DirectApi.graphqlServerEndpoint('report/owner_export_csv'), init).then(async result => {
			const blob = await result.blob();
			Util.exportToCSV(blob, `time_registration_report_${reportName ?? ''}`);
			closeModal();
		});
	};

	const content = (
		<div className={'body-container'}>
			<div style={{marginBottom: '12px'}}>{intl.formatMessage({id: 'common.wait_gathering'})}</div>
			{legacyDownload && (
				<ForecastQueryRenderer
					key="query-render-ReportedTimeCSVModal"
					query={ReportedTimeCSVModalQuery}
					modal={true}
					showLoader={false}
					fetchPolicy={'network-only'}
					variables={{
						pageSize: 50000,
						searchQuery: searchQuery,
						validateAccess: validateAccess,
					}}
					render={relayProps => {
						return <ReportedTimeCSVModal {...relayProps} enabledColumns={enabledColumns} closeModal={closeModal} />;
					}}
				/>
			)}
		</div>
	);

	useEffect(() => {
		if (!legacyDownload) {
			handleCsvDownloadPress();
		}
	}, []);

	return (
		<GenericModal
			closeModal={closeModal}
			headerText={<FormattedHTMLMessage id="common.processing_loading" />}
			content={content}
			preventClickAwayClose={true}
			modalWidth={572}
		/>
	);
};

export default ReportedTimeCSVModalWrapper;
