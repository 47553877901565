import React from 'react';
import styled from 'styled-components';

const RadioButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	color: #393946;

	& > label:hover {
		border-color: #605dec;
	}
	& > input:checked + label {
		background: #605dec;
		border-color: #605dec;
		color: #ffffff;
	}
	& > input:focus + label {
		border-color: #605dec;
		outline: 4px solid #e5f4ff;
	}
`;

const HiddenRadio = styled.input.attrs({type: 'radio'})`
	opacity: 0;
	filter: alpha(opacity=0);
	position: absolute;
	top: 0;
	left: 0;
`;

const Label = styled.label`
	height: 40px;
	line-height: 38px;
	font-size: 13px;
	text-align: center;
	cursor: pointer;
	flex: 1 1 0px;
	border-width: 1px;
	border-style: solid;
	border-color: ${props => (props.error ? '#FEB6B3' : '#e7e7f3')};
	border-radius: 24px;
	padding: 0px 24px;
`;

const RadioButton = ({name, id, value, label, onClick, error, autoFocus = false, dataCy}) => {
	return (
		<RadioButtonContainer>
			<HiddenRadio
				autoFocus={autoFocus}
				name={name}
				value={value}
				id={id || value}
				onClick={() => {
					onClick(value);
				}}
			/>
			<Label htmlFor={id || value} error={error} data-cy={dataCy}>
				{label || value}
			</Label>
		</RadioButtonContainer>
	);
};

export default RadioButton;
