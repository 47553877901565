import React from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {FormattedMessage, injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import * as tracking from '../../../tracking';
import oneloginLogo from '../../../images/integrations/onelogin-logo.png';
import CustomScrollDiv from '../../shared/components/scroll-bars/custom_scroll_div';
import AutosizeInput from 'react-input-autosize';
import Util from '../../shared/util/util';
import UpdateCompanyMutation from '../../../mutations/update_company_mutation.modern';
import ToggleSSOMutation from '../../../mutations/ToggleSSOMutation';
import {createToast} from '../../shared/components/toasts/another-toast/toaster';
import {ELEMENT_TYPE} from '../../../constants';
import {buildHeaderBar} from '../../shared/components/headers/header-bar/header_bar';
import {trackPage, unregisterPageInfo} from '../../../tracking/amplitude/TrackingV2';

class appOnelogin extends React.Component {
	constructor(props) {
		super(props);

		const company = props.viewer.company;

		this.state = {
			oneloginUrl: company.oneloginUrl ? company.oneloginUrl : '',
			oneloginClientId: company.oneloginClientId ? company.oneloginClientId : '',
			oneloginClientSecret: company.oneloginClientSecret ? company.oneloginClientSecret : '',
			verifying: false,
		};

		this.superPropertyChecksum = trackPage('App Catalog - Onelogin');
	}

	componentDidMount() {
		document.title = 'OneLogin - Apps & Integrations - Forecast';
		// Segment
		tracking.trackPage('settings-app-catalog-onelogin');
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	handleChange(property, e) {
		this.setState({[property]: e.target.value});
	}

	openHelpCenter() {
		window.open('https://support.forecast.app/hc/en-us/articles/5055420460305');
	}

	connectOneLogin() {
		this.setState({verifying: true});

		const onSuccess = () => {
			this.setState({verifying: false});
			createToast({duration: 10000, message: this.props.intl.formatMessage({id: 'common.settings_saved'})});
		};

		Util.CommitMutation(
			UpdateCompanyMutation,
			{
				id: this.props.viewer.company.id,
				oneloginUrl: this.state.oneloginUrl,
				oneloginClientId: this.state.oneloginClientId,
				oneloginClientSecret: this.state.oneloginClientSecret,
			},
			onSuccess
		);
	}

	activateSSO() {
		Util.CommitMutation(ToggleSSOMutation, {
			activateOneLogin: true,
		});
	}

	getHeader() {
		const leftContent = [],
			rightContent = [];

		leftContent.push({type: ELEMENT_TYPE.BACK_BUTTON, onClick: this.props.history.goBack});

		return buildHeaderBar(leftContent, rightContent);
	}

	render() {
		const {formatMessage} = this.props.intl;
		return (
			<div className="section-content settings-app settings-app-page">
				{this.props.children}
				{this.getHeader()}
				<CustomScrollDiv>
					<div className="section-body">
						<div className="inner">
							<fieldset className="general">
								<div className="header-wrapper">
									<img width={100} height={100} alt="onelogin" src={oneloginLogo} />
									<div className="title-description-wrapper">
										<div className="description">
											<FormattedMessage id="settings.app_catalog.onelogin_description" />
										</div>
									</div>
								</div>

								{!this.props.viewer.company.oneloginEnabled ? (
									<button
										disabled={this.props.viewer.company.oktaEnabled || this.props.viewer.company.AADEnabled}
										className="submit-button"
										onClick={this.activateSSO.bind(this)}
									>
										<FormattedMessage id="common.activate" />
									</button>
								) : (
									''
								)}

								{this.props.viewer.company.oneloginEnabled ? (
									<div className="input-fields">
										<label htmlFor="onelogin_url">
											<span>
												<strong>onelogin account URL</strong>
											</span>
											https://
											<AutosizeInput
												id="onelogin_url"
												type="text"
												className="onelogin-account-name"
												placeholder={formatMessage({
													id: 'settings.app_catalog.harvest.account_name_placeholder',
												})}
												value={this.state.oneloginUrl}
												onChange={this.handleChange.bind(this, 'oneloginUrl')}
											/>
											.onelogin.com
										</label>
										<label htmlFor="onelogin_client_id">
											<strong>Application client id</strong>
											<input
												id="onelogin_client_id"
												type="text"
												placeholder="Client id"
												value={this.state.oneloginClientId}
												onChange={this.handleChange.bind(this, 'oneloginClientId')}
											/>
										</label>
										<label htmlFor="onelogin_client_secret">
											<strong>Application client secret</strong>
											<input
												id="onelogin_client_secret"
												type="password"
												placeholder="Client secret"
												value={this.state.oneloginClientSecret}
												onChange={this.handleChange.bind(this, 'oneloginClientSecret')}
											/>
										</label>
										{this.props.viewer.company.scimUsernamePassword ? (
											<label htmlFor="scim_bearer_token">
												<strong>SCIM bearer token</strong>
												<input
													id="scim_bearer_token"
													type="text"
													value={btoa(this.props.viewer.company.scimUsernamePassword)}
												/>
											</label>
										) : null}
										<button
											disabled={this.state.verifying}
											className="submit-button"
											onClick={this.connectOneLogin.bind(this)}
										>
											<FormattedMessage id="common.save" />
										</button>
										<button className="help-button" onClick={this.openHelpCenter.bind(this)}>
											<FormattedMessage id="settings.app_catalog.help_button" />
										</button>
									</div>
								) : (
									<button className="help-button" onClick={this.openHelpCenter.bind(this)}>
										<FormattedMessage id="settings.app_catalog.help_button" />
									</button>
								)}
							</fieldset>
						</div>
					</div>
				</CustomScrollDiv>
			</div>
		);
	}
}

const appOneloginQuery = graphql`
	query appOnelogin_Query {
		viewer {
			actualPersonId
			component(name: "app_onelogin")
			...appOnelogin_viewer
		}
	}
`;

export {appOneloginQuery};

export default createFragmentContainer(injectIntl(withRouter(appOnelogin)), {
	viewer: graphql`
		fragment appOnelogin_viewer on Viewer {
			id
			company {
				id
				oneloginEnabled
				oktaEnabled
				AADEnabled
				scimUsernamePassword
				oneloginClientId
				oneloginClientSecret
				oneloginUrl
			}
		}
	`,
});
