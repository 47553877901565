import styled from 'styled-components';
import {CSS_CONSTANTS} from '../../../../css_variables';

import arrow from '../../../../images/empty-states/empty_state_pink_arrow.svg';

export const PageWrapper = styled.div`
	/* required to make scroll work */
	display: flex;
	flex-grow: 1;
	min-height: 235px;
	margin-bottom: 30px;
	position: relative;
	width: 100%;
	height: 100%;
	flex-direction: column;
	justify-content: center;
`;

export const ImagesWrapper = styled.div`
	position: ${props => props.position || 'initial'};
	left: ${props => props.left || 'initial'};
	right: ${props => props.right || 'initial'};
	top: ${props => props.top || 'initial'};
	bottom: ${props => props.bottom || 'initial'};
	width: ${props => {
		switch (props.size) {
			case 'small':
				return '235px';
			case 'medium':
				return '400px';
			default:
				return '400px';
		}
	}};
	height: ${props => {
		switch (props.size) {
			case 'small':
				return '235px';
			case 'medium':
				return '400px';
			default:
				return '400px';
		}
	}};
`;

export const LeftArrow = styled.div`
	background: url(${arrow}) center no-repeat;
	position: absolute;
	width: 55%;
	height: ${props => {
		switch (props.size) {
			case 'small':
				return '235px';
			case 'medium':
				return '400px';
			default:
				return '400px';
		}
	}};
	left: 10%;
	transform: scaleX(-1);
	filter: FlipH;
	-ms-filter: 'FlipH';
`;

export const RightArrow = styled.div`
	background: url(${arrow}) center no-repeat;
	position: absolute;
	width: 55%;
	height: ${props => {
		switch (props.size) {
			case 'small':
				return '235px';
			case 'medium':
				return '400px';
			default:
				return '400px';
		}
	}};
	right: 10%;
`;

export const Avatar = styled.div`
	background: url(${props => props.image}) center no-repeat;
	position: absolute;
	width: ${props => {
		switch (props.size) {
			case 'small':
				return '235px';
			case 'medium':
				return '400px';
			default:
				return '400px';
		}
	}};
	height: ${props => {
		switch (props.size) {
			case 'small':
				return '235px';
			case 'medium':
				return '400px';
			default:
				return '400px';
		}
	}};
	left: 50%;
	transform: translateX(-50%);
`;

export const HeadlineMessage = styled.div`
	font-size: ${props => {
		switch (props.size) {
			case 'small':
				return '14px';
			case 'medium':
				return '24px';
			default:
				return '24px';
		}
	}};
	font-weight: 600;
	text-align: center;
	color: ${CSS_CONSTANTS.v2_text_gray};
	padding: 0 48px;
	width: 100%;
	white-space: normal;
`;

export const InfoMessage = styled.div`
	font-size: 16px;
	font-weight: 500;
	text-align: center;
	margin-top: 4px;
	padding: 0 48px;
	width: 100%;
	white-space: normal;
	color: ${CSS_CONSTANTS.v2_text_light_gray};
`;

export const ButtonWrapper = styled.div`
	text-align: center;
	margin-top: 22px;
	.custom-button-container {
		height: 30px;
		padding: 0 14px;
	}
`;
