/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
query SkylineRoleDropdown_Query(
  $projectId: ID
) {
  viewer {
    component(name: "role_dropdown")
    actualPersonId
    id
    ...SkylineRoleDropdown_viewer_2CgS8h
  }
}

fragment SkylineRoleDropdown_viewer_2CgS8h on Viewer {
  company {
    roles(includeDisabled: false, projectId: $projectId) {
      edges {
        node {
          id
          name
        }
      }
    }
    id
  }
}
*/
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "projectId",
            "type": "ID"
        }
    ], v1 = {
        "alias": null,
        "args": [
            {
                "kind": "Literal",
                "name": "name",
                "value": "role_dropdown"
            }
        ],
        "kind": "ScalarField",
        "name": "component",
        "storageKey": "component(name:\"role_dropdown\")"
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actualPersonId",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v4 = {
        "kind": "Variable",
        "name": "projectId",
        "variableName": "projectId"
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "SkylineRoleDropdown_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "args": [
                                (v4 /*: any*/)
                            ],
                            "kind": "FragmentSpread",
                            "name": "SkylineRoleDropdown_viewer"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "SkylineRoleDropdown_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Company",
                            "kind": "LinkedField",
                            "name": "company",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": [
                                        {
                                            "kind": "Literal",
                                            "name": "includeDisabled",
                                            "value": false
                                        },
                                        (v4 /*: any*/)
                                    ],
                                    "concreteType": "RoleTypeConnection",
                                    "kind": "LinkedField",
                                    "name": "roles",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "RoleTypeEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Role",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v3 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "name",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                (v3 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "SkylineRoleDropdown_Query",
            "operationKind": "query",
            "text": "query SkylineRoleDropdown_Query(\n  $projectId: ID\n) {\n  viewer {\n    component(name: \"role_dropdown\")\n    actualPersonId\n    id\n    ...SkylineRoleDropdown_viewer_2CgS8h\n  }\n}\n\nfragment SkylineRoleDropdown_viewer_2CgS8h on Viewer {\n  company {\n    roles(includeDisabled: false, projectId: $projectId) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n    id\n  }\n}\n"
        }
    };
})();
node.hash = '4d39be532a5ee6f728e0eb731f6525e1';
export default node;
