import React, {useCallback, useEffect, useRef, useState} from 'react';
import {injectIntl} from 'react-intl';
import {useHistory, withRouter} from 'react-router-dom';
import {createRefetchContainer, graphql} from 'react-relay';
import BaseLevelWrapper from './BaselevelWrapper';
import {taskRow} from '../rows/TaskRow';
import {Table} from 'web-components';
import Util from '../../../../shared/util/util';
import {useGenericReportContext} from '../../../saved-report/GenericReportContext';

const TaskRelayWrapperV2 = ({
	viewer,
	intl,
	relay,
	tableHeader,
	rowIndex,
	enabledColumns,
	nextLevelProps,
	currency,
	customFieldColumnCount,
	pageSize,
}) => {
	const tasks = viewer.reportData.tasks.edges;
	const [modalTasksFetching, setModalTasksFetching] = useState(false);
	const didMount = useRef(false);
	const history = useHistory();

	const hasNextPage = viewer.reportData.tasks.pageInfo.hasNextPage;

	const {isSharedView} = useGenericReportContext();

	const fetchMore = () => {
		if (!modalTasksFetching) {
			const refetchObject = {};
			setModalTasksFetching(true);
			refetchObject.pageSize = tasks.length + pageSize;
			relay.refetch(refetchObject, null, () => setModalTasksFetching(false));
		}
	};

	useEffect(() => {
		if (!didMount.current) {
			didMount.current = true;
		} else if (!modalTasksFetching) {
			const refetchObject = {};
			setModalTasksFetching(true);
			refetchObject.pageSize = pageSize;
			relay.refetch(refetchObject, null, () => setModalTasksFetching(false));
		}
	}, []);

	const dataRows = tasks.map(task => {
		return task.node;
	});

	const data = {
		rows: dataRows,
		currency,
	};

	const showTaskModal = useCallback(
		taskId => {
			Util.showTaskModal(taskId, history);
		},
		[history]
	);

	const rows = () => {
		return (
			<Table.Rows
				data={data}
				canExpand={false}
				autoload={hasNextPage && rowIndex === 1}
				loadMore={hasNextPage ? fetchMore : false}
				{...nextLevelProps}
			>
				{({rowData, tableColumnsProps}) => {
					return (
						<Table.Row {...tableColumnsProps}>
							{taskRow(intl, rowData, showTaskModal, isSharedView, data.currency)}
						</Table.Row>
					);
				}}
			</Table.Rows>
		);
	};

	return rowIndex === 1 ? (
		<BaseLevelWrapper
			tableHeader={tableHeader}
			intl={intl}
			enabledColumns={enabledColumns}
			getRows={rows}
			currentLength={rows.length}
			hasNextPage={hasNextPage}
			refetch={relay.refetch}
			currency={currency}
			customFieldColumnCount={customFieldColumnCount}
			pageSize={pageSize}
		/>
	) : (
		rows()
	);
};

const TaskRelayWrapperV2Query = graphql`
	query TaskRelayWrapperV2Query_Query(
		$pageSize: Int
		$searchQuery: TaskSearchQueryType
		$reportId: String
		$sharedId: String
		$customFieldKeys: [String]
	) {
		viewer {
			actualPersonId
			component(name: "task_relay_wrapper_v2")
			...TaskRelayWrapperV2_viewer
				@arguments(
					pageSize: $pageSize
					searchQuery: $searchQuery
					reportId: $reportId
					sharedId: $sharedId
					customFieldKeys: $customFieldKeys
				)
		}
	}
`;

export {TaskRelayWrapperV2Query};

export default injectIntl(
	withRouter(
		createRefetchContainer(
			TaskRelayWrapperV2,
			{
				viewer: graphql`
					fragment TaskRelayWrapperV2_viewer on Viewer
					@argumentDefinitions(
						searchQuery: {type: "TaskSearchQueryType"}
						pageSize: {type: "Int"}
						reportId: {type: "String"}
						sharedId: {type: "String"}
						customFieldKeys: {type: "[String]"}
					) {
						reportData(id: $reportId, sharedId: $sharedId) {
							tasks(first: $pageSize, searchQuery: $searchQuery)
								@connection(key: "TaskReport_tasks", filters: ["searchQuery"]) {
								pageInfo {
									endCursor
									hasNextPage
								}
								edges {
									node {
										id
										companyTaskId
										name
										approved
										dates {
											start
											deadline
											createdAt
										}
										project {
											id
											name
											deadline
											stage
											client {
												name
											}
											rateCard {
												name
											}
											estimationUnit
											minutesPerEstimationPoint
											projectColor
											companyProjectId
											customProjectId
											readOnlyAccess
										}
										status {
											name
										}
										role {
											name
										}
										assignees {
											id
											firstName
											lastName
										}
										phase {
											name
											dates {
												start
												deadline
											}
										}
										sprint {
											name
											dates {
												start
												deadline
											}
										}
										work {
											estimate
											remaining
										}
										reported {
											time
											points
											projected
										}
										progress
										financial {
											revenue {
												planned
												actual
											}
										}
										indicators {
											priority
											bug
											billable
											blocked
										}
										labels {
											name
											color
										}
										reportCustomFieldValues(fieldNodeKeys: $customFieldKeys) {
											id
											key
											value
											displayName
											readOnly
										}
									}
								}
							}
						}
					}
				`,
			},
			graphql`
				query TaskRelayWrapperV2RefetchQuery(
					$pageSize: Int
					$searchQuery: TaskSearchQueryType
					$reportId: String
					$sharedId: String
					$customFieldKeys: [String]
				) {
					viewer {
						...TaskRelayWrapperV2_viewer
							@arguments(
								pageSize: $pageSize
								searchQuery: $searchQuery
								reportId: $reportId
								sharedId: $sharedId
								customFieldKeys: $customFieldKeys
							)
					}
				}
			`
		)
	)
);
