import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { BulkSelector, FlexRow, IconTooltip, Link, Table, useTableFunctions } from '@forecast-it/design-system';
import { openReport } from './actions/OpenReport';
import { toggleFavoriteReport } from './actions/ToggleFavoriteReport';
import { duplicateReport } from './actions/DuplicateReport';
import { shareReport } from './actions/share-report/ShareReport';
import { renameReport } from './actions/rename-report/RenameReport';
import { deleteReport } from './actions/delete-report/DeleteReport';
import { transferOwnership } from './actions/transfer-ownership/TransferOwnership';
import { hasPermission } from '../../shared/util/PermissionsUtil';
import { PERMISSION_TYPE } from '../../../Permissions';
import { REPORT_COLUMNS } from './Constants';
import { profilePicRectSrc } from '../../../directApi';
import { createToast } from '../../shared/components/toasts/another-toast/toaster';
import { createFragmentContainer, graphql } from 'react-relay';
import { bulkDeleteReports } from './actions/delete-report/BulkDeleteReport';
import { bulkShareReports } from './actions/share-report/BulkShareReport';
import { trackEvent } from '../../../tracking/amplitude/TrackingV2';
const ReportsTable = ({ enableSelection, company, reports, search, groupings, scrollRef, setFilteredRows, groupingColumnHeader, }) => {
    const intl = useIntl();
    const history = useHistory();
    const { formatMessage } = intl;
    const isAdmin = hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS);
    const initialSorting = [{ id: REPORT_COLUMNS.EDITED, desc: true }];
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const onSelectionChange = useCallback((rows) => {
        trackEvent('Standard Report', 'Bulk Selection Changed', { selectionCount: rows.length });
        setSelectedRowIds(rows.map(row => row.id));
    }, []);
    const selectedRows = useMemo(() => reports.filter(report => selectedRowIds.includes(report.id)), [selectedRowIds, reports]);
    const [tableRef, table] = useTableFunctions();
    const hideShareAction = (row) => {
        const canShare = row.reportService && (row.isReportOwner || isAdmin);
        return !canShare;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Table, { "data-cy": 'reports-table', data: reports, searchValue: search, grouping: groupings, width: '100%', scrollRef: scrollRef, initialSorting: initialSorting, stickyHeader: true, tableRef: tableRef, onSelectionChange: enableSelection ? onSelectionChange : undefined, onFilteringChange: setFilteredRows, onSortingChange: (sorting) => {
                trackEvent('Standard Report', 'Sorting Changed', { sorting });
            }, getRowId: (row) => row.id, autoResetExpanded: false, highlightSearch: true },
            React.createElement(Table.GroupingColumn, { header: groupingColumnHeader, defaultColumnKey: 'name', allowSorting: true },
                React.createElement(Table.TextColumn, { id: REPORT_COLUMNS.NAME, accessorKey: 'name', "data-cy": 'report-name', header: formatMessage({ id: 'report.column.name' }), allowSorting: true, ellipsis: true, icon: report => (React.createElement(FlexRow, null,
                        report.isShared ? (React.createElement(IconTooltip, { icon: 'shared' },
                            `${formatMessage({ id: 'report.shared_with_externals' })}. `,
                            React.createElement(Link, { onClick: () => shareReport(report) }, "See more."))) : null,
                        !report.privateAccess ? (React.createElement(IconTooltip, { icon: 'internalTime' },
                            `${formatMessage({ id: 'report.company_access' })}. `,
                            report.reportService ? (React.createElement(Link, { onClick: () => shareReport(report) }, "See more.")) : null)) : null)), alignIconRight: true, onClick: (row) => () => openReport(row, history), width: '55%' })),
            React.createElement(Table.TextColumn, { id: REPORT_COLUMNS.TYPE, accessorKey: 'type', "data-cy": 'report-type', header: formatMessage({ id: 'report.column.type' }), allowSorting: true, ellipsis: true, width: '10%' }),
            React.createElement(Table.DateColumn, { id: REPORT_COLUMNS.EDITED, accessorKey: 'updatedAt', "data-cy": 'report-edited', header: formatMessage({ id: 'common.edited' }), allowSorting: true, width: '10%', enableGlobalFilter: false }),
            React.createElement(Table.AvatarColumn, { id: REPORT_COLUMNS.CREATED_BY, accessorKey: 'person.fullName', image: row => { var _a, _b; return (((_a = row.person) === null || _a === void 0 ? void 0 : _a.profilePictureId) ? profilePicRectSrc((_b = row.person) === null || _b === void 0 ? void 0 : _b.profilePictureId) : undefined); }, "data-cy": 'report-createdBy', header: formatMessage({ id: 'report.column.createdBy' }), allowSorting: true, ellipsis: true, width: '10%' }),
            React.createElement(Table.IconColumn, { id: REPORT_COLUMNS.FAVORITES, accessorKey: 'favorite', "data-cy": 'report-favorites', header: formatMessage({ id: 'report.column.favorites' }), align: Table.Align.left, allowSorting: true, onClick: toggleFavoriteReport, icon: (favorite) => {
                    if (favorite === true) {
                        return {
                            icon: 'starFill',
                            color: 'warning',
                        };
                    }
                    else if (favorite === false) {
                        return {
                            icon: 'star',
                        };
                    }
                }, width: '5%' }),
            React.createElement(Table.TextColumn, { id: REPORT_COLUMNS.ACCESS, accessorKey: 'access', "data-cy": 'report-access', header: formatMessage({ id: 'report.column.privateAccess' }), width: '10%', enableGlobalFilter: false, visible: false }),
            React.createElement(Table.ActionColumn, { header: '' },
                React.createElement(Table.ActionColumn.Option, { "data-cy": 'action-duplicate', onClick: row => {
                        trackEvent('Standard Report', 'Menu Action Duplicate');
                        duplicateReport(row, company === null || company === void 0 ? void 0 : company.id, result => {
                            const report = result.duplicateSavedReport.savedReport.node;
                            createToast({
                                duration: 5000,
                                message: formatMessage({ id: 'report.has-been-duplicated' }, { name: row.name }),
                            });
                            table.scrollToId(report.id);
                        });
                    } },
                    React.createElement(FormattedMessage, { id: 'common.duplicate' })),
                React.createElement(Table.ActionColumn.Option, { "data-cy": 'action-share', onClick: row => {
                        trackEvent('Standard Report', 'Menu Action Share');
                        shareReport(row);
                    }, hidden: hideShareAction },
                    React.createElement(FormattedMessage, { id: 'share_insight.button.share' })),
                React.createElement(Table.ActionColumn.Option, { "data-cy": 'action-rename', onClick: row => {
                        trackEvent('Standard Report', 'Menu Action Rename');
                        renameReport(row, result => {
                            const report = result.renameSavedReport.savedReport;
                            createToast({
                                duration: 5000,
                                message: formatMessage({ id: 'save_report.report_renamed' }, { oldName: row.name, newName: report.name }),
                            });
                            table.scrollToId(report.id);
                        });
                    }, hidden: row => !row.isReportOwner && !isAdmin },
                    React.createElement(FormattedMessage, { id: 'common.rename' })),
                React.createElement(Table.ActionColumn.Option, { "data-cy": 'action-transfer-ownership', onClick: row => {
                        trackEvent('Standard Report', 'Menu Action Transfer Ownership');
                        transferOwnership(row, intl);
                    }, hidden: row => !row.reportService || (!row.isReportOwner && !isAdmin) },
                    React.createElement(FormattedMessage, { id: 'common.action.transfer_ownership' })),
                React.createElement(Table.ActionColumn.Option, { "data-cy": 'action-delete', onClick: row => {
                        trackEvent('Standard Report', 'Menu Action Delete');
                        deleteReport(row, company === null || company === void 0 ? void 0 : company.id, intl);
                    }, hidden: row => !row.isReportOwner && !isAdmin },
                    React.createElement(FormattedMessage, { id: 'common.delete' })))),
        React.createElement(BulkSelector, { label: formatMessage({ id: 'report.bulk_selector_label' }, { count: selectedRows.length }), onClear: table.clearSelections, show: selectedRows.length > 0, "data-cy": 'bulk-selector' },
            React.createElement(BulkSelector.MainAction, { onClick: () => bulkShareReports(selectedRows, () => {
                    table.clearSelections();
                }), "data-cy": 'bulk-share-reports' }, formatMessage({ id: 'share_insight.button.share' })),
            React.createElement(BulkSelector.MainAction, { onClick: () => bulkDeleteReports(selectedRows, company === null || company === void 0 ? void 0 : company.id, intl, () => {
                    table.clearSelections();
                }), "data-cy": 'bulk-delete-reports' }, formatMessage({ id: 'common.delete' })))));
};
export default createFragmentContainer(ReportsTable, {
    company: graphql `
		fragment ReportsTable_company on Company {
			id
		}
	`,
});
