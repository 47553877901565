import React from 'react';
import {CalculationLoader, Table} from 'web-components';
import Util from '../../../../shared/util/util';
import {GLOBAL_FILTER_FIELD, GLOBAL_FILTER_OPERATOR} from '../../../../../constants';
import {getQueryRenderer} from '../TimeRegistrationReportPageUtil';

export const getSprintRow = (
	data,
	intl,
	groupIndex,
	searchQuery,
	groupings,
	enabledColumns,
	hasNextPage,
	fetchMore,
	nextLevelProps,
	currency,
	reportId,
	sharedId,
	customFieldColumnCount,
	customFieldKeys
) => {
	const getColumns = sprint => {
		const formattedPrice = intl.formatNumber(sprint.timeRegTotals?.totalPrice, {format: 'always_two_decimal'});
		const formattedCost = intl.formatNumber(sprint.timeRegTotals?.totalCost, {format: 'always_two_decimal'});
		const columns = [
			<Table.Column>
				<span>{sprint.noSprint ? intl.formatMessage({id: 'time_reg_report.no_sprint'}) : sprint.name}</span>
			</Table.Column>,
			<Table.Column />,
			<Table.Column />,
			<Table.Column />,
			<Table.Column>{Util.convertMinutesToFullHour(sprint.timeRegTotals?.totalTimeRegistered, intl, true)}</Table.Column>,
			<Table.Column>
				{Util.convertMinutesToFullHour(sprint.timeRegTotals?.totalBillableRegistered, intl, true)}
			</Table.Column>,
			<Table.Column>
				{Util.convertMinutesToFullHour(sprint.timeRegTotals?.totalNonBillableRegistered, intl, true)}
			</Table.Column>,
			<Table.Column />,
			<Table.Column />,
			<Table.Column />,
			<Table.Column />,
			<Table.Column />,
			<Table.Column />,
			<Table.Column />,
			<Table.Column>
				<span>
					{formattedPrice !== null ? (
						Util.GetFormattedCurrencySymbol(currency, formattedPrice)
					) : (
						<CalculationLoader />
					)}
				</span>
			</Table.Column>,
			<Table.Column>
				<span>
					{formattedCost !== null ? Util.GetFormattedCurrencySymbol(currency, formattedCost) : <CalculationLoader />}
				</span>
			</Table.Column>,
			<Table.Column />,
			<Table.Column />,
		];

		for (let i = 0; i < customFieldColumnCount; i++) {
			columns.push(<Table.Column />);
		}

		return columns;
	};

	return (
		<Table.Rows data={data} canExpand={true} loadMore={hasNextPage && fetchMore} {...nextLevelProps}>
			{({rowData, tableColumnsProps, nextLevelProps, expanded}) => {
				const filterField =
					rowData.noSprint === true ? GLOBAL_FILTER_FIELD.NON_SPRINT_TIME_REG : GLOBAL_FILTER_FIELD.SPRINT_TIME_REG;
				return (
					<>
						<Table.Row {...tableColumnsProps}>{getColumns(rowData)}</Table.Row>
						{expanded &&
							getQueryRenderer(
								null,
								groupings,
								searchQuery,
								groupIndex,
								enabledColumns,
								{field: filterField, operator: GLOBAL_FILTER_OPERATOR.IS, value: [rowData.sprintId]},
								nextLevelProps,
								currency,
								reportId,
								sharedId,
								customFieldColumnCount,
								customFieldKeys
							)}
					</>
				);
			}}
		</Table.Rows>
	);
};
