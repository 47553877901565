import React, {useState} from 'react';
import {FormattedHTMLMessage, useIntl} from 'react-intl';
import InputField from '../../../components/inputs/input_field';
import Util from '../../shared/util/util';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import UpdateCompanyMutation from '../../../mutations/update_company_mutation.modern';
import GenericModal from '../../../containers/modal/generic_modal';
import TooltipContainer from '../../shared/components/tooltips/tooltip_container';
import InformationIcon from '../../../images/information-icon';
import OverlayLoader from '../../../components/loaders/overlay_loader';
import {createToast} from '../../shared/components/toasts/toast';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';

export default ({
	closeModal,
	companyId,
	weekendDisplayPerUser,
	weekendDisplayShowAlways,
	previousWeekendDisplayPerUser,
	previousWeekendDisplayShowAlways,
	isCompanyPrevented,
	isActiveUserPrevented,
	isInactiveUserPrevented,
}) => {
	const {formatMessage} = useIntl();
	const companyChangesRequired = isCompanyPrevented || isActiveUserPrevented || isInactiveUserPrevented;
	const confirmText = formatMessage({id: 'common.confirm'});
	const [inputValue, setInputValue] = useState('');
	const [loading, setLoading] = useState(false);
	const isSwitchingToHideAlways = weekendDisplayShowAlways === false;

	const showChangesSavedToast = () => {
		trackEvent('Weekend Visibility', 'Setting Updated', {
			weekendDisplayPerUser,
			weekendDisplayShowAlways,
		});
		createToast({duration: 2000, message: formatMessage({id: 'common.changes_saved'})});
		closeModal();
	};

	const updateWeekendDisplaySetting = () => {
		setLoading(true);

		Util.CommitMutation(
			UpdateCompanyMutation,
			{
				id: companyId,
				weekendDisplayPerUser,
				weekendDisplayShowAlways,
			},
			() => showChangesSavedToast(),
			true
		);
	};

	const getChangingToInformation = () => {
		if (weekendDisplayPerUser === true) {
			return {
				title: formatMessage({id: 'settings_details.company_control_visibility_of_weekends_as_eye_option'}),
				tooltip: [
					{
						unstyledList: [
							formatMessage(
								{
									id: 'settings_details.weekend_visibility_individual_user_visibility_tooltip',
								},
								{b: b => <b>{b}</b>}
							),
						],
						noBox: true,
					},
				],
			};
		}

		if (weekendDisplayShowAlways === true) {
			return {
				title: formatMessage({id: 'settings_details.company_control_show_weekends_new_setting'}),
				tooltip: [
					{
						unstyledList: [
							formatMessage(
								{
									id: 'settings_details.company_control_show_weekends_weekends_not_counted_tooltip',
								},
								{b: b => <b>{b}</b>}
							),
						],
						noBox: true,
					},
				],
			};
		}

		if (isSwitchingToHideAlways) {
			return {
				title: formatMessage({id: 'settings_details.company_control_hide_weekends_new_setting'}),
				tooltip: [
					{
						unstyledList: [
							formatMessage({
								id: 'settings_details.company_control_hide_weekends_not_possible_to_select_weekends_tooltip',
							}),
						],
						noBox: true,
					},
				],
			};
		}

		return {};
	};

	const getChangingToContent = () => {
		const changingToInformation = getChangingToInformation();
		return (
			<>
				<b className="change-information-new-option">
					<span>{changingToInformation.title}</span>
				</b>
				<TooltipContainer
					className="header-tooltip-wrapper"
					infoText={changingToInformation.tooltip}
					tooltipInfinteDuration={true}
					edgeOffset={350}
				>
					<InformationIcon />
				</TooltipContainer>
			</>
		);
	};

	const getChangeInformation = () => {
		const descriptionInformation = isSwitchingToHideAlways ? (
			<span className={'description-information'}>
				{formatMessage({id: 'settings_details.weekend_visibility_hide_always_disclaimer'})}
			</span>
		) : (
			<>
				<span className={'description-information'}>{formatMessage({id: 'settings_details.only_visual_changes'})}</span>

				{previousWeekendDisplayShowAlways === false && (
					<span className={'description-information'}>
						{formatMessage({id: 'settings_details.switching_from_hide_weekends_information'})}
					</span>
				)}
			</>
		);

		return (
			<div className={'description'}>
				{descriptionInformation}

				{companyChangesRequired && (
					<>
						<span className="description-information">
							{formatMessage({id: 'settings_details.weekend_visibility_weekend_hours_will_be_removed'})}:
						</span>

						<div className={'description-list pushed-right'}>
							{isCompanyPrevented && (
								<div className="list-line">
									<div className="circle"></div>
									<FormattedHTMLMessage
										id={'settings_details.weekend_visibility_changes_will_happen_company'}
									/>
								</div>
							)}
							{(isActiveUserPrevented || isInactiveUserPrevented) && (
								<div className="list-line">
									<div className="circle"></div>
									<FormattedHTMLMessage
										id={'settings_details.weekend_visibility_changes_will_happen_active_users'}
									/>
								</div>
							)}
						</div>
					</>
				)}

				{isSwitchingToHideAlways && (
					<>
						<span className="description-information">
							{formatMessage({id: 'settings_details.weekend_visibility_hide_always_disclaimer_2'})}
						</span>
						<span className="description-information">
							{formatMessage({id: 'settings_details.weekend_visibility_hide_always_disclaimer_3'})}
						</span>
					</>
				)}
			</div>
		);
	};

	const getConfirmChangesContent = () => {
		return (
			<>
				<div className="confirmation-info">
					<FormattedHTMLMessage id="settings.confirm_text" />
				</div>

				<InputField
					value={inputValue}
					onChange={e => setInputValue(e)}
					placeholder={formatMessage({id: 'settings.type_confirm_text'}, {confirmText})}
					type="text"
					autoFocus={true}
					hideLabel={true}
					className="confirmation-input"
				/>

				<div className="confirmation-info">
					<FormattedHTMLMessage id="common.warning.this_action_can_not_be_undone" />
				</div>
			</>
		);
	};

	const getModalContent = () => {
		return (
			<div className="default-warning-modal change-weekend-visibility-setting-modal-content">
				{loading && <OverlayLoader />}

				<div className="changes-about-to-happen-title">
					<span>
						<span>{`${formatMessage({id: 'settings_details.you_are_about_to_change_options'})} `}</span>
						{getChangingToContent()}
					</span>
				</div>

				{getChangeInformation()}

				{companyChangesRequired && getConfirmChangesContent()}
			</div>
		);
	};

	const isConfirmButtonDisabled =
		loading || (companyChangesRequired && confirmText.toLowerCase() !== inputValue.toLowerCase().trim());

	return (
		<GenericModal
			closeModal={closeModal}
			className="change-weekend-visibility-setting-modal"
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.confirm'}),
					style: BUTTON_STYLE.FILLED,
					color: companyChangesRequired ? BUTTON_COLOR.RED : BUTTON_COLOR.GREEN_APPROVE,
					callback: () => updateWeekendDisplaySetting(),
					preventDefaultClose: true,
					disabled: isConfirmButtonDisabled,
				},
			]}
			content={getModalContent()}
		/>
	);
};
