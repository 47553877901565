import React from 'react';
import styled from 'styled-components';
import loader from '../../../../images/loaders/loader-animation.svg';

const MiniLoaderStyle = styled.div`
	min-width: 20px;
	width: 20px;
	height: 20px;
	background-size: 40px 40px;
	background-image: url(${loader});
	background-repeat: no-repeat;
	background-position: center;
`;

export const MiniLoader = () => {
	return <MiniLoaderStyle />;
};
