import styled from 'styled-components';
import React from 'react';
import Pink from '../../images/sections/insights/categories/categories_pink.png';
import {useIntl} from 'react-intl';
import Moment from 'moment';
import Util from '../shared/util/util';
import create_saved_report_mutation from '../../mutations/reporting/create_saved_report_mutation';
import {
	getEyeApplied,
	getGroupingOne,
	getPeriodDate,
	getPeriodType,
} from '../../containers/modal/create-new-report/create-new-report-util';
import {REPORT_TYPE} from '../../constants';
import {getSavedReportURLByReportType} from '../../components/insights/saved_report_util';

const NoReportsTitle = styled.h1`
	width: 830px;
	align-self: center;
	display: flex;
	margin-bottom: 30px;
`;

const ReportCardsWrapper = styled.div`
	width: 830px;
	align-self: center;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
`;

const ReportCard = styled.div`
	position: relative;
	overflow: hidden;
	width: 400px;
	height: 225px;
	border-radius: 5px;
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
	cursor: pointer;
	transition: transform 0.25s;
	margin-bottom: 30px;
	background-color: white;
	background-size: auto 225px;
	background-position: right center;
	background-repeat: no-repeat;
	background-image: url(${props => props.imageColor});
`;

const ReportCardTitle = styled.div`
	font-size: 24px;
	font-weight: bold;
	letter-spacing: 0.8px;
	margin: 0 0 0.5em 0;
`;

const ReportCardTextContainer = styled.div`
	width: 258px;
	height: 225px;
	padding: 20px;
`;

export const NoReports = ({history, isUsingProjectAllocation, isUsingSchedulingPlanMode, isUsingMixedMode}) => {
	const intl = useIntl();
	const getSavedReportLink = report => {
		return getSavedReportURLByReportType(report.type, report.id);
	};

	const nextStep = category => {
		const onSuccess = response => {
			history.push(getSavedReportLink(response.createSavedReport.savedReport.node));
		};

		const startDate = Moment().startOf('month').format('YYYY-MM-DD');
		const endDate = Moment().endOf('month').format('YYYY-MM-DD');

		Util.CommitMutation(
			create_saved_report_mutation,
			{
				name: category + ', Untitled',
				type: category,
				eyeApplied: JSON.stringify(
					getEyeApplied(category, isUsingProjectAllocation, isUsingSchedulingPlanMode, isUsingMixedMode)
				),
				startDate,
				endDate,
				filterApplied: JSON.stringify(null),
				filters: [],
				groupingOne: getGroupingOne(category),
				periodType: getPeriodType(category),
				periodDate: getPeriodDate(category),
				reportService: category === REPORT_TYPE.TASK_REPORT || category === REPORT_TYPE.TIME_REG,
			},
			onSuccess
		);
	};

	const makeReportCard = (name, color, reportCategory) => {
		return (
			<ReportCard imageColor={color} onClick={() => nextStep(reportCategory)}>
				<ReportCardTextContainer>
					<ReportCardTitle>{intl.formatMessage({id: 'insights.category.' + name})}</ReportCardTitle>
					<div>{intl.formatMessage({id: 'new_insight.step1-description-' + name})}</div>
				</ReportCardTextContainer>
			</ReportCard>
		);
	};

	return (
		<>
			<NoReportsTitle>{intl.formatMessage({id: 'new_report'})}</NoReportsTitle>
			<ReportCardsWrapper>
				{makeReportCard('projectPortfolioReport', Pink, REPORT_TYPE.PROJECT_PORTFOLIO_REPORT)}
				{makeReportCard('utilizationReport', Pink, REPORT_TYPE.UTILIZATION_REPORT)}
				{makeReportCard('timeRegistered', Pink, REPORT_TYPE.TIME_REG)}
				{makeReportCard('taskReport', Pink, REPORT_TYPE.TASK_REPORT)}
			</ReportCardsWrapper>
		</>
	);
};
