import React, {useEffect, useRef, useState} from 'react';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {createRefetchContainer, graphql} from 'react-relay';
import TimeRegBaseLevelWrapper from './TimeRegBaseLevelWrapper';
import {Table} from 'web-components';
import {getTimeRegRow} from '../rows/TimeRegRow';
import {useGenericReportContext} from '../../../saved-report/GenericReportContext';

const TimeRegRelayWrapper = ({
	viewer,
	intl,
	relay,
	tableHeader,
	groupIndex,
	enabledColumns,
	nextLevelProps,
	searchQuery,
	currency,
	customFieldColumnCount,
	pageSize,
}) => {
	const timeRegs = viewer.reportData.timeRegistrations.edges;

	const {isSharedView} = useGenericReportContext();

	const [modalTasksFetching, setModalTasksFetching] = useState(false);
	const didMount = useRef(false);

	const hasNextPage = viewer.reportData.timeRegistrations.pageInfo.hasNextPage;

	const fetchMore = () => {
		if (!modalTasksFetching) {
			const refetchObject = {};
			setModalTasksFetching(true);
			refetchObject.pageSize = timeRegs.length + pageSize;
			relay.refetch(refetchObject, null, () => setModalTasksFetching(false));
		}
	};

	useEffect(() => {
		if (!didMount.current) {
			didMount.current = true;
		} else if (!modalTasksFetching) {
			const refetchObject = {};
			setModalTasksFetching(true);
			refetchObject.pageSize = pageSize;
			relay.refetch(refetchObject, null, () => setModalTasksFetching(false));
		}
	}, []);

	const dataRows = timeRegs.map((timeReg, index) => {
		return timeReg.node;
	});
	const data = {rows: dataRows};
	const timeRegRows = () => {
		return (
			<Table.Rows
				data={data}
				canExpand={false}
				autoload={hasNextPage && groupIndex === 1}
				loadMore={hasNextPage ? fetchMore : undefined}
				{...nextLevelProps}
			>
				{({rowData, tableColumnsProps, nextLevelProps, expanded}) => {
					return (
						<Table.Row {...tableColumnsProps}>
							{getTimeRegRow(currency, intl, rowData, enabledColumns, fetchMore, isSharedView)}
						</Table.Row>
					);
				}}
			</Table.Rows>
		);
	};

	return groupIndex === 1 ? (
		<TimeRegBaseLevelWrapper
			tableHeader={tableHeader}
			intl={intl}
			enabledColumns={enabledColumns}
			getRows={timeRegRows}
			currentLength={timeRegs.length}
			hasNextPage={viewer.reportData.timeRegistrations.pageInfo.hasNextPage}
			refetch={relay.refetch}
			customFieldColumnCount={customFieldColumnCount}
			pageSize={pageSize}
		/>
	) : (
		timeRegRows()
	);
};

const TimeRegRelayWrapperQuery = graphql`
	query TimeRegRelayWrapper_Query(
		$pageSize: Int
		$searchQuery: TaskSearchQueryType
		$reportId: String
		$sharedId: String
		$customFieldKeys: [String]
	) {
		viewer {
			actualPersonId
			component(name: "time_reg_relay_wrapper_V2")
			...TimeRegRelayWrapper_viewer
				@arguments(
					pageSize: $pageSize
					searchQuery: $searchQuery
					reportId: $reportId
					sharedId: $sharedId
					customFieldKeys: $customFieldKeys
				)
		}
	}
`;

export {TimeRegRelayWrapperQuery};

export default injectIntl(
	withRouter(
		createRefetchContainer(
			TimeRegRelayWrapper,
			{
				viewer: graphql`
					fragment TimeRegRelayWrapper_viewer on Viewer
					@argumentDefinitions(
						searchQuery: {type: "TaskSearchQueryType"}
						pageSize: {type: "Int"}
						reportId: {type: "String"}
						sharedId: {type: "String"}
						customFieldKeys: {type: "[String]"}
					) {
						reportData(id: $reportId, sharedId: $sharedId) {
							timeRegistrations(first: $pageSize, searchQuery: $searchQuery)
								@connection(key: "TimeRegReport_timeRegistrations", filters: ["searchQuery"]) {
								pageInfo {
									endCursor
									hasNextPage
								}
								edges {
									node {
										date
										notes
										billable
										invoiced
										approvalStatus
										cost
										price
										person {
											firstName
											lastName
											profilePictureId
											role {
												name
											}
											department {
												name
											}
										}
										timeRegistered
										billableRegistered
										nonBillableRegistered
										task {
											name
											project {
												name
												projectColor
												companyProjectId
												customProjectId
												client {
													name
												}
												fullAccessToProject
												readOnlyAccess
											}
											phase {
												name
											}
											sprint {
												name
											}
										}
										project {
											name
											projectColor
											companyProjectId
											customProjectId
											client {
												name
											}
											fullAccessToProject
											readOnlyAccess
										}
										idleTime {
											name
											isInternalTime
										}
										reportCustomFieldValues(fieldNodeKeys: $customFieldKeys) {
											id
											key
											value
											displayName
											readOnly
										}
										role {
											name
										}
									}
								}
							}
						}
					}
				`,
			},
			graphql`
				query TimeRegRelayWrapperRefetchQuery(
					$pageSize: Int
					$searchQuery: TaskSearchQueryType
					$reportId: String
					$sharedId: String
					$customFieldKeys: [String]
				) {
					viewer {
						component(name: "time_reg_relay_wrapper_V2")
						...TimeRegRelayWrapper_viewer
							@arguments(
								pageSize: $pageSize
								searchQuery: $searchQuery
								reportId: $reportId
								sharedId: $sharedId
								customFieldKeys: $customFieldKeys
							)
					}
				}
			`
		)
	)
);
