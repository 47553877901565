import React, {useCallback, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import {CSS_CONSTANTS} from '../../../../../css_variables';
import useEventListener from '../../../hooks/useEventListener';
import Util from '../../../util/util';
import * as tracking from '../../../../../tracking';
import SendRecommenderFeedback from '../../../../../mutations/send_recommender_feedback_mutation';
import {EVENT_ID} from '../../../../../containers/event_manager';
import useSubscription from '../../../hooks/useSubscription';
import {trackEvent} from '../../../../../tracking/amplitude/TrackingV2';
import {convertIntoMinutesWithGranularity} from '../hours-input/hours_input_logic';
import {Label, Text} from '@forecast-it/design-system';
import {useTaskEstimateValidation} from '../../../util/time-registration/time-registration-settings/useTaskEstimateValidation';

const Title = styled.div`
	padding: 8px 12px 4px;
	font-size: 8px;
	text-transform: uppercase;
	text-align: right;
	color: ${CSS_CONSTANTS.v2_text_light_gray};
	letter-spacing: 0.4px;
	font-weight: 500;
`;

const SuggestionRow = styled.div`
	height: 24px;
	width: 88px;
	padding: 0 12px;
	font-size: 13px;
	font-weight: 400;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	color: ${CSS_CONSTANTS.v2_text_gray};
	background-color: ${({showFocus}) => (showFocus ? '#f0e7fc' : null)};
	text-align: right;
	&:hover {
		background-color: #f0e7fc;
	}
`;

const PopupContentDS = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const LabelDS = styled.div`
	padding: 8px 16px;
`;

const SuggestionRowDS = styled.div`
	display: flex;
	flex: 1 0 auto;
	padding: 8px 16px;
	cursor: pointer;
	align-items: center;
	background-color: ${({showFocus}) => (showFocus ? '#f0e7fc' : null)};
	&:hover {
		background-color: #f0e7fc;
	}
	&:last-child {
		border-radius: 4px;
	}
`;

// Round the incoming float
// E.g. 4.25 => 4.5 or 4.125 => 4
const roundFloat = (float, step) => {
	step || (step = 1.0);
	var inv = 1.0 / step;
	return Math.round(float * inv) / inv;
};

const getFirstTimeSuggestion = (secondTimeSuggestion, inputParent, viewer) => {
	// Always select 0.5 if idleTime, unless secondTimeSuggestion is also 0.5
	const choice =
		inputParent && inputParent.idleTime
			? secondTimeSuggestion === 0.5
				? 0
				: 1
			: viewer.timeSuggestionsRecommender.recommendationId;
	const options = [0.25, 0.5, 0.75, 1.0, 1.5];

	return options[choice];
};

const getSecondTimeSuggestion = (max, inputParent, viewer) => {
	// Always select fourthOption if idletime
	const choice = inputParent && inputParent.idleTime ? 3 : viewer.timeSuggestionsRecommenderTwo.recommendationId;
	const firstOption = roundFloat(max > 2 ? 2 : max * 0.75, 0.25);
	let secondOption = (2 + (max - 1)) / 2;
	secondOption = roundFloat(secondOption, 0.5);
	const thirdOption = max === 0 ? max : max - 1;
	let fourthOption = max === 0 ? 1 : max / 2;
	fourthOption = roundFloat(fourthOption, 0.25);
	const options = [firstOption, secondOption, thirdOption, fourthOption];

	return options[choice];
};

const getWorkingDayInHours = (viewer, date) => {
	let workingDayInMinutes = 0;
	// US start with sunday
	let days = [
		viewer.sunday,
		viewer.monday,
		viewer.tuesday,
		viewer.wednesday,
		viewer.thursday,
		viewer.friday,
		viewer.saturday,
	];

	// If it's not US, we want to start with monday
	if (date && date.locale() !== 'en') {
		// Remove the first item from the array (sunday)
		const sunday = days.shift();
		// Push it at the end of the array so sunday now becomes the last day and monday the first
		days.push(sunday);
	}

	if (date && date.isValid()) {
		const dayIndex = date.weekday();
		workingDayInMinutes = days[dayIndex];
	}

	return workingDayInMinutes / 60.0;
};

/*
 * Returns the remaining available hours for a day, out of a full working day
 * E.g. Working day = 8h, Person registered already 5h, Remaining = 3h
 */
const getMax = (inputParent, viewer, date) => {
	let max = 0;

	// One working day
	const workingDayHours = getWorkingDayInHours(viewer, date);
	const task = inputParent ? inputParent.task : null;

	// Calculate the total registered time for that day for that user
	const totalTimeRegisteredInHours = viewer.timeRegistrations
		? viewer.timeRegistrations.edges.reduce(
				(totalTimeRegistered, timeRegistration) => totalTimeRegistered + timeRegistration.node.minutesRegistered,
				0
		  ) / 60.0
		: 0;

	if (task) {
		const taskRemainingInHours = task.timeLeft / 60.0;

		// If remaining is 0 or is more than a full working day
		if (taskRemainingInHours === 0 || taskRemainingInHours >= workingDayHours) {
			// If the registered hours for the day is more than a full working day
			if (totalTimeRegisteredInHours >= workingDayHours) {
				max = 0;
			} else {
				max = workingDayHours - totalTimeRegisteredInHours;

				if (max < 1) {
					max = 1;
				}
			}
			// Remaining is between 0.1 and a full working day
		} else {
			max = taskRemainingInHours;

			if (max < 1) {
				max = 1;
			}
		}
		return max;
	}

	// If the person registered a full working day or more, we set the max to 0
	if (totalTimeRegisteredInHours >= workingDayHours) {
		max = 0;
	} else {
		// Otherwise, we subtract what the person already worked from the full day
		max = workingDayHours - totalTimeRegisteredInHours;

		if (max < 1) {
			max = 1;
		}
	}
	return max;
};

const handleClickedTimeSuggestion = (option, inputParent, addTimeSuggestion) => {
	const suggestedTimeInHours = option;
	const wasClickEvent = true;

	if (addTimeSuggestion) {
		addTimeSuggestion(inputParent, suggestedTimeInHours, true, wasClickEvent);
	}
};

/**
 * Sends feedback to the recommender
 * The recommender accepts 3 values: 0 - first algorithm, 1 - second algorithm, 3 - negative to both
 * The value can be 0.0 (negative) or 1.0 (positive)
 *
 * @param {int} recommender
 * @param {float} value
 */
const sendFeedback = (recommender, value, wasClickEvent, viewer) => {
	var algorithm;
	if (recommender === 0) {
		// leftmost option
		algorithm = 'first';
	} else if (recommender === 1) {
		// middle option
		algorithm = 'second';
	} else if (recommender === -1) {
		// rightmost option
		algorithm = 'third';
	} else {
		// no option was chosen - it is called "(both) were wrong" for compatibility with older data in Amplitude.
		algorithm = 'both';
	}

	tracking.trackEvent('[ML - Suggested Time]', {
		guess: recommender === null || value === 0.0 ? 'wrong' : 'right',
		algorithm: algorithm,
		clicked: !!wasClickEvent,
		wasComponentDisplayed: true,
	});

	trackEvent('Suggested Time', 'Feedback Given', {
		guess: recommender === null || value === 0.0 ? 'wrong' : 'right',
		algorithm: algorithm,
		clicked: !!wasClickEvent,
		wasComponentDisplayed: true,
	});

	// A non algorithm option was chosen
	// Send negative feedback to both our recommenders
	if (recommender === null || recommender === -1) {
		Util.CommitMutation(SendRecommenderFeedback, {
			recommendationInstanceId: viewer.timeSuggestionsRecommender.recommendationInstanceId,
			recommendationId: viewer.timeSuggestionsRecommender.recommendationId,
			generation: viewer.timeSuggestionsRecommender.generation,
			timeStep: viewer.timeSuggestionsRecommender.timeStep,
			feedbackValue: 0.0,
		});
		Util.CommitMutation(SendRecommenderFeedback, {
			recommendationInstanceId: viewer.timeSuggestionsRecommenderTwo.recommendationInstanceId,
			recommendationId: viewer.timeSuggestionsRecommenderTwo.recommendationId,
			generation: viewer.timeSuggestionsRecommenderTwo.generation,
			timeStep: viewer.timeSuggestionsRecommenderTwo.timeStep,
			feedbackValue: 0.0,
		});

		return;
	}

	// Otherwise, send positive feedback to the one that was chosen
	Util.CommitMutation(SendRecommenderFeedback, {
		recommendationInstanceId:
			recommender === 0
				? viewer.timeSuggestionsRecommender.recommendationInstanceId
				: viewer.timeSuggestionsRecommenderTwo.recommendationInstanceId,
		recommendationId:
			recommender === 0
				? viewer.timeSuggestionsRecommender.recommendationId
				: viewer.timeSuggestionsRecommenderTwo.recommendationId,
		generation:
			recommender === 0 ? viewer.timeSuggestionsRecommender.generation : viewer.timeSuggestionsRecommenderTwo.generation,
		timeStep:
			recommender === 0 ? viewer.timeSuggestionsRecommender.timeStep : viewer.timeSuggestionsRecommenderTwo.timeStep,
		feedbackValue: value,
	});
};

const applyGranularityToSuggestion = (suggestion, workingHoursForDay) => {
	if (!suggestion) return 0;

	return convertIntoMinutesWithGranularity(suggestion, workingHoursForDay, true) / 60;
};

const filterSuggestions = (suggestions, validateTimeInputValue) => {
	return [...new Set(suggestions)].filter(option => option !== 0 && !validateTimeInputValue(option * 60));
};

const SuggestedTimeTooltipContent = ({
	viewer,
	popupVisible,
	inputParent,
	addTimeSuggestion,
	date,
	workingHoursForDay,
	intl,
	replicateDesignSystem,
}) => {
	const [focusIndex, setFocusIndex] = useState(-1);
	const firstOptionRef = useRef(null);
	const secondOptionRef = useRef(null);
	const thirdOptionRef = useRef(null);

	const onTimeRegistrationEventReceived = useCallback(
		object => {
			const firstAlgorithm = 0;
			const secondAlgorithm = 1;
			const thirdAlgorithm = -1; // not a real algorithm, this is the third option that always shows the max
			const positive = 1.0;
			const negative = 0.0;
			const {timeRegisteredInHours, wasClickEvent} = object;
			// Don't send anything if timeRegisteredInHours is null or if viewer is missing
			if (!viewer || timeRegisteredInHours === null) return;
			// The manually registered time matched the first algorithm's recommendation
			// We send positive feedback to it
			if (timeRegisteredInHours === firstOptionRef.current) {
				sendFeedback(firstAlgorithm, positive, wasClickEvent, viewer);
				// The manually registered time matched the second algorithm's recommendation
				// We send positive feedback to it
			} else if (timeRegisteredInHours === secondOptionRef.current) {
				sendFeedback(secondAlgorithm, positive, wasClickEvent, viewer);
				// The manually registered time didn't match any of the algorithm recommendations
				// We send negative feedback to both of them
			} else if (timeRegisteredInHours === thirdOptionRef.current) {
				sendFeedback(thirdAlgorithm, positive, wasClickEvent, viewer);
			} else {
				sendFeedback(null, negative, wasClickEvent, viewer);
			}
		},
		[viewer]
	);

	const {validateTimeInputValue} = useTaskEstimateValidation(
		inputParent?.task?.estimateForecastMinutes,
		inputParent?.task?.totalMinutesRegistered,
		null,
		intl
	);

	const thirdSuggestion = viewer ? getMax(inputParent, viewer, date) : 0;
	const secondTimeSuggestion = viewer ? getSecondTimeSuggestion(thirdSuggestion, inputParent, viewer) : 0;
	const firstSuggestion = viewer ? getFirstTimeSuggestion(secondTimeSuggestion, inputParent, viewer) : 0;

	firstOptionRef.current = firstSuggestion;
	secondOptionRef.current = secondTimeSuggestion;
	thirdOptionRef.current = thirdSuggestion;

	const filteredSuggestions = useMemo(() => {
		const suggestions = [firstSuggestion, secondTimeSuggestion, thirdSuggestion];
		const suggestionsGranularityFormatted = suggestions.map(suggestion =>
			applyGranularityToSuggestion(suggestion, workingHoursForDay)
		);
		return filterSuggestions(suggestionsGranularityFormatted, validateTimeInputValue);
	}, [firstSuggestion, secondTimeSuggestion, thirdSuggestion, workingHoursForDay]);

	const handleKeyboardSelect = () => {
		if (focusIndex >= 0) {
			const focusedOption = filteredSuggestions[focusIndex];
			handleClickedTimeSuggestion(focusedOption, inputParent, addTimeSuggestion);
		}
	};

	const arrowSelect = direction => {
		if (direction === 'up') {
			setFocusIndex(focusIndex - 1 < 0 ? 0 : focusIndex - 1);
		}
		if (direction === 'down') {
			setFocusIndex(focusIndex + 1 >= filteredSuggestions.length ? focusIndex : focusIndex + 1);
		}
	};

	const handleKeyDown = useCallback(
		keyboardEvent => {
			if (popupVisible) {
				if (keyboardEvent.key === 'ArrowUp') {
					arrowSelect('up');
				}
				if (keyboardEvent.key === 'ArrowDown') {
					arrowSelect('down');
				}

				if (keyboardEvent.key === 'Enter' || keyboardEvent.key === 'Tab') {
					handleKeyboardSelect();
				}
			}
		},
		[popupVisible, focusIndex, filteredSuggestions]
	);

	useEventListener('keydown', handleKeyDown);
	useSubscription(EVENT_ID.TIME_REGISTRATION_MUTATION_SUCCESS, onTimeRegistrationEventReceived);

	// little bit of crimes against humanity to hack together a design-system replica view. This monstrosity should eventually be replaced by a proper component in the design system.
	return replicateDesignSystem ? (
		<PopupContentDS>
			{viewer && filteredSuggestions.length === 0 ? (
				<LabelDS>
					<Text>No suggestions</Text>
				</LabelDS>
			) : (
				<>
					<LabelDS>
						<Label>Suggestions</Label>
					</LabelDS>
					{viewer ? (
						filteredSuggestions.map((option, index) => (
							<SuggestionRowDS
								key={index}
								showFocus={focusIndex === index}
								onClick={() => handleClickedTimeSuggestion(option, inputParent, addTimeSuggestion)}
							>
								<Text>{Util.convertMinutesToFullHour(option * 60, intl)}</Text>
							</SuggestionRowDS>
						))
					) : (
						<SuggestionRow>Loading...</SuggestionRow>
					)}
				</>
			)}
		</PopupContentDS>
	) : (
		<>
			{viewer && filteredSuggestions.length === 0 ? (
				<Title>No suggestions</Title>
			) : (
				<>
					<Title>{intl.formatMessage({id: 'common.suggestions'})}</Title>
					{viewer ? (
						filteredSuggestions.map((option, index) => (
							<SuggestionRow
								key={index}
								showFocus={focusIndex === index}
								onClick={() => handleClickedTimeSuggestion(option, inputParent, addTimeSuggestion)}
							>
								{Util.convertMinutesToFullHour(option * 60, intl)}
							</SuggestionRow>
						))
					) : (
						<SuggestionRow>Loading...</SuggestionRow>
					)}
				</>
			)}
		</>
	);
};

export default SuggestedTimeTooltipContent;
