import React from 'react';
import Styled from 'styled-components/macro';
const DIRECTION = {
	UP: 'up',
	DOWN: 'down',
	LEFT: 'left',
	RIGHT: 'right',
};
export const ArrowIcon = ({direction, onClick, color, title, cy}) => {
	return (
		<StyledSpan direction={direction} onClick={onClick} data-cy={cy}>
			<svg width="6px" height="12px" viewBox="0 0 6 12">
				<title>{title}</title>
				<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
					<g id="Artboard" transform="translate(-363.000000, -2406.000000)" fill={color ? color : '#535353'} fillRule="nonzero">
						<path
							d="M370.379216,2409.20657 C370.812197,2408.87871 371.441132,2408.94858 371.783982,2409.36263 C372.126831,2409.77668 372.053765,2410.37812 371.620784,2410.70598 L365.950027,2414.99996 L360.381167,2410.80464 C359.947335,2410.47781 359.872705,2409.87655 360.214477,2409.46169 C360.556249,2409.04682 361.185,2408.97546 361.618833,2409.30229 L365.946858,2412.56282 L370.379216,2409.20657 Z"
							id="Arrow-right"
							transform="translate(365.999999, 2411.999960) scale(-1, 1) rotate(90.000000) translate(-365.999999, -2411.999960) "
						></path>
					</g>
				</g>
			</svg>
		</StyledSpan>
	);
};

const StyledSpan = Styled.span`
	cursor: pointer;
	transform: ${props => {
		switch (props.direction) {
			case DIRECTION.DOWN:
				return 'rotate(90deg)';
			case DIRECTION.LEFT:
				return 'rotate(180deg)';
			case DIRECTION.UP:
				return 'rotate(270deg)';
			default:
				return 'rotate(0deg)';
		}
	}}
`;

ArrowIcon.DIRECTION = DIRECTION;
