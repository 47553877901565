import React from 'react';
import {ButtonGroupContainer} from '../timesheets_styled';
import {ELEMENT_TYPE} from '../../../../constants';
import HeaderBar from '../../../shared/components/headers/header-bar/header_bar';
import PropTypes from 'prop-types';
import {taskTypeDefaultOptions} from '../timesheets_page';

export const ButtonGroupHeader = ({intl, selectedSwitch, setSelectedSwitch, groupLocked}) => {
	const title = {
		type: ELEMENT_TYPE.INFO_TEXT,
		intl: intl,
		infoText: intl.formatMessage({id: 'timesheets.quick_find_and_add'}),
	};
	const toggleGroup = {
		type: ELEMENT_TYPE.BUTTON_TOGGLE_GROUP,
		intl: intl,
		userpilot: 'timesheets-toggles',
		defaultOptions: taskTypeDefaultOptions,
		onSwitchSelection: setSelectedSwitch,
		singlePicker: true,
		selectedSwitch: selectedSwitch,
		groupLocked: groupLocked,
	};

	const leftContent = [title, toggleGroup];
	return (
		<ButtonGroupContainer data-userpilot={'timesheets-toggles'}>
			<HeaderBar customClasses={' no-padding'} leftContent={leftContent} centerContent={[]} rightContent={[]} />
		</ButtonGroupContainer>
	);
};

ButtonGroupHeader.propTypes = {
	intl: PropTypes.object.isRequired,
	taskTypeDefaultOptions: PropTypes.objectOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			messageId: PropTypes.string.isRequired,
			nothingReturnedMessageId: PropTypes.string.isRequred,
		})
	).isRequired,
	groupLocked: PropTypes.bool.isRequired,
	selectedSwitch: PropTypes.string,
	setSelectedSwitch: PropTypes.func.isRequired,
};
