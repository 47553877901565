import React, {useState, useCallback} from 'react';
import {useIntl} from 'react-intl';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import {CSS_CONSTANTS} from '../../../../../css_variables';
import Button from '../../buttons/button/button';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../../../constants';
import HelpIcon from '../../../../../images/v2/components/header/help.svg';
import HelpIconActive from '../../../../../images/v2/components/header/help-active.svg';
import OnboardingItem from './onboarding_item';
import OnboardingSection from './onboarding_section';
import {ClickAwayListener} from '@material-ui/core';
import {trackEvent} from '../../../../../tracking/amplitude/TrackingV2';

const OnboardingWrapper = styled.div`
	width: ${props => (props.customButton ? 'auto' : '30px')};
	height: 30px;
	//margin-right: 8px;
	.custom-button-container {
		min-width: 30px !important; // Ew
	}
	button {
		height: ${props => (props.customButton ? '26px' : 'auto')};
	}
	.help-icon {
		background-image: url(${HelpIcon});
		background-position: center;
		background-repeat: no-repeat;
	}
	.help-icon-active {
		background-image: url(${HelpIconActive});
		background-position: center;
		background-repeat: no-repeat;
	}
`;

const OnboardingPopup = styled.div`
	width: 472px;
	background-color: white;
	border: 1px solid ${CSS_CONSTANTS.v2_button_very_light_grey};
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
`;

const OnboardingContent = styled.div`
	margin: 30px;
`;

const OnboardingFooter = styled.div`
	display: flex;
	padding-top: 10px;
	font-weight: 500;
	font-size: 11px;
	color: ${CSS_CONSTANTS.v2_text_light_gray};
	a {
		padding-left: 4px;
		color: ${CSS_CONSTANTS.link_text_color};
	}
`;

const Title = styled.div`
	font-size: 20px;
	font-weight: 500;
	margin-bottom: 24px;
	color: ${CSS_CONSTANTS.v2_text_gray};
`;

const Subtitle = styled.div`
	margin-bottom: 16px;
	color: ${CSS_CONSTANTS.v2_text_gray};

	a {
		color: ${CSS_CONSTANTS.link_text_color};
	}
`;

const onboardingComponent = ({
	title,
	options,
	helpCenterLink,
	subLink,
	noSubtitle,
	noHelpCenter,
	customHeaderOnboarding,
	thirdLevelOnboardingContainer,
	positionLeft,
}) => {
	const intl = useIntl();
	const [showOnboardingPopup, setShowOnboardingPopup] = useState(false);

	const onboardingButton = document.getElementById(
		customHeaderOnboarding ? 'new-nav-header-onboarding' : 'onboarding-button'
	);
	const buttonBounds = onboardingButton ? onboardingButton.getBoundingClientRect() : null;
	const style = {
		zIndex: 99,
		position: 'absolute',
	};

	if (buttonBounds) {
		style.top = buttonBounds.top + 35;
		style.left = customHeaderOnboarding || positionLeft ? buttonBounds.left : buttonBounds.right - 472;
	}

	const setOnboardingExpanded = () => {
		setShowOnboardingPopup(!showOnboardingPopup);
		if (!showOnboardingPopup) {
			trackEvent('Onboarding Popup', 'Expanded');
		}
	};

	const closePopup = useCallback(() => {
		setShowOnboardingPopup(false);
	});

	return (
		<ClickAwayListener onClickAway={closePopup}>
			<OnboardingWrapper customButton={!!customHeaderOnboarding}>
				{thirdLevelOnboardingContainer ? (
					<Button
						id={'onboarding-button'}
						symbolClass={showOnboardingPopup ? 'help-icon-active' : 'help-icon'}
						symbolSize={'16px'}
						buttonStyle={BUTTON_STYLE.FILLED}
						colorTheme={BUTTON_COLOR.TRANSPARENT}
						onClick={setOnboardingExpanded.bind(this)}
						userpilot={'onboarding-button'}
					/>
				) : (
					<Button
						id={'onboarding-button'}
						symbolClass={'help-icon'}
						symbolSize={'16px'}
						buttonStyle={BUTTON_STYLE.OUTLINE_THICK}
						colorTheme={showOnboardingPopup ? BUTTON_COLOR.PURPLE : BUTTON_COLOR.VERYLIGHTGREY}
						onClick={setOnboardingExpanded.bind(this)}
						userpilot={'onboarding-button'}
					/>
				)}
				{showOnboardingPopup
					? ReactDOM.createPortal(
							<div style={style} className="onboarding-content-wrapper">
								<OnboardingPopup>
									<OnboardingContent>
										<Title>{title}</Title>
										{noSubtitle ? null : subLink ? (
											<Subtitle>
												{intl.formatMessage({id: 'onboarding.subtitle_new_main'})}&nbsp;
												<a href={subLink} target={'_blank'} rel="noopener noreferrer">
													{intl.formatMessage({id: 'onboarding.subtitle_new_link'})}
												</a>
											</Subtitle>
										) : (
											<Subtitle>{intl.formatMessage({id: 'onboarding.subtitle'})}</Subtitle>
										)}
										{options.map((option, index) => {
											return option.section ? (
												<OnboardingSection key={option.id} title={option.title} />
											) : (
												<OnboardingItem
													index={index}
													key={option.id}
													title={option.title}
													description={option.description}
													contentId={option.contentId}
													closePopup={closePopup}
												/>
											);
										})}
										{noHelpCenter ? null : (
											<OnboardingFooter>
												<div>{intl.formatMessage({id: 'onboarding.footer_1'})}</div>
												<a
													href={helpCenterLink ? helpCenterLink : 'https://support.forecast.app/'}
													target="_blank"
													rel="noopener noreferrer"
												>
													{intl.formatMessage({id: 'onboarding.footer_2'})}
												</a>
											</OnboardingFooter>
										)}
									</OnboardingContent>
								</OnboardingPopup>
							</div>,
							document.querySelector('#root-portal-container')
					  )
					: null}
			</OnboardingWrapper>
		</ClickAwayListener>
	);
};

export default onboardingComponent;
