/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type settingsHolidayCalendars_holidayCalendarEntry$ref: FragmentReference;
declare export opaque type settingsHolidayCalendars_holidayCalendarEntry$fragmentType: settingsHolidayCalendars_holidayCalendarEntry$ref;
export type settingsHolidayCalendars_holidayCalendarEntry = {|
  +id: string,
  +name: ?string,
  +year: ?number,
  +month: ?number,
  +day: ?number,
  +$refType: settingsHolidayCalendars_holidayCalendarEntry$ref,
|};
export type settingsHolidayCalendars_holidayCalendarEntry$data = settingsHolidayCalendars_holidayCalendarEntry;
export type settingsHolidayCalendars_holidayCalendarEntry$key = {
  +$data?: settingsHolidayCalendars_holidayCalendarEntry$data,
  +$fragmentRefs: settingsHolidayCalendars_holidayCalendarEntry$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "settingsHolidayCalendars_holidayCalendarEntry",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "year",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "month",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "day",
      "storageKey": null
    }
  ],
  "type": "HolidayCalendarEntry"
};
// prettier-ignore
(node/*: any*/).hash = '25995f24e90648ba38cad2d1218dade4';

module.exports = node;
