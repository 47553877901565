import { SearchResultVariant } from '../types/SearchResultVariant';
import { getLinkFromVariant } from './FormatterUtil';
import { SEARCH_RESULT_VARIANT } from './../SearchResultRow';
const SUGGESTION_TYPE = {
    RECENTLY_CREATED: 'RECENTLY_CREATED',
    RECENTLY_UPDATED: 'RECENTLY_UPDATED',
    RECENTLY_VISITED: 'RECENTLY_VISITED',
    RECENTLY_ASSIGNED: 'RECENTLY_ASSIGNED',
};
const getSuggestionReasonFromSuggestionType = suggestionType => {
    switch (suggestionType) {
        case SUGGESTION_TYPE.RECENTLY_UPDATED:
            return 'your recently updated tasks.';
        case SUGGESTION_TYPE.RECENTLY_VISITED:
            return 'your recently visited tasks.';
        case SUGGESTION_TYPE.RECENTLY_ASSIGNED:
            return 'tasks recently assigned to you.';
        default:
            return '';
    }
};
const getSearchResultVariantFromSuggestionVariant = (suggestionVariant) => {
    switch (suggestionVariant) {
        case SEARCH_RESULT_VARIANT.PROJECT_GROUP:
            return SearchResultVariant.ProjectGroup;
        case SEARCH_RESULT_VARIANT.PROJECT:
            return SearchResultVariant.Project;
        case SEARCH_RESULT_VARIANT.TASK:
            return SearchResultVariant.Task;
        case SEARCH_RESULT_VARIANT.INTERNAL_TIME:
            return SearchResultVariant.InternalTime;
        default:
            return SearchResultVariant.Suggestion;
    }
};
export const suggestionFormatter = ({ searchResult, trackAndHideSearch }) => {
    const onSelect = (_, openedInNewTab) => {
        trackAndHideSearch({
            id: searchResult.entityId,
            variant: searchResult.suggestionVariant,
            suggestionType: searchResult.suggestionType,
            isSuggestion: true,
            searchResult,
        }, !openedInNewTab);
    };
    const formattedHighlight = {
        highlightField: `Suggestion based on ${getSuggestionReasonFromSuggestionType(searchResult.suggestionType)}`,
    };
    const link = getLinkFromVariant(searchResult.linkId, searchResult.customProjectId, searchResult.suggestionVariant);
    return {
        title: searchResult.entityName,
        name: searchResult.entityName,
        highlight: formattedHighlight,
        variant: getSearchResultVariantFromSuggestionVariant(searchResult.suggestionVariant),
        companyProjectId: searchResult.companyProjectId,
        customProjectId: searchResult.customProjectId,
        projectColor: searchResult.projectColor,
        projectIndicatorName: searchResult.projectName,
        ariaLabel: `Search result. Type: Suggestion. Title: ${searchResult.entityName}. Highlight: ${formattedHighlight.highlightField}`,
        link,
        onSelect,
    };
};
