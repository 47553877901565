import React, {useRef} from 'react';
import Styled from 'styled-components';
import {Button} from 'web-components';
import link from '../../../../../../../../images/v2/components/link.svg';
import {createToast} from '../../../../../toasts/toast';
import {CSS_CONSTANTS} from '../../../../../../../../css_variables';
import Util from '../../../../../../util/util';
import * as tracking from '../../../../../../../../tracking';
import {VARIANT} from '../../../../../../../../containers/modal/project-automate/project_automate_modal';
import CreateTaskDismissedNotification from '../../../../../../../../mutations/CreateTaskDismissedNotification';
import UpdateTaskMutation from '../../../../../../../../mutations/update_task_mutation.modern';
import {SimilarTaskSectionQueryWrapper} from './SimilarTaskSectionQueryWrapper';
import {SimilarTaskSectionLoader} from './SimilarTaskSectionLoader';
import {trackEvent} from '../../../../../../../../tracking/amplitude/TrackingV2';

const PredictionPopupWrapper = Styled.div`
	width: 532px;
    cursor: initial;
	padding: 11px 7px;
`;

const PredictionPopupTextLinkWrapper = Styled.div`
	display: flex;
	justify-content: space-between;
`;

const PredictionPopupText = Styled.div`
    font-size: 13px;
    color: ${CSS_CONSTANTS.v2_text_gray};
    white-space: normal;
    font-weight: 400;
    margin-bottom: 8px;
`;

const LinkIcon = Styled.div`
	margin-left: 8px;
	height: 19px;
	width: 18px;
	background: url(${link});
	background-size: 18px;
	background-position: center;
	background-repeat: no-repeat;
	display: none;
`;

const PredictionPopupLink = Styled.a`
	display: flex;
	font-size: 13px;
	font-weight: 400;
	color: ${CSS_CONSTANTS.v2_branding_purple};
	text-decoration: none;
	&:hover {
		color: #6f49c5;
		${LinkIcon} {
			display: block;
		}
	}
`;

const PredictionPopupTextBold = Styled(PredictionPopupText)`
    font-weight: 500;
`;

const ButtonGroup = Styled.div`
    display: flex;
    justify-content: space-between;
	align-items: center;
    margin: 8px 0;
	button:not(:first-child) {
		margin-left: 4px;
	}
`;

const getPredictionToast = (predictionApplied, intl) => {
	return () =>
		createToast({
			duration: 5000,
			message: intl.formatMessage({id: predictionApplied ? 'common.prediction_applied' : 'common.prediction_discarded'}),
		});
};

const handleEstimationChanges = (val, task, intl) => {
	if (isNaN(val)) return;

	const mutation = {
		ids: [task.id],
		forecast: val,
	};
	if (task.estimateForecast === val) return;
	tracking.trackAIEvent('Prediction applied', VARIANT.ESTIMATES);
	trackEvent('Task Overrun Prediction', 'Applied');
	Util.CommitMutation(UpdateTaskMutation, mutation, getPredictionToast(true, intl));
};

const handleNotificationDismissed = (task, intl) => {
	const mutation = {
		taskIds: [task.id],
		eventType: 'TASK_OVERRUN',
	};
	tracking.trackAIEvent('Prediction dismissed', VARIANT.ESTIMATES, null, {taskId: task.id, taskName: task.name});
	trackEvent('Task Overrun Prediction', 'Dismissed', {taskId: task.id, taskName: task.name});
	Util.CommitMutation(CreateTaskDismissedNotification, mutation, getPredictionToast(false, intl));
};

export const PredictionPopup = ({predictedEstimate, task, visible, modelScore = 0, intl}) => {
	const {formatMessage} = intl;
	const optionSelected = useRef(false);

	const handleCallback = positive => {
		if (!optionSelected.current) {
			optionSelected.current = true;

			if (positive) {
				handleEstimationChanges(predictedEstimate, task, intl);
			} else {
				handleNotificationDismissed(task, intl);
			}
		}
	};

	return (
		<PredictionPopupWrapper>
			<PredictionPopupTextBold>{formatMessage({id: 'common.task_predicted_overrun'})}</PredictionPopupTextBold>
			<PredictionPopupTextLinkWrapper>
				<PredictionPopupText>{formatMessage({id: 'common.prediction_similar_tasks_took'})}</PredictionPopupText>
			</PredictionPopupTextLinkWrapper>
			{visible ? <SimilarTaskSectionQueryWrapper taskId={task.id} /> : <SimilarTaskSectionLoader />}
			<ButtonGroup>
				<PredictionPopupLink
					href="https://www.forecast.app/feedback-task-overrun"
					target="_blank"
					rel="noopener noreferrer"
				>
					Give feedback
					<LinkIcon />
				</PredictionPopupLink>
				<Button variant={Button.VARIANT.PURPLE_OUTLINE} onClick={() => handleCallback(false)}>
					{formatMessage({id: 'common.discard_prediction'})}
				</Button>
			</ButtonGroup>
		</PredictionPopupWrapper>
	);
};
