var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import ForecastQueryRenderer from '../../../../../../../ForecastQueryRenderer';
import { hasTopDownProgramBudgetFeature } from '../../../../../util/ProgramFinancialLogic';
import TimeRegistrationModal, { TimeRegistrationModalQuery, } from '../modal-content/TimeRegistrationModal';
import { toBackendEntityId } from '../util/TimeRegistrationModalUtil';
function TimeRegistrationModalQueryWrapper(_a) {
    var { personId, entityId, projectId } = _a, modalProps = __rest(_a, ["personId", "entityId", "projectId"]);
    const entityVariables = entityId ? toBackendEntityId(entityId, projectId) : {};
    const hasTopDownProgramBudgetFeatureFlag = hasTopDownProgramBudgetFeature();
    return (React.createElement(ForecastQueryRenderer, { key: "query-render-bulk-task-update-standalone", query: TimeRegistrationModalQuery, modal: true, showLoader: false, variables: Object.assign({ personId, fetchProgramInfo: hasTopDownProgramBudgetFeatureFlag }, entityVariables), render: (relayProps) => {
            return React.createElement(TimeRegistrationModal, Object.assign({}, relayProps, modalProps));
        } }));
}
export default TimeRegistrationModalQueryWrapper;
