import React, {useState} from 'react';
import styled from 'styled-components';
import {
	DeprecatedContentContainer as ContentContainer,
	DeprecatedCreativeModal as CreativeModal,
	DeprecatedText as Text,
	deprecatedTheme as theme,
	FlexColumn,
	FlexItem,
	FlexRow,
	DeprecatedLink as Link,
	FLEX_ALIGN_ITEMS,
} from '@forecast-it/design-system';
import Util from '../../../util/util';
import UpdateProgramFinancialMetadataMutation from '../../../../../mutations/financial-service/update_program_financial_metadata_mutation';
import {useIntl} from 'react-intl';
import RadioButton from '../../../../../components/new-ui/radio_button';
import Styled from 'styled-components/macro';
import {CurrencyInput} from 'web-components';
import {BUDGET_TYPE, PERIOD_BUDGET_TYPE} from '../../../../../constants';
import {useHistory} from 'react-router-dom';
import ProjectUtil from '../../../util/project_util';
import ProgramUtil from '../../../util/ProgramUtil';

const TopMargin = Styled.div`
	margin-top: 1px;
`;

const SelectionHeader = styled.div`
	display: flex;
	padding: ${theme.spacing.m};
	background: ${({selected, disabled}) =>
		selected ? theme.colors.background.secondary.default : disabled ? theme.colors.background.secondary.disabled : 'none'};
	div {
		color: ${({disabled}) => (disabled ? theme.colors.text.disabled : 'inherit')};
	}
	width: 100%;
	border-bottom: ${({showBorder}) => (showBorder ? '1px solid ' + theme.colors.border.primary.default : 'none')};

	/* once we decide on a component library - we should use a radio button from there and get rid of this css that turns the purple radio button blue */
	div.radio-button-container {
		width: 18px;
		height: 18px;
		border-radius: 9px;
	}
	div.radio-button-checked {
		background-color: white;
		border: 1px solid ${theme.colors.border.active};
		&::before {
			content: '';
			position: absolute;
			background: ${theme.colors.border.active};
			height: 10px;
			width: 10px;
			border-radius: 5px;
			left: 4px;
			top: 4px;
		}
	}
	div.radio-button:hover div.radio-button-container {
		border: 2px solid ${theme.colors.border.active};
	}
`;

const SelectionBody = styled.div`
	padding: ${theme.spacing.m};
`;

export const ChangeProgramBudgetTypeModal = ({company, program, closeModal, onSaveCallback}) => {
	const intl = useIntl();
	const history = useHistory();
	const {formatMessage} = intl;
	const {budgetType, budgetValue, projectsFinancialsTotals} = program;
	const projects = program.projects.edges.map(e => e.node);

	const projectConfirmedRevenueTotal = ProjectUtil.getProjectConfirmedRevenueTotal(projects);

	const programHasTimeMaterialProject = !!projects.find(project => {
		const {budgetType, defaultPeriodBudgetType} = project;
		return (
			budgetType === BUDGET_TYPE.TIME_AND_MATERIALS ||
			(budgetType === BUDGET_TYPE.RETAINER && defaultPeriodBudgetType === PERIOD_BUDGET_TYPE.FIXED_HOURS)
		);
	});

	const goToProgramOverview = () => {
		history.replace(`${ProgramUtil.programLink()}/${program.prefix}/overview`);
		closeModal();
	};

	const currencySymbol = Util.GetCurrencySymbol(company.currency);

	const totalProgramRevenueAtCompletion = projectsFinancialsTotals.recognitionForecastRevenue; // Revenue - Recognition at completion

	const [isCreating, setCreating] = useState(false);
	const [selectedBudgetValue, setSelectedBudgetValue] = useState(budgetValue);
	const [validBudgetValue, setValidBudgetValue] = useState(true);
	const showBudgetValueError = !validBudgetValue;
	const [programBudgetType, setProgramBudgetType] = useState(budgetType);
	const validate = () => {
		let valid = true;
		if (programBudgetType === 'FIXED_PRICE') {
			valid = selectedBudgetValue >= Util.roundFloatToTwoDecimals(totalProgramRevenueAtCompletion);
		}
		if (programBudgetType === 'CAPPED') {
			valid = selectedBudgetValue >= Util.roundFloatToTwoDecimals(projectConfirmedRevenueTotal);
		}
		setValidBudgetValue(valid);
		return valid;
	};

	const updateProgramBudgetType = () => {
		if (validate()) {
			setCreating(true);
			Util.CommitMutation(
				UpdateProgramFinancialMetadataMutation,
				{
					companyId: company.id,
					id: program.id,
					budgetType: programBudgetType,
					budgetValue: parseInt(selectedBudgetValue, 10),
				},
				() => {
					setCreating(false);
					closeModal();
					onSaveCallback();
				}
			);
		}
	};

	const availableBudgetTypes = ['UNCAPPED', 'CAPPED', 'FIXED_PRICE'];

	function getSettingsPane(availableBudgetType) {
		switch (availableBudgetType) {
			case 'CAPPED':
				return (
					<FlexRow alignItems={'flex-start'} gap={'s'}>
						<div style={{width: '50%'}}>
							<FlexColumn gap={'m'}>
								<Text type={'label'}>{formatMessage({id: 'project_budget.confirmed_revenue'})}</Text>
								<Text>
									{Util.getFormattedNumberWithCurrency(currencySymbol, projectConfirmedRevenueTotal, intl)}
								</Text>
								<Text type={'label'}>{formatMessage({id: 'project_budget.total_revenue_at_completion'})}</Text>
								<Text>
									{Util.getFormattedNumberWithCurrency(currencySymbol, totalProgramRevenueAtCompletion, intl)}
								</Text>
							</FlexColumn>
						</div>
						<div style={{width: '50%'}}>
							<FlexColumn gap={'m'}>
								<Text type={'label'}>{formatMessage({id: 'program.program_cap_amount.title'})}</Text>
								<Text>{formatMessage({id: 'program.program_cap_amount.description'})}</Text>
								<CurrencyInput
									cy={'program-cap-amount'}
									currencySymbole={currencySymbol}
									value={selectedBudgetValue}
									callback={setSelectedBudgetValue}
									hasError={showBudgetValueError}
									intl={intl}
								/>
								{showBudgetValueError && (
									<Text color={'destructive'}>
										{formatMessage({id: 'program.program_cap_amount.error_min_amount'})}
									</Text>
								)}
							</FlexColumn>
						</div>
					</FlexRow>
				);
			case 'FIXED_PRICE':
				return (
					<FlexRow alignItems={'flex-start'} gap={'s'}>
						<div style={{width: '50%'}}>
							<FlexColumn gap={'m'}>
								<Text type={'label'}>{formatMessage({id: 'project_budget.total_revenue_at_completion'})}</Text>
								<Text>
									{Util.getFormattedNumberWithCurrency(currencySymbol, totalProgramRevenueAtCompletion, intl)}
								</Text>
							</FlexColumn>
						</div>
						<div style={{width: '50%'}}>
							<FlexColumn gap={'m'}>
								<Text type={'label'}>{formatMessage({id: 'program.program_fixed_price_amount.title'})}</Text>
								<Text>{formatMessage({id: 'program.program_fixed_price_amount.description'})}</Text>
								<CurrencyInput
									cy={'program-fixed-price-amount'}
									currencySymbole={currencySymbol}
									value={selectedBudgetValue}
									callback={setSelectedBudgetValue}
									hasError={showBudgetValueError}
									intl={intl}
								/>
								{showBudgetValueError && (
									<Text color={'destructive'}>
										{budgetType === 'CAPPED'
											? formatMessage({id: 'program.program_cap_amount.error_min_amount'})
											: formatMessage({id: 'program.program_fixed_price_amount.error_min_amount'})}
									</Text>
								)}
							</FlexColumn>
						</div>
					</FlexRow>
				);
			default:
				return null;
		}
	}

	return (
		<CreativeModal
			title={formatMessage({id: 'program.change_program_budget_type'})}
			closeModal={closeModal}
			onCreateHandler={updateProgramBudgetType}
			disabled={isCreating}
			creativeButtonText={formatMessage({id: 'common.confirm_changes'})}
			size={'m'}
			alignItems={'left'}
		>
			{programHasTimeMaterialProject && (
				<ContentContainer width={'100%'}>
					<FlexColumn gap={'s'}>
						<Text type={'heading'} variant={'m'}>
							{formatMessage({id: 'program.budget_type.fixed_price.disabled.header'})}
						</Text>
						<Text>{formatMessage({id: 'program.budget_type.fixed_price.disabled.description'})}</Text>
						<Link onClick={goToProgramOverview}>
							{formatMessage({id: 'program.budget_type.fixed_price.disabled.link'})}
						</Link>
					</FlexColumn>
				</ContentContainer>
			)}
			<FlexColumn gap={'s'}>
				<Text type={'heading'} variant={'m'}>
					{formatMessage({id: 'program.budget_type.header'})}
				</Text>
				<Text>{formatMessage({id: 'program.budget_type_modal.select_prompt'})}</Text>
			</FlexColumn>

			{availableBudgetTypes.map(availableBudgetType => {
				const isSelected = programBudgetType === availableBudgetType;
				const isDisabled = availableBudgetType === 'FIXED_PRICE' && programHasTimeMaterialProject;
				const settingsPane = getSettingsPane(availableBudgetType);
				const showSettingsPane = !!settingsPane && isSelected;
				return (
					<ContentContainer padding={'none'} width={'100%'}>
						<FlexColumn gap={'s'}>
							<SelectionHeader
								selected={isSelected}
								disabled={isDisabled}
								showBorder={showSettingsPane}
								onClick={isDisabled ? () => {} : () => setProgramBudgetType(availableBudgetType)}
							>
								<FlexColumn>
									<FlexRow gap={'s'}>
										<FlexItem alignSelf={FLEX_ALIGN_ITEMS.CENTER}>
											<RadioButton
												cy={availableBudgetType.toLowerCase() + '-selector'}
												stateValue={isSelected}
												value={true}
												onSelected={
													isDisabled ? () => {} : () => setProgramBudgetType(availableBudgetType)
												}
											/>
										</FlexItem>
										<TopMargin>
											<FlexColumn gap={'s'}>
												<Text type="heading" variant="s">
													{formatMessage({
														id:
															'program.budget_type.' +
															availableBudgetType.toLowerCase() +
															'.title',
													})}
												</Text>
												<Text>
													{formatMessage({
														id:
															'program.budget_type.' +
															availableBudgetType.toLowerCase() +
															'.description',
													})}
												</Text>
											</FlexColumn>
										</TopMargin>
									</FlexRow>
								</FlexColumn>
							</SelectionHeader>
							{showSettingsPane && <SelectionBody>{settingsPane}</SelectionBody>}
						</FlexColumn>
					</ContentContainer>
				);
			})}
		</CreativeModal>
	);
};
