import React, {Component} from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import ReactDOM from 'react-dom';
import CustomScrollDiv from '../scroll-bars/custom_scroll_div';
import {INITIALS_SIZE} from '../../../../constants';
import {FormattedMessage} from 'react-intl';
import TooltipContainer from '../tooltips/tooltip_container';
import PersonInitials from '../person/person_initials';
import Util from '../../util/util';
import {profilePicSrc} from '../../../../directApi';
import {FlexColumn} from '@forecast-it/design-system';

class DropdownV2 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: false,
			searchCriteria: '',
			selected: null,
			renderOnTop: false,
			dropdownHeight: 0,
			expandedNestedOptions: [],
			allNestedCollapsed: true,
			allNestedExpanded: false,
		};
		this.uuid = props.uuid || uuid.v4();
		this.arrowId = 'arrow-' + this.uuid;
		this.topLevelOptionId = 'top-level-option';
		this.topLevelOptionArrowId = 'top-level-option-arrow';

		this.collapseDropdown = this.collapseDropdown.bind(this);
	}

	componentDidMount() {
		document.addEventListener('scroll', this.collapseDropdown, true);
		window.addEventListener('resize', this.collapseDropdown, true);
		if (this.props.focusOnMount) {
			this.inputRef.focus();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.dropdown &&
			((!prevState.expanded && this.state.expanded) || prevState.renderOnTop !== this.state.renderOnTop)
		) {
			const dropdownNode = this.dropdown.getBoundingClientRect();
			const inputNode = this.inputRef.getBoundingClientRect();
			const windowHeight = window.innerHeight;
			const newRenderOnTop =
				dropdownNode.height + inputNode.bottom > windowHeight && !this.props.disableRenderCalculations;

			if (this.state.renderOnTop !== newRenderOnTop || this.state.dropdownHeight !== dropdownNode.height) {
				this.setState({
					renderOnTop: newRenderOnTop,
					dropdownHeight: dropdownNode.height,
				});
			}
		}
		if (prevState.expanded && !this.state.expanded) {
			// Approximate if dropdown will need to render on top based on maxHeight of dropdown to prevent constant state switching
			if (this.inputRef) {
				const inputNode = this.inputRef.getBoundingClientRect();
				const maxHeight = this.props.maxHeight || 400;

				this.setState({renderOnTop: inputNode.bottom + maxHeight > window.innerHeight});
			}
		}
		if (this.dropdown && prevState.searchCriteria !== this.state.searchCriteria) {
			const dropdownNode = this.dropdown.getBoundingClientRect();
			this.setState({
				dropdownHeight: dropdownNode.height,
			});
		}
		if (this.state.searchCriteria.length !== 0 && this.state.searchCriteria !== prevState.searchCriteria) {
			this.setState({allNestedExpanded: true});
		}
	}

	componentWillUnmount() {
		document.removeEventListener('scroll', this.collapseDropdown, true);
		window.removeEventListener('resize', this.collapseDropdown, true);
	}

	collapseDropdown(e) {
		if (
			e.type === 'scroll' &&
			e.srcElement.selectionStart !== undefined &&
			e.srcElement.selectionStart === e.srcElement.selectionEnd
		)
			return; //hack to make sure the dropdown doesn't collapse when opening it with an overflowing name
		if (e.target && ['contract-trigger', 'expand-trigger'].includes(e.target.className)) return; //hack to fix bug in insights page that would not allow dropdowns in task modal to expand on top

		if (!this.state.expanded && this.inputRef) {
			this.inputRef.blur();
			return;
		}
		if (
			e.target &&
			e.target.firstChild &&
			((this.select_option_0 && e.target.firstChild.id === this.select_option_0.id) ||
				(this.nested_list_wrapper && e.target.firstChild.className === this.nested_list_wrapper.className))
		) {
			return;
		}
		this.setState({expanded: false, selected: null, searchCriteria: ''});
	}

	handleFocus() {
		if (this.props.onClick) {
			this.props.onClick();
		}

		if (!this.props.disabled) {
			if (this.props.onFocus) {
				this.props.onFocus();
			}
			if (!this.state.expanded) {
				this.inputRef.focus();
			}

			if (this.inputRef) {
				this.offset = this.inputRef.getBoundingClientRect();
			}
			this.setState({expanded: true});
		}
	}

	handleFocusFromClear() {
		if (!this.props.disabled) {
			this.handleFocus();
			this.props.onChange(null);
		}
	}

	handleBlur(e) {
		const newTarget = e.type.includes('key') ? null : e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11
		if (
			newTarget &&
			((this.inputRef && this.inputRef.contains(newTarget)) ||
				newTarget.id === this.arrowId ||
				newTarget.id === this.topLevelOptionId ||
				newTarget.id === this.topLevelOptionArrowId ||
				(newTarget.className && newTarget.className.includes('create-new-text')))
		)
			return;
		this.setState({expanded: false, selected: null, searchCriteria: ''});
		if (this.props.onBlur) {
			this.props.onBlur(e);
		}
	}

	handleSelect(e, option) {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		if (option && !option.disabled && !this.props.disabled) {
			if (option.clearSelected) {
				// User clicked the 'Clear' option
				this.props.onChange(null);
			} else {
				this.props.onChange(option);
			}
			if (!this.props.multiSelect && !this.props.stayOpenOnSelect) {
				this.inputRef.focus();
				this.setState({expanded: false, selected: null, searchCriteria: ''});
			}
		}

		// rerender the dropdown after selecting a item from the dropdown
		setTimeout(() => {
			this.forceUpdate();
		});
	}

	handleSearchCriteriaChange(e) {
		let value = e.target.value;
		if (!this.props.nested && !this.state.expanded && e.target.value) {
			let valueLabel = '';
			this.props.options.forEach(option => {
				if (option.value === this.props.value) {
					valueLabel = option.label;
				}
			});

			if (Util.normalizedIncludes(e.target.value, valueLabel)) {
				value = e.target.value.replace(valueLabel, '');
			}
		}

		const newState = {searchCriteria: value, selected: 0, expanded: true};
		this.setState(newState);
		if (this.props.onSearchValueChange) {
			this.props.onSearchValueChange(value);
		}
	}

	handleKeyPress(e) {
		if (this.state.expanded) {
			if (e.keyCode === 27 || e.key === 'Escape') {
				// Escape
				this.handleBlur(e);
			} else if (e.key === 'Enter') {
				//Enter / Space
				const options = this.props.options.filter(option => {
					if (typeof option.label === 'string') {
						return (
							this.state.searchCriteria === '' ||
							Util.normalizedIndexOf(option.label, this.state.searchCriteria) >= 0
						);
					}
					return (
						this.state.searchCriteria === '' ||
						(option.searchString && Util.normalizedIndexOf(option.searchString, this.state.searchCriteria) >= 0)
					);
				});
				if (this.state.selected !== null) {
					const option = options[this.state.selected];
					this.handleSelect(null, option);
				} else if (this.state.selected === null && this.state.searchCriteria !== '') {
					this.handleSelect(null, options[0]);
				}
			} else if (e.keyCode === 40) {
				// Down arrow
				const optionsLength =
					this.state.searchCriteria === ''
						? this.props.options.length
						: this.props.options.filter(option => {
								if (typeof option.label === 'string') {
									return (
										this.state.searchCriteria === '' ||
										Util.normalizedIndexOf(option.label, this.state.searchCriteria) >= 0
									);
								}
								return (
									this.state.searchCriteria === '' ||
									(option.searchString &&
										Util.normalizedIndexOf(option.searchString, this.state.searchCriteria) >= 0)
								);
						  }).length;
				if (this.state.selected + 1 !== optionsLength) {
					let selected = 0;
					if (this.state.selected !== null) {
						selected = this.state.selected + 1;
					}
					this.setState({selected: selected});
					if (this.customScrollRef) {
						const domNode = ReactDOM.findDOMNode(this['select_option_' + selected]);
						if (domNode) this.customScrollRef.setScrollTop(domNode.offsetTop - 50);
					}
				}
			} else if (e.keyCode === 38) {
				// Alt + Up arrow closes the dropdown
				if (e.altKey) {
					if (this.state.expanded) {
						this.setState({expanded: false, selected: null, searchCriteria: ''});
						return;
					}
				}
				// Up arrow
				const optionsLength =
					this.state.searchCriteria === ''
						? this.props.options.length
						: this.props.options.filter(option => {
								if (typeof option.label === 'string') {
									return (
										this.state.searchCriteria === '' ||
										Util.normalizedIndexOf(option.label, this.state.searchCriteria) >= 0
									);
								}
								return (
									this.state.searchCriteria === '' ||
									Util.normalizedIndexOf(option.searchString, this.state.searchCriteria) >= 0
								);
						  }).length;
				if (!this.state.renderOnTop && this.state.selected === null) return;

				let selected = 0;
				if (this.state.renderOnTop && this.state.selected === null) {
					selected = optionsLength - 1;
				} else if (this.state.selected > 0) {
					selected = this.state.selected - 1;
				}
				this.setState({selected: selected});
				if (this.customScrollRef) {
					const domNode = ReactDOM.findDOMNode(this['select_option_' + selected]);
					if (domNode) this.customScrollRef.setScrollTop(domNode.offsetTop - 200);
				}
			}
		} else if (e.keyCode === 40 && e.altKey) {
			// Alt + Down arrow opens the dropdown
			if (!this.state.expanded) {
				this.setState({expanded: true});
				return;
			}
		}
	}

	toggleDropdown(e, bool) {
		if (this.props.disabled) {
			return;
		}

		if (this.props.focusOnClick) {
			const curExpanded = this.state.expanded;
			this.setState({expanded: bool != null ? bool : !curExpanded, searchCriteria: '', selected: null}, () =>
				!curExpanded ? this.focusDropdownInput() : null
			);
		}
		// Old functionality, has some weird behaviour with arrow, but didn't wanna change it everywhere
		else {
			if (this.state.expanded) {
				this.setState({expanded: false, selected: null, searchCriteria: ''});
			} else {
				this.inputRef.focus();
			}
		}
	}

	focusDropdownInput() {
		// Can be called vis refs to focus the dropdown input
		if (this.inputRef) {
			this.inputRef.focus();
		}
	}

	handleCreateNew() {
		this.props.onCreateNew();
		const option = {label: this.state.searchCriteria};
		this.handleSelect(null, option);
	}

	setExpandedNestedOptions(value) {
		let expandedNestedOptions = this.state.expandedNestedOptions;
		if (Util.normalizedIncludes(expandedNestedOptions, value)) {
			expandedNestedOptions = expandedNestedOptions.filter(el => el !== value);
		} else {
			expandedNestedOptions.push(value);
		}
		this.setState({expandedNestedOptions, allNestedCollapsed: false});
	}

	retryAction(e) {
		if (this.props.integrationRetryToFetch) {
			if (e) {
				e.preventDefault();
				e.stopPropagation();
			}
			this.props.integrationRetryToFetch(this.state.searchCriteria);
		}
	}

	render() {
		let extension = this.props.required ? ' *' : this.props.optional ? ' (optional)' : '';
		let valueLabel = '';
		let descriptionLabel = null;
		let options = [];

		if (this.props.nested && this.props.stayOpenOnSelect) {
			this.props.options.forEach(option => {
				const obj = {
					value: option.value,
					label: option.label,
					subLabel: option.subLabel,
					description: option.description,
					options: [],
					isPersonSelector: option.isPersonSelector,
				};
				let nestedOptions = [];
				if (this.state.searchCriteria === '') {
					nestedOptions = option.options;
				} else {
					option.options
						.filter(nestedOption => {
							return nestedOption.label
								? Util.normalizedIncludes(nestedOption.label, this.state.searchCriteria)
								: nestedOption.person
								? Util.normalizedIncludes(
										(nestedOption.person.firstName ? nestedOption.person.firstName : '') +
											' ' +
											(nestedOption.person.lastName ? nestedOption.person.lastName : '') +
											' ' +
											(nestedOption.person.email ? nestedOption.person.email : ''),
										this.state.searchCriteria
								  )
								: false;
						})

						.forEach(nestedOption => nestedOptions.push(nestedOption));
				}

				if (nestedOptions.length !== 0) {
					obj.options = nestedOptions;
					options.push(obj);
				}
				obj.label = obj.label + ' (' + nestedOptions.length + ')';
			});
		} else {
			if (this.props.nested) {
				this.props.options.forEach(option => {
					option.options.forEach(opt => {
						if (opt.value === this.props.value) {
							valueLabel = opt.valueLabel || opt.label;
							descriptionLabel = opt.description ? opt.description : null;
						}
					});
				});
			} else {
				this.props.options.forEach(option => {
					if (this.props.multiSelect && this.props.value && this.props.value.includes(option.value)) {
						const label = option.valueLabel || option.label;
						valueLabel += valueLabel === '' ? label : ', ' + label;
					} else if (option.value === this.props.value) {
						valueLabel = option.valueLabel || option.label;
						descriptionLabel = option.description ? option.description : null;
					}
				});
			}
			if (this.props.nested) {
				let opts = this.props.options.filter(opt =>
					opt.options.find(
						option =>
							this.state.searchCriteria === '' ||
							Util.normalizedIndexOf(option.label, this.state.searchCriteria) >= 0
					)
				);
				options = opts;
			} else {
				options = this.props.options.filter(option => {
					if (typeof option.label === 'string') {
						return (
							this.state.searchCriteria === '' ||
							Util.normalizedIndexOf(option.label, this.state.searchCriteria) >= 0
						);
					} else {
						return (
							this.state.searchCriteria === '' ||
							(option.searchString && Util.normalizedIndexOf(option.searchString, this.state.searchCriteria) >= 0)
						);
					}
				});
			}

			if (this.props.clearable && this.props.clearText) {
				options.unshift({
					label: this.props.clearText,
					description: this.props.clearTextDescription,
					value: null,
					clearSelected: true,
					disabled: this.props.clearTextDisabled,
				});
			}
		}
		const expandedClass = this.state.expanded ? ' expanded' : '';
		const propsBasedClass = this.props.hideLabel ? ' label-hidden' : '';
		const renderOnTopClass = this.state.renderOnTop ? ' on-top' : '';

		//const dropdownStyle = this.state.renderOnTop ? {top: 0 - this.state.dropdownHeight + (this.props.hideLabel ? 0 : 16)} : {};
		let dropdownStyle = {};
		if (this.offset) {
			if (this.state.renderOnTop) {
				dropdownStyle = {
					bottom: window.innerHeight - this.offset.top,
					left: this.offset.left,
					width: this.props.restrictWidth ? this.offset.width : undefined,
					marginBottom: 0,
				};
			} else {
				dropdownStyle = {
					top: this.offset.top + this.offset.height,
					left: this.offset.left,
					width: this.props.restrictWidth ? this.offset.width : undefined,
				};
			}
		}
		const transparentBackground = this.props.transparentBackground ? ' transparent-background' : '';
		const customStyle = {
			height: this.props.customHeight + 'px',
		};
		const showCreateNew = this.props.onCreateNew && this.state.searchCriteria !== '' && options.length === 0;
		return (
			<TooltipContainer {...this.props.tooltipProps} disabled={this.state.expanded}>
				<div
					data-cy={this.props.dataCy}
					id={this.props.id}
					className={
						'drop-down-v2' +
						(this.props.stylingTheme ? ' ' + this.props.stylingTheme : '') +
						(this.props.customClasses ? ' ' + this.props.customClasses : '') +
						(this.props.invalidInput && !this.state.expanded ? ' invalid' : '') +
						(this.props.disabled ? ' disabled' : '')
					}
					title={valueLabel}
				>
					{this.props.hideLabel ? null : (
						<label id={'label-' + this.uuid} className={'label'} htmlFor={this.uuid}>
							{this.props.label}{' '}
							<span className={this.props.required ? 'required-extension' : ''}>{extension}</span>
						</label>
					)}
					{this.props.iconClass ? <div className={this.props.iconClass} /> : null}
					<input
						title={descriptionLabel ? descriptionLabel : this.props.inputTitle ? this.props.inputTitle : null}
						style={{
							color: this.props.inputTextColor || undefined,
							height: this.props.customHeight + 'px',
							width: !this.props.noWidth
								? this.props.customWidth
									? this.props.customWidth + 'px'
									: 'auto'
								: null,
						}}
						type="text"
						className={
							(this.props.inputClassName ? this.props.inputClassName : '') +
							expandedClass +
							renderOnTopClass +
							transparentBackground +
							propsBasedClass +
							(showCreateNew ? ' create-new' : '') +
							(this.props.iconClass ? ' has-icon' : '')
						}
						id={this.uuid}
						value={this.state.expanded ? this.state.searchCriteria : valueLabel}
						disabled={this.props.disabled}
						onChange={this.handleSearchCriteriaChange.bind(this)}
						ref={input => {
							this.inputRef = input;
						}}
						autoComplete={this.props.autoComplete ? this.props.autoComplete : 'off'}
						onFocus={this.handleFocus.bind(this)}
						onClick={this.props.focusOnClick ? this.handleFocus.bind(this) : null}
						onBlur={this.handleBlur.bind(this)}
						onKeyDown={this.handleKeyPress.bind(this)}
						placeholder={valueLabel !== '' ? valueLabel : this.props.placeholder}
						role="combobox"
						name={this.props.inputName ? this.props.inputName : ''}
						// Dynamic way to set attributes
						{...(this.props.readOnly ? {readOnly: true} : {})}
						aria-autocomplete="list"
						aria-owns="options"
						aria-labelledby={'label-' + this.uuid}
						aria-expanded={this.state.expanded}
						aria-activedescendant={this.state.selected != null ? 'select_option_' + this.state.selected : ''}
						data-cy={this.props.inputCy}
						data-userpilot={this.props.userpilot}
					/>
					{showCreateNew ? (
						<div tabIndex={'0'} onClick={this.handleCreateNew.bind(this)} className={'create-new-text'}>
							{this.props.createNewText}
						</div>
					) : (
						<div
							id={this.arrowId}
							style={customStyle}
							className={'arrow' + expandedClass + propsBasedClass + (this.props.disabled ? ' disabled' : '')}
							aria-hidden="true"
							onClick={this.toggleDropdown.bind(this)}
							data-cy={this.props.buttonCy}
							tabIndex={-1}
						/>
					)}
					{this.state.expanded && (options.length || this.props.integrationDropdown)
						? ReactDOM.createPortal(
								<ul
									data-cy={this.props.listDataCy}
									role="listbox"
									id="options"
									style={dropdownStyle}
									className={
										'base-list' +
										propsBasedClass +
										renderOnTopClass +
										(this.props.nested ? ' two-level-list' : ' one-level-list') +
										(this.props.stylingTheme ? ' ' + this.props.stylingTheme : '') +
										(this.props.headerProjectSelectDropdown ? ' header-project-select-dropdown' : '') +
										(this.props.customClasses ? ' ' + this.props.customClasses : '') +
										(this.props.borderlessOnTop ? ' no-on-top-border' : '') +
										(this.props.integrationLoading ? ' integration-loading' : '')
									}
									ref={ul => (this.dropdown = ul)}
								>
									{this.props.integrationLoading ? (
										<li className={'loading-box'}>
											<div className={'loading-text'}>{<FormattedMessage id="common.loading" />}</div>
											<div className={'loading-item'} />
										</li>
									) : options.length === 0 ? (
										<li className={'retry-box'} onMouseDown={this.retryAction.bind(this)}>
											<div className={'empty-text'}>{<FormattedMessage id="common.empty_dots" />}</div>
											{this.props.integrationRetryToFetch ? (
												<div className={'retry-text'}>
													{' '}
													<FormattedMessage id="common.retry" />
												</div>
											) : null}
										</li>
									) : this.props.nested ? (
										<CustomScrollDiv
											autoHeight={true}
											autoHeightMin={1}
											autoHeightMax={this.props.maxHeight ? this.props.maxHeight : 400}
											ref={div => (this.customScrollRef = div)}
										>
											<div className="nested-list-wrapper" ref={div => (this.nested_list_wrapper = div)}>
												{options.map((option, index) => (
													<li
														data-cy={
															option.label.toLowerCase().trim().includes('non-project')
																? this.props.nonProjectCy
																: this.props.listDataCy + '-' + index
														}
														className={
															'top-level-option' +
															(options.length - 1 === index ? ' no-border-bottom' : '') +
															(!this.state.allNestedCollapsed &&
															Util.normalizedIncludes(
																this.state.expandedNestedOptions,
																option.value
															)
																? ' expanded'
																: ' collapsed')
														}
														key={index}
														tabIndex={-1}
														onMouseDown={this.setExpandedNestedOptions.bind(this, option.value)}
														role="option"
														id={this.topLevelOptionId}
														onBlur={this.handleBlur.bind(this)}
													>
														<div className="option-label-wrapper">
															<div className={'option-label'}>{option.label}</div>
															{option.count ? (
																<div className="option-count"> ({option.count})</div>
															) : null}
															<div
																id={this.topLevelOptionArrowId}
																className={'collapse-nested-options-icon '}
															/>
														</div>
														{!this.state.allNestedExpanded &&
														(this.state.allNestedCollapsed ||
															!Util.normalizedIncludes(
																this.state.expandedNestedOptions,
																option.value
															)) ? null : (
															<ul className="nested-list" role="listbox">
																{/* select team */}
																{option.options.map((nestedOption, index) => (
																	<li
																		data-cy={this.props.listDataCy + '-' + index}
																		title={option.description ? option.description : null}
																		id={'select_option_' + index}
																		ref={li => (this['select_option_' + index] = li)}
																		className={
																			'nested-option' +
																			(option.isPersonSelector ? '' : ' padding-top') +
																			(this.state.selected === index ? ' selected' : '')
																		}
																		key={index}
																		tabIndex={-1}
																		role="option"
																		onMouseDown={e => {
																			this.handleSelect(e, nestedOption);
																		}}
																		onBlur={this.handleBlur.bind(this)}
																	>
																		{option.isPersonSelector ? (
																			<div className="person-option">
																				{nestedOption.person.profilePictureId ? (
																					<img
																						crossOrigin="use-credentials"
																						alt="profile_pic"
																						src={profilePicSrc(
																							nestedOption.person.profilePictureId
																						)}
																						width="36"
																						height="36"
																					/>
																				) : (
																					<PersonInitials
																						size={INITIALS_SIZE.SMALL}
																						initials={
																							nestedOption.person.initials
																								? nestedOption.person.initials
																								: Util.getInitials(
																										`${nestedOption.person.firstName} ${nestedOption.person.lastName}`
																								  )
																						}
																					/>
																				)}
																				<div className="person-details">
																					<span className="name">
																						{nestedOption.person.firstName +
																							' ' +
																							nestedOption.person.lastName}
																					</span>
																					<span className="email">
																						{nestedOption.person.email}
																					</span>
																				</div>
																			</div>
																		) : (
																			<span className="nested-option-label">
																				{nestedOption.label}
																			</span>
																		)}
																	</li>
																))}
															</ul>
														)}
													</li>
												))}
											</div>
										</CustomScrollDiv>
									) : (
										<div className="purple-border">
											<CustomScrollDiv
												autoHeight={true}
												autoHeightMin={1}
												autoHeightMax={this.props.maxHeight ? this.props.maxHeight : 400}
												ref={div => (this.customScrollRef = div)}
											>
												{options.map((option, index) => (
													<li
														title={option.description ? option.description : null}
														id={'select_option_' + index}
														ref={li => (this['select_option_' + index] = li)}
														role="option"
														tabIndex={-1}
														key={index}
														className={
															'option' +
															(this.state.selected === index ? ' selected' : '') +
															(option.disabled || this.props.disabled ? ' disabled' : '') +
															(this.props.noWrap ? ' nowrap' : '')
														}
														//This is sort of hacky - onMouseDown is required for cypress, but does not behave well with the DnD framework.
														//As such we fire the function both onMouseDown and onClick - for inexplicable reasons, these are currently not fired twice even with the flag?
														onClick={e =>
															this.props.optionClickEvent ? this.handleSelect(e, option) : null
														}
														onMouseDown={e => this.handleSelect(e, option)}
														//onMouseDown={e => (this.props.optionClickEvent ? null : this.handleSelect(e, option))}
														data-cy={
															option.dataCy
																? option.dataCy
																: this.props.listDataCy +
																  '-' +
																  (option.label && option.label.length > 0
																		? option.label.split(' ').join('')
																		: '')
														}
														data-cy-class={option.dataCy ? option.dataCy : this.props.listDataCy}
													>
														{this.props.isPersonSelector ? (
															<div
																className="person-container"
																style={{
																	height: this.props.personRowHeight
																		? this.props.personRowHeight
																		: '',
																}}
															>
																{option.profilePictureId ? (
																	<img
																		crossOrigin="use-credentials"
																		alt="profile_pic"
																		src={profilePicSrc(option.profilePictureId)}
																		width={
																			this.props.personImageSize
																				? this.props.personImageSize
																				: '36'
																		}
																		height={
																			this.props.personImageSize
																				? this.props.personImageSize
																				: '36'
																		}
																	/>
																) : (
																	<PersonInitials
																		size={INITIALS_SIZE.SMALL}
																		initials={
																			option.initials
																				? option.initials
																				: Util.getInitials(
																						`${option.firstName} ${option.lastName}`
																				  )
																		}
																	/>
																)}
																<div className="person-description">
																	<div
																		style={
																			this.props.personNameStyle
																				? this.props.personNameStyle
																				: undefined
																		}
																		className="person-name"
																	>
																		{option.label}
																	</div>
																	{option.subLabel ? (
																		<div className="person-role">{option.subLabel}</div>
																	) : null}
																</div>
															</div>
														) : (
															<>
																{option.logo ? <span className={option.logo}></span> : null}
																<FlexColumn gap="none">
																	<span className="option-label">{option.label}</span>
																	{option.subLabel ? (
																		<div className="option-sublabel">{option.subLabel}</div>
																	) : null}
																</FlexColumn>
															</>
														)}
														{this.props.multiSelect &&
														this.props.value &&
														this.props.value.includes(option.value) ? (
															<div className="selected-value-indicator" />
														) : (
															''
														)}
													</li>
												))}
											</CustomScrollDiv>
										</div>
									)}
								</ul>,
								document.querySelector('#root-portal-container')
						  )
						: null}
					{this.props.invalidInput && !this.state.expanded && this.props.errorMessage ? (
						<span className="error-message">{this.props.errorMessage}</span>
					) : null}
				</div>
			</TooltipContainer>
		);
	}
}

DropdownV2.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.object, PropTypes.bool]),
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
			valueLabel: PropTypes.string, // Alternative label to show in input if option is selected
			value: PropTypes.any,
			disabled: PropTypes.bool,
			description: PropTypes.string,
		})
	),
	label: PropTypes.string,
	required: PropTypes.bool,
	invalidInput: PropTypes.bool,
	optional: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	disabled: PropTypes.bool,
	clearable: PropTypes.bool,
	clearText: PropTypes.string,
	clearTextDisabled: PropTypes.bool,
	clearTextDescription: PropTypes.string,
	onCreateNew: PropTypes.func,
	hideLabel: PropTypes.bool,
	stylingTheme: PropTypes.oneOf(['white', 'black']),
	placeholder: PropTypes.string,
	projectSelect: PropTypes.bool,
	transparentBackground: PropTypes.bool,
	isPersonSelector: PropTypes.bool,
	uuid: PropTypes.string,
	stayOpenOnSelect: PropTypes.bool,
	inputTextColor: PropTypes.string,
	customHeight: PropTypes.number,
	customClasses: PropTypes.string,
	inputCy: PropTypes.string,
	restrictWidth: PropTypes.bool,
	multiSelect: PropTypes.bool,
	maxHeight: PropTypes.number,
};

DropdownV2.defaultProps = {
	customHeight: 40,
	restrictWidth: true,
};

export default DropdownV2;
