import React from 'react';
import styled from 'styled-components';
import {DropdownHandler, Icon, NavBarIconButton} from '@forecast-it/design-system';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import {hasModule} from '../../shared/util/ModuleUtil';
import {MODULE_TYPES} from '../../../constants';

const StyledA = styled.a`
	text-decoration: none;
	justify-content: space-between;

	&:hover {
		color: inherit;
	}
`;

const HelpButton = props => {
	return (
		<DropdownHandler>
			<DropdownHandler.Trigger onClick={() => trackEvent('Navigation Bar Help Menu Button', 'Clicked')}>
				<NavBarIconButton aria-label="help" icon="help" />
			</DropdownHandler.Trigger>
			<DropdownHandler.Content>
				<DropdownHandler.Item asChild>
					<StyledA
						href="https://support.forecast.app/hc/en-us/sections/4767242189201-Release-Notes"
						target="_blank"
						rel="noopener noreferrer"
						onClick={() => trackEvent('Whats New', 'Clicked')}
					>
						What's New
						<Icon icon="newTab" />
					</StyledA>
				</DropdownHandler.Item>
				{!hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED) ? (
					<DropdownHandler.Item asChild>
						<StyledA
							href="https://support.forecast.app"
							target="_blank"
							rel="noopener noreferrer"
							onClick={() => trackEvent('Help Center', 'Opened')}
						>
							Help
							<Icon icon="newTab" />
						</StyledA>
					</DropdownHandler.Item>
				) : null}
			</DropdownHandler.Content>
		</DropdownHandler>
	);
};

export default HelpButton;
