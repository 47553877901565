import React from 'react';
import {dispatch, EVENT_ID} from '../../../../../containers/event_manager';
import Util from '../../../util/util';
import CreateTaskProgressMutation from '../../../../../mutations/create_task_progress_mutation';
import {ProgressRegistrationPopup} from './ProgressRegistrationPopup';
import {useIntl} from 'react-intl';
import {createToast} from '../../toasts/toast';
import {ForecastThemeProvider} from '../../../ForecastThemeProvider';

const dispatchPopupEvent = task => {
	if (task) {
		dispatch(EVENT_ID.TIME_POPUP_OPENED, task.id);
	}
};

const saveTaskProgress = (intl, task, newProgressValue) => {
	const onSuccess = () => {
		createToast({
			duration: 5000,
			message: intl.formatMessage({id: 'common.progress-updated'}),
		});
	};
	if (!isNaN(newProgressValue) && task && task.project?.id) {
		Util.CommitMutation(
			CreateTaskProgressMutation,
			{
				taskId: task.id,
				progress: newProgressValue,
				projectId: task.project.id,
				phaseId: task.phase?.id,
			},
			onSuccess
		);
	}
};

export const TaskProgressRegistrationPopup = ({task, currentProgressValue}) => {
	const intl = useIntl();

	return (
		<ForecastThemeProvider>
			<ProgressRegistrationPopup
				currentProgressValue={currentProgressValue}
				onOpen={() => dispatchPopupEvent(task)}
				onClose={newProgressValue => saveTaskProgress(intl, task, newProgressValue)}
			/>
		</ForecastThemeProvider>
	);
};
