// @ts-check

import React, {useRef} from 'react';
import styled from 'styled-components';
import {StatusIndicator} from '../../../../containers/project/project_health/project_health_status/StatusIndicator';
import ForecastQueryRenderer from '../../../../ForecastQueryRenderer';
import StatusSidebar, {StatusSidebarQuery} from './StatusSidebar';
import InlineLoader from '../inline-loader/inline_loader';
import {toggleSlideout} from '../slideout/SlideoutConductor';

/**
 * @enum {string}
 */
export const RAG_STATUS = {
	RED: 'RED',
	AMBER: 'AMBER',
	GREEN: 'GREEN',
};

/**
 * Converter function to translate old color based approach to new RAG_STATUS based approach.
 * @param {string} statusColor Hex-color representation of a status
 * @returns {RAG_STATUS} RAG_STATUS representation of a status. Default to RAG_STATUS.GREEN.
 */
export const convertStatusColorToRagStatus = statusColor => {
	switch (statusColor) {
		case '#ff3300':
			return RAG_STATUS.RED;
		case '#ffc107':
			return RAG_STATUS.AMBER;
		default:
			return RAG_STATUS.GREEN;
	}
};

export const ragStatusSortOrder = ragStatus => {
	switch (ragStatus) {
		case RAG_STATUS.GREEN:
			return '2';
		case RAG_STATUS.AMBER:
			return '1';
		case RAG_STATUS.RED:
			return '0';
	}
};

const StatusIndicatorClickableWrapper = styled.button`
	border: none;
	padding: 4px;
	border-radius: 4px;
	cursor: pointer;
	&:hover {
		background-color: rgba(0, 0, 135, 0.1);
	}
`;

/**
 * @typedef {object} StatusWrapperProps
 * @property {number} [projectId]
 * @property {string} [programPrefix]
 * @property {RAG_STATUS} currentStatusRag
 * @property {boolean} [noHeader=false]
 */

const slideoutContent = (projectId, programPrefix) => (
	<ForecastQueryRenderer
		key={`query-render-status-sidebar` + (projectId || programPrefix)}
		query={StatusSidebarQuery}
		variables={{projectId: projectId?.toString(), programPrefix: programPrefix}}
		customLoader={() => <InlineLoader />}
		render={(relayProps, retry) => {
			// @ts-ignore
			return <StatusSidebar {...relayProps} />;
		}}
	/>
);

/**
 * @type {React.FC<StatusWrapperProps>}
 */
const StatusWrapper = ({projectId, programPrefix, currentStatusRag = RAG_STATUS.GREEN, noHeader = false}) => {
	const statusWrapperRef = useRef(null);

	const slideoutId = `status-${programPrefix ?? projectId}`;
	const toggleSidebar = e => {
		e.preventDefault();
		e.stopPropagation();
		toggleSlideout(slideoutId, {
			content: slideoutContent(projectId, programPrefix),
			trackingName: programPrefix ? 'Program Status Slideout' : 'Project Status Slideout',
			noHeader: noHeader,
			invokerRef: statusWrapperRef,
		});
	};

	return (
		<StatusIndicatorClickableWrapper ref={statusWrapperRef} tabIndex={0} onClick={e => toggleSidebar(e)}>
			<StatusIndicator projectStatusColor={currentStatusRag} fromRagStatus showChevron withMargin={false} />
		</StatusIndicatorClickableWrapper>
	);
};

export default StatusWrapper;
