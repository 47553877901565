import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import LabelWithTooltip from '../../../../../shared/components/add-time-entry/validation-info/LabelWithTooltip';
import { NoteRequiredError, NoteRequiredInfoIcon, } from '../../../../../shared/components/add-time-entry/validation-info/NoteRequiredInfo';
import { convertIntoFloatHoursFormatWithGranularity } from '../../../../../shared/components/inputs/hours-input/hours_input_logic';
import HoursInput from '../../../../../shared/components/inputs/hours-input/hours_input_view';
import useUpdateEffect from '../../../../../shared/hooks/useUpdateEffect';
import { isBillableSplitAllowed, isRolesAllowed, isTimeRegistrationNoteRequired, } from '../../../../../shared/util/cache/TimeRegistrationSettingsUtil';
import Util from '../../../../../shared/util/util.js';
import { Column, ContentWrapper, Label, Notes, Row } from './AdvancedTimeRegistrationStyled';
import { trackEvent } from '../../../../../../tracking/amplitude/TrackingV2';
import { RemainingTimeAndError } from '../../../../../shared/components/add-time-entry/validation-info/TimeExceedsEstimateInfo';
import ForecastTooltip from '../../../../../shared/components/tooltips/ForecastTooltip.js';
import RoleDropdownRelayWrapper from '../../../../../shared/components/dropdowns/role-dropdown/RoleDropdownRelayWrapper';
import { RoleDropdownLabelFormat } from '../../../../../shared/components/dropdowns/role-dropdown/RoleDropdown';
import { getFormattedValue } from '../timesheet-table-util/TimeRegUtil';
export var PopupFocusItem;
(function (PopupFocusItem) {
    // Can be extended with e.g. hour inputs if needed in the future
    PopupFocusItem[PopupFocusItem["Notes"] = 0] = "Notes";
    PopupFocusItem[PopupFocusItem["Hours"] = 1] = "Hours";
})(PopupFocusItem || (PopupFocusItem = {}));
function getTimeInputValue_timeRegInputConversion(timeInputMinutes) {
    if (timeInputMinutes != null && !isNaN(timeInputMinutes)) {
        return `${timeInputMinutes}m`;
    }
    return undefined;
}
function getBillableTimeInputValue_timeRegInputConversion(billableTimeInputMinutes, timeInputValue) {
    if (billableTimeInputMinutes != null && !isNaN(billableTimeInputMinutes)) {
        return `${billableTimeInputMinutes}m`;
    }
    return timeInputValue;
}
function AdvancedTimeRegistrationContent({ id, timeInputMinutes, onTimeChange, billableTimeInputMinutes, onBillableTimeChange, notes, onNotesChange, billable, noteError, timeError, remainingInfoMessage, workingHours, timeRegistrationFocus, role, onRoleChange, firstTimeRegistration, canHaveRole, projectId, taskId, personId, isCompanyTimesheet, }) {
    const intl = useIntl();
    const billableMinutesUpdated = useRef(false);
    const initialNote = useRef(notes);
    const noteInputRef = useRef(null);
    const hoursInputRef = useRef(null);
    const isNoteRequired = isTimeRegistrationNoteRequired();
    const billableSplitAllowed = isBillableSplitAllowed();
    const isEditOperation = !!id;
    const showRoles = isRolesAllowed(isEditOperation && isCompanyTimesheet);
    const hasTeamTimeAccess = Util.AuthorizeViewerAccess('team-time');
    const onHoursInputChange = (value, _, __, isEnterBlur) => {
        const formattedValue = getFormattedValue(value);
        onTimeChange(formattedValue, isEnterBlur);
        trackEvent('Advanced Time Registration', 'Time Input Changed', {
            from: timeInputMinutes,
            to: formattedValue,
        });
    };
    const onBillableHoursInputChange = (value, _, __, isEnterBlur) => {
        const formattedValue = getFormattedValue(value);
        onBillableTimeChange(formattedValue, isEnterBlur);
        trackEvent('Advanced Time Registration', 'Billable Time Input Changed', {
            from: billableTimeInputMinutes,
            to: formattedValue,
        });
        billableMinutesUpdated.current = true;
    };
    const handleNotesChange = e => {
        const note = e.target ? e.target.value : '';
        onNotesChange(note);
    };
    const onNotesBlur = () => {
        trackEvent('Advanced Time Registration', 'Note Changed', { from: initialNote, to: notes });
        initialNote.current = notes;
    };
    useUpdateEffect(() => {
        if (!billableMinutesUpdated.current) {
            onBillableTimeChange(null);
        }
    }, [timeInputMinutes]);
    useEffect(() => {
        var _a, _b;
        if (timeRegistrationFocus.id === id) {
            switch (timeRegistrationFocus.focusItem) {
                case PopupFocusItem.Notes:
                    (_a = noteInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
                    break;
                case PopupFocusItem.Hours:
                    (_b = hoursInputRef.current) === null || _b === void 0 ? void 0 : _b.focus();
                    break;
            }
        }
    }, [timeRegistrationFocus]);
    // Controlled HoursInput works in hours
    const timeInputValue = getTimeInputValue_timeRegInputConversion(timeInputMinutes);
    // The value of the billable time input should be linked with the time input if it has not been changed yet
    const billableTimeInputValue = getBillableTimeInputValue_timeRegInputConversion(billableTimeInputMinutes, timeInputValue);
    return (React.createElement(ContentWrapper, { "data-cy": 'time-entry-popup-content' },
        React.createElement(Row, null,
            React.createElement(Column, null,
                React.createElement(Label, null, intl.formatMessage({ id: 'common.time_entry' })),
                React.createElement(HoursInput, { focusOnMount: firstTimeRegistration, innerRef: hoursInputRef, placeholder: 'ex. 1h', value: timeInputValue, mutation: onHoursInputChange, width: '90px', granularityFormatter: (val, intl, minuteLimit, showAsterisk) => convertIntoFloatHoursFormatWithGranularity(val, intl, minuteLimit, showAsterisk, workingHours), onlyFormatGranularityOnBlur: true, isTimeregInput: true })),
            billable && billableSplitAllowed && (React.createElement(Column, null,
                React.createElement(Label, null, intl.formatMessage({ id: 'common.billable_time' })),
                React.createElement(HoursInput, { placeholder: 'ex. 1h', value: billableTimeInputValue, mutation: onBillableHoursInputChange, width: '90px', granularityFormatter: (val, intl, minuteLimit, showAsterisk) => convertIntoFloatHoursFormatWithGranularity(val, intl, minuteLimit, showAsterisk, workingHours), onlyFormatGranularityOnBlur: true, isTimeregInput: true }))),
            canHaveRole && (React.createElement(ForecastTooltip, { disabled: showRoles, content: hasTeamTimeAccess
                    ? 'The role used on the time registration can be changed from the Timesheet Entries Page or Timesheet Approval Page'
                    : 'Role selection on time entries can be enabled by your admin in Forecast settings.' },
                React.createElement(Column, { style: { width: billable && billableSplitAllowed ? '163px' : '185px' } },
                    React.createElement(RoleDropdownRelayWrapper, { role: role, onSelect: onRoleChange, isDisabled: !showRoles, projectId: projectId, taskId: taskId, personId: personId, showLabel: true, labelFormat: RoleDropdownLabelFormat.NO_MARGIN }))))),
        React.createElement(RemainingTimeAndError, { timeError: timeError, remainingInfoMessage: remainingInfoMessage, marginBottom: '0' }),
        React.createElement(Column, null,
            React.createElement(Label, null,
                React.createElement(LabelWithTooltip, { label: 'Note', isRequired: isNoteRequired, isTimePage: true },
                    React.createElement(NoteRequiredInfoIcon, null))),
            React.createElement(Notes, { value: notes, ref: noteInputRef, maxLength: 999, rows: timeError ? 2 : 3, cols: 40, placeholder: 'Enter notes', onChange: handleNotesChange, onBlur: onNotesBlur }),
            noteError ? React.createElement(NoteRequiredError, null) : null)));
}
export default AdvancedTimeRegistrationContent;
