/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type settingsTeams_person$ref: FragmentReference;
declare export opaque type settingsTeams_person$fragmentType: settingsTeams_person$ref;
export type settingsTeams_person = {|
  +id: string,
  +fullName: ?string,
  +firstName: ?string,
  +lastName: ?string,
  +email: ?string,
  +profilePictureId: ?string,
  +profilePictureDefaultId: ?number,
  +role: ?{|
    +name: ?string
  |},
  +isViewer: ?boolean,
  +$refType: settingsTeams_person$ref,
|};
export type settingsTeams_person$data = settingsTeams_person;
export type settingsTeams_person$key = {
  +$data?: settingsTeams_person$data,
  +$fragmentRefs: settingsTeams_person$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "settingsTeams_person",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profilePictureId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profilePictureDefaultId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Role",
      "kind": "LinkedField",
      "name": "role",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isViewer",
      "storageKey": null
    }
  ],
  "type": "Person"
};
// prettier-ignore
(node/*: any*/).hash = '54d099f51645af5fca4132ea4291e069';

module.exports = node;
