/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type PersonDropdown_persons$ref: FragmentReference;
declare export opaque type PersonDropdown_persons$fragmentType: PersonDropdown_persons$ref;
export type PersonDropdown_persons = $ReadOnlyArray<{|
  +node: ?{|
    +id: string,
    +fullName: ?string,
    +firstName: ?string,
    +lastName: ?string,
    +profilePictureId: ?string,
    +active: ?boolean,
    +client: ?{|
      +id: string
    |},
    +role: ?{|
      +name: ?string
    |},
  |},
  +$refType: PersonDropdown_persons$ref,
|}>;
export type PersonDropdown_persons$data = PersonDropdown_persons;
export type PersonDropdown_persons$key = $ReadOnlyArray<{
  +$data?: PersonDropdown_persons$data,
  +$fragmentRefs: PersonDropdown_persons$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "PersonDropdown_persons",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Person",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "profilePictureId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "active",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Client",
          "kind": "LinkedField",
          "name": "client",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Role",
          "kind": "LinkedField",
          "name": "role",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PersonTypeEdge"
};
})();
// prettier-ignore
(node/*: any*/).hash = '723979b9af200f390d1737244100e6ac';

module.exports = node;
