import React, {useContext, useEffect, useState} from 'react';
import {ProgramContext} from '../program-overview-page/ProgramContextProvider';
import {
	FlexColumn,
	FlexRow,
	DeprecatedMainContainer as MainContainer,
	DeprecatedText as Text,
	DeprecatedLink as Link,
	FlexItem,
	Icon,
} from '@forecast-it/design-system';
import {useIntl} from 'react-intl';
import {useTrackPage} from '../../../../tracking/amplitude/hooks/useTrackPage';
import CustomScrollDiv from '../../../shared/components/scroll-bars/custom_scroll_div';
import ProgramBudgetTypeInfo from './ProgramBudgetTypeInfo';
import ProjectBudgetAllocationTable from './ProjectBudgetAllocationTable';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import ProgramUtil from '../../../shared/util/ProgramUtil';

const ErrorContainer = styled.div`
	width: 100%;
	border: 2px solid red;
	border-radius: 5px;
	padding: 10px;
`;

const ProgramBudgetEdit = () => {
	const {program, projects, company, actualPersonId, retry} = useContext(ProgramContext);
	const {formatMessage} = useIntl();
	const [showErrorBox, setShowErrorBox] = useState(false);
	const [errorTitle, setErrorTitle] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const history = useHistory();
	useEffect(() => {
		document.title = `${formatMessage({id: 'common.budget'})} - ${program.prefix}`;
	});
	useTrackPage('Program Budget');

	return (
		<MainContainer>
			<CustomScrollDiv>
				<FlexColumn gap={'xl'}>
					{showErrorBox && (
						<ErrorContainer>
							<FlexRow gap="l">
								<FlexItem>
									<Icon icon={'warning'} size={'l'} />
								</FlexItem>
								<FlexItem>
									<FlexColumn gap={'s'}>
										<Text variant={'bold'}>{errorTitle}</Text>
										<Text>{errorMessage}</Text>
									</FlexColumn>
								</FlexItem>
							</FlexRow>
						</ErrorContainer>
					)}
					<FlexRow>
						<Text>
							<Link onClick={() => history.push(`${ProgramUtil.programLink()}/${program.prefix}/budget`)}>
								{formatMessage({id: 'common.program_budget'})}
							</Link>
							{formatMessage({id: 'common.edit_program_budget'})}
						</Text>
					</FlexRow>
					<Text type={'heading'} variant={'l'}>
						{formatMessage({id: 'program_budget.program_funds_allocation'})}
					</Text>
					<ProgramBudgetTypeInfo
						program={program}
						company={company}
						actualPersonId={actualPersonId}
						hideContextMenu={true}
						showEditBudgetLink={true}
					/>
					<ProjectBudgetAllocationTable
						program={program}
						projects={projects}
						company={company}
						setShowErrorBox={setShowErrorBox}
						setErrorTitle={setErrorTitle}
						setErrorMessage={setErrorMessage}
						retry={retry}
					/>
				</FlexColumn>
			</CustomScrollDiv>
		</MainContainer>
	);
};

export default ProgramBudgetEdit;
