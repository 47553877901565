/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
query TimesheetsTaskCountQuery(
  $taskTypeSearchQuery: TaskSearchQueryType
) {
  viewer {
    component(name: "TimesheetsTaskCountQuery")
    company {
      allTasksCount(searchQuery: $taskTypeSearchQuery, approvedOnly: true, runningProjectOnly: true)
      id
    }
    id
  }
}
*/
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "taskTypeSearchQuery",
            "type": "TaskSearchQueryType"
        }
    ], v1 = {
        "alias": null,
        "args": [
            {
                "kind": "Literal",
                "name": "name",
                "value": "TimesheetsTaskCountQuery"
            }
        ],
        "kind": "ScalarField",
        "name": "component",
        "storageKey": "component(name:\"TimesheetsTaskCountQuery\")"
    }, v2 = {
        "alias": null,
        "args": [
            {
                "kind": "Literal",
                "name": "approvedOnly",
                "value": true
            },
            {
                "kind": "Literal",
                "name": "runningProjectOnly",
                "value": true
            },
            {
                "kind": "Variable",
                "name": "searchQuery",
                "variableName": "taskTypeSearchQuery"
            }
        ],
        "kind": "ScalarField",
        "name": "allTasksCount",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "TimesheetsTaskCountQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Company",
                            "kind": "LinkedField",
                            "name": "company",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "TimesheetsTaskCountQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Company",
                            "kind": "LinkedField",
                            "name": "company",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v3 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "TimesheetsTaskCountQuery",
            "operationKind": "query",
            "text": "query TimesheetsTaskCountQuery(\n  $taskTypeSearchQuery: TaskSearchQueryType\n) {\n  viewer {\n    component(name: \"TimesheetsTaskCountQuery\")\n    company {\n      allTasksCount(searchQuery: $taskTypeSearchQuery, approvedOnly: true, runningProjectOnly: true)\n      id\n    }\n    id\n  }\n}\n"
        }
    };
})();
node.hash = '7839fadf2efc00f8296613998d08306a';
export default node;
