import React from 'react';
import {Column, GroupingRowWrapper, ExpansionToggle} from './Elements.styled';
import {keyBy} from 'lodash';
import {getColumnWidth} from './utils';
import {RoleNameColumn} from './grouping-row-elements';
import {useIntl} from 'react-intl';
import Util from '../../../../util/util';
import {NumericTileV2, UNIT} from './task-row-elements/NumericTile';

export const PhaseRoleGroupingRow = ({node, onClick = () => null}) => {
	const intl = useIntl();
	const {expanded, sharedOptions, role, children, rollUpValues, phaseId, preventExpansion, isForBaseline} = node;
	const {
		rollupProgress,
		rollupEstimates,
		rollupRemaining,
		rollupDifferenceFromEstimate,
		rollupPlannedBillableTime,
		rollupActualBillableTimeDifference,
		minutesAvailable,
		minutesRemaining,
	} = rollUpValues;
	const {currencySymbol, isUsingProjectAllocation, company} = sharedOptions;
	const columnsObject = keyBy(sharedOptions.availableColumns, 'name');
	const displayAvailability = isUsingProjectAllocation || Util.isMixedAllocationModeEnabled(company);
	const displayUtilizationGauge = displayAvailability && sharedOptions.visibility.displayScopingAvailability;
	const noPhase = phaseId === 'no-phase';
	const noRole = role.id === 'no_role';
	const isSelectedRoleDisabled =
		!noRole && sharedOptions.roles ? !sharedOptions.roles.map(edge => edge.node.id).includes(role.id) : false;

	const taskAmount = children?.length || 0;

	const expansionOnClick = preventExpansion ? undefined : () => onClick;
	const displayTaskInformation = !isForBaseline || rollupProgress !== null;

	return (
		<GroupingRowWrapper onClick={onClick}>
			<ExpansionToggle
				preventExpansion={preventExpansion}
				expanded={preventExpansion ? false : expanded}
				onClick={expansionOnClick}
			></ExpansionToggle>
			<RoleNameColumn
				name={role.name}
				noRole={noRole}
				isSelectedRoleDisabled={isSelectedRoleDisabled}
				taskAmount={taskAmount}
				displayUtilizationGauge={displayUtilizationGauge && !noPhase && !noRole}
				availability={{minutesAvailable, minutesRemaining}}
				rollUpValues={rollUpValues}
			/>
			{getColumnWidth('project-id', columnsObject) && <Column width={getColumnWidth('project-id', columnsObject)} />}
			{getColumnWidth('project-name', columnsObject) && <Column width={getColumnWidth('project-name', columnsObject)} />}
			{displayAvailability && !noPhase && getColumnWidth('total_availability', columnsObject) && (
				<Column width={getColumnWidth('total_availability', columnsObject)} align={'right'}>
					{Util.convertMinutesToFullHour(minutesAvailable, intl)}
				</Column>
			)}
			{displayAvailability && !noPhase && getColumnWidth('remaining_availability', columnsObject) && (
				<Column width={getColumnWidth('remaining_availability', columnsObject)} align={'right'}>
					<NumericTileV2
						id={node.id}
						key={'over-forecast'}
						value={minutesRemaining}
						unit={UNIT.HOURS}
						cy={'over-forecast-tile'}
						highlightNegative={true}
					/>
				</Column>
			)}
			{getColumnWidth('date', columnsObject) && <Column width={getColumnWidth('date', columnsObject)} />}
			{getColumnWidth('assigned-role', columnsObject) && (
				<Column width={getColumnWidth('assigned-role', columnsObject)} />
			)}
			{getColumnWidth('assigned-person', columnsObject) && (
				<Column width={getColumnWidth('assigned-person', columnsObject)} />
			)}
			{getColumnWidth('status', columnsObject) && <Column width={getColumnWidth('status', columnsObject)} />}
			{getColumnWidth('phase', columnsObject) && <Column width={getColumnWidth('phase', columnsObject)} />}
			{getColumnWidth('sprint', columnsObject) && <Column width={getColumnWidth('sprint', columnsObject)} />}
			{getColumnWidth('done-percentage', columnsObject) && (
				<Column align={'right'} width={getColumnWidth('done-percentage', columnsObject)}>
					{displayAvailability && taskAmount > 0 && `${Math.round(rollupProgress)}%`}
				</Column>
			)}
			{getColumnWidth('period-target', columnsObject) && (
				<Column width={getColumnWidth('period-target', columnsObject)} />
			)}
			{getColumnWidth('forecast', columnsObject) && (
				<Column align={'right'} width={getColumnWidth('forecast', columnsObject)}>
					{displayAvailability && displayTaskInformation && Util.convertMinutesToFullHour(rollupEstimates, intl)}
				</Column>
			)}
			{getColumnWidth('time-entries', columnsObject) && <Column width={getColumnWidth('time-entries', columnsObject)} />}
			{getColumnWidth('billable-time-entries', columnsObject) && (
				<Column width={getColumnWidth('billable-time-entries', columnsObject)} />
			)}
			{getColumnWidth('non-billable-time-entries', columnsObject) && (
				<Column width={getColumnWidth('non-billable-time-entries', columnsObject)} />
			)}
			{getColumnWidth('remaining', columnsObject) && (
				<Column align={'right'} width={getColumnWidth('remaining', columnsObject)}>
					{displayAvailability && displayTaskInformation && Util.convertMinutesToFullHour(rollupRemaining, intl)}
				</Column>
			)}
			{getColumnWidth('over-forecast', columnsObject) && (
				<Column align={'right'} width={getColumnWidth('over-forecast', columnsObject)}>
					{displayAvailability &&
						displayTaskInformation &&
						Util.convertMinutesToFullHour(rollupDifferenceFromEstimate, intl)}
				</Column>
			)}
			{getColumnWidth('price', columnsObject) && (
				<Column align={'right'} width={getColumnWidth('price', columnsObject)}>
					{displayAvailability &&
						displayTaskInformation &&
						Util.getFormattedNumberWithCurrency(currencySymbol, rollupPlannedBillableTime, intl)}
				</Column>
			)}
			{getColumnWidth('actual-price', columnsObject) && (
				<Column align={'right'} width={getColumnWidth('actual-price', columnsObject)}>
					{displayAvailability &&
						displayTaskInformation &&
						Util.getFormattedNumberWithCurrency(currencySymbol, rollupActualBillableTimeDifference, intl)}
				</Column>
			)}
			{getColumnWidth('planned-cost', columnsObject) && <Column width={getColumnWidth('planned-cost', columnsObject)} />}
			{getColumnWidth('actual-cost', columnsObject) && <Column width={getColumnWidth('actual-cost', columnsObject)} />}

			{getColumnWidth('labels', columnsObject) && <Column width={getColumnWidth('labels', columnsObject)} />}
			{getColumnWidth('approved', columnsObject) && <Column width={getColumnWidth('approved', columnsObject)} />}
			{getColumnWidth('starred', columnsObject) && <Column width={getColumnWidth('starred', columnsObject)} />}
			{getColumnWidth('context-menu', columnsObject) && <Column width={getColumnWidth('context-menu', columnsObject)} />}
		</GroupingRowWrapper>
	);
};
