/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
query CreateReportModalQuery {
  viewer {
    actualPersonId
    component(name: "create_report_modal")
    ...CreateReportModal_viewer
    id
  }
}

fragment CreateReportModal_viewer on Viewer {
  company {
    isUsingProjectAllocation
    isUsingSchedulingPlanMode
    isUsingMixedAllocation
    id
  }
}
*/
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actualPersonId",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": [
            {
                "kind": "Literal",
                "name": "name",
                "value": "create_report_modal"
            }
        ],
        "kind": "ScalarField",
        "name": "component",
        "storageKey": "component(name:\"create_report_modal\")"
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "CreateReportModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        (v1 /*: any*/),
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "CreateReportModal_viewer"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "CreateReportModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        (v1 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Company",
                            "kind": "LinkedField",
                            "name": "company",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "isUsingProjectAllocation",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "isUsingSchedulingPlanMode",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "isUsingMixedAllocation",
                                    "storageKey": null
                                },
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "CreateReportModalQuery",
            "operationKind": "query",
            "text": "query CreateReportModalQuery {\n  viewer {\n    actualPersonId\n    component(name: \"create_report_modal\")\n    ...CreateReportModal_viewer\n    id\n  }\n}\n\nfragment CreateReportModal_viewer on Viewer {\n  company {\n    isUsingProjectAllocation\n    isUsingSchedulingPlanMode\n    isUsingMixedAllocation\n    id\n  }\n}\n"
        }
    };
})();
node.hash = '2658d855de922529411bfa7619ae0473';
export default node;
