import React from 'react';
import PropTypes from 'prop-types';
import {StyledTime, StyledCheckmark} from './checkmark_time_styled';
import Util from '../../../../shared/util/util';
import {injectIntl} from 'react-intl';

export const CheckmarkAndTime = ({time, hoursToWork, intl, overflow, dataCy, locked}) => {
	const timeInHours = intl ? Util.convertMinutesToFullHour(time, intl, true) : time;
	const color = locked ? '#a1a1a1' : '#0C6';

	return (
		<StyledTime showOverflow={overflow}>
			<StyledCheckmark>
				{time >= hoursToWork && hoursToWork > 0 ? (
					<svg xmlns="http://www.w3.org/2000/svg" width="17" height="13" viewBox="0 0 17 13">
						<path
							fill={color}
							fillRule="evenodd"
							d="M5.659 12.314L.527 7.32a1.733 1.733 0 0 1 0-2.484 1.83 1.83 0 0 1 2.552 0l3.16 3.076 7.58-7.37A1.922 1.922 0 0 1 16.432.48a1.672 1.672 0 0 1 .071 2.438l-9.442 9.388a1 1 0 0 1-1.402.008z"
						/>
					</svg>
				) : null}
			</StyledCheckmark>
			<p data-cy={dataCy}>{timeInHours}</p>
		</StyledTime>
	);
};

CheckmarkAndTime.propTypes = {
	time: PropTypes.number.isRequired,
	hoursToWork: PropTypes.number.isRequired,
	intl: PropTypes.any,
	overflow: PropTypes.bool,
	dataCy: PropTypes.string,
};

CheckmarkAndTime.defaultProps = {
	showOverflow: false,
	dataCy: '',
};

export default injectIntl(CheckmarkAndTime);
