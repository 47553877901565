import React, {Component} from 'react';
import {FormattedDate, injectIntl} from 'react-intl';
import Moment from 'moment';
import ActivityLogRenderer from './activity_log_renderer';
import Util from '../../../../util/util';
import UpdateTaskMutation from '../../../../../../mutations/update_task_mutation.modern';
import AssignedDropdown from '../../../dropdowns/assigned-dropdown/assigned_dropdown';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../../../../constants';
import Warning from '../../../../../../components/warning';
import {MODAL_TYPE, showModal} from '../../../modals/generic_modal_conductor';
import {EVENT_ID, subscribe, unsubscribe} from '../../../../../../containers/event_manager';
import {hasPermission} from '../../../../util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../../../Permissions';

class FooterSection extends Component {
	constructor(props) {
		super(props);
		this.changesSavedTimeout = null;
		this.hasActivityLogAccess =
			!hasPermission(PERMISSION_TYPE.CLIENT_HIDE_TIME_ENTRIES) && !hasPermission(PERMISSION_TYPE.CLIENT_HIDE_ESTIMATES);
		this.state = {
			renderActivityLog: false,
			showSavedText: false,
		};
		this.showSaved = this.showSaved.bind(this);
	}

	componentDidMount() {
		subscribe(EVENT_ID.SHOW_CHANGES_SAVED, this.showSaved);
	}

	componentWillUnmount() {
		if (this.changesSavedTimeout !== null) {
			clearTimeout(this.changesSavedTimeout);
			this.changesSavedTimeout = null;
		}
		unsubscribe(EVENT_ID.SHOW_CHANGES_SAVED, this.showSaved);
	}

	showSaved() {
		clearTimeout(this.changesSavedTimeout);
		this.changesSavedTimeout = null;

		this.setState({showSavedText: true});
		this.changesSavedTimeout = setTimeout(() => {
			this.setState({showSavedText: false});
		}, 5000);
	}

	toggleActivities() {
		this.setState({renderActivityLog: !this.state.renderActivityLog});
		this.props.toggleActivities();
	}

	handleKeyPress(e) {
		if (e.key === 'Enter') {
			this.toggleActivities();
		}
	}

	showSetOwnerPrompt(personId) {
		const {formatMessage} = this.props.intl;
		const callbackPositive = params => {
			this.setOwner(true, personId);
			this.props.closeModal();
		};

		showModal({
			type: MODAL_TYPE.GENERIC,
			content: (
				<div>
					<Warning messageId={'task_modal.owner_client_warning'} />
				</div>
			),
			className: 'default-warning-modal',
			cy: 'task-modal-owner-warning-modal',
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.confirm'}),
					callback: callbackPositive,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
				},
			],
		});
	}

	setOwner(deleteNode, personId) {
		const onSuccess = response => {
			Util.dispatchScheduleEvent(response);
		};
		Util.CommitMutation(
			UpdateTaskMutation,
			{
				ids: [this.props.task.id],
				ownerId: Array.isArray(personId) ? personId[0] : personId,
				projectId: this.props.project.id,
				deleteNode,
			},
			onSuccess
		);
	}

	showSetFollowersPrompt(personId) {
		const {formatMessage} = this.props.intl;
		const callbackPositive = params => {
			this.unassignFollower(true, personId);
			this.props.closeModal();
		};

		showModal({
			type: MODAL_TYPE.GENERIC,
			content: (
				<div>
					<Warning messageId={'task_modal.follower_client_warning'} />
				</div>
			),
			className: 'default-warning-modal',
			cy: 'task-modal-follower-warning-modal',
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.confirm'}),
					callback: callbackPositive,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
				},
			],
		});
	}

	assignFollower(personIds) {
		if (personIds) {
			Util.CommitSchedulingModalUpdate(UpdateTaskMutation, {
				ids: [this.props.task.id],
				followers: personIds,
			});
		}
	}

	unassignFollower(deleteNode, personId) {
		Util.CommitSchedulingModalUpdate(UpdateTaskMutation, {
			ids: [this.props.task.id],
			followers: this.props.task.followers.filter(follower => follower.id !== personId).map(follower => follower.id),
			deleteNode,
			projectId: this.props.project.id,
		});
	}

	render() {
		const {formatMessage} = this.props.intl;
		const {renderActivityLog} = this.state;
		const {viewer, task, project, footerBottomPosition, isTaskReadOnly, isClientUser} = this.props;
		const {useTaskOwner, useTaskFollowers} = this.props.project;
		const {createdAt, createdBy, owner, followers} = task;
		const createdByName = createdBy ? `${createdBy.firstName}${createdBy.lastName ? ` ${createdBy.lastName}` : ''}` : '';
		const isOwnerAssigned = owner ? task.assignedPersons.some(person => person.id === owner.id) : false;
		const isVieweFollowerAndAssigned = followers
			? task.assignedPersons.some(person =>
					followers.some(follower => follower.id === person.id && follower.id === viewer.actualPersonId)
			  )
			: false;

		return (
			<div>
				<div className="task-modal-v3-footer-section" data-cy="task-modal-footer-section">
					<div className="task-info">
						<div className="update-info">
							<span>
								{this.state.showSavedText ? (
									formatMessage({id: 'common.changes_saved'}) + ' ' + Moment().format('LLL')
								) : createdByName ? (
									<React.Fragment>
										<span className="update-name">
											{' '}
											{`${formatMessage({id: 'common.created_by_x'}, {name: createdByName})}`}
										</span>
										<p style={{padding: 0, margin: 0}}>
											<FormattedDate
												value={Moment.utc(createdAt).toDate()}
												year="numeric"
												month="short"
												day="numeric"
											/>
										</p>
									</React.Fragment>
								) : (
									''
								)}
							</span>
						</div>

						{useTaskOwner ? (
							<AssignedDropdown
								assignablePersons={project.projectPersons.edges}
								assignedPersons={task.owner ? [task.owner] : []}
								assignPerson={
									owner && owner.id === viewer.actualPersonId && isClientUser && !isOwnerAssigned
										? this.showSetOwnerPrompt.bind(this)
										: this.setOwner.bind(this, false)
								}
								unassignPerson={
									owner && owner.id === viewer.actualPersonId && isClientUser && !isOwnerAssigned
										? this.showSetOwnerPrompt.bind(this)
										: this.setOwner.bind(this, false, null)
								}
								isMultiSelect={false}
								label={formatMessage({id: 'common.owner'})}
								cy="task-modal-owner-dropdown"
								isClientUser={isClientUser}
								closeOnUnassign={isClientUser}
								viewerId={viewer.actualPersonId}
								disabled={isTaskReadOnly}
								viewer={viewer}
								task={task}
								useSmallerStyling={true}
								withAddIcon={true}
							/>
						) : useTaskFollowers ? (
							<AssignedDropdown
								assignablePersons={project.projectPersons.edges}
								assignedPersons={followers}
								assignPerson={this.assignFollower.bind(this)}
								unassignPerson={
									isClientUser && !isVieweFollowerAndAssigned
										? this.showSetFollowersPrompt.bind(this)
										: this.unassignFollower.bind(this, !isVieweFollowerAndAssigned && isClientUser)
								}
								isMultiSelect={true}
								label={formatMessage({id: 'common.followers'})}
								maxPeopleIconsShown={5}
								cy="task-modal-followers-dropdown"
								isClientUser={isClientUser}
								closeOnUnassign={isClientUser}
								viewerId={viewer.actualPersonId}
								disabled={isTaskReadOnly}
								viewer={viewer}
								task={task}
								useSmallerStyling={true}
								withAddIcon={true}
							/>
						) : null}

						<div>
							{this.hasActivityLogAccess && (
								<div
									className="activity-log-button child"
									data-cy="activity-log-button"
									data-userpilot={'task-modal-activity-log-button'}
									tabIndex={0}
									onClick={this.toggleActivities.bind(this)}
									onKeyPress={this.handleKeyPress.bind(this)}
								>
									<div className="activity-log-button-text">
										{formatMessage({id: 'task_modal.activity-log'})}
									</div>
									<div className={'arrow' + (renderActivityLog ? ' expanded' : '')} />
								</div>
							)}
						</div>
					</div>
				</div>
				{renderActivityLog ? (
					<ActivityLogRenderer
						taskId={task.id}
						footerBottomPosition={footerBottomPosition}
						taskLevels={project.taskLevels}
					/>
				) : null}
			</div>
		);
	}
}

export default injectIntl(FooterSection);
