import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {BUTTON_STYLE, BUTTON_COLOR} from '../../../../../constants';
import TooltipContainer from '../../tooltips/tooltip_container';
import GenericTooltip from '../../tooltips/generic_tooltip';
import FinancialLockIcon from '../../../../../components/new-ui/FinancialLockIcon';

class Button extends Component {
	focus() {
		this.buttonRef.focus();
	}

	handleClickWithFocus() {
		this.focus();
		this.props.onClick();
	}

	handleMouseDown = e => {
		if (this.props.focusOnMouseDown) this.buttonRef.focus();
	};

	render() {
		const symbol = this.props.symbolClass ? (
			<div
				className={
					'symbol ' +
					this.props.symbolClass +
					(this.props.text ? '' : ' no-text') +
					(this.props.placeSymbolLast ? ' symbol-last' : ' symbol-first')
				}
				style={this.props.symbolSize ? {width: this.props.symbolSize, height: this.props.symbolSize} : undefined}
			/>
		) : null;

		return (
			<TooltipContainer {...this.props.tooltipProps}>
				<button
					id={this.props.id}
					ref={button => (this.buttonRef = button)}
					data-userpilot={this.props.userpilot}
					data-cy={this.props.cy}
					title={this.props.buttonTitle ? this.props.buttonTitle : ''}
					className={
						'custom-button-container ' +
						(this.props.isDisabled ? ' disabled ' : '') +
						this.props.buttonStyle +
						' ' +
						(this.props.autoScheduleStyle ? ' ai-blue' : this.props.colorTheme) +
						(this.props.onHoverFilled ? ' on-hover-filled' : '') +
						(this.props.focusStyle ? ' focus-style' : '') +
						(this.props.className ? ' ' + this.props.className : '') +
						(this.props.tooltipLabel ? ' has-tooltip-label' : '') +
						(this.props.roundedBorders ? ' rounded-borders' : '')
					}
					onClick={this.props.focusOnClick ? this.handleClickWithFocus.bind(this) : this.props.onClick}
					onBlur={this.props.onBlur}
					onKeyDown={e => {
						if (this.props.preventDefault) {
							e.preventDefault();
						}
						if (this.props.onKeydown) {
							this.props.onKeyDown(e);
						}
					}}
					onKeyUp={e => {
						if (this.props.preventDefault) {
							e.preventDefault();
						}
					}}
					onMouseDown={this.handleMouseDown.bind(this)}
					disabled={this.props.isDisabled}
					style={this.props.isDisabled ? {pointerEvents: 'none'} : {}} // Needed to allow onMouseLeave on disabled buttons
				>
					{this.props.tooltipLabel ? (
						<GenericTooltip type={this.props.tooltipLabel} small={this.props.smallTooltipLabel} />
					) : null}
					<div className="input-container">
						{this.props.placeSymbolLast ? '' : symbol}
						{this.props.text ? (
							<span
								className={
									'text ' +
									(this.props.isDisabled ? ' disabled' : '') +
									(this.props.uppercase ? ' uppercase-text' : ' lowercase-text') +
									(this.props.normalFontWeight ? ' normal-font-weight' : '')
								}
								disabled={this.props.isDisabled}
							>
								{this.props.text}
							</span>
						) : null}

						{this.props.placeSymbolLast ? symbol : ''}
						{this.props.locked ? <FinancialLockIcon fill={'#000000'} /> : null}
					</div>
				</button>
			</TooltipContainer>
		);
	}
}

Button.propTypes = {
	className: PropTypes.string,
	id: PropTypes.string,
	isDisabled: PropTypes.bool,
	onClick: PropTypes.func,
	onBlur: PropTypes.func,
	buttonStyle: PropTypes.oneOf([BUTTON_STYLE.FILLED, BUTTON_STYLE.OUTLINE, BUTTON_STYLE.OUTLINE_THICK]).isRequired,
	uppercase: PropTypes.bool,
	roundedBorders: PropTypes.bool,
	normalFontWeight: PropTypes.bool,
	colorTheme: PropTypes.oneOf([
		BUTTON_COLOR.GREEN,
		BUTTON_COLOR.GREY,
		BUTTON_COLOR.VERYLIGHTGREY,
		BUTTON_COLOR.LIGHTGREY,
		BUTTON_COLOR.PURPLE,
		BUTTON_COLOR.FADEDGREY,
		BUTTON_COLOR.WHITE,
		BUTTON_COLOR.WHITE_ACTIVE,
		BUTTON_COLOR.RED,
		BUTTON_COLOR.MEDIUMGREY,
		BUTTON_COLOR.AI_BLUE,
		BUTTON_COLOR.TRANSPARENT,
		BUTTON_COLOR.PINK,
		BUTTON_COLOR.LIGHT,
		BUTTON_COLOR.GREEN_APPROVE,
		BUTTON_COLOR.MODERN_BLUE,
	]).isRequired,
	symbolClass: PropTypes.string,
	placeSymbolLast: PropTypes.bool,
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	onHoverFilled: PropTypes.bool,
	symbolSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	cy: PropTypes.string,
	autoScheduleStyle: PropTypes.bool,
};

Button.defaultProps = {
	placeSymbolLast: false,
	uppercase: true,
	roundedBorders: false,
	normalFontWeight: false,
};

export default Button;
