import React from 'react';

const bugIcon = props => {

	const fill = props.fill ?
		props.fill :
		props.active ?
			(props.onClick ? '#6e0fea' : '#535353') :
			'#cecece';

	return (
		<span tabIndex='0' onKeyDown={props.onKeyDown} title={props.title} data-cy={props.cy}>
			<svg onClick={props.onClick ? props.onClick : null}
				 className={'bug-icon ' + (props.className ? props.className : '')} xmlns='http://www.w3.org/2000/svg'
				 width='16' height='16' viewBox='0 0 16 16'>
				<path
					data-cy={props.cy ? props.cy + '-path' : ''}
					fill={fill}
					fillRule='evenodd'
					d='M8 12.8c-2.22 0-4-1.88-4-4 0-2.206 1.794-4 4-4s4 1.794 4 4c0 2.122-1.78 4-4 4zM7.2 2.4c0-.44.36-.8.8-.8.44 0 .8.36.8.8 0 .44-.36.8-.8.8-.44 0-.8-.36-.8-.8zm8.8 4h-2.948a5.927 5.927 0 0 0-.21-.4L15.2 4.428V1.6h-1.6v1.972l-1.754 1.169a5.599 5.599 0 0 0-1.78-1.141c.207-.354.334-.76.334-1.2C10.4 1.077 9.323 0 8 0a2.403 2.403 0 0 0-2.4 2.4c0 .44.127.846.334 1.2a5.599 5.599 0 0 0-1.78 1.14L2.4 3.573V1.6H.8v2.828L3.158 6c-.076.13-.144.264-.21.4H0V8h2.464a5.62 5.62 0 0 0-.064.8c0 .272.026.538.064.8H0v1.6h2.948c.077.16.16.317.252.469l-1.6 1.6V16h1.6v-2.069l1.016-1.015a5.57 5.57 0 0 0 7.568 0l1.016 1.015V16h1.6v-2.731l-1.6-1.6c.091-.152.175-.308.252-.469H16V9.6h-2.464c.038-.262.064-.528.064-.8a5.62 5.62 0 0 0-.064-.8H16V6.4z'
				/>
			</svg>
		</span>
	);
};

export default bugIcon;
