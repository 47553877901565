/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type PersonDropdown_persons$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ToggleIsSSOMandatory_company$ref: FragmentReference;
declare export opaque type ToggleIsSSOMandatory_company$fragmentType: ToggleIsSSOMandatory_company$ref;
export type ToggleIsSSOMandatory_company = {|
  +oktaEnabled: ?boolean,
  +oneloginEnabled: ?boolean,
  +AADEnabled: ?boolean,
  +googleSSOEnabled: ?boolean,
  +isSSOMandatory: ?boolean,
  +allPersons: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +fullName: ?string,
        +firstName: ?string,
        +lastName: ?string,
        +email: ?string,
        +profilePictureId: ?string,
        +profilePictureDefaultId: ?number,
        +active: ?boolean,
        +hasSSOExemption: ?boolean,
      |},
      +$fragmentRefs: PersonDropdown_persons$ref,
    |}>
  |},
  +$refType: ToggleIsSSOMandatory_company$ref,
|};
export type ToggleIsSSOMandatory_company$data = ToggleIsSSOMandatory_company;
export type ToggleIsSSOMandatory_company$key = {
  +$data?: ToggleIsSSOMandatory_company$data,
  +$fragmentRefs: ToggleIsSSOMandatory_company$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ToggleIsSSOMandatory_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "oktaEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "oneloginEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "AADEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "googleSSOEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSSOMandatory",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "excludeClientUsers",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "first",
          "value": 10000
        },
        {
          "kind": "Literal",
          "name": "onlyActive",
          "value": true
        }
      ],
      "concreteType": "PersonTypeConnection",
      "kind": "LinkedField",
      "name": "allPersons",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PersonTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Person",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fullName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "firstName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "email",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "profilePictureId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "profilePictureDefaultId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "active",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasSSOExemption",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PersonDropdown_persons"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "allPersons(excludeClientUsers:true,first:10000,onlyActive:true)"
    }
  ],
  "type": "Company"
};
// prettier-ignore
(node/*: any*/).hash = '520b785b6f0f1c2c6febdd8a3785b372';

module.exports = node;
