import 'url-search-params-polyfill';
export const getRedirectLink = (queryString) => {
    const urlParams = new URLSearchParams(queryString);
    return urlParams.has('retURL') ? urlParams.get('retURL') : '/';
};
const splitUrl = (url) => {
    const splitPath = url.split('?');
    const pathname = splitPath[0];
    const search = splitPath[1];
    return { pathname, search };
};
export const getPathNameAndSearch = () => {
    // Get redirect link
    let pathname = getRedirectLink(window.location.search);
    let search = null;
    if (pathname === null || pathname === void 0 ? void 0 : pathname.includes('?')) {
        const urlComponents = splitUrl(pathname);
        pathname = urlComponents.pathname;
        search = urlComponents.search;
    }
    return { pathname, search };
};
const sageThemeUrlMatchers = ['/sage_signup/', '://sage.forecast.', '://sage.wolwa.'];
export const isSageSpecificUrl = () => {
    return sageThemeUrlMatchers.some(matcher => window.location.href.includes(matcher));
};
// Returns the first occurrence of Tx that is not immediately preceded by "project/" or "program/"
const taskRegexString = '\\/(?<!(project\\/|program\\/))T\\d+';
const taskRegex = new RegExp(taskRegexString);
export const getIndexOfTaskLink = (pathname) => {
    return pathname.search(taskRegex);
};
export const pathIncludesTask = (path) => {
    return getIndexOfTaskLink(path) > -1;
};
export const getTaskUrl = (path) => {
    const matches = path.match(taskRegex);
    if (matches) {
        return matches[0];
    }
    return '';
};
const taskRegexReplace = new RegExp(`(.*)(${taskRegexString})(.*)`);
export const removeTaskLinkFromUrl = (path) => {
    return path.replace(taskRegexReplace, '$1$4');
};
export const getTaskPathFromUrlIfExists = () => {
    return getTaskUrl(window.location.pathname);
};
export const navigateUnauthorizedCheckTaskLink = (history) => {
    const path = window.location.pathname;
    if (pathIncludesTask(path)) {
        const cardPath = path.substring(getIndexOfTaskLink(path), path.length);
        history.push(cardPath);
    }
    else {
        history.push('/not-authorized');
    }
};
