import Styled from 'styled-components';

export const Field = Styled.div``;

export const ModalBody = Styled.div`
	${Field} {
	    margin-top: 10px;
	    + ${Field} {
	        margin-top: 16px;
	    }
        label {
            font-weight: 400;
        }
	}
`;
