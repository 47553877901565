import React from 'react';
import {uniqueId} from 'lodash';
import {Table} from 'web-components';
import GroupColumns from './rows/GroupColumns';

export const getBaseLevelTableHeaderTotals = (tableHeader, intl, currency, customFieldColumnCount) => {
	const columns = GroupColumns(
		{
			name: intl.formatMessage({id: 'common.totals'}),
			...tableHeader.totals,
			currency,
		},
		intl,
		customFieldColumnCount
	);

	return (
		<Table.Rows data={{rows: [columns.length]}} canExpand={false} hasHeader>
			{({tableColumnsProps}) => {
				return (
					<Table.Row {...tableColumnsProps} key={uniqueId('key_')}>
						{columns}
					</Table.Row>
				);
			}}
		</Table.Rows>
	);
};
