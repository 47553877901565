import React from 'react';
import { useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import Util from '../../../util/util';
import NestedDropdown from '../../dropdowns/nested_dropdown';
import { useProjectDropdownController } from './useProjectDropdownController';
const ProjectDropdown = ({ viewer, project: selectedProject, onChange, cy }) => {
    const intl = useIntl();
    const hasOpportunityAccess = Util.hasOpportunityAccess();
    const projectDropdownData = useProjectDropdownController(viewer, hasOpportunityAccess);
    return (React.createElement(NestedDropdown, { placeholder: intl.formatMessage({ id: 'task_modal.move_to_new_project' }), label: projectDropdownData.selectedProjectLabel, options: projectDropdownData.projectOptions, value: selectedProject.name, projectIdLabel: {
            companyProjectId: selectedProject.companyProjectId,
            customProjectId: selectedProject.customProjectId,
            name: selectedProject.name,
            projectColor: selectedProject.projectColor,
            isGroupProject: selectedProject.isProjectGroup,
        }, onChange: onChange, customClass: "project-dropdown", showArrowWhenCollapsed: true, disablePreselectedOptionRemove: true, cy: cy, userpilot: 'project-dropdown', nativeScrollbar: true }));
};
const projectDropdownQuery = graphql `
	query ProjectDropdown_Query($id: String) {
		viewer {
			actualPersonId
			component(name: "project_dropdown")
			...ProjectDropdown_viewer @arguments(id: $id)
		}
	}
`;
export { projectDropdownQuery };
export default createFragmentContainer(ProjectDropdown, {
    viewer: graphql `
		fragment ProjectDropdown_viewer on Viewer @argumentDefinitions(id: {type: "String"}) {
			projects(first: 10000, excludeGroupProjects: true, excludeRestricted: true) {
				edges {
					node {
						id
						projectColor
						status
						companyProjectId
						customProjectId
						projectGroupId
						name
					}
				}
			}
			projectGroups(first: 10000) @connection(key: "Viewer_projectGroups", filters: []) {
				edges {
					node {
						id
						companyProjectGroupId
						name
						color
						projects(first: 10000) {
							edges {
								node {
									id
									projectColor
									status
									companyProjectId
									customProjectId
									projectGroupId
									name
								}
							}
						}
					}
				}
			}
		}
	`,
});
