import Moment from 'moment';
import {getFilterFunctions} from '../../../shared/components/filters/filter_logic';
import {PERMISSION_TYPE} from '../../../../Permissions';
import {hasSomePermission} from '../../../shared/util/PermissionsUtil';
import Util from '../../../shared/util/util';
import {hasFeatureFlag} from '../../../shared/util/FeatureUtil';
import {HIDDEN_FEATURES, MAX_BULK_SELECTION} from '../../../../constants';
import {GROUP_TYPE} from '../../../shared/components/task-tables/task-table-v3/TaskTable';
import {groupBy} from 'lodash';
import ProjectUtil from '../../../shared/util/project_util';
import UpdateTaskMutation from '../../../../mutations/update_task_mutation.modern';
import {ValueSource} from '../../../../containers/project/project_settings/financials/ValueCalculationsComponent';
import {isBillableSplitAllowed} from '../../../shared/util/cache/TimeRegistrationSettingsUtil';
import CompanySetupUtil from '../../../shared/util/CompanySetupUtil';

const SCOPING_GROUP_KEY = 'project_scoping_group_setting';

export const CUSTOM_FIELD_PREFIX = 'customField_';

export const getCustomFieldColumnName = (entityType, key) => CUSTOM_FIELD_PREFIX + entityType + '_' + key;

export const isEmpty = obj => {
	for (var key in obj) {
		if (obj.hasOwnProperty(key)) return false;
	}
	return true;
};

export const tabs = [
	{translationId: 'common.all_phases', value: 'all'},
	{translationId: 'common.active_phases', value: 'active'},
	{translationId: 'common.past_phases', value: 'done'},
];

export const getInitialSelectedTab = () => {
	const storedTab = localStorage.getItem('project-scoping-selected-tab');
	if (storedTab) {
		return tabs.find(tab => tab.value === storedTab);
	} else return tabs[0];
};

export const getInitialSelectedColumns = (formatMessage, viewer, showPeriods) => {
	const storedCols = JSON.parse(localStorage.getItem('project-new-scoping-selected-cols'));
	const hasFinancialAccess =
		CompanySetupUtil.hasFinance() &&
		hasSomePermission([PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION, PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE]);
	const sprintsEnabled = viewer.project && viewer.project.sprintTimeBox;
	const isEstimatedInNewPoints = viewer.project.estimationUnit === 'POINTS';
	const showCost = ProjectUtil.projectTracksCost();
	const showRevenue = ProjectUtil.projectTracksRevenue(viewer.project);
	const showTime = ProjectUtil.projectTracksTime(viewer.project) || viewer.project.manualProgressOnTasksEnabled;
	const showBillableTimeSplit = isBillableSplitAllowed();
	const availableCols = Util.getScopingColumns(
		formatMessage,
		hasFinancialAccess,
		sprintsEnabled,
		viewer,
		isEstimatedInNewPoints,
		showPeriods,
		showRevenue,
		showCost,
		showTime,
		showBillableTimeSplit
	);
	// Replace localStorage if the stored columns differ in length, or if their IDs are different
	const shouldReplace = (stored, avail) =>
		stored.length !== avail.length || stored.filter((c, i) => c.id !== avail[i].name).length > 0;

	if (storedCols) {
		for (let i = 0; i < availableCols.length; i++) {
			const col = availableCols[i];
			const storedCol = storedCols.find(storedCol => storedCol.id === col.name);
			if (!col.hide) {
				availableCols[i].checked = storedCol ? !!storedCol.checked : col.checked;
			}
		}
		if (shouldReplace(storedCols, availableCols)) {
			Util.localStorageSetItem(
				'project-scoping-selected-cols',
				JSON.stringify(
					availableCols.map(c => {
						return {
							id: c.name,
							checked: c.checked,
						};
					})
				)
			);
		}
	}

	//If no time registration is enabled, remove related columns
	if (ProjectUtil.projectUsesManualProgress(viewer.project) || Util.isFeatureHidden(HIDDEN_FEATURES.TIME_REGISTRATIONS)) {
		return Util.getNoTimeRegColumns(
			availableCols,
			viewer.project.manualProgressOnProjectEnabled ? 'done-percentage' : undefined
		);
	}

	return availableCols;
};

export const getOrderedPhaseIds = phases => {
	return Util.sortPhases(phases, false).map(phase => phase.id);
};

export const getExpandedPhasesMap = phases => {
	const map = new Map();
	phases.forEach(phase => {
		map.set(phase.id, true);
	});

	return map;
};

export const getExpandedTaskMap = tasks => {
	const map = new Map();
	tasks.forEach(task => {
		map.set(task.id, false);
	});

	return map;
};

export const getPhasesFilters = (projectId, preAppliedFilters) => {
	let filtersKey = 'project-scoping-filters-v4-';

	if (preAppliedFilters) {
		return getFilterFunctions(preAppliedFilters);
	} else if (localStorage.getItem(`${filtersKey}${projectId}`)) {
		const filters = JSON.parse(localStorage.getItem(`${filtersKey}${projectId}`));
		return getFilterFunctions(filters);
	} else {
		const filters = {
			person: {},
			task: {},
		};
		return getFilterFunctions(filters);
	}
};

export const compareSprintDates = (a, b) => {
	let aStartDate = Moment({
		y: a.node.startYear,
		M: a.node.startMonth - 1,
		d: a.node.startDay,
	});
	let aEndDate = Moment({
		M: a.node.endMonth - 1,
		y: a.node.endYear,
		d: a.node.endDay,
	});
	let bStartDate = Moment({
		y: b.node.startYear,
		M: b.node.startMonth - 1,
		d: b.node.startDay,
	});
	let bEndDate = Moment({
		y: b.node.endYear,
		M: b.node.endMonth - 1,
		d: b.node.endDay,
	});
	//Date used for sorting sprints with not selected dates
	const dummyDate = Moment({
		y: -10000,
		M: 1,
		d: 1,
	});

	if (!aStartDate.isValid()) aStartDate = dummyDate.clone();
	if (!aEndDate.isValid()) aEndDate = dummyDate.clone();
	if (!bStartDate.isValid()) bStartDate = dummyDate.clone();
	if (!bEndDate.isValid()) bEndDate = dummyDate.clone();

	if (aStartDate.isBefore(bStartDate)) return 1;
	if (bStartDate.isBefore(aStartDate)) return -1;
	//If same date, sort by end date ascending
	if (aEndDate.isBefore(bEndDate)) return 1;
	if (bEndDate.isBefore(aEndDate)) return -1;

	const aId = parseInt(atob(a.node.id).replace('Sprint:', ''), 10);
	const bId = parseInt(atob(b.node.id).replace('Sprint:', ''), 10);
	if (aId < bId) return 1;
	return -1;
};

export const getInitialGroupType = () => {
	const newGroupSetting = localStorage.getItem(SCOPING_GROUP_KEY);
	if (newGroupSetting && Object.values(GROUP_TYPE).some(type => type === newGroupSetting)) {
		return newGroupSetting;
	}

	if (JSON.parse(localStorage.getItem('project-scoping-group-by-person'))) {
		return GROUP_TYPE.PERSON;
	}
	if (JSON.parse(localStorage.getItem('project-scoping-group-by-role'))) {
		return GROUP_TYPE.ROLE;
	}

	return GROUP_TYPE.NO_GROUPING;
};

/**
 * Find all children and grandchildren of the selected task/tasks
 * @param tasksToAdd Lists of tasks to be selected
 * @param allTasks All tasks
 * @returns List of all the children and grandchildren of the tasksToAdd
 */
const _getSubtasksFromSelectedTasks = (tasksToAdd, allTasks) => {
	const subtaskMap = groupBy(allTasks, 'node.parentTaskId');
	let subtasksToSelect = [];
	let tasksToSelect = [];
	// Find children tasks
	tasksToAdd.forEach(t => {
		let tasks = subtaskMap[t.id];
		if (tasks) {
			tasks = tasks.map(task => {
				return task.node;
			});
			subtasksToSelect = subtasksToSelect.concat(tasks);
			tasksToSelect = tasksToSelect.concat(tasks);
		}
	});
	// Find grandchildren tasks
	subtasksToSelect.forEach(t => {
		let tasks = subtaskMap[t.id];
		if (tasks) {
			tasks = tasks.map(task => {
				return task.node;
			});
			tasksToSelect = tasksToSelect.concat(tasks);
		}
	});
	// Array of children and grandchildren to be selected
	return tasksToSelect;
};

export const updateSelectTasks = (
	viewer,
	intl,
	selectedTasks,
	taskToSelect,
	shiftKey,
	createToast,
	setSelectedTasks,
	groupSetting,
	treeData
) => {
	const isGrouped = groupSetting !== GROUP_TYPE.NO_GROUPING;
	// Deep copy to avoid messing with previously selected tasks
	let selected = [...selectedTasks];
	const alreadySelected = selected.find(elem => elem.id === taskToSelect.id);
	// Always prioritize unselect if already selected
	if (alreadySelected) {
		// Remove selected element
		selected.splice(
			selected.findIndex(elem => elem.id === taskToSelect.id),
			1
		);
	} else {
		// Do multi select on shift, if a previous task (starting point) was selected
		if (shiftKey && selectedTasks.length > 0) {
			const flatten = array =>
				array.flatMap(({task, children}) => [task, ...flatten(children || [])]).filter(node => node);
			const flattenTask = flatten(treeData);

			let tasksToAdd = [];
			const start = selected[selected.length - 1];
			const end = taskToSelect;
			if (start && end) {
				const startIndex = flattenTask.findIndex(task => task.id === start.id) || 0;
				const endIndex = flattenTask.findIndex(task => task.id === end.id);
				if (startIndex < endIndex) {
					// +1 to start to avoid adding the already added task
					tasksToAdd = flattenTask.slice(startIndex, endIndex + 1);
				} else {
					// Slice always goes from 0..n, so flip direction of going from end to start
					tasksToAdd = flattenTask.slice(endIndex, startIndex + 1);
				}
				// Avoid adding elements that were previously added
				tasksToAdd = tasksToAdd.filter(task => !selected.find(t => t.id === task.id));
				selected = selected.concat(tasksToAdd);
				// If there is no grouping then select the children and grandchildren of the selected tasks
				if (!isGrouped)
					selected = selected.concat(_getSubtasksFromSelectedTasks(tasksToAdd, viewer.project.tasks.edges));
			}
		}
		// Else add as normally
		else {
			selected.push(taskToSelect);
			// If there is no grouping then select the children and grandchildren of the selected tasks
			if (!isGrouped)
				selected = selected.concat(_getSubtasksFromSelectedTasks([taskToSelect], viewer.project.tasks.edges));
		}
	}
	if (selected.length > MAX_BULK_SELECTION) {
		createToast({
			duration: 5000,
			message: intl.formatMessage({id: 'common.max_bulk_selected'}, {tasks: MAX_BULK_SELECTION}),
		});
	}
	// remove duplicates
	selected = selected.filter((v, i, a) => a.findIndex(t => t.id === v.id) === i);
	setSelectedTasks(selected.length > MAX_BULK_SELECTION ? selected.slice(0, MAX_BULK_SELECTION) : selected);
};

export const updateTaskForScoping = (taskId, onSuccess) => {
	if (
		hasFeatureFlag('use_financial_service_in_scoping') &&
		!hasFeatureFlag('financial_socket_push') &&
		Util.isScoping() &&
		taskId
	) {
		// updating the task to recalculate the task financials - only when registering time on scoping to prevent overhead anywhere else
		Util.CommitMutation(UpdateTaskMutation, {ids: [taskId]}, onSuccess);
	} else if (onSuccess) {
		onSuccess();
	}
};

export const getHiddenFinancialFields = (financialSourceSettings, intl) => {
	const hiddenFinancialFields = [];

	if (!financialSourceSettings) return hiddenFinancialFields;

	if (financialSourceSettings.plannedRevenue !== ValueSource.TASK) {
		hiddenFinancialFields.push(intl.formatMessage({id: 'project_budget.planned_billable_time'}));
	}
	if (financialSourceSettings.plannedCost !== ValueSource.TASK) {
		hiddenFinancialFields.push(intl.formatMessage({id: 'project_budget.planned_cost'}));
	}
	if (financialSourceSettings.actualRevenue !== ValueSource.TASK) {
		hiddenFinancialFields.push(intl.formatMessage({id: 'project_budget.actual_billable_time_short'}));
	}
	if (financialSourceSettings.actualCost !== ValueSource.TASK) {
		hiddenFinancialFields.push(intl.formatMessage({id: 'project_budget.actual_cost_to_date'}));
	}
	if (
		financialSourceSettings.actualRevenue !== ValueSource.TASK ||
		financialSourceSettings.forecastRevenue !== ValueSource.TASK
	) {
		hiddenFinancialFields.push(intl.formatMessage({id: 'project_budget.value_of_service_at_completion'}));
	}
	return hiddenFinancialFields;
};
