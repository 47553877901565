/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type CANT_DELETE_TASK_REASON = "BLOCKED_BY_SUBTASK" | "GITHUB" | "HAS_INVOICED_TIME" | "HAS_LOCKED_TIME" | "HAS_OTHER_PEOPLES_TIME_REGS" | "HAS_TIME_REGS" | "JIRA" | "NO_USER_TYPE" | "UNIT4" | "%future added value";
export type PROJECT_STATUS = "DONE" | "HALTED" | "OPPORTUNITY" | "PLANNING" | "RUNNING" | "%future added value";
export type STATUS_CATEGORY = "DONE" | "INPROGRESS" | "TODO" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type TimesheetTableWrapper_viewer$ref: FragmentReference;
declare export opaque type TimesheetTableWrapper_viewer$fragmentType: TimesheetTableWrapper_viewer$ref;
export type TimesheetTableWrapper_viewer = {|
  +id: string,
  +actualPersonId: ?string,
  +harvestUser: ?boolean,
  +unit4User: ?boolean,
  +company: ?{|
    +id: string,
    +bambooHREnabled: ?boolean,
    +harvestEnabled: ?boolean,
    +unit4Enabled: ?boolean,
    +useTimeOffApproval: ?boolean,
    +roles: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |}
      |}>
    |},
    +taskTypeQueryTasks?: ?{|
      +pageInfo: {|
        +endCursor: ?string,
        +hasNextPage: boolean,
      |},
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +userCanDeleteTask: ?boolean,
          +userCantDeleteTaskReason: ?CANT_DELETE_TASK_REASON,
          +name: ?string,
          +latestUiUpdateAt: ?string,
          +startDay: ?number,
          +startMonth: ?number,
          +startYear: ?number,
          +deadlineYear: ?number,
          +deadlineMonth: ?number,
          +deadlineDay: ?number,
          +timeLeft: ?number,
          +estimateForecastMinutes: ?number,
          +totalMinutesRegistered: ?number,
          +companyTaskId: ?number,
          +favoured: ?boolean,
          +highPriority: ?boolean,
          +bug: ?boolean,
          +blocked: ?boolean,
          +approved: ?boolean,
          +canStart: ?boolean,
          +canBeSetToDone: ?boolean,
          +billable: ?boolean,
          +fullAccessToProject: ?boolean,
          +parentTaskId: ?string,
          +statusColumnV2: ?{|
            +id: string,
            +name: ?string,
            +category: ?STATUS_CATEGORY,
          |},
          +assignedPersons: ?$ReadOnlyArray<?{|
            +id: string,
            +firstName: ?string,
            +lastName: ?string,
            +email: ?string,
            +profilePictureId: ?string,
            +profilePictureDefaultId: ?number,
            +permissions: ?$ReadOnlyArray<?string>,
          |}>,
          +project: ?{|
            +id: string,
            +name: ?string,
            +companyProjectId: ?number,
            +customProjectId: ?string,
            +minutesPerEstimationPoint: ?number,
            +estimationUnit: ?string,
            +projectColor: ?string,
            +projectStartYear: ?number,
            +projectStartMonth: ?number,
            +projectStartDay: ?number,
            +projectEndYear: ?number,
            +projectEndMonth: ?number,
            +projectEndDay: ?number,
            +manualProgressOnProjectEnabled: ?boolean,
            +manualProgressOnPhasesEnabled: ?boolean,
            +manualProgressOnTasksEnabled: ?boolean,
            +taskLevels: ?number,
            +status: ?PROJECT_STATUS,
            +fullAccessToProject: ?boolean,
            +projectPerson: ?{|
              +id: string,
              +role: ?{|
                +id: string,
                +name: ?string,
              |},
            |},
            +harvestProject: ?{|
              +id: string
            |},
            +unit4Project: ?{|
              +id: string,
              +activitiesEnabled: ?boolean,
            |},
            +client: ?{|
              +id: string,
              +name: ?string,
            |},
          |},
          +phase: ?{|
            +id: string,
            +name: ?string,
          |},
        |},
        +cursor: string,
      |}>,
    |},
    +taskTypeQueryIdleTimes?: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +isInternalTime: ?boolean,
          +favoured: ?boolean,
          +disabled: ?boolean,
        |}
      |}>
    |},
  |},
  +timeRegistrationSuggestedTasks?: ?$ReadOnlyArray<?{|
    +id: string,
    +name: ?string,
    +task: ?{|
      +id: string,
      +name: ?string,
      +progress: ?number,
      +companyTaskId: ?number,
      +highPriority: ?boolean,
      +approved: ?boolean,
      +favoured: ?boolean,
      +fullAccessToProject: ?boolean,
      +latestUiUpdateAt: ?string,
      +startDay: ?number,
      +startMonth: ?number,
      +startYear: ?number,
      +deadlineYear: ?number,
      +deadlineMonth: ?number,
      +deadlineDay: ?number,
      +timeLeft: ?number,
      +estimateForecastMinutes: ?number,
      +totalMinutesRegistered: ?number,
      +parentTaskId: ?string,
      +billable: ?boolean,
      +assignedPersons: ?$ReadOnlyArray<?{|
        +id: string
      |}>,
      +statusColumnV2: ?{|
        +category: ?STATUS_CATEGORY
      |},
      +project: ?{|
        +id: string,
        +name: ?string,
        +status: ?PROJECT_STATUS,
        +companyProjectId: ?number,
        +customProjectId: ?string,
        +projectColor: ?string,
        +estimationUnit: ?string,
        +fullAccessToProject: ?boolean,
        +manualProgressOnProjectEnabled: ?boolean,
        +manualProgressOnPhasesEnabled: ?boolean,
        +manualProgressOnTasksEnabled: ?boolean,
        +client: ?{|
          +id: string,
          +name: ?string,
        |},
        +harvestProject: ?{|
          +id: string,
          +name: ?string,
        |},
        +unit4Project: ?{|
          +id: string,
          +name: ?string,
        |},
      |},
      +phase: ?{|
        +id: string,
        +name: ?string,
      |},
    |},
    +idleTime: ?{|
      +id: string,
      +name: ?string,
      +isInternalTime: ?boolean,
      +favoured: ?boolean,
    |},
    +total: ?number,
    +reasons: ?$ReadOnlyArray<?{|
      +id: string,
      +label: ?string,
      +value: ?number,
      +sentenceId: ?string,
    |}>,
  |}>,
  +taskSuggestionsRecommender?: ?{|
    +recommendationInstanceId: ?number,
    +recommendationId: ?number,
    +generation: ?number,
    +timeStep: ?number,
  |},
  +$refType: TimesheetTableWrapper_viewer$ref,
|};
export type TimesheetTableWrapper_viewer$data = TimesheetTableWrapper_viewer;
export type TimesheetTableWrapper_viewer$key = {
  +$data?: TimesheetTableWrapper_viewer$data,
  +$fragmentRefs: TimesheetTableWrapper_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latestUiUpdateAt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDay",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startMonth",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startYear",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineYear",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineMonth",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineDay",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeLeft",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "estimateForecastMinutes",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalMinutesRegistered",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyTaskId",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "favoured",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "highPriority",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "approved",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billable",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullAccessToProject",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "parentTaskId",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "category",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyProjectId",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "customProjectId",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "estimationUnit",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectColor",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "manualProgressOnProjectEnabled",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "manualProgressOnPhasesEnabled",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "manualProgressOnTasksEnabled",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v30 = [
  (v0/*: any*/)
],
v31 = {
  "alias": null,
  "args": null,
  "concreteType": "Client",
  "kind": "LinkedField",
  "name": "client",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "concreteType": "PhaseType",
  "kind": "LinkedField",
  "name": "phase",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isInternalTime",
  "storageKey": null
},
v36 = [
  {
    "kind": "Variable",
    "name": "currentViewingDate",
    "variableName": "currentViewingDate"
  },
  {
    "kind": "Variable",
    "name": "timePeriod",
    "variableName": "timePeriod"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "taskTypeSearchQuery",
      "type": "TaskSearchQueryType"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "fetchSwitchTasks",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sortValue",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "fetchSuggestedTasks",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "timePeriod",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "currentViewingDate",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "personId",
      "type": "ID!"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "company",
          "taskTypeQueryTasks"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "company",
          "taskTypeQueryIdleTimes"
        ]
      }
    ]
  },
  "name": "TimesheetTableWrapper_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actualPersonId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "harvestUser",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unit4User",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bambooHREnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "harvestEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unit4Enabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "useTimeOffApproval",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "includeDisabled",
              "value": false
            }
          ],
          "concreteType": "RoleTypeConnection",
          "kind": "LinkedField",
          "name": "roles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RoleTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Role",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "roles(includeDisabled:false)"
        },
        {
          "condition": "fetchSwitchTasks",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": "taskTypeQueryTasks",
              "args": null,
              "concreteType": "TaskTypeConnection",
              "kind": "LinkedField",
              "name": "__Company_taskTypeQueryTasks_connection",
              "plural": false,
              "selections": [
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TaskTypeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Task",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "userCanDeleteTask",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "userCantDeleteTaskReason",
                          "storageKey": null
                        },
                        (v1/*: any*/),
                        (v4/*: any*/),
                        (v5/*: any*/),
                        (v6/*: any*/),
                        (v7/*: any*/),
                        (v8/*: any*/),
                        (v9/*: any*/),
                        (v10/*: any*/),
                        (v11/*: any*/),
                        (v12/*: any*/),
                        (v13/*: any*/),
                        (v14/*: any*/),
                        (v15/*: any*/),
                        (v16/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "bug",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "blocked",
                          "storageKey": null
                        },
                        (v17/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "canStart",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "canBeSetToDone",
                          "storageKey": null
                        },
                        (v18/*: any*/),
                        (v19/*: any*/),
                        (v20/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "StatusColumnV2",
                          "kind": "LinkedField",
                          "name": "statusColumnV2",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v1/*: any*/),
                            (v21/*: any*/)
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Person",
                          "kind": "LinkedField",
                          "name": "assignedPersons",
                          "plural": true,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "firstName",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "lastName",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "email",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "profilePictureId",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "profilePictureDefaultId",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "permissions",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ProjectType",
                          "kind": "LinkedField",
                          "name": "project",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v1/*: any*/),
                            (v22/*: any*/),
                            (v23/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "minutesPerEstimationPoint",
                              "storageKey": null
                            },
                            (v24/*: any*/),
                            (v25/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "projectStartYear",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "projectStartMonth",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "projectStartDay",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "projectEndYear",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "projectEndMonth",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "projectEndDay",
                              "storageKey": null
                            },
                            (v26/*: any*/),
                            (v27/*: any*/),
                            (v28/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "taskLevels",
                              "storageKey": null
                            },
                            (v29/*: any*/),
                            (v19/*: any*/),
                            {
                              "alias": null,
                              "args": [
                                {
                                  "kind": "Variable",
                                  "name": "personId",
                                  "variableName": "personId"
                                }
                              ],
                              "concreteType": "ProjectPerson",
                              "kind": "LinkedField",
                              "name": "projectPerson",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Role",
                                  "kind": "LinkedField",
                                  "name": "role",
                                  "plural": false,
                                  "selections": (v2/*: any*/),
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "HarvestProjectType",
                              "kind": "LinkedField",
                              "name": "harvestProject",
                              "plural": false,
                              "selections": (v30/*: any*/),
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Unit4ProjectType",
                              "kind": "LinkedField",
                              "name": "unit4Project",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "activitiesEnabled",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            (v31/*: any*/)
                          ],
                          "storageKey": null
                        },
                        (v32/*: any*/),
                        (v33/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v34/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": "taskTypeQueryIdleTimes",
              "args": null,
              "concreteType": "IdleTimeTypeConnection",
              "kind": "LinkedField",
              "name": "__Company_taskTypeQueryIdleTimes_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "IdleTimeTypeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "IdleTime",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/),
                        (v35/*: any*/),
                        (v15/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "disabled",
                          "storageKey": null
                        },
                        (v33/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v34/*: any*/)
                  ],
                  "storageKey": null
                },
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    },
    {
      "condition": "fetchSuggestedTasks",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": (v36/*: any*/),
          "concreteType": "SuggestedTask",
          "kind": "LinkedField",
          "name": "timeRegistrationSuggestedTasks",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Task",
              "kind": "LinkedField",
              "name": "task",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "progress",
                  "storageKey": null
                },
                (v14/*: any*/),
                (v16/*: any*/),
                (v17/*: any*/),
                (v15/*: any*/),
                (v19/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/),
                (v6/*: any*/),
                (v7/*: any*/),
                (v8/*: any*/),
                (v9/*: any*/),
                (v10/*: any*/),
                (v11/*: any*/),
                (v12/*: any*/),
                (v13/*: any*/),
                (v20/*: any*/),
                (v18/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Person",
                  "kind": "LinkedField",
                  "name": "assignedPersons",
                  "plural": true,
                  "selections": (v30/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "StatusColumnV2",
                  "kind": "LinkedField",
                  "name": "statusColumnV2",
                  "plural": false,
                  "selections": [
                    (v21/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectType",
                  "kind": "LinkedField",
                  "name": "project",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v29/*: any*/),
                    (v22/*: any*/),
                    (v23/*: any*/),
                    (v25/*: any*/),
                    (v24/*: any*/),
                    (v19/*: any*/),
                    (v26/*: any*/),
                    (v27/*: any*/),
                    (v28/*: any*/),
                    (v31/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "HarvestProjectType",
                      "kind": "LinkedField",
                      "name": "harvestProject",
                      "plural": false,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Unit4ProjectType",
                      "kind": "LinkedField",
                      "name": "unit4Project",
                      "plural": false,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v32/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "IdleTime",
              "kind": "LinkedField",
              "name": "idleTime",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v35/*: any*/),
                (v15/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "total",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "SuggestedTaskReason",
              "kind": "LinkedField",
              "name": "reasons",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "label",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "value",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sentenceId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v36/*: any*/),
          "concreteType": "Recommender",
          "kind": "LinkedField",
          "name": "taskSuggestionsRecommender",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "recommendationInstanceId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "recommendationId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "generation",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "timeStep",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ebc582a4a35ad4ef8c9c4ceb5b7e6622';

module.exports = node;
