import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Util from '../../util/util';
import {INITIALS_SIZE} from '../../../../constants';
import PersonInitials from './person_initials';
/**
 * Generic person display
 */

class Person extends Component {
	render() {
		let size = 24;
		let initialsSize = INITIALS_SIZE.SMALL;
		if (this.props.imageSize) {
			switch (this.props.imageSize) {
				case 'small':
					size = 16;
					initialsSize = INITIALS_SIZE.SMALL;
					break;
				case 'new-ui-dropdown':
					size = 30;
					initialsSize = INITIALS_SIZE.MEDIUM;
					break;
				case 'new-ui-person-dropdown':
					size = 26;
					break;
				case 'large':
					size = 34;
					initialsSize = INITIALS_SIZE.MEDIUM;
					break;
				case 'very-large':
					size = 40;
					initialsSize = INITIALS_SIZE.LARGE;
					break;
				case 'medium':
				default:
					size = 24;
					break;
			}
		}
		const initials = Util.getInitials(this.props.name);
		return (
			<div
				className={'forecast-person no-drag'}
				title={this.props.hideTitle ? null : this.props.name}
				data-cy={this.props.cy}
			>
				{this.props.hideImage ? null : (
					<div
						className={'image-container no-drag' + (this.props.removePerson ? ' can-remove' : '')}
						onClick={this.props.removePerson ? this.props.removePerson : null}
						style={{width: size, height: size}}
					>
						<div
							className={
								this.props.isActive || this.props.isActive == null
									? 'disabled-overlay-hidden'
									: 'disabled-overlay'
							}
						/>
						{!this.props.imageSrc || this.props.imageSrc.includes('.png') ? (
							<div className="person-component-initials-container">
								<PersonInitials
									initials={initials}
									size={this.props.initialsSize ? this.props.initialsSize : initialsSize}
								/>
							</div>
						) : this.props.isOwner || this.props.personSelected ? (
							Util.getProfilePictureWithBorder(
								this.props.imageSrc,
								this.props.ownerBorderColor || '#05E298',
								size
							)
						) : (
							<img crossOrigin="use-credentials" alt="" src={this.props.imageSrc} height={size} />
						)}
					</div>
				)}
				<div
					className={'no-drag ' + (!this.props.showName && !this.props.showRole ? 'person-info hide' : 'person-info')}
				>
					<span
						className={
							'name no-drag' +
							(this.props.showName ? '' : ' hide') +
							(this.props.isDeactivated ? ' deactivated' : '')
						}
					>
						<div className={'name-inner' + (this.props.personSelected ? ' personSelected' : '')}>
							{this.props.name}
						</div>
						{this.props.taskAmount !== null && this.props.taskAmount !== undefined ? (
							<div className="task-amount">{'(' + this.props.taskAmount + ')'}</div>
						) : null}
					</span>
					<span
						className={
							'role no-drag' +
							(this.props.showRole ? '' : ' hide') +
							(this.props.personSelected ? ' personSelected' : '')
						}
					>
						{this.props.role ? this.props.role : ''}
					</span>
				</div>
			</div>
		);
	}
}

Person.propTypes = {
	name: PropTypes.string.isRequired,
	role: PropTypes.string,
	showName: PropTypes.bool.isRequired,
	showRole: PropTypes.bool.isRequired,
	hideTitle: PropTypes.bool,
	imageSize: PropTypes.oneOf(['very-large', 'large', 'medium', 'small', 'new-ui-dropdown', 'new-ui-person-dropdown']),
	imageSrc: PropTypes.string,
	canRemove: PropTypes.bool,
	isActive: PropTypes.bool,
	isDeactivated: PropTypes.bool,
	isOwner: PropTypes.bool,
	ownerBorderColor: PropTypes.string,
	cy: PropTypes.string,
	removePerson: PropTypes.func,
	personSelected: PropTypes.bool,
	initialsSize: PropTypes.oneOf([INITIALS_SIZE.EXTRA_SMALL, INITIALS_SIZE.SMALL, INITIALS_SIZE.MEDIUM, INITIALS_SIZE.LARGE]),
};

export default Person;
