import React, {Component} from 'react';
import UploadingOverlay from '../uploading-overlay/uploading_overlay';
import {withRouter} from 'react-router-dom';
import {removeTaskLinkFromUrl} from '../../util/UrlUtil';

class TaskModalLoader extends Component {
	closeModal() {
		const path = window.location.pathname;
		const newUrl = removeTaskLinkFromUrl(path);
		this.props.history.push(newUrl);
	}

	render() {
		return (
			<div data-cy="task-loader" className="task-modal-v3" onMouseDown={this.closeModal.bind(this)}>
				<div className="task-task-modal-v3-content" onMouseDown={e => e.stopPropagation()}>
					<div className="cut-off-corner-box-container">
						<svg width="920" height="15">
							<polygon points="0,0 899,0 920,13, 920,15 0,15" style={{fill: 'white'}} />
						</svg>
					</div>
					<div className="task-modal-loader loading" style={{minHeight: '456px'}}>
						<UploadingOverlay />
						{this.props.children}
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(TaskModalLoader);
