/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = [
        (v0 /*: any*/)
    ];
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "companyTaskId",
                "type": "String!"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "companyProjectId",
                "type": "String!"
            }
        ],
        "kind": "Fragment",
        "metadata": null,
        "name": "TimeRegistrationModalCreate_viewer",
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "actualPersonId",
                "storageKey": null
            },
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Variable",
                        "name": "companyTaskId",
                        "variableName": "companyTaskId"
                    }
                ],
                "concreteType": "Task",
                "kind": "LinkedField",
                "name": "task",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectType",
                        "kind": "LinkedField",
                        "name": "project",
                        "plural": false,
                        "selections": (v1 /*: any*/),
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Variable",
                        "name": "id",
                        "variableName": "companyProjectId"
                    }
                ],
                "concreteType": "ProjectType",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": (v1 /*: any*/),
                "storageKey": null
            }
        ],
        "type": "Viewer"
    };
})();
node.hash = '7f1169b8c10c50d5e2ece8d4734fc462';
export default node;
