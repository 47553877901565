import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import LabelNamed from '../../labels/label_named';

class LabelSectionLabelPicker extends Component {
	constructor(props) {
		super(props);
		this.state = {
			input: '',
			matchingLabels: [],
			showDropdown: true,
			isManagingFocus: false,
			dropdownHeight: 250,
		};
		this.collapseDropdown = this.collapseDropdown.bind(this);
	}

	componentDidMount() {
		document.addEventListener('scroll', this.collapseDropdown, true);
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			(!prevState.isManagingFocus && this.state.isManagingFocus) ||
			(this.dropdown && prevState.searchCriteria !== this.state.searchCriteria)
		) {
			const dropdownNode = this.dropdown.getBoundingClientRect();
			this.setState({
				dropdownHeight: dropdownNode.height,
			});
		}
	}

	componentWillUnmount() {
		document.removeEventListener('scroll', this.collapseDropdown, true);
	}

	collapseDropdown(e) {
		if (this.props.projectLocked) return;
		if (e.target && e.target.id === 'labels-dropdown') return;
		if (this.labelPickerInput) {
			this.labelPickerInput.blur();
		}
	}

	removeLabelHandler(label) {
		this.props.removeLabelFromTask(label.id);
	}

	onFocus(e) {
		// Clear the timeout so we dont collapse the dropdown
		clearTimeout(this._timeoutID);
		if (!this.state.isManagingFocus) {
			this.setState({
				isManagingFocus: true,
				showDropdown: true,
			});
		}
	}

	onBlur(e) {
		// Set a timeout to collapse the dropdown if we dont get focus within this tick
		this._timeoutID = setTimeout(() => {
			if (this.state.isManagingFocus) {
				this.setState({
					isManagingFocus: false,
				});
			}
		}, 0);
	}

	render() {
		const taskLabels = this.props.taskLabels;
		return (
			<div
				className={`label-picker-container ${this.props.expanded ? 'expanded' : 'not-expanded'}`}
				data-cy={this.props.cy}
			>
				<div
					className={`labels ${this.props.expanded ? 'expanded' : 'not-expanded'}`}
					ref={label => (this.label = label)}
				>
					{taskLabels.map(label => (
						<LabelNamed
							key={label.id}
							label={label}
							removeLabel={this.removeLabelHandler.bind(this)}
							disabled={this.props.disabled}
							cy={this.props.cy}
						/>
					))}
				</div>
			</div>
		);
	}
}

LabelSectionLabelPicker.propTypes = {
	taskLabels: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			name: PropTypes.string,
		})
	).isRequired,
	allLabels: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			name: PropTypes.string,
		})
	).isRequired,
	addLabelToTask: PropTypes.func.isRequired,
	removeLabelFromTask: PropTypes.func.isRequired,
	createAndAddLabel: PropTypes.func.isRequired,
	placeInputFirst: PropTypes.bool,
	dropdownMaxHeight: PropTypes.number,
	addInfoText: PropTypes.bool,
	projectLocked: PropTypes.bool,
};

LabelSectionLabelPicker.defaultProps = {
	placeInputFirst: true,
	dropdownMaxHeight: 250,
};

export default injectIntl(LabelSectionLabelPicker);
