import {graphql} from 'react-relay';

export const scopingPredictionsQuery = graphql`
	query ProjectScopingPredictionsQuery($projectId: ID) {
		viewer {
			id
			component(name: "scoping_predictions")
			company {
				availableMLModels {
					lowHighModel
					lowHighModelScore
				}
			}
			predictionProject: project(internalId: $projectId) {
				id
				predictedOverrunningTasks {
					globalTaskId
					estimate
					overrunProbability
				}
			}
		}
	}
`;
