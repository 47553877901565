import React from 'react';
import { isRolesAllowed } from '../../util/cache/TimeRegistrationSettingsUtil';
import { CSS_CONSTANTS } from '../../../../css_variables';
function TimeRegistrationRolesDisplay({ roles }) {
    return isRolesAllowed() && !!roles ? (React.createElement("div", { style: {
            display: 'inline',
            fontWeight: 'normal',
            color: `${CSS_CONSTANTS.v2_text_light_gray}`,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        } }, ` (Time registered as ${roles.join(', ')})`)) : null;
}
export default TimeRegistrationRolesDisplay;
