/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type TaskRelayWrapperV2_viewer$ref: FragmentReference;
declare export opaque type TaskRelayWrapperV2_viewer$fragmentType: TaskRelayWrapperV2_viewer$ref;
export type TaskRelayWrapperV2_viewer = {|
  +reportData: ?{|
    +tasks: ?{|
      +pageInfo: {|
        +endCursor: ?string,
        +hasNextPage: boolean,
      |},
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +companyTaskId: ?number,
          +name: ?string,
          +approved: ?boolean,
          +dates: ?{|
            +start: ?string,
            +deadline: ?string,
            +createdAt: ?string,
          |},
          +project: ?{|
            +id: string,
            +name: ?string,
            +deadline: ?string,
            +stage: ?string,
            +client: ?{|
              +name: ?string
            |},
            +rateCard: ?{|
              +name: ?string
            |},
            +estimationUnit: ?string,
            +minutesPerEstimationPoint: ?number,
            +projectColor: ?string,
            +companyProjectId: ?number,
            +customProjectId: ?string,
            +readOnlyAccess: ?boolean,
          |},
          +status: ?{|
            +name: ?string
          |},
          +role: ?{|
            +name: ?string
          |},
          +assignees: ?$ReadOnlyArray<?{|
            +id: string,
            +firstName: ?string,
            +lastName: ?string,
          |}>,
          +phase: ?{|
            +name: ?string,
            +dates: ?{|
              +start: ?string,
              +deadline: ?string,
            |},
          |},
          +sprint: ?{|
            +name: ?string,
            +dates: ?{|
              +start: ?string,
              +deadline: ?string,
            |},
          |},
          +work: ?{|
            +estimate: ?number,
            +remaining: ?number,
          |},
          +reported: ?{|
            +time: ?number,
            +points: ?number,
            +projected: ?number,
          |},
          +progress: ?number,
          +financial: ?{|
            +revenue: ?{|
              +planned: ?number,
              +actual: ?number,
            |}
          |},
          +indicators: ?{|
            +priority: ?boolean,
            +bug: ?boolean,
            +billable: ?boolean,
            +blocked: ?boolean,
          |},
          +labels: ?$ReadOnlyArray<?{|
            +name: ?string,
            +color: ?string,
          |}>,
          +reportCustomFieldValues: ?$ReadOnlyArray<?{|
            +id: string,
            +key: ?string,
            +value: ?string,
            +displayName: ?string,
            +readOnly: ?boolean,
          |}>,
        |}
      |}>,
    |}
  |},
  +$refType: TaskRelayWrapperV2_viewer$ref,
|};
export type TaskRelayWrapperV2_viewer$data = TaskRelayWrapperV2_viewer;
export type TaskRelayWrapperV2_viewer$key = {
  +$data?: TaskRelayWrapperV2_viewer$data,
  +$fragmentRefs: TaskRelayWrapperV2_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "start",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadline",
  "storageKey": null
},
v4 = [
  (v1/*: any*/)
],
v5 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "TaskReportTableTask_Dates",
    "kind": "LinkedField",
    "name": "dates",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchQuery",
      "type": "TaskSearchQueryType"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "pageSize",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "reportId",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sharedId",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "customFieldKeys",
      "type": "[String]"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "pageSize",
        "cursor": null,
        "direction": "forward",
        "path": [
          "reportData",
          "tasks"
        ]
      }
    ]
  },
  "name": "TaskRelayWrapperV2_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "reportId"
        },
        {
          "kind": "Variable",
          "name": "sharedId",
          "variableName": "sharedId"
        }
      ],
      "concreteType": "ReportData",
      "kind": "LinkedField",
      "name": "reportData",
      "plural": false,
      "selections": [
        {
          "alias": "tasks",
          "args": [
            {
              "kind": "Variable",
              "name": "searchQuery",
              "variableName": "searchQuery"
            }
          ],
          "concreteType": "ReportTaskTypeConnection",
          "kind": "LinkedField",
          "name": "__TaskReport_tasks_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ReportTaskTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ReportTask",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "companyTaskId",
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "approved",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "TaskReportTableTask_Dates",
                      "kind": "LinkedField",
                      "name": "dates",
                      "plural": false,
                      "selections": [
                        (v2/*: any*/),
                        (v3/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "createdAt",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ReportProject",
                      "kind": "LinkedField",
                      "name": "project",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/),
                        (v3/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "stage",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ReportClient",
                          "kind": "LinkedField",
                          "name": "client",
                          "plural": false,
                          "selections": (v4/*: any*/),
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "TaskReportTableTask_RateCard",
                          "kind": "LinkedField",
                          "name": "rateCard",
                          "plural": false,
                          "selections": (v4/*: any*/),
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "estimationUnit",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "minutesPerEstimationPoint",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "projectColor",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "companyProjectId",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "customProjectId",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "readOnlyAccess",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "TaskReportTableTask_Status",
                      "kind": "LinkedField",
                      "name": "status",
                      "plural": false,
                      "selections": (v4/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ReportRole",
                      "kind": "LinkedField",
                      "name": "role",
                      "plural": false,
                      "selections": (v4/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ReportPerson",
                      "kind": "LinkedField",
                      "name": "assignees",
                      "plural": true,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "firstName",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "lastName",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ReportPhase",
                      "kind": "LinkedField",
                      "name": "phase",
                      "plural": false,
                      "selections": (v5/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ReportSprint",
                      "kind": "LinkedField",
                      "name": "sprint",
                      "plural": false,
                      "selections": (v5/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "TaskReportTableTask_Work",
                      "kind": "LinkedField",
                      "name": "work",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "estimate",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remaining",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "TaskReportTableTask_Reported",
                      "kind": "LinkedField",
                      "name": "reported",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "time",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "points",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "projected",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "progress",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ReportFinancial",
                      "kind": "LinkedField",
                      "name": "financial",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "TaskReportTableTask_Revenue",
                          "kind": "LinkedField",
                          "name": "revenue",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "planned",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "actual",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "TaskReportTableTask_Indicators",
                      "kind": "LinkedField",
                      "name": "indicators",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "priority",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "bug",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "billable",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "blocked",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "TaskReportTableTask_Label",
                      "kind": "LinkedField",
                      "name": "labels",
                      "plural": true,
                      "selections": [
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "color",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": [
                        {
                          "kind": "Variable",
                          "name": "fieldNodeKeys",
                          "variableName": "customFieldKeys"
                        }
                      ],
                      "concreteType": "ReportCustomFieldValue",
                      "kind": "LinkedField",
                      "name": "reportCustomFieldValues",
                      "plural": true,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "key",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "value",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "displayName",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "readOnly",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '6ba833c8ab58e01343301b0b1739ce33';

module.exports = node;
