import { getFormattedName } from './FormatterUtil';
const getFormattedInternalTimeHighlight = () => {
    // Default to company_project_id_fallback if highlight is undefined
    const highlightField = 'Internal Time';
    const highlightValue = '';
    return {
        highlightField,
        highlightValue,
    };
};
export const internalTimeFormatter = ({ searchResult, trackAndHideSearch, }) => {
    const onSelect = (rank, openedInNewTab) => {
        trackAndHideSearch({ id: searchResult.id, variant: searchResult.variant, rank: rank, searchResult }, !openedInNewTab);
    };
    const formattedName = getFormattedName(searchResult.name, searchResult.highlight);
    const formattedHighlight = getFormattedInternalTimeHighlight();
    return {
        title: searchResult.name,
        name: formattedName,
        highlight: formattedHighlight,
        variant: searchResult.variant,
        ariaLabel: `Search result. Type: Internal time. Title: ${searchResult.name}.`,
        onSelect,
    };
};
