import React, {Component} from 'react';
import TooltipIcon from '../../tooltips/tooltip_icon';
import {injectIntl} from 'react-intl';

class Collapse extends Component {
	render() {
		return (
			<TooltipIcon
				className={'collapse-button' + (this.props.collapsed ? ' collapsed' : ' expanded')}
				onClick={this.props.toggleCollapse}
				infoText={
					!this.props.collapsed
						? this.props.intl.formatMessage({id: 'workflow.collapse'})
						: this.props.intl.formatMessage({id: 'workflow.expand'})
				}
				data-userpilot={this.props.userpilot || 'collapse-button'}
			/>
		);
	}
}

export default injectIntl(Collapse);
