function isInStringEnum(value, enumerable) {
    return Object.values(enumerable).includes(value);
}
/**
 * Function to reverse-map a value back to the appropriate enum-type, an operation which is not natively implemented in TS.
 * Commonly used to type-check that an untyped value, such as a user-input or an item from LocalStorage, is a valid value of the given enum.
 * Returns null if the value is not in the enum.
 */
export function stringToEnumValue(value, enumerable) {
    if (value && isInStringEnum(value, enumerable)) {
        // while casting first to unknown is not technically type-safe, once we are sure that the value is in the enum, we are guaranteed a valid value in the enum.
        return value;
    }
    return null;
}
