var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
// Manually maintained list of pathnames used by the tabs that should be filtered out. Might be possible to make an automatic solution, but currently was the best I could come up with.
const tabPaths = ['/period', '/month-by-month'];
const removeTabpathsFromPathname = (pathname) => {
    return tabPaths.reduce((pathname, tabPath) => {
        return pathname ? pathname.replace(tabPath, '') : '';
    }, pathname);
};
const handleBeforeUnload = (e) => {
    if (e) {
        e.preventDefault();
        e.returnValue = 'Do you want to save your changes?'; // cannot use intl here - this has to be a plain js function, besides, most browsers show their own prompts
    }
};
export const useRouterPrompt = ({ hasChanges, onSave, onSaveAs, onDiscard, ignoreChildPaths, }) => {
    const history = useHistory();
    const { location } = history;
    const [showPrompt, setShowPrompt] = useState(false);
    const [currentPath, setCurrentPath] = useState('');
    const removeBlocker = useCallback(() => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
        history.block(() => void 0);
    }, [history]);
    useEffect(() => {
        if (hasChanges) {
            // handles when page is unloaded (refresh, close, click favourite, etc)
            // it uses the browser default prompt
            window.addEventListener('beforeunload', handleBeforeUnload);
            // internal routing allows a custom prompt to be shown
            history.block(prompt => {
                var _a;
                const currentPathnameWithoutTabSuffix = removeTabpathsFromPathname((_a = location === null || location === void 0 ? void 0 : location.pathname) !== null && _a !== void 0 ? _a : ''); // Seems like location can be undefined in the tests since there is no parent <Router />. Not sure how to circumvent.
                const skip = ignoreChildPaths && prompt.pathname.includes(currentPathnameWithoutTabSuffix);
                if (!skip) {
                    setCurrentPath(prompt.pathname);
                    setShowPrompt(true);
                    return 'true';
                }
            });
        }
        else {
            removeBlocker();
        }
        return () => {
            removeBlocker();
        };
    }, [history, hasChanges]);
    const handleSave = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (onSave) {
            const onSuccess = () => {
                removeBlocker();
                history.push(currentPath);
            };
            onSave(onSuccess);
        }
    }), [currentPath, history, onSave]);
    const handleSaveAs = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (onSaveAs) {
            const onSuccess = () => {
                removeBlocker();
                history.push(currentPath);
            };
            onSaveAs(onSuccess);
        }
    }), [currentPath, history, onSaveAs]);
    const handleDiscard = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const canRoute = onDiscard ? yield Promise.resolve(onDiscard()) : true;
        if (canRoute) {
            removeBlocker();
            history.push(currentPath);
            setShowPrompt(false);
        }
    }), [currentPath, history, onDiscard]);
    const closePrompt = useCallback(() => {
        setShowPrompt(false);
    }, []);
    return { showPrompt, handleSave, handleSaveAs, handleDiscard, closePrompt };
};
