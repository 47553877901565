import styled from 'styled-components';
export const DesignSystemComboBoxOldLookAdapter = styled.div `
	width: 100%;

	div {
		color: #a1a1a1;
		:first-child {
			&:hover {
				border-color: #b3b3b3;
			}
		}

		border-color: #e6e6e6;

		font-family: 'neue-haas-grotesk-text', 'sans-serif';
		font-size: 9px;
		letter-spacing: 1px;
		font-weight: 500;

		input {
			font-family: 'neue-haas-grotesk-text', 'sans-serif';
			font-size: 12px;
		}
	}
`;
export const DesignSystemMultiComboBoxBorderAdapter = styled.div `
	width: 100%;

	div.multiComboboxControl {
		:first-child {
			&:hover {
				border-color: #b3b3b3;
			}
		}

		border-color: #e6e6e6;
	}
`;
