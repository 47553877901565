import React from 'react';
import Util from '../../../../forecast-app/shared/util/util';
import styled from 'styled-components';
import ForecastTooltip from '../../../shared/components/tooltips/ForecastTooltip';
import {
	DeprecatedContentContainer as ContentContainer,
	FlexColumn,
	FlexRow,
	DeprecatedText as Text,
	deprecatedTheme as theme,
	Icon,
} from '@forecast-it/design-system';
import {FormattedHTMLMessage, useIntl} from 'react-intl';
import {NUMBER_TYPE} from '../../../../components/new-ui/project/project-budget-v3/util/BudgetUtils';
import {ForecastTooltipFormulaRenderer} from '../../../../components/ForecastTooltipFormulaRenderer';
import {PROGRAM_BUDGET_TYPE} from '../../../../constants';
import {hasTopDownProgramBudgetFeature} from '../../../shared/util/ProgramFinancialLogic';
import ProjectUtil from '../../../shared/util/project_util';

export const getTotalCost = projects => {
	let totalCost = 0;
	projects.forEach(project => {
		totalCost +=
			project.node.financialNumbers.totalCostAtCompletion && ProjectUtil.isFixedPriceProject(project.node)
				? project.node.financialNumbers.totalCostAtCompletion
				: 0;
	});

	return totalCost;
};

const ProgramBudgetTypeProfitBox = ({program, company}) => {
	const {formatMessage} = useIntl();
	const intl = useIntl();

	const VerticalDivider = styled.div`
		border-left: 1px solid ${theme.colors.border.primary.default};
		height: 40px;
	`;

	const formatNumber = (value, numberType, currencySymbol, intl) => {
		switch (numberType) {
			case NUMBER_TYPE.MONEY:
				return Util.getFormattedNumberWithCurrency(currencySymbol, value, intl);
			case NUMBER_TYPE.PERCENTAGE:
				return Util.getFormattedPercentage(value, intl);
			default:
				break;
		}
		return value;
	};

	const tooltips = {
		SpendTotalAtCompletion: [
			{
				title: formatMessage({id: 'project_budget.total_value_of_service_at_completion'}),
				details: [
					formatMessage({id: 'common.time'}),
					'x',
					formatMessage({id: 'common.rate'}),
					'+',
					formatMessage({id: 'common.expenses'}),
				],
			},
		],
		SpendActualToDate: [
			{
				title: formatMessage({id: 'project_budget.actual_value_of_service_to_date'}),
				details: [
					formatMessage({id: 'common.time'}),
					'x',
					formatMessage({id: 'common.rate'}),
					'+',
					formatMessage({id: 'common.expenses'}),
					'(to date)',
				],
			},
		],
		ProfitMarginActualToDate: [
			{
				title: formatMessage({id: 'project_budget.profit_margin_to_date'}),
				details: [
					`( ${formatMessage({id: 'common.revenue_recognized_to_date'})}`,
					'-',
					`${formatMessage({id: 'common.cost_to_date'})})`,
					'/',
					formatMessage({id: 'common.revenue_recognized_to_date'}),
					'X',
					'100',
				],
			},
			!hasTopDownProgramBudgetFeature()
				? {
						description: formatMessage({id: 'project_budget.aggregated_projects_in_program_tooltip'}),
				  }
				: {},
		],
		ProfitMarginActualAtCompletion: [
			{
				title: formatMessage({id: 'project_budget.profit_margin_at_completion'}),
				details: [
					`( ${formatMessage({id: 'common.revenue_recognition_at_completion'})}`,
					'-',
					`${formatMessage({id: 'common.cost_at_completion'})})`,
					'/',
					formatMessage({id: 'common.revenue_recognition_at_completion'}),
					'X',
					'100',
				],
			},
			!hasTopDownProgramBudgetFeature()
				? {
						description: formatMessage({id: 'project_budget.aggregated_projects_in_program_tooltip'}),
				  }
				: {},
		],
	};

	const totalCost = getTotalCost(program.projects.edges);

	return (
		<FlexColumn gap={'xl'}>
			<FlexRow alignItems={'flex-end'} gap={'xl'}>
				<ContentContainer width={'auto'} height={program.budgetType === PROGRAM_BUDGET_TYPE.CAPPED ? '250px' : '170px'}>
					<FlexColumn gap={'xl'}>
						<Text type={'heading'} variant={'m'} color={'primary'}>
							{<FormattedHTMLMessage id={'common.profit'} />}
						</Text>
						<FlexRow justifyContent="left" alignItems="flex-start" gap={'xl'}>
							<FlexColumn gap={'s'}>
								<Text type={'label'} variant={'l'} color={'primary'}>
									{formatMessage({id: 'project_budget.profit_margin_to_date'})}
								</Text>
								<FlexRow gap={'m'}>
									<Text type={'heading'} variant={'s'} color={'primary'}>
										{formatNumber(
											program.projectsFinancialsToDate.recognitionActualProfitPercentage,
											NUMBER_TYPE.PERCENTAGE,
											null,
											intl
										)}
									</Text>
									<ForecastTooltip
										maxWidth={1000}
										placement={'bottom'}
										content={
											<ForecastTooltipFormulaRenderer
												items={tooltips.ProfitMarginActualToDate}
												translatedMessage={true}
											/>
										}
									>
										<FlexColumn>
											<Icon icon={'help'} size={'s'} />
										</FlexColumn>
									</ForecastTooltip>
								</FlexRow>
							</FlexColumn>
							<VerticalDivider />
							<FlexColumn gap={'s'}>
								<FlexRow gap={'l'}>
									<div style={{width: '200px'}}>
										<Text type={'label'} variant={'l'} color={'primary'}>
											{formatMessage({id: 'project_budget.profit_margin_at_completion'})}
										</Text>
									</div>
								</FlexRow>
								<FlexRow gap={'m'}>
									<Text type={'heading'} variant={'s'} color={'primary'}>
										{formatNumber(
											program.budgetType === PROGRAM_BUDGET_TYPE.FIXED_PRICE
												? (program.budgetValue - totalCost) / program.budgetValue
												: program.projectsFinancialsTotals.recognitionProfitPercentage,
											NUMBER_TYPE.PERCENTAGE,
											null,
											intl
										)}
									</Text>
									<ForecastTooltip
										maxWidth={1000}
										placement={'bottom'}
										content={
											<ForecastTooltipFormulaRenderer
												items={tooltips.ProfitMarginActualAtCompletion}
												translatedMessage={true}
											/>
										}
									>
										<FlexColumn>
											<Icon icon={'help'} size={'s'} />
										</FlexColumn>
									</ForecastTooltip>
								</FlexRow>
							</FlexColumn>
						</FlexRow>
					</FlexColumn>
				</ContentContainer>
			</FlexRow>
		</FlexColumn>
	);
};

export default ProgramBudgetTypeProfitBox;
