/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type MyWorkProfile_person$ref: FragmentReference;
declare export opaque type MyWorkProfile_person$fragmentType: MyWorkProfile_person$ref;
export type MyWorkProfile_person = {|
  +id: string,
  +initials: ?string,
  +firstName: ?string,
  +lastName: ?string,
  +role: ?{|
    +id: string,
    +name: ?string,
  |},
  +profilePictureId: ?string,
  +profilePictureDefaultId: ?number,
  +$refType: MyWorkProfile_person$ref,
|};
export type MyWorkProfile_person$data = MyWorkProfile_person;
export type MyWorkProfile_person$key = {
  +$data?: MyWorkProfile_person$data,
  +$fragmentRefs: MyWorkProfile_person$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MyWorkProfile_person",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "initials",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Role",
      "kind": "LinkedField",
      "name": "role",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profilePictureId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profilePictureDefaultId",
      "storageKey": null
    }
  ],
  "type": "Person"
};
})();
// prettier-ignore
(node/*: any*/).hash = '8bc66e0c7c3928fecb1700f44bbd6e41';

module.exports = node;
