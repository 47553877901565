import Styled from 'styled-components';
import {CSS_CONSTANTS} from '../../../css_variables';

export const NewLabelRowStyled = Styled.div`
    height: 40px;
    width: 100%;
    padding-left: 48px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	
	.new-label-row-wrapper {
	    height: 100%;
	    width: auto;
	    display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
	    
	    input {
            width: 232px;
            margin-right: 16px;
            padding: 5px 5px 5px 16px;
            border: 1px solid #6f49c5;
            border-radius: 4px;
            outline: none;
            box-sizing: border-box;
            color: #333;
            font-size: 13px;
    
            &.invalid {
                border-color: ${CSS_CONSTANTS.v2_border_invalid};
            }
            &::placeholder {
              color: #c9bdb5;
            }
        }
	}
`;
