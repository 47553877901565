/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type BUDGET_TYPE = "FIXED_PRICE" | "FIXED_PRICE_V2" | "NON_BILLABLE" | "RETAINER" | "TIME_AND_MATERIALS" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type SecondaryNavigation_project$ref: FragmentReference;
declare export opaque type SecondaryNavigation_project$fragmentType: SecondaryNavigation_project$ref;
export type SecondaryNavigation_project = {|
  +companyProjectId: ?number,
  +customProjectId: ?string,
  +sprintTimeBox: ?boolean,
  +budgetType: ?BUDGET_TYPE,
  +projectGroupId: ?string,
  +useBaseline: ?boolean,
  +hasInvoices: ?boolean,
  +financialSourceSettings: ?{|
    +plannedCost: ?string,
    +plannedRevenue: ?string,
    +actualCost: ?string,
    +actualRevenue: ?string,
    +forecastCost: ?string,
    +forecastRevenue: ?string,
  |},
  +$refType: SecondaryNavigation_project$ref,
|};
export type SecondaryNavigation_project$data = SecondaryNavigation_project;
export type SecondaryNavigation_project$key = {
  +$data?: SecondaryNavigation_project$data,
  +$fragmentRefs: SecondaryNavigation_project$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SecondaryNavigation_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "companyProjectId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customProjectId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sprintTimeBox",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "budgetType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectGroupId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "useBaseline",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasInvoices",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FinancialSourceSettings",
      "kind": "LinkedField",
      "name": "financialSourceSettings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "plannedCost",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "plannedRevenue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "actualCost",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "actualRevenue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "forecastCost",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "forecastRevenue",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProjectType"
};
// prettier-ignore
(node/*: any*/).hash = 'b2bb3945c3819cf8286c7dbc0ea5ef9b';

module.exports = node;
