import React from 'react';
import styled from 'styled-components';
import closeModal from '../../../../images/components/close-modal.svg';
import closeModalWhite from '../../../../images/components/close-modal-white.svg';
import plusBlack from '../../../../images/v2/components/header/plus-black.svg';
import Util from '../../util/util';
import AiIcon from '../../../../images/ico-ai';

const Label = styled.div`
	padding: 4px 8px 4px 4px;
	max-width: 225px;
	margin-left: 12px;
	height: 20px;
	border-radius: 2px;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	background-color: ${props => (props.backgroundColor ? props.backgroundColor : '#f3f3f3')};
	color: ${props => (props.color ? props.color : 'inherit')};
	cursor: ${props => (props.clickable ? 'pointer' : 'default')};
	position: relative;
	margin-bottom: 5px;
	display: flex;
	flex-shrink: 0;
	&.low-z-index {
		z-index: -1;
	}
	&:hover,
	&:focus {
		.add-suggested-label {
			opacity: 0.5;
		}
	}
	.ai-icon {
		position: relative;
		z-index: 2;
		margin-right: 4px;
	}
	.label-name {
		position: relative;
		z-index: 2;
		font-size: 10px;
		letter-spacing: 0.5px;
		font-weight: 500;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.remove-label-button {
		width: 8px;
		height: 8px;
		padding-right: 2px;
		margin-left: 10px;
		margin-top: 2px;

		position: relative;
		right: 0px;
		border: none;
		outline: none;

		display: inline-block;
		overflow: hidden;

		background-image: url(${closeModal});
		background-size: contain;
		background-repeat: no-repeat;

		&:hover,
		&:focus {
			opacity: 0.5;
		}

		&.white {
			background-image: url(${closeModalWhite});
		}
	}
	.add-suggested-label {
		width: 8px;
		height: 8px;
		margin: 2px 0 2px 5px;
		position: relative;
		border: none;
		outline: none;
		display: inline-block;
		overflow: hidden;
		background: url(${plusBlack});
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
	}
	&::before {
		position: absolute;
		-webkit-transform: translateY(-50%) translateX(50%) rotate(-45deg);
		transform: translateY(-50%) translateX(50%) rotate(-45deg);
		top: 50%;
		right: 100%;
		content: '';
		background-color: inherit;
		width: 13px;
		height: 13px;
	}
`;

const LabelNamed = ({label, index, noTitle, disabled, addSuggestedLabel, removeLabel, cy, lowZIndex}) => {
	const canDelete = removeLabel && !disabled;
	const canAdd = addSuggestedLabel && !disabled;

	return (
		<Label
			className={`label-content ${lowZIndex ? 'low-z-index' : ''}`}
			key={label.name + index}
			title={noTitle ? null : label.name}
			backgroundColor={label.color}
			color={label.color ? Util.getTextColorV2(label.color) : null}
			clickable={canAdd}
			onClick={() => canAdd && addSuggestedLabel(label.id)}
			data-cy={`${cy}-label`}
		>
			{addSuggestedLabel ? <AiIcon useNewIcon customSize={'12'} /> : null}
			<div className={'label-name'} data-cy="label-chip-name">
				{label.name}
			</div>
			{canDelete ? (
				<button
					tabIndex="-1"
					onClick={() => removeLabel(label)}
					className={'remove-label-button' + (Util.getTextColorV2(label.color) === '#ffffff' ? ' white' : '')}
					disabled={disabled}
					data-cy={`${cy}-label-delete`}
				/>
			) : null}
			{canAdd ? <button tabIndex="-1" className="add-suggested-label" disabled={disabled} /> : null}
		</Label>
	);
};

export default LabelNamed;
