import Styled from 'styled-components/macro';

export const CardWrapper = Styled.div`
  padding: 0 10px;
  margin: 0 0 10px;
  max-width: 281px;
  min-width: 281px;
  box-sizing: border-box;
  outline: none;
`;

export const CardLoaderWrapper = Styled.div`
    margin: 0 10px 10px;
`;

export const HeaderLogo = Styled.img`
    max-height: 18px;
    transform: ${props => props.collapsed && 'rotate(90deg)'};
    margin-top: ${props => props.collapsed && '22px'};
    margin-right: ${props => !props.collapsed && '8px'};
`;

export const RotatedDiv = Styled.div`
    transform: rotate(90deg);
`;

export const WorkflowWrapper = Styled.div`
    box-sizing: border-box;
    display: flex;
    flex: 1 1 auto;
    flex-direction:row;
    //height:100%;
    padding: 0 32px;
    > div {
        outline: none;
    }
`;

export const ColumnWrapper = Styled.div`
    position: relative;
    border-radius: 4px 4px 0 0;
    margin: 0 24px 0 0;
    min-width: 281px;
    max-width: 281px;
    background-color: #f3f3f3;
    display: flex;
    flex-direction:column;
    height:100%;


    &:focus {
        outline: none;
    }  
    &:first-of-type {
        margin-left: 0px;
    }
    // More robust and predictable (but very hacky looking) way to add margin after the final column element.
    // See https://alexandergottlieb.com/2018/02/22/overflow-scroll-and-the-right-padding-problem-a-css-only-solution/ for details
    &:last-of-type {
        &:after {
            content: "";
            display: block;
            position: absolute;
            right: -66px;
            width: 66px;
            height: 1px;
        }
    }
`;

export const CollapsedColumnWrapper = Styled.div`
    height: 100%;
    width: 44px;
    border-radius: 4px 4px 0 0;
    margin: 0 24px 0 0;
    .header-droppable {
        height: 100%;
        background-color: #dfdfdf;
        border-radius: 4px 4px 0 0;
    }
    .header-wrapper {
        
        height: 100%;
        &.drop-active {
            .header-droppable {
                background-color: rgba(110, 15, 234, 0.1)
            }
        }
    }
`;
export const HoverControl = Styled.div`
    visibility: ${props => (props.settingsExpanded && !(props.collapsed || props.invert) ? 'visible' : 'hidden')};
    transform: ${props => props.collapsed && 'rotate(90deg)'};
    transform: ${props => props.invert && 'rotate(180deg)'};
    margin-bottom: ${props => props.collapsed && '4px'};
    display: flex;
`;
export const ColumnHeader = Styled.div`
    &:hover ${HoverControl} {
        visibility: visible;
    }
    outline: none;
    border-radius: 4px 4px 0 0;
    position: relative;
    background-color:#dfdfdf;
    font-size: 14px;
    color: #535353;
    min-height:44px;
    height: 44px;
    padding: 0 10px;
    display: flex;
    text-overflow: ellipsis;
    flex-direction: row;
    align-items: center;
`;

export const CollapsedColumnHeader = Styled.header`
    &:hover ${HoverControl} {
        visibility: visible;
    }
    position: relative;
    height: 100%;
    padding-top: 14px;
    cursor: pointer;
    border-radius: 4px 4px 0 0;
    font-size: 14px;
    color: #535353;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const CantDropCollapsed = Styled.div`
        position: absolute;
        height: 100%;
        top: 0px;
        border-radius: 4px 4px 0 0;
		width: 100%;
		background-color: rgba(0, 0, 0, 0.1);
		display: flex;
		align-items: center;
		justify-content: center;
		backdrop-filter: blur(5px);
		z-index: 2;
    .text-container {
            height: 90%;
			width: 90%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			backdrop-filter: blur(10px);
			background-color: rgba(255, 255, 255, 0.7);
			border-radius: 5px;
        .blocked-icon-wrapper {
            svg {
                width: 30px;
                height: 30px;
            }
        }
    }
`;

export const TitleAndCount = Styled.div`
    display: flex;
    align-items: center;
    white-space: nowrap;
    flex-grow: 1;
    max-width: 186px;
    writing-mode: ${props => props.collapsed && 'vertical-rl'};
    margin-top: ${props => props.collapsed && '8px'};
        p {
            overflow: ${props => (props.collapsed ? 'visible' : 'hidden')};
            display: flex;
            align-items: center;
            margin-right: ${props => !props.collapsed && '4px'};
            b {
                font-weight: 600;
                margin-right: ${props => !props.collapsed && '4px'};
                margin-bottom: ${props => props.collapsed && '4px'};
        }
    }
`;

export const ColumnContentWrapper = Styled.div`
    flex-grow: 1;
    overflow: auto;
    outline: none;
    height: ${props => props.height && `${props.height}px`}
`;

export const ContentContainer = Styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
`;

export const TextInput = Styled.input`
    font-weight: 600;
    width: 100%;
    outline: none; 
    border: none;
    border-radius: 4px;
    background-color: #cecece;
    padding: 4px 8px;
    margin-right: 8px;
`;

export const HeaderSettingsMenu = Styled.div`
    position: absolute;
    top: 45px;
    right: 7px;
    z-index: 2;
`;

export const HeaderBarStyled = Styled.div`
    .header-bar {
        min-width: initial;        
    }
`;
export const AddTaskSection = Styled.div`
    display:flex;
    flex-direction:row;
    margin: 10px;
`;
export const BasicTextInputWrapper = Styled.div`
    flex:1;
`;

export const DependencyOverlay = Styled.div`
    border-radius: 4px 4px 0 0;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(5px);
    z-index: 2;
    .text-container {
        height: 90%;
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        backdrop-filter: blur(10px);
        background-color: rgba(255, 255, 255, 0.7);
        border-radius: 5px;

        .not-allowed-overlay {
            height: 100%;
            width: 100%;
            opacity: 0.8;
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
        }
        .blocked-icon-wrapper {
            z-index: 3;
            margin-bottom: 10px;
            svg {
                width: 30px;
                height: 30px;
            }
        }
        .dependencies-not-fulfilled {
            padding: 0 6px;
            z-index: 3;
            opacity: 1;
            color: black;
            display: flex;
            flex-direction: column;
            .dependency-header {
                text-align: center;
                margin-bottom: 10px;
                font-weight: 500;
                font-size: 14px;
            }
            .dependency-item {
                display: flex;
                justify-content: center;
                flex-direction: row;
                position: relative;
                margin-bottom: 6px;
                .dependency-text {
                    &::before {
                        content: '-';
                    }
                    font-weight: normal;
                    font-size: 13px;
                }
            }
        }
        h3 {
            z-index: 3;
            opacity: 1;
            position: absolute;
        }
    }
`;

export const WorkflowPageContainer = Styled.div`
    flex: auto;
    overflow: auto;
`;
