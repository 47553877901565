import {SOCKET_ACTION, SOCKET_EVENT_TYPE} from '../../../../constants';

export const getColumnSocketConfig = (projectIds, statusColumnId) => {
	return [
		{
			type: SOCKET_EVENT_TYPE.PROJECT,
			action: SOCKET_ACTION.DELETE,
			projectIds: projectIds,
		},
		{
			type: SOCKET_EVENT_TYPE.PROJECT,
			action: SOCKET_ACTION.UPDATE,
			projectIds: projectIds,
		},
		{
			type: SOCKET_EVENT_TYPE.TASK,
			action: SOCKET_ACTION.DELETE,
			projectIds: projectIds,
			statusColumnIds: statusColumnId,
		},
		{
			type: SOCKET_EVENT_TYPE.TASK,
			action: SOCKET_ACTION.UPDATE,
			projectIds: projectIds,
			statusColumnIds: statusColumnId,
		},
		{
			type: SOCKET_EVENT_TYPE.TASK,
			action: SOCKET_ACTION.CREATE,
			projectIds: projectIds,
			statusColumnIds: statusColumnId,
		},
	];
};
