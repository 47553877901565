import {hasSomePermission} from '../../../shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../Permissions';
import {addCustomFieldsEntity, filterAndHandleEyeOptions, filterEyeOptions} from '../../../shared/util/ReportServiceUtil';
import {REPORT_GROUPINGS, HIDDEN_FEATURES} from '../../../../constants';
import Util from '../../../shared/util/util';
import CompanySetupUtil, {isFeatureHidden} from '../../../shared/util/CompanySetupUtil';
import {hasFeatureFlag} from '../../../shared/util/FeatureUtil';

const groupFinancialOptions = [
	{
		name: 'taskTotals.financial.revenue.planned',
		checked: true,
		hide: true,
	},
	{
		name: 'taskTotals.financial.revenue.actual',
		checked: true,
		hide: true,
	},
	{
		name: 'taskTotals.reported.time',
		checked: true,
		hide: true,
	},
];

const groupTaskTotalsOptions = [
	{
		name: 'taskTotals.work.estimate.minutes',
		checked: true,
		hide: true,
	},
	{
		name: 'taskTotals.work.estimate.points',
		checked: true,
		hide: true,
	},
	{
		name: 'taskTotals.work.remaining.minutes',
		checked: true,
		hide: true,
	},
	{
		name: 'taskTotals.work.remaining.points',
		checked: true,
		hide: true,
	},
];

export const theEyeOptionsNoGrouping = () => [
	// Company Data
	{
		name: 'company.currency',
		checked: true,
		hide: true,
	},

	// Totals
	{
		name: 'totals',
		checked: true,
		hide: true,
		nestedOptions: [
			{
				name: 'entities',
				checked: true,
				hide: true,
			},
			...groupFinancialOptions,
			...groupTaskTotalsOptions,
		],
	},

	//Groups
	{
		name: 'task.phase',
		checked: true,
		hide: true,
		nestedOptions: [
			{
				name: 'name',
				checked: true,
				hide: true,
			},
			...groupFinancialOptions,
			...groupTaskTotalsOptions,
		],
	},
	{
		name: 'task.sprint',
		checked: true,
		hide: true,
		nestedOptions: [
			{
				name: 'name',
				checked: true,
				hide: true,
			},
			...groupTaskTotalsOptions,
		],
	},
	...(!isFeatureHidden(HIDDEN_FEATURES.CLIENT)
		? [
				{
					name: 'task.project.client',
					checked: true,
					hide: true,
					nestedOptions: [
						{
							name: 'name',
							checked: true,
							hide: true,
						},
						...groupTaskTotalsOptions,
					],
				},
		  ]
		: []),

	{
		name: 'task.project',
		checked: true,
		hide: true,
		nestedOptions: [
			{
				name: 'name',
				checked: true,
				hide: true,
			},
			{
				name: 'projectColor',
				checked: true,
				hide: true,
			},
			{
				name: 'companyProjectId',
				checked: true,
				hide: true,
			},
			{
				name: 'customProjectId',
				checked: true,
				hide: true,
			},
			{
				name: 'estimationUnit',
				checked: true,
				hide: true,
			},
			{
				name: 'minutesPerEstimationPoint',
				checked: true,
				hide: true,
			},
			...groupTaskTotalsOptions,
			...groupFinancialOptions,
		],
	},
	{
		name: 'task.role',
		checked: true,
		hide: true,
		nestedOptions: [
			{
				name: 'name',
				checked: true,
				hide: true,
			},
			...groupTaskTotalsOptions,
			...groupFinancialOptions,
		],
	},

	// Task options
	{
		name: 'task.companyTaskId',
		checked: true,
		hide: true,
	},
	{
		name: 'task.name',
		checked: true,
		hide: true,
	},
	{
		name: 'task.dates',
		checked: false,
		translationId: 'common.task_dates',
		nestedOptions: [
			{
				name: 'start',
				checked: false,
				translationId: 'common.start_date',
			},
			{
				name: 'deadline',
				checked: false,
				translationId: 'common.deadline',
			},
			{
				name: 'createdAt',
				checked: false,
				translationId: 'insights.component.list.column.createdAt',
			},
		],
	},
	{
		name: 'task.project.info',
		checked: true,
		translationId: 'common.project',
	},
	{
		name: 'task.project.deadline',
		checked: false,
		translationId: 'insights.component.list.column.projectDeadline',
	},
	{
		name: 'task.project.stage',
		checked: false,
		translationId: 'insights.component.list.column.projectStage',
	},
	...(!isFeatureHidden(HIDDEN_FEATURES.CLIENT)
		? [
				{
					name: 'task.project.client.info',
					checked: true,
					translationId: 'common.client',
				},
		  ]
		: []),
	{
		name: 'task.status.name',
		checked: true,
		translationId: 'common.status',
	},
	{
		name: 'task.role.info',
		checked: true,
		translationId: 'common.role',
	},
	{
		name: 'task.assignees',
		checked: true,
		translationId: 'common.assignees',
		nestedOptions: [
			{
				name: 'firstName',
				checked: true,
				hide: true,
			},
			{
				name: 'lastName',
				checked: true,
				hide: true,
			},
		],
	},
	{
		name: 'task.phase.info',
		checked: true,
		translationId: 'common.phase',
	},
	{
		name: 'task.phase.dates',
		checked: false,
		translationId: 'common.phase_dates',
		nestedOptions: [
			{
				name: 'start',
				checked: false,
				translationId: 'common.start_date',
			},
			{
				name: 'deadline',
				checked: false,
				translationId: 'common.deadline',
			},
		],
	},
	{
		name: 'task.sprint.info',
		checked: true,
		translationId: 'common.sprint',
	},
	{
		name: 'task.sprint.dates',
		checked: false,
		translationId: 'common.sprint_dates',
		nestedOptions: [
			{
				name: 'start',
				checked: false,
				translationId: 'common.start_date',
			},
			{
				name: 'deadline',
				checked: false,
				translationId: 'common.deadline',
			},
		],
	},
	{
		name: 'task.work',
		checked: true,
		disabled: false,
		translationId: 'common.work',
		nestedOptions: [
			{
				name: 'estimate',
				checked: true,
				disabled: false,
				translationId: 'common.estimate',
				nestedOptions: [
					{
						name: 'minutes',
						checked: true,
						disabled: false,
						translationId: 'common.hours',
					},
					{
						name: 'points',
						checked: false,
						disabled: false,
						translationId: 'common.points',
					},
				],
			},
			{
				name: 'remaining',
				checked: true,
				disabled: false,
				translationId: 'common.remaining',
				nestedOptions: [
					{
						name: 'minutes',
						checked: true,
						disabled: false,
						translationId: 'common.hours',
					},
					{
						name: 'points',
						checked: false,
						disabled: false,
						translationId: 'common.points',
					},
				],
			},
			{
				name: 'difference',
				checked: false,
				disabled: false,
				translationId: 'common.difference',
				nestedOptions: [
					{
						name: 'minutes',
						checked: false,
						disabled: false,
						translationId: 'common.hours',
					},
					{
						name: 'points',
						checked: false,
						disabled: false,
						translationId: 'common.points',
					},
				],
			},
		],
	},
	{
		name: 'task.reported',
		checked: true,
		translationId: 'common.reported',
		nestedOptions: [
			{
				name: 'time',
				checked: true,
				translationId: 'common.time_entries',
			},
			{
				name: 'projected',
				checked: true,
				translationId: 'common.projected',
			},
		],
	},
	{
		name: 'task.progress',
		checked: true,
		translationId: 'common.progress',
	},
	{
		name: 'task.project.rateCard.name',
		checked: false,
		translationId: 'common.rate-card',
	},

	{
		name: 'task.financial.revenue.planned',
		translationId: hasFeatureFlag('financial_categories_update')
			? 'common.estimated_billable_value_of_service'
			: 'project_budget.planned_revenue',
		checked: true,
	},
	{
		name: 'task.financial.revenue.actual',
		translationId: hasFeatureFlag('financial_categories_update')
			? 'common.actual_billable_value_of_service'
			: 'project_budget.actual_revenue',
		checked: true,
	},
	{
		name: 'task.indicators',
		translationId: 'common.indicators',
		checked: true,
		nestedOptions: [
			{
				name: 'priority',
				checked: true,
				hide: true,
			},
			{
				name: 'bug',
				checked: true,
				hide: true,
			},
			{
				name: 'billable',
				checked: true,
				hide: true,
			},
			{
				name: 'blocked',
				checked: true,
				hide: true,
			},
		],
	},
	{
		name: 'task.approved',
		translationId: 'common.approved',
		checked: true,
	},
	{
		name: 'task.labels',
		translationId: 'common.labels',
		checked: false,
		nestedOptions: [
			{
				name: 'name',
				checked: true,
				hide: true,
			},
			{
				name: 'color',
				checked: true,
				hide: true,
			},
		],
	},
];

const financialPermissions = [PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION, PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE];

const FINANCIAL_FIELDS = ['.financial', '.rateCard', 'task.financial.revenue.actual', 'task.financial.revenue.planned'];

const NO_TIME_REG_FIELDS = ['.reported', '.difference'];

const customFieldsOtions = [
	{
		entityTypePrefix: 'company',
		entityType: 'reportCustomFieldDefinitions',
		hide: false,
		checked: false,
	},
	{
		entityTypePrefix: 'task',
		entityType: 'reportCustomFieldValues',
		hide: true,
		checked: true,
	},
];

/**
 * This will create the default exclude list based on the selected options
 * and grouping. It make sure that the hidden options are excluded
 * if not required by a group.
 *
 * @param initialEyeOptions The options
 * @param groupings The groupings
 * @param sharedView Is shared view
 * @returns {*[]} The excluded list
 */
const getExcludeList = (initialEyeOptions, groupings, sharedView) => {
	const excludeList = [];
	if (!groupings.includes(REPORT_GROUPINGS.PROJECT) && !initialEyeOptions['task.project.info']) {
		excludeList.push(
			...[
				'task.project.name',
				'task.project.projectColor',
				'task.project.companyProjectId',
				'task.project.customProjectId',
			]
		);
	}

	if (
		(!groupings.includes(REPORT_GROUPINGS.CLIENT) && !initialEyeOptions['task.project.client.info']) ||
		isFeatureHidden(HIDDEN_FEATURES.CLIENT)
	) {
		excludeList.push(...['task.project.client.name', 'task.project.client.info']);
	}

	if (!groupings.includes(REPORT_GROUPINGS.ROLE) && !initialEyeOptions['task.role.info']) {
		excludeList.push(...['task.role.name']);
	}

	if (!groupings.includes(REPORT_GROUPINGS.SPRINT) && !initialEyeOptions['task.sprint.info']) {
		excludeList.push(...['task.sprint.name']);
	}

	if (!groupings.includes(REPORT_GROUPINGS.PHASE) && !initialEyeOptions['task.phase.info']) {
		excludeList.push(...['task.phase.name']);
	}

	if (
		!initialEyeOptions['task.work.estimate.minutes'] &&
		!initialEyeOptions['task.work.estimate.points'] &&
		!initialEyeOptions['task.work.remaining.minutes'] &&
		!initialEyeOptions['task.work.remaining.points'] &&
		!initialEyeOptions['task.work.difference.minutes'] &&
		!initialEyeOptions['task.work.difference.points']
	) {
		excludeList.push('task.project.estimationUnit');
		excludeList.push('task.project.minutesPerEstimationPoint');
	}

	new Map([
		['task.work.estimate.minutes', '.taskTotals.work.estimate.minutes'],
		['task.work.estimate.points', '.taskTotals.work.estimate.points'],
		['task.work.remaining.minutes', '.taskTotals.work.remaining.minutes'],
		['task.work.remaining.points', '.taskTotals.work.remaining.points'],
		['task.financial.revenue.planned', '.taskTotals.financial.revenue.planned'],
		['task.work.estimate.minutes', '.taskTotals.work.estimate.minutes'],
		['task.financial.revenue.actual', '.taskTotals.financial.revenue.actual'],
		['task.reported.time', '.taskTotals.reported.time'],
	]).forEach((value, key) => {
		if (!initialEyeOptions[key]) {
			excludeList.push(value);
		}
	});

	const financialUser = sharedView || (hasSomePermission(financialPermissions) && CompanySetupUtil.hasFinance());
	if (!financialUser || isFeatureHidden(HIDDEN_FEATURES.REVENUE)) {
		excludeList.push(...FINANCIAL_FIELDS);
	}

	const noTimeReg = Util.isFeatureHidden(HIDDEN_FEATURES.TIME_REGISTRATIONS);
	if (noTimeReg) {
		excludeList.push(...NO_TIME_REG_FIELDS);
	}

	return excludeList;
};

export const filterObjectEyeOptionsWithCustomFields = (initialEyeOptions, groupings, sharedView, customFieldDefinitions) => {
	const excludeList = getExcludeList(initialEyeOptions, groupings, sharedView);
	return filterAndHandleEyeOptions(
		initialEyeOptions,
		addCustomFieldsEntity(theEyeOptionsNoGrouping(), customFieldDefinitions, customFieldsOtions),
		excludeList
	);
};

export const theEyeOptionsFilteredWithCustomFields = (initialEyeOptions, groupings, sharedView, customFieldDefinitions) => {
	const excludeList = getExcludeList(initialEyeOptions, groupings, sharedView);
	return filterEyeOptions(
		addCustomFieldsEntity(theEyeOptionsNoGrouping(), customFieldDefinitions, customFieldsOtions),
		excludeList,
		''
	);
};
