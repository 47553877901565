import React, {memo, useEffect, useRef} from 'react';
import styled, {css} from 'styled-components';
import {FlexRow, Icon, Text, WorkSignifier} from '@forecast-it/design-system';
import {Link} from 'react-router-dom';
import {getFormattedVariantName} from './formatters/FormatterUtil';
import {Recent} from '../../../../images/svg-collection/Recent';
import {ProjectGroupIndicatorNoFragment} from '../../../shared/components/project-indicator/ProjectGroupIndicator';
import {ProjectIndicatorNoFragment} from '../../../shared/components/project-indicator/ProjectIndicator';

export const SEARCH_RESULT_VARIANT = {
	RECENT: 'RECENT',
	SUGGESTION: 'SUGGESTION',
	TASK: 'TASK',
	PROJECT: 'PROJECT',
	PROJECT_GROUP: 'PROJECT_GROUP',
	INTERNAL_TIME: 'INTERNAL_TIME',
};

const RowWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 16px;

	border: 2px solid transparent;

	border-radius: 4px;
	padding: 0 16px;
	width: 100%;
	height: 56px;

	&:hover {
		background-color: #e5f4ff;
	}

	&:focus-within {
		background-color: transparent;
		border: 2px solid #3b94ff;
	}
`;

const RowStyle = css`
	display: flex;
	flex-grow: 1;
	height: 100%;
	gap: 16px;
	align-items: center;

	overflow: hidden;
	padding: 0;
	outline: none;

	border: none;
	border-radius: 4px;
	font-size: 16px;
`;

const LinkRow = styled(Link)`
	${RowStyle};
	color: inherit;
	text-decoration: inherit;

	&.context-menu-item {
		background-color: #e7e7f3;
	}

	:hover {
		color: inherit;
	}
`;

const ButtonRow = styled.button`
	${RowStyle};
`;

const TitleHighlightWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
	align-items: flex-start;

	overflow: hidden;

	em {
		font-style: normal;
		border-radius: 3px;
		background-color: #ebff73;
	}
`;

const Highlight = styled(Text)`
	text-transform: capitalize;
`;

const Type = styled.div`
	display: flex;
	align-items: center;

	// Display type at the end of the row
	flex: 1 0 auto;
	justify-content: flex-end;

	// Avoids the type from being cut off
	min-width: 0;
`;

const IconTitle = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const IconWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const InternalTimeIconWrapper = styled.div`
	min-width: 61px;
	display: flex;
	justify-content: center;
`;

function getProjectFromResult(result) {
	return {
		customProjectId: result.customProjectId,
		companyProjectId: result.companyProjectId,
		projectColor: result.projectColor,
		name: result.title,
	};
}
function getProjectGroupFromResult(result) {
	return {
		companyProjectGroupId: result.companyProjectId,
		color: result.projectColor,
		name: result.title,
	};
}

const SearchResultRow = memo(
	({result, allowLinks, index, focus, setFocus, isContextMenuItem, showContextMenu, showTaskIndicator}) => {
		const rowRef = useRef(null);
		const isInternalTime = result.variant === 'INTERNAL_TIME';

		const handleFocus = () => {
			if (!focus) {
				setFocus(index);
			}
		};

		const handleBlur = e => {
			// If newTarget is not a searchResultRow, reset focus to -1
			const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement;
			if (newTarget && newTarget.id === 'search-result-row') return;
			setFocus(-1);
		};

		const handleRowClick = e => {
			const isModifiedEvent = !!(e.metaKey || e.altKey || e.ctrlKey || e.shiftKey);

			result.onSelect(index, isModifiedEvent);
		};

		const handleRowAuxClick = e => {
			if (result.link && e.button === 1) {
				result.onSelect(index, true);
			}
		};

		const handleRowContextMenu = e => {
			if (result.link) {
				e.preventDefault();
				showContextMenu(e, index, result.link, () => result.onSelect(index, true));
			}
		};

		useEffect(() => {
			if (focus) {
				rowRef.current?.focus();
			}
		}, [focus]);

		const RowComponent = result.link && allowLinks ? LinkRow : ButtonRow;
		const isConnectedProject = result.variant === SEARCH_RESULT_VARIANT.PROJECT_GROUP;

		const getIndicator = (result, isConnectedProject, showTaskIndicator) => {
			if (showTaskIndicator) {
				return (
					<WorkSignifier
						workId={`T${result?.companyTaskId}`}
						name={result?.name}
						color={result?.projectColor}
						outline
					/>
				);
			} else if (result.companyProjectId && isConnectedProject) {
				return <ProjectGroupIndicatorNoFragment outline projectGroup={getProjectGroupFromResult(result)} />;
			} else if (result.companyProjectId) {
				return <ProjectIndicatorNoFragment outline project={getProjectFromResult(result)} />;
			} else {
				return null;
			}
		};

		return (
			<RowWrapper>
				{getIndicator(result, isConnectedProject, showTaskIndicator)}
				{isInternalTime && (
					<InternalTimeIconWrapper>
						<Icon icon="internalTime" size="l" />
					</InternalTimeIconWrapper>
				)}
				<RowComponent
					id={'search-result-row'}
					className={isContextMenuItem ? 'context-menu-item' : null}
					to={result.link}
					ref={rowRef}
					tabIndex={0}
					onClick={handleRowClick}
					onAuxClick={handleRowAuxClick}
					onContextMenu={handleRowContextMenu}
					onFocus={handleFocus}
					onBlur={handleBlur}
					title={result.title}
					aria-label={result.ariaLabel}
					data-cy={`search-result-${result.variant?.toLowerCase()}`}
				>
					<TitleHighlightWrapper>
						<IconTitle>
							{result.variant === SEARCH_RESULT_VARIANT.RECENT && (
								<IconWrapper>
									<Recent />
								</IconWrapper>
							)}
							<Text ellipsis>{result.name}</Text>
						</IconTitle>
						{!isInternalTime && result.highlight && (
							<FlexRow>
								<Highlight color="medium" noWrap>
									{result.highlight.highlightField}
								</Highlight>
								<Text ellipsis noWrap>
									{result.highlight.highlightValue}
								</Text>
							</FlexRow>
						)}
					</TitleHighlightWrapper>
					<Type>
						<Text color="medium">{getFormattedVariantName(result.variant)}</Text>
					</Type>
				</RowComponent>
			</RowWrapper>
		);
	}
);

export default SearchResultRow;
