import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import UpdateSubTaskMutation from '../../../../../../mutations/update_sub_task_mutation';
import DeleteSubTaskMutation from '../../../../../../mutations/delete_sub_task_mutation';
import ConvertSubTaskToTaskMutation from '../../../../../../mutations/convert_sub_task_to_task_mutation';
import Util from '../../../../util/util';
import {DATE_PICKER_STYLE, ESTIMATION_UNIT} from '../../../../../../constants';
import CheckBox from '../../../../../../components/new-ui/check_box';
import InputFieldV2 from '../../../../../../components/new-ui/input_field';
import RichTextField from '../../../../../../components/new-ui/rich_text_field';
import SubtaskAssignedDropdown from './subtask_assigned_dropdown';
import ActionMenu from '../../../action-menu/actions_menu';
import DatePicker from '../../../date-picker/date_picker_v3';
import {createToast} from '../../../toasts/another-toast/toaster';
import {dispatch, EVENT_ID} from '../../../../../../containers/event_manager';
import HoursInput from '../../../inputs/hours-input/hours_input_view';
import {profilePicSrc} from '../../../../../../directApi';

class Subtask extends Component {
	constructor(props) {
		super(props);

		this.state = {
			nameInput: props.subTask.name,
			estimateInput:
				props.project.estimationUnit === ESTIMATION_UNIT.HOURS ? props.subTask.estimate / 60.0 : props.subTask.estimate,
			notesExpanded: false,
			startDay: props.subTask.startDay,
			startMonth: props.subTask.startMonth,
			startYear: props.subTask.startYear,
			endDay: props.subTask.endDay,
			endMonth: props.subTask.endMonth,
			endYear: props.subTask.endYear,
			contextMenuHovered: false,
			taskNameFocus: false,
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.state.notesExpanded && !this.props.draggingId && nextProps.draggingId === this.props.subTask.id) {
			this.setState({notesExpanded: false});
		}
		if (nextProps.subTask.name !== this.state.nameInput && !this.state.taskNameFocus) {
			this.setState({nameInput: nextProps.subTask.name});
		}
	}

	toggleChecked() {
		Util.CommitSchedulingModalUpdate(UpdateSubTaskMutation, {
			id: this.props.subTask.id,
			done: !this.props.subTask.done,
		});

		if (!this.props.subTask.done && !this.props.viewer.client) {
			dispatch(EVENT_ID.SUBTASK_MARKED_AS_DONE, this.props.task.id, this.props.subTask.estimate);
		}
	}

	toggleExpandNotes() {
		this.setState({notesExpanded: !this.state.notesExpanded});
	}

	handleNameUpdate() {
		this.setState({taskNameFocus: false});
		if (this.props.subTask.name !== this.state.nameInput) {
			Util.CommitSchedulingModalUpdate(UpdateSubTaskMutation, {
				id: this.props.subTask.id,
				name: this.state.nameInput,
			});
		}
	}

	handleEstimateUpdate(value) {
		this.setState({estimateInput: value});

		if (this.props.subTask.estimate !== this.state.estimateInput) {
			const hourEstimated = this.props.project.estimationUnit === ESTIMATION_UNIT.HOURS;

			const estimate = hourEstimated ? value * 60 : this.state.estimateInput || 0;
			Util.CommitSchedulingModalUpdate(UpdateSubTaskMutation, {
				id: this.props.subTask.id,
				estimate: +estimate,
			});

			dispatch(EVENT_ID.SUBTASK_ESTIMATE_UPDATE, this.props.task.id);
		}
		if (!this.state.estimateInput || this.state.estimateInput === '0') {
			this.setState({estimateInput: null});
		}
	}

	handleDescriptionUpdate(text) {
		if (this.props.subTask.description !== text) {
			Util.CommitMutation(UpdateSubTaskMutation, {
				id: this.props.subTask.id,
				description: text,
			});
		}
	}

	handleDateRangeChange(startDate, endDate) {
		Util.CommitMutation(UpdateSubTaskMutation, {
			id: this.props.subTask.id,
			startYear: startDate.year(),
			startMonth: startDate.month() + 1,
			startDay: startDate.date(),
			endYear: endDate.year(),
			endMonth: endDate.month() + 1,
			endDay: endDate.date(),
		});
	}

	clearBothDates() {
		Util.CommitMutation(UpdateSubTaskMutation, {
			id: this.props.subTask.id,
			startYear: null,
			startMonth: null,
			startDay: null,
			endYear: null,
			endMonth: null,
			endDay: null,
		});
	}

	deleteSubTask(subTaskId) {
		Util.CommitMutation(
			DeleteSubTaskMutation,
			{
				id: subTaskId,
				taskId: this.props.task.id,
			},
			() => {
				createToast({
					duration: 5000,
					message: this.props.intl.formatMessage({id: 'task_modal.subtask-has-been-deleted'}),
				});
			}
		);

		dispatch(EVENT_ID.SUBTASK_ESTIMATE_UPDATE, this.props.task.id);
	}

	convertToTask() {
		Util.CommitMutation(
			ConvertSubTaskToTaskMutation,
			{
				id: this.props.subTask.id,
				projectId: this.props.project.id,
				parentTaskId: this.props.task.id,
			},
			() => {
				createToast({
					duration: 5000,
					message: this.props.intl.formatMessage({id: 'task_modal.subtask_converted'}),
				});
			}
		);
	}

	handleNameBlur() {
		this._name_input._input_field_.blur();
	}

	handleEstimateBlur() {
		this._estimate_input._input_field_.blur();
	}

	onContextMenuEnter() {
		this.setState({contextMenuHovered: true});
	}

	onContextMenuLeave() {
		this.setState({contextMenuHovered: false});
	}

	render() {
		const {provided, innerRef, subTask, project, isTwoLevelSubTask, disabled, viewer, task} = this.props;
		const {formatMessage} = this.props.intl;

		const startDate = Util.CreateNonUtcMomentDate(subTask.startYear, subTask.startMonth, subTask.startDay);
		const endDate = Util.CreateNonUtcMomentDate(subTask.endYear, subTask.endMonth, subTask.endDay);

		const taskStartDate = Util.CreateNonUtcMomentDate(task.startYear, task.startMonth, task.startDay);
		const taskEndDate = Util.CreateNonUtcMomentDate(task.deadlineYear, task.deadlineMonth, task.deadlineDay);

		const hourEstimated = this.props.project.estimationUnit === 'HOURS';
		const estimationUnit = hourEstimated
			? formatMessage({id: 'common.hours.short'})
			: formatMessage({id: 'common.points.short'});

		const actionMenuOptions = [
			{
				text: formatMessage({id: 'common.delete'}),
				onClick: this.deleteSubTask.bind(this, subTask.id),
				cy: `option-delete`,
			},
		];

		if (!this.props.task.project.vstsProject) {
			actionMenuOptions.push({
				text: formatMessage({id: 'common.convert_to_task'}),
				onClick: this.convertToTask.bind(this),
				cy: `option-create-task`,
			});
		}

		const {useDetailedSubtasks} = this.props.viewer;

		const mentions =
			project.projectPersons &&
			project.projectPersons.edges
				.filter(projectPerson => {
					return projectPerson.node.person.active;
				})
				.map(projectPerson => {
					return {
						personId: projectPerson.node.person.id,
						name: projectPerson.node.person.firstName + ' ' + projectPerson.node.person.lastName,
						avatar: profilePicSrc(projectPerson.node.person.profilePictureId),
					};
				});

		const mentionsLabels = viewer.company.labels.edges.map(label => {
			return {id: label.node.id, name: label.node.name};
		});

		return (
			<div>
				<div
					className={
						'subtask top-row' +
						(!isTwoLevelSubTask ? ' is-to-do' : '') +
						(this.props.draggingId === null ? ' not-dragging' : '') +
						(subTask.done ? ' done' : '')
					}
					onMouseEnter={this.onContextMenuEnter.bind(this)}
					onMouseLeave={this.onContextMenuLeave.bind(this)}
					ref={innerRef}
					{...provided.draggableProps}
					style={this.props.style}
				>
					<div className="subtask-item done-toggle">
						<CheckBox
							customClasses={'subtask-done-status'}
							isDisabled={disabled}
							isChecked={subTask.done}
							onChange={this.toggleChecked.bind(this, subTask)}
							large={true}
							green={true}
						/>
					</div>
					<div className={'subtask-item name' + (isTwoLevelSubTask ? ' no-margin-right' : '')}>
						<div
							className={'drag-handle' + (this.props.draggingId === subTask.id ? ' show' : '')}
							{...provided.dragHandleProps}
						/>
						<InputFieldV2
							id={subTask.id}
							hideLabel={true}
							locked={disabled}
							value={this.state.nameInput}
							onChange={text => this.setState({nameInput: text || '', taskNameFocus: true})}
							onBlur={this.handleNameUpdate.bind(this)}
							type="text"
							onEnter={this.handleNameBlur.bind(this)}
							ref={e => (this._name_input = e)}
							customClass={'task-modal-subtask-input no-border' + (subTask.done ? ' completed' : '')}
							placeholder={''}
							cy={this.props.cy}
							maxLength={191}
							title={this.state.nameInput}
						/>
					</div>

					{isTwoLevelSubTask ? (
						<>
							<div className="subtask-item notes">
								{/* TODO: Description can be an empty string, but can't check because of the weird structure */}
								<button
									onClick={this.toggleExpandNotes.bind(this)}
									className={
										'notes-button' +
										(subTask.done ? ' disabled' : '') +
										(subTask.description !== null ? ' has-notes' : '') +
										(this.state.notesExpanded ? ' expanded' : '')
									}
								/>
							</div>
							<div className="subtask-item hours-estimated">
								{hourEstimated ? (
									<HoursInput
										id="remaining-input-field"
										value={this.state.estimateInput}
										mutation={v => this.handleEstimateUpdate(v)}
										mutationValidChange={true}
										disabled={disabled}
									></HoursInput>
								) : (
									<InputFieldV2
										id={subTask.id}
										hideLabel={true}
										locked={disabled}
										value={this.state.estimateInput || ''}
										onChange={text => this.setState({estimateInput: text || ''})}
										onBlur={this.handleEstimateUpdate.bind(this, this.state.estimateInput)}
										maxLength={6}
										type="number"
										onEnter={this.handleEstimateBlur.bind(this)}
										ref={e => (this._estimate_input = e)}
										suffix={estimationUnit}
										customClass={'task-modal-subtask-input'}
										placeholder="0" // if the estimate is no greater than 0 add 0 as placeholder
										alignTextRight={true}
										title={this.state.nameInput}
									/>
								)}
							</div>

							{useDetailedSubtasks ? (
								<>
									<div className="subtask-item to-from-date">
										<DatePicker
											disabled={disabled}
											startDate={subTask.startFrom === 'TASK' ? taskStartDate : startDate}
											endDate={subTask.endFrom === 'TASK' ? taskEndDate : endDate}
											handleDateRangeChange={this.handleDateRangeChange.bind(this)}
											datePickerStyle={DATE_PICKER_STYLE.COMPACT}
											clearable={true}
											clearBothDates={this.clearBothDates.bind(this)}
											collapseOnWheel={true}
										/>
									</div>

									<div className="subtask-item assign-person">
										<SubtaskAssignedDropdown
											viewer={this.props.viewer}
											task={this.props.task}
											project={this.props.project}
											taskId={this.props.task.id}
											subtask={subTask}
											disabled={disabled}
										/>
									</div>
								</>
							) : null}
						</>
					) : null}

					{!disabled ? (
						<div className={'subtask-item context-menu expanded'} data-cy="subtask-action-menu">
							<ActionMenu
								disabled={disabled}
								options={actionMenuOptions}
								cy={`${this.props.cy}-action-menu`}
								whiteInner={true}
							/>
						</div>
					) : null}
				</div>
				<div className={'notes-description' + (this.state.notesExpanded ? ' expanded' : '')}>
					<RichTextField
						mentions={mentions}
						mentionsLabels={mentionsLabels}
						text={subTask.description || undefined}
						readOnly={disabled || this.props.subTask.done}
						alwaysShowControls={true}
						ref={element => (this.subTaskDescription = element)}
						handleTextChange={this.handleDescriptionUpdate.bind(this)}
						//ignoreComponentWillReceiveProps={false}
					/>
				</div>
			</div>
		);
	}
}

export default injectIntl(Subtask);
