import { useIntl } from 'react-intl';
import { isDaySelected, onKeyboardEventHandler, workingHourForTheDay } from '../TimesheetsCalendarUtil';
import { format, getWeek, isBefore, isSameDay, isToday } from 'date-fns';
import { CellText, CellTextBold, DayCellStyle, DayInfoWrapper } from '../TimesheetsCalendar_styled';
import { TodayText } from './DayCell_styled';
import { FlexColumn, FlexRow, Icon } from '@forecast-it/design-system';
import React, { useEffect, useRef, useState } from 'react';
import { getTimeRegistrationsOfTheDay, separateWorkingTimeAndTimeOff } from '../../timesheets_time_registrations';
import Util from '../../../../shared/util/util';
import { CalendarEvents } from '../calendar-events/CalendarEvents';
export const DayCell = ({ timeRegistrations, currentPersonViewed, selectedDate, selectDateFromCalendar, lockedDate, day, isDayOutsideViewingMonth, holidaysOfTheDay, calendarEvents, isEventConverted, isLoadingCalendarEvents, currentViewingDate, openModal, showContextMenu, }) => {
    const intl = useIntl();
    const ref = useRef(null);
    const timeRegistrationsForDay = getTimeRegistrationsOfTheDay(day, timeRegistrations.edges);
    const workingHours = workingHourForTheDay(currentPersonViewed, day);
    const timeWorked = separateWorkingTimeAndTimeOff(timeRegistrationsForDay);
    const totalTime = timeWorked.timeOff + timeWorked.workingTime;
    const isDayFullTimeOff = timeWorked.timeOff > 0 && workingHours - timeWorked.timeOff <= 0;
    const totalTimeInHoursFormatted = Util.convertMinutesToFullHour(totalTime, intl, true);
    const workingHoursFormatted = Util.convertMinutesToFullHour(workingHours, intl, true);
    const daySelected = isDaySelected(selectedDate, day);
    const isHoliday = holidaysOfTheDay.length > 0;
    const isLocked = lockedDate && (isSameDay(day, lockedDate) || isBefore(day, lockedDate));
    const isDayToday = isToday(day);
    const checkMarkColor = isLocked ? 'disabledOnDisabled' : isDayOutsideViewingMonth ? 'disabled' : 'success';
    const isWeekSelected = getWeek(currentViewingDate) === getWeek(day);
    const showCheckMark = !isDayFullTimeOff && totalTime >= workingHours && workingHours > 0;
    const isNonWorkingDay = isHoliday || isDayFullTimeOff || workingHours <= 0;
    const showTotalTime = timeRegistrationsForDay.length > 0;
    const showTimeSection = showCheckMark || showTotalTime;
    const showLockIcon = isLocked;
    const showTimeOffIcon = (isDayFullTimeOff || isHoliday) && !isLocked;
    const [isSmallCell, setIsSmallCell] = useState(false);
    let textColor = 'default';
    if (isDayOutsideViewingMonth) {
        textColor = 'onLight';
    }
    else if (isLocked) {
        textColor = 'disabledOnDisabled';
    }
    const date = day.getDate();
    const handleResize = () => {
        var _a, _b;
        const width = (_b = (_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect()) === null || _b === void 0 ? void 0 : _b.width;
        if (width && width < 300) {
            setIsSmallCell(true);
        }
        else if (width && width >= 300) {
            setIsSmallCell(false);
        }
    };
    useEffect(() => {
        handleResize();
    }, []);
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (React.createElement(DayCellStyle, { ref: ref, tabIndex: 0, hoverable: true, showCheckMark: showCheckMark, nonWorkingDay: isNonWorkingDay, locked: isLocked, selected: daySelected, onClick: () => selectDateFromCalendar(day, false), onKeyDown: event => onKeyboardEventHandler(event, day, false, selectDateFromCalendar) },
        React.createElement(FlexColumn, { gap: "s" },
            React.createElement(DayInfoWrapper, { isDayToday: isDayToday },
                React.createElement(FlexRow, { justifyContent: "space-between" },
                    React.createElement(FlexRow, { flex: "0 0 auto", alignItems: 'center' },
                        isDayToday ? React.createElement(TodayText, null, date) : React.createElement(CellTextBold, { color: textColor }, date),
                        date === 1 ? React.createElement(CellTextBold, { color: textColor }, format(day, 'MMM')) : null,
                        showLockIcon ? React.createElement(Icon, { color: 'disabledOnDisabled', icon: "lockClosed" }) : null,
                        showTimeOffIcon ? React.createElement(Icon, { color: 'disabledOnDisabled', icon: "timeOff" }) : null),
                    isHoliday ? (React.createElement(FlexRow, { flex: '1 0', justifyContent: showTimeSection ? 'center' : 'end' },
                        React.createElement(CellText, { color: 'disabledOnDisabled' }, holidaysOfTheDay[0].node.name))) : null,
                    showTimeSection ? (React.createElement(FlexRow, { flex: "0 0 auto", justifyContent: 'end' },
                        React.createElement(FlexRow, { flex: '0 0 auto' },
                            React.createElement(CellText, { color: textColor },
                                showTotalTime ? `${totalTimeInHoursFormatted}` : null,
                                !isSmallCell && !isNonWorkingDay ? ` / ${workingHoursFormatted}` : null)),
                        React.createElement(FlexRow, { flex: '0 0 auto' }, showCheckMark ? React.createElement(Icon, { color: checkMarkColor, icon: "checkCircle" }) : null))) : null)),
            React.createElement(CalendarEvents, { calendarEvents: calendarEvents, isEventConverted: isEventConverted, isLocked: isLocked, renderPlaceholders: isLoadingCalendarEvents && isWeekSelected, openModal: openModal, showContextMenu: showContextMenu }))));
};
export default DayCell;
