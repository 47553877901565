import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import TaskModalFile from './files_section_file';
import Util from '../../../util/util';

class FilesSection extends Component {
	render() {
		const {task, isTaskReadOnly} = this.props;
		const {files, googleDriveFiles} = task;
		const filesPreviewMap = Util.getFilesPreviewableMap(files);

		if (task.files == null || googleDriveFiles == null) {
			return <div className={'error-files-empty'} />;
		}

		//Don't render the section if there are no files
		if ((filesPreviewMap == null || filesPreviewMap.length < 1) && !googleDriveFiles.edges.length) return null;

		return (
			<div className="task-modal-v3-files-section" data-cy="task-modal-files-section">
				<div className="files-section-content">
					<div className="files-list" data-cy="task-modal-files-list">
						{filesPreviewMap.map(
							fp =>
								fp.file && (
									<TaskModalFile
										key={fp.file.id}
										isTaskReadOnly={isTaskReadOnly}
										file={fp.file}
										task={task}
										showPreview={fp.showPreview}
									/>
								)
						)}
						{googleDriveFiles.edges.map(fileEdge => (
							<TaskModalFile
								key={fileEdge.node.id}
								isTaskReadOnly={isTaskReadOnly}
								file={fileEdge.node}
								task={task}
								gDriveFile={true}
							/>
						))}
					</div>
				</div>
			</div>
		);
	}
}

export default injectIntl(FilesSection);
