/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type DeprecatedProjectIndicatorJS_project$ref = any;
export type CANT_DELETE_TASK_REASON = "BLOCKED_BY_SUBTASK" | "GITHUB" | "HAS_INVOICED_TIME" | "HAS_LOCKED_TIME" | "HAS_OTHER_PEOPLES_TIME_REGS" | "HAS_TIME_REGS" | "JIRA" | "NO_USER_TYPE" | "UNIT4" | "%future added value";
export type DEPENDENCY_TYPE = "CANNOT_BE_COMPLETED" | "CANNOT_START" | "%future added value";
export type PROGRAM_BUDGET_TYPE = "CAPPED" | "FIXED_PRICE" | "UNCAPPED" | "%future added value";
export type PROJECT_STATUS = "DONE" | "HALTED" | "OPPORTUNITY" | "PLANNING" | "RUNNING" | "%future added value";
export type STATUS_CATEGORY = "DONE" | "INPROGRESS" | "TODO" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type taskModalV3_task$ref: FragmentReference;
declare export opaque type taskModalV3_task$fragmentType: taskModalV3_task$ref;
export type taskModalV3_task = {|
  +id: string,
  +userCanDeleteTask: ?boolean,
  +userCantDeleteTaskReason: ?CANT_DELETE_TASK_REASON,
  +fullAccessToProject: ?boolean,
  +approved: ?boolean,
  +name: ?string,
  +readOnly: ?{|
    +isReadOnly: ?boolean
  |},
  +coverFile: ?{|
    +id: string
  |},
  +parentTaskId: ?string,
  +parentTask: ?{|
    +id: string,
    +companyTaskId: ?number,
    +name: ?string,
    +parentTask: ?{|
      +id: string,
      +companyTaskId: ?number,
      +name: ?string,
    |},
  |},
  +hasChildren: ?boolean,
  +companyTaskId: ?number,
  +blocked: ?boolean,
  +bug: ?boolean,
  +billable: ?boolean,
  +highPriority: ?boolean,
  +startYear: ?number,
  +startMonth: ?number,
  +startDay: ?number,
  +description: ?string,
  +jiraId: ?string,
  +jiraKey: ?string,
  +gitlabIid: ?string,
  +vstsId: ?string,
  +pipedriveId: ?string,
  +isUnit4Task: ?boolean,
  +estimateForecast: ?number,
  +timeLeft: ?number,
  +deadlineDay: ?number,
  +deadlineMonth: ?number,
  +deadlineYear: ?number,
  +startFrom: ?string,
  +deadlineFrom: ?string,
  +gitlabServerIid: ?string,
  +hasLockedTime: ?boolean,
  +hasInvoicedTime: ?boolean,
  +favoured: ?boolean,
  +progressDetails: ?{|
    +progress: ?number
  |},
  +followers: ?$ReadOnlyArray<?{|
    +id: string,
    +firstName: ?string,
    +lastName: ?string,
    +profilePictureId: ?string,
    +profilePictureDefaultId: ?number,
  |}>,
  +taskLabels: ?$ReadOnlyArray<?{|
    +label: ?{|
      +id: string,
      +name: ?string,
      +color: ?string,
    |}
  |}>,
  +subTasks: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +estimate: ?number,
        +done: ?boolean,
        +sortOrder: ?number,
        +name: ?string,
        +description: ?string,
        +startDay: ?number,
        +startMonth: ?number,
        +startYear: ?number,
        +endDay: ?number,
        +endMonth: ?number,
        +endYear: ?number,
        +startFrom: ?string,
        +endFrom: ?string,
        +role: ?{|
          +id: string,
          +name: ?string,
        |},
        +person: ?{|
          +id: string,
          +firstName: ?string,
          +lastName: ?string,
          +active: ?boolean,
          +profilePictureId: ?string,
          +profilePictureDefaultId: ?number,
        |},
      |}
    |}>
  |},
  +thisTaskDependsOn: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +type: ?DEPENDENCY_TYPE,
        +completed: ?boolean,
        +taskDependsOnThis: ?{|
          +id: string,
          +name: ?string,
          +companyTaskId: ?number,
          +startYear: ?number,
          +startMonth: ?number,
          +startDay: ?number,
          +deadlineDay: ?number,
          +deadlineMonth: ?number,
          +deadlineYear: ?number,
          +statusColumnV2: ?{|
            +id: string,
            +category: ?STATUS_CATEGORY,
          |},
        |},
        +thisDependsOnTask: ?{|
          +id: string,
          +name: ?string,
          +companyTaskId: ?number,
          +startYear: ?number,
          +startMonth: ?number,
          +startDay: ?number,
          +deadlineDay: ?number,
          +deadlineMonth: ?number,
          +deadlineYear: ?number,
          +statusColumnV2: ?{|
            +id: string,
            +category: ?STATUS_CATEGORY,
          |},
        |},
      |}
    |}>
  |},
  +dependsOnThisTask: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +type: ?DEPENDENCY_TYPE,
        +completed: ?boolean,
        +taskDependsOnThis: ?{|
          +id: string,
          +name: ?string,
          +companyTaskId: ?number,
          +startYear: ?number,
          +startMonth: ?number,
          +startDay: ?number,
          +deadlineDay: ?number,
          +deadlineMonth: ?number,
          +deadlineYear: ?number,
          +statusColumnV2: ?{|
            +id: string,
            +category: ?STATUS_CATEGORY,
          |},
        |},
        +thisDependsOnTask: ?{|
          +id: string,
          +name: ?string,
          +companyTaskId: ?number,
          +startYear: ?number,
          +startMonth: ?number,
          +startDay: ?number,
          +deadlineDay: ?number,
          +deadlineMonth: ?number,
          +deadlineYear: ?number,
          +statusColumnV2: ?{|
            +id: string,
            +category: ?STATUS_CATEGORY,
          |},
        |},
      |}
    |}>
  |},
  +repeatingTask: ?{|
    +id: string,
    +repeatType: ?string,
    +monday: ?boolean,
    +tuesday: ?boolean,
    +wednesday: ?boolean,
    +thursday: ?boolean,
    +friday: ?boolean,
    +saturday: ?boolean,
    +sunday: ?boolean,
    +monthlyDay: ?number,
  |},
  +statusColumnV2: ?{|
    +id: string,
    +name: ?string,
    +category: ?STATUS_CATEGORY,
    +projectGroupStatusColumnId: ?number,
  |},
  +phase: ?{|
    +id: string,
    +name: ?string,
    +startYear: ?number,
    +startMonth: ?number,
    +startDay: ?number,
    +deadlineDay: ?number,
    +deadlineMonth: ?number,
    +deadlineYear: ?number,
  |},
  +sprint: ?{|
    +id: string,
    +name: ?string,
    +startYear: ?number,
    +startMonth: ?number,
    +startDay: ?number,
    +endDay: ?number,
    +endMonth: ?number,
    +endYear: ?number,
  |},
  +assignedPersons: ?$ReadOnlyArray<?{|
    +id: string,
    +firstName: ?string,
    +lastName: ?string,
    +role: ?{|
      +id: string,
      +name: ?string,
    |},
    +profilePictureId: ?string,
    +profilePictureDefaultId: ?number,
    +email: ?string,
  |}>,
  +timeRegistrations: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +invoiced: ?boolean,
        +xeroInvoiceId: ?string,
        +minutesRegistered: ?number,
        +lockedInPeriod: ?boolean,
      |}
    |}>
  |},
  +role: ?{|
    +id: string,
    +name: ?string,
  |},
  +googleDriveFiles: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: ?string,
        +name: ?string,
        +link: ?string,
        +thumb: ?string,
      |}
    |}>
  |},
  +files: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +mimeType: ?string,
        +key: ?string,
        +size: ?number,
        +yearCreated: ?number,
        +monthCreated: ?number,
        +dayCreated: ?number,
        +hourCreated: ?number,
        +minuteCreated: ?number,
        +secondCreated: ?number,
        +person: ?{|
          +id: string,
          +fullName: ?string,
        |},
      |}
    |}>
  |},
  +commentCount: ?number,
  +comments: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +comment: ?string,
        +year: ?number,
        +month: ?number,
        +day: ?number,
        +hours: ?number,
        +minutes: ?number,
        +seconds: ?number,
        +person: ?{|
          +id: string,
          +firstName: ?string,
          +lastName: ?string,
          +fullName: ?string,
          +profilePictureId: ?string,
          +profilePictureDefaultId: ?number,
        |},
      |}
    |}>
  |},
  +createdAt: ?string,
  +createdBy: ?{|
    +id: string,
    +firstName: ?string,
    +lastName: ?string,
  |},
  +latestUiUpdateAt: ?string,
  +latestUiUpdateBy: ?{|
    +id: string,
    +firstName: ?string,
    +lastName: ?string,
  |},
  +owner: ?{|
    +id: string,
    +firstName: ?string,
    +lastName: ?string,
    +fullName: ?string,
    +profilePictureId: ?string,
    +profilePictureDefaultId: ?number,
    +role: ?{|
      +id: string,
      +name: ?string,
    |},
  |},
  +project: ?{|
    +id: string,
    +name: ?string,
    +useTaskOwner: ?boolean,
    +useTaskFollowers: ?boolean,
    +useTaskHierarchy: ?boolean,
    +projectStartYear: ?number,
    +projectStartMonth: ?number,
    +projectStartDay: ?number,
    +projectEndYear: ?number,
    +projectEndMonth: ?number,
    +projectEndDay: ?number,
    +status: ?PROJECT_STATUS,
    +projectColor: ?string,
    +isJiraProject: ?boolean,
    +jiraSubtaskType: ?string,
    +jiraCloudProject: ?{|
      +id: string
    |},
    +jiraCloudEpicIds: ?$ReadOnlyArray<?string>,
    +jiraServerProject: ?{|
      +id: string
    |},
    +gitlabProjectName: ?string,
    +gitlabServerProjectName: ?string,
    +projectGroupId: ?string,
    +billable: ?boolean,
    +taskLevels: ?number,
    +companyProjectId: ?number,
    +customProjectId: ?string,
    +isProgramRevenueLocked: ?boolean,
    +programBudgetType: ?PROGRAM_BUDGET_TYPE,
    +program: ?{|
      +name: ?string,
      +prefix: ?string,
      +budgetType: ?PROGRAM_BUDGET_TYPE,
      +members: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +role: ?string,
            +person: ?{|
              +id: string
            |},
          |}
        |}>
      |},
    |},
    +harvestProject: ?{|
      +id: string
    |},
    +manualProgressOnProjectEnabled: ?boolean,
    +manualProgressOnPhasesEnabled: ?boolean,
    +manualProgressOnTasksEnabled: ?boolean,
    +vstsProject: ?string,
    +vstsAccount: ?string,
    +vstsTwoWaySync: ?boolean,
    +sprintTimeBox: ?boolean,
    +estimationUnit: ?string,
    +remainingAutoCalculated: ?boolean,
    +rateCard: ?{|
      +id: string,
      +disabledRoles: ?$ReadOnlyArray<?{|
        +id: string
      |}>,
    |},
    +projectPersons: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +person: ?{|
            +id: string,
            +firstName: ?string,
            +lastName: ?string,
            +fullName: ?string,
            +profilePictureId: ?string,
            +profilePictureDefaultId: ?number,
            +active: ?boolean,
            +permissions: ?$ReadOnlyArray<?string>,
            +harvestUser: ?boolean,
          |},
          +project: ?{|
            +id: string
          |},
          +role: ?{|
            +id: string,
            +name: ?string,
          |},
        |}
      |}>
    |},
    +client: ?{|
      +id: string,
      +name: ?string,
      +logoId: ?string,
      +logoDefaultId: ?number,
    |},
    +phases: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +startYear: ?number,
          +startMonth: ?number,
          +startDay: ?number,
          +deadlineYear: ?number,
          +deadlineMonth: ?number,
          +deadlineDay: ?number,
        |}
      |}>
    |},
    +statusColumnsV2: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +category: ?STATUS_CATEGORY,
          +order: ?number,
          +jiraStatusId: ?string,
          +adoState: ?string,
          +projectGroupStatusColumnId: ?number,
          +userActions: ?{|
            +canDelete: ?boolean,
            +canDeleteWithTasks: ?boolean,
            +canCreateTask: ?boolean,
            +canRename: ?boolean,
          |},
        |}
      |}>
    |},
    +sprints: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +startYear: ?number,
          +startMonth: ?number,
          +startDay: ?number,
          +endYear: ?number,
          +endMonth: ?number,
          +endDay: ?number,
        |}
      |}>
    |},
    +$fragmentRefs: DeprecatedProjectIndicatorJS_project$ref,
  |},
  +$refType: taskModalV3_task$ref,
|};
export type taskModalV3_task$data = taskModalV3_task;
export type taskModalV3_task$key = {
  +$data?: taskModalV3_task$data,
  +$fragmentRefs: taskModalV3_task$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyTaskId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billable",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startYear",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startMonth",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDay",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineDay",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineMonth",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineYear",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startFrom",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePictureId",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePictureDefaultId",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDay",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endMonth",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endYear",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "Role",
  "kind": "LinkedField",
  "name": "role",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/)
  ],
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "category",
  "storageKey": null
},
v26 = [
  (v0/*: any*/),
  (v1/*: any*/),
  (v3/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/),
  (v11/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "StatusColumnV2",
    "kind": "LinkedField",
    "name": "statusColumnV2",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      (v25/*: any*/)
    ],
    "storageKey": null
  }
],
v27 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "DependencyTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DependencyType",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "completed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "taskDependsOnThis",
            "plural": false,
            "selections": (v26/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "thisDependsOnTask",
            "plural": false,
            "selections": (v26/*: any*/),
            "storageKey": null
          },
          (v22/*: any*/)
        ],
        "storageKey": null
      },
      (v23/*: any*/)
    ],
    "storageKey": null
  },
  (v24/*: any*/)
],
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectGroupStatusColumnId",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v30 = [
  (v0/*: any*/),
  (v13/*: any*/),
  (v14/*: any*/)
],
v31 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "subTasks"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "thisTaskDependsOn"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "dependsOnThisTask"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "timeRegistrations"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "googleDriveFiles"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "files"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "comments"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "project",
          "projectPersons"
        ]
      }
    ]
  },
  "name": "taskModalV3_task",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userCanDeleteTask",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userCantDeleteTaskReason",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullAccessToProject",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "approved",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskReadOnly",
      "kind": "LinkedField",
      "name": "readOnly",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isReadOnly",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "coverFile",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parentTaskId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Task",
      "kind": "LinkedField",
      "name": "parentTask",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v3/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Task",
          "kind": "LinkedField",
          "name": "parentTask",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v3/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasChildren",
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "blocked",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bug",
      "storageKey": null
    },
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "highPriority",
      "storageKey": null
    },
    (v5/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/),
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jiraId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jiraKey",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gitlabIid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vstsId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pipedriveId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isUnit4Task",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "estimateForecast",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeLeft",
      "storageKey": null
    },
    (v9/*: any*/),
    (v10/*: any*/),
    (v11/*: any*/),
    (v12/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deadlineFrom",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gitlabServerIid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasLockedTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasInvoicedTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "favoured",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskProgress",
      "kind": "LinkedField",
      "name": "progressDetails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "progress",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Person",
      "kind": "LinkedField",
      "name": "followers",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v13/*: any*/),
        (v14/*: any*/),
        (v15/*: any*/),
        (v16/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskLabel",
      "kind": "LinkedField",
      "name": "taskLabels",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Label",
          "kind": "LinkedField",
          "name": "label",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "color",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "subTasks",
      "args": null,
      "concreteType": "SubTaskTypeConnection",
      "kind": "LinkedField",
      "name": "__Task_subTasks_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SubTaskTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SubTask",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "estimate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "done",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sortOrder",
                  "storageKey": null
                },
                (v1/*: any*/),
                (v8/*: any*/),
                (v7/*: any*/),
                (v6/*: any*/),
                (v5/*: any*/),
                (v17/*: any*/),
                (v18/*: any*/),
                (v19/*: any*/),
                (v12/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endFrom",
                  "storageKey": null
                },
                (v20/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Person",
                  "kind": "LinkedField",
                  "name": "person",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v13/*: any*/),
                    (v14/*: any*/),
                    (v21/*: any*/),
                    (v15/*: any*/),
                    (v16/*: any*/)
                  ],
                  "storageKey": null
                },
                (v22/*: any*/)
              ],
              "storageKey": null
            },
            (v23/*: any*/)
          ],
          "storageKey": null
        },
        (v24/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": "thisTaskDependsOn",
      "args": null,
      "concreteType": "DependencyTypeConnection",
      "kind": "LinkedField",
      "name": "__Task_thisTaskDependsOn_connection",
      "plural": false,
      "selections": (v27/*: any*/),
      "storageKey": null
    },
    {
      "alias": "dependsOnThisTask",
      "args": null,
      "concreteType": "DependencyTypeConnection",
      "kind": "LinkedField",
      "name": "__Task_dependsOnThisTask_connection",
      "plural": false,
      "selections": (v27/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RepeatingTask",
      "kind": "LinkedField",
      "name": "repeatingTask",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "repeatType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "monday",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tuesday",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "wednesday",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thursday",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "friday",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "saturday",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sunday",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "monthlyDay",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StatusColumnV2",
      "kind": "LinkedField",
      "name": "statusColumnV2",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v25/*: any*/),
        (v28/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PhaseType",
      "kind": "LinkedField",
      "name": "phase",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        (v7/*: any*/),
        (v9/*: any*/),
        (v10/*: any*/),
        (v11/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Sprint",
      "kind": "LinkedField",
      "name": "sprint",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        (v7/*: any*/),
        (v17/*: any*/),
        (v18/*: any*/),
        (v19/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Person",
      "kind": "LinkedField",
      "name": "assignedPersons",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v13/*: any*/),
        (v14/*: any*/),
        (v20/*: any*/),
        (v15/*: any*/),
        (v16/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "timeRegistrations",
      "args": null,
      "concreteType": "TimeRegTypeConnection",
      "kind": "LinkedField",
      "name": "__Task_timeRegistrations_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TimeRegTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TimeRegType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "invoiced",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "xeroInvoiceId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "minutesRegistered",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lockedInPeriod",
                  "storageKey": null
                },
                (v22/*: any*/)
              ],
              "storageKey": null
            },
            (v23/*: any*/)
          ],
          "storageKey": null
        },
        (v24/*: any*/)
      ],
      "storageKey": null
    },
    (v20/*: any*/),
    {
      "alias": "googleDriveFiles",
      "args": null,
      "concreteType": "GoogleDriveFileTypeConnection",
      "kind": "LinkedField",
      "name": "__Task_googleDriveFiles_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GoogleDriveFileTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "GoogleDriveFile",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "link",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "thumb",
                  "storageKey": null
                },
                (v22/*: any*/)
              ],
              "storageKey": null
            },
            (v23/*: any*/)
          ],
          "storageKey": null
        },
        (v24/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": "files",
      "args": null,
      "concreteType": "FileTypeConnection",
      "kind": "LinkedField",
      "name": "__Task_files_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FileTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "File",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "mimeType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "key",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "size",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "yearCreated",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "monthCreated",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "dayCreated",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hourCreated",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "minuteCreated",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "secondCreated",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Person",
                  "kind": "LinkedField",
                  "name": "person",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v29/*: any*/)
                  ],
                  "storageKey": null
                },
                (v22/*: any*/)
              ],
              "storageKey": null
            },
            (v23/*: any*/)
          ],
          "storageKey": null
        },
        (v24/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commentCount",
      "storageKey": null
    },
    {
      "alias": "comments",
      "args": null,
      "concreteType": "CommentTypeConnection",
      "kind": "LinkedField",
      "name": "__Task_comments_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CommentTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CommentType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "comment",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "year",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "month",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "day",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hours",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "minutes",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "seconds",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Person",
                  "kind": "LinkedField",
                  "name": "person",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v13/*: any*/),
                    (v14/*: any*/),
                    (v29/*: any*/),
                    (v15/*: any*/),
                    (v16/*: any*/)
                  ],
                  "storageKey": null
                },
                (v22/*: any*/)
              ],
              "storageKey": null
            },
            (v23/*: any*/)
          ],
          "storageKey": null
        },
        (v24/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Person",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": (v30/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latestUiUpdateAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Person",
      "kind": "LinkedField",
      "name": "latestUiUpdateBy",
      "plural": false,
      "selections": (v30/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Person",
      "kind": "LinkedField",
      "name": "owner",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v13/*: any*/),
        (v14/*: any*/),
        (v29/*: any*/),
        (v15/*: any*/),
        (v16/*: any*/),
        (v20/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectType",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "useTaskOwner",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "useTaskFollowers",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "useTaskHierarchy",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectStartYear",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectStartMonth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectStartDay",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectEndYear",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectEndMonth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectEndDay",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectColor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isJiraProject",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jiraSubtaskType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "JiraCloudProjectType",
          "kind": "LinkedField",
          "name": "jiraCloudProject",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jiraCloudEpicIds",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "JiraServerProjectType",
          "kind": "LinkedField",
          "name": "jiraServerProject",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gitlabProjectName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gitlabServerProjectName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectGroupId",
          "storageKey": null
        },
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "taskLevels",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "companyProjectId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customProjectId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isProgramRevenueLocked",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "programBudgetType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProgramType",
          "kind": "LinkedField",
          "name": "program",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "prefix",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "budgetType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectServiceProgramMemberTypeConnection",
              "kind": "LinkedField",
              "name": "members",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectServiceProgramMemberTypeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProjectServiceProgramMemberType",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "role",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Person",
                          "kind": "LinkedField",
                          "name": "person",
                          "plural": false,
                          "selections": (v2/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HarvestProjectType",
          "kind": "LinkedField",
          "name": "harvestProject",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "manualProgressOnProjectEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "manualProgressOnPhasesEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "manualProgressOnTasksEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vstsProject",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vstsAccount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vstsTwoWaySync",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sprintTimeBox",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "estimationUnit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "remainingAutoCalculated",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "RateCard",
          "kind": "LinkedField",
          "name": "rateCard",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Role",
              "kind": "LinkedField",
              "name": "disabledRoles",
              "plural": true,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": "projectPersons",
          "args": null,
          "concreteType": "ProjectPersonTypeConnection",
          "kind": "LinkedField",
          "name": "__Project_projectPersons_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectPersonTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectPerson",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Person",
                      "kind": "LinkedField",
                      "name": "person",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v13/*: any*/),
                        (v14/*: any*/),
                        (v29/*: any*/),
                        (v15/*: any*/),
                        (v16/*: any*/),
                        (v21/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "permissions",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "harvestUser",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProjectType",
                      "kind": "LinkedField",
                      "name": "project",
                      "plural": false,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    },
                    (v20/*: any*/),
                    (v22/*: any*/)
                  ],
                  "storageKey": null
                },
                (v23/*: any*/)
              ],
              "storageKey": null
            },
            (v24/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Client",
          "kind": "LinkedField",
          "name": "client",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "logoId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "logoDefaultId",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v31/*: any*/),
          "concreteType": "PhaseTypeConnection",
          "kind": "LinkedField",
          "name": "phases",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PhaseTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PhaseType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v5/*: any*/),
                    (v6/*: any*/),
                    (v7/*: any*/),
                    (v11/*: any*/),
                    (v10/*: any*/),
                    (v9/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "phases(first:10000)"
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 1000000
            }
          ],
          "concreteType": "StatusColumnV2TypeConnection",
          "kind": "LinkedField",
          "name": "statusColumnsV2",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "StatusColumnV2TypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "StatusColumnV2",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v25/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "order",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "jiraStatusId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "adoState",
                      "storageKey": null
                    },
                    (v28/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "StatusColumnUserActions",
                      "kind": "LinkedField",
                      "name": "userActions",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "canDelete",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "canDeleteWithTasks",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "canCreateTask",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "canRename",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "statusColumnsV2(first:1000000)"
        },
        {
          "alias": null,
          "args": (v31/*: any*/),
          "concreteType": "SprintTypeConnection",
          "kind": "LinkedField",
          "name": "sprints",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SprintTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Sprint",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v5/*: any*/),
                    (v6/*: any*/),
                    (v7/*: any*/),
                    (v19/*: any*/),
                    (v18/*: any*/),
                    (v17/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "sprints(first:10000)"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DeprecatedProjectIndicatorJS_project"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Task"
};
})();
// prettier-ignore
(node/*: any*/).hash = '0e8cb2881f87dd911a8331ea877c811e';

module.exports = node;
