import React, { useMemo, useState } from 'react';
import { ButtonDropdownField, DropdownList, FlexColumn, FlexRow, Icon, SubHeading } from '@forecast-it/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
const ChangeViewGroupings = ({ maxLevel, groupings: initialGroupings, onChange, children }) => {
    const { formatMessage } = useIntl();
    const [groupings, setGroupings] = useState(() => new Set(initialGroupings));
    const [showDropdown, setShowDropdown] = useState(() => groupings.size === 0);
    const addGrouping = (newGrouping) => {
        setGroupings(prevGroupings => {
            const newGroupings = prevGroupings.add(newGrouping);
            onChange([...newGroupings]);
            return new Set(newGroupings);
        });
        setShowDropdown(false);
    };
    const removeGrouping = (e, toBeRemovedGrouping) => {
        e.preventDefault();
        setGroupings(prevGroupings => {
            const newGroupings = [...prevGroupings].filter(prevGrouping => prevGrouping !== toBeRemovedGrouping);
            onChange([...newGroupings]);
            return new Set(newGroupings);
        });
        if (groupings.size <= 1) {
            setShowDropdown(true);
        }
    };
    const childrenArray = useMemo(() => React.Children.toArray(children), [children]);
    const selectedGroupings = useMemo(() => {
        const order = [...groupings];
        return childrenArray
            .filter((child) => groupings.has(child.props.value))
            .sort((a, b) => order.indexOf(a.props.value) - order.indexOf(b.props.value));
    }, [childrenArray, groupings]);
    const groupingOptions = childrenArray.filter((child) => !groupings.has(child.props.value));
    const showAddAnotherGroupLevel = groupings.size < maxLevel && !showDropdown && groupingOptions.length > 0;
    return (React.createElement(DropdownList.ContentGroup, { title: formatMessage({ id: 'change_view.groupings' }) },
        selectedGroupings.map((child, index) => (React.createElement(DropdownList.Item, { key: child.props.value, onClick: e => removeGrouping(e, child.props.value), "data-cy": 'selected-grouping-' + child.props.value },
            React.createElement(FlexRow, { justifyContent: 'space-between' },
                React.createElement(FlexColumn, null,
                    selectedGroupings.length > 1 ? (React.createElement(SubHeading, null,
                        React.createElement(FormattedMessage, { id: 'expense_management.grouping_level', values: { count: index + 1 } }))) : undefined,
                    child.props.children),
                React.createElement(Icon, { icon: 'close' }))))),
        showAddAnotherGroupLevel ? (React.createElement(DropdownList.ButtonItem, { onClick: () => setShowDropdown(true), "data-cy": 'add-another-grouping' },
            React.createElement(Icon, { icon: 'plus' }),
            " ",
            React.createElement(FormattedMessage, { id: 'expense_management.grouping.add_another_group' }))) : undefined,
        showDropdown ? (React.createElement(DropdownList.DropdownItem, null,
            React.createElement(DropdownList.Trigger, null,
                React.createElement(ButtonDropdownField, { isDefaultLabel: true, "data-cy": 'select-grouping' },
                    React.createElement(FormattedMessage, { id: 'expense_management.grouping.select_group' }))),
            React.createElement(DropdownList.Content, { offset: 4, useTriggerWidth: true }, groupingOptions.map((child) => (React.createElement(DropdownList.Item, { key: child.props.value, onClick: () => addGrouping(child.props.value), "data-cy": 'grouping-option-' + child.props.value }, child.props.children)))))) : undefined));
};
const Grouping = () => null;
ChangeViewGroupings.Grouping = Grouping;
export default ChangeViewGroupings;
