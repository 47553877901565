import React from 'react';
import Styled from 'styled-components';
import {CSS_CONSTANTS} from '../../../../../../css_variables';
import {Link} from 'react-router-dom';
import {useIntl} from 'react-intl';

export const PageDeprecated = ({text, link}) => {
	const intl = useIntl();

	return (
		<Deprecation>
			<a>
				<Link to={link}>{intl.formatMessage({id: text})}</Link>
			</a>
		</Deprecation>
	);
};

const Deprecation = Styled.div`
	font-size: 13px;
	color: ${CSS_CONSTANTS.v2_text_gray};
	font-weight: 500;

	a:hover {
		color: ${CSS_CONSTANTS.v2_text_gray};
	}
`;
