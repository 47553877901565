import { MODAL_TYPE, showModal } from '../../../../shared/components/modals/generic_modal_conductor';
import { createToast } from '../../../../shared/components/toasts/another-toast/toaster';
import BulkDeleteSavedReportMutation from '../../../../../mutations/bulk_delete_saved_report_mutation';
import Util from '../../../../shared/util/util';
const triggerToast = (result, intl) => {
    const deletedCount = result.bulkDeleteSavedReport.deletedReportIds.length;
    createToast({
        duration: 5000,
        message: intl.formatMessage({ id: 'report.bulk-has-been-deleted' }, { deletedCount: deletedCount }),
    });
};
const modalCallback = (reports, companyId, intl, successCallback) => {
    const reportServiceIds = reports.filter(report => report.reportService).map(report => report.id);
    const savedReportIds = reports.filter(report => !report.reportService).map(report => report.id);
    Util.CommitMutation(BulkDeleteSavedReportMutation, {
        reportServiceIds: reportServiceIds,
        savedReportIds: savedReportIds,
        companyId: companyId,
    }, (result) => {
        triggerToast(result, intl);
        successCallback();
    });
};
export const bulkDeleteReports = (reports, companyId, intl, successCallback) => {
    showModal({
        type: MODAL_TYPE.DELETE_REPORT_MODAL,
        reportCount: reports.length,
        callback: () => modalCallback(reports, companyId, intl, successCallback),
    });
};
