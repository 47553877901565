import React, {useMemo} from 'react';
import {Column, GrowableColumn, PeriodLockedWrapper, PeriodNameWrapper, PeriodRowWrapper, RowWrapper} from './Elements.styled';
import {keyBy} from 'lodash';
import {getColumnWidth} from './utils';
import {FormattedNumber, useIntl} from 'react-intl';
import LockedIcon from '../../../../../../images/integrations/locked_icon';
import Moment from 'moment';
import {DATE_PICKER_STYLE, ESTIMATION_UNIT, PERIOD_BUDGET_TYPE} from '../../../../../../constants';
import Util from '../../../../util/util';
import DatePicker from '../../../date-picker/date_picker_v3';
import {getNonBillableTime} from '../../../../util/time-registration/time-registration-settings/BillableTimeSplitUtil';

/**
 * Method to return the name column of the period.
 * It also shows an locked icon, if the period is locked.
 */
const NameColumn = React.memo(({period, width, intl}) => {
	return (
		<GrowableColumn width={width}>
			<PeriodNameWrapper style={{display: 'flex'}}>
				{period.periodLocked ? (
					<PeriodLockedWrapper>
						<LockedIcon
							width={12}
							height={16}
							className="status-icon"
							title={intl.formatMessage(
								{id: 'retainer.period_locked'},
								{lockedTime: Moment(period.periodLockedTime).format('LL')}
							)}
						/>
					</PeriodLockedWrapper>
				) : null}
				<span className="period-name" title={period.name}>
					{period.name}
				</span>
			</PeriodNameWrapper>
		</GrowableColumn>
	);
});

/**
 * This method creates the period date. It shows the start and end date, in
 * the same way as the phase dates, and task dates.
 */
const DateColumn = React.memo(({period, width}) => {
	return (
		period.startYear && (
			<Column width={width}>
				<div className="phase-deadline-select double">
					<DatePicker
						buttonCy={'date-button'}
						disabled={true}
						id={period.id}
						startDate={Util.CreateNonUtcMomentDate(period.startYear, period.startMonth, period.startDay)}
						endDate={Util.CreateNonUtcMomentDate(period.endYear, period.endMonth, period.endDay)}
						datePickerStyle={DATE_PICKER_STYLE.SCOPING_PHASE_DATE}
						colorInherited={true}
						fontWeight={'normal'}
						collapseOnWheel={true}
						compactShowYear={true}
					/>
				</div>
			</Column>
		)
	);
});

/**
 * This this will return the complete estimate of the period.
 * If using story points, they will be converted into hours.
 * It is only shown when using a Fixed hours period.
 */
const EstimateColumn = React.memo(({period, estimate, estimationUnit, minutesPerEstimationPoint, intl, width, align}) => {
	const isVisible = period.periodBudgetType === PERIOD_BUDGET_TYPE.FIXED_HOURS;

	const estimateValue =
		estimationUnit === ESTIMATION_UNIT.HOURS
			? Util.convertMinutesToFullHour(estimate, intl)
			: Util.convertMinutesToFullHour(estimate * minutesPerEstimationPoint, intl);

	return (
		<Column width={width} align={align} paddingRight={16}>
			{isVisible && estimateValue}
		</Column>
	);
});

/**
 * This will show the complete amount of time entries for the period.
 */
const TimeEntriesColumn = React.memo(({period, timeRegistration, intl, width, align}) => {
	const isVisible =
		period.periodBudgetType === PERIOD_BUDGET_TYPE.FIXED_HOURS ||
		period.periodBudgetType === PERIOD_BUDGET_TYPE.TIME_AND_MATERIALS;

	return (
		<Column width={width} align={align}>
			{isVisible && Util.convertMinutesToFullHour(timeRegistration, intl)}
		</Column>
	);
});

/**
 * This will show the planned price for the period.
 * It is only shown when using a Fixed price period.
 */
const PlannedPriceColumn = React.memo(({period, price, currency, width, align}) => {
	const isVisible = period.periodBudgetType === PERIOD_BUDGET_TYPE.FIXED_PRICE;

	return (
		<Column width={width} align={align}>
			{isVisible && <FormattedNumber value={price} style="currency" currency={currency} />}
		</Column>
	);
});

/**
 * This will show the actual price for the period.
 * It is only shown when using a Fixed price period.
 */
const ActualPriceColumn = React.memo(({period, price, currency, width, align}) => {
	const isVisible = period.periodBudgetType === PERIOD_BUDGET_TYPE.FIXED_PRICE;

	return (
		<Column width={width} align={align}>
			{isVisible && <FormattedNumber value={price} style="currency" currency={currency} />}
		</Column>
	);
});

/**
 * This will show period value. Bases on the type this can either be the hours or price.
 */
const PeriodValueColumn = React.memo(({period, intl, currency, width, align}) => {
	switch (period.periodBudgetType) {
		case PERIOD_BUDGET_TYPE.FIXED_HOURS:
			const hoursTarget =
				period.periodHoursAmount +
				(period.periodDifferenceHoursAmount || 0) +
				(period.sharedPeriodDifferenceHoursAmount || 0);
			return (
				<Column width={width} align={align}>
					{Util.convertMinutesToFullHour(hoursTarget * 60, intl)}
				</Column>
			);
		case PERIOD_BUDGET_TYPE.FIXED_PRICE:
			const priceTarget =
				period.periodPriceAmount +
				(period.periodDifferencePriceAmount || 0) +
				(period.sharedPeriodDifferencePriceAmount || 0);
			return (
				<Column width={width} align={align}>
					<FormattedNumber value={priceTarget} style="currency" currency={currency} />
				</Column>
			);
		default:
			return <Column width={width} align={align} />;
	}
});

/**
 * This method generates the period row.
 *
 * @param node The period node
 * @returns {JSX.Element} The row
 */
export const PeriodRow = ({node}) => {
	const intl = useIntl();
	const {period, rolledUpValues, availableColumns, sharedOptions} = node;

	const columnsObject = useMemo(() => keyBy(availableColumns, 'name'), [availableColumns]);

	const nameColumnWidth = useMemo(() => {
		let width = getColumnWidth('task-name', columnsObject);
		const idColumnWidth = getColumnWidth('task-id', columnsObject);
		width += idColumnWidth || 0;
		return width;
	}, [availableColumns]);

	const estimationUnit = sharedOptions?.estimationUnit;
	const minutesPerEstimationPoint = sharedOptions?.minutesPerEstimationPoint;
	const currency = sharedOptions?.currency;

	return (
		<RowWrapper>
			<PeriodRowWrapper>
				{/* task-id and task-name used for the same column*/}
				<NameColumn width={nameColumnWidth} period={period} intl={intl} />

				{getColumnWidth('date', columnsObject) && (
					<DateColumn width={getColumnWidth('date', columnsObject)} period={period} />
				)}

				{getColumnWidth('assigned-role', columnsObject) && (
					<Column width={getColumnWidth('assigned-role', columnsObject)} />
				)}

				{getColumnWidth('assigned-person', columnsObject) && (
					<Column width={getColumnWidth('assigned-person', columnsObject)} />
				)}

				{getColumnWidth('status', columnsObject) && <Column width={getColumnWidth('status', columnsObject)} />}

				{getColumnWidth('phase', columnsObject) && <Column width={getColumnWidth('phase', columnsObject)} />}

				{getColumnWidth('sprint', columnsObject) && <Column width={getColumnWidth('sprint', columnsObject)} />}

				{getColumnWidth('done-percentage', columnsObject) && (
					<Column width={getColumnWidth('done-percentage', columnsObject)} />
				)}
				{getColumnWidth('period-target', columnsObject) && (
					<PeriodValueColumn
						width={getColumnWidth('period-target', columnsObject)}
						align={columnsObject['period-target'].align}
						period={period}
						intl={intl}
						currency={currency}
					/>
				)}

				{getColumnWidth('forecast', columnsObject) && (
					<EstimateColumn
						width={getColumnWidth('forecast', columnsObject)}
						align={columnsObject['forecast'].align}
						period={period}
						estimate={rolledUpValues?.estimate}
						estimationUnit={estimationUnit}
						minutesPerEstimationPoint={minutesPerEstimationPoint}
						intl={intl}
					/>
				)}

				{getColumnWidth('time-entries', columnsObject) && (
					<TimeEntriesColumn
						width={getColumnWidth('time-entries', columnsObject)}
						align={columnsObject['time-entries'].align}
						period={period}
						timeRegistration={rolledUpValues?.timeRegistered}
						intl={intl}
					/>
				)}

				{getColumnWidth('billable-time-entries', columnsObject) && (
					<TimeEntriesColumn
						width={getColumnWidth('billable-time-entries', columnsObject)}
						align={columnsObject['billable-time-entries'].align}
						period={period}
						timeRegistration={rolledUpValues?.billableTimeRegistered}
						intl={intl}
					/>
				)}

				{getColumnWidth('non-billable-time-entries', columnsObject) && (
					<TimeEntriesColumn
						width={getColumnWidth('non-billable-time-entries', columnsObject)}
						align={columnsObject['non-billable-time-entries'].align}
						period={period}
						timeRegistration={getNonBillableTime(
							rolledUpValues?.timeRegistered,
							rolledUpValues?.billableTimeRegistered
						)}
						intl={intl}
					/>
				)}

				{getColumnWidth('remaining', columnsObject) && <Column width={getColumnWidth('remaining', columnsObject)} />}

				{getColumnWidth('over-forecast', columnsObject) && (
					<Column width={getColumnWidth('over-forecast', columnsObject)} />
				)}
				{getColumnWidth('price', columnsObject) && (
					<PlannedPriceColumn
						width={getColumnWidth('price', columnsObject)}
						align={columnsObject['price'].align}
						period={period}
						price={rolledUpValues?.price}
						currency={currency}
					/>
				)}

				{getColumnWidth('actual-price', columnsObject) && (
					<ActualPriceColumn
						width={getColumnWidth('actual-price', columnsObject)}
						align={columnsObject['actual-price'].align}
						period={period}
						price={rolledUpValues?.actualPrice}
						currency={currency}
					/>
				)}

				{getColumnWidth('planned-cost', columnsObject) && (
					<PlannedPriceColumn
						width={getColumnWidth('planned-cost', columnsObject)}
						align={columnsObject['planned-cost'].align}
						period={period}
						price={rolledUpValues?.plannedCost}
						currency={currency}
					/>
				)}

				{getColumnWidth('actual-cost', columnsObject) && (
					<ActualPriceColumn
						width={getColumnWidth('actual-cost', columnsObject)}
						align={columnsObject['actual-cost'].align}
						period={period}
						price={rolledUpValues?.actualCost}
						currency={currency}
					/>
				)}

				{getColumnWidth('labels', columnsObject) && <Column width={getColumnWidth('labels', columnsObject)} />}

				{getColumnWidth('approved', columnsObject) && <Column width={getColumnWidth('approved', columnsObject)} />}

				<Column width={60} />

				<Column width={20} />
			</PeriodRowWrapper>
		</RowWrapper>
	);
};
