import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {ELEMENT_TYPE} from '../../../../../constants';
import Switch from '../../switch/switch';

class BurgerMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			opened: false,
		};
	}

	toggleBurgerMenu(e) {
		this.setState(prevState => {
			return {opened: !prevState.opened};
		});
		if (this.expand_btn) {
			this.expand_btn.focus();
		}
	}

	handleBlur(e) {
		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11
		if (
			newTarget &&
			(newTarget.className.includes('burger-menu-option') || newTarget.className.includes('switch-container'))
		)
			return;
		this.setState({opened: false});
	}

	toggleOption(option) {
		this.props.handleOption(option);
	}

	render() {
		return (
			<div className="burger-menu">
				<button
					onClick={this.toggleBurgerMenu.bind(this)}
					className={'expand-button' + (this.state.opened ? ' expanded' : ' collapsed')}
					onBlur={this.handleBlur.bind(this)}
					ref={btn => (this.expand_btn = btn)}
				/>
				{this.state.opened && this.props.options.length > 0 ? (
					<ul className="burger-menu-options" ref={ul => (this.expanded_div = ul)}>
						{this.props.options.map((opt, i) => {
							return (
								<li key={i} className="burger-menu-option" onBlur={this.handleBlur.bind(this)} tabIndex={0}>
									<div className={'burger-menu-option-name'} onClick={this.toggleOption.bind(this, opt)}>
										{opt.name}
									</div>
									{opt.type === ELEMENT_TYPE.TOGGLE_SWITCH ? (
										<Switch
											locked={false}
											onChange={this.toggleOption.bind(this, opt)}
											checked={opt.activated}
											sliderWidth={35}
											sliderHeight={18}
										/>
									) : null}
								</li>
							);
						})}
					</ul>
				) : null}
			</div>
		);
	}
}

export default BurgerMenu;
