/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
query SettingsClientGuestUsers_Query(
  $clientId: String
) {
  viewer {
    actualPersonId
    component(name: "settings_client")
    ...SettingsClientGuestUsers_viewer_3sjgMp
    id
  }
}

fragment SettingsClientGuestUsers_viewer_3sjgMp on Viewer {
  id
  availableFeatureFlags {
    key
    id
  }
  company {
    id
    client(id: $clientId) {
      id
      name
      street
      zip
      city
      vat
      country
      notes
      logoId
      logoDefaultId
      clientUsers(first: 1000) {
        edges {
          node {
            id
            firstName
            lastName
            email
            phone
            __typename
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
  projects(first: 100000) {
    edges {
      node {
        id
        name
        client {
          id
        }
        projectPersons(first: 1000, onlyClientUsers: true) {
          edges {
            node {
              id
              person {
                id
              }
              __typename
            }
            cursor
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "clientId",
            "type": "String"
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actualPersonId",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": [
            {
                "kind": "Literal",
                "name": "name",
                "value": "settings_client"
            }
        ],
        "kind": "ScalarField",
        "name": "component",
        "storageKey": "component(name:\"settings_client\")"
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v5 = {
        "kind": "Literal",
        "name": "first",
        "value": 1000
    }, v6 = [
        (v5 /*: any*/)
    ], v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
    }, v9 = {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v10 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 100000
        }
    ], v11 = [
        (v3 /*: any*/)
    ], v12 = [
        (v5 /*: any*/),
        {
            "kind": "Literal",
            "name": "onlyClientUsers",
            "value": true
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "SettingsClientGuestUsers_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "clientId",
                                    "variableName": "clientId"
                                }
                            ],
                            "kind": "FragmentSpread",
                            "name": "SettingsClientGuestUsers_viewer"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "SettingsClientGuestUsers_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "FeatureFlag",
                            "kind": "LinkedField",
                            "name": "availableFeatureFlags",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "key",
                                    "storageKey": null
                                },
                                (v3 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Company",
                            "kind": "LinkedField",
                            "name": "company",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                {
                                    "alias": null,
                                    "args": [
                                        {
                                            "kind": "Variable",
                                            "name": "id",
                                            "variableName": "clientId"
                                        }
                                    ],
                                    "concreteType": "Client",
                                    "kind": "LinkedField",
                                    "name": "client",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        (v4 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "street",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "zip",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "city",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "vat",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "country",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "notes",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "logoId",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "logoDefaultId",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": (v6 /*: any*/),
                                            "concreteType": "PersonTypeConnection",
                                            "kind": "LinkedField",
                                            "name": "clientUsers",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "PersonTypeEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "Person",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                                (v3 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "firstName",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "lastName",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "email",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "phone",
                                                                    "storageKey": null
                                                                },
                                                                (v7 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        (v8 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v9 /*: any*/)
                                            ],
                                            "storageKey": "clientUsers(first:1000)"
                                        },
                                        {
                                            "alias": null,
                                            "args": (v6 /*: any*/),
                                            "filters": [],
                                            "handle": "connection",
                                            "key": "Client_clientUsers",
                                            "kind": "LinkedHandle",
                                            "name": "clientUsers"
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": (v10 /*: any*/),
                            "concreteType": "ProjectTypeConnection",
                            "kind": "LinkedField",
                            "name": "projects",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ProjectTypeEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ProjectType",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                                (v3 /*: any*/),
                                                (v4 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Client",
                                                    "kind": "LinkedField",
                                                    "name": "client",
                                                    "plural": false,
                                                    "selections": (v11 /*: any*/),
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": (v12 /*: any*/),
                                                    "concreteType": "ProjectPersonTypeConnection",
                                                    "kind": "LinkedField",
                                                    "name": "projectPersons",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "ProjectPersonTypeEdge",
                                                            "kind": "LinkedField",
                                                            "name": "edges",
                                                            "plural": true,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "ProjectPerson",
                                                                    "kind": "LinkedField",
                                                                    "name": "node",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        (v3 /*: any*/),
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "concreteType": "Person",
                                                                            "kind": "LinkedField",
                                                                            "name": "person",
                                                                            "plural": false,
                                                                            "selections": (v11 /*: any*/),
                                                                            "storageKey": null
                                                                        },
                                                                        (v7 /*: any*/)
                                                                    ],
                                                                    "storageKey": null
                                                                },
                                                                (v8 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        (v9 /*: any*/)
                                                    ],
                                                    "storageKey": "projectPersons(first:1000,onlyClientUsers:true)"
                                                },
                                                {
                                                    "alias": null,
                                                    "args": (v12 /*: any*/),
                                                    "filters": [],
                                                    "handle": "connection",
                                                    "key": "Project_projectPersons",
                                                    "kind": "LinkedHandle",
                                                    "name": "projectPersons"
                                                },
                                                (v7 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v8 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v9 /*: any*/)
                            ],
                            "storageKey": "projects(first:100000)"
                        },
                        {
                            "alias": null,
                            "args": (v10 /*: any*/),
                            "filters": [],
                            "handle": "connection",
                            "key": "Viewer_projects",
                            "kind": "LinkedHandle",
                            "name": "projects"
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "SettingsClientGuestUsers_Query",
            "operationKind": "query",
            "text": "query SettingsClientGuestUsers_Query(\n  $clientId: String\n) {\n  viewer {\n    actualPersonId\n    component(name: \"settings_client\")\n    ...SettingsClientGuestUsers_viewer_3sjgMp\n    id\n  }\n}\n\nfragment SettingsClientGuestUsers_viewer_3sjgMp on Viewer {\n  id\n  availableFeatureFlags {\n    key\n    id\n  }\n  company {\n    id\n    client(id: $clientId) {\n      id\n      name\n      street\n      zip\n      city\n      vat\n      country\n      notes\n      logoId\n      logoDefaultId\n      clientUsers(first: 1000) {\n        edges {\n          node {\n            id\n            firstName\n            lastName\n            email\n            phone\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n  projects(first: 100000) {\n    edges {\n      node {\n        id\n        name\n        client {\n          id\n        }\n        projectPersons(first: 1000, onlyClientUsers: true) {\n          edges {\n            node {\n              id\n              person {\n                id\n              }\n              __typename\n            }\n            cursor\n          }\n          pageInfo {\n            endCursor\n            hasNextPage\n          }\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
        }
    };
})();
node.hash = 'b066799d5eb4bd1b91753da6dc1ee87a';
export default node;
