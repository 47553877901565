import {DeprecatedList as List} from '@forecast-it/design-system';
import React, {useCallback, useEffect} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {BUDGET_TYPE, SOCKET_ACTION, SOCKET_EVENT_TYPE} from '../../../../../constants';
import {EVENT_ID, subscribe, unsubscribe} from '../../../../../containers/event_manager';
import {PERMISSION_TYPE} from '../../../../../Permissions';
import {withSimpleSocketHandling} from '../../../../../socket/withSimpleSocketHandling';
import {hasPermission} from '../../../../shared/util/PermissionsUtil';
import Util from '../../../../shared/util/util';
import InsightRow from './InsightRow';
import {toggleBudgetSlideout} from './slideouts/BudgetSlideout';
import {togglePredictedEndSlideout} from './slideouts/PredictedEndSlideout';
import {toggleSuggestedStatusSlideout} from './slideouts/SuggestedStatusSlideout';
import CompanySetupUtil from '../../../../shared/util/CompanySetupUtil';

const ProgramNovaInsights = ({viewer, projects, retry, setSocketConfig}) => {
	const {program} = viewer;
	const {projectHealthRollup} = program;

	const hasFinancialInfo =
		CompanySetupUtil.hasFinance() &&
		(hasPermission(PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION) ||
			hasPermission(PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE));

	const programId = Util.getUUIdFromBase64String(viewer.program.id);
	const projectsIds = projects.map(project => Util.getUUIdFromBase64String(project.id));
	const programHasFixedPriceProject = projects.some(project => {
		const budgetType = project.settings.edges.find(obj => obj.node.name === 'budgetType')?.node.value;
		return budgetType === BUDGET_TYPE.FIXED_PRICE || budgetType === BUDGET_TYPE.FIXED_PRICE_V2;
	});

	const socketEvents = [
		{
			type: SOCKET_EVENT_TYPE.PROGRAM,
			action: SOCKET_ACTION.UPDATE,
			entityIds: programId,
		},
		{
			type: SOCKET_EVENT_TYPE.PROGRAM,
			action: SOCKET_ACTION.DELETE,
			entityIds: programId,
		},
		{
			type: SOCKET_EVENT_TYPE.V2_PROJECT,
			action: SOCKET_ACTION.UPDATE,
			entityIds: projectsIds,
		},
		{
			type: SOCKET_EVENT_TYPE.V2_PROJECT,
			action: SOCKET_ACTION.DELETE,
			entityIds: projectsIds,
		},
	];
	setSocketConfig(socketEvents);

	const getFreshData = useCallback(result => {
		if (result.attachProjectToProgram || result.detachProjectFromProgram || result.createProjectStatus) {
			retry();
		}
	}, []);

	useEffect(() => {
		subscribe(EVENT_ID.SCHEDULING_MODAL_MUTATION_SUCCESS, getFreshData);
		return () => {
			unsubscribe(EVENT_ID.SCHEDULING_MODAL_MUTATION_SUCCESS, getFreshData);
		};
	}, [getFreshData]);

	return (
		<List>
			{hasFinancialInfo && programHasFixedPriceProject ? (
				<>
					<InsightRow
						name={'Budgets'}
						ragStatus={projectHealthRollup?.budgetOverrunRag}
						insightMessage={projectHealthRollup?.budgetOverrunMessage}
						onSelect={(_, invokerRef) => toggleBudgetSlideout({program, projects, invokerRef})}
						lockedInsightText={
							hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS)
								? 'To unlock this insight, set your budget tolerance from either the project settings or the admin panel.'
								: 'To unlock this insight, set your budget tolerance from the project settings.'
						}
						cy={'program-budget-insight'}
					/>
				</>
			) : null}

			<InsightRow
				name={'End Dates'}
				ragStatus={projectHealthRollup?.predictedOverrunRag}
				insightMessage={projectHealthRollup?.predictedOverrunMessage}
				onSelect={(_, invokerRef) => togglePredictedEndSlideout({program, projects, invokerRef})}
				lockedInsightText={
					'To unlock this insight, add active projects with recently done tasks, time registrations, and allocations to the program.'
				}
				cy={'program-end-date-insight'}
			/>

			<InsightRow
				name={'Statuses'}
				ragStatus={projectHealthRollup?.suggestedStatusDisagreesRag}
				insightMessage={projectHealthRollup?.suggestedStatusDisagreesMessage}
				onSelect={(_, invokerRef) => toggleSuggestedStatusSlideout({program, projects, invokerRef})}
				lockedInsightText={
					'To unlock this insight, add active projects with recently done tasks, time registrations, and allocations to the program.'
				}
				cy={'program-status-insight'}
			/>
		</List>
	);
};

const ProgramNovaInsightsQuery = graphql`
	query ProgramNovaInsights_Query($prefix: String) {
		viewer {
			actualPersonId
			component(name: "program_nova_insights")
			...ProgramNovaInsights_viewer @arguments(prefix: $prefix)
		}
	}
`;

export {ProgramNovaInsightsQuery};

export default withSimpleSocketHandling(
	createFragmentContainer(ProgramNovaInsights, {
		viewer: graphql`
			fragment ProgramNovaInsights_viewer on Viewer @argumentDefinitions(prefix: {type: "String"}) {
				id
				program(prefix: $prefix) {
					id
					name
					color
					projectHealthRollup {
						suggestedStatusDisagreesRag
						suggestedStatusDisagreesMessage
						predictedOverrunRag
						predictedOverrunMessage
						budgetOverrunRag
						budgetOverrunMessage
						projectHealthResponses {
							projectId
							predictedEndDateRAGStatus
							predictedEndDateInfo
							predictedEndDateError
							budgetInsightRag
							budgetInsightInfoShort
							budgetInsightError
							suggestedStatusRag
						}
					}
				}
			}
		`,
	})
);
