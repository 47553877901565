/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type settingsIdleTimes_idleTime$ref: FragmentReference;
declare export opaque type settingsIdleTimes_idleTime$fragmentType: settingsIdleTimes_idleTime$ref;
export type settingsIdleTimes_idleTime = {|
  +id: string,
  +name: ?string,
  +isInternalTime: ?boolean,
  +disabled: ?boolean,
  +timeRegistrations: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string
      |}
    |}>
  |},
  +$refType: settingsIdleTimes_idleTime$ref,
|};
export type settingsIdleTimes_idleTime$data = settingsIdleTimes_idleTime;
export type settingsIdleTimes_idleTime$key = {
  +$data?: settingsIdleTimes_idleTime$data,
  +$fragmentRefs: settingsIdleTimes_idleTime$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "settingsIdleTimes_idleTime",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isInternalTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "disabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        }
      ],
      "concreteType": "TimeRegTypeConnection",
      "kind": "LinkedField",
      "name": "timeRegistrations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TimeRegTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TimeRegType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "timeRegistrations(first:1)"
    }
  ],
  "type": "IdleTime"
};
})();
// prettier-ignore
(node/*: any*/).hash = '082329c05eb169c0e8460e8fe4ff9e17';

module.exports = node;
