import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TooltipContainer from './tooltip_container';
class TooltipIcon extends Component {
	render() {
		return (
			<TooltipContainer infoText={this.props.infoText}>
				<div
					data-cy={this.props.cy}
					data-userpilot={this.props.userpilot}
					onClick={this.props.onClick}
					className={this.props.className}
				/>
			</TooltipContainer>
		);
	}
}
TooltipIcon.propTypes = {
	infoText: PropTypes.string.isRequired,
	className: PropTypes.string.isRequired,
	onClick: PropTypes.func,
};
export default TooltipIcon;
