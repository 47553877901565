import moment from 'moment';
import Util from './util';
import {cloneDeep} from 'lodash';

const DEFAULT_ROLE = 'DEFAULT';

export class RateCardUtil {
	constructor(rateCard, roles) {
		this.rateCard = rateCard;
		this.rates = rateCard?.rates;
		this.roles = roles;
		this.rateVersionsByRole = new Map();
		this.mapRates(rateCard);
		this.rateCardVersions = rateCard && roles ? this.getRateCardVersions(rateCard, roles) : [];
		this.sortRateCardVersions();
	}

	getRate(roleId, date = undefined) {
		if (date) {
			return this.getRoleRateOrDefaultOnDate(roleId, date);
		}
		return null;
	}

	getAverageRateBetweenDates(roleId, startDate, endDate) {
		let totalRate = 0;
		let dayCount = 0;

		const date = startDate.clone();
		while (date <= endDate) {
			const rate = this.getRoleRateOrDefaultOnDate(roleId, date);
			totalRate += rate;
			dayCount += 1;

			date.add(1, 'day');
		}

		return totalRate / dayCount;
	}

	getRateCardVersions = rateCard => {
		const versionDates = [];
		for (const rate of rateCard.rates.edges) {
			if (!versionDates.some(date => date === rate.node.startDate)) {
				versionDates.push(rate.node.startDate);
			}
		}

		const versions = [];
		for (const versionDate of versionDates) {
			versions.push({
				startDate: versionDate,
				defaultRate: 0,
				defaultRateId: null,
				rates: [],
			});
		}

		for (const rate of rateCard.rates.edges) {
			const version = versions.find(version => version.startDate === rate.node.startDate);
			if (rate.node.defaultRate) {
				version.defaultRate = rate.node.rate;
				version.defaultRateId = rate.node.id;
			} else {
				version.rates.push(rate.node);
			}
		}

		for (const version of versions) {
			version.roleRates = this.getVersionRoleRates(version, null);
		}

		return versions;
	};

	getVersionRoleRates = (rateCardVersion, initialRate) => {
		const roleRates = this.roles.edges.map(role => {
			const roleRate = rateCardVersion ? rateCardVersion.rates.find(rate => rate.role?.id === role.node.id) : null;
			return {
				roleId: role.node.id,
				rateId: roleRate ? roleRate.id : null,
				name: role.node.name,
				rate: roleRate ? roleRate.rate : initialRate !== null ? initialRate : null,
				isUsingDefaultRate: !roleRate && initialRate === null,
			};
		});
		roleRates.sort((roleRate1, roleRate2) => roleRate1.name.localeCompare(roleRate2.name));

		return roleRates;
	};

	sortRateCardVersions = () => {
		this.rateCardVersions.sort((version1, version2) => {
			if (version1.startDate === null) {
				return 1;
			} else if (version2.startDate === null) {
				return -1;
			} else {
				return moment(version2.startDate) - moment(version1.startDate);
			}
		});
	};

	createRateCardVersion = (rateCardVersionIndex, creatingInitialVersion) => {
		const duplicateRateCardVersion = rateCardVersionIndex !== null ? this.rateCardVersions[rateCardVersionIndex] : null;
		let startDate = moment();
		while (this.hasVersionStartDate(this.rateCardVersions, startDate)) {
			startDate = startDate.add(1, 'days');
		}

		let roleRates = [];
		if (duplicateRateCardVersion) {
			for (const roleRate of duplicateRateCardVersion.roleRates) {
				const duplicateRoleRate = cloneDeep(roleRate);
				duplicateRoleRate.rateId = null;
				roleRates.push(duplicateRoleRate);
			}
		} else {
			roleRates = this.getVersionRoleRates(null, creatingInitialVersion ? 0 : null);
		}

		this.rateCardVersions.push({
			startDate: creatingInitialVersion ? null : startDate.format('YYYY-MM-DD'),
			defaultRate: duplicateRateCardVersion ? duplicateRateCardVersion.defaultRate : 0,
			rates: [],
			roleRates,
		});

		this.sortRateCardVersions();
		const addedVersionIndex = this.rateCardVersions.findIndex(
			rateCardVersion => rateCardVersion.startDate === startDate.format('YYYY-MM-DD')
		);

		return addedVersionIndex;
	};

	hasVersionStartDate = (rateCardVersions, startDate) => {
		return rateCardVersions.some(
			rateCardVersion => rateCardVersion.startDate && moment(rateCardVersion.startDate).isSame(startDate, 'days')
		);
	};

	deleteRateCardVersion = rateCardVersionIndex => {
		this.rateCardVersions.splice(rateCardVersionIndex, 1);
	};

	getRateCardRates = disabledRoles => {
		const rates = [];
		for (const rateCardVersion of this.rateCardVersions) {
			const defaultRate = {
				id: rateCardVersion.defaultRateId ? Util.getIdFromBase64String(rateCardVersion.defaultRateId) : null,
				rate: rateCardVersion.defaultRate,
				defaultRate: true,
				roleId: null,
				startDate: rateCardVersion.startDate,
			};
			if (this.rateCard) {
				defaultRate.rateCardId = Util.getIdFromBase64String(this.rateCard.id);
			}
			rates.push(defaultRate);
			for (const roleRate of rateCardVersion.roleRates) {
				if ((roleRate.rateId || !roleRate.isUsingDefaultRate) && !disabledRoles.includes(roleRate.roleId)) {
					const rate = {
						id: roleRate.rateId ? Util.getIdFromBase64String(roleRate.rateId) : null,
						rate: roleRate.rate,
						defaultRate: roleRate.isUsingDefaultRate,
						roleId: Util.getIdFromBase64String(roleRate.roleId),
						startDate: rateCardVersion.startDate,
					};
					if (this.rateCard) {
						rate.rateCardId = Util.getIdFromBase64String(this.rateCard.id);
					}
					rates.push(rate);
				}
			}
		}
		return rates;
	};

	mapRates(rateCard) {
		if (!rateCard || !rateCard.rates || !rateCard.rates.edges) {
			return;
		}

		const rates = rateCard.rates.edges.map(edge => edge.node);

		rates.forEach(rate => {
			const roleKey = rate.role ? rate.role.id : DEFAULT_ROLE;

			if (!this.rateVersionsByRole.get(roleKey)) {
				this.rateVersionsByRole.set(roleKey, []);
			}
			const roleVersions = this.rateVersionsByRole.get(roleKey);
			if (roleVersions) {
				roleVersions.push({date: rate.startDate, rate: rate.rate});
			}
		});

		/*
		 * versions are sorted by date in descending order with the nulls last for faster lookup in the calculateRate function
		 * (no need to iterate all rates, just get the latest one with a date in the past)
		 */
		function sortByDateDescNullsLast(a, b) {
			if (!b.date) {
				return -1;
			}
			if (!a.date) {
				return 1;
			}
			if (!a.date && !b.date) {
				return 0;
			}
			return moment(b.date) - moment(a.date);
		}

		for (const roleRateTable of this.rateVersionsByRole.values()) {
			roleRateTable.sort(sortByDateDescNullsLast);
		}
	}

	getRatesUntilWantedDate(roleVersions, wantedDate) {
		return roleVersions
			? roleVersions.filter(rateForRole => !rateForRole.date || !moment(rateForRole.date).isAfter(wantedDate))
			: [];
	}

	getRoleRateOrDefaultOnDate(roleId, wantedDate) {
		let roleRatesBeforeDate = this.rateVersionsByRole.get(roleId)
			? this.getRatesUntilWantedDate(this.rateVersionsByRole.get(roleId), wantedDate)
			: null;
		const defaultRatesBeforeDate = this.getRatesUntilWantedDate(this.rateVersionsByRole.get(DEFAULT_ROLE), wantedDate);
		let rate = 0;
		if (roleRatesBeforeDate && roleRatesBeforeDate.length > 0) {
			const latestRoleRate = roleRatesBeforeDate[0];
			rate = latestRoleRate.rate;
			if (defaultRatesBeforeDate && defaultRatesBeforeDate.length > 0) {
				const latestDefaultRate = defaultRatesBeforeDate[0];
				if (
					(latestDefaultRate.date && !latestRoleRate.date) ||
					moment(latestDefaultRate.date).isAfter(moment(latestRoleRate.date))
				) {
					rate = latestDefaultRate.rate;
				}
			}
		} else if (defaultRatesBeforeDate && defaultRatesBeforeDate.length > 0) {
			rate = defaultRatesBeforeDate[0].rate;
		}
		return rate;
	}
}

export const filterRolesByRatecardDisabledRoles = (companyRoles, rateCard) => {
	const unfilteredRoles = companyRoles ? companyRoles : [];
	const disabledRoles = rateCard?.disabledRoles ? rateCard.disabledRoles.map(role => role.id) : [];
	return unfilteredRoles.filter(role => !disabledRoles.includes(role.node.id));
};
