export var Granularity;
(function (Granularity) {
    Granularity["Minutes"] = "MINUTES";
    Granularity["Quarters"] = "QUARTERS";
    Granularity["Hours"] = "HOURS";
    Granularity["Days"] = "DAYS";
})(Granularity || (Granularity = {}));
export function getLabelFromGranularity(granularity) {
    switch (granularity) {
        case Granularity.Minutes:
            return '1 minute';
        case Granularity.Quarters:
            return '15 minutes';
        case Granularity.Hours:
            return '1 hour';
        case Granularity.Days:
            return '1 day';
    }
}
export function getShortLabelFromGranularity(granularity) {
    switch (granularity) {
        case Granularity.Minutes:
            return 'minute';
        case Granularity.Quarters:
            return 'quarter';
        case Granularity.Hours:
            return 'hour';
        case Granularity.Days:
            return 'day';
    }
}
export function getGranularityInfoLabel(granularity) {
    const labelStart = 'Time entries round up to ';
    switch (granularity) {
        case Granularity.Minutes:
            return labelStart + 'the next minute';
        case Granularity.Quarters:
            return labelStart + 'the next quarter';
        case Granularity.Hours:
            return labelStart + 'the next hour';
        case Granularity.Days:
            return labelStart + 'match your working hours';
    }
}
