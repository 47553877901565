import { isExceedingEstimateAllowed } from '../../cache/TimeRegistrationSettingsUtil';
export function isTimeRegistrationTimeInvalid({ inputMinutes, remainingMinutes, subtractMinutes = 0, }) {
    if (isExceedingEstimateAllowed())
        return false;
    if (remainingMinutes === null)
        return false;
    if (inputMinutes < 1)
        return false;
    return inputMinutes - subtractMinutes > remainingMinutes;
}
function getTimeInvalidReasonString({ remainingMinutes }) {
    if (remainingMinutes === null)
        return null;
    return `Time entry cannot exceed the task estimate`;
}
export function isTimeRegistrationTimeInvalidWithReason({ inputMinutes, remainingMinutes, subtractMinutes, }) {
    if (isTimeRegistrationTimeInvalid({ inputMinutes, remainingMinutes, subtractMinutes })) {
        return getTimeInvalidReasonString({ remainingMinutes, subtractMinutes });
    }
    return null;
}
