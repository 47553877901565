import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { WorkSignifier } from '@forecast-it/design-system';
import { useProjectIndicatorViewController } from './useProjectIndicatorViewController';
const ProjectIndicator = ({ project, outline = false }) => {
    const projectIndicatorData = useProjectIndicatorViewController(project);
    if (!projectIndicatorData) {
        return React.createElement(React.Fragment, null); // temp fix until we fix the recent project dropdown that shows empty projects
    }
    const { workId, name, color } = projectIndicatorData;
    return React.createElement(WorkSignifier, { workId: workId, name: name, color: color, outline: outline });
};
// Exported raw component to reuse it in ProjectIndicatorJS that wraps it in a JS fragment (since JS and TS fragments are incompatible)
export { ProjectIndicator as ProjectIndicatorNoFragment };
export default createFragmentContainer(ProjectIndicator, {
    project: graphql `
		fragment ProjectIndicator_project on ProjectType {
			name
			companyProjectId
			customProjectId
			projectColor
		}
	`,
});
