// @ts-check
import React, {useCallback, useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {useLocation} from 'react-router-dom';
import styled from 'styled-components';

import {dispatch, EVENT_ID, subscribe, unsubscribe} from '../../../../containers/event_manager';
import {CSS_CONSTANTS} from '../../../../css_variables';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';
import useEventListener from '../../hooks/useEventListener';
import SlideoutContentWrapper from './SlideoutContentWrapper';

/**
 * @typedef SlideoutData
 * @property {string} [slideoutId]
 * @property {number} [width=535]
 * @property {Object} [content]
 * @property {string} [trackingName]
 * @property {string} [cy]
 * @property {boolean} [noHeader]
 * @property {React.MutableRefObject<HTMLDivElement | null>} [invokerRef] Used to pass focus back to invoker
 */

/**
 * @param {SlideoutData} slideoutData
 * @returns {void}
 */
export const toggleSlideout = (slideoutId, slideoutData) => dispatch(EVENT_ID.TOGGLE_SLIDEOUT, slideoutId, slideoutData);

/**
 * @returns {void}
 */
export const closeSlideout = () => dispatch(EVENT_ID.CLOSE_SLIDEOUT);

/**
 * @typedef {{noHeader: boolean; width: number;}} SlideoutContainerProps
 */
const SlideoutContainer =
	/** @type {import('styled-components').ThemedStyledFunction<'div', SlideoutContainerProps>} */
	(styled.div)`
		z-index: 99;
		box-sizing: border-box;
		position: fixed;
		width: ${props => (props.shouldShow ? `${props.width}px` : '0px')};
		display: flex;
		flex-direction: column;
		top: 0;
		bottom: 0;
		right: 0;
		margin-top: ${props => (props.noHeader ? '36px' : '131px')};
		padding: ${props => (props.shouldShow ? '34px 30px' : 0)};
		border-left: ${props => (props.shouldShow ? `1px solid ${CSS_CONSTANTS.v2_border_gray}` : 'none')};
		border-top: 1px solid ${CSS_CONSTANTS.v2_border_gray};
		background-color: white;
		box-shadow: 0px 3px 5px 0px rgba(74, 89, 105, 0.14);
		transition: width 0.1s ease-in-out;
	`;

/**
 * @typedef {object} SlideoutConductorProps
 * @property {string} slideoutId Unique identifier of slideout
 * @property {number} [width=535] Use to override default slideout width
 */

/**
 * @type {React.FC<SlideoutConductorProps>}
 */
const SlideoutConductor = () => {
	/** @type {SlideoutData} */
	const initialState = {};

	const [slideoutData, setSlideoutData] = useState(initialState);
	const location = useLocation();

	const openSlideout = (id, data) => {
		if (data.trackingName) {
			trackEvent(data.trackingName, 'Toggled', {expanded: true});
		}
		setSlideoutData({slideoutId: id, ...data});
	};

	const closeSlideout = useCallback(
		refocus => {
			if (slideoutData.content) {
				if (slideoutData.trackingName) {
					trackEvent(slideoutData.trackingName, 'Toggled', {expanded: false});
				}
				setSlideoutData(initialState);

				if (refocus && slideoutData.invokerRef?.current) {
					slideoutData.invokerRef.current?.focus();
				}
			}
		},
		[slideoutData]
	);

	const toggleSlideout = useCallback(
		(id, data) => {
			if (id !== slideoutData?.slideoutId) {
				openSlideout(id, data);
			} else {
				closeSlideout();
			}
		},
		[slideoutData]
	);

	const handleKeyDown = useCallback(
		keyboardEvent => {
			if (keyboardEvent.key === 'Escape') {
				closeSlideout(true);
			}
		},
		[slideoutData]
	);

	useEffect(() => {
		subscribe(EVENT_ID.TOGGLE_SLIDEOUT, toggleSlideout);
		subscribe(EVENT_ID.CLOSE_SLIDEOUT, closeSlideout);
		return () => {
			unsubscribe(EVENT_ID.TOGGLE_SLIDEOUT, toggleSlideout);
			unsubscribe(EVENT_ID.CLOSE_SLIDEOUT, closeSlideout);
		};
	}, [slideoutData]);

	useEffect(() => {
		closeSlideout();
	}, [location]);
	useEventListener('keydown', handleKeyDown);

	const portalRoot = document.querySelector('#root-portal-container');

	return portalRoot
		? ReactDOM.createPortal(
				<SlideoutContainer
					shouldShow={slideoutData.slideoutId}
					width={slideoutData.width || 535}
					noHeader={slideoutData.noHeader}
					data-cy={slideoutData.cy}
					onClick={e => e.stopPropagation()}
				>
					{slideoutData.content ? (
						<SlideoutContentWrapper closeSlideout={closeSlideout}>{slideoutData.content}</SlideoutContentWrapper>
					) : null}
				</SlideoutContainer>,
				portalRoot
		  )
		: null;
};

export default SlideoutConductor;
