import React from 'react';
import {useIntl} from 'react-intl';

export const TaskModalTimeEntriesPlaceholder = () => {
	const intl = useIntl();
	return (
		<div className="header-tile header-tile-time-entries">
			<div className="tile-wrapper">
				<div className="tile-container">
					<div className="tile-label">{intl.formatMessage({id: 'common.time_entries'})}</div>
					<div className="text-container">-</div>
				</div>
			</div>
		</div>
	);
};
