/**
 * Workaround for implementing the partial fetch pattern with Relay.
 * The partial fetch pattern refers to the practice of first loading a smaller segment of a component's the data, and then triggering a refetch to load the data in its entirety once the component is mounted.
 *
 * Unfortunately, this pattern is vulnerable to a race-condition when combined with any fetchPolicy that uses the store, e.g. "store-and-network" as used by ForecastQueryRenderer by default.
 * When the Query is in the store, the QueryRenderer will mount the page before the Query has finished, in order to render it immediately.
 * This causes the partial fetch and the full fetch to be executed in parallel instead of sequentially. If the partial fetch ends up finishing after the full fetch, the partial result will overwrite
 * the full result, leaving the page in a state of partial data.
 *
 * Unfortunately, Relay does not expose a nice way to inspect whether or not a query will use the store before it is run, and neither does the child component know whether the data came
 * from Store or from Network. This workaround aims to emulate this control. By manually calling setQueryInRelayStore once the full fetch has finished
 * the first time (when it is guaranteed to be sourced from Network), future invocations of the same query can use this cache to read that the query is likely already in the Relay store and react appropriately.
 *
 * Do note that this solution is not robust - it is possible for the Relay Store to garbage-collect items in the Store without this cache being invalidated. As such, it is important
 * that the component using the partial fetch pattern defaults to performing the full fetch if the Query is in the cache
 * (it should likely do this anyway - the page being cached means the user will not perceive potential slowness and any UX gains from the partial fetch pattern are neglected).
 *
 * Hopefully, this workaround is obsolete in the hook-based versions of Relay since the hooks seem to expose callback functions that would be a more robust replacement.
 * Finally, the partial fetch pattern should generally be considered a quick-fix, not an ideal solution.
 */
export var Query;
(function (Query) {
    Query[Query["OverviewProjects"] = 0] = "OverviewProjects";
})(Query || (Query = {}));
const CACHE = new Set();
export const setQueryInRelayStore = (query) => {
    CACHE.add(query);
};
export const isQueryInRelayStore = (query) => {
    return CACHE.has(query);
};
