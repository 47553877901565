import React, {useRef, useEffect, useState, memo} from 'react';
import PropTypes from 'prop-types';
import LabelNamed from './label_named';
import styled from 'styled-components';
import {injectIntl} from 'react-intl';
import ForecastTooltip from '../tooltips/ForecastTooltip';

const TooltipLabelWrapper = styled.div`
	display: flex;
	width: 100%;
	padding-left: 4px;
`;

const LabelsGroup = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: ${props => (props.noWrap ? 'nowrap' : 'wrap')};
	position: relative;
	overflow: hidden;
	margin-right: ${props => (props.noWrap ? '4px' : '8px')};
	width: 100%;
	&.low-z-index {
		z-index: -1;
	}
`;

const Dots = styled.div`
	font-size: 16px;
`;

const LabelGroup = ({intl, labels, noWrap, lowZIndex}) => {
	const [isOverflowing, setIsOverflowing] = useState(false);

	const labelGroupRef = useRef(null);
	const labelRef = useRef(null);

	const calculateOverflow = el => {
		return el.scrollWidth > el.clientWidth;
	};

	useEffect(() => {
		if (labelGroupRef.current) {
			const overflowing = calculateOverflow(labelGroupRef.current);
			if (overflowing !== isOverflowing) {
				setIsOverflowing(overflowing);
			}
		}
	}, [labels]);

	if (labelGroupRef.current) {
		const overflowing = calculateOverflow(labelGroupRef.current);
		if (overflowing !== isOverflowing) {
			setIsOverflowing(overflowing);
		}
	}

	const labelNames = labels.map((entry, index) => (entry ? (index > 0 ? ', ' : '') + entry.label.name : ''));
	const labelsTooltipText = [labelNames];
	return noWrap ? (
		<TooltipLabelWrapper>
			<ForecastTooltip spanStyle={{width: '100%', display: 'flex'}} content={labelsTooltipText}>
				<LabelsGroup
					className={`label-content ${lowZIndex ? 'low-z-index' : ''}`}
					noWrap={noWrap}
					ref={el => (labelGroupRef.current = el)}
				>
					{labels.map((entry, index) => {
						return entry ? (
							<LabelNamed
								key={index}
								noTitle
								passedRef={labelRef}
								label={entry.label}
								index={index}
								cy={'task-modal-label-picker'}
							/>
						) : null;
					})}
				</LabelsGroup>
				{isOverflowing ? <Dots className={`label-content  ${lowZIndex ? 'low-z-index' : ''}`}>...</Dots> : null}
			</ForecastTooltip>
		</TooltipLabelWrapper>
	) : (
		<LabelsGroup noWrap={noWrap} ref={el => (labelGroupRef.current = el)}>
			{labels.map((entry, index) => {
				return entry ? (
					<LabelNamed key={index} passedRef={labelRef} label={entry.label} index={index} lowZIndex={lowZIndex} />
				) : null;
			})}
		</LabelsGroup>
	);
};

LabelGroup.propTypes = {
	labels: PropTypes.array.isRequired,
	lowZIndex: PropTypes.bool,
	noWrap: PropTypes.bool,
};

export default injectIntl(memo(LabelGroup));
