import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import styled from 'styled-components';
import {WarningIcon} from 'web-components/icons';
import {PercentageInputWrapper} from '../../../components/inputs/percentage_input_wrapper';
import {CSS_CONSTANTS} from '../../../css_variables';

const ThresholdWrapper = styled.div`
	border: 1px solid ${CSS_CONSTANTS.v2_border_gray};
	border-radius: 4px;
	padding: 16px;
`;

const ThresholdRow = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 8px;
	&:last-child {
		margin-bottom: 0;
	}
`;

const Text = styled.div`
	font-size: 13px;
	color: ${CSS_CONSTANTS.v2_text_gray};
	margin: 0 4px;
`;

const TitleText = styled(Text)`
	margin: 0 0 24px 0;
`;

export const BudgetThresholdsUpdateForm = ({revenueMarginLow, revenueMarginHigh, callback}) => {
	const intl = useIntl();
	const [hasError, setHasError] = useState(false);

	const updateLow = marginLow => {
		if (marginLow < 0) {
			setHasError(true);
			return;
		}
		setHasError(false);
		const clampedMarginLow = Math.min(marginLow, 1);
		if (clampedMarginLow === revenueMarginLow) return;
		const updatedMarginHigh = clampedMarginLow > revenueMarginHigh ? clampedMarginLow : revenueMarginHigh;
		callback(clampedMarginLow, updatedMarginHigh);
	};

	const updateHigh = marginHigh => {
		if (marginHigh < 0) {
			setHasError(true);
			return;
		}
		setHasError(false);
		const clampedMarginHigh = Math.min(marginHigh, 1);
		if (clampedMarginHigh === revenueMarginHigh) return;
		const updatedMarginLow = revenueMarginLow > clampedMarginHigh ? clampedMarginHigh : revenueMarginLow;
		callback(updatedMarginLow, clampedMarginHigh);
	};

	return (
		<ThresholdWrapper>
			<TitleText>Warn me when the project total billable time and expenses at completion is more than:</TitleText>
			<ThresholdRow>
				<PercentageInputWrapper
					intl={intl}
					value={revenueMarginLow != null ? revenueMarginLow * 100 : 0}
					hasError={hasError}
					callback={value => updateLow(value)}
					placeholder={'%'}
					width={'75px'}
				/>
				<Text>of the fixed price budget</Text>
				<WarningIcon variant={WarningIcon.VARIANT.WARNING} />
			</ThresholdRow>
			<ThresholdRow>
				<PercentageInputWrapper
					intl={intl}
					value={revenueMarginHigh != null ? revenueMarginHigh * 100 : 0}
					hasError={hasError}
					callback={value => updateHigh(value)}
					placeholder={'%'}
					width={'75px'}
				/>
				<Text>of the fixed price budget</Text>
				<WarningIcon variant={WarningIcon.VARIANT.DANGER} />
			</ThresholdRow>
		</ThresholdWrapper>
	);
};
