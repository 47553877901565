import React from 'react';
export const ForecastLogo = ({width = "81", height = "14"}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 81 14"
            fill="none"
            style={{marginBottom: '2px'}}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.94393 13.6872V8.48603H8.92863V6.02235H2.94393V2.46369H10.42V0H0V13.6872H2.94393Z"
                fill="#0004DB"
            />
            <path
                d="M15.5786 14C18.5419 14 20.6724 11.986 20.6724 8.79888C20.6724 5.67039 18.5419 3.61732 15.5786 3.61732C12.6153 3.61732 10.4849 5.67039 10.4849 8.79888C10.4849 11.8101 12.6153 14 15.5786 14ZM15.5786 11.7905C14.2616 11.7905 13.3513 10.852 13.3513 8.83799C13.3513 6.82402 14.2616 5.80726 15.5786 5.80726C16.8957 5.80726 17.806 6.82402 17.806 8.83799C17.806 10.852 16.8957 11.7905 15.5786 11.7905Z"
                fill="#0004DB"
            />
            <path
                d="M25.0438 13.6872V9.75698C25.0438 7.31285 25.9154 6.53073 27.8909 6.53073C28.2202 6.53073 28.5688 6.55028 28.9562 6.58939V3.73464C28.8012 3.71508 28.6656 3.71508 28.5107 3.71508C26.1672 3.71508 25.315 5.33799 24.9857 6.13966V3.91061H22.2936V13.6872H25.0438Z"
                fill="#0004DB"
            />
            <path
                d="M34.315 14C37.259 14 38.4791 12.3966 39.0214 11.1453L36.5617 10.1872C36.155 11.1061 35.5546 11.8883 34.315 11.8883C33.1336 11.8883 32.2233 11.1844 32.1458 9.46369H38.9827C39.2538 6.19832 37.5495 3.61732 34.2957 3.61732C31.4873 3.61732 29.3956 5.7095 29.3956 8.77933C29.3956 11.986 31.3324 14 34.315 14ZM32.2426 7.50838C32.4363 6.27654 33.1917 5.61173 34.2957 5.61173C35.4577 5.61173 36.155 6.29609 36.2712 7.50838H32.2426Z"
                fill="#0004DB"
            />
            <path
                d="M45.4189 14C47.7043 14 49.4668 12.7486 50.2028 10.8715L47.6075 9.93296C47.1814 11.1844 46.4841 11.7905 45.3608 11.7905C44.0825 11.7905 42.9979 10.7933 42.9979 8.77933C42.9979 6.76536 44.0438 5.80726 45.3414 5.80726C46.3486 5.80726 47.1233 6.39385 47.5106 7.50838L50.0479 6.55028C49.4087 4.88827 47.7624 3.61732 45.4383 3.61732C42.5137 3.61732 40.2283 5.63128 40.2283 8.77933C40.2283 11.9274 42.4362 14 45.4189 14Z"
                fill="#0004DB"
            />
            <path
                d="M55.322 14C56.9876 14 58.0916 13.0419 58.5371 12.3771V13.6872H61.2098V3.91061H58.4596V5.22067C58.0141 4.53631 56.9489 3.61732 55.322 3.61732C52.7461 3.61732 51.1191 5.7095 51.1191 8.79888C51.1191 11.8883 52.7461 14 55.322 14ZM56.2904 11.8883C55.0121 11.8883 54.005 11.0866 54.005 8.79888C54.005 6.51117 55.0121 5.7095 56.2904 5.7095C57.6461 5.7095 58.5758 6.72626 58.5758 8.79888C58.5758 10.8715 57.6461 11.8883 56.2904 11.8883Z"
                fill="#0004DB"
            />
            <path
                d="M67.4261 13.9609C70.2538 13.9609 71.9194 12.6899 71.9194 10.7346C71.9194 8.87709 70.4087 8.03631 68.5881 7.68436L67.2517 7.43017C66.2059 7.23464 65.9153 6.98045 65.9153 6.47207C65.9153 5.94413 66.3608 5.53352 67.2905 5.53352C68.2589 5.53352 68.9561 6.10056 69.2466 6.90223L71.5127 5.86592C71.0866 4.88827 69.847 3.61732 67.4261 3.61732C64.8307 3.61732 63.2619 4.84916 63.2619 6.7067C63.2619 8.6229 64.4434 9.30726 66.477 9.71788L67.8521 9.99162C68.8399 10.1872 69.053 10.5782 69.053 11.0279C69.053 11.5754 68.5494 12.0056 67.581 12.0056C65.7023 12.0056 65.4118 10.5782 65.373 10.3631L62.8746 11.1648C63.2813 12.8855 65.1406 13.9609 67.4261 13.9609Z"
                fill="#0004DB"
            />
            <path
                d="M80.1818 13.6872V11.4777H78.0901C77.4897 11.4777 77.2379 11.2039 77.2379 10.6173V6.02235H79.7557V3.91061H77.2379V0.840783H74.4876V3.91061H72.454V6.02235H74.4876V11.1257C74.4876 12.6508 75.3786 13.6872 76.9474 13.6872H80.1818Z"
                fill="#0004DB"
            />
        </svg>)

}