import React, {useState} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import styled from 'styled-components';
import {hideAll} from 'tippy.js';
import {CSS_CONSTANTS} from '../../../../../../../../css_variables';
import * as tracking from '../../../../../../../../tracking';
import Util from '../../../../../../util/util';
import {useIntl} from 'react-intl';
import {Link, useHistory} from 'react-router-dom';
import DismissSimilarTaskMutation from '../../../../../../../../mutations/DismissSimilarTaskMutation';
import {createToast} from '../../../../../toasts/toast';
import {trackComplexEvent, trackEvent} from '../../../../../../../../tracking/amplitude/TrackingV2';
import DeprecatedProjectIndicatorJS from '../../../../../project-indicator/js/DeprecatedProjectIndicatorJS';

const SimilarTaskWrapper = styled.div`
	width: 100%;
	border: 1px solid ${CSS_CONSTANTS.v2_border_gray};
	border-radius: 4px;
	padding: 0 8px 8px;
	display: grid;
	grid-template-columns: 1fr auto auto auto;
	color: ${CSS_CONSTANTS.v2_text_gray};
`;

const SimilarTaskTitle = styled.div`
	display: flex;
	margin-right: 4px;
	align-items: center;
	font-weight: 400;
	font-size: 13px;
	overflow: hidden;
	.task-title {
		margin-left: 8px;
		text-decoration: none;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
`;

const SimilarTaskElement = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 62px;
	height: 30px;
	text-align: right;
	margin-right: 8px;
	font-weight: 400;
	font-size: 13px;
`;

const SimilarTaskHeaderTitle = styled(SimilarTaskTitle)`
	font-size: 9px;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: ${CSS_CONSTANTS.v2_text_light_gray};
`;

const SimilarTaskHeaderElement = styled(SimilarTaskElement)`
	font-size: 9px;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: ${CSS_CONSTANTS.v2_text_light_gray};
`;

const SimilarTaskDiscardButton = styled.button`
	font-size: 13px;
	font-weight: 400;
	border: none;
	color: ${CSS_CONSTANTS.v2_branding_purple};
`;

const trackTaskModalOpened = () => {
	tracking.trackEvent('Task Modal triggered from Task Overrun');
	trackComplexEvent('Task Modal', 'Triggered', {triggerLocation: 'From Task Overrun'});
};

const SimilarTaskSection = ({viewer}) => {
	const intl = useIntl();
	const history = useHistory();

	// hacky optimistic response workaround, could be changed to relay optimistic response with a sizable time investment
	const [dismissedTaskIds, setDismissedTaskIds] = useState([]);

	const similarTasks = viewer.task?.similarTasks?.edges
		.filter(task => task.node.fullAccessToProject && !dismissedTaskIds.includes(task.node.id))
		.map(task => task.node)
		.slice(0, 3);

	const openTaskModal = () => {
		trackTaskModalOpened();
		hideAll({duration: 0});
	};

	const dismissSimilarTask = (similarTaskId, similarTaskName) => {
		setDismissedTaskIds([...dismissedTaskIds, similarTaskId]);
		tracking.trackEvent('Similar task dismissed', {
			taskId: viewer.task.id,
			taskName: viewer.task.name,
			dismissedSimilarTaskId: similarTaskId,
			dismissedSimilarTaskName: similarTaskName,
		});

		trackEvent('Similar Task', 'Dismissed', {
			taskId: viewer.task.id,
			taskName: viewer.task.name,
			dismissedSimilarTaskId: similarTaskId,
			dismissedSimilarTaskName: similarTaskName,
		});

		Util.CommitMutation(
			DismissSimilarTaskMutation,
			{
				taskId: viewer.task.id,
				dismissedTaskId: similarTaskId,
				similarTaskId1: similarTasks[0]?.id,
				similarTaskId2: similarTasks[1]?.id,
				similarTaskId3: similarTasks[2]?.id,
			},
			() => createToast({duration: 3000, message: 'The task has been marked as not similar'})
		);
	};

	return similarTasks.length > 0 ? (
		<SimilarTaskWrapper>
			<SimilarTaskHeaderTitle>Similar Tasks</SimilarTaskHeaderTitle>
			<SimilarTaskHeaderElement>Estimated</SimilarTaskHeaderElement>
			<SimilarTaskHeaderElement>Actual</SimilarTaskHeaderElement>
			<SimilarTaskHeaderElement></SimilarTaskHeaderElement>
			{similarTasks.map(similarTask => {
				const minutesRegistered = similarTask.timeRegistrations.edges.reduce(
					(total, timeReg) => total + timeReg.node.minutesRegistered,
					0
				);
				return [
					<SimilarTaskTitle>
						<DeprecatedProjectIndicatorJS project={similarTask.project} small noWrapper />
						<Link
							className="task-title"
							title={similarTask.name}
							to={
								(history.location.pathname.endsWith('/')
									? history.location.pathname
									: history.location.pathname + '/') +
								'T' +
								similarTask.companyTaskId
							}
							onClick={() => openTaskModal()}
						>
							{similarTask.name}
						</Link>
					</SimilarTaskTitle>,
					<SimilarTaskElement>
						{Util.convertMinutesToFullHour(similarTask.estimateForecast, intl)}
					</SimilarTaskElement>,
					<SimilarTaskElement>{Util.convertMinutesToFullHour(minutesRegistered, intl)}</SimilarTaskElement>,
					<SimilarTaskDiscardButton onClick={() => dismissSimilarTask(similarTask.id, similarTask.name)}>
						Not similar?
					</SimilarTaskDiscardButton>,
				];
			})}
		</SimilarTaskWrapper>
	) : null;
};

const SimilarTaskSectionQuery = graphql`
	query SimilarTaskSection_Query($taskId: ID!) {
		viewer {
			actualPersonId
			component(name: "similar_task_section")
			...SimilarTaskSection_viewer @arguments(taskId: $taskId)
		}
	}
`;
export {SimilarTaskSectionQuery};

export default createFragmentContainer(SimilarTaskSection, {
	viewer: graphql`
		fragment SimilarTaskSection_viewer on Viewer @argumentDefinitions(taskId: {type: "ID!"}) {
			id
			task(id: $taskId) {
				id
				name
				similarTasks(overrunOnly: true) {
					edges {
						node {
							id
							companyTaskId
							name
							estimateForecast
							fullAccessToProject
							project {
								id
								name
								projectColor
								companyProjectId
								...DeprecatedProjectIndicatorJS_project
							}
							timeRegistrations(first: 1000000) {
								edges {
									node {
										minutesRegistered
									}
								}
							}
						}
					}
				}
			}
		}
	`,
});
