import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { FlexRow, Icon, Tabs } from '@forecast-it/design-system';
import HeaderLabel from '../../../../components/new-ui/HeaderLabel';
import { APPROVAL_STATUS, BUTTON_COLOR, BUTTON_STYLE, GLOBAL_FILTER_FIELD, GLOBAL_FILTER_OPERATOR } from '../../../../constants';
import { EVENT_ID } from '../../../../containers/event_manager';
import useSubscription from '../../../../forecast-app/shared/hooks/useSubscription';
import ForecastQueryRenderer from '../../../../ForecastQueryRenderer';
import { trackEvent } from '../../../../tracking/amplitude/TrackingV2';
import { trackTimeRegistrationSuggestionsShown } from '../../../../tracking/tracking_types/TrackTimeRegistrationSuggestionsShown';
import { CUSTOM_FIELD_PREFIX } from '../../../project-tab/projects/scoping-page/ProjectScopingUtil';
import Button from '../../../shared/components/buttons/button/button';
import { parseTaskFilters } from '../../../shared/components/filters/filter_logic';
import SearchLazy from '../../../shared/components/headers/header-bar/SearchLazy';
import TheEye from '../../../shared/components/headers/header-bar/TheEye';
import InlineLoader from '../../../shared/components/inline-loader/inline_loader';
import ForecastTooltip from '../../../shared/components/tooltips/ForecastTooltip';
import { useForecastFetchQuery } from '../../../shared/hooks/useForecastFetchQuery';
import { stringToEnumValue } from '../../../shared/util/EnumUtil';
import Util from '../../../shared/util/util';
import { getValidSuggestions } from '../time-registration-target-suggestions/TimeRegistrationTargetSuggestionUtil';
import { TIME_PERIOD } from '../timesheets_change_date_view';
import { getHolidaysOfTheDay } from '../timesheets_person_data';
import { DateInfoSectionWrapper, TabSectionWrapper } from '../timesheets_styled';
import { getInitialOptions, handleChangedOptions } from '../timesheets_the_eye_options';
import { LOCALSTORAGE_TIMESHEETS_SELECTED_TAB } from '../top-section/TimesheetsCalendarUtil';
import { BottomSectionWrapper } from './BottomSectionWrapper_styled';
import { addTimeRegTypes, groupRegs } from './timesheets-time-registration-table/timesheet-table-util/DataUtil';
import { TimesheetsTaskCountQuery } from './timesheets-time-registration-table/timesheet-table-wrapper/TimesheetsTaskCountQuery';
import TimesheetThisWeeksWorkTableWrapper, { TimesheetThisWeeksWorkTableWrapperQuery, } from './timesheets-time-registration-table/timesheet-table-wrapper/TimesheetThisWeeksWorkTableWrapper';
import TimesheetTimeTableWrapper, { TimesheetTimeTableWrapperQuery, } from './timesheets-time-registration-table/timesheet-table-wrapper/TimesheetTimeTableWrapper';
import TimesheetsApprovalButtons from './TimesheetsApprovalButtons';
import { getInitialState, TimesheetsFilters } from './TimesheetsFilters';
import { getPeriodTitle, suggestionsWithoutTimeRegs } from './TimesheetUtil';
var TimesheetTab;
(function (TimesheetTab) {
    TimesheetTab["ThisWeeksWork"] = "ThisWeeksWork";
    TimesheetTab["Timesheet"] = "Timesheet";
})(TimesheetTab || (TimesheetTab = {}));
function getDefaultTab(calendarFetchEnabled) {
    return calendarFetchEnabled ? TimesheetTab.Timesheet : TimesheetTab.ThisWeeksWork;
}
function getSelectedTab(calendarFetchEnabled) {
    var _a;
    const localSelectedTab = localStorage.getItem(LOCALSTORAGE_TIMESHEETS_SELECTED_TAB);
    return (_a = stringToEnumValue(localSelectedTab, TimesheetTab)) !== null && _a !== void 0 ? _a : getDefaultTab(calendarFetchEnabled);
}
var SortableColumn;
(function (SortableColumn) {
    SortableColumn["ProjectIndicator"] = "project-indicator";
    SortableColumn["ProjectName"] = "project-name";
    SortableColumn["TaskName"] = "task-name";
    SortableColumn["Phase"] = "phase";
    SortableColumn["Client"] = "client";
})(SortableColumn || (SortableColumn = {}));
function getLocalSortValue() {
    var _a;
    const localSortValue = localStorage.getItem('time-page-sort');
    return (_a = stringToEnumValue(localSortValue, SortableColumn)) !== null && _a !== void 0 ? _a : SortableColumn.ProjectIndicator;
}
function parseSortValue(value) {
    switch (value) {
        case SortableColumn.ProjectName:
            return 'project-name';
        case SortableColumn.TaskName:
            return 'task-name';
        case SortableColumn.Phase:
            return 'phase-name';
        case SortableColumn.Client:
            return 'client-name';
        case SortableColumn.ProjectIndicator:
        default:
            return `project-id`;
    }
}
function parseSort(sort) {
    const directionSuffix = sort.ascending ? '-asc' : '-dsc';
    return `${parseSortValue(sort.value)}${directionSuffix}`;
}
const TimesheetsBottomSection = ({ timeRegistrations, currentViewingDate, timePeriod, selectedPerson, nonViewerPersonSelected, selectDayFromCalendar, lastWorkingDayInWeek, lockedDate, companyLockedDate, usingTimeApproval, usingInternalTimeApproval, usingTimeOffApproval, customFieldDefinitions, overBudgetProgramsByCompanyProjectId, timeRegistrationTargetSuggestionsData, paginationRef, viewer, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const { formatMessage } = useIntl();
    const initialSelectedTab = useMemo(() => {
        var _a, _b, _c, _d;
        return getSelectedTab(((_b = (_a = viewer.calendarIntegrations) === null || _a === void 0 ? void 0 : _a.googleCalendar) === null || _b === void 0 ? void 0 : _b.calendarFetchEnabled) ||
            ((_d = (_c = viewer.calendarIntegrations) === null || _c === void 0 ? void 0 : _c.outlookCalendar) === null || _d === void 0 ? void 0 : _d.calendarFetchEnabled) ||
            null);
    }, []);
    const [selectedTab, setSelectedTab] = useState(initialSelectedTab);
    // If google calendar is disable or enabled. Change tab order and default tab.
    const didMount = React.useRef(false);
    useEffect(() => {
        var _a, _b, _c, _d;
        if (didMount.current) {
            setSelectedTab(getDefaultTab(((_b = (_a = viewer.calendarIntegrations) === null || _a === void 0 ? void 0 : _a.googleCalendar) === null || _b === void 0 ? void 0 : _b.calendarFetchEnabled) ||
                ((_d = (_c = viewer.calendarIntegrations) === null || _c === void 0 ? void 0 : _c.outlookCalendar) === null || _d === void 0 ? void 0 : _d.calendarFetchEnabled) ||
                null));
        }
        else {
            didMount.current = true;
        }
    }, [
        (_b = (_a = viewer.calendarIntegrations) === null || _a === void 0 ? void 0 : _a.googleCalendar) === null || _b === void 0 ? void 0 : _b.calendarFetchEnabled,
        (_d = (_c = viewer.calendarIntegrations) === null || _c === void 0 ? void 0 : _c.outlookCalendar) === null || _d === void 0 ? void 0 : _d.calendarFetchEnabled,
    ]);
    const [theEyeOptions, setTheEyeOptions] = useState(getInitialOptions(customFieldDefinitions));
    const [filters, setFilters] = useState(getInitialState(selectedPerson.id));
    const [sortValue, setSortValue] = useState(getLocalSortValue());
    const localSortAscending = localStorage.getItem('time-page-sort-ascending');
    const [sortAscending, setSortAscending] = useState(localSortAscending ? JSON.parse(localSortAscending) : true);
    const [searchString, setSearchString] = useState('');
    const [showSuggestions, setShowSuggestions] = React.useState(false);
    const validTimeRegistrationSuggestions = useMemo(() => getValidSuggestions(timeRegistrationTargetSuggestionsData, selectedPerson.id), [timeRegistrationTargetSuggestionsData, selectedPerson.id]);
    const hasSuggestions = !nonViewerPersonSelected && !!validTimeRegistrationSuggestions && validTimeRegistrationSuggestions.length > 0;
    const handleTabChange = value => {
        trackEvent('Timesheet Table', 'Tab Changed', { prevTab: selectedTab, newTab: value });
        localStorage.setItem(LOCALSTORAGE_TIMESHEETS_SELECTED_TAB, value);
        setSelectedTab(value);
    };
    const goToTimesheetsTab = () => {
        trackEvent('Timesheet Table Totals', 'Clicked');
        handleTabChange(TimesheetTab.Timesheet);
    };
    const setAndTrackShowSuggestions = useCallback((showSuggestions) => {
        var _a, _b;
        if (showSuggestions) {
            const newSuggestions = (_a = timeRegistrationTargetSuggestionsData === null || timeRegistrationTargetSuggestionsData === void 0 ? void 0 : timeRegistrationTargetSuggestionsData.timeRegistrationTargetSuggestions) === null || _a === void 0 ? void 0 : _a.sortedSuggestions;
            const newSuggestionsAvailable = newSuggestions && newSuggestions.length > 0;
            trackEvent('Time Registration Suggestions', 'Shown', { version: newSuggestionsAvailable ? 'New' : 'Old' });
            trackTimeRegistrationSuggestionsShown({
                companyId: viewer.company.id,
                personId: selectedPerson.id,
                modelVersion: (_b = timeRegistrationTargetSuggestionsData === null || timeRegistrationTargetSuggestionsData === void 0 ? void 0 : timeRegistrationTargetSuggestionsData.timeRegistrationTargetSuggestions) === null || _b === void 0 ? void 0 : _b.modelVersion,
            });
        }
        setShowSuggestions(showSuggestions);
    }, [timeRegistrationTargetSuggestionsData]);
    useSubscription(EVENT_ID.SHOW_TIME_REG_SUGGESTIONS, () => {
        trackEvent('Show Suggestions Link', 'Clicked', { availableSuggestions: validTimeRegistrationSuggestions === null || validTimeRegistrationSuggestions === void 0 ? void 0 : validTimeRegistrationSuggestions.length });
        setAndTrackShowSuggestions(true);
    });
    const onTimesheetPersonChange = useCallback(personId => {
        handleTabChange(TimesheetTab.Timesheet);
        setFilters({ task: { person: [personId] } });
    }, []);
    useSubscription(EVENT_ID.TIMESHEET_PERSON_CHANGED, onTimesheetPersonChange);
    const sort = useMemo(() => ({
        value: sortValue,
        setValue: setSortValue,
        ascending: sortAscending,
        setAscending: setSortAscending,
    }), [sortValue, sortAscending, setSortValue, setSortAscending]);
    const usingCurrentUserAssigneeFilter = useMemo(() => {
        return !nonViewerPersonSelected && JSON.stringify(filters) === JSON.stringify({ task: { person: [selectedPerson.id] } });
    }, [filters === null || filters === void 0 ? void 0 : filters.task]);
    const isWeekView = timePeriod === TIME_PERIOD.WEEK_VIEW;
    const columns = [...theEyeOptions];
    columns.splice(0, 0, { name: 'switch-task-indicator', checked: true });
    columns.splice(3, 0, { name: 'task-name', checked: true });
    columns.push({ name: 'hour-inputs', checked: true }, { name: 'notes', checked: true }, { name: 'harvest', checked: true }, { name: 'unit4', checked: true }, { name: 'star', checked: true }, { name: 'edit', checked: !isWeekView }, { name: 'delete', checked: true });
    const enabledColumns = useMemo(() => columns, [theEyeOptions, isWeekView]);
    const week = useMemo(() => Array(7)
        .fill(0)
        .map((n, i) => currentViewingDate.clone().startOf('w').add(i, 'day')), [currentViewingDate]);
    const holidays = isWeekView
        ? week.map(day => getHolidaysOfTheDay(selectedPerson, day))
        : getHolidaysOfTheDay(selectedPerson, currentViewingDate);
    const memoizedHolidays = useMemo(() => holidays, [isWeekView, selectedPerson, currentViewingDate]);
    const handleTheEyeOptionSelect = (_, __, ___, newOptions) => {
        // Preserve initial order
        const optionOrder = theEyeOptions.map(opt => opt.name);
        newOptions.sort((a, b) => (optionOrder.indexOf(a.name) > optionOrder.indexOf(b.name) ? 1 : -1));
        setTheEyeOptions(handleChangedOptions(newOptions));
    };
    const getTimesheetsApprovalStatus = timeRegs => {
        const projectTimeRegs = timeRegs.filter(tReg => !tReg.node.idleTime);
        let timeRegStatus = projectTimeRegs.length > 0 ? APPROVAL_STATUS.APPROVED : APPROVAL_STATUS.NOT_SUBMITTED;
        for (const tReg of projectTimeRegs) {
            if (!tReg.node || (tReg.node.approvalStatus === APPROVAL_STATUS.NOT_SUBMITTED && !tReg.node.idleTime)) {
                return APPROVAL_STATUS.NOT_SUBMITTED;
            }
            else if (tReg.node.approvalStatus === APPROVAL_STATUS.SUBMITTED) {
                timeRegStatus = APPROVAL_STATUS.SUBMITTED;
            }
        }
        return timeRegStatus;
    };
    const weekStart = useMemo(() => currentViewingDate.clone().startOf('week'), [currentViewingDate]);
    const weekEnd = useMemo(() => currentViewingDate.clone().endOf('week'), [currentViewingDate]);
    const isFullyLocked = useMemo(() => {
        if (lockedDate) {
            return isWeekView
                ? lockedDate.isSameOrAfter(currentViewingDate.clone().endOf('week'), 'd')
                : lockedDate.isSameOrAfter(currentViewingDate, 'd');
        }
        return false;
    }, [isWeekView, lockedDate, currentViewingDate]);
    const isWorkdaysLocked = useMemo(() => {
        if (lockedDate && lastWorkingDayInWeek) {
            return lockedDate.isSameOrAfter(lastWorkingDayInWeek, 'd');
        }
        return false;
    }, [isWeekView, lockedDate, lastWorkingDayInWeek]);
    const taskTypeSearchQuery = useMemo(() => {
        const searchQuery = { filters: parseTaskFilters(filters, false, false) };
        if (searchString && searchString !== '') {
            const queryNameSearch = {
                operator: GLOBAL_FILTER_OPERATOR.CONTAINS,
                field: GLOBAL_FILTER_FIELD.NAME,
                value: [searchString],
            };
            searchQuery.filters.push(queryNameSearch);
        }
        searchQuery.filters.push({
            field: GLOBAL_FILTER_FIELD.FILTER_BY_TIME_REG_SETTINGS,
            value: [
                isWeekView ? weekStart.format('YYYY-MM-DD') : currentViewingDate.format('YYYY-MM-DD'),
                isWeekView ? weekEnd.format('YYYY-MM-DD') : currentViewingDate.format('YYYY-MM-DD'),
            ],
        });
        searchQuery.filters.push({
            field: GLOBAL_FILTER_FIELD.START_DATE,
            operator: GLOBAL_FILTER_OPERATOR.LESS_OR_EQUAL,
            value: [isWeekView ? weekEnd.format('YYYY-MM-DD') : currentViewingDate.format('YYYY-MM-DD'), 'NULL'],
        });
        searchQuery.filters.push({
            field: GLOBAL_FILTER_FIELD.END_DATE,
            operator: GLOBAL_FILTER_OPERATOR.GREATER_OR_EQUAL,
            value: [isWeekView ? weekStart.format('YYYY-MM-DD') : currentViewingDate.format('YYYY-MM-DD'), 'NULL'],
        });
        searchQuery.filters.push({
            field: GLOBAL_FILTER_FIELD.PROJECT_MANUAL_PROGRESS,
            operator: GLOBAL_FILTER_OPERATOR.IN,
            value: ['false'],
        });
        return searchQuery;
    }, [currentViewingDate.format('YYYYMMDD'), isWeekView, filters, searchString]);
    const timeRegsForPeriod = useMemo(() => {
        return timeRegistrations.filter(tReg => {
            const tDate = Util.CreateNonUtcMomentDate(tReg.node.year, tReg.node.month, tReg.node.day);
            if (isWeekView) {
                return (tDate === null || tDate === void 0 ? void 0 : tDate.isSameOrAfter(weekStart, 'days')) && tDate.isSameOrBefore(weekEnd, 'days');
            }
            else {
                return currentViewingDate.isSame(tDate, 'date');
            }
        });
    }, [timeRegistrations, weekStart, weekEnd, isWeekView]);
    const periodTimeApprovalStatus = usingTimeApproval ? getTimesheetsApprovalStatus(timeRegsForPeriod) : null;
    const { fetch, data } = useForecastFetchQuery(TimesheetsTaskCountQuery);
    const timeSheetsTaskCountData = data;
    useEffect(() => {
        if (taskTypeSearchQuery === null || taskTypeSearchQuery === void 0 ? void 0 : taskTypeSearchQuery.filters) {
            fetch({
                taskTypeSearchQuery: taskTypeSearchQuery,
            });
        }
    }, [taskTypeSearchQuery]);
    const thisWeeksWorkItemsCount = ((_f = (_e = timeSheetsTaskCountData === null || timeSheetsTaskCountData === void 0 ? void 0 : timeSheetsTaskCountData.viewer) === null || _e === void 0 ? void 0 : _e.company) === null || _f === void 0 ? void 0 : _f.allTasksCount) || 0;
    useEffect(() => {
        theEyeOptions.forEach(option => {
            if (option.name.startsWith(CUSTOM_FIELD_PREFIX)) {
                option.hide = isWeekView;
            }
        });
        setTheEyeOptions(theEyeOptions);
    }, [timePeriod]);
    // Hide suggestions if the dates are changed
    useEffect(() => {
        setAndTrackShowSuggestions(false);
    }, [currentViewingDate, isWeekView]);
    // Hide suggestions if showing someone elses time regs
    useEffect(() => {
        if (nonViewerPersonSelected) {
            setAndTrackShowSuggestions(false);
        }
    }, [nonViewerPersonSelected]);
    // Group filtered timereg by type to show the row-count to make the number comparable to the "this weeks work" count.
    const groupedTimeRegs = groupRegs(addTimeRegTypes(timeRegsForPeriod));
    const timesheetItemsCount = groupedTimeRegs.size;
    const selectedPeriodTitle = getPeriodTitle(isWeekView, currentViewingDate);
    const toggleSuggestions = () => {
        trackEvent(showSuggestions ? 'Hide Suggested Entries Button' : 'Reveal Suggested Entries Button', 'Clicked', {
            availableSuggestions: validTimeRegistrationSuggestions === null || validTimeRegistrationSuggestions === void 0 ? void 0 : validTimeRegistrationSuggestions.length,
        });
        setAndTrackShowSuggestions(!showSuggestions);
    };
    const calendarFetchEnabled = ((_h = (_g = viewer.calendarIntegrations) === null || _g === void 0 ? void 0 : _g.googleCalendar) === null || _h === void 0 ? void 0 : _h.calendarFetchEnabled) ||
        ((_k = (_j = viewer.calendarIntegrations) === null || _j === void 0 ? void 0 : _j.outlookCalendar) === null || _k === void 0 ? void 0 : _k.calendarFetchEnabled);
    const getTabs = () => {
        return calendarFetchEnabled ? (React.createElement(React.Fragment, null,
            React.createElement(Tabs.Tab, { "data-cy": 'timesheet-tab', value: TimesheetTab.Timesheet }, `Timesheet (${timesheetItemsCount})`),
            React.createElement(Tabs.Tab, { "data-cy": 'this-weeks-work-tab', value: TimesheetTab.ThisWeeksWork }, `${selectedPeriodTitle} (${thisWeeksWorkItemsCount})`))) : (React.createElement(React.Fragment, null,
            React.createElement(Tabs.Tab, { "data-cy": 'this-weeks-work-tab', value: TimesheetTab.ThisWeeksWork }, `${selectedPeriodTitle} (${thisWeeksWorkItemsCount})`),
            React.createElement(Tabs.Tab, { "data-cy": 'timesheet-tab', value: TimesheetTab.Timesheet }, `Timesheet (${timesheetItemsCount})`)));
    };
    const hasSuggestionWithoutTimeReg = useMemo(() => suggestionsWithoutTimeRegs(timeRegsForPeriod, validTimeRegistrationSuggestions), [validTimeRegistrationSuggestions, timeRegsForPeriod]);
    let suggestionTooltip = null;
    if (!hasSuggestions) {
        suggestionTooltip = formatMessage({ id: 'timesheets.tooltip.no_suggestions' });
    }
    else if (!hasSuggestionWithoutTimeReg) {
        suggestionTooltip = formatMessage({ id: 'timesheets.tooltip.no_relevant_suggestions' });
    }
    return (React.createElement(BottomSectionWrapper, { "data-userpilot": "timesheets-task-list" },
        React.createElement(TabSectionWrapper, null,
            React.createElement(FlexRow, { justifyContent: 'space-between' },
                React.createElement(Tabs, { value: selectedTab, onValueChange: handleTabChange },
                    React.createElement(Tabs.List, null, getTabs())),
                React.createElement(FlexRow, { justifyContent: 'flex-end' },
                    React.createElement("div", { className: 'header-bar no-padding' },
                        React.createElement("div", { className: 'header-bar-right' },
                            selectedTab === TimesheetTab.ThisWeeksWork ? (React.createElement(React.Fragment, null,
                                React.createElement(SearchLazy, { dataCy: 'timesheetsSearch', key: `generic-search-timesheets`, onChange: setSearchString, value: searchString, alwaysExpanded: searchString !== '', placeholder: 'Search' }),
                                React.createElement(TimesheetsFilters, { filters: filters, setFilters: setFilters, viewer: viewer }))) : (React.createElement(ForecastTooltip, { content: suggestionTooltip },
                                React.createElement(Button, { cy: 'toggle-suggestions-button', userpilot: 'toggle-suggestions-button', isDisabled: !hasSuggestionWithoutTimeReg || !hasSuggestions, text: React.createElement(FlexRow, null,
                                        React.createElement(Icon, { icon: showSuggestions ? 'sparkleHide' : 'sparkle' }),
                                        showSuggestions && hasSuggestionWithoutTimeReg
                                            ? formatMessage({ id: 'timesheets.suggestions.hide_button' })
                                            : formatMessage({ id: 'timesheets.suggestions.reveal_button' })), buttonStyle: BUTTON_STYLE.OUTLINE_THICK, colorTheme: BUTTON_COLOR.VERYLIGHTGREY, onClick: toggleSuggestions }))),
                            React.createElement(TheEye, { expandLeft: true, key: `the-eye`, options: theEyeOptions, onSelect: handleTheEyeOptionSelect }),
                            selectedTab === TimesheetTab.Timesheet ? (React.createElement(TimesheetsApprovalButtons, { selectedPerson: selectedPerson, nonViewerPersonSelected: nonViewerPersonSelected, usingTimeApproval: usingTimeApproval, usingTimeOffApproval: usingTimeOffApproval, isWeekView: isWeekView, currentViewingDate: currentViewingDate, lockedDate: lockedDate, companyLockedDate: companyLockedDate, isFullyLocked: isFullyLocked, lastWorkingDayInWeek: lastWorkingDayInWeek })) : null))))),
        React.createElement(FlexRow, { justifyContent: 'flex-start' },
            isWeekView ? (React.createElement(DateInfoSectionWrapper, null, currentViewingDate.clone().startOf('week').format('DD') +
                ' - ' +
                currentViewingDate.clone().endOf('week').format('DD MMMM'))) : (React.createElement(DateInfoSectionWrapper, null,
                currentViewingDate.format('dddd DD MMMM'),
                "\u00A0",
                React.createElement("div", { className: 'holiday-names' }, holidays && holidays.length > 0 ? '(' + holidays.map(h => h.node.name).join(', ') + ')' : ''))),
            periodTimeApprovalStatus && isWorkdaysLocked && periodTimeApprovalStatus !== APPROVAL_STATUS.NOT_SUBMITTED ? (periodTimeApprovalStatus === APPROVAL_STATUS.APPROVED ? (React.createElement(HeaderLabel, { text: 'Approved', color: HeaderLabel.INDICATOR_COLOR.GREEN })) : (React.createElement(HeaderLabel, { text: 'Submitted', color: HeaderLabel.INDICATOR_COLOR.BLUE }))) : null),
        selectedTab === TimesheetTab.ThisWeeksWork ? (React.createElement(ForecastQueryRenderer, { key: "query-render-TimesheetThisWeeksWorkTableWrapper", query: TimesheetThisWeeksWorkTableWrapperQuery, customLoader: () => React.createElement(InlineLoader, null), fetchPolicy: 'network-only', variables: {
                pageSize: 50,
                taskTypeSearchQuery: taskTypeSearchQuery,
                sortValue: parseSort(sort),
                timePeriod: timePeriod,
                currentViewingDate: currentViewingDate.format('YYYY-MM-DD'),
                personId: selectedPerson.id,
            }, render: (relayProps, retry) => {
                return (React.createElement(TimesheetThisWeeksWorkTableWrapper, Object.assign({}, relayProps, { paginationRef: paginationRef, retry: retry, selectedPerson: selectedPerson, selectedSwitch: 'none', enabledColumns: enabledColumns, timeRegistrations: timeRegistrations, timeRegsForPeriod: timeRegsForPeriod, weekStart: weekStart, weekEnd: weekEnd, currentViewingDate: currentViewingDate, timePeriod: timePeriod, filterActive: !!(filters === null || filters === void 0 ? void 0 : filters.task), searchActive: !!searchString, usingCurrentUserAssigneeFilter: usingCurrentUserAssigneeFilter, sort: sort, selectDayFromCalendar: selectDayFromCalendar, holidays: memoizedHolidays, lockedDate: lockedDate, isFullyLocked: isFullyLocked, usingTimeApproval: usingTimeApproval, usingInternalTimeApproval: usingInternalTimeApproval, usingTimeOffApproval: usingTimeOffApproval, periodTimeApprovalStatus: periodTimeApprovalStatus, overBudgetProgramsByCompanyProjectId: overBudgetProgramsByCompanyProjectId, timeRegistrationTargetSuggestionsData: timeRegistrationTargetSuggestionsData, goToTimesheetsTab: goToTimesheetsTab })));
            } })) : (React.createElement(ForecastQueryRenderer, { key: "query-render-TimesheetTimeTableWrapper", query: TimesheetTimeTableWrapperQuery, customLoader: () => React.createElement(InlineLoader, null), render: (relayProps, retry) => {
                return (React.createElement(TimesheetTimeTableWrapper, Object.assign({}, relayProps, { retry: retry, selectedPerson: selectedPerson, enabledColumns: enabledColumns, timeRegistrations: timeRegistrations, timeRegsForPeriod: timeRegsForPeriod, weekStart: weekStart, weekEnd: weekEnd, currentViewingDate: currentViewingDate, timePeriod: timePeriod, searchValue: '', sort: sort, selectDayFromCalendar: selectDayFromCalendar, holidays: memoizedHolidays, lockedDate: lockedDate, isFullyLocked: isFullyLocked, usingTimeApproval: usingTimeApproval, usingInternalTimeApproval: usingInternalTimeApproval, usingTimeOffApproval: usingTimeOffApproval, periodTimeApprovalStatus: periodTimeApprovalStatus, overBudgetProgramsByCompanyProjectId: overBudgetProgramsByCompanyProjectId, 
                    // The full set of suggestions and the valid suggestions are passed along separately because the tracking requires the full set, but the table itself only requires the valid
                    timeRegistrationTargetSuggestionsData: timeRegistrationTargetSuggestionsData, validTimeRegistrationSuggestions: validTimeRegistrationSuggestions, goToTimesheetsTab: goToTimesheetsTab, showSuggestions: showSuggestions, hasSuggestions: hasSuggestions })));
            } }))));
};
export default TimesheetsBottomSection;
