import React, {useEffect} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {searchFilter} from '../../../shared/components/filters/filter_util';
import {withSocketHandling} from '../../../../socket/withSocketHandling';
import {TaskList} from '../../../shared/components/task-tables/my-work-task-table/Task_list';
import {getSocketConfig} from '../My_work_socket_handling';
import {EVENT_ID, subscribe, unsubscribe} from '../../../../containers/event_manager';

const MyWorkOverdueTasksSection = ({
	retry,
	intl,
	history,
	viewer,
	selectedPersonId,
	enabledColumns,
	showOverdue,
	setOverdueTasksAmount,
	searchFilterValue,
	filterFunctions,
	filterOptions,
	setSocketConfig,
}) => {
	let overdueTasks = viewer.company.overdueTasks.edges.filter(
		task => task.node.fullAccessToProject === true && task.node.statusColumnV2.category !== 'DONE'
	);
	if (searchFilterValue.length > 0) {
		overdueTasks = overdueTasks.filter(task => searchFilter(task.node, searchFilterValue));
	}
	if (filterFunctions && filterFunctions.taskFilter) {
		overdueTasks = overdueTasks.filter(task => filterFunctions.taskFilter(task.node, filterOptions));
	}

	const refetchTasks = props => {
		if (
			props.duplicateTask ||
			(props.input &&
				(props.input.deadlineYear ||
					props.input.startYear ||
					props.input.assignedPersons ||
					props.input.statusColumnId))
		) {
			retry();
		}
	};

	const socketTaskIds = viewer.company.overdueTasks.edges.map(edge => parseInt(atob(edge.node.id).replace('Task:', '')));

	useEffect(() => {
		subscribe(EVENT_ID.SCHEDULING_MODAL_MUTATION_SUCCESS, refetchTasks);
		return () => {
			unsubscribe(EVENT_ID.SCHEDULING_MODAL_MUTATION_SUCCESS, refetchTasks);
		};
	}, []);

	useEffect(() => {
		const actualPersonIdInt = parseInt(atob(viewer.actualPersonId).replace('Person:', ''));
		const selectedPersonIdInt = parseInt(atob(selectedPersonId).replace('Person:', ''));
		setSocketConfig(getSocketConfig([actualPersonIdInt, selectedPersonIdInt], socketTaskIds));
	}, [selectedPersonId]);

	useEffect(() => {
		setOverdueTasksAmount(overdueTasks.length);
	}, [overdueTasks.length]);

	return showOverdue && overdueTasks.length > 0 ? (
		<TaskList
			intl={intl}
			enabledColumns={enabledColumns}
			tasks={overdueTasks}
			history={history}
			viewer={viewer}
			enableSort
			stickyTopPosition={52}
			localSortKey={'my-work-overdue-sort-value'}
		/>
	) : null;
};

const MyWorkOverdueTasksSectionQuery = graphql`
	query MyWorkOverdueTasksSection_Query($searchQuery: TaskSearchQueryType, $personId: ID!) {
		viewer {
			component(name: "my_work_overdue_tasks_section")
			actualPersonId
			id
			...MyWorkOverdueTasksSection_viewer @arguments(searchQuery: $searchQuery, personId: $personId)
		}
	}
`;

export {MyWorkOverdueTasksSectionQuery};

export default withSocketHandling(
	createFragmentContainer(MyWorkOverdueTasksSection, {
		viewer: graphql`
			fragment MyWorkOverdueTasksSection_viewer on Viewer
			@argumentDefinitions(searchQuery: {type: "TaskSearchQueryType"}, personId: {type: "ID!"}) {
				id
				actualPersonId
				startDate
				endDate
				createdAt
				monday
				tuesday
				wednesday
				thursday
				friday
				saturday
				sunday
				# start -- needed only for context menu
				email
				timerStartDate
				harvestUser
				unit4User
				excludeFromCompanyLockedPeriod
				submitLockedDateYear
				submitLockedDateMonth
				submitLockedDateDay
				timerTask {
					id
				}
				availableFeatureFlags {
					key
				}
				# end -- needed only for context menu
				company {
					...genericTaskContextMenu_company
					id
					harvestEnabled
					unit4Enabled
					lockedPeriodYear
					lockedPeriodMonth
					lockedPeriodDay
					roles {
						edges {
							node {
								id
								name
							}
						}
					}
					modules {
						moduleType
					}
					overdueTasks: allTasks(
						first: 1000000
						searchQuery: $searchQuery
						approvedOnly: true
						runningProjectOnly: true
					) @connection(key: "Company_overdueTasks", filters: []) {
						edges {
							node {
								id
								...TaskRow_task @arguments(personId: $personId)
								fullAccessToProject
								sortOrder
								# filter start
								companyTaskId
								name
								latestUiUpdateAt
								subTaskCount
								startDay
								startMonth
								startYear
								deadlineDay
								deadlineMonth
								deadlineYear
								bug
								blocked
								highPriority
								billable
								favoured
								hasDependency
								project {
									id
									status
									companyProjectId
									name
									client {
										id
										name
									}
								}
								statusColumnV2 {
									id
									name
									category
								}
								taskLabels {
									label {
										id
									}
								}
								assignedPersons {
									id
								}
								phase {
									id
									name
									deadlineDay
									deadlineMonth
									deadlineYear
									startDay
									startMonth
									startYear
								}
								timeRegistrations(first: 1000000) {
									edges {
										node {
											id
											minutesRegistered
										}
									}
								}
								sprint {
									id
									endDay
									endMonth
									endYear
									startDay
									startMonth
									startYear
								}
								owner {
									id
								}
								followers {
									id
								}
								# filter end
							}
						}
					}
				}
			}
		`,
	})
);
