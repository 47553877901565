import React from 'react';

const declinedIcon = props => (
	<div className={props.className} title={props.title}>
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
			<g fill="none" fillRule="nonzero">
				<path
					fill="#ADADAD"
					d="M1.726 19.943a3.032 3.032 0 0 1-.468-.184c-.352-.167-.84-.656-1.008-1.003-.266-.544-.246.109-.246-8.751s-.02-8.208.246-8.751C.418.906.906.418 1.254.25c.543-.266-.11-.246 8.75-.246 8.859 0 8.206-.02 8.75.246.347.168.835.656 1.003 1.004.266.543.246-.11.246 8.75s.02 8.208-.246 8.752c-.168.347-.656.836-1.004 1.003-.543.266.114.247-8.769.243-6.926 0-8.078-.008-8.258-.059z"
				/>
				<path fill="#FFF" d="M6.086 9.984V4.02h2.3v4.757h3.197V4.019h2.3v11.93h-2.3v-5.146H8.386v5.146h-2.3z" />
				<path fill="#D0021B" d="M4.972 16.223A8 8 0 0 0 16.316 5.09L4.973 16.223zm-1.386-1.441L14.902 3.677A8 8 0 0 0 3.586 14.782zM10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10z" />
			</g>
		</svg>
	</div>
);
export default declinedIcon;
