import React, {useState} from 'react';
import styled from 'styled-components';
import GenericModal from '../../../containers/modal/generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE, MODULE_TYPES} from '../../../constants';
import CustomScrollDiv from '../../shared/components/scroll-bars/custom_scroll_div';
import {BasicTextInput, Checkbox, InputLabel, Table} from 'web-components';
import {cloneDeep, uniq} from 'lodash';
import {useIntl} from 'react-intl';
import {createToast} from '../../shared/components/toasts/toast';
import Util from '../../shared/util/util';
import UpdateProfileMutation from '../../../mutations/UpdateProfileMutation';
import {TextBold} from '../../../Text.styled';
import ForecastTooltip from '../../shared/components/tooltips/ForecastTooltip';
import {
	dependencyChecked,
	getDefaultPermissions,
	getDependingPermissions,
	hasPermissionGroup,
} from '../../shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import {hasModule} from '../../shared/util/ModuleUtil';
import {ModalText, ModalWrapper} from '../../../containers/modal/modal_styled';
import {ExpansionToggle} from '../../shared/components/task-tables/task-table-v3/elements/Elements.styled';

const InputWrapper = styled.div`
	margin-left: 16px;
`;

const Description = styled.div`
	overflow: hidden;
	white-space: normal;
	text-overflow: ellipsis;
	font-family: $text-font-family;
	font-size: 12px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.25;
	letter-spacing: normal;
	color: #a5a5a5;
`;

const ErrorMessage = styled.div`
	color: #d0021b;
	font-size: 13px;
	padding-left: 16px;
	padding-top: 4px;
`;

const profileModal = props => {
	const [_name, setName] = useState(props.profile.name);
	const [_error, setError] = useState(null);
	const [_permissions, setPermissions] = useState(cloneDeep(props.profile.permissions));

	const intl = useIntl();

	const permissionChecked = (rowData, removing) => {
		if (removing) {
			const dependentPermissions = getDependingPermissions(rowData);
			const filteredPermissions = _permissions.filter(
				permission => !rowData.permissions.includes(permission) && !dependentPermissions.includes(permission)
			);
			setPermissions(filteredPermissions);
		} else {
			const defaultPermissions = getDefaultPermissions(rowData);
			const addedPermissions = uniq(_permissions.concat(rowData.permissions).concat(defaultPermissions));
			setPermissions(addedPermissions);
		}
	};

	const handleSetName = name => {
		setName(name);
		if (name.length > 191) {
			setError(intl.formatMessage({id: 'settings_finance.name_limit_191'}));
		} else {
			setError(null);
		}
	};

	const handleSave = () => {
		const onSuccess = () => {
			createToast({
				duration: 5000,
				message: intl.formatMessage({id: 'settings.profile_updated'}),
			});
		};

		Util.CommitMutation(
			UpdateProfileMutation,
			{
				id: props.profile.id,
				name: _name,
				profilePermissions: _permissions,
			},
			onSuccess
		);
	};

	const getPermissions = () => {
		return props.permissions.filter(group => {
			// Only show sisense groups for people with sisense module
			if (group.permissions.includes(PERMISSION_TYPE.SISENSE_READ)) {
				return hasModule(MODULE_TYPES.SISENSE);
			}
			return true;
		});
	};

	const content = (
		<ModalWrapper large>
			{!props.hideProfileName && (
				<InputWrapper>
					<InputLabel
						text={'Profile name'}
						activeError={!!_error}
						extension={_error}
						child={
							props.profileNameCanBeEdited ? (
								<BasicTextInput
									value={_name}
									onChange={handleSetName}
									hasError={_error}
									width="250px"
									placeholder={'Profile name'}
								/>
							) : (
								<TextBold>{_name}</TextBold>
							)
						}
					/>
				</InputWrapper>
			)}
			<ModalText>{props.description}</ModalText>
			{_error && <ErrorMessage>{_error}</ErrorMessage>}
			<CustomScrollDiv style={{height: '50vh', minWidth: '500px'}}>
				<Table>
					<Table.Header>
						<Table.HeaderColumn flex={1} usePadding visible width="MEDIUM">
							<Table.HeaderColumn.Title>Permission</Table.HeaderColumn.Title>
						</Table.HeaderColumn>
						<Table.HeaderColumn usePadding visible width="MEDIUM" align="center">
							<Table.HeaderColumn.Title>Enabled</Table.HeaderColumn.Title>
						</Table.HeaderColumn>
					</Table.Header>
					<Table.Rows
						data={{
							rows: getPermissions(),
						}}
					>
						{({rowData, tableColumnsProps}) => {
							const isChecked = hasPermissionGroup(rowData, _permissions);
							const canBeEdited = props.profileCanBeEdited;
							const isDisabled = !canBeEdited || dependencyChecked(rowData, _permissions);

							return (
								<>
									<Table.Row
										hoverStyle={{
											color: 'blue',
										}}
										{...tableColumnsProps}
									>
										<Table.Column>
											{rowData.description && (
												<ExpansionToggle
													expanded={tableColumnsProps.expanded}
													onClick={() => tableColumnsProps.toggleExpansion()}
												/>
											)}
											{rowData.name}
										</Table.Column>
										<Table.Column align="center">
											<ForecastTooltip
												content={
													isDisabled &&
													(!canBeEdited
														? 'You are not allowed to edit this permission'
														: 'This permission cannot be disabled as another permission depends on it')
												}
											>
												<Checkbox
													disabled={isDisabled}
													checked={isChecked}
													onChange={() => permissionChecked(rowData, isChecked)}
												/>
											</ForecastTooltip>
										</Table.Column>
									</Table.Row>
									{tableColumnsProps.expanded && (
										<Table.Row {...tableColumnsProps}>
											<Table.Column>{<Description>{rowData.description}</Description>}</Table.Column>
										</Table.Row>
									)}
								</>
							);
						}}
					</Table.Rows>
				</Table>
			</CustomScrollDiv>
		</ModalWrapper>
	);
	return (
		<GenericModal
			buttons={[
				{
					text: 'Cancel',
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: 'Save',
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					disabled: !props.profileCanBeEdited || !!_error,
					callback: () => handleSave(),
				},
			]}
			headerText={props.headerText}
			wrapperClassName="edit-profile-modal"
			closeModal={props.closeModal}
			content={content}
		/>
	);
};

export default profileModal;
