import ProjectDropdown, {projectDropdownQuery} from './ts/ProjectDropdown';
import ForecastQueryRenderer from '../../../../ForecastQueryRenderer';
import React from 'react';

const projectDropdownWrapper = ({project, onChange, cy, loaderComponent}) => {
	return (
		<ForecastQueryRenderer
			key="query-render-projectDropdown"
			query={projectDropdownQuery}
			customLoader={() => loaderComponent}
			render={(relayProps, retry) => {
				return <ProjectDropdown {...relayProps} retry={retry} project={project} onChange={onChange} cy={cy} />;
			}}
		/>
	);
};

export default projectDropdownWrapper;
