import {InputStyle} from '../../../../inputs/time-registration-input/time_reg_w_sugestions_input';
import {PercentageInput} from '../../../../../../../components/inputs/PercentageInput';
import Util from '../../../../../util/util';
import Button from '../../../../buttons/button/button';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../../../../../constants';
import TimeRegContainer from '../../../../../../../styles/components/time_reg_styled';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {convertToPercentageFormat} from '../../../../../../../components/inputs/PercentageInputLogic';
import {ProgressSuggestion} from './ProgressSuggestion';
import {createToast} from '../../../../toasts/another-toast/toaster';
import CreateTaskProgressMutation from '../../../../../../../mutations/create_task_progress_mutation';

export const ProgressRegistration = ({task, isTaskModal, close}) => {
	const intl = useIntl();
	const [inputValue, setInputValue] = useState(task.progressDetails ? task.progressDetails.progress : 0);

	let inputRef;

	// Focus input on mount
	useEffect(() => {
		if (inputRef) {
			inputRef.focus();
		}
	}, []);

	const handleCancelButtonEnterPress = e => {
		if (e.key === 'Enter') close();
	};

	const handleCancelButtonPress = () => {
		close();
	};

	function saveProgress(inputValue) {
		const validValue = convertToPercentageFormat(inputValue, intl);
		if (!validValue.error) {
			const onSuccess = () => {
				createToast({
					duration: 5000,
					message: intl.formatMessage({id: 'common.progress-updated'}),
				});
			};
			Util.CommitSchedulingModalUpdate(
				CreateTaskProgressMutation,
				{
					taskId: task.id,
					progress: Math.round(validValue.float * 100),
					phaseId: task.phase ? task.phase.id : null,
					projectId: task.project.id,
				},
				onSuccess
			);
		}
		close();
	}

	const handleSaveButtonPress = () => {
		saveProgress(inputValue);
	};

	const handleSaveButtonEnterPress = e => {
		if (e.key === 'Enter') {
			saveProgress(inputValue);
		}
	};

	const handleInputChange = value => {
		if (inputValue !== value) {
			setInputValue(value);
		}
	};

	const options = [25, 50, 75];

	return (
		<TimeRegContainer isTaskModal={isTaskModal}>
			<div className="popup-wrapper">
				<div className="entry-wrapper">
					<div className="title">{intl.formatMessage({id: 'common.update-progress'})}</div>
				</div>
				<div className="entry-wrapper">
					<InputStyle>
						<div className="input-suggestions">
							<div className="title-input-wrapper hour-input">
								<div className="icon-title-wrapper">
									<div className="input-title">{intl.formatMessage({id: 'common.progress'})}</div>
								</div>
								<PercentageInput
									intl={intl}
									value={inputValue}
									mutation={handleInputChange}
									innerRef={div => (inputRef = div)}
									customClassName={'hour-input'}
								/>
							</div>
							<div className="title-input-wrapper">
								<div className="nopopup-options">
									{options.map((option, index) => {
										return <ProgressSuggestion option={option} onClick={saveProgress} key={index} />;
									})}
								</div>
							</div>
						</div>
					</InputStyle>
				</div>

				<div className="buttons">
					<Button
						text={intl.formatMessage({id: 'common.cancel'})}
						buttonStyle={BUTTON_STYLE.FILLED}
						colorTheme={BUTTON_COLOR.WHITE}
						onKeyDown={handleCancelButtonEnterPress}
						onClick={handleCancelButtonPress}
						focusOnMouseDown={true} // need to focus on the button when clicking down to trigger the blur effect on the input to tranform string to hours amount
						focusOnClick={true}
					/>
					<Button
						text={intl.formatMessage({id: 'common.save'})}
						buttonStyle={BUTTON_STYLE.FILLED}
						colorTheme={BUTTON_COLOR.GREEN}
						onClick={handleSaveButtonPress}
						onKeyDown={handleSaveButtonEnterPress}
						focusOnMouseDown={true}
						focusOnClick={true}
						cy="task-modal-progress-button-save-button"
					/>
				</div>
			</div>
		</TimeRegContainer>
	);
};
