import React from 'react';

const getOrientationClass = orientation => {
	switch (orientation) {
		case 'down ':
			return '';
		case 'up':
			return 'expanded ';
		case 'left':
			return 'left ';
		case 'right':
			return 'right ';
		default:
			return '';
	}
};

const ExpandIcon = props => (
	<div
		onClick={props.onClick ? props.onClick : null}
		className={'expand-icon ' + (props.orientation ? getOrientationClass(props.orientation) : props.expanded ? 'expanded ' : '') + (props.customClass ? props.customClass : '')}
		data-userpilot={props.userpilot ? props.userpilot : ''}
	>
		<svg width="12" height="7" xmlns="http://www.w3.org/2000/svg">
			<path d={props.expanded ? 'M1 5.102L5.948 1 11 5' : 'M1 1.102L5.948 5 11 1'} stroke={props.useDarkIcon ? '#535353' : '#a1a1a1'} strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round" />
		</svg>
	</div>
);

export default ExpandIcon;
