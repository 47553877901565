import {APPROVAL_STATUS} from '../../../../constants';

export const hasLockedInternalTime = (timeReg, usingInternalTimeApproval) => {
	if (usingInternalTimeApproval) {
		const idleTime = timeReg.idleTime;
		const isInternalTime = idleTime?.isInternalTime;
		const approvalStatus = timeReg?.approvalStatus;

		return isInternalTime && [APPROVAL_STATUS.SUBMITTED, APPROVAL_STATUS.APPROVED].includes(approvalStatus);
	}
	return false;
};

export const timeEntryLockedInternalTime = (lineItem, dayRegs, isWeekView, usingInternalTimeApproval) => {
	if (usingInternalTimeApproval) {
		const timeReg = lineItem.timeReg ? (lineItem.timeReg.node ? lineItem.timeReg.node : lineItem.timeReg) : lineItem;

		return isWeekView
			? dayRegs && dayRegs.length > 0 && dayRegs.some(tReg => hasLockedInternalTime(tReg.node, usingInternalTimeApproval))
			: hasLockedInternalTime(timeReg, usingInternalTimeApproval);
	}

	return false;
};
