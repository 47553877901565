import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import Util from '../../../util/util.js';
import UpdateTaskMutation from '../../../../../mutations/update_task_mutation.modern';
import LabelSectionLabelPicker from './label_section_label_picker';
import {dispatch, EVENT_ID} from '../../../../../containers/event_manager';
import {filterTaskLabelEdges} from '../../../util/LabelUtil';
import LabelDropdown from '../../../../../components/dropdowns/label-dropdown/Label_dropdown';

class LabelSection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: localStorage.getItem('task-modal-v3-label-section-expanded') || false,
			addedSuggestedLabelNames: [],
		};
	}

	toggleExpansion(e) {
		if (this.state.expanded) {
			localStorage.removeItem('task-modal-v3-label-section-expanded');
		} else {
			Util.localStorageSetItem('task-modal-v3-label-section-expanded', true);
		}
		this.setState({expanded: !this.state.expanded});
	}

	addLabelToTask(labelId, onSuccessCallback) {
		// Check if the task already has the label
		if (this.getTaskLabelsFromProps().some(label => label.id === labelId)) {
			return;
		}
		const onSuccess = response => {
			if (onSuccessCallback) {
				onSuccessCallback(response);
			}
			Util.dispatchScheduleEvent(response);
			dispatch(EVENT_ID.SHOW_CHANGES_SAVED);
		};
		Util.CommitMutation(
			UpdateTaskMutation,
			{
				ids: [this.props.task.id],
				labels: [...this.getTaskLabelsFromProps().map(label => label.id), labelId],
			},
			onSuccess
		);
	}

	getTaskLabelsFromProps() {
		return this.props.task.taskLabels
			.filter(taskLabel => taskLabel !== null)
			.map(taskLabel => ({id: taskLabel.label.id, name: taskLabel.label.name}));
	}

	getAllLabelsFromProps() {
		return this.props.viewer.company.labels.edges.map(edge => ({id: edge.node.id, name: edge.node.name}));
	}

	removeLabelFromTask(labelId) {
		const onSuccess = response => {
			Util.dispatchScheduleEvent(response);
			dispatch(EVENT_ID.SHOW_CHANGES_SAVED);
		};
		Util.CommitMutation(
			UpdateTaskMutation,
			{
				ids: [this.props.task.id],
				labels: [
					...this.getTaskLabelsFromProps()
						.filter(label => label.id !== labelId)
						.map(label => label.id),
				],
			},
			onSuccess
		);
	}

	/*
	 * Creates a new label if it does not exist yet and adds it to the current task
	 * If it already exists, it adds the existing label to the task
	 */
	createAndAddLabel(labelName) {
		const processedLabelName = labelName.trim().replace(/ /g, '_').replace(/#/g, '');
		if (this.getAllLabelsFromProps().some(label => label.name === processedLabelName)) {
			// Check if label already exists
			this.addLabelToTask(this.getAllLabelsFromProps().find(label => label.name === processedLabelName).id);
		}
	}

	render() {
		const {formatMessage} = this.props.intl;
		const {viewer, task, disabled} = this.props;
		const {expanded} = this.state;
		// Task modal has been throwing errors in production , due to missing data on the task. We're looking into it.
		if (task.taskLabels == null) {
			return <div className={'error-task-labels-empty'} />;
		}
		const allLabels = viewer.company.labels.edges.filter(filterTaskLabelEdges).map(edge => edge.node);
		const taskLabels = task.taskLabels.filter(taskLabel => taskLabel !== null).map(taskLabel => taskLabel.label);
		const selectedLabelIds = taskLabels.map(label => label.id);

		return (
			<div className="task-modal-v3-label-section-root" data-cy="task-modal-labels-section">
				<div className="task-modal-v3-label-section">
					<LabelDropdown
						labels={allLabels}
						selectedItems={selectedLabelIds}
						onSelect={this.addLabelToTask.bind(this)}
						onRemove={this.removeLabelFromTask.bind(this)}
						selectedGroupName={formatMessage({id: 'common.added'})}
						name={formatMessage({id: 'task_modal.find_label'})}
						disabled={disabled}
						cy={'task-modal-label-picker-input'}
						userpilot={'task-modal-labels-dropdown'}
						taskId={task.id}
						usePortal
						useSuggestions
					/>
					<div className="label-picker">
						<LabelSectionLabelPicker
							taskId={this.props.task.id}
							taskLabels={taskLabels}
							allLabels={allLabels}
							addLabelToTask={this.addLabelToTask.bind(this)}
							removeLabelFromTask={this.removeLabelFromTask.bind(this)}
							createAndAddLabel={this.createAndAddLabel.bind(this)}
							expanded={expanded}
							cy="task-modal-label-picker"
							disabled={disabled}
						/>
					</div>
				</div>
			</div>
		);
	}
}

LabelSection.propTypes = {
	viewer: PropTypes.object.isRequired,
	task: PropTypes.object.isRequired,
	disabled: PropTypes.bool.isRequired,
};

export default injectIntl(LabelSection);
