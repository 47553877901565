import React from 'react';

const UtilizationModalEmptyStateGraphic = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="154" height="118">
		<g fill="none">
			<path fill="#A1A1A1" d="M.575.227H49.64v68.068H.575z" opacity=".2" />
			<path fill="#FFF" d="M2.824 3.26h21.92v1.842H2.824zm44.567.918a.92.92 0 01-.918.919.92.92 0 01-.918-.919.92.92 0 01.918-.918.92.92 0 01.918.918z" />
			<path fill="#A1A1A1" d="M52.598.227h49.065v68.068H52.598z" opacity=".2" />
			<path fill="#A1A1A1" d="M54.847 27.069h44.567V65.22H54.847z" opacity=".3" />
			<path fill="#FFF" d="M54.847 3.26h5.98v1.842h-5.98zm7.456 0h11.303v1.842H62.303zm37.111.918c0 .506-.412.919-.918.919s-.918-.413-.918-.919c0-.505.412-.918.918-.918s.918.413.918.918z" />
			<path fill="#A1A1A1" d="M104.569.227h49.065v68.068h-49.065z" opacity=".2" />
			<path fill="#FFF" d="M106.818 3.26h13.959v1.842h-13.959zm44.567.918a.92.92 0 01-.918.919.92.92 0 01-.918-.919.92.92 0 01.918-.918.92.92 0 01.918.918zM2.825 8.136h44.567V20.34H2.825z" />
			<path fill="#A1A1A1" d="M6.893 11.623H43.44v1.842H6.893zm0 3.533h21.92v1.842H6.893z" opacity=".3" />
			<path fill="#FFF" d="M2.825 23.245h44.567v31.573H2.825z" />
			<path fill="#FFC8F6" d="M2.825 36.007h44.567v26.151H2.825z" />
			<path fill="#A1A1A1" d="M6.893 26.732H43.44v1.842H6.893zm0 3.533h9.472v1.842H6.893z" opacity=".3" />
			<path fill="#FE66AC" d="M28.999 47.245a4.504 4.504 0 11-8.31 3.476A4.504 4.504 0 0129 47.245z" />
			<path
				fill="#B81B60"
				d="M26.378 45.92c.134.378-.198.703-.732.72-.54.018-1.081-.278-1.215-.656-.134-.378.198-.703.732-.72.54-.018 1.081.278 1.215.656zm-2.813-.058c.163.11.122.337-.087.5-.21.162-.511.203-.674.087-.163-.11-.122-.337.087-.5.21-.157.511-.198.674-.087zm4.341 6.09a5.006 5.006 0 01-6.555-2.69 4.96 4.96 0 01-.331-2.639 4.488 4.488 0 00-.32 4.091 4.504 4.504 0 005.893 2.418 4.476 4.476 0 001.854-1.447c-.18.099-.355.192-.54.267z"
			/>
			<path
				fill="#B81B60"
				d="M28.372 46.187c.104.134.197.268.29.413 1.174-.279 1.964-.32 2.08-.04.227.546-2.22 2.086-5.462 3.445-3.249 1.36-6.062 2.017-6.288 1.476-.117-.278.465-.813 1.487-1.452a5.101 5.101 0 01-.093-.494c-2.544 1.458-4.097 2.882-3.777 3.643.46 1.099 4.637.39 9.339-1.575 4.701-1.964 8.136-4.451 7.677-5.544-.326-.767-2.43-.662-5.254.128z"
				opacity=".6"
			/>
			<g>
				<path fill="#FFF" d="M54.847 8.136h44.567v16.272H54.847z" />
				<path fill="#A1A1A1" d="M58.915 11.623h36.547v1.842H58.915zm0 3.533h36.547v1.842H58.915zm0 3.539h21.92v1.842h-21.92z" opacity=".3" />
			</g>
			<g>
				<path fill="#FFF" d="M106.818 8.136h44.567V20.34h-44.567z" />
				<path fill="#A1A1A1" d="M110.886 11.623h36.547v1.842h-36.547zm0 3.533h21.92v1.842h-21.92z" opacity=".3" />
			</g>
			<g>
				<path fill="#FFF" d="M106.818 23.245h44.567v12.204h-44.567z" />
				<path fill="#A1A1A1" d="M110.886 26.732h36.547v1.842h-36.547zm0 3.533h9.286v1.842h-9.286z" opacity=".3" />
			</g>
			<g>
				<path fill="#FFF" d="M88.604 69.619a.143.143 0 01-.145-.145V30.562c0-.081.064-.145.145-.145h44.567c.082 0 .146.064.146.145v38.912a.144.144 0 01-.146.146H88.604z" />
				<path fill="#E6E6E6" d="M133.171 30.562v38.912H88.604V30.562h44.567m0-.29H88.604a.287.287 0 00-.29.29v38.912c0 .163.128.291.29.291h44.567c.163 0 .29-.128.29-.29V30.561a.294.294 0 00-.29-.29z" />
				<path fill="#DFF0E7" d="M88.604 43.324h44.567v26.151H88.604z" />
				<path fill="#A1A1A1" d="M92.672 34.049h36.547v1.842H92.672zm0 3.533h9.472v1.842h-9.472z" opacity=".3" />
				<path fill="#B8BCCF" d="M125.279 51.628a5.337 5.337 0 01-2.865 6.985 5.338 5.338 0 11-4.12-9.85 5.337 5.337 0 016.985 2.865z" />
				<path
					fill="#9398B3"
					d="M123.728 55.242c-.54.332-1.15-.063-1.36-.889-.21-.825.058-1.766.604-2.098.54-.33 1.151.064 1.36.89.21.825-.058 1.76-.604 2.097zm-1.93-5.317c.053-.227.314-.29.587-.134.274.157.454.471.401.698-.052.226-.313.29-.587.133-.267-.15-.447-.464-.4-.697zm2.186 7.287a5.945 5.945 0 01-7.776-3.184 5.91 5.91 0 01-.395-3.127 5.317 5.317 0 00-.378 4.853 5.339 5.339 0 009.188 1.15c-.21.11-.419.216-.64.308z"
				/>
				<path fill="#9398B3" d="M119.247 53.65a.54.54 0 11-.401-1.005.54.54 0 01.703.302.54.54 0 01-.302.703z" />
				<path
					fill="#FFF"
					d="M104.707 62.995c2.546-.378 2.854-.686 3.232-3.231.377 2.545.685 2.853 3.23 3.23-2.545.379-2.853.687-3.23 3.232-.372-2.545-.68-2.853-3.232-3.231zm-7.775-11.419c1.226-.18 1.371-.332 1.557-1.558.18 1.226.332 1.372 1.558 1.558-1.226.18-1.372.33-1.558 1.557-.186-1.226-.33-1.371-1.557-1.557z"
				/>
			</g>
			<g>
				<path
					fill="#FFAB81"
					d="M78.29 64.994c1.25 1.465 3.928 4.359 5.555 4.243 1.622-.117 5.382-8.119 5.539-8.694.157-.575-1.256-1.621-1.779-1.255-.523.366-4.108 4.655-4.474 5.108-2.058-2.842-3.01-3.19-3.01-3.19l-1.831 3.788zm10.722 4.888c1.25 1.464 3.928 4.358 5.555 4.242 1.622-.116 8.903-1.093 10.002-1.72.517-.297-1.436-1.47-2-1.773-.592-.32-7.694-.97-8.066-.523-2.057-2.841-3.667-4.021-3.667-4.021l-1.824 3.795z"
				/>
				<path
					fill="#006AB7"
					d="M74.431 76.303c-2.865 3.249-5.515 12.111-5.898 13.825-.384 1.709-1.203 2.865-2.83 4.155-4.051 3.214-4.783 8.31-7.363 11.623.244.802.139 3.388.592 4.504.454 1.116 1.046 1.5 1.36-.349.314-1.848 5.073-7.7 6.09-9.112 4.278-5.933 8.055-5.672 11.954-18.643 1.087-3.591-.017-10.408-3.905-6.003z"
				/>
				<path fill="#01558D" d="M55.969 107.092c.186-1.535 1.603-1.65 2.37-1.593.768.058 1.116.407 1.012 2.255-.105 1.848.244 2.795.244 3.022-.54 1.83-.453 6.37-2.76 6.37-1.761 0-2.587-2.285-2.337-4.086.186-1.348 1.052-2.476 1.47-5.968z" />
				<path fill="#C6C8C3" d="M58.218 111.317c-.018-1.692.68-2.412.68-3.51 0-1.413-.826-2.662-1.883-2.14-.506.204-.953.617-1.046 1.425-.419 3.492-1.285 4.62-1.465 5.968-.238 1.743.529 3.946 2.185 4.08 2.423-.134 1.546-4.156 1.529-5.824z" />
				<path fill="#D1DBDE" d="M57.921 111.317c-.017-1.692.68-2.412.68-3.51 0-1.57-.622-2.244-1.592-2.14-.506.204-.947.617-1.046 1.425-.419 3.492-1.285 4.62-1.465 5.968-.232 1.697.488 3.83 2.057 4.062 2.22-.302 1.384-4.178 1.366-5.806z" />
				<path
					fill="#005299"
					d="M66.388 100.949c4.277-5.933 8.055-5.672 11.954-18.643.25-.837.384-1.853.395-2.859l-2.162 2.11c-2.713.552-3.085 7.281-5.137 10.286-4.533 6.654-11.332 11.762-11.663 19.48.203-.094.39-.471.523-1.268.314-1.842 5.073-7.694 6.09-9.106z"
				/>
				<path
					fill="#006AB7"
					d="M76.233 81.504c0 2.046 1.673 3.981 2.353 4.452-.261 1.726.576 6.805.942 8.223-5.341-.349-8.694 6.596-11.518 6.282.418 1.726-.053 5.236-.21 5.811.157.366.68-.157 1.099-.47.418-.314 8.618-5.73 11.256-6.527 1.848-.558 3.824-1.516 4.818-5.323.994-3.806 2.074-6.456.279-13.116-.628-2.33-3.981-4.881-5.132-4.219-1.15.663-3.887.349-3.887 4.887z"
				/>
				<path fill="#01558D" d="M68.01 100.461c-.71 0-3.522-1.128-3.319 1.377.088 1.064-.685 1.854-1.36 2.639-1.51 1.743-2.022 5.48-1.185 6.072.837.593 2.028-.07 2.795-.836.767-.768 2.737-3.772 3.348-3.842.61-.07 1.464-4.852-.28-5.41z" />
				<path
					fill="#C6C8C3"
					d="M64.912 108.562c.907-2.476 2.61-4.574 2.61-6.759 0-1.028-.593-1.551-1.36-1.598-.831-.006-1.581.297-1.47 1.633.087 1.064-.686 1.854-1.36 2.639-1.511 1.743-2.023 5.48-1.186 6.072.07.053.145.088.22.122.675.192 2.017-.656 2.546-2.11z"
				/>
				<path
					fill="#D1DBDE"
					d="M65.888 100.217c-.714.064-1.29.436-1.197 1.621.088 1.064-.685 1.854-1.36 2.639-1.51 1.743-2.022 5.48-1.185 6.072.076.053.157.1.244.128.715-.128 1.709-.918 2.144-2.12.907-2.477 2.61-4.574 2.61-6.76 0-.97-.54-1.493-1.256-1.58z"
				/>
				<path
					fill="#005299"
					d="M81.545 86.142c-1.599.157-2.407.081-2.959-.186-.261 1.726.576 6.805.942 8.223-1.366-.087-2.604.296-3.731.918 1.673-.017 4.655.57 5.027 3.952.865-.32 1.726-.79 2.481-1.65.506-1.372.802-3.517.232-5.161-.76-2.197-2.336-3.638-1.993-6.096z"
				/>
				<path
					fill="#9D71BF"
					d="M75.762 60.874c0-2.301 1.674-4.504 4.347-4.294 2.673.209 6.753 4.085 7.909 5.026 1.15.942 1.15 4.4.785 6.335-.367 1.935-2.459 5.602-2.459 6.543 0 .942-.313 5.434-.732 6.277-2.987.371-10.449-.686-11.518-4.603-.157-.575 1.773-3.26 2.301-4.295.831-1.615-.633-4.23-.633-10.989z"
				/>
				<path fill="#78499C" d="M85.606 66.633c.628 3.929-3.196 8.235-3.591 14.04 1.406.18 2.696.204 3.591.088.419-.843.733-5.335.733-6.277 0-.941 2.092-4.608 2.458-6.543.168-.907.261-2.139.191-3.295-1.29-3.08-3.928-1.412-3.382 1.987z" />
				<path fill="#9D71BF" d="M87.443 61.124c1.319 1.313 2.929 2.842 4.655 5.829-.471 1.464-1.988 3.405-2.778 3.667-.785.261-4.295-2.883-4.138-5.916.157-3.045 1.32-4.516 2.26-3.58z" />
				<path
					fill="#FFAB81"
					d="M88.46 55.231c.377 1.337 1.226 2.999 2.167 2.267.953-.744.936-1.744 1.633-1.366 1.064.581-1.83 3.167-1.83 3.167-.384.669-1.354 1.947-2.656.884-1.302-1.064.21-6.649.686-4.952zm17.416 14.941c0-1.197 1.104-.232 1.284-1.813-.493-.064-2.01-.343-2.62 1.098-1.61.024-2.79 0-2.79 0-.546 1.064 1.447 3.946 3.632 2.639 1.436-.854.494-1.633.494-1.924zM85.426 56.952c-.11.621-.657 1.917.407 2.806-1.819.697-6.207.54-5.01-2.423 1.204-2.958 4.603-.384 4.603-.384z"
				/>
				<path fill="#DB8765" d="M81.37 56.475l-.314 1.534c1.093.697 2.79 1.348 4.19.813-.169-.708.11-1.447.18-1.87 0 0-2.603-1.97-4.056-.477z" />
				<g>
					<path fill="#FFAB81" d="M80.26 57.585c-2.325-1.553-2.07-4.376-1.296-5.881.773-1.5 3.574-3.54 5.84-2.029 6.497 4.347-.081 10.89-4.544 7.91z" />
					<path fill="#302A25" d="M88.128 52.128c-3.411.203-1.087 5.84-5.143 6.642-2.214.442-8.281-2.615-3.783-7.473 3.08-7.293 9.763-2.999 8.926.83z" />
					<path fill="#FFAB81" d="M85.873 56.056c-1.08-.529-1.325-2.54.175-2.185 1.499.349-.175 2.185-.175 2.185z" />
					<path fill="#61564F" d="M81.736 49.49c-.343.54.04 1.516 1.58 1.516 1.628 0 2.156-1.336 3.836-.889-.79-1.336-3.964-2.923-5.416-.627z" />
				</g>
			</g>
		</g>
	</svg>
);

export default UtilizationModalEmptyStateGraphic;
