import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {ProgressRegistration} from './ProgressRegistration';

const ProgressTile = ({intl, task}) => {
	const [expanded, setExpanded] = useState(false);

	const progress = task.progressDetails ? task.progressDetails.progress : 0;

	const toggleExpansion = () => {
		setExpanded(!expanded);
	};

	const onClick = () => {
		toggleExpansion();
	};

	const close = () => {
		if (expanded) {
			toggleExpansion();
		}
	};

	return (
		<div className={'header-tile progress-tile' + (expanded ? ' expanded' : '')} onClick={onClick.bind(this)}>
			<div className="tile-wrapper">
				<div className="tile-container">
					<div className="tile-label">{intl.formatMessage({id: 'common.completion'})}</div>
					<div className="text-container">
						<div className="number-text">{progress + '%'}</div>
					</div>
				</div>
			</div>
			{expanded ? (
				<div className="header-tile-dropdown-container">
					<div
						className={'content-container-time-entries'}
						data-cy={'time-entries-readonly'}
						onClick={e => e.stopPropagation()}
					>
						<div className="new-entry-row">
							<ProgressRegistration task={task} isTaskModal={true} close={close} />
						</div>
					</div>
				</div>
			) : null}
		</div>
	);
};

ProgressTile.propTypes = {
	viewer: PropTypes.object.isRequired,
	intl: PropTypes.object.isRequired,
	task: PropTypes.object.isRequired,
	project: PropTypes.object.isRequired,
};

export {ProgressTile};
