import {Selections, SelectionsHeader, SelectionsHeaderActions} from './SkillsSelections.styled';
import {CrossIcon, InputLabel, WarningIcon} from 'web-components';
import Search from '../../../../../components/new-ui/search';
import {Scrollbars} from 'react-custom-scrollbars';
import React, {useState} from 'react';
import _ from 'lodash';
import {useIntl} from 'react-intl';
import {FeedbackButton} from '../../buttons/feedback-button';
import {FlexGroup, HoverItem, HoverShow, ItemDescription, ItemName, ItemNote, ItemWarning} from '../../modals/ItemList.styled';
import DotsHorizontal from '../../../../../images/cross-section-icons/dots_horizontal.svg';
import ContextMenu from '../../context-menus/context_menu';
import {getSkillNameAndLevel} from '../../../../../containers/settings/skills/SkillsUtil';

export const SMALL = 'SMALL';
export const LARGE = 'LARGE';

export const SkillsSelections = ({
	skills,
	selectedSkillPersons,
	onRemove,
	warningCallback = () => false,
	header = '',
	size = SMALL,
	hideSelectionHeaderActions = false,
	showGiveFeedback = false,
	skillLevelsEnabled = false,
	skillLevels,
	onSkillLevelChange,
}) => {
	const {formatMessage} = useIntl();

	const skillById = _.keyBy(skills, skill => skill.id);

	const [searchValue, setSearchValue] = useState('');
	const [showSkillContextMenu, setShowSkillContextMenu] = useState(null);
	const [skillContextMenuX, setSkillContextMenuX] = useState(null);
	const [skillContextMenuY, setSkillContextMenuY] = useState(null);

	const getSearchString = skill => [skill.name, skill.category?.name].map(string => string?.toLowerCase()).join(' ');

	const searchFilter = skill => {
		return getSearchString(skill).includes(searchValue.toLowerCase());
	};

	const selected = selectedSkillPersons
		.map(sp => skillById[sp.skillId])
		.filter(Boolean)
		.filter(searchFilter)
		.sort((a, b) => a.name.localeCompare(b.name))
		.sort((a, b) => {
			const aWarning = !!warningCallback(a);
			const bWarning = !!warningCallback(b);
			return aWarning === bWarning ? 0 : aWarning ? -1 : 1;
		});

	const displayContextMenu = (event, skill) => {
		const target = event.target;

		if (target) {
			const {bottom, right} = target.getBoundingClientRect();
			setSkillContextMenuX(right);
			setSkillContextMenuY(bottom);
			setShowSkillContextMenu(skill.id === showSkillContextMenu ? null : skill.id);
		}
	};

	const onHoverShowClick = skillLevelsEnabled
		? (event, skill) => displayContextMenu(event, skill)
		: (event, skill) => onRemove(skill);

	const getContextMenuOptions = skill => {
		const options = [];

		const selectedSkillLevel = selectedSkillPersons.find(sp => sp.skillId === skill.id);

		skillLevels.forEach(skillLevel => {
			const isSelected = selectedSkillLevel?.skillLevelId === skillLevel.id;

			options.push({
				text: `${skillLevel.level} ${skillLevel.description}`,
				onClick: () => (isSelected ? undefined : onSkillLevelChange(skill.id, skillLevel.id)),
				disabled: isSelected,
				selected: isSelected,
			});
		});

		options.push({
			text: formatMessage({id: 'settings_people.remove_skill'}),
			textColor: '#DB0900',
			onClick: () => onRemove(skill),
			bottomOption: true,
		});

		return options;
	};

	const shouldDisplayContextMenu = skill =>
		showSkillContextMenu && showSkillContextMenu === skill.id && skillContextMenuX && skillContextMenuY;

	const getSkillLevel = skill => {
		const appliedSkillLevel = selectedSkillPersons.find(sp => sp.skillId === skill.id);

		if (appliedSkillLevel) {
			return skillLevels.find(skillLevel => skillLevel.id === appliedSkillLevel.skillLevelId);
		}

		return null;
	};

	return (
		<Selections size={size} data-cy={'skill-selections'}>
			<SelectionsHeader>
				{size !== 'LARGE' && <InputLabel text={header} />}
				{size === 'LARGE' && (
					<div>
						<h3>{header} </h3>
					</div>
				)}
				<SelectionsHeaderActions size={size}>
					{!hideSelectionHeaderActions && (
						<Search key={`selected-search`} value={searchValue} onChange={setSearchValue} />
					)}
					{showGiveFeedback && <FeedbackButton link={'https://experience.forecast.it/feedback-skills'} />}
				</SelectionsHeaderActions>
			</SelectionsHeader>
			<Scrollbars style={size === LARGE ? {height: Math.min(selected.length, 6) * 43 + 'px'} : {height: '205px'}}>
				{selected.map((skill, index) => {
					const warning = warningCallback(skill);
					const skillLevel = getSkillLevel(skill);
					const skillName = skillLevelsEnabled ? getSkillNameAndLevel(skill, skillLevel) : skill.name;
					return (
						<HoverItem size={size} key={skill.id} data-cy={`selected-skill-${index}`}>
							<FlexGroup>
								{!!warning && <WarningIcon size={WarningIcon.SIZE.STANDARD} updated color={'#E01212'} />}
								<ItemDescription>
									<ItemName>{skillName}</ItemName>
									{warning ? (
										<ItemWarning>{warning}</ItemWarning>
									) : (
										<ItemNote>
											{skill.category?.name || formatMessage({id: 'settings_skills.no_category'})}
										</ItemNote>
									)}
								</ItemDescription>
							</FlexGroup>
							<HoverShow onClick={event => onHoverShowClick(event, skill)}>
								{skillLevelsEnabled ? (
									<img src={DotsHorizontal} alt="Skill options menu" />
								) : (
									<CrossIcon size={CrossIcon.SIZE.STANDARD} color={'#000'} cy={'clear-icon'} />
								)}
							</HoverShow>

							{shouldDisplayContextMenu(skill) && (
								<ContextMenu
									key={skill.id}
									cy="skill-options-context-menu"
									options={getContextMenuOptions(skill)}
									closeContextMenu={() => setShowSkillContextMenu(null)}
									contextMenuPosition={{
										x: skillContextMenuX,
										y: skillContextMenuY,
									}}
								/>
							)}
						</HoverItem>
					);
				})}
			</Scrollbars>
		</Selections>
	);
};
