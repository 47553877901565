import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TooltipContainer from '../../../shared/components/tooltips/tooltip_container';
import InformationIcon from '../../../../images/information-icon';

class ProjectSprintsHeaderTile extends Component {
	render() {
		return (
			<div className={'sprints-header-elem'}>
				<div className={'sprints-header-content'}>
					{this.props.infoIcon ? (
						<div
							className={'alignment-box' + (this.props.infoIcon ? ' flexed' : '')}
							style={{float: this.props.align == null ? 'right' : this.props.align}}
						>
							<div className="skill-label-container">
								<div className={'label'}>{this.props.label}</div>
								<div className={'value'}>
									{this.props.value == null ? (
										'-'
									) : (
										<>
											{this.props.value}
											{this.props.valueUnit}
										</>
									)}
								</div>
							</div>
							<TooltipContainer
								infoText={this.props.tooltip}
								tooltipInfinteDuration={true}
								triangleLocation={this.props.triangleLocation}
								width={this.props.tooltipWidth}
								edgeOffset={this.props.edgeOffset}
								translatedMessage={true}
							>
								<InformationIcon fill={this.props.isWhite ? '#e6e6e6' : '#4a5968'} />
							</TooltipContainer>
						</div>
					) : (
						<div className={'alignment-box'} style={{float: this.props.align == null ? 'right' : this.props.align}}>
							<TooltipContainer
								infoText={this.props.tooltip}
								triangleLocation={this.props.triangleLocation}
								width={this.props.tooltipWidth}
								edgeOffset={this.props.edgeOffset}
								translatedMessage={true}
							>
								<div className={'label'}>{this.props.label}</div>
								<div className={'value'}>
									{this.props.value == null ? (
										'-'
									) : (
										<>
											{this.props.value}
											{this.props.valueUnit}
										</>
									)}
								</div>
							</TooltipContainer>
						</div>
					)}
				</div>
			</div>
		);
	}
}
ProjectSprintsHeaderTile.propTypes = {
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	label: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	valueUnit: PropTypes.string,
};
export default ProjectSprintsHeaderTile;
