import React, {useEffect, useRef, useState} from 'react';
import {
	DeprecatedAvatar as Avatar,
	DeprecatedContextMenuItem as ContextMenuItem,
	DeprecatedDropdownMenu as DropdownMenu,
	Icon,
	DeprecatedInput as Input,
	DeprecatedListCell as ListCell,
	DeprecatedListRow as ListRow,
	DeprecatedPlaceholderItem as PlaceholderItem,
	DeprecatedSearchWithDropdown as SearchWithDropdown,
	DeprecatedText as Text,
} from '@forecast-it/design-system';
import useSearch from '../../../shared/hooks/useSearch';
import {useIntl} from 'react-intl';
import {getDefaultMediumClientLogo} from '../../../../components/default_avatars';
import PropTypes from 'prop-types';
import {clientLogoSrc} from '../../../../directApi';

const ClientSearchDropdown = ({clients, selectedClient, setClient = () => null}) => {
	const [searchValue, setSearchValue] = useState('');
	const [_clients, setClients] = useState([]);

	const _search = useSearch();
	const {formatMessage} = useIntl();

	const inputRef = useRef();

	useEffect(() => {
		if (selectedClient?.name) {
			inputRef.current.setValue(selectedClient.name);
		}
	}, [selectedClient]);

	useEffect(() => {
		const clientsList = clients?.edges.map(client => client.node);
		if (selectedClient?.id) {
			clientsList.unshift({id: null, name: formatMessage({id: 'common.no_client'})});
		}
		setClients(_search.search(clientsList, 'name', searchValue));
	}, [clients, searchValue, selectedClient]);

	const ClientDropdownMenu = ({close}) => (
		<DropdownMenu>
			{_clients?.length > 0 ? (
				_clients.map(client => (
					<ContextMenuItem
						onClick={() => {
							setClient(client);
							setSearchValue('');
							inputRef.current.setValue(client.id ? client.name : null);
							close();
						}}
						cy={'client_item'}
					>
						<ListRow key={client.id}>
							<ListCell size={'s'}>
								{client.id && (
									<Avatar
										src={
											client.logoId
												? clientLogoSrc(client.logoId)
												: getDefaultMediumClientLogo(client.logoDefaultId)
										}
									/>
								)}
							</ListCell>
							<ListCell>{_search.highlightMatch(client.name, searchValue, false)}</ListCell>
							<ListCell fixed={'20px'}>
								{client.id === selectedClient?.id && <Icon icon={'tick'} size={'m'} />}
							</ListCell>
						</ListRow>
					</ContextMenuItem>
				))
			) : (
				<PlaceholderItem>
					<Text color={'disabled'}>{formatMessage({id: 'search.no_match'}, {searchText: searchValue})}</Text>
				</PlaceholderItem>
			)}
		</DropdownMenu>
	);

	return (
		<SearchWithDropdown DropdownMenu={ClientDropdownMenu}>
			<Input
				ref={inputRef}
				icon={'search'}
				placeholder={formatMessage({id: 'common.action.add_entity'}, {entity: formatMessage({id: 'common.client'})})}
				onChange={e => {
					setSearchValue(e.target.value);
				}}
				data-cy={'client_search_dropdown'}
			/>
		</SearchWithDropdown>
	);
};

export default ClientSearchDropdown;

ClientSearchDropdown.propTypes = {
	clients: PropTypes.array.isRequired,
	selectedClient: PropTypes.object,
	setClient: PropTypes.func,
};
