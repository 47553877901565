/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SimilarTaskSection_viewer$ref = any;
export type SimilarTaskSection_QueryVariables = {|
  taskId: string
|};
export type SimilarTaskSection_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: SimilarTaskSection_viewer$ref,
  |}
|};
export type SimilarTaskSection_Query = {|
  variables: SimilarTaskSection_QueryVariables,
  response: SimilarTaskSection_QueryResponse,
|};
*/


/*
query SimilarTaskSection_Query(
  $taskId: ID!
) {
  viewer {
    actualPersonId
    component(name: "similar_task_section")
    ...SimilarTaskSection_viewer_4v7Zcs
    id
  }
}

fragment DeprecatedProjectIndicatorJS_project on ProjectType {
  name
  companyProjectId
  customProjectId
  projectColor
}

fragment SimilarTaskSection_viewer_4v7Zcs on Viewer {
  id
  task(id: $taskId) {
    id
    name
    similarTasks(overrunOnly: true) {
      edges {
        node {
          id
          companyTaskId
          name
          estimateForecast
          fullAccessToProject
          project {
            id
            name
            projectColor
            companyProjectId
            ...DeprecatedProjectIndicatorJS_project
          }
          timeRegistrations(first: 1000000) {
            edges {
              node {
                minutesRegistered
                id
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "taskId",
    "type": "ID!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "similar_task_section"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"similar_task_section\")"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SimilarTaskSection_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "args": [
              {
                "kind": "Variable",
                "name": "taskId",
                "variableName": "taskId"
              }
            ],
            "kind": "FragmentSpread",
            "name": "SimilarTaskSection_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SimilarTaskSection_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "taskId"
              }
            ],
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "task",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "overrunOnly",
                    "value": true
                  }
                ],
                "concreteType": "TaskTypeConnection",
                "kind": "LinkedField",
                "name": "similarTasks",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TaskTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Task",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "companyTaskId",
                            "storageKey": null
                          },
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "estimateForecast",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fullAccessToProject",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProjectType",
                            "kind": "LinkedField",
                            "name": "project",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "projectColor",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "companyProjectId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "customProjectId",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "first",
                                "value": 1000000
                              }
                            ],
                            "concreteType": "TimeRegTypeConnection",
                            "kind": "LinkedField",
                            "name": "timeRegistrations",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "TimeRegTypeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "TimeRegType",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "minutesRegistered",
                                        "storageKey": null
                                      },
                                      (v3/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": "timeRegistrations(first:1000000)"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "similarTasks(overrunOnly:true)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "SimilarTaskSection_Query",
    "operationKind": "query",
    "text": "query SimilarTaskSection_Query(\n  $taskId: ID!\n) {\n  viewer {\n    actualPersonId\n    component(name: \"similar_task_section\")\n    ...SimilarTaskSection_viewer_4v7Zcs\n    id\n  }\n}\n\nfragment DeprecatedProjectIndicatorJS_project on ProjectType {\n  name\n  companyProjectId\n  customProjectId\n  projectColor\n}\n\nfragment SimilarTaskSection_viewer_4v7Zcs on Viewer {\n  id\n  task(id: $taskId) {\n    id\n    name\n    similarTasks(overrunOnly: true) {\n      edges {\n        node {\n          id\n          companyTaskId\n          name\n          estimateForecast\n          fullAccessToProject\n          project {\n            id\n            name\n            projectColor\n            companyProjectId\n            ...DeprecatedProjectIndicatorJS_project\n          }\n          timeRegistrations(first: 1000000) {\n            edges {\n              node {\n                minutesRegistered\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '02e332171dd002464ae3bc668ee2dca0';

module.exports = node;
