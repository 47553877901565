import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TabStyle = styled.div`
	flex-grow: 1;
	display: flex;
`;

export const Tab = ({children}) => {
	return <TabStyle>{children}</TabStyle>;
};

Tab.propTypes = {
	/**
	 * The title to display in the tab
	 */
	title: PropTypes.string.isRequired,
	/**
	 * Put on the tab that should be selected pr default. If not specified on any tab, the first tab is selected pr. default.
	 */
	defaultSelected: PropTypes.bool,
	/**
	 * The content to show when the tab is selected
	 */
	children: PropTypes.node,
	/**
	 * Cypress lookup key to add to the tab as data-cy={props.cy}
	 */
	cy: PropTypes.string,
};
