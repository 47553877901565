import React, {Component} from 'react';
import PropTypes from 'prop-types';

class UploadingOverlay extends Component {
	render() {
		return <div className={`uploading-overlay-container loading${this.props.useGrayOverlay ? ' gray-background' : ''}`} />;
	}
}

UploadingOverlay.propTypes = {
	useGrayOverlay: PropTypes.bool,
};

export default UploadingOverlay;
