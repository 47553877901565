import {DeprecatedButton as Button} from '@forecast-it/design-system';
import React from 'react';
import ForecastQueryRenderer from '../../../../../ForecastQueryRenderer';
import SuggestedStatus, {SuggestedStatusQuery} from './SuggestedStatus';

export const SuggestedStatusRenderer = ({projectId, programPrefix, currentStatusVariant, callback}) => {
	return (
		<ForecastQueryRenderer
			key="query-render-suggested-status"
			query={SuggestedStatusQuery}
			variables={{projectId: projectId, programPrefix: programPrefix}}
			customLoader={() => {
				return (
					<Button type={'secondary'} disabled={true} style={{marginLeft: '16px'}}>
						See Suggestion
					</Button>
				);
			}}
			render={(relayProps, retry) => {
				// @ts-ignore
				return <SuggestedStatus {...relayProps} callback={callback} currentStatusVariant={currentStatusVariant} />;
			}}
		/>
	);
};
