import { IconTooltip, Text, theme } from '@forecast-it/design-system';
import React from 'react';
import { RoleDropdownLabelFormat } from './RoleDropdown';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Label } from '../../../../my-work-tab/my-timesheets-page/bottom-section/timesheets-time-registration-table/advanced-time-registration-input/AdvancedTimeRegistrationStyled';
export const LabelWarningContainer = styled.div `
	height: 11px; // to make this fit it with existing modals without changing all of them, we need to lock the height
	margin-bottom: ${p => (p.labelFormat === RoleDropdownLabelFormat.NO_MARGIN ? '0' : '6px')};
	overflow: visible;
	display: flex;
	gap: ${theme.spacing['100']};
	align-items: center;
`;
export function LabelAndWarning({ showLabel, labelFormat, warningText, children }) {
    const { formatMessage } = useIntl();
    return showLabel ? (React.createElement(React.Fragment, null,
        React.createElement(LabelWarningContainer, { labelFormat: labelFormat },
            React.createElement(Label, null, formatMessage({ id: 'common.role' })),
            warningText ? (React.createElement(IconTooltip, { icon: 'warning', iconSize: 's', iconColor: 'error' },
                React.createElement(Text, { size: '2' }, warningText))) : null),
        React.createElement("div", { className: "roles" }, children))) : (React.createElement(React.Fragment, null, children));
}
