import React, {useEffect, useState} from 'react';

import {createRefetchContainer, graphql} from 'react-relay';
import SettingsResourcesSubNav from './SettingsResourcesSubNav';
import * as tracking from '../../../tracking';
import {BulkSelectPopup, Table} from 'web-components';
import _ from 'lodash';
import {useIntl} from 'react-intl';
import SkillCategoryRow from '../../../containers/settings/skills/SkillCategoryRow';
import Util from '../../shared/util/util';
import {HeaderBarStyled} from '../../../components/initial-plan/InitialPlan.styled';
import HeaderBar from '../../shared/components/headers/header-bar/header_bar';
import {
	BUTTON_COLOR,
	BUTTON_STYLE as BUTTON_STYLED,
	ELEMENT_TYPE,
	FILTER_SECTION,
	FILTER_TYPE,
	MODULE_TYPES,
} from '../../../constants';
import {FILTER_SECTIONS} from '../../shared/components/filters/FilterWrapper';
import CustomScrollDiv from '../../shared/components/scroll-bars/custom_scroll_div';
import {MODAL_TYPE, showModal} from '../../shared/components/modals/generic_modal_conductor';
import {getSkillsBulkOptions} from '../../../containers/settings/skills/SkillsBulkLogic';
import {hasModule} from '../../shared/util/ModuleUtil';
import {useTrackPage} from '../../../tracking/amplitude/hooks/useTrackPage';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';

const NO_CATEGORY = {id: undefined};
const SettingsSkills = ({viewer}) => {
	const {formatMessage} = useIntl();
	const filterString = `admin-resources-skills-filters`;
	const [searchFilterValue, setSearchFilterValue] = useState('');
	const [filters, setFilters] = useState({});
	const [filterFunctions, setFilterFunctions] = useState(() => true);
	const isFiltering = filters && Object.keys(filters).length;
	const [selected, setSelected] = useState(new Set());

	useTrackPage('Skills');

	useEffect(() => {
		document.title = 'Skills - Account - Forecast';
		// Segment
		tracking.trackPage('skills');
	}, []);

	let skills = viewer.company.skills.edges.map(edge => edge.node);
	let skillCategories = viewer.company.skillCategories.edges.map(edge => edge.node);
	skillCategories.push(NO_CATEGORY);

	useEffect(() => {
		const skillIds = viewer.company.skills.edges.map(edge => edge.node.id);
		for (const selectedSkillId of selected) {
			if (!skillIds?.includes(selectedSkillId)) {
				selected.delete(selectedSkillId);
			}
		}
		setSelected(new Set(selected));
	}, [viewer.company.skills]);

	const toggleSkillSelected = skillId => {
		const existed = selected.delete(skillId);
		if (!existed) {
			selected.add(skillId);
		}
		setSelected(new Set(selected));
	};

	const isSkillSelected = skillId => selected.has(skillId);

	const onFilterChange = (filters, filterFunctions) => {
		setFilters(filters);
		setFilterFunctions(filterFunctions);
	};

	const getHeader = () => {
		const leftContent = [];
		const rightContent = [];

		// search
		rightContent.push({
			type: ELEMENT_TYPE.SEARCH,
			value: searchFilterValue,
			onChange: search => setSearchFilterValue(search),
			cy: 'skills-search',
		});

		// filters
		const skillFilters = [
			FILTER_TYPE.SKILL_PERSON,
			FILTER_TYPE.SKILL_ROLE,
			FILTER_TYPE.SKILL_RESOURCE_TYPE,
			FILTER_TYPE.SKILL_TEAM,
			FILTER_TYPE.SKILL_DEPARTMENT,
			FILTER_TYPE.SKILL_ASSIGNED,
		];

		if (viewer.company.skillLevelsEnabled) {
			skillFilters.push(FILTER_TYPE.SKILL_LEVEL);
		}

		rightContent.push({
			type: ELEMENT_TYPE.FILTER_V4,
			defaultSection: FILTER_SECTIONS.SKILLS,
			skillFilters,
			viewer,
			appliedFiltersName: filterString,
			filterSection: FILTER_SECTION.SKILLS,
			onFiltersChange: onFilterChange,
			userpilot: 'filter-button',
			cy: 'admin-resources-skills-filters',
		});

		// create skill
		rightContent.push({
			id: 'create-skill-button',
			text: formatMessage({id: 'common.create_skill'}),
			type: ELEMENT_TYPE.BUTTON,
			style: BUTTON_STYLED.FILLED,
			color: BUTTON_COLOR.PURPLE,
			callback: () => {
				tracking.trackEvent('skills - create skill modal opened');
				trackEvent('Create Skill Modal', 'Opened');
				showModal({
					type: MODAL_TYPE.CREATE_SKILL,
				});
			},
			dataCy: 'skills-create-skill',
		});

		// create category
		rightContent.push({
			id: 'create-category-button',
			text: formatMessage({id: 'common.create_skill_category'}),
			type: ELEMENT_TYPE.BUTTON,
			style: BUTTON_STYLED.OUTLINE,
			color: BUTTON_COLOR.GREY,
			callback: () => {
				tracking.trackEvent('skills - create category modal opened');
				trackEvent('Create Skill Category Modal', 'Opened');
				showModal({
					type: MODAL_TYPE.CREATE_SKILL_CATEGORY,
				});
			},
			dataCy: 'skills-create-skill-category',
		});

		// manage skill levels
		if (hasModule(MODULE_TYPES.SKILL_LEVELS)) {
			rightContent.push({
				id: 'manage-skill-levels-button',
				text: formatMessage({
					id: viewer.company.skillLevelsEnabled ? 'common.manage_skill_levels' : 'common.enable_skill_levels',
				}),
				type: ELEMENT_TYPE.BUTTON,
				style: BUTTON_STYLED.OUTLINE,
				color: BUTTON_COLOR.GREY,
				callback: () => {
					tracking.trackEvent('skills - manage skill levels modal opened');
					trackEvent('Manage Skill Levels Modal', 'Opened');
					showModal({
						type: MODAL_TYPE.MANAGE_SKILL_LEVELS,
					});
				},
				dataCy: 'skills-manage-skill-levels',
			});
		}

		return <HeaderBar leftContent={leftContent} rightContent={rightContent} />;
	};

	// apply filters
	const hasFiltersApplied = filterFunctions && filterFunctions.skillFilter && isFiltering;
	if (hasFiltersApplied) {
		const filterOptions = {
			persons: viewer.company.allPersons,
			teams: viewer.company.teams,
			departments: viewer.company.departments,
		};
		skills = skills.filter(skill => filterFunctions.skillFilter(skill, filterOptions));
	}

	// filter skills by search
	if (searchFilterValue) {
		skills = skills.filter(skill => Util.normalizedIncludes(skill.name, searchFilterValue));
	}

	// group skills into categories by id
	const skillsByCategoryId = _.groupBy(skills, skill => skill.category?.id);

	// remove empty categories on filtering
	if (searchFilterValue || hasFiltersApplied) {
		skillCategories = skillCategories.filter(
			skillCategory => skillCategory === NO_CATEGORY || skillsByCategoryId[skillCategory.id]
		);
	}

	const selectedSkillsCount = selected.size;
	const clearSkillsSelection = () => setSelected(new Set());

	return (
		<div className="section-content settings-app settings-skills" data-cy={'settings-skills-page'}>
			<SettingsResourcesSubNav viewer={viewer} />
			<HeaderBarStyled secondLevel>{getHeader()}</HeaderBarStyled>
			{selectedSkillsCount > 0 && (
				<BulkSelectPopup
					itemCount={selectedSkillsCount}
					counterText={formatMessage({id: 'bulk_edit.skills_selected'})}
					actionOptions={getSkillsBulkOptions(selected, viewer.company, formatMessage, clearSkillsSelection)}
					onClose={clearSkillsSelection}
				/>
			)}
			<CustomScrollDiv>
				<div className="section-body">
					<Table paddedCells>
						<Table.Header>
							<Table.HeaderColumn visible usePadding width={Table.TABLE_COLUMN_WIDTH.EXTRA_LARGE}>
								<Table.HeaderColumn.Title>
									{formatMessage({id: 'settings_skills.skill'})}
								</Table.HeaderColumn.Title>
							</Table.HeaderColumn>
							<Table.HeaderColumn visible usePadding width={Table.TABLE_COLUMN_WIDTH.LARGE}>
								<Table.HeaderColumn.Title>
									{formatMessage({id: 'settings_skills.users'})}
								</Table.HeaderColumn.Title>
							</Table.HeaderColumn>
							<Table.HeaderColumn visible usePadding width={Table.TABLE_COLUMN_WIDTH.LARGE}>
								<Table.HeaderColumn.Title>
									{formatMessage({id: 'settings_skills.virtual_resources'})}
								</Table.HeaderColumn.Title>
							</Table.HeaderColumn>
							<Table.HeaderColumn visible usePadding width={Table.TABLE_COLUMN_WIDTH.LARGE}>
								<Table.HeaderColumn.Title>
									{formatMessage({id: 'settings_skills.deactivated_users'})}
								</Table.HeaderColumn.Title>
							</Table.HeaderColumn>
							<Table.HeaderColumn
								flex={1}
								visible
								usePadding
								width={Table.TABLE_COLUMN_WIDTH.SMALL}
								align={Table.TABLE_COLUMN_ALIGNMENT.RIGHT}
							/>
						</Table.Header>
						<Table.Rows data={{rows: skillCategories}} canExpand initiallyExpanded keyFunc={row => row.id}>
							{({rowData: category, tableColumnsProps, nextLevelProps, expanded}) => (
								<SkillCategoryRow
									company={viewer.company}
									skillCategory={category.id ? category : null}
									skills={skillsByCategoryId[category.id] || []}
									expanded={expanded}
									isSkillSelected={isSkillSelected}
									toggleSkillSelected={toggleSkillSelected}
									tableColumnsProps={tableColumnsProps}
									nextLevelProps={nextLevelProps}
								/>
							)}
						</Table.Rows>
					</Table>
				</div>
			</CustomScrollDiv>
		</div>
	);
};

const settingsSkillsQuery = graphql`
	query settingsSkillsQuery {
		viewer {
			actualPersonId
			component(name: "settings_skills")
			...settingsSkills_viewer
		}
	}
`;

export {settingsSkillsQuery};

export default createRefetchContainer(SettingsSkills, {
	viewer: graphql`
		fragment settingsSkills_viewer on Viewer {
			...SettingsResourcesSubNav_viewer
			id
			company {
				id
				skillLevelsEnabled
				...SkillCategoryRow_company
				departments(first: 10000) @connection(key: "Company_departments") {
					edges {
						...DepartmentDropdown_departments
						node {
							persons {
								edges {
									node {
										id
									}
								}
							}
						}
					}
				}
				teams(first: 1000000) {
					edges {
						node {
							id
							teamPersons(first: 1000) {
								edges {
									node {
										id
										person {
											id
										}
									}
								}
							}
						}
					}
				}
				allPersons(first: 10000, excludeClientUsers: true) {
					edges {
						...PersonDropdown_persons
						node {
							role {
								id
							}
							department {
								id
							}
						}
					}
				}
				skills(first: 10000) @connection(key: "Company_skills") {
					edges {
						node {
							id
							name
							category {
								id
							}
							skillPersons {
								person {
									id
									role {
										id
									}
									department {
										id
									}
									active
									permissions
								}
								level {
									id
								}
							}
							...SkillCategoryRow_skills
						}
					}
				}
				skillCategories(first: 10000) @connection(key: "Company_skillCategories") {
					edges {
						node {
							id
							name
							...SkillCategoryRow_skillCategory
						}
					}
				}
			}
			filters(first: 1000000, filterSection: SKILLS) @connection(key: "Viewer_filters", filters: []) {
				edges {
					node {
						id
						name
						section
						value
						updatedAt
					}
				}
			}
		}
	`,
});
