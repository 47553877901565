import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import Subtask from './subtask';
import {ESTIMATION_UNIT} from '../../../../../../constants';
import InputFieldV2 from '../../../../../../components/new-ui/input_field';
import CreateSubTaskMutation from '../../../../../../mutations/create_sub_task_mutation';
import UpdateSubTaskMutation from '../../../../../../mutations/update_sub_task_mutation';
import Util from '../../../../util/util';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import SubtaskIcon from '../../../../../../images/v2/components/workflow-task/v3/ico-subtask.svg';
import ExpandIcon from '../../../../../../images/v2/components/dropdown/expand_icon';
import WarningIcon from '../../../../../../images/warning_icon';
import InformationIcon from '../../../../../../images/information-icon';
import TooltipContainer from '../../../tooltips/tooltip_container';
import {dispatch, EVENT_ID, subscribe} from '../../../../../../containers/event_manager';
import {cloneDeep} from 'lodash';
import HoursInput from '../../../inputs/hours-input/hours_input_view';
import ForecastTooltip from '../../../tooltips/ForecastTooltip';

const getItemStyle = (isDragging, draggableStyle) => ({
	// styles we need to apply on draggables
	...draggableStyle,
});

class SubtaskSection extends Component {
	constructor(props) {
		super(props);

		this.state = {
			newSubTaskInputName: '',
			newSubTaskInputEstimate: '',
			draggingId: null,
			expanded: this.props.addedByUser || localStorage.getItem('task-modal-v3-subtask-section-expanded') === 'true',
			addingSubTask: false,
			pulseDifference: false,
		};
	}

	componentDidMount() {
		subscribe(EVENT_ID.SUBTASK_ESTIMATE_UPDATE, this.animateDifference.bind(this));
	}

	animateDifference(taskId) {
		if (taskId === this.props.task.id) {
			this.setState({pulseDifference: true});
			setTimeout(() => this.setState({pulseDifference: false}), 2000);
		}
	}

	toggleExpandSection() {
		if (this.state.expanded) {
			Util.localStorageSetItem('task-modal-v3-subtask-section-expanded', false);
		} else {
			Util.localStorageSetItem('task-modal-v3-subtask-section-expanded', true);
		}

		this.setState({expanded: !this.state.expanded});
	}

	handleCreateSubtask() {
		const hourEstimated = this.props.project.estimationUnit === ESTIMATION_UNIT.HOURS;
		const name = this.state.newSubTaskInputName;
		const hours = this.state.newSubTaskInputEstimate * (hourEstimated ? 60 : 1);
		const isTwoLevelSubTask = this.props.project.taskLevels === 2;
		if (!this.state.addingSubTask && name !== '' && !isNaN(hours)) {
			const onSuccess = () => {
				this.setState({
					newSubTaskInputName: '',
					newSubTaskInputEstimate: '',
					addingSubTask: false,
				});

				this._subtask_name_input.focus();

				this.props.scrollToSubtaskInput();

				if (isTwoLevelSubTask) {
					dispatch(EVENT_ID.SUBTASK_ESTIMATE_UPDATE, this.props.task.id);
				}

				if (this.props.task.subTasks.edges.length === 1 && isTwoLevelSubTask) {
					dispatch(EVENT_ID.SHOW_REMAINING_NOTICE_TASK_MODAL, this.props.task.id);
				}
			};

			this.setState({addingSubTask: true});

			Util.CommitSchedulingModalUpdate(
				CreateSubTaskMutation,
				{
					taskId: this.props.task.id,
					name: name,
					estimate: hours,
				},
				onSuccess
			);
		}
	}

	handleSubtaskInputChange(text) {
		this.setState({newSubTaskInputName: text || ''});

		const hourEstimated = this.props.project.estimationUnit === ESTIMATION_UNIT.HOURS;
		if (text.length && this.props.task.subTasks.edges.length === 0) {
			this.setState({newSubTaskInputEstimate: this.props.task.estimateForecast / (hourEstimated ? 60 : 1)});
		}
	}

	handleSubtaskHoursChange(value) {
		this.setState({newSubTaskInputEstimate: value});
	}

	reorderSubtask(result) {
		if (result.destination) {
			const oldSortOrder = this.props.task.subTasks.edges.find(edge => edge.node.id === result.draggableId).node
				.sortOrder;
			const newSortOrder = result.destination.index;

			if (oldSortOrder !== newSortOrder) {
				const optimisticSortOrder = newSortOrder + (oldSortOrder > newSortOrder ? -0.5 : 0.5);

				Util.CommitMutation(UpdateSubTaskMutation, {
					id: result.draggableId,
					sortOrder: newSortOrder,
					optimisticSortOrder: optimisticSortOrder,
				});
			}
		}
		this.setState({draggingId: null});
	}

	render() {
		const {formatMessage} = this.props.intl;
		const isTwoLevelSubTask = this.props.project.taskLevels === 2;
		const {
			disabled,
			task,
			task: {estimateForecast},
			project: {estimationUnit},
		} = this.props;

		const subtasksEstimationSum = task.subTasks.edges.reduce((total, subtask) => total + subtask.node.estimate, 0);
		const numberOfDoneSubtasks = this.props.task.subTasks.edges.filter(subTask => subTask.node.done).length;

		return (
			<div
				className={
					'task-modal-v3-subtasks-section' +
					(this.props.addedByUser && !this.props.forceRerender ? ' animated fadeInLeft' : '')
				}
				data-cy="task-modal-subtasks-section"
			>
				<button className="subtask-section-header" onClick={this.toggleExpandSection.bind(this)}>
					<div className="subtask-icon">
						<img src={SubtaskIcon} alt={formatMessage({id: 'common.sub_tasks'})} />
					</div>
					<div className="subtask-section-header-item title">
						<div>
							{isTwoLevelSubTask ? formatMessage({id: 'common.sub_tasks'}) : formatMessage({id: 'common.to_do'})}
						</div>
					</div>

					<div className="subtask-section-header-item status">
						<div className="item-name">
							(
							<span className="status-finished">
								{numberOfDoneSubtasks > 0 ? (
									<svg xmlns="http://www.w3.org/2000/svg" width="10" height="8" viewBox="0 0 10 8">
										<path
											fill="#5FC600"
											fill-rule="evenodd"
											d="M8 0L3.707 4.293 1.414 2 0 3.414l3.707 3.707 5.707-5.707z"
										/>
									</svg>
								) : null}
								<span className={numberOfDoneSubtasks > 0 ? 'finished-number' : ''}>
									{numberOfDoneSubtasks}
								</span>
								/<span className="out-of-number">{this.props.task.subTasks.edges.length}</span>
							</span>
							)
						</div>
					</div>

					{isTwoLevelSubTask ? (
						<div className="subtask-section-header-item deprecated">
							<ForecastTooltip
								delay={100}
								interactive={true}
								appendTo={() => document.body} // To prevent styling from breaking, as per documentation
								placement={'right'}
								content={formatMessage(
									{id: 'task_modal.old_subtask_version'},
									{
										a: msg => (
											<a
												href="https://support.forecast.app/hc/en-us/articles/5675601042321"
												target="_blank"
												rel="noopener noreferrer"
											>
												{msg}
											</a>
										),
									}
								)}
							>
								<span>{formatMessage({id: 'common.old_version'})}</span>
							</ForecastTooltip>
						</div>
					) : (
						''
					)}

					{task.subTasks.edges.length && isTwoLevelSubTask ? (
						<div className="subtask-section-header-item estimations-info-text">
							<span>{formatMessage({id: 'task_modal.difference_from_forecast'})}:</span>
							{estimationUnit === ESTIMATION_UNIT.HOURS ? (
								<span className={`value ${this.state.pulseDifference ? 'animated bounceIn ' : ''}`}>
									{Util.convertMinutesToFullHour(
										estimateForecast - subtasksEstimationSum,
										this.props.intl,
										true
									)}
								</span>
							) : (
								<span className={`value ${this.state.pulseDifference ? 'animated bounceIn ' : ''}`}>
									{estimateForecast - subtasksEstimationSum}
									{' p'}
								</span>
							)}

							{estimateForecast - subtasksEstimationSum !== 0 ? (
								<span className="warning-icon">
									<TooltipContainer
										triangleLocation={'topMiddle'}
										infoText={formatMessage({
											id:
												estimateForecast < subtasksEstimationSum
													? 'task_modal.difference_from_forecast_danger_tooltip'
													: 'task_modal.difference_from_forecast_warning_tooltip',
										})}
									>
										<WarningIcon danger={estimateForecast < subtasksEstimationSum} />
									</TooltipContainer>
								</span>
							) : null}
							<span className="info-icon">
								<TooltipContainer
									infoText={[
										{
											title: formatMessage({id: 'common.difference'}),
											details: [
												formatMessage({id: 'common.estimate'}),
												'-',
												formatMessage({id: 'task_modal.sum_of_all_subtask_estimates'}),
											],
										},
									]}
									translatedMessage={true}
								>
									<InformationIcon />
								</TooltipContainer>
							</span>
						</div>
					) : null}

					<ExpandIcon expanded={this.state.expanded} />
				</button>
				{this.state.expanded ? (
					<div className="subtask-section-content">
						<div className="subtasks-list">
							<DragDropContext
								onDragEnd={this.reorderSubtask.bind(this)}
								onDragStart={result => {
									this.setState({draggingId: result ? result.draggableId : null});
								}}
							>
								<Droppable droppableId="subtasks" isDropDisabled={disabled}>
									{(provided, snapshot) => (
										<div ref={provided.innerRef} style={{}}>
											{cloneDeep(this.props.task.subTasks.edges)
												.sort((a, b) => {
													return a.node.sortOrder - b.node.sortOrder;
												})
												.map((subTaskEdge, index) => (
													<Draggable
														key={subTaskEdge.node.id}
														draggableId={subTaskEdge.node.id}
														index={index}
														isDragDisabled={disabled}
													>
														{(provided, snapshot) => (
															<Subtask
																provided={provided}
																innerRef={provided.innerRef}
																style={getItemStyle(
																	snapshot.isDragging,
																	provided.draggableProps.style
																)}
																key={subTaskEdge.node.id}
																viewer={this.props.viewer}
																subTask={subTaskEdge.node}
																disabled={disabled}
																project={this.props.project}
																task={this.props.task}
																isTwoLevelSubTask={isTwoLevelSubTask}
																draggingId={this.state.draggingId}
																cy="task-modal-subtask"
															/>
														)}
													</Draggable>
												))}
											{provided.placeholder}
										</div>
									)}
								</Droppable>
							</DragDropContext>
						</div>
						{disabled ? null : (
							<div className="add-subtask">
								<div className={'subtask-name' + (!isTwoLevelSubTask ? ' todo-name' : '')}>
									<InputFieldV2
										id={'task-modal-v3-subtask-name'}
										hideLabel={true}
										value={this.state.newSubTaskInputName}
										onChange={this.handleSubtaskInputChange.bind(this)}
										type="text"
										onEnter={this.handleCreateSubtask.bind(this)}
										ref={e => (this._subtask_name_input = e)}
										customClass={'task-modal-subtask-name'}
										placeholder={
											isTwoLevelSubTask
												? formatMessage({id: 'task_modal.new_subtask'})
												: formatMessage({id: 'task_modal.new_to_do'})
										}
										cy="task-modal-add-subtask"
										maxLength={191}
									/>
								</div>

								{isTwoLevelSubTask ? (
									<div className="subtask-hours">
										{this.props.project.estimationUnit === ESTIMATION_UNIT.HOURS ? (
											<HoursInput
												ref={e => (this._subtask_hours_input = e)}
												value={this.state.newSubTaskInputEstimate}
												placeholder={formatMessage({id: 'common.estimate'})}
												cy="task-modal-add-subtask"
												mutation={this.handleSubtaskHoursChange.bind(this)}
											/>
										) : (
											<InputFieldV2
												id={''}
												hideLabel={true}
												value={this.state.newSubTaskInputEstimate}
												onChange={text => this.setState({newSubTaskInputEstimate: text || ''})}
												type="number"
												onEnter={this.handleCreateSubtask.bind(this)}
												ref={e => (this._subtask_hours_input = e)}
												customClass={'task-modal-subtask-hours'}
												placeholder={formatMessage({id: 'common.estimate'})}
												cy="task-modal-add-subtask"
												maxLength={6}
											/>
										)}
									</div>
								) : null}

								<div className="subtask-add-button">
									<button
										disabled={this.state.newSubTaskInputName === '' || this.state.addingSubTask}
										className={
											this.state.newSubTaskInputName === '' || this.state.addingSubTask ? 'disabled' : ''
										}
										onClick={this.handleCreateSubtask.bind(this)}
									>
										+
									</button>
								</div>
							</div>
						)}
					</div>
				) : null}
			</div>
		);
	}
}

export default injectIntl(SubtaskSection);
