import React from 'react';
import {useIntl} from 'react-intl';
import {Table} from 'web-components';
import {getCustomFieldColumns} from '../../../shared/util/ReportServiceUtil';
import {hasFeatureFlag} from '../../../shared/util/FeatureUtil';

export const getBaseLevelTableHeader = (columns, customFieldDefinitions) => {
	const {formatMessage} = useIntl();
	const customFields = getCustomFieldColumns(customFieldDefinitions, columns);

	const hasFinancialCategoriesUpdate = hasFeatureFlag('financial_categories_update');

	return (
		<Table.Header>
			<Table.HeaderColumn usePadding flex={1} align="left" visible={true} width="LARGE">
				<Table.HeaderColumn.Title>&nbsp;</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns['task.indicators']} width="MEDIUM">
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.indicators'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn usePadding align="left" visible={columns['task.project.info']} width="LARGE">
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.project'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns['task.project.deadline']} width="MEDIUM">
				<Table.HeaderColumn.Title>
					{formatMessage({id: 'insights.component.list.column.projectDeadline'})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns['task.project.stage']} width="MEDIUM">
				<Table.HeaderColumn.Title>
					{formatMessage({id: 'insights.component.list.column.projectStage'})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns['task.project.rateCard.name']} width="MEDIUM">
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.rate-card'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns['task.project.client.info']} width="SMALL">
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.client'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns['task.dates.start']} width="MEDIUM">
				<Table.HeaderColumn.Title>
					{formatMessage({id: 'common.task'})} {formatMessage({id: 'common.start_date'})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns['task.dates.deadline']} width="MEDIUM">
				<Table.HeaderColumn.Title>
					{formatMessage({id: 'common.task'})} {formatMessage({id: 'common.end_date'})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns['task.dates.createdAt']} width="MEDIUM">
				<Table.HeaderColumn.Title>
					{formatMessage({id: 'common.task'})} {formatMessage({id: 'insights.component.list.column.createdAt'})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns['task.role.info']} width="MEDIUM">
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.role'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns['task.assignees']} width="LARGE">
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.assignees'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns['task.status.name']} width="MEDIUM">
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.status'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns['task.phase.info']} width="MEDIUM">
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.phase'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns['task.phase.dates.start']} width="MEDIUM">
				<Table.HeaderColumn.Title>
					{formatMessage({id: 'common.phase'})} {formatMessage({id: 'common.start_date'})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns['task.phase.dates.deadline']} width="MEDIUM">
				<Table.HeaderColumn.Title>
					{formatMessage({id: 'common.phase'})} {formatMessage({id: 'common.end_date'})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns['task.sprint.info']} width="MEDIUM">
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.sprint'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns['task.sprint.dates.start']} width="MEDIUM">
				<Table.HeaderColumn.Title>
					{formatMessage({id: 'common.sprint'})} {formatMessage({id: 'common.start_date'})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns['task.sprint.dates.deadline']} width="MEDIUM">
				<Table.HeaderColumn.Title>
					{formatMessage({id: 'common.sprint'})} {formatMessage({id: 'common.end_date'})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="right" usePadding visible={columns['task.progress']} width="SMALL">
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.progress'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn usePadding align="right" visible={columns['task.work.estimate.minutes']} width="MEDIUM">
				<Table.HeaderColumn.Title>
					{formatMessage({id: 'common.estimate'})}
					<br />
					{formatMessage({id: 'common.hours'})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn usePadding align="right" visible={columns['task.work.estimate.points']} width="MEDIUM">
				<Table.HeaderColumn.Title>
					{formatMessage({id: 'common.estimate'})}
					<br />
					{formatMessage({id: 'common.points'})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn usePadding align="right" visible={columns['task.reported.projected']} width="MEDIUM">
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.projected'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn usePadding align="right" visible={columns['task.reported.time']} width="MEDIUM">
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.reported'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="right" usePadding visible={columns['task.work.remaining.minutes']} width="MEDIUM">
				<Table.HeaderColumn.Title>
					{formatMessage({id: 'common.remaining'})}
					<br />
					{formatMessage({id: 'common.hours'})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="right" usePadding visible={columns['task.work.remaining.points']} width="MEDIUM">
				<Table.HeaderColumn.Title>
					{formatMessage({id: 'common.remaining'})}
					<br />
					{formatMessage({id: 'common.points'})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="right" usePadding visible={columns['task.work.difference.minutes']} width="MEDIUM">
				<Table.HeaderColumn.Title>
					{formatMessage({id: 'common.difference'})}
					<br />
					{formatMessage({id: 'common.hours'})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="right" usePadding visible={columns['task.work.difference.points']} width="MEDIUM">
				<Table.HeaderColumn.Title>
					{formatMessage({id: 'common.difference'})}
					<br />
					{formatMessage({id: 'common.points'})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="right" usePadding visible={columns['task.financial.revenue.planned']} width="MEDIUM">
				<Table.HeaderColumn.Title>
					{formatMessage({
						id: hasFinancialCategoriesUpdate
							? 'common.estimated_billable_value_of_service'
							: 'project_budget.planned_revenue',
					})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="right" usePadding visible={columns['task.financial.revenue.actual']} width="MEDIUM">
				<Table.HeaderColumn.Title>
					{formatMessage({
						id: hasFinancialCategoriesUpdate
							? 'common.actual_billable_value_of_service'
							: 'project_budget.actual_billable_time',
					})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="center" usePadding visible={columns['task.approved']} width="SMALL">
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.approved'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns['task.labels']} width="EXTRA_LARGE">
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.labels'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			{customFields.map(customField => (
				<Table.HeaderColumn align="center" usePadding visible={customField.visible} width="MEDIUM">
					<Table.HeaderColumn.Title>{customField.displayName}</Table.HeaderColumn.Title>
				</Table.HeaderColumn>
			))}
		</Table.Header>
	);
};
