import React from 'react';
import { useIntl } from 'react-intl';
import Util from '../../../../shared/util/util.js';
import styled, { css } from 'styled-components';
import { isBillableSplitAllowed } from '../../../../shared/util/cache/TimeRegistrationSettingsUtil';
export var ALIGN;
(function (ALIGN) {
    ALIGN["START"] = "flex-start";
    ALIGN["END"] = "flex-end";
})(ALIGN || (ALIGN = {}));
const TimesheetTotalsStyle = styled.div `
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-grow: 1;
	justify-content: ${({ align }) => align || ALIGN.START};
`;
const TimesheetTotalsColumn = styled.div `
	display: flex;
	flex-direction: column;
	gap: 4px;
	justify-content: center;
`;
const TimesheetTotalsText = styled.span `
	display: inline-block;
	width: fit-content;
	line-height: 14px;
	text-align: left;
	white-space: nowrap;
	cursor: pointer;

	align-self: ${({ align }) => align || ALIGN.START};

	${({ light }) => light &&
    css `
			color: #727483;
		`}

	&:hover {
		color: #650ed8;
	}
`;
function TimesheetsTimeTableTotals({ children, align }) {
    return React.createElement(TimesheetTotalsStyle, { align: align }, children);
}
function TimeSheetsTimeTableTotalsLabels({ goToTimesheetsTab, align }) {
    return (React.createElement(TimesheetTotalsColumn, { onClick: goToTimesheetsTab },
        React.createElement(TimesheetTotalsText, { align: align }, "Timesheet Total:"),
        isBillableSplitAllowed() && (React.createElement(TimesheetTotalsText, { align: align, light: true },
            "Billable Total:",
            ' '))));
}
function TimesheetsTimeTableTotalsHours({ minutesRegistered, workPeriodInMinutes, billableMinutesRegistered, goToTimesheetsTab, align, }) {
    const intl = useIntl();
    const registeredTimeInHours = Util.convertMinutesToFullHour(minutesRegistered, intl, true);
    const workPeriodInHours = Util.convertMinutesToFullHour(workPeriodInMinutes, intl, true);
    const billableRegisteredTimeInHours = Util.convertMinutesToFullHour(billableMinutesRegistered, intl, true);
    return (React.createElement(TimesheetTotalsColumn, { onClick: goToTimesheetsTab },
        React.createElement(TimesheetTotalsText, { align: align, "data-cy": 'timesheets-total-for-view' },
            registeredTimeInHours,
            "/",
            workPeriodInHours),
        isBillableSplitAllowed() && (React.createElement(TimesheetTotalsText, { align: align, light: true }, billableRegisteredTimeInHours))));
}
function TimesheetsTimeTableTotalsCombined({ minutesRegistered, workPeriodInMinutes, billableMinutesRegistered, goToTimesheetsTab, }) {
    return (React.createElement(React.Fragment, null,
        React.createElement(TimeSheetsTimeTableTotalsLabels, { goToTimesheetsTab: goToTimesheetsTab }),
        React.createElement(TimesheetsTimeTableTotalsHours, { minutesRegistered: minutesRegistered, workPeriodInMinutes: workPeriodInMinutes, billableMinutesRegistered: billableMinutesRegistered, goToTimesheetsTab: goToTimesheetsTab })));
}
TimesheetsTimeTableTotals.Labels = TimeSheetsTimeTableTotalsLabels;
TimesheetsTimeTableTotals.Hours = TimesheetsTimeTableTotalsHours;
TimesheetsTimeTableTotals.Combined = TimesheetsTimeTableTotalsCombined;
export default TimesheetsTimeTableTotals;
