import React from 'react';
import Tippy from '@tippyjs/react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';
import {followCursor} from 'tippy.js';
import {CSS_CONSTANTS} from '../../../../css_variables';

export const TooltipColor = {
	PURPLE: 'purple',
	WHITE: 'white',
	DEFAULT: 'default',
};

const ForecastTippy = styled(Tippy)`
	&.tippy-box {
		color: #535353;
		font-weight: 500;
		box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05);
		font-size: 11px;

		&[data-theme~='light-border'] {
			&[data-theme~=${TooltipColor.DEFAULT}] {
				background-color: #ebebeb;
				border-color: #e6e6e6;

				&[data-placement^='bottom'] > .tippy-arrow:before {
					border-bottom-color: #ebebeb;
				}
				&[data-placement^='bottom'] > .tippy-arrow:after {
					border-bottom-color: #e6e6e6;
				}
				&[data-placement^='top'] > .tippy-arrow:before {
					border-top-color: #ebebeb;
				}
				&[data-placement^='top'] > .tippy-arrow:after {
					border-top-color: #e6e6e6;
				}
				&[data-placement^='right'] > .tippy-arrow:before {
					border-right-color: #ebebeb;
				}
				&[data-placement^='right'] > .tippy-arrow:after {
					border-right-color: #e6e6e6;
				}
				&[data-placement^='left'] > .tippy-arrow:before {
					border-left-color: #ebebeb;
				}
				&[data-placement^='left'] > .tippy-arrow:after {
					border-left-color: #e6e6e6;
				}
			}

			&[data-theme~=${TooltipColor.PURPLE}] {
				background-color: #fff;
				border-color: ${CSS_CONSTANTS.v2_branding_purple};

				&[data-placement^='bottom'] > .tippy-arrow:before {
					border-bottom-color: #fff;
				}
				&[data-placement^='bottom'] > .tippy-arrow:after {
					border-bottom-color: ${CSS_CONSTANTS.v2_branding_purple};
					left: 0.99px;
				}
				&[data-placement^='top'] > .tippy-arrow:before {
					border-top-color: #fff;
				}
				&[data-placement^='top'] > .tippy-arrow:after {
					border-top-color: ${CSS_CONSTANTS.v2_branding_purple};
					left: 0.99px;
				}
				&[data-placement^='right'] > .tippy-arrow:before {
					border-right-color: #f8f5fe;
				}
				&[data-placement^='right'] > .tippy-arrow:after {
					border-right-color: ${CSS_CONSTANTS.v2_branding_purple};
					left: 0.99px;
				}
				&[data-placement^='left'] > .tippy-arrow:before {
					border-left-color: #fff;
				}
				&[data-placement^='left'] > .tippy-arrow:after {
					border-left-color: ${CSS_CONSTANTS.v2_branding_purple};
					left: 0.99px;
				}
			}

			&[data-theme~=${TooltipColor.WHITE}] {
				background-color: #fff;
				border-color: #727483;
				box-shadow: 0px 4px 16px 0px #0000001f;

				&[data-placement^='bottom'] > .tippy-arrow:before {
					border-bottom-color: #fff;
				}
				&[data-placement^='bottom'] > .tippy-arrow:after {
					border-bottom-color: ${CSS_CONSTANTS.v2_branding_purple};
					left: 0.99px;
				}
				&[data-placement^='top'] > .tippy-arrow:before {
					border-top-color: #fff;
				}
				&[data-placement^='top'] > .tippy-arrow:after {
					border-top-color: ${CSS_CONSTANTS.v2_branding_purple};
					left: 0.99px;
				}
				&[data-placement^='right'] > .tippy-arrow:before {
					border-right-color: #f8f5fe;
				}
				&[data-placement^='right'] > .tippy-arrow:after {
					border-right-color: ${CSS_CONSTANTS.v2_branding_purple};
					left: 0.99px;
				}
				&[data-placement^='left'] > .tippy-arrow:before {
					border-left-color: #fff;
				}
				&[data-placement^='left'] > .tippy-arrow:after {
					border-left-color: ${CSS_CONSTANTS.v2_branding_purple};
					left: 0.99px;
				}
			}
		}
	}
	.bold-font-weight {
		font-weight: bold;
	}
`;

// See https://atomiks.github.io/tippyjs/v6/all-props/#touch for valid Tippy props
const ForecastTooltip = ({children, spanStyle = {}, color = 'default', ...props}) => {
	return (
		<ForecastTippy
			{...props}
			interactive={props.interactive}
			content={props.content && <span>{props.content}</span>}
			maxWidth={props.maxWidth || 250}
			placement={props.placement || 'bottom'}
			delay={props.delay ?? [500, 200]}
			disabled={!props.content || props.disabled}
			theme={`light-border ${color}`}
			plugins={[followCursor]}
		>
			<span style={spanStyle}>{children}</span>
		</ForecastTippy>
	);
};

ForecastTooltip.propTypes = {
	delay: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
	placement: PropTypes.string,
	disabled: PropTypes.bool,
	maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	spanStyle: PropTypes.object,
	color: PropTypes.oneOf(Object.values(TooltipColor)),
	interactive: PropTypes.bool,
};

export default ForecastTooltip;
