import moment from 'moment';

export const TIME_PERIOD = {
	DAY_VIEW: 'day',
	WEEK_VIEW: 'week',
	MONTH_VIEW: 'month',
};

export const getStartAndEndDates = date => {
	let dates = {};
	dates.start = date.clone().startOf('month').startOf('week').format('YYYY-MM-DD');
	dates.end = date.clone().endOf('month').endOf('week').format('YYYY-MM-DD');
	return dates;
};

const handleDatesViewed = (startDate, endDate, personId, refetch, callback) => {
	refetch(
		{
			personId: personId,
			startDateString: startDate,
			endDateString: endDate,
		},
		null,
		callback
	);
};

export const handleTodayButtonClick = (personId, refetch, callback) => {
	const dates = getStartAndEndDates(moment());
	handleDatesViewed(dates.start, dates.end, personId, refetch, callback);
	return moment();
};

//val can be 0 if we select, from the calendar, a day oustide of the month selected
export const handleAdjacentPeriodClick = (currentViewingDate, forward, period, personId, refetch, callback, val = 1) => {
	const newDate = forward ? currentViewingDate.clone().add(val, period) : currentViewingDate.clone().subtract(val, period);
	if (refetch) {
		const dates = getStartAndEndDates(newDate);
		handleDatesViewed(dates.start, dates.end, personId, refetch, callback);
	}
	return newDate;
};

export const handleDateRangeClick = (date, timePeriod, personId, refetch, callback) => {
	const dates = getStartAndEndDates(date);
	if (refetch) {
		handleDatesViewed(dates.start, dates.end, personId, refetch, callback);
	}
	return date;
};
