import React, {useState} from 'react';
import {injectIntl} from 'react-intl';
import {BasicNumberInput} from 'web-components';
import styled from 'styled-components';

import {currencies} from '../../../constants';
import Dropdown from '../../shared/components/dropdowns/dropdown';
import {CSS_CONSTANTS} from '../../../css_variables';
import Util from '../../shared/util/util';
import CreateExchangeRateMutation from '../../../mutations/create_exchange_rate_mutation';
import AddButton from '../../shared/components/add_button';
import {createToast} from '../../shared/components/toasts/another-toast/toaster';

const NewCurrencyStyle = styled.div`
	margin-top: 10px;
	padding-left: 16px;
	display: flex;
	align-items: center;

	.element {
		margin-right: 8px;
		color: ${CSS_CONSTANTS.v2_text_gray};
		font-size: 13px;

		.add-button-component {
			width: 30px;
			height: 30px;
		}
	}
`;

export const NewCurrency = injectIntl(({intl: {formatMessage}, companyId, companyCurrency, exchangeRates}) => {
	const [currency, setCurrency] = useState(null);
	const [rate, setRate] = useState(null);

	const exchangeRateCurrencies = exchangeRates.map(el => el.currency);
	const filteredCurrencies = currencies.filter(
		currency => currency.value !== companyCurrency && !exchangeRateCurrencies.includes(currency.value)
	);

	const createExchangeRate = () => {
		if (currency !== null && rate !== null && rate !== '' && rate > 0) {
			const onSuccess = () => {
				setRate(null);
				setCurrency(null);
				createToast({
					duration: 5000,
					message: formatMessage({id: 'exchange_rate.has_been_deleted'}),
				});
			};

			Util.CommitMutation(
				CreateExchangeRateMutation,
				{
					companyId,
					currency,
					rate: +rate,
				},
				onSuccess,
				true
			);
		}
	};

	return (
		<NewCurrencyStyle>
			<div className="element" data-userpilot={'new-currency'}>
				<Dropdown
					onChange={option => setCurrency(option.value)}
					value={currency}
					options={filteredCurrencies}
					hideLabel={true}
					customHeight={30}
					placeholder={formatMessage({id: 'settings_details.currency_section'})}
				/>
			</div>
			<div className="element">{companyCurrency} 1.00 =</div>
			<div className="element">
				<BasicNumberInput
					value={rate}
					callback={setRate}
					width="122px"
					placeholder={formatMessage({id: 'common.exchange_rate'})}
				/>
			</div>
			<div className="element">
				<AddButton disabled={!currency} onClick={createExchangeRate} />
			</div>
		</NewCurrencyStyle>
	);
});
