const useSort = () => {
	const _getValue = (item, pathToValue) => {
		const path = pathToValue ? pathToValue.split('.') : [];
		let dir = item;
		while (path.length > 0) {
			const next = path.shift();
			dir = dir ? dir[next] : dir;
		}
		return dir || '';
	};

	const sort = (sortValue, item1, item2) => {
		const value1 = _getValue(item1, sortValue.key);
		const value2 = _getValue(item2, sortValue.key);

		if (value1 == null) return 1;
		else if (value2 == null) return -1;
		else {
			return (
				sortValue.key &&
				sortValue.direction *
					`${value1}`?.localeCompare(`${value2}`, undefined, {
						sensitivity: 'base',
						numeric: true,
					})
			);
		}
	};

	const sortGroupings = (g1, g2) => {
		if (!g1 || g1 === 'undefined' || g1 === 'false') return 1;
		else if (!g2 || g2 === 'undefined' || g2 === 'false') return -1;
		else return `${g1}`.localeCompare(`${g2}`);
	};

	/**
	 * Updating the way the table is sorted based on the header that you click, here called key
	 * @param key the key to sort according to (prefix, name, date, etc.)
	 * @param sortValue the current sortValue, expected format is {key, direction}
	 * @param setSortValue callback function to update sortValue
	 */
	const updateSort = (key, sortValue, setSortValue) => {
		if (key === sortValue.key) {
			if (sortValue.direction === 1) {
				setSortValue({key: key, direction: -1});
			} else {
				setSortValue({key: '', direction: 0});
			}
		} else {
			setSortValue({key: key, direction: 1});
		}
	};

	const isSortedColumn = (key, sortValue) => sortValue.key === key;
	const getDirection = sortValue => (sortValue.direction === 1 ? 'asc' : 'desc');

	return {sort, sortGroupings, updateSort, isSortedColumn, getDirection};
};

export default useSort;
