import React from 'react';
import { Button } from '@forecast-it/design-system';
import { CommitMutation } from '../../../../../mutations/ts/CommitMutation';
import OptIntoFeatureFlagMutation from '../../../../../mutations/ts/OptIntoFeatureFlagMutation';
const optIntoFeatureFlag = (featureFlag, onSuccess) => {
    CommitMutation(OptIntoFeatureFlagMutation, { featureFlag: featureFlag }, onSuccess);
};
const OptIntoFeatureFlagButton = ({ featureFlag, onSuccess }) => {
    return (React.createElement(Button, { emphasis: 'low', onClick: () => optIntoFeatureFlag(featureFlag, onSuccess) }, "Try the new look"));
};
export default OptIntoFeatureFlagButton;
