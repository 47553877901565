import styled from 'styled-components';

export const IconAndDescriptionWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	img {
		height: 100px;
		width: 100px;
	}
`;

export const ButtonsWrapper = styled.div`
	margin-top: 24px;
	display: flex;
	gap: 24px;
`;
export const DescriptionWrapper = styled.div`
	padding-left: 40px;
	white-space: break-spaces;
`;
