import Styled from 'styled-components/macro';

export const TaskCountWrapper = Styled.div`
	display:flex;
	flex-direction:row;
	align-items:center;
`;

export const CounterText = Styled.div`
	font-size: 13px;
	font-weight: bold;
	color: #535353;
	white-space: nowrap;
	margin: 0 0 0 8px;

`;
export const Counter = Styled.div`
	font-size: 13px;
	padding: 0 4px;
	display:flex;
	align-items: center;
	justify-content: center;
	height: 20px;
	min-width: 20px;
	border-radius: 2px;
	background-color: #6e0fea;
	text-align:center;
	color: #fff;
`;
export const Item = Styled.div`
	opacity: ${props => (props.disabled ? '0.5' : '1')};
	cursor: ${props => (props.isTitle || props.disabled ? 'default' : 'pointer')};
	font-size: 13px;
	color: ${props => (props.isTitle ? '#6e0fea' : '#535353')};
	padding-left: ${props => (props.indent ? props.indent * 16 + 'px' : 0)};
	padding-top: 6px;
	padding-bottom: 6px;
	padding-right: 50px;
	white-space: nowrap;
	&:hover {
		background-color: ${props => !props.isTitle && 'rgba(110, 15, 234, 0.1)'}
	}
`;

export const Dropdown = Styled.div`
	display: flex;
	flex-direction: column;
	position: absolute;
	bottom: 40px;
	right: 0;
	border-radius: 4px;
	box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05);
	border: solid 1px #e6e6e6;
	background-color: #ffffff;
`;

export const ButtonGroup = Styled.div`
	margin-left: 6px;
	height: 30px;
	.custom-button-container {
		height: 30px;
	}
`;

export const ListSection = Styled.div`
	border-bottom: 1px solid #e6e6e6;
`;

export const Info = Styled.div`
	display: flex;
	height: 100%;
	width: 200px;
	align-items:center;
	> svg {
		cursor: pointer;
			g {
				stroke: #a1a1a1;
			}
		}
	span.counter-spacing {
		margin-left: 16px;
		
	}
`;

export const Popup = Styled.div`
	padding: 0 24px;
	width: 100%;
	height: 100%;
	border: solid 1px #dbdbdb;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	justify-content: space-between;
	display: flex;
	align-items: center;
	background-color: #fff;

`;
export const Container = Styled.div`
	z-index: 100;
	position: fixed;
	height: 60px;
	bottom: 70px;
	left: 50%;
	transform: translateX(-50%)
`;
