/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type TaskRollupSection_viewer$ref: FragmentReference;
declare export opaque type TaskRollupSection_viewer$fragmentType: TaskRollupSection_viewer$ref;
export type TaskRollupSection_viewer = {|
  +id: string,
  +task: ?{|
    +id: string,
    +rollupEstimate: ?number,
    +rollupTimeEntries: ?number,
    +rollupRemaining: ?number,
    +project: ?{|
      +manualProgressOnProjectEnabled: ?boolean,
      +manualProgressOnPhasesEnabled: ?boolean,
      +manualProgressOnTasksEnabled: ?boolean,
    |},
  |},
  +$refType: TaskRollupSection_viewer$ref,
|};
export type TaskRollupSection_viewer$data = TaskRollupSection_viewer;
export type TaskRollupSection_viewer$key = {
  +$data?: TaskRollupSection_viewer$data,
  +$fragmentRefs: TaskRollupSection_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "id",
      "type": "String"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaskRollupSection_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "companyTaskId",
          "variableName": "id"
        }
      ],
      "concreteType": "Task",
      "kind": "LinkedField",
      "name": "task",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rollupEstimate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rollupTimeEntries",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rollupRemaining",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectType",
          "kind": "LinkedField",
          "name": "project",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "manualProgressOnProjectEnabled",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "manualProgressOnPhasesEnabled",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "manualProgressOnTasksEnabled",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '1319e0aabdb01ce277014a0d8a5ed0bb';

module.exports = node;
