/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 10000
        }
    ], v2 = [
        (v0 /*: any*/)
    ], v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "id",
                "type": "ID!"
            }
        ],
        "kind": "Fragment",
        "metadata": null,
        "name": "AddProjectBillToModal_viewer",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Variable",
                        "name": "internalId",
                        "variableName": "id"
                    }
                ],
                "concreteType": "ProjectType",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": (v1 /*: any*/),
                        "concreteType": "ProjectBillToTypeConnection",
                        "kind": "LinkedField",
                        "name": "projectBillTos",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProjectBillToTypeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ProjectBillTo",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                            (v0 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "BillTo",
                                                "kind": "LinkedField",
                                                "name": "billTo",
                                                "plural": false,
                                                "selections": (v2 /*: any*/),
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "BillFrom",
                                                "kind": "LinkedField",
                                                "name": "billFrom",
                                                "plural": false,
                                                "selections": (v2 /*: any*/),
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": "projectBillTos(first:10000)"
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "Client",
                        "kind": "LinkedField",
                        "name": "client",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": (v1 /*: any*/),
                                "concreteType": "ClientBillToTypeConnection",
                                "kind": "LinkedField",
                                "name": "clientBillTos",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ClientBillToTypeEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "ClientBillTo",
                                                "kind": "LinkedField",
                                                "name": "node",
                                                "plural": false,
                                                "selections": [
                                                    (v0 /*: any*/),
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "clientId",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "BillTo",
                                                        "kind": "LinkedField",
                                                        "name": "billTo",
                                                        "plural": false,
                                                        "selections": [
                                                            (v0 /*: any*/),
                                                            (v3 /*: any*/),
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "kind": "ScalarField",
                                                                "name": "billFromId",
                                                                "storageKey": null
                                                            }
                                                        ],
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "BillFrom",
                                                        "kind": "LinkedField",
                                                        "name": "billFrom",
                                                        "plural": false,
                                                        "selections": [
                                                            (v0 /*: any*/),
                                                            (v3 /*: any*/),
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "kind": "ScalarField",
                                                                "name": "integration",
                                                                "storageKey": null
                                                            }
                                                        ],
                                                        "storageKey": null
                                                    }
                                                ],
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": "clientBillTos(first:10000)"
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "BillFrom",
                        "kind": "LinkedField",
                        "name": "billFroms",
                        "plural": true,
                        "selections": [
                            (v0 /*: any*/),
                            (v3 /*: any*/)
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Viewer"
    };
})();
node.hash = 'af7df8e506aa358b1e1ec966fc3accf0';
export default node;
