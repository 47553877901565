import React from 'react';
import Warning from '../../../../components/warning';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../../constants';
import GenericModal from '../../../../containers/modal/generic_modal';
import DirectApi from '../../../../directApi';
import {createToast} from '../../../shared/components/toasts/toast';

const DemoGenerationModal = ({closeModal, demoEndpoint}) => {
	const callbackPositive = () => {
		const headers = new Headers();
		headers.append('Content-Type', 'application/json');
		DirectApi.Fetch_Raw_With_Init(`generate_demo_data/${demoEndpoint}`, {
			method: 'POST',
			credentials: 'include',
			headers,
			body: JSON.stringify({}),
		});
		createToast({
			duration: 150000,
			message: `Restoring demo. Page will refresh when demo is fully generated.`,
		});
		setTimeout(() => {
			createToast({
				duration: 5000,
				message: `Demo restored.`,
			});
			window.location.reload();
		}, 150000);
	};

	const content = (
		<div className="default-warning-modal">
			<Warning messageId="settings_details.generate_demo_environment" />
			<div className="warning-part-2">Are you sure that you want to generate a demo environment?</div>
			<div className="warning-part-2">
				Generating a new environment will <b>delete everything in this account</b>
			</div>
		</div>
	);

	const buttons = [
		{
			text: 'cancel',
			style: BUTTON_STYLE.FILLED,
			color: BUTTON_COLOR.WHITE,
		},
		{
			text: 'generate',
			style: BUTTON_STYLE.FILLED,
			color: BUTTON_COLOR.GREEN,
			callback: callbackPositive,
		},
	];

	return <GenericModal buttons={buttons} closeModal={closeModal} content={content} />;
};

export default DemoGenerationModal;
