/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type DeprecatedProjectIndicatorJS_project$ref = any;
export type PROJECT_STATUS = "DONE" | "HALTED" | "OPPORTUNITY" | "PLANNING" | "RUNNING" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectDropdown_projects$ref: FragmentReference;
declare export opaque type ProjectDropdown_projects$fragmentType: ProjectDropdown_projects$ref;
export type ProjectDropdown_projects = $ReadOnlyArray<{|
  +node: ?{|
    +id: string,
    +name: ?string,
    +projectColor: ?string,
    +companyProjectId: ?number,
    +customProjectId: ?string,
    +status: ?PROJECT_STATUS,
    +manualProgressOnProjectEnabled: ?boolean,
    +manualProgressOnPhasesEnabled: ?boolean,
    +manualProgressOnTasksEnabled: ?boolean,
    +$fragmentRefs: DeprecatedProjectIndicatorJS_project$ref,
  |},
  +$refType: ProjectDropdown_projects$ref,
|}>;
export type ProjectDropdown_projects$data = ProjectDropdown_projects;
export type ProjectDropdown_projects$key = $ReadOnlyArray<{
  +$data?: ProjectDropdown_projects$data,
  +$fragmentRefs: ProjectDropdown_projects$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ProjectDropdown_projects",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectType",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectColor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "companyProjectId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customProjectId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "manualProgressOnProjectEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "manualProgressOnPhasesEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "manualProgressOnTasksEnabled",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DeprecatedProjectIndicatorJS_project"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProjectTypeEdge"
};
// prettier-ignore
(node/*: any*/).hash = '12064ebb35c2db1980b32c553bcf0a51';

module.exports = node;
