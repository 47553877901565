import UpdateTaskMutation from '../../../../mutations/update_task_mutation.modern';
import Util from '../../../shared/util/util';

export const theEyeOptions = [
	{
		name: 'taskType',
		checked: true,
		translationId: 'project_board.card_details_selector_task_type',
		nestedOptions: [
			{
				name: 'bug',
				checked: true,
				translationId: 'common.bug',
				nestedOptions: null,
			},
			{
				name: 'dependencies',
				checked: true,
				translationId: 'common.dependencies',
				nestedOptions: null,
			},
			{
				name: 'highPriority',
				checked: true,
				translationId: 'common.high_priority',
				nestedOptions: null,
			},
		],
	},
	{
		name: 'taskId',
		checked: true,
		translationId: 'project_board.card_details_selector_task_id',
		nestedOptions: null,
	},
	{
		name: 'dates',
		checked: true,
		translationId: 'common.task_dates',
		nestedOptions: null,
	},
	{
		name: 'labels',
		checked: true,
		translationId: 'common.labels',
		nestedOptions: null,
	},
	{
		name: 'imagePreview',
		checked: true,
		translationId: 'project_board.card_details_selector_image_preview',
		nestedOptions: null,
	},
	{
		name: 'taskDetails',
		checked: true,
		translationId: 'project_board.card_details_selector_task_details',
		nestedOptions: [
			{
				name: 'comments',
				checked: true,
				translationId: 'cards.comments',
				nestedOptions: null,
			},
			{
				name: 'todosSubtasks',
				checked: true,
				translationId: 'project_board.card_details_todos_suntasks',
				nestedOptions: null,
			},
			{
				name: 'attachments',
				checked: true,
				translationId: 'project_board.card_details_attachments',
				nestedOptions: null,
			},
		],
	},
	{
		name: 'assigned',
		checked: true,
		translationId: 'project_board.card_details_assigned',
		nestedOptions: null,
	},
	{
		name: 'progress',
		checked: true,
		translationId: 'project_board.card_details_progress_bar',
		nestedOptions: null,
	},
	{
		name: 'clientName',
		checked: false,
		translationId: 'common.client',
		nestedOptions: null,
	},
];

export const getTaskFromTaskStatusId = (taskStatusId, taskMap, isConnectedParent) => {
	const res = taskStatusId.split('-');
	const statusColumnId = isConnectedParent ? parseInt(res[1]) : res[1];
	return taskMap.get(statusColumnId).find(task => task.id === res[0]);
};

export const getCardParentFromElement = element => {
	let count = 0;
	let maxTries = 10;
	let elementFound = null;
	while (element && element.parentNode && element.tagName !== 'body' && count <= maxTries) {
		//className is not a string when element is a polygon (used in a task modal which can be shown over workflow)
		//this probably should not keep being executed when the task modal is open at all though
		if (typeof element.className === 'string' && element.className.includes('board-card-outer')) {
			elementFound = element;
			break;
		}
		element = element.parentNode;
		count++;
	}
	return elementFound;
};

export const assignViewerOnSpacePress = (task, viewerId, isJiraProject) => {
	let personIds = [];
	if (task.assignedPersons.length > 0) {
		personIds = task.assignedPersons.map(person => {
			return person.id;
		});
	}
	if (personIds.includes(viewerId)) {
		personIds.splice(personIds.indexOf(viewerId), 1);
	} else {
		// If this is a jira project, replace assigned team member with viewer
		if (isJiraProject) {
			personIds = [viewerId];
		} else {
			personIds.push(viewerId);
		}
	}
	Util.CommitMutation(UpdateTaskMutation, {
		ids: [task.id],
		assignedPersons: personIds,
	});
};
