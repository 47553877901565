const useGrouping = () => {
	/**
	 * An iterative extracts a value / entity specified by a given path in an object
	 * @param item Obj
	 * @param pathToEntity string (expecting 'my.path.name'
	 * @returns {*} The entity at the given path.
	 * @private
	 */
	const _getEntity = (item, pathToEntity) => {
		const path = pathToEntity ? pathToEntity.split('.') : [];
		let dir = item;
		while (path.length > 0) {
			const next = path.shift();
			dir = dir ? dir[next] : dir;
		}
		return dir;
	};

	/**
	 * A group by function that allows you to pass extraction functions for the key, display value, and sort value
	 * The key is will be the object key, the display value the string value for the group, and the sort value the sort value for the group
	 * The idea is that you can display and sort by something different from the key.
	 * @param collection The collection you are grouping (tasks, projects, programs, etc)
	 * @param pathToEntity If the collection is a nested object (company.projects) you can specify a path
	 * @param entityKeyFn A function to extract the key
	 * @param entityDisplayFn An optional function to extract the display name
	 * @param entitySortFn An optional function to extract the sort order
	 * @returns {*} An object with {key: {display:string, sortOrder:string/int, elements:array}}
	 */
	const groupByToMap = (
		collection,
		pathToEntity,
		entityKeyFn = entity => entity,
		entityDisplayFn = entity => entity,
		entitySortFn = entity => entity
	) => {
		return collection.reduce((acc, cur) => {
			const entity = _getEntity(cur, pathToEntity);
			const groupKey = entityKeyFn(entity);
			const groupInfo = acc[groupKey] ?? {
				display: entityDisplayFn(entity),
				sortOrder: entitySortFn(entity),
				elements: [],
			};

			groupInfo.elements.push(cur);
			acc[groupKey] = groupInfo;

			return acc;
		}, {});
	};

	return {groupByToMap};
};

export default useGrouping;
