import { MODAL_TYPE, showModal } from '../../../../shared/components/modals/generic_modal_conductor';
import BulkChangeAccessForSavedReportMutation from '../../../../../mutations/bulk_change_access_for_saved_report_mutation';
import Util from '../../../../shared/util/util';
const changePrivateAccess = (successCallback, ids, privateAccess) => {
    Util.CommitMutation(BulkChangeAccessForSavedReportMutation, {
        ids: ids,
        privateAccess: privateAccess,
    }, successCallback);
};
export const bulkShareReports = (reports, successCallback) => {
    showModal({
        type: MODAL_TYPE.BULK_SHARE_REPORT_MODAL,
        reports: reports,
        changePrivateAccess: changePrivateAccess.bind(successCallback),
    });
};
