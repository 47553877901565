import Styled from 'styled-components';
import {CSS_CONSTANTS} from '../../../../../css_variables';
import {LARGE} from './SkillsSelections';

export const DropdownOption = Styled.div`
   display: flex;
   justify-content: space-between;
	 align-items: center;
`;

export const Selections = Styled.div`
	margin-top: ${props => (props.size === LARGE ? '0px' : '20px')};
`;

export const SelectionsHeader = Styled.div`
    display: flex;
    justify-content: space-between;   
    align-items: center   
    margin-bottom: 5px;   
`;

export const SelectionsHeaderActions = Styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    column-gap: 2px;
    
    .dropdown-container {
        height: 24px;
        width: 130px;
        border: 1px solid transparent;
        :hover, :focus, :active {
            border: 1px solid ${CSS_CONSTANTS.v2_button_very_light_grey};
        }
        input {
            padding: 0;
        }
        input, p {
            text-align: center;
        }
    }
    
    .search-V2 {
        height:  ${props => (props.size === LARGE ? '36px' : '24px')};
        min-width: ${props => (props.size === LARGE ? '38px' : '24px')};
        padding: ${props => (props.size === LARGE ? '0 30px 0 0' : '0 24px 0 0')};
        margin-right: 0;
        background-size: ${props => (props.size === LARGE ? '16px' : '14px')};
        background-position: ${props => (props.size === LARGE ? 'right 8px center' : 'right 5px center')}
        border: 1px solid transparent;
	    border-radius: 4px;
        
        :hover, &.expanded {
            border: 1px solid ${CSS_CONSTANTS.v2_button_very_light_grey};
        }
        
        input {
            padding: ${props => (props.size === LARGE ? '8px 8px 8px 8px' : '4px 0 0 6px')} ;
            font-size: 12px;            
            width: 100px;
        }
    }
`;
