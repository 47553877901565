import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {cloneDeep, keyBy} from 'lodash';
import {
	Column,
	DotOptions,
	DragHandle,
	ExpansionToggle,
	ExpansionWrapper,
	TaskRowDropdownStyle,
	TaskRowWrapper,
} from './Elements.styled';
import {useIntl} from 'react-intl';
import {AffixedInputWrapper} from '../../../inputs/AffixedInputWrapper';
import HoursInput from '../../../inputs/hours-input/hours_input_view';
import LabelGroup from '../../../labels/label_group';
import DatePicker from '../../../date-picker/date_picker_v3';
import DropdownV2 from '../../../dropdowns/dropdown';
import NumericTile from '../../../../../../components/new-ui/numeric_tile';
import {NumericTileV2, UNIT} from './task-row-elements/NumericTile';
import {UnfocusedDropdownTile} from './task-row-elements/UnfocusedDropdownTile';
import NumericTiles from '../../../../../../components/new-ui/task-table-v3/numeric-tiles/numeric_tiles_view';
import TextTile from '../../../../../../components/new-ui/text_tile';
import StarTask from '../../../star/star_task';
import {isDateDisabled} from '../../../../util/DateUtil';
import {DATE_PICKER_STYLE, ESTIMATION_UNIT} from '../../../../../../constants';
import {hasFeatureFlag} from '../../../../util/FeatureUtil';
import UpdateTaskMutation from '../../../../../../mutations/update_task_mutation.modern';
import AssignPersonToTaskMutation from '../../../../../../mutations/ts/AssignPersonToTaskMutation';
import UnassignPersonFromTaskMutation from '../../../../../../mutations/ts/UnassignPersonFromTaskMutation';
import Util from '../../../../util/util';
import * as tracking from '../../../../../../tracking';
import ProjectUtil from '../../../../util/project_util';
import {TaskIdColumn, TaskNameColumn} from './task-row-elements';
import TimeRegPopup from '../../../popups/time_reg_popup';
import {getColumnWidth} from './utils';
import {AssigneeGroup, Checkbox} from 'web-components';
import {isTaskInHierarchy} from '../table-util/TableUtil';
import {hasPermission, isClientUser} from '../../../../util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../../../Permissions';
import {MODAL_TYPE, showModal} from '../../../modals/generic_modal_conductor';
import {PredictionPopupInputExtension} from './task-row-elements/prediction-popup/PredictionPopupInputExtension';
import {showOverrunPredictionWarning} from '../../../../util/PredictionUtil';
import {RoleDropdown} from '../../../dropdowns/Role_dropdown';
import {PersonDropdown} from '../../../dropdowns/Person_dropdown';
import {VARIANT} from '../../../../../../containers/modal/project-automate/project_automate_modal';
import {TaskProgressRegistrationPopup} from '../../../popups/progress-registration/TaskProgressRegistrationPopup';
import {
	CUSTOM_FIELD_PREFIX,
	getCustomFieldColumnName,
} from '../../../../../project-tab/projects/scoping-page/ProjectScopingUtil';
import {CustomFieldColumn} from './header-row-elements/CustomFieldColumn';
import {trackEvent} from '../../../../../../tracking/amplitude/TrackingV2';
import {getNonBillableTime} from './../../../../util/time-registration/time-registration-settings/BillableTimeSplitUtil';
import {profilePicSrc, projectUrl} from '../../../../../../directApi';
import DeprecatedProjectIndicatorJS from '../../../project-indicator/js/DeprecatedProjectIndicatorJS';

const getTotalTimeEntries = (timeRegistrations, byPerson) => {
	let list = timeRegistrations.edges;
	const total = list.reduce((acc, elem) => acc + elem.node.minutesRegistered / 60, 0);
	return total;
};

const getTotalBillableTimeEntries = timeRegistrations => {
	const timeRegistrationEdges = timeRegistrations.edges;
	return timeRegistrationEdges.reduce((acc, timeReg) => acc + timeReg.node.billableMinutesRegistered / 60, 0);
};

const getTotalActualPrice = (timeRegistrations, byPerson) => {
	let list = timeRegistrations.edges;
	const total = list.reduce((acc, elem) => acc + elem.node.price, 0);
	return total;
};

const onEstimateUpdateSuccess = result => {
	if (result.updateTask.errors && result.updateTask.errors.length === 1) {
		Util.checkForSageErrorAndShowModal(result.updateTask.errors, false);
	}
};

const handleEstimationChanges = (key, val, task, onSuccess) => {
	if (isNaN(val)) return;
	const valRounded = Math.round(val); // Round to avoid edge cases caused by JS floating point representation

	const mutation = {
		ids: [task.id],
		forecast: valRounded,
	};
	if (task.estimateForecast === valRounded) return;

	const latestOverrunPredictionTask = localStorage.getItem('latest-overrun-prediction-shown');
	if (latestOverrunPredictionTask === task.id) {
		tracking.trackAIEvent('Recently seen overrun reestimated', VARIANT.ESTIMATES, null, {
			appliedEstimate: valRounded,
			predictedEstimate: task.predictedEstimate?.estimate,
			taskId: task.id,
			taskName: task.name,
		});
		trackEvent('Recently Inspected Predicted Overrun Task', 'Reestimated', {
			appliedEstimate: valRounded,
			predictedEstimate: task.predictedEstimate?.estimate,
			taskId: task.id,
			taskName: task.name,
		});
	}

	Util.CommitMutation(UpdateTaskMutation, mutation, onSuccess);
};

export const TaskRow = memo(
	({
		node: {
			task,
			taskProject,
			taskDepth,
			expanded,
			sharedOptions,
			selected,
			children,
			level,
			parent,
			rollUpValues: {
				rollupChildrenCount,
				rollupDoneChildrenCount,
				rollupProgress,
				rollupEstimate,
				rollupTimeRegistered,
				rollupBillableTimeRegistered,
				rollupRemaining,
				rollupDifferenceEstimate,
				rollupPrice,
				rollupActualPrice,
				rollupPlannedCost,
				rollupActualCost,
				rollupRoles,
				rollupAssignees,
				rollupStatus,
				rollupTotalPriceAtCompletion,
				rollupProjectedCost,
			},
			groupSetting,
			parentSelected,
			ancestors,
		},
		draggedNode,
		toggleExpansion,
	}) => {
		// Extracts the value to show in the table from the RollUpValue object which is created in the TableUtil component.
		rollupChildrenCount = rollupChildrenCount.getValue();
		rollupDoneChildrenCount = rollupDoneChildrenCount.getValue();
		rollupEstimate = rollupEstimate.getValue();
		rollupTimeRegistered = rollupTimeRegistered.getValue();
		rollupBillableTimeRegistered = rollupBillableTimeRegistered.getValue();
		rollupRemaining = rollupRemaining.getValue();
		rollupDifferenceEstimate = rollupDifferenceEstimate.getValue();
		rollupPrice = rollupPrice.getValue();
		rollupActualPrice = rollupActualPrice.getValue();
		rollupPlannedCost = rollupPlannedCost.getValue();
		rollupActualCost = rollupActualCost.getValue();
		rollupTotalPriceAtCompletion = rollupTotalPriceAtCompletion.getValue();
		rollupProjectedCost = rollupProjectedCost.getValue();

		const parentTaskWithNoEstimateAndTimeRegs =
			task.hasChildren && task.estimateForecast === 0 && hasFeatureFlag('Timeline_progress_for_parent_tasks');

		const columnsObject = keyBy(sharedOptions.availableColumns, 'name');
		const intl = useIntl();

		const isDragging = draggedNode !== null;
		const isDraggingParent = useMemo(() => isDragging && isTaskInHierarchy(task, draggedNode), [isDragging]);

		const lazyDataFetched = true;
		const disableActions = !lazyDataFetched || task.readOnly?.isReadOnly;

		const isClient = isClientUser();

		const hasChildren = children.length > 0;
		const childrenCollapsed = !expanded && hasChildren;

		const maxSubTaskDepth = Util.getMaxSubtaskDepth(!!(taskProject && taskProject.isJiraProject));

		const onContextMenu = useCallback(
			e => {
				if (sharedOptions.onContextMenu) {
					return sharedOptions.onContextMenu(task, taskProject, e);
				}
			},
			[task, taskProject]
		);

		return (
			<TaskRowWrapper
				className={isDragging && 'no-hover'}
				isDragging={isDragging}
				draggingThis={draggedNode && draggedNode.id === task.id}
				draggingParent={isDraggingParent}
				onContextMenu={e => onContextMenu(e)}
				data-cy="task-row"
			>
				<DragHandle></DragHandle>
				{getColumnWidth('selector', columnsObject) && (
					<SelectorColumn
						width={getColumnWidth('selector', columnsObject)}
						selected={selected}
						handleSelected={sharedOptions.handleRowSelected}
						task={task}
						disableActions={disableActions || parentSelected}
					/>
				)}
				{sharedOptions.useTaskHierarchy &&
					(hasChildren ? (
						<ExpansionToggle expanded={expanded} onClick={() => toggleExpansion(task)}></ExpansionToggle>
					) : (
						<ExpansionWrapper />
					))}
				{/* <DragHandle /> 14 px for width calculations */}
				{/* TASK ID */}
				{getColumnWidth('task-id', columnsObject) && (
					<TaskIdColumn
						width={getColumnWidth('task-id', columnsObject)}
						col={columnsObject['task-id']}
						task={task}
						showTaskModal={sharedOptions.showTaskModal}
					/>
				)}
				{/* TASK NAME */}
				{getColumnWidth('task-name', columnsObject) && (
					<TaskNameColumn
						width={getColumnWidth('task-name', columnsObject, level)}
						task={task}
						parent={parent}
						availableFeatureFlags={sharedOptions.availableFeatureFlags}
						showTaskModal={sharedOptions.showTaskModal}
						createSubtaskTaskExpanded={sharedOptions.createSubtaskTask === task.id}
						showCreateSubtask={sharedOptions.useTaskHierarchy && ancestors + 1 < maxSubTaskDepth && !disableActions}
						onCreateSubtask={sharedOptions.onCreateSubtask}
						rollupChildrenCount={children.length}
						rollupEstimate={rollupEstimate}
						rollupTimeRegistered={rollupTimeRegistered}
						expanded={expanded}
						intl={intl}
					/>
				)}
				{/* PROJECT ID */}
				{getColumnWidth('project-id', columnsObject) && (
					<TaskProjectIdColumn
						width={getColumnWidth('project-id', columnsObject)}
						task={task}
						taskProject={taskProject}
					></TaskProjectIdColumn>
				)}
				{/* PROJECT NAME */}
				{getColumnWidth('project-name', columnsObject) && (
					<TaskProjectNameColumn
						width={getColumnWidth('project-name', columnsObject)}
						taskProject={taskProject}
						disableActions={disableActions}
					></TaskProjectNameColumn>
				)}
				{/* TASK DATE SELECTOR*/}
				{getColumnWidth('date', columnsObject) && (
					<TaskDateColumn
						width={getColumnWidth('date', columnsObject)}
						task={task}
						disableActions={disableActions}
						isDragging={isDragging}
						align={columnsObject['date'].align}
					/>
				)}
				{/* CUSTOM FIELDS */}
				{sharedOptions.availableColumns
					.filter(
						column =>
							column.checked &&
							column.name?.startsWith(CUSTOM_FIELD_PREFIX) &&
							getColumnWidth(column.name, columnsObject)
					)
					.map(column => {
						const entityType = 'TASK';
						const customFieldValueDefinition = task.customFieldValues?.edges
							.map(edge => edge.node)
							.find(cfv => getCustomFieldColumnName(entityType, cfv.key) === column.name);
						return (
							<CustomFieldColumn
								key={task.id + ':' + column.name}
								width={getColumnWidth(column.name, columnsObject)}
								customFieldKey={column.customFieldKey}
								value={customFieldValueDefinition?.value}
								entityId={task.id}
								entityType={entityType}
								readOnly={column.entityType !== entityType || column.readOnly || disableActions}
								backgroundColor={'#ffffff'}
								showBox={true}
							/>
						);
					})}
				{/* TASK ROLES DROPDOWN */}
				{getColumnWidth('assigned-role', columnsObject) && (
					<TaskRoleColumnV2
						width={getColumnWidth('assigned-role', columnsObject)}
						task={task}
						isClientUser={isClient}
						disableActions={disableActions}
						roles={sharedOptions.roles}
						disabledRoles={sharedOptions.disabledRoles}
						isDragging={isDragging}
						childrenCollapsed={childrenCollapsed}
						rollupRoles={rollupRoles}
						intl={intl}
						lazyDataFetched={lazyDataFetched}
					/>
				)}
				{/* TASK ASSIGNEES DROPDOWN */}
				{getColumnWidth('assigned-person', columnsObject) && (
					<TaskAssigneeColumnV2
						width={getColumnWidth('assigned-person', columnsObject)}
						isClientUser={isClient}
						task={task}
						disableActions={disableActions}
						projectPersons={sharedOptions.projectPersons}
						isDragging={isDragging}
						rollupAssignees={rollupAssignees}
						childrenCollapsed={childrenCollapsed}
						intl={intl}
						lazyDataFetched={lazyDataFetched}
					/>
				)}
				{/* STATUS DROPDOWN */}
				{getColumnWidth('status', columnsObject) && (
					<TaskStatusColumn
						width={getColumnWidth('status', columnsObject)}
						lazyDataFetched={lazyDataFetched}
						isClientUser={isClient}
						task={task}
						taskProject={taskProject}
						disableActions={disableActions}
						projectPersons={sharedOptions.projectPersons}
						intl={intl}
						childrenCollapsed={childrenCollapsed}
						rollupStatus={rollupStatus}
						children={children}
					></TaskStatusColumn>
				)}
				{/* PHASE DROPDOWN */}
				{getColumnWidth('phase', columnsObject) && (
					<TaskPhaseColumn
						width={getColumnWidth('phase', columnsObject)}
						lazyDataFetched={lazyDataFetched && !task.parentTaskId}
						task={task}
						disableActions={disableActions}
						sharedOptions={sharedOptions}
						intl={intl}
					/>
				)}
				{/* SPRINT DROPDOWN */}
				{getColumnWidth('sprint', columnsObject) && (
					<TaskSprintColumn
						width={getColumnWidth('sprint', columnsObject)}
						lazyDataFetched={lazyDataFetched}
						task={task}
						disableActions={disableActions}
						sharedOptions={sharedOptions}
						intl={intl}
					/>
				)}
				{/* PROGRESS */}
				{getColumnWidth('done-percentage', columnsObject) && (
					<TaskProgressColumn
						width={getColumnWidth('done-percentage', columnsObject)}
						task={task}
						taskProject={taskProject}
						taskDone={childrenCollapsed ? rollupChildrenCount === rollupDoneChildrenCount && task.done : task.done}
						rollupProgress={rollupProgress}
						childrenCollapsed={childrenCollapsed}
						intl={intl}
						parentTaskWithNoEstimateAndTimeRegs={parentTaskWithNoEstimateAndTimeRegs}
					/>
				)}
				{/* PERIOD TARGET */}
				{getColumnWidth('period-target', columnsObject) && (
					<Column width={getColumnWidth('period-target', columnsObject)} />
				)}
				{/* ESTIMATE */}
				{getColumnWidth('forecast', columnsObject) && (
					<TaskEstimateColumn
						width={getColumnWidth('forecast', columnsObject)}
						lazyDataFetched={lazyDataFetched}
						task={task}
						taskProject={taskProject}
						childrenCollapsed={childrenCollapsed}
						sharedOptions={sharedOptions}
						rollUpEstimate={rollupEstimate}
						intl={intl}
						groupSetting={groupSetting}
						disableActions={disableActions}
						parentTaskWithNoEstimateAndTimeRegs={parentTaskWithNoEstimateAndTimeRegs}
					></TaskEstimateColumn>
				)}
				{/* TIME ENTRIES */}
				{getColumnWidth('time-entries', columnsObject) && (
					<TaskTimeRegistrationsColumn
						width={getColumnWidth('time-entries', columnsObject)}
						lazyDataFetched={lazyDataFetched}
						task={task}
						taskProject={taskProject}
						childrenCollapsed={childrenCollapsed}
						rollupTimeRegistered={rollupTimeRegistered}
						sharedOptions={sharedOptions}
						disableActions={disableActions}
					></TaskTimeRegistrationsColumn>
				)}
				{/* BILLABLE TIME ENTRIES */}
				{getColumnWidth('billable-time-entries', columnsObject) && (
					<TaskBillableTimeRegistrationsColumn
						width={getColumnWidth('billable-time-entries', columnsObject)}
						lazyDataFetched={lazyDataFetched}
						task={task}
						taskProject={taskProject}
						childrenCollapsed={childrenCollapsed}
						rollupBillableTimeRegistered={rollupBillableTimeRegistered}
						sharedOptions={sharedOptions}
						disableActions={disableActions}
					></TaskBillableTimeRegistrationsColumn>
				)}
				{/* NON-BILLABLE TIME ENTRIES */}
				{getColumnWidth('non-billable-time-entries', columnsObject) && (
					<TaskNonBillableTimeRegistrationsColumn
						width={getColumnWidth('non-billable-time-entries', columnsObject)}
						lazyDataFetched={lazyDataFetched}
						task={task}
						taskProject={taskProject}
						childrenCollapsed={childrenCollapsed}
						rollupTimeRegistered={rollupTimeRegistered}
						rollupBillableTimeRegistered={rollupBillableTimeRegistered}
						sharedOptions={sharedOptions}
						disableActions={disableActions}
					></TaskNonBillableTimeRegistrationsColumn>
				)}
				{/* REMAINING */}
				{getColumnWidth('remaining', columnsObject) && (
					<TaskRemainingColumn
						width={getColumnWidth('remaining', columnsObject)}
						lazyDataFetched={lazyDataFetched}
						task={task}
						taskProject={taskProject}
						childrenCollapsed={childrenCollapsed}
						rollupRemaining={rollupRemaining}
						sharedOptions={sharedOptions}
						intl={intl}
						groupSetting={groupSetting}
					></TaskRemainingColumn>
				)}
				{/* DIFFERENCE FORECAST */}
				{getColumnWidth('over-forecast', columnsObject) && (
					<TaskOverEstimateColumn
						width={getColumnWidth('over-forecast', columnsObject)}
						lazyDataFetched={lazyDataFetched}
						task={task}
						sharedOptions={sharedOptions}
						childrenCollapsed={childrenCollapsed}
						rollupDifferenceEstimate={rollupDifferenceEstimate}
						groupSetting={groupSetting}
					></TaskOverEstimateColumn>
				)}
				{!hasFeatureFlag('financial_categories_update')
					? /* Planned Revenue */
					  getColumnWidth('price', columnsObject) && (
							<TaskPlanRevenueColumn
								width={getColumnWidth('price', columnsObject)}
								lazyDataFetched={lazyDataFetched}
								task={task}
								sharedOptions={sharedOptions}
								childrenCollapsed={childrenCollapsed}
								rollupPrice={rollupPrice}
								formatMessage={intl.formatMessage}
								formatNumber={intl.formatNumber}
							></TaskPlanRevenueColumn>
					  )
					: /* Projected Billable Value of Service */
					  getColumnWidth('projected-billable-value-of-service', columnsObject) && (
							<TaskProjectedBillableValueOfServiceColumn
								width={getColumnWidth('projected-billable-value-of-service', columnsObject)}
								lazyDataFetched={lazyDataFetched}
								task={task}
								sharedOptions={sharedOptions}
								childrenCollapsed={childrenCollapsed}
								rollupProjectedBillableValueOfService={rollupTotalPriceAtCompletion}
								formatMessage={intl.formatMessage}
								formatNumber={intl.formatNumber}
							></TaskProjectedBillableValueOfServiceColumn>
					  )}
				{/* ACTUAL REVENUE */}
				{getColumnWidth('actual-price', columnsObject) && (
					<TaskActualRevenueColumn
						width={getColumnWidth('actual-price', columnsObject)}
						lazyDataFetched={lazyDataFetched}
						task={task}
						sharedOptions={sharedOptions}
						childrenCollapsed={childrenCollapsed}
						rollupActualPrice={rollupActualPrice}
						formatMessage={intl.formatMessage}
						formatNumber={intl.formatNumber}
					></TaskActualRevenueColumn>
				)}
				{!hasFeatureFlag('financial_categories_update')
					? /* PLANNED COST */
					  getColumnWidth('planned-cost', columnsObject) && (
							<TaskPlannedCostColumn
								width={getColumnWidth('planned-cost', columnsObject)}
								lazyDataFetched={lazyDataFetched}
								task={task}
								sharedOptions={sharedOptions}
								childrenCollapsed={childrenCollapsed}
								rollupPlannedCost={rollupPlannedCost}
								formatMessage={intl.formatMessage}
								formatNumber={intl.formatNumber}
							></TaskPlannedCostColumn>
					  )
					: /* PROJECTED COST */
					  getColumnWidth('projected-cost', columnsObject) && (
							<TaskProjectedCostColumn
								width={getColumnWidth('projected-cost', columnsObject)}
								lazyDataFetched={lazyDataFetched}
								task={task}
								sharedOptions={sharedOptions}
								childrenCollapsed={childrenCollapsed}
								rollupProjectedCost={rollupProjectedCost}
								formatMessage={intl.formatMessage}
								formatNumber={intl.formatNumber}
							></TaskProjectedCostColumn>
					  )}
				{/* ACTUAL COST */}
				{getColumnWidth('actual-cost', columnsObject) && (
					<TaskActualCostColumn
						width={getColumnWidth('actual-cost', columnsObject)}
						lazyDataFetched={lazyDataFetched}
						task={task}
						sharedOptions={sharedOptions}
						childrenCollapsed={childrenCollapsed}
						rollupActualCost={rollupActualCost}
						formatMessage={intl.formatMessage}
						formatNumber={intl.formatNumber}
					></TaskActualCostColumn>
				)}
				{/* LABELS */}
				{getColumnWidth('labels', columnsObject) && (
					<TaskLabelsColumn
						width={getColumnWidth('labels', columnsObject)}
						lazyDataFetched={lazyDataFetched}
						taskLabels={task.taskLabels}
						sharedOptions={sharedOptions}
						isDragging={isDragging}
					></TaskLabelsColumn>
				)}
				{/* APPROVED */}
				{getColumnWidth('approved', columnsObject) && (
					<TaskApprovedColumn
						width={getColumnWidth('approved', columnsObject)}
						lazyDataFetched={lazyDataFetched}
						taskId={task.id}
						taskApproved={task.approved}
						sharedOptions={sharedOptions}
						formatMessage={intl.formatMessage}
						disableActions={!hasPermission(PERMISSION_TYPE.PHASE_UPDATE) || disableActions}
					></TaskApprovedColumn>
				)}
				{/* STAR TASK */}
				{getColumnWidth('starred', columnsObject) && (
					<TaskStarColumn
						width={getColumnWidth('starred', columnsObject)}
						taskId={task.id}
						taskApproved={task.approved}
						taskFavoured={task.favoured}
						disabled={disableActions}
					></TaskStarColumn>
				)}
				{getColumnWidth('context-menu', columnsObject) && (
					<TaskDotMenuColumn
						width={getColumnWidth('context-menu', columnsObject)}
						disabled={false}
						onContextMenu={onContextMenu}
					></TaskDotMenuColumn>
				)}
			</TaskRowWrapper>
		);
	}
);

const ComplexColumn = ({getContent, width}) => {
	const [isHovered, setIsHovered] = useState(false);
	const [isFocused, setIsFocused] = useState(false);

	const showContent = isHovered || isFocused;

	return (
		<Column
			width={width}
			tabIndex={showContent ? -1 : 0}
			onClick={() => setIsFocused(true)}
			onFocus={() => setIsFocused(true)}
			onBlur={() => setIsFocused(false)}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			{getContent(isHovered, isFocused)}
		</Column>
	);
};

const SelectorColumn = React.memo(({task, selected, handleSelected, disableActions, width}) => {
	return (
		<Column width={width}>
			<Checkbox
				disabled={disableActions}
				checked={selected}
				onClick={e => handleSelected(task, e.shiftKey)}
				cy={'selector-checkbox'}
				userpilot={'bulk-update-checkbox'}
			/>
		</Column>
	);
});

const TaskProjectIdColumn = React.memo(({taskProject, width}) => {
	return (
		<Column width={width}>
			<DeprecatedProjectIndicatorJS project={taskProject} />
		</Column>
	);
});

const TaskProjectNameColumn = React.memo(({disableActions, taskProject, width}) => {
	const getProjectNavigationString = project => {
		const curLocArr = window.location.href.split('/');
		const pageName = curLocArr[curLocArr.length - 1];
		return projectUrl(project.companyProjectId, project.customProjectId) + '/' + pageName;
	};

	return (
		<Column width={width}>
			<div className={'project-group'}>
				<div className={'project-name'}>
					{disableActions ? (
						<div className={'td-text no-click'} tabIndex={'0'} title={taskProject.name}>
							{taskProject.name}
						</div>
					) : (
						<a
							className={'td-text'}
							href={getProjectNavigationString(taskProject)}
							tabIndex={'0'}
							title={taskProject.name}
						>
							{taskProject.name}
						</a>
					)}
				</div>
			</div>
		</Column>
	);
});

const TaskRoleColumnV2 = React.memo(
	({
		lazyDataFetched,
		disableActions,
		roles,
		disabledRoles,
		task,
		isClientUser,
		width,
		isDragging,
		childrenCollapsed,
		rollupRoles,
		intl,
	}) => {
		const assignRoleToTask = (roleIds, task) => {
			const roleId = roleIds && roleIds.length > 0 ? roleIds[0] : null;
			Util.CommitMutation(UpdateTaskMutation, {
				ids: [task.id],
				roleId: roleId,
			});
		};
		const selectedItems = task.role ? [task.role.id] : [];
		const activeDisabledRole = disabledRoles.find(role => role.node.id === selectedItems[0]);

		const getContent = (isHovered, isFocused) => {
			const showDropdown = isHovered || isFocused;
			if (childrenCollapsed) {
				let roleName;
				if (rollupRoles.size > 1) {
					roleName = intl.formatMessage({id: 'common.multiple_roles'});
				} else if (rollupRoles.size === 1 && [...rollupRoles][0]) {
					roleName = task.role.name;
				} else {
					roleName = '-';
				}
				return <UnfocusedDropdownTile key={'roles-dropdown'} disabled={true} value={roleName} newDropdown />;
			} else if (!lazyDataFetched || !showDropdown) {
				return (
					<UnfocusedDropdownTile
						key={'roles-dropdown'}
						disabled={!lazyDataFetched || disableActions || isClientUser}
						value={task.role ? task.role.name : intl.formatMessage({id: 'common.assign_role'})}
						noSelection={!task.role}
						newDropdown
						isRoleDisabled={!!activeDisabledRole}
					/>
				);
			} else {
				return (
					<div className="dropdown-container" tabIndex="-1" style={{width: '100%'}}>
						<RoleDropdown
							selectedItems={selectedItems}
							onSelect={ids => assignRoleToTask(ids, task)}
							onRemove={() => assignRoleToTask(null, task)}
							dropdownAlignment={'left'}
							key={'assigned-role'}
							width={255}
							name={intl.formatMessage({id: 'common.assign_role'})}
							isMultiSelect={false}
							roles={roles}
							activeDisabledRole={activeDisabledRole}
							optionsName={intl.formatMessage({id: 'settings.roles'})}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
							disabled={disableActions}
							taskId={task.id}
							useSuggestions
							usePortal
							focusOnMount={isFocused}
						/>
					</div>
				);
			}
		};

		return <ComplexColumn width={width} getContent={getContent} />;
	}
);

const TaskAssigneeColumnV2 = React.memo(
	({
		lazyDataFetched,
		disableActions,
		task,
		projectPersons,
		isClientUser,
		width,
		isDragging,
		childrenCollapsed,
		rollupAssignees,
		intl,
	}) => {
		const assignPersonsToTask = ids => {
			if (ids && ids.length > 0) {
				Util.CommitMutation(AssignPersonToTaskMutation, {
					taskId: task.id,
					personId: ids[0],
				});
			}
		};

		const unassignPersonsFromTask = ids => {
			if (ids && ids.length > 0) {
				Util.CommitMutation(UnassignPersonFromTaskMutation, {
					taskId: task.id,
					personId: ids[0],
				});
			}
		};

		const getContent = (isHovered, isFocused) => {
			const showDropdown = isHovered || isFocused;

			//Both task and subtasks are unassigned.
			const allUnassigned = rollupAssignees && [...rollupAssignees].every(assignees => assignees.length === 0);

			//Check that all task has the same assignees
			let prevTaskAssignees = null;
			let sameAssignees = true;
			rollupAssignees.forEach(assignees => {
				if (prevTaskAssignees) {
					sameAssignees &=
						prevTaskAssignees.length === assignees.length && assignees.every(a => prevTaskAssignees.includes(a));
				}
				prevTaskAssignees = assignees;
			});

			const selectedItemsPlaceholder = task.assignedPersons.map(person => {
				return {
					fullName: person.fullName,
					imageSource: profilePicSrc(person.profilePictureId),
				};
			});

			if (childrenCollapsed && !sameAssignees && !allUnassigned) {
				return (
					<UnfocusedDropdownTile
						key={'assigned-dropdown'}
						disabled={true}
						value={intl.formatMessage({id: 'common.multiple_assignees'})}
					/>
				);
			} else if (childrenCollapsed && allUnassigned) {
				return <UnfocusedDropdownTile key={'assigned-dropdown'} disabled={true} value={'-'} />;
			} else if (!lazyDataFetched || !showDropdown) {
				return (
					<UnfocusedDropdownTile
						key={'assigned-dropdown'}
						disabled={!lazyDataFetched || disableActions || isClientUser}
						value={
							task.assignedPersons.length > 0 ? (
								<AssigneeGroup alignStart showSingleName showCount={5} assignees={selectedItemsPlaceholder} />
							) : (
								intl.formatMessage({id: 'common.assign_people'})
							)
						}
						newDropdown
						noSelection={task.assignedPersons.length === 0}
					/>
				);
			} else {
				// If any selected person is not in 'persons', add them
				const missingSelectedPersons = task.assignedPersons
					?.filter(ap => !projectPersons.some(pp => ap.id === pp.node.id))
					.map(person => ({node: {person}}));
				return (
					<div className="dropdown-container" tabIndex="-1" style={{width: '100%'}}>
						<PersonDropdown
							persons={projectPersons.concat(missingSelectedPersons).map(projectPerson => {
								var node = {...projectPerson.node.person};
								if (projectPerson.node.role) {
									node.role = projectPerson.node.role;
								}
								return {node: node};
							})}
							name={intl.formatMessage({id: 'common.assign_people'})}
							optionsName={intl.formatMessage({id: 'common.persons'})}
							selectedGroupName={intl.formatMessage({id: 'common.applied'})}
							selectedItems={task.assignedPersons.map(person => person.id)}
							selectedItemsPlaceholder={task.assignedPersons.map(person => {
								return {
									fullName: person.fullName,
									imageSource: profilePicSrc(person.profilePictureId),
								};
							})}
							disabled={disableActions}
							onSelect={ids => assignPersonsToTask(ids)}
							onRemove={ids => unassignPersonsFromTask(ids)}
							dropdownAlignment={'left'}
							width={255}
							taskId={task.id}
							useSuggestions
							showRole
							usePortal
							focusOnMount={isFocused}
							includeDeactivatedPersons
						/>
					</div>
				);
			}
		};

		return <ComplexColumn width={width} getContent={getContent} />;
	}
);

const TaskDateColumn = React.memo(({disableActions, task, width, isDragging, align}) => {
	if (isDragging) return <Column width={width} />;

	const onSuccess = result => {
		if (result.updateTask.errors && result.updateTask.errors.length === 1) {
			Util.checkForSageErrorAndShowModal(result.updateTask.errors, false);
		}
	};

	const intl = useIntl();
	const handleDateRangeChange = (task, startDate, endDate) => {
		Util.CommitMutation(
			UpdateTaskMutation,
			{
				ids: [task.id],
				startYear: startDate.year(),
				startMonth: startDate.month() + 1,
				startDay: startDate.date(),
				deadlineYear: endDate.year(),
				deadlineMonth: endDate.month() + 1,
				deadlineDay: endDate.date(),
			},
			onSuccess
		);
	};

	const clearDates = task => {
		Util.CommitMutation(
			UpdateTaskMutation,
			{
				ids: [task.id],
				startYear: null,
				startMonth: null,
				startDay: null,
				deadlineYear: null,
				deadlineMonth: null,
				deadlineDay: null,
			},
			onSuccess
		);
	};

	return (
		<Column align={align} width={width}>
			<div className="phase-deadline-select double">
				<DatePicker
					buttonCy={'date-button'}
					disabled={disableActions}
					id={task.id}
					task={task}
					isNewDateRange={true}
					startDate={Util.CreateNonUtcMomentDate(task.startYear, task.startMonth, task.startDay)}
					endDate={Util.CreateNonUtcMomentDate(task.deadlineYear, task.deadlineMonth, task.deadlineDay)}
					handleDateRangeChange={(startDate, endDate) => handleDateRangeChange(task, startDate, endDate)}
					clearable={true}
					clearBothDates={() => clearDates(task)}
					startFrom={Util.getStartFrom(task, intl)}
					endFrom={Util.getEndFrom(task, intl)}
					datePickerStyle={DATE_PICKER_STYLE.SCOPING_PHASE_DATE}
					colorInherited={true}
					fontWeight={'normal'}
					collapseOnWheel={true}
					compactShowYear={true}
					disabledDates={isDateDisabled}
				/>
			</div>
		</Column>
	);
});

const TaskPhaseColumn = React.memo(({lazyDataFetched, task, disableActions, sharedOptions, isClientUser, width, intl}) => {
	const onPhaseChange = (selected, task) => {
		const phaseId = selected ? selected.value : null;
		Util.CommitMutation(UpdateTaskMutation, {
			ids: [task.id],
			phaseId: phaseId,
		});
	};

	const getContent = (isHovered, isFocused) => {
		const showDropdown = isHovered || isFocused;

		if (!lazyDataFetched || !showDropdown) {
			return (
				<UnfocusedDropdownTile
					key={'phase-dropdown'}
					disabled={!lazyDataFetched || disableActions || isClientUser}
					value={task.phase ? task.phase.name : intl.formatMessage({id: 'project_scopes.no-scope'})}
				/>
			);
		} else {
			return (
				<TaskRowDropdownStyle>
					<DropdownV2
						focusOnClick={true}
						optionClickEvent={true}
						onChange={phase => onPhaseChange(phase, task)}
						options={sharedOptions.phases}
						value={task.phase ? task.phase.id : null}
						hideLabel={true}
						disabled={disableActions || isClientUser}
						listDataCy={'sprint-list'}
						buttonCy={'sprint-arrow'}
						inputCy={'sprint-input'}
						customHeight={30}
						focusOnMount={isFocused}
					/>
				</TaskRowDropdownStyle>
			);
		}
	};

	// tabIndex is set to -1 when showing dropdown to allow escaping element (since focus will be passed down to child)
	return <ComplexColumn width={width} getContent={getContent} />;
});

const TaskSprintColumn = React.memo(({lazyDataFetched, task, disableActions, sharedOptions, width, intl}) => {
	const onSprintChange = (selected, task, isConnectedParent) => {
		const onSuccess = response => {
			if (
				response &&
				response.updateTask &&
				response.updateTask.errors &&
				response.updateTask.errors.includes('jira_sprint_change_failed')
			) {
				// Jira rejected the sprint change. Show message to user.
				Util.showJiraSprintChangeRejected(this.props.intl);
			}
		};

		const mutationObject = {
			ids: [task.id],
		};

		if (isConnectedParent) {
			mutationObject.projectGroupSprintId = selected ? selected.value : null;
		} else {
			mutationObject.sprintId = selected ? selected.value : null;
		}
		Util.CommitMutation(UpdateTaskMutation, mutationObject, onSuccess);
	};

	const getContent = (isHovered, isFocused) => {
		const showDropdown = isHovered || isFocused;

		if (!lazyDataFetched || !showDropdown) {
			return (
				<UnfocusedDropdownTile
					key={'sprint-dropdown'}
					disabled={!lazyDataFetched || disableActions}
					value={task.sprint ? task.sprint.name : intl.formatMessage({id: 'project_sprints.backlog'})}
				/>
			);
		} else {
			return (
				<TaskRowDropdownStyle>
					<DropdownV2
						focusOnClick={true}
						optionClickEvent={true}
						onChange={sprint => onSprintChange(sprint, task, sharedOptions.isConnectedParent)}
						options={sharedOptions.sprints}
						value={
							task.sprint
								? sharedOptions.isConnectedParent
									? task.sprint.projectGroupSprintId
									: task.sprint.id
								: null
						}
						hideLabel={true}
						disabled={disableActions}
						listDataCy={'sprint-list'}
						buttonCy={'sprint-arrow'}
						inputCy={'sprint-input'}
						customHeight={30}
						focusOnMount={isFocused}
					/>
				</TaskRowDropdownStyle>
			);
		}
	};

	return <ComplexColumn width={width} getContent={getContent} />;
});

const TaskStatusColumn = React.memo(
	({
		lazyDataFetched,
		width,
		taskProject,
		task,
		intl,
		disableActions,
		isClientUser,
		childrenCollapsed,
		rollupStatus,
		children,
	}) => {
		// Find all available Task Status options
		const projectStatusOptions = cloneDeep(taskProject.statusColumnsV2.edges)
			.sort((a, b) => {
				const comparisonResult = a.node.order - b.node.order;
				if (comparisonResult > 0) {
					return 1;
				} else if (comparisonResult < 0) {
					return -1;
				} else {
					return 0;
				}
			})
			.map(statusColumn => {
				return {
					label: statusColumn.node.name,
					value: statusColumn.node.id,
					logo: statusColumn.node.jiraStatusId ? 'jira-logo' : undefined,
					category: statusColumn.node.category,
				};
			});

		/**
		 * When the task status is changed, this method will be executed.
		 *
		 * It investigates if it is required to micro assist the change,
		 * if the task has subtask, it call the micro assist modal,
		 * which will investigate if it is required to show the modal or not.
		 *
		 * @param task The task to change the status on
		 * @param option The selected status
		 * @param intl I18n
		 */
		const onStatusColumnChange = (task, option, intl) => {
			const microAssist = () => {
				// Check if this status change should be micro assisted
				const shouldMicroAssist = task.hasChildren;

				if (shouldMicroAssist) {
					showModal({
						type: MODAL_TYPE.TASK_STATUS_MICRO_ASSIST,
						companyTaskId: task.companyTaskId,
					});
				}
			};

			// Update the task
			Util.changeTaskStatus(task, option, intl, null, microAssist);
		};

		const getContent = (isHovered, isFocused) => {
			const showDropdown = isHovered || isFocused;

			if (childrenCollapsed) {
				let status =
					rollupStatus.size > 1 ? intl.formatMessage({id: 'common.multiple_statuses'}) : task.statusColumnV2.name;

				return <UnfocusedDropdownTile key={'status-dropdown'} disabled={true} value={status} />;
			} else {
				if ((!lazyDataFetched || !showDropdown) && !childrenCollapsed) {
					return (
						<UnfocusedDropdownTile
							key={'status-dropdown'}
							disabled={!lazyDataFetched || disableActions || isClientUser}
							value={task.statusColumnV2 ? task.statusColumnV2.name : ''}
							cy="task-status"
						/>
					);
				} else {
					return (
						<TaskRowDropdownStyle>
							<DropdownV2
								focusOnClick={true}
								optionClickEvent={true}
								onChange={option => onStatusColumnChange(task, option, intl)}
								options={projectStatusOptions}
								value={task.statusColumnV2.id}
								hideLabel={true}
								disabled={disableActions || isClientUser || childrenCollapsed}
								listDataCy={'status-list'}
								buttonCy={'status-arrow'}
								inputCy={'status-input'}
								restrictWidth={true}
								//borderlessOnTop={false}
								customHeight={30}
								focusOnMount={isFocused}
							/>
						</TaskRowDropdownStyle>
					);
				}
			}
		};

		return <ComplexColumn width={width} getContent={getContent} />;
	}
);

const TaskProgressColumn = React.memo(
	({width, task, taskDone, taskProject, rollupProgress, childrenCollapsed, intl, parentTaskWithNoEstimateAndTimeRegs}) => {
		const manualProgressOnTasksEnabled = taskProject.manualProgressOnTasksEnabled;
		const manualProgressOnPhasesEnabled = taskProject.manualProgressOnPhasesEnabled;

		const rollupProgressValue = rollupProgress.getApprovedValue ? rollupProgress.getApprovedValue() : rollupProgress;

		const taskProgressValue =
			manualProgressOnTasksEnabled && task.progressDetails ? task.progressDetails.progress : task.progress;

		const progress = childrenCollapsed || parentTaskWithNoEstimateAndTimeRegs ? rollupProgressValue : taskProgressValue;

		let content = null;
		// If No time registration is enabled
		if (manualProgressOnTasksEnabled || manualProgressOnPhasesEnabled) {
			// Create the content to show
			const getContent = () => {
				if (manualProgressOnPhasesEnabled) {
					return taskDone ? <div className={'done-check-mark'} /> : <div />;
				} else if (taskDone) {
					return <div className={'done-check-mark'} />;
				} else {
					const progressRegistrationPopup = (
						<TaskProgressRegistrationPopup task={task} currentProgressValue={progress} />
					);
					return (
						<NumericTileV2
							id={task.id}
							key={'progress'}
							popup={progressRegistrationPopup}
							value={progress}
							unit={UNIT.PERCENTAGE}
							cy={'done-percentage-tile'}
						/>
					);
				}
			};

			return <ComplexColumn width={width} getContent={getContent} />;
		} else {
			taskDone
				? (content = <div className={'done-check-mark'} />)
				: progress !== undefined
				? (content = (
						<NumericTileV2
							id={task.id}
							key={'progress'}
							value={intl.formatNumber(progress, {format: 'rounded_zero_decimal'})}
							unit={UNIT.PERCENTAGE}
							cy={'done-percentage-tile'}
						/>
				  ))
				: (content = <TextTile textAlign="right" id={task.id} key={'progress'} value={'-'} />);

			return <Column width={width}>{content}</Column>;
		}
	}
);

const TaskEstimateColumn = React.memo(
	({
		lazyDataFetched,
		task,
		childrenCollapsed,
		disableActions,
		sharedOptions,
		rollUpEstimate,
		width,
		intl,
		groupSetting,
		parentTaskWithNoEstimateAndTimeRegs,
	}) => {
		const estimationUnit = task.project.estimationUnit;
		const isEstimatedInHours = estimationUnit === ESTIMATION_UNIT.HOURS;
		const showWarning = showOverrunPredictionWarning(task) && !childrenCollapsed;

		const estimateForecast =
			childrenCollapsed ||
			parentTaskWithNoEstimateAndTimeRegs ||
			(sharedOptions.useTaskHierarchy && groupSetting === 'person')
				? rollUpEstimate
				: task.estimateForecast;
		const forecastValue = isEstimatedInHours ? estimateForecast / 60 : estimateForecast;

		// Should be pulled from sharedOptions after grouping is implemented
		const isShared = false;
		const numberOfPerson = 1;
		const taskAssignedNames = [];

		const estimateSharedMessage = intl.formatMessage(
			{id: 'common.estimate_shared'},
			{
				total: !isEstimatedInHours ? forecastValue : Util.convertMinutesToFullHour(forecastValue * 60, intl),
				persons: taskAssignedNames,
			}
		);
		const predictionInputExtension = showWarning ? (
			<PredictionPopupInputExtension
				task={task}
				modelScore={sharedOptions.lowHighModelScore}
				showWarning={showWarning}
				intl={intl}
			/>
		) : null;

		const getContent = (isHovered, isFocused) => {
			const showContent = isHovered || isFocused;
			if (isShared) {
				const personForecastValueInHoursShared = Math.round((forecastValue / numberOfPerson) * 100) / 100;

				if (!isEstimatedInHours) {
					return (
						<NumericTile
							task={task}
							id={task.id}
							key={'estimate'}
							value={personForecastValueInHoursShared}
							unit={estimationUnit}
							isV2={true}
							cy={'forecast-tile'}
							groupedValues={isShared}
							tooltip={isShared}
							infoText={
								isShared
									? '' +
									  intl.formatMessage(
											{id: 'common.estimate_shared'},
											{total: forecastValue, persons: taskAssignedNames}
									  )
									: ''
							}
						/>
					);
				} else {
					return (
						<NumericTileV2
							id={task.id}
							key={'estimate'}
							value={personForecastValueInHoursShared * 60}
							unit={UNIT.HOURS}
							asterisk={isShared}
							infoText={intl.formatMessage(
								{id: 'common.estimate_shared'},
								{total: Util.convertMinutesToFullHour(forecastValue * 60, intl), persons: taskAssignedNames}
							)}
							cy={'forecast-tile'}
						/>
					);
				}
			} else if (!isEstimatedInHours) {
				if (childrenCollapsed) {
					return (
						<NumericTileV2
							id={task.id}
							value={forecastValue}
							unit={UNIT.POINTS}
							cy={'forecast-tile'}
							inputStyle={!childrenCollapsed}
							inputIndent
						></NumericTileV2>
					);
				} else {
					return (
						<AffixedInputWrapper
							onClick={e => e.focus()}
							disabled={disableActions || !lazyDataFetched}
							customClassName={'hours-input'}
							value={forecastValue}
							callback={value => handleEstimationChanges('estimateInput', value, task, onEstimateUpdateSuccess)}
							affix={intl.formatMessage({id: 'common.points.short'})}
						/>
					);
				}
			} else {
				return !showContent || childrenCollapsed ? (
					<NumericTileV2
						id={task.id}
						value={forecastValue * 60}
						unit={UNIT.HOURS}
						asterisk={isShared}
						infoText={isShared ? estimateSharedMessage : null}
						cy={'forecast-tile'}
						inputStyle={!childrenCollapsed}
						inputIndent
						inputExtension={predictionInputExtension}
					/>
				) : lazyDataFetched ? (
					<>
						{predictionInputExtension}
						<HoursInput
							customClassName={'hours-input'}
							value={forecastValue}
							mutation={v => handleEstimationChanges('estimateInput', v * 60, task, onEstimateUpdateSuccess)}
							onClick={e => e.focus()}
							cy={'estimate-input'}
							disabled={disableActions}
							focusOnMount={isFocused}
							withInputExtension={showWarning}
						/>
					</>
				) : (
					<NumericTiles
						id={task.id}
						valueInMinutes={forecastValue * 60}
						asterisk={isShared}
						infoText={isShared ? estimateSharedMessage : null}
						cy={'forecast-tile'}
					></NumericTiles>
				);
			}
		};

		return <ComplexColumn width={width} getContent={getContent} />;
	}
);

const TaskTimeRegistrationsColumn = React.memo(
	({task, disableActions, sharedOptions, isClientUser, childrenCollapsed, rollupTimeRegistered, width, intl}) => {
		const timeRegPopup =
			!disableActions && !isClientUser ? (
				<TimeRegPopup
					viewer={sharedOptions.timeRegOptions}
					projectId={task.project.id}
					task={task}
					harvestTask={false}
					refetch={sharedOptions.refetch}
				/>
			) : null;
		const totalTime = useMemo(() => {
			return task.timeRegistrations ? getTotalTimeEntries(task.timeRegistrations, false) : 0;
		}, [task.timeRegistrations]);

		let content = null;
		if (task.timeRegistrations) {
			const minutesRegistered = childrenCollapsed ? rollupTimeRegistered : totalTime * 60;
			content = (
				<NumericTileV2
					id={task.id}
					value={minutesRegistered}
					unit={UNIT.HOURS}
					popup={timeRegPopup}
					cy={'time-entries-tile'}
				/>
			);
		} else {
			content = <TextTile textAlign="right" id={task.id} key={'time-entries'} value={'-'} />;
		}
		return <Column width={width}>{content}</Column>;
	}
);

const TaskBillableTimeRegistrationsColumn = React.memo(({task, childrenCollapsed, rollupBillableTimeRegistered, width}) => {
	const totalBillableTime = useMemo(() => {
		return task.timeRegistrations ? getTotalBillableTimeEntries(task.timeRegistrations, false) : 0;
	}, [task.timeRegistrations]);

	let content = null;
	if (task.timeRegistrations) {
		const minutesRegistered = childrenCollapsed ? rollupBillableTimeRegistered : totalBillableTime * 60;
		content = <NumericTileV2 id={task.id} value={minutesRegistered} unit={UNIT.HOURS} cy={'billable-time-entries-tile'} />;
	} else {
		content = <TextTile textAlign="right" id={task.id} key={'billable-time-entries'} value={'-'} />;
	}
	return <Column width={width}>{content}</Column>;
});

const TaskNonBillableTimeRegistrationsColumn = React.memo(
	({task, childrenCollapsed, rollupTimeRegistered, rollupBillableTimeRegistered, width}) => {
		const totalTime = useMemo(() => {
			return task.timeRegistrations ? getTotalTimeEntries(task.timeRegistrations, false) : 0;
		}, [task.timeRegistrations]);

		const totalBillableTime = useMemo(() => {
			return task.timeRegistrations ? getTotalBillableTimeEntries(task.timeRegistrations, false) : 0;
		}, [task.timeRegistrations]);

		const totalNonBillableTime = getNonBillableTime(totalTime, totalBillableTime);

		let content = null;
		if (task.timeRegistrations) {
			const minutesRegistered = childrenCollapsed
				? getNonBillableTime(rollupTimeRegistered, rollupBillableTimeRegistered)
				: totalNonBillableTime * 60;
			content = (
				<NumericTileV2 id={task.id} value={minutesRegistered} unit={UNIT.HOURS} cy={'non-billable-time-entries-tile'} />
			);
		} else {
			content = <TextTile textAlign="right" id={task.id} key={'non-billable-time-entries'} value={'-'} />;
		}
		return <Column width={width}>{content}</Column>;
	}
);

const TaskRemainingColumn = React.memo(
	({
		lazyDataFetched,
		task,
		taskProject,
		disableActions,
		sharedOptions,
		isClientUser,
		childrenCollapsed,
		rollupRemaining,
		width,
		intl,
		groupSetting,
	}) => {
		const handleRemainingChange = (val, task) => {
			if (val >= 0) {
				Util.CommitMutation(UpdateTaskMutation, {ids: [task.id], remaining: val});
			}
		};

		const getContent = (isHovered, isFocused) => {
			const showContent = isHovered || isFocused;

			if (task.timeLeft === undefined) {
				return <TextTile textAlign="right" id={task.id} key={'remaining'} value={'-'} />;
			}
			const estimationUnit = task.project.estimationUnit;
			const isEstimatedInHours = estimationUnit === ESTIMATION_UNIT.HOURS;

			// Should be pulled from sharedOptions after grouping is implemented
			const isShared = false;
			const numberOfPerson = 1;

			if (childrenCollapsed) {
				return (
					<NumericTileV2
						id={task.id}
						value={rollupRemaining}
						unit={isEstimatedInHours ? UNIT.HOURS : UNIT.POINTS}
					></NumericTileV2>
				);
			}

			const disableEdit =
				disableActions || taskProject.remainingAutoCalculated || task.statusColumnV2.category === 'DONE' || isShared;

			if (!isEstimatedInHours) {
				const remainingValue = Math.round(task.timeLeft * 100) / 100;
				//const personRemainingValue = groupByPerson ? Math.round((remainingValue / numberOfPerson) * 100) / 100 : null;
				if (disableEdit || !showContent) {
					return (
						<NumericTileV2
							id={task.id}
							value={remainingValue}
							unit={UNIT.POINTS}
							asterisk={isShared}
							inputStyle={!disableEdit}
						></NumericTileV2>
					);
				}
				return (
					<AffixedInputWrapper
						onClick={e => e.focus()}
						disabled={disableEdit}
						customClassName={'hours-input'}
						value={remainingValue}
						callback={value => handleRemainingChange(value, task)}
						affix={intl.formatMessage({id: 'common.points.short'})}
					/>
				);
			} else {
				const remainingValue = task.timeLeft;
				const remainingInMinutes = isShared
					? Math.round((remainingValue / numberOfPerson) * 100) / 100
					: remainingValue;
				if (disableEdit || !showContent) {
					return (
						<NumericTileV2
							id={task.id}
							value={remainingInMinutes}
							unit={UNIT.HOURS}
							asterisk={isShared}
							inputStyle={!disableEdit}
						></NumericTileV2>
					);
				} else {
					return (
						<HoursInput
							customClassName={'hours-input'}
							value={remainingInMinutes / 60}
							mutation={v => handleRemainingChange(v * 60, task)}
							onClick={e => e.focus()}
							disabled={isShared}
							focusOnMount={isFocused}
						/>
					);
				}
			}
		};

		return <ComplexColumn width={width} getContent={getContent} />;
	}
);

const TaskOverEstimateColumn = React.memo(({task, childrenCollapsed, rollupDifferenceEstimate, width, groupSetting}) => {
	const estimationUnit = task.project.estimationUnit;
	const isEstimatedInHours = estimationUnit === ESTIMATION_UNIT.HOURS;
	const totalTime = useMemo(() => {
		return task.timeRegistrations ? getTotalTimeEntries(task.timeRegistrations, false) : 0;
	}, [task.timeRegistrations]);

	// Should be pulled from sharedOptions after grouping is implemented
	const groupByPerson = false; //groupSetting === GROUP_TYPE.PERSON;
	const numberOfPerson = 1; //task.assignedPersons ? task.assignedPersons.length : 1;

	let content = null;
	if (task.timeRegistrations) {
		const personTimeRegs = 0;
		const forecast = isEstimatedInHours ? task.estimateForecast / 60 : task.estimateForecast;
		const minutesPerEstimationPoint = task.project.minutesPerEstimationPoint;

		const overForecastValue = childrenCollapsed
			? rollupDifferenceEstimate
			: isEstimatedInHours
			? (forecast - totalTime) * 60
			: forecast * minutesPerEstimationPoint - totalTime * 60;

		const overForecastPersonValue = isEstimatedInHours
			? (forecast / numberOfPerson - personTimeRegs) * 60
			: (forecast * minutesPerEstimationPoint) / numberOfPerson - personTimeRegs * 60;

		content = (
			<NumericTileV2
				id={task.id}
				key={'over-forecast'}
				value={groupByPerson ? overForecastPersonValue : overForecastValue}
				unit={UNIT.HOURS}
				cy={'over-forecast-tile'}
				highlightNegative={true}
			/>
		);
	} else {
		content = <TextTile textAlign="right" id={task.id} key={'over-forecast'} value={'-'} />;
	}

	return <Column width={width}>{content}</Column>;
});

const TaskPlanRevenueColumn = React.memo(
	({
		lazyDataFetched,
		task,
		disableActions,
		sharedOptions,
		childrenCollapsed,
		rollupPrice,
		width,
		formatMessage,
		formatNumber,
	}) => {
		const currencySymbol = sharedOptions.currencySymbol;
		const placeUnitBeforeValue = Util.CurrencyIsPrefixed(currencySymbol);

		// Should be pulled from sharedOptions after grouping is implemented
		const groupByPerson = false;
		//const isShared = false;
		const numberOfPerson = 1;
		//const taskAssignedNames = [];
		/*task.assignedPersons.length > 2 // Oxford comma rule of three
				? task.assignedPersons.map(person => person.firstName + ' ' + person.lastName).reduce((acc, name, index) => (index === task.assignedPersons.length - 1 ? acc.concat(' and ' + name) : acc.concat(name + ', ')), '')
				: task.assignedPersons.map(person => person.firstName + ' ' + person.lastName).reduce((acc, name, index) => (index === task.assignedPersons.length - 1 ? acc.concat(' and ' + name) : acc.concat(name + ' ')), '');*/
		const estimatePrice = childrenCollapsed ? rollupPrice : ProjectUtil.getEstimatedPrice(task);
		const forecastPriceValue = formatNumber(estimatePrice, {format: 'always_two_decimal'});
		const forecastPersonPriceValue = formatNumber(Number(ProjectUtil.getEstimatedPrice(task) / numberOfPerson), {
			format: 'always_two_decimal',
		});
		let content = null;
		content = (
			<NumericTileV2
				id={task.id}
				key={'price'}
				placeUnitBeforeValue={placeUnitBeforeValue}
				value={groupByPerson ? forecastPersonPriceValue : forecastPriceValue}
				unit={currencySymbol}
				cy={'price-tile'}
			/>
		);
		return <Column width={width}>{content}</Column>;
	}
);

const TaskProjectedBillableValueOfServiceColumn = React.memo(
	({
		lazyDataFetched,
		task,
		disableActions,
		sharedOptions,
		childrenCollapsed,
		rollupProjectedBillableValueOfService,
		width,
		formatMessage,
		formatNumber,
	}) => {
		const currencySymbol = sharedOptions.currencySymbol;
		const placeUnitBeforeValue = Util.CurrencyIsPrefixed(currencySymbol);

		// Should be pulled from sharedOptions after grouping is implemented
		const groupByPerson = false;
		//const isShared = false;
		const numberOfPerson = 1;
		const projectedBillableValueOfService = childrenCollapsed
			? rollupProjectedBillableValueOfService
			: ProjectUtil.getProjectedBillableValueOfServiceForTask(task);
		const projectedBillableValueOfServiceFormatted = formatNumber(projectedBillableValueOfService, {
			format: 'always_two_decimal',
		});
		const personProjectedBillableValueOfService = formatNumber(
			Number(ProjectUtil.getProjectedBillableValueOfServiceForTask(task) / numberOfPerson),
			{
				format: 'always_two_decimal',
			}
		);
		let content = null;
		content = (
			<NumericTileV2
				id={task.id}
				key={'projected-billable-value-of-service'}
				placeUnitBeforeValue={placeUnitBeforeValue}
				value={groupByPerson ? personProjectedBillableValueOfService : projectedBillableValueOfServiceFormatted}
				unit={currencySymbol}
				cy={'projected-billable-value-of-service-tile'}
			/>
		);
		return <Column width={width}>{content}</Column>;
	}
);

const TaskActualRevenueColumn = React.memo(
	({
		lazyDataFetched,
		task,
		disableActions,
		sharedOptions,
		childrenCollapsed,
		rollupActualPrice,
		width,
		formatMessage,
		formatNumber,
	}) => {
		const currencySymbol = sharedOptions.currencySymbol;
		const placeUnitBeforeValue = Util.CurrencyIsPrefixed(currencySymbol);

		// Should be pulled from sharedOptions after grouping is implemented
		const groupByPerson = false;
		//const isShared = false;

		let content = null;

		if (task.timeRegistrations) {
			const actualPrice = childrenCollapsed ? rollupActualPrice : ProjectUtil.getActualPriceForTask(task);
			const currentPriceValue = formatNumber(actualPrice, {format: 'always_two_decimal'});
			const currentPersonPriceValue = formatNumber(
				task.timeRegistrations && task.billable ? getTotalActualPrice(task.timeRegistrations, true) : 0,
				{format: 'always_two_decimal'}
			);

			const value = groupByPerson ? currentPersonPriceValue : currentPriceValue;

			content = (
				<NumericTileV2
					id={task.id}
					key={'actual-price'}
					placeUnitBeforeValue={placeUnitBeforeValue}
					value={value}
					unit={currencySymbol}
					cy={'actual-price-tile'}
				/>
			);
		} else {
			content = <TextTile textAlign="right" id={task.id} key={'actual-price'} value={'-'} />;
		}
		return <Column width={width}>{content}</Column>;
	}
);

const TaskPlannedCostColumn = React.memo(({task, sharedOptions, width, formatNumber, rollupPlannedCost, childrenCollapsed}) => {
	const plannedCostValue = childrenCollapsed ? rollupPlannedCost : ProjectUtil.getPlannedCostForTask(task);

	const plannedCostFormattedValue = formatNumber(plannedCostValue, {
		format: 'always_two_decimal',
	});

	const currencySymbol = sharedOptions.currencySymbol;
	const placeUnitBeforeValue = Util.CurrencyIsPrefixed(currencySymbol);

	return (
		<Column width={width}>
			<NumericTileV2
				id={task.id}
				key={'planned-cost'}
				placeUnitBeforeValue={placeUnitBeforeValue}
				task={task}
				value={plannedCostFormattedValue}
				unit={currencySymbol}
				isV2={true}
				cy={'planned-cost-tile'}
			/>
		</Column>
	);
});

const TaskProjectedCostColumn = React.memo(
	({task, sharedOptions, width, formatNumber, rollupProjectedCost, childrenCollapsed}) => {
		const projectedCostValue = childrenCollapsed ? rollupProjectedCost : ProjectUtil.getProjectedCostForTask(task);

		const projectedCostFormattedValue = formatNumber(projectedCostValue, {
			format: 'always_two_decimal',
		});

		const currencySymbol = sharedOptions.currencySymbol;
		const placeUnitBeforeValue = Util.CurrencyIsPrefixed(currencySymbol);

		return (
			<Column width={width}>
				<NumericTileV2
					id={task.id}
					key={'projected-cost'}
					placeUnitBeforeValue={placeUnitBeforeValue}
					task={task}
					value={projectedCostFormattedValue}
					unit={currencySymbol}
					isV2={true}
					cy={'projected-cost-tile'}
				/>
			</Column>
		);
	}
);

const TaskActualCostColumn = React.memo(({task, sharedOptions, width, formatNumber, rollupActualCost, childrenCollapsed}) => {
	const actualCostValue = childrenCollapsed ? rollupActualCost : ProjectUtil.getActualCostForTask(task);

	const actualCostFormattedValue = formatNumber(actualCostValue, {
		format: 'always_two_decimal',
	});

	const currencySymbol = sharedOptions.currencySymbol;
	const placeUnitBeforeValue = Util.CurrencyIsPrefixed(currencySymbol);

	return (
		<Column width={width}>
			<NumericTileV2
				id={task.id}
				key={'planned-cost'}
				placeUnitBeforeValue={placeUnitBeforeValue}
				task={task}
				value={actualCostFormattedValue}
				unit={currencySymbol}
				isV2={true}
				cy={'actual-cost-tile'}
			/>
		</Column>
	);
});

const TaskLabelsColumn = React.memo(
	({lazyDataFetched, taskLabels, disableActions, sharedOptions, isClientUser, width, isDragging}) => {
		if (isDragging) return <Column width={width} />;

		let content = null;
		content = <LabelGroup labels={taskLabels} noWrap={true} />;
		return <Column width={width}>{content}</Column>;
	}
);

const TaskApprovedColumn = React.memo(
	({lazyDataFetched, taskId, taskApproved, disableActions, width, sharedOptions, formatMessage}) => {
		const onTaskApprovalChange = (taskId, option) => {
			Util.CommitMutation(UpdateTaskMutation, {
				ids: [taskId],
				approved: option.value,
			});
		};

		const getContent = (isHovered, isFocused) => {
			const canUpdatePhase = hasPermission(PERMISSION_TYPE.PHASE_UPDATE);
			const showDropdown = isHovered || isFocused;
			if (!lazyDataFetched || !showDropdown || !canUpdatePhase) {
				return (
					<UnfocusedDropdownTile
						key={'approved-dropdown'}
						disabled={!lazyDataFetched || disableActions || !canUpdatePhase}
						value={taskApproved ? formatMessage({id: 'common.yes'}) : formatMessage({id: 'common.no'})}
						cy="task-approved"
					/>
				);
			} else {
				return (
					<TaskRowDropdownStyle>
						<DropdownV2
							customClasses={'approved-dropdown'}
							focusOnClick={true}
							optionClickEvent={true}
							onChange={option => onTaskApprovalChange(taskId, option)}
							options={[
								{
									label: formatMessage({id: 'common.yes'}),
									value: true,
								},
								{
									label: formatMessage({id: 'common.no'}),
									value: false,
								},
							]}
							value={taskApproved}
							hideLabel={true}
							disabled={disableActions}
							listDataCy={'approved-list'}
							buttonCy={'approved-arrow'}
							inputCy={'approved-input'}
							restrictWidth={true}
							customHeight={30}
							focusOnMount={isFocused}
						/>
					</TaskRowDropdownStyle>
				);
			}
		};

		return <ComplexColumn width={width} getContent={getContent} />;
	}
);

const TaskStarColumn = React.memo(({taskId, taskApproved, taskFavoured, width, disabled = false}) => {
	let content = null;
	content = !taskApproved ? null : (
		<div className={'star-option'}>
			<StarTask taskId={taskId} favoured={taskFavoured} disabled={disabled} />
		</div>
	);
	return <Column width={width}>{content}</Column>;
});

const TaskDotMenuColumn = React.memo(({disabled, width, onContextMenu}) => {
	const [active, setActive] = useState(false);

	const closeDotMenu = useCallback(e => {
		if (!e.target.className || !e.target.className.includes('dot-options')) {
			setActive(false);
		}
	}, []);

	useEffect(() => {
		if (active) {
			window.addEventListener('click', closeDotMenu);
			return () => {
				window.removeEventListener('click', closeDotMenu);
			};
		}
	}, [active]);

	const onDotMenuClick = e => {
		setActive(!active);
		onContextMenu(e);
	};

	const content = disabled ? null : (
		<DotOptions
			data-cy="dot-options"
			className={'dot-options' + (active ? ' clicked' : '')}
			onClick={e => onDotMenuClick(e)}
			onBlur={() => setActive(false)}
		/>
	);
	return <Column width={width}>{content}</Column>;
});
