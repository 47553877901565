import {Button, Detail, FlexColumn, FlexRow, SubHeading, Text, theme} from '@forecast-it/design-system';
import React, {useState} from 'react';
import Styled from 'styled-components/macro';
import Util from '../../../shared/util/util';
import UpdateCompanyMutation from '../../../../mutations/update_company_mutation.modern';
import {Switch} from 'web-components';
import {useIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import {PersonDropdown} from '../../../shared/components/dropdowns/Person_dropdown';
import {cloneDeep, partition} from 'lodash';
import styled from 'styled-components';
import Person from '../../../shared/components/person/person';
import * as tracking from '../../../../tracking';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';
import UpdatePersonMutation from '../../../../mutations/update_person_mutation.modern';
import {profilePicSrc} from '../../../../directApi';

const Section = Styled.div`
	display: flex;
	max-width: 700px;
  flex-direction: column;
	flex-wrap: wrap;
	gap: ${theme.spacing.xs};
`;

const AddPersonSection = styled.div`
	width: 228px;
`;

const ExemptionsTable = styled.div`
	margin-top: 10px;
`;

const ToggleIsSSOMandatory = ({company}) => {
	const intl = useIntl();
	const {formatMessage} = intl;
	const [isSSOMandatory, setIsSSOMandatory] = useState(company.isSSOMandatory);

	const sortedPersons = cloneDeep(company.allPersons.edges).sort((a, b) =>
		Util.sortAlphabetically(a.node.fullName, b.node.fullName)
	);
	const personPartition = partition(sortedPersons, person => {
		return person.node.hasSSOExemption;
	});

	const selectedItems = personPartition[0].map(person => person.node.id);

	const {oktaEnabled, oneloginEnabled, AADEnabled, googleSSOEnabled} = company;
	const isDisabled = !(oktaEnabled || oneloginEnabled || AADEnabled || googleSSOEnabled);

	const onSelect = personId => {
		tracking.trackPageAction('SSO - Exemption added');
		trackEvent('SOO Person Exemption', 'Added');

		Util.CommitMutation(
			UpdatePersonMutation,
			{
				id: personId[0],
				hasSSOExemption: true,
			},
			null
		);
	};

	const onRemove = personId => {
		Util.CommitMutation(
			UpdatePersonMutation,
			{
				id: personId[0],
				hasSSOExemption: false,
			},
			null
		);
	};

	const saveConfiguration = (company, isSSOMandatory) => {
		const onSuccess = response => {
			setIsSSOMandatory(response.updateCompany.company.isSSOMandatory);
		};

		Util.CommitMutation(
			UpdateCompanyMutation,
			{
				id: company.id,
				isSSOMandatory: !isSSOMandatory,
			},
			onSuccess
		);
	};

	return (
		<>
			<Section>
				<FlexColumn gap={'l'}>
					<SubHeading>{formatMessage({id: 'login.sso_mandatory_title'})}</SubHeading>
					<Detail>{formatMessage({id: 'login.sso_mandatory_description'})}</Detail>
					<FlexRow gap={'l'}>
						<Switch
							onChange={() => saveConfiguration(company, isSSOMandatory)}
							checked={isSSOMandatory}
							locked={isDisabled}
						/>
						<Text color={isDisabled ? 'disabled' : 'body'} type={'label'} size={2}>
							{isSSOMandatory ? formatMessage({id: 'common.disable'}) : formatMessage({id: 'common.enable'})}
						</Text>
					</FlexRow>
					{isSSOMandatory && (
						<>
							<SubHeading>{intl.formatMessage({id: 'login.sso_mandatory.exemptions'})}</SubHeading>
							<Detail>{intl.formatMessage({id: 'login.sso_mandatory.exemptions_description'})}</Detail>
							{personPartition[1].length > 0 ? (
								<AddPersonSection>
									<PersonDropdown
										persons={sortedPersons}
										name={intl.formatMessage({id: 'login.sso_mandatory.add-person'})}
										optionsName={intl.formatMessage({id: 'common.persons'})}
										selectedGroupName={intl.formatMessage({id: 'common.applied'})}
										selectedItems={selectedItems}
										onRemove={ids => onRemove(ids)}
										onSelect={ids => onSelect(ids)}
										dropdownAlignment={'left'}
										width={275}
										usePortal
										maxHeight={255}
									/>
								</AddPersonSection>
							) : null}
							<ExemptionsTable>
								{personPartition[0].length === 0 ? (
									<Text>{intl.formatMessage({id: 'login.sso_no_exemption'})}</Text>
								) : (
									<table className="sso-exemption-table">
										<tbody>
											{personPartition[0].map(person => (
												<tr key={person.node.id} className="sso-exemption-row">
													<td className="name-column person-link-cell">
														<Person
															key={person.node.id}
															name={person.node.fullName}
															showName={true}
															showRole={false}
															imageSrc={profilePicSrc(person.node.profilePictureId)}
															imageSize="medium"
														/>
													</td>
													<td>
														<Button
															icon="close"
															emphasis="low"
															onClick={onRemove.bind(this, [person.node.id])}
															text={null}
														/>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								)}
							</ExemptionsTable>
						</>
					)}
				</FlexColumn>
			</Section>
		</>
	);
};

export default createFragmentContainer(ToggleIsSSOMandatory, {
	company: graphql`
		fragment ToggleIsSSOMandatory_company on Company {
			oktaEnabled
			oneloginEnabled
			AADEnabled
			googleSSOEnabled
			isSSOMandatory
			allPersons(first: 10000, excludeClientUsers: true, onlyActive: true) {
				edges {
					...PersonDropdown_persons
					node {
						id
						fullName
						firstName
						lastName
						email
						profilePictureId
						profilePictureDefaultId
						active
						hasSSOExemption
					}
				}
			}
		}
	`,
});
