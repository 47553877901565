import React from 'react';
import styled from 'styled-components';
import {CSS_CONSTANTS} from '../../../../../css_variables';

const OnboardingSection = styled.div`
	padding: 36px 0 8px 0;
`;

const ItemTitle = styled.div`
	font-weight: 600;
	font-size: 13px;
	color: ${CSS_CONSTANTS.v2_text_gray};
`;

const onboardingSection = ({title}) => {
	return (
		<OnboardingSection>
			<ItemTitle>{title}</ItemTitle>
		</OnboardingSection>
	);
};

export default onboardingSection;
