import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import Util from '../../util/util';
import UpdateTimeRegistration from '../../../../mutations/update_time_registration_mutation.modern';
import Moment from 'moment';
import CustomScrollDiv from '../scroll-bars/custom_scroll_div';
import DropdownV2 from '../dropdowns/dropdown';

class HarvestPopOut extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: false,
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.loadOnExpand && this.state.expanded === true && this.state.expanded !== prevState.expanded) {
			this.props.updateHarvestTasks();
		}
	}

	handleBlur(e) {
		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11
		if (this._container && this._container.contains(newTarget)) {
			return;
		} else this.toggleExpanded(e, false);
	}

	toggleExpanded(e, btn) {
		const noteHeight = 70;
		const marginsAndHeader = 66;
		//const minSpace = 125;
		const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
		const y = e.clientY ? e.clientY : 0;
		//const popOutSize = Math.min(this.props.timeRegItem.timeRegistrationsWithNotes.length, 2.5) * noteHeight + marginsAndHeader + minSpace;
		const numRenderedItems = this.props.timeRegistrationsWithNotes
			? Math.min(this.props.timeRegistrationsWithNotes.length, 4)
			: 0;
		const popOutSize = numRenderedItems * noteHeight + marginsAndHeader + (numRenderedItems === 2.5 ? 4 : 0);
		const isCloseToEdge = h - 60 - y <= popOutSize;
		const wouldFoldOverTop = y - popOutSize - 50 <= 0;
		if (isCloseToEdge && !wouldFoldOverTop) {
			this.setState({foldUp: true});
		} else {
			this.setState({foldUp: false});
		}

		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement;
		e.stopPropagation();

		if (btn) {
			//click on the button
			this.setState(prevState => {
				return {expanded: !prevState.expanded};
			});
		} else if (newTarget.className === 'notes-pop-out' || (this.notes_pop_out && this.notes_pop_out.contains(newTarget))) {
			//click inside the pop-out
			this.setState({
				expanded: true,
			});
		} else {
			//click somewhere else
			this.setState({
				expanded: false,
			});
		}
	}

	updateHarvestTask(timeReg, selectedHarvestTask) {
		if (!selectedHarvestTask && timeReg.project.id) {
			this.props.updateHarvestTasks(timeReg.project.id);
		} else if (!timeReg.harvestTask || (timeReg.harvestTask && timeReg.harvestTask.id !== selectedHarvestTask.value)) {
			Util.CommitMutation(UpdateTimeRegistration, {
				id: timeReg.id,
				harvestTaskId: selectedHarvestTask.value,
				harvestTaskName: selectedHarvestTask.label,
			});
		}
	}

	onHarvestDropdownBlur() {
		if (this._container) {
			this._container.focus();
		}
	}

	harvestRetry(timeRegistration) {
		Util.CommitMutation(UpdateTimeRegistration, {
			id: timeRegistration.id,
			retryHarvest: true,
		});
	}

	render() {
		const timeRegistrationsWithNotes = this.props.timeRegistrationsWithNotes;
		const {formatMessage, formatDate} = this.props.intl;
		const noteHeight = 70;
		const marginsAndHeader = 66;
		// Render 4 harvest tasks max, then we scroll
		const numRenderedItems = timeRegistrationsWithNotes ? Math.min(timeRegistrationsWithNotes.length, 4) : 0;
		const notesPopOutSize = numRenderedItems * noteHeight + marginsAndHeader + (numRenderedItems === 4 ? 4 : 0);

		if (timeRegistrationsWithNotes) {
			timeRegistrationsWithNotes.sort(function (a, b) {
				//sort notes by date
				if (a.year !== b.year) return a.year - b.year;
				if (a.month !== b.month) return a.month - b.month;
				return a.day - b.day;
			});
		}
		const harvestTaskOptions = [];
		this.props.harvestTasks.forEach(option => {
			harvestTaskOptions.push({
				value: option.id,
				label: option.name,
			});
		});

		return (
			<div
				className={'harvest-pop-out' + (this.state.foldUp ? ' fold-up' : '')}
				tabIndex={'0'}
				ref={div => (this._container = div)}
				onBlur={this.handleBlur.bind(this)}
			>
				<div
					ref={div => (this.notes_section = div)}
					onClick={e => (!this.props.disabled ? this.toggleExpanded(e, true) : {})}
					className={
						'harvest-button' + (!this.props.disabled ? ' has-notes' : '') + (this.state.expanded ? ' expanded' : '')
					}
				/>
				{this.state.expanded ? (
					timeRegistrationsWithNotes ? (
						<div
							className={'notes-pop-out' + (this.state.foldUp ? ' fold-up' : '')}
							style={this.state.foldUp ? {top: -1 * notesPopOutSize} : {}}
							ref={div => (this.notes_pop_out = div)}
						>
							{this.props.loadOnExpand && this.props.integrationLoading ? (
								<div className={'harvest-loading'}>
									<li className={'loading-box'}>
										<div className={'loading-text'}>{formatMessage({id: 'common.loading'})}</div>
										<div className={'loading-item'} />
									</li>
								</div>
							) : (
								<>
									<div className={'notes-header'}>
										<div className={'notes-header-title'}>
											{' '}
											{formatMessage({id: 'overview_time.harvest_for'})}
										</div>
										<div className={'task-name'}> {this.props.taskName}</div>
									</div>
									<CustomScrollDiv autoHeight={true} autoHeightMin={1} autoHeightMax={320}>
										<div className="notes-container">
											{timeRegistrationsWithNotes.map((timeRegistration, index) => {
												const day = Moment({
													year: timeRegistration.year,
													month: timeRegistration.month - 1,
													date: timeRegistration.day,
												});
												const isLockedInTime =
													this.props.lockedDate && this.props.lockedDate.isSameOrAfter(day, 'day');

												return (
													<div key={'note-' + index} className="note">
														<div className="note-header">
															<div className="note-date">
																{formatDate(
																	Moment({
																		year: timeRegistration.year,
																		month: timeRegistration.month - 1,
																		date: timeRegistration.day,
																	}),
																	{
																		weekday: 'long',
																		month: 'numeric',
																		day: 'numeric',
																	}
																)}
															</div>
															<div
																className="note-hours"
																title={
																	timeRegistration.invoiced
																		? formatMessage({id: 'invoice.cannot_edit_time_reg'})
																		: ''
																}
															>
																{'(' +
																	(!timeRegistration.invoiced
																		? Util.convertMinutesToFullHour(
																				timeRegistration.minutesRegistered,
																				this.props.intl
																		  )
																		: formatMessage(
																				{id: 'invoice.invoiced_hours'},
																				{
																					amount: timeRegistration.minutesRegistered,
																					formatted: Util.convertMinutesToFullHour(
																						timeRegistration.minutesRegistered,
																						this.props.intl
																					),
																				}
																		  )) +
																	')'}
															</div>
															{Util.getHarvestStatusIcon(timeRegistration, this.props.intl)}
															{timeRegistration.harvestError && (
																<div
																	className="harvest-retry"
																	onClick={this.harvestRetry.bind(this, timeRegistration)}
																	title={formatMessage({id: 'integrations.harvest.retry'})}
																>
																	{formatMessage({id: 'common.retry'})}
																</div>
															)}
														</div>
														<div tabIndex="0" className="note-content">
															<DropdownV2
																onBlur={this.onHarvestDropdownBlur.bind(this)}
																value={
																	timeRegistration.harvestTaskIdInt
																		? btoa(
																				`HarvestTaskType:${timeRegistration.harvestTaskIdInt}`
																		  )
																		: null
																}
																options={harvestTaskOptions}
																onChange={val => this.updateHarvestTask(timeRegistration, val)}
																hideLabel={true}
																placeholder={formatMessage({id: 'card_modal.harvest_task'})}
																disabled={this.props.disabled || isLockedInTime}
																customHeight={30}
																customClasses="harvest-task-dropdown"
																onClick={
																	this.props.loadOnExpand
																		? void 0
																		: this.props.updateHarvestTasks.bind(this)
																}
																integrationDropdown={true}
																integrationLoading={this.props.harvestLoading}
																integrationRetryToFetch={this.props.updateHarvestTasks.bind(
																	this
																)}
															/>
														</div>
													</div>
												);
											})}
										</div>
									</CustomScrollDiv>
								</>
							)}
						</div>
					) : null
				) : null}
			</div>
		);
	}
}
HarvestPopOut.propTypes = {
	timeRegItem: PropTypes.object.isRequired,
	timeRegistrationsWithNotes: PropTypes.array,
};
export default injectIntl(HarvestPopOut);
